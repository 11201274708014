import React from 'react'
import { Informe2019 } from './Informes'

function App() {
  return (
    <div className="App ">
      <Informe2019 />
    </div>
  )
}

export default App

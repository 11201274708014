import React, { PureComponent } from 'react'
// import { Link, Route } from 'react-router-dom'
// import { PAGES_IDS, ENTRIES } from '../../constants'
// import { logoLg } from '../../Images'

import './index.css'
import Button from '../Button'

type Entry = {
  id: string
  label: string
  path?: string
  type: string
}

type Props = {
  blockScroll: (string) => void
}

type State = {
  open: boolean
}

class Header extends PureComponent<Props, State> {
  state = {
    open: false,
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ open: !prevState.open }))
  }

  render() {
    const { open } = this.state

    return (
      <nav className={`shape-header `}>
        <ul className={`${open ? 'open' : ''}`}></ul>
        <div className="extra-buttons">
          <div
            className={`menu-btn ${open ? 'open' : ''} `}
            onClick={this.toggleMenu}
          >
            <span className="menu-btn-lines"></span>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header

import React, { PureComponent } from 'react'
import Col from './Col'

type Props = {
  data: any
  level: number
  className: string
  animate: boolean
  reset: boolean
}

class Row extends PureComponent<Props> {
  render() {
    const { data, level, className, animate, reset } = this.props

    return (
      <div className={`shape-row  level-${level} ${className}`}>
        <>
          {data.map((d, key) => {
            if (d.type === 'col') {
              return (
                <Col
                  key={key}
                  data={d.data}
                  level={key}
                  cols={d.cols}
                  md={d.md}
                  sm={d.sm}
                  animate={animate}
                  reset={reset}
                  className={d.className || ''}
                />
              )
            }
            return null
          })}
        </>
      </div>
    )
  }
}

export default Row

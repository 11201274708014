import React, { Component } from 'react'
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'

import {
  Hero,
  Intro,
  Raices,
  EsLey,
  Justicia,
  Jesus,
  App,
  Vientos,
  America,
  Echando,
  Cuerpo,
  Ramas,
  Trabajamos,
  Cosechando,
  SorJuana,
  Elevamos,
  Recursos,
  Gracias,
} from './Sections'
import { StartAnimation } from '../../Components'

const PAGES = [
  'Hero',
  'Intro',
  'Raices',
  'EsLey',
  'Justicia',
  'Jesus',
  'AppSection',
  'Vientos',
  'America',
  'Echando',
  'Cuerpo',
  'Ramas',
  'Trabajamos',
  'Cosechando',
  'SorJuana',
  'Elevamos',
  'Recursos',
  'Gracias',
]

const titlesByKey = {
  Raices: 'Raíces de un ciclo',
  EsLey: '¡Es ley! ',
  Justicia: 'Las raíces de la justicia siguen creciendo',
  Jesus: 'Como Jesús acompañamos sin juzgar',
  AppSection: 'Cuando nos informamos, nos (APP)licamos',
  Vientos: 'Vientos feministas soplarán en todo el mundo',
  America: '¡América Latina será toda feminista!',
  Echando: 'Echando raíces',
  Cuerpo: 'Nuestro cuerpo, nuestro territorio',
  Ramas: 'Nuestras ramas continúan creciendo',
  Trabajamos: 'Trabajamos para que otra realidad sea posible',
  Cosechando: 'Seguimos cosechando frutos',
  SorJuana: '¡Sor juanaaa...!',
  Elevamos: 'Elevamos la voz cada día más alto',
  Recursos: 'Nuestros recursos',
  Gracias: '¡Gracias!',
}

function stringLiterals<T extends string>(...args: T[]): T[] {
  return args
}
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never

type Props = {}

type State = {
  open: boolean
  active: ElementType<typeof PAGES>
}

class Informe2019 extends Component<Props, State> {
  state = {
    open: false,
    active: PAGES[0],
  }

  componentDidMount() {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
    scrollSpy.update()
  }

  componentDidUpdate(prevProps, prevState) {
    const { active } = this.state
    if (active !== prevState.active) {
      this.setState({ open: false })
    }
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ open: !prevState.open }))
  }

  handleSetActive = (to) => {
    this.setState({ active: to })
  }

  render() {
    const { open, active } = this.state
    const sections = PAGES.filter((p) => p !== 'Hero' && p !== 'Intro')
    const hidenLinks = ['Hero', 'Intro']

    return (
      <>
        <nav className="shape-header">
          <div className="shape-container">
            <div className="shape-row">
              <div className="shape-col-1 star">
                <StartAnimation />
              </div>
              <div className="title shape-col-10 font-raleway text-center text-color-green font-size-sm">
                INFORME CDD 2019
              </div>
              <div className="shape-col-1-np">
                <div className="extra-buttons">
                  <div
                    className={`menu-btn ${open ? 'open' : ''} `}
                    onClick={this.toggleMenu}
                  >
                    <span className="menu-btn-lines"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={` menu ${open ? 'open' : ''}`}>
            <div className="shape-container">
              <div className="menu-box">
                <div className="text-center star-menu">
                  <StartAnimation />
                </div>
                <ul>
                  <div className="hidden">
                    {hidenLinks
                      .filter((p, k) => k < 8)
                      .map((page, key) => (
                        <li key={key}>
                          <Link
                            activeClass="active"
                            to={page}
                            spy={true}
                            hashSpy={true}
                            smooth={true}
                            duration={500}
                            onSetActive={this.handleSetActive}
                          >
                            {page}
                          </Link>
                        </li>
                      ))}
                  </div>
                  {sections
                    .filter((p, k) => k < 8)
                    .map((page, key) => (
                      <li key={key}>
                        <Link
                          activeClass="active"
                          to={page}
                          spy={true}
                          hashSpy={true}
                          smooth={true}
                          duration={500}
                          onSetActive={this.handleSetActive}
                        >
                          {`${key + 1}. ${titlesByKey[page]}`}
                        </Link>
                      </li>
                    ))}
                </ul>
                <ul>
                  {sections
                    .filter((p, k) => k > 7)
                    .map((page, key) => (
                      <li key={key}>
                        <Link
                          activeClass="active"
                          to={page}
                          spy={true}
                          hashSpy={true}
                          smooth={true}
                          duration={500}
                          onSetActive={this.handleSetActive}
                        >
                          {`${key + 1 + 8}. ${titlesByKey[page]}`}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <Element name="Hero">
          <Hero animate={'Hero' === active} reset={'Hero' !== active} />
        </Element>
        <div className="shape-row ">
          <div className="shape-container">
            <Element name="Intro">
              <Intro animate={'Intro' === active} reset={'Intro' !== active} />
            </Element>

            <Element name="Raices">
              <Raices
                animate={'Raices' === active}
                reset={'Raices' !== active}
              />
            </Element>

            <Element name="EsLey">
              <EsLey animate={'EsLey' === active} reset={'EsLey' !== active} />
            </Element>

            <Element name="Justicia">
              <Justicia
                animate={'Justicia' === active}
                reset={'Justicia' !== active}
              />
            </Element>

            <Element name="Jesus">
              <Jesus animate={'Jesus' === active} reset={'Hero' !== active} />
            </Element>

            <Element name="AppSection">
              <App
                animate={'AppSection' === active}
                reset={'Jesus' === active}
              />
            </Element>

            <Element name="Vientos">
              <Vientos
                animate={'Vientos' === active}
                reset={'Vientos' !== active}
              />
            </Element>

            <Element name="America">
              <America
                animate={'America' === active}
                reset={'America' !== active}
              />
            </Element>

            <Element name="Echando">
              <Echando
                animate={'Echando' === active}
                reset={'Echando' !== active}
              />
            </Element>

            <Element name="Cuerpo">
              <Cuerpo
                animate={'Cuerpo' === active}
                reset={'Cuerpo' !== active}
              />
            </Element>

            <Element name="Ramas">
              <Ramas animate={'Ramas' === active} reset={'Ramas' !== active} />
            </Element>

            <Element name="Trabajamos">
              <Trabajamos
                animate={'Trabajamos' === active}
                reset={'Trabajamos' !== active}
              />
            </Element>

            <Element name="Cosechando">
              <Cosechando
                animate={'Cosechando' === active}
                reset={'Cosechando' !== active}
              />
            </Element>

            <Element name="SorJuana">
              <SorJuana
                animate={'SorJuana' === active}
                reset={'SorJuana' !== active}
              />
            </Element>

            <Element name="Elevamos">
              <Elevamos
                animate={'Elevamos' === active}
                reset={'Elevamos' !== active}
              />
            </Element>

            <Element name="Recursos">
              <Recursos
                animate={'Recursos' === active}
                reset={'Recursos' !== active}
              />
            </Element>

            <Element name="Gracias">
              <Gracias
                animate={'Gracias' === active}
                reset={'Gracias' !== active}
              />
            </Element>
          </div>
        </div>
      </>
    )
  }
}

export default Informe2019

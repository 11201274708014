import React from 'react'
import Row from './Row'

type Props = {
  data: any
  animate?: boolean
  reset: boolean
}

const Template = ({ data, animate, reset }: Props) => {
  return (
    <>
      {data.map((d, key) => {
        if (d.type === 'row') {
          return (
            <Row
              reset={reset}
              key={key}
              data={d.data}
              level={key}
              animate={!!animate}
              className={d.className || ''}
            />
          )
        }
        return null
      })}
    </>
  )
}

export default Template

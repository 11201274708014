import { app1 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'app',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '5',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'CUANDO NOS INFORMAMOS, NOS (APP)LICAMOS',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'El Manual Educativo de Catolicadas aborda el embarazo adolescente, el uso de métodos anticonceptivos y la interrupción del embarazo en casos de violación.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Posteriormente, estos proveedores replicaron el manual con estudiantes de secundaria y continuarán haciéndolo durante el siguiente año. ',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Durante 2019 difundimos el juego (App)lícate con tu sexualidad que creamos para evaluar los temas que aborda el manual, principalmente entre adolescentes.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        data: [
          {
            type: 'text',
            cols: 12,
            className: 'mt-10 font-EB font-size-md-2 ',
            text: [
              {
                type: 'p',
                text: 'Desde CDD, en colaboración con aliados, ',
              },
              {
                type: 'p',
                color: 'green',
                text:
                  'capacitamos a proveedores de salud utilizando nuestro manual educativo de ',
              },
              {
                type: 'p',
                color: 'green',
                className: 'font-italic',
                text: 'Catolicadas',
              },
            ],
          },
          {
            type: 'placeholder',
            cols: 1,
          },
          {
            type: 'img',
            hover: true,
            img: app1,
            cols: 10,
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    className: 'esley-text ',
    data: [
      {
        type: 'col',
        cols: 8,
        md: 12,
        sm: 12,
        className: 'mt-5',
        data: [
          {
            type: 'text',
            cols: 3,
            md: 4,
            sm: 12,
            className: 'text-center',
            text: [
              {
                type: 'strong',
                className: 'font-raleway font-size-lg ',
                number: 10795,
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                text: 'descargas',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text: 'de la app',
              },
            ],
          },
          {
            type: 'text',
            cols: 3,
            md: 4,
            sm: 12,
            className: 'text-center',
            text: [
              {
                type: 'strong',
                className: 'font-raleway font-size-lg ',
                number: 227,
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                text: 'proveedores',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text: 'de salud capacitados',
              },
            ],
          },
          {
            type: 'text',
            cols: 3,
            md: 4,
            sm: 12,
            className: 'text-center',
            text: [
              {
                type: 'strong',
                className: 'font-raleway font-size-lg ',
                number: 129,
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },

              {
                type: 'p',
                text: 'estudiantes',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text: 'de secundaria',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'Si eres joven y quieres medir tus conocimientos:',
              },
            ],
          },
          {
            type: 'button',
            cols: 6,
            sm: 6,
            md: 6,
            text: 'Jugar App (Apple)',
            color: 'black',
            outline: true,
            size: 'sm',
            link:
              'https://apps.apple.com/us/app/app-l%C3%ADcate-con-tu-sexualidad/id1303004481',
          },

          {
            type: 'button',
            cols: 6,
            sm: 6,
            md: 6,
            text: 'Jugar App (Android)',
            color: 'black',
            outline: true,
            size: 'sm',
            link:
              'https://play.google.com/store/apps/details?id=com.BitAllForce.Applicate',
          },
        ],
      },
    ],
  },
]

import React from 'react'
import ImageWithPlaceHolder from '../../Image'
import { useSpring, animated } from 'react-spring'
import { upperFirst } from 'lodash'

import './index.css'

type Props = {
  parent: number
  level: number
  cols: number
  className?: string
  img: string
  color?: string
  figcaption?: string
  figcaptionTop?: string
  hover: boolean
  md?: number
  sm?: number
  animate: boolean
  delay: number
  side: string
}

const calc = (x, y) => [
  -(y - window.innerHeight / 3) / 50,
  (x - window.innerWidth / 3) / 50,
  1.1,
]
const trans = (x, y, s) =>
  `perspective(900px) rotateX(${x / 3}deg) rotateY(${y / 3}deg) scale(${s})`

const Image = (data: Props) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  const animateClass = data.animate
    ? `animation-list animate__animated ${
        data.side || 'animate__fadeIn'
      } animate__delay-${data.delay || 0}s`
    : 'fade-in'

  return (
    <>
      {data.hover ? (
        <div
          className={`shape-col-${data.cols || 'auto'} shape-col-${
            data.sm || 12
          }-sm  shape-col-${data.md || 12}-md shape-text-img ${
            data.className || ''
          }  ${animateClass}`}
        >
          <animated.figure
            onMouseMove={({ clientX: x, clientY: y }) =>
              set({ xys: calc(x, y) })
            }
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{
              transform:
                // @ts-ignore
                props.xys.interpolate(trans),
            }}
          >
            {data.figcaptionTop ? (
              <figcaption className="top">{data.figcaptionTop}</figcaption>
            ) : null}
            <ImageWithPlaceHolder alt="nos van a ver juntas" src={data.img} />
            {data.figcaption ? (
              <figcaption>{data.figcaption}</figcaption>
            ) : null}
          </animated.figure>
        </div>
      ) : (
        <figure
          className={`shape-col-${data.cols || 'auto'} shape-col-${
            data.sm || 12
          }-sm  shape-col-${data.md || 12}-md shape-text-img ${
            data.className || ''
          } ${animateClass}`}
        >
          {data.figcaptionTop ? (
            <figcaption className="top">{data.figcaptionTop}</figcaption>
          ) : null}
          <ImageWithPlaceHolder alt="nos van a ver juntas" src={data.img} />
          {data.figcaption ? <figcaption>{data.figcaption}</figcaption> : null}
        </figure>
      )}
    </>
  )
}
export default Image

import React, { PureComponent } from 'react'
import { Hero, DotText, BodyText, Image } from '../../Components'
import Button from '../Button'

type Props = {
  data: any
  level: number
  cols: number
  md?: number
  sm?: number
  className?: string
  animate: boolean
  reset: boolean
}

class Col extends PureComponent<Props> {
  render() {
    const { data, level, cols, md, sm, className, animate, reset } = this.props

    return (
      <div
        className={`shape-col-${cols ? cols : 'auto'} shape-col-${
          md || 12
        }-md shape-col-${sm || 12}-sm level-${level} ${className}`}
      >
        {data && data.length
          ? data.map((d, key) => {
              if (d.type === 'hero') {
                return <Hero key={key} {...d} level={key} parent={level} />
              }

              if (d.type === 'dotText') {
                return <DotText key={key} {...d} level={key} parent={level} />
              }

              if (d.type === 'text') {
                return (
                  <BodyText
                    key={key}
                    {...d}
                    level={key}
                    parent={level}
                    animate={animate}
                    reset={reset}
                  />
                )
              }

              if (d.type === 'img') {
                return (
                  <Image
                    key={key}
                    {...d}
                    delay={d.delay}
                    level={key}
                    animate={animate}
                    parent={level}
                  />
                )
              }

              if (d.type === 'button') {
                return (
                  <div
                    key={key}
                    className={`shape-col-${d.cols}-np shape-col-${
                      d.md || 12
                    }-md shape-col-${d.sm || 12}-sm shape-text-placeholder`}
                  >
                    <a href={d.link} target="_blank">
                      <Button key={key} {...d} />
                    </a>
                  </div>
                )
              }

              if (d.type === 'placeholder') {
                return (
                  <div
                    key={key}
                    className={`shape-col-${d.cols}-np shape-col-${
                      d.md || 12
                    }-md shape-col-${d.sm || 12}-sm shape-text-placeholder`}
                  />
                )
              }
              return null
            })
          : null}
      </div>
    )
  }
}

export default Col

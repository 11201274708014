import React, { Fragment } from 'react'

import './index.css'
import StartAnimation from '../../StartAnimation'
import NumberAnimation from '../../NumberAnimation'

type Props = {
  parent?: number
  level?: number
  cols: number
  className?: string
  text: {
    type: string
    text?: string
    color?: string
    link?: string
    items?: string[]
    className?: string
    star: string
    number: number
    start: number
  }[]
  star: string
  color?: string
  md?: number
  sm?: number
  animate: boolean
  reset: boolean
}

const BodyText = (data: Props) => {
  const insideP = ['p', 'strong', 'mark', 'link', 'br', 'video']

  return (
    <div
      className={`shape-col-${data.cols} shape-col-${
        data.sm || 12
      }-sm  shape-col-${data.md || 12}-md ${
        data.className || ''
      } shape-text-body level-${data.parent || 0}-${data.level || 0}`}
    >
      {data.star ? (
        <div className={`stat-container ${data.star}`}>
          <StartAnimation />
        </div>
      ) : null}

      <p>
        {data.text.map((t, key) => {
          if (insideP.includes(t.type)) {
            return (
              <Fragment key={key}>
                {t.type === 'p' ? (
                  <>
                    {t.star ? (
                      <span className="p-start">
                        <StartAnimation />
                      </span>
                    ) : null}
                    <span
                      className={`${t.star ? 'ml' : ''} ${
                        t.color ? `text-color-${t.color}` : ''
                      } ${t.className || ''}`}
                    >
                      {t.text}
                    </span>
                  </>
                ) : null}

                {t.type === 'strong' ? (
                  <strong
                    className={`${t.color ? `text-color-${t.color}` : ''} ${
                      t.className || ''
                    }`}
                  >
                    {t.text ? t.text : null}
                    {t.number && data.animate ? (
                      <NumberAnimation
                        animate={data.animate}
                        start={t.start ? t.start : t.number / 1.2}
                        ends={t.number}
                        reset={data.reset}
                      />
                    ) : (
                      <>{t.number.toLocaleString('en-US')}</>
                    )}
                  </strong>
                ) : null}

                {t.type === 'mark' ? (
                  <mark
                    className={`${t.color ? `bg-color-${t.color}` : ''} ${
                      t.className || ''
                    }`}
                  >
                    {t.text}
                  </mark>
                ) : null}
                {t.type === 'link' && t.link ? (
                  <a
                    target="_blank"
                    className={`${t.color ? `text-color-${t.color}` : ''} ${
                      t.className || ''
                    }`}
                    href={t.link}
                    rel="noopener noreferrer"
                  >
                    {t.text}
                  </a>
                ) : null}

                {t.type === 'br' ? <br /> : null}
              </Fragment>
            )
          }
          if (t.type === 'hr') {
            return (
              <span
                key={key}
                className={`${(t.color ? `bg-color-${t.color}` : '') + ' hr'} ${
                  t.className || ''
                }`}
              />
            )
          }
          return null
        })}
      </p>

      {data.text
        .filter((t) => t.type === 'ol')
        .map((list, key) => {
          return (
            <ul key={key}>
              {list.items && list.items.map((li, k) => <li key={key}>{li}</li>)}
            </ul>
          )
        })}
    </div>
  )
}

export default BodyText

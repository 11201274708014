import React from 'react'
import { Template, HeroAnimation } from '../../../../Components'
import { DATA } from './data'

const Hero = ({ animate, reset }) => {
  return (
    <>
      <HeroAnimation />
      <Template data={DATA} animate={animate} reset={reset} />
    </>
  )
}

export default Hero

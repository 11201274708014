import {
  cosechamos1,
  cosechamos2,
} from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'cosechando',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '12',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'SEGUIMOS COSECHANDO FRUTOS',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'El compromiso y convicción de las integrantes del OCNF en la búsqueda de justicia es indudable.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 3,
        md: 12,
        sm: 12,
        className: 'mt-10',
        data: [
          {
            className: 'max-width-img',
            type: 'img',
            side: 'animate__pulse',
            img: cosechamos1,
          },
        ],
      },
      {
        type: 'col',
        cols: 5,
        className: 'mt-10',
        data: [
          {
            type: 'text',
            cols: 12,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'Gracias a este incansable trabajo, el OCNF recibió el Premio Abogados de Atocha 2019, que fue otorgado por la Fundación Abogados de Atocha de España, que reconoce cada año a aquellas personas e instituciones que luchan por la democracia, la libertad y los derechos de todas y todos.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    className: 'cosechando',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 9,
        data: [
          {
            type: 'img',
            img: cosechamos2,
            delay: 1,
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 2,
      },
    ],
  },
]

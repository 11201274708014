export const DATA = [
  {
    type: 'row',
    className: 'raices',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            cols: 11,
            className: 'star-bottom',
            star: 'top',
            title: [
              {
                text: '¡GRACIAS!',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                star: 'left',
                text: 'American Jewish World Service',
              },
            ],
          },
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                star: 'left',
                text: 'Embajada del Reino de los Países Bajos en México',
              },
            ],
          },
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                star: 'left',
                text: 'Fundación Ford',
              },
            ],
          },
        ],
      },

      {
        type: 'col',
        cols: 7,
        data: [
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'A nuestra Asamblea de Asociadas y al equipo de trabajo de CDD. ',
              },
              {
                type: 'mark',
                color: 'yellow',
                text: 'A nuestros donantes:',
              },
            ],
          },
          {
            type: 'text',
            cols: 6,
            text: [
              {
                type: 'p',
                star: 'left',
                text: 'Donante Anónimo',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                star: 'left',
                text: 'Fidelity Charitable Gift Fund',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                star: 'left',
                text:
                  'Federación Internacional de Planificación de la Familia, Región Hemisferio Occidental (IPPF/RHO) y RFSU/Suecia',
              },
            ],
          },
          {
            type: 'text',
            cols: 6,
            text: [
              {
                type: 'p',
                star: 'left',
                text: 'Embajada de Australia en México',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                star: 'left',
                text: 'Fondo Canadá para Iniciativas Locales',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                star: 'left',
                text: 'Unión Europea',
              },
            ],
          },
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'mark',
                color: 'yellow',
                text:
                  'A todas las personas que nos permitieron caminar con ellas: aliadas, asesores, seguidores, madres y familiares de víctimas de feminicidio y mujeres acompañadas.',
              },
            ],
          },
        ],
      },
    ],
  },
]

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './index.css'

type Props = {
  className?: string
  color?: string
  disabled?: boolean
  loading?: boolean
  outline?: boolean
  size?: 'xs' | 'md' | 'sm' | 'lg'
  text: string
  type?: 'submit' | 'button' | 'reset'
  onClick?: () => void
}

const Button = ({
  className,
  color,
  disabled,
  loading,
  outline,
  size,
  text,
  type,
  ...props
}: Props) => {
  const buttonColor =
    (color ? 'easy-btn-' + color : 'easy-btn-default') +
    (outline ? '-outline' : '')
  const buttonSize = size ? 'easy-btn-' + size : 'easy-btn-sm'

  return (
    <button
      {...props}
      type={type ? type : 'submit'}
      disabled={!!disabled || !!loading}
      className={`easy-btn ${buttonColor} ${buttonSize} ${className || ''}`}
    >
      <div className="easy-btn-container">
        <span className={`easy-btn-text font-EB ${loading ? 'active' : ''}`}>
          {text}
        </span>
        {loading && (
          <span className="easy-btn-loader-spinner">
            <FontAwesomeIcon icon="spinner" spin />
          </span>
        )}
      </div>
    </button>
  )
}

export default Button

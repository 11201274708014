export const DATA = [
  {
    type: 'row',
    className: 'hero ',
    data: [
      {
        type: 'col',
        cols: 3,
      },
      {
        type: 'col',
        cols: 6,
        data: [
          {
            type: 'text',
            className: 'text-center font-EB ',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'El 2019 fue un año de',
              },
              {
                type: 'p',
                color: 'green',
                text: ' movilización, celebración y cambio ',
              },
              {
                type: 'p',
                text:
                  'para Católicas por el Derecho a Decidir (CDD) y para el movimiento feminista.',
              },
            ],
          },
        ],
      },

      {
        type: 'col',
        cols: 3,
      },
    ],
  },
  // {
  //   type: 'row',
  //   className: 'intro-info',
  //   data: [
  //     {
  //       type: 'col',
  //       cols: 3,
  //     },
  //     {
  //       type: 'col',
  //       cols: 6,
  //       className: 'text-center',
  //       data: [
  //         {
  //           type: 'button',
  //           cols: 12,
  //           text: 'Descargar informe completo',
  //           color: 'black',
  //           outline: true,
  //           size: 'md',
  //           link: '',
  //         },
  //       ],
  //     },
  //     {
  //       type: 'col',
  //       cols: 3,
  //     },
  //   ],
  // },
]

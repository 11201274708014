import {
  ramas1,
  ramas2,
  ramas3,
  ramas4,
} from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'raices',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '10',
              },
            ],
          },
          {
            type: 'hero',
            cols: 11,
            title: [
              {
                text: 'NUESTRAS RAMAS CONTINÚAN CRECIENDO',
              },
            ],
          },

          {
            type: 'text',
            cols: 10,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'En 2019, caminamos juntas en la búsqueda de la justicia que por fin llegó para tres familias y sus hijas, víctimas de feminicidio. ',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        data: [
          {
            type: 'text',
            cols: 12,
            className: 'mt-10',
            text: [
              {
                type: 'p',
                text:
                  'Estas sentencias nos generan sentimientos encontrados porque ellas ya no están, pero representan un paso importante en la lucha contra la impunidad y, para las que seguimos, nos dan fuerza para continuar trabajando.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'Desde CDD y el OCNF ',
              },
              {
                type: 'mark',
                color: 'yellow',
                text:
                  ' acompañamos otros 52 casos de feminicidio, tentativa de feminicidio, desaparición y violencia sexual. ',
              },
              {
                type: 'p',
                text:
                  ' Esperamos que, en un futuro no muy lejano, también podrán acceder a la justicia.',
              },
            ],
          },
        ],
      },
    ],
  },
]

export const DATA2 = [
  {
    type: 'row',
    className: 'raices-casos',
    data: [
      {
        type: 'col',
        cols: 4,
        className: 'lesvy',
        data: [
          {
            type: 'placeholder',
            cols: 3,
          },
          {
            type: 'img',
            className: 'raices-casos-icon',
            img: ramas2,
            hover: true,
            cols: 6,
          },
          {
            type: 'hero',
            cols: 12,
            className: 'font-size-md-1 text-center',
            title: [
              {
                text: 'LESVY',
              },
            ],
          },
          {
            type: 'placeholder',
            cols: 1,
          },
          {
            type: 'text',
            cols: 11,
            className: 'font-size-sm text-center',
            text: [
              {
                type: 'p',
                text:
                  'Lesvy era una jóven alegre y carismática. Desde niña le gustaba mucho leer y uno de sus sueños era recorrer el mundo. En 2017, a sus 22 años, Lesvy fue víctima de feminicidio en la Ciudad de México. Desde un inicio, el caso enfrentó la violencia institucional y, sin completar las diligencias adecuadas, su agresor fue inicialmente acusado de homicidio por omisión por no evitar el supuesto suicidio de Lesvy. Después de una larga lucha, en octubre de 2019, se dictó el fallo condenatorio de 45 años de prisión al agresor por feminicido.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        className: 'emma',
        data: [
          {
            type: 'placeholder',
            cols: 3,
          },
          {
            type: 'img',
            className: 'raices-casos-icon',
            img: ramas3,
            hover: true,
            cols: 6,
          },
          {
            type: 'hero',
            cols: 12,
            className: 'font-size-md-1 text-center',
            title: [
              {
                text: 'EMMA',
              },
            ],
          },
          {
            type: 'placeholder',
            cols: 1,
          },
          {
            type: 'text',
            cols: 11,
            className: 'font-size-sm text-center',
            text: [
              {
                type: 'p',
                text:
                  'Emma disfrutaba mucho estar con sus tres hijos, a quienes había recuperado tras una batalla de custodia de cinco años. Meses después de recuperarlos, en 2017, Emma fue asesinada por dos sicarios. Todos a su alrededor estaban convencidos de que detrás del feminicidio se encontraba su ex-esposo, quien la había violentado sistemáticamente y quien aprovechaba sus vínculos políticos en Yucatán para seguir impune. En septiembre de 2019, su ex-esposo fue sentenciado a la pena máxima de 50 años.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        className: 'alicia ',
        data: [
          {
            type: 'placeholder',
            cols: 3,
          },
          {
            type: 'img',
            className: 'raices-casos-icon',
            img: ramas4,
            hover: true,
            cols: 6,
          },
          {
            type: 'hero',
            cols: 12,
            className: 'font-size-md-1 text-center',
            title: [
              {
                text: 'ALICIA',
              },
            ],
          },
          {
            type: 'placeholder',
            cols: 1,
          },
          {
            type: 'text',
            cols: 11,
            className: 'font-size-sm text-center',
            text: [
              {
                type: 'p',
                text:
                  'Alicia, periodista en Nuevo León, fue víctima de feminicidio en su hogar en 2018. Fue asesinada por un sicario, pero el autor intelectual fue su ex-marido. Cuando Alicia inició el proceso de divorcio en 2010, el abuso que ya sufría se exacerbó. A partir del acompañamiento del caso, se logró demostrarel contexto de violencia que vivía y, en septiembre de 2019, el ex-esposo de Alicia fue sentenciado a la condena máxima de 50 años.',
              },
            ],
          },
        ],
      },
    ],
  },
]

import React from 'react'
import './index.css'
import StartAnimation from '../../StartAnimation'
import AnimationShape from '../../AnimationShape'

type Props = {
  parent?: number
  level?: number
  cols: number
  tinyTitle: string
  title: {
    text: string
    type?: string
    color?: string
  }[]
  subtitle?: {
    text: string
    type?: string
    color?: string
  }[]
  color?: string
  className?: string
  star: string
  shape: string
  md?: number
  sm?: number
}

const Hero = (data: Props) => {
  return (
    <div
      className={`shape-col-${data.cols} shape-col-${
        data.sm || 12
      }-sm  shape-col-${data.md || 12}-md shape-text-hero level-${
        data.parent || 0
      }-${data.level || 0} ${data.className}`}
    >
      {data.shape ? (
        <AnimationShape className="bottom right shape-back" />
      ) : null}
      {data.title ? (
        <p className="shape-text-hero-title">
          {data.title.map((t, key) => {
            if (t.type && t.type === 'br') {
              return <br key={key} />
            } else {
              return (
                <span key={key} className={`text-color-${t.color || 'black'}`}>
                  {t.text}
                </span>
              )
            }
          })}
        </p>
      ) : null}
      {data.subtitle ? (
        <p className="shape-text-hero-subtitle">
          {data.subtitle.map((t, key) => {
            if (t.type && t.type === 'br') {
              return <br key={key} />
            } else {
              return (
                <span key={key} className={`text-color-${t.color || 'black'}`}>
                  {t.text}
                </span>
              )
            }
          })}
        </p>
      ) : null}
      {data.star ? (
        <div className={`stat-container ${data.star}`}>
          <StartAnimation />
        </div>
      ) : null}
    </div>
  )
}
export default Hero

import React from 'react'
import { round } from 'lodash'
import { animated, config } from 'react-spring'
import { Spring } from 'react-spring/renderprops'

type Props = { animate: boolean; start: number; ends: number; reset: boolean }

const NumberAnimation = ({ animate, start, ends, reset }: Props) => {
  console.log(animate)
  return (
    <Spring
      from={{ number: start }}
      to={{ number: ends }}
      config={{ mass: 172, tension: 301, friction: 282 }}
    >
      {(props) => <span>{round(props.number).toLocaleString('en-US')}</span>}
    </Spring>
  )
}

export default NumberAnimation

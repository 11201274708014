import React from 'react'
import { animated } from 'react-spring'
import { Spring } from 'react-spring/renderprops'

import './index.css'

const StartAnimation = () => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ tension: 0, friction: 2, precision: 0.1, duration: 500 }}
    >
      {({ opacity }) => (
        <animated.svg
          className="animated-star"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 5 50 75"
          style={{ opacity }}
        >
          <polygon
            className="star"
            fill="#f2b655"
            points="51.68 29.49 33.13 26.17 43.73 10.93 28.49 21.87 25.84 0 23.19 21.87 7.95 10.93 18.55 26.17 0 29.49 20.7 32.61 7.95 47.49 23.19 36.55 25.84 58.42 28.49 36.55 43.73 47.49 31.14 32.8 51.68 29.49"
          />
        </animated.svg>
      )}
    </Spring>
  )
}

export default StartAnimation

import { jesus1, jesus2 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'jesus ',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 6,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '4',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'COMO JESÚS ACOMPAÑAMOS SIN JUZGAR',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'Creemos que una mujer no tiene por qué renunciar a su fe por ejercer su libertad de conciencia cuando decide tener un aborto.',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text: 'Por eso, desde CDD ',
              },
              {
                type: 'mark',
                color: 'yellow',
                text:
                  'ofrecimos información y acompañamiento a mujeres, en su mayoría católicas. ',
              },
              {
                type: 'p',
                text:
                  'Asimismo, nuestro grupo ecuménico, conformado por líderes de distintas denominaciones cristianas, comenzó a ofrecer ',
              },
              {
                type: 'mark',
                color: 'yellow',
                text: 'acompañamiento espiritual',
              },
              {
                type: 'p',
                text:
                  ' a mujeres para atender dudas en torno a su relación con Dios, a raíz de la decisión que han tomado.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 6,
        data: [
          { type: 'placeholder', cols: 1 },
          {
            type: 'img',
            className: 'mt-5',
            hover: true,
            img: jesus1,
            cols: 11,
          },
          { type: 'placeholder', cols: 1 },
          {
            type: 'img',
            className: 'mt-5',
            hover: true,
            img: jesus2,
            delay: 1,
            cols: 11,
          },
        ],
      },
    ],
  },
]

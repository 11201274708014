import { justicia1, justicia2 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'justicia',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            className: 'star-bottom',
            star: 'bottom',
            cols: 12,
            title: [
              {
                text: '3',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'LAS RAÍCES DE LA JUSTICIA SIGUEN CRECIENDO',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'Este año continuamos trabajando en Veracruz en donde habíamos solicitado',
              },
              {
                type: 'p',
                color: 'pink',
                text:
                  ' una Alerta de Violencia de Género por Agravio Comparado.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        md: 6,
        sm: 12,
        data: [
          { type: 'placeholder', cols: 2 },
          {
            type: 'img',
            img: justicia1,
            className: 'max-width-img',
            hover: true,
            side: 'animate__fadeInRight',
            cols: 8,
          },
        ],
      },
      {
        type: 'col',
        cols: 5,
        md: 6,
        sm: 12,
        data: [
          {
            type: 'img',
            className: 'max-width-img mt-5',
            img: justicia2,
            hover: true,
            delay: 1,
            side: 'animate__fadeInLeft',
            cols: 11,
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Esta Alerta nos permite evidenciar la forma en la que las leyes o políticas públicas locales discriminan a las mujeres o transgreden sus derechos; en este caso, porque no permiten a las mujeres en el estado de Veracruz acceder a la interrupción legal del embarazo en diversas circunstancias. ',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Después de que el Congreso local se negó a tomar medidas para atender las recomendaciones de esta Alerta, incluyendo la ampliación de causales de aborto, ',
              },
              {
                type: 'mark',
                color: 'pink',
                text:
                  ' en CDD y el Observatorio Ciudadano Nacional del Feminicidio (OCNF) continuamos empujando el caso, que en 2019 fue atraído por la Suprema Corte de Justicia de la Nación. ',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Esperamos una decisión en los próximos meses que, de ser positiva, brindaría un estándar sobre la obligación de los congresos locales de modificar las leyes que contravienen los derechos de las mujeres.',
              },
            ],
          },
        ],
      },
    ],
  },
]

import {
  elevamos1,
  elevamos2,
  facebook,
  instagram,
  twitter,
  personas,
} from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'elevamos ',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '14',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'ELEVAMOS LA VOZ CADA DÍA MÁS ALTO',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'A través de nuestros mensajes en redes sociales alcanzamos a:',
              },
            ],
          },
          {
            type: 'img',
            img: elevamos2,
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 8,
        data: [
          {
            type: 'img',
            img: elevamos1,
            cols: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    className: 'elevamos-text mt-5 ',
    data: [
      {
        type: 'col',
        cols: 3,

        data: [
          {
            type: 'img',
            className: 'elevamos-redes',
            img: facebook,
            cols: 12,
          },
          {
            type: 'text',
            cols: 12,
            className: 'text-center',
            text: [
              {
                type: 'strong',
                className: 'font-raleway font-size-lg ',
                number: 26941394,
                start: 26901360,
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            className: 'font-EB text-center font-size-md',
            text: [
              {
                type: 'p',
                text: 'personas en Facebook',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 3,
        className: 'text-center',
        data: [
          {
            type: 'img',
            className: 'elevamos-redes',
            img: twitter,
            delay: 1,
            cols: 12,
          },
          {
            type: 'text',
            cols: 12,
            className: 'text-center',
            text: [
              {
                type: 'strong',
                className: 'font-raleway font-size-lg ',
                number: 1786114,
                start: 1716000,
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            className: 'font-EB text-center font-size-md',
            text: [
              {
                type: 'p',
                text: 'personas en Twitter',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 3,
        className: 'text-center',
        data: [
          {
            type: 'img',
            delay: 2,
            className: 'elevamos-redes',
            img: instagram,
            cols: 12,
          },
          {
            type: 'text',
            cols: 12,
            className: 'text-center',
            text: [
              {
                type: 'strong',
                className: 'font-raleway font-size-lg ',
                number: 310162,
                start: 310010,
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            className: 'font-EB text-center font-size-md',
            text: [
              {
                type: 'p',
                text: 'personas en Instagram',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 3,
        className: 'text-center',
        data: [
          {
            type: 'img',
            className: 'elevamos-redes',
            img: personas,
            delay: 3,
            cols: 12,
          },
          {
            type: 'text',
            cols: 12,
            className: 'text-center',
            text: [
              {
                type: 'strong',
                className: 'font-raleway font-size-lg ',
                number: 310162,
                start: 300062,
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            className: 'font-EB text-center font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'personas recibieron nuestros materiales impresos y digitales',
              },
            ],
          },
        ],
      },
    ],
  },
]

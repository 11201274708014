import React, { PureComponent } from 'react'

import './index.css'
import { MediaIconSVG } from '..'

type Props = {}

class Footer extends PureComponent<Props> {
  render() {
    return <footer className="shape-footer">footer</footer>
  }
}

export default Footer

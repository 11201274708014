import { vientos1 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'vientos',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8 ',
      },
      {
        type: 'col',
        cols: 6,
        data: [
          {
            type: 'hero',
            cols: 11,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '6',
              },
            ],
          },
          {
            type: 'hero',
            cols: 11,
            title: [
              {
                text: 'VIENTOS FEMINISTAS SOPLARÁN EN TODO EL MUNDO',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'Desde CDD formamos parte de la delegación oficial mexicana en la Cumbre de Nairobi sobre la Conferencia Internacional de Población y Desarrollo (CIPD25) para impulsar el compromiso del Estado Mexicano con los DSR.',
              },
            ],
          },
          {
            type: 'text',
            cols: 11,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'Desde la reunión preparatoria en Puebla, las organizaciones feministas logramos una declaración que incluyó el compromiso para avanzar en la despenalización del aborto y la laicidad del Estado; y',
              },
              {
                type: 'mark',
                color: 'pink',
                text:
                  ' en Nairobi el Estado mexicano se pronunció a favor del derecho a la autonomía reproductiva de las mujeres.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 5,
        className: 'mt-10',
        data: [
          {
            type: 'img',
            hover: true,
            img: vientos1,
            cols: 12,
          },
        ],
      },
    ],
  },
]

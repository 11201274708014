import React from 'react'
import { animated } from 'react-spring'
import { Spring } from 'react-spring/renderprops'

import './index.css'

const HeroAnimation = () => {
  return (
    <Spring
      from={{ opacity: 0, color: 'red' }}
      to={{ opacity: 1, color: 'blue' }}
      config={{ tension: 1, friction: 2, precision: 0.1, duration: 500 }}
    >
      {({ opacity, color }) => (
        <animated.svg
          className="hero-illustration"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 540"
          style={{ opacity }}
        >
          <g id="arboles">
            <g>
              <g>
                <g>
                  <path
                    className="st0"
                    d="M349.45,302.29c0.26-0.87-0.77-1.56-1.64-1.83c-3.44-1.06-7.24-0.91-10.58,0.41
					c-0.64,0.25-1.3,0.58-1.65,1.17c-0.54,0.9-0.12,2.13,0.68,2.8c0.81,0.67,1.9,0.87,2.94,0.93c3.21,0.17,6.48-0.95,8.91-3.06"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M340.54,280.04c-0.26-2.36-0.51-4.77,0.02-7.09c0.22-0.93,0.57-1.86,1.23-2.54
					c0.66-0.69,1.67-1.1,2.59-0.85c1.21,0.33,1.9,1.7,1.83,2.95s-0.73,2.39-1.45,3.42c-0.94,1.34-2.01,2.59-3.2,3.72"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M334.64,288.46c-1.45-2.39-4.85-3.35-7.34-2.07c-0.71,0.37-1.36,0.91-1.7,1.63s-0.33,1.64,0.17,2.27
					c0.71,0.9,2.04,0.93,3.18,0.82c2.54-0.25,5.45-1.14,6.31-3.54"
                  />
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M704.98,166.74c-8.43,10.78-13.8,23.92-15.33,37.52c-0.56,4.94-0.62,9.94-1.49,14.84
							c-0.87,4.9-2.64,9.8-6.09,13.38c-2.62,2.71-6.03,4.5-9.38,6.23c-7.65,3.97-15.31,7.94-22.96,11.91
							c6.66-7.51,16.24-11.68,24.11-17.92c2.25-1.79,4.39-3.77,5.94-6.2c1.52-2.38,2.42-5.11,3.05-7.87
							c1.47-6.38,1.6-13.01,3.07-19.4c2.3-10,7.76-18.94,13.14-27.68"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M708.26,159.49c-0.35-1.56-1.29-2.92-2.21-4.23c-1.93-2.75-3.9-5.54-6.52-7.64
							c-0.63-0.51-1.32-1-1.68-1.72s-0.24-1.77,0.49-2.13c0.22-0.11,0.48-0.14,0.72-0.17c1.68-0.15,3.4,0.16,4.93,0.89
							c-0.02-0.59-0.04-1.19-0.06-1.78c4.31,3.9,6.06,10.4,4.29,15.93"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M708.96,159.76c0.62-5.41,2.93-10.57,6.29-14.87c1.1-1.4,2.45-2.8,4.2-3.11s3.86,1.05,3.63,2.82
							c-0.1,0.75-0.57,1.39-0.78,2.12c-0.21,0.72,0,1.71,0.73,1.88c0.36,0.09,0.73-0.05,1.08-0.17c1.4-0.47,2.96-0.64,4.33-0.09
							c1.37,0.55,2.45,1.96,2.28,3.43c-0.17,1.43-1.4,2.49-2.68,3.15c-2.99,1.54-6.48,1.61-9.83,1.86s-6.9,0.8-9.43,3.01"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M708.54,160.15c5.1-2.13,10.96-2.38,16.22-0.68c0.93,0.3,1.88,0.68,2.51,1.42
							c0.64,0.74,0.84,1.93,0.23,2.68c-0.27,0.33-0.67,0.54-1.06,0.72c-1.68,0.76-3.53,1.14-5.38,1.1
							c-0.28-0.01-0.62,0.01-0.74,0.26c-0.07,0.14-0.04,0.3-0.01,0.45c0.14,0.69,0.29,1.38,0.43,2.07
							c-0.97,0.04-1.94-0.25-2.73-0.81c-0.64-0.45-1.14-1.07-1.74-1.57c-0.59-0.5-1.27-0.89-1.95-1.28
							c-2.19-1.26-4.38-2.52-6.57-3.78"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M683.51,135.91c-3.89-3.88-9.4-5.81-13.24-9.74c-1.04-1.06-2-2.52-1.57-3.94
							c0.13-0.44,0.4-0.84,0.71-1.18c1.73-1.84,4.87-1.67,6.93-0.21c2.06,1.46,3.22,3.87,4.02,6.27c0.8,2.39,1.33,4.9,2.54,7.12"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M681.92,139.32c0.01-0.86-0.56-1.63-1.29-2.09s-1.58-0.64-2.42-0.8c-2.65-0.5-5.41-0.78-8.04-0.16
							s-5.13,2.28-6.19,4.77c-0.63,1.47-0.62,3.41,0.6,4.45c0.82,0.7,1.96,0.82,3.03,0.92c0.91,0.08,1.84,0.17,2.72-0.06
							c0.98-0.25,1.84-0.86,2.59-1.55c2.77-2.52,4.8-6.35,8.47-7.12"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M681.61,140.88c1.96,3.18,2.9,6.99,2.64,10.72c-0.14,2.04-0.46,4.52,1.19,5.73
							c1.55,1.14,3.93,0.13,4.83-1.57c0.89-1.7,0.67-3.77,0.2-5.63c-0.48-1.91-1.22-3.83-2.61-5.23c-1.78-1.8-4.55-2.71-5.57-5.03"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M683.39,137.83c5.54-1.44,11.08-2.88,16.62-4.33c1.73-0.45,3.5-0.9,5.27-0.69
							c1.77,0.21,3.56,1.24,4.14,2.92c0.59,1.71-0.24,3.7-1.68,4.81c-1.43,1.11-3.33,1.46-5.15,1.4c-1.81-0.07-3.59-0.52-5.37-0.87
							c-5.04-0.97-10.25-1.06-15.32-0.27"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M735.85,189.68c2.64-3,5.37-6.22,6.11-10.16c0.46-2.43,0.22-5.24,1.92-7.03c0.4-0.42,0.92-0.77,1.5-0.85
							c1.53-0.22,2.74,1.43,2.91,2.96c0.31,2.86-1.57,5.46-3.5,7.58c-2.69,2.96-5.7,5.64-8.94,7.99"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M737.89,189.39c4.49,0,8.96-1.48,12.57-4.15c2.15-1.6,4.02-3.61,6.41-4.84c0.41-0.21,0.88-0.4,1.32-0.27
							c0.44,0.13,0.73,0.56,0.91,0.99c1.05,2.49-0.46,5.46-2.7,6.96s-5.03,1.88-7.71,2.22c-3.34,0.43-6.69,0.86-10.03,1.29"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M737,192.5c-1.08,4.72-1.49,9.6-1.2,14.44c0.1,1.78,0.64,3.95,2.37,4.36c1.46,0.34,2.89-0.95,3.27-2.41
							c0.37-1.45-0.02-2.98-0.41-4.43c-1.21-4.56-2.36-9.13-3.44-13.72"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M736.83,192.56c-2.83-1.01-6.07,0.04-8.39,1.93c-1.4,1.14-2.6,2.67-2.87,4.45s0.6,3.82,2.29,4.45
							c2.2,0.82,4.57-0.95,5.74-2.99c0.84-1.45,1.33-3.08,1.76-4.7c0.41-1.57,0.75-3.16,1.03-4.76"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M718.7,253.56c-0.06-2.67-0.49-5.38-1.73-7.75s-3.38-4.35-5.99-4.94c-0.28-0.06-0.56-0.11-0.84-0.06
							c-0.64,0.11-1.1,0.71-1.32,1.33c-0.69,1.99,0.56,4.14,2.08,5.6c1.89,1.8,4.28,3.08,6.83,3.63"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M724.34,255.28c7.71,0.72,14.39-6.04,22.13-6.24c0.57,1.75-0.34,3.72-1.74,4.91
							c-1.4,1.19-3.21,1.78-4.98,2.31c-3.93,1.18-8.04,2.24-12.09,1.62"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M724.63,257.34c1.74,1.92,3.78,3.56,6.04,4.84c0.89,0.5,1.84,0.98,2.47,1.78
							c0.63,0.81,0.81,2.08,0.09,2.81c-0.37,0.37-0.89,0.53-1.38,0.69c-1.06,0.32-2.21,0.65-3.27,0.3c-0.6-0.2-1.11-0.59-1.58-1.01
							c-2.67-2.32-4.69-5.39-5.75-8.76"
                      />
                    </g>
                    <g className="st2">
                      <path
                        className="st3"
                        d="M703.85,286.04c-5.53,4.35-11.9,7.64-18.66,9.62c-5.21,1.53-10.75,2.33-15.35,5.22
							c-3.26,2.04-5.83,5.02-8.97,7.24c-3.98,2.81-8.73,4.33-12.82,6.97c-8.98,5.81-13.79,16.51-15.2,27.11
							c-1.41,10.6,0.09,21.34,1.13,31.98c1.83,18.74,5.87,37.92,6.27,56.74c1.53-26.07-4.2-59.61,0-86.64
							c0.98-6.31-1.25-8.11,2.15-13.53c4.6-7.33,11.82-12.57,19.02-17.38c13.64-9.12,27.85-17.4,42.51-24.77"
                      />
                    </g>
                    <g>
                      <path
                        className="st3"
                        d="M677.21,244.22c-3.17,3.18-6.92,5.79-11,7.66c-5.09,2.34-11.12,3.88-14.13,8.62
							c-2.3,3.63-2.38,8.58-5.51,11.52c-1.72,1.61-4.14,2.37-5.69,4.14c-1.45,1.66-1.9,3.93-2.31,6.09
							c-0.99,5.32-1.98,10.63-2.97,15.95c-0.4,2.13-0.47,4.92,1.52,5.79c1.5-2.04,2.34-4.48,3.26-6.84
							c3.66-9.38,8.89-18.04,14.1-26.65c1.72-2.85,3.45-5.7,5.45-8.37c4.99-6.64,11.55-11.9,18.03-17.09"
                      />
                    </g>
                    <g className="st2">
                      <path
                        className="st3"
                        d="M560.51,271.45c4.42,5.05,11.02,9.38,17.69,8.62c2.56-0.29,5.16-1.06,7.67-0.46
							c3.8,0.9,6.17,4.57,9.21,7.02c4.91,3.95,11.95,4.91,16.11,9.63c0.96,1.09,1.91,2.45,3.35,2.58c2.08,0.19,3.4-2.56,2.68-4.52
							c-0.72-1.96-2.62-3.19-4.4-4.28c-5.16-3.15-10.31-6.3-15.47-9.46c-2.81-1.72-5.64-3.44-8.68-4.71
							c-9.49-3.96-19.75-3.65-28.18-5.18"
                      />
                    </g>
                    <g className="st2">
                      <path
                        className="st3"
                        d="M577.38,213.17c6.73,2.59,10.94,6.6,15.03,12.54c1.74,2.53,3.16,5.3,5.15,7.64
							c2.64,3.12,6.14,5.37,9.62,7.5c3.39,2.08,6.82,4.11,10.28,6.09c3.26,1.86,6.58,3.7,9.35,6.23c2.77,2.54,4.97,5.9,5.34,9.63
							c0.03,0.29,0.04,0.6-0.09,0.86c-0.3,0.61-1.25,0.61-1.78,0.18c-0.53-0.43-0.75-1.13-0.97-1.77c-0.94-2.75-2.37-5.43-4.65-7.23
							c-1.65-1.3-3.64-2.07-5.57-2.89c-18.14-7.68-32.58-21.01-42.47-38.03"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M630.8,177.14c-2.69,2.66-5.32,5.54-6.68,9.07c-1.14,2.98-1.3,6.23-1.45,9.42
							c-0.04,0.94-0.08,1.93,0.31,2.79s1.35,1.54,2.26,1.28c0.88-0.25,1.33-1.2,1.77-2s1.25-1.64,2.13-1.4
							c1.41,0.38,1.07,2.78,2.34,3.51c0.52,0.3,1.18,0.22,1.72-0.04c1.23-0.59,1.89-2,1.96-3.37c0.06-1.36-0.37-2.69-0.84-3.97
							c-2-5.47-4.74-11.04-3.96-16.82"
                      />
                    </g>
                    <g className="st4">
                      <path
                        className="st5"
                        d="M629.57,173.45c0.67,0.04,0.72-0.99,0.49-1.62c-2.12-5.88-4.71-11.59-7.74-17.06
							c-1.55-2.8-3.24-5.61-3.78-8.77c-0.1-0.61-0.1-1.37,0.43-1.7c0.21-0.13,0.47-0.16,0.71-0.17c3.02-0.16,5.95,2.25,6.38,5.25
							c0.12-2.02,0.64-4.54,2.61-4.98c1.82-0.41,3.37,1.39,4.13,3.09c1.63,3.68,1.79,7.87,1.17,11.84
							c-0.62,3.97-1.99,7.78-3.35,11.57"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M631.48,175.29c5.86-7.74,11.07-15.98,15.53-24.6c0.17-0.33,0.37-0.69,0.73-0.79
							c0.55-0.17,1.05,0.37,1.32,0.88c1.35,2.55,0.85,5.95-1.18,8.01c1.46-0.7,2.92-1.41,4.38-2.11c0.34-0.17,0.75-0.33,1.09-0.15
							c0.29,0.16,0.39,0.51,0.46,0.83c0.84,4.23-1.58,8.44-4.52,11.6c-3.74,4.01-8.48,7.08-13.67,8.84
							c-1.65,0.56-3.45,0.99-5.11,0.47"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M591.08,144.38c-1.49-4.66-2.11-9.59-1.82-14.47c0.08-1.4,0.25-2.83,0.86-4.09
							c0.61-1.26,1.76-2.34,3.15-2.53c2.16-0.31,4.15,1.7,4.45,3.87c0.19,1.4-0.16,2.81-0.58,4.16c-0.85,2.68-2.03,5.24-3.2,7.79
							c-1.04,2.26-2.08,4.53-3.12,6.79"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M592.17,145.19c-3.9-2.41-8.5-3.36-12.78-5.02c-4.27-1.66-8.55-4.42-10.03-8.76
							c-0.6-1.75-0.44-4.12,1.23-4.91c0.57-0.27,1.22-0.3,1.85-0.27c3.37,0.17,6.47,2.05,8.75,4.53c2.29,2.48,3.86,5.51,5.31,8.56
							c0.83,1.75,1.71,3.6,3.35,4.64"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M556.42,164.12c-2.57,2.44-5.24,5.06-6.19,8.47c-0.51,1.81-0.49,3.72-0.43,5.59
							c0.08,2.45,0.41,5.27,2.46,6.61c0.41,0.27,0.89,0.47,1.39,0.5c0.96,0.06,1.87-0.52,2.43-1.3c0.56-0.78,0.81-1.74,0.96-2.69
							c0.52-3.26-0.04-6.58-0.12-9.87c-0.08-3.3,0.43-6.84,2.65-9.28"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M554.84,163.15c-7,1.42-14.32,2.99-19.79,7.58c-1.17,0.98-2.29,2.19-2.53,3.7
							c-0.24,1.51,0.79,3.29,2.32,3.31c0.86,0.01,2-0.37,2.37,0.4c0.3,0.63-0.34,1.28-0.6,1.93c-0.5,1.29,0.67,2.68,1.96,3.2
							c2.49,1,5.64-0.12,6.94-2.47c0.99-1.81,0.9-3.98,1.21-6.02c0.69-4.61,3.63-8.84,7.72-11.08"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M555.79,163.49c-4.67-6.34-11.89-10.74-19.67-11.98c-2.27-0.36-4.85-0.38-6.57,1.15
							c-0.81,0.72-1.33,2.02-0.68,2.88c0.33,0.43,0.86,0.64,1.36,0.83c3.05,1.15,6.11,2.3,9.16,3.45c1.88,0.71,3.78,1.42,5.77,1.71
							c2.66,0.38,5.47-0.02,7.98,0.95"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M556.68,161.8c0.96-2.15,1.84-4.43,1.72-6.78c-0.08-1.64-0.63-3.21-1.18-4.76
							c-1.85-5.23-3.93-10.78-8.4-14.06c-0.41-0.3-0.93-0.59-1.39-0.37c-0.28,0.13-0.45,0.42-0.57,0.7c-0.58,1.39-0.27,3,0.4,4.35
							s1.66,2.5,2.54,3.71c1.99,2.74,3.44,5.87,4.23,9.16c0.65,2.68,1.07,5.79,3.35,7.33"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M558.27,162.07c1.15-4.64,3.94-8.71,7.14-12.27c2.3-2.56,5.22-5.07,8.66-5.04c0.43,0,0.89,0.06,1.23,0.31
							c0.33,0.24,0.51,0.63,0.62,1.03c0.35,1.28,0.01,2.67-0.68,3.81s-1.68,2.05-2.71,2.89c-3.65,3.01-7.84,5.38-12.3,6.96"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M559.61,163.93c3.51-2.32,7.69-3.63,11.9-3.73c1.66-0.04,3.48,0.17,4.64,1.37
							c0.45,0.47,0.76,1.05,1.01,1.65c0.48,1.17,0.74,2.5,0.37,3.71c-0.37,1.21-1.5,2.25-2.76,2.21c-0.41-0.01-0.81-0.13-1.21-0.25
							c-4.97-1.49-9.95-2.98-14.92-4.47"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M527.08,239.76c0.85-6.45-1.32-13.01-4.79-18.52c-0.48-0.76-1-1.57-0.94-2.46
							c0.06-0.96,0.81-1.78,1.68-2.2c0.87-0.42,1.86-0.52,2.82-0.61c0.42-0.04,0.87-0.07,1.22,0.15c0.26,0.17,0.42,0.45,0.55,0.72
							c0.76,1.54,1.03,3.27,1.3,4.97c0.46,2.94,0.93,5.9,0.86,8.88c-0.06,2.98-0.68,6-2.24,8.54"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M528.82,240.27c5.71-5.43,12.07-10.81,19.82-12.26c0.83-0.16,1.69-0.26,2.51-0.06
							c0.82,0.2,1.6,0.77,1.83,1.58c0.27,0.96-0.27,1.97-0.91,2.74c-1.92,2.28-4.83,3.46-7.65,4.4c-5.28,1.76-10.72,2.98-16.15,4.2"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M523.17,246.95c-2.72-2-6.45-1.77-9.78-1.18c-2.02,0.36-4.01,0.82-5.99,1.37
							c-2.63,0.74-5.57,2.03-6.24,4.67c-0.14,0.57-0.16,1.19,0.11,1.71c0.64,1.27,2.44,1.26,3.84,1c5.57-1.03,10.95-3.13,15.75-6.14
							"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M524.48,246.69c-4.65,4.09-6.7,10.9-5.09,16.87c0.25,0.92,0.6,1.85,1.28,2.51
							c0.69,0.66,1.79,0.96,2.61,0.48c0.73-0.42,1.04-1.31,1.23-2.13c1.32-5.89-1-12.02-0.38-18.02"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M527.82,244.26c2.51,0.76,4.6,2.51,6.49,4.33c1.89,1.82,3.7,3.78,5.96,5.12c2.26,1.34,5.1,2,7.53,1
							c2.43-1,4.05-4.04,2.91-6.4c-1.06-2.19-3.76-2.93-6.16-3.39c-2.81-0.53-5.63-1.01-8.47-1.42c-3.88-0.57-8.19-0.93-11.39,1.33"
                      />
                    </g>
                    <g>
                      <path
                        className="st0"
                        d="M572.07,270.95c-0.42,0.17-0.89,0.04-1.31-0.14c-2.2-0.92-3.82-2.84-5.14-4.83
							c-0.54-0.82-1.06-1.7-1.19-2.68c-0.13-0.98,0.24-2.08,1.1-2.56c0.12-0.07,0.26-0.12,0.4-0.11c0.22,0.01,0.4,0.18,0.59,0.3
							c0.62,0.44,1.43,0.53,2.1,0.89c1.24,0.66,1.85,2.07,2.36,3.38c0.66,1.68,1.32,3.36,1.98,5.05c-0.29-1.54-0.44-3.12-0.43-4.69
							c0-1.39,0.14-2.84,0.85-4.03c0.71-1.19,2.13-2.06,3.49-1.73c0.52,0.13,1.02,0.44,1.29,0.9c0.2,0.34,0.27,0.74,0.3,1.14
							c0.11,1.57-0.46,3.1-1.13,4.52c-0.92,1.93-2.07,3.75-3.42,5.41"
                      />
                    </g>
                    <g>
                      <path
                        className="st0"
                        d="M597.6,185.85c0.58-1.04,0.15-2.33-0.36-3.4c-1.83-3.83-4.71-7.15-8.23-9.51
							c-0.39-0.26-0.8-0.51-1.26-0.56c-1.26-0.13-2.17,1.42-1.86,2.65c0.31,1.23,1.38,2.1,2.45,2.77c-0.81-0.44-1.75-0.73-2.65-0.54
							c-0.9,0.19-1.71,0.98-1.69,1.9c0.03,1.32,1.54,2.03,2.8,2.43c-0.69,0.25-1.41,0.53-1.89,1.09c-0.48,0.56-0.59,1.5-0.05,2
							c0.26,0.23,0.61,0.33,0.96,0.39c1.4,0.26,2.86,0.15,4.2-0.32c1.02-0.36,2.08-0.92,3.11-0.62c1.52,0.44,2.36,2.58,3.93,2.41"
                      />
                    </g>
                    <g>
                      <path
                        className="st0"
                        d="M676.21,279.9c-1.91,0.87-3.9,1.8-5.2,3.44c-0.81,1.02-1.46,2.37-2.73,2.65
							c-0.31,0.07-0.68,0.04-0.86-0.22c-0.25-0.36,0.04-0.83,0.1-1.27c0.12-0.94-0.94-1.64-1.88-1.67
							c-0.94-0.04-1.87,0.32-2.82,0.29c-0.95-0.02-2.02-0.68-1.94-1.62c0.05-0.55,0.47-0.99,0.9-1.34c3.19-2.6,7.64-2.85,11.76-2.83
							c1.23,0.01,2.7,0.17,3.26,1.26"
                      />
                    </g>
                    <g>
                      <path
                        className="st0"
                        d="M676.39,278.6c0.13-4.38,1.47-8.72,3.83-12.41c0.24-0.37,0.49-0.74,0.87-0.97c0.91-0.57,2.21,0,2.72,0.95
							c0.51,0.95,0.41,2.11,0.12,3.15c-0.13,0.46-0.19,1.13,0.27,1.26c0.28,0.08,0.55-0.12,0.79-0.29c0.79-0.57,1.71-0.97,2.67-1.14
							c0.31-0.06,0.63-0.09,0.93-0.01c0.6,0.17,0.99,0.77,1.12,1.38c0.23,1.08-0.18,2.22-0.87,3.08s-1.63,1.48-2.58,2.04
							c-3.77,2.22-7.99,3.68-12.33,4.25"
                      />
                    </g>
                    <g>
                      <path
                        className="st3"
                        d="M664.05,238.81c-2.09,0.72-4.39,0.57-6.52-0.03c-2.12-0.59-4.1-1.61-6.07-2.61
							c-0.7-0.36-1.48-0.84-1.55-1.62c-0.06-0.6,0.38-1.17,0.93-1.44s1.18-0.29,1.78-0.27c2.19,0.08,4.36,0.72,6.25,1.85
							c-0.96-0.85-1.96-1.76-2.38-2.98c-0.42-1.22-0.02-2.82,1.18-3.28c1.17-0.46,2.47,0.35,3.27,1.32c0.8,0.97,1.37,2.16,2.38,2.91
							c-0.6-1.15-1.02-2.43-0.93-3.72c0.09-1.29,0.79-2.59,1.95-3.15c0.4-0.19,0.91-0.28,1.26-0.01c0.16,0.13,0.26,0.32,0.36,0.51
							c2.01,4.1,1.65,9.27-0.91,13.05"
                      />
                    </g>
                    <g>
                      <path
                        className="st0"
                        d="M608.05,248.96c-3.43-0.24-7.19,0.54-9.45,3.14c-0.62,0.71-1.08,1.85-0.43,2.53
							c0.49,0.52,1.32,0.44,2.02,0.29c1.38-0.29,2.73-0.71,4.03-1.25c-0.29,1.39-1.63,2.53-1.44,3.94c0.14,1.02,1.14,1.79,2.17,1.86
							c1.03,0.07,2.03-0.45,2.74-1.2c1.95-2.03,1.82-5.22,1.56-8.03"
                      />
                    </g>
                    <g className="st4">
                      <path
                        className="st5"
                        d="M682.58,135.79c2.12-6.04,0.73-12.72-1.23-18.81c-1.28-3.99-2.81-8.21-1.75-12.26
							c0.24-0.93,0.68-1.9,1.52-2.37c1.42-0.8,3.2,0.17,4.28,1.39c1.59,1.79,2.49,4.18,2.47,6.58c-0.04-1.64-0.07-3.38,0.72-4.82
							c0.79-1.44,2.7-2.41,4.13-1.61c0.59,0.33,1,0.89,1.35,1.47c1.81,2.98,2.27,6.64,1.84,10.11c-0.43,3.46-1.7,6.76-3.15,9.94
							c-2.27,4.99-5.41,10.13-10.56,11.99"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g className="st2">
                    <path
                      className="st3"
                      d="M89.45,452.48c-1.96-0.16-3.92-0.32-5.88-0.48c-4.26-0.35-8.55-0.7-12.64-1.93
						c-4.09-1.23-8.01-3.43-10.58-6.84s-3.56-8.17-1.84-12.08c1.71-3.91,6.44-6.48,10.49-5.12c4.82,1.61,6.78,7.36,10.76,10.51
						c3.41,2.69,8.08,3.25,12.41,2.78s8.49-1.84,12.77-2.61c2.07-0.37,4.52-0.48,5.95,1.06c1.28,1.38,1.16,3.65,0.19,5.26
						c-0.97,1.61-2.61,2.7-4.26,3.61c-7.96,4.4-17.45,5.98-26.4,4.39"
                    />
                  </g>
                  <g className="st7">
                    <path
                      className="st5"
                      d="M82.32,383.92c0.21-2.59-1.66-4.83-3.48-6.68c-3.05-3.1-6.3-6.02-9.71-8.72
						c-3.06-2.42-6.39-4.84-7.95-8.41c-0.35-0.81-0.61-1.68-0.51-2.56c0.21-1.86,2.05-3.26,3.92-3.39c1.87-0.13,3.69,0.76,5.12,1.98
						c-0.83-2.17-1.3-4.87,0.21-6.64c1.58-1.85,4.71-1.65,6.59-0.1s2.74,4.01,3.15,6.41c0.51-2.32-0.66-4.99,0.59-7.01
						c0.76-1.23,2.24-1.84,3.68-1.95c3.5-0.28,6.84,2.03,8.57,5.08s2.09,6.7,1.9,10.2c-0.54,9.6-5.42,19.2-13.74,24.02"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M90.67,404.23c-1.52-2.49-4.31-3.89-7.05-4.89c-2.07-0.75-4.3-1.36-6.46-0.92
						c-2.16,0.44-4.19,2.2-4.25,4.4c-0.08,2.83,2.87,4.76,5.6,5.51c3.12,0.86,6.44,0.94,9.6,0.25"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M91.24,403.25c1.37-4.68,5.84-8.28,10.71-8.63c2.25-0.16,4.98,0.74,5.42,2.95
						c0.26,1.29-0.38,2.6-1.17,3.66c-1.95,2.63-4.86,4.42-7.93,5.55c-3.07,1.13-6.33,1.64-9.57,2.12"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st3"
                      d="M250.3,430.42c3.54,6.04,12.47,7.19,20.13,6.37c7.66-0.83,15.59-2.95,22.82-1.03
						c4.81,1.28,8.74,4.23,13.07,6.45c9.96,5.11,21.87,6.29,33.45,7.37c1.63,0.15,3.88-0.1,4.19-1.61c0.22-1.1-0.87-1.93-1.86-2.51
						c-10.43-6.03-22.28-10.14-31.83-17.26c-3.12-2.32-6.02-4.98-9.7-6.51c-5.55-2.3-12.15-1.76-18.34-0.65
						c-9.72,1.74-19.26,4.78-28.21,8.98"
                    />
                  </g>
                  <g>
                    <path
                      className="st8"
                      d="M363.29,449.96c5.58-1.1,11.23-2.22,16.42-4.53c4.25-1.89,8.07-4.54,12.26-6.55s9-3.39,13.46-2.44
						c2.18,0.47,4.15,1.46,6.09,2.44c5.23,2.66,10.46,5.31,15.69,7.97c0.4,0.2,0.83,0.44,0.92,0.85c0.18,0.77-0.9,1.3-1.76,1.49
						c-1.78,0.38-3.6,0.59-5.4,0.79c-17.74,1.91-35.59,2.36-53.25,1.37"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M359.87,451.58c2.71-2.63,6.01-4.75,9.61-6.14c2.74-1.06,5.75-1.78,7.93-3.69c0.62-0.55,1.2-1.35,0.9-2.05
						c-0.36-0.86-1.66-0.87-2.67-0.67c-3.18,0.64-6.15,2.07-8.97,3.66c2.73-4.17,4.49-8.83,5.13-13.56c0.2-1.47,0.28-2.99-0.28-4.33
						c-0.56-1.34-1.92-2.45-3.52-2.39c-2.61,0.09-4.12,2.87-5,5.18c-1.38,3.63-2.76,7.26-4.13,10.9c1.04-5.71-1.93-11.63-7.2-14.38
						c-3.72,5.74-2.41,12.74-0.97,19.01c0.71,3.11,1.86,6.68,5.14,7.62"
                    />
                  </g>
                </g>
                <g>
                  <path
                    className="st0"
                    d="M671.4,451.76c-11.33-7.88-19.45-20.24-22.19-33.76c5.64,2.86,10.71,6.84,14.84,11.64
					c-6.93-11.81-9.35-26.61-4.72-39.5c0.39-1.1,1.17-2.38,2.32-2.23c0.47,0.06,0.87,0.37,1.23,0.68c3.59,3.09,5.73,7.52,7.39,11.96
					c3.43,9.2,5.19,19.03,5.15,28.86c3.28-11.51,7.59-23.42,16.7-31.17c1.61,0.57,2.03,2.62,2.11,4.33
					c0.66,13.24-3.62,26.67-11.82,37.09c7.03-9.74,14.79-20.04,26.18-23.86c0.89-0.3,1.96-0.52,2.69,0.07
					c1.19,0.96,0.29,2.86-0.68,4.04c-10.65,12.93-24.15,23.49-39.25,30.71"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M190.94,344.61c-3.16-1.82-6.85-2.5-10.49-2.84c-4.81-0.45-9.72-0.37-14.37,0.91
					c-4.65,1.28-9.05,3.85-11.96,7.7c-0.69,0.92-1.32,1.95-1.42,3.1c-0.22,2.48,2.16,4.57,4.61,4.95c2.46,0.38,4.91-0.5,7.23-1.39
					c-0.98,1.38-1.99,2.83-2.2,4.51c-0.21,1.68,0.67,3.62,2.31,4c0.71,0.16,1.45,0.02,2.15-0.19c5.8-1.78,8.93-7.82,12.84-12.45
					c2.98-3.54,6.69-6.45,10.83-8.51"
                  />
                </g>
                <g className="st4">
                  <path
                    className="st5"
                    d="M192.9,345.18c-7.66,4.37-13.05,12.5-14.11,21.25c-0.25,2.08-0.24,4.31,0.83,6.1
					c1.37,2.29,4.47,3.35,6.96,2.39c2.49-0.96,4.06-3.84,3.53-6.46c0.43,1.94,1.21,4,2.95,4.95c2.04,1.11,4.79,0.14,6.13-1.77
					s1.47-4.45,0.9-6.71s-1.75-4.3-2.88-6.34c-2.4-4.3-4.63-8.68-6.7-13.15"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M261.8,363.8c1.83-3.71,5.63-6.01,9.44-7.63c2.17-0.92,4.44-1.69,6.79-1.8c2.35-0.11,4.81,0.51,6.56,2.08
					c0.65,0.58,1.21,1.34,1.26,2.21s-0.59,1.81-1.45,1.83c2.05,0.92,3.92,2.27,5.43,3.93c0.75,0.83,1.45,1.8,1.52,2.91
					c0.12,1.99-1.78,3.53-3.67,4.16c-1.46,0.49-3.02,0.67-4.55,0.51c-3.8-0.39-7.07-2.72-10.38-4.63
					c-3.31-1.92-7.25-3.51-10.87-2.29"
                  />
                </g>
                <g>
                  <g className="st4">
                    <path
                      className="st5"
                      d="M260.73,361.02c3.38-2.47,7.25-4.31,10.29-7.19c3.04-2.88,5.17-7.31,3.78-11.26
						c-0.31-0.88-0.81-1.73-1.58-2.27c-0.6-0.42-1.33-0.62-2.06-0.75c-3.97-0.74-8.98,1.03-9.63,5.02c0.2-1.7,0.09-3.54-0.87-4.95
						c-0.96-1.42-2.99-2.2-4.47-1.34c-1.69,0.98-1.83,3.34-1.61,5.28c0.38,3.3,1.22,6.55,2.48,9.62c1.07,2.59,2.47,5.21,2.26,8"
                    />
                  </g>
                  <g>
                    <path
                      className="st1"
                      d="M261.33,366.59c-0.09-2.71-2.43-4.76-4.68-6.27c-2.97-1.98-6.15-3.66-9.47-4.98
						c-1.77-0.71-3.62-1.32-5.52-1.26c-1.9,0.06-3.87,0.91-4.83,2.55c-0.83,1.42-0.76,3.31,0.17,4.66c0.93,1.35,2.67,2.09,4.29,1.83
						c-3.64,1.13-4.99,6.23-2.92,9.43s6.56,4.25,10.17,3.03c3.61-1.22,6.34-4.31,7.98-7.75"
                    />
                  </g>
                  <g>
                    <path
                      className="st1"
                      d="M189.79,344.52c1.56-5.69-1.12-11.58-3.63-16.92c-2.51-5.34-4.96-11.41-2.98-16.97
						c0.43-1.2,1.12-2.39,2.24-2.98c1.86-0.98,4.27,0.09,5.49,1.81c1.21,1.72,1.53,3.89,1.82,5.98c1.53-3.78,5.6-6.38,9.68-6.17
						c1.28,0.06,2.6,0.4,3.56,1.25c0.8,0.7,1.29,1.68,1.66,2.67c2.38,6.37,0.41,13.7-3.44,19.3c-3.85,5.6-9.36,9.81-14.77,13.92"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M215.26,408.85c-0.91-5.95-5.44-11.2-11.19-12.98c-2.38-0.74-5.02-0.9-7.34,0.03
						c-2.32,0.93-4.22,3.09-4.39,5.58c-0.18,2.51,1.38,4.89,3.43,6.34s4.54,2.13,6.98,2.71c3.48,0.82,7.64,1.3,10.16-1.22"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M213.9,390.92c0.95-2.93,2.03-5.85,3.73-8.42c1.7-2.57,4.07-4.78,6.98-5.8c1.65-0.58,3.92-0.5,4.62,1.1
						c0.24,0.54,0.24,1.14,0.17,1.73c-0.35,3.2-2.53,5.91-4.91,8.08c-3.01,2.75-6.48,4.98-10.23,6.57"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M215.64,446.1c-1.45-5.53-5.72-10.25-11.07-12.24c-1.51-0.56-3.18-0.92-4.73-0.49
						c-1.55,0.43-2.92,1.84-2.85,3.46c0.06,1.34,1.02,2.45,1.97,3.39c1.99,1.99,4.18,3.85,6.74,5.04c2.56,1.19,5.53,1.67,8.23,0.84"
                    />
                  </g>
                </g>
                <g>
                  <path
                    className="st0"
                    d="M354.11,293.03c-0.14-2.24-0.27-4.49,0.08-6.71c0.35-2.21,1.24-4.41,2.87-5.94
					c1.16-1.09,2.97-1.8,4.35-0.99c1.28,0.75,1.61,2.44,1.6,3.93c0,0.84-0.08,1.69-0.39,2.47c-0.42,1.05-1.24,1.89-2.04,2.69
					c-1.2,1.21-2.4,2.42-3.6,3.63c-0.65,0.66-1.37,1.35-2.28,1.52"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M325.19,260.53c1.86-3.27,5.82-4.6,9.45-5.59c3.63-0.99,7.61-2.13,9.71-5.25c0.52-0.77,0.91-1.67,0.91-2.6
					c0.01-1.74-1.36-3.27-2.99-3.9s-3.44-0.53-5.15-0.23c-0.17-1.25,0.65-2.52,0.36-3.75c-0.37-1.57-2.43-2.21-3.94-1.66
					c-1.51,0.55-2.58,1.89-3.57,3.17c0.17-0.84,0.33-1.71,0.14-2.54s-0.86-1.62-1.71-1.66c-0.52-0.03-1.01,0.21-1.45,0.49
					c-3.08,1.94-4.38,5.75-5.02,9.33c-0.39,2.16-0.61,4.35-0.68,6.55c-0.09,3,0.3,6.36,2.61,8.28"
                  />
                </g>
                <g className="st4">
                  <path
                    className="st5"
                    d="M323.17,261.25c-1.03-2.72-2.05-5.44-3.08-8.16c-1.09-2.89-2.2-5.81-4-8.32c-1.8-2.51-4.39-4.6-7.44-5.12
					c-0.76-0.13-1.58-0.15-2.28,0.18c-1.29,0.62-1.78,2.29-1.48,3.69c0.3,1.4,1.19,2.58,2.06,3.71c-1.83-1.21-4.4-1.19-6.21,0.05
					c-0.54,0.37-1.02,0.86-1.21,1.48c-0.29,1,0.31,2.09,1.16,2.69c0.85,0.6,1.9,0.83,2.92,1.04c-1.4-0.08-2.84,0.03-4.1,0.63
					c-1.27,0.59-2.33,1.73-2.58,3.11c-0.36,2.03,1.16,4.01,3.04,4.87s4.01,0.84,6.07,0.78c5.26-0.15,10.69-0.46,15.64,1.34"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M396.93,289.82c1.4-4.57,2.79-9.57,1-14c-0.21-0.52-0.51-1.06-1.04-1.24c-0.56-0.19-1.17,0.12-1.6,0.53
					c-1.21,1.15-1.47,2.96-1.55,4.62c-0.09,1.9-0.05,3.81,0.13,5.7c-0.56-1.24-1.17-2.54-2.31-3.29s-2.96-0.61-3.54,0.62
					c-0.42,0.88-0.06,1.95,0.49,2.76c1.51,2.23,4.2,3.29,6.73,4.22"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M452.46,290.8c0.37-2.13-0.95-4.15-2.31-5.83c-1.43-1.76-3-3.44-4.96-4.59c-0.81-0.48-1.75-0.86-2.68-0.7
					s-1.77,1.06-1.57,1.98c0.21,0.92,1.25,1.35,2.15,1.63c-1.53-0.48-4.65-0.45-5.75,0.95c-2.09,2.67,1.89,3.84,3.87,4.36
					c3.82,1.01,8.45,1.87,12.37,1.02c3.43-0.74,5.69-2.93,7.02-6.13c1.16-2.8,3.49-12.44-0.3-14.14c-1.02-0.46-2.27,0.02-2.99,0.86
					c-0.73,0.84-1.03,1.97-1.2,3.07c-0.27-1.01-0.56-2.07-1.26-2.84c-0.71-0.77-1.97-1.14-2.82-0.53c-0.73,0.52-0.9,1.53-0.86,2.42
					c0.04,0.89,0.23,1.8-0.01,2.66c-0.26-0.98-0.54-2.01-1.26-2.73c-0.72-0.72-2.02-0.96-2.72-0.23c-0.67,0.69-0.49,1.81-0.27,2.74
					c0.24,1.02,0.48,2.05,0.89,3.02c1.76,4.15,6.48,7,6.78,11.5"
                  />
                </g>
                <g className="st4">
                  <path
                    className="st5"
                    d="M455.31,289.29c3.91-0.79,6.18-4.79,7.81-8.42c1.63-3.64,3.54-7.71,7.31-9.01c1.07-0.37,2.34-0.45,3.27,0.2
					c1.07,0.75,1.37,2.27,1.04,3.54c-0.33,1.27-1.14,2.34-1.94,3.38c1.06-1.85,3.17-3.04,5.3-3.02c0.37,0,0.76,0.05,1.09,0.22
					c1.35,0.71,0.85,2.75,0.17,4.11c1.05-1.39,3.56-0.36,3.96,1.33c0.4,1.7-0.62,3.4-1.82,4.67c-4.08,4.29-10.3,5.72-16.19,6.3
					c-3.49,0.35-7.31,0.39-10.17-1.64"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M432.22,222.77c2.64,0.01,5.25,1.28,6.89,3.35c1.21,1.54,1.88,3.45,2.98,5.07
					c0.57,0.84,1.35,1.66,2.36,1.78c1.01,0.12,2.13-0.86,1.79-1.82c1.95,0.73,4.31,0.47,5.91-0.86c1.6-1.33,2.27-3.76,1.38-5.65
					c-1.05-2.2-3.73-3.11-6.17-3.18c-2.43-0.08-4.89,0.44-7.3,0.07c2.59-0.11,5.15-0.65,7.57-1.58c1.79-0.69,3.56-1.65,4.69-3.2
					c1.13-1.55,1.45-3.81,0.35-5.38s-3.8-1.86-4.91-0.3c0.92-0.55,1.87-1.13,2.46-2.03s0.71-2.19-0.02-2.98
					c-0.86-0.93-2.38-0.71-3.57-0.28c-1.94,0.69-3.76,1.72-5.24,3.15c-4.09,3.96-5.08,10.42-9.41,14.11"
                  />
                </g>
                <g className="st4">
                  <path
                    className="st5"
                    d="M431.11,220.58c1.01-5.62-0.74-11.54-4.15-16.12c-0.82-1.11-1.76-2.16-2.94-2.87s-2.65-1.04-3.97-0.64
					c-1.44,0.44-2.57,1.77-2.75,3.27s0.59,3.07,1.88,3.84c-0.91-0.36-1.86-0.73-2.84-0.67c-0.98,0.06-2,0.66-2.21,1.62
					c-0.27,1.24,0.85,2.31,1.86,3.09c2.4,1.87,4.85,3.76,7.69,4.85c1.35,0.52,2.79,0.86,3.96,1.71c1.05,0.77,1.81,1.92,2.1,3.19"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M430.6,223c-0.42-1.51-2.14-2.18-3.65-2.62c-5.45-1.57-11.55-2.81-16.6-0.21c-1.24,0.64-2.5,2.05-1.8,3.25
					c0.23,0.39,0.63,0.65,1.03,0.86c0.94,0.48,2,0.75,3.06,0.77c-1.7,0.25-3.48,1.7-3.11,3.38c0.3,1.34,1.85,2.07,3.21,1.93
					s2.56-0.92,3.69-1.69c0.04,0.96-0.37,1.88-0.53,2.83c-0.17,0.95,0.02,2.1,0.86,2.56c0.92,0.5,2.05-0.1,2.81-0.81
					c1.81-1.67,2.88-3.98,4.27-6.01s3.4-3.94,5.86-4.07"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M376.1,242.93c-2.11-1.59-4.36-3.4-4.9-5.98c-0.23-1.1,0.14-2.56,1.25-2.74c1.13-0.18,1.91,1.07,2.35,2.13
					c0.6,1.42,1.2,2.84,1.79,4.26c0.93-1.99,2.76-3.53,4.87-4.12c0.88-0.24,1.9-0.3,2.64,0.24c1.04,0.76,1.02,2.43,0.27,3.49
					s-1.99,1.63-3.19,2.1c-1.3,0.51-2.64,0.94-3.99,1.31"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M455.78,295.56c2.2,3.09,3.83,6.59,4.79,10.27c0.53,2.03,0.88,4.18,2.09,5.89
					c1.21,1.71,3.62,2.82,5.44,1.79c1.09-0.62,1.71-1.86,1.89-3.11s-0.03-2.5-0.23-3.74c0.45,1.4,1.62,2.54,3.03,2.96
					c0.28,0.08,0.58,0.14,0.86,0.09c0.52-0.09,0.94-0.5,1.19-0.96c0.79-1.44,0.26-3.24-0.51-4.69c-2.93-5.52-9.37-8.96-15.59-8.32"
                  />
                </g>
                <g>
                  <path
                    className="st1"
                    d="M430.94,220.79c1.87-7.7-5.87-15.24-4.25-22.99c0.18-0.87,0.56-1.8,1.37-2.16c1.4-0.62,2.82,0.88,3.38,2.3
					c0.53,1.35,0.8,2.81,0.77,4.27c0.04-1.97,0.42-4.03,1.68-5.54c1.27-1.51,3.63-2.22,5.27-1.14c1.57,1.03,1.93,3.17,1.94,5.05
					c0.05,7.69-3.41,15.34-9.21,20.39"
                  />
                </g>
                <g className="st2">
                  <path
                    className="st3"
                    d="M430.66,224.56c0.75,12-3.4,23.88-9.48,34.26c-6.08,10.38-14.04,19.5-21.68,28.78
					c-2.49,3.02-4.95,6.07-6.97,9.42c-3.34,5.55-7.83,12.13-9.82,18.29c-0.63,1.95-6.75,9,0,7c3.09-1.43,6.37-6.73,7.26-10.01
					c2.66-9.81,8.95-18.18,15.1-26.27c2-2.63,4-5.27,6.29-7.65c1.7-1.77,3.56-3.4,5.18-5.25c2.56-2.92,4.47-6.33,6.37-9.71
					c2.11-3.75,4.21-7.5,6.32-11.25c1.51-2.69,3.04-5.42,3.85-8.4c1.76-6.49-0.06-13.37-2.09-19.79"
                  />
                </g>
                <g className="st2">
                  <path
                    className="st3"
                    d="M325.35,261.6c2.1,4.89,4.3,9.74,6.61,14.53c1.59,3.29,3.29,6.65,6.07,9.02c1.92,1.65,4.25,2.74,6.25,4.29
					c2.8,2.18,4.82,5.16,7.15,7.83c2.57,2.94,5.55,5.53,8.84,7.65c1.68,1.09,3.45,2.07,4.9,3.45c1.24,1.19,2.21,2.63,3.16,4.06
					c2.45,3.69,4.91,7.37,7.36,11.06c0.93-1.05,0.26-2.69-0.45-3.91c-2.24-3.86-4.47-7.71-6.71-11.57
					c-1.43-2.46-2.88-4.95-4.88-6.97c-2.04-2.06-4.58-3.54-6.95-5.22c-3.03-2.15-5.79-4.65-8.54-7.15
					c-8.05-7.33-16.24-15.04-20.66-24.99"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M464.7,238.35c-1.39-2.37-1.96-5.22-1.59-7.95c0.16-1.14,0.83-2.52,1.97-2.39
					c0.72,0.08,1.21,0.76,1.51,1.42c0.54,1.19,0.77,2.52,0.66,3.82c0.69-1.29,1.59-2.58,2.92-3.18c1.34-0.6,3.19-0.25,3.82,1.08
					c0.43,0.91,0.17,2.03-0.42,2.84c-0.59,0.81-1.46,1.38-2.34,1.86c-1.51,0.83-3.12,1.47-4.78,1.93"
                  />
                </g>
                <g className="st2">
                  <path
                    className="st3"
                    d="M455.33,292.73c-2.27,4-5.58,7.4-9.51,9.79c-1.71,1.04-3.56,1.91-5.05,3.25c-1.19,1.07-2.1,2.4-3.21,3.55
					c-1.63,1.71-3.64,3-5.67,4.21c-10.22,6.11-21.33,10.56-32.38,14.99c-0.97,0.39-1.98,0.78-3.01,0.69
					c-1.25-0.11-2.4-1.01-2.82-2.2c2.66-2.08,5.85-3.34,8.99-4.57c4.15-1.62,8.29-3.24,12.44-4.87c6.49-2.54,13.05-5.12,18.79-9.07
					c5.46-3.77,10.02-8.68,14.57-13.51c1.62-1.73,3.61-3.59,5.97-3.36"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M439.24,306.05c0.31-2.67,0.17-5.38-0.41-8c-0.42-1.86-1.22-3.86-2.95-4.65c-1.04-0.47-2.23-0.41-3.37-0.35
					c-0.29,0.02-0.59,0.04-0.85,0.17c-0.45,0.23-0.68,0.74-0.8,1.23c-0.52,2.1,0.35,4.36,1.78,5.99s3.34,2.74,5.24,3.78"
                  />
                </g>
                <g className="st2">
                  <path
                    className="st3"
                    d="M376.33,338.89c4.79,7.15,6.56,15.95,6.84,24.55c0.28,8.6-0.83,17.18-1.3,25.78
					c-0.63,11.44-0.13,22.91,0.24,34.36c0.05,1.55,0.07,3.18-0.7,4.52s-2.65,2.16-3.92,1.28c-1.32-0.93-1.16-2.9-0.91-4.49
					c1.66-10.97-0.06-22.15,0.11-33.25c0.18-11.45,2.36-22.95,0.84-34.3c-0.62-4.6-1.84-9.2-1.35-13.82"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M302.76,414.08c9.69-1.44,19.57-1.57,29.3-0.38c2.83,0.35,5.8,0.79,8.46-0.25c2.06-0.81,3.67-2.41,5.4-3.79
					c3.87-3.1,8.44-5.17,12.73-7.63s8.49-5.51,10.87-9.86c-3.69,0.4-6.95,2.47-10.21,4.26c-11.55,6.32-24.54,9.51-37.49,11.84
					c-5.15,0.93-10.8,2.01-14.05,6.1"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M397.52,382.27c5.22-0.88,10.56-1.75,15.77-0.87c3.97,0.67,7.9,2.36,11.87,1.68
					c7.14-1.21,11.25-9.42,18.24-11.32c2.23-0.61,4.59-0.51,6.89-0.73c10.95-1.05,20.2-9.11,31.11-10.5
					c6.13-0.78,12.36,0.61,18.51-0.01c4.37-0.44,8.58-1.88,12.94-2.4c5.76-0.69,11.57,0.23,17.35,0.73
					c0.76,0.07,1.57,0.11,2.23-0.26c0.66-0.37,1.04-1.34,0.55-1.92c-0.24-0.28-0.61-0.4-0.96-0.51c-9.67-3.04-19.54-5.45-29.52-7.22
					c-4.51-0.8-9.05-1.46-13.62-1.66c-16.38-0.72-32.47,4.58-47.33,11.49c-14.87,6.91-28.94,15.48-44.01,21.94"
                  />
                </g>
                <g className="st2">
                  <path
                    className="st3"
                    d="M438.38,450.35c6.66-2.45,13.03-5.66,18.97-9.55c7.44-4.88,14.18-10.81,21.83-15.35
					c8.17-4.85,17.31-8.04,26.72-9.34c5.64-0.78,11.39-0.87,17.03-0.12c12.87,1.73,25.08,8.04,33.94,17.53
					c0.49,0.53,1,1.13,1.04,1.85c0.1,1.81-2.46,2.33-4.25,2.06c-8.04-1.21-15.46-5.05-23.4-6.83c-7.58-1.7-15.59-1.48-23.06,0.64
					c-5.04,1.43-9.81,3.7-14.8,5.28c-5.48,1.74-11.29,2.67-16.37,5.36c-3.52,1.86-6.62,4.52-10.32,6c-2.96,1.18-6.16,1.54-9.29,2.17
					c-3.6,0.72-7.14,1.8-10.78,2.23c-3.65,0.44-7.51,0.18-10.71-1.62"
                  />
                </g>
                <g>
                  <path
                    className="st8"
                    d="M556.09,412.56c5.59-2.49,11.2-5,17.12-6.57c5.58-1.49,11.62-2.25,16.15-5.82
					c2.72-2.14,4.66-5.13,7.35-7.31c2.87-2.33,6.46-3.63,9.28-6.01c2.78-2.35,4.63-5.59,6.41-8.76c1.82-3.22,3.64-6.44,5.46-9.66
					c-1.3-0.19-2.53,0.58-3.62,1.31c-19.07,12.86-38.17,25.75-55.81,40.5"
                  />
                </g>
                <g>
                  <path
                    className="st8"
                    d="M640.19,360.08c8.36-0.46,15.98,4.41,22.92,9.09c3.32,2.24,6.63,4.47,9.95,6.71
					c2.52,1.7,5.05,3.4,7.81,4.66c2.93,1.34,6.08,2.16,9,3.5c7.46,3.43,13.06,10.07,20.47,13.59c2.81,1.33,5.88,2.21,8.4,4.04
					c2.03,1.47,3.61,3.49,5.59,5.03c3.52,2.73,8.28,3.78,12.62,2.77c-0.16-0.9-1-1.5-1.76-2c-18.1-11.83-36.34-23.44-54.72-34.83
					c-3.08-1.91-6.16-3.81-9.24-5.71c-2.49-1.54-4.99-3.08-7.64-4.33c-6.85-3.22-14.67-4.37-22.16-3.27"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  className="st10"
                  d="M638.49,361.53l0.02,0.37C638.51,361.93,638.51,361.81,638.49,361.53z"
                />
                <path
                  className="st10"
                  d="M618.17,348.09c0.01,0.01,0,0.03,0,0.04C618.18,348.12,618.18,348.11,618.17,348.09z"
                />
                <path
                  className="st10"
                  d="M618.16,348.36c-0.03-0.04,0.02-0.15,0.01-0.23C618.16,348.15,618.14,348.17,618.16,348.36z"
                />
                <path
                  className="st10"
                  d="M589.92,302.4c-0.01-0.01-0.01-0.03-0.02-0.04C589.91,302.38,589.91,302.38,589.92,302.4z"
                />
                <path
                  className="st10"
                  d="M590.6,303.53l-0.02-0.01C590.52,303.5,590.56,303.52,590.6,303.53z"
                />
                <path
                  className="st10"
                  d="M593.21,302.48c-0.01,0.01,0.02-0.02,0.05-0.1L593.21,302.48z"
                />
                <path
                  className="st10"
                  d="M625.62,364.75C625.76,364.79,625.8,364.88,625.62,364.75C625.6,364.63,625.79,365.71,625.62,364.75z"
                />
                <path
                  className="st10"
                  d="M625.78,357.7l0.02-0.12C625.77,357.58,625.78,357.65,625.78,357.7z"
                />
                <path
                  className="st10"
                  d="M625.63,361.5c0,0,0,0-0.03-0.01C625.61,361.49,625.6,361.48,625.63,361.5z"
                />
                <path
                  className="st10"
                  d="M624.91,350.39l-0.01-0.03C624.89,350.3,624.9,350.35,624.91,350.39z"
                />
                <path
                  className="st10"
                  d="M625.61,363.31c0.01,0.01,0,0.03,0,0.04C625.62,363.34,625.62,363.33,625.61,363.31z"
                />
                <path
                  className="st10"
                  d="M625.6,363.58c-0.03-0.04,0.02-0.16,0.01-0.23C625.6,363.36,625.58,363.39,625.6,363.58z"
                />
                <path
                  className="st10"
                  d="M625.76,354.37c0-0.01,0,0.04,0.03,0.16L625.76,354.37z"
                />
                <path
                  className="st10"
                  d="M95.88,518.39C95.86,518.41,95.87,518.41,95.88,518.39L95.88,518.39z"
                />
                <path
                  className="st10"
                  d="M431.39,447.64c-0.04-0.05-0.06-0.07-0.05-0.1c0.08,0.13,1.82,0.92,4.16,2l-0.02-0.12
				c0.55,0.48,0.7,0.19,1.67-0.24c-0.14-0.1-0.1-0.07-0.69-0.07c0.16-0.49-0.4,0.1-0.23-0.28c-0.15,0.02-0.34-0.1-0.45-0.01
				c-0.08-0.17-0.43-0.13-0.34-0.33c-0.06,0.04-0.28,0.08-0.4,0.03c0.31-0.15-0.42-0.2-0.25-0.43c-0.25,0.15-0.59-0.13-0.91-0.15
				c0.02-0.01,0.07-0.03,0.09-0.01c-0.14-0.09-0.15-0.17-0.23-0.26c0.15,0.1-1.02,0.28-0.3-0.2c-0.06,0.15-0.39-0.01-0.38,0.14
				c-0.09-0.17-0.09-0.11-0.09-0.29c-0.01,0.29-0.86-0.21-0.37-0.33c-0.54,0.27-0.8-0.63-2.01-0.43c0.31-1.16,0.04-0.05-1.72-1.25
				l0.18-0.12c-0.87-0.24-1.12-0.81-4.54-2.81l0.08,0c-0.21-0.21-0.44,0.08-0.58-0.18l0.06,0.01c0.05-0.24-0.4-0.35-3.37-2.3
				c-0.21,0.23,0.39-0.14-0.95-0.47c0.04-0.05,0.14-0.02,0.22-0.01c-0.35-0.02,0.03-0.17-0.29-0.18c0.02,0.05-0.06,0.07-0.08,0.1
				c-0.08-0.15-0.36-0.3-0.59-0.38l0.09-0.07c-0.35-0.22-0.79-0.37-1.21-0.57c-0.75-0.3,0.19-0.3-6.2-2.23l0.03-0.01
				c-1.47-0.17,0.1-0.04-3.41-0.74c-0.02,0.11,0.02,0.18-0.21,0c-0.29,0.66-0.79-0.62-3.77,0.2l0.12-0.18
				c-0.74,0.26-1.57,0.21-2.27,0.55c0.03-0.05,0.02-0.1,0.1-0.11c-0.18-0.08-0.57,0.14-0.55,0.24c-0.3,0.08,0.05-0.24-2.04,0.79
				c-0.07-0.23-0.52,0.04-0.64,0.21c0.03-0.09-0.17,0-0.15-0.11c-0.18,0.09-0.33,0.21-0.25,0.3c-0.68,0.09-0.51,0.03-2.24,1.41
				c0.21-0.69,0.72-0.79-1.7,1.14c-0.01-0.02,0.01-0.07,0.04-0.11c-0.13,0.24,0.02-0.04-0.11,0.63c-0.06-0.32-0.39,0.21-0.44-0.22
				c-0.03,0.05-0.06,0.13-0.01,0.18c-0.08,0.04-0.13,0.02-0.11-0.09c-0.22,0.24-0.06,0.25-0.28,0.43c-0.03-0.04,0-0.09-0.01-0.16
				c-0.05,0.17-0.1,0.34-0.31,0.46c-0.01-0.03-0.05-0.04,0-0.09c-0.31,0.28-0.37,0.65-0.78,0.88c-0.04-0.25-0.16,0.05-0.3-0.07
				l-0.14,0.21l0.13,0c-0.16,0.14-0.21,0.37-0.39,0.45l0.07-0.16l-0.27,0.3l0.22-0.1c-0.24,0.64,0.15,0.12-1.08,1.16
				c0.02-0.19-0.4-0.07-0.32,0.26c0.01-0.03-0.01-0.03,0.02-0.08c-0.27,0.29-0.41,0.37-0.53,0.42l0.07-0.1
				c-0.52,0.26-0.27,0.63-2.25,2.32l0-0.09c-0.53,0.6-0.58,0.39-1.86,1.64c0.05-0.13,0.12-0.21-0.27,0.23
				c0.14,0.1,0.36-0.1,0.07,0.31c0.11-0.19-0.12-0.14-0.23-0.13c0.03,0.02,0.04,0.07,0.06,0.09c-0.34-0.07,0.04,0.12-0.24,0.25
				c0.04-0.06-0.36,0.55-0.23,0.08c-0.14,0.12-0.13,0.42-0.35,0.42c0.04-0.08,0.13-0.15,0.17-0.23c-0.24-0.01-0.23,0.03-0.36,0.1
				l0.01-0.01l-0.29,0.14l0.09-0.02c0.03,0.42-0.33,0.34-0.44,0.71c0.09-0.31,0.02-0.16-0.32-0.28c-0.05,0.18-0.26,0.06-0.32,0.32
				c0.01-0.1-0.92,0.9-2.2,1.6c-0.01-0.17-0.1-0.32-0.67,0.34l0.13-0.01c-0.07-0.17,0.13,0.27,0.75-0.44
				c-0.08,0.31,0.14,0.38,0.63-0.08c-0.01,0.05,0.1,0.11-0.01,0.14c0.33-0.06,0.35-0.39,0.54-0.32l-0.02,0.02
				c0.47-0.12,0.54-0.34,1.12-0.98c0.1,0.33,0.33-0.44,0.49-0.02c0.04-0.4,0.11,0.21,0.86-0.83c0.02,0.06,0.48-0.24,0.72-0.36
				l0.01,0.03c-0.08-0.17,0.15-0.32,0.27-0.48c0.07,0.09,0.07,0.14,0.02,0.25c0-0.04,0.08,0.03,0.23-0.35l0.05,0.13
				c0.18-0.16,0.27-0.44,0.56-0.57c-0.07-0.11-0.42-0.11-0.23-0.38c0.05,0.04,0.14,0.12,0.1,0.2c0.02-0.03,0.04-0.11,0.1-0.13
				l-0.02,0.18c0.02-0.08,0.31-0.3,0.12-0.03c0.11-0.07,0.26-0.13,0.35-0.11c0.02-0.45,0,0.01,0.66-0.88l0,0
				c0.06-0.33,0.9-0.56,1.92-1.73c-0.08,0.19,0.1,0.01-0.09,0.18c0.2-0.14,0.3-0.12,0.39-0.39c0.02,0.15,0.24-0.01,0.3-0.12
				l-0.18,0.05c0.76-0.35,0.05,0.2,1.87-1.73c-0.27-0.01,0.19-0.27,0.17-0.02l-0.02-0.06c0.12-0.03,0.35-0.24,0.36-0.12
				c0.09-0.16-0.01-0.12-0.13-0.06c0.27-0.13,0.34-0.3,0.59-0.46c-0.12,0.01,0.37-0.94,0.56-0.34c0.13-0.26,0.43-0.27,0.56-0.35
				l-0.08,0.13c0.05-0.08,0.1-0.12,0.15-0.17l-0.14-0.04c0.08-0.04,0.13-0.08,0.16-0.04c0.04-0.19-0.32-0.31,0.24-0.47
				c-0.29,0.16,0.08,0.11-0.06,0.28c0.36-0.14,0.22,0.03,0.43-0.49c0.02,0.1,0.17,0.08,0.2,0.03c-0.07,0.08-0.17-0.14,0.17-0.46
				l-0.12,0.06c0.29-0.43-0.11-0.1,1.39-0.68c-0.13-0.05-0.13-0.02-0.03-0.23l-0.33,0.33l0.15-0.31c-0.12,0.08-0.2,0.23-0.28,0.26
				c0.06-0.16,0.07-0.13-0.05-0.17c0.62-0.42,0.5,0.31,1.48-0.69l-0.15,0.03c-0.01-0.13,0.22-0.15,0.32-0.26
				c0.04,0.09,0.13,0.08,0.21,0.1c-0.04-0.06,0.27-0.15,0.21-0.34c0.41-0.04,0.26,0.1,0.77-0.53c-0.04,0.14,0.12,0.14-0.04,0.3
				c0.1-0.05,0.2-0.07,0.17-0.14c-0.14,0.12,0.43-0.13,0.49-0.57c0.31,0.07,0.49,0.05,1.96-0.56c0,0.03,0.01,0.05-0.03,0.05
				c0.64-0.27,0.03,0.03,1.1-0.17l-0.05,0.03c0.19,0.06,0.39-0.21,0.69-0.24c0,0-0.01,0.03,0.01,0.05c0.16-0.19,0.53-0.33,0.8-0.4
				c0,0.06,0,0.09-0.01,0.12c0.03-0.1,0.13-0.25,0.46,0.06l-0.23,0.03c0.74,0.25,0.73-0.34,2.32-0.35
				c-0.15,0.13-0.44,0.22-0.62,0.31c0.14,0,0.47-0.03,0.54-0.12c-0.06,0.02-0.13,0.06-0.18,0.03c1.15-0.21,0.05-0.12,3.1,0.46
				c0-0.27,0.16-0.3,0.35-0.22c-0.05,0.06-0.24,0.16-0.05,0.26c-0.13-0.12,0.16-0.12,0.14-0.21c0.19,0.11,0.37,0.32,0.43,0.55
				c-0.02-0.12,0.13-0.13,0.28-0.06l-0.2-0.19c0.43-0.07,0.29,0.32,0.72,0.25l-0.15,0.07c0.22-0.02,0.74,0.19,1.01,0.01
				c0.03,0.04,0.05,0.14-0.04,0.13c0.47,0.15,1.15,0.07,1.48,0.33l-0.06,0.06c0.3,0,0.08,0.11,0.65,0.15l-0.13,0.04
				c1.97,0.63,0.45-0.05,4.44,2.03c0-0.04,0.17-0.22,0.41,0.05c-0.07-0.04,0.21,0.62,0.57,0.18c0.03,0.36,0.74,0.29,2.49,1.75
				l-0.13,0.04c0.81,0.1,0.34-0.04,1.71,0.83c-0.02,0.02-0.07,0.07-0.13,0.04c0.17,0.11,0.58,0.55,0.68,0.26
				c-0.1,0.16,0.36,0.26,0.6,0.31l-0.11,0.1c0.03-0.04,0.3,0.04,0.88,0.27l-0.13,0.02c0.29,0.2,0.36-0.17,0.64,0.43
				c0.04-0.02,0.39,0.03,0.23,0.1c0.21,0.35,0.1-0.13,0.69,0.51c-0.25-0.16-0.45-0.3-0.42-0.3c-0.07,0.11,0.12,0.26-0.14,0.3
				c0.1,0.15,0.53-0.08,1.32,0.72c0.07-0.12,0.19-0.02,0.13-0.2c0.02,0.03-0.05-0.03-0.15-0.05l0.01-0.01
				c-0.07-0.04-0.16-0.09-0.26-0.15c0.11-0.09,0.11,0.26,0.57,0.03c0.1,0.55,0.12,0.49,1.39,1.12c-0.07-0.01-0.12-0.05-0.17-0.03
				c0.21,0.05,0.54,0.3,0.68,0.16c0.18,0.2-0.21,0.07,0.11,0.23l-0.07,0.02C430.12,447.38,430.88,446.93,431.39,447.64z
				 M426.93,444.26c0.04-0.01-0.04-0.1-0.08-0.17C426.79,444.09,427.16,444.2,426.93,444.26z M427.28,445.44
				c-0.02,0.02,0,0-0.06,0.04C427.15,445.46,427.06,445.32,427.28,445.44z M427.27,444.36c0.05,0.02,0,0,0.11,0.06
				C427.35,444.4,427.32,444.38,427.27,444.36z"
                />
                <path
                  className="st10"
                  d="M403.99,436.4C403.97,436.46,403.98,436.49,403.99,436.4L403.99,436.4z"
                />
                <path
                  className="st10"
                  d="M394.52,441.31c0.01-0.01,0.01-0.02,0.02-0.03C394.52,441.3,394.51,441.31,394.52,441.31z"
                />
                <path
                  className="st10"
                  d="M387.99,446.75c-0.01,0-0.02,0-0.03,0C387.97,446.74,387.96,446.76,387.99,446.75z"
                />
                <path
                  className="st10"
                  d="M383.89,449.45c0.01,0.03,0,0,0.02,0.06L383.89,449.45z"
                />
                <path
                  className="st10"
                  d="M394.72,439.65l0.02-0.03C394.79,439.55,394.75,439.6,394.72,439.65z"
                />
                <polygon
                  className="st10"
                  points="398.87,436.9 398.87,436.91 398.9,436.89 			"
                />
                <path
                  className="st10"
                  d="M391.3,444.12l0.12-0.18C391.44,443.93,391.38,443.98,391.3,444.12z"
                />
                <path
                  className="st8"
                  d="M400.59,437.52l-0.09-0.13C400.43,437.44,400.48,437.61,400.59,437.52z"
                />
                <path
                  className="st10"
                  d="M180.76,474.11c0.01-0.01,0.01-0.01,0.02-0.01C180.74,474.12,180.72,474.12,180.76,474.11z"
                />
                <path
                  className="st10"
                  d="M393.16,376.22l0.04-0.28C393.2,376,393.13,375.98,393.16,376.22z"
                />
                <path
                  className="st10"
                  d="M393.04,366.62c0.01,0,0.03,0.08,0.04,0.14C393.06,366.62,393.05,366.62,393.04,366.62z"
                />
                <path
                  className="st10"
                  d="M393.11,370.05C393.14,370.34,393.19,370.31,393.11,370.05L393.11,370.05z"
                />
                <path
                  className="st10"
                  d="M393.08,366.77c0,0.02,0,0.04,0,0.05C393.11,366.95,393.1,366.86,393.08,366.77z"
                />
                <path
                  className="st10"
                  d="M393.2,374.25C393.2,374.25,393.2,374.25,393.2,374.25c0,0.02,0,0.03,0,0.05L393.2,374.25z"
                />
                <path
                  className="st10"
                  d="M397.47,382.11l-0.13,0.09C397.4,382.17,397.46,382.14,397.47,382.11z"
                />
                <path
                  className="st10"
                  d="M397.34,382.21c-0.1,0.06-0.22,0.12-0.27,0.2L397.34,382.21z"
                />
                <path
                  className="st10"
                  d="M671.05,366.82l0.43,0.32C671.54,367.11,671.27,366.96,671.05,366.82z"
                />
                <path
                  className="st10"
                  d="M644.79,358.63c-0.11,0.02-0.19,0.05-0.27,0.08C644.55,358.71,644.62,358.71,644.79,358.63z"
                />
                <path
                  className="st10"
                  d="M696.24,382.86c-0.01-0.01-0.01-0.01-0.02-0.02C696.07,382.77,696.15,382.82,696.24,382.86z"
                />
                <path
                  className="st10"
                  d="M644.97,358.63l0.28-0.04C645.12,358.6,645.04,358.61,644.97,358.63z"
                />
                <path
                  className="st10"
                  d="M713.15,393.73c0.11,0.09,0.14,0.13,0.32,0.23C713.35,393.85,713.46,393.95,713.15,393.73z"
                />
                <path
                  className="st10"
                  d="M717.75,396.83l0.23,0.18C717.93,396.97,717.97,396.95,717.75,396.83z"
                />
                <path
                  className="st10"
                  d="M713.97,392.94l0.76,0.54C714.52,393.32,714.24,393.13,713.97,392.94z"
                />
                <path
                  className="st10"
                  d="M717.5,395.42l-0.49-0.4C717.13,395.09,717.01,395.13,717.5,395.42z"
                />
                <path
                  className="st10"
                  d="M662.56,362.33c-0.01,0-0.02-0.01-0.03-0.01C662.54,362.33,662.55,362.33,662.56,362.33z"
                />
                <path
                  className="st10"
                  d="M841.44,450.2l0.79,0.02c-1.22-0.22,0.04-0.06-7.73-0.33c0.81-0.2-1.6-0.15-1.37-0.41
				c-0.61,0.21-2.1-0.14-3.13-0.13c0.06-0.01,0.18-0.03,0.27-0.01c0.11-0.42-2.97,0.22-2.33-0.49c0.01,0.16-1.25,0-1.03,0.16
				c-0.52-0.19-0.45-0.13-0.71-0.34c0.36,0.32-2.98-0.31-1.62-0.41c-2.12,0.09-0.09,0.03-6.03-0.82c0.01,0.35,0.09,0.28-0.79,0.08
				c2.18,0.14-1.33-0.63-0.21-0.26c-0.88-0.97-2.12-0.34-6.87-1.68l0.36-0.11c-4.92-1.23-1.93-0.24-17.53-5.74l0.24,0.05
				c-0.89-0.43-1.14-0.14-1.94-0.61l0.17,0.04c-2.18-1.07-2.56-0.96-15.99-7.34c0.07-0.04,0.37,0.09,0.62,0.17
				c-1.75-0.75-0.33-0.14-3.27-1.58l0.19-0.02c-5.95-3.16-7.4-3.79-23.34-13.26l0.08,0.04c-3.95-2.4-0.09,0.14-9.79-6.44l0.03,0.12
				c-2.44-1.5-0.57-0.2-11.2-7.35l0.47,0.12c-2.24-1.28-4.34-2.95-6.51-4.23c0.15,0.05,0.18,0.01,0.38,0.15
				c-0.98-0.73-2.8-1.99-7.79-5.07c0.18-0.15-1.27-1.08-1.82-1.33c0.2,0.06-0.39-0.35-0.14-0.3c-0.57-0.38-1.11-0.69-1.07-0.53
				c-1.77-1.48-1.34-1.09-7.5-4.88c0.27,0.09,0.48,0.17,0.65,0.24c-1.09-0.67-1.25-0.89-6.45-4.12c0.01-0.02,0.17,0.06,0.31,0.13
				c-0.88-0.43,0.6,0.34-1.6-0.52c0.59,0.03-1.23-0.89-0.35-0.75c-0.18-0.09-0.41-0.19-0.41-0.1c-0.24-0.18-0.29-0.26,0-0.16
				c-0.94-0.54-0.67-0.24-1.5-0.77c0.03-0.04,0.21,0.05,0.32,0.05c-0.47-0.18-0.95-0.37-1.63-0.85c0.06,0,0-0.08,0.21,0.05
				c-1.21-0.75-2.19-1.08-3.46-1.99c0.25,0.03,0.46-0.03-1.13-0.93l0.24,0.25c-0.63-0.39-1.25-0.61-1.76-1l0.51,0.22l-1.19-0.69
				l0.63,0.47c-0.69-0.31-0.06,0.24-0.86-0.31c0.18,0.09-0.63-0.39-0.9-0.68l0,0c-1.34-0.63-0.27-0.55-2.98-1.73
				c0.46-0.04-0.84-0.75-1.2-0.75c0.09,0.04,0.06,0,0.24,0.09c-1.13-0.66-1.57-0.96-1.92-1.22l0.29,0.15
				c-1.41-0.91,0.1,0.05-5.03-2.99l0.24,0.17c-0.97-0.11-0.02-0.63-4.74-2.94l0.21,0.05c-2.64-1.47-1.91-1.39-7.48-4.27
				c0.35,0.11,0.44,0.14-1.12-0.54c0.07,0.16,0.16,0.15,0.43,0.37c-0.21-0.02-0.65-0.16-1.01-0.32c0.68,0.23,0.07-0.17-0.18-0.36
				c0.03,0.05-0.08,0.05-0.07,0.09c-0.68-0.53-0.22,0.15-1.2-0.44c0.16-0.08,0.39-0.1-2.41-0.95c0.27,0.06,0.64,0.2,0.91,0.27
				c-0.54-0.37-0.62-0.36-1.06-0.56l0.04,0.02c-0.33-0.16-0.68-0.26-1.03-0.4l0.26,0.12c-0.89,0.12-1.59-0.45-2.72-0.46
				c1.09,0.01,0.2-0.09,0.32-0.22c-0.26-0.1-0.67-0.23-0.54-0.24c-2.48-0.53-4.42-0.65-11.11,0.11c0.1-0.07-0.01-0.01,0.45-0.31
				c0,0-0.01,0-0.01,0c0.3-0.19-2.27,0.97-1.16,0.29c-0.65,0.21-1.68,0.51-1.84,0.69l0.43-0.06c-0.03-0.11,0.12-0.32,0.88-0.27
				l-0.4,0.14c0.5,0.19,1.63-0.52,2.49-0.62c-0.52,0.36-0.06,0.32,2.07-0.02c-0.11,0.06,0.11,0.13-0.25,0.15
				c0.45,0.01,0.85-0.18,2.11-0.11l-0.09,0.01c2.78,0.23,1.19-0.86,8.48,1.24c-0.09,0.16-0.16-0.15,1.8,0.51
				c-0.08,0.05,1.64,0.71,2.42,1.13l-0.04,0.02c0.23-0.1,1.04,0.31,1.67,0.55c-0.07,0.09-0.19,0.08-0.51-0.02
				c0.06,0.03,0.14,0.13,1.26,0.46c-0.69,0.06,0.46,0.28,2.92,1.32l-0.45-0.12c0.35,0.25,0.89,0.32,0.31,0.24
				c1.8,1.14,0.8,0.48,4.22,2.4l0,0c1.52,0.69,2.17,1.56,7.54,4.78c-0.59-0.27,0.15,0.18-0.59-0.3c0.69,0.47,0.8,0.65,1.64,0.99
				c-0.33-0.05,0.46,0.46,0.82,0.63l-0.46-0.38c0.39,0.21,0.72,0.42,1.06,0.64l-0.27,0.03c1.43,0.97,3.26,1.93,6.78,3.89
				c-0.45-0.78,1.52,1.43,1.34,0.9c0.81,0.6,1.34,0.82,2.19,1.41c0.04-0.09,2.66,1.13,1.85,1.29c0.86,0.41,1.45,1,1.89,1.31
				l-0.45-0.22c0.27,0.13,0.47,0.27,0.68,0.4l-0.18-0.25c0.24,0.17,0.44,0.31,0.41,0.34c0.72,0.11,0.25-0.26,1.53,0.73
				c-1.35-0.92-0.95,0.1,1.2,0.84c-0.18-0.01,0.14,0.29,0.32,0.39c-0.31-0.22,0.28-0.1,1.37,0.58l-0.37-0.28
				c1.9,1.05,2.69,2.12,8.05,5.42l-0.38-0.31c0.56,0.16,1.29,1.08,5.4,3.42c-0.35-0.11,0.05,0.25-0.65-0.13
				c1.59,0.92,1.07,0.36,8.6,5.61c-0.03,0-0.04,0.01-0.05,0.01c2.05,1.27-0.04,0.04,3.08,2.24l-0.18-0.1
				c0.77,0.6,0.1,0.13,4.79,2.87c-0.03,0.02-0.06,0.04-0.08,0.05c0.2,0.02,0.6,0.19,1.2,0.98l-0.69-0.46
				c0.25,0.39,0.9,0.6,1.24,0.87l-0.09,0.03c1.07,0.69,1.2,0.54,2.33,1.32l-0.08-0.13c1.71,0.89,0.4,0.81,3.55,2.3
				c-0.5-0.16-1.41-0.67-1.99-0.96c0.41,0.28,1.4,0.91,1.66,0.97c-0.18-0.09-0.41-0.19-0.56-0.32c1,0.34-0.2-0.19,9.15,6.26
				c-0.44-0.64,0.5,0.31,0.56-0.01c0.21,0.12,0-0.01,0.58,0.38c0,0-0.01-0.01-0.03-0.02c0.52,0.39,0.88,0.87,1.54,1.62
				c-0.06-0.17,0.39,0.06,0.86,0.41l-0.62-0.56c1.3,0.63,0.91,0.89,2.22,1.52l-0.44-0.16c1.17,0.78-0.11-0.05,7.6,4.43l-0.15-0.01
				c0.51,0.24,0.17,0.14,2.04,1.14l-0.36-0.12c1.44,0.97,2.4,1.16,3.68,1.85c-0.11,0.19,1.03,0.94,1.6,1.31l-0.04,0.02
				c3.08,1.44,8.83,5.02,19.45,9.79l-0.08,0.12c1.09,0.56,1.65,0.82,3.06,1.4l-0.33-0.03c2.78,0.84,1.29,0.41,6.16,2.39
				c-0.03,0.02-0.1,0.06-0.33-0.02c0.65,0.27,2.47,1.16,2.34,0.79c-0.06,0.19,1.43,0.6,2.22,0.79l-0.17,0.09
				c0.1-0.02,1.02,0.23,2.95,0.82l-0.35-0.03c1.31,0.27-0.26-0.01,6.12,2.14c-0.97-0.33-1.76-0.6-1.68-0.58
				c-0.04,0.12,0.74,0.41,0.06,0.37c0.66,0.17,0.01-0.1,2.65,0.67l-0.08-0.01c1.08,0.29,0.57,0.25,2.42,0.8
				c0.01-0.14,0.52,0.03,0.07-0.22c0.12,0.04-0.2-0.05-0.52-0.12l0.03,0c-0.26-0.08-0.6-0.17-0.99-0.28
				c2.95,0.39,0.55,0.55,7.56,2.07c-0.21-0.03-0.45-0.09-0.56-0.08c0.03,0.07,1.87,0.13,2.96,0.69l-0.18,0.01
				c2.33,0.81,3.98,0.42,6.57,1.3c-0.18-0.06-0.27-0.08-0.3-0.12c2.54,0.32-2.02,0.21,15.71,2.1l-0.2-0.12
				c0.58,0.09,1.85,0.05,1.74,0.16c0.46-0.25,5.26,0.73,4.17-0.04C843.67,451.02,843.88,450.29,841.44,450.2z M702.48,386.72
				l0.97,0.43c-0.41-0.27-0.91-0.51-1.14-0.69c0.47,0.19,0.41,0.19,0.27-0.02c0.71,0.5,1,0.8,1.3,1.1L702.48,386.72z M803.39,443.52
				c0.09,0-0.23-0.14-0.46-0.25C803.12,443.32,803.69,443.45,803.39,443.52z M806.21,445.25c-0.27,0.03-0.16,0.04-0.5-0.11
				C805.83,445.15,805.69,445.16,806.21,445.25z M804.52,443.79L804.52,443.79c0.05,0.01,0.1,0.03,0.14,0.04
				C804.62,443.82,804.58,443.81,804.52,443.79z"
                />
                <path
                  className="st10"
                  d="M705.96,387.72C706.59,388.08,706.57,387.98,705.96,387.72L705.96,387.72z"
                />
                <path
                  className="st10"
                  d="M720.1,398.37c-0.03-0.02-0.06-0.04-0.09-0.06C720.07,398.36,720.09,398.37,720.1,398.37z"
                />
                <path
                  className="st10"
                  d="M670.88,366.69c-0.02-0.02-0.04-0.04-0.06-0.06C670.85,366.65,670.82,366.65,670.88,366.69z"
                />
                <path
                  className="st10"
                  d="M662.68,361.69c-0.01-0.02-0.04-0.05-0.1-0.08C662.58,361.61,662.62,361.65,662.68,361.69z"
                />
                <path
                  className="st10"
                  d="M658.84,360.09c-0.13-0.07-0.31-0.13-0.54-0.23C658.48,359.93,658.67,360,658.84,360.09z"
                />
                <path
                  className="st10"
                  d="M655.72,359.15c0.01,0,0,0,0.08-0.05C655.75,359.12,655.73,359.14,655.72,359.15z"
                />
                <path
                  className="st10"
                  d="M699.82,383.73c0.04,0.02,0.07,0.04,0.11,0.06C700.19,383.92,700.02,383.82,699.82,383.73z"
                />
                <path
                  className="st10"
                  d="M686.93,375.99c0.15,0.06,0.3,0.12,0.45,0.2C687.16,376.04,686.98,375.93,686.93,375.99z"
                />
                <polygon
                  className="st10"
                  points="713.88,392.89 713.88,392.89 713.97,392.94 			"
                />
                <path
                  className="st10"
                  d="M687.38,376.19c0.18,0.12,0.39,0.27,0.59,0.37C687.76,376.39,687.57,376.28,687.38,376.19z"
                />
                <path
                  className="st10"
                  d="M641.84,359.33c0.01,0.01,0,0.02-0.01,0.03l0.16-0.06L641.84,359.33z"
                />
                <path
                  className="st10"
                  d="M641.67,359.41c0.08-0.02,0.14-0.04,0.16-0.06L641.67,359.41z"
                />
                <path
                  className="st10"
                  d="M641.33,359.55c0.11-0.06,0.23-0.09,0.34-0.13C641.55,359.45,641.39,359.47,641.33,359.55z"
                />
                <path
                  className="st10"
                  d="M646.53,358.39c0.31-0.01,0.73,0,0.93-0.07C647.19,358.37,647.4,358.27,646.53,358.39z"
                />
                <path
                  className="st10"
                  d="M646.53,358.39c-0.13,0.01-0.25,0.02-0.31,0.05C646.34,358.42,646.44,358.41,646.53,358.39z"
                />
                <path
                  className="st10"
                  d="M683.08,374.63l0.66,0.34C683.79,375,683.59,374.87,683.08,374.63z"
                />
                <path
                  className="st10"
                  d="M775.78,433.11l0.61,0.26C776.27,433.35,776.59,433.38,775.78,433.11z"
                />
                <path
                  className="st10"
                  d="M82.48,449.62l-0.13-0.11c0.39,0.04,1.24,0.01,1.17,0.12c0.31-0.24,3.52,0.63,2.8-0.12
				c0.52,0.08,0.63-0.63-1.01-0.66l0.53,0c-0.67-0.15-0.57-0.15-5.17-0.15c0.55-0.21-1.07-0.12-0.9-0.39
				c-0.41,0.2-1.4-0.06-2.1-0.03c0.04-0.01,0.12-0.04,0.18-0.02c0.11-0.39-2.16,0.25-1.52-0.45c-0.01,0.17-0.83,0-0.7,0.16
				c-0.33-0.19-0.29-0.12-0.44-0.32c0.21,0.33-1.97-0.26-1.04-0.37c-1.52,0.05-0.01,0.06-3.9-0.95c-0.05,0.27,0.01,0.33-0.56,0.03
				c0.98,0.21,0.12-0.37-0.07-0.27c-0.26-0.95-1.88-0.65-4.01-2.36l0.28-0.02c-2.85-2.87-2.6,0.18-5.8-10.03l0.05,0.16
				c0.02-0.64-0.35-0.7-0.25-1.31l0.03,0.11c0.06-0.42,0.23-5.31,2.25-7.43c-0.07,0.01,0.18-0.47,0.67-0.85
				c-0.28-0.02,0.29-0.13,1.73-1.46c0.01,0.07-0.15,0.2-0.26,0.33c0.94-0.71,0.13-0.19,2.01-1.02l-0.06,0.13
				c4.13-0.75,3.91-0.8,16.91,3.55l-0.06,0c1.21,0.09,2.44,0.89,5.25,0.99c-0.17,0.24-0.1,0.6,0.1,0.74c-0.01,0-0.02-0.01-0.07-0.02
				l0.08,0.05c0-0.02,0-0.02-0.01-0.02c0.13,0.13,0.03-0.06,0.68-0.2l-0.03,0.14c0.12-0.05,0.05-0.13,0-0.2
				c0.11-0.05,0.24,0.05-0.03-0.26c0.5,0.11-0.17-0.26,0.23-0.15c-0.01-0.03-0.02-0.05-0.02-0.08c0.58-0.01,1.21-0.04,1.91-0.12
				l-0.08-0.07c1.71-0.49,0.53-0.13,8.11-4.31l-0.16,0.31c1.3-1.15,2.86-2,4.23-2.89c-0.47,0.68,1.53-1.19,5.44-1.4
				c-0.05,0.23,1.01,0.26,1.39,0.3c-0.14,0.04,0.3,0.15,0.13,0.2c0.42,0.07,0.78,0.21,0.76,0.07c1.8,1.21,1.57,0.47,4.66,3.23
				c-0.19-0.11-0.33-0.19-0.43-0.25c0.5,0.71,0.89,0.59,2.58,4.19c0.03-0.02,0.01-0.16,0.6,0.71c-0.36-0.25,0.18,0.98-0.31,0.55
				c0.04,0.12,0.12,0.27,0.18,0.23c0.01,0.19-0.01,0.26-0.12,0.08c0.17,0.67,0.27,0.41,0.33,1.04c-0.05,0-0.08-0.12-0.14-0.18
				c0.12,0.3,0.24,0.62,0.23,1.16c-0.03-0.03-0.06,0.03-0.07-0.13c-0.08,0.54,0.27,0.35-0.07,2.6c-0.07-0.18-0.22-0.25-0.42,0.88
				l0.14-0.19c-0.1,0.47-0.14,0.91-0.32,1.28l0.04-0.36c-0.09,0.29-0.15,0.58-0.27,0.86c0.09-0.15,0.18-0.3,0.25-0.45
				c-0.07,0.5,0.25,0.04-0.04,0.62c0.03-0.13-0.2,0.45-0.4,0.64l0,0c-0.34,0.99-0.37,0.12-1.08,2c-0.08-0.35-0.57,0.47-0.57,0.75
				c0.03-0.06,0-0.04,0.06-0.16c-0.52,0.64-0.76,0.91-0.95,1.1l0.1-0.14c-0.74,0.8,0.07-0.05-2.72,2.75l0.16-0.11
				c-0.81,0.87-0.8,0.23-3.04,2.1l0.08-0.12c-1.76,0.97-1.36,0.69-5.25,2.26c0.28-0.19,0.44-0.26-0.77,0.3
				c0.12,0.08,0.16,0.02,0.4,0.02c-0.1,0.11-0.36,0.27-0.61,0.36c0.42-0.24-0.07-0.17-0.29-0.16c0.04,0.02-0.01,0.08,0.02,0.11
				c-0.59,0-0.01,0.25-0.77,0.39c0.02-0.13,0.09-0.26-1.63,0.58c0.16-0.1,0.41-0.19,0.57-0.28c-0.45,0-0.48,0.05-0.79,0.13
				l0.03-0.01l-0.71,0.17l0.19-0.02c-0.4,0.51-1.02,0.41-1.64,0.85c0.61-0.42,0.07-0.16,0.08-0.32c-0.19,0.02-0.47,0.06-0.41-0.01
				c-0.22,0.09-0.21,0.29-1.29,0.34c1.38-0.2-2.1,0.92-5.83,1.49c0.17-0.33,0.79-0.42-1.74,0.13l0.29,0.04
				c-0.02-0.11,0.12-0.3,0.6-0.09l-0.28,0.05c0.31,0.27,1.13-0.12,1.69-0.18c-0.11,0.19-0.1,0.32,1.4,0.05
				c-0.07,0.06,0.09,0.13-0.16,0.14c0.74,0.01,1.11-0.37,1.41-0.26l-0.06,0.02c2.11-0.29,1.82-0.71,5.63-1.58
				c0.03,0.22-0.22-0.03,1.17-0.46c-0.02,0.07,1.17-0.31,1.75-0.46l-0.01,0.03c0.04-0.21,0.63-0.39,1.04-0.59
				c0.02,0.11-0.04,0.18-0.25,0.31c0.04-0.05,0.06,0.07,0.82-0.43c-0.16,0.44,0.23,0.04,1.94-0.94l-0.24,0.22
				c0.32-0.07,0.13-0.15,0.37-0.24c0.03,0.12,0.42,0.03-0.11,0.2c1.34-0.43,0.57-0.14,2.99-1.37l0,0c0.46-0.64,1.5-0.29,4.94-3.45
				c-0.26,0.36,0.16-0.05-0.29,0.35c0.45-0.35,0.6-0.38,0.92-0.92c-0.06,0.24,0.42-0.2,0.57-0.42c-0.11,0.08-0.23,0.15-0.34,0.23
				c0.2-0.23,0.38-0.42,0.56-0.62l0.03,0.22c0.96-1.52,1.76-1.83,2.74-4.6c-0.57,0.37,0.89-1.18,0.46-1.01
				c0.32-0.63,0.32-1.04,0.54-1.72c-0.06,0.02-0.19-2.05,0.34-1.54c-0.09-0.67,0.15-1.24,0.12-1.63l0.07,0.35
				c-0.04-0.21-0.05-0.38-0.07-0.56l-0.1,0.21c0.01-0.21-0.01-0.38,0.04-0.38c-0.3-0.41-0.33,0-0.36-1.15
				c0.15,1.13,0.7,0.37-0.17-1.01c0.12,0.08,0.11-0.23,0.05-0.36c0.05,0.27-0.22-0.03-0.57-0.89l0.09,0.31
				c-0.48-0.38-0.3-1.46-1.48-3.44c0,0.24,0.06,0.24-0.32-0.08c0.25,0.28,0.46,0.6,0.69,0.89l-0.54-0.51
				c0.18,0.28,0.45,0.56,0.54,0.74c-0.27-0.24-0.22-0.22-0.22-0.04c-0.88-1.32,0.08-1.19-2.3-3.15l0.22,0.26
				c-0.89-0.5-0.53-0.92-3.8-2.2c0.27-0.01-0.06-0.21,0.49-0.08c-2.2-0.12-1.27-1.04-7.01-0.03c0.01-0.02,0.01-0.03,0.01-0.03
				c-1.48,0.72-0.06-0.16-2.43,0.95l0.11-0.08c-0.56,0.3-0.27-0.03-3.03,2.27c-0.02-0.05-0.02-0.07-0.03-0.1
				c-0.03,0.14-0.21,0.39-0.93,0.5l0.46-0.33c-0.34,0.02-0.59,0.42-0.85,0.56l-0.01-0.09c-0.69,0.51-0.58,0.68-1.35,1.18l0.11,0
				c-0.96,0.88-0.62-0.08-2.37,1.52c0.23-0.28,0.79-0.69,1.1-0.99c-0.28,0.17-0.93,0.58-1.04,0.73c0.11-0.08,0.23-0.2,0.35-0.23
				c-0.28,0.31-0.69,0.67-1.29,0.92c0.1-0.59-1.07,0.57-4.08,0.71c0.01-0.09,0.03-0.19,0-0.28c0.02,0.01,0.05,0.04,0.03,0.06
				c0.04-0.1,0.13-0.11,0.18-0.17c-0.19-0.06-0.2-0.23-0.3-0.34c0.21-0.08,0.21,0.13,0.39,0.13c-0.16-0.03-0.11-0.26-0.25-0.24
				c0.13-0.07,0.07-0.07,0.24-0.09c-0.24-0.07-0.02-0.1-0.15-0.21c0.13-0.01,0.09-0.3,0.34-0.06c-0.38-0.15,0.32-0.48-0.32-1.33
				c-0.01,0.04,0.03,0.05,0.07,0.08l-0.04-0.08c0.19,0.4,0.34-0.26,0.19-0.01c0.75,0.24-0.21-1.23,0.38-1.18
				c-0.07-1.49-0.02-0.43,0.35-3.34l0.04,0.05c0.08-0.17-0.28-0.23-0.13-0.38l0.02,0.03c0.25-0.08,0.08-1.17,0.32-3.18
				c0.02,0,0.03,0.02,0.05,0.03c0.03-0.04,0.21-0.01-0.02-0.14c0.1-0.08,0.1-0.28,0.06-0.43l0.11,0.03
				c0.03-0.26-0.04-0.54-0.04-0.83c-0.06,0.03-0.42-0.05-0.09-0.07c-0.1-0.04-0.35-0.09,0.05-0.04l-0.06-0.14
				c0.05-0.01,0.07,0.02,0.14,0.03c-0.1-0.06,0.16-0.23-0.01-0.31c0.09-0.2-0.08-0.97-0.32-1.55c0.13-0.02,0.11-0.23,0.2-0.12
				c-0.03-0.26-0.04-0.33-0.16-0.65c-0.08,0.08-0.2,0.03,0.01-0.06c-0.21-0.35,0.34-0.87,0.05-1.14l0.01,0.02
				c-0.16-0.79-0.09-0.04-0.05-2.13c-0.07,0-0.2,0.05-0.03-0.12c-0.17,0.63-0.34-1.23-0.39-3.38c0.11,0.27,0.19-0.19,0.38,0.02
				c-0.11-0.23,0.15-0.33,0.17-0.54c0.01,0.02,0.02,0.06,0,0.06c0.08-0.06,0.15-0.03,0.22-0.04c-0.14-0.14-0.07-0.28-0.1-0.42
				c0.22,0.04,0.12,0.21,0.28,0.3c-0.12-0.1,0.02-0.26-0.1-0.32c0.15,0.01,0.09-0.02,0.24,0.06c-0.17-0.18,0.03-0.09-0.03-0.25
				c0.11,0.06,0.21-0.19,0.31,0.13c-0.23-0.31,0.49-0.22,0.37-1.21c-0.03,0.03,0,0.06,0.01,0.1l0.01-0.08
				c-0.04,0.4,0.4,0.01,0.15,0.1c0.44,0.62,0.51-1.05,0.92-0.62c0.82-1.02,0.16-0.38,2.32-1.86l-0.01,0.06
				c0.17-0.03,0-0.35,0.19-0.31l-0.01,0.04c0.25-0.06,0.23,0.22,1.89-0.9l0.13,0.15c0.08-0.34,0.01,0.24,0.59-0.31
				c0,0.02,0,0.04-0.01,0.05c0.05,0.01,0.1,0.19,0.11-0.06c0.1,0.07,0.26,0.01,0.38-0.06l0,0.11c0.23-0.03,0.45-0.17,0.7-0.22
				c-0.1-0.03-0.12-0.21-0.05-0.25l0.06,0.15c0.02-0.1,0-0.36,0.04,0.05l0.1-0.08c0.02,0.05-0.01,0.08,0,0.14
				c0.03-0.11,0.22,0.13,0.26-0.06c0.2,0.05,0.81-0.21,1.31-0.41c0.01,0.13,0.2,0.12,0.1,0.21c0.22-0.02,0.28-0.03,0.57-0.11
				c-0.06-0.08-0.01-0.21,0.05,0.01c0.34-0.17,0.67,0.45,0.95,0.23l-0.02,0.01c0.15-0.07,0.58,0.15,1.43,0.74
				c0.17-0.7,0.49,0.81,0.02,2.34c-0.02-0.04-0.07-0.06-0.05-0.09c-0.14,0.02-0.14,0.25-0.06,0.3c0.08-0.12-0.37,0.35-0.55,0.98
				c-0.17-0.15-0.3,0.08-0.28,0.22c-0.03-0.07-0.1,0.01-0.15-0.08c-0.06,0.08-0.1,0.17,0,0.24c-0.32-0.05-0.12,0.43-0.44,0.26
				c-0.14,0.3-0.32,0.55-0.52,0.77c-0.11-0.53,0.46-0.55-0.95,0.56c-0.01-0.02,0-0.05,0.01-0.08c-0.04,0.03-0.11,0.03-0.06,0.11
				l0.03-0.05c0.14,0.09-0.01,0.36,0.02,0.44c-0.07-0.3-0.25,0.06-0.31-0.33c-0.02,0.03-0.03,0.09,0.01,0.15
				c-0.05,0.01-0.08-0.02-0.08-0.11c-0.12,0.13-0.02,0.2-0.16,0.27c-0.02-0.04,0-0.08-0.02-0.14c-0.09,0.16-0.08,0-1,0.5l0.08,0.06
				c-0.11,0.04-0.16,0.21-0.28,0.19l0.06-0.1l-0.2,0.11l0.14,0.02c-0.17,0.21,0.16,0.28-0.53,0.08c-0.01,0.17-0.25,0.13-0.27,0.31
				c0.01-0.04-0.02-0.14-0.03-0.16l-0.01,0.14c-0.16-0.06-0.13,0.06-0.55,0.05l0.06-0.05l-0.09-0.06l0,0.1
				c-0.08-0.03-0.55,0.2-1.66,0.51c0.14-0.04-0.3-0.26-0.4,0.1c-0.31-0.09-0.32-0.42-1.21,0.39c0.1-0.1-0.03-0.18-0.09-0.24
				c0.01,0.03,0,0.07,0.01,0.11c-0.14-0.27-0.05,0.18-0.19,0.07c0.02-0.02-0.33,0.25-0.14-0.05c-0.3,0.09,0.03,0-0.54-0.07
				c0.07,0.42,0.02,0.03-0.34,0.39c0.11-0.22,0.07-0.08-0.11-0.4c-0.07,0.13-0.15-0.08-0.24,0.12c-0.01-0.03-0.04-0.02-0.06-0.04
				c-0.01,0.07-0.27,0.19-1.26,0.31c0.09-0.29-0.32,0.15-0.09-0.23c-0.09,0.04-0.19,0.12-0.29,0.15c-0.01-0.09,0.07-0.12,0.11-0.17
				c0.01,0.04-0.26-0.02-0.27,0.22c0.21-0.33-0.17,0.33,0.49,0.02l-0.07,0.2l0.15-0.16c0.03,0.06,0.04,0.17-0.03,0.2
				c0.08,0.09,0.03,0.12,0.27,0.02c-0.02,0.04,0.02,0.15-0.05,0.12c0.15,0.06,0.45,0.17,0.54-0.33l0.15,0.37l0.01-0.15
				c0.03,0,0.08,0.01,0.07,0.07c0.09-0.11-0.02-0.06,0.02-0.17c0.14,0.13,0.33-0.1,0.39,0.14c0.03-0.12-0.13-0.07-0.01-0.17
				c-0.02,0.32,0.3-0.2,0.27,0.22c0.1-0.32,0.29,0.26,0.65-0.24c-0.01,0.09,0.02-0.06,0.47,0.12c0-0.19,0.15-0.18,0.25-0.23
				c0.01,0.11,0,0.16-0.05,0.21c0.01-0.02,0.07,0.04,0.21-0.15l0,0.14c0.14-0.03,0.25-0.21,0.44-0.15c-0.01-0.13-0.2-0.33-0.03-0.44
				c0.02,0.07,0.05,0.18,0,0.22c0.02,0.01,0.15-0.14,0.03,0.08c0.04-0.06,0.24-0.07,0.07,0.05c0.08,0,0.17,0.05,0.22,0.11
				c0-0.07,0.05-0.3,0.18-0.01c0.02-0.07,0.12-0.02,0.1-0.17c0.04-0.01,0.06,0.06,0.08,0.1c0.05-0.09,0,0,0.21-0.24l0,0
				c0.13-0.24,0.62,0.09,1.47-0.26c-0.08,0.11,0.06,0.07-0.09,0.09c0.15,0.01,0.2,0.08,0.31-0.09c-0.02,0.14,0.14,0.13,0.2,0.07
				l-0.12-0.06c0.43,0.02,0.08,0.13,0.92-0.1c-0.49-0.41,0.72-0.35,0.62-0.25l0-0.06c0.08,0.04,0.25-0.04,0.25,0.07
				c0.07-0.09,0.01-0.11-0.08-0.11c0.19,0.02,0.25-0.09,0.44-0.11c-0.1-0.03,0.33-0.65,0.4-0.06c0.1-0.17,0.31-0.06,0.41-0.08
				l-0.06,0.08c0.04-0.05,0.07-0.07,0.11-0.09l-0.1-0.08c0.24,0.01,0.11,0.09,0.28-0.3c-0.27,0.09,0.24,0.55,0.25-0.08
				c0.02,0.09,0.13,0.12,0.15,0.09c-0.03,0.05-0.15-0.12,0.09-0.35c-0.08-0.01-0.15,0.02,0.02-0.17c0.04,0.05-0.05,0.08,0,0.09
				c-0.02-0.35,0.91,0.25,0.76-0.4l-0.18,0.23l0.05-0.25c-0.07,0.05-0.1,0.16-0.16,0.17c0.02-0.13,0.03-0.1-0.06-0.16
				c0.4-0.4,0.3,0.48,0.92-0.4c-0.1-0.02-0.19,0.02,0.05-0.19c0.06,0.08,0.12,0.07,0.19,0.09c-0.05-0.05,0.15-0.12,0.03-0.29
				c0.26-0.04,0.26,0.1,0.31-0.47c0.04,0.12,0.15,0.1,0.11,0.25c0.08-0.21-0.01,0.05,0.11-0.64c0.26,0.02,0.37-0.04,0.85-0.88
				c0.01,0.01,0.02,0.02,0.03,0.02c0.12-0.42,0.08,0.04,0.42-0.53l0,0.04c0.15-0.06,0.01-0.3,0.1-0.45c0,0,0.02,0.02,0.05,0.02
				c-0.1-0.18-0.08-0.41-0.08-0.59c0.04,0.01,0.06,0.02,0.08,0.03c-0.08-0.05-0.13-0.15,0.2-0.24l-0.03,0.15
				c0.58,0.1-0.46-0.88,0.1-1.04c-0.06-0.09-0.23-0.33-0.31-0.44c0.17,0.05,0.28,0.23,0.38,0.35c-0.26-0.57-0.17-0.19-1.37-2.05
				c0.07,0.12-0.46,0.35-0.19-0.08c0.05,0.13-0.14,0.11-0.14,0.2c-0.1-0.12-0.18-0.32-0.18-0.56c-0.01,0.11-0.11,0.13-0.21,0.06
				l0.11,0.18c-0.29,0.08-0.16-0.31-0.45-0.22l0.1-0.08c-0.15,0.02-0.49-0.1-0.65,0.12c-0.02-0.04-0.04-0.13,0.03-0.14
				c-0.2-0.14-0.39-0.04-1.33,0l0.07-0.08c-0.87,0.36-0.6-0.37-3.01,0.32c-0.17,0.31-0.21,0.01-1.68,0.79
				c-0.17-0.17-0.02-0.15-0.59,0.09l0.01-0.11c-0.23,0.47,0.03,0.24-0.91,0.72c-0.01-0.03-0.04-0.09,0-0.11
				c-0.11,0.05-0.48,0.12-0.29,0.34c-0.1-0.16-0.24,0.12-0.3,0.27l-0.06-0.13c0.03,0.03-0.04,0.16-0.29,0.5l0-0.11
				c-0.17,0.11,0.1,0.36-0.39,0.25c0,0.03-0.02,0-0.51,0.38c0.14-0.11,0.26-0.18,0.25-0.17c-0.03-0.28-0.67,0.05-0.7,0.57
				c-0.02,0.01,0.02-0.02,0.04-0.09l0,0.01c0.03-0.03,0.08-0.07,0.13-0.12c0,0.09,0,0.17-0.11,0.16c0.03,0.09,0.06,0.19,0.07,0.24
				c-0.08,0.17-0.89,0.26-1.96,1.59c0.04-0.01,0.06-0.01,0.08,0.01c-0.27,0.26-0.41,0-1.18,1.33c-0.05-0.09-0.06-0.37-0.44-1
				c0.22-0.03,0.13-0.33-0.01-0.41c0.08,0.02,0.05-0.11,0.15-0.09c-0.03-0.12-0.1-0.22-0.21-0.17c0.13-0.41,0.12-0.24-0.5-1.54
				c0.5,0.18,0.43,0.66-0.27-1.23c0.02,0,0.05,0.02,0.08,0.04c-0.15-0.11,0.02,0.03-0.5-0.17c0.31,0.01-0.01-0.28,0.39-0.24
				c-0.03-0.03-0.09-0.06-0.15-0.03c0.04-0.13,0.17-0.05-0.21-0.56c0.03,0,0.06-0.03,0.08,0.02c-0.09-0.24-0.37-0.35-0.36-0.64
				c0.23,0.02,0.04-0.11,0.21-0.18l-0.1-0.13l-0.07,0.09c-0.04-0.13-0.21-0.2-0.19-0.32l0.11,0.07l-0.11-0.22l-0.02,0.16
				c-0.21-0.2-0.29,0.15-0.1-0.59c-0.17-0.01-0.14-0.28-0.33-0.3c0.05,0.01,0.14-0.02,0.15-0.03l-0.14-0.01
				c0.06-0.18-0.07-0.14-0.09-0.6l0.05,0.07l0.05-0.1l-0.09,0c0.23-0.05-0.44-0.33-0.79-1.74c-0.07,0.16,0.1-0.65-0.88-1.6
				c0.12,0.09,0.17-0.08,0.2-0.15c-0.03,0.02-0.07,0.02-0.1,0.04c0.22-0.22-0.19-0.01-0.12-0.18c0.01-0.15,0.1-0.07-0.08-0.74
				c-0.38,0.18-0.02,0.02-0.49-0.26c0.24,0.05,0.11,0.04,0.34-0.23c-0.15-0.03,0.02-0.18-0.19-0.22c0.03-0.02,0-0.05,0.02-0.09
				c-0.08,0.02-0.27-0.22-0.89-1.15c0.3-0.07-0.32-0.23,0.14-0.21c-0.09-0.07-0.22-0.12-0.3-0.19c0.06-0.07,0.14,0,0.21,0
				c-0.02,0.03-0.16-0.25-0.35-0.12c0.4-0.01-0.37,0.04,0.29,0.46l-0.21,0.04l0.23,0.06c-0.03,0.05-0.12,0.13-0.19,0.08
				c-0.03,0.12-0.09,0.08,0.12,0.27c-0.05,0-0.12,0.08-0.13,0.01c0.03,0.17,0.05,0.48,0.56,0.38l-0.27,0.29l0.15-0.04
				c0.01,0.03,0.02,0.08-0.04,0.1c0.14,0.04,0.05-0.04,0.17-0.04c-0.06,0.18,0.22,0.3,0.01,0.43c0.12-0.01,0.03-0.15,0.16-0.06
				c-0.31,0.08,0.29,0.24-0.13,0.34c0.36,0.01-0.21,0.23,0.42,0.6c-0.09,0.01,0.06,0,0.02,0.51c0.18-0.05,0.22,0.12,0.3,0.21
				c-0.1,0.04-0.15,0.04-0.22,0c0.02,0.01-0.03,0.08,0.2,0.18l-0.14,0.03c0.07,0.13,0.28,0.21,0.27,0.42
				c0.12-0.04,0.27-0.28,0.43-0.13c-0.06,0.03-0.17,0.09-0.21,0.05c0,0.02,0.17,0.13-0.08,0.05c0.07,0.02,0.11,0.24-0.03,0.09
				c0.01,0.08-0.01,0.19-0.06,0.25c0.07-0.01,0.31,0,0.05,0.19c0.08,0.01,0.05,0.12,0.18,0.07c0.02,0.04-0.05,0.07-0.08,0.11
				c0.11,0.04,0-0.01,0.28,0.17l0,0c0.25,0.09,0.05,0.65,0.53,1.5c-0.12-0.08-0.06,0.06-0.1-0.09c0.01,0.15-0.05,0.21,0.13,0.32
				c-0.14-0.01-0.11,0.16-0.05,0.21l0.05-0.13c0.01,0.45-0.12,0.1,0.16,0.96c0.4-0.51,0.37,0.76,0.26,0.65l0.06,0
				c-0.03,0.08,0.03,0.26-0.07,0.25c0.09,0.08,0.11,0.01,0.11-0.07c-0.03,0.2,0.09,0.26,0.09,0.46c0.04-0.1,0.6,0.43,0.01,0.42
				c0.16,0.13,0.02,0.32,0.02,0.42l-0.07-0.07c0.04,0.04,0.05,0.08,0.07,0.13l0.1-0.08c-0.05,0.24-0.11,0.09,0.24,0.34
				c-0.04-0.29-0.58,0.12,0.02,0.26c-0.09,0-0.15,0.09-0.12,0.12c-0.04-0.04,0.16-0.12,0.32,0.18c0.03-0.08,0.01-0.16,0.16,0.07
				c-0.06,0.03-0.07-0.07-0.09-0.03c0.38,0.05-0.51,0.75,0.15,0.87l-0.15-0.26l0.21,0.14c-0.02-0.09-0.12-0.16-0.11-0.22
				c0.11,0.06,0.08,0.06,0.17-0.01c0.22,0.54-0.52,0.09,0.01,1.04c0.06-0.09,0.07-0.18,0.15,0.14c-0.1,0.02-0.12,0.08-0.17,0.13
				c0.07-0.02,0.04,0.19,0.24,0.17c-0.1,0.25-0.21,0.17,0.25,0.54c-0.12-0.04-0.17,0.07-0.27-0.05c0.13,0.19-0.03-0.04,0.48,0.47
				c-0.18,0.21-0.18,0.29,0.07,1.28c-0.03,0-0.05,0.01-0.04-0.02c0.2,0.43-0.06,0.03,0.02,0.7l-0.03-0.03
				c-0.02,0.03-0.01,0.05-0.01,0.08c-0.3,0.22-0.19-0.55-1.18-0.5c0.02,0.03,0.06,0,0.1-0.01l-0.09-0.01
				c0.14-0.32,0.2-0.25-3.19-2.83l0.06-0.02c-0.12-0.13-0.31,0.17-0.38-0.02l0.04-0.01c-0.27-0.17,0.03-0.35-2-0.92l0-0.2
				c-0.28,0.21,0.14-0.21-0.7-0.12c0.01-0.02,0.02-0.03,0.04-0.04c-0.03-0.04,0.04-0.21-0.13-0.01c-0.05-0.11-0.23-0.17-0.38-0.16
				l0.05-0.1c-0.23-0.08-0.51-0.07-0.77-0.12c0.02,0.07-0.12,0.4-0.08,0.07c-0.06,0.09-0.14,0.33-0.02-0.06l-0.14,0.04
				c0-0.05,0.04-0.07,0.05-0.13c-0.07,0.09-0.19-0.19-0.3-0.03c-0.16-0.12-0.94-0.03-1.48,0.15c0-0.13-0.21-0.13-0.1-0.21
				c-0.24,0.01-0.31,0.02-0.62,0.13c0.07,0.08,0.03,0.2-0.05-0.01c-0.32,0.2-0.86-0.31-1.11,0.01l0.01-0.02
				c-0.82,0.45-0.03-0.26-2.09,0.81c0.04,0.06,0.15,0.13-0.09,0.11c1.34-0.21-1.99,0.48-0.5,3.76c-0.05-0.01-0.09,0.02-0.12-0.02
				c-0.05,0.16,0.22,0.34,0.3,0.28c-0.1-0.17,0.25,0.58,1.05,0.86c-0.14,0.18,0.15,0.32,0.29,0.28c-0.07,0.04,0.04,0.11-0.04,0.17
				c0.11,0.06,0.23,0.08,0.26-0.03c0.07,0.35,0.49,0.01,0.45,0.37c0.37,0.04,0.7,0.08,1,0.14c-0.4,0.32-0.76-0.01,1.07,0.44
				c-0.01,0.02-0.04,0.03-0.07,0.04c0.05,0.01,0.1,0.07,0.13-0.03l-0.05,0.01c-0.03-0.17,0.28-0.23,0.32-0.31
				c-0.17,0.26,0.22,0.14-0.02,0.46c0.04-0.01,0.09-0.04,0.1-0.11c0.37,0.29-0.02,0.02,1.35,0.43l-0.03-0.1
				c0.12,0.03,0.25-0.08,0.34,0l-0.11,0.05l0.23,0.02l-0.11-0.1c0.25-0.08,0.01-0.32,0.51,0.21c0.09-0.14,0.29,0.01,0.39-0.14
				c-0.03,0.03-0.05,0.13-0.05,0.15l0.07-0.12c0.11,0.13,0.14,0.01,0.52,0.2l-0.08,0.02l0.06,0.09l0.04-0.09
				c-0.06,0.23,0.49-0.24,1.75,0.1c-0.15,0,0.23,0.34,0.41,0.01c0.26,0.16,0.24,0.5,1.25-0.12c-0.12,0.07,0,0.19,0.05,0.25
				c-0.01-0.04,0.01-0.07,0.01-0.11c0.09,0.29,0.09-0.17,0.2-0.03c-0.02,0.01,0.36-0.2,0.13,0.08c0.31-0.05-0.04,0,0.52,0.16
				c-0.02-0.42-0.01-0.03,0.39-0.35c-0.13,0.21-0.08,0.08,0.07,0.41c0.08-0.13,0.15,0.09,0.26-0.1c0.01,0.03,0.05,0.02,0.07,0.05
				c0.02-0.08,0.27-0.15,1.28-0.38c-0.04,0.3,0.3-0.21,0.14,0.21c0.09-0.06,0.17-0.16,0.26-0.21c0.04,0.08-0.04,0.14-0.07,0.19
				c-0.02-0.02,0.26-0.07,0.21-0.29c-0.11,0.37,0.08-0.35-0.49,0.11l0.03-0.21l-0.12,0.19c-0.04-0.05-0.07-0.16-0.01-0.2
				c-0.09-0.07-0.05-0.11-0.27,0.02c0.01-0.05-0.03-0.14,0.03-0.12c-0.16-0.03-0.43-0.13-0.5,0.37l-0.15-0.37l-0.01,0.15
				c-0.03,0-0.08-0.01-0.07-0.07c-0.08,0.11,0.02,0.06-0.03,0.17c-0.13-0.13-0.33,0.09-0.37-0.15c-0.04,0.12,0.12,0.08,0,0.17
				c0.04-0.32-0.31,0.17-0.24-0.25c-0.14,0.3-0.23-0.28-0.65,0.15c0.02-0.09-0.03,0.05-0.43-0.19c-0.03,0.19-0.18,0.15-0.28,0.18
				c0.01-0.11,0.03-0.15,0.08-0.2c-0.02,0.02-0.06-0.05-0.22,0.11l0.03-0.14c-0.13,0-0.27,0.16-0.44,0.06
				c-0.11,0.31,0.08,0.52-0.27-0.09c-0.03,0.18-0.17-0.21-0.61,0.17l0,0c-0.18,0.18-0.54-0.24-1.42-0.22
				c0.11-0.07-0.03-0.08,0.11-0.05c-0.13-0.06-0.14-0.14-0.31-0.03c0.07-0.12-0.08-0.17-0.14-0.15l0.08,0.1
				c-0.18-0.41-1.24,0.14-1.44-0.4l-0.03,0.05c-0.05-0.07-0.22-0.1-0.16-0.19c-0.1,0.03-0.06,0.09,0,0.13
				c-0.14-0.12-0.24-0.06-0.39-0.15c0.05,0.11-0.64,0.28-0.32-0.19c-0.17,0.07-0.25-0.14-0.33-0.19l0.09-0.03
				c-0.05,0.02-0.09,0-0.13-0.01l0.01,0.13c-0.04-0.03-0.08-0.04-0.06-0.09c-0.07,0.06-0.02,0.2-0.14,0.27
				c-0.04-0.08-0.2,0.01-0.18-0.15c0.24,0.12,0.21-0.55-0.21-0.12c0.05-0.08,0.01-0.17-0.03-0.16c0.05-0.02,0.01,0.2-0.3,0.17
				c0.04,0.07,0.11,0.1-0.14,0.1c0.01-0.07,0.09-0.02,0.07-0.06c-0.24,0.28-0.28-0.83-0.72-0.34l0.27,0.01l-0.22,0.11
				c0.07,0.03,0.18-0.01,0.22,0.02c-0.11,0.07-0.09,0.04-0.09,0.15c-0.54-0.1,0.23-0.47-0.77-0.54c0.03,0.1,0.09,0.15-0.18,0.05
				c0.05-0.09,0.02-0.14,0.01-0.21c-0.03,0.07-0.15-0.08-0.25,0.09c-0.1-0.21,0.02-0.27-0.51-0.13c0.1-0.07,0.06-0.17,0.2-0.18
				c-0.2-0.01,0.05,0-0.6,0.06c0-0.28-0.05-0.24-0.73-0.83c0.03-0.01,0.04-0.03,0.04-0.01c-0.45-0.28,0.28-0.05-0.44-0.76
				c0,0,0.03,0,0.05-0.02c-0.19-0.03-0.3-0.21-0.36-0.35c0.24,0.13,0.25-0.27,0.29-1.29c0.08,0.14,0.09,0.31,0.13,0.42
				c0.02-0.06,0.08-0.2,0.02-0.28c-0.04,0.09-0.06,0.11-0.02-0.32c0.29,0.24,0.31-0.24,0.6-0.09c0.08-0.27,0.39-0.25,0.61-0.39
				c-0.12-0.01-0.01-0.56,0.13-0.07c-0.12-0.06,0-0.17-0.06-0.24c0.12,0.01,0.28,0.11,0.42,0.34c-0.05-0.1,0-0.16,0.08-0.15
				l-0.15-0.1c0.15-0.22,0.24,0.19,0.39-0.01l-0.04,0.11c0.09-0.08,0.37-0.08,0.44-0.33c0.02,0.03,0.06,0.11,0.02,0.14
				c0.15,0.1,0.35-0.08,1.12-0.17l-0.06,0.08c0.83-0.27,0.39,0.24,2.62,0.42c-0.02,0.01,0.04-0.24,0.24-0.1
				c-0.05,0.02,0.2,0.5,0.34-0.02c0.16,0.44,0.77,0.09,0.8,0.48c0.09-0.04,0.17-0.01,0.26-0.04c0,0.22-0.09,0.13,0.42,0.35
				l-0.08,0.07c0.46-0.12,0.15-0.23,1.01,0.25c-0.02,0.03-0.05,0.08-0.09,0.07c0.09,0.06,0.3,0.32,0.41,0.05
				c-0.09,0.17,0.2,0.13,0.35,0.13l-0.09,0.11c0.02-0.04,0.15-0.05,0.54,0.03l-0.09,0.05c0.16,0.11,0.29-0.24,0.35,0.25
				c0.03-0.01-0.01,0,0.48,0.35c-0.13-0.1-0.23-0.18-0.22-0.18c-0.07,0.23-0.05,0.13,0.5,0.53c-0.01-0.01-0.03,0.07-0.08,0.02
				c0.02,0.03,0.05,0.07,0.11,0.13c0.08-0.11,0.14-0.04,0.15-0.18c0.01,0.02-0.03-0.02-0.09-0.02l0.01-0.01
				c-0.03-0.03-0.08-0.06-0.13-0.1c0.08-0.02,0.16-0.04,0.17,0.07c0.08-0.05,0.17-0.1,0.21-0.12c0.16,0.13,0.39,0.66,1.6,1.81
				c0-0.04,0-0.06,0.02-0.08c0.22,0.3-0.08,0.37,1.08,1.37c-0.02,0.6-0.02-0.05,0.49,2.61c0.06-0.13,0.53,0.23,0.02,0.19
				c0.1-0.11,0.16,0.08,0.25,0.03c-0.06,0.13-0.2,0.28-0.44,0.38c0.11-0.03,0.16,0.05,0.13,0.16l0.13-0.16
				c0.19,0.24-0.24,0.24-0.05,0.48l-0.1-0.07c0.07,0.13,0.04,0.48,0.29,0.6c-0.03,0.03-0.12,0.06-0.14,0
				c-0.11,0.22,0.04,0.39,0.17,1.33l-0.09-0.06c0.37,0.86-0.27,0.64-0.04,3.05c-0.01-0.02,0.27-0.02,0.14,0.27l0-0.03
				c-0.2,0.05-0.14,0.21-0.16,0.34l0.26,0.03c-0.4,0.31,0.09,0.86-0.26,1.02c0.06,0.08,0.06,0.18,0.12,0.26
				c-0.23,0.08-0.14-0.04-0.19,0.58l-0.1-0.06c0.29,0.44,0.26,0.1,0.12,1.18c-0.03-0.01-0.09-0.02-0.09-0.06
				c-0.02,0.12-0.19,0.46,0.1,0.45c-0.19-0.02-0.05,0.27,0.02,0.42l-0.14-0.04c0.04,0,0.11,0.14,0.2,0.57l-0.08-0.07
				c-0.06,0.39-0.01,0.18-0.16,1.14c0.03-0.18,0.06-0.32,0.07-0.31c-0.22-0.16-0.46,0.49-0.13,0.94c-0.01,0.02,0-0.03-0.02-0.1
				l0.01,0.01c0-0.05,0.01-0.11,0.02-0.18c0.06,0.07,0.11,0.14,0.02,0.21c0.08,0.05,0.17,0.11,0.21,0.14
				c-0.01,0.21-0.41,0.78-0.64,2.62c0.03-0.03,0.04-0.04,0.08-0.04c-0.26-0.43,0.44,1.39-1.04,1.92c0.45,0.3-0.38,0.01-0.32,0.31
				c-0.16-0.01,0,0-0.45-0.06c0,0,0,0,0.01,0c-0.42-0.1-0.76-0.34-1.29-0.72c0.07,0.13-0.26,0.1-0.62-0.02l0.49,0.25
				c-0.95-0.06-0.74-0.38-1.68-0.51l0.31-0.01c-0.82-0.36-0.94-0.07-5.55-1.74l0.11-0.04c-0.38-0.08-0.12-0.09-1.49-0.5l0.27,0.01
				c-1.05-0.52-1.76-0.44-2.69-0.72c0.07-0.21-0.8-0.55-1.23-0.72l0.02-0.03c-1.79,0.54-6.02-3-14.5,6.5l-0.02-0.24
				c-0.17,2-0.24,0.9-0.54,4.58c-0.03-0.02-0.09-0.05-0.09-0.22c-0.07,0.49-0.07,1.91,0.2,1.71c-0.02,0.13-0.2,0.26,0.28,1.59
				l-0.15-0.06c0.06,0.03,0.24,0.68,0.74,1.98l-0.15-0.2c0.26,0.51,0.4,1.65,1.87,4.03c-0.42-0.58-0.66-1.09-0.63-1.05
				c-0.11,0.06,0.14,0.59-0.23,0.27c0.25,0.42,0.07-0.07,1.21,1.49l-0.04-0.04c0.51,0.59,0.22,0.38,1.11,1.4
				c0.08-0.11,0.34,0.15,0.17-0.17c0.05,0.07-0.1-0.1-0.27-0.25l0.02,0.01c-0.13-0.14-0.29-0.32-0.48-0.53
				c1.62,1.16,0.63,1.34,4.11,3.42c-0.63-0.23-0.58-0.25,1.46,0.83l-0.13-0.01c1.37,1.03,2.62,0.75,4.23,1.66
				c-0.11-0.06-0.17-0.09-0.19-0.13C74.41,448.43,71.09,448.17,82.48,449.62z M94.23,400.38c-0.01-0.03-0.12,0.16-0.14-0.02
				C94.11,400.39,94.17,400.37,94.23,400.38z M94.01,400.55c-0.01,0.03,0,0-0.05,0.05C93.98,400.6,93.99,400.58,94.01,400.55z
				 M93.18,400.06c-0.01-0.02,0,0-0.03-0.07C93.17,399.97,93.28,399.95,93.18,400.06z M86.19,402.1c0.03-0.02-0.01-0.08-0.02-0.14
				C86.15,401.97,86.37,402.03,86.19,402.1z M86.41,402.13c0.03,0.01,0,0,0.06,0.03C86.46,402.15,86.44,402.15,86.41,402.13z
				 M89.65,405.28c-0.03-0.03-0.06-0.05-0.11-0.07c0.02,0,0.06-0.01,0.06,0.01c-0.01-0.03-0.01-0.06-0.01-0.08
				C89.65,405.15,89.7,405.19,89.65,405.28z M89.67,405.13c-0.04,0-0.06,0-0.08,0C89.58,405.13,89.6,404.95,89.67,405.13z
				 M90.34,420.79c0-0.02,0-0.04,0-0.08C90.34,420.74,90.34,420.71,90.34,420.79z M90.27,420.49c0.03,0.02,0.07-0.05,0.12-0.09
				C90.36,420.38,90.41,420.62,90.27,420.49z M89.18,404.28c0,0.05,0.06,0.15-0.15,0.29C89.11,404.47,88.89,404.33,89.18,404.28z
				 M89.17,404.99C89.17,404.99,89.18,404.99,89.17,404.99C89.17,404.99,89.17,404.99,89.17,404.99
				C89.17,404.99,89.17,404.99,89.17,404.99z M89.32,420.91c0-0.04,0.07-0.13,0.07,0.03C89.36,420.93,89.33,420.93,89.32,420.91z
				 M62.79,442.45c-0.17-0.05-0.14-0.01-0.26-0.25C62.6,442.26,62.51,442.23,62.79,442.45z M62.1,440.25
				c0.06,0.03-0.05-0.17-0.11-0.33C62.07,440.02,62.35,440.33,62.1,440.25z M62.61,440.82L62.61,440.82
				c0.07,0.09,0.13,0.17,0.16,0.22C62.74,441,62.69,440.93,62.61,440.82z"
                />
                <path
                  className="st10"
                  d="M82.47,387.22l0.12,0.36c0.09-0.59-0.93-1.42-0.52-1.56c-0.18-0.06,0.08-0.55-1.06-1.71
				c0.42,0.17-0.38-0.65-0.1-0.72c-0.34-0.1-0.65-0.75-1.05-1.05c0.03,0.01,0.09,0.03,0.11,0.07c-0.13-0.21-0.15-0.31-0.2-0.46
				c-0.05,0.07-1.16-0.49-0.46-0.52c-0.16-0.06-0.39-0.3-0.41-0.16c-0.08-0.27-0.1-0.2-0.07-0.41c-0.06,0.31-1.1-0.87-0.43-0.65
				c-1.04-0.33,0.09-0.1-2.25-1.71c-0.1,0.16-0.12,0.33-0.42-0.04c0.65,0.27,0.24-0.32,0.05-0.24c0.19-0.73-1.14-0.89-2.54-2
				l0.23-0.07c-1.47-0.49,0.42-0.4-7.39-4.12l0.12,0c-0.4-0.23-0.59,0.09-0.92-0.2l0.08,0.01c-0.35,0.06,0.04-0.67-7.71-2.29
				c0.03-0.06,0.18-0.05,0.31-0.07c-0.5,0.07-0.06-0.17-0.52-0.11c0.05,0.04-0.05,0.09-0.06,0.11c-0.2-0.14-0.7-0.2-1.08-0.2
				l0.08-0.1c-0.64-0.11-1.35-0.13-2.1-0.15c-0.35,0.1-4.6,0-4,7.16l-0.04-0.02c0.84,0.51,0.96,0.55,1.25,0.69
				c-0.38-0.02-0.78-0.03-1.25-0.04l0.21-0.18c-1.19,0.26-2.58,0.41-3.57,1.21c0.04-0.07,0.02-0.11,0.13-0.17
				c-0.3,0.01-0.83,0.53-0.76,0.61c-0.52,0.41-1.14,0.31-1.79,3.14c-0.25-0.06-0.2,0.91,0.04,1.18c-0.1-0.09,0.01,0.29-0.11,0.2
				c0.12,0.36,0.32,0.64,0.39,0.53c0.5,0.89,0.1,1.24,3.37,2.65c-0.11,0-0.2-0.01-0.27-0.01c1.18,0.4,0.65,0.51,3.4,0.94
				c0,0.02-0.08,0.03-0.16,0.03c0.4-0.04-0.05,0.03,0.8-0.34c-0.26,0.27,0.69,0.14,0.28,0.47c0.09-0.01,0.21-0.05,0.2-0.12
				c0.13,0.02,0.18,0.07,0.03,0.14c0.51-0.04,0.33-0.15,0.78-0.14c0,0.05-0.09,0.07-0.14,0.12c0.23-0.09,0.46-0.18,0.84-0.15
				c-0.02,0.03,0.02,0.07-0.09,0.07c0.66-0.02,1.1-0.3,1.81-0.26c-0.11,0.12-0.1,0.29,0.68,0.13L56.13,387
				c0.33-0.03,0.61-0.2,0.9-0.18l-0.24,0.11l0.62-0.12l-0.36-0.02c0.32-0.14-0.07-0.23,0.38-0.24c-0.09,0.03,0.33-0.04,0.52,0.04
				l0,0c0.72-0.28,0.19,0.32,1.53-0.28c-0.13,0.27,0.51,0.15,0.64-0.07c-0.04,0.02-0.02,0.03-0.1,0.07c0.58-0.11,0.83-0.11,1.02-0.1
				l-0.14,0.04c0.73-0.06-0.06,0.03,2.59-0.45l-0.13-0.01c0.76-0.3,0.5,0.27,2.48-0.23l-0.09,0.07c1.31-0.09,1.05,0.03,3.83,0.13
				c-0.16,0.05-0.22,0.05,0.55,0.06c-0.04-0.12-0.08-0.08-0.22-0.19c0.1-0.06,0.3-0.08,0.48-0.06c-0.33,0.04-0.02,0.18,0.11,0.27
				c-0.02-0.04,0.03-0.07,0.03-0.11c0.76,0.21,0.08-0.17,1.72,0.21c-0.13,0.01-0.3-0.03-0.43-0.01c0.25,0.2,0.29,0.18,0.49,0.24
				l-0.02-0.01l0.46,0.17l-0.11-0.07c0.43-0.28,0.73,0.1,1.25-0.01c-0.25,0.01-0.42,0.34,0.73,0.54c-0.23-0.02,1.87,0.32,4.02,1.32
				c-0.19,0.11-0.41,0.16,1.06,0.51l-0.15-0.13c-0.02,0.08-0.21,0.23-0.38-0.15l0.18,0.06c-0.04-0.35-0.71-0.29-1.05-0.46
				c0.11-0.12,0.25-0.22-0.75-0.59c0.07-0.02,0.01-0.15,0.16-0.06c-0.4-0.3-0.78-0.15-0.9-0.36l0.04,0
				c-0.74-0.24-2.07-0.88-4.62-1.24c0.04-0.06-0.78-0.24-1.17-0.37l0.02-0.03c-0.1,0.16-0.51,0.08-0.82,0.08
				c0.02-0.11,0.08-0.14,0.25-0.17c-0.04,0.03-0.03-0.09-0.64,0.03l0.09-0.13c-0.15,0.03-0.57-0.2-1.55,0.2l0.21-0.11
				c-0.09,0.04-0.67-0.03-0.17-0.07c-0.9-0.14-0.38-0.07-2.21,0.02l0,0c-0.39,0.22-1.72-0.17-4.04,0.3c0.28-0.12-0.11-0.06,0.29-0.1
				c-0.38,0.01-0.48-0.06-0.86,0.14c0.12-0.15-0.3-0.11-0.48-0.05l0.27,0.05c-0.2,0.05-0.37,0.06-0.55,0.08l0.07-0.18
				c-0.82,0.05-1.7,0.27-3.45,0.68c0.4,0.35-0.94-0.28-0.72,0.03c-0.46-0.03-0.7,0.07-1.16,0.07c0.09,0.08-1.3,0.56-1-0.03
				c-0.41,0.14-0.78-0.05-1.01-0.06l0.22-0.05c-0.13,0.03-0.23,0.03-0.34,0.04l0.12,0.12c-0.13-0.02-0.24-0.02-0.23-0.07
				c-0.25,0.18,0.02,0.41-0.74,0.19c0.52,0.07,0-0.14,0.37-0.17c-0.08,0.01-0.25,0.01-0.23-0.06c-0.5-0.16-0.24,0.17-0.77,0.12
				c0.07-0.08-0.11-0.18-0.19-0.17c0.16,0-0.03,0.25-0.66,0.18l0.2,0.07c-0.39,0.17-0.88-0.21-2.18-0.81
				c0.07,0.15,0.11,0.14-0.22,0.14c0.23,0.05,0.45,0.13,0.69,0.15c-0.16,0.02-0.32,0.02-0.48,0.02c0.2,0.08,0.45,0.08,0.57,0.13
				c-0.24,0.04-0.21,0.01-0.14,0.14c-0.99-0.39-0.28-0.6-2.16-1.2l0.15,0.15c-0.13,0.06-0.35-0.19-0.55-0.27
				c0.07-0.07-0.01-0.17-0.04-0.28c-0.04,0.07-0.35-0.29-0.52-0.18c-0.3-0.58-0.03-0.28-0.95-0.99c0.17,0.07,0.12-0.14,0.34,0.08
				c-0.09-0.13-0.13-0.26-0.19-0.23c0.11,0.04-0.14-0.14-0.24-0.54c0,0.05,0,0.02,0.03,0.13c-0.5-0.55-0.47-0.74,0.81-3.08
				c0.01,0.04,0.03,0.04-0.02,0.07c0.78-0.79-0.04-0.06,1.38-0.89l-0.06,0.06c0.43-0.04,0.03-0.24,2.16-1.05
				c0,0.06-0.01,0.09-0.01,0.12c0.05-0.11,0.21-0.28,0.69,0.03c-0.12,0.01-0.24,0.02-0.36,0.04c0.2,0.16,0.46-0.05,0.66-0.03
				l-0.02,0.08c0.54-0.04,0.56-0.24,1.16-0.19l-0.06-0.06c0.83-0.11-0.02-0.03,1.59,0.28c-0.24,0.03-0.53,0.02-0.73,0.06
				c0.21,0.03,0.71,0.12,0.84,0.05c-0.09,0-0.2,0.02-0.28-0.03c0.19-0.18,0.76,0.45,2.08,0.91c0-0.21,0,0,0.65-0.96
				c-0.88-0.42-0.02,0-2.14-1.25c0.18,0.4-0.25-0.1-0.32,0.2c-0.44-0.1-0.57-0.42-0.94-0.99c0.01,0.12-0.21,0.08-0.41-0.07
				l0.26,0.28c-0.64-0.12-0.36-0.45-0.95-0.61l0.21,0.02c-0.13-0.56-0.56,0.69-2-2.45l0.08,0.05c-0.05-0.35,0.04-0.14,0.1-0.82
				l0.03,0.16c1.86-1.73,0.07-2.61,10.74-1.2l-0.05,0.13c0.52,0.17,0.78,0.25,1.42,0.42l-0.16,0.06c1.26,0.03,0.56-0.06,2.82,0.75
				c-0.02,0.02-0.06,0.07-0.16,0.04c0.29,0.12,1.06,0.58,1.07,0.27c-0.06,0.17,0.62,0.29,0.98,0.36l-0.11,0.1
				c0.04-0.03,0.46,0.04,1.33,0.35l-0.17,0.01c0.35,0.16,0.98,0.48,2.52,1.4c-0.39-0.24-0.71-0.42-0.67-0.41
				c-0.06,0.11,0.24,0.31-0.1,0.32c0.29,0.1,0.03-0.1,1.14,0.44l-0.04-0.01c0.44,0.24,0.2,0.21,0.91,0.67
				c0.06-0.13,0.25,0.03,0.13-0.2c0.04,0.04-0.08-0.05-0.22-0.1l0.02,0c-0.1-0.06-0.24-0.14-0.39-0.23c1.22,0.39,0.6,0.61,2.88,1.95
				c-0.42-0.16-0.37-0.2,0.86,0.68l-0.09,0c0.34,0.59,0.48,0.03,2.27,1.92c0-0.01-0.01-0.01-0.01-0.02c0.38,0.7,2.78,2.32,4.19,5.44
				l0.05-0.13C81.6,387.23,81.04,386.94,82.47,387.22z M70.28,374.64c0.05-0.01-0.08-0.11-0.16-0.2
				C70.21,374.48,70.5,374.59,70.28,374.64z M71.08,376.08c-0.1,0.02-0.11,0.06-0.21-0.09C70.93,376,70.86,376.02,71.08,376.08z
				 M70.79,374.83c0.07,0.03,0,0,0.17,0.09C70.92,374.9,70.87,374.88,70.79,374.83z M76.7,380.22c0.01,0.04,0.02,0.08,0.05,0.13
				C76.72,380.29,76.7,380.25,76.7,380.22z"
                />
                <path
                  className="st10"
                  d="M81.9,388.97c-0.07-0.03-0.22-0.17-0.01-0.3c0,0.03-0.2-0.09-0.01-0.25c-0.06-0.17-0.11-0.24-0.43,0.01
				l0.22-0.31l-0.12,0.1c-0.01-0.01-0.02-0.04,0.03-0.08c-0.11,0.04-0.04,0.05-0.13,0.1c0.06-0.15-0.17-0.08-0.01-0.26
				c-0.1,0.07-0.02,0.11-0.13,0.12c0.23-0.19-0.23-0.03,0.09-0.27c-0.27,0.07,0.03-0.28-0.35-0.16c0.07-0.04-0.05-0.01-0.04-0.31
				c-0.14,0.12-0.17,0.04-0.24,0.03c0.08-0.08,0.11-0.1,0.17-0.12c-0.05-0.03-0.04-0.04-0.17,0l0.1-0.09
				c-0.06-0.05-0.22,0.01-0.23-0.12c-0.09,0.09-0.18,0.31-0.32,0.31c0.04-0.05,0.12-0.14,0.16-0.15c-0.02-0.16-0.03,0.1,0.09-0.32
				c-0.04,0.03-0.24,0.19-0.06-0.08c-0.06,0.04-0.05-0.05-0.15,0.06c-0.02-0.01,0.03-0.07,0.05-0.1c-0.07,0.03,0-0.02-0.23,0.06l0,0
				c-0.12,0.28-0.1-0.34-0.62-0.68c0.1,0.02-0.02-0.28-0.01-0.05c-0.15-0.47-0.82-0.16-0.58-0.62l-0.04,0.04
				c0.01-0.07-0.07-0.12,0-0.19c-0.08,0.01-0.08,0.06-0.06,0.11c-0.02-0.12-0.11-0.09-0.15-0.19c-0.06,0.13-0.48,0.11-0.1-0.2
				c-0.14,0.04-0.09-0.15-0.11-0.2l0.06-0.01c-0.04,0.01-0.06-0.01-0.07-0.02l-0.05,0.11c-0.01-0.03-0.03-0.05,0-0.09
				c-0.07,0.05-0.1,0.17-0.2,0.23c0.01-0.07-0.12-0.01-0.04-0.14c0.06,0.11,0.08-0.14,0.17-0.1c-0.08-0.15,0.06-0.17-0.25-0.02
				c0.06-0.07,0.07-0.15,0.05-0.15c0.04,0.03-0.07,0.13-0.14,0.17c-0.02-0.1-0.09,0-0.12-0.03c0,0.07,0.03,0.08-0.12,0.08
				c0.04-0.06,0.06-0.01,0.07-0.05c-0.13,0.1-0.01-0.2-0.13-0.16c0-0.07,0.09-0.14,0.09-0.19c-0.15,0.12,0-0.28-0.28,0.03l0.17,0.01
				c-0.14,0.22-0.45,0.06-0.52-0.36c-0.04,0.06-0.08-0.06-0.2,0.1c0.23-0.36-0.51,0.13-0.13-0.24c-0.14,0.01,0.03-0.01-0.42,0.15
				c0.06-0.32-0.27-0.13-0.22-0.4c-0.49-0.04-0.15,0.12-1.03-0.32c0.08-0.1,0.07-0.04,0.11-0.16c-0.3,0.47-0.25-0.05-0.51-0.29
				c-0.06,0.02-0.22,0.1-0.31,0.1c0.1-0.11,0.22-0.14,0.31-0.19c-0.04-0.02-0.14-0.05-0.2,0.02c0.06-0.02-0.14,0.34-0.32-0.38
				c-0.2,0.07-0.26-0.18-0.44-0.23c-0.01,0.13-0.33,0.36-0.09-0.03c0,0.11-0.09,0.12-0.12,0.19c-0.03-0.08-0.04-0.23,0.02-0.48
				c-0.03,0.11-0.09,0.13-0.12,0.07c0.09,0.1-0.26,0.04-0.1-0.1c-0.08,0.02-0.23-0.1-0.38,0.11c0-0.04,0.02-0.12,0.05-0.13
				c0.12-0.28-0.69,0.07-2.24-0.68l0.01,0.01c0.03-0.2-0.06-0.18-0.12-0.2l-0.09,0.25c-0.05-0.41-0.46,0.02-0.45-0.33
				c-0.06,0.05-0.1,0.04-0.16,0.09c0-0.12,0.1-0.18-0.25-0.24l0.05-0.09c-0.08,0.19-0.31-0.2-0.83,0.07
				c0.04-0.18-0.13-0.05-0.22,0.02c0.04-0.2,0.16-0.15-0.47-0.09c-0.04,0.09-0.17,0.06-0.33-0.07c0.09,0.02,0.16,0.04,0.15,0.05
				c0.11-0.06-0.35-0.46-0.48-0.07c-0.01-0.01,0.02,0,0.05-0.03l0,0.01c0.03,0,0.06,0,0.09,0.01c-0.04,0.07-0.08,0.12-0.11,0.04
				c-0.04,0.09-0.07,0.17-0.09,0.22c-0.09-0.05-0.09-0.48-0.68-0.19c-0.17-0.53,0.04-0.22-0.63-0.18c0.01,0.02,0.02,0.04,0.02,0.07
				c-0.12-0.03-0.29-0.34-0.97-0.05l-0.01-0.15c0.22,0.31-0.49-0.24-1.01,0.27c0.01-0.01,0.01-0.01,0.01-0.01
				c0,0.14,0.02,0.34,0.05,0.64l0.09-0.14l0.02,0.11l-0.07,0c0.03,0.11,0.06,0.02,0.09-0.05c0,0.06,0.01,0.1,0.03,0.13l0.09-0.22
				c0.01,0.09-0.04,0.18,0.01,0.23c0.04-0.1-0.03-0.21,0.04-0.27c0.03,0.11,0.02,0.19,0.07,0.11c0,0.06-0.01,0.1-0.03,0.12
				c0.04-0.07,0.11-0.02,0.14-0.15c0.03,0.13,0.14-0.03,0.12,0.19c0.02-0.05,0.08-0.17,0.12-0.18c-0.09,0.26,0.14,0.02,0.1,0.3
				c0.07-0.23,0.19-0.09,0.29-0.22c-0.01,0.02-0.02,0.06-0.03,0.04c0.05,0.02,0.06,0.09,0.09,0.14c0.02-0.19,0.11-0.23,0.16-0.35
				c0.04,0.2-0.06,0.22-0.06,0.41c0.01-0.16,0.12-0.15,0.11-0.28c0.04,0.12,0.04,0.06,0.04,0.23c0.03-0.24,0.05-0.04,0.1-0.18
				c0.06,0.13-0.01,0.23,0.08,0.16c0,0.34,0.05-0.04,0.51-0.16c0,0.02,0,0.03,0,0.05c0.02,0-0.05-0.27,0.1-0.24
				c-0.02-0.01-0.03,0.04-0.04,0.08l0.04-0.05c-0.02,0.26-0.18,0.14,0.07,0.25l-0.07-0.07c0.17,0.2,0.23,0.34,1.16,0.18
				c-0.02,0.05-0.05,0.1-0.03,0.15c-0.04-0.21,0.62-0.12,1.65,0.35c0.04-0.12,0.39-0.17,0.5,0.17l0.11-0.14l-0.02,0.19
				c0.17-0.19-0.09,0.24,0.37,0c-0.01,0.02-0.02,0.03-0.03,0.05c0.03,0.01-0.02,0.21,0.08-0.02c0.01,0.1,0.1,0.13,0.18,0.12
				c-0.01,0.16-0.08,0.11,0.36,0.18c-0.03-0.06,0.03-0.23,0.08-0.24l-0.03,0.16c0.05-0.09,0.15-0.32,0,0.06l0.08-0.03
				c-0.01,0.05-0.03,0.07-0.06,0.12c0.06-0.09,0.05,0.19,0.15,0.03c-0.6,0.23,1.6,0.13,2.47,1.03c0.01-0.02,0.08-0.21,0.06,0.01
				c-0.14-0.39,1.16,0.53,0.94,0.46c0.29-0.08,0.4,0.21,0.67,0.17c-0.03,0.03-0.05,0.07-0.07,0.06c-0.02,0.13,0.14,0.09,0.19,0
				c-0.05,0.03-0.03,0.26,0.21,0.15c0.04,0.1,0.17,0.02,0.18,0.13c0.11-0.11,0.08,0.13,0.21-0.05c-0.12,0.19,0.03,0.23,0.13,0.16
				c-0.05,0.05,0,0.08-0.06,0.16c0.05,0.03,0.12,0.02,0.17-0.08c-0.05,0.29,0.3-0.07,0.17,0.26c0.21-0.01,0.39,0.01,0.56,0.05
				c-0.06,0.23-0.1,0.09,0.47,0.39c-0.01,0.02-0.04,0.03-0.06,0.03c0.03,0.01,0.03,0.06,0.09-0.02l-0.04,0.01
				c0.05-0.15,0.26-0.2,0.31-0.26c-0.2,0.22,0.07,0.14-0.2,0.4c0.03-0.01,0.07-0.03,0.11-0.09c0.06,0.23,0,0.04,0.48,0.47
				c-0.02-0.04,0.09,0.1,0.12-0.09c-0.06,0.05,0.29,0,0.1,0.08l0.12,0.05c0.01-0.1-0.01-0.11,0.15-0.19c0.02,0.16,0.08,0.1,0,0.35
				c0.12-0.1,0.14,0.06,0.28-0.05c-0.03,0.02-0.1,0.1-0.1,0.12l0.1-0.08c-0.1,0.16,0.03,0.14,0.04,0.09
				c-0.06,0.06,0.44,0.17,0.08,0.3c0.13,0.06,0.37,0.01,0.45,0.13c0.16-0.27,0.01-0.01,0.12,0.08c-0.03,0-0.11,0.1-0.09,0.08
				c0.04,0.09,0.19,0.08,0.35,0.11c-0.05-0.03-0.01,0.34,0.18,0.13c-0.04,0.21-0.05,0.52,0.61,0.33c-0.1,0.01-0.13,0.14-0.15,0.2
				c0.02-0.03,0.05-0.05,0.08-0.08c-0.17,0.25,0.19-0.1,0.1,0.05c0.05,0,0.24,0.03,0,0.11c0.15,0.09-0.02-0.04,0.08,0.31
				c0.3-0.31,0.01-0.02,0.39-0.09c-0.2,0.11-0.05-0.03-0.27,0.31c0.12-0.06-0.01,0.12,0.16,0.04c-0.03,0.04,0.03,0.04-0.06,0.12
				c-0.21-0.33,0.66,0.02,0.59,0.46c0.07,0.01,0.17-0.02,0.24-0.01c-0.05,0.07-0.12,0.06-0.17,0.09
				C81.66,389,81.75,389.09,81.9,388.97z M67.78,380.55c0.01-0.03,0.02-0.05,0.02-0.07C67.81,380.46,67.86,380.51,67.78,380.55z
				 M67.76,381.47c0.01,0,0.02,0,0.04,0C67.79,381.48,67.8,381.47,67.76,381.47z M67.92,381.38c-0.01,0.03,0.02,0.06,0.03,0.11
				C67.91,381.47,67.85,381.49,67.92,381.38z"
                />
                <path
                  className="st10"
                  d="M122.62,377.25c0.02,0,0.02,0,0.03,0c-0.73-0.92,0.16-0.1-1.01-1.58l0.08,0.06c-0.33-0.23,0-0.48-2.44-1.21
				c0.02-0.04,0.04-0.05,0.05-0.08c-0.11,0.06-0.33,0.06-0.67-0.36l0.39,0.11c-0.15-0.23-0.51-0.15-0.7-0.22l0.04-0.07
				c-0.62-0.11-0.66,0.08-1.3-0.02l0.06,0.07c-0.86,0.15-0.41-0.4-1.61-0.11c0.26-0.17,0.03-0.04,0.65-0.2c-0.1-0.01-0.26,0-0.42,0
				c0.01,0,0.01-0.01,0.02-0.01c-0.14,0.04-0.01,0-0.31,0.05c0.37-0.41,0.61-0.17,0.93-0.83c-0.02,0.09-0.04,0.17-0.07,0.26
				c0.08-0.52,1.28-0.66,0.66-4.46l0.09,0.05c-0.24-0.44,0.05-0.31-0.52-1.11c0.06,0.04,0.11,0.09,0.17,0.14
				c-0.25-0.36-0.04-0.48-1.53-1.48c0.14-0.16-0.4-0.66-0.71-0.83l0.03-0.02c-2.62,0.32-1.74-2.34-10.21,1.41l-0.05-0.13
				c-0.54,0.23-0.81,0.34-1.43,0.69l0.08-0.16c-0.96,0.95-0.41,0.41-2.57,1.77c-0.01-0.03-0.02-0.09,0.07-0.16
				c-0.28,0.17-1.16,0.58-0.89,0.75c-0.11-0.15-0.6,0.41-0.86,0.7l-0.03-0.15c0.02,0.06-0.32,0.37-0.99,1.06l0.07-0.17
				c-0.29,0.28-0.92,0.68-2.35,1.9c0.37-0.31,0.66-0.56,0.64-0.53c-0.08-0.1-0.39,0.14-0.27-0.2c-0.22,0.25,0.08,0.06-0.83,0.99
				l0.02-0.04c-0.37,0.37-0.27,0.14-0.93,0.75c0.11,0.09-0.09,0.25,0.16,0.17c-0.05,0.03,0.07-0.07,0.16-0.2l0,0.02
				c0.09-0.09,0.21-0.2,0.34-0.33c-0.71,1.16-0.84,0.39-2.58,2.58c0.21-0.42,0.24-0.37-0.78,0.84l0.01-0.1
				c-1.01,0.63-0.9,1.68-2.02,2.37c0.08-0.05,0.11-0.08,0.15-0.07c-0.21,0.2-0.34,0.32-0.44,0.41c0.36-0.84,0.03-0.27,1.18-2.2
				c0.16,0.11-0.16,0.11,0.46-0.99c0.04,0.06,0.58-0.88,0.88-1.3l0.02,0.03c-0.11-0.17,0.16-0.63,0.31-0.99
				c0.09,0.06,0.1,0.15,0.04,0.36c-0.01-0.06,0.1,0.01,0.27-0.76c0.19,0.38,0.19-0.09,0.68-1.75l-0.01,0.29
				c0.17-0.23-0.02-0.18,0.09-0.38c0.1,0.07,0.31-0.23,0.07,0.2c0.6-1.02,0.27-0.42,1.14-2.61l0,0c-0.02-0.7,0.77-0.98,1.7-4.95
				c0,0.39,0.11-0.11-0.03,0.39c0.16-0.47,0.25-0.56,0.23-1.11c0.09,0.2,0.22-0.34,0.22-0.58l-0.15,0.33
				c0.03-0.26,0.07-0.49,0.12-0.72l0.14,0.15c0.17-1.02,0.38-2.31-0.02-4.66c-0.33,0.53,0.22-1.28-0.08-0.97
				c-0.02-0.62-0.2-0.93-0.32-1.54c-0.05,0.06-1.07-1.47-0.39-1.32c-0.34-0.47-0.39-1-0.55-1.3l0.19,0.24
				c-0.12-0.15-0.19-0.28-0.27-0.41l-0.02,0.21c-0.07-0.16-0.15-0.3-0.1-0.32c-0.39-0.19-0.32,0.18-0.74-0.74
				c0.5,0.85,0.76-0.04-0.5-0.73c0.13,0.02,0.02-0.24-0.07-0.32c0.14,0.2-0.21,0.08-0.8-0.48l0.17,0.22
				c-0.54-0.11-0.72-1.09-2.4-2.23c0.09,0.19,0.13,0.18-0.29,0.07c0.29,0.15,0.6,0.28,0.89,0.44c-0.2-0.08-0.42-0.13-0.63-0.19
				c0.26,0.15,0.58,0.26,0.7,0.39c-0.3-0.1-0.25-0.1-0.2,0.06c-1.2-0.74-0.32-1.05-3.01-1.61c0.09,0.04,0.19,0.07,0.28,0.12
				c-0.86,0.03-0.79-0.57-3.84,0.04c0.19-0.14-0.15-0.16,0.32-0.32c-1.52,0.74,0.07-0.15-4.64,3.91c-0.01-0.02-0.01-0.02-0.02-0.03
				c-0.26,1.43-0.2-0.04-0.55,2.25l-0.01-0.12c-0.07,0.35-0.12,0.56,0.36,2.78c-0.21-0.19-0.37-0.3-0.63-0.76l-0.01,0.1
				c-0.75-0.88,0.2-0.56-1.23-2.13c0.27,0.18,0.63,0.69,0.88,0.98c-0.13-0.25-0.47-0.86-0.63-0.94c0.07,0.09,0.19,0.2,0.21,0.31
				c-1.83-2.17,0.03-0.83-5.37-3.7c0.54,0.14-0.38,0.13-0.19,0.39c-0.13,0.06,0,0.03-0.4,0.18c0,0,0,0,0.06-0.01
				c-0.47,0.2-0.71,0.15-1.49,0.43c0.15,0.01-0.06,0.27-0.33,0.55c0.14-0.12,0.31-0.22,0.46-0.32c-0.48,0.8-0.75,0.46-1.08,1.37
				l0.06-0.31c0.33,0.62-1.33-0.55-0.13,5.48l-0.09-0.06c0.02,0.04,0.04,0.07,0.06,0.11c-0.21-1.07-9.48-3.96-9.09,4.14l-0.15-0.17
				c0.97,1.49,0.29,0.8,2.17,3.46c-0.03,0.01-0.1,0.03-0.2-0.08c0.25,0.35,1,1.34,1.1,1.02c-0.13,0.14,0.63,0.66,1.03,0.92
				l-0.15,0.05c0.06-0.01,0.52,0.31,1.49,1.02l-0.21-0.07c0.39,0.29,1.05,0.95,2.87,2.47c-0.46-0.38-0.83-0.7-0.79-0.68
				c-0.08,0.1,0.28,0.42-0.13,0.32c0.34,0.22,0.05-0.09,1.36,0.85l-0.05-0.02c0.52,0.39,0.23,0.29,1.1,1
				c0.07-0.12,0.3,0.09,0.15-0.18c0.05,0.05-0.09-0.07-0.26-0.16l0.02,0c-0.12-0.1-0.29-0.22-0.47-0.37
				c1.48,0.76,0.75,0.89,3.55,2.83c-0.52-0.25-0.46-0.29,1.09,0.92l-0.11-0.02c0.85,1.15,2.03,1.19,2.86,2.59
				c-0.06-0.09-0.09-0.14-0.08-0.18c0.93,1.91,0.42-1.01,5.09,7.49l0.05-0.15c0.24-1.27,0.1,3.96,1.56,2.23
				c0.04,0.14,0.08,0.29,0.11,0.44c0.02-0.12-0.01-0.26-0.05-0.4c-0.07,0.04-0.03-0.05,0.32-0.17c-0.02-0.02-0.06-0.03-0.09-0.05
				c0.09-0.06,0.18-0.13,0.29-0.21l-0.21,0.3c0.01,0.08,0.32-0.4,0.56-0.88c0.04,0.05,0.26,0.24,0.03-0.07
				c0.07,0.05,0.1,0.08,0.09,0c0.04,0.04,0.06,0.08,0.06,0.11c-0.01-0.08,0.08-0.1,0.02-0.21c0.12,0.07,0.1-0.13,0.24,0.05
				c-0.02-0.05-0.05-0.19-0.02-0.22c0.11,0.25,0.13-0.09,0.3,0.14c-0.1-0.22,0.09-0.21,0.09-0.38c0.01,0.02,0.02,0.06,0.01,0.05
				c0.06-0.02,0.11,0.02,0.17,0.04c-0.12-0.16-0.07-0.25-0.11-0.37c0.17,0.11,0.11,0.21,0.24,0.34c-0.1-0.12,0-0.2-0.1-0.29
				c0.12,0.06,0.07,0.02,0.19,0.13c-0.14-0.2,0.01-0.06-0.04-0.21c0.15,0.1,0.19-0.08,0.28,0.28c-0.02,0.01,0.13-0.11,0.1-0.41
				c-0.1,0.03,0.23-0.61,0.09-0.09c0.02-0.01,0.1-0.02,0.19-0.23c0.1,0.11,0.06,0.23,0.28-0.05c-0.29-0.27,0.2,0.23,2.95-2.21
				l0.13,0.14c-0.01-0.34,0,0.27,0.3-0.3c0.01,0.02,0.01,0.04,0.01,0.05c0.03-0.01,0.14,0.16,0.05-0.07
				c0.09,0.06,0.18,0.01,0.23-0.07c0.13,0.13,0.02,0.13,0.42-0.17c-0.08-0.01-0.15-0.18-0.12-0.22l0.1,0.13
				c-0.03-0.09-0.13-0.33,0.04,0.04l0.04-0.09c0.03,0.04,0.02,0.07,0.05,0.13c-0.02-0.1,0.19,0.08,0.14-0.09
				c-0.28,0.62,1.34-1.18,2.76-1.33c-0.01-0.03-0.11-0.19,0.05-0.04c-0.37-0.24,1.27-0.43,1.1-0.48c0.16-0.29,0.48-0.19,0.65-0.44
				c0,0.04,0.02,0.09-0.01,0.1c0.09,0.09,0.18-0.06,0.15-0.16c-0.17,0.05,0.45-0.02,0.64-0.35c0.07,0.22,0.21,0.11,0.24-0.01
				c0,0.07,0.07,0.05,0.08,0.15c0.06-0.02,0.11-0.08,0.06-0.19c0.21,0.21,0.16-0.29,0.34,0.02c0.15-0.18,0.3-0.32,0.45-0.43
				c0.14,0.2,0.02,0.13,0.68-0.15c0.01,0.02,0,0.05-0.01,0.07c0.03-0.02,0.08,0.01,0.04-0.09l-0.02,0.04
				c-0.09-0.13,0.02-0.33,0-0.41c0.04,0.3,0.17,0.03,0.2,0.41c0.01-0.02,0.02-0.08,0-0.14c0.26,0.09,0.05,0.02,0.76-0.11
				c-0.05-0.01,0.15-0.01,0.01-0.15c-0.01,0.08,0.2-0.24,0.14-0.03l0.12-0.07c-0.08-0.07-0.1-0.06-0.06-0.25
				c0.12,0.09,0.15,0,0.3,0.21c-0.01-0.16,0.15-0.09,0.16-0.26c0,0.04,0.02,0.14,0.03,0.15l0-0.13c-0.03,0.14,0.29-0.15,0.37,0.13
				l-0.01-0.11c0.03,0.21,0.28-0.34,0.66-0.23c-0.01,0.02,0.01,0.15,0.01,0.12c0.11,0.02,0.2-0.11,0.34-0.23
				c-0.06,0.03,0.28,0.21,0.24-0.08c0.19,0.12,0.26,0.42,0.71-0.32c-0.06,0.09,0.04,0.18,0.08,0.24c-0.01-0.03-0.01-0.07-0.02-0.11
				c0.11,0.28,0.03-0.21,0.11-0.06c0.03-0.03,0.19-0.18,0.09,0.06c0.19-0.07-0.06-0.02,0.34,0.1c-0.08-0.42-0.01-0.02,0.18-0.37
				c-0.04,0.23-0.05,0.04,0.1,0.4c0.03-0.13,0.1,0.08,0.14-0.11c0.01,0.05,0.05-0.01,0.06,0.12c-0.11-0.52,0.24,0.2,0.56-0.6
				c0.07,0.05-0.1,0.3,0.17,0.16c0,0.29,0.17-0.2,0.1,0.21c0.05-0.05,0.09-0.15,0.15-0.2c0.03,0.08-0.02,0.13-0.03,0.19
				c-0.01-0.03,0.13-0.05,0.11-0.25c-0.07,0.04-0.29,0.09-0.29-0.15c0.03,0.02-0.21,0.12-0.24-0.13c-0.19-0.04-0.29-0.05-0.28,0.36
				l-0.14-0.36l0.01,0.15c-0.02,0-0.05-0.01-0.05-0.07c-0.04,0.11,0.02,0.06,0,0.17c-0.1-0.13-0.19,0.1-0.25-0.14
				c-0.01,0.12,0.08,0.08,0.02,0.17c-0.02-0.31-0.18,0.18-0.18-0.23c-0.12,0.26-0.24-0.19-0.38,0.2c0.01-0.08-0.04,0.04-0.3-0.14
				c0.02,0.18-0.08,0.17-0.14,0.22c-0.02-0.11-0.02-0.15,0.01-0.21c-0.05,0.02-0.06,0.01-0.12,0.14l-0.01-0.14
				c-0.08,0.02-0.14,0.19-0.26,0.12c0.02,0.13,0.16,0.33,0.06,0.44c-0.02-0.07-0.05-0.18-0.02-0.22c-0.01,0.02-0.16-0.04-0.08-0.14
				c-0.05-0.01-0.11-0.05-0.14-0.12c0.01,0.05,0,0.31-0.11,0c-0.01,0.07-0.07,0.01-0.04,0.16c-0.02,0-0.04-0.06-0.06-0.1
				c-0.02,0.08,0,0-0.11,0.22l0,0c0.16,0.27-0.34-0.11-1.03,0.12c0.09-0.07-0.25-0.14-0.05-0.02c-0.35-0.15-0.67,0.48-0.95,0.12
				l0.01,0.06c-0.05-0.05-0.16-0.01-0.16-0.11c-0.04,0.07,0,0.1,0.05,0.12c-0.12-0.06-0.16,0.04-0.27,0.01
				c0.07,0.12-0.25,0.46-0.25-0.03c-0.06,0.14-0.19-0.02-0.25-0.02l0.03-0.06c-0.02,0.04-0.05,0.04-0.07,0.05l0.06,0.11
				c-0.03-0.01-0.06,0-0.07-0.05c-0.01,0.08,0.08,0.18,0.05,0.3c-0.06-0.05-0.1,0.1-0.15-0.05c0.13,0.02-0.06-0.15,0.03-0.2
				c-0.18-0.02-0.11-0.15-0.19,0.19c-0.01-0.09-0.08-0.15-0.09-0.13c0.05-0.01,0.07,0.13,0.04,0.21c-0.1-0.04-0.07,0.07-0.11,0.08
				c0.06,0.05,0.09,0.03-0.02,0.15c-0.03-0.06,0.03-0.06,0.01-0.08c-0.01,0.17-0.18-0.11-0.23,0.01c-0.06-0.05-0.05-0.16-0.1-0.19
				c0,0.2-0.23-0.17-0.17,0.25l0.13-0.13c-0.06,0.28,0.14,0.27-0.52,0.23c0.06,0.06,0.13,0.07-0.05,0.14
				c-0.03-0.09-0.07-0.12-0.11-0.17c0.03,0.07-0.11,0.03-0.06,0.22c-0.14-0.08-0.15-0.23-0.27,0.22c0-0.11-0.08-0.16-0.03-0.26
				c-0.09,0.12,0.02-0.03-0.18,0.44c-0.29-0.11,0-0.12-1.53,0.57c-0.02-0.13,0.02-0.08-0.05-0.19c-0.03,0.03,0.09,0.19,0.02,0.26
				c0.22-0.05-0.53-0.33-0.8,0.29c-0.01-0.15,0.06-0.26,0.08-0.37c-0.05,0.02-0.15,0.08-0.14,0.18c0-0.06-0.13-0.06-1.06,0.22
				c0.1,0.09,0.04,0.5-0.1,0.05c0.1,0.07,0.02,0.16,0.08,0.24c-0.09-0.02-0.23-0.13-0.38-0.35c0.06,0.09,0.03,0.16-0.04,0.14
				c0.15,0-0.17,0.23-0.15,0.02c-0.04,0.08-0.25,0.12-0.21,0.37c-0.03-0.03-0.08-0.09-0.06-0.13c0.05,0.04-0.83,0.48-2.22,1.13
				c0-0.02,0.15,0.22-0.06,0.2l0.02,0c-0.13-0.15-0.18-0.07-0.25-0.03l0.12,0.23c-0.35-0.23-0.35,0.37-0.61,0.13
				c0,0.08-0.05,0.11-0.06,0.19c-0.1-0.1-0.05-0.19-0.38,0.04l-0.03-0.1c0.06,0.22-0.38,0.08-0.61,0.7
				c-0.1-0.16-0.14,0.07-0.16,0.19c-0.09-0.28-0.05-0.01-0.64,0.47c0.09-0.17,0,0,0.23-0.57c0.03,0.04,0.13,0.01,0.04,0.17
				c0.11-0.06-0.05-0.23,0.4-0.47c-0.1-0.02,0.29-1.37,0.44-0.44c-0.13-0.09,0.24-0.55,0.06-0.54c0.25-0.12,0.18-0.13,0.39-0.13
				c-0.32-0.02,0.74-1.2,0.59-0.51c0.26-1.05,0.04,0.04,1.59-2.35c-0.17-0.11-0.33-0.11,0.05-0.43c-0.3,0.7,0.34,0.23,0.24,0.05
				c0.75,0.19,0.9-1.12,2.26-2.37l0.03,0.24c1.06-1.14-0.48-0.17,6.43-5.54l-0.07,0.1c0.4-0.22,0.26-0.54,0.68-0.66l-0.05,0.07
				c0.18,0.13,0.58-0.97,5.01-2.56c-0.02-0.06,0.19-0.2,0.72-0.32c-0.22-0.19,0.3,0.14,1.5-0.64c0,0.07-0.14,0.12-0.23,0.2
				c0.4-0.29,0.13,0.13,0.49-0.14c-0.07-0.02,0-0.1,0-0.13c0.23,0.03,0.7-0.1,1.04-0.21l-0.05,0.12c0.61-0.1,1.27-0.27,1.96-0.29
				c0.71-0.14,1.4-0.04,4.69,5.69l0-0.04c0.24,0.89-3.27,0.25-3.51,3.3c1.46,0.01,0.35-0.56,6.35,0l-0.23,0.11
				c0.5,0.21,0.73-0.19,2.84,1.45c-0.57-0.09,1.07,0.83,1.34,3.34c-0.22-0.04-0.26,0.6-0.29,0.84c-0.03-0.1-0.14,0.15-0.19,0.03
				c-0.12,0.22-0.2,0.44-0.09,0.47c-1.23,0.76-0.57,0.81-3.18,1.93c0.05-0.09,0.13-0.17,0.2-0.22c-0.63,0.13-0.66,0.26-3.33,0.7
				c0-0.02,0.07-0.05,0.14-0.08c-0.37,0.14,0.05-0.03-0.7,0.49c0.21-0.31-0.7-0.02-0.35-0.42c-0.09,0.02-0.2,0.08-0.18,0.14
				c-0.14-0.01-0.18-0.05-0.05-0.14c-0.49,0.08-0.31,0.18-0.76,0.2c0-0.05,0.09-0.07,0.13-0.13c-0.22,0.11-0.45,0.2-0.83,0.17
				c0.02-0.03-0.02-0.07,0.09-0.07c-0.65,0.03-1.12,0.25-1.84,0.14c0.12-0.1,0.14-0.27-0.66-0.23l0.15,0.1
				c-0.34-0.02-0.65,0.09-0.93,0.02l0.26-0.06l-0.64-0.01l0.35,0.1c-0.35,0.07,0.02,0.24-0.43,0.15c0.09-0.01-0.34-0.03-0.51-0.15
				l0,0c-0.78,0.1-0.12-0.35-1.59-0.11c0.2-0.22-0.47-0.28-0.65-0.1c0.05-0.01,0.03-0.03,0.12-0.04c-0.61-0.05-0.86-0.11-1.05-0.18
				l0.16,0c-0.75-0.14,0.07-0.02-2.71-0.22l0.13,0.03c-0.85,0.14-0.45-0.42-2.6-0.18l0.1-0.07c-1.38,0.09-1.12-0.2-4.03,0.13
				c0.16-0.08,0.21-0.1-0.58,0.08c0.07,0.11,0.11,0.06,0.27,0.13c-0.09,0.08-0.29,0.16-0.47,0.18c0.32-0.12-0.03-0.18-0.18-0.23
				c0.03,0.03-0.01,0.08,0,0.11c-0.41-0.15-0.03,0.23-0.57,0.18c0.02-0.1,0.02-0.25-1.19,0.2c0.12-0.06,0.3-0.1,0.42-0.16
				c-0.32-0.1-0.35-0.05-0.57-0.03l0.02,0l-0.52,0.04l0.14,0.01c-0.29,0.41-0.73,0.24-1.17,0.58c0.23-0.11,0.2-0.55-0.94-0.07
				c0.04-0.09-1.75,0.74-4.08,1.76c0.07-0.2,0.09-0.48-1.08,0.58l0.2-0.06c-0.06-0.06-0.07-0.31,0.35-0.25l-0.16,0.12
				c0.31,0.15,0.69-0.39,1.05-0.56c0.05,0.13,0.04,0.31,0.98-0.16c-0.03,0.06,0.1,0.12-0.07,0.16c0.49-0.09,0.68-0.44,0.91-0.37
				l-0.03,0.03c0.78-0.25,2.06-0.69,4.66-1.4c-0.02,0.06,0.83-0.06,1.25-0.06l-0.01,0.03c0.05-0.2,0.47-0.22,0.77-0.3
				c0,0.11-0.05,0.16-0.2,0.23c0.03-0.04,0.05,0.08,0.61-0.19c-0.14,0.37,0.14,0.09,1.46-0.32l-0.2,0.13
				c0.09-0.05,0.68-0.04,0.18,0.05c0.92,0.06,0.39,0,2.24-0.11l0,0c0.66-0.14,0,0,2,0.13c-0.04,0.21,0.63,0.06,2.11,0.27
				c-0.31,0.05,0.09,0.09-0.31,0.03c0.38,0.08,0.46,0.17,0.88,0.09c-0.15,0.11,0.27,0.19,0.46,0.17l-0.26-0.12
				c0.21,0,0.39,0.04,0.57,0.07l-0.12,0.15c0.82,0.16,1.77,0.23,3.64,0.22c-0.34-0.43,0.92,0.47,0.75,0.12
				c0.46,0.12,0.73,0.05,1.22,0.13c-0.07-0.08,1.42-0.43,1.06,0.14c0.45-0.1,0.83,0.08,1.09,0.1l-0.24,0.05
				c0.14-0.03,0.26-0.03,0.37-0.03l-0.14-0.12c0.14,0.02,0.26,0.02,0.25,0.07c0.27-0.2-0.03-0.4,0.79-0.24
				c-0.56-0.03,0.02,0.14-0.38,0.2c0.59,0.06,0.31,0.16,1.08-0.19c-0.07,0.09,0.15,0.16,0.24,0.13c-0.18,0.02,0-0.23,0.67-0.32
				l-0.22-0.01c0.36-0.33,0.99,0.06,2.56-0.14c-0.16-0.09-0.17-0.05,0.12-0.24c-0.24,0.08-0.49,0.17-0.74,0.24
				c0.15-0.08,0.3-0.17,0.45-0.27c-0.22,0.05-0.46,0.17-0.61,0.17c0.21-0.14,0.19-0.1,0.07-0.18c1.13-0.2,0.67,0.51,2.67-0.47
				l-0.24,0.03c1.1-0.55,0.41,0.19,2.48-1.88c-0.03,0.21,0.21,0.04,0,0.4C122.96,380.61,122.52,382.22,122.62,377.25z M94.31,373.71
				c0.01-0.11-0.02-0.12,0.15-0.19C94.43,373.57,94.43,373.5,94.31,373.71z M70.03,372.01c-0.14,0-0.13,0.02-0.25-0.16
				C69.85,371.88,69.76,371.87,70.03,372.01z M87.62,384.03c-0.04,0.01-0.09,0.08-0.1-0.05C87.52,384.01,87.57,384.01,87.62,384.03z
				 M87.48,384.13c-0.01,0.02,0,0-0.03,0.03C87.46,384.16,87.46,384.15,87.48,384.13z M86.9,383.64c0.01-0.01,0.01-0.02,0.02-0.03
				C86.92,383.63,86.91,383.64,86.9,383.64z M84.66,384.96c0.07,0.01,0.1,0.02,0.14,0.04c-0.03,0.04-0.05,0.07-0.08,0.11
				C84.67,385.06,84.68,385.01,84.66,384.96z M84.83,384.95c-0.01-0.02-0.02-0.03-0.07-0.07l0.1,0.03
				C84.86,384.92,84.84,384.94,84.83,384.95z M84.89,384.88c0-0.01,0-0.01,0-0.02l0.02,0C84.9,384.87,84.89,384.87,84.89,384.88z
				 M80.44,357.31l-0.15,0.07C80.3,357.39,80.27,357.31,80.44,357.31z M79.95,357.52c-0.01,0.06,0.02,0.01-0.16,0.1
				C79.79,357.57,79.77,357.62,79.95,357.52z M79.95,357.59c0,0.01,0,0.01,0,0.01C79.92,357.59,79.92,357.59,79.95,357.59z
				 M79.77,356.89C79.73,356.87,79.74,356.87,79.77,356.89L79.77,356.89z M69.09,370.21c0.06,0.01-0.08-0.14-0.17-0.27
				C69.01,370.01,69.33,370.21,69.09,370.21z M69.68,370.55c0.09,0.06,0,0,0.2,0.15C69.83,370.67,69.78,370.63,69.68,370.55z
				 M83.31,385.77c-0.21-0.11,0.1-0.65-1.17-2.29c0.46,0.29-0.44-0.86-0.14-0.88c-0.37-0.19-0.74-1.04-1.2-1.47
				c0.03,0.02,0.1,0.05,0.13,0.1c0.38-0.25-1.59-1.17-0.68-1.27c-0.11,0.11-0.58-0.49-0.59-0.29c-0.11-0.33-0.13-0.25-0.11-0.49
				c-0.03,0.35-1.32-1.22-0.55-0.84c-1.13-0.5,0.05-0.18-2.76-2.37c-0.13,0.21-0.11,0.31-0.49-0.12c0.82,0.43,0.22-0.33,0.03-0.26
				c0.09-0.84-1.4-1.15-3.16-2.7l0.27-0.03c-1.61-1.17-0.12,0.31-8.21-6.91l0.13,0.07c-0.36-0.44-0.67-0.24-0.92-0.72l0.08,0.06
				c-0.18-0.3-2.67-3.09-2.03-6.01l0.14,0.05c-0.07,0,0.07-0.4,0.41-0.72c-0.21-0.01,0.15-0.13,1.43-1.05
				c0,0.07-0.15,0.14-0.25,0.23c0.86-0.41,0.12-0.16,1.79-0.41l-0.08,0.1c0.74,0.01,1.55,0.13,2.33,0.55
				c1.47-0.16,5.31,5.6,3.23-4.11l0,0.05c0.3-0.53,0.99-3.57,4.35-2.2l0.01-0.1c0.74,0.47,0.72,0.05,4.37,6.11l-0.31-0.11
				c0.59,0.57-0.07-0.06,2.04,1.97c0.06-0.55-0.8-1.04-0.9-6.2c0.44,0.64-0.42-1.56,2.05-4.07c0.09,0.19,0.82-0.28,1.1-0.47
				c-0.08,0.1,0.28-0.03,0.2,0.11c0.33-0.12,0.65-0.23,0.56-0.33c1.84,0.12,1.35-0.41,4.79,0.43c-0.2,0-0.34,0-0.44-0.01
				c0.64,0.34,0.89,0.08,3.52,2.33c-0.02,0.01-0.1-0.04-0.17-0.09c0.4,0.27-0.06-0.04,0.94,0.4c-0.41-0.04,0.49,0.72-0.1,0.59
				c0.08,0.07,0.21,0.15,0.25,0.09c0.08,0.15,0.08,0.21-0.08,0.12c0.39,0.47,0.36,0.23,0.67,0.69c-0.04,0.02-0.12-0.06-0.2-0.07
				c0.24,0.17,0.45,0.38,0.65,0.81c-0.04-0.01-0.05,0.06-0.12-0.07c0.39,0.69,0.84,1.13,1.06,2c-0.15-0.1-0.3-0.07,0.03,0.85
				l0.04-0.2c0.13,0.39,0.3,0.74,0.32,1.1l-0.14-0.29c0.06,0.25,0.13,0.5,0.16,0.76c0-0.15,0.02-0.3,0.01-0.45
				c0.16,0.41,0.23-0.08,0.25,0.49c-0.03-0.11,0.05,0.42-0.04,0.65l0,0c0.2,0.91-0.28,0.23,0.08,1.98c-0.24-0.23-0.26,0.6-0.09,0.81
				c-0.01-0.06-0.03-0.03-0.04-0.15c-0.09,0.72-0.15,1.03-0.21,1.26c0-0.05,0.01-0.11,0.01-0.16c-0.19,0.92,0.02-0.07-0.65,3.3
				l0.07-0.16c-0.11,1.04-0.5,0.52-1.04,3.05l-0.02-0.13c-0.61,1.62-0.59,1.22-2.12,4.5c0.07-0.28,0.13-0.43-0.33,0.64
				c0.14-0.01,0.12-0.08,0.29-0.21c0.01,0.14-0.07,0.39-0.17,0.6c0.13-0.4-0.17-0.08-0.31,0.05c0.05-0.01,0.05,0.06,0.09,0.07
				c-0.41,0.33,0.17,0.18-0.26,0.7c-0.07-0.09-0.14-0.22-0.73,1.32c0.04-0.16,0.15-0.36,0.19-0.52c-0.31,0.25-0.3,0.31-0.45,0.54
				l0.02-0.02l-0.36,0.53l0.11-0.12c0.09,0.58-0.39,0.88-0.48,1.54c0.09-0.64-0.06-0.15-0.18-0.26c-0.11,0.12-0.27,0.32-0.27,0.24
				c-1.09,2.35-0.31,1.91-4.11,6.52c-0.02-0.05-0.03-0.1,0.02-0.14c-0.13-0.02-0.36,0.04-0.44-0.08c0.07,0,0.19,0.01,0.21,0.05
				c0.12-0.12-0.11,0.04,0.32-0.13c-0.05-0.01-0.29-0.1,0.04-0.11c-0.07-0.03,0.01-0.07-0.13-0.09c0-0.02,0.07-0.02,0.11-0.03
				c-0.07-0.04,0,0-0.17-0.17l0,0c-0.3,0.06,0.21-0.29,0.21-1c0.04,0.11,0.22-0.19,0.03-0.04c0.27-0.28-0.23-0.79,0.2-0.92
				l-0.06-0.01c0.06-0.04,0.06-0.14,0.16-0.11c-0.05-0.06-0.1-0.03-0.13,0.01c0.09-0.1,0.01-0.16,0.07-0.26
				c-0.14,0.02-0.35-0.38,0.12-0.22c-0.11-0.1,0.08-0.17,0.11-0.23l0.05,0.05c-0.03-0.03-0.02-0.06-0.02-0.08l-0.12,0.02
				c0.02-0.03,0.02-0.05,0.07-0.05c-0.08-0.03-0.2,0.02-0.3-0.05c0.06-0.04-0.06-0.12,0.1-0.13c-0.06,0.12,0.16-0.01,0.18,0.1
				c0.08-0.16,0.18-0.05-0.12-0.24c0.09,0.02,0.17-0.02,0.15-0.04c0,0.05-0.15,0.02-0.22-0.03c0.07-0.08-0.05-0.09-0.04-0.13
				c-0.06,0.04-0.06,0.08-0.14-0.07c0.07,0,0.05,0.05,0.08,0.03c-0.16-0.06,0.16-0.13,0.06-0.22c0.06-0.04,0.17,0,0.21-0.03
				c-0.19-0.06,0.24-0.16-0.18-0.24l0.08,0.16c-0.25-0.15-0.3,0.04-0.04-0.56c-0.07,0.03-0.11,0.1-0.11-0.09
				c0.1,0,0.14-0.03,0.2-0.05c-0.07,0,0-0.11-0.19-0.12c0.12-0.11,0.27-0.07-0.13-0.32c0.11,0.03,0.18-0.02,0.26,0.06
				c-0.09-0.12,0.03,0.02-0.36-0.31c0.19-0.24,0.15,0.04-0.06-1.62c0.13,0.02,0.07,0.04,0.2,0.01c-0.32,0.22,0.35-0.41-0.28-0.89
				c0.15,0.04,0.24,0.13,0.33,0.19c0-0.05-0.03-0.17-0.13-0.18c0.01,0.07,0.02,0.09-0.21-0.15c0.38-0.02,0.07-0.26,0.36-0.37
				c-0.16-0.16,0.03-0.35-0.03-0.55c-0.12,0.07-0.49-0.11-0.02-0.11c-0.1,0.07-0.16-0.03-0.25,0.01c0.05-0.08,0.19-0.18,0.44-0.26
				c-0.11,0.03-0.16-0.01-0.13-0.08c-0.04,0.15-0.17-0.23,0.03-0.15c-0.07-0.07-0.04-0.28-0.29-0.31c0.03-0.02,0.11-0.05,0.14-0.02
				c-0.04,0.03-0.22-0.92-0.42-2.43c0.02,0-0.26,0.08-0.17-0.12l0,0.02c0.19-0.08,0.12-0.15,0.11-0.22l-0.26,0.05
				c0.34-0.27-0.25-0.44,0.06-0.61c-0.07-0.03-0.09-0.08-0.16-0.11c0.12-0.07,0.2,0,0.08-0.36l0.11,0.01
				c-0.23,0,0.04-0.38-0.47-0.78c0.18-0.05-0.02-0.15-0.12-0.21c0.36-0.04-0.09-0.02-0.25-0.87c0.03,0.1,0.04,0.18,0.03,0.18
				c0.13,0.07,0.25-0.54-0.16-0.51c0.01-0.01,0.01,0.02,0.05,0.04l-0.01,0c0.01,0.03,0.02,0.06,0.04,0.1
				c-0.08-0.01-0.15-0.03-0.08-0.09c-0.09,0-0.19,0.01-0.23,0.01c0.02-0.13,0.39-0.3-0.15-0.77c0.41-0.41,0.21-0.05-0.12-0.72
				c-0.02,0.02-0.02,0.04-0.05,0.05c-0.03-0.16,0.19-0.38-0.37-1.02l0.14-0.06c-0.21,0.33,0.04-0.57-0.65-0.95
				c0.01,0.01,0.01,0.01,0.02,0.01c-0.13,0.05-0.31,0.14-0.58,0.28l0.16,0.04l-0.1,0.06l-0.03-0.07c-0.09,0.08,0.01,0.07,0.08,0.08
				c-0.05,0.03-0.09,0.05-0.11,0.08l0.24,0.02c-0.08,0.04-0.18,0.02-0.21,0.09c0.11,0.01,0.18-0.1,0.27-0.06
				c-0.09,0.08-0.17,0.09-0.07,0.11c-0.05,0.03-0.09,0.03-0.13,0.02c0.08,0.02,0.07,0.11,0.19,0.09c-0.11,0.08,0.08,0.14-0.13,0.2
				c0.05,0,0.19,0.03,0.21,0.06c-0.28,0.01,0.03,0.16-0.24,0.22c0.24-0.02,0.16,0.17,0.32,0.22c-0.02,0-0.06,0-0.05-0.01
				c0,0.06-0.06,0.1-0.1,0.15c0.19-0.05,0.26,0.02,0.39,0.03c-0.16,0.12-0.23,0.03-0.4,0.1c0.15-0.05,0.19,0.07,0.31,0.01
				c-0.1,0.09-0.04,0.06-0.19,0.14c0.24-0.06,0.05,0.04,0.21,0.03c-0.09,0.11-0.21,0.09-0.12,0.15c-0.35,0.15,0.09-0.03,0.33,0.49
				c0.01,0.02,0.23-0.17,0.26,0c0-0.02-0.05-0.01-0.09-0.01l0.06,0.02c-0.25,0.09-0.2-0.12-0.2,0.18l0.03-0.11
				c-0.19,0.17-0.36-0.03-0.14,0.33c0.35-0.18-0.28,0.12,1.18,3.48l-0.17,0.08c0.32,0.1-0.26-0.08,0.19,0.37
				c-0.03,0-0.05-0.01-0.07-0.02c0.06,0.04-0.21,0.1,0.06,0.08c-0.08,0.07-0.06,0.17,0,0.24c-0.16,0.08-0.13-0.02,0.04,0.45
				c0.04-0.07,0.22-0.09,0.25-0.05l-0.16,0.05c0.1,0,0.36-0.03-0.05,0.03l0.07,0.06c-0.05,0.01-0.08,0-0.14,0.01
				c0.11,0.01-0.13,0.16,0.05,0.16c-0.51-0.44,0.72,1.61,0.44,3c0.03,0,0.22-0.05,0.02,0.06c0.33-0.28,0.03,1.32,0.12,1.18
				c0.21,0.23,0.03,0.51,0.21,0.74c-0.04-0.01-0.09-0.01-0.09-0.03c-0.12,0.06,0.01,0.19,0.1,0.19c-0.01-0.17-0.12,0.43,0.12,0.71
				c-0.23,0-0.18,0.17-0.07,0.22c-0.07-0.02-0.07,0.05-0.16,0.03c0,0.07,0.05,0.13,0.16,0.11c-0.27,0.13,0.22,0.24-0.12,0.32
				c0.13,0.2,0.2,0.38,0.26,0.56c-0.23,0.06-0.14-0.02-0.07,0.68c-0.02,0-0.04-0.02-0.06-0.03c0.01,0.03-0.03,0.07,0.07,0.07
				l-0.03-0.03c0.15-0.04,0.31,0.13,0.39,0.13c-0.29-0.06-0.08,0.15-0.45,0.06c0.02,0.02,0.07,0.05,0.14,0.04
				c-0.17,0.21-0.03,0.04-0.15,0.75c0.03-0.04-0.04,0.14,0.14,0.06c-0.08-0.03,0.16,0.26-0.01,0.14l0.03,0.14
				c0.09-0.05,0.08-0.08,0.25,0.03c-0.13,0.09-0.05,0.14-0.3,0.22c0.15,0.05,0.03,0.17,0.2,0.23c-0.04-0.02-0.14-0.03-0.16-0.02
				l0.12,0.04c-0.09,0.05,0.01,0.06-0.14,0.33c-0.02-0.01,0.01-0.1-0.11-0.02l0.1,0.03c-0.21-0.04,0.22,0.37,0,0.69
				c-0.02-0.02-0.14-0.04-0.12-0.03c-0.05,0.1,0.04,0.22,0.11,0.39c-0.01-0.07-0.29,0.19,0,0.25c-0.19,0.1-0.48,0.29,0.07,0.77
				c-0.07-0.08-0.18-0.02-0.25,0c0.04,0,0.07,0.02,0.11,0.02c-0.3,0.01,0.19,0.1,0.02,0.12c0.02,0.04,0.12,0.24-0.09,0.07
				c0.01,0.2,0.03-0.05-0.2,0.28c0.35,0.05,0.15,0.02,0.21,0.17c-0.14,0.03-0.03,0.09-0.32,0.09c0.11,0.07-0.08,0.07,0.05,0.16
				c-0.04,0.04,0,0.01-0.12,0.03c0.04,0,0.06,0.01,0.08,0.01c-0.23,0.29-0.02,0.01-0.46,0.71
				C83.27,386.19,83.08,385.83,83.31,385.77z M83.57,368.5c0.03-0.01,0.05-0.01,0.07-0.01C83.66,368.5,83.64,368.57,83.57,368.5z
				 M82.89,369.03c-0.03,0-0.05,0.05-0.08,0.08C82.81,369.07,82.76,369.01,82.89,369.03z M82.76,368.95c-0.01-0.01,0,0-0.02-0.04
				C82.74,368.92,82.75,368.93,82.76,368.95z M84.62,386.55l-0.05,0.05c0.01-0.02,0.02-0.04,0.03-0.06L84.62,386.55z M86.09,385.49
				c0,0.06,0,0.1,0,0.14C86.04,385.52,85.87,385.29,86.09,385.49z M86.34,385.02c0.01-0.01,0.01-0.01,0.01-0.01L86.34,385.02
				C86.34,385.03,86.34,385.02,86.34,385.02z M86.52,384.95c0-0.01,0.01-0.02,0.01-0.03c0,0.01,0,0.02,0.01,0.03L86.52,384.95z
				 M86.88,383.84c0-0.04,0-0.07,0.01-0.11c0.02,0.06,0.04,0.12,0.06,0.15C86.92,383.85,86.9,383.85,86.88,383.84z M95.47,373.88
				c0.03-0.03,0.07-0.07,0.14-0.14C95.56,373.8,95.62,373.74,95.47,373.88z M95.95,373.31c-0.01,0.05,0.13-0.04,0.25-0.09
				C96.13,373.29,95.93,373.54,95.95,373.31z M115.59,373.69c-0.05,0.03-0.12,0.04-0.18,0.07
				C115.46,373.72,115.44,373.67,115.59,373.69z"
                />
                <path
                  className="st10"
                  d="M106.91,365.55c0.02-0.03,0.04,0.06,0.29-0.15L106.91,365.55z"
                />
                <path
                  className="st10"
                  d="M88.97,433c0-0.05-0.03-0.21,0.09-0.02c-0.47-0.58,0.98,0.34,2.66-0.42c-0.01,0.04,0,0.09-0.04,0.1
				c0.09,0.1,0.26-0.05,0.24-0.15c-0.16,0,0.52,0.05,0.93-0.34c0.06,0.22,0.27,0.1,0.31-0.02c0,0.07,0.09,0.04,0.09,0.14
				c0.09-0.03,0.15-0.1,0.1-0.2c0.26,0.19,0.24-0.33,0.46-0.04c0.34-0.3,0.09-0.18,1.45-0.98c0.01,0.02,0,0.05,0,0.08
				c0.03-0.03,0.1-0.03,0.04-0.11l-0.02,0.05c-0.13-0.09-0.04-0.35-0.08-0.42c0.11,0.28,0.21-0.05,0.33,0.31
				c0.01-0.03,0.01-0.09-0.03-0.14c0.37-0.07-0.01,0.04,0.93-0.69l-0.1-0.04c0.08-0.07,0.05-0.22,0.15-0.24l0,0.11l0.1-0.16
				l-0.12,0.02c0.03-0.22-0.18-0.22,0.38-0.25c-0.08-0.14,0.12-0.2,0.04-0.36c0.02,0.04,0.09,0.11,0.11,0.11l-0.07-0.11
				c0.14-0.01,0.05-0.09,0.37-0.28l-0.02,0.08l0.1,0l-0.07-0.08c0.15,0.13,0.05-0.43,0.46-0.8c0,0.03,0.11,0.11,0.09,0.08
				c0.11-0.1,0.11-0.28,0.13-0.51c-0.05,0.12,0.38-0.06,0.13-0.3c0.21-0.12,0.56-0.05,0.21-0.99c0.04,0.11,0.18,0.06,0.25,0.04
				c-0.04,0-0.07-0.03-0.1-0.04c0.3,0.02-0.15-0.13,0.02-0.16c-0.01-0.04-0.07-0.32,0.11-0.08c0.01-0.25,0,0.02,0.26-0.37
				c-0.42-0.09-0.03,0-0.27-0.38c0.19,0.14,0.04,0.08,0.41,0.02c-0.11-0.09,0.11-0.1-0.05-0.23c0.04,0,0.03-0.04,0.07-0.05
				c-0.08-0.05-0.09-0.22-0.23-1.07c0.3,0.04-0.19-0.25,0.22-0.1c-0.05-0.08-0.14-0.15-0.19-0.22c0.08-0.03,0.13,0.04,0.19,0.06
				c-0.02,0.01-0.05-0.21-0.28-0.17c0.37,0.07-0.31-0.08,0.07,0.4l-0.21-0.03c0.29,0.11,0.02,0.09-0.03,0.31
				c-0.04-0.01-0.15,0.01-0.12-0.03c-0.06,0.2-0.12,0.34,0.3,0.43l-0.38,0.07l0.15,0.03c-0.01,0.02-0.02,0.06-0.08,0.04
				c0.1,0.08,0.07-0.01,0.16,0.04c-0.14,0.08,0.03,0.27-0.21,0.26c0.11,0.05,0.1-0.08,0.17,0.03c-0.3-0.09,0.11,0.27-0.28,0.14
				c0.27,0.18-0.3,0.12,0.02,0.52c-0.08-0.05,0.03,0.05-0.27,0.27c0.17,0.07,0.1,0.17,0.11,0.26c-0.1-0.04-0.14-0.07-0.17-0.12
				c-0.01,0.06-0.03,0.07,0.05,0.2l-0.12-0.06c-0.02,0.1,0.09,0.25-0.05,0.34c0.12,0.06,0.37,0.03,0.4,0.2
				c-0.07-0.02-0.18-0.06-0.19-0.11c0.02,0.01-0.15,0.16-0.16,0c-0.04,0.04-0.12,0.08-0.19,0.07c0.05,0.03,0.24,0.2-0.07,0.11
				c0.05,0.05-0.04,0.08,0.09,0.14c-0.01,0.03-0.08,0-0.12-0.01c0.05,0.07,0,0,0.09,0.25l0,0c0.11,0.2-0.35,0.32-0.64,0.92
				c-0.02-0.11-0.08-0.02,0-0.1c-0.1,0.06-0.17,0.03-0.13,0.2c0.04-0.13-0.31,0.08-0.06,0.03c-0.36,0.09-0.4,0.94-0.85,0.78
				l0.03,0.05c-0.08-0.01-0.16,0.09-0.21,0c-0.01,0.09,0.04,0.09,0.1,0.08c-0.15,0.02-0.14,0.13-0.28,0.17
				c0.12,0.06-0.07,0.59-0.27,0.1c-0.02,0.16-0.21,0.07-0.28,0.09l0.02-0.07c-0.01,0.04-0.03,0.06-0.06,0.08l0.1,0.07
				c-0.04,0-0.06,0.02-0.09-0.02c0.02,0.08,0.14,0.14,0.15,0.27c-0.07-0.02-0.08,0.14-0.18,0.02c0.15-0.05-0.11-0.12-0.02-0.21
				c-0.18,0.06-0.16-0.1-0.16,0.27c-0.03-0.07-0.08-0.09-0.11-0.09c0.04,0.03,0.09,0.15-0.03,0.31c0.08,0.02,0.11-0.01,0.01,0.16
				c-0.04-0.06,0.03-0.07-0.01-0.09c0.02,0.17-0.22-0.04-0.26,0.09c-0.07-0.03-0.09-0.14-0.14-0.16c0.03,0.21-0.28-0.13-0.16,0.3
				l0.13-0.17l-0.03,0.21c0.05-0.03,0.07-0.13,0.12-0.13c-0.01,0.11-0.01,0.09,0.06,0.16c-0.36,0.31-0.15-0.47-0.73,0.11
				c0.07,0.05,0.15,0.05-0.06,0.15c-0.04-0.09-0.08-0.11-0.13-0.15c0.03,0.07-0.13,0.04-0.07,0.23c-0.18-0.07-0.17-0.21-0.33,0.26
				c0-0.12-0.08-0.16-0.02-0.27c-0.11,0.13,0.03-0.03-0.25,0.46c-0.19-0.17-0.2-0.16-0.91,0.09c0-0.03-0.01-0.05,0.01-0.04
				c-0.39,0.3,0.06-0.36-0.77,0.12c0,0,0-0.03-0.01-0.05c-0.06,0.18-0.22,0.26-0.33,0.32c-0.01-0.28,0.03-0.19-0.22-0.2
				c0.34-0.64-0.59,0.67-0.59,0.05c-0.06,0.05-0.21,0.2-0.32,0.25c0.06-0.15,0.18-0.23,0.26-0.32c-0.06,0-0.21,0.03-0.23,0.13
				c0.09-0.01,0.11-0.02-0.21,0.2c0.01-0.38-0.33-0.09-0.41-0.39c-0.22,0.14-0.42-0.07-0.67-0.05c0.09,0.07-0.22,0.49-0.13,0
				c0.06,0.1-0.06,0.14-0.06,0.22c-0.08-0.07-0.16-0.21-0.2-0.46c0.01,0.11-0.06,0.15-0.12,0.11c0.17,0.09-0.31,0.11-0.17-0.07
				c-0.1,0.05-0.33-0.04-0.45,0.2c-0.01-0.04-0.02-0.12,0.02-0.14c-0.38-0.22-1.12,0.11-2.94-0.66c0.02-0.02-0.05,0.27-0.22,0.08
				l0.02,0.01c0.02-0.2-0.1-0.18-0.18-0.22l-0.1,0.25c-0.09-0.43-0.61-0.07-0.6-0.44c-0.08,0.04-0.14,0.01-0.21,0.05
				c0.02-0.17,0.11-0.14-0.3-0.31l0.08-0.08c-0.37,0.13-0.12,0.28-0.8-0.17c0.02-0.03,0.05-0.08,0.08-0.07
				c-0.07-0.05-0.2-0.29-0.33-0.03c0.1-0.16-0.14-0.11-0.27-0.1c0.26-0.27-0.07,0.08-0.84-0.69c0.09,0.09,0.15,0.16,0.14,0.16
				c0.16-0.03-0.05-0.61-0.45-0.45c0-0.02,0.02,0.02,0.07,0.02l-0.01,0.01c0.02,0.02,0.05,0.05,0.09,0.08
				c-0.08,0.02-0.15,0.03-0.13-0.06c-0.08,0.04-0.17,0.08-0.22,0.09c-0.11-0.13-0.17-0.54-0.77-1.68c-0.02,0.03-0.02,0.04-0.05,0.06
				c-0.07-0.26,0.2-0.27-0.3-1.29l0.15-0.03c-0.42-0.17,0.25-0.5-0.04-1.34c0.01,0,0,0,0.01,0.01c-0.17-0.15-0.01,0-0.74,0.05
				l0.03-0.11c-0.12,0.03-0.05,0.1,0,0.16c-0.06-0.01-0.11,0-0.13,0.02l0.18,0.19c-0.08,0-0.41-0.03,0.02,0.08
				c-0.12,0.04-0.2,0.01-0.13,0.1c-0.06,0-0.1-0.02-0.12-0.06c0.06,0.08,0,0.19,0.12,0.24c-0.14,0.05,0,0.23-0.21,0.21
				c0.05,0.03,0.17,0.13,0.17,0.2c-0.26-0.13-0.02,0.23-0.3,0.2c0.24,0.09,0.11,0.31,0.26,0.45c-0.02-0.01-0.06-0.02-0.05-0.03
				c-0.01,0.09-0.08,0.12-0.12,0.18c0.19-0.01,0.26,0.11,0.39,0.15c-0.17,0.13-0.24-0.02-0.41,0.04c0.16-0.03,0.19,0.15,0.31,0.09
				c-0.1,0.1-0.04,0.08-0.19,0.15c0.24-0.04,0.06,0.06,0.22,0.09c-0.12,0.06,0.04,0.25-0.28,0.18c0.35-0.09-0.02,0.55,0.88,0.71
				c-0.01-0.03-0.06-0.01-0.1-0.01l0.07,0.03c-0.33-0.12-0.16,0.34-0.15,0.11c0.2,0.07-1.69,0.34,4.09,3.44l-0.01,0.19
				c0.26-0.21-0.14,0.21,0.56,0.1c-0.01,0.02-0.02,0.03-0.04,0.04c0.03,0.03-0.03,0.21,0.11,0c0.03,0.11,0.17,0.16,0.29,0.15
				l-0.05,0.1c0.18,0.09,0.41,0.07,0.62,0.13c-0.06-0.07,0-0.24,0.07-0.24l-0.02,0.16c0.05-0.09,0.15-0.33,0.01,0.06l0.11-0.03
				c-0.01,0.05-0.04,0.07-0.06,0.13c0.06-0.09,0.13,0.2,0.23,0.04c0.09,0.11,0.81,0.09,1.18-0.05c-0.02,0.13,0.14,0.14,0.04,0.22
				c0.19,0.01,0.24,0.02,0.49-0.05c-0.04-0.08,0.01-0.21,0.04,0.02c0.29-0.15,0.57,0.46,0.81,0.21l-0.01,0.01
				C87.8,432.6,87.65,432.82,88.97,433z M80.01,429.6c0.01,0.01,0.02,0.02,0.05,0.04C80.03,429.63,80.05,429.64,80.01,429.6z
				 M80.23,429.8c0.02-0.01-0.15-0.08,0.01-0.13C80.21,429.69,80.23,429.74,80.23,429.8z M80.54,428.83
				c0.02-0.02,0.04-0.03,0.06-0.04C80.63,428.81,80.61,428.85,80.54,428.83z"
                />
                <path
                  className="st10"
                  d="M164.69,445.17l-0.04,0.14c0.01-0.05,0.39-0.16,1.24-0.35l-0.13,0.1c0.39-0.06,1.24-0.08,2.67-0.16
				c-0.42,0.02-0.77,0.04-0.73,0.03c0.01,0.13,0.35,0.13,0.09,0.32c0.28-0.06-0.03-0.1,1.11-0.28l-0.03,0.02
				c0.46-0.06,0.26,0.06,1.05,0.01c-0.02-0.14,0.2-0.13-0.02-0.23c0.05,0.01-0.08,0.01-0.22,0.05l0.01-0.01
				c-0.11,0.01-0.26,0.02-0.42,0.04c1.13-0.43,0.77,0.19,3.22-0.27c-0.39,0.17-0.38,0.1,1.02-0.08l-0.07,0.06
				c1.03,0.23,1.61-0.57,2.75-0.27c-0.08-0.02-0.12-0.03-0.14-0.06c0.54,0.29,3.15-0.42,6.54-0.34l-0.09-0.1
				c0.76,0.12,1.08,0.05,1.94-0.9c-0.14-0.03-0.23-0.15-0.82,0.2c0.03-0.44-0.53,0.33-0.47-0.16c-0.11,0.12-0.4-0.24-1.77,0.26
				c0.31-0.28-0.67,0.02-0.6-0.26c-0.23,0.25-0.87,0.16-1.29,0.31c0.02-0.02,0.07-0.06,0.11-0.05c-0.24-0.01-0.32-0.07-0.47-0.11
				c0.27-0.01-0.95,0.69-0.57,0.06c-0.12,0.09-0.44,0.14-0.35,0.24c-0.23-0.1-0.2-0.04-0.32-0.2c0.19,0.25-1.24,0.29-0.7-0.08
				c-0.05,0.37-1.46,0.03-2.8,0.76c-0.38-0.8,0.15-0.35-3.05,0.01l0.12-0.2c-1.39,0.33,0.26-0.03-7.65,0.61l0.1-0.05
				c-0.41-0.03-0.44,0.33-0.83,0.22l0.07-0.03c-0.24-0.05-0.17-0.21-7.29,0.42c0.01-0.06,0.15-0.09,0.25-0.14
				c-0.42,0.19-0.09-0.16-0.48,0.02c0.06,0.03-0.02,0.09-0.03,0.13c-0.21-0.08-0.66-0.04-0.99,0.02l0.06-0.11
				c-0.58,0.02-1.22,0.14-1.87,0.19c-1.31-0.01,0.25-0.06-9.23,0.81l0.04-0.02c-1.6,0.1-1.66,0.2-10.33,0.94l0.17-0.18
				c-1.04,0.29-2.17,0.19-3.19,0.45c0.06-0.04,0.06-0.09,0.16-0.1c-0.23-0.1-0.81,0.07-0.81,0.17c0-0.08-1.01-0.02-3.01,0.37
				c0.01-0.23-0.7-0.12-0.94,0.01c0.08-0.08-0.22-0.05-0.13-0.15c-0.28,0.03-0.53,0.1-0.47,0.21c-0.94-0.14-0.67-0.09-3.67,0.48
				c0.05-0.07,0.12-0.12,0.18-0.16c-1.07,0.11-0.91,0.07-3.06,0.38c0-0.02,0.07-0.05,0.13-0.07c-0.34,0.14,0.04-0.03-0.63,0.5
				c0.18-0.31-0.63-0.01-0.33-0.41c-0.08,0.03-0.18,0.09-0.15,0.15c-0.12-0.01-0.17-0.04-0.05-0.13c-0.44,0.1-0.27,0.19-0.68,0.23
				c0-0.05,0.08-0.08,0.11-0.14c-0.2,0.12-0.39,0.23-0.74,0.23c0.02-0.03-0.03-0.07,0.08-0.08c-0.59,0.07-0.97,0.36-1.63,0.33
				c0.1-0.12,0.08-0.28-0.62-0.14l0.15,0.08c-0.3,0.03-0.56,0.19-0.82,0.16l0.22-0.1l-0.56,0.09l0.33,0.04
				c-0.85,0.42,0.04,0.18-2.24,0.34c0.13-0.26-0.46-0.18-0.59,0.03c0.04-0.01,0.02-0.03,0.1-0.06c-0.55,0.08-0.77,0.06-0.95,0.03
				l0.14-0.04c-0.67,0.01,0.06-0.03-2.4,0.33l0.12,0.01c-0.06,0.27-0.49-0.08-0.81,0.08c-0.13-0.27,0.07-0.09-1.49,0.19l0.08-0.08
				c-1.19,0.21-0.99-0.01-3.54,0.35c0.19-0.09,0.31-0.12-0.51,0.06c0.06,0.11,0.09,0.06,0.24,0.13c-0.08,0.08-0.25,0.15-0.42,0.17
				c0.29-0.11-0.02-0.18-0.16-0.23c0.03,0.03-0.01,0.08,0,0.11c-0.73-0.02-0.03,0.17-1.58,0.25c0.11-0.05,0.27-0.07,0.38-0.12
				c-0.28-0.13-0.3-0.08-0.5-0.09l0.02,0l-0.46-0.02l0.12,0.03c-0.29,0.38-0.67,0.14-1.09,0.41c0.21-0.09,0.23-0.49-0.82-0.24
				c0.11-0.09-1.69,0.41-3.88,0.62c0.08-0.05,0.04-0.5-1.34-0.06l0.03-0.26c-0.07,0.4-0.35,0.3,0.05,0.78
				c-0.52-0.05,0.19,0.41-0.23,0.33c0.06,0.16-0.01,0.41,0.11,0.5c-0.14,0.14-0.01,0.52-0.23,0.48c0.05,0.05,0.15,0.28,0.14,0.42
				c-0.25-0.28-0.06,0.52-0.33,0.44c0.23,0.2,0.07,0.69,0.18,1.03c-0.02-0.02-0.05-0.06-0.04-0.09c-0.03,0.19-0.1,0.24-0.15,0.36
				c0.02-0.21,0.68,0.92-0.06,0.41c0.16-0.01,0.15,0.41,0.29,0.33c-0.12,0.18-0.06,0.15-0.22,0.24c0.26-0.13,0.2,0.98-0.13,0.55
				c0.36,0.05-0.01,1.13,0.67,2.2c-0.8,0.28-0.34-0.13,0.02,2.38c-0.58-0.03-0.25-0.15-0.47,0.9l0.13,0.11l-0.12,0.03l0-0.09
				c-0.11,0.04-0.02,0.08,0.04,0.12c-0.06,0-0.1,0.02-0.13,0.03l0.21,0.12c-0.09,0-0.18-0.06-0.23,0c0.1,0.06,0.21-0.03,0.27,0.06
				c-0.11,0.04-0.19,0.01-0.11,0.08c-0.06,0-0.1-0.02-0.12-0.04c0.07,0.06,0.01,0.14,0.13,0.19c-0.14,0.03,0,0.18-0.22,0.14
				c0.05,0.03,0.15,0.12,0.15,0.18c-0.24-0.14-0.05,0.18-0.32,0.08c0.22,0.12,0.03,0.25,0.12,0.4c-0.02-0.01-0.05-0.04-0.03-0.04
				c-0.04,0.06-0.11,0.05-0.17,0.08c0.18,0.07,0.19,0.19,0.29,0.28c-0.2,0.01-0.2-0.13-0.38-0.17c0.15,0.05,0.1,0.19,0.23,0.21
				c-0.13,0.01-0.08,0.03-0.24-0.01c0.22,0.11,0.01,0.07,0.13,0.18c-0.15,0.03-0.22-0.07-0.19,0.05c-0.39-0.12,0.09,0.04-0.16,0.66
				c-0.01,0.03,0.3,0.05,0.17,0.21c0.02-0.02-0.02-0.05-0.05-0.08l0.02,0.07c-0.1-0.29-0.32-0.01-0.17-0.07
				c-0.76,0.02,0.44,0-3.35,3.43l-0.15-0.11c0.03,0.35-0.01-0.28-0.34,0.39c-0.01-0.02-0.01-0.03-0.01-0.05
				c-0.04,0.01-0.16-0.14-0.06,0.08c-0.1-0.04-0.21,0.05-0.27,0.14l-0.05-0.1c-0.16,0.09-0.27,0.28-0.43,0.4
				c0.09-0.01,0.19,0.15,0.15,0.2L86,469.15c0.04,0.09,0.17,0.31-0.05-0.03l-0.04,0.1c-0.03-0.03-0.03-0.07-0.07-0.12
				c0.03,0.1-0.23-0.03-0.16,0.14c-0.14,0-0.55,0.55-0.66,0.9c-0.09-0.1-0.19,0.01-0.19-0.11c-0.12,0.13-0.16,0.17-0.27,0.4
				c0.08,0.02,0.15,0.15-0.04,0.02c-0.07,0.31-0.7,0.1-0.67,0.44c-0.1-0.11-0.1,0.26-1.18,0.91c0.03,0.03,0.17,0.13-0.05,0.07
				c0.71,0.03-0.85,0.45-1.58,1.99c-0.02-0.04-0.06-0.07-0.03-0.09c-0.14-0.03-0.16,0.19-0.09,0.25c0.12-0.09-0.42,0.27-0.5,0.79
				c-0.18-0.14-0.27,0.06-0.23,0.18c-0.04-0.06-0.1,0.01-0.15-0.07c-0.05,0.07-0.06,0.16,0.04,0.22c-0.31-0.03-0.01,0.39-0.34,0.26
				c-0.13,0.4,0.06,0.24-0.73,1.45c-0.02-0.01-0.03-0.04-0.03-0.07c-0.01,0.04-0.07,0.06,0.02,0.11l0-0.05
				c0.16,0.03,0.19,0.29,0.25,0.34c-0.22-0.21-0.16,0.12-0.43-0.16c0,0.03,0.03,0.08,0.09,0.11c-0.3,0.17-0.06,0.04-0.59,0.78
				c0.05-0.04-0.12,0.15,0.1,0.13c-0.05,0.08,0.03,0.22-0.06,0.26l-0.03-0.1l-0.05,0.17l0.11-0.05c0.04,0.2,0.23,0.16-0.29,0.31
				c0.12,0.11-0.06,0.21,0.06,0.34c-0.03-0.03-0.11-0.08-0.13-0.08l0.1,0.09c-0.13,0.03-0.03,0.09-0.29,0.33l0-0.07l-0.1,0.01
				l0.08,0.06c-0.18-0.11,0.05,0.44-0.3,0.82c-0.01-0.03-0.12-0.09-0.1-0.07c-0.09,0.11-0.05,0.28-0.06,0.5
				c0.03-0.12-0.37,0.08-0.1,0.3c-0.21,0.13-0.53,0.02-0.2,0.94c-0.04-0.11-0.17-0.07-0.25-0.05c0.04,0.01,0.07,0.03,0.1,0.04
				c-0.3-0.04,0.15,0.14-0.02,0.15c0.01,0.04,0.05,0.31-0.11,0.07c-0.04,0.23,0-0.02-0.29,0.31c0.4,0.14,0.03,0,0.21,0.4
				c-0.16-0.16-0.03-0.08-0.4-0.09c0.1,0.1-0.12,0.07,0.02,0.22c-0.03-0.01-0.05,0.01-0.08,0.02c0.07,0.04,0.06,0.24-0.03,1.04
				c-0.27-0.13,0.1,0.28-0.24,0.02c0.02,0.08,0.09,0.17,0.12,0.26c-0.09,0-0.11-0.08-0.16-0.12c0.03,0-0.03,0.2,0.2,0.25
				c-0.32-0.19,0.28,0.18,0.06-0.38l0.19,0.1c-0.24-0.19,0.01-0.09,0.12-0.28c0.04,0.03,0.14,0.03,0.1,0.06
				c0.12-0.17,0.2-0.3-0.18-0.48l0.39,0.02l-0.14-0.06c0.01-0.02,0.03-0.05,0.09-0.02c-0.08-0.1-0.07-0.01-0.15-0.08
				c0.16-0.04,0.01-0.26,0.25-0.2c-0.1-0.07-0.11,0.06-0.16-0.06c0.28,0.14-0.07-0.28,0.3-0.08c-0.24-0.21,0.32-0.07,0.05-0.51
				c0.07,0.05-0.03-0.05,0.3-0.24c-0.16-0.08-0.09-0.18-0.1-0.27c0.1,0.04,0.14,0.07,0.17,0.13c0.01-0.06,0.03-0.07-0.05-0.2
				l0.12,0.07c0.03-0.1-0.07-0.25,0.06-0.34c-0.12-0.05-0.37-0.03-0.4-0.2c0.07,0.02,0.18,0.06,0.19,0.1
				c-0.02-0.01,0.14-0.15,0.16,0c0.04-0.04,0.12-0.08,0.19-0.07c-0.05-0.03-0.24-0.19,0.07-0.11c-0.05-0.05,0.04-0.08-0.1-0.14
				c0.01-0.03,0.08-0.01,0.12,0c-0.06-0.07,0,0-0.11-0.24l0,0c-0.2-0.19,0.17-0.14,0.52-0.97c0.04,0.11,0.08,0.01,0.02,0.1
				c0.08-0.08,0.16-0.06,0.09-0.22c0.1,0.09,0.18,0,0.17-0.06l-0.11,0.02c0.36-0.16,0.16-0.98,0.64-0.95l-0.05-0.04
				c0.08-0.01,0.13-0.13,0.2-0.06c-0.01-0.09-0.07-0.07-0.12-0.04c0.13-0.07,0.1-0.17,0.21-0.25c-0.13-0.02-0.13-0.58,0.22-0.19
				c-0.04-0.16,0.18-0.15,0.23-0.19l0.01,0.08c-0.01-0.05,0.01-0.07,0.03-0.1l-0.12-0.03c0.04-0.02,0.05-0.05,0.09-0.02
				c-0.05-0.07-0.18-0.08-0.24-0.19c0.08-0.01,0.02-0.16,0.16-0.09c-0.15,0.14,0.49,0.34,0.14-0.12c0.05,0.05,0.11,0.05,0.14,0.04
				c-0.05-0.01-0.15-0.1-0.11-0.3c-0.08,0.01-0.1,0.05-0.08-0.14c0.06,0.03,0.01,0.08,0.05,0.07c-0.09-0.15,0.22-0.05,0.19-0.2
				c0.01,0.02,0.5,0.12,0.21-0.26l-0.04,0.21l-0.07-0.2c-0.03,0.05-0.01,0.15-0.04,0.17c-0.05-0.1-0.03-0.08-0.13-0.11
				c0.17-0.44,0.38,0.36,0.58-0.45c-0.09,0-0.15,0.03-0.03-0.16c0.08,0.06,0.13,0.05,0.19,0.07c-0.06-0.04,0.08-0.11-0.07-0.23
				c0.19-0.03,0.26,0.09,0.14-0.39c0.06,0.1,0.16,0.09,0.16,0.21c0.02-0.17,0,0.04-0.06-0.52c0.24,0.03,0.27,0.02,0.67-0.59
				c0.01,0.01,0.02,0.02,0.02,0.02c0.1-0.44,0.19,0.32,0.51-0.56c0,0,0.02,0.02,0.04,0.03c-0.07-0.17-0.01-0.33,0.04-0.46
				c0.07,0.11,0.02,0.09,0.13,0.15c-0.41-0.06,0.56-0.06,0.61-0.97c0.06,0.15,0.03,0.29,0.04,0.41c0.04-0.04,0.13-0.16,0.08-0.25
				c-0.05,0.07-0.07,0.09,0.01-0.29c0.26,0.28,0.29-0.16,0.56-0.01c0.05-0.25,0.34-0.25,0.49-0.44c-0.13-0.04-0.18-0.49,0.09-0.09
				c-0.12-0.03-0.06-0.15-0.15-0.2c0.1-0.01,0.28,0.03,0.5,0.19c-0.09-0.07-0.08-0.14,0.01-0.16c-0.18,0.06,0.12-0.3,0.16-0.08
				c0.03-0.1,0.24-0.22,0.15-0.47c0.04,0.02,0.11,0.07,0.09,0.11c0.41-0.15,0.66-0.87,2.34-1.85c0,0.02-0.19-0.2,0.06-0.23
				l-0.02,0.01c0.15,0.13,0.21,0.02,0.28-0.03l-0.15-0.22c0.42,0.16,0.38-0.48,0.69-0.3c0-0.09,0.06-0.13,0.06-0.22
				c0.15,0.1,0.07,0.16,0.43-0.14l0.04,0.1c0.04-0.41-0.18-0.21,0.49-0.71c0.02,0.03,0.05,0.07,0.04,0.1
				c0.07-0.06,0.35-0.13,0.13-0.32c0.13,0.14,0.16-0.12,0.18-0.25c0.19,0.29-0.01-0.03,0.81-0.83c-0.09,0.09-0.17,0.17-0.17,0.15
				c0.17,0.08,0.14,0.07,0.49-0.36c-0.01,0.01,0.07,0.04,0.02,0.08c0.03-0.02,0.07-0.04,0.11-0.09c-0.11-0.09-0.05-0.13-0.18-0.15
				c0.02-0.01-0.01,0.02-0.01,0.08l-0.01-0.01c-0.02,0.03-0.05,0.07-0.08,0.11c-0.03-0.08-0.04-0.15,0.05-0.14
				c-0.05-0.08-0.1-0.16-0.13-0.2c-0.02-0.16,0.78-0.29,1.23-1.49c-0.03,0.01-0.05,0.02-0.08,0.01c0.13-0.2,0.41-0.18,0.48-1.32
				c0.2,0.05,0,1.09,0.91,3.55l-0.06-0.07c0.03,0.33,0.38,0.27,0.33,0.59l-0.04-0.05c0.03,0.24,0.21,0.09,0.08,1.01l-0.06-0.14
				c-0.07,0.12,0.03,0.14,0.12,0.17c-0.17,0,0.48,0.47,0.08,0.47c0.1,0.06,0.11,0.21,0.25,0.21c-0.08,0.13,0.15,0.27-0.05,0.35
				c0.06,0.01,0.22,0.08,0.26,0.15c-0.3-0.03,0.12,0.29-0.15,0.37c0.18-0.01-0.01,0.37,0.75,1.18l-0.14,0.06
				c0.05-0.01,0.12,0.06,0.18,0.28c-0.01,0-0.01,0-0.02,0c0.13,0.39,0.08-0.13,0.59,1.28c-0.06,0.01-0.13-0.08-0.2-0.14
				c0.28,0.25-0.12,0.12,0.14,0.35c0.01-0.05,0.09-0.02,0.12-0.02c-0.02,0.18,0.13,0.51,0.28,0.72l-0.12,0
				c0.19,0.42,0.46,0.85,0.71,1.3c0.34,0.9-0.15-0.16,3.72,6.15l-0.03-0.02c0.44,0.89,1.84,3.01,3.33,7.11l-0.2-0.09
				c0.46,0.7,0.45,1.57,0.78,2.3c-0.05-0.04-0.1-0.03-0.11-0.1c-0.08,0.19,0.13,0.6,0.23,0.58c0.02,0.36-0.14-0.06,0.46,2.25
				c-0.22,0.01-0.11,0.53,0,0.71c-0.07-0.06-0.06,0.17-0.16,0.1c0.02,0.21,0.08,0.4,0.19,0.36c-0.21,0.71-0.04,0.47,0.23,2.81
				c-0.55-0.59-0.07-1.1-0.17,2.19c-0.02,0-0.04-0.06-0.05-0.11c0.09,0.27-0.02-0.05,0.39,0.55c-0.28-0.19-0.11,0.47-0.46,0.18
				c0.01,0.06,0.06,0.15,0.13,0.14c-0.03,0.09-0.07,0.12-0.14,0.01c0.02,0.34,0.14,0.24,0.09,0.54c-0.05-0.01-0.06-0.07-0.11-0.11
				c0.07,0.17,0.15,0.34,0.07,0.59c-0.03-0.02-0.07,0-0.06-0.07c-0.05,0.44,0.14,0.8-0.02,1.27c-0.19-0.18-0.09,0.15-0.28,0.15
				l0,0.27l0.11-0.09c-0.05,0.23,0.03,0.45-0.07,0.64l-0.04-0.18l-0.06,0.43l0.12-0.23c0.16,0.72,0.19,0.02-0.29,1.71
				c-0.08-0.19-0.39,0.17-0.16,0.42c0-0.03-0.02-0.02-0.02-0.09c-0.11,0.42-0.2,0.57-0.27,0.69l0.01-0.13
				c-0.22,0.26,0.05,0.07-0.52,1.79l0.05-0.08c0.22,0.16-0.26,0.3-0.23,0.58c-0.28-0.04-0.06-0.11-0.4,1.08l-0.03-0.09
				c-0.32,0.84-0.42,0.64-1.23,2.43c0.01-0.16,0.05-0.26-0.19,0.34c0.12,0.02,0.09-0.02,0.23-0.07c0.03,0.09,0,0.24-0.06,0.35
				c0.05-0.24-0.16-0.09-0.27-0.04c0.04,0,0.06,0.05,0.09,0.06c-0.35,0.08,0.08,0.08-0.16,0.35c0.01-0.08-0.16,0.71-0.19,0.18
				c-0.1,0.18,0.02,0.49-0.2,0.57c0.02-0.09,0.09-0.19,0.1-0.29c-0.23,0.09-0.22,0.13-0.32,0.24l0.01-0.01l-0.25,0.25l0.09-0.05
				c0.15,0.4-0.24,0.46-0.25,0.88c0.04-0.18-0.26-0.44-0.61,0.31c0.06-0.17-0.66,1.28-1.76,2.44c-0.06-0.15-0.12-0.39-0.57,0.61
				l0.13-0.07c-0.27,0.01,0.22-0.47,0.1-0.12c0.3,0.1,0.34-0.42,0.54-0.6c-0.01,0.29,0.25,0.36,0.61-0.34
				c0.01,0.06,0.13,0.07,0.03,0.14c0.31-0.19,0.23-0.53,0.43-0.53l-0.01,0.03c0.32-0.38,1.02-1.12,1.95-2.78
				c0.05,0.06,0.38-0.44,0.58-0.66l0.02,0.02c-0.13-0.14,0.05-0.38,0.12-0.58c0.09,0.06,0.11,0.12,0.09,0.24
				c-0.01-0.04,0.09,0,0.12-0.44l0.09,0.11c0.13-0.24,0.13-0.55,0.38-0.81c-0.11-0.08-0.43,0.08-0.35-0.29
				c0.07,0.02,0.18,0.06,0.16,0.16c0.01-0.03,0-0.13,0.05-0.17l0.04,0.18c-0.01-0.09,0.2-0.44,0.11-0.09
				c0.08-0.12,0.2-0.25,0.29-0.27c-0.14-0.5,0.01,0.01,0.33-1.17l0,0c-0.04-0.34,0.66-1.02,1.13-2.64c0.01,0.22,0.1-0.04-0.01,0.22
				c0.14-0.23,0.22-0.27,0.18-0.59c0.09,0.13,0.21-0.14,0.21-0.28l-0.14,0.15c0.02-0.14,0.07-0.26,0.12-0.38l0.14,0.12
				c0.24-0.56,0.42-1.19,0.52-2.48c-0.42,0.14,0.56-0.49,0.18-0.5c0.15-0.3,0.12-0.49,0.24-0.82c-0.09,0.08-0.32-1.07,0.21-0.71
				c-0.07-0.32,0.16-0.56,0.21-0.73l0.02,0.17c-0.01-0.1,0-0.18,0.01-0.26l-0.13,0.08c0.03-0.09,0.05-0.17,0.1-0.16
				c-0.11-0.19-0.43-0.01-0.13-0.57c-0.11,0.37,0.14,0.01,0.14,0.28c0.15-0.39,0.2-0.18-0.05-0.75c0.08,0.06,0.17-0.08,0.16-0.14
				c0.01,0.12-0.22,0.01-0.22-0.5l-0.04,0.15c-0.26-0.32,0.22-0.64,0.37-1.73c-0.12,0.09-0.09,0.11-0.21-0.12
				c0.03,0.18,0.06,0.35,0.08,0.53c-0.05-0.12-0.11-0.23-0.17-0.34c0,0.16,0.07,0.34,0.04,0.43c-0.09-0.16-0.06-0.15-0.16-0.08
				c0.03-0.82,0.57-0.29,0.24-1.83l-0.05,0.16c-0.12-0.06-0.02-0.3-0.06-0.46c0.1,0.01,0.13-0.09,0.2-0.16
				c-0.07,0.01-0.01-0.36-0.21-0.4c0.14-0.45,0.21-0.22-0.2-1.04c0.12,0.11,0.17-0.07,0.27,0.18c-0.01-0.13,0-0.24-0.07-0.24
				c0.06,0.2,0.05-0.49-0.36-0.75c0.2-0.31,0.15-0.54-0.16-2.29c0.02,0.01,0.03,0,0.03,0.01c-0.24-0.72,0.06-0.01-0.15-1.23
				l0.03,0.06c0.06-0.22-0.23-0.46-0.29-0.79c0,0,0.03,0.01,0.05-0.01c-0.21-0.18-0.36-0.58-0.51-0.87c0.05-0.01,0.07-0.01,0.1-0.01
				c-0.11-0.02-0.25-0.13-0.04-0.52l0.09,0.26c0.13-0.18-0.11-0.33-0.13-0.48l0.08-0.01c-0.35-0.6-0.32-0.46-1.06-1.94
				c0.2,0.09,0.4,0.38,0.56,0.54c-0.06-0.15-0.22-0.5-0.34-0.54c0.04,0.05,0.12,0.11,0.11,0.18c-0.77-1.11-0.1-0.01-1.33-3.35
				c0.13,0.09-0.76,0.35-0.32-1.04c-0.07,0.09-0.19-0.02-0.25-0.21l0.01,0.3c-0.37-0.32,0.02-0.48-0.35-0.8l0.16,0.08
				c-0.08-0.28-0.25-0.32-1.64-2.31l0.09,0c-0.24-0.22,0-0.17-0.45-0.6l0.13,0.06c-0.2-0.52-0.62-0.62-0.9-0.99
				c0.19-0.11,0.03-0.52-0.05-0.72l0.03-0.01c-0.24-0.74-0.45,0.01-1.33-2.09c0.54,0.39-0.06-0.07,1.89,1.65l-0.06,0.01
				c0.12,0.16,0.35-0.12,0.41,0.09l-0.04,0c0.16,0.29,0.89,0.65,2.85,1.78c-0.01,0.02-0.03,0.02-0.05,0.03
				c0.02,0.04-0.08,0.2,0.14,0.04c0.04,0.12,0.23,0.21,0.39,0.24l-0.07,0.08c0.23,0.13,0.53,0.2,0.81,0.31
				c0-0.07,0.21-0.36,0.11-0.05c0.08-0.07,0.22-0.29,0.01,0.06l0.15,0c-0.01,0.05-0.05,0.06-0.08,0.11
				c0.63,0.22-0.03,0.14,1.92,0.43c-0.03,0.12,0.18,0.19,0.04,0.24c0.26,0.07,0.33,0.1,0.68,0.12c-0.04-0.11,0.05-0.2,0.05,0.03
				c0.42-0.05,0.69,0.65,1.06,0.5l-0.02,0.01c0.79,0.2,0.09-0.08,1.98,0.9c0.03-0.06,0.04-0.2,0.12,0.03
				c-0.09-0.64,0.38,0.37,3.38,1.35c-0.04,0.02-0.07,0.06-0.11,0.04c0.03,0.15,0.31,0.18,0.36,0.09c-0.14-0.07,0.4,0.4,1.18,0.6
				c-0.13,0.18,0.15,0.31,0.3,0.28c-0.07,0.04,0.04,0.11-0.05,0.16c0.1,0.07,0.21,0.1,0.26-0.01c0.16,0.42,0.08,0.22,1.36,0.89
				c-0.53,0.19-0.7-0.35,0.9,0.89c-0.02,0.01-0.05,0.01-0.09,0c0.19,0.02-0.03-0.01,0.51-0.1c-0.27,0.14,0.14,0.25-0.23,0.41
				c0.04,0.01,0.11,0.01,0.15-0.04c0.02,0.05,0.01,0.09-0.09,0.11c0.18,0.1,0.21-0.02,0.33,0.1c-0.04,0.03-0.08,0.02-0.13,0.05
				c0.13,0,0.27,0,0.34,0.13c-0.03,0.01-0.04,0.05-0.07,0.02c0.18,0.18,0.48,0.16,0.6,0.44c-0.22,0.08,0.01,0.12-0.12,0.25
				l0.14,0.08l0.03-0.1c0.09,0.1,0.26,0.11,0.29,0.24l-0.12-0.03l0.18,0.18l-0.03-0.16c0.27,0.12,0.22-0.24,0.29,0.53
				c0.17-0.04,0.22,0.22,0.41,0.2c-0.05,0-0.13,0.06-0.14,0.08l0.13-0.03c0,0.19,0.11,0.12,0.26,0.56l-0.07-0.05l-0.02,0.11
				l0.09-0.03c-0.21,0.09,0.55,0.22,1.1,1.6h-0.08c0.27,0.1,0.02,0.6,1.06,1.61c-0.12-0.09-0.17,0.07-0.21,0.15
				c0.03-0.02,0.07-0.02,0.1-0.04c-0.22,0.21,0.19,0.02,0.12,0.19c-0.03-0.02,0.34,0.31-0.02,0.16c0.05,0.11,0.32,0.13,0.23,0.28
				c-0.05-0.03-0.08-0.1-0.13-0.14c-0.11,0.17-0.07,0.16-0.06,0.25l0-0.01l0,0.21c0.39-0.15,0.03-0.02,0.47,0.31
				c-0.24-0.09-0.11-0.05-0.37,0.18c0.14,0.05-0.05,0.18,0.16,0.25c-0.03,0.01-0.01,0.05-0.03,0.08c0.08-0.02,0.23,0.29,0.62,1.34
				c-0.31-0.01,0.26,0.31-0.19,0.17c0.07,0.09,0.18,0.18,0.24,0.27c-0.08,0.04-0.14-0.04-0.2-0.07c0.02-0.02,0.09,0.29,0.3,0.22
				c-0.38-0.12,0.37,0.07-0.15-0.54l0.22,0.02l-0.21-0.12c0.04-0.05,0.15-0.09,0.2-0.02c0.06-0.11,0.1-0.06-0.06-0.3
				c0.05,0.01,0.14-0.05,0.13,0.02c0.03,0.02-0.43-0.4-0.35-1.27c-0.12-0.01-0.05,0.15-0.17,0.04c0.32-0.03-0.26-0.29,0.17-0.33
				c-0.36-0.05,0.23-0.2-0.38-0.68c0.09-0.01-0.06-0.01,0-0.54c-0.18,0.04-0.22-0.13-0.3-0.23c0.1-0.04,0.15-0.03,0.22,0.01
				c-0.03,0,0.03-0.08-0.2-0.2l0.14-0.03c-0.07-0.14-0.26-0.23-0.27-0.45c-0.12,0.04-0.25,0.29-0.42,0.14
				c0.06-0.04,0.16-0.09,0.21-0.06c0-0.02-0.19-0.13,0.07-0.05c-0.07-0.02-0.14-0.24,0.02-0.09c-0.02-0.08-0.01-0.2,0.04-0.26
				c-0.34,0.16,0.19-0.29-0.49-0.54l0,0c-0.28-0.06-0.12-0.69-0.79-1.48c0.14,0.06,0.04-0.08,0.12,0.07c-0.05-0.16,0-0.23-0.2-0.3
				c0.14-0.02,0.07-0.19-0.01-0.23l-0.01,0.14c-0.14-0.45,0.1-0.13-0.45-0.93c-0.21,0.62-0.62-0.63-0.49-0.57l-0.06,0.02
				c0-0.1-0.13-0.25-0.03-0.28c-0.11-0.05-0.11,0.02-0.08,0.11c-0.05-0.21-0.19-0.23-0.27-0.42c0,0.11-0.76-0.17-0.2-0.39
				c-0.21-0.06-0.16-0.31-0.22-0.39l0.1,0.04c-0.06-0.02-0.09-0.06-0.13-0.09l-0.05,0.12c-0.08-0.25,0.05-0.13-0.4-0.21
				c0.19,0.25,0.46-0.38-0.16-0.24c0.08-0.04,0.08-0.16,0.04-0.17c0.06,0.02-0.09,0.18-0.38-0.01c0.01,0.09,0.08,0.15-0.18,0.02
				c0.04-0.06,0.1,0.03,0.1-0.02c-0.35,0.11-0.03-0.98-0.64-0.7l0.28,0.15l-0.26,0c0.07,0.06,0.19,0.08,0.22,0.13
				c-0.13,0.01-0.11-0.01-0.14,0.1c-0.5-0.32,0.38-0.41-0.67-0.87c0.01,0.11,0.07,0.19-0.21-0.02c0.07-0.07,0.04-0.14,0.05-0.21
				c-0.04,0.06-0.17-0.13-0.31,0.01c-0.1-0.27,0.04-0.27-0.57-0.27c0.12-0.05,0.07-0.16,0.24-0.14c-0.23-0.06,0.05,0.01-0.69-0.05
				c-0.03-0.27-0.1-0.37-1.05-0.85c0.02-0.02,0.02-0.04,0.04-0.02c-0.48-0.14,0.01-0.07-0.59-0.46l0.04,0
				c-0.05-0.15-0.32-0.02-0.48-0.13c0,0,0.02-0.02,0.02-0.04c-0.19,0.09-0.44,0.02-0.63-0.02c0.03-0.04,0.04-0.07,0.06-0.09
				c-0.28,0.15-0.12-0.55-1.57-0.59c0.14-0.1,0.35-0.09,0.5-0.12c-0.08-0.04-0.29-0.11-0.38-0.04c0.12,0.05,0.16,0.06-0.37,0.05
				c0.05-0.37,0.08-0.23-1.36-1c0.1,0.12-0.45,0.37-0.19-0.07c0.06,0.14-0.14,0.11-0.13,0.21c-0.09-0.11-0.17-0.31-0.17-0.57
				c-0.01,0.11-0.12,0.12-0.21,0.05l0.1,0.19c-0.31,0.06-0.13-0.32-0.44-0.26l0.11-0.06c-0.15,0.01-0.48-0.18-0.69,0
				c-0.01-0.04-0.01-0.13,0.05-0.13c-0.17-0.19-0.38-0.11-1.35-0.38l0.09-0.05c-0.96-0.01-0.52-0.53-2.9-1.25
				c-0.37,0.09-0.17-0.06-1.81-0.69c0.03-0.25,0.1-0.11-0.45-0.43l0.1-0.06c-0.54,0.05-0.2,0.16-1.11-0.45
				c0.02-0.02,0.06-0.07,0.1-0.05c-0.1-0.08-0.31-0.4-0.44-0.14c0.11-0.16-0.21-0.18-0.38-0.2l0.1-0.1
				c-0.02,0.04-0.18,0.02-0.59-0.14l0.1-0.03c-0.3-0.28-0.15-0.11-0.86-0.79c0.13,0.13,0.24,0.24,0.22,0.23
				c0.26-0.1-0.12-0.65-0.7-0.67c-0.01-0.02,0.03,0.02,0.09,0.04l-0.01,0c0.04,0.03,0.08,0.08,0.14,0.13
				c-0.09,0.01-0.18,0.01-0.18-0.11c-0.55,0.21-0.43-0.67-1.81-2.19c0,0.02-0.01,0.02-0.01,0.04c-0.25-0.59-0.28-0.26-0.76-2.1
				l0.13,0.06c-0.6-0.66-0.32-0.22-0.85-1.9c0.03,0,0.09-0.01,0.13,0.06c-0.07-0.21-0.1-0.86-0.36-0.7c0.18-0.06-0.11-0.47-0.26-0.7
				l0.15,0c-0.04,0.01-0.22-0.25-0.48-0.87l0.11,0.08c-0.08-0.38-0.39-0.14-0.17-0.84c0.04,0.12-0.29-0.32-0.11-0.23
				c0.1-0.43-0.16,0.03-0.12-0.93c0.06,0.32,0.1,0.58,0.08,0.56c0.13-0.03,0.1-0.29,0.31-0.12c0.05-0.19-0.44-0.36-0.38-1.58
				c-0.14,0.03-0.14-0.14-0.23,0.04c0-0.04,0.01,0.06,0.06,0.16c0,0-0.01,0-0.01-0.01c0.01,0.08,0.03,0.19,0.06,0.32
				c-0.15-0.03,0.13-0.26-0.36-0.49c0.36-0.47,0.25-0.45-0.02-1.96c0.03,0.06,0.04,0.14,0.08,0.17c-0.09-0.22-0.1-0.67-0.29-0.7
				c0.06-0.29,0.18,0.15,0.12-0.24l0.06,0.05c0.25-0.79-0.57-1.23-0.2-2.11c-0.02,0.06-0.03,0.09-0.06,0.1
				c0.11-0.16-0.16-2.35,0.23-5.02l-0.11,0.06c0.39-0.36-0.5-1.15,1.08-1.45c-0.01,0.15-0.11,0.3,0.87,0.15
				c-0.04,0.05,0.06,0.14-0.1,0.13c0.45,0.07,0.69-0.24,0.88-0.1l-0.04,0.02c0.69-0.07,1.97-0.13,4.34-0.54
				c-0.02,0.06,0.75,0.02,1.12,0.04l-0.01,0.03c0.05-0.19,0.43-0.2,0.7-0.27c0,0.11-0.04,0.16-0.19,0.22
				c0.03-0.03,0.05,0.08,0.55-0.17l-0.05,0.14c0.13-0.05,0.57,0.07,1.35-0.47l-0.17,0.14c0.08-0.05,0.61-0.08,0.17,0.04
				c0.82,0.01,0.34,0.01,2-0.27l0,0c0.57-0.21,0,0,1.79-0.17c-0.01,0.21,0.56-0.06,1.89-0.15c-0.26,0.11,0.1,0.07-0.27,0.09
				c0.35,0.01,0.43,0.08,0.79-0.09c-0.11,0.14,0.27,0.13,0.43,0.08l-0.25-0.07c0.18-0.04,0.34-0.04,0.51-0.05l-0.07,0.17
				c0.76,0,1.58-0.15,3.2-0.47c-0.36-0.36,0.87,0.31,0.67,0c0.42,0.04,0.65-0.06,1.08-0.05c-0.1-0.08,1.2-0.59,0.94,0.01
				c0.38-0.15,0.73,0,0.96,0l-0.2,0.07c0.12-0.04,0.22-0.05,0.32-0.06l-0.13-0.11c0.12,0.01,0.22,0,0.23,0.05
				c0.21-0.2-0.07-0.41,0.67-0.28c-0.49,0,0.02,0.14-0.32,0.21c0.07-0.02,0.24-0.05,0.23,0.03c0.5,0.09,0.2-0.2,0.7-0.25
				c-0.05,0.09,0.13,0.16,0.21,0.13c-0.16,0.02-0.03-0.23,0.58-0.32l-0.19-0.01c0.32-0.3,0.85,0.05,2.24,0.04
				c-0.13-0.11-0.15-0.08,0.13-0.22l-0.66,0.15l0.41-0.21c-0.2,0.02-0.42,0.12-0.54,0.11c0.2-0.12,0.18-0.08,0.07-0.17
				c1.02-0.07,0.5,0.52,2.36,0.01l-0.21-0.03c0.07-0.12,0.38-0.05,0.58-0.12c-0.01,0.1,0.12,0.12,0.21,0.18
				c-0.02-0.07,0.46-0.04,0.5-0.24c0.59,0.12,0.29,0.2,1.33-0.25c-0.14,0.12,0.09,0.17-0.23,0.28c0.67-0.26-0.25-0.01,4.22-0.58
				c-0.01,0.02-0.01,0.03-0.01,0.04c0.99-0.2-0.01,0.06,1.58-0.03l-0.08,0.03c0.39,0.03,0.15,0.02,2.27-0.52
				c-0.01,0.05-0.01,0.08-0.02,0.1c0.06-0.1,0.22-0.23,0.66,0.06l-0.34,0.04c0.19,0.16,0.44-0.05,0.63-0.03l-0.02,0.08
				c0.52-0.07,0.51-0.27,1.08-0.3l-0.06-0.05c0.8-0.31,0.29,0.44,1.74-0.22c-0.19,0.16-0.62,0.28-0.87,0.39
				c0.2-0.02,0.69-0.08,0.78-0.18c-0.08,0.03-0.18,0.08-0.26,0.06c1.66-0.41,0.06-0.11,4.59-0.19c-0.17,0.11-0.13-0.87,1.37,0.1
				c-0.07-0.1,0.13-0.17,0.38-0.15l-0.35-0.11c0.58-0.21,0.53,0.21,1.12,0l-0.18,0.11c0.45,0.03,0.53-0.12,3.6-0.51l-0.05,0.08
				c0.39-0.12,0.2,0.07,0.96-0.15l-0.15,0.1c0.72,0.04,1.08-0.29,1.68-0.39c0.02,0.22,0.58,0.24,0.87,0.26l-0.01,0.03
				c1.35,0.21-0.02-0.32,10.34-0.85l-0.12,0.11c1.09-0.43,0.45-0.23,2.7-0.41c-0.01,0.03-0.02,0.09-0.12,0.11
				c0.29-0.01,1.13,0.06,1.01-0.22C163.74,445.49,164.36,445.27,164.69,445.17z M169.6,444.93c-0.06,0.08-0.05,0.11-0.21,0.05
				C169.43,444.95,169.4,445,169.6,444.93z M168.87,444.06c-0.04,0-0.09,0.01-0.18,0.02C168.76,444.06,168.69,444.08,168.87,444.06z
				 M168.22,444.22c0.03-0.03-0.12-0.05-0.22-0.08C168.08,444.12,168.37,444.05,168.22,444.22z M89.55,466.33
				c0.03-0.01,0.08-0.12,0.12,0.02C89.66,466.32,89.6,466.33,89.55,466.33z M89.69,466.17c0.01-0.02,0,0,0.03-0.05
				C89.71,466.14,89.71,466.15,89.69,466.17z M97.57,473.51c0.02-0.02,0,0,0.06-0.04C97.67,473.5,97.69,473.61,97.57,473.51z
				 M97.43,474.5c-0.04,0.01,0,0.08,0.01,0.15C97.47,474.64,97.23,474.54,97.43,474.5z M97.2,474.42c-0.03-0.02,0,0-0.06-0.05
				C97.14,474.38,97.16,474.4,97.2,474.42z M93.03,462.48c0.02,0.07-0.02,0.24-0.07-0.02C92.98,462.46,93.01,462.46,93.03,462.48z
				 M92.03,463.39c-0.07-0.08-0.03-0.19,0.02-0.37C92.01,463.08,92.21,463.12,92.03,463.39z M92.13,463.11
				c0.01,0.03,0.02,0.07,0.04,0.13C92.14,463.19,92.17,463.25,92.13,463.11z M92.34,463.6c-0.04-0.02-0.04,0.11-0.07,0.2
				C92.32,463.84,92.13,463.47,92.34,463.6z"
                />
                <path
                  className="st10"
                  d="M71.02,448.8c-0.15-0.13-0.11-0.1-0.78-0.2c0.21-0.47-0.46,0.04-0.26-0.32c-0.17,0-0.39-0.15-0.51-0.07
				c-0.09-0.18-0.49-0.19-0.39-0.39c-0.07,0.03-0.32,0.04-0.46-0.01c0.35-0.13-0.48-0.24-0.3-0.47c-0.28,0.13-0.68-0.2-1.05-0.22
				c0.03-0.01,0.08-0.03,0.1,0c-0.17-0.1-0.19-0.19-0.29-0.28c0.19,0.1-1.13,0.25-0.36-0.22c-0.05,0.15-0.44-0.03-0.42,0.13
				c-0.12-0.18-0.12-0.12-0.14-0.3c0.02,0.29-1.01-0.23-0.46-0.34c-0.22,0.3-1.02-0.5-2.33-0.39c0.1-0.85,0.27-0.24-2.18-1.14
				l0.17-0.14c-1.25-0.11-2.02-1.02-5.83-2.11l0.09-0.01c-0.3-0.16-0.45,0.17-0.7-0.06c0.14,0.01,0.02-0.37-5.59-1.74
				c0.03-0.06,0.14-0.05,0.24-0.07c-0.38,0.07-0.03-0.18-0.38-0.11c0.03,0.04-0.04,0.08-0.06,0.11c-0.14-0.13-0.5-0.21-0.77-0.23
				l0.07-0.09c-0.46-0.13-0.99-0.16-1.51-0.26c-0.99-0.27,0.23-0.02-7.5-0.73l0.03-0.01c-2.09,0-2.72-0.11-8.42,0.79l0.12-0.2
				c-0.81,0.38-1.74,0.4-2.52,0.8c0.04-0.05,0.03-0.1,0.11-0.12c-0.21-0.07-0.64,0.18-0.61,0.28c0.01-0.07-0.83,0.12-2.3,0.91
				c-0.06-0.22-0.59,0.04-0.73,0.21c0.04-0.09-0.19,0-0.15-0.12c-0.21,0.09-0.39,0.21-0.31,0.31c-0.77,0.09-0.55,0.04-2.67,1.37
				c0.35-0.71,0.94-0.71-2.11,1c-0.01-0.02,0.03-0.07,0.07-0.1c-0.2,0.22,0.03-0.04-0.28,0.63c0.02-0.34-0.48,0.17-0.41-0.29
				c-0.05,0.05-0.1,0.13-0.06,0.18c-0.1,0.03-0.14,0.01-0.09-0.11c-0.29,0.22-0.13,0.25-0.42,0.4c-0.02-0.04,0.03-0.09,0.03-0.16
				c-0.1,0.16-0.2,0.33-0.47,0.42c0-0.03-0.05-0.05,0.03-0.09c-0.41,0.24-0.59,0.61-1.1,0.77c0.03-0.26-0.19,0.02-0.3-0.13
				l-0.22,0.18l0.14,0.03c-0.22,0.11-0.35,0.33-0.56,0.38l0.12-0.15l-0.39,0.25l0.26-0.06c-0.47,0.61,0.11,0.15-1.55,0.95
				c0.08-0.2-0.4-0.15-0.43,0.19c0.02-0.02,0-0.03,0.05-0.08c-0.39,0.23-0.57,0.28-0.71,0.3l0.1-0.07c-0.49,0.2,0.04-0.05-1.69,0.98
				l0.1-0.03c0.06,0.27-0.4,0.07-0.58,0.3c-0.19-0.21,0.02-0.12-1.05,0.59l0.03-0.09c-0.8,0.52-0.76,0.27-2.54,1.32
				c0.1-0.13,0.19-0.2-0.36,0.2c0.09,0.08,0.09,0.03,0.23,0.06c-0.03,0.09-0.13,0.21-0.25,0.27c0.17-0.18-0.09-0.16-0.21-0.17
				c0.03,0.02,0.02,0.07,0.04,0.1c-0.36-0.11,0.03,0.1-0.34,0.22c0.06-0.07-0.53,0.52-0.27,0.05c-0.19,0.11-0.26,0.42-0.49,0.39
				c0.06-0.08,0.18-0.14,0.24-0.21c-0.26-0.04-0.26,0.01-0.41,0.06l0.02,0l-0.35,0.11l0.1-0.01c-0.07,0.43-0.45,0.31-0.67,0.68
				c0.12-0.14-0.02-0.51-0.71,0.01c0.03-0.11-1.15,0.87-2.71,1.63c0-0.16,0.02-0.42-0.76,0.42l0.15-0.02
				c-0.27-0.07,0.38-0.39,0.14-0.09c0.26,0.19,0.48-0.31,0.74-0.42c-0.11,0.29,0.1,0.41,0.72-0.1c-0.01,0.06,0.1,0.11-0.02,0.14
				c0.37-0.06,0.44-0.41,0.63-0.33l-0.02,0.03c0.47-0.24,1.42-0.64,3.08-1.7c0.01,0.06,0.58-0.19,0.87-0.27l0.01,0.03
				c-0.04-0.19,0.25-0.3,0.43-0.44c0.04,0.1,0.03,0.16-0.05,0.26c0.01-0.04,0.07,0.05,0.35-0.31l0.02,0.15
				c0.24-0.14,0.42-0.4,0.78-0.49c-0.04-0.12-0.42-0.18-0.12-0.43c0.04,0.05,0.11,0.15,0.04,0.22c0.02-0.02,0.08-0.1,0.15-0.11
				l-0.08,0.17c0.04-0.08,0.43-0.24,0.14-0.01c0.14-0.05,0.32-0.08,0.41-0.05c0.2-0.49,0,0.01,1-0.76l0,0c0.35-0.35,0,0,1.29-0.66
				c0.06,0.2,0.38-0.2,1.37-0.67c-0.15,0.18,0.1,0.03-0.16,0.16c0.26-0.09,0.36-0.05,0.56-0.31c-0.03,0.16,0.26,0.04,0.36-0.05
				l-0.21,0.01c0.12-0.09,0.24-0.14,0.36-0.19l0.01,0.18c0.59-0.22,1.14-0.58,2.23-1.34c-0.38-0.23,0.75,0.1,0.5-0.19
				c0.34-0.08,0.46-0.24,0.8-0.35c-0.12-0.02,0.69-0.9,0.71-0.26c0.22-0.25,0.55-0.21,0.72-0.27l-0.12,0.12
				c0.07-0.07,0.15-0.11,0.22-0.15l-0.14-0.06c0.1-0.03,0.17-0.06,0.19-0.02c0.1-0.21-0.23-0.36,0.4-0.45
				c-0.37,0.14,0.07,0.12-0.16,0.28c0.05-0.04,0.17-0.11,0.18-0.04c0.41-0.06,0.08-0.24,0.43-0.42c0,0.1,0.16,0.11,0.21,0.06
				c-0.1,0.07-0.13-0.18,0.31-0.45l-0.15,0.04c0.14-0.37,0.65-0.19,1.71-0.57c-0.14-0.06-0.14-0.03,0.02-0.24l-0.44,0.32l0.23-0.31
				c-0.15,0.07-0.27,0.22-0.37,0.25c0.1-0.16,0.1-0.13-0.01-0.18c0.76-0.36,0.53,0.36,1.79-0.61l-0.17,0.02
				c0.01-0.13,0.27-0.15,0.4-0.26c0.03,0.1,0.14,0.09,0.22,0.11c-0.04-0.06,0.33-0.15,0.29-0.35c0.48-0.02,0.29,0.12,0.94-0.54
				c-0.07,0.14,0.12,0.14-0.08,0.31c0.39-0.34-0.21,0.05,3.09-1.39c-0.01,0.03,0.01,0.05-0.03,0.05c0.77-0.36,0.02,0.02,1.26-0.27
				l-0.06,0.04c0.23,0.05,0.45-0.25,0.78-0.32c0,0-0.01,0.03,0.01,0.05c0.18-0.23,0.6-0.37,0.9-0.49c0,0.05,0,0.08,0,0.11
				c0.03-0.11,0.14-0.25,0.53,0l-0.27,0.07c0.22,0.16,0.35,0.08,1.31-0.37l-0.06-0.05c0.59-0.27,0.26,0.18,1.37-0.28
				c-0.15,0.17-0.47,0.29-0.67,0.41c0.16-0.02,0.54-0.09,0.61-0.2c-0.06,0.03-0.14,0.09-0.2,0.07c0.36-0.06,2.34-0.66,4.72,0.06
				c-0.05-0.11,0.12-0.16,0.31-0.12l-0.27-0.14c0.47-0.16,0.41,0.26,0.88,0.1l-0.15,0.09c0.29,0.1,0.39-0.11,2.88-0.09l-0.05,0.07
				c0.33-0.07,0.14,0.09,0.77-0.01l-0.13,0.07c0.56,0.16,0.89-0.11,1.37-0.11c-0.03,0.22,0.41,0.35,0.62,0.42l-0.01,0.03
				c0.86,0.31,0.12-0.54,3.59,0.59c-0.02-0.04,0.13-0.25,0.47-0.05c-0.08-0.02,0.4,0.55,0.68,0.03c0.16,0.35,0.9,0.1,3.31,1.12
				l-0.13,0.07c0.93-0.09,0.38-0.11,2.14,0.44c-0.02,0.03-0.05,0.08-0.13,0.06c0.22,0.08,0.8,0.42,0.82,0.12
				c-0.06,0.18,0.48,0.18,0.76,0.19l-0.08,0.12c0.02-0.04,0.34-0.02,1.04,0.1l-0.14,0.05c0.29,0.06,0.57,0.22,2,0.77
				c-0.31-0.13-0.57-0.23-0.54-0.23c-0.05,0.12,0.2,0.24-0.06,0.33c0.15,0.13,0.55-0.16,1.63,0.52c0.04-0.13,0.2-0.05,0.09-0.22
				c0.03,0.02-0.06-0.02-0.18-0.03c0,0,0.01-0.01,0.01-0.01c-0.08-0.03-0.2-0.07-0.32-0.12c0.1-0.11,0.16,0.25,0.63-0.05
				c0.25,0.56,0.27,0.47,1.79,0.98c-0.35,0-0.32-0.05,0.76,0.31l-0.07,0.03c0.63,0.59,1.4,0.09,2.07,0.8
				c-0.05-0.05-0.07-0.07-0.07-0.1c0.12,0.16,2.26,0.91,4.77,2.24l-0.02-0.12C69.68,449.36,69.94,449.03,71.02,448.8z M58.77,443.76
				c0.04-0.02-0.06-0.09-0.13-0.15C58.71,443.63,58.96,443.67,58.77,443.76z M59.48,444.89c-0.02,0.02,0,0-0.06,0.05
				C59.34,444.93,59.21,444.81,59.48,444.89z M59.17,443.8c0.06,0.01,0,0,0.14,0.04C59.28,443.84,59.24,443.83,59.17,443.8z"
                />
                <path
                  className="st10"
                  d="M344.2,447.31l0.46,0.09c-0.54-0.24-1.42-0.45-4.47-1.22c0.54-0.07-0.9-0.36-0.67-0.58
				c-0.41,0.08-1.18-0.43-1.8-0.57c0.04,0,0.12-0.01,0.16,0.03c-0.28-0.15-0.36-0.25-0.5-0.38c-0.01,0.09-1.54-0.18-0.8-0.41
				c-0.2-0.02-0.6-0.21-0.55-0.06c-0.23-0.26-0.22-0.18-0.29-0.41c0.08,0.36-1.65-0.76-0.8-0.62c-1.38-0.27,0.02-0.02-3.26-1.73
				c-0.1,0.23-0.06,0.33-0.52-0.06c0.86,0.34,0.19-0.34-0.01-0.26c-0.04-0.89-1.56-0.99-3.45-2.56l0.27-0.03
				c-1.89-1.07,1.03,0.56-9.03-6.78l0.15,0.05c-0.47-0.4-0.73-0.15-1.12-0.58l0.1,0.04c-1.05-0.57-3.56-2.66-6.53-3.77l0.06-0.14
				c-0.02,0.06-0.37-0.06-0.97-0.43c-0.02,0.03-0.03,0.05-0.03,0.07c-0.09-0.15-0.5-0.47-2.04-0.88c0.05-0.05,0.24,0.01,0.41,0.03
				c-1.12-0.32-0.18-0.08-2.11-0.75l0.13-0.07c-3.52-1.11-3.75-1.46-16.22-3.04l0.06-0.01c-1.67,0.12-2.66-0.13-7.22-0.01l0.06,0.08
				c-1.54,0.11-0.4,0.35-8.12,1.33l0.24-0.22c-1.5,0.53-3.14,0.73-4.58,1.29c0.49-0.29-1.43,0.24-5.19,1.54
				c-0.02-0.23-1.02,0.09-1.34,0.29c0.1-0.1-0.32,0.02-0.21-0.11c-0.4,0.11-0.75,0.25-0.65,0.34c-1.39,0.11-1,0.16-5.23,1.41
				c0.19-0.13,0.33-0.22,0.44-0.27c-0.68,0.15-0.88,0.25-4.59,0.94c0.02,0.02,0.12,0.05-0.74,0.47c0.27-0.33-0.92,0.04-0.48-0.39
				c-0.12,0.03-0.26,0.1-0.23,0.16c-0.18,0-0.24-0.03-0.07-0.13c-0.64,0.13-0.4,0.22-0.99,0.28c0-0.05,0.11-0.08,0.17-0.14
				c-0.29,0.13-0.58,0.25-1.08,0.26c0.03-0.03-0.03-0.07,0.11-0.08c-0.86,0.11-1.44,0.39-2.4,0.38c0.14-0.12,0.16-0.28-0.9-0.13
				l0.21,0.07c-0.45,0.03-0.83,0.2-1.22,0.17l0.33-0.1l-0.83,0.1l0.48,0.03c-0.44,0.13,0.07,0.23-0.53,0.23
				c0.12-0.03-0.45,0.03-0.69-0.06l0,0c-0.95,0.24-0.27-0.32-2.08,0.21c0.21-0.27-0.68-0.16-0.86,0.05
				c0.06-0.02,0.03-0.03,0.15-0.06c-0.8,0.11-1.13,0.1-1.4,0.07l0.21-0.04c-1.02,0.05,0.07-0.04-3.54,0.52l0.18,0
				c-1.05,0.39-0.76-0.3-3.35,0.64l0.11-0.09c-1.76,0.53-1.46,0.14-5.06,1.48c0.18-0.14,0.24-0.17-0.71,0.28
				c0.12,0.08,0.14,0.01,0.37,0.02c-0.09,0.1-0.32,0.27-0.54,0.36c0.37-0.25-0.08-0.17-0.28-0.15c0.04,0.02,0,0.08,0.03,0.11
				c-0.55,0.02,0.02,0.25-0.67,0.41c0-0.12,0.01-0.29-1.42,0.69c0.13-0.11,0.34-0.23,0.47-0.33c-0.42,0.04-0.44,0.1-0.7,0.21
				l0.03-0.01l-0.62,0.25l0.17-0.04c-0.26,0.54-0.85,0.54-1.31,1.05c0.46-0.5,0.03-0.16,0-0.32c-0.17,0.04-0.41,0.13-0.37,0.05
				c-1.21,0.73-0.77,0.82-6.01,2.78c0.12-0.28,0.22-0.25-1.55,0.16l0.26,0.05c-0.02-0.1,0.1-0.28,0.54-0.08l-0.25,0.06
				c0.19,0.06,0.26,0.17,1.5-0.27c-0.01,0.15-0.01,0.33,1.26-0.12c-0.05,0.07,0.11,0.12-0.12,0.16c0.67-0.1,0.94-0.52,1.23-0.46
				l-0.05,0.03c1.83-0.57,1.42-1.01,4.72-2.41c0.07,0.18-0.19,0,0.97-0.59c0,0.07,1-0.43,1.53-0.57l0,0.03
				c0.02-0.21,0.53-0.41,0.89-0.61c0.03,0.11-0.02,0.18-0.2,0.31c0.04-0.04,0.06,0.05,0.72-0.43c-0.12,0.44,0.2,0.02,1.76-0.84
				l-0.23,0.19c0.1-0.08,0.85-0.26,0.24-0.01c1.2-0.27,0.49-0.14,2.81-0.85l0,0c1.19-0.46,1.14-0.12,5.33-0.87
				c-0.38,0.14,0.14,0.06-0.39,0.12c0.5-0.03,0.63,0.04,1.15-0.16c-0.17,0.15,0.4,0.12,0.63,0.05l-0.36-0.05
				c0.27-0.05,0.5-0.06,0.74-0.08l-0.11,0.18c1.07-0.02,2.29-0.21,4.71-0.56c-0.51-0.38,1.27,0.31,0.98-0.01
				c0.62,0.04,0.95-0.07,1.59-0.07c-0.07-0.09,1.74-0.62,1.38-0.01c0.56-0.16,1.08-0.03,1.41-0.04l-0.3,0.08
				c0.18-0.05,0.33-0.06,0.48-0.08l-0.18-0.1c0.18,0,0.33-0.01,0.33,0.04c0.35-0.28-0.03-0.37,1-0.32c-0.98,0.06-0.36,0.65,0.9-0.05
				c-0.08,0.1,0.19,0.15,0.31,0.11c-0.24,0.02,0.04-0.23,0.86-0.38l-0.29,0.01c0.43-0.34,1.28-0.03,3.3-0.29
				c-0.19-0.08-0.22-0.05,0.18-0.25l-0.96,0.27l0.6-0.29c-0.29,0.06-0.61,0.19-0.79,0.2c0.28-0.15,0.26-0.11,0.1-0.19
				c1.41-0.22,0.87,0.43,3.44-0.48l-0.3,0.02c0.78-0.38,0.87,0.04,3.74-1.13c-0.18,0.16,0.15,0.14-0.29,0.34
				c1.1-0.43,0.49-0.44,5.98-1.85c-0.02,0.04,0.01,0.05-0.08,0.06c1.51-0.53,0.03,0.04,2.32-0.52l-0.11,0.05
				c0.57-0.07,0.19-0.04,3.2-1.13c0,0.05-0.01,0.08-0.01,0.11c0.07-0.12,0.3-0.29,0.95-0.09l-0.49,0.12
				c0.29,0.12,0.64-0.14,0.9-0.17l-0.02,0.08c0.75-0.19,0.72-0.38,1.54-0.52l-0.1-0.04c1.12-0.43,0.49,0.36,2.52-0.48
				c-0.28,0.19-0.89,0.36-1.26,0.51c0.29-0.04,0.99-0.17,1.13-0.29c-0.12,0.04-0.26,0.11-0.38,0.1c1.57-0.59,0.44-0.07,6.67-0.42
				c-0.44-0.29,0.35-0.02,0.27-0.32c0.15-0.02,0,0,0.42,0c0,0,0,0-0.01,0c0.41,0.04,0.73,0.24,1.29,0.51
				c-0.08-0.11,0.22-0.15,0.57-0.1l-0.5-0.15c0.87-0.14,0.74,0.27,1.62,0.13l-0.28,0.09c0.78,0.11,0.85-0.15,5.28,0.18l-0.09,0.07
				c0.35-0.01,0.14,0.02,1.4,0.12l-0.24,0.05c1.01,0.27,1.62,0.02,2.49,0.15c-0.04,0.22,0.75,0.45,1.14,0.56l-0.02,0.03
				c1,0.59,2.83-0.08,12.38,4.06l-0.08,0.12c0.64,0.37,0.96,0.55,1.77,0.95l-0.21,0c1.63,0.55,0.77,0.18,3.49,1.87
				c-0.03,0.02-0.09,0.05-0.21-0.02c0.36,0.22,1.27,1.03,1.31,0.71c-0.1,0.16,0.75,0.55,1.2,0.76l-0.14,0.07
				c0.06-0.02,0.57,0.25,1.64,0.89l-0.22-0.05c0.42,0.28,1.18,0.87,3.09,2.41c-0.49-0.39-0.88-0.7-0.83-0.68
				c-0.08,0.1,0.3,0.43-0.12,0.32c0.35,0.22,0.05-0.09,1.42,0.86l-0.05-0.02c0.55,0.39,0.25,0.29,1.17,1.02
				c0.07-0.12,0.32,0.1,0.14-0.18c0.05,0.05-0.1-0.07-0.28-0.17l0.02,0c-0.13-0.1-0.3-0.23-0.5-0.38c1.57,0.78,0.78,0.94,3.79,2.88
				c-0.56-0.24-0.5-0.28,1.21,0.89l-0.12-0.02c1.04,1.12,2.24,1.03,3.41,2.25c-0.08-0.08-0.12-0.12-0.13-0.16
				c1.94,1.14-0.88,0.22,8.64,4.35l-0.09-0.13c0.34,0.12,1.1,0.27,1.01,0.37c0.45-0.12,2.95,1.26,2.52,0.42
				C345.42,448.31,345.63,447.64,344.2,447.31z M324,435.83c0.06,0.01-0.09-0.14-0.19-0.27C323.91,435.63,324.25,435.83,324,435.83z
				 M325.04,437.67c-0.15,0-0.13,0.02-0.27-0.17C324.84,437.53,324.75,437.53,325.04,437.67z M324.62,436.19
				c0.09,0.06,0,0,0.21,0.15C324.79,436.31,324.72,436.26,324.62,436.19z"
                />
                <path
                  className="st10"
                  d="M176.73,357.27c0.45-0.34-0.14-0.08,0.75-0.97c-0.26,0.33-0.45,0.62-0.44,0.58
				c0.11,0.07,0.31-0.2,0.31,0.13c0.11-0.26-0.09-0.04,0.49-1.02l-0.01,0.04c0.26-0.38,0.2-0.17,0.71-0.77
				c-0.12-0.08,0.04-0.24-0.18-0.15c0.04-0.03-0.05,0.07-0.11,0.19l0-0.02c-0.07,0.09-0.16,0.2-0.26,0.33
				c0.46-1.09,0.63-0.49,2.1-2.45c-0.18,0.38-0.22,0.32,0.72-0.72l-0.01,0.09c0.93-0.46,0.91-1.46,2.04-1.86
				c-0.08,0.03-0.11,0.04-0.15,0.03c0.59-0.03,2.46-2.14,5.62-3.37l-0.12-0.06c0.81-0.11,1-0.45,1.6-1.53
				c-0.18,0.04-0.41-0.01-0.78,0.47c-0.12-0.42-0.4,0.5-0.51,0.02c-0.06,0.17-0.49-0.08-1.59,0.92c0.18-0.39-0.62,0.3-0.67,0.02
				c-0.1,0.34-0.75,0.52-1.05,0.87c0.01-0.03,0.03-0.09,0.07-0.1c-0.22,0.11-0.32,0.09-0.48,0.14c0.23-0.14-0.53,1.07-0.49,0.33
				c-0.06,0.14-0.32,0.35-0.2,0.39c-0.26,0.03-0.2,0.07-0.39,0c0.29,0.11-0.94,0.91-0.66,0.3c-0.41,0.93-0.1-0.1-1.85,1.88
				c0.14,0.12,0.31,0.16-0.08,0.4c0.32-0.63-0.31-0.26-0.22-0.08c-0.65-0.28-1,0.95-2.14,2.13l-0.05-0.23
				c-0.54,1.34-0.45-0.51-4.42,6.54l0.01-0.11c-0.24,0.35,0.06,0.56-0.23,0.84l0.01-0.08c-0.69,0.26,0.55,0.93-3.3,3.41
				c-0.3-0.3,0.08-0.1-2.06,0.73c0-0.06,0.12-0.13,0.21-0.2c-0.36,0.29-0.13-0.13-0.46,0.14c0.06,0.02,0,0.1,0,0.13
				c-0.22-0.02-0.65,0.12-0.94,0.25l0.03-0.12c-0.53,0.13-1.09,0.32-1.62,0.28c-2.43-1.65-4.75-3.28,0.86-11.27
				c-0.76,1.15-1.46,4.66-10.16,1.36l0.23,0.05c-0.7-0.7-0.79-1.74-0.84-2.63c0.26,0.36,0.01-1.12,1.35-3.07
				c0.15,0.16,0.56-0.36,0.67-0.6c-0.01,0.1,0.2-0.09,0.2,0.04c0.19-0.19,0.36-0.38,0.24-0.44c0.88-0.38,0.52-0.37,2.9-2.24
				c-0.02,0.09-0.05,0.17-0.09,0.24c0.51-0.31,0.53-0.36,2.62-1.63c0.01,0.02-0.04,0.07-0.09,0.12c0.26-0.26-0.03,0.04,0.4-0.69
				c-0.06,0.36,0.6-0.21,0.46,0.27c0.07-0.05,0.14-0.14,0.09-0.19c0.12-0.03,0.17-0.01,0.09,0.11c0.38-0.24,0.19-0.27,0.56-0.44
				c0.02,0.05-0.05,0.1-0.06,0.17c0.15-0.17,0.3-0.34,0.63-0.44c-0.01,0.04,0.04,0.06-0.05,0.09c0.53-0.24,0.84-0.61,1.49-0.72
				c-0.07,0.14-0.03,0.28,0.63,0.02l-0.16-0.05c0.29-0.08,0.51-0.28,0.78-0.28l-0.2,0.13c0.18-0.06,0.36-0.12,0.54-0.17l-0.33,0
				c0.28-0.16-0.09-0.22,0.32-0.26c0.61,0.02,0.2,0.08,1.88-0.23c-0.13,0.26,0.46,0.18,0.59-0.03c-0.04,0.01-0.02,0.03-0.1,0.06
				c0.55-0.06,0.77-0.04,0.95-0.01l-0.13,0.03c0.67,0.01-0.06,0.02,2.43-0.17l-0.12-0.02c0.09-0.27,0.49,0.12,0.82,0
				c0.1,0.29-0.08,0.08,1.51-0.03l-0.08,0.07c1.22-0.07,0.99,0.1,3.59,0.1c-0.2,0.06-0.32,0.07,0.51,0.03
				c-0.05-0.12-0.08-0.07-0.22-0.17c0.09-0.06,0.28-0.11,0.44-0.1c-0.3,0.06-0.01,0.19,0.11,0.26c-0.02-0.04,0.03-0.07,0.02-0.11
				c0.72,0.16,0.06-0.16,1.61,0.08c-0.12,0.02-0.28,0.01-0.4,0.03c0.25,0.19,0.28,0.15,0.47,0.2l-0.02-0.01l0.44,0.13l-0.11-0.06
				c0.38-0.29,0.68,0.03,1.16-0.11c-0.23,0.02-0.36,0.4,0.71,0.47c-0.24,0.01,1.77,0.15,3.74,1.09c-0.18,0.11-0.39,0.14,0.95,0.52
				l-0.13-0.13c-0.02,0.08-0.22,0.22-0.35-0.15c0.06,0.02,0.11,0.04,0.17,0.06c-0.07-0.5-0.13,0.3-1.64-1.03
				c0.06-0.02,0.01-0.15,0.15-0.06c-0.38-0.28-0.74-0.1-0.85-0.31l0.04,0c-0.67-0.19-1.98-0.73-4.37-0.87
				c0.03-0.06-0.74-0.19-1.11-0.28l0.01-0.03c-0.09,0.18-0.47,0.11-0.75,0.13c0.02-0.11,0.07-0.15,0.23-0.19
				c-0.04,0.03-0.03-0.09-0.59,0.08l0.07-0.13c-0.14,0.03-0.56-0.16-1.43,0.26l0.19-0.11c-0.08,0.04-0.63-0.01-0.16-0.07
				c-0.82-0.12-0.34-0.06-2.05,0.02h0c-0.6,0.14,0,0-1.82-0.02c0.03-0.2-0.58-0.01-1.93-0.02c0.27-0.09-0.1-0.07,0.28-0.07
				c-0.35-0.03-0.44-0.11-0.81,0.04c0.12-0.13-0.27-0.15-0.44-0.1l0.25,0.08c-0.19,0.03-0.35,0.03-0.52,0.03l0.08-0.17
				c-0.77,0-1.66,0.07-3.29,0.56c0.42,0.31-0.94-0.2-0.69,0.09c-0.44,0.02-0.65,0.17-1.09,0.25c0.1,0.05-1.06,0.86-0.95,0.21
				c-0.34,0.25-0.74,0.21-0.96,0.28l0.18-0.13c-0.11,0.08-0.21,0.12-0.3,0.16l0.16,0.06c-0.12,0.03-0.22,0.07-0.24,0.03
				c-0.15,0.26,0.18,0.36-0.57,0.49c0.48-0.17-0.08-0.13,0.25-0.31c-0.06,0.04-0.22,0.13-0.24,0.05c-0.51,0.09-0.14,0.26-0.6,0.48
				c0.02-0.11-0.18-0.1-0.25-0.04c0.15-0.08,0.1,0.2-0.44,0.52l0.19-0.06c-0.19,0.41-0.84,0.28-2.11,0.9c0.17,0.04,0.17,0-0.02,0.26
				l0.55-0.43l-0.29,0.38c0.18-0.11,0.34-0.29,0.45-0.34c-0.13,0.19-0.13,0.15,0.01,0.19c-0.9,0.51-0.7-0.26-2.15,1.08l0.2-0.07
				c-0.84,0.64-0.31-0.06-2.07,1.77c0.04-0.18-0.18-0.09,0.03-0.36c-0.32,0.58,0.19-0.2-2.59,3.62c-0.01-0.01-0.01-0.02-0.02-0.02
				c-0.18,1.06-0.18-0.06-0.47,1.65c-0.01-0.03-0.01-0.06-0.01-0.09c0.05,0.36-0.33,0.33,0.78,2.36c-0.04,0.01-0.06,0.01-0.09,0.02
				c0.11,0.03,0.27,0.17,0.21,0.67c-0.07-0.1-0.13-0.21-0.19-0.32c-0.07,0.26,0.25,0.42,0.34,0.6l-0.08,0.02
				c0.37,0.45,0.52,0.34,0.94,0.77l0-0.09c0.82,0.36-0.11,0.64,1.55,0.97c-0.26,0.06-0.7-0.11-1-0.2c0.18,0.13,0.66,0.38,0.81,0.33
				c-0.09-0.02-0.21-0.03-0.27-0.1c0.31,0,0.65,0.06,1.05,0.2c-0.61,0.79,2.65,0.37,3.35,0.37c-0.17,0.26,0.54-0.34-0.26,0.35
				c0.07-0.02-0.02,0.01,0.18,0.17c-0.55,0.49-0.22,0.5-0.45,2.32l-0.07-0.06c0.11,0.43-0.11,0.24,0.16,1.06l-0.11-0.16
				c0.03,0.82,0.48,1.16,0.79,1.71c-0.21,0.09,0.04,0.7,0.22,0.98l-0.03,0.01c0.6-1.33,0.57,4.52,10.4-1.04l-0.04,0.17
				c0.6-1.14,0.4-0.45,1.61-2.53c0.02,0.02,0.07,0.07,0.03,0.17c0.15-0.27,0.65-0.97,0.34-1c0.17,0.07,0.3-0.56,0.39-0.89l0.1,0.11
				c-0.03-0.03,0.05-0.43,0.39-1.22l0,0.17C176.21,358.12,176.11,358.39,176.73,357.27z M178.26,355.44
				c0.02,0.09,0.05,0.11-0.11,0.18C178.17,355.57,178.18,355.63,178.26,355.44z M177.12,355.41c-0.02,0.03-0.05,0.08-0.1,0.15
				C177.06,355.49,177.02,355.56,177.12,355.41z M176.81,356.02c0-0.05-0.12,0.06-0.21,0.12
				C176.65,356.07,176.78,355.8,176.81,356.02z"
                />
                <path
                  className="st10"
                  d="M226.33,355.55c0-0.33,0-0.01-0.3-1.27c0.04,0,0.06,0,0.09,0.01c-0.1-0.06-0.22-0.22-0.01-0.67
				c0.04,0.11,0.07,0.23,0.09,0.35c0.15-0.22-0.12-0.45-0.14-0.64l0.08,0c-0.19-0.52-0.38-0.46-0.58-1l-0.03,0.08
				c-0.56-0.65,0.34-0.46-0.87-1.52c0.23,0.1,0.52,0.44,0.72,0.64c-0.09-0.19-0.35-0.61-0.5-0.65c0.06,0.06,0.15,0.13,0.17,0.21
				c-1.18-1.29-0.04-0.05-2.78-3.74c0.03,0.42-0.19-0.16-0.36,0.11c-0.14-0.17-0.35-0.18,0-0.4c0.13,0.27-0.43-0.14-0.23,0.2
				c-0.21-0.24-0.19-0.46-0.4-0.96c-0.01,0.12-0.22,0.04-0.41-0.13l0.22,0.32c-0.6-0.2-0.31-0.52-0.96-0.69l0.17,0.01
				c-0.15-0.07-0.64-0.43-1.09-0.25c0.41-0.43-0.47,0.21,1.08-1.43c0.16,0.17,0.64-0.27,0.84-0.55l0.02,0.03
				c-1.68,0.64,4.59-4.02-6.25-7.45l0.14-0.09c-1.16,0.32-0.5,0.11-2.79,0.26c0.01-0.03,0.02-0.1,0.12-0.11
				c-0.29,0.02-1.14-0.04-1,0.24c-0.03-0.19-0.63,0.05-0.95,0.18l0.03-0.14c-0.01,0.06-0.38,0.18-1.21,0.42l0.13-0.11
				c-0.38,0.1-1.22,0.16-2.63,0.4c0.41-0.08,0.75-0.12,0.72-0.11c-0.02-0.13-0.36-0.09-0.12-0.31c-0.27,0.09,0.04,0.09-1.07,0.4
				l0.03-0.02c-0.45,0.12-0.27-0.03-1.04,0.13c0.04,0.13-0.18,0.16,0.05,0.23c-0.05,0,0.08-0.02,0.21-0.08l-0.01,0.01
				c0.11-0.03,0.25-0.06,0.41-0.09c-1.06,0.59-0.79-0.12-3.12,0.78c0.35-0.24,0.35-0.18-0.97,0.28l0.05-0.07
				c-1.06,0.01-1.42,0.93-2.59,0.94c0.08-0.01,0.12-0.01,0.15,0.02c-0.12-0.02-0.32,0.03-0.56,0.12c0.27-0.55,0.51-0.24,1.75-2.18
				c0.04,0.07,0.54-0.58,0.81-0.88l0.02,0.03c-0.11-0.17,0.14-0.48,0.27-0.75c0.09,0.07,0.09,0.14,0.05,0.3
				c-0.01-0.05,0.1,0.02,0.24-0.57l0.08,0.13c0.04-0.15,0.4-0.43,0.46-1.45l0,0.23c0-0.1,0.32-0.57,0.14-0.12
				c0.51-0.71,0.23-0.29,0.93-1.93l0,0c-0.02-0.44,0.9-1.42,1.49-3.64c-0.01,0.3,0.11-0.07-0.04,0.29c0.17-0.33,0.26-0.39,0.24-0.81
				c0.09,0.17,0.23-0.22,0.24-0.4l-0.16,0.22c0.04-0.19,0.09-0.36,0.15-0.52l0.14,0.13c0.27-0.75,0.48-1.66,0.57-3.43
				c-0.44,0.29,0.52-0.82,0.16-0.7c0.16-0.43,0.08-0.69,0.17-1.15c-0.09,0.07-0.37-1.36,0.18-1c-0.09-0.42,0.09-0.79,0.11-1.03
				l0.05,0.22c-0.03-0.13-0.03-0.24-0.02-0.35l-0.12,0.13c0.02-0.13,0.02-0.24,0.07-0.24c-0.19-0.25-0.4,0.02-0.21-0.75
				c-0.06,0.53,0.14-0.02,0.18,0.37c0.09-0.56,0.18-0.29-0.16-1.02c0.09,0.06,0.16-0.13,0.14-0.22c0.01,0.17-0.23,0-0.3-0.64
				l-0.02,0.21c-0.31-0.36,0.13-0.93-0.04-2.44c-0.09,0.16-0.05,0.17-0.24-0.12c0.09,0.23,0.16,0.47,0.23,0.71l-0.26-0.43
				c0.05,0.22,0.16,0.45,0.16,0.58c-0.13-0.21-0.1-0.19-0.18-0.07c-0.21-1.09,0.56-0.67-0.55-2.54l0.06,0.23
				c-0.63-0.97,0.14-0.41-2.01-2.14c0.2,0.01,0.04-0.2,0.38-0.03c-0.67-0.23,0.28,0.11-4.46-1.39c0.01-0.01,0.01-0.02,0.02-0.03
				c-1.1,0.15,0-0.16-1.76,0.05l0.09-0.04c-0.37,0.13-0.34-0.2-2.21,1.29c-0.02-0.04-0.02-0.06-0.03-0.09
				c-0.01,0.12-0.13,0.3-0.65,0.32l0.3-0.25c-0.26-0.02-0.39,0.31-0.55,0.43l-0.03-0.08c-0.42,0.43-0.28,0.57-0.68,1.05l0.09-0.02
				c-0.31,0.87-0.63-0.05-0.9,1.67c-0.05-0.27,0.09-0.73,0.17-1.03c-0.12,0.2-0.35,0.7-0.3,0.85c0.02-0.09,0.02-0.22,0.09-0.29
				c0.02,0.26-0.04,0.54-0.12,0.85c-0.02-0.06-0.04-0.12-0.05-0.19l-0.01,0.41c-0.37-0.57,0.06-0.64-0.36-1.2l0.18,0.16
				c-0.27-0.43-0.08-0.77-2.46-3.24l0.1-0.01c-0.38-0.23-0.1-0.24-0.85-0.67l0.19,0.05c-0.56-0.6-1.12-0.6-1.75-0.84
				c0.1-0.21-0.51-0.53-0.87-0.62l0.02-0.03c1.2,1.55-4.39-2.76-3.89,8.16l-0.14,0.01c0.07,0.55,0.09,0.83,0.28,1.48l-0.13-0.11
				c0.64,1.1,0.27,0.47,1,2.78c-0.03,0-0.1,0-0.14-0.1c0.09,0.3,0.25,1.19,0.49,0.99c-0.18,0.07,0.21,0.65,0.42,0.95l-0.15,0
				c0.06,0,0.28,0.36,0.73,1.16l-0.14-0.11c0.19,0.37,0.48,1.23,1.06,2.63c-0.17-0.41-0.31-0.75-0.28-0.72
				c-0.12,0.05,0,0.39-0.27,0.2c0.16,0.26,0.08-0.06,0.65,1.02l-0.03-0.03c0.22,0.44,0.04,0.28,0.36,1.05
				c0.12-0.07,0.19,0.16,0.22-0.09c0.01,0.05-0.04-0.08-0.12-0.2l0.02,0.01c-0.05-0.11-0.11-0.25-0.18-0.41
				c0.79,0.98,0.11,0.84,1.36,3.13c-0.28-0.34-0.22-0.35,0.39,1l-0.08-0.05c0.08,1.09,1.03,1.48,1,2.72c0-0.08,0-0.13,0.02-0.15
				c-0.16,0.41,0.53,1.9,0.83,4.01c-0.31-0.27-0.07-0.09-0.6-1.44c0.16-0.06-0.02,0.14-0.37-0.69c0.07,0-0.22-0.72-0.33-1.08l0.03,0
				c-0.2,0.02-0.33-0.34-0.49-0.57c0.1-0.04,0.16-0.01,0.27,0.1c-0.04-0.02,0.06-0.07-0.36-0.46l0.15,0
				c-0.1-0.1-0.14-0.56-0.94-1.09l0.19,0.11c-0.08-0.05-0.31-0.54-0.03-0.17c-0.31-0.76-0.12-0.32-1.06-1.73l0,0
				c-0.38-0.2-0.62-1.47-2.06-3.1c0.23,0.16,0-0.12,0.22,0.18c-0.18-0.3-0.17-0.41-0.5-0.62c0.18,0.02-0.05-0.3-0.18-0.41l0.09,0.24
				c-0.13-0.13-0.23-0.26-0.33-0.4l0.18-0.04c-0.44-0.62-1.05-1.26-2.37-2.28c-0.02,0.51-0.36-0.86-0.46-0.51
				c-0.27-0.34-0.5-0.43-0.8-0.76c0.01,0.12-1.29-0.41-0.69-0.67c-0.38-0.15-0.57-0.49-0.74-0.65l0.2,0.08
				c-0.12-0.05-0.21-0.11-0.29-0.17l0.03,0.17c-0.09-0.09-0.18-0.15-0.15-0.19c-0.3,0.01-0.2,0.35-0.72-0.21
				c0.4,0.31,0.07-0.13,0.39,0.04c-0.07-0.03-0.22-0.11-0.17-0.16c-0.35-0.38-0.29,0.03-0.72-0.25c0.1-0.04-0.02-0.2-0.1-0.23
				c0.15,0.08-0.12,0.2-0.67-0.08l0.16,0.12c-0.44,0.07-0.68-0.57-1.94-1.23c0.07,0.16,0.1,0.14-0.22,0.13l0.67,0.19l-0.48-0.01
				c0.19,0.08,0.43,0.1,0.54,0.17c-0.23,0.01-0.2-0.01-0.15,0.12c-0.94-0.42-0.2-0.75-2.15-1.07l0.18,0.11
				c-1.02-0.23-0.15-0.28-2.66-0.47c0.17-0.08-0.05-0.19,0.3-0.21c-0.67,0.13,0.27,0.03-4.38,0.19c0.01-0.01,0-0.02,0.01-0.03
				c-0.97,0.46-0.05-0.19-1.61,0.57l0.07-0.07c-0.19,0.27-0.66-0.11-1.24,2.17c-0.04-0.02-0.05-0.03-0.08-0.04
				c0.06,0.1,0.09,0.3-0.23,0.68c0.01-0.13,0.01-0.26,0.03-0.39c-0.21,0.18-0.04,0.51-0.06,0.7l-0.08-0.02
				c0.08,0.6,0.28,0.57,0.43,1.15l0.04-0.08c0.55,0.69-0.4,0.51,0.92,1.56c-0.25-0.07-0.57-0.41-0.78-0.63
				c0.1,0.2,0.41,0.63,0.56,0.66c-0.07-0.06-0.17-0.12-0.19-0.21c0.28,0.13,0.54,0.35,0.82,0.66c-0.37-0.19,0.22,0.63,0.92,1.4
				c-0.01,0-0.05,0-0.22,0.02c0.64,0.25-0.07,0.02,0.42,0.3c-0.24-0.03-0.13,0.08-0.54-0.28c0.17,0.1,0.05,0.12,0.32,0.28
				c-0.21-0.02-0.45-0.11-0.6-0.5c-0.04,0.15,0.05,0.13-0.3,0.11c0.1,0.05,0.24,0.1,0.31,0.15c-0.55,0.15-0.48-0.27-1.08-0.14
				l0.2-0.09c-0.46,0.09-0.72-0.42-3.84,0.77l0.02-0.1c-0.35,0.32-0.3,0-0.89,0.73c0.02-0.07,0.05-0.13,0.08-0.19
				c-0.74,0.58-0.66,1.28-0.67,1.92c-0.23-0.03-0.32,0.66-0.28,0.99l-0.03-0.01c1.77,1.5-0.91,2.97,6.91,6.11l-0.05,0.13
				c0.49,0.19,0.74,0.29,1.38,0.41l-0.15,0.07c1.2-0.1,0.51-0.04,2.78,0.3c-0.01,0.03-0.04,0.09-0.14,0.08
				c0.29,0.05,1.12,0.29,1.04-0.01c-0.02,0.19,0.64,0.09,0.98,0.05l-0.07,0.13c0.02-0.05,0.42-0.09,1.29-0.13l-0.15,0.08
				c0.39,0,1.24,0.13,2.67,0.26c-0.42-0.04-0.76-0.08-0.73-0.09c-0.01,0.13,0.33,0.18,0.04,0.33c0.29-0.02-0.01-0.1,1.13-0.1
				l-0.04,0.01c0.46,0.01,0.25,0.1,1.03,0.18c0-0.14,0.22-0.1,0.02-0.23c0.05,0.02-0.08,0-0.22,0.02l0.01-0.01
				c-0.11-0.01-0.26-0.02-0.42-0.03c1.16-0.22,0.74,0.28,3.19,0.34c-0.41,0.07-0.39,0.01,0.99,0.17l-0.08,0.04
				c0.9,0.49,1.7-0.11,2.66,0.59c-0.07-0.05-0.1-0.07-0.1-0.1c1.11,0.96,0.06-0.68,5.55,3.14l0.01-0.13
				c0.35,0.44,0.59,0.82,1.97,1.06c-0.01-0.02-0.02-0.04-0.03-0.06c0.06-0.01,0.1,0.01,0.03,0.06c0,0,0,0,0,0c0,0.01,0,0-0.02,0.02
				c0.03,0.01,0.04-0.01,0.07,0.05c-0.01-0.02-0.02-0.03-0.03-0.04c0.07,0.01,0.12,0.03,0.03-0.1c0.1-0.06,0.2-0.04-0.13-0.1
				c0.02,0,0.04-0.01,0.07-0.01c-0.05-0.05-0.13-0.23,0.12-0.27c-0.09,0.02,0.07-0.08,0.23-0.58l-0.06,0.36
				c0.24-0.33,0.06-1.01,0.07-1.35c0.08,0.03-0.09,0.01,0.12-0.2c-0.05,0.08-0.03,0.15,0.19,0.38c-0.19-0.05-0.42-0.13,0.39,1.01
				l-0.02-0.18c-0.06,0.05-0.32,0.04-0.17-0.34l0.09,0.15c0.2-0.27-0.29-0.64-0.4-0.98c0.15-0.02,0.32,0.03-0.07-0.88
				c0.06,0.03,0.12-0.09,0.15,0.06c-0.19-0.45,0.13-0.22-0.22-1.18c0.28,0.35-0.02-0.73,0.26-0.65c-0.26-0.25-0.19-0.95-0.39-1.39
				c0.02,0.02,0.07,0.07,0.06,0.11c-0.02-0.26,0.04-0.35,0.06-0.52c0.06,0.28-0.81-0.94-0.13-0.61c-0.11-0.11-0.2-0.45-0.29-0.35
				c0.07-0.26,0.02-0.22,0.15-0.38c-0.21,0.24-0.51-1.26-0.05-0.76c-0.73-0.77,0.12-0.05-1.09-2.52c-0.17,0.09-0.27,0.23-0.34-0.24
				c0.46,0.56,0.36-0.19,0.16-0.18c0.52-0.53-0.53-1.29-1.13-2.92l0.23,0.06c-0.73-1.3-0.01,0.28-3.14-7.45l0.08,0.09
				c-0.09-0.42-0.45-0.35-0.45-0.78l0.05,0.06c0.23-0.24-0.13,0.01-1.17-5.89c-0.25,0.14,0.25-0.21-0.14-1.52
				c0.06,0.02,0.08,0.15,0.13,0.26c-0.16-0.43,0.16-0.08,0.02-0.47c-0.04,0.05-0.09-0.03-0.12-0.04c0.1-0.19,0.14-0.62,0.17-0.95
				l0.09,0.08c0.13-0.53,0.25-1.13,0.56-1.61c0.49-0.71,1.62-1.07,6.43,3.02l-0.03-0.03c1.9,0.07,0.6,8.26,4.39-1.61
				c-0.01,0.08-0.03,0.16-0.03,0.24c0.42-0.3,0.26-0.62,2.64-1.47c-0.32,0.37,1.15-0.31,3.56,0.26c-0.1,0.19,0.54,0.42,0.79,0.46
				c-0.1,0.02,0.15,0.16,0.02,0.2c0.23,0.13,0.45,0.24,0.48,0.12c0.76,1.11,0.87,0.51,2.13,2.81c-0.88-0.49-0.21-1.44,0.38,2.92
				c-0.02,0-0.05-0.07-0.07-0.14c0.13,0.35-0.03-0.05,0.47,0.68c-0.31-0.21-0.03,0.66-0.43,0.32c0.02,0.08,0.08,0.19,0.15,0.17
				c-0.01,0.13-0.05,0.17-0.14,0.04c0.07,0.46,0.17,0.3,0.17,0.72c-0.05,0-0.07-0.09-0.13-0.13c0.1,0.21,0.19,0.43,0.15,0.79
				c-0.03-0.02-0.07,0.02-0.07-0.09c0.02,0.61,0.23,1.06,0.08,1.73c-0.1-0.12-0.26-0.14-0.25,0.61l0.1-0.14
				c-0.02,0.32,0.07,0.61-0.03,0.87l-0.04-0.24l-0.05,0.59l0.12-0.33c0.05,0.33,0.24,0,0.13,0.41c-0.01-0.09-0.05,0.32-0.18,0.47
				l0,0c0.05,0.75-0.33,0.07-0.24,1.48c-0.2-0.21-0.32,0.4-0.16,0.6c0-0.04-0.02-0.03-0.03-0.12c-0.13,0.58-0.22,0.79-0.31,0.96
				l0.02-0.16c-0.23,0.67,0.01-0.06-0.68,2.45l0.06-0.11c-0.07,0.8-0.44,0.32-0.87,2.26l-0.03-0.11c-0.46,1.18-0.5,0.89-1.75,3.29
				c0.02-0.17,0.04-0.21-0.28,0.46c0.13,0.02,0.11-0.04,0.27-0.11c0.01,0.11-0.05,0.3-0.15,0.45c0.11-0.3-0.16-0.1-0.29-0.02
				c0.04,0,0.05,0.06,0.09,0.07c-0.51,0.58,0.11,0.13-0.89,1.43c0.04-0.12,0.15-0.25,0.19-0.37c-0.29,0.13-0.28,0.18-0.42,0.33
				l0.02-0.01l-0.34,0.33l0.11-0.07c-1.13,2.1,0.37,0.13-5.52,4.27c-0.01-0.03-0.02-0.06-0.04-0.11c0.07,0.04-0.02-0.05,0.23-0.16
				c-0.18-0.05-0.13-0.11-0.16-0.16c0.11,0.02,0.15,0.04,0.19,0.07c-0.01-0.06,0.01-0.04-0.09-0.12l0.13,0.04
				c0-0.06-0.13-0.15-0.03-0.22c-0.13-0.03-0.36,0-0.43-0.09c0.11,0.01,0.13,0.06,0.52-0.02c-0.05-0.01-0.29-0.1,0.04-0.08
				c-0.07-0.03,0.01-0.06-0.14-0.08c0-0.02,0.07-0.01,0.11-0.01c-0.07-0.03,0.01-0.01-0.17-0.14v0c-0.24-0.11,0.41-0.43,0.45-1.47
				L195,339c0.06-0.02,0.07-0.11,0.16-0.08c-0.05-0.05-0.1-0.03-0.13,0c0.09-0.07,0.02-0.12,0.07-0.19
				c0.05,0.09-0.28-0.31,0.12-0.17c-0.11-0.08,0.08-0.13,0.11-0.17l0.05,0.04c-0.03-0.02-0.03-0.04-0.02-0.06l-0.12,0.01
				c0.02-0.02,0.02-0.04,0.07-0.04c-0.08-0.03-0.2,0.01-0.3-0.05c0.06-0.03-0.06-0.09,0.1-0.1c-0.06,0.09,0.16,0,0.18,0.08
				c0.09-0.13,0.17-0.03-0.12-0.19c0.09,0.02,0.17-0.01,0.15-0.03c0,0.04-0.15,0.01-0.21-0.03c0.07-0.06-0.05-0.07-0.04-0.1
				c-0.07,0.03-0.06,0.05-0.13-0.05c0.07,0,0.05,0.04,0.08,0.03c-0.15-0.05,0.17-0.1,0.07-0.17c0.06-0.03,0.17,0,0.21-0.02
				c-0.18-0.06,0.23-0.13-0.18-0.19l0.08,0.13c-0.27,0.01-0.27-0.34,0.04-0.54c-0.07,0,0.01-0.09-0.19-0.1
				c0.43,0-0.39-0.31,0.14-0.2c-0.09-0.1,0.02,0.01-0.35-0.24c0.31-0.1-0.03-0.25,0.23-0.34c-0.23-0.37-0.19-0.06-0.26-0.89
				c0.13,0.02,0.07,0.03,0.2,0.01c-0.56-0.01-0.08-0.21-0.01-0.5c-0.05-0.03-0.2-0.12-0.24-0.18c0.15,0.03,0.23,0.1,0.32,0.14
				c-0.17,0.03,0.03-0.1-0.23-1.03c0.05-0.06,0.19-0.14,0.45-0.21c-0.11,0.02-0.16-0.01-0.12-0.06c-0.03,0.11-0.17-0.17,0.04-0.11
				c-0.06-0.05-0.03-0.21-0.28-0.23c0.6,0.14-0.49-0.52-0.16-1.87c-0.03,0.03-0.17,0.03-0.19,0.01c0.04-0.04-0.17-0.06,0.03-0.1
				l0,0.01c0.19-0.07,0.12-0.12,0.11-0.18l-0.26,0.05c0.34-0.23-0.23-0.32,0.09-0.48c-0.07-0.02-0.08-0.06-0.15-0.07
				c0.08-0.05,0.22,0,0.09-0.29c0.46-0.02-0.08-0.3-0.33-0.58c0.18-0.05-0.02-0.12-0.11-0.15c0.17-0.06,0.23,0.06-0.12-0.38
				c-0.1,0.02-0.13-0.11-0.08-0.27c0.02,0.08,0.04,0.14,0.02,0.13c0.07,0.06,0.26-0.49-0.15-0.38c0.01-0.01,0.01,0.01,0.05,0.03
				l-0.01,0c0.01,0.02,0.02,0.04,0.03,0.07c-0.08,0-0.14-0.01-0.08-0.06c-0.09,0.01-0.18,0.03-0.23,0.03
				c-0.01-0.08,0.39-0.3-0.13-0.59c0.05-0.09,0.17-0.02,0.11-0.1c0.32-0.25,0.06-0.02-0.22-0.45c-0.02,0.02-0.02,0.03-0.05,0.05
				c-0.01-0.09,0.16-0.41-0.36-0.75l0.13-0.07c-0.3,0.38,0.07-0.5-0.64-0.63c0.01,0,0.01,0.01,0.02,0
				c-0.13,0.07-0.29,0.17-0.55,0.33l0.16,0l-0.09,0.07l-0.03-0.05c-0.08,0.08,0.01,0.05,0.08,0.05c-0.05,0.03-0.09,0.06-0.1,0.08
				l0.23-0.03c-0.08,0.05-0.18,0.05-0.2,0.11c0.11-0.01,0.17-0.11,0.26-0.09c-0.08,0.07-0.16,0.1-0.07,0.1
				c-0.05,0.03-0.09,0.04-0.12,0.04c0.08,0,0.06,0.07,0.19,0.04c-0.1,0.09,0.08,0.09-0.12,0.18c0.05-0.01,0.18-0.01,0.2,0.01
				c-0.27,0.05,0.03,0.11-0.23,0.21c0.23-0.06,0.16,0.1,0.31,0.12c-0.02,0-0.06,0.01-0.05,0c0,0.05-0.06,0.09-0.09,0.13
				c0.18-0.07,0.25-0.03,0.37-0.04c-0.16,0.12-0.22,0.06-0.39,0.14c0.15-0.06,0.18,0.02,0.3-0.04c-0.09,0.08-0.04,0.05-0.18,0.13
				c0.23-0.09,0.05,0.02,0.2-0.01c-0.09,0.1-0.21,0.1-0.11,0.13c-0.3,0.16,0.06,0.02,0.36,0.31c-0.01,0.01-0.03,0.02-0.04,0.02
				c0.01,0.02,0.21-0.16,0.25-0.04c0-0.02-0.05,0-0.09,0l0.06,0.01c-0.15,0.09-0.07-0.02-0.19,0.03c0.05,0.02,0.32,0.18-0.01,0.14
				l0.03-0.09c-0.09,0.17-0.2,0.36,0.34,0.94c-0.05,0.01-0.11,0.01-0.15,0.05c0.16-0.13,0.38,0.41,0.42,1.37
				c0.14-0.03,0.33,0.25,0.08,0.45l0.17,0.02l-0.17,0.07c0.24,0.04-0.25,0.05,0.17,0.27c-0.03,0.01-0.05,0-0.07-0.01
				c0.07,0.02-0.21,0.09,0.06,0.06c-0.08,0.06-0.07,0.13-0.02,0.18c-0.13,0.06-0.15-0.02,0.01,0.34c0.04-0.05,0.22-0.08,0.25-0.05
				l-0.16,0.05c0.1,0,0.05-0.05,0.18-0.03c-0.01,0.05-0.18,0.07-0.23,0.05l0.07,0.04c-0.05,0.01-0.07,0.01-0.14,0.01
				c0.1,0-0.14,0.12,0.04,0.12c-0.49-0.36,0.65,1.26,0.29,2.25c0.02,0,0.12-0.02,0.11,0c0.15,0.02-0.03,0.97-0.01,0.93
				c0.21,0.17,0.02,0.38,0.19,0.56c-0.04-0.01-0.09-0.01-0.09-0.03c-0.12,0.04,0,0.14,0.1,0.14c-0.04-0.02-0.25,0.1-0.02,0.22
				c-0.06,0.07,0.07,0.13-0.01,0.19c0.16,0.03-0.07,0.12,0.15,0.13c-0.23,0-0.18,0.12-0.07,0.17c-0.07-0.01-0.07,0.04-0.17,0.02
				c0,0.05,0.04,0.1,0.16,0.09c-0.27,0.09,0.22,0.19-0.13,0.24c0.12,0.15,0.2,0.29,0.25,0.42c-0.21,0.06-0.14-0.05-0.08,0.51
				c-0.02,0-0.04-0.01-0.06-0.03c0.01,0.02-0.04,0.05,0.07,0.05l-0.03-0.02c0.15-0.03,0.31,0.1,0.38,0.11
				c-0.29-0.05-0.08,0.11-0.45,0.03c0.02,0.02,0.07,0.04,0.13,0.04c-0.16,0.14-0.04,0.01-0.14,0.56c0.03-0.03-0.04,0.11,0.14,0.05
				c-0.07-0.03,0.16,0.21-0.02,0.11l0.03,0.1c0.1-0.04,0.09-0.05,0.25,0.03c-0.02-0.02,0,0-0.12,0.07c0.05,0.07,0.15,0.15-0.18,0.08
				c0.15,0.04,0.03,0.13,0.19,0.18c-0.04-0.02-0.14-0.03-0.16-0.02l0.12,0.04c-0.19,0-0.09,0.09-0.06,0.07
				c-0.08-0.01,0.08,0.4-0.21,0.19c0.02,0.12,0.2,0.27,0.14,0.38c0.31-0.01,0.01,0,0,0.12c-0.02-0.02-0.14-0.04-0.11-0.03
				c-0.05,0.07,0.04,0.17,0.11,0.3c0-0.04-0.29,0.15-0.01,0.19c-0.19,0.06-0.46,0.19,0.07,0.59c-0.07-0.07-0.18-0.03-0.25-0.02
				c0.04,0,0.07,0.02,0.11,0.02c-0.3-0.02,0.2,0.1,0.02,0.1c0.03,0.04,0.1,0.18-0.09,0.04c0.01,0.15,0.02-0.03-0.21,0.2
				c0.43,0.08,0.02,0,0.29,0.24c-0.21-0.1,0-0.05-0.4-0.06c0.11,0.06-0.11,0.04,0.06,0.13c-0.05-0.01-0.01,0.04-0.13,0.01
				c0.17,0.07,0.27,0.05-0.12,0.22l0.13,0.04c-0.4-0.04-2.26,1.55-1.05,1.63l-0.25,0.19c0,0,0.01,0,0.01,0
				c-0.09,0.05-0.17,0.13-0.25,0.28l-0.07-0.13c0.15,0.31-0.22,0.12-0.21,0.26c-0.02,0-0.11,0-0.17-0.02l-0.03,0.16
				c-0.19-0.23-0.54-0.48,0.22,0.73l-0.01-0.15l0.07,0.09l-0.04,0.03c0.07,0.08,0.05-0.01,0.04-0.07c0.03,0.05,0.06,0.09,0.08,0.1
				l-0.04-0.22c0.05,0.08,0.05,0.17,0.11,0.2c-0.02-0.1-0.11-0.17-0.1-0.25c0.07,0.08,0.1,0.17,0.09,0.07
				c0.03,0.05,0.04,0.09,0.04,0.12c0-0.08,0.06-0.06,0.02-0.18c0.08,0.11,0.08-0.07,0.16,0.13c-0.01-0.05-0.02-0.18,0-0.2
				c0.06,0.26,0.1-0.03,0.2,0.24c-0.06-0.23,0.08-0.15,0.08-0.3c0,0.02,0.01,0.06,0,0.05c0.04,0,0.08,0.06,0.11,0.1
				c-0.07-0.18-0.03-0.24-0.05-0.37c0.11,0.16,0.06,0.22,0.14,0.39c-0.06-0.15,0.01-0.17-0.05-0.29c0.07,0.1,0.05,0.04,0.12,0.19
				c-0.08-0.23,0.01-0.05-0.01-0.2c0.09,0.1,0.09,0.21,0.12,0.12c0.15,0.31,0.01-0.06,0.25-0.33c0.07,0.21-0.04-0.34-0.01-0.12
				l0.01-0.06c0.08,0.16-0.01,0.07,0.03,0.19c0.02-0.05,0.15-0.3,0.12,0.02l-0.08-0.04c0.17,0.08,0.31,0.24,0.8-0.25
				c0.11,0.38-0.13,0.11,1.59-0.14l0.02-0.17l0.05,0.18c0.05-0.23,0.03,0.25,0.24-0.13c0,0.03-0.01,0.05-0.01,0.07
				c0.03-0.07,0.06,0.22,0.05-0.05c0.04,0.09,0.11,0.09,0.15,0.04c0.04,0.12-0.04,0.16,0.29,0.03c-0.04-0.05-0.05-0.23-0.02-0.25
				l0.03,0.16c0.01-0.1-0.04-0.06-0.01-0.18c0.04,0.02,0.04,0.18,0.02,0.24l0.04-0.06c0.01,0.05,0,0.07,0,0.14
				c0.01-0.1,0.09,0.16,0.1-0.02c-0.38,0.45,1.19-0.49,1.9,0.04c0-0.02,0-0.23,0.04-0.02c-0.3-0.4,0.46,0.23,1.23-0.03
				c-0.01,0.04-0.02,0.09-0.04,0.08c0.01,0.12,0.12,0.03,0.13-0.07c-0.03,0.03,0.04,0.26,0.18,0.06c0.05,0.07,0.12-0.04,0.15,0.05
				c0.06-0.15,0.08,0.09,0.13-0.12c-0.05,0.22,0.07,0.19,0.12,0.1c-0.03,0.06,0.02,0.08-0.02,0.17c0.04,0.01,0.09-0.03,0.1-0.14
				c0.02,0.28,0.2-0.18,0.17,0.17c0.15-0.09,0.28-0.14,0.4-0.17c0.01,0.22-0.09,0.12,0.4,0.17c-0.01,0.02-0.02,0.04-0.04,0.05
				c0.02,0,0.03,0.04,0.06-0.05l-0.03,0.02c0.01-0.15,0.16-0.28,0.18-0.35c-0.11,0.27,0.07,0.1-0.08,0.44
				c0.02-0.02,0.05-0.06,0.06-0.12c0.07,0.17-0.01,0.04,0.42,0.24c-0.02-0.03,0.07,0.06,0.08-0.13c-0.04,0.06,0.22-0.11,0.08,0.04
				l0.09,0c-0.03-0.03,0.08-0.41,0.12-0.11c0.08-0.03,0.16-0.11,0.02,0.19c0.07-0.14,0.12,0,0.2-0.15
				c-0.02,0.03-0.06,0.12-0.06,0.14l0.06-0.11c-0.18,0.26,0.36-0.07,0.14,0.31c0.11,0,0.28-0.14,0.35-0.05c0.09-0.3,0-0.01,0.1,0.03
				c-0.02,0.01-0.07,0.13-0.06,0.1c0.04,0.07,0.15,0,0.28-0.04c-0.03,0.07-0.08,0.07,0.07,0.18c0.04-0.06,0.06-0.04,0.09-0.13
				c-0.02,0.21,0.09,0.47,0.51,0.06c-0.08,0.05-0.08,0.17-0.1,0.23l0.05-0.1c-0.11,0.28,0.15-0.17,0.08,0.01
				c0.05-0.02,0.18-0.05,0.01,0.09c0.12,0.02-0.01-0.02,0.1,0.24c0.21-0.38,0.01-0.02,0.3-0.22c-0.15,0.17-0.03-0.02-0.18,0.36
				c0.09-0.09,0,0.11,0.13-0.03c-0.02,0.04,0.03,0.02-0.04,0.12c-0.25-0.2,0.61-0.19,0.51,0.19c0.05-0.02,0.13-0.08,0.19-0.09
				c-0.04,0.08-0.09,0.1-0.13,0.14c0.01-0.03,0.09,0.02,0.2-0.14c-0.06,0-0.2-0.07-0.03-0.26c0,0.03-0.17-0.02-0.03-0.21
				c-0.08-0.14-0.12-0.15-0.35,0.16l0.15-0.35l-0.09,0.13c-0.01-0.01-0.02-0.03,0.02-0.08c-0.08,0.07-0.03,0.06-0.1,0.13
				c0.03-0.15-0.15-0.01-0.03-0.22c-0.07,0.09,0,0.1-0.09,0.14c0.17-0.25-0.18,0.05,0.05-0.27c-0.03,0.03-0.04,0.04-0.06,0.06
				c0.02-0.03,0.04-0.09-0.08-0.09c0.04-0.15,0.17-0.06-0.16,0.02c0.06-0.05-0.05,0-0.06-0.26c-0.1,0.16-0.13,0.1-0.19,0.11
				c0.05-0.1,0.08-0.13,0.12-0.16c-0.05-0.01-0.03-0.02-0.13,0.06l0.07-0.12c-0.05-0.02-0.17,0.09-0.19-0.02
				c-0.06,0.11-0.11,0.34-0.21,0.39c0.04-0.1,0.09-0.09,0.14-0.5c-0.02,0.04-0.17,0.26-0.06-0.05c-0.04,0.06-0.04-0.02-0.11,0.11
				c-0.01-0.01,0.01-0.07,0.02-0.11c-0.05,0.05-0.01-0.02-0.17,0.13c-0.17,0.2-0.26-0.5-1.11-0.75l-0.03,0.05
				c0-0.07-0.07-0.09-0.03-0.17c-0.06,0.04-0.05,0.09-0.03,0.13c-0.03-0.1-0.09-0.05-0.14-0.11c0.09-0.03-0.33,0.21-0.12-0.15
				c-0.09,0.09-0.09-0.11-0.12-0.14l0.05-0.04c-0.03,0.02-0.04,0.02-0.06,0.01l-0.02,0.12c-0.01-0.03-0.03-0.03-0.01-0.08
				c-0.04,0.07-0.04,0.19-0.11,0.28c-0.01-0.07-0.09,0.04-0.06-0.11c0.06,0.07,0.04-0.15,0.11-0.16c-0.09-0.12,0.02-0.17-0.19,0.08
				c0.03-0.08,0.03-0.17,0.01-0.15c0.03,0.01-0.03,0.15-0.07,0.2c-0.03-0.08-0.07,0.03-0.09,0.02c0.01,0.07,0.03,0.07-0.08,0.12
				c0.02-0.07,0.04-0.04,0.04-0.07c-0.08,0.14-0.04-0.18-0.12-0.1c-0.01-0.07,0.04-0.16,0.03-0.21c-0.11,0.1-0.1-0.18-0.2,0.14
				l0.13-0.06c-0.05,0.27-0.37,0.18-0.45-0.13c-0.01,0.07-0.07-0.02-0.12,0.17c0.09-0.42-0.35,0.32-0.14-0.17
				c-0.03-0.01-0.04-0.03-0.06,0.04c0.04,0-0.15-0.03-0.21,0.26c-0.02-0.32-0.22-0.01-0.24-0.29c-0.06,0.02-0.13,0.04-0.18,0.04
				c0.09,0.15-0.17-0.36-0.61,0.06c0.04-0.12,0.04-0.06,0.04-0.19c-0.11,0.55-0.2,0.04-0.42-0.07c-0.04,0.04-0.13,0.18-0.19,0.21
				c0.05-0.14,0.12-0.21,0.17-0.29c-0.15,0.39-0.18-0.51-0.89,0.09c0.01-0.05,0.02-0.12,0.02-0.19c-0.01,0.06-0.05,0.1-0.03,0.18
				c-0.05-0.02-0.09-0.14-0.12-0.5c0.01,0.11-0.03,0.16-0.07,0.11c0.09,0.04-0.18,0.14-0.09-0.05c-0.05,0.05-0.19-0.01-0.23,0.24
				c0.03-0.33-0.23-0.14-0.74,0.16c-0.09-0.2-0.1-0.63-0.49-0.06c-0.1-0.23-0.27-0.08-0.41-0.2c0.02,0.04,0.01,0.17,0,0.19
				c-0.03-0.04-0.06,0.16-0.08-0.04l0.01,0c-0.04-0.19-0.1-0.14-0.15-0.14l0.02,0.26c-0.17-0.36-0.3,0.19-0.41-0.14
				c-0.02,0.07-0.06,0.07-0.07,0.14c-0.35-0.19-0.18-0.42-0.92,0.13l-0.01-0.04c-0.1,0.13-0.06-0.01-0.28,0.27
				c-0.01-0.02-0.02-0.03-0.03-0.05c0.1,0.06-0.22,0.05-0.12,0.04c0.06-0.03-0.44-0.3-0.34,0.11c-0.01-0.01,0.01-0.01,0.02-0.05
				l0,0.01c0.02-0.01,0.04-0.02,0.06-0.03c0,0.07-0.01,0.14-0.06,0.07c-0.01,0.32,0.15,0.21-0.49,0.3
				c-0.08-0.06-0.02-0.17-0.09-0.12c-0.22-0.33-0.02-0.06-0.39,0.18c0.02,0.02,0.03,0.03,0.04,0.05c-0.03,0-0.14-0.04-0.27-0.02
				c0.23-0.26,0.16-0.14,0.54-0.62c0.25,0.27-0.07,0.22,0.84-0.39c-0.12-0.1,0.02-0.06,1.02-0.72c-0.12,0.32,0.35-0.09,0.55-0.07
				c-0.19,0.21-0.44,0.47-0.43,0.6l0.18-0.08c-0.07-0.05-0.13-0.28,0.28-0.28c-0.04,0.05-0.08,0.1-0.12,0.14
				c0.33,0.1,0.52-0.53,0.81-0.76c0.07,0.14,0.09,0.3,0.84-0.41c-0.01,0.07,0.13,0.08-0.01,0.16c0.26-0.15,0.36-0.34,0.45-0.49
				c0,0.04-0.01,0.1-0.02,0.16c0.07-0.12,0.3-0.24,0.25-0.32c0.23-0.03,0.02-0.01,0.35,0.07c-0.26-0.18,1.04-0.65,0.66-0.14
				c-0.06-0.36,1.34-0.46,2.41-1.47c0.56,0.7-0.06,0.36,2.92-0.61l-0.08,0.22c1.32-0.44-0.36-0.21,7.36-1.37l-0.09,0.05
				c0.4,0.01,0.42-0.35,0.8-0.25l-0.07,0.03c0.67,0.2,0.26-0.6,4.82,0.27l-0.04,0.15c0.03-0.05,0.23-0.06,0.62,0.19
				c-0.01-0.25,0.06,0.23,1.2,0.58c-0.05,0.04-0.16-0.02-0.26-0.03c0.4,0.09-0.04,0.17,0.33,0.26c-0.02-0.06,0.08-0.06,0.1-0.08
				c0.08,0.18,0.38,0.44,0.6,0.64l-0.11,0.03c0.32,0.38,0.68,0.81,0.88,1.32c0.16,0.54,0.42,0.78-3.35,4.79
				c0.24-0.06,0.41-0.13,0.44-0.09c-0.13,0.09-0.54,0.21-0.68,0.21l0.02-0.02c-0.04,0.05-0.25,0.13-0.62,0.4
				c0.07-0.03,1.74,0.45,3.27,0.82l-0.04,0c1.51,2.16,0.18-2.77,6.07,7.22l-0.2-0.12c0.46,0.89,0.31,1.9,0.2,2.77
				c-0.17-0.39-0.18,1.08-1.88,2.6c-0.11-0.2-0.61,0.18-0.78,0.37c0.04-0.09-0.21,0.03-0.17-0.1c-0.23,0.11-0.43,0.24-0.34,0.33
				c-0.91,0-0.53,0.28-3.35,0.8c0.73-0.69,1.27,0.16-2.69-0.38c0.01-0.02,0.08-0.03,0.14-0.03c-0.34,0.02,0.06,0-0.74,0.25
				c0.28-0.22-0.55-0.26-0.12-0.51c-0.08-0.01-0.19,0.01-0.19,0.08c-0.1-0.06-0.12-0.11,0.01-0.14c-0.41-0.1-0.31,0.06-0.67-0.09
				c0.02-0.04,0.1-0.03,0.16-0.07c-0.22,0.02-0.44,0.02-0.71-0.17c0.03-0.02,0.02-0.07,0.1-0.03c-0.5-0.24-0.97-0.24-1.44-0.67
				c0.15-0.03,0.24-0.16-0.35-0.5l0.05,0.15c-0.23-0.18-0.52-0.23-0.68-0.44l0.22,0.08l-0.45-0.32l0.2,0.25
				c-0.88-0.3-0.1,0.16-1.74-1.37c0.25,0.02-0.06-0.47-0.4-0.42c0.04,0.02,0.03-0.01,0.11,0.03c-0.41-0.35-0.55-0.54-0.64-0.69
				l0.12,0.08c-0.45-0.49,0.06,0.02-1.84-1.55l0.08,0.09c-0.24,0.14-0.28-0.41-0.61-0.52c0.1-0.29,0.12-0.02-1.18-0.9l0.11,0
				c-1.01-0.65-0.69-0.71-2.86-2.08c0.2,0.05,0.31,0.1-0.43-0.27c-0.02,0.13,0.03,0.11,0.1,0.26c-0.11,0.01-0.29-0.04-0.42-0.13
				c0.29,0.09,0.1-0.16,0.03-0.28c0,0.04-0.06,0.05-0.07,0.09c-0.55-0.46-0.13,0.11-1.39-0.76c0.12,0.02,0.26,0.1,0.37,0.13
				c-0.14-0.27-0.19-0.25-0.35-0.37l0.01,0.01l-0.35-0.29l0.08,0.09c-0.46,0.13-0.62-0.27-1.12-0.29c0.22,0.06,0.47-0.28-0.54-0.65
				c0.11-0.04-1.6-0.58-3.6-1.5c0.17-0.1,0.38-0.23-1-0.39l0.15,0.11c0.01-0.08,0.19-0.26,0.36,0.1l-0.17-0.04
				c0.06,0.33,0.66,0.22,0.98,0.37c-0.09,0.13-0.24,0.2,0.67,0.57c-0.06,0.02-0.02,0.15-0.15,0.06c0.35,0.29,0.7,0.15,0.79,0.36
				l-0.04,0c0.61,0.32,1.7,0.9,3.82,1.95c-0.05,0.04,0.58,0.46,0.85,0.71l-0.03,0.02c0.16-0.12,0.46,0.1,0.7,0.22
				c-0.07,0.09-0.13,0.1-0.28,0.06c0.04-0.01-0.01,0.09,0.53,0.21l-0.13,0.08c0.13,0.03,0.39,0.41,1.32,0.49l-0.22-0.01
				c0.09,0.01,0.51,0.33,0.1,0.14c0.59,0.54,0.25,0.22,1.63,1.12l0,0c0.55,0.23,0,0,1.37,1.11c-0.16,0.14,0.43,0.35,1.38,1.25
				c-0.25-0.11,0.02,0.12-0.24-0.13c0.22,0.26,0.23,0.36,0.59,0.51c-0.18,0.01,0.08,0.29,0.22,0.37l-0.11-0.23
				c0.15,0.11,0.25,0.23,0.36,0.34l-0.18,0.06c0.5,0.56,1.12,1.11,2.47,2.05c-0.01-0.51,0.39,0.84,0.48,0.47
				c0.28,0.33,0.52,0.4,0.84,0.7c-0.03-0.11,1.32,0.29,0.75,0.61c0.4,0.11,0.62,0.44,0.83,0.56l-0.22-0.05
				c0.13,0.03,0.22,0.08,0.32,0.13l-0.06-0.16c0.1,0.07,0.2,0.12,0.18,0.16c0.3-0.07,0.14-0.37,0.75,0.09
				c-0.2-0.23-0.37,0.54,0.57,0.26c-0.09,0.07,0.07,0.2,0.15,0.2c-0.16-0.05,0.06-0.2,0.67-0.09l-0.18-0.08
				c0.41-0.2,0.81,0.39,2.23,0.59c-0.12-0.12-0.14-0.1,0.16-0.2c-0.23,0.02-0.46,0.04-0.7,0.04c0.15-0.05,0.3-0.09,0.45-0.14
				c-0.21-0.01-0.44,0.04-0.56,0.01c0.22-0.08,0.19-0.05,0.1-0.16c1.03,0.07,0.45,0.67,2.39,0.19l-0.21-0.03
				c1.03-0.22,0.27,0.22,2.57-0.81c-0.1,0.15,0.14,0.14-0.15,0.33c0.55-0.46-0.21,0.15,3.37-2.78c0.01,0.01,0.01,0.02,0.02,0.03
				c0.38-0.98,0.17,0.09,0.76-1.49l-0.01,0.09C226.45,356.44,226.29,355.99,226.33,355.55z M205.6,337.29
				c0.05-0.08,0.04-0.12,0.2-0.08C205.76,337.24,205.79,337.19,205.6,337.29z M177.06,338.82c-0.07,0.06-0.07,0.1-0.21,0.01
				C176.89,338.82,176.85,338.86,177.06,338.82z M190.6,341.55C190.6,341.55,190.6,341.55,190.6,341.55
				C190.6,341.55,190.6,341.55,190.6,341.55z M175.81,337.9c0.04-0.03-0.11-0.07-0.21-0.11
				C175.69,337.78,175.98,337.76,175.81,337.9z M176.3,337.84c0.07,0,0,0,0.18,0.01C176.44,337.84,176.39,337.84,176.3,337.84z
				 M190.05,342.65c-0.17,0.01-0.1-0.52-1.49-1.13c0.44-0.01-0.56-0.42-0.32-0.6c-0.34,0.05-0.83-0.42-1.29-0.51
				c0.03,0,0.09-0.01,0.12,0.02c-0.19-0.13-0.23-0.23-0.34-0.33c0,0.1-1.31,0.05-0.49-0.32c-0.05,0.14-0.55-0.08-0.51,0.08
				c-0.16-0.2-0.16-0.13-0.21-0.33c0.07,0.3-1.28-0.3-0.62-0.39c-0.87,0.17-0.05-0.16-2.59-0.47c-0.02,0.18,0.05,0.35-0.37,0.17
				c-0.13-0.88,0.26-0.31-2.99-0.74l0.16-0.17c-1.41,0.05,0.25,0-7.65-0.68l0.11-0.03c-0.4-0.1-0.49,0.24-0.85,0.06l0.08-0.02
				c-0.07-0.33-0.06,0.14-5.44-1.52c-0.01,0.28-0.06-0.3-1.31-0.58c0.05-0.05,0.17,0,0.28,0.01c-0.44-0.06,0.01-0.17-0.38-0.24
				c0.03,0.06-0.07,0.06-0.09,0.09c-0.12-0.17-0.47-0.41-0.75-0.56l0.11-0.05c-0.41-0.34-0.91-0.68-1.29-1.13
				c-0.66-0.83-0.04,0.06-1.24-3.78c0.12,0.03,0.32-0.27,0.28-0.06c0.49-0.38,0.35-1.14,3.34-1.08l-0.04,0.01
				c0.76-0.12,2.81,0.33,5.16-0.46c-1.53-1.3-3.82-2.02-4.59-5.54c0.05,0.05,0.11,0.11,0.16,0.16c0.12-0.36-0.42-0.7,1.12-2.14
				c-0.11,0.4,0.92-0.58,3.27-0.74c-0.03,0.22,0.63,0.22,0.88,0.15c-0.09,0.06,0.2,0.1,0.09,0.18c0.27,0.04,0.52,0.04,0.49-0.08
				c0.84,0.42,0.61,0.14,3.51,0.82c-0.09,0.05-0.18,0.07-0.26,0.07c0.53,0.23,0.61,0.17,2.78,1.18c-0.01,0.02-0.08,0-0.14-0.01
				c0.35,0.07-0.06,0,0.79-0.05c-0.33,0.14,0.49,0.39,0.02,0.53c0.08,0.03,0.19,0.04,0.21-0.03c0.09,0.08,0.1,0.13-0.04,0.14
				c0.4,0.19,0.33,0.01,0.66,0.23c-0.03,0.04-0.11,0.01-0.17,0.04c0.23,0.02,0.44,0.07,0.7,0.29c-0.03,0.01-0.02,0.07-0.11,0.01
				c0.49,0.31,0.95,0.38,1.4,0.84c-0.15,0.02-0.25,0.14,0.33,0.54l-0.05-0.16c0.23,0.2,0.52,0.26,0.67,0.48l-0.22-0.09
				c0.15,0.11,0.29,0.23,0.44,0.35l-0.19-0.27c0.29,0.14,0.13-0.2,0.4,0.11c0.34,0.5,0.06,0.2,1.29,1.37
				c-0.25-0.04,0.03,0.48,0.37,0.45c-0.03-0.02-0.04,0-0.1-0.04c0.38,0.4,0.49,0.59,0.58,0.75l-0.11-0.1
				c0.39,0.54-0.05-0.04,1.54,1.84l-0.05-0.11c0.27-0.08,0.18,0.46,0.46,0.66c-0.18,0.25-0.11-0.02,0.84,1.23l-0.1-0.03
				c0.69,0.99,0.44,0.87,1.73,3.08c-0.15-0.14-0.22-0.24,0.23,0.45c0.08-0.1,0.03-0.11,0.04-0.27c0.1,0.04,0.23,0.19,0.3,0.33
				c-0.2-0.23-0.16,0.09-0.17,0.23c0.02-0.03,0.08-0.01,0.1-0.04c0.23,0.69,0.18-0.03,0.76,1.41c-0.08-0.09-0.15-0.23-0.23-0.32
				c-0.03,0.3,0.02,0.31,0.08,0.5l0-0.02l0.12,0.44l-0.01-0.12c0.45,0.16,0.34,0.59,0.72,0.91c-0.26-0.38,0.17,0.88,0.6,3.32
				l0.11-0.09c-0.18,0.06,0.33,0.58-0.49,0.91c0.09-0.06,0.14-0.01,0.41-0.33c-0.05,0.02-0.3,0.08-0.02-0.09
				c-0.07,0.01-0.03-0.06-0.16,0.01c-0.01-0.02,0.05-0.05,0.08-0.08c-0.08,0.01,0-0.02-0.23-0.03l0,0
				c-0.23,0.21,0.05-0.34-0.29-0.81c0.09,0.05,0.09-0.25,0.01-0.05c0.03-0.48-0.67-0.41-0.29-0.74l-0.05,0.02
				c0.03-0.06-0.02-0.14,0.08-0.17c-0.07-0.01-0.09,0.03-0.1,0.08c0.03-0.12-0.07-0.11-0.07-0.22c-0.1,0.1-0.48-0.05-0.02-0.22
				c-0.14,0-0.02-0.17-0.03-0.22l0.06,0.01c-0.04-0.01-0.05-0.02-0.06-0.04l-0.08,0.09c0-0.03-0.01-0.05,0.03-0.08
				c-0.08,0.02-0.15,0.13-0.27,0.15c0.03-0.06-0.11-0.05,0.01-0.14c0.01,0.12,0.12-0.1,0.19-0.04c-0.02-0.17,0.12-0.13-0.22-0.09
				c0.08-0.04,0.12-0.12,0.1-0.12c0.02,0.04-0.11,0.1-0.19,0.11c0.02-0.09-0.08-0.03-0.09-0.06c-0.03,0.07-0.01,0.08-0.14,0.04
				c0.05-0.04,0.06,0.01,0.08-0.02c-0.16,0.05,0.07-0.19-0.06-0.18c0.03-0.06,0.13-0.1,0.15-0.15c-0.18,0.07,0.09-0.25-0.27-0.05
				l0.15,0.06c-0.2,0.16-0.43-0.1-0.34-0.49c-0.06,0.05-0.05-0.08-0.21,0.03c0.34-0.27-0.51-0.03-0.03-0.26
				c-0.13-0.03,0.03,0-0.44,0.02c0.17-0.27-0.2-0.2-0.06-0.44c-0.43-0.17-0.18,0.06-0.83-0.58c0.11-0.07,0.07-0.02,0.16-0.12
				c-0.44,0.35-0.21-0.12-0.36-0.41c-0.06,0-0.24,0.03-0.31,0.01c0.13-0.08,0.24-0.07,0.34-0.09c-0.03-0.03-0.11-0.09-0.19-0.04
				c0.05,0.01-0.23,0.25-0.17-0.44c-0.2,0.01-0.17-0.24-0.32-0.34c-0.06,0.12-0.41,0.25-0.07-0.06c-0.03,0.1-0.12,0.09-0.17,0.15
				c0-0.09,0.04-0.23,0.18-0.45c-0.06,0.09-0.13,0.1-0.14,0.03c0.05,0.11-0.25-0.03-0.06-0.12c-0.08,0-0.18-0.15-0.39,0
				c0.45-0.39-0.46-0.13-1.73-1.34l0.01,0.01c0.09-0.18-0.01-0.18-0.06-0.21l-0.15,0.22c0.08-0.4-0.42-0.1-0.31-0.43
				c-0.07,0.04-0.11,0.01-0.17,0.05c0.03-0.11,0.15-0.14-0.16-0.29c0.3-0.33-0.29-0.17-0.7-0.21c0.09-0.17-0.11-0.08-0.2-0.04
				c0.09-0.18,0.2-0.11-0.4-0.2c-0.06,0.08-0.17,0.01-0.28-0.14c0.08,0.04,0.14,0.08,0.13,0.08c0.11-0.02-0.2-0.53-0.41-0.18
				c-0.01-0.01,0.02,0,0.06-0.01l-0.01,0.01c0.02,0.01,0.05,0.02,0.08,0.03c-0.06,0.05-0.11,0.09-0.11,0.01
				c-0.06,0.07-0.11,0.15-0.14,0.18c-0.07-0.06,0.05-0.49-0.56-0.34c-0.01-0.54,0.1-0.2-0.52-0.32c0.01,0.03,0.01,0.04,0,0.07
				c-0.09-0.05-0.2-0.4-0.87-0.27l0.03-0.15c0.1,0.46-0.36-0.39-0.98,0.03c0.01,0,0.01,0,0.01-0.01c-0.03,0.14-0.07,0.33-0.12,0.63
				l0.11-0.11l-0.01,0.12l-0.06-0.01c0,0.11,0.05,0.03,0.1-0.02c-0.01,0.06-0.01,0.1-0.01,0.13l0.14-0.19
				c-0.02,0.09-0.08,0.16-0.05,0.23c0.06-0.09,0.03-0.21,0.1-0.25c0,0.11-0.03,0.19,0.03,0.12c-0.01,0.06-0.03,0.09-0.06,0.11
				c0.06-0.06,0.1,0,0.16-0.11c0,0.14,0.13,0.01,0.06,0.22c0.03-0.05,0.12-0.14,0.15-0.14c-0.14,0.23,0.12,0.05,0.01,0.32
				c0.12-0.21,0.19-0.05,0.32-0.15c-0.01,0.02-0.03,0.05-0.04,0.04c0.04,0.03,0.03,0.1,0.04,0.16c0.07-0.18,0.15-0.2,0.23-0.3
				c-0.01,0.2-0.11,0.2-0.16,0.38c0.05-0.15,0.15-0.11,0.17-0.24c0,0.13,0.02,0.07-0.02,0.23c0.09-0.23,0.05-0.02,0.14-0.15
				c0.02,0.14-0.07,0.22,0.02,0.18c-0.09,0.33,0.06-0.03,0.5-0.04c0,0.02-0.01,0.03-0.01,0.05c0.02,0.01,0.02-0.27,0.15-0.21
				c-0.02-0.01-0.04,0.03-0.06,0.07l0.05-0.04c-0.08,0.24-0.2,0.09-0.01,0.26l-0.05-0.09c0.1,0.23,0.12,0.38,1,0.44
				c-0.03,0.04-0.07,0.08-0.07,0.14c0.02-0.2,0.6,0.04,1.39,0.73c0.07-0.11,0.42-0.05,0.4,0.29l0.14-0.1l-0.07,0.17
				c0.2-0.14-0.15,0.2,0.33,0.09c-0.01,0.02-0.03,0.03-0.04,0.04c0.03,0.02-0.08,0.19,0.07,0c-0.02,0.1,0.06,0.14,0.13,0.15
				c-0.06,0.15-0.12,0.08,0.27,0.26c-0.01-0.07,0.1-0.21,0.15-0.2l-0.08,0.14c0.07-0.07,0.23-0.27-0.02,0.05l0.08-0.01
				c-0.03,0.04-0.05,0.05-0.09,0.1c0.08-0.07-0.01,0.19,0.12,0.07c-0.63,0.06,1.42,0.54,1.88,1.6c0.22-0.31,0.22,0.46,1.29,1.04
				c-0.04,0.02-0.07,0.05-0.09,0.03c-0.06,0.11,0.1,0.12,0.17,0.06c-0.05,0.01-0.12,0.23,0.14,0.2c0,0.1,0.14,0.07,0.12,0.17
				c0.14-0.07,0.03,0.15,0.2,0.01c-0.18,0.14-0.05,0.22,0.06,0.18c-0.06,0.03-0.03,0.08-0.11,0.12c0.04,0.04,0.1,0.05,0.18-0.03
				c-0.15,0.25,0.29,0.02,0.06,0.29c0.19,0.05,0.35,0.12,0.48,0.21c-0.13,0.19-0.14,0.05,0.28,0.49c-0.02,0.01-0.04,0.01-0.06,0.01
				c0.02,0.02,0,0.07,0.09,0l-0.04,0c0.1-0.12,0.31-0.1,0.38-0.14c-0.26,0.13,0.01,0.14-0.33,0.3c0.02,0,0.08-0.01,0.13-0.05
				c-0.04,0.22-0.02,0.03,0.25,0.57c0-0.04,0.04,0.11,0.15-0.05c-0.07,0.02,0.26,0.08,0.06,0.1l0.09,0.08
				c0.05-0.09,0.03-0.1,0.22-0.13c-0.06,0.13,0.03,0.13-0.14,0.32c0.15-0.06,0.11,0.1,0.27,0.04c-0.04,0.01-0.12,0.06-0.14,0.07
				l0.12-0.04c-0.15,0.11-0.03,0.14,0,0.1c-0.07,0.04,0.32,0.3-0.04,0.29c0.09,0.1,0.34,0.12,0.35,0.26
				c0.25-0.19,0.01-0.01,0.07,0.11c-0.02,0-0.14,0.05-0.11,0.04c0,0.1,0.14,0.13,0.27,0.21c-0.03-0.04-0.15,0.3,0.11,0.17
				c-0.12,0.17-0.25,0.44,0.42,0.49c-0.09-0.02-0.17,0.08-0.22,0.13c0.03-0.02,0.07-0.03,0.1-0.04c-0.26,0.16,0.22-0.03,0.07,0.08
				c0,0,0.17,0.11-0.05,0.09c0.1,0.13,0-0.04-0.05,0.3c0.05-0.02,0.09-0.04,0.12-0.05c-0.04,0.04,0.06,0.07-0.06,0.21
				c0.02-0.01,0.02-0.01,0.04-0.01c-0.01,0.05,0.01,0.02-0.09,0.09c0.06-0.03,0.15,0.12,0.02,0.17c0.03-0.01,0.04-0.02,0.06-0.02
				c0,0.14,0.01,0.26,0.03,0.37c-0.05-0.03-0.1-0.05-0.16-0.08C190.6,342.89,189.63,342.91,190.05,342.65z M181.18,331.92
				c0.01-0.02,0.03-0.04,0.04-0.06C181.24,331.85,181.26,331.9,181.18,331.92z M181.07,332.87c-0.03-0.03-0.08-0.03,0-0.11
				C181.05,332.78,181.07,332.82,181.07,332.87z M180.94,332.81c-0.02,0,0,0-0.04,0C180.91,332.8,180.92,332.81,180.94,332.81z
				 M190.82,343.72c-0.01-0.04-0.02-0.07-0.03-0.1c0.05,0.02,0.01,0,0.08,0.09C190.86,343.71,190.84,343.71,190.82,343.72z
				 M190.82,343.55c-0.01-0.01-0.01-0.02-0.02-0.03c0.02,0.01,0.04,0.01,0.06,0.02C190.84,343.55,190.83,343.55,190.82,343.55z
				 M191.45,341.48c0,0,0-0.01,0-0.01C191.45,341.47,191.45,341.48,191.45,341.48z M187.57,326.79c-0.02-0.03-0.04-0.08-0.08-0.17
				C187.53,326.69,187.49,326.62,187.57,326.79z M187.19,326.19c0.05,0.03,0-0.15,0-0.27C187.12,325.87,187.45,326.31,187.19,326.19
				z M186.89,327.61c0.04,0.04-0.02,0.02,0.12,0.19C186.91,327.76,186.88,327.78,186.89,327.61z M193.91,328.65
				c0.02,0-0.01,0.05-0.07,0.01C193.87,328.66,193.9,328.65,193.91,328.65z M193.08,329.22c0-0.05-0.03-0.07,0.08-0.07
				C193.13,329.15,193.11,329.19,193.08,329.22z M194.62,343.06c0,0.01,0,0.02,0,0.03C194.62,343.08,194.61,343.08,194.62,343.06
				C194.61,343.07,194.61,343.07,194.62,343.06z M194.52,343.1c-0.01,0.03-0.03,0.07-0.04,0.09
				C194.5,343.21,194.42,343.12,194.52,343.1z M194.46,343.14c-0.01,0.09,0,0-0.05,0.14C194.4,343.11,194.22,343.44,194.46,343.14z
				 M194.29,343.33c0,0.07,0,0.14-0.08,0.04C194.24,343.38,194.27,343.36,194.29,343.33z M194.16,344.09c-0.01,0-0.03,0-0.05,0.01
				C194.11,344.06,194.06,343.94,194.16,344.09z M193.69,343.29c0-0.03-0.01-0.05-0.01-0.07
				C193.69,343.19,193.72,343.23,193.69,343.29z M195.54,342.06c0.01,0.01,0.03,0.02,0.03,0.04c0.03-0.09,0.11-0.17,0.2-0.25
				C195.65,342.06,195.51,342.25,195.54,342.06z M195.92,341.75c0.04-0.03,0.08-0.06,0.13-0.08c-0.03,0.05-0.08,0.12-0.13,0.22
				C195.92,341.86,195.91,341.81,195.92,341.75z M196.31,341.58c-0.03-0.01-0.05-0.01-0.08-0.02c0.08-0.05,0.15-0.1,0.22-0.15
				C196.4,341.47,196.36,341.52,196.31,341.58z M206.43,338.06c0.03-0.01,0.08-0.02,0.17-0.04
				C206.53,338.04,206.6,338.02,206.43,338.06z M207.05,337.83c-0.03,0.04,0.11,0.04,0.22,0.05
				C207.18,337.91,206.92,338.01,207.05,337.83z"
                />
                <path
                  className="st10"
                  d="M187.98,344.9c-0.04,0.04-0.09,0.38-0.09-0.12l-0.04,0.06c0.01-0.04,0.01-0.15,0.03-0.11
				c-0.12-0.09-0.15-0.08-0.24,0.29l0.01-0.38l-0.03,0.15c-0.01-0.01-0.02-0.02-0.01-0.08c-0.05,0.1,0,0.07-0.04,0.16
				c-0.02-0.14-0.13,0.05-0.1-0.19c-0.03,0.11,0.03,0.09-0.03,0.17c0.06-0.29-0.14,0.11-0.05-0.27c-0.02,0.04-0.03,0.07-0.03,0.08
				c0.01-0.03,0.01-0.1-0.09-0.06c-0.02-0.15,0.13-0.13-0.13,0.08c0.03-0.07-0.05,0.01-0.13-0.21c-0.03,0.18-0.08,0.14-0.13,0.17
				c0.01-0.11,0.03-0.15,0.05-0.2c-0.05,0.02-0.03,0-0.09,0.1l0.03-0.14c-0.05,0-0.12,0.15-0.17,0.05
				c-0.02,0.13,0.01,0.36-0.07,0.44c0-0.11,0.05-0.06-0.02-0.52c-0.01,0.04-0.07,0.3-0.06-0.03c-0.02,0.07-0.04-0.01-0.06,0.14
				c-0.01,0-0.01-0.07-0.01-0.11c-0.03,0.07-0.01-0.01-0.11,0.18h0c-0.09,0.23-0.37-0.38-1.15-0.38l-0.01,0.06
				c-0.02-0.06-0.08-0.06-0.06-0.16c-0.04,0.05-0.02,0.1,0,0.13c-0.05-0.09-0.09-0.01-0.15-0.07c-0.01,0.11-0.01,0.01-0.11,0.24
				c-0.03-0.09,0.02-0.21-0.04-0.14c-0.03-0.06,0-0.16,0.02-0.21c0,0.12-0.14-0.04-0.1-0.15c-0.02,0.03-0.03,0.03-0.05,0.02
				l0.01,0.12c-0.02-0.02-0.03-0.02-0.03-0.07c-0.02,0.08,0,0.2-0.04,0.3c-0.02-0.06-0.07,0.06-0.07-0.1c0.07,0.06,0-0.16,0.06-0.18
				c-0.11-0.1-0.02-0.16-0.15,0.12c0.01-0.09-0.01-0.17-0.02-0.15c0.03,0,0.01,0.15-0.02,0.22c-0.04-0.07-0.05,0.05-0.08,0.04
				c0.03,0.07,0.04,0.05-0.04,0.14c0-0.07,0.03-0.05,0.02-0.08c-0.04,0.15-0.08-0.16-0.13-0.06c-0.02-0.06,0-0.17-0.02-0.21
				c-0.08,0.13-0.12-0.16-0.15,0.18l0.1-0.08c0.01,0.29-0.31,0.25-0.42-0.03c0,0.07-0.07-0.01-0.07,0.19c0-0.43-0.24,0.4-0.16-0.13
				c-0.02,0-0.05-0.02-0.04,0.05c0.04-0.01-0.14,0.01-0.14,0.31c-0.08-0.31-0.2,0.03-0.27-0.23c-0.05,0.04-0.11,0.07-0.16,0.08
				c0.1,0.13-0.24-0.31-0.53,0.19c0.01-0.13,0.02-0.07,0-0.2c-0.02,0.02,0.03,0.21-0.02,0.25c0.01-0.05-0.13-0.32-0.13-0.37
				c-0.1,0.01-0.15,0.54-0.24,0.13c-0.02,0.05-0.08,0.2-0.13,0.25c0.01-0.15,0.06-0.23,0.09-0.32c-0.04,0.42-0.3-0.46-0.78,0.28
				c-0.01-0.05-0.01-0.12-0.03-0.19c0,0.06-0.02,0.11,0.02,0.19c-0.05-0.01-0.11-0.12-0.22-0.46c0.03,0.11,0.01,0.16-0.03,0.13
				c0.09,0.01-0.13,0.18-0.1-0.03c-0.03,0.07-0.17,0.04-0.16,0.28c0.04-0.18-0.36-0.15-0.63,0.32c-0.13-0.16-0.25-0.59-0.45,0.06
				c-0.15-0.2-0.26-0.01-0.42-0.09c0.03,0.03,0.06,0.16,0.05,0.19c-0.04-0.03-0.01,0.17-0.08-0.02l0.01,0
				c-0.09-0.18-0.13-0.11-0.17-0.09l0.1,0.25c-0.26-0.31-0.21,0.25-0.41-0.04c0,0.07-0.03,0.09-0.02,0.16
				c-0.05-0.04-0.04-0.23-0.26-0.05c-0.13-0.46-0.24,0.14-0.39,0.4c-0.1-0.16-0.09,0.03-0.09,0.13l-0.07-0.12
				c0.06,0.17,0.08,0.21,0.06,0.29c-0.02-0.01-0.07-0.09-0.04-0.07c-0.11-0.11,0.06,0-0.23,0.09c0.06,0.09-0.05,0.15-0.2,0.13
				c0.05-0.04,0.1-0.06,0.1-0.05c0.04-0.04-0.52-0.13-0.26,0.21c-0.01,0,0.01-0.01,0-0.05l0.01,0.01c0.01-0.01,0.03-0.03,0.05-0.05
				c0.03,0.07,0.05,0.13-0.02,0.08c0.11,0.29,0.22,0.14-0.3,0.44c-0.1-0.03-0.09-0.15-0.13-0.08c-0.34-0.22-0.04-0.05-0.27,0.3
				c0.02,0.01,0.04,0.01,0.06,0.03c-0.06,0.01-0.44,0.04-0.41,0.53l-0.13-0.08c0.28,0.2-0.13,0.2-0.05,0.31
				c-0.02,0.01-0.09,0.05-0.14,0.06l0.06,0.15c-0.28-0.1-0.7-0.15,0.57,0.5c0-0.02-0.09-0.15,0.1-0.06l-0.16-0.16
				c0.08,0.05,0.14,0.12,0.2,0.12c-0.07-0.08-0.18-0.1-0.22-0.17c0.1,0.04,0.17,0.09,0.11,0.02c0.05,0.03,0.08,0.06,0.1,0.08
				c-0.05-0.06,0.01-0.07-0.08-0.15c0.12,0.05,0.02-0.09,0.2,0.04c-0.04-0.04-0.11-0.14-0.1-0.16c0.18,0.19,0.07-0.06,0.28,0.12
				c-0.17-0.17-0.02-0.15-0.08-0.28c0.01,0.01,0.04,0.04,0.03,0.04c0.03-0.01,0.1,0.03,0.14,0.04c-0.15-0.13-0.15-0.2-0.22-0.3
				c0.17,0.1,0.16,0.17,0.3,0.28c-0.12-0.1-0.08-0.15-0.18-0.23c0.11,0.06,0.06,0.02,0.19,0.12c-0.18-0.17-0.01-0.05-0.11-0.17
				c0.12,0.05,0.18,0.15,0.15,0.06c0.27,0.22-0.03-0.05,0.06-0.37c0.15,0.16-0.18-0.29-0.06-0.11l-0.02-0.05
				c0.13,0.11,0.02,0.07,0.11,0.16c-0.01-0.06,0-0.31,0.11-0.02l-0.08-0.01c0.19,0.01,0.34,0.11,0.57-0.47
				c0.03,0.04,0.05,0.09,0.09,0.13c-0.16-0.09,0.43-0.38,1.21-0.68l-0.03-0.16l0.1,0.16c0.02-0.09-0.18-0.3,0.12-0.02l0.05-0.16
				c0.01,0.03,0.01,0.05,0.01,0.07c0-0.07,0.12,0.19,0.03-0.06c0.06,0.08,0.12,0.05,0.14,0c0.06,0.09,0.01,0.17,0.25-0.04
				c-0.05-0.04-0.11-0.21-0.09-0.24l0.07,0.15c-0.02-0.1-0.05-0.05-0.06-0.18c0.04,0.01,0.09,0.17,0.09,0.22l0.02-0.07
				c0.02,0.04,0.02,0.07,0.04,0.13c-0.02-0.1,0.12,0.13,0.08-0.04c-0.22,0.52,0.93-0.74,1.65-0.37c0-0.02-0.05-0.22,0.03-0.02
				c-0.35-0.33,0.45,0.14,1.09-0.28c-0.01,0.04,0,0.09-0.02,0.09c0.04,0.12,0.11,0,0.1-0.1c-0.02,0.03,0.08,0.25,0.17,0.02
				c0.06,0.06,0.1-0.07,0.14,0.01c0.02-0.16,0.09,0.07,0.1-0.15c0,0.23,0.1,0.18,0.13,0.07c-0.01,0.07,0.03,0.07,0.02,0.17
				c0.04,0,0.07-0.05,0.06-0.16c0.07,0.27,0.14-0.22,0.18,0.13c0.11-0.12,0.22-0.2,0.33-0.25c0.06,0.2-0.06,0.15,0.4,0.07
				c0,0.02-0.01,0.04-0.02,0.06c0.02-0.01,0.04,0.03,0.04-0.07l-0.02,0.03c-0.02-0.15,0.08-0.31,0.09-0.39
				c-0.04,0.29,0.08,0.08,0.02,0.45c0.01-0.02,0.03-0.07,0.03-0.14c0.1,0.15-0.01,0.04,0.44,0.14c-0.03-0.03,0.08,0.04,0.04-0.14
				c-0.02,0.07,0.17-0.17,0.08,0.01l0.08-0.03c-0.04-0.02-0.03-0.41,0.08-0.14c0.06-0.06,0.11-0.15,0.06,0.17
				c0.03-0.15,0.1-0.04,0.14-0.2c-0.01,0.04-0.02,0.14-0.02,0.16l0.03-0.12c-0.1,0.29,0.31-0.16,0.2,0.26
				c0.1-0.03,0.21-0.21,0.3-0.15c-0.01-0.31,0-0.01,0.1,0c-0.01,0.02-0.03,0.14-0.02,0.12c0.06,0.05,0.13-0.05,0.24-0.12
				c0,0.07-0.05,0.09,0.11,0.15c0.02-0.07,0.04-0.05,0.04-0.15c0.04,0.22,0.26,0.41,0.46-0.12c-0.05,0.07-0.02,0.18-0.01,0.25
				l0.02-0.11c-0.01,0.3,0.08-0.21,0.07-0.02c0.04-0.04,0.14-0.11,0.04,0.08c0.11-0.03-0.02-0.01,0.16,0.19
				c0.06-0.44,0-0.02,0.19-0.31c-0.07,0.22-0.04-0.01-0.04,0.41c0.05-0.12,0.04,0.1,0.1-0.07c0,0.05,0.03,0.01,0.01,0.13
				c-0.29-0.06,0.47-0.43,0.51-0.04c0.04-0.04,0.09-0.12,0.13-0.16c0,0.09-0.04,0.12-0.06,0.18c0-0.03,0.08-0.02,0.12-0.21
				C188.05,345.14,187.91,345.13,187.98,344.9z M177.15,345.88c0-0.03,0.04,0,0.04,0.06C177.17,345.92,177.16,345.9,177.15,345.88z
				 M177.86,346.56c-0.02-0.03-0.11-0.13,0.04-0.02C177.89,346.54,177.88,346.55,177.86,346.56z"
                />
                <path
                  className="st10"
                  d="M193.59,355.28c0.1-0.04,0.21-0.06-0.45-0.89c0.08,0.08,0.15,0.21,0.23,0.29c0.03-0.29-0.02-0.29-0.08-0.47
				l0,0.02l-0.12-0.41l0.01,0.11c-0.45-0.13-0.32-0.54-0.69-0.84c0.14,0.17,0.51,0.07,0.04-0.8c0.12,0.17-0.77-1.49-1.11-3.46
				c0.18,0.07,0.37,0.21-0.15-1l-0.03,0.17c0.07-0.03,0.31,0.03,0.09,0.34l-0.05-0.16c-0.26,0.21,0.14,0.65,0.19,0.97
				c-0.14,0-0.32-0.08-0.08,0.83c-0.05-0.03-0.13,0.07-0.14-0.08c-0.02,0.44,0.31,0.62,0.2,0.81l-0.02-0.03
				c0.19,0.6,0.42,1.93,1.52,3.85c-0.07,0.01,0.23,0.67,0.35,1.01l-0.03,0.01c0.19-0.03,0.34,0.3,0.51,0.5
				c-0.1,0.05-0.16,0.03-0.28-0.07c0.04,0.01-0.05,0.08,0.38,0.41l-0.15,0.02c0.22,0.03-0.01,0.6,0.99,0.93l-0.19-0.08
				c0.08,0.04,0.33,0.48,0.03,0.16c0.35,0.7,0.15,0.3,1.12,1.53l0,0c0.44,0.37,0,0,0.95,1.4c-0.19,0.09,0.3,0.43,0.94,1.5
				c-0.21-0.16-0.02,0.11-0.19-0.18c0.14,0.29,0.11,0.4,0.4,0.62c-0.17-0.03-0.01,0.29,0.1,0.4l-0.04-0.24
				c0.1,0.14,0.17,0.27,0.23,0.41l-0.18,0.01c0.25,0.67,0.72,1.23,1.39,2.61c0.29-0.4-0.16,0.82,0.12,0.59
				c0.03,0.38,0.13,0.57,0.16,0.96c0.08-0.11,0.61,1.08,0,0.84c0.13,0.34-0.03,0.65-0.07,0.84l-0.04-0.18
				c0.03,0.11,0.02,0.19,0.02,0.28l0.12-0.1c-0.02,0.11-0.02,0.2-0.07,0.19c0.15,0.21,0.44-0.02,0.17,0.63
				c0.09-0.43-0.14,0-0.15-0.31c-0.14,0.44-0.19,0.21,0.03,0.84c-0.08-0.07-0.18,0.07-0.18,0.15c0.01-0.13,0.25,0.03,0.14,0.58
				l0.08-0.16c0.15,0.36-0.24,0.71-0.94,1.71c0.16-0.02,0.14-0.06,0.11,0.21c0.09-0.17,0.14-0.37,0.21-0.56l-0.01,0.42
				c0.08-0.16,0.1-0.38,0.17-0.46c0.01,0.2-0.01,0.18,0.12,0.14c-0.39,0.55-0.71,0.55-1.39,1.54l0.16-0.08
				c0.03,0.13-0.23,0.25-0.33,0.4c-0.05-0.09-0.16-0.05-0.26-0.05c0.05,0.05-0.31,0.23-0.25,0.42c-0.52,0.09-0.3-0.07-1.02,0.63
				c0.08-0.15-0.12-0.14,0.12-0.32c-0.13,0.05-0.25,0.07-0.23,0.14c0.18-0.09-0.49,0.05-0.79,0.5c-0.23-0.2-0.45-0.08-1.21-0.26
				c0.13,0.11-0.09-0.37-0.01,0c-0.08-0.04-0.04,0.02-0.39-0.13c0.01,0,0,0.01,0.01,0.02c-0.25-0.08-0.52-0.17-0.87-0.32
				c0.03-0.01,0.03-0.04,0.07-0.01c-0.81-0.5-0.05,0.05-0.96-1.01l0.06,0.04c-0.09-0.36-0.22,0.02-1.25-1.63
				c0.06-0.01,0.09-0.01,0.12-0.01c-0.11-0.02-0.3-0.12-0.07-0.59l0.09,0.3c0.13-0.2-0.11-0.38-0.11-0.55l0.08,0
				c-0.14-0.52-0.16-0.12-0.4-1.25c-0.22,0.49,0.27-0.36-0.08-2.2c-1.01,1.31-0.2-0.14-2.55,3.89c0.38-0.13-0.08,0.21,0.22,0.29
				c-0.12,0.17-0.06,0.35-0.38,0.09c0.21-0.17-0.01,0.4,0.24,0.17c-0.16,0.23-0.38,0.26-0.8,0.48c0.12,0,0.08,0.19-0.06,0.35
				c0.09-0.06,0.18-0.13,0.27-0.2c-0.11,0.54-0.43,0.28-0.58,0.79l0.01-0.19c-0.36,0.08,0.15,0.81-2.14,1.91l0.02-0.09
				c-0.3,0.15-0.15-0.02-0.75,0.18l0.12-0.09c-2.42-0.35-0.8,0.55-4.73-3.12c-0.02,0.04-0.29,0.06-0.35-0.37
				c0,0.07,0.22-0.64-0.39-0.65c0.28-0.66-0.49-0.04-0.32-3.87l0.1,0.12c-0.27-1-0.24-0.42,0.12-2.43c0.03,0.02,0.08,0.05,0.07,0.14
				c0.06-0.26,0.4-0.93,0.1-0.92c0.18,0.05,0.19-0.54,0.22-0.86l0.11,0.09c-0.04-0.03-0.01-0.4,0.19-1.17l0.03,0.15
				c0.12-0.35,0.47-1.04,1.14-2.19c-0.2,0.34-0.35,0.61-0.35,0.58c0.11,0.06,0.29-0.2,0.32,0.1c0.08-0.25-0.1-0.03,0.33-1l0,0.03
				c0.2-0.38,0.18-0.17,0.58-0.79c-0.13-0.06,0-0.23-0.2-0.12c0.03-0.03-0.04,0.07-0.08,0.19l0-0.02c-0.05,0.09-0.12,0.21-0.2,0.34
				c0.27-1.09,0.55-0.47,1.61-2.55c-0.1,0.39-0.15,0.34,0.54-0.78l0.01,0.09c0.79-0.59,0.52-1.53,1.46-2.14
				c-0.06,0.04-0.09,0.07-0.13,0.06c0.42-0.12,0.42-0.98,2.34-2.43c-0.02-0.01-0.04-0.01-0.05-0.02c0,0.02-0.21-0.01,0.03,0.05
				c-0.1,0.01-0.11,0.07-0.08,0.13c-0.11,0-0.15-0.08-0.1,0.25c0.05-0.03,0.23,0.01,0.25,0.04l-0.16-0.02
				c0.1,0.03,0.07-0.02,0.18,0.04c-0.02,0.03-0.19-0.01-0.24-0.04l0.05,0.05c-0.05-0.01-0.07-0.02-0.13-0.03
				c0.1,0.03-0.17,0.05-0.01,0.1c-0.35-0.43,0.23,1.19-0.24,1.8c0.01,0,0.14,0,0.1,0.02c0.21-0.04-0.27,0.41,0.07,1.18
				c-0.04-0.01-0.09-0.01-0.09-0.02c-0.12,0.04,0,0.11,0.1,0.11c-0.03-0.02-0.25,0.08-0.02,0.17c-0.06,0.06,0.07,0.1-0.01,0.15
				c0.16,0.02-0.07,0.1,0.15,0.09c-0.23,0.01-0.18,0.11-0.07,0.13c-0.07-0.01-0.07,0.04-0.16,0.03c0.01,0.04,0.05,0.07,0.17,0.06
				c-0.26,0.1,0.23,0.12-0.11,0.2c0.13,0.11,0.23,0.2,0.29,0.3c-0.18,0.08-0.17-0.03-0.01,0.4c-0.02,0-0.05,0-0.06-0.01
				c0.01,0.02-0.03,0.04,0.07,0.03l-0.03-0.01c0.14-0.05,0.32,0.02,0.4,0.01c-0.3,0.02-0.06,0.1-0.43,0.11
				c0.02,0.01,0.07,0.02,0.14,0c-0.12,0.13-0.04,0-0.04,0.45c0.02-0.03-0.02,0.09,0.15,0c-0.07,0,0.21,0.12,0.01,0.08l0.05,0.07
				c0.01-0.04,0.39-0.14,0.15,0.04c0.07,0.04,0.17,0.06-0.15,0.1c0.16-0.01,0.06,0.09,0.23,0.08c-0.04,0-0.14,0.02-0.15,0.03
				l0.13-0.01c-0.31,0,0.24,0.24-0.19,0.27c0.05,0.08,0.26,0.13,0.24,0.23c0.29-0.11,0.01,0,0.04,0.09
				c-0.02-0.01-0.15,0.02-0.12,0.02c-0.02,0.07,0.1,0.1,0.2,0.17c-0.07,0.02-0.11-0.01-0.1,0.15c0.07-0.01,0.06,0.02,0.16-0.02
				c-0.2,0.13-0.25,0.42,0.29,0.36c-0.09-0.01-0.18,0.06-0.23,0.09l0.11-0.03c-0.28,0.12,0.22-0.02,0.05,0.05
				c0.05,0.02,0.16,0.07-0.06,0.07c0.07,0.09,0-0.02-0.1,0.22c0.42-0.14,0.02-0.01,0.36,0.02c-0.22,0.04-0.01-0.04-0.38,0.15
				c0.12-0.01-0.07,0.08,0.11,0.06c-0.26,0.01-0.15-0.12,0.17,0.48c0.05,0.01,0.15,0.01,0.2,0.02c-0.08,0.04-0.13,0.03-0.18,0.04
				c0.03-0.01,0.06,0.06,0.24-0.01c-0.05-0.03-0.11-0.16,0.13-0.21c-0.06-0.01-0.38,0.12,0.06-0.13l-0.07-0.01
				c0.04-0.01,0.13-0.06,0.11-0.03c0.02-0.15,0-0.16-0.36-0.05l0.34-0.17l-0.15,0.05c0-0.01,0.01-0.03,0.06-0.05
				c-0.11,0.01-0.06,0.03-0.16,0.05c0.11-0.09-0.1-0.08,0.13-0.18c-0.11,0.03-0.07,0.07-0.16,0.05c0.28-0.09-0.15-0.07,0.22-0.16
				c-0.05,0.01-0.07,0.01-0.09,0.01c0.03-0.01,0.09-0.04,0.02-0.1c0.12-0.08,0.18,0.05-0.12-0.08c0.07,0-0.03-0.03,0.14-0.2
				c-0.18,0.05-0.16-0.01-0.21-0.03c0.11-0.03,0.15-0.04,0.2-0.04c-0.04-0.04-0.01-0.03-0.13-0.04l0.13-0.03
				c-0.02-0.04-0.18-0.04-0.11-0.12c-0.12,0.03-0.33,0.15-0.43,0.11c0.1-0.04,0.07,0.02,0.47-0.22c-0.04,0.01-0.3,0.05,0.01-0.06
				c-0.07,0.01-0.01-0.04-0.15,0c0-0.01,0.06-0.03,0.1-0.05c-0.07,0,0-0.02-0.21-0.03l0,0c-0.24,0,0.19-0.45,0.02-1.12l-0.06,0.01
				c0.05-0.03,0.04-0.09,0.13-0.09c-0.06-0.02-0.1,0.01-0.12,0.03c0.07-0.07-0.02-0.08,0.03-0.15c-0.11,0.02-0.01-0.01-0.25-0.04
				c0.08-0.05,0.21-0.03,0.13-0.07c0.05-0.04,0.15-0.03,0.2-0.03c-0.12,0.03,0.02-0.13,0.13-0.12c-0.03-0.01-0.03-0.02-0.03-0.04
				l-0.11,0.03c0.02-0.02,0.02-0.03,0.07-0.04c-0.08,0-0.19,0.04-0.3,0.03c0.06-0.03-0.07-0.05,0.08-0.09
				c-0.04,0.07,0.16-0.03,0.19,0.02c0.08-0.12,0.15-0.04-0.15-0.1c0.09-0.01,0.16-0.04,0.14-0.05c0,0.03-0.14,0.03-0.22,0.02
				c0.06-0.05-0.06-0.04-0.05-0.06c-0.06,0.04-0.05,0.05-0.14-0.01c0.07-0.01,0.05,0.02,0.08,0.01c-0.16-0.01,0.15-0.1,0.04-0.13
				c0.06-0.03,0.16-0.02,0.2-0.05c-0.13-0.05,0.13-0.13-0.19-0.1l0.09,0.08c-0.28,0.06-0.28-0.27-0.01-0.38
				c-0.07,0.01,0-0.06-0.2-0.05c0.43-0.04-0.41-0.19,0.12-0.15c-0.1-0.06,0.01,0-0.37-0.14c0.3-0.09-0.03-0.18,0.23-0.26
				c-0.04-0.04-0.07-0.1-0.08-0.14c-0.13,0.07,0.31-0.21-0.17-0.49c0.13,0.02,0.07,0.02,0.2,0.01c-0.02-0.02-0.21,0.01-0.24-0.04
				c0.05,0.01,0.33-0.1,0.38-0.09c-0.01-0.09-0.53-0.22-0.1-0.22c-0.05-0.03-0.19-0.1-0.23-0.15c0.15,0.03,0.22,0.09,0.31,0.12
				c-0.27-0.2-0.16,0.05-0.14-0.74c0.06,0,0.21,0.06,0.29,0.02c-0.12,0.03-0.16-0.06-0.29-0.02c0-0.04,0.12-0.06,0.53-0.08
				c-0.11,0-0.16-0.03-0.11-0.06c-0.03,0.07-0.14-0.15,0.06-0.07c-0.05-0.04,0.01-0.14-0.23-0.21c0.14,0.08,0.25-0.24-0.07-0.62
				c0.18-0.04,0.63,0.06,0.15-0.38c0.25-0.02,0.13-0.19,0.28-0.26c-0.04,0.01-0.17-0.03-0.19-0.06c0.05-0.01-0.14-0.09,0.06-0.05
				l-0.01,0.01c0.2,0.02,0.15-0.04,0.17-0.07l-0.26-0.06c0.4-0.02-0.08-0.28,0.27-0.26c-0.06-0.04-0.05-0.07-0.11-0.1
				c0.04-0.01,0.21,0.11,0.2-0.14c0.14,0.04,0.18,0.06-0.29-0.16l0.21,0.04c0.01-0.08,0.22-0.11,0.05-0.23
				c0.03,0.01,0.09,0.03,0.09,0.04c0.02-0.03,0.18-0.1-0.11-0.17c0.18,0.05,0.04-0.07-0.03-0.14l0.13,0.05
				c-0.16-0.08-0.21-0.1-0.24-0.16c0.02-0.01,0.11,0.02,0.07,0.03c0.15-0.19-0.27-0.06,0.11-0.19c-0.1-0.03-0.05-0.14,0.07-0.19
				c-0.02,0.05-0.04,0.09-0.05,0.08c-0.04,0.02,0.49-0.14,0.08-0.28c0.01,0,0,0.01,0.03,0.04l-0.01-0.01c0,0.02-0.01,0.03-0.01,0.06
				c-0.06-0.04-0.11-0.08-0.03-0.07c-0.08-0.04-0.16-0.08-0.2-0.1c0.62-0.29,0.96-0.92,1.32-2.49c-0.17,0.03-0.13-0.07-0.56,0.62
				c-0.14-0.07-0.34-0.16-0.64-0.29l0.1,0.11c-0.03,0-0.11,0-0.09-0.08c-0.11-0.03-0.03,0.03,0.02,0.08
				c-0.06-0.02-0.1-0.04-0.12-0.04l0.17,0.15c-0.08-0.04-0.15-0.1-0.21-0.09c0.08,0.07,0.19,0.07,0.24,0.14
				c-0.11-0.02-0.18-0.07-0.11,0c-0.06-0.02-0.09-0.05-0.11-0.07c0.05,0.06,0,0.08,0.1,0.15c-0.13-0.03-0.01,0.1-0.21,0
				c0.04,0.03,0.13,0.12,0.13,0.14c-0.22-0.15-0.05,0.08-0.31-0.05c0.2,0.13,0.04,0.16,0.14,0.26c-0.02-0.01-0.05-0.03-0.03-0.03
				c-0.03,0.02-0.1,0-0.15,0c0.18,0.08,0.2,0.15,0.3,0.23c-0.19-0.04-0.2-0.12-0.37-0.18c0.15,0.06,0.12,0.13,0.24,0.17
				c-0.12-0.02-0.06,0-0.22-0.05c0.22,0.1,0.03,0.04,0.15,0.13c-0.13-0.01-0.22-0.08-0.17,0c-0.55,0,0.45-0.19-0.41,0.94
				c0.02-0.41-0.44,0.46-0.6,0.21c0.02,0.33-0.48,0.69-0.64,1.09c0-0.03,0-0.09,0.03-0.11c-0.16,0.16-0.26,0.17-0.39,0.25
				c0.17-0.2-0.15,1.28-0.36,0.35c0.13,0.09-0.19,0.48-0.03,0.49c-0.23,0.09-0.16,0.11-0.36,0.09c0.3,0.03-0.58,1.06-0.51,0.44
				c0.25,0.26-0.92,1.08-1.16,2.5c-0.84-0.24-0.17-0.34-1.81,2.23l-0.09-0.21c-0.29,1.33-0.47-0.47-3.31,6.54l-0.01-0.11
				c-0.18,0.36,0.15,0.51-0.07,0.83l-0.01-0.07c-0.09,0.08,0.04,0.94-0.56,4.93c0.07-0.03,0.13,0.28,0.13,0.7
				c0.39-0.29-0.33,0.17,0.45,1.42c-0.06,0-0.13-0.12-0.2-0.21c0.29,0.36-0.13,0.13,0.14,0.46c0.01-0.06,0.1-0.01,0.13-0.01
				c-0.01,0.22,0.16,0.64,0.36,0.93l-0.12-0.01c0.24,0.53,0.66,1.07,1.1,1.58c0.86,0.59,1.11,1.59,8.47,0.5
				c0.05,0.22,0.34-0.42,2.44-2.95l-0.21-0.15c0.41,0.98,0.73,2.18,1.61,2.89c-0.07-0.02-0.11,0.01-0.18-0.07
				c0.05,0.27,0.6,0.63,0.67,0.55c-0.07-0.01,0.8,1.01,2.98,1.02c-0.03,0.24,0.75,0.14,0.97-0.04c-0.07,0.09,0.24,0.02,0.16,0.13
				c0.3-0.07,0.54-0.21,0.45-0.3c1.34-0.39,1.22-0.2,2.91-2.32c-0.77,1,1.35-0.86,0.94-3.19c0.26,0.25,0.17-0.59,0.49-0.21
				c-0.01-0.08-0.05-0.19-0.12-0.18c0.03-0.12,0.07-0.15,0.14-0.02c-0.02-0.45-0.13-0.3-0.11-0.7c0.05,0,0.07,0.08,0.12,0.13
				c-0.09-0.21-0.19-0.41-0.14-0.74c0.03,0.02,0.07-0.02,0.07,0.08c-0.03-0.58-0.31-0.96-0.3-1.58c0.12,0.09,0.29,0.06,0.08-0.61
				l-0.06,0.15c-0.05-0.3-0.26-0.51-0.27-0.77l0.13,0.19c-0.06-0.18-0.12-0.35-0.19-0.52c0.02,0.1,0.01,0.21,0.02,0.32
				c-0.58-0.71-0.14,0.07-0.9-1.96c0.23,0.11,0.12-0.46-0.22-0.51c0.03,0.03,0.04,0,0.09,0.06c-0.28-0.45-0.34-0.66-0.38-0.83
				l0.09,0.11c-0.27-0.58,0.06,0.03-1.28-1.91l0.05,0.11c-0.27,0.08-0.14-0.45-0.42-0.64c0.18-0.24,0.12,0.01-0.83-1.14l0.1,0.02
				c-0.71-0.87-0.45-0.81-1.85-2.77c0.16,0.11,0.23,0.21-0.25-0.4c-0.07,0.1-0.02,0.11-0.03,0.26c-0.1-0.03-0.23-0.15-0.31-0.28
				c0.21,0.2,0.16-0.1,0.15-0.23c-0.02,0.04-0.07,0.02-0.1,0.05C193.93,355.28,193.69,355.73,193.59,355.28z M180.86,359.29
				c0.03,0.08,0.06,0.1-0.07,0.18C180.8,359.42,180.82,359.48,180.86,359.29z M188.02,350.28c0.02,0.02-0.04,0.02-0.06-0.03
				C187.98,350.26,188.01,350.27,188.02,350.28z M186.42,351.49c0.06-0.04,0-0.09,0.17,0.02L186.42,351.49z M186.47,351.43
				c0.04-0.03,0.01-0.03,0.12,0.03L186.47,351.43z M186.6,351.29c0.02-0.02,0,0,0.05-0.06C186.69,351.25,186.82,351.27,186.6,351.29
				z M179.75,359.35c-0.02,0.03-0.04,0.07-0.08,0.15C179.7,359.43,179.68,359.5,179.75,359.35z M179.54,359.94
				c-0.01-0.05-0.1,0.06-0.18,0.14C179.39,360.01,179.48,359.73,179.54,359.94z"
                />
                <path
                  className="st10"
                  d="M259.31,365.99c-0.06,0.01-0.19-0.03-0.06-0.24c-0.05,0.03-0.19,0.34-0.05-0.14l-0.06,0.04
				c0.02-0.04,0.05-0.14,0.06-0.1c-0.1-0.12-0.13-0.12-0.3,0.21l0.1-0.37l-0.07,0.14c-0.01-0.01-0.02-0.03,0.01-0.08
				c-0.07,0.08-0.02,0.06-0.08,0.15c0.01-0.15-0.14,0.02-0.05-0.21c-0.06,0.1,0.01,0.1-0.07,0.16c0.13-0.27-0.16,0.08,0.02-0.27
				c-0.03,0.04-0.04,0.06-0.05,0.07c0.02-0.03,0.03-0.1-0.08-0.08c0.01-0.15,0.16-0.1-0.15,0.05c0.05-0.06-0.05,0-0.08-0.24
				c-0.07,0.17-0.11,0.12-0.16,0.14c0.04-0.1,0.06-0.14,0.09-0.18c-0.05,0-0.03-0.01-0.11,0.08l0.05-0.13
				c-0.05-0.01-0.15,0.12-0.18,0.01c-0.04,0.12-0.06,0.35-0.15,0.42c0.02-0.11,0.06-0.05,0.08-0.51c-0.02,0.04-0.13,0.28-0.06-0.04
				c-0.03,0.06-0.04-0.02-0.09,0.13c-0.01,0,0-0.07,0.01-0.11c-0.04,0.06-0.01-0.01-0.14,0.16l0,0c-0.13,0.22-0.3-0.45-1.09-0.57
				l-0.02,0.06c-0.01-0.06-0.08-0.07-0.04-0.16c-0.05,0.05-0.03,0.09-0.02,0.13c-0.04-0.1-0.09-0.02-0.14-0.09
				c-0.02,0.11-0.01,0.01-0.14,0.22c-0.02-0.09,0.05-0.21-0.02-0.15c-0.03-0.07,0.02-0.16,0.05-0.2c-0.08,0.1-0.09-0.09-0.12-0.12
				l0.04-0.04c-0.02,0.02-0.04,0.02-0.05,0.02l0,0.12c-0.01-0.02-0.03-0.03-0.02-0.08c-0.03,0.07-0.02,0.2-0.08,0.3
				c-0.01-0.06-0.08,0.05-0.06-0.11c0.06,0.07,0.02-0.16,0.09-0.17c-0.1-0.11,0-0.16-0.16,0.1c0.02-0.09,0.01-0.17,0-0.15
				c0.03,0.01-0.01,0.15-0.05,0.21c-0.04-0.08-0.06,0.04-0.08,0.03c0.02,0.07,0.03,0.06-0.06,0.13c0.01-0.07,0.04-0.04,0.03-0.07
				c-0.06,0.15-0.06-0.17-0.12-0.08c-0.01-0.06,0.02-0.17,0.01-0.21c-0.09,0.12-0.1-0.17-0.17,0.16l0.11-0.07
				c-0.03,0.29-0.34,0.21-0.42-0.08c-0.01,0.07-0.07-0.02-0.1,0.18c0.05-0.43-0.29,0.36-0.14-0.16c-0.02,0-0.04-0.02-0.05,0.04
				c0.03,0-0.14-0.01-0.17,0.29c-0.04-0.31-0.2,0.01-0.24-0.26c-0.05,0.03-0.12,0.06-0.16,0.06c0.08,0.14-0.2-0.35-0.55,0.11
				c0.02-0.13,0.03-0.07,0.03-0.2c-0.03,0.02,0,0.21-0.06,0.24c0.02-0.05-0.08-0.33-0.08-0.38c-0.1,0-0.26,0.52-0.25,0.09
				c-0.03,0.05-0.11,0.19-0.16,0.22c0.03-0.15,0.1-0.22,0.14-0.3c-0.22,0.27,0.05,0.16-0.81,0.15c0-0.05,0.01-0.13,0.01-0.2
				c-0.01,0.06-0.04,0.11-0.02,0.19c-0.04-0.01-0.08-0.13-0.12-0.5c0.01,0.11-0.02,0.16-0.06,0.11c0.08,0.03-0.16,0.14-0.09-0.05
				c-0.04,0.06-0.17,0-0.21,0.25c0.07-0.16-0.3-0.22-0.67,0.16c-0.08-0.18-0.07-0.63-0.44-0.07c-0.08-0.24-0.24-0.09-0.36-0.22
				c0.02,0.04,0.01,0.17-0.01,0.19c-0.03-0.04-0.06,0.16-0.07-0.05l0.01,0c-0.03-0.2-0.08-0.14-0.12-0.14l0.01,0.26
				c-0.13-0.38-0.28,0.16-0.36-0.18c-0.02,0.07-0.05,0.07-0.08,0.14c-0.03-0.05,0.04-0.23-0.21-0.15c0.06-0.49-0.25,0.04-0.49,0.2
				c-0.02-0.19-0.09-0.01-0.13,0.08l-0.01-0.14c-0.02,0.18-0.02,0.23-0.07,0.28c-0.01-0.02-0.02-0.11,0-0.08
				c-0.22-0.09,0.03,0.29-0.23-0.03c0.01,0.1-0.12,0.1-0.22,0.02c0.06,0,0.11,0,0.11,0.01c0.05,0-0.36-0.38-0.31,0.05
				c-0.01-0.01,0.01-0.01,0.03-0.04l0,0.01c0.02,0,0.04-0.01,0.06-0.02c-0.01,0.07-0.03,0.13-0.06,0.06
				c-0.06,0.3,0.11,0.24-0.48,0.2c-0.06-0.08,0.01-0.17-0.06-0.13c-0.13-0.37,0-0.06-0.38,0.08c0.01,0.02,0.02,0.03,0.03,0.06
				c-0.05-0.02-0.34-0.26-0.63,0.12l-0.03-0.15c0.06,0.34-0.22,0.05-0.25,0.18c-0.01-0.01-0.1-0.03-0.14-0.07l-0.07,0.14
				c-0.1-0.28-0.32-0.63-0.03,0.77l0.04-0.15l0.03,0.11l-0.05,0.01c0.04,0.1,0.04,0.01,0.05-0.06c0.01,0.06,0.02,0.1,0.04,0.12
				l0.03-0.23c0.02,0.09,0,0.18,0.04,0.23c0.01-0.11-0.05-0.2-0.02-0.27c0.04,0.1,0.04,0.19,0.06,0.09c0.01,0.06,0.01,0.1,0,0.13
				c0.02-0.07,0.07-0.04,0.07-0.16c0.04,0.13,0.09-0.04,0.11,0.17c0-0.05,0.03-0.18,0.05-0.19c-0.01,0.27,0.1,0,0.12,0.29
				c0.01-0.24,0.11-0.12,0.16-0.26c0,0.02,0,0.06-0.01,0.05c0.04,0.02,0.05,0.09,0.08,0.13c-0.02-0.2,0.03-0.25,0.04-0.37
				c0.06,0.18,0,0.23,0.04,0.41c-0.02-0.16,0.05-0.17,0.02-0.3c0.05,0.11,0.03,0.05,0.07,0.21c-0.02-0.24,0.02-0.04,0.03-0.2
				c0.06,0.11,0.04,0.23,0.08,0.15c0.06,0.33,0.03-0.05,0.3-0.25c0.02,0.22,0.03-0.34,0.02-0.12l0.02-0.05
				c0.04,0.17-0.03,0.06-0.01,0.19c0.03-0.05,0.19-0.25,0.11,0.05l-0.06-0.06c0.14,0.11,0.26,0.29,0.78-0.09
				c0,0.05-0.01,0.11,0.02,0.15c-0.09-0.16,0.56-0.12,1.49-0.1l0.03-0.16l0.04,0.18c0.06-0.22,0.02,0.25,0.23-0.11
				c0,0.03,0,0.04-0.01,0.06c0.02,0,0.04,0.2,0.04-0.04c0.03,0.09,0.1,0.09,0.14,0.05c0.03,0.11-0.04,0.16,0.27,0.04
				c-0.04-0.05-0.04-0.23-0.01-0.25l0.02,0.16c0.01-0.1-0.03-0.06,0-0.18c0.04,0.02,0.03,0.18,0.02,0.24l0.04-0.06
				c0,0.05,0,0.07,0,0.14c0.01-0.1,0.08,0.16,0.1-0.02c-0.37,0.44,1.13-0.46,1.78,0.02c0-0.02-0.01-0.23,0.04-0.02
				c-0.3-0.39,0.44,0.22,1.14-0.11c-0.01,0.04-0.01,0.09-0.03,0.08c0.02,0.12,0.11,0.01,0.12-0.08c-0.02,0.03,0.05,0.26,0.17,0.04
				c0.05,0.07,0.11-0.05,0.14,0.03c0.04-0.15,0.08,0.08,0.11-0.14c-0.03,0.22,0.08,0.19,0.12,0.09c-0.02,0.07,0.02,0.07,0,0.17
				c0.04,0,0.08-0.04,0.08-0.15c0.04,0.28,0.17-0.2,0.17,0.15c0.13-0.11,0.24-0.17,0.36-0.21c0.03,0.21-0.08,0.14,0.39,0.12
				c0,0.02-0.02,0.04-0.03,0.06c0.02,0,0.03,0.04,0.05-0.06l-0.02,0.02c0-0.15,0.12-0.29,0.13-0.37c-0.08,0.28,0.07,0.09-0.03,0.45
				c0.01-0.02,0.04-0.06,0.05-0.13c0.08,0.16-0.02,0.04,0.41,0.19c-0.02-0.03,0.07,0.05,0.06-0.14c-0.03,0.07,0.19-0.14,0.08,0.02
				l0.08-0.02c-0.03-0.03,0.03-0.41,0.1-0.12c0.07-0.05,0.13-0.13,0.03,0.18c0.05-0.15,0.11-0.02,0.17-0.18
				c-0.02,0.04-0.04,0.13-0.04,0.15l0.05-0.12c-0.14,0.28,0.33-0.11,0.16,0.28c0.1-0.01,0.24-0.18,0.32-0.11
				c0.05-0.31,0-0.01,0.1,0.01c-0.02,0.02-0.05,0.14-0.04,0.11c0.05,0.06,0.14-0.03,0.25-0.08c-0.02,0.07-0.07,0.08,0.08,0.16
				c0.03-0.06,0.05-0.04,0.07-0.14c0,0.23,0.17,0.44,0.48-0.02c-0.06,0.06-0.06,0.18-0.06,0.24l0.04-0.1
				c-0.07,0.29,0.12-0.19,0.08-0.01c0.04-0.03,0.16-0.08,0.02,0.09c0.12,0-0.01-0.01,0.11,0.22c0.15-0.41,0-0.02,0.25-0.26
				c-0.12,0.2-0.03-0.02-0.13,0.39c0.07-0.1,0.01,0.11,0.11-0.05c-0.02,0.05,0.03,0.02-0.02,0.13c-0.26-0.14,0.56-0.29,0.49,0.09
				c0.05-0.03,0.12-0.1,0.17-0.12c-0.03,0.08-0.07,0.11-0.11,0.15C259.15,366.13,259.22,366.16,259.31,365.99z M248.49,364.23
				c0-0.03,0-0.05,0-0.07C248.5,364.13,248.53,364.18,248.49,364.23z M248.76,365.1c0-0.04-0.03-0.17,0.04,0
				C248.79,365.1,248.81,365.1,248.76,365.1z"
                />
                <path
                  className="st10"
                  d="M291.43,367.72c0-0.29,0.22-0.26-0.67-1.91c0.04-0.01,0.06-0.01,0.08-0.01c-0.11-0.02-0.24-0.14-0.1-0.56
				l0.13,0.27c0.1-0.22-0.17-0.36-0.22-0.5l0.08-0.02c-0.26-0.4-0.43-0.29-0.7-0.69l-0.02,0.08c-0.55-0.41,0.04-0.37-1.06-1.02
				c0.24-0.01,0.54,0.2,0.77,0.3c-0.13-0.12-0.44-0.42-0.58-0.39c0.07,0.03,0.17,0.05,0.19,0.13c-1.19-0.58-0.15-0.09-2.81-1.83
				c0.19-0.23,0.43-0.58,0.78-1.16l0.05,0.08c0.04-0.41,0.21-0.15,0.18-0.95c0.03,0.06,0.05,0.11,0.06,0.17
				c0.2-0.74-0.24-1.17-0.5-1.6c0.21-0.09,0.02-0.62-0.14-0.85l0.03-0.01c-0.77-0.69,0.08-0.22-3.56-2.12
				c0.03,0.05-0.17,0.23-0.51,0.03c0.09,0.03-0.45-0.55-0.73-0.02c-0.48-0.3-0.11-0.07-3.7-0.29l0.1-0.11
				c-0.88,0.46-0.35,0.2-2.24,0.57c0-0.03,0-0.09,0.08-0.12c-0.24,0.04-0.95,0.07-0.8,0.33c-0.05-0.18-0.52,0.11-0.77,0.26
				l0.01-0.15c0.01,0.05-0.29,0.21-0.93,0.54l0.09-0.12c-0.4,0.12-0.13,0.4-0.89,0.26c0.12-0.05-0.31,0.33-0.24,0.13
				c-0.48-0.03,0.05,0.15-0.97,0.27c0.33-0.11,0.6-0.19,0.58-0.18c-0.04-0.12-0.32-0.05-0.16-0.29c-0.21,0.12,0.05,0.08-0.81,0.49
				l0.02-0.02c-0.25,0.11-0.26,0.07-0.46,0.11c0.09-0.09-0.01,0,0.27-0.42l0,0c0.13-0.38,1.25-0.68,2.56-2.14
				c-0.11,0.23,0.11,0-0.14,0.22c0.26-0.18,0.36-0.18,0.5-0.52c0.01,0.17,0.28-0.06,0.35-0.2l-0.22,0.1
				c0.1-0.14,0.21-0.24,0.32-0.34l0.07,0.17c0.51-0.47,1.03-1.09,1.71-2.44c-0.49,0.06,0.73-0.45,0.37-0.5
				c0.26-0.3,0.3-0.52,0.51-0.87c-0.1,0.04,0.02-1.26,0.44-0.77c0.03-0.38,0.28-0.63,0.38-0.83l-0.01,0.2
				c0.01-0.12,0.04-0.21,0.06-0.31l-0.14,0.08c0.05-0.1,0.07-0.2,0.12-0.19c-0.11-0.25-0.4-0.07-0.06-0.69
				c-0.14,0.44,0.14,0.03,0.11,0.34c0.01-0.08,0.03-0.23,0.1-0.19c0.22-0.43-0.14-0.24-0.06-0.71c0.08,0.07,0.18-0.09,0.17-0.17
				c-0.01,0.15-0.22-0.02-0.2-0.6c-0.01,0.06-0.03,0.12-0.05,0.18c-0.27-0.35,0.24-0.78,0.21-2.11c-0.11,0.13-0.07,0.14-0.23-0.11
				c0.06,0.2,0.1,0.41,0.16,0.62l-0.21-0.39c0.02,0.19,0.12,0.39,0.11,0.51c-0.12-0.18-0.09-0.17-0.17-0.07
				c-0.12-0.95,0.6-0.51-0.22-2.19l0.02,0.2c-0.14-0.04-0.14-0.34-0.25-0.5c0.1-0.02,0.08-0.14,0.11-0.24
				c-0.06,0.04-0.18-0.41-0.38-0.37c-0.11-0.56,0.08-0.35-0.76-1c0.17,0.05,0.1-0.16,0.34,0.05c-0.48-0.37,0.19,0.17-3.17-2.4
				c0.01-0.01,0.02-0.02,0.03-0.02c-0.91-0.25,0.05-0.11-1.35-0.62l0.08,0c-0.33-0.09-0.15-0.22-2.16,0
				c0.01-0.04,0.01-0.06,0.02-0.09c-0.07,0.09-0.24,0.17-0.6-0.12l0.32-0.02c-0.17-0.17-0.42,0.01-0.59,0.01l0.02-0.08
				c-0.5,0.07-0.48,0.27-1,0.36l0.07,0.04c-0.66,0.35-0.35-0.21-1.5,0.57c0.11-0.21,0.46-0.44,0.67-0.59
				c-0.19,0.05-0.61,0.25-0.66,0.37c0.06-0.05,0.14-0.13,0.22-0.13c-1.29,1-0.08-0.02-3.52,2.53c0.41-0.06-0.13,0.2,0.15,0.33
				c-0.13,0.11-0.12,0.37-0.39,0.03c0.24-0.15-0.06,0.41,0.22,0.2c-0.14,0.19-0.34,0.35-0.59-0.03c0.07,0.1,0.08,0.19,0.05,0.17
				c0.14,0.23,0.08,0.15-0.03,0.2l-0.01-0.23c-0.02,0.25-0.01,0.16-0.05,0.7c-0.11-0.12,0.15-0.26-0.36-1.08l0.14,0.11
				c-0.19-0.69-0.66-0.89-1.04-1.3c0.19-0.13-0.13-0.63-0.35-0.85l0.03-0.02c-0.55-0.17-1.92-1.54-4.64-0.66
				c0.1,0.02-0.03,0.3-0.41,0.5c0.26,0.19-0.97-0.41-1.04,4.92l-0.12-0.1c0.51,0.95,0.22,0.38,0.67,2.42c-0.03,0-0.1,0-0.13-0.09
				c0.06,0.26,0.11,1.03,0.37,0.87c-0.18,0.05,0.12,0.57,0.29,0.83l-0.15-0.01c0.05,0,0.23,0.32,0.57,1.02l-0.12-0.1
				c0.13,0.44,0.41,0.15,0.29,0.97c-0.06-0.13,0.34,0.34,0.14,0.27c-0.02,0.52,0.15-0.06,0.3,1.06c-0.12-0.36-0.21-0.66-0.19-0.63
				c-0.12,0.04-0.04,0.34-0.29,0.16c0.13,0.23,0.08-0.05,0.52,0.89l-0.02-0.02c0.16,0.39,0.01,0.25,0.24,0.92
				c0.13-0.06,0.17,0.14,0.22-0.08c0.01,0.05-0.03-0.06-0.1-0.17l0.02,0.01c-0.04-0.09-0.08-0.22-0.14-0.36
				c0.68,0.85-0.01,0.7,1.03,2.72c-0.25-0.29-0.19-0.3,0.31,0.86l-0.07-0.04c0.02,0.95,0.93,1.24,0.87,2.31c0-0.07,0-0.11,0.03-0.13
				c-0.72,0,2.51,4.43,0.39,5.96c-0.06-0.1-0.12-0.19-0.22-0.19c0.04-0.21-0.3-0.43-0.11-0.54c-0.07-0.01-0.3-0.13-0.39-0.23
				c0.37,0.03-0.32-0.42-0.07-0.55c-0.3,0.01-0.56-0.43-0.89-0.55c0.03,0,0.08-0.01,0.09,0.02c-0.12-0.14-0.12-0.23-0.18-0.34
				c0.14,0.13-1.11,0-0.28-0.29c-0.09,0.13-0.41-0.11-0.43,0.04c-0.07-0.19-0.09-0.13-0.06-0.32c-0.04,0.28-0.9-0.36-0.37-0.4
				c-0.23,0.28-0.92-0.61-2.11-0.58c0.21-0.8,0.3-0.22-1.9-1.19l0.17-0.13c-1.13-0.09-1.81-1.08-5.34-1.89l0.08-0.02
				c-0.28-0.14-0.41,0.2-0.64,0c0.32-0.04-0.28-0.24-4.19-0.86c-0.08,0.32,0.29-0.34-1.14,0.06c0.01-0.06,0.11-0.09,0.2-0.13
				c-0.33,0.17-0.07-0.16-0.37,0c0.04,0.03-0.02,0.09-0.02,0.12c-0.16-0.09-0.51-0.05-0.76,0.02l0.04-0.11
				c-0.45,0.03-0.94,0.19-1.42,0.33c-0.95,0.17,0.04-0.17-3.8,3.39c0.14-0.14,0.18,0.03-0.03,0.11c0.2,0.32-0.43,0.59,0.24,2.17
				l-0.03-0.02c1.12,1.12-0.48,0.51,2.84,2.73c0.06-0.11,0.03-0.17,0.22,0.07c0.24-0.26,0.48-0.11-0.21,0.7l-0.02-0.22
				c-0.44,0.76-1.06,1.46-1.16,2.33c-0.02-0.06-0.07-0.08-0.04-0.16c-0.16,0.13-0.2,0.63-0.09,0.66c-0.04-0.07-0.36,0.81,0.02,2.39
				c-0.24,0.01-0.07,0.59,0.1,0.74c-0.08-0.04-0.02,0.19-0.13,0.14c0.08,0.22,0.21,0.4,0.3,0.32c0.16,0.69-0.08,0.73,1.81,2.26
				c-0.71-0.17-1.12-0.28,1.84,1.24c-0.01,0.02-0.07,0.02-0.12,0.01c0.29,0.01-0.04,0.01,0.64-0.19c-0.26,0.22,0.42,0.23,0.05,0.49
				c0.07,0,0.16-0.01,0.17-0.08c0.08,0.05,0.1,0.1-0.02,0.14c0.11,0.11,0.55-0.14,0.43,0.1c0.18-0.04,0.35-0.1,0.6-0.01
				c-0.02,0.03,0,0.07-0.08,0.05c0.45,0.08,0.79-0.12,1.27,0.01c-0.16,0.2,0.16,0.08,0.18,0.27l0.27-0.06l-0.11-0.09
				c0.24,0,0.43-0.13,0.63-0.09l-0.17,0.09l0.43-0.07l-0.25-0.05c0.61-0.38-0.04-0.16,1.64-0.42c-0.12,0.17,0.35,0.24,0.43-0.1
				c-0.02,0.02-0.01,0.03-0.06,0.07c0.38-0.18,0.56-0.19,0.69-0.19l-0.11,0.07c0.33,0.01,0.03-0.04,1.6-0.86l-0.09,0.03
				c-0.07-0.26,0.38-0.06,0.52-0.29c0.19,0.2-0.01,0.13,0.92-0.61l-0.01,0.09c0.66-0.54,0.68-0.3,2.16-1.43
				c-0.07,0.13-0.15,0.2,0.31-0.21c-0.13-0.11-0.38,0.07-0.03-0.32c-0.14,0.18,0.1,0.15,0.22,0.16c-0.03-0.02-0.03-0.07-0.05-0.1
				c0.34,0.1-0.03-0.12,0.29-0.23c-0.04,0.06,0.44-0.53,0.24-0.06c0.16-0.11,0.19-0.42,0.41-0.4c-0.05,0.07-0.15,0.14-0.2,0.21
				c0.24,0.03,0.24-0.01,0.38-0.06l-0.01,0l0.32-0.11l-0.09,0.01c0.03-0.42,0.39-0.3,0.56-0.67c-0.14,0.3-0.03,0.16,0.29,0.31
				c0.07-0.18,0.27-0.04,0.37-0.3c-0.04,0.11,1.06-0.84,2.46-1.43c-0.01,0.19,0.08,0.33,0.71-0.33l-0.14,0.01
				c0.06,0.18-0.11-0.27-0.82,0.41c0.12-0.31-0.13-0.38-0.67,0.04c0.01-0.05-0.09-0.12,0.02-0.14c-0.35,0.03-0.4,0.37-0.58,0.28
				l0.02-0.02c-0.5,0.09-0.59,0.28-1.27,0.88c-0.06-0.34-0.39,0.42-0.5-0.02c-0.09,0.41-0.09-0.23-1,0.74
				c-0.02-0.06-0.52,0.2-0.78,0.29l-0.01-0.03c0.06,0.18-0.2,0.3-0.34,0.45c-0.05-0.1-0.05-0.15,0.02-0.25
				c0,0.04-0.08-0.04-0.29,0.32l-0.04-0.14c-0.21,0.15-0.33,0.42-0.65,0.53c0.06,0.12,0.41,0.15,0.18,0.41
				c-0.05-0.05-0.13-0.14-0.07-0.21c-0.02,0.02-0.05,0.11-0.12,0.12l0.05-0.17c-0.03,0.08-0.36,0.26-0.13,0.02
				c-0.12,0.06-0.28,0.1-0.37,0.07c-0.09,0.45,0-0.01-0.81,0.79l0,0c-0.28,0.36,0,0-1.11,0.68c-0.06-0.2-0.32,0.2-1.21,0.61
				c0.13-0.16-0.09-0.04,0.15-0.15c-0.24,0.08-0.32,0.02-0.5,0.26c0.03-0.15-0.23-0.07-0.32,0.01l0.19,0.02
				c-0.84,0.04,0.03-0.17-2.46,0.69c0.21,0.16-0.31,0.11-0.15-0.08l-0.02,0.06c-0.12-0.05-0.42-0.02-0.35-0.12
				c-0.16,0.07-0.07,0.1,0.06,0.12c-0.29-0.07-0.44,0.01-0.73-0.05c0.1,0.08-0.93,0.4-0.62-0.16c-0.28,0.08-0.47-0.16-0.61-0.21
				l0.14-0.02c-0.09,0.01-0.15,0-0.22-0.02l0.05,0.13c-0.07-0.04-0.14-0.05-0.13-0.1c-0.17,0.07-0.06,0.43-0.51,0.07
				c0.3,0.14,0.04-0.14,0.27-0.11c-0.06-0.01-0.17-0.03-0.13-0.09c-0.26-0.23-0.2,0.12-0.52,0c0.07-0.07-0.01-0.18-0.07-0.19
				c0.1,0.01-0.04,0.23-0.48,0.07l0.11,0.08c-0.35,0.11-0.48-0.32-1.16-1c-0.01,0.14,0.02,0.13-0.21,0.1
				c0.14,0.07,0.27,0.15,0.42,0.2c-0.12,0-0.23-0.02-0.34-0.03c0.11,0.09,0.28,0.11,0.34,0.17c-0.17,0-0.15-0.01-0.14,0.11
				c-0.6-0.45,0.11-0.53-1.07-1.24l0.06,0.13c-0.13,0.04-0.19-0.18-0.31-0.26c0.08-0.05,0.06-0.14,0.07-0.22
				c-0.06,0.05-0.17-0.25-0.35-0.17c-0.05-0.41,0.09-0.26-0.54-0.76c0.14,0.05,0.14-0.11,0.3,0.05c-0.04-0.1-0.06-0.2-0.12-0.17
				c0.11,0.03-0.12-0.1-0.16-0.38c-0.01,0.04-0.01,0.01,0.02,0.11c-0.09-0.03,0.02-0.21-0.36-0.28c0.2-0.31,0.02-0.43,0.06-2.05
				c0.03,0.02,0.05,0.01,0.03,0.05c0.04-0.72-0.02-0.02,0.37-1.04l0,0.06c0.17-0.12,0.03-0.45,0.19-0.69c0,0,0.02,0.03,0.04,0.02
				c-0.07-0.25,0.09-0.61,0.22-0.87c0.04,0.04,0.07,0.07,0.09,0.09c-0.05-0.09-0.11-0.28,0.35-0.29c-0.05,0.06-0.09,0.12-0.14,0.19
				c0.46-0.16,0.22-0.14,0.95-1.02c-0.65-0.12,1.61-0.45,0.81-0.74c0.06,0,0.18-0.03,0.3-0.04c-1.32-0.34-0.28,0.01-3.2-1.53
				c-0.1,0.25-0.28,0.21-0.41,0.04c0.07-0.03,0.28-0.03,0.18-0.2c0.05,0.18-0.19,0.03-0.21,0.13c-0.1-0.18-0.15-0.44-0.04-0.68
				c-0.06,0.1-0.18,0.04-0.24-0.1l0.03,0.25c-0.37-0.19,0-0.4-0.33-0.61l0.14,0.05c-0.16-0.12-0.31-0.58-0.58-0.69
				c0.02-0.04,0.09-0.11,0.14-0.03c0.08-0.29-0.26-0.49,0.06-1.75l0.03,0.12c0.59-1.22,0.67-0.67,3.95-2
				c-0.02-0.03,0-0.29,0.37-0.19c-0.07,0,0.5,0.41,0.58-0.16c0.22,0.32,0.81-0.19,3.05,0.43l-0.1,0.09c0.8-0.21,0.31-0.18,1.93,0.18
				c-0.01,0.03-0.04,0.08-0.11,0.08c0.2,0.05,0.74,0.34,0.74,0.04c-0.05,0.19,0.43,0.14,0.69,0.13l-0.08,0.12
				c0.01-0.04,0.3-0.05,0.94,0.02l-0.12,0.05c0.25,0.05,0.52,0.17,1.8,0.67c-0.28-0.11-0.51-0.21-0.49-0.21
				c-0.04,0.12,0.18,0.23-0.07,0.33c0.12,0.13,0.52-0.19,1.46,0.47c0.05-0.13,0.18-0.05,0.09-0.22c0.03,0.02-0.05-0.02-0.16-0.03
				l0.01-0.01c-0.08-0.03-0.17-0.07-0.29-0.11c0.1-0.11,0.15,0.24,0.58-0.06c0.18,0.54,0.22,0.46,1.56,0.97
				c-0.07,0-0.13-0.04-0.18-0.02c0.22,0.03,0.58,0.26,0.71,0.12c0.2,0.2-0.21,0.08,0.13,0.22l-0.07,0.03c0.5,0.6,1.27,0.15,1.78,0.9
				c-0.04-0.05-0.05-0.07-0.05-0.11c-0.06,0.24,2.01,0.94,3.36,3.09l0.06-0.1c-0.55-0.06,1.45,2.12,1.41,0.61
				c-0.24,0.25-0.33,0.37,0.51-0.59c-0.06,0.14,0,0.32-0.17,0.7c0.02-0.02,0.05-0.05,0.11-0.1c0.01,0.77,0.36,1.65,0.72,1.04
				c0.19,0.12-0.21,0.29-0.34,0.25l0.03,0.01c-0.01-0.02-0.01,0-0.05-0.05c0,0,0,0.01,0,0.01c-0.01,0-0.01-0.01-0.04,0
				c0.03,0.03,0.05,0,0.04,0.12c-0.34-0.11-0.02-0.06-0.63,0.71l-0.08-0.24c-0.01,0.19,0,0.13,0.34,0.68
				c-0.5,0.14,0.32,0.31-0.09,0.38c0.1,0.13,0.12,0.37,0.26,0.42c-0.04,0.08-0.02,0.19,0,0.28c-0.22,0.03-0.22-0.07-0.22,0.54
				c-0.03-0.05-0.15,0.01-0.1-0.12c-0.18,0.36,0.03,0.66-0.17,0.76l0-0.03c-0.22,0.59-0.41,0.99-1.36,3.64
				c-0.03-0.04-0.41,0.54-0.62,0.79l-0.02-0.02c0.13,0.14-0.06,0.42-0.15,0.66c-0.09-0.06-0.11-0.12-0.08-0.27
				c0.01,0.04-0.08,0-0.16,0.49l-0.09-0.12c0.07,0.2-0.53,0.24-0.38,1.23l-0.01-0.21c0,0.1-0.29,0.47-0.13,0.09
				c-0.48,0.55-0.2,0.23-0.97,1.52l0,0c-0.19,0.51,0,0-0.98,1.28c-0.13-0.15-0.32,0.39-1.09,1.3c0.08-0.24-0.11,0.02,0.1-0.23
				c-0.23,0.21-0.33,0.22-0.44,0.56c-0.02-0.17-0.27,0.08-0.34,0.21l0.21-0.11c-0.09,0.14-0.19,0.24-0.3,0.35l-0.08-0.17
				c-0.51,0.47-0.92,1.07-1.8,2.27c0.48,0.05-0.79,0.27-0.45,0.4c-0.32,0.21-0.4,0.42-0.7,0.67c0.12-0.01-0.44,1.15-0.65,0.53
				c-0.16,0.33-0.51,0.42-0.66,0.55l0.09-0.17c-0.06,0.1-0.12,0.16-0.18,0.23l0.16,0.01c-0.09,0.06-0.15,0.13-0.19,0.09
				c-0.03,0.26,0.32,0.27-0.28,0.6c0.34-0.28-0.11-0.09,0.08-0.34c-0.04,0.06-0.14,0.17-0.17,0.11c-0.41,0.21-0.01,0.27-0.33,0.58
				c-0.02-0.1-0.19-0.04-0.23,0.02c0.09-0.11,0.17,0.15-0.2,0.57l0.14-0.1c-0.04,0.42-0.63,0.44-1.61,1.21
				c0.16,0.01,0.15-0.02,0.05,0.24l0.37-0.48l-0.16,0.39c0.13-0.13,0.22-0.32,0.32-0.38c-0.06,0.2-0.07,0.16,0.06,0.17
				c-0.68,0.63-0.66-0.14-1.68,1.29l0.17-0.09c0.03,0.14-0.24,0.25-0.34,0.41c-0.06-0.08-0.16-0.03-0.26-0.02
				c0.06,0.05-0.3,0.28-0.21,0.46c-0.49,0.22-0.33,0-0.8,0.91c0.02-0.17-0.17-0.08-0.01-0.34c-0.31,0.53,0.19-0.14-2.69,2.71
				c-0.01-0.03-0.03-0.04,0.02-0.07c-0.65,0.74-0.04-0.03-1.16,0.89l0.04-0.06c-0.25,0.07,0,0-0.65,0.71c0-0.04-0.18-0.02-0.01,0.01
				c-0.05-0.04-0.05,0.04-0.38,0.32c0.14,0.1,0.12-0.01-0.18,0.41c0.03-0.19,0.14-0.15-0.19-0.39l0.37,0.1l-0.13-0.08
				c0.01-0.01,0.04-0.03,0.09,0c-0.07-0.1-0.06-0.02-0.14-0.1c0.16-0.01,0.03-0.22,0.26-0.13c-0.09-0.07-0.11,0.03-0.15-0.08
				c0.26,0.16-0.04-0.25,0.3-0.02c-0.15-0.25,0.3-0.09,0.05-0.44c0.06,0.06-0.02-0.05,0.29-0.18c-0.16-0.08-0.1-0.16-0.11-0.24
				c0.1,0.04,0.14,0.07,0.17,0.12c0.01-0.06,0.03-0.06-0.05-0.18l0.12,0.06c0.02-0.09-0.09-0.22,0.03-0.3
				c-0.12-0.05-0.36-0.03-0.41-0.17c0.07,0.02,0.18,0.05,0.2,0.09c-0.01-0.02,0.11-0.12,0.16,0c0.03-0.04,0.1-0.07,0.18-0.07
				c-0.05-0.02-0.26-0.16,0.06-0.1c-0.06-0.04,0.03-0.07-0.11-0.12c0.01-0.02,0.08-0.01,0.12-0.01c-0.06-0.06,0,0-0.14-0.21l0,0
				c-0.31,0.01,0.27-0.26,0.37-1c0.03,0.12,0.24-0.18,0.04-0.04c0.3-0.26-0.15-0.83,0.29-0.95l-0.06-0.02
				c0.07-0.03,0.07-0.15,0.17-0.11c-0.05-0.07-0.09-0.04-0.13,0.01c0.1-0.1,0.02-0.17,0.09-0.27c-0.14,0.02-0.34-0.41,0.12-0.23
				c-0.11-0.11,0.09-0.18,0.11-0.24l0.05,0.06c-0.03-0.03-0.03-0.06-0.02-0.09l-0.12,0.02c0.02-0.03,0.02-0.06,0.07-0.05
				c-0.08-0.04-0.2,0.01-0.3-0.05c0.06-0.04-0.06-0.13,0.1-0.14c-0.06,0.13,0.16-0.01,0.18,0.1c0.08-0.17,0.18-0.06-0.12-0.26
				c0.09,0.02,0.17-0.03,0.15-0.05c0,0.05-0.15,0.02-0.22-0.03c0.07-0.08-0.05-0.09-0.04-0.13c-0.06,0.05-0.05,0.08-0.14-0.07
				c0.07,0,0.05,0.05,0.08,0.03c-0.16-0.06,0.16-0.14,0.06-0.23c0.06-0.04,0.17-0.01,0.21-0.04c-0.19-0.06,0.24-0.18-0.19-0.25
				l0.09,0.17c-0.26-0.15-0.3,0.06-0.08-0.6c-0.07,0.04-0.1,0.11-0.12-0.09c0.1,0,0.13-0.04,0.19-0.07
				c-0.07,0.01-0.01-0.12-0.2-0.12c0.11-0.13,0.26-0.11-0.16-0.33c0.11,0.02,0.17-0.05,0.26,0.03c-0.1-0.12,0.03,0.02-0.41-0.27
				c0.18-0.14,0.21-0.22-0.09-0.79c0.03-0.01,0.04-0.02,0.04,0c-0.39-0.21,0.38-0.18-0.37-0.64c0,0,0.03-0.01,0.04-0.03
				c-0.18,0.04-0.32-0.03-0.44-0.06c0.2-0.19,0.16-0.11,0-0.28c0.3,0.01-0.41,0.1-0.49-0.3c-0.08,0.02-0.26,0.1-0.36,0.11
				c0.09-0.13,0.24-0.16,0.35-0.21c-0.06-0.02-0.2-0.06-0.26,0.03c0.08,0.02,0.1,0.02-0.24,0.14c0.09-0.38-0.28-0.14-0.37-0.43
				c-0.22,0.14-0.49-0.02-0.77,0.12c0.1,0.1,0,0.51-0.14,0.07c0.11,0.06,0.02,0.15,0.06,0.22c-0.12-0.02-0.28-0.09-0.47-0.24
				c0.08,0.08,0.05,0.15-0.04,0.18c0.24-0.06-0.16,0.33-0.19,0.11c-0.04,0.11-0.27,0.31-0.11,0.53c-0.04-0.01-0.12-0.03-0.12-0.08
				c-0.53,1.09-0.28,0.7-0.68,2.74c-0.02-0.01,0.27-0.01,0.14,0.17l0-0.02c-0.2,0.03-0.15,0.13-0.16,0.2l0.26,0.02
				c-0.38,0.19,0.13,0.51-0.2,0.61c0.07,0.05,0.07,0.1,0.13,0.15c-0.14,0.05-0.19-0.05-0.15,0.36l-0.1-0.03
				c0.23,0.07-0.11,0.38,0.32,0.91c-0.19,0.02,0,0.16,0.09,0.24c-0.36-0.03,0.09,0.04,0.13,0.94c-0.01-0.11-0.02-0.19-0.01-0.19
				c-0.13-0.09-0.31,0.53,0.11,0.55c-0.01,0.01-0.01-0.02-0.05-0.05l0.01,0c-0.01-0.03-0.02-0.06-0.03-0.1
				c0.08,0.02,0.14,0.05,0.07,0.11c0.09,0.01,0.19,0.01,0.23,0.02c-0.03,0.13-0.42,0.28,0.1,0.81c-0.45,0.41-0.21,0.04,0.09,0.76
				c0.02-0.03,0.02-0.04,0.05-0.05c0.02,0.17-0.19,0.39,0.38,1.07l-0.14,0.07c0.19-0.34-0.01,0.6,0.72,0.96
				c-0.01-0.01-0.01-0.01-0.02-0.01c0.04-0.02,0.08-0.04,0.14-0.07c-0.06,0.12-0.13,0.24-0.32,0.4l0.08,0.01
				c-0.42,0.59-0.36-0.01-1.14,1.09c0.04-0.23,0.28-0.55,0.41-0.77c-0.14,0.12-0.46,0.42-0.46,0.56c0.04-0.06,0.08-0.16,0.15-0.19
				c-0.97,1.2,0.02,0.11-3.23,2.57c0.07-0.18,0.57,0.65-1.06,0.65c0.11,0.05,0,0.2-0.19,0.32l0.32-0.1
				c-0.29,0.48-0.52,0.12-0.81,0.6l0.06-0.19c-0.32,0.2-0.32,0.35-2.27,2.38l-0.01-0.09c-0.2,0.32-0.19,0.06-0.58,0.66l0.04-0.16
				c-0.54,0.38-0.57,0.85-0.91,1.28c-0.16-0.15-0.57,0.17-0.76,0.34l-0.02-0.03c-0.78,0.69,0.29,0.26-2.68,3.2
				c0.06,0,0.15,0.25-0.2,0.5c0.06-0.06-0.69,0.15-0.38,0.68c-0.57,0.29,0,0.14-2.46,3.13l-0.02-0.15
				c-0.25,1.04-0.07,0.44-1.13,2.22c-0.02-0.02-0.07-0.07-0.03-0.15c-0.15,0.22-0.63,0.82-0.33,0.88c-0.17-0.09-0.3,0.5-0.37,0.8
				l-0.1-0.11c0.04,0.03-0.07,0.38-0.31,1.13l-0.02-0.15c-0.24,0.55-0.25,0.41-1.1,2.16c0.17-0.35,0.3-0.62,0.3-0.59
				c-0.12-0.06-0.27,0.22-0.32-0.09c-0.07,0.26,0.1,0.02-0.25,1.01l0-0.03c-0.15,0.4-0.17,0.18-0.47,0.84
				c0.13,0.05,0.03,0.22,0.21,0.09c-0.03,0.04,0.02-0.06,0.04-0.2l0.01,0.01c0.04-0.09,0.09-0.21,0.15-0.35
				c-0.1,1.1-0.51,0.5-1.09,2.72c0-0.39,0.05-0.36-0.32,0.87l-0.03-0.08c-0.62,0.76-0.05,1.56-0.74,2.39
				c0.04-0.06,0.07-0.09,0.1-0.09c-0.44,1.02-0.21,0.23-1.57,5.71l0.12-0.06c-0.44,0.73-0.05,0.96,0.42,2.03
				c0.09-0.2,0.07-0.15,0.01-0.89c0.51,0.09-0.15-0.48,0.25-0.37c-0.04-0.19,0.06-0.45-0.04-0.57c0.16-0.14,0.09-0.58,0.3-0.52
				c-0.04-0.06-0.1-0.34-0.08-0.49c0.2,0.34,0.14-0.58,0.4-0.44c-0.18-0.26,0.09-0.78,0.06-1.18c0.01,0.03,0.04,0.08,0.02,0.11
				c0.07-0.2,0.16-0.24,0.24-0.37c-0.07,0.24-0.42-1.17,0.16-0.44c-0.16-0.03-0.03-0.48-0.18-0.43c0.17-0.16,0.1-0.15,0.29-0.2
				c-0.29,0.08,0.12-1.13,0.3-0.55c-0.32-0.26,0.38-1.13,0.23-2.59c0.88,0,0.24,0.25,1.16-2.47l0.13,0.18
				c0.25-1.19,0.35-1.11,2.9-6.17l-0.01,0.1c0.23-0.29-0.06-0.51,0.23-0.74c0.04,0.39,0.19-0.27,2.94-4.18
				c-0.27-0.08,0.33,0.03,0.74-1.11c0.04,0.05-0.02,0.16-0.04,0.26c0.11-0.4,0.17,0.04,0.28-0.33c-0.06,0.02-0.06-0.08-0.08-0.1
				c0.18-0.08,0.43-0.4,0.58-0.66l0.05,0.11c0.33-0.4,0.64-0.88,1.01-1.33c0.71-0.69-0.17,0.21,5.69-5.94l-0.02,0.03
				c2.21-2.02-1.88,1.71,6.95-6.21l-0.02,0.24c0.06-0.08,0.13-0.14,0.19-0.22c0.07,0.13,0.17,0.29,0.33,0.53l0.04-0.17l0.08,0.09
				l-0.08,0.04c0.09,0.08,0.08-0.02,0.09-0.1c0.03,0.05,0.06,0.09,0.09,0.1l0.02-0.24c0.05,0.08,0.02,0.19,0.11,0.21
				c0.01-0.11-0.12-0.17-0.07-0.27c0.09,0.08,0.1,0.17,0.13,0.06c0.03,0.05,0.03,0.09,0.02,0.13c0.02-0.09,0.12-0.08,0.1-0.2
				c0.1,0.1,0.16-0.09,0.23,0.11c0-0.06,0.03-0.2,0.08-0.22c0,0.28,0.18-0.04,0.25,0.23c-0.01-0.25,0.2-0.17,0.27-0.33
				c0,0.02,0,0.06-0.02,0.05c0.07,0,0.11,0.06,0.16,0.09c-0.05-0.19,0.04-0.26,0.06-0.39c0.13,0.16,0.01,0.24,0.09,0.4
				c-0.05-0.15,0.09-0.19,0.03-0.31c0.09,0.1,0.07,0.04,0.14,0.19c-0.05-0.24,0.05-0.05,0.06-0.21c0.12,0.09,0.08,0.22,0.16,0.12
				c0.14,0.36-0.03-0.09,0.61-0.31c0.03-0.01-0.16-0.26,0.04-0.26c-0.03,0-0.02,0.05-0.03,0.09l0.04-0.06
				c-0.15,0.27,0.23,0.25,0.06,0.17c-0.06,0.62,0.62,0.04,1.55-0.2c0.32,0.22,0.16,0.39,2.98,0.25l-0.01,0.19
				c0.19-0.22-0.09,0.18,0.45,0.05c-0.01,0.02-0.02,0.03-0.04,0.04c0.03,0.02-0.04,0.21,0.09,0c0.01,0.1,0.11,0.15,0.21,0.15
				c-0.03,0.18-0.09,0.08,0.38,0.28c-0.02-0.07,0.08-0.22,0.14-0.21l-0.07,0.15c0.07-0.07,0.24-0.27-0.01,0.06l0.09,0
				c-0.02,0.04-0.05,0.05-0.09,0.1c0.09-0.07,0.01,0.2,0.14,0.09c-0.9-0.13,1.8,1.11,0.78,2.39c0.02,0.01,0.21,0.09-0.01,0.06
				c0.63-0.07-0.51,0.47-1.42,1.34c0-0.04-0.02-0.09,0-0.1c-0.04,0.04-0.19,0.14-0.87,0.54c-0.06-0.22-0.22-0.13-0.26,0
				c0-0.07-0.07-0.06-0.07-0.15c-0.07,0.02-0.13,0.08-0.09,0.19c-0.19-0.23-0.23,0.27-0.37-0.06c-0.32,0.16-0.12,0.28-1.38,0.3
				c0-0.02,0.02-0.04,0.04-0.06c-0.03,0.01-0.08-0.04-0.08,0.07l0.03-0.03c0.05,0.15-0.15,0.31-0.16,0.38
				c0.07-0.29-0.17-0.08-0.06-0.45c-0.02,0.02-0.05,0.07-0.05,0.13c-0.24-0.19-0.05-0.02-0.84-0.21c0.05,0.03-0.16-0.06-0.08,0.13
				c0.05-0.07-0.32,0.12-0.16-0.04l-0.16,0l0.07,0.09c-0.17,0.09-0.1,0.27-0.34-0.18c-0.08,0.14-0.2-0.01-0.3,0.13
				c0.03-0.03,0.06-0.13,0.06-0.15l-0.07,0.11c-0.04-0.11-0.09-0.01-0.34-0.23l0.06-0.01l-0.02-0.09l-0.05,0.09
				c0.1-0.18-0.45,0.09-0.77-0.24c0.03-0.01,0.09-0.12,0.07-0.1c-0.09-0.09-0.25-0.05-0.46-0.06c0.08,0.02-0.09-0.35-0.27-0.12
				c-0.1-0.23,0.11-0.48-0.85-0.34c0.11-0.01,0.11-0.15,0.11-0.23c-0.02,0.03-0.05,0.06-0.07,0.09c0.12-0.28-0.18,0.11-0.14-0.06
				c-0.04,0-0.3-0.03-0.03-0.12c-0.21-0.12,0.04,0.07-0.19-0.35c-0.27,0.34-0.01,0.02-0.43,0.07c0.21-0.1,0.07,0.01,0.24-0.33
				c-0.13,0.05-0.01-0.13-0.19-0.06c0.03-0.04-0.04-0.05,0.05-0.13c-0.22,0.2-0.73-0.22-0.88-0.4c0.25-0.16-0.28-0.05,0.11-0.21
				c-0.08-0.02-0.19-0.01-0.27-0.03c0.05-0.07,0.12-0.05,0.18-0.07c-0.02,0.03-0.14-0.12-0.31,0.04c0.33-0.16-0.3,0.13,0.27,0.24
				l-0.18,0.11c0.2-0.18,0.2,0.21,0.06,0.3c0.08,0.19,0.17,0.32,0.5,0.06l-0.2,0.33l0.12-0.1c0.01,0.02,0.03,0.05-0.02,0.09
				c0.12-0.03,0.04-0.06,0.13-0.1c-0.03,0.16,0.22,0.12,0.08,0.31c0.1-0.07,0-0.12,0.12-0.12c-0.24,0.21,0.28,0.05-0.04,0.31
				c0.28-0.15-0.04,0.33,0.47,0.21c-0.07,0.05,0.06-0.01,0.14,0.35c0.13-0.14,0.2-0.04,0.28-0.03c-0.07,0.09-0.1,0.12-0.17,0.13
				c0.06,0.02,0.06,0.05,0.2,0.01l-0.09,0.1c0.09,0.05,0.26-0.02,0.32,0.13c0.07-0.1,0.11-0.36,0.27-0.36
				c-0.03,0.06-0.09,0.16-0.14,0.17c0.01-0.02,0.13,0.16-0.03,0.16c0.04,0.04,0.06,0.13,0.04,0.2c0.1-0.15,0.03,0.26,0.5,0.05l0,0
				c0.2-0.2,0.12,0.19,0.96,0.53c-0.11,0.05,0,0.08-0.1,0.03c0.08,0.08,0.07,0.16,0.23,0.07c-0.08,0.1,0.02,0.18,0.08,0.16
				l-0.04-0.11c0.22,0.36,0.97-0.01,1.08,0.46l0.03-0.05c0.03,0.07,0.17,0.09,0.12,0.18c0.09-0.04,0.05-0.09,0-0.13
				c0.11,0.11,0.19,0.04,0.31,0.12c-0.03-0.12,0.49-0.33,0.27,0.15c0.13-0.1,0.21,0.11,0.28,0.13l-0.07,0.04
				c0.04-0.02,0.07-0.02,0.11-0.02l-0.03-0.12c0.04,0.02,0.07,0.03,0.06,0.08c0.05-0.07-0.01-0.2,0.07-0.3
				c0.04,0.06,0.15-0.05,0.16,0.11c-0.2-0.07-0.08,0.6,0.18,0.06c-0.02,0.07,0.01,0.12,0.04,0.14c-0.01-0.05,0.02-0.18,0.21-0.24
				c-0.05-0.06-0.1-0.06,0.08-0.14c0.01,0.07-0.06,0.05-0.04,0.08c0.07-0.16,0.17,0.16,0.28,0.05c-0.01,0.03,0.2,0.47,0.35,0.01
				l-0.2,0.1l0.12-0.18c-0.06,0-0.13,0.08-0.16,0.06c0.06-0.1,0.05-0.07,0.02-0.17c0.45-0.14-0.04,0.55,0.72,0.17
				c-0.06-0.07-0.13-0.09,0.1-0.13c0.01,0.1,0.05,0.13,0.09,0.18c-0.01-0.07,0.14-0.02,0.12-0.21c0.16,0.11,0.13,0.25,0.37-0.19
				c-0.02,0.11,0.06,0.17-0.02,0.26c0.13-0.12-0.03,0.03,0.3-0.44c0.17,0.17,0.24,0.19,0.88-0.12c0.01,0.03,0.02,0.05,0,0.04
				c0.27-0.37,0.14,0.37,0.73-0.32c0,0,0.01,0.03,0.02,0.04c0-0.19,0.11-0.31,0.19-0.42c0.13,0.25,0.06,0.19,0.28,0.08
				c-0.27,0.26,0.49-0.05,0.57-0.77c0.06,0.15,0.02,0.3,0.01,0.42c0.05-0.04,0.15-0.17,0.11-0.26c-0.06,0.07-0.07,0.09,0.01-0.29
				c0.29,0.26,0.28-0.2,0.58-0.14c0-0.29,0.29-0.44,0.33-0.76c-0.09,0.07-0.45-0.3,0.02-0.16c-0.11,0.06-0.14-0.09-0.23-0.08
				c0.08-0.09,0.25-0.19,0.49-0.25c-0.11,0.02-0.16-0.06-0.12-0.15l-0.14,0.13c-0.16-0.2,0.26-0.2,0.07-0.41l0.1,0.06
				c-0.06-0.11-0.06-0.43-0.33-0.46c0.03-0.03,0.1-0.07,0.14-0.03c-0.26-0.51-0.42-1.56-2.16-2.58c-0.2,0.15-0.17-0.13-1.39-0.28
				c-0.02-0.19,0.08-0.16-0.39-0.24l0.06-0.09c-0.34,0.24-0.05,0.25-0.87,0.11c0.01-0.03,0.02-0.09,0.05-0.1
				c-0.09-0.01-0.34-0.17-0.32,0.13c0.01-0.19-0.19-0.03-0.29,0.05c0.07-0.33-0.02,0.03-1.11,0.03c0.13,0,0.23,0,0.22,0.01
				c-0.06-0.16-0.05-0.15-0.57-0.09c0.01,0-0.03-0.08,0.04-0.07c-0.03-0.01-0.07-0.02-0.14-0.02c0.02,0.14-0.05,0.12,0.02,0.23
				c-0.02-0.01,0.02-0.01,0.06-0.04l0,0.01c0.03-0.01,0.08-0.01,0.12-0.02c-0.03,0.07-0.07,0.14-0.13,0.06
				c-0.02,0.09-0.04,0.19-0.05,0.23c-0.1,0.11-0.69-0.31-1.83,0.11c0.03,0.02,0.04,0.03,0.05,0.05c-0.22,0.03-0.38-0.21-1.26,0.39
				l-0.07-0.14c-0.6,0.43-1.16,0.61,0.74-1.04c-0.02,0.07,0.02,0.1-0.05,0.16c0.23-0.04,0.55-0.49,0.49-0.56
				c0.04,0.07,0.75-0.52,1.96-1.9c0.13,0.18,0.58-0.28,0.67-0.51c-0.01,0.1,0.19-0.08,0.19,0.05c0.18-0.18,0.33-0.36,0.21-0.42
				c0.76-0.4,0.54-0.28,2.34-2.38c-0.17,0.84-0.77,1.03,1.9-1.78c0.01,0.02-0.01,0.08-0.04,0.13c0.15-0.3-0.01,0.05,0.12-0.73
				c0.07,0.34,0.45-0.35,0.5,0.13c0.04-0.07,0.07-0.17,0.01-0.2c0.09-0.06,0.15-0.06,0.12,0.07c0.25-0.33,0.07-0.3,0.32-0.56
				c0.03,0.04,0,0.1,0.01,0.17c0.06-0.2,0.12-0.4,0.37-0.6c0.01,0.03,0.06,0.04,0,0.1c0.36-0.4,0.45-0.83,0.91-1.19
				c0.04,0.27,0.19-0.1,0.34,0.01l0.17-0.27l-0.15,0.03c0.19-0.2,0.26-0.47,0.46-0.6l-0.08,0.2l0.33-0.4l-0.25,0.17
				c0.3-0.8-0.15-0.1,1.28-1.59c-0.01,0.24,0.45-0.04,0.37-0.38c-0.02,0.03,0.01,0.03-0.02,0.1c0.3-0.4,0.47-0.52,0.6-0.61
				l-0.07,0.11c0.43-0.42-0.01,0.06,1.33-1.74l-0.08,0.07c-0.16-0.23,0.38-0.26,0.46-0.57c0.27,0.1,0.02,0.12,0.78-1.1l0.01,0.1
				c0.56-0.94,0.64-0.64,1.82-2.66c-0.03,0.18-0.08,0.29,0.24-0.4c-0.12-0.02-0.1,0.03-0.25,0.09c-0.02-0.1,0.03-0.27,0.1-0.4
				c-0.06,0.27,0.16,0.09,0.27,0.02c-0.04,0-0.05-0.05-0.09-0.06c0.4-0.51-0.12-0.12,0.63-1.3c-0.01,0.11-0.08,0.24-0.1,0.35
				c0.26-0.13,0.23-0.18,0.33-0.33l-0.01,0.01l0.24-0.34l-0.08,0.08c-0.19-0.42,0.21-0.59,0.17-1.05c-0.16,0.1,0.66,0.28,1.14-3.17
				c0.04,0.06,0.09,0.12,0.1,0.18c-0.31-0.19,0.08,0.5-0.21,0.49c0.27,0.13,0.22,0.63,0.4,0.93c-0.02-0.02-0.06-0.04-0.06-0.07
				c0.01,0.18-0.06,0.25-0.08,0.37c-0.01-0.2,0.81,0.77,0.01,0.4c0.16-0.03,0.21,0.36,0.33,0.27c-0.09,0.18-0.04,0.15-0.19,0.26
				c0.25-0.16,0.3,0.92-0.07,0.53c0.36,0.03,0.06,1.08,0.73,2.11c-0.81,0.25-0.32-0.13-0.2,2.32l-0.19-0.11
				c0.3,1.16-0.27,2.08-0.47,5.84l-0.04-0.08c-0.09,0.31,0.27,0.39,0.1,0.67l-0.02-0.06c0.07-0.17-0.34,0.55,0.09,4.67
				c0.36-0.35-0.34,0.27,0.69,1.12c-0.06,0.03-0.15-0.06-0.24-0.12c0.36,0.22-0.09,0.16,0.25,0.36c0-0.06,0.09-0.04,0.11-0.05
				c0.04,0.19,0.34,0.49,0.61,0.64l-0.11,0.05c0.42,0.35,1,0.57,1.62,0.67c0.07-0.59,2.22,1.67,4.99-5.33l0,0.03
				c0.04-0.67,0.13-0.74,0.23-0.94c0.28,0.38,0.65,0.81,1.16,1.33l-0.23,0.03c0.81,0.42,1.65,1.07,2.69,0.94
				c-0.06,0.04-0.06,0.09-0.16,0.08c0.21,0.15,0.8-0.02,0.77-0.13c-0.04,0.07,1.04-0.04,2.19-1.41c0.14,0.18,0.54-0.3,0.57-0.54
				c0.01,0.1,0.17-0.1,0.19,0.02c0.15-0.19,0.22-0.42,0.1-0.44c0.49-0.56,0.66-0.38,0.84-2.92c0.26,0.71,0.42,1.13-0.05-2.34
				c0.02,0,0.05,0.05,0.08,0.1c-0.15-0.26,0.04,0.03-0.53-0.44c0.33,0.1-0.05-0.5,0.37-0.33c-0.04-0.06-0.1-0.13-0.16-0.1
				c-0.01-0.1,0.02-0.14,0.13-0.07c0.03-0.16-0.44-0.39-0.18-0.43c-0.14-0.13-0.29-0.25-0.36-0.52c0.03,0,0.06-0.04,0.09,0.04
				c-0.2-0.44-0.57-0.62-0.75-1.1c0.26,0.02-0.03-0.19,0.11-0.31c-0.06-0.07-0.13-0.14-0.2-0.2l-0.01,0.14
				c-0.14-0.2-0.36-0.3-0.44-0.5l0.17,0.1c-0.09-0.12-0.2-0.23-0.3-0.34l0.1,0.24c-0.68-0.34-0.12,0.13-1.23-1.27
				c0.22,0.02,0.03-0.42-0.3-0.34c0.03,0.01,0.03-0.01,0.09,0.02c-0.34-0.28-0.44-0.43-0.51-0.56l0.11,0.07
				c-0.15-0.32-0.06-0.01-1.52-1.14l0.06,0.07c-0.22,0.17-0.22-0.33-0.5-0.38c0.1-0.26,0.12-0.04-0.98-0.63l0.09-0.02
				c-0.81-0.41-0.58-0.53-2.33-1.46c0.16,0.01,0.26,0.05-0.34-0.2c-0.02,0.12,0.02,0.09,0.07,0.23c-0.09,0.03-0.24,0-0.35-0.06
				c0.24,0.05,0.09-0.16,0.04-0.27c0,0.04-0.05,0.06-0.06,0.09c-0.09-0.35-0.08,0.09-0.35-0.16c0.08,0.01-0.71-0.14-0.18-0.19
				c-0.18-0.09-0.48,0.03-0.58-0.17c0.09,0.01,0.21,0.07,0.3,0.07c-0.11-0.23-0.15-0.21-0.27-0.3l0.01,0.01l-0.28-0.22l0.06,0.08
				c-0.39,0.19-0.5-0.18-0.91-0.12c0.17,0.02,0.4-0.33-0.42-0.55c0.08-0.05-1.36-0.34-2.97-0.79c0.04-0.04,0.1-0.11,0.19-0.15
				c0.02,0.12,0.05,0.11-0.01-0.06c0.02,0.03,0.05-0.03,0.09,0.04c-0.08,0.01-0.07,0.02,0.04-0.01c-0.01,0-0.02,0.01-0.03,0.01
				c0.07-0.15,0.01-0.02,0.18-0.34c0.01,0.05,0.01,0.09,0.06,0.06c-0.02-0.02-0.01-0.01-0.07-0.1c0,0.01,0,0.01,0,0.02
				c-0.01,0-0.02,0.01-0.04,0.02c0-0.02,0.01-0.04,0-0.08c0.06,0.06,0.03,0.06-0.04-0.21c-0.02,0,0,0.05,0.01,0.09l0-0.06
				c0.1,0.14-0.01,0.07,0.05,0.19c0.01-0.05,0.13-0.33,0.13-0.01l-0.09-0.03c0.17,0.1-0.01,0.09,0.33,0.07
				c-0.19-0.34,0.27,0.19,0.53-0.47c0.01,0.05,0.02,0.1,0.06,0.14c-0.14-0.15,0.37-0.41,1.27-0.49c-0.04-0.13,0.23-0.34,0.42-0.09
				l0-0.17l0.08,0.17c0.02-0.24,0.06,0.24,0.25-0.18c0.01,0.03,0,0.05,0,0.07c0.02-0.07,0.1,0.21,0.05-0.06
				c0.06,0.08,0.13,0.06,0.18,0.01c0.06,0.12-0.01,0.15,0.32-0.02c-0.05-0.04-0.09-0.22-0.06-0.24l0.05,0.15
				c0-0.1-0.05-0.05-0.04-0.18c0.05,0.01,0.07,0.17,0.06,0.23l0.04-0.07c0.01,0.05,0.01,0.07,0.02,0.14c0-0.1,0.13,0.14,0.11-0.04
				c-0.32,0.51,1.19-0.68,2.13-0.34c0-0.02-0.02-0.12,0-0.11c-0.09-0.2,0.51,0.17,1.42-0.19c-0.01,0.04-0.01,0.09-0.02,0.09
				c0.04,0.12,0.14,0,0.13-0.1c-0.02,0.04,0.09,0.25,0.21,0.02c0.07,0.06,0.13-0.07,0.18,0.01c0.03-0.16,0.11,0.07,0.12-0.15
				c0,0.22,0.12,0.18,0.16,0.08c-0.01,0.07,0.04,0.07,0.02,0.17c0.05,0,0.09-0.04,0.08-0.16c0.09,0.27,0.18-0.21,0.22,0.13
				c0.14-0.12,0.28-0.2,0.41-0.25c0.06,0.21-0.06,0.14,0.49,0.09c0,0.02-0.01,0.04-0.03,0.06c0.02-0.01,0.05,0.04,0.05-0.07
				l-0.02,0.03c-0.02-0.15,0.11-0.3,0.11-0.38c-0.06,0.29,0.1,0.08,0.02,0.45c0.02-0.02,0.04-0.07,0.04-0.13
				c0.12,0.16,0,0.04,0.53,0.15c-0.03-0.03,0.1,0.04,0.05-0.14c-0.03,0.07,0.21-0.15,0.1,0.02l0.1-0.02
				c-0.03-0.1-0.05-0.09,0.04-0.25c-0.02,0.02,0,0,0.07,0.12c0.07-0.05,0.14-0.14,0.07,0.18c0.04-0.15,0.13-0.03,0.18-0.19
				c-0.02,0.04-0.03,0.13-0.03,0.15l0.04-0.12c-0.01,0.2,0.09,0.09,0.06,0.06c-0.01,0.08,0.39-0.07,0.18,0.21
				c0.12-0.02,0.27-0.19,0.37-0.12c0.01-0.31,0-0.01,0.12,0.01c-0.02,0.02-0.04,0.14-0.03,0.11c0.07,0.06,0.16-0.04,0.29-0.1
				c-0.04-0.01,0.13,0.3,0.18,0.01c0.04,0.19,0.15,0.46,0.57-0.05c-0.07,0.06-0.04,0.18-0.03,0.25c0-0.04,0.02-0.07,0.03-0.11
				c-0.04,0.3,0.1-0.19,0.09-0.01c0.04-0.03,0.18-0.09,0.04,0.09c0.14-0.01-0.02-0.02,0.18,0.21c0.11-0.42,0-0.02,0.25-0.28
				c-0.11,0.2-0.04-0.01-0.08,0.4c0.07-0.11,0.03,0.11,0.13-0.05c-0.01,0.05,0.04,0.01,0,0.13c-0.31-0.15,0.56-0.33,0.61,0.07
				c0.05-0.03,0.12-0.11,0.17-0.13c-0.02,0.08-0.06,0.12-0.09,0.16c0-0.03,0.1,0,0.17-0.19c-0.07,0.01-0.23-0.02-0.12-0.24
				c0.01,0.03-0.19,0.03-0.1-0.2c-0.12-0.11-0.17-0.13-0.32,0.23l0.05-0.38l-0.05,0.14c-0.01-0.01-0.03-0.03-0.01-0.08
				c-0.07,0.09-0.01,0.07-0.06,0.15c-0.01-0.15-0.16,0.02-0.11-0.21c-0.05,0.11,0.03,0.1-0.05,0.16c0.1-0.28-0.18,0.09-0.03-0.27
				c-0.02,0.03-0.03,0.04-0.04,0.06c0.01-0.04,0-0.08-0.12-0.06c-0.02-0.15,0.18-0.12-0.17,0.06c0.04-0.07-0.05,0.01-0.14-0.24
				c-0.05,0.18-0.11,0.13-0.17,0.15c0.03-0.11,0.04-0.15,0.08-0.19c-0.06,0-0.04-0.01-0.12,0.08l0.04-0.13
				c-0.06-0.01-0.15,0.13-0.21,0.02c-0.03,0.12-0.02,0.36-0.11,0.43c0.02-0.11,0.07-0.13,0.01-0.52c-0.01,0.05-0.11,0.29-0.07-0.04
				c-0.03,0.06-0.05-0.01-0.08,0.13c-0.02,0-0.01-0.07-0.01-0.11c-0.04,0.07-0.01-0.01-0.14,0.17l0,0c-0.12,0.24-0.4-0.44-1.4-0.5
				l-0.01,0.06c-0.02-0.06-0.1-0.07-0.07-0.16c-0.05,0.05-0.03,0.09,0,0.13c-0.06-0.09-0.11-0.03-0.18-0.08
				c0.09-0.05-0.31,0.27-0.17-0.12c-0.08,0.11-0.12-0.08-0.16-0.11l0.04-0.04c-0.02,0.03-0.04,0.02-0.06,0.02l0.01,0.12
				c-0.02-0.02-0.04-0.02-0.03-0.07c-0.03,0.08,0,0.2-0.05,0.3c-0.02-0.06-0.09,0.06-0.09-0.1c0.09,0.06,0-0.16,0.08-0.18
				c-0.13-0.1-0.02-0.17-0.19,0.12c0.02-0.09-0.01-0.17-0.03-0.15c0.04,0,0.01,0.15-0.03,0.21c-0.05-0.07-0.07,0.05-0.09,0.04
				c0.03,0.07,0.05,0.06-0.05,0.13c0-0.07,0.04-0.04,0.03-0.08c-0.05,0.15-0.09-0.17-0.16-0.07c-0.03-0.06,0.01-0.17-0.02-0.21
				c-0.06,0.17-0.12-0.23-0.18,0.17l0.12-0.08c0,0.27-0.34,0.27-0.52-0.04c0,0.07-0.08-0.01-0.1,0.19c0.01-0.43-0.31,0.38-0.19-0.14
				c-0.09,0.09,0.01-0.02-0.23,0.35c-0.09-0.31-0.25,0.03-0.33-0.23c-0.06,0.04-0.14,0.07-0.19,0.08c0.15,0.11-0.18-0.31-0.66,0.18
				c0.01-0.13,0.03-0.07,0.01-0.2c-0.01,0.56-0.2,0.08-0.48,0.01c-0.03,0.05-0.11,0.2-0.17,0.24c0.02-0.15,0.09-0.23,0.13-0.32
				c0.09,0.18-0.23-0.03-0.99,0.24c-0.06-0.05-0.14-0.19-0.21-0.45c0.03,0.11,0,0.16-0.05,0.12c0.1,0.03-0.17,0.17-0.11-0.04
				c-0.05,0.07-0.2,0.02-0.22,0.28c-0.03-0.34-0.3-0.08-0.79,0.3c-0.14-0.19-0.21-0.6-0.54,0.04c-0.14-0.21-0.31-0.03-0.48-0.12
				c0.03,0.03,0.04,0.17,0.02,0.19c-0.04-0.03-0.05,0.17-0.09-0.03l0.01,0c-0.08-0.18-0.13-0.12-0.18-0.11l0.06,0.26
				c-0.24-0.33-0.3,0.24-0.47-0.07c-0.01,0.07-0.05,0.08-0.06,0.16c-0.05-0.07-0.01-0.22-0.29-0.08c-0.05-0.46-0.29,0.1-0.54,0.36
				c-0.07-0.18-0.11,0.02-0.14,0.12c-0.07-0.16,0.04-0.23-0.36,0.15c0.03,0.1-0.1,0.13-0.26,0.1c0.07-0.03,0.13-0.05,0.13-0.03
				c0.05-0.06-0.5-0.22-0.36,0.17c-0.01-0.01,0.01-0.01,0.02-0.05l0,0.01c0.02-0.01,0.04-0.02,0.07-0.04
				c0.01,0.08,0.01,0.14-0.05,0.08c0.02,0.09,0.04,0.18,0.05,0.23c-0.07,0.01-0.33-0.37-0.55,0.17c-0.38-0.35-0.09-0.22-0.52,0.15
				c0.02,0.01,0.03,0.02,0.05,0.05c-0.08,0.02-0.43-0.11-0.68,0.42c-0.08-0.24-0.07-0.03,0.15-0.68l0.03,0.3
				c0.04-0.22,0.03-0.17-0.21-0.89c0.16,0,0.16-0.06,0.11-0.13c0.03-0.01,0.07,0.02-0.01-0.07c0.04,0.02,0.14,0.04,0.11,0.05
				c0.11-0.09,0.11-0.16-0.19-0.28c0.12-0.01,0-0.02,0.33,0.04l-0.14-0.06c0.01-0.01,0.03-0.02,0.08,0
				c-0.09-0.07-0.06-0.01-0.15-0.07c0.15,0-0.02-0.15,0.21-0.08c-0.1-0.05-0.1,0.02-0.16-0.06c0.28,0.11-0.08-0.17,0.27-0.01
				c-0.03-0.02-0.05-0.03-0.06-0.04c0.04,0.01,0.09,0.01,0.07-0.1c0.14-0.01,0.12,0.19-0.05-0.16c0.06,0.05-0.01-0.05,0.24-0.11
				c-0.17-0.3,0.07,0.16,0.09-0.1c0.01,0,0.01,0,0.03,0.01c-0.01-0.25,0.32,0.18,0.64-0.45c0,0.06,0.12,0.09,0,0.15
				c0.38-0.16,0.39-0.53,0.61-0.5c-0.12,0.16-0.03,0.02,1.52-0.93c0.02-0.61,1.16-0.3,1.56-1.41c0.01,0.02,0.07-0.01,0.15-0.05
				c0.74,0.47,0.03,0.29,2.42-0.87l-0.03,0.22c1.01-0.72,2.11-0.78,5.85-2.03l-0.07,0.06c0.33-0.02,0.29-0.38,0.62-0.32
				c-0.27,0.35,0.3-0.11,4.54-0.26c-0.07-0.26,0.11,0.27,1.17,0.06c-0.02,0.06-0.13,0.06-0.22,0.09c0.36-0.11,0.04,0.17,0.36,0.07
				c-0.04-0.04,0.04-0.09,0.05-0.12c0.14,0.11,0.48,0.19,0.74,0.2l-0.07,0.09c0.42,0.12,0.9,0.21,1.34,0.41
				c0.77,0.34-0.01-0.05,2.4,2.21c-0.12,0.04-0.09,0.33-0.19,0.18c0.01,0.35,0,0.45-0.1,0.95c-0.06-0.04-0.04,0.65-1.49,0.73
				c0.04,0.11-0.15,0.14-0.36,0.09l0.29,0.16c-0.1,0-0.22,0.06-0.46-0.07c0.17-0.1-0.62,1,0.35,1.3c-0.07,0.03-0.15,0.06-0.22,0.08
				c2.5,0.56,2.11-0.68,5.52,3.26c-0.07-0.02-0.13-0.03-0.21-0.05c0.54,0.6,0.54,1.44,0.67,2.16c-0.31-0.25,0.36-0.14-0.83,2.54
				c-0.17-0.15-0.44,0.28-0.51,0.48c-0.01-0.09-0.16,0.06-0.18-0.05c-0.13,0.15-0.26,0.29-0.15,0.36c-0.71,0.13-0.27,0.39-2.41,1.22
				c0.66-0.64,0.76-0.2-2.13-0.31c0.01-0.02,0.06-0.03,0.12-0.03c-0.29,0.02,0.05,0-0.64,0.24c0.26-0.21-0.44-0.26-0.05-0.5
				c-0.07-0.01-0.16,0.01-0.17,0.08c-0.08-0.05-0.09-0.1,0.03-0.14c-0.34-0.09-0.27,0.06-0.56-0.08c0.02-0.04,0.09-0.03,0.14-0.07
				c-0.19,0.02-0.38,0.03-0.6-0.14c0.03-0.02,0.02-0.07,0.09-0.03c-0.42-0.21-0.83-0.17-1.24-0.52c0.24-0.11-0.12-0.15-0.05-0.33
				l-0.27-0.1l0.05,0.14c-0.21-0.13-0.47-0.13-0.63-0.29l0.2,0.03l-0.43-0.2l0.2,0.19c-0.79-0.08-0.07,0.17-1.68-0.8
				c0.22-0.04-0.11-0.42-0.41-0.25c0.03,0.01,0.03-0.02,0.1-0.01c-0.42-0.18-0.57-0.3-0.68-0.4l0.12,0.03
				c-0.47-0.29,0.06,0-1.85-0.76l0.08,0.06c-0.17,0.22-0.32-0.27-0.61-0.26c0.03-0.29,0.11-0.06-1.16-0.42l0.09-0.04
				c-0.95-0.25-0.72-0.41-2.77-0.95c0.17-0.02,0.28-0.01-0.41-0.11c0.01,0.12,0.05,0.09,0.13,0.2c-0.08,0.05-0.25,0.06-0.37,0.02
				c0.25-0.01,0.05-0.18-0.04-0.27c0.01,0.04-0.04,0.07-0.04,0.1c-0.2-0.33-0.04,0.1-0.41-0.07c0.09-0.01-0.76,0.03-0.24-0.14
				c-0.22-0.05-0.48,0.14-0.64-0.04c0.1-0.01,0.23,0.02,0.33,0c-0.17-0.2-0.21-0.17-0.36-0.24l0.01,0.01l-0.35-0.15l0.08,0.06
				c-0.34,0.28-0.56-0.06-0.97,0.09c0.19-0.02,0.32-0.41-0.57-0.44c0.06-0.08-1.46-0.04-3.22-0.29c0.1-0.14,0.25-0.33-0.89-0.07
				l0.14,0.06c-0.02-0.06,0.09-0.31,0.31-0.01l-0.15,0.02c0.12,0.3,0.58,0.01,0.87,0.05c-0.26,0.18-0.15,0.39,0.66,0.34
				c-0.04,0.04,0.01,0.15-0.1,0.1c0.34,0.17,0.6-0.08,0.71,0.09l-0.03,0.01c0.32,0.06,1.73,0.25,3.5,0.61
				c-0.03,0.05,0.56,0.24,0.84,0.38l-0.02,0.03c0.1-0.17,0.39-0.05,0.62-0.02c-0.04,0.1-0.09,0.13-0.22,0.15
				c0.03-0.02,0.02,0.09,0.47,0.02l-0.09,0.12c0.27,0.07,0.59,0,0.9,0.2c0.06-0.12-0.17-0.43,0.22-0.39
				c-0.01,0.07-0.03,0.19-0.13,0.18c0.03,0,0.13-0.02,0.18,0.03l-0.18,0.07c0.09-0.02,0.48,0.15,0.11,0.1
				c0.14,0.06,0.29,0.17,0.32,0.26c0.5-0.19-0.01,0.01,1.25,0.21l0,0c0.5,0.03,0,0,1.36,0.54c-0.12,0.18,0.42,0.16,1.4,0.65
				c-0.23-0.01,0.04,0.1-0.23-0.03c0.24,0.15,0.26,0.25,0.59,0.25c-0.15,0.07,0.12,0.23,0.26,0.26l-0.14-0.17
				c0.14,0.05,0.25,0.12,0.37,0.18l-0.14,0.12c0.53,0.36,1.14,0.56,2.38,1.09c-0.01-0.45,0.31,0.69,0.44,0.32
				c0.25,0.25,0.45,0.27,0.73,0.5c-0.04-0.11,1.15,0.13,0.61,0.48c0.34,0.06,0.5,0.36,0.66,0.47l-0.18-0.04
				c0.11,0.02,0.18,0.06,0.26,0.11l-0.03-0.15c0.08,0.07,0.16,0.11,0.14,0.15c0.24-0.05,0.15-0.39,0.63,0.07
				c-0.1-0.22-0.39,0.52,0.46,0.25c-0.08,0.06,0.04,0.19,0.11,0.2c-0.13-0.04,0.06-0.2,0.58-0.08l-0.15-0.08
				c0.39-0.2,0.66,0.43,1.9,0.57c-0.11-0.12-0.12-0.09,0.13-0.21c-0.2,0.03-0.4,0.07-0.6,0.07c0.13-0.05,0.26-0.09,0.39-0.16
				c-0.18,0.01-0.38,0.05-0.48,0.03c0.19-0.08,0.16-0.06,0.09-0.16c0.9,0.02,0.42,0.7,2.06,0l-0.19,0c0.04-0.13,0.32-0.11,0.47-0.23
				c0.02,0.1,0.14,0.09,0.24,0.12c-0.03-0.05,0.38-0.17,0.33-0.37c0.51-0.1,0.34,0.09,0.89-0.75c-0.03,0.16,0.17,0.09-0.01,0.34
				c0.28-0.47-0.14,0.19,1.91-3.13c0.01,0.01,0.02,0.02,0.03,0.02c0.06-0.86,0.15,0.03,0.31-1.36L291.43,367.72z M251.34,357.09
				c0.04-0.02-0.05-0.08-0.11-0.15C251.29,356.96,251.52,357,251.34,357.09z M251.94,358.19c-0.02,0.02,0,0-0.06,0.05
				C251.82,358.23,251.7,358.11,251.94,358.19z M251.71,357.12c0.05,0.01,0,0,0.12,0.04C251.81,357.15,251.77,357.14,251.71,357.12z
				 M236.78,391.44c-0.02-0.02,0.02-0.06,0.07,0C236.82,391.44,236.8,391.44,236.78,391.44z M218.2,419.38
				c-0.04-0.08-0.08-0.09,0.05-0.19C218.25,419.24,218.22,419.19,218.2,419.38z M219.29,419.21c0.01-0.03,0.03-0.07,0.06-0.15
				C219.33,419.13,219.35,419.07,219.29,419.21z M219.44,418.63c0.01,0.04,0.09-0.07,0.17-0.14
				C219.58,418.57,219.51,418.83,219.44,418.63z M244.71,394.36c-0.01,0.03,0.04,0.06,0.07,0.1
				C244.75,394.44,244.64,394.48,244.71,394.36z M244.58,394.47c-0.02,0.01,0,0-0.05,0.01
				C244.54,394.48,244.55,394.48,244.58,394.47z M237.58,390.98c0.03,0,0.06-0.04,0.09-0.07
				C237.67,390.95,237.71,391.02,237.58,390.98z M237.71,391.08c0.01,0.01,0,0,0.02,0.04C237.72,391.11,237.72,391.1,237.71,391.08z
				 M238.47,393.58c0.02,0,0.06,0,0.05,0.01c0-0.06,0.06-0.1,0.09-0.15c-0.19,0.05-0.26-0.02-0.39-0.03c0.16-0.13,0.23-0.03,0.4-0.1
				c-0.15,0.05-0.19-0.07-0.31-0.01c0.1-0.09,0.04-0.06,0.19-0.14c-0.24,0.06-0.05-0.04-0.21-0.04c0.09-0.11,0.21-0.09,0.12-0.15
				c0.36-0.15-0.09,0.03-0.32-0.5c-0.01-0.02-0.24,0.16-0.26-0.01c0,0.02,0.05,0.01,0.09,0.02l-0.06-0.03
				c0.25-0.08,0.19,0.14,0.21-0.17l-0.04,0.11c0.2-0.16,0.36,0.05,0.16-0.32c-0.37,0.19,0.28-0.08-0.65-3.64l0.18-0.04
				c-0.29-0.17,0.23,0.14-0.09-0.41c0.02,0,0.04,0.01,0.05,0.02c0.01-0.04,0.21-0.04-0.03-0.08c0.09-0.05,0.1-0.15,0.06-0.24
				c0.18-0.04,0.11,0.05,0.08-0.45c-0.05,0.05-0.24,0.03-0.25-0.02l0.16-0.01c-0.09-0.03-0.35-0.07,0.05-0.02l-0.05-0.08
				c0.05,0,0.07,0.02,0.13,0.03c-0.1-0.04,0.17-0.11,0-0.17c0.33,0.16-0.02,0,0.38-1.54c0-0.03-0.09-0.35,0.1-0.06
				c-0.06-0.24,0.15-0.09,0.15-0.25c0.21,0.31,0.23-0.12,0.36,0.11c-0.15-0.31,0.47,0.03,1.24,0.52c-0.04,0.01-0.08,0.04-0.09,0.02
				c-0.08,0.1,0.07,0.13,0.16,0.08c-0.11,0.04-0.08,0.17-0.08,0.19c0.07,0,0.04,0.12,0.15,0.04c-0.03,0.09,0.11,0.12,0.05,0.21
				c0.16-0.01-0.03,0.15,0.18,0.11c-0.22,0.05-0.14,0.18-0.03,0.21c-0.07,0-0.06,0.06-0.16,0.06c0.02,0.06,0.06,0.1,0.18,0.07
				c-0.25,0.15,0.25,0.2-0.09,0.31c0.15,0.28,0.24,0.13,0.25,1.22c-0.02,0-0.04-0.02-0.06-0.03c0.01,0.03-0.03,0.07,0.07,0.07
				l-0.03-0.03c0.15-0.05,0.31,0.12,0.39,0.13c-0.29-0.06-0.08,0.15-0.45,0.06c0.02,0.02,0.07,0.05,0.13,0.04
				c-0.18,0.21-0.03,0.04-0.16,0.75c0.03-0.04-0.04,0.14,0.14,0.06c-0.07-0.04,0.15,0.27-0.02,0.14l0.02,0.14
				c0.09-0.04,0.09-0.07,0.25,0.04c-0.13,0.08-0.06,0.14-0.31,0.2c0.15,0.06,0.02,0.18,0.17,0.25c-0.04-0.02-0.13-0.04-0.15-0.04
				l0.12,0.05c-0.1,0.05,0,0.07-0.17,0.32c-0.02-0.01,0.02-0.1-0.11-0.03l0.1,0.04c-0.2-0.07,0.18,0.41-0.09,0.69
				c-0.01-0.02-0.14-0.06-0.11-0.05c-0.06,0.09,0.01,0.23,0.04,0.41c0-0.07-0.32,0.14-0.05,0.25c-0.21,0.07-0.51,0.18-0.09,0.79
				c-0.05-0.1-0.17-0.07-0.24-0.06c0.03,0.01,0.07,0.03,0.1,0.04c-0.3-0.06,0.16,0.14-0.01,0.13c0.01,0.04,0.05,0.26-0.1,0.04
				c-0.05,0.2,0.05-0.04-0.27,0.23c0.4,0.17,0.02,0.01,0.2,0.36c-0.16-0.16,0-0.06-0.38-0.14c0.09,0.1-0.12,0.04,0.01,0.18
				c-0.05-0.02-0.03,0.05-0.13-0.02c0.48,0.22-0.29,0.07,0.16,0.81c-0.09,0.02-0.17-0.26-0.23,0.04c-0.28-0.08-0.28-0.24-0.3,0.33
				c-0.1-0.25-0.15,0.29-0.08,0.07l-0.24,0c0.08-0.04,0.18-0.03,0.21-0.1c-0.11,0-0.17,0.11-0.26,0.07c0.08-0.08,0.17-0.1,0.06-0.12
				c0.05-0.03,0.09-0.03,0.13-0.02c-0.08-0.02-0.07-0.1-0.2-0.08c0.11-0.09-0.09-0.14,0.12-0.21c-0.05,0-0.19-0.02-0.21-0.05
				c0.28-0.02-0.04-0.16,0.23-0.23C238.56,393.82,238.63,393.64,238.47,393.58z M239.11,394.51c0.07-0.07,0.02-0.02,0.23-0.21
				c-0.04,0-0.07,0.01-0.1,0.02c0.05-0.06,0.13-0.1,0.24-0.01l-0.11-0.16c0.04-0.02,0.14,0.13,0.25,0.07
				C239.64,394.33,239.59,394.51,239.11,394.51z M239.63,394.22c0.03-0.03,0.06,0.02,0.01-0.1c0.03,0.03,0.13,0.06,0.09,0.08
				c0.02,0,0.02-0.01,0.03-0.01c-0.03,0.04-0.05,0.07-0.09,0.12C239.65,394.26,239.64,394.24,239.63,394.22z M262.37,377.34
				c0,0.03,0,0.07,0,0.14C262.36,377.42,262.37,377.48,262.37,377.34z M262.32,378.02c0.03,0.05-0.04-0.35,0.12-0.17
				C262.41,377.82,262.37,377.95,262.32,378.02z M264.5,364.37c-0.05,0-0.07,0.04-0.08-0.08
				C264.43,364.33,264.47,364.34,264.5,364.37z M263.9,363.65c-0.01-0.03-0.02-0.05-0.02-0.07
				C263.88,363.56,263.93,363.59,263.9,363.65z M262.81,365.39c-0.01,0-0.01,0-0.02,0c0-0.01,0.01-0.02,0.01-0.03
				C262.8,365.37,262.8,365.38,262.81,365.39z M262.42,367.61c0.01,0,0,0.01,0,0.01C262.42,367.62,262.42,367.61,262.42,367.61z
				 M263.34,365.57c-0.25,0.15-0.22,0.36,0.57,0.41c-0.05,0.03-0.01,0.15-0.11,0.09c0.29,0.21,0.57,0.01,0.64,0.2l-0.03,0
				c0.46,0.18,1.33,0.56,3.07,1.28c-0.04,0.04,0.46,0.35,0.67,0.55l-0.02,0.02c0.14-0.13,0.37,0.04,0.58,0.11
				c-0.06,0.09-0.12,0.11-0.24,0.1c0.04-0.01,0,0.09,0.43,0.12l-0.11,0.09c0.23,0.12,0.54,0.13,0.79,0.38
				c0.08-0.1-0.05-0.44,0.3-0.33c-0.02,0.07-0.07,0.17-0.17,0.15c0.03,0.01,0.12,0.01,0.16,0.06l-0.18,0.03
				c0.09-0.01,0.41,0.24,0.08,0.12c0.11,0.09,0.23,0.22,0.23,0.32c0.5-0.09-0.01,0,1.1,0.45l0,0c0.46,0.12,0,0,1.13,0.79
				c-0.15,0.14,0.33,0.23,1.12,0.91c-0.21-0.06,0.01,0.1-0.2-0.08c0.18,0.19,0.17,0.29,0.47,0.37c-0.15,0.04,0.05,0.25,0.16,0.3
				l-0.08-0.19c0.12,0.07,0.2,0.17,0.29,0.26l-0.16,0.08c0.37,0.47,0.86,0.78,1.77,1.64c0.18-0.4-0.01,0.7,0.25,0.43
				c0.1,0.3,0.28,0.39,0.39,0.7c0.02-0.13,0.88,0.59,0.24,0.64c0.24,0.19,0.15,0.51,0.19,0.65l-0.1-0.11
				c0.06,0.07,0.09,0.13,0.11,0.2l0.08-0.12c0.01,0.09,0.04,0.15-0.01,0.17c0.17,0.11,0.39-0.19,0.36,0.41
				c-0.06-0.35-0.13,0.05-0.25-0.17c0.03,0.05,0.07,0.17,0,0.16c-0.03,0.36,0.21,0.1,0.31,0.45c-0.1-0.02-0.14,0.12-0.11,0.17
				c-0.05-0.1,0.22-0.1,0.33,0.38l0.01-0.14c0.3,0.23,0.02,0.61-0.14,1.61c0.12-0.08,0.1-0.1,0.2,0.12
				c-0.01-0.16-0.04-0.33-0.08-0.49l0.18,0.31c0-0.15-0.08-0.31-0.06-0.4c0.1,0.15,0.07,0.14,0.17,0.06
				c-0.03,0.78-0.49,0.24-0.41,1.68l0.08-0.13c0.1,0.08-0.05,0.28-0.04,0.43c-0.09-0.04-0.15,0.04-0.23,0.08
				c0.07,0.02-0.11,0.3,0.06,0.41c-0.35,0.33-0.25,0.07-0.33,0.93c-0.04-0.15-0.18-0.05-0.13-0.28c-0.26,0.94,0.12,0.04-2.03,2.15
				c0-0.03-0.02-0.05,0.02-0.05c-0.65,0.26,0,0.11-1.05,0.05l0.05-0.02c-0.14-0.12-0.42,0.07-0.71-0.05c0,0,0.02-0.02,0.02-0.05
				c-0.24,0.1-0.64-0.03-0.92-0.16c0.04-0.05,0.07-0.07,0.09-0.1c-0.09,0.06-0.29,0.14-0.33-0.35c0.51,0.12-0.35-0.48-1.48-1.57
				c0.18,0.1,0.37,0.29,0.53,0.39c-0.08-0.12-0.25-0.41-0.37-0.44c0.04,0.04,0.12,0.09,0.12,0.15c-0.5-0.59-0.18,0.02-0.19-2.03
				c-0.02,0.14-2.29-0.43-1,1.73c-0.07-0.06-0.17-0.22-0.26-0.03c0.12-0.15,0.13,0.16,0.22,0.13c-0.11,0.2-0.31,0.41-0.55,0.47
				c0.12-0.02,0.13,0.14,0.05,0.3l0.19-0.21c0.06,0.46-0.33,0.3-0.29,0.77l-0.06-0.16c-0.16,0.21,0.02,0.4-0.68,2.62l-0.05-0.07
				c-0.04,0.31-0.12,0.09-0.23,0.66l-0.02-0.14c-0.29,0.95-1.05,1.74-3.87,1.33c0.03,0.04,0.02-0.61-0.47-0.33
				c0.18-0.07-0.7-0.76-0.6-2.91l0.1,0.09c-0.34-0.8-0.23-0.28-0.13-2.03c0.03,0.01,0.09,0.03,0.09,0.1
				c0.02-0.22,0.22-0.84-0.08-0.78c0.19,0.01,0.07-0.48,0.01-0.74l0.13,0.05c-0.05-0.01-0.1-0.31-0.15-0.99l0.08,0.12
				c-0.02-0.28,0.09-0.58,0.25-2.05c-0.04,0.33-0.07,0.59-0.08,0.56c0.13,0.01,0.18-0.25,0.33-0.02c0.09-0.18-0.29-0.47,0.05-1.67
				c-0.14,0-0.11-0.17-0.23-0.02c0.01-0.04,0,0.06,0.02,0.17c0,0-0.01-0.01-0.01-0.01c0,0.09-0.01,0.2-0.02,0.33
				c-0.13-0.06,0.19-0.23-0.23-0.57c0.44-0.4,0.39-0.38,0.31-1.95c0.13,0.31,0.07,0.3-0.01-0.79l0.06,0.05
				c0.29-0.8-0.5-1.26-0.18-2.14c-0.02,0.06-0.03,0.09-0.06,0.11c0.03-0.14-0.21-1.49-0.44-4.25c-0.16,0.15,0.28-0.19,0.1-0.07
				l0.05-0.02c-0.11,0.13-0.06,0.02-0.16,0.11c0.06-0.01,0.29-0.02,0.02,0.1l0.01-0.08c-0.01,0.17-0.1,0.36,0.46,0.49
				c-0.04,0.03-0.09,0.06-0.12,0.1c0.07-0.16,0.4,0.35,0.78,0.98l0.15-0.07l-0.13,0.13c0.09,0,0.25-0.24,0.04,0.1l0.16,0
				c-0.03,0.02-0.04,0.02-0.06,0.03c0.07-0.02-0.16,0.16,0.07,0.01c-0.06,0.07-0.03,0.12,0.03,0.13c-0.06,0.07-0.17,0.06,0.09,0.21
				c0.02-0.05,0.18-0.16,0.2-0.15l-0.13,0.1c0.09-0.05,0.04-0.06,0.15-0.1c0,0.04-0.14,0.13-0.19,0.14l0.07,0
				c-0.04,0.03-0.06,0.04-0.12,0.07c0.09-0.05-0.1,0.15,0.06,0.06c-0.29,0.13,0.02-0.02,0.61,0.73l0.16-0.01
				c0,0.04-0.09,0.03-0.14,0.1c0.23-0.04,0.05,0.13,0.21,0.12c-0.29,0.25,0.13,0.16-0.04,0.35c0.01-0.01,0.19-0.13,0.03,0.02
				c0.2-0.42,0.01,0.43,0.62,0.76c-0.04,0.01-0.08,0.03-0.09,0.02c-0.17,0.16,0.13-0.01,0.21,0.31c0.15-0.05-0.03,0.1,0.17,0.01
				c-0.2,0.1-0.13,0.15-0.02,0.13c-0.07,0.02-0.05,0.05-0.14,0.09c0.02,0.03,0.07,0.04,0.17-0.02c-0.22,0.17,0.25,0.02-0.05,0.2
				c0.15,0.04,0.27,0.08,0.35,0.14c-0.15,0.13-0.17,0.01,0.09,0.34c-0.02,0.01-0.04,0.01-0.06,0.01c0.01,0.01-0.02,0.05,0.08,0
				l-0.03,0c0.13-0.08,0.31-0.07,0.38-0.1c-0.28,0.09-0.04,0.1-0.39,0.21c0.02,0,0.07-0.01,0.13-0.04c-0.09,0.13-0.05,0,0.05,0.39
				c0.02-0.03-0.01,0.08,0.15-0.03c-0.07,0.02,0.22,0.06,0.02,0.07l0.06,0.05c0.01-0.04,0.36-0.2,0.15,0
				c0.08,0.03,0.17,0.02-0.14,0.12c0.15-0.04,0.07,0.06,0.23,0.03c-0.04,0.01-0.13,0.04-0.15,0.05l0.12-0.03
				c-0.3,0.05,0.26,0.18-0.15,0.26c0.06,0.06,0.27,0.08,0.25,0.17c0.28-0.13,0.01-0.01,0.04,0.07c-0.02,0-0.14,0.04-0.11,0.03
				c-0.02,0.06,0.1,0.08,0.2,0.13c-0.06,0.03-0.11,0-0.09,0.14c0.07-0.01,0.06,0.01,0.15-0.03c-0.21,0.16-0.21,0.39,0.28,0.31
				c-0.08-0.01-0.18,0.06-0.23,0.09l0.11-0.03c-0.28,0.11,0.22-0.02,0.05,0.05c0.05,0.02,0.15,0.06-0.06,0.06
				c0.07,0.08,0-0.01-0.11,0.2c0.42-0.13,0.02-0.01,0.36,0.02c-0.23,0.03,0-0.03-0.39,0.13c0.12-0.01-0.08,0.07,0.1,0.05
				c-0.05,0.01,0,0.03-0.12,0.05c-0.18-0.37,0.44,0.41,0.21,0.38c0.05,0.02,0.15,0.02,0.2,0.04c-0.08,0.03-0.13,0.01-0.19,0.02
				c0.42,0.1,0.01-0.07,0.4-0.16c-0.02,0.02-0.1-0.11,0.13-0.15c0.04-0.14,0.02-0.15-0.35-0.08l0.35-0.14l-0.15,0.03
				c0-0.01,0.01-0.03,0.07-0.04c-0.11,0-0.06,0.02-0.16,0.03c0.13-0.07-0.09-0.08,0.14-0.16c-0.12,0.02-0.08,0.06-0.16,0.04
				c0.29-0.07-0.15-0.07,0.23-0.14c-0.26,0.02,0.06,0.02-0.06-0.09c0.12-0.07,0.19,0.05-0.12-0.07c0.07,0-0.03-0.03,0.15-0.19
				c-0.18,0.05-0.16-0.01-0.2-0.03c0.11-0.03,0.15-0.04,0.2-0.04c-0.04-0.04-0.01-0.02-0.13-0.03l0.13-0.03
				c-0.02-0.04-0.18-0.03-0.11-0.11c-0.12,0.04-0.33,0.15-0.43,0.12c0.1-0.04,0.07,0.02,0.47-0.23c-0.04,0.01-0.3,0.06,0-0.06
				c-0.07,0.01-0.01-0.04-0.15,0.01c0-0.01,0.06-0.04,0.1-0.05c-0.07,0.01,0-0.02-0.21-0.01l0,0c-0.24,0.02,0.2-0.45-0.09-1.06
				l-0.06,0.02c0.05-0.04,0.02-0.09,0.12-0.12c-0.06-0.01-0.09,0.02-0.12,0.06c0.06-0.08-0.03-0.08,0-0.15
				c-0.1,0.04-0.01,0-0.25,0.02c0.07-0.06,0.2-0.07,0.11-0.09c0.04-0.05,0.14-0.07,0.19-0.07c-0.11,0.05-0.01-0.13,0.1-0.14
				c-0.03,0-0.04-0.02-0.04-0.03l-0.1,0.06c0.01-0.02,0.01-0.03,0.05-0.05c-0.08,0.02-0.18,0.09-0.29,0.1
				c0.05-0.04-0.08-0.03,0.06-0.1c-0.02,0.08,0.14-0.07,0.19-0.03c0.05-0.14,0.14-0.08-0.17-0.06c0.08-0.03,0.15-0.08,0.13-0.08
				c0.01,0.02-0.13,0.07-0.2,0.08c0.05-0.06-0.06-0.02-0.07-0.04c-0.05,0.05-0.03,0.06-0.14,0.03c0.06-0.03,0.05,0,0.08-0.02
				c-0.15,0.04,0.12-0.13,0.01-0.13c0.05-0.04,0.15-0.07,0.18-0.1c-0.14-0.01,0.09-0.16-0.22-0.04l0.11,0.04
				c-0.29,0.15-0.33-0.16-0.13-0.34c-0.07,0.03-0.02-0.05-0.2,0.02c0.39-0.19-0.44-0.02,0.06-0.18c-0.11-0.02,0.02,0-0.39,0.01
				c0.25-0.19-0.1-0.14,0.11-0.31c-0.05-0.02-0.1-0.06-0.13-0.09c-0.1,0.12,0.2-0.34-0.35-0.34c0.12-0.05,0.07-0.01,0.18-0.08
				c-0.03-0.01-0.18,0.11-0.23,0.08c0.1-0.1,0.48-0.34,0.09-0.39c-0.05,0-0.21,0.02-0.26,0c0.14-0.05,0.23-0.04,0.32-0.06
				c-0.39,0.15,0.32-0.46-0.51-0.5c0.04-0.02,0.12-0.06,0.19-0.1c-0.06,0.03-0.12,0.02-0.18,0.1c-0.02-0.03,0.07-0.12,0.38-0.37
				c-0.09,0.06-0.14,0.07-0.12,0.02c0.02,0.07-0.2-0.04,0-0.09c-0.07,0-0.08-0.12-0.3-0.02c0.17-0.02,0.03-0.35-0.44-0.4
				c0.11-0.15,0.5-0.4-0.15-0.36c0.16-0.19-0.04-0.22,0.01-0.38c-0.02,0.04-0.14,0.1-0.17,0.1c0.02-0.05-0.16,0.04,0-0.08l0,0.01
				c0.15-0.13,0.08-0.14,0.06-0.17l-0.21,0.16c0.25-0.31-0.26-0.11-0.02-0.36c-0.07,0.02-0.08,0-0.15,0.02
				c0.01-0.04,0.21-0.09,0.02-0.24c0.12-0.09,0.16-0.11-0.3,0.13c0.03-0.07,0.28-0.15,0-0.31c0.02-0.02,0.07-0.06,0.09-0.05
				c-0.02-0.03,0.02-0.2-0.2,0c0.14-0.12-0.04-0.07-0.13-0.05l0.11-0.09c-0.16,0.09-0.2,0.12-0.26,0.11
				c0.01-0.02,0.08-0.08,0.06-0.05c-0.04-0.22-0.21,0.2-0.09-0.19c-0.08,0.07-0.14-0.03-0.12-0.15c0.03,0.04,0.06,0.08,0.05,0.08
				c0.01,0.04,0.12-0.49-0.19-0.19c0-0.01,0.01,0.01,0.05,0l-0.01,0.01c0.01,0.01,0.03,0.02,0.04,0.03
				c-0.06,0.04-0.12,0.06-0.08-0.01c-0.26,0.13-0.14,0.24-0.4-0.21c0.03-0.09,0.14-0.1,0.08-0.12c0.22-0.32,0.05-0.04-0.26-0.21
				c-0.01,0.02-0.01,0.03-0.03,0.06c-0.01-0.04,0-0.42-0.43-0.36l0.1-0.12c-0.22,0.25-0.16-0.12-0.27-0.05
				c0-0.02-0.03-0.09-0.02-0.14l-0.15,0.05c0.11-0.18,0.22-0.45-0.09-0.11c0.05-0.18,0.07-0.04-0.01-0.49
				c0.01,0.01,0.03,0.03,0.04,0.04l-0.06-0.22c0.05,0.07,0.07,0.17,0.13,0.19c-0.03-0.1-0.13-0.16-0.12-0.24
				c0.08,0.07,0.12,0.15,0.1,0.06c0.04,0.05,0.05,0.09,0.05,0.12c-0.01-0.08,0.06-0.07,0.01-0.19c0.1,0.1,0.08-0.09,0.19,0.1
				c-0.02-0.05-0.04-0.18-0.02-0.2c0.05,0.23,0.04-0.09,0.23,0.21c-0.02-0.04-0.02-0.07-0.02-0.09
				C262.89,365.61,263.15,365.52,263.34,365.57z M263.33,376.91c0.03,0.01,0,0,0.07,0.04C263.4,377.02,263.32,377.18,263.33,376.91z
				 M263.22,368.14c0.03-0.02,0.12-0.12,0.02,0.03C263.23,368.16,263.23,368.15,263.22,368.14z M263.78,367.43
				c0.02-0.02,0.04-0.03,0.06-0.04C263.86,367.38,263.83,367.42,263.78,367.43z M263,365.37c0-0.06,0.01-0.1-0.04-0.17
				c0.08,0.08,0.06,0.04,0.14,0.15C263.07,365.36,263.04,365.37,263,365.37z M263.25,365.33
				C263.24,365.29,263.22,365.28,263.25,365.33C263.36,365.41,262.92,365.12,263.25,365.33z M263.22,365.18c-0.01,0-0.01,0-0.02,0
				C263.19,365.15,263.19,365.14,263.22,365.18z M262.88,365.15C262.87,365.15,262.87,365.15,262.88,365.15
				c0.03-0.14,0.02,0.29-0.02-0.03C262.86,365.13,262.87,365.14,262.88,365.15z M271.14,356.84c-0.03,0.03-0.02-0.02-0.15,0.09
				C270.99,356.92,271.04,356.81,271.14,356.84z M257.9,351.61c-0.08-0.03-0.12-0.01-0.1-0.17
				C257.84,351.47,257.79,351.46,257.9,351.61z M258.27,350.22c0.04,0.02,0.02-0.13,0.03-0.23
				C258.25,349.94,258.51,350.33,258.27,350.22z M258.51,350.59c0.03,0.06,0,0,0.06,0.15C258.56,350.71,258.54,350.67,258.51,350.59
				z M261.33,358.71c0.06-0.23,0.01-0.19,0.13-0.33c-0.2,0.22-0.52-1.03-0.05-0.64c-0.39,0.03-0.35-1.32-1.35-2.27
				c0.68-0.54,0.37,0.05-0.73-2.64l0.22,0.05c-0.64-1.03-0.46-1.07-2.2-6.46l0.06,0.08c-0.03-0.36-0.39-0.32-0.34-0.68l0.04,0.06
				c0-0.61-0.44-0.15-0.64-4.39l0.15,0c-0.05-0.02-0.13-0.17,0.03-0.57c-0.23,0.06,0.2-0.11,0.27-1.1c0.05,0.04,0.02,0.14,0.03,0.22
				c-0.01-0.35,0.17,0.02,0.17-0.28c-0.05,0.01-0.07-0.06-0.09-0.09c0.15-0.07,0.34-0.29,0.5-0.45l0.03,0.11
				c0.3-0.2,0.67-0.4,1.14-0.4c-0.05-0.06,0.09-0.41,0.12-0.09c0.08-0.1,0.18-0.33,0.06,0.06c1.19,0.42,1.83,0.69,4.35,8.01l-0.02,0
				c2.39,0.01-3.54-2.51,6.74-8.56l-0.15,0.17c0.87-0.25,1.78,0.05,2.64,0.14c-0.06,0.02-0.08,0.07-0.16,0.04
				c0.14,0.16,0.63,0.21,0.67,0.13c-0.06,0.08,0.76,0.34,2.21,1.2c-0.16,0.15,0.29,0.51,0.49,0.6c-0.09-0.01,0.06,0.18-0.06,0.18
				c0.15,0.17,0.32,0.3,0.38,0.21c0.16,0.81,0.37,0.38,1.37,2.76c-0.78-0.5-0.06-1.16,0.07,2.45c-0.02,0-0.04-0.06-0.05-0.12
				c0.08,0.3-0.02-0.05,0.37,0.63c-0.26-0.22-0.16,0.53-0.48,0.18c0.01,0.07,0.04,0.17,0.11,0.16c-0.04,0.1-0.08,0.12-0.14,0.01
				c-0.01,0.39,0.12,0.27,0.04,0.61c-0.05-0.01-0.05-0.09-0.1-0.13c0.05,0.19,0.11,0.39-0.02,0.66c-0.02-0.03-0.07,0-0.04-0.09
				c-0.13,0.48-0.02,0.92-0.35,1.39c-0.13-0.24-0.14,0.14-0.32,0.09c-0.02,0.1-0.06,0.19-0.09,0.29l0.14-0.07
				c-0.13,0.23-0.11,0.51-0.29,0.67l0.03-0.21l-0.22,0.45l0.21-0.21c-0.13,0.83,0.19,0.09-1.04,1.7c0-0.23-0.44,0.05-0.36,0.38
				c0.02-0.03-0.01-0.03,0.02-0.1c-0.31,0.37-0.47,0.5-0.6,0.58l0.07-0.1c-0.43,0.39,0.03-0.05-1.47,1.57l0.09-0.06
				c0.12,0.25-0.4,0.19-0.54,0.47c-0.25-0.15,0-0.12-1.01,0.88l0.02-0.1c-0.79,0.72-0.75,0.46-2.56,1.88
				c0.1-0.16,0.19-0.24-0.37,0.27c0.1,0.07,0.1,0.01,0.26,0.01c-0.03,0.03,0.02,0.1-0.21,0.25c0.03-0.11-0.17-0.07-0.28-0.06
				c0.04,0.02,0.03,0.07,0.06,0.1c-0.15,0-0.16,0.04-0.15,0.09c-0.04,0-0.08,0-0.11-0.01l0.03-0.08c-0.89,0.07-1.04,0.98-2.02,1.06
				c0.07-0.01,0.1-0.01,0.13,0.01c-0.23,0.06-0.52,0.37-2.58,1.28c0.07-0.01,0.08-0.08,0.17-0.03c-0.04-0.06-0.09-0.05-0.13-0.03
				c0.1-0.04,0.04-0.09,0.1-0.14c0.03,0.09-0.2-0.34,0.15-0.12c-0.09-0.1,0.11-0.09,0.14-0.12l0.04,0.05
				c-0.02-0.03-0.02-0.04-0.01-0.06l-0.12-0.02c0.03-0.01,0.03-0.03,0.08-0.01c-0.07-0.04-0.19-0.05-0.28-0.12
				c0.07-0.01-0.04-0.1,0.11-0.05c-0.08,0.06,0.15,0.04,0.16,0.11c0.12-0.09,0.16,0.02-0.08-0.19c0.08,0.04,0.17,0.03,0.15,0.02
				c-0.01,0.03-0.15-0.03-0.2-0.08c0.08-0.03-0.03-0.07-0.02-0.09c-0.07,0.01-0.07,0.03-0.12-0.08c0.07,0.02,0.04,0.05,0.07,0.04
				c-0.14-0.09,0.18-0.04,0.1-0.12c0.06-0.01,0.16,0.05,0.2,0.04c-0.1-0.12,0.19-0.08-0.12-0.21l0.05,0.13
				c-0.27-0.07-0.15-0.39,0.17-0.43c-0.07-0.02,0.03-0.07-0.16-0.14c0.41,0.13-0.29-0.38,0.18-0.13c0.01-0.02,0.03-0.04-0.03-0.06
				c0,0.04,0.05-0.15-0.24-0.25c0.32,0.02,0.05-0.22,0.32-0.21c-0.01-0.06-0.02-0.14-0.01-0.19c-0.15,0.08,0.36-0.1,0.04-0.62
				c0.11,0.06,0.05,0.05,0.18,0.08c-0.51-0.21,0-0.21,0.16-0.4c-0.04-0.05-0.15-0.17-0.16-0.24c0.13,0.08,0.18,0.16,0.25,0.23
				c-0.19-0.31-0.17,0.01,0.13-0.9c0.04,0.02,0.11,0.05,0.18,0.07c-0.05-0.03-0.09-0.07-0.17-0.08c0.03-0.04,0.16-0.05,0.51,0.02
				c-0.11-0.02-0.14-0.07-0.09-0.09c-0.06,0.07-0.08-0.21,0.08-0.07c-0.04-0.06,0.06-0.17-0.16-0.29c0.3,0.13,0.2-0.17,0.09-0.75
				c0.21-0.02,0.62,0.13,0.23-0.44c0.25,0,0.17-0.22,0.34-0.3c-0.04,0.01-0.16-0.05-0.18-0.08c0.05-0.01-0.12-0.12,0.07-0.06
				l-0.01,0.01c0.2,0.04,0.16-0.03,0.18-0.08l-0.25-0.09c0.4,0-0.04-0.35,0.31-0.31c-0.05-0.05-0.04-0.08-0.1-0.13
				c0.07,0,0.19,0.12,0.22-0.16c0.42,0.23,0.07-0.26,0.02-0.58c0.18,0.05,0.05-0.09-0.01-0.17l0.13,0.05
				c-0.08-0.06-0.18-0.17-0.03-0.39c-0.1-0.03-0.05-0.15,0.08-0.24c-0.02,0.06-0.05,0.11-0.06,0.11c-0.01,0.06,0.5-0.21,0.1-0.34
				c0.01-0.01,0,0.01,0.02,0.04l-0.01-0.01c0,0.02-0.01,0.04-0.01,0.07c-0.06-0.04-0.11-0.08-0.03-0.09
				c-0.26-0.18-0.25,0.01,0.02-0.58c0.1-0.03,0.15,0.08,0.15-0.01c0.4,0.01,0.06,0.02,0.1-0.42c-0.03,0.01-0.04,0.01-0.07,0
				c0.04-0.06,0.37-0.21,0.18-0.7l0.15,0.03c-0.34-0.09,0.06-0.24-0.05-0.33c0.01-0.01,0.07-0.08,0.12-0.11l-0.1-0.13
				c0.3,0.01,0.71-0.07-0.68-0.35l0.12,0.1l-0.11-0.01l0.01-0.05c-0.11,0-0.03,0.04,0.03,0.08c-0.06-0.01-0.1-0.02-0.13-0.01
				l0.19,0.12c-0.09-0.02-0.16-0.08-0.23-0.05c0.09,0.06,0.2,0.03,0.25,0.1c-0.11,0-0.19-0.04-0.11,0.02
				c-0.06-0.01-0.09-0.03-0.11-0.05c0.06,0.05,0,0.09,0.12,0.14c-0.13-0.01,0,0.11-0.21,0.04c0.05,0.02,0.15,0.1,0.15,0.13
				c-0.24-0.13-0.05,0.1-0.31,0c0.22,0.11,0.06,0.16,0.16,0.27c-0.02-0.01-0.05-0.03-0.04-0.03c-0.03,0.03-0.1,0.02-0.15,0.03
				c0.19,0.06,0.21,0.13,0.32,0.2c-0.2-0.01-0.21-0.09-0.39-0.13c0.15,0.04,0.13,0.12,0.26,0.15c-0.12,0-0.06,0.01-0.22-0.02
				c0.23,0.08,0.03,0.04,0.16,0.12c-0.13,0.01-0.22-0.05-0.17,0.02c-0.33-0.07,0.04,0.05,0.09,0.42c-0.21-0.07,0.3,0.17,0.11,0.07
				l0.04,0.04c-0.17-0.03-0.05-0.05-0.17-0.09c0.03,0.05,0.14,0.3-0.09,0.09l0.08-0.04c-0.18,0.08-0.37,0.14-0.27,0.85
				c-0.4-0.09-0.03-0.16-0.69,1.57l0.14,0.1l-0.18-0.03c0.18,0.14-0.24-0.08,0.01,0.29c-0.02-0.01-0.04-0.02-0.05-0.03
				c-0.01,0.03-0.21-0.03,0.02,0.06c-0.1,0-0.12,0.07-0.1,0.13c-0.13-0.01-0.13-0.09-0.15,0.27c0.06-0.02,0.23,0.04,0.24,0.08
				l-0.16-0.04c0.09,0.05,0.07-0.01,0.17,0.06c-0.03,0.03-0.19-0.03-0.23-0.07l0.04,0.06c-0.05-0.01-0.07-0.03-0.13-0.05
				c0.09,0.05-0.18,0.03-0.02,0.11c-0.27-0.52,0,1.35-0.67,1.95c0.01,0.01,0.12,0.03,0.1,0.04c0,0,0,0,0,0c0,0,0,0,0,0
				c0.21,0.02-0.37,0.37-0.34,1.31c-0.03-0.02-0.08-0.04-0.07-0.05c-0.12,0-0.05,0.12,0.05,0.15c-0.03-0.03-0.26,0-0.09,0.18
				c-0.08,0.04,0.02,0.13-0.07,0.16c0.14,0.08-0.1,0.08,0.1,0.16c-0.21-0.07-0.21,0.05-0.12,0.12c-0.06-0.03-0.08,0.01-0.16-0.03
				c-0.01,0.04,0.02,0.1,0.13,0.12c-0.28,0,0.16,0.23-0.19,0.16c0.07,0.17,0.12,0.31,0.13,0.44c-0.21,0-0.13-0.1-0.2,0.42
				c-0.02-0.01-0.04-0.02-0.05-0.04c0,0.02-0.05,0.03,0.05,0.06l-0.02-0.03c0.15,0.02,0.27,0.17,0.34,0.2
				c-0.27-0.12-0.1,0.07-0.44-0.09c0.01,0.02,0.06,0.05,0.12,0.07c-0.18,0.07-0.04-0.01-0.26,0.45c0.03-0.02-0.06,0.08,0.13,0.08
				c-0.07-0.04,0.12,0.23-0.03,0.09l0.01,0.1c0.03-0.03,0.41,0.06,0.11,0.12c0.04,0.08,0.12,0.16-0.19,0.03
				c0.14,0.07,0.01,0.12,0.15,0.2c-0.03-0.02-0.13-0.05-0.15-0.06l0.11,0.06c-0.19-0.05-0.1,0.06-0.07,0.05
				c-0.07-0.02,0,0.37-0.24,0.12c0,0.11,0.15,0.28,0.07,0.37c0.04,0.01,0.07,0.01,0.09,0.02c-0.3,0.14-0.03-0.02-0.78,0.28
				c0.32,0.24-0.1-0.6,0.19-0.58c-0.27-0.17-0.27-0.76-0.49-1.11c0.02,0.02,0.07,0.05,0.07,0.09c-0.03-0.22,0.02-0.3,0.03-0.45
				c0.06,0.24-0.94-0.82-0.07-0.48C261.56,359.05,261.43,358.59,261.33,358.71z M263.38,360.81c-0.04-0.04,0.05-0.03,0.14-0.02
				C263.48,360.78,263.51,360.87,263.38,360.81z M262.33,362.6c0-0.01,0-0.02,0-0.02C262.34,362.58,262.34,362.59,262.33,362.6z
				 M262.27,362.58c-0.02,0.03-0.04,0.05-0.06,0.08C262.21,362.62,262.19,362.63,262.27,362.58z M269.85,357.43
				c-0.03,0.01-0.06,0.03-0.1,0.05c0.02-0.05,0,0,0.12-0.12L269.85,357.43z M265.52,351.9c0.01-0.01,0,0,0.02-0.05
				C265.58,351.88,265.71,351.9,265.52,351.9z M265.56,351.76c0,0.01,0,0.01,0,0.03C265.55,351.78,265.56,351.79,265.56,351.76z
				 M266.46,351.93c0.02,0.01,0.05,0.02,0.07,0.03C266.55,351.98,266.49,351.99,266.46,351.93z M262.88,362.29
				c0.01,0,0.04-0.02,0.05,0.05C262.93,362.34,262.91,362.32,262.88,362.29z M263.01,362.19c-0.02-0.01-0.03-0.01-0.04-0.01
				c0.04-0.03,0.08-0.05,0.12-0.08C263.06,362.13,263.04,362.15,263.01,362.19z M270.82,357.4c-0.16-0.06-0.27-0.1-0.38-0.12
				c0.07-0.13,0.16-0.18,0.26-0.22c-0.02,0.04-0.02,0.06,0.09,0.09c-0.04,0,0.06-0.02,0.16-0.1c0,0,0,0.01,0,0.01
				c0.09-0.03,0.2-0.08,0.33-0.13C271.27,357.09,270.98,356.86,270.82,357.4z M271.81,357.61c0.03-0.01,0.07-0.03,0.13-0.06
				C271.89,357.58,271.94,357.55,271.81,357.61z M272.28,357.32c-0.02,0.04,0.12,0.02,0.22,0.03
				C272.53,357.3,272.17,357.55,272.28,357.32z"
                />
                <path
                  className="st10"
                  d="M276.54,462.79c0.19,0,0.5,0.01,0.58-0.11l-0.11-0.07c-0.05,0.18,0.06-0.28-0.87-0.11
				c0.25-0.19,0.12-0.38-0.55-0.33c0.04-0.04-0.01-0.15,0.09-0.1c-0.29-0.16-0.52,0.08-0.61-0.09l0.03-0.01
				c-0.57-0.26-0.09,0.13-1.48,0c0.15-0.31-0.54,0.11-0.38-0.3c-0.3,0.28,0.06-0.24-1.19,0.01c0.03-0.09-0.01,0.06-0.75-0.26
				c-0.06,0.18-0.33,0.12-0.52,0.14c0.02-0.11,0.05-0.15,0.16-0.19c-0.02,0.03-0.04-0.08-0.41,0.08l0.05-0.13
				c-0.24-0.01-0.5,0.13-0.8,0.02c-0.02,0.13,0.23,0.37-0.11,0.44c-0.01-0.07-0.01-0.19,0.07-0.21c-0.03,0.01-0.1,0.05-0.16,0.02
				l0.14-0.11c-0.07,0.05-0.43-0.01-0.11-0.07c-0.13-0.02-0.28-0.09-0.32-0.17c-0.33,0.31,0-0.01-1.09,0.15h0
				c-0.28,0.2-1.03-0.25-2.58-0.13c0.19-0.08-0.06-0.08,0.19-0.06c-0.24-0.05-0.29-0.13-0.55,0.01c0.09-0.13-0.18-0.16-0.3-0.13
				l0.17,0.09c-0.79-0.28,0.1-0.18-2.56-0.02c0.19,0.19-0.32,0.08-0.14-0.1l-0.03,0.06c-0.11-0.06-0.43-0.05-0.35-0.15
				c-0.17,0.06-0.07,0.1,0.06,0.13c-0.29-0.08-0.46,0-0.76-0.05c0.1,0.07-0.89,0.49-0.65-0.09c-0.27,0.12-0.51-0.06-0.67-0.08
				l0.14-0.05c-0.09,0.03-0.16,0.03-0.23,0.03l0.09,0.12c-0.08-0.02-0.16-0.02-0.16-0.07c-0.15,0.12,0.06,0.43-0.48,0.22
				c0.34,0.04-0.01-0.14,0.23-0.18c-0.06,0.01-0.18,0.02-0.15-0.05c-0.34-0.13-0.15,0.18-0.5,0.19c0.04-0.09-0.09-0.17-0.14-0.15
				c0.1-0.02,0.05,0.21-0.42,0.27l0.14,0.03c-0.26,0.27-0.58-0.12-1.56-0.22c0.09,0.12,0.1,0.09-0.1,0.21l0.47-0.1l-0.3,0.18
				c0.14,0,0.3-0.08,0.38-0.07c-0.14,0.1-0.13,0.07-0.06,0.17c-0.75,0.02-0.27-0.56-1.65-0.18l0.14,0.05
				c-0.05,0.12-0.27,0.03-0.41,0.08c0.01-0.1-0.08-0.13-0.14-0.19c0.01,0.07-0.32,0-0.35,0.2c-0.39-0.16-0.2-0.23-0.93,0.15
				c0.1-0.11-0.06-0.18,0.17-0.26c-0.11,0-0.22-0.01-0.22,0.06c0.18-0.06-0.46-0.06-0.69,0.33c-0.27-0.22-0.45-0.21-2.05-0.12
				c0.01-0.03,0-0.05,0.04-0.04c-0.7,0.11-0.02-0.03-1.13-0.09l0.06-0.02c-0.18-0.11-0.44,0.13-0.74,0.11c0,0,0.01-0.03,0-0.05
				c-0.2,0.17-0.58,0.23-0.87,0.27c0.01-0.05,0.01-0.08,0.02-0.11c-0.04,0.1-0.17,0.22-0.45-0.11l0.24-0.01
				c-0.35-0.26-0.75-0.25-2.37,0.23c0.15-0.15,0.45-0.22,0.63-0.3c-0.14,0-0.48,0.01-0.55,0.11c0.06-0.02,0.13-0.07,0.19-0.04
				c-1.17,0.24-0.04,0.11-3.17-0.29c0.05,0.27-0.12,0.33-0.33,0.26c0.04-0.07,0.23-0.19,0.02-0.27c0.15,0.11-0.15,0.14-0.11,0.23
				c-0.19-0.09-0.4-0.27-0.51-0.49c0.03,0.11-0.11,0.15-0.28,0.1l0.23,0.16c-0.42,0.13-0.34-0.28-0.76-0.15l0.14-0.09
				c-0.22,0.04-0.78-0.06-1.01,0.17c-0.03-0.04-0.08-0.12,0.02-0.14c-0.5-0.07-1.15,0.15-1.53-0.03l0.05-0.07
				c-0.29,0.07-0.11-0.1-0.67,0.01l0.12-0.07c-2.08-0.14-0.43,0.12-4.84-0.82c0.01,0.04-0.1,0.26-0.41,0.07
				c0.08,0.01-0.38-0.53-0.6-0.01c-0.13-0.36-0.79-0.05-2.86-0.99l0.11-0.07c-0.8,0.12-0.32,0.15-1.85-0.36
				c0.02-0.03,0.05-0.08,0.12-0.07c-0.19-0.07-0.68-0.38-0.71-0.09c0.06-0.17-0.4-0.17-0.65-0.18l0.08-0.12
				c-0.02,0.04-0.29,0.03-0.9-0.08l0.12-0.05c-0.32-0.13-0.33,0.25-0.7-0.3c-0.04,0.02-0.39,0.03-0.24-0.06
				c-0.25-0.31-0.08,0.14-0.74-0.41c0.26,0.13,0.47,0.23,0.45,0.23c0.05-0.12-0.15-0.24,0.09-0.32c-0.1-0.14-0.52,0.18-1.36-0.54
				c-0.06,0.13-0.18,0.04-0.11,0.21c-0.03-0.03,0.05,0.02,0.15,0.04l-0.01,0.01c0.07,0.03,0.16,0.08,0.26,0.13
				c-0.1,0.1-0.12-0.25-0.56,0.02c-0.12-0.54-0.17-0.46-1.42-1.04c0.06,0.01,0.12,0.05,0.17,0.03c-0.21-0.05-0.53-0.29-0.67-0.16
				c-0.18-0.21,0.21-0.07-0.11-0.23l0.07-0.02c-0.44-0.6-1.22-0.22-1.63-0.98c0.03,0.05,0.05,0.07,0.04,0.11
				c-0.04-0.16-1.7-1.13-3.46-2.92l-0.03,0.12c-0.34-0.97-2.07-1.47-0.3-6.65c-0.21,0.36,0.14-0.35-0.64,0.06l0.07-0.21l-0.09,0.09
				c-0.1-2.53-0.53-2.54-0.89-8.37l0.02,0.07c-0.4-3.54-0.28,0.01-0.55-9.03l-0.09,0.07c-0.17-2.15-0.2-0.65-0.25-10.36l0.16,0.35
				c-0.18-1.99,0.06-4.06-0.05-6.01c0.03,0.12,0.08,0.12,0.08,0.31c0.06-0.75,0.14-2.87,0.06-7.2c0.22,0.06,0.24-1.27,0.15-1.74
				c0.06,0.16,0.09-0.4,0.17-0.21c0.02-0.53,0-1.01-0.12-0.93c0.31-1.76,0.21-1.31,0.17-6.93c0.06,0.21,0.1,0.38,0.13,0.53
				c0.16-0.23-0.36-0.28,0.28-1.99l-0.01,0.22l0.12-0.22c0.05,0.04,0.11,0.14,0.04,0.2c0.13,0.05,0.07,0.1,0.33-0.09
				c-0.01,0.05,0.06,0.13-0.02,0.13c-0.03,0.05,0.42-0.47,1.37-0.47c0.01-0.13-0.17-0.03-0.04-0.17c0.04,0.32,0.31-0.29,0.36,0.14
				c0.06-0.36,0.12,0.22,0.74-0.43c0.01,0.09,0-0.06,0.58-0.03c-0.04-0.18,0.15-0.23,0.26-0.32c0.04,0.1,0.03,0.15-0.01,0.23
				c0-0.03,0.08,0.04,0.22-0.22l0.03,0.14c0.16-0.08,0.25-0.29,0.49-0.3c-0.04-0.12-0.31-0.25-0.14-0.42
				c0.04,0.06,0.1,0.16,0.05,0.21c0.01-0.02,0.04-0.08,0.09-0.07l-0.03,0.14c0.03-0.07,0.27-0.14,0.1,0.02
				c0.09-0.02,0.22-0.02,0.28,0.03c-0.12-0.2,0.34,0.08,0.6-0.53l0,0c0.08-0.28,0.75-0.15,1.64-0.86c-0.07,0.14,0.08,0.04-0.08,0.13
				c0.17-0.05,0.25-0.01,0.33-0.22c0.01,0.14,0.2,0.06,0.24-0.01l-0.15-0.01c0.49-0.16,0.15,0.11,1.02-0.5
				c-0.65-0.2,0.7-0.67,0.64-0.53l-0.02-0.06c0.1,0,0.27-0.15,0.29-0.04c0.06-0.12-0.02-0.11-0.11-0.08c0.22-0.07,0.26-0.2,0.46-0.3
				c-0.1,0.02,0.18-0.8,0.43-0.23c0.07-0.22,0.32-0.2,0.42-0.26l-0.04,0.11c0.03-0.06,0.07-0.1,0.1-0.14l-0.13-0.04
				c0.26-0.09,0.14,0.04,0.24-0.43c-0.31,0.14,0.35,0.53,0.26-0.18c0.04,0.09,0.16,0.07,0.18,0.03c-0.03,0.07-0.18-0.09,0.02-0.41
				l-0.09,0.05c0.14-0.39-0.11-0.1,0.95-0.68c-0.13-0.03-0.11,0-0.11-0.19l-0.16,0.32l0.01-0.28c-0.07,0.08-0.08,0.21-0.14,0.25
				c-0.01-0.14,0.01-0.12-0.1-0.14c0.35-0.47,0.54,0.27,0.92-0.79c-0.12,0.02-0.19,0.09,0.02-0.23c0.08,0.06,0.14,0.03,0.22,0.03
				c-0.06-0.04,0.14-0.19-0.01-0.32c0.28-0.14,0.28,0.01,0.27-0.64c0.05,0.12,0.17,0.06,0.15,0.24c0.06-0.26,0,0.06,0.03-0.75
				c0.28-0.06,0.38-0.17,0.8-1.25c0.01,0.01,0.02,0.02,0.03,0.02c0.09-0.47,0.06,0.01,0.4-0.71l0,0.05
				c0.15-0.09-0.01-0.35,0.07-0.55c0,0,0.02,0.02,0.05,0.02c-0.11-0.19-0.1-0.47-0.09-0.68c0.05,0.02,0.07,0.03,0.09,0.04
				c-0.08-0.05-0.16-0.17,0.19-0.29c-0.16,0.48,0.43-0.49-0.02-1.58c0.15,0.11,0.21,0.34,0.29,0.49c0.01-0.11,0.01-0.37-0.09-0.43
				c0.02,0.04,0.06,0.1,0.03,0.14c-0.37-0.93,0.01-0.02-0.66-2.53c-0.15,0.23-0.28,0.19-0.39,0.02c0.06-0.04,0.27-0.03,0.18-0.21
				c0.04,0.16-0.19,0.05-0.21,0.15c-0.09-0.17-0.15-0.42-0.17-0.66c-0.02,0.12-0.15,0.09-0.28,0c0.05,0.06,0.1,0.15,0.14,0.22
				c-0.39,0-0.21-0.37-0.61-0.32l0.14-0.07c-0.2,0-0.71-0.14-0.88,0.12c-0.03-0.04-0.07-0.12,0.01-0.14
				c-0.33-0.12-0.46-0.01-1.69,0.35l0.06-0.1c-0.85,0.66-1.05-0.1-3.34,1.65c-0.13,0.42-0.21,0.08-1.67,1.51
				c-0.26-0.11-0.06-0.12-0.64,0.34l-0.02-0.12c-0.15,0.6,0.04,0.25-0.85,1.12c-0.02-0.03-0.06-0.07-0.03-0.12
				c-0.11,0.1-0.5,0.31-0.26,0.47c-0.14-0.13-0.24,0.22-0.28,0.41l-0.09-0.11c0.03,0.03-0.02,0.21-0.23,0.65l-0.02-0.11
				c-0.32,0.36-0.16,0.18-0.87,1.03c0.13-0.17,0.25-0.3,0.25-0.28c-0.1-0.08-0.23,0.04-0.28-0.17c-0.13,0.04,0.1,0.44-0.56,0.94
				c0.12,0.07,0.03,0.15,0.19,0.13c-0.02,0.01,0.02-0.03,0.03-0.11l0.01,0.01c0.03-0.05,0.07-0.11,0.12-0.18
				c0.02,0.1,0.03,0.19-0.09,0.22c0.05,0.09,0.09,0.18,0.12,0.23c-0.49,0.14-0.65-0.26-0.89,0.94c0-0.05,0.03-0.09,0.01-0.13
				c-0.03,0.16-0.21,0.4-0.07,0.52c-0.17,0.12-0.09-0.18-0.2,0.06l-0.03-0.06c-0.52,0.3,0.01,0.94-0.58,1.27
				c0.04-0.02,0.06-0.04,0.09-0.03c-0.15,0.28-0.27,0.26-0.45,0.68c-0.06-0.56-0.15-0.4-0.15-1.08c0.05,0,0.07,0.16,0.13,0.23
				c-0.1-0.39-0.2-0.77-0.17-1.42c0.03,0.04,0.07-0.04,0.07,0.15c-0.05-1.11-0.31-1.88-0.29-3.1c0.11,0.18,0.29,0.24,0.13-1.14
				l-0.07,0.27c-0.04-0.57-0.22-1.07-0.2-1.56l0.11,0.42c-0.04-0.36-0.07-0.71-0.13-1.07l-0.01,0.61c-0.15-0.57-0.22,0.08-0.25-0.68
				c0.04,0.15-0.06-0.57,0.02-0.88l0,0c-0.3-1.16,0.31-0.4-0.39-2.65c0.3,0.25,0.07-0.88-0.16-1.09c0.02,0.07,0.04,0.03,0.08,0.18
				c-0.15-0.75-0.2-1.26-0.28-1.77l0.08,0.27c-0.3-1.52,0.03-0.01-1.2-4.41l0.05,0.22c-0.65-1.25,0.17-1.13-1.49-4.08l0.12,0.12
				c-1.07-2.09-0.52-1.79-3.06-5.96c0.2,0.2,0.25,0.26-0.53-0.81c-0.04,0.15,0.03,0.17,0.11,0.44c-0.15-0.09-0.39-0.35-0.56-0.6
				c0.38,0.41,0.15-0.11,0.07-0.34c-0.01,0.05-0.09,0.01-0.1,0.05c-0.2-0.65-0.25,0.04-0.64-0.76c0.14-0.01,0.31,0.03-1.22-1.59
				c0.16,0.14,0.35,0.38,0.51,0.53c-0.19-0.48-0.24-0.52-0.47-0.81l0.02,0.03l-0.51-0.69l0.11,0.19c-0.68-0.24-0.88-0.93-1.66-1.36
				c0.73,0.43,0.19,0.01,0.34-0.05c-0.12-0.18-0.32-0.45-0.23-0.41c-0.24-0.18-0.33-0.07-1.12-1.12c0.1,0.54-0.81-0.82-5.95-3.84
				c0.1,0,0-0.01,0.44,0.02c0,0,0,0,0,0c0.28,0.01-1.88-0.52-0.81-0.45c-0.49-0.19-1.27-0.51-1.47-0.45l0.28,0.18
				c0.04-0.1,0.27-0.2,0.66,0.26l-0.31-0.11c0.18,0.22,0.23,0.19,1.78,0.89c-0.39-0.01,0.02,0.36,1.23,1.04
				c-0.1-0.01-0.02,0.17-0.23-0.02c0.62,0.56,1.2,0.6,1.36,0.91l-0.06-0.03c1.87,1.41,2.22,1.3,4.95,4.47
				c-0.18,0.1-0.05-0.22,1,1.05c-0.08,0,0.78,1.15,1.14,1.73l-0.04,0c0.22,0,0.6,0.6,0.92,1c-0.1,0.04-0.19-0.01-0.39-0.21
				c0.07,0.04-0.05,0.07,0.69,0.81c-0.55-0.18,0.18,0.36,1.43,2.03l-0.27-0.25c0.12,0.33,0.48,0.54,0.09,0.29
				c0.67,1.47,0.32,0.61,1.73,3.33l0,0c0.52,0.97,0.89,2.24,2.16,6.52c-0.21-0.46-0.03,0.18-0.19-0.48
				c0.12,0.63,0.08,0.79,0.38,1.43c-0.17-0.2-0.05,0.51,0.05,0.8l-0.01-0.46c0.09,0.33,0.14,0.63,0.2,0.93l-0.2-0.13
				c0.16,1.33,0.57,2.85,1.06,5.92c0.37-0.64-0.27,1.61,0.05,1.24c-0.02,0.78,0.1,1.21,0.09,2.01c0.1-0.05,0.58,2.21-0.02,1.74
				c0.14,0.72-0.02,1.36-0.04,1.77l-0.05-0.38c0.03,0.23,0.03,0.42,0.04,0.61l0.12-0.22c-0.02,0.23-0.02,0.42-0.06,0.41
				c0.28,0.49,0.37,0.03,0.23,1.3c0.03-1.25-0.6-0.56-0.06,1.13c-0.09-0.12-0.17,0.22-0.15,0.37c0.01-0.3,0.25,0.11,0.25,1.14
				l0.04-0.36c0.12,0.62-0.24,0.32-0.02,1.44c-0.01,0-0.01,0-0.04-0.01c0.03,0.68,0-0.01-0.3,2.73c0.26-0.13,0.06-0.1,0.2,0.29
				l-0.04-1.26l0.15,0.81c0.02-0.38-0.05-0.8-0.02-1.03c0.09,0.39,0.06,0.35,0.16,0.17c-0.12,1.76-0.59,1.01-0.4,4.38l0.07-0.37
				c0.13,1.14-0.25,1.07-0.04,4.96c-0.1-0.28-0.18,0.13-0.23-0.48c-0.01,0.3-0.04,0.58,0.02,0.58c-0.08,0.3,0.49,1.23-0.15,3.77
				c0.4-0.31-0.32-0.05,0.02-0.28c-0.11-0.03-0.17-0.18-0.3-0.14c0.05-0.15-0.21-0.22-0.03-0.35c-0.06,0.01-0.24-0.02-0.3-0.08
				c0.3-0.04-0.18-0.26,0.06-0.4c-0.26,0.06-0.35-0.25-0.58-0.32c0.02,0,0.06-0.01,0.07,0.01c-0.06-0.1-0.03-0.17-0.04-0.26
				c-0.16,0.12-0.31,0.01-0.46,0.02c0.05-0.23,0.23-0.09,0.33-0.24c-0.11,0.11-0.28-0.08-0.35,0.05c0.02-0.16-0.02-0.11,0.06-0.26
				c-0.19,0.15-0.1-0.04-0.27-0.01c0.07-0.11-0.22-0.26,0.14-0.32c-0.02,0.22-0.37-0.59-1.35-0.53c0.03,0.03,0.07,0,0.11-0.01
				l-0.09-0.02c0.46,0.07-0.06-0.45,0.09-0.16c0.49-0.59-0.13-0.01-3.83-3.04l0.06-0.01c-0.13-0.15-0.34,0.14-0.42-0.07l0.04-0.01
				c-0.22-0.25-0.92-0.63-3.06-1.59c0.03-0.06,0.1-0.05,0.16-0.07c-0.25,0.08,0.02-0.17-0.21-0.09c0.01,0.04-0.04,0.08-0.06,0.11
				c-0.06-0.12-0.27-0.19-0.44-0.2l0.06-0.09c-0.27-0.11-0.59-0.12-0.91-0.18c0.02,0.07-0.13,0.4-0.1,0.07
				c-0.06,0.09-0.16,0.33-0.03-0.06l-0.16,0.03c0-0.05,0.04-0.07,0.06-0.13c-0.72-0.07,0-0.18-2.11,0.2
				c-0.02-0.13-0.26-0.09-0.15-0.19c-0.29,0.05-0.37,0.07-0.72,0.24c0.1,0.07,0.06,0.19-0.07,0c-0.34,0.28-1.06-0.06-1.27,0.34
				l0.01-0.02c-0.78,0.69-0.1-0.21-2.06,1.44c0.06,0.06,0.17,0.09-0.08,0.13c0.56,0.28-1.01,0.57-0.69,2.71l-0.17-0.1
				c0.22,0.57,0.18,1.21,0.61,1.64c-0.05-0.01-0.09,0.01-0.12-0.04c0.4,0.59,0.1,0.05,1.37,1.43c-0.16,0.16,0.14,0.37,0.31,0.35
				c-0.07,0.03,0.04,0.12-0.05,0.17c0.12,0.08,0.24,0.13,0.29,0.02c0.23,0.4,0.1,0.34,1.6,0.79c-0.5,0.25-0.82-0.09,1.22,0.6
				c-0.01,0.02-0.05,0.03-0.08,0.03c0.19-0.04-0.04,0.01,0.45-0.29c-0.2,0.24,0.25,0.17-0.03,0.46c0.04,0,0.11-0.03,0.12-0.1
				c0.05,0.04,0.05,0.08-0.03,0.14c0.22,0.02,0.18-0.11,0.36-0.04c-0.02,0.05-0.06,0.05-0.1,0.1c0.12-0.06,0.25-0.12,0.38-0.02
				c-0.02,0.02-0.01,0.07-0.06,0.05c0.26,0.08,0.52-0.09,0.77,0.09c-0.15,0.18,0.07,0.1,0.03,0.28l0.17,0l-0.03-0.11
				c0.13,0.05,0.29-0.04,0.39,0.06l-0.12,0.04l0.26,0.05l-0.12-0.11c0.3-0.05,0.05-0.32,0.56,0.29c0.11-0.13,0.33,0.06,0.46-0.07
				c-0.04,0.03-0.07,0.12-0.07,0.14l0.09-0.1c0.05,0.06,0.05,0.08,0.02,0.15c0.18,0.08,0.01-0.24,0.2-0.08
				c-0.02,0-0.02,0.03-0.06,0.03c0.27,0.06,0.36,0.13,0.41,0.19l-0.09,0l0.06,0.1l0.06-0.08c-0.1,0.21,0.54-0.14,1.99,0.42
				l-0.05,0.06c0.01-0.03,0.27,0.29,0.53,0.02c0.32,0.47,0.79-0.06,1.09,0.32c-0.12-0.03,0.22,0.21,0.37-0.24
				c-0.15,0.06-0.02,0.18,0.03,0.26c-0.01-0.04,0.02-0.07,0.02-0.11c0.08,0.3,0.1-0.15,0.23,0c-0.03,0.01,0.44-0.15,0.14,0.1
				c0.13,0,0.27-0.23,0.37-0.09c-0.06,0.03-0.13,0.03-0.19,0.06c0.11,0.17,0.13,0.13,0.21,0.16l-0.01,0l0.21,0.09
				c0.02-0.41-0.01-0.03,0.49-0.31c-0.17,0.19-0.09,0.09,0.05,0.41c0.1-0.12,0.17,0.1,0.31-0.07c0,0.03,0.05,0.03,0.07,0.05
				c-0.01-0.09,0.35-0.12,1.53-0.35c-0.05,0.31,0.35-0.23,0.16,0.2c0.11-0.06,0.2-0.17,0.31-0.23c0.04,0.08-0.05,0.14-0.08,0.2
				c-0.02-0.02,0.3-0.08,0.25-0.3c-0.13,0.38,0.09-0.36-0.57,0.13l0.04-0.22l-0.14,0.2c-0.04-0.05-0.08-0.16,0-0.2
				c-0.1-0.07-0.05-0.11-0.31,0.02c0.02-0.05-0.03-0.14,0.04-0.12c-0.17-0.04-0.48-0.14-0.6,0.36l-0.16-0.37l-0.02,0.15
				c-0.03,0-0.09-0.02-0.08-0.08c-0.11,0.11,0.02,0.07-0.04,0.17c-0.14-0.14-0.39,0.08-0.42-0.17c-0.05,0.11,0.13,0.09-0.02,0.17
				c0.08-0.31-0.36,0.15-0.26-0.27c-0.19,0.3-0.04-0.25-0.77,0.08c0.03-0.09-0.03,0.05-0.47-0.25c-0.05,0.18-0.22,0.12-0.35,0.14
				c0.02-0.11,0.05-0.15,0.12-0.19c-0.02,0.02-0.05-0.07-0.27,0.08l0.05-0.13c-0.15-0.01-0.33,0.11-0.52-0.01
				c-0.03,0.12,0.1,0.38-0.13,0.43c0-0.07,0.02-0.19,0.08-0.21c-0.01-0.01-0.22,0.08,0-0.09c-0.06,0.04-0.28-0.03-0.06-0.07
				c-0.08-0.03-0.17-0.1-0.18-0.19c-0.06,0.19-0.16-0.25-0.72,0.06l0,0c-0.23,0.15-0.6-0.34-1.61-0.49
				c0.14-0.05-0.02-0.09,0.14-0.03c-0.14-0.09-0.15-0.17-0.35-0.09c0.09-0.11-0.07-0.19-0.15-0.17l0.08,0.12
				c-0.39-0.26-0.01-0.16-0.92-0.46c0.18,0.63-0.87-0.18-0.72-0.2l-0.04,0.05c-0.05-0.08-0.25-0.14-0.17-0.22
				c-0.12,0.02-0.08,0.08-0.01,0.13c-0.15-0.14-0.28-0.1-0.45-0.22c0.06,0.1-0.74,0.23-0.37-0.24c-0.21,0.05-0.29-0.18-0.38-0.23
				l0.1-0.01c-0.06,0.01-0.1-0.01-0.15-0.02l0.01,0.13c-0.17-0.19-0.02-0.14-0.44-0.01c0.27,0.14,0.25-0.54-0.24-0.15
				c0.06-0.07,0.01-0.18-0.03-0.17c0.06-0.01-0.01,0.21-0.35,0.14c0.04,0.08,0.12,0.11-0.16,0.08c0.02-0.07,0.1-0.01,0.08-0.05
				c-0.3,0.23-0.33-0.89-0.81-0.45l0.31,0.05l-0.25,0.08c0.08,0.04,0.21,0.01,0.25,0.05c-0.12,0.05-0.1,0.03-0.1,0.14
				c-0.57-0.17,0.25-0.45-0.84-0.67c0.03,0.11,0.1,0.17-0.21,0.02c0.06-0.08,0.03-0.14,0.02-0.21c-0.03,0.07-0.17-0.11-0.29,0.05
				c-0.11-0.25,0.03-0.27-0.57-0.23c0.12-0.05,0.08-0.16,0.24-0.15c-0.23-0.05,0.05,0.02-0.68-0.06c0.02-0.3-0.07-0.28-0.78-1.04
				c0.03-0.01,0.04-0.03,0.05,0c-0.36-0.33,0.01,0-0.25-0.63l0.03,0.02c0.06-0.14-0.23-0.18-0.25-0.36c0,0,0.03,0,0.05-0.01
				c-0.2-0.06-0.31-0.28-0.38-0.45c0.29,0,0.19,0.04,0.19-0.28c0.06,0.35,0.12-0.35,0-1.29c0.1,0.14,0.12,0.33,0.17,0.46
				c0.02-0.08,0.07-0.26,0-0.34c-0.04,0.12-0.05,0.14-0.05-0.36c0.32,0.2,0.3-0.35,0.63-0.27c0.05-0.32,0.41-0.4,0.64-0.64
				c-0.15,0.03-0.11-0.57,0.14-0.12c-0.14-0.02-0.02-0.18-0.12-0.22c0.13-0.02,0.33,0.02,0.55,0.19c-0.08-0.08-0.04-0.16,0.07-0.18
				l-0.19-0.04c0.12-0.28,0.31,0.1,0.44-0.15l-0.02,0.12c0.08-0.12,0.4-0.2,0.42-0.48c0.03,0.02,0.09,0.09,0.05,0.13
				c0.22,0.06,0.34-0.19,1.2-0.42l-0.05,0.09c0.85-0.39,0.59,0.17,2.97,0.32c0.37-0.14,0.17,0.02,1.87,0.5
				c-0.01,0.25-0.09,0.11,0.48,0.41l-0.1,0.06c0.54-0.08,0.2-0.18,1.16,0.39c-0.02,0.02-0.06,0.07-0.1,0.06
				c0.11,0.07,0.36,0.38,0.47,0.11c-0.1,0.16,0.23,0.17,0.41,0.17l-0.09,0.11c0.02-0.04,0.19-0.03,0.62,0.09l-0.1,0.04
				c0.34,0.25,0.16,0.11,0.97,0.72c-0.15-0.12-0.27-0.22-0.25-0.21c-0.25,0.13,0.2,0.63,0.8,0.59c0.01,0.02-0.03-0.02-0.1-0.03
				l0.01-0.01c-0.04-0.03-0.1-0.07-0.16-0.11c0.09-0.02,0.18-0.03,0.2,0.09c0.52-0.29,0.53,0.65,2.22,1.92
				c-0.01-0.04-0.01-0.06,0-0.09c0.36,1.26,3.62,1.81,2.78,5.86c-0.01-0.02-0.03-0.02-0.03-0.03c0.05,1.87-0.06-0.01-0.2,2.95
				l-0.02-0.15c-0.08,0.74-0.06,0.22,0.23,4.33c-0.05-0.02-0.07-0.03-0.1-0.04c0.1,0.13,0.19,0.46-0.14,1.21l0-0.65
				c-0.18,0.33,0,0.84-0.03,1.18l-0.08-0.04c0.02,0.99,0.21,1.01,0.2,2.07l0.06-0.11c0.22,1.49-0.45,0.59,0.09,3.28
				c-0.16-0.39-0.24-1.19-0.33-1.69c0,0.38,0.03,1.3,0.13,1.49c-0.02-0.15-0.07-0.35-0.04-0.5c0.42,2.1-0.11,0.63,0.12,8.61
				c0.26-0.57,0.04,0.46,0.34,0.34c0.03,0.19,0.01-0.01,0.03,0.54c0,0,0-0.01,0-0.02c-0.02,0.52-0.2,0.95-0.4,1.72
				c0.1-0.12,0.18,0.26,0.18,0.73l0.08-0.66c0.26,1.1-0.17,0.99,0.12,2.1l-0.14-0.34c0.05,1.07,0.01-0.12,1,6.54
				c-0.19-0.07-0.27-0.33-0.47-0.44c0.02,0,0.07,0,0.07,0.02c-0.05-0.12-0.01-0.19-0.02-0.28c-0.17,0.09-0.32-0.04-0.48-0.06
				c0.07-0.23,0.24-0.05,0.37-0.2c-0.13,0.1-0.29-0.13-0.36-0.01c0.03-0.16-0.01-0.12,0.08-0.26c-0.21,0.13-0.1-0.07-0.28-0.06
				c0.08-0.11-0.21-0.31,0.16-0.32c-0.05,0.23-0.32-0.65-1.41-0.77c0.03,0.04,0.07,0.01,0.12,0l-0.1-0.03
				c0.5,0.13-0.06-0.49,0.1-0.16c0.45-0.5,0.01-0.17-4.22-3.44l0.07-0.01c-0.16-0.16-0.36,0.14-0.46-0.07l0.05,0
				c0.05-0.24-0.45-0.38-3.49-1.51c0.03-0.06,0.1-0.06,0.17-0.08c-0.26,0.09,0-0.17-0.24-0.08c0.02,0.04-0.04,0.08-0.05,0.11
				c-0.08-0.12-0.31-0.18-0.49-0.19l0.06-0.1c-0.3-0.09-0.65-0.09-0.99-0.15c0.03,0.07-0.13,0.4-0.1,0.07
				c-0.07,0.09-0.16,0.33-0.03-0.06l-0.18,0.03c-0.07-0.25-0.04-0.7-4.66,0.57l0.01-0.02c-0.74,0.82-0.39-0.42-2.12,2.01
				c0.09,0.04,0.18,0.03-0.04,0.18c0.54,0.03-0.68,1.03,0.75,3.04l-0.2,0c0.51,0.4,0.82,0.97,1.38,1.2
				c-0.05,0.01-0.08,0.05-0.13,0.01c0.63,0.41,0.13-0.01,1.84,0.96c-0.12,0.2,0.25,0.33,0.41,0.29c-0.07,0.04,0.07,0.11-0.01,0.18
				c0.14,0.06,0.28,0.08,0.3-0.04c0.33,0.38,0.18,0.28,1.82,0.59c-0.51,0.3-0.86-0.06,1.38,0.55c-0.01,0.02-0.05,0.03-0.08,0.04
				c0.2-0.05-0.04,0.01,0.46-0.3c-0.19,0.25,0.28,0.16,0.01,0.47c0.05-0.01,0.11-0.04,0.12-0.11c0.05,0.04,0.06,0.08-0.02,0.14
				c0.06,0.09,0.43-0.17,0.29,0.05c0.13-0.06,0.26-0.12,0.41-0.03c-0.02,0.03-0.01,0.07-0.06,0.05c0.3,0.07,0.57-0.11,0.86,0.07
				c-0.15,0.18,0.09,0.1,0.06,0.28l0.19-0.01l-0.05-0.11c0.15,0.04,0.32-0.06,0.43,0.03l-0.13,0.05l0.29,0.04l-0.14-0.11
				c0.32-0.07,0.02-0.32,0.65,0.26c0.11-0.14,0.37,0.03,0.5-0.11c-0.04,0.03-0.06,0.13-0.06,0.15l0.09-0.11
				c0.06,0.05,0.07,0.08,0.04,0.15c0.21,0.07-0.02-0.25,0.21-0.09c-0.02,0.01-0.02,0.03-0.06,0.04c0.3,0.04,0.4,0.1,0.47,0.16
				l-0.1,0.01l0.07,0.09l0.05-0.08c-0.08,0.21,0.53-0.18,2.23,0.25l-0.05,0.06c0.02-0.04,0.3,0.27,0.58-0.02
				c0.4,0.45,0.87-0.12,1.23,0.25c-0.13-0.02,0.25,0.21,0.39-0.25c-0.16,0.07-0.01,0.19,0.05,0.25c-0.01-0.04,0.02-0.07,0.01-0.11
				c0.12,0.29,0.09-0.15,0.26-0.02c-0.04,0.02,0.48-0.17,0.16,0.09c0.14,0,0.28-0.23,0.4-0.09c-0.06,0.03-0.14,0.03-0.2,0.06
				c0.12,0.17,0.14,0.13,0.24,0.16l-0.01,0l0.23,0.08c0.01-0.42-0.01-0.03,0.53-0.31c0.32,0.74,0.14-0.05,1.34,1.84
				c-0.23,0-0.26,1.04-0.32,1.56l-0.03-0.02c-0.19,0.55-0.15,0.82-0.08,1.11c-0.17-0.17-0.02,0.03-0.7,0.37l-0.01-0.16
				c-0.1,0.09-0.01,0.15,0.06,0.21c-0.1,0.09-0.24,0.02,0.11,0.26c-0.51,0.03,0.24,0.23-0.18,0.23c0.08,0.09,0.04,0.25,0.16,0.29
				c-0.13,0.1,0.04,0.32-0.18,0.31c0.05,0.03,0.18,0.16,0.19,0.25c-0.27-0.15-0.01,0.32-0.29,0.29c0.24,0.11,0.11,0.42,0.24,0.63
				c-0.02-0.01-0.06-0.03-0.05-0.05c-0.02,0.12-0.09,0.15-0.13,0.23c0.14,0.06,0.13,0.02,0.2,0.33c-0.07-0.03-0.14-0.1-0.25-0.07
				c0.1,0,0.14,0.1,0.19,0.16c-0.05,0.03-0.08,0.05-0.02,0.15c-0.02,0.01-0.05,0.02-0.1,0.04c0.24,0.02,0.04,0.1,0.19,0.19
				c-0.09,0.09-0.02,0.18-0.32,0.14c-0.28,3.79,0.14,2.51-5.34,9.62l-0.08-0.12c-0.74,0.56-1.1,0.84-2.01,1.53l0.14-0.21
				c-1,1.01-1.18,1.02-4.24,2.61c0-0.04,0.01-0.1,0.17-0.19c-0.49,0.22-1.94,0.7-1.67,0.88c-0.08-0.17-1.11,0.4-1.66,0.69l0.04-0.16
				c-0.04,0.08-0.7,0.38-2.15,0.97l0.22-0.16c-0.71,0.21-2.03,0.65-4.82,1.3c0.77-0.19,1.39-0.35,1.33-0.32
				c-0.03-0.12-0.66,0.02-0.21-0.28c-0.49,0.18,0.04,0.09-1.98,0.71l0.06-0.03c-0.83,0.24-0.48,0.05-1.93,0.4
				c0.06,0.13-0.35,0.21,0.06,0.23c-0.1,0.01,0.15-0.04,0.39-0.13l-0.02,0.02c0.2-0.05,0.47-0.12,0.77-0.2
				c-2.05,0.87-1.57,0.11-5.87,1.55c0.15-0.07,0.33-0.11,0.41-0.17c-0.05-0.05-1.3,0.71-2.25,0.71l0.11-0.09
				c-1.29,0.23,0.27-0.04-4.89,1.79c0.14-0.03,0.22-0.05,0.26-0.03c-2.58,1.28,0.36-1.2-10.6,6.26l0.19-0.03
				c-0.36,0.27-1.06,0.97-1.08,0.83c-0.11,0.4-3.37,2.4-2.25,2.34c-0.47,0.36-0.06,0.95,1.29-0.35l-0.44,0.42
				c0.71-0.44,0.49-0.4,4.46-3.94c-0.33,0.57,1.01-0.72,1.05-0.39c0.23-0.45,1.27-1,1.87-1.52c-0.03,0.04-0.08,0.12-0.15,0.14
				c0.16,0.37,1.72-1.7,1.66-0.77c-0.1-0.12,0.75-0.59,0.53-0.62c0.42-0.09,0.35-0.12,0.61-0.07c-0.4-0.1,1.97-1.2,1.2-0.45
				c1.22-1.05,0.32-0.15,4.21-2.03c-0.16-0.26-0.22-0.22,0.47-0.43c-0.27,0.17-0.64,0.32,0.26,0.14c0.93,0.45,2.19-0.82,5.26-1.51
				l-0.19,0.25c1.3-0.83,0.83,0.43,13.84-4l-0.17,0.11c0.75-0.22,0.71-0.58,1.42-0.75l-0.12,0.08c1.6-0.64,5.82-2.76,8.21-5.12
				c-0.08-0.04,0.32-0.37,0.93-1.01c-0.06-0.02-0.1-0.02-0.13-0.02c0.17-0.06,0.77-0.43,1.84-2.29c0.03,0.06-0.1,0.29-0.19,0.49
				c0.49-0.9,1.46-2.8,1.09-0.16l-0.04-0.05c-0.08,0.19,0.28,0.25,0.12,0.42l-0.02-0.04c-0.24,0.56-0.04,0.27-0.55,3.49
				c-0.02-0.01-0.03-0.02-0.04-0.04c-0.03,0.03-0.21-0.04,0,0.16c-0.11,0.08-0.14,0.3-0.13,0.46l-0.1-0.05
				c-0.07,0.27-0.06,0.59-0.1,0.9c0.07-0.02,0.4,0.12,0.08,0.1c0.09,0.06,0.33,0.15-0.06,0.03l0.03,0.16
				c-0.05,0-0.07-0.04-0.13-0.06c-0.09,0.71-0.13,0.01-0.08,2.09c-0.13-0.01-0.16,0.22-0.23,0.08c-0.04,0.28-0.05,0.36-0.02,0.73
				c0.1-0.06,0.2,0.02-0.02,0.06c0.1,0.44-0.57,0.82-0.39,1.2l-0.01-0.02c-0.14,0.87,0.11,0.08-0.75,2.2
				c0.07,0.03,0.2,0.03-0.03,0.13c0.62-0.12-0.31,0.44-1.3,3.69c-0.02-0.04-0.07-0.07-0.04-0.11c-0.15,0.04-0.17,0.34-0.09,0.39
				c-0.11,0.18-0.05-0.19-0.63,1.29c-0.18-0.13-0.33,0.17-0.3,0.33c-0.04-0.07-0.12,0.04-0.17-0.05c-0.07,0.11-0.1,0.24,0.01,0.28
				c-0.43,0.2-0.24,0.11-0.97,1.46c-0.17-0.57,0.4-0.7-0.98,0.94c-0.01-0.02-0.01-0.05,0-0.09c-0.04,0.19,0.01-0.04,0.06,0.54
				c-0.12-0.28-0.27,0.15-0.4-0.23c-0.02,0.04-0.02,0.11,0.03,0.15c-0.06,0.02-0.1,0.01-0.11-0.09c-0.12,0.19,0.01,0.22-0.14,0.34
				c-0.03-0.04-0.02-0.08-0.04-0.14c-0.01,0.14-0.02,0.28-0.17,0.36c-0.01-0.03-0.05-0.05-0.01-0.08c-0.2,0.2-0.2,0.52-0.49,0.66
				c-0.07-0.22-0.13,0.02-0.26-0.11l-0.09,0.16l0.11,0.02c-0.11,0.1-0.12,0.29-0.26,0.33l0.03-0.13l-0.18,0.22l0.16-0.06
				c-0.11,0.3,0.26,0.19-0.55,0.4c0.06,0.16-0.22,0.28-0.16,0.47c-0.01-0.05-0.08-0.12-0.1-0.12l0.05,0.13
				c-0.08,0.02-0.1,0.02-0.15-0.04c-0.16,0.14,0.22,0.11-0.02,0.23c0.01-0.02-0.02-0.03,0-0.07c-0.17,0.23-0.28,0.29-0.36,0.33
				l0.04-0.09l-0.12,0.02l0.05,0.08c-0.15-0.16-0.1,0.53-1.16,1.84l-0.04-0.07c0.02,0.03-0.37,0.17-0.2,0.52
				c-0.56,0.19-0.21,0.81-0.66,1.01c0.07-0.11-0.28,0.15,0.1,0.44c0-0.16-0.17-0.08-0.25-0.05c0.04,0.01,0.06,0.04,0.1,0.05
				c-0.31,0,0.11,0.14-0.07,0.24c0-0.04-0.01,0.49-0.14,0.11c-0.05,0.12,0.12,0.34-0.04,0.39c-0.01-0.07,0.02-0.14,0.01-0.2
				c-0.19,0.05-0.16,0.08-0.23,0.16l0.01-0.01l-0.15,0.18c0.38,0.16,0.04,0,0.12,0.59c-0.11-0.23-0.06-0.12-0.4-0.09
				c0.07,0.14-0.16,0.13-0.04,0.33c-0.03-0.01-0.04,0.04-0.08,0.05c0.08,0.03-0.01,0.39-0.33,1.6c-0.22-0.1-0.21-0.11-0.34,0.27
				c0.03-0.01-0.08,0.32,0.14,0.37c-0.26-0.31,0.28,0.26,0.17-0.59l0.17,0.14l-0.1-0.22c0.06-0.02,0.18,0,0.18,0.09
				c0.12-0.07,0.12,0.01,0.13-0.31c0.03,0.04,0.14,0.03,0.09,0.09c0.02,0.05-0.12-0.62,0.5-1.32c-0.09-0.09-0.13,0.1-0.15-0.08
				c0.27,0.19-0.01-0.41,0.35-0.17c-0.21-0.29,0.22,0.08,0.2-0.8c0.07,0.06-0.04-0.04,0.4-0.4c-0.15-0.11-0.05-0.26-0.02-0.4
				c0.1,0.05,0.12,0.09,0.14,0.18c-0.01-0.02,0.08-0.02,0.02-0.29l0.11,0.09c0.07-0.15,0-0.37,0.18-0.52
				c-0.11-0.07-0.4-0.01-0.37-0.25c0.07,0.02,0.17,0.07,0.17,0.14c0-0.02-0.02-0.09,0.03-0.11l0.06,0.13
				c-0.02-0.07,0.13-0.27,0.09-0.04c0.06-0.07,0.16-0.14,0.24-0.13c-0.32-0.19,0.33-0.02,0.18-0.74l0,0c0.03-0.32,0,0,0.48-0.72
				c0.13,0.15,0.14-0.19,0.57-0.71c-0.01,0.15,0.09,0.01-0.03,0.14c0.13-0.11,0.21-0.09,0.22-0.31c0.06,0.12,0.2,0,0.22-0.08
				l-0.14,0.03c0.4-0.27,0.15,0.05,0.81-0.68c-0.66-0.1,0.58-0.73,0.52-0.56l-0.03-0.06c0.1-0.01,0.25-0.16,0.28-0.05
				c0.05-0.12-0.03-0.11-0.11-0.07c0.2-0.07,0.23-0.21,0.42-0.3c-0.11,0.03,0.15-0.79,0.41-0.22c0.07-0.21,0.32-0.18,0.41-0.24
				l-0.04,0.1c0.03-0.06,0.06-0.09,0.1-0.13l-0.12-0.05c0.26-0.08,0.13,0.05,0.24-0.41c-0.31,0.11,0.33,0.54,0.26-0.16
				c0.04,0.09,0.16,0.08,0.17,0.04c-0.03,0.07-0.18-0.1,0.04-0.4l-0.09,0.04c-0.04-0.09,0.03-0.13,0.09-0.23
				c0.02,0.02,0.27-0.01,0.88-0.39c-0.13-0.04-0.11-0.01-0.1-0.19l-0.17,0.3l0.02-0.28c-0.07,0.07-0.09,0.21-0.15,0.24
				c0-0.14,0.01-0.11-0.09-0.15c0.37-0.44,0.52,0.3,0.95-0.73c-0.12,0.01-0.2,0.08,0.03-0.22c0.07,0.07,0.14,0.04,0.21,0.04
				c-0.06-0.04,0.14-0.18,0-0.32c0.28-0.12,0.28,0.03,0.3-0.61c0.05,0.12,0.17,0.07,0.14,0.25c0.07-0.25-0.01,0.06,0.07-0.74
				c0.28-0.04,0.38-0.13,0.88-1.16c0.02,0.02,0.04,0.02,0.02,0.05c0.15-0.51,0.08,0.01,0.47-0.66l0,0.05
				c0.16-0.07,0.03-0.34,0.14-0.52c0,0,0.02,0.02,0.04,0.02c-0.09-0.2-0.03-0.47,0.01-0.67c0.04,0.03,0.07,0.04,0.09,0.05
				c-0.08-0.07-0.15-0.19,0.24-0.25c-0.22,0.42,0.44-0.37,0.33-1.48c0.1,0.14,0.1,0.37,0.14,0.52c0.03-0.08,0.06-0.22,0.04-0.33
				c0,0.02,0,0.04-0.02,0.06c0.02-0.86-0.08-0.06,0.89-2.1c-0.11,0.12-0.41-0.43,0.05-0.21c-0.13,0.08-0.12-0.14-0.22-0.12
				c0.11-0.11,0.3-0.22,0.56-0.26c-0.11,0-0.13-0.11-0.06-0.22l-0.18,0.13c-0.08-0.32,0.31-0.18,0.23-0.51l0.07,0.11
				c-0.02-0.16,0.14-0.54-0.07-0.75c0.04-0.02,0.13-0.03,0.14,0.04c0.17-0.23,0.07-0.42,0.23-1.52l0.07,0.09
				c-0.14-1.04,0.5-0.69,0.7-3.43c-0.21-0.38,0.06-0.2,0.12-2.17c0.26-0.08,0.13,0.05,0.24-0.65l0.09,0.07
				c-0.27-0.53-0.21-0.15-0.06-1.35c0.03,0.01,0.09,0.02,0.09,0.07c0.02-0.14,0.2-0.54-0.09-0.51c0.19,0.02,0.05-0.31-0.02-0.48
				l0.14,0.04c-0.04,0-0.11-0.18-0.2-0.64l0.08,0.08c0.2-1.29-0.26-1.21,1.72,1.34c-0.57,0.63-0.37,0.05,1.26,2.13l-0.22,0.04
				c1.06,0.65,1.28,1.95,4.45,4.35l-0.09-0.02c0.22,0.26,0.48,0.01,0.63,0.3c-0.34-0.06,0.19,0.28,3.64,2.85
				c0.24-0.22-0.44,0.1,1.04,0.61c-0.05,0.04-0.15,0-0.25-0.01c0.38,0.06-0.03,0.17,0.32,0.22c-0.02-0.05,0.07-0.06,0.09-0.09
				c0.09,0.16,0.39,0.37,0.64,0.47l-0.1,0.06c0.39,0.27,0.86,0.48,1.32,0.75c0.82,0.6-0.22-0.06,6.62,3.41l-0.03,0
				c2.58,0.91-2.36-0.6,7.76,3.05l-0.2,0.11c0.87,0.09,1.66,0.55,2.52,0.63c-0.06,0.02-0.07,0.07-0.15,0.05
				c0.14,0.16,0.63,0.19,0.66,0.1c-0.04,0.06,0.74,0.35,2.36,0.57c-0.08,0.21,0.48,0.32,0.7,0.27c-0.08,0.05,0.15,0.11,0.05,0.18
				c0.22,0.05,0.43,0.07,0.42-0.06c0.64,0.41,0.45,0.28,2.89,0.62c-0.72,0.33-1.16-0.1,2.21,0.64c-0.01,0.02-0.07,0.03-0.12,0.03
				c0.3-0.03-0.05,0.01,0.63-0.28c-0.24,0.24,0.46,0.2,0.11,0.49c0.07,0,0.16-0.03,0.16-0.1c0.09,0.04,0.11,0.09-0.01,0.14
				c0.36,0.04,0.26-0.1,0.58-0.01c-0.01,0.05-0.08,0.05-0.13,0.1c0.18-0.05,0.37-0.1,0.62,0.01c-0.02,0.02,0,0.07-0.08,0.05
				c0.46,0.11,0.84-0.04,1.31,0.2c-0.21,0.17,0.15,0.12,0.12,0.3l0.28,0.02l-0.08-0.12c0.23,0.07,0.48,0,0.66,0.12l-0.19,0.02
				l0.45,0.09l-0.23-0.14c0.76-0.11,0.03-0.19,1.75,0.43c-0.2,0.08,0.17,0.39,0.44,0.17c-0.03,0-0.02,0.02-0.09,0.02
				c0.43,0.13,0.58,0.22,0.7,0.31l-0.12-0.02c0.48,0.22-0.06,0,1.84,0.58l-0.08-0.05c0.16-0.22,0.31,0.26,0.6,0.24
				c-0.03,0.28-0.11,0.06,1.12,0.41l-0.09,0.04c0.88,0.31,0.68,0.4,2.54,1.22c-0.17-0.01-0.27-0.04,0.36,0.18
				c0.02-0.12-0.03-0.09-0.08-0.22c0.09-0.03,0.25-0.01,0.36,0.05c-0.25-0.04-0.09,0.16-0.03,0.27c0-0.04,0.05-0.06,0.06-0.09
				c0.11,0.35,0.07-0.08,0.37,0.16c-0.09-0.02,0.72,0.18,0.19,0.2c0.18,0.1,0.49,0,0.58,0.21c-0.1-0.01-0.21-0.08-0.3-0.09
				c0.1,0.24,0.14,0.22,0.26,0.32l-0.01-0.01l0.27,0.24l-0.06-0.08c0.41-0.15,0.5,0.21,0.92,0.23c-0.18-0.05-0.43,0.26,0.33,0.63
				c-0.12,0,1.29,0.65,2.56,1.76c-0.14,0.06-0.4,0.11,0.62,0.59l-0.07-0.13c-0.02,0.06-0.26,0.19-0.25-0.18l0.12,0.07
				c0.09-0.3-0.42-0.37-0.63-0.55c0.31-0.01,0.36-0.24-0.36-0.62c0.06-0.01,0.07-0.13,0.14-0.03c-0.2-0.32-0.54-0.24-0.55-0.45
				l0.03,0.01c-0.42-0.31-1.18-1.06-2.93-1.93c0.05-0.04-0.46-0.39-0.68-0.6l0.02-0.02c-0.14,0.13-0.39-0.04-0.61-0.11
				c0.06-0.09,0.12-0.11,0.25-0.1c-0.04,0.01,0-0.09-0.46-0.11l0.11-0.1c-0.25-0.12-0.57-0.11-0.85-0.36
				c-0.07,0.11,0.09,0.45-0.28,0.35c0.02-0.07,0.06-0.18,0.16-0.16c-0.03-0.01-0.13,0-0.17-0.06l0.18-0.04
				c-0.09,0.01-0.44-0.22-0.09-0.11c-0.12-0.09-0.26-0.21-0.28-0.3c-0.51,0.14,0.01-0.01-1.21-0.32l0,0
				c-0.36,0.06-1.02-0.67-2.73-1.15c0.23-0.01-0.04-0.1,0.23,0.01c-0.25-0.13-0.28-0.23-0.61-0.2c0.14-0.09-0.14-0.22-0.28-0.23
				l0.15,0.15c-0.15-0.03-0.26-0.08-0.38-0.14l0.12-0.13c-0.56-0.28-1.21-0.42-2.52-0.67c0.11,0.43-0.46-0.59-0.49-0.21
				c-0.3-0.18-0.5-0.15-0.81-0.3c0.07,0.1-1.11,0.17-0.69-0.31c-0.33,0.03-0.54-0.23-0.71-0.29l0.17,0c-0.1,0-0.18-0.02-0.26-0.04
				l0.06,0.14c-0.09-0.05-0.17-0.07-0.15-0.11c-0.21,0.09-0.07,0.42-0.59,0.06c0.36,0.15,0.03-0.14,0.31-0.1
				c-0.06-0.01-0.2-0.03-0.16-0.09c-0.34-0.24-0.22,0.13-0.61,0.02c0.07-0.07-0.05-0.19-0.12-0.19c0.12,0.02-0.03,0.22-0.54,0.13
				l0.14,0.07c-0.36,0.18-0.61-0.3-1.66-0.7c0.06,0.14,0.08,0.11-0.17,0.17l0.54,0.05l-0.38,0.08c0.16,0.04,0.35,0.01,0.44,0.06
				c-0.18,0.05-0.16,0.03-0.11,0.14c-0.81-0.22-0.16-0.63-1.76-0.7l0.14,0.09c-0.09,0.1-0.3-0.06-0.47-0.06
				c0.04-0.09-0.05-0.15-0.1-0.23c-0.01,0.07-0.35-0.1-0.45,0.08c-0.39-0.28-0.15-0.28-1.07-0.16c0.14-0.07-0.01-0.19,0.26-0.19
				c-0.52,0.03,0.2,0.08-3.31-0.72c0.02-0.02,0.01-0.05,0.06-0.02c-0.84-0.13,0-0.04-1.21-0.47l0.07,0
				c-0.17-0.16-0.52-0.02-0.83-0.15c0,0,0.02-0.02,0.02-0.05c-0.27,0.09-0.7,0.01-1.03-0.05c0.02-0.05,0.04-0.07,0.06-0.1
				c-0.08,0.08-0.25,0.15-0.45-0.27l0.26,0.08c-0.08-0.21-0.34-0.11-0.46-0.18l0.04-0.07c-0.4-0.11-0.48,0.07-0.9-0.09l0.02,0.07
				c-0.64-0.06-0.13-0.3-1.33-0.41c0.21-0.08,0.55-0.03,0.78-0.04c-0.15-0.06-0.52-0.17-0.63-0.11c0.07,0,0.16-0.01,0.21,0.04
				c-0.36-0.13-2.34-0.5-4.2-2.16c0,0.12-0.17,0.1-0.33-0.02l0.19,0.24c-0.5-0.05-0.26-0.4-0.76-0.45l0.18-0.03
				c-0.23-0.2-0.39-0.08-2.68-1.05l0.07-0.05c-0.33-0.06-0.09-0.14-0.72-0.28l0.15-0.02c-0.46-0.35-0.87-0.23-1.31-0.4
				c0.1-0.19-0.25-0.47-0.43-0.61l0.02-0.02c-0.69-0.59-0.28,0.44-3.15-1.8c0,0.04-0.21,0.2-0.46-0.11
				c0.07,0.05-0.19-0.65-0.63-0.27c-0.05-0.37-0.81-0.4-2.75-2.13l0.14-0.02c-0.9-0.22-0.39-0.02-1.85-1.11
				c0.03-0.02,0.07-0.06,0.14-0.02c-0.18-0.14-0.6-0.66-0.72-0.38c0.12-0.15-0.37-0.34-0.63-0.44l0.12-0.08
				c-0.03,0.03-0.33-0.09-0.91-0.46l0.14,0.01c-0.23-0.17-0.44-0.41-1.5-1.48c0.23,0.24,0.42,0.43,0.39,0.42
				c0.09-0.09-0.08-0.3,0.19-0.27c-0.07-0.18-0.58-0.07-1.22-1.14c-0.1,0.1-0.2-0.04-0.18,0.15c-0.02-0.04,0.04,0.04,0.14,0.1
				c0,0-0.01,0-0.02,0c0.06,0.06,0.14,0.15,0.23,0.24c-0.14,0.05-0.04-0.29-0.59-0.22c0.06-0.6-0.03-0.53-1.07-1.7
				c0.06,0.04,0.11,0.11,0.16,0.11c-0.19-0.15-0.43-0.54-0.61-0.48c-0.09-0.29,0.23,0.04-0.01-0.28l0.08,0.01
				c-0.18-0.8-1.09-0.85-1.17-1.81c0.01,0.06,0.01,0.1-0.01,0.12c0.3-0.16-1.7-2.21-0.82-4.94c-0.13,0.38-0.1,0.25,0.23-0.76
				c0.04,0.85,0.34-1.51,1.01-0.59c0.07-0.01,0.3,0.04,0.4,0.11c-0.35,0.04,0.34,0.33,0.11,0.5c0.28-0.06,0.54,0.33,0.85,0.42
				c-0.03,0-0.08,0.01-0.09-0.02c0.12,0.12,0.11,0.21,0.17,0.32c-0.13-0.13,1.07-0.02,0.26,0.27c0.09-0.13,0.38,0.11,0.41-0.04
				c0.05,0.19,0.07,0.13,0.04,0.31c0.06-0.28,0.84,0.38,0.32,0.4c0.24-0.27,0.85,0.66,1.99,0.69c-0.37,0.9-0.15,0.15,1.74,1.27
				l-0.18,0.12c0.9,0.12,1.18,0.87,4.97,2.19l-0.08,0.01c0.25,0.16,0.41-0.18,0.62,0.04l-0.06,0.01c0.02,0.22,0.47,0.26,3.88,1.33
				c0.13-0.28-0.33,0.24,1.04,0.19c-0.03,0.06-0.12,0.06-0.21,0.08c0.34-0.08,0.02,0.17,0.33,0.1c-0.03-0.04,0.04-0.09,0.05-0.11
				c0.12,0.12,0.43,0.18,0.67,0.19l-0.07,0.1c0.4,0.1,0.86,0.13,1.31,0.21c-0.03-0.08,0.15-0.39,0.14-0.07
				c0.09-0.09,0.19-0.32,0.05,0.06l0.23-0.02c0.23,0.58-0.04,0.07,6.08,0.74l-0.03,0.01c1.32-0.01,0.02-0.06,3.41,0.32
				c0.01-0.11-0.03-0.18,0.2-0.01c0.3-0.67,0.73,0.53,3.67-0.13l-0.13,0.17c0.74-0.21,1.52,0,2.25-0.19
				c-0.04,0.04-0.04,0.09-0.11,0.09c0.16,0.12,0.57-0.01,0.57-0.1c0.32,0.03-0.1,0.17,2.11-0.15c-0.01,0.23,0.48,0.17,0.65,0.06
				c-0.06,0.07,0.15,0.07,0.09,0.16c0.2-0.01,0.38-0.06,0.34-0.17c0.63,0.21,0.43,0.15,2.58-0.21c-0.51,0.51-1.03,0.23,2.01,0
				c0,0.02-0.05,0.05-0.09,0.06c0.24-0.12-0.04,0.02,0.46-0.44c-0.14,0.3,0.44,0.06,0.21,0.44c0.06-0.02,0.13-0.07,0.11-0.14
				c0.09,0.02,0.11,0.06,0.03,0.14c0.31-0.06,0.2-0.17,0.48-0.17c0,0.05-0.06,0.07-0.08,0.13c0.14-0.1,0.28-0.2,0.52-0.16
				c-0.01,0.03,0.01,0.07-0.06,0.07c0.41-0.02,0.69-0.28,1.15-0.19c-0.13,0.22,0.15,0.07,0.18,0.26l0.24-0.06l-0.1-0.09
				c0.21,0,0.4-0.14,0.58-0.08l-0.16,0.08l0.4-0.04l-0.23-0.07c0.6-0.32-0.03-0.19,1.57-0.12c-0.15,0.13,0.24,0.33,0.41,0.03
				c-0.03,0.01-0.01,0.03-0.07,0.05c0.39-0.02,0.55,0.02,0.66,0.06l-0.11,0.02c0.55,0.18,0.63-0.27,3.22-0.06l-0.06,0.07
				c0.8-0.05,0.69,0.13,2.47,0.15c-0.13,0.06-0.24,0.07,0.36,0.02c-0.03-0.17-0.33-0.19,0.16-0.27c-0.21,0.06-0.01,0.19,0.07,0.26
				c-0.01-0.04,0.02-0.07,0.02-0.11c0.2,0.29,0.05-0.12,0.35,0c-0.07,0.02,0.64-0.16,0.22,0.1c0.18,0.01,0.39-0.22,0.54-0.08
				c-0.08,0.03-0.2,0.02-0.28,0.05c0.17,0.17,0.19,0.13,0.33,0.17l-0.01,0l0.31,0.1l-0.08-0.05c0.26-0.33,0.47-0.01,0.81-0.21
				c-0.27,0.16-0.12,0.11,0.05,0.42c0.16-0.1,0.23,0.12,0.46-0.03c-0.09,0.07,1.3-0.08,2.72,0.2
				C276.17,462.78,276.02,462.75,276.54,462.79z M223.79,456.82c0.02-0.02,0,0,0.06-0.05C223.9,456.79,224.01,456.93,223.79,456.82z
				 M216.43,384.05c-0.02-0.02,0,0-0.04-0.06C216.42,383.95,216.52,383.9,216.43,384.05z M207.8,401.88c-0.02,0.02,0,0-0.06,0.04
				C207.71,401.9,207.67,401.8,207.8,401.88z M207.81,400.88c0.03-0.02-0.01-0.08-0.03-0.15C207.75,400.74,208,400.81,207.81,400.88
				z M208.05,400.93c0.03,0.01,0,0,0.07,0.04C208.1,400.96,208.08,400.95,208.05,400.93z M217.62,383.96
				c-0.01-0.03-0.1,0.22-0.15,0.03C217.48,384.03,217.56,383.98,217.62,383.96z M217.41,384.24c-0.01,0.04,0,0-0.04,0.08
				C217.38,384.3,217.39,384.28,217.41,384.24z M214.61,389.72c0.2,0.23,0.11-0.3,0.37-0.17c-0.18-0.19,0.05-0.43,0-0.67
				c0.01,0.02,0.04,0.05,0.02,0.07c0.06-0.1,0.14-0.11,0.21-0.16c-0.18-0.09-0.15-0.27-0.22-0.41c0.23-0.06,0.18,0.17,0.36,0.2
				c-0.15-0.05-0.05-0.29-0.19-0.3c0.15-0.05,0.09-0.06,0.26-0.05c-0.22-0.12,0-0.11-0.1-0.25c0.13,0.02,0.16-0.31,0.35,0
				c-0.21,0.05,0.41-0.53,0.1-1.49c-0.03,0.04,0.01,0.07,0.03,0.11l0-0.1c0.04,0.47,0.43-0.18,0.18,0.05
				c1.02,0.47-0.67-0.33,3.05-4.04l0,0.07c0.18-0.1-0.06-0.37,0.17-0.41l-0.01,0.04c0.56-0.23,0.16-0.26,2.73-2.25
				c0.01,0.02,0.01,0.04,0,0.06c0.05-0.01,0.16,0.15,0.11-0.12c0.13,0.01,0.31-0.1,0.42-0.23l0.03,0.1
				c0.25-0.13,0.46-0.38,0.73-0.54c-0.06-0.03-0.18-0.38,0.02-0.12c-0.01-0.11-0.11-0.35,0.06,0.03l0.1-0.13
				c0.03,0.04,0.02,0.08,0.04,0.13c0.59-0.41,0.09,0.05,1.65-1.17c0.05,0.12,0.26,0.04,0.18,0.16c0.25-0.1,0.31-0.13,0.62-0.3
				c-0.09-0.07-0.06-0.19,0.06,0c0.37-0.26,0.85,0.29,1.11,0.07l-0.02,0.01c0.15-0.03,0.55,0.18,1.27,1.14
				c0.48-0.47,0.14,0.52-0.32,3.58c-0.03-0.04-0.07-0.06-0.06-0.1c-0.15,0.06-0.14,0.35-0.05,0.39c-0.1,0.19-0.06-0.18-0.51,1.35
				c-0.19-0.11-0.32,0.2-0.28,0.35c-0.04-0.07-0.11,0.05-0.17-0.03c-0.06,0.12-0.09,0.24,0.02,0.28c-0.43,0.22-0.23,0.13-0.91,1.53
				c-0.19-0.57,0.37-0.73-0.95,1.02c-0.01-0.01-0.01-0.06,0-0.09c-0.03,0.2,0.01-0.04,0.09,0.53c-0.14-0.28-0.27,0.17-0.42-0.21
				c-0.01,0.04-0.01,0.11,0.04,0.15c-0.06,0.03-0.1,0.01-0.11-0.08c-0.11,0.19,0.02,0.22-0.12,0.35c-0.03-0.04-0.02-0.08-0.04-0.13
				c0,0.14-0.01,0.28-0.16,0.36c-0.01-0.03-0.05-0.04-0.02-0.08c-0.19,0.21-0.19,0.52-0.49,0.66c-0.07-0.22-0.13,0.02-0.26-0.12
				l-0.09,0.15l0.11,0.03c-0.12,0.09-0.13,0.28-0.27,0.31l0.04-0.13l-0.19,0.2l0.17-0.04c-0.13,0.29,0.24,0.22-0.58,0.32
				c0.04,0.17-0.25,0.24-0.23,0.43c0-0.05-0.05-0.13-0.07-0.14l0.02,0.14c-0.08,0.01-0.1,0-0.14-0.07c-0.18,0.1,0.19,0.16-0.07,0.21
				c0.01-0.02-0.01-0.03,0.01-0.07c-0.23,0.18-0.34,0.21-0.42,0.21l0.06-0.07l-0.12-0.02l0.02,0.09c-0.09-0.22-0.25,0.56-1.79,1.19
				l0-0.08c0.01,0.04-0.39-0.05-0.45,0.33c-0.55-0.15-0.65,0.55-1.13,0.41c0.12-0.04-0.3-0.06-0.21,0.4c0.1-0.13-0.08-0.17-0.16-0.2
				c0.02,0.03,0.02,0.07,0.04,0.1c-0.23-0.21-0.01,0.17-0.21,0.13c0.03-0.03-0.34,0.36-0.18-0.01c-0.12,0.06-0.14,0.33-0.3,0.26
				c0.04-0.06,0.11-0.09,0.15-0.14c-0.18-0.1-0.18-0.05-0.28-0.04l0.01,0l-0.23,0.02c0.16,0.39,0.03,0.03-0.34,0.49
				c-0.47-0.5,0.01-0.31-2.02,0.57c0.02-0.24-0.06-0.14-0.26-0.12c0.01-1.26-0.08,0.01,0.05-3.51c0.09,0.28-0.18-0.03,0.18-0.14
				C214.67,389.98,214.59,389.81,214.61,389.72z M214.49,387.79c-0.03,0.06,0.02,0-0.08,0.19
				C214.43,387.82,214.45,387.74,214.49,387.79z M214.37,388.17c-0.02,0.06-0.03,0.11-0.05,0.18
				C214.33,388.27,214.34,388.2,214.37,388.17z M214.18,388.89c-0.01,0.04-0.02,0.07-0.03,0.12c-0.01-0.06-0.03-0.11-0.04-0.17
				C214.13,388.88,214.16,388.89,214.18,388.89z M189.65,470.67c0.14-0.13,0.09-0.12,0.38-0.13
				C189.94,470.58,190.02,470.51,189.65,470.67z M191.09,471.25c0.07-0.02,0.16-0.04,0.32-0.09
				C191.28,471.21,191.41,471.16,191.09,471.25z M192.26,470.84c-0.06,0.05,0.21,0.01,0.41-0.01
				C192.52,470.88,192.08,471.05,192.26,470.84z M218.76,463.1c0.03-0.01,0,0,0.08-0.02C218.89,463.13,218.95,463.29,218.76,463.1z
				 M218.89,464.42c-0.04,0,0.02,0.1,0.06,0.19C218.89,464.56,218.68,464.43,218.89,464.42z M218.55,464.21c-0.05-0.04,0,0-0.1-0.1
				C218.46,464.13,218.49,464.16,218.55,464.21z M214.22,450.57c0,0.19,0,0.42-0.04,0.7C214.19,451.17,214.16,451.13,214.22,450.57z
				 M214.22,450.55c-0.03-0.02-0.06-0.06,0-0.07C214.22,450.51,214.22,450.53,214.22,450.55z M213.48,452.64
				c0.03,0.06,0.07,0.04-0.01,0.16L213.48,452.64z M213.44,457.97c0.03,0.02-0.05-0.24,0.11-0.11
				C213.52,457.84,213.48,457.92,213.44,457.97z M214.17,458.05c0.01-0.06,0.04-0.13,0.08-0.22
				C214.24,457.93,214.27,457.89,214.17,458.05z M214.05,442.75c0,0.01,0.01,0.03,0.01,0.04c-0.02-0.01-0.04-0.03-0.05-0.04
				C214.03,442.76,214.04,442.75,214.05,442.75z M209.71,437.89c-0.02-0.01-0.04-0.02-0.08-0.05
				C209.67,437.85,209.63,437.84,209.71,437.89z M209.37,437.77c0.04-0.01-0.02-0.09-0.04-0.15
				C209.29,437.63,209.57,437.72,209.37,437.77z M209.3,438.77c0.02,0,0,0.03,0.1,0.04C209.37,438.83,209.31,438.9,209.3,438.77z
				 M214.06,445c-0.12,0.1,0.02,0.07-0.05,0.16c-0.15-0.14-0.43,0.06-0.46-0.18c-0.06,0.11,0.15,0.09-0.02,0.17
				c0.09-0.32-0.4,0.15-0.29-0.27c-0.2,0.3-0.02-0.25-0.86,0.09c0.03-0.09-0.03,0.05-0.54-0.23c-0.05,0.18-0.24,0.13-0.38,0.16
				c0.01-0.11,0.04-0.15,0.12-0.19c-0.02,0.02-0.05-0.07-0.3,0.09l0.04-0.13c-0.17-0.01-0.36,0.13-0.58,0.01
				c-0.03,0.13,0.14,0.37-0.1,0.43c0-0.07,0-0.19,0.07-0.21c-0.02,0.01-0.08,0.05-0.12,0.02l0.11-0.11
				c-0.06,0.05-0.31-0.02-0.07-0.07c-0.09-0.03-0.19-0.1-0.22-0.17c-0.06,0.37-0.15-0.3-0.79,0.11l0,0
				c-0.23,0.18-0.7-0.29-1.83-0.35c0.15-0.06-0.03-0.08,0.15-0.04c-0.16-0.07-0.19-0.16-0.39-0.06c0.09-0.11-0.1-0.18-0.19-0.16
				l0.1,0.11c-0.46-0.23-0.03-0.17-1.06-0.39c0.28,0.62-0.97-0.11-0.8-0.15l-0.03,0.05c-0.06-0.08-0.28-0.12-0.21-0.21
				c-0.13,0.03-0.07,0.08,0.01,0.13c-0.18-0.13-0.32-0.08-0.51-0.19c0.08,0.08-0.77,0.3-0.42-0.23c-0.22,0.06-0.33-0.17-0.43-0.22
				l0.11-0.02c-0.07,0.01-0.11-0.01-0.16-0.02l0.02,0.13c-0.2-0.18-0.03-0.14-0.48,0c0.26,0.21,0.3-0.56-0.27-0.14
				c0.06-0.08,0-0.18-0.05-0.17c0.07-0.01,0,0.21-0.37,0.15l0.08,0.06c-0.41,0.02-0.04,0.11-0.97-0.61
				c0.01,0.13,0.03,0.11-0.14,0.17l0.35,0.04l-0.26,0.09c0.09,0.04,0.23,0,0.28,0.04c-0.13,0.06-0.11,0.03-0.1,0.14
				c-0.56-0.17,0.08-0.57-1.03-0.63c0.05,0.11,0.14,0.17-0.22,0.03c0.04-0.09,0-0.15-0.02-0.22c-0.02,0.07-0.21-0.09-0.31,0.08
				c-0.18-0.25-0.03-0.27-0.67-0.15c0.11-0.07,0.04-0.18,0.21-0.18c-0.26-0.02,0.06,0-0.74,0.05c-0.07-0.29-0.17-0.3-1.19-0.87
				c0.02-0.02,0.03-0.04,0.05-0.02c-0.47-0.2,0-0.04-0.56-0.55l0.04,0.01c-0.03-0.16-0.31-0.1-0.45-0.26c0,0,0.03-0.01,0.03-0.03
				c-0.21,0.02-0.45-0.13-0.6-0.26c0.05-0.04,0.09-0.06,0.11-0.07c-0.28-0.06,0.02-0.37-0.85-1.52c0.14,0.08,0.28,0.25,0.38,0.34
				c-0.01-0.08-0.08-0.28-0.18-0.31c0.02,0.13,0.03,0.16-0.21-0.31c0.37,0.04,0.13-0.48,0.47-0.53c-0.06-0.33,0.25-0.51,0.44-0.77
				c-0.22-0.12-0.21-0.26-0.07-0.33c0.03,0.07,0.06,0.23,0.19,0.19c-0.14,0-0.05-0.17-0.13-0.21c0.14-0.04,0.34-0.02,0.56,0.14
				c-0.09-0.07-0.04-0.16,0.07-0.18l-0.2-0.03c0.13-0.27,0.32,0.08,0.46-0.18l-0.03,0.12c0.09-0.11,0.42-0.22,0.47-0.49
				c0.04,0.02,0.1,0.09,0.05,0.13c0.24,0.04,0.38-0.19,1.37-0.46l-0.06,0.1c0.94-0.42,0.71,0.15,3.34,0.25
				c0.4-0.16,0.19,0.03,2.12,0.43c0.02,0.28-0.08,0.11,0.57,0.41l-0.1,0.07c0.58-0.11,0.21-0.18,1.3,0.36
				c-0.02,0.03-0.05,0.08-0.1,0.06c0.13,0.06,0.41,0.39,0.51,0.11c-0.09,0.17,0.26,0.17,0.45,0.18l-0.09,0.11
				c0.02-0.04,0.21-0.03,0.66,0.11l-0.11,0.04c0.38,0.28,0.2,0.11,1.07,0.78c-0.17-0.13-0.3-0.23-0.28-0.23
				c-0.26,0.16,0.19,0.63,0.87,0.66c0.01,0.02-0.03-0.02-0.11-0.04l0.01-0.01c-0.04-0.03-0.1-0.08-0.17-0.13
				c0.1-0.01,0.19-0.01,0.21,0.11c0.09-0.04,0.19-0.08,0.24-0.1c0.08,0.5-0.32,0.58,0.79,1.01c-0.05-0.01-0.08-0.05-0.13-0.04
				c0.15,0.05,0.33,0.28,0.49,0.18c-0.01,0.88-0.13,0.13,0.92,1.19c-0.01-0.04-0.01-0.07,0.01-0.09
				C212.11,441.51,213.23,442.19,214.06,445z M223.79,457.85c0.02,0.01,0.06,0.02,0.12,0.04
				C223.86,457.88,223.91,457.9,223.79,457.85z M224.27,457.94c-0.04,0.02,0.06,0.09,0.11,0.16
				C224.43,458.09,224.05,458.04,224.27,457.94z"
                />
                <path
                  className="st10"
                  d="M368.39,393.09c0.83-0.02,0.2-0.51,0.63-1.3c-0.2,0.05-0.15,0.04-0.64,0.52c-0.26-0.45-0.25,0.4-0.42,0.02
				c-0.11,0.14-0.36,0.23-0.37,0.38c-0.2-0.04-0.46,0.29-0.55,0.09c-0.02,0.07-0.17,0.28-0.3,0.36c0.12-0.36-0.5,0.24-0.57-0.04
				c-0.07,0.3-0.59,0.43-0.85,0.71c0.01-0.03,0.03-0.08,0.06-0.08c-0.19,0.07-0.27,0.04-0.4,0.06c0.21-0.09-0.53,1.05-0.41,0.15
				c0.08,0.14-0.31,0.33-0.17,0.41c-0.22-0.02-0.17,0.02-0.33-0.08c0.25,0.17-0.84,0.65-0.57,0.14c0.09,0.36-1.04,0.48-1.86,1.56
				c-0.59-0.64,0-0.36-2.37,0.89l0.02-0.22c-0.99,0.82-2.13,0.86-5.79,2.59l0.06-0.07c-0.34,0.07-0.24,0.42-0.59,0.41
				c0.07-0.17-0.13-0.14-5.65,1.99c-0.01-0.06,0.09-0.12,0.16-0.19c-0.29,0.27-0.12-0.13-0.38,0.12c0.05,0.02,0.01,0.09,0.01,0.13
				c-0.19-0.03-0.54,0.09-0.79,0.21l0.02-0.12c-0.46,0.13-0.95,0.37-1.46,0.55c-1.02,0.2,0.2-0.1-7.31,2.3l0.03-0.02
				c-2.68,0.69,2.34-0.5-8.33,2.08l0.12-0.19c-0.82,0.39-1.76,0.36-2.57,0.7c0.04-0.05,0.03-0.1,0.12-0.11
				c-0.2-0.08-0.66,0.13-0.65,0.22c0.01-0.07-0.84,0.05-2.45,0.58c-0.02-0.23-0.59-0.07-0.77,0.07c0.05-0.08-0.19-0.04-0.13-0.14
				c-0.23,0.05-0.43,0.13-0.37,0.24c-0.79-0.08-0.55-0.05-3,0.73c0.53-0.61,1.12-0.45-2.36,0.44c0-0.02,0.05-0.06,0.1-0.08
				c-0.26,0.17,0.04-0.03-0.45,0.54c0.11-0.33-0.53,0.04-0.33-0.38c-0.06,0.03-0.14,0.1-0.11,0.16c-0.1,0-0.14-0.03-0.06-0.13
				c-0.35,0.14-0.2,0.21-0.53,0.29c-0.01-0.05,0.05-0.08,0.07-0.15c-0.15,0.13-0.29,0.27-0.58,0.29c0.01-0.03-0.03-0.06,0.05-0.08
				c-0.47,0.13-0.75,0.45-1.3,0.49c0.1-0.25-0.2-0.02-0.27-0.2l-0.27,0.12l0.14,0.06c-0.25,0.06-0.43,0.25-0.65,0.25l0.16-0.12
				l-0.45,0.16l0.28,0c-0.62,0.5,0.07,0.17-1.78,0.62c0.13-0.19-0.37-0.21-0.48,0.11c0.03-0.02,0.01-0.03,0.07-0.07
				c-0.43,0.16-0.63,0.18-0.78,0.18l0.11-0.06c-0.55,0.11,0.04-0.05-1.89,0.72l0.1-0.01c0.02,0.27-0.42,0.01-0.64,0.22
				c-0.17-0.23,0.04-0.12-1.15,0.48l0.04-0.09c-0.88,0.46-0.83,0.17-2.73,1.18c0.11-0.13,0.21-0.19-0.39,0.19
				c0.09,0.08,0.1,0.03,0.24,0.06c-0.03,0.09-0.15,0.2-0.27,0.26c0.19-0.17-0.09-0.17-0.21-0.17c0.03,0.02,0.02,0.08,0.04,0.1
				c-0.57,0.19,0.05,0.17-1.13,0.71c0.06-0.08,0.18-0.14,0.25-0.22c-0.27-0.03-0.27,0.02-0.43,0.07l0.02,0l-0.37,0.12l0.11-0.01
				c-0.07,0.43-0.46,0.33-0.66,0.73c0.12-0.15-0.05-0.52-0.73,0.08c0-0.11-1.1,1-2.57,2.06c-0.03-0.17-0.06-0.42-0.68,0.6l0.14-0.06
				c-0.28,0,0.3-0.48,0.12-0.12c0.3,0.12,0.4-0.43,0.65-0.59c-0.07,0.31,0.18,0.37,0.72-0.22c-0.01,0.06,0.12,0.1,0,0.15
				c0.36-0.12,0.38-0.48,0.58-0.43l-0.02,0.03c0.29-0.18,1.42-1.05,3.01-1.99c0.01,0.06,0.59-0.21,0.88-0.3l0.01,0.03
				c-0.03-0.19,0.25-0.32,0.44-0.45c0.04,0.1,0.02,0.16-0.06,0.26c0.01-0.04,0.07,0.05,0.37-0.3l0.01,0.15
				c0.25-0.13,0.45-0.39,0.82-0.46c-0.03-0.13-0.42-0.2-0.1-0.44c0.04,0.06,0.1,0.16,0.02,0.23c0.03-0.02,0.08-0.1,0.16-0.11
				l-0.09,0.17c0.05-0.08,0.46-0.22,0.15,0c0.65-0.19,0.25-0.09,1.51-0.71l0,0c0.4-0.31,0,0,1.39-0.53c0.03,0.2,0.44-0.15,1.49-0.48
				c-0.18,0.16,0.1,0.05-0.19,0.14c0.28-0.05,0.36-0.01,0.62-0.22c-0.06,0.15,0.25,0.09,0.37,0.01l-0.22-0.03
				c0.14-0.06,0.27-0.09,0.4-0.12l-0.02,0.18c0.64-0.11,1.24-0.39,2.52-0.89c-0.33-0.3,0.72,0.26,0.55-0.08
				c0.35-0.01,0.52-0.14,0.88-0.17c-0.11-0.05,0.91-0.71,0.77-0.08c0.29-0.19,0.6-0.07,0.79-0.1l-0.15,0.09
				c0.09-0.05,0.17-0.07,0.25-0.09l-0.12-0.09c0.1,0,0.18-0.02,0.19,0.02c0.15-0.18-0.13-0.4,0.52-0.33
				c-0.41,0.04,0.04,0.14-0.23,0.24c0.06-0.02,0.2-0.06,0.19,0.01c0.42,0.05,0.14-0.21,0.55-0.3c-0.03,0.1,0.13,0.14,0.19,0.11
				c-0.12,0.04-0.08-0.21,0.43-0.36l-0.16,0c0.24-0.33,0.7-0.02,1.86-0.13c-0.12-0.1-0.13-0.06,0.08-0.23l-0.53,0.2l0.31-0.24
				c-0.17,0.03-0.33,0.15-0.44,0.15c0.15-0.13,0.14-0.1,0.04-0.18c0.85-0.16,0.44,0.49,1.96-0.16l-0.17-0.02
				c0.05-0.13,0.31-0.08,0.47-0.16c0,0.1,0.11,0.11,0.2,0.16c-0.02-0.07,0.37-0.07,0.38-0.27c0.49,0.08,0.26,0.18,1.07-0.33
				c-0.1,0.13,0.09,0.16-0.16,0.29c0.48-0.27-0.22,0.02,3.43-0.86c-0.01,0.03,0.01,0.05-0.04,0.05c0.84-0.28,0.02,0.04,1.35-0.15
				l-0.06,0.03c0.32,0,0.14,0,1.82-0.69c0,0.05,0,0.07,0,0.1c0.03-0.11,0.16-0.24,0.55,0l-0.28,0.07c0.18,0.14,0.36-0.09,0.52-0.09
				l0,0.08c0.43-0.12,0.39-0.31,0.85-0.4l-0.06-0.05c0.6-0.3,0.29,0.19,1.41-0.38c-0.14,0.18-0.47,0.34-0.67,0.47
				c0.17-0.03,0.56-0.14,0.62-0.26c-0.06,0.03-0.13,0.1-0.21,0.09c1.3-0.59,0.03-0.11,3.76-0.71c-0.13,0.12-0.18-0.84,1.16-0.07
				c-0.07-0.09,0.08-0.18,0.29-0.19l-0.31-0.06c0.44-0.28,0.48,0.14,0.92-0.15l-0.13,0.14c0.33-0.02,0.39-0.17,2.86-1.03l-0.02,0.09
				c0.3-0.19,0.18,0.04,0.75-0.3l-0.1,0.12c0.6-0.08,0.82-0.46,1.28-0.65c0.06,0.21,0.53,0.14,0.77,0.1l0,0.03
				c0.95-0.16-0.12-0.42,3.63-1.23c-0.04-0.03-0.01-0.29,0.4-0.29c-0.09,0.02,0.64,0.26,0.63-0.34c0.6,0.11,0.04-0.14,3.51-1.04
				l-0.06,0.13c0.73-0.66,0.27-0.31,2.04-1.02c0,0.03,0.01,0.09-0.06,0.13c0.23-0.09,0.92-0.22,0.73-0.46
				c0.08,0.17,0.5-0.18,0.73-0.36l0.01,0.15c-0.01-0.05,0.26-0.25,0.87-0.64l-0.07,0.13c0.35-0.18,0.11-0.05,2.06-0.85
				c-0.33,0.14-0.59,0.24-0.57,0.22c0.05,0.12,0.32,0.03,0.18,0.28c0.2-0.14-0.06-0.08,0.78-0.56l-0.02,0.03
				c0.34-0.19,0.23-0.02,0.83-0.29c-0.07-0.12,0.11-0.18-0.1-0.21c0.04-0.01-0.06,0.03-0.15,0.11c0,0,0-0.01,0-0.02
				c-0.08,0.04-0.19,0.09-0.32,0.15c0.73-0.72,0.64-0.01,2.43-1.16c-0.24,0.27-0.26,0.21,0.76-0.37l-0.03,0.08
				c0.89-0.1,1.04-1,2.04-1.08c-0.07,0.01-0.1,0.01-0.13-0.01c0.19-0.01,2.21-1.19,4.88-2.44l-0.11-0.06
				C368.02,393.25,368.36,392.98,368.39,393.09z M356.72,398.05c-0.03,0.06,0.31-0.22,0.22,0.02
				C356.96,398.03,356.82,398.06,356.72,398.05z M357.27,397.82c0.05-0.03,0,0,0.13-0.07C357.37,397.76,357.34,397.78,357.27,397.82
				z M358.28,398.36c0,0.03,0,0-0.01,0.08C358.21,398.48,358.02,398.49,358.28,398.36z"
                />
                <path
                  className="st10"
                  d="M379.43,440.78c-0.63-1.07,0.05-0.41-1.78-2.04c0,0.27-0.13,0.31-0.3,0.24c0.03-0.07,0.2-0.18,0.04-0.27
				c0.12,0.12-0.13,0.13-0.11,0.23c-0.16-0.1-0.33-0.27-0.46-0.47c0.04,0.11-0.08,0.16-0.24,0.14c0.08,0.04,0.15,0.07,0.22,0.12
				c-0.33,0.2-0.35-0.22-0.67,0.01l0.09-0.12c-0.17,0.09-0.65,0.18-0.71,0.48c-0.04-0.02-0.11-0.08-0.05-0.13
				c-0.34,0.02-0.4,0.18-1.41,0.91l0.02-0.11c-0.64,0.85-0.85,0.18-2.97,1.99c-0.14,0.42-0.18,0.1-1.69,1.42
				c-0.24-0.14-0.05-0.12-0.66,0.26l-0.01-0.12c-0.22,0.56,0.02,0.26-0.99,0.96c-0.01-0.03-0.04-0.08-0.01-0.12
				c-0.12,0.08-0.54,0.21-0.33,0.41c-0.11-0.15-0.27,0.17-0.36,0.33l-0.06-0.13c0.03,0.03-0.07,0.2-0.37,0.57l0.01-0.11
				c-0.4,0.26-0.19,0.13-1.11,0.75c0.18-0.12,0.32-0.21,0.31-0.19c-0.06-0.31-0.67,0-0.9,0.65c-0.03,0.01,0.03-0.02,0.07-0.09
				l0,0.01c0.04-0.03,0.1-0.08,0.17-0.13c-0.01,0.1-0.04,0.19-0.16,0.18c0.02,0.1,0.03,0.2,0.03,0.25
				c-0.51-0.04-0.51-0.47-1.16,0.54c0.02-0.05,0.06-0.07,0.06-0.12c-0.08,0.14-0.34,0.27-0.27,0.45c-0.2,0.04-0.01-0.2-0.2-0.03
				l0-0.07c-0.7,0.12-1.64,2.08-0.2-0.99c1.24,0.4,0,0,1.33-1.69l0.12,0.18c0.15-0.91,0.93-1.14,2.42-4.86l0.01,0.08
				c0.17-0.24-0.14-0.41,0.08-0.61l0.01,0.06c0.22-0.02,0.3-0.45,1.5-3.82c-0.27-0.14,0.23,0.34,0.22-1.04
				c0.06,0.03,0.05,0.13,0.07,0.21c-0.07-0.34,0.17-0.01,0.11-0.32c-0.04,0.03-0.08-0.04-0.11-0.06c0.13-0.11,0.19-0.43,0.21-0.67
				l0.09,0.07c0.11-0.4,0.14-0.86,0.23-1.32c-0.08,0.03-0.39-0.15-0.07-0.14c-0.09-0.09-0.32-0.2,0.06-0.05l-0.02-0.23
				c0.5-0.25,0.24,0.06,0.5-6.14l0.01,0.03c-0.2-1.21,0.11-1.5-0.25-4.49l-0.07,0.18c-0.3-1.67,0.2-0.79-2.59,1.86l-0.07-0.2
				c-0.21,0.74-0.79,1.3-1,2.02c-0.01-0.06-0.05-0.08-0.02-0.14c-0.18,0.07-0.29,0.49-0.2,0.54c-0.19,0.26-0.1-0.18-0.95,1.9
				c-0.19-0.13-0.39,0.33-0.38,0.54c-0.03-0.08-0.13,0.1-0.19-0.01c-0.09,0.17-0.14,0.36-0.02,0.38c-0.49,0.45-0.35,0.3-1.08,2.36
				c-0.19-0.69,0.27-1.03-0.95,1.78c-0.02-0.01-0.02-0.06-0.01-0.11c-0.01,0.27,0-0.05,0.18,0.61c-0.2-0.26-0.26,0.36-0.49-0.01
				c-0.01,0.06,0.01,0.15,0.07,0.16c-0.05,0.07-0.1,0.08-0.14-0.04c-0.09,0.31,0.06,0.25-0.06,0.51c-0.04-0.02-0.04-0.08-0.08-0.13
				c0.02,0.17,0.05,0.34-0.09,0.54c-0.02-0.03-0.07-0.02-0.04-0.08c-0.16,0.38-0.06,0.75-0.33,1.12c-0.14-0.21-0.13,0.11-0.31,0.06
				l-0.05,0.24l0.12-0.05c-0.09,0.19-0.05,0.42-0.17,0.56l-0.01-0.18l-0.13,0.38l0.15-0.18c0.04,0.68,0.19,0.05-0.53,1.49
				c-0.06-0.19-0.4,0.09-0.19,0.36c0-0.03-0.02-0.02-0.02-0.09c-0.13,0.37-0.23,0.5-0.31,0.6l0.02-0.12
				c-0.37,0.45-0.02,0.67-1.08,3.05l-0.04-0.08c-0.21,0.78-0.35,0.61-0.88,2.32c-0.02-0.14,0-0.25-0.12,0.34
				c0.17,0.02,0.26-0.27,0.22,0.23c0-0.22-0.18-0.06-0.27,0c0.04,0,0.07,0.04,0.1,0.04c-0.33,0.12,0.1,0.08-0.08,0.34
				c0-0.07-0.01,0.66-0.15,0.19c-0.04,0.15,0.05,0.33,0,0.46c-0.06,0.12,0.16-0.75-0.52-1.39c1.09-0.15,0.24,0.18-0.18-3.38
				c0.03,0.36,0.24-0.38-0.36-2.87l0.05,0.05c0.01-0.25-0.35-0.22-0.25-0.46l0.03,0.04c0.23-0.07-0.03-0.54-0.55-3.41
				c-0.3,0.02,0.36,0.13-0.27-0.85c0.06,0,0.1,0.07,0.16,0.12c-0.22-0.21,0.15-0.09-0.05-0.28c-0.03,0.04-0.09,0.01-0.12,0.01
				c0.06-0.14,0-0.4-0.09-0.58l0.11,0.01c-0.06-0.34-0.23-0.69-0.34-1.07c-0.05,0.06-0.42,0.05-0.11-0.07
				c-0.11-0.03-0.37-0.02,0.04-0.06l-0.1-0.16c0.04-0.02,0.08,0.01,0.14,0c-0.29-0.86,0.12-0.08-1.03-2.34
				c0.12-0.05,0.02-0.33,0.15-0.21c-1-1.42,0.27-2.03-3.8-3.85c0.1,0.12,0.13,0.07-0.17,0.25c0.57,0.26-1.37,1.36-0.92,3.23
				l-0.15-0.14c0.1,0.69-0.11,1.37,0.09,2.01c-0.04-0.04-0.09-0.04-0.09-0.1c0.1,0.86,0.09,0.1,0.44,2.3
				c-0.23,0.02-0.11,0.44,0.03,0.56c-0.08-0.04-0.04,0.14-0.15,0.1c0.04,0.17,0.11,0.31,0.22,0.26c-0.11,0.56-0.09,0.37,0.6,2.11
				c-0.56-0.32-0.42-0.84,0.34,1.67c-0.02,0-0.05-0.03-0.08-0.06c0.16,0.17-0.03-0.03,0.51,0.28c-0.32-0.05,0.02,0.38-0.39,0.27
				c0.03,0.04,0.09,0.09,0.16,0.06c0,0.07-0.03,0.11-0.13,0.05c0.12,0.24,0.2,0.13,0.26,0.36c-0.05,0.01-0.08-0.03-0.14-0.04
				c0.12,0.09,0.25,0.19,0.26,0.4c-0.03,0-0.06,0.03-0.08-0.03c0.1,0.33,0.4,0.51,0.41,0.9c-0.24-0.05-0.04,0.14-0.21,0.21
				l0.11,0.18l0.07-0.1c0.04,0.17,0.21,0.29,0.2,0.46l-0.11-0.11l0.12,0.32l0.02-0.2c0.55,0.24-0.28,0.09,0.46,1.26
				c-0.22-0.06-0.22,0.29,0.06,0.34c-0.02-0.02-0.03,0-0.06-0.05c0.12,0.32,0.11,0.46,0.1,0.56l-0.05-0.09l-0.05,0.12l0.09,0.01
				c-0.14,0.19,0.28,0.17,0.86,2.45c-0.3-0.09-0.03-0.5-0.86-0.75c0.02,0.05,0.07,0.04,0.11,0.04l-0.09-0.07
				c0.45,0.31,0.07-0.52,0.14-0.12c0.8-0.18-0.51-0.93-1.43-2.82c0.16,0.35,0.11-0.45-1.32-2.43l0.07,0.03
				c-0.08-0.23-0.39-0.06-0.4-0.32l0.04,0.02c0.2-0.15-0.24-0.49-1.8-2.79c-0.17,0.21,0.18-0.27-0.59-0.62
				c0.05-0.03,0.12,0.01,0.19,0.03c-0.29-0.09,0.09-0.14-0.17-0.22c-0.01,0.05-0.08,0.05-0.11,0.06c-0.01-0.15-0.18-0.34-0.34-0.46
				l0.1-0.04c-0.2-0.27-0.5-0.5-0.78-0.76c-0.02,0.07-0.34,0.25-0.13-0.01c-0.11,0.03-0.33,0.17,0-0.07l-0.17-0.08
				c0.03-0.04,0.07-0.03,0.13-0.07c-0.65-0.59,0.08-0.15-2.04-1.41c0.07-0.11-0.17-0.27,0.01-0.25c-1.92-0.75-2.78-3.41-4.75,1.56
				l-0.18-0.08c0.31,0.57,0.28,1.26,0.65,1.78c-0.05-0.02-0.09-0.01-0.11-0.06c0.32,0.76,0.11,0.07,1.03,1.99
				c-0.21,0.09,0.02,0.44,0.18,0.51c-0.08-0.01,0,0.14-0.11,0.14c0.09,0.14,0.2,0.25,0.28,0.17c0.07,0.54-0.01,0.41,1.27,1.72
				c-0.62-0.09-0.75-0.55,0.99,1.33c-0.02,0.01-0.06,0-0.1-0.02c0.22,0.07-0.04-0.01,0.58,0c-0.3,0.11,0.19,0.31-0.22,0.42
				c0.05,0.02,0.12,0.03,0.16-0.02c0.03,0.07,0.02,0.11-0.09,0.11c0.21,0.15,0.23,0.01,0.39,0.18c-0.04,0.03-0.08,0.01-0.14,0.04
				c0.15,0.02,0.3,0.03,0.41,0.2c-0.03,0.01-0.04,0.06-0.08,0.01c0.25,0.23,0.59,0.21,0.79,0.54c-0.23,0.08,0.04,0.14-0.07,0.29
				l0.19,0.09l0-0.12c0.13,0.12,0.33,0.12,0.4,0.26l-0.15-0.03l0.27,0.19l-0.09-0.17c0.46,0.01-0.18,0.34,1.05,0.74
				c-0.21,0.08-0.02,0.36,0.24,0.23c-0.02-0.01-0.03,0.02-0.08,0c0.27,0.18,0.35,0.29,0.39,0.38l-0.09-0.04l0.03,0.12l0.08-0.05
				c0.03,0.27,0.21-0.1,2.1,1.33l-0.08,0.03c0.57,0.27,0.41,0.41,1.68,1.09c-0.13-0.09,0.15,0.3,0.49-0.04
				c-0.18-0.02-0.09,0.16-0.06,0.25c0.01-0.04,0.05-0.06,0.06-0.09c-0.01,0.32,0.13-0.09,0.26,0.12c-0.05,0,0.54,0.08,0.12,0.16
				c0.13,0.07,0.38-0.07,0.43,0.11c-0.07,0-0.15-0.04-0.23-0.04c0.05,0.21,0.08,0.18,0.17,0.26l-0.01-0.01l0.19,0.19
				c0.2-0.36,0-0.04,0.66-0.01c0.2,1.03-0.12-0.15,2.86,4.12c-0.28-0.04-0.34-0.17-0.13,0.23c0.01-0.02,0.11,0.2,0.24,0.26
				c-0.03-0.17,0.31-0.02-0.05-0.51c0.07,0,0.21-0.03-0.03-0.16l0,0.14c-0.04-0.05-0.08-0.1-0.1-0.15c0.34,0.1,0.35-0.13,0.06-0.56
				c0.05,0.01,0.13-0.07,0.13,0.02c0.02-0.29-0.29-0.34-0.18-0.49l0.02,0.02c-0.79-0.45,0,0.19-0.59-1.1
				c0.21,0.05-0.21-0.23,0.13-0.41c-0.36-0.11,0.23-0.08-0.45-0.89c0.09-0.01-0.06,0-0.02-0.68c-0.19,0.02-0.23-0.2-0.31-0.35
				c0.11-0.03,0.16-0.01,0.23,0.05c-0.03-0.01,0.05-0.07-0.21-0.28l0.14-0.01c-0.07-0.19-0.28-0.34-0.28-0.61
				c-0.13,0.03-0.27,0.31-0.44,0.07c0.06-0.03,0.17-0.08,0.22-0.02c-0.02-0.02-0.08-0.06-0.07-0.12l0.15,0.07
				c-0.07-0.04-0.12-0.34,0.03-0.11c-0.02-0.11,0-0.25,0.06-0.31c-0.22,0.08,0.11-0.36-0.47-0.8l0,0c-0.27-0.16-0.06-0.88-0.57-2.1
				c0.13,0.13,0.06-0.07,0.11,0.14c-0.02-0.21,0.05-0.27-0.15-0.44c0.14,0.04,0.11-0.19,0.05-0.27l-0.05,0.16
				c-0.02-0.59,0.15-0.12-0.2-1.3c-0.4,0.6-0.41-1.03-0.29-0.91l-0.06-0.01c0.03-0.11-0.04-0.36,0.07-0.32
				c-0.09-0.12-0.11-0.03-0.11,0.08c0.02-0.26-0.1-0.37-0.11-0.63c-0.03,0.11-0.66-0.62-0.04-0.56c-0.17-0.19-0.03-0.43-0.05-0.57
				l0.08,0.1c-0.05-0.06-0.06-0.12-0.07-0.18l-0.1,0.1c0-0.07-0.01-0.13,0.04-0.14c-0.12-0.1-0.41,0.16-0.31-0.34
				c0.02,0.29,0.14-0.04,0.22,0.15c0.02-0.32,0.13-0.18-0.26-0.51c0.1,0.01,0.15-0.11,0.12-0.15c0.04,0.08-0.19,0.11-0.34-0.28
				l0,0.12c-0.26-0.37-0.13,0.06-0.05-1.33c-0.1,0.09-0.07,0.1-0.22-0.04l0.17,0.37l-0.23-0.21c0.03,0.12,0.13,0.23,0.13,0.3
				c-0.12-0.1-0.09-0.09-0.17-0.01c-0.16-0.64,0.51-0.3-0.08-1.4c-0.06,0.12-0.06,0.24-0.16-0.2c0.1-0.01,0.12-0.09,0.17-0.15
				c-0.07,0.02-0.05-0.26-0.25-0.25c0.09-0.35,0.19-0.21-0.28-0.75c0.12,0.06,0.16-0.08,0.28,0.09c-0.14-0.27,0.03,0.06-0.5-0.68
				c0.19-0.26,0.14-0.38-0.08-1.72c0.03,0.01,0.05,0,0.04,0.03c-0.13-0.56,0.02-0.03,0.05-0.93l0.02,0.05
				c0.1-0.15-0.14-0.36-0.11-0.6c0,0,0.03,0.01,0.05,0c-0.17-0.17-0.2-0.49-0.23-0.73c0.06,0.01,0.09,0.02,0.12,0.03
				c-0.09-0.04-0.24-0.16,0.14-0.36c-0.05,0.5,0.24-0.48,0.19-1.74c0.1,0.17,0.08,0.41,0.13,0.58c0.02-0.11,0.11-0.36,0.03-0.45
				c0,0.05,0.03,0.12-0.01,0.15c-0.1-0.18-0.12-0.39-0.01-0.6c-0.03,0.68,0.83-0.87,1.07-0.58c0.01-0.08,0.08-0.02,0.17-0.21
				c0.06,0.11,0.06,0.35-0.11,0.61c0.07-0.09,0.17-0.04,0.19,0.08l0.03-0.21c0.3,0.16-0.09,0.33,0.19,0.52l-0.13-0.04
				c0.12,0.11,0.22,0.52,0.51,0.62c-0.02,0.04-0.09,0.11-0.13,0.04c-0.01,0.34,0.19,0.44,0.69,1.69l-0.11-0.06
				c0.69,1.01-0.03,1,1.12,4c0.54,0.76,0.16-0.15,0.81,3.28l-0.11-0.05c0.45,0.52,0.26,0.14,0.52,1.52c-0.03,0-0.09,0-0.11-0.05
				c0.03,0.17-0.02,0.67,0.25,0.56c-0.19,0.04,0.05,0.37,0.17,0.55l-0.14-0.01c0.04-0.01,0.16,0.19,0.37,0.69l-0.1-0.07
				c0.04,0.29,0.37,0.12,0.09,0.65c-0.03-0.09,0.26,0.27,0.08,0.18c-0.13,0.32,0.16,0.01,0.04,0.72c-0.03-0.25-0.05-0.45-0.04-0.43
				c-0.13,0.02-0.12,0.22-0.32,0.09c-0.07,0.13,0.4,0.29,0.28,1.22c0.14-0.03,0.13,0.11,0.23-0.03c-0.01,0.03-0.01-0.05-0.05-0.12
				l0.01,0.01c-0.01-0.07-0.03-0.15-0.04-0.25c0.08,0.07,0.15,0.14,0.09,0.26c0.1,0.04,0.19,0.08,0.24,0.11
				c-0.37,0.35-0.31,0.35-0.05,1.5c-0.03-0.05-0.03-0.11-0.07-0.13c0.09,0.17,0.08,0.51,0.26,0.53c-0.06,0.23-0.18-0.11-0.12,0.19
				l-0.06-0.03c-0.23,0.61,0.56,0.92,0.23,1.61c0.02-0.05,0.03-0.07,0.06-0.08c-0.11,0.46,0.5,1.16-0.55,4.61
				c-0.13-0.13-0.31-0.14-0.2,0.73l0.08-0.11c-0.17-0.06,0.27,0.09,0.2-0.86c0.16,0.27,0.36,0.17,0.41-0.49
				c0.03,0.04,0.15,0.02,0.09,0.11c0.21-0.26,0-0.52,0.18-0.59l0,0.03c0,0,0,0,0,0c-0.15,0.66,0.02,0.85,0.8,1.61
				c0.05-0.14,0.04-0.1-0.16-0.58c0.51-0.02-0.23-0.29,0.18-0.27c-0.07-0.11-0.03-0.31-0.16-0.36c0.13-0.12-0.05-0.39,0.17-0.38
				c-0.05-0.04-0.18-0.2-0.19-0.3c0.27,0.19,0-0.4,0.29-0.36c-0.24-0.14-0.12-0.52-0.27-0.76c0.02,0.01,0.06,0.04,0.05,0.06
				c0.01-0.14,0.08-0.19,0.12-0.28c-0.23-0.02-0.34-0.46,0.03-0.31c-0.31-0.29,0.02-0.01-0.01-0.86c0.01,0.01,0.01,0.01,0.02,0.01
				c-0.03-0.04-0.02-0.01-0.01-0.12c0.08,0.05-0.05-0.03,0.44-0.66c-0.16-0.11-0.04-0.34-0.01-0.54c0.1,0.04,0.13,0.09,0.14,0.21
				c-0.02-0.03,0.09-0.02,0.03-0.41l0.11,0.09c0.07-0.23,0.01-0.51,0.2-0.77c-0.11-0.06-0.42,0.11-0.39-0.23
				c0.07,0.01,0.18,0.04,0.18,0.13c0-0.03-0.02-0.11,0.03-0.16l0.06,0.16c-0.02-0.08,0.14-0.41,0.1-0.08
				c0.06-0.11,0.17-0.24,0.26-0.26c-0.19-0.4,0.01,0.01,0.18-1.08l0,0c0.01-0.44,0,0,0.47-1.16c0.17,0.11,0.13-0.34,0.53-1.21
				c0.01,0.2,0.1-0.03-0.01,0.2c0.13-0.21,0.22-0.23,0.2-0.51c0.08,0.13,0.22-0.11,0.22-0.23l-0.15,0.12
				c0.55-0.62,0.12,0.16,1.02-2.33c-0.25,0.1,0.06-0.32,0.15-0.09l-0.04-0.05c0.1-0.08,0.22-0.37,0.28-0.26
				c0.02-0.18-0.06-0.11-0.14,0c0.19-0.23,0.19-0.41,0.36-0.66c-0.1,0.06-0.06-1.01,0.36-0.55c0.01-0.29,0.28-0.43,0.36-0.56
				l-0.02,0.15c0.01-0.09,0.04-0.15,0.07-0.22l-0.14,0.02c0.05-0.07,0.08-0.13,0.13-0.11c-0.04-0.19-0.41-0.14,0.02-0.53
				c-0.28,0.35,0.48,0.43,0.23-0.36c0.06,0.08,0.19,0,0.19-0.06c-0.03,0.1-0.21-0.05-0.05-0.49l-0.09,0.11
				c-0.12-0.36,0.36-0.46,0.92-1.27c-0.14,0.02-0.12,0.05-0.14-0.19l-0.13,0.46l-0.02-0.35c-0.06,0.13-0.07,0.3-0.12,0.37
				c-0.02-0.17,0-0.14-0.12-0.13c0.34-0.67,0.62,0.03,0.94-1.35l-0.11,0.1c-0.08-0.11,0.11-0.25,0.13-0.39
				c0.08,0.06,0.15,0,0.24-0.03c-0.07-0.03,0.15-0.28,0-0.41c0.33-0.26,0.29-0.06,0.33-0.88c0.05,0.14,0.18,0.04,0.14,0.27
				c0.05-0.1,0.12-0.18,0.06-0.21c-0.04,0.19,0.28-0.36,0.06-0.76c0.32-0.12,0.4-0.29,1.14-1.71c0.02,0.03,0.04,0.02,0.01,0.06
				c0.26-0.66,0.04,0,0.66-0.92l-0.01,0.06c0.19-0.1,0.11-0.45,0.28-0.69c0,0,0.02,0.03,0.04,0.03c-0.04-0.26,0.11-0.61,0.22-0.88
				c0.04,0.03,0.06,0.05,0.08,0.07c-0.06-0.09-0.1-0.26,0.33-0.33l-0.12,0.21c0.41-0.24,0.31-0.19,0.52-1.24
				c0.03,0.09,0.05,0.17,0.07,0.27c0.02-0.12,0.15-0.6,0.28-0.88c-0.11,0.45-0.2,0.75-0.23,0.96c0.03-0.05,0.1-0.2,0.15-0.46
				c-0.07,0.11-0.08,0.13,0.26-0.78c-0.18,0.82,0.04,0.67-0.17,1.34c0.05-0.03,0.07,0.1,0.02,0.55c0.27-0.08,0.35,0.08,0.3,0.29
				c-0.07-0.03-0.22-0.2-0.27,0.01c0.09-0.16,0.16,0.14,0.24,0.08c-0.07,0.2-0.22,0.43-0.42,0.55c0.11-0.05,0.16,0.09,0.13,0.26
				l0.12-0.24c0.2,0.4-0.24,0.36-0.07,0.76l-0.1-0.13c0.06,0.21,0.01,0.77,0.26,0.98c-0.03,0.04-0.12,0.09-0.14-0.01
				c-0.01,0.5,0.23,1.13,0.07,1.51l-0.07-0.04c0.08,0.29-0.09,0.12,0.05,0.66l-0.08-0.11c-0.07,2.07,0.16,0.42-0.76,4.81
				c0.03-0.01,0.26,0.11,0.06,0.41c0.02-0.08-0.54,0.36-0.02,0.59c-0.37,0.12-0.07,0.79-1.08,2.81l-0.07-0.12
				c0.09,0.8,0.13,0.32-0.45,1.82c-0.03-0.02-0.08-0.05-0.06-0.12c-0.07,0.19-0.41,0.66-0.12,0.71c-0.18-0.07-0.2,0.39-0.22,0.64
				l-0.11-0.09c0.04,0.02,0.01,0.29-0.12,0.9l-0.04-0.13c-0.15,0.31,0.23,0.34-0.34,0.68c0.02,0.04,0.01,0.39-0.07,0.23
				c-0.32,0.24,0.14,0.09-0.44,0.72c0.14-0.26,0.25-0.46,0.25-0.44c-0.11-0.06-0.24,0.14-0.31-0.11c-0.15,0.1,0.15,0.53-0.61,1.34
				c0.13,0.06,0.03,0.18,0.2,0.12c-0.03,0.02,0.02-0.05,0.04-0.15l0.01,0.01c0.04-0.07,0.08-0.16,0.14-0.26
				c0.1,0.11-0.25,0.11,0,0.56c-0.55,0.1-0.46,0.16-1.1,1.38c0.01-0.06,0.05-0.12,0.04-0.17c-0.06,0.21-0.31,0.52-0.18,0.66
				c-0.21,0.17-0.06-0.22-0.23,0.1l-0.02-0.07c-0.61,0.42-0.26,1.2-1.03,1.61c0.05-0.03,0.07-0.04,0.11-0.04
				c-0.48,0.3,0.11,0.55-2.03,2.05c-0.03-0.24,0-0.23-0.42-0.15c0.02,0.02-0.33,0.1-0.26,0.31c0.14-0.39-0.08,0.37,0.61-0.16
				l-0.03,0.22l0.14-0.2c0.05,0.05,0.1,0.15,0.02,0.2c0.11,0.09,0.06,0,0.15,0.01c-0.08-0.03,0.06,0.26-0.27,0.12
				c0.3,0.67-2.21,1-1.21,2.69c0.04-0.03,0.3-0.4,0.66-0.8l-0.13,0.1c0.13,0.03,0.13-0.06,0.14-0.15c0.05,0.03,0.06,0.07,0.04,0.13
				c0.02-0.08,0.09-0.08,0.18-0.35c0.2,0.34,0.1-0.36,0.25-0.01c0.03-0.1,0.16-0.16,0.16-0.27c0.01,0.01,0.02,0,0.03,0.01
				c0-0.01,0-0.03,0-0.05c0.14,0.01,0.23-0.2,0.34-0.03c-0.01-0.06,0.04-0.24,0.11-0.3c0.02,0.31,0.28-0.18,0.41,0.08
				c-0.04-0.26,0.3-0.34,0.4-0.57c0,0.02,0,0.07-0.02,0.07c0.11-0.05,0.18-0.01,0.27-0.02c-0.1-0.17,0.03-0.31,0.04-0.47
				c0.23,0.07,0.06,0.24,0.2,0.36c-0.1-0.13,0.12-0.28,0-0.36c0.16,0.03,0.11-0.01,0.25,0.09c-0.12-0.21,0.06-0.09,0.05-0.27
				c0.11,0.08,0.31-0.19,0.3,0.17c0-0.46,0.05,0.05,0.5-0.66c0.01,0.04,0.08-0.06,0.58,0.06c-0.01-0.19,0.19-0.19,0.31-0.26
				c0.02,0.11,0,0.16-0.06,0.22c0.01-0.03,0.07,0.05,0.25-0.17l0,0.14c0.17-0.05,0.3-0.24,0.54-0.2c-0.02-0.13-0.25-0.31-0.05-0.44
				c0.02,0.07,0.06,0.18,0.01,0.22c0.02-0.01,0.06-0.07,0.1-0.05l-0.06,0.13c0.04-0.06,0.3-0.08,0.09,0.04
				c0.09,0,0.21,0.03,0.27,0.09c-0.08-0.22,0.31,0.14,0.7-0.39l0,0c0.15-0.25,0.76,0.02,1.81-0.42c-0.1,0.12,0.07,0.06-0.11,0.1
				c0.18-0.01,0.24,0.06,0.38-0.13c-0.03,0.14,0.17,0.12,0.24,0.06l-0.14-0.05c0.52-0.01,0.11,0.14,1.12-0.17
				c-0.55-0.39,0.88-0.4,0.77-0.29l0-0.06c0.1,0.03,0.31-0.05,0.29,0.06c0.1-0.1,0.02-0.11-0.08-0.11c0.23,0.01,0.31-0.11,0.53-0.13
				c-0.11-0.02,0.45-0.68,0.48-0.06c0.14-0.18,0.37-0.07,0.49-0.09l-0.08,0.08c0.05-0.05,0.1-0.07,0.14-0.09l-0.1-0.09
				c0.27,0.01,0.11,0.09,0.38-0.3c-0.34,0,0.12,0.62,0.31-0.06c0,0.09,0.12,0.13,0.15,0.1c-0.05,0.05-0.14-0.16,0.18-0.36l-0.1,0.01
				c0.28-0.3-0.06-0.14,1.14-0.22c-0.11-0.08-0.1-0.05-0.02-0.22l-0.27,0.22l0.13-0.25c-0.09,0.05-0.17,0.16-0.23,0.17
				c0.05-0.13,0.06-0.1-0.03-0.17c0.52-0.27,0.36,0.46,1.17-0.31c-0.11-0.03-0.22-0.01,0.12-0.19c0.04,0.09,0.11,0.09,0.18,0.13
				c-0.04-0.06,0.21-0.11,0.14-0.29c0.31,0.01,0.24,0.14,0.54-0.43c-0.01,0.13,0.12,0.14,0.01,0.28c0.18-0.2-0.04,0.05,0.39-0.64
				c0.27,0.08,0.41,0.05,1.34-0.66c0.01,0.03,0.03,0.04,0,0.05c0.37-0.38,0.05,0.03,0.74-0.38l-0.02,0.04
				c0.18,0.01,0.19-0.3,0.37-0.42c0,0,0.01,0.03,0.03,0.04c0.01-0.23,0.18-0.46,0.29-0.64c0.03,0.03,0.04,0.05,0.05,0.07
				c-0.04-0.09-0.03-0.21,0.33-0.17c-0.04,0.05-0.08,0.1-0.12,0.15c0.21-0.02,0.92-0.45,0.45-1.81c0.17,0.09,0.24,0.37,0.29,0.55
				c0.03-0.13,0.03-0.46-0.09-0.51C379.41,440.67,379.46,440.73,379.43,440.78z M365,446.23c-0.01-0.03,0,0-0.02-0.07
				C365.02,446.13,365.14,446.12,365,446.23z M354.3,447.72c-0.01-0.03-0.02-0.05-0.03-0.08
				C354.29,447.66,354.3,447.69,354.3,447.72z M354.06,447.27c0.08,0.02,0.05-0.02,0.13,0.13
				C354.13,447.36,354.08,447.31,354.06,447.27z M353.31,445.84c0.1-0.08,0.08-0.16,0.23,0.13
				C353.47,445.93,353.39,445.87,353.31,445.84z M353.3,445.08c-0.03,0.07-0.04,0.14-0.04,0.2c-0.04-0.09-0.06-0.19-0.03-0.26
				C353.25,445.04,353.26,445.06,353.3,445.08z M350.98,440.89c-0.01-0.02-0.03-0.04-0.06-0.08
				C350.95,440.84,350.92,440.8,350.98,440.89z M350.73,440.45c-0.04-0.01,0.19,0.2-0.03,0.17
				C350.74,440.62,350.72,440.52,350.73,440.45z M350.26,441.56c0.02,0.01-0.01,0.03,0.09,0.09
				C350.3,441.65,350.23,441.68,350.26,441.56z M353.42,447.57c-0.08-0.2-0.45-0.17-0.36-0.4c-0.11,0.07,0.1,0.16-0.1,0.14
				c0.23-0.24-0.46-0.06-0.16-0.39c-0.34,0.16,0.12-0.21-0.88-0.35c0.07-0.07-0.04,0.04-0.43-0.48c-0.13,0.14-0.29,0-0.44-0.05
				c0.06-0.09,0.11-0.11,0.2-0.11c-0.03,0.01-0.01-0.09-0.33-0.07l0.1-0.1c-0.17-0.09-0.42-0.06-0.58-0.27
				c-0.08,0.1-0.02,0.41-0.29,0.34c0.03-0.06,0.08-0.17,0.16-0.16c-0.02,0-0.1,0.01-0.12-0.04l0.15-0.04
				c-0.08,0.01-0.3-0.17-0.05-0.1c-0.08-0.07-0.15-0.18-0.15-0.27c-0.11,0.2-0.13-0.34-0.83-0.29l0,0c-0.35-0.06,0,0-0.82-0.58
				c0.15-0.13-0.23-0.17-0.84-0.65c0.17,0.02,0-0.09,0.16,0.04c-0.13-0.15-0.12-0.23-0.36-0.25c0.13-0.06-0.02-0.21-0.11-0.24
				l0.05,0.15c-0.35-0.44,0.05-0.18-0.87-0.89c0,0.7-0.89-0.58-0.71-0.55l-0.06,0.03c-0.03-0.1-0.21-0.26-0.11-0.3
				c-0.14-0.05-0.11,0.03-0.05,0.12c-0.11-0.22-0.27-0.24-0.4-0.44c0.03,0.11-0.86-0.15-0.3-0.43c-0.24-0.06-0.23-0.33-0.3-0.43
				l0.11,0.05c-0.07-0.03-0.1-0.07-0.14-0.11l-0.04,0.13c-0.11-0.27,0.04-0.14-0.45-0.26c0.15,0.32,0.54-0.32-0.18-0.28
				c0.09-0.03,0.08-0.16,0.04-0.18c0.07,0.04-0.11,0.18-0.42-0.08l0.05,0.1c-0.38-0.19-0.09,0.11-0.57-1.11
				c-0.06,0.12-0.02,0.12-0.21,0.06l0.29,0.24l-0.28-0.08c0.07,0.09,0.2,0.14,0.23,0.21c-0.15-0.03-0.12-0.04-0.16,0.07
				c-0.39-0.49,0.34-0.45-0.58-1.18c-0.01,0.12,0.03,0.23-0.22-0.11c0.09-0.05,0.08-0.12,0.11-0.2c-0.06,0.04-0.13-0.21-0.31-0.13
				c-0.02-0.33,0.12-0.25-0.48-0.57c0.13,0.02,0.13-0.12,0.29-0.01c-0.21-0.19,0.05,0.04-0.66-0.46c0.11-0.29,0.03-0.38-0.53-1.55
				c0.03,0,0.05-0.02,0.05,0.02c-0.28-0.47,0.01-0.03-0.2-0.87l0.03,0.04c0.06-0.17-0.23-0.29-0.27-0.52c0,0,0.03,0,0.05-0.01
				c-0.2-0.11-0.34-0.39-0.43-0.6c0.06-0.01,0.09-0.01,0.12-0.01c-0.1-0.01-0.26-0.07,0.03-0.37c0.21,0.3,0.23-0.46-0.44-1.63
				c0.16,0.11,0.25,0.33,0.35,0.47c-0.01-0.11-0.05-0.37-0.15-0.42c0.17,0.72-0.23-0.87,0.49-0.64c-0.19-0.43,0.05,0.02-0.04-0.37
				c0.14,0.13,0.09,0.01,0.14,0.41c-0.09-0.18,0.09-0.14-0.02-0.33c0.12,0.1,0.17,0.27,0.15,0.65c0.03-0.11,0.07-0.14,0.11-0.07
				c0.04-0.26-0.09-0.19,0.38,0.18l-0.14,0.03c0.17,0.04,0.52,0.32,0.79,0.2c0.01,0.05,0,0.14-0.08,0.12
				c0.19,0.23,0.39,0.23,1.46,0.88l-0.12,0.02c1.08,0.42,0.49,0.76,2.82,2.66c0.79,0.39,0.08-0.21,2.02,2.44l-0.12,0
				c0.6,0.27,0.28,0,1.03,1.11c-0.03,0.01-0.09,0.04-0.12,0c0.08,0.14,0.21,0.6,0.43,0.4c-0.16,0.1,0.17,0.31,0.34,0.42l-0.13,0.05
				c0.03-0.02,0.22,0.1,0.58,0.47l-0.12-0.02c0.25,0.47,0.13,0.24,0.72,1.3c-0.11-0.21-0.2-0.38-0.18-0.37
				c-0.11,0.06-0.04,0.24-0.27,0.19c-0.02,0.13,0.47,0.11,0.66,0.99c0.12-0.07,0.16,0.05,0.21-0.11c0,0.03-0.02-0.04-0.09-0.09
				l0.01,0c-0.03-0.05-0.07-0.12-0.12-0.2c0.1,0.03,0.19,0.07,0.17,0.2c0.1,0,0.21,0.01,0.26,0.01c-0.23,0.44-0.16,0.42,0.44,1.36
				c-0.04-0.03-0.07-0.08-0.11-0.09c0.13,0.12,0.24,0.43,0.42,0.38c0.02,0.22-0.2-0.03-0.05,0.21l-0.07-0.01
				c-0.02,0.63,0.82,0.63,0.74,1.36C352.31,444.66,352.13,444.77,353.42,447.57z M355.93,441.26c-0.02-0.05,0.03-0.19,0.07,0.02
				C355.97,441.28,355.95,441.27,355.93,441.26z M356.85,440.77c-0.01-0.02-0.01-0.05-0.03-0.1
				C356.85,440.71,356.83,440.67,356.85,440.77z M356.77,440.24c-0.04-0.03,0.11,0.27-0.08,0.15
				C356.72,440.42,356.74,440.31,356.77,440.24z M356.89,447.99c0.01,0.05,0.01,0.12,0,0.26
				C356.77,448.13,356.5,448.1,356.89,447.99z M365.76,442.66c-0.01-0.05-0.08,0.32-0.17,0.1
				C365.61,442.8,365.69,442.71,365.76,442.66z M365.53,443.11c-0.01,0.05,0,0-0.05,0.12C365.5,443.21,365.51,443.17,365.53,443.11z
				 M364.45,443.19c-0.02-0.02,0,0-0.05-0.06C364.43,443.07,364.56,442.97,364.45,443.19z M366.16,446.52
				c0-0.04-0.16,0.19-0.16-0.01C366.01,446.54,366.09,446.52,366.16,446.52z M365.87,446.73c-0.02,0.03,0,0-0.07,0.06
				C365.82,446.78,365.84,446.76,365.87,446.73z M362.54,448.28c-0.04,0.01-0.08,0.02-0.12,0.02c0.05,0,0.07-0.01,0.09,0.02
				C362.35,448.44,361.9,448.47,362.54,448.28z M363.39,448.56c0.01-0.02,0.02-0.03,0.02-0.05c0.02,0.01,0.03,0.01,0.04,0.02
				C363.44,448.54,363.41,448.55,363.39,448.56z M363.56,448.46c0.01-0.01-0.04,0.03-0.1,0.06c0-0.09-0.21-0.05-0.01-0.29
				c-0.04,0.03-0.02,0.07-0.02,0.11l0.04-0.09c0.15,0.24-0.2,0.35,0.34,0.03c-0.01-0.01-0.02-0.01-0.03-0.02
				C363.82,448.34,363.64,448.3,363.56,448.46z M363.99,448.43c0.02-0.02,0.05-0.05,0.07-0.08
				C364.05,448.37,364.03,448.4,363.99,448.43z M378.01,441.52c-0.11,0.47-0.64,0.66-0.93,1.07c0-0.05-0.03-0.09,0.01-0.12
				c-0.15-0.04-0.33,0.22-0.27,0.3c-0.19,0.11,0.05-0.2-1.18,0.89c-0.11-0.2-0.38,0.02-0.43,0.17c0-0.08-0.12-0.01-0.13-0.11
				c-0.11,0.07-0.21,0.17-0.13,0.26c-0.49,0-0.28,0-1.55,0.96c0.09-0.6,0.65-0.51-1.31,0.54c-0.01-0.02,0.01-0.05,0.04-0.08
				c-0.11,0.17,0.03-0.03-0.14,0.53c-0.02-0.31-0.32,0.05-0.3-0.36c-0.03,0.03-0.06,0.1-0.02,0.16c-0.06,0-0.1-0.03-0.07-0.12
				c-0.18,0.13-0.07,0.21-0.26,0.28c-0.02-0.05,0.01-0.08,0.01-0.14c-0.06,0.13-0.12,0.26-0.29,0.28c0-0.03-0.03-0.06,0.01-0.08
				c-0.26,0.12-0.37,0.42-0.71,0.44c0.01-0.23-0.13-0.03-0.21-0.2l-0.14,0.11l0.1,0.06c-0.14,0.05-0.22,0.22-0.37,0.21l0.08-0.11
				l-0.25,0.13l0.17,0.01c-0.23,0.23,0.15,0.29-0.67,0.12c-0.02,0.17-0.32,0.15-0.36,0.34c0.02-0.05-0.01-0.14-0.03-0.16l-0.02,0.14
				c-0.08-0.02-0.09-0.03-0.11-0.11c-0.21,0.04,0.14,0.21-0.13,0.18c0.02-0.02,0-0.03,0.03-0.06c-0.27,0.1-0.39,0.1-0.48,0.08
				l0.08-0.05l-0.11-0.05l-0.01,0.09c-0.02-0.23-0.39,0.44-2.07,0.7l0.02-0.08c0,0.04-0.38-0.14-0.52,0.22
				c-0.5-0.26-0.75,0.37-1.18,0.18c0.98-0.7,0.14-0.36,3.73-2.7l-0.02,0.07c0.2-0.07,0.02-0.38,0.25-0.38l-0.01,0.04
				c0.2,0.23,0.45-0.32,3.05-2c0.03,0.06-0.02,0.11-0.04,0.18c0.09-0.25,0.13,0.11,0.21-0.12c-0.04-0.01-0.04-0.08-0.06-0.11
				c0.14,0.02,0.32-0.11,0.44-0.24l0.03,0.11c0.25-0.15,0.48-0.39,0.74-0.58c-0.07-0.03-0.19-0.37,0.02-0.12
				c-0.02-0.11-0.12-0.34,0.06,0.02l0.09-0.14c0.04,0.03,0.02,0.08,0.05,0.13c0.59-0.46,0.1,0.09,1.57-1.48
				c0.08,0.1,0.27-0.05,0.22,0.1c0.23-0.18,0.28-0.23,0.53-0.5c-0.12-0.03-0.12-0.16,0.06-0.02c0.26-0.38,0.94-0.07,1.11-0.4
				l-0.01,0.02C375.59,440.79,378.1,438.3,378.01,441.52z"
                />
                <path
                  className="st10"
                  d="M451.02,293.54c0.1-0.17,0.26-0.44,0.22-0.55l-0.1,0.08c0.05,0.02,0.14,0.17-0.14,0.23l0.06-0.11
				c-0.24-0.02-0.28,0.42-0.44,0.61c-0.07-0.08-0.1-0.2-0.5,0.38c0-0.05-0.1-0.04-0.02-0.11c-0.26,0.2-0.22,0.47-0.38,0.5l0.01-0.03
				c-0.3,0.35-0.85,0.84-1.96,2.4c-0.01-0.04-0.4,0.35-0.62,0.5l-0.01-0.02c0.06,0.12-0.14,0.3-0.26,0.46c-0.05-0.06-0.05-0.1,0-0.2
				c0,0.03-0.06-0.02-0.22,0.35c-0.13-0.35-0.03,0.14-0.55,0.78l0.03-0.15c-0.02,0.06-0.29,0.32-0.11,0.05
				c-0.45,0.37-0.18,0.16-0.97,1.05l0,0c-0.23,0.36,0,0-0.93,0.88c-0.09-0.12-0.29,0.28-0.99,0.91c0.1-0.18-0.08,0.01,0.11-0.17
				c-0.19,0.15-0.27,0.14-0.41,0.39c0.01-0.12-0.21,0.04-0.28,0.13l0.17-0.07c-0.09,0.1-0.18,0.16-0.27,0.24l-0.03-0.13
				c-0.44,0.31-0.85,0.73-1.66,1.58c0.36,0.07-0.63,0.16-0.39,0.27c-0.27,0.14-0.36,0.29-0.62,0.44c0.08,0.01-0.49,0.81-0.56,0.35
				c-0.17,0.23-0.44,0.28-0.57,0.37l0.09-0.12c-0.06,0.07-0.11,0.11-0.17,0.16l0.12,0.01c-0.08,0.04-0.13,0.09-0.15,0.06
				c-0.08,0.17,0.05,0.03-0.23,0.38c0.08-0.07,0.17-0.2-0.11-0.27c-0.15,0.12-0.27-0.06-0.43-0.05c0.01-0.01,0.04-0.02,0.04-0.01
				c-0.06-0.05-0.05-0.1-0.07-0.16c-0.08,0.11-0.2,0.08-0.3,0.12c0-0.16,0.13-0.1,0.18-0.23c-0.06,0.1-0.19,0-0.22,0.1
				c-0.01-0.11-0.03-0.07,0.01-0.18c-0.11,0.14-0.07-0.01-0.18,0.04c0.03-0.09-0.17-0.14,0.05-0.23c-0.22,0.19-0.2-0.33-0.95-0.23
				c0.02,0.02,0.05,0,0.08-0.01l-0.07-0.01c0.32,0.02-0.04-0.3,0.06-0.11c0.46-0.34-0.49-0.3-1.25-1.24
				c0.19,0.26-0.02-0.27-0.91-1.25l0.04,0.01c-0.04-0.13-0.26,0-0.25-0.15l0.03,0.01c0.11-0.16-0.51-0.68-1.17-2
				c0.01-0.01,0.03,0,0.04,0c0.01-0.03,0.13-0.08-0.07-0.08c0.03-0.09-0.04-0.21-0.11-0.29l0.08-0.02
				c-0.06-0.17-0.19-0.33-0.27-0.51c-0.03,0.04-0.28,0.08-0.08-0.02c-0.08,0-0.25,0.04,0.03-0.04l-0.07-0.07
				c0.03-0.02,0.05,0,0.1-0.01c-0.18-0.4,0.06-0.04-0.55-1.19c0.08-0.02,0.06-0.16,0.13-0.1c-0.03-0.17-0.04-0.22-0.12-0.44
				c-0.06,0.05-0.14,0.02,0.01-0.04c-0.14-0.27,0.3-0.52,0.16-0.73l0.01,0.01c-0.03-0.14,0.14-0.42,0.73-1.23
				c-0.51,0.06,0.6-0.71,1.94-0.89c-0.02,0.02-0.02,0.06-0.05,0.06c0.05,0.09,0.21,0.04,0.23-0.02c-0.08-0.02,0.31,0.18,0.8,0.27
				c-0.09,0.13,0.09,0.21,0.18,0.2c-0.05,0.02,0.02,0.08-0.05,0.11c0.06,0.05,0.14,0.07,0.18,0c0.05,0.31,0.04,0.11,0.77,0.8
				c-0.41,0.03-0.36-0.4,0.38,0.8c-0.01,0.01-0.04-0.01-0.06-0.02c0.12,0.06-0.02-0.02,0.36,0.07c-0.22,0.02,0.03,0.2-0.25,0.2
				c0.02,0.02,0.07,0.03,0.11,0.01c0,0.04-0.02,0.06-0.09,0.05c0.09,0.11,0.14,0.04,0.18,0.16c-0.03,0.01-0.05-0.01-0.1-0.01
				c0.09,0.04,0.17,0.07,0.18,0.18c-0.02,0-0.04,0.02-0.05-0.01c0.06,0.17,0.27,0.24,0.26,0.46c-0.16-0.02-0.03,0.08-0.15,0.12
				l0.07,0.09l0.05-0.06c0.02,0.09,0.13,0.16,0.11,0.25l-0.07-0.06l0.07,0.17l0.02-0.11c0.14,0.16,0.21-0.09,0.03,0.44
				c0.12,0.02,0.08,0.22,0.21,0.25c-0.03-0.01-0.1,0-0.11,0.01l0.1,0.02c-0.02,0.05-0.03,0.06-0.09,0.06
				c0.01,0.14,0.15-0.08,0.12,0.1c-0.01-0.01-0.02,0-0.04-0.03c0.05,0.18,0.04,0.26,0.02,0.31l-0.03-0.05l-0.04,0.07l0.06,0.01
				c-0.13,0.19,0.25,0.05,0.31,1.38l-0.05-0.02c0.13,0.47-0.01,0.21,0.32,1.35c-0.06-0.09-0.13,0.01-0.17,0.06
				c0.02-0.01,0.05,0,0.08,0c-0.19,0.1,0.12,0.05,0.03,0.16c-0.01-0.02,0.16,0.29-0.05,0.11c0.01,0.08,0.19,0.16,0.1,0.24
				c-0.03-0.04-0.03-0.09-0.06-0.12c-0.1,0.09-0.08,0.1-0.09,0.16l0-0.01l-0.04,0.15c0.29-0.02,0.02-0.01,0.25,0.32
				c-0.14-0.11-0.06-0.06-0.28,0.05c0.09,0.07-0.06,0.11,0.06,0.21c-0.02,0-0.03,0.02-0.04,0.04c0.06-0.02,0.1,0.25,0.18,1.06
				c-0.21-0.06,0.12,0.26-0.16,0.09c0.03,0.08,0.09,0.15,0.12,0.23c-0.06,0.02-0.09-0.05-0.13-0.08c0.02-0.01,0.01,0.22,0.18,0.21
				c-0.24-0.15,0.24,0.11-0.02-0.41l0.14,0.05l-0.12-0.12c0.04-0.02,0.12-0.04,0.14,0.02c0.06-0.07,0.08-0.02,0.01-0.22
				c0.03,0.02,0.1-0.01,0.08,0.04c0.04-0.12,0.13-0.34-0.22-0.44l0.26-0.1l-0.11-0.02c0-0.02,0.01-0.06,0.05-0.05
				c-0.07-0.08-0.05,0.01-0.12-0.03c0.1-0.1-0.05-0.27,0.12-0.3c-0.08-0.03-0.06,0.1-0.12-0.01c0.22,0.04-0.12-0.25,0.18-0.2
				c-0.23-0.11,0.2-0.09-0.11-0.54c0.06,0.01-0.04-0.02,0.11-0.37c-0.13-0.01-0.11-0.14-0.15-0.23c0.08,0,0.11,0.01,0.15,0.06
				c-0.02-0.01,0.04-0.04-0.09-0.18l0.1,0.01c-0.02-0.11-0.13-0.22-0.08-0.37c-0.09,0-0.24,0.14-0.31-0.01
				c0.05-0.01,0.13-0.02,0.15,0.01c0.01-0.01-0.09-0.13,0.06-0.02c-0.04-0.03-0.03-0.2,0.04-0.06c0.01-0.06,0.04-0.14,0.09-0.17
				c-0.16,0.04,0.13-0.19-0.2-0.48l0,0c-0.16-0.11,0.08-0.51-0.13-1.2c0.08,0.07,0.05-0.04,0.06,0.08c0.01-0.12,0.06-0.15-0.06-0.25
				c0.09,0.02,0.09-0.1,0.06-0.15l-0.05,0.09c0.03-0.34,0.1-0.06-0.03-0.74c-0.31,0.34-0.21-0.6-0.14-0.51l-0.04,0
				c0.03-0.06-0.01-0.2,0.06-0.19c-0.06-0.07-0.07-0.02-0.08,0.05c0.03-0.15-0.05-0.21-0.05-0.36c-0.01,0.07-0.45-0.32-0.02-0.32
				c-0.12-0.1-0.02-0.25-0.04-0.33l0.06,0.06c-0.03-0.03-0.04-0.07-0.06-0.1l-0.06,0.07c0.02-0.19,0.07-0.07-0.2-0.26
				c-0.01,0.23,0.44-0.09-0.04-0.2c0.07,0,0.09-0.08,0.07-0.1c0.03,0.04-0.1,0.1-0.25-0.11l0.01,0.07c-0.07,0.01-0.08-0.05-0.13-0.1
				c0.04-0.03,0.06,0.05,0.07,0.01c-0.2-0.03,0.07-0.29-0.12-0.67c-0.05,0.08-0.03,0.07-0.15,0.03c0.06,0.05,0.11,0.11,0.16,0.17
				l-0.18-0.07c0.03,0.06,0.11,0.1,0.12,0.14c-0.09-0.03-0.07-0.03-0.12,0.03c-0.22-0.33,0.32-0.29-0.29-0.75
				c-0.01,0.08,0.01,0.15-0.14-0.06c0.06-0.04,0.05-0.09,0.06-0.14c-0.04,0.04-0.1-0.13-0.22-0.05c-0.04-0.21,0.06-0.19-0.38-0.27
				c0.09-0.02,0.07-0.11,0.18-0.07c-0.16-0.08,0.05,0.01-0.5-0.1c-0.02-0.17-0.09-0.32-0.83-0.56c0.01-0.02,0.01-0.03,0.03-0.02
				c-0.38-0.02,0-0.06-0.55-0.2l0.03-0.01c-0.09-0.08-0.24,0.07-0.38,0.05c0,0,0.01-0.02,0-0.03c-0.1,0.12-0.29,0.16-0.42,0.23
				c0-0.03,0-0.04,0-0.06c-0.01,0.06-0.07,0.13-0.24-0.05c0.04-0.02,0.08-0.03,0.12-0.03c-0.13-0.4-0.46,0.57-0.77,0.3
				c-0.05,0.06-0.15,0.24-0.23,0.34c0-0.12,0.09-0.26,0.14-0.35c-0.05,0.02-0.13,0.1-0.17,0.17c0.01-0.01,0.02-0.02,0.03-0.02
				c-0.34,0.53,0,0.03-1.2,1.1c0.1-0.06,0.21,0.36-0.08,0.14c0.1-0.04,0.06,0.11,0.14,0.12c-0.09,0.07-0.24,0.12-0.42,0.12
				c0.08,0.01,0.08,0.09,0.02,0.17l0.14-0.08c0.04,0.24-0.24,0.11-0.2,0.36l-0.04-0.09c0,0.12-0.11,0.41,0.04,0.55
				c-0.03,0.01-0.09,0.03-0.1-0.02c-0.11,0.2-0.04,0.29,0.05,1.12l-0.06-0.05c0.35,0.66-0.22,0.57,0.67,2.36
				c0.26,0.11,0.04,0.14,0.84,1.21c-0.1,0.15-0.09,0.03,0.15,0.44l-0.08,0c0.37,0.15,0.17-0.02,0.62,0.66
				c-0.02,0.01-0.06,0.03-0.08,0.01c0.05,0.08,0.13,0.36,0.28,0.21c-0.11,0.08,0.11,0.18,0.23,0.23l-0.09,0.04
				c0.02-0.02,0.13,0.03,0.39,0.22l-0.08,0c0.18,0.25,0.09,0.12,0.54,0.69c-0.09-0.11-0.16-0.2-0.15-0.19
				c-0.21,0.05,0.06,0.47,0.49,0.54c0.01,0.02-0.02-0.02-0.07-0.04l0.01,0c-0.03-0.03-0.06-0.06-0.1-0.1c0.07,0,0.13,0.01,0.13,0.09
				c0.07-0.02,0.14-0.04,0.17-0.05c0.13,0.07,0.26,0.7,1.42,1.47c-0.01-0.03-0.02-0.04-0.01-0.06c0.24,0.2,0.06,0.3,1.2,0.76
				c-0.01,0.01-0.03,0.01-0.03,0.01c0.09-0.07,0.1,0.13-0.24,0.4l0.12-0.06c-0.09,0.3-0.54,0.28-1.38,0.78
				c0.12,0.02,0.12-0.01,0,0.18l0.35-0.33l-0.18,0.28c0.12-0.08,0.22-0.22,0.29-0.26c-0.08,0.14-0.08,0.12,0.02,0.13
				c-0.6,0.4-0.49-0.13-1.45,0.83l0.14-0.05c0,0.1-0.22,0.16-0.32,0.27c-0.03-0.06-0.12-0.04-0.19-0.04
				c0.04,0.04-0.27,0.18-0.23,0.31c-0.41,0.12-0.25-0.03-0.75,0.6c0.05-0.12-0.11-0.07,0.05-0.25c-0.34,0.38,0.17-0.08-2.47,1.72
				c0-0.01,0-0.02,0-0.03c-0.56,0.43-0.02-0.04-1,0.51l0.04-0.04c-0.27,0.09-0.08,0.05-1.31,1.01c-0.01-0.03-0.02-0.05-0.02-0.07
				c-0.01,0.08-0.07,0.22-0.44,0.15l0.21-0.12c-0.17-0.05-0.27,0.16-0.4,0.2l-0.01-0.06c-0.32,0.2-0.26,0.32-0.61,0.51l0.06,0.02
				c-0.43,0.43-0.31-0.19-1.07,0.63c0.08-0.16,0.33-0.34,0.46-0.49c-0.12,0.07-0.42,0.24-0.46,0.34c0.05-0.04,0.09-0.1,0.16-0.11
				c-0.98,0.71-0.01,0.08-2.96,1.35c0.28,0.1-0.15,0.08-0.03,0.26c-0.25,0.18-0.46,0.08-0.91,0.01c0.07,0.05-0.05,0.14-0.22,0.19
				l0.26-0.01c-0.33,0.28-0.41-0.01-0.74,0.27l0.09-0.12c-0.31,0.08-0.32,0.24-2.28,1.2l0.02-0.06c-0.22,0.17-0.15,0-0.61,0.32
				l0.08-0.1c-0.49,0.14-0.66,0.44-1.04,0.64c-0.06-0.14-0.44-0.03-0.64,0.02l0-0.02c-1.11,0.26-0.03,0.14-5.91,2.38l-0.03-0.1
				c-0.35,0.1-0.52,0.14-0.94,0.3l0.06-0.1c-0.68,0.48-0.28,0.23-1.79,0.74c0-0.02,0-0.07,0.07-0.1c-0.2,0.06-0.79,0.15-0.66,0.32
				c-0.04-0.12-0.43,0.12-0.64,0.25l0.01-0.1c0,0.04-0.25,0.18-0.8,0.43l0.08-0.09c-0.35,0.1-0.13,0.29-0.77,0.22
				c0.1-0.04-0.27,0.24-0.21,0.1c-0.41-0.01,0.05,0.1-0.85,0.21c0.29-0.09,0.52-0.15,0.5-0.14c-0.03-0.09-0.26-0.03-0.11-0.21
				c-0.18,0.09,0.03,0.06-0.73,0.37l0.02-0.02c-0.31,0.12-0.19,0-0.73,0.17c0.04,0.09-0.12,0.12,0.05,0.16
				c-0.04,0,0.05-0.02,0.14-0.07l-0.01,0.01c0.08-0.03,0.18-0.06,0.29-0.1c-0.71,0.48-0.57-0.01-2.19,0.74
				c0.24-0.18,0.24-0.14-0.69,0.24l0.03-0.05c-0.75,0.04-0.99,0.7-1.83,0.74c0.06-0.01,0.09-0.01,0.1,0.01
				c-0.44-0.05-2.03,0.93-4.25,1.97l0.09,0.03c-0.51,0.18-0.7,0.33-0.9,1.22c0.1-0.04,0.19,0.01,0.43-0.41
				c0.13,0.28,0.22-0.38,0.34-0.06c0.04-0.1,0.31,0.02,1.02-0.71c-0.12,0.27,0.42-0.21,0.46,0c0.08-0.22,0.51-0.35,0.74-0.55
				c-0.01,0.02-0.03,0.06-0.06,0.06c0.16-0.06,0.23-0.03,0.34-0.05c-0.18,0.08,0.52-0.78,0.35-0.12c-0.06-0.1,0.29-0.24,0.18-0.3
				c0.18,0.01,0.14-0.02,0.26,0.05c-0.19-0.12,0.75-0.47,0.48-0.11c0.03-0.31,0.84-0.32,1.72-1.1c0.78,0.72-0.01-0.03,2.09-0.56
				l-0.05,0.16c0.93-0.56,1.94-0.5,5.19-1.65l-0.06,0.05c0.29-0.05,0.25-0.3,0.54-0.29l-0.04,0.03c0.17-0.05,0.17,0.17,4.96-1.59
				c0,0.05-0.08,0.09-0.15,0.14c0.26-0.21,0.09,0.09,0.33-0.1c-0.04-0.01,0-0.07,0-0.09c0.16,0.01,0.46-0.09,0.68-0.2l-0.02,0.09
				c0.4-0.13,0.81-0.34,1.24-0.52c0.84-0.23-0.16,0.1,6.07-2.62l-0.02,0.02c2.41-1.35-2.52,1.52,6.58-3.39l-0.06,0.17
				c0.59-0.49,1.37-0.74,1.94-1.24c-0.02,0.05-0.01,0.08-0.07,0.11c0.18-0.01,0.5-0.3,0.47-0.36c0.31-0.14,0.02,0.11,1.81-1.18
				c0.07,0.14,0.48-0.16,0.58-0.32c-0.02,0.07,0.15-0.04,0.13,0.05c0.17-0.11,0.3-0.24,0.23-0.29c0.64-0.23,0.46-0.16,2.14-1.55
				c-0.25,0.61-0.72,0.69,1.69-1.17c0.01,0.01-0.02,0.05-0.05,0.09c0.16-0.21-0.02,0.03,0.21-0.52c0,0.25,0.39-0.22,0.36,0.13
				c0.04-0.04,0.07-0.1,0.04-0.14c0.25,0.24,0.01,0.18,0.71,0.17c0,0.01,0,0.01-0.01,0.03l0.04-0.05c0,0,0,0,0,0
				c0.09-0.08-0.05-0.03-0.13-0.49l0.1,0.03c-0.03-0.09-0.08-0.04-0.14-0.01c-0.02-0.07,0.02-0.12-0.08-0.06
				c0.06-0.09,0.13-0.17,0.26-0.25c0,0.03,0.04,0.03-0.02,0.07c0.32-0.27,0.45-0.59,0.85-0.82c-0.01,0.11,0.06,0.2,0.43-0.16
				l-0.12,0.01c0.17-0.13,0.26-0.33,0.43-0.41l-0.09,0.14l0.3-0.27l-0.21,0.11c0.34-0.58-0.09-0.08,1.17-1.11
				c0.03,0.2,0.34-0.09,0.33-0.26c-0.02,0.02,0,0.03-0.03,0.07c0.28-0.27,0.42-0.35,0.53-0.41l-0.06,0.07
				c0.39-0.28-0.02,0.04,1.24-1.21l-0.07,0.05c-0.08-0.18,0.31-0.17,0.42-0.4c0.19,0.09,0,0.08,0.75-0.76l-0.01,0.08
				c0.58-0.65,0.58-0.41,1.78-1.81c-0.06,0.14-0.11,0.2,0.25-0.27c-0.08-0.03-0.08,0.01-0.19,0.04c0.01-0.08,0.07-0.2,0.15-0.28
				c-0.1,0.19,0.1,0.09,0.19,0.05c-0.03-0.01-0.03-0.05-0.05-0.06c0.4-0.33-0.06-0.1,0.73-0.87c-0.03,0.08-0.1,0.17-0.14,0.24
				c0.21-0.06,0.2-0.1,0.3-0.2l-0.01,0.01l0.24-0.21l-0.08,0.04c-0.03-0.34,0.28-0.4,0.36-0.75c-0.07,0.15,0.11,0.37,0.54-0.28
				c-0.03,0.11,0.68-1.03,1.64-2.27C450.83,293.9,450.74,293.97,451.02,293.54z M434.82,303.61c-0.02,0.01,0,0-0.05,0.02
				C434.75,303.62,434.74,303.54,434.82,303.61z M434.96,302.92c0.03-0.01,0.01-0.06,0-0.1
				C434.94,302.82,435.1,302.91,434.96,302.92z M435.11,302.99c0.02,0.01,0,0,0.04,0.04C435.15,303.02,435.14,303.01,435.11,302.99z
				 M404.89,321.25c0.03-0.06,0.02-0.08,0.14-0.07C405,321.21,405.01,321.17,404.89,321.25z M405.53,321.72
				c0.02-0.01,0.06-0.02,0.12-0.04C405.6,321.7,405.65,321.68,405.53,321.72z M405.95,321.51c-0.02,0.03,0.1,0.01,0.18,0.02
				C406.17,321.49,405.86,321.68,405.95,321.51z M438.87,305.75c0-0.02,0-0.01,0.02-0.03C438.89,305.72,438.9,305.73,438.87,305.75z
				"
                />
                <path
                  className="st10"
                  d="M377.05,346.95l-0.01,0.35c0.2-0.43-0.21-1.56,0.1-1.53c-0.08-0.14,0.18-0.39-0.13-1.88
				c0.2,0.34-0.02-0.71,0.17-0.63c-0.19-0.25-0.15-0.92-0.28-1.37c0.02,0.02,0.05,0.07,0.04,0.11c0.32-0.02-0.59-1.31,0.01-1.06
				c-0.11,0.03-0.19-0.52-0.26-0.41c0.05-0.25,0.01-0.21,0.1-0.35c-0.14,0.2-0.36-1.28-0.02-0.74c-0.51-0.76,0.07-0.08-0.8-2.57
				c-0.16,0.08-0.18,0.14-0.25-0.28c0.33,0.6,0.26-0.11,0.11-0.15c0.37-0.5-0.41-1.27-0.91-2.96l0.17,0.09
				c-0.82-1.29,0.38-0.14-3.38-7.36l0.08,0.08c-0.17-0.4-0.42-0.33-0.53-0.72l0.05,0.06c-0.21-0.29-1.37-2.72-2.85-4.54l0.09-0.07
				c-0.05,0.19-0.32-0.55-1.41-1.86c0.05-0.01,0.14,0.1,0.24,0.17c-0.56-0.61-0.09-0.11-0.99-1.25l0.1,0.01
				c-2.66-3.31,0.16-0.08-7.87-8.7l0.03,0.02c-1.47-1.37-0.01,0.02-3.58-3.64l-0.01,0.07c-0.78-0.78-0.41-0.03-4.29-3.94l0.22,0.05
				c-0.91-0.66-1.67-1.55-2.55-2.21c0.4,0.21-0.87-0.92-2.87-2.58c0.13-0.1-0.44-0.58-0.67-0.71c0.1,0.02-0.13-0.19-0.01-0.17
				c-0.22-0.2-0.43-0.37-0.45-0.27c-0.59-0.8-0.46-0.56-2.85-2.63c0.13,0.05,0.23,0.1,0.3,0.14c-0.4-0.36-0.47-0.47-2.4-2.31
				c0,0.02,0.02,0.09-0.58-0.22c0.3,0.02-0.42-0.51,0.01-0.44c-0.07-0.05-0.17-0.11-0.19-0.06c-0.08-0.1-0.09-0.15,0.04-0.09
				c-0.35-0.31-0.29-0.14-0.58-0.45c0.03-0.02,0.1,0.03,0.16,0.03c-0.2-0.11-0.4-0.22-0.62-0.5c0.03,0,0.02-0.05,0.09,0.03
				c-0.43-0.44-0.85-0.65-1.26-1.19c0.13,0.03,0.23-0.03-0.32-0.56l0.05,0.15c-0.21-0.24-0.47-0.39-0.62-0.62l0.2,0.14l-0.41-0.43
				l0.19,0.29c-0.26-0.2-0.1,0.14-0.36-0.21c0.07,0.06-0.21-0.24-0.26-0.42l0,0c-0.53-0.44,0.06-0.29-0.99-1.12
				c0.24,0.01-0.18-0.46-0.38-0.49c0.03,0.03,0.03,0,0.09,0.06c-0.37-0.44-0.49-0.64-0.59-0.8l0.1,0.11
				c-0.42-0.58,0.04,0.04-1.61-1.96l0.07,0.11c-0.58-0.53-0.08-0.56-1.44-1.94l0.09,0.04c-0.79-1.03-0.53-0.87-2.06-3.1
				c0.15,0.15,0.22,0.24-0.29-0.45c-0.04,0.09,0.01,0.1,0.03,0.26c-0.09-0.05-0.22-0.19-0.31-0.34c0.21,0.23,0.11-0.07,0.08-0.21
				c-0.01,0.03-0.06,0.01-0.07,0.03c-0.07-0.38-0.17,0.03-0.35-0.43c0.08-0.01,0.19,0.01-0.57-0.97c0.08,0.09,0.17,0.23,0.25,0.32
				c-0.05-0.29-0.09-0.31-0.18-0.49l0.01,0.02l-0.2-0.43l0.04,0.12c-0.39-0.17-0.4-0.59-0.77-0.92c0.36,0.32,0.11,0.02,0.22,0
				c-0.03-0.12-0.1-0.29-0.04-0.26c-0.09-0.12-0.22-0.09-0.41-0.77c0.41,1.02-1.2-1.8-2.06-3.35c0.19,0.07,0.34,0.14-0.53-1
				l0.05,0.18c0.06-0.04,0.22-0.02,0.21,0.34l-0.1-0.15c-0.09,0.26,0.37,0.64,0.53,0.97c-0.15-0.01-0.22,0,0.26,0.87
				c-0.05-0.03-0.07,0.09-0.13-0.06c0.14,0.45,0.45,0.61,0.44,0.82l-0.03-0.03c0.52,1.28,0.78,0.93,1.85,3.29
				c-0.14,0.06-0.01-0.15,0.45,0.68c-0.05,0,0.34,0.71,0.5,1.06l-0.02,0c0.15,0,0.34,0.35,0.52,0.58c-0.07,0.03-0.12,0-0.24-0.11
				c0.04,0.02-0.04,0.05,0.38,0.47c-0.31-0.03-0.01,0.15,0.87,1.1l-0.18-0.12c0.09,0.2,0.12,0.06,0.21,0.2
				c-0.08,0.04,0.03,0.28-0.15-0.04c0.43,0.77,0.17,0.33,1.22,1.73l0,0c0.52,0.45,0.35,0.78,2.27,3.15
				c-0.22-0.18,0.02,0.11-0.21-0.2c0.2,0.3,0.2,0.41,0.52,0.65c-0.15-0.05,0.08,0.28,0.21,0.4l-0.11-0.24
				c0.13,0.14,0.23,0.28,0.33,0.42l-0.15,0c0.44,0.62,1.04,1.3,2.27,2.54c0-0.44,0.36,0.84,0.43,0.56c0.25,0.37,0.45,0.52,0.73,0.87
				c0.02-0.07,1.1,0.72,0.61,0.77c0.34,0.25,0.48,0.6,0.64,0.78l-0.17-0.13c0.1,0.08,0.18,0.16,0.25,0.24l-0.02-0.15
				c0.08,0.1,0.15,0.18,0.12,0.2c0.3,0.08,0.2-0.18,0.61,0.43c-0.33-0.4-0.06,0.08-0.34-0.18c0.32,0.43,0.11,0.29,0.76,0.66
				c-0.09,0,0,0.17,0.07,0.22c-0.11-0.12,0.15-0.08,0.58,0.33l-0.13-0.16c0.37,0.11,0.56,0.72,1.52,1.79
				c-0.03-0.15-0.06-0.15,0.21,0.01l-0.54-0.46l0.41,0.23c-0.15-0.15-0.36-0.28-0.44-0.38c0.2,0.1,0.17,0.1,0.15-0.02
				c0.69,0.74,0.11,0.67,1.64,1.85l-0.13-0.17c0.54,0.32,0.27,0.51,2.09,1.84c-0.16-0.05-0.03,0.14-0.31-0.05
				c0.65,0.39-0.17-0.2,3.34,2.96c-0.03,0-0.02,0.02-0.07-0.02c0.88,0.71-0.01,0.04,1.19,1.21l-0.07-0.05
				c0.13,0.21,0.45,0.36,0.74,0.6c-0.03,0-0.06,0.01-0.09,0.03c0.01-0.01,0.02-0.04,0.03-0.03c-0.06-0.02-0.07-0.07-0.1-0.11
				c-0.02,0.14-0.12,0.16-0.18,0.23c-0.06-0.14,0.06-0.15,0.06-0.28c-0.01,0.11-0.14,0.09-0.13,0.19c-0.05-0.09-0.04-0.05-0.06-0.16
				c-0.03,0.17-0.06,0.02-0.11,0.12c-0.01-0.09-0.18-0.05-0.05-0.23c-0.06,0.25-0.32-0.17-0.75,0.32c0.02,0.01,0.03-0.03,0.05-0.05
				l-0.05,0.03c0.21-0.15-0.14-0.22-0.01-0.13c0.28-0.44-0.62-0.17-1.38-0.16c0.41,0.04-0.02-0.23-2.7-0.34l-0.03-0.13
				c-0.15,0.19,0.06-0.16-0.44,0.03c0.01-0.04,0.04-0.06,0.07-0.08c-0.11,0.17-0.04-0.16-0.14,0.06c-0.05-0.07-0.16-0.08-0.25-0.05
				l0.02-0.07c-0.16-0.03-0.34,0.02-0.51,0.02c0.06,0.03,0.05,0.16,0,0.18l-0.02-0.11c-0.02,0.07-0.05,0.25-0.02-0.04l-0.08,0.04
				c-0.01-0.03,0.01-0.05,0.02-0.1c-0.03,0.07-0.15-0.11-0.19,0.01c-0.1-0.06-0.64,0.11-0.91,0.32c-0.03-0.09-0.15-0.05-0.1-0.13
				c-0.15,0.05-0.2,0.07-0.37,0.2c0.06,0.04,0.06,0.13-0.04,0.01c-0.16,0.19-0.6-0.03-0.68,0.23l0-0.01
				c-0.39,0.42-0.06-0.1-1.1,0.87c0.03,0.03,0.13,0.06-0.04,0.08c0.7-0.38-1.02,0.96,0.08,2.42c-0.03,0-0.06,0.02-0.08-0.01
				c-0.04,0.11,0.14,0.21,0.19,0.17c-0.06-0.12,0.17,0.4,0.67,0.54c-0.1,0.13,0.09,0.22,0.19,0.18c-0.04,0.03,0.03,0.07-0.03,0.12
				c0.07,0.04,0.15,0.05,0.17-0.03c0.04,0.24,0.32-0.02,0.29,0.24c0.27,0.02,0.48,0.03,0.66,0.04c-0.25,0.23-0.52,0.1,0.74,0.18
				c0,0.01-0.02,0.03-0.04,0.04c0.03,0,0.08,0.03,0.08-0.04l-0.03,0.02c-0.04-0.11,0.15-0.21,0.16-0.26
				c-0.07,0.2,0.16,0.07,0.05,0.32c0.02-0.01,0.05-0.04,0.05-0.09c0.29,0.12-0.01,0.02,0.94-0.02l-0.05-0.06
				c0.08-0.01,0.13-0.12,0.21-0.09l-0.05,0.06c0.05-0.02,0.1-0.03,0.14-0.05l-0.09-0.03c0.12-0.12-0.02-0.2,0.36-0.02
				c0.01-0.11,0.17-0.08,0.18-0.21c-0.01,0.03,0.01,0.1,0.02,0.11l0-0.09c0.1,0.05,0.07-0.04,0.36-0.04l-0.04,0.03l0.06,0.04
				l-0.01-0.07c0.04,0.13,0.21-0.24,0.65-0.33c-0.01,0.02,0.02,0.1,0.01,0.08c0.12-0.02,0.19-0.13,0.32-0.26
				c-0.08,0.05,0.24,0.13,0.23-0.13c0.2,0.02,0.29,0.23,0.65-0.45c-0.04,0.08,0.06,0.12,0.11,0.14c-0.02-0.02-0.02-0.05-0.03-0.07
				c0.14,0.16,0-0.14,0.1-0.08c-0.01,0.01,0.15-0.22,0.1,0.01c0.16-0.11-0.02,0.01,0.34-0.04c-0.13-0.27-0.01-0.02,0.13-0.32
				c-0.02,0.16-0.03,0.06,0.15,0.25c0.01-0.1,0.11,0.03,0.12-0.12c0.01,0.02,0.03,0.02,0.05,0.03c-0.02-0.05,0.12-0.16,0.68-0.48
				c0.03,0.21,0.15-0.18,0.12,0.13c0.04-0.05,0.08-0.13,0.12-0.17c0.03,0.05-0.01,0.1-0.01,0.14c-0.02-0.02,0.15-0.07,0.09-0.22
				c-0.02,0.26,0.01-0.25-0.28,0.12l-0.01-0.15l-0.04,0.14c-0.03-0.03-0.07-0.1-0.04-0.14c-0.06-0.04-0.05-0.07-0.16,0.05
				c0-0.03-0.05-0.09,0-0.09c-0.15,0.01-0.3,0.03-0.23,0.33l-0.17-0.21l0.03,0.1c-0.02,0-0.05,0.01-0.06-0.03
				c-0.03,0.09,0.03,0.04,0.02,0.12c-0.11-0.06-0.17,0.13-0.26-0.02c0.01,0.09,0.09,0.03,0.04,0.11c-0.06-0.21-0.14,0.18-0.21-0.11
				c0,0.23-0.22-0.12-0.34,0.26c-0.01-0.06-0.01,0.04-0.31,0c0.04,0.12-0.05,0.14-0.1,0.2c-0.03-0.07-0.03-0.1-0.02-0.15
				c0,0.02-0.05-0.01-0.09,0.13l-0.03-0.09c-0.07,0.04-0.1,0.18-0.23,0.17c0.03,0.08,0.19,0.18,0.11,0.29
				c-0.03-0.04-0.07-0.11-0.05-0.15c0.01,0.01-0.18,0.02-0.09-0.07c-0.05,0.01-0.11,0-0.15-0.04c0.01,0.04,0.03,0.21-0.11,0.03
				c0,0.05-0.07,0.03-0.02,0.12c-0.02,0.01-0.05-0.03-0.07-0.05c-0.01,0.07,0,0-0.08,0.19l0,0c-0.04,0.17-0.37,0.03-0.85,0.32
				c0.04-0.08-0.04-0.04,0.04-0.07c-0.09,0-0.13-0.04-0.17,0.08c0-0.09-0.1-0.08-0.13-0.04l0.08,0.04c-0.26-0.09-0.66,0.44-0.9,0.19
				v0.04c-0.05-0.03-0.14-0.01-0.13-0.08c-0.05,0.05-0.01,0.07,0.04,0.08c-0.1-0.04-0.15,0.02-0.25,0c0.05,0.07-0.3,0.32-0.22-0.06
				c-0.08,0.09-0.16-0.04-0.21-0.06l0.04-0.03c-0.03,0.02-0.05,0.02-0.07,0.02l0.03,0.08c-0.03-0.01-0.05-0.02-0.05-0.05
				c-0.03,0.05,0.02,0.14-0.03,0.21c-0.04-0.04-0.12,0.05-0.12-0.07c0.15,0.04,0.05-0.41-0.14-0.05c0.01-0.05,0-0.09-0.02-0.1
				c0.01,0.03-0.02,0.13-0.17,0.16c0.04,0.04,0.07,0.04-0.07,0.09c0-0.05,0.05-0.03,0.04-0.05c-0.07,0.1-0.11-0.13-0.2-0.07
				c0-0.02-0.06-0.35-0.25-0.07c0.06-0.01,0.11-0.01,0.16-0.02l-0.12,0.1c0.05,0.01,0.11-0.03,0.13-0.01
				c-0.06,0.06-0.05,0.04-0.04,0.11c-0.36-0.01,0.13-0.32-0.49-0.3c0.02,0.06,0.06,0.1-0.11,0.05c0.03-0.06,0.01-0.1,0.01-0.14
				c-0.02,0.05-0.09-0.04-0.16,0.08c-0.06-0.14,0.02-0.19-0.32-0.06c0.06-0.05,0.04-0.12,0.13-0.13c-0.13,0,0.03,0-0.39,0.06
				c0.01-0.2-0.01-0.16-0.45-0.53c0.02-0.01,0.03-0.02,0.03-0.01c-0.28-0.05,0.09-0.23-0.52-0.69c0.27,0.06,0.15-0.18,0.03-0.84
				c0.09,0.08,0.11,0.18,0.16,0.24c0-0.04,0.02-0.14-0.04-0.18c-0.01,0.06-0.02,0.08-0.07-0.2c0.24,0.12,0.16-0.19,0.38-0.14
				c-0.01-0.18,0.2-0.22,0.31-0.35c-0.08,0.02-0.14-0.36,0.06-0.07c-0.08-0.02-0.04-0.1-0.08-0.14c0.08-0.01,0.19,0.02,0.33,0.14
				c-0.06-0.05-0.04-0.11,0.02-0.11c-0.14,0.03,0.12-0.2,0.12-0.04c0.03-0.07,0.2-0.12,0.18-0.3c0.02,0.02,0.06,0.06,0.04,0.09
				c0.15,0-0.02-0.1,0.42-0.2c0,0.01,0.19-0.14,0.17-0.04c0.66-0.17,0.14-0.06,1.62-0.09c0.15-0.15,0.12,0,1.07-0.04
				c0.03,0.14-0.04,0.11,0.32,0.15l-0.04,0.07c0.27-0.18,0.04-0.18,0.69-0.07c-0.01,0.02-0.01,0.06-0.04,0.07
				c0.07,0.01,0.26,0.13,0.26-0.07c-0.01,0.13,0.16,0.03,0.24-0.02l-0.02,0.1c0-0.03,0.07-0.07,0.33-0.14l-0.04,0.06
				c0.12,0.03,0.1-0.24,0.27,0.05c0.01-0.01-0.03,0,0.38,0.05c-0.1-0.02-0.19-0.03-0.18-0.04c-0.11,0.13,0.34,0.31,0.55,0.06
				c0.01,0.01-0.02,0-0.06,0.02l0-0.01c-0.03,0-0.06,0-0.11-0.01c0.04-0.05,0.08-0.08,0.12-0.02c0.03-0.06,0.06-0.12,0.08-0.15
				c0.12-0.03,0.47,0.29,1.52,0.31c-0.02-0.02-0.03-0.03-0.03-0.05c0.22,0.04,0.17,0.23,1.11,0.1l0.01,0.11
				c0.03-0.16,0.69-0.04,1.09,0.21c-0.03,0.02-0.05,0.03-0.07,0.04c0.1,0,0.29,0.06,0.44,0.52l-0.27-0.24
				c0.05,0.22,0.35,0.32,0.47,0.46l-0.05,0.02c0.42,0.36,0.52,0.27,0.95,0.68l-0.02-0.07c0.72,0.46,0.01,0.44,1.37,1.22
				c-0.23-0.07-0.59-0.34-0.84-0.49c0.15,0.15,0.54,0.49,0.66,0.51c-0.07-0.05-0.17-0.1-0.22-0.17c1.42,1.08,0.1,0,3.38,3.41
				c-0.08-0.37,0.19,0.17,0.3-0.02c0.08,0.07,0,0,0.21,0.21c0,0,0,0,0-0.01c0.18,0.23,0.24,0.5,0.4,0.91
				c0.01-0.1,0.19,0.03,0.35,0.23l-0.19-0.32c0.52,0.36,0.26,0.5,0.78,0.87l-0.19-0.09c0.36,0.44,0.48,0.37,2.76,2.62l-0.08-0.01
				c0.2,0.15,0.06,0.08,0.72,0.7l-0.15-0.08c0.46,0.6,0.89,0.74,1.33,1.19c-0.12,0.1,0.24,0.58,0.41,0.81l-0.02,0.01
				c0.84,1.43,0.35-0.16,5.9,7.28l-0.09,0.05c0.28,0.45,0.4,0.68,0.78,1.22l-0.13-0.07c0.86,0.89,0.43,0.38,1.61,2.34
				c-0.02,0-0.07,0.01-0.13-0.08c0.16,0.26,0.49,1.07,0.61,0.87c-0.11,0.07,0.3,0.59,0.52,0.87l-0.11,0
				c0.04,0.01,0.31,0.33,0.76,1.08l-0.12-0.1c0.23,0.48,0.36,0.17,0.5,1.06c-0.08-0.13,0.33,0.34,0.18,0.29
				c0.12,0.57,0.09-0.07,0.48,1.18c-0.17-0.41-0.31-0.73-0.29-0.71c-0.08,0.04,0.05,0.37-0.18,0.16c0.14,0.25,0.06-0.04,0.6,1.01
				l-0.02-0.03c0.21,0.44,0.07,0.26,0.36,1.03c0.09-0.05,0.15,0.17,0.15-0.06c0.01,0.05-0.03-0.08-0.11-0.2l0.01,0.01
				c-0.04-0.11-0.1-0.25-0.16-0.41c0.64,1.05,0.2,0.84,1.18,3.16c-0.22-0.37-0.17-0.36,0.32,1.01l-0.06-0.06
				c0.06,1.07,0.8,1.57,0.75,2.78c0-0.08,0-0.12,0.02-0.14c-0.14,0.56,0.84,3.16,0.7,6.78l0.07-0.09c-0.01,0.25,0.05,0.8-0.03,0.75
				C376.31,346.63,376.46,349.11,377.05,346.95z M345.92,300.87c0.01-0.02-0.03-0.05-0.05-0.08
				C345.86,300.81,346,300.77,345.92,300.87z M346.18,301.46c0,0.02,0,0-0.02,0.05C346.14,301.52,346.09,301.47,346.18,301.46z
				 M346.05,300.81c0.02-0.01,0,0,0.04,0C346.08,300.81,346.07,300.81,346.05,300.81z M373.62,331.94c-0.09-0.06-0.09-0.03-0.1-0.2
				C373.55,331.79,373.5,331.76,373.62,331.94z M373.6,330.45c0.04,0.03-0.02-0.14-0.03-0.26
				C373.51,330.14,373.8,330.56,373.6,330.45z M373.87,330.89c0.04,0.07,0,0,0.08,0.17C373.93,331.02,373.91,330.97,373.87,330.89z"
                />
                <path
                  className="st10"
                  d="M377.7,325.02c-0.13-0.07,0.08-0.4-0.65-1.46c0.28,0.2-0.21-0.56,0-0.56c-0.24-0.14-0.36-0.7-0.59-1.02
				c0.02,0.01,0.06,0.04,0.07,0.08c-0.07-0.21-0.05-0.28-0.08-0.42c0.12,0.24-0.88-0.7-0.17-0.44c-0.1,0.06-0.3-0.37-0.34-0.25
				c-0.01-0.22-0.04-0.17,0.02-0.32c-0.1,0.22-0.61-0.95-0.19-0.6c-0.61-0.48,0.04-0.08-1.25-1.91c-0.12,0.1-0.15,0.19-0.29-0.16
				c0.4-0.55,0.26-0.01-1.24-2.42l0.18,0.03c-0.84-0.86-0.52-1.22-3.93-5.61l0.08,0.05c-0.19-0.32-0.42-0.2-0.55-0.53l0.05,0.04
				c-0.19-0.24-1.45-2.14-2.86-3.52l0.08-0.07c-0.03,0.03-0.19-0.11-0.43-0.47c-0.11,0.33,0.12-0.2-0.91-0.98
				c0.04-0.02,0.14,0.06,0.22,0.12c-0.52-0.46-0.08-0.07-0.93-0.97l0.09-0.01c-2.39-2.61,0.14-0.01-6.99-7.05l0.03,0.02
				c-0.81-1.19-0.65,0.35-2.09-2.63l0.01,0.08c-0.01-0.01,0-0.02-0.01-0.03c-0.08,0.08,0.18,0.13-0.14-0.29
				c0.35-0.06-0.19-0.17,0.1-0.19c-0.06-0.07-0.04-0.2-0.13-0.23c0.08-0.09-0.05-0.26,0.11-0.26c-0.04-0.02-0.13-0.12-0.15-0.19
				c0.2,0.11-0.01-0.26,0.19-0.24c-0.17-0.08-0.09-0.34-0.18-0.5c0.01,0.01,0.04,0.03,0.03,0.04c0.01-0.09,0.06-0.12,0.09-0.18
				c-0.14-0.01-0.17-0.16-0.26-0.23c0.13-0.12,0.16,0.07,0.29,0.03c-0.11,0.01-0.11-0.2-0.2-0.16c0.08-0.09,0.04-0.08,0.16-0.12
				c-0.18,0.07-0.13-0.5,0.09-0.27c-0.3-0.11,0.19-0.38-0.35-1.14c-0.01,0.04,0.03,0.05,0.05,0.07l-0.03-0.07
				c0.16,0.35,0.22-0.29,0.13-0.03c0.56,0.18-0.03-0.72,0.34-2.18c-0.06,0.27,0.18-0.19,0.58-1.83l0.02,0.05
				c0.09-0.14-0.14-0.24,0-0.35l0.01,0.03c0.22-0.22,0.12-0.25,1.02-2.04c-0.17-0.05,0.19,0.04,0.24-0.54
				c0.03,0.03,0.02,0.08,0.02,0.13c-0.01-0.2,0.11,0.03,0.11-0.16c-0.03,0.01-0.05-0.04-0.07-0.06c0.1-0.04,0.19-0.19,0.23-0.32
				l0.05,0.06c0.13-0.2,0.23-0.44,0.38-0.67c0.02,0.13-0.28-0.19-0.03-0.06c-0.04-0.07-0.16-0.2,0.05-0.01l0.03-0.12
				c0.03,0.01,0.03,0.05,0.06,0.08c0.31-0.5,0.08,0.02,0.82-1.47c0.07,0.05,0.19-0.09,0.17,0.02c0.39-0.42,0.19-0.63,1.18-0.89
				l-0.01,0.01c0.53-0.14-0.02-0.03,1.63,0.19c-0.11-0.38,0.63,0.04,1.84,2.16c-0.03-0.02-0.06-0.01-0.07-0.04
				c-0.06,0.1,0.07,0.29,0.14,0.27c-0.01,0.17-0.06-0.09,0.18,1.12c-0.16-0.01-0.14,0.25-0.07,0.35c-0.04-0.03-0.06,0.08-0.12,0.03
				c-0.01,0.1,0.01,0.2,0.09,0.19c-0.22,0.31-0.08,0.18-0.23,1.39c-0.26-0.38,0.14-0.6-0.48,1c-0.01-0.01-0.01-0.04-0.01-0.07
				c0.01,0.15,0-0.03,0.14,0.37c-0.14-0.17-0.17,0.19-0.33-0.05c0,0.03,0,0.08,0.05,0.1c-0.04,0.03-0.07,0.03-0.09-0.04
				c-0.06,0.17,0.04,0.15-0.04,0.29c-0.03-0.02-0.02-0.05-0.05-0.09c0.02,0.1,0.03,0.21-0.07,0.3c-0.01-0.02-0.04-0.02-0.02-0.05
				c-0.12,0.2-0.08,0.43-0.28,0.59c-0.07-0.15-0.09,0.04-0.2-0.03l-0.05,0.13l0.08,0c-0.07,0.09-0.07,0.23-0.17,0.29l0.02-0.1
				l-0.12,0.19l0.12-0.07c-0.07,0.25,0.16,0.12-0.4,0.36c0.04,0.12-0.17,0.23-0.14,0.36c-0.04-0.15-0.25-0.03-0.18,0.15
				c0.01-0.02-0.01-0.02,0-0.05c-0.15,0.17-0.23,0.21-0.3,0.24l0.04-0.06l-0.09,0.01l0.02,0.06c-0.27,0.03,0.13,0.2-1.24,1.23
				l-0.01-0.06c-0.31,0.33-0.35,0.2-1.11,0.89c0.09-0.06-0.23,0.02-0.1,0.34c0.06-0.12-0.08-0.1-0.15-0.1
				c0.02,0.01,0.03,0.05,0.04,0.06c-0.21-0.08,0.02,0.11-0.14,0.14c0.01-0.03-0.19,0.34-0.14,0.04c-0.08,0.07-0.05,0.27-0.19,0.26
				c0.02-0.05,0.07-0.09,0.09-0.14c-0.15-0.02-0.14,0.01-0.21,0.04l0.01,0l-0.17,0.07c0.18,0.22,0.02,0.01-0.17,0.44
				c-0.44-0.3,0.03-0.28-1.54,1.1c-0.04-0.19,0.03-0.26-0.18-0.04c0.02,0-0.11,0.12-0.11,0.22c0.16-0.02,0.09,0.21,0.4-0.26
				l0.05,0.15l0.04-0.18c0.05,0.01,0.12,0.06,0.07,0.12c0.11-0.01,0.07,0.05,0.22-0.16c0,0.04,0.08,0.07,0.02,0.09
				c0.19-0.05,0.16-0.26,0.28-0.22l-0.01,0.02c0.11-0.62-0.12,0.04,0.58-0.63c0.09,0.22,0.17-0.29,0.3-0.01
				c-0.02-0.26,0.11,0.13,0.47-0.49c0.03,0.06-0.02-0.04,0.43-0.17c-0.06-0.12,0.08-0.19,0.15-0.28c0.04,0.06,0.05,0.1,0.02,0.16
				c0-0.02,0.06,0.02,0.13-0.2l0.04,0.09c0.1-0.09,0.15-0.26,0.33-0.33c-0.05-0.08-0.27-0.11-0.16-0.27
				c0.04,0.03,0.09,0.09,0.07,0.14c0.01-0.02,0.02-0.07,0.06-0.07l-0.01,0.11c0.01-0.06,0.19-0.16,0.08-0.01
				c0.06-0.04,0.16-0.06,0.22-0.04c-0.11-0.12,0.26-0.02,0.39-0.5l0,0c0.03-0.21,0.57-0.28,1.15-0.95
				c-0.04,0.12,0.06,0.01-0.05,0.11c0.12-0.07,0.19-0.05,0.23-0.23c0.02,0.1,0.15,0.01,0.18-0.06l-0.11,0.02
				c0.36-0.21,0.13,0.06,0.72-0.56c-0.5-0.02,0.45-0.62,0.43-0.51l-0.02-0.04c0.07-0.02,0.19-0.17,0.21-0.09
				c0.03-0.1-0.03-0.08-0.09-0.03c0.16-0.09,0.17-0.21,0.31-0.32c-0.07,0.04,0.04-0.63,0.29-0.27c0.03-0.18,0.22-0.22,0.28-0.29
				l-0.02,0.09c0.01-0.05,0.04-0.09,0.06-0.13l-0.1,0c0.04-0.03,0.06-0.07,0.09-0.05c0-0.11-0.28-0.13,0.03-0.33
				c-0.2,0.18,0.32,0.31,0.16-0.2c0.04,0.05,0.13,0.01,0.13-0.02c-0.02,0.06-0.15-0.04-0.04-0.31l-0.06,0.06
				c-0.04-0.05,0-0.11,0.02-0.2c-0.07,0.09,0.26-0.15,0.53-0.57c-0.1,0.02-0.08,0.03-0.11-0.11c-0.01,0.1-0.03,0.19-0.05,0.28
				l-0.04-0.21c-0.03,0.08-0.02,0.18-0.05,0.22c-0.03-0.11-0.01-0.09-0.09-0.08c0.16-0.43,0.44,0.03,0.47-0.86
				c-0.08,0.05-0.12,0.13-0.04-0.18c0.07,0.02,0.1-0.02,0.15-0.05c-0.05-0.01,0.05-0.19-0.08-0.24c0.15-0.2,0.18-0.08,0.01-0.56
				c0.07,0.07,0.13-0.02,0.16,0.13c-0.04-0.21,0.01,0.05-0.2-0.57c0.15-0.14,0.21-0.29,0.05-1.21c0.02,0.01,0.03-0.01,0.03,0.02
				c-0.15-0.4,0.05-0.03-0.07-0.69l0.02,0.03c0.04-0.13-0.17-0.23-0.21-0.4c0,0,0.02,0,0.03-0.01c-0.16-0.07-0.27-0.27-0.39-0.4
				c0.03-0.01,0.04-0.02,0.06-0.02c-0.07,0-0.16-0.04-0.06-0.28c0.03,0.04,0.06,0.08,0.08,0.12c0.03-0.23-0.08-0.58-1.04-0.99
				c0.13-0.02,0.31,0.06,0.43,0.1c-0.06-0.07-0.23-0.2-0.3-0.18c0.03,0.01,0.09,0.02,0.1,0.06c-0.71-0.29-0.02-0.01-1.74-1.01
				c0.01,0.19-0.1,0.22-0.23,0.17c0.02-0.05,0.15-0.12,0.02-0.19c0.1,0.08-0.1,0.1-0.06,0.17c-0.13-0.07-0.28-0.19-0.38-0.33
				c0.03,0.07-0.06,0.11-0.18,0.1l0.17,0.08c-0.26,0.14-0.27-0.15-0.53,0.03l0.07-0.09c-0.14,0.07-0.53,0.18-0.57,0.4
				c-0.03-0.01-0.09-0.05-0.04-0.09c-0.33,0.14-0.52,0.55-0.76,0.64l-0.02-0.05c-0.09,0.18-0.1,0-0.26,0.33l0-0.09
				c-0.27,0.18-0.21,0.44-0.33,0.66c-0.12-0.1-0.31,0.06-0.39,0.14l-0.02-0.02c-0.35,0.33,0.23,0.16-1.04,1.58
				c-0.1,0.65,0.13-0.01-1.07,2.12l-0.04-0.08c0.05,0.49,0.07,0.18-0.28,1.09c-0.02-0.01-0.06-0.03-0.05-0.07
				c-0.05,0.11-0.25,0.4-0.04,0.43c-0.13-0.04-0.1,0.24-0.08,0.39l-0.09-0.05c0.03,0.01,0.03,0.17,0.02,0.54l-0.04-0.07
				c-0.06,0.2,0.2,0.18-0.14,0.44c0.01-0.06,0.09,0.24,0,0.14c-0.18,0.17,0.1,0.05-0.15,0.48c0.04-0.17,0.08-0.3,0.08-0.29
				c-0.09-0.02-0.13,0.11-0.23-0.03c-0.07,0.08,0.19,0.28-0.12,0.88c0.1,0.01,0.07,0.1,0.16,0.03c-0.01,0.02,0-0.03-0.01-0.09
				l0.01,0.01c0.01-0.05,0.02-0.1,0.03-0.17c0.04,0.06,0.06,0.13,0,0.19c0.06,0.05,0.11,0.1,0.14,0.13
				c-0.31,0.16-0.28,0.16-0.32,0.99c-0.01-0.04-0.01-0.08-0.03-0.1c0.03,0.13-0.03,0.36,0.1,0.4c-0.08,0.14-0.11-0.1-0.11,0.11
				l-0.04-0.03c-0.26,0.39,0.27,0.69-0.03,1.13c0.02-0.03,0.03-0.05,0.05-0.05c-0.03,0.71-0.15,0.47,0.06,3.13
				c-0.09-0.1-0.18-0.19-0.29-0.3l-0.02,0.07c-1.34-0.86-0.01,0.07-3.63-3.34l0.2,0.03c-0.78-0.55-1.4-1.33-2.15-1.88
				c0.06,0.02,0.08-0.01,0.15,0.06c-0.08-0.2-0.52-0.5-0.57-0.45c0.03-0.05-0.59-0.67-1.99-1.81c0.13-0.1-0.35-0.51-0.56-0.61
				c0.09,0.02-0.1-0.17,0.01-0.16c-0.18-0.17-0.36-0.31-0.39-0.22c-0.47-0.7-0.37-0.48-2.4-2.24c0.12,0.04,0.2,0.08,0.27,0.11
				c-0.34-0.31-0.4-0.4-2.03-1.96c0.01-0.01,0.07,0.02,0.11,0.06c-0.27-0.17,0.04,0.01-0.63-0.22c0.17,0.01-0.17-0.16,0.16-0.42
				l0.03,0.09c0.06-0.07-0.01-0.08-0.06-0.11c0.04-0.02,0.06-0.04,0.07-0.06l-0.18-0.07c0.34-0.09-0.19-0.09,0.1-0.15
				c-0.06-0.04-0.05-0.14-0.14-0.15c0.08-0.07-0.06-0.18,0.09-0.2c-0.04-0.01-0.14-0.07-0.15-0.12c0.2,0.05-0.03-0.19,0.17-0.2
				c-0.17-0.03-0.12-0.22-0.23-0.33c0.01,0,0.04,0.01,0.04,0.02c0-0.07,0.05-0.1,0.07-0.14c-0.14,0.02-0.19-0.08-0.28-0.13
				c0.12-0.1,0.17,0.02,0.29-0.02c-0.11,0.02-0.13-0.12-0.22-0.08c0.07-0.08,0.03-0.06,0.14-0.11c-0.17,0.02-0.04-0.05-0.15-0.09
				c0.09-0.04-0.01-0.2,0.21-0.11c-0.27,0,0.09-0.37-0.5-0.74c0,0.03,0.03,0.03,0.06,0.04l-0.04-0.04c0.2,0.2,0.18-0.22,0.12-0.04
				c0.53,0.03-0.35-0.74,0.07-0.79c-0.17-0.4,0.11,0.05-0.14-0.77c-0.04,0.4,0.19-0.49,0.17-1.34l0.03,0.03
				c0.06-0.1-0.19-0.16-0.08-0.25l0.01,0.02c0.08-0.16,0.22-0.02,0.38-1.44l0.14,0c-0.14-0.2,0.12,0.1,0.1-0.46
				c0.01,0.01,0.02,0.02,0.02,0.03c0.03-0.02,0.15,0.04,0.01-0.09c0.08-0.02,0.13-0.13,0.14-0.23l0.06,0.05
				c0.09-0.13,0.12-0.32,0.21-0.47c-0.06,0.04-0.17-0.04-0.15-0.09l0.11,0.04c-0.05-0.06-0.18-0.17,0.04,0l0.01-0.09
				c0.03,0.01,0.04,0.04,0.07,0.07c-0.04-0.07,0.17-0.05,0.09-0.16c0.12-0.08,0.28-0.5,0.44-0.84c0.07,0.06,0.15-0.03,0.15,0.06
				c0.1-0.11,0.13-0.14,0.24-0.31c-0.07-0.02-0.1-0.11,0.03-0.01c0.12-0.25,0.54-0.02,0.61-0.25l-0.01,0.01
				c0.21-0.18,0.06,0,1.24-0.12c-0.33-0.36,0.8,0.05,1.6,0.86c-0.03,0-0.06,0.02-0.07,0c-0.05,0.09,0.08,0.17,0.15,0.14
				c-0.01-0.08,0.02,0.34,0.27,0.71c-0.16,0.02-0.1,0.19-0.03,0.25c-0.05-0.02-0.05,0.06-0.11,0.04c0.01,0.07,0.04,0.13,0.12,0.12
				c-0.19,0.14,0.16,0.27-0.09,0.35c0.07,0.22,0.11,0.43,0.11,0.64c-0.31-0.22-0.03-0.5-0.22,0.76c-0.01,0-0.02-0.03-0.03-0.05
				c0,0.03-0.04,0.07,0.03,0.09l-0.01-0.04c0.12-0.02,0.17,0.19,0.22,0.22c-0.18-0.12-0.1,0.15-0.32-0.02
				c0.01,0.03,0.03,0.06,0.08,0.07c-0.22,0.24,0-0.01-0.4,0.88l0.07-0.01c-0.03,0.08,0.02,0.19-0.05,0.23l-0.02-0.08l-0.05,0.15
				l0.09-0.06c0.01,0.19,0.21,0.07-0.24,0.3c0.08,0.09-0.08,0.18,0,0.29c-0.01-0.03-0.07-0.06-0.09-0.07l0.06,0.08
				c-0.11,0.04-0.04,0.09-0.27,0.28l0.01-0.05l-0.07,0.01l0.04,0.05c-0.06,0.02-0.19,0.37-0.69,1.01c0.06-0.08-0.28-0.02-0.18,0.23
				c-0.23,0.07-0.32-0.13-0.57,0.7c0.03-0.1-0.09-0.1-0.14-0.11c0.02,0.01,0.03,0.04,0.05,0.06c-0.18-0.11,0.03,0.13-0.09,0.11
				c0.01-0.01-0.12,0.26-0.11,0.01c-0.15,0.16,0.02-0.02-0.36,0.13c0.18,0.24,0.02,0.01-0.1,0.35c0-0.17,0.02-0.07-0.19-0.21
				c0,0.1-0.12,0-0.12,0.15c-0.01-0.02-0.03-0.01-0.05-0.01c0.04,0.04-0.13,0.2-0.71,0.62c-0.05-0.21-0.15,0.21-0.14-0.11
				c-0.04,0.06-0.08,0.15-0.12,0.2c-0.04-0.05,0-0.1,0-0.15c0.02,0.02-0.17,0.09-0.08,0.24c0-0.27,0.02,0.26,0.31-0.18l0.03,0.15
				l0.04-0.15c0.04,0.02,0.09,0.09,0.05,0.13c0.08,0.03,0.06,0.06,0.18-0.08c0,0.03,0.06,0.09,0.01,0.09
				c0.11-0.01,0.33-0.04,0.23-0.39l0.21,0.18l-0.04-0.1c0.02-0.01,0.05-0.02,0.07,0.02c0.02-0.1-0.03-0.03-0.04-0.11
				c0.13,0.04,0.18-0.17,0.29-0.04c-0.02-0.09-0.1-0.01-0.06-0.11c0.09,0.21,0.13-0.22,0.24,0.06c-0.05-0.25,0.2,0.12,0.34-0.36
				c0.02,0.06-0.01-0.04,0.34-0.09c-0.07-0.11,0.03-0.17,0.08-0.24c0.05,0.06,0.06,0.09,0.05,0.15c0-0.02,0.06,0,0.07-0.17
				l0.05,0.08c0.07-0.07,0.08-0.21,0.22-0.26c-0.06-0.07-0.24-0.11-0.19-0.25c0.04,0.03,0.1,0.09,0.09,0.13
				c0.01,0,0.04-0.14,0.05,0.04c0-0.05,0.12-0.14,0.06,0c0.05-0.03,0.12-0.04,0.17-0.02c-0.03-0.04-0.09-0.2,0.11-0.08
				c-0.02-0.05,0.07-0.06-0.01-0.13c0.02-0.02,0.06,0.01,0.09,0.02c-0.01-0.08,0,0,0.02-0.23l0,0c-0.03-0.19,0.4-0.2,0.72-0.8
				c0,0.1,0.06,0.01-0.01,0.09c0.08-0.06,0.14-0.05,0.13-0.2c0.05,0.08,0.14,0,0.14-0.05l-0.09,0.02c0.25-0.19,0.11,0.03,0.45-0.49
				c-0.45,0.02,0.21-0.53,0.21-0.44l-0.03-0.03c0.06-0.02,0.12-0.14,0.16-0.08c0-0.08-0.05-0.06-0.09-0.02
				c0.11-0.08,0.08-0.17,0.17-0.27c-0.07,0.03-0.14-0.5,0.18-0.23c-0.03-0.14,0.13-0.19,0.16-0.24l0.01,0.07
				c-0.01-0.04,0.01-0.07,0.02-0.1l-0.09,0c0.13-0.11,0.1-0.01,0-0.29c-0.1,0.18,0.39,0.16,0.09-0.16c0.05,0.04,0.12,0,0.12-0.03
				c0.01,0.04-0.14,0.01-0.12-0.22c-0.05,0.03-0.07,0.09-0.07-0.1c0.05,0.01,0.02,0.07,0.04,0.05c-0.16-0.18,0.58-0.32,0.19-0.58
				l0.02,0.21l-0.09-0.15c-0.01,0.06,0.02,0.13,0,0.17c-0.05-0.07-0.03-0.06-0.11-0.05c0.02-0.4,0.39,0.1,0.28-0.66
				c-0.06,0.04-0.09,0.1-0.07-0.12c0.07,0.01,0.1-0.02,0.14-0.05c-0.05,0,0.02-0.14-0.12-0.16c0.11-0.15,0.18-0.08-0.08-0.4
				c0.08,0.04,0.12-0.03,0.18,0.07c-0.06-0.15,0.02,0.03-0.27-0.38c0.13-0.12,0.17-0.23-0.08-0.88c0.02,0,0.03-0.01,0.03,0.01
				c-0.21-0.28,0.06-0.05-0.15-0.5l0.03,0.02c0.02-0.11-0.18-0.13-0.25-0.24c0,0,0.02-0.01,0.03-0.02c-0.15-0.01-0.29-0.1-0.4-0.17
				c0.02-0.02,0.03-0.03,0.04-0.04c-0.06,0.03-0.14,0.03-0.09-0.2l0.09,0.07c0.25-0.33-0.7,0-0.62-0.4c-0.07,0-0.26,0.04-0.37,0.03
				c0.09-0.08,0.23-0.09,0.34-0.11c-0.06-0.03-0.21-0.07-0.26-0.01c0.09,0.03,0.11,0.02-0.25,0.07c0.08-0.26-0.3-0.13-0.37-0.33
				c-0.23,0.08-0.45-0.06-0.72-0.01c0.09,0.04-0.14,0.37-0.14,0.02c0.08,0.06-0.03,0.11-0.02,0.16c-0.09-0.04-0.2-0.12-0.3-0.26
				c0.04,0.07-0.02,0.12-0.1,0.11l0.13,0.07c-0.15,0.15-0.21-0.13-0.36,0.04l0.04-0.08c-0.08,0.07-0.35,0.13-0.36,0.33
				c-0.03-0.01-0.07-0.06-0.04-0.09c-0.19,0.01-0.25,0.13-0.78,0.61l0-0.08c-0.29,0.61-0.54,0.11-1.37,1.73
				c0.09,0.23-0.09,0.12-0.36,1.28c-0.17,0.02-0.09-0.05-0.22,0.36l-0.06-0.05c0.15,0.34,0.14,0.09-0.02,0.83
				c-0.02-0.01-0.06-0.02-0.06-0.05c-0.02,0.08-0.15,0.32,0.05,0.31c-0.13-0.02-0.04,0.19,0.01,0.29l-0.1-0.03
				c0.03,0,0.07,0.1,0.14,0.39l-0.06-0.05c-0.02,0.14,0.24,0.1-0.04,0.33c0.02,0.02-0.02,0.01-0.02,0.45
				c0.01-0.12,0.01-0.22,0.02-0.21c-0.17-0.11-0.27,0.38,0,0.64c-0.01,0.01,0-0.02-0.03-0.06l0.01,0c0-0.03,0-0.08-0.01-0.12
				c0.05,0.04,0.09,0.08,0.04,0.14c0.06,0.03,0.13,0.05,0.16,0.07c0.05,0.12-0.26,0.61,0,1.8c0.01-0.02,0.02-0.04,0.04-0.04
				c0.02,0.06-0.06,0.71,0.33,1.71c-0.15-0.08-0.31-0.17-0.47-0.38c0.03,0,0.02-0.05,0.09,0.02c-0.36-0.37-0.74-0.53-1.09-0.99
				c0.12,0.02,0.21-0.05-0.26-0.49l0.04,0.13c-0.18-0.2-0.41-0.32-0.53-0.52l0.17,0.11l-0.36-0.36l0.15,0.25
				c-0.23-0.16-0.1,0.13-0.32-0.16c0.06,0.05-0.18-0.2-0.22-0.36l0,0c-0.49-0.37,0.09-0.25-0.86-0.92c0.22-0.01-0.14-0.4-0.32-0.41
				c0.03,0.02,0.03,0,0.08,0.05c-0.32-0.36-0.43-0.53-0.51-0.67l0.09,0.09c-0.36-0.48,0.04,0.03-1.42-1.62l0.06,0.09
				c-0.53-0.41-0.05-0.47-1.29-1.59l0.08,0.03c-0.71-0.84-0.45-0.74-1.86-2.54c0.14,0.12,0.21,0.19-0.26-0.37
				c-0.04,0.08,0,0.09,0.02,0.23c-0.08-0.03-0.21-0.15-0.29-0.27c0.19,0.19,0.11-0.08,0.08-0.2c-0.01,0.03-0.05,0.01-0.07,0.04
				c-0.06-0.33-0.16,0.05-0.32-0.35c0.08-0.02,0.17-0.03-0.53-0.79c0.07,0.07,0.16,0.19,0.24,0.26c-0.04-0.25-0.08-0.26-0.16-0.41
				l0.01,0.02l-0.17-0.36l0.03,0.1c-0.36-0.12-0.36-0.48-0.71-0.73c0.14,0.18-0.03-0.1-0.22-0.85c0.18,0.48-1.02-1.39-1.8-2.81
				c0.16,0.05,0.32,0.1-0.45-0.85l0.04,0.16c0.05-0.04,0.22-0.03,0.18,0.28l-0.09-0.13c-0.1,0.24,0.32,0.54,0.45,0.82
				c-0.1,0.03-0.22,0.06,0.22,0.74c-0.05-0.02-0.07,0.08-0.12-0.05c0.12,0.38,0.41,0.5,0.39,0.68l-0.02-0.02
				c0.42,1.06,0.7,0.75,1.67,2.73c-0.13,0.06-0.01-0.13,0.41,0.56c-0.05,0.01,0.32,0.58,0.47,0.88l-0.02,0
				c0.14-0.02,0.31,0.27,0.48,0.46c-0.07,0.04-0.12,0.02-0.22-0.07c0.04,0.01-0.04,0.05,0.35,0.37c-0.41,0,0.15,0.1,0.79,0.88
				l-0.17-0.09c0.07,0.04,0.33,0.44,0.05,0.14c0.38,0.63,0.15,0.26,1.11,1.41l0,0c0.48,0.36,0.31,0.63,2.02,2.6
				c-0.2-0.14,0.01,0.1-0.19-0.16c0.18,0.25,0.18,0.34,0.46,0.53c-0.14-0.03,0.06,0.25,0.18,0.34l-0.09-0.2
				c0.12,0.12,0.2,0.23,0.29,0.35l-0.14,0.02c0.38,0.53,0.9,1.09,1.97,2.11c0.02-0.4,0.28,0.74,0.36,0.47
				c0.2,0.32,0.38,0.43,0.62,0.74c0.02-0.08,0.99,0.58,0.51,0.66c0.3,0.2,0.41,0.51,0.53,0.67l-0.15-0.11
				c0.09,0.07,0.15,0.13,0.21,0.2l-0.01-0.13c0.06,0.09,0.13,0.16,0.1,0.18c0.26,0.06,0.19-0.19,0.53,0.35
				c-0.37-0.45-0.49,0.11,0.35,0.41c-0.08,0-0.01,0.16,0.05,0.2c-0.1-0.1,0.13-0.1,0.51,0.26l-0.1-0.14
				c0.34,0.08,0.45,0.62,1.26,1.55c-0.02-0.13-0.04-0.13,0.2-0.01l-0.47-0.38l0.36,0.19c-0.13-0.13-0.31-0.23-0.37-0.32
				c0.18,0.08,0.15,0.08,0.14-0.03c0.6,0.64,0.03,0.59,1.36,1.59l-0.1-0.15c0.47,0.26,0.19,0.46,1.77,1.56
				c-0.15-0.04-0.04,0.14-0.28-0.03c0.58,0.36-0.13-0.17,2.81,2.53c-0.01,0-0.02,0.01-0.03,0.01c0.68,0.55-0.03,0.03,0.96,1.02
				l-0.06-0.04c0.22,0.27,0.05,0.09,1.64,1.25c-0.03,0.02-0.05,0.03-0.07,0.04c0.09-0.01,0.26,0.04,0.36,0.45l-0.23-0.21
				c0.03,0.2,0.29,0.27,0.39,0.4l-0.05,0.03c0.35,0.31,0.45,0.21,0.81,0.57l-0.01-0.07c0.63,0.38-0.04,0.4,1.16,1.03
				c-0.2-0.05-0.52-0.28-0.73-0.4c0.13,0.12,0.45,0.41,0.57,0.43c-0.06-0.04-0.15-0.08-0.19-0.14c1.22,0.9,0.09-0.01,2.85,2.91
				c-0.05-0.33,0.16,0.15,0.28-0.04c0.32,0.24,0.34,0.5,0.49,0.99c0.02-0.09,0.17,0.01,0.3,0.18l-0.15-0.28
				c0.46,0.29,0.2,0.45,0.66,0.74l-0.17-0.07c0.29,0.35,0.41,0.31,2.41,2.15l-0.07,0c0.18,0.12,0.05,0.07,0.64,0.57l-0.14-0.06
				c0.4,0.5,0.79,0.59,1.19,0.95c-0.11,0.11,0.2,0.5,0.36,0.69l-0.02,0.01c0.88,1.18,0.03-0.19,5.49,5.77l-0.08,0.06
				c0.28,0.35,0.4,0.54,0.78,0.95l-0.13-0.04c0.83,0.63,0.39,0.26,1.63,1.78c-0.02,0.01-0.07,0.02-0.12-0.05
				c0.15,0.21,0.53,0.85,0.63,0.65c-0.1,0.09,0.33,0.45,0.56,0.65l-0.11,0.02c0.04,0,0.3,0.23,0.8,0.79l-0.12-0.06
				c0.26,0.37,0.35,0.07,0.57,0.82c-0.09-0.1,0.35,0.24,0.2,0.22c0.18,0.47,0.08-0.08,0.58,0.91c-0.2-0.32-0.38-0.56-0.35-0.55
				c-0.07,0.05,0.09,0.31-0.15,0.18c0.18,0.18,0.05-0.05,0.67,0.75l-0.03-0.02c0.24,0.33,0.08,0.22,0.46,0.81
				c0.08-0.06,0.17,0.11,0.14-0.09c0.02,0.04-0.04-0.06-0.13-0.15l0.01,0c-0.06-0.08-0.13-0.19-0.21-0.31
				c0.75,0.73,0.28,0.67,1.55,2.41c-0.26-0.25-0.22-0.26,0.47,0.77l-0.07-0.03c0.26,0.87,0.98,1.12,1.22,2.12
				c-0.02-0.07-0.03-0.1-0.01-0.12c0,0.51,1.37,2.4,2.29,5.32l0.04-0.1c0.01,0.74,0.45,0.85,1.13,1.63
				C378.13,325.84,377.4,325.1,377.7,325.02z M355.13,287.92c0-0.01,0-0.03,0.01-0.07C355.14,287.88,355.14,287.85,355.13,287.92z
				 M355.2,287.58c-0.02-0.02,0,0.19-0.09,0.08C355.13,287.67,355.16,287.61,355.2,287.58z M354.4,288.03
				c0.01-0.04,0.07-0.11,0.04,0.03C354.43,288.05,354.41,288.04,354.4,288.03z M339.24,276.43c-0.01-0.03,0.04-0.09,0.05,0.01
				C339.27,276.44,339.25,276.44,339.24,276.43z M339.92,276.21c0.01,0.02,0,0,0.01,0.05C339.93,276.25,339.93,276.24,339.92,276.21
				z M339.93,276.01c-0.02-0.01,0.03,0.14-0.07,0.06C339.88,276.09,339.9,276.04,339.93,276.01z M339.94,280.05
				c0.01,0.03,0.01,0.05,0.01,0.09c-0.03-0.02-0.05-0.05-0.1-0.09C339.87,280.03,339.9,280.04,339.94,280.05z M371.34,313.02
				c-0.06-0.04,0.26,0.27,0.06,0.22C371.43,313.26,371.37,313.12,371.34,313.02z M371.57,314.54c-0.08-0.02-0.09-0.01-0.12-0.14
				C371.49,314.42,371.44,314.41,371.57,314.54z M371.69,313.56c0.04,0.05,0,0,0.09,0.13C371.77,313.66,371.74,313.62,371.69,313.56
				z"
                />
                <path
                  className="st10"
                  d="M380.54,318.71c0.02,0.42,0.32-0.72,0.46-0.55c-0.04-0.33,0.34-0.97,0.45-1.47c0,0.03,0,0.09-0.02,0.13
				c0.24,0.14,0.26-1.55,0.61-1c-0.11-0.03,0.16-0.59,0.03-0.52c0.19-0.2,0.14-0.19,0.3-0.26c-0.25,0.11,0.5-1.39,0.43-0.7
				c0.15-1.14,0.02,0.03,1.15-2.75c-0.19-0.05-0.22-0.01,0.02-0.41c-0.19,0.78,0.29,0.08,0.19-0.04c0.65-0.17,0.64-1.34,1.69-3.02
				l0.04,0.2c0.72-1.46-0.15-0.07,4.8-7.65l-0.04,0.12c0.31-0.37,0.14-0.57,0.47-0.88l-0.03,0.08c0.28-0.26-0.15-0.14,3.61-4.81
				c-0.18,0.01,0.48-0.58,1.52-2.12c0.02,0.04-0.06,0.18-0.12,0.3c0.49-0.79,0.08-0.14,1.05-1.44l0.01,0.11
				c0.45-0.56,0.89-1.22,1.38-1.86c0.14,0.23,2.87-4.12,6.65-8.57l-0.02,0.04c1.33-1.83-0.04-0.02,3.6-4.48l-0.08,0
				c0.78-0.99,0.02-0.44,3.88-5.31l-0.04,0.25c0.63-1.1,1.51-2.09,2.11-3.18c-0.17,0.42,0.76-0.88,2.37-3.66
				c0.11,0.11,0.55-0.61,0.65-0.9c-0.01,0.11,0.18-0.19,0.17-0.06c0.17-0.29,0.31-0.56,0.21-0.56c0.72-0.87,0.56-0.64,2.19-3.81
				c-0.02,0.15-0.05,0.27-0.08,0.36c0.27-0.55,0.43-0.62,1.78-3.33c0.01,0.01-0.01,0.09-0.04,0.16c0.15-0.45-0.13,0.3,0.11-0.85
				c0.06,0.32,0.39-0.63,0.44-0.16c0.03-0.09,0.06-0.22,0.01-0.22c0.08-0.12,0.13-0.15,0.11,0.01c0.21-0.49,0.06-0.36,0.28-0.78
				c0.03,0.02,0,0.11,0.01,0.17c0.06-0.24,0.1-0.5,0.3-0.85c0.01,0.03,0.05,0,0,0.11c0.29-0.64,0.35-1.15,0.75-1.81
				c0.02,0.14,0.1,0.22,0.44-0.58l-0.13,0.12c0.16-0.32,0.21-0.65,0.36-0.92l-0.06,0.27l0.24-0.63l-0.2,0.33
				c0.08-0.36-0.17-0.03,0.06-0.46c-0.03,0.09,0.14-0.33,0.29-0.47l0,0c0.2-0.74,0.3-0.1,0.64-1.56c0.09,0.24,0.35-0.43,0.28-0.64
				c-0.01,0.05,0.01,0.03-0.01,0.13c0.21-0.61,0.33-0.85,0.43-1.03l-0.05,0.16c0.32-0.75-0.01,0.06,0.93-2.72l-0.06,0.13
				c0.12-0.88,0.45-0.48,0.81-2.61l0.02,0.11c0.33-1.41,0.39-1.1,1.05-4.09c0,0.25-0.01,0.37,0.11-0.6
				c-0.09,0.04-0.07,0.09-0.16,0.24c-0.03-0.11-0.02-0.33,0.01-0.52c-0.01,0.35,0.13,0.02,0.2-0.12c-0.03,0.02-0.05-0.04-0.07-0.03
				c0.22-0.37-0.15-0.1,0.05-0.63c0.07,0.06,0.13,0.15,0.25-1.24c0,0.14-0.04,0.33-0.04,0.46c0.16-0.29,0.15-0.33,0.2-0.55
				l-0.01,0.02l0.12-0.52l-0.05,0.13c-0.19-0.43,0.06-0.81-0.04-1.35c0.09,0.53,0.08,0.1,0.18,0.15c0.05-0.13,0.1-0.33,0.13-0.27
				c0-0.18-0.12-0.22,0.15-0.98c-0.21,1.25,0.04-2.45,0.02-4.45c0.13,0.18,0.25,0.31-0.03-1.28l-0.03,0.21
				c0.07-0.01,0.21,0.1,0.04,0.45l-0.03-0.21c-0.2,0.21,0.05,0.83,0.04,1.25c-0.12-0.04-0.19-0.05-0.22,1
				c-0.03-0.06-0.11,0.04-0.07-0.13c-0.11,0.52,0.06,0.85-0.06,1.04l-0.01-0.04c-0.24,1.56,0.21,1.38-0.41,4.21
				c-0.14-0.05,0.09-0.12-0.08,0.91c-0.04-0.04-0.23,0.85-0.36,1.27l-0.02-0.02c0.11,0.11,0,0.54-0.03,0.87
				c-0.07-0.03-0.09-0.09-0.09-0.27c0.01,0.05-0.06,0.01-0.05,0.67c-0.19-0.27-0.09,0.13-0.22,1.56l-0.02-0.23
				c-0.09,0.22,0.04,0.14-0.02,0.32c-0.09-0.03-0.19,0.24-0.07-0.15c-0.32,0.96-0.14,0.41-0.55,2.31l0,0
				c-0.05,0.77-0.38,0.88-1.38,4.13c0.05-0.31-0.09,0.08,0.07-0.32c-0.17,0.37-0.25,0.44-0.32,0.88c-0.03-0.17-0.21,0.25-0.25,0.44
				l0.16-0.24c-0.06,0.21-0.14,0.38-0.21,0.56l-0.07-0.14c-0.36,0.77-0.66,1.72-1.35,3.56c0.41-0.22-0.65,0.78-0.35,0.7
				c-0.24,0.43-0.33,0.69-0.58,1.13c0.07,0-0.32,1.42-0.54,0.96c-0.13,0.45-0.42,0.75-0.55,0.98l0.07-0.23
				c-0.04,0.14-0.1,0.25-0.15,0.35l0.13-0.09c-0.07,0.12-0.13,0.23-0.16,0.21c0.02,0.35,0.23,0.13-0.25,0.79
				c0.3-0.5-0.1-0.03,0.08-0.42c-0.33,0.5-0.26,0.22-0.45,1.03c-0.02-0.1-0.17,0.07-0.2,0.16c0.09-0.16,0.12,0.13-0.17,0.72
				l0.13-0.19c-0.02,0.42-0.54,0.86-1.43,2.21c0.14-0.08,0.14-0.11,0.04,0.22l0.34-0.72l-0.15,0.5c0.12-0.21,0.21-0.46,0.29-0.58
				c-0.06,0.24-0.07,0.21,0.05,0.15c-0.6,0.95-0.66,0.36-1.52,2.32l0.15-0.19c-0.23,0.65-0.48,0.5-1.52,2.73
				c0.03-0.19-0.15,0.01,0.01-0.35c-0.31,0.81,0.18-0.22-2.62,4.28c0-0.02-0.01-0.02-0.01-0.03c-0.61,1.01-0.03-0.03-1.12,1.49
				l0.04-0.09c-0.31,0.35-0.08,0.11-1.39,2.35c-0.02-0.03-0.03-0.04-0.04-0.06c0.01,0.1-0.05,0.32-0.51,0.57l0.23-0.33
				c-0.22,0.1-0.3,0.43-0.45,0.59l-0.03-0.05c-0.35,0.52-0.24,0.61-0.65,1.14l0.07-0.03c-0.44,0.85-0.47,0.1-1.2,1.68
				c0.07-0.26,0.33-0.7,0.47-0.98c-0.14,0.19-0.48,0.66-0.5,0.8c0.05-0.09,0.09-0.2,0.17-0.27c-1.06,1.71,0.01,0.11-3.39,4.22
				c0.38-0.15-0.17,0.23,0.03,0.31c-0.07,0.1,0.01,0-0.21,0.26c0,0,0,0,0.01-0.01c-0.23,0.23-0.51,0.35-0.94,0.6
				c0.1-0.01-0.02,0.21-0.22,0.41l0.33-0.26c-0.34,0.62-0.52,0.37-0.86,1l0.08-0.22c-0.44,0.49-0.38,0.57-2.53,3.47l0-0.08
				c-0.14,0.24-0.09,0.08-0.66,0.93l0.07-0.18c-0.58,0.62-0.69,1.11-1.1,1.69c-0.12-0.1-0.57,0.4-0.8,0.64l-0.01-0.02
				c-0.86,1.28-2.74,3.54-6.33,8.56l-0.07-0.07c-0.37,0.47-0.56,0.71-0.98,1.33l0.03-0.16c-0.62,1.27-0.28,0.58-1.75,2.72
				c-0.01-0.02-0.03-0.06,0.03-0.17c-0.2,0.28-0.84,1.04-0.6,1.05c-0.11-0.07-0.44,0.61-0.62,0.97l-0.05-0.11
				c0.01,0.05-0.2,0.47-0.67,1.35l0.04-0.17c-0.19,0.36-0.68,0.97-1.74,2.65c0.27-0.43,0.49-0.77,0.47-0.73
				c-0.08-0.05-0.3,0.29-0.24-0.05c-0.15,0.3,0.07,0.02-0.58,1.21l0.01-0.04c-0.27,0.48-0.21,0.23-0.71,1.03
				c0.09,0.04-0.06,0.25,0.14,0.09c-0.04,0.05,0.05-0.09,0.11-0.23l0,0.02c0.07-0.11,0.16-0.26,0.26-0.43
				c-0.52,1.31-0.68,0.73-1.93,3.32c0.03-0.1,0.1-0.2,0.1-0.26c-0.05,0-0.25,0.91-0.7,1.31l0.01-0.09
				c-0.75,0.96-0.62,1.88-1.48,2.92c0.06-0.07,0.09-0.11,0.11-0.12c-0.89,1.62,0.13-0.61-3.25,7.05l0.1-0.07
				c-0.11,0.26-0.28,0.86-0.34,0.79c0.1,0.27-1.25,2.3-0.58,1.96c-0.18,0.34,0.27,0.57,0.68-0.55l-0.13,0.36
				c0.32-0.38,0.32-1.71,0.61-1.58C379.87,320.53,380.15,320.35,380.54,318.71z M387.87,304.71c-0.05,0.09-0.17,0.37-0.19,0.18
				C387.68,304.94,387.79,304.81,387.87,304.71z M387.45,305.42c-0.04,0.08,0,0-0.1,0.18C387.36,305.56,387.39,305.51,387.45,305.42
				z M386.38,305.93c0-0.12-0.02-0.1,0.11-0.23C386.47,305.76,386.47,305.7,386.38,305.93z"
                />
                <path
                  className="st10"
                  d="M393.95,313.54c0.08,0.04,0.22-0.22,0.05,0.16c0.44-0.95,0.19-0.41,0.87-2.29l0,0
				c0.18-0.76,0.47-0.87,1.92-4.07c-0.09,0.32,0.1-0.08-0.11,0.32c0.22-0.36,0.31-0.42,0.43-0.86c0.01,0.18,0.24-0.23,0.31-0.42
				l-0.19,0.23c0.09-0.21,0.19-0.38,0.28-0.55l0.05,0.15c0.45-0.75,0.89-1.69,1.81-3.5c-0.44,0.18,0.76-0.73,0.45-0.68
				c0.31-0.41,0.4-0.68,0.71-1.11c-0.07-0.01,0.49-1.42,0.66-0.93c0.18-0.45,0.51-0.73,0.67-0.95l-0.11,0.23
				c0.06-0.14,0.13-0.24,0.2-0.34l-0.15,0.08c0.09-0.12,0.16-0.22,0.19-0.2c0.02-0.37-0.21-0.16,0.34-0.79
				c-0.36,0.48,0.1,0.04-0.12,0.42c0.39-0.49,0.28-0.21,0.55-1.02c0.01,0.1,0.17-0.06,0.22-0.14c-0.11,0.16-0.1-0.15,0.26-0.71
				l-0.15,0.18c0.07-0.42,0.65-0.83,1.68-2.14c-0.15,0.07-0.15,0.1-0.01-0.23l-0.42,0.71l0.2-0.5c-0.14,0.2-0.26,0.46-0.35,0.57
				c0.09-0.24,0.09-0.21-0.03-0.15c0.43-0.56-0.01-0.01,0.92-0.91l-0.09,0.13l0.15-0.08c0.02,0.04,0.01,0.13-0.05,0.13
				c0.05,0.08,0,0.08,0.22,0.07c-0.02,0.03-0.01,0.1-0.06,0.07c-0.03,0.02,0.41-0.13,0.95,0.24c0.06-0.07-0.08-0.08,0.04-0.11
				c-0.1,0.2,0.28-0.05,0.15,0.22c0.17-0.19,0,0.19,0.57,0.04c-0.03,0.06,0.02-0.03,0.33,0.22c0.05-0.12,0.17-0.07,0.27-0.08
				c-0.02,0.07-0.04,0.1-0.1,0.12c0.01-0.01,0.03,0.05,0.2-0.04l-0.04,0.09c0.12,0.02,0.25-0.06,0.39,0.03
				c0.03-0.09-0.07-0.27,0.1-0.3c0,0.05-0.01,0.13-0.06,0.14c0.01,0,0.06-0.03,0.08,0l-0.08,0.07c0.04-0.03,0.2,0.03,0.04,0.05
				c0.06,0.02,0.12,0.08,0.14,0.14c0.01-0.16,0.15,0.18,0.54-0.04l0,0c0.16-0.11,0.48,0.24,1.22,0.27c-0.1,0.04,0.02,0.06-0.1,0.03
				c0.11,0.05,0.13,0.11,0.27,0.03c-0.06,0.08,0.07,0.12,0.13,0.11l-0.07-0.08c0.32,0.15,0.02,0.12,0.73,0.23
				c-0.22-0.42,0.65,0.02,0.55,0.05l0.02-0.04c0.05,0.05,0.2,0.07,0.15,0.13c0.09-0.03,0.04-0.06-0.01-0.09
				c0.13,0.08,0.22,0.03,0.36,0.09c-0.07-0.04,0.48-0.29,0.3,0.11c0.14-0.06,0.24,0.08,0.31,0.1l-0.07,0.03
				c0.04-0.02,0.08-0.01,0.11-0.01l-0.03-0.09c0.16,0.1,0.04,0.09,0.32-0.06c-0.2-0.11-0.12,0.42,0.2,0.06
				c-0.03,0.06,0.03,0.12,0.06,0.11c-0.05,0.02-0.03-0.14,0.22-0.16l-0.06-0.03c0.03-0.06,0.08-0.05,0.16-0.06
				c0,0.05-0.07,0.03-0.05,0.05c0.13-0.16,0.22,0.2,0.64,0.24c-0.04-0.08-0.05-0.06,0.06-0.14l-0.23,0.05l0.15-0.11
				c-0.07,0-0.15,0.05-0.19,0.03c0.07-0.06,0.07-0.04,0.04-0.11c0.4,0,0.07,0.41,0.8,0.18c-0.06-0.06-0.13-0.07,0.13-0.08
				c0,0.07,0.04,0.09,0.07,0.14c-0.01-0.05,0.16,0,0.17-0.14c0.19,0.1,0.11,0.16,0.46-0.12c-0.04,0.08,0.04,0.12-0.07,0.19
				c0.16-0.08-0.04,0.03,0.42-0.31c0.15,0.12,0.26,0.14,1.01-0.14c0,0.02,0.01,0.03-0.01,0.03c0.31-0.21,0.04,0.05,0.56-0.16
				l-0.02,0.03c0.12,0.03,0.16-0.18,0.3-0.25c0,0,0,0.02,0.02,0.03c0.03-0.15,0.17-0.3,0.25-0.41c0.02,0.02,0.02,0.03,0.03,0.05
				c-0.02-0.06-0.01-0.15,0.24-0.09l-0.09,0.09c0.31,0.28,0.13-0.75,0.54-0.65c0.01-0.08,0.02-0.29,0.05-0.42
				c0.06,0.11,0.03,0.27,0.03,0.39c0.03-0.05,0.07-0.16,0.07-0.23c0,0.02-0.01,0.03-0.02,0.04c0.06-0.66-0.01-0.02,0.51-1.64
				c-0.18,0.05-0.24-0.03-0.21-0.16c0.05,0.01,0.16,0.1,0.19-0.02c-0.06,0.09-0.11-0.05-0.17-0.03c0.04-0.12,0.13-0.26,0.24-0.37
				c-0.07,0.04-0.12-0.03-0.12-0.13l-0.05,0.17c-0.17-0.19,0.09-0.26-0.11-0.45l0.09,0.04c-0.08-0.1-0.25-0.43-0.47-0.4
				c0-0.03,0.03-0.09,0.08-0.06c-0.11-0.23-0.23-0.27-1.08-0.62l0.08-0.04c-0.75,0.05-0.51-0.43-2.54-0.26
				c-0.22,0.2-0.15-0.02-1.49,0.26c-0.1-0.16,0.01-0.09-0.48-0.05l0.03-0.08c-0.31,0.28-0.06,0.16-0.9,0.3c0-0.02,0-0.07,0.03-0.08
				c-0.1,0.01-0.4-0.02-0.32,0.17c-0.03-0.13-0.22,0.03-0.32,0.11l0-0.1c0.01,0.03-0.09,0.11-0.38,0.27l0.03-0.07
				c-0.31,0.06-0.16,0.03-0.89,0.21c0.14-0.04,0.25-0.06,0.25-0.05c0.04-0.22-0.45-0.15-0.72,0.22c-0.02,0,0.03-0.01,0.06-0.05
				l0,0.01c0.04-0.01,0.08-0.03,0.14-0.05c-0.03,0.06-0.06,0.12-0.14,0.08c-0.01,0.07-0.02,0.14-0.03,0.18
				c-0.32-0.14-0.24-0.43-0.86,0.12c0.02-0.03,0.05-0.04,0.06-0.07c-0.08,0.08-0.28,0.12-0.25,0.25c-0.14-0.01,0.02-0.14-0.13-0.05
				l0.01-0.05c-0.4-0.07-0.4,0.51-0.85,0.41c0.03,0,0.05,0.01,0.06,0.02c-0.87,0.37-0.31-0.12-2.67,1.56c0-0.01-0.01-0.02-0.02-0.04
				l0.02,0.06c0.01-0.01,0.01-0.01,0.01-0.01c0,0.13,0.05-0.02,0.47,0.21l-0.09,0.06c0.09,0.03,0.09-0.04,0.1-0.11
				c0.09,0.03,0.1,0.14,0.12-0.15c0.19,0.3,0.06-0.22,0.19,0.03c0.03-0.08,0.14-0.1,0.12-0.19c0.1,0.05,0.18-0.13,0.25,0.01
				c0-0.04,0.04-0.16,0.09-0.2c-0.01,0.21,0.2-0.1,0.27,0.09c-0.01-0.18,0.22-0.2,0.3-0.34c0,0.02,0,0.05-0.02,0.04
				c0.08-0.02,0.12,0.01,0.18,0.02c-0.05-0.13,0.05-0.21,0.07-0.31c0.15,0.08,0.02,0.17,0.1,0.27c-0.05-0.1,0.1-0.17,0.04-0.24
				c0.1,0.04,0.08,0.01,0.16,0.1c-0.06-0.16,0.05-0.06,0.06-0.18c0.06,0.07,0.22-0.09,0.18,0.16c-0.06-0.29,0.4-0.01,0.71-0.74
				c-0.03,0.01-0.02,0.04-0.03,0.07l0.04-0.05c-0.18,0.28,0.3,0.09,0.08,0.11c0.09,0.57,0.53-0.3,1.75-0.6
				c-0.3,0.07,0.26,0.09,1.58-0.32l-0.03,0.04c0.14,0.01,0.11-0.24,0.25-0.17l-0.02,0.02c0.11,0.17,0.84-0.19,2.4-0.24
				c0,0.01-0.01,0.02-0.02,0.03c0.03,0.02,0.01,0.15,0.1-0.03c0.07,0.06,0.22,0.05,0.33,0.02l-0.02,0.08
				c0.19,0.01,0.4-0.04,0.62-0.06c-0.02-0.04,0.03-0.29,0.05-0.07c0.03-0.07,0.06-0.25,0.03,0.04l0.1-0.04
				c0.01,0.03-0.02,0.05-0.02,0.1c0.47-0.03,0.03,0.07,1.35-0.09c-0.01,0.09,0.12,0.12,0.04,0.16c0.17,0.04,0.21,0.05,0.43,0.08
				c-0.02-0.08,0.05-0.13,0.03,0.02c0.14-0.02,0.11,0.16,0.45,0.52l-0.01,0c0.12,0.07,0.17,0.4,0.11,1.32
				c0.42-0.29-0.19,0.93-1.35,1.84c0.01-0.03-0.01-0.06,0.02-0.08c-0.09-0.04-0.22,0.1-0.2,0.16c-0.14,0.03,0.08-0.1-0.88,0.34
				c-0.02-0.15-0.23-0.08-0.3,0c0.02-0.05-0.07-0.04-0.05-0.11c-0.09,0.02-0.16,0.05-0.14,0.13c-0.29-0.14-0.15-0.07-1.19,0.14
				c0.23-0.34,0.56-0.11-0.95-0.09c0-0.01,0.03-0.03,0.05-0.04c-0.12,0.07,0.03-0.01-0.25,0.28c0.09-0.2-0.2-0.07-0.07-0.32
				c-0.03,0.01-0.07,0.04-0.06,0.09c-0.04-0.02-0.05-0.05,0-0.1c-0.15,0.02-0.11,0.11-0.24,0.09c0-0.03,0.03-0.04,0.05-0.08
				c-0.08,0.06-0.15,0.12-0.26,0.08c0.01-0.02,0-0.05,0.03-0.04c-0.2-0.01-0.35,0.14-0.56,0.05c0.08-0.14-0.07-0.06-0.06-0.19
				l-0.12,0.02l0.04,0.07c-0.1-0.02-0.2,0.07-0.28,0.01l0.08-0.04l-0.19,0l0.1,0.06c-0.21,0.07,0.01,0.23-0.44-0.13
				c-0.06,0.1-0.24,0-0.32,0.1c0.02-0.02,0.04-0.09,0.03-0.11l-0.05,0.08c-0.04-0.03-0.05-0.05-0.03-0.1
				c-0.14-0.04,0.02,0.17-0.14,0.08c0.01-0.01,0.01-0.02,0.04-0.03c-0.2-0.02-0.27-0.05-0.31-0.09l0.06-0.01l-0.05-0.06l-0.03,0.06
				c-0.15-0.18-0.11,0.21-1.46-0.17l0.04-0.04c-0.01,0.02-0.19-0.2-0.38-0.01c-0.23-0.32-0.58,0.03-0.78-0.24
				c0.08,0.02-0.14-0.16-0.28,0.14c0.11-0.03,0.03-0.13-0.01-0.18c0,0.03-0.02,0.05-0.02,0.07c-0.04-0.21-0.08,0.1-0.17-0.01
				c0.03-0.01-0.33,0.07-0.1-0.08c-0.09-0.01-0.21,0.13-0.27,0.02c0.04-0.02,0.1-0.01,0.14-0.02c-0.06-0.13-0.08-0.1-0.14-0.13
				l0.01,0l-0.14-0.08c-0.06,0.29,0,0.02-0.38,0.15c0.15-0.1,0.08-0.05,0.02-0.29c-0.09,0.07-0.1-0.09-0.23,0
				c0-0.02-0.02-0.03-0.03-0.05c0.01,0.06-0.28,0.03-1.12-0.1c0.03-0.05,0.02-0.07,0-0.07c0.19-0.23,0.44-0.59,0.83-1.28l-0.17,0.18
				c0.3-0.65,0.54-0.48,1.8-2.68c-0.05,0.19,0.15,0-0.04,0.35c0.39-0.82-0.2,0.21,3.01-4.23c0,0.02,0.01,0.02,0.01,0.03
				c0.68-1.01,0.03,0.03,1.23-1.48l-0.05,0.09c0.33-0.35,0.11-0.09,1.54-2.37c0.02,0.03,0.03,0.05,0.04,0.07
				c-0.01-0.11,0.07-0.32,0.55-0.57l-0.25,0.34c0.23-0.1,0.33-0.44,0.48-0.6l0.03,0.06c0.38-0.52,0.28-0.61,0.71-1.16l-0.08,0.03
				c0.47-0.87,0.48-0.11,1.26-1.73c-0.07,0.27-0.35,0.72-0.5,1.01c0.15-0.2,0.5-0.68,0.53-0.82c-0.05,0.09-0.1,0.21-0.18,0.27
				c1.1-1.77,0-0.1,3.48-4.4c-0.38,0.17,0.17-0.24-0.04-0.32c0.07-0.11-0.01,0,0.21-0.28c0,0,0,0-0.01,0.01
				c0.23-0.25,0.51-0.38,0.94-0.66c-0.1,0.01,0.02-0.21,0.21-0.44l-0.32,0.28c0.33-0.66,0.51-0.41,0.85-1.07l-0.08,0.23
				c0.41-0.5,0.52-0.81,2.42-3.74c0.1,0.08,0.08,0.06,0.35-0.23c0.14,0.02,0.31-0.21,0.38-0.07c0.01-0.05,0.11-0.2,0.19-0.25
				c-0.07,0.25,0.34-0.17,0.39,0.02c0.04-0.21,0.39-0.3,0.56-0.5c-0.01,0.02-0.02,0.05-0.04,0.06c0.13-0.05,0.18-0.03,0.28-0.04
				c-0.14,0.06,0.35-0.73,0.28-0.1c-0.06-0.09,0.21-0.23,0.11-0.29c0.15,0.01,0.12-0.01,0.23,0.06c-0.17-0.12,0.56-0.45,0.39-0.1
				c-0.05-0.27,0.72-0.34,1.26-1.07c0.47,0.56,0.04,0.12,1.62-0.59l-0.01,0.15c0.67-0.56,1.47-0.56,3.98-1.71l-0.04,0.05
				c0.23-0.04,0.17-0.29,0.4-0.28l-0.03,0.03c0.07,0.03,0.46-0.05,3.91-1.34c0,0.04-0.06,0.08-0.11,0.13
				c0.2-0.19,0.08,0.09,0.26-0.08c-0.04-0.01-0.01-0.07-0.01-0.09c0.13,0.02,0.37-0.06,0.54-0.15l-0.01,0.08
				c0.32-0.09,0.65-0.25,1-0.38c0.61-0.11-0.17,0.09,4.96-1.72l-0.02,0.02c0.96-0.35,1.96-0.6,5.55-1.97l-0.06,0.15
				c0.52-0.34,1.16-0.42,1.67-0.75c-0.02,0.04-0.01,0.07-0.07,0.09c0.15,0.03,0.43-0.16,0.41-0.23c0.26-0.07-0.01,0.13,1.58-0.7
				c0.05,0.15,0.41-0.03,0.51-0.15c-0.02,0.06,0.13,0,0.11,0.08c0.15-0.07,0.27-0.15,0.21-0.21c0.54-0.06,0.38-0.03,1.89-0.93
				c-0.26,0.49-0.68,0.49,1.5-0.66c0.01,0.01-0.02,0.05-0.05,0.07c0.14-0.15-0.02,0.03,0.21-0.44c-0.02,0.24,0.34-0.11,0.29,0.21
				c0.04-0.03,0.07-0.09,0.04-0.13c0.07-0.02,0.1,0,0.06,0.08c0.21-0.15,0.1-0.18,0.3-0.28c0.02,0.03-0.02,0.06-0.02,0.11
				c0.08-0.11,0.15-0.23,0.33-0.29c0,0.02,0.03,0.04-0.02,0.06c0.29-0.17,0.42-0.43,0.78-0.54c-0.02,0.18,0.13-0.01,0.21,0.09
				l0.16-0.13l-0.1-0.02c0.16-0.08,0.24-0.24,0.39-0.27l-0.09,0.11l0.27-0.18l-0.18,0.05c0.32-0.44-0.08-0.11,1.08-0.7
				c-0.05,0.14,0.29,0.09,0.3-0.15c-0.02,0.02,0,0.02-0.03,0.06c0.26-0.18,0.39-0.22,0.48-0.25l-0.06,0.06
				c0.34-0.17-0.02,0.04,1.14-0.78l-0.07,0.03c-0.06-0.18,0.28-0.08,0.39-0.25c0.15,0.13-0.01,0.09,0.7-0.49l-0.01,0.07
				c0.52-0.44,0.5-0.26,1.65-1.18c-0.06,0.11-0.11,0.16,0.23-0.18c-0.07-0.05-0.07-0.01-0.17-0.01c0.01-0.07,0.07-0.16,0.14-0.22
				c-0.1,0.15,0.08,0.1,0.17,0.09c-0.02-0.01-0.02-0.05-0.04-0.06c0.26,0.03-0.04-0.07,0.21-0.2c-0.03,0.05,0.3-0.43,0.18-0.07
				c0.12-0.1,0.12-0.32,0.29-0.34c-0.03,0.06-0.11,0.12-0.14,0.18c0.19-0.01,0.18-0.04,0.28-0.1l-0.01,0.01l0.23-0.13l-0.07,0.02
				c-0.02-0.31,0.26-0.29,0.35-0.58c-0.06,0.11,0.09,0.35,0.49-0.13c0.03,0.06,0.63-0.79,1.52-1.65c0.03,0.11,0.08,0.28,0.41-0.46
				l-0.09,0.05c0.19-0.02-0.16,0.35-0.07,0.09c-0.22-0.06-0.25,0.32-0.39,0.46c0.01-0.22-0.14-0.25-0.47,0.21
				c0-0.04-0.09-0.06-0.01-0.1c-0.24,0.11-0.21,0.36-0.36,0.35l0.01-0.02c-0.27,0.24-0.77,0.57-1.8,1.69
				c-0.02-0.04-0.37,0.21-0.56,0.3l-0.01-0.02c0.05,0.13-0.13,0.24-0.24,0.36c-0.04-0.06-0.04-0.11,0-0.19
				c0,0.03-0.06-0.03-0.2,0.26l-0.03-0.1c-0.15,0.12-0.23,0.34-0.48,0.43c0.04,0.08,0.31,0.08,0.13,0.29
				c-0.04-0.03-0.09-0.09-0.05-0.15c-0.01,0.02-0.04,0.08-0.09,0.09l0.04-0.13c-0.02,0.06-0.28,0.21-0.1,0.02
				c-0.09,0.05-0.22,0.09-0.28,0.07c-0.09,0.36,0-0.01-0.62,0.63l0,0c-0.21,0.28,0,0-0.85,0.56c-0.06-0.14-0.25,0.18-0.92,0.56
				c0.1-0.13-0.07-0.02,0.11-0.12c-0.18,0.08-0.25,0.05-0.38,0.24c0.01-0.11-0.18-0.02-0.25,0.05l0.15-0.02
				c-0.08,0.07-0.16,0.11-0.25,0.15l-0.02-0.13c-0.41,0.18-0.76,0.46-1.52,1.01c0.27,0.15-0.52-0.06-0.35,0.15
				c-0.23,0.07-0.32,0.17-0.56,0.25c0.08,0.02-0.48,0.63-0.5,0.18c-0.16,0.17-0.39,0.14-0.51,0.19l0.09-0.08
				c-0.05,0.05-0.1,0.08-0.15,0.1l0.1,0.04c-0.07,0.02-0.12,0.05-0.13,0.01c-0.07,0.15,0.16,0.25-0.29,0.31
				c0.26-0.09-0.05-0.09,0.12-0.2c-0.29,0.07-0.18-0.04-0.44,0.31c0-0.07-0.11-0.08-0.15-0.04c0.07-0.05,0.09,0.13-0.23,0.31
				l0.11-0.03c-0.11,0.26-0.46,0.13-1.21,0.37c0.1,0.05,0.1,0.02-0.02,0.17l0.32-0.21l-0.17,0.21c0.1-0.05,0.2-0.15,0.27-0.16
				c-0.08,0.11-0.07,0.09,0,0.13c-0.54,0.24-0.37-0.27-1.28,0.4l0.12-0.01c-0.01,0.09-0.19,0.1-0.29,0.18
				c-0.02-0.07-0.09-0.06-0.16-0.08c0.03,0.04-0.24,0.1-0.21,0.24c-0.34,0.01-0.2-0.09-0.67,0.38c0.05-0.1-0.09-0.1,0.06-0.22
				c-0.28,0.24,0.16-0.04-2.17,1.03c0-0.02-0.01-0.03,0.02-0.04c-0.05,0.03-0.08,0.04-0.11,0.06c-0.14-0.02,0.41-0.23,0.3-0.75
				c-0.02,0.02,0,0.04,0.01,0.07l0.01-0.06c0.52,0.71,0-0.01,1.16-1.14c-0.28,0.3,0.49-0.27,0.89-1.07l0.01,0.04
				c0.1-0.07-0.1-0.23,0.04-0.26l0,0.03c0.15-0.13,0.23,0.06,0.87-1.27l0.13,0.03c-0.1-0.23,0.11,0.13,0.2-0.45
				c0.01,0.01,0.01,0.02,0.02,0.03c0.03-0.01,0.14,0.06,0.03-0.09c0.08-0.01,0.15-0.12,0.18-0.22l0.05,0.05
				c0.1-0.14,0.16-0.33,0.26-0.5c-0.07,0.04-0.17-0.04-0.15-0.09l0.11,0.04c-0.05-0.06-0.19-0.17,0.04,0l0.01-0.1
				c0.03,0.01,0.04,0.04,0.07,0.07c-0.04-0.07,0.17-0.07,0.09-0.18c0.12-0.08,0.26-0.59,0.32-0.98c0.08,0.04,0.14-0.09,0.16,0.01
				c0.07-0.15,0.09-0.19,0.13-0.41c-0.07,0.01-0.13-0.06,0.02-0.03c0-0.27,0.49-0.35,0.42-0.61l0,0.01
				c0.04-0.31,0.07-0.17,0.74-1.15c-0.04-0.02-0.14-0.05,0.03-0.08c-0.51,0.13,0.55-0.62,1.03-2.07c0.01,0.03,0.04,0.05,0.03,0.07
				c0.1-0.01,0.12-0.19,0.06-0.23c-0.06,0.09,0.28-0.26,0.38-0.76c0.13,0.09,0.21-0.1,0.18-0.19c0.03,0.04,0.07-0.02,0.12,0.03
				c0.04-0.07,0.05-0.14-0.02-0.18c0.24-0.02,0.03-0.33,0.27-0.27c0.05-0.24,0.13-0.45,0.21-0.65c0.17,0.34-0.15,0.49,0.49-0.63
				c0.01,0.01,0.01,0.03,0.01,0.06c0.02-0.03,0.07-0.05,0.01-0.09l0,0.04c-0.12-0.02-0.1-0.24-0.14-0.28
				c0.13,0.17,0.14-0.11,0.3,0.12c0-0.03-0.01-0.07-0.05-0.09c0.25-0.15,0.09-0.01,0.41-0.71c0.09,0.14,0.08-0.02,0.18,0.06
				l0.03-0.1l-0.07-0.01c0.06-0.06,0.03-0.18,0.11-0.21l0,0.08l0.08-0.14l-0.1,0.04c0.03-0.19-0.19-0.11,0.29-0.25
				c-0.06-0.1,0.1-0.17,0.05-0.29c0.01,0.03,0.06,0.08,0.08,0.08l-0.04-0.08c0.12-0.02,0.06-0.08,0.31-0.24l-0.02,0.05l0.07,0
				l-0.04-0.06c0.21,0.01-0.1-0.25,0.78-0.94c-0.07,0.08,0.28,0.04,0.2-0.21c0.22-0.06,0.33,0.14,0.58-0.67
				c-0.03,0.09,0.08,0.1,0.14,0.11c-0.02-0.02-0.03-0.04-0.05-0.06c0.18,0.11-0.03-0.13,0.09-0.11c-0.01,0.02,0.11-0.27,0.1-0.02
				c0.15-0.16-0.02,0.01,0.35-0.13c-0.18-0.23-0.02-0.01,0.09-0.36c0,0.17-0.01,0.07,0.2,0.21c0-0.1,0.12,0,0.11-0.15
				c0.01,0.02,0.03,0.01,0.05,0.01c-0.04-0.04,0.12-0.2,0.67-0.66c0.06,0.2,0.13-0.22,0.15,0.1c0.04-0.06,0.06-0.15,0.11-0.21
				c0.05,0.04,0.01,0.1,0.01,0.14c0.06,0.07,0.27-0.42,1.18-0.43c0.02-0.09-0.13-0.03-0.02-0.12c0,0.23,0.26-0.19,0.27,0.11
				c0.08-0.25,0.05,0.16,0.6-0.28c0,0.06,0-0.04,0.45,0c-0.01-0.13,0.13-0.16,0.23-0.22c0.02,0.07,0.01,0.11-0.03,0.16
				c0.01-0.02,0.05,0.03,0.18-0.15l0.01,0.1c0.13-0.05,0.22-0.2,0.4-0.21c-0.02-0.09-0.22-0.18-0.07-0.3
				c0.02,0.04,0.06,0.12,0.02,0.15c0.01-0.01,0.04-0.06,0.07-0.05l-0.04,0.1c0.03-0.05,0.22-0.09,0.07,0.02
				c0.07-0.01,0.17-0.01,0.21,0.03c-0.09-0.25,0.22,0.13,0.5-0.38l0,0c0.08-0.2,0.61-0.11,1.29-0.66c-0.05,0.11,0.06,0.02-0.07,0.1
				c0.13-0.05,0.19-0.02,0.26-0.18c0.01,0.1,0.15,0.03,0.19-0.02l-0.12,0c0.38-0.15,0.12,0.08,0.78-0.46
				c-0.5-0.08,0.5-0.56,0.47-0.47l-0.02-0.04c0.08-0.02,0.2-0.16,0.22-0.08c0.04-0.1-0.02-0.08-0.09-0.04
				c0.16-0.09,0.18-0.2,0.32-0.31c-0.07,0.03,0.03-0.63,0.29-0.28c0.02-0.18,0.21-0.24,0.26-0.33l-0.01,0.1
				c0.01-0.06,0.03-0.1,0.05-0.14l-0.1,0.01c0.04-0.04,0.06-0.08,0.09-0.07c-0.02-0.11-0.29-0.09-0.02-0.34
				c-0.14,0.04,0.39,0.23,0.12-0.24c0.05,0.04,0.13-0.02,0.12-0.06c-0.01,0.07-0.14,0-0.13-0.3l-0.03,0.08
				c-0.05-0.04-0.04-0.1-0.05-0.2c-0.05,0.08,0.24-0.25,0.19-0.81c-0.07,0.08-0.04,0.08-0.16-0.03c0.04,0.09,0.09,0.18,0.12,0.29
				c-0.05-0.06-0.1-0.11-0.16-0.17c0.02,0.08,0.09,0.17,0.08,0.23c-0.08-0.08-0.06-0.07-0.12-0.02c-0.12-0.45,0.42-0.29-0.23-1
				l0.02,0.09c-0.09,0.01-0.1-0.14-0.19-0.18c0.06-0.04,0.04-0.1,0.05-0.16c-0.03,0.04-0.14-0.16-0.26-0.08
				c-0.11-0.25,0.01-0.22-0.52-0.27c0.09-0.04,0.02-0.13,0.18-0.1c-0.07-0.04-0.12-0.08-0.14-0.04c0.14,0.02-0.28-0.17-0.48,0.06
				c-0.1-0.15-0.36-0.29-1.22,0.06c0-0.01,0-0.02,0-0.02c-0.3,0.22-0.04-0.04-0.61,0.25l0.02-0.03c-0.13-0.01-0.17,0.21-0.31,0.3
				c0,0-0.01-0.02-0.02-0.03c-0.03,0.16-0.18,0.32-0.28,0.44c-0.02-0.03-0.03-0.05-0.04-0.06c0.02,0.07,0.01,0.17-0.25,0.09
				c0.15-0.22-0.44-0.01-0.8,0.91c-0.01-0.13,0.07-0.28,0.11-0.4c-0.06,0.05-0.19,0.19-0.18,0.27c0.01-0.03,0.02-0.09,0.06-0.09
				c0.01,0.14-0.66,0.18-0.94,1.15c0,0,0-0.01,0-0.01c0,0,0,0,0,0.01c0.1-0.28,0-0.01,0.22-0.58c-0.08,0.23-0.05,0.13-0.21,0.54
				c0.03-0.11,0.13-0.48,0.36-0.96c-0.07,0.12-0.08,0.19-0.04,0.06c-0.04,0.03,0-0.01-0.17,0.02c0.02-0.02,0.08-0.04,0.1-0.03
				c0.02-0.13-0.02-0.33-0.19-1.09l0.07,0.05c-0.42-0.82,0.28-0.67-0.99-2.85c0,0.04-0.17,0.1-0.28-0.1
				c0.09,0.04-0.19-0.46-0.4-0.12c0.21,0.09-0.8-0.35-1.98,0.3l0-0.09c-0.16,0.54-0.13,0.18-0.6,1.14
				c-0.02-0.01-0.06-0.03-0.05-0.08c-0.06,0.12-0.25,0.46-0.03,0.46c-0.13-0.03-0.07,0.27-0.03,0.41l-0.09-0.03
				c0.03,0.01,0.07,0.16,0.14,0.53l-0.06-0.06c-0.01,0.2,0.24,0.12-0.02,0.45c-0.01-0.06,0.16,0.2,0.04,0.13
				c-0.12,0.2,0.11,0.02-0.03,0.48c-0.01-0.17,0-0.3,0.01-0.29c-0.09,0-0.1,0.14-0.23,0.03c-0.05,0.09,0.25,0.22,0.12,0.85
				c0.1-0.01,0.09,0.08,0.16-0.01c-0.01,0.02,0-0.03-0.03-0.08l0.01,0c-0.01-0.04-0.01-0.1-0.02-0.17c0.05,0.05,0.1,0.1,0.05,0.18
				c0.07,0.03,0.13,0.07,0.17,0.08c-0.26,0.23-0.24,0.22-0.05,1c-0.02-0.03-0.03-0.07-0.05-0.09c0.06,0.11,0.07,0.34,0.2,0.35
				c-0.03,0.15-0.13-0.06-0.08,0.13l-0.04-0.02c-0.14,0.43,0.44,0.57,0.28,1.06c0.01-0.03,0.01-0.05,0.03-0.06
				c0.04,0.62-0.07-0.04,0.97,3.14c0.01-0.01,0.01-0.03,0.02-0.04c0.02,0.09,0.12-0.03,0.19,0.34l-0.22-0.17l0.05,0.1
				c-0.02,0.01-0.05,0.02-0.07-0.02c-0.01,0.1,0.04,0.03,0.04,0.11c-0.13-0.04-0.17,0.17-0.28,0.05c0.02,0.08,0.1,0,0.06,0.1
				c-0.09-0.2-0.12,0.22-0.24-0.05c0.04,0.24-0.25-0.08-0.33,0.36c-0.03-0.06,0.01,0.04-0.33,0.09c0.07,0.11-0.03,0.16-0.07,0.23
				c-0.05-0.06-0.06-0.09-0.05-0.15c0,0.02-0.06,0-0.07,0.16l-0.05-0.09c-0.07,0.07-0.08,0.21-0.22,0.25
				c0.05,0.07,0.24,0.12,0.18,0.25c-0.04-0.03-0.09-0.09-0.08-0.13c-0.01,0-0.04,0.14-0.05-0.04c0,0.05-0.12,0.13-0.06,0
				c-0.05,0.03-0.12,0.04-0.17,0.01c0.02,0.04,0.08,0.2-0.11,0.08c0.02,0.05-0.06,0.06,0,0.13c-0.02,0.02-0.06-0.01-0.09-0.03
				c0.01,0.07,0,0-0.04,0.22l0,0c0.01,0.18-0.41,0.2-0.77,0.71c0.01-0.1-0.06-0.02,0.02-0.09c-0.09,0.05-0.14,0.03-0.14,0.17
				c-0.04-0.09-0.13-0.02-0.14,0.03l0.09-0.01c-0.26,0.15-0.1-0.04-0.51,0.4c0.44,0.07-0.3,0.47-0.28,0.38l0.02,0.04
				c-0.06,0.01-0.14,0.11-0.17,0.04c-0.01,0.08,0.03,0.07,0.08,0.04c-0.12,0.05-0.12,0.14-0.23,0.21c0.07-0.01,0.02,0.51-0.22,0.17
				c0,0.14-0.17,0.13-0.22,0.18l0.01-0.07c-0.01,0.04-0.02,0.06-0.04,0.09l0.09,0.02c-0.15,0.07-0.1-0.02-0.08,0.27
				c0.14-0.14-0.32-0.27-0.13,0.12c-0.04-0.05-0.11-0.04-0.12-0.02c0-0.04,0.14,0.04,0.05,0.25c0.06-0.01,0.09-0.06,0.04,0.12
				c-0.04-0.02,0.01-0.07-0.02-0.06c0.11,0.23-0.64,0.06-0.38,0.44l0.06-0.19l0.03,0.17c0.03-0.05,0.02-0.13,0.06-0.15
				c0.02,0.09,0.01,0.07,0.08,0.09c-0.16,0.36-0.29-0.24-0.51,0.46c0.07-0.01,0.12-0.05,0.01,0.14c-0.05-0.04-0.1-0.03-0.15-0.03
				c0.04,0.03-0.07,0.11,0.03,0.2c-0.17,0.06-0.19-0.03-0.13,0.37c-0.04-0.08-0.12-0.05-0.11-0.16c-0.03,0.15,0-0.04,0.01,0.44
				c-0.19,0.01-0.22,0.05-0.53,0.65c-0.01-0.02-0.03-0.02-0.01-0.03c-0.09,0.32-0.02-0.02-0.3,0.36l0-0.03
				c-0.1,0.02-0.02,0.2-0.09,0.29c0,0-0.01-0.02-0.03-0.02c0.06,0.13,0.02,0.28-0.01,0.4c-0.16-0.12-0.09-0.1-0.22,0.08
				c-0.17-0.51,0.08,0.66-0.28,0.48c0,0.07,0.02,0.24-0.01,0.35c-0.06-0.1-0.05-0.23-0.07-0.33c-0.03,0.05-0.08,0.18-0.04,0.23
				c0.04-0.07,0.04-0.09,0.02,0.24c-0.23-0.15-0.2,0.23-0.42,0.2c-0.01,0.22-0.23,0.33-0.33,0.54c0.08-0.05,0.22,0.32-0.06,0.11
				c0.09-0.02,0.07,0.09,0.12,0.11c-0.08,0.04-0.2,0.07-0.38,0.04c0.07,0.02,0.08,0.09,0.02,0.13l0.13-0.04
				c0.02,0.2-0.23,0.04-0.21,0.24l-0.03-0.08c-0.01,0.09-0.15,0.28-0.04,0.44c-0.03,0-0.09-0.01-0.09-0.05
				c-0.14,0.08-0.1,0.23-0.36,0.8l-0.03-0.07c-0.09,0.63-0.33,0.2-1.03,1.7c0.05,0.23-0.06,0.11-0.53,1.11
				c-0.16-0.03-0.07-0.08-0.31,0.26l-0.04-0.07c0.03,0.35,0.13,0.14-0.3,0.71c-0.02-0.01-0.05-0.04-0.04-0.07
				c-0.05,0.06-0.26,0.2-0.08,0.29c-0.11-0.08-0.11,0.14-0.12,0.25l-0.07-0.07c0.02,0.01,0.02,0.11-0.07,0.39l-0.03-0.07
				c-0.09,0.1,0.15,0.21-0.2,0.24c0,0.02,0.01-0.01-0.27,0.34c0.08-0.09,0.14-0.16,0.14-0.15c-0.07-0.17-0.45,0.12-0.38,0.48
				c-0.01,0.01,0.01-0.02,0.02-0.06l0,0.01c0.02-0.03,0.04-0.06,0.07-0.1c0.02,0.06,0.02,0.12-0.05,0.12
				c0.03,0.06,0.06,0.12,0.08,0.15c-0.07,0.12-0.51,0.28-1.26,1.2c0.03,0,0.04-0.01,0.06,0.01c-0.21,0.17-0.26-0.03-0.9,0.87
				l-0.08-0.07c0.07,0.1-0.54,0.7-1.01,0.67l0.18-0.07c-0.14-0.08-0.24,0.09-0.34,0.11l-0.01-0.06c-0.27,0.12-0.23,0.25-0.54,0.35
				l0.05,0.03c-0.38,0.26-0.21-0.1-0.92,0.38c0.08-0.14,0.29-0.27,0.41-0.37c-0.11,0.04-0.36,0.14-0.4,0.22
				c0.04-0.03,0.08-0.08,0.13-0.07c-0.47,0.19-1,0.62-3.28,0.82c0.06,0.06-0.04,0.13-0.18,0.15l0.21,0.03
				c-0.28,0.22-0.33-0.08-0.62,0.14l0.08-0.1c-0.22,0.02-0.25,0.14-1.91,0.8l0.01-0.06c-0.2,0.14-0.12-0.03-0.5,0.22l0.06-0.08
				c-0.41,0.06-0.55,0.33-0.86,0.48c-0.05-0.15-0.37-0.09-0.53-0.07l0-0.02c-0.65,0.1,0.1,0.31-2.46,0.86
				c0.03,0.02,0.01,0.2-0.27,0.2c0.06-0.01-0.44-0.18-0.43,0.23c-0.22-0.12-0.59,0.26-2.4,0.68l0.04-0.09
				c-0.5,0.45-0.18,0.21-1.4,0.68c0-0.02-0.01-0.07,0.04-0.09c-0.16,0.06-0.63,0.13-0.51,0.3c-0.05-0.12-0.34,0.12-0.5,0.24
				l-0.01-0.1c0.01,0.03-0.17,0.17-0.6,0.43l0.05-0.09c-0.27,0.1-0.07,0.29-0.6,0.22c0.08-0.04-0.2,0.24-0.16,0.1
				c-0.33,0,0.04,0.1-0.66,0.23c0.22-0.09,0.4-0.16,0.39-0.15c-0.03-0.08-0.22-0.02-0.12-0.2c-0.15,0.01-0.2,0.35-1.1,0.54
				c0.05,0.09-0.08,0.12,0.06,0.15c-0.03,0,0.04-0.02,0.1-0.07l0,0.01c0.06-0.03,0.13-0.06,0.22-0.1c0,0.11-0.21-0.05-0.29,0.34
				c-0.41-0.15-0.37-0.1-1.38,0.44c0.17-0.18,0.18-0.14-0.53,0.24l0.02-0.05c-0.61,0.06-0.7,0.7-1.39,0.74
				c0.23,0.11,0.09-0.03-2.62,1.51c0.42-0.6,0.53-1.09,0.86-1.66c0.13,0.08,0.54-0.47,0.75-0.75l0.01,0.02
				c0.72-1.43,2.48-3.94,5.12-9.78l0.09,0.05c0.27-0.56,0.44-0.83,0.7-1.56l0.01,0.17c0.32-1.43,0.16-0.65,1.09-3.17
				c0.02,0.02,0.05,0.05,0.01,0.17c0.13-0.33,0.57-1.27,0.33-1.21c0.14,0.04,0.25-0.74,0.33-1.15l0.07,0.09
				c-0.03-0.05,0.07-0.52,0.3-1.53l0.01,0.18c0.08-0.41,0.37-1.17,0.85-3.17c-0.12,0.51-0.21,0.92-0.22,0.88
				c0.09,0.02,0.2-0.39,0.25-0.03c0.05-0.35-0.07,0,0.17-1.38l0,0.04c0.07-0.4,0.11-0.39,0.19-0.69c-0.01,0.03-0.03,0.04-0.04,0.05
				c0-0.07,0.02,0,0.03-0.26c0.03,0.07,0.05,0.06,0,0.2c0.03-0.12,0.08-0.29,0.14-0.56c-0.1,0-0.04-0.27-0.16-0.03
				c0.01-0.05,0,0.05-0.02,0.18c0-0.02,0-0.03,0-0.06c0.01,0.04-0.06,0-0.02,0.13l0.01-0.07c0,0.03-0.01,0.06-0.01,0.09l-0.01-0.02
				c-0.03,0.14-0.07,0.31-0.11,0.51c0.04-1.46,0.41-0.96,0.62-3.92c0.01,0.11-0.01,0.23,0.01,0.29c0.04-0.02-0.12-0.97,0.14-1.54
				l0.03,0.09c0.29-1.23-0.14-2.03,0.13-3.39c-0.02,0.1-0.03,0.14-0.05,0.16c-0.03-1.9,0.44,0.59-0.6-8.01l-0.05,0.12
				c-0.04-0.29-0.21-0.91-0.12-0.88c-0.15-0.11-0.23-1.19-0.36-1.76c0.17,0.04,0.35,0.21,0.53,0.27c-0.02,0-0.05,0.01-0.06-0.01
				c0.09,0.09,0.09,0.15,0.14,0.23c-0.1-0.1,0.78,0.01,0.19,0.2c0.06-0.09,0.28,0.09,0.3-0.02c0.04,0.14,0.05,0.1,0.02,0.22
				c0.05-0.2,0.58,0.34,0.21,0.31c0.2-0.14,0.52,0.54,1.33,0.8c-0.43,0.58-0.11,0.07,0.86,1.38l-0.15,0.02
				c0.67,0.53,0.61,1.37,2.13,3.58l-0.06-0.03c0.09,0.22,0.32,0.1,0.36,0.32l-0.04-0.02c0.06,0.18,0.5,0.2,2.02,2.08
				c0.03-0.04,0.15,0,0.38,0.19c0.02-0.22,0.02,0.28,0.85,0.26c-0.02,0.04-0.1,0.03-0.17,0.04c0.28-0.03,0.02,0.12,0.28,0.09
				c-0.03-0.03,0.03-0.06,0.04-0.08c0.1,0.09,0.38,0.14,0.6,0.12l-0.05,0.07c0.37,0.04,0.84-0.06,1.25-0.31
				c0.5-0.52,0.08,0.08,0.83-2.39c0.53,0.26-0.01-0.02,1.54,0.55l-0.02,0.01c1.08-0.12,0.07,0.29,2.83-0.22
				c-0.02-0.08-0.06-0.1,0.15-0.07c-0.02-0.51,0.82,0.12,2.47-1.73l0,0.16c0.34-0.53,0.86-0.97,1.02-1.6
				c0.01,0.04,0.04,0.06,0.01,0.12c0.25-0.83,0.07-0.11,0.2-2.3c0.16-0.01,0.02-0.44-0.11-0.55c0.06,0.03,0-0.14,0.08-0.11
				c-0.06-0.16-0.16-0.29-0.22-0.23c-0.16-0.5,0.02-0.53-1.42-1.61c0.53,0.11,0.8,0.2-1.38-0.89c0.01-0.01,0.05-0.01,0.09-0.01
				c-0.21-0.01,0.03-0.01-0.47,0.12c0.19-0.15-0.3-0.18-0.03-0.35c-0.05,0-0.11,0.01-0.12,0.05c-0.06-0.04-0.07-0.07,0.02-0.1
				c-0.25-0.05-0.19,0.05-0.4-0.03c0.01-0.03,0.06-0.03,0.1-0.06c-0.13,0.03-0.26,0.05-0.43-0.03c0.02-0.02,0-0.05,0.06-0.03
				c-0.32-0.09-0.58,0.01-0.91-0.14c0.14-0.12-0.1-0.08-0.09-0.21l-0.2-0.01l0.06,0.08c-0.16-0.04-0.33,0.02-0.46-0.06l0.13-0.02
				l-0.31-0.05l0.16,0.09c-0.51,0.11-0.01,0.13-1.22-0.18c0.13-0.07-0.14-0.26-0.31-0.09c0.02,0,0.01-0.02,0.06-0.02
				c-0.3-0.04-0.42-0.09-0.5-0.14l0.09,0c-0.2-0.13-0.04,0.02-1.32-0.17l0.06,0.02c-0.08,0.18-0.25-0.13-0.44-0.07
				c-0.02-0.2,0.06-0.06-0.81-0.09l0.05-0.04c-0.64-0.02-0.53-0.16-1.93-0.22c0.11-0.04,0.18-0.05-0.28-0.02
				c0.02,0.08,0.04,0.05,0.11,0.12c-0.05,0.04-0.15,0.07-0.24,0.07c0.16-0.04,0.01-0.13-0.06-0.18c0.01,0.03-0.02,0.05-0.01,0.08
				c-0.16-0.2-0.02,0.08-0.27,0c0.06-0.02-0.49,0.12-0.17-0.07c-0.15,0-0.3,0.16-0.42,0.06c0.06-0.02,0.15-0.02,0.21-0.04
				c-0.14-0.12-0.15-0.09-0.26-0.12l0.01,0l-0.24-0.06l0.06,0.03c-0.19,0.24-0.37,0.03-0.62,0.18c0.12-0.04,0.16-0.32-0.42-0.24
				c0-0.04-0.01,0.01-0.08-0.08c0,0-0.01,0-0.01,0c0,0-0.01,0,0-0.01c0,0,0,0,0,0.01c-0.01,0-0.04-0.01-0.07,0.06
				c0.53-0.09-0.47,0.13-1.73,0.14c0.13-0.03,0.28-0.07,0.46-0.11c-0.01,0.05,0.52-0.02,0.77-0.02l0,0.02
				c0.03-0.13,0.29-0.16,0.47-0.22c0.01,0.08-0.02,0.11-0.12,0.17c0.02-0.03,0.04,0.05,0.37-0.15l-0.03,0.1
				c0.08-0.04,0.4,0.01,0.9-0.41l-0.11,0.11c0.05-0.04,0.41-0.09,0.12,0.02c0.56-0.04,0.23-0.02,1.35-0.33l0,0
				c0.22-0.2,1.07-0.09,2.48-0.56c-0.16,0.11,0.08,0.04-0.17,0.09c0.24-0.03,0.3,0.01,0.53-0.15c-0.06,0.11,0.2,0.06,0.3,0.01
				l-0.18-0.02c0.12-0.05,0.23-0.07,0.34-0.09l-0.03,0.13c0.51-0.09,1.07-0.28,2.12-0.74c-0.3-0.19,0.63,0.08,0.45-0.11
				c0.29-0.04,0.43-0.15,0.72-0.22c-0.07-0.03,0.69-0.63,0.63-0.17c0.22-0.18,0.49-0.16,0.64-0.21l-0.12,0.09
				c0.07-0.05,0.14-0.08,0.2-0.11l-0.11-0.04c0.08-0.02,0.15-0.05,0.16-0.02c0.09-0.18-0.14-0.25,0.38-0.35
				c-0.2-0.01,0.1,0.44,0.4-0.17c-0.01,0.08,0.13,0.06,0.17,0.02c-0.1,0.06-0.07-0.13,0.28-0.37l-0.13,0.05
				c0.11-0.3,0.58-0.21,1.4-0.75c-0.12-0.01-0.12,0.01-0.01-0.18c-0.11,0.12-0.22,0.23-0.34,0.34c0.06-0.09,0.12-0.19,0.17-0.28
				c-0.11,0.09-0.21,0.23-0.28,0.27c0.08-0.14,0.08-0.12-0.02-0.13c0.57-0.43,0.53,0.14,1.35-0.98l-0.13,0.08
				c0.46-0.62,0.31-0.13,1.01-1.58c0.01,0.13,0.14,0.01,0.07,0.24c0.11-0.47-0.07,0.18,0.8-2.92c0.01,0.01,0.02,0.01,0.02,0.01
				c-0.07-0.71,0.1,0,0.03-1.14l0.02,0.06c-0.07-0.25,0.14-0.2-0.74-1.5c0.03-0.01,0.04-0.01,0.06-0.02
				c-0.08-0.01-0.19-0.1-0.19-0.45c0.06,0.07,0.11,0.14,0.16,0.21c0.03-0.18-0.21-0.28-0.29-0.39l0.05-0.02
				c-0.33-0.28-0.42-0.19-0.82-0.35l0.03,0.05c-0.61,0.07-0.2-0.47-1.26,0.05c0.12-0.13,0.42-0.24,0.63-0.3
				c-0.16,0-0.53,0.07-0.58,0.15c0.05-0.03,0.12-0.07,0.19-0.06c-1.11,0.47-0.05,0.05-3.07,0.81c0.15,0.09-0.01,0.23-0.13,0.27
				c0.03-0.06,0.11-0.2,0.05-0.23c0.06,0.06-0.12,0.17-0.07,0.23c-0.09,0.02-0.15-0.03,0-0.22c-0.05,0,0.05-0.12,0.11-0.2
				c-0.09,0.12-0.16,0.18-0.14,0.14c-0.09,0.16-0.29,0.17,0.03-0.05c-0.09,0.06,0,0.02-0.27,0.11c0.29-0.13,0.05-0.24,0.48-0.81
				l0.03,0.06c0.07-0.27,0.12-0.09,0.26-0.63l0.01,0.12c0.28-0.43,0.19-0.76,0.32-1.17c0.14,0.06,0.35-0.32,0.4-0.58l0.02,0.01
				c-0.47,0.98,0.53-3.85-5.58-0.24l-0.05-0.08c-0.3,0.2-0.45,0.3-0.78,0.59l0.02-0.11c-0.43,0.71-0.18,0.31-1.31,1.41
				c-0.01-0.02-0.03-0.06,0.01-0.11c-0.15,0.14-0.63,0.49-0.43,0.58c-0.1-0.09-0.31,0.32-0.42,0.53l-0.05-0.09
				c0.02,0.03-0.13,0.27-0.45,0.77l0.02-0.11c-0.23,0.27,0.05,0.32-0.51,0.59c0.06-0.09-0.09,0.34-0.12,0.2
				c-0.33,0.21,0.09,0.06-0.56,0.63c0.18-0.23,0.34-0.41,0.33-0.39c-0.07-0.06-0.23,0.12-0.21-0.11c-0.1,0.17,0.06,0.03-0.38,0.7
				l0.01-0.02c-0.18,0.26-0.15,0.11-0.49,0.54c0.08,0.05-0.02,0.17,0.13,0.1c-0.03,0.02,0.03-0.04,0.07-0.14l0,0.01
				c0.05-0.06,0.11-0.14,0.17-0.24c-0.29,0.79-0.46,0.31-1.32,1.8c0.09-0.28,0.12-0.24-0.42,0.56l0-0.06
				c-0.58,0.43-0.42,1.1-1.1,1.54c0.05-0.03,0.07-0.05,0.09-0.04c-0.28,0.08-0.75,0.9-1.57,1.89c-0.04-0.23-0.04-0.01,0.28-0.59
				c0.02,0.04,0.4-0.45,0.6-0.67l0.01,0.02c-0.07-0.12,0.11-0.36,0.21-0.56c0.06,0.05,0.06,0.1,0.02,0.22
				c0-0.04,0.07,0.01,0.19-0.42c0.17,0.35,0.02-0.16,0.46-0.97l-0.01,0.17c0.01-0.07,0.26-0.42,0.1-0.09
				c0.41-0.53,0.18-0.22,0.8-1.4l0,0c0.02-0.33,0.76-1,1.43-2.6c-0.04,0.22,0.08-0.04-0.06,0.22c0.15-0.24,0.23-0.27,0.26-0.58
				c0.04,0.13,0.19-0.14,0.22-0.28l-0.14,0.15c0.05-0.14,0.11-0.26,0.17-0.37l0.08,0.11c0.28-0.53,0.53-1.17,0.86-2.47
				c-0.34,0.16,0.48-0.55,0.21-0.5c0.16-0.3,0.16-0.5,0.29-0.82c-0.07,0.03-0.06-1.05,0.26-0.71c0-0.33,0.19-0.56,0.24-0.74l0,0.17
				c0-0.1,0.02-0.18,0.03-0.27l-0.1,0.08c0.03-0.09,0.05-0.17,0.08-0.17c-0.1-0.22-0.27-0.04-0.04-0.58
				c-0.1,0.38,0.1,0.01,0.07,0.29c0-0.06,0.02-0.19,0.07-0.17c0.16-0.37-0.09-0.2-0.03-0.6c0.05,0.06,0.13-0.08,0.13-0.14
				c-0.02,0.13-0.15-0.04-0.12-0.51l-0.04,0.15c-0.16-0.3,0.2-0.67,0.33-1.79c-0.09,0.09-0.07,0.11-0.14-0.13l0.04,0.55l-0.11-0.36
				c-0.01,0.16,0.04,0.35,0.02,0.45c-0.05-0.17-0.04-0.15-0.11-0.08c0.06-0.8,0.44-0.38,0.18-1.9l-0.03,0.16
				c-0.11-0.89,0.11-0.35-0.32-2.12c0.09,0.11,0.11-0.08,0.21,0.18c-0.54-1.04,0.05-0.01-2.2-2.67c0.01-0.01,0.01-0.01,0.02-0.02
				c-0.82-0.06,0.01-0.13-1.32-0.21l0.07-0.02c-0.3,0.05-0.18-0.17-1.83,0.58c0-0.03,0-0.05-0.01-0.07
				c-0.03,0.08-0.15,0.19-0.53,0.11c0.08-0.05,0.17-0.08,0.26-0.12c-0.19-0.07-0.34,0.15-0.48,0.2l-0.01-0.06
				c-0.39,0.21-0.32,0.35-0.72,0.59l0.06,0.01c-0.43,0.54-0.41-0.15-1.05,0.96c0.04-0.2,0.26-0.48,0.39-0.67
				c-0.13,0.11-0.43,0.39-0.43,0.51c0.04-0.06,0.07-0.14,0.13-0.18c-0.71,1.02-0.36-0.12-1.62,2.75c-0.01-0.15-0.02-0.3-0.03-0.34
				c-0.46,0.59,0.84,0.02-1.44-2.29l0.07,0c-0.2-0.1-0.04-0.12-0.72-0.49l0.15,0.03c-0.53-0.4-0.95-0.31-1.45-0.36
				c0.03-0.15-0.47-0.28-0.73-0.27l0.01-0.02c0.93,1.02-3.21-1.61-1.92,6.51l-0.1,0.01c0.07,0.41,0.1,0.61,0.25,1.1l-0.09-0.08
				c0.48,0.82,0.22,0.35,0.74,2.07c-0.02,0-0.07,0-0.1-0.08c0.06,0.23,0.17,0.89,0.33,0.75c-0.12,0.04,0.14,0.49,0.27,0.73
				l-0.11-0.01c0.04,0.01,0.19,0.29,0.46,0.91l-0.09-0.09c0.11,0.39,0.3,0.16,0.24,0.86c-0.04-0.11,0.24,0.31,0.11,0.24
				c0,0.36,0.07,0.12,0.15,0.53c-0.18-0.42-0.45-0.91-0.95-1.68c-0.13,0.33-0.03-0.64-0.19-0.43c-0.09-0.29-0.22-0.4-0.34-0.68
				c-0.02,0.08-0.74-0.58-0.28-0.6c-0.21-0.19-0.22-0.47-0.3-0.61l0.11,0.1c-0.06-0.06-0.1-0.12-0.14-0.18l-0.02,0.12
				c-0.03-0.08-0.07-0.14-0.04-0.16c-0.2-0.07-0.23,0.17-0.4-0.33c0.17,0.31,0.08-0.06,0.24,0.13c-0.04-0.04-0.11-0.13-0.06-0.15
				c-0.12-0.34-0.19-0.06-0.4-0.35c0.08,0,0.05-0.14,0-0.18c0.07,0.09-0.13,0.09-0.41-0.24l0.07,0.12
				c-0.31-0.07-0.28-0.55-0.92-1.32c0,0.12,0.03,0.11-0.18,0.03c0.13,0.1,0.26,0.2,0.38,0.3l-0.3-0.14c0.1,0.1,0.25,0.18,0.3,0.26
				c-0.15-0.06-0.13-0.06-0.13,0.03c-0.5-0.52,0.08-0.55-1.14-1.24l0.1,0.12c-0.69-0.41-0.16-0.3-1.7-0.85
				c0.13-0.03,0-0.14,0.24-0.09c-0.5-0.05,0.18,0.04-3.04-0.42c0.01-0.01,0.01-0.02,0.01-0.02c-0.7,0.19-0.01-0.11-1.15,0.16
				l0.05-0.03c-0.2,0.14-0.33-0.19-1.31,1.11c-0.02-0.02-0.03-0.03-0.04-0.04c0.01,0.08-0.02,0.22-0.32,0.41
				c0.03-0.09,0.06-0.17,0.11-0.26c-0.18,0.08-0.14,0.34-0.19,0.47l-0.05-0.03c-0.07,0.42,0.08,0.43,0.06,0.84l0.04-0.04
				c0.19,0.58-0.35,0.21,0.15,1.23c-0.13-0.13-0.22-0.43-0.3-0.62c0.01,0.15,0.07,0.5,0.15,0.57c-0.02-0.06-0.07-0.12-0.06-0.19
				c0.13,0.16,0.22,0.38,0.29,0.66c-0.36-0.24,0.21,1.64,0.75,2.42c0.04-0.17,0.24-0.03,0.31,0.09c-0.07-0.01-0.23-0.07-0.24,0
				c0.05-0.07,0.2,0.08,0.25,0.02c0.04,0.08,0.01,0.15-0.21,0.05c0.02,0.05-0.13-0.02-0.23-0.06c0.14,0.06,0.23,0.11,0.18,0.1
				c0.16,0.02,0.03,0.1-0.11-0.04c0.02,0.04-0.01-0.01-0.09,0.16c-0.04-0.03-0.08-0.09,0.01-0.1c-0.52-0.06-1.17,0.16-1.58,0.09
				l0.04-0.05c-0.27,0.09-0.15-0.05-0.69,0.11l0.1-0.07c-0.52-0.01-0.77,0.25-1.18,0.36c-0.03-0.15-0.45-0.12-0.69-0.04l0-0.02
				c0.64,0.62-3.42,1.54,2.34,6.46l-0.12,0.01c0.84,0.17,0.35,0.1,1.84,0.72c-0.01,0.02-0.04,0.05-0.11,0.03
				c0.19,0.08,0.72,0.38,0.72,0.16c-0.04,0.13,0.42,0.16,0.66,0.18l-0.07,0.08c0.02-0.03,0.3,0,0.9,0.1l-0.11,0.03
				c0.34,0.11,0.27-0.17,0.75,0.24c-0.11-0.02,0.36-0.05,0.23,0.03c0.32,0.23,0.02-0.11,0.8,0.29c-0.28-0.09-0.5-0.17-0.48-0.17
				c-0.03,0.09,0.2,0.17-0.02,0.23c0.2,0.03,0.01-0.07,0.78,0.1l-0.03,0c0.31,0.08,0.15,0.1,0.66,0.29c0.02-0.1,0.16-0.03,0.06-0.15
				c0.03,0.02-0.05-0.02-0.15-0.03l0.01-0.01c-0.07-0.02-0.17-0.06-0.28-0.09c0.82,0.04,0.45,0.29,2.07,0.81
				c-0.29-0.03-0.26-0.07,0.62,0.32l-0.06,0.01c0.49,0.51,1.15,0.29,1.64,0.95c-0.03-0.04-0.05-0.06-0.05-0.09
				c0.13,0.38,1.91,1.19,3.25,3.1l0.02-0.09c0.37,0.36-0.11,0.12,1.32,0.94c0.01,0.04,0.02,0.07,0.04,0.08l0.06-0.16
				c0.02,0.06-0.02,0.13,0.03,0.16c0.03-0.08-0.05-0.14,0-0.19c0.04,0.07,0.04,0.13,0.08,0.06c0.01,0.04,0,0-0.01,0.09c0,0,0,0,0,0
				c0.05,0.04-0.09,0.05,0.11-0.12c0.05,0.09,0.12-0.04,0.14,0.11c0.01-0.04,0.05-0.13,0.08-0.14c-0.04,0.19,0.13-0.01,0.14,0.19
				c0.02-0.17,0.16-0.1,0.23-0.2c0,0.01-0.01,0.04-0.02,0.03c0.05,0.01,0.07,0.05,0.11,0.08c-0.02-0.14,0.05-0.18,0.07-0.27
				c0.08,0.12-0.01,0.17,0.03,0.29c-0.02-0.11,0.08-0.13,0.04-0.22c0.06,0.07,0.04,0.03,0.09,0.14c-0.02-0.17,0.04-0.04,0.05-0.15
				c0.08,0.07,0.05,0.15,0.11,0.09c0.08,0.26-0.02-0.07,0.43-0.21c0.02-0.01-0.12-0.17,0.02-0.18c-0.02,0-0.01,0.04-0.02,0.06
				l0.02-0.04c-0.1,0.19,0.17,0.17,0.05,0.12c0.12,0.15-0.04,0.25,0.26,0.11c-0.17-0.28,0.38,0.15,0.8-0.39
				c0.23,0.06,0.33,0.37,2.01-0.72l0.08,0.1c0.04-0.24-0.03,0.19,0.28-0.2c0.01,0.04,0,0.07-0.01,0.1
				c-0.01-0.18,0.12,0.12,0.06-0.11c0.06,0.04,0.14,0.01,0.19-0.05l0.02,0.07c0.12-0.03,0.21-0.14,0.33-0.2
				c-0.06-0.01-0.1-0.13-0.08-0.16l0.06,0.1c-0.01-0.07-0.07-0.24,0.03,0.03l0.04-0.06c0.02,0.03,0.01,0.05,0.03,0.09
				c-0.01-0.07,0.14,0.05,0.12-0.07c-0.25,0.41,1.12-0.86,2.2-1.22c-0.01-0.02-0.1-0.12,0.04-0.04c-0.44-0.09,0.59-0.18,1.26-1
				c0.01,0.03,0.03,0.06,0.01,0.07c0.02-0.03,0.15-0.12,0.52-0.55c0.09,0.13,0.18,0.02,0.18-0.07c0.02,0.05,0.06,0.01,0.09,0.08
				c0.04-0.04,0.06-0.09,0.01-0.15c0.2,0.09,0.07-0.24,0.26-0.09c0.14-0.23,0-0.18,0.7-0.74c0.01,0.01,0.01,0.03,0.01,0.05
				c0.02-0.02,0.06-0.02,0.01-0.07l-0.01,0.03c-0.1-0.06-0.07-0.23-0.1-0.27c0.1,0.18,0.13-0.04,0.24,0.2c0-0.02,0-0.06-0.04-0.09
				c0.22-0.03,0.04-0.01,0.52-0.34c-0.04,0.01,0.1-0.06-0.04-0.1c0.02,0.06,0.07-0.23,0.09-0.07l0.07-0.09l-0.08,0
				c0.02-0.13-0.12-0.16,0.24-0.12c-0.05-0.1,0.08-0.11,0.03-0.22c0.01,0.03,0.05,0.08,0.07,0.09l-0.04-0.08
				c0.08,0.02,0.03-0.04,0.25-0.12l-0.02,0.04l0.06,0.02l-0.04-0.06c0.09,0.12,0.08-0.3,0.38-0.41c0,0.02,0.05,0.09,0.04,0.07
				c0.08-0.03,0.1-0.15,0.16-0.28c-0.03,0.05,0.26,0.01,0.14-0.15c0.16-0.01,0.33,0.15,0.37-0.5c-0.01,0.08,0.09,0.1,0.14,0.11
				c-0.02-0.02-0.03-0.04-0.05-0.06c0.17,0.12-0.05-0.14,0.06-0.08c0.01-0.03,0.06-0.2,0.08,0c0.1-0.13-0.05,0.02,0.26-0.08
				c-0.21-0.22-0.02-0.01-0.02-0.3c0.05,0.15-0.02,0.05,0.21,0.19c-0.03-0.09,0.1,0,0.05-0.13c0.03,0.02,0.03-0.03,0.09,0.04
				c-0.26-0.26,0.23,0.01,0.13-0.61c0.07,0.01,0.05,0.22,0.17,0.02c0.11,0.17,0.03-0.2,0.15,0.08c0.01-0.05,0-0.13,0.01-0.19
				c0.05,0.03,0.04,0.09,0.06,0.13c-0.02-0.01,0.08-0.1-0.04-0.21c0.12,0.22-0.1-0.21-0.16,0.19l-0.08-0.13
				c0.12,0.13-0.14,0.13-0.2,0.03c-0.13,0.05-0.21,0.1-0.04,0.34l-0.22-0.15l0.06,0.09c-0.01,0.01-0.03,0.02-0.06-0.02
				c0.02,0.08,0.04,0.03,0.07,0.1c-0.11-0.03-0.09,0.14-0.21,0.03c0.04,0.07,0.08,0.01,0.08,0.09c-0.13-0.18-0.05,0.18-0.21-0.06
				c0.02,0.21-0.23-0.01-0.17,0.29c-0.02-0.06,0,0.04-0.25,0.04c0.08,0.1,0.01,0.14-0.01,0.19c-0.05-0.06-0.06-0.09-0.07-0.13
				c-0.02,0.04-0.04,0.03-0.03,0.13l-0.06-0.08c-0.04,0.05-0.02,0.17-0.13,0.18c0.06,0.07,0.22,0.14,0.19,0.24
				c-0.04-0.03-0.09-0.09-0.09-0.12c0,0.01-0.13,0.04-0.1-0.05c-0.03,0.01-0.09,0.01-0.14-0.02c0.02,0.03,0.1,0.19-0.08,0.05
				c0.02,0.05-0.04,0.04,0.02,0.11c-0.01,0.01-0.05-0.02-0.07-0.04c0.02,0.06,0,0,0,0.18l0,0c0.08,0.17-0.14,0.03-0.56,0.44
				c0-0.08-0.05-0.03,0.01-0.07c-0.07,0.02-0.12-0.01-0.11,0.11c0.04-0.08-0.22,0-0.04,0.01c-0.27,0.01-0.32,0.56-0.61,0.42
				l0.02,0.04c-0.05-0.01-0.11,0.05-0.14-0.02c-0.01,0.06,0.03,0.06,0.07,0.06c-0.1,0.01-0.09,0.08-0.18,0.1
				c0.08,0.04-0.04,0.39-0.18,0.06c0,0.11-0.14,0.05-0.18,0.07l0.01-0.05c0,0.03-0.02,0.04-0.03,0.05l0.07,0.05
				c-0.03,0-0.04,0.02-0.06-0.01c0.02,0.06,0.1,0.09,0.11,0.18c-0.05-0.01-0.04,0.09-0.12,0.02c0.1-0.03-0.08-0.08-0.03-0.14
				c-0.12,0.04-0.12-0.07-0.08,0.19c-0.03-0.06-0.09-0.08-0.1-0.06c0.03-0.02,0.08,0.07,0.08,0.13c-0.08,0-0.03,0.07-0.06,0.09
				c0.05,0.01,0.07-0.01,0.02,0.11c-0.03-0.04,0.01-0.05-0.02-0.06c0.03,0.11-0.15-0.02-0.16,0.08c-0.05-0.01-0.08-0.09-0.12-0.09
				c0.05,0.14-0.2-0.06-0.07,0.21l0.06-0.13c0.03,0.21,0.16,0.14-0.32,0.3c0.05,0.02,0.11,0.01-0.01,0.11
				c-0.04-0.06-0.07-0.06-0.11-0.08c0.03,0.04-0.07,0.05,0.01,0.17c-0.13-0.02-0.15-0.11-0.15,0.22c-0.02-0.08-0.09-0.09-0.07-0.17
				c-0.04,0.1,0.01-0.03-0.05,0.35c-0.16-0.07-0.16-0.07-0.52,0.24c-0.01-0.02-0.02-0.03-0.01-0.03c-0.11,0.29-0.13-0.23-0.41,0.27
				c0,0-0.01-0.02-0.02-0.03c0.02,0.13-0.04,0.22-0.09,0.29c-0.03-0.09,0-0.06-0.06-0.12c0.26,0.11-0.38-0.06-0.52,0.52
				c-0.02-0.11,0.01-0.2,0.02-0.27c-0.03,0.02-0.1,0.08-0.08,0.15c0.04-0.03,0.05-0.04-0.04,0.19c-0.14-0.23-0.2,0.05-0.37-0.09
				c-0.06,0.16-0.25,0.11-0.37,0.21c0.08,0.01,0.05,0.32-0.03,0.09c0.01,0.03,0,0.07,0.02,0.1c-0.07-0.01-0.17-0.06-0.29-0.19
				c0.05,0.06,0.03,0.11-0.02,0.11c0.12-0.02-0.11,0.18-0.11,0.03c-0.03,0.07-0.18,0.11-0.15,0.28c-0.02-0.02-0.06-0.06-0.05-0.09
				c-0.24,0.04-0.06,0.32-1.69,0.85c0-0.02,0.1,0.16-0.06,0.14l0.01,0c-0.08-0.11-0.13-0.05-0.18-0.03l0.07,0.17
				c-0.24-0.19-0.3,0.24-0.47,0.05c-0.01,0.06-0.05,0.07-0.07,0.13c-0.07-0.09-0.02-0.13-0.28-0.01l0-0.07
				c0,0.17-0.27,0.01-0.57,0.41c-0.05-0.12-0.12,0.03-0.16,0.1c-0.04-0.26-0.02,0.09-0.67,0.22c0.08-0.02,0.14-0.03,0.14-0.03
				c0.05-0.11-0.4-0.16-0.4,0.13c-0.01,0,0.01-0.01,0.03-0.04l0,0.01c0.02-0.01,0.05-0.02,0.07-0.03c-0.01,0.06-0.02,0.1-0.07,0.06
				c0,0.07,0,0.13,0,0.17c-0.37,0.07-0.84-0.08-1.98,0.38l-0.03-0.1c0.12,0.21-0.49-0.02-0.34,0.15c-1.04,0.01-0.61,0.46-0.56-1.99
				c0.16,0.28,0.06-0.54,0.25-0.45c-0.15-0.21-0.02-0.71-0.1-1.06c0.01,0.02,0.04,0.06,0.03,0.09c0.02-0.19,0.08-0.25,0.11-0.37
				c0.06,0.6-0.11-1.19,0.06,0.11c-0.06-0.1,0.28,1.18,0.29,2.71c-0.12-0.08-0.24-0.19,0.01,0.75l0.04-0.12
				c-0.05,0.02-0.21-0.06-0.03-0.26l0.02,0.12c0.2-0.13-0.04-0.49-0.03-0.73c0.1,0.02,0.21,0.09,0.16-0.6
				c0.03,0.03,0.1-0.03,0.09,0.07c0.07-0.31-0.13-0.49-0.03-0.62l0.01,0.03c-0.03-0.45,0.06-1.22-0.39-3.03
				c0.04,0.01-0.01-0.53-0.02-0.79l0.02,0c-0.14-0.02-0.17-0.29-0.23-0.47c0.08-0.01,0.11,0.02,0.17,0.11
				c-0.03-0.02,0.05-0.04-0.16-0.38l0.1,0.02c-0.12-0.1,0.14-0.3-0.4-0.85c0.09,0.1-0.04-0.01,0.07-0.07c0,0.01,0,0.01,0.01,0.01
				c0.32-0.37,0.01,0.01-0.37-2.31l0.15,0.08c-0.47-1.13-0.3-2.3-1.61-5.95l0.05,0.07c-0.06-0.32-0.31-0.29-0.31-0.61l0.03,0.05
				c0.14-0.21-0.14-0.09-1.24-5.63c0.04,0.01,0.07,0.11,0.11,0.19c-0.15-0.32,0.1-0.07-0.03-0.37c-0.02,0.04-0.07-0.02-0.09-0.02
				c0.05-0.16,0.01-0.52-0.03-0.76l0.08,0.04c0-0.43-0.03-0.89,0.13-1.27c0.26-0.53,1.05-0.95,4.81,1.86l-0.03-0.04
				c0.29,0.67,0.29,0.41,1.13,3.93c1.88-1.02-3.37-0.45,3.46-7.3l-0.08,0.17c0.66-0.47,1.51-0.53,2.26-0.64
				c-0.34,0.5-0.01-0.75,2.23,0.97c-0.14,0.06,0.09,0.47,0.2,0.63c-0.06-0.04-0.01,0.16-0.09,0.11c0.05,0.19,0.12,0.38,0.19,0.33
				c-0.08,0.74,0.05,0.52,0.3,2.87c-0.05-0.05-0.09-0.12-0.11-0.18c-0.02,0.46,0.04,0.5-0.1,2.43c-0.01,0-0.02-0.06-0.03-0.11
				c0.04,0.28-0.01-0.04,0.23,0.57c-0.18-0.2-0.13,0.48-0.34,0.18c0,0.07,0.02,0.15,0.07,0.14c-0.03,0.09-0.06,0.12-0.1,0.01
				c-0.02,0.35,0.08,0.24,0.01,0.56c-0.03-0.01-0.03-0.07-0.07-0.11c0.03,0.17,0.07,0.35-0.01,0.6c-0.02-0.02-0.05,0-0.03-0.07
				c-0.1,0.45,0,0.82-0.2,1.29c-0.05-0.1-0.16-0.14-0.26,0.42l0.09-0.09c-0.07,0.23-0.05,0.46-0.15,0.65l0-0.19l-0.11,0.44
				l0.12-0.23c-0.01,0.25,0.17,0.03,0.03,0.32c0.01-0.07-0.08,0.23-0.2,0.32l0,0c-0.07,0.55-0.25,0.02-0.37,1.07
				c-0.11-0.18-0.27,0.27-0.2,0.42c0.01-0.03-0.01-0.02,0-0.09c-0.16,0.4-0.25,0.55-0.33,0.67l0.03-0.1
				c-0.25,0.47,0.01-0.05-0.78,1.76l0.06-0.08c-0.14,0.59-0.37,0.2-0.86,1.62l-0.01-0.08c-0.44,0.87-0.44,0.64-1.49,2.4
				c0.04-0.16,0.09-0.24-0.22,0.34c0.09,0.01,0.08-0.03,0.19-0.09c0,0.08-0.05,0.23-0.12,0.33c0.09-0.23-0.11-0.07-0.2-0.01
				c0.03,0,0.03,0.04,0.06,0.05c-0.27,0.15,0.11,0.13-0.18,0.39c-0.05-0.06-0.1-0.13-0.49,0.7c0.03-0.09,0.1-0.2,0.13-0.29
				c-0.21,0.11-0.2,0.15-0.3,0.26l0.01-0.01l-0.25,0.26l0.08-0.06c0.05,0.36-0.27,0.47-0.36,0.85c0.07-0.17-0.12-0.38-0.55,0.37
				c0.09-0.3-0.28,0.68-1.41,1.81c0.01-0.01,0.02-0.02,0.03-0.03c-0.1,0.31,0.08,0.22-0.4,0.79c-0.03-0.15-0.06-0.32-0.52,0.69
				l0.11-0.09c-0.05-0.02-0.13-0.18,0.16-0.26l-0.07,0.12c0.52,0.01-0.53-0.22,1.34-1.08l-0.09,0.08c0.09-0.05,0.2-0.02,0.37-0.46
				c0.18,0.25,0.14-0.41,0.32-0.12c0-0.12,0.33-0.05,0.81-0.95c-0.02,0.29,0.32-0.33,0.43-0.16c-0.01-0.24,0.37-0.49,0.49-0.78
				c0,0.02,0,0.06-0.03,0.08c0.12-0.12,0.19-0.12,0.28-0.18c-0.13,0.14,0.14-0.92,0.26-0.25c-0.09-0.06,0.15-0.34,0.03-0.35
				c0.16-0.07,0.12-0.08,0.26-0.07c-0.22-0.02,0.43-0.77,0.37-0.32c-0.14-0.27,0.57-0.69,0.89-1.8c0.98,0.2,0,0,1.37-1.59l0.05,0.15
				c0.45-0.93,1.17-1.53,3.27-4.08l-0.02,0.07c0.22-0.18,0.07-0.37,0.32-0.49l-0.02,0.05c0.06,0.13,0.2-0.25,3.07-2.42
				c-0.16-0.11,0.21,0.07,0.82-0.56c0.01,0.04-0.06,0.1-0.11,0.16c0.19-0.23,0.1,0.07,0.28-0.14c-0.04-0.01-0.01-0.07-0.02-0.09
				c0.14,0,0.4-0.12,0.58-0.2l-0.01,0.08c0.33-0.09,0.68-0.19,1.02-0.12c0.5,0.26,0.99,0.67-1.7,5.65l0-0.02l-0.15,0.33
				c1.2,0.48-1.33-1.12,7.01-1.66c-0.05,0-0.1-0.01-0.15-0.01c0.19,0.23,0.36,0.19,0.8,1.74c-0.25-0.19,0.22-0.16-0.21,2.33
				c-0.15-0.07-0.27,0.37-0.28,0.54c-0.02-0.07-0.1,0.1-0.14,0.02c-0.07,0.16-0.13,0.32-0.04,0.33c-0.49,0.4-0.18,0.38-1.44,1.94
				c0.08-0.66,0.73-0.56-1.58,1.09c-0.01-0.01,0.02-0.05,0.05-0.08c-0.16,0.19,0.02-0.03-0.24,0.49c0.03-0.25-0.4,0.16-0.32-0.18
				c-0.04,0.04-0.09,0.1-0.05,0.14c-0.08,0.03-0.12,0.01-0.07-0.07c-0.25,0.17-0.12,0.2-0.37,0.31c-0.01-0.03,0.03-0.07,0.04-0.12
				c-0.1,0.12-0.19,0.25-0.42,0.31c0-0.02-0.03-0.04,0.03-0.07c-0.36,0.17-0.55,0.44-0.99,0.54c0.04-0.1,0-0.2-0.43,0.01l0.11,0.03
				c-0.2,0.07-0.34,0.22-0.52,0.24l0.13-0.1l-0.36,0.15l0.22-0.02c-0.18,0.13,0.07,0.15-0.2,0.2c-0.41,0.03-0.14-0.04-1.25,0.34
				c0.11-0.17-0.29-0.13-0.39,0.09c0.02-0.01,0.01-0.02,0.06-0.05c-0.36,0.11-0.51,0.13-0.63,0.13l0.09-0.04
				c-0.45,0.09,0.04-0.02-1.59,0.48l0.08-0.01c-0.01,0.19-0.34-0.01-0.54,0.13c-0.12-0.18,0.04-0.07-0.99,0.26l0.05-0.06
				c-0.8,0.24-0.67,0.08-2.39,0.49c0.12-0.07,0.2-0.1-0.34,0.07c0.05,0.07,0.07,0.04,0.17,0.08c-0.05,0.06-0.17,0.12-0.28,0.14
				c0.19-0.09-0.03-0.13-0.12-0.16c0.02,0.02-0.01,0.05,0.01,0.08c-0.5,0.02-0.01,0.12-1.07,0.24c0.07-0.04,0.18-0.06,0.25-0.1
				c-0.2-0.08-0.21-0.05-0.35-0.04l0.01,0l-0.31,0l0.08,0.02c-0.18,0.26,0.01,0-0.72,0.31c0.12-0.05,0.14-0.34-0.58-0.14
				c0.09-0.06-1.15,0.31-2.66,0.45c0.07-0.12,0.16-0.26-0.74,0.08l0.12,0.02c-0.02-0.05,0.03-0.22,0.26-0.06
				c-0.51,0.49,0.14-0.16,0.52,0.18c0.01,0,0.01,0,0.01-0.01c-0.01,0.1-0.03,0.23-0.04,0.45l0.08-0.09l0,0.08l-0.05-0.01
				c0.01,0.08,0.04,0.02,0.07-0.02c0,0.04,0,0.07,0.01,0.09l0.09-0.14c-0.01,0.06-0.05,0.12-0.02,0.16c0.04-0.07,0-0.15,0.06-0.18
				c0.01,0.08-0.01,0.14,0.04,0.08c0,0.04-0.02,0.07-0.03,0.08c0.04-0.04,0.08,0,0.12-0.09c0.01,0.1,0.11,0,0.07,0.15
				c0.02-0.03,0.08-0.11,0.11-0.11c-0.09,0.17,0.1,0.03,0.05,0.22c0.07-0.15,0.15-0.04,0.23-0.12c-0.01,0.01-0.02,0.04-0.02,0.03
				c0.03,0.02,0.03,0.07,0.05,0.11c0.03-0.13,0.1-0.15,0.15-0.23c0.02,0.14-0.06,0.15-0.07,0.28c0.02-0.11,0.1-0.09,0.11-0.19
				c0.02,0.09,0.02,0.05,0.02,0.16c0.04-0.17,0.04-0.02,0.09-0.12c0.03,0.09-0.02,0.16,0.04,0.12c-0.12,0.19,0.27,0.06,0.47-0.2
				c-0.01-0.01-0.02,0.03-0.04,0.05l0.03-0.03c-0.03,0.18-0.14,0.08,0.04,0.18l-0.05-0.06c0.07,0.26,0.44-0.1,2.05,0.59
				c0.04-0.09,0.27-0.12,0.37,0.13l0.08-0.1l-0.01,0.13c0.12-0.13-0.07,0.17,0.28,0c-0.01,0.04-0.04,0.05-0.06,0.07
				c0.1-0.15,0.01,0.16,0.1-0.05c0.01,0.07,0.08,0.09,0.14,0.08c0,0.12-0.06,0.07,0.27,0.12c-0.03-0.04,0.02-0.17,0.05-0.17
				l-0.02,0.11c0.03-0.06,0.1-0.23,0,0.04l0.06-0.02c-0.01,0.03-0.02,0.05-0.04,0.09c0.04-0.06,0.05,0.13,0.11,0.02
				c-0.42,0.17,1.18,0.06,1.87,0.65c0.01-0.02,0.04-0.15,0.05,0c-0.06-0.29,0.8,0.39,0.72,0.28c0.2-0.06,0.31,0.13,0.5,0.09
				c-0.02,0.02-0.03,0.05-0.05,0.05c0,0.09,0.11,0.06,0.14-0.01c-0.1-0.06,0.22,0.21,0.45,0.13c-0.07,0.14,0.04,0.16,0.11,0.11
				c-0.03,0.04,0.01,0.06-0.03,0.11c0.04,0.02,0.09,0.01,0.12-0.07c-0.01,0.21,0.21-0.06,0.14,0.18c0.15-0.02,0.28-0.01,0.4,0.01
				c-0.03,0.16-0.07,0.07,0.37,0.25c-0.01,0.01-0.02,0.02-0.04,0.03c0.02,0.01,0.03,0.04,0.06-0.02l-0.03,0.01
				c0.02-0.11,0.17-0.15,0.2-0.2c-0.13,0.16,0.06,0.09-0.12,0.29c0.02-0.01,0.05-0.03,0.07-0.07c0.06,0.17,0.01,0.03,0.38,0.32
				c-0.02-0.03,0.07,0.07,0.08-0.07c-0.04,0.04,0.21-0.02,0.08,0.05l0.09,0.03c0-0.07-0.01-0.08,0.1-0.15
				c0,0.09,0.07,0.08,0.02,0.25c0.08-0.08,0.11,0.04,0.2-0.04c-0.02,0.02-0.06,0.07-0.07,0.09l0.07-0.06
				c-0.06,0.12,0.03,0.1,0.03,0.07c-0.04,0.04,0.32,0.1,0.08,0.21c0.1,0.04,0.27-0.01,0.33,0.08c0.1-0.2,0-0.01,0.09,0.06
				c-0.02,0-0.07,0.07-0.06,0.06c0.04,0.07,0.14,0.05,0.25,0.07c-0.04-0.02,0.01,0.24,0.14,0.09c-0.02,0.15-0.02,0.36,0.45,0.22
				c-0.07,0.01-0.09,0.1-0.1,0.15c0.01-0.02,0.04-0.04,0.05-0.06c-0.12,0.18,0.13-0.07,0.07,0.04c0.03,0,0.17,0.02,0,0.08
				c0.11,0.07-0.01-0.03,0.07,0.22c0.21-0.22,0.01-0.01,0.28-0.06c-0.14,0.08-0.04-0.02-0.18,0.22c0.08-0.04-0.01,0.08,0.11,0.03
				c-0.02,0.03,0.02,0.03-0.04,0.08c-0.14-0.29,0.52,0.14,0.43,0.34c0.05,0.01,0.12-0.01,0.17,0c-0.04,0.05-0.08,0.04-0.12,0.06
				c0.01-0.02,0.07,0.05,0.18-0.04c-0.05-0.02-0.16-0.12-0.02-0.22c0,0.02-0.15-0.07-0.01-0.18c-0.05-0.12-0.08-0.18-0.31,0
				l0.15-0.22l-0.08,0.07c0-0.01-0.01-0.03,0.02-0.06c-0.08,0.02-0.03,0.04-0.09,0.07c0.04-0.11-0.13-0.06-0.02-0.19
				c-0.07,0.05-0.01,0.08-0.09,0.08c0.16-0.14-0.16-0.02,0.06-0.19c-0.19,0.05,0.01-0.2-0.26-0.11c0.05-0.03-0.04,0-0.04-0.22
				c-0.09,0.09-0.12,0.03-0.17,0.02c0.05-0.06,0.08-0.07,0.12-0.08c-0.04-0.02-0.03-0.03-0.12,0l0.07-0.07
				c-0.04-0.03-0.15,0.01-0.17-0.08c-0.06,0.07-0.12,0.23-0.21,0.23c0.03-0.04,0.08-0.1,0.11-0.11c-0.03-0.11-0.01,0.07,0.05-0.23
				c-0.02,0.03-0.17,0.14-0.05-0.06c-0.04,0.03-0.04-0.03-0.1,0.05c-0.01-0.01,0.02-0.05,0.03-0.08c-0.05,0.03,0,0-0.16,0.04l0,0
				c-0.08,0.2-0.08-0.24-0.48-0.47c0.08,0.01-0.03-0.2-0.01-0.03c-0.13-0.33-0.6-0.09-0.46-0.42l-0.03,0.03
				c0-0.05-0.06-0.09-0.01-0.13c-0.06,0.01-0.05,0.05-0.04,0.08c-0.02-0.08-0.09-0.05-0.13-0.12c-0.03,0.09-0.34,0.1-0.09-0.14
				c-0.1,0.04-0.07-0.11-0.1-0.14l0.05-0.01c-0.03,0.01-0.04,0-0.06-0.01l-0.03,0.08c-0.01-0.02-0.02-0.03-0.01-0.06
				c-0.04,0.04-0.06,0.13-0.13,0.17c0-0.05-0.09,0-0.04-0.1c0.05,0.07,0.05-0.1,0.12-0.08c-0.07-0.1,0.03-0.12-0.18,0
				c0.04-0.05,0.04-0.11,0.02-0.11c0.03,0.02-0.04,0.1-0.09,0.12c-0.02-0.07-0.07,0-0.09-0.02c0,0.05,0.02,0.06-0.08,0.06
				c0.02-0.04,0.04-0.01,0.04-0.04c-0.09,0.07-0.02-0.14-0.11-0.1c0-0.05,0.05-0.1,0.05-0.14c-0.1,0.1-0.02-0.2-0.2,0.04l0.12,0
				c-0.16,0.1-0.07,0.2-0.34-0.14c0,0.05,0.02,0.1-0.09,0.04c0.03-0.06,0.03-0.09,0.04-0.14c-0.02,0.05-0.06-0.04-0.13,0.08
				c0.14-0.27-0.36,0.12-0.11-0.17c-0.1,0.02,0.02-0.01-0.3,0.13c0.02-0.23-0.21-0.08-0.19-0.27c-0.35,0-0.1,0.09-0.77-0.17
				c0.05-0.07,0.05-0.03,0.07-0.12c-0.19,0.34-0.18-0.02-0.39-0.18c-0.04,0.02-0.15,0.08-0.22,0.08c0.07-0.08,0.15-0.11,0.21-0.15
				c-0.03-0.01-0.11-0.03-0.15,0.03c0.04,0.01,0.06,0.02-0.15,0.08c0.11-0.25-0.13-0.12-0.1-0.33c-0.14,0.05-0.2-0.12-0.33-0.15
				c0,0.09-0.21,0.27-0.07-0.02c0.01,0.08-0.06,0.1-0.07,0.16c-0.03-0.05-0.05-0.16-0.02-0.36c-0.02,0.08-0.06,0.1-0.09,0.05
				c0.07,0.07-0.19,0.04-0.08-0.06c-0.06,0.02-0.17-0.06-0.27,0.09c0.25-0.34-0.38,0.02-1.61-0.52l0.01,0.01
				c0.02-0.14-0.05-0.12-0.09-0.13l-0.06,0.18c-0.05-0.29-0.33,0.01-0.33-0.23c-0.04,0.04-0.07,0.03-0.11,0.06
				c0-0.09,0.07-0.12-0.18-0.17l0.04-0.06c-0.06,0.13-0.22-0.14-0.6,0.04c0.03-0.13-0.09-0.04-0.16,0.01
				c0.03-0.14,0.12-0.1-0.34-0.08c-0.03,0.06-0.12,0.04-0.24-0.06c0.06,0.02,0.12,0.03,0.11,0.04c0.08-0.04-0.23-0.34-0.34-0.07
				c0-0.01,0.01,0,0.04-0.02l0,0.01c0.02,0,0.04,0.01,0.07,0.01c-0.03,0.04-0.06,0.08-0.08,0.02c-0.03,0.06-0.06,0.12-0.07,0.15
				c-0.07-0.05-0.04-0.34-0.49-0.16c-0.1-0.39,0.04-0.15-0.44-0.17c0.01,0.02,0.01,0.03,0.01,0.05c-0.09-0.03-0.17-0.25-0.7-0.12
				c0.16-0.16,0.01-0.03,0.47-0.14l-0.02,0.01c0.58,0.12,0.49-0.23,1.77-0.3c-0.01,0,0-0.01-0.01-0.01c0.24,0,0.06-0.08,1.16,0.14
				c-0.03,0.03,0.02,0.1-0.06,0.08c0.24,0.08,0.39-0.11,0.48,0l-0.02,0.01c0.34,0.02,0.91,0.12,2.37,0.03
				c-0.02,0.04,0.4,0.09,0.59,0.15l-0.01,0.02c0.05-0.13,0.25-0.09,0.4-0.1c-0.01,0.08-0.04,0.1-0.12,0.13
				c0.02-0.02,0.03,0.06,0.31-0.05l-0.04,0.09c0.19,0.01,0.38-0.08,0.62,0.01c0.02-0.09-0.17-0.27,0.09-0.3
				c0,0.05,0.01,0.13-0.06,0.15c0.02,0,0.08-0.03,0.12-0.01l-0.11,0.07c0.05-0.03,0.33,0.03,0.08,0.05c0.1,0.02,0.21,0.07,0.25,0.13
				c0.29-0.21,0,0.01,0.84-0.05l0,0c0.33-0.06,0,0,0.96,0.14c-0.05,0.14,0.3,0.04,1.01,0.19c-0.15,0.04,0.04,0.06-0.15,0.02
				c0.18,0.06,0.21,0.12,0.42,0.06c-0.08,0.07,0.12,0.14,0.21,0.13l-0.12-0.09c0.1,0.01,0.18,0.03,0.27,0.05l-0.07,0.11
				c0.41,0.13,0.84,0.16,1.73,0.25c-0.09-0.29,0.33,0.39,0.34,0.12c0.2,0.11,0.34,0.08,0.55,0.18c-0.05-0.07,0.75-0.14,0.46,0.2
				c0.22-0.02,0.35,0.16,0.46,0.21l-0.11,0c0.07,0,0.12,0.02,0.17,0.03l-0.03-0.1c0.06,0.03,0.11,0.05,0.09,0.08
				c0.14-0.05,0.07-0.3,0.39-0.02c-0.07-0.14-0.24,0.37,0.28,0.15c-0.05,0.04,0.01,0.13,0.05,0.14c-0.08-0.02,0.04-0.16,0.36-0.03
				l-0.08-0.06c0.25-0.07,0.37,0.24,0.9,0.73c0-0.1-0.02-0.09,0.14-0.07c-0.1-0.05-0.21-0.1-0.32-0.15l0.25,0.02
				c-0.09-0.06-0.21-0.08-0.26-0.12c0.13,0,0.11,0.01,0.1-0.08c0.45,0.32-0.05,0.39,0.84,0.89l-0.05-0.1
				c0.09-0.03,0.15,0.13,0.24,0.19c-0.06,0.04-0.03,0.1-0.04,0.16c0.04-0.04,0.14,0.18,0.27,0.12c0.06,0.3-0.05,0.19,0.44,0.55
				c-0.1-0.03-0.09,0.09-0.22-0.03c0.04,0.07,0.06,0.14,0.1,0.12c-0.08-0.1,0.07,0.3,0.39,0.4c-0.14,0.23,0.05,0.31-0.06,1.5
				c-0.02-0.01-0.03-0.01-0.02-0.04c-0.09,0.52,0.02,0.02-0.35,0.73l0.01-0.04c-0.13,0.08-0.07,0.32-0.21,0.5
				c0,0-0.01-0.02-0.03-0.02c0.02,0.19-0.13,0.45-0.25,0.63c-0.03-0.03-0.04-0.05-0.05-0.07c0.03,0.07,0.05,0.21-0.28,0.2l0.12-0.13
				c-0.57,0.22-0.26,0.57-1.3,1.24c0.05-0.14,0.2-0.31,0.29-0.44c-0.09,0.06-0.29,0.2-0.31,0.29c0.03-0.03,0.06-0.09,0.1-0.1
				c-0.66,0.56,0.03,0.13-2.24,0.72c0.06,0.18-0.05,0.24-0.2,0.23c0.02-0.05,0.13-0.16-0.02-0.19c0.12,0.05-0.08,0.12-0.04,0.18
				c-0.16-0.03-0.34-0.14-0.42-0.29c0.03,0.08-0.07,0.11-0.19,0.08l0.17,0.1c-0.3,0.11-0.26-0.16-0.55-0.08l0.1-0.06
				c-0.16,0.04-0.54-0.06-0.72,0.07c-0.02-0.03-0.04-0.1,0.03-0.1c-0.01-0.14-0.6-0.11-2.05-1.09l-0.01,0.01
				c-0.01-0.02-0.05-0.06,0.02-0.08c-0.04-0.03-0.07-0.06-0.1-0.09c-0.18,0.76,1.27,1.26-1.31,3.43c0.04,0.01-0.25-0.37-0.42,0
				c-0.06-0.28-0.64-0.02-1.83-1.19l0.09-0.01c-0.56-0.2-0.28,0-1.05-0.92c0.02-0.01,0.06-0.03,0.1,0.01
				c-0.09-0.12-0.26-0.53-0.39-0.37c0.11-0.08-0.17-0.28-0.32-0.41l0.1-0.03c-0.03,0.02-0.19-0.12-0.5-0.49l0.09,0.03
				c-0.13-0.24-0.29-0.02-0.28-0.54c0.05,0.07-0.26-0.16-0.12-0.14c-0.03-0.31-0.1,0.05-0.28-0.61c0.11,0.21,0.19,0.37,0.18,0.36
				c0.08-0.04,0.01-0.21,0.18-0.14c-0.02-0.14-0.36-0.16-0.63-1c-0.08,0.06-0.13-0.06-0.14,0.08c-0.01-0.03,0.02,0.04,0.08,0.09
				l-0.01,0c0.03,0.05,0.07,0.12,0.12,0.2c-0.11,0.02,0.02-0.21-0.36-0.24c0.09-0.41,0.09-0.38-0.62-1.26
				c0.21,0.12,0.17,0.14-0.34-0.45l0.05,0.01c-0.19-0.58-0.82-0.49-1.06-1.11c0.02,0.04,0.03,0.06,0.02,0.08
				c-0.26-0.21-0.77-0.9-2.81-2.26l0,0.09c-0.78-0.58,0.09,0.17-1.28-0.06c0.1,0.09,0.07,0.06,0.51,0.14
				c-0.17,0.32,0.31-0.02,0.15,0.23c0.11,0.01,0.25,0.12,0.34,0.07c0.04,0.13,0.31,0.16,0.22,0.29c0,0,0.01,0,0.01,0
				c-0.09,0.13-0.13,0.43,0.01,1l-0.1-0.38c0.01,0.39,0.24,1.3,0.89,3.79c-0.24-0.36,0.1,0.8-0.12,0.73
				c0.2,0.27,0.19,1.04,0.34,1.54c-0.02-0.03-0.05-0.08-0.05-0.13c-0.27-0.01,0.52,1.52-0.05,1.2c0.11-0.02,0.14,0.61,0.23,0.49
				c-0.07,0.28-0.03,0.24-0.13,0.38c0.19-0.21,0.18,1.5-0.06,0.84c0.3,0.99,0.02,0.06,0.14,3.06c0.2-0.03,0.21-0.08,0.15,0.39
				c-0.13-0.81-0.29,0.04-0.16,0.12c-0.53,0.44-0.03,1.52-0.39,3.54l-0.1-0.18c-0.29,1.65,0.44,0.09-2.19,9.02l0.01-0.13
				c-0.19,0.46,0.01,0.6-0.24,1l0.01-0.09c-0.19,0.32,0.18,0.11-2.48,5.67c0.17-0.05-0.37,0.7-1.13,2.43
				c-0.03-0.04,0.03-0.2,0.07-0.33c-0.36,0.88-0.06,0.16-0.82,1.64l-0.03-0.1c-1.81,3.19-0.44,1.03-7.15,11.5l0.02-0.04
				c-1.32,1.93,0.09,0.06-3.57,4.72l0.08,0c-0.79,1.03-0.01,0.47-4.05,5.43l0.05-0.25c-0.68,1.11-1.62,2.1-2.3,3.19
				c0.19-0.42-0.8,0.88-2.66,3.63c-0.11-0.12-0.61,0.59-0.72,0.88c0.02-0.11-0.2,0.18-0.18,0.05c-0.2,0.28-0.37,0.55-0.27,0.55
				c-0.81,0.84-0.61,0.62-2.58,3.72c0.04-0.15,0.08-0.27,0.12-0.36c-0.33,0.54-0.49,0.6-2.15,3.25c-0.01-0.01,0.02-0.09,0.06-0.16
				c-0.2,0.44,0.17-0.29-0.21,0.85c-0.03-0.33-0.46,0.62-0.45,0.13c-0.04,0.09-0.08,0.22-0.03,0.22c-0.09,0.12-0.14,0.14-0.11-0.01
				c-0.27,0.48-0.1,0.36-0.37,0.78c-0.03-0.02,0.01-0.11,0.01-0.18c-0.08,0.25-0.16,0.5-0.41,0.83c0-0.03-0.05-0.01,0.01-0.11
				c-0.38,0.62-0.49,1.14-0.97,1.78c0-0.14-0.08-0.24-0.51,0.56l0.14-0.11c-0.19,0.32-0.27,0.65-0.47,0.91l0.09-0.27l-0.33,0.62
				l0.24-0.32c-0.13,0.36,0.16,0.05-0.12,0.46c0.04-0.09-0.19,0.32-0.35,0.45l0,0c-0.29,0.73-0.33,0.07-0.82,1.54
				c-0.07-0.26-0.4,0.41-0.37,0.62c0.02-0.05-0.01-0.03,0.03-0.13c-0.29,0.59-0.45,0.82-0.58,1l0.06-0.15
				c-0.42,0.73,0.01-0.06-1.27,2.68l0.08-0.13c-0.24,0.89-0.56,0.43-1.15,2.58l0-0.11c-0.53,1.43-0.57,1.05-1.61,4.05
				c0.03-0.25,0.07-0.38-0.2,0.6c0.1-0.03,0.08-0.08,0.19-0.22c0.01,0.11-0.03,0.34-0.09,0.53c0.07-0.36-0.12-0.04-0.22,0.1
				c0.03-0.02,0.04,0.04,0.07,0.04c-0.27,0.35,0.13,0.12-0.15,0.63c-0.06-0.07-0.11-0.18-0.38,1.25c0.01-0.14,0.07-0.33,0.08-0.47
				c-0.2,0.28-0.18,0.32-0.26,0.55l0.01-0.02l-0.2,0.52l0.07-0.13c0.11,0.46-0.19,0.81-0.16,1.39c-0.02-0.56-0.06-0.11-0.16-0.17
				c-0.06,0.13-0.14,0.33-0.16,0.26c-0.03,0.19,0.09,0.23-0.27,0.99c0.36-1.25-0.36,2.5-0.59,4.57c-0.08-0.21-0.24-0.35-0.14,1.32
				l0.05-0.21c-0.07,0-0.19-0.12,0.01-0.46l0,0.22c0.2-0.2,0.08-0.86,0.14-1.29c0.12,0.12,0.17,0.14,0.32-1
				c0.02,0.06,0.11-0.03,0.06,0.15c0.15-0.52,0.04-0.88,0.18-1.06l0,0.05c0.25-1.52,0.16-1.64,0.96-4.27
				c0.13,0.07-0.11,0.11,0.22-0.92c0.04,0.04,0.31-0.85,0.5-1.26l0.02,0.02c-0.09-0.12,0.08-0.55,0.16-0.88
				c0.07,0.04,0.08,0.1,0.05,0.29c-0.01-0.05,0.07,0,0.15-0.68c0.16,0.3,0.12-0.12,0.43-1.57l-0.01,0.24
				C394.01,313.65,393.88,313.72,393.95,313.54z M410.23,291.09c0-0.02,0,0,0-0.05C410.26,291.02,410.34,291.04,410.23,291.09z
				 M410.63,291.64c0.01,0,0.03-0.01,0.05-0.02C410.66,291.63,410.69,291.62,410.63,291.64z M410.82,291.5
				c-0.01,0.03,0.05,0.03,0.1,0.05C410.92,291.53,410.77,291.63,410.82,291.5z M463.31,232.75c-0.01-0.04,0.03-0.12,0.05,0.01
				C463.35,232.76,463.33,232.76,463.31,232.75z M465.12,238.29c-0.05-0.03-0.03-0.06,0.01-0.08
				C465.11,238.23,465.11,238.26,465.12,238.29z M465.49,238.32c-0.12,0-0.02,0.04,0.1-0.16l-0.02,0.15l0.01-0.01l-0.02,0.14
				C465.51,238.42,465.45,238.36,465.49,238.32z M465.47,238.14l0.02-0.03C465.49,238.12,465.52,238.16,465.47,238.14z
				 M465.61,238.43c0.06,0.16-0.02-0.03,0.09-0.13C465.68,238.33,465.65,238.37,465.61,238.43z M465.89,238.3l0.02-0.02
				C465.91,238.32,465.9,238.32,465.89,238.3z M465.93,238.34c0.03-0.01-0.06-0.03,0.02-0.09
				C465.94,238.28,465.98,238.34,465.93,238.34z M463.9,232.22c0.02,0.01,0.04-0.05,0.07-0.09
				C463.94,232.11,464.02,232.31,463.9,232.22z M463.98,232.4c0.01,0.03,0,0,0.01,0.07C463.99,232.45,463.98,232.43,463.98,232.4z
				 M464.81,236.43c0.02,0,0.05,0.01,0.04,0.03c-0.02-0.09,0.03-0.13,0.04-0.2c-0.13,0.03-0.21-0.1-0.31-0.15
				c0.09-0.14,0.17,0.02,0.29-0.05c-0.11,0.04-0.16-0.15-0.24-0.09c0.06-0.11,0.02-0.08,0.12-0.16c-0.17,0.03-0.05-0.07-0.17-0.11
				c0.08-0.05-0.05-0.27,0.19-0.16c-0.31-0.02,0.07-0.41-0.63-0.96c0,0.04,0.04,0.04,0.07,0.05l-0.05-0.06
				c0.25,0.28,0.14-0.32,0.12-0.06c0.58,0.02-0.22-0.67-0.25-2.11c0.04,0.29,0.06-0.26,0.13-1.8l0.02,0.04
				c0.07-0.13-0.17-0.21-0.04-0.31l0.01,0.03c0.17-0.14,0.09,0.04,1.21-1.18l0.07,0.12c0.1-0.17-0.04,0.07,0.46-0.02
				c-0.02,0.04-0.06,0.04-0.09,0.06c0.15-0.14-0.02,0.16,0.15-0.02c0.02,0.09,0.12,0.13,0.21,0.18l-0.06,0.05
				c0.1,0.1,0.25,0.22,0.35,0.41c0.03-0.04,0.28-0.1,0.08,0.02c0.08,0,0.25-0.05-0.02,0.04l0.08,0.08c-0.03,0.02-0.05,0-0.1,0.02
				c0.21,0.49-0.04,0.03,0.59,1.45c-0.09,0.02-0.05,0.21-0.13,0.12c0.08,0.45,0.31,0.38,0.15,1.95l-0.01-0.02
				c-0.31,3.09,0.62,0.79,2.77-1.21l0.02,0.13c0.22-0.36,0.65-0.5,0.9-0.8c0,0.03,0.01,0.06-0.02,0.08c0.11,0.03,0.24-0.14,0.21-0.2
				c0.16,0-0.09,0,0.91-0.32c-0.01,0.16,0.17,0.14,0.26,0.1c-0.04,0.04,0.04,0.06,0,0.12c0.07,0.02,0.15,0.03,0.17-0.05
				c0.11,0.32,0.15,0.02,0.84,0.8c-0.51-0.04-0.07-0.51,0.05,0.93c-0.01,0-0.02-0.03-0.03-0.05c0.06,0.13,0-0.03,0.25,0.29
				c-0.18-0.12-0.11,0.2-0.33,0.01c0,0.03,0.02,0.07,0.07,0.08c-0.03,0.04-0.06,0.04-0.1-0.02c-0.01,0.16,0.07,0.13,0.02,0.26
				c-0.03-0.01-0.03-0.05-0.06-0.08c0.03,0.09,0.06,0.19-0.02,0.28c-0.01-0.02-0.05-0.02-0.03-0.05c-0.09,0.19-0.03,0.41-0.23,0.57
				c-0.08-0.15-0.09,0.04-0.2-0.04l-0.05,0.12l0.08,0c-0.07,0.08-0.07,0.22-0.17,0.27l0.02-0.1l-0.13,0.17l0.12-0.05
				c-0.03,0.13,0.13,0.11,0,0.19c0.01-0.03,0,0-0.18,0.07c-0.01-0.03-0.06,0.33-0.23,0.05c0.03,0.12-0.18,0.2-0.16,0.33
				c-0.03-0.15-0.23-0.07-0.19,0.12c0.01-0.01-0.01-0.02,0.01-0.05c-0.17,0.14-0.25,0.17-0.31,0.18l0.04-0.05l-0.09,0l0.02,0.06
				c-0.26-0.03,0.09,0.27-1.39,0.92l0.01-0.06c0,0.03-0.28-0.04-0.36,0.24c-0.41-0.11-0.53,0.38-0.9,0.28
				c0.1-0.03-0.22-0.05-0.19,0.28c0.09-0.09-0.04-0.12-0.11-0.15c0.02,0.02,0.01,0.05,0.02,0.07c-0.16-0.15-0.02,0.11-0.18,0.09
				c0.02-0.02-0.29,0.24-0.14-0.01c-0.1,0.04-0.14,0.23-0.26,0.17c0.03-0.04,0.09-0.06,0.13-0.1c-0.13-0.07-0.13-0.04-0.21-0.04
				l0.01,0l-0.18,0c0.09,0.28,0.02,0.02-0.31,0.33c-0.4-0.37-0.04-0.19-1.61,0.33c0-0.12,0.04,0.01-0.34-0.22
				c0.33-0.15-0.22-0.11,0.05-0.21c-0.08-0.05-0.09-0.17-0.19-0.18c0.06-0.1-0.11-0.22,0.03-0.27c-0.04-0.01-0.16-0.08-0.19-0.14
				c0.22,0.05-0.08-0.24,0.12-0.28C464.93,236.82,464.95,236.56,464.81,236.43z M453.44,255.3c-0.01-0.02-0.06,0.13-0.1,0.01
				C453.36,255.33,453.4,255.31,453.44,255.3z M453.31,255.46c-0.01,0.02,0,0-0.03,0.04C453.29,255.49,453.3,255.48,453.31,255.46z
				 M452.67,255.24c-0.01-0.01,0,0-0.03-0.04C452.66,255.18,452.73,255.16,452.67,255.24z M451.84,257.38
				c-0.13,0.07-0.14,0.06-0.18,0.06c0.01-0.01,0.01-0.03,0-0.06C451.72,257.41,451.79,257.31,451.84,257.38z M431.25,265.24
				c0.02-0.04-0.21,0.15-0.15-0.01C431.08,265.25,431.18,265.23,431.25,265.24z M430.87,265.4c-0.04,0.02,0,0-0.09,0.05
				C430.8,265.43,430.83,265.42,430.87,265.4z M430.18,265c0-0.02,0,0,0-0.06C430.23,264.92,430.36,264.91,430.18,265z
				 M439.93,211.75c-0.01-0.07-0.04-0.08,0.07-0.13C439.99,211.65,439.98,211.61,439.93,211.75z M422.31,216.49
				c-0.06,0.03-0.06,0.06-0.15-0.03C422.2,216.46,422.17,216.49,422.31,216.49z M434.03,221.56c-0.03,0-0.09,0.04-0.07-0.05
				C433.96,221.53,434,221.54,434.03,221.56z M433.89,221.6c-0.01,0.01,0,0-0.03,0.02C433.87,221.62,433.88,221.61,433.89,221.6z
				 M433.56,221.04c0-0.02,0-0.04-0.01-0.05C433.58,220.98,433.59,221.01,433.56,221.04z M430.88,222.34c0-0.03-0.03-0.03,0.02-0.09
				l0.03,0.08L430.88,222.34z M429.47,210.28c0.03,0.07,0.06,0.14,0.08,0.2C429.52,210.43,429.5,210.37,429.47,210.28z
				 M429.24,209.47c-0.01-0.03-0.02-0.06-0.02-0.09C429.25,209.4,429.23,209.37,429.24,209.47z M421.62,215.66
				c0.03-0.01-0.07-0.07-0.14-0.12C421.43,215.56,421.79,215.58,421.62,215.66z M421.96,215.7c0.05,0.01,0,0,0.12,0.03
				C422.06,215.73,422.02,215.72,421.96,215.7z M429.49,220.48c0.29,0.05-0.31-0.36-0.13-0.45c-0.24-0.01-0.49-0.4-0.77-0.55
				c0.02,0,0.06,0.01,0.08,0.03c-0.12-0.13-0.12-0.2-0.18-0.3c0.15,0.13-0.93-0.17-0.25-0.28c-0.07,0.09-0.35-0.16-0.36-0.04
				c-0.07-0.17-0.08-0.12-0.07-0.26c-0.02,0.22-0.79-0.44-0.33-0.38c-0.7-0.09,0.05-0.09-1.62-0.84c-0.05,0.11-0.04,0.24-0.28,0.03
				c0.06-1.05-0.02,0.04-1.85-1.1l0.14-0.09c-1.07-0.2-1.84-0.79-5.11-1.67l0.08-0.01c-0.25-0.13-0.37,0.09-0.58-0.09l0.05,0
				c0.15-0.14-0.38-0.05-3.28-2.07c-0.07,0.17,0.03-0.19-0.68-0.7c0.04-0.02,0.11,0.05,0.17,0.08c-0.26-0.16,0.06-0.11-0.17-0.25
				c0,0.04-0.06,0.02-0.09,0.03c-0.02-0.14-0.18-0.38-0.3-0.56l0.09,0c-0.15-0.33-0.34-0.69-0.41-1.09
				c-0.03-0.68,0.28-1.36,6.47-1.35c0.03-1.73-1.35,1.98-2.57-6.22l0.08,0.13c0.01-0.57,0.7-0.85,1.28-1.13
				c-0.14,0.29-0.21-0.22,2.36-0.11c-0.05,0.15,0.4,0.23,0.58,0.21c-0.07,0.03,0.12,0.09,0.04,0.13c0.17,0.06,0.34,0.09,0.34,0.01
				c0.47,0.45,0.4,0.13,2.11,1.24c-0.68-0.02-0.66-0.67,1.28,1.47c-0.01,0.01-0.05-0.02-0.09-0.05c0.2,0.14-0.03-0.02,0.52,0.19
				c-0.25,0,0.21,0.38-0.14,0.34c0.04,0.04,0.11,0.08,0.14,0.04c0.04,0.08,0.03,0.11-0.06,0.08c0.21,0.23,0.21,0.1,0.36,0.33
				c-0.03,0.02-0.07-0.02-0.12-0.02c0.13,0.08,0.27,0.16,0.37,0.38c-0.03,0-0.03,0.04-0.07-0.02c0.23,0.34,0.52,0.5,0.69,0.91
				c-0.11-0.02-0.2,0.03,0.08,0.43l0.01-0.12c0.1,0.18,0.27,0.3,0.33,0.48l-0.12-0.11l0.21,0.33l-0.07-0.22
				c0.5,0.43,0.11-0.08,0.78,1.36c-0.18-0.08-0.09,0.32,0.15,0.38c-0.02-0.02-0.02,0-0.06-0.05c0.16,0.33,0.2,0.48,0.22,0.6
				l-0.04-0.07c0.15,0.44-0.03-0.03,0.68,1.53l-0.02-0.08c0.2-0.01,0.03,0.35,0.19,0.53c-0.2,0.06,0.12,0.16,0.3,0.94l-0.01-0.01
				c0.02,0.01,0.02,0.05,0.02,0.09c-0.14-0.07,0.22,0.17,0.15,0.91l-0.05-0.05c-0.12,0.81,0.46,1.27,0.24,2.18
				c0.01-0.06,0.02-0.09,0.04-0.11c-0.22,0.4,0.25,2.52-0.25,5.18l0.08-0.06c-0.03,0.13-0.04,0.38-0.07,0.5
				C429.88,221,430.2,220.88,429.49,220.48z M430.89,216.02c0-0.02,0.01-0.04,0.02-0.06C430.92,216.01,430.92,216.04,430.89,216.02z
				 M430.84,216.63l0,0.09C430.84,216.69,430.84,216.66,430.84,216.63z M430.98,216.32c0.01,0.04,0.01,0.08-0.01,0.13
				C431,216.47,430.85,216.28,430.98,216.32z M431.17,217.5c-0.13-0.19-0.13-0.13-0.2,0.01c-0.08-0.06-0.08-0.39-0.17-0.31
				c0.08-0.18,0.04-0.16,0.15-0.25c-0.03,0.05-0.05-0.02-0.1-0.19C431.14,216.95,430.97,217.22,431.17,217.5z M429.89,209.72
				c-0.01-0.03-0.02-0.07-0.04-0.14C429.87,209.64,429.85,209.58,429.89,209.72z M429.7,209.02c-0.04-0.05,0.15,0.31-0.02,0.2
				C429.71,209.25,429.7,209.11,429.7,209.02z M435.01,218.16c-0.03,0.07,0-0.24,0.36-0.38
				C435.26,217.9,435.14,218.03,435.01,218.16z M435.48,217.66c0.05-0.08,0.14-0.22,0.27-0.43
				C435.72,217.43,435.79,217.31,435.48,217.66z M440.71,211.78c0.01-0.02,0.03-0.05,0.07-0.1
				C440.76,211.72,440.78,211.68,440.71,211.78z M440.93,211.38c0,0.04,0.09-0.04,0.16-0.08
				C441.1,211.24,440.95,211.56,440.93,211.38z M436.93,223.32c-0.01,0.02,0.01,0.05,0.02,0.08
				C436.93,223.38,436.88,223.39,436.93,223.32z M436.86,222.72c0.01-0.02,0.01-0.03,0.02-0.05
				C436.89,222.67,436.92,222.7,436.86,222.72z M436.84,223.37c-0.01,0,0,0-0.03,0C436.82,223.37,436.83,223.37,436.84,223.37z
				 M434.52,222.36c0.07-0.04,0.13-0.05,0.09,0.05C434.57,222.4,434.54,222.38,434.52,222.36z M434.7,222.47
				C434.7,222.47,434.7,222.47,434.7,222.47C434.7,222.47,434.7,222.47,434.7,222.47z M434.82,222.5c-0.1-0.1-0.13,0.05,0.24-0.09
				c-0.03,0.03,0.04,0.1-0.07,0.09c0.04,0.01,0.08,0,0.12,0C435.06,222.6,434.95,222.51,434.82,222.5z M439.3,228.94
				c0.02,0,0,0,0.06,0C439.39,228.98,439.4,229.1,439.3,228.94z M439.16,229.84c-0.03-0.01,0,0.08,0,0.15
				C439.19,230.01,438.99,229.8,439.16,229.84z M438.97,229.65c-0.03-0.03,0,0-0.05-0.08C438.93,229.58,438.94,229.61,438.97,229.65
				z M434.39,224.68c0.02,0.01,0.05,0.01,0.06,0.02c-0.03,0.01-0.04,0.01-0.05,0.02C434.4,224.71,434.4,224.69,434.39,224.68z
				 M434.42,224.79C434.42,224.79,434.43,224.79,434.42,224.79C434.42,224.79,434.42,224.79,434.42,224.79z M434.31,244.19
				c-0.01,0.04-0.02,0.1-0.04,0.21C434.28,244.31,434.27,244.4,434.31,244.19z M434.21,244.98c-0.01-0.05-0.07,0.12-0.12,0.24
				C434.1,245.12,434.13,244.81,434.21,244.98z M424.12,268.23c-0.01,0-0.01,0-0.01,0.01c0.01-0.02,0.02-0.03,0.02-0.04
				L424.12,268.23z"
                />
                <path
                  className="st10"
                  d="M324.86,288.21c0.44-0.51-0.44,1.01,0.71,2.06c-0.03,0-0.06,0.03-0.07,0.01c-0.03,0.1,0.14,0.17,0.19,0.12
				c-0.05-0.11,0.18,0.35,0.63,0.44c-0.09,0.13,0.08,0.2,0.17,0.16c-0.04,0.03,0.02,0.07-0.03,0.12c0.07,0.03,0.14,0.03,0.16-0.04
				c0.04,0.23,0.3-0.03,0.27,0.22c0.23,0,0.42,0,0.61,0.01c-0.22,0.24-0.48,0.1,0.67,0.17c0,0.01-0.02,0.03-0.04,0.04
				c0.03,0,0.07,0.03,0.07-0.04l-0.03,0.02c-0.03-0.11,0.14-0.2,0.15-0.26c-0.07,0.2,0.14,0.07,0.04,0.32
				c0.02-0.01,0.05-0.04,0.05-0.09c0.24,0.13-0.02,0.02,0.83,0.05l-0.04-0.06c0.07,0,0.13-0.1,0.19-0.06l-0.05,0.06l0.13-0.03
				l-0.08-0.04c0.13-0.1-0.05-0.22,0.32,0.04c0.02-0.11,0.16-0.05,0.19-0.17c-0.01,0.03,0,0.1,0,0.11l0.02-0.09
				c0.08,0.06,0.07-0.02,0.33,0.02l-0.04,0.03l0.05,0.05l0-0.07c0.02,0.14,0.23-0.2,0.63-0.2c-0.01,0.02,0,0.11,0,0.08
				c0.11,0,0.19-0.1,0.32-0.2c-0.09,0.05,0.24,0.14,0.22-0.09c0.17,0.05,0.29,0.25,0.61-0.39c-0.04,0.08,0.06,0.12,0.1,0.14
				c-0.02-0.02-0.02-0.05-0.03-0.07c0.14,0.16-0.01-0.14,0.09-0.07c0.01-0.02,0.14-0.19,0.09,0.02c0.14-0.11-0.01,0.01,0.32-0.04
				c-0.15-0.26-0.01-0.01,0.08-0.32c0.01,0.16-0.02,0.06,0.18,0.23c0-0.1,0.11,0.01,0.1-0.13c0.01,0.02,0.03,0.01,0.05,0.02
				c-0.03-0.05,0.08-0.17,0.5-0.61c0.1,0.19,0.06-0.22,0.15,0.08c0.02-0.06,0.02-0.14,0.04-0.2c0.05,0.03,0.03,0.09,0.04,0.13
				c-0.02-0.01,0.1-0.11-0.01-0.23c0.1,0.24-0.09-0.22-0.2,0.21l-0.07-0.13c0.06,0.22-0.05,0.05-0.19,0.12
				c-0.01-0.03-0.07-0.08-0.03-0.08c-0.14,0.05-0.24,0.09-0.11,0.36l-0.21-0.18l0.05,0.1c-0.01,0-0.04,0.01-0.06-0.02
				c0,0.09,0.03,0.03,0.05,0.11c-0.11-0.04-0.13,0.14-0.24,0.01c0.02,0.08,0.09,0.02,0.06,0.1c-0.09-0.2-0.1,0.19-0.21-0.09
				c0.03,0.22-0.21-0.1-0.27,0.26c-0.01-0.06-0.01,0.04-0.28-0.01c0.04,0.12-0.04,0.14-0.08,0.19c-0.03-0.07-0.03-0.1-0.02-0.15
				c-0.04,0.03-0.05,0.02-0.08,0.12l-0.03-0.09c-0.07,0.03-0.09,0.16-0.21,0.14c0.03,0.09,0.17,0.2,0.1,0.3
				c-0.02-0.04-0.06-0.12-0.04-0.15c0,0.02-0.16,0-0.08-0.08c-0.04,0-0.1-0.02-0.14-0.06c0.04,0.12-0.15-0.09-0.27,0.25l0,0
				c-0.06,0.16-0.34-0.03-0.79,0.17c0.04-0.07-0.03-0.05,0.05-0.06c-0.08-0.01-0.11-0.06-0.17,0.05c0.01-0.09-0.08-0.1-0.11-0.07
				l0.06,0.05c-0.23-0.14-0.66,0.31-0.83,0.02l-0.01,0.04c-0.04-0.04-0.13-0.03-0.11-0.1c-0.05,0.04-0.02,0.07,0.02,0.09
				c-0.09-0.05-0.14,0-0.23-0.03c0.04,0.08-0.31,0.29-0.2-0.07c-0.08,0.08-0.15-0.05-0.2-0.07l0.04-0.03
				c-0.03,0.02-0.05,0.02-0.07,0.02l0.02,0.08c-0.02-0.02-0.05-0.02-0.04-0.05c-0.03,0.05,0.01,0.14-0.04,0.21
				c-0.03-0.04-0.11,0.04-0.11-0.07c0.14,0.05,0.06-0.41-0.12-0.05c0.02-0.05,0-0.09-0.02-0.1c0.01,0.03-0.02,0.12-0.16,0.15
				c0.03,0.05,0.06,0.05-0.07,0.09c0-0.05,0.05-0.03,0.03-0.05c-0.07,0.1-0.1-0.13-0.19-0.07c0-0.01-0.05-0.35-0.23-0.07l0.15-0.03
				l-0.11,0.1c0.04,0.01,0.1-0.03,0.12-0.01c-0.06,0.06-0.05,0.04-0.04,0.11c-0.34-0.01,0.14-0.32-0.44-0.28
				c0.01,0.06,0.05,0.1-0.1,0.05c0.03-0.06,0.01-0.1,0.01-0.14c-0.02,0.05-0.08-0.04-0.15,0.09c-0.06-0.13,0.02-0.19-0.29-0.04
				c0.06-0.05,0.04-0.12,0.12-0.14c-0.12,0.01,0.03,0-0.36,0.09c0.01-0.19,0-0.15-0.43-0.46c0.02-0.01,0.02-0.02,0.03-0.01
				c-0.32-0.12,0.22-0.08-0.32-0.41c0,0,0.02-0.01,0.03-0.02c-0.13,0.02-0.22-0.06-0.29-0.13c0.26-0.01,0.12-0.23-0.22-0.75
				c0.1,0.04,0.17,0.11,0.24,0.15c-0.01-0.04-0.03-0.13-0.1-0.14c0.01,0.06,0.01,0.07-0.14-0.14c0.26,0.01,0.07-0.21,0.29-0.24
				c-0.08-0.15,0.1-0.23,0.14-0.36c-0.07,0.04-0.21-0.26-0.02-0.09c-0.02-0.02-0.03-0.06-0.07-0.08c0.06-0.03,0.18-0.03,0.34,0.06
				c-0.07-0.04-0.08-0.09-0.03-0.1c-0.1,0.05,0.04-0.2,0.09-0.05c0.01-0.07,0.13-0.14,0.07-0.3c0.02,0.01,0.07,0.05,0.06,0.08
				c0.44-0.26,0.36-0.47,1.85-0.72c0.09-0.17,0.1-0.03,0.92-0.24c0.05,0.12-0.02,0.12,0.3,0.1l-0.03,0.07
				c0.2-0.2-0.01-0.21,0.58-0.15c0,0.02-0.01,0.06-0.03,0.07c0.06,0.01,0.23,0.11,0.22-0.09c-0.01,0.13,0.14,0.02,0.21-0.03
				c-0.06,0.25,0.01-0.06,0.81,0.1c-0.09-0.02-0.16-0.04-0.15-0.04c-0.1,0.1,0.3,0.33,0.47,0.08c0.01,0.01-0.02,0-0.05,0.02l0-0.01
				c-0.03,0-0.06-0.01-0.09-0.01c0.04-0.04,0.08-0.08,0.11-0.02c0.03-0.06,0.07-0.12,0.08-0.14c0.12-0.01,0.38,0.29,1.28,0.5
				c-0.01-0.02-0.02-0.03-0.01-0.05c0.18,0.08,0.09,0.25,0.92,0.37l-0.03,0.1c0.23-0.22,0.31,0.39,0.95,0.35c0,0,0,0,0,0
				c0.12-0.07-0.01-0.02,0.1-0.5l0.07,0.04c0-0.09-0.06-0.05-0.11-0.02c0.02-0.04,0.02-0.07,0.01-0.09l-0.16,0.08
				c0.02-0.06,0.1-0.09,0.07-0.15c-0.07,0.04-0.04,0.14-0.12,0.15c0-0.09,0.04-0.13-0.03-0.1c0.02-0.04,0.04-0.06,0.07-0.06
				c-0.07,0.02-0.12-0.04-0.18,0.02c0.01-0.1-0.14-0.05-0.07-0.19c-0.03,0.03-0.13,0.07-0.17,0.05c0.15-0.13-0.13-0.08-0.03-0.25
				c-0.13,0.12-0.22-0.03-0.35,0.01c0.01-0.01,0.03-0.03,0.04-0.02c-0.04-0.04-0.04-0.09-0.06-0.13c-0.06,0.12-0.16,0.11-0.23,0.17
				c-0.01-0.15,0.1-0.13,0.14-0.26c-0.04,0.1-0.16,0.05-0.17,0.15c-0.02-0.1-0.03-0.05-0.01-0.17c-0.08,0.15-0.06,0.01-0.14,0.09
				c-0.02-0.25-0.02,0.06-0.62-0.22c-0.02,0,0.02,0.22-0.12,0.14c0.02,0.01,0.03-0.02,0.05-0.04l-0.05,0.02
				c0.22-0.11-0.09-0.24,0.02-0.13c0.36-0.38-0.56-0.29-1.25-0.31c0.47,0.01-0.04-0.24-2.52-0.13l-0.05-0.12
				c-0.1,0.21,0.03-0.17-0.39,0.11c0-0.04,0.03-0.06,0.05-0.09c-0.06,0.18-0.08-0.15-0.11,0.09c-0.06-0.06-0.16-0.04-0.23,0l0-0.08
				c-0.14,0-0.29,0.09-0.45,0.12c0.07,0.02,0.08,0.15,0.04,0.17l-0.04-0.11c-0.01,0.07,0.01,0.25-0.03-0.03l-0.06,0.06
				c-0.01-0.03,0-0.05-0.01-0.1c-0.01,0.07-0.16-0.08-0.17,0.05c-0.1-0.04-0.57,0.25-0.74,0.49c-0.04-0.08-0.15-0.02-0.12-0.1
				c-0.12,0.08-0.16,0.1-0.28,0.26c0.06,0.03,0.09,0.12-0.03,0.01c-0.09,0.21-0.56,0.08-0.57,0.35l0-0.01
				c-0.04,0.39-0.28,0.04-0.75,1.12C324.91,288.14,325.01,288.14,324.86,288.21z M331.4,286.77
				C331.36,286.78,331.35,286.78,331.4,286.77C331.4,286.78,331.38,286.86,331.4,286.77z M331.38,286.11c-0.01,0-0.02,0-0.04,0
				C331.35,286.11,331.34,286.11,331.38,286.11z M331.22,286.16c0.01-0.02-0.02-0.05-0.04-0.08
				C331.17,286.1,331.29,286.07,331.22,286.16z"
                />
                <path
                  className="st10"
                  d="M322.47,262.83l0.03,0.31c0.15-0.42-0.44-1.36-0.12-1.38c-0.1-0.1,0.15-0.39-0.39-1.66
				c0.24,0.27-0.12-0.63,0.09-0.59c-0.21-0.19-0.26-0.8-0.46-1.17c0.02,0.02,0.06,0.06,0.06,0.09c0.3-0.07-0.76-1.1-0.13-0.95
				c-0.1,0.04-0.25-0.44-0.31-0.32c0.02-0.23-0.01-0.19,0.06-0.33c-0.12,0.21-0.51-1.09-0.12-0.66c-0.56-0.6,0.05-0.09-1.05-2.19
				c-0.14,0.09-0.16,0.17-0.27-0.21c0.48-0.56,0.22,0.01-1.04-2.7l0.18,0.06c-0.87-1.07,0.36-0.11-3.56-6.38l0.08,0.06
				c-0.19-0.35-0.43-0.24-0.57-0.59l0.06,0.04c-0.19-0.24-1.65-2.37-3.56-3.36l0.05-0.09c-0.02,0.05-0.24-0.07-0.61-0.34
				c0.03,0.37,0.04-0.29-1.36-0.56c0.03-0.03,0.16,0,0.27,0.01c-0.75-0.18-0.11-0.06-1.44-0.4l0.08-0.06
				c-1.8-0.37-4.55,1.08-2.5,6.46c-0.01-0.17,0.1-0.15,0.05,0.12c0.12,0.02,0.4,0.86,0.77,1.2c-0.83-0.08-0.63-0.02-3.38-0.15
				l0.04,0.06c-0.16,0.07-0.2-0.02-0.32-0.02c0.1,0.54-3.82-0.37-3.58,3.41c-0.06-0.06-0.11-0.12-0.16-0.19
				c0.33,1.16,1.23,2.09,2.24,2.42c-0.03,0-0.01,0.04-0.12,0.01c0.01,0.01,0.03,0.02,0.04,0.03c-0.05,0.03-0.08,0.05-0.05,0.08
				c-1.37,0.26-1.16-0.03-3.23,1.7c0.01-0.02,0.02-0.03,0.03-0.06c0.16,0.15-1.1,0.63-0.72,3.27c-0.25-0.16,0.1,0.61-0.23,0.35
				c0.03,0.08,0.1,0.17,0.14,0.14c0.03,0.12,0.02,0.17-0.08,0.06c0.2,0.42,0.2,0.24,0.37,0.61c-0.03,0.01-0.08-0.05-0.14-0.08
				c0.15,0.16,0.3,0.31,0.45,0.61c-0.03-0.01-0.03,0.04-0.08-0.05c0.33,0.5,0.71,0.71,1.12,1.18c-0.12-0.03-0.23,0.08,0.36,0.49
				l-0.07-0.14c0.23,0.2,0.51,0.26,0.69,0.44c-0.07-0.02-0.14-0.05-0.21-0.08c0.16,0.11,0.33,0.18,0.49,0.27l-0.25-0.2
				c0.29,0.09,0.06-0.16,0.38,0.07c-0.08-0.02,0.26,0.14,0.37,0.27l0,0c0.64,0.13,0.05,0.32,1.3,0.42
				c-0.19,0.13,0.38,0.26,0.54,0.15c-0.04,0-0.02,0.02-0.1,0.01c0.53,0.06,0.74,0.13,0.9,0.19l-0.15,0
				c0.65,0.13-0.05,0.03,2.32,0.08l-0.12-0.01c0.7-0.17,0.45,0.27,2.19-0.11l-0.08,0.05c1.16-0.14,0.92,0,3.35-0.19
				c-0.19,0.06-0.29,0.07,0.48-0.02c-0.05-0.08-0.08-0.05-0.21-0.1c0.08-0.05,0.25-0.09,0.41-0.1c-0.28,0.06,0.01,0.13,0.13,0.17
				c-0.02-0.02,0.02-0.05,0.01-0.08c0.33,0.14,0.05-0.16,0.5-0.07c-0.03,0.08-0.08,0.15,1.01,0.02c-0.11,0.02-0.26,0.02-0.37,0.05
				c0.25,0.11,0.27,0.09,0.46,0.11l-0.02,0l0.42,0.06l-0.11-0.03c0.32-0.23,0.64-0.02,1.07-0.15c-0.42,0.12-0.07,0.08-0.11,0.18
				c0.11,0.04,0.27,0.08,0.22,0.11c0.2-0.07,0.32,0.09,0.61,0c-0.18,0.02,1.58,0.01,3.63,0.56c-0.15,0.09-0.32,0.13,0.97,0.32
				l-0.14-0.09c-0.01,0.07-0.14,0.17-0.35-0.08l0.16,0.03c-0.1-0.24-0.64-0.18-0.95-0.27c0.09-0.1,0.15-0.17-0.73-0.37
				c0.05-0.02-0.02-0.11,0.12-0.06c-0.39-0.18-0.69-0.04-0.82-0.17l0.04,0c-1.16-0.33-1.13,0-3.4-0.38
				c0.02-0.14,0.12,0.07-0.74,0.01c0.02-0.05-0.7-0.08-1.05-0.12l0.01-0.02c-0.07,0.13-0.43,0.11-0.69,0.14
				c0.01-0.08,0.05-0.11,0.2-0.15c-0.03,0.03-0.03-0.07-0.54,0.09c0.16-0.25-0.11-0.08-1.25,0.19l0.17-0.09
				c-0.07,0.04-0.58,0.03-0.15-0.03c-0.79-0.02-0.33-0.01-1.9,0.17l0,0c-0.55,0.14,0,0-1.69,0.08c-0.01-0.16-0.53,0.04-1.78-0.03
				c0.25-0.05-0.08-0.06,0.26-0.03c-0.32-0.04-0.39-0.1-0.74-0.03c0.12-0.08-0.23-0.13-0.38-0.12l0.22,0.09
				c-0.17-0.01-0.32-0.03-0.47-0.05l0.1-0.11c-0.65-0.2-1.41-0.15-2.86-0.7c0.1,0.39-0.49-0.63-0.5-0.34
				c-0.28-0.26-0.48-0.34-0.74-0.63c-0.01,0.1-1-0.59-0.51-0.65c-0.28-0.22-0.33-0.55-0.41-0.72l0.12,0.13
				c-0.07-0.08-0.12-0.15-0.16-0.23l-0.02,0.13c-0.05-0.1-0.08-0.18-0.05-0.19c-0.22-0.13-0.26,0.13-0.38-0.5
				c0.11,0.39,0.09,0,0.2,0.19c-0.08-0.51,0.03-0.17-0.32-0.71c0.08,0.04,0.08-0.12,0.07-0.18c0.03,0.13-0.19,0.01-0.22-0.5
				l-0.02,0.17c0.1-0.4-0.16-1.27,1.67-2.87l-0.17,0.07c0.39-0.38,0.43-0.16,2.18-1c-0.12,0.1,0.09,0.1-0.19,0.23
				c0.14-0.04,0.28-0.05,0.27-0.09c-0.24,0.18,0.66-0.69,2.44-0.11l0.02-0.18c0.02,0,0.04,0,0.06,0.01c0-0.17-0.05,0.08,0-0.5
				c0.01,0,0.02,0.01,0.04,0.01c-0.05-0.02-0.05,0.03-0.03-0.15c-0.23-0.02-0.09,0.08-1.06-0.03c0.02-0.02,0.01-0.03,0.06-0.01
				c-0.97-0.21-0.04,0.03-1.36-0.55l0.07,0.02c-0.19-0.33-0.32,0.22-1.63-1.19c0.05-0.01,0.08-0.01,0.1-0.02
				c-0.09-0.01-0.29-0.08-0.15-0.54c0.02,0.09,0.05,0.18,0.09,0.27c0.06-0.18-0.12-0.35-0.11-0.51l0.06,0.01
				c-0.03-0.4-0.17-0.44,0.02-0.88l-0.06,0.03c0.26-0.57,0.25-0.21,0.82-0.75c-0.1,0.1-0.2,0.2-0.26,0.3
				c0.12-0.12,0.46-0.34,0.53-0.43c-0.06,0.04-0.12,0.11-0.19,0.13c1.33-0.69-0.02-0.26,4.09-0.51c-0.27-0.21,0.23,0,0.18-0.22
				c0.45-0.06,0.48,0.19,1.17,0.44c-0.1-0.09,0.23-0.09,0.69,0l-0.63-0.17c0.55-0.01,0.12,0.05,2,0.35l-0.22,0.03
				c1.57-0.13,0.1-0.12-1.86-2.99l0.07,0.02c-0.19-0.28-0.03-0.18-0.35-0.78l0.09,0.11c-0.68-2.53-0.36-3,5.36-2.93l-0.06,0.08
				c0.36,0.29,0.54,0.44,0.98,0.8l-0.13-0.02c0.93,0.55,0.47,0.17,1.82,1.71c-0.02,0.01-0.07,0.02-0.13-0.05
				c0.17,0.21,0.54,0.88,0.65,0.69c-0.1,0.08,0.33,0.49,0.55,0.72l-0.11,0.01c0.04,0,0.3,0.27,0.77,0.91l-0.12-0.08
				c0.24,0.41,0.36,0.12,0.51,0.92c-0.08-0.12,0.33,0.3,0.18,0.25c0.13,0.51,0.09-0.08,0.5,1.04c-0.18-0.36-0.33-0.64-0.31-0.62
				c-0.08,0.04,0.06,0.33-0.17,0.17c0.15,0.22,0.06-0.04,0.61,0.87l-0.02-0.02c0.22,0.38,0.06,0.24,0.39,0.91
				c0.08-0.06,0.16,0.14,0.15-0.07c0.01,0.05-0.04-0.07-0.11-0.17l0.01,0.01c-0.05-0.09-0.11-0.22-0.19-0.35
				c0.7,0.87,0.21,0.75,1.35,2.73c-0.24-0.3-0.19-0.31,0.39,0.88l-0.06-0.04c0.18,0.95,0.9,1.31,1.02,2.39
				c-0.01-0.07-0.01-0.11,0-0.13c-0.07,0.53,1.17,2.71,1.56,5.95l0.06-0.09C321.85,262.61,321.51,262.22,322.47,262.83z
				 M297.98,255.77c0.11-0.15,0.19-0.25,0.26-0.32c-0.11,0.15-0.12,0.35-0.18,0.44C298.07,255.7,298.08,255.72,297.98,255.77z
				 M298.18,255.83c0.06-0.06-0.11-0.23,0.23-0.53C298.32,255.48,298.23,255.65,298.18,255.83z M300.99,248.46
				c0.08-0.07,0.16-0.16,0.26-0.27C301.19,248.27,301.09,248.36,300.99,248.46z M317.47,249.82c-0.09-0.04-0.09-0.02-0.11-0.17
				C317.39,249.69,317.34,249.66,317.47,249.82z M317.4,248.45c0.04,0.02-0.02-0.13-0.04-0.23
				C317.3,248.17,317.6,248.53,317.4,248.45z M317.67,248.82c0.04,0.06,0,0,0.08,0.15C317.74,248.94,317.71,248.89,317.67,248.82z"
                />
                <path
                  className="st10"
                  d="M306.23,239.64c0.04-0.01,0.06-0.02,0.09-0.03C306.31,239.6,306.28,239.6,306.23,239.64z"
                />
                <path
                  className="st10"
                  d="M319.02,261.64c-0.05-0.02-0.15-0.12,0-0.21c0,0.02-0.14-0.07,0-0.17c-0.04-0.12-0.07-0.17-0.3-0.01
				l0.17-0.21l-0.09,0.06c0-0.01-0.01-0.03,0.03-0.06c-0.08,0.02-0.03,0.04-0.1,0.07c0.05-0.1-0.12-0.06,0-0.18
				c-0.07,0.04-0.02,0.07-0.09,0.08c0.17-0.13-0.16-0.03,0.07-0.19c-0.19,0.04,0.03-0.19-0.24-0.12c0.05-0.02-0.04-0.01-0.01-0.21
				c-0.1,0.08-0.12,0.02-0.17,0.01c0.06-0.05,0.08-0.07,0.12-0.08c-0.03-0.02-0.02-0.03-0.11-0.01l0.07-0.06
				c-0.04-0.03-0.15,0-0.15-0.09c-0.07,0.06-0.14,0.21-0.23,0.21c0.03-0.04,0.09-0.1,0.12-0.1c-0.01-0.11-0.02,0.07,0.07-0.22
				c-0.03,0.02-0.17,0.12-0.04-0.06c-0.04,0.03-0.03-0.03-0.1,0.04c-0.01-0.01,0.02-0.05,0.04-0.07c-0.05,0.02,0-0.02-0.16,0.04l0,0
				c-0.1,0.19-0.06-0.24-0.41-0.48c0.07,0.01-0.01-0.19-0.01-0.03c-0.09-0.33-0.56-0.12-0.39-0.44l-0.03,0.03
				c0.01-0.05-0.05-0.09,0.01-0.13c-0.05,0.01-0.05,0.04-0.05,0.08c-0.01-0.09-0.08-0.06-0.1-0.13c-0.04,0.09-0.34,0.07-0.07-0.14
				c-0.1,0.03-0.06-0.11-0.08-0.14l0.05-0.01c-0.03,0.01-0.04,0-0.05-0.01l-0.03,0.08c-0.01-0.02-0.02-0.03,0-0.06
				c-0.05,0.03-0.07,0.12-0.14,0.16c0.01-0.05-0.09-0.01-0.03-0.1c0.04,0.08,0.06-0.09,0.12-0.07c-0.05-0.11,0.04-0.12-0.17-0.01
				c0.04-0.04,0.05-0.11,0.04-0.1c0.02,0.02-0.05,0.09-0.1,0.12c-0.01-0.07-0.06,0-0.08-0.02c0,0.05,0.02,0.06-0.09,0.06
				c0.03-0.04,0.04-0.01,0.05-0.03c-0.09,0.07,0-0.14-0.09-0.11c0-0.05,0.06-0.1,0.06-0.13c-0.11,0.09,0-0.19-0.19,0.02l0.12,0.01
				c-0.09,0.15-0.31,0.04-0.36-0.25c-0.03,0.04-0.06-0.04-0.14,0.07c0.16-0.26-0.36,0.09-0.09-0.17c-0.09,0.01,0.02-0.01-0.29,0.11
				c0.05-0.22-0.19-0.09-0.15-0.28c-0.34-0.02-0.11,0.08-0.71-0.21c0.06-0.07,0.05-0.03,0.08-0.12c-0.21,0.33-0.17-0.03-0.35-0.2
				c-0.04,0.02-0.15,0.07-0.21,0.07c0.07-0.08,0.15-0.1,0.21-0.14c-0.03-0.01-0.1-0.04-0.14,0.02c0.04-0.01-0.09,0.23-0.22-0.26
				c-0.14,0.05-0.18-0.12-0.3-0.16c-0.01,0.09-0.22,0.26-0.06-0.02c0,0.08-0.06,0.09-0.07,0.16c-0.03-0.05-0.04-0.16,0.01-0.36
				c-0.02,0.07-0.06,0.09-0.08,0.05c0.06,0.07-0.18,0.03-0.07-0.07c-0.06,0.02-0.16-0.06-0.26,0.08c0.26-0.32-0.36,0.01-1.52-0.53
				l0.01,0.01c0.02-0.14-0.05-0.12-0.09-0.13l-0.05,0.18c-0.04-0.29-0.31,0.03-0.32-0.22c-0.04,0.04-0.07,0.03-0.11,0.07
				c0-0.08,0.07-0.13-0.18-0.16l0.03-0.06c-0.05,0.13-0.22-0.13-0.57,0.07c0.02-0.13-0.09-0.03-0.15,0.02
				c0.02-0.14,0.11-0.11-0.32-0.05c-0.03,0.06-0.12,0.05-0.23-0.04c0.06,0.01,0.11,0.02,0.11,0.03c0.07-0.04-0.26-0.31-0.33-0.03
				c0-0.01,0.01,0,0.04-0.02l0,0.01c0.02,0,0.04,0,0.06,0c-0.03,0.05-0.05,0.09-0.07,0.03c-0.02,0.06-0.04,0.12-0.06,0.15
				c-0.06-0.03-0.08-0.34-0.47-0.11c-0.13-0.36,0.02-0.15-0.44-0.1c0.01,0.02,0.02,0.02,0.02,0.05c-0.08-0.02-0.22-0.22-0.67,0
				l-0.01-0.11c0.17,0.24-0.34-0.16-0.68,0.23c0.01-0.01,0.01,0,0.01-0.01c0.01,0.1,0.02,0.23,0.06,0.45l0.05-0.1l0.02,0.08l-0.05,0
				c0.03,0.07,0.04,0.01,0.06-0.04c0.01,0.04,0.01,0.07,0.02,0.09l0.05-0.16c0.01,0.06-0.02,0.13,0.01,0.16
				c0.03-0.07-0.03-0.14,0.02-0.19c0.03,0.07,0.02,0.13,0.05,0.07c0,0.04,0,0.07-0.01,0.09c0.03-0.05,0.07-0.02,0.09-0.11
				c0.03,0.09,0.09-0.02,0.09,0.13c0.01-0.04,0.05-0.12,0.08-0.13c-0.05,0.18,0.1,0.01,0.08,0.21c0.04-0.17,0.13-0.07,0.19-0.16
				c0,0.01-0.01,0.04-0.02,0.03c0.03,0.01,0.04,0.06,0.06,0.1c0.01-0.14,0.06-0.16,0.1-0.25c0.04,0.13-0.03,0.16-0.02,0.29
				c0-0.11,0.08-0.11,0.07-0.2c0.03,0.08,0.03,0.04,0.04,0.16c0.01-0.17,0.03-0.03,0.06-0.13c0.04,0.09,0,0.16,0.06,0.11
				c0.01,0.24,0.03-0.03,0.34-0.13c0.02,0.06,0,0.05,0.06-0.14c-0.01,0-0.02,0.03-0.03,0.06l0.03-0.03
				c-0.01,0.18-0.12,0.1,0.06,0.18l-0.05-0.05c0.11,0.13,0.17,0.23,0.8,0.09c-0.01,0.03-0.03,0.07-0.01,0.11
				c-0.03-0.14,0.43-0.1,1.14,0.2c0.03-0.09,0.27-0.12,0.35,0.11l0.07-0.1l-0.01,0.13c0.11-0.14-0.06,0.17,0.26-0.01
				c-0.01,0.04-0.04,0.05-0.06,0.07c0.09-0.15,0.01,0.16,0.09-0.05c0.01,0.07,0.07,0.09,0.13,0.07c0,0.11-0.06,0.08,0.25,0.12
				c-0.02-0.04,0.02-0.16,0.05-0.17l-0.02,0.11c0.03-0.06,0.1-0.23,0,0.04l0.05-0.03c-0.01,0.03-0.02,0.05-0.04,0.09
				c0.04-0.06,0.04,0.13,0.1,0.02c-0.41,0.17,1.11,0.06,1.7,0.67c0.01-0.01,0.05-0.15,0.04,0c-0.12-0.28,0.76,0.33,0.65,0.31
				c0.2-0.06,0.28,0.14,0.46,0.11c-0.02,0.02-0.04,0.05-0.05,0.04c-0.01,0.09,0.1,0.06,0.13,0c-0.03,0.02-0.02,0.18,0.14,0.1
				c0.03,0.07,0.11,0.02,0.12,0.09c0.08-0.08,0.06,0.09,0.14-0.04c-0.08,0.13,0.02,0.16,0.09,0.11c-0.03,0.04,0,0.06-0.05,0.11
				c0.03,0.02,0.08,0.01,0.12-0.06c-0.04,0.2,0.21-0.05,0.11,0.18c0.15-0.01,0.27,0,0.38,0.03c-0.05,0.16-0.07,0.06,0.32,0.27
				c-0.01,0.01-0.03,0.02-0.04,0.02c0.02,0.01,0.02,0.04,0.06-0.02l-0.03,0.01c0.04-0.1,0.18-0.14,0.22-0.18
				c-0.14,0.15,0.04,0.1-0.15,0.28c0.02,0,0.05-0.02,0.07-0.06c0.03,0.16,0,0.03,0.32,0.33c-0.01-0.03,0.06,0.07,0.09-0.06
				c-0.04,0.03,0.2,0,0.07,0.06l0.08,0.03c0.01-0.07,0-0.08,0.12-0.14c-0.01,0.09,0.06,0.08-0.01,0.25c0.09-0.07,0.1,0.04,0.19-0.03
				c-0.02,0.02-0.07,0.07-0.07,0.08l0.07-0.06c-0.07,0.11,0.02,0.1,0.02,0.07c-0.04,0.04,0.3,0.12,0.05,0.21
				c0.09,0.04,0.26,0.01,0.31,0.1c0.12-0.19,0-0.01,0.08,0.06c-0.02,0-0.08,0.07-0.06,0.05c0.03,0.07,0.13,0.06,0.24,0.08
				c-0.03-0.02-0.02,0.24,0.12,0.09c-0.04,0.14-0.05,0.36,0.41,0.24c-0.07,0.01-0.09,0.09-0.11,0.14c0.02-0.02,0.04-0.03,0.06-0.05
				c-0.13,0.17,0.14-0.07,0.07,0.04c0.03,0,0.16,0.03-0.01,0.07c0.1,0.07-0.01-0.03,0.05,0.22c0.22-0.21,0.01-0.01,0.27-0.05
				c-0.14,0.07-0.03-0.02-0.2,0.21c0.08-0.04-0.01,0.08,0.11,0.03c-0.02,0.02,0.02,0.03-0.05,0.08c-0.14-0.23,0.46,0.04,0.39,0.34
				c0.05,0.01,0.12-0.01,0.16,0c-0.04,0.04-0.08,0.04-0.12,0.06C318.85,261.66,318.91,261.73,319.02,261.64z M309.37,255.88
				c0.01-0.02,0.01-0.04,0.01-0.05C309.39,255.82,309.42,255.85,309.37,255.88z M309.49,256.46c-0.01,0.02,0.01,0.04,0.02,0.07
				C309.49,256.52,309.45,256.53,309.49,256.46z"
                />
                <path
                  className="st10"
                  d="M321.46,263.75c0.02-0.02,0.07,0.11,0.22,0.04c-0.25,0.04,0.23-0.03-0.13-0.21l0.14-0.04
				c-0.17,0.08-0.08-0.18,0.03-0.21c-0.01-0.14-0.03-0.24-0.32-0.15l0.21-0.17l-0.1,0.03c0-0.01-0.01-0.04,0.04-0.05
				c-0.09-0.01-0.04,0.03-0.11,0.03c0.06-0.1-0.11-0.13,0.04-0.22c-0.08,0.01-0.03,0.08-0.11,0.04c0.21-0.07-0.16-0.1,0.11-0.18
				c-0.22,0.01,0.13-0.19-0.23-0.26c0.06-0.01-0.04-0.01,0.03-0.26c-0.12,0.04-0.13-0.04-0.18-0.07c0.07-0.03,0.1-0.04,0.15-0.02
				c-0.03-0.03-0.02-0.05-0.12-0.07l0.09-0.03c-0.03-0.06-0.16-0.08-0.14-0.18c-0.08,0.03-0.19,0.17-0.29,0.11
				c0.04-0.02,0.12-0.06,0.14-0.05c-0.01-0.01,0-0.14,0.08-0.08c0-0.04,0.01-0.1,0.05-0.13c-0.04,0.01-0.21,0.04-0.02-0.09
				c-0.05,0.01-0.03-0.06-0.12-0.01c-0.01-0.02,0.03-0.04,0.05-0.06c-0.06,0,0,0-0.17-0.05l0,0c-0.19,0.03,0.01-0.15-0.29-0.68
				c0.08,0.02,0.04-0.04,0.06,0.03c0-0.07,0.04-0.11-0.08-0.14c0.07,0.06,0.05-0.22,0-0.04c0.06-0.27-0.47-0.46-0.28-0.72
				l-0.04,0.01c0.02-0.05-0.02-0.13,0.05-0.14c-0.06-0.02-0.07,0.01-0.07,0.06c0.01-0.1-0.06-0.12-0.07-0.21
				c-0.06,0.07-0.38-0.12-0.03-0.2c-0.11-0.03-0.02-0.15-0.03-0.2l0.05,0.02c-0.03-0.01-0.04-0.03-0.05-0.05l-0.06,0.06
				c0-0.03-0.01-0.05,0.02-0.06c-0.06,0-0.11,0.08-0.2,0.08c0.02-0.05-0.09-0.06,0-0.13c0.03,0.14,0.38-0.18-0.03-0.12
				c0.05-0.02,0.07-0.05,0.07-0.07c-0.02,0.02-0.11,0.06-0.22-0.03c-0.02,0.05,0,0.08-0.11,0c0.04-0.03,0.05,0.02,0.06-0.01
				c-0.12,0.01,0.04-0.15-0.06-0.18c0.02-0.05,0.1-0.06,0.11-0.1c-0.15,0.03,0.08-0.2-0.21-0.1l0.12,0.09l-0.15-0.01
				c0.02,0.04,0.09,0.05,0.09,0.07c-0.08,0-0.06-0.01-0.11,0.04c-0.22-0.23,0.34-0.13-0.1-0.49c-0.03,0.05-0.02,0.11-0.11-0.02
				c0.06-0.03,0.07-0.07,0.1-0.11c-0.04,0.03-0.05-0.08-0.17-0.02c0.03-0.13,0.13-0.14-0.21-0.18c0.08-0.02,0.1-0.08,0.18-0.05
				c-0.1-0.05,0.03,0.01-0.35-0.09c0.08-0.15,0.08-0.17-0.23-0.55c0.02-0.01,0.03-0.02,0.03-0.01c-0.29-0.12,0.23-0.13-0.28-0.44
				c0,0,0.02-0.01,0.03-0.02c-0.13,0.02-0.23-0.04-0.3-0.09c0.17-0.1,0.13-0.05,0.05-0.19c0.46-0.05-0.59-0.09-0.24-0.32
				c-0.05-0.01-0.19-0.03-0.26-0.07c0.11-0.03,0.2,0,0.28,0.01c-0.02-0.03-0.09-0.1-0.16-0.08c0.04,0.04,0.05,0.05-0.19-0.03
				c0.22-0.16-0.08-0.21,0.04-0.38c-0.17-0.05-0.15-0.25-0.28-0.37c0.01,0.08-0.31,0.1-0.1-0.02c-0.03,0.02-0.07,0.01-0.1,0.04
				c0-0.07,0.03-0.18,0.13-0.33c-0.05,0.06-0.1,0.05-0.11-0.01c0.04,0.12-0.21-0.09-0.05-0.11c-0.07-0.02-0.16-0.17-0.33-0.09
				c0.01-0.03,0.04-0.08,0.07-0.07c-0.63-0.65-0.44-0.32-1.49-1.42c0.02-0.01-0.11,0.16-0.16,0l0.01,0.01
				c0.07-0.13-0.02-0.14-0.06-0.18l-0.12,0.14c0.04-0.31-0.38-0.17-0.3-0.41c-0.06,0.02-0.1-0.01-0.15,0.01
				c0.04-0.11,0.1-0.08-0.16-0.25l0.06-0.04c-0.28,0.05-0.1,0.16-0.55-0.18c0.01-0.02,0.04-0.05,0.06-0.05
				c-0.05-0.04-0.14-0.21-0.23-0.02c0.07-0.11-0.1-0.08-0.19-0.06c0.15-0.18-0.01,0.02-0.66-0.37c0.08,0.04,0.13,0.08,0.13,0.08
				c0.13-0.05-0.19-0.4-0.4-0.2c-0.01-0.01,0.02,0.01,0.05,0l-0.01,0.01c0.02,0.01,0.05,0.02,0.08,0.03
				c-0.05,0.03-0.09,0.06-0.1-0.01c-0.04,0.05-0.09,0.1-0.11,0.12c-0.1,0.02-0.29-0.42-1.1-0.61c0.01,0.02,0.01,0.03,0.01,0.05
				c-0.14-0.06-0.14-0.27-0.87-0.25l0.01-0.11c0.11,0.26-0.38-0.28-0.95,0.01c0.01,0,0.01,0,0.01-0.01
				c-0.01,0.1-0.02,0.23-0.02,0.45l0.09-0.08l0.01,0.08l-0.06-0.01c0.02,0.08,0.05,0.02,0.09-0.02c0,0.04,0,0.07,0.01,0.09l0.1-0.14
				c-0.01,0.06-0.06,0.12-0.02,0.16c0.05-0.06,0-0.15,0.07-0.18c0.02,0.08-0.01,0.14,0.05,0.08c0,0.04-0.02,0.07-0.04,0.08
				c0.05-0.04,0.1,0,0.14-0.08c0.01,0.1,0.13,0.01,0.09,0.16c0.02-0.03,0.09-0.1,0.13-0.1c-0.11,0.16,0.12,0.05,0.05,0.23
				c0.09-0.15,0.18-0.03,0.28-0.09c-0.01,0.01-0.02,0.03-0.03,0.02c0.04,0.03,0.04,0.08,0.06,0.11c0.05-0.13,0.12-0.14,0.19-0.2
				c0.01,0.14-0.08,0.14-0.1,0.27c0.03-0.11,0.13-0.07,0.14-0.17c0.02,0.09,0.02,0.05,0.01,0.16c0.06-0.16,0.05-0.01,0.11-0.1
				c0.03,0.1-0.04,0.16,0.04,0.13c-0.15,0.17,0.28,0.12,0.59-0.09c-0.02-0.01-0.03,0.02-0.05,0.04l0.04-0.02
				c-0.19,0.1,0.04,0.23-0.03,0.12c0.06,0.55-0.1,0.17,2.77,1.59l-0.05,0.12c0.23-0.08-0.18,0.06,0.3,0.15
				c-0.03,0.03-0.06,0.03-0.09,0.04c0.16-0.09-0.05,0.16,0.12,0c-0.01,0.07,0.06,0.13,0.13,0.15c-0.05,0.13-0.07,0.04,0.26,0.26
				c-0.01-0.06,0.08-0.15,0.12-0.13l-0.06,0.1c0.06-0.04,0.19-0.16-0.01,0.04l0.07,0.01c-0.02,0.03-0.04,0.03-0.07,0.06
				c0.07-0.04,0,0.15,0.11,0.08c0.02,0.09,0.42,0.28,0.65,0.31c-0.2,0.22,0.62,0.56,1.05,1.48c0.02-0.01,0.11-0.11,0.04,0.03
				c0.11-0.46,0.22,0.54,1.06,1.2c-0.03,0.01-0.06,0.03-0.07,0.01c0.03,0.02,0.12,0.15,0.53,0.55c-0.14,0.08-0.03,0.18,0.06,0.18
				c-0.05,0.01-0.02,0.06-0.08,0.08c0.03,0.04,0.08,0.07,0.15,0.02c-0.11,0.19,0.24,0.09,0.06,0.27c0.21,0.17,0.19,0.02,0.65,0.81
				c-0.01,0.01-0.03,0-0.05,0c0.02,0.02,0.01,0.06,0.07,0.02l-0.03-0.01c0.07-0.09,0.24-0.02,0.29-0.05
				c-0.2,0.07,0.02,0.13-0.24,0.2c0.02,0.01,0.06,0.01,0.1-0.02c-0.01,0.22,0,0.04,0.24,0.58c0-0.04,0.04,0.11,0.11-0.01
				c0.03,0.05,0.14,0.06,0.12,0.12l-0.07-0.01l0.07,0.09l0.01-0.08c0.13,0.05,0.19-0.08,0.06,0.27c0.11-0.03,0.09,0.11,0.21,0.09
				c-0.03,0-0.09,0.03-0.1,0.04l0.09-0.02c-0.03,0.08,0.04,0.05,0.06,0.28l-0.04-0.03l-0.03,0.06l0.07-0.02
				c-0.13,0.05,0.27,0.15,0.3,0.48c-0.02-0.01-0.1,0.03-0.08,0.02c0.01,0.09,0.12,0.14,0.23,0.23c-0.04-0.04-0.08,0.25,0.1,0.18
				c-0.04,0.17-0.24,0.27,0.38,0.51c-0.07-0.03-0.12,0.06-0.15,0.1c0.02-0.01,0.05-0.02,0.07-0.03c-0.17,0.13,0.15-0.01,0.06,0.08
				c0.02,0.01,0.17,0.12-0.02,0.08c0.09,0.12-0.01-0.01,0.01,0.28c0.27-0.13,0.02-0.01,0.3,0.08c-0.16,0-0.04-0.03-0.25,0.15
				c0.1,0-0.03,0.09,0.11,0.09c-0.03,0.02,0.02,0.04-0.07,0.07c0.19-0.07,0.41,0.33,0.49,0.49c-0.2,0.05,0.18,0.09-0.12,0.12
				c0.05,0.03,0.13,0.04,0.17,0.08C321.54,263.77,321.5,263.74,321.46,263.75z M312.38,252.22c0.01,0,0.02,0,0.03,0.01
				C312.39,252.23,312.41,252.23,312.38,252.22z M312.53,252.21c-0.02,0.02,0,0.05,0.01,0.08
				C312.52,252.27,312.44,252.26,312.53,252.21z M312.55,251.59C312.59,251.59,312.6,251.58,312.55,251.59
				C312.56,251.58,312.59,251.51,312.55,251.59z"
                />
                <path
                  className="st10"
                  d="M300,277.46c0.03,0.09,0.5,0.04,0.26,0.13c0.56-0.08,0.73-0.11,1.38-0.38c-0.04-0.01-0.11-0.04,0.71-0.13
				c-0.43,0.87-0.28,0.48-1.15,3.35l0.06-0.04c0.06,0.16-0.03,0.2-0.03,0.33c0.66-0.35-0.62,3.02,3.73,3.07l-0.18,0.12
				c1.06-0.17,2.16-0.49,2.88-1.25c-0.03,0.06-0.01,0.09-0.09,0.15c0.23-0.07,0.6-0.53,0.54-0.58c0.11,0.01,0.3-0.34,1.03-1.3
				c0.02,0.34,0.06,0.78,0.1,1.39c-0.02-0.03-0.05-0.05-0.07-0.08c0.2,1.05-0.12,0.82,0.99,2.65c-0.01,0-0.02-0.01-0.04-0.02
				c0.12,0.1-0.16-0.18,0.57,0.24c-0.29,0,0.39,0.45-0.02,0.41c0.07,0.05,0.17,0.09,0.18,0.04c0.08,0.09,0.1,0.13-0.03,0.1
				c0.11,0.19,0.55,0.13,0.47,0.32c0.21,0.05,0.41,0.09,0.71,0.24c-0.03,0.01,0,0.05-0.09,0.02c0.56,0.2,0.98,0.11,1.59,0.13
				c-0.17,0.09,0.12,0.22,0.6,0.03l-0.15-0.03c0.3-0.06,0.5-0.25,0.74-0.32c-0.05,0.05-0.12,0.09-0.18,0.14
				c0.17-0.08,0.33-0.17,0.48-0.27c-0.09,0.04-0.2,0.07-0.29,0.11c0.22-0.2-0.1-0.12,0.24-0.28c-0.05,0.05,0.24-0.15,0.4-0.18l0,0
				c0.38-0.51,0.33,0.12,0.89-0.97c0.05,0.22,0.37-0.26,0.31-0.44c-0.01,0.04,0.01,0.03-0.02,0.1c0.19-0.49,0.32-0.66,0.43-0.8
				l-0.04,0.15c0.28-0.58,0.03,0.06,0.5-2.18l-0.03,0.11c-0.07-0.68,0.32-0.38,0.22-2.07l0.04,0.08c0.1-1.1,0.16-0.85,0.55-3.13
				c0.02,0.14,0.01,0.18,0.1-0.45c-0.09,0.03-0.07,0.06-0.16,0.17c-0.03-0.08-0.02-0.26,0.01-0.4c-0.01,0.27,0.13,0.03,0.2-0.07
				c-0.03,0.01-0.05-0.03-0.07-0.03c0.21-0.26-0.15-0.09,0.06-0.48c0.07,0.05,0.12,0.12,0.28-0.92c0,0.1-0.05,0.24-0.05,0.35
				c0.17-0.19,0.16-0.23,0.22-0.39l-0.01,0.02l0.17-0.37l-0.06,0.09c-0.14-0.36,0.15-0.59,0.13-1.02c-0.03,0.19,0.2,0.38,0.46-0.58
				c-0.02,0.17,0.4-1.46,1.34-3.25c0.06,0.17,0.08,0.32,0.46-0.86l-0.11,0.12c0.06,0.02,0.15,0.17-0.14,0.31l0.06-0.15
				c-0.26,0.04-0.27,0.57-0.43,0.85c-0.08-0.1-0.13-0.18-0.5,0.61c-0.01-0.05-0.11-0.01-0.03-0.13c-0.25,0.33-0.18,0.63-0.34,0.72
				l0.01-0.03c-0.25,0.62-0.61,0.94-1.36,3.71c-0.04-0.04-0.26,0.62-0.39,0.93l-0.02-0.02c0.1,0.09,0,0.42-0.04,0.67
				c-0.07-0.03-0.09-0.08-0.09-0.22c0.01,0.04-0.06,0.01-0.05,0.52c-0.2-0.21-0.12,0.08-0.12,1.2l-0.05-0.18
				c0.02,0.08-0.1,0.55-0.07,0.14c-0.2,0.72-0.1,0.29-0.25,1.81l0,0c0.03,0.54,0,0-0.2,1.61c-0.16-0.02-0.03,0.51-0.3,1.66
				c-0.01-0.24-0.07,0.07,0.01-0.24c-0.1,0.29-0.16,0.34-0.16,0.68c-0.05-0.13-0.17,0.18-0.19,0.32l0.13-0.18
				c-0.04,0.16-0.09,0.29-0.15,0.42l-0.08-0.11c-0.41,0.61-0.47,1.25-1.57,2.18c0.37,0.14-0.73,0.07-0.51,0.23
				c-0.33,0.08-0.5,0.19-0.85,0.19c0.07,0.1-1.03,0.3-0.73-0.09c-0.32,0.03-0.54-0.19-0.68-0.28c0.05,0.01,0.1,0.03,0.16,0.03
				c-0.09-0.01-0.16-0.05-0.23-0.09l0.05,0.11c-0.08-0.05-0.15-0.09-0.13-0.12c-0.24,0.02-0.14,0.26-0.55-0.19
				c0.3,0.27,0.05-0.08,0.3,0.06c-0.29-0.3-0.09-0.21-0.64-0.42c0.08-0.01,0.02-0.15-0.03-0.19c0.09,0.09-0.15,0.11-0.45-0.28
				l0.08,0.15c-0.28-0.14-0.41-0.61-0.57-1.75c-0.06,0.11-0.04,0.13-0.17-0.1c0.05,0.19,0.12,0.37,0.19,0.54
				c-0.07-0.11-0.15-0.21-0.21-0.33c0.04,0.17,0.14,0.35,0.16,0.44c-0.12-0.14-0.09-0.14-0.14-0.04c-0.14-0.85,0.2-0.45-0.07-2.04
				l-0.04,0.17c-0.12-0.46,0.12-0.35,0.24-2.25c0.01,0.02,0.02,0.06,0.02,0.15c0.37-0.74-0.17-0.03,0.02-2.62
				c-0.06,0.23-0.29,1.06-0.55,1.97l-0.19-0.06c-0.02,0.18-0.01,0.32,0.06,0.3c-0.35,1.06,0.07,0.1-1.33,2.47
				c-0.01-0.03-0.03-0.02,0.01-0.06c-0.57,0.74,0.02,0.05-1.03,0.93l0.05-0.05c-0.35,0.08,0.02,0.25-1.68,1.02
				c0-0.04,0.01-0.07,0.01-0.09c-0.03,0.08-0.16,0.23-0.56,0.01c0.1,0,0.19-0.02,0.29-0.03c-0.16-0.1-0.37,0.04-0.52,0.02l0.02-0.05
				c-0.43,0-0.46,0.14-0.92,0l0.03,0.05c-0.59-0.12-0.25-0.22-0.89-0.6c0.12,0.06,0.24,0.13,0.34,0.15
				c-0.14-0.08-0.43-0.34-0.53-0.36c0.06,0.04,0.14,0.08,0.17,0.14c-0.91-1.23-0.58-0.35-0.47-3.53c-0.23-0.5-0.34-0.23,0.81-1.52
				c-0.12,0.15-0.28,0.34-0.35,0.41c0.26-0.7,0.61-0.7,1.01-1.52l-0.07,0.22c0.17-0.32,0.78-1.22,0.9-1.55
				c-0.24,0.14-0.47,0.29-0.66,0.43c-0.01-0.02,0-0.1,0.26-0.24c-0.19-0.04-1.24,0.88-3.05,1.42l0.03-0.06
				c-0.18,0.08-0.07,0.03-0.79,0.22l0.12-0.08c-0.44,0.28-5.77,0.01-1.91-6.17l0.06,0.08c0.36-0.26,0.53-0.38,0.97-0.7l-0.05,0.12
				c0.73-0.73,0.28-0.38,2.02-1.28c0,0.02,0.01,0.07-0.07,0.11c0.24-0.11,0.94-0.33,0.78-0.48c0.06,0.12,0.53-0.2,0.78-0.37
				l-0.01,0.11c0.01-0.04,0.3-0.24,0.98-0.57l-0.1,0.1c0.43-0.15,0.17-0.33,0.95-0.32c-0.13,0.05,0.34-0.26,0.27-0.12
				c0.5-0.03-0.05-0.1,1.06-0.27c-0.37,0.09-0.66,0.18-0.63,0.16c0.03,0.09,0.32,0.01,0.13,0.2c0.23-0.11-0.03-0.06,0.92-0.4
				l-0.03,0.02c0.4-0.12,0.23-0.02,0.92-0.17c-0.03-0.09,0.16-0.12-0.04-0.16c0.04,0-0.07,0.02-0.18,0.07l0.01-0.01
				c-0.1,0.02-0.22,0.06-0.37,0.09c0.95-0.45,0.73-0.05,2.82-0.59c-0.34,0.14-0.33,0.1,0.9-0.13l-0.06,0.05
				c0.91,0.11,1.44-0.48,2.46-0.25c-0.07-0.01-0.1-0.02-0.12-0.04c0.45,0.22,2.83-0.24,5.84,0.39l-0.07-0.08
				c0.23,0.04,0,0.01,1.43,0.36c-0.02,0.03-0.03,0.02-0.02,0.11l-0.05-0.08c-0.04,0.04-0.02,0.15-0.11,0.14
				c0.05,0.07,0.19,0.17,0.17,0.26c-0.03-0.04-0.08-0.1-0.08-0.13c-0.11,0,0.06,0.02-0.21-0.1c0.02,0.03,0.09,0.19-0.07,0.03
				c0.02,0.05-0.04,0.03,0.02,0.11c-0.01,0.01-0.04-0.03-0.07-0.05c0.01,0.05-0.02,0,0.01,0.17l0,0c0.17,0.12-0.24,0.02-0.57,0.33
				c0.03-0.07-0.19-0.03-0.04,0c-0.34,0.02-0.26,0.54-0.53,0.3l0.02,0.04c-0.05-0.02-0.1,0.03-0.13-0.03
				c-0.01,0.05,0.03,0.06,0.07,0.06c-0.09-0.01-0.08,0.06-0.16,0.07c0.08,0.06-0.02,0.35-0.16,0.03c0,0.1-0.12,0.03-0.16,0.04
				l0-0.05c0,0.03-0.02,0.04-0.03,0.05l0.07,0.05c-0.02,0-0.04,0.01-0.06-0.02c0.02,0.05,0.1,0.1,0.12,0.18
				c-0.05-0.02-0.03,0.08-0.11,0c0.08-0.02-0.08-0.08-0.04-0.14c-0.12,0.03-0.1-0.07-0.06,0.17c-0.03-0.05-0.09-0.08-0.09-0.06
				c0.03-0.02,0.08,0.07,0.09,0.13c-0.07-0.01-0.02,0.06-0.04,0.07c0.05,0.02,0.06,0,0.03,0.1c-0.03-0.04,0-0.05-0.02-0.05
				c0.04,0.11-0.14-0.03-0.14,0.06c-0.05-0.02-0.08-0.09-0.11-0.1c0.05,0.13-0.19-0.06-0.04,0.19l0.04-0.11
				c0.04,0.19,0.16,0.14-0.25,0.26c0.05,0.02,0.1,0.02,0.01,0.1c-0.04-0.05-0.07-0.06-0.11-0.09c0.03,0.04-0.06,0.04,0.02,0.15
				c-0.19-0.23-0.03,0.38-0.19,0.04c-0.02,0.09,0-0.02,0,0.32c-0.2-0.11-0.15,0.15-0.33,0.06c-0.15,0.32,0.04,0.13-0.47,0.62
				c-0.04-0.08-0.01-0.05-0.08-0.11c0.23,0.32-0.1,0.15-0.33,0.27c0,0.05,0.01,0.17-0.02,0.23c-0.04-0.1-0.03-0.18-0.04-0.25
				c-0.02,0.02-0.07,0.08-0.04,0.14c0-0.05,0.19,0.18-0.33,0.11c-0.01,0.15-0.19,0.12-0.28,0.22c0.07,0,0.13,0.29,0,0.09
				c0.02,0.03,0.02,0.07,0.05,0.09c-0.06,0-0.16-0.04-0.3-0.15c0.06,0.05,0.06,0.1,0.01,0.1c0.09-0.03-0.05,0.18-0.09,0.04
				c-0.01,0.06-0.13,0.12-0.05,0.28c-0.19-0.39-0.15,0.35-1.22,1.15l0.01-0.01c-0.11-0.08-0.13-0.02-0.16,0.01l0.13,0.14
				c-0.27-0.1-0.15,0.29-0.36,0.17c0.01,0.05-0.01,0.08,0,0.13c-0.08-0.04-0.07-0.12-0.23,0.08c-0.17-0.25-0.17,0.17-0.3,0.47
				c-0.1-0.09-0.08,0.06-0.07,0.14c-0.11-0.1-0.03-0.15-0.23,0.25c0.04,0.06-0.03,0.12-0.17,0.17c0.05-0.05,0.09-0.08,0.09-0.07
				c0-0.09-0.41,0.03-0.23,0.26c-0.01,0,0.01-0.01,0-0.04l0.01,0.01c0.01-0.01,0.02-0.03,0.04-0.05c0.02,0.05,0.04,0.09-0.02,0.08
				c0.04,0.05,0.07,0.11,0.09,0.13c-0.07,0.03-0.32-0.13-0.38,0.34c-0.38-0.11-0.11-0.11-0.36,0.3c0.02,0,0.03,0,0.05,0.01
				c-0.07,0.06-0.3,0.03-0.44,0.55l-0.09-0.06c0.27,0.01-0.34,0.17-0.29,0.69c0-0.01,0-0.01,0-0.01c0.08,0.06,0.19,0.14,0.38,0.25
				l-0.04-0.11l0.07,0.04l-0.03,0.04c0.08,0.03,0.04-0.03,0.02-0.07c0.04,0.02,0.06,0.04,0.08,0.04l-0.08-0.15
				c0.05,0.04,0.08,0.1,0.13,0.09c-0.04-0.07-0.13-0.07-0.13-0.14c0.08,0.03,0.12,0.07,0.09,0.01c0.03,0.02,0.05,0.05,0.06,0.07
				c-0.02-0.06,0.04-0.07-0.02-0.14c0.09,0.04,0.05-0.09,0.16,0.01c-0.02-0.03-0.06-0.12-0.04-0.14c0.11,0.16,0.08-0.07,0.22,0.07
				c-0.1-0.14,0.03-0.15,0.01-0.26c0.01,0.01,0.02,0.03,0.01,0.03c0.03-0.02,0.08,0.01,0.12,0.01c-0.1-0.09-0.09-0.16-0.13-0.24
				c0.13,0.05,0.1,0.13,0.21,0.2c-0.09-0.07-0.03-0.13-0.11-0.18c0.08,0.03,0.05,0,0.15,0.07c-0.12-0.12,0-0.04-0.06-0.13
				c0.1,0.02,0.13,0.1,0.13,0.02c0.11,0.2,0.18-0.2,0.06-0.51c-0.01,0.01,0.01,0.03,0.03,0.06l-0.01-0.04
				c0.14,0.11,0,0.16,0.18,0.06l-0.07,0.02c0.26,0.07,0.13-0.44,1.51-1.47c-0.05-0.08,0.03-0.3,0.29-0.26l-0.05-0.11l0.11,0.07
				c-0.06-0.17,0.11,0.14,0.14-0.24c0.03,0.03,0.02,0.06,0.03,0.09c-0.08-0.15,0.14,0.07,0-0.11c0.07,0.02,0.12-0.02,0.14-0.08
				c0.1,0.06,0.04,0.09,0.24-0.18c-0.05,0-0.14-0.1-0.12-0.13l0.09,0.07c-0.04-0.06-0.15-0.2,0.04,0.02l0.01-0.06
				c0.03,0.02,0.03,0.04,0.06,0.08c-0.04-0.06,0.14,0.02,0.07-0.08c-0.06,0.45,0.62-0.99,1.46-1.34c-0.01-0.02-0.12-0.11,0.02-0.04
				c-0.28-0.03,0.7-0.62,0.57-0.52c0.03-0.21,0.25-0.22,0.3-0.41c0.01,0.03,0.03,0.05,0.02,0.06c0.08,0.04,0.1-0.08,0.05-0.13
				c-0.09,0.07,0.28-0.11,0.31-0.36c0.1,0.12,0.16,0.03,0.14-0.05c0.02,0.04,0.06,0.02,0.09,0.08c0.03-0.03,0.04-0.08-0.02-0.13
				c0.18,0.09,0.02-0.22,0.22-0.06c0.04-0.15,0.1-0.27,0.16-0.37c0.14,0.09,0.04,0.09,0.37-0.25c0.01,0.01,0.01,0.03,0.01,0.05
				c0.01-0.02,0.05-0.01,0-0.06l0,0.03c-0.09-0.06-0.08-0.22-0.11-0.26c0.1,0.18,0.11-0.02,0.23,0.21c0-0.02-0.01-0.05-0.04-0.09
				c0.18,0,0.03,0,0.43-0.25c-0.03,0.01,0.09-0.04-0.04-0.1c0.02,0.05,0.05-0.2,0.08-0.06l0.05-0.07c-0.07-0.03-0.08-0.01-0.11-0.14
				c0.09,0.03,0.09-0.04,0.24,0.06c-0.05-0.1,0.07-0.09,0.02-0.2c0.01,0.03,0.05,0.08,0.06,0.09l-0.04-0.08c0.1,0.1,0.1,0,0.07-0.01
				c0.03,0.05,0.2-0.28,0.23-0.01c0.07-0.08,0.07-0.26,0.17-0.29c-0.16-0.15-0.01-0.01,0.08-0.07c0,0.02,0.05,0.09,0.04,0.07
				c0.07-0.02,0.08-0.12,0.13-0.23c-0.03,0.03,0.24,0.05,0.12-0.11c0.14,0.05,0.36,0.1,0.32-0.39c0,0.07,0.08,0.1,0.12,0.13
				c-0.02-0.02-0.03-0.04-0.04-0.06c0.15,0.15-0.05-0.14,0.05-0.06c0.01-0.03,0.05-0.16,0.07,0.01c0.09-0.1-0.04,0.01,0.23-0.03
				c-0.12-0.14-0.04-0.27,0.17-0.06c-0.02-0.09,0.08,0.02,0.05-0.11c0.02,0.03,0.03-0.02,0.07,0.06c-0.03-0.03-0.04-0.06-0.05-0.08
				c0.15,0.28,0.12-0.48,0.45-0.3c0.01-0.05,0-0.12,0.02-0.17c0.04,0.04,0.03,0.09,0.05,0.13c-0.02-0.01,0.06-0.07-0.02-0.19
				c-0.03,0.05-0.14,0.14-0.22-0.01c0.02,0-0.08,0.14-0.18-0.01c-0.13,0.03-0.18,0.06-0.04,0.3l-0.19-0.19l0.05,0.09
				c-0.15-0.11-0.13-0.09-0.46-0.26l0.29,0.06c-0.34-0.26-1.36,0.01-1.28-0.29c-0.13,0.07-0.31-0.26-1.62-0.14
				c0.32-0.14-0.6-0.09-0.5-0.27c-0.24,0.15-0.8,0-1.2,0.06c0.02-0.01,0.07-0.03,0.1-0.02c-0.22-0.03-0.28-0.08-0.42-0.12
				c0.27,0-1.04,0.45-0.48-0.06c0.01,0.11-0.47,0.09-0.38,0.19c-0.2-0.09-0.18-0.05-0.28-0.16c0.16,0.18-1.14,0.15-0.63-0.09
				c-0.71,0.35-0.06-0.08-2.3,0.32c0.04,0.15,0.11,0.21-0.27,0.2c-0.34-0.57,0.1-0.25-2.75,0.23l0.1-0.15
				c-1.1,0.55-1.43-0.12-6.67,2.06l0.07-0.06c-0.36,0.1-0.3,0.36-0.65,0.42l0.05-0.04c-0.24,0.16-2.62,0.95-3.95,2.48l-0.08-0.07
				c0.04,0.03-0.12,0.21-0.45,0.52c0.39,0.06-0.32-0.11-0.73,1.25c-0.03-0.03,0.01-0.16,0.03-0.27c-0.22,0.75-0.1,0.1-0.41,1.45
				l-0.07-0.07C296.54,275.63,295.16,275.11,300,277.46z M307.35,266.74c-0.05,0.07-0.04,0.08-0.18,0.06
				C307.21,266.78,307.18,266.82,307.35,266.74z M312.18,273.48c0-0.01,0.04-0.02,0.03,0.04
				C312.2,273.51,312.19,273.49,312.18,273.48z M312.76,273.75c0.01,0.02,0.04,0.01,0.08,0.02
				C312.81,273.78,312.8,273.83,312.76,273.75z M320.32,266.57c-0.04-0.06-0.05-0.09-0.06-0.13
				C320.31,266.46,320.39,266.43,320.32,266.57z M306.61,266.26c-0.03,0.01-0.08,0.02-0.15,0.05
				C306.52,266.29,306.46,266.31,306.61,266.26z M306.06,266.49c0.03-0.03-0.13-0.01-0.23-0.01
				C305.78,266.52,306.17,266.32,306.06,266.49z"
                />
                <path
                  className="st10"
                  d="M317.36,279.1c0.01-0.01,0.01-0.02,0.02-0.03C317.38,279.09,317.38,279.06,317.36,279.1z"
                />
                <path
                  className="st10"
                  d="M310.67,271.07c0.09,0.07,0.03,0.09,0.24-0.14c-0.05-0.01-0.12-0.11-0.11-0.14l0.08,0.08
				c-0.03-0.06-0.13-0.21,0.03,0.02l0.01-0.06c0.02,0.03,0.03,0.05,0.05,0.08c-0.03-0.07,0.13,0.04,0.07-0.07
				c-0.09,0.44,0.68-0.87,1.5-1.03c-0.01-0.01-0.06-0.08-0.03-0.07c-0.01-0.11,0.73-0.42,0.66-0.35c0.05-0.2,0.27-0.16,0.33-0.33
				c0.01,0.03,0.02,0.06,0.01,0.06c0.07,0.06,0.1-0.05,0.07-0.11c0,0.04,0.14,0.11,0.16-0.07c0.07,0.01,0.07-0.09,0.14-0.06
				c-0.03-0.11,0.11,0,0.04-0.14c0.07,0.14,0.15,0.06,0.14-0.02c0.01,0.05,0.05,0.03,0.07,0.09c0.03-0.02,0.05-0.06,0.01-0.13
				c0.16,0.13,0.06-0.2,0.21-0.01c0.06-0.13,0.14-0.23,0.21-0.32c0.11,0.12,0.02,0.1,0.39-0.15c0.01,0.01,0,0.03,0,0.05
				c0.01-0.01,0.05,0,0.01-0.06l-0.01,0.03c-0.07-0.08-0.03-0.23-0.05-0.28c0.06,0.2,0.1,0.01,0.17,0.26c0-0.02,0-0.05-0.02-0.1
				c0.16,0.05,0.02,0.01,0.44-0.13c-0.03,0,0.09-0.02-0.02-0.1c0.01,0.06,0.09-0.18,0.08-0.03l0.06-0.06
				c-0.06-0.04-0.07-0.03-0.07-0.17c0.08,0.05,0.09-0.01,0.21,0.12c-0.03-0.11,0.08-0.07,0.06-0.19c0,0.03,0.03,0.09,0.04,0.1
				l-0.02-0.09c0.07,0.12,0.1,0.03,0.07,0.01c0.02,0.06,0.24-0.21,0.21,0.05c0.08-0.06,0.12-0.22,0.22-0.23
				c-0.12-0.19-0.01-0.01,0.09-0.05c-0.01,0.02,0.03,0.1,0.02,0.08c0.07,0,0.1-0.09,0.17-0.18c-0.03,0.02,0.2,0.12,0.13-0.07
				c0.11,0.09,0.3,0.2,0.38-0.27c-0.02,0.06,0.05,0.12,0.08,0.16c-0.01-0.02-0.02-0.05-0.03-0.07c0.1,0.19-0.01-0.15,0.06-0.05
				c0.02-0.03,0.09-0.14,0.06,0.04c0.1-0.06-0.03,0,0.21,0.04c-0.1-0.29-0.01-0.01,0.06-0.27c0.01,0.16-0.03,0.02,0.11,0.26
				c0-0.09,0.07,0.05,0.07-0.09c0.01,0.03,0.03-0.01,0.05,0.08c-0.26,0.05,0.21-0.42,0.46-0.23c0.02-0.04,0.04-0.11,0.06-0.15
				c0.03,0.05,0.01,0.09,0.01,0.13c-0.01-0.02,0.06-0.05,0.03-0.18c-0.04,0.04-0.16,0.08-0.19-0.08c0.02,0.01-0.11,0.1-0.16-0.07
				c-0.13-0.01-0.18,0-0.12,0.27l-0.13-0.24l0.03,0.1c-0.01,0-0.03,0-0.04-0.04c-0.01,0.08,0.02,0.04,0.02,0.11
				c-0.07-0.08-0.1,0.08-0.17-0.08c0.01,0.08,0.06,0.04,0.03,0.11c-0.05-0.21-0.08,0.13-0.14-0.14c-0.04,0.19-0.16-0.09-0.2,0.17
				c0-0.06-0.02,0.03-0.2-0.07c0.03,0.12-0.03,0.12-0.05,0.16c-0.03-0.07-0.03-0.1-0.02-0.14c-0.03,0.02-0.03,0.01-0.05,0.1
				l-0.02-0.09c-0.05,0.02-0.06,0.14-0.14,0.1c0.03,0.09,0.14,0.22,0.09,0.3c-0.02-0.04-0.05-0.12-0.04-0.15
				c-0.11-0.03,0.05,0.05-0.17-0.16c0.01,0.03,0.04,0.21-0.07,0.01c0.01,0.05-0.04,0.02-0.01,0.11c-0.01,0.01-0.03-0.04-0.05-0.06
				c0,0.05-0.02,0-0.03,0.16l0,0c0.13,0.17-0.24-0.05-0.6,0.16c0.04-0.06-0.17-0.08-0.03-0.01c-0.33-0.06-0.35,0.45-0.55,0.15
				l0.01,0.04c-0.04-0.03-0.1,0-0.11-0.07c-0.02,0.05,0.01,0.07,0.05,0.08c-0.08-0.03-0.09,0.04-0.16,0.03
				c0.06,0.08-0.09,0.33-0.15-0.01c-0.02,0.1-0.12,0-0.16,0l0.01-0.04c-0.01,0.03-0.02,0.03-0.04,0.04l0.05,0.07
				c-0.02-0.01-0.04,0-0.05-0.03c0.01,0.06,0.07,0.12,0.07,0.2c-0.04-0.03-0.04,0.07-0.1-0.02c0.08,0-0.06-0.1-0.01-0.14
				c-0.12,0-0.08-0.09-0.09,0.15c-0.02-0.06-0.07-0.1-0.07-0.08c0.03-0.01,0.06,0.09,0.06,0.14c-0.06-0.02-0.03,0.05-0.05,0.06
				c0.04,0.03,0.06,0.01,0.01,0.1c-0.02-0.04,0.01-0.04-0.01-0.06c0.02,0.11-0.12-0.06-0.14,0.02c-0.04-0.03-0.05-0.1-0.09-0.12
				c0.02,0.13-0.17-0.1-0.07,0.18l0.06-0.1c0.09,0.15-0.12,0.29-0.39,0.18c0.03,0.04-0.06,0.03,0,0.15
				c-0.14-0.27-0.09,0.36-0.19-0.01c-0.04,0.09,0-0.02-0.05,0.31c-0.17-0.15-0.17,0.11-0.32-0.02c-0.19,0.28,0.02,0.14-0.54,0.49
				c-0.03-0.09,0-0.05-0.06-0.13c0.17,0.35-0.12,0.13-0.35,0.19c-0.01,0.04-0.02,0.17-0.05,0.22c-0.03-0.1,0-0.18,0-0.25
				c-0.03,0.01-0.08,0.06-0.06,0.13c0.01-0.04,0.14,0.21-0.33,0.04c-0.03,0.14-0.2,0.08-0.29,0.16c0.07,0.01,0.09,0.31-0.01,0.08
				c0.01,0.03,0.01,0.07,0.03,0.1c-0.06-0.01-0.15-0.06-0.27-0.2c0.05,0.06,0.04,0.1,0,0.1c0.09-0.02-0.07,0.17-0.09,0.02
				c-0.01,0.06-0.14,0.09-0.08,0.26c-0.13-0.41-0.19,0.31-1.27,0.93l0.01,0c-0.1-0.1-0.12-0.03-0.16-0.01l0.11,0.15
				c-0.26-0.14-0.16,0.26-0.36,0.12c0.01,0.05-0.02,0.07-0.01,0.13c-0.07-0.04-0.06-0.13-0.23,0.05c-0.15-0.27-0.18,0.15-0.31,0.42
				c-0.09-0.1-0.08,0.05-0.07,0.13c-0.1-0.1-0.02-0.16-0.23,0.22c0.03,0.06-0.04,0.12-0.17,0.15c0.05-0.04,0.09-0.07,0.09-0.06
				c0.01-0.08-0.4,0-0.22,0.23c-0.01,0,0.01,0,0-0.04l0,0.01c0.01-0.01,0.02-0.03,0.04-0.05c0.02,0.05,0.04,0.09-0.02,0.07
				c0.03,0.06,0.07,0.11,0.09,0.14c-0.06,0.03-0.31-0.15-0.37,0.3c-0.37-0.13-0.11-0.11-0.35,0.27c0.02,0,0.03,0,0.05,0.02
				c-0.07,0.05-0.31,0.02-0.42,0.51l-0.09-0.06c0.31,0.05-0.35,0.14-0.27,0.66c0-0.01,0-0.01,0-0.01c0.08,0.06,0.19,0.14,0.37,0.25
				l-0.04-0.11l0.07,0.04l-0.03,0.04c0.07,0.03,0.03-0.03,0.01-0.07c0.03,0.02,0.06,0.04,0.08,0.04l-0.08-0.14
				c0.05,0.04,0.08,0.1,0.13,0.1c-0.04-0.07-0.12-0.07-0.13-0.14c0.07,0.03,0.11,0.07,0.09,0.01c0.03,0.02,0.05,0.05,0.06,0.07
				c-0.02-0.05,0.03-0.07-0.02-0.14c0.09,0.04,0.04-0.09,0.16,0.02c-0.02-0.03-0.06-0.12-0.05-0.14c0.11,0.16,0.07-0.06,0.21,0.08
				c-0.1-0.14,0.03-0.14,0-0.25c0.01,0.01,0.02,0.03,0.01,0.03c0.03-0.02,0.08,0.01,0.11,0.01c-0.1-0.1-0.08-0.16-0.13-0.23
				c0.13,0.06,0.1,0.13,0.2,0.2c-0.08-0.07-0.03-0.13-0.11-0.18c0.08,0.03,0.05,0.01,0.14,0.07c-0.12-0.12,0-0.04-0.06-0.13
				c0.09,0.02,0.13,0.1,0.12,0.03c0.19,0.15,0-0.05,0.12-0.35c0.06,0.02,0.04,0.03-0.07-0.14c-0.01,0.01,0.01,0.03,0.03,0.06
				l-0.01-0.04c0.14,0.12,0,0.15,0.17,0.07l-0.07,0.01c0.18-0.01,0.29,0.01,0.56-0.57c0.02,0.03,0.04,0.07,0.08,0.08
				c-0.13-0.06,0.18-0.4,0.85-0.78c-0.05-0.08,0.06-0.29,0.29-0.22l-0.04-0.12l0.1,0.08c-0.04-0.17,0.1,0.15,0.14-0.21
				c0.03,0.04,0.02,0.06,0.03,0.09c-0.07-0.16,0.13,0.09,0.01-0.1C310.59,271.16,310.65,271.12,310.67,271.07z M308.49,271.87
				c0-0.02,0.04-0.02,0.03,0.04C308.51,271.89,308.5,271.88,308.49,271.87z M309.05,272.18c0.01,0.02,0.04,0.02,0.07,0.03
				C309.09,272.22,309.08,272.26,309.05,272.18z"
                />
                <path
                  className="st10"
                  d="M321.56,246.85c-0.02,0.43-0.08,0.23-0.15,0.96c0.1,0,0.07,0.2,0.16,0.01c-0.01,0.04,0-0.07-0.01-0.2
				l0.01,0.01c0.01-0.1,0.02-0.24,0.03-0.39c0.15,1.09-0.27,0.71-0.15,2.95c-0.09-0.37-0.05-0.35-0.01,0.93l-0.04-0.06
				c-0.21,0.93,0.33,1.5,0.12,2.52c0.01-0.07,0.02-0.11,0.04-0.12c-0.19,0.5,0.28,2.8,0.65,5.94l0.06-0.09
				c-0.08,0.75,0.32,0.94,0.94,1.79c0.07-0.41-0.56-1.23-0.25-1.27c-0.09-0.1,0.11-0.36-0.39-1.56c0.23,0.26-0.08-0.6,0.13-0.55
				c-0.2-0.19-0.18-0.77-0.32-1.14c0.02,0.02,0.05,0.06,0.04,0.09c-0.01-0.22,0.03-0.29,0.04-0.43c0.03,0.27-0.6-0.95-0.01-0.47
				c-0.11,0.02-0.16-0.45-0.24-0.35c0.06-0.21,0.02-0.18,0.13-0.29c-0.17,0.17-0.23-1.11,0.04-0.63c-0.39-0.78,0.03,0.01-0.34-2.26
				c-0.16,0.03-0.22,0.1-0.18-0.28c0.62-0.31,0.21,0.11,0.19-2.75l0.13,0.13c0.02-1.17-0.29-1.35,1.07-6.75v0.1
				c0.15-0.33-0.06-0.46,0.15-0.74l-0.01,0.07c0.11-0.3,1.09-2.25,2.41-3.75l0.08,0.07c-0.03-0.03,0.08-0.21,0.45-0.42
				c-0.19-0.08,0.23,0.02,1.02-0.8c0.01,0.05-0.08,0.12-0.14,0.19c0.25-0.29,0.12,0.05,0.35-0.2c-0.05,0-0.01-0.07-0.01-0.09
				c0.18-0.03,0.5-0.17,0.74-0.24l-0.03,0.08c0.41-0.08,0.88-0.04,1.31,0.27c2.64-2.59-3.64,8.21,4.85,1.31
				c-1.16,1.72,4.5-4.12,3.18,4.5c0.02-0.01,0.03-0.02-0.02,0.01c0.5,0.29,1.11-0.41,2.61-0.95c-0.3,0.3,1.01-0.36,3.07,0.18
				c-0.07,0.13,0.46,0.35,0.67,0.41c-0.08,0,0.12,0.14,0.02,0.15c0.18,0.13,0.37,0.24,0.39,0.15c0.61,1,0.73,0.53,1.57,2.63
				c-0.63-0.35,0.47-0.2-0.32,2.38c-0.01-0.01-0.01-0.07,0-0.12c-0.04,0.3,0.01-0.05,0.01,0.65c-0.07-0.26-0.34,0.43-0.4,0.05
				c-0.03,0.06-0.05,0.15,0,0.17c-0.07,0.08-0.11,0.09-0.1-0.03c-0.2,0.32-0.05,0.27-0.29,0.53c-0.02-0.02,0.01-0.09,0.01-0.14
				c-0.06,0.18-0.14,0.36-0.38,0.54c0-0.03-0.04-0.02,0.02-0.08c-0.37,0.35-0.56,0.72-1.08,0.99c0.03-0.12-0.01-0.21-0.53,0.17
				l0.14-0.01c-0.23,0.14-0.39,0.35-0.62,0.43l0.15-0.15l-0.43,0.27l0.28-0.1c-0.21,0.2,0.11,0.13-0.23,0.28
				c0.06-0.05-0.24,0.13-0.41,0.13l0,0c-0.48,0.39-0.23-0.14-1.13,0.63c0.05-0.22-0.43,0.05-0.48,0.22
				c0.03-0.02,0.01-0.03,0.07-0.07c-0.43,0.23-0.63,0.3-0.78,0.34l0.1-0.07c-0.57,0.24,0.04-0.03-1.96,1l0.11-0.03
				c-0.55,0.41-0.48-0.05-1.9,0.87l0.05-0.07c-1.01,0.49-0.84,0.28-2.99,1.2c0.15-0.11,0.24-0.16-0.43,0.18
				c0.07,0.06,0.09,0.02,0.23,0.03c-0.05,0.07-0.2,0.17-0.33,0.23c0.23-0.15-0.05-0.12-0.18-0.12c0.03,0.02,0,0.06,0.02,0.08
				c-0.34-0.01,0.01,0.17-0.42,0.24c0-0.08,0-0.18-0.9,0.38c0.08-0.07,0.22-0.13,0.3-0.19c-0.26-0.01-0.28,0.04-0.45,0.09l0.02-0.01
				l-0.4,0.13l0.11-0.02c-0.17,0.35-0.54,0.31-0.83,0.64c0.28-0.32,0.02-0.11,0-0.22c-0.11,0.02-0.26,0.07-0.24,0.02
				c-0.13,0.17-0.32,0.09-0.5,0.34c0.04-0.05-1.29,0.86-2.73,2.37c0.01-0.18-0.11-0.36-0.57,0.82c0.03-0.04,0.08-0.08,0.11-0.12
				c-0.06-0.03-0.13-0.18,0.17-0.31l-0.07,0.15c0.24-0.03,0.35-0.54,0.58-0.79c0.07,0.08,0.08,0.2,0.67-0.43
				c-0.01,0.05,0.1,0.05-0.02,0.13c0.35-0.2,0.41-0.51,0.6-0.51l-0.02,0.03c0.97-0.52,0.73-0.9,2.76-1.66
				c0.04,0.13-0.13-0.01,0.62-0.34c0,0.04,0.64-0.21,0.97-0.29l0,0.02c0.01-0.14,0.34-0.25,0.56-0.37c0.02,0.07-0.01,0.12-0.12,0.2
				c0.02-0.03,0.04,0.05,0.45-0.27c-0.06,0.29,0.13,0.04,1.07-0.57l-0.13,0.14c0.06-0.06,0.52-0.21,0.15-0.01
				c0.72-0.22,0.3-0.08,1.68-0.73l0,0c0.47-0.39,0.72-0.14,3.09-1.34c-0.19,0.16,0.1,0.01-0.21,0.15c0.3-0.11,0.39-0.09,0.65-0.32
				c-0.06,0.13,0.26,0,0.38-0.09l-0.23,0.04c0.15-0.08,0.28-0.14,0.42-0.2l-0.02,0.14c0.62-0.25,1.3-0.63,2.57-1.45
				c-0.39-0.1,0.81-0.13,0.56-0.26c0.36-0.14,0.52-0.3,0.87-0.49c-0.06-0.02,0.73-0.93,0.76-0.45c0.24-0.29,0.57-0.41,0.73-0.55
				l-0.12,0.16c0.08-0.09,0.14-0.16,0.22-0.24l-0.14,0.02c0.09-0.07,0.16-0.14,0.19-0.12c0.05-0.28-0.19-0.18,0.33-0.61
				c-0.45,0.47,0.19,0.49,0.36-0.47c0.02,0.08,0.17-0.03,0.19-0.12c-0.09,0.14-0.1-0.11,0.09-0.62l-0.09,0.17
				c-0.1-0.36,0.5-0.75,0.56-2.1c-0.08,0.12-0.06,0.14-0.15-0.14c0.03,0.21,0.06,0.43,0.06,0.65c-0.03-0.14-0.07-0.28-0.12-0.42
				c0.01,0.19,0.05,0.41,0.02,0.53c-0.05-0.2-0.03-0.18-0.11-0.09c-0.01-0.92,0.52-0.55-0.2-2.22l0.04,0.19
				c-0.45-0.68,0-0.64-1.49-2c0.15,0.04,0.03-0.14,0.3,0.03c-0.62-0.33,0.22,0.07-3.76-1.3c0.01-0.01,0.01-0.01,0.01-0.02
				c-0.93,0.15-0.01-0.13-1.49,0.11l0.07-0.03c-0.09,0-0.25,0.03-0.74,0.31c0,0,0.02-0.03,0.01-0.04c-0.33,0.3-0.14,0.14-0.86,0.69
				c0.25-0.25,0.19-0.17,0.33-0.34c-0.11,0.09-0.25,0.28-0.53,0.51c0.2-0.43,0.26-0.21,0.88-1.9c0.04,0.06,0.01,0.93,0.09,0.31
				c-0.01,0.03-0.01,0.05-0.02,0.07c-0.13-1.97,0.47-0.77-2.05-3.59c-0.21,0.27-0.16,0.35-1.37-0.26c0.06,0.07-0.14,0.12-0.39,0.13
				c0.12,0.01,0.24,0.03,0.35,0.05c-0.56,0.19-0.56-0.11-1.1,0.18l0.16-0.13c-0.4,0.23-0.75,0.01-2.82,2.05l0-0.07
				c-0.12,0.22-0.03-0.06-0.2,0.28c0.01-0.92-4.27-6.44-7.63-0.37l0.01-0.14c-0.37,1.05-0.21,0.45-1.09,2.33
				c-0.02-0.02-0.05-0.05-0.02-0.14c-0.11,0.25-0.48,0.95-0.25,0.92c-0.12-0.04-0.21,0.56-0.23,0.87l-0.08-0.07
				c0.03,0.03-0.03,0.39-0.12,1.17l-0.03-0.14c-0.04,0.35-0.28,1.11-0.46,2.42c0.05-0.39,0.1-0.7,0.11-0.67
				c-0.09-0.01-0.16,0.3-0.24,0.03c-0.01,0.26,0.07-0.01,0,1.05L321.56,246.85z M321.46,247.49c0-0.11,0.02-0.14,0.04-0.15
				c0.01,0.05-0.02,0,0,0.2C321.48,247.52,321.47,247.51,321.46,247.49z M322.2,246.99c0-0.03,0-0.08,0.01-0.16
				C322.21,246.9,322.21,246.83,322.2,246.99z M322.29,246.21c0,0.08,0,0.33-0.09,0.18C322.21,246.43,322.25,246.3,322.29,246.21z"
                />
                <path
                  className="st10"
                  d="M325.76,257.75c0.05,0.01-0.17-0.18-0.01-0.09c-0.08-0.09,0.17-0.24,0.03-0.35
				c0.09-0.08,0.07-0.23,0.25-0.22c-0.08-0.05-0.04-0.29,0.18-0.26l-0.08-0.09l0.12,0.05c-0.1-0.14,0.14,0.1,0.05-0.22
				c0.04,0.02,0.04,0.05,0.06,0.07c-0.11-0.12,0.15,0.04-0.03-0.09c0.07,0.01,0.1-0.03,0.1-0.08c0.09,0.04,0.06,0.08,0.16-0.17
				c-0.05,0.01-0.15-0.07-0.15-0.1l0.1,0.05c-0.05-0.05-0.05,0-0.1-0.08c0.03-0.02,0.12,0.05,0.14,0.09l-0.01-0.05
				c0.03,0.02,0.04,0.03,0.07,0.06c-0.05-0.05,0.13,0.01,0.04-0.08c0.06,0.42,0.31-0.94,0.98-1.26c-0.01-0.01-0.06-0.05-0.05-0.05
				c-0.06-0.08,0.5-0.47,0.47-0.46c-0.02-0.19,0.18-0.2,0.18-0.37c0.01,0.02,0.04,0.05,0.03,0.06c0.08,0.04,0.07-0.07,0.02-0.12
				c0.01,0.03,0.17,0.08,0.12-0.1c0.07,0,0.03-0.1,0.1-0.09c-0.06-0.09,0.09-0.02-0.01-0.14c0.11,0.11,0.15,0.03,0.12-0.04
				c0.03,0.04,0.05,0.02,0.09,0.07c0.02-0.03,0.03-0.07-0.04-0.12c0.18,0.09-0.01-0.2,0.18-0.05c0.01-0.13,0.04-0.24,0.08-0.34
				c0.14,0.08,0.04,0.09,0.29-0.21c0.01,0.01,0.01,0.03,0.02,0.04c0.01-0.01,0.04-0.01-0.01-0.06l0,0.02
				c-0.09-0.06-0.1-0.2-0.14-0.25c0.12,0.17,0.09-0.01,0.24,0.21c0-0.02-0.01-0.05-0.05-0.08c0.15,0.01,0.02,0.01,0.35-0.21
				c-0.03,0,0.07-0.03-0.05-0.09c0.02,0.05,0.02-0.18,0.06-0.05l0.04-0.06c-0.07-0.03-0.07-0.02-0.11-0.14c0,0.02,0,0,0.1,0.02
				c0.01-0.06,0-0.15,0.13,0.05c-0.06-0.1,0.05-0.08-0.01-0.19c0.01,0.03,0.05,0.08,0.07,0.09l-0.04-0.08c0.09,0.1,0.09,0,0.06,0
				c0.04,0.04,0.15-0.24,0.2,0.01c0.05-0.07,0.04-0.23,0.12-0.26c-0.16-0.15-0.01-0.01,0.06-0.06c0,0.02,0.05,0.09,0.04,0.07
				c0.06-0.01,0.06-0.11,0.09-0.2c-0.02,0.02,0.22,0.07,0.09-0.09c0.12,0.07,0.32,0.13,0.25-0.33c0,0.07,0.08,0.11,0.12,0.13
				l-0.04-0.06c0.14,0.16-0.05-0.14,0.04-0.06c0.01-0.03,0.04-0.14,0.07,0.02c0.07-0.08-0.02,0,0.2,0
				c-0.17-0.25-0.01-0.01-0.03-0.27c0.05,0.15-0.02,0.02,0.17,0.23c-0.03-0.09,0.07,0.03,0.03-0.09c0.02,0.03,0.03-0.01,0.07,0.06
				c-0.22,0.11,0.08-0.45,0.33-0.3c0.01-0.04,0-0.11,0.01-0.16c0.04,0.05,0.03,0.09,0.04,0.13c-0.01-0.02,0.05-0.06-0.02-0.18
				c-0.02,0.04-0.12,0.12-0.19-0.04c0.02,0.01-0.07,0.12-0.16-0.03c-0.12,0.02-0.15,0.04-0.03,0.28l-0.18-0.2l0.05,0.09
				c-0.01,0-0.03,0-0.05-0.03c0.01,0.08,0.03,0.04,0.05,0.1c-0.09-0.06-0.06,0.1-0.17-0.04c0.03,0.08,0.07,0.03,0.06,0.1
				c-0.1-0.18-0.04,0.14-0.16-0.11c0.01,0.02,0.01,0.03,0.01,0.04c-0.01-0.02-0.04-0.04-0.09,0.04c-0.09-0.06,0.03-0.15-0.05,0.12
				c-0.01-0.05-0.02,0.03-0.19-0.03c0.07,0.11,0.01,0.12,0,0.16c-0.04-0.06-0.06-0.09-0.06-0.13c-0.02,0.03-0.03,0.02-0.01,0.11
				l-0.05-0.08c-0.03,0.03-0.01,0.14-0.09,0.12c0.05,0.07,0.18,0.18,0.17,0.26c-0.09-0.02,0.09,0.01-0.27-0.25
				c0.02,0.03,0.1,0.19-0.06,0.02c0.02,0.05-0.03,0.02,0.03,0.11c-0.01,0.01-0.04-0.03-0.06-0.05c0.02,0.05-0.01,0,0.02,0.16l0,0
				c0.06,0.18-0.41,0.01-0.95,0.51l0.02,0.04c-0.04-0.02-0.09,0.02-0.12-0.04c0,0.05,0.03,0.06,0.07,0.06
				c-0.08-0.01-0.07,0.05-0.13,0.06c0-0.15,0.01,0.32-0.14,0.03c0.02,0.09-0.11,0.02-0.14,0.03l0-0.04c0,0.03-0.01,0.03-0.02,0.04
				l0.07,0.05c-0.02,0-0.03,0.01-0.05-0.02c0.02,0.05,0.1,0.09,0.13,0.17c-0.04-0.02-0.02,0.08-0.1,0c0.07-0.02-0.08-0.08-0.05-0.13
				c-0.11,0.02-0.1-0.07-0.03,0.15c-0.04-0.05-0.09-0.08-0.09-0.06c0.02-0.02,0.08,0.07,0.09,0.12c-0.06-0.01-0.01,0.06-0.03,0.07
				c0.05,0.02,0.06,0,0.04,0.09c-0.03-0.03,0-0.04-0.03-0.05c0.05,0.1-0.13-0.03-0.11,0.05c-0.04-0.02-0.08-0.08-0.11-0.09
				c0.06,0.11-0.18-0.05-0.01,0.18l0.02-0.1c0.13,0.12-0.03,0.31-0.28,0.25c0.04,0.04-0.05,0.04,0.05,0.14
				c-0.21-0.21,0.04,0.35-0.17,0.03c-0.01,0.09,0-0.02,0.06,0.29c-0.2-0.11-0.11,0.14-0.28,0.05c-0.08,0.28,0.05,0.1-0.31,0.56
				c-0.06-0.07-0.02-0.05-0.09-0.1c0.27,0.28-0.07,0.14-0.24,0.25c0.01,0.04,0.04,0.16,0.03,0.21c-0.06-0.09-0.06-0.16-0.09-0.23
				c-0.02,0.24,0.12,0.04-0.52,0.5c0.08,0.02,0.08,0.08,0.14,0.11c-0.05,0.01-0.16-0.02-0.33-0.12c0.07,0.04,0.07,0.08,0.03,0.09
				c0.07-0.04,0,0.17-0.08,0.04c0.01,0.06-0.09,0.12,0.02,0.25c-0.18-0.42-0.04,0.53-0.85,1c0.03,0,0.1,0.07,0.1,0.09
				c-0.04,0,0.05,0.11-0.06,0.03l0.01-0.01c-0.13-0.06-0.12,0-0.15,0.03l0.15,0.1c-0.28-0.06-0.05,0.27-0.28,0.19
				c0.03,0.04,0.01,0.07,0.04,0.11c-0.06-0.02-0.11-0.11-0.19,0.1c-0.24-0.22-0.11,0.19-0.13,0.45c-0.12-0.06-0.05,0.07-0.02,0.13
				c-0.11-0.05-0.08-0.14-0.13,0.25c0.06,0.04,0.01,0.12-0.1,0.18c0.03-0.05,0.05-0.09,0.05-0.08c0-0.06-0.37,0.12-0.12,0.26
				c-0.01,0,0-0.01-0.01-0.04l0.01,0.01c0.01-0.02,0.01-0.03,0.02-0.05c0.04,0.04,0.07,0.07,0.01,0.07c0.05,0.04,0.1,0.08,0.13,0.1
				c-0.05,0.04-0.34-0.04-0.23,0.35c-0.37-0.01-0.15-0.07-0.22,0.32c0.02,0,0.03,0,0.05,0c-0.04,0.05-0.28,0.13-0.19,0.55l-0.1-0.03
				c0.26-0.06-0.05,0.18-0.16,0.49c-0.17-0.43,0.21,0.04,0.5-2.55l0.13,0.01c-0.16-0.17,0.13,0.14,0.02-0.29
				c0.04,0.02,0.05,0.04,0.07,0.07c-0.14-0.11,0.16,0-0.04-0.1c0.07-0.01,0.1-0.08,0.09-0.14c0.13,0.01,0.07,0.06,0.15-0.28
				c-0.05,0.03-0.16-0.03-0.16-0.06l0.11,0.03c-0.06-0.04-0.22-0.12,0.04,0l-0.02-0.06c0.03,0.01,0.05,0.03,0.08,0.05
				c-0.06-0.04,0.14-0.04,0.03-0.11c0.13,0.45,0.19-1.22,0.88-1.91c-0.02-0.01-0.15-0.06,0.01-0.05c-0.3,0.03,0.5-0.78,0.39-0.73
				c-0.04-0.22,0.18-0.31,0.17-0.51c0.02,0.02,0.05,0.04,0.04,0.05c0.09,0.01,0.07-0.11,0.01-0.15c-0.07,0.1,0.24-0.2,0.2-0.46
				c0.13,0.09,0.17-0.03,0.13-0.1c0.03,0.04,0.06,0,0.11,0.05c0.02-0.04,0.02-0.09-0.05-0.13c0.21,0.03-0.03-0.23,0.2-0.13
				c0-0.16,0.03-0.3,0.07-0.42c0.16,0.05,0.06,0.07,0.31-0.36c0.01,0.01,0.02,0.03,0.02,0.04c0.01-0.02,0.05-0.03-0.01-0.07l0,0.03
				c-0.1-0.03-0.13-0.19-0.17-0.23c0.15,0.15,0.1-0.05,0.28,0.14c0-0.02-0.02-0.05-0.06-0.08c0.18-0.05,0.03-0.01,0.37-0.38
				c-0.03,0.01,0.08-0.07-0.06-0.09c0.03,0.05,0.01-0.21,0.07-0.08l0.04-0.09c-0.07-0.01-0.08,0.01-0.14-0.12
				c0.1,0.01,0.09-0.06,0.26,0c-0.07-0.09,0.05-0.11-0.02-0.21c0.01,0.03,0.07,0.07,0.08,0.08l-0.06-0.07
				c0.06,0.01,0.14,0.06,0.08-0.03c0.04,0.01,0.13-0.32,0.22-0.07c0.05-0.1,0.03-0.28,0.12-0.34c-0.19-0.11-0.01,0,0.07-0.09
				c0,0.02,0.07,0.08,0.05,0.06c0.07-0.04,0.06-0.14,0.1-0.27c-0.02,0.04,0.25,0,0.1-0.14c0.15,0.02,0.38,0.03,0.27-0.47
				c0,0.07,0.09,0.09,0.14,0.1c-0.02-0.01-0.04-0.04-0.05-0.05c0.18,0.12-0.07-0.13,0.04-0.08c0-0.03,0.03-0.18,0.08,0
				c0.08-0.12-0.04,0.01,0.23-0.07c-0.22-0.21-0.02-0.01-0.05-0.29c0.07,0.14-0.02,0.04,0.22,0.19c-0.04-0.09,0.09,0.01,0.03-0.12
				c0.03,0.02,0.03-0.02,0.08,0.04c-0.27-0.25,0.21,0.02,0.07-0.56c0.06,0,0.06,0.21,0.16,0.02c0.12,0.17,0.02-0.18,0.14,0.08
				c0.01-0.05-0.01-0.12,0-0.17c0.05,0.04,0.04,0.08,0.06,0.12c-0.02-0.01,0.05-0.08-0.04-0.19c-0.02,0.05-0.13,0.17-0.23,0.03
				c0.02,0-0.07,0.15-0.19,0.02c-0.12,0.05-0.19,0.09-0.01,0.32l-0.22-0.15l0.07,0.08c-0.01,0-0.03,0.01-0.06-0.02
				c0.02,0.08,0.04,0.03,0.07,0.09c-0.11-0.04-0.07,0.13-0.2,0.02c0.04,0.07,0.08,0.01,0.08,0.09c-0.13-0.17-0.03,0.17-0.2-0.06
				c0.04,0.2-0.21-0.01-0.13,0.27c-0.03-0.05,0,0.04-0.23,0.04c0.08,0.1,0.02,0.13,0.01,0.18c-0.05-0.05-0.07-0.08-0.08-0.12
				c-0.02,0.04-0.03,0.03-0.01,0.12l-0.06-0.07c-0.04,0.05,0,0.16-0.1,0.17c0.06,0.06,0.22,0.13,0.21,0.22
				c-0.04-0.03-0.1-0.08-0.1-0.11c0,0.02-0.11,0.04-0.1-0.05c-0.03,0.01-0.08,0.02-0.13-0.01c0.02,0.03,0.12,0.17-0.06,0.05
				c0.03,0.04-0.04,0.04,0.04,0.11c-0.01,0.01-0.05-0.02-0.07-0.03c0.02,0.05,0,0,0.03,0.17l0,0c0.2,0.09-0.24,0.08-0.53,0.48
				c0.02-0.08-0.2,0.02-0.04,0.01c-0.26,0.06-0.17,0.54-0.48,0.45l0.03,0.03c-0.05,0-0.09,0.06-0.14,0
				c0.01,0.06,0.04,0.06,0.08,0.04c-0.09,0.02-0.07,0.08-0.15,0.12c0.09,0.04,0.06,0.36-0.15,0.08c0.02,0.1-0.12,0.07-0.15,0.09
				l-0.01-0.05c0,0.03-0.01,0.04-0.02,0.06l0.08,0.03c-0.02,0.01-0.03,0.02-0.06,0c0.03,0.05,0.12,0.07,0.16,0.15
				c-0.05,0-0.01,0.09-0.11,0.04c0.08-0.05-0.09-0.06-0.07-0.13c-0.11,0.06-0.12-0.04-0.02,0.19c-0.04-0.05-0.11-0.05-0.1-0.03
				c0.02-0.03,0.09,0.05,0.11,0.1c-0.07,0.02-0.01,0.07-0.03,0.09c0.05,0,0.06-0.02,0.05,0.09c-0.04-0.03-0.01-0.05-0.03-0.05
				c0.06,0.1-0.15,0.01-0.12,0.1c-0.05,0-0.1-0.07-0.13-0.06c0.09,0.11-0.21,0,0.01,0.21l0.02-0.13c0.09,0.18,0.19,0.09-0.19,0.35
				c0.06,0.01,0.1-0.01,0.03,0.1c-0.06-0.04-0.09-0.04-0.13-0.05c0.04,0.03-0.05,0.06,0.06,0.15c-0.12,0.01-0.18-0.06-0.06,0.23
				c-0.05-0.06-0.11-0.06-0.13-0.13c0,0.1-0.01-0.02,0.09,0.32c-0.23-0.05-0.11,0.21-0.31,0.17c-0.06,0.37,0.07,0.11-0.29,0.79
				c-0.07-0.06-0.03-0.05-0.11-0.08c0,0.03,0.13,0.06,0.12,0.13c0.21-0.05-0.48,0.04-0.31,0.5c-0.07-0.08-0.09-0.17-0.12-0.24
				c-0.02,0.03-0.05,0.11,0.01,0.16c-0.02-0.03-0.1,0.02-0.48,0.58c0.09,0.01,0.24,0.25-0.03,0.07c0.08,0,0.09,0.07,0.16,0.08
				c-0.05,0.03-0.17,0.04-0.37-0.01c0.07,0.02,0.09,0.07,0.04,0.09c0.08-0.07,0.01,0.2-0.08,0.07c0.01,0.06-0.09,0.17,0.05,0.29
				c-0.03,0-0.08-0.01-0.09-0.04c-0.13,0.16,0.1,0.24-0.67,1.59c-0.01-0.01,0.18,0.05,0.06,0.13l0.01-0.01
				c-0.14-0.02-0.13,0.05-0.15,0.09l0.17,0.07c-0.3,0.04-0.01,0.35-0.26,0.34c0.03,0.04,0.02,0.08,0.06,0.12
				c-0.1,0-0.12-0.08-0.18,0.19l-0.06-0.04c0.14,0.09-0.16,0.22,0,0.64c-0.13-0.03-0.04,0.1,0,0.17c-0.22-0.1,0.04,0.04-0.16,0.62
				c0.02-0.07,0.04-0.13,0.04-0.12c-0.06-0.09-0.34,0.25-0.07,0.37c-0.01,0.01,0-0.01-0.02-0.04l0.01,0c0-0.02,0.01-0.04,0.01-0.07
				c0.04,0.03,0.08,0.07,0.02,0.09c0.06,0.03,0.12,0.06,0.15,0.07c-0.05,0.07-0.34,0.05-0.17,0.52c-0.39,0.12-0.15-0.03-0.16,0.49
				c0.02-0.01,0.03-0.02,0.05-0.01c-0.03,0.11-0.25,0.19-0.09,0.76l-0.11,0c0.23-0.15-0.19,0.36,0.14,0.8c0-0.01,0-0.01-0.01-0.01
				c0.1,0,0.24,0,0.45-0.01l-0.09-0.07l0.08-0.01l0,0.05c0.08-0.02,0.01-0.05-0.03-0.07c0.04,0,0.07-0.01,0.09-0.02l-0.15-0.08
				c0.06,0,0.12,0.04,0.16,0c-0.07-0.04-0.15,0.01-0.19-0.04c0.08-0.02,0.14-0.01,0.08-0.05c0.04,0,0.07,0.01,0.08,0.03
				c-0.05-0.04-0.01-0.09-0.1-0.11c0.09-0.02-0.01-0.11,0.14-0.09c-0.04-0.01-0.11-0.07-0.12-0.1c0.18,0.08,0.02-0.11,0.22-0.07
				c-0.16-0.06-0.06-0.15-0.14-0.23c0.01,0.01,0.04,0.02,0.03,0.02c0.02-0.04,0.07-0.04,0.1-0.06c-0.13-0.02-0.16-0.09-0.23-0.14
				c0.14-0.03,0.15,0.05,0.28,0.06c-0.11-0.01-0.1-0.1-0.19-0.1c0.09-0.02,0.04-0.03,0.16-0.03c-0.17-0.03-0.02-0.04-0.12-0.09
				c0.09-0.04,0.16,0.01,0.12-0.05c0.2,0.11,0.05-0.28-0.22-0.49c-0.01,0.02,0.03,0.02,0.05,0.04l-0.03-0.03
				c0.18,0.03,0.09,0.14,0.18-0.05l-0.06,0.06c0.13-0.03,0.18,0.06,0.19-0.02c0.01,0.01,0.02,0,0.17,0.07c0-0.01,0-0.01-0.01-0.01
				c0.1,0.03,0.23,0.06,0.44,0.11l-0.08-0.08l0.08,0.01l-0.01,0.04c0.08,0,0.02-0.03-0.02-0.06c0.04,0.01,0.07,0.01,0.09,0.01
				l-0.13-0.1c0.06,0.02,0.11,0.06,0.16,0.05c-0.06-0.05-0.14-0.03-0.17-0.08c0.08,0,0.13,0.03,0.08-0.02
				c0.04,0.01,0.06,0.03,0.08,0.04c-0.04-0.04,0-0.07-0.07-0.11c0.09,0.01,0.01-0.09,0.15-0.03c-0.03-0.02-0.1-0.08-0.1-0.11
				c0.16,0.1,0.04-0.07,0.22,0.01c-0.14-0.09-0.03-0.13-0.1-0.21c0.01,0.01,0.03,0.02,0.02,0.02c0.02-0.02,0.07-0.01,0.11-0.02
				c-0.13-0.06-0.14-0.11-0.2-0.17c0.14,0.02,0.14,0.08,0.26,0.12c-0.1-0.04-0.08-0.1-0.17-0.12c0.09,0.01,0.05-0.01,0.16,0.02
				c-0.16-0.07-0.02-0.04-0.1-0.1c0.09,0,0.15,0.05,0.12-0.01c0.23,0.08-0.02-0.04-0.02-0.33c0.06,0.01,0.04,0.02-0.11-0.1
				c-0.01,0.01,0.02,0.03,0.05,0.04l-0.02-0.03c0.12,0.03,0.03,0.04,0.11,0.08c-0.02-0.04-0.07-0.25,0.07-0.07l-0.06,0.03
				c0.12-0.03,0.28-0.08,0.3-0.63c0.03,0.02,0.06,0.05,0.1,0.05C325.54,257.85,325.65,257.79,325.76,257.75z M324.77,257.69
				c-0.01-0.01,0.03-0.02,0.04,0.03C324.79,257.7,324.78,257.69,324.77,257.69z M324.13,258.77c0-0.01,0-0.01,0-0.03
				C324.13,258.75,324.13,258.74,324.13,258.77z M324.15,258.62c-0.02,0.02-0.01,0.08-0.08,0.02
				C324.1,258.66,324.12,258.64,324.15,258.62z M323.43,258.72c-0.01-0.01,0.02-0.05,0.05,0.02
				C323.46,258.73,323.45,258.72,323.43,258.72z M325.38,257.81c0.02,0.01,0.04,0,0.08,0.01
				C325.44,257.84,325.44,257.87,325.38,257.81z"
                />
                <path
                  className="st10"
                  d="M399.64,279.41c0.08-0.19-0.04-0.76-0.19-1.33c0.09-0.01,0.08-0.19,0.14-0.1c-0.02-0.22-0.02-0.28-0.1-0.56
				c-0.06,0.07-0.14,0.02,0.01-0.05c-0.14-0.3,0.22-0.75,0-1l0.01,0.01c-0.35-0.72,0.3-0.07-0.71-1.92c-0.05,0.04-0.07,0.1-0.1-0.08
				c-0.16,0.39-0.52-0.9-2.24-0.49l0.05-0.14c-0.45,0.26-0.94,0.53-1.1,0.98c-0.01-0.04-0.04-0.06-0.01-0.09
				c-0.25,0.51-0.02,0.09-0.45,1.5c-0.15-0.04-0.18,0.24-0.12,0.34c-0.04-0.04-0.06,0.07-0.12,0.03c-0.01,0.1,0,0.2,0.08,0.2
				c-0.2,0.3-0.16,0.18-0.1,1.37c-0.29-0.31-0.09-0.6-0.15,1.06c-0.01,0-0.03-0.03-0.04-0.05c0.07,0.13-0.01-0.03,0.28,0.27
				c-0.2-0.1-0.07,0.23-0.32,0.08c0.01,0.03,0.04,0.07,0.09,0.07c-0.02,0.04-0.05,0.06-0.1,0.01c-0.05,0.06,0.18,0.28,0.01,0.21
				c0.06,0.08,0.13,0.16,0.09,0.29c-0.02-0.01-0.05,0-0.05-0.03c0,0.22,0.16,0.38,0.08,0.62c-0.15-0.08-0.05,0.08-0.18,0.08
				l0.03,0.13l0.07-0.05c-0.01,0.11,0.08,0.22,0.04,0.31l-0.05-0.09l0.02,0.21l0.05-0.12c0.09,0.22,0.22-0.03-0.09,0.5
				c0.11,0.06,0.02,0.26,0.14,0.34c-0.03-0.02-0.1-0.03-0.11-0.02l0.09,0.05c-0.03,0.05-0.04,0.06-0.1,0.05
				c-0.02,0.16,0.17-0.05,0.09,0.14c-0.01-0.01-0.02-0.01-0.03-0.04c0.01,0.21-0.01,0.3-0.04,0.35l-0.02-0.06l-0.06,0.07l0.06,0.02
				c-0.14,0.2,0.19,0.04,0.11,1.62l-0.05-0.03c0.03,0.01-0.15,0.24,0.08,0.41c-0.26,0.34,0.18,0.6-0.03,0.9
				c0-0.1-0.11,0.2,0.22,0.25c-0.06-0.1-0.13,0.01-0.17,0.06c0.02-0.01,0.05,0,0.08,0c-0.19,0.12,0.11,0.04,0.04,0.18
				c-0.02-0.03,0.17,0.32-0.04,0.13c0.01,0.1,0.19,0.18,0.11,0.27c-0.03-0.04-0.04-0.1-0.06-0.14c-0.1,0.11-0.07,0.12-0.08,0.19
				l0-0.01l-0.03,0.17c0.29-0.03,0.02-0.01,0.27,0.35c-0.15-0.12-0.08-0.06-0.28,0.08c0.09,0.07-0.05,0.14,0.08,0.23
				c-0.02,0.01-0.02,0.03-0.03,0.05c0.06-0.05,0.13,0.28,0.35,1.19c-0.14-0.01-0.22,0.01-0.03,0.28c-0.09-0.01-0.01,0.02-0.16-0.18
				c0.06,0-0.04-0.01,0.04-0.33c-0.02,0-0.03-0.01-0.05-0.02c0.01-0.03,0.02-0.07-0.01-0.08c0.02,0.02,0,0.04-0.01,0.08
				c-0.06-0.02-0.09-0.08-0.12-0.13c0.07-0.02,0.11-0.02,0.15,0.01c-0.02-0.01,0.02-0.05-0.12-0.12l0.1-0.01
				c-0.03-0.09-0.17-0.15-0.14-0.28c-0.23,0-0.32,0.18-0.01-0.2c-0.05,0.01-0.22-0.01-0.02-0.12c-0.05-0.01-0.02-0.08-0.12-0.05
				c-0.01-0.03,0.04-0.05,0.06-0.06c-0.07-0.02,0,0-0.18-0.12l0,0c-0.17-0.07,0.02-0.44-0.27-0.97c0.08,0.05,0.04-0.04,0.07,0.06
				c0-0.1,0.04-0.14-0.08-0.2c0.1,0,0.08-0.1,0.04-0.14l-0.04,0.08c0-0.29,0.08-0.06-0.11-0.62c-0.27,0.35-0.27-0.48-0.2-0.41
				l-0.04,0.01c0.02-0.06-0.04-0.17,0.04-0.17c-0.06-0.05-0.07,0-0.07,0.06c0.01-0.13-0.07-0.17-0.09-0.29
				c-0.04,0.07-0.45-0.21-0.05-0.27c-0.12-0.06-0.05-0.21-0.07-0.27l0.06,0.04c-0.03-0.02-0.05-0.05-0.06-0.07l-0.06,0.07
				c0-0.17,0.06-0.08-0.21-0.18c0.07,0.18,0.38-0.19-0.06-0.17c0.06-0.02,0.08-0.09,0.06-0.1c0.04,0.02-0.08,0.11-0.25-0.05
				c-0.01,0.06,0.02,0.1-0.12-0.01c0.04-0.03,0.06,0.03,0.06,0c-0.24,0.01,0.13-0.64-0.3-0.5c0.06,0.04,0.12,0.07,0.17,0.11
				l-0.17-0.02c0.04,0.04,0.12,0.06,0.13,0.1c-0.09-0.01-0.07-0.01-0.11,0.05c-0.29-0.26,0.32-0.23-0.32-0.61
				c-0.01,0.07,0.02,0.13-0.14-0.02c0.05-0.05,0.04-0.09,0.05-0.14c-0.03,0.04-0.1-0.1-0.2,0c-0.05-0.17,0.05-0.19-0.35-0.17
				c0.08-0.04,0.06-0.11,0.16-0.1c-0.15-0.04,0.04,0-0.45,0c-0.02-0.17-0.07-0.26-0.71-0.45c0.01-0.02,0.01-0.03,0.03-0.02
				c-0.33-0.01,0-0.06-0.44-0.19l0.03-0.01c-0.06-0.1-0.21,0.05-0.33,0.02c0,0,0.01-0.02,0-0.03c-0.1,0.11-0.25,0.14-0.37,0.18
				c0-0.03,0-0.04,0-0.06c-0.05-0.19-0.42-0.33-1,0.32c0-0.12,0.1-0.22,0.16-0.31c-0.33,0.26-0.06,0.18-1.26,1.09
				c0.08-0.06,0.29,0.28-0.04,0.16c0.09-0.06,0.09,0.09,0.16,0.07c-0.07,0.09-0.2,0.19-0.35,0.25c0.08-0.02,0.11,0.05,0.09,0.14
				l0.09-0.13c0.12,0.19-0.17,0.2-0.03,0.39l-0.08-0.05c0.05,0.1,0.09,0.38,0.28,0.4c-0.02,0.02-0.07,0.06-0.09,0.03
				c-0.03,0.19,0.1,0.22,0.42,0.79l-0.07-0.01c0.47,0.43-0.05,0.43,0.95,1.76c0.23,0.03,0.06,0.13,0.84,0.91
				c-0.08,0.14-0.1,0.05,0.16,0.37l-0.08,0.02c0.35,0.06,0.16-0.08,0.63,0.46c-0.02,0.01-0.05,0.04-0.08,0.02
				c0.05,0.06,0.15,0.29,0.28,0.13c-0.09,0.1,0.12,0.13,0.22,0.16l-0.08,0.06c0.02-0.02,0.11,0,0.38,0.11l-0.07,0.02
				c0.09,0.1,0.23-0.13,0.22,0.22c0.02-0.01-0.01,0.01,0.33,0.28c-0.09-0.08-0.16-0.14-0.15-0.14c-0.18,0.06,0.13,0.45,0.48,0.38
				c0.01,0.01-0.02-0.01-0.06-0.02l0.01,0c-0.03-0.02-0.06-0.04-0.1-0.07c0.06-0.02,0.12-0.02,0.12,0.05
				c0.06-0.03,0.12-0.07,0.15-0.08c0.12,0.03,0.3,0.56,1.35,1.06c-0.01-0.02-0.02-0.04-0.01-0.06c0.67,0.4,0,0.26,2.31,0.95
				c0,0.01,0,0.01-0.01,0.03c0.05-0.08,0.04-0.02,0.06-0.23l0.03,0.04c-0.01-0.08-0.08-0.01-0.14-0.34l0.08,0.03
				c-0.02-0.09-0.07-0.04-0.12-0.01c0.01-0.04,0-0.07-0.01-0.1l-0.15,0.11c0.12-0.33-0.18,0.1-0.06-0.16c0,0.05-0.24-0.06-0.06-0.13
				c-0.06-0.02-0.15-0.03-0.23,0.01c0-0.01,0-0.01,0-0.02c-0.03,0.03-0.13,0.07-0.18,0.06c0.15-0.13-0.16-0.08-0.05-0.25
				c-0.13,0.13-0.24-0.03-0.38,0c0.01-0.01,0.03-0.03,0.04-0.01c-0.05-0.04-0.04-0.09-0.07-0.14c-0.07,0.12-0.17,0.1-0.26,0.15
				c-0.01-0.15,0.12-0.12,0.15-0.24c-0.05,0.1-0.17,0.04-0.19,0.13c-0.01-0.1-0.03-0.06,0-0.17c-0.09,0.15-0.06,0-0.16,0.07
				c0.02-0.09-0.16-0.11,0.04-0.23c-0.17,0.21-0.23-0.28-0.85-0.04c0.02,0.02,0.04-0.01,0.07-0.03l-0.06,0.01
				c0.58-0.63,0,0-1.16-0.98c0.28,0.28-0.25-0.44-0.92-0.91l0.04,0c-0.05-0.1-0.24,0.06-0.24-0.07l0.03,0
				c-0.08-0.15,0.11-0.2-0.94-1.08l0.07-0.12c-0.24,0.02,0.16-0.06-0.32-0.32c0.01-0.01,0.03-0.01,0.04-0.01
				c0-0.03,0.11-0.1-0.07-0.06c0.02-0.08-0.05-0.18-0.12-0.24l0.07-0.03c-0.07-0.14-0.22-0.26-0.31-0.41c0,0.07-0.11,0.13-0.15,0.09
				l0.09-0.08c-0.07,0.02-0.24,0.09,0.02-0.04l-0.07-0.05c0.03-0.02,0.05-0.01,0.09-0.03c-0.08,0.01,0.03-0.18-0.1-0.16
				c-0.01-0.13-0.35-0.49-0.63-0.72c0.08-0.04,0.02-0.16,0.1-0.13c-0.08-0.13-0.1-0.17-0.24-0.32c-0.03,0.06-0.13,0.07-0.01-0.03
				c-0.22-0.16,0.05-0.55-0.16-0.63l0.01,0.01c-0.14-0.18-0.04-0.06-0.09-0.5c0.25-0.01,0.08-0.29,0.31-0.29
				c-0.02-0.02-0.13-0.09,0.03-0.04c-0.51-0.05,0.57-0.43,1.64-0.5c-0.02,0.02-0.03,0.06-0.05,0.05c0.03,0.09,0.18,0.05,0.2-0.02
				c-0.07-0.05,0.28,0.2,0.7,0.21c-0.08,0.13,0.08,0.19,0.17,0.16c-0.04,0.03,0.02,0.07-0.04,0.11c0.06,0.03,0.12,0.04,0.16-0.03
				c-0.01,0.22,0.3,0.04,0.2,0.27c0.21,0.07,0.39,0.16,0.54,0.29c-0.35,0.11-0.4-0.29,0.41,0.61c-0.01,0.01-0.04,0-0.05-0.01
				c0.02,0.02,0.02,0.08,0.08,0.03l-0.03-0.02c0.06-0.1,0.25-0.01,0.3-0.03c-0.2,0.06,0.05,0.17-0.22,0.23
				c0.02,0.01,0.07,0.02,0.1-0.01c0.03,0.31-0.01-0.01,0.4,0.84l0.04-0.06c0.03,0.07,0.15,0.1,0.14,0.18l-0.07-0.03l0.09,0.13
				l0.01-0.1c0.15,0.1,0.19-0.12,0.08,0.36c0.12,0,0.1,0.16,0.23,0.17c-0.03-0.01-0.1,0.02-0.11,0.03l0.09,0
				c-0.04,0.11,0.05,0.08,0.06,0.37l-0.03-0.04l-0.04,0.06l0.07,0c-0.01,0.05,0.16,0.37,0.45,1.1c-0.04-0.09-0.16,0.23,0.1,0.26
				c-0.04,0.21-0.27,0.26,0.37,0.78c-0.08-0.06-0.12,0.04-0.16,0.09c0.02-0.01,0.05-0.01,0.07-0.02c-0.17,0.12,0.13,0.02,0.06,0.12
				c-0.01-0.01,0.2,0.2-0.03,0.1c0.09,0.19-0.01-0.02,0,0.37c0.28-0.09,0.02-0.01,0.31,0.19c-0.16-0.05-0.07-0.04-0.26,0.12
				c0.1,0.03-0.03,0.11,0.11,0.15c-0.02,0.01-0.02,0.03-0.03,0.05c0.05-0.02,0.15,0.16,0.45,0.8c-0.21,0.02,0.18,0.17-0.13,0.12
				c0.05,0.05,0.13,0.09,0.18,0.15c-0.05,0.03-0.1-0.01-0.14-0.02c0.02-0.02,0.07,0.17,0.22,0.11c-0.27-0.03,0.25,0.01-0.13-0.32
				l0.15-0.01l-0.14-0.06c0.03-0.03,0.1-0.07,0.14-0.03c0.04-0.07,0.07-0.05-0.05-0.18c0.03,0,0.09-0.04,0.09,0
				c0-0.11,0.03-0.31-0.32-0.28c0.02-0.03-0.03,0.04,0.23-0.16l-0.11,0.02c0-0.02,0-0.05,0.04-0.06c-0.09-0.04-0.04,0.02-0.12,0.01
				c0.09,0.03-0.06-0.42,0.13-0.15c-0.14-0.09,0.15,0.17,0.05,0.01c0.08-0.06-0.07-0.13-0.19-0.38c0.03,0.04,0.07,0.07,0.13,0.06
				c-0.27-0.12,0.26,0.07-0.1-0.45l0.15,0.03l-0.14-0.11c0.03-0.03,0.11-0.06,0.14,0c0.05-0.09,0.08-0.04-0.03-0.25
				c0.03,0.01,0.1-0.03,0.09,0.03c0.04,0.03-0.29-0.37-0.14-1.05c-0.09-0.03-0.05,0.11-0.12,0c0.23,0.03-0.15-0.27,0.16-0.23
				c-0.23-0.12,0.16-0.02-0.15-0.6c0.06,0.01-0.04-0.01,0.1-0.41c-0.13-0.02-0.12-0.16-0.15-0.25c0.08,0,0.11,0.01,0.15,0.07
				c-0.02-0.01,0.04-0.04-0.1-0.2l0.1,0.02c-0.01-0.12-0.13-0.25-0.08-0.41c-0.09,0-0.24,0.15-0.31-0.02
				c0.05-0.01,0.13-0.02,0.15,0.02c-0.01-0.01-0.04-0.05-0.02-0.08l0.09,0.06c-0.04-0.04-0.02-0.22,0.04-0.06
				c0.01-0.07,0.04-0.15,0.09-0.18c-0.26,0,0.19-0.15-0.17-0.55l0,0c-0.15-0.14,0.12-0.56,0.01-1.35c0.06,0.1,0.05-0.03,0.04,0.1
				c0.03-0.13,0.09-0.15-0.01-0.29c0.09,0.05,0.11-0.09,0.09-0.15l-0.06,0.09c0.1-0.36,0.12-0.05,0.12-0.81
				c-0.39,0.28-0.06-0.7-0.01-0.59l-0.04-0.02c0.04-0.06,0.05-0.22,0.11-0.18c-0.04-0.09-0.07-0.04-0.09,0.02
				c0.07-0.15,0.01-0.24,0.06-0.39c-0.04,0.07-0.31-0.5,0.09-0.33c-0.07-0.15,0.07-0.26,0.09-0.34l0.03,0.08
				c-0.02-0.05-0.01-0.08-0.01-0.12l-0.09,0.04c0.09-0.17,0.09-0.04-0.07-0.34c-0.1,0.22,0.43,0.12,0.06-0.22
				c0.06,0.03,0.12-0.03,0.11-0.06c0.01,0.05-0.14,0.03-0.16-0.24l-0.03,0.07c-0.06-0.03-0.05-0.09-0.06-0.17
				c-0.05,0.12,0.19-0.22,0.32-0.62c-0.09,0.03-0.07,0.04-0.14-0.07l0.03,0.25l-0.1-0.17c-0.01,0.07,0.04,0.16,0.02,0.2
				c-0.06-0.08-0.04-0.07-0.11-0.04c0.03-0.42,0.4-0.05,0.26-0.83c-0.06,0.05-0.09,0.12-0.06-0.15c0.07,0.02,0.1-0.03,0.14-0.05
				c-0.05,0,0.03-0.16-0.11-0.2c0.14-0.18,0.18-0.07-0.01-0.5c0.07,0.06,0.13-0.01,0.16,0.12c-0.03-0.18,0.01,0.05-0.17-0.51
				c0.19-0.1,0.15-0.17,0.4-0.97c0.02,0.01,0.03,0.01,0.02,0.03c0.09-0.36,0-0.01,0.32-0.43l0,0.03c0.11-0.02,0.05-0.21,0.16-0.3
				c0,0,0.01,0.02,0.02,0.03c-0.02-0.15,0.11-0.29,0.22-0.38c0.07,0.27,0.22,0,1.22-0.02c-0.11,0.07-0.25,0.07-0.34,0.12
				c0.06,0.01,0.2,0.05,0.26,0c-0.09-0.03-0.11-0.04,0.27-0.02c-0.15,0.28,0.11,0.05,0.57,1.04c0.14-0.11,0.23-0.05,0.24,0.06
				c-0.05,0-0.17-0.04-0.18,0.07c0.04-0.1,0.12,0.02,0.17-0.03c-0.01,0.11-0.08,0.24-0.23,0.35c0.07-0.04,0.12,0.01,0.11,0.1
				l0.07-0.13c0.16,0.16-0.13,0.2,0.02,0.37l-0.08-0.05c0.06,0.09,0.06,0.37,0.24,0.46c-0.02,0.02-0.08,0.05-0.1,0.01
				c-0.07,0.18,0.04,0.3,0.13,1.11l-0.06-0.05c0.24,0.76-0.19,0.47-0.12,2.56c0.15,0.28,0,0.14-0.04,1.6
				c-0.19,0.06-0.08-0.03-0.19,0.48l-0.06-0.06c0.17,0.4,0.14,0.12-0.02,0.99c-0.02-0.01-0.06-0.02-0.06-0.06
				c-0.02,0.1-0.18,0.38,0.03,0.38c-0.13-0.02-0.06,0.22-0.03,0.35l-0.09-0.04c0.03,0,0.07,0.14,0.07,0.49l-0.05-0.06
				c-0.1,0.33-0.04,0.16-0.26,0.94c0.05-0.15,0.08-0.27,0.09-0.25c-0.17-0.13-0.36,0.29-0.21,0.77c-0.01,0.02,0.01-0.03,0-0.08
				l0.01,0.01c0.01-0.04,0.02-0.09,0.04-0.15c0.03,0.06,0.06,0.12-0.01,0.17c0.05,0.05,0.1,0.1,0.13,0.13
				c-0.31,0.21-0.47-0.03-0.43,0.83c-0.01-0.04,0.01-0.07-0.01-0.09c0.01,0.12-0.08,0.31,0.03,0.38c-0.1,0.11-0.09-0.11-0.13,0.07
				l-0.03-0.04c-0.32,0.27,0.11,0.68-0.29,0.96c0.13,0.04,0.12-0.19-0.76,3.09c0.08-0.06,0.05-0.04,0.08-0.37
				c0.32,0.15-0.06-0.23,0.19-0.09c-0.01-0.09,0.07-0.18,0.01-0.25c0.11-0.02,0.09-0.23,0.22-0.16c-0.02-0.04-0.05-0.17-0.03-0.23
				c0.11,0.19,0.12-0.22,0.28-0.11c-0.11-0.15,0.07-0.33,0.05-0.51c0.01,0.01,0.03,0.04,0.01,0.05c0.05-0.08,0.11-0.08,0.16-0.12
				c-0.12-0.07-0.09-0.21-0.14-0.31c0.17-0.05,0.12,0.13,0.25,0.15c-0.1-0.04-0.02-0.22-0.12-0.22c0.11-0.04,0.07-0.05,0.19-0.05
				c-0.15-0.08,0.01-0.08-0.07-0.19c0.09,0.01,0.12-0.24,0.25-0.02c-0.26-0.19,0.3-0.3,0.01-1.13c-0.02,0.03,0.02,0.05,0.03,0.07
				l-0.01-0.07c0.08,0.36,0.28-0.2,0.13,0.01c0.48,0.31,0.2-0.66,0.64-1.97c-0.11,0.28,0.28-0.17,0.5-1.74l0.02,0.04
				c0.07-0.14-0.17-0.21-0.05-0.33l0.01,0.03c0.16-0.1,0.19-0.88,0.48-2.67c0.04,0.01,0.05,0.06,0.07,0.11
				c-0.09-0.17,0.12-0.02,0.03-0.18c-0.03,0.02-0.06-0.02-0.08-0.02c0.07-0.07,0.09-0.24,0.07-0.36l0.07,0.03
				c0.03-0.22,0.01-0.46,0.02-0.7c-0.05,0.02-0.29-0.06-0.06-0.07c-0.07-0.04-0.24-0.08,0.04-0.03l-0.03-0.12
				c0.03-0.01,0.05,0.02,0.09,0.03C399.57,279.63,399.76,279.48,399.64,279.41z M395.27,290.69c0.01,0.01,0.03,0.01,0.04,0.02
				c-0.02,0-0.04,0-0.06,0.02C395.22,290.63,395.06,290.49,395.27,290.69z M392.11,288.9c-0.01,0-0.02-0.01-0.04-0.02
				C392.09,288.89,392.07,288.88,392.11,288.9z M391.93,288.86c0.02-0.01,0-0.05,0-0.09C391.91,288.77,392.05,288.81,391.93,288.86z
				 M391.86,289.52c-0.01,0.01,0,0-0.04,0.03C391.79,289.54,391.77,289.47,391.86,289.52z M394.95,289.11c0.05,0.01,0-0.04,0.1-0.02
				C395,289.1,395.02,289.17,394.95,289.11z M395,288.73c0-0.01-0.01-0.03-0.02-0.04C395.02,288.69,395.05,288.69,395,288.73z
				 M397.68,285.74c0.01-0.04,0.07-0.09,0.04,0.03C397.7,285.76,397.69,285.75,397.68,285.74z M398.4,285.71
				c0-0.01,0.01-0.03,0.01-0.06C398.42,285.68,398.42,285.65,398.4,285.71z M398.4,285.46c0.02,0.02,0.05-0.03,0.09-0.07
				C398.47,285.37,398.48,285.58,398.4,285.46z"
                />
                <path
                  className="st10"
                  d="M463,276.24c0.03,0.31,0.23-0.69,0.06-0.28c0.06-0.33-0.02-0.51,0.01-0.85c-0.05,0.05-0.43-0.93,0-0.74
				c-0.12-0.29-0.05-0.58-0.06-0.76l0.06,0.16c-0.04-0.09-0.05-0.17-0.07-0.25l-0.06,0.11c0-0.1-0.03-0.18,0.01-0.18
				c-0.18-0.15-0.27,0.07-0.28-0.5c0.06,0.38,0.09-0.04,0.19,0.23c-0.04-0.42,0.07-0.24-0.29-0.7c0.07,0.03,0.08-0.12,0.05-0.18
				c0.04,0.12-0.14,0.03-0.34-0.39l0.04,0.15c-0.29-0.17-0.12-0.68-0.6-1.67c-0.02,0.12,0,0.13-0.18-0.03l0.32,0.43l-0.28-0.24
				c0.08,0.14,0.22,0.26,0.25,0.36c-0.13-0.11-0.11-0.11-0.13-0.01c-0.42-0.74,0.28-0.58-1.07-1.61c0.05,0.04,0.1,0.09,0.14,0.13
				c-0.91-0.24-0.37-0.47-2.15,0.25c0.07-0.12-0.11-0.09,0.11-0.25c-0.43,0.37,0.16-0.13-2.48,2.28c0-0.01-0.01-0.02-0.01-0.02
				c-0.17,0.39-0.19,0.36-0.25,0.41c-0.02-0.11-0.08-0.22-0.08-0.31l0.06,0.01c-0.1-0.42-0.24-0.39-0.38-0.82l-0.02,0.06
				c-0.45-0.51,0.28-0.43-0.82-1.12c0.2,0.02,0.5,0.26,0.66,0.42c-0.09-0.15-0.38-0.47-0.51-0.47c0.06,0.03,0.16,0.07,0.18,0.14
				c-1.59-0.62-0.42-0.64-3.57,0.23c0.17,0.33,0.22,0.31-0.73,0.96c0.09-0.02,0.05,0.16-0.03,0.36c0.06-0.09,0.12-0.18,0.18-0.26
				c-0.04,0.52-0.31,0.39-0.27,0.91l-0.05-0.17c-0.11,0.2,0,0,0.02,1.25c-0.12-1.07-4.67-4.61-4.88,1.23l-0.09-0.09
				c0.45,0.83,0.17,0.37,0.75,2.04c-0.02,0-0.07,0-0.1-0.07c0.07,0.22,0.23,0.86,0.39,0.7c-0.12,0.06,0.19,0.46,0.35,0.67l-0.11,0
				c0.04,0,0.22,0.25,0.6,0.79l-0.11-0.07c0.18,0.35,0.32,0.09,0.4,0.77c-0.07-0.1,0.3,0.24,0.15,0.2c0.1,0.43,0.09-0.07,0.44,0.84
				c-0.16-0.28-0.29-0.51-0.27-0.5c-0.08,0.04,0.04,0.28-0.17,0.16c0.14,0.18,0.05-0.05,0.56,0.68l-0.02-0.02
				c0.2,0.29,0.06,0.2,0.37,0.72c0.08-0.06,0.15,0.09,0.14-0.08c0.01,0.04-0.03-0.05-0.11-0.13l0.01,0
				c-0.05-0.07-0.11-0.17-0.17-0.28c0.66,0.63,0.17,0.6,1.3,2.1c-0.24-0.21-0.19-0.23,0.39,0.67l-0.06-0.03
				c0.19,0.76,0.89,0.95,1,1.84c-0.01-0.06-0.01-0.09,0-0.11c-0.03,0.13,0.07,0.39,0.22,0.74c-0.04-0.04-0.1-0.14-0.28-0.47
				c-0.07,0.05-0.17,0.19-0.23-0.07c0.62-0.6,0.04-0.02-0.74-1.45l0.15-0.02c-0.69-0.45-0.76-1.41-2.96-2.88l0.06,0.01
				c-0.16-0.16-0.32,0.03-0.44-0.16l0.04,0.01c-0.1-0.16-0.54-0.04-2.41-1.35c-0.03,0.04-0.14,0.03-0.39-0.1
				c0.01,0.22-0.06-0.27-0.85-0.1c0.01-0.04,0.09-0.05,0.16-0.07c-0.26,0.09-0.04-0.12-0.28-0.03c0.03,0.03-0.02,0.06-0.02,0.09
				c-0.12-0.07-0.39-0.06-0.59,0l0.03-0.08c-0.37,0.04-0.75,0.22-1.09,0.45c-0.51,0.49-0.1-0.11-0.45,2.81
				c-0.39-0.02-0.89,0.01-1.59,0.19l0.08-0.13c-0.57,0.24-1.22,0.33-1.68,0.74c0.02-0.04,0-0.07,0.05-0.1
				c-0.65,0.51-0.1,0.06-1.5,1.61c-0.13-0.1-0.31,0.3-0.28,0.47c-0.03-0.06-0.09,0.1-0.13,0.03c-0.05,0.17-0.07,0.33,0.02,0.32
				c-0.23,0.76-0.31,0.69,0.33,2.1c-0.37-0.71,0.06,1.03,1.33,1.32c-0.22,0.08,0.23,0.26-0.08,0.34c0.05,0.02,0.11,0.02,0.13-0.02
				c0.05,0.05,0.05,0.09-0.04,0.09c0.23,0.12,0.19,0.01,0.38,0.13c-0.02,0.03-0.07,0.02-0.11,0.04c0.14,0,0.26,0.02,0.41,0.13
				c-0.02,0.01-0.02,0.05-0.06,0.02c0.29,0.15,0.56,0.11,0.86,0.3c-0.15,0.1,0.09,0.09,0.06,0.22l0.19,0.03l-0.05-0.08
				c0.16,0.06,0.32,0.01,0.44,0.09l-0.13,0.02l0.3,0.06l-0.15-0.1c0.51-0.09,0.02-0.12,1.19,0.19c-0.13,0.07,0.15,0.25,0.3,0.07
				c-0.02,0-0.01,0.02-0.06,0.02c0.3,0.02,0.41,0.07,0.5,0.11l-0.09,0.01c0.2,0.12,0.04-0.02,1.29,0.07l-0.06-0.02
				c0.06-0.18,0.25,0.11,0.42,0.04c0.03,0.19-0.06,0.07,0.78,0.04l-0.05,0.04c0.61,0.01,0.51,0.11,1.83,0.24
				c-0.1,0.03-0.18,0.03,0.26,0.04c-0.01-0.08-0.04-0.06-0.1-0.13c0.05-0.04,0.15-0.06,0.24-0.04c-0.16,0.02-0.02,0.13,0.04,0.18
				c-0.01-0.03,0.02-0.05,0.02-0.07c0.13,0.21,0.04-0.07,0.26,0.03c-0.05,0.01,0.49-0.05,0.16,0.09c0.14,0.02,0.31-0.12,0.41,0
				c-0.06,0.01-0.15-0.01-0.21,0.01c0.11,0.14,0.13,0.11,0.23,0.15l-0.01,0l0.22,0.1l-0.05-0.04c0.22-0.19,0.35,0.04,0.62-0.05
				c-0.22,0.08-0.1,0.07-0.01,0.29c0.13-0.05,0.16,0.11,0.34,0.03c-0.09,0.02,0.95,0.11,1.97,0.53c-0.08,0.08-0.22,0.18,0.53,0.21
				l-0.07-0.07c-0.06,0.11,0.08-0.19-0.62-0.21c0.18-0.07,0.18-0.24-0.36-0.32c0.03-0.02,0.01-0.1,0.08-0.06
				c-0.19-0.16-0.39-0.02-0.44-0.15l0.02,0c-0.31-0.21-0.45-0.15-1.09-0.2c0.14-0.2-0.41,0.02-0.25-0.26
				c-0.25,0.16,0.07-0.16-0.89-0.12c0.02-0.04-0.37-0.15-0.55-0.23l0.01-0.02c-0.06,0.12-0.25,0.06-0.4,0.06
				c0.02-0.07,0.05-0.1,0.13-0.12c-0.02,0.02-0.02-0.06-0.31,0.02l0.05-0.09c-0.18-0.02-0.38,0.05-0.6-0.04
				c-0.03,0.09,0.15,0.27-0.1,0.3c0-0.05,0-0.13,0.07-0.14c-0.02,0-0.08,0.03-0.12,0l0.11-0.07c-0.05,0.03-0.32-0.04-0.08-0.05
				c-0.09-0.03-0.2-0.08-0.24-0.14c-0.27,0.19,0-0.01-0.82,0.05l0,0c-0.21,0.12-0.79-0.21-1.92-0.21c0.14-0.05-0.04-0.06,0.15-0.03
				c-0.18-0.05-0.21-0.11-0.41-0.03c0.07-0.08-0.12-0.12-0.21-0.11l0.12,0.08c-0.56-0.26,0.08-0.11-1.84-0.35
				c0.1,0.17-0.24-0.01-0.08-0.09l-0.03,0.03c-0.07-0.06-0.28-0.12-0.21-0.17c-0.13,0-0.07,0.05,0.01,0.1
				c-0.18-0.12-0.31-0.1-0.5-0.22c0.05,0.08-0.73,0.05-0.39-0.25c-0.21-0.01-0.29-0.22-0.37-0.28l0.1,0.02
				c-0.06-0.01-0.1-0.04-0.14-0.06l0,0.1c-0.04-0.04-0.08-0.07-0.06-0.1c-0.14,0.01-0.14,0.28-0.36-0.07
				c0.2,0.24,0.36-0.26-0.21-0.21c0.06-0.03,0.03-0.13,0-0.14c0.06,0.03-0.08,0.14-0.34-0.07l0.05,0.08
				c-0.07,0.02-0.12-0.04-0.21-0.11c0.09,0.09-0.11-0.29-0.32-0.84c-0.04,0.09-0.02,0.09-0.16,0.01l0.22,0.24l-0.21-0.11
				c0.05,0.09,0.15,0.15,0.18,0.2c-0.12-0.04-0.09-0.05-0.12,0.03c-0.26-0.45,0.19-0.31-0.34-1.1l-0.01,0.1
				c-0.09-0.02-0.07-0.17-0.11-0.26c0.07,0,0.08-0.07,0.12-0.11c-0.06,0.01-0.02-0.2-0.15-0.23c0.12-0.26,0.15-0.11-0.03-0.65
				c0.06,0.08,0.13-0.01,0.15,0.15c0.01-0.07,0.04-0.14-0.01-0.15c0,0.13,0.13-0.27-0.04-0.53c0.25-0.08,0.2-0.23,0.99-1.08
				c0.01,0.02,0.02,0.03-0.01,0.04c0.38-0.37,0-0.01,0.7-0.39l-0.03,0.03c0.15,0.01,0.23-0.22,0.44-0.29c0,0,0,0.02,0.01,0.03
				c0.09-0.16,0.34-0.3,0.53-0.38c0,0.04,0.01,0.07,0.01,0.09c0.01-0.07,0.07-0.21,0.33-0.01c-0.06,0.01-0.11,0.02-0.17,0.04
				c0.11,0.1,0.22-0.05,0.31-0.04l0,0.06c0.45-0.13,0.29-0.28,1.4-0.3c-0.11,0.1-0.33,0.14-0.46,0.2c0.1,0,0.34-0.01,0.4-0.07
				c-0.04,0.01-0.1,0.04-0.14,0.02c0.35-0.2,0.6-0.02,2.19,0.55c-0.1-0.2-0.01,0,0.9-0.26c-0.01,0.04-0.02,0.07-0.03,0.12
				c0.16-0.83,0.5-0.18-1.95-1.19l0.05-0.03c-0.19-0.06-0.03-0.08-0.37-0.23l0.09,0c-0.66-0.65-0.69-0.63,1.01-2.1
				c-0.04,0,0.31,0.3,0.39-0.09c0.1,0.24,0.61-0.07,2,0.78l-0.09,0.04c0.59,0.03,0.26-0.07,1.27,0.52
				c-0.02,0.02-0.05,0.05-0.09,0.03c0.12,0.08,0.41,0.4,0.48,0.2c-0.08,0.11,0.26,0.18,0.43,0.25l-0.08,0.06
				c0.02-0.03,0.21,0.05,0.61,0.26l-0.1,0c0.2,0.17,0.28-0.09,0.42,0.39c-0.07-0.05,0.29,0.06,0.15,0.09
				c0.11,0.27,0.09-0.07,0.41,0.47c-0.15-0.16-0.26-0.29-0.24-0.28c-0.06,0.06,0.04,0.2-0.14,0.19c0.03,0.12,0.39,0.03,0.77,0.75
				c0.07-0.07,0.13,0.03,0.13-0.1c0.01,0.02-0.03-0.03-0.09-0.07l0.01,0c-0.04-0.04-0.09-0.1-0.14-0.16c0.1-0.04,0.01,0.2,0.38,0.15
				c-0.06,0.4,0,0.35,0.65,1.14c-0.04-0.03-0.07-0.07-0.1-0.08c0.13,0.09,0.25,0.37,0.38,0.34c0.05,0.2-0.16-0.03,0,0.18l-0.05-0.01
				c0.08,0.53,0.7,0.59,0.69,1.23c0-0.04,0-0.06,0.02-0.08c0.01,0.34,0.53,1.01,0.79,3.26l0.07-0.05c-0.18,0.93,0.72,0.84,1.1,2.06
				c0.24-0.34-0.17-1.19,0.13-1.15c-0.09-0.09,0.21-0.33-0.18-1.41c0.22,0.23-0.07-0.53,0.13-0.49c-0.19-0.16-0.2-0.67-0.38-0.98
				c0.02,0.01,0.05,0.04,0.05,0.07c-0.04-0.19,0-0.25,0-0.38c0.07,0.21-0.74-0.7-0.09-0.41c-0.1,0.04-0.23-0.35-0.29-0.25
				c0.02-0.19-0.01-0.16,0.06-0.28c-0.12,0.19-0.49-0.87-0.11-0.54c-0.6-0.49,0.09-0.05-1.04-1.7c-0.11,0.08-0.16,0.19-0.27-0.14
				c0.38-0.49,0.29,0-1.04-2.1l0.17,0.02c-0.74-0.87-1.16-1.83-2.87-5.05l0.06,0.06c-0.08-0.3-0.33-0.24-0.35-0.54l0.04,0.04
				c0.08-0.52-0.65-0.1-0.67-3.62l0.11,0.02c-0.06-0.1-0.05-0.17,0.11-0.45c-0.15,0.03,0.1-0.1,0.49-0.74
				c0.02,0.04-0.04,0.09-0.06,0.16c0.12-0.23,0.09,0.06,0.22-0.13c-0.04-0.01-0.02-0.06-0.02-0.09c0.12,0,0.36-0.06,0.55-0.08
				l-0.04,0.08c0.31,0.01,0.71,0.12,1.04,0.42c-0.23-2.37,3.93,10.16,2.62,0.57c0.56,1.46-0.67-4.78,3.78-0.48
				c-0.31-1,0.31,2.58,1.06,0.7c0.17,0.35-0.34-0.02,1.5-2.12c0.08,0.13,0.46-0.19,0.58-0.34c-0.03,0.07,0.16-0.04,0.13,0.06
				c0.17-0.1,0.32-0.21,0.25-0.26c0.4,0.26,1.03-1,2.06,0.18c-0.76-0.34-0.45-0.52,0.92,1.92c-0.01,0-0.05-0.04-0.08-0.08
				c0.17,0.21-0.02-0.03,0.45,0.37c-0.24-0.09,0.09,0.46-0.23,0.3c0.03,0.05,0.09,0.12,0.13,0.09c0.01,0.09,0,0.13-0.08,0.05
				c0.12,0.31,0.16,0.18,0.24,0.47c-0.03,0.01-0.06-0.05-0.11-0.07c0.1,0.13,0.21,0.27,0.22,0.53c-0.02-0.01-0.04,0.02-0.06-0.05
				c0.09,0.43,0.3,0.72,0.28,1.21c-0.09-0.07-0.19-0.07-0.12,0.46l0.06-0.11c0.01,0.23,0.11,0.43,0.06,0.63l-0.05-0.17l0.02,0.43
				l0.05-0.24c0.06,0.23,0.17-0.02,0.13,0.28c-0.12,0.44-0.09,0.13-0.17,1.42c-0.15-0.14-0.2,0.31-0.09,0.44
				c0-0.03-0.02-0.02-0.02-0.08c-0.07,0.4-0.13,0.56-0.18,0.68l0.01-0.1c-0.15,0.49,0.01-0.04-0.45,1.77l0.04-0.08
				c-0.06,0.57-0.31,0.23-0.65,1.62l-0.02-0.08c-0.37,0.84-0.37,0.63-1.36,2.32c0.04-0.15,0.09-0.23-0.22,0.32
				c0.09,0.01,0.08-0.02,0.19-0.08c0.01,0.08-0.05,0.22-0.12,0.32c0.09-0.21-0.11-0.07-0.2-0.02c0.03,0,0.03,0.04,0.06,0.05
				c-0.27,0.13,0.11,0.13-0.18,0.36c-0.05-0.06-0.1-0.13-0.51,0.64c0.04-0.08,0.11-0.18,0.14-0.27c-0.21,0.09-0.2,0.13-0.31,0.23
				l0.01-0.01l-0.26,0.23l0.08-0.05c0.03,0.35-0.3,0.42-0.4,0.79c0.07-0.16-0.08-0.37-0.57,0.29c0.07-0.13-0.78,1.06-2.04,2.19
				c-0.01-0.15,0-0.31-0.61,0.56l0.12-0.06c-0.05-0.03-0.1-0.2,0.2-0.21l-0.09,0.1c0.24,0.06,0.38-0.38,0.58-0.56
				c0.05,0.1,0.07,0.21,0.59-0.32c-0.01,0.05,0.09,0.05-0.01,0.12c0.31-0.17,0.32-0.46,0.49-0.47l-0.01,0.03
				c0.36-0.36,1.01-0.82,2.23-2.51c0.02,0.04,0.41-0.41,0.62-0.61l0.01,0.02c-0.07-0.12,0.12-0.34,0.22-0.53
				c0.06,0.05,0.06,0.1,0.02,0.21c0-0.04,0.07,0.02,0.19-0.4c0.17,0.34,0.02-0.15,0.45-0.93l-0.01,0.17c0.01-0.07,0.25-0.4,0.1-0.08
				c0.39-0.51,0.17-0.21,0.73-1.37l0,0c-0.02-0.32,0.71-1.01,1.1-2.63c0,0.22,0.07-0.05-0.02,0.22c0.1-0.25,0.18-0.29,0.16-0.59
				c0.06,0.12,0.15-0.17,0.16-0.3l-0.1,0.17c0.02-0.14,0.06-0.26,0.09-0.39l0.1,0.09c0.16-0.55,0.28-1.23,0.23-2.52
				C462.82,276.55,462.83,275.93,463,276.24z M444.19,284.1L444.19,284.1C444.19,284.1,444.19,284.1,444.19,284.1
				C444.19,284.1,444.19,284.1,444.19,284.1z M449.25,283.75c-0.02,0.01,0,0-0.05,0.01C449.16,283.73,449.13,283.61,449.25,283.75z
				 M449.2,282.86c0.03,0-0.02-0.08-0.04-0.14C449.13,282.71,449.37,282.86,449.2,282.86z M449.43,283c0.03,0.02,0,0,0.07,0.06
				C449.49,283.05,449.47,283.03,449.43,283z M451.92,286.85c0.04,0.09,0.07,0.2,0.11,0.3
				C451.97,287.09,451.92,286.96,451.92,286.85z M452.03,287.38c0.02-0.08,0.02-0.11,0.04-0.13c0.02,0.06,0.04,0.11,0.06,0.17
				C452.09,287.37,452.05,287.34,452.03,287.38z M452.15,287.5c0.03,0.09,0.06,0.19,0.09,0.29
				C452.11,287.7,451.92,287.48,452.15,287.5z M452.25,288.04c0.02,0.01,0.05,0.03,0.04,0.05c0-0.03,0-0.05,0-0.07
				c0.04,0.13,0.07,0.26,0.1,0.4C452.36,288.29,452.33,288.14,452.25,288.04z M452.49,288.91c0.02,0.12,0.04,0.23,0.05,0.35
				c-0.05-0.05-0.11-0.14-0.13-0.22C452.52,289.12,452.5,289.02,452.49,288.91z M452.54,289.33c0.02,0.17,0.03,0.35,0.03,0.54
				c-0.02-0.09-0.04-0.17-0.1-0.2C452.55,289.57,452.47,289.38,452.54,289.33z M452.62,290.57c-0.03-0.02,0.07,0.08,0,0.12
				c-0.05-0.21,0.01-0.11-0.12-0.44c0.29,0.02-0.09-0.5,0.09-0.15l0.07-0.07C452.66,290.21,452.71,290.6,452.62,290.57z
				 M449.09,280.36c-0.02-0.02-0.04-0.06-0.08-0.11C449.05,280.29,449.01,280.25,449.09,280.36z M448.77,279.97
				c0.03,0.01-0.01-0.11-0.03-0.19C448.68,279.74,448.95,280.02,448.77,279.97z M448.7,281c0.03,0.03-0.01,0.02,0.1,0.13
				C448.73,281.11,448.72,281.13,448.7,281z M455.87,272.52C455.97,272.54,455.93,272.56,455.87,272.52L455.87,272.52z
				 M455.9,272.01c0,0.11,0.07,0.11-0.11,0.35C455.79,272.28,455.82,272.16,455.9,272.01z"
                />
                <path
                  className="st10"
                  d="M454.8,289.75c0.08-0.08,0.09-0.11-0.16-0.22l0.26,0.05l-0.1-0.04c0.01-0.01,0.02-0.02,0.06,0
				c-0.06-0.05-0.05-0.01-0.11-0.05c0.1,0-0.01-0.1,0.15-0.06c-0.07-0.04-0.07,0.01-0.11-0.04c0.2,0.08-0.06-0.12,0.19-0.01
				c-0.02-0.01-0.03-0.02-0.04-0.03c0.03,0.01,0.06,0.01,0.05-0.07c0.1-0.01,0.09,0.13-0.03-0.11c0.04,0.03-0.01-0.03,0.17-0.08
				c-0.12-0.05-0.08-0.08-0.1-0.12c0.07,0.02,0.1,0.04,0.13,0.06c0-0.04,0.01-0.03-0.06-0.08l0.09,0.03
				c0.01-0.04-0.08-0.11-0.01-0.14c-0.09-0.03-0.25-0.03-0.29-0.1c0.08,0.02,0.07,0.05,0.36,0.04c-0.03-0.01-0.2-0.09,0.03-0.05
				c-0.04-0.02,0.01-0.03-0.09-0.06c0-0.01,0.05,0,0.08,0c-0.04-0.03,0.01-0.01-0.11-0.1l0,0c-0.12-0.12,0.3-0.3,0.5-0.91
				c-0.03-0.04-0.06-0.03-0.09-0.01c0.16-0.11-0.05-0.04,0.25-0.29l0.03,0.03c-0.02-0.02-0.01-0.03-0.01-0.04l-0.08,0
				c0.02-0.01,0.02-0.02,0.05-0.01c-0.05-0.03-0.14-0.02-0.2-0.06c0.05-0.01-0.03-0.06,0.08-0.05c-0.05,0.05,0.11,0.02,0.12,0.07
				c0.08-0.07,0.12,0-0.07-0.13c0.06,0.02,0.12,0.01,0.11,0c-0.01,0.02-0.1-0.01-0.15-0.04c0.06-0.03-0.03-0.05-0.02-0.06
				c-0.05,0.01-0.04,0.03-0.09-0.05c0.05,0.01,0.03,0.03,0.05,0.02c-0.1-0.05,0.12-0.04,0.06-0.09c0.05-0.01,0.11,0.02,0.15,0.01
				c-0.07-0.08,0.12-0.08-0.1-0.13l0.04,0.09c-0.18-0.02-0.15-0.26,0.08-0.32c-0.05-0.01,0.01-0.05-0.12-0.08
				c0.3,0.05-0.23-0.24,0.12-0.11c0-0.02,0.02-0.03-0.03-0.04c0.01,0.03,0.01-0.11-0.19-0.15c0.22-0.02,0.01-0.16,0.2-0.18
				c-0.02-0.04-0.03-0.1-0.03-0.13c-0.1,0.07,0.24-0.11-0.04-0.44c0.09,0.03,0.04,0.03,0.13,0.03c-0.38-0.08-0.03-0.14,0.06-0.3
				c-0.03-0.03-0.12-0.1-0.14-0.14c0.1,0.04,0.14,0.09,0.2,0.13c-0.17-0.19-0.11,0.03-0.04-0.64c0.05,0.01,0.14,0.05,0.19,0.02
				c-0.08,0.02-0.11-0.04-0.19-0.02c0.01-0.04,0.09-0.06,0.36-0.07c-0.08,0-0.11-0.03-0.08-0.05c-0.03,0.06-0.09-0.13,0.04-0.06
				c-0.04-0.04,0.01-0.13-0.16-0.18c0.23,0.05,0.1-0.19-0.06-0.54c0.14-0.05,0.44-0.02,0.08-0.34c0.17-0.05,0.08-0.18,0.17-0.27
				c-0.03,0.01-0.12-0.01-0.13-0.02c0.03-0.02-0.11-0.06,0.04-0.05l0,0.01c0.14-0.01,0.1-0.06,0.11-0.09l-0.18-0.01
				c0.27-0.08-0.1-0.23,0.14-0.27c-0.05-0.02-0.05-0.05-0.09-0.07c0.05-0.02,0.15,0.04,0.11-0.16c0.32,0.06-0.01-0.2-0.12-0.4
				c0.13,0,0.01-0.07-0.05-0.11l0.1,0c-0.07-0.02-0.16-0.07-0.11-0.25c-0.07,0-0.07-0.09,0-0.18c0,0.05,0,0.09-0.01,0.08
				c0.02,0.04,0.27-0.27-0.02-0.25c0.01,0,0,0.01,0.03,0.02l-0.01,0c0,0.01,0.01,0.03,0.01,0.05c-0.05-0.01-0.09-0.03-0.04-0.05
				c-0.06-0.01-0.13-0.01-0.16-0.02c0.02-0.05,0.32-0.12,0.03-0.37c0.06-0.05,0.12,0.02,0.1-0.04c0.26-0.1,0.04,0-0.05-0.3
				c-0.02,0.01-0.02,0.02-0.04,0.02c0.01-0.05,0.19-0.24-0.08-0.51l0.1-0.02c-0.24,0.04-0.03-0.17-0.13-0.2
				c0.01-0.01,0.02-0.07,0.05-0.11l-0.1-0.05c0.19-0.08,0.43-0.25-0.54-0.02l0.1,0.03l-0.08,0.02l-0.01-0.04
				c-0.07,0.03,0,0.03,0.04,0.04c-0.04,0.01-0.07,0.02-0.08,0.03l0.16,0.02c-0.06,0.01-0.13,0-0.16,0.03
				c0.07,0.01,0.14-0.04,0.19-0.01c-0.07,0.03-0.13,0.03-0.07,0.05c-0.04,0.01-0.07,0.01-0.09,0c0.05,0.02,0.03,0.05,0.12,0.05
				c-0.09,0.03,0.03,0.07-0.12,0.09c0.04,0,0.12,0.02,0.13,0.04c-0.19-0.02,0,0.08-0.2,0.09c0.17,0.01,0.08,0.09,0.18,0.13
				c-0.01,0-0.04,0-0.03-0.01c-0.01,0.03-0.06,0.04-0.09,0.06c0.14-0.01,0.17,0.03,0.26,0.04c-0.13,0.05-0.16,0-0.29,0.02
				c0.11-0.01,0.12,0.05,0.21,0.03c-0.08,0.03-0.04,0.03-0.15,0.05c0.17-0.01,0.03,0.02,0.14,0.03c-0.08,0.04-0.16,0.02-0.1,0.06
				c-0.24,0.04,0.04,0.02,0.17,0.25c-0.15,0.01,0.24,0.03,0.09,0.02l0.04,0.02c-0.12,0.03-0.04-0.02-0.13-0.02
				c0.03,0.02,0.18,0.16-0.03,0.08l0.04-0.05c-0.1,0.1-0.21,0.18,0.04,0.62c-0.04,0-0.07-0.01-0.11,0.01
				c0.13-0.05,0.18,0.31,0.03,0.9c0.1,0,0.17,0.22-0.03,0.28l0.11,0.04l-0.13,0.02c0.15,0.06-0.17,0,0.07,0.19
				c-0.04,0-0.06-0.02-0.09-0.03c0.16,0.04-0.15,0.04,0.07,0.06c-0.07,0.02-0.07,0.07-0.04,0.11c-0.09,0.02-0.1-0.04-0.05,0.21
				c0.04-0.03,0.16-0.02,0.18,0.01l-0.11,0.01c0.07,0.02,0.04-0.02,0.13,0.01c-0.01,0.03-0.13,0.01-0.17,0l0.04,0.04
				c-0.03,0-0.05-0.01-0.1-0.01c0.07,0.02-0.12,0.05,0.01,0.08c-0.29-0.3,0.25,0.9-0.13,1.41c0.01,0,0.09,0,0.07,0.02
				c0.15-0.02-0.2,0.29-0.07,0.94c-0.03-0.01-0.06-0.02-0.06-0.03c-0.09,0.01-0.02,0.09,0.05,0.1c-0.08-0.02-0.11,0.05-0.12,0.06
				c0.04,0.02-0.02,0.07,0.08,0.07c-0.05,0.04,0.03,0.09-0.03,0.11c0.1,0.04-0.06,0.06,0.09,0.1c-0.16-0.03-0.14,0.05-0.07,0.09
				c-0.05-0.02-0.05,0.02-0.12-0.01c0,0.03,0.02,0.06,0.1,0.07c-0.2,0.02,0.13,0.14-0.12,0.13c0.07,0.11,0.11,0.2,0.13,0.29
				c-0.15,0.01-0.09-0.05-0.11,0.31c-0.01,0-0.03-0.01-0.04-0.02c0,0.01-0.03,0.03,0.04,0.04l-0.02-0.02c0.11,0,0.2,0.1,0.25,0.11
				c-0.2-0.07-0.07,0.06-0.31-0.03c0.01,0.01,0.04,0.03,0.09,0.04c-0.12,0.06-0.03,0-0.15,0.33c0.02-0.02-0.04,0.06,0.09,0.05
				c-0.05-0.02,0.09,0.15-0.02,0.07l0.01,0.07c0.03-0.03,0.29,0.03,0.08,0.08c0.03,0.05,0.09,0.1-0.13,0.03
				c0.1,0.04,0.01,0.08,0.12,0.13c-0.02-0.01-0.09-0.03-0.11-0.03l0.08,0.03c-0.13-0.02-0.07,0.05-0.05,0.04
				c-0.05-0.01,0.02,0.26-0.16,0.1c0.01,0.08,0.12,0.19,0.06,0.25c0.22,0.03,0.01,0-0.01,0.08c-0.01-0.01-0.1-0.04-0.08-0.03
				c-0.04,0.04,0.02,0.11,0.05,0.2c-0.06-0.01-0.06-0.05-0.12,0.07c0.04,0.02,0.03,0.04,0.1,0.05c-0.15,0.01-0.32,0.13,0.01,0.37
				c-0.04-0.05-0.12-0.04-0.17-0.04l0.07,0.02c-0.21-0.04,0.13,0.08,0.01,0.06c0.02,0.03,0.06,0.12-0.06,0.02
				c0,0.09,0.01-0.01-0.15,0.1c0.29,0.09,0.01,0,0.19,0.18c-0.14-0.08,0.01-0.03-0.28-0.07c0.07,0.05-0.08,0.02,0.03,0.09
				c-0.03-0.01-0.01,0.02-0.09-0.01c0.09-0.21,0.22,0.39-0.06,0.4c0.02,0.04,0.07,0.08,0.09,0.12c-0.06-0.01-0.08-0.05-0.11-0.07
				c0.02,0,0,0.07,0.13,0.12c-0.01-0.04,0.02-0.15,0.17-0.07C454.64,289.81,454.65,289.68,454.8,289.75z M456.26,281.28
				c0.02,0.01-0.02,0.03-0.05,0C456.23,281.28,456.24,281.28,456.26,281.28z M455.59,281.49c0-0.01,0,0,0-0.03
				C455.61,281.46,455.7,281.44,455.59,281.49z"
                />
                <path
                  className="st10"
                  d="M484.23,282.34c-0.46-1.24,0.08-0.02-2.37-3.12c0.03-0.01,0.01-0.03,0.07-0.01
				c0.01-0.65-1.2,0.72-1.05-1.64c0.03,0.01,0.04,0.01,0.06,0.02c-0.06-0.07-0.11-0.24,0.07-0.62c0.01,0.11,0.03,0.23,0.03,0.34
				c0.12-0.18-0.04-0.44-0.07-0.62l0.06,0.01c-0.2-0.52-0.31-0.49-0.68-0.91l0.01,0.07c-0.72-0.31-0.02-0.65-1.53-0.57
				c0.19-0.11,0.63-0.11,0.92-0.05c-0.2-0.1-0.71-0.16-0.81-0.09c0.08-0.01,0.19-0.03,0.27,0c-1.6,0.33-0.13-0.21-4.02,1.45
				c0.34,0-0.17,0.14-0.01,0.28c-0.07,0.07,0,0-0.21,0.16c0,0,0,0,0,0c-0.14,0.08-0.29,0.11-0.47,0.15
				c0.02-0.07,0.05-0.17,0.11-0.33l0,0.16c0.33-0.6,0.25-1.03,0.33-1.58c0.16,0.04,0.3-0.5,0.32-0.79l0.02,0.01
				c-1.39,1.72,2.64-6.22-7.48-2.83l0.05-0.14c-0.6,0.95-0.35,0.38-1.53,2.09c-0.01-0.02-0.04-0.06,0-0.14
				c-0.15,0.23-0.61,0.86-0.38,0.87c-0.12-0.06-0.28,0.52-0.36,0.82l-0.07-0.08c0.02,0.04-0.08,0.38-0.31,1.11l0-0.14
				c-0.23,0.53-0.19,0.44-1.02,2.18c0.17-0.34,0.3-0.62,0.29-0.59c-0.08-0.04-0.22,0.23-0.23-0.04c-0.08,0.24,0.07,0.02-0.3,0.97
				l0-0.03c-0.16,0.39-0.14,0.19-0.46,0.82c0.09,0.03-0.01,0.2,0.15,0.07c-0.03,0.04,0.03-0.06,0.06-0.19l0,0.01
				c0.04-0.09,0.1-0.21,0.16-0.35c-0.25,1.03-0.44,0.58-1.29,2.6c0.09-0.36,0.12-0.33-0.44,0.8l0-0.07
				c-0.63,0.67-0.47,1.44-1.27,2.12c0.05-0.05,0.08-0.07,0.1-0.07c-0.46,0.2-1.49,2.46-3.97,4.25l0.1,0.01
				c-0.71,0.22-0.67,0.74-1.3,1.55c0.47-0.07,0.96-0.97,1.13-0.71c0.04-0.14,0.41-0.05,1.22-1.1c-0.1,0.34,0.46-0.41,0.53-0.21
				c0.06-0.27,0.53-0.61,0.73-0.97c-0.01,0.02-0.02,0.07-0.05,0.09c0.16-0.15,0.24-0.16,0.37-0.25c-0.16,0.21,0.29-1.11,0.34-0.34
				c-0.09-0.06,0.22-0.43,0.09-0.42c0.2-0.11,0.15-0.11,0.3-0.12c-0.24,0.01,0.58-1,0.46-0.45c0.15-0.78,0.12,0,1.13-2.04
				c-0.15-0.06-0.24-0.04,0.01-0.33c0.63,0.07,0.14,0.25,1.48-2.33l0.06,0.17c0.36-1.15,0.66-1.14,2.92-6.26l-0.01,0.09
				c0.2-0.3,0-0.46,0.25-0.71l-0.02,0.06c0.6-0.23-0.41-0.79,3.11-2.95l0.04,0.1c0.08-0.13,0.15-0.11,0.56-0.19
				c-0.13-0.13,0.17,0.07,1.22-0.15c-0.02,0.04-0.13,0.05-0.22,0.08c0.61-0.03,0.11-0.06,1.17,0.18l-0.07,0.05
				c0.4,0.17,0.83,0.42,1.14,0.82c0.59,1.52,1.12,2.6-3.67,9.83c0.17-0.12,0.86-0.59,3.27-2.84c0.01,0.01,0.01,0.02,0.03,0.03
				c0.14-0.2,0.04-0.09,0.39-0.42l-0.02,0.03c1.22-1.08-0.15-0.3,3.39-2.54l-0.06-0.04c0.09-0.12,0.17-0.05,0.26-0.08
				c-0.15-0.16,2.05-1.28,3.11,0.78c-0.04-0.04-0.09-0.08-0.14-0.12c0.12,0.5,0.06,1.38-0.52,3.08l0.46-0.16
				c-1.11,0.74,1.21-0.88,2.99,0.99c-0.14,0.07,0.16,0.52,0.26,0.7c-0.06-0.05,0,0.17-0.09,0.12c0.05,0.2,0.1,0.39,0.17,0.34
				c-0.33,1.1,0.09,0.85-0.96,2.86c-0.01-0.1,0.01-0.19,0.04-0.27c-0.33,0.35-0.24,0.53-1.84,2.04c0.02,0.01,0.08,0-0.2,0.51
				c0.01-0.28-0.46,0.32-0.4-0.07c-0.05,0.06-0.1,0.14-0.05,0.17c-0.09,0.06-0.14,0.06-0.09-0.05c-0.28,0.29-0.13,0.25-0.42,0.47
				c-0.02-0.03,0.03-0.08,0.03-0.14c-0.1,0.16-0.21,0.33-0.47,0.49c0-0.03-0.04-0.03,0.03-0.08c-0.41,0.33-0.61,0.68-1.14,0.94
				c0.04-0.12-0.02-0.22-0.53,0.18l0.14-0.02c-0.23,0.15-0.38,0.36-0.61,0.44l0.15-0.15l-0.43,0.28l0.27-0.1
				c-0.2,0.2,0.11,0.13-0.22,0.28c0.06-0.05-0.24,0.14-0.4,0.14l0,0c-0.47,0.4-0.22-0.12-1.11,0.63c0.05-0.22-0.43,0.04-0.48,0.22
				c0.03-0.02,0.01-0.03,0.07-0.07c-0.45,0.22-0.64,0.29-0.79,0.33l0.12-0.07c-0.57,0.22,0.04-0.03-1.98,0.88l0.11-0.03
				c-0.57,0.37-0.45-0.07-1.95,0.69l0.06-0.07c-1.04,0.36-0.85,0.19-3.09,0.75c0.16-0.09,0.26-0.11-0.45,0.09
				c0.06,0.07,0.09,0.04,0.22,0.07c-0.06,0.07-0.22,0.13-0.37,0.15c0.25-0.09-0.02-0.13-0.15-0.15c0.02,0.02-0.01,0.05,0,0.08
				c-0.33-0.1-0.03,0.17-0.46,0.14c0.02-0.08,0.06-0.16-0.95,0.12c0.1-0.04,0.24-0.05,0.34-0.09c-0.25-0.07-0.27-0.05-0.45-0.05
				l0.02,0l-0.41-0.01l0.11,0.02c-0.27,0.27-0.6,0.1-0.99,0.3c0.38-0.19,0.06-0.09,0.08-0.2c-0.1-0.02-0.26-0.05-0.22-0.08
				c-0.18,0.09-0.32-0.05-0.58,0.08c-0.01-0.02-0.1-0.01-0.14-0.03c0.51,0.07-1.73,0.22-3.37,0.12c0.13-0.13,0.26-0.2-0.97-0.08
				l0.15,0.05c-0.01-0.06,0.09-0.2,0.34,0l-0.16,0.01c0.14,0.21,0.63,0.01,0.95,0.04c-0.05,0.11-0.16,0.19,0.75,0.22
				c-0.04,0.03,0.03,0.1-0.1,0.08c0.4,0.09,0.65-0.1,0.8,0.01l-0.03,0.01c1.15,0.16,1.01-0.2,3.25-0.14
				c-0.01,0.14-0.12-0.05,0.69-0.11c-0.02,0.06,0.67-0.02,1.01-0.03l-0.01,0.02c0.04-0.14,0.39-0.16,0.63-0.23
				c0,0.08-0.04,0.11-0.16,0.17c0.03-0.03,0.04,0.07,0.5-0.16c-0.11,0.27,0.12,0.06,1.15-0.37l-0.15,0.11
				c0.07-0.05,0.54-0.11,0.15,0.01c0.74-0.1,0.31-0.03,1.76-0.49l0,0c0.27-0.24,1.4-0.25,3.17-1.06c-0.2,0.15,0.1,0.02-0.21,0.13
				c0.3-0.08,0.39-0.07,0.66-0.29c-0.07,0.13,0.26,0.01,0.39-0.08l-0.23,0.03c0.15-0.08,0.28-0.13,0.42-0.19l-0.03,0.14
				c0.62-0.23,1.33-0.59,2.57-1.44c-0.39-0.1,0.8-0.13,0.55-0.27c0.36-0.14,0.5-0.32,0.85-0.5c-0.06-0.03,0.74-0.89,0.75-0.41
				c0.24-0.28,0.57-0.36,0.74-0.47l-0.13,0.14c0.08-0.08,0.15-0.14,0.23-0.19l-0.14,0c0.1-0.05,0.17-0.11,0.19-0.08
				c0.09-0.25-0.17-0.21,0.42-0.5c-0.37,0.25,0.07,0.08-0.16,0.29c0.42-0.24,0.26-0.05,0.61-0.61c0,0.09,0.16,0.02,0.21-0.04
				c-0.11,0.09-0.08-0.14,0.3-0.5l-0.15,0.1c0.1-0.35,0.69-0.41,1.6-1.3c-0.14,0.03-0.13,0.06-0.02-0.2
				c-0.12,0.17-0.24,0.34-0.37,0.5l0.18-0.38c-0.12,0.14-0.23,0.33-0.32,0.4c0.08-0.18,0.08-0.16-0.03-0.14
				c0.62-0.67,0.68-0.04,1.44-1.64l-0.12,0.15c0.38-0.89,0.34-0.11,0.54-2.42C484.12,282.24,484.17,282.02,484.23,282.34z
				 M463.66,279.31c-0.02-0.09-0.04-0.08,0.06-0.18C463.71,279.18,463.7,279.12,463.66,279.31z M464.5,279.05
				c0.01-0.03,0.03-0.07,0.06-0.15C464.54,278.96,464.56,278.9,464.5,279.05z M464.68,278.47c0.01,0.04,0.08-0.07,0.14-0.15
				C464.8,278.4,464.72,278.64,464.68,278.47z M473.47,277.88c0.02,0.01,0.03,0.01,0.04,0.02c-0.04,0.03,0.01,0-0.12,0.14
				L473.47,277.88z"
                />
                <path
                  className="st10"
                  d="M456.38,290.95l-0.03-0.1c0.2,0.18-0.38-0.09-0.69,0.35c0.01-0.01,0.01-0.01,0.01-0.01
				c0.02,0.1,0.06,0.23,0.12,0.43l0.05-0.11l0.03,0.07l-0.05,0.01c0.04,0.07,0.05,0,0.06-0.05c0.01,0.04,0.03,0.07,0.04,0.08
				l0.04-0.16c0.02,0.06-0.01,0.13,0.04,0.16c0.02-0.08-0.05-0.14-0.01-0.19c0.04,0.07,0.04,0.13,0.07,0.06
				c0.01,0.04,0.01,0.07,0,0.09c0.02-0.06,0.08-0.04,0.08-0.12c0.05,0.08,0.1-0.04,0.12,0.11c0-0.04,0.03-0.13,0.06-0.14
				c-0.02,0.19,0.11-0.01,0.13,0.19c0.01-0.17,0.13-0.1,0.18-0.2c0,0.01,0,0.04-0.01,0.03c0.04,0.01,0.06,0.05,0.09,0.08
				c-0.02-0.14,0.03-0.18,0.05-0.27c0.07,0.12,0,0.16,0.04,0.29c-0.02-0.11,0.06-0.13,0.03-0.21c0.05,0.07,0.04,0.03,0.08,0.14
				c-0.03-0.17,0.03-0.04,0.04-0.15c0.07,0.07,0.05,0.15,0.09,0.09c0.08,0.26-0.02-0.07,0.36-0.2c0.02-0.01-0.1-0.17,0.01-0.18
				c-0.02,0-0.01,0.03-0.01,0.06l0.02-0.04c0.05,0.18-0.1,0.13,0.12,0.15l-0.07-0.03c-0.24,0.26,0.49-0.29,2.18-0.48
				c-0.01-0.09,0.16-0.25,0.4-0.11l0.01-0.12l0.07,0.11c0.04-0.23-0.03,0.18,0.25-0.15c0.01,0.04-0.01,0.06-0.01,0.09
				c0-0.17,0.1,0.13,0.06-0.1c0.05,0.05,0.12,0.03,0.16-0.02c0.07,0.1,0,0.09,0.3-0.07c-0.05-0.02-0.08-0.14-0.06-0.17l0.05,0.1
				c-0.01-0.07-0.05-0.24,0.03,0.03l0.03-0.05c0.01,0.03,0.01,0.05,0.02,0.09c-0.01-0.07,0.12,0.08,0.11-0.05
				c-0.25,0.38,1.03-0.67,1.96-0.78c-0.01-0.02-0.08-0.14,0.04-0.03c-0.25-0.15,0.86-0.34,0.74-0.36c0.09-0.2,0.32-0.15,0.42-0.34
				c0,0.03,0.02,0.06,0,0.07c0.07,0.06,0.12-0.06,0.09-0.12c-0.11,0.05,0.31-0.05,0.4-0.29c0.07,0.14,0.16,0.06,0.16-0.03
				c0.01,0.05,0.05,0.03,0.07,0.09c0.04-0.03,0.06-0.07,0.01-0.14c0.17,0.12,0.07-0.22,0.23-0.03c0.16-0.17-0.11-0.09,0.66-0.56
				c0.01,0.01,0.01,0.03,0,0.05c0.01-0.02,0.05-0.01,0.01-0.07l-0.01,0.03c-0.08-0.07-0.05-0.23-0.07-0.28
				c0.08,0.19,0.11-0.01,0.21,0.24c0-0.02,0-0.06-0.03-0.1c0.18,0.02,0.03,0,0.48-0.21c-0.03,0,0.1-0.04-0.03-0.1
				c0.02,0.06,0.07-0.2,0.08-0.05l0.06-0.07c-0.07-0.03-0.08-0.01-0.09-0.15c0.09,0.04,0.1-0.03,0.24,0.07
				c-0.04-0.1,0.08-0.09,0.04-0.2c0.01,0.03,0.05,0.08,0.06,0.09l-0.03-0.08c0.02,0.1,0.15-0.17,0.27,0l-0.03-0.07
				c0.08,0.13,0.09-0.28,0.35-0.31c0,0.02,0.05,0.09,0.04,0.07c0.08-0.02,0.09-0.13,0.14-0.24c-0.03,0.04,0.24,0.05,0.12-0.11
				c0.14,0.05,0.38,0.1,0.33-0.41c0,0.07,0.08,0.1,0.13,0.12c-0.02-0.02-0.03-0.04-0.05-0.06c0.16,0.14-0.05-0.14,0.05-0.07
				c0.01-0.03,0.05-0.17,0.08,0.01c0.09-0.1-0.04,0.01,0.24-0.04c-0.19-0.23-0.01-0.01-0.02-0.28c0.05,0.15-0.02,0.04,0.2,0.21
				c-0.03-0.09,0.09,0.02,0.05-0.11c0.02,0.02,0.03-0.02,0.08,0.05c-0.29,0.14,0.17-0.53,0.39-0.44c0.01-0.05-0.01-0.12,0-0.17
				c0.05,0.04,0.04,0.08,0.06,0.12c-0.02-0.01,0.05-0.08-0.03-0.18c-0.03,0.05-0.13,0.16-0.22,0.01c0.02,0-0.07,0.15-0.18,0.01
				c-0.12,0.04-0.18,0.08-0.02,0.31l-0.21-0.17l0.06,0.09c-0.01,0-0.03,0.01-0.06-0.02c0.02,0.08,0.03,0.03,0.06,0.1
				c-0.1-0.04-0.08,0.12-0.19,0c0.04,0.07,0.08,0.02,0.07,0.09c-0.12-0.18-0.04,0.16-0.19-0.08c0.02,0.2-0.2-0.04-0.15,0.24
				c-0.02-0.06-0.01,0.04-0.22,0c0.07,0.11,0.01,0.13-0.01,0.18c-0.05-0.06-0.06-0.09-0.06-0.13c-0.02,0.03-0.03,0.02-0.02,0.12
				l-0.05-0.08c-0.04,0.04-0.02,0.16-0.11,0.15c0.05,0.07,0.2,0.16,0.17,0.26c-0.03-0.04-0.08-0.1-0.08-0.13
				c-0.12,0,0.08,0.05-0.21-0.1c0.02,0.03,0.09,0.19-0.07,0.03c0.02,0.05-0.04,0.03,0.02,0.11c-0.01,0.01-0.04-0.03-0.07-0.05
				c0.01,0.05,0,0,0,0.17l0,0c0.17,0.12-0.24,0.03-0.59,0.33c0.03-0.07-0.2-0.04-0.04,0c-0.35,0.01-0.3,0.53-0.56,0.28l0.02,0.04
				c-0.04-0.02-0.1,0.02-0.12-0.04c-0.01,0.06,0.02,0.07,0.06,0.07c-0.09-0.01-0.09,0.06-0.17,0.06c0.07,0.07-0.06,0.35-0.17,0.02
				c-0.01,0.1-0.13,0.02-0.16,0.02l0.01-0.05c-0.01,0.03-0.02,0.04-0.03,0.04l0.06,0.06c-0.02,0-0.04,0.01-0.06-0.02
				c0.01,0.06,0.09,0.11,0.09,0.19c-0.04-0.02-0.04,0.08-0.11-0.01c0.09-0.01-0.07-0.09-0.02-0.14c-0.12,0.01-0.09-0.08-0.08,0.16
				c-0.02-0.06-0.08-0.09-0.08-0.07c0.03-0.02,0.07,0.08,0.07,0.14c-0.07-0.01-0.03,0.06-0.05,0.07c0.05,0.02,0.06,0.01,0.02,0.1
				c-0.03-0.04,0.01-0.05-0.01-0.06c0.02,0.11-0.14-0.05-0.14,0.04c-0.04-0.02-0.06-0.1-0.1-0.11c0.03,0.13-0.18-0.09-0.07,0.19
				l0.06-0.1c0.01,0.19,0.14,0.16-0.3,0.22c0.04,0.03,0.09,0.03-0.01,0.1c-0.03-0.06-0.06-0.07-0.1-0.1c0.03,0.04-0.07,0.03,0,0.16
				c-0.11-0.04-0.13-0.14-0.14,0.18c-0.02-0.08-0.07-0.1-0.05-0.17c-0.04,0.09,0.01-0.02-0.06,0.32c-0.18-0.15-0.19,0.12-0.34-0.01
				c-0.22,0.28,0.01,0.14-0.62,0.5c-0.02-0.09,0.01-0.06-0.05-0.13c-0.02,0.02,0.07,0.13,0.03,0.17c0.19,0.08-0.4-0.25-0.51,0.22
				c-0.01-0.11,0.02-0.18,0.04-0.26c-0.03,0.01-0.09,0.06-0.08,0.13c0.04-0.02,0.05-0.03-0.04,0.17c-0.1-0.25-0.18,0.01-0.31-0.16
				c-0.06,0.14-0.22,0.05-0.34,0.12c0.07,0.02,0.03,0.32-0.03,0.08c0.01,0.03,0,0.07,0.01,0.1c-0.06-0.02-0.14-0.09-0.24-0.24
				c0.04,0.07,0.02,0.11-0.02,0.1c0.1,0-0.11,0.16-0.1,0.01c-0.03,0.06-0.16,0.07-0.14,0.25c-0.05-0.44-0.27,0.29-1.59,0.59l0.01,0
				c-0.07-0.12-0.11-0.07-0.15-0.06l0.05,0.18c-0.2-0.22-0.27,0.19-0.41-0.02c-0.01,0.05-0.05,0.06-0.06,0.12
				c-0.05-0.07-0.01-0.14-0.24-0.04c-0.03-0.3-0.23,0.07-0.5,0.27c-0.04-0.13-0.1,0.02-0.14,0.09c-0.04-0.14,0.05-0.15-0.33,0.1
				c0,0.07-0.09,0.09-0.24,0.06c0.07-0.01,0.12-0.02,0.12-0.01c0.05-0.07-0.36-0.2-0.34,0.09c-0.01,0,0.01-0.01,0.03-0.03l0,0.01
				c0.02-0.01,0.04-0.01,0.06-0.02c-0.01,0.05-0.02,0.1-0.06,0.05c0,0.07,0,0.13,0,0.16c-0.08-0.01-0.19-0.28-0.52,0.08
				c-0.26-0.31-0.03-0.15-0.48,0.03c0.02,0.01,0.02,0.02,0.03,0.04C456.98,290.78,456.84,290.62,456.38,290.95z M458.45,290.91
				c-0.03,0-0.07,0.03-0.06-0.06C458.39,290.88,458.43,290.89,458.45,290.91z M458.05,290.36c0-0.02,0-0.04-0.01-0.05
				C458.05,290.3,458.09,290.32,458.05,290.36z"
                />
                <path
                  className="st10"
                  d="M456.02,296.18c-0.02-0.03-0.1-0.01-0.17-0.03c0.15-0.2,0.11-0.08,0.4-0.46c0.03,0.05-0.1,0.48,0.12-0.02
				c-0.02,0.1-0.05,0.22-0.08,0.41l0.09-0.07l-0.01,0.08l-0.05-0.01c0,0.08,0.04,0.02,0.07-0.01c-0.01,0.04-0.01,0.07-0.01,0.09
				l0.1-0.13c-0.01,0.06-0.06,0.11-0.04,0.16c0.05-0.06,0.02-0.14,0.08-0.17c0,0.08-0.03,0.13,0.03,0.09
				c-0.01,0.04-0.02,0.06-0.04,0.08c0.04-0.04,0.08,0.01,0.12-0.07c0,0.1,0.1,0.01,0.05,0.16c0.02-0.03,0.09-0.1,0.12-0.09
				c-0.11,0.16,0.09,0.04,0.01,0.22c0.09-0.14,0.15-0.02,0.24-0.09c-0.01,0.01-0.02,0.03-0.03,0.02c0.03,0.03,0.02,0.08,0.03,0.11
				c0.06-0.13,0.12-0.13,0.18-0.2c-0.01,0.14-0.09,0.14-0.12,0.26c0.04-0.1,0.11-0.07,0.13-0.16c0,0.09,0.01,0.05-0.02,0.16
				c0.07-0.16,0.04-0.01,0.11-0.1c0.01,0.1-0.05,0.15,0.02,0.13c-0.07,0.23,0.04-0.02,0.38,0c0,0.06-0.02,0.04,0.11-0.11
				c-0.01-0.01-0.03,0.02-0.05,0.04l0.04-0.02c-0.07,0.17-0.15,0.05-0.01,0.19l-0.03-0.07c-0.01,0.28,0.47,0,1.72,1.11
				c0.06-0.07,0.3-0.01,0.29,0.25l0.11-0.06l-0.06,0.12c0.16-0.08-0.12,0.13,0.25,0.1c-0.03,0.03-0.05,0.03-0.08,0.04
				c0.14-0.1-0.05,0.15,0.11-0.01c-0.02,0.07,0.04,0.11,0.09,0.12c-0.05,0.11-0.08,0.05,0.19,0.21c-0.01-0.05,0.08-0.14,0.11-0.13
				l-0.06,0.1c0.06-0.04,0.18-0.17-0.01,0.04l0.06,0c-0.02,0.03-0.04,0.03-0.07,0.07c0.06-0.04-0.01,0.14,0.09,0.06
				c-0.46-0.01,1.02,0.52,1.33,1.37c0.01-0.01,0.11-0.11,0.04,0.02c0.03-0.3,0.58,0.7,0.47,0.58c0.21,0.04,0.2,0.25,0.38,0.32
				c-0.03,0.01-0.06,0.03-0.06,0.01c-0.05,0.08,0.06,0.1,0.12,0.06c-0.04,0-0.1,0.15,0.09,0.16c-0.01,0.07,0.1,0.07,0.08,0.14
				c0.11-0.03,0.01,0.11,0.15,0.04c-0.14,0.08-0.05,0.15,0.03,0.14c-0.05,0.02-0.02,0.05-0.09,0.08c0.03,0.03,0.07,0.05,0.13,0
				c-0.12,0.17,0.21,0.06,0.02,0.22c0.13,0.06,0.24,0.14,0.33,0.22c-0.12,0.12-0.09,0.02,0.17,0.4c-0.01,0.01-0.03,0-0.05,0
				c0.01,0.01,0,0.05,0.06,0.02l-0.03-0.01c0.08-0.07,0.23-0.03,0.28-0.05c-0.2,0.06-0.01,0.11-0.26,0.18c0.02,0.01,0.05,0,0.1-0.02
				c-0.04,0.17-0.01,0.03,0.14,0.46c0-0.03,0.02,0.09,0.11-0.01c-0.05,0,0.18,0.1,0.03,0.08l0.05,0.07
				c0.04-0.06,0.03-0.07,0.17-0.06c-0.05,0.08,0.01,0.1-0.13,0.22c0.11-0.02,0.07,0.09,0.19,0.07c-0.03,0-0.09,0.03-0.1,0.03
				l0.09-0.02c-0.12,0.06-0.03,0.1-0.01,0.07c-0.06,0.01,0.21,0.26-0.06,0.21c0.06,0.09,0.23,0.14,0.23,0.25
				c0.19-0.11,0.01-0.01,0.04,0.1c-0.02-0.01-0.1,0.02-0.08,0.01c-0.01,0.07,0.08,0.12,0.17,0.2c-0.02-0.04-0.13,0.2,0.06,0.15
				c-0.1,0.11-0.22,0.29,0.24,0.43c-0.06-0.03-0.13,0.03-0.17,0.06c0.02-0.01,0.05-0.01,0.08-0.02c-0.2,0.08,0.15,0.01,0.04,0.07
				c0.03,0.02,0.13,0.11-0.04,0.06c0.05,0.11,0.01-0.03-0.07,0.22c0.3-0.06,0.02-0.01,0.26,0.1c-0.16-0.02-0.02-0.03-0.28,0.08
				c0.09,0.01-0.05,0.06,0.08,0.08c-0.03,0.01,0,0.03-0.08,0.04c0.05-0.31,0.35,0.41,0.15,0.51c0.04,0.03,0.1,0.06,0.14,0.09
				c-0.06,0.02-0.09-0.01-0.13-0.02c0.02-0.01,0.03,0.08,0.17,0.07c-0.03-0.05-0.06-0.19,0.11-0.18c-0.01,0.02-0.08-0.14,0.09-0.15
				c0.03-0.13,0.03-0.19-0.25-0.17l0.25-0.09l-0.11,0.01c0-0.01,0.01-0.03,0.05-0.03c-0.08-0.02-0.04,0.01-0.12,0.01
				c0.09-0.06-0.07-0.12,0.09-0.16c-0.08,0-0.05,0.06-0.12,0.02c0.21-0.02-0.12-0.11,0.16-0.12c-0.19-0.07,0.12-0.16-0.15-0.24
				c0.06,0.01-0.03-0.03,0.1-0.2c-0.13,0.02-0.12-0.04-0.15-0.08c0.07-0.02,0.11-0.02,0.14,0c-0.02-0.04-0.01-0.04-0.1-0.07
				l0.1-0.01c-0.02-0.05-0.13-0.08-0.09-0.16c-0.09,0.02-0.22,0.12-0.3,0.06c0.05-0.02,0.13-0.04,0.15-0.03
				c0.05-0.11-0.07,0.06,0.17-0.16c-0.03,0.01-0.21,0.02,0-0.07c-0.05,0-0.01-0.05-0.11-0.02c0-0.02,0.04-0.03,0.07-0.05
				c-0.06-0.01,0.01-0.02-0.16-0.05l0,0c-0.18,0.12,0.07-0.24-0.13-0.65c0.06,0.05,0.08-0.18,0.01-0.03c0.08-0.34-0.44-0.4-0.14-0.6
				l-0.04,0.01c0.03-0.04,0-0.1,0.07-0.12c-0.05-0.02-0.07,0.01-0.08,0.05c0.03-0.09-0.04-0.09-0.03-0.17
				c-0.08,0.06-0.34-0.11,0-0.16c-0.1-0.02,0-0.13,0-0.17l0.04,0.02c-0.03-0.01-0.03-0.02-0.04-0.04l-0.07,0.05
				c0-0.02,0-0.04,0.03-0.05c-0.06,0.01-0.12,0.07-0.2,0.07c0.03-0.04-0.07-0.05,0.02-0.11c0,0.09,0.1-0.06,0.14,0
				c0-0.12,0.09-0.09-0.15-0.1c0.06-0.02,0.1-0.07,0.08-0.08c0.01,0.03-0.09,0.06-0.14,0.06c0.02-0.07-0.06-0.03-0.06-0.06
				c-0.03,0.05-0.01,0.06-0.1,0.01c0.04-0.02,0.04,0.01,0.06-0.01c-0.11,0.02,0.06-0.13-0.03-0.15c0.02-0.04,0.1-0.06,0.12-0.09
				c-0.14,0.03,0.09-0.17-0.19-0.07l0.1,0.06c-0.19,0-0.17,0.13-0.21-0.3c-0.03,0.04-0.04,0.09-0.1-0.01
				c0.06-0.03,0.07-0.06,0.1-0.1c-0.04,0.03-0.03-0.07-0.16,0c0.26-0.15-0.36-0.09,0-0.2c-0.09-0.04,0.02,0-0.32-0.04
				c0.14-0.18-0.13-0.17-0.02-0.33c-0.3-0.18-0.13,0.02-0.56-0.53c0.08-0.04,0.06-0.01,0.12-0.07c-0.33,0.2-0.14-0.11-0.24-0.35
				c-0.05,0-0.17,0-0.23-0.03c0.1-0.04,0.18-0.02,0.25-0.02c-0.02-0.03-0.07-0.08-0.14-0.05c0.04,0.01-0.18,0.18-0.1-0.34
				c-0.15-0.02-0.12-0.2-0.22-0.28c0,0.07-0.29,0.13-0.09,0c-0.03,0.02-0.07,0.02-0.09,0.04c0-0.06,0.04-0.16,0.15-0.31
				c-0.05,0.06-0.1,0.06-0.1,0.01c0.03,0.09-0.18-0.05-0.04-0.09c-0.06-0.01-0.13-0.13-0.28-0.04c0.37-0.19-0.34-0.15-1.24-1.15
				l0.01,0.01c0.07-0.12,0-0.13-0.04-0.16l-0.12,0.14c0.07-0.28-0.31-0.11-0.22-0.34c-0.05,0.02-0.08,0-0.13,0.02
				c0.03-0.08,0.11-0.09-0.11-0.22l0.06-0.05c-0.11,0.1-0.16-0.21-0.58-0.16c0.07-0.11-0.08-0.07-0.15-0.04
				c0.07-0.12,0.14-0.06-0.29-0.18c-0.05,0.05-0.13,0-0.21-0.12c0.06,0.03,0.1,0.06,0.09,0.07c0.09-0.02-0.14-0.39-0.31-0.16
				c0-0.01,0.01,0,0.04-0.01l-0.01,0.01c0.02,0.01,0.04,0.02,0.06,0.03c-0.04,0.03-0.08,0.06-0.08,0c-0.04,0.05-0.09,0.1-0.11,0.12
				c-0.05-0.06,0.04-0.34-0.42-0.28c-0.01-0.39,0.07-0.13-0.4-0.26c0,0.02,0.01,0.03,0,0.05c-0.08-0.05-0.13-0.29-0.66-0.24
				l0.03-0.1c0.23,0.07-0.89,0.15-0.3-0.65l-0.12,0.09c0.05,0.03,0.12,0.19-0.18,0.25l0.08-0.12c-0.25-0.02-0.35,0.45-0.55,0.66
				c0.02-0.18-0.16-0.03-0.33,0.1c-0.02-0.06-0.01-0.07-0.13-0.13c0.04,0.11,0.11,0.11-0.14,0.43c0-0.05-0.1-0.04,0-0.12
				c-0.3,0.22-0.3,0.51-0.47,0.54l0.01-0.03c-0.78,0.63-0.43,0.87-1.94,2.2c-0.09-0.11,0.12-0.04-0.39,0.5
				c-0.02-0.04-0.46,0.4-0.69,0.6l-0.01-0.02c0.06,0.13-0.17,0.34-0.3,0.53c-0.05-0.06-0.05-0.11,0.01-0.22
				c0,0.04-0.06-0.02-0.25,0.4c-0.11-0.38-0.05,0.16-0.63,0.89l0.04-0.17c-0.02,0.07-0.34,0.37-0.12,0.07
				c-0.51,0.45-0.22,0.2-1.09,1.23l0,0c-0.25,0.42,0,0-1.01,1.06c-0.1-0.11-0.32,0.33-1.09,1.09c0.11-0.2-0.09,0.02,0.12-0.19
				c-0.21,0.18-0.3,0.19-0.44,0.47c0.01-0.13-0.23,0.07-0.3,0.19l0.18-0.1c-0.1,0.12-0.19,0.2-0.29,0.29l-0.03-0.13
				c-0.47,0.39-0.9,0.91-1.79,1.93c0.38,0.02-0.68,0.27-0.42,0.35c-0.28,0.19-0.39,0.36-0.67,0.57c0.08,0.01-0.51,0.93-0.6,0.47
				c-0.18,0.28-0.47,0.37-0.61,0.48l0.1-0.14c-0.06,0.08-0.12,0.14-0.18,0.19l0.13,0c-0.08,0.05-0.14,0.11-0.17,0.08
				c-0.05,0.23,0.2,0.2-0.32,0.49c0.32-0.24-0.07-0.07,0.12-0.28c-0.35,0.23-0.24,0.05-0.51,0.58c0-0.08-0.15-0.02-0.19,0.03
				c0.1-0.09,0.1,0.13-0.25,0.47l0.13-0.09c-0.08,0.32-0.58,0.38-1.49,1.04c0.13,0,0.13-0.03,0,0.19l0.38-0.4l-0.19,0.32
				c0.13-0.11,0.23-0.27,0.31-0.32c-0.08,0.16-0.08,0.13,0.02,0.13c-0.63,0.5-0.54-0.04-1.57,1.1l0.15-0.08
				c-0.66,0.6-0.33,0.13-1.61,1.44c0.05-0.13-0.12-0.06,0.05-0.27c-0.38,0.47,0.17-0.1-2.7,2.16c0-0.03-0.02-0.03,0.03-0.05
				c-0.68,0.56-0.03-0.02-1.13,0.67l0.05-0.05c-0.29,0.13-0.09,0.06-1.45,1.21c-0.01-0.03-0.02-0.05-0.02-0.07
				c-0.01,0.09-0.08,0.23-0.49,0.21l0.24-0.15c-0.19-0.03-0.31,0.19-0.44,0.25l-0.01-0.06c-0.36,0.24-0.29,0.36-0.69,0.59l0.06,0.01
				c-0.49,0.48-0.35-0.16-1.21,0.76c0.1-0.17,0.37-0.39,0.53-0.55c-0.14,0.08-0.48,0.29-0.52,0.39c0.05-0.04,0.11-0.12,0.17-0.13
				c-1.1,0.84-0.01,0.1-3.33,1.67c0.31,0.07-0.17,0.1-0.04,0.27c-0.31,0.22-0.54,0.13-1.01,0.1c0.08,0.05-0.06,0.15-0.25,0.22
				l0.29-0.04c-0.38,0.32-0.45,0.03-0.84,0.35l0.11-0.13c-0.38,0.13-0.38,0.26-2.59,1.44l0.02-0.07c-0.25,0.19-0.17,0.02-0.69,0.39
				l0.09-0.11c-0.56,0.19-0.75,0.51-1.17,0.76c-0.06-0.14-0.5,0.01-0.72,0.09l0-0.02c-1.22,0.43-0.12,0.08-6.58,3.23l-0.03-0.09
				c-0.39,0.15-0.58,0.23-1.03,0.46l0.06-0.11c-0.74,0.62-0.31,0.29-1.95,1.07c0-0.02-0.01-0.07,0.07-0.11
				c-0.22,0.1-0.87,0.3-0.71,0.44c-0.06-0.12-0.48,0.21-0.7,0.37l0-0.11c0,0.04-0.26,0.23-0.86,0.6l0.08-0.1
				c-0.38,0.17-0.12,0.32-0.84,0.37c0.11-0.06-0.27,0.29-0.23,0.15c-0.46,0.07,0.07,0.09-0.92,0.38c0.31-0.14,0.57-0.26,0.55-0.24
				c-0.04-0.08-0.3,0.02-0.15-0.18c-0.2,0.13,0.04,0.06-0.77,0.51l0.02-0.02c-0.34,0.18-0.22,0.04-0.8,0.31
				c0.05,0.08-0.12,0.15,0.07,0.15c-0.04,0.01,0.06-0.03,0.15-0.1l-0.01,0.01c0.08-0.04,0.19-0.09,0.31-0.15
				c-0.76,0.62-0.65,0.12-2.38,1.15c0.25-0.23,0.26-0.18-0.75,0.36l0.03-0.06c-0.83,0.16-1.08,0.86-2.02,1.01
				c0.06-0.01,0.1-0.02,0.12,0c-0.49-0.02-2.24,1.2-4.86,2.21l0.09,0.03c-0.69,0.08-0.73,0.5-1.35,1.24c0.4,0,0.97-0.76,1.08-0.47
				c0.06-0.11,0.36,0.04,1.27-0.7c-0.16,0.28,0.5-0.22,0.52-0.01c0.11-0.23,0.62-0.37,0.89-0.6c-0.01,0.02-0.04,0.06-0.07,0.07
				c0.18-0.07,0.26-0.05,0.38-0.07c-0.21,0.1,0.62-0.83,0.4-0.15c-0.06-0.1,0.33-0.28,0.22-0.33c0.2,0,0.16-0.03,0.29,0.03
				c-0.2-0.1,0.86-0.56,0.55-0.16c0.5-0.62,0.02,0.05,1.77-1.08c-0.08-0.12-0.18-0.16,0.16-0.27c0.5,0.45-0.01,0.24,2.31-0.92
				l-0.04,0.17c1.01-0.74,2.14-0.88,5.65-2.69l-0.06,0.06c0.32-0.1,0.25-0.35,0.58-0.39l-0.05,0.04c0.18-0.04,0.18,0.08,5.44-2.43
				c0,0.05-0.09,0.11-0.16,0.17c0.28-0.25,0.11,0.08,0.36-0.15c-0.05,0-0.01-0.07-0.01-0.09c0.18-0.01,0.51-0.16,0.75-0.3
				l-0.02,0.09c0.44-0.18,0.89-0.45,1.38-0.67c1.09-0.41-0.14,0.1,6.81-3.3l-0.02,0.02c1.35-0.77,0-0.03,3.57-1.75l-0.06-0.04
				c1.09-0.94-0.06-0.03,3.9-2.34l-0.07,0.18c0.67-0.57,1.51-0.95,2.15-1.53c-0.03,0.05-0.01,0.08-0.08,0.13
				c0.2-0.03,0.56-0.37,0.52-0.43c0.03,0.05,0.73-0.4,1.98-1.5c0.08,0.14,0.53-0.24,0.64-0.42c-0.02,0.08,0.17-0.07,0.15,0.03
				c0.18-0.14,0.33-0.3,0.25-0.34c0.7-0.34,0.52-0.24,2.31-1.96c-0.01,0.06-0.03,0.12-0.05,0.17c0.67-0.57,0.57-0.46,1.87-1.68
				c0.01,0.01-0.02,0.06-0.05,0.11c0.16-0.25-0.01,0.04,0.21-0.59c0.01,0.27,0.42-0.3,0.38,0.07c0.04-0.06,0.08-0.14,0.04-0.16
				c0.08-0.06,0.13-0.06,0.09,0.05c0.25-0.27,0.1-0.24,0.35-0.47c0.02,0.03-0.02,0.08-0.02,0.13c0.08-0.16,0.16-0.33,0.39-0.49
				c0,0.03,0.04,0.02-0.02,0.08c0.35-0.34,0.48-0.69,0.91-1.01c-0.01,0.12,0.06,0.2,0.46-0.24l-0.13,0.03
				c0.18-0.17,0.28-0.39,0.46-0.51l-0.09,0.17l0.32-0.34l-0.22,0.15c0.13-0.22-0.13-0.1,0.13-0.31c-0.04,0.06,0.18-0.17,0.33-0.21
				l0,0c0.32-0.48,0.25,0.09,0.81-0.83c0.03,0.21,0.37-0.15,0.35-0.32c-0.02,0.03,0,0.03-0.03,0.08c0.3-0.33,0.45-0.44,0.57-0.52
				l-0.07,0.09c0.42-0.36-0.02,0.05,1.34-1.46l-0.08,0.06c0.3-0.54,0.43-0.09,1.28-1.38l-0.01,0.08c0.64-0.77,0.61-0.52,1.98-2.12
				c-0.07,0.15-0.13,0.23,0.28-0.31c-0.09-0.02-0.08,0.02-0.21,0.06c0.01-0.08,0.08-0.22,0.17-0.32c-0.12,0.21,0.1,0.09,0.2,0.04
				c-0.03,0-0.03-0.05-0.05-0.06c0.29-0.11-0.09-0.14,0.24-0.36c0.04,0.07,0.08,0.15,0.59-0.64c-0.04,0.09-0.13,0.19-0.17,0.27
				c0.22-0.09,0.22-0.12,0.34-0.23l-0.01,0.01l0.28-0.24l-0.08,0.05c0-0.36,0.34-0.44,0.46-0.82
				C453.64,298.58,454.22,298.41,456.02,296.18z M458.74,296.3c0.01-0.02,0.02-0.03,0.03-0.04
				C458.79,296.26,458.81,296.3,458.74,296.3z M458.65,296.89c-0.02,0.02,0,0.05,0,0.08C458.63,296.95,458.58,296.94,458.65,296.89z
				 M458.55,296.92c-0.01,0,0,0-0.03-0.01C458.53,296.91,458.54,296.92,458.55,296.92z M456.51,295.47
				c-0.01-0.01-0.01-0.01-0.02-0.01C456.5,295.45,456.51,295.46,456.51,295.47z M405.32,330.65c0.03-0.07,0.01-0.09,0.15-0.09
				C405.44,330.59,405.45,330.54,405.32,330.65z M406.09,331c0.03-0.01,0.06-0.03,0.13-0.07C406.16,330.97,406.21,330.94,406.09,331
				z M406.54,330.71c-0.02,0.03,0.11-0.01,0.21-0.02C406.78,330.64,406.46,330.89,406.54,330.71z"
                />
                <path
                  className="st10"
                  d="M473.67,309.4c0.14,0.08,0.38-0.29,0.45-0.5l0.02,0.01c-0.44-0.99,1.17-0.3-1.62-5.93l0.09-0.04
				c-0.16-0.31-0.22-0.47-0.48-0.81l0.11,0.03c-0.65-0.47-0.27-0.2-1.23-1.39c0.02-0.01,0.06-0.03,0.11,0.02
				c-0.12-0.15-0.42-0.65-0.52-0.46c0.1-0.1-0.29-0.32-0.49-0.44l0.09-0.04c-0.03,0.02-0.25-0.13-0.72-0.47l0.11,0.02
				c-0.25-0.23-0.31,0.05-0.56-0.51c0.08,0.06-0.33-0.09-0.19-0.12c-0.19-0.34-0.06,0.09-0.61-0.54c0.23,0.17,0.41,0.32,0.38,0.31
				c0.06-0.07-0.12-0.22,0.11-0.21c-0.18-0.09-0.03,0.06-0.69-0.35l0.02,0.01c-0.27-0.17-0.1-0.16-0.54-0.46
				c-0.05,0.09-0.16-0.01-0.09,0.14c-0.02-0.02,0.04,0.03,0.14,0.06l-0.01,0c0.07,0.04,0.14,0.1,0.23,0.16
				c-0.79-0.23-0.29-0.47-1.86-1.12c0.28,0.04,0.25,0.08-0.6-0.32l0.06-0.01c-0.54-0.46-1.12-0.19-1.72-0.68
				c0.04,0.03,0.06,0.05,0.07,0.07c-0.27-0.32-2.01-0.61-4.23-1.08l0.05,0.08c-0.56-0.26-0.7,0.02-1.44,0.45
				c0.15,0.04,0.11,0.03,0.64-0.08c-0.06,0.3,0.36-0.18,0.29,0.14c0.09-0.07,0.23,0.21,1.15,0.04c-0.24,0.15,0.42,0.08,0.33,0.27
				c0.18-0.13,0.56,0.04,0.85,0.01c-0.02,0.01-0.05,0.03-0.08,0.02c0.15,0.05,0.18,0.1,0.27,0.16c-0.17-0.06,0.84-0.27,0.31,0.13
				c0.02-0.11,0.35-0.02,0.31-0.12c0.12,0.12,0.11,0.07,0.15,0.2c-0.05-0.21,0.81,0.1,0.4,0.21c0.17-0.21,0.78,0.31,1.86,0.3
				c0.01,1.02,0.01-0.02,1.69,1.04l-0.14,0.07c0.89,0.37,1.57,0.92,3.86,3.06l-0.07-0.03c0.15,0.22,0.35,0.09,0.44,0.34l-0.04-0.02
				c-0.16,0.02,0.25,0.21,1.97,3.17c0.13-0.14-0.11,0.18,0.43,0.86c-0.04,0.01-0.09-0.07-0.14-0.12c0.2,0.22-0.09,0.09,0.1,0.29
				c0.01-0.04,0.07-0.01,0.09-0.01c-0.02,0.14,0.09,0.41,0.19,0.61l-0.08-0.01c0.12,0.35,0.27,0.73,0.35,1.12
				c0.13,0.71,0.03-0.02-0.36,2.67c-0.07-0.05-0.27,0.14-0.21,0c-0.31,0.09-0.28,0.86-2.02,0.27l0.02,0
				c-0.86-1.04-0.37-0.15-1.54-3.74c-0.32,1.41-0.01-0.32-1,6.72l-0.05-0.15c-0.16,0.66-0.67,1.11-1.07,1.59
				c0.02-0.32,0.24,0.12-1.97,1.01c-0.01-0.15-0.41-0.07-0.56,0c0.05-0.05-0.13-0.05-0.07-0.11c-0.16,0-0.31,0.01-0.29,0.1
				c-0.71-0.4-0.61-0.01-1.91-0.94c0.71,0.01,0.31,0.79-0.75-1.59c0.01,0,0.04,0.03,0.06,0.07c-0.13-0.19,0.02,0.03-0.39-0.34
				c0.22,0.09-0.02-0.4,0.27-0.24c-0.02-0.05-0.07-0.11-0.11-0.09c0-0.08,0.03-0.11,0.09-0.04c-0.07-0.27-0.14-0.16-0.16-0.42
				c0.03,0,0.05,0.05,0.1,0.07c-0.08-0.12-0.16-0.25-0.14-0.47c0.02,0.01,0.05-0.01,0.05,0.05c-0.03-0.38-0.19-0.65-0.12-1.07
				c0.08,0.07,0.19,0.08,0.15-0.39l-0.07,0.09c0.01-0.2-0.08-0.38-0.03-0.55l0.05,0.15l-0.01-0.38l-0.06,0.21
				c-0.22-0.6-0.12,0.01-0.09-1.5c0.13,0.14,0.2-0.25,0-0.39c0.01,0.03,0.02,0.01,0.04,0.07c-0.06-0.36-0.05-0.51-0.03-0.63
				l0.03,0.09c-0.01-0.45,0.03,0.04-0.36-1.58l0.01,0.08c-0.2-0.01,0.01-0.33-0.13-0.52c0.17-0.12,0.08,0.03-0.32-0.94l0.06,0.04
				c-0.34-0.73-0.13-0.66-0.9-2.19c0.1,0.1,0.14,0.16-0.15-0.31c-0.06,0.07-0.02,0.07-0.03,0.18c-0.07-0.03-0.15-0.12-0.21-0.22
				c0.14,0.15,0.12-0.06,0.12-0.16c-0.02,0.02-0.05,0.01-0.07,0.03c-0.16-0.45-0.11,0.02-0.55-0.91c0.06,0.05,0.11,0.15,0.17,0.2
				c0.01-0.2-0.02-0.21-0.07-0.33l0,0.01l-0.1-0.29l0.01,0.08c-0.32-0.07-0.27-0.37-0.56-0.54c0.11,0.1,0.38-0.01-0.08-0.56
				c0.08,0.04-0.74-0.88-1.53-2.11c0.13,0,0.3,0.01-0.41-0.59l0.04,0.12c0.03-0.04,0.21-0.08,0.17,0.19l-0.08-0.08
				c-0.1,0.21,0.29,0.36,0.41,0.56c-0.09,0.04-0.22,0.03,0.17,0.56c-0.04-0.01-0.07,0.08-0.11-0.01c0.09,0.29,0.35,0.33,0.32,0.48
				l-0.02-0.02c0.21,0.36,0.51,0.97,1.54,2.43c-0.05,0.01,0.18,0.46,0.25,0.69l-0.02,0c0.14-0.02,0.23,0.21,0.34,0.36
				c-0.07,0.03-0.11,0.01-0.19-0.06c0.03,0.01-0.03,0.05,0.24,0.3l-0.1,0c0.05,0.06,0.09,0.38,0.59,0.73l-0.12-0.08
				c0.05,0.04,0.17,0.37,0,0.11c0.15,0.52,0.07,0.22,0.53,1.21l0,0c0.24,0.33,0,0,0.36,1.12c-0.16,0.02,0.12,0.35,0.26,1.21
				c-0.09-0.16-0.04,0.07-0.08-0.17c0.01,0.23-0.03,0.28,0.11,0.5c-0.1-0.07-0.08,0.18-0.04,0.28l0.04-0.16
				c0.03,0.12,0.03,0.22,0.04,0.33l-0.12-0.04c0,0.5,0.12,0.99,0.22,2.07c0.28-0.21-0.29,0.53-0.06,0.44
				c-0.07,0.27-0.02,0.43-0.06,0.71c0.06-0.06,0.32,0.84-0.08,0.63c0.08,0.26-0.04,0.5-0.05,0.65l-0.04-0.14
				c0.02,0.08,0.03,0.15,0.03,0.23l0.08-0.09c-0.01,0.08,0,0.15-0.04,0.16c0.13,0.15,0.28-0.04,0.2,0.47
				c0.08-0.17-0.46-0.05,0.03,0.42c-0.07-0.03-0.1,0.1-0.07,0.16c-0.02-0.11,0.15-0.03,0.29,0.37l-0.02-0.13
				c0.27,0.17,0.02,0.63,0.48,1.51c0.03-0.12,0-0.11,0.18,0.01c-0.11-0.12-0.21-0.25-0.3-0.38c0.08,0.07,0.17,0.14,0.26,0.2
				c-0.08-0.12-0.2-0.23-0.23-0.31c0.13,0.09,0.1,0.09,0.13,0c0.38,0.6-0.24,0.57,0.93,1.36l-0.09-0.12
				c0.69,0.39,0.16,0.34,1.74,0.62c-0.11,0.06,0.04,0.13-0.2,0.15c0.47-0.08-0.19,0.03,2.86-0.69c0,0.01,0,0.02,0,0.02
				c0.53-0.43,0.05,0.08,0.94-0.57l-0.03,0.05c0.17-0.17,0.24-0.01,0.84-1.36c0.02,0.02,0.03,0.03,0.05,0.05
				c-0.03-0.07-0.02-0.21,0.29-0.34c-0.04,0.07-0.07,0.14-0.1,0.21c0.17-0.05,0.14-0.28,0.21-0.38l0.04,0.04
				c0.13-0.34,0.01-0.4,0.14-0.76l-0.05,0.02c0.05-0.54,0.25-0.14,0.25-1.14c0.08,0.16,0.06,0.45,0.07,0.64
				c0.04-0.13,0.1-0.46,0.05-0.55c0,0.06,0.02,0.13-0.01,0.18c-0.07-0.19-0.08-0.41-0.03-0.68c0.12,0.05,0.49-0.66,0.27-1.45
				c0.06,0.21,0.03,0.07,0.11,0.54c-0.02-0.11-0.01-0.12-0.06-0.38c0.06,0.22,0.14,0.53,0.19,1.02c0.09-0.36-0.02,0.53,2.6,0.27
				l-0.02,0.06c0.23-0.19,0.19,0.01,0.61-0.41l-0.06,0.12C473.43,310.17,473.48,309.79,473.67,309.4z M467.65,297.69
				c0.06-0.02,0.08-0.04,0.13,0.07C467.75,297.75,467.78,297.74,467.65,297.69z M467.63,298.48c0.02,0.01,0.05,0.03,0.1,0.06
				C467.68,298.52,467.73,298.54,467.63,298.48z M468.02,298.67c-0.03,0,0.04,0.08,0.08,0.15
				C468.15,298.83,467.84,298.7,468.02,298.67z"
                />
                <path
                  className="st10"
                  d="M451.26,290.86c0.03,0.03-0.16,0.1-0.04,0.13c-0.02,0.02-0.03,0.04-0.05,0.08c0.02,0.01,0.14-0.1-0.02,0.06
				c0.03-0.01,0.09-0.05,0.12-0.06c-0.03,0.05-0.06,0.06-0.09,0.09c0.28-0.13-0.02-0.04,0.15-0.26c0,0.02-0.1-0.02,0.01-0.14
				c-0.04-0.09-0.06-0.08-0.23,0.11l0.14-0.23l-0.07,0.08c0-0.01-0.01-0.02,0.02-0.05c-0.06,0.05-0.02,0.04-0.08,0.09
				c0.04-0.09-0.08-0.01,0.01-0.15c-0.06,0.06-0.02,0.07-0.07,0.09c0.13-0.16-0.11,0.03,0.07-0.18c-0.13,0.12,0.02,0-0.08-0.01
				c0.04-0.1,0.12-0.05-0.09,0c0.04-0.03-0.03-0.01,0-0.17c-0.08,0.1-0.09,0.06-0.13,0.07c0.05-0.06,0.07-0.08,0.1-0.1
				c-0.04-0.01-0.02-0.01-0.08,0.04l0.06-0.08c-0.03-0.01-0.11,0.06-0.11-0.02c-0.05,0.07-0.12,0.22-0.19,0.25
				c0.04-0.07,0.05-0.02,0.17-0.32c-0.02,0.03-0.14,0.16-0.02-0.04c-0.03,0.04-0.02-0.02-0.08,0.07c-0.01-0.01,0.02-0.05,0.03-0.07
				c-0.04,0.03,0-0.01-0.12,0.08c-0.13,0.07-0.13-0.34-0.48-0.64c-0.04,0.02-0.04,0.05-0.04,0.08c0-0.07-0.05-0.03-0.05-0.09
				c-0.04,0.06-0.01,0-0.14,0.11c0.02-0.06,0.09-0.12,0.03-0.1c0-0.05,0.06-0.1,0.08-0.12c-0.04,0.08-0.06-0.07,0-0.12
				c-0.02,0.01-0.03,0.01-0.03,0l-0.04,0.08c0-0.02-0.01-0.02,0.01-0.05c-0.04,0.04-0.07,0.12-0.13,0.17c0.01-0.04-0.06,0.02,0-0.08
				c0.02,0.05,0.06-0.1,0.1-0.09c-0.02-0.1,0.05-0.1-0.12,0.03c0.04-0.05,0.05-0.1,0.04-0.1c0.01,0.01-0.05,0.09-0.09,0.12
				c0-0.06-0.04,0.01-0.05,0c-0.01,0.05,0,0.04-0.07,0.07c0.02-0.04,0.03-0.02,0.04-0.04c-0.08,0.08,0.02-0.12-0.04-0.08
				c0.01-0.04,0.06-0.1,0.07-0.13c-0.09,0.04-0.01-0.12-0.14,0.06l0.08-0.02c-0.09,0.17-0.26,0.04-0.2-0.15
				c-0.03,0.04-0.03-0.02-0.11,0.09c0.16-0.26-0.27,0.16-0.03-0.13c-0.08,0.03,0-0.01-0.22,0.15c0.07-0.21-0.11-0.05-0.05-0.23
				c-0.04,0-0.08,0.01-0.11,0c-0.02,0.11,0-0.27-0.33-0.07c0.05-0.07,0.04-0.03,0.08-0.11c-0.02,0-0.07,0.13-0.11,0.13
				c0.02-0.1,0.17-0.38-0.08-0.27c-0.03,0.02-0.12,0.09-0.16,0.09c0.07-0.08,0.12-0.11,0.17-0.15c-0.19,0.09-0.02,0.12-0.46-0.15
				c0.03-0.04,0.08-0.1,0.08-0.16c-0.02,0.08-0.07,0.09-0.09,0.16c-0.02-0.01,0-0.09,0.1-0.34c-0.03,0.07-0.06,0.09-0.07,0.05
				c0.03,0.03-0.13,0.05-0.03-0.06c-0.04,0.02-0.09-0.05-0.19,0.09c0.09-0.07-0.1-0.22-0.4-0.1c0.02-0.12,0.18-0.41-0.2-0.17
				c0.04-0.17-0.09-0.12-0.11-0.24c0,0.03-0.05,0.11-0.07,0.12c0-0.03-0.09,0.08-0.02-0.05l0,0c0.05-0.13,0.01-0.11-0.02-0.13
				l-0.08,0.16c0.06-0.27-0.2,0.02-0.13-0.22c-0.16,0.08-0.04-0.34-0.29,0.08c0-0.05,0.13-0.18-0.09-0.2
				c0.01-0.02,0.03-0.06,0.04-0.06c-0.02-0.01-0.05-0.13-0.12,0.06c0.05-0.12-0.04-0.03-0.09,0.01l0.04-0.09
				c-0.07,0.1-0.09,0.13-0.13,0.15c0-0.01,0.03-0.07,0.03-0.05c-0.1-0.12-0.07,0.18-0.11-0.09c-0.03,0.07-0.1,0.03-0.12-0.06
				c0.03,0.02,0.06,0.03,0.05,0.04c0.01,0.03-0.06-0.35-0.18-0.07c0-0.01,0,0,0.03-0.02l0,0.01l0.04,0.01
				c-0.03,0.04-0.06,0.08-0.05,0.02c-0.03,0.05-0.07,0.11-0.08,0.14c-0.02-0.02,0.05-0.34-0.23-0.16c-0.01-0.07,0.06-0.1,0.01-0.1
				c0.05-0.27,0.02-0.04-0.22-0.06c0,0.02,0,0.03-0.01,0.05c-0.02-0.03-0.11-0.27-0.37-0.11l0.03-0.1
				c-0.07,0.22-0.13-0.04-0.19,0.04c-0.01-0.01-0.04-0.05-0.05-0.08l-0.08,0.07c0.03-0.2,0.03-0.49-0.25,0.47
				c0.11-0.16-0.03,0.04,0.07-0.03c-0.01,0.04-0.02,0.07-0.02,0.09l0.09-0.13c-0.02,0.06-0.06,0.11-0.05,0.15
				c0.04-0.06,0.04-0.14,0.08-0.17c-0.01,0.07-0.04,0.13,0,0.08c-0.01,0.04-0.03,0.06-0.04,0.08c0.03-0.04,0.05,0,0.09-0.08
				c-0.02,0.09,0.06,0,0,0.14c0.02-0.03,0.07-0.1,0.09-0.1c-0.09,0.16,0.05,0.03-0.03,0.21c0.08-0.15,0.1-0.04,0.16-0.11
				c-0.01,0.01-0.02,0.03-0.02,0.03c0.01,0.02,0,0.07,0,0.1c0.05-0.13,0.09-0.14,0.14-0.22c-0.03,0.13-0.07,0.14-0.11,0.26
				c0.04-0.1,0.08-0.09,0.11-0.18c-0.01,0.08,0,0.04-0.03,0.15c0.07-0.16,0.03-0.02,0.08-0.11c-0.01,0.09-0.05,0.15-0.01,0.11
				c-0.08,0.23,0.03-0.03,0.24-0.07l-0.01,0.03c0.01,0,0.03-0.17,0.09-0.15c-0.01-0.01-0.02,0.03-0.03,0.05l0.03-0.03
				c-0.03,0.12-0.03,0.03-0.07,0.11c0.04-0.02,0.18-0.09,0.04,0.06l-0.01-0.05c0.04,0.11,0.04,0.26,0.43,0.18
				c-0.02,0.03-0.04,0.06-0.04,0.1c0-0.13,0.36,0.11,0.8,0.39l0.07-0.09l-0.04,0.12c0.06-0.03,0.08-0.23,0.06,0.05l0.1-0.05
				c-0.02,0.04-0.04,0.05-0.06,0.07c0.1-0.13-0.04,0.14,0.07-0.05c-0.01,0.07,0.02,0.08,0.06,0.07c-0.02,0.06-0.08,0.09,0.12,0.11
				c0-0.04,0.06-0.15,0.08-0.16l-0.04,0.11c0.04-0.06,0-0.05,0.06-0.11c0.01,0.02-0.04,0.12-0.07,0.15l0.04-0.02
				c-0.01,0.03-0.03,0.04-0.05,0.08c0.04-0.06-0.01,0.12,0.06,0.02c-0.18,0.21,0.09-0.06,0.67,0.3c0.13-0.1,0.08,0.07,0.17,0
				c-0.09,0.25,0.13,0.05,0.1,0.22c0-0.01,0.07-0.15,0.03,0c-0.03-0.33,0.17,0.26,0.66,0.24c-0.02,0.02-0.04,0.05-0.04,0.05
				c-0.03,0.08,0.06,0.04,0.09-0.02c-0.04,0.06-0.01,0.11,0,0.12c0.03-0.03,0.04,0.04,0.08-0.05c0,0.06,0.08-0.01,0.07,0.06
				c0.07-0.08,0.02,0.07,0.1-0.06c-0.08,0.13-0.02,0.14,0.04,0.09c-0.03,0.04-0.01,0.05-0.05,0.1c0.02,0.01,0.05,0,0.09-0.08
				c-0.06,0.18,0.15-0.08,0.05,0.14c0.1-0.04,0.19-0.05,0.26-0.05c-0.04,0.14-0.09,0.07,0.18,0.17c-0.01,0.01-0.02,0.02-0.03,0.03
				c0.01,0,0.01,0.03,0.05-0.03l-0.02,0.01c0.04-0.1,0.15-0.16,0.18-0.21c-0.13,0.16,0.02,0.07-0.15,0.28
				c0.01-0.01,0.04-0.03,0.06-0.07c0,0.11-0.03,0.02,0.18,0.21c0-0.02,0.03,0.05,0.07-0.08c-0.03,0.04,0.15-0.05,0.04,0.03
				l0.05,0.01c-0.01-0.03,0.13-0.25,0.09-0.06c0.06-0.02,0.1-0.06-0.03,0.12c0.07-0.08,0.07,0.01,0.14-0.08
				c-0.02,0.02-0.06,0.08-0.06,0.09l0.06-0.07c-0.15,0.15,0.22,0,0.02,0.21c0.06,0.01,0.19-0.07,0.21-0.01
				c0.11-0.19,0-0.01,0.05,0.02c-0.01,0.01-0.07,0.08-0.05,0.06c0.01,0.05,0.09,0.01,0.17-0.01c-0.02,0.04-0.06,0.04,0.01,0.12
				c0.03-0.04,0.04-0.02,0.07-0.08c-0.05,0.18,0.05,0.31,0.29,0.06c-0.05,0.03-0.08,0.11-0.09,0.15l0.05-0.06
				c-0.11,0.18,0.11-0.11,0.05,0.01c0.03-0.01,0.11-0.03-0.01,0.06c0.07,0.01-0.01-0.01,0.02,0.16c0.18-0.25,0.01-0.01,0.21-0.14
				c-0.11,0.11-0.01-0.02-0.16,0.24c0.06-0.06-0.02,0.07,0.08-0.01c-0.02,0.03,0.01,0.01-0.04,0.08
				C451.04,290.74,450.86,291.16,451.26,290.86z M445.7,287.95c-0.01-0.01-0.01-0.01-0.02-0.01
				C445.69,287.91,445.72,287.82,445.7,287.95z M445.83,287.31c0.01-0.02,0.02-0.03,0.02-0.04
				C445.87,287.26,445.86,287.29,445.83,287.31z"
                />
                <path
                  className="st10"
                  d="M361.25,256.71c-0.09-0.13-0.22-0.33-0.32-0.34l0.01,0.1c0.06-0.18,0.26,0.27,0.06,0.1
				c-0.14,0.18,0.2,0.34,0.27,0.52c-0.2-0.08-0.29,0.05,0.04,0.5c-0.04-0.01-0.08,0.06-0.1-0.02c0.03,0.26,0.27,0.31,0.21,0.44
				l-0.02-0.01c0.13,0.32,0.29,0.89,1.05,2.17c-0.05,0.01,0.11,0.4,0.15,0.61l-0.02,0c0.13-0.02,0.2,0.18,0.28,0.31
				c-0.07,0.03-0.11,0.01-0.18-0.05c0.03,0.01-0.04,0.05,0.2,0.26l-0.1,0.01c0.08,0.17,0.26,0.3,0.3,0.56
				c0.09-0.02,0.15-0.28,0.31-0.07c-0.04,0.03-0.11,0.07-0.16,0.02c0.01,0.02,0.07,0.06,0.07,0.11l-0.12-0.06
				c0.05,0.03,0.14,0.31,0,0.1c0.03,0.1,0.04,0.22,0.01,0.28c0.33,0.16-0.01,0,0.47,0.72l0,0c0.22,0.26,0,0,0.38,0.92
				c-0.14,0.02,0.11,0.29,0.36,0.98c-0.11-0.11-0.03,0.07-0.1-0.12c0.04,0.19,0.01,0.25,0.17,0.4c-0.11-0.03-0.05,0.17,0.01,0.25
				l0.01-0.15c0.05,0.09,0.08,0.17,0.1,0.26l-0.13,0c0.11,0.42,0.3,0.82,0.73,1.63c0.2-0.24-0.14,0.5,0.08,0.36
				c0.02,0.24,0.12,0.34,0.16,0.58c0.03-0.08,0.54,0.56,0.1,0.51c0.15,0.18,0.08,0.4,0.11,0.52l-0.07-0.1
				c0.04,0.06,0.06,0.11,0.08,0.16l0.06-0.09c0.01,0.07,0.02,0.12-0.01,0.13c0.13,0.09,0.27-0.12,0.26,0.32
				c-0.05-0.27-0.09,0.03-0.17-0.14c0.02,0.29-0.07,0.17,0.24,0.47c-0.07-0.01-0.09,0.1-0.07,0.14c-0.04-0.08,0.14-0.07,0.27,0.26
				l-0.01-0.11c0.24,0.14,0.06,0.46,0.17,1.23c0.06-0.09,0.04-0.09,0.16,0.04l-0.16-0.34l0.18,0.2c-0.03-0.11-0.11-0.21-0.12-0.28
				c0.1,0.09,0.07,0.08,0.12,0.01c0.15,0.56-0.33,0.31,0.19,1.29l0.01-0.12c0.09,0.02,0.07,0.2,0.13,0.3
				c-0.07,0.01-0.08,0.08-0.11,0.14c0.05-0.02,0.05,0.25,0.2,0.24c-0.05,0.33-0.12,0.18,0.25,0.7c-0.09-0.06-0.11,0.07-0.21-0.09
				c0.19,0.31-0.01-0.16,0.6,2.26c-0.02-0.01-0.03,0.01-0.03-0.03c0.17,0.55-0.02,0.01,0.06,0.89l-0.02-0.04
				c-0.06,0.15,0.14,0.33,0.15,0.57c0,0-0.02-0.01-0.03,0c0.14,0.14,0.23,0.44,0.27,0.66c-0.04,0-0.06,0-0.08-0.01
				c0.07,0.03,0.17,0.11-0.04,0.36l-0.02-0.19c-0.11,0.11,0.03,0.24,0.02,0.35l-0.06-0.01c0.12,0.48,0.24,0.35,0.35,1.51
				c-0.11-0.11-0.17-0.34-0.24-0.48c0,0.11,0.03,0.38,0.1,0.43c-0.02-0.04-0.05-0.1-0.03-0.14c0.22,0.91,0.08,0.03-0.09,2.51
				c0.19-0.04,0.24,0.09,0.19,0.26c-0.05-0.02-0.14-0.18-0.19-0.01c0.07-0.12,0.1,0.12,0.17,0.09c-0.06,0.15-0.18,0.32-0.34,0.41
				c0.08-0.03,0.1,0.09,0.07,0.22l0.11-0.18c0.09,0.33-0.2,0.27-0.11,0.6l-0.06-0.11c-0.08,0.19,0.07,0.27-0.03,1.99l-0.05-0.04
				c0.04,0.24-0.07,0.09-0.01,0.53l-0.05-0.09c-0.12,0.38,0.06,0.62,0.05,0.95c-0.15-0.02-0.24,0.28-0.29,0.43l-0.02-0.01
				c-0.21,0.6,0.34,0.07-0.39,2.49c0.03-0.01,0.18,0.09,0.05,0.32c0.01-0.06-0.37,0.29-0.01,0.47c-0.22,0.13-0.06,0.63-0.6,2.35
				l-0.06-0.08c0.14,0.64,0.09,0.25-0.11,1.52c-0.02-0.01-0.06-0.03-0.06-0.08c-0.03,0.16-0.2,0.6,0.01,0.57
				c-0.13-0.02-0.07,0.35-0.04,0.54l-0.09-0.04c0.03,0.01,0.06,0.23,0.08,0.72l-0.05-0.09c-0.04,0.28,0.21,0.2-0.1,0.62
				c0-0.09,0.12,0.29,0.01,0.19c-0.16,0.28,0.11,0.01-0.12,0.67c0.03-0.23,0.06-0.42,0.06-0.4c-0.09-0.01-0.13,0.18-0.23,0.01
				c-0.06,0.13,0.19,0.34-0.05,1.2c0.1,0,0.07,0.12,0.16,0.01c-0.01,0.03,0-0.04-0.02-0.12l0.01,0.01c0-0.06,0.01-0.14,0.01-0.24
				c0.1,0.05-0.13,0.16,0.16,0.41c-0.32,0.28-0.27,0.27-0.25,1.39c-0.09-0.23-0.05-0.22-0.01,0.57l-0.04-0.04
				c-0.23,0.57,0.32,0.92,0.07,1.54c0.02-0.04,0.02-0.07,0.04-0.08c0.01,0.35-0.01,1.19,0.38,3.64l0.06-0.06
				c-0.06,0.54,0.22,0.54,0.83,1.01c0-0.14,0-0.1-0.23-0.49c0.35-0.09-0.23-0.22,0.06-0.27c-0.08-0.09-0.09-0.26-0.19-0.3
				c0.07-0.12-0.09-0.35,0.07-0.37c-0.04-0.03-0.15-0.16-0.17-0.26c0.21,0.14-0.05-0.36,0.15-0.35c-0.18-0.1-0.17-0.45-0.27-0.67
				c0.01,0.01,0.04,0.03,0.04,0.05c0-0.13,0.05-0.18,0.07-0.26c0,0.15-0.54-0.57,0.01-0.28c-0.11,0.02-0.14-0.27-0.22-0.2
				c0.07-0.13,0.03-0.11,0.14-0.18c-0.18,0.11-0.2-0.67,0.05-0.38c-0.26-0.04-0.01-0.77-0.45-1.54c0.81-0.24,0.07,0.01,0.2-1.66
				l0.13,0.08c-0.17-0.83,0.18-1.51,0.43-4.18l0.02,0.06c0.07-0.22-0.17-0.28-0.04-0.48l0.01,0.04c0.09-0.05,0.08-0.23,0.68-3.14
				c-0.2-0.09,0.17,0.26,0.11-0.82c0.04,0.02,0.04,0.09,0.06,0.16c-0.06-0.26,0.12-0.02,0.06-0.26c-0.03,0.02-0.06-0.03-0.08-0.04
				c0.08-0.1,0.13-0.35,0.13-0.53l0.07,0.05c0.07-0.32,0.09-0.68,0.15-1.03c0.17-0.58,0.01,0.19,0.61-5.13l0.01,0.02
				c-0.02-1.06-0.01-0.01,0.19-2.72c-0.09-0.01-0.12,0.03-0.01-0.16c-0.51-0.24,0.35-0.58-0.31-2.92l0.13,0.09
				c-0.18-0.58-0.14-1.21-0.34-1.77c0.03,0.03,0.07,0.03,0.07,0.08c0.07-0.13-0.06-0.45-0.13-0.44c-0.01-0.26,0.13,0.04-0.38-1.65
				c0.16-0.02,0.06-0.4-0.05-0.52c0.06,0.03,0.02-0.13,0.1-0.09c-0.04-0.15-0.1-0.29-0.17-0.24c0.03-0.53,0.05-0.38-0.59-1.98
				c0.43,0.33,0.37,0.73-0.41-1.55c0.01,0,0.04,0.03,0.06,0.06c-0.13-0.16,0.03,0.02-0.4-0.27c0.23,0.05-0.06-0.35,0.25-0.25
				c-0.03-0.04-0.08-0.08-0.12-0.06c-0.01-0.07,0.01-0.1,0.09-0.05c-0.11-0.23-0.16-0.12-0.22-0.33c0.03-0.01,0.06,0.03,0.11,0.04
				c-0.1-0.09-0.19-0.18-0.23-0.37c0.02,0,0.04-0.03,0.06,0.03c-0.01-0.02-0.02-0.04-0.03-0.06c0.02,0.02,0.05,0.03,0.07,0.04
				c-0.02,0-0.07,0,0.03,0.01c0,0,0,0,0,0c0.11,0.05,0-0.05,0.41-0.27l0.01,0.09c0.07-0.05,0-0.08-0.04-0.11
				c0.04-0.01,0.07-0.02,0.08-0.04l-0.15-0.1c0.36-0.01-0.16-0.13,0.13-0.12c-0.05-0.05-0.02-0.14-0.1-0.17
				c0.1-0.04-0.01-0.18,0.15-0.16c-0.03-0.02-0.11-0.11-0.11-0.15c0.17,0.11,0.04-0.18,0.23-0.12c-0.16-0.09-0.03-0.24-0.09-0.37
				c0.01,0.01,0.03,0.03,0.02,0.04c0.03-0.06,0.08-0.07,0.12-0.1c-0.13-0.04-0.13-0.15-0.2-0.23c0.15-0.04,0.14,0.09,0.27,0.1
				c-0.11-0.03-0.07-0.16-0.16-0.16c0.1-0.04,0.05-0.04,0.17-0.04c-0.16-0.06-0.01-0.06-0.09-0.14c0.09,0.01,0.08-0.18,0.24-0.01
				c-0.23-0.12,0.25-0.28-0.09-0.88c-0.01,0.03,0.02,0.04,0.04,0.06l-0.01-0.06c0.08,0.28,0.26-0.1,0.13,0.03
				c0.45,0.29,0.07-0.82,0.45-0.66c0.06-0.44,0.07,0.1,0.26-0.76c-0.23,0.33,0.43-0.34,0.75-1.19l0.01,0.04
				c0.09-0.08-0.12-0.22,0.02-0.27l0.01,0.03c0.14-0.15,0.23,0.04,0.78-1.36l0.14,0.02c-0.11-0.22,0.12,0.12,0.18-0.47
				c0.01,0.01,0.02,0.02,0.02,0.03c0.03-0.02,0.14,0.06,0.03-0.09c0.08-0.02,0.15-0.14,0.17-0.24l0.06,0.05
				c0.1-0.15,0.14-0.35,0.23-0.52c-0.06,0.05-0.17-0.03-0.15-0.08l0.11,0.03c-0.05-0.06-0.19-0.16,0.04,0l0.01-0.1
				c0.03,0.01,0.04,0.04,0.07,0.06c-0.05-0.07,0.17-0.08,0.09-0.19c0.12-0.09,0.23-0.61,0.28-1.02c0.08,0.03,0.14-0.1,0.16,0
				c0.06-0.16,0.08-0.2,0.11-0.43c-0.07,0.02-0.13-0.05,0.02-0.03c-0.01-0.28,0.48-0.39,0.4-0.64l0,0.01
				c0.03-0.33,0.06-0.17,0.69-1.23c-0.04-0.02-0.14-0.04,0.02-0.08c-0.51,0.18,0.54-0.68,0.87-2.23c0.02,0.03,0.05,0.04,0.04,0.07
				c0.1-0.03,0.1-0.22,0.03-0.24c-0.05,0.1,0.25-0.31,0.28-0.83c0.14,0.07,0.19-0.13,0.15-0.23c0.04,0.04,0.07-0.04,0.12,0.01
				c0.03-0.08,0.03-0.16-0.05-0.17c0.24-0.17,0.17-0.07,0.32-1.03c0.23,0.3-0.04,0.53,0.34-0.76c0.01,0.01,0.02,0.03,0.03,0.05
				c-0.04-0.12,0,0.02-0.21-0.29c0.17,0.13,0.11-0.15,0.32,0.03c0-0.03-0.03-0.07-0.07-0.08c0.03-0.03,0.06-0.03,0.09,0.02
				c0.01-0.14-0.08-0.12-0.04-0.23c0.03,0.01,0.04,0.04,0.07,0.07c0.01-0.21,0.03-0.18,0.07-0.69c0.12,0.1,0.07-0.04,0.2-0.01
				l0-0.11l-0.07,0.02c0.03-0.08-0.03-0.19,0.04-0.24l0.03,0.08l0.03-0.16l-0.08,0.07c-0.04-0.19-0.22-0.04,0.2-0.35
				c-0.09-0.08,0.04-0.2-0.05-0.29c0.02,0.03,0.08,0.05,0.1,0.05l-0.07-0.06c0.1-0.07,0.02-0.1,0.21-0.35l0,0.06l0.07-0.03
				l-0.05-0.04c0.19-0.15,0.1-0.3,0.58-2.42c0.02,0.1,0.12,0.04,0.18,0.02c-0.03,0-0.05-0.02-0.07-0.03
				c0.21-0.01-0.1-0.09,0.02-0.15c0,0.02-0.05-0.29,0.08-0.07c0.03-0.23-0.01,0.03,0.22-0.33c-0.28-0.08-0.02,0-0.14-0.35
				c0.1,0.14,0.04,0.07,0.28,0.05c-0.06-0.08,0.09-0.08,0-0.2c0.02,0,0.03-0.01,0.05-0.02c-0.06,0-0.03-0.24,0.09-0.97
				c0.19,0.11-0.06-0.26,0.17-0.03c-0.01-0.07-0.05-0.16-0.06-0.23c0.06,0,0.07,0.07,0.11,0.1c-0.02,0,0.04-0.19-0.13-0.22
				c0.2,0.18-0.2-0.15-0.07,0.36l-0.13-0.08l0.09,0.13c-0.04,0.01-0.12,0-0.13-0.05c-0.07,0.04-0.08,0-0.06,0.19
				c-0.03-0.02-0.1-0.02-0.07-0.05c-0.07,0.09-0.2,0.26,0.1,0.44l-0.28,0.01l0.1,0.05c-0.01,0.02-0.03,0.05-0.07,0.03
				c0.05,0.09,0.05,0,0.1,0.06c-0.12,0.06-0.03,0.25-0.2,0.22c0.07,0.05,0.08-0.06,0.11,0.04c-0.2-0.11,0.04,0.25-0.22,0.11
				c0.18,0.17-0.22,0.03-0.05,0.49c-0.05-0.03,0.03,0.03-0.23,0.26c0.12,0.06,0.06,0.15,0.06,0.24c-0.07-0.03-0.1-0.05-0.12-0.1
				c0.01,0.01-0.05,0.03,0.02,0.18l-0.09-0.05c-0.03,0.1,0.05,0.22-0.05,0.33c0.08,0.03,0.27-0.02,0.28,0.13
				c-0.05-0.01-0.13-0.03-0.14-0.07c-0.01,0.01,0.04,0.14-0.06-0.01c0.02,0.04-0.04,0.17-0.05,0.03c-0.03,0.05-0.09,0.09-0.14,0.1
				c0.12,0.05-0.18,0.08,0,0.47l0,0c0.09,0.16-0.28,0.37-0.39,0.99c-0.03-0.09-0.06,0.01-0.02-0.09c-0.06,0.08-0.12,0.09-0.06,0.22
				c-0.07-0.06-0.13,0.03-0.12,0.09l0.08-0.04c-0.18,0.24-0.11,0-0.32,0.57c0.44-0.09-0.12,0.55-0.13,0.45l0.04,0.03
				c-0.06,0.03-0.09,0.15-0.15,0.1c0.01,0.08,0.05,0.05,0.09,0.01c-0.1,0.09-0.07,0.17-0.15,0.28c0.07-0.04,0.16,0.48-0.16,0.23
				c0.04,0.13-0.12,0.18-0.16,0.23l-0.01-0.07c0.01,0.04,0,0.07-0.01,0.09l0.09,0c-0.13,0.11-0.1,0.01,0,0.28
				c0.1-0.17-0.38-0.18-0.1,0.15c-0.05-0.04-0.12-0.01-0.12,0.02c-0.01-0.04,0.14,0,0.11,0.23c0.05-0.03,0.07-0.08,0.06,0.1
				c-0.05-0.01-0.01-0.07-0.04-0.05c0.16,0.2-0.61,0.21-0.27,0.53l0.02-0.2l0.07,0.16c0.02-0.05,0-0.13,0.02-0.16
				c0.04,0.08,0.02,0.07,0.1,0.07c-0.08,0.39-0.33-0.17-0.4,0.57c0.07-0.02,0.11-0.07,0.03,0.13c-0.06-0.03-0.1-0.01-0.15,0
				c0.05,0.02-0.05,0.12,0.07,0.19c-0.15,0.1-0.19,0.01-0.07,0.39c-0.05-0.07-0.12-0.03-0.14-0.13c0,0.15,0-0.04,0.09,0.44
				c-0.19,0.04-0.2,0.09-0.44,0.74c-0.02-0.01-0.03-0.01-0.02-0.03c-0.05,0.33-0.02-0.02-0.26,0.4l0-0.03
				c-0.1,0.04,0.01,0.2-0.06,0.31c0,0-0.02-0.01-0.03-0.01c0.07,0.12,0.05,0.28,0.03,0.4c-0.03-0.02-0.05-0.03-0.07-0.04
				c0.12,0.19-0.36,0.06-0.36,1.01c-0.07-0.1-0.07-0.23-0.09-0.33c-0.02,0.05-0.07,0.19-0.02,0.24c0.03-0.08,0.04-0.1,0.04,0.24
				c-0.23-0.13-0.19,0.25-0.41,0.24c0,0.23-0.21,0.35-0.3,0.58c0.08-0.06,0.24,0.31-0.05,0.12c0.09-0.03,0.07,0.08,0.12,0.11
				c-0.08,0.05-0.2,0.09-0.38,0.06c0.08,0.02,0.08,0.09,0.02,0.14l0.13-0.05c0.03,0.2-0.23,0.06-0.2,0.26l-0.04-0.08
				c0,0.1-0.14,0.29-0.03,0.45c-0.03,0-0.09,0-0.09-0.05c-0.14,0.09-0.09,0.24-0.33,0.84l-0.03-0.07c-0.07,0.65-0.33,0.22-0.98,1.8
				c0.07,0.24-0.06,0.11-0.48,1.18c-0.16-0.02-0.08-0.07-0.29,0.29l-0.04-0.07c0.06,0.36,0.13,0.13-0.25,0.76
				c-0.02-0.01-0.05-0.04-0.04-0.07c-0.04,0.07-0.25,0.23-0.05,0.31c-0.12-0.06-0.1,0.15-0.1,0.27l-0.08-0.06
				c0.03,0.01,0.03,0.12-0.02,0.41l-0.03-0.07c-0.08,0.12,0.17,0.2-0.17,0.27c0.01,0.02-0.01,0-0.23,0.39
				c0.06-0.11,0.12-0.19,0.12-0.18c-0.1-0.17-0.42,0.21-0.3,0.57c-0.01,0.01,0.01-0.02,0.01-0.07l0.01,0.01
				c0.01-0.03,0.03-0.07,0.05-0.11c0.02,0.06,0.04,0.11-0.03,0.14c0.04,0.05,0.09,0.11,0.11,0.14c-0.02,0.14-0.53,0.4-0.89,1.6
				c0.02-0.02,0.03-0.02,0.06-0.02c-0.2,0.2,0.18,0.09-0.45,1.29c0.18,0.04-0.05,0.61-0.08,1.08c-0.1-0.16-0.21-0.31-0.25-0.53
				c0.18,0.05,0.01-0.13,0.12-0.19l-0.1-0.17l-0.03,0.09c-0.05-0.16-0.19-0.27-0.2-0.42l0.09,0.1l-0.13-0.29l0.02,0.19
				c-0.38-0.38-0.12,0.06-0.51-1.15c0.13,0.08,0.14-0.26-0.09-0.31c0.01,0.02,0.02,0,0.05,0.04c-0.13-0.29-0.15-0.41-0.15-0.51
				l0.04,0.07c-0.1-0.35,0.03,0.03-0.55-1.23l0.01,0.07c-0.19,0.02-0.01-0.28-0.17-0.42c0.16-0.12,0.08,0.02-0.35-0.76l0.06,0.02
				c-0.31-0.58-0.13-0.54-0.77-1.81c0.09,0.08,0.13,0.14-0.12-0.26c-0.06,0.06-0.03,0.06-0.05,0.16c-0.06-0.02-0.14-0.1-0.17-0.18
				c0.12,0.13,0.12-0.05,0.13-0.14c-0.02,0.02-0.05,0.01-0.07,0.03c0.09-0.24-0.08,0.02-0.13-0.24c0.04,0.04-0.34-0.39-0.03-0.19
				c-0.07-0.13-0.28-0.19-0.25-0.35c0.05,0.05,0.09,0.13,0.14,0.17c0.04-0.18,0.01-0.18-0.02-0.29l0,0.01l-0.06-0.25l0,0.07
				c-0.3-0.07-0.2-0.32-0.45-0.48c0.09,0.09,0.36,0,0.02-0.49c0.07,0.04-0.56-0.82-1.04-1.91
				C361.42,256.96,361.46,257.06,361.25,256.71z M365.71,297.93c0-0.05,0.05-0.17,0.05,0.03
				C365.74,297.95,365.73,297.94,365.71,297.93z M366.43,297.65c0-0.02,0-0.05,0-0.1C366.44,297.6,366.44,297.56,366.43,297.65z
				 M366.4,297.29c0.02,0.02,0.05-0.07,0.09-0.12C366.46,297.13,366.51,297.42,366.4,297.29z M368.14,264.2
				c-0.01-0.02-0.04,0.14-0.1,0.02C368.06,264.24,368.1,264.21,368.14,264.2z M368.04,264.38c0,0.02,0,0-0.02,0.05
				C368.02,264.42,368.03,264.4,368.04,264.38z M367.36,264.28c-0.01-0.01,0,0-0.04-0.04C367.34,264.22,367.4,264.19,367.36,264.28z
				"
                />
                <path
                  className="st10"
                  d="M429.36,224.27l0.24,0.02c-0.33-0.21-1.05,0.25-1.04-0.05c-0.07,0.11-0.32-0.19-1.23,0.29
				c0.18-0.24-0.46,0.1-0.46-0.09c-0.12,0.2-0.57,0.26-0.82,0.47c0.01-0.02,0.03-0.06,0.06-0.06c-0.16,0.05-0.23,0.03-0.34,0.05
				c0.17-0.09-0.49,0.8-0.35,0.13c0.06,0.09-0.27,0.27-0.16,0.32c-0.18,0.01-0.14,0.03-0.27-0.02c0.19,0.08-0.67,0.61-0.47,0.2
				c-0.27,0.65-0.1-0.09-1.22,1.32c0.1,0.07,0.23,0.1-0.03,0.28c-0.97-0.31,0.02,0.02-1.41,1.55l-0.06-0.15
				c-0.42,0.9-0.89,1.72-2.6,4.42l0.02-0.07c-0.2,0.16-0.04,0.36-0.28,0.47c0.01-0.22-0.42,0.56-2.5,0.57l0.09-0.06
				c-0.15-0.04-0.1-0.05-0.15-0.33c-0.15,0.1,0.13-0.12-0.19-0.9c0.04,0.01,0.07,0.09,0.1,0.16c-0.13-0.26,0.1-0.05,0-0.29
				c-0.02,0.03-0.06-0.02-0.09-0.02c0.06-0.13,0.07-0.4,0.07-0.61l0.07,0.05c0.23-1.09,0.16-1.49,2.38-4.17
				c-0.73,0.46-1.27,0.73-3.19,1.79c0.08-0.08-0.28,0.09-0.28-0.05c-0.49,0.11-0.78,0.64-2.66,0.68l0.02-0.01
				c-0.54-0.91-4.3-0.46,0.25-4.14c0.01,0.04,0.01,0.08,0.01,0.13c0.41-0.37,1-0.74,1.25-0.98c-0.23-0.06-1.21,0.26-2.83,0
				c0.31-0.27-0.03,0.58-1.49-1.46c0.15-0.03,0.03-0.4-0.04-0.55c0.05,0.04,0.04-0.12,0.11-0.07c-0.01-0.15-0.02-0.3-0.1-0.28
				c0.41-0.71-0.01-0.61,1.07-1.85c-0.08,0.67-0.84,0.39,1.69-0.76c0,0.01-0.03,0.04-0.07,0.07c0.19-0.14-0.03,0.02,0.33-0.42
				c-0.07,0.24,0.42-0.07,0.28,0.24c0.05-0.03,0.1-0.08,0.08-0.12c0.08-0.01,0.11,0.01,0.05,0.09c0.27-0.12,0.15-0.16,0.41-0.24
				c0.01,0.03-0.04,0.06-0.06,0.11c0.11-0.1,0.23-0.21,0.46-0.23c-0.01,0.02,0.02,0.04-0.04,0.06c0.38-0.09,0.61-0.33,1.05-0.34
				c-0.06,0.09-0.04,0.2,0.42,0.08l-0.1-0.05c0.2-0.03,0.36-0.15,0.54-0.13l-0.14,0.08c0.12-0.03,0.25-0.06,0.37-0.08
				c-0.07-0.01-0.15-0.01-0.22-0.02c0.2-0.1-0.04-0.17,0.23-0.16c0.4,0.06,0.13,0.08,1.27-0.05c-0.13,0.11,0.25,0.21,0.39,0.01
				c-0.03,0.01-0.01,0.02-0.07,0.04c0.35-0.01,0.5,0.01,0.61,0.03c-0.02,0-0.05,0.01-0.07,0.01c0.44,0.05-0.04,0.01,1.62,0.02
				l-0.08-0.02c0.08-0.18,0.31,0.12,0.54,0.06c0.05,0.2-0.06,0.05,1,0.11l-0.06,0.04c0.81,0.09,0.63,0.16,2.31,0.53
				c-0.14,0.01-0.22,0,0.33,0.09c-0.01-0.09-0.04-0.06-0.11-0.15c0.07-0.03,0.2-0.03,0.3,0c-0.21,0-0.04,0.12,0.03,0.19
				c-0.01-0.03,0.03-0.04,0.03-0.07c0.44,0.23,0.07-0.1,1.03,0.31c-0.08,0-0.18-0.04-0.26-0.04c0.16,0.18,0.04,0.03,0.52,0.36
				l-0.06-0.06c0.3-0.14,0.43,0.14,0.78,0.11c-0.16-0.01-0.3,0.19,0.38,0.43c-0.12,0,1.12,0.36,2.33,1.2
				c-0.13,0.06-0.28,0.08,0.6,0.41l-0.07-0.1c-0.02,0.05-0.17,0.14-0.22-0.12l0.11,0.05c0.02-0.23-0.41-0.24-0.6-0.38
				c0.07-0.07,0.19-0.12-0.38-0.46c0.04-0.01,0.03-0.1,0.11-0.03c-0.22-0.22-0.48-0.15-0.52-0.3l0.03,0
				c-0.37-0.21-0.94-0.67-2.69-1.24c0.03-0.03-0.45-0.23-0.67-0.36l0.01-0.02c-0.09,0.11-0.32-0.01-0.51-0.03
				c0.03-0.07,0.07-0.09,0.18-0.1c-0.03,0.01-0.01-0.06-0.4-0.03l0.07-0.08c-0.13,0.09-0.27-0.33-0.97-0.03l0.14-0.05
				c-0.06,0.02-0.41-0.08-0.1-0.06c-0.52-0.18-0.21-0.08-1.35-0.24l0,0c-0.28,0.13-1.02-0.35-2.49-0.32
				c0.18-0.05-0.06-0.06,0.19-0.04c-0.23-0.04-0.29-0.1-0.54-0.01c0.09-0.09-0.17-0.12-0.29-0.1l0.16,0.07
				c-0.13,0.01-0.24-0.01-0.35-0.01l0.06-0.12c-0.51-0.04-1.1-0.01-2.21,0.22c0.25,0.24-0.6-0.21-0.46,0.01
				c-0.29-0.02-0.44,0.07-0.74,0.07c0.07,0.03-0.78,0.49-0.64,0.05c-0.25,0.14-0.5,0.06-0.66,0.08l0.13-0.06
				c-0.08,0.04-0.15,0.05-0.22,0.07l0.1,0.06c-0.09,0-0.15,0.02-0.16-0.01c-0.12,0.16,0.09,0.27-0.42,0.28
				c0.33-0.06-0.03-0.09,0.19-0.19c-0.05,0.02-0.16,0.06-0.16,0.01c-0.35,0-0.11,0.16-0.45,0.26c0.02-0.07-0.11-0.09-0.16-0.06
				c0.11-0.03,0.05,0.14-0.34,0.3l0.13-0.02c-0.17,0.28-0.61,0.06-1.51,0.46c0.12,0.03,0.12,0-0.02,0.18
				c0.13-0.1,0.26-0.19,0.39-0.28l-0.21,0.25c0.12-0.07,0.24-0.19,0.33-0.21c-0.1,0.12-0.09,0.1,0,0.13
				c-0.64,0.35-0.54-0.26-1.45,0.85l0.13-0.08c-0.46,0.68-0.37,0.13-0.73,1.75c-0.06-0.12-0.13,0.04-0.15-0.21
				c0.08,0.48-0.08-0.18,1.17,2.78c-0.01,0-0.02,0.01-0.02,0.01c0.64,0.32-0.07,0.12,0.96,0.61c-0.01,0-0.03,0-0.05,0
				c0.11,0.06,0.01,0.02,0.42,0.03c0,0-0.04,0.01-0.05,0.02c0.28-0.1,1.17-0.09,0.11,0.17l0.09-0.07c-0.36,0.18-0.21-0.03-0.5,0.48
				l0.06,0c-0.29,0.52-0.4-0.14-0.77,0.94c-0.01-0.18,0.13-0.46,0.22-0.64c-0.1,0.1-0.31,0.4-0.3,0.5c0.02-0.06,0.04-0.14,0.09-0.17
				c-0.49,1.49-0.49,0.4-0.08,3.29c-0.04-0.32,0.15,0.14,0.26-0.06c0.39,0.22,0.32,0.36,0.71,0.73c-0.03-0.09,0.14-0.08,0.33-0.03
				c-0.09-0.04-0.17-0.09-0.25-0.14c0.46-0.04,0.39,0.24,0.85,0.12l-0.14,0.07c0.33-0.01,0.42,0.04,2.5-0.62l-0.02,0.06
				c0.24-0.14,0.15,0.02,0.62-0.26l-0.08,0.09c0.2-0.07,0.23-0.01,0.69-0.46c-0.45,2.41-1.07,0.9,1.62,5l-0.09,0.09
				c0.75-0.5,0.39-0.1,1.78-1.05c0.01,0.02,0.03,0.06-0.03,0.12c0.18-0.12,0.67-0.51,0.46-0.59c0.11,0.09,0.31-0.34,0.4-0.56
				l0.06,0.09c-0.02-0.03,0.1-0.28,0.33-0.83l0,0.12c0.18-0.3-0.1-0.3,0.4-0.66c-0.04,0.1,0.02-0.35,0.08-0.21
				c0.28-0.27-0.1-0.04,0.42-0.71c-0.13,0.25-0.25,0.46-0.24,0.43c0.08,0.04,0.2-0.16,0.23,0.06c0.06-0.19-0.07-0.02,0.23-0.74
				l0,0.03c0.13-0.28,0.12-0.13,0.39-0.58c-0.09-0.04,0-0.16-0.14-0.08c0.02-0.02-0.02,0.04-0.05,0.14l0-0.01
				c-0.03,0.07-0.08,0.15-0.13,0.25c0.17-0.77,0.35-0.38,1.12-1.82c-0.08,0.27-0.12,0.23,0.42-0.53l0,0.06
				c0.58-0.35,0.49-1.04,1.25-1.32c-0.05,0.02-0.07,0.03-0.1,0.02c0.35,0.03,1.69-1.52,3.89-1.69l-0.06-0.06
				C429.17,224.84,428.87,225.13,429.36,224.27z M420.73,230.66c0,0.05,0.08-0.29,0.14-0.11
				C420.85,230.52,420.78,230.61,420.73,230.66z M420.95,230.23c0.02-0.05,0,0,0.05-0.11C420.99,230.15,420.97,230.18,420.95,230.23
				z M421.78,230.04c0.02,0.06,0.05,0.07-0.05,0.13C421.73,230.14,421.75,230.17,421.78,230.04z"
                />
                <path
                  className="st10"
                  d="M427.83,223.34c0,0.02-0.13,0.01-0.06-0.14c-0.08-0.08-0.11-0.09-0.22,0.16l0.04-0.26l-0.04,0.1
				c-0.01-0.01-0.02-0.02,0-0.06c-0.05,0.06-0.01,0.05-0.04,0.11c0-0.1-0.11,0.02-0.06-0.15c-0.04,0.07,0.01,0.07-0.04,0.11
				c0.07-0.2-0.12,0.06-0.01-0.19c-0.01,0.02-0.02,0.03-0.03,0.04c0.01-0.03,0.01-0.06-0.07-0.05c-0.01-0.1,0.13-0.09-0.11,0.04
				c0.03-0.04-0.03,0.01-0.09-0.17c-0.04,0.12-0.08,0.09-0.12,0.1c0.02-0.07,0.03-0.1,0.06-0.13c-0.04,0-0.03-0.01-0.08,0.06
				l0.03-0.09c-0.04-0.01-0.11,0.09-0.14,0.01c-0.03,0.09-0.02,0.25-0.09,0.3c0.01-0.08,0.05-0.07,0.02-0.37
				c-0.01,0.03-0.08,0.2-0.05-0.03c-0.02,0.04-0.03-0.01-0.06,0.09c-0.01,0,0-0.05,0-0.08c-0.03,0.04-0.01-0.01-0.1,0.11l0,0
				c-0.11,0.12-0.32-0.29-0.95-0.47c-0.04,0.03-0.02,0.06-0.01,0.09c-0.12-0.15-0.04,0.05-0.31-0.24l0.03-0.03
				c-0.02,0.02-0.03,0.01-0.04,0.01l0,0.08c-0.01-0.02-0.02-0.02-0.02-0.05c-0.02,0.05-0.01,0.14-0.05,0.21
				c-0.01-0.04-0.06,0.04-0.05-0.07c0.05,0.05,0.01-0.11,0.06-0.12c-0.08-0.07,0-0.12-0.13,0.07c0.02-0.06,0-0.12-0.01-0.11
				c0.02,0,0,0.1-0.03,0.15c-0.03-0.05-0.05,0.03-0.06,0.02c0.02,0.05,0.03,0.04-0.04,0.09c0-0.05,0.03-0.03,0.02-0.05
				c-0.04,0.1-0.05-0.12-0.1-0.06c-0.01-0.04,0.01-0.12,0-0.15c-0.07,0.08-0.09-0.11-0.13,0.11l0.09-0.05
				c-0.01,0.18-0.25,0.16-0.34-0.06c0,0.05-0.05-0.01-0.08,0.13c0.03-0.3-0.23,0.25-0.12-0.11c-0.02,0-0.03-0.02-0.04,0.03
				c0.03-0.01-0.12-0.01-0.14,0.2c-0.04-0.22-0.16,0-0.2-0.19c-0.04,0.02-0.09,0.04-0.13,0.04c0.08,0.09-0.13-0.23-0.45,0.07
				c0.02-0.09,0.02-0.05,0.02-0.14c-0.05,0.39-0.14,0.04-0.31-0.03c-0.03,0.03-0.09,0.13-0.13,0.15c0.03-0.1,0.08-0.15,0.11-0.21
				c-0.18,0.13,0.04,0.13-0.65-0.1c0.03,0.07-0.02,0.11-0.01,0.18c-0.04-0.03-0.08-0.14-0.1-0.34c0.01,0.08-0.02,0.11-0.05,0.08
				c0.06,0.03-0.12,0.1-0.07-0.04c-0.04,0.04-0.13,0-0.17,0.17c0.02-0.24-0.17-0.09-0.54,0.12c-0.07-0.14-0.07-0.44-0.36-0.04
				c-0.07-0.16-0.2-0.06-0.29-0.14c0.02,0.03,0.01,0.12-0.01,0.14c-0.02-0.03-0.05,0.11-0.06-0.03l0.01,0
				c-0.03-0.14-0.07-0.1-0.1-0.1l0.01,0.18c-0.12-0.26-0.22,0.12-0.3-0.11c-0.02,0.05-0.04,0.05-0.06,0.1
				c-0.02-0.05,0.02-0.15-0.18-0.09c0.03-0.33-0.2,0.03-0.39,0.17c-0.02-0.13-0.08,0-0.11,0.06l-0.01-0.1
				c-0.01,0.07-0.05,0.17-0.24,0.14c0.01,0.07-0.08,0.08-0.18,0.03c0.05-0.01,0.09-0.01,0.09,0c0.04-0.03-0.31-0.23-0.25,0.06
				c-0.01-0.01,0.01,0,0.02-0.03l0,0.01c0.01,0,0.03-0.01,0.05-0.02c-0.01,0.05-0.01,0.1-0.05,0.05c0,0.06,0,0.13,0,0.16
				c-0.05-0.01-0.17-0.3-0.38,0.02c-0.06-0.05,0-0.12-0.06-0.09c-0.13-0.25-0.01-0.04-0.3,0.1c0.01,0.01,0.02,0.02,0.03,0.04
				c-0.05,0-0.27-0.15-0.5,0.16l-0.04-0.1c0.07,0.23-0.17,0.06-0.18,0.16c-0.01,0-0.08-0.01-0.12-0.03l-0.04,0.11
				c-0.11-0.18-0.32-0.38,0.06,0.53l0.01-0.11l0.04,0.07l-0.03,0.01c0.04,0.07,0.04,0,0.04-0.05c0.02,0.04,0.03,0.07,0.04,0.08
				l0-0.16c0.02,0.06,0.02,0.13,0.05,0.15c0-0.07-0.06-0.13-0.04-0.19c0.04,0.07,0.05,0.13,0.06,0.06c0.01,0.04,0.02,0.07,0.01,0.09
				c0.01-0.05,0.05-0.04,0.04-0.12c0.05,0.08,0.07-0.04,0.1,0.11c0-0.04,0-0.13,0.02-0.14c0.01,0.19,0.08-0.01,0.12,0.18
				c-0.02-0.17,0.07-0.1,0.1-0.2c0,0.01,0,0.04,0,0.03c0.03,0.01,0.05,0.05,0.07,0.08c-0.03-0.13,0-0.17,0-0.26
				c0.07,0.12,0.02,0.16,0.06,0.28c-0.03-0.11,0.03-0.12-0.01-0.21c0.04,0.07,0.03,0.03,0.07,0.14c-0.04-0.17,0.02-0.03,0.01-0.14
				c0.06,0.07,0.05,0.15,0.07,0.09c0.08,0.23,0.01-0.04,0.22-0.21c0.03,0.15,0-0.24,0-0.09l0.01-0.04c0.04,0.11-0.02,0.05,0,0.13
				c0.02-0.04,0.14-0.2,0.09,0.02l-0.05-0.03c0.12,0.08,0.21,0.18,0.62-0.13c0,0.04,0,0.07,0.02,0.11c-0.07-0.12,0.29-0.22,0.9-0.15
				c-0.01-0.1,0.2-0.2,0.29-0.01l0.02-0.12l0.03,0.13c0.04-0.16,0.02,0.17,0.18-0.09c0,0.04-0.01,0.06-0.02,0.09
				c0.02-0.16,0.05,0.14,0.05-0.08c0.03,0.06,0.08,0.06,0.11,0.03c0.03,0.09-0.03,0.11,0.22,0.02c-0.03-0.03-0.03-0.16-0.01-0.18
				l0.02,0.11c0.01-0.07-0.03-0.04,0-0.13c0.03,0.01,0.03,0.13,0.02,0.17l0.03-0.04c0,0.03,0,0.05,0,0.1
				c0.01-0.07,0.07,0.11,0.08-0.01c-0.27,0.32,0.88-0.35,1.44-0.01c0-0.01,0-0.09,0.01-0.07c-0.04-0.15,0.31,0.17,0.95-0.01
				c-0.01,0.03-0.01,0.06-0.02,0.06c0.02,0.09,0.09,0.01,0.1-0.06c-0.02,0.08,0.06,0.11,0.07,0.11c0.02-0.04,0.07,0.02,0.07-0.08
				c0.04,0.05,0.09-0.04,0.12,0.02c0.03-0.11,0.07,0.06,0.09-0.09c-0.02,0.16,0.06,0.13,0.1,0.06c-0.01,0.05,0.02,0.05,0,0.12
				c0.03,0,0.06-0.02,0.07-0.11c0.04,0.2,0.13-0.14,0.14,0.11c0.1-0.07,0.2-0.12,0.29-0.15c0.02,0.15-0.05,0.09,0.32,0.09
				c0,0.01-0.01,0.03-0.02,0.04c0.01,0,0.03,0.03,0.04-0.04l-0.02,0.02c-0.01-0.11,0.09-0.21,0.1-0.26
				c-0.05,0.2,0.06,0.06-0.01,0.31c0.01-0.01,0.03-0.04,0.03-0.09c0.07,0.12,0,0.03,0.34,0.13c-0.02-0.02,0.06,0.04,0.04-0.1
				c-0.02,0.05,0.15-0.1,0.07,0.02l0.07-0.01c-0.03-0.03,0.01-0.29,0.08-0.09c0.05-0.03,0.1-0.09,0.04,0.13
				c0.04-0.1,0.09-0.01,0.13-0.12c-0.01,0.03-0.03,0.09-0.03,0.11l0.03-0.08c-0.02,0.13,0.05,0.06,0.04,0.04
				c-0.01,0.05,0.26-0.03,0.11,0.15c0.08-0.01,0.19-0.12,0.25-0.07c0.02-0.22,0-0.01,0.08,0.01c-0.01,0.01-0.03,0.1-0.03,0.08
				c0.04,0.04,0.11-0.02,0.2-0.06c-0.01,0.06-0.05,0.06,0.08,0.11c0.02-0.04,0.04-0.03,0.04-0.1c0.02,0.15,0.14,0.32,0.38-0.02
				c-0.05,0.04-0.03,0.13-0.03,0.17l0.02-0.07c-0.03,0.21,0.08-0.13,0.06-0.01c0.03-0.02,0.12-0.06,0.02,0.06
				c0.09,0-0.01-0.01,0.11,0.15c0.08-0.29,0-0.02,0.18-0.19c-0.08,0.14-0.03-0.01-0.07,0.28c0.05-0.08,0.02,0.08,0.09-0.04
				c-0.01,0.03,0.02,0.01-0.01,0.09c-0.21-0.09,0.39-0.23,0.4,0.05c0.04-0.02,0.08-0.07,0.12-0.09c-0.01,0.06-0.04,0.08-0.07,0.11
				c0-0.02,0.07,0,0.12-0.13C427.86,223.52,427.75,223.5,427.83,223.34z M419.04,222.45c0-0.02,0-0.04,0-0.05
				C419.04,222.39,419.07,222.41,419.04,222.45z M419.3,223.04c0-0.03-0.03-0.11,0.04,0C419.32,223.04,419.33,223.04,419.3,223.04z"
                />
                <path
                  className="st10"
                  d="M429.71,219.82c0.02-0.01,0.05,0.07,0.18,0.04c-0.04-0.04-0.09-0.17,0.08-0.19
				c-0.01,0.02-0.1-0.12,0.07-0.16c0.01-0.13,0-0.18-0.27-0.12l0.24-0.13l-0.1,0.03c0-0.01,0-0.03,0.04-0.04
				c-0.08-0.01-0.04,0.02-0.11,0.02c0.08-0.08-0.08-0.1,0.07-0.17c-0.08,0.01-0.04,0.06-0.11,0.03c0.21-0.05-0.13-0.09,0.14-0.14
				c-0.19-0.04,0.1-0.17-0.17-0.2c0.06,0-0.03-0.02,0.07-0.2c-0.12,0.04-0.12-0.03-0.16-0.05c0.07-0.03,0.1-0.03,0.14-0.02
				c-0.02-0.03-0.01-0.04-0.1-0.05l0.09-0.03c-0.02-0.05-0.14-0.06-0.11-0.14c-0.08,0.03-0.21,0.14-0.29,0.1
				c0.04-0.02,0.12-0.05,0.15-0.04c0.04-0.11-0.05,0.06,0.16-0.17c-0.03,0.01-0.21,0.04-0.01-0.07c-0.05,0.01-0.02-0.04-0.11,0
				c-0.01-0.01,0.04-0.03,0.06-0.05c-0.05,0,0-0.02-0.16-0.03l0,0c-0.17,0.14,0.05-0.24-0.17-0.61c0.06,0.04,0.08-0.18,0.01-0.03
				c0.06-0.33-0.45-0.36-0.16-0.56l-0.04,0.01c0.03-0.04,0-0.1,0.06-0.11c-0.05-0.02-0.07,0.02-0.08,0.05
				c0.03-0.08-0.04-0.09-0.03-0.16c-0.08,0.06-0.33-0.09,0-0.16c-0.1-0.02,0-0.12,0-0.16l0.04,0.01c-0.03-0.01-0.03-0.02-0.04-0.04
				l-0.07,0.05c0-0.02,0-0.04,0.03-0.05c-0.06,0.01-0.12,0.07-0.2,0.07c0.03-0.04-0.07-0.04,0.02-0.1c0,0.08,0.1-0.06,0.14-0.01
				c0-0.12,0.09-0.08-0.15-0.09c0.06-0.02,0.1-0.07,0.08-0.07c0.01,0.03-0.09,0.06-0.14,0.06c0.02-0.07-0.06-0.03-0.06-0.06
				c-0.03,0.04-0.01,0.06-0.1,0.01c0.04-0.02,0.04,0.01,0.06-0.01c-0.11,0.02,0.06-0.13-0.03-0.14c0.03-0.04,0.1-0.06,0.12-0.09
				c-0.13,0.03,0.09-0.17-0.18-0.07l0.1,0.06c-0.15,0.09-0.29-0.12-0.19-0.39c-0.04,0.03-0.03-0.06-0.15,0
				c0.26-0.15-0.36-0.09,0-0.19c-0.09-0.04,0.02,0-0.31-0.05c0.15-0.17-0.12-0.17,0.01-0.32c-0.28-0.19-0.13,0.02-0.51-0.54
				c0.09-0.03,0.05,0,0.13-0.06c-0.35,0.18-0.13-0.12-0.2-0.35c-0.04-0.01-0.17-0.02-0.22-0.05c0.1-0.03,0.18-0.01,0.25-0.01
				c-0.02-0.03-0.06-0.08-0.13-0.06c0.04,0.01-0.2,0.15-0.05-0.34c-0.14-0.03-0.09-0.2-0.17-0.29c-0.01,0.07-0.31,0.09-0.08-0.01
				c-0.03,0.01-0.07,0.01-0.1,0.03c0.01-0.06,0.06-0.15,0.19-0.28c-0.06,0.05-0.1,0.05-0.1,0c0.02,0.09-0.17-0.07-0.02-0.09
				c-0.06-0.02-0.1-0.14-0.27-0.07c0.4-0.13-0.32-0.19-0.97-1.28l0,0.01c0.09-0.11,0.03-0.12,0-0.16l-0.15,0.11
				c0.13-0.26-0.27-0.16-0.13-0.36c-0.05,0.01-0.07-0.02-0.13-0.01c0.04-0.07,0.13-0.06-0.05-0.23c0.27-0.15-0.15-0.18-0.44-0.31
				c0.1-0.09-0.06-0.08-0.13-0.08c0.1-0.1,0.16-0.02-0.23-0.23c-0.06,0.03-0.12-0.03-0.16-0.17c0.04,0.05,0.08,0.09,0.07,0.09
				c0.08,0.01-0.01-0.4-0.24-0.23c0-0.01,0.01,0.01,0.04,0l-0.01,0c0.01,0.01,0.03,0.02,0.05,0.04c-0.05,0.02-0.09,0.04-0.08-0.02
				c-0.05,0.03-0.11,0.07-0.14,0.09c-0.03-0.07,0.15-0.31-0.31-0.38c0.12-0.37,0.11-0.11-0.28-0.36c0,0.02,0,0.03-0.02,0.05
				c-0.05-0.07-0.02-0.31-0.52-0.43l0.06-0.09c-0.03,0.3-0.15-0.35-0.67-0.27c0.01,0,0.01,0,0.01,0c-0.06,0.08-0.14,0.19-0.25,0.38
				l0.11-0.04l-0.04,0.07l-0.04-0.03c-0.03,0.07,0.03,0.04,0.07,0.01c-0.02,0.04-0.04,0.06-0.04,0.08l0.14-0.08
				c-0.04,0.05-0.1,0.08-0.1,0.13c0.07-0.04,0.07-0.13,0.14-0.13c-0.03,0.07-0.07,0.11-0.01,0.09c-0.02,0.03-0.05,0.05-0.07,0.06
				c0.05-0.02,0.07,0.03,0.14-0.02c-0.04,0.09,0.09,0.04-0.01,0.16c0.03-0.02,0.12-0.06,0.14-0.05c-0.16,0.11,0.07,0.07-0.07,0.21
				c0.14-0.1,0.14,0.03,0.25,0c-0.01,0.01-0.03,0.02-0.03,0.01c0.02,0.03-0.01,0.08-0.01,0.12c0.09-0.1,0.16-0.09,0.23-0.13
				c-0.06,0.13-0.13,0.1-0.2,0.21c0.07-0.08,0.13-0.03,0.18-0.11c-0.03,0.08-0.01,0.05-0.07,0.14c0.12-0.12,0.04,0,0.13-0.06
				c-0.02,0.09-0.1,0.13-0.03,0.12c-0.14,0.19,0.05,0,0.35,0.12c-0.02,0.06-0.03,0.04,0.14-0.07c-0.01-0.01-0.03,0.01-0.06,0.03
				l0.04-0.01c-0.12,0.14-0.15,0-0.07,0.17l-0.01-0.07c-0.09,0.25,0.43,0.13,1.32,1.5c0.08-0.05,0.29,0.06,0.22,0.29l0.12-0.04
				l-0.08,0.1c0.17-0.05-0.15,0.1,0.22,0.14c-0.03,0.03-0.06,0.02-0.09,0.03c0.16-0.07-0.08,0.13,0.11,0.01
				c-0.03,0.06,0.01,0.11,0.06,0.14c-0.07,0.09-0.09,0.03,0.14,0.24c0-0.05,0.11-0.12,0.14-0.11l-0.08,0.08
				c0.06-0.03,0.21-0.13-0.02,0.03l0.06,0.01c-0.02,0.02-0.05,0.03-0.08,0.05c0.07-0.03-0.04,0.13,0.07,0.08
				c-0.44-0.09,0.89,0.68,1.08,1.51c0.02-0.01,0.12-0.1,0.03,0.03c0.07-0.29,0.47,0.71,0.4,0.61c0.2,0.05,0.17,0.27,0.34,0.33
				c-0.03,0.01-0.06,0.02-0.06,0.01c-0.06,0.07,0.06,0.1,0.12,0.07c-0.04,0-0.11,0.15,0.07,0.16c-0.01,0.07,0.09,0.07,0.07,0.14
				c0.11-0.03,0,0.11,0.14,0.04c-0.14,0.08-0.06,0.15,0.02,0.14c-0.05,0.01-0.03,0.05-0.09,0.07c0.02,0.03,0.06,0.05,0.13,0.01
				c-0.13,0.16,0.21,0.06,0.01,0.21c0.13,0.06,0.24,0.14,0.32,0.21c-0.12,0.12-0.09,0.02,0.16,0.39c-0.01,0.01-0.03,0-0.05,0
				c0.01,0.01,0,0.05,0.06,0.01l-0.03-0.01c0.08-0.07,0.23-0.03,0.28-0.06c-0.2,0.07,0,0.11-0.26,0.18c0.02,0,0.05,0,0.1-0.02
				c-0.04,0.16-0.01,0.03,0.14,0.45c0-0.03,0.02,0.09,0.11-0.02c-0.05,0.01,0.18,0.09,0.03,0.08l0.06,0.07
				c0.04-0.06,0.03-0.07,0.16-0.07c-0.05,0.08,0.02,0.1-0.12,0.22c0.11-0.03,0.07,0.08,0.19,0.06c-0.03,0-0.09,0.03-0.1,0.04
				l0.09-0.02c-0.12,0.07-0.02,0.1-0.01,0.07c-0.06,0.02,0.21,0.24-0.05,0.21c0.06,0.08,0.23,0.12,0.24,0.22
				c0.18-0.12,0.01-0.01,0.05,0.09c-0.02-0.01-0.1,0.03-0.08,0.02c0,0.07,0.09,0.1,0.18,0.17c-0.02-0.03-0.11,0.21,0.07,0.13
				c-0.09,0.11-0.2,0.31,0.28,0.39c-0.07-0.02-0.12,0.05-0.16,0.08c0.02-0.01,0.05-0.02,0.07-0.03c-0.19,0.1,0.15-0.01,0.05,0.06
				c0.03,0.02,0.14,0.09-0.03,0.06c0.07,0.1,0-0.03-0.04,0.22c0.28-0.1,0.01-0.01,0.27,0.06c-0.16,0.01-0.02-0.03-0.26,0.11
				c0.09,0-0.05,0.07,0.09,0.07c-0.03,0.01,0.01,0.03-0.07,0.05c-0.05-0.27,0.42,0.21,0.23,0.47c0.04,0.02,0.11,0.04,0.15,0.06
				C429.79,219.84,429.76,219.82,429.71,219.82z M424.06,210.5c-0.01-0.03-0.05-0.04,0.03-0.07
				C424.06,210.43,424.06,210.46,424.06,210.5z M424.34,209.89c0.02-0.01,0.03-0.02,0.04-0.03
				C424.4,209.85,424.4,209.9,424.34,209.89z"
                />
                <path
                  className="st10"
                  d="M433.4,218.33c0.02,0-0.01,0.09,0.11,0.15c0-0.06,0.04-0.2,0.19-0.11c-0.02,0.01,0-0.16,0.16-0.09
				c0.09-0.09,0.12-0.15-0.13-0.28l0.26,0.05l-0.1-0.05c0.01-0.01,0.02-0.02,0.06,0c-0.05-0.06-0.05-0.01-0.1-0.06
				c0.11-0.01,0-0.14,0.17-0.09c-0.07-0.05-0.07,0.02-0.11-0.05c0.19,0.1-0.04-0.16,0.2-0.02c-0.12-0.16,0.18-0.07,0.01-0.28
				c0.04,0.04-0.01-0.04,0.19-0.11c-0.12-0.05-0.07-0.1-0.08-0.15c0.07,0.03,0.1,0.05,0.12,0.08c0-0.04,0.02-0.04-0.04-0.11
				l0.09,0.04c0.01-0.05-0.07-0.14,0.02-0.18c-0.08-0.03-0.25-0.03-0.29-0.12c0.05,0.01,0.13,0.04,0.14,0.06
				c0.09-0.06-0.06,0.01,0.23-0.03c-0.03-0.01-0.18-0.11,0.04-0.06c-0.04-0.03,0.02-0.04-0.08-0.08c0.01-0.01,0.05,0,0.08,0
				c-0.04-0.04,0.01-0.01-0.1-0.14l0,0c-0.21-0.01,0.19-0.15,0.29-0.59c0.01,0.07,0.18-0.08,0.03-0.02
				c0.27-0.21-0.09-0.59,0.27-0.55l-0.04-0.02c0.05-0.01,0.06-0.08,0.12-0.04c-0.03-0.05-0.06-0.04-0.09-0.01
				c0.08-0.04,0.03-0.1,0.09-0.15c-0.1-0.01-0.18-0.3,0.11-0.12c-0.06-0.08,0.08-0.09,0.11-0.12l0.02,0.04
				c-0.01-0.02-0.01-0.04,0-0.05l-0.08-0.01c0.02-0.01,0.02-0.03,0.06-0.02c-0.05-0.03-0.14-0.02-0.2-0.08
				c0.05-0.01-0.02-0.09,0.09-0.06c-0.06,0.07,0.11,0.02,0.11,0.09c0.08-0.09,0.13,0-0.05-0.17c0.06,0.03,0.12,0.01,0.11,0
				c-0.01,0.03-0.1-0.01-0.14-0.05c0.06-0.04-0.02-0.06-0.01-0.09c-0.05,0.02-0.05,0.04-0.08-0.06c0.05,0.01,0.02,0.04,0.05,0.03
				c-0.09-0.07,0.13-0.05,0.08-0.13c0.05-0.01,0.11,0.03,0.15,0.02c-0.12-0.07,0.19-0.06-0.08-0.18l0.03,0.12
				c-0.14-0.13-0.21-0.02,0.06-0.36c-0.05,0.01-0.09,0.04-0.06-0.08c0.07,0.02,0.1,0.01,0.14,0.01c-0.05-0.01,0.02-0.07-0.11-0.11
				c0.29,0.08-0.2-0.32,0.14-0.14c-0.04-0.09,0.01,0.02-0.19-0.26c0.23-0.02,0.03-0.22,0.23-0.24c-0.07-0.34-0.11-0.08,0.02-0.78
				c0.08,0.04,0.04,0.04,0.13,0.05c-0.37-0.12-0.01-0.18,0.11-0.41c-0.03-0.04-0.11-0.14-0.12-0.2c0.09,0.05,0.13,0.13,0.18,0.18
				c0.01-0.03,0.01-0.11-0.05-0.14c0.03,0.04-0.26-0.03,0.2-0.29c-0.08-0.13,0.08-0.21,0.09-0.34c-0.05,0.04-0.28-0.16-0.05-0.07
				c-0.03-0.01-0.05-0.04-0.09-0.05c0.05-0.04,0.15-0.07,0.33-0.06c-0.08,0-0.1-0.04-0.07-0.07c-0.05,0.08-0.07-0.17,0.05-0.08
				c-0.03-0.05,0.03-0.18-0.13-0.25c0.38,0.19-0.09-0.37,0.26-1.65l0,0.01c0.14-0.01,0.11-0.07,0.12-0.11l-0.18-0.03
				c0.28-0.09-0.07-0.32,0.18-0.36c-0.04-0.03-0.04-0.07-0.08-0.1c0.09-0.02,0.13,0.05,0.13-0.2c0.29,0.09,0.03-0.24-0.06-0.55
				c0.13,0.01,0.02-0.1-0.03-0.15c0.14,0.01,0.12,0.1,0.02-0.34c-0.06-0.02-0.06-0.11,0.02-0.24c-0.01,0.07-0.01,0.12-0.02,0.11
				c0.06,0.07,0.29-0.29,0.01-0.34c0.01-0.01,0,0.01,0.02,0.04l-0.01,0c0,0.02,0,0.04,0,0.07c-0.05-0.02-0.09-0.05-0.03-0.07
				c-0.06-0.02-0.13-0.04-0.16-0.05c0.03-0.07,0.33-0.1,0.08-0.5c0.36-0.16,0.15,0.01,0.08-0.46c-0.02,0.01-0.02,0.02-0.05,0.02
				c0.02-0.1,0.21-0.22-0.02-0.7l0.11-0.01c-0.21,0.17,0.15-0.36-0.23-0.71c0.01,0.01,0,0.01,0.01,0.01
				c-0.1,0.01-0.23,0.02-0.45,0.06l0.1,0.06l-0.08,0.02l-0.01-0.05c-0.07,0.03-0.01,0.05,0.04,0.07c-0.04,0.01-0.07,0.01-0.09,0.03
				l0.16,0.06c-0.06,0.01-0.13-0.03-0.16,0.01c0.07,0.03,0.14-0.03,0.19,0.02c-0.07,0.03-0.13,0.02-0.07,0.05
				c-0.04,0.01-0.07,0-0.09-0.02c0.05,0.03,0.02,0.08,0.11,0.09c-0.09,0.03,0.02,0.1-0.13,0.1c0.04,0.01,0.12,0.05,0.13,0.08
				c-0.18-0.05-0.01,0.1-0.21,0.09c0.17,0.04,0.07,0.13,0.17,0.2c-0.01,0-0.04-0.01-0.03-0.02c-0.01,0.04-0.06,0.05-0.1,0.07
				c0.14,0.01,0.17,0.06,0.25,0.1c-0.13,0.04-0.16-0.03-0.29-0.02c0.11,0,0.11,0.08,0.2,0.07c-0.08,0.03-0.04,0.03-0.15,0.05
				c0.17,0.01,0.03,0.03,0.13,0.06c-0.09,0.05-0.16,0.01-0.11,0.07c-0.21-0.08-0.01,0.27,0.29,0.42c0-0.02-0.03-0.02-0.06-0.03
				l0.04,0.03c-0.18,0-0.11-0.12-0.17,0.07l0.05-0.06c-0.25,0.11,0.18,0.42-0.23,2.09c0.09,0.02,0.16,0.25-0.06,0.38l0.11,0.06
				l-0.13,0.01c0.15,0.1-0.17-0.04,0.04,0.27c-0.04-0.01-0.06-0.03-0.08-0.05c0.16,0.07-0.15,0.03,0.06,0.09
				c-0.07,0.02-0.08,0.09-0.06,0.15c-0.12,0.02-0.08-0.04-0.08,0.28c0.04-0.03,0.17-0.01,0.17,0.02l-0.11,0
				c0.07,0.02,0.24,0.06-0.04,0.01l0.03,0.05c-0.03,0-0.05-0.01-0.09-0.02c0.07,0.03-0.12,0.07-0.01,0.11
				c-0.23-0.39,0.12,1.16-0.35,1.93c0.02,0.01,0.16,0.01,0,0.05c0.24-0.15-0.17,0.93-0.15,0.75c0.1,0.19-0.07,0.32,0,0.5
				c-0.02-0.02-0.06-0.02-0.05-0.04c-0.09,0.01-0.03,0.12,0.03,0.14c0.03-0.11-0.16,0.26-0.04,0.47c-0.15-0.04-0.15,0.07-0.09,0.13
				c-0.04-0.02-0.06,0.02-0.12-0.01c-0.01,0.04,0.01,0.09,0.09,0.1c-0.2,0.03,0.11,0.19-0.14,0.18c0.05,0.14,0.08,0.27,0.08,0.4
				c-0.17,0-0.08-0.05-0.16,0.41c-0.01,0-0.03-0.02-0.04-0.03c0,0.02-0.03,0.04,0.04,0.05l-0.01-0.02c0.11,0,0.19,0.13,0.24,0.15
				c-0.19-0.09-0.08,0.08-0.31-0.04c0.01,0.02,0.04,0.04,0.08,0.05c-0.15,0.1-0.03,0.01-0.21,0.45c0.02-0.02-0.05,0.08,0.09,0.06
				c-0.05-0.03,0.07,0.19-0.03,0.09l0,0.09c0.07-0.02,0.07-0.03,0.17,0.06c-0.09,0.03-0.05,0.08-0.23,0.09c0.1,0.05,0,0.11,0.1,0.18
				c-0.02-0.02-0.09-0.04-0.1-0.04l0.08,0.05c-0.13-0.03-0.08,0.06-0.05,0.05c-0.06-0.03-0.01,0.34-0.18,0.13
				c-0.01,0.11,0.08,0.25,0.02,0.34c0.22,0.03,0.01,0-0.02,0.1c-0.01-0.02-0.09-0.05-0.07-0.04c-0.05,0.05,0,0.15,0.02,0.26
				c0-0.04-0.22,0.09-0.04,0.16c-0.14,0.03-0.36,0.11-0.05,0.5c-0.03-0.06-0.12-0.04-0.17-0.04c0.02,0.01,0.05,0.02,0.07,0.03
				c-0.21-0.04,0.12,0.09-0.01,0.08c0.01,0.03,0.05,0.17-0.07,0.03c-0.02,0.13,0.03-0.03-0.18,0.15c0.28,0.11,0.02,0,0.16,0.23
				c-0.12-0.1,0-0.04-0.27-0.08c0.07,0.06-0.08,0.03,0.02,0.11c-0.03-0.01-0.02,0.03-0.09-0.01c0.19-0.25,0.09,0.53-0.13,0.53
				c0.02,0.05,0.06,0.1,0.08,0.15C433.45,218.4,433.43,218.35,433.4,218.33z M436.66,206.88c0.01,0.01-0.02,0.04-0.05-0.01
				C436.63,206.87,436.65,206.88,436.66,206.88z M436.04,207.04c-0.02-0.01-0.04,0.02-0.07,0.03
				C435.98,207.05,435.97,207,436.04,207.04z"
                />
                <path
                  className="st10"
                  d="M415.22,265.26c0.02-0.01,0.07,0.1,0.21,0.01c-0.24,0.06,0.22-0.04-0.12-0.18l0.14-0.04
				c-0.01,0.02-0.12-0.12,0.04-0.18c0-0.13-0.01-0.2-0.29-0.13l0.23-0.14l-0.1,0.03c0-0.01,0-0.03,0.04-0.04
				c-0.08-0.01-0.04,0.02-0.11,0.02c0.07-0.08-0.09-0.12,0.07-0.18c-0.08,0.01-0.04,0.07-0.12,0.03c0.21-0.04-0.14-0.1,0.14-0.15
				c-0.19-0.06,0.11-0.17-0.16-0.24c0.06,0.01-0.03-0.02,0.09-0.21c-0.13,0.02-0.12-0.05-0.16-0.09c0.08-0.02,0.11-0.02,0.15,0
				c-0.02-0.04-0.01-0.04-0.1-0.07l0.1-0.01c-0.02-0.05-0.14-0.09-0.09-0.17c-0.09,0.02-0.23,0.11-0.31,0.05
				c0.05-0.01,0.13-0.03,0.15-0.02c0.05-0.11-0.07,0.05,0.18-0.16c-0.04,0-0.21,0,0-0.08c-0.05,0-0.01-0.05-0.11-0.03
				c0-0.02,0.04-0.03,0.07-0.04c-0.06-0.01,0,0-0.15-0.07l0,0c-0.19,0.11,0.08-0.24-0.1-0.72c0.05,0.06,0.1-0.19,0.01-0.04
				c0.1-0.26-0.37-0.44-0.14-0.67l-0.04,0.01c0.03-0.04-0.01-0.12,0.06-0.13c-0.05-0.02-0.07,0.01-0.08,0.05
				c0.02-0.09-0.05-0.11-0.05-0.2c-0.07,0.06-0.36-0.11-0.01-0.19c-0.11-0.02-0.02-0.15-0.03-0.19l0.05,0.02
				c-0.03-0.01-0.04-0.03-0.05-0.04l-0.06,0.06c0-0.03-0.01-0.04,0.02-0.06c-0.06,0.01-0.11,0.09-0.2,0.09
				c0.02-0.05-0.09-0.05,0-0.12c0.04,0.13,0.36-0.22-0.04-0.11c0.06-0.03,0.08-0.09,0.06-0.09c0.02,0.03-0.07,0.08-0.13,0.08
				c0-0.08-0.07-0.03-0.08-0.06c-0.01,0.05,0.01,0.07-0.1,0.02c0.04-0.03,0.05,0.01,0.06-0.02c-0.11,0.03,0.02-0.15-0.08-0.15
				c0.01-0.05,0.08-0.08,0.09-0.12c-0.13,0.06,0.03-0.22-0.22-0.05l0.13,0.05c-0.21,0.05-0.12,0.17-0.36-0.27
				c-0.01,0.06,0.01,0.11-0.11,0.02c0.04-0.05,0.04-0.09,0.05-0.14c-0.03,0.04-0.08-0.05-0.16,0.06c-0.03-0.12,0.04-0.19-0.26-0.04
				c0.06-0.06,0.04-0.12,0.12-0.14c-0.11,0.01,0.03-0.01-0.32,0.13c-0.15-0.1,0.16-0.25-1.17-0.12c-0.02-0.19,0.01-0.14-0.15-0.12
				c0.13-0.16-0.12,0.27-0.37,0.13c-0.03,0.05-0.06,0.19-0.1,0.24c-0.03-0.1,0.02-0.2,0.04-0.28c-0.04,0.02-0.12,0.08-0.1,0.15
				c0.05-0.03,0.05-0.05-0.03,0.19c-0.16-0.22-0.2,0.08-0.39-0.01c-0.02,0.18-0.22,0.22-0.28,0.41c0.1,0.01,0.25,0.25-0.03,0.09
				c0.08-0.02,0.08,0.06,0.14,0.08c-0.06,0.04-0.18,0.07-0.35,0.07c0.08,0.01,0.1,0.06,0.06,0.1c0.07-0.11,0.06,0.23-0.06,0.12
				c0.03,0.07-0.02,0.25,0.15,0.31c-0.02,0.01-0.08,0.03-0.1,0c0.09,0.82,0.09,0.47,0.55,1.83c-0.02,0,0.16-0.11,0.14,0.05l0-0.01
				c-0.11,0.09-0.04,0.13-0.02,0.17l0.16-0.09c-0.17,0.25,0.26,0.25,0.1,0.43c0.06,0,0.08,0.04,0.13,0.04
				c-0.07,0.08-0.13,0.04,0.04,0.26l-0.07,0.02c0.16-0.05,0.07,0.27,0.52,0.41c-0.1,0.08,0.06,0.1,0.14,0.11
				c-0.22,0.12,0.06-0.01,0.42,0.5c-0.05-0.06-0.08-0.1-0.07-0.11c-0.11-0.01,0.01,0.42,0.26,0.28c0,0.01-0.01-0.01-0.05-0.01
				l0.01,0c-0.02-0.01-0.03-0.03-0.06-0.05c0.05-0.02,0.1-0.03,0.08,0.03c0.06-0.03,0.12-0.06,0.14-0.08
				c0.03,0.08-0.14,0.32,0.35,0.43c-0.11,0.4-0.11,0.1,0.33,0.4c0-0.02,0-0.03,0.01-0.05c0.07,0.09,0.04,0.3,0.61,0.47l-0.05,0.09
				c-0.02-0.27,0.22,0.35,0.77,0.27c-0.01,0-0.01,0-0.01,0c0.05-0.09,0.11-0.21,0.2-0.4l-0.11,0.05l0.03-0.08l0.05,0.03
				c0.02-0.08-0.04-0.04-0.08-0.01c0.02-0.04,0.03-0.07,0.03-0.09l-0.14,0.09c0.03-0.06,0.1-0.09,0.08-0.14
				c-0.07,0.04-0.06,0.13-0.13,0.14c0.02-0.08,0.06-0.12-0.01-0.09c0.02-0.04,0.04-0.05,0.06-0.06c-0.05,0.02-0.08-0.03-0.14,0.03
				c0.03-0.09-0.1-0.04-0.01-0.17c-0.03,0.02-0.12,0.06-0.14,0.05c0.15-0.12-0.08-0.07,0.05-0.22c-0.13,0.11-0.16-0.03-0.27,0
				c0.01-0.01,0.03-0.02,0.03-0.01c-0.02-0.04,0-0.08,0-0.12c-0.09,0.1-0.16,0.09-0.24,0.13c0.05-0.13,0.13-0.11,0.19-0.21
				c-0.07,0.09-0.13,0.03-0.18,0.11c0.02-0.09,0-0.05,0.06-0.15c-0.12,0.13-0.04,0-0.14,0.06c0.01-0.1,0.09-0.13,0.02-0.13
				c0.16-0.22-0.04,0.05-0.37-0.17c-0.01-0.01-0.08,0.18-0.16,0.09c0.01,0.01,0.03-0.01,0.06-0.02l-0.05,0.01
				c0.12-0.14,0.17,0,0.06-0.18l0.02,0.08c0.37-0.03-0.57-0.24-1.38-1.76c-0.09,0.04-0.29-0.1-0.21-0.36l-0.12,0.02l0.09-0.09
				c-0.23,0.01,0.19-0.01-0.2-0.2c0.04-0.02,0.06-0.01,0.1-0.01c-0.17,0.04,0.11-0.12-0.11-0.04c0.04-0.06,0-0.12-0.05-0.16
				c0.09-0.08,0.09-0.01-0.11-0.29c-0.01,0.05-0.13,0.1-0.16,0.08l0.1-0.06c-0.07,0.02-0.23,0.08,0.03-0.03l-0.06-0.03
				c0.03-0.02,0.05-0.01,0.09-0.03c-0.07,0.01,0.07-0.12-0.05-0.1c0.51,0.29-0.79-0.98-0.06-1.55c-0.01-0.01-0.14-0.09,0.01-0.03
				c-0.35-0.04,0.61-0.4,0.45-0.27c0.09-0.17,0.26-0.04,0.4-0.16c-0.01,0.03-0.01,0.06-0.02,0.06c0.03,0.09,0.11,0.01,0.11-0.06
				c-0.02,0.08,0.07,0.11,0.08,0.12c0.02-0.04,0.09,0.02,0.08-0.08c0.04,0.06,0.11-0.02,0.14,0.04c0.06-0.1,0.07,0.08,0.13-0.07
				c-0.05,0.15,0.05,0.15,0.11,0.09c-0.03,0.04,0.01,0.06-0.03,0.11c0.04,0.01,0.08,0,0.1-0.08c-0.02,0.2,0.2-0.07,0.12,0.16
				c0.14-0.02,0.26,0,0.37,0.05c-0.07,0.16-0.07,0.04,0.26,0.34c-0.01,0.01-0.03,0.01-0.05,0.01c0.02,0.01,0.01,0.05,0.06,0l-0.03,0
				c0.06-0.09,0.22-0.07,0.27-0.1c-0.19,0.09,0.02,0.11-0.23,0.22c0.02,0,0.05,0,0.09-0.03c-0.02,0.18,0,0.02,0.18,0.46
				c0-0.03,0.02,0.09,0.11-0.01c-0.06,0.01,0.19,0.1,0.04,0.09l0.06,0.07c0.04-0.06,0.03-0.07,0.17-0.06
				c-0.05,0.08,0.01,0.11-0.12,0.22c0.11-0.02,0.07,0.1,0.19,0.09c-0.03,0-0.09,0.02-0.11,0.03l0.09-0.01
				c-0.05,0.06,0.02,0.04,0,0.24c-0.02,0-0.02-0.07-0.08,0.02l0.07-0.01c-0.14,0.03,0.25,0.19,0.17,0.45
				c-0.02-0.01-0.1,0.01-0.08,0.01c-0.01,0.08,0.08,0.14,0.16,0.23c-0.02-0.04-0.14,0.2,0.05,0.17c-0.08,0.13-0.3,0.2,0.24,0.49
				c-0.06-0.04-0.13,0.03-0.16,0.06c0.02-0.01,0.05-0.01,0.08-0.02c-0.19,0.08,0.15,0.02,0.04,0.08c0.02,0.02,0.14,0.12-0.04,0.07
				c0.06,0.13,0.01-0.05-0.05,0.25c0.29-0.08,0.02-0.01,0.27,0.1c-0.16-0.01-0.03-0.03-0.27,0.11c0.09,0.01-0.05,0.08,0.09,0.09
				c-0.03,0.01,0.01,0.04-0.07,0.06c0.34-0.14-0.1,0.22,0.5,0.31c-0.02,0.06-0.22-0.02-0.08,0.14c-0.2,0.06,0.17,0.08-0.12,0.11
				c0.05,0.03,0.12,0.03,0.17,0.06C415.31,265.28,415.26,265.26,415.22,265.26z M412.78,264.89c0.01,0.03,0.06,0.05-0.03,0.08
				C412.77,264.96,412.77,264.93,412.78,264.89z M412.49,265.51c-0.02,0.01-0.03,0.02-0.04,0.03
				C412.43,265.54,412.43,265.49,412.49,265.51z"
                />
                <path
                  className="st10"
                  d="M371.09,237.24c-0.09,0.31,0.26-0.03,0.11,1.22c0.19,0.09-0.01,0.15,0.44,1.07
				c-0.12,0.1-0.11,0.01,0.04,0.38l-0.08,0c0.31,0.14,0.16-0.04,0.46,0.57c-0.02,0.01-0.06,0.03-0.08,0.01
				c0.04,0.07,0.07,0.3,0.22,0.17c-0.11,0.07,0.08,0.14,0.17,0.18l-0.09,0.04c0.02-0.02,0.1,0.01,0.33,0.16l-0.07,0.01
				c0.06,0.11,0.24-0.09,0.16,0.24c0.02,0-0.02-0.01,0.24,0.31c-0.07-0.08-0.12-0.15-0.11-0.15c-0.09,0.13-0.06,0.09,0.25,0.42
				c-0.01-0.01-0.03,0.04-0.06,0.01c0.01,0.02,0.03,0.05,0.06,0.1c0.07-0.07,0.1-0.02,0.12-0.11c0,0.01-0.02-0.01-0.06-0.02l0.01,0
				c-0.02-0.02-0.05-0.05-0.08-0.08c0.06-0.01,0.11-0.01,0.11,0.06c0.06-0.02,0.13-0.05,0.16-0.06c0.11,0.03,0.17,0.56,1.02,1.17
				c0-0.02-0.01-0.04,0.01-0.06c0.15,0.16,0.01,0.29,0.87,0.69l-0.05,0.09c0.29-0.17,0.27,0.5,0.98,0.55c0,0,0,0-0.01,0.01
				c0.14-0.07-0.01-0.01,0.13-0.5l0.07,0.05c0.01-0.09-0.05-0.05-0.11-0.04c0.02-0.04,0.03-0.07,0.02-0.09l-0.17,0.06
				c0.03-0.05,0.15-0.25-0.06-0.01c0.01-0.09,0.06-0.13-0.02-0.11c0.02-0.04,0.05-0.05,0.07-0.05c-0.07,0.01-0.11-0.06-0.18-0.01
				c0.02-0.1-0.14-0.08-0.04-0.2c-0.03,0.02-0.14,0.04-0.18,0.02c0.18-0.09-0.12-0.11,0.02-0.25c-0.15,0.09-0.21-0.08-0.35-0.07
				c0.01-0.01,0.04-0.02,0.04-0.01c-0.04-0.05-0.02-0.1-0.03-0.15c-0.09,0.1-0.18,0.07-0.27,0.1c0.03-0.15,0.14-0.1,0.2-0.21
				c-0.07,0.09-0.17,0.01-0.21,0.09c0.01-0.1-0.01-0.06,0.04-0.17c-0.12,0.12-0.06-0.01-0.16,0.04c0.04-0.08-0.12-0.13,0.09-0.21
				c-0.25,0.07-0.02-0.04-0.62-0.2c0-0.01,0.01-0.02,0.01-0.03c-0.02-0.01-0.07,0.21-0.17,0.08c0.02,0.02,0.04-0.01,0.07-0.02
				l-0.06,0c0.25,0,0.02-0.26,0.07-0.11c0.48-0.19-0.37-0.46-0.93-0.96c0.39,0.37,0.13-0.14-1.29-2.19l0.11-0.08
				c-0.22-0.06,0.16,0-0.17-0.35c0.04-0.01,0.07,0.02,0.1,0.04c-0.19-0.03,0.14-0.09-0.1-0.1c0.05-0.06,0.03-0.16-0.01-0.23l0.08,0
				c-0.01-0.13-0.08-0.27-0.1-0.43c-0.03,0.06-0.16,0.06-0.18,0.02l0.11-0.03c-0.07-0.01-0.25-0.02,0.03-0.02l-0.05-0.07
				c0.03-0.01,0.05,0.01,0.1,0.01c-0.07-0.02,0.11-0.12-0.02-0.16c0.04-0.11-0.07-0.52-0.17-0.81c0.09,0.01,0.1-0.1,0.15-0.04
				c0.01-0.13,0-0.17-0.02-0.34c-0.06,0.02-0.15-0.02,0.01-0.03c-0.08-0.23,0.37-0.32,0.26-0.51l0.01,0.01
				c-0.02-0.23,0.07-0.06,0.67-0.84c-0.47,0.01,0.48-0.47,1.37-0.56c-0.02,0.02-0.03,0.06-0.04,0.05c-0.01,0.03,0.22,0.13,0.68,0.27
				c-0.12,0.11,0,0.18,0.09,0.18c-0.05,0.02-0.01,0.07-0.08,0.09c0.04,0.04,0.09,0.07,0.15,0.01c-0.1,0.19,0.25,0.11,0.07,0.29
				c0.19,0.22,0.24,0.03,0.54,1.04c-0.01,0-0.03-0.01-0.05-0.02c0.01,0.03-0.01,0.07,0.06,0.05l-0.03-0.02
				c0.09-0.07,0.24,0.06,0.29,0.06c-0.21-0.01-0.02,0.16-0.29,0.13c0.02,0.02,0.06,0.03,0.1,0.02c-0.07,0.27-0.01-0.02,0.1,0.84
				l0.06-0.04c0.01,0.07,0.11,0.12,0.08,0.19l-0.06-0.05l0.04,0.13l0.04-0.08c0.11,0.12,0.2,0-0.03,0.33
				c0.11,0.02,0.05,0.17,0.17,0.2c-0.03-0.01-0.1-0.01-0.11,0l0.09,0.02c-0.06,0.08,0.02,0.08-0.05,0.34l-0.02-0.04l-0.05,0.05
				l0.07,0.01c-0.14-0.01,0.19,0.28,0.1,0.69c-0.01-0.02-0.1-0.02-0.08-0.02c-0.03,0.11,0.06,0.22,0.12,0.38
				c-0.02-0.1-0.21,0.19,0.02,0.25c-0.11,0.17-0.33,0.14,0.11,0.77c-0.05-0.07-0.13,0-0.18,0.03c0.02,0,0.05,0.01,0.08,0.01
				c-0.21,0.05,0.12,0.06,0.02,0.12c-0.01-0.01,0.12,0.23-0.06,0.08c0.02,0.19,0-0.02-0.13,0.31c0.3,0.02,0.02,0,0.22,0.26
				c-0.14-0.09-0.04-0.05-0.29,0.01c0.08,0.06-0.07,0.08,0.04,0.16c-0.02,0-0.03,0.02-0.05,0.03c0.05,0.01,0.08,0.18,0.1,0.82
				c-0.2-0.07,0.09,0.21-0.16,0.04c0.02,0.06,0.08,0.13,0.1,0.19c-0.06,0.01-0.08-0.05-0.12-0.08c0.02-0.01-0.01,0.16,0.16,0.18
				c-0.23-0.13,0.22,0.11,0.02-0.31l0.14,0.06l-0.11-0.1c0.03,0,0.31,0.07,0.17-0.13c0.03,0.02,0.1,0,0.08,0.04
				c0.06-0.14,0.12-0.27-0.18-0.36l0.27-0.05l-0.1-0.03c0.01-0.02,0.02-0.04,0.06-0.03c-0.07-0.07-0.05,0.01-0.11-0.04
				c0.1-0.06-0.03-0.21,0.15-0.21c-0.08-0.03-0.07,0.07-0.12-0.02c0.22,0.06-0.09-0.21,0.19-0.13c-0.2-0.11,0.22-0.13-0.06-0.42
				c0.06,0.02-0.03-0.03,0.15-0.26c-0.13-0.02-0.1-0.12-0.12-0.18c0.08,0.01,0.11,0.02,0.14,0.06c-0.01-0.01,0.03-0.04-0.07-0.14
				l0.1,0.02c0-0.09-0.11-0.17-0.04-0.28c-0.09-0.01-0.25,0.08-0.31-0.03c0.05,0,0.13-0.01,0.15,0.02c-0.01,0,0.07-0.17,0.1-0.05
				c0.01-0.04,0.05-0.1,0.11-0.12c-0.04-0.01-0.2-0.07,0.02-0.11c-0.05-0.02,0-0.08-0.1-0.08c0-0.02,0.05-0.03,0.08-0.04
				c-0.06-0.04,0,0-0.14-0.15v0c-0.14-0.11,0.14-0.36,0.01-0.91c0.06,0.06,0.05-0.03,0.05,0.06c0.02-0.09,0.08-0.11-0.02-0.2
				c0.09,0.02,0.11-0.07,0.08-0.11l-0.06,0.06c0.25-0.2-0.31-0.73-0.01-0.96l-0.04-0.01c0.04-0.04,0.02-0.15,0.09-0.14
				c-0.05-0.06-0.07-0.02-0.09,0.03c0.05-0.11-0.02-0.16,0-0.27c-0.06,0.05-0.36-0.3,0.04-0.24c-0.09-0.08,0.02-0.19,0.03-0.25
				l0.04,0.05c-0.03-0.03-0.03-0.05-0.03-0.08l-0.08,0.04c0.01-0.03,0-0.06,0.04-0.06c-0.06-0.02-0.13,0.05-0.22,0
				c0.03-0.05-0.07-0.11,0.04-0.15c0,0.17,0.42-0.05,0.01-0.16c0.06,0,0.11-0.05,0.09-0.07c0.03,0.03-0.11,0.08-0.21-0.11
				c-0.03,0.05-0.02,0.09-0.11-0.04c0.05-0.02,0.04,0.04,0.06,0.02c-0.23-0.05,0.36-0.51-0.1-0.52l0.11,0.15l-0.15-0.07
				c0.02,0.05,0.09,0.09,0.08,0.12c-0.08-0.03-0.06-0.03-0.12,0.01c-0.18-0.32,0.38-0.09-0.07-0.62c-0.03,0.06-0.03,0.12-0.12-0.06
				c0.06-0.03,0.07-0.07,0.1-0.11c-0.04,0.03-0.06-0.11-0.18-0.06c0.02-0.16,0.12-0.16-0.25-0.25c0.08-0.01,0.09-0.09,0.18-0.04
				c-0.12-0.07,0.04,0.01-0.41-0.13c0.05-0.15,0.01-0.29-0.52-0.58c0.01-0.02,0.02-0.03,0.03-0.02c-0.33-0.05,0.02-0.08-0.4-0.24
				l0.03-0.01c-0.06-0.1-0.2,0.04-0.31,0.02c0,0,0.01-0.02,0-0.03c-0.09,0.1-0.24,0.14-0.34,0.18c0.01-0.2,0.04-0.13-0.19-0.13
				c0.25-0.48-0.39,0.49-0.52,0.17c-0.04,0.05-0.11,0.21-0.17,0.28c-0.01-0.12,0.06-0.22,0.1-0.31c-0.05,0.02-0.17,0.1-0.15,0.18
				c0.06-0.04,0.08-0.06-0.08,0.22c-0.15-0.23-0.26,0.11-0.45,0.02c-0.07,0.21-0.31,0.25-0.43,0.45c0.08-0.03,0.19,0.33-0.07,0.1
				c0.09-0.01,0.06,0.09,0.11,0.12c-0.08,0.04-0.21,0.05-0.38,0.01c0.07,0.03,0.07,0.09,0.01,0.13l0.13-0.03
				c0.02,0.19-0.23,0.03-0.2,0.24l-0.04-0.08c0,0.09-0.14,0.29,0,0.43c-0.03,0-0.09,0-0.09-0.04c-0.12,0.1-0.08,0.24-0.15,0.83
				l-0.05-0.05c-0.1,0.18,0.09,0.31,0.12,0.47C371.2,237.01,371.38,237.06,371.09,237.24z M373.48,241.31
				c-0.01-0.01-0.02-0.01-0.03-0.03C373.47,241.29,373.45,241.28,373.48,241.31z M373.34,241.15c-0.01,0.01,0.1,0.07-0.02,0.09
				C373.34,241.24,373.33,241.19,373.34,241.15z"
                />
                <path
                  className="st10"
                  d="M382.66,239.38c-0.09,0-0.15-0.11,0.06-0.12c-0.04-0.04-0.14-0.12-0.18-0.19c0.11,0.02,0.17,0.1,0.23,0.15
				c-0.19-0.29-0.05,0.07-0.3-1.05c-0.01,0.08-0.32,0.08-0.1-0.03c-0.03,0.01-0.07,0.01-0.1,0.03c0-0.08,0.03-0.19,0.12-0.34
				c-0.05,0.06-0.1,0.05-0.12-0.01c0.06,0.13-0.24-0.07-0.09-0.12c-0.08-0.02-0.24-0.17-0.39-0.04c0-0.03,0.01-0.09,0.04-0.09
				c-0.08-0.12-0.26-0.11-0.8-0.04l0.03-0.07c-0.58,0.29-0.25,0.1-1.28,0.79c-0.01-0.02,0.14,0.12-0.01,0.15l0.01-0.01
				c-0.11-0.08-0.14-0.01-0.18,0.02l0.12,0.14c-0.29-0.09-0.18,0.32-0.41,0.21c0.01,0.06-0.02,0.09-0.01,0.14
				c-0.09-0.05-0.07-0.12-0.25,0.11l-0.04-0.07c0.08,0.15-0.24,0.13-0.3,0.62c-0.1-0.08-0.09,0.08-0.08,0.16
				c-0.16-0.2,0.02,0.07-0.42,0.53c0.05-0.06,0.09-0.1,0.09-0.1c-0.07-0.05-0.12-0.02-0.19-0.13c0.03,0.09,0.02,0.22-0.1,0.31
				c0.01,0.01,0.02,0.02,0.01,0.04c-0.03-0.02-0.02-0.05-0.09,0c0.08,0.06,0.04,0.08,0.13,0.1c-0.01,0,0.01-0.01,0-0.05l0.01,0.01
				c0.01-0.02,0.02-0.04,0.04-0.07c0.03,0.05,0.04,0.09-0.02,0.09c0.04,0.05,0.08,0.1,0.1,0.13c-0.08,0.05-0.34-0.08-0.37,0.45
				c-0.43-0.03-0.12-0.09-0.35,0.41c0.02,0,0.03-0.01,0.05,0c-0.08,0.1-0.29,0.08-0.38,0.71l-0.1-0.04
				c0.27-0.06-0.32,0.28-0.17,0.84c0-0.01,0-0.01,0-0.01c0.09,0.04,0.22,0.08,0.43,0.14l-0.06-0.1l0.08,0.02l-0.02,0.05
				c0.08,0,0.03-0.04,0-0.08c0.04,0.01,0.07,0.02,0.09,0.01l-0.11-0.13c0.06,0.02,0.1,0.08,0.15,0.06c-0.05-0.06-0.14-0.04-0.16-0.1
				c0.08,0,0.13,0.04,0.09-0.02c0.04,0.01,0.06,0.03,0.07,0.06c-0.03-0.05,0.02-0.09-0.05-0.14c0.1,0.01,0.03-0.11,0.17-0.04
				c-0.03-0.03-0.08-0.11-0.07-0.14c0.14,0.14,0.06-0.1,0.23,0.01c-0.13-0.12,0.01-0.17-0.04-0.28c0.01,0.01,0.03,0.03,0.02,0.03
				c0.03-0.03,0.08-0.02,0.12-0.02c-0.12-0.07-0.11-0.14-0.17-0.22c0.14,0.02,0.12,0.11,0.24,0.16c-0.1-0.05-0.05-0.13-0.14-0.16
				c0.09,0.01,0.05-0.01,0.16,0.03c-0.14-0.09-0.01-0.05-0.08-0.13c0.1-0.01,0.15,0.07,0.13-0.01c0.14,0.18,0.16-0.24,0-0.55
				c-0.01,0.01,0.02,0.03,0.03,0.05l-0.01-0.04c0.16,0.1,0.02,0.17,0.19,0.02l-0.08,0.03c0.19,0.15,0.16,0.12,1.8-2.23l0.11,0.07
				c-0.06-0.23,0.05,0.18,0.16-0.26c0.03,0.03,0.02,0.06,0.03,0.09c-0.08-0.16,0.14,0.07,0.01-0.11c0.07,0.02,0.13-0.03,0.15-0.09
				c0.11,0.07,0.03,0.08,0.27-0.19c-0.05,0-0.13-0.1-0.12-0.13l0.09,0.08c-0.04-0.06-0.14-0.2,0.04,0.02l0.01-0.07
				c0.03,0.02,0.03,0.04,0.05,0.08c-0.03-0.07,0.14,0.02,0.09-0.09c0.03,0.28,0.3-0.92,0.74-0.24l-0.01,0.01
				c0.02,0,0.24-0.08,0.05,0.07c0.17-0.06,0.09,0.11,0.22,0.11c-0.17,0.2,0.15,0.18,0.03,0.34c0.01,0,0.14-0.08,0.03,0.03
				c0.23-0.25,0.06,0.82,0.09,0.65c0.12,0.17-0.04,0.31,0.03,0.48c-0.02-0.02-0.06-0.03-0.05-0.04c-0.09,0.01-0.04,0.11,0.03,0.14
				c0.04-0.1-0.18,0.23-0.12,0.47c-0.14-0.07-0.16,0.03-0.12,0.11c-0.04-0.03-0.06,0-0.11-0.04c-0.02,0.04-0.02,0.09,0.06,0.12
				c-0.2-0.03,0.04,0.23-0.19,0.13c-0.06,0.21,0.08,0.19-0.45,0.72c-0.01-0.01-0.01-0.03-0.01-0.05c-0.01,0.02-0.05,0.02,0,0.07
				l0-0.03c0.1,0.05,0.09,0.22,0.12,0.26c-0.11-0.18-0.12,0.03-0.24-0.2c0,0.02,0.01,0.06,0.04,0.09c-0.02,0-0.05-0.02-0.07-0.07
				c-0.03,0.08,0.04,0.12-0.02,0.16c-0.02-0.03-0.02-0.05-0.04-0.08c-0.07,0.11-0.01,0.06-0.34,0.24c0.03,0-0.09,0.04,0.03,0.1
				c-0.01-0.05-0.09,0.2-0.09,0.05l-0.07,0.07c0.06,0.03,0.08,0.02,0.08,0.16c-0.09-0.04-0.11,0.03-0.25-0.09
				c0.03,0.11-0.09,0.08-0.07,0.2c0-0.03-0.03-0.09-0.04-0.1l0.02,0.09c-0.07-0.04-0.04,0.03-0.25,0.04c0-0.02,0.07-0.03-0.03-0.07
				l0.02,0.07c-0.05-0.14-0.16,0.27-0.44,0.24c0.01-0.02-0.02-0.1-0.02-0.08c-0.08,0-0.13,0.1-0.22,0.19
				c0.04-0.03-0.22-0.12-0.17,0.07c-0.15-0.06-0.2-0.28-0.5,0.27c0.04-0.07-0.03-0.13-0.07-0.16c0.01,0.02,0.01,0.05,0.02,0.08
				c-0.09-0.19-0.01,0.15-0.08,0.04c-0.02,0.02-0.13,0.14-0.07-0.04c-0.14,0.06,0.05,0.01-0.25-0.06c0.07,0.29,0.01,0.02-0.12,0.27
				c0.02-0.16,0.04-0.03-0.09-0.27c-0.02,0.09-0.08-0.05-0.1,0.08c-0.01-0.03-0.04,0.01-0.05-0.08c0.09,0.36-0.18-0.13-0.39,0.45
				c-0.05-0.04,0.06-0.21-0.13-0.1c-0.01-0.2-0.12,0.15-0.08-0.14c-0.03,0.04-0.06,0.11-0.1,0.15c-0.02-0.06,0.01-0.09,0.02-0.13
				c0.01,0.02-0.09,0.04-0.07,0.18c0.05-0.03,0.21-0.08,0.22,0.1c-0.02-0.01,0.15-0.1,0.18,0.08c0.13,0.02,0.22,0.02,0.19-0.27
				l0.11,0.25l-0.01-0.11c0.01,0,0.04,0,0.04,0.05c0.03-0.08-0.02-0.04-0.01-0.12c0.07,0.08,0.14-0.08,0.19,0.09
				c0-0.08-0.06-0.05-0.02-0.12c0.02,0.21,0.12-0.13,0.14,0.15c0.08-0.19,0.18,0.12,0.27-0.16c-0.01,0.06,0.02-0.03,0.23,0.08
				c-0.02-0.13,0.05-0.12,0.09-0.16c0.02,0.07,0.02,0.11,0,0.15c0.04-0.02,0.04-0.01,0.08-0.1l0.01,0.1
				c0.06-0.02,0.09-0.14,0.18-0.1c-0.02-0.09-0.13-0.22-0.07-0.3c0.02,0.05,0.04,0.12,0.03,0.15c0.01-0.02,0.12,0.02,0.06,0.09
				c0.03,0,0.09,0.03,0.11,0.08c-0.01-0.04-0.02-0.21,0.08-0.01c0-0.05,0.05-0.02,0.02-0.11c0.02-0.01,0.04,0.04,0.05,0.06
				c0.01-0.06,0,0,0.06-0.16l0,0c-0.13-0.17,0.26,0.05,0.74-0.2c-0.06,0.06,0.2,0.07,0.04,0.01c0.27,0.07,0.41-0.44,0.67-0.25
				l-0.01-0.04c0.05,0.02,0.12-0.03,0.14,0.04c0.02-0.06-0.02-0.07-0.06-0.07c0.1,0.01,0.1-0.06,0.19-0.07
				c-0.07-0.06,0.07-0.38,0.19-0.04c0.01-0.11,0.14-0.04,0.19-0.06l-0.01,0.05c0.01-0.03,0.02-0.04,0.04-0.05l-0.07-0.05
				c0.03,0,0.04-0.01,0.06,0.01c-0.01-0.06-0.1-0.1-0.11-0.18c0.05,0.01,0.04-0.09,0.12-0.02c-0.1,0.03,0.08,0.08,0.03,0.15
				c0.13-0.04,0.12,0.07,0.09-0.19c0.03,0.05,0.09,0.07,0.1,0.05c-0.03,0.02-0.08-0.07-0.09-0.12c0.08-0.01,0.02-0.07,0.05-0.09
				c-0.05-0.01-0.07,0.01-0.03-0.1c0.04,0.03,0,0.05,0.02,0.06c-0.04-0.11,0.16,0.01,0.15-0.09c0.05,0.01,0.09,0.08,0.12,0.08
				c-0.07-0.13,0.21,0.02,0.04-0.22l-0.04,0.13c-0.06-0.21-0.17-0.11,0.26-0.36c-0.06-0.01-0.11,0.01-0.02-0.11
				c0.05,0.04,0.09,0.04,0.13,0.05c-0.04-0.03,0.06-0.07-0.05-0.16c0.12-0.02,0.19,0.06,0.07-0.25c0.05,0.06,0.11,0.05,0.13,0.13
				c0-0.11,0.01,0.03-0.09-0.34c0.17,0.01,0.2-0.01,0.33-0.45c0.02,0.01,0.03,0.01,0.02,0.02c-0.09-0.29,0.27,0.11,0.15-0.48
				c0,0,0.02,0.01,0.03,0.01c-0.1-0.08-0.12-0.18-0.15-0.26c0.19,0.04,0.13,0.05,0.17-0.11
				C382.75,239.77,382.96,239.72,382.66,239.38z M378.63,240.69c0-0.01,0.01-0.01,0.01-0.03
				C378.64,240.67,378.65,240.66,378.63,240.69z M378.64,240.55c0.01,0.02,0.05,0.01,0.08,0.01
				C378.69,240.57,378.68,240.63,378.64,240.55z"
                />
                <path
                  className="st10"
                  d="M474.79,243.07c-0.02,0.06-0.06,0.07-0.08,0.13c-0.07-0.09-0.01-0.13-0.3-0.01l0-0.08
				c-0.01,0.19-0.28,0.01-0.6,0.44c-0.05-0.12-0.12,0.04-0.16,0.12c-0.06-0.23,0.01,0.02-0.67,0.32c0.08-0.04,0.14-0.06,0.14-0.05
				c0.03-0.13-0.43-0.08-0.39,0.21c-0.01,0,0.01-0.01,0.03-0.04l0,0.01c0.02-0.01,0.04-0.03,0.07-0.05c0,0.06-0.01,0.11-0.06,0.08
				c0.01,0.06,0.03,0.13,0.03,0.16c-0.04,0.1-0.51-0.02-1.13,0.5c0.02,0.01,0.03,0.01,0.05,0.02c-0.13,0.07-0.3-0.06-0.72,0.52
				l-0.07-0.08c0.26,0.06-0.43,0.13-0.59,0.72c0-0.01,0-0.01,0-0.01c0.04,0.05,0.01,0,0.06,0.14l0.03-0.05
				c0.06,0.06,0.14,0.13,0.24,0.22l0-0.12l0.07,0.05l-0.05,0.04c0.07,0.04,0.05-0.03,0.04-0.08c0.03,0.03,0.06,0.05,0.08,0.05
				l-0.04-0.17c0.05,0.05,0.05,0.12,0.11,0.12c-0.02-0.08-0.11-0.09-0.1-0.17c0.07,0.04,0.1,0.09,0.1,0.02
				c0.03,0.03,0.04,0.06,0.04,0.08c0-0.06,0.07-0.08,0.03-0.16c0.08,0.05,0.09-0.09,0.17,0.03c-0.01-0.04-0.02-0.14,0.01-0.16
				c0.06,0.19,0.11-0.06,0.21,0.11c-0.06-0.16,0.09-0.16,0.11-0.28c0,0.01,0.01,0.04,0,0.04c0.05-0.01,0.09,0.02,0.13,0.03
				c-0.07-0.12-0.03-0.18-0.04-0.27c0.12,0.08,0.06,0.15,0.15,0.25c-0.06-0.09,0.02-0.15-0.05-0.21c0.08,0.04,0.05,0.01,0.13,0.1
				c-0.09-0.15,0.02-0.05-0.01-0.15c0.1,0.04,0.1,0.13,0.13,0.05c0.17,0.21-0.04-0.05,0.32-0.34c0.01-0.01-0.16-0.13-0.04-0.18
				c-0.02,0.01,0,0.04,0,0.07l0.01-0.05c-0.03,0.21,0.21,0.11,0.08,0.1c0.12,0.21,0.09,0.18,2.86-1.33l0.07,0.11
				c0.06-0.23-0.05,0.19,0.28-0.17c0.01,0.04-0.01,0.06-0.02,0.1c0.01-0.18,0.1,0.13,0.07-0.1c0.06,0.05,0.13,0.03,0.18-0.02
				c0.07,0.11-0.01,0.08,0.33-0.04c-0.05-0.03-0.06-0.15-0.03-0.17l0.03,0.11c0-0.07-0.01-0.25,0.02,0.03l0.04-0.05
				c0.01,0.03,0,0.05,0,0.1c0.01-0.07,0.1,0.1,0.11-0.02c-0.49,0.37,1.33-0.27,1.17,0.96c0.02,0,0.16-0.03,0.02,0.04
				c0.3-0.28-0.04,0.45-0.33,1.17c-0.01-0.03-0.04-0.05-0.03-0.06c-0.08-0.03-0.09,0.09-0.04,0.14c0.08-0.08-0.28,0.14-0.35,0.4
				c-0.09-0.13-0.17-0.03-0.17,0.05c-0.02-0.05-0.06-0.02-0.08-0.08c-0.04,0.03-0.06,0.08-0.01,0.14c-0.18-0.11-0.08,0.23-0.25,0.05
				c-0.17,0.18-0.02,0.2-0.81,0.53c0-0.01,0-0.03,0.01-0.05c-0.02,0.01-0.06,0-0.03,0.06l0.01-0.03c0.07,0.08-0.01,0.24,0,0.29
				c-0.03-0.21-0.13-0.01-0.16-0.28c-0.01,0.02-0.02,0.06,0,0.1c-0.21-0.06-0.04,0-0.59,0.1c0.04,0.01-0.12,0.01-0.01,0.11
				c0.01-0.06-0.16,0.17-0.11,0.02l-0.1,0.05l0.07,0.03c-0.08,0.11,0.03,0.2-0.27-0.01c0,0.11-0.13,0.06-0.14,0.18
				c0.01-0.03-0.01-0.1-0.01-0.11l-0.01,0.09c-0.07-0.06-0.05,0.02-0.28-0.02l0.04-0.03l-0.04-0.05l0,0.07
				c-0.01-0.14-0.23,0.21-0.54,0.13c0.01-0.02,0.01-0.1,0.01-0.08c-0.09-0.02-0.17,0.07-0.29,0.14c0.05-0.02-0.2-0.16-0.2,0.04
				c-0.14-0.09-0.15-0.32-0.6,0.16c0.05-0.06-0.01-0.13-0.04-0.17c0.01,0.02,0,0.05,0,0.08c-0.05-0.2-0.05,0.14-0.1,0.03
				c-0.02,0.02-0.17,0.11-0.07-0.05c-0.16,0.03,0.05,0.02-0.26-0.1c0.01,0.3,0.01,0.02-0.19,0.24c0.06-0.15,0.04-0.03-0.04-0.28
				c-0.04,0.09-0.07-0.07-0.12,0.06c0-0.03-0.04,0-0.04-0.09c0.02,0.37-0.16-0.16-0.51,0.37c-0.05-0.05,0.11-0.2-0.12-0.12
				c0.03-0.2-0.16,0.12-0.06-0.16c-0.05,0.03-0.09,0.1-0.14,0.13c-0.01-0.06,0.03-0.09,0.04-0.13c0.01,0.02-0.13,0.02-0.12,0.18
				c0.06-0.25-0.06,0.22,0.24-0.03l-0.03,0.15c0-0.19,0.2,0,0.17,0.11c0.13,0.05,0.23,0.06,0.26-0.23l0.07,0.26l0.01-0.11
				c0.01,0,0.04,0.01,0.03,0.05c0.04-0.07-0.01-0.05,0.02-0.12c0.06,0.1,0.16-0.05,0.18,0.12c0.02-0.08-0.06-0.06,0-0.12
				c-0.02,0.22,0.16-0.11,0.12,0.18c0.12-0.17,0.17,0.15,0.33-0.1c-0.02,0.06,0.03-0.03,0.22,0.13c0.01-0.13,0.08-0.11,0.13-0.14
				c0,0.08-0.01,0.11-0.03,0.14c0.04-0.01,0.05,0,0.11-0.09l-0.01,0.1c0.07-0.01,0.13-0.12,0.22-0.06c0-0.09-0.09-0.24,0-0.31
				c0.01,0.05,0.02,0.13-0.01,0.15c0.01-0.01,0.13,0.05,0.05,0.1c0.04,0.01,0.08,0.05,0.1,0.1c0-0.04,0.04-0.21,0.09,0.01
				c0.01-0.05,0.06,0,0.05-0.11c0.02,0,0.03,0.05,0.04,0.07c0.02-0.06,0,0,0.11-0.15l0,0c0.04-0.19,0.14,0.06,0.73-0.04
				c-0.04,0.07,0.03,0.05-0.05,0.05c0.07,0.02,0.1,0.07,0.15-0.03c-0.08,0.05,0.19,0.11,0.04,0.02c0.25,0.14,0.57-0.32,0.77-0.06
				l0-0.04c0.04,0.03,0.13,0.01,0.12,0.08c0.04-0.05,0-0.07-0.04-0.08c0.1,0.04,0.13-0.03,0.22-0.01c-0.05-0.07,0.21-0.35,0.2,0.02
				c0.05-0.1,0.16,0.01,0.21,0.01l-0.03,0.05c0.02-0.03,0.04-0.03,0.06-0.04l-0.05-0.07c0.03,0.01,0.05,0,0.06,0.03
				c0.01-0.06-0.06-0.13-0.04-0.21c0.04,0.03,0.08-0.07,0.13,0.03c-0.14,0.01,0.11,0.4,0.13,0c0.01,0.05,0.04,0.08,0.06,0.08
				c-0.02-0.03-0.03-0.12,0.07-0.21c-0.05-0.03-0.07-0.01,0.01-0.11c0.02,0.04-0.03,0.05,0,0.06c0.01-0.12,0.15,0.06,0.19-0.03
				c0.05,0.03,0.05,0.11,0.09,0.12c-0.01-0.15,0.19,0.11,0.13-0.2l-0.1,0.11l0.03-0.15c-0.04,0.02-0.06,0.08-0.09,0.08
				c0.01-0.08,0.01-0.06-0.03-0.11c0.25-0.2,0.1,0.36,0.5-0.05c-0.05-0.03-0.11-0.03,0.03-0.11c0.03,0.06,0.06,0.07,0.1,0.1
				c-0.03-0.04,0.09-0.04,0.03-0.17c0.13,0.03,0.14,0.13,0.19-0.21c0.02,0.08,0.08,0.1,0.05,0.18c0.05-0.1-0.01,0.03,0.09-0.35
				c0.15,0.07,0.2,0.07,0.53-0.29c0.01,0.02,0.02,0.02,0.01,0.03c-0.01-0.27,0.3,0.13,0.38-0.4c0,0,0.01,0.02,0.03,0.02
				c-0.05-0.12-0.03-0.23-0.01-0.32c0.16,0.12,0.09,0.1,0.2-0.04c-0.09,0.26,0.3-0.22,0.06-0.66c0.09,0.06,0.13,0.16,0.17,0.24
				c0.02-0.05,0.03-0.16-0.04-0.19c-0.01,0.06,0,0.08-0.11-0.17c0.27,0.04,0.08-0.22,0.28-0.3c-0.11-0.16,0-0.34-0.07-0.53
				c-0.03,0.08-0.33-0.03-0.09-0.07c-0.04,0-0.07-0.02-0.11-0.01c0.02-0.08,0.08-0.19,0.21-0.3c-0.06,0.05-0.11,0.01-0.12-0.05
				c-0.08,0.12-0.13,0.18-0.13-0.15l0.08,0c-0.07-0.04-0.18-0.24-0.36-0.18c0.01-0.03,0.04-0.08,0.07-0.07
				c-0.27-0.18-0.95-0.82-2.29-0.46c0.01-0.02,0.07,0.18-0.1,0.13l0.01,0c-0.07-0.12-0.13-0.07-0.19-0.05l0.05,0.18
				C475.06,242.84,474.95,243.27,474.79,243.07z M472.75,244.04c-0.01-0.02-0.01-0.03-0.01-0.05
				C472.76,243.97,472.78,244,472.75,244.04z M473.24,244.42c0,0.02,0.04,0.03,0.07,0.04C473.28,244.47,473.23,244.52,473.24,244.42
				z"
                />
                <path
                  className="st10"
                  d="M472.18,252.22c0.02,0-0.18,0.05-0.12-0.09l0,0.01c0.13-0.05,0.08-0.11,0.07-0.16l-0.18,0.03
				c0.23-0.2-0.18-0.31,0.03-0.45c-0.05-0.02-0.06-0.06-0.12-0.07c0.09-0.06,0.14-0.01,0.03-0.27l0.07,0
				c-0.17,0.01,0-0.28-0.41-0.54c0.12-0.05-0.04-0.11-0.11-0.14c0.24-0.06-0.06-0.01-0.3-0.58c0.04,0.07,0.06,0.12,0.05,0.12
				c0.11,0.03,0.07-0.42-0.2-0.33c0-0.01,0.01,0.01,0.04,0.02l-0.01,0c0.01,0.02,0.03,0.04,0.04,0.06c-0.05,0.01-0.1,0.01-0.07-0.05
				c-0.06,0.02-0.13,0.04-0.16,0.05c-0.01-0.09,0.2-0.29-0.27-0.49c0.18-0.39,0.12-0.09-0.26-0.46c0,0.02-0.01,0.03-0.02,0.05
				c-0.06-0.1,0.01-0.31-0.55-0.56l0.07-0.08c-0.01,0.27-0.18-0.38-0.74-0.36c0.01,0,0.01,0,0.01,0c-0.06,0.08-0.13,0.2-0.24,0.38
				l0.11-0.04l-0.04,0.07l-0.04-0.03c-0.03,0.08,0.03,0.04,0.08,0.02c-0.02,0.04-0.04,0.06-0.04,0.08l0.15-0.08
				c-0.04,0.05-0.1,0.08-0.1,0.13c0.07-0.04,0.07-0.13,0.14-0.13c-0.03,0.08-0.07,0.11,0,0.09c-0.02,0.03-0.05,0.05-0.07,0.05
				c0.06-0.02,0.08,0.04,0.15-0.01c-0.04,0.09,0.09,0.06-0.01,0.17c0.03-0.02,0.12-0.05,0.15-0.03c-0.17,0.1,0.07,0.08-0.08,0.21
				c0.14-0.09,0.15,0.05,0.26,0.03c-0.01,0.01-0.04,0.02-0.03,0.01c0.02,0.04-0.01,0.08-0.02,0.12c0.1-0.09,0.17-0.07,0.25-0.1
				c-0.07,0.13-0.14,0.09-0.22,0.18c0.08-0.08,0.14-0.01,0.19-0.09c-0.04,0.08-0.01,0.05-0.08,0.14c0.13-0.11,0.04,0.01,0.14-0.04
				c-0.03,0.1-0.11,0.12-0.04,0.12c-0.19,0.2,0.05-0.04,0.34,0.22c0.01,0.01,0.11-0.17,0.17-0.07c-0.01-0.01-0.04,0.01-0.06,0.01
				l0.05,0c-0.14,0.12-0.16-0.03-0.09,0.17l0-0.08c-0.16,0.28,0.47,0.23,0.97,1.99c0.09-0.02,0.25,0.17,0.1,0.39l0.12,0.02
				l-0.12,0.06c0.22,0.06-0.18-0.05,0.14,0.25c-0.04,0.01-0.06-0.01-0.09-0.02c0.17,0.01-0.14,0.08,0.09,0.06
				c-0.06,0.05-0.04,0.12,0,0.16c-0.11,0.05-0.09-0.02,0.02,0.3c0.03-0.04,0.16-0.06,0.17-0.03l-0.11,0.03
				c0.07,0,0.25-0.01-0.03,0.02l0.05,0.04c-0.03,0.01-0.05,0-0.1,0c0.07,0.01-0.1,0.1,0.02,0.11c-0.41-0.42,0.48,1.17-0.34,1.57
				c0,0.01,0.11,0.12-0.01,0.03c0.31,0.13-0.64,0.21-0.46,0.14c-0.13,0.13-0.24-0.04-0.4,0.02c0.02-0.02,0.03-0.05,0.04-0.05
				c0.01-0.09-0.09-0.04-0.12,0.01c0.03-0.02,0.04-0.18-0.13-0.1c-0.01-0.07-0.11-0.02-0.1-0.09c-0.09,0.07-0.03-0.09-0.14,0.01
				c0.11-0.11,0.03-0.15-0.05-0.13c0.04-0.02,0.02-0.05,0.08-0.09c-0.02-0.03-0.07-0.03-0.13,0.02c0.11-0.17-0.21-0.03-0.03-0.2
				c-0.13-0.05-0.24-0.13-0.32-0.22c0.13-0.11,0.09,0-0.11-0.44c0.01,0,0.03,0,0.05,0.01c-0.01-0.02,0.01-0.05-0.06-0.03l0.02,0.01
				c-0.09,0.06-0.23-0.02-0.29-0.01c0.21-0.02,0.02-0.11,0.29-0.12c-0.02-0.01-0.05-0.02-0.1,0c0.08-0.17,0.01-0.03-0.03-0.53
				c-0.01,0.03,0-0.1-0.11-0.02c0.06,0.01-0.16-0.15-0.01-0.1l-0.04-0.09c-0.05,0.05-0.04,0.07-0.18,0.02
				c0.07-0.07,0.01-0.11,0.17-0.2c-0.11-0.01-0.05-0.11-0.17-0.13c0.03,0.01,0.1,0,0.11-0.01l-0.09-0.01
				c0.1,0.03-0.09-0.21,0.12-0.25l-0.08,0c0.15-0.01-0.21-0.22-0.11-0.47c0.02,0.01,0.1,0.01,0.08,0c0.02-0.08-0.06-0.15-0.13-0.25
				c0.02,0.04,0.17-0.18-0.03-0.17c0.12-0.1,0.28-0.27-0.16-0.52c0.06,0.05,0.13-0.01,0.17-0.03c-0.02,0.01-0.05,0-0.08,0
				c0.21-0.05-0.14-0.04-0.03-0.08c-0.02-0.02-0.11-0.15,0.05-0.06c-0.03-0.14-0.02,0.04,0.1-0.22c-0.3,0.02-0.02,0-0.24-0.16
				c0.15,0.05,0.02,0.04,0.28-0.04c-0.09-0.03,0.06-0.06-0.06-0.11c0.03,0,0-0.04,0.09-0.03c-0.36,0.03,0.15-0.14-0.37-0.44
				c0.04-0.05,0.2,0.1,0.12-0.1c0.2,0.03-0.12-0.14,0.16-0.05c-0.03-0.04-0.1-0.08-0.13-0.12c0.06-0.01,0.09,0.02,0.13,0.04
				c-0.02,0.01-0.02-0.1-0.16-0.1c0.02,0.05,0.03,0.21-0.14,0.19c0.02-0.02,0.07,0.16-0.11,0.15c-0.05,0.13-0.06,0.2,0.23,0.23
				l-0.26,0.06l0.11,0.01c0,0.01-0.01,0.03-0.05,0.03c0.07,0.04,0.05-0.01,0.12,0.01c-0.1,0.06,0.05,0.14-0.12,0.16
				c0.08,0.02,0.06-0.05,0.12,0c-0.21-0.01,0.11,0.14-0.18,0.11c0.17,0.11-0.15,0.15,0.11,0.28c-0.06-0.02,0.03,0.03-0.12,0.2
				c0.13,0,0.11,0.07,0.14,0.11c-0.08,0-0.11,0-0.14-0.03c0.01,0.04,0,0.04,0.09,0.09l-0.1,0c0.01,0.06,0.12,0.11,0.06,0.19
				c0.09,0,0.24-0.08,0.31-0.01c-0.05,0.01-0.13,0.02-0.15,0c-0.06,0.1,0.07-0.04-0.2,0.13c0.04,0,0.21,0.03-0.01,0.08
				c0.05,0.01,0,0.05,0.11,0.04c0,0.02-0.05,0.03-0.07,0.03c0.05,0.02,0,0,0.15,0.09l0,0c0.2-0.09-0.1,0.23,0.01,0.72
				c-0.04-0.07-0.12,0.17-0.02,0.03c-0.14,0.23,0.28,0.5,0.02,0.67l0.04,0c-0.04,0.03-0.02,0.11-0.09,0.1
				c0.05,0.03,0.07,0.01,0.09-0.03c-0.05,0.08,0.02,0.11-0.01,0.19c0.09-0.04,0.31,0.2-0.04,0.17c0.09,0.05-0.03,0.14-0.03,0.18
				l-0.04-0.03c0.02,0.02,0.03,0.04,0.03,0.05l0.08-0.04c-0.01,0.02-0.01,0.04-0.04,0.05c0.06,0.01,0.13-0.05,0.21-0.02
				c-0.03,0.04,0.06,0.07-0.04,0.11c0.02-0.1-0.11,0.04-0.14-0.04c-0.02,0.13-0.11,0.07,0.13,0.15c-0.06,0-0.11,0.05-0.09,0.06
				c-0.01-0.04,0.1-0.04,0.15-0.02c-0.03,0.07,0.05,0.05,0.05,0.08c0.03-0.04,0.02-0.07,0.11,0.02c-0.05,0.02-0.04-0.03-0.06-0.01
				c0.12,0.01-0.08,0.13,0.01,0.17c-0.03,0.04-0.11,0.04-0.13,0.07c0.14,0-0.12,0.18,0.18,0.13l-0.1-0.1
				c0.21,0.04,0.18-0.1,0.21,0.39c0.03-0.05,0.03-0.1,0.11,0.03c-0.06,0.03-0.07,0.06-0.1,0.1c0.04-0.03,0.04,0.08,0.16,0.02
				c-0.03,0.12-0.13,0.15,0.21,0.16c-0.08,0.02-0.09,0.09-0.17,0.07c0.1,0.04-0.03,0,0.35,0.03c-0.06,0.15-0.04,0.22,0.35,0.43
				c-0.01,0.02-0.02,0.03-0.02,0.02c0.27-0.06-0.07,0.3,0.45,0.22c0,0-0.01,0.02-0.01,0.03c0.09-0.09,0.19-0.11,0.27-0.13
				c-0.05,0.19-0.05,0.13,0.1,0.17c-0.18,0.11,0.2-0.22,0.4-0.02c0.04-0.04,0.11-0.16,0.16-0.21c0,0.11-0.07,0.19-0.11,0.26
				c0.04-0.01,0.14-0.05,0.14-0.12c-0.05,0.02-0.07,0.03,0.08-0.18c0.11,0.25,0.21-0.03,0.38,0.09c0.06-0.17,0.27-0.18,0.37-0.35
				c-0.1-0.02-0.19-0.29,0.05-0.08c-0.08,0-0.07-0.08-0.12-0.11c0.07-0.02,0.19-0.03,0.35,0.01c-0.07-0.02-0.08-0.08-0.04-0.11
				c-0.09,0.09-0.01-0.23,0.08-0.1c-0.01-0.07,0.08-0.23-0.08-0.33c0.03,0,0.09-0.01,0.1,0.02
				C472.33,253.3,472.23,253.63,472.18,252.22z M470.68,250.29c0-0.03-0.05-0.06,0.04-0.07C470.7,250.23,470.7,250.26,470.68,250.29
				z M471.1,249.74c0.02-0.01,0.03-0.01,0.05-0.02C471.16,249.73,471.15,249.77,471.1,249.74z"
                />
                <path
                  className="st10"
                  d="M352.93,259.49c0.03,0.01,0.15-0.01,0.01,0.06c0.31-0.46-0.29,1,0.93,1.49c-0.02,0.02-0.04,0.05-0.06,0.04
				c0.02,0,0.19,0.11,0.75,0.14c-0.04,0.15,0.1,0.15,0.16,0.08c-0.02,0.04,0.03,0.06,0,0.12c0.05,0.01,0.1-0.02,0.11-0.1
				c0.07,0.2,0.2-0.13,0.23,0.11c0.25-0.07-0.06-0.11,0.92-0.15c0,0.01-0.01,0.03-0.02,0.04c0.02-0.01,0.05,0.02,0.04-0.05
				l-0.02,0.02c-0.04-0.1,0.07-0.22,0.06-0.28c-0.02,0.21,0.11,0.04,0.08,0.31c0.01-0.01,0.03-0.05,0.02-0.1
				c0.17,0.1,0.03,0.02,0.55,0c-0.03-0.01,0.11,0,0.02-0.11c-0.01,0.06,0.15-0.16,0.1-0.01l0.09-0.04
				c-0.05-0.05-0.07-0.04-0.03-0.18c0.07,0.07,0.1,0.01,0.2,0.16c0-0.11,0.11-0.06,0.11-0.18c0,0.03,0.01,0.1,0.02,0.11l0-0.09
				c-0.02,0.1,0.21-0.11,0.26,0.09l-0.01-0.08c0.03,0.14,0.18-0.23,0.45-0.19c-0.01,0.02,0.02,0.1,0.01,0.08
				c0.08,0,0.13-0.09,0.22-0.19c-0.04,0.03,0.22,0.11,0.16-0.08c0.13,0.06,0.26,0.25,0.43-0.32c-0.02,0.07,0.06,0.12,0.09,0.15
				c-0.01-0.02-0.02-0.05-0.03-0.07c0.12,0.17-0.02-0.15,0.07-0.06c0.01-0.03,0.09-0.16,0.07,0.03c0.11-0.09-0.04,0,0.25,0
				c-0.14-0.26-0.01-0.01,0.04-0.29c0.03,0.16-0.03,0.04,0.16,0.24c-0.01-0.09,0.09,0.03,0.07-0.11c0.02,0.03,0.03-0.02,0.07,0.06
				c-0.2-0.3,0.22,0.06,0.19-0.54c0.06,0.01,0.03,0.22,0.15,0.05c0.09,0.18,0.04-0.18,0.13,0.1c0.02-0.05,0.01-0.12,0.03-0.17
				c0.04,0.04,0.03,0.09,0.04,0.13c-0.01-0.01,0.06-0.07-0.01-0.19c-0.03,0.05-0.15,0.15-0.23-0.01c0.02,0-0.09,0.14-0.18-0.01
				c-0.13,0.03-0.19,0.05-0.07,0.31l-0.18-0.2l0.05,0.1c-0.01,0-0.03,0.01-0.05-0.03c0.01,0.08,0.03,0.04,0.05,0.11
				c-0.09-0.06-0.1,0.11-0.19-0.03c0.03,0.08,0.07,0.03,0.06,0.11c-0.09-0.2-0.07,0.15-0.17-0.11c-0.01,0.2-0.19-0.08-0.19,0.21
				c-0.01-0.06-0.02,0.04-0.22-0.04c0.05,0.12-0.02,0.12-0.05,0.17c-0.03-0.07-0.04-0.1-0.03-0.14c-0.03,0.03-0.04,0.01-0.05,0.11
				l-0.03-0.09c-0.05,0.03-0.05,0.15-0.14,0.12c0.03,0.08,0.15,0.21,0.11,0.29c-0.02-0.04-0.06-0.12-0.05-0.14
				c-0.11-0.03,0.06,0.05-0.18-0.15c0.01,0.03,0.04,0.21-0.07,0.01c0.01,0.05-0.04,0.02-0.01,0.11c-0.02,0.01-0.04-0.04-0.05-0.06
				c0,0.06,0,0-0.04,0.16l0,0c0.13,0.16-0.24-0.04-0.67,0.13c0.05-0.05-0.17-0.1-0.03-0.01c-0.32-0.11-0.48,0.38-0.61,0.05l0,0.04
				c-0.03-0.04-0.1-0.02-0.1-0.09c-0.03,0.05,0,0.07,0.03,0.09c-0.08-0.05-0.1,0.02-0.17-0.01c0.04,0.09-0.2,0.29-0.15-0.05
				c-0.05,0.09-0.12-0.04-0.15-0.05l0.03-0.04c-0.02,0.02-0.03,0.02-0.05,0.03l0.03,0.08c-0.02-0.01-0.04-0.01-0.04-0.04
				c-0.01,0.06,0.03,0.14,0,0.21c-0.03-0.04-0.07,0.05-0.09-0.05c0.08,0.03-0.02-0.11,0.04-0.13c-0.11-0.04-0.05-0.12-0.14,0.11
				c0-0.06-0.03-0.12-0.04-0.1c0.03,0,0.02,0.1,0,0.15c-0.05-0.04-0.05,0.04-0.08,0.04c0.03,0.04,0.05,0.03-0.03,0.1
				c-0.01-0.05,0.03-0.04,0.02-0.06c-0.03,0.11-0.1-0.1-0.15-0.03c-0.03-0.04-0.01-0.12-0.03-0.14c-0.03,0.13-0.11-0.16-0.15,0.13
				l0.1-0.06c0.02,0.17-0.26,0.21-0.44-0.02c0,0.05-0.07-0.01-0.09,0.13c0.03-0.3-0.29,0.24-0.16-0.11
				c-0.08,0.05,0.02-0.01-0.23,0.22c-0.03-0.22-0.22-0.03-0.23-0.23c-0.33,0.01-0.12,0.1-0.68-0.22c0.08-0.05,0.05-0.01,0.11-0.08
				c-0.3,0.22-0.17-0.13-0.16-0.29c-0.04-0.01-0.16-0.05-0.19-0.1c0.1,0.01,0.17,0.05,0.24,0.06c-0.12-0.12,0.07-0.17,0.1-0.66
				c-0.08,0.01-0.1-0.05-0.17-0.06c0.04-0.03,0.16-0.04,0.36,0.01c-0.07-0.02-0.09-0.06-0.05-0.08c-0.06,0.05-0.03-0.17,0.06-0.05
				c-0.02-0.05,0.07-0.12-0.05-0.25c0.25,0.45,0.07-0.59,1.19-0.96l-0.01,0c0.08,0.12,0.11,0.06,0.15,0.05l-0.07-0.17
				c0.2,0.2,0.25-0.2,0.39,0.01c0.01-0.05,0.04-0.06,0.06-0.12c0.04,0.07,0,0.14,0.23,0.06c0.01,0.31,0.22-0.05,0.5-0.2
				c0.02,0.13,0.1,0,0.14-0.06c0.02,0.14-0.08,0.13,0.34-0.03c0.01-0.07,0.11-0.06,0.24,0c-0.07,0-0.12-0.01-0.12-0.01
				c-0.06,0.06,0.29,0.28,0.35-0.01c0.01,0.01-0.01,0-0.04,0.02l0-0.01c-0.02,0-0.04,0-0.07,0c0.02-0.05,0.05-0.09,0.07-0.03
				c0.02-0.06,0.03-0.13,0.04-0.16c0.08,0.04,0.09,0.32,0.52,0.09c0.14,0.37-0.03,0.15,0.47,0.13c-0.01-0.02-0.01-0.03-0.01-0.05
				c0.11,0.03,0.16,0.23,0.72,0.11l-0.01,0.11c-0.1-0.25,0.3,0.23,0.78-0.02c-0.01,0-0.01,0-0.01,0.01c0.03-0.1,0.05-0.23,0.09-0.44
				l-0.09,0.08l0-0.08l0.06,0.01c-0.01-0.08-0.05-0.02-0.08,0.01c0.01-0.04,0.01-0.07,0-0.09l-0.11,0.13
				c0.01-0.06,0.07-0.11,0.03-0.16c-0.05,0.06-0.01,0.15-0.08,0.18c-0.01-0.08,0.02-0.14-0.04-0.09c0.01-0.04,0.02-0.07,0.04-0.08
				c-0.05,0.04-0.09-0.01-0.13,0.07c-0.01-0.1-0.11-0.01-0.07-0.15c-0.1,0.07-0.19,0.15,0-0.17c-0.03,0.04-0.07,0.11-0.1,0.19
				c0.03-0.04-0.13,0.01-0.02-0.13c-0.01,0-0.03,0-0.05-0.01c0,0,0,0,0-0.01c-0.07,0.01-0.01,0.01-0.06-0.17
				c0.01,0.02-0.15,0.01-0.08-0.15c-0.09-0.09-0.14-0.11-0.27,0.14l0.05-0.26l-0.05,0.1c-0.01-0.01-0.02-0.02,0-0.06
				c-0.06,0.06-0.01,0.05-0.06,0.1c-0.01-0.11-0.13-0.01-0.08-0.17c-0.05,0.07,0.02,0.07-0.05,0.11c0.1-0.19-0.15,0.04-0.01-0.2
				c-0.16,0.11-0.05-0.18-0.26-0.02c0.04-0.04-0.04,0-0.08-0.19c-0.07,0.11-0.1,0.06-0.15,0.07c0.04-0.07,0.06-0.09,0.09-0.11
				c-0.04-0.01-0.03-0.02-0.11,0.03l0.05-0.08c-0.05-0.02-0.14,0.06-0.17-0.03c-0.05,0.08-0.07,0.25-0.16,0.26
				c0.02-0.04,0.06-0.12,0.08-0.13c-0.04-0.11-0.01,0.07,0.01-0.23c-0.02,0.03-0.14,0.17-0.05-0.05c-0.03,0.04-0.04-0.02-0.09,0.07
				c-0.01-0.01,0.01-0.05,0.02-0.08c-0.04,0.03-0.01-0.01-0.14,0.07l0,0c-0.05,0.2-0.11-0.21-0.47-0.39c0.07,0-0.02-0.19-0.01-0.03
				c-0.11-0.3-0.56-0.08-0.39-0.4l-0.03,0.03c0.01-0.05-0.04-0.08,0.01-0.13c-0.05,0.01-0.05,0.04-0.05,0.08
				c-0.01-0.08-0.07-0.06-0.09-0.13c0.16-0.02-0.33,0.04-0.05-0.14c-0.09,0.03-0.04-0.11-0.06-0.14l0.04-0.01
				c-0.03,0-0.04-0.01-0.05-0.02l-0.04,0.07c0-0.02-0.01-0.03,0.01-0.06c-0.05,0.03-0.08,0.11-0.16,0.14
				c0.01-0.05-0.08-0.01-0.01-0.1c0.03,0.08,0.07-0.09,0.13-0.06c-0.04-0.11,0.06-0.11-0.16-0.03c0.05-0.04,0.07-0.1,0.05-0.1
				c0.02,0.02-0.06,0.09-0.11,0.1c0-0.07-0.06-0.01-0.07-0.03c-0.01,0.05,0.01,0.06-0.09,0.04c0.03-0.04,0.04,0,0.05-0.03
				c-0.1,0.05,0.02-0.14-0.07-0.12c0.01-0.05,0.08-0.08,0.09-0.12c-0.12,0.06,0.05-0.18-0.19-0.01l0.11,0.03
				c-0.11,0.13-0.32-0.05-0.26-0.31c-0.04,0.03-0.04-0.05-0.15,0.03c0.24-0.18-0.36-0.02-0.02-0.17c-0.09-0.02,0.02,0-0.3,0
				c0.15-0.17-0.11-0.16,0.03-0.3c-0.22-0.21-0.16-0.02-0.25-0.57c0.09,0.03,0.04,0.03,0.13,0.03c-0.36-0.1,0-0.19,0.12-0.28
				c-0.02-0.03-0.07-0.14-0.06-0.2c0.06,0.09,0.08,0.15,0.12,0.21c-0.07-0.27,0.05,0.15,0.4-0.36c0,0.05-0.02,0.14,0.01,0.19
				c-0.02-0.13,0-0.42,0.06,0.16c0-0.08,0.02-0.11,0.04-0.08c-0.05-0.03,0.13-0.08,0.05,0.04c0.04-0.03,0.11,0.03,0.2-0.12
				c-0.33,0.29,0.52-0.04,1.31,0.69l-0.01-0.01c-0.06,0.13,0,0.12,0.04,0.15l0.11-0.15c-0.06,0.28,0.29,0.08,0.2,0.31
				c0.05-0.02,0.07,0,0.12-0.02c-0.02,0.07-0.12,0.09,0.08,0.21c-0.24,0.2,0.17,0.13,0.45,0.23c-0.09,0.1,0.06,0.07,0.13,0.06
				c-0.09,0.1-0.15,0.04,0.23,0.21c0.06-0.04,0.12,0.03,0.15,0.16c-0.04-0.05-0.07-0.08-0.06-0.09c-0.06-0.01-0.02,0.39,0.22,0.22
				c0,0.01,0-0.01-0.04-0.01l0.01,0c-0.01-0.01-0.03-0.02-0.04-0.04c0.05-0.02,0.09-0.03,0.07,0.02c0.06-0.03,0.11-0.06,0.14-0.08
				c0.02,0.08-0.18,0.28,0.26,0.39c-0.18,0.34-0.13,0.09,0.19,0.39c0.01-0.02,0.01-0.03,0.03-0.04c0.04,0.08-0.06,0.28,0.37,0.53
				l-0.09,0.06c0.15-0.26,0,0.36,0.5,0.49c-0.01,0-0.01,0-0.01,0c0,0,0.04-0.02,0.39-0.23l-0.12-0.01l0.06-0.05l0.03,0.04
				c0.06-0.06-0.01-0.05-0.06-0.05c0.04-0.02,0.06-0.04,0.07-0.06l-0.17,0.01c0.05-0.03,0.13-0.03,0.14-0.08
				c-0.08,0.01-0.12,0.09-0.18,0.06c0.05-0.06,0.11-0.08,0.04-0.08c0.04-0.02,0.06-0.03,0.09-0.02c-0.06-0.01-0.05-0.06-0.14-0.04
				c0.07-0.07-0.07-0.08,0.07-0.15c-0.04,0.01-0.13,0.01-0.15-0.01c0.02-0.01,0.01,0,0.04,0l-0.01-0.01
				c0.1-0.04-0.05-0.09,0.11-0.17c-0.16,0.05-0.13-0.08-0.25-0.1c0.01,0,0.04-0.01,0.04,0c-0.01-0.04,0.03-0.07,0.04-0.11
				c-0.12,0.07-0.18,0.03-0.26,0.05c0.09-0.11,0.15-0.06,0.25-0.14c-0.09,0.06-0.14-0.01-0.21,0.05c0.05-0.07,0.02-0.05,0.11-0.12
				c-0.15,0.09-0.04-0.01-0.15,0.02c0.04-0.09,0.13-0.1,0.05-0.12c0.19-0.14-0.04-0.02-0.33-0.21c0.01-0.01,0.01-0.02,0.02-0.02
				c-0.01-0.01-0.11,0.16-0.17,0.06c0.01,0.01,0.04-0.01,0.06-0.02l-0.04,0c0.14-0.11,0.15,0.03,0.09-0.16l0,0.08
				c0.09-0.2-0.35-0.21-1.39-1.58c-0.07,0.06-0.29,0.01-0.3-0.26l-0.11,0.06l0.05-0.12c-0.16,0.08,0.12-0.13-0.26-0.1
				c0.03-0.03,0.05-0.03,0.08-0.05c-0.14,0.11,0.04-0.15-0.11,0.02c0.01-0.07-0.05-0.11-0.11-0.11c0.04-0.11,0.07-0.06-0.23-0.19
				c0.02,0.05-0.06,0.16-0.09,0.15l0.05-0.1c-0.05,0.05-0.15,0.2,0.01-0.04l-0.06,0.01c0.01-0.03,0.03-0.04,0.06-0.08
				c-0.05,0.05-0.02-0.14-0.1-0.05c0.37,0.07-1.18-0.7-2.24-0.49c0.01,0.03,0.08,0.12-0.06,0.04c0.49-0.02-0.94,0.35-0.58,1.57
				c-0.03-0.01-0.06,0-0.07-0.02c0,0.01,0.04,0.22,0.37,0.61c-0.14,0.08-0.05,0.17,0.04,0.16c-0.05,0.01-0.02,0.06-0.08,0.08
				c0.03,0.04,0.08,0.06,0.14,0c-0.1,0.19,0.22,0.04,0.06,0.23c0.15,0.05,0.28,0.1,0.38,0.16c-0.08,0.14-0.09,0.06,0.28,0.34
				c-0.01,0.01-0.03,0.01-0.04,0.02c0.02,0.01,0.02,0.05,0.06-0.01l-0.03,0c0.05-0.1,0.2-0.11,0.24-0.14
				c-0.16,0.13,0.03,0.1-0.18,0.26c0.02,0,0.05-0.02,0.08-0.05c0.02,0.17,0,0.03,0.32,0.38c-0.01-0.03,0.06,0.08,0.09-0.06
				c-0.05,0.03,0.21,0,0.07,0.06l0.08,0.03c0.01-0.07-0.01-0.08,0.12-0.14c-0.01,0.09,0.06,0.08,0,0.25
				c0.08-0.07,0.11,0.04,0.2-0.04c-0.02,0.02-0.06,0.07-0.07,0.08l0.07-0.06c-0.07,0.12,0.03,0.1,0.03,0.07
				c-0.04,0.05,0.31,0.1,0.07,0.21c0.1,0.04,0.26-0.01,0.33,0.06c0.08-0.2,0-0.01,0.09,0.05c-0.02,0.01-0.07,0.08-0.05,0.06
				c0.04,0.06,0.14,0.03,0.26,0.03c-0.04-0.01,0.04,0.24,0.14,0.07c0,0.14,0.06,0.38,0.47,0.12c-0.06,0.03-0.06,0.12-0.06,0.17
				c0.01-0.02,0.03-0.05,0.04-0.07c-0.07,0.2,0.1-0.11,0.08,0.01c0.03-0.01,0.16-0.03,0.02,0.07c0.12,0.03-0.02-0.03,0.13,0.19
				c0.12-0.28,0-0.02,0.23-0.15c-0.19,0.09,0.19,0.19-0.37,0.39c-0.02-0.06,0-0.05-0.08,0.13c0.02,0,0.02-0.03,0.03-0.05l-0.03,0.03
				c0.02-0.18,0.14-0.1-0.06-0.18c0.57,0,0.07-0.25-2.75,0.26l-0.07-0.11c-0.05,0.24,0.03-0.2-0.26,0.17c-0.01-0.04,0-0.07,0.01-0.1
				c0.01,0.18-0.11-0.12-0.06,0.11c-0.06-0.05-0.14-0.01-0.18,0.04c-0.09-0.1-0.01-0.09-0.33,0.13c0.06,0.01,0.11,0.12,0.09,0.15
				l-0.07-0.09c0.02,0.07,0.1,0.23-0.03-0.02l-0.03,0.06c-0.02-0.03-0.02-0.05-0.04-0.09c0.02,0.07-0.15-0.05-0.11,0.08
				C354.56,257.09,353.24,258.45,352.93,259.49z M357.39,257.53c0,0.02-0.01,0.04-0.01,0.05
				C357.38,257.6,357.34,257.57,357.39,257.53z M359.49,254.1c0.01,0.03,0.05,0.05-0.03,0.07
				C359.48,254.17,359.48,254.14,359.49,254.1z M359.18,254.7c-0.02,0.01-0.03,0.02-0.04,0.03
				C359.12,254.73,359.12,254.69,359.18,254.7z M359.32,256.94c-0.02,0.02-0.05,0.04-0.07,0.04
				C359.28,256.97,359.3,256.96,359.32,256.94z M359.17,256.71c0.05,0.03-0.17,0.14,0.04,0.15c-0.16,0.16,0.17,0.03-0.11,0.16
				c0.01-0.01,0.02-0.04,0.03-0.03c-0.04-0.02-0.04-0.07-0.06-0.11c-0.01,0.04,0.01,0-0.05,0.08c0,0,0,0,0,0
				c-0.04,0.06-0.08,0.09-0.11,0.15C358.95,257,358.81,256.97,359.17,256.71z M358.98,256.83c-0.02,0.1-0.11,0.09-0.11,0.18
				c-0.02-0.09-0.02-0.05-0.02-0.16c-0.04,0.17-0.04,0.02-0.09,0.12c-0.04-0.09,0.01-0.16-0.06-0.12
				C358.75,256.73,358.69,256.78,358.98,256.83z M358.68,256.8c-0.01,0.03,0.03,0.02-0.01,0.09c0.01-0.04,0.03-0.07,0.04-0.09
				C358.71,256.89,358.62,256.82,358.68,256.8z M357.3,256.89c-0.01,0-0.01,0-0.03,0.01C357.28,256.89,357.27,256.9,357.3,256.89z
				 M357.18,256.97c0.01-0.02-0.02-0.04-0.04-0.07C357.17,256.91,357.22,256.89,357.18,256.97z"
                />
                <path
                  className="st10"
                  d="M377.88,422.29c-0.35-0.74,0.06,0-0.23-2.13c-0.15,0.03-0.22,0.09-0.17-0.26c0.68-0.32,0.17,0.07,0.14-2.56
				l0.13,0.11c-0.22-1.11,0.06-1.19-0.06-6.45l0.03,0.09c0.04-0.34-0.21-0.39-0.11-0.71l0.02,0.06c0.04-0.19,0.15-0.12,0.08-6.14
				c0.04,0.02,0.06,0.13,0.09,0.22c-0.11-0.37,0.11-0.07,0.01-0.4c-0.02,0.05-0.06-0.02-0.09-0.03c0.07-0.17,0.07-0.55,0.04-0.83
				l0.07,0.05c0.02-0.49-0.03-1.03-0.03-1.58c0.1-1.2,0.03,0.17-0.05-7.79l0.01,0.03c-0.07-1.6-0.06-0.01,0.1-4.09l-0.06,0.03
				c-0.29-1.47-0.06,0.04-0.07-4.67l0.12,0.16c-0.14-0.89,0-1.83-0.11-2.7c0.03,0.05,0.06,0.05,0.06,0.14
				c0.08-0.18,0-0.68-0.06-0.69c0.06-0.01,0.08-0.85-0.07-2.55c0.16,0.02,0.13-0.58,0.05-0.78c0.05,0.07,0.05-0.18,0.12-0.1
				c0-0.24-0.03-0.45-0.11-0.41c0.16-0.78,0.11-0.57-0.09-3.11c0.06,0.11,0.09,0.19,0.11,0.25c-0.03-0.43,0.02-0.53-0.07-2.68
				c0.01,0,0.03,0.06,0.04,0.11c-0.07-0.29,0.02,0.04-0.3-0.57c0.21,0.18,0.05-0.53,0.31-0.24c-0.01-0.07-0.05-0.16-0.1-0.14
				c0.01-0.1,0.04-0.14,0.1-0.03c-0.04-0.38-0.12-0.24-0.11-0.58c0.03,0,0.05,0.07,0.09,0.11c-0.07-0.17-0.14-0.35-0.11-0.64
				c0.02,0.02,0.05-0.02,0.05,0.07c-0.01-0.5-0.18-0.85-0.12-1.39c0.08,0.09,0.19,0.1,0.14-0.51l-0.06,0.12
				c0-0.26-0.09-0.48-0.05-0.7l0.05,0.19l-0.02-0.48l-0.05,0.27c-0.07-0.26-0.17,0.03-0.13-0.31c0.01,0.07,0-0.26,0.08-0.39
				c0,0,0,0,0,0c-0.12-0.57,0.24-0.12-0.03-1.2c0.17,0.13,0.16-0.37,0.02-0.49c0.01,0.03,0.02,0.02,0.03,0.09
				c-0.01-0.46,0.01-0.65,0.04-0.8l0.01,0.12c0.04-0.57,0.02,0.05-0.05-2.04l-0.01,0.1c-0.15-0.62,0.25-0.37-0.02-1.94l0.05,0.07
				c-0.06-1.03,0.08-0.82,0.02-2.99c0.05,0.17,0.06,0.26,0-0.43c-0.08,0.04-0.05,0.07-0.11,0.19c-0.05-0.07-0.09-0.23-0.09-0.36
				c0.05,0.25,0.13,0,0.17-0.11c-0.02,0.02-0.05-0.02-0.08-0.01c0.14-0.29-0.16-0.05-0.07-0.44c0.07,0.02,0.16,0.06,0.01-0.9
				c0.03,0.1,0.03,0.23,0.05,0.33c0.11-0.22,0.08-0.25,0.1-0.41l0,0.02l0.05-0.38l-0.03,0.1c-0.24-0.28-0.04-0.57-0.2-0.95
				c0.04,0.18,0.32,0.26,0.23-0.66c0.04,0.12-0.15-1.42-0.12-3.3c0.12,0.11,0.24,0.23-0.01-0.91l-0.03,0.15
				c0.06-0.01,0.21,0.07,0.03,0.32l-0.02-0.15c-0.2,0.15,0.03,0.59,0.02,0.89c-0.12-0.04-0.2-0.1-0.18,0.71
				c-0.03-0.04-0.1,0.03-0.08-0.09c-0.08,0.37,0.11,0.6,0,0.74l-0.01-0.03c-0.17,1.04,0.25,0.96,0,3.02
				c-0.14-0.02,0.06-0.11,0.04,0.65c-0.04-0.02-0.07,0.63-0.12,0.93l-0.02-0.01c0.13,0.06,0.1,0.38,0.13,0.61
				c-0.08-0.01-0.11-0.05-0.14-0.18c0.02,0.03-0.06,0.02,0.08,0.48c-0.34-0.22,0.07,0.15,0.13,1.12l-0.08-0.16
				c0.03,0.07,0,0.52-0.04,0.14c-0.07,0.68-0.03,0.28,0.04,1.69c0,0,0,0,0,0c0.1,0.49,0,0-0.01,1.51c-0.15-0.01,0,0.48-0.03,1.59
				c-0.06-0.22-0.05,0.08-0.04-0.23c-0.03,0.29-0.09,0.36,0.01,0.67c-0.09-0.11-0.11,0.22-0.09,0.36l0.06-0.2
				c0.01,0.15,0.01,0.29,0,0.43l-0.12-0.07c-0.06,0.62-0.01,1.33,0.1,2.72c0.28-0.27-0.28,0.7-0.05,0.56
				c-0.06,0.35,0,0.55-0.04,0.91c0.06-0.05,0.33,1.04-0.07,0.78c0.08,0.33-0.05,0.61-0.07,0.8l-0.03-0.17
				c0.02,0.1,0.02,0.19,0.02,0.27l0.08-0.1c-0.01,0.1-0.01,0.19-0.05,0.19c0.14,0.2,0.28-0.01,0.15,0.59
				c0.04-0.41-0.1,0.01-0.13-0.29c-0.06,0.43-0.12,0.22,0.09,0.8c-0.06-0.05-0.12,0.1-0.1,0.17c0-0.13,0.16,0.02,0.18,0.51
				l0.02-0.16c0.19,0.28-0.1,0.72-0.18,1.87c0.08-0.1,0.06-0.12,0.14,0.13l-0.06-0.57l0.12,0.36c0-0.17-0.05-0.36-0.04-0.46
				c0.07,0.17,0.05,0.16,0.12,0.07c-0.03,0.83-0.39,0.41-0.16,1.98l0.04-0.17c0.04,0.85-0.14,0.19,0.12,2.23
				c-0.08-0.12-0.12,0.06-0.18-0.21c0.14,0.61,0.03-0.2,0.13,3.58c-0.02-0.02-0.03,0-0.03-0.05c0.08,0.91-0.04,0.01-0.08,1.36
				l-0.01-0.07c-0.05,0.33-0.03,0.12,0.22,1.94c-0.04-0.01-0.05-0.01-0.07-0.02c0.07,0.05,0.15,0.2-0.08,0.54l-0.01-0.29
				c-0.12,0.15,0,0.38-0.02,0.53l-0.05-0.02c0.02,0.44,0.16,0.45,0.15,0.93l0.04-0.05c0.16,0.68-0.32,0.23,0.05,1.47
				c-0.1-0.18-0.15-0.54-0.22-0.76c0,0.17,0.01,0.58,0.08,0.67c-0.01-0.07-0.05-0.16-0.03-0.22c0.18,1.42,0.07,0.06-0.14,3.86
				c0.21-0.25,0.01,0.2,0.22,0.16c0.05,0.38-0.13,0.56-0.37,0.98c0.08-0.05,0.11,0.12,0.08,0.33l0.1-0.29
				c0.11,0.5-0.18,0.43-0.07,0.94l-0.07-0.16c-0.04,0.42,0.05,0.48,0.15,3.06l-0.05-0.05c0.06,0.32-0.06,0.17,0.05,0.81l-0.06-0.13
				c-0.07,0.6,0.14,0.93,0.18,1.43c-0.15,0-0.2,0.47-0.23,0.71l-0.02-0.01c-0.12,1.32,0.02,0.15,0.16,7.57l-0.1-0.01
				c-0.03,0.43-0.04,0.64-0.01,1.17l-0.07-0.11c0.25,0.96,0.13,0.42,0.15,2.29c-0.02-0.01-0.06-0.02-0.07-0.11
				c0,0.24-0.1,0.95,0.1,0.86c-0.13,0.01-0.01,0.54,0.05,0.82l-0.1-0.04c0.04,0.02,0.1,0.35,0.18,1.06l-0.06-0.12
				c0.03,0.41,0,0.12-0.02,2.25c0-0.36,0.01-0.64,0.01-0.62c-0.09,0-0.11,0.29-0.23,0.06c0.03,0.24,0.07-0.01,0.14,0.95l-0.01-0.03
				c0.02,0.39-0.05,0.22-0.06,0.89c0.1-0.01,0.08,0.18,0.16,0c-0.01,0.04,0-0.06-0.03-0.18c0,0,0.01,0.01,0.01,0.01
				c0-0.09-0.01-0.22-0.01-0.36c0.24,0.98-0.17,0.67,0.04,2.72c-0.1-0.34-0.05-0.32,0.01,0.86l-0.04-0.06
				c-0.21,0.85,0.33,1.38,0.06,2.33c0.02-0.07,0.02-0.1,0.05-0.11c-0.23,0.44,0.15,2.61-0.04,5.51l0.07-0.07
				c-0.23,0.68,0.15,0.9,0.55,1.8c0.17-0.37-0.28-1.24,0.02-1.21c-0.08-0.11,0.18-0.32-0.11-1.49c0.18,0.27,0.01-0.56,0.21-0.49
				c-0.17-0.2-0.07-0.74-0.17-1.1c0.01,0.02,0.04,0.06,0.03,0.09c0.01-0.2,0.06-0.26,0.1-0.39c0,0.25-0.5-0.95,0.04-0.44
				c-0.11,0.01-0.11-0.43-0.2-0.35c0.08-0.19,0.04-0.16,0.15-0.26C377.61,423.02,377.65,421.82,377.88,422.29z M377.58,415.1
				c0.01,0.06,0,0,0.01,0.15C377.59,415.21,377.59,415.17,377.58,415.1z M377.58,414.49c-0.03-0.06,0.07,0.35-0.07,0.2
				C377.53,414.72,377.55,414.58,377.58,414.49z M376.9,415.77c-0.01-0.1,0.01-0.13,0.03-0.14c0.01,0.04-0.02,0.01,0.02,0.18
				C376.93,415.8,376.91,415.79,376.9,415.77z"
                />
                <path
                  className="st10"
                  d="M393.27,430.1c-0.33-0.87,0.05-0.06-0.23-2.73c-0.18,0.04-0.21,0.1-0.17-0.34
				c0.16,0.66,0.27-0.03,0.15-0.12c0.49-0.4-0.07-1.36-0.01-3.17l0.13,0.14c-0.17-1.47,0.01,0.05-0.06-8.27l0.03,0.11
				c0.04-0.44-0.21-0.5-0.11-0.91l0.02,0.08c-0.02-0.38,0.18-3.08,0.02-5.48l0.11-0.01c-0.14,0.12,0.04-0.69-0.05-2.38
				c0.04,0.02,0.06,0.17,0.09,0.28c-0.08-0.84-0.01-0.14-0.04-1.63l0.07,0.07c0.02-4.35,0.05,0.05-0.08-12.01l0.01,0.04
				c-0.07-2.06-0.06-0.01,0.1-5.24l-0.06,0.04c0.02-1.14-0.27-0.33-0.07-5.98l0.12,0.2c-0.14-1.15,0-2.34-0.11-3.47
				c0.12,0.44,0.09-1.3-0.07-3.97c0.16,0.03,0.13-0.74,0.05-1c0.05,0.09,0.05-0.23,0.12-0.13c0-0.3-0.03-0.58-0.11-0.53
				c0.16-1.01,0.1-0.75-0.09-3.99c0.06,0.13,0.09,0.23,0.11,0.32c-0.03-0.56,0.02-0.68-0.07-3.43c-0.02,0.01-0.05,0.08-0.26-0.58
				c0.21,0.23,0.05-0.68,0.31-0.31c-0.01-0.09-0.05-0.2-0.1-0.18c0.01-0.13,0.04-0.17,0.1-0.04c-0.04-0.48-0.12-0.31-0.11-0.75
				c0.03,0,0.05,0.09,0.09,0.14c-0.07-0.22-0.14-0.44-0.11-0.81c0.02,0.02,0.05-0.02,0.05,0.09c-0.01-0.64-0.18-1.08-0.12-1.78
				c0.08,0.11,0.19,0.14,0.14-0.65l-0.06,0.15c0-0.33-0.09-0.62-0.05-0.9l0.05,0.24l-0.02-0.62l-0.05,0.35
				c-0.07-0.33-0.17,0.04-0.13-0.4c0.01,0.09,0-0.33,0.08-0.5c0,0,0,0,0,0c-0.11-0.7,0.24-0.18-0.03-1.54
				c0.18,0.17,0.15-0.48,0.02-0.63c0.01,0.04,0.02,0.02,0.03,0.11c-0.01-0.58,0.01-0.82,0.04-1.02l0.01,0.15
				c0.04-0.74,0.01,0.06-0.05-2.61l-0.01,0.13c-0.15-0.8,0.28-0.52-0.02-2.49l0.05,0.09c-0.06-1.34,0.08-1.04,0.02-3.83
				c0.05,0.22,0.06,0.33,0-0.55c-0.08,0.06-0.05,0.09-0.11,0.24c-0.05-0.09-0.09-0.29-0.09-0.47c0.05,0.32,0.13,0,0.17-0.14
				c-0.02,0.02-0.05-0.02-0.08-0.01c0.14-0.37-0.16-0.06-0.07-0.57c0.08,0.04,0.16,0.11,0.01-1.15c0.03,0.12,0.03,0.3,0.05,0.42
				c0.11-0.28,0.08-0.32,0.1-0.53l0,0.02l0.05-0.49l-0.03,0.12c-0.24-0.36-0.04-0.73-0.2-1.22c0.15,0.47,0.09,0.08,0.19,0.11
				c0.03-0.12,0.06-0.31,0.09-0.26c-0.02-0.16-0.15-0.19,0.03-0.9c-0.13,1.12-0.18-2.22-0.21-4.04c0.13,0.16,0.24,0.29-0.01-1.17
				l-0.03,0.19c0.07-0.01,0.21,0.09,0.03,0.41l-0.02-0.19c-0.2,0.19,0.03,0.76,0.02,1.14c-0.13-0.08-0.19-0.1-0.18,0.92
				c-0.03-0.05-0.1,0.04-0.08-0.12c-0.08,0.48,0.11,0.77,0,0.95l-0.01-0.04c-0.14,1.38,0.24,1.29,0,3.88
				c-0.14-0.02,0.07-0.13,0.04,0.84c-0.04-0.03-0.07,0.8-0.12,1.2l-0.02-0.01c0.13,0.08,0.1,0.49,0.13,0.78
				c-0.08-0.01-0.11-0.07-0.14-0.23c0.02,0.04-0.06,0.02,0.08,0.61c-0.25-0.2-0.08,0.13,0.13,1.43l-0.08-0.2
				c-0.04,0.22,0.07,0.11,0.06,0.29c-0.09-0.01-0.13,0.26-0.1-0.11c-0.07,0.91-0.03,0.38,0.04,2.17c0,0,0,0,0,0
				c0.17,0.69-0.13,0.86-0.05,3.98c-0.06-0.29-0.05,0.1-0.04-0.29c-0.03,0.37-0.09,0.46,0.01,0.85c-0.09-0.14-0.11,0.28-0.09,0.46
				l0.06-0.26c0.01,0.2,0.01,0.37,0,0.55l-0.12-0.1c-0.06,0.78-0.01,1.7,0.1,3.49c0.29-0.35-0.29,0.91-0.05,0.72
				c-0.06,0.45,0,0.7-0.04,1.16c0.07-0.04,0.33,1.31-0.07,1.01c0.08,0.42-0.05,0.79-0.07,1.03l-0.03-0.22
				c0.02,0.13,0.02,0.24,0.02,0.35l0.08-0.13c-0.01,0.13-0.01,0.24-0.05,0.24c0.17,0.27,0.27,0.01,0.15,0.75
				c0.04-0.53-0.1,0.02-0.13-0.37c-0.06,0.55-0.12,0.29,0.09,1.03c-0.06-0.07-0.12,0.13-0.1,0.21c0.01-0.17,0.17,0.05,0.18,0.66
				l0.02-0.21c0.19,0.35-0.1,0.93-0.18,2.4c0.08-0.13,0.06-0.15,0.14,0.16l-0.06-0.73l0.12,0.47c0-0.22-0.05-0.46-0.04-0.59
				c0.07,0.22,0.05,0.2,0.12,0.09c-0.04,1.04-0.39,0.57-0.16,2.53l0.04-0.22c0.15,0.63-0.18,0.56,0.12,2.85
				c-0.08-0.16-0.12,0.08-0.18-0.27c0.16,0.76,0.03-0.26,0.13,4.58c-0.01-0.01-0.02-0.01-0.02-0.02c0.07,1.08-0.04-0.01-0.08,1.7
				l-0.01-0.09c-0.05,0.42-0.03,0.12,0.22,2.49c-0.03-0.01-0.05-0.02-0.07-0.03c0.07,0.07,0.15,0.26-0.08,0.7l-0.01-0.37
				c-0.12,0.19,0,0.48-0.02,0.68l-0.05-0.03c0.02,0.57,0.16,0.58,0.15,1.19l0.04-0.06c0.16,0.87-0.32,0.32,0.05,1.89
				c-0.1-0.22-0.15-0.69-0.22-0.98c0,0.22,0.01,0.74,0.08,0.86c-0.01-0.09-0.05-0.2-0.03-0.29c0.18,1.83,0.07,0.07-0.14,4.94
				c0.21-0.32,0.01,0.26,0.22,0.21c0.01,0.11,0,0,0,0.31c0,0,0,0,0-0.01c-0.04,0.3-0.18,0.54-0.37,0.96
				c0.08-0.06,0.11,0.16,0.08,0.42l0.1-0.37c0.11,0.64-0.18,0.55-0.07,1.2l-0.07-0.2c-0.04,0.59,0.05,0.63,0.15,3.92l-0.05-0.06
				c0.04,0.25-0.02,0.1,0.05,1.04l-0.06-0.17c-0.07,0.77,0.14,1.19,0.18,1.83c-0.15,0-0.2,0.61-0.23,0.91l-0.02-0.01
				c-0.02,2.68,0.02,1.3,0.16,9.7l-0.1-0.01c-0.03,0.55-0.04,0.82-0.01,1.5l-0.07-0.14c0.25,1.26,0.13,0.57,0.15,2.94
				c-0.02-0.01-0.06-0.03-0.07-0.14c0,0.31-0.1,1.21,0.1,1.1c-0.13,0.01-0.01,0.69,0.05,1.05l-0.1-0.06
				c0.04,0.03,0.1,0.45,0.18,1.36l-0.06-0.15c0.02,0.37,0,1.08-0.02,2.88c0-0.46,0.01-0.83,0.01-0.79c-0.09,0-0.11,0.37-0.23,0.08
				c0.03,0.3,0.07-0.01,0.14,1.21l-0.01-0.04c0.02,0.5-0.05,0.28-0.06,1.13c0.1-0.02,0.08,0.23,0.16,0c-0.01,0.06,0-0.09-0.03-0.24
				c0,0,0.01,0.01,0.01,0.01c0-0.12-0.01-0.28-0.01-0.46c0.24,1.26-0.17,0.88,0.04,3.49c-0.02-0.09-0.02-0.2-0.04-0.25
				c-0.04,0.02,0.24,0.84,0.05,1.35l-0.04-0.07c-0.21,1.09,0.33,1.77,0.06,2.98c0.02-0.08,0.02-0.13,0.05-0.14
				c-0.17,1.45,0.05,0-0.04,7.06l0.07-0.09c0.21-0.69-0.55,3.39,0.55,1.96l0.01,0.35c0.17-0.44-0.29-1.57,0.02-1.55
				c-0.08-0.14,0.18-0.4-0.11-1.92c0.18,0.35,0.01-0.72,0.21-0.63c-0.17-0.26-0.07-0.94-0.17-1.4c0.01,0.03,0.04,0.08,0.03,0.12
				c0.3,0.02-0.43-1.4,0.13-1.07c-0.11,0.02-0.11-0.55-0.2-0.45c0.08-0.24,0.04-0.21,0.15-0.34
				C393,431.03,393.03,429.5,393.27,430.1z M392.97,420.88c0.01,0.08,0,0,0.01,0.19C392.98,421.03,392.97,420.98,392.97,420.88z
				 M392.96,420.14c0.01,0.1,0.04,0.37-0.07,0.23C392.92,420.4,392.94,420.25,392.96,420.14z M392.29,421.75
				c-0.01-0.12,0.01-0.16,0.03-0.18c0.01,0.06-0.02,0,0.02,0.23C392.32,421.78,392.3,421.77,392.29,421.75z"
                />
                <path
                  className="st10"
                  d="M594.38,383.94c-0.08,0.04,0.7,0.04,0.55-0.56c0.66-0.09-0.01-0.19,3.36-2.21l-0.03,0.15
				c0.61-0.88,0.22-0.4,1.89-1.64c0.01,0.03,0.03,0.09-0.04,0.15c0.22-0.15,0.92-0.48,0.68-0.66c0.11,0.15,0.49-0.32,0.69-0.57
				l0.04,0.14c-0.02-0.05,0.22-0.33,0.8-0.88l-0.06,0.15c0.28-0.24,0.95-0.63,2.04-1.36c-0.32,0.22-0.59,0.39-0.57,0.36
				c0.07,0.11,0.34-0.05,0.24,0.24c0.19-0.18-0.07-0.07,0.74-0.75l-0.02,0.03c0.34-0.26,0.24-0.07,0.85-0.46
				c-0.09-0.11,0.1-0.2-0.13-0.2c0.04-0.02-0.06,0.04-0.15,0.14l0-0.02c-0.09,0.06-0.2,0.13-0.32,0.22c0.69-0.87,0.7-0.17,2.46-1.66
				c-0.24,0.31-0.26,0.25,0.79-0.5l-0.02,0.08c0.94-0.23,1.03-1.21,2.12-1.39c-0.07,0.02-0.11,0.02-0.14,0
				c1.09-0.35,0.21-0.2,5.29-2.84l-0.12-0.06c0.86-0.08,0.8-0.57,1.43-1.57c-0.22,0.05-0.17,0.03-0.75,0.53
				c-0.14-0.41-0.32,0.49-0.46,0.02c-0.04,0.16-0.46-0.09-1.4,0.87c0.14-0.38-0.55,0.28-0.61-0.01c-0.09,0.32-0.66,0.48-0.95,0.78
				c0.01-0.03,0.03-0.08,0.07-0.09c-0.21,0.09-0.3,0.06-0.45,0.08c0.23-0.12-0.58,1.12-0.45,0.19c0.09,0.13-0.33,0.38-0.19,0.44
				c-0.24,0-0.18,0.04-0.36-0.05c0.27,0.14-0.9,0.76-0.62,0.21c0.11,0.38-1.23,0.64-1.96,1.84c-0.67-0.54-0.03-0.38-2.48,1.33
				l0-0.23c-0.97,0.98,0.16-0.24-5.81,4.04l0.05-0.09c-0.34,0.17-0.17,0.49-0.54,0.58l0.04-0.06c-0.14,0.04-0.31-0.09-5.48,3.9
				c-0.02-0.06,0.06-0.15,0.12-0.24c-0.23,0.37-0.16-0.09-0.36,0.25c0.06,0,0.03,0.09,0.05,0.12c-0.2,0.04-0.54,0.29-0.76,0.51
				l-0.02-0.12c-0.44,0.31-0.87,0.72-1.35,1.09c-0.5,0.45-0.99,0.53-5.29,2.76l0.64,0.35c-0.76-0.55-0.61-0.6-6.28-3.4
				c0.76,0.13-1.29-0.74-0.98-0.89c-0.58-0.06-1.74-0.86-2.64-1.21c0.06,0.01,0.17,0.03,0.23,0.08c0.25-0.35-2.67-0.81-1.82-1.24
				c-0.05,0.15-1.07-0.42-0.94-0.2c-0.38-0.35-0.35-0.26-0.49-0.53c0.2,0.41-2.51-1.17-1.28-0.86c-1.89-0.49-0.04-0.11-5.06-2.46
				c-0.09,0.32-0.01,0.3-0.73-0.12c1.92,0.69-1.01-0.95-0.11-0.3c-0.47-1.12-1.79-0.88-5.69-3.18l0.35-0.02
				c-1.6-0.62-0.57-0.43-14.89-8.25l0.22,0.07c-0.77-0.49-1.04-0.23-1.72-0.76l0.15,0.06c-3.76-1.96-0.71-0.92-14.41-7.5
				c0.06-0.04,0.35,0.06,0.59,0.13c-1.65-0.63-0.31-0.12-3.09-1.33l0.17-0.04c-6.1-2.5-6.81-3.1-23.86-6.83l0.08,0
				c-4.26-0.34,0.01-0.27-10.81-1.04l0.08,0.09c-1.55-0.03-1,0.31-12.37,0.55l0.4-0.21c-1.7,0.36,0.72-0.25-7.1,1.04
				c0.13-0.06,0.13-0.11,0.35-0.14c-0.88,0.11-3.41,0.43-8.4,1.75c0.01-0.25-1.53,0.17-2.04,0.4c0.16-0.11-0.48,0.04-0.29-0.1
				c-0.61,0.15-1.16,0.33-1.03,0.41c-2.1,0.3-1.59,0.2-7.9,2.35c0.18-0.12,0.34-0.21,0.48-0.29c-1.18,0.43-1.38,0.32-6.58,2.28
				c0-0.02,0.13-0.09,0.26-0.16c-0.8,0.41,0.58-0.25-1.26,0.92c0.34-0.44-1.34,0.41-0.76-0.2c-0.17,0.08-0.37,0.2-0.3,0.25
				c-0.26,0.07-0.36,0.07-0.13-0.1c-0.91,0.41-0.54,0.39-1.39,0.71c-0.01-0.05,0.15-0.13,0.21-0.22c-0.39,0.26-0.79,0.51-1.51,0.75
				c0.03-0.04-0.07-0.05,0.15-0.13c-1.22,0.48-1.98,1.07-3.34,1.53c0.16-0.18,0.2-0.37-1.32,0.33l0.32-0.04
				c-0.63,0.25-1.12,0.62-1.68,0.8l0.43-0.27l-1.15,0.54l0.69-0.22c-0.59,0.36,0.16,0.19-0.68,0.5c0.16-0.09-0.62,0.27-0.99,0.31
				l0,0c-1.19,0.7-0.56-0.13-2.87,1.34c0.2-0.39-1.01,0.23-1.2,0.51c0.08-0.05,0.03-0.05,0.19-0.14c-1.06,0.53-1.52,0.7-1.9,0.83
				l0.25-0.14c-1.43,0.61,0.08-0.07-4.81,2.44l0.25-0.1c-1.36,0.93-1.28,0.13-4.56,2.36l0.13-0.15c-2.41,1.37-1.97,0.88-6.98,3.71
				c0.36-0.28,0.56-0.4-1,0.55c0.16,0.05,0.2-0.03,0.51-0.1c-0.13,0.15-0.46,0.4-0.78,0.57c0.54-0.39-0.09-0.16-0.37-0.08
				c0.06,0.01-0.01,0.09,0.03,0.11c-0.77,0.19,0,0.27-0.98,0.65c0.03-0.15,0.13-0.34-2.08,1.15c0.2-0.16,0.51-0.34,0.72-0.49
				c-0.59,0.15-0.62,0.22-1.01,0.41l0.04-0.02l-0.91,0.43l0.24-0.09c-0.47,0.67-1.28,0.8-2.03,1.49c0.73-0.67,0.08-0.19,0.07-0.36
				c-0.24,0.09-0.6,0.24-0.53,0.15c-0.36,0.06-2.3,1.84-8.66,5.34c0.06-0.1-0.01,0,0.3-0.4c0,0,0,0,0,0c0.2-0.2-1.73,1.44-0.93,0.55
				c-0.51,0.34-1.33,0.87-1.43,1.08l0.36-0.16c-0.06-0.1,0.02-0.34,0.7-0.46l-0.32,0.22c0.49,0.04,1.32-0.83,2.01-1.21
				c-0.41,0.62,0.32,0.15,1.75-0.74c-0.07,0.09,0.15,0.08-0.15,0.22c0.91-0.39,1.29-0.93,1.69-0.98l-0.06,0.05
				c2.38-1.38,0.61-0.75,6.92-3.99c0,0.01,0,0.01,0,0.02c0.4-0.29,0,0,1.47-0.91c-0.02,0.08,1.49-0.72,2.23-1.06l-0.01,0.04
				c0.05-0.24,0.8-0.62,1.32-0.95c0.03,0.1-0.04,0.19-0.28,0.38c0.06-0.03,0.18-0.03,1.03-0.69c-0.31,0.57,0.46-0.18,2.5-1.58
				l-0.31,0.3c0.37-0.15,0.68-0.55,0.34-0.12c1.76-0.84,0.77-0.37,3.92-2.15l0,0c1.26-0.88,2.22-0.99,7.34-3.66
				c-0.49,0.34,0.22-0.02-0.51,0.33c0.7-0.3,0.89-0.31,1.57-0.78c-0.2,0.23,0.59-0.11,0.9-0.3l-0.52,0.15
				c0.36-0.19,0.69-0.34,1.02-0.48l-0.11,0.23c1.47-0.6,3.14-1.49,6.46-3.12c-0.83-0.1,1.9-0.37,1.38-0.53
				c0.88-0.3,1.33-0.57,2.22-0.87c-0.06-0.08,2.25-1.44,1.95-0.69c0.76-0.44,1.53-0.52,2-0.68l-0.41,0.21
				c0.24-0.12,0.45-0.2,0.66-0.29l-0.29-0.02c0.26-0.08,0.47-0.16,0.48-0.11c0.46-0.49-0.07-0.35,1.36-0.76
				c-1.4,0.51-0.44,0.78,1.28-0.41c-0.1,0.13,0.3,0.07,0.46-0.01c-0.33,0.1,0.07-0.28,1.18-0.7l-0.41,0.11
				c1.82-0.8,3.03-0.8,8.49-2.68l-0.44,0.08c1.28-0.55,1.28-0.19,5.61-1.72c-0.29,0.18,0.2,0.13-0.48,0.38
				c1.61-0.52,0.84-0.54,9.15-2.17c-0.02,0.02-0.02,0.03-0.03,0.04c2.16-0.47,0,0.03,3.46-0.45l-0.18,0.04
				c0.91-0.03,0.07-0.05,5.02-0.96c-0.01,0.05-0.03,0.07-0.04,0.1c0.14-0.11,0.52-0.26,1.43-0.03l-0.76,0.09
				c0.41,0.14,0.98-0.11,1.38-0.12l-0.04,0.08c1.16-0.11,1.16-0.3,2.42-0.38l-0.13-0.05c1.74-0.32,0.73,0.35,3.87-0.25
				c-0.46,0.16-1.41,0.26-1.99,0.37c0.45-0.01,1.52-0.06,1.76-0.17c-0.18,0.02-0.41,0.08-0.59,0.06c2.45-0.43,0.75-0.05,10.12,0.33
				c-0.63-0.33,0.54,0.02,0.45-0.29c0.22,0.01-0.01-0.01,0.63,0.06c0,0-0.01,0-0.04-0.01c0.6,0.11,1.09,0.36,1.93,0.72
				c-0.12-0.12,0.34-0.13,0.87-0.03l-0.73-0.21c1.32-0.04,1.1,0.35,2.43,0.38l-0.43,0.04c1.25,0.33-0.09-0.28,7.92,1.16l-0.14,0.04
				c0.51,0.06,0.19,0.08,2.09,0.42l-0.35,0c1.52,0.44,2.4,0.41,3.7,0.71c-0.06,0.2,1.1,0.65,1.68,0.86l-0.03,0.02
				c2.97,0.72,8.76,2.91,18.38,7.48l-0.08,0.12c0.99,0.54,1.48,0.81,2.72,1.43l-0.3-0.04c2.49,0.92,1.17,0.39,5.42,2.66
				c-0.03,0.02-0.1,0.05-0.3-0.05c0.56,0.31,2.11,1.33,2.05,0.96c-0.08,0.17,1.23,0.7,1.91,0.99l-0.17,0.07
				c0.09-0.01,0.89,0.34,2.57,1.14l-0.32-0.07c1,0.35,0.26,0.2,5.17,2.93c-0.82-0.46-1.48-0.84-1.41-0.82
				c-0.06,0.11,0.59,0.5-0.03,0.36c0.56,0.26,0.03-0.1,2.27,1.05l-0.07-0.03c0.9,0.47,0.46,0.34,2,1.21
				c0.04-0.14,0.46,0.13,0.11-0.2c0.09,0.06-0.16-0.09-0.44-0.21l0.03,0c-0.22-0.12-0.5-0.28-0.83-0.45
				c2.55,0.96,0.43,0.51,6.29,3.47c-0.18-0.07-0.37-0.18-0.48-0.2c0.01,0.07,1.65,0.53,2.46,1.3l-0.16-0.03
				c1.81,1.36,3.41,1.38,5.39,2.93c-0.14-0.1-0.21-0.16-0.22-0.2c3.14,2.02-1.5-0.9,12.65,7.18l-0.12-0.18
				c0.46,0.28,1.51,0.78,1.37,0.84c0.33-0.02,2.02,1.28,2.91,1.78c-0.54,0.49-1.49,0.68-1.78,1.21l0.02-0.03
				c-2.39,1.88,2.03-1.55-7.53,5.83l0.04-0.24c-0.66,0.75-1.61,1.2-2.26,1.93c0.02-0.07-0.01-0.11,0.07-0.16
				c-0.23,0.03-0.6,0.45-0.54,0.53c-0.03-0.07-0.8,0.47-2.14,1.79c-0.12-0.19-0.61,0.25-0.72,0.47c0.02-0.1-0.2,0.07-0.19-0.06
				c-0.2,0.16-0.36,0.34-0.25,0.41c-0.8,0.34-0.56,0.25-2.59,2.22c0.25-0.84,0.89-0.95-2.12,1.6c-0.01-0.02,0.02-0.07,0.06-0.12
				c-0.18,0.28,0.02-0.05-0.22,0.72c-0.03-0.35-0.5,0.3-0.48-0.19c-0.05,0.06-0.09,0.16-0.04,0.2c-0.1,0.05-0.15,0.04-0.11-0.09
				c-0.29,0.3-0.11,0.29-0.4,0.52c-0.03-0.04,0.02-0.1,0.01-0.17c-0.09,0.19-0.18,0.39-0.45,0.55c0-0.04-0.06-0.04,0.02-0.1
				c-0.42,0.34-0.57,0.77-1.1,1.06c0.01-0.28-0.2,0.07-0.34-0.06l-0.22,0.24l0.16-0.01c-0.22,0.17-0.34,0.43-0.56,0.52l0.12-0.19
				l-0.39,0.35l0.28-0.12c-0.45,0.75,0.13,0.13-1.59,1.34c0.06-0.23-0.45-0.05-0.45,0.3c0.02-0.03,0-0.03,0.05-0.1
				c-0.39,0.32-0.58,0.41-0.73,0.47l0.09-0.09c-0.53,0.32,0.03-0.05-1.75,1.37l0.1-0.05c0.09,0.26-0.43,0.16-0.61,0.43
				c-0.24-0.18,0.01-0.12-1.11,0.81l0.02-0.1c-0.86,0.69-0.8,0.42-2.72,1.81c0.11-0.16,0.2-0.23-0.39,0.26
				c0.1,0.07,0.1,0.02,0.26,0.02c-0.03,0.1-0.14,0.23-0.27,0.31c0.19-0.21-0.1-0.15-0.24-0.15c0.04,0.02,0.02,0.07,0.05,0.1
				c-0.61,0.26,0.05,0.16-1.2,0.85c0.07-0.09,0.19-0.17,0.26-0.25c-0.29,0-0.29,0.04-0.45,0.12l0.02-0.01l-0.39,0.16l0.11-0.02
				c-0.08,0.46-0.5,0.37-0.74,0.78c0.14-0.16-0.02-0.53-0.79,0.09c0.1-0.13-1.28,1-3.06,1.9c0.02-0.18,0.06-0.42-0.88,0.46
				l0.17-0.03c-0.05-0.05-0.08-0.31,0.29-0.2l-0.13,0.1c0.28,0.18,0.56-0.33,0.85-0.46c0.05,0.13,0.01,0.32,0.8-0.15
				c-0.02,0.06,0.1,0.11-0.03,0.15c0.42-0.09,0.5-0.45,0.71-0.39l-0.02,0.03c0.54-0.3,1.7-0.82,3.45-2.09
				c0.01,0.06,0.63-0.28,0.95-0.41l0.01,0.03c-0.04-0.19,0.27-0.35,0.47-0.51c0.05,0.1,0.04,0.16-0.05,0.27
				c0.01-0.04,0.08,0.05,0.38-0.37l0.02,0.15c0.02-0.21,0.59,0,0.89-0.98l-0.08,0.19c0.04-0.08,0.46-0.33,0.16-0.03
				c0.68-0.33,0.29-0.14,1.51-1.08l0,0c0.16-0.37,1.29-0.63,2.81-1.91c-0.15,0.21,0.11,0.01-0.17,0.2c0.28-0.15,0.39-0.13,0.58-0.44
				c-0.02,0.17,0.29-0.01,0.39-0.13l-0.23,0.06c0.13-0.11,0.25-0.19,0.38-0.27l0.03,0.18c0.61-0.36,1.18-0.86,2.3-1.91
				c-0.47-0.13,0.84-0.15,0.53-0.32c0.36-0.17,0.48-0.36,0.82-0.56c-0.12-0.01,0.63-1.08,0.74-0.44c0.21-0.31,0.58-0.35,0.75-0.47
				l-0.12,0.15c0.07-0.09,0.15-0.15,0.22-0.21l-0.16-0.03c0.1-0.05,0.17-0.11,0.2-0.07c0.07-0.26-0.28-0.31,0.38-0.56
				c-0.39,0.24,0.09,0.11-0.14,0.33c0.05-0.05,0.17-0.16,0.19-0.09c0.44-0.16,0.05-0.27,0.42-0.55c0.01,0.11,0.19,0.07,0.24,0
				c-0.11,0.1-0.15-0.17,0.28-0.55l-0.16,0.08c0.09-0.41,0.69-0.37,1.77-1.06c-0.16-0.03-0.16,0.01-0.02-0.25l-0.43,0.45l0.2-0.38
				c-0.15,0.11-0.26,0.3-0.36,0.36c0.09-0.19,0.09-0.16-0.04-0.18c0.76-0.57,0.66,0.2,1.84-1.16l-0.18,0.08
				c-0.01-0.14,0.27-0.23,0.39-0.39c0.05,0.09,0.16,0.04,0.26,0.04c-0.05-0.05,0.33-0.26,0.25-0.44c0.52-0.19,0.33,0.02,0.9-0.86
				c-0.04,0.17,0.16,0.1-0.02,0.34c0.37-0.52-0.21,0.12,2.95-2.56c0,0.03,0.03,0.04-0.02,0.07c0.72-0.71,0.04,0.04,1.25-0.82
				l-0.05,0.06c0.32-0.16,0.12-0.07,1.49-1.54c0.02,0.05,0.03,0.07,0.04,0.1c-0.01-0.11,0.05-0.3,0.54-0.26l-0.25,0.2
				c0.24,0.04,0.32-0.25,0.48-0.33l0.03,0.08c0.37-0.31,0.26-0.47,0.69-0.78l-0.08-0.01c0.48-0.57,0.37,0.04,1.25-1.02
				c-0.06,0.23-0.34,0.53-0.48,0.75c0.15-0.11,0.5-0.39,0.52-0.53c-0.05,0.06-0.1,0.16-0.17,0.18c1.08-1.15-0.01-0.11,3.47-2.41
				c-0.08,0.18-0.54-0.68,1.12-0.6c-0.11-0.05,0.01-0.21,0.21-0.31l-0.33,0.08c0.33-0.46,0.53-0.09,0.87-0.55l-0.08,0.18
				c0.35-0.18,0.34-0.35,2.5-2.23l0.01,0.09c0.23-0.3,0.19-0.04,0.65-0.6l-0.06,0.15c0.58-0.33,0.67-0.78,1.08-1.16
				c0.13,0.17,0.59-0.09,0.81-0.22l0.01,0.03c0.92-0.51-0.28-0.4,3.32-2.6C594,384.36,593.97,384.08,594.38,383.94z M605.25,376.14
				c-0.01,0.09,0.02,0.12-0.14,0.13C605.13,376.23,605.13,376.29,605.25,376.14z M604.23,375.71c-0.03,0.02-0.06,0.05-0.13,0.1
				C604.15,375.76,604.1,375.81,604.23,375.71z M603.79,376.15c0.01-0.04-0.12,0.01-0.22,0.03
				C603.63,376.13,603.83,375.93,603.79,376.15z M453.99,355.28l0.85-0.47c-0.43,0.14-0.88,0.37-1.14,0.44
				c0.4-0.24,0.37-0.19,0.13-0.22c0.76-0.22,1.14-0.25,1.53-0.3L453.99,355.28z M555.48,369.69c0.09,0.01-0.18-0.17-0.37-0.32
				C555.29,369.45,555.78,369.67,555.48,369.69z M557.66,371.83c-0.24-0.02-0.16,0.01-0.43-0.2
				C557.34,371.67,557.22,371.65,557.66,371.83z M556.47,370.12c0.15,0.07,0,0,0.35,0.18C556.74,370.26,556.64,370.21,556.47,370.12
				z"
                />
                <path
                  className="st10"
                  d="M589.57,447.86l0.6,0.05c-0.8-0.3-0.62,0.02-5.89-0.87c0.65-0.08-1.19-0.37-0.95-0.6
				c-0.5,0.1-1.59-0.34-2.36-0.54c0.05,0,0.14,0,0.2,0.03c0.2-0.38-2.42-0.31-1.64-0.85c-0.04,0.16-0.95-0.21-0.82-0.03
				c-0.33-0.29-0.3-0.21-0.43-0.45c0.18,0.37-2.18-0.82-1.1-0.67c-1.8-0.32,0.04-0.01-4.29-1.96c-0.09,0.29-0.03,0.31-0.63-0.1
				c1.47,0.59-0.41-0.72-0.06-0.29c-0.26-1-2.05-1.14-4.6-3.03l0.32-0.02c-1.34-0.68-0.53-0.35-11.58-8.59l0.18,0.08
				c-0.58-0.51-0.88-0.28-1.37-0.82l0.13,0.06c-1.45-0.81-1.17-1.66-11.47-7.69c0.06-0.04,0.3,0.07,0.5,0.13
				c-1.37-0.61-0.24-0.12-2.57-1.28l0.16-0.04c-4.6-2.05-5.62-2.92-20.3-5.75l0.07-0.01c-3.63-0.12,0.01-0.3-9.23-0.5l0.07,0.09
				c-1.9,0.26,0.15-0.12-10.45,1.37l0.32-0.24c-1.4,0.52,0.56-0.35-5.89,1.68c0.1-0.07,0.09-0.12,0.28-0.17
				c-0.72,0.2-2.84,0.79-6.77,2.77c-0.05-0.23-1.28,0.34-1.64,0.67c0.11-0.13-0.4,0.12-0.27-0.05c-0.48,0.24-0.9,0.5-0.76,0.56
				c-1.69,0.62-1.32,0.37-6.11,3.44c0.12-0.13,0.22-0.24,0.31-0.33c-0.91,0.6-1.08,0.55-4.96,3.17c-0.01-0.02,0.09-0.11,0.18-0.19
				c-0.56,0.51,0.42-0.32-0.82,1.06c0.17-0.47-1.03,0.59-0.7-0.07c-0.12,0.1-0.25,0.25-0.19,0.29c-0.2,0.11-0.29,0.12-0.13-0.08
				c-0.67,0.51-0.36,0.44-1,0.86c-0.03-0.04,0.09-0.14,0.13-0.24c-0.27,0.29-0.54,0.59-1.09,0.93c0.02-0.05-0.07-0.04,0.09-0.15
				c-0.91,0.65-1.41,1.29-2.47,1.91c0.09-0.19,0.06-0.37-1.05,0.49l0.27-0.08c-0.47,0.33-0.81,0.73-1.24,0.97l0.3-0.31l-0.86,0.65
				l0.54-0.29c-0.42,0.41,0.19,0.16-0.47,0.56c0.12-0.11-0.47,0.33-0.78,0.41l0,0c-0.88,0.81-0.49-0.06-2.16,1.59
				c0.09-0.38-0.82,0.32-0.92,0.61c0.05-0.05,0.01-0.05,0.13-0.15c-0.81,0.59-1.17,0.81-1.47,0.97l0.19-0.16
				c-1.09,0.7,0.06-0.07-3.7,2.68l0.2-0.11c-1.02,0.96-1.07,0.21-3.59,2.45l0.09-0.14c-1.93,1.35-1.58,0.9-5.68,3.52
				c0.29-0.25,0.46-0.36-0.83,0.48c0.14,0.05,0.18-0.02,0.45-0.07c-0.1,0.14-0.38,0.36-0.65,0.51c0.45-0.34-0.1-0.16-0.34-0.1
				c0.05,0.01,0,0.09,0.04,0.11c-0.66,0.14,0.02,0.26-0.82,0.58c0-0.16,0.13-0.26-1.79,0.93c0.17-0.13,0.45-0.27,0.62-0.4
				c-0.51,0.08-0.55,0.15-0.88,0.29l0.03-0.01l-0.79,0.32l0.21-0.06c-0.4,0.59-1.11,0.63-1.76,1.22c0.64-0.57,0.07-0.17,0.06-0.34
				c-0.21,0.06-0.52,0.16-0.46,0.08c-0.32-0.04-2.04,1.5-7.9,3.35c0.06-0.08,0.14-0.2,0.3-0.31c-0.21,0.1-1.6,0.78-0.86,0.26
				c-0.49,0.16-1.28,0.41-1.4,0.58l0.34-0.04c-0.04-0.1,0.08-0.32,0.68-0.22l-0.31,0.11c0.41,0.2,1.27-0.36,1.89-0.59
				c-0.27,0.28,0.29,0.25,1.59-0.28c-0.07,0.07,0.12,0.11-0.16,0.18c0.83-0.16,1.22-0.59,1.58-0.54l-0.06,0.03
				c2.17-0.99,2.34-1.03,6.21-2.75c0.05,0.22-0.23,0,1.28-0.71c-0.01,0.08,1.3-0.54,1.93-0.85l0,0.04c0.03-0.23,0.67-0.55,1.1-0.84
				c0.04,0.1-0.02,0.19-0.22,0.35c0.05-0.03,0.17-0.02,0.86-0.61c-0.22,0.52,0.39-0.13,2.06-1.44l-0.24,0.29
				c0.32-0.14,0.52-0.52,0.29-0.11c1.44-0.79,0.62-0.34,3.19-2.07l0,0c0.58-0.66,2.17-1.18,5.8-3.86c-0.36,0.36,0.19-0.04-0.39,0.35
				c0.56-0.34,0.72-0.36,1.22-0.87c-0.12,0.24,0.49-0.15,0.72-0.36l-0.43,0.19c0.27-0.21,0.53-0.38,0.79-0.55l-0.04,0.23
				c1.15-0.72,2.42-1.7,4.89-3.65c-0.74,0.01,1.54-0.58,1.06-0.68c0.69-0.38,1-0.71,1.69-1.12c-0.09-0.06,1.58-1.67,1.5-0.91
				c0.54-0.51,1.17-0.72,1.53-0.94l-0.29,0.26c0.17-0.16,0.33-0.26,0.49-0.37l-0.25,0.03c0.2-0.11,0.36-0.22,0.38-0.18
				c0.26-0.5-0.17-0.32,0.97-0.9c-1.07,0.66-0.14,0.81,0.98-0.59c-0.05,0.14,0.27,0.02,0.39-0.08c-0.26,0.15-0.04-0.26,0.81-0.85
				l-0.32,0.17c0.34-0.56,1.43-0.76,3.68-2c-0.25,0.01-0.27,0.06,0.13-0.32l-1.03,0.74l0.6-0.58c-0.33,0.2-0.65,0.49-0.85,0.59
				c0.28-0.29,0.26-0.24,0.05-0.23c1.55-0.86,1.14-0.12,3.91-2.04l-0.35,0.14c0.9-0.7,1.06-0.4,4.3-2.49
				c-0.2,0.22,0.2,0.1-0.31,0.44c1.28-0.78,0.58-0.71,7.22-3.35c-0.01,0.02-0.01,0.03-0.02,0.04c1.73-0.71,0.01,0.04,2.83-0.83
				l-0.14,0.06c0.74-0.1,0.2-0.12,4.09-1.41c0,0.05-0.01,0.08-0.02,0.11c0.1-0.12,0.4-0.3,1.2-0.15l-0.63,0.15
				c0.36,0.1,0.81-0.19,1.15-0.23l-0.03,0.09c0.96-0.22,0.95-0.39,2-0.56l-0.12-0.04c1.44-0.44,0.63,0.29,3.24-0.51
				c-0.37,0.19-1.16,0.35-1.65,0.49c0.38-0.05,1.28-0.16,1.47-0.28c-0.15,0.04-0.34,0.1-0.49,0.09c2.04-0.58,0.61-0.11,8.56-0.24
				c-0.55-0.3,0.45-0.01,0.37-0.31c0.19-0.01-0.01-0.01,0.54,0.03c0,0,0,0-0.02-0.01c0.51,0.08,0.92,0.31,1.64,0.62
				c-0.1-0.11,0.28-0.14,0.73-0.08l-0.63-0.17c1.12-0.1,0.94,0.29,2.06,0.26l-0.36,0.06c1.05,0.23,1.12-0.11,6.73,0.78l-0.12,0.05
				c0.44,0.03,0.16,0.07,1.77,0.31l-0.3,0.02c1.29,0.36,2.04,0.3,3.14,0.54c-0.06,0.22,0.94,0.54,1.42,0.75l-0.03,0.02
				c2.48,0.65,7.32,2.26,15.2,6.98l-0.09,0.11c0.77,0.56,1.15,0.84,2.15,1.46l-0.26-0.05c2.02,0.98,0.94,0.42,4.28,2.8
				c-0.03,0.01-0.1,0.04-0.25-0.06c0.43,0.33,1.61,1.38,1.62,1.03c-0.1,0.16,0.95,0.73,1.49,1.05l-0.16,0.05
				c0.08-0.01,0.72,0.37,2.04,1.24l-0.26-0.09c0.6,0.46,1.72,1.29,3.99,3.08c-0.64-0.49-1.15-0.88-1.09-0.85
				c-0.08,0.1,0.42,0.51-0.09,0.34c0.45,0.28,0.04-0.09,1.82,1.12l-0.06-0.03c0.71,0.5,0.33,0.36,1.55,1.25
				c0.06-0.13,0.39,0.14,0.14-0.19c0.07,0.06-0.13-0.09-0.36-0.21l0.03,0c-0.17-0.12-0.4-0.29-0.65-0.47c2,1.03,1.08,1.17,5.01,3.51
				c-0.15-0.07-0.31-0.18-0.4-0.2c0,0.07,1.39,0.54,2.01,1.26l-0.14-0.02c1.02,0.83-0.2-0.19,4.54,2.61
				c-0.12-0.09-0.18-0.14-0.19-0.18c0.46,0.31,5.04,3.03,11.55,4.44l-0.14-0.13c0.45,0.11,1.43,0.22,1.34,0.32
				c0.54-0.14,3.98,0.93,3.26,0.15C591.29,448.74,591.44,448.03,589.57,447.86z M562.78,435.16c0.07,0.02-0.13-0.17-0.27-0.32
				C562.65,434.93,563.05,435.17,562.78,435.16z M564.29,437.3c-0.19-0.02-0.15,0.01-0.34-0.21
				C564.04,437.14,563.93,437.12,564.29,437.3z M563.56,435.62c0.12,0.07,0,0,0.27,0.19C563.78,435.77,563.7,435.72,563.56,435.62z"
                />
                <path
                  className="st10"
                  d="M562.81,445.38l0.41,0c-0.52-0.2-1.91,0.25-1.8-0.19c-0.18,0.06-0.41-0.28-2.22-0.4
				c0.48-0.11-0.8-0.31-0.59-0.53c-0.38,0.14-1.05-0.36-1.61-0.46c0.04,0,0.11-0.01,0.15,0.02c-0.25-0.13-0.31-0.24-0.43-0.36
				c-0.01,0.09-1.41-0.13-0.7-0.37c-0.19-0.02-0.53-0.18-0.5-0.05c-0.18-0.26-0.18-0.19-0.22-0.41c0.05,0.34-1.47-0.71-0.68-0.59
				c-1.09-0.21-0.05-0.03-2.83-1.69c-0.11,0.2-0.09,0.33-0.48-0.07c0.82,0.34,0.2-0.36,0.02-0.25c0.08-0.83-1.35-0.99-2.95-2.49
				l0.26-0.03c-1.72-0.85,0.42-0.36-8.1-6.18l0.14,0.04c-0.44-0.34-0.68-0.06-1.05-0.44l0.1,0.03c-0.4-0.22-3.25-2.09-6.05-3.03
				l0.05-0.14c-0.02,0.06-0.33-0.04-0.88-0.35c-0.02,0.03-0.03,0.06-0.03,0.08c-0.08-0.13-0.45-0.42-1.83-0.77
				c0.05-0.05,0.22,0,0.37,0.02c-1-0.28-0.16-0.05-1.88-0.68l0.12-0.07c-3.12-1.1-3.26-1.51-14.54-3.05l0.05-0.02
				c-2.5,0.51-0.14-0.44-6.47,1.19l0.08,0.07c-1.32,0.45-0.25,0.43-6.77,2.9l0.16-0.25c-1.21,0.74-2.63,1.14-3.83,1.84
				c0.06-0.07,0.04-0.11,0.17-0.17c-0.31,0.01-0.99,0.42-0.95,0.51c-0.82,0.18-0.85,0.25-3.63,1.68c-0.06-0.22-0.9,0.19-1.16,0.42
				c0.07-0.11-0.29,0.05-0.21-0.09c-0.34,0.15-0.64,0.33-0.53,0.41c-1.23,0.28-0.89,0.22-4.43,2.06c0.12-0.14,0.22-0.23,0.3-0.31
				c-0.6,0.31-0.78,0.3-3.81,1.76c0.02,0.02,0.11,0.03-0.51,0.62c0.14-0.38-0.79,0.26-0.53-0.26c-0.09,0.06-0.2,0.16-0.15,0.21
				c-0.16,0.05-0.22,0.03-0.1-0.11c-0.52,0.29-0.28,0.3-0.78,0.51c-0.02-0.05,0.07-0.11,0.1-0.18c-0.21,0.2-0.42,0.39-0.85,0.54
				c0.01-0.04-0.05-0.05,0.07-0.11c-0.71,0.33-1.11,0.77-1.93,1.02c0.08-0.16,0.04-0.31-0.81,0.13l0.21,0.01
				c-0.37,0.16-0.64,0.43-0.98,0.51l0.24-0.19l-0.68,0.34l0.42-0.11c-0.33,0.25,0.13,0.2-0.38,0.37c0.09-0.06-0.37,0.16-0.6,0.15
				l0,0c-0.74,0.51-0.32-0.23-1.7,0.8c0.09-0.31-0.63,0.04-0.72,0.29c0.04-0.03,0.01-0.04,0.1-0.1c-0.65,0.32-0.94,0.4-1.17,0.46
				l0.16-0.1c-0.84,0.32,0.06-0.05-2.88,1.41l0.15-0.05c-0.79,0.61-0.71-0.08-2.75,1.32l0.07-0.11c-1.43,0.79-1.21,0.44-4.23,2.05
				c0.2-0.18,0.33-0.26-0.6,0.31c0.11,0.07,0.13,0.01,0.34,0.01c-0.07,0.11-0.26,0.27-0.45,0.37c0.31-0.24-0.09-0.17-0.27-0.14
				c0.04,0.02,0.01,0.08,0.04,0.11c-0.5,0.03,0.04,0.24-0.57,0.41c-0.01-0.11-0.01-0.25-1.26,0.64c0.12-0.1,0.31-0.2,0.42-0.3
				c-0.38,0.02-0.4,0.07-0.64,0.17l0.02-0.01l-0.56,0.22l0.15-0.03c-0.22,0.52-0.76,0.48-1.17,0.97c0.4-0.46,0.02-0.15-0.01-0.31
				c-0.15,0.04-0.38,0.1-0.34,0.03c-0.19,0.24-0.46,0.14-0.72,0.49c0.13-0.14-1.83,1.25-4.4,2.77c0.07-0.24,0.12-0.49-1.21,0.76
				l0.22-0.08c-0.06-0.07-0.07-0.31,0.4-0.3l-0.18,0.15c0.35,0.12,0.77-0.52,1.18-0.75c0.02,0.19,0.02,0.31,1.09-0.36
				c-0.03,0.07,0.12,0.1-0.07,0.17c0.57-0.19,0.74-0.61,1-0.58l-0.04,0.04c1.52-0.62,1.17-1.1,4.13-2.31c0.07,0.19-0.19,0,0.87-0.54
				c0,0.07,0.92-0.37,1.37-0.53l0,0.03c0-0.21,0.46-0.41,0.76-0.61c0.04,0.1,0,0.17-0.15,0.31c0.03-0.05,0.07,0.06,0.62-0.44
				c-0.05,0.42,0.19,0.04,1.48-0.96l-0.17,0.21c0.07-0.09,0.73-0.35,0.22-0.04c1.02-0.39,0.42-0.16,2.36-1.21l0,0
				c0.93-0.7,1-0.27,4.44-2.01c-0.28,0.22,0.14,0.02-0.3,0.21c0.43-0.15,0.56-0.12,0.94-0.45c-0.1,0.18,0.38,0,0.56-0.12l-0.33,0.05
				c0.21-0.12,0.41-0.2,0.61-0.28l-0.04,0.2c0.92-0.32,1.9-0.84,3.84-1.87c-0.56-0.2,1.18-0.07,0.83-0.3
				c0.54-0.14,0.79-0.34,1.33-0.52c-0.1-0.06,1.3-1.07,1.17-0.4c0.43-0.31,0.91-0.32,1.19-0.41l-0.23,0.15
				c0.14-0.09,0.26-0.14,0.38-0.2l-0.19-0.05c0.15-0.04,0.28-0.1,0.3-0.05c0.21-0.33-0.15-0.36,0.76-0.56
				c-0.61,0.21,0.07,0.12-0.34,0.34c0.66-0.18,0.39,0.02,1.1-0.61c-0.04,0.11,0.21,0.09,0.3,0.03c-0.19,0.08-0.06-0.23,0.63-0.56
				l-0.25,0.07c0.29-0.42,1.09-0.32,2.8-0.93c-0.19-0.05-0.2-0.01,0.1-0.27l-0.78,0.43l0.45-0.39c-0.25,0.11-0.49,0.29-0.64,0.34
				c0.21-0.2,0.2-0.16,0.04-0.2c1.2-0.47,0.83,0.25,2.94-1.02l-0.26,0.06c0.62-0.48,0.76-0.08,3.13-1.55
				c-0.13,0.18,0.16,0.12-0.2,0.37c0.56-0.41-0.36,0.13,5.07-2.4c-0.01,0.02,0,0.03-0.01,0.04c1.17-0.62,0.01,0.06,1.95-0.72
				l-0.09,0.06c0.5-0.14,0.18-0.04,2.67-1.5c0.01,0.05,0.01,0.08,0.01,0.11c0.04-0.12,0.21-0.32,0.83-0.23l-0.41,0.19
				c0.28,0.07,0.54-0.24,0.77-0.31l0,0.08c0.63-0.3,0.56-0.49,1.25-0.77l-0.09-0.02c0.89-0.63,0.52,0.27,2.09-0.97
				c-0.2,0.24-0.69,0.53-0.97,0.75c0.25-0.11,0.83-0.37,0.92-0.52c-0.09,0.06-0.2,0.16-0.31,0.17c1.22-0.91,0.37-0.15,5.68-1.83
				c0.08-0.44,0.19-0.75,1.74-0.14c-0.09-0.09,0.16-0.19,0.47-0.18c-0.15-0.03-0.3-0.06-0.45-0.08c0.74-0.26,0.68,0.16,1.42-0.07
				l-0.23,0.11c0.66,0.1,0.71-0.33,4.65-0.08l-0.08,0.06c0.31-0.01,0.13,0.02,1.24,0.11l-0.21,0.05c0.89,0.25,1.44,0.06,2.22,0.2
				c-0.05,0.21,0.64,0.46,0.98,0.59l-0.02,0.03c3.06,1.16,1.62,0.14,11.11,3.88l-0.07,0.12c0.59,0.3,0.89,0.45,1.64,0.78l-0.19,0.02
				c1.5,0.35,0.69,0.09,3.25,1.44c-0.03,0.02-0.08,0.06-0.19,0.01c0.33,0.19,1.19,0.86,1.22,0.54c-0.09,0.16,0.7,0.45,1.11,0.62
				l-0.13,0.08c0.05-0.02,0.53,0.17,1.5,0.71l-0.2-0.03c0.38,0.25,1.07,0.76,2.75,2.17c-0.43-0.36-0.77-0.64-0.74-0.62
				c-0.08,0.1,0.25,0.4-0.13,0.31c0.31,0.19,0.05-0.09,1.26,0.78l-0.04-0.02c0.48,0.37,0.21,0.27,1,0.95
				c0.08-0.12,0.29,0.09,0.15-0.18c0.04,0.05-0.09-0.07-0.24-0.16l0.02,0c-0.11-0.09-0.26-0.22-0.43-0.35
				c1.39,0.73,0.64,0.87,3.27,2.74c-0.49-0.24-0.44-0.27,1.03,0.87l-0.11-0.02c0.85,1.09,1.97,1.02,2.93,2.21
				c-0.07-0.08-0.11-0.12-0.1-0.16c0.17,0.39,4.05,2.94,7.75,3.97l-0.09-0.13C559.21,445.44,564.22,446.96,562.81,445.38z
				 M545.12,434.94c0.06,0.01-0.08-0.14-0.16-0.25C545.05,434.75,545.36,434.94,545.12,434.94z M545.94,436.68
				c-0.13,0-0.13,0.03-0.24-0.15C545.77,436.55,545.69,436.55,545.94,436.68z M545.67,435.27c0.08,0.05,0,0,0.19,0.14
				C545.82,435.38,545.77,435.34,545.67,435.27z"
                />
                <path
                  className="st10"
                  d="M550.53,451.03c-0.1-0.11-0.1-0.26-0.78-0.31c0.28-0.34-0.62-0.04-0.29-0.4c-0.16,0.03-0.19-0.43-1.59-0.82
				c0.42-0.05-0.56-0.37-0.34-0.56c-0.33,0.07-0.8-0.37-1.23-0.49c0.03,0,0.09-0.01,0.12,0.02c-0.18-0.13-0.21-0.23-0.3-0.33
				c-0.01,0.1-1.29-0.06-0.44-0.35c-0.07,0.14-0.52-0.14-0.5,0.03c-0.13-0.22-0.14-0.15-0.15-0.35c0.01,0.31-1.17-0.49-0.52-0.47
				c-0.25,0.27-1.2-0.82-2.72-1.02c0.16-0.87,0.33-0.19-2.48-1.78l0.21-0.09c-1.33-0.53,0.25,0.11-6.58-3.98l0.11,0.02
				c-0.32-0.26-0.55,0.02-0.81-0.29l0.08,0.02c-0.24-0.08,0.06-0.44-6.45-3.56c0.04-0.05,0.18-0.01,0.29,0
				c-0.47-0.04-0.01-0.18-0.44-0.22c0.04,0.05-0.06,0.07-0.09,0.1c-0.14-0.17-0.57-0.35-0.9-0.45l0.1-0.07
				c-0.53-0.26-1.17-0.43-1.8-0.67c-1.27-0.5,0.26-0.01-9.27-1.79l0.04-0.01c-1.06,0.68-0.22-1.49-10.27,2.47l0.09-0.24
				c-0.87,0.71-1.95,1.17-2.67,2.02c0.03-0.07-0.01-0.11,0.08-0.18c-0.26,0.05-0.66,0.54-0.58,0.63c-0.03-0.05-0.88,0.62-2,2.42
				c-0.17-0.16-0.55,0.47-0.6,0.73c-0.01-0.11-0.18,0.14-0.2,0.01c-0.16,0.24-0.27,0.47-0.14,0.5c-0.75,0.55-0.33,0.54-2.2,2.82
				c0-0.12,0.03-0.22,0.07-0.3c-0.44,0.3-0.37,0.54-2.43,1.72c-0.01-0.02,0.04-0.07,0.09-0.11c-0.26,0.24,0.04-0.04-0.43,0.66
				c0.08-0.35-0.6,0.17-0.43-0.3c-0.06,0.05-0.14,0.13-0.1,0.19c-0.12,0.02-0.17,0-0.08-0.12c-0.39,0.2-0.21,0.25-0.58,0.38
				c-0.01-0.05,0.06-0.09,0.08-0.16c-0.16,0.16-0.33,0.3-0.66,0.36c0.01-0.03-0.04-0.06,0.06-0.09c-0.55,0.18-0.89,0.51-1.54,0.56
				c0.08-0.14,0.05-0.28-0.62-0.08l0.15,0.06c-0.3,0.06-0.53,0.24-0.8,0.22l0.21-0.11l-0.55,0.13l0.33,0.01
				c-0.82,0.47,0.05,0.18-2.21,0.46c0.12-0.26-0.47-0.16-0.59,0.05c0.04-0.02,0.02-0.03,0.1-0.06c-0.55,0.1-0.78,0.09-0.95,0.07
				l0.14-0.04c-0.68,0.04,0.06-0.04-2.39,0.46l0.12,0c-0.04,0.27-0.5-0.05-0.8,0.14c-0.15-0.26,0.06-0.1-1.47,0.31l0.07-0.08
				c-1.17,0.33-0.99,0.07-3.5,0.75c0.17-0.11,0.29-0.16-0.5,0.13c0.08,0.1,0.1,0.05,0.26,0.1c-0.07,0.09-0.23,0.18-0.39,0.22
				c0.27-0.15-0.05-0.18-0.19-0.21c0.03,0.03,0,0.08,0.02,0.11c-0.73,0.08-0.01,0.16-1.53,0.48c0.1-0.07,0.26-0.11,0.36-0.17
				c-0.3-0.08-0.31-0.04-0.51-0.01l0.02,0l-0.46,0.05l0.12,0.01c-0.23,0.43-0.64,0.25-1.01,0.59c0.2-0.12,0.15-0.53-0.85-0.08
				c0.07-0.1-1.57,0.71-3.69,1.41c0.06-0.19,0.13-0.42-1.02,0.38l0.18-0.02c-0.05-0.06-0.03-0.32,0.34-0.17l-0.15,0.09
				c0.27,0.21,0.65-0.29,0.99-0.39c0.02,0.15-0.04,0.31,0.89-0.04c-0.03,0.06,0.09,0.13-0.07,0.15c0.46-0.03,0.63-0.37,0.84-0.28
				l-0.03,0.02c0.67-0.2,1.9-0.53,4.16-1.32c-0.01,0.07,0.74-0.1,1.11-0.13l0,0.03c0.02-0.2,0.4-0.26,0.66-0.36
				c0.02,0.11-0.02,0.16-0.15,0.25c0.03-0.04,0.06,0.07,0.52-0.25l-0.03,0.15c0.12-0.06,0.57-0.01,1.28-0.64l-0.16,0.15
				c0.07-0.06,0.6-0.15,0.17,0.02c0.81-0.08,0.34-0.04,1.95-0.48l0,0c0.55-0.26,0,0,1.77-0.32c0.01,0.21,0.56-0.1,1.87-0.28
				c-0.25,0.13,0.1,0.06-0.26,0.11c0.35-0.02,0.44,0.05,0.78-0.14c-0.1,0.14,0.28,0.12,0.44,0.06l-0.25-0.05
				c0.18-0.05,0.34-0.06,0.5-0.07l-0.06,0.18c0.76-0.04,1.58-0.2,3.19-0.62c-0.39-0.34,0.9,0.25,0.68-0.05
				c0.43,0.01,0.65-0.12,1.09-0.15c-0.1-0.06,1.13-0.74,0.95-0.12c0.36-0.21,0.75-0.12,0.97-0.17l-0.19,0.11
				c0.11-0.06,0.21-0.09,0.32-0.13l-0.15-0.08c0.13-0.02,0.23-0.05,0.24,0c0.17-0.25-0.14-0.37,0.62-0.43
				c-0.49,0.12,0.06,0.13-0.27,0.29c0.07-0.03,0.23-0.1,0.23-0.03c0.53-0.04,0.16-0.24,0.64-0.43c-0.03,0.11,0.18,0.11,0.25,0.06
				c-0.16,0.06-0.08-0.19,0.47-0.5l-0.19,0.06c0.2-0.42,0.89-0.21,2.14-0.96c-0.17-0.02-0.17,0.02,0-0.26
				c-0.16,0.18-0.35,0.32-0.52,0.48l0.27-0.4c-0.17,0.12-0.32,0.31-0.44,0.37c0.12-0.2,0.12-0.16-0.02-0.19
				c0.86-0.61,0.8,0.24,2-1.4l-0.18,0.12c-0.02-0.14,0.26-0.31,0.36-0.5c0.06,0.08,0.17,0,0.28-0.03c-0.06-0.04,0.31-0.37,0.18-0.54
				c0.49-0.36,0.35-0.09,0.7-1.18c0.01,0.18,0.19,0.04,0.07,0.35c0.24-0.66-0.18,0.18,2.41-3.43c0.01,0.03,0.03,0.04-0.02,0.07
				c0.7-0.81,0-0.01,1.24-0.98l-0.05,0.07c0.37-0.16,0.04-0.1,1.66-1.57c0.02,0.05,0.03,0.08,0.03,0.11c0-0.11,0.08-0.31,0.61-0.23
				l-0.29,0.18c0.24,0.07,0.37-0.22,0.54-0.29l0.02,0.08c0.45-0.25,0.37-0.43,0.88-0.66l-0.08-0.03c0.65-0.55,0.39,0.32,1.59-0.7
				c-0.15,0.19-0.52,0.43-0.73,0.6c0.19-0.07,0.62-0.27,0.69-0.38c-0.07,0.04-0.15,0.12-0.23,0.12c1.48-0.74,0.02-0.18,4.4-0.83
				c-0.32-0.26,0.24-0.04,0.15-0.33c0.21,0.01,0.36-0.13,0.28,0.28c-0.27-0.09,0.38-0.21,0.02-0.3c0.3,0.02,0.44,0.2,0.88,0.46
				c-0.06-0.11,0.13-0.16,0.37-0.14l-0.34-0.12c0.57-0.2,0.51,0.22,1.09,0.06l-0.18,0.1c0.42,0.12,0.52-0.2,3.54,0.01l-0.06,0.07
				c0.4-0.03,0.18,0.12,0.94,0.08l-0.16,0.06c0.67,0.2,1.1,0.02,1.69,0.09c-0.05,0.21,0.47,0.42,0.72,0.53l-0.02,0.03
				c1.35,0.9,0.29-0.5,8.29,3.13l-0.07,0.12c0.43,0.26,0.64,0.4,1.2,0.67l-0.16,0.03c1.14,0.23,0.5,0.05,2.46,1.15
				c-0.02,0.02-0.07,0.07-0.16,0.02c0.25,0.15,0.89,0.7,0.95,0.39c-0.09,0.17,0.54,0.34,0.86,0.45l-0.12,0.09
				c0.04-0.03,0.42,0.09,1.21,0.44l-0.17,0c0.35,0.18,1.05,0.67,2.26,1.44c-0.36-0.23-0.64-0.42-0.61-0.41
				c-0.07,0.11,0.21,0.31-0.11,0.32c0.27,0.11,0.03-0.09,1.06,0.42l-0.04-0.01c0.41,0.22,0.18,0.2,0.85,0.63
				c0.06-0.13,0.24,0.01,0.12-0.2c0.04,0.04-0.07-0.04-0.21-0.08l0.02,0c-0.1-0.06-0.22-0.13-0.37-0.22
				c1.16,0.31,0.51,0.61,2.78,1.67c-0.41-0.09-0.37-0.14,0.87,0.53l-0.09,0.01c0.7,0.79,1.65,0.47,2.4,1.39
				c-0.05-0.06-0.08-0.09-0.08-0.13c0.27,0.55,2.8,1.5,5.53,3.53l-0.02-0.13C548.98,451.18,549.28,451.31,550.53,451.03z
				 M536.56,442.64c0.05-0.01-0.06-0.11-0.14-0.2C536.5,442.48,536.77,442.6,536.56,442.64z M537.26,444.03
				c-0.09,0.03-0.11,0.06-0.2-0.08C537.11,443.95,537.05,443.97,537.26,444.03z M537.02,442.81c0.07,0.03,0,0,0.16,0.08
				C537.14,442.88,537.1,442.85,537.02,442.81z"
                />
                <path
                  className="st10"
                  d="M531.35,452.81c0.05-0.11,0.21-0.13-0.2-0.16c0.44-0.26-0.3-0.06,0.07-0.26c-0.1-0.04-0.13-0.18-0.26-0.16
				c0.07-0.14-0.17-0.23,0.02-0.33c-0.06,0-0.22-0.04-0.27-0.1c0.3-0.01-0.13-0.26,0.13-0.37c-0.26,0.03-0.28-0.27-0.49-0.36
				c0.02,0,0.06,0,0.06,0.02c-0.04-0.1,0-0.16,0.01-0.24c-0.17,0.09-0.3-0.02-0.45-0.03c0.09-0.21,0.24-0.06,0.37-0.19
				c-0.13,0.09-0.25-0.1-0.34,0.01c0.05-0.15,0-0.1,0.11-0.23c-0.21,0.12-0.08-0.05-0.25-0.04c0.09-0.1-0.15-0.27,0.19-0.28
				c-0.36,0.17-0.13-0.56-1.18-0.66c0.02,0.04,0.06,0.01,0.11,0l-0.09-0.03c0.42,0.12,0.04-0.42,0.12-0.14
				c0.68-0.38-1.04-0.65-0.61-1.06c-1.16-0.89-0.33-0.26-2.37-2.33l0.06,0c-0.09-0.17-0.35,0.1-0.39-0.11l0.04,0
				c-0.29-0.22,0.11-0.35-1.91-1.38l0.03-0.2c-0.27,0.1-0.17-0.08-1.18-0.58l0.07-0.09c-0.23-0.14-0.52-0.19-0.8-0.27
				c0.01,0.07-0.19,0.38-0.1,0.06c-0.07,0.08-0.2,0.31-0.02-0.06l-0.15,0.01c0.01-0.05,0.05-0.06,0.07-0.12
				c-0.08,0.08-0.18-0.23-0.31-0.09c-0.14-0.16-1.01-0.15-1.62-0.03c0.01-0.13-0.22-0.14-0.09-0.22c-0.27,0-0.34-0.01-0.68,0.07
				c0.07,0.09,0.01,0.21-0.06-0.01c-0.36,0.2-0.88-0.36-1.17-0.07l0.02-0.02c-0.77,0.29-0.09-0.05-2.17,0.33
				c0.02,0.07,0.09,0.18-0.12,0.06c0.58,0.45-0.7-0.25-3.19,1.76c0-0.05-0.02-0.09,0.01-0.11c-0.14-0.05-0.29,0.2-0.24,0.28
				c0.14-0.08-0.56,0.17-1.01,0.83c-0.13-0.19-0.34,0.03-0.36,0.18c-0.01-0.08-0.12,0-0.14-0.1c-0.09,0.08-0.16,0.18-0.07,0.26
				c-0.42,0.05-0.28-0.02-1.15,1.09c-0.07-0.53,0.42-0.65-0.98,0.74c-0.01-0.02-0.01-0.05,0-0.08c-0.04,0.18,0.01-0.03,0.06,0.51
				c-0.12-0.28-0.25,0.11-0.38-0.26c-0.01,0.04-0.02,0.1,0.03,0.15c-0.42,0.15-0.14,0-0.85,0.9c-0.08-0.22-0.12,0-0.25-0.13
				l-0.08,0.14l0.1,0.03c-0.1,0.08-0.1,0.26-0.22,0.28l0.02-0.12l-0.16,0.18l0.15-0.03c-0.1,0.26,0.24,0.21-0.51,0.28
				c0.05,0.16-0.21,0.22-0.17,0.4c0-0.05-0.06-0.12-0.08-0.14l0.04,0.13c-0.07,0.01-0.09-0.01-0.13-0.07
				c-0.15,0.09,0.19,0.15-0.04,0.2c0.01-0.02-0.01-0.03,0-0.07c-0.19,0.17-0.28,0.2-0.36,0.2l0.05-0.07l-0.11-0.02l0.03,0.09
				c-0.12-0.21-0.17,0.55-1.44,1.14c0.16,0.03-0.57,0.03-1.44,1.03c0.08-0.13-0.08-0.17-0.16-0.2c0.02,0.03,0.02,0.07,0.04,0.1
				c-0.21-0.22-0.01,0.19-0.17,0.13c0.02-0.01-0.28,0.32-0.16-0.01c-0.28,0.15,0.03-0.01-0.56,0.04c0.15,0.39,0.02,0.03-0.28,0.46
				c0.07-0.24,0.05-0.1-0.18-0.37c-0.04,0.15-0.16-0.04-0.22,0.17c-0.01-0.03-0.05-0.02-0.07-0.04c0,0.07-0.26,0.23-1.26,0.53
				c0.04-0.29-0.31,0.19-0.14-0.21c-0.09,0.05-0.18,0.15-0.27,0.2c-0.03-0.08,0.05-0.13,0.08-0.19c0.02,0.03-0.28,0.05-0.24,0.28
				c0.15-0.36-0.1,0.35,0.52-0.08l-0.04,0.21l0.13-0.18c0.04,0.05,0.08,0.17,0.01,0.2c0.1,0.08,0.06,0.12,0.29-0.03
				c-0.01,0.05,0.04,0.14-0.03,0.13c0.16,0.02,0.48,0.08,0.5-0.44l0.22,0.34l-0.01-0.15c0.03,0,0.08,0,0.09,0.06
				c0.07-0.12-0.03-0.06,0-0.17c0.16,0.1,0.33-0.14,0.43,0.08c0.01-0.12-0.14-0.05-0.04-0.17c0.03,0.32,0.27-0.27,0.31,0.16
				c0.04-0.36,0.22,0.23,0.63-0.38c0.01,0.09,0.01-0.06,0.52,0c-0.05-0.18,0.11-0.22,0.2-0.31c0.04,0.1,0.04,0.15,0.01,0.22
				c0-0.02,0.08,0.03,0.17-0.21l0.04,0.14c0.13-0.08,0.19-0.28,0.41-0.29c-0.05-0.12-0.3-0.25-0.17-0.41
				c0.04,0.06,0.1,0.16,0.07,0.21c0.02,0,0.11-0.18,0.06,0.07c0.02-0.07,0.23-0.13,0.09,0.02c0.08-0.02,0.19-0.01,0.26,0.04
				c-0.08-0.18,0.28,0.07,0.46-0.53l0,0c0.04-0.27,0.65-0.14,1.34-0.83c-0.04,0.14,0.08,0.04-0.06,0.12
				c0.14-0.05,0.22-0.01,0.27-0.21c0.03,0.13,0.18,0.06,0.22-0.01l-0.14-0.01c0.42-0.14,0.14,0.09,0.85-0.45
				c-0.61-0.21,0.58-0.59,0.52-0.46l-0.02-0.06c0.09,0,0.24-0.12,0.26-0.02c0.04-0.11-0.03-0.1-0.11-0.08
				c0.19-0.05,0.21-0.18,0.39-0.25c-0.11,0,0.15-0.73,0.37-0.17c0.05-0.19,0.29-0.14,0.37-0.19l-0.04,0.09
				c0.02-0.06,0.06-0.08,0.09-0.11l-0.11-0.06c0.24-0.05,0.13,0.06,0.21-0.36c-0.25,0.15,0.35,0.48,0.23-0.12
				c0.04,0.09,0.15,0.1,0.16,0.06c-0.02,0.06-0.18-0.1,0.03-0.36c-0.09,0-0.15,0.05-0.01-0.18c0.05,0.05-0.04,0.09,0.01,0.09
				c-0.07-0.36,0.92,0.13,0.72-0.49l-0.16,0.24l0.03-0.25c-0.07,0.05-0.09,0.17-0.14,0.19c0-0.13,0.02-0.1-0.08-0.15
				c0.38-0.43,0.31,0.43,0.91-0.45c-0.1-0.02-0.19,0.02,0.05-0.19c0.06,0.08,0.12,0.08,0.19,0.1c-0.05-0.05,0.15-0.12,0.05-0.29
				c0.26-0.01,0.24,0.12,0.37-0.44c0.02,0.12,0.13,0.12,0.07,0.26c0.11-0.19-0.02,0.05,0.22-0.62c0.27,0.08,0.32,0.03,1.1-0.58
				c0,0.01,0.01,0.02,0.01,0.03c0.29-0.33,0.01,0.05,0.6-0.27l-0.02,0.04c0.15,0.03,0.16-0.24,0.32-0.31c0,0,0.01,0.03,0.02,0.04
				c0.02-0.2,0.19-0.36,0.31-0.49c0.1,0.27,0.03,0.19,0.33,0.06c-0.21,0.74,0.44-0.86,0.73-0.35c0.06-0.08,0.19-0.3,0.31-0.4
				c-0.02,0.17-0.15,0.31-0.21,0.44c0.08-0.03,0.25-0.13,0.25-0.24c-0.11,0.05-0.13,0.07,0.2-0.3c0.11,0.35,0.45-0.05,0.66,0.19
				c0.23-0.22,0.56-0.12,0.87-0.23c-0.14-0.05,0.15-0.54,0.17-0.04c-0.11-0.09,0.05-0.16-0.01-0.25c0.13,0.05,0.28,0.18,0.4,0.42
				c-0.04-0.1,0.03-0.16,0.13-0.14l-0.15-0.12c0.21-0.2,0.24,0.22,0.45,0.02l-0.06,0.11c0.11-0.09,0.43-0.07,0.54-0.3
				c0.02,0.03,0.06,0.12,0,0.14c0.18,0.14,0.36-0.08,1.23-0.08l-0.07,0.08c0.89-0.2,0.39,0.22,2.68,0.76
				c0.34-0.1,0.16,0.04,1.65,0.66c-0.05,0.23-0.11,0.1,0.37,0.45l-0.1,0.05c0.5-0.02,0.22-0.19,1.01,0.49
				c-0.02,0.02-0.07,0.06-0.1,0.05c0.09,0.08,0.26,0.39,0.42,0.15c-0.12,0.14,0.18,0.19,0.34,0.22l-0.11,0.09
				c0.02-0.03,0.17-0.01,0.56,0.16l-0.1,0.03c0.15,0.15,0.34-0.18,0.32,0.34c0.03,0,0,0.02,0.44,0.47
				c-0.12-0.13-0.21-0.24-0.19-0.23c-0.26,0.07,0.09,0.66,0.63,0.66c0.01,0.02-0.02-0.02-0.09-0.04l0.01,0
				c-0.03-0.03-0.07-0.08-0.12-0.13c0.09-0.01,0.17,0,0.17,0.11c0.09-0.03,0.19-0.06,0.24-0.07c0.15,0.16,0.3,0.78,1.47,2.17
				c0-0.04,0-0.06,0.02-0.08c0.23,0.34-0.1,0.36,1.07,1.56l-0.11,0.11c0.56,0,0.11,0.92,1.2,1.51c-0.01,0-0.02,0.01-0.05,0.02
				l0.09-0.02c-0.01,0-0.01,0-0.01-0.01c0.18,0-0.02-0.06,0.35-0.61l0.07,0.12C531.54,452.83,531.44,452.83,531.35,452.81z
				 M527.01,448.31c-0.02,0.01,0,0-0.06,0.03C526.91,448.32,526.89,448.21,527.01,448.31z M527.22,447.36
				c0.04-0.01,0.01-0.08,0-0.14C527.19,447.23,527.41,447.33,527.22,447.36z M527.44,447.44c0.03,0.01,0,0,0.06,0.05
				C527.49,447.48,527.47,447.46,527.44,447.44z"
                />
                <path
                  className="st10"
                  d="M618.74,373.11c0.02,0.01,0.05,0.03,0.05,0.13c0-0.3,0.07-1.15-0.08-1.04c0.09-0.01,0.01-0.65-0.04-0.99
				l0.07,0.05c-0.03-0.03-0.07-0.43-0.13-1.29l0.04,0.14c-0.02-0.39,0-1.26,0-2.74c0,0.43,0,0.78-0.01,0.75
				c0.06,0,0.07-0.35,0.16-0.07c-0.02-0.29-0.05,0.01-0.11-1.15l0.01,0.04c-0.02-0.47,0.04-0.26,0.03-1.08
				c-0.07,0.01-0.06-0.21-0.12,0c0-0.05,0,0.09,0.02,0.22l-0.01-0.01c0,0.11,0,0.26,0.01,0.43c-0.18-1.19,0.12-0.87-0.05-3.31
				c0.01,0.09,0.01,0.19,0.03,0.24c0.03-0.02-0.18-0.77-0.04-1.28l0.03,0.07c0.14-1.03-0.25-1.68-0.06-2.83
				c-0.01,0.08-0.02,0.12-0.03,0.14c0.16-0.54-0.12-3.12-0.02-6.7l-0.05,0.09c0.01-0.25-0.04-0.78,0.02-0.74
				c-0.14-0.19,0.25-2.23-0.11-1.76c0.04-0.32-0.33-0.39-0.32,0.65l-0.01-0.34c-0.05,0.35-0.09,1.12,0.09,3.29
				c-0.14-0.33,0,0.68-0.14,0.6c0.12,0.25,0.06,0.89,0.13,1.33c-0.01-0.02-0.03-0.07-0.02-0.11c-0.22-0.02,0.31,1.27-0.09,1.01
				c0.08-0.02,0.08,0.52,0.15,0.42c-0.06,0.23-0.03,0.2-0.11,0.32c0.13-0.18,0.11,1.27-0.06,0.71c0.25,0.85-0.04,0.05,0.19,2.58
				c0.16-0.04,0.13-0.07,0.13,0.32c-0.38,0.26-0.21-0.19-0.08,3.11l-0.1-0.13c0.13,1.39-0.01,0.06,0.07,7.84l-0.02-0.1
				c-0.03,0.42,0.15,0.47,0.08,0.86l-0.01-0.07c-0.03,0.52-0.08-0.2-0.05,7.46c-0.03-0.02-0.04-0.16-0.06-0.27
				c0.06,0.8,0.01,0.15,0.02,1.54l-0.05-0.07c0.03,2.6-0.12,2.56,0.04,11.39l-0.01-0.04c0.04,1.96,0.05,0-0.09,4.96l0.04-0.04
				c-0.02,1.09,0.19,0.31,0.01,5.67l-0.08-0.19c0.09,1.09-0.02,2.22,0.05,3.29c-0.08-0.42-0.07,1.24,0.01,3.76
				c-0.11-0.03-0.1,0.7-0.05,0.95c-0.03-0.08-0.04,0.22-0.08,0.12c0,0.29,0.02,0.55,0.08,0.5c-0.13,0.97-0.08,0.72,0.02,3.78
				c-0.04-0.12-0.06-0.22-0.08-0.3c0.01,0.53-0.02,0.65,0.01,3.25c-0.01,0-0.02-0.07-0.03-0.14c0.05,0.41-0.01-0.29,0.21,0.69
				c-0.14-0.22-0.05,0.64-0.23,0.29c0.01,0.08,0.03,0.19,0.07,0.17c-0.01,0.12-0.03,0.16-0.07,0.04c0.02,0.46,0.08,0.3,0.07,0.71
				c-0.02,0-0.03-0.09-0.06-0.13c0.05,0.21,0.09,0.42,0.06,0.77c-0.01-0.02-0.03,0.02-0.03-0.09c0,0.6,0.11,1.03,0.06,1.69
				c-0.05-0.1-0.13-0.15-0.11,0.61l0.05-0.14c-0.01,0.31,0.06,0.59,0.02,0.85l-0.04-0.23l0.01,0.58l0.04-0.33
				c0.04,0.32,0.12-0.03,0.09,0.38c-0.01-0.08-0.01,0.31-0.06,0.47l0,0c0.07,0.64-0.17,0.2-0.01,1.46c-0.13-0.16-0.12,0.46-0.03,0.6
				c0-0.04-0.01-0.02-0.02-0.11c0,0.55-0.02,0.78-0.05,0.96l-0.01-0.14c-0.04,0.7-0.01-0.05,0,2.48l0.01-0.12
				c0.2,0.37-0.26,0.14-0.04,2.36l-0.03-0.09c0.02,1.28-0.08,0.99-0.09,3.63c-0.03-0.21-0.03-0.32-0.01,0.52
				c0.06-0.05,0.04-0.09,0.09-0.23c0.03,0.09,0.05,0.28,0.05,0.44c-0.03-0.3-0.09,0-0.13,0.13c0.02-0.02,0.04,0.02,0.05,0.01
				c-0.11,0.35,0.11,0.06,0.03,0.54c-0.06-0.04-0.11-0.13-0.04,1.09c-0.01-0.13-0.02-0.27-0.03-0.4c-0.08,0.27-0.07,0.3-0.08,0.5
				l0-0.02l-0.05,0.46l0.02-0.12c0.17,0.34,0.02,0.69,0.12,1.16c-0.1-0.45-0.06-0.08-0.14-0.11c-0.02,0.12-0.05,0.29-0.07,0.24
				c0.01,0.16,0.1,0.16-0.04,0.85c0.13-1.03,0.07,2.08,0.05,3.83c-0.09-0.17-0.16-0.28-0.03,1.11l0.03-0.18
				c-0.06,0-0.14-0.09-0.01-0.39l0.01,0.18c0.15-0.18,0-0.72,0.02-1.08c0.1,0.1,0.13,0.09,0.15-0.86c0.02,0.05,0.07-0.04,0.06,0.11
				c0.07-0.45-0.06-0.73,0.03-0.9l0.01,0.04c0.02-1.35-0.13-0.68,0.09-3.68c0.1,0.03-0.06,0.1-0.01-0.79
				c0.03,0.03,0.07-0.76,0.11-1.13l0.01,0.01c-0.09-0.07-0.06-0.46-0.08-0.74c0.05,0.01,0.08,0.06,0.1,0.22
				c-0.02-0.04,0.04-0.02-0.04-0.58c0.17,0.2,0.06-0.13-0.07-1.36l0.05,0.19c0.03-0.19-0.07-0.39,0.03-0.16
				c0.07-0.89,0.03-0.39,0.02-2.06v0c-0.11-0.64,0.12-0.84,0.11-3.78c0.04,0.27,0.04-0.09,0.03,0.28c0.03-0.35,0.07-0.43,0.01-0.81
				c0.06,0.13,0.08-0.26,0.07-0.43l-0.05,0.25c-0.01-0.19,0-0.35,0.01-0.52l0.08,0.09c0.05-0.73,0.04-1.61-0.01-3.31
				c-0.21,0.31,0.22-0.85,0.04-0.68c0.05-0.42,0.01-0.66,0.05-1.1c-0.05,0.01-0.22-1.22,0.07-0.95c-0.05-0.4,0.05-0.75,0.06-0.97
				l0.02,0.21c-0.01-0.13-0.01-0.23-0.01-0.33l-0.06,0.12c0.01-0.12,0.01-0.23,0.04-0.23c-0.14-0.28-0.18-0.03-0.1-0.71
				c-0.03,0.69,0.3,0.32,0.04-0.62c0.04,0.07,0.09-0.12,0.08-0.2c-0.01,0.16-0.12-0.07-0.12-0.62l-0.02,0.2
				c-0.13-0.32,0.08-0.88,0.16-2.27c-0.06,0.12-0.05,0.14-0.1-0.15l0.03,0.69l-0.08-0.44c0,0.21,0.03,0.44,0.02,0.56
				c-0.05-0.21-0.03-0.19-0.08-0.09c0.05-0.96,0.29-0.57,0.15-2.4l-0.03,0.21c-0.1-0.6,0.14-0.54-0.06-2.71
				c0.05,0.15,0.09-0.08,0.12,0.26c-0.12-0.77-0.03,0.23-0.05-4.35c0.01,0.01,0.01,0.01,0.02,0.02c-0.04-1.01,0.03,0,0.07-1.61
				l0.01,0.08c0.04-0.4,0.02-0.12-0.14-2.36c0.02,0.01,0.04,0.02,0.05,0.02c-0.05-0.07-0.1-0.25,0.07-0.66l0,0.35
				c0.09-0.18,0-0.46,0.02-0.64l0.04,0.02c-0.01-0.54-0.11-0.55-0.1-1.13l-0.03,0.06c-0.1-0.81,0.23-0.32-0.02-1.79
				c0.07,0.21,0.11,0.65,0.15,0.93c0-0.21,0-0.71-0.05-0.81c0.01,0.08,0.03,0.19,0.02,0.27c-0.12-1.74-0.05-0.07,0.13-4.69
				c-0.15,0.3,0-0.25-0.16-0.2c-0.01-0.1,0,0,0-0.29c0,0,0,0,0,0.01c0.03-0.28,0.13-0.51,0.27-0.91c-0.05,0.06-0.08-0.15-0.06-0.4
				l-0.07,0.35c-0.08-0.61,0.13-0.52,0.05-1.14l0.05,0.19c0.03-0.59-0.03-0.6-0.09-3.72l0.04,0.06c-0.03-0.24,0.02-0.1-0.03-0.99
				l0.04,0.16c0.05-0.73-0.1-1.12-0.12-1.74c0.11,0,0.15-0.57,0.17-0.86l0.02,0.01c-0.07-1.44-0.03-3.94-0.11-9.2l0.07,0.01
				c0.02-0.52,0.03-0.78,0.01-1.42l0.05,0.13C618.68,374.69,618.76,375.34,618.74,373.11z M618.75,366.23
				c0.01,0.12,0,0.16-0.02,0.17c-0.01-0.05,0.01,0-0.02-0.22C618.73,366.19,618.74,366.21,618.75,366.23z M618.27,367.05
				c-0.01-0.08,0,0-0.01-0.18C618.26,366.91,618.26,366.96,618.27,367.05z M618.27,367.76c-0.01-0.09-0.03-0.34,0.05-0.22
				C618.3,367.5,618.29,367.65,618.27,367.76z"
                />
                <path
                  className="st10"
                  d="M618.61,401.52c-0.01,0.17-0.04,0.33-0.03,0.41C618.63,401.85,618.65,401.64,618.61,401.52z"
                />
                <path
                  className="st10"
                  d="M641.47,446.57c-0.03-0.07-0.11-0.4-0.13-0.62c0.16,0.38-0.04-0.8,0.1-0.7c-0.14-0.28-0.12-1.04-0.22-1.54
				c0.01,0.03,0.03,0.08,0.03,0.13c0.22,0.01-0.38-1.43,0.02-1.18c-0.08,0.02-0.11-0.61-0.17-0.48c0.04-0.27,0.01-0.23,0.09-0.38
				c-0.12,0.21-0.19-1.48,0.01-0.83c-0.31-1,0.04-0.05-0.34-3c-0.17,0.05-0.12,0.08-0.15-0.37c0.34-0.18,0.23,0.19-0.1-3.63
				l0.1,0.15c-0.22-1.67-0.01-0.13-0.49-9.13l0.03,0.12c0.01-0.49-0.18-0.54-0.13-1l0.02,0.09c0.04-0.39-0.17-3.6-0.16-6.07
				c-0.1,0.1,0-0.78-0.14-2.63c0.03,0.02,0.05,0.18,0.07,0.31c-0.09-0.94-0.02-0.18-0.09-1.8l0.06,0.08
				c-0.14-3.03,0.01-3-0.47-13.28l0.01,0.04c-0.1-2.28-0.05,0-0.06-5.79l-0.04,0.05c-0.01-1.28-0.21-0.36-0.17-6.62l0.09,0.22
				c-0.12-1.27-0.04-2.59-0.13-3.83c0.08,0.44,0.06-1.16-0.1-4.39c0.11,0.03,0.08-0.82,0.03-1.11c0.03,0.1,0.03-0.26,0.08-0.14
				c0-0.34-0.03-0.64-0.08-0.59c0.11-1.13,0.07-0.85-0.08-4.42c0.04,0.14,0.06,0.25,0.08,0.35c-0.02-0.62,0.01-0.75-0.06-3.79
				c0.01,0,0.02,0.08,0.03,0.16c-0.06-0.48,0.02,0.34-0.21-0.8c0.15,0.25,0.04-0.75,0.22-0.35c-0.01-0.1-0.03-0.22-0.07-0.2
				c0.01-0.15,0.03-0.19,0.07-0.04c-0.03-0.53-0.08-0.35-0.08-0.83c0.02,0,0.03,0.1,0.06,0.15c-0.05-0.25-0.1-0.49-0.07-0.9
				c0.01,0.02,0.03-0.02,0.03,0.1c-0.01-0.7-0.13-1.2-0.08-1.97c0.05,0.11,0.13,0.18,0.1-0.72l-0.05,0.17c0-0.36-0.07-0.69-0.03-1
				l0.04,0.27l-0.01-0.68l-0.04,0.39c-0.04-0.37-0.12,0.04-0.09-0.45c0.01,0.1,0-0.36,0.06-0.55l0,0c-0.07-0.73,0.17-0.25,0-1.7
				c0.13,0.19,0.11-0.54,0.02-0.7c0,0.05,0.01,0.02,0.02,0.12c0-0.64,0.02-0.91,0.04-1.12l0.01,0.16c0.04-0.82,0.01,0.06-0.01-2.89
				l-0.01,0.14c-0.2-0.44,0.26-0.15,0.04-2.75l0.03,0.1c-0.02-1.49,0.08-1.15,0.09-4.24c0.03,0.24,0.03,0.37,0.01-0.61
				c-0.06,0.06-0.04,0.1-0.09,0.27c-0.03-0.1-0.05-0.32-0.05-0.52c0.03,0.35,0.09,0,0.13-0.15c-0.02,0.03-0.04-0.03-0.05-0.02
				c0.11-0.41-0.11-0.07-0.03-0.63c0.06,0.05,0.11,0.16,0.04-1.28c0.01,0.15,0.02,0.32,0.02,0.47c0.09-0.31,0.07-0.35,0.09-0.58
				l0,0.02l0.05-0.54l-0.02,0.14c-0.16-0.4-0.01-0.81-0.11-1.35c0.09,0.52,0.06,0.09,0.13,0.13c0.02-0.14,0.05-0.34,0.07-0.28
				c-0.01-0.2-0.1-0.16,0.05-0.99c-0.15,1.18-0.04-2.41,0-4.47c0.09,0.2,0.16,0.32,0.04-1.29l-0.03,0.21c0.06,0,0.14,0.11,0.01,0.45
				l-0.01-0.21c-0.15,0.21-0.01,0.84-0.03,1.26c-0.1-0.12-0.13-0.1-0.16,1.01c-0.02-0.06-0.08,0.05-0.05-0.13
				c-0.08,0.53,0.05,0.86-0.04,1.05l0-0.04c-0.03,1.56,0.11,0.81-0.13,4.29c-0.1-0.03,0.06-0.11,0,0.92
				c-0.03-0.03-0.07,0.89-0.12,1.32l-0.01-0.01c0.09,0.09,0.06,0.54,0.07,0.87c-0.05-0.01-0.07-0.07-0.09-0.25
				c0.02,0.05-0.05,0.02,0.04,0.68c-0.17-0.24-0.06,0.16,0.06,1.59l-0.05-0.23c-0.03,0.22,0.07,0.46-0.03,0.19
				c-0.07,1.05-0.03,0.46-0.02,2.4v0c0.11,0.74-0.13,1.01-0.11,4.41c-0.04-0.32-0.04,0.11-0.03-0.33c-0.03,0.41-0.07,0.5,0,0.95
				c-0.06-0.15-0.08,0.31-0.07,0.5l0.05-0.29c0.01,0.22,0,0.41-0.01,0.61l-0.08-0.11c0.08,0.97-0.12,1.2,0.03,3.86
				c0.22-0.39-0.22,1.01-0.04,0.8c-0.05,0.5,0,0.78-0.03,1.29c0.05,0,0.23,1.41-0.06,1.11c0.06,0.47-0.04,0.87-0.05,1.14l-0.02-0.25
				c0.01,0.15,0.01,0.27,0.01,0.39l0.06-0.14c-0.01,0.14-0.01,0.27-0.04,0.26c0.15,0.33,0.17,0.04,0.1,0.83
				c0.02-0.81-0.3-0.37-0.03,0.72c-0.04-0.08-0.08,0.14-0.08,0.24c0.01-0.19,0.12,0.09,0.13,0.73l0.02-0.23
				c0.1,0.78-0.15,1.39-0.03,4.8l0.02-0.24c0.1,0.68-0.11,0.7,0.1,3.16c-0.05-0.17-0.09,0.09-0.13-0.3
				c0.13,0.92,0.02-0.26,0.14,5.07c-0.01-0.01-0.01-0.01-0.02-0.02c0.06,1.19-0.03,0-0.04,1.88l-0.01-0.1
				c-0.02,0.47-0.03,0.14,0.2,2.75c-0.02-0.01-0.03-0.02-0.05-0.02c0.05,0.08,0.11,0.29-0.05,0.77l-0.01-0.41
				c-0.09,0.21,0.01,0.53,0,0.75l-0.04-0.03c0.02,0.63,0.12,0.64,0.13,1.32l0.03-0.07c0.12,0.94-0.22,0.39,0.07,2.09
				c-0.08-0.25-0.12-0.76-0.17-1.08c0,0.24,0.02,0.82,0.07,0.95c-0.01-0.1-0.04-0.22-0.02-0.32c0.17,2.02,0.04,0.08,0.01,5.47
				c0.14-0.36,0.01,0.29,0.16,0.23c0,0.62,0.06,0.17-0.24,1.4c0.05-0.07,0.08,0.17,0.07,0.46l0.06-0.41
				c0.1,0.71-0.11,0.62-0.02,1.33l-0.05-0.22c-0.01,0.71,0.05,0.7,0.22,4.33l-0.04-0.07c0.04,0.28-0.01,0.12,0.07,1.15l-0.05-0.18
				c-0.02,0.86,0.14,1.31,0.19,2.03c-0.11,0-0.13,0.68-0.13,1.01l-0.02-0.01c0.13,1.54,0.17,4.54,0.5,10.72l-0.07-0.01
				c0.01,0.61,0.01,0.91,0.06,1.66l-0.06-0.15c0.23,1.41,0.12,0.66,0.24,3.25c-0.02-0.01-0.05-0.03-0.06-0.15
				c0.01,0.35-0.01,1.34,0.13,1.21c-0.09,0.01,0.03,0.76,0.09,1.16l-0.07-0.06c0.03,0.04,0.09,0.51,0.2,1.5l-0.05-0.17
				c0.12,0.42,0,0.91,0.14,3.19c-0.03-0.51-0.04-0.91-0.03-0.88c-0.06,0-0.06,0.41-0.16,0.09c0.04,0.33,0.05-0.01,0.17,1.33
				l-0.01-0.04c0.04,0.55-0.02,0.31,0.03,1.26c0.07-0.02,0.07,0.25,0.12-0.01c0,0.06-0.01-0.1-0.03-0.26c0,0,0,0.01,0.01,0.01
				c-0.01-0.13-0.02-0.31-0.03-0.51c0.24,1.39-0.07,1.03,0.23,3.85c-0.02-0.1-0.02-0.22-0.04-0.28c-0.03,0.03,0.22,0.89,0.11,1.49
				l-0.03-0.08c-0.08,1.21,0.35,1.95,0.23,3.29c0.01-0.09,0.01-0.14,0.02-0.16c0.01,1.87-0.03-0.62,0.45,7.81l0.04-0.11
				c0.01,0.29,0.09,0.91,0.03,0.86c0.15,0.21-0.1,2.61,0.23,2.05c-0.02,0.36,0.35,0.44,0.27-0.77l0.03,0.39
				C641.74,449.17,641.58,449.61,641.47,446.57z M638.52,373.64l-0.04-0.8l0.09,0.52c0-0.24-0.04-0.51-0.03-0.66
				c0.05,0.25,0.03,0.22,0.08,0.1C638.61,373.23,638.56,373.43,638.52,373.64z M640,431.77c-0.02-0.13-0.01-0.18,0.01-0.2
				c0.01,0.06-0.01,0,0.03,0.25C640.02,431.81,640.01,431.8,640,431.77z M640.35,430.22c0.02,0.04,0.02-0.13,0.03-0.26
				C640.4,430.06,640.44,430.35,640.35,430.22z M640.43,430.79c0.01,0.09,0,0,0.02,0.21C640.45,430.96,640.44,430.9,640.43,430.79z"
                />
                <path
                  className="st10"
                  d="M726.99,452.77c-0.27-0.01-0.11,0-0.8,0.01l0.25-0.02c-0.26-0.14-1.01,0.03-1.52,0.03
				c0.15-0.11,0.11-0.13-1.22-0.12c0.07-0.02-0.06-0.07,0.16-0.06c-0.64-0.05-1.03,0.09-1.27,0.01l0.05-0.01
				c-1.88,0.04-0.98,0.15-5.18,0.05c0.02-0.1,0.12,0.05-1.11,0.04c0.04-0.03-1.07-0.04-1.6-0.07l0.02-0.02
				c-0.1,0.09-0.65,0.08-1.05,0.1c0.02-0.06,0.09-0.08,0.3-0.1c-0.06,0.02-0.02-0.04-0.82,0.06c0.29-0.18-0.2-0.05-1.91,0.11
				l0.27-0.06c-0.26-0.02-0.56,0.08-0.23-0.03c-1.29-0.04-0.57-0.02-2.9,0.05h0c-1.18,0.13-1.24-0.1-5.33,0.01
				c0.38-0.05-0.13-0.04,0.39-0.03c-0.5-0.02-0.61-0.06-1.14,0.02c0.18-0.06-0.37-0.08-0.61-0.06l0.35,0.04
				c-0.27,0.01-0.5,0.01-0.73,0l0.13-0.08c-1.02-0.03-2.26,0.01-4.66,0.11c0.6,0.18-1.29-0.18-0.96-0.02
				c-0.48-0.06-0.83,0.04-4.27-0.09l0.3-0.03c-0.18,0.02-0.32,0.02-0.47,0.02l0.17,0.06c-0.17-0.01-0.32-0.01-0.32-0.03
				c-0.41,0.16-0.05,0.17-1,0.11c0.98,0.01,0.45-0.3-0.88-0.02c0.09-0.05-0.17-0.08-0.29-0.07c0.22,0.01-0.12,0.12-0.87,0.14
				l0.28,0.01c-0.43,0.14-1.24-0.06-3.21-0.1c0.17,0.06,0.2,0.04-0.21,0.11l0.97-0.05l-0.62,0.09c0.29,0,0.62-0.04,0.79-0.03
				c-0.93,0.08-0.47-0.2-3.51,0l0.29,0.02c-0.83,0.1-0.86-0.11-3.81,0.12c0.21-0.06-0.11-0.09,0.36-0.13
				c-0.23,0-0.45-0.01-0.45,0.03c0.4,0.03-0.89,0.13-5.68,0.12c0.01-0.01,0.02-0.01,0.02-0.02c-1.43,0.06,0-0.03-2.27-0.04
				l0.12-0.01c-0.57-0.03-0.17-0.02-3.32,0.19c0.01-0.02,0.02-0.03,0.03-0.05c-0.1,0.05-0.35,0.1-0.93-0.05l0.5-0.01
				c-0.26-0.09-0.64,0.01-0.91,0l0.03-0.04c-0.76,0.02-0.77,0.12-1.59,0.12l0.08,0.03c-1.14,0.12-0.48-0.22-2.52,0.05
				c0.3-0.08,0.92-0.12,1.3-0.17c-0.29,0-0.99,0.02-1.15,0.07c0.12-0.01,0.27-0.04,0.38-0.02c-1.6,0.2-0.51-0.04-6.61-0.04
				c0.43,0.15-0.35,0.01-0.28,0.16c-0.14,0.01,0.01,0.01-0.41,0c0,0,0.01,0,0.02,0c-0.39-0.02-0.72-0.12-1.29-0.26
				c0.08,0.05-0.21,0.08-0.56,0.06l0.49,0.07c-0.86,0.09-0.74-0.12-1.6-0.03l0.27-0.05c-0.88-0.02-0.85,0.04-5.24,0.15l0.08-0.04
				c-0.34,0.03-0.14-0.02-1.39,0.05l0.22-0.04c-1.03-0.04-1.58,0.12-2.45,0.15c0-0.11-0.81-0.14-1.22-0.15l0.01-0.02
				c-1.98,0.11-5.63,0.08-12.96,0.23l0.01-0.07c-0.73-0.01-1.1-0.02-2,0.01l0.18-0.05c-1.72,0.19-0.81,0.1-3.93,0.14
				c0.01-0.02,0.04-0.05,0.18-0.05c-0.42,0-1.62-0.06-1.47,0.08c-0.01-0.09-0.92-0.01-1.4,0.03l0.07-0.07
				c-0.05,0.03-0.62,0.07-1.82,0.12l0.2-0.04c-0.69,0.08-0.21-0.01-3.85-0.07c0.61,0.01,1.1,0.03,1.06,0.03
				c0-0.06-0.49-0.08-0.1-0.17c-0.4,0.02,0.01,0.05-1.62,0.06l0.05-0.01c-0.66-0.01-0.37-0.05-1.51-0.09c0.02,0.07-0.3,0.05,0,0.12
				c-0.07-0.01,0.12,0,0.32-0.01l-0.02,0.01c0.16,0,0.37,0.01,0.61,0.01c-1.69,0.11-1.23-0.14-4.65-0.2
				c0.12-0.01,0.27,0.01,0.34-0.01c-0.02-0.03-1.1,0.1-1.8-0.1l0.1-0.02c-1.43-0.26-2.38,0.02-3.96-0.35
				c0.11,0.03,0.16,0.04,0.18,0.06c-2.17-0.56,0.66,0.47-9.25-1.75l0.11,0.08c-0.33-0.1-1.07-0.26-1-0.3
				c-0.45,0.05-2.85-1.14-2.4-0.62c-0.39-0.17-0.64,0.14,0.77,0.58l-0.45-0.14c0.4,0.17,1.52,0.62,4.51,1.17
				c-0.49,0.02,0.94,0.22,0.79,0.33c0.36-0.03,1.25,0.2,1.87,0.26c-0.04,0-0.11,0.01-0.16-0.01c-0.06,0.22,1.72,0.01,1.39,0.35
				c-0.01-0.08,0.75,0.04,0.61-0.05c0.31,0.11,0.27,0.07,0.43,0.18c-0.22-0.15,1.8,0.16,0.98,0.21c1.29-0.1,0.04,0.09,3.65,0.27
				c-0.03-0.19-0.06-0.13,0.46-0.08c0.17,0.36-0.25,0.22,4.38,0.42l-0.2,0.08c2.09-0.09-0.88,0.16,11.06,0.19l-0.15,0.02
				c0.59,0.03,0.67-0.15,1.21-0.09l-0.11,0.01c0.48,0.06,4.28-0.05,7.34,0.04c-0.12-0.1,0.95,0.02,3.18-0.07
				c-0.03,0.03-0.22,0.04-0.38,0.06c1.14-0.07,0.22-0.02,2.17-0.04l-0.09,0.05c3.65-0.06,3.5,0.08,16.06-0.2l-0.05,0.01
				c2.76-0.07,0.02-0.05,7,0.01l-0.05-0.04c1.56,0,0.45-0.2,8-0.12l-0.27,0.08c1.53-0.11,3.13-0.02,4.63-0.12
				c-0.54,0.08,1.39,0.06,5.3-0.1c-0.04,0.11,0.99,0.08,1.34,0.03c-0.12,0.04,0.31,0.03,0.17,0.08c0.41-0.01,0.78-0.03,0.71-0.09
				c1.37,0.11,1.03,0.06,5.33-0.11c-0.17,0.04-0.31,0.07-0.42,0.08c0.75-0.03,0.91,0.01,4.58-0.09c0,0.01-0.1,0.02-0.19,0.03
				c0.58-0.06-0.41,0.02,0.97-0.22c-0.3,0.15,0.9,0.03,0.42,0.22c0.12-0.01,0.27-0.04,0.24-0.07c0.18,0.01,0.23,0.03,0.05,0.07
				c0.65-0.03,0.42-0.09,1-0.09c0,0.02-0.12,0.04-0.18,0.07c0.3-0.05,0.59-0.1,1.09-0.09c-0.03,0.02,0.03,0.03-0.12,0.04
				c0.85-0.01,1.45-0.14,2.38-0.1c-0.13,0.06-0.23,0.13,0.87,0.09l-0.2-0.04c0.44,0,0.83-0.07,1.2-0.05l-0.33,0.04l0.82-0.02
				l-0.47-0.03c0.44-0.05-0.05-0.12,0.54-0.1c-0.12,0.01,0.44,0,0.67,0.05l0,0c0.87-0.08,0.32,0.16,2.05-0.04
				c-0.22,0.14,0.66,0.1,0.85,0.01c-0.06,0.01-0.03,0.01-0.15,0.03c0.77-0.02,1.09,0,1.35,0.02l-0.19,0.01
				c1,0.02-0.07,0.01,3.5-0.07l-0.17-0.01c0.52-0.22,0.21,0.26,3.33-0.04l-0.12,0.04c1.81-0.06,1.39,0.05,5.13-0.03
				c-0.3,0.04-0.44,0.04,0.74-0.01c-0.08-0.06-0.12-0.03-0.32-0.08c0.12-0.04,0.39-0.06,0.62-0.07c-0.43,0.04,0,0.09,0.19,0.12
				c-0.03-0.02,0.03-0.04,0.02-0.06c0.5,0.1,0.08-0.12,0.76-0.05c-0.07,0.06-0.2,0.11,1.54,0c-0.17,0.01-0.39,0.03-0.56,0.04
				c0.38,0.08,0.42,0.05,0.7,0.06l-0.03,0l0.65,0.03l-0.17-0.02c0.48-0.18,0.98-0.04,1.62-0.16c-0.63,0.11-0.1,0.06-0.15,0.14
				c0.17,0.02,0.42,0.04,0.35,0.06c0.24-0.01,0.17-0.11,1.2,0.01c-1.13-0.11,1.94-0.13,5.4-0.2
				C725.19,452.89,725.05,452.96,726.99,452.77z M614.78,453.2c0.16-0.05,0.08-0.04,0.31-0.01
				C615.01,453.2,615.09,453.18,614.78,453.2z M615.99,453.69c-0.11,0-0.2,0-0.25,0C615.78,453.69,615.73,453.69,615.99,453.69z
				 M616.68,453.66c-0.05,0.01,0.15,0.03,0.31,0.05C616.87,453.72,616.53,453.74,616.68,453.66z"
                />
                <path
                  className="st10"
                  d="M650.93,422.82c0.1-0.06,0.17,0.11,0.16,0.32l0.1-0.31c0.23,0.48-0.2,0.48,0.04,0.96l-0.12-0.15
				c0.02,0.34,0.07,0.45,0.9,3l-0.09-0.03c0.19,0.31-0.04,0.18,0.3,0.77l-0.12-0.1c0.09,0.62,0.5,0.83,0.71,1.3
				c-0.21,0.07-0.1,0.55-0.04,0.79l-0.03,0c0.26,0.96,0.31-0.15,1.7,3.56c0.02-0.05,0.29-0.05,0.36,0.35
				c-0.04-0.08-0.13,0.68,0.46,0.57c0.03,0.6,0.11,0.05,1.85,3.25l-0.15-0.03c0.84,0.55,0.36,0.2,1.59,1.72
				c-0.03,0.01-0.09,0.04-0.15-0.02c0.15,0.19,0.5,0.83,0.67,0.58c-0.14,0.13,0.33,0.43,0.58,0.59l-0.13,0.06
				c0.04-0.03,0.32,0.17,0.9,0.63l-0.15-0.03c0.3,0.26,0.07,0.11,1.53,1.67c-0.25-0.26-0.44-0.48-0.42-0.46
				c-0.09,0.09,0.09,0.31-0.2,0.27c0.2,0.14,0.05-0.08,0.81,0.54l-0.03-0.01c0.3,0.26,0.1,0.21,0.58,0.69
				c0.09-0.11,0.21,0.04,0.16-0.17c0.02,0.04-0.05-0.04-0.16-0.1c0,0,0.01,0,0.02,0c-0.07-0.07-0.16-0.15-0.26-0.25
				c0.95,0.43,0.25,0.62,2.01,1.85c-0.34-0.13-0.29-0.17,0.64,0.57l-0.08,0c0.44,0.79,1.35,0.57,1.81,1.47
				c-0.03-0.06-0.05-0.09-0.04-0.12c0.08,0.18,2,1.58,4.23,3.55l0.01-0.12c3.6,4.69-2.15-6.88,1.52-1.23
				c-0.17-0.02-0.41-0.07,0.37,0.83l-0.01-0.16c-0.05,0.05-0.31,0.07-0.16-0.27l0.09,0.12c0.21-0.25-0.28-0.52-0.38-0.8
				c0.3,0.15,0.41-0.07-0.06-0.75c0.06,0.02,0.12-0.09,0.14,0.04c-0.04-0.39-0.39-0.49-0.3-0.68l0.02,0.02
				c-0.24-0.53-0.26-1.02-1.63-3.3c0.06-0.01-0.19-0.6-0.27-0.91l0.03-0.01c-0.19,0.03-0.3-0.27-0.44-0.46
				c0.1-0.04,0.16-0.02,0.26,0.07c-0.04-0.01,0.05-0.07-0.32-0.38l0.15-0.01c-0.14-0.25-0.41-0.44-0.5-0.81
				c-0.03,0.01-0.11,0.03-0.19,0.04c0-0.02,0-0.03,0.01-0.05l0.03,0.02c-0.04-0.09-0.04-0.05,0.15-0.01c0,0,0,0,0,0
				c0.02,0.02,0,0,0.05,0.05c-0.75-1.32-0.1-0.12-1.32-3.12c-0.21,0.11-0.26,0.22-0.36-0.33c0.49,0.75,0.37-0.17,0.16-0.2
				c0.52-0.65-0.57-1.6-1.08-3.77l0.23,0.12c-0.65-1.79-0.17,0.2-2.35-9.94l0.07,0.13c-0.04-0.55-0.4-0.55-0.36-1.08l0.04,0.09
				c-0.12-0.45-0.4-3.81-0.96-6.72l0.15-0.03c-0.17,0.21-0.07-0.82-0.38-2.92c0.06,0.02,0.1,0.2,0.15,0.34
				c-0.21-1.02-0.03-0.17-0.23-2l0.11,0.08c-0.25-3.27-0.28-3.69-0.08-14.78l0.01,0.05c0.33-2.46-0.39-0.12,1.31-6.24l-0.1,0.02
				c-0.03-0.23,0.12-0.24,0.19-0.38c-0.27,0.38,0.27-3.23,1.39-0.69l-0.25-0.17c0.75,1.22,1.06,2.69,1.73,3.92
				c-0.07-0.06-0.11-0.05-0.16-0.17c0,0.31,0.37,1.01,0.46,0.99c0.11,0.85,0.21,0.87,1.39,3.79c-0.23,0.03,0.1,0.93,0.29,1.21
				c-0.1-0.09,0.02,0.29-0.11,0.2c0.11,0.36,0.26,0.67,0.35,0.58c0.13,1.26,0.13,0.91,1.44,4.72c-0.14-0.17-0.22-0.29-0.28-0.39
				c0.19,0.61,0.19,0.81,1.09,4.14c0.02-0.02,0.05-0.11,0.5,0.63c-0.34-0.22,0.09,0.84-0.36,0.47c0.04,0.1,0.11,0.23,0.18,0.19
				c0.01,0.16-0.02,0.22-0.13,0.08c0.17,0.58,0.24,0.35,0.34,0.88c-0.05,0.01-0.09-0.1-0.16-0.14c0.15,0.25,0.3,0.5,0.34,0.96
				c-0.03-0.02-0.06,0.04-0.09-0.1c0.17,0.77,0.48,1.28,0.53,2.14c-0.13-0.12-0.29-0.11-0.07,0.82l0.06-0.2
				c0.06,0.4,0.25,0.74,0.25,1.09l-0.12-0.29c0.05,0.25,0.11,0.5,0.15,0.75l0.01-0.44c0.16,0.39,0.24-0.08,0.25,0.47
				c-0.03-0.11,0.05,0.4-0.03,0.63l0,0c0.28,0.86-0.31,0.25,0.28,1.88c-0.27-0.18-0.15,0.62,0.07,0.78
				c-0.02-0.05-0.03-0.02-0.07-0.13c0.1,0.73,0.09,1.03,0.07,1.26l-0.04-0.19c0.03,0.92-0.03-0.07,0.37,3.22l0.01-0.16
				c0.27,0.97-0.34,0.63,0.2,3.08l-0.07-0.11c0.15,1.65-0.06,1.3,0.1,4.75c-0.07-0.27-0.08-0.42-0.01,0.68
				c0.12-0.07,0.07-0.12,0.16-0.3c0.07,0.11,0.12,0.36,0.11,0.58c-0.07-0.4-0.19,0-0.25,0.17c0.04-0.03,0.07,0.03,0.11,0.02
				c-0.21,0.46,0.23,0.08,0.08,0.71c-0.11-0.05-0.23-0.12-0.04,1.43c-0.04-0.15-0.03-0.37-0.07-0.52c-0.16,0.37-0.03,0.13-0.24,1.22
				l0.05-0.15c0.33,0.46,0.02,0.91,0.22,1.52c-0.19-0.59-0.12-0.1-0.27-0.15c-0.05,0.15-0.11,0.38-0.15,0.31
				c0.11,0.29-0.12,0.47,0.04,0.88c-0.03-0.27,0.08,2.25-0.24,5.24c-0.15-0.2-0.3-0.4-0.16,1.44l0.08-0.23
				c-0.09,0.01-0.28-0.14,0.02-0.51l0,0.24c0.31-0.21,0.07-0.94,0.14-1.41c0.14,0.05,0.3,0.12,0.35-1.11
				c0.04,0.07,0.15-0.04,0.1,0.16c0.17-0.58-0.08-0.97,0.1-1.18l0.01,0.05c0.35-1.64-0.2-1.61,0.29-4.82
				c0.2,0.03-0.1,0.17-0.02-1.04c0.06,0.04,0.11-0.99,0.18-1.49l0.03,0.01c-0.18-0.1-0.14-0.61-0.17-0.98
				c0.11,0.02,0.15,0.08,0.2,0.28c-0.03-0.05,0.09-0.03-0.1-0.77c0.36,0.23,0.13-0.16-0.21-1.78l0.12,0.25
				c-0.05-0.11-0.02-0.82,0.06-0.22c0.07-1.11,0.04-0.46-0.12-2.7l0,0c-0.31-1.14,0.18-1.06-0.26-4.95c0.12,0.35,0.06-0.13,0.1,0.36
				c0-0.46,0.07-0.58-0.11-1.06c0.14,0.16,0.13-0.36,0.07-0.58l-0.06,0.33c-0.04-0.24-0.05-0.46-0.06-0.68l0.18,0.1
				c0-0.99-0.22-2.11-0.66-4.3c-0.34,0.49,0.24-1.18-0.07-0.9c0-0.57-0.14-0.86-0.17-1.44c-0.08,0.08-0.72-1.54-0.1-1.26
				c-0.19-0.5-0.09-0.98-0.13-1.28l0.1,0.26c-0.06-0.16-0.09-0.29-0.11-0.43l-0.09,0.18c-0.01-0.16-0.04-0.3,0.01-0.3
				c-0.29-0.29-0.38,0.07-0.39-0.88c0.08,0.66,0.14-0.05,0.27,0.42c-0.04-0.69,0.1-0.39-0.37-1.23c0.1,0.07,0.13-0.19,0.09-0.29
				c0.04,0.21-0.24,0-0.41-0.75l0.02,0.26c-0.35-0.38-0.12-1.15-0.42-2.98c-0.08,0.18-0.05,0.2-0.25-0.15l0.29,0.86l-0.3-0.53
				c0.06,0.27,0.2,0.54,0.22,0.71c-0.16-0.25-0.12-0.23-0.19-0.08c-0.26-1.29,0.43-0.8-0.54-3.12l0.02,0.27
				c-0.39-0.69,0.03-0.79-1.13-3.37c0.16,0.16,0.14-0.15,0.34,0.25c-0.36-0.61,0.1,0.38-1.94-5.37c0.02,0.01,0.03,0,0.04,0.01
				c-0.55-1.23,0.08-0.02-0.61-2.03l0.06,0.1c-0.13-0.51-0.03-0.18-1.42-2.77c0.05-0.01,0.07-0.01,0.1-0.01
				c-0.12-0.05-0.31-0.23-0.22-0.84l0.19,0.42c0.07-0.29-0.24-0.55-0.31-0.79l0.08,0c-0.22-0.47-0.22-0.17-0.87-1.49l0,0.16
				c-0.52-0.77-0.57-2.7-1.15-0.23c-0.01-0.07,0.17-0.78,0.47-1.71c-0.1-0.03-0.14,0.16-0.35,0.84c0.02-0.05,0.04-0.09,0.06-0.11
				c-0.82,2.69-0.19,0.42-2.31,6.45c0.37-0.33-0.06,0.33,0.25,0.32c-0.01,0.14,0,0-0.08,0.38c0,0,0,0,0-0.01
				c-0.12,0.36-0.38,0.62-0.73,1.09c0.12-0.06,0.12,0.22,0.03,0.54l0.21-0.44c0.04,0.82-0.36,0.65-0.3,1.48l-0.06-0.27
				c-0.11,0.69-0.11,0.79-0.17,4.92l-0.07-0.08c0.05,0.32-0.04,0.14,0.03,1.3l-0.08-0.21c-0.13,0.97,0.19,1.49,0.24,2.29
				c-0.22,0-0.29,0.76-0.33,1.14l-0.03-0.01c0.23,3.35-0.28,1.45,1.08,12.06l-0.14,0c0.04,0.68,0.07,1.03,0.23,1.86l-0.13-0.16
				c0.58,1.49,0.26,0.66,0.79,3.59c-0.03-0.01-0.1-0.02-0.13-0.15c0.06,0.39,0.12,1.52,0.38,1.32c-0.19,0.04,0.13,0.85,0.31,1.27
				l-0.15-0.04c0.06,0.02,0.25,0.52,0.59,1.61l-0.12-0.17c0.13,0.45,0.27,1.32,0.75,3.52c-0.12-0.56-0.22-1.01-0.2-0.97
				c-0.13,0.03-0.04,0.48-0.3,0.16c0.13,0.36,0.09-0.04,0.53,1.43l-0.02-0.04c0.18,0.59,0.01,0.35,0.27,1.39
				c0.13-0.05,0.18,0.24,0.23-0.06c0.01,0.07-0.03-0.11-0.11-0.28l0.02,0.01c-0.04-0.15-0.09-0.34-0.15-0.55
				c0.73,1.43-0.01,1.11,1.16,4.19c-0.25-0.45-0.23-0.49,0.38,1.11c-0.03-0.03-0.07-0.07-0.12-0.11c0.16,0.03,0.03-0.27-0.08-0.37
				l0.03,0.22c-0.1-0.12-0.16-0.25-0.22-0.37l0.18-0.02c-0.27-0.6-0.67-1.16-1.55-2.26c-0.18,0.44-0.04-0.8-0.25-0.51
				c-0.12-0.34-0.3-0.46-0.44-0.8c-0.01,0.12-0.98-0.64-0.34-0.72c-0.28-0.21-0.28-0.56-0.37-0.72l0.14,0.12
				c-0.08-0.07-0.13-0.14-0.18-0.21l-0.04,0.15c-0.04-0.1-0.09-0.17-0.04-0.19c-0.23-0.08-0.33,0.27-0.5-0.37
				c0.19,0.37,0.11-0.09,0.31,0.14c-0.04-0.05-0.13-0.16-0.06-0.18c-0.11-0.42-0.25-0.06-0.49-0.41c0.1-0.01,0.08-0.18,0.03-0.22
				c0.08,0.1-0.17,0.15-0.5-0.28l0.07,0.15c-0.4-0.1-0.29-0.66-0.87-1.69c-0.04,0.15-0.01,0.15-0.24,0.02l0.4,0.41l-0.35-0.2
				c0.1,0.14,0.27,0.25,0.31,0.35c-0.18-0.09-0.15-0.09-0.18,0.04c-0.5-0.73,0.27-0.62-0.97-1.74l0.06,0.17
				c-0.14,0.01-0.21-0.25-0.35-0.36c0.09-0.05,0.05-0.15,0.06-0.25c-0.05,0.05-0.22-0.32-0.41-0.24c-0.15-0.49,0.05-0.32-0.78-0.83
				c0.16,0.03,0.1-0.16,0.33,0.01c-0.46-0.32,0.13,0.19-2.44-2.66c0.02,0,0.03-0.01,0.03-0.01c-0.64-0.54,0.05-0.04-0.84-1.06
				l0.06,0.04c-0.19-0.26-0.05-0.16-1.65-1.12c0.04-0.03,0.05-0.04,0.08-0.06c-0.11,0.03-0.28,0.01-0.34-0.45l0.23,0.18
				c0-0.23-0.3-0.24-0.4-0.36l0.06-0.05c-0.38-0.26-0.5-0.11-0.88-0.41l0.01,0.08c-0.69-0.32-0.05-0.38-1.41-0.86
				c0.12-0.11,0.55,0.09,0.87,0.19c-0.22-0.14-0.77-0.48-0.84-0.4c0.08,0.02,0.18,0.03,0.27,0.12c-1.19-0.52-1.38,0.5-1.22,3.1
				c0.27-0.28,0.03,0.21,0.33,0.14c-0.02,0.2,0.11,0.3-0.29,0.24c0.1-0.24,0.19,0.33,0.29,0.02
				C651.33,422.3,651.16,422.41,650.93,422.82z M665.33,431.79c-0.11-0.08-0.12-0.05-0.1-0.27
				C665.26,431.58,665.2,431.53,665.33,431.79z M665.69,429.87c0.04,0.04,0.02-0.16,0.03-0.3
				C665.75,429.68,665.88,430.02,665.69,429.87z M665.93,430.48c0.03,0.09,0,0,0.06,0.23C665.98,430.65,665.96,430.59,665.93,430.48
				z M662.16,441.5c0.06,0.05,0.26,0.19,0.06,0.2C662.26,441.69,662.2,441.59,662.16,441.5z M662.44,443.05
				c-0.03,0.01,0,0-0.08,0.02C662.3,443.02,662.22,442.85,662.44,443.05z M662.57,441.91c0.05,0.04,0,0,0.11,0.1
				C662.66,441.98,662.63,441.96,662.57,441.91z M670.81,445.75c0.02,0.02,0.04,0.05,0.08,0.07c0,0,0,0.01,0,0.01
				C670.87,445.81,670.84,445.79,670.81,445.75z M670.9,445.09c-0.3-0.19-0.19-0.05-0.31,0.25c-0.14-0.33-0.26-0.73-0.44-1.02
				c0.01,0.04,0.03-0.03,0.09,0.15l0-0.04c0,0,0,0.01,0,0.01c-0.01-0.03,0,0-0.01-0.08C670.66,444.46,670.53,444.85,670.9,445.09z
				 M670.72,445.62c-0.04-0.06-0.07-0.14-0.11-0.21C670.68,445.46,670.7,445.54,670.72,445.62z M670.29,443.94
				c-0.08-0.07-0.14-0.19-0.21-0.26C670.16,444.37,669.51,442.75,670.29,443.94z M669.53,442.67c0.01-0.14,0-0.2,0.01-0.25
				c0.06,0.05,0.12,0.11,0.16,0.19C669.57,442.48,669.54,442.57,669.53,442.67z M653.78,420.79l-0.22,0.03
				c0.8,0.4,1.33,1.17,2.04,1.66c-0.06-0.01-0.1,0.02-0.16-0.04c0.04,0.21,0.46,0.49,0.53,0.42c-0.07,0.03,0.48,0.68,1.72,1.83
				c-0.19,0.13,0.22,0.55,0.43,0.65c-0.1-0.01,0.05,0.18-0.07,0.18c0.15,0.18,0.31,0.33,0.38,0.22c0.28,0.75,0.21,0.5,1.96,2.4
				c-0.79-0.22-0.84-0.87,1.34,2.01c-0.02,0.01-0.07-0.02-0.11-0.06c0.25,0.18-0.05-0.02,0.68,0.22c-0.34,0.02,0.24,0.48-0.23,0.45
				c0.06,0.05,0.14,0.09,0.19,0.04c0.04,0.1,0.03,0.14-0.1,0.1c0.26,0.28,0.27,0.11,0.46,0.39c-0.04,0.03-0.1-0.02-0.16-0.02
				c0.18,0.09,0.35,0.18,0.48,0.44c-0.03,0-0.05,0.05-0.1-0.02c0.29,0.4,0.68,0.56,0.9,1.07c-0.27-0.02,0.04,0.19-0.1,0.32l0.2,0.21
				l0.01-0.15c0.14,0.22,0.37,0.33,0.44,0.55l-0.17-0.12l0.29,0.38l-0.08-0.27c0.66,0.45,0.15-0.12,1.1,1.55
				c-0.21-0.08-0.12,0.42,0.23,0.44c-0.03-0.02-0.03,0-0.09-0.05c0.26,0.39,0.32,0.57,0.36,0.72l-0.08-0.1
				c0.24,0.5-0.05-0.04,1.1,1.72l-0.03-0.1c0.27-0.07,0.09,0.42,0.33,0.59c-0.23,0.2,0.09,0,0.58,1.06l-0.03-0.01
				c0.03,0.06,0.07,0.08,0.15,0.74c-0.01-0.11-0.02-0.16,0.01-0.19c0.47,2.04-0.27-0.62,2.8,8.33l0.06-0.14
				c0.08,0.28,0.35,0.81,0.31,0.91c-0.05-0.06-0.09-0.11-0.03-0.17c-0.07,0.01-0.33-0.04-0.46-0.13c0.38-0.03-0.43-0.36-0.2-0.54
				c-0.31,0.06-0.63-0.38-1-0.5c0.03,0,0.08-0.01,0.1,0.02c-0.14-0.14-0.14-0.23-0.22-0.35c0.17,0.16-1.18-0.07-0.3-0.32
				c-0.09,0.13-0.43-0.15-0.45,0c-0.07-0.21-0.09-0.15-0.06-0.33c-0.05,0.29-0.93-0.52-0.36-0.47c-0.3,0.22-0.86-0.77-2.18-1.11
				c0.35-0.79,0.33-0.14-1.75-1.86l0.21-0.06c-1.12-0.63-1.64-1.64-4.47-4.56l0.09,0.04c-0.17-0.3-0.48-0.1-0.57-0.44
				c0.27-0.04-0.04-0.05-3.33-4.99c0.06-0.02,0.14,0.07,0.22,0.13c-0.32-0.23,0.11-0.14-0.18-0.35c-0.01,0.06-0.09,0.03-0.12,0.03
				c0-0.19-0.19-0.51-0.35-0.74l0.12,0c-0.2-0.43-0.52-0.87-0.75-1.36c-0.28-0.95,0.1,0.2-2.5-7.17l0.02,0.03
				c-0.2-0.73-0.41-1.53-0.27-3.96c-0.04,0.01-0.22,0.01-0.02-0.05C651.98,419.27,652.45,420.47,653.78,420.79z"
                />
                <path
                  className="st10"
                  d="M675.21,437.47c-0.09,0.43-0.14,0.64-0.18,0.8c-0.03-0.04-0.17,0.22-0.13-0.32l-0.07,0.59
				c0.06-0.1,0.14-0.23,0.14,0.04c0.01-0.02,0.01-0.03,0.01-0.04c-0.06,0.48-0.05,1.42-0.37,7.2l0.1-0.11
				c-0.43-0.18,0.44,3.91,1.5,1.63l-0.25,0.28c0.53-0.22,0.85-1.52,1.15-1.2c0.02-0.18,0.49-0.14,1.29-1.6
				c-0.06,0.41,0.42-0.35,0.62-0.33c-0.1,0.18-0.3,0.25-0.36,0.59c0.1-0.22-1.1,1.82-2.92,3.98c0.01-0.48,0.16-0.55-0.38,0.14
				c-0.08-0.25-0.11,0.6-1.13,0.86c0.11-0.23,0.2-0.33-1.14,0.22c0-0.05-0.02,0-0.01-0.18c0,0-0.01,0-0.01,0
				c0.15-0.32-0.24-0.14,0.06,0.29c-0.01-0.01-0.01-0.02-0.02-0.05l0.12,0c-0.04-0.08,0.03-0.32,0.42-0.13l-0.19,0.07
				c0.27,0.24,0.78-0.23,1.17-0.32c-0.29,0.4,0.17-0.13,0.25,0.18c0.35-0.01,0.05,0.11,0.76-0.21c-0.04,0.06,0.09,0.13-0.09,0.15
				c0.53-0.03,0.74-0.4,0.98-0.31l-0.04,0.03c0.8-0.29,2.24-0.69,4.68-1.97c0,0.07,0.83-0.27,1.24-0.39l0,0.03
				c0-0.2,0.41-0.36,0.68-0.53c0.04,0.1,0,0.17-0.13,0.29c0.02-0.05,0.07,0.06,0.55-0.38c-0.02,0.4,0.17,0.06,1.31-0.83l-0.15,0.2
				c0.07-0.08,0.64-0.31,0.19-0.03c0.91-0.32,0.39-0.14,2.08-1.06l0,0c0.55-0.43,0,0,1.88-0.91c0.08,0.19,0.59-0.3,1.98-0.96
				c-0.23,0.22,0.13,0.02-0.25,0.2c0.38-0.15,0.49-0.12,0.81-0.44c-0.07,0.18,0.34,0,0.49-0.12l-0.29,0.05
				c0.18-0.12,0.35-0.2,0.52-0.28l-0.01,0.19c0.79-0.35,1.64-0.86,3.21-1.98c-0.53-0.15,1.05-0.16,0.7-0.35
				c0.46-0.18,0.64-0.4,1.1-0.62c-0.11-0.03,0.95-1.18,0.97-0.51c0.32-0.34,0.75-0.41,0.98-0.54l-0.17,0.18
				c0.1-0.11,0.2-0.17,0.3-0.24l-0.18-0.02c0.13-0.06,0.23-0.13,0.25-0.09c0.12-0.32-0.24-0.32,0.55-0.64
				c-0.5,0.29,0.1,0.11-0.22,0.38c0.54-0.27,0.36-0.04,0.83-0.75c-0.01,0.12,0.21,0.06,0.28-0.02c-0.15,0.11-0.12-0.19,0.41-0.63
				l-0.2,0.11c0.15-0.46,0.88-0.48,2.2-1.42c-0.18,0-0.18,0.03,0-0.27l-0.55,0.57l0.28-0.45c-0.18,0.15-0.34,0.38-0.46,0.45
				c0.12-0.23,0.12-0.19-0.03-0.19c0.92-0.72,0.81,0.09,2.22-1.62l-0.21,0.12c0.83-0.87,0.34-0.02,2.07-2.3
				c-0.04,0.2,0.19,0.06-0.01,0.39c0.35-0.71-0.22,0.21,3.04-3.94c0,0.02,0.01,0.03,0.02,0.03c0.59-1.02,0.07,0.06,1.14-1.46
				l-0.04,0.09c0.28-0.35,0.18-0.1,1.1-2.48c0.04,0.03,0.05,0.05,0.07,0.07c-0.05-0.11-0.04-0.35,0.46-0.61l-0.18,0.36
				c0.25-0.1,0.25-0.46,0.36-0.64l0.06,0.06c0.24-0.58,0.07-0.67,0.36-1.26l-0.08,0.04c0.15-0.97,0.56-0.11,0.69-1.92
				c0.06,0.28-0.04,0.78-0.09,1.1c0.1-0.22,0.29-0.76,0.23-0.91c-0.01,0.1-0.01,0.23-0.07,0.31c-0.05-0.33,0-0.72,0.16-1.16
				c0.38,0.32,1.23-2.78,1.5-4.69c-0.43,0.81,0.06-0.44-0.32,0.02c0.01-0.07,0.01-0.12,0.02-0.17c-0.01,0.04-1.31,0.26-0.66-0.16
				c-0.79,0.2-0.28-0.05-0.86,0.02c0.07,0.03-0.21,0.23-0.61,0.3c0.07-0.01-0.03,0.02,0.53-0.02c-0.52,0.25-0.26,0.02-1.39,0.35
				l0.18-0.16c-0.55,0.16-0.67,0.19-3.86,1.88l0.02-0.1c-0.37,0.29-0.26,0.02-0.97,0.59l0.12-0.16c-0.79,0.33-1.03,0.8-1.6,1.22
				c-0.11-0.19-0.72,0.09-1.01,0.24l-0.01-0.03c-2.31,1.76-1.39,0.53-8.18,6.6l-0.08-0.11c-0.47,0.37-0.7,0.54-1.22,1.06l0.04-0.17
				c-0.75,1.15-0.3,0.52-2.2,2.29c-0.01-0.03-0.04-0.09,0.04-0.18c-0.25,0.22-1.06,0.78-0.77,0.91c-0.14-0.13-0.56,0.49-0.78,0.81
				l-0.05-0.14c0.02,0.06-0.26,0.42-0.89,1.19l0.06-0.18c-0.28,0.3-0.85,0.8-2.27,2.12c0.36-0.34,0.65-0.61,0.63-0.57
				c-0.09-0.09-0.39,0.17-0.29-0.18c-0.21,0.26,0.08,0.06-0.8,1.05l0.02-0.04c-0.37,0.4-0.27,0.15-0.93,0.79
				c0.11,0.09-0.09,0.25,0.16,0.17c-0.05,0.03,0.07-0.07,0.16-0.2l0,0.02c0.09-0.09,0.21-0.21,0.35-0.35
				c-0.72,1.19-0.83,0.45-2.64,2.67c0.2-0.36,0.27-0.41-0.54,0.67c0.03-0.09-0.01,0,0.09-0.33l0,0c0.2-0.83,0.66-0.75,2.3-4.06
				c-0.08,0.34,0.12-0.07-0.1,0.34c0.23-0.37,0.35-0.42,0.44-0.9c0.04,0.2,0.29-0.22,0.35-0.42l-0.22,0.23
				c0.09-0.22,0.19-0.39,0.29-0.57l0.1,0.17c0.5-0.79,0.9-1.79,1.62-3.77c-0.53,0.2,0.8-0.79,0.41-0.74
				c0.28-0.45,0.31-0.76,0.57-1.24c-0.11,0.03,0.16-1.61,0.55-1.05c0.08-0.5,0.43-0.83,0.54-1.09l-0.05,0.26
				c0.03-0.16,0.07-0.28,0.12-0.4l-0.16,0.1c0.07-0.14,0.12-0.26,0.16-0.24c-0.09-0.37-0.37-0.14,0.11-0.91
				c-0.26,0.57,0.14,0.03,0.02,0.47c0.31-0.58,0.28-0.26,0.29-1.18c0.06,0.11,0.21-0.08,0.23-0.19c-0.07,0.19-0.21-0.11,0.02-0.81
				l-0.11,0.22c-0.1-0.48,0.49-0.98,1.14-2.6c-0.16,0.11-0.14,0.14-0.14-0.24l-0.18,0.84l0-0.57c-0.08,0.24-0.09,0.54-0.16,0.68
				c-0.01-0.28,0.01-0.24-0.12-0.16c0.42-1.17,0.74-0.42,1.1-2.77l-0.12,0.23c0.29-1.27,0.27-0.23,0.71-3.28
				c0.06,0.21,0.2-0.05,0.17,0.37c-0.02-0.88-0.11,0.3,0.98-5.29c0.01,0.02,0.02,0.02,0.03,0.03c0.13-1.27,0.05,0.02,0.49-1.94
				l0,0.11c0.15-0.47,0.06-0.17,0.12-2.93c0.05,0.02,0.07,0.03,0.1,0.04c-0.08-0.1-0.16-0.33,0.24-0.79l-0.06,0.43
				c0.21-0.2,0.08-0.56,0.13-0.79l0.07,0.04c0.05-0.67-0.15-0.7-0.06-1.41l-0.07,0.07c-0.12-1.04,0.49-0.31,0.16-2.21
				c0.13,0.28,0.14,0.82,0.19,1.17c0.03-0.26,0.08-0.87-0.01-1.01c0.01,0.11,0.04,0.24,0,0.34c-0.36-2.44,0.85-1.06-3.93-2.9
				c0.51,0-0.27,0.21-0.01,0.41c-0.11,0.11,0,0-0.29,0.24l0,0c-0.33,0.23-0.58,0.24-1.19,0.51c0.13,0.01,0,0.25-0.22,0.46l0.38-0.25
				c-0.34,0.7-0.6,0.35-0.95,1.05l0.07-0.25c-0.42,0.48-0.46,0.56-2.59,3.83l-0.02-0.1c-0.12,0.28-0.11,0.09-0.64,1.05l0.04-0.21
				c-0.58,0.71-0.59,1.29-0.96,1.95c-0.18-0.12-0.63,0.44-0.86,0.73l-0.02-0.03c-1.43,2.81-1.13,1.18-5.03,10.25l-0.13-0.07
				c-0.28,0.58-0.42,0.87-0.69,1.62l-0.03-0.19c-0.22,1.49-0.09,0.66-1.02,3.32c-0.02-0.02-0.07-0.06-0.04-0.19
				c-0.13,0.34-0.62,1.3-0.29,1.27c-0.18-0.05-0.26,0.77-0.31,1.2l-0.11-0.1c0.04,0.04-0.03,0.54-0.23,1.6l-0.03-0.19
				c-0.1,0.43-0.36,1.22-0.92,3.26c0.14-0.52,0.26-0.94,0.26-0.89c-0.12-0.03-0.26,0.38-0.34,0c-0.05,0.36,0.1,0.01-0.17,1.43
				l0-0.05c-0.11,0.58-0.15,0.3-0.37,1.29c0.14,0.01,0.06,0.29,0.23,0.05c-0.02,0.06,0.02-0.1,0.02-0.28l0.01,0.02
				c0.03-0.14,0.06-0.32,0.11-0.53c0.04,1.52-0.5,0.92-0.77,4.03c-0.05-0.52,0.01-0.49-0.21,1.28l-0.04-0.1
				c-0.53,1.22,0.16,2.12-0.39,3.48C675.16,437.54,675.17,437.49,675.21,437.47z M687.37,434.24c0-0.11-0.02-0.12,0.15-0.2
				C687.49,434.1,687.49,434.03,687.37,434.24z M676.35,429.33c-0.06-0.11-0.08-0.1,0.02-0.27
				C676.38,429.12,676.34,429.06,676.35,429.33z M680.09,443.18c-0.02,0.47-0.45,0.67-0.59,1.23c0.12-0.46-0.02-0.26-0.35-0.13
				c0.15-0.33,0.59-0.69,0.8-1.07c-0.01,0.03-0.02,0.1-0.05,0.12C679.97,443.25,680.03,443.21,680.09,443.18z M680.94,442.08
				c0.02-0.01,0.03-0.01,0.04-0.02c-0.01,0.02-0.02,0.04-0.03,0.06C680.95,442.1,680.96,442.08,680.94,442.08z M681.03,442.04
				c0.07-0.03,0.11-0.05,0.14-0.06C681.09,442.15,681.01,442.25,681.03,442.04z M680.81,442.53c0.01-0.05,0.03-0.1,0.06-0.15
				C680.86,442.43,680.83,442.48,680.81,442.53z M680.81,442.53c-0.05,0.11-0.1,0.21-0.2,0.27c0.04-0.12,0.13-0.26,0.18-0.39
				C680.8,442.45,680.8,442.48,680.81,442.53z M680.39,442.74c0.11-0.28,0.3-0.61,0.39-0.36
				C680.54,442.53,680.51,442.59,680.39,442.74z M689.28,433.65c-0.07,0.07-0.26,0.34-0.25,0.11
				C689.02,433.8,689.16,433.7,689.28,433.65z M688.7,434.21c-0.05,0.07,0,0-0.14,0.15C688.59,434.33,688.63,434.29,688.7,434.21z
				 M686.52,436.38l0.03,0.24c1.02-1.29-0.21,0.1,6.35-6.19l-0.06,0.11c0.39-0.28,0.19-0.58,0.61-0.78l-0.04,0.08
				c0.67-0.62,1.15-0.81,6.39-5.5c0.02,0.06-0.08,0.18-0.15,0.29c0.29-0.44,0.17,0.07,0.44-0.34c-0.07,0.01-0.03-0.09-0.04-0.12
				c0.24-0.07,0.64-0.4,0.92-0.67l0.01,0.13c0.54-0.39,1.08-0.9,1.68-1.34c0.91-0.82,1.74-1.07,9.12-5.24l-0.03,0.03
				c0.43-0.3,0.98-0.31,2.04-0.76c-1,2.7,0.49,0.87-2.44,7.39l-0.05-0.26c-0.38,1.16-1.17,2.16-1.69,3.23
				c0.01-0.08-0.03-0.11,0.03-0.21c-0.24,0.14-0.51,0.77-0.44,0.83c-0.15,0.05-0.46,0.52-2.04,2.8c-0.15-0.17-0.64,0.48-0.76,0.75
				c0.01-0.12-0.22,0.13-0.21-0.01c-0.21,0.24-0.38,0.48-0.26,0.52c-0.92,0.6-0.59,0.5-2.96,3.03c0.01-0.1,0.04-0.19,0.08-0.27
				c-0.53,0.45-0.54,0.5-2.69,2.31c-0.01-0.02,0.04-0.08,0.09-0.14c-0.25,0.33,0.03-0.05-0.37,0.81c0.02-0.38-0.63,0.37-0.53-0.16
				c-0.06,0.07-0.13,0.18-0.07,0.22c-0.13,0.07-0.18,0.06-0.11-0.09c-0.38,0.35-0.17,0.33-0.56,0.59c-0.03-0.04,0.04-0.11,0.05-0.19
				c-0.14,0.21-0.28,0.43-0.63,0.62c0-0.04-0.06-0.04,0.04-0.11c-0.55,0.39-0.82,0.86-1.5,1.19c0.05-0.16-0.04-0.3-0.7,0.2
				l0.18-0.01c-0.3,0.18-0.49,0.47-0.78,0.57l0.18-0.2l-0.55,0.37l0.36-0.13c-0.26,0.27,0.16,0.18-0.27,0.38
				c0.07-0.07-0.3,0.18-0.52,0.18l0,0c-0.6,0.55-0.31-0.21-1.41,0.86c0.04-0.3-0.56,0.05-0.61,0.3c0.04-0.03,0.01-0.04,0.08-0.1
				c-0.54,0.32-0.79,0.41-0.98,0.47l0.13-0.09c-0.72,0.32,0.05-0.05-2.44,1.37l0.13-0.05c-0.66,0.58-0.61-0.08-2.38,1.22l0.06-0.1
				c-1.24,0.7-1.06,0.39-3.72,1.76c0.18-0.16,0.29-0.23-0.53,0.26c0.1,0.08,0.12,0.02,0.3,0.03c-0.06,0.1-0.23,0.24-0.4,0.32
				c0.28-0.22-0.08-0.17-0.24-0.16c0.04,0.02,0.01,0.08,0.03,0.11c-0.45-0.02,0.04,0.24-0.51,0.34c-0.01-0.11-0.02-0.24-1.13,0.5
				c0.1-0.09,0.27-0.17,0.38-0.26c-0.34-0.02-0.36,0.03-0.57,0.11l0.02-0.01l-0.5,0.15l0.14-0.02c-1.88,1.26-0.09,0.05-5.5,2.24
				c-0.01-0.09,0.05-0.21,0.33-0.28l-0.12,0.19c0.13,0.06,0.07-0.15,0.54-0.34c-0.06,0.04-0.07,0.02-0.13,0.01
				c0.14-0.23,0.27-0.52,0.43-0.69c0.09,0.11,0.18,0.27,0.86-0.68c0,0.08,0.15,0.05,0,0.18c0.44-0.36,0.44-0.8,0.69-0.87l-0.02,0.04
				c0.54-0.65,1.57-1.85,3.26-4.46c0.03,0.06,0.6-0.72,0.92-1.07c-0.12,0.29,0.23-0.26,0.4-0.78c-0.19,0.86,0.44-0.5,1.64-1.87
				c-0.17-0.12-0.32-0.13,0.08-0.43c-0.34,0.64,0.28,0.25,0.24,0.06C684.87,438.99,685.11,437.67,686.52,436.38z M677.68,427.65
				c-0.02,0.1-0.06,0.45-0.16,0.24C677.54,427.94,677.61,427.77,677.68,427.65z M677.47,428.5c-0.01,0.09,0,0-0.05,0.22
				C677.43,428.67,677.44,428.61,677.47,428.5z M675.59,444.95c-0.05-0.08-0.16-0.43-0.16-0.66c0.25,0.42,0.04-0.84,0.32-0.72
				c-0.22-0.31-0.09-1.1-0.17-1.64c0.02,0.03,0.05,0.09,0.03,0.14c0.04-0.27,0.11-0.37,0.16-0.53c-0.09,0.03-0.48-1.29,0.04-0.77
				c-0.07-0.17-0.06-0.54-0.17-0.45c0.14-0.27,0.08-0.24,0.25-0.37c-0.27,0.2-0.08-1.58,0.19-0.85c-0.36-1.01,0.07-0.06,0.05-3.18
				c-0.22,0.01-0.33,0.09-0.2-0.43c1.04-0.28,0.21,0.16,0.85-3.73l0.15,0.2c0.27-1.76-0.38,0.12,2.36-9.29l0,0.14
				c0.21-0.47-0.11-0.65,0.18-1.05l-0.01,0.09c0.12-0.42,1.46-3.31,2.34-5.93l0.14,0.06c-0.06-0.03,0.03-0.31,0.36-0.81
				c-0.03-0.02-0.06-0.03-0.08-0.03c0.13-0.07,0.42-0.41,0.82-1.69c0.05,0.05-0.01,0.21-0.03,0.35c0.31-0.93,0.03-0.16,0.79-1.71
				l0.06,0.12c0.36-0.63,0.67-1.38,1.05-2.1c0.57-1.29,1.3-1.94,6.29-9.7c0.74,0.92,6.05-8.47,4.29,2.59l-0.13-0.25
				c0,1.34-0.39,2.69-0.44,4c-0.02-0.08-0.07-0.09-0.05-0.22c-0.17,0.25-0.2,1-0.1,1.02c-0.32,0.74-0.22,0.8-0.67,3.74
				c-0.21-0.08-0.37,0.79-0.34,1.12c-0.04-0.12-0.13,0.25-0.19,0.1c-0.08,0.34-0.11,0.67,0.02,0.64c-0.52,1.08-0.35,0.79-1.13,4.5
				c-0.03-0.18-0.04-0.31-0.04-0.41c-0.16,0.62-0.25,0.75-1.14,3.83c-0.02-0.01-0.01-0.1,0-0.18c-0.05,0.45-0.01-0.06,0.12,0.94
				c-0.19-0.35-0.34,0.72-0.54,0.19c-0.02,0.1-0.02,0.24,0.06,0.24c-0.07,0.14-0.13,0.17-0.15,0c-0.14,0.55,0.03,0.4-0.15,0.88
				c-0.05-0.02-0.03-0.12-0.07-0.19c0,0.28,0.01,0.56-0.19,0.94c-0.02-0.03-0.07,0-0.02-0.12c-0.27,0.69-0.24,1.26-0.63,1.98
				c-0.05-0.16-0.19-0.24-0.47,0.62l0.15-0.13c-0.15,0.35-0.15,0.72-0.34,1l0.04-0.29c-0.08,0.23-0.17,0.45-0.26,0.67l0.23-0.34
				c-0.07,0.39,0.23,0.06-0.03,0.5c0.03-0.1-0.16,0.35-0.35,0.48l0,0c-0.2,0.84-0.39,0.03-0.71,1.65c-0.14-0.29-0.43,0.41-0.34,0.65
				c0.01-0.05-0.01-0.04,0.01-0.14c-0.29,0.62-0.45,0.85-0.59,1.03l0.06-0.16c-0.44,0.74,0.01-0.07-1.32,2.75l0.09-0.12
				c-0.26,0.91-0.59,0.32-1.39,2.55l-0.01-0.13c-0.23,0.19-0.59,1.84-2.43,3.5c0.08-0.05,0.12-0.07,0.15-0.06
				c-1.4,1.14,0.4-0.41-5.29,5.45l0.14,0c-0.41,0.46-0.37,0.14-1.66,1.68c-0.03-0.21-0.01-0.36,0.14-0.35
				C675.56,446.23,675.22,446.26,675.59,444.95z"
                />
                <path
                  className="st10"
                  d="M617.43,334.7c0.08,0.36,0.05,0.7,0.14,1.02c-0.01,0-0.02-0.03-0.04-0.07c0.07,0.17-0.02-0.02,0.24,0.33
				c-0.16-0.1-0.01,0.32-0.21,0.17c0.01,0.04,0.04,0.09,0.08,0.08c0,0.06-0.02,0.08-0.07,0.02c0.05,0.23,0.09,0.14,0.11,0.35
				c-0.02,0-0.04-0.04-0.07-0.06c0.06,0.1,0.11,0.2,0.1,0.38c-0.02-0.01-0.03,0.01-0.04-0.04c0.03,0.3,0.17,0.5,0.14,0.84
				c-0.06-0.05-0.14-0.05-0.08,0.31l0.04-0.07c0.01,0.16,0.08,0.29,0.06,0.43l-0.05-0.11l0.04,0.29l0.03-0.17
				c0.06,0.15,0.12-0.02,0.1,0.18c-0.05,0.3-0.06,0.09,0.02,0.97c-0.12-0.07-0.1,0.23,0,0.3c-0.01-0.02-0.02-0.01-0.03-0.05
				c0.02,0.28,0.01,0.4-0.01,0.49l-0.01-0.07c-0.01,0.35-0.01-0.03,0.1,1.24l0.01-0.06c0.13,0.04-0.05,0.25,0.01,0.42
				c-0.14,0.06-0.04-0.04,0.05,0.77l-0.04-0.04c0.06,0.62-0.04,0.5,0.05,1.82c-0.04-0.1-0.05-0.16,0.01,0.26
				c0.06-0.03,0.03-0.05,0.08-0.12c0.04,0.04,0.06,0.14,0.07,0.22c-0.04-0.15-0.09,0-0.12,0.07c0.02-0.01,0.04,0.01,0.06,0
				c-0.04,0.37,0.08,0.02,0.06,0.82c-0.02-0.06-0.02-0.14-0.04-0.2c-0.07,0.14-0.05,0.15-0.06,0.25l0-0.01l-0.03,0.23l0.02-0.06
				c0.26,1.12,0.01,0.07,0.13,2.99c-0.08-0.07-0.18-0.13,0.01,0.56l0.02-0.09c-0.04,0.01-0.15-0.04-0.03-0.19l0.02,0.09
				c0.14-0.09-0.03-0.36-0.02-0.54c0.08,0.02,0.14,0.07,0.12-0.44c0.02,0.02,0.07-0.02,0.06,0.06c0.06-0.23-0.09-0.37-0.01-0.45
				l0.01,0.02c0-0.34,0.07-0.86-0.08-2.24c0.03,0.01,0.04-0.38,0.07-0.57l0.02,0.01c-0.09-0.03-0.08-0.23-0.1-0.37
				c0.06,0,0.08,0.03,0.1,0.1c-0.02-0.02,0.04-0.02-0.06-0.29l0.07,0.03c-0.02-0.07,0.06-0.29-0.18-0.71l0.06,0.09
				c-0.02-0.04-0.02-0.32,0.03-0.08c0.04-0.42,0.02-0.17-0.06-1.03v0c-0.08-0.3,0,0-0.03-0.92c0.1,0.01-0.01-0.29-0.02-0.97
				c0.05,0.13,0.04-0.05,0.04,0.14c0.01-0.18,0.05-0.22-0.03-0.41c0.07,0.06,0.07-0.14,0.05-0.22l-0.04,0.13
				c-0.01-0.09-0.01-0.18-0.01-0.26l0.09,0.04c0.02-0.39-0.03-0.81-0.16-1.66c-0.19,0.18,0.17-0.43,0.01-0.34
				c0.03-0.22-0.02-0.33-0.01-0.56c-0.04,0.04-0.28-0.62,0.02-0.48c-0.07-0.2,0.01-0.38,0.01-0.49l0.03,0.1
				c-0.02-0.06-0.02-0.11-0.03-0.17l-0.05,0.06c0.01-0.06,0-0.12,0.03-0.12c-0.1-0.11-0.2,0.03-0.13-0.35
				c-0.06,0.14,0.32,0.05,0-0.31c0.05,0.03,0.08-0.07,0.07-0.11c0.01,0.08-0.11,0.01-0.16-0.3l-0.01,0.1
				c-0.15-0.17,0.04-0.44,0.03-1.15c-0.05,0.07-0.04,0.08-0.11-0.07l0.08,0.34l-0.11-0.21c0.01,0.1,0.06,0.22,0.05,0.28
				c-0.06-0.1-0.04-0.09-0.09-0.04c-0.04-0.52,0.26-0.26,0-1.21l-0.02,0.11c-0.06-0.04-0.03-0.2-0.06-0.3
				c0.05,0,0.06-0.06,0.09-0.11c-0.04,0.01-0.02-0.24-0.12-0.25c0.06-0.3,0.1-0.15-0.14-0.68c0.06,0.07,0.08-0.05,0.14,0.11
				c-0.14-0.35,0,0.13-0.36-2.16c0.01,0,0.01,0,0.02,0.01c-0.12-0.5,0.03,0-0.06-0.81l0.02,0.04c0-0.2,0.02-0.06-0.33-1.15
				c0.03,0,0.04,0,0.05,0c-0.05-0.02-0.12-0.11,0-0.34l0.03,0.17c0.07-0.1-0.04-0.23-0.04-0.32l0.04,0.01
				c-0.06-0.27-0.15-0.25-0.19-0.54l-0.02,0.03c-0.19-0.39,0.2-0.17-0.19-0.88c0.09,0.09,0.17,0.3,0.24,0.43
				c-0.02-0.1-0.07-0.35-0.13-0.39c0.02,0.04,0.05,0.09,0.04,0.13c-0.11-0.45-0.34-0.91-0.4-3.04c-0.05,0.04-0.09-0.06-0.1-0.18
				l-0.03,0.19c-0.15-0.28,0.07-0.29-0.08-0.57l0.07,0.08c-0.02-0.24-0.09-0.28-0.55-1.79l0.04,0.02c-0.1-0.18,0.02-0.11-0.16-0.47
				l0.06,0.07c-0.05-0.37-0.25-0.52-0.36-0.81c0.11-0.03,0.07-0.32,0.04-0.46l0.02,0c-0.21-0.79,0.07-0.09-1.57-4.37l0.07-0.02
				c-0.08-0.25-0.12-0.38-0.25-0.67l0.07,0.04c-0.38-0.48-0.17-0.2-0.63-1.26c0.02,0,0.05,0,0.07,0.04
				c-0.06-0.14-0.16-0.56-0.27-0.46c0.08-0.04-0.12-0.31-0.23-0.45l0.07,0c-0.03,0-0.15-0.16-0.39-0.54l0.07,0.05
				c-0.1-0.24-0.22-0.06-0.23-0.53c0.04,0.07-0.2-0.17-0.09-0.14c-0.04-0.29-0.07,0.04-0.25-0.58c0.1,0.2,0.17,0.36,0.16,0.34
				c0.06-0.03-0.01-0.19,0.13-0.1c-0.09-0.12-0.04,0.03-0.35-0.48l0.01,0.01c-0.12-0.21-0.03-0.14-0.21-0.5
				c-0.06,0.04-0.1-0.07-0.1,0.05c-0.01-0.03,0.02,0.03,0.07,0.09l-0.01,0c0.03,0.05,0.06,0.12,0.1,0.19
				c-0.42-0.45-0.07-0.43-0.8-1.46c0.16,0.15,0.13,0.16-0.26-0.46l0.04,0.02c-0.14-0.52-0.63-0.61-0.76-1.21
				c0.01,0.04,0.02,0.06,0.01,0.08c-0.01-0.32-0.93-1.32-1.83-2.83l-0.01,0.07c-0.45-0.76,0.03,0.18-0.97-0.65
				c0.12,0.1-0.05,0.15,0.36,0.36c-0.19,0.11,0.29,0.13,0.06,0.24c0.08,0.02,0.01,0.23,0.59,0.69c-0.2-0.06,0.19,0.29,0.04,0.33
				c0.17,0.03,0.29,0.34,0.47,0.48c-0.01,0-0.04-0.01-0.05-0.03c0.06,0.11,0.05,0.16,0.08,0.23c-0.07-0.12,0.6,0.3,0.12,0.24
				c0.06-0.05,0.21,0.18,0.23,0.1c0.01,0.13,0.03,0.1-0.01,0.19c0.06-0.14,0.43,0.48,0.14,0.33c0.22-0.02,0.33,0.56,0.99,1.09
				c-0.36-0.01-0.27-0.02,0.67,1.41l-0.12-0.01c0.5,0.59,0.63,1.29,1.74,3.5l-0.04-0.04c0.05,0.2,0.23,0.16,0.24,0.36l-0.03-0.03
				c-0.03,0.08,0.11,0.38,1.29,3.5c-0.03,0-0.07-0.06-0.1-0.11c0.15,0.18-0.06,0.06,0.07,0.23c0.01-0.03,0.05,0,0.06,0
				c-0.01,0.11,0.06,0.33,0.13,0.49l-0.06-0.01c0.08,0.3,0.23,0.61,0.34,0.94c0.05,0.56-0.02-0.06,1.28,4.51l-0.01-0.02
				c0.25,1.41,0.08-0.17,1.05,5.19l-0.1-0.08c0.19,0.52,0.17,1.1,0.33,1.61c-0.02-0.03-0.05-0.03-0.06-0.08
				c-0.04,0.12,0.06,0.41,0.11,0.41c0,0.25-0.07-0.02,0.25,1.53c-0.11,0-0.04,0.36,0.02,0.48c-0.04-0.04-0.02,0.11-0.08,0.07
				c0.02,0.14,0.05,0.27,0.11,0.24c-0.07,0.48-0.03,0.34,0.26,1.88c-0.04-0.03-0.06-0.06-0.08-0.09
				C617.4,334.26,617.42,334.43,617.43,334.7z M611.35,310.81c0.01,0.02,0.02,0.04,0.05,0.08
				C611.37,310.86,611.4,310.89,611.35,310.81z M611.55,311.09c-0.02-0.01,0,0.07,0.01,0.13
				C611.59,311.24,611.42,311.04,611.55,311.09z M611.67,310.39c-0.02-0.02,0.01-0.01-0.06-0.09
				C611.66,310.31,611.67,310.3,611.67,310.39z"
                />
                <path
                  className="st10"
                  d="M625.91,363.03c0.01-0.35,0.08-0.89-0.07-2.33c0.03,0.01,0.04-0.4,0.07-0.59l0.02,0.01
				c-0.09-0.04-0.08-0.24-0.1-0.38c0.06,0,0.08,0.03,0.1,0.11c-0.02-0.02,0.04-0.02-0.06-0.3c0.24,0.12-0.05-0.09-0.12-0.7l0.06,0.1
				c-0.02-0.04-0.02-0.33,0.03-0.09c0.03-0.44,0.01-0.18-0.07-1.07l0,0c-0.09-0.31,0,0-0.03-0.96c0.1,0.01-0.01-0.3-0.02-1.01
				c0.05,0.14,0.04-0.05,0.04,0.14c0.01-0.18,0.05-0.23-0.03-0.42c0.07,0.06,0.07-0.14,0.05-0.23l-0.04,0.13
				c-0.02-0.1-0.02-0.18-0.02-0.27l0.09,0.04c0.02-0.4-0.05-0.84-0.18-1.72c-0.19,0.18,0.17-0.45,0.01-0.36
				c0.03-0.22-0.02-0.35-0.01-0.58c-0.04,0.04-0.29-0.64,0.01-0.5c-0.07-0.2,0.01-0.39,0.01-0.51l0.03,0.11
				c-0.02-0.06-0.02-0.12-0.03-0.17l-0.05,0.07c0-0.07,0-0.12,0.03-0.12c-0.1-0.12-0.2,0.03-0.14-0.36c-0.01,0.35,0.32,0.13,0-0.32
				c0.05,0.03,0.08-0.07,0.07-0.11c0.01,0.08-0.11,0.01-0.16-0.31l-0.01,0.1c-0.15-0.17,0.04-0.45,0.03-1.2
				c-0.05,0.07-0.04,0.08-0.11-0.07l0.08,0.35l-0.11-0.22c0.01,0.11,0.06,0.22,0.06,0.29c-0.06-0.1-0.04-0.09-0.09-0.04
				c-0.04-0.54,0.26-0.27-0.01-1.26l-0.01,0.11c-0.1-0.57,0.06-0.23-0.23-1.39c0.06,0.07,0.08-0.05,0.14,0.12
				c-0.14-0.37,0,0.13-0.35-2.24c0.02,0.01,0.02,0,0.02,0.03c-0.13-0.57,0.02-0.01-0.05-0.87l0.01,0.04c0-0.21,0.02-0.06-0.31-1.2
				c0.03,0,0.04,0,0.05,0.01c-0.05-0.03-0.12-0.11,0.01-0.35l0.03,0.18c0.08-0.11-0.04-0.24-0.03-0.33l0.04,0.01
				c-0.05-0.28-0.15-0.27-0.19-0.57l-0.02,0.04c-0.18-0.41,0.21-0.17-0.16-0.92c0.09,0.1,0.16,0.32,0.22,0.46
				c-0.01-0.11-0.06-0.36-0.11-0.41c0.02,0.04,0.05,0.09,0.04,0.14c-0.26-0.87-0.05-0.03-0.27-2.43c-0.12,0.18-0.02-0.13-0.17-0.08
				c-0.07-0.21,0.03-0.35,0.16-0.66c-0.05,0.04-0.09-0.07-0.09-0.2l-0.04,0.19c-0.13-0.3,0.08-0.29-0.05-0.59l0.06,0.09
				c-0.01-0.25-0.08-0.29-0.44-1.89l0.04,0.02c-0.08-0.2,0.02-0.11-0.13-0.5l0.06,0.07c-0.02-0.39-0.21-0.56-0.29-0.87
				c0.11-0.02,0.09-0.32,0.08-0.47l0.02,0c-0.1-0.84,0.01-0.1-1.09-4.68l0.07-0.01c-0.04-0.27-0.06-0.4-0.15-0.73l0.06,0.06
				c-0.31-0.55-0.14-0.23-0.43-1.39c0.02,0,0.05,0,0.07,0.05c-0.03-0.15-0.07-0.6-0.2-0.51c0.09-0.03-0.07-0.33-0.15-0.5l0.07,0.01
				c-0.03,0-0.12-0.2-0.28-0.62l0.06,0.06c-0.06-0.26-0.21-0.11-0.13-0.58c0.03,0.08-0.16-0.21-0.07-0.16
				c0.02-0.3-0.07,0.03-0.13-0.64c0.06,0.22,0.1,0.4,0.09,0.38c0.06-0.02,0.03-0.2,0.15-0.08c-0.06-0.14-0.04,0.02-0.25-0.56
				l0.01,0.02c-0.08-0.24,0-0.14-0.1-0.55c-0.07,0.03-0.09-0.09-0.11,0.03c0-0.03,0.01,0.04,0.05,0.11l-0.01,0
				c0.02,0.06,0.04,0.13,0.06,0.22c-0.32-0.55,0.01-0.45-0.47-1.66c0.12,0.19,0.09,0.19-0.14-0.52l0.04,0.03
				c-0.01-0.56-0.47-0.78-0.45-1.41c0,0.04,0,0.06-0.01,0.08c0.07-0.31-0.55-1.58-0.96-3.36l-0.04,0.06
				c-0.18-0.9-0.03,0.19-0.71-0.98c0.08,0.14-0.09,0.13,0.22,0.48c-0.22,0.04,0.23,0.23-0.02,0.26c0.07,0.05-0.07,0.23,0.35,0.88
				c-0.18-0.13,0.1,0.34-0.05,0.34c0.15,0.09,0.19,0.43,0.32,0.63c-0.01-0.01-0.04-0.03-0.04-0.05c0.03,0.13,0.01,0.17,0.01,0.26
				c-0.04-0.15,0.5,0.48,0.05,0.27c0.07-0.03,0.15,0.24,0.2,0.17c-0.02,0.13,0,0.11-0.06,0.19c0.1-0.13,0.28,0.61,0.04,0.37
				c0.36,0.38-0.04,0.01,0.53,1.25c0.08-0.04,0.14-0.11,0.17,0.13c-0.4,0.31-0.14-0.02,0.33,1.59l-0.11-0.04
				c0.36,0.72,0.33,1.47,1.04,3.94l-0.03-0.05c0.02,0.22,0.2,0.2,0.19,0.42l-0.02-0.03c-0.02,0.07,0.01,0.42,0.8,3.8
				c-0.03,0-0.06-0.07-0.09-0.12c0.13,0.21-0.07,0.06,0.05,0.25c0.01-0.03,0.05,0.01,0.06,0.01c-0.02,0.11,0.02,0.35,0.08,0.52
				l-0.06-0.02c0.05,0.32,0.16,0.66,0.23,1.02c0.01,0.58-0.02-0.07,0.93,4.79l-0.01-0.02c0.21,1.47,0.04-0.15,0.85,5.45l-0.1-0.09
				c0.18,0.55,0.16,1.14,0.3,1.68c-0.02-0.03-0.05-0.03-0.05-0.08c-0.05,0.12,0.05,0.43,0.1,0.43c0,0.26-0.07-0.02,0.24,1.59
				c-0.11,0-0.04,0.37,0.02,0.5c-0.04-0.04-0.02,0.12-0.08,0.07c0.02,0.15,0.05,0.28,0.11,0.25c-0.07,0.5-0.04,0.36,0.26,1.95
				c-0.04-0.03-0.06-0.06-0.08-0.1c0.04,0.32,0.03,0.34,0.19,1.63c-0.01,0-0.03-0.04-0.04-0.07c0.07,0.18-0.02-0.02,0.24,0.34
				c-0.16-0.1-0.01,0.34-0.21,0.17c0.01,0.04,0.04,0.1,0.08,0.08c0,0.07-0.02,0.09-0.07,0.03c0.05,0.24,0.09,0.15,0.11,0.36
				c-0.02,0-0.04-0.04-0.07-0.06c0.06,0.11,0.11,0.21,0.11,0.39c-0.02-0.01-0.03,0.01-0.04-0.04c0.03,0.31,0.17,0.52,0.15,0.87
				c-0.06-0.05-0.14-0.05-0.08,0.33l0.04-0.08c0.01,0.16,0.09,0.3,0.06,0.44l-0.05-0.12l0.04,0.3l0.02-0.17
				c0.06,0.16,0.12-0.03,0.1,0.19c-0.01-0.04,0.01,0.16-0.04,0.25l0,0c0.11,0.37-0.17,0.07,0.07,0.76c-0.13-0.08-0.1,0.24,0,0.31
				c-0.01-0.02-0.02-0.01-0.03-0.05c0.03,0.29,0.02,0.41,0,0.5l-0.01-0.07c0,0.36-0.01-0.03,0.11,1.29l0.01-0.06
				c0.13,0.39-0.16,0.24,0.07,1.23l-0.04-0.04c0.07,0.65-0.03,0.52,0.06,1.89c-0.04-0.1-0.05-0.17,0.01,0.27
				c0.06-0.03,0.03-0.05,0.08-0.12c0.04,0.04,0.06,0.14,0.07,0.23c-0.04-0.16-0.09,0-0.12,0.07c0.02-0.01,0.04,0.01,0.06,0
				c-0.04,0.39,0.08,0.02,0.06,0.85c-0.02-0.06-0.02-0.15-0.04-0.21c-0.07,0.14-0.05,0.16-0.06,0.26l0-0.01l-0.03,0.24l0.02-0.06
				c0.26,1.17,0.02,0.07,0.11,3.11c-0.08-0.07-0.17-0.14,0.01,0.58l0.02-0.09c-0.04,0.01-0.15-0.04-0.02-0.2l0.02,0.09
				c0.14-0.1-0.03-0.37-0.02-0.56c0.08,0.02,0.14,0.07,0.12-0.45c0.02,0.03,0.07-0.02,0.06,0.06c0.06-0.24-0.08-0.38,0-0.47
				L625.91,363.03z M620.47,324c0.01,0.02,0.01,0.04,0.03,0.09C620.48,324.05,620.5,324.09,620.47,324z M620.59,324.46
				c0.03,0.03-0.1-0.21,0.02-0.13C620.59,324.31,620.6,324.4,620.59,324.46z M620.82,323.54c0.05,0.03,0.06,0.02,0.05,0.11
				C620.85,323.62,620.88,323.63,620.82,323.54z"
                />
                <path
                  className="st10"
                  d="M642.58,290.38c-0.19-0.02-0.14,0.03,0.05-0.48c0.41-0.07,0.11,0.29,1.8-4.1l0.01,0.22
				c0.94-1.93-0.78,0.6,5.26-9.96l-0.06,0.14c0.33-0.51,0.24-0.65,0.6-1.09l-0.05,0.1c0.35-0.39,1.81-2.9,4.36-6.1
				c-0.14,0.04,0.59-0.78,1.97-2.59c0.01,0.04-0.11,0.2-0.19,0.34c0.68-0.95,0.13-0.18,1.37-1.75l-0.02,0.11
				c2.38-2.87,2.37-2.76,11.16-12l-0.04,0.04c2.03-1.95-0.07-0.1,5.32-4.79l-0.07,0c1.21-1.04,0.23-0.45,6.23-5.3l-0.16,0.24
				c1.14-1.08,2.51-1.99,3.66-3c-0.38,0.41,1.16-0.83,4.3-3.32c0.03,0.11,0.87-0.52,1.13-0.78c-0.08,0.1,0.28-0.16,0.19-0.04
				c0.33-0.25,0.63-0.49,0.54-0.49c1.21-0.73,0.89-0.57,4.39-3.24c-0.13,0.14-0.23,0.24-0.31,0.32c0.6-0.45,0.76-0.53,3.8-2.77
				c0,0.01-0.07,0.08-0.14,0.14c0.45-0.39-0.33,0.26,0.69-0.75c-0.17,0.3,0.78-0.51,0.48-0.07c0.09-0.08,0.2-0.19,0.16-0.2
				c0.15-0.1,0.21-0.11,0.08,0.02c0.52-0.41,0.3-0.31,0.79-0.66c0.01,0.02-0.08,0.1-0.11,0.16c0.22-0.22,0.44-0.43,0.86-0.71
				c-0.02,0.03,0.04,0.01-0.08,0.1c0.71-0.51,1.13-0.97,1.93-1.49c-0.08,0.12-0.12,0.25,0.78-0.44l-0.19,0.08
				c0.37-0.26,0.65-0.55,0.98-0.75l-0.25,0.23l0.67-0.51l-0.41,0.25c0.34-0.31-0.11-0.07,0.39-0.4c-0.09,0.08,0.36-0.27,0.58-0.36
				l0,0c0.67-0.59,0.36-0.06,1.67-1.27c-0.1,0.25,0.6-0.32,0.71-0.5c-0.04,0.04-0.02,0.03-0.11,0.11c0.62-0.48,0.89-0.67,1.12-0.81
				l-0.15,0.12c0.6-0.4-0.4,0.24,1.86-1.4c0.03,0.05-0.01,0.11,0.05,0.14c-0.07,0.03-0.01,0.14-0.12,0.11
				c0.02,0.02,0.07,0.09,0.07,0.13c-0.12-0.09-0.04,0.14-0.17,0.09c0.11,0.07,0.02,0.19,0.06,0.29c-0.01-0.01-0.03-0.02-0.02-0.03
				c-0.02,0.05-0.06,0.06-0.09,0.08c0.1,0.02,0.1,0.11,0.15,0.16c-0.1,0.04-0.11-0.06-0.2-0.06c0.08,0.01,0.06,0.12,0.13,0.11
				c-0.07,0.04-0.04,0.04-0.12,0.05c0.12,0.03,0.01,0.05,0.08,0.1c-0.07,0.01-0.05,0.15-0.17,0.03c0.19,0.07-0.15,0.24,0.19,0.64
				c0-0.02-0.02-0.03-0.04-0.04l0.02,0.04c-0.11-0.19-0.16,0.13-0.09,0.01c-0.38-0.11,0.13,0.59-0.16,0.58
				c0.08,0.33-0.09-0.05,0.03,0.6c0.04-0.29-0.18,0.37-0.08,1.04l-0.02-0.02c-0.03,0.09,0.15,0.1,0.09,0.18l-0.01-0.02
				c0,0.18-0.16,0.06,0.09,1.16l-0.09,0.05c0.16,0.08-0.12-0.01,0.12,0.36c-0.03,0-0.05-0.02-0.07-0.04
				c0.15,0.06-0.11,0.05,0.07,0.1c-0.04,0.05-0.01,0.15,0.02,0.22l-0.06,0c0.02,0.13,0.09,0.27,0.14,0.41
				c0.01-0.06,0.1-0.07,0.12-0.03l-0.07,0.04c0.05,0,0.18-0.01-0.02,0.02l0.05,0.06c-0.02,0.01-0.04,0-0.07,0.01
				c0.06,0.01-0.04,0.14,0.05,0.15c-0.02,0.11,0.21,0.44,0.44,0.67c-0.06,0.03,0.01,0.13-0.06,0.1c0.07,0.11,0.1,0.14,0.23,0.26
				c0.01-0.06,0.08-0.07,0.01,0.03c0.18,0.09,0.13,0.47,0.35,0.49l-0.01,0c0.4,0.19-0.05,0.13,1.02,0.62
				c0.01-0.03,0.01-0.07,0.04-0.04c-0.24-0.26,1.06,0.46,1.82-0.85c0.01,0.02,0.03,0.04,0.02,0.06c0.12-0.31-0.02-0.06,0.21-0.89
				c0.11,0.03,0.12-0.14,0.07-0.2c0.03,0.02,0.04-0.05,0.09-0.02c0.01-0.07-0.01-0.13-0.07-0.12c0.11-0.18,0.11-0.15-0.08-0.82
				c0.24,0.13,0.17,0.35-0.05-0.62c0.01,0,0.02,0.01,0.04,0.02c-0.07-0.06,0.01,0.01-0.24-0.11c0.15,0.02,0.01-0.14,0.21-0.1
				c-0.01-0.02-0.04-0.03-0.07-0.02c0.01-0.03,0.02-0.04,0.07-0.02c-0.04-0.09-0.09-0.05-0.11-0.13c0.02,0,0.04,0.01,0.07,0.01
				c-0.06-0.03-0.11-0.07-0.11-0.14c0.02,0,0.03-0.01,0.04,0.01c-0.03-0.12-0.17-0.18-0.15-0.33c0.11,0.02,0.02-0.05,0.11-0.08
				l-0.04-0.07l-0.04,0.04c-0.01-0.06-0.09-0.1-0.08-0.17l0.05,0.04l-0.05-0.11l-0.02,0.08c-0.06-0.05-0.11,0.04-0.11-0.05
				c0.03,0-0.02-0.06,0.08-0.24c-0.08-0.01-0.06-0.14-0.15-0.16c0.02,0.01,0.07-0.01,0.08-0.01l-0.07-0.01
				c0.01-0.03,0.02-0.04,0.06-0.05c-0.01-0.09-0.11,0.06-0.09-0.06c0.01,0.01,0.02,0,0.03,0.01c-0.05-0.12-0.04-0.17-0.03-0.21
				l0.02,0.03l0.03-0.05l-0.05,0c0.07-0.13-0.15-0.02-0.37-0.86c0.04,0.07,0.09-0.19-0.09-0.2c0.01-0.16,0.16-0.23-0.35-0.57
				c0.06,0.04,0.08-0.04,0.1-0.08c-0.01,0.01-0.04,0.01-0.05,0.02c0.11-0.11-0.09,0-0.06-0.09c0.01,0.01-0.17-0.13,0.01-0.08
				c-0.1-0.14,0.01,0.02-0.05-0.28c-0.18,0.1-0.01,0.01-0.25-0.11c0.12,0.02,0.06,0.02,0.17-0.12c-0.08-0.01,0.01-0.09-0.1-0.1
				c0.01-0.01,0.01-0.02,0.01-0.04c-0.03,0.02-0.13-0.1-0.48-0.53c0.15-0.05-0.17-0.09,0.06-0.11c-0.05-0.03-0.11-0.05-0.16-0.08
				c0.03-0.04,0.07-0.01,0.1-0.01c-0.01,0.01-0.09-0.11-0.18-0.04c0.19-0.03-0.17,0.04,0.16,0.21l-0.1,0.03l0.11,0.02
				c-0.01,0.03-0.06,0.07-0.09,0.05c-0.01,0.06-0.04,0.05,0.07,0.12c-0.02,0-0.06,0.05-0.06,0.01c0.02,0.08,0.04,0.23,0.28,0.16
				l-0.13,0.15l0.07-0.03c0,0.01,0.01,0.04-0.02,0.05c0.07,0.02,0.02-0.02,0.08-0.02c-0.03,0.09,0.11,0.14,0.02,0.21
				c0.06-0.01,0.01-0.08,0.08-0.04c-0.15,0.05,0.15,0.11-0.05,0.17c0.17,0-0.07,0.17,0.22,0.28c-0.05,0.01,0.03,0,0.02,0.25
				c0.09-0.03,0.11,0.05,0.15,0.1c-0.05,0.02-0.07,0.02-0.11,0c0.01,0-0.01,0.04,0.1,0.08l-0.07,0.02c0.03,0.06,0.14,0.1,0.13,0.2
				c0.06-0.02,0.13-0.14,0.21-0.07c-0.03,0.02-0.08,0.04-0.11,0.03c0,0.01,0.09,0.06-0.04,0.03c0.04,0.01,0.05,0.12-0.01,0.04
				c0.01,0.04,0,0.09-0.03,0.12c0.03-0.01,0.16-0.01,0.03,0.09c0.04,0,0.02,0.06,0.09,0.03c0.01,0.02-0.02,0.04-0.04,0.05
				c0.05,0.02,0,0,0.14,0.08l0,0c0.13,0.04,0.02,0.33,0.24,0.73c-0.06-0.04-0.03,0.03-0.05-0.04c0,0.07-0.03,0.1,0.06,0.15
				c-0.07-0.01-0.06,0.08-0.03,0.1l0.03-0.06c-0.14,0.17,0.35,0.56,0.16,0.78l0.03,0c-0.02,0.04,0,0.13-0.05,0.12
				c0.04,0.04,0.05,0.01,0.06-0.03c-0.02,0.09,0.03,0.13,0.02,0.22c0.03-0.05,0.29,0.23-0.01,0.2c0.07,0.07-0.01,0.15-0.01,0.2
				l-0.03-0.04c0.02,0.02,0.02,0.04,0.03,0.06l0.05-0.03c-0.04,0.12-0.06,0.04,0.1,0.17c0-0.14-0.3,0.03-0.01,0.13
				c-0.05-0.01-0.08,0.04-0.07,0.05c-0.02-0.02,0.09-0.05,0.15,0.1c0.02-0.04,0.02-0.07,0.08,0.04c-0.03,0.01-0.03-0.04-0.04-0.02
				c0.17,0.07-0.3,0.35,0.02,0.43l-0.06-0.13l0.1,0.08c0-0.04-0.05-0.08-0.04-0.11c0.05,0.04,0.04,0.03,0.09,0.01
				c0.08,0.27-0.25,0.01-0.07,0.49c0.04-0.04,0.05-0.08,0.06,0.08c-0.05,0-0.07,0.02-0.1,0.04c0.04,0,0,0.09,0.1,0.1
				c-0.08,0.1-0.12,0.05,0.05,0.27c-0.05-0.03-0.09,0.01-0.12-0.06c0.04,0.1-0.01-0.02,0.15,0.28c-0.13,0.06-0.1,0.08-0.22,0.55
				c-0.01-0.01-0.02-0.01-0.01-0.02c-0.03,0.22,0,0-0.18,0.25l0-0.02c-0.07,0.01-0.01,0.13-0.07,0.19c0,0-0.01-0.01-0.02-0.02
				c0.04,0.1-0.02,0.19-0.07,0.26c-0.06-0.4-0.33,0.37-0.48,0.13c-0.03,0.03-0.11,0.12-0.18,0.15c0.03-0.08,0.11-0.13,0.14-0.18
				c-0.03,0.01-0.12,0.03-0.13,0.08c0.05-0.01,0.07-0.01-0.13,0.1c0.01-0.19-0.2-0.05-0.24-0.21c-0.14,0.05-0.24-0.09-0.37-0.14
				c0.05,0.06-0.2,0.14-0.08,0.01c-0.02,0.02-0.05,0.02-0.06,0.04c-0.03-0.05-0.04-0.14,0-0.26c-0.02,0.05-0.07,0.05-0.09,0.01
				l0.01,0.09c-0.14,0,0-0.15-0.13-0.16l0.06-0.02c-0.06-0.01-0.15-0.13-0.27-0.09c0.01-0.02,0.03-0.06,0.05-0.05
				c0-0.1-0.14-0.12-0.39-0.44l0.05,0c-0.31-0.52-0.13-0.13-0.47-1.32c0.01,0.01-0.13,0.05-0.1-0.11l0,0.01
				c0.09-0.05,0.04-0.11,0.04-0.17l-0.13,0.02c0.16-0.19-0.15-0.39,0.02-0.5c-0.04-0.03-0.04-0.08-0.08-0.11
				c0.1-0.06,0.07,0.01,0.05-0.29l0.05,0.02c-0.17-0.19-0.13-0.02-0.14-0.55c0.02,0,0.05,0,0.05,0.02c0-0.06,0.06-0.23-0.08-0.21
				c0.09,0,0.01-0.13-0.03-0.2l0.07,0.01c-0.02,0-0.06-0.06-0.13-0.26l0.04,0.03c0.01-0.1-0.18-0.07,0.02-0.23
				c-0.01-0.01,0,0,0.02-0.32c-0.01,0.09-0.01,0.16-0.02,0.15c0.12,0.07,0.21-0.25,0.03-0.46c0.01-0.01,0,0.02,0.01,0.05l-0.01,0
				c0,0.02,0,0.05,0,0.09c-0.03-0.03-0.06-0.06-0.02-0.1c-0.04-0.02-0.09-0.05-0.11-0.06c0-0.11,0.19-0.38,0.26-1.28
				c-0.01,0.01-0.02,0.02-0.04,0.02c0.04-0.2,0.17-0.1,0.15-0.93l0.08,0c-0.21-0.18,0.27-0.39,0.05-0.97c0.01,0,0.01,0,0.03,0.01
				l-0.04-0.02c0,0,0,0,0,0c-0.01,0-0.01,0-0.02-0.01c0.17-0.14,0.35-0.3,0.56-0.47c-0.44,0.21-0.26,0.14,1.27-0.92
				c-0.01,0.02,0.02,0.07-0.02,0.06c0.08,0.01,0.23,0.06,0.28-0.19l0.09,0.18l0.01-0.08c0.01,0,0.04,0,0.04,0.04
				c0.05-0.06-0.01-0.03,0.01-0.08c0.07,0.06,0.18-0.04,0.21,0.08c0.02-0.06-0.07-0.04,0-0.09c-0.03,0.16,0.17-0.09,0.13,0.12
				c0.08-0.15,0.13,0.14,0.37-0.07c-0.01,0.04,0.01-0.03,0.24,0.1c0.02-0.09,0.1-0.07,0.16-0.09c0,0.05-0.02,0.08-0.05,0.1
				c0.01-0.01,0.03,0.03,0.13-0.05l-0.02,0.07c0.07,0,0.16-0.08,0.25-0.02c0.01-0.06-0.07-0.18,0.04-0.22c0,0.03,0,0.09-0.03,0.11
				c0.01,0.01,0.1-0.05,0.01,0.04c0.03-0.03,0.13,0.01,0.03,0.03c0.04,0.01,0.09,0.04,0.1,0.08c0.01-0.03,0.07-0.14,0.1,0.02
				c0.02-0.03,0.07,0.01,0.07-0.07c0.02,0,0.02,0.04,0.03,0.06c0.04-0.04,0,0,0.14-0.08h0c0.1-0.09,0.32,0.13,0.8,0.13
				c-0.06,0.04,0.02,0.04-0.06,0.02c0.07,0.03,0.08,0.07,0.17,0.02c-0.04,0.06,0.05,0.09,0.08,0.07l-0.05-0.05
				c0.21,0.1,0.02,0.08,0.47,0.15c-0.14-0.3,0.43,0.02,0.36,0.04l0.01-0.03c0.03,0.04,0.13,0.05,0.1,0.09
				c0.06-0.02,0.03-0.04-0.01-0.07c0.08,0.06,0.14,0.02,0.23,0.07c-0.04-0.04,0.33-0.18,0.19,0.08c0.09-0.04,0.15,0.06,0.2,0.08
				l-0.05,0.02c0.03-0.01,0.05-0.01,0.07,0l-0.01-0.06c0.1,0.08,0.02,0.07,0.21-0.03c-0.14-0.05-0.08,0.29,0.12,0.05
				c-0.02,0.04,0.01,0.09,0.03,0.08c-0.03,0.01-0.02-0.1,0.15-0.1c-0.03-0.03-0.07-0.04,0.07-0.05c0,0.03-0.05,0.01-0.03,0.03
				c0.13-0.13,0.21,0.41,0.41,0.15l-0.15,0l0.11-0.06c-0.04-0.01-0.1,0.01-0.12-0.01c0.06-0.04,0.05-0.02,0.04-0.08
				c0.29,0.04-0.09,0.23,0.44,0.26c-0.02-0.05-0.06-0.08,0.1-0.02c-0.02,0.04,0,0.07,0,0.11c0.01-0.04,0.09,0.04,0.14-0.05
				c0.07,0.11,0,0.13,0.28,0.07c-0.05,0.03-0.03,0.08-0.11,0.09c0.11,0.01-0.03,0,0.33-0.02c0.01,0.14,0.04,0.14,0.44,0.45
				c-0.01,0.01-0.02,0.02-0.02,0.01c0.2,0.13-0.01,0,0.19,0.28l-0.02-0.01c-0.01,0.07,0.13,0.06,0.17,0.14c0,0-0.01,0-0.02,0.01
				c0.1,0,0.19,0.09,0.25,0.15c-0.11,0.08-0.12,0.14,0.24,0.74c-0.07-0.04-0.13-0.12-0.18-0.16c0,0.04,0.03,0.13,0.08,0.15
				c-0.01-0.06-0.01-0.07,0.11,0.14c-0.19-0.01-0.05,0.22-0.21,0.26c0.05,0.15-0.08,0.25-0.13,0.4c0.06-0.05,0.14,0.21,0.01,0.09
				c0.02,0.02,0.02,0.06,0.04,0.07c-0.06,0.03-0.15,0.05-0.27,0.01c0.05,0.02,0.05,0.07,0.01,0.09l0.09-0.02
				c0.01,0.14-0.16,0-0.16,0.14l-0.02-0.06c-0.01,0.07-0.14,0.16-0.09,0.29c-0.02-0.01-0.06-0.03-0.05-0.05
				c-0.1,0.01-0.12,0.14-0.46,0.41l0.01-0.05c-0.53,0.28-0.13,0.16-1.34,0.3c0.01-0.01,0.01,0.14-0.13,0.07l0.01,0
				c-0.02-0.1-0.1-0.08-0.16-0.09l-0.02,0.13c-0.13-0.2-0.42,0.01-0.47-0.18c-0.04,0.03-0.09,0.01-0.14,0.03
				c-0.01-0.12,0.04-0.06-0.25-0.16l0.04-0.04c-0.25,0.08-0.08,0.11-0.57-0.1c0.01-0.01,0.02-0.04,0.04-0.04
				c-0.06-0.03-0.19-0.16-0.23-0.02c0.04-0.09-0.12-0.06-0.2-0.06l0.04-0.06c-0.01,0.02-0.09,0.03-0.3-0.01l0.05-0.03
				c-0.09-0.06-0.14,0.13-0.21-0.12c-0.02,0-0.01-0.01-0.29-0.17c0.08,0.05,0.14,0.09,0.13,0.09c0.12-0.08-0.13-0.3-0.42-0.24
				c-0.01-0.01,0.02,0.01,0.05,0.01l-0.01,0c0.02,0.01,0.05,0.03,0.08,0.04c-0.04,0.02-0.09,0.02-0.1-0.03
				c-0.04,0.03-0.08,0.06-0.1,0.07c-0.09-0.07-0.28-0.32-1.04-0.87c0,0.02,0.01,0.03,0,0.04c-0.16-0.16,0.02-0.17-0.69-0.69
				l0.05-0.06c-0.44-0.18-0.13-0.9-0.83-0.46l-0.04-0.07c-0.03,0.06,0.02,0.07,0.07,0.08c-0.03,0.06-0.11,0.06,0.1,0.09
				c-0.22,0.13,0.15,0.04-0.03,0.14c0.05,0.02,0.08,0.1,0.14,0.09c-0.03,0.07,0.09,0.12,0,0.18c0.03,0,0.12,0.02,0.15,0.05
				c-0.15,0.01,0.08,0.13-0.04,0.2c0.13-0.03,0.17,0.13,0.29,0.16c-0.01,0-0.03,0.01-0.03,0c0.03,0.05,0.01,0.08,0.02,0.13
				c0.08-0.06,0.15-0.01,0.23-0.02c-0.02,0.11-0.11,0.05-0.16,0.13c0.06-0.06,0.14,0.03,0.17-0.03c-0.01,0.08,0.01,0.05-0.03,0.13
				c0.09-0.08,0.05,0.02,0.13,0c-0.03,0.06,0.11,0.12-0.06,0.16c0.17-0.13,0.12,0.27,0.68,0.21c-0.02-0.01-0.03,0-0.05,0.01l0.05,0
				c-0.23-0.01,0.05,0.21-0.04,0.08c-0.31,0.26,0.35,0.24,1.05,0.7c-0.24-0.16,0.26,0.34,0.95,0.56l-0.03,0.01
				c0.07,0.07,0.16-0.09,0.21,0l-0.02,0.01c0.03,0.1,0.54,0.27,1.6,0.51c-0.01,0.03-0.04,0.04-0.07,0.05c0.11-0.12,0,0.12,0.13-0.04
				c0.04,0.05,0.15,0.06,0.23,0.04l-0.02,0.05c0.15,0.02,0.31-0.02,0.47-0.04c-0.05,0.07-0.02-0.24,0.02-0.05
				c0.02-0.05,0.03-0.18,0.02,0.02l0.07-0.04c0.06,0.17,0.38,0.08,1.32-0.56c-0.06-0.01-0.07-0.08,0.03-0.01
				c0.1-0.19,0.49-0.15,0.51-0.38l0,0.01c0.21-0.46,0.12,0.09,0.63-1.07c-0.04-0.01-0.1,0,0-0.08c-0.33,0.44,0.35-0.95-0.69-1.92
				c0.02,0,0.04-0.02,0.06,0c-0.25-0.23-0.07,0-0.75-0.55c0.07-0.09-0.09-0.17-0.17-0.15c0.03-0.02-0.03-0.06,0.02-0.09
				c-0.06-0.03-0.13-0.04-0.14,0.01c-0.13-0.18-0.1-0.17-0.82-0.27c0.22-0.16,0.4-0.02-0.63-0.2c0-0.01,0.02-0.02,0.04-0.02
				c-0.09,0.04,0.02-0.01-0.19,0.18c0.08-0.13-0.13-0.06-0.02-0.23c-0.02,0.01-0.05,0.03-0.05,0.06c-0.05-0.05,0.01-0.09-0.3-0.03
				c0.01-0.01,0-0.03,0.02-0.03c-0.13-0.02-0.24,0.09-0.38,0.02c0.06-0.1-0.04-0.04-0.04-0.14l-0.08,0.02l0.02,0.05
				c-0.07-0.01-0.14,0.05-0.19,0.01l0.06-0.03l-0.13,0l0.07,0.05c-0.14,0.05,0,0.16-0.29-0.09c-0.04,0.07-0.16,0-0.21,0.07
				c0.02-0.02,0.02-0.07,0.02-0.08l-0.04,0.06c-0.03-0.02-0.03-0.04-0.02-0.07c-0.09-0.03,0.01,0.12-0.09,0.05
				c0.01,0,0.01-0.01,0.03-0.02c-0.13-0.01-0.18-0.03-0.21-0.06l0.04-0.01l-0.03-0.04l-0.02,0.04c-0.1-0.12-0.09,0.14-0.97-0.06
				l0.02-0.03c0,0.01-0.17-0.12-0.25,0.02c-0.14-0.09-0.14-0.25-0.7,0.04c0.07-0.04,0-0.09-0.03-0.13c0,0.02-0.01,0.04,0,0.06
				c-0.05-0.15-0.04,0.08-0.11,0.01c0.01-0.01-0.21,0.1-0.07-0.04c-0.06,0-0.12,0.13-0.17,0.06c0.03-0.02,0.06-0.02,0.09-0.04
				c-0.06-0.08-0.07-0.06-0.11-0.07l0,0l-0.1-0.03c0.01,0.21,0.01,0.02-0.22,0.17c0.07-0.1,0.04-0.05-0.04-0.2
				c-0.04,0.06-0.08-0.04-0.15,0.05c0-0.02-0.02-0.02-0.03-0.03c-0.04,0.05-0.31,0.14-0.47,0.17c0.19-0.19,0.51-0.5,1.14-1
				l-0.07,0.1c1.39-1.23,1.14-0.88,3.99-3.42c-0.2,0.23-0.31,0.34,0.56-0.51c-0.1,0.01-0.12,0.06-0.3,0.17
				c0.07-0.11,0.25-0.31,0.43-0.48c-0.3,0.32,0.07,0.07,0.23-0.04c-0.04,0.01,0-0.05-0.02-0.05c0.44-0.27-0.02-0.14,0.54-0.56
				c-0.01,0.09-0.07,0.22,1.17-1.07c-0.1,0.13-0.28,0.3-0.39,0.42c0.34-0.2,0.36-0.25,0.57-0.45l-0.02,0.02l0.5-0.44l-0.14,0.1
				c0.23-0.47,0.69-0.73,1.08-1.27c-0.38,0.53-0.03,0.12-0.01,0.21c0.14-0.1,0.34-0.27,0.3-0.2c0.17-0.18,0.05-0.2,0.89-0.85
				c-0.89,0.77,1.33-1.49,3.66-4.16c-0.03,0.07,0,0-0.13,0.3c0,0,0,0,0,0c-0.05,0.09,0.81-1.21,0.47-0.54
				c0.25-0.32,0.66-0.83,0.7-0.96c-0.19,0.2-0.08,0.09-0.54,0.62l0.16-0.21c-0.28,0.11-0.66,0.8-1.01,1.19
				c0.02-0.19,0-0.15-0.94,0.84c0.03-0.07-0.1-0.01,0.07-0.16c-0.49,0.44-0.66,0.82-0.89,0.95l0.03-0.04
				c-1.3,1.41-0.55,0.86-3.71,3.79c-0.05-0.09,0.12-0.04-0.79,0.82c0.01-0.04-0.84,0.71-1.26,1.05l0-0.02
				c-0.01,0.14-0.44,0.5-0.73,0.79c-0.03-0.05,0.01-0.12,0.16-0.28c-0.03,0.05-0.04-0.02-0.58,0.61c0.1-0.33-0.18,0.11-1.4,1.37
				l0.17-0.22c-0.21,0.15-0.39,0.43-0.2,0.13c-1.03,0.82-0.45,0.36-2.23,1.96l0,0c-0.61,0.6-0.03,0.03-1.93,1.56
				c0.03-0.15,0.11-0.08-0.2,0.12c0-0.06,0.05-0.29-0.11-0.06c0.28,0.04-0.46,0.48-2.03,1.76c0.28-0.27-0.13,0.05,0.3-0.27
				c-0.42,0.29-0.53,0.33-0.92,0.72c0.11-0.16-0.35,0.17-0.53,0.33l0.31-0.18c-0.21,0.17-0.4,0.31-0.59,0.46l0.05-0.15
				c-0.85,0.61-1.85,1.39-3.77,2.92c0.6-0.21-1.18,0.62-0.82,0.55c-0.44,0.23-0.66,0.53-3.62,2.45l0.23-0.2
				c-0.14,0.12-0.26,0.2-0.38,0.29l0.18-0.05c-0.15,0.09-0.27,0.18-0.29,0.16c-0.25,0.37,0.06,0.17-0.78,0.68
				c0.82-0.57,0.2-0.51-0.74,0.5c0.05-0.09-0.19,0.03-0.28,0.11c0.19-0.12-0.03,0.17-0.66,0.63l0.24-0.15
				c-0.29,0.37-1.08,0.68-2.76,1.8c0.18-0.05,0.19-0.08-0.12,0.21l0.79-0.61l-0.47,0.44c0.24-0.17,0.49-0.4,0.65-0.49
				c-0.73,0.61-0.51,0.11-2.95,2.06l0.26-0.15c-0.64,0.57-0.78,0.41-3.12,2.36c0.14-0.17-0.14-0.01,0.23-0.32
				c-0.19,0.14-0.37,0.26-0.36,0.29c0.35-0.21-0.68,0.61-4.63,3.52c0.01-0.01,0-0.02,0.01-0.03c-1.13,0.93-0.02-0.04-1.88,1.37
				l0.09-0.08c-0.48,0.34-0.16,0.08-2.58,2.23c-0.01-0.02-0.01-0.04-0.01-0.06c-0.05,0.1-0.22,0.31-0.77,0.55l0.39-0.32
				c-0.26,0.1-0.51,0.42-0.73,0.58l0-0.05c-0.6,0.5-0.54,0.59-1.2,1.11l0.09-0.03c-0.83,0.82-0.53,0.12-1.96,1.68
				c0.19-0.26,0.64-0.7,0.92-0.99c-0.23,0.19-0.77,0.67-0.86,0.81c0.09-0.09,0.19-0.2,0.29-0.27c-1.12,1.21-0.43,0.3-5.17,4.38
				c0.43-0.18-0.26,0.24-0.11,0.31c-0.1,0.1,0.01,0-0.32,0.28c0,0,0.01,0,0.02-0.02c-0.31,0.25-0.64,0.39-1.16,0.7
				c0.1-0.02-0.11,0.21-0.38,0.44l0.41-0.3c-0.58,0.66-0.64,0.43-1.23,1.1l0.17-0.23c-0.65,0.62-0.66,0.57-3.77,3.83l0.03-0.08
				c-0.22,0.27-0.12,0.09-0.98,1.04l0.13-0.19c-0.79,0.71-1.06,1.24-1.64,1.91c-0.08-0.08-0.67,0.51-0.97,0.8l0-0.02
				c-1.3,1.57-3.94,4.25-8.41,10.31l-0.05-0.05c-0.46,0.6-0.69,0.89-1.22,1.65l0.07-0.18c-0.88,1.55-0.42,0.73-2.17,3.4
				c0-0.02-0.02-0.06,0.06-0.19c-0.24,0.36-0.98,1.35-0.77,1.3c-0.09-0.04-0.49,0.81-0.71,1.25l-0.02-0.1
				c0,0.06-0.26,0.58-0.84,1.67l0.07-0.2c-0.24,0.68-0.19,0.13-1.92,3.46c0.29-0.56,0.53-1,0.51-0.96c-0.06-0.03-0.3,0.41-0.19,0.01
				c-0.17,0.38,0.05,0.01-0.68,1.52l0.02-0.05c-0.3,0.61-0.21,0.32-0.76,1.36c0.07,0.01-0.07,0.31,0.11,0.05
				c-0.04,0.06,0.06-0.11,0.13-0.3l0,0.02c0.08-0.15,0.17-0.34,0.29-0.56c-0.64,1.62-0.67,1.09-1.99,4.35
				c0.04-0.12,0.1-0.26,0.11-0.33c-0.05,0.02-0.29,1.08-0.68,1.73l0.01-0.11c-0.64,1.31-0.66,2.29-1.32,3.72
				c0.01-0.02,0.02-0.04,0.03-0.04c-0.59,1.65-1.58,5.3-2.42,9.37l0.07-0.12c-0.06,0.35-0.14,1.12-0.19,1.05
				c0.09,0.29-0.74,3.16-0.28,2.53c-0.11,0.43,0.24,0.61,0.46-0.88l-0.07,0.48c0.16-0.43,0.26-1.6,0.72-4.67
				c0.02,0.5,0.22-0.96,0.33-0.8c-0.04-0.37,0.23-1.26,0.31-1.89c0.09,0.4-0.01,0.23,0.43-1.22c-0.08,0,0.14-0.74,0.04-0.62
				c0.15-0.3,0.11-0.27,0.24-0.41c-0.19,0.2,0.43-1.78,0.35-0.96C641.72,292.66,641.48,293.89,642.58,290.38z M709.75,224.06
				c0-0.02,0,0,0-0.04C709.75,224.03,709.75,224.04,709.75,224.06z M709.74,224.21c0.01,0-0.02-0.1,0.05-0.05
				C709.77,224.15,709.76,224.19,709.74,224.21z M710.25,223.93c0,0.02-0.03,0.06-0.03-0.01
				C710.23,223.92,710.24,223.92,710.25,223.93z M714.75,223.52c0.01,0,0.02,0.01,0.04,0.02
				C714.77,223.53,714.79,223.53,714.75,223.52z M714.86,223.05c0.01-0.01,0,0,0.03-0.03C714.91,223.03,714.93,223.08,714.86,223.05
				z M714.91,223.54c-0.02,0.01,0.01,0.04,0.02,0.07C714.94,223.6,714.82,223.58,714.91,223.54z M711.88,219.19
				c-0.01,0.01-0.02,0.02-0.03,0.03C711.87,219.15,711.87,219.17,711.88,219.19z M710.22,220.92c-0.04,0-0.05,0-0.06,0
				C710.17,220.93,710.18,220.86,710.22,220.92z M646.57,281.36c-0.05,0.11-0.18,0.43-0.19,0.26
				C646.37,281.67,646.48,281.49,646.57,281.36z M646.1,282.27c-0.05,0.1,0,0-0.12,0.23C646.01,282.45,646.04,282.39,646.1,282.27z
				 M645.12,283.17c0.02-0.17,0-0.09,0.13-0.29C645.22,282.96,645.24,282.88,645.12,283.17z"
                />
                <path
                  className="st10"
                  d="M693.46,203.68l0.01-0.06c0.13,0.04-0.06,0.25,0.01,0.41c-0.14,0.06-0.05-0.04,0.07,0.76l-0.04-0.04
				c0.09,0.61-0.02,0.5,0.11,1.81c-0.04-0.07-0.05-0.09,0.04,0.26c0.05-0.03,0.03-0.05,0.06-0.12c0.04,0.04,0.08,0.13,0.1,0.21
				c-0.06-0.14-0.09,0.01-0.11,0.08c0.02-0.01,0.04,0.01,0.06,0c0.02,0.37,0.09,0.01,0.18,0.79c-0.03-0.05-0.04-0.13-0.07-0.19
				c-0.05,0.14-0.03,0.16-0.03,0.26v-0.01l0.01,0.23l0.01-0.06c0.44,1.06-0.02,0.08,0.79,2.86c-0.1-0.04-0.2-0.07,0.17,0.52l0-0.09
				c-0.03,0.02-0.16,0.01-0.08-0.18l0.04,0.08c0.11-0.13-0.13-0.33-0.18-0.51c0.08-0.01,0.16,0.03-0.01-0.45
				c0.03,0.02,0.06-0.04,0.07,0.04c-0.01-0.23-0.18-0.32-0.13-0.42l0.01,0.02c-0.1-0.32-0.14-0.82-0.49-2.15
				c0.03,0.01-0.02-0.38-0.02-0.56l0.02,0c-0.1-0.02-0.11-0.21-0.16-0.35c0.06,0,0.08,0.02,0.12,0.09
				c-0.02-0.01,0.04-0.03-0.11-0.27l0.07,0.02c-0.04-0.06,0.04-0.28-0.22-0.68l0.06,0.09c-0.03-0.04-0.03-0.31,0.02-0.08
				c0.02-0.41,0.01-0.17-0.1-1.01l0,0c-0.09-0.29,0,0-0.05-0.91c0.11,0.02-0.03-0.29,0.02-0.96c0.04,0.13,0.04-0.05,0.03,0.14
				c0.03-0.17,0.07-0.21,0-0.4c0.06,0.06,0.08-0.13,0.07-0.21l-0.05,0.12c-0.01-0.09,0-0.17,0.01-0.26l0.08,0.05
				c0.05-0.38,0.03-0.79-0.03-1.63c-0.22,0.15,0.23-0.4,0.06-0.33c0.07-0.2,0.04-0.33,0.09-0.54c-0.05,0.04-0.16-0.65,0.1-0.46
				c-0.04-0.2,0.08-0.36,0.1-0.47l0.01,0.11c-0.01-0.06,0-0.11,0-0.16l-0.07,0.05c0.02-0.06,0.02-0.11,0.05-0.11
				c-0.07-0.13-0.2-0.01-0.07-0.36c-0.09,0.11,0.3,0.12,0.06-0.3c0.04,0.04,0.09-0.05,0.09-0.09c-0.01,0.08-0.11-0.02-0.09-0.32
				l-0.03,0.09c-0.12-0.2,0.13-0.41,0.32-1.08c-0.07,0.05-0.06,0.06-0.09-0.09l-0.02,0.34l-0.05-0.23c-0.02,0.1,0,0.22-0.02,0.28
				c-0.03-0.11-0.02-0.1-0.07-0.06c0.11-0.5,0.32-0.17,0.33-1.15l-0.04,0.1c-0.05-0.05,0.03-0.19,0.02-0.3
				c0.05,0.02,0.08-0.04,0.11-0.08c-0.04,0,0.05-0.23-0.05-0.27c0.14-0.27,0.13-0.11,0.07-0.68c0.04,0.08,0.09-0.02,0.1,0.15
				c-0.03-0.37-0.05,0.12,0.42-2.11c0.01,0.01,0.02,0.01,0.01,0.04c0.08-0.55,0.02,0,0.25-0.78l0,0.04
				c0.09-0.18,0.01-0.05,0.17-1.16c0.02,0.01,0.04,0.02,0.05,0.03c-0.04-0.04-0.07-0.15,0.15-0.3l-0.05,0.17
				c0.11-0.06,0.06-0.22,0.1-0.3l0.03,0.02c0.07-0.26-0.03-0.29,0.06-0.56l-0.04,0.02c0.01-0.43,0.26-0.05,0.23-0.86
				c0.04,0.12,0.01,0.34,0.01,0.48c0.03-0.1,0.09-0.34,0.06-0.4c0,0.04,0,0.1-0.02,0.13c0.11-0.43,0.11-0.97,1.17-2.77
				c-0.06,0.01-0.05-0.1,0.01-0.21l-0.12,0.14c0.02-0.31,0.21-0.2,0.23-0.51l0.01,0.11c0.11-0.2,0.05-0.28,0.56-1.75l0.02,0.04
				c0.03-0.2,0.07-0.07,0.15-0.47l0.01,0.09c0.18-0.32,0.11-0.55,0.2-0.85c0.1,0.04,0.24-0.21,0.31-0.33l0.01,0.01
				c0.56-0.91-0.3,0.21,2.12-4.79l0.02,0.08c0.08-0.59,0.02-0.26,0.49-1.29c0.01,0.01,0.03,0.03,0.02,0.08
				c0.07-0.13,0.32-0.48,0.17-0.49c0.09,0.04,0.15-0.28,0.19-0.45l0.05,0.05c-0.02-0.02,0.03-0.21,0.17-0.63l0.01,0.08
				c0.12-0.22-0.09-0.21,0.26-0.5c-0.03,0.07,0.01-0.26,0.05-0.16c0.2-0.21-0.08-0.03,0.3-0.54c-0.1,0.19-0.18,0.34-0.18,0.33
				c0.06,0.03,0.15-0.12,0.16,0.04c0.05-0.14-0.05-0.01,0.17-0.55l0,0.02c0.1-0.22,0.1-0.1,0.29-0.45c-0.07-0.03,0-0.12-0.1-0.05
				c0.02-0.02-0.02,0.03-0.04,0.11l0-0.01c-0.03,0.05-0.06,0.12-0.1,0.19c0.12-0.59,0.3-0.3,0.74-1.46
				c-0.03,0.21-0.06,0.19,0.24-0.46l0.01,0.04c0.37-0.38,0.19-0.84,0.63-1.25c-0.03,0.03-0.04,0.04-0.06,0.04
				c0.27-0.15,0.67-1.45,1.65-2.88l-0.07,0.01c0.51-0.71-0.15,0.1,0.2-1.14c-0.04,0.15-0.16,0.02-0.18,0.47
				c-0.18-0.13-0.01,0.31-0.2,0.16c0.02,0.08-0.21,0.1-0.38,0.82c-0.03-0.21-0.18,0.29-0.28,0.18c0.04,0.17-0.18,0.41-0.22,0.63
				c0-0.02-0.01-0.05,0.01-0.06c-0.07,0.1-0.12,0.12-0.17,0.17c0.07-0.12,0.03,0.68-0.15,0.22c0.07,0.03-0.06,0.27,0.03,0.25
				c-0.11,0.07-0.07,0.07-0.17,0.08c0.16-0.01-0.22,0.61-0.23,0.28c0,0.5-0.03-0.02-0.48,1.2c0.08,0.03,0.18,0.02,0.03,0.2
				c-0.18-0.28-0.14-0.25-0.81,1.32l-0.05-0.1c-0.2,0.75-0.74,1.23-1.74,3.48l0-0.06c-0.12,0.17,0.03,0.28-0.12,0.42l0.01-0.04
				c-0.05,0.04-0.27,0.31-1.63,3.33c-0.02-0.02-0.01-0.09,0-0.15c-0.02,0.24-0.09,0-0.11,0.22c0.03-0.02,0.04,0.03,0.05,0.04
				c-0.08,0.07-0.18,0.29-0.23,0.45l-0.04-0.05c-0.14,0.28-0.24,0.61-0.38,0.92c-0.32,0.46,0.02-0.06-1.73,4.33l0-0.02
				c-0.43,1.38-0.05-0.24-1.65,5.01l-0.05-0.12c-0.06,0.55-0.34,1.05-0.37,1.59c-0.01-0.04-0.03-0.04-0.02-0.09
				c-0.08,0.09-0.1,0.4-0.06,0.42c-0.09,0.23-0.06-0.05-0.33,1.51c-0.11-0.04-0.17,0.32-0.15,0.45c-0.02-0.05-0.06,0.1-0.1,0.04
				c-0.03,0.14-0.04,0.27,0.02,0.26c-0.2,0.44-0.14,0.32-0.28,1.87c-0.03-0.07-0.04-0.12-0.05-0.16c-0.03,0.26-0.08,0.32-0.23,1.61
				c-0.01,0-0.02-0.04-0.02-0.07c0.03,0.18-0.01-0.02,0.18,0.37c-0.13-0.13-0.07,0.31-0.24,0.12c0,0.04,0.02,0.1,0.06,0.09
				c-0.02,0.06-0.04,0.08-0.07,0.01c0,0.23,0.07,0.16,0.04,0.36c-0.02,0-0.03-0.05-0.06-0.07c0.04,0.11,0.07,0.22,0.03,0.39
				c-0.01-0.01-0.04,0.01-0.03-0.05c-0.03,0.3,0.07,0.52-0.01,0.84c-0.05-0.06-0.13-0.07-0.13,0.3l0.05-0.07
				c-0.02,0.15,0.03,0.3-0.01,0.43l-0.03-0.12l-0.01,0.29c0.01-0.05,0.03-0.11,0.05-0.16c0.03,0.16,0.11-0.01,0.08,0.2
				c-0.07,0.29-0.07,0.08-0.06,0.96c-0.12-0.09-0.12,0.22-0.03,0.3c0-0.02-0.01-0.01-0.02-0.05c0,0.28-0.02,0.39-0.05,0.48
				l-0.01-0.07C693.42,202.79,693.45,202.42,693.46,203.68z M702.97,173.86c0.02,0.05,0.03,0.05-0.04,0.1
				C702.93,173.93,702.95,173.96,702.97,173.86z M702.4,173.95c-0.01,0.02-0.02,0.04-0.04,0.08
				C702.38,173.99,702.36,174.03,702.4,173.95z M702.3,174.27c-0.01-0.03-0.06,0.05-0.1,0.08
				C702.2,174.39,702.26,174.14,702.3,174.27z"
                />
                <path
                  className="st10"
                  d="M706.66,210.55c-0.33-0.03-0.22-0.02-1.22,0.4c0.18-0.31,0.48-0.22-0.97,0.22c0-0.01,0.02-0.03,0.04-0.04
				c-0.1,0.08,0.02-0.02-0.17,0.27c0.04-0.16-0.22,0.02-0.15-0.19c-0.03,0.02-0.05,0.05-0.04,0.08c-0.04,0-0.06-0.02-0.03-0.06
				c-0.14,0.06-0.08,0.1-0.21,0.14c-0.01-0.02,0.02-0.04,0.03-0.07c-0.06,0.06-0.11,0.13-0.23,0.14c0-0.02-0.01-0.03,0.02-0.04
				c-0.2,0.06-0.31,0.21-0.53,0.22c0.04-0.12-0.08-0.02-0.12-0.11l-0.11,0.05l0.06,0.03c-0.1,0.02-0.18,0.11-0.27,0.1l0.07-0.06
				l-0.19,0.06l0.12,0.01c-0.25,0.22,0.04,0.09-0.75,0.23c0.06-0.08-0.15-0.13-0.2,0.03c0.01-0.01,0-0.02,0.03-0.03
				c-0.19,0.05-0.27,0.05-0.33,0.04l0.05-0.02c-0.36-0.02,0.09,0.1-1.55,0.41l0.02-0.04c-0.38,0.13-0.35,0.02-1.2,0.25
				c0.1-0.02-0.19-0.06-0.21,0.19c0.09-0.06-0.02-0.09-0.07-0.11c0.01,0.02,0,0.04,0.01,0.05c-0.13-0.11-0.01,0.06-0.17,0.05
				c0.03-0.02-0.28,0.17-0.12-0.01c-0.09,0.02-0.15,0.16-0.25,0.11c0.03-0.03,0.09-0.04,0.12-0.07c-0.11-0.06-0.11-0.04-0.18-0.03
				l0.01,0l-0.16,0l0.04,0.01c-0.08,0.2-0.22,0.08-0.35,0.23c0.1-0.12,0.04-0.08-0.09-0.19c-0.06,0.08-0.13-0.02-0.21,0.09
				c0.07-0.06-0.6,0.25-1.31,0.39c0.01-0.07,0.05-0.2-0.36,0.09l0.07,0.01c-0.01-0.09,0.03,0.14,0.42-0.12
				c-0.08,0.13,0.02,0.2,0.31,0.05c-0.01,0.03,0.03,0.07-0.02,0.07c0.16,0.02,0.22-0.13,0.3-0.07l-0.01,0.01
				c0.31,0.03,0.01-0.08,0.69-0.25c-0.02,0.17,0.24-0.14,0.23,0.08c0.1-0.18,0,0.12,0.56-0.19c0,0.05,0-0.03,0.39,0.01
				c0-0.09,0.14-0.11,0.23-0.14c0.01,0.05,0,0.08-0.05,0.11c0.01-0.02,0.03,0.03,0.18-0.1l-0.01,0.07c0.12-0.03,0.22-0.13,0.38-0.12
				c-0.01-0.06-0.16-0.15-0.01-0.22c0.01,0.03,0.03,0.09-0.01,0.11c0.01-0.01,0.04-0.04,0.07-0.03l-0.05,0.07
				c0.03-0.03,0.21-0.05,0.06,0.02c0.06,0,0.14,0.01,0.18,0.04c0.11-0.19,0,0,0.5-0.21l0,0c0.11-0.13,0.54-0.01,1.26-0.24
				c-0.08,0.06,0.04,0.03-0.09,0.05c0.12-0.01,0.16,0.03,0.27-0.07c-0.03,0.07,0.11,0.06,0.16,0.03l-0.09-0.03
				c0.41,0.04-0.03,0.1,1.24-0.27c-0.11-0.07,0.15-0.07,0.08,0.03l0.01-0.03c0.06,0.02,0.21-0.02,0.19,0.03
				c0.08-0.05,0.02-0.06-0.04-0.06c0.15,0.01,0.22-0.05,0.37-0.06c-0.06-0.02,0.38-0.34,0.33-0.03c0.12-0.09,0.25-0.02,0.33-0.03
				l-0.06,0.04c0.04-0.02,0.07-0.03,0.11-0.04l-0.05-0.05c0.04,0,0.08-0.01,0.08,0.02c0.06-0.07-0.08-0.2,0.21-0.16
				c-0.09-0.06,0.02,0.32,0.21-0.02c-0.01,0.05,0.06,0.07,0.08,0.06c-0.05,0.02-0.05-0.1,0.17-0.18l-0.07,0
				c0.1-0.16,0.29,0,0.78-0.06c-0.05-0.05-0.06-0.03,0.02-0.11l-0.22,0.1l0.12-0.12c-0.07,0.02-0.13,0.07-0.18,0.07
				c0.06-0.06,0.05-0.05,0.01-0.09c0.36-0.09,0.19,0.25,0.82-0.1l-0.07-0.01c0.01-0.06,0.13-0.04,0.19-0.08
				c0.01,0.05,0.05,0.06,0.09,0.08c-0.01-0.03,0.15-0.04,0.15-0.14c0.21,0.03,0.12,0.09,0.43-0.18c-0.03,0.07,0.05,0.08-0.05,0.15
				c0.05-0.01,0.11-0.02,0.1-0.05c-0.08,0.05,0.23-0.02,0.29-0.24c0.15,0.07,0.25,0.05,1-0.2c0,0.02,0.01,0.02-0.02,0.02
				c0.32-0.15,0.01,0.01,0.55-0.11l-0.02,0.02c0.1,0.03,0.19-0.12,0.34-0.15c0,0,0,0.02,0.01,0.02c0.07-0.11,0.25-0.19,0.38-0.25
				c0,0.03,0.01,0.04,0.01,0.05c0.01-0.05,0.05-0.13,0.23-0.01c-0.23,0.27-0.14,0.12,0.97-0.44c-0.05,0.09-0.17,0.18-0.25,0.25
				c0.05-0.02,0.15-0.06,0.2-0.11c-0.01,0.01-0.03,0.02-0.04,0.01c0.51-0.31,0-0.05,1.54-0.42c-0.07-0.12-0.01-0.18,0.1-0.19
				c0,0.04-0.07,0.13,0.04,0.13c-0.09-0.02,0.03-0.09,0.01-0.12c0.11,0,0.24,0.04,0.33,0.12c-0.04-0.04,0.02-0.09,0.11-0.1
				l-0.14-0.02c0.16-0.15,0.21,0.06,0.38-0.09l-0.04,0.07c0.09-0.06,0.37-0.14,0.42-0.29c0.02,0.01,0.06,0.04,0.02,0.07
				c0.25-0.07,0.48-0.32,0.7-0.32l0,0.04c0.11-0.1,0.07,0.02,0.29-0.17l-0.03,0.06c0.25-0.05,0.31-0.25,0.49-0.35
				c0.04,0.1,0.24,0.06,0.33,0.03l0,0.02c0.39-0.1-0.07-0.2,1.45-0.67c-0.02-0.01-0.04-0.14,0.14-0.15
				c-0.04,0.02,0.31,0.09,0.24-0.18c0.16,0.08,0.34-0.21,1.41-0.58l-0.01,0.07c0.24-0.34,0.07-0.16,0.77-0.57
				c0,0.02,0.01,0.04-0.01,0.07c0.09-0.05,0.38-0.13,0.27-0.24c0.05,0.08,0.2-0.1,0.28-0.2l0.02,0.07
				c-0.01-0.02,0.08-0.12,0.31-0.33l-0.02,0.06c0.15-0.08,0-0.19,0.34-0.2c-0.04,0.04,0.08-0.19,0.08-0.09
				c0.2-0.03-0.04-0.07,0.37-0.21c-0.12,0.08-0.22,0.15-0.22,0.13c0.03,0.05,0.14,0,0.11,0.12c0.09-0.02,0.07-0.26,0.59-0.46
				c-0.04-0.06,0.03-0.09-0.07-0.1c0.02-0.01-0.02,0.02-0.05,0.06l0-0.01c-0.03,0.02-0.07,0.05-0.12,0.09
				c-0.02-0.07,0.14,0,0.12-0.25c0.27,0.07,0.26,0.03,0.79-0.4c-0.02,0.03-0.05,0.05-0.05,0.07c0.07-0.08,0.24-0.19,0.2-0.28
				c0.13-0.05-0.01,0.11,0.12-0.01l0,0.04c0.37-0.11,0.3-0.55,0.72-0.64c-0.03,0.01-0.04,0.01-0.06,0c0.15-0.13,0.52-0.35,1.71-1.5
				l-0.06-0.01c0.51-0.37-0.14,0.02,0.24-0.79c-0.08,0.04-0.05,0.02-0.18,0.29c-0.19-0.18-0.04,0.2-0.18,0.05
				c-0.02,0.07-0.11,0.13-0.09,0.2c-0.09,0-0.15,0.17-0.22,0.09c0,0.03-0.02,0.14-0.06,0.19c-0.01-0.17-0.17,0.16-0.24,0.03
				c0.02,0.14-0.18,0.25-0.23,0.4c0-0.01,0-0.04,0.01-0.04c-0.07,0.05-0.11,0.04-0.16,0.06c0.08-0.05-0.07,0.51-0.15,0.1
				c0.06,0.06-0.08,0.17-0.01,0.2c-0.1,0-0.07,0.02-0.15-0.02c0.13,0.06-0.27,0.35-0.22,0.1c0.02,0.3-0.45,0.24-0.57,0.84
				c-0.52-0.37,0-0.01-0.89,0.55l-0.02-0.1c-0.33,0.4-0.78,0.52-2.18,1.46l0.02-0.04c-0.14,0.04-0.06,0.21-0.21,0.22l0.02-0.02
				c-0.29,0.06-0.17,0.08-1.79,0.87c0.04,0.15,0.05-0.2-0.45,0.25c-0.01-0.03,0.03-0.06,0.05-0.1c-0.1,0.14-0.06-0.06-0.15,0.06
				c0.02,0.01,0.01,0.05,0.02,0.06c-0.08-0.01-0.22,0.06-0.32,0.13l0-0.06c0.07,0.12-0.01,0-0.6,0.31c-0.41,0.22,0,0-1.43,0.76
				c-0.03-0.06-0.19,0.02-0.12-0.06c-0.02,0.01-0.03,0.02-0.05,0.03c-0.02-0.04-0.04-0.01-0.06,0.02c0-0.03,0-0.05-0.01-0.07
				l-0.08,0.1c0-0.05,0.04-0.08,0.01-0.12c-0.04,0.04,0,0.11-0.05,0.13c-0.02-0.06,0-0.1-0.04-0.06c0-0.03,0.01-0.05,0.03-0.06
				c-0.04,0.03-0.08,0-0.11,0.06c-0.01-0.07-0.1-0.01-0.07-0.11c-0.01,0.02-0.07,0.07-0.1,0.07c0.08-0.12-0.09-0.03-0.05-0.17
				c-0.06,0.11-0.14,0.02-0.21,0.07c0.01-0.01,0.02-0.02,0.02-0.02c-0.03-0.02-0.03-0.06-0.05-0.08c-0.03,0.09-0.09,0.1-0.13,0.15
				c-0.02-0.1,0.06-0.1,0.07-0.19c-0.02,0.08-0.09,0.06-0.1,0.12c-0.02-0.07-0.02-0.04-0.01-0.12c-0.04,0.12-0.04,0.01-0.08,0.08
				c-0.03-0.07,0.02-0.11-0.04-0.09c0.11-0.12-0.21-0.09-0.44,0.08c0.01,0.01,0.02-0.02,0.04-0.03l-0.03,0.02
				c0.14-0.08-0.04-0.17,0.02-0.09c0.2-0.26-0.3-0.18-0.77-0.29c0.36,0.11,0.03-0.15-1.42-0.74l0.03-0.09
				c-0.16,0.06,0.13-0.04-0.22-0.1c0.02-0.02,0.04-0.02,0.07-0.03c-0.11,0.07,0.04-0.11-0.09,0c0.01-0.05-0.04-0.09-0.09-0.11
				c0.04-0.09,0.05-0.02-0.17-0.19c0,0.04-0.07,0.1-0.09,0.09l0.05-0.06c-0.05,0.03-0.15,0.1,0.01-0.03l-0.05-0.01
				c0.02-0.02,0.03-0.02,0.06-0.04c-0.05,0.02,0.01-0.11-0.07-0.07c-0.02-0.07-0.24-0.23-0.39-0.3c0.24-0.08-0.31-0.55,0.06-1.14
				c-0.01-0.01-0.11-0.03,0-0.03c-0.29,0.12,0.16-0.34,0.62-0.62c0,0.02,0,0.04-0.01,0.05c0.04,0.05,0.09-0.02,0.09-0.06
				c-0.08,0.01,0.21,0.05,0.39-0.05c-0.02,0.11,0.07,0.1,0.12,0.06c-0.02,0.03,0.02,0.04,0,0.08c0.03,0.01,0.07-0.01,0.08-0.07
				c0.03,0.15,0.16-0.07,0.15,0.11c0.18,0.01,0.13-0.1,0.68,0.22c-0.01,0.01-0.02,0.01-0.03,0.02c0.02,0.01,0.02,0.04,0.05-0.01
				l-0.02,0c0.02-0.08,0.14-0.09,0.17-0.12c-0.11,0.1,0.05,0.08-0.09,0.21c0.01,0,0.04-0.01,0.06-0.04
				c0.06,0.15,0.02,0.02,0.31,0.32c-0.01-0.03,0.05,0.07,0.07-0.03c0.03,0.03,0.11,0.01,0.11,0.06l-0.05,0l0.07,0.05l-0.01-0.06
				c0.1,0.01,0.09-0.13,0.1,0.17c0.07-0.04,0.09,0.06,0.17,0.03c-0.02,0.01-0.06,0.04-0.06,0.05l0.06-0.03
				c-0.01,0.06,0.04,0.03,0.09,0.19l-0.03-0.01l-0.01,0.05l0.04-0.02c-0.09,0.05,0.22,0.07,0.29,0.31c-0.01,0-0.07,0.03-0.05,0.02
				c0.02,0.06,0.1,0.09,0.19,0.15c-0.05-0.04-0.06,0.18,0.09,0.12c-0.01,0.13-0.16,0.19,0.31,0.36c-0.06-0.02-0.08,0.05-0.1,0.08
				c0.01-0.01,0.03-0.02,0.05-0.03c-0.11,0.1,0.1-0.01,0.05,0.06c0.02,0.01,0.13,0.08-0.01,0.06c0.08,0.1-0.01-0.04,0.02,0.21
				c0.19-0.1,0.01-0.01,0.22,0.06c-0.11,0-0.04-0.02-0.17,0.11c0.07,0-0.02,0.07,0.08,0.07c-0.02,0.01,0.01,0.03-0.04,0.05
				c0.14-0.06,0.3,0.25,0.36,0.36c-0.14,0.04,0.13,0.07-0.08,0.09c0.04,0.02,0.09,0.03,0.13,0.06c-0.03,0.03-0.07,0.01-0.1,0.01
				c0.01-0.01,0.05,0.08,0.16,0.03c-0.18,0.03,0.17-0.02-0.1-0.16l0.1-0.02c-0.15,0.01-0.03-0.04-0.04-0.14
				c0.02,0,0.06-0.04,0.06-0.01c-0.01-0.1-0.03-0.18-0.23-0.11l0.15-0.13l-0.07,0.03c0-0.01,0-0.03,0.02-0.04
				c-0.06-0.01-0.03,0.02-0.08,0.02c0.04-0.07-0.09-0.1,0.02-0.16c-0.06,0.01-0.02,0.06-0.08,0.03c0.15-0.05-0.12-0.08,0.08-0.14
				c-0.15-0.03,0.05-0.16-0.18-0.19c0.04-0.01-0.03-0.01,0.01-0.19c-0.09,0.04-0.1-0.02-0.14-0.05c0.05-0.03,0.07-0.03,0.1-0.02
				c-0.02-0.02-0.02-0.04-0.09-0.05l0.06-0.03c-0.03-0.04-0.12-0.05-0.12-0.13c-0.06,0.03-0.13,0.14-0.2,0.1
				c0.03-0.02,0.08-0.05,0.1-0.04c-0.01,0-0.02-0.11,0.05-0.06c-0.01-0.03,0-0.07,0.03-0.1c-0.03,0.01-0.15,0.04-0.03-0.07
				c-0.04,0.01-0.02-0.04-0.09,0c-0.01-0.01,0.02-0.03,0.03-0.05c-0.05,0,0,0-0.13-0.03l0,0c-0.14,0.04-0.01-0.11-0.3-0.48
				c0.06,0.01,0.02-0.03,0.05,0.01c-0.02-0.05,0.01-0.09-0.08-0.09c0.06-0.02,0.04-0.08,0.01-0.09l-0.01,0.06
				c0-0.22-0.43-0.26-0.35-0.49l-0.03,0.02c0-0.04-0.05-0.09,0-0.11c-0.05-0.01-0.05,0.02-0.04,0.05c-0.02-0.08-0.08-0.07-0.11-0.14
				c-0.02,0.06-0.3,0.01-0.08-0.14c-0.09,0.01-0.07-0.1-0.09-0.13l0.04,0c-0.02,0-0.04-0.01-0.05-0.02l-0.02,0.06
				c-0.01-0.02-0.02-0.03-0.01-0.05c-0.04,0.02-0.05,0.09-0.11,0.11c0-0.04-0.08-0.02-0.04-0.09c0.07,0.09,0.19-0.23-0.07-0.08
				c0.03-0.03,0.03-0.08,0.02-0.08c0.02-0.01-0.02,0.09-0.15,0.05c0.01,0.04,0.03,0.05-0.07,0.04c0.02-0.03,0.04,0,0.04-0.02
				c-0.07,0.04-0.03-0.11-0.11-0.1c0.01-0.01,0.03-0.26-0.15-0.1l0.11,0.02l-0.1,0.04c0.03,0.02,0.08,0,0.09,0.02
				c-0.05,0.03-0.04,0.02-0.05,0.07c-0.23-0.06,0.16-0.23-0.28-0.27c0.01,0.04,0.04,0.07-0.08,0.03c0.02-0.05,0.01-0.07,0-0.1
				c-0.01,0.03-0.07-0.03-0.11,0.06c-0.05-0.09-0.01-0.14-0.22,0c0.04-0.05,0.01-0.09,0.07-0.11c-0.09,0.02,0.02-0.01-0.24,0.13
				c-0.04-0.11-0.08-0.15-0.46-0.11c0-0.01,0-0.02,0.01-0.02c-0.12,0.15-0.12-0.21-0.43,0.07c0,0,0-0.01-0.01-0.02
				c-0.02,0.1-0.09,0.15-0.14,0.21c-0.05-0.13-0.02-0.1-0.14-0.04c0.17-0.13-0.29,0.04-0.29,0.43c-0.04-0.07-0.03-0.15-0.03-0.22
				c-0.03,0.02-0.07,0.1-0.04,0.14c0.03-0.04,0.03-0.05,0.02,0.15c-0.16-0.11-0.12,0.13-0.28,0.12c0.02,0.14-0.11,0.23-0.12,0.38
				c0.05-0.05,0.2,0.13,0.03,0.07c0.02,0.01,0.04,0.04,0.07,0.04c-0.04,0.04-0.11,0.09-0.23,0.12c0.05-0.01,0.08,0.03,0.06,0.07
				l0.07-0.06c0.08,0.1-0.13,0.09-0.04,0.2l-0.05-0.03c0.03,0.05,0.02,0.21,0.15,0.24c-0.02,0.01-0.06,0.03-0.07,0.01
				c0.14,0.49,0.05,0.41,0.86,1.47c-0.01,0,0.08-0.11,0.12,0.01l-0.01-0.01c-0.05,0.09,0.02,0.11,0.05,0.14l0.09-0.1
				c-0.02,0.23,0.3,0.13,0.25,0.3c0.04-0.01,0.07,0.01,0.11-0.01c-0.02,0.09-0.06,0.06,0.14,0.18l-0.04,0.03
				c0.2-0.05,0.07-0.12,0.43,0.11c-0.01,0.01-0.02,0.04-0.04,0.04c0.04,0.02,0.12,0.14,0.17,0c-0.04,0.09,0.08,0.05,0.15,0.04
				c-0.1,0.14,0.02-0.02,0.52,0.23c-0.06-0.03-0.11-0.05-0.1-0.05c-0.1,0.05,0.16,0.28,0.31,0.12c0,0.01-0.01,0-0.04,0l0-0.01
				c-0.02-0.01-0.04-0.01-0.06-0.02c0.03-0.03,0.06-0.04,0.08,0c0.03-0.04,0.06-0.07,0.08-0.09c0.07-0.01,0.24,0.28,0.86,0.4
				c-0.01-0.01-0.01-0.02-0.01-0.04c0.11,0.05,0.1,0.19,0.66,0.17l-0.01,0.08c0.04-0.08,0.08-0.07,0.16-0.04
				c-0.25,0.12-0.62,0.04-0.77,0.23l0.01-0.01c-0.59,0.17-0.8,0.39-3.36,1.2l0.04-0.1c-0.32,0.2-0.72,0.21-1.04,0.4
				c0.02-0.02,0.01-0.05,0.04-0.06c-0.09-0.04-0.27,0.08-0.26,0.12c-0.16,0.03,0.03-0.09-0.99,0.34c-0.02-0.11-0.25-0.03-0.32,0.05
				c0.02-0.04-0.08-0.01-0.06-0.07C706.71,210.45,706.63,210.5,706.66,210.55z M721,205c0,0.01,0,0,0.01,0.04
				C720.97,205.06,720.9,205.07,721,205z M720.55,204.75c-0.01,0.01-0.02,0.02-0.05,0.04C720.52,204.77,720.5,204.79,720.55,204.75z
				 M720.39,204.93c0-0.02-0.05,0-0.1,0C720.29,204.95,720.41,204.81,720.39,204.93z M715.72,207.24c0.01,0,0.02,0.01,0.03,0.01
				c-0.01,0.01-0.03,0.02-0.04,0.02C715.71,207.26,715.71,207.26,715.72,207.24z M711.72,207.91c-0.01,0.01-0.01,0.02-0.02,0.03
				C711.68,207.94,711.68,207.91,711.72,207.91z M711.69,207.46c0.01-0.01-0.01-0.04-0.01-0.06
				C711.69,207.42,711.75,207.42,711.69,207.46z M714.03,208.04c-0.01,0.01-0.03,0.01-0.04,0.02l0-0.02L714.03,208.04z"
                />
                <path
                  className="st10"
                  d="M638.87,265.41c-0.05,0.2-0.14,0.52-0.1,0.6l0.07-0.12c-0.05-0.01-0.12-0.11,0.07-0.27l-0.03,0.13
				c0.18-0.08,0.15-0.5,0.24-0.74c0.07,0.08,0.1,0.12,0.32-0.55c0.01,0.04,0.08,0,0.03,0.1c0.16-0.29,0.11-0.52,0.23-0.61
				l-0.01,0.03c0.49-0.82,0.18-0.85,1.09-2.41c0.08,0.06-0.08,0.05,0.24-0.52c0.02,0.03,0.31-0.46,0.47-0.68l0.01,0.01
				c-0.06-0.09,0.1-0.33,0.18-0.51c0.04,0.03,0.05,0.08,0.01,0.19c0-0.03,0.05,0.01,0.15-0.4c0.06,0.09,0.01,0.17,0.16-0.16
				c0.01,0.01,0.03,0.03,0.04,0.04c0.06-0.4-0.08,0.01,0.2-0.78l-0.03,0.15c0.01-0.06,0.25-0.37,0.09-0.08
				c0.38-0.49,0.16-0.2,0.8-1.25l0,0c0.47-0.82,0.08-0.14,1.63-2.19c-0.09,0.18,0.07-0.03-0.1,0.18c0.17-0.19,0.24-0.21,0.35-0.47
				c-0.01,0.11,0.18-0.1,0.24-0.21l-0.14,0.11c0.08-0.11,0.15-0.2,0.23-0.29l0.02,0.1c0.35-0.4,0.76-0.87,1.51-1.84
				c-0.31,0.04,0.56-0.32,0.35-0.34c0.23-0.2,0.32-0.36,0.57-0.55c-0.05-0.02,0.47-0.79,0.53-0.44c0.16-0.24,0.41-0.35,0.53-0.46
				l-0.09,0.12c0.05-0.07,0.11-0.12,0.16-0.18l-0.1,0.02c0.07-0.05,0.12-0.11,0.14-0.09c0.05-0.21-0.13-0.14,0.29-0.44
				c-0.28,0.23,0.06,0.04-0.12,0.24c0.29-0.22,0.2-0.07,0.46-0.53c-0.01,0.06,0.12,0.01,0.16-0.04c-0.09,0.07-0.05-0.11,0.25-0.39
				l-0.12,0.08c0.09-0.26,0.52-0.37,1.32-0.98c-0.1,0.01-0.11,0.03,0.02-0.15l-0.35,0.36l0.18-0.28c-0.11,0.1-0.21,0.24-0.29,0.29
				c0.08-0.14,0.08-0.12-0.01-0.11c0.56-0.45,0.46-0.06,1.4-1.03l-0.13,0.07c0.27-0.35,0.38-0.13,1.48-1.28
				c-0.05,0.11,0.1,0.04-0.07,0.22c0.39-0.44-0.15,0.08,2.48-1.92c0,0.02,0.01,0.02-0.03,0.04c0.62-0.5,0.01,0,1.02-0.63l-0.05,0.04
				c0.26-0.13,0.08-0.03,1.34-1.07c0.01,0.02,0.01,0.04,0.01,0.05c0.01-0.07,0.09-0.19,0.43-0.21l-0.21,0.14
				c0.16,0,0.27-0.18,0.39-0.24l0.01,0.04c0.33-0.22,0.28-0.3,0.64-0.51l-0.05,0c0.45-0.41,0.3,0.08,1.09-0.7
				c-0.09,0.14-0.35,0.34-0.49,0.48c0.13-0.08,0.43-0.27,0.47-0.35c-0.05,0.04-0.1,0.1-0.16,0.12c1-0.76,0.01-0.08,2.97-1.62
				c-0.26-0.02,0.15-0.09,0.05-0.21c0.26-0.19,0.47-0.17,0.88-0.19c-0.06-0.03,0.06-0.12,0.23-0.19l-0.26,0.06
				c0.35-0.28,0.4-0.07,0.75-0.35l-0.1,0.11c0.36-0.16,0.36-0.24,2.31-1.37l-0.02,0.05c0.14-0.11,0.07-0.02,0.62-0.36l-0.08,0.09
				c0.49-0.2,0.67-0.47,1.06-0.69c0.05,0.1,0.44-0.06,0.63-0.14l0,0.02c1.02-0.65,0.16,0.43,5.72-3.47l0.04,0.06
				c0.31-0.23,0.47-0.33,0.8-0.67l-0.04,0.1c0.51-0.73,0.25-0.31,1.37-1.52c0.01,0.02,0.02,0.05-0.03,0.1
				c0.15-0.16,0.63-0.57,0.46-0.61c0.07,0.06,0.31-0.36,0.44-0.58l0.03,0.07c-0.01-0.04,0.16-0.28,0.49-0.81l-0.03,0.11
				c0.23-0.31-0.01-0.28,0.5-0.67c-0.06,0.09,0.11-0.33,0.12-0.21c0.32-0.28-0.08-0.03,0.54-0.74c-0.17,0.26-0.32,0.48-0.31,0.45
				c0.05,0.04,0.2-0.17,0.16,0.05c0.1-0.19-0.05-0.02,0.37-0.76l-0.01,0.03c0.18-0.3,0.14-0.14,0.45-0.64
				c-0.07-0.03,0.03-0.17-0.1-0.06c0.02-0.03-0.03,0.05-0.07,0.15l0-0.01c-0.04,0.07-0.1,0.16-0.17,0.27
				c0.31-0.84,0.45-0.46,1.17-2.11c-0.02,0.06-0.05,0.13-0.05,0.17c0.03,0,0.11-0.59,0.39-0.85l0,0.06c0.46-0.62,0.31-1.2,0.79-1.9
				c-0.03,0.05-0.05,0.07-0.07,0.08c0.28-0.32,0.78-2.15,1.41-4.66l-0.06,0.05c-0.14,0.45,0.67-2.58-0.19-1.38l0.02-0.24
				c-0.15,0.28,0.08,1.09-0.15,1.04c0.03,0.1-0.17,0.24-0.17,1.29c-0.07-0.26-0.13,0.47-0.25,0.38c0.07,0.2-0.1,0.63-0.13,0.95
				c0-0.02-0.01-0.06,0-0.08c-0.2-0.08,0,1.01-0.31,0.66c0.08,0.01-0.04,0.38,0.05,0.33c-0.11,0.14-0.07,0.13-0.17,0.18
				c0.17-0.07-0.21,0.89-0.23,0.45c-0.01,0.71,0.01-0.01-0.54,1.78c0.13,0.03,0.16,0,0.02,0.26c-0.52,0.04-0.09-0.13-1.09,1.96
				l-0.04-0.13c-0.47,1.06-0.99,1.84-3.14,4.64l0.03-0.07c-0.21,0.21-0.1,0.36-0.35,0.52l0.03-0.05c-0.17,0.2-1.52,1.47-2.72,2.53
				l-0.05-0.06c0.02,0.02-0.09,0.16-0.41,0.32c0.01,0.02,0.02,0.03,0.03,0.04c-0.07,0-0.31,0.08-0.91,0.6c0-0.03,0.07-0.1,0.13-0.16
				c-0.44,0.35-0.07,0.06-0.93,0.57l0.02-0.07c-2.66,1.37-0.01-0.05-7.28,3.86l0.02-0.02c-1.22,0.73,0.02,0.01-3.18,1.7l0.05,0.02
				c-0.78,0.67,0.1-0.02-3.5,2.17l0.07-0.15c-0.61,0.52-1.35,0.88-1.95,1.38c0.11-0.11,0.32-0.37-2.21,1.61
				c-0.05-0.1-0.47,0.22-0.59,0.37c0.03-0.07-0.15,0.07-0.12-0.01c-0.16,0.13-0.3,0.27-0.24,0.29c-0.62,0.34-0.48,0.26-2.11,1.76
				c0.04-0.08,0.07-0.14,0.11-0.19c-0.29,0.27-0.37,0.29-1.74,1.58c0.02,0,0.06-0.01-0.18,0.4c0.01-0.22-0.36,0.29-0.31-0.02
				c-0.04,0.05-0.08,0.12-0.04,0.14c-0.07,0.06-0.11,0.06-0.07-0.03c-0.22,0.25-0.1,0.21-0.31,0.42c-0.02-0.02,0.02-0.07,0.02-0.11
				c-0.08,0.14-0.15,0.28-0.35,0.45c0-0.02-0.03-0.01,0.02-0.07c-0.31,0.31-0.44,0.61-0.82,0.92c0.02-0.09-0.03-0.16-0.39,0.24
				l0.11-0.04c-0.16,0.16-0.24,0.36-0.39,0.48l0.08-0.15l-0.27,0.33l0.19-0.15c-0.12,0.2,0.1,0.06-0.12,0.27
				c0.03-0.05-0.15,0.17-0.27,0.22l0,0c-0.27,0.42-0.21-0.02-0.69,0.8c-0.02-0.17-0.31,0.18-0.3,0.31c0.02-0.03,0-0.02,0.03-0.07
				c-0.25,0.31-0.37,0.43-0.46,0.51l0.05-0.08c-0.34,0.38,0.02-0.04-1.07,1.45l0.06-0.06c-0.24,0.5-0.41,0.14-0.99,1.4l0.01-0.07
				c-0.15,0.36-0.33,0.37-1.29,2.04c-0.46-0.51-0.12-0.57-0.13,0.19c0.04-0.15,0.08-0.22-0.18,0.33c0.07-0.01,0.06-0.04,0.15-0.11
				c0,0.07-0.04,0.21-0.1,0.31c0.07-0.21-0.08-0.04-0.16,0.02c0.02-0.01,0.02,0.03,0.04,0.03c-0.21,0.17,0.08,0.09-0.15,0.37
				c-0.03-0.05-0.07-0.11-0.39,0.69c0.04-0.09,0.07-0.18,0.11-0.27c-0.16,0.13-0.16,0.16-0.24,0.28l0.01-0.01l-0.19,0.28l0.06-0.07
				c0.03,0.29-0.18,0.47-0.22,0.82c0.03-0.34-0.03-0.08-0.09-0.13c-0.05,0.07-0.13,0.18-0.13,0.13c-0.03,0.1,0.04,0.17-0.27,0.56
				c0.42-0.66-0.54,1.43-0.9,2.64C638.96,264.97,639.03,264.91,638.87,265.41z M680.31,228.01c0,0.07,0.02,0.06-0.07,0.14
				C680.25,228.11,680.25,228.16,680.31,228.01z M679.65,228.18c-0.01,0.02-0.03,0.06-0.07,0.11
				C679.61,228.24,679.58,228.29,679.65,228.18z M679.43,228.62c0-0.03-0.08,0.06-0.14,0.12
				C679.28,228.79,679.42,228.46,679.43,228.62z"
                />
                <path
                  className="st10"
                  d="M559.11,170.37c-0.05-0.32-0.21-1-0.14-0.95c-0.26-0.36,0.04-2.82-0.31-2.27c0.02-0.39-0.36-0.52-0.26,0.86
				l-0.04-0.44c-0.01,0.41-0.05,1.55,0.68,4.28c-0.24-0.4,0.21,0.87,0.05,0.79c0.19,0.29,0.34,1.13,0.54,1.67
				c-0.02-0.03-0.05-0.08-0.06-0.14c-0.24,0.04,0.74,1.45,0.28,1.3c0.07-0.04,0.28,0.64,0.3,0.49c0.03,0.31,0.05,0.26,0.02,0.43
				c0.06-0.25,0.59,1.56,0.21,0.91c0.59,1.02-0.02,0.07,1.26,3.15c0.15-0.11,0.09-0.11,0.26,0.36c-0.26,0.29-0.3-0.12,1.35,3.84
				l-0.16-0.13c0.84,1.6-0.06,0.24,4.02,9.43l-0.08-0.12c0.19,0.51,0.39,0.5,0.53,1l-0.05-0.08c0.12,0.43,1.66,3.64,2.75,6.23
				c0.05-0.14,0.35,0.81,1.29,2.66c-0.04-0.01-0.13-0.17-0.21-0.29c0.5,0.93,0.1,0.18,0.89,1.81l-0.09-0.05
				c1.49,3.04,1.3,2.98,6.58,13.35l-0.03-0.04c1.32,2.18-0.26,0.22,3.04,5.55c-0.02,0.01-0.05,0.02-0.1,0.05
				c0.09-0.24-0.17,0.06-0.09-0.13c-0.06,0.01-0.14-0.04-0.19,0.01c-0.03-0.08-0.18-0.04-0.14-0.15c-0.03,0.02-0.12,0.05-0.17,0.04
				c0.13-0.1-0.18-0.07-0.11-0.19c-0.1,0.09-0.25-0.03-0.39,0.01c0.01-0.01,0.03-0.02,0.04-0.01c-0.06-0.03-0.07-0.07-0.11-0.1
				c-0.04,0.09-0.14,0.09-0.21,0.13c-0.06-0.11,0.08-0.1,0.08-0.19c-0.02,0.08-0.16,0.04-0.15,0.11c-0.05-0.07-0.05-0.04-0.06-0.13
				c0.02,0.14-0.37,0.02-0.18-0.11c-0.11,0.21-0.25-0.19-0.84,0.13c0.03,0.01,0.03-0.02,0.05-0.03l-0.05,0.02
				c0.26-0.1-0.19-0.17-0.01-0.09c0.15-0.38-0.52-0.07-1.55-0.16c0.27,0.02-0.46-0.18-1.36-0.03l0.03-0.02
				c-0.12-0.02-0.12,0.16-0.23,0.1l0.02-0.01c-0.22-0.05-0.2-0.02-1.62,0.15c0.01,0.15,0.06-0.17-0.4,0.13
				c0-0.03,0.03-0.05,0.06-0.08c-0.1,0.11-0.04-0.07-0.13,0.02c0.02,0.01,0,0.05,0,0.06c-0.07-0.03-0.19,0-0.28,0.04l0-0.06
				c-0.17,0.04-0.34,0.13-0.52,0.18c0.04-0.08,0.07,0.23-0.01,0.05c-0.01,0.06,0,0.18-0.03-0.02l-0.07,0.06
				c-0.01-0.02,0-0.04,0-0.07c-0.39,0.16-0.05-0.06-1.06,0.59c-0.03-0.06-0.16,0-0.11-0.07c-0.4,0.24-0.25,0.3-1.06,0.57l0.01-0.01
				c-0.36,0.47-0.19-0.21-1.05,1.13c0.05,0.02,0.09,0.01-0.02,0.1c0.26,0.02-0.32,0.54,0.5,1.6l-0.1,0c0.28,0.2,0.45,0.5,0.76,0.62
				c-0.03,0-0.04,0.02-0.07,0.01c0.36,0.21,0.06,0,1.03,0.47c-0.05,0.11,0.16,0.16,0.24,0.12c-0.03,0.03,0.05,0.05,0.01,0.09
				c0.08,0.02,0.15,0.03,0.16-0.03c0.21,0.17,0.13,0.15,1.03,0.2c-0.25,0.18-0.46,0.06,0.8,0.14c0,0.01-0.02,0.02-0.04,0.03
				c0.1-0.05-0.02,0.01,0.21-0.2c-0.07,0.14,0.17,0.05,0.06,0.23c0.02-0.01,0.05-0.03,0.05-0.06c0.03,0.01,0.04,0.03,0.01,0.07
				c0.13-0.02,0.09-0.08,0.2-0.07c0,0.02-0.02,0.03-0.04,0.06c0.06-0.05,0.12-0.09,0.22-0.07c-0.01,0.01,0,0.03-0.02,0.03
				c0.17-0.01,0.28-0.11,0.46-0.08c-0.05,0.11,0.06,0.03,0.07,0.13l0.1-0.03l-0.04-0.04c0.09,0,0.16-0.07,0.23-0.05l-0.06,0.04
				l0.16-0.02l-0.09-0.03c0.16-0.16,0.05,0.18,0.62-0.11c-0.04,0.1,0.12,0.13,0.16,0c-0.01,0.01,0,0.02-0.02,0.03
				c0.16-0.04,0.22-0.03,0.27-0.02l-0.04,0.02l0.06,0.03l0.01-0.05c0.17,0.06,0-0.1,1.17-0.34l-0.01,0.04
				c0.28-0.13,0.29-0.02,0.94-0.33c-0.06,0.02,0.17,0.02,0.12-0.21c-0.06,0.07,0.04,0.08,0.08,0.1c-0.01-0.01-0.01-0.04-0.02-0.05
				c0.13,0.09,0-0.08,0.12-0.07c-0.01,0.02,0.18-0.2,0.1,0c0.07-0.04,0.08-0.17,0.16-0.15c-0.02,0.03-0.06,0.05-0.08,0.08
				c0.1,0.04,0.1,0.01,0.15,0l-0.01,0l0.13-0.03c-0.1-0.18-0.01-0.01,0.16-0.28c-0.04,0.13-0.01,0.07,0.14,0.16
				c0.02-0.08,0.11-0.01,0.14-0.13c0.01,0.01,0.03,0,0.05,0.01c-0.06-0.02,0.15-0.17,0.68-0.64c0.06,0.1,0.06,0.1,0.24-0.04
				c-0.01,0,0.07-0.09,0.07-0.16c-0.11,0.02-0.06-0.15-0.28,0.18l-0.03-0.11l-0.03,0.13c-0.03-0.01-0.08-0.05-0.05-0.09
				c-0.08,0-0.05-0.04-0.15,0.11c0-0.03-0.05-0.05-0.01-0.07c-0.08-0.08-0.09,0.31-0.65,0.45c0.01,0.07,0.09-0.01,0.04,0.08
				c-0.04-0.16-0.14,0.18-0.2-0.03c-0.01,0.18-0.06-0.1-0.37,0.28c-0.01-0.04,0,0.03-0.31,0.04c0.02,0.09-0.08,0.12-0.14,0.17
				c-0.02-0.05-0.02-0.08,0.01-0.12c0,0.01-0.04-0.02-0.12,0.12l-0.01-0.07c-0.08,0.05-0.14,0.15-0.27,0.15
				c0.02,0.06,0.16,0.13,0.05,0.22c-0.02-0.03-0.04-0.08-0.02-0.11c-0.01,0.01-0.03,0.04-0.05,0.04l0.03-0.07
				c-0.02,0.04-0.16,0.05-0.05-0.01c-0.05,0.01-0.12,0-0.15-0.02c0.05,0.17-0.14-0.09-0.36,0.23l0,0c-0.13,0.11,0,0-0.47,0.13
				c0-0.1-0.14,0.03-0.5,0.1c0.06-0.06-0.03-0.03,0.07-0.05c-0.09,0-0.12-0.04-0.21,0.05c0.02-0.07-0.08-0.07-0.12-0.04l0.07,0.03
				c-0.27-0.02-0.05-0.08-0.6,0.01c0.24,0.25-0.5,0.11-0.43,0.06l-0.01,0.03c-0.05-0.03-0.16-0.02-0.14-0.07
				c-0.06,0.03-0.03,0.05,0.02,0.06c-0.11-0.04-0.17,0.01-0.29-0.02c0.05,0.03-0.35,0.25-0.25-0.05c-0.1,0.05-0.19-0.04-0.25-0.06
				l0.05-0.02c-0.03,0.01-0.06,0.01-0.09,0.01l0.03,0.06c-0.13-0.06-0.03-0.06-0.25,0.06c0.16,0.07,0.09-0.3-0.16-0.04
				c0.02-0.04-0.02-0.09-0.05-0.08c0.04-0.01,0.02,0.1-0.17,0.11l0.05,0.02c-0.02,0.04-0.06,0.03-0.13,0.04
				c0.05,0.01-0.13-0.11-0.44-0.25c0.02,0.06,0.03,0.05-0.06,0.1l0.18-0.01l-0.13,0.07c0.05,0.01,0.12-0.02,0.15,0
				c-0.06,0.04-0.05,0.03-0.04,0.08c-0.3-0.04-0.02-0.28-0.6-0.23c0.03,0.05,0.09,0.08-0.11,0.03c0.02-0.05-0.01-0.07-0.03-0.11
				c-0.01,0.04-0.12-0.03-0.16,0.06c-0.12-0.12-0.04-0.13-0.36-0.05c0.05-0.04,0.01-0.09,0.1-0.1c-0.14,0,0.03,0-0.39,0.06
				c-0.06-0.15-0.11-0.14-0.67-0.41c0.01-0.01,0.01-0.02,0.02-0.01c-0.25-0.1-0.01-0.01-0.32-0.28l0.02,0.01
				c-0.02-0.09-0.17-0.05-0.24-0.13c0,0,0.01-0.01,0.02-0.02c-0.11,0.01-0.24-0.07-0.33-0.14c0.03-0.02,0.04-0.03,0.05-0.04
				c-0.03-0.22,0.22,0.04-0.51-0.8c0.08,0.04,0.15,0.13,0.21,0.17c-0.01-0.05-0.06-0.15-0.11-0.16c0.01,0.02,0.04,0.04,0.03,0.06
				c-0.24-0.29,0.19-0.11,0.25-1.13c0.02,0.03,0.03,0.12,0.1,0.09c-0.07,0.01-0.03-0.08-0.06-0.1c0.07-0.03,0.17-0.03,0.29,0.04
				c-0.05-0.03-0.03-0.08,0.03-0.1l-0.11,0c0.06-0.16,0.17,0.02,0.25-0.13l-0.01,0.07c0.04-0.07,0.23-0.15,0.24-0.3
				c0.02,0.01,0.06,0.04,0.03,0.06c0.15-0.02,0.18-0.11,0.71-0.43l-0.02,0.06c0.45-0.38,0.37-0.02,1.79-0.64
				c0.16-0.18,0.09-0.04,1.13-0.36c0.09,0.12,0,0.06,0.38,0.02l-0.03,0.05c0.25-0.2,0.07-0.12,0.71-0.19c0,0.02,0,0.05-0.03,0.05
				c0.08,0,0.31,0.05,0.27-0.09c0.01,0.09,0.17,0,0.26-0.05l-0.01,0.07c0-0.02,0.09-0.07,0.33-0.14l-0.04,0.04
				c0.25,0.01,0.12-0.01,0.72,0.02c-0.11-0.01-0.21-0.01-0.2-0.02c-0.06,0.15,0.25,0.21,0.6,0.02c0.01,0.01-0.02,0-0.06,0.02l0-0.01
				c-0.03,0-0.07,0-0.11,0c0.04-0.03,0.08-0.06,0.13-0.02c0.03-0.04,0.06-0.09,0.07-0.11c0.12,0.21,0.14,0.18,0.67,0.18
				c-0.02,0.01-0.05,0.01-0.07,0.02c0.08-0.03,0.24,0.02,0.27-0.07c0.09,0.06-0.07,0.08,0.07,0.08l-0.02,0.03
				c0.24,0.19,0.48-0.17,0.74,0.09c-0.02-0.02-0.03-0.02-0.03-0.04c2.08,0.43,2.16-0.46,7.02,6.1l-0.22-0.15
				c0.96,1.07,1.87,2.26,2.9,3.21c-0.39-0.33,0.81,1.03,3.6,3.38c-0.11,0.06,0.63,0.68,0.91,0.85c-0.11-0.05,0.19,0.21,0.07,0.17
				c0.29,0.25,0.56,0.46,0.55,0.37c0.9,0.91,0.67,0.71,3.89,3.07c-0.14-0.06-0.25-0.11-0.34-0.16c0.59,0.41,0.64,0.56,3.43,2.48
				c-0.01,0.01-0.09-0.04-0.17-0.08c0.48,0.26-0.33-0.2,0.87,0.34c-0.32-0.04,0.67,0.51,0.19,0.4c0.1,0.05,0.22,0.11,0.22,0.07
				c0.13,0.1,0.16,0.15,0,0.09c0.51,0.32,0.36,0.15,0.82,0.45c-0.02,0.02-0.11-0.03-0.18-0.04c0.26,0.11,0.51,0.22,0.89,0.49
				c-0.03,0,0,0.04-0.11-0.03c0.66,0.43,1.2,0.62,1.91,1.12c-0.13-0.02-0.25,0,0.63,0.51l-0.14-0.13c0.35,0.21,0.7,0.33,0.98,0.54
				l-0.28-0.12l0.67,0.37l-0.35-0.25c0.38,0.17,0.02-0.12,0.48,0.17c-0.1-0.05,0.35,0.21,0.51,0.36l0,0
				c0.74,0.33,0.17,0.29,1.67,0.91c-0.25,0.02,0.49,0.38,0.69,0.38c-0.05-0.02-0.03,0-0.13-0.04c0.68,0.33,0.92,0.49,1.13,0.63
				c-0.23-0.07-0.4-0.18,0.32,0.31c-0.11-0.14-0.2-0.05-0.14,0.38c-0.02-0.02-0.07,0.01-0.06-0.05c-0.07,0.19,0.06,0.32-0.02,0.39
				l-0.01-0.02c-0.15,0.43-0.04,0.15-0.23,1.94c-0.03-0.02-0.11,0.32-0.18,0.47l-0.01-0.01c0.08,0.05,0.03,0.21,0.02,0.34
				c-0.05-0.02-0.07-0.04-0.08-0.11c0.01,0.02-0.04,0.01-0.01,0.26l-0.06-0.05c0.08,0.09-0.24,0.18-0.01,0.64l-0.03-0.1
				c0.01,0.05-0.08,0.26-0.05,0.06c-0.15,0.33-0.06,0.14-0.26,0.86l0,0c-0.02,0.27,0,0-0.32,0.73c-0.08-0.07-0.1,0.23-0.43,0.72
				c0.03-0.12-0.05,0.01,0.04-0.12c-0.1,0.12-0.15,0.12-0.19,0.29c-0.02-0.08-0.13,0.04-0.16,0.11l0.1-0.06
				c-0.04,0.07-0.09,0.12-0.14,0.17l-0.04-0.08c-0.26,0.21-0.4,0.51-0.98,0.96c0.19,0.12-0.38-0.06-0.27,0.1
				c-0.18,0.03-0.25,0.1-0.43,0.13c0.07,0.03-0.48,0.35-0.39,0.03c-0.15,0.08-0.3,0-0.39-0.02l0.08-0.02
				c-0.05,0.01-0.09,0.01-0.13,0.01l0.05,0.06c-0.05-0.01-0.09-0.01-0.09-0.04c-0.1,0.07,0.01,0.22-0.29,0.09
				c0.25,0.07,0.16-0.28-0.24-0.07c0.03-0.04-0.03-0.09-0.06-0.09c0.06,0.01-0.02,0.12-0.27,0.05l0.07,0.04
				c-0.17,0.05-0.33-0.14-0.68-0.57c-0.01,0.07,0.01,0.07-0.11,0.03c0.07,0.06,0.15,0.11,0.22,0.15c-0.06-0.02-0.13-0.03-0.19-0.05
				c0.06,0.06,0.16,0.09,0.19,0.13c-0.09-0.02-0.08-0.02-0.08,0.04c-0.3-0.32,0.03-0.25-0.49-0.84l0.01,0.09
				c-0.07-0.01-0.08-0.14-0.12-0.21c0.05-0.01,0.05-0.06,0.07-0.11c-0.03,0.01-0.05-0.18-0.15-0.18c0.04-0.25,0.08-0.12-0.13-0.56
				c0.06,0.06,0.09-0.03,0.13,0.1c-0.13-0.66-0.05-0.01,0.17-1.81c0.01,0.01,0.02,0.01,0.01,0.03c0.11-0.47,0.01,0,0.31-0.64
				l-0.01,0.04c0.12-0.14-0.01-0.04,0.35-0.96c0.02,0.02,0.03,0.03,0.04,0.04c-0.03-0.05-0.04-0.15,0.2-0.21l-0.08,0.13
				c0.11-0.01,0.11-0.16,0.17-0.21l0.03,0.03c0.29-0.32,0.19-0.39,1.32-1.01l-0.01,0.02c-0.04-0.03,0.41-0.01,0.94-0.96
				c0.02,0.03,0.02,0.04,0.04,0.07c-0.04-0.27,0.04-0.05-0.26-0.4c0,0.01,0,0,0.01,0.01c-0.2,0.02,0,0-0.76,0.22
				c0.14-0.04-0.21,0.37-1.72,1.56l0.04-0.07c-1.13,0.67-0.17,0.29-2.86,1.12c0.02,0.02-0.02,0.14-0.23,0.11
				c0.04,0-0.28-0.19-0.36,0.1c-0.12-0.2-0.49,0.21-1.75-0.25l0.05-0.03c-0.29-0.08-0.3,0.13-0.26-0.64
				c0.01,0.01,0.04,0.02,0.04,0.06c0.02-0.1,0.22-0.35,0.08-0.39c0.08,0.04,0.13-0.21,0.19-0.35l0.04,0.06
				c-0.03-0.01,0.06-0.17,0.27-0.48l-0.01,0.08c0.16-0.15-0.04-0.23,0.36-0.33c-0.05,0.05,0.09-0.22,0.09-0.11
				c0.23-0.09-0.05-0.06,0.44-0.3c-0.15,0.11-0.27,0.19-0.26,0.18c0.04,0.05,0.16-0.03,0.13,0.11c0.08-0.09-0.04-0.03,0.33-0.37
				l-0.01,0.01c0.17-0.12,0.12-0.03,0.41-0.21c-0.04-0.06,0.05-0.1-0.06-0.1c0.02-0.01-0.03,0.02-0.07,0.07l0-0.01
				c-0.04,0.03-0.09,0.06-0.15,0.1c0.34-0.4,0.33-0.11,1.24-0.66c-0.14,0.13-0.15,0.09,0.42-0.15l-0.02,0.04
				c0.45,0.01,0.64-0.43,1.17-0.33c-0.04-0.01-0.05-0.01-0.06-0.02c0.2,0.06,0.97-0.27,2.86,0.12l-0.03-0.06
				c0.72,0.3-0.12-0.14,1.05-0.09c-0.09-0.06-0.06-0.06-0.46-0.08c0.09-0.2-0.28,0.07-0.18-0.15c-0.07,0.05-0.15-0.18-0.82-0.11
				c0.17-0.11-0.31-0.05-0.25-0.18c-0.13,0.1-0.41,0-0.62,0.07c0.01-0.01,0.03-0.03,0.05-0.02c-0.11-0.01-0.14-0.05-0.22-0.08
				c0.12,0-0.53,0.35-0.25-0.03c0.01,0.08-0.24,0.09-0.19,0.15c-0.11-0.05-0.09-0.03-0.15-0.1c0.09,0.12-0.58,0.15-0.33-0.04
				c-0.31,0.27-0.05-0.06-1.14,0.36c0.03,0.07,0.1,0.14-0.11,0.16c-0.43-0.55,0,0-1.34,0.38l0.02-0.11
				c-0.48,0.54-1.39,0.36-2.75,2.27l0.01-0.06c-0.13,0.17,0.02,0.27-0.11,0.41c0.14-0.98-0.24,1.62,2.37,1.67
				c-0.01,0.03-0.07,0.05-0.12,0.07c0.2-0.1,0.04,0.08,0.23-0.01c-0.03-0.02,0.01-0.05,0.01-0.06c0.1,0.04,0.31,0,0.46-0.04
				l-0.02,0.06c0.28-0.04,0.59-0.14,0.89-0.24c0.41-0.18,0,0,1.44-0.64c-0.12,0.21-0.23,0.42-0.28,0.65
				c-0.01-0.03-0.03-0.04-0.01-0.09c-0.23,0.65-0.02,0.09-0.42,1.76c-0.11-0.03-0.13,0.32-0.07,0.44c-0.03-0.04-0.04,0.1-0.09,0.05
				c0,0.14,0.02,0.26,0.08,0.24c-0.03,0.67-0.11,0.57,0.6,1.68c-0.02,0-0.04-0.01-0.07-0.02c0.37,0.4,0.18,0.43,1.19,0.86
				c0,0.01-0.04,0.01-0.07,0c0.18,0.01-0.02,0.02,0.37-0.11c-0.13,0.13,0.28,0.09,0.09,0.25c0.04,0,0.1-0.02,0.09-0.05
				c0.06,0.02,0.07,0.04,0.01,0.07c0.09,0.06,0.3-0.12,0.28,0.01c0.1-0.04,0.2-0.09,0.36-0.07c-0.01,0.02,0.01,0.04-0.04,0.04
				c0.29-0.03,0.46-0.17,0.76-0.2c-0.04,0.06-0.01,0.15,0.3,0.01l-0.08-0.02c0.14-0.04,0.23-0.16,0.35-0.18l-0.08,0.08
				c0.08-0.04,0.16-0.09,0.24-0.13l-0.15,0.04c0.28-0.35-0.04-0.06,0.83-0.64c-0.02,0.14,0.22,0,0.2-0.18
				c-0.01,0.02,0,0.02-0.02,0.05c0.16-0.21,0.24-0.27,0.32-0.31l-0.04,0.07c0.22-0.22,0,0.04,0.59-0.95l-0.04,0.04
				c-0.1-0.1,0.16-0.16,0.17-0.33c0.15,0.02,0.03,0.06,0.26-0.63l0.02,0.05c0.14-0.53,0.23-0.4,0.51-1.53
				c0.01,0.09,0,0.15,0.06-0.23c-0.06,0.01-0.04,0.03-0.1,0.08c-0.02-0.04-0.03-0.13-0.01-0.2c0.01,0.14,0.09,0.02,0.14-0.03
				c-0.02,0.01-0.03-0.02-0.05-0.02c0.12-0.3-0.07-0.04,0.11-0.71c0.01,0.05-0.01,0.13,0,0.18c0.1-0.1,0.08-0.12,0.11-0.2l0,0.01
				l0.07-0.19l-0.03,0.05c-0.08-1,0.02-0.07,0.21-2.57c0.01,0.02,0.04-0.01,0.09,0.09c0-0.02-0.01-0.03-0.02-0.04
				c0.34,0.19,0.92,0.46,2.31,1.04l-0.14-0.08c0.54,0-0.01,0.32,2.83,1.24l-0.12-0.01c1.56,0.58,1.17,0.57,4.44,1.72
				c-0.27-0.06-0.4-0.1,0.64,0.24c-0.04-0.08-0.1-0.07-0.25-0.18c0.12,0.01,0.36,0.07,0.56,0.15c-0.38-0.1-0.03,0.09,0.12,0.18
				c-0.02-0.03,0.04-0.02,0.03-0.05c0.39,0.26,0.12-0.09,0.67,0.21c-0.08,0.03-0.21,0.03,1.31,0.57c-0.15-0.05-0.34-0.12-0.49-0.17
				c0.3,0.22,0.14,0.05,1.07,0.6l-0.13-0.09c0.47,0.05,0.83,0.36,1.4,0.57c-0.55-0.21-0.11,0-0.19,0.05c0.12,0.1,0.3,0.24,0.24,0.22
				c0.18,0.12,0.23-0.02,0.9,0.62c-0.28-0.3,0.74,0.29,2.26,4.07c-0.14-0.27-0.18-0.22,0.12,1.08c-0.03-0.08-0.04-0.19-0.01-0.37
				l0.05,0.23c0.09-0.26-0.17-0.93-0.2-1.4c0.09,0.1,0.2,0.18-0.05-1.14c0.04,0.06,0.06-0.07,0.1,0.13c-0.64-2-1.97-3.03-5.06-4.53
				c0.04-0.01-0.89-0.44-1.34-0.66l0.02-0.01c-0.12,0.05-0.59-0.14-0.94-0.26c0.03-0.05,0.1-0.04,0.3,0c-0.05,0,0-0.05-0.73-0.21
				c0.31-0.07-0.15-0.12-1.7-0.53l0.26,0.03c-0.22-0.1-0.51-0.11-0.19-0.1c-1.09-0.46-0.48-0.19-2.52-0.94l0,0
				c-1.06-0.32-1.03-0.5-4.48-2.05c0.34,0.12-0.09-0.09,0.34,0.13c-0.4-0.22-0.48-0.3-0.95-0.46c0.18,0.02-0.27-0.22-0.48-0.3
				l0.27,0.18c-0.04-0.06-0.2,0.02-0.35-0.3l-0.02,0.08c0.02-0.01,0.09,0.01,0.08,0.08c-0.1-0.05-0.21-0.1-0.31-0.16l0.14-0.02
				c-0.81-0.49-1.85-0.96-3.82-2c0.26,0.41-0.87-0.76-0.75-0.48c-0.3-0.27-1.03-0.55-3.28-2.21l0.24,0.13
				c-0.14-0.08-0.26-0.15-0.37-0.23l0.1,0.14c-0.13-0.1-0.24-0.17-0.23-0.19c-0.4-0.08-0.13,0.12-0.83-0.43
				c0.53,0.39,0.02-0.07,0.43,0.18c-0.53-0.41-0.25-0.27-1.08-0.66c0.1,0.01-0.08-0.16-0.17-0.21c0.16,0.13-0.16,0.04-0.74-0.36
				l0.2,0.16c-0.59-0.31-1.45-1.18-4.26-3.25l0.19,0.19c-0.66-0.4-0.56-0.57-2.78-2.17c0.18,0.09-0.02-0.13,0.34,0.12
				c-0.16-0.14-0.31-0.28-0.33-0.25c0.27,0.25-0.74-0.38-3.93-3.48c0.03,0.01,0.02-0.02,0.07,0.04c-1.06-0.98-0.01-0.02-1.47-1.58
				l0.08,0.07c-0.32-0.42-0.13-0.08-2.15-2.19c0.02-0.01,0.04-0.01,0.05-0.01c-0.1-0.04-0.29-0.19-0.49-0.71l0.29,0.36
				c-0.08-0.24-0.37-0.46-0.51-0.66l0.05,0c-0.44-0.55-0.52-0.5-0.98-1.11l0.03,0.08c-0.74-0.76-0.09-0.49-1.51-1.79
				c0.24,0.16,0.63,0.58,0.89,0.83c-0.17-0.21-0.6-0.71-0.72-0.78c0.08,0.08,0.19,0.17,0.24,0.26c-1.52-1.66-0.13-0.02-3.57-4.91
				c-0.1,0.05-0.44,0.12-0.74-1.64c-0.01,0.09-0.16-0.14-0.28-0.44l0.14,0.44c-0.42-0.68-0.19-0.67-0.62-1.35l0.16,0.21
				c-0.33-0.74-0.38-0.69-2.24-4.31l0.07,0.05c-0.17-0.27-0.04-0.13-0.6-1.14l0.13,0.17c-0.38-0.88-0.74-1.27-1.12-1.98
				c0.1-0.05-0.2-0.74-0.35-1.08l0.02,0c-0.9-1.67-2.28-4.58-5.28-10.79l0.07-0.02c-0.27-0.62-0.4-0.93-0.77-1.69l0.12,0.13
				c-0.82-1.38-0.4-0.64-1.6-3.27c0.02,0.01,0.06,0.02,0.12,0.14c-0.15-0.36-0.54-1.4-0.62-1.21c0.08-0.05-0.34-0.78-0.55-1.17
				l0.09,0.04c-0.04-0.03-0.3-0.49-0.79-1.49l0.11,0.15c-0.28-0.41-0.36-0.95-1.39-3.29c0.22,0.52,0.4,0.94,0.37,0.91
				c0.06-0.03-0.11-0.45,0.11-0.15c-0.16-0.33-0.04,0.03-0.67-1.34l0.02,0.04c-0.24-0.56-0.09-0.33-0.49-1.32
				c-0.06,0.04-0.16-0.24-0.11,0.04c-0.02-0.06,0.04,0.1,0.13,0.26l-0.01-0.01c0.06,0.14,0.13,0.32,0.22,0.52
				c-0.73-1.39-0.35-1.1-1.62-4c0.05,0.1,0.1,0.23,0.14,0.28c0.02-0.04-0.52-0.88-0.63-1.55l0.06,0.08
				c-0.35-1.31-0.97-1.98-1.28-3.46c0.02,0.1,0.03,0.15,0.03,0.18c-0.16-0.92-1.47-3.63-2.27-8.42L559.11,170.37z M577.44,223.22
				c0.01-0.02-0.04-0.03-0.06-0.05C577.36,223.18,577.51,223.14,577.44,223.22z M577.79,223.63c0,0.01,0,0-0.01,0.03
				C577.74,223.67,577.69,223.64,577.79,223.63z M577.58,223.17c0.02,0,0,0,0.05,0C577.62,223.17,577.6,223.17,577.58,223.17z
				 M602.38,249.8c0,0.01,0,0,0,0.04C602.34,249.87,602.27,249.88,602.38,249.8z M601.88,249.57c-0.01,0.01-0.03,0.02-0.07,0.05
				C601.84,249.6,601.82,249.62,601.88,249.57z M601.67,249.79c0-0.02-0.07,0.01-0.12,0.02
				C601.54,249.85,601.69,249.66,601.67,249.79z M599.75,241.29l-0.51-0.28c0.22,0.16,0.48,0.32,0.6,0.42
				c-0.25-0.12-0.22-0.12-0.14,0c-0.37-0.3-0.52-0.47-0.69-0.65L599.75,241.29z M564.9,188.51c-0.03-0.04,0.03,0.14,0.07,0.28
				C564.92,188.69,564.77,188.41,564.9,188.51z M564.6,186.72c0.11,0.11,0.07,0.05,0.12,0.26
				C564.69,186.91,564.74,186.97,564.6,186.72z M564.61,187.94c-0.04-0.09,0,0-0.09-0.22C564.53,187.77,564.56,187.83,564.61,187.94
				z"
                />
                <path
                  className="st10"
                  d="M631.2,265.21l0.07-0.04c-0.17-0.29-0.14-0.08-0.11-0.77l-0.04,0.04c-0.04-0.7-0.06,0.13-0.54-0.75
				c-0.01,0.1-0.01,0.06,0.14,0.36c-0.25,0.05,0.15,0.17-0.06,0.19c0.05,0.07,0.05,0.19,0.12,0.22c-0.05,0.08,0.05,0.25-0.06,0.26
				c0.03,0.02,0.1,0.12,0.11,0.19c-0.14-0.11,0.02,0.26-0.13,0.24c0.13,0.08,0.08,0.33,0.17,0.49c-0.01-0.01-0.03-0.03-0.03-0.04
				c0,0.09-0.03,0.12-0.06,0.18c0.01-0.1,0.35,0.43-0.02,0.2c0.08-0.01,0.08,0.19,0.15,0.16c-0.06,0.09-0.03,0.08-0.11,0.12
				c0.13-0.07,0.11,0.48-0.06,0.26c0.27,0.21-0.15,0.23,0.23,1.11c-0.56,0.11-0.06-0.01-0.25,1.16l-0.09-0.07
				c0.06,0.59-0.22,1.05-0.71,2.88l-0.01-0.04c-0.08,0.14,0.08,0.22-0.03,0.34l0-0.03c-0.07,0.02-0.07,0.15-0.94,2.06
				c0.12,0.1-0.08-0.21-0.22,0.54c-0.02-0.02-0.01-0.07-0.01-0.12c0,0.19-0.09-0.01-0.09,0.16c0.03-0.01,0.04,0.03,0.05,0.04
				c-0.08,0.05-0.16,0.21-0.2,0.33l-0.04-0.05c-0.12,0.2-0.2,0.46-0.32,0.69c-0.38,0.41,0,0-1.76,3.12l0-0.02
				c-0.4,0.58-0.48,0.87-2.17,3.44l-0.03-0.11c-0.14,0.41-0.46,0.72-0.6,1.12c0-0.03-0.02-0.04,0-0.08c-0.1,0.04-0.17,0.27-0.13,0.3
				c-0.11,0.15-0.05-0.08-0.57,1.05c-0.09-0.07-0.22,0.19-0.22,0.3c-0.01-0.05-0.07,0.06-0.09,0c-0.05,0.1-0.08,0.2-0.02,0.21
				c-0.27,0.26-0.2,0.17-0.64,1.32c-0.07-0.37,0.16-0.57-0.54,1.01c-0.01-0.01-0.01-0.03,0-0.06c-0.01,0.15,0-0.02,0.08,0.33
				c-0.1-0.14-0.14,0.21-0.25,0.01c-0.01,0.03,0,0.08,0.03,0.09c-0.03,0.04-0.05,0.04-0.07-0.02c-0.06,0.05,0.02,0.29-0.08,0.21
				c0.01,0.09,0.02,0.19-0.05,0.3c-0.01-0.01-0.03-0.01-0.02-0.04c-0.09,0.22-0.04,0.42-0.18,0.63c-0.07-0.11-0.07,0.07-0.16,0.04
				l-0.03,0.14l0.06-0.03c-0.05,0.11-0.03,0.23-0.09,0.32l0-0.1l-0.06,0.22l0.08-0.11c0.03,0.38,0.09,0.02-0.26,0.85
				c-0.04-0.1-0.2,0.08-0.09,0.21c0-0.02-0.01-0.01-0.01-0.05c-0.06,0.21-0.1,0.29-0.14,0.35l0.01-0.06c-0.1,0.24,0-0.03-0.24,0.93
				l0.02-0.04c0.12,0.07-0.11,0.17-0.08,0.31c-0.13,0-0.04-0.06-0.13,0.58l-0.03-0.04c-0.05,0.47-0.13,0.37-0.26,1.39
				c-0.03-0.08-0.03-0.13-0.02,0.2c0.06-0.01,0.04-0.03,0.09-0.08c0.03,0.04,0.05,0.11,0.04,0.18c-0.03-0.12-0.09-0.01-0.13,0.04
				c0.02-0.01,0.04,0.01,0.05,0.01c-0.14,0.12,0.05,0.02,0,0.2c-0.01-0.04,0.07,0.36-0.05,0.13c-0.01,0.11,0.1,0.22,0.03,0.31
				c-0.01-0.05-0.01-0.11-0.02-0.16c-0.09,0.1-0.07,0.11-0.09,0.19l0-0.01l-0.04,0.18l0.02-0.04c0.17,0.14,0.03,0.27,0.14,0.45
				c-0.03-0.09-0.23-0.12-0.17,0.31c-0.07-0.07,0.12,0.71,0.18,1.55c-0.08-0.03-0.19-0.08,0.07,0.42l0.01-0.07
				c-0.08,0.11-0.12-0.24-0.02-0.08c0.13-0.09-0.07-0.27-0.08-0.41c0.09,0.08,0.21,0.02,0.1-0.35c0.02,0.02,0.07-0.02,0.06,0.04
				c0.05-0.18-0.1-0.27-0.02-0.34l0.01,0.01c0-0.25,0.05-0.64-0.04-1.7c0.03,0.01,0.08-0.28,0.13-0.42l0.01,0.01
				c-0.09-0.04-0.06-0.18-0.06-0.29c0.05,0.01,0.07,0.03,0.09,0.09c-0.01-0.01,0.04-0.02-0.03-0.23l0.07,0.03
				c0.01-0.13-0.06-0.28,0.02-0.44c-0.06-0.02-0.2,0.11-0.21-0.08c0.04,0,0.1,0,0.1,0.05c0-0.02-0.02-0.06,0-0.09l0.04,0.08
				c-0.02-0.04,0.04-0.24,0.04-0.06c0.02-0.07,0.07-0.15,0.11-0.17c-0.13-0.22,0,0,0.01-0.61l0,0c-0.03-0.24,0,0,0.18-0.68
				c0.1,0.05,0.04-0.21,0.22-0.71c0.01,0.11,0.05-0.02,0,0.11c0.06-0.12,0.11-0.14,0.09-0.3c0.05,0.07,0.11-0.07,0.11-0.14
				l-0.07,0.08c0.01-0.07,0.04-0.13,0.06-0.19l0.07,0.06c0.13-0.28,0.19-0.58,0.35-1.21c-0.22,0.04,0.31-0.2,0.12-0.23
				c0.09-0.14,0.1-0.23,0.19-0.38c-0.05,0.04-0.02-0.55,0.19-0.31c0.01-0.16,0.15-0.25,0.19-0.32l-0.01,0.08
				c0.01-0.05,0.02-0.09,0.04-0.12l-0.07,0.02c0.03-0.04,0.04-0.08,0.07-0.06c-0.03-0.11-0.2-0.07,0.02-0.29
				c-0.15,0.2,0.23,0.23,0.13-0.2c0.03,0.04,0.1,0,0.1-0.04c-0.02,0.06-0.11-0.03-0.02-0.27l-0.05,0.06
				c-0.05-0.19,0.19-0.27,0.5-0.72c-0.07,0.01-0.07,0.03-0.06-0.1l-0.08,0.25l0-0.19c-0.04,0.07-0.04,0.17-0.07,0.2
				c-0.01-0.09,0-0.08-0.06-0.07c0.2-0.37,0.32,0,0.53-0.76l-0.06,0.06c-0.04-0.06,0.06-0.14,0.08-0.22c0.04,0.03,0.08,0,0.12-0.02
				c-0.03-0.01,0.09-0.15,0.01-0.22c0.18-0.15,0.15-0.04,0.2-0.49c0.02,0.08,0.09,0.02,0.06,0.15c0.17-0.58-0.03-0.02,0.76-1.48
				c0.01,0.01,0.02,0.01,0,0.03c0.17-0.38,0.02,0.01,0.38-0.51l-0.01,0.03c0.1-0.06,0.08-0.24,0.17-0.38c0,0,0.01,0.01,0.02,0.01
				c-0.01-0.14,0.08-0.34,0.15-0.49c0.02,0.02,0.03,0.03,0.04,0.04c-0.03-0.05-0.04-0.14,0.18-0.19l-0.07,0.12
				c0.11-0.01,0.09-0.15,0.14-0.2l0.03,0.03c0.13-0.33,0.03-0.29,0.45-1.01c0.02,0.11-0.04,0.27-0.06,0.38
				c0.04-0.07,0.14-0.23,0.12-0.29c-0.01,0.03-0.01,0.08-0.04,0.1c0.25-0.63-0.03-0.05,1.06-1.46c-0.13-0.05-0.11-0.14-0.02-0.23
				c0.02,0.03,0.02,0.16,0.11,0.08c-0.09,0.05-0.02-0.11-0.08-0.1c0.1-0.08,0.24-0.14,0.37-0.14c-0.06-0.01-0.04-0.09,0.03-0.16
				l-0.13,0.08c0.06-0.24,0.22-0.1,0.27-0.35l0,0.09c0.11-0.11,0.09-0.19,0.66-1.28l0.02,0.04c0.04-0.16,0.07-0.04,0.16-0.35
				l0.01,0.07c0.18-0.22,0.13-0.43,0.22-0.65c0.1,0.06,0.24-0.12,0.31-0.21l0.01,0.01c0.27-0.36-0.18-0.12,0.84-1.61
				c-0.02,0-0.11-0.1,0.03-0.23c-0.02,0.04,0.32-0.13,0.1-0.32c0.17-0.05,0.17-0.41,0.81-1.54l0.03,0.07c0-0.47-0.02-0.19,0.3-1.05
				c0.01,0.01,0.04,0.03,0.03,0.07c0.04-0.11,0.22-0.4,0.07-0.41c0.09,0.03,0.09-0.24,0.1-0.37l0.06,0.04
				c-0.02-0.01-0.01-0.17,0.02-0.52l0.03,0.07c0.06-0.19-0.14-0.16,0.13-0.43c-0.01,0.06-0.05-0.21,0.01-0.13
				c0.15-0.18-0.08-0.02,0.15-0.46c-0.04,0.17-0.08,0.29-0.08,0.28c0.06,0.02,0.11-0.11,0.17,0.02c0.06-0.09-0.12-0.27,0.14-0.85
				c-0.07-0.01-0.04-0.09-0.11-0.02c0.01-0.02-0.01,0.03,0,0.09l-0.01-0.01c-0.01,0.04-0.02,0.1-0.03,0.17
				c-0.06-0.04,0.11-0.1-0.08-0.3c0.26-0.17,0.23-0.17,0.29-0.97c0.04,0.17,0.01,0.16,0.06-0.4l0.02,0.03
				c0.19-0.39-0.18-0.67,0.05-1.1c-0.01,0.03-0.02,0.05-0.04,0.05C631.28,266.57,631.44,266.83,631.2,265.21z M630.47,270.06
				c0.01,0.03,0-0.18,0.07-0.08C630.52,269.97,630.5,270.03,630.47,270.06z M630.53,269.79c0-0.03,0,0,0.01-0.07
				C630.54,269.74,630.53,269.76,630.53,269.79z M631.04,269.57c0.01,0.01,0,0,0.03,0.03C631.06,269.64,631.02,269.7,631.04,269.57z
				"
                />
                <path
                  className="st10"
                  d="M530.22,242.94c0.04-0.04,0.17-0.04,0.35,0.28l-0.17-0.13c0.07,0.16,0.13,0.13,0.9,0.87
				c-0.16-0.01-0.14,0,0.55,0.85c-0.05-0.03-0.03,0.08-0.12-0.07c0.26,0.45,0.57,0.63,0.61,0.83l-0.03-0.03
				c0.81,1.3,0.64,0.55,2.03,3.69c-0.1,0.02,0-0.11,0.4,0.82c-0.04-0.02,0.31,0.82,0.45,1.23l-0.02-0.01
				c0.12,0.04,0.28,0.46,0.42,0.75c-0.06,0.01-0.1-0.04-0.19-0.19c0.03,0.03-0.03,0.03,0.31,0.6c-0.25-0.15,0.01,0.17,0.71,1.4
				l-0.14-0.18c0.07,0.21,0.25,0.38,0.05,0.19c0.38,0.97,0.16,0.43,1.02,2.15l0,0c0.4,0.38,0.22,1.06,2.23,3.76
				c-0.23-0.22,0.04,0.11-0.23-0.24c0.24,0.33,0.27,0.43,0.59,0.74c-0.14-0.08,0.13,0.3,0.28,0.42l-0.15-0.24
				c0.15,0.16,0.27,0.3,0.39,0.45l-0.13-0.02c0.53,0.65,1.2,1.43,2.62,2.79c-0.11-0.4,0.55,0.85,0.54,0.58
				c0.26,0.26,0.69,0.77,2.64,2.36l-0.22-0.12c0.13,0.08,0.23,0.14,0.33,0.21l-0.08-0.13c0.12,0.09,0.22,0.16,0.2,0.18
				c0.36,0.06,0.12-0.12,0.77,0.35c-0.69-0.44-0.48,0.09,0.63,0.37c-0.09,0.01,0.09,0.13,0.18,0.17c-0.17-0.09,0.14-0.07,0.71,0.18
				l-0.2-0.11c0.77,0.18,1.17,0.78,4.41,1.8l-0.22-0.1c0.68,0.11,0.63,0.32,3.03,0.79c-0.18,0.01,0.06,0.11-0.32,0.05
				c0.91,0.1-0.25-0.08,4.93,0.99c-0.01,0.01-0.01,0.01-0.02,0.01c1.17,0.15-0.01,0.03,1.82,0.38l-0.1-0.01
				c0.45,0.1,0.13,0.04,2.72,0.24c-0.01,0.02-0.02,0.03-0.03,0.04c0.09-0.04,0.3-0.06,0.74,0.16l-0.4-0.05
				c0.19,0.12,0.52,0.07,0.73,0.11l-0.03,0.04c0.62,0.07,0.64-0.03,1.31,0.07l-0.07-0.04c0.94,0.01,0.35,0.27,2.05,0.22
				c-0.25,0.04-0.76,0.02-1.08,0.02c0.24,0.03,0.81,0.09,0.94,0.06c-0.1,0-0.22,0.01-0.31-0.02c2,0.12,0.09-0.04,5.35,0.76
				c-0.33-0.19,0.28,0.03,0.25-0.13c0.12,0.01,0,0,0.33,0.05c0,0,0,0-0.01,0c0.31,0.07,0.56,0.21,1,0.41
				c-0.06-0.06,0.18-0.05,0.46,0.01l-0.39-0.13c0.7,0.02,0.58,0.21,1.29,0.23l-0.23,0.02c0.69,0.14,0.69,0.03,4.22,0.62l-0.07,0.02
				c0.27,0.02,0.11,0.04,1.12,0.19l-0.19,0.01c0.81,0.21,1.28,0.19,1.97,0.33c-0.03,0.11,0.61,0.29,0.92,0.39l-0.02,0.01
				c1.48,0.37,4.31,1.19,9.99,3.42l-0.04,0.06c0.54,0.25,0.81,0.38,1.49,0.65l-0.16-0.01c1.34,0.42,0.63,0.19,2.94,1.27
				c-0.02,0.01-0.05,0.03-0.16-0.02c0.3,0.16,1.14,0.67,1.1,0.48c-0.04,0.09,0.66,0.34,1.03,0.5l-0.09,0.03
				c0.05,0,0.47,0.19,1.35,0.61l-0.17-0.04c0.41,0.13,0.77,0.46,2.69,1.63c-0.42-0.26-0.76-0.48-0.73-0.46
				c-0.03,0.05,0.3,0.28-0.02,0.18c0.29,0.16,0.02-0.05,1.17,0.61l-0.04-0.02c0.46,0.29,0.23,0.2,1,0.73
				c0.03-0.07,0.24,0.09,0.07-0.1c0.05,0.04-0.08-0.05-0.22-0.13c0,0,0.01,0,0.01,0c-0.11-0.07-0.25-0.17-0.41-0.28
				c1.22,0.65,0.8,0.62,3.1,2.2c-0.09-0.05-0.18-0.12-0.24-0.14c0,0.03,0.82,0.42,1.17,0.89l-0.08-0.03
				c0.81,0.87,1.64,1.06,2.45,2.12c-0.06-0.07-0.09-0.11-0.09-0.13c1.2,1.35,2.88,2.61,4.98,5.85l-0.02-0.11
				c0.16,0.24,0.55,0.71,0.46,0.71c0.23,0.12,1.1,2.32,1.13,1.68c0.14,0.32,0.52,0.23-0.11-0.8l0.21,0.33
				c-0.16-0.35-0.54-1.22-2.16-3.15c0.34,0.23-0.46-0.65-0.3-0.64c-0.28-0.15-0.7-0.77-1.07-1.12c0.03,0.02,0.08,0.05,0.1,0.09
				c0.2-0.14-1.16-0.88-0.7-0.95c-0.05,0.07-0.48-0.39-0.44-0.26c-0.14-0.24-0.14-0.19-0.17-0.34c0.04,0.23-1.09-1.01-0.52-0.65
				c-0.89-0.54,0-0.08-2.29-1.95c-0.08,0.16-0.03,0.14-0.37-0.15c0.11-0.36,0.32-0.05-2.75-2.33l0.18,0.02
				c-1.39-0.7,0.1-0.45-7.72-4.76l0.12,0.04c-0.42-0.25-0.56-0.12-0.92-0.38l0.08,0.03c-0.3-0.27-3.21-1.54-5.44-2.59
				c0,0.04-0.29-0.07-0.79-0.3c0.13,0.28-0.04-0.18-1.66-0.62c0.03-0.02,0.19,0.02,0.31,0.05c-0.89-0.27-0.17-0.05-1.68-0.59
				l0.09-0.02c-0.67-0.27-1.44-0.48-2.2-0.74c0.34-0.07-4.9-1.44-10.58-2.46l0.04,0c-1.95-0.23-0.53-0.06-3.81-0.56
				c0.02,0,0.02-0.02-0.04-0.07c0.01-0.01-0.03,0.03-0.01,0.07c-0.5-0.08-1.09-0.16-1.83-0.27l0.04,0.05
				c-1.26-0.17-0.38,0.15-6.49-0.76l0.23-0.05c-1.26-0.06-2.53-0.36-3.75-0.47c0.45-0.02-1.12-0.2-4.26-0.76
				c0.05-0.1-0.76-0.26-1.06-0.29c0.1-0.01-0.24-0.1-0.11-0.11c-0.32-0.08-0.62-0.13-0.58-0.06c-1.04-0.42-0.79-0.23-4.16-1.22
				c0.15,0.01,0.26,0.03,0.35,0.04c-0.56-0.23-0.74-0.17-3.45-1.38c0.01-0.01,0.08,0.01,0.16,0.04c-0.45-0.14,0.31,0.12-0.81-0.14
				c0.28-0.01-0.63-0.37-0.2-0.35c-0.09-0.04-0.21-0.07-0.2-0.03c-0.12-0.08-0.15-0.11,0-0.08c-0.46-0.23-0.33-0.1-0.73-0.34
				c0.01-0.02,0.1,0.02,0.16,0.02c-0.23-0.08-0.46-0.18-0.77-0.42c0.03,0,0-0.04,0.1,0.03c-0.56-0.39-1.02-0.6-1.57-1.13
				c0.12,0.03,0.21,0.02-0.46-0.55l0.09,0.14c-0.26-0.24-0.54-0.41-0.73-0.65l0.22,0.16l-0.49-0.46l0.24,0.29
				c-0.29-0.22-0.06,0.11-0.37-0.24c0.07,0.06-0.25-0.26-0.34-0.43l0,0c-0.55-0.48-0.05-0.28-1.15-1.22
				c0.22,0.05-0.28-0.47-0.45-0.53c0.03,0.03,0.03,0.01,0.1,0.08c-0.42-0.48-0.58-0.69-0.71-0.86l0.12,0.12
				c-0.52-0.63,0.03,0.05-1.76-2.22l0.07,0.12c-0.38-0.27,0.08-0.18-1.36-2.34l0.07,0.07c-0.68-1.31-0.48-1.03-1.73-3.81
				c0.13,0.21,0.18,0.32-0.24-0.55c-0.03,0.08,0.01,0.11,0.03,0.28c-0.07-0.08-0.18-0.27-0.26-0.45c0.17,0.31,0.08-0.04,0.05-0.19
				c-0.01,0.03-0.04-0.01-0.06,0.01c-0.07-0.41-0.13-0.02-0.29-0.55c0.07,0.03,0.17,0.1-0.5-1.16c0.07,0.13,0.15,0.28,0.22,0.4
				c-0.06-0.31-0.09-0.34-0.17-0.55l0.01,0.02l-0.19-0.5l0.04,0.13c-0.32-0.29-0.37-0.71-0.71-1.14c0.32,0.42,0.09,0.05,0.18,0.05
				c-0.04-0.13-0.11-0.33-0.07-0.28c-0.09-0.17-0.16-0.1-0.44-0.89c0.47,1.08-1.24-2.07-2.68-3.55c0.19,0.11,0.39,0.03-0.95-0.87
				L530.22,242.94z M605.67,284.65c-0.13-0.02-0.08,0-0.22-0.13C605.51,284.55,605.44,284.53,605.67,284.65z M605.27,283.82
				c-0.04-0.02-0.09-0.06-0.18-0.11C605.17,283.75,605.09,283.71,605.27,283.82z M604.58,283.45c0.05,0.01-0.09-0.09-0.19-0.17
				C604.48,283.33,604.74,283.45,604.58,283.45z M550.29,268.45c0.39,0.16,0.56,0.28,0.74,0.39l-0.76-0.24l0.51,0.1
				c-0.22-0.09-0.49-0.14-0.62-0.2C550.41,268.53,550.38,268.53,550.29,268.45z"
                />
                <path
                  className="st10"
                  d="M605.9,284.73c-0.03-0.01,0.02-0.04-0.11-0.07C605.79,284.66,605.82,284.69,605.9,284.73z"
                />
                <path
                  className="st10"
                  d="M605.35,300.51c-0.04,0.04-0.17,0.04-0.32-0.25l0.15,0.11c-0.06-0.23-0.58-0.51-0.88-0.73
				c0.14-0.02,0.15-0.05-0.67-0.66c0.06,0.01,0.01-0.09,0.13,0.03c-0.35-0.35-0.7-0.37-0.8-0.54l0.04,0.02
				c-1.24-0.67-0.67-0.37-3.47-1.86c0.07-0.09,0.08,0.09-0.79-0.31c0.04-0.03-0.74-0.35-1.1-0.53l0.02-0.01
				c-0.11,0.06-0.48-0.11-0.78-0.19c0.03-0.05,0.09-0.05,0.25-0.02c-0.04,0,0-0.05-0.61-0.16c0.26-0.09-0.12-0.1-1.4-0.38l0.21,0.01
				c-0.18-0.09-0.42-0.06-0.16-0.08c-0.89-0.35-0.39-0.16-2.1-0.65l0,0c-0.68-0.09-0.84-0.36-3.84-1.24
				c0.29,0.05-0.08-0.07,0.29,0.06c-0.35-0.14-0.42-0.2-0.82-0.26c0.15-0.02-0.24-0.17-0.42-0.2l0.23,0.13
				c-0.19-0.06-0.36-0.12-0.52-0.18l0.12-0.05c-0.72-0.3-1.61-0.55-3.32-1.17c0.22,0.33-0.75-0.55-0.66-0.31
				c-0.4-0.22-0.65-0.29-1.06-0.5c-0.01,0.05-1.27-0.33-0.9-0.48c-0.41-0.13-0.7-0.38-0.91-0.49l0.21,0.08
				c-0.13-0.05-0.23-0.09-0.33-0.14l0.09,0.11c-0.11-0.07-0.21-0.11-0.2-0.14c-0.33,0-0.1,0.15-0.73-0.24
				c0.47,0.26,0.01-0.07,0.38,0.09c-0.46-0.27-0.23-0.21-0.96-0.41c0.08-0.01-0.08-0.13-0.16-0.16c0.15,0.09-0.13,0.07-0.65-0.18
				l0.18,0.11c-0.36-0.04-0.8-0.48-2.1-1.23c0.09,0.11,0.11,0.11-0.19,0.02l0.67,0.3l-0.46-0.14c0.2,0.1,0.43,0.18,0.55,0.25
				c-0.22-0.06-0.19-0.06-0.12,0.03c-0.88-0.49-0.42-0.53-2.23-1.27l0.18,0.12c-0.62-0.19-0.46-0.39-2.63-1.21
				c0.17,0.02-0.04-0.12,0.3,0.01c-0.8-0.25,0.21,0.12-4.29-1.8c0.01,0,0.02-0.01,0.02-0.01c-1.04-0.35,0.01-0.03-1.59-0.68
				l0.09,0.02c-0.39-0.17-0.11-0.07-2.43-0.69c0.02-0.02,0.03-0.03,0.04-0.04c-0.09,0.02-0.29,0.01-0.65-0.28l0.36,0.12
				c-0.16-0.15-0.46-0.15-0.65-0.23l0.04-0.03c-0.55-0.17-0.59-0.08-1.18-0.28l0.05,0.05c-0.86-0.16-0.27-0.32-1.83-0.54
				c0.24,0,0.7,0.1,0.99,0.15c-0.21-0.07-0.72-0.22-0.85-0.21c0.09,0.02,0.2,0.03,0.28,0.07c-1.81-0.43-0.09,0.02-4.75-1.58
				c0.26,0.24-0.25-0.08-0.25,0.09c-0.54-0.19-0.17,0.01-1.13-0.65c0.04,0.07-0.18,0.02-0.42-0.08l0.33,0.18
				c-0.64-0.12-0.49-0.29-1.13-0.43l0.21,0.02c-0.59-0.25-0.63-0.13-3.75-1.24l0.07-0.01c-0.25-0.06-0.09-0.05-0.98-0.36l0.17,0.03
				c-0.69-0.35-1.15-0.35-1.75-0.61c0.05-0.1-0.49-0.39-0.76-0.54l0.02-0.01c-1.36-0.63-3.88-1.7-8.03-5.39l0.06-0.04
				c-0.36-0.41-0.54-0.62-1.01-1.1l0.13,0.07c-0.64-0.52-0.7-0.62-1.79-2.29c0.02,0,0.06,0,0.11,0.09c-0.17-0.26-0.54-1.07-0.6-0.92
				c0.08-0.05-0.29-0.62-0.48-0.93l0.09,0.02c-0.05-0.02-0.26-0.39-0.65-1.21l0.1,0.12c-0.14-0.34-0.41-1-0.95-2.74
				c0.12,0.45,0.25,0.79,0.23,0.76c0.06-0.02-0.05-0.38,0.13-0.12c-0.12-0.28-0.04,0.03-0.46-1.13l0.02,0.03
				c-0.16-0.48-0.05-0.28-0.28-1.11c-0.06,0.03-0.12-0.2-0.11,0.03c-0.01-0.06,0.02,0.09,0.08,0.22l-0.01-0.01
				c0.04,0.12,0.08,0.27,0.14,0.44c-0.52-1.18-0.15-0.92-0.96-3.39c0.04,0.09,0.06,0.19,0.09,0.24c0.02-0.03-0.38-0.75-0.39-1.31
				l0.05,0.07c-0.14-1.11-0.71-1.67-0.87-2.9c0.02,0.08,0.02,0.13,0.01,0.15c-0.56-1.33,0.45-0.25-2.88-6.56l0.01,0.11
				c-0.15-0.22-0.52-0.66-0.44-0.66c-0.25-0.19-1.35-1.95-1.31-1.37c-0.2-0.28-0.51-0.08,0.23,0.72l-0.24-0.25
				c0.23,0.35,0.74,0.84,2.08,2.74c-0.29-0.24,0.35,0.63,0.18,0.61c0.22,0.18,0.45,0.83,0.7,1.22c-0.29-0.18-0.11-0.13,0.25,0.92
				c0.07-0.04,0.27,0.49,0.3,0.36c0.03,0.25,0.05,0.21,0.02,0.35c0.07-0.22,0.54,1.24,0.19,0.73c0.51,0.8,0.01,0.04,0.97,2.57
				c0.15-0.08,0.11-0.1,0.21,0.3c-0.31,0.27-0.24-0.1,0.77,3.23l-0.13-0.11c0.71,1.32-0.47,0.29,2.79,7.93l-0.07-0.09
				c0.17,0.42,0.34,0.4,0.5,0.79l-0.05-0.06c0.38,0.76,1.86,2.94,3.43,4.44c0.01-0.03,0.24,0.15,0.59,0.52
				c0-0.33-0.06,0.24,1.29,1.03c-0.03,0.01-0.16-0.07-0.27-0.13c0.73,0.48,0.14,0.09,1.35,0.98l-0.09,0
				c0.54,0.39,1.2,0.74,1.83,1.15c-0.45-0.06,4.25,2.21,9.22,4.04l-0.04-0.01c2,0.62,0.01,0,5.03,1.7l-0.03-0.05
				c1.11,0.36,0.38-0.09,5.78,1.78l-0.22,0.01c1.13,0.26,2.23,0.77,3.33,1.07c-0.45-0.06,1.22,0.48,3.72,1.48
				c-0.07,0.09,0.64,0.39,0.9,0.46c-0.1-0.01,0.2,0.13,0.08,0.13c0.28,0.13,0.54,0.23,0.52,0.15c0.87,0.57,0.67,0.39,3.64,1.75
				c-0.14-0.03-0.24-0.05-0.32-0.08c0.51,0.24,0.61,0.33,3.11,1.54c-0.01,0.01-0.08-0.02-0.15-0.04c0.42,0.15-0.28-0.12,0.75,0.14
				c-0.27,0.03,0.6,0.34,0.18,0.34c0.08,0.03,0.2,0.06,0.19,0.02c0.12,0.07,0.14,0.11,0.01,0.08c0.45,0.19,0.32,0.07,0.72,0.27
				c-0.01,0.02-0.1-0.01-0.15,0c0.22,0.06,0.45,0.11,0.78,0.29c-0.03,0,0,0.04-0.1-0.01c0.58,0.28,1.05,0.36,1.67,0.7
				c-0.12,0-0.2,0.06,0.56,0.36l-0.12-0.1c0.3,0.14,0.6,0.2,0.85,0.34l-0.24-0.06l0.58,0.23l-0.31-0.17
				c0.33,0.09,0.01-0.12,0.41,0.07c-0.09-0.03,0.31,0.13,0.45,0.25l0,0c0.66,0.18,0.15,0.24,1.46,0.55
				c-0.21,0.07,0.43,0.27,0.6,0.23c-0.04-0.01-0.03,0.01-0.11-0.02c0.56,0.18,0.78,0.28,0.96,0.37l-0.14-0.04
				c0.7,0.27-0.05,0,2.53,0.79l-0.12-0.05c0.8,0.13,0.49,0.38,2.41,0.74c-0.04-0.01-0.46-0.13,3.58,1.22
				c-0.22-0.04-0.33-0.07,0.53,0.19c-0.03-0.07-0.08-0.06-0.2-0.16c0.1,0,0.3,0.04,0.47,0.1c-0.32-0.07-0.03,0.09,0.09,0.17
				c-0.02-0.02,0.04-0.03,0.03-0.05c0.31,0.23,0.1-0.09,0.55,0.16c-0.07,0.04-0.17,0.04,1.08,0.44c-0.12-0.03-0.28-0.1-0.41-0.13
				c0.24,0.17,0.27,0.18,0.46,0.27l-0.02-0.01l0.44,0.23l-0.11-0.07c0.4-0.01,0.69,0.26,1.18,0.38c-0.47-0.11-0.1,0.02-0.16,0.07
				c0.1,0.07,0.26,0.18,0.2,0.17c0.15,0.07,0.2-0.04,0.8,0.42c-0.88-0.62,2,0.95,3.47,2.1c-0.19-0.03-0.33-0.07,0.89,0.77
				L605.35,300.51z M533.8,261.29c0.01,0.04,0.03,0.09,0.06,0.18C533.83,261.4,533.86,261.48,533.8,261.29z M534.06,261.97
				c-0.03-0.04,0.01,0.15,0.03,0.26C534.13,262.29,533.91,261.84,534.06,261.97z M534.11,260.68c-0.03-0.05,0.01,0-0.08-0.22
				C534.11,260.55,534.09,260.51,534.11,260.68z"
                />
                <polygon
                  className="st10"
                  points="572.01,285.74 572.01,285.74 571.97,285.72 			"
                />
                <path
                  className="st10"
                  d="M526.12,242.4c0.02-0.02,0.12-0.08-0.07-0.14l0.1-0.04c0,0.02-0.11-0.08,0-0.14
				c-0.02-0.1-0.04-0.16-0.23-0.07l0.13-0.14l-0.07,0.04c0-0.01-0.01-0.03,0.02-0.04c-0.06,0-0.02,0.02-0.08,0.04
				c0.04-0.07-0.09-0.07,0-0.15c-0.06,0.02-0.01,0.06-0.07,0.04c0.14-0.07-0.12-0.05,0.06-0.14c-0.15,0,0.03-0.15-0.18-0.15
				c0.04-0.01-0.03-0.01-0.01-0.18c-0.08,0.05-0.1-0.01-0.13-0.02c0.05-0.03,0.07-0.04,0.1-0.04c-0.02-0.02-0.02-0.03-0.09-0.03
				l0.06-0.03c-0.03-0.04-0.12-0.03-0.12-0.1c-0.05,0.04-0.11,0.14-0.19,0.12c0.03-0.02,0.07-0.06,0.09-0.06
				c-0.01,0-0.02-0.09,0.04-0.07c-0.01-0.02,0-0.07,0.02-0.1c-0.02,0.01-0.14,0.06-0.03-0.06c-0.03,0.01-0.02-0.03-0.08,0.01
				c-0.01-0.01,0.02-0.03,0.03-0.05c-0.04,0.01,0,0-0.13-0.01l0,0c-0.13,0.05-0.01-0.1-0.28-0.41c0.06,0,0.02-0.03,0.05,0.01
				c-0.01-0.05,0.01-0.08-0.07-0.08c0.06,0.03,0-0.15,0-0.03c0-0.2-0.39-0.22-0.29-0.44l-0.03,0.02c0.01-0.04-0.03-0.08,0.01-0.1
				c-0.04-0.01-0.05,0.02-0.04,0.05c-0.01-0.07-0.06-0.07-0.07-0.13c-0.03,0.06-0.28-0.01-0.05-0.13c-0.08,0-0.04-0.1-0.05-0.13
				l0.04,0c-0.02,0-0.03-0.01-0.04-0.02l-0.03,0.05c0-0.02-0.01-0.03,0.01-0.05c-0.04,0.01-0.06,0.08-0.12,0.09
				c0.01-0.04-0.07-0.03-0.02-0.09c0.03,0.07,0.05-0.06,0.1-0.03c-0.04-0.09,0.04-0.09-0.14-0.05c0.04-0.02,0.05-0.07,0.04-0.07
				c0.02,0.02-0.04,0.06-0.09,0.07c0-0.06-0.05-0.02-0.06-0.04c-0.01,0.04,0.01,0.05-0.07,0.02c0.02-0.03,0.04,0,0.04-0.02
				c-0.08,0.03,0.01-0.11-0.06-0.11c0.01-0.04,0.06-0.06,0.06-0.09c-0.09,0.04,0.02-0.15-0.16-0.03l0.1,0.03
				c-0.15,0.03-0.09,0.12-0.24-0.2c-0.01,0.04,0,0.08-0.08,0c0.04-0.03,0.04-0.06,0.05-0.09c-0.02,0.03-0.04-0.05-0.12,0.02
				c0-0.09,0.06-0.12-0.17-0.08c0.05-0.03,0.05-0.07,0.11-0.07c-0.08-0.02,0.02,0-0.25,0.01c-0.03-0.16,0.07-0.02-0.67-0.6
				c0.05-0.04,0.04-0.01,0.07-0.07c-0.01,0.19-0.04-0.27-0.46-0.24c0.07-0.04,0.14-0.03,0.19-0.04c-0.02-0.02-0.08-0.05-0.12-0.02
				c0.03,0.02,0.04,0.03-0.13,0.01c0.13-0.14-0.08-0.13-0.02-0.27c-0.12,0-0.14-0.14-0.23-0.2c-0.02,0.07-0.22,0.13-0.05-0.03
				c-0.01,0.06-0.06,0.05-0.08,0.1c-0.02-0.04-0.01-0.13,0.05-0.26c-0.03,0.05-0.06,0.05-0.08,0.01c0.04,0.07-0.15-0.02-0.05-0.07
				c-0.05,0-0.12-0.09-0.23-0.01c0-0.02,0.02-0.06,0.04-0.06c-0.12-0.12-0.19,0.02-1.14-0.76c0.01-0.01-0.06,0.12-0.11,0.02
				l0.01,0.01c0.02-0.05-0.04-0.22-0.13-0.01c0.01-0.22-0.27-0.07-0.23-0.25c-0.04,0.02-0.06,0-0.1,0.02
				c0.02-0.08,0.07-0.07-0.13-0.16l0.04-0.04c-0.1,0.08-0.13-0.15-0.5-0.11c0.04-0.08-0.07-0.05-0.13-0.03
				c0.11-0.14-0.04,0.02-0.46-0.22c0.05,0.02,0.09,0.05,0.09,0.05c0.09-0.04-0.16-0.28-0.28-0.11c0-0.01,0.01,0,0.03-0.01l0,0.01
				c0.02,0.01,0.03,0.01,0.05,0.02c-0.03,0.03-0.06,0.05-0.07,0c-0.03,0.04-0.06,0.07-0.08,0.09c-0.07,0.01-0.2-0.28-0.74-0.4
				c0.01,0.01,0.01,0.02,0,0.04c-0.09-0.04-0.09-0.2-0.58-0.19l0.01-0.07c0.07,0.18-0.24-0.19-0.64-0.04c0.01,0,0.01,0,0.01,0
				c-0.02,0.07-0.03,0.17-0.05,0.32l0.07-0.05l0,0.06l-0.04-0.01c0.01,0.06,0.04,0.02,0.06-0.01c-0.01,0.03,0,0.05,0,0.07l0.08-0.09
				c-0.01,0.05-0.05,0.08-0.02,0.11c0.04-0.04,0.01-0.1,0.06-0.12c0.01,0.06-0.01,0.1,0.03,0.06c-0.01,0.03-0.02,0.05-0.03,0.05
				c0.04-0.03,0.07,0.01,0.1-0.05c0,0.07,0.09,0.01,0.05,0.11c0.02-0.02,0.07-0.07,0.09-0.06c-0.08,0.11,0.08,0.04,0.02,0.17
				c0.07-0.1,0.13-0.01,0.2-0.06c-0.01,0.01-0.02,0.02-0.02,0.02c0.03,0.02,0.02,0.06,0.03,0.08c0.04-0.09,0.09-0.09,0.14-0.14
				c0,0.1-0.06,0.1-0.08,0.19c0.03-0.08,0.09-0.05,0.1-0.12c0.01,0.07,0.01,0.04,0,0.12c0.05-0.11,0.03-0.01,0.08-0.07
				c0.02,0.07-0.03,0.11,0.03,0.09c-0.11,0.12,0.2,0.09,0.41-0.07c-0.01-0.01-0.02,0.02-0.03,0.03l0.03-0.02
				c-0.13,0.07,0.02,0.16-0.02,0.09c-0.08,0.16-0.06,0.14,1.96,0.95l-0.03,0.09c0.15-0.08-0.12,0.07,0.22,0.07
				c-0.02,0.03-0.04,0.03-0.06,0.04c0.1-0.08-0.02,0.11,0.09-0.01c0,0.05,0.05,0.09,0.1,0.09c-0.02,0.09-0.05,0.04,0.2,0.15
				c-0.01-0.04,0.04-0.11,0.07-0.11l-0.03,0.08c0.04-0.04,0.11-0.14,0,0.03l0.05,0c-0.01,0.02-0.03,0.03-0.04,0.05
				c0.04-0.03,0.02,0.11,0.08,0.04c-0.35,0.03,0.91,0.34,1.37,0.97c0.01-0.01,0.06-0.1,0.04,0.02c0.02-0.23,0.51,0.49,0.51,0.41
				c0.17,0.01,0.22,0.18,0.37,0.21c-0.02,0.01-0.03,0.03-0.04,0.02c0.02,0.01,0.1,0.08,0.44,0.26c-0.08,0.08,0.01,0.13,0.06,0.11
				c-0.03,0.02,0,0.05-0.05,0.07c0.03,0.02,0.07,0.03,0.1-0.01c-0.04,0.15,0.17,0.02,0.08,0.17c0.16,0.07,0.13-0.02,0.56,0.42
				c-0.01,0.01-0.02,0.01-0.03,0.01c0.01,0.01,0.01,0.04,0.05,0l-0.02,0c0.04-0.07,0.16-0.06,0.19-0.08
				c-0.13,0.08,0.03,0.09-0.13,0.18c0.01,0,0.04-0.01,0.06-0.03c0.02,0.15,0.01,0.03,0.25,0.34c-0.01-0.03,0.04,0.07,0.07-0.03
				c-0.04,0.02,0.15,0.04,0.05,0.06l0.06,0.05l0-0.06c0.09,0.01,0.12-0.09,0.08,0.17c0.07-0.04,0.08,0.06,0.16,0.02
				c-0.02,0.01-0.06,0.04-0.06,0.05l0.06-0.03c-0.01,0.06,0.03,0.02,0.08,0.18l-0.03-0.01l-0.02,0.04l0.04-0.02
				c-0.09,0.06,0.22,0.06,0.27,0.27c-0.01,0-0.07,0.04-0.05,0.03c0.02,0.06,0.1,0.07,0.19,0.12c-0.03-0.02-0.02,0.18,0.09,0.1
				c0,0.12-0.14,0.21,0.32,0.28c-0.05-0.01-0.07,0.06-0.09,0.09c0.01-0.01,0.03-0.02,0.05-0.03c-0.1,0.11,0.1-0.03,0.05,0.04
				c0.02,0.01,0.13,0.05-0.01,0.06c0.09,0.08-0.01-0.03,0.03,0.19c0.17-0.12,0.01-0.01,0.22,0.01c-0.11,0.02-0.03-0.02-0.16,0.13
				c0.07-0.01-0.01,0.07,0.08,0.05c-0.02,0.02,0.02,0.03-0.04,0.06c0.21-0.16-0.05,0.15,0.41,0.15c-0.01,0.05-0.16,0.01-0.03,0.11
				c-0.13,0.06,0.13,0.04-0.07,0.09c0.04,0.01,0.09,0.02,0.13,0.03c-0.03,0.03-0.07,0.02-0.09,0.02
				C526.02,242.38,526.05,242.41,526.12,242.4z M518.6,235.88c-0.01,0.01,0,0.03,0.01,0.06
				C518.59,235.92,518.54,235.92,518.6,235.88z"
                />
                <path
                  className="st10"
                  d="M502.84,249.99c0.25,0.34,0.85,0.95,2.94,0.79l-0.02,0.01c0.83,0.26,0.71-0.05-0.64,1.59l0.05,0
				c-0.03,0.12-0.1,0.1-0.16,0.17c0.19-0.01-1.81,3.51,1.4,2.68l-0.12,0.1c0.18-0.09,0.75-0.07,1.93-0.31
				c-0.05,0.47,0-0.04-0.21,1.52c-0.13-0.02-0.05,0.54,0.08,0.7c-0.05-0.05,0.01,0.17-0.05,0.11c0.07,0.21,0.18,0.39,0.22,0.33
				c0.55,0.85,0.33,1,2.29,1.42c-0.02,0-0.03,0.01-0.04,0.01c0.82,0.01,0.58,0.2,2.11-0.34c0,0.01-0.04,0.04-0.08,0.07
				c0.24-0.16-0.16,0.11,0.35-0.4c-0.06,0.21,0.41-0.19,0.29,0.09c0.05-0.04,0.1-0.1,0.07-0.12c0.08-0.04,0.12-0.04,0.06,0.04
				c0.26-0.21,0.13-0.18,0.37-0.36c0.01,0.02-0.02,0.07-0.03,0.11c0.08-0.14,0.18-0.26,0.36-0.42c0,0.02,0.03,0.02-0.02,0.07
				c0.3-0.3,0.4-0.6,0.71-0.94c0,0.09,0.06,0.15,0.33-0.29l-0.09,0.06c0.12-0.18,0.17-0.37,0.29-0.51l-0.05,0.16l0.19-0.35
				l-0.15,0.18c0.07-0.21-0.11-0.04,0.05-0.27c-0.02,0.05,0.11-0.18,0.21-0.25l0,0c0.15-0.45,0.21-0.02,0.45-0.88
				c0.06,0.15,0.24-0.23,0.2-0.36c-0.01,0.03,0.01,0.02-0.01,0.07c0.17-0.34,0.25-0.47,0.33-0.56l-0.03,0.09
				c0.24-0.4-0.01,0.04,0.79-1.47l-0.05,0.06c0.19-0.48,0.31-0.17,0.89-1.32l-0.01,0.07c0.49-0.7,0.43-0.51,1.57-1.88
				c-0.07,0.13-0.11,0.18,0.24-0.26c-0.07-0.01-0.07,0.02-0.16,0.06c0.01-0.07,0.08-0.18,0.16-0.26c-0.11,0.18,0.07,0.06,0.15,0.02
				c-0.02,0-0.02-0.04-0.03-0.04c0.24-0.1-0.06-0.11,0.22-0.3c0.02,0.06,0.03,0.12,0.53-0.52c-0.04,0.07-0.12,0.15-0.17,0.22
				c0.18-0.07,0.19-0.1,0.29-0.19l-0.01,0.01l0.25-0.19l-0.07,0.04c0.04-0.29,0.32-0.35,0.47-0.64c-0.15,0.29,0.01,0.08,0.04,0.15
				c0.07-0.04,0.18-0.1,0.17-0.07c0.06-0.15,0.21-0.13,0.3-0.34c-0.06,0.09,0.77-0.84,1.99-1.86c0.13,0.26,0.64-1.06,0.7,0.75
				c0.01-0.25-0.25,0.35-0.34,0.23c0.01,0.2-0.28,0.5-0.37,0.77c0-0.02,0-0.05,0.02-0.07c-0.09,0.13-0.15,0.15-0.22,0.22
				c0.1-0.16-0.09,0.81-0.21,0.27c0.07,0.03-0.11,0.33-0.02,0.31c-0.13,0.09-0.09,0.09-0.2,0.11c0.17-0.03-0.33,0.75-0.29,0.36
				c-0.07,0.64-0.05-0.03-0.68,1.52c0.11,0.04,0.16,0.02,0.01,0.24c-0.49-0.02-0.1-0.15-0.97,1.73l-0.05-0.12
				c-0.2,1-0.94,1.66-1.62,4.75l-0.01-0.07c-0.09,0.26,0.08,0.33-0.04,0.56l0-0.05c-0.04,0.21-0.52,1.89-0.46,3.3l-0.08,0
				c0.03,0,0.04,0.16-0.01,0.47c0.22-0.12-0.17,0.05,0.03,0.99c-0.03-0.01-0.04-0.1-0.07-0.17c0.09,0.29-0.08,0.05,0,0.32
				c0.02-0.04,0.05,0.02,0.06,0.02c-0.05,0.13-0.02,0.43,0.01,0.65l-0.06-0.04c0.34,2.17-0.21,2.88,4.22,3.08l-0.01,0.03
				c0.07-0.27,0.07-0.21,0.2-0.54c0,0.31,0,0.29,0.02,0.32c-0.02-0.07,0.17,0.32,0.22,0.53l0.03-0.04c0.1,0.08,0.05,0.14,0.08,0.22
				c0.23-0.25,1.29,2.44,3.34,0.69c-0.01,0.06-0.03,0.11-0.04,0.17c0.22-0.05,0.32-0.25,0.35-0.49c0.65,0.19,0.08,0.61,2.35,0.75
				c-0.05,0.05,0.17-0.02,0.12,0.05c0.21-0.08,0.37-0.22,0.31-0.25c0.71-0.63,0.89-0.47,0.95-2.36c0.01,0.02,0.02,0.04,0.03,0.06
				c-0.11-0.73,0.06-0.59-0.56-1.92c-0.01,0.01-0.02,0.05-0.32-0.24c0.19,0.06-0.14-0.38,0.12-0.27c-0.03-0.04-0.08-0.09-0.11-0.07
				c-0.02-0.08-0.02-0.11,0.05-0.05c-0.15-0.25-0.15-0.13-0.26-0.37c0.02-0.01,0.05,0.03,0.09,0.05c-0.1-0.1-0.2-0.2-0.28-0.41
				c0.02,0.01,0.03-0.03,0.05,0.03c-0.17-0.34-0.39-0.54-0.52-0.94c0.08,0.04,0.15,0.02-0.07-0.4l-0.01,0.1
				c-0.08-0.18-0.21-0.31-0.25-0.48l0.09,0.12l-0.16-0.33l0.05,0.21c-0.12-0.16-0.1,0.07-0.18-0.19c0.03,0.05-0.08-0.18-0.07-0.3
				l0,0c-0.24-0.38,0.12-0.16-0.37-0.85c0.15,0.05-0.01-0.31-0.13-0.36c0.01,0.02,0.02,0.01,0.05,0.05
				c-0.14-0.33-0.17-0.47-0.19-0.58l0.04,0.08c-0.13-0.42,0.02,0.03-0.59-1.46l0.02,0.08c-0.26-0.41,0.07-0.34-0.49-1.41l0.05,0.04
				c-0.28-0.75-0.14-0.61-0.64-2.21c0.07,0.12,0.09,0.18-0.09-0.32c-0.05,0.05-0.02,0.06-0.04,0.16c-0.05-0.04-0.1-0.15-0.13-0.26
				c0.09,0.18,0.09-0.02,0.1-0.11c-0.01,0.02-0.04,0-0.06,0.01c0.04-0.24-0.12-0.01-0.13-0.32c0.06,0.01,0.12,0.03-0.15-0.68
				c0.04,0.07,0.06,0.17,0.09,0.24c0.04-0.18,0.01-0.2,0-0.32l0,0.01l-0.03-0.29l-0.01,0.08c-0.21-0.17-0.12-0.42-0.29-0.69
				c0.16,0.26,0.07,0.03,0.15,0.04c0.01-0.08,0.01-0.19,0.03-0.16c-0.09-0.12,0-0.23-0.12-0.41c0.05,0.09-0.3-1.04-0.44-2.5
				c0.15,0.14-0.08-0.15,0.1-0.92c-0.02,0.01-0.02,0.01-0.04,0.01c0.06-0.13,0.18-0.08,0.23-0.69l0.08,0.02
				c-0.16-0.12,0.02-0.15,0.22-0.9c0.25,0.37,0.51,0.98,0.58,0.61c0.12,0.23,0.45,0.05-0.05-0.6c0.06,0.07,0.11,0.14,0.16,0.21
				c-0.09-0.34-0.91-0.77-0.74-0.9c-0.12-0.02-0.1-0.34-1.08-0.94c0.29,0.07-0.4-0.36-0.26-0.42c-0.22-0.04-0.59-0.4-0.91-0.54
				c0.02,0,0.06,0.01,0.08,0.03c0.14-0.18-1.03-0.32-0.61-0.53c-0.03,0.07-0.39-0.15-0.36-0.05c-0.12-0.15-0.12-0.11-0.16-0.23
				c0.06,0.18-0.92-0.4-0.46-0.33c-0.69-0.08-0.01-0.1-1.9-0.74c-0.03,0.14,0,0.16-0.28,0.02c-0.07-0.47,0.19-0.13-2.25-0.9
				l0.13-0.06c-1.21-0.15-2.18-0.74-5.97-1.27l0.08-0.01c-0.32-0.08-0.39,0.09-0.67-0.03l0.06,0c-0.27-0.04-2.23-0.46-3.95-0.71
				l0.01-0.08c0,0.03-0.19,0.01-0.53-0.13c0.05,0.14-0.12-0.14-1.08-0.37c0.03-0.02,0.12,0.01,0.2,0.03
				c-0.54-0.2-0.11-0.01-0.97-0.54l0.07-0.01c-1.28-1.47-1.48-1.72,3.23-5.29l-0.03,0.01c1.37-0.06,0.52-0.38,3.65,0.38l-0.01-0.05
				c0.13,0,0.14,0.07,0.22,0.11c0.01-0.11-0.08-0.09,0.4,0.06c0.23-0.91-0.21-0.37-2.78-2.39l0.16,0.05
				c-0.6-0.54-0.92-1.34-1.19-2.06c0.78,0.44-1.21-0.34,0.74-2.04c0.01,0.1,0.49-0.01,0.68-0.06c-0.06,0.03,0.16,0.04,0.09,0.08
				c0.21,0,0.41-0.02,0.37-0.08c1.06,0.23,0.8,0,2.78,0.51c-0.15,0-0.26,0-0.32-0.01c0.27,0.15,0.51,0.04,2.28,1.08
				c0-0.02-0.03-0.06,0.45,0.09c-0.22,0.02,0.37,0.32,0.05,0.31c0.06,0.03,0.14,0.06,0.15,0.02c0.07,0.06,0.08,0.1-0.02,0.07
				c0.3,0.18,0.23,0.07,0.48,0.27c-0.02,0.02-0.07-0.01-0.12-0.01c0.15,0.07,0.31,0.13,0.5,0.32c-0.02,0-0.01,0.04-0.07-0.02
				c0.35,0.3,0.67,0.43,1,0.82c-0.1-0.01-0.16,0.01,0.26,0.41l-0.04-0.11c0.16,0.18,0.36,0.28,0.48,0.45l-0.15-0.1l0.31,0.32
				l-0.14-0.21c0.2,0.15,0.07-0.1,0.26,0.16c-0.05-0.04,0.16,0.18,0.2,0.31l0,0c0.39,0.34-0.04,0.21,0.72,0.86
				c-0.18-0.02,0.13,0.35,0.27,0.38c-0.02-0.02-0.02,0-0.07-0.05c0.26,0.34,0.35,0.49,0.41,0.61l-0.07-0.08
				c0.3,0.45-0.03-0.03,1.12,1.55l-0.04-0.09c0.41,0.42,0.05,0.42,0.99,1.53l-0.06-0.04c0.53,0.82,0.36,0.68,1.37,2.45
				c-0.11-0.12-0.15-0.2,0.18,0.36c0.03-0.06,0-0.08-0.01-0.19c0.06,0.04,0.15,0.16,0.21,0.28c-0.14-0.19-0.08,0.04-0.07,0.15
				c0.01-0.02,0.04,0,0.05-0.02c0.03,0.29,0.13-0.01,0.23,0.35c-0.06,0-0.14-0.03,0.36,0.77c-0.06-0.09-0.11-0.17-0.17-0.26
				c0.02,0.22,0.05,0.23,0.1,0.38l0-0.01l0.11,0.34l-0.02-0.09c0.26,0.17,0.25,0.47,0.49,0.76c-0.23-0.28-0.08-0.03-0.16-0.02
				c0.02,0.09,0.05,0.23,0.01,0.2c0.06,0.1,0.15,0.09,0.23,0.61c-0.21-0.8,0.72,1.46,1.16,2.72c-0.12-0.08-0.23-0.15,0.26,0.81
				l-0.02-0.14c-0.05,0.02-0.16-0.02-0.11-0.28l0.06,0.13c0.09-0.18-0.18-0.53-0.27-0.79c0.11,0.03,0.15,0.03-0.1-0.68
				c0.03,0.03,0.06-0.06,0.08,0.06c-0.03-0.18-0.1-0.31-0.15-0.41c0.14,0.08-0.13-0.02-0.02-0.19c0.01,0.28,0.38,0.27,0.22-0.6
				l0.06,0.28c0.07-0.37-0.46-1.21-0.24-1.25c-0.09-0.09,0.06-0.35-0.48-1.49c0.21,0.24-0.17-0.56-0.01-0.54
				c-0.17-0.17-0.29-0.72-0.49-1.05c0.02,0.02,0.05,0.05,0.05,0.08c0.22-0.07-0.68-0.93-0.23-0.85c-0.07,0.04-0.25-0.39-0.27-0.28
				c-0.02-0.21-0.04-0.17,0-0.3c-0.05,0.19-0.55-0.96-0.2-0.58c-0.55-0.54,0.06-0.08-1.17-1.91c-0.11,0.1-0.09,0.13-0.24-0.18
				c0.23-0.41,0.24,0.02-1.38-2.29l0.15,0.04c-0.9-0.75-0.55-1.35-4.62-4.91l0.08,0.04c-0.25-0.26-0.4-0.15-0.61-0.42l0.06,0.03
				c-0.23-0.21-1.82-1.65-3.09-3.11l0.06-0.05c-0.02,0.02-0.16-0.14-0.36-0.49c-0.07,0.24,0.04-0.1-0.63-1.09
				c0.03,0,0.09,0.1,0.15,0.17c-0.29-0.55-0.11-0.09-0.37-1.13l0.06,0.04c0.66-2.08,3.21-3.49,6.04,1.41
				c-0.3-0.51,0.25-0.18,0.3-1.46l0.01,0.06c0.32-0.71-1.3-2.06,0.54-3.58l-0.04-0.03c0.74-0.52,2.36,0.11,3.4,2.36
				c-0.07-0.04,0.3-0.07,0.18,0.17c0.77-0.26,0.16,0.13,0.52-2.15c0.54,0.68-1.03-0.66,2-1.67c-0.02,0.1,0.54,0.11,0.75,0.13
				c-0.07,0.02,0.16,0.07,0.07,0.1c0.22,0.06,0.42,0.11,0.4,0.04c0.94,0.68,0.85,0.26,2.36,1.89c-0.54-0.12,0.1-0.41,0.93,2.25
				c0.01-0.01,0.01-0.07,0.29,0.41c-0.18-0.15,0.08,0.54-0.16,0.29c0.02,0.07,0.06,0.15,0.1,0.13c0.01,0.11,0,0.14-0.06,0.04
				c0.1,0.37,0.13,0.23,0.18,0.58c-0.02,0-0.05-0.07-0.08-0.1c0.08,0.17,0.15,0.34,0.17,0.63c-0.02-0.02-0.03,0.02-0.04-0.07
				c0.08,0.5,0.23,0.85,0.24,1.42c-0.07-0.08-0.14-0.11-0.05,0.53l0.04-0.12c0.01,0.26,0.1,0.49,0.08,0.72l-0.05-0.19l0.05,0.49
				l0.02-0.28c0.06,0.26,0.12-0.04,0.11,0.32c-0.02-0.07,0.02,0.27-0.03,0.41l0,0c0.09,0.56-0.16,0.17,0.05,1.24
				c-0.13-0.14-0.11,0.39-0.02,0.51c0-0.03-0.01-0.02-0.02-0.09c0,0.44-0.02,0.64-0.04,0.79l-0.01-0.09c-0.05,0.59,0-0.04-0.07,2.11
				l0.02-0.1c0.05,0.64-0.23,0.42-0.19,2l-0.02-0.08c-0.11,1.08-0.15,0.83-0.53,3.05c-0.01-0.13,0-0.17-0.1,0.43
				c0.07-0.03,0.05-0.07,0.12-0.17c0.02,0.08,0.01,0.24-0.03,0.38c0.02-0.26-0.09-0.02-0.15,0.08c0.02-0.01,0.03,0.03,0.05,0.02
				c-0.17,0.27,0.1,0.08-0.06,0.46c-0.05-0.05-0.07-0.12-0.25,0.9l0.05-0.34c-0.14,0.2-0.12,0.23-0.18,0.39l0.01-0.02l-0.14,0.37
				l0.05-0.09c-0.02,0.36-0.09,0.38-0.68,1.68c-0.07-0.07-0.15-0.14,0-0.12l-0.03-0.01c0.03-0.03,0.03-0.12,0.08-0.1
				c-0.03-0.05-0.05-0.02-0.06,0.02c0.04-0.08,0-0.12,0.03-0.21c-0.04,0.04-0.24-0.26,0.05-0.18c-0.06-0.07,0.03-0.14,0.04-0.18
				l0.03,0.04c-0.02-0.02-0.02-0.04-0.02-0.06l-0.06,0.02c0.01-0.02,0.01-0.04,0.03-0.04c-0.04-0.02-0.1,0.02-0.15-0.02
				c0.03-0.03-0.04-0.09,0.04-0.11c-0.02,0.13,0.3,0.01,0.02-0.12c0.04,0.01,0.08-0.03,0.07-0.04c0.02,0.02-0.09,0.04-0.13-0.11
				c-0.03,0.03-0.03,0.07-0.07-0.05c0.03-0.01,0.03,0.04,0.04,0.02c-0.16-0.08,0.34-0.3,0.02-0.39l0.05,0.13l-0.09-0.08
				c0,0.04,0.04,0.08,0.04,0.1c-0.05-0.04-0.04-0.03-0.08-0.01c-0.07-0.27,0.27,0.01,0.08-0.46c-0.04,0.03-0.05,0.08-0.06-0.07
				c0.05,0,0.07-0.03,0.09-0.05c-0.04,0.01,0-0.09-0.1-0.09c0.07-0.1,0.12-0.06-0.08-0.25c0.06,0.02,0.09-0.02,0.13,0.04
				c-0.05-0.09,0.01,0.02-0.2-0.23c0.1-0.09,0.1-0.12,0.01-0.57c0.02,0,0.02,0,0.02,0.01c-0.09-0.21,0.02,0,0.02-0.32l0.01,0.01
				c0.05-0.06-0.08-0.11-0.07-0.19c0,0,0.01,0,0.02,0c-0.09-0.04-0.13-0.14-0.17-0.22c0.14,0,0.1,0.02,0.09-0.13
				c0.33,0.2-0.34-0.32-0.06-0.37c-0.03-0.03-0.12-0.12-0.15-0.18c0.08,0.03,0.13,0.1,0.18,0.14c-0.01-0.04-0.03-0.13-0.08-0.14
				c0.01,0.05,0.02,0.07-0.12-0.12c0.19-0.02,0.01-0.21,0.15-0.29c-0.1-0.12-0.03-0.27-0.07-0.43c-0.03,0.07-0.23-0.07-0.06-0.07
				c-0.03,0-0.05-0.03-0.08-0.02c0.02-0.06,0.08-0.13,0.19-0.19c-0.05,0.02-0.08-0.01-0.07-0.06l-0.05,0.08
				c-0.1-0.09,0.1-0.13,0-0.22l0.05,0.02c-0.04-0.05-0.05-0.21-0.18-0.24c0.01-0.01,0.05-0.04,0.07-0.02
				c0.04-0.1-0.06-0.17-0.21-0.57l0.05,0.02c-0.2-0.56-0.06-0.16-0.36-1.33c-0.15-0.07-0.02-0.09-0.37-0.77
				c0.09-0.07,0.08-0.01-0.02-0.28l0.06,0.01c-0.22-0.12-0.12,0.02-0.29-0.45c0.02,0,0.04-0.01,0.06,0
				c-0.02-0.05-0.02-0.23-0.14-0.15c0.09-0.04-0.04-0.12-0.1-0.16l0.07-0.02c-0.02,0.01-0.08-0.03-0.21-0.17l0.05,0.01
				c-0.03-0.09-0.18,0.02-0.07-0.2c-0.02,0,0.01,0-0.12-0.27c0.03,0.07,0.06,0.14,0.05,0.13c0.14,0.02,0.06-0.33-0.19-0.38
				c0-0.01,0.01,0.01,0.03,0.03l-0.01,0c0.01,0.02,0.02,0.04,0.04,0.07c-0.04-0.01-0.08-0.02-0.06-0.07c-0.05,0-0.1,0.01-0.12,0.01
				c-0.06-0.07,0-0.44-0.45-1.11c0,0.02,0,0.03-0.02,0.04c-0.08-0.16,0.07-0.2-0.44-0.74l0.06-0.05c-0.39-0.18-0.11-0.87-0.76-0.37
				l-0.03-0.05c-0.03,0.06,0.02,0.05,0.06,0.06c-0.02,0.02-0.04,0.04-0.04,0.06l0.13,0c-0.21,0.14,0.14,0.01-0.03,0.12
				c0.05,0.01,0.06,0.08,0.13,0.06c-0.04,0.06,0.08,0.1-0.02,0.15c0.03,0,0.11,0.01,0.13,0.04c-0.15,0.01,0.06,0.11-0.07,0.17
				c0.13-0.02,0.13,0.11,0.23,0.15c-0.01,0-0.03,0-0.03-0.01c0.01,0.04-0.01,0.07-0.02,0.11c0.09-0.04,0.14,0.01,0.21,0.01
				c-0.06,0.1-0.12,0.03-0.19,0.09c0.07-0.04,0.11,0.05,0.16,0c-0.03,0.07-0.01,0.05-0.07,0.1c0.11-0.05,0.04,0.02,0.12,0.02
				c-0.05,0.05,0.05,0.13-0.11,0.12c0.18-0.07,0.03,0.26,0.51,0.34c-0.01-0.02-0.03-0.01-0.05-0.01l0.04,0.02
				c-0.58,0.17,0,0,0.39,1.01c-0.09-0.27,0.01,0.37,0.32,0.86l-0.03-0.01c0,0.08,0.17,0.02,0.14,0.12l-0.02-0.01
				c0.01,0.13-0.13,0.11,0.35,0.96l-0.08,0.06c0.17,0.04-0.12,0.01,0.16,0.29c-0.03,0.01-0.05-0.01-0.08-0.03
				c0.15,0.02-0.1,0.07,0.08,0.07c-0.03,0.05-0.01,0.13,0.03,0.19l-0.05,0.01c0.02,0.11,0.1,0.22,0.13,0.34
				c0.01-0.05,0.1-0.07,0.12-0.04l-0.07,0.04c0.05,0,0.18-0.02-0.02,0.02l0.04,0.05c-0.02,0.01-0.04,0-0.07,0.01
				c0.06,0.01-0.05,0.12,0.05,0.13c-0.01,0.1,0.17,0.38,0.33,0.6c-0.06,0.02-0.03,0.11-0.09,0.08c0.04,0.1,0.05,0.13,0.14,0.26
				c0.03-0.04,0.1-0.04,0,0.02c0.13,0.13-0.09,0.4,0.07,0.49l-0.01-0.01c0.12,0.18,0.04,0.12,0.13,0.91c0.03,0,0.1-0.04,0.02,0.05
				c0.23-0.29-0.02,0.55,0.33,1.52c-0.02-0.01-0.04-0.01-0.05-0.02c-0.05,0.05,0.02,0.15,0.06,0.15c0.01-0.07-0.05,0.27,0.12,0.56
				c-0.11,0.01-0.07,0.14-0.02,0.18c-0.04-0.01-0.03,0.04-0.08,0.03c0.01,0.05,0.03,0.1,0.09,0.08c-0.13,0.11,0.12,0.19-0.04,0.26
				c0.07,0.15,0.13,0.29,0.16,0.43c-0.25-0.11-0.12-0.33,0,0.54c-0.01,0-0.02-0.01-0.03-0.02c0,0.02-0.01,0.06,0.04,0.05l-0.01-0.02
				c0.07-0.04,0.16,0.09,0.2,0.09c-0.15-0.03-0.03,0.12-0.22,0.06c0.01,0.02,0.04,0.03,0.07,0.03c-0.08,0.21-0.01-0.01-0.01,0.67
				l0.05-0.03c0,0.06,0.07,0.11,0.03,0.16l-0.04-0.04l0.02,0.11l0.04-0.06c0.07,0.11,0.16-0.03-0.05,0.26
				c0.08,0.03,0.02,0.13,0.1,0.17c-0.02-0.01-0.07-0.01-0.08-0.01l0.06,0.02c-0.05,0.06,0.01,0.07-0.06,0.26l-0.01-0.04l-0.04,0.03
				l0.05,0.01c-0.08,0.01,0.1,0.16-0.1,0.5l0.07-0.04c-0.09,0.17-0.2,0.57-0.24,0.52c0.06,0.18-0.83,1.55-0.37,1.32
				c-0.05,0.09-0.02,0.17,0.04,0.18c-0.05,0.22-0.16,0.65-0.14,0.29c-0.08,0.23-0.21,0.59-0.19,0.68l0.09-0.13
				c-0.05-0.02-0.1-0.13,0.11-0.31l-0.05,0.15c0.19-0.05-0.05-0.05,0.37-0.79c0.07,0.12-0.06,0.2,0.13-0.2
				c0.01,0.01,0.02,0.02,0.03,0.04c0.05-0.06,0.06-0.08,0.07-0.09l0.02,0.01l-0.05,0.04c0.06,0.02,0.06-0.03,0.06-0.08
				c0.05,0.02,0.07,0.07,0.07-0.06c0.07,0.01-0.03,0.03,0.08,0.2c-0.09-0.08-0.03-0.04-0.21,0c0.05,0.3-0.02,0.03,0.04,0.79
				c-0.14-0.06,0.06,0.16-0.12,0.03c0.02,0.05,0.05,0.1,0.07,0.15c-0.04,0.01-0.06-0.04-0.08-0.06c0.07,0.06,0.07,0.22,0.13-0.1
				l0.1,0.04l-0.07-0.08c0.03-0.01,0.09-0.01,0.1,0.02c0.1-0.06-0.01-0.09,0.22,0.07l-0.18-0.1c0.17,0.23,0.88,0.27,0.75,0.46
				c0.09,0,0.12,0.22,0.93,0.56c-0.24-0.02,0.32,0.26,0.19,0.34c0.19,0,0.46,0.28,0.69,0.41c-0.02,0-0.05-0.01-0.06-0.03
				c0.1,0.11,0.11,0.16,0.17,0.24c-0.14-0.12,0.75,0.2,0.22,0.23c0.05-0.06,0.29,0.15,0.29,0.06c0.06,0.14,0.07,0.1,0.06,0.21
				c0.01-0.17,0.62,0.46,0.26,0.34c0.55,0.21-0.01,0.01,1.2,1.01c0.07-0.09,0.07-0.15,0.22,0.06c-0.2,0.47-0.15,0.04,1.15,1.5
				l-0.13,0c0.74,0.62,1.04,1.43,3.02,3.69l-0.06-0.04c0.14,0.22,0.3,0.13,0.4,0.35l-0.04-0.03c0.14,0.15,1.1,1.51,2.13,2.37
				l-0.05,0.06c0.02-0.02,0.14,0.07,0.33,0.31c0.02-0.01,0.02-0.02,0.03-0.03c0,0.07,0.1,0.26,0.69,0.65
				c-0.03,0.02-0.1-0.03-0.17-0.06c0.26,0.12-0.01,0.1,0.23,0.21c-0.02-0.04,0.04-0.02,0.06-0.03c0.07,0.12,0.31,0.3,0.51,0.4
				l-0.07,0.02c0.34,0.23,0.78,0.4,1.27,0.47c-0.4,0.38,2.7-0.59,2.25-3.06c-0.04,0.1-0.11,0.07,0-0.08
				c-0.15,0.03-0.13-0.12-0.09-0.33c0.33,0.15,0.9,0.32,1.91,0.56l-0.02-0.05c0.7-0.25-0.12-0.01,3.52-0.7l-0.06,0.15
				c0.55-0.49,1.12-1.14,1.06-1.95c0.02,0.05,0.04,0.05,0.04,0.12c-0.19-0.96,0.13-0.26-1.41-2.13c0.08-0.07-0.29-0.36-0.3-0.3
				c0.04,0,0.01-0.04,0.08-0.04c-0.01-0.01-0.07-0.04-0.16-0.05c0.74-0.2,0.03-0.02,1.27-0.37c-0.02,0.02-0.03,0.03-0.05,0.04
				c-0.02-0.17,0.82,0.04,1.58-1.59c0.08,0.19,0.19-0.36,0.28-0.09c0.01-0.06,0.01-0.13-0.02-0.13c0.04-0.07,0.06-0.1,0.07,0
				c0.09-0.12-0.07-0.39,0.08-0.38c-0.03-0.15-0.05-0.29-0.04-0.52c0.02,0.01,0.03-0.01,0.04,0.06c-0.02-0.41-0.19-0.66-0.35-1.05
				c0.11,0.08,0.11-0.14-0.14-0.39l0.02,0.1c-0.12-0.17-0.3-0.25-0.4-0.38c0.05,0.02,0.09,0.05,0.14,0.07
				c-0.09-0.09-0.19-0.16-0.3-0.23c0.05,0.05,0.1,0.11,0.14,0.16c-0.18-0.09-0.06,0.1-0.24-0.08c0.05,0.02-0.15-0.12-0.21-0.21l0,0
				c-0.42-0.14,0.01-0.22-0.81-0.39c0.14-0.07-0.22-0.21-0.34-0.15c0.03,0,0.02-0.01,0.07,0c-0.32-0.1-0.45-0.17-0.54-0.23
				l0.08,0.02c-0.39-0.17,0.04,0-1.46-0.42l0.07,0.03c-0.47-0.01-0.22-0.26-1.4-0.34l0.06-0.02c-0.77-0.1-0.58-0.2-2.19-0.38
				c0.13-0.02,0.2-0.02-0.32-0.03c0.03,0.06,0.05,0.04,0.13,0.09c-0.06,0.03-0.17,0.04-0.27,0.03c0.19-0.02,0.01-0.09-0.07-0.13
				c0.01,0.02-0.02,0.04-0.01,0.05c-0.2-0.12-0.05,0.11-0.33,0.02c0.02-0.05,0.05-0.11-0.67-0.05c0.07-0.01,0.17-0.01,0.24-0.02
				c-0.16-0.09-0.18-0.07-0.3-0.09l0.01,0l-0.28-0.04l0.07,0.02c-0.21,0.17-0.43,0.01-0.71,0.13c0.13-0.03,0.2-0.23-0.49-0.16
				c0.07-0.04-1.03,0.13-2.44,0.25c0.08-0.1,0.14-0.19-0.67,0.1l0.11,0.01c-0.02-0.04,0.03-0.16,0.23-0.05l-0.11,0.03
				c0.13,0.13,0.43-0.09,0.65-0.1c-0.03,0.09-0.05,0.15,0.54,0.07c-0.03,0.02,0.03,0.07-0.06,0.07c0.28,0.03,0.44-0.11,0.54-0.04
				l-0.02,0.01c0.78,0.09,0.7-0.25,2.22-0.04c-0.02,0.1-0.08-0.05,0.48-0.01c-0.02,0.03,0.46,0.08,0.68,0.13l-0.01,0.01
				c0.05-0.09,0.28-0.05,0.46-0.05c-0.01,0.05-0.04,0.07-0.14,0.09c0.02-0.01,0.01,0.04,0.36-0.02c-0.19,0.22,0.12-0.04,0.83,0.01
				l-0.12,0.04c0.12,0.04,0.07-0.04,0.17-0.02c-0.02,0.05,0.14,0.11-0.08,0.06c0.49,0.13,0.2,0.05,1.23,0.19l0,0
				c0.37,0,0,0,1.08,0.26c-0.03,0.11,0.34,0.08,1.12,0.35c-0.17-0.01,0.04,0.05-0.17-0.02c0.2,0.08,0.23,0.14,0.47,0.14
				c-0.09,0.04,0.13,0.13,0.23,0.14l-0.13-0.09c0.11,0.03,0.2,0.07,0.3,0.11l-0.08,0.06c0.4,0.22,0.9,0.26,1.8,0.75
				c-0.01-0.27,0.23,0.45,0.28,0.27c0.14,0.2,0.26,0.27,0.36,0.49c0.05-0.08,0.47,0.57,0.14,0.48c0.11,0.19,0.02,0.39,0,0.5
				c-0.01-0.04-0.01-0.07-0.02-0.11c0.02,0.06,0.01,0.12,0.01,0.17l0.06-0.06c-0.01,0.06-0.02,0.12-0.04,0.12
				c0.08,0.14,0.21,0.02,0.03,0.4c0.11-0.33-0.22-0.25-0.14,0.31c-0.03-0.05-0.1,0.02-0.11,0.07c0.03-0.08,0.12,0.07-0.07,0.36
				l0.08-0.08c-0.02,0.22-0.29,0.41-1.01,0.72c0.08,0.02,0.09,0.01-0.03,0.13c0.11-0.07,0.22-0.14,0.32-0.22
				c-0.05,0.07-0.11,0.14-0.17,0.2c0.11-0.05,0.2-0.16,0.26-0.19c-0.07,0.11-0.07,0.09,0.01,0.1c-0.53,0.24-0.32-0.03-1.33,0.36
				l0.12,0.01c-0.61,0.08-0.11,0.01-1.65-0.1c0.02,0.12,0.28,0.33,0.41,0.54c-0.6-0.55-0.4-0.41,1.78,1.86
				c-0.02,0-0.02,0.01-0.03-0.02c0.24,0.54,0.12-0.01,0.14,0.85l-0.01-0.04c-0.16,0.18,0.2,0.13-0.45,1.22
				c-0.02-0.03-0.03-0.05-0.03-0.06c0.01,0.06,0,0.2-0.32,0.21c0.05-0.04,0.1-0.08,0.15-0.13c-0.13,0-0.2,0.16-0.3,0.2l-0.01-0.04
				c-0.25,0.16-0.22,0.25-0.54,0.36l0.04,0.02c-0.44,0.24-0.2-0.14-1.04,0.23c0.12-0.08,0.38-0.15,0.52-0.22
				c-0.12,0.03-0.4,0.07-0.46,0.12c0.05-0.01,0.11-0.05,0.16-0.04c-0.99,0.17-0.03,0.1-2.73-0.24c0.12,0.19-0.14-0.03-0.15,0.12
				c-0.1-0.05-0.23-0.03-0.09-0.19c0.12,0.11-0.24-0.01-0.08,0.13c-0.15-0.08-0.19-0.21-0.29-0.45c0,0.06-0.11,0.02-0.17-0.07
				c0.02,0.05,0.04,0.11,0.08,0.16c-0.13-0.09-0.11-0.07-0.28-0.43c0.02,0.02,0.05,0.04,0.08,0.05c-0.16-0.21-0.04-0.04-0.76-0.22
				l0.01,0.03l-0.01-0.03c0.11,0.33-0.04,0.3,0.38,1.77l-0.04-0.03c0.05,0.23-0.03,0.11,0.05,0.57l-0.04-0.09
				c0.08,0.7-0.44,3.31-4.54-0.23l0.05-0.05c-0.2-0.24-0.3-0.36-0.58-0.63l0.09,0.02c-0.61-0.41-0.28-0.16-1.19-1.19
				c0.02-0.01,0.05-0.02,0.09,0.03c-0.11-0.14-0.39-0.58-0.46-0.44c0.07-0.06-0.24-0.31-0.41-0.45l0.07-0.02
				c-0.03,0.01-0.22-0.16-0.59-0.53l0.09,0.04c-0.19-0.25-0.25-0.03-0.43-0.55c0.06,0.07-0.26-0.15-0.15-0.14
				c-0.14-0.32-0.05,0.06-0.45-0.61c0.16,0.21,0.29,0.38,0.28,0.36c0.05-0.04-0.07-0.21,0.1-0.14c-0.13-0.12-0.03,0.04-0.51-0.49
				l0.02,0.01c-0.19-0.22-0.07-0.15-0.38-0.54c-0.05,0.05-0.13-0.07-0.09,0.07c-0.02-0.03,0.03,0.04,0.1,0.09l-0.01,0
				c0.04,0.05,0.1,0.12,0.17,0.2c-0.59-0.45-0.22-0.48-1.3-1.55c0.21,0.15,0.18,0.16-0.42-0.48l0.05,0.01
				c-0.3-0.58-0.85-0.61-1.19-1.25c0.02,0.04,0.04,0.07,0.03,0.08c-0.13-0.34-1.39-1.35-3.09-2.7l0.02,0.07
				c-0.22-0.18-0.09-0.06-1.14-0.81c0.44-0.03-0.1-0.13-0.08-0.04c-0.03-0.01-0.05-0.02-0.07-0.02c0.01-0.05-0.01-0.11,0.08-0.11
				c-0.06-0.03-0.05,0.05-0.08-0.02c0.15,0.05-0.06-0.16,0.14-0.1c-0.14-0.09,0.15-0.09-0.03-0.33c0.05,0.05-0.02-0.16,0.15-0.08
				c-0.01-0.03,0.01-0.11,0.04-0.14c0.02,0.15,0.12-0.08,0.19,0.05c-0.03-0.13,0.12-0.15,0.15-0.26c0,0.01,0,0.03-0.01,0.03
				c0.05-0.02,0.08,0,0.12,0c-0.05-0.08,0-0.15,0-0.22c0.11,0.04,0.04,0.12,0.11,0.18c-0.05-0.06,0.04-0.13-0.01-0.17
				c0.07,0.02,0.05,0,0.12,0.05c-0.06-0.1,0.02-0.04,0.01-0.13c0.05,0.04,0.14-0.07,0.14,0.09c-0.09-0.18,0.27-0.07,0.32-0.59
				c-0.02,0.01-0.01,0.03,0,0.05l0.01-0.04c-0.06,0.21,0.2,0.03,0.07,0.06c0.18,0.34,0.34-0.5,0.53-0.27
				c0.19-0.27,0.02,0.09,0.43-0.41c-0.25,0.15,0.39-0.1,0.87-0.55l-0.01,0.03c0.09-0.02,0-0.18,0.1-0.17l-0.01,0.02
				c0.15-0.08,0.13,0.1,0.95-0.63l0.08,0.06c0.01-0.18,0.04,0.12,0.27-0.24c0.01,0.03,0,0.05-0.01,0.08
				c-0.01-0.16,0.09,0.08,0.06-0.1c0.06,0.02,0.14-0.04,0.19-0.09l0.02,0.05c0.11-0.06,0.21-0.17,0.33-0.25
				c-0.06,0.01-0.1-0.08-0.07-0.11l0.06,0.06c-0.01-0.05-0.06-0.17,0.03,0.01l0.04-0.06c0.02,0.02,0.01,0.04,0.02,0.07
				c0.24-0.19,0.04,0.05,0.67-0.62c0.04,0.05,0.12-0.01,0.1,0.06c0.1-0.08,0.13-0.1,0.23-0.23c-0.05-0.02-0.06-0.08,0.03-0.01
				c0.1-0.18,0.44-0.07,0.49-0.25l0,0.01c0.14-0.19,0.1-0.08,0.9-0.49c-0.02-0.03-0.07-0.08,0.05-0.04
				c-0.37-0.1,0.58-0.21,1.43-0.99c0,0.02,0.01,0.04-0.01,0.06c0.07,0.03,0.15-0.09,0.12-0.13c-0.07,0.02,0.28-0.07,0.52-0.37
				c0.05,0.1,0.17,0,0.19-0.07c0,0.04,0.06,0.01,0.06,0.06c0.05-0.03,0.09-0.08,0.05-0.12c0.21,0,0.12,0.03,0.63-0.46
				c0,0.27-0.26,0.28,0.54-0.29c0.01,0.01,0,0.03-0.01,0.04c0.03-0.09-0.01,0.01,0.01-0.26c0.04,0.15,0.13-0.04,0.17,0.16
				c0.01-0.02,0.02-0.05,0-0.08c0.07,0.01,0.05,0.08,0.23-0.17c0,0.02,0.02,0.03,0,0.04c0.1-0.07,0.12-0.22,0.26-0.25
				c0.02,0.11,0.06,0.01,0.11,0.08l0.05-0.06l-0.05-0.02c0.06-0.03,0.07-0.12,0.13-0.12l-0.02,0.06l0.1-0.08l-0.08,0
				c0.03-0.07-0.07-0.1,0.02-0.12c0.01,0.03,0.05-0.04,0.25,0.02c-0.01-0.08,0.12-0.09,0.11-0.19c0,0.02,0.02,0.07,0.03,0.07
				l-0.01-0.07c0.09,0.01,0.06-0.05,0.28-0.1l-0.03,0.03l0.05,0.02l-0.01-0.05c0.14,0.05-0.01-0.18,0.77-0.51
				c-0.06,0.05,0.2,0.06,0.18-0.12c0.17-0.01,0.23,0.14,0.54-0.4c-0.04,0.06,0.05,0.08,0.09,0.1c-0.01-0.01-0.01-0.03-0.02-0.05
				c0.12,0.1,0-0.09,0.08-0.07c-0.01,0.01,0.12-0.18,0.08,0c0.14-0.1-0.02,0.01,0.28-0.06c-0.1-0.18-0.01-0.01,0.11-0.25
				c-0.02,0.12-0.02,0.06,0.12,0.16c0.01-0.08,0.09,0.01,0.1-0.1c0.01,0.01,0.02,0.01,0.04,0.01c-0.02-0.03,0.11-0.14,0.55-0.45
				c0.04,0.15,0.11-0.16,0.1,0.07c0.03-0.04,0.05-0.11,0.09-0.15c0.03,0.03,0,0.07,0,0.1c-0.01-0.01,0.12-0.08,0.05-0.18
				c0.01,0.19-0.02-0.18-0.22,0.15l-0.02-0.11l-0.03,0.11c-0.03-0.02-0.06-0.06-0.04-0.09c-0.06-0.02-0.04-0.04-0.13,0.06
				c0-0.02-0.04-0.06-0.01-0.06c-0.08,0.01-0.24,0.03-0.18,0.28l-0.15-0.13l0.03,0.07c-0.01,0-0.04,0.01-0.05-0.02
				c-0.02,0.07,0.02,0.03,0.02,0.08c-0.09-0.03-0.14,0.11-0.21,0.02c0.01,0.06,0.08,0.01,0.03,0.08c-0.05-0.15-0.11,0.15-0.17-0.05
				c0,0.17-0.18-0.07-0.28,0.23c-0.01-0.04,0,0.03-0.25,0.03c0.03,0.09-0.05,0.11-0.09,0.16c-0.02-0.05-0.03-0.07-0.01-0.11
				c0,0.01-0.04-0.01-0.08,0.11l-0.02-0.07c-0.06,0.04-0.09,0.14-0.19,0.15c0.03,0.06,0.15,0.12,0.09,0.2
				c-0.02-0.03-0.05-0.08-0.04-0.1c-0.01,0-0.05,0.09-0.03-0.03c-0.01,0.04-0.11,0.06-0.04-0.01c-0.04,0.01-0.09,0.01-0.12-0.02
				c0.04,0.09-0.14-0.04-0.23,0.25l0,0c-0.03,0.13-0.33,0.07-0.69,0.36c0.03-0.07-0.04-0.02,0.03-0.06
				c-0.07,0.02-0.11-0.01-0.14,0.09c-0.01-0.07-0.09-0.04-0.11-0.01l0.07,0.01c-0.22,0.05-0.06-0.05-0.45,0.17
				c0.29,0.15-0.33,0.25-0.29,0.19l0.01,0.03c-0.04-0.01-0.12,0.04-0.13-0.01c-0.03,0.05,0.01,0.05,0.05,0.05
				c-0.1,0.01-0.12,0.07-0.21,0.09c0.05,0.01-0.13,0.35-0.2,0.05c-0.04,0.09-0.15,0.05-0.2,0.06l0.03-0.04
				c-0.02,0.03-0.03,0.04-0.05,0.05l0.05,0.04c-0.13,0-0.06-0.04-0.13,0.16c0.13-0.05-0.13-0.27-0.12,0.04
				c-0.01-0.04-0.06-0.06-0.07-0.04c0.01-0.03,0.08,0.06-0.04,0.18c0.04,0.01,0.08-0.01-0.01,0.09c-0.02-0.03,0.02-0.04,0-0.05
				c0,0.18-0.44-0.14-0.39,0.19l0.1-0.11l-0.03,0.12c0.04-0.02,0.06-0.08,0.09-0.08c-0.01,0.06-0.02,0.05,0.03,0.08
				c-0.22,0.19-0.12-0.24-0.48,0.16c0.05,0.02,0.1,0.01-0.04,0.09c-0.02-0.04-0.05-0.05-0.09-0.06c0.02,0.03-0.08,0.05-0.04,0.14
				c-0.13-0.01-0.11-0.08-0.21,0.2c0-0.06-0.06-0.07-0.02-0.14c-0.07,0.09,0.01-0.02-0.14,0.29c-0.13-0.05-0.16-0.03-0.57,0.25
				c0-0.01-0.01-0.02,0-0.02c-0.17,0.18-0.01-0.02-0.32,0.13l0.01-0.02c-0.08-0.02-0.08,0.12-0.16,0.16c0,0,0-0.01-0.01-0.02
				c-0.01,0.1-0.09,0.19-0.15,0.25c-0.06-0.07-0.22-0.13-0.66,0.41c0-0.09,0.06-0.17,0.08-0.23c-0.04,0.02-0.12,0.08-0.11,0.14
				c0.05-0.03,0.06-0.05-0.08,0.16c-0.09-0.17-0.21,0.08-0.35-0.01c-0.09,0.14-0.27,0.14-0.41,0.24c0.08,0,0.01,0.25-0.05,0.08
				c0,0.03-0.01,0.06,0,0.08c-0.07,0-0.16-0.02-0.26-0.1c0.04,0.04,0.02,0.08-0.04,0.09l0.1,0.02c-0.06,0.14-0.16-0.05-0.22,0.09
				l0.01-0.06c-0.04,0.06-0.2,0.13-0.19,0.27c-0.02-0.01-0.05-0.04-0.04-0.06c-0.12,0.01-0.15,0.11-0.52,0.4l0-0.05
				c-0.53,0.4-0.15,0.12-1.28,0.8c-0.04,0.18-0.08,0.05-0.72,0.6c-0.1-0.07-0.03-0.07-0.29,0.1l-0.01-0.06
				c-0.08,0.25,0.03,0.12-0.42,0.41c-0.01-0.01-0.02-0.04-0.01-0.06c-0.05,0.03-0.24,0.08-0.14,0.18c-0.05-0.08-0.12,0.07-0.15,0.14
				l-0.03-0.06c0.01,0.02-0.02,0.09-0.15,0.26l0-0.05c-0.09,0.05,0.05,0.18-0.2,0.13c0,0.02-0.01,0-0.27,0.18
				c0.07-0.05,0.14-0.09,0.13-0.08c-0.02-0.14-0.33,0-0.38,0.27c-0.01,0,0.01-0.01,0.03-0.04l0,0.01c0.02-0.02,0.04-0.03,0.07-0.06
				c0,0.05-0.01,0.09-0.07,0.08c0.01,0.05,0.02,0.1,0.02,0.12c-0.07,0.07-0.44,0.1-1.13,0.69c0.02,0,0.03,0,0.04,0.01
				c-0.17,0.11-0.2-0.04-0.75,0.56c0,0,0,0,0,0c0.03,0-0.18,0.06-0.04-0.05c0.01-0.03,0.04-0.07,0.08-0.09
				c-0.05-0.01,0.06-0.02-0.02-0.15l-0.01,0.03c0.25-0.55-0.08,0.21,1.02-2.59c0,0.02,0,0.06-0.02,0.09
				c0.17,0.12,0.22-1.04,0.45-0.63c-0.08-0.03,0.13-0.39,0.03-0.35c0.14-0.13,0.1-0.12,0.22-0.16c-0.18,0.05,0.41-0.89,0.33-0.43
				c0.14-0.66,0.03-0.03,0.94-1.75c-0.13-0.05-0.16-0.03,0.04-0.28c0.49,0.04,0.07,0.19,1.5-1.88l0.01,0.14
				c0.61-1.04,1.42-1.75,3.83-4.68l-0.04,0.07c0.24-0.22,0.13-0.37,0.39-0.54l-0.03,0.05c0.18-0.21,1.61-1.56,2.94-2.67l0.05,0.06
				c-0.02-0.02,0.12-0.16,0.45-0.32c-0.23-0.08,0.1,0.06,0.98-0.62c0,0.03-0.09,0.09-0.15,0.15c0.5-0.33,0.08-0.07,1.02-0.53
				l-0.02,0.07c0.41-0.15,0.9-0.31,1.39-0.34c-0.13-0.38,2.94,1.26,1.44,3.32c0.03-0.06,0.15-0.22,0,0.08l-0.01-0.01
				c0.02,0.06,0,0.13-0.02,0.2c0.73,0.05,0.25,0.17,1.59-0.53l-0.02,0.02c0.59-0.14,1.48-1.03,3.24,0.23l0.01-0.05
				c0.62,0.45,0.15-0.14-0.78,3.2l0.06-0.15c-0.32,0.32-0.72,0.58-1.13,0.81c0.09,0.15,0.01,0.04,0.24,0.43
				c0.01-0.01,0.02-0.01,0.03-0.02l0.08,0.12c0.26-0.19,0.55-0.32,0.86-0.42c-0.24,0.59-0.07-0.89,2.3,0.86
				c-0.09,0.04,0.17,0.45,0.26,0.61c-0.05-0.04,0.01,0.15-0.05,0.1c0.05,0.18,0.1,0.36,0.15,0.32c-0.25,0.97,0.17,0.79-0.99,2.39
				c0.02-0.5,0.38-0.02-1.86,1.2c0.02,0.01,0.07,0-0.3,0.35c0.08-0.21-0.46,0.18-0.3-0.1c-0.05,0.04-0.12,0.09-0.09,0.12
				c-0.09,0.03-0.13,0.03-0.06-0.05c-0.3,0.18-0.17,0.17-0.46,0.31c-0.01-0.02,0.04-0.06,0.06-0.1c-0.12,0.12-0.25,0.22-0.51,0.32
				c0.01-0.02-0.03-0.03,0.05-0.06c-0.42,0.19-0.67,0.45-1.16,0.59c0.05-0.08,0.04-0.16-0.48,0.08l0.12,0
				c-0.22,0.09-0.39,0.23-0.6,0.28l0.15-0.1l-0.41,0.18l0.25-0.06c-0.21,0.13,0.07,0.1-0.24,0.19c0.06-0.03-0.23,0.08-0.36,0.07l0,0
				c-0.46,0.25-0.18-0.1-1.05,0.4c0.08-0.16-0.37,0.01-0.44,0.13c0.03-0.01,0.01-0.02,0.07-0.05c-0.39,0.14-0.57,0.17-0.7,0.2
				l0.09-0.04c-0.52,0.13,0.04-0.02-1.82,0.58l0.09-0.02c-0.53,0.26-0.42-0.08-1.76,0.48l0.06-0.05c-0.94,0.28-0.75,0.14-2.74,0.63
				c0.15-0.07,0.23-0.09-0.39,0.09c0.05,0.05,0.07,0.02,0.19,0.04c-0.06,0.05-0.2,0.11-0.32,0.13c0.22-0.08-0.02-0.09-0.13-0.1
				c0.02,0.01-0.01,0.04,0,0.06c-0.29-0.05-0.02,0.12-0.4,0.13c0.02-0.06,0.06-0.13-0.83,0.16c0.1-0.03,0.19-0.06,0.29-0.1
				c-0.22-0.04-0.24-0.01-0.39,0.01l0.02,0l-0.36,0.03l0.09,0c-0.23,0.22-0.52,0.13-0.85,0.31c0.32-0.17,0.04-0.07,0.06-0.15
				c-0.09,0-0.23,0-0.2-0.03c-0.11,0.03-0.12,0.13-0.65,0.1c0.83-0.05-1.58,0.41-2.89,0.66c0.11-0.11,0.18-0.2-0.84,0.15l0.14,0
				c-0.01-0.05,0.04-0.15,0.29-0.07l-0.13,0.04c0.16,0.12,0.55-0.11,0.82-0.15c-0.04,0.1-0.05,0.15,0.68,0.01
				c-0.03,0.03,0.04,0.07-0.08,0.07c0.36,0,0.54-0.17,0.69-0.12l-0.03,0.01c1.03-0.08,0.9-0.32,2.8-0.51c0,0.11-0.1-0.03,0.6-0.14
				c-0.01,0.03,0.59-0.07,0.88-0.09l-0.01,0.02c0.04-0.1,0.33-0.14,0.54-0.21c0,0.06-0.03,0.09-0.14,0.13
				c0.02-0.02,0.03,0.04,0.43-0.15c-0.17,0.28,0.14-0.08,1.01-0.32l-0.13,0.09c0.16-0.01,0.07-0.06,0.2-0.09
				c0.01,0.06,0.2,0.04-0.07,0.09c0.66-0.1,0.28-0.04,1.55-0.4l0,0c0.94-0.37,0.22,0.07,2.83-0.74c-0.19,0.1,0.08,0.02-0.2,0.09
				c0.27-0.06,0.34-0.04,0.6-0.19c-0.08,0.09,0.22,0.02,0.34-0.04l-0.2,0.01c0.14-0.05,0.26-0.08,0.39-0.12l-0.04,0.1
				c0.56-0.14,1.2-0.37,2.41-0.91c-0.31-0.1,0.7-0.05,0.51-0.16c0.32-0.08,0.48-0.19,0.81-0.29c-0.03-0.03,0.79-0.59,0.7-0.24
				c0.26-0.18,0.55-0.2,0.71-0.27l-0.14,0.09c0.08-0.05,0.16-0.09,0.23-0.12l-0.11-0.02c0.09-0.03,0.17-0.06,0.18-0.04
				c0.13-0.2-0.07-0.17,0.46-0.34c-0.36,0.15,0.04,0.06-0.21,0.2c0.37-0.13,0.24-0.02,0.65-0.39c-0.03,0.06,0.12,0.03,0.18,0
				c-0.12,0.05-0.01-0.12,0.37-0.33l-0.14,0.06c0.16-0.25,0.67-0.23,1.6-0.84c-0.12,0.01-0.12,0.04,0.03-0.16
				c-0.13,0.13-0.27,0.25-0.42,0.36l0.23-0.28c-0.13,0.1-0.26,0.23-0.35,0.28c0.11-0.13,0.1-0.11,0.01-0.11
				c0.64-0.46,0.59,0.02,1.47-1.23l-0.12,0.12c0.29-0.63,0.48-0.34,0.56-2.08c0.06,0.12,0.09-0.08,0.14,0.21
				c-0.45-1.12,0.06-0.05-2.19-2.55c0.01,0,0.01-0.01,0.01-0.01c-0.61,0-0.31-0.1-0.68-0.08c0.02-0.02,0.03-0.03,0.05-0.05
				l0.01,0.04c0.32-0.29,0.26-0.37,0.58-0.71l-0.05,0.02c0.28-0.6,0.44-0.03,0.63-1.27c0.04,0.18-0.04,0.55-0.13,0.76
				c0.1-0.14,0.24-0.55,0.2-0.66c-0.01,0.07-0.01,0.16-0.04,0.23c-0.15-1.67,0.39-0.71-1.92-2.96c0.22,0.19-0.21-0.04-0.19,0.13
				c-0.45-0.02-0.48-0.12-1-0.25c0.06,0.05-0.11,0.09-0.32,0.12l0.3,0.01c-0.47,0.18-0.46-0.04-0.91,0.2l0.13-0.1
				c0.02,0.04-0.53,0.12-0.84,0.49c-0.01-0.01,0-0.07,0.02-0.09c-0.19,0.08-0.56,0.6-0.06-0.27c0,0.04-0.01,0.11,0,0.12
				c0.25-0.52,0.18-0.95,0.08-1.44c0.13-0.01,0.02-0.54-0.14-0.77l0.02,0c-0.93,0.82-0.33-2.84-6.78,1.22l-0.04-0.06
				c-0.33,0.24-0.5,0.37-0.86,0.71l0.04-0.11c-0.57,0.77-0.27,0.34-1.49,1.61c-0.01-0.02-0.02-0.05,0.03-0.11
				c-0.16,0.17-0.66,0.61-0.5,0.65c-0.08-0.06-0.35,0.37-0.49,0.6l-0.03-0.08c0,0.03-0.17,0.3-0.56,0.84l0.04-0.11
				c-0.26,0.31-0.02,0.29-0.58,0.67c0.07-0.09-0.14,0.33-0.15,0.22c-0.35,0.28,0.08,0.03-0.64,0.73c0.22-0.26,0.39-0.46,0.38-0.44
				c-0.05-0.04-0.23,0.16-0.16-0.06c-0.12,0.19,0.04,0.02-0.49,0.75l0.01-0.03c-0.22,0.3-0.16,0.14-0.56,0.62
				c0.06,0.04-0.06,0.17,0.09,0.07c-0.03,0.03,0.04-0.05,0.09-0.15l0,0.01c0.06-0.07,0.13-0.16,0.21-0.26
				c-0.44,0.83-0.53,0.43-1.56,2.04c0.03-0.06,0.08-0.13,0.09-0.17c-0.03,0-0.22,0.59-0.56,0.82l0.01-0.06
				c-0.59,0.57-0.56,1.2-1.2,1.84c0.04-0.05,0.06-0.07,0.08-0.07c-0.17,0.14-0.48,0.65-0.89,1.42c0.07-0.19,0.2-0.59,0.27-0.81
				l0.01,0.01c-0.07-0.08,0.02-0.4,0.05-0.64c0.05,0.02,0.06,0.07,0.06,0.2c-0.01-0.04,0.05-0.01,0.06-0.5
				c0.19,0.31-0.02-0.18,0.13-1.16l0.03,0.17c0.06-0.17-0.03-0.1,0-0.24c0.06,0.01,0.13-0.18,0.05,0.1
				c0.18-0.73,0.08-0.31,0.26-1.74l0,0c-0.07-0.56,0.23-0.69,0.34-3.22c0.03,0.24,0.04-0.08,0.02,0.24c0.04-0.3,0.08-0.37,0.03-0.69
				c0.06,0.11,0.09-0.22,0.08-0.37l-0.06,0.21c0.01-0.16,0.01-0.3,0.02-0.45l0.08,0.08c0.04-0.63,0.04-1.39-0.1-2.84
				c-0.19,0.29,0.16-0.75,0-0.59c0.02-0.37-0.03-0.57-0.04-0.95c-0.05,0.02-0.34-1.02-0.03-0.82c-0.09-0.34-0.04-0.64-0.05-0.84
				l0.05,0.18c-0.03-0.11-0.04-0.19-0.05-0.28l-0.04,0.11c-0.01-0.11-0.02-0.2,0.01-0.2c-0.16-0.21-0.18,0.02-0.21-0.59
				c0.08,0.59,0.35,0.2-0.08-0.53c0.06,0.05,0.06-0.12,0.03-0.19c0.02,0.14-0.12-0.03-0.26-0.49l0.03,0.17
				c-0.22-0.23-0.1-0.79-0.6-1.91c0,0.12,0.02,0.13-0.16-0.07c0.11,0.17,0.22,0.34,0.31,0.52c-0.08-0.11-0.16-0.21-0.25-0.31
				c0.09,0.16,0.2,0.33,0.24,0.43c-0.12-0.15-0.1-0.14-0.11-0.04c-0.38-0.76,0.1-0.63-1.06-1.81l0.1,0.15
				c-0.6-0.47-0.28-0.54-1.97-1.31c0.14,0-0.03-0.11,0.26-0.03c-1.32-0.17-0.01-0.07-3.63,0.57c0-0.01,0-0.01,0-0.01
				c-0.37,0.8-0.19-0.07-0.6,1.32l0-0.08c-0.02,0.1-0.06,0.12-0.02,0.7c0,0-0.01-0.03-0.02-0.04c0.1,0.44,0.02,0.04,0.17,1.07
				c-0.08-0.34-0.05-0.25-0.11-0.45c0.06,0.26,0.15,0.76-0.14-0.26l0.07,0.13c-0.19-0.45-0.01-0.24-0.46-0.7l0.01,0.06
				c-0.53-0.49,0.06-0.44-1.12-1.08c0.2,0.03,0.53,0.25,0.73,0.41c-0.12-0.14-0.48-0.43-0.59-0.44c0.07,0.04,0.16,0.07,0.21,0.13
				c-1.62-0.53-0.33-0.66-3.64,0.85c0.2-0.26-0.05,0.24,0.13,0.21c0,0.1,0-0.01-0.02,0.26c0,0,0,0,0-0.01
				c-0.03,0.27-0.09,0.4-0.19,0.84c0.05-0.06,0.1,0.12,0.12,0.33l0.02-0.31c0.17,0.5-0.04,0.47,0.12,0.96l-0.07-0.15
				c0.03,0.11,0.08,0.44,0.08,0.39c0.03,0.09,0.06,0.18,0.09,0.29c-0.02-0.02-0.06-0.1-0.07-0.15c-0.08,0.11-0.21-0.06-0.56-0.53
				l0.06,0.01c-0.17-0.13-0.03-0.1-0.63-0.6l0.13,0.07c-0.45-0.49-0.84-0.58-1.33-0.81c0.06-0.1-0.42-0.36-0.7-0.42l0.01-0.01
				c-1.34-0.06-3.6,1.79-0.8,6.71l-0.12-0.05c0.86,0.6,0.39,0.29,1.84,1.55c-0.02,0.01-0.05,0.02-0.12-0.04
				c0.19,0.17,0.7,0.7,0.72,0.53c-0.06,0.08,0.41,0.37,0.66,0.53l-0.08,0.02c0.04,0,0.33,0.19,0.89,0.64l-0.12-0.05
				c0.32,0.31,0.31,0.06,0.69,0.68c-0.09-0.09,0.33,0.2,0.21,0.19c0.26,0.41,0.04-0.07,0.7,0.8c-0.24-0.28-0.45-0.49-0.42-0.48
				c-0.05,0.04,0.15,0.27-0.08,0.16c0.19,0.15,0.03-0.04,0.72,0.65l-0.03-0.02c0.27,0.29,0.13,0.19,0.55,0.72
				c0.05-0.05,0.16,0.1,0.09-0.07c0.02,0.04-0.04-0.05-0.13-0.14l0.01,0c-0.06-0.07-0.14-0.17-0.23-0.28
				c0.75,0.68,0.41,0.62,1.7,2.23c-0.05-0.05-0.1-0.13-0.14-0.15c-0.01,0.03,0.51,0.46,0.62,0.89l-0.06-0.04
				c0.33,0.82,0.94,1.1,1.22,2.06c-0.02-0.07-0.03-0.1-0.02-0.12c0.04,0.47,1.31,2.37,1.99,5.32l0.03-0.09
				c0.05,0.2,0.19,0.64,0.12,0.62c0.26,0.99,0.05,1.06-0.73-1.23c0.11-0.03-0.01,0.11-0.28-0.55c0.04,0.01-0.21-0.55-0.3-0.83
				l0.02,0c-0.1-0.01-0.23-0.29-0.34-0.47c0.05-0.02,0.09,0.01,0.17,0.1c-0.03-0.02,0.03-0.03-0.25-0.38
				c0.33,0.09-0.12-0.11-0.58-0.89l0.12,0.1c-0.05-0.04-0.23-0.43-0.03-0.13c-0.28-0.61-0.12-0.26-0.82-1.37l0,0
				c-0.63-0.79,0.01-0.25-1.56-2.48c0.16,0.15-0.01-0.08,0.15,0.16c-0.14-0.24-0.14-0.31-0.37-0.51c0.11,0.05-0.05-0.22-0.15-0.31
				l0.08,0.18c-0.09-0.11-0.16-0.22-0.24-0.33l0.11,0.01c-0.31-0.48-0.73-1.04-1.65-1.98c0.02,0.32-0.29-0.64-0.33-0.42
				c-0.2-0.27-0.35-0.38-0.57-0.65c-0.02,0.04-0.85-0.5-0.5-0.56c-0.27-0.17-0.41-0.43-0.54-0.55l0.14,0.09
				c-0.08-0.06-0.15-0.11-0.21-0.16l0.04,0.11c-0.07-0.07-0.13-0.12-0.12-0.14c-0.24-0.03-0.13,0.14-0.5-0.27
				c0.29,0.27,0.03-0.07,0.27,0.1c-0.28-0.27-0.12-0.21-0.65-0.42c0.07-0.01-0.03-0.12-0.09-0.16c0.1,0.08-0.1,0.06-0.48-0.16
				l0.12,0.1c-0.29-0.02-0.53-0.47-1.51-0.99c0.06,0.1,0.08,0.09-0.15,0.04l0.51,0.2l-0.35-0.07c0.15,0.07,0.33,0.11,0.41,0.17
				c-0.17-0.03-0.15-0.03-0.1,0.04c-0.7-0.34-0.26-0.49-1.72-0.8l0.15,0.07c-0.48-0.02-0.38-0.3-2.12-0.25
				c0.12-0.05-0.06-0.09,0.21-0.13c-1.17,0.29-0.05-0.06-2.75,1.66c0-0.01-0.01-0.01-0.01-0.01c0.13,0.79-0.11,0.03,0.15,1.29
				l-0.03-0.06c0.12,0.28-0.12,0.19,0.95,1.61c-0.02,0.01-0.03,0.01-0.04,0.01c0.07,0.02,0.19,0.11,0.26,0.45
				c-0.07-0.07-0.12-0.15-0.18-0.22c0.01,0.17,0.23,0.28,0.32,0.4l-0.04,0.01c0.31,0.31,0.38,0.25,0.72,0.55l-0.02-0.05
				c0.28,0.15,0.3,0.24,0.37,0.34c-0.06,0-0.12,0-0.11,0.06c-0.07-0.15-0.07-0.1-0.32-0.35c0.02,0.06,0.1,0.14,0.19,0.19
				c-0.02-0.01-0.03-0.01-0.06-0.02c0.21,0.08-0.36,0.05-0.04,0.15c-0.23-0.05-0.33-0.14-0.7-0.3c0.05,0.06-0.12,0.07-0.32,0.06
				l0.28,0.07c-0.48,0.08-0.42-0.13-0.91-0.01l0.15-0.06c-0.43,0.12-0.57-0.18-2.86,0.88l0.02-0.06c-0.15,0.13-0.1,0.01-0.66,0.45
				l0.08-0.11c-0.52,0.31-0.66,0.65-0.96,1.04c-0.09-0.08-0.41,0.28-0.54,0.49l-0.01-0.02c1.52-0.29-3.49,2.18,3.99,5.01l-0.02,0.07
				c0.4,0.09,0.59,0.13,1.09,0.2l-0.11,0.03c0.94-0.02,0.43-0.02,2.15,0.25c-0.01,0.01-0.03,0.04-0.11,0.03
				c0.23,0.04,0.87,0.22,0.81,0.06c-0.01,0.09,0.49,0.09,0.76,0.1l-0.05,0.06c0.03-0.02,0.34-0.01,1,0.05l-0.12,0.02
				c0.4,0.08,0.27-0.13,0.87,0.18c-0.12-0.01,0.36-0.04,0.26,0.03c0.4,0.18,0-0.08,0.94,0.23c-0.32-0.08-0.59-0.13-0.56-0.13
				c-0.01,0.06,0.25,0.13,0.02,0.17c0.22,0.02,0-0.05,0.88,0.09l-0.03,0c0.36,0.07,0.19,0.08,0.79,0.24
				c0.01-0.07,0.17-0.01,0.03-0.11c0.04,0.02-0.06-0.02-0.17-0.03l0.01,0c-0.09-0.02-0.2-0.05-0.33-0.07
				c0.92,0.07,0.63,0.25,2.45,0.67c-0.07-0.01-0.14-0.04-0.18-0.03c0.01,0.03,0.63,0.03,0.94,0.29l-0.06,0.01
				c0.68,0.42,1.3,0.25,2.01,0.81c-0.05-0.04-0.07-0.05-0.08-0.07c0.25,0.34,2.29,0.99,4.18,2.95l-0.01-0.08
				c0.13,0.09-0.02,0.04,0.53,0.58c-0.08,0.05-0.17,0.12-0.29,0.22l0.12-0.17c-0.23-0.29-0.29,1.48-1.07,0.21
				c-0.12,0.02-0.12-0.33-1.1-0.69c0.27-0.01-0.43-0.23-0.32-0.32c-0.21,0.03-0.61-0.2-0.93-0.22c0.02,0,0.06-0.01,0.08,0
				c0.07-0.22-0.98,0.05-0.66-0.28c-0.01,0.08-0.38,0-0.32,0.08c-0.15-0.09-0.13-0.05-0.2-0.15c0.1,0.14-0.89-0.02-0.48-0.14
				c-0.67,0.21,0-0.11-1.8,0.04c0.02,0.12,0.06,0.15-0.22,0.12c-0.26-0.49,0.06-0.14-2.13-0.03l0.09-0.1
				c-1.05,0.31-2.03-0.01-5.28,0.95l0.06-0.04c-0.29,0.05-0.27,0.23-0.55,0.24l0.05-0.03c-0.22,0.08-2.01,0.49-3.34,1.21l-0.03-0.07
				c0.02,0.03-0.13,0.11-0.45,0.21c0.01,0.02,0.02,0.03,0.02,0.04c-0.07-0.02-0.3,0.02-0.95,0.45c0-0.03,0.08-0.09,0.14-0.14
				c-0.46,0.3-0.08,0.05-0.93,0.52l0.01-0.07C503.9,247.23,504.5,246.01,502.84,249.99z M526.31,227.42c0.01,0-0.06-0.07,0.03-0.06
				C526.31,227.36,526.32,227.4,526.31,227.42z M526.59,226.97c0.01,0,0.03-0.01,0.04-0.01C526.64,226.98,526.62,227,526.59,226.97z
				 M535.82,248.08c-0.03-0.01,0.03,0.08,0.05,0.15C535.91,248.25,535.67,248.06,535.82,248.08z M535.62,247.19
				c0.06,0.01,0.06,0,0.1,0.09C535.69,247.26,535.72,247.27,535.62,247.19z M535.59,247.88c-0.03-0.03,0,0-0.07-0.08
				C535.53,247.81,535.56,247.84,535.59,247.88z M531.08,238.26c0-0.01-0.07,0.08-0.07-0.01
				C531.02,238.27,531.05,238.26,531.08,238.26z M530.96,238.35c-0.01,0.01,0,0-0.03,0.03
				C530.94,238.37,530.95,238.36,530.96,238.35z M530.56,238.08c-0.01-0.01,0,0-0.01-0.03
				C530.56,238.03,530.61,238.03,530.56,238.08z M549.43,229.19C549.45,229.27,549.42,229.25,549.43,229.19L549.43,229.19z
				 M534.95,229.21c0.02-0.06-0.16,0.28-0.16,0.11C534.78,229.36,534.88,229.27,534.95,229.21z M534.59,229.65
				c-0.03,0.05,0,0-0.08,0.11C534.52,229.74,534.55,229.71,534.59,229.65z M533.82,229.89c0.01-0.08-0.01-0.07,0.09-0.14
				C533.89,229.78,533.9,229.74,533.82,229.89z M527.95,240.78c0.02,0-0.05-0.07,0.03-0.11
				C527.97,240.7,527.96,240.73,527.95,240.78z M520.14,228.26c0.03,0.02-0.05-0.11-0.1-0.19
				C519.98,228.04,520.31,228.29,520.14,228.26z M520.59,229.4c-0.08-0.03-0.07-0.01-0.13-0.13
				C520.5,229.29,520.45,229.27,520.59,229.4z M520.46,228.54c0.05,0.04,0,0,0.11,0.11C520.54,228.63,520.51,228.6,520.46,228.54z
				 M515.53,238.57c0.03-0.01-0.09-0.05-0.17-0.09C515.3,238.49,515.68,238.5,515.53,238.57z M516.46,239.2
				c-0.07,0.03-0.06,0.04-0.17-0.02C516.33,239.18,516.29,239.19,516.46,239.2z M515.92,238.6c0.06,0.01,0,0,0.14,0.03
				C516.02,238.62,515.98,238.62,515.92,238.6z M525.31,245.73c0.02,0.02,0.08,0.03-0.02,0.03
				C525.29,245.75,525.3,245.74,525.31,245.73z M525.53,245.53c-0.02,0-0.08,0.01-0.07-0.1
				C525.48,245.46,525.5,245.49,525.53,245.53z M520.77,253.2c-0.01,0.02-0.02,0.05-0.04,0.11
				C520.75,253.26,520.74,253.3,520.77,253.2z M520.67,253.62c-0.01-0.03-0.06,0.07-0.1,0.13
				C520.58,253.79,520.62,253.48,520.67,253.62z M525.62,247.15c-0.09-0.03-0.15-0.06-0.06,0.56c-0.03-0.03-0.07,0.04-0.07-0.06
				c-0.02,0.29,0.14,0.45,0.08,0.57l-0.01-0.02c0.02,0.81,0.26,0.75,0.41,2.3c-0.1,0.01,0.03-0.09,0.13,0.49
				c-0.03-0.01,0.06,0.48,0.09,0.72l-0.02,0c0.1,0.02,0.14,0.27,0.21,0.44c-0.05,0.01-0.08-0.02-0.13-0.11
				c0.02,0.02-0.04,0.03,0.15,0.34c-0.28-0.11,0.08,0.11,0.32,0.81l-0.09-0.1c0.04,0.04,0.11,0.38,0,0.11
				c0.11,0.53,0.04,0.22,0.41,1.25l0,0c0.37,0.75-0.06,0.17,0.75,2.28c-0.1-0.15-0.01,0.07-0.09-0.16c0.06,0.22,0.04,0.28,0.19,0.48
				c-0.09-0.05-0.02,0.19,0.04,0.28l-0.02-0.16c0.05,0.11,0.09,0.21,0.12,0.31l-0.1-0.02c0.14,0.46,0.36,0.96,0.86,1.93
				c0.1-0.27,0.03,0.58,0.14,0.41c0.07,0.27,0.16,0.39,0.25,0.66c0.03-0.05,0.54,0.63,0.2,0.58c0.16,0.21,0.16,0.45,0.21,0.58
				l-0.08-0.11c0.05,0.07,0.07,0.13,0.1,0.19l0.02-0.09c0.02,0.08,0.05,0.14,0.03,0.15c0.16,0.1,0.18-0.08,0.29,0.37
				c-0.11-0.3-0.06,0.04-0.18-0.16c0.1,0.31-0.01,0.2,0.32,0.53c-0.06-0.02-0.04,0.1-0.01,0.15c-0.04-0.09,0.12-0.03,0.28,0.3
				l-0.04-0.12c0.18,0.14,0.2,0.5,0.33,1.35c0.05-0.08,0.03-0.09,0.12,0.07c-0.04-0.13-0.08-0.26-0.13-0.39l0.15,0.24
				c-0.03-0.12-0.1-0.25-0.11-0.33c0.08,0.11,0.06,0.1,0.1,0.03c0.09,0.59-0.11,0.34-0.01,1.43l0.05-0.11c0,0.35-0.08,0.3-0.7,1.39
				c0.04-0.1-0.08-0.05,0.04-0.19c-0.07,0.06-0.14,0.1-0.12,0.13c0.01-0.06-0.06,0.07-0.31,0.16c0.03,0,0.03,0,0.07-0.02
				c-0.03,0.05-0.13,0-0.25,0.21c-0.13-0.09-0.31-0.03-0.75-0.23c0.03-0.02,0.03-0.06-0.05-0.08l0,0.06
				c-0.2-0.1-0.45-0.24-0.78-0.48c0.02,0,0.02-0.02,0.04,0.01c-0.47-0.48-0.04,0.02-0.55-0.83c0.22,0.33,0.05,0.03-0.36,0.4
				c-0.04-0.03-0.06-0.02-0.1-0.08c-0.02,0.11,0.14,0,0.11,0.57c-0.03-0.02-0.05-0.04-0.06-0.05c0.03,0.05,0.09,0.2-0.23,0.3
				c0.04-0.05,0.08-0.1,0.11-0.16c-0.12,0.03-0.15,0.2-0.24,0.25l-0.02-0.04c-0.21,0.17-0.17,0.27-0.5,0.34l0.04,0.03
				c-0.43,0.09-0.22-0.06-0.77-0.14c0.1,0.01,0.2,0.02,0.28,0.01c-0.11-0.02-0.38-0.1-0.45-0.1c0.05,0.01,0.11,0.02,0.15,0.05
				c-1.03-0.61-0.23,0.11-1.67-2.77c0.03,0.65-0.08-0.27-0.11,0.18c-0.05-0.18,0,0.01-0.07-0.26c0,0,0,0,0,0.01
				c-0.01-0.06-0.03-0.18-0.1-0.56c-0.1,0.12-0.08,0.18-0.01-0.35c-0.21,0.59-0.09-0.16-0.29,0.93c0.05-0.1,0.1-0.18,0.14-0.25
				c-0.1,0.54-0.09,0.19-0.4,1.25l0.04-0.27c-0.44,0.55,0.31,0.68-1.36,1.73l0.02-0.04c-0.19,0.06-0.09,0.02-0.48,0.03
				c0.03-0.01,0.06-0.01,0.08-0.02c-0.31-0.12-0.55-0.14-0.79-0.41c0.08-0.06-0.05-0.35-0.09-0.51l0.02,0
				c0.11-1.01-0.57,0.13-0.64-5.62l0.07,0.01c0.05-0.32,0.06-0.48,0.1-0.88l0.04,0.09c-0.07-0.75-0.07-0.33,0.17-1.74
				c0.01,0.01,0.04,0.03,0.03,0.09c0.04-0.18,0.22-0.69,0.07-0.65c0.09,0.02,0.1-0.39,0.12-0.61l0.06,0.05
				c-0.02-0.02,0-0.28,0.09-0.81l0.02,0.1c0.1-0.3-0.12-0.23,0.22-0.69c-0.02,0.09-0.01-0.31,0.04-0.21c0.2-0.3-0.08-0.01,0.29-0.73
				c-0.1,0.25-0.17,0.46-0.17,0.44c0.06,0.02,0.14-0.18,0.17,0.01c0.04-0.18-0.05-0.01,0.16-0.71l0,0.02
				c0.1-0.28,0.09-0.14,0.29-0.61c-0.07-0.02,0-0.15-0.11-0.04c0.02-0.03-0.02,0.05-0.04,0.14l0-0.01c-0.03,0.07-0.06,0.16-0.1,0.25
				c0.13-0.75,0.29-0.46,0.82-1.92c-0.05,0.26-0.08,0.24,0.29-0.59l0.01,0.05c0.43-0.51,0.28-1.05,0.81-1.59
				c-0.04,0.04-0.05,0.06-0.07,0.06c0.3-0.2,0.89-1.68,2.17-3.35c0.02,0.03,0.04,0.06,0,0.08c0.05,0.07-0.04,0.13,0.03,0.21
				c-0.05,0.01-0.03-0.01,0.08,0.09c0.01-0.01-0.02-0.02-0.03-0.04l0.02,0.03c-0.08-0.16-0.17,0.06-0.09-0.02
				c-0.27-0.26-0.19,0.38-0.28,0.87c0.06-0.35-0.16,0-0.48,1.72l-0.1,0.01c0.12,0.12-0.11-0.06-0.02,0.28
				c-0.03-0.01-0.03-0.03-0.05-0.06c0.11,0.08-0.12,0.01,0.03,0.09c-0.05,0.02-0.07,0.09-0.06,0.15l-0.05-0.02
				c-0.03,0.09-0.02,0.21-0.04,0.32c0.03-0.03,0.12-0.01,0.12,0.03l-0.08,0c0.05,0.03,0.16,0.07-0.03,0.01l0.02,0.06
				c-0.02,0-0.03-0.02-0.06-0.03c0.05,0.03-0.1,0.07-0.02,0.12c-0.06,0.06-0.04,0.4,0.03,0.6c-0.06-0.01-0.07,0.07-0.11,0.02
				c-0.01,0.1-0.01,0.12,0.02,0.25c0.04-0.02,0.1,0.01-0.01,0.02c0.07,0.15-0.24,0.28-0.13,0.41l-0.01-0.01
				c0.06,0.19-0.01,0.13-0.18,0.78c0.02,0.01,0.11,0,0,0.05c0.3-0.2-0.2,0.46-0.06,1.35c-0.02-0.01-0.04-0.01-0.04-0.03
				c-0.06,0.03-0.01,0.13,0.04,0.14c0.03-0.06-0.1,0.23,0.01,0.5c-0.11-0.01-0.1,0.11-0.05,0.15c-0.03-0.02-0.04,0.03-0.08,0.01
				c0,0.05,0.02,0.09,0.08,0.08c-0.14,0.08,0.1,0.18-0.07,0.21c0.06,0.22,0.09,0.09,0.07,0.86c-0.01,0-0.02-0.01-0.03-0.02
				c0,0.02-0.02,0.05,0.03,0.05l-0.01-0.02c0.07-0.03,0.15,0.08,0.19,0.09c-0.15-0.04-0.04,0.1-0.22,0.04
				c0.01,0.01,0.03,0.03,0.07,0.03c-0.09,0.17-0.01-0.01-0.03,0.58l0.04-0.02c0,0.05,0.07,0.09,0.04,0.14l-0.04-0.04l0.02,0.09
				l0.03-0.05c0.07,0.09,0.16-0.03-0.04,0.22c0.08,0.02,0.02,0.12,0.11,0.14c-0.02-0.01-0.07-0.01-0.08-0.01l0.06,0.02
				c-0.05,0.05,0.01,0.05-0.05,0.23l-0.01-0.03l-0.04,0.03l0.05,0.01c-0.1-0.01,0.12,0.19,0.06,0.47c-0.01-0.01-0.07-0.01-0.06-0.01
				c-0.02,0.07,0.04,0.15,0.09,0.25c-0.02-0.07-0.14,0.14,0.02,0.17c-0.06,0.12-0.24,0.12,0.12,0.5c-0.04-0.04-0.09,0.01-0.12,0.03
				c0.02,0,0.04,0,0.06,0c-0.14,0.05,0.1,0.03,0.02,0.08c0.01,0.01,0.09,0.14-0.03,0.06c0.04,0.13-0.01-0.01-0.06,0.22
				c0.21-0.02,0.01-0.01,0.18,0.15c-0.11-0.04-0.03-0.03-0.2,0.04c0.06,0.03-0.04,0.06,0.05,0.1c-0.02,0-0.02,0.02-0.03,0.03
				c0.04,0,0.08,0.1,0.2,0.52c-0.15-0.01,0.1,0.12-0.11,0.06c0.03,0.04,0.08,0.07,0.1,0.1c-0.04,0.02-0.07-0.02-0.1-0.03
				c0.08,0.02,0.13,0.17,0.1-0.12l0.11,0.01c-0.15-0.05,0-0.05,0-0.16c0.02,0.01,0.07-0.02,0.06,0.01c0.02-0.1,0.03-0.19-0.18-0.2
				l0.18-0.07l-0.08,0c0-0.01,0-0.03,0.04-0.03c-0.06-0.03-0.03,0.01-0.08-0.01c0.06-0.06-0.05-0.13,0.07-0.16
				c-0.06-0.01-0.04,0.05-0.09,0c0.16,0.01-0.09-0.12,0.12-0.11c-0.13-0.1,0.1-0.14-0.09-0.26c0.04,0.01-0.02-0.02,0.08-0.18
				c-0.09,0-0.08-0.07-0.1-0.11c0.05,0,0.08,0,0.1,0.03c-0.01-0.03,0-0.04-0.06-0.09l0.07,0.01c-0.01-0.05-0.09-0.11-0.05-0.18
				c-0.06,0-0.17,0.07-0.22,0c0.03-0.01,0.09-0.01,0.11,0.01c-0.01,0,0.04-0.11,0.07-0.04c0.01-0.03,0.03-0.07,0.07-0.08
				c-0.03,0-0.15-0.03,0.01-0.07c-0.03-0.01,0-0.05-0.08-0.05c0-0.02,0.03-0.02,0.05-0.03c-0.04-0.02,0,0-0.1-0.09v0
				c-0.1-0.07,0.09-0.25,0-0.6c0.04,0.04,0.04-0.02,0.03,0.04c0.02-0.06,0.06-0.07-0.01-0.13c0.06,0.02,0.08-0.04,0.06-0.07
				l-0.04,0.04c0.13-0.16-0.16-0.5,0.05-0.62l-0.03-0.01c0.03-0.03,0.02-0.1,0.07-0.08c-0.03-0.04-0.05-0.02-0.06,0.01
				c0.04-0.07,0-0.1,0.03-0.17c-0.05,0.03-0.21-0.23,0.05-0.15c-0.06-0.06,0.04-0.12,0.05-0.15l0.02,0.03
				c-0.01-0.02-0.01-0.04-0.01-0.05l-0.06,0.02c0.01-0.02,0.01-0.04,0.04-0.04c-0.04-0.02-0.1,0.01-0.15-0.03
				c0.03-0.03-0.03-0.08,0.05-0.09c-0.03,0.11,0.3,0.02,0.03-0.1c0.03,0.01,0.06-0.01,0.07-0.02c-0.02,0.01-0.09-0.01-0.12-0.11
				c-0.03,0.03-0.03,0.05-0.07-0.04c0.03,0,0.02,0.03,0.04,0.02c-0.08-0.04,0.08-0.09,0.03-0.15c0.02,0,0.24-0.09,0.02-0.18
				l0.04,0.11l-0.08-0.07c0,0.03,0.03,0.07,0.02,0.09c-0.05-0.03-0.03-0.03-0.08-0.02c-0.04-0.24,0.26,0.05,0.13-0.37
				c-0.04,0.02-0.06,0.06-0.05-0.06c0.05,0.01,0.07-0.01,0.1-0.03c-0.04,0,0.01-0.07-0.09-0.09c0.07-0.07,0.13-0.04-0.05-0.22
				c0.05,0.03,0.09-0.01,0.12,0.05c-0.04-0.08,0.01,0.02-0.17-0.22c0.11-0.06,0.12-0.07,0.12-0.47c0.01,0,0.02,0,0.02,0.01
				c-0.12-0.16,0.2-0.05,0.05-0.41c0,0,0.01,0,0.02,0c-0.08-0.05-0.1-0.14-0.11-0.21c0.14,0.03,0.09,0.04,0.12-0.09
				c0.27,0.24-0.26-0.37,0.04-0.31c-0.02-0.04-0.08-0.13-0.09-0.19c0.07,0.04,0.1,0.12,0.14,0.16c0-0.03,0.01-0.11-0.04-0.13
				c0,0.05,0,0.06-0.08-0.13c0.19,0.03,0.07-0.16,0.22-0.19c-0.05-0.12,0.06-0.22,0.07-0.35c-0.04,0.05-0.2-0.13-0.03-0.07
				c-0.02-0.01-0.04-0.04-0.07-0.04c0.04-0.04,0.11-0.08,0.24-0.09c-0.06,0-0.07-0.04-0.05-0.07c-0.06,0.09-0.04-0.17,0.04-0.09
				c-0.02-0.05,0.03-0.18-0.08-0.25c0.02-0.01,0.06-0.01,0.07,0.01c0.15-0.6-0.01-0.44,0.33-1.58c0.01,0.01-0.14-0.02-0.05-0.11
				l0,0.01c0.1,0,0.09-0.06,0.1-0.11l-0.13-0.04c0.22-0.07,0.01-0.33,0.19-0.35c-0.03-0.04-0.02-0.07-0.04-0.11
				c0.09-0.01,0.08,0.04,0.13-0.19l0.04,0.03c-0.1-0.19-0.14-0.04,0.01-0.45c0.01,0.01,0.04,0.02,0.04,0.03
				c0.02-0.04,0.12-0.15-0.03-0.18c0.09,0.02,0.04-0.1,0.01-0.16c0.16,0.08-0.03-0.02,0.17-0.58c-0.02,0.06-0.04,0.12-0.05,0.11
				c0.07,0.09,0.26-0.19,0.1-0.34c0.01,0,0,0.01,0.01,0.04l-0.01,0c0,0.02-0.01,0.04-0.02,0.07c-0.03-0.03-0.05-0.06,0-0.08
				c-0.04-0.03-0.08-0.06-0.1-0.07c0.12-0.08,0.35,0.13,0.19-0.32C525.5,246.76,525.61,246.98,525.62,247.15z M521.32,253.09
				c0-0.03,0.01,0,0.04-0.13C521.38,253.02,521.39,253.02,521.32,253.09z M525.02,247.13c-0.01-0.01-0.04,0.01-0.06,0.02
				C524.97,247.15,524.96,247.07,525.02,247.13z M525,247.01c0,0.01,0,0.01,0,0.03C524.99,247.03,524.99,247.04,525,247.01z
				 M525.47,247.04c0.01,0.01,0.02,0.01,0.03,0.02C525.5,247.08,525.47,247.09,525.47,247.04z M525.57,246.58l-0.03-0.11
				c-0.01,0.01,0,0.02-0.01,0.02c-0.01-0.02-0.01-0.04-0.03-0.05C525.57,246.42,525.65,246.34,525.57,246.58z M511.37,258.76
				c-0.26,0-0.36-0.03-0.47-0.06c0.15-0.02,0.29-0.04,0.43-0.07c-0.09,0.05-0.18,0.08-0.27,0.11c0.14,0,0.28-0.06,0.36-0.06
				C511.28,258.74,511.29,258.72,511.37,258.76z M514.96,243.09c-0.03,0-0.06,0.01-0.12,0.02l0,0
				C514.89,243.1,514.94,243.09,514.96,243.09z M514.52,243.21c0.02-0.02-0.09-0.01-0.17-0.02
				C514.3,243.22,514.62,243.09,514.52,243.21z M503.43,248.62c1.02-0.32-0.52-0.56,4.92-3.26l0.02,0.07
				c0.33-0.11,0.49-0.17,0.89-0.34l-0.06,0.08c0.69-0.43,0.29-0.23,1.73-0.71c0,0.02,0,0.05-0.07,0.08
				c0.19-0.06,0.76-0.14,0.65-0.27c0.03,0.09,0.42-0.11,0.64-0.2l-0.02,0.08c0.01-0.03,0.26-0.14,0.81-0.33l-0.09,0.06
				c0.35-0.07,0.17-0.21,0.76-0.15c-0.1,0.03,0.28-0.16,0.22-0.07c0.39,0.02-0.03-0.08,0.84-0.11c-0.29,0.05-0.52,0.08-0.5,0.07
				c0.01,0.06,0.24,0.04,0.08,0.15c0.19-0.05-0.02-0.05,0.75-0.22l-0.02,0.01c0.32-0.06,0.18,0.01,0.72-0.05
				c-0.02-0.07,0.14-0.07-0.01-0.12c0.03,0-0.05,0.01-0.15,0.04l0.01-0.01c-0.08,0.01-0.18,0.03-0.29,0.04
				c0.77-0.26,0.58,0.02,2.22-0.22c-0.27,0.08-0.26,0.05,0.7-0.02l-0.05,0.03c0.69,0.14,1.14-0.24,1.9,0
				c-0.05-0.02-0.08-0.02-0.09-0.04c0.26,0.25,2.3-0.04,4.19,1.35l-0.02-0.08c-0.15,0.07,0.6,0.19,0.12,0.54l0.08-0.09
				c-0.19-0.03-0.36,0.33-0.55,0.48c-0.02-0.1-0.04-0.15-0.53,0.29c0.01-0.04-0.07-0.04,0.02-0.09c-0.27,0.16-0.32,0.38-0.46,0.4
				l0.02-0.02c-0.73,0.5-0.52,0.67-1.85,1.68c-0.06-0.08,0.09-0.03-0.37,0.4c-0.01-0.04-0.4,0.33-0.6,0.49l-0.01-0.02
				c0.03,0.1-0.17,0.28-0.28,0.43c-0.03-0.04-0.03-0.08,0.03-0.18c-0.01,0.03-0.04-0.02-0.23,0.33c-0.05-0.31-0.05,0.13-0.55,0.75
				l0.04-0.14c-0.02,0.06-0.28,0.32-0.1,0.06c-0.41,0.41-0.17,0.17-0.86,1.11l0,0c-0.07,0.26-0.75,0.8-1.46,2.13
				c0.06-0.18-0.07,0.04,0.07-0.18c-0.14,0.2-0.2,0.23-0.27,0.49c-0.01-0.11-0.16,0.12-0.19,0.23l0.12-0.13
				c-0.05,0.12-0.11,0.21-0.17,0.31l-0.05-0.09c-0.28,0.43-0.49,0.97-0.98,2.01c0.29-0.08-0.47,0.38-0.26,0.38
				c-0.19,0.22-0.24,0.37-0.43,0.59c0.07,0.01-0.34,0.79-0.43,0.45c-0.13,0.23-0.36,0.32-0.47,0.42l0.08-0.11
				c-0.05,0.07-0.09,0.11-0.15,0.15l0.1,0c-0.07,0.04-0.12,0.08-0.13,0.06c-0.05,0.18,0.14,0.16-0.3,0.36
				c0.28-0.17-0.05-0.05,0.13-0.2c-0.28,0.14-0.19,0.03-0.48,0.39c0.02-0.06-0.11-0.04-0.15-0.01c0.09-0.05,0.04,0.13-0.31,0.28
				c0.4-0.07,0.17-0.01-2.44,0.07l0.11,0.07c-0.36-0.08-0.31-0.13-1.34-1c0.09,0.06,0.05-0.08,0.19,0.08
				c-0.06-0.08-0.08-0.17-0.12-0.16c0.05,0.02-0.07-0.08-0.15-0.36c0,0.03,0,0.03,0.02,0.08c-0.05-0.03,0-0.15-0.21-0.29
				c0.09-0.06,0.03-0.42,0.11-0.91c0.01,0.07,0.08,0.09,0.08-0.03l-0.08,0.02c0.02-0.06-0.01-0.03,0.08-0.3
				c0,0.01-0.01,0.01-0.01,0.01c0.08-0.26,0.2-0.54,0.42-0.82c0.01,0.01,0.04-0.01-0.01,0.05c0.1-0.53,0.94-0.54-0.56-0.44
				l0.09-0.02c-0.15,0.03-0.23-0.02-1.73,0.36c0-0.03,0.01-0.04,0.01-0.05c-0.04,0.05-0.15,0.12-0.45-0.01l0.24-0.03
				c-0.13-0.07-0.31,0.04-0.43,0.04l0.01-0.04c-0.36,0.05-0.36,0.17-0.7,0.12l0.03,0.04c-0.24-0.15-0.28-0.07-0.58-0.79
				c0.06,0.14,0.14,0.4,0.26,0.46c-0.07-0.07-0.12-0.32-0.16-0.39c0.01,0.05,0.04,0.11,0.03,0.15c0.05-0.6,0.13-1.28,2.23-3.45
				c-0.03,0.07-0.17-0.15-0.35-0.36c0.07,0.12,0.14,0.22,0.18,0.33c-0.3-0.17,0.05-0.33-0.89-0.65c0.06-0.01,0.11-0.02,0.17-0.02
				c-0.37-0.17-0.47,0.12-2.5,0.02l0.04-0.02c-0.19-0.07-0.1-0.02-0.42-0.26c0.03,0.02,0.06,0.03,0.08,0.04
				c-0.18-0.29-0.37-0.5-0.38-0.88C503.26,249.06,503.34,248.74,503.43,248.62z M515.34,243.52c0.03-0.01,0,0.01,0.14-0.04
				C515.43,243.53,515.45,243.54,515.34,243.52z"
                />
                <path
                  className="st10"
                  d="M511.61,251.98l0.01,0.01c0,0,0,0,0-0.01c0.06,0.03,0.15,0.07,0.3,0.12l-0.03-0.08l0.06,0.01l-0.02,0.04
				c0.06,0.01,0.03-0.03,0.01-0.07c0.03,0.01,0.05,0.02,0.07,0.01l-0.06-0.11c0.04,0.02,0.06,0.07,0.1,0.05
				c-0.03-0.05-0.1-0.03-0.1-0.09c0.06,0.01,0.09,0.04,0.07-0.02c0.03,0.01,0.04,0.03,0.04,0.05c-0.01-0.04,0.03-0.07-0.01-0.12
				c0.07,0.01,0.04-0.09,0.13-0.03c-0.02-0.02-0.04-0.09-0.03-0.11c0.08,0.11,0.06-0.08,0.17,0.01c-0.08-0.1,0.03-0.14,0.01-0.23
				c0.01,0.01,0.02,0.03,0.01,0.03c0.03-0.02,0.06-0.01,0.09-0.02c-0.08-0.06-0.06-0.12-0.09-0.18c0.1,0.02,0.08,0.09,0.16,0.13
				c-0.07-0.05-0.02-0.11-0.08-0.13c0.07,0.01,0.04-0.01,0.12,0.03c-0.09-0.08,0-0.04-0.04-0.1c0.08,0,0.1,0.06,0.1,0
				c0.07,0.14,0.15-0.16,0.08-0.44c-0.01,0.01,0.01,0.03,0.02,0.04l0-0.04c0.02,0.16,0.17,0.02,0.08,0.05
				c0.14,0.03,0.08,0.16,0.2-0.05c-0.22-0.1,0.28-0.07,0.36-0.55c-0.24,0.28,0.13,0.08,1.23-1.05l0.08,0.06
				c-0.02-0.16,0.04,0.11,0.17-0.19c0.02,0.03,0.01,0.05,0,0.07c-0.03-0.13,0.1,0.07,0.03-0.09c0.05,0.02,0.11-0.02,0.14-0.06
				l0.02,0.05c0.08-0.04,0.14-0.13,0.22-0.18c-0.04,0-0.09-0.08-0.07-0.11l0.06,0.06c-0.02-0.05-0.07-0.16,0.02,0.02l0.02-0.05
				c0.02,0.02,0.01,0.04,0.03,0.06c-0.01-0.05,0.11,0.02,0.08-0.06c0.07-0.01,0.3-0.25,0.37-0.41c0.12,0.18,0.53-0.33,1.29-0.52
				c-0.01-0.02-0.06-0.09,0.03-0.03c-0.31-0.14,0.44-0.08,1.08-0.59c0,0.02,0.01,0.04-0.01,0.05c0.02-0.02,0.12-0.06,0.49-0.31
				c0.04,0.11,0.13,0.05,0.14-0.02c0,0.04,0.04,0.02,0.05,0.07c0.04-0.02,0.07-0.05,0.04-0.1c0.12,0.1,0.11-0.16,0.21-0.01
				c0.16-0.13,0.04-0.13,0.7-0.37c0,0.01,0,0.02-0.01,0.04c0.02-0.01,0.05,0,0.03-0.05l-0.01,0.02c-0.05-0.06,0.02-0.17,0.01-0.21
				c0.02,0.15,0.1,0,0.12,0.2c0.01-0.01,0.01-0.04,0-0.07c0.17,0.03,0.04,0,0.47-0.11c-0.03,0,0.09-0.01,0.01-0.08
				c0.04-0.01,0.06-0.09,0.11-0.07l-0.02,0.05l0.08-0.04l-0.06-0.02c0.06-0.09-0.07-0.14,0.21-0.02c0-0.08,0.09-0.05,0.1-0.14
				c0,0.02,0.01,0.07,0.02,0.08l0-0.07c0.06,0.03,0.04-0.02,0.22-0.01l-0.02,0.02l0.04,0.03l0-0.05c0.02,0.1,0.15-0.17,0.4-0.17
				c-0.01,0.01,0.01,0.07,0.01,0.06c0.07,0,0.12-0.07,0.2-0.14c-0.06,0.03,0.16,0.11,0.15-0.06c0.12,0.04,0.16,0.2,0.43-0.22
				c-0.03,0.05,0.03,0.09,0.05,0.11c-0.01-0.02-0.01-0.04-0.01-0.05c0.07,0.13,0.01-0.1,0.07-0.04c0.01-0.01,0.11-0.11,0.06,0.02
				c0.12-0.06-0.05,0,0.21,0.02c-0.06-0.2-0.01-0.01,0.1-0.2c-0.02,0.11-0.03,0.03,0.08,0.19c0.01-0.07,0.06,0.03,0.08-0.07
				c0,0.14-0.08,0.07,0.47-0.24c0.01,0.15,0.09-0.12,0.07,0.1c0.03-0.03,0.05-0.08,0.08-0.12c0.02,0.04,0,0.07-0.01,0.1
				c0-0.07,0.14-0.17-0.12-0.08l-0.01-0.11c-0.02,0.15-0.04,0.02-0.15,0.02c0-0.02-0.03-0.07,0-0.06c-0.1-0.01-0.18,0-0.15,0.21
				l-0.1-0.17l0.01,0.08c-0.01,0-0.03,0-0.03-0.03c-0.02,0.06,0.01,0.03,0.01,0.08c-0.06-0.05-0.11,0.07-0.16-0.05
				c0,0.06,0.05,0.03,0.02,0.08c-0.02-0.15-0.1,0.11-0.12-0.1c-0.06,0.14-0.15-0.08-0.23,0.13c0-0.04-0.01,0.03-0.19-0.05
				c0.02,0.09-0.04,0.09-0.08,0.12c-0.01-0.05-0.01-0.08,0-0.11c-0.03,0.01-0.04,0.01-0.07,0.08l-0.01-0.07
				c-0.05,0.02-0.08,0.11-0.16,0.08c0.01,0.06,0.1,0.16,0.05,0.22c-0.01-0.03-0.03-0.09-0.02-0.11c0,0.01-0.11-0.01-0.05-0.06
				c-0.03,0-0.07-0.02-0.09-0.05c0,0.03,0,0.15-0.07,0.01c0,0.04-0.04,0.01-0.02,0.08c-0.01,0-0.03-0.03-0.04-0.05
				c-0.01,0.04,0,0-0.06,0.12l0,0c-0.01,0.14-0.11-0.02-0.54,0.13c0.03-0.05-0.02-0.03,0.03-0.04c-0.06-0.01-0.08-0.04-0.11,0.04
				c0-0.07-0.06-0.06-0.08-0.04l0.05,0.03c-0.19-0.07-0.4,0.29-0.57,0.12l0,0.03c-0.03-0.02-0.1,0-0.1-0.05
				c-0.03,0.04,0,0.05,0.03,0.06c-0.07-0.02-0.09,0.03-0.16,0.02c0.04,0.04-0.14,0.27-0.15,0c-0.04,0.07-0.12,0-0.15,0l0.02-0.03
				c-0.01,0.02-0.03,0.02-0.04,0.03l0.04,0.05c-0.02,0-0.04,0-0.04-0.02c0,0.04,0.05,0.09,0.03,0.15c-0.03-0.02-0.06,0.06-0.09-0.02
				c0.11-0.01-0.08-0.29-0.1,0.01c0-0.04-0.03-0.05-0.04-0.06c0.01,0.02,0.02,0.08-0.05,0.15c0.03,0.02,0.06,0.01-0.01,0.08
				c-0.01-0.03,0.02-0.03,0-0.04c-0.01,0.09-0.11-0.05-0.14,0.02c0-0.02-0.17-0.19-0.17,0.05l0.08-0.07l-0.03,0.1
				c0.03-0.01,0.05-0.06,0.07-0.05c-0.01,0.06-0.01,0.04,0.02,0.08c-0.2,0.13-0.05-0.26-0.37,0.02c0.04,0.03,0.07,0.03-0.03,0.08
				c-0.02-0.05-0.04-0.06-0.06-0.08c0.01,0.03-0.07,0.02-0.04,0.12c-0.09-0.04-0.09-0.11-0.17,0.13c0-0.06-0.04-0.08-0.01-0.13
				c-0.05,0.07,0.01-0.02-0.12,0.24c-0.1-0.08-0.11-0.08-0.44,0.09c0-0.01-0.01-0.02,0-0.02c-0.16,0.19-0.01-0.19-0.37,0.14
				c0,0,0-0.01-0.01-0.02c-0.01,0.09-0.07,0.15-0.12,0.2c0.02-0.18-0.23-0.09-0.5,0.24c0-0.08,0.04-0.14,0.06-0.2
				c-0.03,0.01-0.09,0.05-0.09,0.1c0.04-0.02,0.05-0.03-0.06,0.13c-0.07-0.18-0.17,0.03-0.27-0.09c-0.07,0.11-0.21,0.07-0.31,0.14
				c0.06,0.01,0.01,0.24-0.03,0.07c0,0.02-0.01,0.05,0.01,0.08c-0.05-0.01-0.12-0.05-0.21-0.14c0.03,0.04,0.02,0.08-0.03,0.08
				c0.1-0.01-0.1,0.13-0.09,0.02c-0.03,0.05-0.15,0.08-0.14,0.21c-0.02-0.01-0.05-0.04-0.03-0.06c-0.52,0.27-0.32,0.27-1.32,0.81
				c0-0.01,0.09,0.1-0.04,0.11l0.01,0c-0.07-0.07-0.11-0.02-0.15,0.01l0.07,0.11c-0.21-0.09-0.2,0.24-0.35,0.14
				c0,0.04-0.03,0.06-0.03,0.11c-0.08-0.05-0.03-0.08-0.22,0.07l-0.02-0.05c-0.02,0.21,0.08,0.1-0.26,0.35
				c-0.01-0.01-0.03-0.04-0.02-0.05c-0.04,0.03-0.18,0.06-0.07,0.16c-0.06-0.07-0.08,0.06-0.09,0.12c-0.09-0.14,0.01,0.02-0.42,0.39
				c0.05-0.05,0.09-0.08,0.09-0.07c-0.02-0.11-0.31,0.05-0.23,0.25c-0.01,0,0.01-0.01,0.01-0.04l0,0.01
				c0.01-0.01,0.03-0.03,0.04-0.05c0.01,0.04,0.02,0.08-0.03,0.07c0.02,0.04,0.05,0.09,0.06,0.11c-0.02,0.07-0.37,0.11-0.69,0.65
				c0.02,0,0.02,0,0.04,0c-0.08,0.09-0.22,0.03-0.4,0.56l-0.07-0.03C512.06,251.3,511.57,251.59,511.61,251.98z M513.37,250.11
				c-0.02,0.01-0.04,0.06-0.06-0.01C513.32,250.11,513.35,250.11,513.37,250.11z"
                />
                <path
                  className="st10"
                  d="M539.01,242.93c-0.14,0-0.19-0.14-0.32-0.2c0.02,0.05-0.21,0.17-0.07-0.03c0.01,0.07-0.06,0.06-0.07,0.11
				c-0.03-0.05-0.05-0.14-0.01-0.28c-0.02,0.05-0.06,0.06-0.08,0.02c-0.01,0.08-0.01,0.17-0.12-0.04l0.05-0.03
				c-0.06,0-0.17-0.09-0.27,0c0-0.02,0.01-0.06,0.03-0.06c-0.45-0.3-0.36-0.03-1.52-0.7c-0.12,0.08-0.08-0.04-0.72-0.16
				c0-0.1,0.05-0.07-0.19-0.15l0.04-0.04c-0.2,0.09-0.05,0.12-0.46-0.03c0.01-0.01,0.02-0.04,0.03-0.04
				c-0.05-0.02-0.16-0.12-0.18,0.02c0.03-0.09-0.1-0.04-0.16-0.01c0.08-0.16-0.02,0.03-0.6-0.16c0.07,0.02,0.12,0.04,0.11,0.04
				c0.09-0.08-0.21-0.25-0.36-0.08c0-0.01,0.01,0,0.04-0.01l0,0.01c0.02,0,0.04,0.01,0.07,0.01c-0.03,0.03-0.06,0.05-0.08,0.01
				c-0.03,0.04-0.05,0.08-0.06,0.1c-0.08,0.01-0.3-0.25-0.99-0.29c0.01,0.01,0.02,0.02,0.02,0.04c-0.14-0.03-0.12-0.17-0.74-0.08
				l0-0.08c0.1,0.17-0.32-0.16-0.77,0.09c0,0,0,0,0.01,0c0,0.07,0.01,0.17,0.03,0.32l0.07-0.07l0.02,0.06l-0.05,0
				c0.03,0.06,0.05,0.01,0.07-0.02c0,0.03,0.01,0.05,0.02,0.06l0.07-0.1c0,0.05-0.04,0.09,0,0.12c0.03-0.05-0.02-0.1,0.03-0.13
				c0.02,0.06,0.01,0.1,0.05,0.06c0,0.03-0.01,0.05-0.02,0.06c0.03-0.03,0.08-0.01,0.11-0.07c0.02,0.07,0.11-0.01,0.09,0.1
				c0.01-0.03,0.06-0.08,0.09-0.08c-0.07,0.12,0.11,0.02,0.08,0.16c0.05-0.11,0.15-0.03,0.22-0.09c-0.01,0.01-0.02,0.03-0.02,0.02
				c0.04,0.01,0.04,0.05,0.07,0.08c0.02-0.1,0.08-0.11,0.12-0.17c0.03,0.1-0.04,0.11-0.04,0.2c0.01-0.08,0.09-0.07,0.09-0.13
				c0.03,0.06,0.03,0.03,0.03,0.12c0.02-0.12,0.04-0.02,0.08-0.08c-0.03,0.08,0.22,0.15,0.52-0.05c-0.02,0-0.02,0.02-0.03,0.04
				l0.03-0.02c-0.14,0.1,0.08,0.16,0,0.09c-0.22,0.3,0.38,0.14,0.88,0.18c-0.36-0.05,0,0.15,1.69,0.51l-0.01,0.1
				c0.13-0.11-0.07,0.1,0.28,0.05c-0.01,0.03-0.04,0.03-0.06,0.04c0.1-0.1,0,0.12,0.1-0.02c0.01,0.05,0.08,0.08,0.14,0.08
				l-0.03,0.05c0.08,0.05,0.2,0.05,0.3,0.1c-0.03-0.04,0.02-0.12,0.05-0.12l-0.02,0.08c0.03-0.04,0.1-0.15,0,0.03l0.06-0.01
				c-0.01,0.02-0.02,0.03-0.04,0.06c0.04-0.04,0.05,0.11,0.11,0.04c0.05,0.06,0.37,0.11,0.58,0.1c-0.02,0.06,0.05,0.09-0.01,0.11
				c0.09,0.03,0.11,0.04,0.24,0.05c-0.01-0.05,0.04-0.1,0.02,0.01c0.17-0.03,0.2,0.3,0.35,0.23l-0.01,0c0.19,0,0.12,0.05,0.65,0.43
				c0.01-0.02,0.04-0.1,0.04,0.01c-0.06-0.35,0.34,0.34,1.16,0.64c-0.02,0.01-0.03,0.03-0.05,0.02c0,0.07,0.11,0.08,0.13,0.04
				c-0.04-0.06,0.15,0.2,0.43,0.24c-0.07,0.09,0.04,0.14,0.1,0.12c-0.03,0.02,0.01,0.05-0.03,0.08c0.04,0.03,0.08,0.03,0.11-0.02
				c-0.01,0.16,0.2,0.01,0.14,0.18c0.21,0.07,0.13-0.03,0.75,0.42c-0.01,0.01-0.02,0.01-0.04,0.01c0.02,0.01,0.03,0.04,0.06,0
				l-0.02,0c0.02-0.08,0.16-0.08,0.18-0.11c-0.11,0.1,0.06,0.09-0.09,0.21c0.02,0,0.04-0.01,0.06-0.04c0.09,0.17-0.02,0,0.46,0.35
				l0.01-0.05c0.04,0.03,0.12,0,0.13,0.05l-0.05,0.01l0.09,0.04l-0.03-0.06c0.12,0,0.06-0.15,0.16,0.16
				c0.06-0.05,0.11,0.05,0.18-0.01c-0.02,0.01-0.05,0.05-0.05,0.06l0.05-0.04c0.02,0.07,0.05,0.02,0.17,0.17l-0.04,0l0.01,0.05
				l0.03-0.03c-0.06,0.08,0.22,0,0.43,0.2c-0.02,0-0.05,0.06-0.04,0.05c0.06,0.05,0.15,0.04,0.27,0.06
				c-0.07-0.02,0.05,0.19,0.16,0.06c0.07,0.12,0.01,0.27,0.51,0.13c-0.06,0.02-0.03,0.09-0.02,0.12c0-0.02,0.02-0.03,0.02-0.05
				c-0.02,0.15,0.07-0.07,0.08,0.01c0.02,0,0.17-0.03,0.04,0.05c0.13,0.01-0.01,0,0.18,0.15c0.06-0.2,0-0.02,0.21-0.11
				c-0.08,0.09-0.04,0.02-0.03,0.2c0.05-0.05,0.05,0.06,0.12-0.01c0,0.02,0.01,0.02,0.01,0.04c0.01-0.04,0.13-0.04,0.57-0.03
				c-0.05,0.14,0.14-0.07,0.03,0.12c0.04-0.02,0.09-0.06,0.13-0.08c0.01,0.04-0.03,0.06-0.05,0.09c0.04-0.07,0.2-0.09-0.09-0.12
				l0.04-0.1c-0.09,0.13-0.05-0.01-0.16-0.05c0.01-0.02,0.01-0.07,0.03-0.05c-0.09-0.05-0.17-0.08-0.25,0.12l-0.01-0.2l-0.03,0.07
				c-0.01-0.01-0.03-0.02-0.02-0.04c-0.05,0.04,0,0.03-0.03,0.08c-0.03-0.08-0.14,0-0.12-0.12c-0.03,0.05,0.03,0.05-0.03,0.08
				c0.06-0.14-0.15,0.04-0.06-0.15c-0.14,0.08-0.09-0.15-0.28-0.02c0.03-0.04-0.03,0.02-0.13-0.15c-0.04,0.08-0.09,0.04-0.14,0.04
				c0.02-0.05,0.04-0.07,0.07-0.08c-0.03-0.01-0.04-0.02-0.1,0.02l0.03-0.06c-0.05-0.02-0.13,0.03-0.18-0.04
				c-0.03,0.06-0.02,0.19-0.1,0.2c0.01-0.03,0.03-0.09,0.06-0.09c0,0.01-0.09-0.08,0-0.08c-0.02-0.02-0.04-0.06-0.04-0.1
				c-0.02,0.03-0.1,0.11-0.06-0.04c-0.03,0.03-0.04-0.02-0.08,0.04c-0.01-0.01,0-0.04,0-0.06c-0.04,0.03,0,0-0.13,0.04l0,0
				c-0.11,0.06-0.17-0.2-0.51-0.31c0.06-0.02,0.01-0.04,0.05-0.01c-0.04-0.05-0.03-0.09-0.11-0.06c0.05-0.04,0-0.09-0.03-0.09
				l0.01,0.06c-0.07-0.19-0.51-0.14-0.49-0.38l-0.02,0.02c-0.01-0.04-0.07-0.08-0.03-0.11c-0.05,0-0.04,0.03-0.03,0.06
				c-0.03-0.07-0.09-0.06-0.13-0.12c0,0.06-0.31,0.05-0.09-0.13c-0.08,0.01-0.08-0.1-0.1-0.13l0.04,0c-0.02,0-0.04-0.01-0.05-0.02
				l-0.02,0.06c-0.01-0.02-0.02-0.03-0.01-0.05c-0.04,0.02-0.04,0.09-0.11,0.11c0-0.04-0.08-0.02-0.05-0.09
				c0.07,0.08,0.19-0.23-0.06-0.08c0.03-0.03,0.03-0.08,0.02-0.08c0.02-0.01-0.02,0.09-0.16,0.05c0.01,0.04,0.03,0.05-0.07,0.03
				c0.02-0.03,0.04,0,0.04-0.02c-0.08,0.04-0.03-0.12-0.1-0.11c0.01-0.01,0.06-0.25-0.14-0.12l0.11,0.03l-0.11,0.03
				c0.03,0.02,0.08,0.01,0.09,0.03c-0.06,0.02-0.04,0.01-0.06,0.06c-0.23-0.1,0.18-0.19-0.24-0.32c0,0.05,0.02,0.08-0.09,0.01
				c0.03-0.04,0.03-0.06,0.03-0.1c-0.02,0.03-0.06-0.05-0.12,0.03c-0.02-0.1,0.04-0.13-0.21-0.08c0.05-0.03,0.04-0.08,0.11-0.08
				c-0.09-0.01,0.02,0-0.28,0.02c0.01-0.13,0-0.14-0.34-0.35c0.01-0.01,0.01-0.02,0.02-0.01c-0.2,0.02,0.06-0.2-0.34-0.25
				c0,0,0.01-0.01,0.01-0.02c-0.09,0.04-0.18,0.02-0.25,0c0.09-0.11,0.07-0.06-0.03-0.15c0.35-0.13-0.45,0.06-0.27-0.18
				c-0.04,0-0.15,0.02-0.21,0c0.07-0.04,0.15-0.04,0.21-0.05c-0.03-0.02-0.1-0.05-0.14-0.02c0.04,0.02,0.05,0.03-0.15,0.02
				C539.21,243.05,538.98,243.08,539.01,242.93z M534.21,241.04c0-0.01,0.01-0.02,0.02-0.03
				C534.24,241.01,534.25,241.03,534.21,241.04z M534.21,241.51c0.01,0,0.01,0,0.03,0C534.22,241.51,534.24,241.51,534.21,241.51z
				 M534.32,241.48c-0.01,0.01,0.01,0.03,0.02,0.06C534.35,241.53,534.27,241.54,534.32,241.48z"
                />
                <path
                  className="st10"
                  d="M540.09,145.46c0.31,0.39,0.49,0.59,0.97,0.98l-0.13-0.04c1.01,0.57,0.45,0.31,2.24,1.42
				c-0.02,0.01-0.05,0.03-0.13-0.02c0.24,0.15,0.89,0.6,0.88,0.43c-0.04,0.09,0.52,0.31,0.81,0.44l-0.08,0.03
				c0.04-0.01,0.37,0.15,1.07,0.52l-0.14-0.03c0.31,0.19,0.97,0.63,2.04,1.49c-0.32-0.25-0.57-0.45-0.54-0.43
				c-0.04,0.05,0.21,0.26-0.05,0.17c0.22,0.15,0.02-0.05,0.89,0.58l-0.03-0.02c0.34,0.27,0.16,0.19,0.73,0.67
				c0.04-0.06,0.19,0.09,0.08-0.09c0.03,0.04-0.06-0.05-0.17-0.12l0.01,0c-0.08-0.07-0.19-0.16-0.31-0.26
				c0.93,0.6,0.56,0.58,2.23,2.08c-0.07-0.05-0.13-0.12-0.18-0.14c0,0.02,0.61,0.42,0.82,0.85l-0.06-0.03
				c0.51,0.81,1.18,1.05,1.66,2.02c-0.03-0.07-0.05-0.1-0.05-0.12c0.12,0.5,1.8,2.33,2.99,5.41l0.01-0.09
				c0.09,0.21,0.29,0.66,0.23,0.65c0.21,0.12,0.43,2.03,0.63,1.51c0.05,0.29,0.42,0.25,0.12-0.67l0.1,0.3
				c-0.02-0.46-0.65-1.23-0.45-1.31c-0.11-0.09,0-0.38-0.73-1.54c0.26,0.23-0.27-0.58-0.11-0.57c-0.21-0.16-0.42-0.73-0.68-1.06
				c0.02,0.02,0.06,0.05,0.07,0.08c0.21-0.09-0.86-0.89-0.39-0.88c-0.06,0.05-0.33-0.38-0.33-0.26c-0.06-0.22-0.07-0.17-0.06-0.31
				c-0.01,0.2-0.74-0.95-0.31-0.6c-0.67-0.51,0.04-0.09-1.56-1.87c-0.1,0.12-0.06,0.14-0.29-0.16c0.14-0.44,0.27-0.01-1.88-2.23
				l0.16,0.02c-1.16-0.65,0.29-0.53-5.88-4.41l0.1,0.03c-0.32-0.22-0.46-0.09-0.74-0.33l0.07,0.02c-0.27-0.2-2.31-1.31-3.61-3
				l0.06-0.05c-0.11-0.07-0.14-0.11-0.33-0.55c-0.03,0.14-0.05-0.19-0.44-1.26c0.03,0.01,0.06,0.13,0.1,0.22
				c-0.14-0.67-0.09-0.12-0.05-1.3l0.04,0.06c-0.63-2.89,1.77,1.17,5.23-2.26l0.02,0.03c0.02-0.44-1.86-1.79-0.36-3.81l-0.05-0.01
				c0.79-0.77-0.08-0.27,3.29,2.27l-0.14-0.09c0.24,0.22-0.14,0.15,1.43,1.58c-0.12-0.36-0.04-0.03-0.09-0.93
				c0.03,0.05,0.06,0.05,0.08,0.15c0.01-0.23-0.21-0.76-0.25-0.75c0.09-0.08-0.4-0.87-0.29-2.68c0.09,0.05,0.28-0.51,0.39-0.69
				c-0.02,0.07,0.14-0.11,0.12-0.02c0.14-0.16,0.29-0.28,0.23-0.31c1.19-0.06,0.81-0.38,2.97,0.68c-0.6,0.09-0.01-0.49,1.73,2
				c0.01-0.02-0.02-0.09,0.42,0.34c-0.22-0.11,0.25,0.54-0.06,0.34c0.04,0.06,0.11,0.14,0.13,0.11c0.04,0.11,0.04,0.15-0.05,0.06
				c0.22,0.36,0.2,0.2,0.35,0.55c-0.02,0.01-0.06-0.06-0.11-0.08c0.12,0.15,0.25,0.31,0.35,0.62c-0.02-0.01-0.03,0.03-0.06-0.06
				c0.22,0.51,0.46,0.84,0.61,1.43c-0.08-0.07-0.17-0.08,0.07,0.57l0-0.14c0.08,0.28,0.22,0.5,0.25,0.75l-0.09-0.2l0.16,0.52
				l-0.04-0.31c0.12,0.27,0.11-0.06,0.18,0.32c-0.03-0.07,0.07,0.28,0.06,0.44l0,0c0.23,0.56-0.11,0.22,0.35,1.31
				c-0.16-0.11,0,0.44,0.12,0.55c-0.01-0.04-0.02-0.02-0.05-0.09c0.12,0.5,0.15,0.71,0.17,0.88l-0.04-0.12
				c0.12,0.64-0.02-0.04,0.51,2.25l-0.01-0.11c0.26,0.31-0.21,0.09,0.4,2.16l-0.05-0.07c0.23,1.17,0.1,0.91,0.46,3.35
				c-0.06-0.19-0.07-0.29,0.05,0.48c0.05-0.06,0.03-0.09,0.06-0.22c0.04,0.07,0.09,0.25,0.11,0.4c-0.07-0.28-0.09,0.01-0.11,0.14
				c0.02-0.02,0.04,0.02,0.06,0.01c-0.07,0.34,0.13,0.04,0.1,0.49c-0.06-0.03-0.12-0.1,0.08,1.01c-0.02-0.12-0.05-0.25-0.07-0.37
				c-0.05,0.31-0.01,0.09-0.03,0.88l0.01-0.11c0.2,0.3,0.08,0.64,0.22,1.06c-0.14-0.41-0.07-0.06-0.15-0.09
				c-0.01,0.11-0.03,0.28-0.05,0.23c0.02,0.14,0.11,0.15,0.02,0.79c0.06-0.97,0.22,1.92,0.24,3.55c-0.09-0.15-0.17-0.25,0,1.03
				l0.03-0.17c-0.06,0-0.14-0.08-0.02-0.36l0.02,0.17c0.14-0.17-0.02-0.67-0.01-1c0.1,0.09,0.14,0.08,0.11-0.81
				c0.02,0.05,0.07-0.04,0.06,0.1c0.05-0.42-0.09-0.68-0.02-0.84l0.01,0.04c-0.09-1.2-0.11-1.28-0.18-3.42
				c0.11,0.02-0.05,0.11-0.08-0.73c0.03,0.02-0.01-0.71-0.01-1.06l0.02,0.01c-0.1-0.06-0.12-0.42-0.17-0.68
				c0.06,0,0.08,0.05,0.12,0.19c-0.02-0.03,0.04-0.02-0.12-0.53c0.2,0.16,0.05-0.13-0.25-1.24l0.08,0.17c0-0.18-0.12-0.35,0.01-0.16
				c-0.06-0.82-0.02-0.36-0.3-1.9l0,0c-0.23-0.57,0-0.79-0.6-3.47c0.09,0.24,0.02-0.09,0.08,0.25c-0.05-0.33-0.02-0.41-0.16-0.74
				c0.09,0.1,0.03-0.26-0.03-0.41l0.01,0.23c-0.05-0.17-0.08-0.32-0.11-0.47l0.1,0.06c-0.11-0.67-0.32-1.48-0.81-2.98
				c-0.12,0.34-0.01-0.83-0.13-0.62c-0.07-0.4-0.17-0.6-0.27-1c-0.04,0.02-0.59-1.01-0.24-0.87c-0.18-0.33-0.22-0.67-0.29-0.88
				l0.1,0.18c-0.06-0.11-0.1-0.2-0.13-0.29l-0.01,0.13c-0.03-0.11-0.08-0.2-0.05-0.21c-0.23-0.18-0.17,0.05-0.38-0.57
				c0.26,0.6,0.41,0.12-0.25-0.53c0.07,0.03,0.02-0.14-0.03-0.2c0.07,0.14-0.12,0-0.42-0.44l0.09,0.17
				c-0.3-0.16-0.36-0.79-1.28-1.77c0.04,0.13,0.07,0.12-0.18-0.02c0.18,0.13,0.34,0.29,0.51,0.43l-0.36-0.24
				c0.14,0.14,0.33,0.26,0.4,0.36c-0.17-0.12-0.15-0.11-0.12-0.01c-0.67-0.62-0.14-0.69-1.76-1.46l0.16,0.12
				c-0.79-0.24-0.52-0.51-2.54-0.22c0.11-0.12-0.13-0.05,0.19-0.23c-0.97,0.98-0.12-0.08-1.09,3.81c-0.01-0.01-0.02-0.01-0.02-0.01
				c0.26,0.81-0.04-0.21,0.23,0.98c-0.03-0.05-0.06-0.09-0.1-0.15c0.02,0,0.04,0,0.05,0c-0.07-0.03-0.2-0.16-0.25-0.57l0.17,0.29
				c-0.01-0.19-0.22-0.37-0.29-0.53l0.04,0c-0.28-0.43-0.36-0.38-0.66-0.84l0.01,0.06c-0.53-0.57,0.04-0.39-1.05-1.32
				c0.21,0.08,0.5,0.4,0.68,0.58c-0.11-0.16-0.42-0.54-0.53-0.58c0.06,0.05,0.15,0.12,0.19,0.19c-1.58-0.81-0.39-1.07-3.34,1.47
				c0.14-0.31,0.01,0.25,0.17,0.19c0.02,0.11,0-0.01,0.03,0.28c0,0,0,0,0-0.01c0.02,0.29-0.02,0.45-0.01,0.94
				c0.03-0.07,0.12,0.11,0.21,0.34l-0.07-0.34c0.3,0.51,0.09,0.53,0.43,1.01l-0.13-0.14c0.09,0.24,0.12,0.07,0.44,0.65
				c-0.31-0.25-0.62-0.39-1-0.58c0.07-0.1-0.45-0.4-0.75-0.47l0.01-0.01C540.98,139.22,537.24,139.99,540.09,145.46z M548.44,151.42
				c-0.1-0.02-0.07,0-0.16-0.12C548.32,151.33,548.27,151.32,548.44,151.42z M548.13,150.55c0.05,0.04,0.1,0.08,0.13,0.11
				C548.23,150.64,548.19,150.61,548.13,150.55L548.13,150.55z M547.73,150.31c0.04,0.01-0.08-0.1-0.15-0.18
				C547.51,150.11,547.91,150.32,547.73,150.31z"
                />
                <path
                  className="st10"
                  d="M556.81,157.55l0.04-0.04c0.01,0.06,0.09,0.09,0.07,0.14l-0.05-0.03l0.04,0.1l0.02-0.07
				c0.09,0.09,0.15-0.06,0.01,0.26c0.08,0.01,0.05,0.12,0.14,0.14c-0.02-0.01-0.07,0-0.08,0.01l0.07,0.01
				c-0.04,0.07,0.02,0.06,0,0.26l-0.02-0.03l-0.03,0.04l0.05,0c-0.02,0.03,0.08,0.27,0.21,0.78c-0.03-0.07-0.13,0.16,0.04,0.19
				c-0.05,0.14-0.21,0.14,0.18,0.56c-0.05-0.05-0.09,0.01-0.12,0.04c0.02-0.01,0.04,0,0.05-0.01c-0.14,0.06,0.09,0.03,0.03,0.09
				c-0.01-0.01,0.12,0.16-0.03,0.07c0.05,0.14,0-0.01-0.05,0.25c0.21-0.03,0.01-0.01,0.19,0.16c-0.11-0.05-0.04-0.03-0.2,0.05
				c0.07,0.03-0.04,0.07,0.06,0.11c-0.02,0-0.02,0.02-0.03,0.03c0.04-0.01,0.08,0.13,0.19,0.6c-0.15-0.02,0.1,0.14-0.11,0.06
				c0.03,0.04,0.08,0.08,0.1,0.12c-0.04,0.02-0.07-0.02-0.1-0.04c0.08,0.04,0.12,0.19,0.1-0.13l0.11,0.02l-0.09-0.06
				c0.03-0.02,0.08-0.03,0.1,0c0.04-0.04,0.06-0.02,0-0.13c0.02,0.01,0.07-0.01,0.06,0.02c0.02-0.07,0.07-0.21-0.18-0.24l0.18-0.08
				l-0.08,0c0-0.01,0.01-0.04,0.04-0.03c-0.06-0.04-0.03,0.01-0.08-0.01c0.07-0.06-0.05-0.15,0.07-0.18c-0.06-0.01-0.04,0.06-0.09,0
				c0.16,0.01-0.1-0.14,0.11-0.12c-0.16-0.05,0.12-0.14-0.11-0.3c0.04,0.01-0.03-0.01,0.07-0.22c-0.09,0-0.08-0.07-0.11-0.12
				c0.05-0.01,0.08,0,0.11,0.02c-0.01-0.01,0.02-0.03-0.07-0.1l0.07,0c-0.01-0.06-0.1-0.12-0.06-0.21
				c-0.16-0.02-0.25,0.09,0.01-0.14c-0.09,0.01,0.09-0.11-0.15-0.27v0c-0.11-0.06,0.05-0.3-0.09-0.68c0.05,0.04,0.03-0.02,0.04,0.04
				c0.01-0.07,0.04-0.09-0.04-0.14c0.07,0.01,0.07-0.06,0.04-0.09l-0.03,0.05c0.16-0.16-0.3-0.52-0.09-0.72l-0.03,0
				c0.02-0.04,0-0.12,0.05-0.11c-0.04-0.04-0.05-0.01-0.06,0.03c0.02-0.09-0.02-0.12-0.02-0.2c-0.03,0.04-0.29-0.2,0.01-0.18
				c-0.07-0.06,0-0.14,0-0.19l0.03,0.03c-0.02-0.02-0.02-0.04-0.03-0.06l-0.05,0.04c0-0.02,0-0.04,0.02-0.05
				c-0.04-0.01-0.09,0.04-0.15,0.01c0.02-0.04-0.06-0.08,0.02-0.11c0.01,0.13,0.29-0.06,0-0.12c0.05,0,0.08-0.04,0.06-0.06
				c0.02,0.02-0.08,0.06-0.15-0.08c-0.02,0.04-0.01,0.07-0.08-0.03c0.03-0.01,0.03,0.03,0.04,0.01c-0.17-0.04,0.26-0.36-0.07-0.38
				l0.08,0.11l-0.11-0.05c0.01,0.04,0.06,0.07,0.06,0.09c-0.06-0.02-0.04-0.02-0.08,0.01c-0.12-0.24,0.26-0.05-0.02-0.45
				c-0.03,0.04-0.03,0.08-0.08-0.05c0.05-0.01,0.06-0.04,0.08-0.06c-0.03,0.01-0.02-0.08-0.12-0.06c0.04-0.11,0.1-0.09-0.13-0.22
				c0.06,0.01,0.08-0.04,0.13,0.01c-0.07-0.07,0.02,0.02-0.24-0.18c0.08-0.1,0.07-0.14-0.11-0.54c0.02,0,0.02-0.01,0.02,0
				c-0.13-0.18,0.02,0-0.05-0.3l0.01,0.01c0.03-0.07-0.1-0.09-0.11-0.17c0,0,0.01,0,0.02-0.01c-0.09-0.02-0.16-0.11-0.21-0.17
				c0.14-0.03,0.1,0,0.06-0.15c0.36,0.12-0.4-0.23-0.14-0.34c-0.04-0.03-0.14-0.09-0.18-0.14c0.08,0.01,0.15,0.07,0.21,0.1
				c-0.01-0.04-0.06-0.12-0.11-0.12c0.02,0.05,0.03,0.06-0.14-0.09c0.18-0.06-0.03-0.2,0.08-0.31c-0.12-0.09-0.08-0.25-0.16-0.39
				c-0.01,0.07-0.24-0.02-0.07-0.05c-0.03,0-0.06-0.02-0.08,0c0.01-0.06,0.05-0.14,0.14-0.23c-0.04,0.03-0.08,0.01-0.08-0.04
				l-0.04,0.09c-0.12-0.07,0.08-0.14-0.04-0.21l0.06,0.01c-0.05-0.04-0.09-0.19-0.23-0.19c0.01-0.02,0.04-0.05,0.06-0.03
				c0.02-0.1-0.09-0.15-0.32-0.5l0.05,0.01c-0.31-0.49-0.09-0.14-0.62-1.19c-0.15-0.03-0.04-0.08-0.51-0.66
				c0.07-0.09,0.08-0.02-0.07-0.26l0.06-0.01c-0.23-0.07-0.11,0.04-0.37-0.37c0.01-0.01,0.04-0.02,0.06-0.01
				c-0.03-0.05-0.06-0.21-0.17-0.12c0.08-0.05-0.06-0.1-0.13-0.13l0.06-0.03c-0.02,0.01-0.08-0.01-0.24-0.12l0.05,0
				c-0.05-0.08-0.18,0.06-0.11-0.18c-0.02,0,0.01,0-0.17-0.24c0.05,0.06,0.08,0.12,0.07,0.12c0.13-0.01-0.01-0.33-0.25-0.33
				c0-0.01,0.01,0.01,0.04,0.02l-0.01,0c0.01,0.02,0.03,0.04,0.05,0.06c-0.04,0-0.08,0-0.07-0.05c-0.05,0.01-0.09,0.03-0.12,0.03
				c-0.08-0.05-0.08-0.42-0.65-0.97c0,0.02,0,0.03-0.01,0.04c-0.1-0.13,0.03-0.21-0.56-0.63l0.05-0.06c-0.41-0.1-0.26-0.81-0.8-0.2
				l-0.04-0.04c-0.02,0.06,0.03,0.05,0.07,0.04c-0.02,0.02-0.03,0.05-0.03,0.06l0.13-0.02c-0.18,0.18,0.14-0.02-0.01,0.13
				c0.05,0,0.07,0.06,0.13,0.03c-0.02,0.07,0.09,0.08,0.01,0.15c0.03-0.01,0.11-0.01,0.13,0.01c-0.14,0.04,0.08,0.1-0.04,0.18
				c0.12-0.05,0.15,0.08,0.25,0.1c-0.01,0-0.03,0.01-0.03,0c0.02,0.04,0,0.07,0.01,0.11c0.08-0.06,0.14-0.02,0.21-0.03
				c-0.04,0.1-0.11,0.05-0.17,0.12c0.06-0.05,0.12,0.02,0.16-0.04c-0.02,0.07,0,0.05-0.05,0.11c0.1-0.07,0.04,0.02,0.12-0.01
				c-0.04,0.05,0.07,0.11-0.09,0.14c0.16-0.1,0.08,0.25,0.56,0.22c-0.01-0.02-0.03,0-0.05,0l0.04,0.01c-0.53,0.28,0,0,0.57,0.89
				c-0.15-0.24,0.08,0.35,0.48,0.76l-0.03,0c0.02,0.08,0.17-0.01,0.16,0.08l-0.02,0c0.04,0.12-0.1,0.13,0.53,0.84l-0.06,0.08
				c0.17,0-0.12,0.03,0.21,0.24c-0.03,0.01-0.05,0-0.08-0.01c0.15-0.01-0.08,0.09,0.1,0.05c-0.02,0.06,0.02,0.13,0.07,0.17
				l-0.05,0.02c0.04,0.1,0.14,0.19,0.2,0.3c0-0.05,0.08-0.09,0.11-0.06l-0.06,0.05c0.05-0.01,0.17-0.06-0.02,0.03l0.05,0.04
				c-0.02,0.01-0.04,0.01-0.07,0.02c0.06,0-0.02,0.13,0.07,0.11c0.01,0.1,0.25,0.33,0.44,0.5c-0.05,0.03-0.01,0.11-0.07,0.09
				c0.06,0.09,0.07,0.12,0.18,0.22c0.02-0.05,0.09-0.06,0.01,0.02c0.16,0.09,0,0.4,0.17,0.45l-0.01,0c0.16,0.14,0.06,0.11,0.31,0.84
				c0.03-0.01,0.09-0.06,0.03,0.04c0.16-0.33,0.09,0.53,0.64,1.37c-0.02,0-0.05,0-0.05-0.01c-0.04,0.06,0.05,0.14,0.09,0.12
				c0-0.07,0.01,0.27,0.23,0.51c-0.11,0.03-0.04,0.15,0.02,0.17c-0.04,0-0.02,0.05-0.07,0.05c0.02,0.05,0.05,0.09,0.1,0.06
				c-0.1,0.13,0.16,0.15,0.01,0.25c0.13,0.21,0.13,0.05,0.37,0.89c-0.01,0-0.02-0.01-0.04-0.02c0.01,0.02,0,0.06,0.05,0.04
				l-0.02-0.02c0.06-0.06,0.17,0.05,0.21,0.04c-0.15,0-0.01,0.12-0.2,0.11c0.01,0.01,0.04,0.02,0.07,0.01
				C556.63,157.13,556.66,156.9,556.81,157.55z M552.69,148.53c0.01,0-0.08-0.06,0.01-0.06
				C552.68,148.47,552.69,148.51,552.69,148.53z M552.87,148.03c0.01-0.01,0.02-0.01,0.03-0.01
				C552.93,148.04,552.92,148.06,552.87,148.03z"
                />
                <path
                  className="st10"
                  d="M526.84,161.53c2.83,1.15,0.07-0.05,3.09,4.94c0.17,0.01-0.25-0.03,0.57-0.12
				c-0.21,0.13,0.57,0.08,0.25,0.24c0.08,0,0.17-0.02,0.16-0.06c0.11,0.02,0.15,0.04,0.03,0.07c0.41-0.01,0.27-0.07,0.64-0.06
				c0,0.02-0.08,0.03-0.12,0.06c0.19-0.04,0.38-0.08,0.69-0.05c-0.02,0.01,0.02,0.04-0.08,0.03c0.54,0.01,0.91-0.1,1.5-0.05
				c-0.09,0.05-0.13,0.13,0.54,0.11l-0.13-0.05c0.28,0.01,0.52-0.07,0.76-0.03l-0.21,0.04l0.52-0.02l-0.29-0.03
				c0.28-0.05-0.03-0.12,0.34-0.09c-0.07,0.01,0.28,0,0.42,0.05l0,0c0.58-0.09,0.17,0.17,1.29-0.05c-0.14,0.13,0.41,0.1,0.53,0
				c-0.04,0.01-0.02,0.02-0.09,0.03c0.48-0.03,0.69-0.02,0.85,0l-0.12,0.01c0.62,0.01-0.04,0.02,2.19-0.16l-0.11,0
				c0.65-0.16,0.49,0.18,2.08-0.19l-0.07,0.04c1.12-0.16,0.88-0.02,3.2-0.36c-0.18,0.06-0.27,0.08,0.46-0.07
				c-0.06-0.05-0.08-0.02-0.21-0.05c0.07-0.04,0.23-0.09,0.38-0.12c-0.26,0.08,0.02,0.09,0.14,0.11c-0.02-0.01,0.01-0.04,0-0.06
				c0.32,0.06,0.04-0.12,0.47-0.12c-0.03,0.06-0.09,0.13,0.96-0.15l-0.34,0.09c0.25,0.04,0.27,0.02,0.45,0l-0.02,0l0.41-0.03
				l-0.11,0c0.27-0.23,0.6-0.14,0.98-0.33c-0.37,0.18-0.05,0.07-0.07,0.15c0.11,0,0.27,0,0.23,0.03c0.13-0.04,0.13-0.13,0.75-0.12
				c-0.92,0.08,1.79-0.48,3.28-0.88c-0.12,0.12-0.19,0.23,0.95-0.24l-0.16,0.02c0.02,0.05-0.04,0.16-0.33,0.1l0.15-0.05
				c-0.19-0.1-0.61,0.17-0.93,0.24c0.05-0.11,0.04-0.15-0.78,0.05c0.04-0.03-0.05-0.06,0.08-0.08c-0.4,0.03-0.61,0.22-0.78,0.17
				l0.03-0.01c-1.11,0.27-1.16,0.36-3.19,0.63c0.01-0.11,0.11,0.03-0.69,0.15c0.02-0.03-0.67,0.06-1,0.08l0.01-0.02
				c-0.05,0.1-0.39,0.13-0.64,0.19c0-0.06,0.04-0.08,0.17-0.13c-0.03,0.02-0.02-0.04-0.5,0.13c0.24-0.28-0.17,0.08-1.18,0.25
				l0.16-0.08c-0.17,0-0.33,0.12-0.15-0.01c-0.78,0.04-0.33,0.02-1.81,0.23l0,0c-0.56,0.17-0.73-0.01-3.33,0.23
				c0.24-0.06-0.09-0.03,0.24-0.05c-0.31,0-0.38-0.04-0.71,0.05c0.11-0.07-0.24-0.07-0.38-0.04l0.22,0.04
				c-0.17,0.02-0.31,0.02-0.46,0.02l0.08-0.09c-0.65-0.02-1.42,0.06-2.92,0.15c0.28,0.21-0.76-0.21-0.6-0.03
				c-0.38-0.04-0.59,0-0.97-0.04c0.01,0.05-1.09,0.21-0.84-0.07c-0.35,0.05-0.66-0.06-0.86-0.08l0.19-0.02
				c-0.11,0.01-0.2,0.01-0.29,0l0.1,0.06c-0.11-0.01-0.2-0.02-0.2-0.04c-0.24,0.12-0.02,0.19-0.63,0.08
				c0.44,0.04-0.01-0.07,0.32-0.08c-0.43-0.06-0.25-0.1-0.85,0.01c0.06-0.04-0.09-0.09-0.16-0.09c0.13,0.02-0.07,0.13-0.53,0.04
				l0.16,0.05c-0.05-0.12-1.94-0.39-1.28-2.28l-0.12,0.12c0.42-0.25-0.22-0.56,2.54-1.19c-0.75-0.07,3.55-0.34,0.58-0.98
				c0.04-0.15-0.74-0.08-0.93,0.09c0.07-0.06-0.21,0.04-0.14-0.04c-0.27,0.1-0.48,0.24-0.41,0.27c-0.12,0.04-0.15,0.25-1.08,0.4
				l0.07,0c-0.27-0.24-0.25,0.36-1.81-0.66c0.03-0.01,0.05-0.02,0.07-0.02c-0.07,0-0.24-0.05-0.29-0.47
				c0.05,0.08,0.1,0.16,0.16,0.24c-0.02-0.17-0.2-0.31-0.25-0.45l0.04,0c-0.14-0.38-0.26-0.39-0.2-0.86l-0.04,0.04
				c0.13-0.56,0.14-0.32,0.56-0.87c-0.07,0.1-0.14,0.2-0.18,0.29c0.09-0.14,0.38-0.42,0.43-0.52c-0.05,0.05-0.1,0.13-0.16,0.17
				c1.11-0.97-0.04-0.16,3.57-1.87c-0.3-0.05,0.2-0.08,0.11-0.2c0.45-0.12,0.12-0.11,1.07-0.02c-0.06-0.04,0.11-0.1,0.32-0.13
				l-0.31-0.01c0.5-0.19,0.47,0.03,0.97-0.13l-0.16,0.07c0-1.29,0.91-0.38-2.83-0.93l0.06-0.02c-0.2-0.03-0.08-0.02-0.82-0.21
				l0.14,0c-2.07-0.96-1.04-2.02,5.32-3.82l-0.01,0.07c0.45,0.04,0.67,0.1,1.23,0.15l-0.12,0.04c1.06-0.03,0.49-0.05,2.42,0.31
				c-0.01,0.01-0.03,0.04-0.12,0.02c0.25,0.07,0.96,0.31,0.9,0.15c-0.02,0.1,0.56,0.13,0.85,0.19l-0.06,0.05
				c0.03-0.02,0.38,0.05,1.12,0.21l-0.13,0.01c0.54,0.19,0.46,0.11,2.24,0.86c-0.35-0.15-0.57-0.25-0.6-0.26
				c-0.02,0.06,0.26,0.18,0,0.17c0.24,0.07,0.01-0.05,0.96,0.3l-0.03-0.01c0.38,0.15,0.2,0.11,0.84,0.42
				c0.02-0.07,0.19,0.03,0.05-0.11c0.04,0.02-0.06-0.03-0.18-0.07l0.01,0c-0.09-0.05-0.21-0.09-0.35-0.15c1,0.3,0.66,0.39,2.59,1.31
				c-0.07-0.03-0.15-0.07-0.2-0.08c0,0.04,0.69,0.19,0.99,0.55l-0.07-0.01c0.69,0.61,1.37,0.61,2.07,1.37
				c-0.05-0.05-0.07-0.08-0.08-0.1c0.26,0.41,2.3,1.59,4.29,4.02l-0.01-0.09c0.14,0.17,0.47,0.5,0.41,0.51
				c0.23,0.04,0.99,1.71,1.03,1.16c0.14,0.25,0.47,0.08-0.09-0.65l0.19,0.24c-0.12-0.36-0.97-0.92-0.8-1.05
				c-0.13-0.05-0.1-0.36-1.13-1.16c0.3,0.12-0.42-0.44-0.26-0.49c-0.24-0.07-0.59-0.54-0.93-0.75c0.02,0.01,0.07,0.02,0.09,0.05
				c0.16-0.14-1.05-0.55-0.6-0.68c-0.04,0.07-0.4-0.26-0.37-0.15c-0.12-0.17-0.12-0.13-0.15-0.26c0.04,0.2-0.95-0.64-0.45-0.45
				c-0.75-0.27,0.01-0.09-1.95-1.25c-0.06,0.14-0.02,0.15-0.31-0.05c0-0.5,0.2-0.09-2.32-1.52l0.15-0.02
				c-1.19-0.3-1.12-1.01-6.51-2.53l0.1,0c-0.36-0.12-0.45,0.04-0.77-0.11l0.07,0c-0.27-0.16-2.77-0.57-4.58-0.78
				c0.01,0.03-0.23,0.03-0.65-0.04c0.17,0.24-0.08-0.19-1.38,0.03c0.02-0.03,0.14-0.05,0.24-0.07c-0.73,0.11-0.13-0.01-1.41,0.19
				l0.05-0.06c-0.57,0.1-1.19,0.35-1.75,0.73c0.21-0.13,0.05,0-0.34,0.22c-0.04-0.88-1.12,3.37,2.09,4.34
				c-0.44,0.15-1.04,0.36-1.9,0.68l0.05,0.02c-0.12,0.11-0.18,0.06-0.28,0.11c0.36,0.31-4.02,1.38-2.29,3.95
				C526.86,161.54,526.85,161.54,526.84,161.53z M528.75,165.47c-0.04-0.1-0.07-0.17-0.11-0.24c0.08,0.1,0.17,0.18,0.22,0.24
				C528.71,165.37,528.73,165.37,528.75,165.47z M528.84,165.35c-0.04-0.07-0.18-0.02-0.31-0.41
				C528.61,165.09,528.71,165.23,528.84,165.35z M531.24,162.03c0.11,0.01,0.24,0.02,0.37,0.03c-0.14,0.04-0.35,0.1-0.69,0.2
				c0.01,0,0.02-0.01,0.04-0.01c-0.05,0.01-0.02,0-0.11,0.03C530.89,162.23,531.04,162.25,531.24,162.03z M530.83,162.29
				c0.01-0.01,0.01-0.01,0.04-0.01C530.86,162.28,530.84,162.29,530.83,162.29z M544.31,154.56c-0.09,0.01-0.07,0.02-0.18-0.06
				C544.17,154.51,544.12,154.52,544.31,154.56z M543.94,153.9c-0.03-0.01-0.07-0.03-0.15-0.06
				C543.86,153.87,543.79,153.84,543.94,153.9z M543.37,153.72c0.04,0-0.1-0.08-0.18-0.13
				C543.12,153.58,543.54,153.68,543.37,153.72z M532.93,156.94C532.93,156.94,532.93,156.94,532.93,156.94
				c0.01-0.01,0.02,0,0.03-0.01L532.93,156.94z M527.64,159.2c0.05-0.06,0.09-0.12,0.14-0.2
				C527.75,159.06,527.7,159.13,527.64,159.2z"
                />
                <path
                  className="st10"
                  d="M553.86,163.1c0-0.02,0-0.16-0.19-0.02l0.02-0.1c-0.06,0.15-0.05,0-0.17-0.02
				c0.01-0.02-0.01-0.07,0.02-0.06c-0.1-0.03-0.19-0.06-0.23,0.16l-0.05-0.19l-0.01,0.08c-0.01,0-0.03-0.01-0.03-0.04
				c-0.04,0.05,0.01,0.03-0.02,0.08c-0.05-0.07-0.14,0.03-0.15-0.09c-0.02,0.06,0.05,0.05-0.01,0.08c0.03-0.15-0.14,0.07-0.09-0.13
				c-0.12,0.11-0.13-0.12-0.28,0.05c0.02-0.04-0.02,0.02-0.18-0.11c-0.02,0.09-0.08,0.07-0.13,0.08c0.01-0.05,0.02-0.08,0.04-0.1
				c-0.03,0-0.04-0.01-0.1,0.05l0.02-0.07c-0.06,0-0.12,0.07-0.19,0.02c-0.01,0.06,0.04,0.18-0.04,0.22c0-0.03,0-0.09,0.02-0.11
				c0,0.01-0.11-0.05-0.03-0.08c-0.03-0.01-0.06-0.04-0.07-0.08c-0.01,0.03-0.06,0.14-0.07-0.02c-0.02,0.03-0.05-0.01-0.06,0.07
				c-0.02,0-0.02-0.04-0.02-0.06c-0.03,0.04,0,0-0.11,0.08l0,0c-0.09,0.09-0.24-0.13-0.6-0.11c0.05-0.04-0.01-0.04,0.05-0.02
				c-0.05-0.03-0.06-0.07-0.13-0.01c0.03-0.06-0.03-0.08-0.06-0.07l0.03,0.05c-0.14-0.15-0.54,0.07-0.62-0.16l-0.01,0.03
				c-0.02-0.03-0.09-0.04-0.07-0.09c-0.04,0.02-0.03,0.05,0,0.07c-0.06-0.05-0.11-0.02-0.17-0.06c0.02,0.05-0.27,0.17-0.14-0.08
				c-0.07,0.04-0.11-0.06-0.15-0.08l0.04-0.02c-0.02,0.01-0.04,0.01-0.06,0l0.01,0.06c-0.02-0.01-0.03-0.02-0.03-0.04
				c-0.03,0.03,0,0.1-0.05,0.15c-0.02-0.03-0.09,0.02-0.08-0.06c0.1,0.05,0.07-0.29-0.09-0.04c0.02-0.04,0-0.09-0.02-0.08
				c0.02-0.01,0.02,0.09-0.12,0.11c0.02,0.03,0.05,0.04-0.05,0.06c0-0.03,0.04-0.02,0.03-0.03c-0.05,0.07-0.08-0.1-0.14-0.06
				c0-0.02-0.06-0.25-0.18-0.05l0.12-0.02l-0.09,0.07c0.03,0.01,0.08-0.02,0.1-0.01c-0.04,0.04-0.04,0.03-0.03,0.08
				c-0.25,0,0.09-0.25-0.36-0.19c0.02,0.04,0.05,0.07-0.07,0.04c0.01-0.05-0.01-0.07-0.01-0.1c0,0.04-0.07-0.02-0.1,0.08
				c-0.07-0.08-0.02-0.13-0.23,0.02c0.03-0.05,0-0.09,0.06-0.12c-0.09,0.03,0.02-0.01-0.25,0.13c-0.05-0.12-0.07-0.13-0.47-0.17
				c0.01-0.01,0-0.02,0.01-0.02c-0.18,0.1-0.03-0.21-0.42-0.09c0,0,0.01-0.01,0-0.02c-0.06,0.07-0.16,0.08-0.23,0.09
				c0.04-0.13,0.05-0.09-0.08-0.13c0.27-0.25-0.39,0.23-0.32-0.06c-0.04,0.02-0.13,0.07-0.2,0.08c0.05-0.07,0.12-0.09,0.18-0.12
				c-0.03-0.01-0.11-0.01-0.14,0.03c0.05,0.01,0.06,0.01-0.13,0.07c0.05-0.19-0.16-0.08-0.19-0.23c-0.13,0.05-0.23-0.07-0.37-0.09
				c0.05,0.05-0.14,0.19-0.07,0.03c-0.01,0.02-0.04,0.04-0.04,0.06c-0.04-0.04-0.08-0.12-0.08-0.24c0,0.06-0.04,0.07-0.07,0.04
				c0.01,0.08,0.04,0.16-0.13,0l0.04-0.04c-0.06,0.02-0.19-0.04-0.26,0.08c-0.01-0.02-0.01-0.06,0.01-0.07
				c-0.51-0.18-0.37,0.09-1.65-0.33c-0.11,0.1-0.08-0.02-0.74-0.04c-0.01-0.1,0.04-0.08-0.21-0.12l0.03-0.05
				c-0.19,0.12-0.03,0.13-0.47,0.02c0-0.01,0.01-0.04,0.03-0.05c-0.05-0.01-0.17-0.1-0.18,0.04c0.02-0.09-0.1-0.03-0.17,0
				c0.06-0.17-0.02,0.03-0.61-0.11c0.07,0.01,0.12,0.03,0.12,0.03c0.08-0.08-0.22-0.23-0.36-0.06c-0.01-0.01,0.01,0,0.04-0.01
				l0,0.01c0.02,0,0.04,0.01,0.07,0.01c-0.03,0.03-0.06,0.06-0.08,0.01c-0.02,0.04-0.05,0.08-0.06,0.1
				c-0.08,0.01-0.3-0.22-1.01-0.28c0.01,0.01,0.02,0.02,0.02,0.04c-0.14-0.04-0.11-0.17-0.74-0.11l0-0.08
				c-0.12,0.21-0.33-0.22-0.77-0.01c0,0,0,0,0-0.01c-0.06,0.09,0.01,0.01,0.08,0.36l-0.05-0.01c0.02,0.06,0.05,0.02,0.08-0.01
				c0,0.03,0,0.05,0.02,0.07l0.08-0.1c0,0.05-0.04,0.08-0.01,0.12c0.04-0.05-0.01-0.11,0.05-0.13c0.02,0.06,0,0.1,0.05,0.06
				c0,0.03-0.01,0.05-0.03,0.06c0.04-0.03,0.09,0,0.11-0.06c0.02,0.07,0.11,0,0.09,0.11c0.01-0.02,0.07-0.07,0.1-0.07
				c-0.08,0.12,0.11,0.03,0.07,0.16c0.06-0.11,0.15-0.03,0.23-0.08c-0.01,0.01-0.02,0.03-0.02,0.02c0.04,0.02,0.04,0.05,0.06,0.08
				c0.02-0.1,0.09-0.11,0.13-0.16c0.03,0.1-0.05,0.11-0.05,0.2c0.01-0.08,0.1-0.06,0.09-0.13c0.03,0.06,0.03,0.03,0.03,0.12
				c0.03-0.12,0.04-0.01,0.08-0.08c-0.03,0.07,0.22,0.15,0.54-0.04c-0.02,0-0.02,0.02-0.03,0.04l0.03-0.02
				c-0.15,0.1,0.08,0.16,0,0.09c-0.22,0.3,0.41,0.14,0.92,0.17c-0.35-0.02,0.01,0.16,1.79,0.34l0.01,0.1c0.11-0.13-0.05,0.11,0.29,0
				c-0.01,0.03-0.03,0.04-0.05,0.05c0.08-0.11,0.02,0.12,0.09-0.04c0.02,0.05,0.1,0.07,0.16,0.06l-0.02,0.05
				c0.1,0.03,0.21,0.01,0.33,0.04c-0.04-0.03-0.01-0.12,0.02-0.12l0,0.08c0.03-0.05,0.06-0.17,0.01,0.03l0.06-0.02
				c0,0.02-0.02,0.03-0.02,0.06c0.03-0.05,0.07,0.1,0.12,0.02c0.06,0.05,0.4,0.03,0.61-0.04c-0.01,0.06,0.07,0.07,0.02,0.11
				c0.1,0.01,0.13,0.01,0.26-0.02c-0.02-0.04,0.01-0.1,0.02,0.01c0.15-0.07,0.28,0.24,0.42,0.13l-0.01,0.01
				c0.19-0.05,0.13,0.02,0.78,0.22c0.01-0.02,0.01-0.11,0.05,0c-0.18-0.31,0.45,0.22,1.37,0.17c-0.01,0.02-0.02,0.04-0.03,0.04
				c0.03,0.07,0.13,0.03,0.14-0.02c-0.06-0.04,0.22,0.13,0.51,0.05c-0.03,0.11,0.1,0.11,0.15,0.07c-0.02,0.03,0.03,0.04,0,0.08
				c0.05,0.01,0.09-0.01,0.1-0.06c0.06,0.15,0.19-0.08,0.21,0.1c0.23-0.02,0.11-0.08,0.88,0.06c0,0.01-0.02,0.02-0.03,0.03
				c0.02,0,0.04,0.03,0.05-0.03l-0.02,0.01c-0.02-0.08,0.11-0.14,0.12-0.18c-0.06,0.14,0.1,0.06,0.01,0.23
				c0.02-0.01,0.04-0.03,0.04-0.06c0.16,0.12-0.01,0.01,0.58,0.13l-0.02-0.05c0.05,0.01,0.11-0.05,0.14-0.01l-0.04,0.03l0.1,0
				l-0.05-0.04c0.11-0.05,0-0.16,0.22,0.09c0.04-0.07,0.12,0,0.17-0.08c-0.01,0.02-0.02,0.07-0.02,0.08l0.03-0.06
				c0.04,0.06,0.06,0,0.22,0.09l-0.03,0.01l0.02,0.04l0.02-0.05c-0.02,0.1,0.21-0.08,0.48,0.03c-0.01,0.01-0.03,0.07-0.02,0.06
				c0.07,0.03,0.16-0.01,0.27-0.04c-0.07,0.01,0.11,0.16,0.17,0.01c0.11,0.08,0.09,0.25,0.53-0.04c-0.05,0.03-0.01,0.09,0.01,0.13
				c0-0.02,0.01-0.04,0.01-0.05c0.03,0.15,0.05-0.09,0.09-0.01c0.01-0.01,0.16-0.07,0.05,0.04c0.13-0.02-0.01,0,0.21,0.09
				c0.01-0.21,0-0.01,0.18-0.16c-0.06,0.1-0.04,0.03,0.02,0.2c0.04-0.06,0.06,0.05,0.11-0.04c0,0.02,0.01,0.02,0.02,0.03
				c0.01-0.04,0.12-0.06,0.56-0.13c-0.03,0.15,0.13-0.09,0.05,0.11c0.04-0.02,0.08-0.07,0.12-0.09c0.01,0.04-0.02,0.06-0.04,0.09
				C553.78,163.18,553.83,163.16,553.86,163.1z M541.16,160.73c0-0.01,0.01-0.02,0.01-0.03C541.19,160.7,541.2,160.72,541.16,160.73
				z M541.18,161.2c0.01,0,0.01,0,0.03,0C541.2,161.21,541.21,161.2,541.18,161.2z M541.3,161.17c-0.01,0.01,0.02,0.03,0.03,0.06
				C541.34,161.21,541.25,161.23,541.3,161.17z"
                />
                <path
                  className="st10"
                  d="M558.9,161.32c-0.03,0.26,0.33,0.3,0.31-0.52l0.01,0.27c0.07-0.16-0.03,0-0.02-0.71
				c-0.01,0-0.01,0-0.02,0.01c0.02-0.09,0,0,0.13-0.29c-0.02,0.02-0.04,0.03-0.06,0.04c-0.01-0.01,0.11-0.09,0.01-0.03
				c0.02-0.02,0.03-0.04,0.04-0.05c-0.03,0.02-0.06,0.04-0.1,0.05c0.04-0.02,0.08-0.04,0.1-0.06c-0.05,0.02-0.09,0.05-0.12,0.06
				c0.04-0.03,0.09-0.04,0.04-0.04c0.03-0.01,0.05-0.01,0.06-0.02c-0.04,0.01-0.02-0.02-0.08-0.02c0.06-0.02-0.02-0.06,0.09-0.06
				c-0.05-0.04-0.1-0.03,0.07-0.09c-0.11-0.05-0.03-0.1-0.07-0.17c0.01,0.01,0.02,0.02,0.02,0.02c0.02-0.02,0.06-0.02,0.08-0.03
				c-0.09-0.04-0.09-0.09-0.13-0.15c0.1,0.01,0.09,0.07,0.18,0.1c-0.07-0.03-0.04-0.09-0.11-0.11c0.06,0,0.04-0.01,0.11,0.02
				c-0.1-0.07,0-0.03-0.06-0.09c0.07,0,0.1,0.06,0.1-0.01c0.08,0.14,0.15-0.18,0.06-0.45c-0.01,0.01,0.01,0.03,0.02,0.04l-0.01-0.04
				c0.04,0.16,0.17,0,0.08,0.04c0.19,0.29,0.27-0.26,0.49-0.68c-0.18,0.32,0.15,0.06,1.01-1.3l0.09,0.04
				c-0.05-0.17,0.04,0.14,0.12-0.23c0.02,0.02,0.02,0.04,0.02,0.07c-0.06-0.12,0.11,0.04,0.01-0.09c0.06,0.01,0.1-0.05,0.12-0.1
				l0.03,0.04c0.07-0.06,0.1-0.16,0.17-0.24c-0.04,0.01-0.11-0.05-0.1-0.08l0.07,0.04c-0.03-0.04-0.12-0.13,0.03,0.01l0.01-0.05
				c0.02,0.01,0.02,0.03,0.05,0.05c-0.03-0.05,0.11-0.01,0.06-0.09c0.07-0.02,0.22-0.34,0.23-0.5c0.17,0.13,0.41-0.49,1.05-0.93
				c-0.01-0.02-0.09-0.06,0.02-0.04c-0.35-0.02,0.39-0.23,0.79-0.95c0.01,0.02,0.02,0.04,0.01,0.05c0.07,0.02,0.09-0.09,0.05-0.12
				c-0.06,0.03,0.22-0.11,0.29-0.36c0.08,0.08,0.14-0.01,0.13-0.07c0.02,0.03,0.05,0,0.07,0.05c0.03-0.03,0.04-0.07,0-0.11
				c0.15,0.04,0.03-0.19,0.19-0.1c0.09-0.18-0.01-0.13,0.5-0.62c0.01,0.01,0.01,0.02,0.01,0.04c0.01-0.02,0.04-0.02,0.01-0.05
				l0,0.02c-0.07-0.03-0.05-0.16-0.08-0.2c0.08,0.13,0.09-0.04,0.18,0.13c0-0.02,0-0.04-0.03-0.07c0.17-0.04,0.03-0.01,0.39-0.28
				c-0.03,0.01,0.08-0.05-0.03-0.08c0.03-0.03,0.02-0.11,0.07-0.11l0,0.05l0.05-0.07l-0.06,0.01c0.02-0.11-0.12-0.1,0.18-0.1
				c-0.04-0.07,0.07-0.09,0.03-0.17c0.01,0.02,0.04,0.06,0.05,0.06l-0.03-0.06c0.07,0.01,0.03-0.04,0.19-0.1l-0.01,0.03l0.05,0.01
				l-0.02-0.04c0.06,0.08,0.06-0.22,0.3-0.32c0,0.02,0.04,0.07,0.03,0.05c0.06-0.03,0.08-0.11,0.13-0.21
				c-0.04,0.05,0.19,0.04,0.11-0.11c0.13-0.01,0.22,0.12,0.31-0.37c-0.01,0.06,0.06,0.07,0.09,0.08c-0.01-0.01-0.02-0.03-0.03-0.04
				c0.12,0.09-0.03-0.1,0.05-0.06c0.01-0.02,0.06-0.15,0.06,0c0.09-0.1-0.04,0.02,0.2-0.06c-0.14-0.16-0.01-0.01,0.01-0.23
				c0.03,0.11-0.01,0.04,0.14,0.15c-0.01-0.07,0.07,0,0.05-0.09c0.05,0.13-0.04,0.1,0.34-0.4c0.07,0.13,0.04-0.14,0.1,0.06
				c0.01-0.04,0.01-0.1,0.03-0.14c0.03,0.03,0.02,0.06,0.03,0.09c-0.01-0.01,0.07-0.07-0.01-0.16c0.07,0.17-0.06-0.16-0.14,0.13
				l-0.05-0.1c0.04,0.15-0.03,0.03-0.13,0.08c-0.01-0.02-0.05-0.05-0.02-0.06c-0.1,0.03-0.17,0.07-0.06,0.25l-0.15-0.12l0.04,0.07
				c-0.01,0-0.03,0.01-0.04-0.02c0.01,0.06,0.03,0.02,0.04,0.07c-0.08-0.03-0.08,0.11-0.16,0.02c0.02,0.06,0.06,0.01,0.05,0.07
				c-0.08-0.13-0.05,0.14-0.15-0.04c0,0.16-0.17-0.01-0.16,0.21c-0.02-0.04,0,0.03-0.19,0.03c0.05,0.08-0.01,0.1-0.03,0.14
				c-0.03-0.04-0.04-0.06-0.04-0.1c-0.02,0.02-0.03,0.02-0.03,0.1l-0.04-0.06c-0.04,0.04-0.03,0.13-0.11,0.14
				c0.04,0.05,0.16,0.1,0.13,0.18c-0.02-0.02-0.06-0.07-0.06-0.09c0,0.01-0.1,0.03-0.07-0.04c-0.03,0.01-0.07,0.01-0.1-0.01
				c0.02,0.02,0.06,0.14-0.06,0.04c0.01,0.03-0.04,0.03,0.01,0.09c-0.01,0.01-0.04-0.01-0.05-0.03c0.01,0.04,0,0-0.01,0.13l0,0
				c0.05,0.13-0.11,0.02-0.45,0.34c0-0.06-0.04-0.02,0.01-0.05c-0.05,0.02-0.09,0-0.09,0.08c-0.03-0.06-0.08-0.03-0.09,0l0.06,0.01
				c-0.2,0.01-0.26,0.43-0.48,0.34l0.02,0.03c-0.04-0.01-0.09,0.04-0.11,0c-0.01,0.05,0.02,0.05,0.05,0.04
				c-0.08,0.01-0.07,0.07-0.14,0.09c0.06,0.02-0.02,0.3-0.14,0.06c0,0.08-0.11,0.05-0.14,0.06l0.01-0.04c0,0.02-0.02,0.03-0.03,0.04
				l0.05,0.03c-0.02,0-0.03,0.02-0.05,0c0.01,0.04,0.08,0.06,0.09,0.13c-0.04-0.01-0.03,0.07-0.09,0.02
				c0.09-0.05-0.19-0.23-0.09,0.04c-0.02-0.04-0.07-0.05-0.07-0.03c0-0.02,0.08,0.03,0.02,0.16c0.04,0,0.05-0.01,0.02,0.08
				c-0.03-0.02,0.01-0.04-0.01-0.04c0.03,0.08-0.12,0-0.12,0.07c-0.01-0.01-0.23-0.1-0.13,0.11l0.04-0.1l0.01,0.11
				c0.02-0.02,0.02-0.07,0.04-0.08c0.01,0.06,0,0.04,0.05,0.07c-0.13,0.2-0.15-0.22-0.34,0.16c0.04,0.01,0.08,0,0,0.08
				c-0.03-0.04-0.06-0.04-0.09-0.05c0.03,0.03-0.05,0.05,0.01,0.12c-0.1,0-0.12-0.06-0.1,0.19c-0.02-0.05-0.07-0.06-0.06-0.12
				c-0.02,0.08,0-0.02-0.02,0.27c-0.12-0.03-0.13-0.03-0.37,0.25c-0.01-0.01-0.02-0.02-0.01-0.02c-0.08,0.24-0.08-0.17-0.28,0.27
				c0,0-0.01-0.01-0.02-0.02c0.03,0.09-0.01,0.17-0.04,0.23c-0.09-0.11-0.05-0.08-0.15-0.01c-0.08-0.33,0,0.43-0.21,0.22
				c0,0.04,0,0.14-0.03,0.2c-0.03-0.07-0.02-0.15-0.02-0.21c-0.02,0.02-0.07,0.08-0.04,0.13c0.03-0.03,0.03-0.04,0,0.14
				c-0.13-0.14-0.14,0.08-0.28,0.02c-0.02,0.13-0.17,0.14-0.24,0.24c0.06-0.01,0.1,0.22-0.01,0.07c0.01,0.02,0.01,0.05,0.03,0.07
				c-0.05,0.01-0.13,0-0.25-0.06c0.05,0.03,0.04,0.07,0,0.08c0.09-0.04-0.05,0.16-0.08,0.05c-0.01,0.05-0.12,0.13-0.05,0.24
				c-0.02-0.01-0.06-0.02-0.05-0.05c-0.41,0.41-0.21,0.36-1.01,1.16c0-0.01,0.11,0.08,0,0.12l0.01-0.01
				c-0.09-0.05-0.11,0.01-0.14,0.05l0.1,0.09c-0.22-0.03-0.13,0.28-0.31,0.22c0.01,0.04-0.01,0.07,0,0.11
				c-0.08-0.03-0.05-0.07-0.19,0.11l-0.03-0.05c0.02,0.2,0.11,0.08-0.17,0.39c-0.01-0.01-0.03-0.03-0.03-0.05
				c-0.03,0.03-0.16,0.09-0.04,0.17c-0.08-0.06-0.07,0.07-0.06,0.14c-0.12-0.13,0.01,0.02-0.34,0.46c0.04-0.05,0.07-0.09,0.07-0.08
				c-0.03-0.1-0.3,0.09-0.19,0.27c-0.01,0,0.01-0.01,0-0.04l0,0.01c0.01-0.02,0.02-0.03,0.03-0.05c0.02,0.04,0.03,0.07-0.02,0.07
				c0.03,0.04,0.06,0.08,0.07,0.1c-0.04-0.04-0.16,0.27-0.53,0.51c-0.03-0.07-0.07-0.27-0.06-0.41c0.12,0.27,0.04-0.54,0.18-0.47
				c-0.11-0.2,0-0.71-0.03-1.07c0.01,0.02,0.02,0.06,0.02,0.09c0.21,0.04-0.19-1.08,0.17-0.79c-0.08,0-0.03-0.43-0.11-0.35
				c0.08-0.18,0.05-0.15,0.14-0.24c-0.15,0.12,0.03-1.02,0.13-0.55c-0.14-0.69,0.02-0.04,0.15-2.07c-0.14,0.01-0.15,0.04-0.08-0.27
				c0.44-0.17,0.15,0.16,0.56-2.43l0.07,0.13c0.1-1.07,0.21-1.21,1.34-6.13l0,0.09c0.12-0.31-0.04-0.4,0.11-0.68l-0.01,0.06
				c0.08-0.28,0.79-2.16,1.78-3.67l0.06,0.04c0.01-0.13,0.06-0.14,0.35-0.44c-0.24-0.01,0.1,0.02,0.84-0.83
				c0.01,0.03-0.07,0.1-0.12,0.18c0.44-0.41,0.05-0.11,0.95-0.63l-0.02,0.07c2.71-0.48,2.45-0.25,2.49,7.6
				c0.19-0.1,0.46,0.18,1.47-1.61c-0.9,1.29,2.2-3.66,3.25,0.64c0.01-0.05,0.02-0.07,0.04-0.12c-0.13,0.68-0.72,1.75,1.45,0.18
				c0.03,0.11,0.52-0.14,0.69-0.24c-0.05,0.05,0.17-0.02,0.11,0.05c0.2-0.07,0.39-0.12,0.34-0.17c1.01,0.13,0.87-0.34,2.59,0.62
				c-0.67,0.04,0.54-0.43,0.48,2.05c0.01,0,0.04-0.07,0.1,0.46c-0.1-0.2-0.16,0.48-0.27,0.17c-0.01,0.06-0.01,0.15,0.03,0.14
				c-0.04,0.09-0.07,0.11-0.07,0.01c-0.08,0.34,0.01,0.24-0.11,0.54c-0.02-0.01,0-0.07-0.02-0.12c-0.02,0.17-0.04,0.34-0.19,0.57
				c-0.01-0.02-0.04,0,0-0.07c-0.21,0.42-0.3,0.76-0.65,1.15c0-0.1-0.03-0.16-0.38,0.31l0.11-0.06c-0.16,0.19-0.25,0.4-0.42,0.53
				l0.1-0.16l-0.3,0.35l0.2-0.17c-0.14,0.22,0.1,0.06-0.14,0.29c0.04-0.05-0.17,0.18-0.31,0.23l0,0c-0.33,0.42-0.21-0.01-0.85,0.79
				c0.02-0.18-0.35,0.14-0.38,0.29c0.02-0.02,0-0.02,0.05-0.07c-0.33,0.26-0.49,0.35-0.61,0.42l0.07-0.06
				c-0.46,0.3,0.03-0.03-1.62,1.13l0.09-0.04c-0.46,0.4-0.44,0.05-1.63,0.96l0.04-0.06c-0.88,0.52-0.72,0.33-2.59,1.33
				c0.13-0.11,0.21-0.15-0.37,0.19c0.06,0.03,0.08,0,0.2-0.01c-0.05,0.06-0.17,0.15-0.29,0.21c0.2-0.14-0.04-0.08-0.15-0.06
				c0.02,0.01,0,0.04,0.02,0.05c-0.3,0.04,0.01,0.13-0.37,0.24c0-0.06,0.03-0.14-0.79,0.39l0.27-0.18
				c-0.23,0.03-0.24,0.06-0.39,0.12l0.01-0.01l-0.35,0.14l0.09-0.02c-0.17,0.28-0.48,0.28-0.76,0.55c0.27-0.26,0.02-0.08,0.01-0.16
				c-0.09,0.02-0.23,0.07-0.21,0.03c-0.1,0.07-0.08,0.16-0.62,0.29c0.8-0.3-1.44,0.87-2.67,1.53c0.07-0.14,0.12-0.25-0.79,0.41
				l0.14-0.04c-0.03-0.05,0-0.16,0.27-0.16l-0.12,0.08c0.2,0.06,0.5-0.28,0.77-0.41c-0.02,0.11,0,0.15,0.68-0.2
				c-0.03,0.04,0.06,0.05-0.05,0.09c0.35-0.11,0.49-0.34,0.65-0.33l-0.02,0.02c1.01-0.39,0.78-0.59,2.63-1.34
				c0.03,0.11-0.11,0,0.55-0.31c0,0.04,0.57-0.23,0.85-0.34l0,0.02c0.01-0.11,0.3-0.23,0.49-0.35c0.02,0.05-0.01,0.09-0.11,0.17
				c0.02-0.03,0.03,0.03,0.39-0.26c-0.11,0.33,0.12-0.12,0.93-0.58l-0.11,0.12c0.16-0.05,0.06-0.08,0.18-0.14
				c0.02,0.06,0.21-0.01-0.04,0.11c0.64-0.28,0.27-0.11,1.46-0.79l0,0c0.84-0.62,0.27,0.03,2.64-1.52
				c-0.16,0.16,0.08-0.01-0.17,0.15c0.25-0.14,0.33-0.14,0.54-0.37c-0.05,0.11,0.22-0.06,0.32-0.15l-0.19,0.08
				c0.12-0.1,0.23-0.16,0.34-0.24l-0.01,0.11c0.49-0.34,1.09-0.77,2-1.8c-0.33,0.05,0.63-0.37,0.4-0.38
				c0.26-0.23,0.36-0.41,0.6-0.67c-0.04-0.02,0.41-0.95,0.5-0.61c0.14-0.31,0.35-0.52,0.45-0.69l-0.06,0.17
				c0.04-0.1,0.08-0.18,0.11-0.26l-0.1,0.07c0.06-0.09,0.09-0.17,0.11-0.16c-0.03-0.25-0.16-0.08,0.13-0.6
				c-0.17,0.39,0.07,0.01-0.02,0.31c0.18-0.39,0.17-0.2,0.19-0.8c0.03,0.06,0.11-0.08,0.11-0.15c-0.05,0.13-0.09-0.08-0.05-0.54
				c-0.01,0.05-0.02,0.11-0.03,0.16c-0.16-0.27,0.29-0.81-0.18-1.95c0,0.13,0.02,0.13-0.15-0.07c0.11,0.17,0.21,0.34,0.29,0.54
				c-0.07-0.11-0.14-0.23-0.23-0.32c0.09,0.16,0.17,0.34,0.2,0.44c-0.1-0.16-0.08-0.14-0.1-0.05c-0.38-0.73,0.19-0.75-1.24-1.58
				l0.15,0.1c-0.71-0.18-0.44-0.41-2.23-0.2c0.11-0.07-0.08-0.08,0.19-0.16c-0.14,0.02-0.27,0.04-0.26,0.07
				c0.15-0.03-0.39,0.02-0.76,0.39c0.04-0.03-0.55,0.06-1.55,0.74c0.29-0.43,0.02-0.04,0.53-0.72c-0.14-0.05,0.53-0.1-0.16-1.78
				c0.02-0.01,0.04-0.01,0.05-0.01c-0.06-0.03-0.18-0.15-0.32-0.5c0.09,0.07,0.16,0.15,0.23,0.23c-0.03-0.2-0.3-0.31-0.45-0.39
				l0.04-0.02c-0.49-0.19-0.53-0.07-1.02-0.03l0.06,0.02c-0.62,0.3-0.38-0.21-1.34,0.6c0.11-0.15,0.4-0.37,0.6-0.5
				c-0.17,0.07-0.52,0.28-0.57,0.37c0.05-0.04,0.12-0.11,0.18-0.13c-0.57,0.53-0.34,0.3-1.7,1.38c0.21-0.8,0.03-0.02,0.23-1.98
				l0.04,0.05c-0.03-0.2,0.04-0.09-0.04-0.82l0.05,0.13c0.02-0.62-0.19-0.93-0.33-1.42c0.11-0.03,0.01-0.51-0.08-0.74l0.02,0
				c-0.02-0.21-2.5-2.64-4.81-0.63l0.07-0.1l-0.16,0.17l0.05-0.04c-0.29,0.27-0.7,0.74-1.39,1.7l-0.05-0.05
				c-0.25,0.35-0.37,0.54-0.6,1.02l0-0.12c-0.25,0.97-0.16,0.43-0.77,2.16c-0.01-0.01-0.04-0.04-0.01-0.12
				c-0.08,0.23-0.35,0.88-0.18,0.83c-0.09-0.02-0.15,0.51-0.18,0.78l-0.06-0.06c0.02,0.03-0.02,0.35-0.12,1.04l-0.02-0.12
				c-0.04,0.33-0.15,0.59-0.49,2.15c0.08-0.34,0.14-0.61,0.14-0.59c-0.06-0.01-0.13,0.26-0.17,0.02c-0.03,0.23,0.05,0-0.1,0.92
				l0-0.03c-0.07,0.38-0.08,0.2-0.22,0.84c0.07,0,0.02,0.18,0.11,0.02c-0.01,0.04,0.01-0.06,0.02-0.18l0,0.01
				c0.02-0.09,0.04-0.21,0.07-0.34c-0.03,0.97-0.27,0.65-0.51,2.61c0-0.07,0.02-0.15,0.01-0.19c-0.04,0.01,0.05,0.65-0.16,1.02
				l-0.02-0.06c-0.3,0.79-0.01,1.37-0.33,2.25c0.02-0.06,0.03-0.09,0.05-0.1c-0.21,0.42-0.29,2.51-0.54,5.37l0.05-0.07
				c-0.02,0.2,0.02,0.63-0.04,0.6C558.91,160.06,558.55,161.71,558.9,161.32z M560.18,158.27c-0.02,0.01-0.03,0.07-0.06,0
				C560.13,158.28,560.15,158.27,560.18,158.27z M560.88,147.58c-0.01-0.06-0.02,0.33-0.09,0.18
				C560.8,147.8,560.85,147.66,560.88,147.58z M560.76,148.16c-0.01,0.06,0,0-0.03,0.14C560.74,148.27,560.75,148.23,560.76,148.16z
				 M560.17,148.74c-0.03-0.08-0.04-0.06,0.02-0.17C560.19,148.61,560.18,148.57,560.17,148.74z"
                />
                <path
                  className="st10"
                  d="M557.96,163.14c0,0.01,0,0,0,0.03c0,0,0,0,0-0.01c0,0.02,0.01,0.03,0.01,0.05c-0.07,0-0.12,0.01-0.15,0.04
				l0.11,0.04c0-0.02,0.01-0.04,0.04-0.06c0.02,0.06,0.05,0.14,0.09,0.24l0.04-0.08l0.03,0.05l-0.05,0.01
				c0.04,0.05,0.04,0,0.06-0.04c0.01,0.05,0.13,0.1,0.09-0.04c0.02,0.02,0.09-0.03,0.06,0.06c0.02-0.01,0.08-0.11,0.18-0.01
				c-0.02-0.01,0.09-0.11,0.17,0.03c0.02-0.04,0-0.07,0.11-0.04c-0.06,0.08-0.1,0.11,0.5,0.25c-0.04,0.01,0.01,0.08-0.08,0.04
				c0.26,0.13,0.46,0.05,0.54,0.16l-0.02,0c0.72,0.4,0.78,0.02,2.11,0.92c-0.06,0.08-0.05-0.08,0.45,0.22
				c-0.03,0.02,0.39,0.29,0.56,0.45l-0.01,0.01c0.09-0.05,0.28,0.09,0.44,0.18c-0.04,0.04-0.08,0.04-0.17,0.01
				c0.03,0-0.01,0.04,0.33,0.17c-0.28,0.09,0.13,0.02,0.74,0.44l-0.13-0.03c0.06,0.01,0.31,0.25,0.06,0.09
				c0.38,0.37,0.16,0.15,1.01,0.82l0,0c0.33,0.2,0,0,0.84,0.81c-0.08,0.08,0.26,0.25,0.84,0.89c-0.15-0.1,0.01,0.07-0.14-0.11
				c0.14,0.18,0.14,0.24,0.35,0.37c-0.1-0.02,0.05,0.18,0.14,0.24l-0.07-0.15c0.09,0.08,0.15,0.16,0.22,0.24l-0.1,0.01
				c0.29,0.38,0.68,0.76,1.44,1.52c0.02-0.29,0.2,0.54,0.26,0.35c0.14,0.23,0.28,0.32,0.44,0.55c0.01-0.05,0.7,0.44,0.36,0.49
				c0.21,0.15,0.28,0.38,0.38,0.5l-0.11-0.09c0.07,0.05,0.11,0.1,0.16,0.15l-0.01-0.1c0.05,0.07,0.1,0.12,0.08,0.13
				c0.2,0.04,0.13-0.13,0.42,0.26c-0.09-0.17-0.28,0.18,0.33,0.28c-0.06,0.01,0.03,0.12,0.09,0.14c-0.1-0.08,0.07-0.06,0.48,0.04
				c-0.05-0.02-0.09-0.03-0.13-0.05c0.18-0.14,0.78,0.4,1.6-0.31c-0.11,0.03-0.1,0.04,0-0.15c-0.09,0.14-0.21,0.26-0.35,0.37
				c0.08-0.09,0.14-0.18,0.19-0.27c-0.1,0.1-0.22,0.23-0.3,0.27c0.11-0.12,0.09-0.1,0.01-0.1c0.48-0.47,0.66,0,0.92-1.33l-0.05,0.14
				c-0.05-0.59,0.24-0.43-0.44-1.74c0.09,0.06,0.06-0.09,0.19,0.09c-0.05-0.1-0.09-0.19-0.12-0.18c0.01,0-0.03-0.04-0.12-0.16
				c0.01,0.01,0.02,0.02,0.02,0.03c-0.04-0.04-0.1-0.08-0.19-0.15c0.01,0,0.04,0.02,0.04,0.02c-0.04-0.01-0.13-0.13-0.18-0.11
				c0.06-0.01-0.06-0.15-0.28-0.43c0.02,0.06,0.53,0.15,1.24,0.69l-0.05-0.02c0.23,0.05,0.07,0.27,1.54,0.23
				c0,0.02-0.01,0.03-0.01,0.04c0.05-0.04,0.17-0.09,0.45,0.02c-0.08,0.02-0.16,0.03-0.24,0.04c0.14,0.07,0.31-0.05,0.44-0.08
				l0,0.04c0.36-0.15,0.33-0.23,0.64-0.48l-0.05,0.01c0.23-0.52,0.49-0.01,0.39-1.11c0.09,0.13,0.1,0.46,0.06,0.67
				c0.08-0.14,0.11-0.52,0.04-0.59c0.01,0.06,0.04,0.14,0.01,0.2c-0.34-1.18,0.17-0.09-1.31-2.77c0,0.24-0.1-0.12-0.2,0
				c-0.15-0.15-0.19-0.3-0.22-0.51c0.31,0.05,0.61,0.06,0.82,0.1l-0.04,0.04c0.17-0.05,0.08,0.04,0.68-0.1l-0.1,0.06
				c0.52-0.05,0.74-0.31,1.07-0.54c0.05,0.1,0.42-0.1,0.58-0.26l0.01,0.02c-0.89-0.31,2.08-1.02-2.82-4.92l0.04-0.06
				c-0.29-0.18-0.43-0.29-0.82-0.45l0.1-0.01c-0.78-0.15-0.34-0.1-1.73-0.56c0.01-0.01,0.03-0.04,0.1-0.02
				c-0.18-0.06-0.71-0.26-0.67-0.11c0.02-0.1-0.41-0.1-0.63-0.1l0.05-0.06c-0.02,0.01-0.28,0.02-0.84,0l0.1-0.03
				c-0.33-0.04-0.21,0.15-0.74-0.08c0.1-0.01-0.3,0.1-0.22,0.02c-0.36-0.1,0.02,0.08-0.81-0.04c0.28-0.01,0.51,0,0.48,0.01
				c0-0.06-0.23-0.08-0.04-0.17c-0.19,0.01,0.01,0.05-0.75,0.11l0.02-0.01c-0.31,0.03-0.17-0.03-0.7,0
				c0.02,0.07-0.13,0.07,0.01,0.12c-0.03,0,0.05,0,0.14-0.03l-0.01,0.01c0.07-0.01,0.17-0.01,0.28-0.02
				c-0.75,0.24-0.56-0.12-2.1,0.3c0.24-0.12,0.24-0.09-0.65,0.14l0.04-0.04c-0.68,0-0.98,0.48-1.73,0.51c0.05,0,0.08-0.01,0.09,0
				c-0.39-0.05-1.87,0.72-4,1.47l0.07,0.02c-0.53,0.05-0.6,0.37-1.12,0.91c0.3,0,0.79-0.57,0.86-0.36c0.05-0.08,0.27,0.02,1.03-0.52
				c-0.14,0.2,0.4-0.16,0.4,0c0.1-0.17,0.5-0.26,0.74-0.42c-0.01,0.01-0.03,0.04-0.06,0.05c0.15-0.05,0.2-0.03,0.3-0.04
				c-0.18,0.07,0.56-0.58,0.32-0.09c-0.04-0.07,0.28-0.19,0.2-0.23c0.16,0,0.13-0.02,0.23,0.04c-0.15-0.09,0.72-0.36,0.44-0.09
				c0.47-0.41,0,0.02,1.5-0.63c-0.05-0.11-0.1-0.13,0.16-0.17c0.34,0.4-0.03,0.14,1.93-0.3l-0.07,0.11
				c0.93-0.22,1.81-0.38,4.83,0.07l-0.07,0.01c0.25,0.08,0.32-0.07,0.54,0.05l-0.05,0c0.06,0.12,0.91,0.09,2.88,1.39
				c0.01-0.02,0.16,0.03,0.37,0.25c0.02-0.02,0.02-0.03,0.03-0.04c0.02,0.06,0.14,0.24,0.7,0.63c-0.03,0.01-0.1-0.04-0.16-0.07
				c0.25,0.13-0.02,0.09,0.21,0.21c-0.01-0.03,0.05-0.02,0.06-0.03c0.05,0.13,0.24,0.33,0.4,0.47l-0.07,0
				c0.23,0.3,0.5,0.61,0.72,0.97c-0.14-0.09,0.2-0.12,0.07,0.03c0.36,0.1-0.76,3.26-3.39,1.45l0.02,0.01
				c-0.43,0.89-0.49,0.21,1.37,2.85l0.02-0.04c0.09,0.06,0.05,0.12,0.09,0.18c0.11-0.12,1.14,1.36-0.42,2.15l0.08-0.1
				c-0.57,0.25-1.19-0.02-1.79-0.25c0.04,0,0.06-0.02,0.11,0.01c-0.19,0.07-0.08-0.19-2.61-1.09c0.07,0.07,0,0.06-0.08,0.04
				c0.13,0.16,0.25,0.32,0.47,0.48c-0.4-0.29,0.42,0.62,0.66,0.85c-0.12-0.1,0.11,0.2-0.03,0.09c0.23,0.3,0.4,0.55,0.42,0.48
				c0.13,0.78,0.49,0.54,0.21,2.05c-0.22-0.59,0.3,0.63-1.31,0.54c0-0.01,0.04-0.01,0.08-0.01c-0.2-0.01,0.04,0.02-0.44,0.04
				c0.18-0.05-0.3-0.25-0.02-0.28c-0.04-0.02-0.11-0.04-0.12,0c-0.05-0.05-0.06-0.08,0.03-0.07c-0.22-0.15-0.19-0.05-0.37-0.22
				c0.02-0.02,0.06,0.01,0.1,0.01c-0.12-0.05-0.25-0.11-0.38-0.29c0.02,0,0.02-0.03,0.06,0.02c-0.26-0.28-0.53-0.4-0.77-0.75
				c0.09,0.02,0.15-0.03-0.18-0.36l0.03,0.1c-0.13-0.15-0.29-0.24-0.38-0.39l0.13,0.09l-0.26-0.27l0.11,0.18
				c-0.17-0.12-0.08,0.09-0.23-0.12c0.04,0.03-0.13-0.15-0.15-0.26l0,0c-0.35-0.28,0.06-0.19-0.62-0.68
				c0.16-0.01-0.11-0.29-0.24-0.3c0.02,0.02,0.02,0,0.06,0.03c-0.25-0.26-0.33-0.38-0.39-0.48l0.07,0.06
				c-0.28-0.34,0.04,0.02-1.11-1.12l0.05,0.06c-0.42-0.26-0.07-0.35-1.08-1.04l0.06,0.01c-0.63-0.5-0.41-0.49-1.78-1.49
				c0.13,0.05,0.19,0.09-0.27-0.19c-0.01,0.07,0.03,0.06,0.07,0.15c-0.06,0-0.18-0.05-0.26-0.11c0.18,0.08,0.05-0.08,0-0.15
				c0,0.02-0.03,0.02-0.04,0.04c-0.13-0.21-0.1,0.08-0.31-0.15c0.05-0.03,0.11-0.07-0.59-0.37c0.07,0.02,0.16,0.08,0.24,0.1
				c-0.11-0.16-0.13-0.15-0.24-0.22l0.01,0.01l-0.24-0.18l0.06,0.06c-1.27-0.35,0.04,0.01-3.38-1.29c0.03-0.01,0.05-0.02,0.07-0.05
				c0,0.01-0.01,0.03-0.01,0.02c0.04,0,0.05,0.04,0.08,0.06c-0.01-0.1,0.04-0.13,0.06-0.19c0.06,0.09-0.01,0.12,0.02,0.21
				c-0.02-0.08,0.06-0.09,0.03-0.15c0.04,0.05,0.03,0.02,0.06,0.1c-0.01-0.12,0.03-0.03,0.04-0.1c0.02,0.07,0.1-0.04,0.05,0.13
				c0.04,0.01,0.01,0.01,0.09,0.02c-0.07-0.12,0-0.01,0.25-0.25c0.03,0.04,0.01,0.03,0.03-0.11c-0.01,0-0.01,0.02-0.02,0.04
				l0.02-0.03c-0.08,0.13,0.11,0.13,0.03,0.09c-0.05,0.32,0.35,0.02,0.79-0.12c-0.36,0.09,0.07,0.15,1.56-0.18l0.03,0.09
				c0.08-0.15-0.07,0.12,0.24-0.07c0,0.03-0.02,0.04-0.03,0.06c0.04-0.13,0.05,0.11,0.07-0.06c0.03,0.04,0.1,0.04,0.14,0.02l0,0.05
				c0.09,0.01,0.18-0.04,0.28-0.05c-0.04-0.02-0.03-0.11-0.01-0.13l0.02,0.08c0.01-0.05,0.02-0.18,0.01,0.03l0.04-0.03
				c0,0.02-0.01,0.04-0.01,0.07c0.01-0.05,0.08,0.08,0.1-0.01c0.06,0.03,0.38-0.07,0.5-0.17c0.02,0.22,0.61-0.04,1.32,0.18
				c0-0.02-0.01-0.11,0.04-0.01c-0.19-0.28,0.4,0.14,1.16,0.02c-0.01,0.02-0.01,0.04-0.03,0.04c0.03-0.01,0.13,0.01,0.55-0.01
				c-0.02,0.11,0.08,0.1,0.12,0.06c-0.02,0.03,0.02,0.04,0,0.08c0.04,0.01,0.08-0.01,0.08-0.07c0.05,0.15,0.17-0.08,0.17,0.1
				c0.19-0.02,0.1-0.09,0.74,0.08c0,0.01-0.02,0.02-0.03,0.02c0.02,0,0.03,0.03,0.05-0.02l-0.02,0.01
				c-0.01-0.08,0.11-0.13,0.12-0.17c-0.07,0.13,0.08,0.06-0.02,0.22c0.01-0.01,0.03-0.03,0.04-0.06c0.11,0.12,0.03,0.02,0.41,0.19
				c-0.02-0.02,0.08,0.04,0.05-0.06c0.04,0.02,0.1-0.03,0.12,0.01l-0.04,0.03l0.08,0.01l-0.03-0.05c0.09-0.03,0.06-0.13,0.16,0.11
				c0.05-0.07,0.1,0.01,0.16-0.05c-0.02,0.01-0.03,0.06-0.03,0.07l0.04-0.05c0.02,0.06,0.04,0.01,0.16,0.13l-0.03,0l0.01,0.05
				l0.03-0.04c-0.05,0.09,0.23-0.04,0.39,0.13c-0.01,0-0.04,0.06-0.04,0.05c0.05,0.05,0.13,0.02,0.24,0.03
				c-0.04-0.01,0.06,0.18,0.14,0.06c0.05,0.12-0.04,0.24,0.44,0.15c-0.06,0.01-0.05,0.08-0.05,0.12c0.01-0.02,0.02-0.03,0.03-0.05
				c-0.05,0.14,0.08-0.06,0.07,0.02c0.02,0,0.15,0,0.02,0.06c0.11,0.05-0.02-0.04,0.11,0.17c0.11-0.18,0-0.01,0.21-0.06
				c-0.1,0.06-0.04,0-0.1,0.18c0.06-0.03,0.01,0.07,0.1,0.02c-0.01,0.02,0.03,0.02-0.02,0.07c0.1-0.12,0.37,0.07,0.47,0.14
				c-0.11,0.1,0.14,0.01-0.04,0.11c0.04,0,0.09-0.01,0.13-0.01c-0.02,0.04-0.06,0.04-0.08,0.05c0.01-0.02,0.08,0.05,0.15-0.04
				c-0.15,0.11,0.14-0.09-0.15-0.1l0.08-0.07c-0.13,0.08-0.04-0.02-0.1-0.1c0.02-0.01,0.04-0.06,0.05-0.04
				c-0.06-0.08-0.11-0.14-0.25,0.01l0.07-0.18l-0.05,0.06c-0.01-0.01-0.02-0.02,0-0.05c-0.06,0.02-0.01,0.03-0.06,0.06
				c0.01-0.08-0.12-0.04-0.06-0.15c-0.05,0.04,0.01,0.06-0.05,0.07c0.1-0.12-0.14-0.01,0-0.15c-0.15,0.05-0.03-0.16-0.24-0.08
				c0.03-0.03-0.03,0.01-0.09-0.17c-0.06,0.07-0.09,0.03-0.14,0.03c0.03-0.05,0.05-0.06,0.08-0.07c-0.03-0.01-0.03-0.02-0.1,0.01
				l0.04-0.05c-0.05-0.02-0.13,0.02-0.16-0.05c-0.03,0.05-0.04,0.18-0.12,0.19c0.01-0.03,0.04-0.08,0.06-0.09
				c-0.01,0.01-0.07-0.08,0.01-0.08c-0.02-0.02-0.03-0.06-0.03-0.1c-0.02,0.02-0.1,0.11-0.05-0.04c-0.03,0.02-0.04-0.02-0.07,0.04
				c-0.01-0.01,0-0.04,0-0.06c-0.04,0.02,0,0-0.12,0.04l0,0c-0.1,0.09-0.06-0.09-0.47-0.26c0.05-0.02,0-0.04,0.05-0.01
				c-0.04-0.04-0.03-0.08-0.11-0.04c0.07,0-0.08-0.14-0.02-0.02c-0.1-0.12-0.13-0.04-0.71-0.35c0,0.06-0.27,0.13-0.11-0.09
				c-0.07,0.04-0.09-0.07-0.12-0.09l0.04-0.01c-0.02,0.01-0.04,0-0.05,0l0,0.06c-0.01-0.02-0.03-0.02-0.02-0.04
				c-0.03,0.03-0.02,0.1-0.07,0.14c-0.01-0.04-0.08,0.01-0.06-0.07c0.09,0.05,0.1-0.28-0.08-0.05c0.02-0.04,0.01-0.09-0.01-0.08
				c0.02-0.01,0.01,0.09-0.12,0.1c0.02,0.04,0.04,0.04-0.05,0.06c0.01-0.03,0.03-0.01,0.03-0.03c-0.06,0.07-0.06-0.1-0.12-0.06
				c-0.02-0.04,0.02-0.08,0-0.11c-0.06,0.09-0.07-0.14-0.16,0.05l0.11-0.02l-0.08,0.07c0.03,0.01,0.07-0.02,0.09-0.01
				c-0.04,0.04-0.03,0.03-0.03,0.08c-0.23,0,0.1-0.24-0.31-0.19c0.01,0.04,0.04,0.07-0.07,0.04c0.01-0.05,0-0.07-0.01-0.1
				c-0.01,0.04-0.07-0.02-0.09,0.08c-0.05-0.08-0.01-0.14-0.2,0.02c0.03-0.05,0.01-0.09,0.06-0.12c-0.08,0.03,0.02-0.01-0.22,0.14
				c-0.04-0.12-0.05-0.12-0.41-0.15c0.01-0.01,0-0.02,0.01-0.02c-0.21,0.09,0.04-0.18-0.37-0.06c0,0,0-0.01,0-0.02
				c-0.05,0.08-0.13,0.1-0.2,0.11c0.03-0.14,0.04-0.09-0.08-0.12c0.13-0.08-0.17,0.15-0.29-0.03c-0.03,0.02-0.11,0.09-0.17,0.1
				c0.03-0.07,0.1-0.1,0.14-0.14c-0.03,0-0.1,0-0.12,0.05c0.04,0,0.05,0-0.11,0.09c0.02-0.19-0.15-0.06-0.19-0.21
				c-0.11,0.07-0.21-0.04-0.33-0.03c0.05,0.04-0.1,0.21-0.06,0.04c-0.01,0.02-0.03,0.04-0.03,0.07c-0.04-0.03-0.08-0.1-0.11-0.23
				c0.01,0.05-0.02,0.08-0.06,0.06c0.09,0.04-0.15,0.07-0.09-0.03c-0.05,0.03-0.17,0-0.21,0.12c-0.01-0.02-0.02-0.06,0-0.07
				c-0.65,0-0.38,0.08-1.48,0.04c-0.05,0.11-0.09,0-0.63,0.15c-0.03-0.08,0.01-0.09-0.21-0.06l0.01-0.05
				c-0.12,0.16,0.02,0.14-0.38,0.15c0-0.02,0-0.05,0.01-0.05c-0.04,0-0.17-0.05-0.14,0.09c-0.01-0.09-0.09,0-0.14,0.05
				c0.01-0.18-0.02,0.03-0.54,0.08c0.06-0.01,0.11-0.01,0.11,0c0.05-0.08-0.27-0.15-0.32,0.05c-0.01-0.01,0.01,0,0.03-0.02l0,0.01
				c0.02,0,0.04-0.01,0.06-0.01c-0.01,0.04-0.03,0.07-0.06,0.04c-0.01,0.05-0.01,0.09-0.02,0.12c-0.06,0.04-0.33-0.12-0.9,0.05
				c0.01,0.01,0.02,0.01,0.02,0.03c-0.11,0.01-0.17-0.12-0.64,0.13l-0.02-0.07C558.73,163.01,558.29,162.86,557.96,163.14
				L557.96,163.14z M568.35,158.84c0.05-0.05,0.04-0.05,0.14-0.03C568.46,158.83,568.49,158.8,568.35,158.84z M568.82,159.27
				c0.02,0,0.06,0,0.11-0.01C568.88,159.27,568.93,159.27,568.82,159.27z M569.23,159.21c-0.02,0.02,0.08,0.03,0.15,0.05
				C569.43,159.23,569.12,159.31,569.23,159.21z M560.44,162.97c-0.02-0.01-0.07,0.02-0.04-0.05
				C560.4,162.94,560.43,162.95,560.44,162.97z M560.16,162.56c0-0.01,0-0.03,0-0.04C560.18,162.51,560.19,162.54,560.16,162.56z
				 M558.55,163.35C558.55,163.35,558.55,163.35,558.55,163.35C558.54,163.35,558.54,163.35,558.55,163.35
				C558.54,163.35,558.54,163.35,558.55,163.35z"
                />
                <path
                  className="st10"
                  d="M555.13,182.25c-0.01-0.02-0.07-0.2,0-0.03c0-0.03,0-0.05,0-0.07c0.03,0.16,0.1,0.41,0.31,0.7l-0.11-0.08
				c0.31,0.12-0.13,0.85,1.55,1.24c-0.04,0.02-0.04,0.04-0.11,0.03c0.86-0.15,0.21,0.1,1.93-1.22c0.1,0.08,0.27-0.36,0.23-0.52
				c0.02,0.06,0.06-0.12,0.1-0.05c0.04-0.17,0.03-0.33-0.03-0.3c0.09-0.5,0.23-0.43-0.33-2.06c0.05,0.05,0.08,0.09,0.11,0.12
				c-0.1-0.27-0.07-0.34-0.54-1.62c0.01,0,0.03,0.03,0.05,0.06c-0.11-0.16,0.02,0.02-0.31-0.29c0.17,0.07-0.06-0.33,0.17-0.21
				c-0.02-0.04-0.06-0.09-0.09-0.07c-0.01-0.07,0-0.09,0.06-0.04c-0.09-0.22-0.12-0.13-0.18-0.34c0.02-0.01,0.05,0.03,0.08,0.05
				c-0.08-0.09-0.15-0.19-0.18-0.37c0.02,0.01,0.03-0.02,0.04,0.03c-0.09-0.3-0.27-0.49-0.31-0.84c0.07,0.04,0.15,0.02,0.02-0.34
				l-0.03,0.08c-0.04-0.16-0.14-0.28-0.14-0.43l0.07,0.11l-0.09-0.29l0,0.18c-0.08-0.15-0.11,0.04-0.14-0.17
				c0.02,0.04-0.03-0.16,0-0.26l0,0c-0.16-0.36,0.15-0.09-0.17-0.75c0.13,0.06,0.07-0.25-0.04-0.31c0.01,0.02,0.02,0.01,0.03,0.05
				c-0.05-0.29-0.05-0.41-0.05-0.5l0.02,0.07c-0.03-0.36,0.01,0.03-0.19-1.29l0,0.07c-0.14-0.38,0.15-0.24-0.07-1.24l0.03,0.04
				c-0.03-0.65,0.03-0.52,0.12-1.89c0.02,0.11,0.02,0.17,0.04-0.27c-0.06,0.02-0.04,0.04-0.1,0.11c-0.03-0.05-0.04-0.15-0.03-0.24
				c0.01,0.16,0.09,0.01,0.13-0.05c-0.02,0.01-0.03-0.02-0.05-0.02c0.12-0.37-0.08-0.04,0.14-0.85c0,0.06-0.02,0.15-0.01,0.21
				c0.11-0.12,0.09-0.14,0.13-0.24l0,0.01l0.09-0.22l-0.04,0.05c-0.12-0.22,0.06-0.36,0.03-0.62c-0.02,0.14,0.03-0.05,0.34-0.41
				c-0.17,0.26,0.28-1.02,0.79-1.84c0.04,0.11,0.05,0.21,0.32-0.48l-0.08,0.06c0.04,0.01,0.1,0.12-0.1,0.18l0.04-0.09
				c-0.18,0-0.19,0.33-0.3,0.48c-0.05-0.06-0.09-0.14-0.34,0.33c-0.01-0.03-0.08-0.02-0.02-0.08c-0.17,0.18-0.11,0.38-0.22,0.43
				l0-0.02c-0.14,0.33-0.5,0.82-0.77,2.25c-0.03-0.02-0.14,0.38-0.21,0.57l-0.01-0.01c0.08,0.06,0.03,0.25,0.02,0.41
				c-0.05-0.01-0.07-0.04-0.08-0.13c0.01,0.02-0.04,0.01,0.01,0.32l-0.06-0.04c0.08,0.1-0.22,0.23,0.07,0.77l-0.05-0.11
				c0.02,0.05-0.02,0.33-0.04,0.08c-0.08,0.44-0.04,0.19-0.02,1.1v0c0.11,0.2-0.11,0.84,0.08,2.01c-0.06-0.14-0.03,0.05-0.05-0.14
				c0,0.19-0.03,0.24,0.06,0.43c-0.07-0.06-0.06,0.15-0.03,0.24l0.03-0.14c0.02,0.1,0.03,0.19,0.04,0.27l-0.09-0.04
				c0.02,0.41,0.12,0.86,0.39,1.72c0.15-0.21-0.1,0.48,0.05,0.36c0.01,0.23,0.08,0.34,0.11,0.58c0.03-0.05,0.4,0.59,0.08,0.5
				c0.11,0.19,0.07,0.39,0.09,0.51l-0.05-0.1c0.03,0.06,0.05,0.11,0.06,0.17l0.04-0.08c0.01,0.07,0.02,0.12,0,0.13
				c0.13,0.1,0.19-0.06,0.21,0.33c-0.06-0.35-0.34-0.06,0.07,0.32c-0.05-0.02-0.06,0.08-0.04,0.12c-0.02-0.08,0.11-0.02,0.22,0.28
				l-0.02-0.1c0.18,0.14,0.07,0.46,0.23,1.19c0.04-0.08,0.02-0.08,0.12,0.05l-0.15-0.33l0.15,0.2c-0.03-0.1-0.11-0.21-0.12-0.28
				c0.08,0.09,0.06,0.09,0.09,0.02c0.14,0.52-0.18,0.32,0.23,1.23l0-0.11c0.07,0.03,0.05,0.2,0.1,0.3c-0.05,0-0.06,0.07-0.08,0.12
				c0.04-0.01,0.03,0.23,0.13,0.25c-0.07,0.3-0.09,0.14,0.07,0.69c-0.05-0.08-0.09,0.03-0.12-0.14c-0.01,0.08-0.02,0.15,0.01,0.16
				c0-0.11-0.09,0.25,0.04,0.53c-0.15,0.06-0.15,0.22-0.45,0.53c0.01-0.05-0.04-0.1-0.08-0.02l0.07,0.03c-0.05,0.05,0,0-0.17,0.16
				c0,0,0,0,0,0c-0.11,0.1-0.24,0.2-0.41,0.33c0-0.02-0.01-0.02,0.02-0.03c-0.48,0.19,0,0.09-0.74,0.08l0.04-0.01
				c-0.12-0.17-0.2,0.22-0.92-0.5c0.03-0.01,0.05-0.01,0.07-0.02c-0.06,0-0.18-0.04-0.09-0.32l0.07,0.16
				c0.05-0.11-0.08-0.21-0.1-0.3l0.04,0c-0.09-0.27-0.17-0.19-0.26-0.61l-0.02,0.06c-0.28-0.23,0.23-0.3-0.36-0.96
				c0.06-0.02,0.18,0.13,0.27,0.24c-0.28-0.42-0.02,0.07-0.77-0.84c-0.06,0.31,0.1,0.81-0.39,1.96c0.2-0.05-0.05,0.1,0.09,0.16
				c-0.07,0.07-0.07,0.17-0.19,0.01c0.12-0.06-0.05,0.19,0.1,0.11c-0.11,0.1-0.23,0.09-0.46,0.08c0.05,0.03-0.01,0.1-0.11,0.13
				c0.05,0,0.11-0.01,0.16-0.02c-0.19,0.21-0.25,0.01-0.45,0.14c0.02-0.02,0.04-0.04,0.06-0.06c-0.41-0.07-0.55,0.27-1.14-0.72
				l0.05,0.01c-0.12-0.17,0-0.1-0.22-0.46l0.07,0.06c-0.09-0.37-0.3-0.51-0.44-0.8c0.1-0.03,0.05-0.33,0.03-0.48l0.02,0
				c0.17-1.06-0.71,0.13-0.6-5.41l0.05,0.07c-0.09-0.67-0.14-0.22,0.06-1.44c0.01,0.01,0.04,0.02,0.04,0.08
				c0.03-0.15,0.15-0.58,0.01-0.55c0.09,0.01,0.08-0.33,0.08-0.51l0.06,0.04c-0.02-0.01-0.02-0.23,0.02-0.68l0.03,0.08
				c0.07-0.26-0.13-0.2,0.18-0.57c-0.02,0.08-0.03-0.27,0.03-0.17c0.17-0.25-0.08-0.01,0.22-0.61c-0.07,0.22-0.13,0.39-0.13,0.37
				c0.06,0.02,0.13-0.15,0.17,0.02c0.02-0.15-0.05-0.01,0.1-0.6l0,0.02c0.08-0.24,0.09-0.12,0.24-0.5
				c-0.07-0.02-0.01-0.13-0.11-0.04c0.01-0.02-0.01,0.04-0.03,0.12l0-0.01c-0.02,0.06-0.05,0.13-0.08,0.21
				c0.08-0.62,0.23-0.38,0.68-1.59c-0.03,0.22-0.06,0.2,0.25-0.49l0.01,0.05c0.41-0.38,0.23-0.89,0.74-1.28
				c-0.03,0.03-0.05,0.04-0.07,0.04c0.42-0.42,1.03-1.65,2.3-2.63l-0.07-0.01c0.74-0.53-0.18,0.03,0.64-1.05
				c-0.03-0.01-0.02,0.05-0.14,0.05l0.02,0.01c-0.22,0.17-0.4,0.58-0.51,0.43c-0.01,0.09-0.25,0.04-0.66,0.72
				c0.03-0.22-0.27,0.25-0.34,0.12c-0.02,0.17-0.29,0.39-0.39,0.61c0-0.02,0-0.05,0.02-0.06c-0.06,0.05-0.04,0.04-0.21,0.15
				c-0.01-0.05-0.01-0.11,0.06-0.05c0.03-0.08-0.05,0.07-0.09-0.07c0.03,0.22,0.17-0.02-0.18,0.33c0.07,0.04-0.12,0.27-0.03,0.26
				c-0.13,0.06-0.09,0.06-0.19,0.06c0.16,0-0.32,0.62-0.27,0.27c-0.06,0.54-0.08-0.04-0.64,1.25c0.09,0.04,0.17,0.03,0.02,0.21
				c-0.01-0.2,0.06-0.07-0.36,0.14c0.1-0.24,0.02-0.27,0.8-1.91l0,0c0-0.01,0,0,0.01-0.03c0.01,0.01,0.02,0.02,0.05,0.04
				c0-0.07,0.07,0.02,0.08-0.09c0.02,0.13-0.07,0.08,0.46-0.34c0.04,0.15,0.08-0.14,0.09,0.08c0.03-0.04,0.04-0.1,0.07-0.13
				c0.03,0.03,0.01,0.07,0,0.1c-0.01-0.01,0.09-0.06,0.04-0.16c0.02,0.19-0.02-0.17-0.18,0.11l-0.02-0.1c0,0.16-0.05,0.02-0.16,0.05
				c0-0.02-0.04-0.06-0.01-0.06c-0.09-0.02-0.02,0-0.1,0.04c0.06-0.18,0.13-0.38,0.11-0.12c0.08-0.19,0.23-0.48,0.21-0.57l-0.09,0.1
				c0.05,0.02,0.09,0.13-0.12,0.25l0.05-0.12c-0.15,0.04-0.21,0.31-0.3,0.54c-0.03-0.03-0.03-0.06-0.04,0.02
				c-0.01,0.06-0.11-0.16-0.11,0.02l0.04-0.01c-0.02-0.01,0.04,0.15-0.08,0.06c0.01,0.06,0.06,0.02,0.03,0.08
				c-0.05-0.15-0.08,0.13-0.15-0.07c-0.05,0.16-0.18-0.04-0.22,0.19c-0.01-0.04-0.01,0.03-0.21,0c0.03,0.09-0.03,0.1-0.06,0.14
				c-0.03-0.05-0.03-0.07-0.02-0.11c0,0.01-0.04-0.01-0.06,0.09l-0.02-0.07c-0.05,0.03-0.06,0.12-0.15,0.12
				c0.03,0.06,0.14,0.12,0.09,0.2c-0.02-0.03-0.05-0.08-0.04-0.1c0,0-0.12,0.02-0.07-0.05c-0.03,0.01-0.08,0-0.11-0.02
				c0.04,0.08-0.12-0.04-0.17,0.22l0,0c-0.01,0.13-0.27,0.04-0.54,0.29c0.01-0.06-0.03-0.02,0.02-0.05
				c-0.06,0.01-0.09-0.01-0.11,0.08c-0.02-0.06-0.08-0.04-0.09-0.01l0.06,0.02c-0.21-0.02-0.35,0.41-0.57,0.3l0.01,0.03
				c-0.04-0.01-0.1,0.03-0.11-0.02c-0.02,0.05,0.01,0.05,0.05,0.05c-0.08,0-0.09,0.06-0.17,0.08c0.05,0.02-0.07,0.31-0.16,0.05
				c-0.02,0.09-0.12,0.04-0.16,0.05l0.01-0.04c-0.01,0.02-0.02,0.03-0.04,0.04l0.05,0.04c-0.02,0-0.04,0.01-0.05-0.01
				c0.01,0.04,0.07,0.07,0.07,0.14c-0.04-0.01-0.05,0.07-0.1,0.02c0.11-0.04-0.16-0.25-0.1,0.04c-0.01-0.03-0.04-0.04-0.06-0.04
				c0.02,0.02,0.05,0.08-0.02,0.16c0.04,0.01,0.06-0.01,0.01,0.08c-0.02-0.03,0.01-0.04-0.01-0.04c0.01,0.09-0.12-0.01-0.14,0.07
				c-0.01-0.02-0.22-0.13-0.16,0.11l0.06-0.1l0,0.11c0.03-0.02,0.03-0.07,0.06-0.08c0,0.06,0,0.05,0.04,0.08
				c-0.16,0.19-0.13-0.23-0.38,0.15c0.04,0.01,0.08,0-0.02,0.09c-0.03-0.04-0.06-0.04-0.09-0.06c0.02,0.03-0.06,0.05-0.01,0.13
				c-0.11-0.01-0.12-0.07-0.14,0.18c-0.01-0.06-0.07-0.06-0.05-0.12c-0.04,0.08,0.01-0.02-0.06,0.27c-0.12-0.04-0.14-0.03-0.42,0.26
				c-0.01-0.01-0.02-0.02,0-0.02c-0.03,0.2-0.18-0.1-0.34,0.27c0,0-0.01-0.01-0.02-0.02c0.02,0.09-0.03,0.18-0.06,0.24
				c-0.08-0.11-0.04-0.09-0.15-0.01c-0.04-0.37-0.05,0.45-0.24,0.22c-0.01,0.04-0.02,0.15-0.05,0.21c-0.03-0.08,0-0.15,0-0.21
				c-0.03,0.02-0.08,0.08-0.06,0.13c0.03-0.03,0.04-0.05-0.02,0.15c-0.13-0.15-0.16,0.09-0.3,0.02c-0.03,0.14-0.19,0.15-0.28,0.26
				c0.07-0.01,0.08,0.23-0.01,0.08c0.01,0.02,0.01,0.05,0.03,0.07c-0.06,0.01-0.14,0-0.25-0.06c0.05,0.03,0.04,0.07,0,0.09
				c0.08,0.03,0.16,0.05-0.07,0.11l-0.01-0.06c-0.02,0.06-0.13,0.14-0.08,0.26c-0.02-0.01-0.06-0.02-0.05-0.05
				c-0.41,0.35-0.14,0.35-1.11,1.27c0.04,0.14-0.06,0.07-0.37,0.64c-0.09-0.03-0.05-0.07-0.2,0.14l-0.03-0.05
				c0.03,0.22,0.1,0.08-0.18,0.43c-0.01-0.01-0.04-0.03-0.03-0.05c-0.03,0.04-0.16,0.12-0.04,0.18c-0.08-0.05-0.07,0.08-0.06,0.15
				c-0.13-0.12,0.01,0.02-0.34,0.52c0.04-0.06,0.07-0.11,0.07-0.1c-0.05-0.11-0.3,0.12-0.19,0.32c-0.01,0,0.01-0.01,0-0.04l0,0
				c0.01-0.02,0.02-0.04,0.03-0.06c0.02,0.04,0.03,0.07-0.02,0.08c0.03,0.04,0.06,0.08,0.08,0.09c-0.02,0.08-0.34,0.2-0.6,0.85
				c0.02-0.01,0.02-0.01,0.04,0c-0.07,0.12-0.21,0.06-0.34,0.67l-0.07-0.02c0.06,0.39-0.61,0.48,0.14,0.75l-0.02,0.05
				c0.06,0,0.03-0.04,0.01-0.07c0.03,0.01,0.05,0.01,0.07,0l-0.07-0.1c0.04,0.01,0.07,0.06,0.11,0.04c-0.03-0.05-0.1-0.02-0.11-0.08
				c0.06,0,0.1,0.02,0.07-0.03c0.03,0.01,0.04,0.02,0.05,0.04c-0.02-0.04,0.02-0.08-0.02-0.12c0.07,0,0.03-0.1,0.13-0.05
				c-0.02-0.02-0.05-0.09-0.04-0.12c0.09,0.11,0.06-0.09,0.18-0.02c-0.09-0.09,0.02-0.15-0.01-0.24c0.01,0.01,0.02,0.02,0.01,0.03
				c0.03-0.03,0.06-0.02,0.09-0.04c-0.08-0.05-0.07-0.11-0.11-0.17c0.11,0,0.09,0.08,0.17,0.11c-0.07-0.04-0.03-0.11-0.1-0.13
				c0.07,0,0.04-0.01,0.12,0.01c-0.1-0.06,0-0.04-0.05-0.1c0.06,0.05,0.22-0.16,0.13-0.51c-0.01,0.01,0.01,0.03,0.02,0.04
				l-0.01-0.04c0.05,0.17,0.18-0.02,0.09,0.03c0.21,0.3,0.27-0.32,0.47-0.78c-0.16,0.32,0.15,0.05,1.03-1.47l0.09,0.03
				c-0.06-0.16,0.08,0.1,0.13-0.25c0.02,0.02,0.02,0.05,0.02,0.07c-0.07-0.12,0.11,0.04,0.01-0.1c0.06,0,0.1-0.05,0.12-0.11
				l0.03,0.04c0.07-0.07,0.11-0.18,0.18-0.26c-0.04,0.02-0.11-0.05-0.1-0.08l0.07,0.04c-0.03-0.05-0.12-0.13,0.03,0.01l0.01-0.06
				c0.02,0.01,0.02,0.03,0.05,0.05c-0.03-0.05,0.12-0.02,0.07-0.09c0.08-0.03,0.21-0.33,0.26-0.54c0.05,0.04,0.1-0.02,0.11,0.04
				c0.06-0.08,0.07-0.1,0.11-0.23c-0.05-0.01-0.08-0.06,0.02-0.01c0.02-0.17,0.35-0.11,0.32-0.28l0,0.01
				c0.06-0.2,0.09-0.09,0.6-0.52c-0.02-0.02-0.09-0.07,0.03-0.04c-0.36-0.04,0.42-0.24,0.93-0.98c0,0.02,0.02,0.04,0.01,0.05
				c0.07,0.02,0.1-0.08,0.07-0.12c-0.07,0.02,0.24-0.1,0.34-0.36c0.07,0.09,0.14,0,0.14-0.07c0.01,0.03,0.05,0.01,0.07,0.05
				c0.03-0.03,0.05-0.07,0.01-0.11c0.15,0.05,0.07-0.19,0.21-0.09c0.13-0.18,0-0.14,0.62-0.6c0.01,0.01,0,0.02,0,0.04
				c0.01-0.01,0.05-0.01,0.02-0.05l-0.01,0.02c-0.07-0.04-0.03-0.17-0.05-0.21c0.06,0.14,0.11-0.03,0.17,0.15
				c0.01-0.02,0-0.05-0.02-0.07c0.19-0.03-0.01,0.02,0.49-0.32l-0.04-0.02c0.04-0.03,0.04-0.11,0.09-0.11l-0.01,0.05l0.07-0.07
				l-0.06,0c0.04-0.11-0.11-0.12,0.21-0.08c-0.02-0.08,0.09-0.08,0.07-0.17c0,0.02,0.03,0.06,0.04,0.07l-0.02-0.06
				c0.07,0.02,0.04-0.04,0.22-0.08l-0.02,0.03l0.05,0.02l-0.02-0.05c0.04,0.09,0.1-0.21,0.38-0.28c-0.01,0.02,0.02,0.07,0.02,0.06
				c0.07-0.02,0.11-0.1,0.18-0.2c-0.05,0.05,0.19,0.06,0.14-0.1c0.14,0.01,0.2,0.15,0.41-0.33c-0.02,0.06,0.05,0.08,0.08,0.1
				c-0.01-0.01-0.01-0.03-0.03-0.05c0.1,0.11-0.01-0.1,0.06-0.05c0.01-0.01,0.1-0.14,0.07,0.01c0.09-0.08,0.01-0.07,0.13-0.03
				c-0.01,0.01,0,0-0.06,0.18c0-0.04-0.08-0.01-0.01-0.1c-0.2,0.27-0.16,0.52-0.29,0.6l0.01-0.03c-0.49,0.85-0.22,0.83-0.98,2.51
				c-0.1-0.05,0.08-0.08-0.14,0.56c-0.03-0.03-0.2,0.52-0.3,0.78l-0.01-0.01c0.03,0.16,0.04,0.03-0.1,0.41c0-0.01,0-0.02,0-0.04
				c0.01,0.01-0.01,0.06-0.05,0.2c0-0.02,0-0.03-0.01-0.06c-0.2,0.9-0.18,0.38-0.44,2.01l-0.01-0.06c-0.08,0.21,0.1,0.28,0,0.47
				l-0.01-0.04c-0.07,0.17,0.13,0.46-0.33,2.77c0.03,0,0.05,0.13,0.01,0.39c0.22-0.11-0.17,0.05,0.06,0.82
				c-0.03-0.01-0.05-0.08-0.08-0.14c0.06,0.65,0.1,0.11-0.24,2.44c0.27-0.15-0.39,0.51-0.18,0.45c-0.15,0.27-0.17,0.44-0.33,0.7
				c0.07,0-0.23,0.88-0.37,0.55c-0.11,0.26-0.33,0.4-0.44,0.51c0.03-0.04,0.05-0.08,0.07-0.13c-0.04,0.08-0.09,0.13-0.13,0.18
				l0.1-0.02c-0.06,0.06-0.11,0.11-0.13,0.09c-0.04,0.21,0.15,0.14-0.27,0.42c0.27-0.21-0.03-0.05,0.1-0.22
				c-0.32,0.21-0.16,0.05-0.45,0.46c0.01-0.06-0.12-0.02-0.16,0.01c0.09-0.06,0.05,0.13-0.3,0.32l0.13-0.05
				c-0.15,0.2-0.56,0.28-1.51,0.29c0.08,0.05,0.1,0.05-0.1,0.1c0.16-0.01,0.31-0.04,0.47-0.08c-0.63,0.18-0.08,0.13-1.59-0.11
				l0.12,0.07c-0.39-0.07-0.34-0.12-1.51-1.02c0.1,0.06,0.05-0.09,0.2,0.08c-0.06-0.09-0.11-0.18-0.14-0.16
				c0.06,0.02-0.09-0.1-0.2-0.38c0,0.04,0,0.02,0.04,0.1c-0.05-0.03-0.02-0.17-0.26-0.3c0.05-0.05-0.01-0.48-0.06-1.01
				c0.02,0.09,0.11,0.09,0.07-0.05l-0.07,0.04c-0.1-0.17,0.13-0.58-0.09-1.65c-0.16,0.1,0.28-0.43-0.82,0.57l0.07-0.07
				c-0.3,0.16-0.02,0.18-1.37,1.11c0-0.03,0-0.05,0-0.07c-0.02,0.07-0.1,0.19-0.45,0.1c0.08-0.02,0.15-0.04,0.23-0.07
				c-0.14-0.04-0.3,0.09-0.43,0.09l0.01-0.04c-0.35,0.05-0.36,0.16-0.76,0.1l0.04,0.04c-0.47-0.05-0.28-0.11-0.75-0.37
				c0.08,0.04,0.16,0.08,0.23,0.09c-0.13-0.05-0.37-0.24-0.45-0.26c0.05,0.03,0.11,0.06,0.14,0.11c-0.73-1.02-0.51-0.3-0.14-2.87
				c-0.23,0.12,0.07-0.16-0.08-0.19c0.08-0.1,0.1-0.3,0.22-0.09c-0.1,0.05,0.05-0.21-0.12-0.1c0.22-0.33,0.49-0.39,0.92-0.94
				c-0.07,0.08-0.19-0.04-0.19-0.13c-0.01,0.05-0.01,0.12-0.03,0.2c-0.19-0.17,0.13-0.06-0.09-0.36c0.03,0,0.05,0,0.06,0
				c-0.56-0.14,0.33-0.13-2.74,0.55l0.04-0.04c-0.16,0.04-0.06,0.01-0.69,0.07l0.11-0.04c-2.37-0.06-1.18,0.35-0.59-4.24
				c0.33-0.18,0.5-0.26,0.89-0.52l-0.06,0.09c0.66-0.58,0.28-0.28,1.71-1.08c0,0.02,0,0.05-0.06,0.09c0.19-0.1,0.77-0.33,0.63-0.42
				c0.05,0.08,0.42-0.21,0.63-0.36l0,0.08c0.01-0.03,0.25-0.2,0.78-0.53l-0.07,0.09c0.34-0.17,0.12-0.25,0.76-0.35
				c-0.1,0.05,0.25-0.24,0.21-0.13c0.4-0.09-0.05-0.07,0.84-0.35c-0.29,0.13-0.52,0.23-0.5,0.21c0.03,0.06,0.26-0.04,0.12,0.13
				c0.18-0.1-0.03-0.04,0.72-0.43l-0.02,0.02c0.31-0.15,0.19-0.05,0.73-0.27c-0.04-0.06,0.12-0.11-0.05-0.11
				c0.04-0.01-0.05,0.02-0.14,0.08l0.01-0.01c-0.08,0.03-0.18,0.08-0.29,0.13c0.73-0.49,0.62-0.15,2.21-0.93
				c-0.05,0.04-0.13,0.06-0.15,0.09c0.03,0.02,0.47-0.37,0.86-0.36l-0.04,0.04c0.75-0.12,1.07-0.64,1.92-0.72
				c-0.06,0.01-0.09,0.01-0.1,0c0.42,0.04,2.15-0.82,4.69-1.3l-0.07-0.03c-0.48-0.03,2.66-0.24,1.24-0.68l0.24-0.05
				c-0.33-0.06-1.03,0.43-1.06,0.21c-0.08,0.08-0.3-0.07-1.28,0.38c0.2-0.19-0.49,0.12-0.46-0.03c-0.14,0.16-0.62,0.21-0.91,0.37
				c0.01-0.01,0.04-0.04,0.07-0.04c-0.04-0.21-0.86,0.56-0.74,0.11c0.03,0.07-0.35,0.18-0.26,0.22c-0.18-0.01-0.15,0.02-0.25-0.04
				c0.16,0.08-0.86,0.38-0.51,0.09c-0.57,0.47-0.01-0.06-1.75,0.75c0.07,0.12,0.11,0.12-0.18,0.2c-0.39-0.34,0.03-0.17-2.16,0.71
				l0.06-0.13c-1.01,0.63-2.03,0.68-5.2,2.43l0.06-0.06c-0.29,0.12-0.24,0.29-0.52,0.37l0.04-0.04c-0.22,0.12-1.98,0.93-3.31,1.89
				l-0.04-0.06c0.02,0.02-0.13,0.14-0.45,0.29c0.25,0.12-0.17-0.13-0.92,0.7c-0.01-0.03,0.07-0.11,0.12-0.18
				c-0.43,0.46-0.1,0.04-0.82,0.9l-0.01-0.08c-0.31,0.4-0.5,1.07-0.32,1.67c-0.5-0.02,2.9,2.68,6.08,1.4
				c-0.11,0.33,0.05,0.18-0.75,1.98l0.05-0.03c0.03,0.14-0.04,0.16-0.05,0.27c0.35,0.01-0.06,2.93,3.06,2.26l-0.13,0.11
				c0.14-0.07,1.16-0.35,2.01-1.2c-0.06,0.21,0.13-0.14,0.07,0.08c0.03-0.02-0.01,0.07,0.06,0.02c0.02,0.45-0.08,0.24,0.17,1.23
				c-0.12,0,0.03,0.56,0.17,0.72c-0.06-0.05,0.03,0.18-0.04,0.12c0.09,0.22,0.23,0.39,0.25,0.33c0.65,0.86,0.42,1.01,2.51,1.4
				c-0.02,0.01-0.03,0.01-0.04,0.01c0.88,0,0.62,0.23,2.25-0.45c0,0.01-0.04,0.05-0.08,0.08c0.25-0.2-0.17,0.13,0.34-0.46
				c-0.05,0.22,0.41-0.26,0.31,0.04c0.05-0.05,0.09-0.12,0.06-0.13c0.08-0.05,0.11-0.06,0.07,0.03c0.25-0.26,0.12-0.21,0.34-0.43
				c0.02,0.02-0.02,0.07-0.02,0.11c0.08-0.15,0.15-0.3,0.33-0.49c0,0.02,0.04,0.01-0.01,0.07c0.28-0.37,0.35-0.7,0.61-1.11
				c0.01,0.09,0.09,0.15,0.29-0.38l-0.08,0.08c0.1-0.21,0.11-0.43,0.2-0.61l-0.02,0.18l0.12-0.42l-0.11,0.22
				c0.03-0.24-0.12-0.01,0-0.3c-0.01,0.06,0.07-0.22,0.15-0.32v0c0.2-0.72,0.24,1.01,1.26,2.95c0-0.11,0.08-0.08,0.03,0.07
				c0.28,0.05-0.07,0.72,1.24,1.02l-0.03,0.01C553.78,183.5,554.29,183.61,555.13,182.25z M552.36,170.29
				c0.02,0.05,0.04,0.05-0.03,0.11C552.34,170.38,552.35,170.4,552.36,170.29z M544.66,173.69c0.01-0.02,0.03-0.02,0.03,0.03
				C544.67,173.7,544.66,173.7,544.66,173.69z M545.13,173.86c0-0.01,0.01-0.01,0.01-0.03
				C545.14,173.85,545.14,173.84,545.13,173.86z M545.14,173.74c0.01,0.01,0.04,0,0.06-0.01
				C545.19,173.73,545.18,173.82,545.14,173.74z M553.9,165.73c-0.01-0.02-0.03-0.01,0-0.08
				C553.9,165.67,553.91,165.69,553.9,165.73z M553.86,165.54c0.01,0.04,0.02,0.09,0.01,0.16
				C553.85,165.66,553.8,165.66,553.86,165.54z M553.47,165.98c-0.01,0.01-0.02,0.02-0.03,0.03
				C553.43,165.99,553.45,165.99,553.47,165.98z M542.85,168.39c-0.04,0.06-0.02,0.06-0.14,0.08
				C542.75,168.45,542.72,168.48,542.85,168.39z M542.21,168.17c-0.02,0.01-0.06,0.03-0.12,0.06
				C542.14,168.2,542.1,168.23,542.21,168.17z M541.79,168.42c0.02-0.03-0.1,0.01-0.18,0.03
				C541.57,168.49,541.86,168.28,541.79,168.42z M546.93,183.49c-0.15,0-0.25-0.01-0.33-0.02c0.14-0.01,0.3-0.07,0.38-0.07
				C546.85,183.47,546.86,183.45,546.93,183.49z M551.2,175.86C551.19,175.85,551.19,175.85,551.2,175.86c0-0.01,0-0.01,0-0.01
				L551.2,175.86z M552.37,168.71c-0.01,0.34,0.04,0.07,0.1,0.12c0.01-0.01,0.02-0.02,0.02-0.04c-0.05,0.13-0.11,0.27-0.18,0.42
				C552.27,169.01,552.28,169.18,552.37,168.71z"
                />
                <path
                  className="st10"
                  d="M557.73,163.46l-0.05-0.03l0.03-0.03c-0.05-0.03-0.03,0.02-0.03,0.06c-0.02-0.02-0.04-0.03-0.05-0.04
				l0.03,0.12c-0.03-0.03-0.04-0.08-0.08-0.08c0.01,0.05,0.08,0.07,0.07,0.12c-0.05-0.03-0.07-0.06-0.07-0.01
				c-0.02-0.02-0.03-0.04-0.03-0.06c0,0.04-0.04,0.05-0.01,0.11c-0.06-0.03-0.05,0.07-0.12-0.02c0.01,0.03,0.02,0.09,0.01,0.11
				c-0.05-0.13-0.07,0.05-0.15-0.07c0.05,0.11-0.05,0.11-0.05,0.2c0-0.01-0.01-0.03,0-0.03c-0.03,0.01-0.06-0.01-0.09-0.01
				c0.06,0.08,0.04,0.13,0.07,0.19c-0.09-0.04-0.06-0.1-0.13-0.16c0.05,0.06,0.01,0.1,0.06,0.14c-0.06-0.02-0.04,0-0.1-0.05
				c0.08,0.09-0.01,0.03,0.03,0.11c-0.07-0.02-0.09-0.08-0.09-0.02c-0.06-0.16-0.16,0.15-0.1,0.39c0.01-0.01-0.01-0.03-0.02-0.04
				l0,0.03c-0.1-0.09,0.01-0.12-0.13-0.04c0.3-0.36-0.09-0.16-1.26,1.62l-0.09-0.03c0.07,0.15-0.06-0.12-0.08,0.21
				c-0.02-0.02-0.02-0.04-0.03-0.06c0.08,0.1-0.11-0.03,0.01,0.08c-0.05,0-0.08,0.05-0.09,0.09l-0.04-0.03
				c-0.05,0.06-0.06,0.16-0.11,0.23c0.04-0.01,0.11,0.04,0.11,0.06l-0.08-0.03c0.04,0.04,0.14,0.11-0.03,0l0,0.05
				c-0.02-0.01-0.03-0.02-0.05-0.04c0.04,0.04-0.11,0.02-0.04,0.08c-0.03-0.33-0.29,0.9-0.73,1.48c0.02,0.01,0.11,0.03-0.01,0.04
				c0.21-0.05-0.28,0.64-0.21,0.61c0.05,0.16-0.09,0.26-0.04,0.42c-0.02-0.01-0.04-0.02-0.04-0.03c0,0.02-0.02,0.13-0.04,0.5
				c-0.11-0.03-0.11,0.06-0.06,0.11c-0.03-0.02-0.04,0.02-0.08,0c-0.01,0.04,0.01,0.07,0.07,0.08c-0.15,0.04,0.08,0.15-0.1,0.15
				c0.02,0.18,0.1-0.03-0.02,0.68c-0.01,0-0.02-0.01-0.03-0.02c0,0.02-0.02,0.03,0.03,0.04l-0.01-0.02
				c0.08-0.01,0.15,0.08,0.18,0.09c-0.14-0.05-0.05,0.07-0.23,0.01c0.01,0.01,0.03,0.03,0.06,0.03c-0.1,0.11-0.02,0.02-0.11,0.4
				c0.01-0.02-0.02,0.08,0.07,0.03c-0.04-0.02,0.08,0.14-0.01,0.08l0.01,0.07c0.04-0.02,0.04-0.04,0.13,0.02
				c-0.06,0.04-0.03,0.07-0.15,0.11c0.08,0.02,0.02,0.09,0.1,0.12c-0.02-0.01-0.07-0.01-0.08-0.01l0.06,0.02
				c-0.05,0.03,0.01,0.03-0.06,0.17l-0.01-0.03l-0.04,0.02l0.05,0.01c-0.1-0.01,0.12,0.18,0.03,0.36c-0.01-0.01-0.07-0.01-0.06-0.01
				c-0.02,0.06,0.03,0.12,0.08,0.2c-0.01-0.04-0.13,0.13,0.01,0.13c-0.07,0.08-0.24,0.1,0.1,0.39c-0.04-0.03-0.09,0.01-0.12,0.02
				c0.02,0,0.04,0,0.06,0c-0.15,0.04,0.1,0.03,0.02,0.06c0.01,0.02,0.08,0.11-0.04,0.04c0.03,0.11,0.01-0.03-0.07,0.17
				c0.21-0.02,0.01,0,0.18,0.11c-0.11-0.03-0.02-0.03-0.2,0.04c0.06,0.02-0.04,0.05,0.05,0.08c-0.02,0,0,0.03-0.06,0.03
				c0.25-0.04-0.1,0.12,0.3,0.3c-0.03,0.04-0.15-0.05-0.08,0.09c-0.15,0,0.1,0.09-0.1,0.05c0.03,0.03,0.08,0.05,0.1,0.07
				c-0.04,0.02-0.07-0.01-0.1-0.01c0.01-0.01,0.03,0.06,0.12,0.05c-0.02-0.04-0.04-0.15,0.08-0.15c-0.01,0.02-0.06-0.11,0.06-0.12
				c0.02-0.09,0.02-0.15-0.18-0.14l0.18-0.06l-0.08,0c0-0.01,0-0.02,0.04-0.03c-0.06-0.02-0.03,0.01-0.08,0
				c0.06-0.05-0.04-0.1,0.08-0.12c-0.06-0.01-0.04,0.04-0.08,0.01c0.15,0-0.08-0.1,0.12-0.09c-0.13-0.07,0.1-0.11-0.08-0.2
				c0.04,0.01-0.02-0.02,0.09-0.14c-0.09,0-0.08-0.05-0.1-0.08c0.05,0,0.08,0,0.1,0.02c-0.01-0.03,0-0.03-0.06-0.07l0.07,0
				c-0.01-0.04-0.09-0.08-0.05-0.14c-0.06,0-0.18,0.05-0.22,0c0.03,0,0.09-0.01,0.11,0.01c0.04-0.07-0.04,0.03,0.15-0.09
				c-0.03,0-0.15-0.03,0.01-0.06c-0.03-0.01,0-0.04-0.07-0.04c0-0.01,0.03-0.02,0.05-0.02c-0.04-0.02,0,0-0.1-0.07v0
				c-0.14,0.05,0.08-0.15,0.06-0.52c0.02,0.05,0.11-0.1,0.02-0.02c0.13-0.2-0.16-0.34,0.14-0.53c-0.03-0.03-0.05-0.02-0.06,0
				c0.05-0.05,0-0.08,0.04-0.13c-0.07,0.01-0.16-0.2,0.07-0.11c-0.05-0.05,0.05-0.08,0.06-0.11l0.02,0.03
				c-0.01-0.02-0.01-0.03-0.01-0.04l-0.06,0.01c0.01-0.01,0.01-0.03,0.04-0.02c-0.04-0.02-0.1,0-0.15-0.04
				c0.03-0.02-0.02-0.06,0.05-0.06c-0.03,0.06,0.08,0,0.09,0.06c0.05-0.08,0.09-0.02-0.05-0.13c0.04,0.01,0.09,0,0.08-0.01
				c0,0.02-0.07,0-0.11-0.03c0.04-0.03-0.02-0.05-0.01-0.07c-0.03,0.02-0.03,0.03-0.06-0.04c0.03,0,0.02,0.03,0.04,0.02
				c-0.07-0.04,0.09-0.06,0.04-0.11c0.03-0.01,0.08,0.01,0.1,0c-0.09-0.05,0.13-0.06-0.07-0.13l0.03,0.09
				c-0.11-0.09-0.15,0,0.03-0.28c-0.04,0.01-0.06,0.04-0.05-0.06c0.05,0.01,0.07,0,0.1,0c-0.04-0.01,0.01-0.05-0.08-0.08
				c0.07-0.04,0.14-0.01-0.03-0.17c0.05,0.03,0.09,0.01,0.12,0.06c-0.03-0.07,0.01,0.02-0.14-0.2c0.13-0.09,0.04,0.03,0.23-0.8
				c0.06,0.03,0.02,0.03,0.09,0.04c-0.17,0.05,0.23-0.12,0.06-0.47c0.06,0.05,0.08,0.11,0.11,0.16c0.01-0.02,0.02-0.08-0.02-0.11
				c-0.01,0.03-0.01,0.04-0.06-0.12c0.18,0.07,0.09-0.11,0.24-0.09c-0.03-0.11,0.09-0.15,0.12-0.25c-0.07,0-0.18-0.17,0.02-0.05
				c-0.06,0.01-0.07-0.05-0.12-0.05c0.04-0.03,0.13-0.04,0.26,0c-0.05-0.02-0.07-0.05-0.03-0.07c-0.06,0.05-0.02-0.14,0.05-0.05
				c-0.01-0.05,0.05-0.13-0.04-0.21c0.02,0,0.06,0.01,0.06,0.03c0.12-0.1-0.12-0.2,0.59-1.06c0.01,0.01-0.12-0.06-0.02-0.1
				l-0.01,0.01c0.09,0.04,0.09-0.02,0.11-0.04l-0.11-0.08c0.21,0.03,0.08-0.24,0.25-0.19c-0.02-0.04,0-0.06-0.01-0.1
				c0.07,0.02,0.07,0.08,0.16-0.09l0.03,0.04c0.01-0.02,0.13-0.14,0.15-0.43c0.08,0.06,0.05-0.06,0.05-0.11
				c0.13,0.14-0.03-0.06,0.29-0.36c-0.04,0.04-0.07,0.07-0.07,0.06c0.01,0.07,0.31-0.05,0.18-0.21c0.01,0,0,0,0,0.03l0-0.01
				c-0.01,0.01-0.02,0.03-0.03,0.04c-0.02-0.04-0.03-0.07,0.02-0.06c-0.03-0.04-0.05-0.08-0.07-0.1c0.07-0.02,0.22,0.09,0.29-0.27
				c0.29,0.08,0.07,0.08,0.3-0.22c-0.01,0-0.02,0-0.03-0.01c0.08-0.05,0.2,0.01,0.39-0.39l0.06,0.05c-0.19-0.05,0.28-0.08,0.33-0.49
				c0,0.01,0,0.01,0,0.01c-0.05-0.05-0.12-0.12-0.23-0.22L557.73,163.46z M556.25,164.94c0.02-0.01,0.03-0.05,0.06,0.01
				C556.3,164.93,556.28,164.94,556.25,164.94z M556.75,165.11c0,0.01-0.03,0.02-0.02-0.03
				C556.73,165.1,556.74,165.11,556.75,165.11z"
                />
                <path
                  className="st10"
                  d="M578.3,264.21c-0.02-0.15,0.01,0.04-0.12-0.4c0.1-0.09,0.17-0.21,0.05-0.86c0.02,0,0.02,0,0.02,0.01
				c-0.13-0.28,0.05-0.02-0.09-0.49l0.02,0.02c0.02-0.1-0.14-0.15-0.19-0.27c0,0,0.02,0,0.02-0.01c-0.11-0.04-0.23-0.15-0.32-0.23
				c0.02-0.01,0.03-0.02,0.04-0.02c-0.05,0.01-0.11,0-0.07-0.19c0.32,0.08,0.16,0.06-0.74-0.49c0.09-0.03,0.21,0.02,0.3,0.03
				c-0.05-0.04-0.17-0.12-0.22-0.09c0.02,0.01,0.06,0,0.07,0.03c-0.48-0.16-0.03,0.02-1.11-0.75c-0.03,0.13-0.1,0.14-0.18,0.08
				c0.02-0.03,0.12-0.06,0.05-0.13c0.05,0.07-0.07,0.05-0.08,0.1c-0.07-0.07-0.14-0.17-0.17-0.29c0.01,0.06-0.06,0.06-0.14,0.03
				l0.1,0.09c-0.2,0.05-0.15-0.16-0.36-0.09l0.07-0.04c-0.1,0.01-0.41,0.01-0.48,0.17c-0.02-0.01-0.06-0.05-0.01-0.07
				c-0.27,0.04-0.46,0.33-0.64,0.39l-0.01-0.04c-0.06,0.13-0.08,0-0.2,0.25l0-0.07c-0.2,0.15-0.13,0.34-0.2,0.51
				c-0.09-0.05-0.21,0.08-0.26,0.16l-0.01-0.01c-0.17,0.31,0.14,0.04-0.36,1.32c0.06,0.56,0.06,0.05-0.22,1.62l-0.05-0.04
				c0.14,0.31,0.09,0.1,0.03,0.78c-0.02,0-0.05-0.01-0.05-0.04c-0.01,0.08-0.1,0.31,0.05,0.29c-0.1-0.01-0.02,0.18,0.01,0.28
				l-0.07-0.02c0.02,0,0.06,0.1,0.1,0.36l-0.04-0.04c-0.02,0.27-0.01,0.14-0.05,0.75c0.01-0.12,0.01-0.22,0.02-0.21
				c-0.24,0.05-0.2-0.13-0.12,0.55c-0.02-0.03,0,0-0.07-0.08l0,0c-0.13-0.09-0.03-0.48-0.29-1.1c0.06,0.07,0.03-0.04,0.05,0.07
				c-0.01-0.11,0.02-0.14-0.08-0.23c0.07,0.02,0.05-0.1,0.02-0.14l-0.02,0.08c-0.02-0.31,0.08-0.07-0.12-0.68
				c-0.19,0.31-0.23-0.54-0.16-0.48l-0.03,0c0.01-0.06-0.03-0.19,0.03-0.17c-0.05-0.06-0.06-0.02-0.05,0.04
				c0-0.14-0.06-0.19-0.07-0.33c-0.01,0.06-0.35-0.32-0.03-0.29c-0.09-0.1-0.03-0.23-0.04-0.3l0.04,0.05
				c-0.02-0.03-0.03-0.06-0.04-0.09l-0.05,0.05c0-0.04-0.01-0.07,0.01-0.08c-0.07-0.05-0.2,0.09-0.17-0.18
				c0.01,0.2,0.32-0.02-0.03-0.19c0.05,0,0.07-0.06,0.05-0.08c0.03,0.04-0.09,0.06-0.19-0.14l0,0.06c-0.05,0-0.06-0.05-0.1-0.11
				c0.01,0.08,0.05-0.22-0.04-0.59c-0.04,0.06-0.02,0.06-0.11,0l0.12,0.18l-0.13-0.09c0.02,0.06,0.09,0.11,0.09,0.15
				c-0.07-0.04-0.05-0.04-0.09,0.01c-0.14-0.32,0.23-0.21-0.2-0.72l0.01,0.07c-0.06,0-0.06-0.11-0.12-0.15
				c0.05-0.02,0.04-0.06,0.06-0.1c-0.03,0.02-0.07-0.13-0.17-0.1c-0.02-0.19,0.06-0.14-0.28-0.33c0.07,0.01,0.06-0.07,0.15,0
				c-0.03-0.04-0.05-0.09-0.08-0.07c0.08,0.06-0.1-0.2-0.31-0.16c0-0.15-0.03-0.28-0.61-0.71c0.01-0.01,0.01-0.02,0.03,0
				c-0.3-0.13,0.02-0.04-0.43-0.33l0.03,0c-0.06-0.08-0.22-0.03-0.35-0.09c0,0,0.01-0.01,0.01-0.02c-0.12,0.05-0.29,0.02-0.43,0.03
				c0.01-0.02,0.01-0.03,0.01-0.04c-0.03,0.04-0.1,0.07-0.2-0.09l0.12,0.01c-0.29-0.15-0.43,0-1.09,0.24
				c0.03-0.1,0.16-0.17,0.24-0.24c-0.07,0.01-0.23,0.07-0.24,0.13c0.02-0.02,0.05-0.05,0.08-0.05c-0.46,0.4-0.04-0.03-1.2,1.03
				c0.14,0.02,0.14,0.1,0.08,0.2c-0.03-0.02-0.06-0.13-0.13-0.04c0.07-0.06,0.05,0.08,0.1,0.07c-0.07,0.09-0.18,0.18-0.29,0.22
				c0.06-0.01,0.07,0.06,0.04,0.15l0.09-0.11c0.05,0.21-0.15,0.16-0.08,0.38l-0.05-0.07c0.02,0.11,0,0.39,0.13,0.48
				c-0.02,0.02-0.06,0.05-0.07,0c0,0.25,0.2,0.51,0.16,0.7l-0.04-0.01c0.08,0.12-0.03,0.06,0.11,0.29l-0.05-0.04
				c0.01,0.23,0.19,0.31,0.27,0.48c-0.11,0.03-0.09,0.21-0.08,0.3l-0.02,0c0.05,0.36,0.19-0.07,0.5,1.36
				c0.01-0.01,0.14-0.03,0.14,0.13c-0.02-0.04-0.11,0.3,0.16,0.22c-0.09,0.14,0.18,0.31,0.5,1.28l-0.06-0.01
				c0.32,0.21,0.13,0.06,0.52,0.69c-0.01,0.01-0.04,0.02-0.06-0.01c0.05,0.08,0.12,0.34,0.23,0.23c-0.08,0.05,0.1,0.17,0.19,0.24
				l-0.07,0.02c0.02-0.01,0.12,0.06,0.33,0.25l-0.06-0.01c0.08,0.13,0.19-0.02,0.19,0.29c-0.03-0.04,0.18,0.06,0.08,0.07
				c0.03,0.18,0.07-0.04,0.21,0.32c-0.08-0.11-0.14-0.2-0.13-0.19c-0.05,0.04,0,0.13-0.12,0.12c0.02,0.08,0.24,0.05,0.46,0.5
				c0.05-0.05,0.09,0.01,0.09-0.07c0.01,0.02-0.02-0.02-0.06-0.04l0.01,0c-0.02-0.03-0.05-0.06-0.09-0.1
				c0.05,0.01,0.11,0.03,0.11,0.1c0.05-0.01,0.11-0.01,0.13-0.01c-0.06,0.26-0.03,0.25,0.43,0.67c-0.03-0.01-0.05-0.04-0.07-0.03
				c0.09,0.05,0.19,0.2,0.28,0.15c0.05,0.11-0.11,0.01,0.01,0.12l-0.04,0c0.12,0.33,0.52,0.21,0.64,0.58
				c-0.01-0.02-0.01-0.04-0.01-0.05c0.48,0.29-0.01-0.01,2.31,1.34c-0.04-0.06-0.03-0.04-0.29-0.11c0.14-0.21-0.19,0.01-0.06-0.15
				c-0.07,0-0.14-0.08-0.2-0.05c-0.01-0.09-0.17-0.11-0.1-0.19c-0.03,0.01-0.14,0.01-0.18-0.02c0.16-0.04-0.16-0.13-0.05-0.22
				c-0.13,0.05-0.24-0.13-0.38-0.16c0.01,0,0.03-0.01,0.04,0c-0.05-0.05-0.04-0.1-0.06-0.14c-0.07,0.07-0.17,0.01-0.26,0.02
				c-0.01-0.13,0.12-0.05,0.16-0.14c-0.05,0.06-0.17-0.04-0.19,0.03c-0.01-0.09-0.03-0.06,0-0.14c-0.05,0.13-0.35-0.19-0.11-0.19
				c-0.27,0.08-0.28-0.39-0.82-0.39c0.44-0.41,0.12,0-1.07-1.38c0.16,0.2-0.2-0.46-0.82-1.24l0.03,0.01
				c-0.04-0.12-0.2-0.04-0.2-0.18l0.02,0.01c-0.05-0.25-0.08-0.17-0.77-1.59c-0.14,0.03,0.18,0.04-0.21-0.4
				c0.03-0.01,0.06,0.03,0.09,0.05c-0.12-0.09,0.06-0.05-0.05-0.14c-0.01,0.02-0.05,0.01-0.06,0.01c0.02-0.07-0.04-0.2-0.09-0.29
				l0.06,0c-0.06-0.17-0.16-0.35-0.24-0.54c0.09,0.04-0.23,0.08-0.05-0.01c-0.06-0.01-0.19,0.01,0.02-0.03l-0.06-0.08
				c0.02-0.01,0.04,0,0.07,0c-0.17-0.43,0.04-0.05-0.53-1.19c0.06-0.02,0.01-0.17,0.08-0.1c-0.14-0.46-0.29-0.36-0.2-1.21l0.01,0.01
				c0.59-0.42-0.76-1.25,1.64-1.67l-0.06,0.08c0.29-0.08,0.56,0.12,0.84,0.16c-0.02,0.01-0.04,0.03-0.06,0.02
				c0.02,0.08,0.19,0.11,0.22,0.07c0.09,0.1-0.06-0.03,0.68,0.52c-0.09,0.07,0.05,0.21,0.14,0.23c-0.04,0,0.01,0.07-0.05,0.08
				c0.05,0.06,0.11,0.11,0.15,0.07c0.04,0.29,0.05,0.15,0.59,0.93c-0.33-0.09-0.25-0.41,0.3,0.82c-0.01,0-0.03-0.01-0.04-0.03
				c0.09,0.08-0.02-0.02,0.27,0.12c-0.16-0.01,0.03,0.19-0.18,0.16c0.02,0.02,0.05,0.04,0.08,0.03c0,0.04-0.01,0.06-0.06,0.03
				c0.07,0.12,0.11,0.06,0.14,0.18c-0.02,0.01-0.04-0.01-0.07-0.02c0.07,0.04,0.13,0.09,0.14,0.2c-0.02,0-0.03,0.02-0.04-0.01
				c0.06,0.17,0.21,0.26,0.22,0.46c-0.12-0.03-0.01,0.08-0.1,0.11l0.06,0.1l0.03-0.05c0.03,0.09,0.11,0.15,0.11,0.24l-0.06-0.06
				l0.07,0.16l0.01-0.11c0.22,0.21,0.09-0.04,0.25,0.65c-0.11-0.03-0.1,0.15,0.04,0.18c-0.01-0.01-0.02,0-0.03-0.02
				c0.06,0.17,0.06,0.24,0.06,0.29l-0.03-0.05l-0.02,0.06l0.05,0.01c-0.04,0.21,0.1-0.07,0.44,1.28l-0.04-0.01
				c0.14,0.33,0.03,0.31,0.3,1.06c-0.06-0.05,0.07,0.2,0.16,0.15c-0.04,0-0.05,0.07-0.07,0.1c0.01-0.01,0.02-0.01,0.03-0.01
				c-0.03,0.18,0.02,0.02,0.1,0.52v-0.01c0,0.08,0.01,0.03-0.03,0.19c0.01-0.02,0.02-0.03,0.03-0.04c0.12,0.54,0.01-0.02,0.6,2.36
				c0.02-0.07,0.01-0.05-0.11-0.26c0.23-0.03-0.11-0.17,0.07-0.13l-0.01-0.01c0,0,0.01,0,0.02,0.01c-0.04-0.05-0.03-0.14-0.09-0.16
				c0.04-0.04,0.02-0.1,0.02-0.14c0.05,0.06,0.21,0.23,0.03,0.12c0.01-0.01,0.05,0.11,0.12,0.14c0.03-0.12,0.16,0-0.07-0.36
				c0.17,0.07,0.18-0.06,0.05-0.29c0.03,0.01,0.07-0.03,0.07,0.02c0.02-0.15-0.14-0.19-0.08-0.26l0.01,0.01
				c0-0.17-0.21,0.07-0.1-0.18c-0.01,0-0.01-0.01-0.02-0.01c0.01-0.01,0.05-0.09-0.07-0.16c0.03,0.02,0.06,0.04,0.1,0.03
				c0,0.03-0.21-0.12-0.05-0.2c-0.07-0.05-0.15-0.13,0.05-0.19c-0.22-0.06,0.11-0.28-0.32-0.78c0,0.03,0.02,0.03,0.04,0.04
				l-0.03-0.05c0.15,0.23,0.14-0.22,0.09-0.03c0.41,0.08-0.07-0.51,0.01-1.55c-0.02,0.27,0.15-0.45,0.12-1.35l0.02,0.03
				c0.04-0.11-0.14-0.14-0.06-0.24l0.01,0.02c0.1-0.19,0.05-0.19,0.27-1.59c-0.14-0.05,0.14,0.12,0.03-0.41
				c0.03,0.01,0.03,0.05,0.05,0.08c-0.06-0.13,0.08-0.01,0.03-0.13c-0.02,0.01-0.04-0.02-0.06-0.02c0.06-0.05,0.08-0.17,0.07-0.27
				l0.05,0.03c0.04-0.17,0.03-0.35,0.08-0.52c0.05,0.08-0.23-0.06-0.04-0.03c-0.04-0.04-0.16-0.09,0.03-0.01l-0.01-0.09
				c0.02,0,0.03,0.02,0.06,0.04c0.09-0.38,0.04-0.02,0.29-1.07c0.06,0.03,0.12-0.07,0.12,0.01c0.07-0.1,0.08-0.12,0.2-0.3
				c-0.06-0.01-0.07-0.07,0.03-0.01c0.12-0.21,0.44-0.02,0.55-0.15l-0.01,0.01c0.42-0.01-0.02-0.01,1.15,0.43
				c-0.16-0.32,0.73,0.34,1.53,1.38c-0.02-0.01-0.05,0-0.06-0.02c-0.03,0.07,0.06,0.18,0.11,0.17c0.01,0.13-0.03-0.06,0.12,0.75
				c-0.11-0.01-0.1,0.17-0.06,0.24c-0.03-0.03-0.04,0.05-0.09,0.02c-0.01,0.07,0,0.14,0.06,0.14c-0.17,0.21-0.08,0.12-0.19,0.98
				c-0.17-0.26,0.05-0.45-0.29,0.74c-0.01,0-0.01-0.03-0.02-0.05c0.02,0.11,0-0.02,0.14,0.25c-0.12-0.11-0.1,0.15-0.24,0
				c0,0.02,0.01,0.06,0.05,0.07c-0.02,0.03-0.04,0.03-0.07-0.01c-0.02,0.13,0.05,0.1,0.01,0.21c-0.02-0.01-0.02-0.03-0.05-0.06
				c0.02,0.07,0.05,0.14-0.01,0.22c-0.01-0.01-0.03-0.01-0.02-0.03c-0.05,0.15,0.02,0.31-0.1,0.46c-0.08-0.09-0.06,0.04-0.15,0.02
				l-0.01,0.1l0.06-0.02c-0.03,0.08,0,0.17-0.06,0.23l-0.01-0.07l-0.05,0.15l0.07-0.07c0.06,0.22-0.17-0.04-0.21,0.6
				c-0.06-0.09-0.18,0.03-0.08,0.14c0-0.01-0.01-0.01-0.01-0.04c-0.06,0.15-0.1,0.2-0.14,0.23l0.01-0.05l-0.06,0.03l0.03,0.03
				c-0.17,0.1,0.14,0.05-0.5,1.11l-0.02-0.04c-0.12,0.29-0.18,0.22-0.5,0.86c0.04-0.06-0.16,0.08,0.02,0.24
				c0.01-0.09-0.08-0.05-0.13-0.03c0.02,0,0.03,0.02,0.05,0.03c-0.16-0.01,0.04,0.07-0.06,0.13c0-0.02-0.05,0.27-0.08,0.06
				c-0.04,0.06,0.03,0.19-0.06,0.21c0-0.04,0.02-0.08,0.03-0.11c-0.11,0.02-0.09,0.04-0.13,0.08l0,0l-0.1,0.09
				c0.18,0.11,0.02,0-0.01,0.33c-0.39-0.01-0.11-0.08-0.71,0.94c-0.09-0.08-0.09-0.08-0.22,0.11c0.02,0-0.05,0.1-0.03,0.17
				c0.11-0.04,0.1,0.13,0.22-0.25l0.06,0.09l-0.01-0.13c0.03,0,0.09,0.02,0.07,0.07c0.07-0.03,0.06,0.02,0.12-0.15
				c0.01,0.02,0.07,0.03,0.03,0.06c0.15,0.06-0.03-0.31,0.46-0.68c-0.03-0.06-0.08,0.04-0.07-0.05c0.11,0.12,0.05-0.23,0.2-0.07
				c-0.07-0.17,0.11,0.06,0.2-0.43c0.03,0.03-0.02-0.02,0.25-0.21c-0.07-0.06,0-0.15,0.02-0.22c0.05,0.03,0.06,0.05,0.06,0.1
				c-0.01-0.01,0.04,0,0.03-0.17l0.05,0.05c0.05-0.09,0.03-0.21,0.13-0.29c-0.05-0.04-0.2,0-0.18-0.14
				c0.03,0.01,0.09,0.04,0.08,0.08c0-0.01-0.01-0.05,0.02-0.06l0.02,0.07c-0.01-0.05,0.09-0.15,0.05-0.02
				c0.03-0.04,0.09-0.08,0.13-0.08c-0.11-0.05,0.17-0.08,0.12-0.43l0,0c-0.04-0.15,0.3-0.33,0.51-0.9c0.01,0.09,0.05-0.01-0.01,0.08
				c0.06-0.08,0.11-0.08,0.09-0.2c0.04,0.06,0.1-0.03,0.11-0.08l-0.07,0.04c0.19-0.22,0.1,0.01,0.34-0.53
				c-0.34,0.09,0.16-0.51,0.17-0.43l-0.02-0.02c0.05-0.03,0.09-0.15,0.13-0.1c0-0.08-0.03-0.05-0.07-0.01
				c0.09-0.09,0.07-0.17,0.15-0.27c-0.04,0.04-0.08-0.43,0.15-0.22c-0.01-0.12,0.11-0.18,0.14-0.23l0,0.06
				c0-0.04,0.01-0.06,0.02-0.09l-0.07,0.01c0.1-0.11,0.07-0.01,0.03-0.27c-0.12,0.14,0.27,0.18,0.08-0.15
				c0.04,0.03,0.09,0,0.09-0.03c0,0.04-0.11-0.01-0.06-0.21l-0.03,0.05c-0.04-0.03-0.01-0.07-0.01-0.14
				c-0.05,0.07,0.17-0.12,0.34-0.41c-0.07,0.01-0.06,0.02-0.08-0.07l-0.02,0.19l-0.04-0.14c-0.02,0.05,0,0.13-0.02,0.16
				c-0.03-0.07-0.01-0.06-0.07-0.05c0.1-0.3,0.3,0.02,0.32-0.59c-0.05,0.03-0.08,0.09-0.02-0.12c0.05,0.02,0.07-0.01,0.11-0.03
				c-0.04-0.01,0.04-0.13-0.05-0.17c0.12-0.13,0.14-0.05,0.02-0.39C578.23,264.16,578.28,264.11,578.3,264.21z M569.59,268.75
				c-0.01-0.01-0.02-0.02-0.04-0.04C569.57,268.73,569.55,268.71,569.59,268.75z M569.42,268.63c0.02,0,0-0.05,0-0.09
				C569.4,268.54,569.53,268.63,569.42,268.63z M569.36,269.2c-0.01,0.01,0,0-0.04,0.01C569.3,269.18,569.29,269.12,569.36,269.2z
				 M573.43,270.47c-0.03,0.02-0.06,0.04-0.04,0.08c-0.03-0.03-0.08-0.08-0.08-0.12c0.1,0.06,0.06-0.06,0.07-0.13
				C573.38,270.36,573.39,270.42,573.43,270.47z M572.7,267.06c0.01,0.01-0.01,0.01,0.01,0.06
				C572.69,267.11,572.65,267.11,572.7,267.06z M572.76,267.15L572.76,267.15c0.01-0.03,0.01-0.07,0.01-0.12
				c0.03,0.04,0.06,0.08,0.02,0.13c0.04,0.03,0.09,0.06,0.11,0.07c-0.09,0.06-0.13,0.1-0.15,0.18c-0.01-0.05,0-0.11-0.02-0.18
				c0.01,0.01,0.02,0.01,0.05-0.02C572.77,267.22,572.78,267.19,572.76,267.15z M572.63,267.74c0.03-0.02,0.09-0.04,0.11,0
				c-0.01-0.01-0.04-0.03-0.03-0.06C572.76,267.7,572.81,267.84,572.63,267.74z M573.45,270.61c-0.03,0-0.06-0.01-0.09-0.03
				C573.39,270.58,573.4,270.55,573.45,270.61z M573.17,266.9c0,0.02,0,0,0,0.05C573.18,266.94,573.18,266.93,573.17,266.9z
				 M573.13,266.76c0.02,0.01,0.03-0.03,0.05-0.06C573.17,266.69,573.21,266.83,573.13,266.76z"
                />
                <path
                  className="st10"
                  d="M594.92,297.22c0.11-0.04-0.08-0.03-0.39-1.55c0.08,0.03,0.21,0.04-0.14-0.42l0,0.08
				c0.06-0.13,0.16,0.22,0.03,0.08c-0.11,0.11,0.11,0.26,0.13,0.4c-0.13-0.08-0.2-0.01-0.04,0.36c-0.03-0.01-0.07,0.03-0.07-0.03
				c-0.02,0.18,0.14,0.26,0.07,0.34l-0.01-0.01c0.02,0.25,0.04,0.64,0.18,1.72c-0.03-0.01-0.05,0.29-0.1,0.44l-0.01-0.01
				c0.09,0.03,0.06,0.18,0.07,0.29c-0.05-0.01-0.07-0.03-0.09-0.09c0.01,0.01-0.04,0.02,0.03,0.23l-0.07-0.03
				c-0.01,0.13,0.05,0.28-0.02,0.45c0.06,0.02,0.2-0.11,0.21,0.08c-0.04,0-0.09,0-0.1-0.05c0,0.02,0.02,0.06,0,0.09l-0.04-0.08
				c0.02,0.04-0.04,0.24-0.04,0.06c-0.02,0.07-0.06,0.15-0.11,0.17c0.12,0.22,0,0-0.04,0.61l0,0c0.01,0.25,0,0-0.22,0.68
				c-0.09-0.06-0.07,0.21-0.31,0.68c0.01-0.11-0.05,0.02,0.02-0.11c-0.08,0.11-0.12,0.12-0.13,0.28c-0.03-0.07-0.12,0.05-0.13,0.11
				l0.08-0.06c-0.02,0.07-0.06,0.12-0.1,0.17l-0.05-0.07c-0.21,0.22-0.28,0.5-0.72,0.97c0.19,0.1-0.34-0.02-0.22,0.11
				c-0.15,0.04-0.21,0.11-0.36,0.14c0.07,0.02-0.4,0.34-0.32,0.02c-0.13,0.08-0.25-0.02-0.32-0.04l0.07-0.02
				c-0.04,0.01-0.07,0.01-0.11,0.01l0.03,0.06c-0.04-0.01-0.07-0.01-0.07-0.04c-0.08,0.05,0,0.23-0.25,0.06
				c0.19,0.1,0.17-0.26-0.19-0.09c0.04-0.03,0-0.09-0.03-0.09c0.05,0-0.03,0.12-0.24,0.01c0.02,0.02,0.03,0.04,0.05,0.05
				c-0.04,0.03-0.09-0.01-0.16-0.05c0.08,0.05-0.13-0.17-0.19-0.57c-0.04,0.05-0.03,0.06-0.11-0.02c0.03,0.07,0.08,0.13,0.12,0.19
				c-0.04-0.03-0.1-0.06-0.14-0.09c0.02,0.07,0.1,0.12,0.1,0.16c-0.07-0.04-0.06-0.04-0.09,0.01c-0.12-0.37,0.15-0.16-0.03-0.84
				l-0.03,0.07c-0.06-0.03,0-0.14-0.01-0.22c0.05,0.01,0.07-0.03,0.11-0.06c-0.04,0,0.03-0.17-0.06-0.21
				c0.13-0.2,0.13-0.08,0.05-0.53c0.04,0.07,0.09-0.01,0.1,0.12c0.01-0.06,0.04-0.12,0-0.12c0,0.1,0.1-0.22-0.05-0.42
				c0.15-0.11,0.16-0.22,0.44-1.08c0.01,0.01,0.02,0.01,0.01,0.03c0.1-0.41,0.01,0,0.29-0.57l0,0.03c0.09-0.07,0.04-0.25,0.13-0.4
				c0,0,0.01,0.01,0.02,0.01c-0.03-0.14,0.05-0.35,0.1-0.5c0.02,0.02,0.03,0.03,0.04,0.04c-0.01-0.05-0.03-0.13,0.17-0.2l-0.06,0.12
				c0.23-0.09,2.44-3.22-0.39-0.69c0.06,0.02,0.02,0.1-0.06,0.15l0.14-0.05c-0.1,0.22-0.23,0.05-0.33,0.27l0.02-0.09
				c-0.14,0.06-0.08,0.2-1.01,0.97l0-0.04c-0.1,0.13-0.08,0-0.29,0.24l0.03-0.07c-0.99,0.61-0.13,0.26-2.53,1
				c0.01,0.01,0,0.15-0.19,0.11c0.03,0-0.26-0.19-0.31,0.09c-0.07-0.26-0.51,0.26-1.28-0.53l0.06,0c-0.25-0.2-0.26-0.01-0.17-0.73
				c0.01,0.01,0.04,0.02,0.03,0.05c0.02-0.09,0.21-0.3,0.07-0.34c0.08,0.04,0.12-0.19,0.17-0.31l0.04,0.06
				c-0.02-0.01,0.03-0.16,0.22-0.44l-0.01,0.07c0.14-0.13-0.04-0.21,0.33-0.27c-0.05,0.04,0.08-0.2,0.09-0.1
				c0.21-0.06-0.05-0.07,0.4-0.26c-0.14,0.09-0.25,0.17-0.24,0.16c0.04,0.05,0.15-0.02,0.12,0.12c0.09-0.01,0.08-0.29,0.66-0.5
				c-0.04-0.06,0.04-0.09-0.06-0.1c0.02-0.01-0.02,0.02-0.06,0.06l0-0.01c-0.04,0.02-0.08,0.06-0.14,0.09
				c-0.01-0.07,0.15,0.01,0.16-0.26c0.29,0.09,0.27,0.02,0.94-0.36c-0.12,0.13-0.13,0.09,0.37-0.16l-0.01,0.04
				c0.41,0,0.53-0.44,1.02-0.38c-0.03,0-0.05-0.01-0.06-0.02c0.24,0.04,0.69-0.24,2.56-0.26l-0.03-0.05
				c0.69,0.14-0.12-0.11,0.9-0.32c-0.09-0.04-0.07-0.04-0.41,0.03c0.02-0.26-0.21,0.1-0.18-0.11c-0.08,0.03-0.21,0-0.26,0.06
				c-0.08-0.07-0.27,0.01-0.26-0.1c-0.03,0.02-0.14,0.08-0.21,0.09c0.13-0.14-0.28,0-0.25-0.14c-0.1,0.12-0.36,0.08-0.53,0.16
				c0.01-0.01,0.03-0.03,0.04-0.03c-0.1,0-0.13-0.03-0.2-0.05c0.11-0.01-0.42,0.41-0.22,0c0.02,0.08-0.2,0.11-0.15,0.17
				c-0.1-0.04-0.08-0.01-0.14-0.08c0.09,0.11-0.49,0.2-0.3-0.01c-0.16,0.35-0.27-0.11-1.05,0.59c-0.37-0.49-0.01-0.04-1.18,0.39
				l0.01-0.11c-0.39,0.52-1.27,0.31-2.46,2.02l0.01-0.05c-0.12,0.14,0.03,0.25-0.1,0.37c-0.01-0.07,0.19,0.24,0.81,1.96
				c0.09-0.05,0.09-0.01,0.32,0.07c-0.04-0.16,0.09,0.2,0.66,0c-0.01,0.03-0.07,0.05-0.11,0.07c0.19-0.09,0.04,0.08,0.21,0
				c-0.02-0.02,0.01-0.05,0.01-0.06c0.09,0.04,0.29,0.01,0.43-0.03l-0.02,0.06c0.25-0.04,0.52-0.14,0.79-0.22
				c0.03-0.04-0.2,0.1,2.75-1.53c-0.02,0.05-0.04,0.09-0.05,0.14c-0.01-0.03-0.03-0.04-0.01-0.08c-0.09,0.06-0.13,0.31-0.09,0.33
				c-0.08,0.17-0.09-0.07-0.36,1.18c-0.11-0.04-0.16,0.25-0.14,0.36c-0.02-0.04-0.06,0.08-0.09,0.03c-0.03,0.11-0.03,0.22,0.03,0.22
				c-0.23,0.55-0.2,0.39-0.11,1.56c-0.03,0-0.04-0.02-0.05-0.05c0.19,0.21-0.06,0.78,1.05,1.09c-0.15,0.11,0.24,0.13,0.04,0.25
				c0.04,0,0.09-0.01,0.09-0.04c0.05,0.03,0.06,0.05,0,0.07c0.21,0.02,0.15-0.04,0.32-0.01c0,0.02-0.04,0.03-0.07,0.06
				c0.1-0.03,0.19-0.07,0.34-0.05c-0.01,0.01,0.01,0.04-0.04,0.03c0.27-0.01,0.43-0.15,0.7-0.18c-0.06,0.08,0.09,0.13,0.28-0.01
				l-0.07-0.01c0.12-0.06,0.19-0.17,0.3-0.21l-0.06,0.08c0.07-0.05,0.13-0.1,0.2-0.15l-0.13,0.05c0.2-0.35-0.05-0.05,0.65-0.68
				c0,0.11,0.22-0.02,0.16-0.18c-0.01,0.02,0.01,0.02,0,0.05c0.11-0.21,0.18-0.27,0.24-0.32l-0.02,0.06
				c0.16-0.22,0.01,0.03,0.4-0.91l-0.03,0.04c-0.11-0.08,0.13-0.16,0.12-0.31c0.13,0.01,0.04,0.06,0.17-0.58l0.02,0.04
				c0.07-0.47,0.19-0.37,0.3-1.41c0.02,0.08,0.03,0.14,0.02-0.21c-0.06,0.02-0.04,0.03-0.09,0.08c-0.03-0.04-0.05-0.11-0.04-0.18
				c0.03,0.12,0.09,0.01,0.13-0.04c-0.02,0.01-0.04-0.01-0.05-0.01c0.14-0.12-0.05-0.01,0-0.2c0.02,0.04-0.1-0.36,0.05-0.13
				c0-0.11-0.12-0.22-0.05-0.31c0.02,0.05,0.02,0.11,0.03,0.16c0.08-0.1,0.06-0.12,0.08-0.2l0,0.01l0.03-0.18l-0.02,0.05
				c-0.18-0.13-0.03-0.28-0.16-0.45C594.82,297.63,595.02,297.65,594.92,297.22z M587.08,296.96c0,0.01,0,0,0,0.04
				C587.04,297.02,586.97,297.03,587.08,296.96z M586.6,296.71c-0.01,0.01-0.03,0.02-0.06,0.05
				C586.57,296.73,586.55,296.75,586.6,296.71z M586.42,296.91c0-0.02-0.06,0.01-0.11,0.01
				C586.3,296.95,586.43,296.78,586.42,296.91z"
                />
                <path
                  className="st10"
                  d="M569.91,132.92c0.35,0.35,0.42,0.51,0.8,0.82l-0.12-0.07l0.21,0.15l-0.08-0.07
				c0.33,0.27,0.9,0.65,2.07,1.35l-0.04,0.06c0.41,0.25,0.61,0.38,1.14,0.63l-0.13-0.01c1.06,0.33,0.49,0.16,2.34,0.99
				c-0.01,0.01-0.04,0.03-0.13,0c0.24,0.12,0.92,0.49,0.89,0.32c-0.03,0.09,0.54,0.25,0.83,0.34l-0.07,0.04
				c0.04-0.01,0.39,0.1,1.12,0.36l-0.14-0.01c0.33,0.13,1.04,0.46,2.25,1.03c-0.35-0.17-0.64-0.3-0.61-0.29
				c-0.03,0.06,0.26,0.2-0.01,0.18c0.24,0.09,0.01-0.05,0.98,0.34l-0.03-0.01c0.39,0.17,0.2,0.13,0.86,0.45
				c0.02-0.07,0.2,0.03,0.05-0.11c0.04,0.03-0.07-0.03-0.19-0.07l0.01,0c-0.09-0.04-0.22-0.1-0.36-0.17
				c1.03,0.32,0.67,0.42,2.68,1.31c-0.08-0.03-0.16-0.07-0.2-0.08c0,0.04,0.7,0.19,1.03,0.52l-0.07-0.01
				c0.73,0.59,1.43,0.56,2.19,1.29c-0.05-0.05-0.08-0.07-0.08-0.09c0.26,0.41,2.49,1.49,4.49,3.98l-0.01-0.1
				c0.23,0.44,0.33,0.16,0.58,1.31c-0.08-0.11-0.15-0.21-0.2-0.22l0.05,0.14c0.05-0.03,0.16,0,0.16,0.27l-0.08-0.12
				c-0.06,0.2,0.29,0.51,0.42,0.77c-0.12-0.02-0.15,0,0.21,0.69c-0.04-0.03-0.05,0.06-0.09-0.06c0.11,0.36,0.34,0.49,0.33,0.65
				l-0.02-0.02c0.39,1.03,0.6,0.78,1.33,2.67c-0.1,0.03,0-0.11,0.31,0.57c-0.04-0.01,0.22,0.58,0.32,0.87l-0.02,0
				c0.11,0.01,0.23,0.31,0.34,0.51c-0.05,0.01-0.09-0.01-0.17-0.11c0.03,0.02-0.03,0.03,0.25,0.41c-0.32-0.12,0.12,0.13,0.54,0.97
				l-0.12-0.12c0.05,0.16,0.08,0.06,0.13,0.18c-0.06,0.02,0,0.21-0.1-0.05c0.24,0.67,0.11,0.28,0.69,1.52l0,0
				c0.29,0.25,0.1,0.68,0.44,1.53c0,0-0.01,0-0.04,0l0.04,0.01c0-0.01,0-0.01,0-0.01c0.32-0.07,0.01-0.15,0.66,1.33
				c-0.12-0.19-0.01,0.08-0.11-0.2c0.08,0.28,0.06,0.35,0.23,0.61c-0.1-0.08,0,0.23,0.06,0.35l-0.03-0.2
				c0.06,0.14,0.1,0.27,0.14,0.4c-0.64-0.41,0.35,0.29-0.06,1.41c0,0.06,0.07,0.03,0.02,0.08c-0.02-0.16-0.12,0.12-0.14-0.09
				c-0.03,0.16-0.15-0.1-0.29,0.15c0-0.05-0.01,0.03-0.22-0.05c0.01,0.09-0.07,0.09-0.11,0.12c-0.01-0.05,0-0.08,0.02-0.11
				c-0.01,0.01-0.03-0.02-0.09,0.08l0-0.07c-0.06,0.01-0.11,0.1-0.2,0.06c-0.02,0.16,0.09,0.26-0.13-0.02c0,0.03-0.03,0.15-0.08,0
				c-0.01,0.04-0.06,0.01-0.05,0.08c-0.02,0-0.03-0.03-0.04-0.05c-0.03,0.04,0,0-0.11,0.1h0c-0.11,0.08,0,0-0.34,0.01
				c0.02-0.09-0.09-0.01-0.35-0.02c0.05-0.04-0.02-0.04,0.05-0.03c-0.06-0.02-0.08-0.06-0.15,0.01c0.03-0.06-0.05-0.08-0.08-0.06
				l0.04,0.05c-0.15-0.14-0.59,0.11-0.7-0.13l-0.01,0.03c-0.03-0.03-0.11-0.04-0.08-0.09c-0.05,0.02-0.03,0.05,0,0.07
				c-0.07-0.05-0.12-0.02-0.19-0.06c0.03,0.05-0.3,0.17-0.16-0.09c-0.08,0.04-0.12-0.07-0.16-0.08l0.04-0.02
				c-0.02,0.01-0.04,0-0.06,0l0.01,0.06c-0.02-0.01-0.04-0.02-0.03-0.04c-0.03,0.03-0.01,0.1-0.06,0.14
				c-0.02-0.04-0.1,0.01-0.09-0.07c0.11,0.05,0.1-0.28-0.1-0.06c0.02-0.04,0-0.09-0.01-0.08c0.02-0.01,0.01,0.1-0.14,0.09
				c0.02,0.04,0.05,0.04-0.07,0.05c0.01-0.03,0.04-0.01,0.03-0.03c-0.12,0.14-0.13-0.4-0.35-0.16l0.13,0.01l-0.1,0.05
				c0.03,0.01,0.09-0.01,0.1,0.01c-0.05,0.03-0.04,0.02-0.04,0.07c-0.26-0.05,0.12-0.23-0.36-0.27c0.01,0.05,0.04,0.07-0.09,0.03
				c0.02-0.04,0.01-0.07,0.01-0.11c-0.01,0.03-0.07-0.04-0.12,0.05c-0.05-0.11,0.01-0.14-0.25-0.05c0.05-0.04,0.03-0.08,0.1-0.09
				c-0.1,0,0.02,0-0.3,0.05c-0.01-0.14-0.04-0.13-0.43-0.36c0.01-0.01,0.01-0.02,0.02-0.01c-0.21-0.07,0.01-0.01-0.21-0.21l0.02,0
				c-0.01-0.07-0.13-0.02-0.18-0.08c0,0,0.01-0.01,0.02-0.02c-0.09,0.03-0.19-0.01-0.26-0.04c0.2-0.06-0.03-0.23-0.49-0.47
				c0.08-0.02,0.16,0.01,0.23,0.02c-0.03-0.03-0.09-0.09-0.14-0.07c0.04,0.04,0.05,0.05-0.16-0.04c0.16-0.11-0.08-0.18,0.02-0.31
				c-0.13-0.07-0.1-0.22-0.17-0.34c-0.01,0.07-0.24-0.02-0.06-0.04c-0.03,0-0.05-0.02-0.08-0.01c0.01-0.06,0.06-0.13,0.18-0.18
				c-0.05,0.02-0.08,0-0.07-0.04c-0.02,0.1-0.09-0.14,0.01-0.09c-0.04-0.04-0.02-0.16-0.13-0.21c0.02-0.01,0.06-0.02,0.07,0
				c0.08-0.1-0.04-0.22,0.13-0.7c0.41,0.16-0.01-0.22,0.83-0.33c0,0.01-0.05-0.13,0.08-0.1l-0.01,0c0.05,0.09,0.1,0.05,0.15,0.04
				l-0.02-0.13c0.16,0.16,0.34-0.12,0.43,0.05c0.03-0.03,0.07-0.03,0.1-0.07c0.03,0.1-0.02,0.08,0.24,0.09l-0.02,0.05
				c0.18-0.13,0.02-0.15,0.47-0.04c-0.01,0.01-0.02,0.04-0.03,0.04c0.05,0.01,0.16,0.11,0.19-0.03c-0.03,0.09,0.1,0.04,0.17,0.02
				l-0.03,0.06c0-0.02,0.06-0.04,0.26-0.03l-0.04,0.03c0.08,0.04,0.12-0.14,0.18,0.09c0.01,0-0.02-0.01,0.25,0.13
				c-0.07-0.04-0.12-0.07-0.12-0.07c-0.11,0.07,0.14,0.29,0.37,0.19c0.01,0.01-0.01-0.01-0.04,0l0,0c-0.02-0.01-0.04-0.02-0.07-0.03
				c0.04-0.02,0.08-0.03,0.09,0.02c0.04-0.03,0.08-0.06,0.09-0.08c0.09,0.03,0.24,0.34,0.98,0.68c-0.01-0.02-0.01-0.03-0.01-0.04
				c0.21,0.15,0.01-0.04,1.65,0.6c0.06-0.11,0.01,0-0.08-0.37l0.06,0.01c-0.02-0.06-0.05-0.02-0.08,0.01c0-0.03,0-0.05-0.01-0.07
				l-0.09,0.09c0.06-0.24-0.11,0.09-0.05-0.11c-0.04,0.02-0.09-0.01-0.13,0.04c-0.01-0.07-0.12-0.02-0.08-0.13
				c-0.02,0.02-0.09,0.06-0.12,0.05c0.1-0.1-0.11-0.05-0.04-0.18c-0.09,0.09-0.16-0.01-0.26,0.01c0.01-0.01,0.02-0.02,0.03-0.01
				c-0.04-0.03-0.03-0.07-0.05-0.1c-0.05,0.09-0.12,0.08-0.18,0.11c-0.01-0.11,0.08-0.09,0.11-0.18c-0.03,0.07-0.12,0.03-0.13,0.09
				c-0.01-0.07-0.02-0.04,0-0.12c-0.06,0.1-0.04,0-0.11,0.05c0.02-0.06-0.11-0.08,0.03-0.17c-0.13,0.16-0.11-0.16-0.61-0.01
				c0.02,0.01,0.03-0.01,0.05-0.02l-0.04,0.01c0.19-0.04-0.04-0.19,0.03-0.09c0.24-0.3-0.56-0.09-0.41-0.35
				c-0.31-0.04,0.08-0.06-0.51-0.19c0.24,0.16-0.24-0.32-0.87-0.43l0.03-0.01c-0.07-0.06-0.13,0.12-0.19,0.03l0.02-0.01
				c-0.15-0.03-0.05-0.18-1.09-0.1l-0.04-0.09c-0.08,0.16,0.01-0.13-0.35,0.1c0-0.03,0.02-0.05,0.04-0.07
				c-0.05,0.14-0.05-0.11-0.1,0.07c-0.05-0.03-0.15-0.01-0.21,0.03l0-0.05c-0.13,0.02-0.26,0.11-0.39,0.17
				c0.06,0,0.09,0.09,0.05,0.11l-0.05-0.07c0,0.05,0.04,0.18-0.03-0.02l-0.05,0.06c-0.02-0.02-0.01-0.04-0.02-0.07
				c0,0.06-0.15-0.02-0.14,0.08c-0.1,0-0.41,0.35-0.47,0.65c-0.06-0.03-0.11,0.07-0.12,0c-0.06,0.12-0.07,0.16-0.08,0.34
				c0.05-0.02,0.1,0.02-0.01,0.03c0.04,0.19-0.27,0.41-0.12,0.58l-0.01-0.01c0.16,0.4-0.1,0.05,0.29,1.04
				c0.03-0.02,0.08-0.07,0.04,0.05c0.05-0.41,0.23,0.63,1.25,1.12c-0.02,0.01-0.04,0.03-0.05,0.02c0,0.07,0.13,0.09,0.16,0.05
				c-0.04-0.06,0.17,0.22,0.53,0.28c-0.06,0.1,0.07,0.15,0.14,0.13c-0.03,0.02,0.02,0.05-0.02,0.08c0.05,0.02,0.11,0.03,0.12-0.03
				c0.1,0.16,0.03,0.14,0.69,0.24c-0.2,0.15-0.38,0.01,0.53,0.21c0,0.01-0.02,0.02-0.03,0.02c0.02,0,0.05,0.03,0.06-0.02l-0.03,0.01
				c-0.02-0.08,0.14-0.12,0.15-0.16c-0.08,0.13,0.11,0.07-0.01,0.23c0.02,0,0.04-0.02,0.05-0.06c0.02,0.02,0.02,0.04-0.01,0.07
				c0.09-0.01,0.08-0.07,0.15-0.05c-0.01,0.02-0.02,0.03-0.04,0.06c0.1-0.04,0.04,0.05,0.57,0.09l-0.02-0.05
				c0.06,0.01,0.12-0.05,0.17-0.01l-0.05,0.03l0.11,0l-0.06-0.04c0.12-0.05-0.01-0.16,0.25,0.09c0.04-0.08,0.14-0.01,0.18-0.09
				c-0.01,0.02-0.02,0.07-0.02,0.08l0.03-0.06c0.06,0.06,0.07-0.01,0.27,0.07l-0.04,0.01l0.03,0.04l0.02-0.05
				c0.09,0.11,0.08-0.14,0.85-0.07c-0.08,0.02,0.15,0.14,0.2-0.02c0.13,0.06,0.17,0.22,0.59-0.17c-0.05,0.05,0.02,0.09,0.05,0.12
				c-0.01-0.02,0-0.04-0.01-0.05c0.07,0.13,0.02-0.1,0.09-0.04c-0.01,0.01,0.15-0.13,0.07,0.02c0.14-0.06-0.02,0.01,0.27,0.03
				c-0.06-0.2-0.01-0.01,0.14-0.22c-0.03,0.12-0.03,0.05,0.08,0.19c0.02-0.07,0.08,0.03,0.11-0.08c0.01,0.02,0.02,0.01,0.04,0.02
				c0.02-0.06,0.21-0.19,0.33-0.26c0.11,0.3,0.24,0.64,0.38,1.05c0.12-0.31,0,0.72,0.13,0.54c0.06,0.34,0.14,0.52,0.2,0.87
				c0.04-0.03,0.47,0.91,0.14,0.76c0.14,0.3,0.1,0.6,0.13,0.78l-0.06-0.16c0.03,0.1,0.05,0.18,0.07,0.26l0.04-0.11
				c0.01,0.1,0.03,0.18,0,0.19c0.17,0.18,0.19-0.03,0.22,0.54c-0.06-0.4-0.07,0.03-0.15-0.26c0.04,0.41-0.04,0.24,0.22,0.76
				c-0.05-0.04-0.07,0.11-0.05,0.18c-0.01-0.13,0.13,0.03,0.21,0.47l-0.01-0.16c0.16,0.24,0.08,0.71,0.14,1.84
				c0.05-0.1,0.03-0.12,0.12,0.11l-0.1-0.55l0.13,0.34c-0.02-0.17-0.08-0.35-0.08-0.45c0.07,0.16,0.05,0.15,0.09,0.06
				c0.06,0.79-0.22,0.47,0.09,1.95l0.02-0.17c0.13,0.47-0.1,0.43,0.19,2.18c-0.05-0.12-0.09,0.06-0.13-0.21
				c0.11,0.66,0.03-0.18-0.01,3.52c-0.01-0.02-0.02,0-0.01-0.05c-0.03,0.89-0.03,0.01-0.18,1.33l0-0.07
				c-0.07,0.32-0.01,0.09-0.09,1.92c-0.02-0.01-0.03-0.02-0.05-0.03c0.04,0.06,0.08,0.21-0.14,0.52l0.04-0.28
				c-0.11,0.13-0.05,0.37-0.1,0.51l-0.04-0.03c-0.06,0.43,0.03,0.46-0.06,0.92l0.04-0.04c-0.01,0.67-0.25,0.21-0.24,1.43
				c-0.04-0.18,0-0.54,0-0.76c-0.03,0.16-0.11,0.56-0.08,0.66c0-0.07,0-0.16,0.03-0.22c-0.16,1.4,0.05,0.07-0.93,3.69
				c0.2-0.2-0.05,0.2,0.11,0.19c-0.05,0.36-0.22,0.51-0.5,0.87c0.06-0.03,0.05,0.14-0.02,0.33l0.14-0.26
				c-0.04,0.5-0.23,0.38-0.28,0.88l-0.01-0.16c-0.15,0.44-0.08,0.48-0.68,2.94l-0.02-0.05c-0.02,0.2-0.04,0.07-0.17,0.78l-0.01-0.14
				c-0.2,0.56-0.14,0.91-0.25,1.4c-0.11-0.03-0.26,0.41-0.34,0.64l-0.01-0.01c-0.2,1.03-0.83,3.13-1.59,7.33l-0.07-0.02
				c-0.09,0.41-0.14,0.62-0.21,1.14l-0.03-0.11c0.02,1,0.01,0.45-0.22,2.27c-0.01-0.01-0.04-0.03-0.04-0.11
				c-0.03,0.24-0.18,0.93-0.03,0.85c-0.09,0-0.06,0.53-0.05,0.81l-0.07-0.05c0.03,0.03,0.03,0.36,0.04,1.06l-0.04-0.12
				c0,0.43-0.03,0.1-0.14,2.23c0.01-0.35,0.04-0.64,0.04-0.61c-0.06,0-0.09,0.28-0.17,0.05c0.01,0.24,0.05-0.01,0.07,0.94
				l-0.01-0.03c0.01,0.39-0.04,0.21-0.06,0.88c0.07-0.01,0.06,0.18,0.12,0c-0.01,0.04,0-0.06-0.01-0.18l0.01,0.01
				c0-0.09,0-0.22,0-0.35c0.16,0.98-0.15,0.71,0.04,2.7c-0.01-0.07-0.02-0.16-0.03-0.19c-0.03,0.02,0.19,0.64,0.07,1.04l-0.03-0.06
				c-0.11,0.85,0.31,1.35,0.2,2.3c0.01-0.07,0.01-0.1,0.02-0.11c-0.09,0.41,0.24,2.12,0.59,4.53c-0.52-0.56-1.64-1.57-3.31-2.84
				c0.22,0.36-0.26-0.2-0.3-0.04c-0.52-0.46-0.21-0.08-0.97-1.25c0.02,0.09-0.2-0.08-0.42-0.32l0.28,0.36
				c-0.62-0.5-0.43-0.54-1.02-1.08l0.21,0.15c-0.49-0.65-0.64-0.47-3.26-3.62l0.08,0.04c-0.21-0.23-0.09-0.1-0.83-1l0.16,0.14
				c-0.53-0.8-0.96-1.13-1.46-1.76c0.09-0.06-0.34-0.69-0.53-1.02l0.02,0c-1.15-1.53-2.98-4.21-6.58-10.16l0.07-0.02
				c-0.33-0.6-0.49-0.9-0.92-1.63l0.13,0.12c-0.95-1.31-0.47-0.6-1.88-3.15c0.02,0,0.06,0.01,0.13,0.13
				c-0.19-0.34-0.67-1.36-0.73-1.17c0.08-0.05-0.41-0.75-0.65-1.14l0.09,0.03c-0.05-0.03-0.33-0.48-0.9-1.44l0.13,0.15
				c-0.31-0.39-0.46-0.91-1.65-3.21c0.26,0.51,0.46,0.93,0.44,0.89c0.06-0.03-0.14-0.45,0.1-0.16c-0.19-0.32-0.04,0.03-0.77-1.31
				l0.03,0.04c-0.29-0.55-0.12-0.32-0.6-1.28c-0.05,0.05-0.18-0.23-0.1,0.05c-0.03-0.06,0.05,0.1,0.15,0.26l-0.01-0.01
				c0.07,0.13,0.16,0.31,0.26,0.51c-0.86-1.34-0.44-1.08-1.96-3.9c0.06,0.1,0.12,0.22,0.16,0.27c0.02-0.04-0.6-0.84-0.77-1.51
				l0.07,0.07c-0.47-1.29-1.17-1.9-1.63-3.35c0.03,0.1,0.05,0.15,0.05,0.18c-0.76-1.97,0.21,0.68-3.59-8.07l0,0.13
				c-0.12-0.3-0.43-0.94-0.36-0.91c-0.22-0.18-0.91-2.81-1-2.1c-0.11-0.39-0.51-0.36,0.04,0.91l-0.18-0.41
				c0.12,0.41,0.49,1.42,1.77,3.97c-0.3-0.35,0.36,0.83,0.18,0.78c0.24,0.25,0.53,1.06,0.82,1.56c-0.32-0.23-0.13-0.16,0.38,1.13
				c0.06-0.05,0.35,0.6,0.36,0.45c0.07,0.3,0.08,0.25,0.07,0.43c0.02-0.26,0.8,1.48,0.34,0.87c0.72,0.95-0.01,0.07,1.59,3.03
				c0.14-0.12,0.08-0.12,0.29,0.33c-0.23,0.32-0.3-0.1,1.7,3.74l-0.16-0.12c0.96,1.56-0.02,0.23,4.77,9.19l-0.09-0.11
				c0.24,0.5,0.43,0.48,0.62,0.96l-0.06-0.08c0.16,0.42,1.96,3.53,3.3,6.04c0.03-0.15,0.43,0.78,1.58,2.54
				c-0.04,0-0.14-0.16-0.24-0.27c0.6,0.88,0.11,0.17,1.09,1.72l-0.09-0.05c1.9,2.87,1.57,2.93,8.96,12.11l-0.04-0.03
				c1.92,1.73-0.26,0.3,4.89,4.42l-0.01-0.06c0.9-0.06-0.58,0.83,2.62,3.09l0.06,0.26c0.01-0.11-0.03-0.26-0.08-0.43
				c0.5,0.64,1.19,1.49,2.19,2.64l-0.21-0.17c0.87,1.14,1.56,2.47,2.42,3.6c-0.29-0.42,0.55,1.25,3.1,3.93
				c-0.11,0.05,0.61,0.72,0.89,0.91c-0.11-0.05,0.2,0.21,0.07,0.16c0.3,0.24,0.58,0.45,0.57,0.37c0.93,0.89,0.65,0.76,4.01,3.02
				c-0.1-0.04-0.18-0.08-0.26-0.11c0.69,0.43,0.67,0.54,3.52,2.25c-0.01,0.01-0.09-0.03-0.17-0.07c0.5,0.23-0.34-0.18,0.89,0.28
				c-0.32-0.02,0.71,0.47,0.22,0.39c0.1,0.05,0.23,0.1,0.23,0.06c0.14,0.09,0.17,0.14,0.01,0.09c0.54,0.29,0.38,0.13,0.85,0.41
				c-0.01,0.02-0.12-0.02-0.18-0.03c0.26,0.1,0.53,0.19,0.93,0.44c-0.03,0,0.01,0.04-0.11-0.03c0.7,0.38,1.25,0.55,1.99,1.02
				c-0.14-0.01-0.25,0.01,0.67,0.47l-0.14-0.13c0.36,0.2,0.71,0.31,1.01,0.5l-0.29-0.11l0.69,0.35l-0.37-0.24
				c0.39,0.16,0.02-0.13,0.49,0.16c-0.1-0.04,0.36,0.2,0.52,0.35l0,0c0.76,0.32,0.18,0.28,1.7,0.89c-0.25,0.02,0.49,0.38,0.69,0.39
				c-0.05-0.02-0.03,0-0.13-0.04c0.64,0.33,0.9,0.49,1.1,0.63l-0.16-0.08c0.81,0.47-0.06-0.02,2.89,1.53l-0.14-0.09
				c0.53,0.06-0.04,0.24,2.71,1.53l-0.11-0.03c1.47,0.84,1.11,0.68,4.05,2.56c-0.19-0.08-0.23-0.11,0.57,0.38
				c-0.02-0.09-0.07-0.1-0.2-0.24c0.12,0.03,0.33,0.16,0.51,0.29c-0.34-0.21-0.05,0.08,0.07,0.21c-0.01-0.03,0.05-0.01,0.04-0.04
				c0.31,0.36,0.13-0.05,0.6,0.38c-0.09,0.01-0.22-0.02,1.16,0.86c-0.14-0.09-0.31-0.19-0.45-0.28c0.29,0.33,0.11,0.07,0.91,0.85
				l-0.1-0.12c0.45,0.15,0.72,0.56,1.25,0.87c-0.52-0.3-0.11-0.02-0.2,0.01c0.1,0.12,0.27,0.28,0.2,0.26
				c0.17,0.14,0.22,0.02,0.82,0.76c-0.9-1,2.04,1.8,3.51,3.59c-0.23-0.08-0.29-0.25,0.85,1.18l-0.11-0.21
				c-0.05,0.03-0.18-0.01-0.3-0.41l0.14,0.19c-0.08-0.17-0.02-0.26-0.88-1.11c0.17,0.04,0.17,0-0.64-0.96
				c0.06,0.04,0.02-0.09,0.14,0.08c-0.35-0.5-0.67-0.7-0.78-0.91l0.04,0.03c-1.31-1.19-0.68-0.61-3.57-3.3
				c0.09-0.07,0.06,0.12-0.82-0.64c0.05,0-0.78-0.64-1.16-0.96l0.02,0c-0.13,0.02-0.53-0.3-0.85-0.51c0.04-0.03,0.11-0.01,0.27,0.08
				c-0.03-0.02-0.07-0.1-0.64-0.39c0.32,0.01-0.12-0.15-1.55-0.91l0.24,0.09c-0.19-0.15-0.48-0.22-0.17-0.14
				c-0.98-0.69-0.42-0.32-2.33-1.41l0,0c-1.02-0.46-0.93-0.7-4.32-2.49c0.34,0.13-0.09-0.09,0.34,0.15c-0.4-0.24-0.47-0.32-0.94-0.5
				c0.18,0.03-0.27-0.24-0.47-0.32l0.27,0.19c-0.22-0.11-0.41-0.22-0.6-0.33l0.14-0.02c-0.82-0.5-1.87-1-3.88-2
				c0.28,0.4-0.91-0.73-0.78-0.46c-0.32-0.25-1.05-0.53-3.43-2.05l0.25,0.11c-0.15-0.07-0.27-0.14-0.39-0.2l0.11,0.13
				c-0.14-0.09-0.26-0.16-0.24-0.18c-0.41-0.05-0.13,0.13-0.86-0.38c0.77,0.48,0.51-0.04-0.69-0.44c0.1,0.01-0.09-0.15-0.19-0.2
				c0.17,0.12-0.16,0.05-0.77-0.31l0.21,0.15c-0.42-0.09-0.96-0.67-2.45-1.73c0.1,0.14,0.13,0.14-0.23-0.02l0.78,0.46l-0.53-0.25
				c0.23,0.15,0.5,0.28,0.63,0.38c-0.74-0.43-0.28-0.34-2.67-1.89l0.2,0.18c-0.67-0.39-0.6-0.53-2.85-2.13
				c0.19,0.09-0.02-0.13,0.34,0.12c-0.17-0.14-0.31-0.27-0.34-0.25c0.32,0.14-0.7-0.15-2.29-2.19c0.11,0.09,0.18,0.06-0.01-0.12
				l-0.01,0.1c-0.31-0.37-0.73-0.88-1.3-1.6c0.03,0.01,0.02-0.01,0.07,0.06c-0.89-1.15-0.01-0.02-1.19-1.83l0.07,0.09
				c-0.26-0.46-0.07-0.14-1.81-2.53c0.02,0,0.04,0,0.05,0c-0.09-0.05-0.26-0.23-0.42-0.76l0.26,0.39c-0.06-0.25-0.33-0.51-0.45-0.72
				l0.05,0c-0.42-0.59-0.49-0.55-0.93-1.17l0.02,0.08c-0.72-0.8-0.06-0.51-1.47-1.85c0.25,0.16,0.64,0.6,0.89,0.86
				c-0.17-0.22-0.58-0.74-0.72-0.81c0.08,0.08,0.19,0.17,0.25,0.26c-0.1-0.08-0.69-0.42-0.91-1.52c0.18,0.24-0.09-0.55,0.07-0.5
				c-0.16-0.18-0.16-0.71-0.28-1.05c0.01,0.02,0.04,0.05,0.04,0.09c0.21-0.01-0.44-1.02-0.02-0.82c-0.08,0.02-0.13-0.41-0.19-0.32
				c0.04-0.19,0.01-0.16,0.08-0.27c-0.12,0.16-0.22-1.01,0-0.58c-0.29-0.65,0.01-0.04-0.34-2.07c-0.14,0.04-0.14,0.08-0.14-0.25
				c0.4-0.27,0.18,0.12,0.01-2.53l0.1,0.11c-0.1-1.09-0.11-1.25,0.25-6.33l0.01,0.09c0.06-0.33-0.11-0.4,0-0.7l0.01,0.06
				c0.08-0.13,0.04-0.54,1.09-5.95c0.03,0.02,0.01,0.13,0.01,0.23c0.09-0.64,0.01-0.11,0.26-1.22l0.04,0.06
				c0.79-3.1-0.01,0.21,2.28-8.97l0,0.03c0.35-1.56-0.01,0,1.05-3.91l-0.05,0.02c0.12-1.53-0.04,0.01,0.86-4.55l0.05,0.17
				c0.03-0.9,0.28-1.8,0.27-2.68c0.03,0.35,0.2-1,0.16-3.08c0.12,0.03,0.09-0.57,0.04-0.78c0.03,0.07,0.03-0.18,0.08-0.1
				c0-0.24-0.03-0.45-0.08-0.41c0.09-0.79,0.1-0.6-0.25-3.09c0.05,0.1,0.08,0.17,0.11,0.24c0.1-0.09-0.34-0.35,0.11-1.35l-0.04,0.21
				c0.17-0.25-0.02-0.97,0.2-0.9c-0.03-0.09,0.16-0.2,0.21-1.13c0.07,0.24,0.13-0.41,0.25-0.32c-0.07-0.18,0.11-0.55,0.14-0.83
				c0,0.02,0.01,0.05,0,0.07c0.06-0.14,0.11-0.17,0.16-0.26c-0.06,0.18-0.1-0.8,0.13-0.31c-0.08-0.02,0.04-0.34-0.04-0.3
				c0.11-0.11,0.07-0.11,0.18-0.15c-0.16,0.06,0.2-0.78,0.22-0.39c-0.01-0.62,0.02,0.01,0.52-1.55c-0.11-0.03-0.17-0.01-0.02-0.24
				c0.5,0.01,0.09,0.14,1.05-1.67l0.04,0.12c0.43-0.9,0.96-1.57,2.99-3.91l-0.03,0.06c0.2-0.17,0.1-0.32,0.32-0.45l-0.03,0.04
				c0.15-0.17,1.45-1.2,2.5-2.12l0.05,0.06c-0.02-0.02,0.09-0.13,0.37-0.27c-0.13-0.07,0.17,0.03,0.79-0.52
				c0,0.03-0.07,0.08-0.11,0.13c0.4-0.27,0.05-0.06,0.83-0.42l-0.02,0.06c0.35-0.12,0.73-0.25,1.13-0.27
				c-0.13,0.08-0.01-0.24,0.05-0.05c0.03-0.2,4.09,1.87,1.33,3.6c0.85-0.39-2.71,2.39-0.92,2.44l-0.04-0.03
				c0.76-0.54-0.08,0.02,3.15-1.76l-0.07,0.12c0.56-0.37,1.26-0.44,1.88-0.54c-0.21,0.35-0.17-0.53,2,0.69
				c-0.09,0.07,0.18,0.35,0.29,0.46c-0.05-0.03,0.03,0.13-0.04,0.1c0.07,0.14,0.14,0.27,0.19,0.23c0.02,0.83,0.27,0.59,0.02,2.19
				c-0.03-0.08-0.04-0.16-0.03-0.22c-0.17,0.26,0.03,0.47-0.98,1.69c-0.01-0.01,0.01-0.05,0.04-0.08c-0.11,0.19,0.02-0.03-0.15,0.45
				c0-0.2-0.32,0.24-0.29-0.04c-0.03,0.04-0.07,0.1-0.04,0.12c-0.07,0.04-0.1,0.04-0.06-0.04c-0.2,0.2-0.09,0.18-0.29,0.34
				c-0.01-0.02,0.02-0.06,0.02-0.1c-0.07,0.12-0.15,0.23-0.35,0.34c0-0.02-0.03-0.02,0.02-0.06c-0.31,0.21-0.48,0.44-0.86,0.61
				c0.03-0.09,0.01-0.15-0.39,0.08l0.1,0c-0.18,0.08-0.3,0.22-0.47,0.26l0.12-0.1l-0.32,0.17l0.2-0.06
				c-0.16,0.13,0.07,0.1-0.18,0.19c0.05-0.03-0.18,0.07-0.3,0.06l0,0c-0.38,0.23-0.15-0.12-0.86,0.34c0.06-0.15-0.31-0.02-0.37,0.1
				c0.02-0.01,0.01-0.02,0.06-0.04c-0.32,0.11-0.46,0.13-0.57,0.15l0.06-0.03c-0.43,0.09,0.03-0.02-1.51,0.45l0.08-0.01
				c-0.44,0.22-0.33-0.1-1.46,0.37l0.04-0.05c-0.77,0.23-0.63,0.08-2.26,0.55c0.12-0.07,0.18-0.09-0.32,0.09
				c0.05,0.05,0.06,0.02,0.16,0.04c-0.04,0.05-0.15,0.11-0.25,0.14c0.17-0.09-0.03-0.09-0.12-0.1c0.02,0.01,0,0.04,0.01,0.06
				c-0.24-0.04,0,0.12-0.32,0.14c0.01-0.05,0.01-0.14-0.67,0.21c0.06-0.04,0.16-0.08,0.23-0.12c-0.19-0.02-0.2,0.01-0.32,0.04
				l0.01,0l-0.29,0.06l0.08,0c-0.15,0.23-0.41,0.18-0.64,0.4c0.22-0.21,0.02-0.08,0.01-0.15c-0.08,0.01-0.19,0.03-0.17,0
				c-0.11,0.11-0.24,0.05-0.38,0.21c-0.1-0.1-0.06,0.14-2.15,1.43c0.03-0.13,0-0.25-0.53,0.47l0.1-0.07
				c-0.03-0.02-0.07-0.15,0.17-0.18l-0.08,0.09c0.19,0.03,0.34-0.31,0.52-0.46c0.03,0.1,0,0.13,0.52-0.22
				c-0.01,0.04,0.06,0.05-0.03,0.09c0.27-0.11,0.34-0.32,0.48-0.31l-0.02,0.02c0.74-0.29,0.57-0.56,2.09-0.99
				c0.02,0.09-0.09-0.02,0.47-0.19c-0.01,0.03,0.47-0.11,0.71-0.14l0,0.02c0.02-0.1,0.26-0.15,0.43-0.22
				c0.01,0.05-0.02,0.08-0.1,0.14c0.02-0.02,0.03,0.04,0.34-0.16c-0.11,0.28,0.1-0.08,0.81-0.31l-0.1,0.09
				c0.04-0.04,0.39-0.1,0.11,0c0.53-0.08,0.22-0.03,1.27-0.34l0,0c0.78-0.31,0.16,0.07,2.35-0.55c-0.16,0.09,0.07,0.02-0.16,0.08
				c0.22-0.04,0.28-0.02,0.5-0.15c-0.06,0.08,0.19,0.03,0.28-0.02l-0.17,0c0.11-0.04,0.22-0.06,0.32-0.09l-0.03,0.1
				c0.47-0.11,1.01-0.29,1.98-0.78c-0.27-0.1,0.59-0.04,0.41-0.16c0.27-0.08,0.39-0.18,0.66-0.28c-0.05-0.03,0.58-0.61,0.56-0.27
				c0.19-0.18,0.44-0.22,0.57-0.3l-0.1,0.1c0.06-0.06,0.12-0.1,0.17-0.14l-0.1,0c0.07-0.04,0.13-0.08,0.14-0.06
				c0.07-0.19-0.11-0.15,0.31-0.37c-0.37,0.25,0.05,0.37,0.33-0.25c0,0.06,0.12,0.01,0.15-0.04c-0.09,0.07-0.05-0.1,0.2-0.39
				l-0.1,0.08c0.03-0.27,0.49-0.35,1-1.15c-0.09,0.05-0.08,0.06-0.05-0.14c-0.05,0.14-0.12,0.29-0.19,0.43
				c0.03-0.1,0.05-0.2,0.07-0.3c-0.06,0.12-0.11,0.27-0.16,0.34c0.03-0.14,0.04-0.12-0.04-0.09c0.32-0.59,0.51-0.15,0.67-1.44
				l-0.05,0.13c-0.02-0.59,0.23-0.39-0.2-1.78c0.08,0.08,0.07-0.08,0.17,0.13c-0.28-0.41,0.13,0.1-2.12-1.87
				c0.01-0.01,0.01-0.01,0.01-0.01c-0.67-0.04,0.01-0.1-1.07-0.14l0.06-0.01c-0.25,0.04-0.14-0.12-1.48,0.47c0-0.02,0-0.03,0-0.05
				c-0.03,0.06-0.13,0.14-0.42,0.1l0.21-0.09c-0.15-0.04-0.27,0.12-0.39,0.16l0-0.04c-0.31,0.16-0.27,0.25-0.6,0.43l0.05,0.01
				c-0.4,0.36-0.28-0.11-0.97,0.6c0.08-0.13,0.3-0.31,0.43-0.43c-0.12,0.07-0.39,0.24-0.42,0.31c0.04-0.04,0.09-0.09,0.14-0.1
				c-0.1,0.15-0.26,0.29-0.49,0.42c0.01-0.14-0.24-0.01-0.79,0.25c0.02-0.04,0.04-0.08-0.01-0.1c0.15-0.25,0.34-0.27,0.69-0.38
				c-0.07-0.01,0.01-0.12,0.13-0.22l-0.2,0.11c0.21-0.33,0.32-0.15,0.53-0.49l-0.05,0.12c0.17-0.32,0.54-0.16,1.24-2.18l0.03,0.05
				c-0.03-0.18,0.08-0.08-0.02-0.72c0.02,0.04,0.04,0.07,0.06,0.11c-0.02-0.56-0.32-0.79-0.59-1.09c0.09-0.07-0.16-0.41-0.33-0.54
				l0.02-0.01c-0.87,0.78-0.27-2.24-5.67,1.18l-0.04-0.06c-0.27,0.2-0.42,0.28-0.72,0.56l0.03-0.09c-0.46,0.63-0.21,0.27-1.27,1.28
				c-0.01-0.02-0.02-0.05,0.03-0.09c-0.15,0.12-0.58,0.47-0.43,0.53c-0.07-0.06-0.3,0.3-0.42,0.48l-0.03-0.07
				c0,0.02-0.14,0.25-0.45,0.71l0.03-0.1c-0.22,0.24,0.01,0.25-0.49,0.56c0.05-0.08-0.1,0.3-0.11,0.19
				c-0.29,0.23,0.07,0.03-0.52,0.62c0.17-0.22,0.31-0.39,0.3-0.37c-0.05-0.04-0.2,0.13-0.16-0.06c-0.1,0.16,0.04,0.02-0.36,0.65
				l0.01-0.02c-0.17,0.26-0.13,0.12-0.44,0.54c0.06,0.03-0.03,0.15,0.1,0.07c-0.02,0.02,0.03-0.04,0.07-0.13l0,0.01
				c0.04-0.06,0.1-0.14,0.16-0.23c-0.3,0.73-0.43,0.37-1.12,1.8c0.07-0.26,0.09-0.24-0.33,0.58l0-0.05
				c-0.44,0.53-0.29,1.06-0.76,1.65c0.03-0.04,0.05-0.06,0.07-0.07c-0.29,0.26-0.73,1.89-1.43,4.04l0.06-0.04
				c0,0.09-0.12,0.17-0.2,0.87c-0.05-0.32-0.11-0.62-0.18-0.91c0.01,0,0.03,0.05,0.05,0.11c-0.11-0.31,0.05,0.22-0.3-0.52
				c0.18,0.15-0.07-0.52,0.17-0.28c-0.02-0.07-0.07-0.15-0.1-0.12c-0.01-0.1,0-0.14,0.06-0.04c-0.1-0.36-0.13-0.22-0.2-0.55
				c0.02,0,0.05,0.06,0.08,0.09c-0.08-0.16-0.17-0.32-0.2-0.6c0.02,0.01,0.03-0.02,0.05,0.06c-0.1-0.48-0.33-0.78-0.41-1.32
				c0.07,0.07,0.16,0.07-0.03-0.51l-0.01,0.12c-0.06-0.25-0.19-0.45-0.22-0.66l0.09,0.17l-0.15-0.45l0.04,0.27
				c-0.12-0.23-0.11,0.06-0.18-0.27c0.03,0.06-0.07-0.24-0.06-0.39l0,0c-0.16-0.44-0.07,0.04-0.18-0.85c0.03,0.03,0,0.07,0,0.1
				c-0.01-0.01,0.11-0.07,0.05-0.17c0.01,0.16-0.01-0.06-0.13,0.03c-0.03-0.07-0.07-0.14-0.12-0.24c0.16,0.08-0.01-0.39-0.14-0.47
				c0.02,0.03,0.02,0.01,0.05,0.07c-0.17-0.44-0.2-0.62-0.23-0.77l0.05,0.12c-0.13-0.43,0-0.01-0.44-1.23
				c-0.02,0.05,0.11-0.13,0.14-0.01c0.02-0.01-0.05-0.09-0.15-0.01c-0.08-0.18-0.17-0.38-0.29-0.63c0.14,0.49-0.31-0.27-0.26-1.25
				c-0.03-0.02-0.08-0.1-0.08-0.14c0.12,0.1,0.02-0.18,0.16-0.14c-0.12-0.08-0.03-0.23-0.07-0.36c0.01,0.01,0.02,0.02,0.02,0.03
				c0.02-0.06,0.06-0.07,0.09-0.11c-0.09-0.03-0.1-0.13-0.15-0.19c0.11-0.06,0.1,0.07,0.2,0.06c-0.08-0.01-0.05-0.15-0.12-0.14
				c0.07-0.05,0.04-0.04,0.13-0.06c-0.12-0.04-0.01-0.06-0.07-0.13c0.07-0.01,0.06-0.18,0.17-0.04c-0.19-0.12,0.19-0.22-0.04-0.82
				c-0.01,0.03,0.01,0.04,0.02,0.05l-0.01-0.05c0.05,0.26,0.2-0.14,0.09,0.01c0.35,0.23,0.12-0.49,0.66-1.37
				c-0.13,0.23,0.33-0.33,0.76-1.1l0,0.04c0.09-0.08-0.05-0.19,0.07-0.24l0,0.02c0.16,0.01,0.37-0.57,1.28-1.53
				c0.02,0.02,0,0.06,0,0.09c0.02-0.14,0.07,0.04,0.09-0.09c-0.02,0-0.03-0.04-0.04-0.05c0.07-0.01,0.16-0.09,0.21-0.17l0.03,0.05
				c0.13-0.1,0.23-0.26,0.37-0.37c-0.01,0.09-0.15-0.19-0.01-0.05c-0.01-0.05-0.07-0.17,0.03,0.01l0.04-0.07
				c0.02,0.02,0.01,0.04,0.03,0.06c0.3-0.26,0.05,0.02,0.85-0.77c0.04,0.05,0.15-0.01,0.11,0.06c0.13-0.07,0.17-0.1,0.33-0.21
				c-0.06-0.03-0.05-0.09,0.03-0.01c0.18-0.17,0.51,0.04,0.64-0.09l-0.01,0.01c0.39-0.02-0.02-0.02,1.1,0.36
				c-0.06-0.37,0.63,0.48,0.71,1.72c-0.02-0.02-0.04-0.02-0.04-0.05c-0.07,0.05-0.02,0.2,0.02,0.21c-0.04,0.11-0.03-0.08-0.15,0.78
				c-0.11-0.04-0.14,0.14-0.12,0.22c-0.02-0.03-0.06,0.04-0.09,0c-0.03,0.07-0.03,0.14,0.02,0.15c-0.21,0.16-0.1,0.1-0.41,0.9
				c-0.12-0.29,0.2-0.41-0.48,0.61c-0.01-0.01-0.01-0.03,0-0.05c-0.01,0.11,0-0.02,0.05,0.28c-0.08-0.14-0.14,0.11-0.22-0.08
				c-0.01,0.02-0.01,0.06,0.02,0.08c-0.03,0.02-0.05,0.01-0.06-0.04c-0.06,0.11,0.01,0.11-0.06,0.2c-0.02-0.02-0.01-0.04-0.03-0.07
				c0,0.07,0,0.15-0.08,0.21c-0.01-0.01-0.03-0.02-0.01-0.04c-0.1,0.13-0.09,0.29-0.24,0.39c-0.04-0.11-0.07,0.02-0.14-0.03
				l-0.04,0.09l0.06,0c-0.06,0.06-0.06,0.16-0.13,0.19l0.01-0.07l-0.1,0.13l0.09-0.04c-0.06,0.17,0.13,0.09-0.3,0.23
				c0.03,0.08-0.12,0.15-0.1,0.25c-0.03-0.11-0.17-0.03-0.13,0.1c0-0.01-0.01-0.01,0-0.04c-0.11,0.12-0.17,0.14-0.22,0.16l0.03-0.04
				l-0.06,0l0.02,0.04c-0.19,0.03,0.11,0.1-0.88,0.83l0-0.04c-0.22,0.22-0.25,0.13-0.79,0.58c0.06-0.03-0.17,0.01-0.08,0.23
				c0.05-0.08-0.05-0.08-0.1-0.08c0.01,0.01,0.02,0.03,0.03,0.05c-0.14-0.07,0.01,0.08-0.11,0.09c0.01-0.02-0.15,0.22-0.1,0.02
				c-0.06,0.04-0.05,0.19-0.15,0.17c0.02-0.03,0.05-0.06,0.07-0.09c-0.1-0.03-0.1-0.01-0.15,0.01l0.01,0l-0.12,0.04
				c0.11,0.17,0.02,0.01-0.15,0.29c-0.34-0.18-0.07-0.12-1.06,0.51c-0.04-0.11-0.02-0.11-0.24-0.01c0.01,0.01-0.09,0.06-0.11,0.14
				c0.11,0.01,0.03,0.16,0.31-0.12l0.01,0.11l0.05-0.12c0.03,0.01,0.07,0.06,0.03,0.1c0.08,0.01,0.04,0.04,0.17-0.08
				c0,0.03,0.04,0.06,0,0.07c0.11,0.12,0.11-0.29,0.71-0.4c0-0.07-0.09,0-0.03-0.08c0.04,0.16,0.14-0.18,0.21,0.03
				c0.01-0.19,0.06,0.1,0.37-0.3c0.02,0.05-0.01-0.03,0.31-0.09c-0.03-0.09,0.06-0.13,0.12-0.19c0.03,0.05,0.03,0.07,0.01,0.12
				c0-0.02,0.04,0.02,0.1-0.14l0.02,0.07c0.08-0.06,0.11-0.18,0.24-0.21c-0.03-0.06-0.18-0.09-0.1-0.2c0.02,0.03,0.06,0.07,0.04,0.1
				c0.01-0.01,0.02-0.05,0.04-0.05l-0.01,0.08c0.01-0.05,0.14-0.09,0.05,0c0.05-0.02,0.11-0.04,0.15-0.02
				c-0.07-0.09,0.18,0,0.28-0.34l0,0c0.02-0.15,0.4-0.18,0.82-0.64c-0.03,0.08,0.04,0.01-0.04,0.07c0.09-0.05,0.13-0.03,0.16-0.15
				c0.02,0.07,0.11,0.01,0.13-0.04l-0.08,0.01c0.25-0.14,0.09,0.04,0.51-0.37c-0.35-0.04,0.33-0.42,0.31-0.34l-0.01-0.03
				c0.05-0.01,0.14-0.11,0.15-0.05c0.03-0.07-0.02-0.05-0.06-0.03c0.11-0.06,0.13-0.13,0.23-0.2c-0.05,0.02,0.06-0.44,0.22-0.16
				c0.03-0.12,0.17-0.13,0.21-0.17l-0.02,0.06c0.01-0.04,0.03-0.06,0.05-0.08l-0.07-0.01c0.13-0.07,0.07,0.01,0.11-0.24
				c-0.16,0.1,0.19,0.25,0.13-0.11c0.02,0.04,0.09,0.03,0.09,0c-0.02,0.04-0.1-0.04,0-0.22l-0.05,0.03
				c-0.02-0.04,0.01-0.07,0.04-0.13c0,0.01,0.27-0.1,0.45-0.3c-0.07-0.01-0.06,0.01-0.06-0.09l-0.08,0.18l0-0.15
				c-0.03,0.05-0.04,0.12-0.07,0.14c-0.01-0.08,0-0.06-0.05-0.07c0.17-0.27,0.29,0.09,0.46-0.5c-0.06,0.02-0.1,0.07,0-0.13
				c0.04,0.03,0.07,0.01,0.11,0c-0.03-0.01,0.07-0.12-0.02-0.18c0.14-0.1,0.14-0.02,0.11-0.37c0.03,0.06,0.09,0.02,0.09,0.12
				c0.02-0.15,0,0.03-0.02-0.41c0.13-0.06,0.2-0.15,0.31-0.78c0.01,0.01,0.02,0.01,0.02,0.02c0-0.3,0.04,0,0.12-0.46l0.01,0.03
				c0.07-0.07-0.05-0.19-0.04-0.32c0,0,0.01,0.01,0.02,0c-0.08-0.09-0.12-0.24-0.16-0.35c0.02,0,0.03,0,0.04,0
				c-0.05-0.01-0.09-0.06,0.03-0.2c0.23,0.24,0.11,0.13-0.45-0.78c0.1,0,0.19,0.1,0.27,0.15c-0.03-0.05-0.11-0.18-0.17-0.18
				c0.02,0.01,0.06,0.03,0.06,0.06c-0.44-0.32-0.01-0.02-1.05-0.94c-0.01,0.14-0.09,0.15-0.18,0.09c0.02-0.03,0.12-0.08,0.04-0.13
				c0.05,0.06-0.07,0.06-0.07,0.1c-0.07-0.06-0.15-0.15-0.2-0.27c0.01,0.06-0.06,0.07-0.13,0.05l0.1,0.08
				c-0.19,0.07-0.16-0.14-0.35-0.07l0.06-0.05c-0.1,0.02-0.37,0.02-0.44,0.16c-0.02-0.01-0.05-0.05-0.01-0.07
				c-0.19-0.01-0.23,0.03-0.88,0.35l0.02-0.06c-0.44,0.43-0.51,0.02-1.71,1.18c-0.28,0.5,0.02,0.07-1.07,1.26l-0.02-0.06
				c-0.04,0.34,0.03,0.13-0.37,0.68c-0.01-0.01-0.03-0.03-0.02-0.06c-0.05,0.06-0.24,0.22-0.11,0.28c-0.08-0.05-0.11,0.14-0.13,0.24
				l-0.05-0.05c0.02,0.01,0,0.11-0.09,0.37l-0.02-0.06c-0.08,0.11,0.1,0.16-0.18,0.26c0.02-0.04,0.02,0.18-0.03,0.09
				c-0.15,0.08,0.06,0.06-0.2,0.28c0.06-0.1,0.12-0.18,0.12-0.17c-0.05-0.03-0.11,0.04-0.15-0.07c-0.06,0.03,0.06,0.23-0.26,0.56
				c0.06,0.03,0.02,0.08,0.1,0.06c-0.01,0.01,0.01-0.02,0.01-0.06l0,0.01c0.01-0.03,0.03-0.07,0.05-0.11
				c0.01,0.05,0.02,0.1-0.04,0.13c0.03,0.04,0.05,0.09,0.07,0.11c-0.25,0.03-0.23,0.06-0.42,0.59c0-0.03,0.01-0.05,0-0.07
				c0,0.09-0.09,0.23-0.01,0.29c-0.08,0.08-0.05-0.09-0.1,0.05l-0.02-0.03c-0.24,0.22,0.04,0.51-0.23,0.75
				c0.02-0.02,0.03-0.03,0.04-0.03c-0.47,0.86,0.2,2.55-1.43-1.43c0.1,0.14,0.14,0.22-0.18-0.39c-0.03,0.06,0,0.08,0,0.2
				c-0.06-0.05-0.15-0.18-0.21-0.3c0.14,0.21,0.08-0.04,0.07-0.15c-0.01,0.02-0.04,0-0.05,0.01c-0.03-0.3-0.13,0.01-0.23-0.38
				c0.06,0.01,0.14,0.03-0.37-0.81l0.17,0.28c-0.02-0.23-0.05-0.25-0.11-0.4l0.01,0.02l-0.13-0.35l0.02,0.1
				c-0.28-0.17-0.28-0.49-0.54-0.78c0.26,0.28,0.08,0.03,0.16,0.02c-0.02-0.09-0.07-0.24-0.03-0.21c-0.07-0.1-0.16-0.09-0.29-0.63
				c0.3,0.81-0.88-1.46-1.55-2.71c0.05,0,0.34,0.29,0.03-0.08c0.02-0.01,0.04-0.02,0.05-0.06c0.04,0.18,0.21,0.19,0.23-0.07
				c0.12,0.2,0.22,0.3,0.26,0.12c0.12,0.26,0.47,0.13-0.05-0.67l0.17,0.26c0.18-0.44-0.86,0.02-0.66-1.86
				c0.09,0.07,0.08,0.06,0.43-0.57c0,0.04,0.08,0.01,0,0.11c0.24-0.26,0.29-0.5,0.45-0.55l-0.02,0.03c0.85-0.65,0.62-0.77,2.3-1.83
				c0.05,0.1-0.11,0.02,0.49-0.41c0,0.04,0.52-0.33,0.78-0.48l0,0.02c-0.01-0.11,0.25-0.28,0.42-0.43c0.03,0.05,0.01,0.09-0.08,0.18
				c0.01-0.03,0.04,0.03,0.34-0.33c-0.05,0.34,0.1-0.14,0.82-0.73l-0.09,0.14c0.15-0.08,0.04-0.09,0.15-0.17
				c0.03,0.05,0.21-0.05-0.03,0.11c0.59-0.38,0.25-0.16,1.31-1.02l0,0c0.76-0.71,0.22-0.05,2.45-1.81
				c-0.15,0.17,0.08-0.02-0.16,0.16c0.24-0.15,0.31-0.16,0.52-0.4c-0.04,0.11,0.22-0.07,0.31-0.16l-0.19,0.08
				c0.12-0.1,0.22-0.17,0.34-0.25l-0.01,0.11c0.49-0.34,1.08-0.76,2.03-1.76c-0.33,0.05,0.65-0.36,0.42-0.37
				c0.27-0.23,0.37-0.4,0.62-0.67c-0.03-0.02,0.38-0.97,0.5-0.62c0.12-0.32,0.34-0.53,0.43-0.71c-0.02,0.06-0.03,0.12-0.05,0.18
				c0.04-0.1,0.07-0.19,0.1-0.27l-0.09,0.07c0.05-0.09,0.09-0.18,0.11-0.16c-0.04-0.26-0.16-0.08,0.09-0.61
				c-0.2,0.55,0.25,0.32,0.14-0.5c0.04,0.06,0.1-0.09,0.1-0.16c-0.03,0.14-0.1-0.06-0.08-0.54l-0.03,0.16
				c-0.16-0.27,0.19-0.77-0.15-1.93c-0.02,0.12,0.01,0.13-0.15-0.08c0.1,0.17,0.17,0.36,0.23,0.54c-0.05-0.12-0.13-0.22-0.2-0.33
				c0.06,0.16,0.15,0.34,0.16,0.45c-0.09-0.16-0.07-0.15-0.1-0.05c-0.28-0.77,0.23-0.65-0.93-1.78l0.12,0.14
				c-0.65-0.36-0.31-0.53-2.14-0.7c0.13-0.06-0.07-0.09,0.22-0.13c-0.14,0-0.27-0.01-0.28,0.03c0.38-0.75-1.34,2.17-1.97-1.28
				c0.02-0.01,0.03-0.01,0.05-0.02c-0.07-0.02-0.21-0.11-0.37-0.44c0.08,0.07,0.17,0.12,0.24,0.2c-0.05-0.18-0.31-0.25-0.44-0.35
				l0.04-0.02c-0.43-0.22-0.48-0.12-0.95-0.24l0.04,0.04c-0.69,0.05-0.27-0.36-1.47,0.09c0.15-0.12,0.51-0.22,0.75-0.27
				c-0.18,0-0.61,0.07-0.68,0.15c0.07-0.03,0.15-0.07,0.22-0.07c-0.89,0.46-0.83,0.25-2.19,1.33c0-0.41-0.04-0.81-0.04-1.11
				l0.04,0.04c-0.07-0.2,0.04-0.1-0.21-0.82l0.08,0.12c-0.14-0.63-0.45-0.88-0.77-1.28c0.1-0.07-0.2-0.49-0.4-0.66l0.02-0.01
				c-0.85-0.37-3.65-0.37-5.84,4.69l-0.03-0.11c0.02,0.99,0,0.45-0.21,2.26c-0.01-0.01-0.04-0.03-0.04-0.11
				c-0.04,0.24-0.16,0.92-0.01,0.85c-0.09,0-0.06,0.53-0.04,0.8l-0.07-0.05c0.03,0.03,0.05,0.35,0.07,1.05l-0.04-0.12
				c-0.02,0.42,0.16,0.25-0.04,0.93c-0.01-0.12,0.1,0.36,0.02,0.27c-0.1,0.45,0.08-0.02-0.03,1.01c0-0.35,0-0.63,0-0.61
				c-0.07,0-0.07,0.28-0.17,0.06c0.03,0.23,0.05-0.01,0.11,0.93l-0.01-0.03c0.02,0.39-0.04,0.22-0.01,0.87
				c0.07-0.01,0.07,0.17,0.12,0c0,0.04,0-0.06-0.03-0.18l0.01,0.01c-0.01-0.09-0.01-0.21-0.02-0.35c0.22,0.96-0.12,0.7,0.19,2.67
				c-0.02-0.07-0.02-0.15-0.05-0.19c-0.04,0.02,0.24,0.61,0.15,1.03l-0.03-0.05c-0.05,0.85,0.41,1.31,0.38,2.26
				c0-0.07,0-0.1,0.01-0.11c-0.06,0.43,0.46,2.18,1.11,4.63c-0.08-0.05-0.26-0.18-0.37-0.28c0.3,0.14-0.42-0.47-0.27-0.5
				c-0.25-0.07-0.64-0.53-0.97-0.75c0.36,0.01,0.17,0.05-0.55-0.56c-0.01,0.04-0.05,0.04-0.08,0.06c0-0.02,0.1,0.05,0.15-0.05
				c-0.15,0.12,0.13-0.11-0.18-0.09l0.08-0.07c-0.13,0.09-0.04-0.03-0.12-0.1c0.02-0.01,0.03-0.07,0.05-0.04
				c-0.07-0.08-0.13-0.15-0.27,0.01l0.06-0.18l-0.05,0.06c-0.01-0.01-0.02-0.02,0-0.05c-0.06,0.02-0.01,0.03-0.06,0.06
				c0-0.08-0.13-0.05-0.07-0.16c-0.05,0.04,0.01,0.06-0.05,0.07c0.11-0.12-0.15-0.01,0-0.16c-0.15,0.04-0.04-0.17-0.26-0.1
				c0.04-0.03-0.03,0.01-0.09-0.18c-0.06,0.07-0.1,0.02-0.15,0.01c0.03-0.04,0.05-0.06,0.09-0.06c-0.03-0.01-0.03-0.03-0.11-0.01
				l0.05-0.05c-0.05-0.03-0.14,0-0.17-0.08c-0.04,0.03,0.02,0.02-0.12,0.14c0.02-0.03,0.04-0.05,0.05-0.05
				c0,0.01-0.06-0.1,0.02-0.08c-0.02-0.02-0.03-0.07-0.02-0.11c-0.02,0.02-0.12,0.09-0.05-0.05c-0.03,0.02-0.04-0.03-0.08,0.03
				c-0.01-0.01,0.01-0.04,0.01-0.06c-0.04,0.02,0,0-0.14,0.02l0,0c-0.12,0.03-0.14-0.23-0.45-0.38c0.06-0.01,0.01-0.04,0.05,0
				c-0.03-0.05-0.02-0.09-0.1-0.07c0.05-0.04,0.02-0.09-0.02-0.09l0,0.06c-0.04-0.2-0.49-0.2-0.44-0.44l-0.02,0.02
				c0-0.04-0.06-0.08-0.01-0.11c-0.05,0-0.04,0.03-0.03,0.06c-0.02-0.08-0.08-0.06-0.11-0.13c-0.01,0.06-0.31,0.01-0.08-0.14
				c-0.08,0-0.06-0.1-0.08-0.14l0.04,0c-0.02,0-0.04-0.01-0.05-0.02l-0.02,0.06c-0.01-0.02-0.02-0.03,0-0.05
				c-0.04,0.02-0.05,0.09-0.12,0.1c0-0.04-0.08-0.03-0.04-0.09c0.06,0.09,0.21-0.21-0.06-0.08c0.04-0.03,0.04-0.08,0.02-0.08
				c0.02,0-0.03,0.09-0.16,0.03c0,0.04,0.02,0.05-0.08,0.02c0.02-0.03,0.04,0,0.04-0.02c-0.08,0.03-0.02-0.12-0.09-0.12
				c0.01-0.01,0.08-0.24-0.13-0.13l0.11,0.04l-0.11,0.02c0.02,0.02,0.08,0.02,0.08,0.04c-0.06,0.01-0.04,0.01-0.07,0.05
				c-0.21-0.13,0.2-0.17-0.2-0.34c-0.01,0.05,0.01,0.08-0.08,0c0.04-0.03,0.03-0.06,0.04-0.09c-0.02,0.03-0.05-0.05-0.13,0.01
				c-0.01-0.1,0.06-0.12-0.2-0.1c0.05-0.02,0.05-0.07,0.12-0.07c-0.09-0.02,0.02,0-0.28-0.01c0.03-0.13,0.02-0.14-0.28-0.39
				c0.01-0.01,0.02-0.02,0.02-0.01c-0.2-0.01,0.09-0.19-0.29-0.3c0,0,0.01-0.01,0.02-0.02c-0.09,0.03-0.17-0.02-0.23-0.05
				c0.11-0.09,0.09-0.04,0.01-0.15c0.36-0.05-0.45-0.04-0.21-0.23c-0.04-0.01-0.15-0.02-0.2-0.05c0.08-0.03,0.15,0,0.21,0
				c-0.02-0.02-0.08-0.07-0.13-0.05c0.03,0.03,0.04,0.04-0.15-0.02c0.15-0.12-0.08-0.15,0-0.29c-0.13-0.04-0.13-0.19-0.22-0.28
				c0.01,0.07-0.23,0.07-0.08-0.02c-0.02,0.01-0.05,0-0.07,0.02c0-0.06,0.01-0.14,0.09-0.24c-0.04,0.04-0.07,0.03-0.08-0.01
				c0.03,0.1-0.16-0.07-0.04-0.09c-0.05-0.02-0.12-0.14-0.24-0.09c0.01-0.02,0.03-0.05,0.05-0.04c-0.36-0.5-0.34-0.27-0.99-1.26
				c0.01,0-0.1,0.09-0.12-0.04l0,0.01c0.07-0.08,0.01-0.11-0.02-0.15l-0.11,0.07c0.08-0.21-0.24-0.22-0.14-0.37
				c-0.04,0-0.06-0.03-0.11-0.04c0.06-0.08,0.08-0.03-0.06-0.23l0.05-0.01c-0.21-0.04-0.12,0.08-0.34-0.29
				c0.01-0.01,0.04-0.02,0.05-0.01c-0.02-0.04-0.04-0.19-0.15-0.09c0.08-0.05-0.05-0.09-0.11-0.11c0.16-0.07-0.04,0.01-0.33-0.5
				c0.03,0.06,0.06,0.11,0.05,0.11c0.11,0.01,0.03-0.32-0.19-0.3c0-0.01,0,0.01,0.03,0.02l-0.01,0c0.01,0.02,0.02,0.03,0.04,0.06
				c-0.04,0-0.08,0-0.06-0.05c-0.05,0.01-0.09,0.02-0.12,0.03c-0.06-0.06-0.04-0.35-0.44-0.9c0,0.02-0.01,0.02-0.02,0.03
				c-0.06-0.13,0.06-0.19-0.37-0.62l0.06-0.05c-0.08,0.17-0.06-0.33-0.49-0.58c0.01,0,0.01,0,0.01,0c-0.06,0.04-0.14,0.09-0.26,0.19
				l0.09,0.02l-0.04,0.04l-0.03-0.05c-0.03,0.05,0.02,0.05,0.06,0.05c-0.02,0.02-0.04,0.04-0.04,0.05l0.13,0
				c-0.04,0.03-0.09,0.02-0.1,0.07c0.06,0,0.08-0.07,0.13-0.04c-0.03,0.05-0.08,0.06-0.02,0.07c-0.02,0.02-0.04,0.02-0.06,0.01
				c0.05,0.01,0.05,0.07,0.12,0.05c-0.04,0.06,0.06,0.09-0.03,0.14c0.03,0,0.1,0.01,0.12,0.03c-0.14,0.02,0.04,0.1-0.09,0.15
				c0.12-0.02,0.11,0.1,0.2,0.13c-0.01,0-0.03,0-0.03,0c0.01,0.04-0.02,0.07-0.02,0.1c0.09-0.04,0.14,0,0.21,0
				c-0.06,0.09-0.12,0.03-0.19,0.09c0.07-0.04,0.11,0.03,0.16-0.01c-0.03,0.06-0.01,0.04-0.07,0.1c0.11-0.05,0.04,0.02,0.12,0.01
				c-0.1,0.15,0.04-0.02,0.24,0.39c0.01,0.01,0.09-0.12,0.13-0.02c-0.01-0.02-0.03,0-0.05,0l0.04,0.01
				c-0.17-0.04-0.07,0.17-0.07,0.06c-0.38,0.03,0.16,0.39,0.48,0.77c-0.22-0.27-0.11,0.12,0.99,1.48l-0.06,0.08
				c0.17-0.01-0.11,0.04,0.2,0.2c-0.03,0.02-0.05,0-0.07,0c0.13-0.03-0.07,0.1,0.09,0.04c-0.02,0.05,0.03,0.11,0.08,0.14l-0.05,0.02
				c0.05,0.09,0.15,0.15,0.21,0.24c0-0.05,0.07-0.1,0.1-0.08l-0.06,0.06c0.05-0.02,0.16-0.08-0.01,0.03l0.05,0.02
				c-0.02,0.02-0.03,0.02-0.06,0.03c0.05-0.01-0.01,0.12,0.08,0.09c0.01,0.08,0.28,0.28,0.48,0.36c-0.05,0.04,0.01,0.1-0.05,0.1
				c0.07,0.07,0.09,0.09,0.21,0.15c0.01-0.05,0.07-0.07,0.01,0.02c0.16,0.04,0.06,0.36,0.23,0.36l-0.01,0
				c0.18,0.07,0.09,0.09,0.47,0.64c0.02-0.01,0.07-0.08,0.04,0.03c0.04-0.36,0.22,0.45,0.99,0.91c-0.02,0.01-0.04,0.02-0.05,0.01
				c-0.02,0.07,0.09,0.1,0.13,0.06c-0.03-0.06,0.11,0.23,0.38,0.31c-0.08,0.08,0.02,0.14,0.08,0.13c-0.03,0.02,0,0.05-0.04,0.07
				c0.03,0.03,0.08,0.04,0.11,0c-0.03,0.16,0.2,0.04,0.12,0.19c0.2,0.1,0.13-0.01,0.7,0.5c-0.01,0.01-0.02,0.01-0.04,0.01
				c0.02,0.01,0.03,0.04,0.06,0l-0.02,0c0.02-0.08,0.16-0.06,0.19-0.09c-0.12,0.09,0.05,0.09-0.11,0.2c0.02,0,0.05-0.01,0.07-0.03
				c0.06,0.17,0.02,0.04,0.35,0.36c-0.05-0.03-0.09-0.05-0.14-0.09c-0.05,0.15-0.01,0.15-0.32-0.04c-0.02-0.48,0.23-0.1-2.43-1.45
				l0.15-0.03c-1.19-0.41-1.23-0.73-6.47-2.95l0.1,0.02c-0.33-0.18-0.45-0.04-0.74-0.24l0.07,0.02c-0.24-0.2-0.08,0.13-4.13-2.19
				c-0.01,0.03-0.22-0.08-0.54-0.35c0.03,0.26,0-0.12-1.08-0.81c0.03-0.01,0.13,0.06,0.22,0.11c-0.56-0.42-0.12-0.06-0.98-0.92
				l0.08,0.01c-0.35-0.41-0.73-0.87-1.02-1.4c0.18,0.26-2-5.73,2.67-4.89l-0.03,0c1.34,0.85,0.49-0.55,5.03,3.4
				c0-0.01-0.01-0.01-0.01-0.02l0.2-0.09c-0.27-0.34-0.2-0.94-1.5-2.79l0.15,0.12c-0.26-0.43-0.31-0.46-1.37-2.56
				c0.46,0.8-0.57-1.36,1.84-1.52c-0.04,0.09,0.48,0.24,0.69,0.34c-0.08-0.01,0.13,0.13,0.03,0.12c0.73,0.3,0.02,0,2.05,1.76
				c-0.07-0.3,0,0.01-0.13-1.06c0.06,0.1,0.09,0.19,0.12,0.25c-0.1-0.47-0.08-0.58-0.54-2.89c0.01,0,0.03,0.06,0.05,0.12
				c-0.11-0.35,0.05,0.24-0.3-0.58c0.13,0.17,0.06-0.53,0.23-0.24c0-0.06-0.01-0.14-0.05-0.13c0.03-0.08,0.06-0.09,0.07-0.01
				c0.08-0.25-0.01-0.22,0.21-0.37c0.01,0.02-0.04,0.05-0.04,0.09c0.07-0.1,0.23-0.2,0.52-0.15c-0.02,0.01,0.01,0.03-0.07,0.03
				c0.48,0.06,0.91,0.04,1.42,0.33c-0.17-0.01,0.02,0.15,0.43,0.35l-0.08-0.1c0.23,0.14,0.47,0.22,0.64,0.39l-0.19-0.09
				c0.15,0.09,0.28,0.2,0.42,0.3l-0.2-0.21c0.25,0.14,0.05-0.11,0.32,0.14c-0.06-0.04,0.21,0.19,0.27,0.33l0,0
				c0.44,0.38,0.03,0.21,0.86,0.98c-0.19-0.04,0.15,0.4,0.28,0.46c-0.02-0.03-0.02-0.01-0.07-0.07c0.21,0.39,0.31,0.57,0.37,0.71
				l-0.04-0.06c0.26,0.56-0.01-0.05,0.84,2.04l-0.02-0.11c0.28,0.62-0.05,0.51,0.5,2.07l-0.05-0.07c0.24,1.13,0.1,0.89,0.54,3.25
				c-0.07-0.18-0.09-0.27,0.09,0.47c0.05-0.06,0.02-0.09,0.04-0.22c0.05,0.07,0.1,0.23,0.14,0.38c-0.09-0.26-0.09,0.02-0.1,0.14
				c0.01-0.02,0.04,0.01,0.06,0c-0.04,0.33,0.12,0.03,0.14,0.47c-0.06-0.03-0.14-0.08,0.21,0.97l-0.12-0.34
				c-0.02,0.25,0,0.28,0.03,0.46l0-0.02l0.07,0.42l-0.01-0.11c0.24,0.26,0.18,0.6,0.4,0.98c-0.21-0.36-0.08-0.05-0.16-0.06
				c0.01,0.11,0.03,0.27-0.01,0.23c0.05,0.13,0.14,0.12,0.19,0.75c-0.18-0.93,0.66,1.77,1.27,3.25c-0.14-0.11-0.26-0.16,0.38,0.93
				l-0.04-0.16c-0.05,0.02-0.16-0.02-0.15-0.32l0.08,0.14c0.07-0.21-0.26-0.6-0.37-0.91c0.12,0.04,0.15,0.03-0.15-0.78
				c0.04,0.03,0.06-0.06,0.09,0.08c-0.08-0.41-0.29-0.6-0.27-0.77l0.02,0.03c-0.39-1.1-0.5-1.14-0.94-3.18
				c0.11,0-0.03,0.11-0.2-0.69c0.03,0.02-0.11-0.68-0.14-1.02l0.02,0.01c-0.1-0.04-0.16-0.39-0.22-0.64c0.06,0,0.09,0.04,0.14,0.17
				c-0.02-0.03,0.04-0.02-0.15-0.51c0.2,0.14,0.03-0.13-0.3-1.19l0.09,0.16c-0.01-0.18-0.13-0.33,0.01-0.15
				c-0.08-0.8-0.03-0.34-0.33-1.84l0,0c-0.19-0.3-0.09-1.48-0.82-3.34c0.13,0.22,0.01-0.09,0.12,0.23c-0.09-0.31-0.08-0.4-0.28-0.69
				c0.11,0.08-0.03-0.26-0.11-0.39l0.05,0.23c-0.08-0.16-0.14-0.29-0.21-0.43l0.11,0.04c-0.31-0.58-0.69-1.4-1.9-2.4
				c0.1,0.34-0.5-0.66-0.48-0.43c-0.3-0.08-0.5-0.66-2.66-1.14c0.08-0.01,0.16-0.02,0.23-0.02c-0.14,0-0.25,0-0.38,0.02
				c0.05,0.01,0.1,0.03,0.14,0.05c-0.14-0.01-0.26,0.02-0.26,0c-0.26,0.26-0.05,0.07-0.62,0.61c0.09-0.05-0.12-0.55-0.14,0.66
				c-0.05-0.07-0.08,0.13-0.06,0.21c-0.01-0.18,0.11,0.06,0.22,0.55l-0.01-0.18c0.18,0.26,0.07,0.8,0.21,2.05
				c0.04-0.12,0.02-0.13,0.12,0.12l-0.14-0.61l0.15,0.38c-0.03-0.19-0.1-0.39-0.11-0.5c0.08,0.18,0.06,0.16,0.1,0.07
				c0.07,0.53-0.01,0.62,0,0.97c-0.14-0.19-0.35-0.42-0.73-0.68c0.14,0.06,0.01-0.12,0.26,0.08c-0.6-0.37,0.13,0.04-2.41-0.94
				c0.11-0.04,0.12-0.12-0.08-0.06c0.13,0.27-0.89,0.1-1.44,2.39l-0.04-0.07c0.12,0.36,0,0.13,1.11,1.86c-0.02,0-0.04,0-0.05,0
				c0.07,0.03,0.2,0.15,0.22,0.56l-0.15-0.28c0,0.19,0.2,0.36,0.26,0.52c-0.21-0.16,0,0-0.65-0.55c-0.19,0.09-0.17,0.28-1.12-0.72
				c0.03,0.07-0.16,0.02-0.38-0.08l0.29,0.18c-0.56-0.12-0.42-0.3-1.01-0.37l0.19,0c-0.51,0-0.63-0.51-3.5,0.17l0.04-0.05
				c-0.32,0.19-0.3,0.02-0.85,0.6c0.02-0.05,0.05-0.11,0.09-0.16c-0.2,0.31-0.39,0.22-0.45,1.67c-0.11-0.01-0.17,0.54-0.16,0.82
				l-0.02-0.01c0.36,1-0.42,0.25,1.75,4.03C569.48,132.5,569.7,132.61,569.91,132.92z M581.82,139.34
				c-0.09,0.01-0.07,0.02-0.18-0.07C581.69,139.28,581.63,139.28,581.82,139.34z M594.25,157.92c-0.01,0-0.02-0.01-0.03-0.01
				C594.24,157.91,594.22,157.9,594.25,157.92z M594.14,158.38c-0.01,0.01-0.02,0.02-0.02,0.03
				C594.1,158.4,594.09,158.37,594.14,158.38z M594.11,157.91c0.01-0.01-0.01-0.04-0.02-0.07
				C594.09,157.85,594.19,157.86,594.11,157.91z M593.8,209.53c-0.01-0.1,0.01-0.13,0.02-0.14c0.01,0.04-0.01,0,0.01,0.18
				C593.82,209.56,593.81,209.55,593.8,209.53z M569.96,184.35c0.12,0.1,0.07,0.05,0.14,0.25
				C570.07,184.53,570.12,184.59,569.96,184.35z M569.96,185.36c0.02,0.04,0.06,0.1,0.11,0.21
				C570.02,185.48,570.07,185.57,569.96,185.36z M570.41,186.13c-0.04-0.04,0.04,0.14,0.09,0.28
				C570.44,186.31,570.27,186.04,570.41,186.13z M594.3,208.88c0,0.06,0,0,0,0.15C594.3,208.99,594.3,208.95,594.3,208.88z
				 M594.26,208.48c0.01,0.03,0.04-0.11,0.06-0.19C594.3,208.22,594.36,208.61,594.26,208.48z M604.87,159.39
				c0.01-0.05-0.12,0.24-0.14,0.09C604.73,159.51,604.81,159.43,604.87,159.39z M604.59,159.76c-0.02,0.04,0,0-0.06,0.1
				C604.54,159.84,604.56,159.81,604.59,159.76z M603.9,159.95c-0.01-0.07-0.02-0.06,0.07-0.12
				C603.96,159.86,603.96,159.82,603.9,159.95z M598.3,153.9c-0.01-0.02-0.04,0.13-0.08,0.03
				C598.24,153.95,598.27,153.92,598.3,153.9z M598.2,154.07c0,0.02,0,0-0.02,0.04C598.19,154.11,598.2,154.1,598.2,154.07z
				 M597.7,154.06c-0.01-0.01,0,0-0.02-0.03C597.68,154,597.74,153.97,597.7,154.06z M596.8,157.05c-0.01,0-0.01,0.01-0.02-0.02
				L596.8,157.05z M589.71,135.32c-0.01-0.09,0-0.13,0.01-0.14c0.01,0.04-0.01,0,0.03,0.18
				C589.74,135.35,589.72,135.34,589.71,135.32z M590.13,134.26c0.02,0.03,0.03-0.11,0.05-0.19
				C590.16,134,590.23,134.39,590.13,134.26z M590.19,134.65c0.01,0.06,0,0,0.01,0.14C590.19,134.77,590.19,134.72,590.19,134.65z
				 M592.3,146.32c0-0.01,0.01-0.02,0.02-0.06c0,0.05,0.01,0.09,0.02,0.14C592.32,146.37,592.31,146.35,592.3,146.32z
				 M602.09,129.29c0.01-0.02,0.02-0.03,0.03-0.04C602.12,129.26,602.13,129.27,602.09,129.29z M591.15,142.4
				c0.02-0.19-0.01-0.16,0.05-0.27c-0.1,0.17-0.31-0.98-0.06-0.56c-0.34-0.61-0.01-0.04-0.51-2.01c-0.13,0.05-0.13,0.09-0.16-0.24
				c0.38-0.31,0.18,0.1-0.17-2.49l0.1,0.1c-0.16-1.06-0.24-1.22-0.01-6.26l0.01,0.09c0.06-0.33-0.12-0.39-0.02-0.69l0.01,0.06
				c0.03-0.28,0.36-2.31,0.69-4.09l0.08,0.02c-0.03,0,0-0.2,0.16-0.54c-0.14,0.05,0.13-0.12,0.47-1.07
				c0.02,0.03-0.03,0.12-0.05,0.21c0.27-0.53,0.01-0.12,0.68-0.92l0,0.07c1.97-1.21,2.06-1.48,4.11,4.12l0-0.03
				c-0.03,0.06,0.06,0.05-0.08,0.28c0.27-0.06-0.36,0.48-0.22,0.93l0.05-0.11c0.01,0.03-0.02,0.06,0.01,0.13
				c-0.01,0-0.02,0-0.04-0.01c0.1,0.06,0.25,0.11,0.4,0.09c-0.07-0.01-0.16-0.04-0.28-0.06c0.34-0.05,0.47,0.32,0.68-0.69l0.01,0.17
				c0.13-0.75-0.13-0.09,0.84-1.74c-0.44,0.27,1.81-2.19,4.32,1.28c-0.01-0.05-0.04-0.05-0.03-0.12c-0.03,0,0.03,0.11-0.14,0.3
				l0.04,0.01c-0.27-0.41-0.29,1.71,0.59,0.24c-0.02,0.02-0.05-0.01-0.15,0.09c0.1-0.17-0.01-0.01,0.39-0.4
				c0.03,0.1,0.48-0.14,0.66-0.2c-0.05,0.05,0.16,0,0.1,0.06c0.2-0.03,0.39-0.07,0.35-0.12c0.96,0.32,0.91-0.2,2.36,1.19
				c-0.61-0.11,0.38-0.37,0.42,2.16c0.01,0,0.04-0.08,0.13,0.45c-0.11-0.19-0.13,0.49-0.26,0.19c0,0.06,0,0.15,0.04,0.14
				c-0.03,0.09-0.06,0.11-0.07,0.01c-0.07,0.34,0.02,0.24-0.08,0.55c-0.02-0.01-0.01-0.07-0.03-0.11c0,0.17-0.02,0.34-0.16,0.58
				c-0.01-0.02-0.04,0,0-0.07c-0.19,0.42-0.27,0.77-0.62,1.17c0-0.1-0.03-0.16-0.38,0.31l0.11-0.06c-0.16,0.19-0.26,0.39-0.43,0.53
				l0.1-0.16l-0.31,0.34l0.21-0.16c-0.14,0.21,0.11,0.07-0.15,0.28c0.04-0.05-0.18,0.17-0.31,0.22l0,0
				c-0.35,0.41-0.21-0.02-0.87,0.77c0.02-0.18-0.36,0.14-0.39,0.28c0.02-0.02,0-0.02,0.05-0.07c-0.35,0.28-0.5,0.37-0.63,0.44
				l0.08-0.07c-0.47,0.32,0.03-0.03-1.57,1.23l0.09-0.05c-0.41,0.45-0.45,0.08-1.51,1.16l0.03-0.07c-0.79,0.65-0.67,0.43-2.35,1.75
				c0.11-0.13,0.18-0.19-0.34,0.26c0.07,0.02,0.08-0.01,0.2-0.05c-0.03,0.07-0.14,0.18-0.25,0.26c0.17-0.18-0.06-0.07-0.16-0.04
				c0.02,0,0.01,0.04,0.03,0.05c-0.29,0.09,0.03,0.12-0.32,0.3c-0.01-0.06,0-0.14-0.71,0.52l0.23-0.22
				c-0.22,0.07-0.23,0.1-0.36,0.19l0.01-0.01l-0.32,0.2l0.09-0.04c-0.11,0.3-0.43,0.36-0.65,0.68c0.22-0.3,0.01-0.09-0.01-0.16
				c-0.09,0.04-0.22,0.11-0.2,0.07c-0.09,0.08-0.05,0.17-0.56,0.4c0.82-0.38-1.53,1.05-1.86,2.5c-0.21-0.1-0.02,0.98-0.54-0.89
				c0.2,0.22-0.15-0.53,0.01-0.5c-0.12-0.1-0.19-0.37-0.26-0.64l0,0c0,0,0,0,0-0.01c0.07,0,0.17,0.01,0.32,0l-0.06-0.05l0.06-0.01
				l0,0.04c0.06-0.01,0.01-0.03-0.02-0.05c0.03,0,0.05,0,0.06-0.01l-0.1-0.06c0.05,0,0.09,0.03,0.12,0.01
				c-0.05-0.03-0.1,0.01-0.13-0.03c0.06-0.01,0.1,0,0.06-0.03c0.03,0,0.05,0.01,0.06,0.02c-0.03-0.03-0.01-0.06-0.07-0.08
				c0.07-0.01-0.01-0.08,0.1-0.06c-0.03-0.01-0.08-0.05-0.08-0.07c0.13,0.06,0.02-0.08,0.16-0.05c-0.11-0.04-0.04-0.11-0.1-0.17
				c0.01,0,0.03,0.01,0.02,0.02c0.01-0.03,0.05-0.03,0.07-0.04c-0.1-0.02-0.11-0.06-0.17-0.09c0.1-0.02,0.11,0.04,0.2,0.04
				c-0.08-0.01-0.07-0.07-0.14-0.07c0.06-0.02,0.03-0.02,0.11-0.02c-0.12-0.02-0.02-0.03-0.09-0.06c0.07-0.03,0.11,0.01,0.08-0.04
				c0.15,0.08,0.03-0.2-0.16-0.34c0,0.01,0.02,0.02,0.04,0.02l-0.02-0.02c0.13,0.01,0.07,0.1,0.13-0.04l-0.04,0.04
				c0.19-0.06-0.09-0.32,0.32-1.55c-0.06-0.02-0.1-0.19,0.07-0.29l-0.07-0.05l0.09,0c-0.1-0.08,0.12,0.04-0.01-0.2
				c0.03,0.01,0.04,0.03,0.06,0.04c-0.11-0.06,0.11-0.02-0.04-0.07c0.05-0.01,0.06-0.07,0.05-0.11c0.09-0.01,0.05,0.03,0.07-0.21
				c-0.03,0.02-0.12,0-0.12-0.02l0.08,0.01c-0.05-0.02-0.17-0.05,0.03,0l-0.02-0.04c0.02,0,0.04,0.01,0.07,0.02
				c-0.05-0.02,0.09-0.05,0.01-0.08c0.15,0.29-0.03-0.87,0.31-1.45c-0.01,0-0.11-0.01,0-0.03c-0.2,0.08,0.19-0.62,0.12-0.57
				c-0.07-0.14,0.06-0.24,0-0.38c0.02,0.01,0.04,0.02,0.04,0.03c0.06-0.01,0.02-0.09-0.02-0.1c-0.02,0.08,0.12-0.19,0.03-0.35
				c0.11,0.03,0.11-0.06,0.06-0.1c0.03,0.02,0.04-0.02,0.08,0.01c0.01-0.03-0.01-0.07-0.07-0.07c0.15-0.03-0.08-0.14,0.1-0.14
				c-0.01-0.16-0.11,0.01,0.05-0.61c0.01,0,0.02,0.01,0.03,0.02c0-0.01,0.02-0.03-0.03-0.04l0.01,0.02c-0.08,0-0.14-0.09-0.17-0.1
				c0.14,0.06,0.05-0.06,0.22,0.02c-0.01-0.01-0.03-0.03-0.06-0.04c0.1-0.08,0.02-0.01,0.14-0.34c-0.02,0.02,0.03-0.06-0.07-0.04
				c0.03,0.02-0.06-0.14,0.02-0.07l0-0.07c-0.05,0.01-0.05,0.03-0.12-0.04c0.06-0.02,0.04-0.06,0.17-0.08
				c-0.07-0.04,0-0.09-0.08-0.13c0.02,0.01,0.06,0.02,0.07,0.02l-0.06-0.03c0.05-0.02,0-0.03,0.09-0.14
				c0.01,0.01-0.01,0.05,0.05,0.02l-0.05-0.02c0.1,0.04-0.08-0.2,0.05-0.33c0.01,0.01,0.07,0.03,0.05,0.02
				c0.03-0.04,0-0.11-0.02-0.2c0,0.03,0.16-0.08,0.02-0.12c0.1-0.03,0.25-0.1,0.01-0.37c0.03,0.04,0.09,0.02,0.12,0.02l-0.05-0.02
				c0.15,0.02-0.09-0.06,0-0.06c-0.01-0.02-0.04-0.12,0.05-0.03c0-0.1-0.01,0.02,0.12-0.12c-0.21-0.06-0.01,0-0.13-0.16
				c0.09,0.07,0,0.03,0.2,0.04c-0.05-0.04,0.06-0.03-0.02-0.08c0.02,0.01,0.01-0.02,0.07,0c-0.12,0.2-0.1-0.38,0.06-0.41
				c-0.01-0.03-0.05-0.07-0.06-0.1c0.04,0,0.06,0.03,0.08,0.05c-0.02,0,0-0.06-0.09-0.1c0,0.04-0.02,0.14-0.13,0.09
				c0.02-0.01,0.01,0.12-0.11,0.08c-0.06,0.07-0.08,0.12,0.11,0.2l-0.19-0.02l0.07,0.03c0,0.01-0.01,0.02-0.04,0.01
				c0.04,0.04,0.03,0,0.08,0.03c-0.08,0.01,0,0.1-0.11,0.08c0.05,0.03,0.05-0.02,0.08,0.03c-0.14-0.06,0.04,0.11-0.14,0.03
				c0.09,0.11-0.13,0.06,0.01,0.21c-0.03-0.02,0.01,0.03-0.13,0.1c0.09,0.03,0.06,0.07,0.07,0.11c-0.05-0.02-0.07-0.03-0.09-0.05
				c0,0.03-0.01,0.03,0.04,0.08l-0.06-0.03c-0.01,0.04,0.06,0.1,0,0.14c0.06,0.02,0.18,0.01,0.21,0.07
				c-0.03-0.01-0.09-0.02-0.1-0.04c-0.07,0.05,0.06-0.01-0.16,0.04c0.02,0.01,0.14,0.07-0.03,0.05c0.03,0.02-0.01,0.03,0.06,0.05
				c0,0.01-0.04,0-0.06,0c0.03,0.03,0,0,0.07,0.09l0,0c0.15-0.01-0.13,0.12-0.18,0.46c-0.01-0.05-0.12,0.07-0.02,0.02
				c-0.2,0.22,0.12,0.39-0.23,0.47c0.02,0.03,0.04,0.02,0.06,0.01c-0.05,0.04-0.02,0.07-0.06,0.11c0.07,0,0.14,0.22-0.08,0.09
				c0.04,0.06-0.06,0.07-0.07,0.1l-0.02-0.03c0.01,0.02,0.01,0.03,0,0.04l0.06,0c-0.01,0.01-0.02,0.02-0.04,0.02
				c0.03,0.02,0.1,0.01,0.14,0.05c-0.03,0.01,0.02,0.06-0.06,0.05c0.04-0.05-0.08-0.01-0.08-0.06c-0.06,0.07-0.09,0,0.04,0.13
				c-0.04-0.02-0.08-0.01-0.08,0c0.01-0.02,0.07,0.01,0.1,0.04c-0.04,0.03,0.02,0.05,0.01,0.06c0.04-0.01,0.04-0.03,0.06,0.05
				c-0.03-0.01-0.02-0.03-0.03-0.02c0.07,0.05-0.09,0.04-0.05,0.1c-0.03,0.01-0.08-0.02-0.11-0.01c0.08,0.05-0.13,0.05,0.06,0.13
				l-0.02-0.09c0.1,0.09,0.15,0.01-0.04,0.27c0.04-0.01,0.06-0.03,0.05,0.06c-0.05-0.01-0.07,0-0.1,0c0.04,0.01-0.02,0.05,0.08,0.08
				c-0.07,0.04-0.14,0,0.02,0.17c-0.05-0.03-0.09-0.01-0.12-0.06c0.03,0.07-0.01-0.01,0.14,0.19c-0.16,0.02-0.02,0.16-0.17,0.18
				c0.05,0.25,0.08,0.06-0.02,0.58c-0.06-0.03-0.03-0.03-0.09-0.03c0,0.02,0.1,0.02,0.11,0.06c0.13-0.07-0.32,0.11-0.11,0.39
				c-0.07-0.04-0.09-0.1-0.13-0.13c-0.01,0.02-0.01,0.08,0.04,0.1c0-0.03,0-0.04,0.08,0.1c-0.19-0.05-0.07,0.11-0.23,0.11
				c0.05,0.1-0.06,0.16-0.07,0.25c0.07-0.01,0.21,0.13-0.01,0.05c0.06-0.02,0.07,0.03,0.12,0.03c-0.03,0.03-0.12,0.06-0.26,0.05
				c0.05,0,0.07,0.03,0.05,0.06c0.04-0.06,0.04,0.13-0.04,0.06c0.02,0.04-0.03,0.13,0.09,0.19c-0.27-0.15,0.05,0.27-0.25,1.22
				l0-0.01c-0.1,0-0.08,0.05-0.09,0.08l0.13,0.03c-0.2,0.05,0.03,0.24-0.15,0.26c0.03,0.03,0.02,0.05,0.05,0.08
				c-0.07,0.01-0.09-0.04-0.11,0.14l-0.05-0.02c0.1,0.04-0.09,0.16,0.06,0.44c-0.09-0.01-0.02,0.07,0.01,0.12
				c-0.11-0.02-0.08-0.08-0.04,0.25c0.04,0.02,0.03,0.09-0.03,0.18c0.01-0.05,0.02-0.09,0.02-0.08c-0.04-0.06-0.23,0.2-0.03,0.25
				c-0.01,0,0-0.01-0.01-0.03l0.01,0c0-0.01,0-0.03,0-0.05c0.03,0.02,0.06,0.04,0.02,0.06c0.04,0.02,0.09,0.04,0.11,0.04
				c-0.04,0.05-0.24,0.05-0.09,0.37c-0.27,0.09-0.11-0.02-0.1,0.34c0.01-0.01,0.02-0.01,0.03-0.01c-0.02,0.07-0.17,0.13-0.05,0.52
				l-0.08,0c0.12-0.11,0.03,0.5-0.04,0.19c0.01,0.02,0.04,0.05,0.04,0.08c0.21-0.03-0.54-0.96-0.1-0.81
				C591.29,142.73,591.2,142.31,591.15,142.4z M591.47,143.54c0.01,0.01,0.03,0.02,0.01,0.02
				C591.47,143.55,591.47,143.55,591.47,143.54z M591.99,141.86c0.02,0.01,0.03-0.01,0.05-0.02
				C592.03,141.86,592.04,141.89,591.99,141.86z M591.57,141.95c-0.01,0-0.03-0.01-0.03-0.01
				C591.53,141.93,591.55,141.91,591.57,141.95z M580.73,134.68c0.01-0.01-0.06-0.06,0.02-0.06
				C580.73,134.63,580.74,134.66,580.73,134.68z M580.98,134.23c0.01-0.01,0.02-0.01,0.03-0.01
				C581.03,134.23,581.01,134.25,580.98,134.23z M588.3,142.37c-0.03,0-0.03,0.02-0.04,0.04c-0.01-0.01-0.01-0.01-0.01-0.02
				C588.27,142.39,588.28,142.38,588.3,142.37z M589.18,143.04c0.02,0.05,0.05,0.07,0.1,0.06c-0.01,0.01-0.01,0.02-0.01,0.02
				c-0.12-0.06-0.29-0.18-0.27-0.11c-0.02-0.03-0.04-0.05-0.06-0.08C589.06,142.98,589.2,143.03,589.18,143.04z M588.73,142.68
				c-0.05,0.03-0.06,0.03-0.07,0.04c-0.04-0.02-0.07-0.04-0.11-0.07C588.59,142.62,588.56,142.7,588.73,142.68z M588.46,142.58
				c-0.02-0.01-0.04-0.03-0.05-0.04C588.46,142.56,588.46,142.54,588.46,142.58z M588.36,142.47c-0.02-0.04,0.1,0.13-0.04-0.01
				C588.34,142.45,588.36,142.44,588.36,142.47z M586.49,141.27l0.01-0.05c0.04,0.03,0.11,0.01,0.13,0.06l-0.05,0.01l0.08,0.04
				l-0.02-0.06c0.11,0.01,0.08-0.14,0.14,0.18c0.07-0.05,0.1,0.06,0.18,0.01c-0.02,0.01-0.05,0.05-0.05,0.06l0.06-0.04
				c0,0.06,0.02,0.01,0.13,0.16c-0.08-0.04-0.22-0.12-0.62-0.36L586.49,141.27z M581.46,138.66c-0.03-0.01-0.07-0.03-0.15-0.06
				C581.37,138.62,581.31,138.6,581.46,138.66z M580.88,138.46c0.04,0-0.1-0.08-0.18-0.14
				C580.63,138.31,581.05,138.42,580.88,138.46z"
                />
                <path
                  className="st10"
                  d="M584.38,183.91c-0.11-0.09-0.11,0.16,0.18,0.39l-0.03-0.1c0.14,0.16,0.33,0.23,0.46,0.35
				c-0.05-0.02-0.1-0.04-0.15-0.06c0.11,0.08,0.22,0.13,0.34,0.2l-0.17-0.14c0.2,0.07,0.04-0.11,0.26,0.05
				c-0.05-0.02,0.17,0.1,0.25,0.18h0c0.45,0.05,0.05,0.25,0.91,0.18c-0.11,0.12,0.29,0.13,0.38,0.03c-0.03,0.01-0.01,0.01-0.07,0.02
				c0.36-0.05,0.51-0.03,0.63-0.01l-0.1,0.02c0.45-0.03-0.03,0.04,1.51-0.41l-0.08,0.02c0.4-0.27,0.34,0.08,1.36-0.55l-0.04,0.05
				c0.7-0.3,0.57-0.23,2.12-0.61c-0.08,0.05-0.11,0.04,0.31-0.02c-0.03-0.06-0.05-0.04-0.14-0.07c0.05-0.04,0.17-0.07,0.27-0.07
				c-0.19,0.04,0,0.09,0.08,0.13c-0.01-0.02,0.01-0.04,0.01-0.05c0.21,0.12,0.04-0.11,0.33-0.03l-0.02,0.03
				c0.06,0,0.51-0.01,0.2,0.07c0.16,0.02,0.35-0.07,0.48,0.04c-0.07,0-0.17-0.03-0.24-0.02c0.15,0.12,0.06,0.02,0.51,0.26
				l-0.06-0.04c0.25-0.1,0.4,0.11,0.7,0.13c-0.3-0.02-0.07,0.04-0.12,0.1c0.06,0.05,0.14,0.12,0.1,0.13
				c0.14,0.01,0.17,0.14,0.37,0.16c0,0.02,0.05,0.05,0.07,0.08c-0.27-0.29,1.07,0.62,1.86,1.42c-0.12,0-0.24-0.01,0.47,0.49
				l-0.06-0.1c-0.03,0.04-0.15,0.06-0.18-0.16l0.09,0.07c0.03-0.18-0.32-0.31-0.47-0.47c0.08-0.03,0.15-0.05-0.3-0.46
				c0.04,0,0.03-0.07,0.09,0.01c-0.17-0.24-0.39-0.25-0.42-0.38l0.02,0.01c-0.66-0.54-1.23-0.96-2.44-1.33
				c0.03-0.03-0.46-0.16-0.7-0.21l0.01-0.01c-0.06,0.09-0.3,0.05-0.48,0.08c0.01-0.06,0.04-0.08,0.14-0.1
				c-0.02,0.02-0.02-0.05-0.37,0.07c0.14-0.27-0.11,0.05-0.83,0.23l0.1-0.08c-0.04,0.03-0.38,0.1-0.11-0.01
				c-0.53,0.1-0.24,0.03-1.21,0.43l0,0c-0.32,0.2,0,0-1.06,0.42c-0.04-0.11-0.32,0.15-1.13,0.35c0.15-0.08-0.06-0.02,0.16-0.07
				c-0.21,0.04-0.27,0.01-0.47,0.12c0.06-0.08-0.17-0.05-0.27-0.01l0.15,0.02c-0.11,0.03-0.21,0.04-0.31,0.05l0.04-0.09
				c-0.45-0.06-0.91,0.18-1.88-0.16c0.04,0.27-0.29-0.41-0.32-0.23c-0.16-0.17-0.28-0.24-0.39-0.45c-0.05,0.08-0.46-0.54-0.12-0.45
				c-0.08-0.18,0.06-0.34,0.11-0.43l-0.01,0.1c0-0.06,0.02-0.11,0.04-0.16l-0.08,0.03c0.03-0.06,0.06-0.11,0.08-0.09
				c-0.03-0.17-0.19-0.08,0.11-0.4c-0.23,0.3,0.15,0.31,0.23-0.28c0.01,0.06,0.11,0,0.13-0.04c-0.06,0.07-0.09-0.09,0.14-0.36
				l-0.09,0.07c0.06-0.23,0.38-0.35,1.12-0.73c-0.09-0.01-0.1,0,0.03-0.13c-0.11,0.08-0.22,0.16-0.32,0.25l0.17-0.21
				c-0.11,0.06-0.2,0.17-0.27,0.21c0.07-0.11,0.07-0.09,0-0.1c0.28-0.33,4.91-0.42,0.85-0.83c0.01-0.01,0-0.02,0.04-0.01
				c-0.12,0.01-0.11-0.08-0.32,0.1c0.05-0.06,0.1-0.1,0.13-0.12c-0.32-0.04,0.04,0.04-0.69-0.3l0.04,0.01
				c-0.1-0.22-0.21,0.14-0.97-0.9c0.04,0,0.06,0,0.08,0c-0.06-0.01-0.2-0.08-0.05-0.37c0,0.06,0.02,0.13,0.03,0.19
				c0.07-0.11-0.04-0.24-0.02-0.34l0.04,0.01c0.02-0.26-0.09-0.31,0.09-0.58l-0.05,0.01c0.22-0.35,0.18-0.12,0.6-0.41
				c-0.07,0.05-0.15,0.11-0.19,0.16c0.08-0.06,0.33-0.17,0.38-0.22c-0.04,0.02-0.09,0.06-0.14,0.06c0.98-0.31,0.01-0.15,2.85,0.01
				c0.12-0.29,0.05-0.21,1.02-0.17c-0.08-0.01,0.11-0.14,0.34-0.3c-0.13,0.07-0.24,0.13-0.36,0.17c0-0.23,0.96-0.12,0.29-0.81
				c0.06,0.05,0.12,0.1,0.18,0.16c-0.38-0.51-0.2-0.35-1.83-1.79l0.05,0c-0.19-0.13-0.06-0.11-0.43-0.4l0.09,0.04
				c-0.25-0.35-0.51-0.44-0.7-0.74c0.29,0.19-0.43-4.36,2.98-1.79l-0.04,0.06c0.26,0.19,0.38,0.28,0.69,0.51l-0.09-0.01
				c0.67,0.31,0.33,0.09,1.36,1.01c-0.02,0.01-0.04,0.02-0.09-0.02c0.14,0.11,0.44,0.54,0.5,0.39c-0.06,0.07,0.27,0.29,0.45,0.41
				l-0.07,0.02c0.03-0.01,0.22,0.15,0.61,0.51l-0.09-0.04c0.19,0.24,0.27,0.02,0.44,0.55c-0.06-0.07,0.26,0.16,0.14,0.15
				c0.13,0.33,0.06-0.06,0.43,0.63c-0.14-0.22-0.28-0.39-0.26-0.38c-0.05,0.04,0.07,0.21-0.11,0.13c0.13,0.12,0.04-0.04,0.48,0.52
				l-0.02-0.01c0.17,0.23,0.06,0.16,0.33,0.57c0.06-0.04,0.12,0.08,0.1-0.06c0.01,0.03-0.03-0.04-0.09-0.1l0.01,0
				c-0.04-0.06-0.09-0.13-0.15-0.22c0.52,0.51,0.2,0.48,1.02,1.75c-0.17-0.19-0.14-0.2,0.28,0.57l-0.04-0.03
				c0.11,0.64,0.62,0.86,0.66,1.6c0-0.05-0.01-0.07,0-0.09c-0.06,0.34,0.72,1.86,0.78,4.02l0.05-0.06
				c-0.14,0.51,0.17,0.69,0.44,1.35c0.12-0.28-0.21-0.94,0-0.93c-0.07-0.08,0.14-0.26-0.17-1.13c0.16,0.19-0.05-0.43,0.1-0.39
				c-0.14-0.14-0.13-0.55-0.26-0.81c0.01,0.01,0.04,0.04,0.04,0.06c-0.02-0.15,0-0.21,0.01-0.31c0.04,0.18-0.49-0.63-0.04-0.34
				c-0.07,0.03-0.15-0.31-0.2-0.23c0.02-0.15-0.01-0.13,0.05-0.22c-0.09,0.14-0.31-0.75-0.06-0.45c-0.42-0.48,0.07-0.02-0.68-1.49
				c-0.1,0.06-0.12,0.11-0.19-0.14c0.36-0.41,0.14,0-0.73-1.8l0.13,0.03c-0.68-0.71-0.73-1.74-2.94-3.94l0.06,0.03
				c-0.16-0.21-0.31-0.11-0.44-0.33l0.04,0.02c-0.17-0.13-1.31-1.4-2.54-2.04l0.04-0.07c-0.02,0.03-0.15-0.05-0.4-0.24
				c0,0.28,0.05-0.23-0.95-0.37c0.02-0.03,0.12-0.01,0.2-0.01c-0.56-0.07-0.08-0.08-1.1-0.07l0.04-0.06
				c-2.02,1.64-1.85-0.24,1.21,6.06l-0.02-0.01c-0.01,0.51,0.27,0.24-1.7,0.37l0.02,0.05c-0.11,0.04-0.12-0.03-0.2-0.05
				c0.03,0.41-2.56-0.79-2.71,1.87l-0.09-0.14c0.14,0.82,0.62,1.56,1.35,1.83c-0.05,0-0.06,0.02-0.13-0.02
				c0.06,0.07,0.18,0.12,0.3,0.15c-0.58,0.34-0.05,0.01-1.14,0.95c0.02,0,0.05,0-0.09,0.39c-0.03-0.2-0.28,0.3-0.29,0.02
				c-0.02,0.05-0.04,0.12-0.01,0.13c-0.05,0.06-0.09,0.07-0.07-0.02c-0.12,0.08-0.06,0.4-0.19,0.34c-0.02,0.15-0.05,0.29-0.13,0.52
				c-0.01-0.02-0.04,0-0.02-0.07C584.04,183.21,584.21,183.52,584.38,183.91z M595.07,176.07c-0.01-0.02-0.03-0.05-0.07-0.09
				C595.04,176.02,595.01,175.98,595.07,176.07z M594.92,176.67c-0.06-0.02-0.06,0-0.09-0.1
				C594.86,176.59,594.82,176.58,594.92,176.67z M594.79,175.75c0.03,0.01-0.02-0.09-0.05-0.15
				C594.71,175.58,594.94,175.79,594.79,175.75z"
                />
                <path
                  className="st10"
                  d="M603.52,166.62c0.17,0.11,0.34-0.49,0.92-0.92c-0.01-0.01-0.09-0.06,0.02-0.04
				c-0.34-0.03,0.35-0.22,0.68-0.92c0.01,0.02,0.03,0.04,0.02,0.05c0.01-0.03,0.08-0.1,0.29-0.46c0.08,0.08,0.13-0.01,0.12-0.07
				c0.02,0.03,0.05,0,0.07,0.05c0.03-0.03,0.04-0.07-0.01-0.1c0.15,0.04,0.02-0.18,0.18-0.09c0.08-0.17-0.02-0.13,0.44-0.58
				c0.01,0.01,0.01,0.02,0.01,0.03c0.01-0.01,0.04-0.02,0-0.05l0,0.02c-0.07-0.03-0.06-0.16-0.08-0.19
				c0.08,0.13,0.09-0.03,0.18,0.13c0-0.01,0-0.04-0.03-0.07c0.15-0.03,0.03-0.01,0.36-0.26c-0.03,0.01,0.07-0.05-0.03-0.07
				c0.02,0.04,0.05-0.16,0.07-0.05l0.05-0.07l-0.06,0c0.01-0.1-0.09-0.11,0.17-0.09c-0.04-0.07,0.06-0.08,0.02-0.16
				c0.01,0.02,0.04,0.06,0.05,0.06l-0.03-0.06c0.06,0.01,0.02-0.03,0.18-0.09l-0.01,0.03l0.04,0.01l-0.02-0.04
				c0.06,0.09,0.06-0.22,0.28-0.29c0,0.01,0.04,0.07,0.03,0.05c0.06-0.02,0.08-0.11,0.12-0.2c-0.02,0.04,0.18,0.02,0.1-0.1
				c0.12,0,0.21,0.13,0.29-0.34c-0.01,0.06,0.06,0.07,0.09,0.09c-0.01-0.01-0.02-0.03-0.03-0.04c0.11,0.1-0.03-0.1,0.05-0.06
				c0.01-0.02,0.06-0.14,0.06,0c0.08-0.09-0.04,0.01,0.19-0.04c-0.13-0.17-0.01-0.01,0.01-0.22c0.03,0.11-0.01,0.03,0.14,0.15
				c-0.01-0.07,0.07,0.01,0.05-0.09c0.02,0.02,0.03-0.02,0.06,0.04c-0.17-0.21,0.16,0.03,0.15-0.42c0.05,0.01,0.02,0.16,0.12,0.03
				c0.06,0.13,0.04-0.14,0.1,0.07c0.01-0.04,0.01-0.09,0.03-0.13c0.03,0.03,0.02,0.06,0.03,0.09c-0.01-0.01,0.07-0.06,0-0.16
				c0.06,0.17-0.05-0.16-0.13,0.12l-0.04-0.1c0.08,0.11-0.12,0.08-0.15,0.01c-0.1,0.03-0.16,0.05-0.06,0.24l-0.15-0.13l0.04,0.07
				c-0.01,0-0.03,0.01-0.04-0.02c0,0.06,0.02,0.02,0.04,0.07c-0.08-0.03-0.08,0.1-0.16,0c0.02,0.06,0.06,0.01,0.05,0.07
				c-0.08-0.14-0.05,0.13-0.14-0.05c0,0.15-0.16-0.02-0.15,0.2c-0.01-0.04,0,0.03-0.18,0.02c0.05,0.08,0,0.1-0.02,0.14
				c-0.03-0.04-0.04-0.07-0.04-0.1c-0.02,0.02-0.03,0.02-0.03,0.09l-0.04-0.06c-0.04,0.03-0.03,0.12-0.11,0.13
				c0.04,0.05,0.15,0.11,0.13,0.18c-0.02-0.03-0.06-0.07-0.06-0.09c0,0.01-0.09,0.02-0.07-0.04c-0.03,0.01-0.07,0.01-0.1-0.02
				c0.01,0.02,0.06,0.14-0.06,0.03c0.01,0.03-0.03,0.03,0.01,0.08c-0.01,0.01-0.03-0.02-0.05-0.03c0.01,0.04,0,0-0.01,0.13l0,0
				c0.05,0.13-0.1,0.02-0.42,0.3c0-0.06-0.03-0.02,0.01-0.05c-0.05,0.02-0.08-0.01-0.08,0.08c0.03-0.06-0.16,0-0.03,0.01
				c-0.2,0.01-0.23,0.41-0.45,0.31l0.02,0.03c-0.04-0.01-0.08,0.04-0.1-0.01c-0.01,0.04,0.02,0.05,0.05,0.04
				c-0.07,0.01-0.07,0.06-0.13,0.08c0.06,0.03-0.02,0.29-0.13,0.05c0,0.08-0.1,0.04-0.13,0.06l0-0.04c0,0.02-0.01,0.03-0.02,0.04
				l0.05,0.03c-0.02,0-0.03,0.01-0.05,0c0.01,0.04,0.08,0.06,0.09,0.12c-0.04-0.01-0.03,0.07-0.09,0.02
				c0.09-0.04-0.19-0.23-0.08,0.04c-0.02-0.04-0.07-0.05-0.07-0.03c0-0.02,0.08,0.03,0.03,0.15c0.04,0,0.05-0.01,0.02,0.07
				c-0.03-0.02,0-0.04-0.01-0.04c0.03,0.08-0.11,0-0.11,0.07c-0.04-0.01-0.06-0.06-0.09-0.06c0.04,0.09-0.15-0.02-0.03,0.16
				l0.04-0.1l0.02,0.11c0.02-0.02,0.02-0.07,0.03-0.07c0.01,0.06,0.01,0.04,0.05,0.07c-0.12,0.19-0.15-0.21-0.31,0.15
				c0.04,0.01,0.08,0,0,0.08c-0.03-0.04-0.06-0.04-0.09-0.05c0.03,0.02-0.05,0.04,0.02,0.12c-0.09,0-0.12-0.06-0.09,0.18
				c-0.02-0.05-0.07-0.05-0.07-0.11c-0.02,0.08,0-0.02,0,0.25c-0.12-0.03-0.13-0.03-0.34,0.25c-0.01-0.01-0.02-0.02-0.01-0.02
				c0.06,0.19-0.25-0.04-0.29,0.46c-0.04-0.06-0.01-0.04-0.06-0.08c0.2,0.03-0.28,0.03-0.27,0.47c-0.04-0.07-0.03-0.14-0.04-0.2
				c-0.02,0.02-0.06,0.08-0.03,0.12c0.02-0.03,0.03-0.04,0.01,0.14c-0.14-0.13-0.13,0.08-0.27,0.02c-0.01,0.12-0.15,0.14-0.21,0.24
				c0.07,0.02,0.12,0.22-0.04,0.05c0.06,0.01,0.05,0.06,0.1,0.08c-0.05,0.02-0.14,0.01-0.27-0.05c0.05,0.03,0.05,0.06,0.01,0.08
				c0.08-0.04-0.03,0.15-0.07,0.05c-0.01,0.05-0.1,0.13-0.03,0.24c-0.02,0-0.06-0.02-0.06-0.04c-0.38,0.51-0.17,0.35-0.84,1.15
				c-0.01-0.01,0.12,0.06,0.01,0.11l0.01-0.01c-0.05-0.02-0.23,0.03-0.02,0.13c-0.22-0.01-0.09,0.28-0.27,0.23
				c0.01,0.04,0,0.07,0.01,0.11c-0.08-0.02-0.06-0.07-0.17,0.12l-0.03-0.04c0.05,0.19,0.12,0.07-0.11,0.39
				c-0.01-0.01-0.04-0.03-0.03-0.04c-0.02,0.03-0.14,0.1-0.01,0.16c-0.08-0.05-0.05,0.07-0.04,0.13c-0.13-0.11,0.01,0.03-0.26,0.46
				c0.03-0.05,0.06-0.09,0.06-0.09c-0.03-0.09-0.29,0.13-0.14,0.28c-0.01,0,0-0.01,0-0.04l0,0c0.01-0.02,0.02-0.03,0.02-0.06
				c0.02,0.03,0.04,0.07-0.01,0.07c0.03,0.03,0.07,0.07,0.08,0.08c0,0.07-0.3,0.18-0.48,0.74c0.02,0,0.02-0.01,0.04,0
				c-0.05,0.09-0.2,0.07-0.25,0.58l-0.07-0.02c0.18-0.05-0.21,0.23-0.11,0.66c0-0.01,0-0.01,0-0.01c0.07,0.02,0.16,0.05,0.31,0.08
				l-0.04-0.08l0.06,0.01l-0.02,0.04c0.06,0,0.02-0.04,0-0.07c0.03,0.01,0.05,0.01,0.07,0l-0.08-0.09c0.04,0.01,0.07,0.06,0.11,0.04
				c-0.04-0.04-0.1-0.02-0.12-0.07c0.06,0,0.1,0.02,0.07-0.02c0.03,0.01,0.04,0.02,0.05,0.04c-0.02-0.04,0.02-0.07-0.04-0.11
				c0.07,0,0.02-0.09,0.12-0.04c-0.02-0.02-0.06-0.08-0.05-0.1c0.1,0.1,0.04-0.08,0.17-0.01c-0.09-0.08,0-0.13-0.03-0.21
				c0.01,0.01,0.02,0.02,0.01,0.02c0.02-0.03,0.06-0.02,0.09-0.03c-0.09-0.05-0.08-0.1-0.13-0.15c0.1,0,0.09,0.07,0.18,0.1
				c-0.07-0.03-0.04-0.1-0.11-0.11c0.07,0,0.04-0.01,0.12,0.01c-0.11-0.06-0.01-0.04-0.06-0.09c0.07-0.01,0.11,0.04,0.1-0.02
				c0.1,0.12,0.11-0.19-0.02-0.43c-0.01,0.01,0.01,0.02,0.03,0.04l-0.01-0.03c0.06,0.14,0.17-0.01,0.09,0.03
				c0.16,0.08,0.13,0.07,1.14-1.98l0.09,0.03c-0.07-0.16,0.06,0.13,0.09-0.23c0.02,0.02,0.02,0.04,0.03,0.06
				c-0.08-0.11,0.11,0.03-0.01-0.09c0.05,0,0.09-0.05,0.1-0.1c0.1,0.03,0.03,0.05,0.17-0.2c-0.04,0.01-0.11-0.04-0.1-0.07l0.07,0.04
				c-0.03-0.04-0.13-0.12,0.03,0.01l0-0.05c0.02,0.01,0.03,0.03,0.05,0.05c-0.03-0.04,0.11-0.01,0.05-0.09
				C603.45,167.13,603.26,166.98,603.52,166.62z M601.97,168.95c0.01,0.01,0.04,0,0.06,0C602.01,168.96,602,169.01,601.97,168.95z"
                />
                <path
                  className="st10"
                  d="M597.29,184.8c-0.05-0.04-0.08,0.01-0.17,0l0.18-0.07l-0.07,0.01c0-0.01,0-0.02,0.03-0.02
				c-0.06-0.01-0.03,0.01-0.08,0.01c0.06-0.04-0.05-0.06,0.06-0.1c-0.06,0.01-0.04,0.04-0.08,0.02c0.15-0.03-0.09-0.05,0.11-0.08
				c-0.14-0.01,0.03,0.02-0.05-0.06c0.07-0.05,0.11,0.05-0.06-0.06c0.04,0-0.02-0.02,0.06-0.12c-0.09,0.02-0.08-0.01-0.11-0.03
				c0.05-0.02,0.07-0.02,0.1-0.02c-0.02-0.02-0.01-0.02-0.07-0.03l0.07-0.02c-0.01-0.03-0.09-0.03-0.07-0.08
				c-0.06,0.02-0.15,0.09-0.21,0.07c0.05-0.02,0.09-0.02,0.22-0.14c-0.03,0.01-0.14,0.03,0-0.04c-0.03,0.01-0.01-0.03-0.08,0
				c0-0.01,0.03-0.02,0.05-0.03c-0.04,0,0-0.01-0.11-0.01l0,0c-0.12-0.02,0.04-0.32-0.18-0.78c-0.03-0.01-0.05,0.02-0.05,0.04
				c0.02-0.05-0.02-0.05-0.02-0.1c0.11,0.01-0.22-0.03,0-0.1c-0.07,0,0-0.08-0.01-0.1l0.03,0c-0.02,0-0.02-0.01-0.03-0.02
				l-0.04,0.04c0-0.01,0-0.02,0.02-0.04c-0.04,0.01-0.08,0.06-0.14,0.07c0.02-0.03-0.05-0.02,0.01-0.07c0,0.05,0.06-0.05,0.1-0.02
				c0-0.08,0.06-0.06-0.1-0.04c0.04-0.02,0.06-0.06,0.05-0.06c0.01,0.02-0.06,0.05-0.09,0.05c0.01-0.04-0.04-0.01-0.04-0.03
				c-0.02,0.03-0.01,0.04-0.07,0.02c0.03-0.02,0.03,0,0.04-0.01c-0.08,0.03,0.04-0.09-0.02-0.08c0.02-0.03,0.07-0.05,0.08-0.07
				c-0.08,0,0.01-0.11-0.13-0.02l0.07,0.02c-0.1,0.08-0.21-0.05-0.15-0.22c-0.03,0.02-0.02-0.03-0.1,0.02
				c0.17-0.13-0.24,0-0.01-0.12c-0.06-0.01,0.01,0-0.21,0.02c0.09-0.13-0.09-0.08-0.02-0.2c-0.03-0.01-0.07-0.03-0.09-0.05
				c0,0.09,0.06-0.18-0.28-0.19c0.05-0.04,0.04-0.01,0.08-0.06c-0.21,0.18-0.1-0.05-0.16-0.18c-0.03,0-0.11,0.03-0.15,0.02
				c0.06-0.04,0.12-0.04,0.16-0.06c-0.17-0.02-0.03,0.09-0.34-0.37c-0.02,0.05-0.06,0.05-0.08,0.1c-0.01-0.04,0.02-0.12,0.09-0.24
				c-0.03,0.05-0.06,0.05-0.06,0.02c0.03,0.05-0.12,0-0.03-0.05c-0.04,0-0.08-0.07-0.18,0.02c0.12-0.13-0.09-0.14-0.38-0.18
				c0.02-0.12,0.13-0.29-0.21-0.18c0.02-0.12-0.1-0.12-0.13-0.21c0,0.02-0.04,0.07-0.06,0.08c0-0.03-0.07,0.04-0.02-0.04l0,0
				c0.04-0.09,0-0.09-0.03-0.1l-0.07,0.11c0.03-0.2-0.19-0.01-0.15-0.18c-0.03,0.02-0.05,0.01-0.08,0.04
				c0.01-0.04,0.07-0.08-0.08-0.13c0.14-0.19-0.14-0.06-0.32-0.05c0.03-0.09-0.05-0.03-0.09,0c0.03-0.08,0.09-0.07-0.18-0.07
				c-0.02,0.04-0.08,0.02-0.13-0.05c0.04,0.01,0.06,0.02,0.06,0.03c0.04,0-0.13-0.25-0.19-0.05c0-0.01,0,0,0.02-0.01l0,0.01
				l0.04,0.01c-0.02,0.03-0.04,0.06-0.05,0.01c-0.02,0.04-0.04,0.08-0.05,0.1c-0.03-0.03-0.01-0.24-0.26-0.11
				c-0.02-0.05,0.04-0.08-0.01-0.07c-0.02-0.2,0.01-0.03-0.24-0.03c0,0.01,0.01,0.02,0.01,0.03c-0.03-0.02-0.14-0.18-0.39-0.04
				l0-0.08c0.09,0.22-0.19-0.15-0.42,0.12c0,0,0,0,0-0.01c0,0.07,0,0.17,0.01,0.32l0.04-0.07l0.01,0.06l-0.03,0
				c0.01,0.05,0.02,0.01,0.04-0.02c0,0.03,0,0.05,0.01,0.06l0.04-0.11c0,0.05-0.02,0.09,0,0.12c0.02-0.05-0.01-0.1,0.02-0.13
				c0.01,0.05,0,0.1,0.02,0.05c0,0.03-0.01,0.05-0.01,0.06c0.02-0.03,0.04-0.01,0.06-0.07c0.01,0.07,0.06-0.01,0.04,0.1
				c0.01-0.03,0.04-0.08,0.05-0.09c-0.04,0.13,0.05,0.01,0.03,0.15c0.03-0.12,0.08-0.05,0.12-0.11c0,0.01-0.01,0.03-0.01,0.02
				c0.02,0.01,0.02,0.05,0.03,0.07c0.02-0.1,0.05-0.11,0.08-0.17c0.01,0.1-0.03,0.11-0.04,0.2c0.01-0.08,0.05-0.07,0.06-0.14
				c0.01,0.06,0.01,0.03,0.01,0.11c0.02-0.12,0.02-0.02,0.05-0.09c0.02,0.06-0.01,0.11,0.02,0.08c-0.02,0.17,0.02-0.02,0.21-0.07
				c0,0.04-0.01,0.03,0.05-0.09c-0.01,0-0.01,0.02-0.02,0.04l0.02-0.02c-0.01,0.09-0.03,0.03-0.04,0.09
				c0.02-0.01,0.15-0.09,0.05,0.04l-0.03-0.04c0.05,0.09,0.08,0.18,0.45,0.12c-0.01,0.02-0.03,0.05-0.02,0.07
				c-0.01-0.1,0.27-0.04,0.62,0.23c0.03-0.07,0.2-0.05,0.18,0.11l0.06-0.06l-0.03,0.09c0.09-0.08-0.06,0.11,0.15,0.02
				c-0.02,0.03-0.03,0.03-0.05,0.05c0.08-0.09-0.02,0.11,0.06-0.03c-0.01,0.05,0.02,0.07,0.06,0.07c-0.02,0.06-0.06,0.05,0.12,0.1
				c0-0.03,0.04-0.11,0.06-0.11l-0.04,0.07c0.03-0.04,0-0.04,0.05-0.08c0.01,0.02-0.03,0.09-0.06,0.1l0.04-0.01
				c-0.01,0.02-0.02,0.03-0.04,0.06c0.03-0.04,0,0.09,0.05,0.02c-0.3,0.07,0.68,0.18,0.83,0.63c0.01-0.01,0.07-0.09,0.02,0.01
				c-0.01-0.26,0.13,0.24,0.54,0.41c-0.02,0.01-0.04,0.03-0.04,0.02c-0.03,0.05,0.04,0.05,0.08,0.02c-0.05,0.03-0.02,0.08-0.02,0.09
				c0.03-0.01,0.03,0.05,0.08-0.01c0,0.05,0.06,0.03,0.05,0.07c0.07-0.04,0.01,0.06,0.1,0c-0.09,0.07-0.03,0.1,0.02,0.08
				c-0.03,0.02-0.02,0.04-0.06,0.06c0.02,0.02,0.04,0.02,0.09-0.02c-0.08,0.12,0.14,0,0.02,0.13c0.09,0.02,0.16,0.04,0.22,0.08
				c-0.07,0.09-0.07,0.02,0.11,0.21c-0.01,0.01-0.02,0.01-0.03,0.01c0.01,0.01,0,0.03,0.04,0l-0.02,0c0.05-0.06,0.15-0.05,0.19-0.07
				c-0.13,0.07,0,0.06-0.17,0.14c0.01,0,0.04-0.01,0.06-0.03c-0.03,0.1-0.01,0.01,0.1,0.25c0-0.02,0.01,0.05,0.07-0.02
				c-0.04,0.01,0.12,0.04,0.02,0.05l0.04,0.03c0-0.03,0.16-0.13,0.08,0c0.04,0.01,0.1,0.02-0.05,0.08c0.07-0.03,0.05,0.04,0.13,0.02
				c-0.02,0-0.06,0.03-0.07,0.03l0.06-0.02c-0.08,0.05-0.01,0.06-0.01,0.04c-0.03,0.02,0.14,0.13-0.04,0.13
				c0.04,0.04,0.16,0.05,0.16,0.11c0.13-0.08,0.01,0,0.03,0.05c-0.01,0-0.07,0.02-0.06,0.02c0,0.04,0.06,0.06,0.12,0.09
				c-0.01-0.02-0.09,0.13,0.04,0.08c-0.08,0.08-0.13,0.21,0.18,0.22c-0.04-0.01-0.09,0.03-0.11,0.05l0.05-0.02
				c-0.14,0.07,0.11-0.01,0.03,0.04c0.02,0.01,0.09,0.05-0.03,0.04c0.05,0.06,0-0.01-0.04,0.13c0.21-0.07,0.01,0,0.19,0.03
				c-0.12,0.01-0.01-0.02-0.19,0.07c0.06,0-0.04,0.04,0.06,0.04c-0.02,0.01,0,0.02-0.06,0.03c-0.02-0.18,0.29,0.17,0.12,0.27
				c0.03,0.01,0.08,0.02,0.1,0.04c-0.04,0.01-0.07,0-0.09,0c0.01-0.01,0.03,0.04,0.12,0.02c-0.02-0.03-0.05-0.1,0.08-0.11
				c-0.01,0.01-0.06-0.08,0.06-0.09C597.36,184.82,597.29,184.88,597.29,184.8z M592.96,180.56c-0.01,0.01,0,0.03,0,0.05
				C592.94,180.6,592.93,180.61,592.96,180.56z M592.97,180.15c0.01-0.01,0.01-0.02,0.01-0.03
				C592.99,180.11,593,180.13,592.97,180.15z"
                />
                <path
                  className="st10"
                  d="M621.15,148.32c0.07,0.56,0.14,0.82,0.36,1.46l-0.09-0.11c0.61,1.12,0.23,0.55,1.27,2.6
				c-0.02,0-0.05,0-0.11-0.09c0.14,0.27,0.52,1.07,0.59,0.9c-0.08,0.06,0.33,0.58,0.55,0.85l-0.09-0.01
				c0.04,0.02,0.29,0.34,0.8,1.05l-0.11-0.1c0.27,0.45,0.31,0.17,0.59,1c-0.08-0.12,0.3,0.31,0.19,0.28
				c0.2,0.54,0.06-0.07,0.57,1.13c-0.19-0.39-0.36-0.7-0.34-0.68c-0.06,0.03,0.1,0.35-0.11,0.14c0.16,0.24,0.04-0.03,0.61,0.98
				l-0.02-0.03c0.2,0.42,0.08,0.25,0.38,1c0.06-0.04,0.13,0.18,0.11-0.04c0.01,0.05-0.03-0.08-0.1-0.2l0.01,0.01
				c-0.05-0.11-0.11-0.24-0.17-0.4c0.58,1.05,0.29,0.83,1.02,3.16c-0.03-0.08-0.05-0.19-0.08-0.23c-0.02,0.03,0.34,0.73,0.31,1.26
				l-0.04-0.07c0.06,1.06,0.54,1.63,0.58,2.82c0-0.08-0.01-0.12,0-0.14c-0.01,0.57,0.66,3.14,2.12,6.5l0-0.1
				c0.1,0.23,0.39,0.7,0.32,0.68c0.18,0.12,0.94,2.1,1,1.5c0.14,0.29,0.48,0.16-0.07-0.72l0.18,0.28c-0.12-0.34-0.54-0.97-1.6-2.9
				c0.25,0.26-0.27-0.64-0.1-0.61c-0.21-0.18-0.38-0.81-0.58-1.21c0.27,0.19,0.09,0.13-0.16-0.9c-0.07,0.04-0.22-0.48-0.26-0.37
				c0-0.24-0.03-0.2,0.02-0.34c-0.09,0.2-0.4-1.22-0.11-0.71c-0.42-0.8,0-0.04-0.68-2.53c-0.15,0.07-0.12,0.09-0.18-0.3
				c0.3-0.3,0.27,0.17-0.59-3.13l0.13,0.11c-0.75-1.28,0.57-0.42-3.6-7.17l0.07,0.08c-0.19-0.38-0.37-0.34-0.51-0.71l0.05,0.06
				c-0.14-0.33-1.42-2.53-1.66-4.85l0.08-0.01c-0.05-0.12-0.07-0.18,0.06-0.68c-0.07,0.14-0.03-0.28,0.65-1.16
				c0,0.03-0.08,0.12-0.13,0.2c0.5-0.45,0.04-0.21,1.18-0.5l-0.06,0.05c0.89-1.73,2.01,3.73,3.61,2.85l0.02,0.01
				c-0.01-0.54-0.02,0.05,0.61-2.54l-0.01,0.03c0.39-0.48,1.36-2.4,3.79-0.77l0-0.06c1.13,0.35,0.17,1.02,2.33,4.77
				c0.01-0.09,0-0.01,0.07-0.19l0.09,0.18c-0.16-1.05,0.17-2.15,0.58-3.08c0.02,0.52,0.39-1.31,2.68-2.3
				c0.01,0.1,0.66-0.08,0.9-0.13c-0.08,0.04,0.21,0.02,0.12,0.07c0.27-0.01,0.51-0.01,0.47-0.07c1.25,0.53,1.13-0.03,3.01,1.81
				c-0.65-0.14,0.22-0.51,1.05,2.69c-0.01,0-0.04-0.07-0.06-0.13c0.13,0.38-0.06-0.28,0.33,0.64c-0.17-0.2,0.03,0.64-0.19,0.32
				c0.02,0.08,0.05,0.18,0.08,0.16c0,0.12-0.02,0.16-0.07,0.04c0.04,0.45,0.11,0.29,0.09,0.7c-0.02,0-0.03-0.09-0.06-0.13
				c0.04,0.21,0.08,0.42,0.04,0.77c-0.01-0.02-0.04,0.02-0.03-0.09c-0.07,0.35,0.16,0.23-0.11,1.69c-0.04-0.11-0.1-0.17-0.21,0.59
				l0.07-0.13c-0.04,0.31-0.07,0.59-0.17,0.84l0.03-0.23l-0.15,0.57l0.13-0.31c-0.04,0.32,0.12,0-0.02,0.4
				c0.02-0.08-0.09,0.3-0.19,0.44l0,0c-0.14,0.63-0.21,0.14-0.5,1.39c-0.06-0.2-0.27,0.39-0.25,0.56c0.01-0.04,0-0.03,0.02-0.11
				c-0.25,0.52-0.36,0.72-0.46,0.88l0.06-0.14c-0.33,0.64,0.02-0.04-1.13,2.24l0.07-0.1c0,0.43-0.29,0.01-1.21,2.07l0.02-0.09
				c-0.64,1.13-0.58,0.82-1.95,3.15c0.08-0.2,0.13-0.3-0.27,0.46c0.08-0.02,0.08-0.06,0.19-0.16c-0.02,0.09-0.09,0.27-0.18,0.42
				c0.13-0.28-0.08-0.04-0.18,0.05c0.03-0.01,0.02,0.04,0.04,0.04c-0.27,0.26,0.07,0.11-0.24,0.5c-0.03-0.07-0.03-0.17-0.55,0.97
				c0.05-0.12,0.11-0.25,0.16-0.37c-0.2,0.2-0.2,0.24-0.31,0.41l0.01-0.02l-0.26,0.4l0.08-0.1c-0.01,0.39-0.29,0.65-0.39,1.12
				c0.1-0.46-0.02-0.09-0.08-0.16c-0.07,0.1-0.17,0.25-0.17,0.2c-0.05,0.16,0.02,0.17-0.39,0.79c0.22-0.46,0.06,0.49-0.97,2.82
				c0.01-0.05,0.02-0.09,0.03-0.12c-0.17,0.39,0,0.05-0.51,0.82l0.08-0.04c-0.17,0.34-0.13,0.06-0.75,1.66
				c-0.1-0.2-0.25-0.5-0.34-0.54l0.03,0.14c0.05-0.02,0.16,0.01,0.14,0.27l-0.07-0.12c-0.08,0.19,0.24,0.51,0.34,0.78
				c-0.08,0-0.16,0.04,0.21,0.66c-0.04-0.02-0.05,0.06-0.09-0.05c0.12,0.34,0.34,0.47,0.34,0.63l-0.02-0.02
				c0.22,0.57-0.14-0.01,0.41,0.65c-0.23,0.05-0.13-0.01-0.57,0.09c0.18-0.39,0.32,0.34-0.48-0.38l0.22,0.14
				c-0.2-0.28-1.11-0.47-0.97-0.65c-0.12-0.01-0.18-0.28-1.26-0.7c0.3,0.03-0.46-0.29-0.33-0.37c-0.23,0-0.64-0.32-0.97-0.44
				c0.02,0,0.06,0,0.09,0.03c0.12-0.18-1.06-0.25-0.65-0.48c-0.03,0.07-0.4-0.13-0.36-0.04c-0.13-0.14-0.13-0.1-0.17-0.22
				c0.06,0.18-0.95-0.39-0.47-0.32c-0.7-0.08-0.01-0.08-1.93-0.76c-0.03,0.14,0,0.16-0.28,0.01c-0.06-0.45,0.21-0.13-2.28-0.98
				l0.13-0.06c-1.07,0.04-1.17-0.96-6.22-0.77l0.08-0.03c-0.34,0.01-0.36,0.18-0.68,0.17l0.06-0.02c-0.29-0.05,0.05,0.13-3.94,1.48
				c0.03,0.03-0.15,0.15-0.47,0.4c0.34,0.07-0.36-0.14-0.71,1.13c-0.02-0.01-0.01-0.07,0-0.13c-0.15,0.55-0.1,0.09-0.07,1.27
				l-0.06-0.05c1.03,1.5,0.02,1.65,4.1,2.44c-1.44,1.43-0.12,0.07-2.71,3.53l0.05-0.02c-0.15,1.17-0.23,0.04,3.22,2.38l-0.15,0.1
				c0.88-0.2,1.79-0.38,2.56-0.78c-0.04,0.04-0.03,0.06-0.11,0.09c0.19-0.01,0.62-0.25,0.6-0.3c0.04,0.03,0.15-0.05,0.72-0.31
				c0,0.01-0.02,0.04-0.05,0.08c0.12-0.06-0.12,0.15,0.03,0.1c-0.12,0.07-0.17,0.09-0.28,0.44c0-0.08-0.11,0.14-0.12,0.05
				c-0.1,0.2-0.18,0.4-0.11,0.39c-0.64,0.96-0.46,0.7-1.29,2.73c0-0.06,0-0.1,0.01-0.15c-0.27,0.89-0.43,0.65-0.43,2.54
				c0,0-0.01-0.01-0.02-0.03c0.04,0.14-0.06-0.22,0.31,0.44c-0.14-0.1,0.1,0.26-0.11,0.32c0.04,0.06,0.11,0.14,0.13,0.1
				c0.04,0.1,0.05,0.14-0.04,0.06c0.26,0.32,0.21,0.18,0.46,0.44c-0.02,0.02-0.08-0.03-0.12-0.03c0.21,0.12,0.36,0.21,0.59,0.34
				c-0.02,0,0,0.04-0.08-0.01c0.49,0.24,0.88,0.17,1.42,0.16c-0.15,0.07,0.11,0.16,0.53-0.01l-0.13-0.01
				c0.25-0.07,0.44-0.21,0.65-0.29l-0.16,0.12c0.15-0.07,0.29-0.15,0.42-0.24c-0.09,0.03-0.17,0.07-0.26,0.1
				c0.2-0.16-0.08-0.09,0.22-0.23c-0.05,0.04,0.21-0.13,0.34-0.18l0,0c0.33-0.43,0.28,0.05,0.78-0.88c0.03,0.18,0.3-0.25,0.28-0.4
				c-0.01,0.03,0,0.02-0.02,0.09c0.2-0.41,0.31-0.56,0.41-0.68l-0.05,0.12c0.28-0.49,0,0.04,0.78-1.81l-0.05,0.08
				c0.16-0.57,0.33-0.27,0.85-1.65l-0.01,0.07c0.51-0.86,0.42-0.65,1.66-2.32c-0.08,0.15-0.13,0.22,0.26-0.31
				c-0.07-0.01-0.07,0.03-0.18,0.09c0.02-0.07,0.09-0.21,0.18-0.31c-0.13,0.2,0.07,0.06,0.16,0.01c-0.02,0-0.02-0.04-0.03-0.04
				c0.26-0.13-0.05-0.11,0.25-0.34c0.02,0.06,0,0.13,0.61-0.61l-0.2,0.25c0.21-0.1,0.07-0.05,0.61-0.44l-0.08,0.05
				c0.08-0.3,0.38-0.39,0.59-0.71c-0.2,0.3,0,0.08,0.02,0.16c0.09-0.04,0.21-0.11,0.19-0.07c0.08-0.08,0.04-0.18,0.53-0.4
				c-0.74,0.43,1.25-1.1,2.44-1.74c-0.74,0.46,1.36-0.82,0.37-0.2l0.12-0.07c-0.2-0.07-0.5,0.27-0.75,0.4
				c0.01-0.11-0.01-0.17-0.66,0.23c0.02-0.04-0.07-0.05,0.05-0.1c-0.34,0.13-0.46,0.36-0.61,0.38l0.02-0.02
				c-0.92,0.52-0.74,0.63-2.32,1.78c-0.06-0.1,0.11-0.03-0.44,0.45c0-0.03-0.47,0.38-0.69,0.59l-0.01-0.02
				c0.03,0.11-0.2,0.32-0.33,0.5c-0.04-0.04-0.03-0.09,0.04-0.19c-0.01,0.03-0.05-0.02-0.26,0.39c-0.03-0.34-0.07,0.15-0.6,0.9
				l0.05-0.16c-0.12,0.12-0.01,0.1-0.1,0.2c-0.05-0.04-0.19,0.1-0.01-0.11c-0.45,0.53-0.2,0.22-0.9,1.37l0,0
				c-0.07,0.3-0.76,1.03-1.4,2.65c0.05-0.21-0.06,0.05,0.06-0.21c-0.13,0.25-0.19,0.29-0.25,0.59c-0.02-0.12-0.16,0.16-0.2,0.28
				l0.12-0.16c-0.05,0.14-0.11,0.25-0.18,0.37l-0.04-0.1c-0.36,0.44-0.46,1.14-1.47,2c0.31,0.07-0.64,0.12-0.46,0.22
				c-0.3,0.08-0.46,0.18-0.78,0.2c0.05,0.08-0.9,0.22-0.66-0.06c-0.29,0.01-0.46-0.19-0.58-0.27l0.13,0.04
				c-0.08-0.03-0.13-0.06-0.19-0.1l0.03,0.1c-0.06-0.06-0.11-0.1-0.1-0.12c-0.2-0.03-0.15,0.16-0.4-0.28c0.18,0.27,0.06,0,0.19,0.1
				c-0.17-0.37-0.02-0.11-0.36-0.49c0.06,0.03,0.05-0.1,0.02-0.15c0.05,0.09-0.15,0.01-0.2-0.41l0,0.15
				c-0.13-0.24-0.03-0.67,0.34-1.65c-0.08,0.07-0.08,0.09-0.06-0.14c-0.04,0.17-0.08,0.34-0.1,0.52c-0.01-0.12,0-0.23,0-0.35
				c-0.05,0.15-0.05,0.33-0.08,0.42c-0.01-0.17,0-0.15-0.07-0.09c0.25-0.72,0.33-0.33,0.77-1.69l-0.09,0.13
				c0.12-0.47,0.3-0.31,0.91-1.93c-0.01,0.13,0.11-0.01,0.02,0.24c0.06-0.12,0.12-0.22,0.1-0.23c-0.13,0.29,0.14-0.69,1.1-2.25
				c-0.66,0.22-0.07,0.01-1.36,0.64c0.01-0.01,0.01-0.01,0.01-0.02c-0.72,0.44-0.04-0.02-1.22,0.45l0.06-0.03
				c-0.32,0.05-0.05,0.09-1.71,0.68c0-0.03,0.01-0.04,0.01-0.06c-0.04,0.06-0.16,0.15-0.51,0.03l0.27-0.05
				c-0.15-0.07-0.35,0.06-0.49,0.05l0.01-0.04c-0.41,0.05-0.41,0.15-0.86,0.13l0.05,0.03c-0.52,0.04-0.34-0.09-0.91-0.2
				c0.09,0.01,0.19,0.02,0.26,0.02c-0.16-0.02-0.48-0.12-0.57-0.12c0.06,0.02,0.13,0.03,0.18,0.06c-0.88-0.89-0.7-0.23,0.1-2.89
				c-0.1-0.14-0.26-0.37,0.7-0.77c-0.07,0,0.01-0.14,0.16-0.26c-0.08,0.05-0.16,0.09-0.23,0.14c0.26-0.39,0.36-0.2,0.64-0.56
				l-0.08,0.13c-0.24-0.25,2.14-0.87,2.2-1.71c0,0.01,0,0.01,0,0.02c0-0.02-0.01-0.04-0.08-0.11c0.09-0.02,0.09-0.01,0-0.09
				c-0.18,0.06-0.08,0-0.76,0.16l0.12-0.06c-0.57,0.03-0.85,0.22-1.33,0.25c0.33-0.27-6.07-1.76-1.83-4.51l0.03,0.06
				c0.34-0.19,0.52-0.28,0.95-0.5l-0.07,0.09c0.78-0.49,0.32-0.3,1.99-0.73c-0.01,0.02-0.01,0.05-0.09,0.07
				c0.22-0.05,0.88-0.06,0.78-0.19c0.02,0.09,0.5-0.05,0.76-0.09l-0.04,0.07c0.02-0.03,0.33-0.09,1-0.14l-0.11,0.04
				c0.4,0.02,0.24-0.17,0.89,0.07c-0.12-0.01,0.36-0.07,0.26,0c0.42,0.13-0.01-0.08,0.96,0.17c-0.33-0.06-0.6-0.1-0.58-0.1
				c-0.01,0.06,0.26,0.12,0.03,0.17c0.22,0.02,0-0.05,0.89,0.05l-0.03,0c0.36,0.06,0.2,0.07,0.81,0.22
				c0.01-0.07,0.18-0.02,0.03-0.11c0.04,0.01-0.06-0.01-0.17-0.02l0.01,0c-0.09-0.02-0.2-0.04-0.33-0.07
				c0.93,0.07,0.65,0.23,2.48,0.72c-0.07-0.01-0.15-0.04-0.19-0.04c0.01,0.03,0.64,0.06,0.95,0.33l-0.06,0.01
				c0.69,0.44,1.31,0.3,2.04,0.87c-0.05-0.04-0.08-0.06-0.08-0.08c0.3,0.33,2.26,1.04,4.57,2.64l-0.03-0.08
				c0.16,0.11,0.54,0.31,0.48,0.34c0.1-0.02,0.45,0.29,0.75,0.53c-0.07,0.02-0.13,0.04-0.15,0.07c0.28-0.03,0.03,0.01,0.81-0.03
				c0.98,0.32,0.41-0.57,2.14,2.17c-0.04,0,0.33,0.5,0.49,0.75l-0.02,0c0.11-0.01,0.28,0.24,0.44,0.4
				c-0.05,0.03-0.09,0.01-0.18-0.07c0.03,0.01-0.02,0.04,0.33,0.33c-0.35-0.01,0.14,0.09,0.78,0.74l-0.15-0.07
				c0.09,0.14,0.09,0.03,0.18,0.13c-0.05,0.04,0.06,0.2-0.11-0.01c0.43,0.54,0.18,0.23,1.13,1.17l0,0c0.82,0.62,0.06,0.23,2.15,2.07
				c-0.19-0.11,0.03,0.08-0.19-0.12c0.19,0.2,0.22,0.27,0.49,0.42c-0.12-0.02,0.11,0.2,0.22,0.27l-0.12-0.16
				c0.12,0.09,0.21,0.18,0.31,0.27l-0.11,0.02c0.41,0.42,0.97,0.82,2.06,1.61c-0.07-0.32,0.4,0.58,0.4,0.36
				c0.24,0.24,0.42,0.32,0.67,0.55c0.02-0.05,0.91,0.41,0.57,0.5c0.28,0.15,0.45,0.39,0.58,0.5l-0.15-0.08
				c0.09,0.05,0.15,0.1,0.22,0.15l-0.04-0.1c0.07,0.07,0.14,0.12,0.12,0.13c0.24,0.02,0.12-0.15,0.52,0.25
				c-0.3-0.26-0.03,0.07-0.28-0.09c0.29,0.27,0.12,0.2,0.65,0.41c-0.07,0.01,0.03,0.12,0.08,0.16c-0.1-0.08,0.11-0.07,0.47,0.19
				l-0.12-0.11c0.29,0.05,0.52,0.47,1.37,1.16c-0.04-0.1-0.06-0.1,0.16-0.01l-0.46-0.29l0.33,0.13c-0.13-0.1-0.3-0.17-0.37-0.24
				c0.16,0.06,0.14,0.06,0.11-0.03c0.61,0.48,0.18,0.48,1.46,1.21l-0.12-0.12c0.43,0.19,0.26,0.35,1.77,1.19
				c-0.13-0.03,0,0.11-0.24-0.02c0.58,0.29-0.13-0.12,2.78,1.99c-0.02,0-0.02,0.02-0.05-0.02c0.72,0.49,0,0.03,0.99,0.84l-0.06-0.03
				c0.23,0.22,0.06,0.07,1.53,1.05c-0.02,0.01-0.03,0.02-0.05,0.03c0.07,0,0.22,0.04,0.36,0.38l-0.22-0.17
				c0.05,0.16,0.28,0.23,0.38,0.33l-0.04,0.02c0.32,0.27,0.39,0.2,0.73,0.51l-0.02-0.05c0.55,0.35,0.03,0.33,1.07,0.91
				c-0.17-0.06-0.46-0.26-0.65-0.37c0.12,0.11,0.42,0.36,0.51,0.38c-0.06-0.04-0.13-0.07-0.17-0.13c1.07,0.84,0.09-0.01,2.51,2.68
				c-0.05-0.28,0.13,0.14,0.22,0.01c0.26,0.23,0.29,0.45,0.42,0.87c0.01-0.07,0.14,0.04,0.25,0.19l-0.13-0.25
				c0.38,0.3,0.18,0.39,0.54,0.71l-0.13-0.09c0.24,0.37,0.35,0.32,1.87,2.23l-0.06-0.01c0.13,0.13,0.03,0.07,0.48,0.61l-0.11-0.08
				c0.29,0.5,0.61,0.65,0.89,1.04c-0.09,0.06,0.12,0.46,0.23,0.66l-0.02,0c0.44,1.25,0.29-0.23,3.5,6.28l-0.07,0.02
				c0.14,0.38,0.22,0.57,0.43,1.02l-0.09-0.07c0.52,0.8,0.27,0.35,0.88,1.99c-0.02,0-0.05-0.01-0.08-0.08
				c0.08,0.22,0.24,0.87,0.35,0.74c-0.08,0.03,0.15,0.49,0.27,0.73l-0.08-0.02c0.03,0.01,0.16,0.3,0.42,0.93l-0.08-0.09
				c0.12,0.39,0.24,0.18,0.26,0.86c-0.03-0.11,0.19,0.31,0.09,0.25c0.02,0.45,0.07-0.04,0.21,0.96c-0.08-0.33-0.14-0.6-0.13-0.57
				c-0.06,0.02-0.01,0.29-0.15,0.1c0.08,0.21,0.05-0.02,0.32,0.85l-0.01-0.03c0.1,0.36,0.01,0.21,0.17,0.83
				c0.07-0.03,0.1,0.15,0.11-0.03c0,0.04-0.02-0.06-0.06-0.17l0.01,0.01c-0.02-0.09-0.05-0.2-0.09-0.33
				c0.39,0.86,0.04,0.68,0.55,2.53c-0.03-0.07-0.04-0.15-0.07-0.18c-0.03,0.02,0.29,0.58,0.21,0.98l-0.04-0.05
				c-0.04,0.82,0.42,1.27,0.34,2.19c0.01-0.06,0.01-0.09,0.02-0.11c-0.16,0.43,0.36,2.44,0.23,5.25l0.05-0.07
				c-0.02,0.19,0.01,0.61-0.05,0.58c0.13,0.16-0.32,1.73,0.05,1.39c-0.05,0.25,0.31,0.33,0.34-0.49l0,0.27
				c0.13-0.32-0.17-1.19,0.05-1.17c-0.07-0.1,0.17-0.3-0.06-1.44c0.14,0.26-0.01-0.54,0.14-0.48c-0.12-0.19-0.07-0.71-0.15-1.05
				c0.01,0.02,0.03,0.06,0.02,0.09c0.23,0-0.36-1.02,0.05-0.81c-0.08,0.02-0.11-0.41-0.17-0.32c0.04-0.19,0.02-0.16,0.09-0.26
				c-0.12,0.15-0.19-1,0.02-0.57c-0.32-0.63,0.08-0.06-0.39-2.02c-0.13,0.05-0.13,0.08-0.16-0.23c0.37-0.29,0.21,0.1-0.31-2.44
				l0.11,0.09c-0.43-1.17-0.34-2.32-1.62-5.99l0.05,0.07c-0.08-0.32-0.27-0.3-0.31-0.61l0.03,0.05c-0.09-0.12,0.04-0.59-2.26-5.46
				c0.03,0,0.09,0.09,0.15,0.16c-0.33-0.54-0.06-0.09-0.57-1.08l0.07,0.02c-1.63-2.87,0.14-0.09-5.04-7.48l0.02,0.02
				c-1.04-1.16,0.03-0.02-2.57-2.99l-0.01,0.05c-0.74-0.49,0.09,0.13-3.32-3.03l0.17,0.03c-0.73-0.47-1.35-1.13-2.06-1.59
				c0.47,0.29-0.17-0.26-2.39-1.77c0.09-0.08-0.39-0.4-0.58-0.48c0.07,0.01-0.12-0.13-0.03-0.12c-0.18-0.13-0.36-0.24-0.37-0.17
				c-0.54-0.54-0.41-0.39-2.45-1.71c0.1,0.03,0.18,0.05,0.24,0.07c-0.13-0.07-0.31-0.25-1.43-1.02c0.08-0.06,0.15-0.24,0.11-0.6
				l0.03,0.23c0.03-0.25-0.02-0.77-0.21-2.28c0.13,0.23,0.01-0.48,0.16-0.41c-0.11-0.17-0.03-0.62-0.08-0.93
				c0.01,0.02,0.03,0.05,0.02,0.08c0.02-0.17,0.05-0.22,0.09-0.33c-0.02,0.21-0.27-0.82,0.07-0.37c-0.08,0-0.04-0.37-0.11-0.31
				c0.08-0.15,0.04-0.14,0.13-0.21c-0.15,0.1,0.01-0.89,0.12-0.48c-0.13-0.68,0,0.01,0.18-1.8c-0.13,0-0.16,0.03-0.07-0.25
				c0.51-0.13,0.11,0.11,0.7-2.07l0.06,0.12c0.26-1.1,0.67-1.92,2.23-5l-0.02,0.08c0.16-0.24,0.03-0.36,0.23-0.56l-0.02,0.05
				c0.12-0.22,1.19-1.71,2.04-3.03l0.06,0.04c0.2-0.41,0.13-0.22,0.94-1.27c0.02,0.03-0.04,0.11-0.07,0.19
				c0.3-0.47,0.04-0.08,0.67-0.85l0.01,0.07c1.91-2.12-0.08,0.03,5.58-5.72l-0.02,0.02c1.01-0.94-0.05-0.05,2.69-2.24l-0.05-0.02
				c0.63-0.79-0.1,0.03,3.05-2.6l-0.05,0.15c0.53-0.56,1.21-1,1.74-1.54c-0.09,0.13-0.29,0.38,2.01-1.75
				c0.06,0.1,0.44-0.25,0.54-0.41c-0.02,0.07,0.14-0.08,0.12,0.01c0.15-0.14,0.28-0.27,0.22-0.29c0.59-0.36,0.44-0.26,1.96-1.83
				c-0.04,0.09-0.07,0.15-0.1,0.2c0.26-0.27,0.36-0.28,1.64-1.62c-0.02,0-0.06,0.01,0.15-0.41c0,0.21,0.35-0.3,0.3,0.01
				c0.04-0.05,0.07-0.12,0.04-0.13c0.07-0.06,0.1-0.06,0.07,0.03c0.21-0.25,0.09-0.21,0.3-0.42c0.02,0.02-0.02,0.07-0.02,0.11
				c0.07-0.14,0.14-0.28,0.33-0.44c0,0.02,0.03,0.01-0.02,0.07c0.29-0.31,0.41-0.62,0.76-0.93c-0.01,0.09,0.04,0.16,0.37-0.25
				l-0.1,0.05c0.15-0.16,0.23-0.36,0.38-0.48l-0.08,0.15l0.26-0.32l-0.18,0.15c0.11-0.2-0.1-0.06,0.11-0.26
				c-0.03,0.05,0.15-0.16,0.26-0.21l0,0c0.25-0.42,0.21,0.02,0.65-0.8c0.02,0.17,0.29-0.18,0.28-0.32c-0.01,0.03,0,0.02-0.03,0.07
				c0.23-0.31,0.35-0.42,0.45-0.51l-0.05,0.08c0.33-0.37-0.01,0.04,1.03-1.43l-0.06,0.07c0.22-0.5,0.37-0.16,0.94-1.38l0,0.07
				c0.44-0.78,0.45-0.55,1.35-2.2c-0.04,0.15-0.07,0.21,0.18-0.33c-0.07,0.01-0.06,0.04-0.15,0.11c0-0.07,0.04-0.2,0.1-0.3
				c-0.07,0.21,0.08,0.04,0.16-0.02c-0.02,0.01-0.03-0.03-0.04-0.03c0.21-0.17-0.08-0.09,0.14-0.36c0.04,0.05,0.07,0.11,0.34-0.69
				c-0.03,0.09-0.06,0.18-0.09,0.27c0.15-0.14,0.14-0.17,0.22-0.29l-0.01,0.01l0.17-0.28l-0.06,0.07c-0.05-0.29,0.18-0.46,0.2-0.81
				c-0.02,0.33,0.04,0.07,0.1,0.12c0.05-0.07,0.12-0.18,0.13-0.14c0.03-0.1-0.04-0.17,0.25-0.55c-0.07,0.09-0.04-0.07,0.03-0.34
				c0.03,0.05,0.08,0.06,0.14-0.04c0.03,0.28,0.4,0.09,0.16-0.54l0.09,0.23c-0.23-0.37-0.04-0.93,0.28-1.95
				c0.04,0.13,0.15,0.23,0.18-0.76l-0.05,0.12c0.05,0,0.13,0.09-0.04,0.27l0.01-0.13c-0.16,0.09-0.11,0.5-0.19,0.74
				c-0.07-0.07-0.11-0.1-0.29,0.55c-0.01-0.04-0.08,0.01-0.03-0.09c-0.14,0.29-0.08,0.51-0.2,0.6l0-0.03
				c-0.01,0.01-0.01,0.02-0.02,0.03c-0.13-0.17-0.23-0.31-0.14-0.35c-0.07-0.08-0.12-0.08-0.23-0.21c0.02-0.01,0.04-0.03,0.07-0.07
				c0.03,0.24,0.38,0.19,0.19-0.53l0.07,0.24c-0.04-0.07,0-0.73-0.1-0.4c-0.14-0.3-0.34-0.6-0.2-0.63c-0.1-0.06,0.04-0.32-0.57-1.19
				c0.23,0.16-0.21-0.45-0.06-0.45c-0.18-0.11-0.35-0.55-0.57-0.8c0.02,0.01,0.05,0.03,0.06,0.06c0.2-0.11-0.77-0.66-0.31-0.68
				c-0.06,0.05-0.28-0.27-0.29-0.17c-0.05-0.17-0.06-0.13-0.04-0.25c-0.02,0.17-0.64-0.68-0.26-0.45c-0.66-0.35,0.08-0.07-1.37-1.31
				c-0.07,0.11-0.06,0.15-0.25-0.07c0.12-0.44,0.22-0.06-1.68-1.49l0.14-0.02c-0.99-0.54-1.84-1.05-4.91-2.67l0.07,0.02
				c-0.22-0.18-0.36-0.06-0.54-0.28l0.05,0.02c-0.22-0.14-1.52-1.35-1.84-2.9l0.08-0.02c-0.03,0.01-0.08-0.17-0.05-0.48
				c-0.11,0.08,0.06-0.13,0.1-0.99c0.03,0.02,0.01,0.11,0.01,0.18c0.1-0.49-0.03-0.1,0.37-0.89l0.02,0.06
				c0.22-0.3,0.54-0.58,0.95-0.72c-0.29-0.1,2.33-0.01,4.04,1.33c-0.02-0.03-0.02-0.05-0.02-0.07c0.02,0.03,0.04,0.06,0.06,0.09
				l0.01-0.01c0.01,0.02,0.01,0.03,0.02,0.05c0.39-0.5,0.35-0.16-0.34-1.94c1.42,1.24-3.34-4.69,2.82-2.11l-0.13-0.02
				c0.18,0.1-0.03,0.07,1.48,0.74c-0.08-0.2,0,0.01-0.14-0.67c0.41,0.12-0.43,0.36-0.15-2.43c0.09,0.05,0.28-0.34,0.35-0.48
				c-0.01,0.06,0.12-0.07,0.11,0.01c0.11-0.12,0.23-0.21,0.17-0.25c0.83-0.03,0.63-0.5,2.03,0.54c-0.09-0.02-0.17-0.07-0.23-0.11
				c0.14,0.3,0.46,0.25,1.07,1.93c0.01-0.01,0.02-0.06,0.27,0.33c-0.18-0.12,0.06,0.45-0.17,0.26c0.02,0.06,0.06,0.12,0.09,0.1
				c0.01,0.09,0,0.12-0.06,0.04c0.1,0.31,0.13,0.19,0.19,0.47c-0.02,0-0.05-0.05-0.08-0.08c0.08,0.14,0.16,0.27,0.19,0.52
				c-0.02-0.01-0.03,0.02-0.04-0.05c0.09,0.42,0.27,0.69,0.3,1.16c-0.07-0.07-0.15-0.07-0.03,0.45l0.03-0.11
				c0.03,0.22,0.13,0.4,0.13,0.59l-0.06-0.16l0.08,0.41l0-0.24c0.08,0.21,0.12-0.04,0.13,0.26c-0.02-0.06,0.03,0.22-0.01,0.34l0,0
				c0.14,0.47-0.15,0.14,0.14,1.03c-0.14-0.1-0.08,0.33,0.02,0.43c-0.01-0.03-0.02-0.01-0.03-0.07c0.04,0.37,0.04,0.54,0.03,0.67
				l-0.01-0.08c0,0.49-0.01-0.04,0.14,1.76l0.01-0.09c0.12,0.53-0.17,0.34,0.04,1.68l-0.03-0.06c0.02,0.9-0.05,0.7-0.11,2.58
				c-0.03-0.15-0.03-0.22-0.02,0.37c0.06-0.03,0.04-0.06,0.09-0.16c0.03,0.06,0.05,0.2,0.04,0.32c-0.02-0.22-0.09-0.01-0.13,0.08
				c0.02-0.01,0.04,0.02,0.05,0.01c-0.13,0.24,0.11,0.05,0.01,0.39c-0.05-0.03-0.11-0.08-0.09,0.77c0-0.1-0.01-0.19-0.01-0.29
				c-0.1,0.18-0.08,0.21-0.11,0.35l0-0.01l-0.08,0.32l0.03-0.08c0.14,0.26-0.03,0.49,0.04,0.83c-0.07-0.33-0.06-0.06-0.13-0.1
				c-0.03,0.08-0.07,0.2-0.09,0.16c0.09,0.49-0.41,1.77-0.68,4.06l0.06-0.12c-0.05-0.01-0.12-0.09,0.04-0.27l-0.01,0.13
				c0.21-0.37,0.02-0.1,0.54-0.96l-0.13,0.33c0.29-0.36,0.34-1.51,0.55-1.45c-0.03-0.15,0.27-0.33,0.45-1.85
				c0.05,0.37,0.17-0.7,0.29-0.58c-0.06-0.28,0.14-0.93,0.14-1.4c0.01,0.03,0.02,0.08,0,0.12c0.23,0.05-0.12-1.34,0.24-1.04
				c-0.08,0.01-0.02-0.56-0.1-0.46c0.08-0.24,0.05-0.2,0.14-0.32c-0.13,0.17-0.02-1.34,0.11-0.74c-0.24-0.91,0.12-0.06-0.17-2.72
				c-0.16,0.05-0.13,0.08-0.15-0.33c0.35-0.2,0.25,0.14-0.32-3.23l0.12,0.12c-0.43-1.48,0.06-0.11-1.8-7.94l0.04,0.1
				c-0.06-0.43-0.25-0.45-0.26-0.86l0.03,0.07c-0.01-0.34-0.64-3.15-0.56-5.32c-0.03,0.01-0.05-0.26-0.02-0.75
				c-0.18,0.2,0.07-0.09,0.11-1.53c0.03,0.02,0.01,0.16,0.02,0.27c0.1-0.77-0.04-0.17,0.41-1.43l0.02,0.08
				c1.93-1.85,1.57-2.49,5.32,3.98c0.06-0.06,0.06,0.17,0.02,0.43l0.05-0.16c-0.01,0.22-0.01,0.06-0.07,0.99l-0.04-0.21
				c1.52,0.56-0.7,1.82,1.96-3.81l-0.06-0.01c0.45-0.23-0.47-0.71,3.86,1.13c-0.02-0.03-0.04-0.05-0.06-0.08
				c0.31-0.07-0.56,1.16,0.54,2.54c0.06-0.12-0.15-0.12,0.2-0.67c0.24,0.67-0.17-1.73,2.28-2.38c-0.02,0.1,0.66,0.08,0.9,0.11
				c-0.08,0.01,0.19,0.08,0.09,0.11c0.26,0.07,0.49,0.16,0.47,0.08c1.02,0.94,1.04,0.39,2.25,2.81c-0.54-0.34,0.25-0.45,0.32,2.96
				c-0.01,0-0.02-0.07-0.03-0.14c0.05,0.41,0-0.29,0.19,0.71c-0.13-0.23-0.09,0.65-0.25,0.28c0,0.09,0.02,0.2,0.05,0.18
				c-0.02,0.13-0.05,0.16-0.07,0.03c-0.04,0.46,0.04,0.31-0.03,0.73c-0.02-0.01-0.02-0.09-0.04-0.14c0.02,0.22,0.03,0.44-0.07,0.79
				c-0.01-0.02-0.04,0.01-0.01-0.09c-0.12,0.6-0.12,1.06-0.37,1.69c-0.02-0.11-0.08-0.18-0.31,0.56l0.09-0.12
				c-0.1,0.3-0.15,0.59-0.28,0.83l0.05-0.23l-0.21,0.56l0.16-0.3c-0.08,0.32,0.12,0.01-0.06,0.4c0.03-0.08-0.14,0.29-0.26,0.42l0,0
				c-0.21,0.63-0.22,0.13-0.65,1.36c-0.04-0.21-0.32,0.37-0.31,0.54c0.02-0.04,0-0.03,0.03-0.11c-0.28,0.5-0.41,0.69-0.52,0.85
				l0.06-0.13c-0.39,0.62,0.02-0.05-1.31,2.2l0.08-0.1c-0.33,0.71-0.48,0.35-1.35,2.04l0.02-0.09c-0.71,1.12-0.62,0.82-2.15,3.09
				c0.09-0.2,0.16-0.29-0.32,0.44c0.08-0.01,0.08-0.05,0.2-0.15c-0.02,0.09-0.12,0.27-0.22,0.41c0.15-0.28-0.08-0.05-0.18,0.04
				c0.03-0.01,0.02,0.04,0.04,0.04c-0.3,0.23,0.06,0.12-0.3,0.48c-0.02-0.07,0-0.17-0.69,0.9c0.07-0.11,0.15-0.24,0.22-0.35
				c-0.23,0.17-0.24,0.21-0.37,0.37l0.01-0.01l-0.32,0.35l0.09-0.08c-0.09,0.38-0.42,0.58-0.64,1.02c0.21-0.42,0-0.1-0.03-0.17
				c-0.09,0.08-0.23,0.2-0.21,0.15c-0.1,0.13-0.01,0.18-0.59,0.65c0.19-0.12,0.02,0.08-0.83,1.03c0.03-0.02,0.04-0.02,0.06-0.02
				c-0.54,0.26,0.01-0.01-4.2,3.52l0.09-0.01c-0.16,0.13-0.45,0.46-0.47,0.39c-0.03,0.2-1.5,1.09-0.98,1.08
				c-0.22,0.16,0,0.45,0.6-0.14l-0.19,0.19c0.32-0.14,0.73-1,0.88-0.82c0.05-0.11,0.3-0.12,1.04-1.06
				c-0.12,0.28,0.43-0.37,0.46-0.21c0.07-0.23,0.51-0.52,0.75-0.8c0.46,0.06,0.58-0.47,3.16-2.52c-0.11-0.1-0.14-0.08,0.14-0.26
				c0.51,0.2,0.02,0.17,2.15-1.38l-0.04,0.14c0.87-0.69,1-0.77,5.51-3.29l-0.07,0.06c0.32-0.14,0.28-0.31,0.59-0.39l-0.05,0.04
				c0.25-0.15,2.21-0.95,3.88-1.76l0.03,0.07c-0.02-0.02,0.17-0.12,0.55-0.22c-0.22-0.13,0.1,0.09,1.17-0.41
				c-0.01,0.03-0.11,0.07-0.19,0.12c0.6-0.22,0.1-0.06,1.19-0.31l-0.04,0.06c3.29-0.13,7.16,2.11,1.4,5.91l-0.03-0.05
				c1.12,0.05-0.05-0.12,3.71,1.87l-0.14-0.09c-0.04,0.3,0.79,0.52-0.15,2.1c0.02-0.04,0-0.06,0.05-0.12
				c-0.08,0.19-1.27,0.81-1.45,0.4c-0.04,0.09-0.01,0.29-0.29,0.28c0.11,0.4-0.08,0.35,0.59,0.7c-0.07-0.02,0.12,0.22,0.68,0.62
				c-0.07-0.02,0.1,0.15,0.01,0.12c0.16,0.15,0.3,0.29,0.32,0.23c0.36,0.98,0.74,0.61,0.57,2.65c-0.3-0.55,0.53,0.04-1.44,1.74
				c0.01,0.01,0.08-0.02-0.27,0.4c0.08-0.21-0.47,0.22-0.31-0.07c-0.05,0.04-0.12,0.09-0.09,0.12c-0.09,0.03-0.13,0.03-0.06-0.05
				c-0.31,0.18-0.18,0.17-0.48,0.29c-0.01-0.02,0.05-0.06,0.07-0.09c-0.13,0.11-0.28,0.2-0.56,0.25c0.01-0.02-0.02-0.03,0.06-0.05
				c-0.45,0.11-0.79,0.26-1.33,0.21c0.15-0.06-0.07-0.14-0.49-0.13l0.11,0.06c-0.25-0.02-0.48,0.02-0.69-0.04l0.19-0.01
				c-0.16-0.02-0.31-0.03-0.47-0.06c0.09,0.03,0.17,0.06,0.26,0.08c-0.26,0.01,0.01,0.13-0.32,0.04c0.07,0-0.25-0.04-0.37-0.13l0,0
				c-0.54-0.05-0.11-0.18-1.16-0.28c0.16-0.08-0.33-0.22-0.46-0.16c0.03,0.01,0.02-0.01,0.09,0c-0.43-0.13-0.6-0.21-0.73-0.28
				l0.11,0.03c-0.53-0.21,0.05,0-1.95-0.6l0.1,0.04c-0.63-0.06-0.34-0.34-1.89-0.49l0.08-0.02c-1.04-0.17-0.78-0.26-2.95-0.55
				c0.17-0.01,0.26,0-0.43-0.06c0.04,0.06,0.07,0.04,0.18,0.11c-0.07,0.02-0.23,0.03-0.37,0.01c0.25-0.01,0.01-0.09-0.09-0.14
				c0.02,0.02-0.02,0.03-0.02,0.05c-0.28-0.14-0.06,0.11-0.45,0c0.04-0.05,0.1-0.11-0.9-0.1l0.33-0.01c-0.21-0.1-0.24-0.08-0.4-0.11
				l0.02,0l-0.38-0.07l0.09,0.03c-0.29,0.15-0.57-0.02-0.96,0.08c0.37-0.09,0.06-0.06,0.1-0.13c-0.1-0.03-0.24-0.06-0.2-0.08
				c-0.13,0.01-0.15,0.09-0.7-0.06c0.86,0.14-1.73,0.05-3.16,0.05c0.14-0.09,0.22-0.17-0.91-0.01l0.15,0.03
				c0-0.05,0.07-0.14,0.32-0.02l-0.15,0.01c0.15,0.14,0.59-0.01,0.89,0c-0.07,0.1-0.08,0.13,0.71,0.14
				c-0.04,0.02,0.03,0.07-0.09,0.06c0.37,0.07,0.6-0.06,0.74,0.02l-0.03,0.01c1.11,0.16,0.98-0.16,3.01,0.16
				c-0.03,0.1-0.09-0.06,0.65,0.02c-0.02,0.03,0.62,0.1,0.92,0.17l-0.01,0.01c0.07-0.08,0.38-0.03,0.62-0.02
				c-0.02,0.05-0.06,0.07-0.19,0.08c0.03-0.01,0.01,0.04,0.48,0c-0.28,0.21,0.17-0.03,1.12,0.07l-0.16,0.03
				c0.16,0.05,0.1-0.03,0.23-0.01c-0.01,0.06,0.18,0.12-0.1,0.06c0.7,0.17,0.3,0.07,1.66,0.29l0,0c1.05,0.16,0.2,0.06,2.97,0.86
				c-0.23-0.03,0.06,0.06-0.23-0.04c0.27,0.11,0.32,0.17,0.63,0.21c-0.12,0.03,0.18,0.15,0.32,0.17l-0.18-0.11
				c0.15,0.04,0.28,0.09,0.41,0.14l-0.1,0.06c0.57,0.21,1.27,0.47,2.66,0.68c-0.23-0.24,0.68,0.32,0.56,0.13
				c0.35,0.09,0.56,0.07,0.93,0.11c0-0.03,1.03-0.26,0.82,0.03c0.33-0.09,0.65-0.05,0.85-0.1l-0.17,0.07
				c0.1-0.04,0.19-0.07,0.28-0.09l-0.12-0.03c0.11-0.02,0.2-0.04,0.2-0.02c0.18-0.2-0.04-0.17,0.54-0.32
				c-0.37,0.13-0.03,0.08-0.18,0.17c0.14-0.04,0.13,0.01,0.36-0.12c-0.01,0.03,0,0.07,0.09,0.15l-0.11,0.01l0.1,0.04
				c-0.02,0.01-0.22,0.04-0.07,0.14c-0.02,0-0.07,0.03-0.06,0c0,0.11,0,0.21,0.22,0.18l-0.17,0.1l0.08-0.01c0,0.01,0,0.03-0.03,0.04
				c0.06,0.02,0.03-0.02,0.08-0.01c-0.05,0.07,0.07,0.13-0.04,0.18c0.06,0,0.03-0.06,0.08-0.02c-0.16,0.02,0.11,0.12-0.1,0.13
				c0.16,0.03-0.11,0.13,0.14,0.26c-0.04,0,0.03,0.01-0.05,0.21c0.09-0.01,0.09,0.06,0.12,0.1c-0.05,0.01-0.08,0.01-0.11-0.01
				c0.01,0.01-0.01,0.04,0.08,0.08l-0.07,0.01c0.02,0.06,0.11,0.1,0.08,0.18c0.06-0.01,0.16-0.1,0.22-0.03
				c-0.03,0.01-0.09,0.03-0.11,0.01c0,0-0.02,0.13-0.06,0.05c0,0.03-0.02,0.08-0.06,0.1c0.03,0,0.15,0.01,0.01,0.08
				c0.04,0.01,0.01,0.05,0.08,0.04c0,0.02-0.03,0.03-0.05,0.04c0.05,0.02,0,0,0.12,0.08l0,0c0.11,0.05-0.04,0.28,0.11,0.63
				c-0.05-0.04-0.03,0.02-0.04-0.04c-0.01,0.06-0.05,0.08,0.03,0.13c-0.07-0.01-0.07,0.06-0.05,0.08l0.04-0.05
				c-0.1,0.18,0.25,0.5,0.06,0.66l0.03,0c-0.02,0.03-0.01,0.11-0.06,0.1c0.04,0.04,0.05,0.01,0.06-0.02c-0.03,0.08,0.02,0.11,0,0.19
				c0.04-0.04,0.25,0.21-0.03,0.17c0.07,0.06-0.02,0.13-0.02,0.17l-0.03-0.03c0.02,0.02,0.02,0.04,0.02,0.05l0.06-0.03
				c-0.01,0.02,0,0.04-0.03,0.04c0.04,0.01,0.09-0.03,0.15,0c-0.03,0.03,0.05,0.08-0.03,0.1c0.01-0.12-0.3,0.03-0.01,0.11
				c-0.04,0-0.06,0.02-0.06,0.03c0.02-0.01,0.09-0.01,0.14,0.1c0.02-0.03,0.02-0.06,0.08,0.03c-0.03,0.01-0.03-0.03-0.04-0.02
				c0.18,0.04-0.31,0.29,0.01,0.35l-0.06-0.11l0.1,0.06c0-0.03-0.05-0.07-0.04-0.09c0.05,0.03,0.04,0.03,0.08,0
				c0.08,0.24-0.26,0-0.06,0.42c0.04-0.03,0.05-0.07,0.07,0.06c-0.05,0-0.06,0.03-0.09,0.05c0.04-0.01,0.01,0.08,0.11,0.08
				c-0.06,0.09-0.12,0.06,0.09,0.22c-0.06-0.02-0.09,0.02-0.13-0.03c0.05,0.08-0.02-0.02,0.2,0.2c-0.1,0.08-0.1,0.1-0.04,0.51
				c-0.02,0-0.02,0-0.02-0.01c0.15,0.15-0.19,0.08,0.03,0.45c0,0-0.01,0-0.02,0c0.09,0.04,0.12,0.14,0.15,0.2
				c-0.14-0.01-0.09-0.02-0.1,0.12c-0.31-0.21,0.32,0.33,0.02,0.34c0.03,0.03,0.1,0.12,0.13,0.18c-0.08-0.03-0.12-0.1-0.16-0.15
				c0,0.03,0.02,0.12,0.06,0.13c-0.01-0.05-0.01-0.06,0.1,0.12c-0.19,0-0.04,0.18-0.18,0.24c0.07,0.12-0.02,0.24-0.01,0.39
				c0.04-0.06,0.22,0.1,0.05,0.07c0.02,0.01,0.04,0.03,0.07,0.03c-0.03,0.05-0.1,0.1-0.22,0.14c0.05-0.01,0.08,0.02,0.06,0.07
				l0.07-0.06c0.08,0.1-0.13,0.09-0.04,0.19l-0.05-0.03c0.03,0.05,0.01,0.19,0.13,0.24c-0.02,0.01-0.06,0.02-0.07,0
				c-0.08,0.21,0.07,0.31,0.03,1.35c-0.02-0.06,0,0-0.11-0.21l0,0c-0.14-0.15-0.02-0.74-0.36-1.72c0.08,0.11,0.03-0.05,0.06,0.12
				c-0.02-0.16,0.01-0.21-0.11-0.36c0.08,0.04,0.04-0.14,0-0.21l-0.01,0.12c-0.04-0.08-0.05-0.16-0.07-0.23l0.09,0.01
				c-0.07-0.35-0.23-0.74-0.62-1.42c-0.11,0.23,0-0.44-0.11-0.3c-0.05-0.2-0.14-0.28-0.22-0.47c-0.01,0.05-0.51-0.4-0.18-0.42
				c-0.15-0.13-0.16-0.32-0.21-0.41l0.07,0.07c-0.04-0.04-0.07-0.08-0.1-0.13l-0.02,0.08c-0.02-0.05-0.05-0.1-0.03-0.11
				c-0.13-0.04-0.16,0.13-0.28-0.21c0.01,0.14,0.3-0.12-0.15-0.25c0.05,0,0.03-0.1,0-0.12c0.05,0.06-0.09,0.07-0.29-0.15l0.05,0.08
				c-0.21-0.04-0.2-0.36-0.62-0.89c0,0.08,0.02,0.08-0.13,0.02c0.09,0.06,0.17,0.13,0.26,0.2l-0.2-0.09
				c0.07,0.07,0.17,0.12,0.2,0.17c-0.1-0.04-0.09-0.04-0.09,0.03c-0.34-0.36,0.06-0.37-0.74-0.86l0.06,0.08
				c-0.07,0.02-0.15-0.11-0.24-0.16c0.04-0.03,0-0.09-0.01-0.14c-0.02,0.03-0.18-0.14-0.26-0.07c-0.17-0.23-0.04-0.17-0.57-0.31
				c0.09-0.01,0.02-0.09,0.17-0.04c-0.72-0.25-0.02,0.01-1.9-0.79c0.01-0.01,0.01-0.02,0.03-0.01c-0.52-0.08,0.01-0.06-0.75-0.25
				l0.04,0c-0.17-0.05-0.07-0.07-1.13,0.02c0-0.02,0.01-0.03,0.01-0.04c-0.04,0.04-0.13,0.08-0.33-0.04
				c0.06-0.01,0.12-0.02,0.18-0.03c-0.1-0.08-0.24,0.03-0.34,0.05l0-0.04c-0.29,0.12-0.25,0.22-0.47,0.43l0.04,0
				c-0.11,0.42-0.38-0.01-0.28,0.83c-0.07-0.11-0.07-0.34-0.06-0.49c-0.05,0.1-0.09,0.37-0.04,0.43c-0.01-0.04-0.02-0.1,0-0.14
				c0.05,0.15,0.08,0.31,0.11,0.51c-0.09-0.18-0.12,0.35,0,0.56c-0.02-0.01-0.04-0.03-0.05-0.04c0.36,0.38-0.02-0.01,0.28,0.33
				c-0.11-0.09-0.06-0.02-0.24-0.27c0.04,0.06,0.02,0.07,0.15,0.2c-0.12-0.1-0.28-0.28-0.41-0.53c0.05,0.08,0.04,0.11,0.02,0.07
				c0.05,0.11,0.1,0.14-0.09-0.02c0.07,0,0.01-0.42-1.81-0.06l0.02-0.05c-0.17,0.14-0.13-0.02-0.45,0.28l0.05-0.08
				c-0.36,0.19-0.4,0.48-0.52,0.78c-0.11-0.06-0.26,0.26-0.27,0.45l-0.02-0.01c0.75,0.45-0.77,0.59,4.08,2.91l-0.08,0.01
				c0.53,0.12,0.23,0.01,1.12,0.59c-0.01,0.01-0.04,0.03-0.08,0.01c0.11,0.08,0.37,0.37,0.42,0.22c-0.06,0.08,0.23,0.18,0.38,0.25
				l-0.06,0.04c0.02-0.02,0.19,0.07,0.53,0.28l-0.08-0.01c0.17,0.16,0.23-0.05,0.38,0.35c-0.06-0.05,0.23,0.07,0.13,0.09
				c0.12,0.24,0.05-0.06,0.39,0.42c-0.14-0.14-0.25-0.25-0.24-0.25c-0.04,0.05,0.06,0.16-0.09,0.14c0.11,0.07,0.03-0.04,0.44,0.3
				l-0.02-0.01c0.16,0.15,0.06,0.11,0.3,0.38c0.05-0.05,0.11,0.03,0.09-0.08c0.01,0.02-0.03-0.03-0.08-0.06l0.01,0
				c-0.04-0.04-0.08-0.09-0.14-0.14c0.48,0.27,0.17,0.34,1,1.13c-0.17-0.11-0.14-0.12,0.29,0.38l-0.04-0.01
				c0.15,0.46,0.63,0.5,0.75,1.05c-0.01-0.04-0.01-0.06,0-0.07c-0.06,0.17,0.55,0.8,1.27,2.78l0.03-0.05
				c0.17,0.8,0.04-0.18,0.68,0.86c0-0.11,0-0.08-0.2-0.43c0.22-0.04-0.22-0.2,0.02-0.24c-0.07-0.04,0.06-0.22-0.36-0.78
				c0.18,0.1-0.12-0.3,0.03-0.31c-0.16-0.06-0.21-0.37-0.35-0.53c0.01,0.01,0.04,0.02,0.04,0.04c-0.04-0.11-0.02-0.15-0.04-0.23
				c0.06,0.12-0.56-0.33-0.09-0.24c-0.07,0.04-0.18-0.19-0.22-0.11c0-0.12-0.02-0.1,0.03-0.18c-0.07,0.13-0.38-0.48-0.11-0.32
				c-0.21,0.03-0.31-0.56-0.96-1.01c0.38-0.63-0.02,0.01-0.79-1.22l0.12-0.01c-0.63-0.41-0.77-1.15-2.67-2.53l0.05,0.01
				c-0.14-0.14-0.27-0.01-0.37-0.18l0.04,0.01c-0.06,0.02-0.2-0.44-3.02-1.77c0.02-0.03,0.09-0.01,0.14-0.01
				c-0.23-0.01,0-0.09-0.21-0.1c0.02,0.03-0.03,0.04-0.04,0.05c-0.07-0.08-0.28-0.17-0.43-0.2l0.05-0.04
				c-0.25-0.11-0.55-0.19-0.79-0.34c-0.35-0.29,0.01,0-0.41-1.43c0.05,0.03,0.19-0.1,0.15,0c0.77-0.48,0.41-0.6,3.25,0.13
				c-0.05-0.05-0.16-0.09,0.06-0.1c-0.21-0.24-1.13-1.34,0-2.15c-0.02,0.03-0.04,0.06-0.05,0.09c0.19-0.06,0.22-0.08,1.36,0.03
				c-0.03,0.01-0.04,0.04-0.08,0.03c0.08,0.08,0.36,0.07,0.37,0.03c0.19,0.12-0.03-0.01,1.3,0.45c-0.06,0.1,0.24,0.21,0.36,0.23
				c-0.05,0.01,0.07,0.08,0,0.1c0.11,0.06,0.22,0.11,0.23,0.05c0.28,0.34,0.23,0.17,1.38,0.98c-0.46-0.03-0.47-0.45,0.88,0.99
				c-0.01,0.01-0.04-0.01-0.06-0.03c0.14,0.09-0.03-0.01,0.37,0.11c-0.17,0.01,0.14,0.26-0.1,0.24c0.03,0.02,0.08,0.05,0.1,0.02
				c0.03,0.05,0.02,0.08-0.05,0.05c0.14,0.15,0.15,0.06,0.26,0.21c-0.02,0.01-0.05-0.01-0.09-0.01c0.1,0.05,0.19,0.1,0.26,0.25
				c-0.02,0-0.02,0.03-0.05-0.01c0.16,0.22,0.36,0.32,0.47,0.61c-0.14-0.02,0.02,0.11-0.05,0.16l0.1,0.13l0.01-0.08
				c0.06,0.13,0.18,0.21,0.21,0.33l-0.08-0.08l0.13,0.23l-0.04-0.15c0.33,0.3,0.08-0.05,0.47,0.95c-0.12-0.07-0.09,0.21,0.08,0.26
				c-0.01-0.02-0.02,0-0.04-0.04c0.09,0.25,0.11,0.35,0.11,0.43l-0.03-0.06c0.07,0.3-0.01-0.03,0.35,1.08l0-0.06
				c0.14,0.01-0.01,0.24,0.08,0.37c-0.12,0.08-0.05-0.03,0.16,0.69l-0.04-0.03c0.14,0.55,0.03,0.46,0.23,1.65
				c-0.04-0.09-0.06-0.14,0.03,0.24c0.04-0.02,0.04-0.04,0.05-0.08c0.01,0.02,0.06,0.05-0.02,0.24c0.05,0.02,0.03-0.01,0.01,0.14
				c-0.02,0-0.05-0.01-0.05-0.03c-0.01,0.05-0.09,0.19,0.06,0.18c-0.09-0.01-0.02,0.11,0.02,0.17c-0.13-0.06-0.02,0,0.01,0.34
				l0-0.01c-0.07,0.12-0.03,0.05-0.07,0.31c0.01-0.07,0.02-0.13,0.03-0.12c-0.09-0.08-0.22,0.24-0.04,0.38
				c-0.01,0.01,0-0.01-0.01-0.04l0.01,0c0-0.02,0-0.04,0-0.07c0.03,0.03,0.06,0.05,0.02,0.08c0.04,0.02,0.09,0.04,0.11,0.05
				c0,0.08-0.19,0.32-0.24,1.05c0.01-0.01,0.02-0.02,0.04-0.02c-0.04,0.15-0.16,0.11-0.11,0.77l-0.08,0
				c0.21,0.13-0.23,0.33-0.02,0.79c0,0,0,0-0.01,0c0.09,0.07,0-0.01,0.36-0.08l-0.01,0.06c0.06-0.02,0.02-0.05-0.01-0.08
				c0.03,0,0.05,0,0.07-0.02l-0.1-0.09c0.03,0,0.21,0.01-0.01-0.04c0.06-0.02,0.1,0,0.06-0.05c0.03,0,0.05,0.01,0.06,0.03
				c-0.03-0.04,0-0.09-0.06-0.12c0.04-0.01,0.04-0.05,0.05-0.07c0,0.02,0,0.04,0.03,0.02c-0.02-0.02-0.05-0.04,0.03-0.04
				c-0.02-0.02-0.07-0.07-0.07-0.11c0.1,0.06,0.06-0.04,0.11-0.07c0.02,0.15-0.01,0.09,0.01,0.37c-0.08-0.05-0.18-0.13,0.01,0.51
				l0.02-0.08c-0.04,0.01-0.16-0.04-0.02-0.18l0.02,0.08c0.14-0.09-0.02-0.33-0.02-0.5c0.07,0.01,0.15,0.07,0.12-0.4
				c0.02,0.02,0.07-0.02,0.06,0.05c0.09-0.18-0.06-0.06,0.05-0.49c0.01,0.02,0.07,0.03,0.04,0.04c0.08-0.06,0.14-0.12-0.03-0.26
				l0.19,0.06l-0.06-0.05c0.01-0.01,0.02-0.02,0.05,0c-0.03-0.06-0.03-0.01-0.06-0.05c0.09,0,0.04-0.13,0.15-0.07
				c-0.04-0.04-0.06,0.02-0.07-0.05c0.12,0.1-0.01-0.15,0.15-0.01c-0.05-0.15,0.16-0.05,0.07-0.26c0.03,0.03-0.01-0.03,0.17-0.1
				c-0.08-0.05-0.03-0.1-0.03-0.14c0.05,0.03,0.06,0.04,0.07,0.08c0.01-0.03,0.02-0.03-0.01-0.1l0.06,0.04
				c0.02-0.05-0.02-0.13,0.05-0.17c-0.06-0.03-0.18-0.03-0.19-0.12c0.03,0.01,0.09,0.04,0.09,0.06c-0.01,0,0.08-0.07,0.08,0.01
				c0.02-0.02,0.06-0.04,0.1-0.03c-0.08-0.04,0.12-0.03,0.01-0.26l0,0c-0.1-0.1,0.09-0.06,0.27-0.49c0.02,0.05,0.04,0,0.01,0.05
				c0.04-0.04,0.08-0.04,0.04-0.11c0.05,0.04,0.09,0,0.08-0.04l-0.06,0.02c0.12-0.1,0.04-0.14,0.38-0.73c-0.06,0-0.12-0.28,0.1-0.12
				c-0.03-0.08,0.07-0.09,0.09-0.12l0.01,0.04c-0.01-0.02,0-0.04,0-0.05l-0.06,0c0.02-0.01,0.02-0.03,0.04-0.02
				c-0.03-0.03-0.1-0.02-0.13-0.07c0.04-0.01-0.01-0.08,0.07-0.06c-0.06,0.09,0.27,0.12,0.06-0.08c0.04,0.02,0.09,0.01,0.08,0
				c0.01,0.02-0.09,0.01-0.09-0.13c-0.04,0.01-0.04,0.04-0.05-0.06c0.03,0.01,0.01,0.04,0.03,0.03c-0.06-0.06,0.11-0.05,0.07-0.12
				c0.02,0.01,0.25-0.02,0.06-0.16l0.01,0.11l-0.06-0.09c-0.01,0.03,0.02,0.07,0,0.09c-0.04-0.05-0.02-0.04-0.08-0.04
				c0.02-0.24,0.23,0.12,0.22-0.3c-0.04,0.01-0.07,0.04-0.04-0.07c0.05,0.02,0.07,0,0.1,0c-0.04-0.01,0.02-0.07-0.07-0.1
				c0.09-0.05,0.14,0,0-0.21c0.04,0.04,0.09,0.01,0.11,0.07c-0.02-0.08,0.01,0.02-0.11-0.24c0.12-0.03,0.13-0.04,0.2-0.41
				c0.01,0.01,0.02,0.01,0.02,0.01c-0.05-0.24,0.16,0.11,0.12-0.38c0,0,0.01,0.01,0.02,0.01c-0.07-0.06-0.08-0.15-0.09-0.22
				c0.13,0.13,0.21-0.12,0.13-0.54c0.06,0.05,0.09,0.12,0.12,0.17c0.01-0.03,0.02-0.1-0.03-0.13c-0.01,0.04-0.01,0.06-0.07-0.13
				c0.19,0.05,0.08-0.15,0.23-0.16c-0.05-0.12,0.07-0.21,0.09-0.33c-0.05,0.04-0.19-0.14-0.03-0.07c-0.02-0.01-0.04-0.04-0.06-0.04
				c0.04-0.03,0.12-0.07,0.24-0.07c-0.06,0-0.07-0.04-0.04-0.07c-0.06,0.08-0.04-0.16,0.04-0.08c-0.02-0.05,0.03-0.17-0.08-0.24
				c0.02-0.01,0.06-0.01,0.07,0.01c0.13-0.57-0.01-0.41,0.26-1.51c-0.1-0.08,0.02-0.09-0.01-0.68c0.09-0.02,0.08,0.03,0.11-0.2
				l0.05,0.03c-0.13-0.16-0.14-0.02-0.06-0.42c0.02,0,0.05,0.01,0.05,0.03c0.01-0.04,0.09-0.16-0.06-0.16
				c0.09,0.01,0.02-0.09-0.02-0.15c0.17,0.05-0.02-0.01,0.06-0.56c-0.01,0.06-0.02,0.11-0.02,0.11c0.08,0.07,0.22-0.23,0.03-0.33
				c0.01-0.01,0,0.01,0.02,0.03l-0.01,0c0,0.02,0,0.04,0,0.06c-0.03-0.02-0.06-0.05-0.02-0.07c-0.04-0.02-0.09-0.04-0.11-0.05
				c-0.02-0.07,0.2-0.3,0.18-0.93c-0.01,0.01-0.02,0.02-0.03,0.02c0.02-0.12,0.16-0.13,0.04-0.68l0.08,0
				c-0.16,0.1,0.13-0.3-0.1-0.71c0,0,0,0.01,0,0.01c-0.07,0-0.17,0.01-0.32,0.02l0.07,0.06l-0.06,0.01l0-0.05
				c-0.06,0.02-0.01,0.05,0.02,0.07c-0.03,0-0.05,0.01-0.06,0.02l0.11,0.07c-0.05,0-0.09-0.03-0.12,0c0.05,0.03,0.1-0.02,0.13,0.03
				c-0.06,0.02-0.1,0.01-0.06,0.05c-0.03,0-0.05-0.01-0.06-0.02c0.03,0.03,0.01,0.08,0.07,0.1c-0.07,0.02,0.01,0.1-0.1,0.09
				c0.03,0.01,0.08,0.06,0.08,0.09c-0.13-0.06-0.02,0.1-0.16,0.07c0.11,0.05,0.04,0.14,0.1,0.21c-0.01,0-0.03-0.01-0.02-0.02
				c-0.01,0.04-0.05,0.04-0.07,0.06c0.1,0.01,0.11,0.07,0.17,0.11c-0.1,0.04-0.11-0.04-0.2-0.03c0.08,0,0.07,0.08,0.14,0.08
				c-0.06,0.03-0.03,0.03-0.11,0.04c0.12,0.02,0.02,0.03,0.09,0.07c-0.06,0.04-0.12,0-0.08,0.06c-0.14-0.08-0.04,0.23,0.17,0.42
				c0-0.01-0.02-0.02-0.04-0.03l0.02,0.03c-0.11-0.12-0.15,0.08-0.09,0.01c-0.3-0.16-0.11,0.36-0.08,0.84c0-0.36-0.16,0.03-0.2,1.63
				l-0.09,0.01c0.13,0.12-0.1-0.1,0.02,0.26c-0.03-0.01-0.04-0.03-0.06-0.05c0.12,0.07-0.12,0.02,0.04,0.08
				c-0.05,0.02-0.06,0.09-0.05,0.14l-0.05-0.02c-0.03,0.09,0,0.19-0.02,0.29c0.03-0.03,0.12-0.01,0.12,0.02l-0.08,0
				c0.05,0.02,0.17,0.05-0.03,0.01l0.02,0.05c-0.02,0-0.04-0.01-0.07-0.02c0.05,0.03-0.09,0.07-0.01,0.11
				c-0.04,0.05-0.01,0.4,0.06,0.55c-0.21-0.02-0.09,0.63-0.43,1.34c0.02,0.01,0.11,0.01,0,0.04c0.32-0.15-0.22,0.39-0.2,1.22
				c-0.02-0.01-0.04-0.02-0.04-0.03c0,0.03-0.03,0.13-0.06,0.58c-0.11-0.03-0.11,0.07-0.07,0.12c-0.03-0.02-0.04,0.02-0.08-0.01
				c-0.01,0.04,0,0.08,0.06,0.09c-0.15,0.04,0.06,0.18-0.12,0.17c0,0.2,0.08,0.11-0.15,0.77c-0.01,0-0.02-0.02-0.02-0.03
				c0,0.02-0.03,0.04,0.02,0.05l-0.01-0.02c0.08,0,0.12,0.12,0.16,0.14c-0.13-0.08-0.07,0.08-0.22-0.03c0,0.01,0.02,0.04,0.06,0.05
				c-0.13,0.11-0.02,0.03-0.21,0.43c0.02-0.02-0.04,0.08,0.06,0.06c-0.02,0.04,0.03,0.1-0.01,0.13l-0.02-0.05l-0.02,0.09l0.05-0.03
				c0.03,0.1,0.16,0.04-0.12,0.17c0.06,0.05-0.02,0.11,0.05,0.16c-0.01-0.02-0.06-0.04-0.07-0.04l0.05,0.04
				c-0.06,0.02-0.01,0.05-0.13,0.17l0-0.03l-0.05,0.01l0.04,0.03c-0.09-0.05,0.04,0.23-0.14,0.41c0-0.01-0.06-0.04-0.05-0.04
				c-0.05,0.05-0.03,0.14-0.03,0.25c0.01-0.06-0.19,0.04-0.06,0.15c-0.12,0.06-0.25-0.02-0.14,0.46c-0.01-0.06-0.08-0.04-0.12-0.04
				c0.02,0.01,0.03,0.02,0.05,0.03c-0.15-0.04,0.07,0.08-0.02,0.07c0,0.02,0,0.16-0.06,0.02c-0.04,0.13,0.04-0.03-0.16,0.13
				c0.19,0.1,0.01,0,0.07,0.21c-0.07-0.1,0-0.04-0.19-0.08c0.03,0.04-0.02,0.04-0.03,0.06c-0.17-0.24,0.03,0-0.05-0.98
				c0.01,0.34,0.16-0.02,0.17-1.9l0.09-0.02c-0.14-0.1,0.12,0.04-0.03-0.3c0.03,0,0.04,0.03,0.06,0.05
				c-0.12-0.07,0.12-0.03-0.05-0.09c0.05-0.03,0.05-0.11,0.03-0.17l0.05,0.01c0.02-0.11-0.02-0.22-0.01-0.34
				c-0.03,0.04-0.12,0.03-0.13-0.01l0.08-0.01c-0.05-0.02-0.17-0.04,0.03-0.01l-0.03-0.05c0.02,0,0.04,0.01,0.07,0.01
				c-0.05-0.02,0.08-0.09,0-0.13c0.05-0.07-0.03-0.41-0.14-0.62c0.06,0,0.06-0.09,0.1-0.05c-0.01-0.1-0.01-0.13-0.07-0.26
				c-0.04,0.03-0.1,0.01,0-0.02c-0.1-0.14,0.19-0.34,0.05-0.45l0.01,0.01c-0.09-0.18-0.01-0.13,0.04-0.85
				c-0.03,0-0.11,0.02-0.01-0.05c-0.26,0.26,0.12-0.52-0.18-1.43c0.02,0.01,0.04,0.01,0.05,0.02c0.06-0.04-0.01-0.14-0.06-0.14
				c-0.02,0.07,0.06-0.26-0.1-0.52c0.11-0.01,0.08-0.13,0.02-0.17c0.04,0.01,0.03-0.04,0.08-0.03c-0.01-0.05-0.03-0.09-0.09-0.08
				c0.12-0.11-0.13-0.17,0.03-0.24c-0.09-0.22-0.11-0.08-0.22-0.89c0.01,0,0.02,0.01,0.03,0.02c-0.01-0.02,0.01-0.05-0.04-0.04
				l0.02,0.02c-0.07,0.05-0.17-0.06-0.21-0.06c0.15,0.01,0.02-0.11,0.21-0.08c-0.01-0.01-0.04-0.03-0.07-0.02
				c0.05-0.2,0.01,0.01-0.07-0.61l-0.04,0.03c-0.01-0.05-0.08-0.09-0.06-0.14l0.05,0.03l-0.04-0.09l-0.02,0.06
				c-0.09-0.09-0.15,0.06,0-0.24c-0.08-0.01-0.04-0.12-0.13-0.13c0.02,0.01,0.07,0,0.08-0.01l-0.07-0.01
				c0.04-0.06-0.02-0.05,0.01-0.25l0.02,0.03l0.03-0.04l-0.05,0c0.1-0.01-0.15-0.17-0.14-0.48c0.01,0.01,0.08,0,0.06,0
				c0-0.08-0.07-0.15-0.13-0.25c0.03,0.07,0.12-0.16-0.05-0.17c0.04-0.13,0.21-0.17-0.21-0.51c0.05,0.04,0.09-0.02,0.12-0.05
				c-0.02,0.01-0.04,0.01-0.05,0.01c0.21-0.1-0.17-0.05,0.01-0.38c-0.2,0.06-0.01,0.01-0.21-0.13c0.11,0.03,0.04,0.03,0.19-0.08
				c-0.07-0.02,0.03-0.07-0.07-0.1c0.02-0.01,0.02-0.02,0.03-0.03c-0.04,0.01-0.1-0.1-0.29-0.51c0.15-0.01-0.12-0.11,0.09-0.08
				c-0.03-0.03-0.09-0.06-0.12-0.09c0.04-0.02,0.07,0.01,0.1,0.01c-0.01,0.01-0.04-0.07-0.11-0.07c0.03-0.05,0.06-0.11,0.25-0.22
				c-0.03,0.07,0.13,0.02,0.19-0.03c-0.13,0.06,0-0.12,0.37-0.41l-0.15,0.08c0.11-0.29,0.72-0.36,1.42-1.36
				c-0.11,0.06-0.09,0.09-0.03-0.17c-0.07,0.19-0.18,0.36-0.28,0.53l0.14-0.37c-0.1,0.15-0.17,0.34-0.25,0.42
				c0.07-0.17,0.07-0.15-0.02-0.11c0.42-0.73,0.66-0.23,0.8-1.89l-0.04,0.18c-0.11-0.19,0.11-0.02-0.09-1.27l-0.01,0.08
				c0.06-0.03,0.01-0.07,0-0.11c0.07-0.03,0.09,0.01,0.01-0.15c0.22,0.14-0.01-0.18,0.15-0.05c0-0.06,0.08-0.11,0.07-0.17
				c0.08,0.01,0.15-0.12,0.2-0.02c0-0.03,0.05-0.12,0.1-0.14c-0.05,0.15,0.18-0.05,0.18,0.11c0.04-0.13,0.21-0.06,0.35-0.08
				c-0.01,0.01-0.03,0.02-0.04,0.01c0.06,0.03,0.07,0.07,0.1,0.11c-0.08-0.06,0.47-0.14,0.12,0.09c0.04-0.07,0.17,0.01,0.18-0.06
				c0.02,0.09,0.03,0.06,0.01,0.14c0.05-0.14,0.34,0.2,0.1,0.18c0.28-0.02,0.22,0.39,0.68,0.66c-0.43-0.03,0.59,1.45,1.88-0.13
				l-0.02,0.04c0.13-0.04,0.06-0.21,0.21-0.21l-0.02,0.02c0.24,0.13,0.2-0.19,1.77-0.02c0.04-0.11-0.04,0.1,0.37,0.27
				c-0.03,0.02-0.06-0.01-0.1-0.02c0.15,0.04-0.04,0.07,0.09,0.12c0-0.02,0.04-0.02,0.05-0.03c0.01,0.08,0.09,0.19,0.16,0.28
				l-0.06,0.01c0.09,0.17,0.22,0.35,0.3,0.57c-0.09-0.05,0.23-0.08,0.05,0.01c0.06,0.01,0.19,0.01-0.02,0.03l0.06,0.09
				c-0.02,0.01-0.04-0.01-0.07,0c0.16,0.51-0.02,0.04,0.41,1.43c-0.06,0.01-0.05,0.19-0.1,0.1c0.05,0.58,0.2,0.45-0.02,1.49
				l-0.01-0.01c-0.15,0.61,0.05,0.95-0.7,3.64l-0.06-0.09c-0.01,0.39-0.22,0.74-0.28,1.12c-0.01-0.03-0.03-0.04-0.02-0.07
				c-0.08,0.05-0.1,0.27-0.05,0.29c-0.2,0.41-0.21,0.64-0.91,1.9l0-0.06c-0.34,0.74-0.36,0.54-1.08,2.09
				c0.02-0.14,0.05-0.2-0.14,0.31c0.07-0.01,0.06-0.04,0.14-0.1c0.01,0.06-0.02,0.19-0.07,0.28c0.05-0.19-0.08-0.04-0.15,0.02
				c0.02,0,0.03,0.03,0.05,0.03c-0.19,0.16,0.09,0.09-0.11,0.34c-0.05-0.16-0.03,0.02-0.15,0.19c0-0.01,0-0.02,0-0.04
				c-0.05,0.12-0.05,0.26-0.09,0.37l0.01-0.09l-0.05,0.11c0.02-0.05,0.03-0.1,0.05-0.16c-0.15,0.12-0.14,0.15-0.2,0.26l0.01-0.01
				l-0.16,0.25l0.05-0.06c0.01,0.04,0,0.07,0,0.1c0,0,0,0,0,0c0.04,0.09-0.13,0.23-0.09,0.33c-0.01-0.1-0.2,0.01-0.13,0.17
				c0-0.01-0.01-0.01,0-0.04c-0.04,0.07-0.1,0.02-0.22,0.26l0.02-0.05c-0.17,0.18,0-0.02-0.48,0.74l0.03-0.03
				c0.09,0.1-0.15,0.1-0.17,0.24c-0.12-0.05-0.02-0.07-0.32,0.44l-0.01-0.04c-0.22,0.36-0.26,0.26-0.8,1.01
				c0.07-0.08-0.19,0.08-0.05,0.29c0.04-0.11-0.07-0.06-0.13-0.04c0.02,0.01,0.02,0.03,0.04,0.04c-0.18,0.01,0.03,0.05-0.1,0.15
				c0.01-0.03-0.13,0.32-0.11,0.07c-0.06,0.08-0.03,0.23-0.13,0.25c0.01-0.04,0.05-0.09,0.06-0.13c-0.12,0.02-0.11,0.04-0.17,0.09
				l0.01,0l-0.13,0.1l0.04-0.02c0.06,0.2-0.13,0.21-0.15,0.41c0.01-0.16-0.02-0.08-0.19-0.1c0,0.1-0.12,0.07-0.12,0.21
				c-0.09-0.06,0.03,0.06-0.88,1.12c0.1-0.04-0.45,0.01-0.25,0.5c0.05-0.37,0.04-0.1,0.35-0.59c0,0.16,0.14,0.15,0.3-0.15
				c0,0.03,0.06,0.04,0.02,0.07c0.15-0.08,0.11-0.25,0.21-0.24l0,0.01c0.22-0.14,0.22-0.24,0.44-0.64c0.09,0.15,0.11-0.27,0.24-0.08
				c-0.02-0.2,0.08,0.09,0.35-0.51c0.02,0.03,0.22-0.17,0.32-0.26l0.01,0.01c-0.05-0.08,0.05-0.18,0.09-0.27
				c0.04,0.04,0.05,0.07,0.03,0.12c0-0.02,0.04,0.01,0.09-0.2l0.04,0.06c0.08-0.1,0.1-0.25,0.23-0.35
				c-0.04-0.05-0.22-0.01-0.15-0.17c0.03,0.02,0.08,0.05,0.06,0.09c0.01-0.01,0.01-0.06,0.04-0.07l0,0.09
				c0-0.04,0.14-0.18,0.06-0.03c0.05-0.05,0.12-0.09,0.17-0.09c-0.03-0.23,0,0,0.26-0.51l0,0c0.48-0.76,0.41-0.25-0.31,1.12
				c-0.04-0.13-0.08-0.23-0.3,0.65l0.07-0.1c-0.05-0.01-0.11-0.11,0.09-0.23l-0.04,0.11c0.18-0.05,0.18-0.43,0.29-0.64
				c0.06,0.07,0.1,0.12,0.36-0.45c0.01,0.04,0.08,0.01,0.02,0.09c0.18-0.24,0.13-0.46,0.25-0.52l0,0.02c0.3-0.28,0.1-0.26,0.36-0.88
				c0.03-0.01,0,0,0.32-0.46l-0.09,0.05c0.03-0.06,0.07-0.1,0.11-0.15l0.05,0.07c0.19-0.22,0.33-0.48,0.6-1.02
				c-0.22-0.01,0.33-0.12,0.16-0.19c0.12-0.11,0.13-0.2,0.23-0.32c-0.06,0.03,0.04-0.52,0.23-0.26c0.03-0.15,0.17-0.21,0.22-0.28
				l-0.02,0.08c0.01-0.05,0.03-0.08,0.05-0.11l-0.07,0.01c0.03-0.03,0.05-0.07,0.07-0.05c-0.01-0.1-0.2-0.09,0.04-0.28
				c-0.11,0.03,0.24,0.21,0.13-0.18c0.03,0.04,0.09,0,0.1-0.03c-0.02,0.05-0.1-0.04,0-0.26l-0.05,0.05
				c-0.05-0.19,0.21-0.23,0.5-0.66c-0.07,0.01-0.06,0.02-0.07-0.1l-0.08,0.24l0-0.18c-0.03,0.07-0.04,0.16-0.07,0.19
				c-0.01-0.09,0-0.08-0.06-0.07c0.18-0.35,0.32,0.01,0.5-0.71l-0.05,0.06c-0.04-0.05,0.05-0.13,0.06-0.21
				c0.04,0.03,0.08-0.01,0.12-0.02c-0.03-0.01,0.07-0.15-0.01-0.21c0.16-0.15,0.15-0.04,0.15-0.47c0.03,0.07,0.09,0.01,0.08,0.14
				c0.03-0.05,0.06-0.1,0.03-0.11c-0.02,0.1,0.13-0.2,0-0.4c0.15-0.08,0.21-0.18,0.48-0.97c0.01,0.01,0.02,0.01,0.01,0.03
				c0.06-0.31,0.03-0.01,0.25-0.54l0,0.03c0.01-0.01,0-0.02,0.01-0.03c0.02,0.06,0.15-0.11,0.2-0.21l-0.13,0.11
				c0.06-0.11,0.12-0.19,0.19-0.28l0.04,0.09c0.31-0.37,0.57-0.84,1.21-1.72c-0.29,0.02,0.51-0.27,0.31-0.3
				c0.21-0.17,0.29-0.3,0.51-0.48c-0.06-0.01,0.42-0.71,0.47-0.37c0.15-0.21,0.38-0.28,0.5-0.36l-0.09,0.1
				c0.05-0.06,0.1-0.11,0.15-0.14l-0.09,0c0.07-0.04,0.12-0.08,0.13-0.06c0.06-0.17-0.13-0.17,0.29-0.32
				c-0.26,0.12,0.03,0.06-0.11,0.17c0.26-0.1,0.15,0,0.43-0.27c-0.02,0.05,0.07,0.07,0.1,0.06c-0.06,0.02-0.01-0.16,0.3-0.09
				c-0.34-0.24-0.05-0.12,0.38,1.98c0.02-0.07-0.14,0.09-0.12,0.01c-0.14,0.16-0.23,0.35-0.16,0.35c-0.15,1.29-0.41,0.37,1.5-0.59
				c-0.04,0.04-0.03,0.1,0.07,0.05l-0.05-0.05c0.22-0.06,0.51-0.12,0.92-0.15c-0.01,0.01,0,0.02-0.04,0.02
				c0.65,0.11,0.03-0.07,0.9,0.37l-0.05-0.02c0.13,0.23,0.17-0.11,1.1,0.92c-0.03,0.01-0.05,0.01-0.07,0.01
				c0.06,0.01,0.19,0.06,0.13,0.39c-0.03-0.06-0.05-0.13-0.09-0.19c-0.03,0.13,0.11,0.25,0.13,0.36l-0.04,0
				c0.1,0.3,0.2,0.29,0.23,0.65l0.03-0.04c0.11,0.51-0.16,0.17-0.07,1.12c-0.03-0.15-0.02-0.42-0.05-0.59c0,0.13-0.05,0.42-0.02,0.5
				c0-0.05-0.01-0.12,0.02-0.17c0.05,0.17,0.03,0.39-0.06,0.63c0.09-0.31-0.63,0.61-0.81,1.42c0.32,0.03,0.28,0.19,0.86-0.27
				c-0.09,0.09-0.34,0.28-0.09,0.19c-0.19,0.03,0.3-0.23,0.2-0.24c0.23-0.07,0.46-0.07,0.67-0.03c-0.05-0.04,0.06-0.11,0.21-0.13
				l-0.23,0c0.33-0.2,0.34,0.01,0.68-0.15l-0.1,0.07c0.3,0.08,0.36-0.43,2.14,0.01l-0.05,0.02c0.13,0.04,0.06,0.01,0.51,0.26
				l-0.1-0.02c0.56,0.57,2.44,2.22-2.83,4.41l-0.01-0.07c-0.32,0.06-0.48,0.09-0.88,0.18l0.07-0.06c-0.71,0.3-0.3,0.16-1.74,0.37
				c0-0.02,0.01-0.05,0.08-0.06c-0.19,0.02-0.74,0.02-0.66,0.15c-0.02-0.09-0.42,0.04-0.63,0.1l0.03-0.07
				c-0.01,0.03-0.27,0.1-0.81,0.23l0.09-0.06c-0.33,0.03-0.17,0.2-0.74,0.09c0.1-0.02-0.28,0.15-0.21,0.06
				c-0.37-0.03,0.03,0.07-0.81,0.11c0.28-0.05,0.5-0.08,0.48-0.07c-0.01-0.06-0.24-0.04-0.07-0.15c-0.18,0.05,0.02,0.05-0.72,0.23
				l0.02-0.01c-0.3,0.07-0.18,0-0.69,0.1c0.02,0.07-0.12,0.09,0.03,0.11c-0.03,0,0.05-0.01,0.14-0.05l-0.01,0.01
				c0.07-0.02,0.17-0.04,0.28-0.06c-0.72,0.34-0.57-0.02-2.08,0.54c0.23-0.14,0.23-0.11-0.65,0.2l0.04-0.04
				c-0.69,0.06-0.96,0.56-1.72,0.65c0.05-0.01,0.08-0.01,0.09,0c-0.39-0.01-1.87,0.84-3.93,1.85l0.08,0.02
				c-0.3,0.11-0.13-0.13-1.5,1.18c-0.01-0.01,0.01,0,0.03-0.02l0,0.01c0.01,0,0.03,0,0.05-0.01c-0.01,0.04-0.03,0.07-0.06,0.03
				c-0.01,0.05-0.02,0.09-0.02,0.11c0-0.08-0.02-0.22-0.06-0.11c-0.05-0.09-0.11-0.09-0.34,0.09c-0.16-0.25,0-0.11-0.37-0.04
				c0.01,0.01,0.02,0.02,0.02,0.03c-0.09-0.01-0.16-0.14-0.55,0.05l-0.02-0.07c0.13,0.15-0.28-0.09-0.55,0.19c0,0,0,0,0-0.01
				c0.01,0.07,0.03,0.17,0.07,0.32l0.04-0.07l0.02,0.06l-0.04,0.01c0.03,0.05,0.04,0,0.05-0.03c0.01,0.03,0.01,0.05,0.02,0.06
				l0.04-0.11c0.01,0.05-0.01,0.09,0.02,0.11c0.02-0.05-0.03-0.1,0-0.14c0.03,0.05,0.02,0.1,0.05,0.05c0.01,0.03,0,0.05-0.01,0.06
				c0.02-0.04,0.06-0.02,0.07-0.08c0.03,0.06,0.08-0.02,0.08,0.09c0.01-0.03,0.04-0.09,0.06-0.09c-0.03,0.13,0.08,0,0.08,0.15
				c0.02-0.12,0.1-0.06,0.15-0.12c0,0.01-0.01,0.03-0.01,0.02c0.03,0.01,0.04,0.04,0.06,0.07c0-0.1,0.04-0.12,0.06-0.18
				c0.04,0.09-0.01,0.12,0,0.21c-0.01-0.08,0.06-0.08,0.04-0.15c0.03,0.06,0.03,0.03,0.05,0.11c0-0.12,0.02-0.02,0.04-0.1
				c0.04,0.06,0.02,0.11,0.06,0.08c-0.05,0.16,0.21,0,0.31-0.22c-0.01,0-0.01,0.02-0.02,0.04l0.02-0.03
				c0.01,0.13-0.09,0.08,0.07,0.12l-0.05-0.03c0.3,0.13,0.01,0.15,2.02-0.07l0.02,0.09c0.08-0.15-0.06,0.12,0.21-0.06
				c0,0.03-0.02,0.04-0.03,0.06c0.04-0.12,0.04,0.11,0.06-0.05c0.03,0.05,0.08,0.04,0.12,0.02c0.03,0.09-0.02,0.06,0.23,0.02
				c-0.03-0.02-0.03-0.12,0-0.13l0.01,0.08c0.01-0.05,0.02-0.18,0.01,0.03l0.04-0.03c0,0.02-0.01,0.04-0.01,0.07
				c0.01-0.05,0.07,0.08,0.09-0.01c-0.26,0.21,0.88-0.23,1.57-0.04c0-0.02-0.01-0.11,0.03-0.01c-0.12-0.18,0.68,0.03,0.61-0.03
				c0.13-0.1,0.26-0.01,0.39-0.09c-0.01,0.02-0.01,0.04-0.02,0.04c0.02-0.01,0.13-0.01,0.47-0.09c0,0.11,0.08,0.09,0.11,0.04
				c-0.01,0.03,0.03,0.04,0.01,0.08c0.03,0,0.07-0.02,0.06-0.08c0.06,0.14,0.13-0.11,0.16,0.07c0.16-0.04-0.04-0.11,0.64-0.08
				c0,0.01-0.01,0.02-0.02,0.03c0.02,0,0.03,0.02,0.04-0.03l-0.02,0.01c-0.02-0.08,0.07-0.15,0.07-0.19
				c-0.03,0.15,0.07,0.04,0.02,0.22c0.01-0.01,0.02-0.03,0.02-0.07c0.1,0.09,0.02,0.01,0.38,0.08c-0.02-0.01,0.07,0.02,0.03-0.07
				c-0.02,0.04,0.13-0.08,0.07,0.01l0.07-0.01c-0.02-0.05-0.04-0.04,0.02-0.13c0.03,0.06,0.07,0.03,0.11,0.15
				c0.03-0.08,0.09-0.01,0.12-0.09c-0.01,0.02-0.02,0.07-0.01,0.08l0.02-0.06c0.03,0.05,0.03-0.01,0.16,0.07
				c-0.01,0.01-0.05-0.01-0.01,0.05l0.01-0.05c-0.03,0.1,0.19-0.11,0.35,0.01c-0.01,0.01-0.02,0.07-0.02,0.06
				c0.05,0.03,0.11-0.02,0.2-0.04c-0.03,0,0.1,0.15,0.13,0.01c0.05,0.09,0.12,0.25,0.39-0.01c-0.04,0.03-0.02,0.09-0.01,0.13
				c0-0.02,0.01-0.04,0.01-0.05c-0.01,0.15,0.06-0.09,0.06,0c0.02-0.01,0.12-0.05,0.03,0.05c0.11,0-0.02-0.02,0.13,0.12
				c0.05-0.21,0-0.01,0.16-0.13c-0.07,0.09-0.03,0.01-0.04,0.2c0.04-0.05,0.03,0.06,0.09-0.02c0,0.02,0.03,0.01,0,0.07
				c0.06-0.25,0.05,0.13,0.38-0.15c0.02,0.04-0.11,0.11,0.04,0.11c-0.07,0.13,0.12-0.05,0,0.12c0.03-0.01,0.07-0.05,0.11-0.06
				c-0.01,0.04-0.04,0.06-0.06,0.08c0.03-0.05,0.18-0.07-0.04-0.13l0.05-0.09c0.01,0.02-0.13,0.01-0.08-0.11
				c-0.08-0.06-0.12-0.09-0.21,0.1l0.02-0.19l-0.03,0.07c-0.01,0-0.02-0.01-0.01-0.04c-0.04,0.04,0,0.03-0.03,0.08
				c-0.02-0.08-0.11,0-0.08-0.12c-0.03,0.05,0.02,0.05-0.03,0.08c0.06-0.14-0.12,0.04-0.04-0.14c-0.11,0.1-0.07-0.13-0.22,0.01
				c0.02-0.04-0.03,0.01-0.11-0.13c-0.03,0.09-0.07,0.06-0.11,0.07c0.01-0.05,0.02-0.07,0.05-0.09c-0.03,0-0.03-0.01-0.08,0.04
				l0.02-0.07c-0.04,0-0.1,0.06-0.15,0.01c-0.01,0.06,0.01,0.18-0.06,0.21c0-0.03,0.01-0.09,0.03-0.1
				c-0.06-0.06,0.02,0.04-0.05-0.16c-0.01,0.03-0.06,0.14-0.05-0.02c-0.02,0.03-0.04-0.01-0.05,0.07c-0.01,0-0.01-0.04-0.01-0.06
				c-0.02,0.03,0,0-0.09,0.08h0c0.02,0.15-0.14-0.12-0.51-0.13c0.06-0.02-0.09-0.11-0.02-0.02c-0.2-0.15-0.34,0.14-0.53-0.17
				c-0.03,0.03-0.02,0.05,0,0.06c-0.05-0.05-0.08-0.01-0.13-0.04c0.01,0.07-0.2,0.17-0.11-0.06c-0.05,0.05-0.09-0.04-0.12-0.06
				l0.03-0.02c-0.02,0.01-0.03,0.01-0.04,0.01l0.01,0.06c-0.01-0.01-0.03-0.01-0.03-0.04c-0.02,0.04,0.01,0.1-0.03,0.15
				c-0.02-0.03-0.06,0.03-0.06-0.05c0.06,0.03,0-0.08,0.05-0.09c-0.08-0.04-0.02-0.09-0.13,0.06c0.01-0.04-0.01-0.08-0.02-0.08
				c0.03,0,0.01,0.07-0.02,0.11c-0.04-0.04-0.04,0.02-0.07,0.02c0.02,0.03,0.04,0.03-0.03,0.07c0-0.03,0.03-0.02,0.02-0.04
				c-0.03,0.08-0.07-0.08-0.11-0.03c-0.02-0.03,0-0.08-0.02-0.1c-0.04,0.09-0.08-0.12-0.12,0.08l0.08-0.04
				c-0.08,0.12,0.02,0.15-0.29,0.02c0.02,0.04,0.05,0.06-0.05,0.06c0-0.05-0.01-0.07-0.02-0.1c0,0.04-0.06,0-0.07,0.09
				c-0.05-0.07-0.03-0.13-0.17,0.06c0.02-0.05-0.01-0.09,0.03-0.13c-0.06,0.04,0.01-0.01-0.16,0.17c-0.12-0.1,0.02-0.07-0.82-0.01
				c0.02-0.06,0.02-0.03,0.01-0.1c-0.08,0.46-0.05-0.27-0.46,0.12c0.02-0.07,0.08-0.11,0.11-0.16c-0.02,0-0.08,0.01-0.1,0.06
				c0.04,0,0.04-0.01-0.09,0.1c0.01-0.19-0.13-0.04-0.17-0.19c-0.09,0.07-0.17-0.02-0.28-0.01c0.02,0.07-0.08,0.24-0.05,0.01
				c0.03,0.05-0.02,0.08,0,0.13c-0.04-0.02-0.09-0.1-0.11-0.24c0.01,0.05-0.02,0.08-0.05,0.06c0.07,0.03-0.13,0.07-0.07-0.02
				c-0.04,0.03-0.14,0-0.17,0.13c-0.01-0.02-0.02-0.06,0-0.07c-0.15-0.04-0.12,0.16-1.24,0.03c0-0.01,0.02,0.13-0.07,0.08l0.01,0
				c-0.02-0.1-0.07-0.07-0.1-0.07l0,0.13c-0.1-0.19-0.24,0.08-0.3-0.09c-0.02,0.03-0.05,0.03-0.07,0.07
				c-0.02-0.07,0.02-0.1-0.17-0.07l0.01-0.05c-0.03,0.11-0.18-0.06-0.44,0.15c-0.01-0.09-0.08-0.01-0.12,0.04
				c0.02-0.18-0.02,0.05-0.46,0.02c0.05,0,0.09,0,0.09,0.01c0-0.04-0.02-0.07-0.02-0.1c0.17-0.02,0.06,0,0.43-0.11l-0.18,0.12
				c0.12-0.02,0.26-0.14,0.39-0.26c0,0,0,0,0,0c0.16-0.03,0.03,0.04,0.31-0.16c-0.01,0.03,0.05,0.06-0.02,0.08
				c0.23-0.05,0.29-0.24,0.41-0.2l-0.01,0.01c0.32-0.12,0.8-0.28,2.12-0.76c0,0.03,0.38-0.04,0.58-0.04l0,0.02
				c0.02-0.1,0.21-0.12,0.35-0.16c0,0.06-0.02,0.08-0.09,0.12c0.02-0.02,0.02,0.04,0.28-0.1c-0.09,0.25,0.08-0.07,0.67-0.18
				l-0.09,0.07c0.04-0.03,0.32-0.03,0.09,0.02c0.42,0.02,0.18,0,1.03-0.09h0c0.3-0.08,0,0,0.93,0.01c-0.02,0.1,0.29,0,0.98,0.08
				c-0.14,0.03,0.04,0.04-0.14,0.02c0.18,0.03,0.21,0.08,0.41,0.03c-0.07,0.06,0.13,0.09,0.21,0.08l-0.12-0.06
				c0.1,0,0.18,0.01,0.26,0.03l-0.05,0.08c0.38,0.09,0.8,0.1,1.66,0.16c-0.13-0.22,0.38,0.27,0.33,0.09
				c0.2,0.08,0.33,0.06,0.54,0.13c-0.03-0.05,0.68-0.1,0.46,0.15c0.21-0.01,0.36,0.12,0.47,0.15l-0.11,0c0.07,0,0.12,0.01,0.17,0.02
				l-0.05-0.07c0.06,0.02,0.11,0.04,0.1,0.06c0.14-0.06,0.03-0.2,0.38-0.02c-0.32-0.11-0.21,0.26,0.3,0.1
				c-0.04,0.04,0.04,0.1,0.08,0.1c-0.08-0.02,0.03-0.11,0.33-0.05l-0.09-0.04c0.2-0.08,0.41,0.16,1.06,0.43
				c-0.04-0.07-0.05-0.06,0.1-0.08l-0.34-0.06l0.24-0.02c-0.1-0.03-0.22-0.03-0.28-0.05c0.12-0.02,0.1-0.01,0.07-0.07
				c0.49,0.17,0.14,0.31,1.09,0.5l-0.08-0.06c0.06-0.04,0.18,0.07,0.29,0.09c-0.03,0.04,0.02,0.08,0.05,0.13
				c0.01-0.04,0.2,0.1,0.27,0.03c0.21,0.22,0.06,0.16,0.61,0.3c-0.09,0-0.02,0.09-0.17,0.03c0.34,0.14-0.06-0.11,1.42,1.5
				c-0.02,0-0.02,0.01-0.03-0.02c0.23,0.49,0.02,0,0.17,0.78l-0.02-0.04c-0.06,0.2,0.11,0.03,0.13,1.13
				c-0.03-0.01-0.05-0.02-0.06-0.03c0.04,0.04,0.11,0.15-0.14,0.29l0.05-0.16c-0.12,0.05-0.06,0.2-0.12,0.27l-0.03-0.03
				c-0.1,0.22-0.02,0.28-0.19,0.49l0.04,0c-0.22,0.32-0.15,0.04-0.67,0.54c0.07-0.11,0.22-0.24,0.3-0.35
				c-0.07,0.06-0.26,0.18-0.29,0.24c0.03-0.03,0.07-0.06,0.1-0.07c-0.68,0.36,0.01,0.2-2.1-0.06c0.08,0.18-0.09-0.01-0.14,0.1
				c-0.02-0.03-0.04-0.06-0.06-0.09c-0.45-0.47,0.03-0.02-2.34-0.87c0.02,0.01,0.18,0.13,0.91,0.55l-0.02-0.01
				c0.54,0.4,0.2-0.18,1.18,1.01l-0.06-0.06c-0.19,0.43,0.83,3.17-4.29,1.2l0.08,0c-0.56-0.21-0.26-0.05-1.12-0.8
				c0.01-0.01,0.04-0.02,0.08,0.01c-0.11-0.1-0.37-0.45-0.43-0.31c0.06-0.07-0.23-0.23-0.39-0.32l0.07-0.03
				c-0.02,0.01-0.2-0.1-0.57-0.35l0.08,0.02c-0.19-0.18-0.23,0.03-0.44-0.4c0.07,0.05-0.25-0.08-0.15-0.09
				c-0.16-0.25-0.04,0.07-0.48-0.43c0.17,0.14,0.31,0.26,0.29,0.25c0.04-0.05-0.09-0.17,0.08-0.15c-0.13-0.08-0.02,0.04-0.53-0.29
				l0.02,0.01c-0.21-0.14-0.09-0.11-0.42-0.36c-0.04,0.06-0.12-0.02-0.07,0.09c-0.02-0.02,0.03,0.02,0.1,0.05l-0.01,0
				c0.05,0.03,0.11,0.08,0.18,0.13c-0.6-0.22-0.25-0.37-1.42-0.93c0.22,0.05,0.19,0.07-0.47-0.26l0.05-0.01
				c-0.4-0.39-0.87-0.19-1.33-0.6c0.03,0.03,0.05,0.04,0.05,0.06c-0.22-0.23-1.55-0.56-3.34-0.81l0.04,0.05
				c-0.41,0.11-0.72-0.41-1.47,0.22l-0.02-0.13c-0.02,0.1-0.01,0.06,0.12,0.38c-0.26,0.02,0.13,0.19-0.08,0.19
				c0.04,0.07,0.03,0.2,0.09,0.24c-0.06,0.08,0.02,0.26-0.09,0.26c0.03,0.02,0.09,0.14,0.09,0.21c-0.13-0.13-0.01,0.27-0.15,0.23
				c0.12,0.1,0.04,0.35,0.09,0.52c-0.01-0.01-0.03-0.03-0.02-0.04c-0.02,0.09-0.06,0.12-0.08,0.18c0.02-0.11,0.29,0.49-0.05,0.2
				c0.08,0,0.05,0.21,0.12,0.18c-0.07,0.08-0.04,0.07-0.13,0.11c0.14-0.05,0.02,0.51-0.11,0.26c0.22,0.22-0.18,0.22,0.01,1.17
				c-0.61,0.01-0.04-0.02-0.53,1.11l-0.07-0.09c-0.08,0.62-0.59,0.96-1.4,2.79l0-0.05c-0.1,0.14,0.05,0.24-0.07,0.35l0-0.03
				c-0.1,0.1,0.04,0.4-0.68,2.04c0.03,0.01,0.04,0.1-0.02,0.31c0.15-0.04-0.18,0.09,0.04,0.65c-0.03-0.01-0.05-0.06-0.07-0.11
				c0.09,0.19-0.08,0.04,0.01,0.21c0.01-0.03,0.05,0.01,0.06,0.01c-0.04,0.09,0,0.29,0.05,0.43l-0.06-0.02
				c0.05,0.27,0.18,0.55,0.32,0.82c0.06,0.28-0.1,0.05,3.26-0.48l0,0.02c0.03-0.26,0.22-0.3,0.29-0.78c0.09,0.2,0.03-0.26,0.09,0.09
				c0,0-0.01-0.3,0-0.01c0-0.08,0-0.09,0-0.15c0.25,0.23,0,0.74,1.04,1.3l-0.12,0.01c0.44,0.23,0.93,0.51,1.43,0.44
				c-0.03,0.02-0.03,0.04-0.08,0.04c0.65-0.08,0.11,0.02,1.62-0.6c0.06,0.11,0.3-0.11,0.35-0.22c0,0.05,0.09-0.04,0.09,0.03
				c0.09-0.09,0.16-0.18,0.1-0.21c0.32-0.23,0.35-0.11,0.84-1.35c0.01,0.4,0.02,0.61,0.35-1.18c0.01,0,0.02,0.03,0.02,0.06
				c-0.03-0.15,0.01,0.02-0.18-0.31c0.14,0.1,0.06-0.26,0.23-0.1c0-0.04-0.03-0.08-0.06-0.08c0.01-0.05,0.03-0.07,0.07-0.01
				c0.04-0.07-0.13-0.27-0.01-0.24c-0.04-0.09-0.09-0.18-0.08-0.32c0.02,0.01,0.03-0.01,0.03,0.03c-0.01-0.25-0.15-0.41-0.13-0.69
				c0.12,0.07,0.02-0.1,0.11-0.12l-0.05-0.14l-0.04,0.07c-0.01-0.13-0.11-0.22-0.1-0.34l0.06,0.09l-0.07-0.23l-0.01,0.14
				c-0.24-0.32-0.08,0.03-0.31-0.89c0.11,0.06,0.1-0.19-0.07-0.23c0.01,0.01,0.02,0,0.04,0.03c-0.1-0.21-0.12-0.3-0.12-0.38
				l0.03,0.05c-0.08-0.26,0.03,0.02-0.49-0.88l0.02,0.05c-0.13,0.03-0.05-0.2-0.17-0.29c0.09-0.1,0.07,0.01-0.37-0.5l0.05,0.01
				c-0.34-0.35-0.19-0.38-0.97-1.11c0.08,0.03,0.13,0.06-0.16-0.14c-0.02,0.06,0,0.05,0.01,0.12c-0.05,0-0.12-0.03-0.17-0.08
				c0.11,0.06,0.06-0.07,0.05-0.13c-0.01,0.02-0.03,0.02-0.04,0.04c-0.02-0.19-0.04,0.04-0.17-0.12c0.04,0.02-0.35-0.16-0.08-0.12
				c-0.09-0.07-0.25-0.04-0.28-0.15c0.05,0.01,0.1,0.06,0.15,0.07c-0.03-0.13-0.06-0.12-0.11-0.18l0.01,0.01l-0.12-0.14l0.02,0.05
				c-0.87-0.35-0.03-0.05-2.05-1.26c0.07-0.04,0.18-0.11-0.39-0.21l0.05,0.05c-0.05-0.13,0.27,0.05,0.08,0.03
				c0,0.16,0.26,0.12,0.39,0.19c-0.15,0.03-0.15,0.15,0.24,0.28c-0.03,0.01-0.02,0.07-0.07,0.03c0.12,0.14,0.29,0.08,0.31,0.18
				l-0.02,0c0.21,0.15,0.53,0.42,1.47,1c-0.03,0.02,0.2,0.23,0.29,0.35l-0.01,0.01c0.08-0.05,0.19,0.06,0.28,0.13
				c-0.04,0.04-0.07,0.04-0.13,0.02c0.02,0-0.01,0.04,0.21,0.12l-0.07,0.03c0.1,0.09,0.26,0.14,0.35,0.3
				c0.05-0.04,0.03-0.23,0.19-0.12c-0.02,0.03-0.06,0.07-0.1,0.05c0.01,0.01,0.06,0.02,0.07,0.06l-0.09-0.02
				c0.05,0.01,0.17,0.18,0.02,0.07c0.04,0.06,0.08,0.15,0.07,0.19c0.26,0.04,0,0,0.48,0.41l0,0c0.21,0.14,0,0,0.42,0.59
				c-0.1,0.03,0.13,0.19,0.36,0.67c-0.09-0.08-0.02,0.05-0.08-0.08c0.05,0.13,0.02,0.18,0.15,0.28c-0.08-0.02-0.02,0.13,0.02,0.18
				l0-0.11c0.04,0.06,0.06,0.12,0.08,0.19l-0.09,0c0.08,0.31,0.24,0.56,0.48,1.19c0.16-0.15-0.15,0.34,0.03,0.27
				c-0.01,0.17,0.05,0.25,0.04,0.42c0.03-0.06,0.29,0.48-0.02,0.37c0.07,0.15-0.03,0.29-0.03,0.37l-0.03-0.08
				c0.02,0.05,0.02,0.09,0.02,0.13l0.06-0.05c-0.01,0.05-0.01,0.09-0.03,0.09c0.07,0.09,0.21-0.02,0.09,0.27
				c0.08-0.08-0.3-0.09-0.05,0.23c-0.04-0.03-0.09,0.04-0.08,0.07c0-0.06,0.12,0,0.1,0.25l0.03-0.07c0.1,0.16-0.1,0.32-0.33,0.81
				c0.07-0.02,0.06-0.04,0.08,0.09c0.01-0.09,0.03-0.17,0.04-0.26c0.02,0.06,0.02,0.12,0.03,0.18c0.03-0.07,0.02-0.17,0.04-0.21
				c0.03,0.09,0.01,0.08,0.07,0.06c-0.14,0.39-0.28,0.05-0.46,0.79l0.06-0.06c0.04,0.06-0.06,0.14-0.09,0.21
				c-0.04-0.03-0.08,0-0.12,0.01c0.03,0.02-0.1,0.14-0.04,0.22c-0.2,0.12-0.15,0.01-0.3,0.44c0-0.08-0.08-0.04-0.03-0.16
				c-0.04,0.05-0.09,0.08-0.06,0.1c0.05-0.08-0.2,0.13-0.19,0.37c-0.19-0.02-0.22,0.12-1.06,0.45c0-0.02-0.01-0.02,0.02-0.02
				c-0.39,0.11,0,0.03-0.6,0.01l0.03-0.01c-0.09-0.05-0.23,0.04-0.39-0.01c0,0,0.01-0.01,0.01-0.02c-0.12,0.04-0.34,0-0.49-0.07
				c0.02-0.02,0.04-0.04,0.05-0.05c-0.04,0.03-0.15,0.08-0.18-0.17c0.04,0.02,0.07,0.05,0.11,0.07c-0.22-0.4-0.45-0.12-0.95-0.91
				c0.11,0.04,0.23,0.16,0.32,0.22c-0.05-0.06-0.15-0.22-0.22-0.23c0.24,1.52-0.6-3.04-0.94-0.55c-0.02-0.11-0.03-0.33-0.09-0.18
				c0.07-0.08,0.02,0.25,0.07,0.26c-0.03,0.1-0.08,0.14-0.15,0.07c-0.1,0.29-0.09,0.08-0.2,0.28c0.06,0,0.05,0.1,0,0.17l0.11-0.1
				c-0.01,0.25-0.19,0.14-0.21,0.37l-0.01-0.09c-0.12,0.09,0.04,0.23-0.67,1.2l-0.01-0.04c-0.07,0.13-0.06,0.02-0.23,0.25l0.02-0.06
				c-0.6,0.27-0.37,0.42-1.5-1.2c-0.01,0.01-0.15,0.01-0.12-0.19c0,0.03,0.19-0.27-0.1-0.31c0.16-0.12-0.13-0.45,0.28-1.67
				l0.04,0.06c-0.05-0.46-0.08-0.19,0.25-1.04c0.01,0.01,0.04,0.03,0.03,0.07c0.05-0.11,0.25-0.38,0.1-0.4
				c0.09,0.04,0.12-0.23,0.14-0.36l0.05,0.05c-0.02-0.01,0.01-0.17,0.12-0.51l0.01,0.07c0.1-0.18-0.1-0.19,0.23-0.4
				c-0.03,0.06,0-0.23,0.04-0.13c0.18-0.15-0.07-0.04,0.27-0.43c-0.08,0.15-0.16,0.27-0.15,0.26c0.06,0.03,0.13-0.09,0.16,0.05
				c0.04-0.11-0.05-0.01,0.13-0.45l0,0.02c0.08-0.18,0.09-0.08,0.24-0.37c-0.07-0.03-0.01-0.1-0.1-0.05
				c0.01-0.02-0.01,0.03-0.02,0.09l0-0.01c-0.02,0.04-0.05,0.1-0.08,0.16c-0.05-0.06,0.14-0.07,0.01-0.32
				c0.3-0.11,0.26-0.12,0.54-0.9c0,0.18-0.02,0.16,0.15-0.4l0.02,0.04c0.28-0.36-0.01-0.72,0.28-1.12
				c-0.02,0.03-0.03,0.04-0.04,0.05c0.03-0.23,0.27-0.71,0.29-2.69l-0.05,0.04c0-0.03,0-0.08-0.01-0.12
				c0.73,0.23-0.6-0.36,0.43-0.48c-0.02,0.22,0.27-0.15,0.23,0.09c0.06-0.04,0.18,0.12,0.89-0.04c-0.18,0.11,0.33,0.06,0.27,0.19
				c0.14-0.1,0.44,0.01,0.66,0c-0.01,0.01-0.04,0.02-0.06,0.01c0.12,0.04,0.14,0.08,0.21,0.12c-0.14-0.05,0.65-0.18,0.25,0.09
				c0.01-0.08,0.27,0,0.24-0.08c0.09,0.09,0.09,0.06,0.12,0.15c-0.04-0.15,0.63,0.11,0.31,0.17c0.16-0.14,0.59,0.24,1.44,0.31
				c-0.29,0.19-0.23,0.12,1.3,0.87l-0.11,0.04c0.72,0.31,1.19,0.81,3.07,2.44l-0.05-0.02c0.13,0.17,0.28,0.06,0.38,0.24l-0.04-0.02
				c0.1,0.15,0.43,0.14,2.12,1.7c0.02-0.03,0.14,0.02,0.37,0.15c-0.03-0.25-0.01,0.21,0.82,0.18c-0.01,0.03-0.09,0.03-0.16,0.04
				c0.25-0.04,0.03,0.09,0.27,0.05c-0.03-0.02,0.02-0.04,0.03-0.06c0.11,0.06,0.37,0.07,0.56,0.05l-0.03,0.05
				c0.35,0,0.74-0.1,1.11-0.25c0.39-0.26,0.01,0.08,1.36-2.49c0.19,0.06,0.41,0.13,0.68,0.21l-0.02-0.05
				c0.1-0.04,0.11,0.04,0.18,0.05c0-0.27,1.03,0.3,2.42-1.61l0.04,0.14c0.15-0.62,0.27-1.26,0.07-1.84
				c0.02,0.03,0.05,0.03,0.06,0.08c-0.25-0.78,0-0.13-1.04-1.86c0.1-0.07-0.21-0.33-0.35-0.37c0.06,0-0.07-0.1,0-0.1
				c-0.12-0.1-0.25-0.16-0.27-0.1c-0.36-0.34-0.23-0.36-1.81-0.82c0.06-0.01,0.11-0.02,0.15-0.02c-0.28-0.06-0.3-0.17-1.6-0.42
				c0-0.01,0.04-0.01,0.08-0.01c-0.19,0.01,0.03-0.01-0.39,0.13c0.14-0.12-0.31-0.11-0.1-0.25c-0.04,0-0.1,0.01-0.1,0.05
				c-0.06-0.02-0.07-0.05,0-0.07c-0.23-0.03-0.17,0.05-0.37,0c0.01-0.02,0.05-0.02,0.08-0.05c-0.12,0.02-0.23,0.05-0.4-0.01
				c0.01-0.01,0-0.04,0.05-0.02c-0.3-0.06-0.54,0.02-0.86-0.1c0.07-0.04,0.08-0.12-0.29-0.16l0.06,0.06
				c-0.16-0.03-0.31,0.01-0.44-0.04l0.12-0.02l-0.3-0.04l0.16,0.06c-0.17,0.02,0,0.12-0.21,0.06c-0.28-0.11-0.08-0.08-0.96-0.19
				c0.1-0.11-0.21-0.14-0.3-0.06c0.02,0,0.01-0.01,0.06-0.02c-0.29-0.02-0.4-0.06-0.49-0.09l0.08,0c-0.35-0.07,0.03-0.01-1.27-0.06
				l0.06,0.01c-0.05,0.13-0.25-0.08-0.42-0.02c-0.05-0.15,0.04-0.04-0.78,0.01l0.04-0.03c-0.63,0.07-0.52-0.07-1.86,0.12
				c0.1-0.05,0.16-0.06-0.27,0.04c0.04,0.05,0.05,0.03,0.13,0.06c-0.04,0.04-0.13,0.08-0.21,0.09c0.15-0.06-0.02-0.09-0.09-0.11
				c0.01,0.02-0.01,0.04,0,0.06c-0.38,0.02-0.01,0.08-0.81,0.21c0.05-0.03,0.14-0.05,0.19-0.08c-0.15-0.05-0.16-0.02-0.27-0.01
				l0.01,0l-0.24,0.03l0.06,0.01c-0.12,0.21-0.34,0.12-0.53,0.3c0.14-0.13,0.07-0.11-0.06-0.15c0.16-0.12,0.42-0.22,0.58-0.36
				c-0.01,0.02-0.03,0.05-0.05,0.05c0.14-0.06,0.2-0.05,0.3-0.07c-0.17,0.08,0.52-0.63,0.32-0.12c-0.04-0.07,0.27-0.21,0.18-0.25
				c0.16-0.01,0.13-0.02,0.23,0.02c-0.16-0.07,0.71-0.42,0.44-0.12c0.45-0.45,0,0.02,1.48-0.75c-0.06-0.1-0.11-0.12,0.15-0.18
				c0.36,0.36-0.03,0.16,1.94-0.48l-0.06,0.12c0.94-0.4,1.8-0.43,4.93-0.89l-0.06,0.03c0.27,0,0.29-0.18,0.54-0.15l-0.05,0.02
				c0.21-0.05,2-0.08,3.34-0.59l0.03,0.07c-0.01-0.03,0.15-0.09,0.46-0.15c-0.2-0.17,0.12,0.15,0.93-0.4c0,0.03-0.08,0.08-0.14,0.13
				c0.46-0.28,0.08-0.03,0.91-0.49l-0.01,0.07c1.62-1.3,2.18-1.39-2.14-4.98l0.03-0.01c-0.67,0.24-0.38,0.01-1.41,0.46
				c-0.02-0.09,0.06-0.14-0.14,0c0.02-0.02,0.06-0.06,0.14-0.13c0,0-0.25,0.2-0.01,0c-0.06,0.04-0.09,0.07-0.13,0.09
				c0.16-0.69,0.97-1.19,0.18-3.01c0.04,0.03,0.09,0.06,0.13,0.1c-0.35-0.69-0.82-1.41-1.55-1.7c0.05,0.01,0.06-0.02,0.13,0.02
				c-0.99-0.3-0.18-0.16-2.62,0.06c-0.05-0.92,0.5-0.96-1.04-2.01c0.01,0.02-0.01,0.06-0.41,0.18c0.12-0.19-0.45,0.09-0.27-0.16
				c-0.06,0.03-0.12,0.09-0.09,0.11c-0.09,0.03-0.12,0.03-0.05-0.05c-0.2,0.08-0.09-0.02-0.72,0.61c0-0.02-0.03-0.02,0.02-0.06
				c-0.3,0.26-0.42,0.54-0.77,0.8c0.01-0.09-0.04-0.15-0.35,0.22l0.1-0.04c-0.15,0.14-0.22,0.32-0.36,0.43l0.07-0.14l-0.24,0.3
				l0.17-0.14c-0.1,0.18,0.1,0.06-0.1,0.24c0.03-0.05-0.13,0.15-0.24,0.2l0,0c-0.22,0.4-0.21-0.03-0.57,0.75
				c-0.04-0.16-0.26,0.17-0.24,0.31c0.01-0.02,0-0.02,0.02-0.07c-0.03,0.04-0.04,0.06-0.06,0.09c0.15-0.41,0.01-0.35,0.17-1.18
				c0.05,0.09,0.05,0.25,0.07,0.36c0.02-0.07,0.05-0.25,0.01-0.3c0,0.03,0.02,0.07,0,0.1c0.01-0.63-0.04-0.03,0.44-1.63
				c-0.11,0.09-0.15-0.05-0.1-0.18c0.03,0.03,0.08,0.12,0.13,0.02c-0.06,0.07-0.06-0.08-0.11-0.06c0.05-0.08,0.14-0.15,0.27-0.26
				c-0.06,0.01-0.07-0.06-0.04-0.15l-0.09,0.12c-0.05-0.23,0.15-0.17,0.09-0.4l0.04,0.08c0.04-0.13,0.02-0.2-0.04-1.36l0.04,0.02
				c-0.05-0.15,0.05-0.07-0.05-0.36l0.04,0.06c0.04-0.27-0.13-0.41-0.18-0.63c0.11-0.01,0.12-0.23,0.12-0.34l0.02,0
				c-0.08-0.44-0.05,0.02-0.57-1.7c-0.01,0.03-0.14,0.03-0.18-0.15c0.03,0.05,0.01-0.35-0.25-0.23c0.04-0.03-0.37-0.56-1.6-0.77
				l0.05-0.05c-0.42,0.24-0.19,0.06-1.05,0.38c0-0.02-0.01-0.05,0.03-0.07c-0.11,0.04-0.44,0.14-0.33,0.25
				c-0.05-0.08-0.22,0.12-0.3,0.23l-0.02-0.07c0.01,0.02-0.09,0.14-0.3,0.41l0.01-0.07c-0.12,0.03-0.16,0.35-0.49,0.28
				c0,0.07,0.03-0.01-0.05,0.24c0,0,0,0,0,0c0,0.02-0.01,0.05-0.02,0.07c0.02-0.12,0.02-0.14-0.05,0.23c0,0,0.01-0.02,0.02-0.09
				l0,0.02c0.01-0.02,0.02-0.05,0.03-0.08c-0.02,0.1-0.05,0.21-0.05,0.14c-0.03,0.15-0.07,0.3-0.08,0.37
				c0.04-0.25,0.01-0.14,0.1-0.61c-0.04,0.08-0.23,0.06-0.07-0.06c-0.03-0.01-0.03-0.13-0.11-0.09c0.18-0.33,0.12,0.05-0.31-1.04
				c-0.1,0.35,0.05,0.04-2.21-0.6l0.06,0.02c-0.04,0.03-0.13,0.08-0.31,0.27c-0.1-0.14-0.23-0.29-0.43-0.46
				c0.12,0.07,0.03-0.11,0.24,0.1c-0.08-0.12-0.16-0.22-0.19-0.2c0.28,0.13-1.84-0.72,0.22-1.74l-0.04,0.06
				c0-0.27,0.68-0.12,0.31-1.99c0.02,0,0.03,0,0.05,0.01c-0.06-0.05-0.14-0.19-0.15-0.56c0.05,0.09,0.09,0.19,0.13,0.28
				c0.03-0.18-0.17-0.37-0.24-0.51l0.04,0c-0.28-0.39-0.36-0.33-0.71-0.68l0.02,0.06c-0.62-0.33-0.04-0.43-1.3-0.75
				c0.19-0.01,0.55,0.11,0.78,0.2c-0.15-0.1-0.55-0.28-0.66-0.26c0.07,0.02,0.16,0.03,0.22,0.07c-0.23-0.02-0.5-0.08-0.84-0.18
				c0.03-0.07-0.12-0.17-0.6-0.27c0.02-0.04,0.15-0.09,0.32-0.14l-0.31,0.03c0.46-0.25,0.47-0.02,0.93-0.32l-0.13,0.12
				c0.27-0.36,1.02,0.07,2.06-2.48l0.03,0.06c-0.01-0.23,0.11-0.1,0.03-0.91l0.05,0.15c-0.01-0.71-0.37-1.03-0.73-1.4
				c0.09-0.08-0.27-0.47-0.49-0.61l0.02-0.01c-0.96-0.38-3.4-1.35-7.54,0.57l-0.02-0.07c-0.4,0.16-0.6,0.23-1.08,0.46l0.08-0.09
				c-0.83,0.57-0.38,0.27-2.04,1.08c0-0.02,0-0.05,0.07-0.09c-0.22,0.11-0.88,0.36-0.74,0.45c-0.05-0.08-0.48,0.24-0.71,0.41
				l0.01-0.08c-0.01,0.04-0.29,0.22-0.87,0.62l0.08-0.1c-0.38,0.21-0.14,0.28-0.83,0.45c0.1-0.07-0.26,0.28-0.23,0.16
				c-0.44,0.16,0.06,0.06-0.9,0.5c0.31-0.18,0.56-0.32,0.54-0.31c-0.03-0.06-0.29,0.08-0.13-0.11c-0.19,0.14,0.03,0.04-0.76,0.57
				l0.02-0.02c-0.32,0.22-0.2,0.08-0.78,0.44c0.05,0.05-0.12,0.14,0.06,0.1c-0.04,0.02,0.05-0.03,0.15-0.11l-0.01,0.01
				c0.08-0.05,0.18-0.12,0.3-0.19c-0.74,0.67-0.68,0.26-2.27,1.51c0.05-0.05,0.12-0.1,0.15-0.14c-0.03-0.01-0.43,0.51-0.85,0.63
				l0.03-0.06c-0.29,0.14-0.49,0.31-0.69,0.49c0.26-0.29-0.16,0.15,1.15-1.32c0.07,0.09-0.11,0.05,0.49-0.66
				c0.01,0.04,0.52-0.59,0.78-0.89l0.01,0.02c-0.03-0.12,0.23-0.43,0.38-0.68c0.04,0.04,0.02,0.1-0.05,0.24
				c0.01-0.04,0.05,0.01,0.31-0.52c0.02,0.27,0.13-0.08,0.74-1.2l-0.07,0.2c0.13-0.15,0.17-0.38,0.12-0.12
				c0.57-0.73,0.25-0.32,1.19-1.78l0,0c0.26-0.62,0.6-0.66,2.19-3.26c-0.11,0.26,0.08-0.06-0.13,0.26c0.21-0.3,0.29-0.35,0.44-0.72
				c-0.02,0.15,0.21-0.19,0.28-0.36l-0.17,0.2c0.09-0.17,0.18-0.32,0.28-0.47l0.02,0.12c0.4-0.64,0.88-1.45,1.53-3.1
				c-0.32,0.22,0.54-0.74,0.31-0.65c0.22-0.39,0.25-0.65,0.43-1.07c-0.03-0.01,0.14-1.28,0.34-0.95c0.06-0.42,0.22-0.76,0.28-0.99
				l-0.03,0.22c0.02-0.13,0.04-0.24,0.06-0.35l-0.09,0.11c0.04-0.12,0.06-0.24,0.08-0.23c-0.1-0.32-0.17-0.06,0.01-0.76
				c-0.14,0.72,0.29,0.36,0.09-0.65c0.04,0.07,0.09-0.12,0.09-0.21c-0.03,0.17-0.09-0.08-0.11-0.66l-0.02,0.21
				c-0.15-0.34,0.13-0.93-0.07-2.42c-0.03,0.14-0.01,0.16-0.13-0.14c0.07,0.24,0.13,0.47,0.17,0.71l-0.16-0.45
				c0.04,0.22,0.12,0.45,0.12,0.58c-0.08-0.21-0.06-0.19-0.1-0.08c-0.18-1,0.27-0.71-0.64-2.46l0.08,0.21
				c-0.54-0.74-0.17-0.75-2.02-2.02c0.17,0.03-0.04-0.12,0.31,0.02c-0.78-0.23,0.16-0.01-2.94-0.23c0.11-0.06,0.11-0.15-0.1-0.04
				l0.08,0.05c-0.39,0.15-0.83,0.47-1.27,1.16c0-0.01,0-0.01,0-0.01c-0.21,0.57-0.23,0.48-0.3,0.59c-0.01-0.12-0.02-0.25-0.03-0.41
				c0.02,0.01,0.03,0.01,0.05,0.02c-0.05-0.07-0.11-0.26-0.02-0.7c0.02,0.12,0.05,0.25,0.06,0.37c0.07-0.2-0.08-0.49-0.11-0.68
				l0.04,0.02c-0.18-0.57-0.27-0.55-0.6-1.08l0.01,0.07c-0.7-0.52,0-0.65-1.63-0.92c0.23-0.07,0.73,0.03,1.01,0.18
				c-0.19-0.15-0.75-0.32-0.88-0.26c0.09,0.01,0.22,0,0.3,0.05c-2.19,0.34-0.95-0.36-3.96,2.6c0.24-0.27-0.08,0.26,0.09,0.25
				c-0.02,0.08-0.04,0.14-0.05,0.2c-0.05-0.22-0.09-0.43-0.12-0.61l0.05,0.05c-0.1-0.24,0.01-0.12-0.36-0.99l0.1,0.14
				c-0.23-0.76-0.57-1.07-0.93-1.61c0.1-0.06-0.22-0.59-0.4-0.85l0.02,0c-1.9-1.27-5.62-1.56-5.18,5.04l-0.07-0.01
				c0.02,0.55,0.02,0.82,0.12,1.49l-0.07-0.13c0.33,1.24,0.13,0.58,0.54,2.87c-0.02-0.01-0.05-0.02-0.07-0.13
				c0.05,0.31,0.17,1.19,0.28,1.05c-0.09,0.03,0.13,0.67,0.25,1l-0.08-0.04c0.03,0.03,0.16,0.43,0.42,1.28l-0.08-0.13
				c0.13,0.51-0.03,0.03,0.33,1.34c-0.22-0.19-0.07-0.67-0.54-1.6c0,0.11,0.02,0.11-0.15-0.04c0.11,0.13,0.2,0.28,0.29,0.42
				l-0.23-0.25c0.07,0.13,0.19,0.26,0.22,0.35c-0.11-0.12-0.09-0.11-0.1-0.02c-0.37-0.65,0.13-0.57-1.04-1.46l0.12,0.12
				c-0.61-0.24-0.34-0.52-1.99-0.11c0.08-0.1-0.1-0.05,0.12-0.21c-0.74,0.82-0.07-0.04-1.12,2.92c-0.01-0.01-0.01-0.01-0.02-0.01
				c0.2,0.64-0.02,0.14,0.2,0.93c-0.06-0.05-0.12-0.1-0.19-0.16c0.02-0.01,0.03-0.02,0.04-0.02c-0.07,0-0.2-0.06-0.3-0.37l0.19,0.17
				c-0.04-0.16-0.25-0.22-0.34-0.32l0.04-0.02c-0.31-0.24-0.38-0.17-0.71-0.43l0.02,0.05c-0.55-0.25-0.04-0.33-1.1-0.67
				c0.18,0,0.47,0.14,0.66,0.21c-0.12-0.09-0.44-0.27-0.54-0.26c0.06,0.02,0.14,0.04,0.18,0.08c-1.53-0.24-0.69-0.65-2.44,1.62
				c0.2,0.09,0.32,0.09-0.02,1.04c0.05-0.05,0.09,0.1,0.11,0.28l0.02-0.27c0.16,0.43-0.06,0.41,0.13,0.84l-0.08-0.13
				c0,0.22,0,0,0.41,1.05c-0.26-0.13-0.5-0.19-0.78-0.31c0.05-0.1-0.32-0.32-0.52-0.41l0.01-0.01c0.15,0.37-5.77-0.86-3.62,5
				l-0.09-0.07c0.63,0.67,0.22,0.39,1.44,1.54c-0.02,0.01-0.05,0.02-0.11-0.02c0.16,0.15,0.64,0.57,0.66,0.4
				c-0.05,0.08,0.39,0.27,0.63,0.36l-0.07,0.04c0.03-0.02,0.3,0.1,0.88,0.34l-0.11-0.01c0.33,0.17,0.27-0.05,0.73,0.38
				c-0.1-0.04,0.34,0.05,0.23,0.08c0.32,0.26,0.02-0.08,0.79,0.42c-0.27-0.14-0.5-0.25-0.47-0.25c-0.03,0.06,0.19,0.18-0.03,0.17
				c0.19,0.07,0.01-0.05,0.77,0.27l-0.03-0.01c0.3,0.14,0.15,0.12,0.64,0.39c0.03-0.07,0.16,0.03,0.06-0.1
				c0.03,0.02-0.05-0.03-0.15-0.06l0.01,0c-0.07-0.04-0.16-0.09-0.27-0.14c0.79,0.27,0.5,0.35,1.96,1.24
				c-0.06-0.03-0.11-0.07-0.15-0.08c0,0.02,0.54,0.22,0.72,0.54l-0.06-0.01c0.44,0.58,1.04,0.63,1.43,1.38
				c-0.03-0.05-0.04-0.07-0.04-0.09c0.09,0.38,1.58,1.68,2.42,4.03l0.03-0.08c0.14,0.53,0.22,0.34,0.43,1.61
				c-0.15-0.06,0.12-0.18-0.61-0.58c0.27,0.05-0.37-0.32-0.24-0.39c-0.21-0.02-0.55-0.34-0.86-0.44c0.02,0,0.06,0,0.08,0.02
				c0.12-0.19-0.97-0.2-0.58-0.44c-0.03,0.07-0.37-0.1-0.33-0.01c-0.12-0.13-0.11-0.09-0.15-0.2c0.06,0.17-0.86-0.28-0.43-0.27
				c-0.7-0.02,0.03-0.06-1.73-0.54c-0.02,0.13,0.01,0.16-0.25,0.03c-0.07-0.52,0.12-0.11-2.02-0.79l0.12-0.06
				c-1.12-0.07-1.94-0.81-5.4-0.93l0.07-0.02c-0.29-0.04-0.34,0.13-0.61,0.07l0.05-0.01c-0.23,0.01-2.15-0.1-3.62,0.42l-0.02-0.07
				c0.01,0.03-0.16,0.09-0.5,0.15c0.21,0.18-0.13-0.17-1.02,0.42c0-0.03,0.09-0.09,0.15-0.14c-0.5,0.31-0.1,0.02-0.97,0.58
				l0.01-0.07c-1.63,1.78-2.14,1.84,3.15,4.99l-0.03,0.01c0.02-0.01,0.04-0.01,0.06-0.02c-0.34,0.27-1.26,0.68-1.89,3.02l-0.08-0.15
				c0.07,0.82,0.28,1.76,1.02,2.27c-0.05-0.02-0.07,0-0.13-0.06c1,0.46,0.12,0.33,2.84-0.17c-0.04,0.05,0.03,0.06-0.31,0.33
				c0.08-0.12-0.01,0.02-0.14,0.07l0.06-0.03c-0.02,0.03-0.04,0.07-0.06,0.11c0.07-0.04-0.03-0.05,0.18-0.16
				c-0.07,0.08-0.13,0.15-0.21,0.26c0.01-0.04,0.02-0.06,0.03-0.1c-0.57,0.68-0.71,0.27-0.88,2.08c0.01-0.01,0.04-0.03,0.32,0.32
				c-0.22-0.07,0.21,0.42-0.08,0.31c0.05,0.05,0.12,0.1,0.13,0.07c0.05,0.08,0.05,0.12-0.04,0.06c0.06,0.17,0.39,0.17,0.32,0.32
				c0.16,0.07,0.3,0.13,0.51,0.28c-0.02,0.01-0.01,0.04-0.07-0.01c0.4,0.23,0.73,0.22,1.2,0.33c-0.14,0.04,0.07,0.18,0.47,0.09
				l-0.11-0.03c0.24-0.02,0.42-0.13,0.61-0.15c-0.05,0.03-0.1,0.06-0.15,0.08c0.14-0.04,0.27-0.09,0.4-0.15
				c-0.08,0.02-0.16,0.03-0.24,0.04c0.2-0.12-0.06-0.1,0.23-0.18c-0.05,0.03,0.2-0.08,0.33-0.09l0,0c0.38-0.31,0.22,0.11,0.85-0.58
				c-0.01,0.17,0.32-0.12,0.33-0.26c-0.02,0.02,0,0.02-0.04,0.07c0.27-0.3,0.4-0.39,0.51-0.46l-0.07,0.09
				c0.36-0.34-0.01,0.04,1.09-1.34l-0.06,0.06c0.24-0.47,0.35-0.14,1.02-1.28l-0.01,0.07c0.52-0.71,0.48-0.5,1.59-1.95
				c-0.06,0.14-0.1,0.19,0.23-0.28c-0.07-0.01-0.07,0.03-0.16,0.07c0.01-0.07,0.07-0.19,0.15-0.28c-0.1,0.19,0.07,0.06,0.16,0.01
				c-0.02,0-0.02-0.04-0.04-0.04c0.23-0.12-0.06-0.1,0.2-0.32c0.03,0.05,0.05,0.12,0.48-0.58c-0.05,0.08-0.09,0.16-0.14,0.24
				c0.18-0.1,0.18-0.12,0.27-0.22l-0.01,0.01l0.23-0.23l-0.07,0.05c0.01-0.29,0.28-0.39,0.39-0.71c-0.11,0.31,0.02,0.08,0.06,0.15
				c0.07-0.05,0.16-0.13,0.16-0.09c0.32-0.52,0.13-0.18,1.37-1.84c0,0.01,0,0.02-0.01,0.04c0.05-0.08,0-0.12,0.17-0.05
				c-0.3,0.23,0.11,0.1,0.27,0.05c-0.01,0.04-0.04,0.06-0.05,0.08c0-0.02,0.06,0,0.09-0.09c-0.04,0-0.13-0.02-0.07-0.13
				c0.01,0.01-0.11,0.01-0.06-0.11c-0.07-0.06-0.1-0.08-0.18,0.1c0.08-0.21,0.03-0.09,0.52-0.51c0,0.15-0.1,0.2,0.64-0.39
				l-0.12,0.03c0.03,0.04,0.02,0.15-0.22,0.14l0.1-0.07c-0.19-0.07-0.41,0.28-0.59,0.43c0.14-0.36-0.3,0.23-0.65,0.13
				c0.02-0.03-0.03,0.01-0.08-0.13c-0.03,0.09-0.06,0.06-0.1,0.07c0.02-0.05,0.03-0.07,0.05-0.09c-0.03,0-0.02-0.01-0.07,0.04
				l0.02-0.06c-0.04-0.01-0.09,0.06-0.12,0c-0.02,0.06-0.01,0.18-0.06,0.21c0.01-0.03,0.02-0.09,0.03-0.1
				c-0.05-0.06,0.01,0.05-0.03-0.16c-0.01,0.03-0.06,0.13-0.04-0.02c-0.02,0.03-0.03-0.01-0.05,0.06c-0.01,0,0-0.04,0-0.06
				c-0.02,0.03-0.01-0.01-0.08,0.08c0.01,0.15-0.12-0.13-0.41-0.15c0.05-0.02-0.07-0.11-0.02-0.02c-0.22-0.17-0.33,0.17-0.43-0.18
				c-0.03,0.02-0.02,0.05,0,0.06c-0.04-0.05-0.07-0.01-0.11-0.04c0,0.07-0.18,0.17-0.09-0.06c-0.05,0.05-0.07-0.04-0.09-0.06
				l0.02-0.02c-0.01,0.01-0.02,0.01-0.04,0.01l0.01,0.06c-0.01-0.01-0.02-0.01-0.02-0.04c-0.02,0.04,0,0.1-0.03,0.15
				c-0.01-0.03-0.05,0.03-0.05-0.05c0.05,0.03,0-0.08,0.05-0.09c-0.07-0.05-0.01-0.09-0.11,0.06c0.01-0.04-0.01-0.09-0.01-0.08
				c0.02,0,0,0.07-0.02,0.11c-0.03-0.04-0.04,0.02-0.05,0.02c0.02,0.03,0.03,0.03-0.03,0.07c0-0.03,0.02-0.02,0.02-0.04
				c-0.03,0.08-0.05-0.09-0.09-0.04c-0.01-0.03,0-0.08-0.01-0.11c-0.03,0.09-0.07-0.11-0.11,0.09l0.07-0.04
				c0,0.12-0.18,0.14-0.3-0.02c0,0.04-0.05,0-0.06,0.09c0.01-0.22-0.18,0.19-0.11-0.07c-0.05,0.04,0.01-0.01-0.14,0.17
				c-0.05-0.15-0.14,0.02-0.19-0.12c-0.21,0.11-0.03,0.09-0.49,0.13c0.01-0.06,0.02-0.04,0-0.1c-0.01,0.28-0.11,0.04-0.28,0.01
				c-0.02,0.03-0.06,0.1-0.1,0.12c0.01-0.07,0.05-0.12,0.08-0.16c-0.15,0.12,0.04,0.08-0.58,0.01c0.03,0.05,0,0.08,0.01,0.13
				c-0.03-0.02-0.08-0.09-0.13-0.23c0.01,0.05,0,0.08-0.03,0.06c0.06,0.02-0.09,0.09-0.06-0.02c-0.03,0.03-0.12,0.02-0.13,0.15
				c0.04-0.3-0.21,0.14-1.08,0.24l0.01,0c-0.04-0.09-0.07-0.06-0.1-0.05l0.03,0.13c-0.13-0.16-0.17,0.13-0.27-0.02
				c-0.01,0.04-0.03,0.04-0.04,0.08c-0.03-0.05-0.01-0.1-0.16-0.03c-0.03-0.22-0.16,0.05-0.31,0.2c-0.04-0.09-0.06,0.02-0.08,0.07
				c-0.04-0.09,0.02-0.11-0.21,0.09c0.01,0.05-0.05,0.07-0.15,0.06c0.04-0.02,0.07-0.03,0.07-0.02c0.03-0.05-0.28-0.09-0.2,0.1
				c-0.01,0,0.01,0,0.01-0.03l0,0.01l0.04-0.02c0,0.04,0,0.07-0.03,0.04c0.01,0.05,0.02,0.09,0.03,0.11c-0.05,0-0.17-0.17-0.31,0.11
				c-0.22-0.17-0.05-0.1-0.29,0.1c0.01,0.01,0.02,0.01,0.03,0.02c-0.05,0.02-0.22-0.05-0.39,0.25l-0.05-0.06
				c0.21,0.11-0.26,0.02-0.3,0.39c0-0.01,0-0.01,0-0.01c0.04,0.06,0.11,0.13,0.21,0.24l-0.01-0.08l0.04,0.04l-0.02,0.02
				c0.05,0.03,0.03-0.01,0.02-0.04c0.02,0.02,0.04,0.04,0.05,0.04l-0.03-0.11c0.03,0.04,0.04,0.08,0.07,0.09
				c-0.01-0.05-0.07-0.08-0.07-0.12c0.04,0.03,0.06,0.07,0.06,0.02c0.02,0.02,0.03,0.04,0.03,0.06c0-0.04,0.03-0.04,0.01-0.1
				c0.05,0.04,0.04-0.05,0.1,0.04c-0.01-0.03-0.02-0.09-0.01-0.1c0.04,0.13,0.06-0.03,0.13,0.1c-0.04-0.11,0.04-0.09,0.04-0.17
				c0,0.01,0.01,0.03,0,0.03c0.03,0,0.05,0.02,0.07,0.03c-0.05-0.09-0.03-0.12-0.04-0.18c0.07,0.07,0.04,0.11,0.1,0.18
				c-0.04-0.07,0-0.09-0.04-0.14c0.05,0.04,0.03,0.02,0.08,0.08c-0.06-0.11,0.01-0.03-0.01-0.1c0.06,0.04,0.06,0.1,0.08,0.05
				c0.1,0.14,0-0.03,0.15-0.21c0.03,0.03,0.02,0.03-0.02-0.11c-0.01,0,0,0.03,0.01,0.04l0-0.03c0.07,0.11-0.03,0.11,0.1,0.08
				l-0.05-0.01c0.12,0.03,0.19,0.07,0.49-0.25c0.01,0.02,0.01,0.05,0.03,0.07c-0.08-0.07,0.21-0.22,0.73-0.3
				c-0.02-0.07,0.12-0.18,0.24-0.06l0-0.09l0.04,0.08c0.01-0.12,0.03,0.12,0.14-0.1c0.01,0.03,0,0.04-0.01,0.07
				c0-0.12,0.06,0.09,0.03-0.07c0.03,0.04,0.08,0.03,0.1,0c0.04,0.07-0.01,0.07,0.19-0.02c-0.03-0.02-0.05-0.11-0.03-0.12l0.03,0.08
				c0-0.05-0.03-0.18,0.01,0.02l0.02-0.04c0.01,0.02,0,0.04,0.01,0.07c0-0.05,0.07,0.07,0.07-0.02c-0.18,0.27,0.68-0.38,1.23-0.21
				c-0.05-0.18,0.25,0.06,0.82-0.16c-0.01,0.02,0,0.04-0.01,0.04c0.02,0.06,0.08,0,0.08-0.05c-0.01,0.02,0.05,0.12,0.12,0.01
				c0.04,0.03,0.07-0.03,0.1,0.01c0.02-0.08,0.07,0.04,0.07-0.07c0,0.11,0.07,0.09,0.09,0.04c-0.01,0.03,0.02,0.04,0.01,0.08
				c0.03,0,0.05-0.02,0.05-0.08c0.05,0.14,0.1-0.11,0.13,0.07c0.08-0.06,0.16-0.1,0.24-0.12c0.03,0.11-0.02,0.06,0.28,0.05
				c0,0.01-0.01,0.02-0.02,0.03c0.01,0,0.03,0.02,0.03-0.03l-0.01,0.01c-0.01-0.08,0.06-0.15,0.07-0.19
				c-0.03,0.14,0.06,0.04,0.01,0.22c0.01-0.01,0.02-0.03,0.02-0.07c0.07,0.09,0.01,0.02,0.31,0.08c-0.02-0.01,0.06,0.02,0.03-0.07
				c-0.02,0.04,0.12-0.07,0.06,0.01l0.06-0.01c-0.02-0.05-0.03-0.04,0.02-0.13c0.03,0.06,0.06,0.03,0.08,0.16
				c0.03-0.07,0.07-0.01,0.1-0.09c-0.01,0.02-0.02,0.07-0.02,0.08l0.02-0.06c-0.01,0.09,0.05,0.05,0.04,0.03
				c-0.01,0.04,0.22-0.03,0.1,0.11c0.07-0.01,0.16-0.09,0.21-0.05c0.01-0.16,0-0.01,0.07,0.01c-0.01,0.01-0.02,0.07-0.02,0.06
				c0.04,0.03,0.09-0.01,0.17-0.04c-0.03,0,0.07,0.15,0.1,0.01c0.03,0.1,0.08,0.24,0.33-0.01c-0.04,0.03-0.02,0.09-0.02,0.12
				l0.02-0.05c0.24,0.12-0.01,0-0.1,0.43l0.01-0.02c-0.68,0.62-0.39,0.75-1.62,1.97c-0.07-0.07,0.09-0.04-0.32,0.45
				c-0.01-0.03-0.37,0.38-0.56,0.56l-0.01-0.02c0.04,0.1-0.14,0.29-0.25,0.46c-0.04-0.04-0.03-0.08,0.01-0.18
				c0,0.03-0.04-0.01-0.21,0.35c-0.06-0.31-0.05,0.14-0.52,0.79l0.04-0.14c-0.02,0.06-0.27,0.34-0.1,0.07
				c-0.41,0.43-0.17,0.18-0.88,1.13l0,0c-0.48,0.77-0.17,0.07-1.67,2.01c0.09-0.17-0.07,0.03,0.1-0.17
				c-0.17,0.17-0.24,0.19-0.35,0.43c0.01-0.11-0.18,0.08-0.24,0.18l0.15-0.1c-0.08,0.1-0.16,0.18-0.24,0.26l-0.02-0.1
				c-0.41,0.3-0.69,0.81-1.7,1.38c0.27,0.14-0.58-0.02-0.43,0.12c-0.28,0.02-0.41,0.09-0.68,0.04c0.03,0.08-0.83,0.05-0.56-0.17
				c-0.25-0.03-0.4-0.23-0.5-0.31l0.12,0.05c-0.07-0.03-0.12-0.06-0.17-0.1l0.03,0.09c-0.06-0.05-0.11-0.09-0.09-0.11
				c-0.18-0.02-0.14,0.17-0.39-0.22c0.26,0.3,0.34-0.03-0.19-0.33c0.06,0.01,0.04-0.1,0.02-0.14c0.04,0.08-0.14,0.04-0.22-0.31
				l0.01,0.12c-0.13-0.18-0.11-0.57,0.51-1.19c-0.09,0.02-0.1,0.04-0.01-0.14c-0.09,0.12-0.18,0.24-0.24,0.37
				c0.02-0.09,0.06-0.18,0.1-0.28c-0.09,0.1-0.14,0.25-0.19,0.31c0.03-0.14,0.04-0.12-0.04-0.09c0.46-0.51,0.38-0.09,1.22-1.09
				c-0.66,0.54,0.15-0.16,0.07-1.43c-0.14-0.08,0.41,0.19-1.21,1.05c0.05-0.05,0.02-0.11-0.08-0.04l0.06,0.05
				c-0.23,0.09-0.52,0.18-0.95,0.24c0.01-0.01,0-0.02,0.04-0.01c-0.66-0.16-0.07,0.13-0.87-0.46l0.04,0.03
				c-0.06-0.26-0.26,0.07-0.8-1.27c0.03,0,0.05,0.01,0.07,0.01c-0.06-0.03-0.16-0.13,0.01-0.44c-0.01,0.08,0.01,0.15,0.01,0.23
				c0.08-0.12-0.01-0.29,0.01-0.41l0.04,0.02c0.03-0.33-0.08-0.37,0.06-0.72l-0.04,0.03c0.14-0.54,0.22-0.09,0.61-1.04
				c-0.04,0.15-0.19,0.41-0.24,0.58c0.07-0.12,0.26-0.38,0.27-0.47c-0.03,0.05-0.05,0.12-0.1,0.15c0.7-0.91-0.08-0.15,2.55-1.82
				c-0.01,0-0.01,0-0.02,0c0.05-0.01,0.09-0.02,0.15-0.04c-0.07-0.15-0.18-0.34-0.33-0.53c-0.11,0.14-0.2,0.12-0.64,0.1
				c0.05,0.04-0.07,0.11-0.24,0.13l0.25,0c-0.37,0.2-0.37-0.02-0.75,0.16l0.11-0.08c-0.35-0.06-0.36,0.39-2.38,0.17l0.04-0.03
				c-0.15-0.03-0.05,0-0.59-0.2l0.11,0.01c-0.56-0.19-3.29-2.55,2.3-4.7l0.01,0.07c0.35-0.06,0.52-0.09,0.95-0.16l-0.08,0.06
				c0.77-0.27,0.34-0.17,1.87-0.25c-0.01,0.02-0.02,0.05-0.09,0.05c0.2-0.01,0.78,0.07,0.71-0.07c0,0.1,0.44,0.02,0.68,0l-0.04,0.07
				c0.02-0.02,0.3-0.04,0.89-0.04l-0.1,0.03c0.36,0.05,0.23-0.14,0.78,0.12c-0.1-0.01,0.32-0.05,0.23,0.01
				c0.36,0.16,0-0.08,0.84,0.19c-0.29-0.06-0.53-0.11-0.5-0.11c-0.01,0.06,0.22,0.12,0.02,0.17c0.2,0.01,0-0.05,0.79,0.06l-0.03,0
				c0.32,0.06,0.17,0.07,0.71,0.21c0.01-0.07,0.16-0.02,0.03-0.11c0.03,0.01-0.05-0.01-0.15-0.02l0.01,0
				c-0.08-0.02-0.18-0.04-0.29-0.07c0.82,0.04,0.55,0.23,2.19,0.6c-0.29-0.02-0.27-0.05,0.68,0.23l-0.06,0.01
				c0.61,0.38,1.17,0.18,1.83,0.67c-0.05-0.03-0.07-0.05-0.07-0.07c0.16,0.3,1.69,0.54,3.12,1.77c-0.46,0.43-0.55-0.59,0.69,0.67
				l0-0.08c0.48,0.83,0.85,0.82-0.14,2.88c-0.08-0.05,0.08-0.06-0.21,0.52c-0.02-0.03-0.27,0.46-0.42,0.68l-0.01-0.01
				c0.06,0.09-0.09,0.32-0.17,0.5c-0.04-0.03-0.05-0.07-0.02-0.18c0,0.03-0.05-0.01-0.15,0.39c-0.12-0.3-0.01,0.15-0.39,0.88
				l0.02-0.15c-0.01,0.06-0.23,0.38-0.09,0.08c-0.35,0.49-0.15,0.2-0.74,1.25l0,0c-0.44,0.81-0.12,0.11-1.55,2.16
				c0.08-0.18-0.07,0.03,0.1-0.17c-0.17,0.18-0.23,0.2-0.34,0.45c0.01-0.11-0.18,0.1-0.23,0.2l0.14-0.11
				c-0.07,0.11-0.15,0.2-0.23,0.29l-0.03-0.1c-0.36,0.39-0.71,0.88-1.43,1.83c0.3-0.04-0.55,0.31-0.34,0.34
				c-0.23,0.19-0.31,0.35-0.54,0.55c0.05,0.01-0.43,0.8-0.5,0.45c-0.15,0.24-0.38,0.36-0.51,0.46l0.09-0.12
				c-0.05,0.07-0.1,0.12-0.15,0.18l0.1-0.02c-0.07,0.05-0.12,0.11-0.14,0.09c-0.04,0.21,0.14,0.14-0.28,0.43
				c0.27-0.23-0.06-0.04,0.11-0.24c-0.27,0.22-0.2,0.08-0.44,0.53c0-0.06-0.12,0-0.15,0.05c0.08-0.08,0.06,0.11-0.22,0.4l0.11-0.08
				c-0.08,0.25-0.48,0.39-1.25,1.01c0.1-0.01,0.1-0.03-0.01,0.15l0.33-0.36L672.2,153c0.11-0.1,0.2-0.24,0.27-0.29
				c-0.08,0.14-0.07,0.11,0.01,0.1c-0.54,0.46-0.46,0.05-1.33,1.06l0.13-0.08c-0.25,0.36-0.36,0.15-1.37,1.33
				c0.04-0.11-0.1-0.03,0.05-0.21c-0.35,0.45,0.14-0.09-2.28,2.06c0-0.02-0.02-0.02,0.02-0.04c-0.56,0.54-0.02-0.01-0.94,0.71
				l0.04-0.05c-0.24,0.16-0.08,0.04-1.18,1.18c-0.01-0.02-0.01-0.04-0.02-0.05c0,0.07-0.07,0.19-0.4,0.25l0.19-0.16
				c-0.16,0.01-0.25,0.21-0.36,0.28l-0.01-0.04c-0.29,0.25-0.23,0.33-0.55,0.59l0.05,0c-0.38,0.46-0.31-0.04-0.95,0.83
				c0.07-0.15,0.28-0.38,0.4-0.54c-0.11,0.09-0.38,0.32-0.41,0.41c0.04-0.05,0.08-0.11,0.14-0.14c-0.86,0.89-0.01,0.07-2.6,2.06
				c0.26-0.03-0.13,0.12,0,0.21c-0.22,0.24-0.42,0.24-0.81,0.35c0.07,0.01-0.03,0.13-0.17,0.23l0.23-0.11
				c-0.27,0.35-0.36,0.15-0.64,0.5l0.07-0.13c-0.3,0.24-0.3,0.29-1.87,1.86l0-0.05c-0.1,0.14-0.06,0.04-0.48,0.51l0.05-0.11
				c-0.41,0.32-0.5,0.63-0.79,0.95c-0.08-0.08-0.4,0.18-0.55,0.32l-0.01-0.02c-0.77,0.91-0.16-0.24-4.15,5.05l-0.05-0.05
				c-0.23,0.29-0.35,0.43-0.59,0.82l0.01-0.11c-0.32,0.81-0.15,0.35-0.98,1.73c-0.01-0.01-0.03-0.04,0.01-0.11
				c-0.11,0.18-0.49,0.66-0.32,0.67c-0.08-0.04-0.23,0.4-0.31,0.64l-0.04-0.07c0.02,0.03-0.09,0.3-0.32,0.87l0.01-0.11
				c-0.16,0.33,0.06,0.27-0.37,0.73c0.04-0.1-0.04,0.33-0.08,0.23c-0.26,0.32,0.08,0.01-0.39,0.8c0.12-0.28,0.23-0.51,0.22-0.49
				c-0.06-0.03-0.17,0.2-0.17-0.02c-0.06,0.2,0.05,0.01-0.23,0.79l0-0.03c-0.12,0.32-0.12,0.16-0.33,0.69
				c0.07,0.02,0,0.16,0.11,0.04c-0.02,0.03,0.02-0.05,0.04-0.16l0,0.01c0.03-0.08,0.07-0.17,0.12-0.29
				c-0.15,0.86-0.37,0.51-0.77,2.23c0.02-0.3,0.04-0.28-0.21,0.71l-0.01-0.06c-0.31,0.68-0.09,1.21-0.4,1.97
				c0.02-0.05,0.03-0.08,0.05-0.09c-0.2,0.36-0.33,2.21-0.38,4.74l0.05-0.07c-0.01,0.35,0.01,0.11,0.05,1.66
				c-0.11-0.08-0.22-0.16-0.34-0.23c0,0.02,0.03,0.06-0.46-0.11c0.22-0.02-0.38-0.33-0.06-0.32c-0.06-0.03-0.14-0.06-0.15-0.03
				c-0.07-0.07-0.09-0.1,0.02-0.07c-0.31-0.19-0.24-0.07-0.5-0.27c0.02-0.02,0.07,0.01,0.12,0.01c-0.16-0.06-0.32-0.12-0.53-0.3
				c0.02,0,0.01-0.04,0.07,0.01c-0.38-0.28-0.72-0.39-1.1-0.74c0.1,0.01,0.16-0.04-0.32-0.37l0.06,0.1
				c-0.19-0.15-0.41-0.23-0.55-0.38l0.17,0.08l-0.37-0.27l0.18,0.19c-0.23-0.11-0.05,0.11-0.29-0.11c0.06,0.03-0.19-0.16-0.25-0.28
				l0,0c-0.46-0.27-0.01-0.22-0.9-0.71c0.18-0.02-0.21-0.31-0.36-0.31c0.03,0.02,0.02,0,0.08,0.03c-0.34-0.28-0.46-0.41-0.56-0.52
				l0.09,0.07c-0.4-0.38,0.04,0.02-1.49-1.26l0.07,0.07c-0.5-0.32-0.14-0.42-1.34-1.28l0.07,0.02c-0.72-0.69-0.51-0.58-1.94-2.09
				c0.1,0.07,0.12,0.1-0.26-0.32c-0.02,0.07,0.02,0.08,0.05,0.19c-0.07-0.03-0.18-0.13-0.26-0.23c0.18,0.16,0.07-0.06,0.03-0.16
				c0,0.02-0.04,0.01-0.05,0.03c-0.09-0.28-0.12,0.04-0.3-0.3c0.06-0.01,0.14,0-0.53-0.68l0.22,0.22c-0.07-0.22-0.05-0.07-0.36-0.65
				l0.03,0.09c-0.29-0.12-0.33-0.43-0.62-0.67c0.28,0.23,0.08,0.01,0.16-0.01c-0.03-0.09-0.09-0.22-0.05-0.2
				c-0.08-0.09-0.17-0.06-0.35-0.57c-0.01,0.12-0.13-0.03-0.29-0.28c0.35,0,0.54-0.16,0.69-0.11l-0.03,0.01
				c1.04-0.15,0.91-0.31,2.81-0.71c0.01,0.11-0.11-0.02,0.59-0.21c-0.01,0.05,0.58-0.15,0.87-0.23l0,0.02
				c0.02-0.11,0.31-0.2,0.51-0.3c0.01,0.05-0.02,0.09-0.12,0.15c0.02-0.03,0.03,0.04,0.41-0.22c-0.12,0.33,0.12-0.11,0.94-0.52
				l-0.11,0.12c0.16-0.04,0.06-0.08,0.18-0.13c0.02,0.06,0.21,0-0.05,0.1c0.64-0.24,0.28-0.09,1.44-0.74l0,0
				c0.2-0.24,1.16-0.49,2.57-1.5c-0.16,0.15,0.08-0.01-0.17,0.15c0.25-0.13,0.32-0.14,0.52-0.37c-0.05,0.11,0.22-0.06,0.31-0.15
				l-0.18,0.08c0.11-0.1,0.22-0.17,0.33-0.24l-0.01,0.11c0.49-0.31,1.03-0.76,2-1.69c-0.32,0.02,0.64-0.31,0.42-0.35
				c0.27-0.2,0.38-0.36,0.65-0.58c-0.04-0.02,0.52-0.85,0.57-0.5c0.17-0.27,0.43-0.41,0.56-0.54l-0.1,0.14
				c0.06-0.08,0.11-0.14,0.17-0.21l-0.11,0.03c0.07-0.07,0.13-0.13,0.15-0.11c0.05-0.23-0.14-0.13,0.3-0.49
				c-0.29,0.28,0.07,0.04-0.12,0.27c0.29-0.27,0.22-0.1,0.45-0.63c0,0.07,0.12-0.02,0.16-0.08c-0.09,0.1-0.06-0.11,0.2-0.47
				l-0.11,0.11c0.05-0.29,0.5-0.49,1.17-1.37c-0.1,0.05-0.1,0.07-0.02-0.16l-0.27,0.47l0.13-0.34c-0.09,0.13-0.17,0.31-0.23,0.38
				c0.05-0.17,0.06-0.14-0.03-0.11c0.46-0.63,0.5-0.19,1.13-1.52l-0.1,0.13c0.14-0.45,0.34-0.29,0.95-1.91
				c0,0.13,0.11-0.01,0.02,0.24c0.17-0.64-0.08,0.15,1.21-3.22c0,0.01,0.01,0.01,0.01,0.01c0.11-0.8,0.08,0.02,0.27-1.27l0,0.07
				c-0.03-0.3,0.22-0.15-0.33-1.87c0.02,0,0.03,0,0.05,0c-0.06-0.04-0.15-0.17-0.12-0.52c0.04,0.09,0.08,0.18,0.11,0.27
				c0.03-0.17-0.14-0.35-0.2-0.49l0.04,0c-0.22-0.39-0.31-0.35-0.59-0.72l0.01,0.06c-0.54-0.4,0.04-0.43-1.12-0.89
				c0.19,0,0.52,0.18,0.72,0.29c-0.13-0.12-0.48-0.35-0.59-0.34c0.07,0.02,0.15,0.05,0.2,0.1c-1.47-0.35-0.59-0.49-3.25,0.22
				c-0.02-0.44-0.09-0.89-0.13-1.21l0.05,0.04c-0.1-0.19,0.04-0.12-0.33-0.79l0.1,0.1c-0.27-0.61-0.65-0.75-1.08-1.02
				c0.07-0.09-0.36-0.39-0.62-0.46l0.01-0.01c0.08,0.62-1.64-1.24-3.86,5.78l-0.06-0.03c-0.17,0.37-0.24,0.55-0.43,1.02l-0.01-0.11
				c-0.22,0.92-0.07,0.43-0.82,2.01c-0.01-0.01-0.03-0.04,0-0.11c-0.11,0.21-0.47,0.76-0.3,0.76c-0.09-0.04-0.25,0.44-0.34,0.69
				l-0.04-0.07c0.01,0.03-0.1,0.33-0.39,0.94l0.02-0.12c-0.21,0.35,0.04,0.3-0.47,0.76c0.06-0.1-0.09,0.35-0.12,0.24
				c-0.3,0.32,0.08,0.03-0.56,0.8c0.19-0.28,0.34-0.51,0.33-0.48c-0.05-0.03-0.21,0.19-0.17-0.04c-0.1,0.2,0.05,0.02-0.41,0.8
				l0.01-0.03c-0.2,0.31-0.15,0.15-0.51,0.66c0.06,0.03-0.05,0.17,0.1,0.07c-0.03,0.03,0.03-0.05,0.09-0.15l0,0.01
				c0.05-0.07,0.12-0.17,0.19-0.28c-0.4,0.84-0.47,0.5-1.53,2.08c0.03-0.06,0.09-0.12,0.09-0.16c-0.03,0-0.25,0.59-0.61,0.79
				l0.01-0.06c-0.63,0.52-0.69,1.15-1.44,1.67c0.05-0.04,0.08-0.06,0.1-0.05c-0.27,0.2-1.2,1.14-2.34,2.64
				c0.02-0.09,0.04-0.17,0.09-0.23l-0.01,0.04c0.47-1.28,0.03-0.73,1.47-3.41c0.08,0.07-0.09,0.06,0.35-0.73
				c0.01,0.04,0.42-0.65,0.63-0.97l0.01,0.02c-0.04-0.11,0.17-0.44,0.3-0.7c0.04,0.04,0.03,0.09-0.02,0.24
				c0.01-0.04,0.05,0.01,0.26-0.54c0.05,0.27,0.11-0.09,0.64-1.23l-0.05,0.2c0.12-0.15,0.14-0.38,0.11-0.13
				c0.52-0.75,0.23-0.32,1.08-1.8l0,0c0.23-0.62,0.57-0.67,1.96-3.31c-0.1,0.26,0.08-0.07-0.11,0.27c0.19-0.31,0.27-0.36,0.39-0.73
				c0,0.15,0.19-0.21,0.25-0.37l-0.15,0.21c0.08-0.18,0.15-0.33,0.23-0.48l0.03,0.12c0.33-0.67,0.77-1.49,1.2-3.18
				c-0.29,0.25,0.45-0.79,0.23-0.67c0.09-0.31,0.34-0.88,0.59-3.08l0.01,0.22c-0.01-0.13,0-0.24,0-0.35l-0.07,0.12
				c0.02-0.13,0.02-0.24,0.05-0.23c-0.13-0.29-0.16-0.03-0.09-0.74c-0.01,0.73,0.34,0.3-0.03-0.65c0.05,0.06,0.07-0.14,0.04-0.22
				c0.01,0.17-0.12-0.06-0.25-0.62l0.03,0.2c-0.23-0.28-0.06-0.95-0.67-2.29c0,0.14,0.03,0.15-0.17-0.09
				c0.13,0.2,0.26,0.41,0.36,0.63c-0.08-0.13-0.18-0.26-0.28-0.38c0.1,0.19,0.24,0.39,0.27,0.52c-0.13-0.18-0.11-0.17-0.11-0.05
				c-0.45-0.88,0.08-0.76-1.33-2.12l0.14,0.17c-0.76-0.5-0.38-0.63-2.56-1.15c0.16-0.03-0.07-0.1,0.29-0.09
				c-0.78,0.06,0.23-0.11-4.16,1.56c0-0.01,0-0.01,0-0.01c-0.6,0.86-0.12-0.09-1,1.36l0.03-0.08c-0.08,0.18-0.31,0.41-0.43,1.87
				c-0.19-0.69,0.22-0.36-0.41-1.71c0.15,0.18,0.3,0.62,0.4,0.89c-0.04-0.21-0.18-0.72-0.27-0.81c0.03,0.08,0.09,0.18,0.1,0.27
				c-1.09-1.95-0.04-0.9-3.41-3.32c0.33,0.16-0.28,0-0.22,0.16c-0.61,0.1-0.7,0.01-1.35,0.14c0.09,0.01-0.11,0.15-0.35,0.31
				c0.12-0.06,0.24-0.12,0.36-0.17c-0.5,0.45-0.57,0.24-0.96,0.81c0.03-0.07,0.06-0.13,0.1-0.2c-0.11,0.12-0.56,0.52-0.94,2.29
				C625.68,146.11,623.13,142,621.15,148.32z M626.26,157.56c-0.02-0.03-0.04-0.08-0.09-0.16
				C626.21,157.46,626.17,157.4,626.26,157.56z M626.11,158.38c-0.08-0.07-0.06-0.04-0.1-0.19
				C626.04,158.24,626,158.19,626.11,158.38z M625.91,156.97c0.03,0.03-0.04-0.13-0.07-0.24
				C625.78,156.67,626.07,157.06,625.91,156.97z M616.96,177.24c-0.01-0.02-0.01-0.03-0.01-0.04
				C616.95,177.21,616.96,177.22,616.96,177.24z M615.86,177.51c0-0.02,0.02-0.02,0.03-0.04
				C615.91,177.48,615.92,177.49,615.86,177.51z M620.28,171.15c0.03-0.01-0.1-0.05-0.18-0.08
				C620.04,171.08,620.43,171.07,620.28,171.15z M621.25,171.76c-0.08,0.03-0.06,0.04-0.17-0.02
				C621.12,171.73,621.08,171.75,621.25,171.76z M620.68,171.17c0.06,0.01,0,0,0.14,0.02C620.79,171.19,620.75,171.18,620.68,171.17
				z M670.72,220.7c-0.01-0.03-0.02-0.07-0.04-0.14C670.7,220.62,670.68,220.56,670.72,220.7z M670.54,220
				c-0.04-0.05,0.13,0.3,0,0.2C670.56,220.22,670.54,220.09,670.54,220z M670.35,221.16c0.02,0.04-0.01,0.01,0.06,0.16
				C670.35,221.27,670.35,221.29,670.35,221.16z M704.05,151.5c-0.01-0.01-0.03-0.03-0.06-0.06
				C704.02,151.46,703.99,151.44,704.05,151.5z M703.94,152.04c-0.04,0-0.06,0.02-0.08-0.06
				C703.88,151.99,703.85,151.99,703.94,152.04z M703.8,151.31c0.03,0-0.02-0.06-0.05-0.11
				C703.72,151.18,703.93,151.31,703.8,151.31z M709.21,155.64c0-0.02,0.02-0.03,0.03,0.01
				C709.23,155.64,709.21,155.64,709.21,155.64z M713.07,146.17c0,0.02-0.02,0.03-0.03-0.01
				C713.04,146.17,713.06,146.17,713.07,146.17z M712.61,146.32c-0.02-0.01-0.03,0.02-0.05,0.03
				C712.57,146.34,712.55,146.28,712.61,146.32z M709.41,153.41C709.41,153.42,709.41,153.42,709.41,153.41
				C709.41,153.42,709.41,153.42,709.41,153.41L709.41,153.41z M709.44,157.25c0.02,0.01,0.08-0.05,0.08,0.09
				C709.5,157.31,709.48,157.28,709.44,157.25z M709.51,157.43c0.01,0.01,0.03,0.02,0.02,0.02c0-0.01,0.01-0.01,0.02-0.01
				c0,0.07,0,0.03-0.01,0.17C709.51,157.56,709.54,157.49,709.51,157.43z M709.78,156.54c0,0.01,0,0.02,0.02,0.04
				c-0.02-0.01-0.02,0.03-0.07-0.01c0.07-0.08,0.05,0.03-0.08,0.4c-0.09-0.03,0.1-0.08-0.01-0.45c0.03,0.01,0.06,0.01,0.07,0.01
				l0,0.01c0.03-0.04,0.06-0.09,0.07,0.01C709.78,156.54,709.78,156.55,709.78,156.54z M709.65,156.46L709.65,156.46
				C709.65,156.46,709.65,156.46,709.65,156.46C709.65,156.46,709.65,156.46,709.65,156.46z M709.71,155.59c0-0.01,0-0.01,0-0.03
				C709.71,155.57,709.71,155.56,709.71,155.59z M709.67,155.47C709.67,155.47,709.67,155.46,709.67,155.47
				C709.69,155.46,709.68,155.46,709.67,155.47z M709.72,155.44c0,0-0.01,0-0.01,0c0,0,0.01,0,0.01-0.01
				C709.72,155.43,709.72,155.44,709.72,155.44z M708.42,142.4c0.01-0.01,0.01-0.01,0.02-0.02
				C708.46,142.38,708.44,142.39,708.42,142.4z M720.47,155.26c0.04-0.05,0.03-0.06,0.13-0.05
				C720.57,155.23,720.59,155.2,720.47,155.26z M711.06,159.59c0-0.01,0-0.03,0-0.04C711.07,159.54,711.09,159.56,711.06,159.59z
				 M711.24,159.98c0,0.02,0.02,0.03,0.03,0.05C711.26,160.02,711.22,160.04,711.24,159.98z M717.5,163.33
				c0.05-0.01,0.06-0.02,0.1,0.05C717.57,163.38,717.6,163.37,717.5,163.33z M708.38,167.47c0,0.03,0.05-0.18,0.09-0.06
				C708.47,167.38,708.42,167.44,708.38,167.47z M708.52,167.21c0.01-0.03,0,0,0.03-0.07C708.55,167.16,708.54,167.18,708.52,167.21
				z M709.09,167.12c0.01,0.01,0,0,0.02,0.03C709.09,167.19,709.03,167.24,709.09,167.12z M710.19,161.4c0-0.01,0-0.02,0-0.03
				c0.03,0.01,0.04,0.01,0.07,0.02L710.19,161.4z M717.5,163.91c0.02,0.01,0.04,0.02,0.08,0.05
				C717.54,163.94,717.58,163.96,717.5,163.91z M717.8,164.07c-0.03,0,0.03,0.07,0.06,0.12C717.9,164.19,717.67,164.08,717.8,164.07
				z M713.3,158.42C713.3,158.42,713.3,158.43,713.3,158.42C713.3,158.43,713.3,158.42,713.3,158.42
				C713.3,158.42,713.3,158.42,713.3,158.42z M711.21,159.54C711.21,159.54,711.21,159.54,711.21,159.54
				C711.22,159.54,711.21,159.54,711.21,159.54z M720.99,155.62c0.02-0.01,0.06-0.01,0.11-0.03
				C721.06,155.6,721.1,155.59,720.99,155.62z M721.4,155.48c-0.02,0.02,0.09,0.01,0.16,0.02
				C721.61,155.47,721.31,155.6,721.4,155.48z M722.34,144.91C722.34,144.91,722.33,144.91,722.34,144.91L722.34,144.91
				C722.34,144.91,722.34,144.91,722.34,144.91z M722.45,143.65c0.02,0.12,0.02,0.19,0.02,0.25c-0.03-0.11-0.1-0.21-0.12-0.27
				C722.44,143.72,722.43,143.72,722.45,143.65z M722.47,143.91c0,0.06,0,0.11-0.01,0.16c-0.03-0.12-0.08-0.24-0.14-0.35
				C722.38,143.78,722.43,143.84,722.47,143.91z M715.31,150.67c-0.01,0.05,0.01,0.01-0.05,0.14
				C715.27,150.78,715.27,150.71,715.31,150.67z M714.41,152.37c-0.01,0.02-0.02,0.02-0.02,0.01L714.41,152.37z M714.26,152.69
				c-0.01,0.02-0.02,0.04-0.04,0.05C714.23,152.73,714.21,152.72,714.26,152.69z M713.21,154.64c-0.01,0.03-0.02,0.06-0.04,0.09
				C713.18,154.7,713.2,154.67,713.21,154.64z M715.2,151.21c0.01-0.04,0.02-0.08,0.03-0.12c0.01,0.04,0.01,0.09,0.02,0.15
				C715.23,151.2,715.21,151.2,715.2,151.21z M717.59,146.39c0,0-0.01-0.01-0.03-0.02C717.57,146.38,717.58,146.39,717.59,146.39
				c-0.04,0.05-0.06,0.08-0.09,0.12c0.01-0.05,0.01-0.11,0.02-0.16c0.02,0.01,0.03,0.02,0.05,0.02c-0.04-0.04-0.08-0.12,0.12-0.21
				l-0.03,0.12c0.01,0,0.01,0,0.01,0C717.64,146.32,717.61,146.36,717.59,146.39z M713.6,139.38c-0.01,0.05,0.04-0.01,0.08-0.04
				c-0.03,0.04,0.01,0-0.09,0.16C713.58,139.48,713.58,139.48,713.6,139.38z M694.86,129.47c0.04-0.05-0.26,0.21-0.2,0.06
				C694.64,129.56,694.77,129.5,694.86,129.47z M694.34,129.78c-0.05,0.04,0,0-0.12,0.08C694.25,129.85,694.28,129.82,694.34,129.78
				z M693.5,129.77c0.04-0.08,0.02-0.07,0.14-0.11C693.61,129.69,693.64,129.65,693.5,129.77z M688.13,134.25
				c0.01-0.01,0.02-0.02,0.03-0.03C688.16,134.23,688.14,134.24,688.13,134.25z M693.78,108.49c0.02,0.03,0.01,0.01-0.01,0.09
				L693.78,108.49z M687.95,109.79c0,0.01,0,0,0,0.02L687.95,109.79z M683.78,119.86c0.01,0.05-0.06-0.23-0.01-0.02
				c-0.01-0.03,0,0-0.03-0.09l0.01,0.01c-0.03-0.12-0.06-0.27-0.1-0.44c0.21,0.6,0.23,0.82,0.28,1.23
				c-0.02-0.03-0.03,0.05-0.02,0.15c0-0.31-0.07-0.47-0.09-0.79C683.81,119.91,683.8,119.89,683.78,119.86z M683.57,133.58
				c-0.01-0.02,0-0.05,0.02-0.08C683.58,133.53,683.58,133.55,683.57,133.58z M683.5,133.79c-0.02,0.06-0.04,0.1-0.06,0.08
				C683.44,133.96,683.35,134.1,683.5,133.79z M682.87,135.83c0.01,0.01,0.02,0.01,0.03,0.01
				C682.89,135.86,682.88,135.86,682.87,135.83z M684.45,123.73l-0.04-0.07c-0.04,1.07,0.4,1.71,0.21,2.9
				c0.01-0.08,0.02-0.12,0.03-0.14c-0.17,0.46,0.02,2.66-0.7,5.59c0.01-0.12-0.11,0.14-0.01,0.04c-0.09,0.38-0.2,0.77-0.32,1.17
				l0.06-0.06c0,0.01,0,0.06-0.07,0.28c-0.02-0.47,0.1-0.51,0.3-1.81c0.1,0.03-0.06,0.08,0.04-0.57c0.03,0.02,0.1-0.54,0.16-0.8
				l0.01,0.01c-0.09-0.06-0.04-0.33-0.04-0.54c0.05,0.01,0.07,0.05,0.09,0.16c-0.01-0.03,0.05-0.01-0.03-0.42
				c0.22,0.22-0.04-0.14-0.03-0.97l0.05,0.14c0.03-0.15-0.05-0.08-0.03-0.2c0.06,0.01,0.1-0.17,0.07,0.08
				c0.07-0.61,0.03-0.25,0.02-1.47v0c-0.13-0.93,0.13-0.17-0.02-2.7c0.05,0.19,0.03-0.07,0.04,0.2c0.01-0.25,0.04-0.31-0.04-0.58
				c0.07,0.09,0.07-0.2,0.04-0.31l-0.03,0.18c-0.02-0.13-0.02-0.25-0.02-0.37l0.09,0.06c0.08-0.38-0.26-1.08-0.31-2.76
				c0.05,0.38,0.13,0.92,0.35,1.9c-0.03-0.09-0.04-0.19-0.07-0.24C684.23,122.45,684.52,123.2,684.45,123.73z M683.97,118.87
				c-0.01-0.04-0.02-0.09-0.05-0.18C683.95,118.77,683.92,118.69,683.97,118.87z M683.76,118.2c0.03,0.04,0-0.14-0.01-0.26
				C683.71,117.88,683.9,118.33,683.76,118.2z M675.12,128.15c0.03,0-0.07-0.07-0.13-0.12
				C674.93,128.02,675.27,128.11,675.12,128.15z M675.79,128.95c-0.07,0.01-0.06,0.02-0.14-0.06
				C675.68,128.9,675.64,128.9,675.79,128.95z M675.46,128.26c0.05,0.02,0,0,0.12,0.05C675.55,128.3,675.52,128.29,675.46,128.26z
				 M672.2,138.19c0-0.01-0.01-0.02-0.01-0.03C672.19,138.15,672.22,138.16,672.2,138.19z M672.49,138.5c0,0.02,0.02,0.02,0.04,0.04
				C672.51,138.54,672.49,138.56,672.49,138.5z M679.38,137.95C679.37,137.95,679.37,137.95,679.38,137.95c0-0.01,0-0.01,0.01-0.02
				C679.38,137.94,679.38,137.94,679.38,137.95z M679.3,137.97c-0.01,0.01-0.03,0.03-0.04,0.04
				C679.28,137.96,679.28,137.97,679.3,137.97z M673.06,133.23c0.03-0.01-0.08-0.05-0.16-0.08
				C672.85,133.16,673.2,133.15,673.06,133.23z M673.88,133.83c-0.06,0.03-0.05,0.04-0.15-0.02
				C673.77,133.81,673.73,133.82,673.88,133.83z M673.4,133.25c0.05,0.01,0,0,0.12,0.02C673.5,133.27,673.47,133.26,673.4,133.25z
				 M649.03,177.76c0-0.06-0.07,0.29-0.11,0.14C648.92,177.93,648.98,177.82,649.03,177.76z M648.83,178.23
				c-0.02,0.05,0,0-0.05,0.12C648.79,178.33,648.8,178.29,648.83,178.23z M648.17,178.62c-0.02-0.07-0.03-0.06,0.05-0.15
				C648.21,178.51,648.2,178.47,648.17,178.62z M646.85,189.62c0,0,0,0.01,0,0.01C646.85,189.63,646.85,189.63,646.85,189.62
				C646.85,189.62,646.85,189.62,646.85,189.62z M639.46,164.61c0-0.08-0.01-0.07,0.08-0.15
				C639.53,164.5,639.53,164.45,639.46,164.61z M633.32,172.89c0,0.04,0,0.08-0.01,0.14C633.33,173.02,633.26,172.98,633.32,172.89z
				 M632.35,174.67c-0.01-0.02-0.02-0.04-0.04-0.06c0.02-0.01,0.03,0,0.05-0.02C632.35,174.62,632.35,174.64,632.35,174.67z
				 M632.25,174.39c-0.01,0.03-0.01,0.06-0.02,0.09C632.15,174.35,632.11,174.26,632.25,174.39z M633.53,176.92
				c-0.01-0.01-0.02-0.03-0.03-0.04l0.09-0.03C633.56,176.88,633.63,176.91,633.53,176.92z M648.64,155.8
				C648.65,155.79,648.65,155.8,648.64,155.8c0,0.01,0,0.02,0,0.03L648.64,155.8z M640.54,163.83c0.01-0.06-0.14,0.3-0.15,0.13
				C640.39,163.99,640.47,163.89,640.54,163.83z M640.22,164.31c-0.03,0.05,0,0-0.07,0.12C640.16,164.4,640.18,164.37,640.22,164.31
				z M634.12,171.88c0.02-0.22,0.37-0.58,0.54-0.87c-0.01,0.02-0.01,0.06-0.04,0.08c0.14,0.15,0.46-0.98,0.58-0.53
				c-0.07-0.04,0.22-0.35,0.11-0.34c0.16-0.09,0.13-0.1,0.25-0.11c-0.19,0.01,0.61-0.8,0.42-0.36c0.27-0.64,0.07,0.01,1.27-1.6
				c-0.12-0.07-0.15-0.05,0.08-0.27c0.45,0.09,0.06,0.23,1.64-1.83l0.01,0.14c0.56-1.11,1.55-1.77,3.32-5.22l-0.02,0.08
				c0.19-0.28,0.04-0.4,0.24-0.64l-0.02,0.06c0.12-0.25,1.17-2.04,1.68-3.74l0.07,0.02c0.09-0.52,0.1-0.27,0.55-1.7
				c0.02,0.02,0,0.13-0.01,0.22c0.14-0.62,0.03-0.1,0.34-1.17l0.04,0.06c0.67-2.14,1.27-2.91,3.18,1.86
				c0.06-0.05,0.06,0.15,0.04,0.38c0.02-0.11,0.06-0.22,0.09-0.32c0.08,0.51-0.31,0.84,0.68,0.23l0.01,0.16
				c0.29-0.71-0.38-0.99,1.35-1.82l-0.04-0.03c0.69-0.32-0.1-0.17,3.15,1.77l-0.13-0.1c0.43,0.68,0.43,1.55,0.42,2.34
				c-0.08-0.52,0.08,0.29-0.75,2.76c-0.09-0.06-0.31,0.46-0.35,0.65c0-0.07-0.11,0.14-0.12,0.05c-0.1,0.2-0.17,0.39-0.1,0.38
				c-0.46,0.6-0.26,0.47-1.4,2.58c0.02-0.12,0.05-0.2,0.07-0.26c-0.22,0.32-0.24,0.45-1.44,2.1c0.02,0,0.06-0.02-0.12,0.45
				c-0.01-0.23-0.34,0.37-0.32,0.04c-0.03,0.06-0.07,0.14-0.03,0.15c-0.07,0.07-0.1,0.08-0.07-0.02c-0.21,0.29-0.09,0.23-0.31,0.47
				c-0.02-0.02,0.02-0.07,0.02-0.12c-0.07,0.15-0.15,0.31-0.35,0.49c0-0.02-0.04-0.01,0.02-0.07c-0.32,0.35-0.43,0.69-0.85,1.01
				c0.02-0.1-0.02-0.17-0.42,0.26l0.11-0.04c-0.18,0.17-0.28,0.36-0.46,0.49l0.1-0.15l-0.32,0.33l0.21-0.15
				c-0.15,0.21,0.1,0.07-0.15,0.27c0.05-0.05-0.19,0.16-0.32,0.2l0,0c-0.35,0.39-0.22-0.04-0.86,0.74c0.02-0.18-0.34,0.15-0.38,0.28
				c0.02-0.02,0-0.02,0.05-0.07c-0.35,0.26-0.51,0.35-0.63,0.42l0.09-0.08c-0.45,0.31,0.03-0.03-1.57,1.12l0.09-0.04
				c-0.44,0.4-0.42,0.06-1.58,0.95l0.04-0.06c-0.85,0.5-0.69,0.34-2.55,1.23c0.09-0.08,0.13-0.09-0.38,0.15
				c0.06,0.04,0.08,0.01,0.2,0.01c-0.05,0.06-0.18,0.13-0.3,0.18c0.21-0.12-0.04-0.09-0.14-0.08c0.02,0.01,0,0.04,0.01,0.05
				c-0.29,0,0,0.12-0.38,0.19c0.01-0.07,0.05-0.13-0.81,0.28c0.08-0.04,0.21-0.08,0.28-0.13c-0.22,0-0.24,0.02-0.39,0.05l0.02,0
				l-0.35,0.07l0.09-0.01c-0.2,0.24-0.51,0.19-0.82,0.4c0.3-0.21,0.04-0.08,0.04-0.16c-0.09,0.01-0.23,0.02-0.2-0.01
				c-0.1,0.05-0.12,0.14-0.65,0.14c0.47-0.13-0.32,0.38-2.18,0.47c0.04-0.01,0.06-0.01,0.08-0.01c-0.41-0.29-0.02,0.01-0.75-1.09
				c0.01,0.05,0.05-0.02,0.21,0.13l-0.07,0.02c0.05,0.04,0.06-0.01,0.08-0.05c0.05,0.04,0.04,0.12,0.1-0.08
				c0.08,0.24,0.07-0.14,0.12,0.06c0.03-0.05,0.1-0.04,0.1-0.11c0.06,0.05,0.13-0.05,0.16,0.05c0.01-0.03,0.04-0.1,0.08-0.12
				c-0.03,0.15,0.14-0.03,0.16,0.11c0.01-0.13,0.16-0.1,0.23-0.19c0,0.01-0.01,0.03-0.02,0.03c0.05-0.01,0.08,0.03,0.12,0.04
				c-0.02-0.1,0.04-0.14,0.06-0.22c0.09,0.07,0,0.12,0.05,0.2c-0.03-0.07,0.08-0.11,0.04-0.17c0.06,0.04,0.05,0.01,0.1,0.08
				c-0.03-0.12,0.03-0.04,0.05-0.12c0.04,0.05,0.15-0.04,0.11,0.13c-0.04-0.2,0.28,0.01,0.46-0.48c-0.02,0.01-0.01,0.03-0.02,0.05
				l0.02-0.04c-0.11,0.18,0.2,0.08,0.05,0.08c0.1,0.38,0.44-0.41,0.58-0.15c0.24-0.23,0,0.09,0.5-0.32
				c-0.27,0.11,0.41-0.03,0.93-0.43l-0.01,0.03c0.09-0.01,0.01-0.18,0.12-0.16l-0.01,0.02c0.16-0.07,0.13,0.11,0.98-0.57l0.08,0.06
				c0.01-0.18,0.03,0.12,0.28-0.23c0.01,0.03,0,0.05-0.01,0.08c-0.01-0.15,0.09,0.08,0.06-0.1c0.06,0.01,0.14-0.04,0.19-0.1
				l0.02,0.05c0.11-0.06,0.21-0.18,0.32-0.26c-0.06,0.01-0.1-0.07-0.07-0.1l0.06,0.06c-0.01-0.05-0.07-0.17,0.03,0.01l0.04-0.06
				c0.02,0.02,0.01,0.04,0.03,0.06c-0.01-0.06,0.14,0,0.12-0.09c0.11-0.02,0.35-0.31,0.51-0.57c0.04,0.05,0.12-0.02,0.11,0.05
				c0.09-0.09,0.11-0.11,0.2-0.25c-0.05-0.01-0.07-0.07,0.02-0.01c0.07-0.19,0.44-0.11,0.46-0.31l0,0.01
				c0.11-0.21,0.08-0.1,0.8-0.65c-0.02-0.02-0.08-0.06,0.04-0.05c-0.39,0,0.55-0.33,1.12-1.33c0.01,0.02,0.02,0.04,0.01,0.06
				c0.08,0,0.11-0.13,0.07-0.16c-0.06,0.04,0.24-0.16,0.37-0.52c0.08,0.07,0.17-0.05,0.15-0.13c0.02,0.03,0.05-0.02,0.08,0.03
				c0.03-0.05,0.05-0.11,0-0.13c0.19-0.08,0.13-0.02,0.4-0.67c0.1,0.24-0.1,0.38,0.36-0.49c0.01,0.01,0.01,0.02,0.01,0.04
				c-0.01-0.09,0,0.02-0.11-0.24c0.1,0.12,0.1-0.09,0.22,0.07c0-0.02-0.01-0.05-0.04-0.07c0.2-0.15,0,0.01,0.42-0.61l-0.05,0
				c0.03-0.06,0-0.14,0.05-0.17l0.01,0.06l0.04-0.11l-0.06,0.04c-0.01-0.08-0.11-0.05-0.04-0.11c0.02,0.02,0.03-0.06,0.22-0.11
				c-0.05-0.06,0.05-0.14,0-0.22c0.01,0.02,0.05,0.04,0.06,0.05l-0.04-0.05c0.1-0.07,0.04-0.08,0.19-0.23l-0.01,0.04l0.05-0.01
				l-0.03-0.03c0.14-0.04-0.09-0.13,0.34-0.85c-0.02,0.08,0.2-0.07,0.08-0.2c0.12-0.1,0.28-0.04,0.18-0.64
				c0.01,0.07,0.09,0.03,0.13,0.02c-0.02,0-0.03-0.02-0.05-0.02c0.15,0-0.06-0.07,0.02-0.1c0,0.01-0.02-0.21,0.06-0.05
				c0.03-0.17-0.01,0.02,0.17-0.23c-0.2-0.07-0.02,0-0.08-0.25c0.07,0.1,0.02,0.05,0.2,0.04c-0.04-0.06,0.07-0.05,0.01-0.14
				c0.02,0,0.02-0.01,0.04-0.02c-0.04-0.01-0.02-0.17,0.07-0.7c0.13,0.08-0.04-0.19,0.12-0.02c-0.01-0.05-0.04-0.11-0.04-0.17
				c0.04,0,0.05,0.05,0.07,0.07c-0.02,0,0.03-0.14-0.09-0.16c0.15,0.13-0.14-0.11-0.05,0.26l-0.09-0.06l0.06,0.1
				c-0.03,0.01-0.09,0-0.09-0.04c-0.05,0.03-0.06,0-0.05,0.13c-0.02-0.02-0.07-0.01-0.05-0.04c-0.05,0.07-0.15,0.18,0.07,0.32
				l-0.2,0l0.07,0.04c-0.01,0.01-0.02,0.03-0.05,0.02c0.04,0.06,0.03,0,0.07,0.04c-0.08,0.04-0.03,0.18-0.15,0.15
				c0.05,0.04,0.06-0.04,0.08,0.03c-0.14-0.08,0.02,0.18-0.16,0.07c0.12,0.13-0.18,0.05-0.06,0.35c-0.04-0.03,0.02,0.02-0.17,0.18
				c0.08,0.05,0.04,0.11,0.03,0.17c-0.05-0.02-0.07-0.04-0.08-0.08c0.01,0.01-0.04,0.02,0.01,0.13l-0.06-0.04
				c-0.02,0.07,0.03,0.17-0.05,0.23c0.06,0.03,0.2,0,0.19,0.11c-0.03-0.01-0.09-0.03-0.09-0.06c-0.01,0.01,0.01,0.1-0.04-0.01
				c0.02,0.04-0.05,0.11-0.04,0.02c-0.02,0.03-0.07,0.06-0.11,0.06c0.03,0.02,0.11,0.11-0.05,0.08c0.02,0.03-0.03,0.06,0.04,0.09
				c-0.01,0.02-0.04,0.01-0.06,0.01c0.02,0.05,0,0,0.04,0.15l0,0c0.05,0.12-0.22,0.25-0.35,0.68c-0.01-0.07-0.04,0,0-0.06
				c-0.05,0.05-0.09,0.05-0.07,0.15c-0.04-0.05-0.1,0.01-0.1,0.05l0.06-0.02c-0.16,0.15-0.08-0.01-0.29,0.36
				c0.32-0.02-0.15,0.38-0.14,0.3l0.02,0.02c-0.04,0.01-0.08,0.1-0.11,0.05c0,0.06,0.03,0.04,0.06,0.02
				c-0.08,0.05-0.06,0.12-0.14,0.18c0.05-0.02,0.05,0.36-0.15,0.13c0.01,0.1-0.11,0.11-0.14,0.14l0-0.05c0,0.03-0.01,0.05-0.02,0.06
				l0.06,0.01c-0.11,0.06-0.07-0.01-0.04,0.2c0.09-0.1-0.24-0.18-0.09,0.09c-0.03-0.03-0.08-0.03-0.08-0.01
				c0-0.03,0.1,0.02,0.04,0.18c0.04-0.01,0.06-0.04,0.03,0.08c-0.03-0.02,0-0.05-0.02-0.04c0.08,0.16-0.45,0.06-0.27,0.33l0.05-0.14
				l0.02,0.12c0.03-0.03,0.02-0.09,0.04-0.11c0.02,0.06,0.01,0.05,0.06,0.06c-0.13,0.25-0.2-0.17-0.38,0.32c0.05,0,0.09-0.03,0,0.1
				c-0.04-0.03-0.07-0.02-0.1-0.03c0.03,0.02-0.06,0.07,0.01,0.14c-0.12,0.04-0.13-0.03-0.12,0.26c-0.03-0.06-0.08-0.04-0.07-0.12
				c-0.03,0.11,0-0.03-0.02,0.33c-0.14-0.01-0.16,0.02-0.45,0.41c-0.01-0.01-0.02-0.02-0.01-0.02c-0.1,0.22-0.01-0.02-0.25,0.22
				l0-0.02c-0.08,0.01-0.04,0.14-0.1,0.2c0,0-0.01-0.01-0.02-0.02c0.03,0.1-0.03,0.2-0.07,0.28c-0.02-0.02-0.03-0.03-0.04-0.04
				c-0.1-0.04-0.31-0.01-0.48,0.6c-0.02-0.08,0.01-0.17,0.02-0.24c-0.03,0.03-0.09,0.11-0.07,0.16c0.04-0.04,0.05-0.06-0.03,0.17
				c-0.13-0.15-0.18,0.13-0.33,0.06c-0.06,0.15-0.23,0.18-0.35,0.3c0.08-0.02,0.05,0.24-0.03,0.09c0.01,0.02,0,0.06,0.02,0.08
				c-0.07,0.01-0.16,0-0.27-0.06c0.05,0.03,0.03,0.08-0.02,0.1l0.1,0c-0.03,0.14-0.16-0.02-0.2,0.12l0-0.06
				c-0.03,0.07-0.17,0.16-0.14,0.29c-0.02-0.01-0.06-0.03-0.04-0.05c-0.11,0.02-0.13,0.13-0.46,0.45l0-0.05
				c-0.46,0.43-0.12,0.15-1.18,0.84c0-0.01,0.09,0.1-0.06,0.13l0.01-0.01c-0.08-0.07-0.12,0-0.18,0.03l0.06,0.12
				c-0.24-0.08-0.31,0.27-0.47,0.17c-0.01,0.05-0.05,0.07-0.07,0.12c-0.09-0.07-0.02-0.07-0.28,0.08l0-0.06
				c-0.09,0.24,0.04,0.14-0.43,0.37c-0.01-0.01-0.02-0.04,0-0.06c-0.05,0.03-0.24,0.05-0.15,0.16c-0.05-0.08-0.12,0.05-0.16,0.12
				l-0.02-0.07c0.01,0.02-0.02,0.08-0.17,0.24l0.01-0.05c-0.17,0.07-0.07,0.04-0.5,0.23c0.08-0.03,0.15-0.06,0.14-0.05
				c0.01-0.13-0.33-0.06-0.41,0.19c-0.01,0,0.01-0.01,0.03-0.04l0,0.01c0.02-0.01,0.05-0.02,0.08-0.04
				c-0.01,0.04-0.02,0.08-0.08,0.06c0,0.05,0,0.1,0,0.12c-0.09,0.05-0.42,0.04-1.22,0.41c0.02,0.01,0.03,0.01,0.04,0.02
				c-0.2,0.05-0.16-0.09-0.87,0.33L633.5,175c-0.04,0.15-0.01,0.01-0.4,0.2c-0.09-0.92,0,0.09-0.42,0.06
				c0.02,0.07,0.01,0.01-0.04,0.15c0-0.01,0-0.01-0.01-0.03l0,0.04c0,0,0,0,0,0c0,0.01,0,0.02,0,0.03c-0.07-0.17-0.08-0.1-0.38-0.79
				c0.02,0.02,0.11,0.05,0.21,0.19c-0.02-0.03-0.03-0.05-0.05-0.07c0.09,0,0.27-0.17,0.38-0.58l-0.07,0.24
				c0.14-0.17,0.12-0.28,0.52-1.42c-0.01,0.27-0.04,0.66-0.09,0.29c-0.05,0.29-0.1,0.75-0.03,0.85l0.04-0.18
				c-0.05-0.01-0.14-0.11,0.04-0.39l-0.02,0.18c0.19-0.13-0.07-0.03,0.23-1.01c0.13,0.08-0.15,0.45,0.3-0.58
				C633.7,172.45,634.04,171.74,634.12,171.88z M635.91,174.46c0.01,0,0.02-0.01,0.03-0.02
				C635.93,174.45,635.94,174.44,635.91,174.46z M636.01,174.36c0,0.02,0.04,0.02,0.07,0.03
				C636.08,174.37,635.99,174.45,636.01,174.36z M635.62,174.07c0,0.01-0.02,0-0.03,0.03C635.59,174.09,635.57,174.04,635.62,174.07
				z M633.43,148.95C633.36,148.91,633.4,148.88,633.43,148.95L633.43,148.95z M633.35,149.32c0-0.02,0-0.04,0-0.06
				C633.38,149.25,633.37,149.28,633.35,149.32z M626.96,150.18c0.04-0.01,0,0,0.17-0.02L626.96,150.18z"
                />
                <path
                  className="st10"
                  d="M631.94,162.67c0.09,0.17-0.01,0.35,0.08,0.52c-0.02-0.01-0.04-0.01-0.04-0.03c-0.06,0.04,0,0.13,0.05,0.13
				c0.03-0.06-0.08,0.24,0.05,0.48c-0.11,0-0.09,0.11-0.03,0.15c-0.03-0.01-0.03,0.04-0.08,0.02c0,0.05,0.03,0.09,0.08,0.08
				c-0.13,0.09,0.12,0.16-0.06,0.22c0.07,0.21,0.1,0.08,0.14,0.83c-0.01,0-0.02-0.01-0.03-0.02c0.01,0.02-0.01,0.05,0.04,0.04
				l-0.02-0.02c0.07-0.04,0.16,0.07,0.2,0.07c-0.15-0.02-0.03,0.1-0.22,0.06c0.01,0.01,0.04,0.03,0.07,0.02
				c-0.08,0.17-0.01,0.04-0.02,0.51c0.01-0.03-0.01,0.1,0.08,0.03c0,0.05,0.08,0.08,0.05,0.13l-0.04-0.03l0.03,0.09l0.03-0.05
				c0.07,0.09,0.15-0.04-0.03,0.22c0.08,0.02,0.03,0.11,0.12,0.13c-0.02-0.01-0.07,0-0.08,0l0.07,0.01
				c-0.05,0.05,0.02,0.05-0.03,0.23l-0.02-0.03l-0.04,0.03l0.05,0.01c-0.1,0,0.14,0.18,0.1,0.45c-0.01-0.01-0.08-0.01-0.06-0.01
				c-0.01,0.07,0.05,0.14,0.11,0.24c-0.02-0.06-0.13,0.15,0.03,0.16c-0.06,0.12-0.22,0.14,0.16,0.48c-0.05-0.04-0.09,0.02-0.12,0.04
				c0.02-0.01,0.04,0,0.05-0.01c-0.14,0.06,0.1,0.03,0.03,0.08c0.01,0.01,0.1,0.13-0.03,0.06c0.05,0.12-0.01-0.01-0.05,0.22
				c0.21-0.04,0.01-0.01,0.19,0.13c-0.11-0.03-0.03-0.03-0.2,0.06c0.07,0.02-0.04,0.06,0.06,0.1c-0.02,0-0.02,0.02-0.03,0.03
				c0.04,0,0.09,0.09,0.24,0.49c-0.15,0,0.11,0.11-0.1,0.07c0.03,0.03,0.08,0.06,0.11,0.09c-0.04,0.02-0.07-0.01-0.1-0.02
				c0.08,0.02,0.15,0.15,0.09-0.12l0.11,0c-0.16-0.03-0.01-0.05-0.01-0.16c0.02,0,0.07-0.02,0.06,0.01c0.01-0.1,0.02-0.19-0.2-0.18
				l0.18-0.08l-0.08,0c0-0.01,0-0.03,0.03-0.03c-0.06-0.03-0.03,0.01-0.08,0c0.06-0.06-0.06-0.13,0.06-0.16
				c-0.06-0.01-0.04,0.05-0.08,0.01c0.16-0.01-0.1-0.11,0.11-0.11c-0.13-0.08,0.09-0.15-0.11-0.25c0.04,0.01-0.03-0.02,0.07-0.19
				c-0.09,0-0.09-0.06-0.11-0.1c0.05-0.01,0.08,0,0.11,0.02c-0.01-0.03,0-0.04-0.07-0.08l0.07,0c-0.01-0.05-0.1-0.1-0.06-0.17
				c-0.06,0.01-0.17,0.08-0.22,0.02c0.03-0.01,0.09-0.02,0.11,0c-0.01,0,0.03-0.11,0.07-0.04c0-0.03,0.03-0.07,0.06-0.09
				c-0.03,0-0.15-0.02,0-0.07c-0.04-0.01-0.01-0.05-0.08-0.04c0-0.02,0.03-0.02,0.05-0.03c-0.04-0.02,0,0-0.11-0.08l0,0
				c-0.11-0.06,0.07-0.25-0.05-0.58c0.05,0.04,0.04-0.02,0.04,0.04c0.01-0.06,0.05-0.07-0.02-0.12c0.06,0.01,0.07-0.05,0.05-0.07
				l-0.04,0.04c0.11-0.17-0.2-0.48,0-0.61l-0.03,0c0.03-0.03,0.02-0.1,0.07-0.09c-0.03-0.04-0.05-0.01-0.06,0.02
				c0.04-0.07-0.01-0.1,0.01-0.17c-0.05,0.03-0.23-0.21,0.04-0.15c-0.06-0.06,0.03-0.12,0.04-0.15l0.03,0.03
				c-0.02-0.02-0.02-0.03-0.02-0.05l-0.06,0.02c0.01-0.02,0.01-0.04,0.03-0.04c-0.04-0.02-0.1,0.02-0.15-0.01
				c0.03-0.03-0.04-0.07,0.04-0.09c-0.02,0.11,0.3,0,0.02-0.1c0.04,0.01,0.06-0.01,0.07-0.03c-0.02,0.01-0.09,0-0.13-0.1
				c-0.03,0.03-0.02,0.05-0.07-0.04c0.03-0.01,0.03,0.03,0.04,0.02c-0.08-0.03,0.07-0.09,0.02-0.15c0.02,0,0.23-0.11,0-0.18
				l0.05,0.1l-0.09-0.06c0,0.03,0.04,0.07,0.03,0.09c-0.05-0.03-0.04-0.03-0.08-0.01c-0.06-0.24,0.26,0.03,0.1-0.38
				c-0.04,0.03-0.05,0.06-0.06-0.06c0.05,0,0.07-0.02,0.1-0.03c-0.04,0,0-0.07-0.1-0.08c0.07-0.08,0.13-0.05-0.07-0.21
				c0.05,0.02,0.09-0.01,0.13,0.04c-0.04-0.08,0.01,0.02-0.19-0.2c0.11-0.07,0.11-0.08,0.08-0.47c0.01,0,0.02,0,0.02,0.01
				c-0.11-0.23,0.18,0.07,0.01-0.41c0,0,0.01,0,0.02,0c-0.08-0.05-0.11-0.13-0.13-0.2c0.14,0.02,0.09,0.03,0.11-0.1
				c0.3,0.22-0.29-0.34,0.01-0.31c-0.02-0.03-0.09-0.12-0.11-0.17c0.07,0.03,0.11,0.1,0.15,0.15c0-0.03-0.01-0.11-0.05-0.13
				c0,0.05,0,0.06-0.09-0.12c0.19,0.02,0.05-0.17,0.2-0.21c-0.07-0.12,0.04-0.22,0.04-0.35c-0.04,0.05-0.21-0.11-0.04-0.06
				c-0.02-0.01-0.04-0.03-0.07-0.03c0.04-0.04,0.1-0.09,0.23-0.11c-0.06,0.01-0.08-0.03-0.05-0.06c-0.05,0.09-0.06-0.16,0.03-0.09
				c-0.03-0.05,0.01-0.18-0.11-0.23c0.02-0.01,0.06-0.02,0.07,0.01c0.09-0.6-0.06-0.43,0.17-1.57c0.01,0.01-0.14,0-0.06-0.11l0,0.01
				c0.1-0.01,0.08-0.07,0.09-0.12l-0.13-0.02c0.21-0.09-0.03-0.32,0.15-0.36c-0.03-0.03-0.02-0.07-0.05-0.1
				c0.09-0.02,0.08,0.03,0.11-0.2l0.05,0.03c-0.12-0.17-0.14-0.02-0.04-0.44c0.01,0,0.04,0.01,0.05,0.03
				c0.01-0.04,0.1-0.16-0.04-0.17c0.09,0.01,0.03-0.1-0.01-0.16c0.17,0.06-0.03-0.02,0.11-0.58c-0.02,0.07-0.03,0.12-0.03,0.11
				c0.07,0.08,0.24-0.21,0.06-0.34c0.01,0,0,0.01,0.01,0.04l-0.01,0c0,0.02-0.01,0.04-0.01,0.07c-0.03-0.03-0.06-0.06-0.01-0.08
				c-0.04-0.02-0.08-0.05-0.1-0.06c0-0.08,0.21-0.28,0.3-0.94c-0.01,0.01-0.02,0.01-0.04,0.01c0.04-0.13,0.17-0.1,0.15-0.7
				l0.08,0.01c-0.18,0.07,0.18-0.28,0.03-0.75c0,0.01,0,0.01,0,0.01c-0.07-0.01-0.17-0.03-0.32-0.04l0.05,0.08l-0.06,0l0.01-0.05
				c-0.06,0.01-0.02,0.05,0.01,0.07c-0.03,0-0.05,0-0.07,0.01l0.09,0.09c-0.05-0.01-0.08-0.05-0.11-0.02c0.04,0.04,0.11,0,0.13,0.06
				c-0.06,0.01-0.1-0.01-0.06,0.04c-0.03,0-0.05-0.02-0.06-0.03c0.03,0.04-0.01,0.08,0.05,0.12c-0.07,0.01-0.01,0.11-0.12,0.07
				c0.02,0.02,0.07,0.08,0.07,0.1c-0.11-0.08-0.04,0.1-0.17,0.05c0.1,0.07,0.01,0.15,0.06,0.23c-0.01-0.01-0.02-0.02-0.02-0.02
				c-0.02,0.03-0.06,0.04-0.09,0.06c0.09,0.03,0.1,0.09,0.15,0.14c-0.1,0.02-0.1-0.06-0.2-0.06c0.08,0.02,0.06,0.1,0.13,0.1
				c-0.07,0.02-0.03,0.02-0.12,0.02c0.12,0.04,0.01,0.04,0.08,0.09c-0.07-0.04-0.16,0.22,0.01,0.52c0.01-0.02-0.02-0.02-0.03-0.03
				l0.02,0.03c-0.09-0.14-0.17,0.07-0.09-0.01c-0.3-0.23-0.15,0.39-0.19,0.87c0.03-0.35-0.16,0.01-0.3,1.72l-0.1,0.01
				c0.13,0.1-0.11-0.05,0.01,0.28c-0.03-0.01-0.04-0.03-0.05-0.05c0.12,0.07-0.12,0.03,0.04,0.09c-0.05,0.03-0.06,0.1-0.05,0.15
				l-0.05-0.02c-0.02,0.1,0,0.21-0.01,0.32c0.03-0.04,0.12-0.02,0.12,0.02l-0.08,0c0.05,0.02,0.17,0.05-0.03,0.01l0.02,0.05
				c-0.02,0-0.04-0.01-0.07-0.02c0.05,0.03-0.09,0.08-0.01,0.12c-0.05,0.06,0,0.39,0.09,0.58c-0.06,0-0.06,0.08-0.11,0.03
				c0,0.1,0,0.12,0.05,0.24c0.04-0.02,0.11,0-0.01,0.02c0.09,0.14-0.21,0.3-0.09,0.41l-0.01-0.01c0.08,0.18,0,0.13-0.11,0.77
				c0.02,0,0.11-0.01,0.01,0.05C632.19,161.74,631.84,162.43,631.94,162.67z M633.21,157.05c0,0.02-0.03,0.03-0.03-0.01
				C633.19,157.04,633.2,157.04,633.21,157.05z M632.7,157.05c0,0.01,0,0.01,0,0.03C632.7,157.06,632.7,157.07,632.7,157.05z
				 M632.74,157.16c-0.01-0.01-0.03,0.01-0.06,0.03C632.69,157.18,632.69,157.11,632.74,157.16z"
                />
                <path
                  className="st10"
                  d="M629.22,177.82c0.01,0.02-0.12-0.01-0.06-0.11c-0.07-0.07-0.11-0.09-0.2,0.08l0.04-0.19l-0.03,0.07
				c-0.01-0.01-0.02-0.02,0-0.04c-0.04,0.04-0.01,0.03-0.04,0.07c-0.01-0.08-0.1,0-0.06-0.12c-0.03,0.05,0.02,0.05-0.03,0.08
				c0.07-0.14-0.12,0.03-0.02-0.14c-0.11,0.08-0.05-0.13-0.21,0c0.03-0.03-0.03,0.01-0.09-0.13c-0.04,0.08-0.07,0.05-0.11,0.06
				c0.02-0.05,0.03-0.07,0.05-0.09c-0.03,0-0.03-0.01-0.08,0.03l0.03-0.06c-0.04-0.01-0.1,0.05-0.14-0.01
				c-0.02,0.06-0.02,0.18-0.08,0.21c0.01-0.03,0.02-0.09,0.04-0.1c-0.05-0.06,0.01,0.05-0.03-0.16c-0.01,0.02-0.07,0.13-0.05-0.03
				c-0.02,0.03-0.03-0.01-0.06,0.06c-0.01,0,0-0.04,0-0.06c-0.03,0.03,0,0-0.1,0.07l0,0c0,0.16-0.12-0.14-0.45-0.18
				c0.05-0.01-0.07-0.12-0.02-0.02c-0.23-0.19-0.38,0.14-0.48-0.22c-0.03,0.02-0.02,0.05,0,0.06c-0.04-0.05-0.07-0.02-0.12-0.05
				c0,0.07-0.2,0.16-0.1-0.07c-0.05,0.05-0.08-0.05-0.1-0.06l0.03-0.02c-0.02,0.01-0.03,0.01-0.04,0.01l0,0.06
				c-0.01-0.01-0.02-0.01-0.02-0.04c-0.02,0.04,0,0.1-0.04,0.15c-0.01-0.03-0.06,0.02-0.06-0.06c0.05,0.04,0.01-0.08,0.06-0.08
				c-0.07-0.05-0.01-0.09-0.12,0.05c0.01-0.04,0-0.09-0.01-0.08c0.02,0,0,0.08-0.03,0.11c-0.03-0.04-0.04,0.02-0.06,0.01
				c0.02,0.03,0.03,0.03-0.04,0.06c0-0.03,0.03-0.02,0.02-0.04c-0.04,0.07-0.05-0.09-0.1-0.04c-0.01-0.03,0.01-0.08-0.01-0.11
				c-0.04,0.09-0.07-0.12-0.12,0.08l0.08-0.03c-0.08,0.11,0.01,0.15-0.27,0c0.01,0.04,0.04,0.06-0.05,0.05
				c0.01-0.05-0.01-0.07-0.01-0.1c0,0.04-0.05-0.01-0.07,0.09c0.02-0.22-0.21,0.18-0.12-0.08c-0.06,0.04,0.01-0.01-0.16,0.16
				c-0.05-0.16-0.16,0.01-0.21-0.13c-0.24,0.1-0.04,0.09-0.56,0.1c0.01-0.06,0.02-0.03,0.01-0.1c-0.02,0.28-0.13,0.04-0.31,0
				c-0.02,0.02-0.08,0.1-0.12,0.12c0.02-0.07,0.07-0.11,0.1-0.16c-0.02,0-0.08,0.01-0.09,0.06c0.02-0.03,0.03,0.19-0.25-0.09
				c-0.08,0.08-0.17-0.01-0.26,0.01c0.03,0.07-0.06,0.24-0.05,0.01c0.03,0.05-0.01,0.08,0.01,0.13c-0.04-0.02-0.09-0.09-0.13-0.23
				c0.01,0.05-0.01,0.08-0.04,0.06c0.07,0.02-0.11,0.08-0.07-0.02c-0.03,0.03-0.13,0.01-0.15,0.14c0.05-0.29-0.24,0.13-1.23,0.21
				l0.01,0c-0.03-0.05-0.17-0.13-0.08,0.08c-0.13-0.16-0.2,0.13-0.3-0.02c-0.01,0.04-0.04,0.04-0.05,0.08
				c-0.04-0.06,0-0.1-0.18-0.03l0-0.05c0.01,0.11-0.19-0.01-0.36,0.26c-0.03-0.09-0.07,0.02-0.1,0.07c-0.04-0.1,0.03-0.11-0.24,0.09
				c0,0.05-0.06,0.07-0.17,0.06c0.05-0.02,0.08-0.03,0.08-0.02c0.03-0.07-0.29-0.09-0.23,0.11c-0.01,0,0.01,0,0.02-0.03l0,0.01
				c0.01-0.01,0.03-0.01,0.04-0.02c0,0.04,0,0.07-0.04,0.05c0.01,0.05,0.02,0.09,0.02,0.12c-0.06-0.01-0.17-0.17-0.36,0.11
				c-0.23-0.18-0.04-0.1-0.33,0.11c0.01,0.01,0.02,0.01,0.03,0.02c-0.07,0.03-0.22-0.06-0.45,0.26l-0.05-0.06
				c0.18,0.07-0.28,0.04-0.37,0.41c0-0.01,0-0.01,0-0.01c0.04,0.06,0.1,0.13,0.21,0.25l0-0.08l0.04,0.04l-0.03,0.02
				c0.05,0.03,0.03-0.01,0.03-0.05c0.02,0.02,0.04,0.04,0.05,0.04l-0.02-0.12c0.03,0.04,0.03,0.09,0.07,0.09
				c-0.01-0.05-0.07-0.08-0.06-0.12c0.05,0.03,0.06,0.07,0.06,0.02c0.02,0.02,0.02,0.04,0.02,0.06c0-0.04,0.04-0.04,0.02-0.1
				c0.05,0.04,0.05-0.05,0.11,0.04c-0.01-0.03-0.01-0.09,0-0.11c0.04,0.13,0.07-0.03,0.13,0.1c-0.04-0.12,0.06-0.1,0.07-0.18
				c0,0.01,0.01,0.03,0,0.03c0.03,0,0.05,0.02,0.08,0.03c-0.04-0.09-0.02-0.13-0.03-0.19c0.07,0.07,0.04,0.11,0.09,0.19
				c-0.04-0.07,0.01-0.1-0.03-0.15c0.05,0.04,0.03,0.02,0.08,0.08c-0.05-0.11,0.01-0.03-0.01-0.1c0.06,0.04,0.06,0.1,0.08,0.05
				c0.02,0.16,0.17-0.09,0.17-0.32c-0.01,0,0,0.03,0,0.05l0.01-0.03c0.06,0.11-0.04,0.11,0.1,0.09l-0.05-0.01
				c0.16,0.13,0.21-0.27,1.44-0.49c-0.01-0.06,0.13-0.17,0.28-0.06l0.01-0.09l0.04,0.08c0.03-0.13,0.02,0.13,0.17-0.1
				c0.01,0.03-0.01,0.05-0.01,0.07c0.01-0.12,0.06,0.09,0.04-0.07c0.03,0.04,0.08,0.03,0.11,0c0.04,0.08-0.01,0.07,0.21-0.02
				c-0.03-0.02-0.05-0.11-0.03-0.12l0.03,0.08c0-0.05-0.02-0.18,0.01,0.02l0.03-0.04c0.01,0.02,0,0.04,0.01,0.07
				c0-0.05,0.07,0.07,0.08-0.02c-0.21,0.26,0.77-0.36,1.41-0.18c0-0.01-0.02-0.11,0.03-0.01c-0.12-0.18,0.63,0.03,0.55-0.02
				c0.12-0.11,0.24,0,0.36-0.08c-0.01,0.02-0.01,0.04-0.02,0.04c0.02,0.06,0.09,0.01,0.09-0.04c-0.08-0.01,0.2,0.08,0.34-0.04
				c-0.01,0.11,0.07,0.09,0.1,0.04c-0.01,0.03,0.02,0.04,0.01,0.08c0.03,0,0.06-0.02,0.06-0.08c0.05,0.14,0.12-0.1,0.14,0.08
				c0.1-0.05,0.19-0.09,0.27-0.1c0.02,0.12-0.02,0.06,0.32,0.07c0,0.01-0.01,0.02-0.02,0.03c0.01,0,0.03,0.02,0.04-0.03l-0.02,0.01
				c-0.01-0.08,0.08-0.15,0.08-0.18c-0.04,0.14,0.06,0.05,0,0.23c0.01-0.01,0.03-0.03,0.03-0.06c0.08,0.09,0.01,0.02,0.34,0.11
				c-0.02-0.02,0.06,0.03,0.04-0.07c-0.02,0.04,0.13-0.06,0.07,0.02l0.07,0c-0.02-0.05-0.03-0.05,0.03-0.12
				c0.02,0.06,0.07,0.03,0.08,0.16c0.03-0.07,0.08,0,0.12-0.08c-0.01,0.02-0.02,0.07-0.02,0.08l0.03-0.06
				c-0.01,0.09,0.05,0.06,0.04,0.03c-0.02,0.04,0.24-0.02,0.11,0.12c0.08,0,0.18-0.08,0.24-0.03c0.01-0.16,0-0.01,0.08,0.01
				c-0.01,0.01-0.03,0.07-0.02,0.05c0.04,0.03,0.11,0,0.19-0.02c-0.03,0,0.07,0.16,0.12,0.02c0.03,0.1,0.09,0.25,0.37,0.03
				c-0.04,0.03-0.03,0.09-0.03,0.12l0.02-0.05c-0.03,0.15,0.07-0.08,0.06,0c0.02-0.01,0.12-0.03,0.02,0.05
				c0.1,0.01-0.02-0.02,0.11,0.13c0.07-0.2,0-0.01,0.17-0.12c-0.07,0.09-0.03,0-0.06,0.2c0.04-0.05,0.02,0.06,0.08-0.01
				c-0.01,0.02,0.02,0.01,0,0.07c-0.18-0.15,0.38-0.06,0.38,0.1c0.03-0.01,0.08-0.04,0.11-0.05c-0.01,0.04-0.04,0.05-0.06,0.07
				c0-0.02,0.06,0,0.11-0.08C629.25,177.95,629.16,177.92,629.22,177.82z M620.62,177.56c0-0.01-0.01-0.03-0.01-0.03
				C620.61,177.52,620.65,177.52,620.62,177.56z M620.91,177.87c0,0.02,0.02,0.02,0.04,0.04
				C620.93,177.91,620.91,177.93,620.91,177.87z"
                />
                <path
                  className="st10"
                  d="M642.45,197.51c0.05-0.89,0.24-0.59-0.3-2.27c0,0,0.01,0.01,0.02,0.03c-0.07-0.11,0.09,0.18-0.36-0.34
				c0.21,0.09-0.18-0.45,0.1-0.3c-0.04-0.05-0.1-0.12-0.12-0.09c-0.04-0.09-0.03-0.12,0.05-0.06c-0.2-0.29-0.18-0.16-0.34-0.43
				c0.02-0.01,0.06,0.04,0.11,0.05c-0.12-0.11-0.25-0.22-0.38-0.46c0.02,0.01,0.02-0.03,0.06,0.04c-0.26-0.38-0.55-0.56-0.81-0.98
				c0.09,0.03,0.17-0.01-0.21-0.42l0.03,0.11c-0.14-0.18-0.33-0.3-0.43-0.47l0.14,0.11l-0.29-0.33l0.13,0.21
				c-0.18-0.15-0.08,0.09-0.25-0.16c0.05,0.04-0.15-0.18-0.18-0.31l0,0c-0.37-0.34,0.05-0.21-0.68-0.84
				c0.17,0.02-0.11-0.34-0.25-0.37c0.02,0.02,0.02,0,0.07,0.05c-0.25-0.32-0.33-0.47-0.39-0.59l0.06,0.07
				c-0.28-0.44,0.03,0.03-1.08-1.49l0.04,0.08c-0.4-0.4-0.04-0.4-0.95-1.47l0.06,0.04c-0.52-0.79-0.34-0.65-1.33-2.35
				c0.11,0.12,0.15,0.19-0.18-0.34c-0.03,0.06,0,0.07,0.01,0.19c-0.06-0.04-0.15-0.15-0.2-0.26c0.14,0.18,0.08-0.04,0.07-0.15
				c-0.01,0.02-0.04,0-0.05,0.02c-0.03-0.28-0.12,0.01-0.23-0.33c0.06,0,0.13,0.02-0.35-0.73c0.06,0.09,0.11,0.16,0.16,0.25
				c-0.02-0.21-0.04-0.23-0.1-0.36l0,0.01l-0.12-0.32l0.02,0.09c-0.26-0.16-0.24-0.45-0.49-0.72c0.24,0.26,0.08,0.02,0.16,0.02
				c-0.02-0.09-0.05-0.22-0.02-0.19c-0.06-0.09-0.15-0.09-0.23-0.59c0.21,0.77-0.73-1.39-1.18-2.59c0.13,0.08,0.23,0.13-0.28-0.77
				l0.02,0.13c0.04-0.02,0.16,0.01,0.12,0.26l-0.06-0.12c-0.09,0.18,0.2,0.5,0.28,0.75c-0.11-0.02-0.15-0.02,0.11,0.65
				c-0.03-0.03-0.06,0.06-0.08-0.06c0.06,0.34,0.26,0.48,0.24,0.63l-0.02-0.02c0.28,0.94,0.48,0.79,1.09,2.52
				c-0.1,0.03,0-0.11,0.28,0.52c-0.03,0,0.2,0.53,0.3,0.8l-0.02,0c0.11,0.01,0.22,0.27,0.34,0.45c-0.05,0.02-0.09-0.01-0.16-0.1
				c0.03,0.02-0.03,0.04,0.25,0.36c-0.32-0.08,0.12,0.11,0.57,0.84l-0.12-0.1c0.05,0.04,0.22,0.41,0.03,0.12
				c0.27,0.58,0.11,0.25,0.79,1.31l0,0c0.61,0.75-0.01,0.23,1.5,2.39c-0.15-0.14,0.01,0.08-0.14-0.15c0.13,0.23,0.13,0.3,0.35,0.49
				c-0.11-0.04,0.05,0.21,0.14,0.3l-0.07-0.18c0.09,0.11,0.16,0.21,0.22,0.32l-0.11-0.01c0.29,0.47,0.7,0.98,1.56,1.92
				c0.01-0.32,0.24,0.62,0.29,0.42c0.16,0.27,0.31,0.39,0.49,0.66c0.03-0.05,0.74,0.59,0.38,0.59c0.22,0.2,0.28,0.47,0.36,0.61
				l-0.1-0.11c0.06,0.07,0.1,0.13,0.14,0.19l0-0.1c0.04,0.08,0.08,0.14,0.06,0.16c0.2,0.1,0.17-0.1,0.35,0.39
				c-0.15-0.32-0.06,0.02-0.19-0.16c0.14,0.39,0.01,0.17,0.37,0.57c-0.06-0.02-0.04,0.11-0.01,0.16c-0.04-0.11,0.13-0.02,0.28,0.36
				l-0.03-0.14c-0.03,0.02,0.46,0.58,0.04,2.82c0.03-0.04,0.06-0.08,0.08-0.12c-0.08,0.42-0.18,0.33-1.03,1.61
				c0.05-0.11-0.09-0.03,0.06-0.21c-0.08,0.08-0.17,0.13-0.15,0.16c0.02-0.06-0.09,0.1-0.36,0.25c0.04-0.01,0.02-0.01,0.09-0.05
				c-0.02,0.05-0.16,0.04-0.28,0.29c-0.16-0.09-0.37,0.09-0.93,0c0.03-0.03,0.01-0.07-0.08-0.06l0.01,0.05
				c-0.25-0.05-0.57-0.15-0.99-0.35c0.02,0,0.02-0.02,0.05,0.01c-0.58-0.51-0.07,0.05-0.69-0.91l0.04,0.05
				c-0.09,0.33,0.08-1.21-1.47-1.42c0.09-0.05,0.2-0.06,0.29-0.08c-0.19-0.07-0.38,0.11-0.52,0.19l0-0.04
				c-0.37,0.28-0.29,0.36-0.59,0.69l0.05-0.02c-0.26,0.57-0.34,0.05-0.71,1.14c0.02-0.17,0.16-0.47,0.24-0.67
				c-0.09,0.13-0.28,0.45-0.28,0.55c0.02-0.06,0.05-0.14,0.09-0.18c-0.04,0.2-0.13,0.44-0.3,0.69c-0.28-0.39-0.14,1.18-1.94,0.77
				c0.05,0.22-0.11-0.08-0.19,0.04c-0.05-0.06-0.08-0.1-0.11-0.15c0.03-0.01,0.21,0.11,0.13-0.07c0.04,0.18-0.2-0.16-0.14,0.05
				c-0.08-0.17-0.03-0.31,0.02-0.62c-0.04,0.05-0.1-0.08-0.11-0.25l-0.02,0.25c-0.17-0.4,0.05-0.38-0.09-0.81l0.07,0.13
				c-0.22-0.89,0.11-0.8-1.19-2.64l0.06,0c-0.19-0.08-0.04-0.12-0.71-0.4l0.14,0.02c-0.54-0.3-0.91-0.22-1.38-0.21
				c0.01-0.12-0.48-0.15-0.72-0.08c-0.45,0.05-1.02,0.71-4.69,1.78l0.07-0.02c-0.09-0.35-0.11-0.53-0.2-0.99l0.07,0.09
				c-0.3-0.83-0.19-0.37-0.34-2c0.02,0.01,0.05,0.02,0.06,0.09c-0.01-0.22,0.06-0.84-0.09-0.76c0.1-0.01-0.01-0.48-0.03-0.73
				l0.07,0.04c-0.03-0.02-0.05-0.32-0.07-0.95l0.04,0.11c0.04-0.37-0.16-0.24,0.11-0.84c-0.01,0.11-0.06-0.34,0.01-0.25
				c0.13-0.39-0.08,0.01,0.19-0.9c-0.07,0.31-0.12,0.56-0.12,0.54c0.06,0.01,0.13-0.24,0.17-0.02c0.02-0.21-0.05,0,0.07-0.84l0,0.03
				c0.06-0.34,0.07-0.18,0.23-0.75c-0.07-0.01-0.01-0.17-0.11-0.03c0.02-0.03-0.01,0.05-0.03,0.16l0-0.01
				c-0.02,0.08-0.05,0.19-0.08,0.31c0.09-0.87,0.23-0.6,0.72-2.32c-0.02,0.06-0.04,0.14-0.03,0.18c0.02,0,0.08-0.61,0.34-0.88
				l0,0.06c0.29-0.36-0.03-0.31,0.92-1.86c-0.04,0.04-0.06,0.07-0.08,0.07c0.34-0.22,1.14-2.09,3.12-3.8l-0.08,0.01
				c-0.48,0.2,2.32-1.43,0.81-1.19l0.2-0.16c-0.33,0.09-0.76,0.85-0.88,0.68c-0.02,0.12-0.33,0.07-0.95,0.99
				c0.08-0.27-0.36,0.37-0.42,0.22c-0.05,0.21-0.42,0.54-0.58,0.84c0-0.02,0.01-0.06,0.04-0.08c-0.17-0.15-0.38,0.97-0.56,0.54
				c0.07,0.04-0.17,0.37-0.07,0.35c-0.15,0.11-0.11,0.11-0.23,0.13c0.17-0.04-0.46,0.85-0.36,0.4c-0.11,0.66-0.11-0.01-0.84,1.78
				c0.13,0.04,0.16,0.01,0,0.27c-0.47,0.02-0.13-0.2-1,2.11l-0.06-0.13c-0.14,1.21-0.96,2.08-0.97,5.82l-0.02-0.08
				c-0.03,0.32,0.15,0.36,0.09,0.65c0.13-0.49-0.01-0.07,0.96,4.38c0.04-0.42-0.07,0.57,1.27,0.45c-0.01,0.02-0.05,0.03-0.11,0.04
				c0.53-0.08,0.14,0.07,1.11-0.5l-0.01,0.08c2.17-2.32,2.65-3.7,7.93,2.26l-0.06-0.02c1.05-0.94,0.02,0.04,2.62-3.19
				c-0.02,0.04-0.04,0.09-0.06,0.13c0.69-0.1,0.13-0.09,0.94,1.26c-0.04-0.03-0.06-0.02-0.11-0.09c0.87,1.02-0.19,0.36,3.49,1.12
				c-0.04,0.07,0.17-0.06,0.13,0.02c0.22-0.11,0.36-0.28,0.3-0.3C641.87,199.33,641.91,199.57,642.45,197.51z M642.14,196.59
				c-0.01,0.15-0.02,0.25-0.03,0.33c0-0.14-0.06-0.3-0.06-0.39C642.11,196.68,642.1,196.67,642.14,196.59z M642,196.63
				c-0.01,0.07,0.15,0.11,0.07,0.47C642.04,196.94,642.04,196.79,642,196.63z M623.64,189.63c0.03,0.07,0.04,0.06-0.03,0.16
				C623.62,189.75,623.63,189.79,623.64,189.63z M623.07,190.02c0,0.03-0.01,0.07-0.02,0.13
				C623.06,190.09,623.05,190.15,623.07,190.02z M622.94,190.69c0.01,0.06,0.01-0.31,0.09-0.17
				C623.02,190.49,622.98,190.61,622.94,190.69z"
                />
                <path
                  className="st10"
                  d="M629.88,182.28c0.03-0.03,0.12,0,0.12,0.03l-0.08-0.01c0.05,0.02,0.17,0.06-0.03,0.01l0.02,0.05
				c-0.02,0-0.03-0.02-0.06-0.02c0.05,0.03-0.1,0.06-0.02,0.11c-0.05,0.04-0.04,0.4,0.05,0.55c-0.21-0.02-0.11,0.62-0.36,1.34
				c0.02,0,0.11,0,0,0.04c0.29-0.2-0.18,0.42,0.01,1.23c-0.02-0.01-0.04-0.01-0.04-0.03c0.01,0.03,0,0.14,0.09,0.57
				c-0.11,0-0.09,0.1-0.04,0.14c-0.03-0.01-0.03,0.03-0.08,0.02c0,0.04,0.03,0.08,0.08,0.07c-0.13,0.08,0.11,0.15-0.06,0.2
				c0.07,0.19,0.1,0.08,0.14,0.77c-0.01,0-0.02-0.01-0.03-0.02c0.01,0.02-0.01,0.04,0.04,0.04l-0.02-0.02
				c0.07-0.03,0.16,0.06,0.2,0.06c-0.15-0.02-0.03,0.09-0.22,0.06c0.01,0.01,0.04,0.02,0.07,0.02c-0.07,0.15-0.01,0.03-0.02,0.47
				c0.01-0.03,0,0.09,0.08,0.02c0,0.04,0.08,0.08,0.05,0.12l-0.04-0.03l0.03,0.08l0.03-0.05c0.07,0.08,0.15-0.04-0.02,0.2
				c0.08,0.01,0.04,0.1,0.12,0.12c-0.02-0.01-0.07,0-0.08,0l0.07,0.01c-0.04,0.05,0.02,0.04-0.03,0.21l-0.02-0.03l-0.04,0.03l0.05,0
				c-0.1,0,0.14,0.17,0.11,0.41c-0.01-0.01-0.08,0-0.06,0c-0.01,0.07,0.06,0.13,0.12,0.22c-0.02-0.04-0.1,0.16,0.04,0.15
				c-0.05,0.11-0.22,0.15,0.18,0.43c-0.05-0.03-0.09,0.02-0.12,0.05c0.02-0.01,0.04-0.01,0.05-0.01c-0.14,0.06,0.1,0.02,0.03,0.07
				c0.01,0.01,0.11,0.11-0.03,0.06c0.05,0.12,0.01-0.05-0.04,0.2c0.21-0.05,0.01-0.01,0.2,0.1c-0.11-0.02-0.03-0.03-0.19,0.07
				c0.07,0.02-0.03,0.06,0.06,0.08c-0.02,0.01,0.01,0.03-0.06,0.04c0.14-0.03,0.26,0.29,0.3,0.42c-0.15,0.02,0.12,0.09-0.1,0.07
				c0.03,0.03,0.08,0.05,0.12,0.08c-0.04,0.02-0.07,0-0.1-0.01c0.07,0,0.17,0.13,0.08-0.12l0.11-0.01
				c-0.15-0.02-0.02-0.04-0.02-0.15c0.02,0,0.07-0.03,0.06,0c0.01-0.1,0-0.17-0.21-0.15l0.17-0.09l-0.08,0.01
				c0-0.01,0-0.03,0.03-0.03c-0.06-0.02-0.03,0.01-0.08,0.01c0.05-0.06-0.06-0.11,0.05-0.15c-0.06,0-0.03,0.05-0.08,0.02
				c0.15-0.02-0.1-0.1,0.11-0.12c-0.14-0.06,0.09-0.14-0.12-0.22c0.04,0-0.02-0.02,0.06-0.18c-0.09,0.01-0.09-0.05-0.12-0.08
				c0.05-0.01,0.08-0.01,0.11,0.01c-0.01-0.03,0-0.04-0.07-0.07l0.07-0.01c-0.01-0.05-0.1-0.08-0.07-0.15
				c-0.06,0.01-0.16,0.09-0.22,0.03c0.03-0.01,0.09-0.02,0.11-0.01c-0.01-0.01,0.02-0.1,0.07-0.04c0-0.03,0.03-0.07,0.06-0.08
				c-0.03,0-0.15-0.01,0-0.07c-0.04-0.01-0.01-0.04-0.08-0.03c0-0.01,0.03-0.02,0.05-0.03c-0.04-0.01,0,0-0.11-0.07l0,0
				c-0.14-0.02,0.03-0.1-0.06-0.53c0.05,0.03,0.04-0.02,0.04,0.03c0.01-0.05,0.05-0.07-0.02-0.11c0.03,0.06,0.08-0.14,0.01-0.03
				c0.11-0.17-0.21-0.44-0.01-0.56l-0.03,0c0.03-0.03,0.02-0.09,0.07-0.08c-0.03-0.03-0.05-0.01-0.06,0.02
				c0.04-0.06-0.01-0.09,0.01-0.16c-0.05,0.03-0.22-0.19,0.04-0.14c-0.06-0.05,0.03-0.11,0.04-0.14l0.03,0.03
				c-0.02-0.02-0.02-0.03-0.02-0.05l-0.06,0.02c0.01-0.02,0.01-0.03,0.03-0.03c-0.04-0.01-0.1,0.02-0.15-0.01
				c0.03-0.03-0.04-0.07,0.04-0.08c-0.02,0.1,0.3-0.01,0.02-0.09c0.04,0,0.06-0.01,0.07-0.02c-0.02,0.01-0.09,0-0.13-0.09
				c-0.03,0.03-0.02,0.05-0.07-0.03c0.03-0.01,0.03,0.03,0.04,0.02c-0.08-0.03,0.07-0.09,0.02-0.13c0.03-0.03,0.08-0.01,0.1-0.03
				c-0.1-0.03,0.12-0.1-0.1-0.14l0.05,0.09L630,187c0,0.03,0.04,0.06,0.03,0.08c-0.05-0.03-0.03-0.02-0.08-0.01
				c-0.06-0.22,0.26,0.03,0.11-0.34c-0.04,0.02-0.05,0.06-0.06-0.05c0.05,0,0.07-0.02,0.1-0.03c-0.04,0,0-0.07-0.1-0.07
				c0.07-0.07,0.13-0.04-0.07-0.19c0.05,0.02,0.09-0.01,0.13,0.03c-0.04-0.07,0.01,0.02-0.18-0.19c0.11-0.06,0.11-0.07,0.09-0.43
				c0.01,0,0.02,0,0.02,0.01c-0.11-0.2,0.18,0.03,0.02-0.37c0,0,0.01,0,0.02,0c-0.08-0.04-0.11-0.13-0.12-0.19
				c0.14,0.02,0.09,0.03,0.12-0.08c0.27,0.19-0.28-0.33,0.02-0.28c-0.02-0.03-0.09-0.11-0.1-0.16c0.07,0.04,0.1,0.1,0.15,0.14
				c0-0.03,0-0.1-0.05-0.12c0,0.04,0,0.05-0.09-0.11c0.19,0.03,0.06-0.15,0.21-0.18c-0.06-0.11,0.05-0.2,0.05-0.32
				c-0.04,0.05-0.2-0.12-0.04-0.06c-0.02-0.01-0.04-0.03-0.07-0.04c0.04-0.04,0.11-0.07,0.24-0.08c-0.06,0-0.08-0.03-0.05-0.06
				c-0.05,0.08-0.05-0.15,0.03-0.08c-0.02-0.05,0.02-0.16-0.09-0.22c0.02-0.01,0.06-0.01,0.07,0.01c0.12-0.63-0.04-0.4,0.29-1.41
				c0.01,0.01-0.13-0.02-0.05-0.1l0,0.01c0.1,0,0.08-0.05,0.1-0.09l-0.13-0.04c0.21-0.06,0.01-0.29,0.19-0.31
				c-0.03-0.03-0.02-0.07-0.04-0.1c0.08,0,0.08,0.05,0.13-0.17l0.04,0.03c-0.1-0.09,0.11-0.16,0.02-0.53
				c0.09,0.03,0.04-0.08,0.01-0.14c0.16,0.08-0.03-0.01,0.17-0.51c-0.02,0.06-0.04,0.1-0.05,0.1c0.05,0.08,0.27-0.16,0.11-0.3
				c0.01,0,0,0.01,0.01,0.04l-0.01,0c0,0.02-0.01,0.04-0.02,0.06c-0.03-0.03-0.05-0.06,0-0.07c-0.04-0.03-0.08-0.06-0.09-0.07
				c0-0.07,0.24-0.22,0.42-0.81c-0.02,0.01-0.02,0.01-0.04,0c0.06-0.1,0.19-0.07,0.25-0.6l0.07,0.02c-0.19,0.04,0.23-0.22,0.15-0.67
				c0,0.01,0,0.01,0,0.01c-0.07-0.03-0.16-0.06-0.3-0.1l0.04,0.08l-0.06-0.01l0.02-0.05c-0.06,0-0.03,0.04-0.01,0.07
				c-0.03-0.01-0.05-0.01-0.07-0.01l0.07,0.1c-0.04-0.01-0.07-0.06-0.11-0.04c0.03,0.05,0.1,0.02,0.11,0.08
				c-0.06,0-0.09-0.03-0.07,0.02c-0.03-0.01-0.04-0.02-0.05-0.04c0.02,0.04-0.02,0.08,0.03,0.12c-0.07,0-0.03,0.09-0.13,0.04
				c0.02,0.02,0.05,0.08,0.05,0.11c-0.1-0.1-0.05,0.09-0.17,0.01c0.09,0.08-0.01,0.14,0.02,0.22c-0.01-0.01-0.02-0.02-0.01-0.02
				c-0.02,0.03-0.06,0.02-0.09,0.03c0.09,0.05,0.08,0.11,0.13,0.16c-0.1,0-0.09-0.07-0.18-0.1c0.07,0.03,0.04,0.1,0.11,0.12
				c-0.07,0.01-0.04,0.01-0.12,0c0.11,0.06,0.01,0.04,0.06,0.09c-0.07,0.02-0.11-0.04-0.1,0.03c-0.1-0.13-0.11,0.21,0.03,0.45
				c0.01-0.01-0.01-0.02-0.03-0.04l0.01,0.03c-0.07-0.15-0.17,0.03-0.09-0.02c-0.25-0.24-0.2,0.31-0.3,0.77
				c0.08-0.35-0.17,0-0.49,1.54l-0.1,0c0.12,0.13-0.09-0.11-0.02,0.26c-0.03-0.01-0.03-0.03-0.05-0.05
				c0.11,0.08-0.12,0.01,0.03,0.09c-0.05,0.02-0.07,0.08-0.06,0.14l-0.05-0.02C629.89,182.07,629.9,182.18,629.88,182.28z
				 M631.17,180.1c0.01,0-0.04,0.05-0.03-0.02C631.15,180.09,631.16,180.1,631.17,180.1z M630.69,180.13
				c-0.01-0.01-0.04,0.01-0.06,0.02C630.64,180.13,630.65,180.07,630.69,180.13z"
                />
                <path
                  className="st10"
                  d="M685.61,134.15c0.02,0-0.07,0.11,0.03,0.18c-0.09-0.17,0.1,0.15,0.13-0.21l0.07,0.08l-0.03-0.11
				c0.03,0,0.08,0.03,0.08,0.07c0.06-0.01,0.06,0.02,0.08-0.1c0.01,0.02,0.06,0.04,0.03,0.05c0.07-0.04,0.19-0.12,0.03-0.31
				l0.19,0.06l-0.06-0.05c0.01-0.01,0.03-0.02,0.05,0c-0.02-0.07-0.03-0.01-0.06-0.06c0.09-0.01,0.07-0.15,0.17-0.09
				c-0.04-0.05-0.07,0.02-0.07-0.05c0.11,0.11,0.02-0.17,0.17-0.02c-0.08-0.15,0.18-0.01,0.13-0.31c0.03,0.03-0.01-0.03,0.2-0.12
				c-0.07-0.06-0.01-0.11,0-0.17c0.04,0.03,0.06,0.05,0.06,0.09c0-0.01,0.04-0.01,0.02-0.12l0.05,0.05
				c0.03-0.06,0.01-0.16,0.09-0.21c-0.11-0.13-0.25-0.09,0.1-0.1c-0.07-0.06,0.14-0.03,0.07-0.31l0,0c-0.04-0.12,0.24-0.2,0.4-0.6
				c0.01,0.07,0.04,0,0,0.06c0.05-0.05,0.09-0.04,0.07-0.14c0.04,0.05,0.09-0.01,0.09-0.04l-0.06,0.02
				c0.23-0.02,0.13-0.61,0.42-0.63l-0.02-0.02c0.04-0.01,0.08-0.09,0.11-0.05c0-0.06-0.03-0.04-0.06-0.02
				c0.08-0.05,0.06-0.11,0.13-0.17c-0.06,0.01-0.07-0.35,0.13-0.14c-0.02-0.09,0.1-0.11,0.13-0.15l0,0.05
				c0-0.03,0.01-0.05,0.02-0.06l-0.06-0.01c0.02-0.02,0.03-0.03,0.05-0.02c-0.02-0.04-0.1-0.03-0.12-0.09
				c0.04-0.01,0.01-0.1,0.09-0.08c-0.08,0.11,0.26,0.15,0.08-0.1c0.03,0.03,0.09,0.02,0.08,0c0,0.03-0.1-0.01-0.06-0.16
				c-0.04,0.02-0.05,0.05-0.04-0.08c0.03,0.01,0,0.05,0.02,0.04c-0.1-0.14,0.44-0.11,0.21-0.35l-0.02,0.14l-0.04-0.11
				c-0.02,0.04,0,0.09-0.02,0.11c-0.03-0.06-0.02-0.05-0.07-0.05c0.07-0.27,0.23,0.14,0.29-0.37c-0.05,0.01-0.08,0.05-0.02-0.09
				c0.04,0.02,0.07,0.01,0.11,0.01c-0.03-0.01,0.04-0.08-0.05-0.13c0.11-0.06,0.14,0,0.05-0.26c0.04,0.05,0.09,0.02,0.1,0.1
				c0-0.1,0,0.03-0.06-0.3c0.13-0.03,0.15-0.06,0.29-0.5c0.01,0.01,0.02,0.01,0.01,0.02c0.02-0.23,0.02,0.01,0.17-0.28l0,0.02
				c0.07-0.03-0.01-0.14,0.03-0.21c0,0,0.01,0.01,0.02,0.01c-0.05-0.08-0.05-0.19-0.04-0.28c0.13,0.07,0.08,0.06,0.14-0.08
				c0.19,0.34-0.14-0.45,0.13-0.36c-0.01-0.05-0.04-0.16-0.04-0.24c0.06,0.06,0.06,0.15,0.09,0.22c0.01-0.04,0.04-0.13,0-0.17
				c-0.02,0.05-0.02,0.07-0.04-0.16c0.18,0.07,0.11-0.18,0.27-0.19c-0.03-0.16,0.11-0.26,0.15-0.42c-0.05,0.04-0.2-0.19,0.02-0.09
				c-0.06,0.03-0.06-0.05-0.1-0.06c0.05-0.04,0.13-0.08,0.26-0.08c-0.06,0-0.07-0.05-0.03-0.09l-0.09,0.04
				c-0.04-0.13,0.15-0.06,0.11-0.2l0.04,0.05c-0.01-0.07,0.07-0.21-0.04-0.31c0.02-0.01,0.06-0.01,0.07,0.02
				c0.08-0.08,0.03-0.18,0.1-0.61l0.03,0.04c0.11-0.61,0.03-0.17,0.34-1.36c-0.09-0.14,0.03-0.09,0.06-0.87
				c0.11-0.02,0.07,0.03,0.13-0.26l0.05,0.03c-0.13-0.22-0.11-0.05-0.03-0.55c0.02,0,0.04,0.01,0.05,0.03
				c0.01-0.06,0.1-0.21-0.05-0.21c0.09,0.01,0.02-0.12-0.01-0.19l0.07,0.02c-0.02,0-0.05-0.06-0.1-0.26l0.04,0.03
				c0.02-0.1-0.17-0.08,0.04-0.22c-0.01-0.01,0.01,0,0.03-0.3c-0.01,0.08-0.02,0.15-0.02,0.14c0.11,0.08,0.21-0.25,0.03-0.43
				c0.01-0.01,0,0.02,0.02,0.04l-0.01,0c0,0.02,0,0.05,0,0.08c-0.03-0.03-0.06-0.06-0.02-0.09c-0.04-0.02-0.09-0.05-0.11-0.06
				c-0.02-0.09,0.22-0.38,0.16-1.22c-0.01,0.01-0.02,0.02-0.03,0.02c0.01-0.18,0.16-0.14,0-0.88l0.08-0.01
				c-0.24-0.11,0.18-0.42-0.15-0.89c0.01,0,0.01,0,0.02,0c-0.13-0.04-0.01-0.02-0.34,0.17l0-0.06c-0.05,0.04-0.01,0.06,0.03,0.08
				c-0.03,0.01-0.05,0.02-0.06,0.03l0.11,0.07c-0.25,0.01,0.12,0.09-0.09,0.09c0.04,0.04,0.02,0.1,0.08,0.12
				c-0.07,0.04,0.02,0.13-0.09,0.12c0.03,0.01,0.09,0.07,0.09,0.1c-0.13-0.06-0.01,0.13-0.15,0.11c0.12,0.05,0.05,0.17,0.12,0.25
				c-0.01,0-0.03-0.01-0.02-0.02c-0.01,0.05-0.04,0.06-0.07,0.09c0.1,0.01,0.12,0.08,0.18,0.12c-0.1,0.05-0.11-0.04-0.21-0.02
				c0.08,0,0.08,0.1,0.14,0.08c-0.06,0.04-0.03,0.04-0.11,0.06c0.12,0.01,0.02,0.04,0.09,0.08c-0.06,0.02-0.02,0.14-0.16,0.05
				c0.19,0.03-0.11,0.24,0.27,0.56c0-0.02-0.02-0.02-0.04-0.03l0.02,0.03c-0.59-0.14,0,0-0.17,1.09c0.05-0.28-0.17,0.33-0.15,0.93
				l-0.02-0.02c-0.04,0.07,0.14,0.11,0.06,0.17l-0.01-0.02c-0.05,0.12-0.16,0.03-0.17,1.03l-0.1,0.02c0.13,0.12-0.11-0.05-0.01,0.34
				c-0.03-0.01-0.04-0.04-0.05-0.06c0.12,0.09-0.12,0.01,0.03,0.11c-0.05,0.03-0.07,0.11-0.07,0.18l-0.05-0.02
				c-0.04,0.11-0.03,0.25-0.05,0.37c0.03-0.04,0.12-0.01,0.12,0.03l-0.08,0c0.04,0.03,0.16,0.07-0.03,0.01l0.02,0.06
				c-0.02,0-0.03-0.02-0.06-0.03c0.04,0.03-0.1,0.08-0.02,0.14c-0.06,0.08-0.04,0.43-0.01,0.7c-0.06-0.01-0.08,0.08-0.11,0.02
				c-0.02,0.11-0.02,0.14-0.01,0.3c0.05-0.02,0.1,0.02-0.01,0.02c0.05,0.18-0.27,0.31-0.18,0.47l0-0.01
				c0.02,0.22-0.03,0.13-0.34,0.88c0.03,0.01,0.1,0.02-0.01,0.06c0.34-0.15-0.29,0.49-0.46,1.53c-0.01-0.02-0.04-0.03-0.03-0.05
				c-0.07,0.02-0.06,0.14-0.02,0.16c0.04-0.06-0.17,0.21-0.17,0.56c-0.1-0.05-0.13,0.09-0.1,0.15c-0.02-0.03-0.05,0.02-0.09-0.01
				c-0.02,0.05-0.02,0.1,0.04,0.12c-0.17,0.04,0.02,0.23-0.16,0.21c-0.01,0.17-0.03,0.33-0.07,0.47c-0.16-0.21,0.05-0.35-0.27,0.49
				c-0.01,0-0.01-0.02-0.02-0.04c-0.01,0.02-0.04,0.04,0.01,0.06l0-0.03c0.08,0,0.1,0.15,0.13,0.18c-0.11-0.1-0.09,0.09-0.22-0.05
				c0,0.02,0.01,0.05,0.05,0.06c-0.17,0.15,0-0.01-0.33,0.6l0.05,0c-0.03,0.05,0,0.13-0.05,0.16l-0.01-0.06l-0.04,0.11l0.06-0.04
				c0.01,0.13,0.15,0.05-0.17,0.21c0.05,0.06-0.05,0.13,0.01,0.2c-0.01-0.02-0.05-0.04-0.06-0.05l0.04,0.05
				c-0.09,0.06-0.04,0.08-0.18,0.21l0.01-0.04l-0.05,0.01l0.03,0.03c-0.04,0.02-0.12,0.27-0.39,0.76c0.03-0.07-0.2,0.04-0.1,0.18
				c-0.14,0.08-0.26-0.02-0.26,0.56c0-0.07-0.08-0.05-0.12-0.05c0.02,0.01,0.03,0.02,0.04,0.03c-0.15-0.04,0.05,0.08-0.04,0.09
				c0-0.01-0.02,0.2-0.07,0.03c-0.06,0.14,0.01-0.01-0.21,0.16c0.18,0.12,0.02,0,0.03,0.26c-0.04-0.11-0.01-0.05-0.18-0.09
				c0.03,0.07-0.08,0.03-0.04,0.13c-0.01-0.01-0.02,0-0.04,0c0.03,0.02-0.03,0.15-0.27,0.6c-0.1-0.12-0.02,0.18-0.12-0.02
				c-0.01,0.05,0,0.11-0.01,0.17C685.62,134.22,685.63,134.18,685.61,134.15z M691.34,120.42c0,0.03-0.02,0.04-0.03-0.01
				C691.32,120.42,691.33,120.42,691.34,120.42z M690.84,120.54c0-0.01,0,0,0-0.03C690.84,120.51,690.84,120.52,690.84,120.54z
				 M690.89,120.63c-0.02-0.01-0.03,0.02-0.05,0.04C690.84,120.68,690.81,120.58,690.89,120.63z"
                />
                <path
                  className="st10"
                  d="M682.79,132.41c0.02,0,0.07,0,0.06,0.01c0.04-0.07,0.03-0.08-0.16-0.1l0.19-0.02l-0.08,0
				c0,0,0.01-0.01,0.04-0.01c-0.05-0.02-0.03,0-0.08-0.01c0.07-0.02-0.03-0.06,0.09-0.06c-0.06-0.01-0.04,0.02-0.09,0
				c0.15,0.01-0.07-0.06,0.13-0.04c-0.13-0.03,0.02,0.02-0.03-0.06c0.07-0.02,0.09,0.06-0.04-0.06c0.04,0.01-0.01-0.02,0.1-0.08
				c-0.09,0-0.08-0.03-0.09-0.05c0.05,0,0.08,0,0.1,0.01c-0.01-0.02,0-0.02-0.06-0.04l0.07,0c0-0.02-0.08-0.05-0.04-0.08
				c-0.06,0-0.18,0.04-0.22,0.01c0.05-0.01,0.04,0.02,0.25-0.06c-0.03,0-0.15-0.01,0.01-0.03c-0.03,0,0-0.02-0.07-0.02
				c0-0.01,0.03-0.01,0.05-0.02c-0.04-0.01,0-0.01-0.1-0.04v0c-0.1-0.05,0.13-0.26,0.11-0.65c-0.03-0.01-0.05,0-0.06,0.02
				c0.04-0.04,0-0.05,0.02-0.08c-0.02,0.01-0.05,0.01-0.05,0.01c0.03-0.05-0.06-0.01-0.08-0.04c0.04-0.03,0.11-0.01,0.06-0.04
				c0.03-0.02,0.08-0.02,0.1-0.02c-0.06-0.02,0.03-0.06,0.03-0.08l0.03,0.01c-0.02-0.01-0.02-0.01-0.02-0.02l-0.06,0.02
				c0.01-0.01,0.01-0.02,0.03-0.02c-0.04,0-0.1,0.03-0.15,0.02c0.03-0.02-0.04-0.03,0.04-0.05c-0.02,0.04,0.08-0.02,0.1,0.01
				c0.03-0.07,0.08-0.03-0.08-0.06c0.04,0,0.08-0.03,0.07-0.03c0,0.01-0.07,0.02-0.11,0.02c0.03-0.03-0.03-0.02-0.03-0.03
				c-0.03,0.02-0.02,0.03-0.07,0c0.03-0.01,0.03,0.01,0.04,0c-0.08,0,0.07-0.06,0.01-0.07c0.03-0.02,0.08-0.02,0.1-0.03
				c-0.07-0.03,0.05-0.08-0.11-0.05l0.05,0.04c-0.13,0.04-0.17-0.12-0.04-0.21c-0.04,0.01-0.01-0.03-0.1-0.01
				c0.21-0.06-0.22-0.06,0.05-0.09c-0.05-0.02,0.01,0-0.19-0.04c0.14-0.08-0.04-0.09,0.07-0.16c-0.02-0.02-0.05-0.04-0.06-0.06
				c-0.05,0.06,0.12-0.15-0.16-0.23c0.06-0.01,0.04,0,0.09-0.03c-0.01-0.01-0.1,0.04-0.12,0.02c0.03,0,0.14-0.1,0.16-0.11
				c-0.02-0.04-0.3-0.02-0.09-0.1c-0.03,0-0.11-0.01-0.13-0.03c0.07-0.01,0.12,0,0.17,0c-0.07-0.11-0.2-0.29-0.1-0.39
				c-0.05,0.04-0.08,0.01-0.13,0.06c-0.01-0.02,0.04-0.06,0.21-0.17c-0.05,0.03-0.08,0.02-0.06,0c0,0.04-0.1-0.04,0.01-0.05
				c-0.03-0.01-0.03-0.08-0.16-0.04c0.09-0.01,0.03-0.18-0.22-0.26c0.07-0.08,0.26-0.18-0.07-0.21c0.08-0.09-0.02-0.13,0.01-0.21
				c-0.01,0.02-0.07,0.04-0.09,0.04c0.01-0.02-0.08,0.01,0-0.04l0,0.01c0.08-0.06,0.04-0.07,0.03-0.09l-0.11,0.07
				c0.13-0.15-0.14-0.08-0.01-0.2c-0.03,0.01-0.04-0.01-0.08,0c0.01-0.02,0.11-0.04,0.01-0.13c0.06-0.04,0.08-0.05-0.15,0.05
				c0.02-0.04,0.14-0.07,0-0.17c0.01-0.01,0.04-0.03,0.05-0.02c-0.01-0.02,0.01-0.11-0.1-0.01c0.07-0.06-0.02-0.04-0.07-0.04
				l0.06-0.04c-0.08,0.04-0.1,0.05-0.14,0.05c0-0.01,0.04-0.04,0.03-0.02c-0.03-0.12-0.11,0.09-0.05-0.11
				c-0.04,0.03-0.07-0.02-0.07-0.09c0.02,0.02,0.03,0.05,0.03,0.05c0.01,0.02,0.05-0.26-0.11-0.12c0-0.01,0,0,0.02,0l-0.01,0
				l0.02,0.02c-0.03,0.02-0.06,0.03-0.04-0.01c-0.04,0.02-0.08,0.05-0.1,0.06c-0.01-0.03,0.13-0.21-0.12-0.2
				c0.01-0.05,0.07-0.05,0.04-0.06c0.11-0.16,0.02-0.02-0.15-0.13c0,0.01-0.01,0.02-0.02,0.03c-0.01-0.03-0.01-0.22-0.24-0.21
				l0.05-0.06c-0.11,0.13-0.09-0.07-0.15-0.04c0-0.01-0.02-0.05-0.02-0.07l-0.08,0.02c0.07-0.13,0.13-0.33-0.29,0.25l0.07-0.04
				l-0.03,0.05l-0.02-0.01c-0.03,0.05,0.01,0.02,0.04,0c-0.02,0.02-0.03,0.04-0.03,0.05l0.09-0.07c-0.03,0.04-0.07,0.06-0.07,0.09
				c0.04-0.03,0.06-0.08,0.1-0.1c-0.03,0.05-0.06,0.08-0.02,0.05c-0.02,0.02-0.03,0.04-0.05,0.04c0.03-0.02,0.04,0.01,0.08-0.03
				c-0.03,0.06,0.04,0.02-0.03,0.1c0.02-0.02,0.08-0.05,0.09-0.04c-0.11,0.08,0.03,0.04-0.07,0.14c0.09-0.08,0.08,0,0.15-0.03
				c-0.01,0.01-0.02,0.02-0.02,0.01c0.01,0.02-0.02,0.05-0.02,0.07c0.07-0.07,0.1-0.07,0.15-0.1c-0.05,0.08-0.09,0.07-0.15,0.14
				c0.05-0.06,0.08-0.03,0.12-0.08c-0.03,0.05-0.01,0.03-0.06,0.09c0.09-0.09,0.02,0,0.09-0.05c-0.03,0.06-0.08,0.09-0.03,0.08
				c-0.11,0.13,0.03-0.01,0.19,0.04c-0.08,0.07,0.14-0.09,0.05-0.03l0.03-0.01c-0.06,0.07-0.03,0.01-0.08,0.05
				c0.02,0,0.16,0,0.01,0.05l0-0.04c0,0.09-0.05,0.18,0.25,0.28c-0.02,0.01-0.05,0.03-0.06,0.05c0.07,0.01,0.06,0.09,0.05,0.16
				l0.07-0.03c-0.11,0.1,0.1-0.06-0.02,0.04c0.1,0.1,0.18-0.03,0.23,0.26c0.06-0.05,0.18,0.05,0.08,0.15l0.08-0.03l-0.07,0.06
				c0.05,0,0.13-0.11,0.02,0.06l0.09,0.01c-0.03,0.02-0.04,0.01-0.06,0.02c0.11-0.04-0.07,0.08,0.06,0
				c-0.03,0.04-0.02,0.06,0.01,0.07c-0.04,0.04-0.08,0.02,0.04,0.12c0.01-0.03,0.09-0.07,0.11-0.06l-0.07,0.05
				c0.05-0.02,0.02-0.03,0.08-0.04c0,0.02-0.07,0.06-0.1,0.06l0.03,0c-0.02,0.01-0.03,0.02-0.06,0.03c0.05-0.02-0.05,0.07,0.03,0.04
				c-0.28-0.05,0.46,0.38,0.37,0.77c0.01,0,0.1-0.05,0.02,0.01c0.12-0.21-0.01,0.23,0.27,0.48c-0.02,0-0.04,0.01-0.04,0
				c-0.05,0.03,0.01,0.05,0.06,0.04c-0.05,0.01-0.06,0.06-0.06,0.07c0.03,0,0.01,0.04,0.07,0.01c-0.02,0.03,0.05,0.04,0.01,0.07
				c0.08-0.01-0.02,0.05,0.08,0.03c-0.11,0.03-0.07,0.07-0.02,0.07c-0.03,0-0.03,0.02-0.08,0.03c0.01,0.02,0.03,0.03,0.09,0.01
				c-0.12,0.07,0.12,0.04-0.04,0.1c0.07,0.04,0.13,0.08,0.17,0.12c-0.09,0.05-0.08-0.01,0.01,0.2c-0.01,0-0.02,0-0.03,0
				c0.01,0.01-0.01,0.02,0.04,0.01l-0.02-0.01c0.07-0.03,0.16,0,0.2-0.01c-0.15,0.02-0.03,0.05-0.21,0.07c0.01,0,0.04,0.01,0.07,0
				c-0.06,0.06-0.02,0-0.01,0.22c0.01-0.02-0.01,0.04,0.07,0c-0.04,0,0.1,0.06,0,0.04l0.02,0.04c0.01-0.02,0.2-0.06,0.08,0.02
				c0.03,0.02,0.09,0.03-0.08,0.05c0.08,0,0.03,0.04,0.11,0.05c-0.02,0-0.07,0.01-0.08,0.01l0.06,0c-0.15-0.01,0.11,0.13-0.1,0.12
				c0.02,0.04,0.13,0.08,0.11,0.13c0.15-0.03,0.01,0,0.01,0.05c-0.01,0-0.07,0-0.06,0c-0.02,0.03,0.04,0.06,0.08,0.1
				c-0.04,0.01-0.05-0.02-0.06,0.07c0.03,0,0.03,0.01,0.08,0c-0.11,0.05-0.17,0.17,0.1,0.21c-0.04-0.02-0.09,0-0.12,0.01l0.06,0
				c-0.15,0.02,0.11,0.02,0.02,0.03c0.02,0.02,0.07,0.06-0.04,0.02c0.03,0.05,0-0.01-0.08,0.09c0.22-0.01,0.01,0,0.17,0.07
				c-0.11-0.02,0-0.02-0.2,0.01c0.06,0.01-0.05,0.02,0.04,0.04c-0.02,0,0,0.02-0.06,0.01c0.02-0.15,0.24,0.22,0.05,0.24
				c0.02,0.02,0.07,0.03,0.09,0.05c-0.04,0-0.06-0.01-0.09-0.02c0.02,0,0.02,0.04,0.11,0.04c-0.02-0.03-0.02-0.09,0.1-0.07
				c-0.02-0.02-0.2-0.02,0.05-0.05L682.79,132.41z M680.93,127.76c0-0.01-0.01-0.01-0.01-0.02
				C680.94,127.73,680.98,127.68,680.93,127.76z"
                />
                <path
                  className="st10"
                  d="M685.26,137.46l0.03,0.06l-0.06,0.01c0.04,0.05,0.06,0,0.08-0.03c0.01,0.03,0.02,0.05,0.03,0.06l0.07-0.11
				c0.02,0.25,0.08-0.12,0.09,0.09c0.03-0.04,0.1-0.02,0.11-0.08c0.04,0.06,0.12-0.02,0.12,0.09c0.01-0.03,0.06-0.09,0.09-0.1
				c-0.05,0.14,0.13,0,0.12,0.14c0.04-0.12,0.16-0.06,0.24-0.13c0,0.01-0.01,0.03-0.02,0.02c0.05,0.01,0.06,0.04,0.09,0.06
				c0-0.1,0.07-0.13,0.1-0.19c0.06,0.09-0.03,0.12,0,0.21c-0.01-0.08,0.09-0.09,0.07-0.15c0.05,0.06,0.04,0.03,0.07,0.11
				c0-0.12,0.04-0.02,0.07-0.1c0.02,0.06,0.14,0.01,0.07,0.15c0.01-0.19,0.25,0.08,0.51-0.33c-0.02,0-0.02,0.02-0.03,0.04l0.03-0.03
				c-0.07,0.61,0,0,1.07,0.01c-0.29,0,0.35,0.12,0.92-0.03l-0.02,0.02c0.08,0.02,0.07-0.16,0.15-0.09l-0.01,0.01
				c0.13,0.02,0.07,0.15,1.01-0.08l0.04,0.09c0.08-0.15-0.02,0.12,0.32-0.08c0,0.03-0.03,0.04-0.04,0.07
				c0.06-0.14,0.05,0.11,0.09-0.06c0.04,0.04,0.13,0.03,0.19,0.01l-0.01,0.05c0.11,0,0.24-0.05,0.36-0.06
				c-0.05-0.02-0.05-0.11-0.01-0.13l0.02,0.08c0.01-0.05,0.02-0.18,0.02,0.03l0.06-0.03c0.01,0.02-0.01,0.04-0.01,0.07
				c0.02-0.05,0.11,0.07,0.13-0.02c0.09,0.03,0.42-0.09,0.65-0.22c0.01,0.06,0.1,0.05,0.06,0.1c0.11-0.02,0.14-0.03,0.27-0.1
				c-0.04-0.04-0.02-0.1,0.02,0c0.15-0.11,0.38,0.15,0.49,0l-0.01,0.01c0.19-0.11,0.13-0.03,0.92-0.04c0-0.03-0.03-0.1,0.05-0.02
				c-0.29-0.24,0.56,0.07,1.53-0.28c-0.01,0.02-0.01,0.05-0.02,0.05c0.05,0.05,0.15-0.02,0.15-0.07c-0.07-0.01,0.27,0.05,0.56-0.13
				c0.01,0.11,0.14,0.07,0.18,0.01c-0.01,0.04,0.04,0.03,0.03,0.08c0.05-0.01,0.1-0.04,0.08-0.09c0.12,0.12,0.18-0.14,0.26,0.03
				c0.15-0.08,0.28-0.15,0.42-0.19c-0.09,0.24-0.32,0.17,0.54-0.05c0,0.01-0.01,0.02-0.02,0.03c0.02-0.01,0.06,0.01,0.05-0.04
				l-0.02,0.02c-0.05-0.07,0.07-0.17,0.07-0.21c-0.02,0.15,0.12,0.02,0.09,0.21c0.01-0.01,0.03-0.04,0.02-0.07
				c0.22,0.05-0.01,0.01,0.66-0.08l-0.03-0.04c0.06-0.01,0.1-0.08,0.15-0.06l-0.04,0.04l0.1-0.04l-0.07-0.02
				c0.1-0.09-0.05-0.15,0.26,0.01c0.01-0.08,0.13-0.04,0.15-0.13c-0.01,0.02,0,0.07,0,0.08l0.01-0.07c0.07,0.04,0.06-0.02,0.27,0.01
				l-0.03,0.02l0.04,0.03l0-0.05c0.03,0.02,0.28-0.07,0.8-0.2c-0.07,0.03,0.16,0.13,0.19-0.04c0.14,0.05,0.16,0.21,0.57-0.19
				c-0.05,0.05,0.02,0.09,0.05,0.12c-0.01-0.02,0-0.04-0.01-0.05c0.07,0.13,0.03-0.09,0.09-0.03c-0.01,0.01,0.15-0.13,0.07,0.03
				c0.14-0.05-0.02,0,0.25,0.04c-0.04-0.21-0.01-0.01,0.16-0.2c-0.04,0.11-0.03,0.04,0.06,0.2c0.03-0.07,0.07,0.04,0.11-0.06
				c0,0.02,0.02,0.02,0.03,0.03c-0.01-0.04,0.12-0.09,0.59-0.25c0,0.15,0.13-0.12,0.07,0.1c0.04-0.03,0.07-0.08,0.11-0.11
				c0.02,0.04-0.01,0.07-0.03,0.1c-0.01-0.01,0.12-0.04,0.09-0.15c-0.04,0.19,0.03-0.18-0.23,0.07l0.01-0.11l-0.05,0.1
				c-0.02-0.02-0.04-0.08-0.01-0.1c-0.04-0.03-0.03-0.05-0.13,0.02c0-0.02-0.02-0.07,0.01-0.06c-0.08-0.01-0.21-0.04-0.22,0.21
				l-0.09-0.17l0,0.08c-0.01,0-0.04,0-0.04-0.03c-0.03,0.06,0.01,0.03,0,0.08c-0.07-0.06-0.15,0.06-0.18-0.06
				c-0.01,0.06,0.06,0.03,0.01,0.08c0-0.16-0.13,0.1-0.13-0.11c-0.04,0.16-0.14-0.11-0.3,0.12c0-0.05-0.01,0.03-0.22-0.06
				c0,0.09-0.07,0.09-0.12,0.12c-0.01-0.05,0-0.08,0.02-0.11c-0.01,0.01-0.03-0.02-0.09,0.07l0-0.07c-0.06,0.02-0.11,0.1-0.2,0.07
				c-0.02,0.16,0.1,0.25-0.14-0.01c0,0.03-0.02,0.15-0.09,0c-0.01,0.04-0.06,0.01-0.04,0.08c-0.02,0-0.03-0.03-0.04-0.05
				c-0.02,0.05,0,0-0.1,0.11l0,0c-0.06,0.11-0.3-0.05-0.69,0.09c0.04-0.05-0.02-0.03,0.05-0.04c-0.07-0.01-0.09-0.05-0.15,0.03
				c0.01-0.07-0.06-0.07-0.09-0.05l0.05,0.04c-0.16-0.17-0.54,0.27-0.72,0.06l0,0.03c-0.03-0.02-0.12-0.01-0.11-0.06
				c-0.04,0.04-0.01,0.05,0.02,0.06c-0.08-0.03-0.12,0.02-0.2,0c0.04,0.03-0.22,0.26-0.18-0.02c-0.06,0.07-0.14-0.02-0.18-0.02
				l0.03-0.03c-0.02,0.02-0.04,0.02-0.06,0.02l0.03,0.06c-0.02-0.01-0.04,0-0.05-0.03c-0.02,0.04,0.03,0.09,0,0.15
				c-0.03-0.03-0.09,0.05-0.11-0.03c0.13,0.01-0.02-0.3-0.12-0.01c0-0.05-0.03-0.08-0.05-0.07c0.02-0.02,0.05,0.08-0.09,0.15
				c0.03,0.02,0.07,0.02-0.04,0.08c-0.01-0.03,0.03-0.03,0.02-0.04c-0.06,0.17-0.32-0.3-0.39,0.02l0.12-0.06l-0.07,0.1
				c0.04,0,0.07-0.05,0.1-0.04c-0.03,0.05-0.03,0.04,0,0.08c-0.26,0.09-0.01-0.27-0.45-0.05c0.04,0.03,0.08,0.04-0.06,0.07
				c0-0.05-0.03-0.06-0.05-0.09c0.01,0.04-0.09,0.01-0.08,0.11c-0.1-0.06-0.07-0.12-0.24,0.1c0.02-0.06-0.03-0.09,0.03-0.13
				c-0.09,0.06,0.02-0.02-0.22,0.21c-0.09-0.1-0.12-0.1-0.56-0.01c0-0.02,0-0.02,0.01-0.02c-0.2,0.09,0-0.02-0.31-0.01l0.01-0.01
				c-0.06-0.05-0.11,0.08-0.19,0.08c0,0,0-0.01,0-0.02c-0.04,0.09-0.14,0.13-0.21,0.16c0-0.14,0.02-0.09-0.13-0.09
				c0.21-0.32-0.33,0.33-0.37,0.04c-0.03,0.03-0.12,0.11-0.19,0.14c0.03-0.08,0.1-0.13,0.15-0.17c-0.04,0-0.13,0.02-0.14,0.07
				c0.05-0.01,0.07-0.01-0.12,0.11c-0.01-0.19-0.2-0.02-0.28-0.16c-0.12,0.09-0.27,0-0.42,0.03c0.07,0.03-0.09,0.23-0.07,0.05
				c-0.01,0.02-0.03,0.05-0.03,0.07c-0.06-0.03-0.12-0.09-0.17-0.21c0.02,0.05-0.02,0.08-0.07,0.07l0.07,0.06
				c-0.1,0.09-0.11-0.12-0.21-0.02l0.03-0.05c-0.05,0.04-0.21,0.02-0.25,0.15c-0.01-0.02-0.03-0.06-0.01-0.07
				c-0.09-0.05-0.17,0.03-0.57,0.12l0.02-0.05c-0.57,0.11-0.16,0.05-1.33,0.11c-0.1,0.13-0.09,0.01-0.81,0.17
				c-0.05-0.1,0.01-0.08-0.26-0.06l0.02-0.05c-0.17,0.17-0.01,0.13-0.5,0.15c0-0.02,0-0.05,0.02-0.05c-0.05,0-0.22-0.05-0.18,0.09
				c-0.01-0.09-0.12,0-0.18,0.05l0-0.07c0,0.02-0.05,0.06-0.22,0.15l0.02-0.05c-0.09,0-0.04,0.18-0.21,0.01
				c-0.01,0.01,0.01,0-0.29,0.02c0.08,0,0.14-0.01,0.14,0c0.06-0.12-0.28-0.17-0.41,0.04c-0.01-0.01,0.01,0,0.04-0.02l0,0.01
				c0.02,0,0.05-0.01,0.08-0.01c-0.02,0.04-0.05,0.07-0.08,0.03c-0.01,0.05-0.03,0.09-0.04,0.12c-0.09,0.03-0.39-0.13-1.17-0.01
				c0.02,0.01,0.02,0.02,0.03,0.03c-0.17,0-0.14-0.14-0.84,0.08l-0.02-0.07c-0.09,0.24-0.41-0.16-0.84,0.19c0-0.01,0-0.01,0-0.02
				l-0.01,0.04c0,0,0,0,0,0C685.09,137.3,685.12,137.39,685.26,137.46z M688.35,137.22c0-0.01-0.09,0.03-0.05-0.05
				C688.29,137.19,688.32,137.2,688.35,137.22z M688.21,137.23c-0.01,0.01,0,0-0.03,0.01C688.19,137.23,688.19,137.23,688.21,137.23
				z M688.02,136.79c0-0.01,0-0.03,0.01-0.04C688.05,136.75,688.06,136.77,688.02,136.79z"
                />
                <path
                  className="st10"
                  d="M681.74,142.15l-0.13-0.12c0.1,0.21,0.38,0.42,1.06,1.45c-0.2-0.12,0.15,0.35-0.01,0.36
				c0.16,0.09,0.21,0.45,0.33,0.67c-0.01-0.01-0.04-0.03-0.04-0.05c0.02,0.14,0,0.18,0,0.27c-0.03-0.17,0.47,0.57,0.03,0.3
				c0.08-0.02,0.13,0.27,0.18,0.21c-0.04,0.14-0.01,0.12-0.09,0.19c0.12-0.12,0.18,0.7-0.02,0.4c0.26,0.49,0,0,0.22,1.46
				c0.11-0.02,0.16-0.06,0.12,0.19c-0.48,0.2-0.12-0.06-0.22,1.78l-0.09-0.09c0.15,0.91-0.41,1.69-0.13,4.59l-0.03-0.06
				c-0.01,0.25,0.18,0.26,0.14,0.5l-0.02-0.04c0.04,0.17,0.19,1.92,1.07,2.9l-0.06,0.05c0.03-0.03,0.12,0.11,0.28,0.35
				c0.1-0.26-0.12,0.21,0.72,0.63c-0.03,0.02-0.11-0.02-0.17-0.05c0.28,0.1-0.01,0.1,0.25,0.19c-0.02-0.03,0.04-0.03,0.05-0.04
				c0.08,0.11,0.35,0.26,0.58,0.3l-0.06,0.04c0.39,0.16,0.93,0.17,1.39-0.06c0.02,0.12,1.35-1.15,2.11-3.36
				c-0.08,0.06-0.13,0,0.04-0.07c-0.04-0.1,0.21-0.22,0.38-0.62c-0.01,0.06-0.01,0.02,0.03,0c0.05,0.4-0.07,0.14,0.31,1.23
				l0.03-0.04c0.51,0.48-0.09-0.08,2.73,2.1l-0.14,0.05c0.69,0.09,1.45,0.13,2.06-0.18c-0.03,0.03-0.02,0.05-0.09,0.07
				c0.83-0.41,0.16-0.02,1.89-1.49c0.07,0.08,0.34-0.29,0.36-0.46c0,0.06,0.1-0.1,0.11-0.02c0.09-0.16,0.15-0.31,0.08-0.31
				c0.43-0.74,0.4-0.57,0.58-2.2c0.02,0.05,0.03,0.08,0.04,0.12c0.02-0.66,0.15-0.56-0.1-1.89c0.01,0,0.03,0.04,0.05,0.08
				c-0.1-0.2,0.02,0.02-0.3-0.35c0.18,0.08-0.08-0.37,0.16-0.23c-0.02-0.05-0.07-0.1-0.1-0.08c-0.01-0.07,0-0.1,0.06-0.04
				c-0.11-0.25-0.13-0.14-0.22-0.37c0.02-0.01,0.05,0.04,0.09,0.05c-0.09-0.1-0.18-0.2-0.24-0.4c0.02,0.01,0.03-0.02,0.05,0.03
				c-0.16-0.33-0.37-0.49-0.52-0.86c0.08,0.03,0.16-0.01-0.1-0.36l0.01,0.1c-0.09-0.16-0.24-0.26-0.3-0.4l0.11,0.09l-0.21-0.27
				l0.08,0.18c-0.14-0.12-0.09,0.08-0.2-0.13c0.04,0.03-0.1-0.15-0.12-0.26l0,0c-0.32-0.28,0.1-0.17-0.55-0.65
				c0.16-0.01-0.09-0.27-0.22-0.27c0.02,0.01,0.02,0,0.06,0.03c-0.23-0.23-0.3-0.34-0.36-0.44l0.06,0.05
				c-0.25-0.31,0.04,0.01-1.05-0.99l0.05,0.06c-0.4-0.21-0.07-0.32-1.02-0.91l0.06,0.01c-0.58-0.45-0.39-0.43-1.58-1.39
				c0.11,0.05,0.17,0.09-0.23-0.2c-0.01,0.06,0.02,0.06,0.05,0.15c-0.06-0.01-0.16-0.06-0.23-0.12c0.16,0.09,0.06-0.07,0.02-0.15
				c0,0.02-0.03,0.02-0.04,0.04c-0.09-0.21-0.1,0.07-0.27-0.17c0.05-0.03,0.11-0.06-0.47-0.42c0.06,0.03,0.14,0.09,0.2,0.12
				c-0.07-0.16-0.09-0.16-0.17-0.24l0.01,0.01l-0.18-0.2l0.04,0.06c-0.26,0-0.32-0.24-0.6-0.33c0.11,0.06,0.3-0.06-0.26-0.42
				c0.06,0.03-0.85-0.6-2.16-0.96c0.12-0.04,0.15-0.25-0.67,0.01l0.11,0.01c-0.01-0.04,0.04-0.16,0.23-0.03l-0.11,0.02
				c0.11,0.14,0.43-0.02,0.64,0.05c-0.05,0.08-0.09,0.09,0.44,0.27c-0.03,0.01-0.01,0.08-0.08,0.03c0.21,0.15,0.41,0.1,0.46,0.21
				l-0.02,0c0.55,0.47,0.71,0.15,1.69,1.24c-0.07,0.07-0.03-0.09,0.37,0.28c-0.03,0.01,0.3,0.33,0.44,0.49l-0.01,0.01
				c0.09-0.04,0.25,0.12,0.39,0.21c-0.04,0.04-0.08,0.03-0.16-0.01c0.03,0-0.01,0.04,0.29,0.18c-0.27,0.08,0.11,0.03,0.65,0.45
				l-0.12-0.03c0.05,0.01,0.28,0.24,0.05,0.09c0.34,0.36,0.15,0.15,0.92,0.76l0,0c0.31,0.17,0,0,0.79,0.72
				c-0.08,0.08,0.25,0.22,0.8,0.78c-0.14-0.08,0.01,0.06-0.14-0.09c0.13,0.16,0.13,0.22,0.34,0.32c-0.1-0.01,0.05,0.16,0.13,0.22
				l-0.07-0.13c0.08,0.07,0.14,0.14,0.21,0.22l-0.1,0.02c0.26,0.35,0.63,0.66,1.27,1.4c0.06-0.27,0.11,0.51,0.19,0.34
				c0.1,0.22,0.2,0.32,0.31,0.54c0.02-0.06,0.54,0.52,0.21,0.5c0.14,0.18,0.15,0.39,0.18,0.51l-0.07-0.1
				c0.04,0.06,0.06,0.11,0.08,0.17l0.03-0.08c0.02,0.07,0.04,0.12,0.01,0.13c0.14,0.09,0.19-0.08,0.24,0.34
				c-0.06-0.27-0.06,0.02-0.14-0.15c0.05,0.3-0.03,0.15,0.22,0.49c-0.05-0.02-0.06,0.09-0.05,0.13c-0.02-0.08,0.12-0.02,0.2,0.31
				l-0.01-0.11c0.16,0.17,0.05,0.48-0.03,1.26c0.06-0.06,0.05-0.08,0.1,0.09c0-0.13-0.02-0.26-0.04-0.38l0.09,0.25
				c0-0.12-0.04-0.25-0.03-0.32c0.05,0.12,0.04,0.11,0.08,0.05c-0.04,0.58-0.24,0.26-0.25,1.33c0.02-0.04,0.04-0.07,0.05-0.11
				c-0.12,0.61-0.11,0.22-0.41,1.47c-0.01-0.1-0.1,0-0.05-0.19c-0.1,0.43,0.11-0.08-1.46,1.88c0-0.02-0.01-0.02,0.02-0.04
				c-0.51,0.34,0,0.03-0.84,0.34l0.04-0.02c-0.23-0.03-0.04,0.15-1.26,0.29c0.01-0.03,0.02-0.05,0.02-0.06
				c-0.04,0.05-0.15,0.12-0.35-0.11c0.06,0.02,0.12,0.03,0.19,0.04c-0.08-0.09-0.24-0.05-0.34-0.09l0.02-0.03
				c-0.28-0.07-0.32,0.01-0.61-0.13l0.02,0.04c-0.46-0.13-0.03-0.23-0.85-0.54c0.14,0.03,0.35,0.15,0.5,0.19
				c-0.1-0.06-0.31-0.22-0.39-0.23c0.04,0.02,0.1,0.04,0.13,0.08c-0.15-0.03-0.33-0.12-0.49-0.3c0.27,0.04-0.8-1.2-0.77-2.03
				c-0.09,0.4-0.04-0.25-0.16-0.05c0-0.09-0.01,0,0.01-0.29c0,0,0,0.01-0.01,0.03c0-0.21,0.01-0.43-0.01-0.8
				c-0.01,0.08-0.08,0.07-0.06,0.03c0,0.03-0.01,0.07,0.01,0.13c-0.1,0.02,0.11-0.17-0.1-0.16c0.02-0.01,0.04-0.02,0.05-0.02
				c-0.07-0.21-0.54,0.51-0.44,0.25c-0.14,0.21-0.16,0.44-0.79,1.65l-0.02-0.05c-0.07,0.23-0.09,0.08-0.23,0.52l0-0.1
				c-1.32,1.92,0.08,0.88-4.45,0.34l0.08,0.05c-0.39-0.54-0.23-0.21-0.52-1.45c0.02,0,0.05,0.01,0.06,0.06
				c-0.04-0.16-0.02-0.64-0.16-0.57c0.09-0.01-0.02-0.37-0.08-0.56l0.07,0.03c-0.03-0.01-0.08-0.24-0.15-0.73l0.04,0.08
				c0.01-0.29-0.18-0.17,0.02-0.66c0,0.09-0.1-0.27-0.02-0.19c0.1-0.31-0.08,0.01,0.07-0.72c-0.02,0.25-0.03,0.45-0.04,0.43
				c0.06,0,0.09-0.2,0.17-0.03c-0.01-0.16-0.05,0.01-0.05-0.66l0.01,0.02c0.01-0.28,0.05-0.15,0.1-0.61c-0.07,0-0.05-0.13-0.12-0.01
				c0.01-0.03,0,0.04,0.01,0.13l-0.01-0.01c-0.01,0.07-0.01,0.15-0.02,0.25c-0.11-0.7,0.17-0.46,0.16-1.91
				c0.05,0.24,0.02,0.23,0.04-0.61l0.03,0.04c0.18-0.6-0.19-0.99-0.03-1.66c-0.01,0.05-0.01,0.07-0.03,0.08
				c0.08-0.32-0.08-1.9-0.93-3.8l-0.02,0.07C682.21,141.98,682.46,142.01,681.74,142.15z M683.04,150.7c0,0.02,0,0.05,0,0.11
				C683.04,150.77,683.04,150.81,683.04,150.7z M683.01,151.24c0.02,0.04-0.03-0.25,0.06-0.14
				C683.05,151.08,683.03,151.17,683.01,151.24z M683.57,150.38c0,0.07-0.01,0.09-0.03,0.1c-0.01-0.03,0.01,0,0-0.13
				C683.55,150.36,683.56,150.37,683.57,150.38z"
                />
                <path
                  className="st10"
                  d="M683.9,140.53l-0.03,0.05l-0.03-0.02c-0.02,0.05,0.02,0.03,0.05,0.02c-0.02,0.02-0.03,0.04-0.03,0.06
				l0.11-0.05c-0.03,0.04-0.08,0.05-0.07,0.09c0.05-0.02,0.05-0.09,0.1-0.09c-0.02,0.05-0.05,0.08,0,0.07
				c-0.02,0.02-0.03,0.03-0.05,0.04c0.04-0.01,0.05,0.03,0.1-0.01c-0.03,0.06,0.07,0.04-0.01,0.12c0.02-0.01,0.09-0.04,0.11-0.02
				c-0.12,0.07,0.05,0.06-0.05,0.15c0.1-0.07,0.11,0.03,0.19,0.02c-0.01,0-0.03,0.01-0.02,0.01c0.01,0.03-0.01,0.06-0.01,0.09
				c0.07-0.07,0.12-0.05,0.18-0.08c-0.04,0.09-0.1,0.07-0.15,0.14c0.05-0.06,0.1-0.01,0.14-0.07c-0.02,0.06,0,0.04-0.05,0.1
				c0.09-0.08,0.03,0,0.1-0.03c-0.02,0.07-0.07,0.09-0.02,0.09c-0.15,0.07,0.14,0.14,0.37,0.08c-0.01-0.01-0.03,0.01-0.04,0.02
				l0.03,0c-0.09,0.09-0.12-0.01-0.05,0.12l-0.01-0.06c-0.07,0.19,0.32,0.13,0.97,1.25c0.06-0.03,0.21,0.06,0.16,0.25l0.09-0.02
				l-0.06,0.07c0.13-0.02-0.11,0.06,0.16,0.13c-0.03,0.02-0.04,0.01-0.07,0.01c0.12-0.04-0.07,0.09,0.08,0.02
				c-0.02,0.05,0,0.09,0.04,0.11c-0.06,0.07-0.06,0.01,0.1,0.2c0-0.04,0.08-0.08,0.1-0.07l-0.06,0.05c0.05-0.02,0.16-0.08-0.02,0.02
				l0.04,0.01c-0.02,0.02-0.03,0.02-0.06,0.03c0.05-0.02-0.03,0.1,0.05,0.06c-0.32-0.1,0.64,0.6,0.75,1.28
				c0.01-0.01,0.09-0.06,0.02,0.02c0.11-0.19,0.25,0.6,0.27,0.51c0.15,0.06,0.11,0.22,0.23,0.3c-0.02,0-0.04,0.01-0.05,0
				c-0.04,0.05,0.04,0.09,0.08,0.07c-0.03-0.08,0.02,0.22,0.19,0.3c-0.11,0.04-0.05,0.11,0.01,0.11c-0.03,0-0.02,0.04-0.07,0.05
				c0.01,0.03,0.04,0.05,0.1,0.02c-0.1,0.11,0.15,0.07,0,0.17c0.09,0.07,0.16,0.13,0.22,0.21c-0.09,0.07-0.06,0.01,0.09,0.32
				c-0.01,0-0.02,0-0.03,0c0.01,0.01,0,0.04,0.04,0.02l-0.02-0.01c0.06-0.05,0.17,0,0.2-0.01c-0.15,0.03-0.01,0.08-0.2,0.11
				c0.01,0.01,0.04,0.01,0.07,0c-0.04,0.12-0.01,0.02,0.07,0.37c0-0.02,0.01,0.07,0.08,0c-0.04,0,0.12,0.09,0.02,0.07l0.04,0.06
				c0.03-0.04,0.03-0.05,0.12-0.03c-0.04,0.05,0,0.08-0.1,0.15c0.08-0.01,0.04,0.07,0.13,0.07c-0.02,0-0.07,0.01-0.08,0.02l0.06,0
				c-0.03,0.03-0.09,0.06-0.01,0.06c-0.03,0.01,0.14,0.2-0.05,0.15c0.04,0.07,0.16,0.12,0.15,0.2c0.14-0.07,0.01,0,0.03,0.08
				c-0.01-0.01-0.07,0.01-0.06,0.01c-0.01,0.06,0.06,0.09,0.12,0.16c-0.02-0.03-0.1,0.14,0.04,0.11c-0.07,0.08-0.17,0.21,0.17,0.34
				c-0.04-0.03-0.09,0.02-0.12,0.04l0.05-0.01c-0.14,0.06,0.11,0.01,0.03,0.05c0.02,0.01,0.09,0.09-0.03,0.05
				c0.05,0.09,0-0.03-0.05,0.16c0.21-0.04,0.01,0,0.19,0.08c-0.11-0.02-0.02-0.03-0.2,0.06c0.07,0.01-0.04,0.05,0.06,0.07
				c-0.02,0.01,0,0.03-0.06,0.03c0.03-0.23,0.26,0.3,0.12,0.4c0.03,0.02,0.08,0.04,0.1,0.07c-0.04,0.01-0.07-0.01-0.1-0.01
				c0.01-0.01,0.03,0.06,0.12,0.05c-0.02-0.04-0.04-0.14,0.08-0.14c-0.01,0.01-0.06-0.11,0.06-0.12c0.02-0.09,0.02-0.14-0.18-0.13
				l0.18-0.07l-0.08,0.01c0-0.01,0-0.02,0.04-0.03c-0.06-0.02-0.03,0.01-0.08,0c0.06-0.05-0.05-0.09,0.07-0.12
				c-0.06,0-0.04,0.04-0.08,0.01c0.15-0.01-0.09-0.08,0.11-0.09c-0.13-0.05,0.09-0.12-0.11-0.18c0.04,0.01-0.02-0.02,0.07-0.15
				c-0.09,0.01-0.08-0.04-0.11-0.06c0.05-0.01,0.08-0.01,0.1,0c-0.01-0.03,0-0.03-0.07-0.05l0.07-0.01
				c-0.01-0.04-0.1-0.06-0.06-0.12c-0.06,0.01-0.16,0.08-0.22,0.04c0.03-0.01,0.09-0.03,0.11-0.02c0.03-0.08-0.03,0.03,0.13-0.11
				c-0.03,0-0.15,0,0-0.06c-0.04,0-0.01-0.04-0.08-0.02c0-0.01,0.03-0.02,0.05-0.03c-0.04-0.01,0,0-0.11-0.05l0,0
				c-0.13,0.08,0.05-0.17-0.07-0.5c0.04,0.04,0.07-0.13,0.01-0.02c0.06-0.3-0.31-0.27-0.05-0.53c-0.04-0.02-0.05,0-0.06,0.03
				c0.03-0.06-0.02-0.07-0.01-0.13c-0.06,0.04-0.24-0.11,0.01-0.12c-0.07-0.03,0.01-0.09,0.01-0.12l0.03,0.02
				c-0.02-0.01-0.02-0.02-0.03-0.03l-0.05,0.03c0-0.02,0-0.03,0.02-0.04c-0.04,0-0.09,0.04-0.15,0.03c0.02-0.03-0.05-0.04,0.02-0.08
				c0,0.07,0.07-0.03,0.1,0.01c0.01-0.09,0.07-0.06-0.1-0.09c0.04-0.01,0.07-0.04,0.06-0.05c0.01,0.02-0.07,0.04-0.11,0.03
				c0.02-0.05-0.04-0.03-0.04-0.05c-0.02,0.03-0.01,0.05-0.07-0.01c0.03-0.01,0.03,0.01,0.04,0c-0.08,0,0.05-0.09-0.01-0.11
				c0.02-0.03,0.08-0.03,0.09-0.05c-0.1,0,0.08-0.12-0.13-0.08l0.07,0.06c-0.14-0.02-0.13,0.08-0.13-0.25
				c-0.03,0.03-0.03,0.06-0.07-0.02c0.05-0.02,0.06-0.04,0.08-0.06c-0.03,0.01-0.02-0.05-0.11-0.02c0.04-0.07,0.11-0.08-0.11-0.13
				c0.06,0,0.08-0.04,0.13-0.02c-0.06-0.04,0.02,0.01-0.22-0.07c0.12-0.12-0.08-0.15,0.02-0.25c-0.19-0.18-0.1,0-0.34-0.48
				c0.06-0.01,0.04,0,0.09-0.03c-0.02-0.02-0.09,0.05-0.13,0.02c-0.06,0.14,0.17-0.29-0.17-0.38c0.08-0.01,0.13,0.02,0.19,0.02
				c-0.01-0.02-0.04-0.07-0.09-0.06c0.03,0,0.03-0.06-0.14-0.51c-0.05,0.05-0.24,0.04-0.03-0.04c-0.03,0.05-0.08,0.02-0.11,0.06
				c0-0.04,0.05-0.12,0.16-0.21c-0.04,0.03-0.08,0.02-0.07-0.01c0.01,0.07-0.12-0.07-0.01-0.07c-0.04-0.02-0.07-0.12-0.19-0.09
				c0.01-0.01,0.04-0.04,0.06-0.04c-0.04-0.14-0.18-0.04-0.64-1.03c0.01,0-0.1,0.09-0.1-0.02l0,0.01c0.07-0.07,0.03-0.09,0.01-0.12
				l-0.11,0.07c0.1-0.19-0.2-0.15-0.09-0.29c-0.04,0-0.06-0.02-0.09-0.02c0.04-0.05,0.09-0.04-0.03-0.18l0.05-0.02
				c-0.1,0.05-0.06-0.18-0.37-0.26c0.07-0.06-0.04-0.06-0.1-0.07c0.15-0.09-0.04,0.01-0.29-0.34c0.03,0.04,0.06,0.07,0.05,0.07
				c0.08,0.01-0.01-0.31-0.18-0.19c0-0.01,0,0,0.03,0.01l-0.01,0l0.04,0.03c-0.04,0.01-0.07,0.02-0.06-0.02
				c-0.04,0.02-0.08,0.04-0.1,0.05c-0.01-0.07,0.11-0.22-0.23-0.31c0.1-0.27,0.08-0.07-0.21-0.29c0,0.01,0,0.02-0.01,0.03
				c-0.05-0.06,0-0.22-0.39-0.36l0.05-0.06c-0.02,0.19-0.12-0.26-0.51-0.25c0.01,0,0.01,0,0.01,0c-0.04,0.06-0.1,0.14-0.18,0.26
				L683.9,140.53z M685.51,141.42c0.01,0,0.02,0.03-0.03,0.02C685.49,141.43,685.5,141.42,685.51,141.42z M685.28,141.82
				c-0.02,0.01-0.01,0.03-0.02,0.05C685.25,141.85,685.22,141.84,685.28,141.82z"
                />
                <path
                  className="st10"
                  d="M696.88,159.22l-0.02-0.07c-0.35,0.14-0.16,0.14-1.05,0.34c0-0.03,0.06-0.06,0.11-0.1
				c-0.18,0.14-0.06-0.07-0.23,0.07c0.03,0.01,0,0.05,0,0.06c-0.11-0.01-0.33,0.05-0.48,0.14l0.01-0.06
				c-0.29,0.1-0.6,0.24-0.91,0.39c0.08-0.11,0.09,0.22-0.02,0.06c-0.28,0.24-2.89,0.57-2.54,3.57l-0.02-0.02
				c0.24,0.29,0.61,0.98,1.71,0.86c-0.11,0.17-0.22,0.37-0.33,0.62l-0.02-0.13c-0.19,0.52-0.52,1.09-0.4,1.7
				c-0.02-0.03-0.05-0.03-0.05-0.09c0.21,0.77-0.06,0.16,1.16,1.74c-0.07,0.1,0.31,0.25,0.46,0.23c-0.05,0.02,0.1,0.07,0.04,0.1
				c0.15,0.03,0.3,0.04,0.28-0.02c0.71,0.15,0.6,0.21,1.91-0.31c-0.02,0.03-0.04,0.05-0.06,0.08c0.49-0.26,0.48-0.13,1.31-0.89
				c0.01,0.01-0.01,0.04-0.03,0.07c0.1-0.16,0,0.03,0.1-0.39c0.03,0.18,0.25-0.2,0.26,0.05c0.02-0.04,0.04-0.09,0.01-0.11
				c0.05-0.04,0.08-0.04,0.06,0.03c0.14-0.18,0.04-0.16,0.18-0.31c0.02,0.02,0,0.05,0,0.09c0.03-0.11,0.07-0.22,0.19-0.33
				c0,0.02,0.03,0.02,0,0.05c0.19-0.23,0.23-0.46,0.47-0.67c0.01,0.08,0.07,0.13,0.27-0.16l-0.08,0.02c0.1-0.11,0.13-0.26,0.24-0.33
				l-0.04,0.11l0.17-0.22l-0.13,0.09c0.06-0.14-0.1-0.07,0.05-0.2c0.25-0.16,0.1-0.02,0.68-0.63c-0.02,0.14,0.23-0.01,0.22-0.18
				c-0.01,0.02,0,0.02-0.02,0.05c0.2-0.19,0.29-0.24,0.37-0.28l-0.05,0.05c0.26-0.19-0.02,0.02,0.9-0.76l-0.05,0.03
				c-0.04-0.14,0.23-0.08,0.33-0.22c0.12,0.09-0.01,0.06,0.61-0.41l-0.02,0.05c0.49-0.34,0.43-0.21,1.52-0.83
				c-0.07,0.07-0.12,0.11,0.23-0.11c-0.05-0.04-0.05-0.01-0.13-0.02c0.02-0.05,0.09-0.11,0.17-0.15c-0.12,0.1,0.04,0.08,0.11,0.09
				c-0.02-0.01-0.01-0.04-0.02-0.05c0.34-0.1-0.02-0.09,0.7-0.36c-0.04,0.04-0.12,0.07-0.16,0.11c0.15,0.02,0.15,0,0.25-0.03
				l-0.01,0l0.22-0.05l-0.06,0c0.91-0.61,0.05-0.05,2.67-1.04c-0.16,0.39-0.03-0.34,0.61,0.32l0-0.07c-0.16,0.37,0.74,0.39-1.1,1.86
				c0.1-0.23-0.37,0.26-0.4,0.12c-0.05,0.2-0.42,0.42-0.6,0.65c0.01-0.02,0.02-0.05,0.04-0.06c-0.13,0.1-0.19,0.1-0.28,0.15
				c0.14-0.13-0.34,0.76-0.29,0.2c0.06,0.05-0.2,0.29-0.11,0.3c-0.15,0.06-0.11,0.07-0.23,0.06c0.17,0.02-0.54,0.64-0.38,0.27
				c-0.25,0.61-0.05-0.05-1.09,1.3c0.1,0.07,0.15,0.06-0.06,0.24c-0.51-0.16-0.06-0.14-1.44,1.42l-0.01-0.13
				c-0.51,0.88-1.34,1.37-3.14,4.02l0.02-0.07c-0.18,0.2-0.06,0.34-0.26,0.5l0.02-0.05c-0.13,0.19-1.23,1.52-1.91,2.78l-0.07-0.04
				c0.03,0.01-0.04,0.16-0.25,0.4c0.02,0.01,0.03,0.02,0.04,0.02c-0.07,0.02-0.24,0.16-0.55,0.83c-0.02-0.03,0.02-0.11,0.04-0.18
				c-0.22,0.47-0.04,0.08-0.5,0.87l-0.02-0.06c-1.37,2.26-0.05-0.1-3.52,6.51l0.01-0.03c-0.52,1.16,0.02,0-1.46,2.88l0.05,0
				c-0.19,0.89,0.07-0.07-1.43,3.4l-0.03-0.15c-0.19,0.69-0.54,1.34-0.72,2.01c0-0.05-0.03-0.06,0-0.12
				c-0.11,0.11-0.2,0.5-0.16,0.52c-0.15,0.29-0.06-0.04-0.65,1.9c-0.1-0.06-0.24,0.4-0.24,0.57c-0.01-0.06-0.08,0.12-0.1,0.05
				c-0.06,0.18-0.09,0.35-0.03,0.34c-0.3,0.55-0.25,0.42-0.71,2.36c-0.01-0.09-0.02-0.16-0.02-0.21c-0.08,0.33-0.16,0.39-0.56,2.04
				c0.01,0,0.05-0.03,0.08,0.4c-0.1-0.18-0.15,0.39-0.27,0.13c-0.01,0.06,0,0.13,0.04,0.12c-0.03,0.08-0.06,0.1-0.07,0.01
				c-0.05,0.29,0.03,0.21-0.05,0.47c-0.02-0.01-0.02-0.06-0.04-0.1c0.01,0.14,0.02,0.29-0.06,0.5c-0.01-0.02-0.04,0-0.02-0.06
				c-0.09,0.38-0.04,0.68-0.19,1.08c-0.04-0.08-0.11-0.11-0.19,0.36l0.07-0.08c-0.05,0.2-0.03,0.39-0.1,0.55l0-0.15l-0.07,0.37
				l0.08-0.2c0,0.21,0.12,0.01,0.04,0.26c0-0.06-0.04,0.2-0.12,0.29l0,0c-0.02,0.45-0.19,0.07-0.18,0.93
				c-0.1-0.13-0.17,0.27-0.1,0.38c0-0.03-0.01-0.02-0.01-0.07c-0.06,0.36-0.11,0.5-0.15,0.61l0.01-0.09c-0.11,0.44,0-0.04-0.25,1.59
				l0.02-0.08c0.03,0.5-0.24,0.27-0.21,1.52l-0.03-0.06c-0.06,0.82-0.16,0.63-0.28,2.35c-0.02-0.14-0.02-0.21-0.03,0.34
				c0.06-0.03,0.04-0.05,0.09-0.14c0.03,0.06,0.04,0.18,0.04,0.29c-0.03-0.2-0.09-0.01-0.13,0.08c0.02-0.01,0.04,0.02,0.05,0.01
				c-0.12,0.22,0.11,0.05,0.03,0.35c-0.05-0.03-0.11-0.06-0.05,0.71c-0.01-0.08-0.01-0.18-0.02-0.26c-0.09,0.17-0.07,0.19-0.09,0.32
				l0-0.01l-0.05,0.3l0.03-0.08c0.16,0.23,0.01,0.45,0.13,0.75c-0.11-0.29-0.06-0.05-0.14-0.07c-0.02,0.08-0.05,0.19-0.07,0.16
				c0.06,0.14-0.05,0.24,0.04,0.44c-0.04-0.09,0.11,1.09,0.17,2.61c-0.1-0.09-0.19-0.16,0.09,0.71l0.01-0.12
				c-0.04,0.01-0.16-0.04-0.05-0.25l0.03,0.11c0.13-0.13-0.09-0.46-0.08-0.7c0.09,0.04,0.14,0.06,0.1-0.57
				c0.02,0.03,0.07-0.03,0.06,0.07c0.05-0.29-0.1-0.47-0.03-0.58l0.01,0.02c0.07-0.82-0.2-0.78-0.01-2.37
				c0.09,0.02-0.05,0.08,0-0.51c0.03,0.02,0.08-0.49,0.12-0.73l0.01,0.01c-0.09-0.05-0.06-0.3-0.07-0.49
				c0.05,0.01,0.07,0.05,0.09,0.15c-0.01-0.03,0.04-0.01-0.03-0.38c0.22,0.2-0.04-0.13,0-0.88l0.05,0.13
				c-0.02-0.06,0.04-0.4,0.04-0.1c0.11-0.54,0.04-0.22,0.13-1.32l0,0c-0.02-0.39,0,0,0.18-1.18c0.1,0.02,0.06-0.37,0.24-1.23
				c0.01,0.18,0.05-0.05,0,0.18c0.06-0.22,0.11-0.26,0.09-0.52c0.05,0.09,0.11-0.15,0.11-0.26l-0.07,0.15
				c0.01-0.12,0.04-0.22,0.06-0.33l0.07,0.07c0.12-0.47,0.22-1.01,0.37-2.1c-0.25,0.16,0.32-0.49,0.13-0.42
				c0.11-0.26,0.1-0.42,0.2-0.68c-0.05,0.02-0.03-0.84,0.2-0.58c0.01-0.26,0.15-0.46,0.2-0.6l-0.01,0.14
				c0.01-0.08,0.02-0.15,0.04-0.21l-0.08,0.06c0.03-0.07,0.05-0.14,0.07-0.13c-0.06-0.18-0.19-0.05,0.02-0.47
				c-0.11,0.3,0.07,0.01,0.03,0.24c0.13-0.3,0.13-0.15,0.11-0.62c0.03,0.05,0.1-0.05,0.11-0.11c-0.03,0.1-0.11-0.06-0.02-0.42
				l-0.05,0.12c-0.07-0.25,0.21-0.53,0.54-1.37c-0.08,0.06-0.07,0.07-0.07-0.12l-0.09,0.44l0-0.3c-0.04,0.13-0.05,0.28-0.08,0.36
				c-0.01-0.14,0-0.13-0.06-0.08c0.22-0.62,0.37-0.24,0.57-1.45l-0.06,0.12c0.02-0.4,0.22-0.28,0.47-1.69
				c0.02,0.11,0.1-0.02,0.07,0.2c0.06-0.51-0.07,0.14,0.86-2.67c0.01,0.02,0.02,0.01,0,0.05c0.2-0.69,0.02,0,0.44-0.98l-0.01,0.05
				c0.13-0.23,0.05-0.06,0.41-1.48c0.02,0.02,0.03,0.02,0.04,0.03c-0.03-0.06-0.04-0.19,0.22-0.37l-0.09,0.21
				c0.13-0.07,0.11-0.27,0.17-0.38l0.03,0.03c0.12-0.33,0.04-0.37,0.19-0.71l-0.04,0.02c0.1-0.54,0.28-0.08,0.42-1.08
				c0.01,0.15-0.06,0.43-0.09,0.61c0.05-0.12,0.17-0.42,0.15-0.51c-0.01,0.05-0.02,0.13-0.05,0.17c0.33-1.07-0.03-0.06,1.35-2.71
				c-0.22,0.11,0.07-0.15-0.09-0.18c0.1-0.28,0.27-0.36,0.57-0.58c-0.07,0.01-0.03-0.12,0.06-0.26l-0.16,0.17
				c0.1-0.39,0.26-0.25,0.36-0.64l-0.01,0.13c0.18-0.29,0.14-0.36,1-2.18l0.02,0.05c0.05-0.15,0.04-0.05,0.27-0.58l-0.01,0.11
				c0.27-0.39,0.25-0.69,0.42-1.05c0.1,0.05,0.3-0.26,0.41-0.41l0.01,0.01c0.66-0.86-0.13,0,2.97-5.14l0.06,0.04
				c0.19-0.27,0.3-0.4,0.52-0.76l-0.01,0.1c0.28-0.73,0.11-0.33,0.93-1.54c0.01,0.01,0.03,0.04-0.01,0.1
				c0.11-0.15,0.49-0.56,0.33-0.59c0.08,0.05,0.25-0.33,0.35-0.54l0.04,0.07c-0.01-0.03,0.1-0.27,0.39-0.74l-0.02,0.1
				c0.21-0.26-0.02-0.26,0.46-0.58c-0.05,0.08,0.1-0.3,0.11-0.19c0.29-0.23-0.08-0.04,0.52-0.62c-0.18,0.21-0.32,0.39-0.31,0.37
				c0.05,0.04,0.2-0.13,0.16,0.07c0.09-0.16-0.05-0.02,0.39-0.64l-0.01,0.02c0.19-0.25,0.14-0.11,0.47-0.51
				c-0.06-0.04,0.04-0.14-0.09-0.08c0.02-0.02-0.03,0.04-0.08,0.12l0-0.01c-0.05,0.06-0.11,0.13-0.18,0.22
				c0.36-0.69,0.42-0.37,1.37-1.63c-0.12,0.24-0.14,0.21,0.45-0.5l-0.01,0.05c0.56-0.38,0.59-0.94,1.24-1.34
				c-0.04,0.03-0.07,0.04-0.08,0.04c0.38-0.09,1.39-1.54,3.32-2.75l-0.08-0.01c0.07-0.05,1.73-1.48,0.09-2.29
				c0.22-0.03-0.3-0.19-0.19-0.28c-0.17,0.04-0.43-0.16-0.66-0.18c0.01,0,0.04-0.01,0.06,0c-0.11-0.06-0.13-0.1-0.2-0.15
				c0.13,0.05-0.66,0.15-0.24-0.11c-0.02,0.08-0.27,0-0.24,0.07c-0.09-0.09-0.08-0.06-0.11-0.15c0.04,0.15-0.63-0.09-0.31-0.17
				c-0.48,0.12,0.01-0.06-1.27-0.15c-0.01,0.09,0.03,0.17-0.18,0.09c-0.11-0.61,0.02-0.05-1.48-0.34l0.08-0.09
				c-0.74,0.12-1.42-0.3-3.83-0.02l0.05-0.03c-0.21-0.02-0.22,0.17-0.41,0.12l0.03-0.02C699.21,158.82,698.71,158.92,696.88,159.22z
				 M701.16,169.01c-0.01,0.05,0.13-0.24,0.14-0.09C701.3,168.88,701.22,168.96,701.16,169.01z M701.46,168.64
				c0.03-0.04,0,0,0.07-0.09C701.52,168.56,701.5,168.59,701.46,168.64z M702.17,168.49c0,0.07,0.01,0.06-0.08,0.12
				C702.11,168.58,702.11,168.62,702.17,168.49z M709,161.49C709,161.49,709,161.49,709,161.49c0,0.01-0.01,0.01-0.01,0.01
				L709,161.49z M709.45,161.39c-0.02,0-0.03,0-0.05,0c0-0.04,0-0.07,0-0.12C709.42,161.31,709.43,161.35,709.45,161.39z
				 M702.22,158.72c-0.02,0-0.04,0-0.09,0C702.17,158.72,702.13,158.72,702.22,158.72z M701.89,158.77
				c0.02-0.02-0.06-0.03-0.12-0.05C701.74,158.73,701.99,158.67,701.89,158.77z M699.24,159.29c0,0.02-0.01,0.05-0.06,0.06
				c0.14-0.02,0.55,0.01,0.48-0.13c0.01,0.09,0.31-0.01,0.47-0.07l-0.02,0.07c0.01-0.02,0.19-0.09,0.61-0.16l-0.07,0.05
				c0.24,0,0.14-0.18,0.55,0c-0.08,0,0.23-0.1,0.16-0.02c0.26,0.09-0.01-0.08,0.6,0.04c-0.21-0.01-0.37-0.01-0.36-0.02
				c0,0.06,0.16,0.08,0.03,0.16c0.14-0.02-0.01-0.05,0.55-0.09l-0.02,0.01c0.23,0,0.13,0.05,0.51,0.07c0-0.07,0.11-0.05,0.01-0.12
				c0.02,0.01-0.04,0-0.11,0.01l0.01-0.01c-0.05,0-0.13-0.01-0.21-0.01c0.56-0.13,0.38,0.13,1.57,0.14
				c-0.2,0.04-0.19,0.01,0.49,0.08l-0.04,0.02c0.45,0.24,0.84-0.08,1.32,0.25c-0.03-0.02-0.05-0.03-0.05-0.05
				c0.12,0.23,1.23,0.2,2.31,0.87c-0.08,0.05-0.16,0.1-0.23,0.12c-0.01-0.07,0.03-0.16-0.44,0.01c0.02-0.03-0.04-0.06,0.03-0.07
				c-0.23,0.01-0.31,0.18-0.41,0.14l0.02-0.01c-0.3,0.1-0.82,0.19-2.01,0.8c0-0.03-0.36,0.1-0.54,0.15l0-0.02
				c0.01,0.1-0.17,0.16-0.28,0.24c-0.02-0.05-0.01-0.08,0.05-0.14c-0.01,0.02-0.04-0.03-0.23,0.17l0-0.08
				c-0.03,0.11-0.3-0.04-0.54,0.46l0.06-0.09c-0.03,0.04-0.27,0.15-0.08,0.01c-0.38,0.15-0.16,0.06-0.86,0.51l0,0
				c-0.1,0.19-0.72,0.3-1.53,1.01c0.08-0.11-0.06,0,0.09-0.11c-0.15,0.09-0.21,0.08-0.31,0.25c0.01-0.09-0.15,0.02-0.2,0.09
				l0.12-0.04c-0.06,0.07-0.13,0.11-0.2,0.16l-0.02-0.09c-0.31,0.21-0.61,0.52-1.12,1.17c0.25,0.01-0.42,0.17-0.25,0.23
				c-0.17,0.13-0.21,0.24-0.37,0.39c0.06,0-0.22,0.62-0.34,0.32c-0.08,0.18-0.26,0.24-0.34,0.32l0.05-0.09
				c-0.03,0.05-0.06,0.09-0.09,0.13l0.08,0c-0.05,0.04-0.08,0.07-0.1,0.06c-0.01,0.14,0.16,0.13-0.15,0.32
				c0.13-0.03-0.17-0.27-0.22,0.19c-0.01-0.05-0.1-0.02-0.12,0.01c0.05-0.05,0.08,0.09-0.12,0.29l0.08-0.05
				c-0.04,0.2-0.32,0.24-0.91,0.51c0.08,0.02,0.08,0.01-0.01,0.12c0.08-0.07,0.17-0.13,0.25-0.2c-0.04,0.06-0.08,0.12-0.13,0.18
				c0.08-0.05,0.15-0.14,0.21-0.16c-0.05,0.09-0.05,0.08,0.01,0.09c-0.42,0.22-0.28-0.09-1.04,0.34l0.1,0
				c-0.02,0.07-0.17,0.05-0.26,0.09c0-0.05-0.06-0.06-0.1-0.08c0.01,0.03-0.2,0.03-0.23,0.12c-0.27-0.08-0.12-0.1-0.63,0.03
				c0.08-0.04-0.02-0.09,0.13-0.11c-0.07-0.01-0.14-0.03-0.14,0.01c0.05-0.04-0.09,0.02-0.28-0.05c0.02,0.02,0,0,0.07,0.03
				c-0.04,0.03-0.11-0.07-0.28,0.05c-0.05-0.15-0.19-0.14-0.49-0.43c0.03,0.08,0.05-0.18,0,0c-0.04-0.04,0,0.01-0.14-0.16
				c0,0,0,0,0,0c-0.09-0.1-0.18-0.21-0.29-0.37c0.02,0,0.02-0.01,0.03,0.02c-0.18-0.45-0.06,0.01-0.1-0.7c0,0.01,0,0.02,0.01,0.04
				c0.1-0.17-0.11-0.06,0.1-1.06c0.02,0.02,0.04,0.03,0.05,0.04c-0.03-0.05-0.07-0.16,0.19-0.25l-0.08,0.14
				c0.12-0.03,0.1-0.18,0.16-0.25l0.03,0.03c0.12-0.22,0.04-0.27,0.21-0.49l-0.04,0c0-0.2,0.04-0.01,0.47-0.51
				c-0.04,0.09-0.09,0.19-0.12,0.27c0.03-0.05,0.04-0.01,0.21-0.32c-0.01,0.01-0.02,0.02-0.02,0.03c-0.01-0.12-0.06,0,0.44-0.31
				c-0.35-0.45-0.31-0.42-1.6,0.03c0.13,0.14-0.1,0.01-0.07,0.16c-0.1-0.02-0.17,0.04-0.1-0.16c0.1,0.07-0.18,0.06-0.03,0.15
				c-0.11-0.03-0.15-0.13-0.24-0.34c0,0.06-0.08,0.05-0.14-0.02c0.02,0.04,0.04,0.08,0.06,0.12c-0.24-0.06-0.08-0.19-0.26-0.3
				c0.35-0.07-0.42,0.17-0.12-1.33l0.02,0.04c0.04-0.17,0.05-0.05,0.18-0.37l-0.01,0.08c0.22-0.21,0.24-0.43,0.45-0.63
				c0.07,0.08,0.29-0.04,0.41-0.09l0,0.02c1.01-0.12-0.45-0.44,4.66-1.69l-0.05,0.06C698.46,159.33,698.16,159.45,699.24,159.29z
				 M702.42,159.21c0.02-0.01,0,0.01,0.1-0.01C702.49,159.23,702.49,159.25,702.42,159.21z M694.32,164.19c0.01,0,0.01,0,0.02,0
				c-0.01,0.02-0.02,0.04-0.04,0.05C694.3,164.23,694.31,164.21,694.32,164.19z"
                />
                <path
                  className="st10"
                  d="M710.82,159.05l0.02,0.02c0-0.01,0-0.01,0-0.01c0.06,0.04,0.14,0.1,0.27,0.17l-0.02-0.09l0.05,0.02
				l-0.03,0.03c0.06,0.02,0.03-0.03,0.02-0.06c0.03,0.02,0.05,0.03,0.06,0.02l-0.05-0.11c0.04,0.03,0.05,0.08,0.09,0.07
				c-0.02-0.05-0.09-0.05-0.09-0.1c0.06,0.02,0.08,0.05,0.07,0c0.03,0.02,0.04,0.03,0.04,0.05c-0.01-0.04,0.03-0.06,0-0.11
				c0.07,0.02,0.05-0.08,0.13,0c-0.01-0.02-0.03-0.09-0.02-0.11c0.07,0.12,0.07-0.06,0.16,0.04c-0.07-0.1,0.04-0.12,0.03-0.21
				c0,0.01,0.01,0.03,0.01,0.03c0.03-0.02,0.06,0,0.09,0c-0.07-0.07-0.05-0.12-0.08-0.18c0.1,0.04,0.07,0.1,0.14,0.15
				c-0.06-0.05-0.01-0.1-0.07-0.14c0.06,0.02,0.04,0,0.11,0.04c-0.09-0.09,0-0.03-0.03-0.1c0.07,0.01,0.09,0.07,0.1,0.01
				c0.06,0.15,0.16-0.15,0.09-0.41c-0.01,0.01,0.01,0.03,0.02,0.04l0-0.03c0.02,0.15,0.16,0.04,0.07,0.05
				c0.12,0.13,0.11,0.11,1.58-1.53l0.08,0.05c-0.03-0.17,0.02,0.14,0.14-0.19c0.02,0.02,0.01,0.04,0.02,0.07
				c-0.05-0.12,0.1,0.06,0.02-0.09c0.05,0.02,0.1-0.02,0.12-0.07c0.08,0.05,0.02,0.06,0.21-0.14c-0.04,0-0.1-0.07-0.08-0.1
				l0.06,0.05c-0.02-0.05-0.1-0.15,0.03,0.01l0.01-0.05c0.02,0.02,0.02,0.03,0.04,0.06c-0.02-0.05,0.11,0.02,0.07-0.07
				c-0.08,0.34,0.6-0.77,1.34-1.06c-0.01-0.02-0.08-0.08,0.02-0.03c-0.22-0.07,0.61-0.38,0.53-0.4c0.05-0.17,0.23-0.17,0.3-0.32
				c0,0.02,0.02,0.04,0.01,0.05c0.01-0.02,0.1-0.08,0.36-0.37c0.06,0.1,0.13,0.02,0.12-0.04c0.01,0.03,0.04,0.01,0.06,0.06
				c0.03-0.02,0.05-0.06,0.01-0.1c0.13,0.07,0.05-0.17,0.18-0.05c0.11-0.15,0.01-0.13,0.53-0.47c0.01,0.01,0,0.02,0,0.04
				c0.01-0.01,0.04-0.01,0.01-0.05l-0.01,0.02c-0.06-0.05-0.03-0.17-0.05-0.2c0.06,0.14,0.09-0.01,0.15,0.17
				c0-0.01,0-0.04-0.02-0.07c0.15,0,0.03,0,0.39-0.18c-0.03,0,0.08-0.03-0.02-0.08c0.01,0.04,0.07-0.15,0.07-0.04l0.06-0.05
				l-0.06-0.01c0.03-0.09-0.07-0.13,0.18-0.05c-0.02-0.08,0.07-0.07,0.05-0.15c0,0.02,0.03,0.06,0.04,0.07l-0.02-0.06
				c0.06,0.02,0.03-0.03,0.19-0.05l-0.02,0.03l0.04,0.02l-0.02-0.05c0.04,0.1,0.09-0.2,0.31-0.23c0,0.01,0.02,0.07,0.02,0.06
				c0.06-0.01,0.09-0.09,0.15-0.17c-0.03,0.03,0.18,0.05,0.11-0.08c0.12,0.02,0.19,0.17,0.33-0.28c-0.02,0.05,0.04,0.08,0.08,0.1
				c-0.01-0.01-0.02-0.03-0.03-0.05c0.1,0.12-0.01-0.1,0.05-0.05c0.01-0.02,0.07-0.12,0.06,0.01c0.09-0.08-0.04,0,0.19-0.01
				c-0.1-0.19-0.01-0.01,0.04-0.21c0.01,0.11-0.02,0.03,0.11,0.17c0-0.07,0.06,0.02,0.06-0.08c0.01,0.02,0.03-0.01,0.05,0.05
				c-0.13-0.23,0.14,0.06,0.2-0.39c0.05,0.02-0.01,0.16,0.11,0.05c0.04,0.14,0.06-0.13,0.08,0.08c0.02-0.03,0.03-0.09,0.05-0.12
				c0.03,0.03,0.01,0.07,0.01,0.1c-0.01-0.07,0.09-0.19-0.12-0.06l-0.03-0.1c0.02,0-0.09,0.1-0.14-0.02
				c-0.1,0.01-0.16,0.03-0.09,0.22l-0.12-0.15l0.03,0.07c-0.01,0-0.03,0-0.04-0.02c0,0.06,0.02,0.03,0.03,0.08
				c-0.07-0.04-0.09,0.08-0.15-0.02c0.01,0.06,0.06,0.02,0.04,0.08c-0.05-0.14-0.06,0.12-0.13-0.08c-0.02,0.15-0.15-0.05-0.17,0.16
				c-0.01-0.04-0.01,0.03-0.18-0.02c0.04,0.09-0.02,0.09-0.04,0.13c-0.02-0.05-0.03-0.07-0.02-0.1c-0.02,0.02-0.03,0.01-0.04,0.09
				l-0.03-0.06c-0.04,0.03-0.04,0.11-0.12,0.1c0.03,0.06,0.13,0.13,0.09,0.2c-0.02-0.03-0.05-0.08-0.04-0.1
				c0,0.01-0.09,0.01-0.06-0.05c-0.03,0-0.07-0.01-0.09-0.03c0.01,0.03,0.04,0.15-0.06,0.02c0.01,0.04-0.04,0.02,0,0.08
				c-0.01,0.01-0.03-0.02-0.05-0.04c0,0.04,0,0-0.02,0.12l0,0c0.03,0.13-0.1,0-0.45,0.22c0.01-0.06-0.03-0.03,0.02-0.05
				c-0.05,0-0.08-0.02-0.09,0.06c0.04-0.05-0.15-0.03-0.03,0c-0.2-0.03-0.29,0.35-0.48,0.21l0.01,0.03
				c-0.03-0.01-0.08,0.02-0.1-0.03c-0.01,0.04,0.01,0.05,0.04,0.05c-0.07-0.01-0.08,0.04-0.14,0.05c0.05,0.04-0.07,0.27-0.13,0.02
				c-0.01,0.08-0.1,0.02-0.13,0.03l0.01-0.04c-0.01,0.02-0.02,0.03-0.03,0.04l0.05,0.04c-0.02,0-0.03,0.01-0.04-0.01
				c0.01,0.04,0.06,0.08,0.06,0.14c-0.03-0.01-0.04,0.06-0.09,0c0.07-0.01-0.05-0.06-0.01-0.1c-0.09,0.02-0.08-0.06-0.08,0.13
				c-0.02-0.04-0.06-0.06-0.06-0.05c0.02-0.01,0.05,0.06,0.05,0.1c-0.06-0.01-0.03,0.05-0.05,0.06c0.04,0.01,0.05,0,0.01,0.08
				c-0.02-0.03,0.01-0.03-0.01-0.04c0.01,0.08-0.11-0.03-0.12,0.04c-0.03-0.01-0.05-0.07-0.07-0.08c0.02,0.1-0.14-0.05-0.06,0.15
				l0.05-0.09c0,0.15,0.1,0.11-0.25,0.2c0.04,0.02,0.07,0.02-0.01,0.08c-0.03-0.04-0.05-0.05-0.08-0.07c0.02,0.03-0.05,0.03,0,0.12
				c-0.09-0.02-0.11-0.09-0.12,0.15c-0.01-0.06-0.06-0.07-0.04-0.12c-0.03,0.07,0-0.02-0.05,0.24c-0.17-0.01-0.01-0.07-0.74,0.52
				c-0.02-0.06,0-0.04-0.05-0.09c0.18,0.06-0.27-0.03-0.35,0.38c-0.02-0.08,0-0.14,0-0.2c-0.02,0.02-0.07,0.06-0.05,0.11
				c0.03-0.03,0.04-0.03-0.02,0.13c-0.11-0.16-0.14,0.05-0.26-0.05c-0.03,0.12-0.17,0.09-0.25,0.17c0.06,0.03,0.07,0.24-0.05,0.04
				c0.06,0.02,0.03,0.07,0.07,0.1c-0.05,0.01-0.13-0.02-0.24-0.12c0.04,0.04,0.03,0.07-0.01,0.08c0.08-0.02-0.06,0.14-0.08,0.03
				c-0.02,0.05-0.12,0.09-0.07,0.22c-0.02-0.01-0.05-0.04-0.04-0.06c-0.15,0.08-0.03,0.2-1.05,0.87c0-0.01,0.1,0.09-0.02,0.11
				l0.01,0c-0.08-0.06-0.1-0.01-0.13,0.02l0.08,0.1c-0.2-0.07-0.15,0.23-0.31,0.14c0,0.04-0.02,0.06-0.01,0.1
				c-0.07-0.04-0.04-0.08-0.19,0.07l-0.02-0.05c0.05,0.12-0.18,0.08-0.27,0.43c-0.07-0.07-0.07,0.05-0.07,0.11
				c-0.1-0.15,0.01,0.05-0.36,0.35c0.04-0.04,0.08-0.07,0.08-0.06c-0.01-0.09-0.31,0.04-0.2,0.22c-0.01,0,0.01,0,0.01-0.03l0,0.01
				c0.01-0.01,0.02-0.03,0.04-0.04c0.01,0.04,0.02,0.07-0.03,0.06c0.02,0.04,0.05,0.08,0.06,0.1c-0.11-0.18-0.18-0.03-0.65,0.54
				c0.02,0,0.02,0,0.04,0.01c-0.07,0.07-0.21,0.01-0.4,0.46l-0.06-0.04C711.31,158.5,710.83,158.72,710.82,159.05z M712.56,157.53
				c-0.02,0.01-0.04,0.05-0.06-0.01C712.51,157.53,712.53,157.52,712.56,157.53z M711.85,157.71c0.01-0.05,0.01-0.14,0.12-0.24
				c-0.01,0.07-0.03,0.34-0.09,0.18C711.87,157.67,711.86,157.69,711.85,157.71z"
                />
                <path
                  className="st10"
                  d="M738.61,194.02c-0.03-0.04-0.01-0.12-0.11-0.13c0.27-0.15,0.1,0,0.05-0.4c-0.01,0.01-0.02,0.02-0.03,0.02
				c0.01-0.1,0.15-0.15-0.02-0.6l0.08-0.01c-0.15,0.12,0.11-0.28-0.16-0.62c0,0,0,0.01,0.01,0.01c-0.07,0-0.17,0.02-0.32,0.04
				l0.07,0.05l-0.06,0.02l0-0.04c-0.05,0.02-0.01,0.04,0.03,0.06c-0.03,0-0.05,0.01-0.06,0.02l0.11,0.05
				c-0.05,0.01-0.09-0.02-0.12,0.01c0.05,0.02,0.1-0.02,0.14,0.02c-0.05,0.02-0.1,0.02-0.05,0.05c-0.03,0-0.05,0-0.06-0.01
				c0.04,0.03,0.01,0.07,0.08,0.08c-0.07,0.03,0.02,0.09-0.09,0.08c0.03,0.01,0.09,0.05,0.09,0.07c-0.13-0.05-0.01,0.09-0.15,0.08
				c0.12,0.03,0.05,0.12,0.12,0.18c-0.01,0-0.03-0.01-0.02-0.01c-0.01,0.03-0.05,0.04-0.07,0.06c0.1,0.01,0.12,0.06,0.18,0.08
				c-0.09,0.04-0.11-0.03-0.21-0.01c0.08,0,0.08,0.07,0.14,0.06c-0.06,0.03-0.03,0.03-0.11,0.04c0.12,0.01,0.02,0.03,0.1,0.05
				c-0.06,0.04-0.11,0.01-0.08,0.06c-0.15-0.07-0.01,0.22,0.21,0.36c0-0.01-0.02-0.01-0.04-0.02l0.03,0.02
				c-0.41-0.36-0.15,0.03-0.06,2.24l-0.09,0.03c0.15,0.09-0.12-0.07,0.06,0.23c-0.03,0-0.04-0.02-0.06-0.03
				c0.12,0.04-0.11,0.05,0.06,0.07c-0.04,0.03-0.04,0.09-0.02,0.14l-0.05-0.01c-0.01,0.08,0.04,0.17,0.04,0.26
				c0.02-0.03,0.11-0.03,0.13-0.01l-0.08,0.02c0.05,0.01,0.18,0.01-0.03,0.01l0.03,0.04c-0.02,0-0.04,0-0.07,0
				c0.05,0.01-0.08,0.08,0.01,0.1c-0.22-0.27,0.27,0.95,0.18,1.74c0.02,0,0.11-0.03,0.02,0.04c0.19-0.26-0.06,0.43,0.3,1.09
				c-0.02,0-0.04,0-0.05-0.01c0.01,0.02,0.04,0.13,0.22,0.49c-0.11,0.03-0.06,0.11,0,0.13c-0.04,0-0.03,0.04-0.08,0.04
				c0.01,0.04,0.04,0.07,0.1,0.04c-0.11,0.11,0.14,0.11-0.01,0.19c0.12,0.15,0.11,0.05,0.32,0.65c-0.01,0-0.02,0-0.04-0.01
				c0.01,0.01,0,0.04,0.05,0.02l-0.02-0.01c0.06-0.05,0.17,0.01,0.21,0c-0.15,0.03,0,0.09-0.19,0.12c0.01,0.01,0.04,0.01,0.07,0
				c-0.03,0.15,0,0.03,0.12,0.43c0-0.03,0.02,0.08,0.08,0c-0.04,0,0.14,0.09,0.03,0.08l0.05,0.06l0.01-0.06
				c0.09,0.04,0.13-0.05,0.04,0.19c0.08-0.02,0.06,0.08,0.15,0.06c-0.02,0-0.06,0.02-0.07,0.03l0.06-0.02
				c-0.02,0.06,0.03,0.03,0.05,0.19l-0.03-0.02l-0.02,0.04l0.05-0.02c-0.09,0.04,0.19,0.09,0.24,0.31c-0.01,0-0.07,0.03-0.06,0.02
				c0.02,0.06,0.1,0.09,0.18,0.14c-0.04-0.02-0.02,0.18,0.1,0.11c-0.01,0.12-0.12,0.24,0.34,0.27c-0.05,0-0.07,0.06-0.08,0.1
				c0.01-0.01,0.03-0.02,0.04-0.04c-0.09,0.12,0.1-0.04,0.06,0.04c0.02,0,0.14,0.04,0,0.06c0.1,0.07-0.02-0.04,0.07,0.19
				c0.15-0.16,0.01-0.01,0.22-0.03c-0.1,0.05-0.04-0.01-0.12,0.17c0.06-0.03,0.01,0.07,0.1,0.03c-0.01,0.02,0.02,0.02-0.02,0.07
				c0.16-0.22,0.02,0.18,0.45,0.01c0.01,0.05-0.15,0.07,0.02,0.12c-0.1,0.11,0.14-0.01-0.03,0.12c0.04,0,0.09-0.02,0.13-0.02
				c-0.01,0.04-0.05,0.05-0.08,0.06c0.01-0.01,0.07,0.02,0.13-0.06c-0.05-0.01-0.16-0.07-0.08-0.16c0.01,0.02-0.13-0.03-0.05-0.13
				c-0.06-0.08-0.1-0.12-0.24,0.03l0.08-0.18l-0.05,0.06c-0.01-0.01-0.01-0.02,0.01-0.04c-0.06,0.02-0.02,0.03-0.06,0.06
				c0.01-0.08-0.11-0.04-0.04-0.14c-0.05,0.03,0,0.06-0.06,0.06c0.11-0.11-0.13-0.02,0.03-0.15c-0.14,0.03,0-0.15-0.21-0.1
				c0.04-0.02-0.03,0-0.03-0.17c-0.07,0.06-0.1,0.01-0.13,0c0.04-0.04,0.06-0.05,0.09-0.05c-0.02-0.02-0.02-0.03-0.09-0.02
				l0.06-0.04c-0.03-0.03-0.12-0.01-0.12-0.09c-0.05,0.04-0.1,0.15-0.18,0.13c0.02-0.02,0.07-0.06,0.09-0.06
				c-0.01,0-0.02-0.09,0.04-0.07c-0.01-0.02,0-0.06,0.02-0.1c-0.02,0.02-0.14,0.07-0.03-0.05c-0.03,0.01-0.02-0.03-0.08,0.01
				c-0.01-0.01,0.02-0.03,0.03-0.05c-0.04,0.01,0,0-0.13-0.01c-0.09,0.12-0.02-0.18-0.26-0.49c0.05,0.03,0.03-0.15,0-0.03
				c0.03-0.18-0.33-0.3-0.18-0.47l-0.03,0.01c0.02-0.03-0.01-0.08,0.04-0.09c-0.04-0.02-0.05,0.01-0.05,0.04
				c0.01-0.07-0.03-0.08-0.03-0.14c-0.05,0.04-0.26-0.1,0-0.13c-0.07-0.02,0-0.1,0-0.13l0.03,0.02c-0.02-0.01-0.02-0.02-0.03-0.03
				l-0.05,0.04c0-0.02,0-0.03,0.02-0.04c-0.04,0-0.09,0.05-0.15,0.04c0.02-0.03-0.05-0.05,0.02-0.08c0,0.07,0.07-0.04,0.1,0.01
				c0-0.09,0.07-0.06-0.11-0.09c0.04-0.01,0.07-0.04,0.06-0.05c0.01,0.02-0.06,0.04-0.1,0.03c0.02-0.05-0.04-0.03-0.04-0.06
				c-0.02,0.03-0.01,0.05-0.08-0.01c0.03-0.01,0.03,0.02,0.04,0c-0.08,0,0.05-0.1-0.01-0.12c0.02-0.03,0.08-0.03,0.09-0.05
				c-0.1,0,0.08-0.12-0.13-0.09l0.07,0.07c-0.15-0.03-0.13,0.07-0.12-0.28c-0.03,0.03-0.04,0.07-0.07-0.03
				c0.05-0.01,0.06-0.03,0.09-0.05c-0.03,0.01-0.01-0.06-0.11-0.04c0.05-0.08,0.12-0.07-0.11-0.15c0.06,0,0.08-0.03,0.13,0
				c-0.06-0.05,0.02,0.01-0.22-0.12c0.07-0.15,0.05,0.01-0.2-0.87c0.07,0,0.04,0.02,0.1-0.01c-0.13,0.13,0.14-0.23-0.19-0.47
				c0.08,0.01,0.12,0.06,0.17,0.09c-0.01-0.03-0.02-0.09-0.07-0.09c0.01,0.04,0.01,0.05-0.11-0.08c0.19-0.02,0.02-0.15,0.16-0.21
				c-0.09-0.08,0-0.19-0.03-0.3c-0.03,0.05-0.23-0.05-0.05-0.05c-0.02,0-0.05-0.02-0.07-0.02c0.03-0.04,0.09-0.09,0.21-0.13
				c-0.05,0.01-0.08-0.01-0.06-0.04c-0.02,0.08-0.09-0.12,0.01-0.08c-0.03-0.04-0.02-0.15-0.14-0.17c0.02-0.01,0.06-0.02,0.07-0.01
				c0.04-0.17-0.18-0.12-0.1-1.33c0.01,0-0.13,0.02-0.08-0.08l0,0.01c0.1-0.02,0.07-0.07,0.07-0.11l-0.13,0
				c0.19-0.11-0.08-0.26,0.08-0.32c-0.03-0.02-0.04-0.05-0.07-0.08c0.07-0.03,0.09,0.02,0.07-0.19l0.05,0.02
				c-0.12-0.04,0.06-0.19-0.15-0.48c0.09,0,0.01-0.08-0.04-0.13c0.18,0.03-0.05-0.03-0.01-0.5c0,0.06,0,0.1-0.01,0.1
				c0.07,0.06,0.19-0.26-0.01-0.29c0.01-0.01,0,0.01,0.02,0.03l-0.01,0c0,0.02,0,0.04,0.01,0.06c-0.04-0.02-0.07-0.04-0.03-0.06
				c-0.05-0.01-0.09-0.03-0.11-0.03C738.61,194.23,738.8,194.33,738.61,194.02z M738.21,194.59c0.01-0.02-0.01-0.07,0.05-0.03
				C738.25,194.55,738.23,194.57,738.21,194.59z"
                />
                <path
                  className="st10"
                  d="M759.93,193.27c-0.15-0.88,0.08-0.92-1.36-1.93c0.03,0,0.05,0.01,0.07,0.01c-0.63-0.29-0.43-0.36-1.8-0.55
				c0-0.01,0.05-0.02,0.09-0.02c-0.22,0.02,0.03-0.01-0.44,0.17c0.14-0.13-0.38-0.08-0.15-0.24c-0.05,0-0.12,0.02-0.11,0.06
				c-0.07-0.02-0.1-0.04-0.02-0.07c-0.28,0.01-0.18,0.07-0.43,0.06c0-0.02,0.05-0.03,0.08-0.06c-0.13,0.04-0.26,0.08-0.47,0.05
				c0.01-0.01-0.01-0.03,0.05-0.03c-0.36,0-0.61,0.12-1.01,0.08c0.06-0.05,0.06-0.14-0.37-0.09l0.09,0.04
				c-0.19,0.01-0.35,0.08-0.51,0.06l0.14-0.05l-0.35,0.04l0.2,0.02c-0.18,0.06,0.03,0.12-0.22,0.11c0.05-0.01-0.18,0.01-0.28-0.03
				l0,0c-0.4,0.12-0.1-0.17-0.86,0.1c0.08-0.13-0.28-0.08-0.35,0.02c0.02-0.01,0.01-0.02,0.06-0.03c-0.33,0.05-0.46,0.04-0.57,0.03
				l0.08-0.02c-0.41,0.02,0.03-0.01-1.45,0.2l0.07,0c-0.43,0.15-0.28-0.14-1.38,0.13l0.05-0.04c-0.73,0.08-0.58,0-2.13,0.03
				c0.12-0.03,0.19-0.03-0.31-0.01c0.03,0.06,0.05,0.04,0.13,0.09c-0.05,0.03-0.17,0.05-0.26,0.04c0.18-0.03,0-0.09-0.07-0.13
				c0.01,0.02-0.02,0.04-0.01,0.05c-0.2-0.12-0.04,0.11-0.32,0.02c0.02-0.05,0.06-0.1-0.64-0.08c0.07-0.01,0.17,0,0.24-0.01
				c-0.15-0.1-0.17-0.08-0.28-0.1l0.01,0l-0.27-0.07l0.07,0.03c-0.22,0.15-0.41-0.02-0.69,0.06c0.13-0.01,0.22-0.2-0.45-0.22
				c0.08-0.01-1.02-0.04-2.39-0.1c0.11-0.1,0.11-0.14-0.59-0.02l0.02-0.03c-0.01,0.2-0.14-0.26-0.55-0.24c0.01,0,0.01,0,0.01,0
				c-0.04,0.06-0.09,0.14-0.17,0.27l0.08-0.02l-0.02,0.05l-0.03-0.02c-0.02,0.06,0.03,0.03,0.06,0.02c0,0.07-0.25,0.16,0.02,0.09
				c0,0,0,0,0,0.01c0.03-0.02,0-0.01,0.1-0.02c-0.01,0.02-0.03,0.03-0.05,0.04c0.04-0.01,0.06,0.03,0.11-0.01
				c-0.02,0.07,0.08,0.04,0,0.12c0.02-0.02,0.09-0.04,0.11-0.03c-0.12,0.08,0.06,0.06-0.04,0.16c0.1-0.07,0.12,0.03,0.2,0.01
				c-0.01,0.01-0.02,0.02-0.02,0.01c0.02,0.03,0,0.06,0,0.09c0.06-0.07,0.12-0.06,0.17-0.09c-0.03,0.1-0.09,0.08-0.14,0.15
				c0.05-0.06,0.1-0.02,0.13-0.08c-0.01,0.06,0,0.04-0.04,0.11c0.08-0.09,0.03,0,0.1-0.04c0,0.07-0.06,0.1,0,0.1
				c-0.15,0.08,0.16,0.14,0.4,0.04c-0.01-0.01-0.02,0.01-0.04,0.02l0.03-0.01c-0.08,0.11-0.12,0-0.03,0.14
				c-0.34-0.2-0.15,0.1,1.68,1.16l-0.03,0.09c0.15-0.07-0.12,0.06,0.21,0.08c-0.02,0.02-0.04,0.02-0.06,0.03
				c0.1-0.08-0.03,0.11,0.08-0.01c0,0.05,0.04,0.08,0.09,0.09c-0.03,0.09-0.05,0.03,0.19,0.15c-0.01-0.04,0.04-0.11,0.07-0.11
				l-0.03,0.07c0.04-0.04,0.11-0.14-0.01,0.03l0.05,0c-0.01,0.02-0.03,0.03-0.04,0.05c0.04-0.03,0.01,0.11,0.08,0.04
				c-0.34,0.02,0.88,0.32,1.4,0.85c0.01-0.01,0.04-0.1,0.04,0.01c-0.02-0.22,0.58,0.38,0.57,0.3c0.16-0.02,0.24,0.13,0.4,0.11
				c-0.02,0.01-0.03,0.04-0.04,0.03c0.02,0,0.12,0.05,0.48,0.13c-0.05,0.1,0.04,0.12,0.09,0.09c-0.02,0.03,0.01,0.04-0.02,0.08
				c0.03,0.01,0.07,0.01,0.09-0.05c0,0.15,0.17-0.04,0.13,0.13c0.18,0.01,0.11-0.06,0.65,0.19c0,0.01-0.02,0.02-0.03,0.02
				c0.02,0,0.03,0.03,0.05-0.02l-0.02,0.01c0.01-0.08,0.12-0.12,0.14-0.15c-0.08,0.12,0.06,0.07-0.06,0.22
				c0.01,0,0.04-0.02,0.05-0.05c0.07,0.12,0.02,0.02,0.35,0.21c-0.02-0.02,0.07,0.04,0.05-0.06c-0.03,0.03,0.15-0.03,0.07,0.03
				l0.07,0.01c-0.01-0.05-0.03-0.05,0.05-0.12c0.02,0.07,0.06,0.05,0.06,0.18c0.05-0.07,0.09,0.01,0.15-0.05
				c-0.01,0.02-0.03,0.06-0.03,0.07l0.04-0.05c0.01,0.06,0.04,0,0.14,0.12l-0.03,0l0.01,0.05l0.03-0.04
				c-0.05,0.09,0.21-0.05,0.35,0.1c-0.01,0.01-0.04,0.06-0.03,0.05c0.04,0.04,0.12,0.01,0.21,0c-0.04,0,0.07,0.17,0.13,0.04
				c0.05,0.1,0,0.26,0.4,0.06c-0.05,0.02-0.03,0.09-0.03,0.12c0-0.02,0.02-0.03,0.02-0.05c-0.03,0.15,0.07-0.08,0.07,0.01
				c0.02-0.01,0.13-0.03,0.03,0.05c0.11,0.01-0.03-0.02,0.13,0.13c0.07-0.2,0-0.01,0.18-0.12c-0.07,0.09-0.03,0-0.05,0.2
				c0.05-0.05,0.03,0.06,0.09-0.01c-0.01,0.02,0.03,0.01,0,0.07c0.07-0.25,0.06,0.14,0.4-0.14c0.02,0.04-0.12,0.11,0.04,0.11
				c-0.07,0.13,0.12-0.05,0,0.12c0.04-0.01,0.08-0.05,0.11-0.06c-0.01,0.04-0.04,0.06-0.06,0.08c0-0.02,0.07,0,0.1-0.09
				c-0.05,0-0.15-0.03-0.1-0.13c0.01,0.02-0.13,0-0.08-0.11c-0.08-0.06-0.12-0.09-0.21,0.1l0.02-0.19l-0.03,0.07
				c-0.01-0.01-0.02-0.02-0.01-0.04c-0.04,0.04-0.01,0.03-0.04,0.07c-0.01-0.08-0.11,0-0.08-0.12c-0.03,0.05,0.02,0.05-0.03,0.08
				c0.07-0.14-0.12,0.03-0.03-0.15c-0.12,0.09-0.06-0.14-0.22-0.01c0.03-0.03-0.03,0.01-0.1-0.14c-0.04,0.08-0.08,0.05-0.12,0.06
				c0.02-0.05,0.03-0.07,0.06-0.09c-0.03,0-0.03-0.01-0.09,0.03l0.03-0.06c-0.04-0.01-0.11,0.05-0.15-0.01
				c-0.03,0.06-0.02,0.18-0.09,0.2c0.01-0.03,0.03-0.09,0.05-0.1c-0.01,0-0.06-0.07,0-0.08c-0.02-0.02-0.03-0.05-0.03-0.09
				c-0.01,0.02-0.08,0.13-0.05-0.03c-0.02,0.03-0.04-0.01-0.06,0.05c-0.01,0,0-0.04,0-0.06c-0.03,0.03,0,0-0.11,0.06l0,0
				c-0.01,0.15-0.12-0.14-0.47-0.24c0.06,0-0.06-0.14-0.02-0.02c-0.15-0.19-0.37,0.04-0.47-0.31c-0.04,0.01-0.03,0.04-0.02,0.06
				c-0.03-0.06-0.07-0.03-0.11-0.08c-0.01,0.07-0.25,0.1-0.09-0.1c-0.07,0.03-0.07-0.07-0.09-0.09l0.03-0.01
				c-0.02,0.01-0.03,0-0.04,0l-0.01,0.06c-0.01-0.02-0.02-0.02-0.01-0.04c-0.03,0.03-0.03,0.1-0.08,0.13
				c-0.01-0.04-0.07,0.01-0.04-0.07c0.05,0.05,0.03-0.08,0.08-0.07c-0.06-0.07,0.01-0.09-0.14,0.01c0.03-0.04,0.02-0.08,0.01-0.08
				c0.02,0.01-0.02,0.07-0.05,0.09c-0.02-0.05-0.05,0.01-0.07-0.01c0.01,0.04,0.03,0.04-0.06,0.05c0.01-0.03,0.03-0.01,0.03-0.03
				c-0.06,0.06-0.03-0.1-0.09-0.07c-0.01-0.04,0.03-0.08,0.02-0.1c-0.07,0.07-0.03-0.14-0.15,0.03l0.09,0
				c-0.12,0.08-0.04,0.14-0.27-0.1c0,0.04,0.02,0.07-0.07,0.03c0.02-0.04,0.02-0.07,0.02-0.1c-0.01,0.03-0.05-0.03-0.1,0.06
				c-0.02-0.08,0.03-0.13-0.18-0.02c0.04-0.04,0.03-0.08,0.08-0.1c-0.07,0.01,0.02-0.01-0.23,0.09c-0.06-0.15,0.04-0.04-0.75-0.39
				c0.04-0.05,0.04-0.02,0.06-0.08c0.02,0.18-0.09-0.25-0.46-0.13c0.06-0.05,0.12-0.06,0.17-0.08c-0.02-0.01-0.08-0.03-0.11,0
				c0.03,0.01,0.04,0.02-0.12,0.04c0.1-0.16-0.09-0.1-0.05-0.25c-0.11,0.02-0.14-0.11-0.24-0.15c-0.01,0.07-0.19,0.16-0.05-0.02
				c0,0.06-0.05,0.06-0.07,0.11c-0.02-0.04-0.02-0.13,0.02-0.26c-0.02,0.05-0.05,0.06-0.07,0.03c0.05,0.06-0.15,0-0.05-0.06
				c-0.05,0.01-0.12-0.07-0.22,0.02c0-0.02,0.02-0.06,0.04-0.06c-0.1-0.13-0.2,0.08-1.06-0.67c0.01-0.01-0.06,0.12-0.11,0.02
				l0.01,0.01c0.04-0.09-0.01-0.09-0.04-0.12l-0.08,0.11c0.03-0.21-0.25-0.08-0.19-0.25c-0.04,0.01-0.06,0-0.1,0.01
				c0.02-0.07,0.07-0.06-0.1-0.16l0.04-0.03c-0.09,0.07-0.11-0.15-0.44-0.15c0.05-0.08-0.06-0.05-0.12-0.04
				c0.13-0.13-0.05,0.03-0.38-0.27c0.04,0.03,0.07,0.06,0.07,0.06c0.08-0.01-0.08-0.3-0.23-0.16c0-0.01,0,0,0.03,0l0,0
				c0.01,0.01,0.03,0.02,0.05,0.03c-0.03,0.02-0.07,0.03-0.06-0.01c-0.04,0.03-0.07,0.06-0.09,0.07c-0.03-0.07,0.06-0.23-0.31-0.27
				c0.04-0.3,0.07-0.08-0.26-0.27c0,0.02,0,0.02-0.01,0.04c0.2,0.05-0.74-0.35-0.17-0.34l-0.11,0.02c0.11,0.13,0.43-0.03,0.64-0.02
				c-0.03,0.08-0.07,0.13,0.51,0.15c-0.03,0.02,0.02,0.07-0.07,0.05c0.26,0.07,0.43-0.05,0.53,0.04l-0.02,0
				c0.42,0.04,1,0.2,2.62,0.29c-0.02,0.03,0.44,0.1,0.66,0.15l-0.01,0.01c0.05-0.09,0.28-0.05,0.45-0.06
				c-0.01,0.05-0.04,0.07-0.13,0.09c0.02-0.02,0.02,0.05,0.35-0.03c-0.17,0.23,0.11-0.04,0.81-0.08l-0.11,0.06
				c0.05-0.02,0.37,0,0.1,0.03c0.51,0.04,0.22,0.03,1.22-0.06l0,0c0.22-0.12,0.95,0.04,2.23-0.17c-0.16,0.06,0.06,0.03-0.16,0.05
				c0.21,0,0.26,0.03,0.48-0.06c-0.07,0.07,0.16,0.06,0.26,0.03l-0.15-0.03c0.11-0.02,0.21-0.03,0.31-0.03l-0.04,0.09
				c0.45,0,0.94-0.1,1.93-0.26c-0.2-0.19,0.51,0.17,0.4,0.01c0.25,0.03,0.39-0.02,0.65,0c-0.04-0.05,0.73-0.24,0.56,0.04
				c0.23-0.05,0.43,0.04,0.56,0.06l-0.12,0.02c0.07-0.01,0.13-0.01,0.19-0.01l-0.07-0.06c0.07,0.01,0.13,0.02,0.13,0.04
				c0.15-0.09,0-0.2,0.42-0.08c-0.29-0.04,0,0.07-0.21,0.08c0.28,0.06,0.15,0.1,0.56-0.02c-0.04,0.04,0.06,0.09,0.11,0.09
				c-0.09-0.01,0.03-0.12,0.37-0.09l-0.11-0.03c0.2-0.09,0.49,0.09,1.21,0.43c-0.04-0.08-0.06-0.07,0.12-0.06
				c-0.12-0.04-0.25-0.07-0.37-0.09l0.26,0c-0.11-0.04-0.25-0.05-0.31-0.08c0.13-0.01,0.11,0,0.07-0.07
				c0.51,0.23,0.19,0.27,1.1,0.74l-0.06-0.09c0.08-0.01,0.16,0.14,0.25,0.21c-0.04,0.02-0.02,0.09-0.02,0.14
				c0.03-0.03,0.13,0.2,0.23,0.19c0.04,0.33-0.03,0.16,0.27,0.71c-0.06-0.08-0.08,0.05-0.14-0.12c0.01,0.09,0.01,0.17,0.05,0.17
				c-0.04-0.04,0.04,0.1,0.02,0.35c0.01-0.03,0.01-0.01,0.01-0.08c0.04,0.04-0.05,0.14,0.11,0.31c-0.13,0.12-0.11,0.28-0.33,0.73
				c0.01-0.06-0.06-0.1-0.08,0l0.08,0c-0.03,0.06,0.01,0.01-0.13,0.24c0,0,0.01,0,0.01,0c-0.08,0.15-0.18,0.31-0.32,0.52
				c0-0.02-0.02-0.02,0.01-0.04c-0.42,0.46,0.02,0.03-0.74,0.55l0.03-0.03c-0.24,0.05,0,0.11-1.17,0.68c0-0.03,0-0.05,0-0.06
				c-0.02,0.06-0.1,0.16-0.38,0.04l0.2-0.05c-0.12-0.06-0.25,0.06-0.36,0.06l0.01-0.04c-0.28,0.06-0.29,0.14-0.58,0.17l0.05,0.27
				c0.01,0,0.03-0.01,0.05-0.01l-0.04-0.02c0.22-0.12,0.12-0.03,0.73,0.15c-0.12,0,0,0-0.31-0.03c0.1,0.02,0.31,0.12,0.39,0.12
				c-0.04-0.02-0.1-0.03-0.13-0.06c0.15-0.01,0.33,0.08,0.48,0.27c-0.34,0.16,0.64,0.35-0.29,1.76c0.22-0.03-0.08,0.1,0.05,0.18
				c-0.09,0.06-0.12,0.18-0.21-0.01c0.15-0.05-0.1,0.2,0.08,0.12c-0.14,0.09-0.26,0.07-0.53,0.07c0.05,0.03-0.03,0.11-0.15,0.15
				c0.06-0.01,0.13-0.01,0.19-0.02c-0.25,0.22-0.29,0.01-0.57,0.18l0.08-0.07c-0.8,0.07-0.66,0.35-2.24-0.32c0.01,0,0.03,0,0.04,0
				c-0.15-0.04-0.66-0.25-0.47,0.28c-0.23,0.03-0.27-0.05,0.01,0.56l-0.02-0.02c-0.6,1.1,0.85,3.2-3.56,2.04l0.09-0.01
				c-0.67-0.17-0.31-0.03-1.42-0.74c0.01-0.01,0.04-0.03,0.09,0c-0.14-0.1-0.51-0.44-0.54-0.28c0.05-0.08-0.3-0.23-0.49-0.32
				l0.07-0.04c-0.03,0.01-0.24-0.09-0.68-0.36l0.09,0.02c-0.23-0.19-0.25,0.04-0.52-0.43c0.07,0.05-0.28-0.09-0.17-0.11
				c-0.19-0.28-0.04,0.07-0.53-0.51c0.18,0.17,0.34,0.31,0.32,0.3c0.04-0.05-0.11-0.19,0.08-0.15c-0.14-0.09-0.03,0.04-0.56-0.37
				l0.02,0.01c-0.21-0.18-0.1-0.13-0.43-0.46c-0.04,0.06-0.13-0.05-0.08,0.08c-0.02-0.02,0.03,0.03,0.11,0.08l-0.01,0
				c0.05,0.05,0.11,0.11,0.19,0.17c-0.6-0.36-0.29-0.41-1.38-1.36c0.21,0.13,0.18,0.15-0.41-0.45l0.05,0.01
				c-0.28-0.55-0.82-0.6-1.08-1.26c0.18,0.29,0.06,0.09-1.76-2.79c0.11,0.03,0.22,0.05-0.3-0.53l0.03,0.1
				c0.03-0.03,0.16-0.03,0.12,0.18l-0.06-0.08c-0.02,0.03-0.01,0.07,0,0.1c-0.04-0.07-0.08-0.14-0.11-0.22l-0.02,0.07
				c-0.03-0.07-0.1-0.22-0.26-0.61c0.19,0.61-0.3-1.15-0.59-0.19l-0.09-0.18c0,0.3,0.57,0.71,0.37,0.8
				c0.09,0.04-0.02,0.27,0.58,0.92c-0.21-0.11,0.19,0.36,0.05,0.38c0.18,0.07,0.33,0.43,0.52,0.62c-0.02-0.01-0.05-0.02-0.05-0.04
				c0.07,0.13,0.06,0.19,0.1,0.28c-0.1-0.15,0.67,0.38,0.16,0.28c0.06-0.05,0.24,0.22,0.26,0.13c0.03,0.15,0.04,0.12,0.02,0.22
				c0.04-0.16,0.53,0.56,0.2,0.38c0.38,0.27,0.35,0.14,0.88,1.18l-0.02,0c0.1-0.01,0.19,0.19,0.28,0.31
				c-0.05,0.02-0.08,0.01-0.15-0.05c0.02,0.01-0.03,0.04,0.2,0.25c-0.28-0.01,0.09,0.06,0.44,0.61l-0.1-0.06
				c0.04,0.03,0.17,0.3,0.02,0.09c0.18,0.43,0.08,0.18,0.57,0.98l0,0c0.22,0.26,0,0,0.47,0.9c-0.1,0.04,0.15,0.28,0.46,0.96
				c-0.1-0.12-0.01,0.06-0.09-0.12c0.06,0.18,0.05,0.24,0.2,0.4c-0.09-0.04-0.01,0.16,0.05,0.24l-0.02-0.14
				c0.05,0.09,0.08,0.17,0.12,0.26l-0.1-0.01c0.13,0.4,0.35,0.8,0.73,1.64c0.14-0.23-0.05,0.5,0.08,0.36
				c0.03,0.23,0.1,0.35,0.13,0.58c0.04-0.05,0.38,0.62,0.06,0.51c0.1,0.2,0.04,0.4,0.04,0.52l-0.04-0.11
				c0.02,0.06,0.03,0.12,0.04,0.17l0.05-0.07c0,0.07,0.01,0.12-0.02,0.12c0.11,0.12,0.21-0.04,0.15,0.37c0.01-0.35-0.32-0.13,0,0.33
				c-0.05-0.03-0.08,0.07-0.07,0.11c-0.01-0.08,0.12,0,0.13,0.33l0.02-0.11c0.13,0.19-0.03,0.46-0.21,1.2
				c0.07-0.05,0.06-0.07,0.09,0.1l0.01-0.37l0.05,0.24c0.02-0.11,0-0.24,0.01-0.3c0.04,0.12,0.02,0.1,0.08,0.06
				c-0.11,0.53-0.27,0.22-0.38,1.23l0.06-0.1c0.04,0.06-0.05,0.2-0.08,0.31c-0.04-0.03-0.08,0.03-0.13,0.06
				c0.04,0.01-0.1,0.22-0.02,0.29c-0.22,0.24-0.15,0.08-0.32,0.66c0-0.1-0.1-0.02-0.03-0.18c-0.05,0.07-0.1,0.13-0.07,0.15
				c-0.13-0.1,0.38,0.22-0.88,0.89c0.04-0.05,0.01-0.13-0.07-0.05l0.06,0.05c-0.05,0.02-0.02,0.03-0.23,0.11c0.01,0,0.01,0,0.02,0
				c-0.14,0.07-0.31,0.14-0.52,0.22c0.01-0.02,0-0.02,0.03-0.03c-0.56,0.1,0,0.05-0.83-0.03l0.04,0c-0.19-0.13-0.06,0.12-1.19-0.3
				c0.02-0.02,0.04-0.03,0.05-0.04c-0.05,0.03-0.18,0.04-0.25-0.24l0.15,0.11c-0.02-0.13-0.19-0.14-0.25-0.22l0.03-0.02
				c-0.21-0.18-0.29-0.11-0.48-0.36l0,0.04c-0.02,0-0.99-1.65-0.19-0.5c-0.09-0.16-0.29-0.61-0.33-0.61c0.03,0.05,0.08,0.1,0.1,0.21
				c-0.65-0.59-0.14-0.74-1.21,1.65c0.19-0.11-0.04,0.13,0.11,0.15c-0.05,0.1-0.02,0.22-0.19,0.09c0.11-0.12,0,0.24,0.13,0.07
				c-0.07,0.14-0.18,0.2-0.4,0.27c0.06,0.01,0.03,0.1-0.03,0.12c0.04,0,0.08-0.01,0.13-0.04c-0.1,0.23-0.19,0.03-0.31,0.13
				c0.01-0.01,0.02-0.02,0.02-0.03c-0.53-0.21-0.43,0.01-1.2-0.99l0.05,0.01c-0.14-0.16-0.01-0.11-0.29-0.44l0.08,0.06
				c-0.14-0.37-0.36-0.49-0.51-0.79c0.1-0.04,0.03-0.34-0.01-0.49l0.02,0c0.06-0.94-0.43,0.18-0.89-4.86l0.07,0
				c0.01-0.28,0.02-0.42,0.01-0.77l0.05,0.07c-0.17-0.64-0.1-0.27-0.07-1.52c0.01,0.01,0.05,0.02,0.05,0.07
				c0.01-0.16,0.11-0.62-0.04-0.57c0.1,0,0.03-0.35,0.01-0.54l0.07,0.03c-0.02-0.01-0.05-0.23-0.06-0.71l0.04,0.08
				c0.03-0.28-0.16-0.18,0.08-0.62c0,0.09-0.08-0.27,0-0.19c0.12-0.29-0.08,0,0.12-0.67c-0.04,0.23-0.07,0.42-0.07,0.4
				c0.06,0.01,0.1-0.18,0.17-0.02c0-0.16-0.05,0-0.01-0.63l0,0.02c0.02-0.26,0.06-0.14,0.13-0.57c-0.07,0-0.04-0.13-0.12-0.02
				c0.01-0.03-0.01,0.04,0,0.12l-0.01-0.01c-0.01,0.06-0.02,0.14-0.04,0.23c-0.05-0.66,0.18-0.43,0.31-1.78
				c0.02,0.23-0.01,0.22,0.12-0.56l0.02,0.04c0.27-0.51-0.01-0.95,0.35-1.51c-0.02,0.04-0.03,0.06-0.05,0.06
				c0.24-0.23,0.39-1.73,1.22-3.46l-0.07,0.03c0.45-0.84-0.15,0.14,0.12-1.27c-0.22,0.18-0.15,0.84-0.34,0.74
				c0.03,0.09-0.22,0.15-0.31,0.96c-0.06-0.22-0.14,0.35-0.26,0.26c0.06,0.17-0.12,0.48-0.14,0.74c0-0.02-0.01-0.05,0-0.07
				c-0.05,0.13-0.1,0.15-0.15,0.23c0.05-0.15,0.13,0.72-0.12,0.27c0.08,0.02-0.01,0.3,0.07,0.27c-0.09,0.1-0.06,0.1-0.16,0.13
				c0.15-0.06-0.11,0.71-0.18,0.36c0.1,0.55-0.06-0.01-0.23,1.43c0.1,0.01,0.17-0.02,0.08,0.2c-0.47,0.07-0.13-0.11-0.47,1.66
				l-0.08-0.09c0.03,0.87-0.43,1.57-0.53,4.31l-0.02-0.06c-0.04,0.23,0.13,0.27,0.05,0.48l-0.01-0.04c0.05,0.25-0.26,0.04-0.01,4.14
				c-0.03-0.01-0.05-0.08-0.08-0.14c0.1,0.24-0.08,0.05,0.02,0.27c0.01-0.03,0.05,0.01,0.06,0.02c-0.04,0.12-0.01,0.38,0.04,0.57
				l-0.06-0.03c0.03,0.35,0.14,0.72,0.22,1.11c0.25,1.98,1.33,1.12,5.44,3.36l-0.14-0.01c0.55,0.34,1.16,0.75,1.83,0.73
				c-0.04,0.02-0.04,0.04-0.11,0.03c0.9-0.06,0.16,0.08,2.2-0.77c0.06,0.1,0.37-0.22,0.43-0.37c-0.01,0.06,0.11-0.08,0.11,0
				c0.11-0.13,0.18-0.28,0.12-0.29c0.35-0.41,0.44-0.26,0.79-2.02c0.02,0.06,0.03,0.1,0.03,0.14c0.01-0.34,0.18-0.36,0.14-1.8
				c0.01,0,0.02,0.04,0.03,0.08c-0.06-0.2,0.02,0.02-0.22-0.38c0.16,0.11,0.01-0.36,0.21-0.18c-0.01-0.05-0.04-0.1-0.08-0.09
				c0-0.07,0.02-0.1,0.07-0.03c-0.05-0.26-0.09-0.16-0.12-0.39c0.02,0,0.04,0.04,0.07,0.06c-0.07-0.11-0.13-0.22-0.14-0.42
				c0.02,0.01,0.03-0.02,0.04,0.04c-0.06-0.34-0.23-0.54-0.27-0.91c0.07,0.05,0.15,0.03,0.01-0.36l-0.02,0.09
				c-0.04-0.17-0.15-0.3-0.17-0.45l0.08,0.11l-0.12-0.3l0.02,0.19c-0.1-0.15-0.11,0.05-0.15-0.17c0.02,0.04-0.05-0.16-0.03-0.27l0,0
				c-0.22-0.35,0.15-0.13-0.3-0.75c0.15,0.04,0.01-0.28-0.11-0.31c0.01,0.02,0.02,0.01,0.04,0.04c-0.14-0.28-0.17-0.4-0.19-0.51
				l0.04,0.07c-0.13-0.36,0.03,0.02-0.63-1.22l0.02,0.07c-0.29-0.32,0.05-0.3-0.62-1.15l0.05,0.03c-0.18-0.19-0.12-0.34-0.88-1.56
				c0.23,0.19,0.54,0.42,0.86,0.77l-0.13,0.01c0.79,0.51,1.12,1.37,3.55,3.04l-0.06-0.02c0.18,0.17,0.31,0.04,0.47,0.23l-0.04-0.01
				c0.18,0.07,1.47,1.25,2.78,1.45l-0.01,0.08c0-0.04,0.16,0,0.46,0.07c-0.11-0.26,0.07,0.25,0.99-0.07
				c-0.01,0.03-0.09,0.06-0.16,0.09c0.27-0.14,0.07,0.08,0.32-0.06c-0.04-0.01,0.01-0.05,0.01-0.07c0.14,0.02,0.44-0.09,0.62-0.22
				l-0.01,0.07c0.82-0.95,1.35-0.31,0.79-3.23c1.29,0.57,1.65,1.18,4.34-1.53l-0.05,0.02c0.2-0.8,0.26-0.14-0.89-1.37
				c0.57-0.18,1.18-0.35,1.64-0.75c-0.02,0.04-0.01,0.06-0.07,0.1c0.71-0.64,0.15-0.05,1.4-2.03c0.11,0.05,0.19-0.43,0.13-0.6
				c0.03,0.06,0.04-0.14,0.09-0.08C760.02,193.4,759.98,193.23,759.93,193.27z M740.3,190.93c-0.01,0-0.02,0-0.03,0
				c0.01-0.01,0.02-0.03,0.04-0.03C740.31,190.92,740.3,190.92,740.3,190.93z M740.18,190.95l0.1-0.05
				C740.26,190.91,740.29,190.94,740.18,190.95z M741.97,191.62c0.01-0.01,0.02-0.02,0.03-0.03
				C742.01,191.59,742.01,191.62,741.97,191.62z M741.86,192.04c-0.01,0.01,0,0.03-0.01,0.06
				C741.85,192.08,741.81,192.07,741.86,192.04z M744.97,198.92c0.06,0,0.06-0.01,0.1,0.08
				C745.05,198.98,745.08,198.98,744.97,198.92z M736.19,197.91c0.03,0.04,0.05,0.04,0,0.12C736.18,198,736.2,198.03,736.19,197.91z
				 M735.68,198.21c0,0.02,0,0.05-0.01,0.1C735.67,198.27,735.67,198.31,735.68,198.21z M735.61,198.71
				c0.01,0.04-0.01-0.24,0.07-0.13C735.67,198.56,735.64,198.66,735.61,198.71z M742.89,197.39c-0.03-0.04-0.05-0.07-0.09-0.14
				c-0.03,0.09,0.03,0.13-0.1-0.12C742.83,197.26,742.98,197.41,742.89,197.39z M740.59,193.56c-0.02-0.04-0.05-0.08-0.07-0.13
				C740.58,193.48,740.67,193.55,740.59,193.56z M740.34,193.09c0.07,0.11,0.2,0.22,0.26,0.33
				C740.44,193.35,740.51,193.43,740.34,193.09z M742.49,196.96c0.07-0.08,0.08-0.15,0.22,0.07c-0.04-0.05-0.06-0.04-0.09-0.05
				C742.66,196.99,742.58,197.05,742.49,196.96z M742.66,197.11c-0.01,0-0.02,0-0.02,0.01c0-0.02-0.01-0.02-0.01-0.04
				C742.64,197.09,742.65,197.1,742.66,197.11z M742.62,197.22c0.01,0.03,0.04-0.01,0.04,0.06
				C742.64,197.26,742.63,197.24,742.62,197.22z M744.95,199.53c0.02,0.01,0.04,0.03,0.08,0.07
				C745,199.57,745.04,199.6,744.95,199.53z M745.29,199.76c-0.03,0,0.04,0.08,0.07,0.14C745.4,199.91,745.14,199.76,745.29,199.76z
				 M756.78,197.07c-0.05-0.01-0.12-0.03-0.18-0.04C756.67,197.03,756.6,197.03,756.78,197.07z"
                />
                <path
                  className="st10"
                  d="M748.5,177.75c-0.07,0.2-0.06,0.4-0.14,0.57l0.01-0.16l-0.09,0.39l0.09-0.21
				c-0.01,0.22,0.12,0.01,0.02,0.27c0-0.06-0.05,0.2-0.12,0.3l0,0c0,0.47-0.21,0.08-0.12,0.98c-0.11-0.12-0.14,0.3-0.04,0.41
				c0-0.03-0.01-0.02-0.02-0.07c-0.01,0.38-0.03,0.53-0.06,0.65l-0.01-0.1c-0.04,0.46,0-0.04-0.1,1.64l0.03-0.08
				c-0.02,0.5-0.19,0.26-0.48,1.48l-0.01-0.07c-0.37,0.75-0.3,0.58-1.31,2.02c0.03-0.1,0.06-0.12-0.22,0.27
				c0.07,0.01,0.06-0.02,0.16-0.06c-0.01,0.07-0.07,0.18-0.15,0.27c0.1-0.18-0.07-0.06-0.15-0.02c0.02,0,0.02,0.04,0.04,0.04
				c-0.24,0.1,0.06,0.11-0.21,0.29l-0.01-0.03c-0.04,0.06-0.32,0.45-0.18,0.13c-0.13,0.13-0.17,0.34-0.34,0.4
				c0.05-0.07,0.12-0.14,0.17-0.21c-0.18,0.07-0.18,0.1-0.29,0.17l0.01-0.01l-0.25,0.17l0.07-0.03c-0.06,0.29-0.34,0.32-0.51,0.6
				c0.17-0.27,0-0.08-0.03-0.16c-0.07,0.03-0.18,0.08-0.17,0.04c-0.08,0.13-0.22,0.1-0.34,0.29c-0.01-0.01-0.07,0.03-0.11,0.03
				c0.02,0.08-0.55,0.41-1.03,0.67c0-0.01-0.01-0.02-0.01-0.04c-0.03,0.02-0.05,0.16-0.25,0.12c0,0.12-0.05,0.01-0.26,0.19
				c0-0.01-0.01,0,0-0.02c-0.17,0.04-0.18,0.05-0.65,0.17c0.21-0.2-0.39,0.25-0.27,0.04c-0.57,0.04-0.63,0.4-1.68,0.49
				c0.26,0.18,1.04,0.01,0.98,0.22c0.1-0.05,0.23,0.18,1.23,0.11c-0.24,0.11,0.46,0.05,0.39,0.19c0.18-0.11,0.61,0.02,0.91-0.04
				c-0.02,0.01-0.05,0.03-0.08,0.02c-0.02,0.21,0.93-0.27,0.69,0.12c-0.01-0.08,0.36-0.06,0.29-0.13c0.16,0.06,0.13,0.04,0.22,0.12
				c-0.12-0.13,0.87-0.12,0.48,0.06c0.62-0.29,0,0.08,1.75-0.25c-0.03-0.12-0.08-0.15,0.21-0.14c0.26,0.41-0.07,0.17,2.1-0.21
				l-0.07,0.11c1-0.42,2.02-0.21,5.08-1.6l-0.06,0.05c0.28-0.08,0.23-0.26,0.5-0.32l-0.04,0.03c0.21-0.1,1.89-0.78,3.09-1.71
				l0.05,0.06c-0.02-0.02,0.11-0.14,0.4-0.3c-0.24-0.1,0.14,0.1,0.83-0.64c0.01,0.03-0.06,0.1-0.11,0.16
				c0.39-0.39,0.07-0.05,0.8-0.71l0,0.07c0.33-0.29,0.63-0.69,0.89-1.14c0.07,0.53,0.99-4.96-2.72-3.5c0.13-0.35,0.3-0.88,0.53-1.69
				l-0.05,0.03c-0.2-0.8,0.12,0.08-2.41-3.09l0.16-0.04c-0.76-0.15-1.64-0.03-2.24,0.46c0.23,0.15-1.39-3.23-3.33-0.84
				c-0.01-0.01,0.02-0.05,0.05-0.09c-0.15,0.25,0.11-0.16-0.15,0.51c-0.03-0.21-0.29,0.34-0.3,0.04c-0.03,0.05-0.05,0.13-0.01,0.14
				c-0.06,0.07-0.09,0.08-0.07-0.02c-0.15,0.28-0.05,0.21-0.19,0.46c-0.02-0.01,0-0.07-0.01-0.11c-0.03,0.15-0.07,0.3-0.2,0.5
				c-0.01-0.02-0.04,0,0-0.07c-0.19,0.37-0.2,0.69-0.41,1.09c-0.02-0.09-0.1-0.13-0.24,0.36L748.5,177.75z M743.51,188.34
				c-0.03,0.03,0.03,0.03-0.06,0.11c0.01,0,0.02,0,0.03,0c-0.01,0.02-0.05,0.04-0.14,0.06
				C743.45,188.34,743.43,188.36,743.51,188.34z M743.31,188.5c-0.02,0-0.04,0.01-0.07,0.01
				C743.25,188.51,743.28,188.5,743.31,188.5z M743.03,188.6c-0.01,0.01-0.02,0.03-0.02,0.04L743,188.6
				C743.01,188.6,743.02,188.6,743.03,188.6z M742.99,188.6c0,0.05-0.03,0-0.13,0.12C742.83,188.65,742.76,188.59,742.99,188.6z
				 M741.37,189.43c0.02,0.03,0.01,0.05,0.02,0.07c-0.01,0-0.03,0-0.04-0.01C741.36,189.47,741.37,189.45,741.37,189.43z
				 M741.43,189.48c0.01,0.02,0.02,0.02,0.02,0.03C741.45,189.5,741.41,189.52,741.43,189.48z M741.52,189.48
				c0.02,0.02,0.03,0.04,0.03,0.05c-0.02,0-0.04-0.01-0.06-0.01C741.5,189.51,741.51,189.52,741.52,189.48z M741.64,189.55
				c-0.01,0-0.03-0.01-0.04-0.01C741.6,189.48,741.6,189.44,741.64,189.55z M742.71,189.14c-0.02,0-0.06,0.02-0.04-0.04
				C742.66,189.12,742.69,189.13,742.71,189.14z M742.4,188.72c0.01-0.01,0,0,0,0.04C742.4,188.74,742.4,188.73,742.4,188.72z
				 M740.99,189.23c-0.03,0.01-0.03,0-0.2,0.03C740.81,189.22,740.87,189.2,740.99,189.23z M750.34,189.25
				c0.02-0.01,0.06-0.02,0.12-0.04C750.41,189.23,750.46,189.21,750.34,189.25z M750.76,189.07c-0.02,0.02,0.09,0,0.17,0
				C750.97,189.04,750.68,189.2,750.76,189.07z M756.45,181.38C756.45,181.38,756.45,181.38,756.45,181.38
				c0-0.01,0.01-0.02,0.03-0.04L756.45,181.38z M756.4,180.65c0,0.01,0.01-0.02,0.02-0.02C756.42,180.64,756.41,180.65,756.4,180.65
				z M751.56,174.56c-0.13,0.03-0.25,0.08-0.37,0.15C751.25,174.71,751.24,174.54,751.56,174.56z M751.11,174.59
				c0.12-0.01,0.19-0.02,0.26-0.03c-0.11,0.03-0.21,0.11-0.28,0.13C751.19,174.59,751.19,174.61,751.11,174.59z M748.65,178.54
				c-0.05,0.26,0.31-0.53,0.1-0.22c0.12-0.26,0.13-0.42,0.26-0.68c-0.07,0.01,0.11-0.85,0.29-0.55c0.06-0.26,0.25-0.42,0.33-0.54
				l-0.04,0.13c0.03-0.08,0.06-0.14,0.09-0.2l-0.09,0.04c0.05-0.07,0.08-0.12,0.1-0.11c-0.01-0.2-0.17-0.1,0.15-0.45
				c-0.26,0.35,0.14,0.33,0.25-0.32c0.01,0.06,0.11-0.01,0.14-0.05c-0.06,0.08-0.09-0.1,0.16-0.38l-0.1,0.08
				c0.19,0.2-0.12-0.88,2.1-0.42c-0.02-0.04-0.04-0.07-0.07-0.1c-0.16,0.49,1.14,0.08-0.11,2.97l-0.02-0.01
				c0.14,0.23-0.01,0,0.51,0.12c0-0.01,0-0.02,0-0.04c0,0-0.01,0-0.02,0.01c0.06-0.09,0.13-0.21,0.2-0.39
				c0.1,0.07,0.28-0.42,0.27-0.62c0.02,0.07,0.08-0.14,0.1-0.06c0.08-0.2-0.07-0.25,0.35-0.5l-0.04,0.04
				c0.27-0.01-0.03-0.27,1.35-0.55c-0.01,0.03-0.02,0.05-0.03,0.06c0.04-0.05,0.16-0.13,0.41,0.11c-0.07-0.02-0.14-0.03-0.21-0.04
				c0.09,0.1,0.28,0.05,0.38,0.11l-0.03,0.03c0.31,0.1,0.36,0.02,0.67,0.23l-0.02-0.05c0.48,0.25,0.04,0.23,0.83,0.81
				c-0.13-0.08-0.34-0.27-0.49-0.36c0.09,0.09,0.29,0.32,0.37,0.36c-0.04-0.04-0.1-0.08-0.12-0.13c0.56,0.85,0.28-0.04,0.23,2.76
				c0.14,0.12,0.28,0.34-0.5,0.69c0.07,0,0.01,0.12-0.1,0.22l0.19-0.12c-0.04,0.28-0.38,0.26-0.44,0.4c0-0.01-0.01-0.02-0.01-0.03
				c-0.01,0.19-0.03,0.08,0.23,0.44c-0.03-0.05-0.07-0.11-0.11-0.18c0.06,0.07,0.14,0.18,0.25,0.32c0,0-0.01-0.01-0.01-0.01
				c0.47,0.13,0.09-0.46,2.04-0.87l-0.03,0.04c0.22-0.01,0.11,0.01,0.54,0.08c-0.03,0-0.06,0-0.1,0c0.68,0.68,2.33,1.74-2.38,5.46
				l-0.03-0.06c-0.3,0.16-0.45,0.26-0.81,0.49l0.05-0.09c-0.6,0.53-0.25,0.27-1.58,0.96c0-0.02,0-0.05,0.06-0.08
				c-0.18,0.08-0.72,0.27-0.59,0.36c-0.04-0.08-0.4,0.16-0.6,0.28l0.01-0.08c-0.01,0.02-0.23,0.19-0.75,0.43l0.07-0.07
				c-0.33,0.12-0.14,0.23-0.73,0.25c0.09-0.04-0.25,0.2-0.2,0.1c-0.38,0.02,0.04,0.07-0.81,0.21c0.28-0.08,0.5-0.14,0.48-0.13
				c-0.02-0.06-0.25-0.01-0.09-0.14c-0.18,0.08,0.02,0.04-0.7,0.31l0.02-0.01c-0.3,0.1-0.18,0.01-0.7,0.13
				c0.03,0.07-0.13,0.09,0.03,0.11c-0.03,0,0.05-0.01,0.14-0.05l-0.01,0.01c0.07-0.02,0.17-0.04,0.28-0.07
				c-0.73,0.33-0.58,0.05-2.16,0.47c0.26-0.11,0.26-0.07-0.69,0.1l0.04-0.03c-0.7-0.05-1.1,0.35-1.88,0.21
				c0.05,0.01,0.08,0.01,0.09,0.03c-0.64-0.08-2.05,0.13-3.75-0.14c0.01-0.04,0.03-0.09,0.06-0.06c0.02-0.08,0.11-0.03,0.13-0.13
				c0.03,0.04,0.01,0.03,0.02-0.11c-0.01,0-0.01,0.02-0.01,0.04l0.02-0.03c0.03,0.13-0.08,0.1,0.09,0.11
				c-0.47,0.14-0.02,0.18,2-0.58l0.05,0.08c0.04-0.17-0.03,0.13,0.2-0.11c0.01,0.03-0.01,0.05-0.01,0.07c0-0.13,0.07,0.09,0.05-0.07
				c0.04,0.04,0.1,0.02,0.13-0.02c0.06,0.08,0,0.06,0.24-0.06c-0.04-0.01-0.06-0.1-0.04-0.12l0.04,0.07
				c-0.01-0.05-0.04-0.17,0.02,0.02l0.03-0.04c0.01,0.02,0.01,0.04,0.01,0.07c0-0.05,0.09,0.05,0.09-0.04
				c-0.19,0.28,0.81-0.51,1.55-0.62c-0.01-0.02-0.06-0.1,0.03-0.02c-0.19-0.11,0.66-0.25,0.59-0.29c0.08-0.15,0.25-0.12,0.33-0.26
				c0,0.02,0.01,0.04,0,0.05c0.01-0.02,0.11-0.07,0.39-0.32c0.05,0.1,0.12,0.04,0.12-0.02c0.01,0.04,0.04,0.02,0.05,0.06
				c0.03-0.02,0.05-0.05,0.02-0.1c0.12,0.08,0.06-0.16,0.18-0.03c0.11-0.14,0.02-0.12,0.53-0.43c0.01,0.01,0,0.02,0,0.04
				c0.01-0.01,0.04-0.01,0.01-0.05l-0.01,0.02c-0.06-0.05-0.03-0.17-0.05-0.2c0.05,0.14,0.09-0.01,0.15,0.17
				c0-0.01,0-0.04-0.02-0.07c0.15,0.01,0.03,0,0.38-0.17c-0.03,0,0.07-0.03-0.02-0.08c0.01,0.04,0.06-0.15,0.07-0.04l0.05-0.06
				c-0.05-0.02-0.06-0.01-0.07-0.11c0.06,0.02,0.08-0.03,0.18,0.05c-0.03-0.08,0.06-0.07,0.04-0.15c0,0.02,0.03,0.06,0.04,0.07
				l-0.02-0.06c0.06,0.02,0.02-0.03,0.18-0.06l-0.01,0.03l0.04,0.02l-0.02-0.05c0.05,0.09,0.08-0.2,0.28-0.24
				c0,0.01,0.03,0.07,0.03,0.05c0.06-0.02,0.08-0.1,0.12-0.18c-0.02,0.03,0.18,0.03,0.1-0.09c0.11,0.01,0.22,0.14,0.27-0.31
				c-0.01,0.05,0.06,0.07,0.09,0.09c-0.01-0.01-0.02-0.03-0.03-0.04c0.11,0.1-0.03-0.1,0.04-0.05c0.01-0.02,0.05-0.13,0.06,0
				c0.07-0.09-0.03,0.01,0.18-0.04c-0.13-0.17-0.01-0.01,0-0.21c0.03,0.11-0.01,0.03,0.14,0.15c-0.02-0.06,0.07,0.01,0.04-0.08
				c0.02,0.02,0.02-0.02,0.06,0.04c-0.17-0.19,0.15,0.02,0.1-0.42c0.05,0,0.03,0.16,0.12,0.02c0.08,0.13,0.02-0.13,0.1,0.06
				c0.01-0.04,0-0.09,0.01-0.13c0.03,0.03,0.03,0.06,0.04,0.09c-0.01-0.01,0.04-0.06-0.02-0.13c-0.02,0.04-0.11,0.12-0.17,0.01
				c0.02,0-0.07,0.11-0.14,0.01c-0.09,0.03-0.14,0.06-0.03,0.23l-0.15-0.12l0.04,0.06c-0.01,0-0.02,0.01-0.04-0.02
				c0.01,0.06,0.02,0.02,0.04,0.07c-0.07-0.03-0.06,0.09-0.15,0c0.03,0.05,0.06,0.01,0.05,0.07c-0.08-0.13-0.04,0.12-0.14-0.05
				c0.01,0.15-0.15-0.03-0.13,0.18c-0.01-0.04-0.01,0.03-0.17,0.01c0.05,0.08,0,0.09-0.02,0.13c-0.03-0.04-0.04-0.07-0.04-0.1
				c-0.02,0.02-0.03,0.02-0.02,0.09l-0.04-0.06c-0.03,0.03-0.02,0.12-0.09,0.12c0.04,0.05,0.14,0.12,0.12,0.19
				c-0.02-0.03-0.06-0.07-0.06-0.09c0,0.01-0.09,0.01-0.07-0.04c-0.02,0.01-0.06,0-0.09-0.02c0.01,0.02,0.06,0.14-0.05,0.02
				c0.01,0.03-0.03,0.02,0.01,0.08c-0.01,0.01-0.03-0.02-0.05-0.03c0.01,0.04,0,0-0.01,0.12l0,0c0.12,0.09-0.18,0.02-0.47,0.26
				c0.03-0.05-0.15-0.02-0.03,0c-0.19-0.02-0.25,0.35-0.44,0.22l0.01,0.03c-0.03-0.01-0.08,0.02-0.09-0.03
				c-0.01,0.04,0.02,0.05,0.04,0.05c-0.07-0.01-0.07,0.05-0.13,0.05c0.05,0.05-0.06,0.26-0.13,0.02c-0.01,0.07-0.1,0.02-0.13,0.02
				l0.01-0.03c-0.01,0.02-0.02,0.03-0.03,0.03l0.04,0.04c-0.02,0-0.03,0.01-0.04-0.02c0.01,0.04,0.06,0.08,0.06,0.14
				c-0.03-0.01-0.04,0.06-0.08,0c0.07-0.01-0.05-0.06-0.01-0.1c-0.09,0.01-0.07-0.06-0.07,0.12c-0.02-0.04-0.06-0.06-0.06-0.05
				c0.02-0.01,0.05,0.06,0.05,0.1c-0.05-0.01-0.02,0.05-0.04,0.05c0.04,0.01,0.05,0,0.01,0.08c-0.02-0.03,0.01-0.03-0.01-0.04
				c0.01,0.08-0.1-0.03-0.11,0.04c-0.03-0.01-0.04-0.07-0.07-0.08c0.02,0.1-0.14-0.06-0.06,0.14l0.05-0.08
				c0,0.15,0.1,0.11-0.24,0.17c0.03,0.02,0.07,0.02-0.01,0.07c-0.02-0.04-0.05-0.05-0.07-0.07c0.02,0.03-0.05,0.03-0.01,0.12
				c-0.08-0.03-0.1-0.1-0.12,0.14c-0.01-0.06-0.05-0.07-0.03-0.13c-0.03,0.07,0.01-0.02-0.06,0.24c-0.16-0.03-0.01-0.06-0.76,0.39
				c-0.01-0.06,0.01-0.04-0.03-0.09c0.16,0.09-0.26-0.08-0.39,0.3c-0.01-0.08,0.02-0.14,0.04-0.19c-0.02,0.01-0.08,0.04-0.07,0.09
				c0.03-0.02,0.04-0.02-0.04,0.13c-0.07-0.18-0.14,0.02-0.24-0.11c-0.05,0.1-0.18,0.05-0.27,0.1c0.05,0.05,0.01,0.25-0.05,0.03
				c0.05,0.04,0.02,0.08,0.05,0.12c-0.04-0.01-0.12-0.05-0.2-0.18c0.03,0.05,0.01,0.08-0.02,0.07c0.08,0-0.09,0.12-0.08,0.01
				c-0.02,0.04-0.13,0.06-0.12,0.18c-0.01-0.01-0.04-0.05-0.03-0.07c-0.16,0-0.05,0.21-1.19,0.44c0-0.01,0.06,0.12-0.05,0.1l0.01,0
				c-0.05-0.09-0.08-0.05-0.12-0.04l0.04,0.13c-0.15-0.15-0.22,0.14-0.32,0c-0.01,0.04-0.04,0.05-0.05,0.09
				c-0.02-0.03-0.02-0.05-0.04-0.06c0.02-0.01,0.03-0.02,0.05-0.03c-0.07,0.03-0.03,0.02-0.21,0.07c0.29-0.24,0.32-0.16,1.28-0.86
				c0.06,0.09-0.1,0.02,0.39-0.37c0,0.03,0.41-0.31,0.61-0.48l0.01,0.02c-0.03-0.1,0.17-0.27,0.27-0.44
				c0.04,0.04,0.03,0.08-0.02,0.18c0-0.03,0.04,0.01,0.22-0.34c0.07,0.31,0.05-0.13,0.49-0.79l-0.03,0.14
				c0.01-0.06,0.25-0.35,0.09-0.07c0.36-0.45,0.17-0.18,0.72-1.2l0,0c-0.01-0.28,0.67-0.93,0.75-2.46c0.03,0.19,0.04-0.06,0.01,0.19
				c0.04-0.24,0.09-0.29,0.03-0.55c0.06,0.09,0.09-0.18,0.07-0.29l-0.05,0.17c0-0.13,0.01-0.24,0.01-0.35l0.08,0.06
				c0.09-0.5,0-1.05,0.17-2.18C748.47,178.77,748.61,178.25,748.65,178.54z M749.92,188.87c-0.03,0.02-0.01-0.01-0.14,0.05
				C749.82,188.86,749.81,188.86,749.92,188.87z"
                />
                <path
                  className="st10"
                  d="M737.2,187.46l0.06,0.09c-0.09-0.1,0.11-0.09,0.14-0.03c0.09-0.04,0.15-0.07,0.03-0.25l0.16,0.11
				l-0.05-0.06c0.01,0,0.03-0.01,0.04,0.01c-0.01-0.06-0.03-0.02-0.05-0.07c0.08,0.02,0.06-0.1,0.15-0.02
				c-0.03-0.05-0.06,0-0.05-0.07c0.09,0.13,0.03-0.13,0.15,0.04c-0.01-0.15,0.16,0.01,0.13-0.21c0.02,0.04,0-0.03,0.18-0.03
				c-0.06-0.07-0.01-0.1,0.01-0.14c0.04,0.04,0.05,0.06,0.05,0.09c0.02-0.03,0.03-0.02,0.02-0.1l0.04,0.06
				c0.03-0.04,0.01-0.13,0.09-0.13c-0.04-0.05-0.16-0.1-0.14-0.17c0.03,0.02,0.07,0.06,0.07,0.09c0-0.01,0.09-0.03,0.07,0.03
				c0.02-0.01,0.07-0.01,0.1,0.01c-0.02-0.02-0.08-0.13,0.05-0.04c-0.02-0.03,0.03-0.03-0.02-0.08c0.01-0.01,0.04,0.01,0.05,0.02
				c-0.01-0.04,0,0,0-0.13l0,0c-0.06-0.12,0.1-0.03,0.39-0.33c0,0.06,0.04,0.02-0.01,0.05c0.05-0.02,0.08,0,0.07-0.08
				c-0.03,0.06,0.16-0.01,0.03-0.01c0.2-0.02,0.21-0.42,0.43-0.34l-0.02-0.03c0.04,0.01,0.08-0.04,0.1,0c0-0.04-0.02-0.04-0.05-0.04
				c0.07-0.01,0.07-0.06,0.13-0.09c-0.06-0.03,0-0.29,0.13-0.06c0-0.08,0.1-0.05,0.13-0.06l0,0.04c0-0.02,0.01-0.03,0.02-0.04
				l-0.05-0.03c0.02-0.01,0.03-0.02,0.05,0c-0.02-0.04-0.08-0.06-0.09-0.12c0.04,0,0.02-0.07,0.09-0.02
				c-0.07,0.03,0.06,0.05,0.03,0.1c0.09-0.04,0.09,0.04,0.05-0.14c0.03,0.04,0.07,0.05,0.07,0.03c0,0.02-0.08-0.03-0.04-0.15
				c-0.04,0-0.05,0.01-0.03-0.07c0.03,0.02,0,0.04,0.02,0.04c-0.03-0.08,0.11,0,0.11-0.07c0.04,0,0.06,0.06,0.09,0.05
				c-0.05-0.09,0.15,0.01,0.03-0.16l-0.03,0.1l-0.02-0.1c-0.02,0.02-0.01,0.07-0.03,0.08c-0.02-0.05-0.01-0.04-0.05-0.07
				c0.11-0.2,0.16,0.21,0.31-0.16c-0.04-0.01-0.08,0-0.01-0.08c0.03,0.03,0.06,0.03,0.09,0.05c-0.03-0.02,0.05-0.05-0.02-0.12
				c0.09,0,0.12,0.06,0.08-0.18c0.03,0.05,0.07,0.05,0.07,0.11c0.01-0.08,0,0.02-0.01-0.25c0.12,0.03,0.13,0.02,0.33-0.26
				c0.01,0.01,0.02,0.02,0.01,0.02c0.03-0.22,0.13,0.13,0.24-0.27c0,0,0.01,0.01,0.02,0.02c-0.04-0.08-0.01-0.16,0-0.22
				c0.04,0.05,0.01,0.04,0.07,0.07c-0.21-0.02,0.28-0.04,0.24-0.48c0.04,0.07,0.04,0.14,0.05,0.19c0.02-0.02,0.05-0.08,0.02-0.12
				c-0.02,0.03-0.03,0.04-0.02-0.14c0.15,0.12,0.13-0.09,0.27-0.04c0-0.12,0.14-0.15,0.2-0.25c-0.07-0.01-0.14-0.21,0.03-0.05
				c-0.06,0-0.05-0.06-0.1-0.08c0.04-0.02,0.13-0.02,0.27,0.03c-0.05-0.02-0.05-0.06-0.02-0.08c-0.08,0.05,0.02-0.16,0.07-0.05
				c0-0.05,0.09-0.13,0.01-0.24c0.02,0,0.06,0.02,0.06,0.04c0.34-0.54,0.15-0.37,0.73-1.24c0.01,0.01-0.13-0.05-0.02-0.11
				l-0.01,0.01c0.1,0.03,0.1-0.03,0.12-0.07l-0.12-0.06c0.22-0.02,0.06-0.29,0.24-0.27c-0.02-0.04,0-0.07-0.03-0.1
				c0.08,0.01,0.07,0.06,0.15-0.15l0.04,0.04c-0.09-0.1,0.14-0.15,0.07-0.53c0.09,0.03,0.04-0.08,0.02-0.14
				c0.15,0.08-0.02-0.01,0.17-0.5c-0.02,0.06-0.04,0.1-0.04,0.1c0.05,0.08,0.26-0.18,0.08-0.3c0.01,0,0,0.01,0.01,0.03l-0.01,0
				c0,0.02-0.01,0.04-0.01,0.06c-0.03-0.03-0.05-0.06-0.01-0.07c-0.04-0.03-0.08-0.05-0.1-0.06c-0.02-0.07,0.25-0.25,0.29-0.83
				c-0.01,0.01-0.02,0.01-0.04,0.01c0.03-0.1,0.18-0.13,0.07-0.63l0.08,0c-0.16,0.1,0.14-0.29-0.11-0.66c0,0,0,0.01,0,0.01
				c-0.07,0-0.17,0.01-0.32,0.03l0.07,0.06l-0.06,0.01l0-0.04c-0.05,0.02-0.01,0.04,0.02,0.06c-0.03,0-0.05,0.01-0.06,0.02
				l0.11,0.06c-0.05,0-0.09-0.03-0.12,0c0.05,0.03,0.1-0.02,0.13,0.03c-0.05,0.02-0.1,0.01-0.05,0.04c-0.03,0-0.05-0.01-0.06-0.02
				c0.03,0.03,0.01,0.07,0.07,0.09c-0.07,0.02,0.01,0.09-0.1,0.08c0.03,0.01,0.08,0.05,0.08,0.08c-0.13-0.06-0.02,0.09-0.16,0.06
				c0.11,0.05,0.04,0.12,0.1,0.19c-0.01,0-0.03-0.01-0.02-0.02c-0.01,0.03-0.05,0.04-0.08,0.05c0.1,0.02,0.11,0.07,0.17,0.11
				c-0.1,0.03-0.11-0.04-0.2-0.04c0.08,0.01,0.07,0.08,0.13,0.08c-0.06,0.02-0.03,0.02-0.12,0.02c0.12,0.03,0.02,0.03,0.09,0.07
				c-0.07,0.03-0.11-0.01-0.09,0.05c-0.14-0.09-0.05,0.21,0.14,0.4c0-0.01-0.02-0.02-0.04-0.03l0.02,0.03
				c-0.09-0.12-0.16,0.05-0.09-0.01c-0.16-0.04-0.14-0.04-0.73,2.13l-0.09-0.02c0.09,0.15-0.07-0.12-0.05,0.23
				c-0.03-0.02-0.03-0.04-0.04-0.06c0.09,0.1-0.12-0.01,0.02,0.09c-0.05,0-0.08,0.06-0.09,0.11c-0.1-0.02-0.04-0.05-0.14,0.22
				c0.04-0.02,0.12,0.03,0.11,0.06l-0.08-0.03c0.04,0.04,0.14,0.11-0.03,0l0,0.05c-0.02-0.01-0.03-0.02-0.06-0.04
				c0.04,0.04-0.11,0.02-0.04,0.09c-0.04-0.36-0.3,0.95-0.95,1.46c0.01,0.01,0.1,0.05-0.02,0.04c0.34,0-0.33,0.24-0.62,0.94
				c-0.01-0.02-0.03-0.03-0.02-0.05c-0.01,0.03-0.08,0.11-0.27,0.46c-0.08-0.08-0.13,0.01-0.11,0.07c-0.02-0.03-0.05,0-0.07-0.04
				c-0.02,0.03-0.03,0.07,0.02,0.1c-0.15-0.04-0.01,0.18-0.17,0.09c-0.07,0.18,0.02,0.13-0.42,0.6c-0.01-0.01-0.01-0.02-0.01-0.03
				c-0.01,0.02-0.04,0.02,0,0.05l0-0.02c0.07,0.03,0.07,0.16,0.09,0.19c-0.09-0.12-0.09,0.04-0.19-0.12c0,0.01,0.01,0.04,0.03,0.06
				c-0.15,0.04-0.03,0.01-0.35,0.28c0.03-0.01-0.07,0.05,0.03,0.07c-0.02-0.04-0.04,0.16-0.06,0.06l-0.04,0.07l0.06-0.01
				c-0.01,0.1,0.1,0.11-0.17,0.1c0.04,0.07-0.05,0.09-0.01,0.16c-0.01-0.02-0.04-0.05-0.05-0.06l0.03,0.06
				c-0.06-0.01-0.02,0.04-0.18,0.1l0.01-0.03l-0.05-0.01l0.03,0.04c-0.06-0.08-0.05,0.22-0.26,0.31c0-0.01-0.04-0.06-0.03-0.05
				c-0.06,0.03-0.07,0.11-0.11,0.21c0.02-0.04-0.19,0-0.1,0.11c-0.12,0.01-0.23-0.11-0.26,0.36c0-0.06-0.06-0.07-0.1-0.08
				c0.01,0.01,0.02,0.03,0.04,0.04c-0.12-0.09,0.04,0.1-0.04,0.06c0,0.02-0.04,0.14-0.06,0c-0.07,0.1,0.04-0.02-0.19,0.06
				c0.15,0.15,0.01,0.01,0.01,0.22c-0.04-0.11,0.01-0.04-0.15-0.14c0.02,0.06-0.07,0-0.04,0.09c-0.02-0.02-0.02,0.02-0.06-0.03
				c0.19,0.19-0.16-0.01-0.1,0.44c-0.05,0-0.04-0.16-0.12-0.01c-0.08-0.12-0.02,0.14-0.1-0.06c-0.01,0.04,0,0.09-0.01,0.13
				c-0.04-0.02-0.03-0.06-0.04-0.09c0.01,0.01-0.06,0.07,0.02,0.15C737,187.44,737.15,187.75,737.2,187.46z M743.3,179.22
				c-0.01-0.01-0.03,0.01-0.06,0.02C743.25,179.22,743.25,179.17,743.3,179.22z"
                />
                <path
                  className="st10"
                  d="M731.61,179.19c-0.28-0.1-0.77-0.3-1.01-0.48l0.06,0c-0.21-0.12-0.3,0.02-0.48-0.12l0.04,0
				c-0.16-0.1-0.47,0.05-2.89-0.56c0.01,0.04-0.14,0.05-0.42,0.07c0.17,0.2-0.11-0.18-0.84,0.28c0-0.04,0.07-0.07,0.12-0.11
				c-0.22,0.16-0.08-0.06-0.27,0.1c0.04,0,0,0.05,0.01,0.07c-0.13,0.01-0.38,0.12-0.54,0.26l0-0.07c-0.33,0.2-0.61,0.52-0.85,0.88
				c-0.12-0.09-0.52,0.91,0.99,2.71c0.11,0.02,0.18,0.05,0.19,0.05c-0.3-0.01,0,0.01-0.59-0.06c0,0.01-0.12,0.16-0.11,0
				c-0.95,0.39-0.54-0.04-2.2,0.83l0.04,0.03c-0.06,0.09-0.11,0.04-0.18,0.08c0.28,0.3-2.02,0.82-1.56,2.7
				c-0.04-0.03-0.09-0.07-0.13-0.11c0.16,0.15,0.2,0.55,0.96,0.73c-0.06,0.05-0.13,0.11-0.2,0.19c-0.06-0.1-0.37,0.23-0.41,0.39
				c0-0.06-0.11,0.09-0.11,0.01c-0.11,0.14-0.17,0.29-0.1,0.3c-0.41,0.72-0.53,0.58-0.23,2.19c-0.01-0.01-0.01-0.02-0.02-0.03
				c0.27,0.62,0.01,0.53,1.04,1.41c0-0.01,0-0.05,0.37,0.01c-0.18,0.07,0.31,0.2,0.05,0.28c0.05,0.01,0.12,0.02,0.12-0.02
				c0.06,0.04,0.08,0.07-0.01,0.07c0.09,0.09,0.37-0.03,0.33,0.11c0.14-0.01,0.26-0.02,0.46,0.02c-0.01,0.01,0.01,0.04-0.05,0.03
				c0.36,0.04,0.6-0.08,0.98-0.12c-0.09,0.08,0.1,0.14,0.37-0.01l-0.09-0.01c0.17-0.06,0.29-0.19,0.43-0.24l-0.1,0.1
				c0.1-0.06,0.19-0.11,0.28-0.18l-0.18,0.06c0.13-0.13-0.07-0.09,0.14-0.19c-0.03,0.03,0.15-0.09,0.25-0.1l0,0
				c0.27-0.31,0.17,0.11,0.62-0.52c0.01,0.15,0.26-0.08,0.26-0.2c-0.01,0.02,0,0.02-0.03,0.06c0.22-0.2,0.33-0.27,0.41-0.31
				l-0.05,0.05c0.3-0.22-0.02,0.02,1.05-0.81l-0.06,0.03c0.3-0.29,0.28,0,1.13-0.58l-0.03,0.05c0.62-0.25,0.5-0.18,1.88-0.49
				c-0.1,0.05-0.16,0.06,0.28-0.04c-0.03-0.05-0.05-0.03-0.13-0.06c0.04-0.04,0.14-0.08,0.23-0.09c-0.16,0.06,0.01,0.09,0.08,0.12
				c-0.01-0.02,0.01-0.04,0-0.06c0.4,0.03,0.02-0.09,0.88-0.06c-0.06,0.02-0.15,0.01-0.22,0.03c0.14,0.08,0.16,0.06,0.27,0.08
				l-0.01,0l0.24,0.04l-0.06-0.02c0.19-0.17,0.37,0,0.63-0.09c-0.15,0.02,0.06,0.03,0.48,0.27c-0.31-0.14,1.09,0.13,1.99,0.54
				c-0.1,0.05-0.22,0.06,0.52,0.28l-0.07-0.07c-0.01,0.04-0.11,0.11-0.19-0.08l0.09,0.03c-0.02-0.18-0.35-0.17-0.52-0.25
				c0.05-0.06,0.15-0.09-0.38-0.3c0.03-0.01,0-0.08,0.08-0.03c-0.22-0.14-0.41-0.06-0.47-0.17l0.02,0c-0.36-0.09-0.93-0.4-2.43-0.38
				c0.02-0.03-0.42-0.06-0.63-0.07l0.01-0.02c-0.04,0.1-0.25,0.08-0.4,0.12c0-0.05,0.03-0.08,0.11-0.11
				c-0.07,0.02-0.02-0.04-0.32,0.09c0.11-0.26-0.09,0.06-0.72,0.22l0.09-0.08c-0.04,0.03-0.34,0.07-0.1-0.01
				c-0.47,0.06-0.2,0.01-1.09,0.34l0,0c-0.15,0.18-0.91,0.18-1.86,0.95c0.1-0.12-0.06,0,0.11-0.12c-0.17,0.1-0.23,0.09-0.36,0.27
				c0.02-0.09-0.17,0.03-0.23,0.1l0.13-0.05c-0.08,0.07-0.15,0.13-0.22,0.18l-0.02-0.1c-0.36,0.22-0.62,0.6-1.33,1.18
				c0.24,0.09-0.47,0.04-0.32,0.15c-0.21,0.06-0.31,0.14-0.52,0.17c0.06,0.05-0.6,0.32-0.46,0.01c-0.19,0.06-0.35-0.06-0.45-0.09
				c0.03,0,0.07-0.01,0.1-0.01c-0.06,0.01-0.11,0-0.16-0.01l0.04,0.07c-0.06-0.02-0.1-0.04-0.1-0.06c-0.14,0.06-0.04,0.21-0.36,0
				c0.28,0.14,0.25-0.21-0.27-0.16c0.05-0.02-0.01-0.1-0.05-0.11c0.07,0.03-0.06,0.11-0.33-0.07l0.07,0.07
				c-0.2-0.01-0.35-0.28-0.63-0.92c-0.03,0.08-0.01,0.08-0.13-0.02c0.06,0.1,0.13,0.19,0.2,0.27c-0.06-0.05-0.13-0.09-0.19-0.14
				c0.05,0.1,0.15,0.17,0.17,0.23c-0.1-0.06-0.08-0.06-0.09,0.01c-0.22-0.47,0.04-0.28-0.27-1.17c-0.01,0.03-0.02,0.07-0.02,0.1
				c-0.07-0.04-0.01-0.2-0.03-0.3c0.05,0.01,0.07-0.05,0.11-0.09c-0.04,0,0.04-0.22-0.05-0.27c0.19-0.28,0.12-0.08,0.21-0.68
				c0.01,0.09,0.09,0.01,0.05,0.17c0.04-0.07,0.09-0.13,0.06-0.15c0.15,0.1-0.41-0.22,0.83-0.9c-0.04,0.05,0,0.12,0.07,0.05
				l-0.06-0.05c0.05-0.02,0.01-0.02,0.23-0.11c0,0-0.01,0-0.01,0c0.06-0.01,0.04-0.08,0.3-0.05c-0.04,0.02-0.04,0.05-0.1,0.05
				c0.14,0.06,0.5-0.05,0.49-0.1c0.04,0.45,0.37-0.57-1.68-0.57c0.27-0.17-0.19,0.17-0.07-0.29c0.02,0.04,0.04,0.08,0.07,0.12
				c0.03-0.1-0.08-0.15-0.08-0.24l0.04,0c-0.03-0.19-0.12-0.23,0-0.49l-0.04,0.01c0.13-0.43,0.22,0,0.54-0.77
				c-0.03,0.13-0.15,0.33-0.2,0.47c0.07-0.08,0.22-0.29,0.23-0.36c-0.02,0.04-0.04,0.09-0.08,0.12c0.55-0.7-0.06-0.12,2.02-1.34
				c-0.2-0.08,0.12-0.05,0.04-0.18c0.19-0.12,0.35-0.05,0.67,0.05c-0.04-0.05,0.05-0.09,0.18-0.11l-0.19-0.02
				c0.24-0.11,0.17,0.05,0.78-0.1c-0.61,0.04,0.31,0.12,1.17-0.33c-0.06-0.03-0.16-0.09-0.16-0.13c-0.15-0.34-3.92-2.33,1.25-3.79
				l0,0.07c0.26,0.01,0.4,0.03,0.72,0.05l-0.07,0.04c0.62-0.07,0.27-0.1,1.42,0.24c-0.01,0.01-0.03,0.04-0.08,0.02
				c0.15,0.05,0.53,0.29,0.53,0.14c-0.04,0.09,0.31,0.15,0.49,0.2l-0.06,0.05c0.02-0.02,0.23,0.04,0.66,0.23l-0.09,0
				c0.23,0.15,0.23-0.06,0.51,0.35c-0.07-0.05,0.27,0.07,0.16,0.09c0.18,0.25,0.04-0.07,0.5,0.44c-0.18-0.15-0.32-0.27-0.3-0.27
				c-0.04,0.05,0.09,0.18-0.07,0.15c0.13,0.08,0.03-0.04,0.52,0.32l-0.02-0.01c0.2,0.16,0.08,0.12,0.39,0.42
				c0.04-0.06,0.12,0.04,0.08-0.08c0.02,0.02-0.03-0.03-0.1-0.07l0.01,0c-0.05-0.04-0.11-0.1-0.17-0.16
				c0.55,0.32,0.26,0.37,1.22,1.29c-0.19-0.13-0.16-0.15,0.34,0.44l-0.05-0.01c0.2,0.53,0.72,0.61,0.86,1.25
				c-0.01-0.04-0.02-0.06-0.01-0.08c-0.02,0.31,0.96,1.5,1.31,3.36l0.04-0.06c0,0.53,0.9,1.92,0.68,0.32c0.21-0.2,0.01,0,0.35-0.69
				c-0.03-0.08,0.21-0.14,0.31-0.92c0.06,0.22,0.14-0.34,0.25-0.24c-0.06-0.16,0.12-0.47,0.13-0.71c0,0.02,0.01,0.05,0,0.06
				c0.05-0.12,0.1-0.15,0.15-0.22c-0.05,0.15-0.15-0.7,0.11-0.26c-0.08-0.02,0.01-0.29-0.07-0.26c0.09-0.1,0.06-0.09,0.15-0.13
				c-0.15,0.05,0.09-0.69,0.16-0.35c-0.12-0.52,0.06,0.01,0.17-1.38c-0.1,0-0.17,0.03-0.09-0.19c0.46-0.08,0.14,0.1,0.36-1.62
				l0.09,0.08c-0.11-0.82,0.31-1.55,0.07-4.17l0.02,0.05c0.02-0.22-0.16-0.24-0.11-0.45l0.02,0.04c0.08-0.09-0.02-0.21,0.01-3.1
				c-0.13,0.06,0.13-0.1,0.04-0.77c0.03,0.01,0.03,0.08,0.04,0.14c-0.05-0.23,0.08-0.03,0.05-0.23c-0.02,0.02-0.04-0.02-0.06-0.03
				c0.06-0.09,0.12-0.3,0.16-0.44l0.04,0.05c0.11-0.24,0.26-0.49,0.51-0.6c-0.05,0.1-0.1-0.22,0.02-0.06
				c-0.09,0.05,3.03,0.33,2.18,2.51c0.06-0.03,0.07,0.09,0.03,0.22l0.04-0.08c0.01,0.2-0.32,0.55,0.45,0.5
				c-0.02,0-0.05-0.01-0.08-0.01c0.04,0,0.03-0.02,0.22,0.01c-0.01,0-0.01,0-0.02,0c0.47-0.92-0.01,0,2.92-2.5l-0.11,0.05
				c0.53,0.07,0.93,0.46,1.29,0.81c-0.03-0.01-0.05,0-0.09-0.04c0.03,0.12,0.23,0.32,0.27,0.3c0.03,0.26,0.07-0.04,0.59,1.41
				c-0.11,0.01-0.03,0.36,0.04,0.48c-0.04-0.04-0.02,0.11-0.07,0.07c0.02,0.14,0.05,0.27,0.1,0.24c-0.12,0.49,0.02,0.34-0.01,1.94
				c-0.03-0.04-0.05-0.08-0.06-0.11c-0.07,0.56-0.04,0.48-0.23,1.61c-0.01,0-0.01-0.04-0.02-0.08c0.01,0.19,0-0.03,0.12,0.4
				c-0.11-0.15-0.13,0.31-0.26,0.09c0,0.04,0.01,0.1,0.04,0.1c-0.03,0.06-0.05,0.07-0.07,0c-0.04,0.24,0.04,0.17-0.02,0.38
				c-0.02-0.01-0.02-0.05-0.04-0.08c0.01,0.12,0.04,0.24-0.05,0.41c-0.01-0.02-0.04,0-0.02-0.05c-0.1,0.3-0.06,0.55-0.23,0.85
				c-0.04-0.08-0.09-0.1-0.22,0.26l0.07-0.05c-0.07,0.15-0.06,0.31-0.15,0.42l0.01-0.13l-0.11,0.28l0.1-0.14
				c-0.02,0.17,0.11,0.03,0,0.22c0.01-0.04-0.07,0.15-0.15,0.2v0c-0.1,0.38-0.18-0.03-0.34,0.68c-0.07-0.13-0.21,0.15-0.16,0.27
				c0.01-0.02-0.01-0.02,0.01-0.06c-0.15,0.26-0.23,0.35-0.3,0.42l0.03-0.07c-0.21,0.29,0.01-0.03-0.7,1.09l0.05-0.05
				c-0.15,0.38-0.26,0.1-0.76,0.97l0-0.06c-0.41,0.51-0.37,0.36-1.34,1.35c0.06-0.1,0.1-0.14-0.2,0.18c0.06,0.03,0.06,0,0.14-0.02
				c-0.01,0.06-0.07,0.14-0.13,0.2c0.09-0.13-0.06-0.07-0.13-0.05c0.02,0.01,0.02,0.04,0.03,0.05c-0.33,0.21,0.04,0.09-0.65,0.56
				c0.04-0.05,0.11-0.11,0.14-0.16c-0.16,0.02-0.16,0.05-0.25,0.1l0.01,0l-0.21,0.12l0.06-0.02c-0.83,0.84-0.02,0.08-2.66,1.61
				c0.02-0.12,0.08-0.19-0.53,0.22l0.1-0.01c-0.02-0.03-0.02-0.16,0.18-0.09l-0.08,0.05c0.15,0.11,0.34-0.17,0.51-0.24
				c0.01,0.08-0.01,0.16,0.47-0.08c-0.01,0.03,0.05,0.06-0.03,0.08c0.25-0.04,0.31-0.24,0.43-0.22l-0.01,0.02
				c0.3-0.18,0.89-0.36,1.98-1.3c0.01,0.03,0.35-0.21,0.53-0.3l0.01,0.02c-0.02-0.09,0.14-0.22,0.24-0.33
				c0.03,0.05,0.03,0.08-0.02,0.15c0-0.02,0.04,0.02,0.2-0.25l0.02,0.07c0.04-0.07,0.27-0.14,0.45-0.63l-0.03,0.11
				c0.01-0.05,0.23-0.24,0.08-0.04c0.35-0.29,0.15-0.12,0.74-0.81l0,0c0.05-0.22,0.64-0.56,1.27-1.55
				c-0.05,0.14,0.06-0.02-0.06,0.14c0.13-0.14,0.18-0.16,0.23-0.36c0.02,0.09,0.14-0.08,0.17-0.16l-0.11,0.09
				c0.05-0.09,0.1-0.16,0.15-0.23l0.04,0.08c0.25-0.32,0.47-0.74,0.8-1.58c-0.25,0.08,0.37-0.33,0.18-0.32
				c0.13-0.19,0.15-0.32,0.27-0.53c-0.05,0.02,0.01-0.72,0.23-0.46c0.02-0.22,0.17-0.36,0.23-0.48l-0.02,0.11
				c0.01-0.07,0.03-0.12,0.04-0.18l-0.07,0.04c0.03-0.06,0.04-0.11,0.07-0.11c-0.05-0.15-0.2-0.05,0-0.4
				c-0.13,0.33,0.25,0.24,0.12-0.31c0.03,0.05,0.1-0.04,0.1-0.09c-0.03,0.08-0.1-0.03-0.05-0.35l-0.04,0.1
				c-0.1-0.22,0.18-0.43,0.36-1.17c-0.07,0.06-0.05,0.07-0.09-0.1c0,0.12,0,0.25-0.01,0.37c-0.01-0.08-0.03-0.17-0.05-0.25
				c-0.01,0.11,0.01,0.24-0.02,0.3c-0.03-0.12-0.01-0.1-0.07-0.06c0.1-0.54,0.32-0.22,0.27-1.26l-0.03,0.11
				c-0.01-0.6,0.11-0.22-0.07-1.45c0.06,0.08,0.09-0.05,0.13,0.13c-0.14-0.38,0.02,0.13-0.64-2.27c0.01,0,0.01,0,0.02,0
				c-0.32-0.45,0.07-0.04-0.44-0.78l0.04,0.03c-0.15-0.13,0.03-0.21-1.12-0.68c0.01-0.02,0.02-0.02,0.03-0.03
				c-0.06,0.02-0.16,0.02-0.31-0.2c0.06,0.02,0.12,0.05,0.18,0.07c-0.06-0.13-0.24-0.09-0.33-0.13l0.02-0.03
				c-0.3-0.07-0.32,0.02-0.64-0.02l0.03,0.03c-0.46,0.1-0.16-0.29-0.97,0.09c0.09-0.1,0.33-0.18,0.49-0.23
				c-0.12,0-0.4,0.05-0.45,0.12c0.04-0.02,0.1-0.05,0.15-0.05c-0.71,0.41-0.17-0.03-1.67,1.18c-0.01-0.03-0.02-0.07-0.02-0.1
				l0.04,0.02c-0.12-0.2,0.02-0.13-0.24-0.52l0.08,0.06c-0.15-0.41-0.43-0.53-0.71-0.74c0.08-0.08-0.18-0.33-0.34-0.42l0.01-0.01
				c0.55,1.09-2.29-2.36-2.88,3.18l-0.07-0.01c-0.02,0.28-0.02,0.42,0,0.76l-0.05-0.07c0.2,0.61,0.1,0.26,0.18,1.47
				c-0.02,0-0.05-0.01-0.05-0.07c0.01,0.16-0.04,0.61,0.1,0.54c-0.09,0.01,0.01,0.34,0.06,0.52l-0.07-0.02
				c0.02,0.01,0.08,0.21,0.15,0.67l-0.05-0.07c0,0.27,0.18,0.15-0.01,0.6c-0.01-0.08,0.11,0.25,0.03,0.18
				c-0.09,0.29,0.08-0.01-0.05,0.65c0.01-0.23,0.02-0.41,0.02-0.39c-0.06,0-0.08,0.18-0.17,0.03c0.02,0.15,0.05-0.01,0.08,0.6
				l-0.01-0.02c0,0.25-0.05,0.14-0.08,0.55c0.07,0,0.05,0.12,0.12,0.01c-0.01,0.03,0-0.04-0.01-0.12l0.01,0.01
				c0-0.06,0.01-0.14,0.01-0.23c0.12,0.62-0.13,0.42-0.16,1.72c-0.04-0.22-0.01-0.21-0.08,0.54l-0.02-0.04
				c-0.24,0.5,0.07,0.91-0.26,1.45c0.02-0.04,0.03-0.06,0.05-0.06c-0.23,0.22-0.32,1.68-1.15,3.31l0.06-0.02
				c-0.07,0.18-0.12,0.15-0.45,0.83c-0.09-0.09,0.17-0.08-0.19-0.61c0.18,0.14-0.1-0.37,0.04-0.36c-0.16-0.09-0.21-0.46-0.36-0.66
				c0.01,0.01,0.04,0.03,0.04,0.05c-0.04-0.13-0.02-0.18-0.03-0.27c0.07,0.15-0.58-0.45-0.09-0.29c-0.07,0.04-0.19-0.24-0.23-0.16
				c0-0.14-0.02-0.11,0.03-0.21c-0.07,0.14-0.41-0.59-0.12-0.38c-0.46-0.32,0.06-0.05-0.86-1.17c-0.07,0.07-0.1,0.14-0.2-0.08
				c0.26-0.39,0.18-0.03-0.97-1.42l0.12-0.01C733.4,180.87,733.73,180.85,731.61,179.19z M739.31,179.04c0-0.02,0-0.05,0-0.1
				C739.32,178.98,739.31,178.94,739.31,179.04z M739.33,178.55c-0.02-0.04,0.04,0.23-0.06,0.13
				C739.29,178.7,739.3,178.61,739.33,178.55z M742.99,174.03c0,0-0.01,0-0.02,0c0-0.02,0-0.04-0.01-0.07L742.99,174.03z
				 M738.8,179.34c-0.01-0.06,0.01-0.08,0.02-0.09c0.01,0.03-0.01,0,0.01,0.11C738.82,179.36,738.81,179.35,738.8,179.34z
				 M732.95,180.37c-0.02-0.01-0.04-0.03-0.08-0.06C732.91,180.33,732.87,180.3,732.95,180.37z M732.91,180.95
				c-0.05,0-0.06,0.02-0.1-0.07C732.84,180.9,732.8,180.9,732.91,180.95z M732.63,180.16c0.03,0-0.04-0.07-0.07-0.13
				C732.52,180.03,732.78,180.16,732.63,180.16z"
                />
                <path
                  className="st10"
                  d="M735.57,187.8c-0.06-0.04-0.07,0.03-0.17,0.02l0.16-0.11l-0.07,0.03c0-0.01,0-0.02,0.03-0.03
				c-0.06,0-0.03,0.02-0.08,0.03c0.05-0.06-0.07-0.06,0.04-0.12c-0.06,0.02-0.03,0.05-0.08,0.04c0.14-0.06-0.1-0.04,0.09-0.11
				c-0.13-0.01,0.05-0.12-0.14-0.12c0.04-0.01-0.02-0.01,0.03-0.14c-0.08,0.04-0.09,0-0.12-0.01c0.05-0.03,0.07-0.04,0.1-0.04
				c-0.02-0.02-0.01-0.02-0.08-0.02l0.06-0.03c-0.02-0.03-0.1-0.02-0.09-0.08c-0.05,0.03-0.13,0.13-0.19,0.11
				c0.03-0.02,0.08-0.05,0.09-0.05c0.01-0.08-0.02,0.04,0.09-0.14c-0.03,0.02-0.13,0.06-0.02-0.04c-0.03,0.01-0.02-0.03-0.08,0.02
				c-0.01-0.01,0.02-0.03,0.04-0.04c-0.04,0.01,0-0.01-0.11,0.01l0,0c-0.09,0.12-0.01-0.17-0.22-0.37c0.05,0.02,0.02-0.13,0-0.02
				c-0.06-0.28-0.35-0.07-0.22-0.4c-0.04,0-0.04,0.03-0.04,0.05c0-0.06-0.04-0.05-0.06-0.1c0.11-0.01-0.24,0.02-0.03-0.1
				c-0.07,0.01-0.03-0.08-0.04-0.1l0.03,0c-0.02,0-0.03-0.01-0.03-0.01l-0.03,0.05c0-0.02-0.01-0.02,0.01-0.04
				c-0.04,0.02-0.06,0.08-0.11,0.1c0.01-0.03-0.06-0.01-0.01-0.07c0.02,0.05,0.05-0.06,0.09-0.04c-0.03-0.08,0.04-0.08-0.12-0.02
				c0.03-0.03,0.05-0.07,0.03-0.07c0.02,0.01-0.04,0.06-0.08,0.08c0-0.05-0.04,0-0.05-0.02c-0.01,0.04,0.01,0.04-0.06,0.04
				c0.02-0.03,0.03,0,0.03-0.02c-0.07,0.04,0.01-0.1-0.05-0.08c0.01-0.03,0.05-0.07,0.05-0.09c-0.08,0.05,0.01-0.13-0.13,0.01
				l0.08,0.01c-0.07,0.1-0.22,0.01-0.22-0.19c-0.02,0.03-0.04-0.03-0.1,0.05c0.13-0.18-0.25,0.06-0.05-0.12
				c-0.07,0,0.01-0.01-0.21,0.07c0.04-0.15-0.12-0.07-0.09-0.2c-0.23-0.02-0.08,0.06-0.47-0.16c0.04-0.05,0.03-0.02,0.06-0.08
				c-0.16,0.23-0.11-0.03-0.23-0.14c-0.03,0.01-0.11,0.05-0.15,0.05c0.05-0.06,0.11-0.07,0.15-0.1c-0.18,0.02,0,0.09-0.48-0.29
				c0,0.06-0.05,0.07-0.05,0.12c-0.02-0.03-0.02-0.12,0.01-0.26c-0.02,0.05-0.04,0.07-0.06,0.04c0.04,0.04-0.12,0.03-0.04-0.05
				c-0.04,0.01-0.11-0.05-0.18,0.06c0.22-0.17-0.36-0.01-1.01-0.36l0.01,0c0.01-0.1-0.03-0.08-0.06-0.09l-0.04,0.13
				c-0.02-0.21-0.21,0.02-0.2-0.16c-0.03,0.03-0.05,0.02-0.07,0.05c0-0.05,0.05-0.09-0.11-0.11c0.1-0.2-0.16-0.04-0.36-0.01
				c0.02-0.09-0.06-0.02-0.1,0.01c0.02-0.09,0.08-0.08-0.21-0.04c-0.02,0.05-0.08,0.03-0.15-0.04c0.04,0.01,0.07,0.02,0.07,0.03
				c0.04-0.01-0.16-0.24-0.21-0.04c0-0.01,0,0,0.02-0.01l0,0.01l0.04,0c-0.02,0.03-0.04,0.06-0.05,0.02
				c-0.02,0.04-0.04,0.09-0.05,0.11c-0.04-0.03-0.03-0.24-0.3-0.1c-0.06-0.26,0.03-0.11-0.28-0.11c0.01,0.01,0.01,0.02,0.01,0.03
				c-0.05-0.02-0.13-0.17-0.44-0.06l0.01-0.08c0.07,0.23-0.19-0.18-0.47,0.08c0,0,0.01,0,0.01-0.01c-0.01,0.07-0.02,0.17-0.02,0.32
				l0.05-0.06l0,0.06l-0.03,0c0.01,0.06,0.03,0.01,0.05-0.02c0,0.03,0,0.05,0,0.06l0.06-0.1c0,0.05-0.03,0.09-0.01,0.12
				c0.03-0.05,0-0.1,0.04-0.13c0.01,0.06,0,0.1,0.02,0.06c0,0.03-0.01,0.05-0.02,0.06c0.03-0.03,0.05-0.01,0.07-0.07
				c0.01,0.07,0.07-0.01,0.04,0.1c0.01-0.02,0.05-0.08,0.07-0.08c-0.05,0.12,0.06,0.02,0.03,0.16c0.04-0.11,0.09-0.04,0.15-0.09
				c0,0.01-0.01,0.03-0.01,0.02c0.02,0.01,0.02,0.05,0.03,0.08c0.02-0.1,0.06-0.11,0.09-0.17c0.01,0.1-0.04,0.11-0.05,0.2
				c0.01-0.08,0.06-0.07,0.07-0.14c0.01,0.06,0.01,0.03,0.01,0.11c0.03-0.12,0.02-0.02,0.06-0.09c0.02,0.07-0.01,0.11,0.03,0.08
				c-0.02,0.17,0.03-0.02,0.24-0.06c0.01,0.04,0,0.03,0.05-0.09c-0.01,0-0.01,0.02-0.02,0.04l0.02-0.02
				c-0.02,0.13-0.09,0.06,0.02,0.13l-0.03-0.04c0.07,0.1,0.11,0.18,0.53,0.11c-0.01,0.02-0.02,0.05-0.02,0.08
				c-0.02-0.1,0.29-0.06,0.76,0.17c0.02-0.06,0.19-0.08,0.23,0.08l0.05-0.07l-0.01,0.09c0.08-0.09-0.04,0.12,0.17,0
				c-0.01,0.03-0.03,0.04-0.04,0.05c0.06-0.1,0,0.11,0.06-0.04c0.01,0.05,0.05,0.06,0.08,0.05c0,0.08-0.04,0.06,0.17,0.08
				c-0.01-0.03,0.02-0.12,0.04-0.12l-0.02,0.08c0.02-0.04,0.07-0.16,0,0.03l0.04-0.02c-0.01,0.02-0.02,0.03-0.03,0.06
				c0.03-0.04,0.02,0.09,0.07,0.02c-0.29,0.13,0.76,0.04,1.12,0.46c0.05-0.18,0.18,0.18,0.75,0.29c-0.01,0.01-0.03,0.03-0.04,0.03
				c-0.01,0.06,0.06,0.04,0.09,0c-0.02,0.01-0.03,0.13,0.09,0.07c0.01,0.05,0.08,0.01,0.08,0.06c0.06-0.05,0.03,0.07,0.1-0.02
				c-0.07,0.09,0,0.11,0.05,0.08c-0.03,0.02,0,0.04-0.04,0.07c0.02,0.01,0.05,0.01,0.08-0.04c-0.04,0.14,0.14-0.03,0.06,0.12
				c0.1-0.01,0.18,0.01,0.25,0.03c-0.04,0.11-0.06,0.04,0.19,0.2c-0.01,0.01-0.02,0.01-0.03,0.02c0.01,0,0.01,0.03,0.04-0.01
				l-0.02,0c0.03-0.07,0.14-0.09,0.16-0.12c-0.11,0.1,0.02,0.07-0.13,0.19c0.01,0,0.04-0.01,0.06-0.04c0.01,0.11,0,0.02,0.18,0.24
				c0-0.02,0.03,0.05,0.07-0.04c-0.03,0.02,0.14,0.01,0.04,0.04l0.05,0.03c0.02-0.05,0.01-0.05,0.09-0.09
				c-0.02,0.06,0.03,0.06-0.04,0.17c0.07-0.04,0.06,0.03,0.14-0.01c-0.02,0.01-0.05,0.04-0.06,0.05l0.05-0.03
				c-0.07,0.07,0,0.07,0.01,0.05c-0.03,0.03,0.18,0.11,0,0.15c0.05,0.04,0.17,0.03,0.19,0.09c0.11-0.12,0-0.01,0.05,0.05
				c-0.01,0-0.06,0.04-0.05,0.03c0.01,0.05,0.08,0.05,0.15,0.07c-0.02-0.02-0.05,0.16,0.07,0.08c-0.05,0.09-0.11,0.23,0.24,0.21
				c-0.05,0-0.08,0.05-0.1,0.08l0.05-0.03c-0.12,0.09,0.11-0.03,0.04,0.03c0.02,0.01,0.11,0.04-0.02,0.05
				c0.06,0.05,0-0.02-0.01,0.15c0.19-0.11,0.01-0.01,0.19,0.01c-0.11,0.02-0.02-0.02-0.17,0.11c0.06-0.01-0.03,0.05,0.07,0.04
				c-0.02,0.01,0.01,0.02-0.05,0.04c-0.05-0.18,0.32,0.13,0.19,0.28c0.03,0.01,0.08,0.02,0.11,0.03c-0.04,0.02-0.07,0.01-0.09,0.01
				c0.01-0.01,0.04,0.04,0.12,0.01c-0.03-0.03-0.06-0.11,0.06-0.13c-0.01,0.01-0.08-0.07,0.04-0.11
				C735.64,187.81,735.59,187.89,735.57,187.8z M729.58,183.82c0-0.01,0.01-0.02,0.01-0.03
				C729.59,183.78,729.61,183.8,729.58,183.82z M729.62,184.24c-0.01,0.02,0.01,0.03,0.01,0.05
				C729.61,184.28,729.59,184.29,729.62,184.24z"
                />
                <path
                  className="st10"
                  d="M734.79,186.35c0,0,0.01,0.01,0.04,0.01L734.79,186.35z"
                />
                <path
                  className="st10"
                  d="M722.2,198.32c0.15-0.2,0.02,1.48,2.14,1.81c-0.09-0.05-0.04-0.11,0.07,0c0.05-0.1,0.69,0.07,1.26-0.23
				l0,0.01c0.03-0.04,0.24-0.25,0.07-0.01c0.08-0.09,0.14-0.12,0.16-0.12c-0.03,0.06-0.07,0.1-0.04,0.12
				c-0.26,0.38-0.11,0.1-0.29,0.88l0.05-0.02c0.04,0.1-0.03,0.11-0.04,0.19c0.29-0.06-0.02,2.27,2.18,1.62
				c-0.02,0.04-0.05,0.08-0.08,0.12c0.58-0.31,1.04-0.64,1.4-1.01c-0.01,0.03,0,0.05-0.04,0.08c0.12-0.02,0.31-0.24,0.29-0.28
				c0.05,0.03,0.24-0.03,0.39-0.23c0.05,0.07,0.11-0.02,0.21,0.22c-0.11,0.01-0.04,0.33,0.03,0.45c-0.04-0.04-0.02,0.11-0.08,0.07
				c0.02,0.14,0.06,0.28,0.11,0.24c0.15,0.75-0.12,0.88,1.34,1.47c0,0-0.01,0-0.01,0c-0.01-0.07,0.87,0.31,1.63-0.73
				c0.04,0.19,0.24-0.23,0.27,0.02c0.02-0.04,0.04-0.1,0-0.11c0.05-0.05,0.08-0.06,0.07,0.02c0.1-0.07,0.03-0.34,0.15-0.28
				c0.01-0.12,0.02-0.24,0.1-0.4c0.01,0.02,0.04,0,0.01,0.05c0.11-0.3,0.04-0.54,0.14-0.86c0.04,0.06,0.14,0.07,0.13-0.3l-0.05,0.07
				c0.01-0.16-0.05-0.3-0.02-0.43l0.04,0.12l-0.02-0.29l-0.03,0.17c-0.17-0.45-0.08,0.01-0.11-1.14c0.13,0.07,0.1-0.23,0-0.29
				c0.01,0.02,0.02,0.01,0.03,0.05c-0.02-0.28-0.01-0.39,0.01-0.47l0.01,0.07c0.01-0.33,0.01,0.03-0.03-1.2l-0.01,0.06
				c-0.13-0.05,0.08-0.24,0.03-0.4c0.14-0.04,0.04,0.04,0.06-0.74l0.03,0.05c0.06-0.6,0.11-0.47,0.37-1.72
				c0.01,0.08,0.01,0.1,0.07-0.24c-0.06,0.01-0.05,0.03-0.11,0.08c-0.02-0.05-0.02-0.15,0.01-0.22c-0.01,0.15,0.09,0.03,0.14-0.03
				c-0.02,0-0.03-0.02-0.05-0.02c0.16-0.33-0.08-0.05,0.22-0.77c0,0.06-0.03,0.14-0.03,0.2c0.13-0.12,0.03-0.03,0.26-0.39
				l-0.04,0.04c-0.1-0.22,0.1-0.32,0.08-0.57c-0.02,0.12,0.15,0.22,0.31-0.28c-0.02,0.1,0.29-0.83,0.91-1.72
				c0.04,0.09,0.06,0.21,0.31-0.44l-0.07,0.06c0.04,0.01,0.1,0.12-0.09,0.16l0.04-0.08c-0.17-0.01-0.19,0.29-0.29,0.44
				c-0.05-0.05-0.08-0.14-0.34,0.29c-0.01-0.03-0.07-0.02-0.02-0.08c-0.17,0.16-0.12,0.35-0.23,0.38l0-0.02
				c-0.16,0.28-0.51,0.7-0.93,2c-0.02-0.02-0.18,0.33-0.27,0.5l-0.01-0.01c0.08,0.06,0,0.24-0.02,0.38
				c-0.05-0.02-0.06-0.05-0.07-0.13c0.01,0.02-0.05,0.01-0.03,0.29l-0.06-0.05c0.07,0.1-0.25,0.2-0.02,0.72l-0.03-0.11
				c0.01,0.04-0.06,0.31-0.05,0.07c-0.13,0.39-0.06,0.16-0.17,1l0,0c0.08,0.2-0.23,0.76-0.18,1.86c-0.04-0.14-0.04,0.05-0.03-0.14
				c-0.02,0.17-0.07,0.22,0,0.4c-0.07-0.06-0.08,0.13-0.05,0.22l0.04-0.12c0.01,0.09,0.01,0.18,0.01,0.26l-0.08-0.04
				c-0.04,0.38,0.06,0.79,0.17,1.61c0.2-0.16-0.2,0.4-0.03,0.33c-0.05,0.2-0.01,0.31-0.06,0.51c0.06-0.04,0.12,0.63-0.13,0.41
				c0.01,0.19-0.14,0.31-0.18,0.39l0.01-0.09c-0.01,0.05-0.02,0.1-0.04,0.14l0.08-0.02c-0.03,0.05-0.05,0.09-0.07,0.07
				c0.02,0.13,0.2,0.08-0.06,0.32c0.18-0.21-0.17-0.27-0.19,0.2c-0.02-0.05-0.1-0.02-0.11,0.01c0.04-0.05,0.1,0.08-0.11,0.27
				c0.03-0.01,0.05-0.03,0.08-0.04c-0.05,0.17-0.32,0.24-0.91,0.21c0.05,0.06,0.06,0.04-0.06,0.1c0.1-0.01,0.19-0.03,0.29-0.06
				c-0.05,0.04-0.12,0.07-0.18,0.11c0.09,0,0.19-0.06,0.24-0.06c-0.08,0.06-0.08,0.05-0.02,0.09c-0.44-0.01-0.2-0.14-0.98-0.29
				l0.06,0.07c-0.06,0.03-0.14-0.08-0.22-0.12c0.04-0.03,0.01-0.08,0.02-0.12c-0.03,0.03-0.1-0.14-0.2-0.12
				c-0.01-0.27,0.04-0.13-0.23-0.58c0.06,0.06,0.08-0.05,0.15,0.1c-0.14-0.42,0.01,0.07-0.49-1.7c0.01,0,0.01,0.01,0.03,0.04
				c-0.01-0.03-0.02-0.07-0.05-0.05c0.01,0,0.02,0,0.02,0.01c-0.23,0.09-0.54,0.24-0.66,0.33c0-0.01,0-0.01,0-0.02
				c-0.39,0.33-0.03-0.04-0.7,0.43l0.03-0.03c-0.19,0.08-0.03,0.06-0.82,0.8c-0.01-0.03-0.01-0.05-0.02-0.07
				c0,0.06-0.03,0.18-0.29,0.09l0.14-0.07c-0.11-0.05-0.18,0.08-0.26,0.09l0-0.04c-0.2,0.06-0.21,0.16-0.45,0.12l0.02,0.04
				c-0.35-0.04-0.1-0.08-0.68-0.39c0.12,0.04,0.28,0.14,0.39,0.16c-0.07-0.04-0.22-0.17-0.28-0.18c0.03,0.02,0.08,0.04,0.09,0.07
				c-0.46-0.57-0.23,0.08-0.04-2.1c-0.26,0.27,0.07-0.2-0.14-0.08c0.06-0.06-0.02-0.2,0.28-0.29c-0.17,0.25,0.01-0.23-0.19,0.09
				c0.14-0.25,0.38-0.5,0.74-1.08c-0.05,0.06-0.07,0.08-0.08,0.06c-0.09,0.06-0.21,0.13-0.32,0.17c0.06,0,0.12-0.01,0.16,0
				c-0.57,0.37-0.25-0.02-0.87,0.37c0.52-0.48-0.1,0.28-1.83,0.57l0.03-0.04c-0.17,0.03-0.07-0.03-0.41-0.03l0.07-0.02
				c-1-0.6-0.67,0.17-0.85-2.51c-0.02,0-0.11-0.1,0.04-0.27c-0.03,0.02,0.32-0.11,0.14-0.38c0.2-0.03,0.27-0.51,1.43-1.59
				l-0.01,0.08c0.26-0.52,0.07-0.25,0.89-1c0.01,0.01,0.02,0.04-0.01,0.08c0.1-0.1,0.47-0.3,0.33-0.38
				c0.06,0.07,0.25-0.19,0.35-0.33l0.02,0.07c-0.01-0.03,0.12-0.17,0.43-0.47l-0.03,0.08c0.2-0.14,0.01-0.24,0.46-0.31
				c-0.06,0.05,0.14-0.22,0.12-0.11c0.27-0.07-0.05-0.07,0.53-0.29c-0.19,0.1-0.32,0.2-0.32,0.18c0.03,0.05,0.18-0.03,0.12,0.12
				c0.1-0.1-0.03-0.04,0.42-0.37l-0.01,0.01c0.19-0.13,0.13-0.03,0.47-0.22c-0.04-0.06,0.07-0.1-0.05-0.1
				c0.02-0.01-0.03,0.02-0.09,0.07l0-0.01c-0.05,0.03-0.11,0.07-0.18,0.11c0.41-0.41,0.39-0.1,1.43-0.72
				c-0.16,0.14-0.16,0.11,0.47-0.2l-0.02,0.04c0.52-0.03,0.69-0.5,1.28-0.47c-0.04,0-0.06,0-0.07-0.01c0.28,0.09,1.51-0.53,3.2-0.51
				l-0.05-0.05c0.86,0.06-0.17-0.09,1.07-0.42c-0.15,0.04-0.1-0.13-0.5,0.07c0.02-0.22-0.27,0.16-0.24-0.08
				c-0.06,0.07-0.21-0.14-0.9,0.13c0.15-0.15-0.34,0.03-0.31-0.11c-0.11,0.13-0.44,0.1-0.64,0.21c0.01-0.01,0.03-0.03,0.05-0.03
				c-0.12,0.01-0.16-0.02-0.25-0.03c0.14-0.02-0.51,0.44-0.27,0.02c0.02,0.07-0.24,0.13-0.18,0.18c-0.12-0.03-0.1-0.01-0.18-0.07
				c0.11,0.11-0.6,0.25-0.36,0.02c-0.31,0.31-0.05-0.03-1.2,0.52c0.04,0.08,0.11,0.13-0.11,0.17c-0.35-0.43-0.02-0.09-1.47,0.48
				l0.02-0.12c-0.57,0.52-1.4,0.49-3.29,2.1l0.03-0.05c-0.18,0.11-0.09,0.26-0.28,0.33l0.02-0.03c-0.16,0.07-0.19,0.41-1.88,1.79
				c0.02,0.02-0.04,0.12-0.22,0.29c0.15,0.04-0.2-0.02-0.42,0.66c-0.02-0.02,0.01-0.09,0.02-0.15c-0.06,0.24-0.09-0.01-0.14,0.21
				c0.03-0.02,0.03,0.04,0.04,0.05c-0.09,0.07-0.21,0.28-0.27,0.46l-0.03-0.05c-0.15,0.3-0.23,0.67-0.27,1.05
				C722.16,197.64,722.32,197.64,722.2,198.32z M728.28,191.97c-0.01,0.05,0,0.06-0.08,0.07
				C728.21,192.02,728.21,192.04,728.28,191.97z M727.77,191.73c-0.02,0.01-0.04,0.02-0.08,0.05
				C727.72,191.76,727.69,191.78,727.77,191.73z M727.51,191.94c0.01-0.02-0.07,0.01-0.13,0.02
				C727.36,191.99,727.54,191.81,727.51,191.94z"
                />
                <path
                  className="st10"
                  d="M735.68,190.5c-0.02,0.02-0.04,0.03-0.07,0.07c0-0.04-0.01,0.02-0.15-0.04c0.03,0.09-0.02,0.09-0.04,0.12
				c-0.02-0.05-0.02-0.07-0.02-0.1c-0.02,0.02-0.03,0.01-0.04,0.08l-0.02-0.07c-0.03,0.02-0.04,0.1-0.11,0.08
				c0.02,0.06,0.11,0.15,0.08,0.21c-0.02-0.03-0.04-0.08-0.03-0.1c-0.08-0.02,0.04,0.03-0.13-0.11c0.01,0.04,0.03,0.14-0.05,0.01
				c0.01,0.04-0.03,0.01,0,0.08c-0.01,0-0.03-0.03-0.04-0.04c0,0.04,0,0-0.02,0.12l0,0c0.1,0.11-0.18-0.02-0.46,0.14
				c0.03-0.04-0.13-0.05-0.03-0.01c-0.3-0.01-0.21,0.34-0.5,0.12c-0.01,0.04,0.01,0.05,0.04,0.05c-0.06-0.02-0.07,0.03-0.12,0.03
				c0.04,0.05-0.07,0.24-0.12,0c-0.01,0.07-0.09,0-0.12,0.01l0.01-0.03c-0.01,0.02-0.02,0.02-0.03,0.03l0.04,0.05
				c-0.02,0-0.03,0-0.04-0.02c0.01,0.04,0.05,0.08,0.05,0.14c-0.03-0.02-0.03,0.05-0.08-0.01c0.06,0-0.04-0.07,0-0.1
				c-0.09,0-0.06-0.07-0.07,0.11c-0.01-0.04-0.05-0.07-0.05-0.06c0.02-0.01,0.04,0.06,0.04,0.1c-0.05-0.01-0.02,0.04-0.04,0.05
				c0.03,0.02,0.04,0.01,0.01,0.07c-0.02-0.03,0.01-0.03-0.01-0.04c0.01,0.08-0.09-0.04-0.11,0.02c-0.03-0.02-0.04-0.07-0.06-0.08
				c0.02,0.1-0.13-0.06-0.06,0.13l0.05-0.07c0,0.14,0.09,0.12-0.22,0.15c0.03,0.02,0.07,0.03-0.01,0.07
				c-0.02-0.04-0.04-0.05-0.07-0.07c0.02,0.03-0.05,0.02,0,0.11c-0.11-0.19-0.07,0.26-0.14,0c-0.03,0.06,0-0.02-0.04,0.23
				c-0.13-0.11-0.13,0.09-0.24,0c-0.14,0.21,0.01,0.1-0.41,0.39c-0.02-0.06,0-0.04-0.04-0.09c0.13,0.25-0.09,0.1-0.26,0.16
				c-0.01,0.03-0.01,0.12-0.04,0.16c-0.02-0.07,0-0.13,0-0.18c-0.09,0.19,0.07,0.05-0.56,0.32c0.05,0.03,0.03,0.07,0.07,0.11
				c-0.04,0-0.12-0.04-0.23-0.13c0.04,0.04,0.03,0.07,0,0.07c0.07-0.02-0.05,0.13-0.07,0.02c-0.01,0.04-0.11,0.08-0.06,0.2
				c-0.1-0.28-0.14,0.23-0.94,0.79l0.01,0c-0.08-0.06-0.09-0.01-0.12,0.01l0.09,0.1c-0.2-0.08-0.11,0.21-0.27,0.13
				c0.01,0.04-0.01,0.06,0,0.1c-0.06-0.03-0.05-0.09-0.17,0.06l-0.03-0.05c0.06,0.09-0.17,0.09-0.18,0.4
				c-0.07-0.06-0.05,0.05-0.05,0.11c-0.08-0.07-0.03-0.11-0.16,0.2c0.03,0.04-0.01,0.09-0.11,0.14c0.03-0.04,0.06-0.07,0.06-0.06
				c-0.01-0.07-0.29,0.07-0.14,0.21c-0.01,0,0,0,0-0.03l0,0c0.01-0.01,0.02-0.03,0.03-0.04c0.02,0.03,0.03,0.07-0.01,0.06
				c0.03,0.04,0.06,0.07,0.08,0.09c-0.06,0.02-0.24-0.06-0.25,0.28c-0.28-0.03-0.09-0.07-0.23,0.26c0.01,0,0.02,0,0.04,0
				c-0.04,0.06-0.22,0.06-0.24,0.46l-0.07-0.03c0.19-0.04-0.21,0.19-0.09,0.54c0-0.01,0-0.01,0-0.01c0.07,0.03,0.16,0.06,0.31,0.1
				l-0.04-0.07l0.06,0.01l-0.01,0.03c0.06,0,0.02-0.03,0-0.05c0.03,0.01,0.05,0.01,0.06,0.01l-0.08-0.09
				c0.04,0.02,0.07,0.06,0.11,0.04c-0.04-0.04-0.1-0.03-0.12-0.07c0.06,0.01,0.09,0.03,0.06-0.01c0.03,0.01,0.04,0.02,0.05,0.04
				c-0.02-0.03,0.01-0.06-0.04-0.09c0.07,0.01,0.02-0.07,0.11-0.02c-0.02-0.02-0.06-0.07-0.05-0.09c0.1,0.09,0.04-0.06,0.16,0.01
				c-0.09-0.08,0-0.11-0.04-0.18c0.01,0.01,0.02,0.02,0.01,0.02c0.02-0.02,0.05-0.01,0.08-0.01c-0.08-0.05-0.08-0.1-0.12-0.15
				c0.1,0.02,0.09,0.07,0.17,0.11c-0.07-0.04-0.04-0.09-0.1-0.11c0.06,0.01,0.04,0,0.11,0.03c-0.1-0.07-0.01-0.03-0.06-0.09
				c0.07,0,0.1,0.05,0.09,0c0.11,0.13,0.1-0.16-0.02-0.36c-0.01,0.01,0.01,0.02,0.03,0.04l-0.01-0.03c0.11,0.07,0.02,0.11,0.13,0.02
				l-0.05,0.02c0.2,0.03,0.04-0.34,0.98-1.14c-0.04-0.05,0.02-0.21,0.21-0.19l-0.03-0.08l0.08,0.05c-0.04-0.12,0.08,0.1,0.1-0.17
				c0.02,0.02,0.02,0.04,0.02,0.06c-0.06-0.11,0.1,0.05,0-0.08c0.05,0.02,0.09-0.01,0.1-0.05c0.07,0.05,0.02,0.06,0.17-0.12
				c-0.04,0-0.09-0.07-0.08-0.09l0.06,0.05c-0.03-0.04-0.1-0.14,0.02,0.01l0.01-0.04c0.02,0.02,0.02,0.03,0.04,0.06
				c-0.02-0.05,0.1,0.02,0.05-0.06c-0.05,0.33,0.48-0.69,1.12-0.86c-0.01-0.01-0.07-0.09,0.02-0.03c-0.19-0.08,0.55-0.31,0.46-0.31
				c0.04-0.15,0.2-0.13,0.26-0.26c0,0.02,0.02,0.04,0.01,0.05c0.05,0.04,0.08-0.04,0.05-0.09c-0.07,0.04,0.21-0.04,0.26-0.22
				c0.05,0.1,0.11,0.04,0.11-0.02c0.01,0.03,0.04,0.02,0.05,0.07c0.03-0.02,0.04-0.05,0.01-0.1c0.12,0.09,0.05-0.15,0.16-0.02
				c0.05-0.1,0.11-0.18,0.17-0.24c0.09,0.08,0.02,0.07,0.3-0.12c0,0.01,0,0.02,0,0.03c0.01-0.01,0.04,0,0.01-0.04l-0.01,0.02
				c-0.05-0.06-0.02-0.16-0.04-0.2c0.05,0.14,0.08,0,0.13,0.18c0-0.01,0-0.04-0.01-0.07c0.12,0.03,0.02,0.01,0.34-0.11
				c-0.02,0,0.07-0.02-0.01-0.08c0.01,0.04,0.07-0.13,0.06-0.03l0.05-0.04c-0.04-0.03-0.05-0.02-0.05-0.12
				c0.06,0.03,0.07-0.01,0.16,0.08c-0.02-0.08,0.06-0.05,0.05-0.14c0,0.02,0.02,0.06,0.03,0.07l-0.01-0.06
				c0.05,0.08,0.07,0.02,0.05,0c0.01,0.05,0.18-0.17,0.16,0.03c0.06-0.05,0.09-0.17,0.17-0.18c-0.09-0.13-0.01-0.01,0.07-0.04
				c0,0.01,0.02,0.07,0.02,0.06c0.05,0,0.08-0.07,0.13-0.14c-0.02,0.02,0.16,0.07,0.1-0.06c0.09,0.06,0.23,0.14,0.29-0.22
				c-0.02,0.05,0.04,0.09,0.06,0.11l-0.02-0.05c0.08,0.13-0.01-0.11,0.05-0.04c0.01-0.02,0.07-0.11,0.05,0.02
				c0.08-0.06-0.02,0,0.17,0.02c-0.08-0.2-0.01-0.01,0.04-0.2c0.01,0.11-0.02,0.02,0.09,0.18c0-0.07,0.05,0.03,0.05-0.07
				c0.01,0.02,0.02-0.01,0.04,0.05c-0.31-0.04,0.23,0.03,0.39-0.31c0.02,0.04,0.01,0.07,0.01,0.1c-0.01-0.01,0.05-0.04,0.02-0.13
				c-0.02,0.02-0.05,0.03-0.09,0.03c0.09-0.11,0.23-0.25-0.04-0.04c-0.04-0.17-0.06,0.11-0.14-0.07c-0.09,0-0.14,0.01-0.08,0.2
				l-0.1-0.16l0.02,0.07c-0.01,0-0.02,0-0.03-0.03c-0.01,0.06,0.02,0.03,0.02,0.08c-0.06-0.05-0.08,0.07-0.13-0.04
				c0.01,0.06,0.05,0.03,0.03,0.08c-0.04-0.15-0.06,0.1-0.11-0.09C735.77,190.55,735.76,190.44,735.68,190.5
				C735.69,190.5,735.63,190.34,735.68,190.5z M728.98,194.97c0-0.01,0.03-0.02,0.03,0.03
				C728.99,194.98,728.98,194.98,728.98,194.97z M729.41,195.11c0.01,0.01,0.03,0.01,0.06,0.01
				C729.45,195.14,729.44,195.17,729.41,195.11z"
                />
                <path
                  className="st10"
                  d="M638.24,285.52c0.04-0.2,0.33-0.06,0.36-0.71l0.07,0.03c0.11-0.35,0-0.21,0.41-1.02
				c0.02,0.02,0.01,0.09,0,0.15c0.02-0.23,0.09,0,0.11-0.21c-0.03,0.02-0.04-0.03-0.05-0.04c0.09-0.07,0.19-0.27,0.24-0.43
				l0.03,0.05c0.14-0.27,0.26-0.58,0.41-0.89c0.32-0.43-0.02,0.06,2.08-4.06l0,0.02c0.73-1.2-0.15,0.09,2.6-4.46l0.02,0.12
				c0.19-0.51,0.57-0.92,0.78-1.4c0,0.04,0.02,0.05-0.01,0.09c0.11-0.06,0.22-0.34,0.18-0.37c0.15-0.18,0.05,0.05,0.77-1.3
				c0.09,0.07,0.26-0.24,0.29-0.37c0.01,0.05,0.09-0.07,0.1,0c0.08-0.12,0.13-0.24,0.07-0.25c0.35-0.32,0.24-0.23,0.97-1.57
				c0.01,0.04,0.01,0.09,0.01,0.12c0.17-0.26,0.18-0.26,0.83-1.29c0.01,0.01,0,0.04-0.01,0.07c0.05-0.17,0,0.03,0.01-0.4
				c0.06,0.17,0.21-0.24,0.27,0.01c0.02-0.04,0.02-0.1-0.01-0.11c0.04-0.04,0.07-0.05,0.07,0.02c0.1-0.2,0.01-0.17,0.13-0.33
				c0.02,0.01,0,0.05,0.02,0.09c0.02-0.11,0.04-0.22,0.15-0.35c0.01,0.02,0.03,0.01,0,0.05c0.16-0.25,0.18-0.49,0.4-0.72
				c0.02,0.08,0.08,0.12,0.26-0.19l-0.08,0.03c0.09-0.12,0.11-0.27,0.21-0.36l-0.03,0.11l0.15-0.24l-0.12,0.11
				c0.05-0.15-0.11-0.05,0.03-0.2c0.22-0.2,0.1-0.03,0.58-0.74c0.05,0.13,0.22-0.11,0.18-0.23c-0.01,0.02,0.01,0.02-0.01,0.06
				c0.15-0.23,0.23-0.31,0.3-0.37l-0.03,0.06c0.22-0.26-0.01,0.03,0.68-1l-0.04,0.04c-0.08-0.11,0.2-0.16,0.24-0.32
				c0.15,0.04,0.01,0.06,0.43-0.61l0,0.05c0.32-0.51,0.33-0.36,1.06-1.42c-0.03,0.1-0.06,0.15,0.15-0.21
				c-0.06-0.01-0.05,0.01-0.13,0.04c0-0.05,0.03-0.14,0.08-0.21c-0.06,0.14,0.08,0.05,0.14,0.02c-0.02,0-0.02-0.03-0.04-0.04
				c0.26-0.26-0.05-0.07,0.44-0.66c-0.02,0.06-0.07,0.12-0.09,0.18c0.14-0.06,0.13-0.08,0.2-0.15l-0.01,0.01l0.16-0.16l-0.05,0.03
				c-0.04-0.24,0.18-0.29,0.22-0.54c-0.04,0.11,0.1,0.25,0.36-0.22c-0.02,0.08,0.42-0.75,1.1-1.62c0.03,0.1,0.06,0.21,0.32-0.44
				l-0.07,0.06c0.04,0.01,0.1,0.12-0.1,0.16l0.04-0.08c-0.17-0.01-0.19,0.29-0.3,0.43c-0.05-0.06-0.07-0.14-0.35,0.26
				c0-0.03-0.07-0.03-0.01-0.08c-0.18,0.14-0.15,0.33-0.26,0.35l0.01-0.02c-0.2,0.26-0.57,0.62-1.28,1.77
				c-0.02-0.03-0.26,0.27-0.4,0.4l-0.01-0.01c0.05,0.08-0.07,0.22-0.14,0.35c-0.04-0.04-0.04-0.07-0.02-0.14
				c0,0.02-0.04-0.01-0.12,0.26l-0.04-0.07c0.03,0.12-0.3,0.09-0.28,0.66l0.01-0.11c-0.01,0.05-0.18,0.25-0.07,0.05
				c-0.28,0.3-0.12,0.12-0.57,0.83l0,0c-0.11,0.28,0,0-0.54,0.72c-0.07-0.08-0.17,0.23-0.58,0.75c0.04-0.13-0.06,0.01,0.05-0.13
				c-0.12,0.13-0.17,0.14-0.23,0.33c-0.01-0.09-0.14,0.06-0.17,0.14l0.11-0.07c-0.05,0.08-0.1,0.14-0.15,0.21l-0.04-0.08
				c-0.25,0.28-0.47,0.64-0.88,1.36c0.25-0.02-0.4,0.22-0.22,0.25c-0.16,0.14-0.19,0.26-0.34,0.42c0.06-0.01-0.16,0.65-0.31,0.35
				c-0.06,0.19-0.24,0.28-0.31,0.36l0.04-0.1c-0.02,0.06-0.05,0.1-0.08,0.14l0.08-0.01c-0.04,0.04-0.07,0.09-0.09,0.07
				c0.01,0.15,0.18,0.11-0.11,0.35c0.13-0.05-0.21-0.25-0.19,0.23c-0.02-0.05-0.1,0-0.12,0.04c0.04-0.07,0.09,0.06-0.07,0.32
				l0.07-0.07c0.01,0.22-0.3,0.3-0.74,0.84c0.08-0.02,0.08-0.03,0.04,0.12l0.15-0.3l-0.05,0.23c0.06-0.08,0.09-0.2,0.13-0.24
				c-0.02,0.11-0.02,0.09,0.04,0.08c-0.3,0.41-0.36,0.03-0.75,0.91l0.08-0.07c0.03,0.07-0.1,0.17-0.14,0.26
				c-0.04-0.04-0.09,0.01-0.14,0.03c0.03,0.02-0.13,0.19-0.06,0.27c-0.23,0.19-0.17,0.05-0.32,0.59c0-0.09-0.09-0.02-0.04-0.17
				c-0.32,0.73,0.02,0.02-1.08,1.83c-0.01-0.02-0.02-0.01,0-0.04c-0.24,0.49-0.02-0.01-0.48,0.65l0.01-0.04
				c-0.13,0.15-0.05,0.03-0.48,1.06c-0.02-0.02-0.03-0.03-0.04-0.04c0.02,0.05,0.03,0.16-0.22,0.25l0.09-0.15
				c-0.12,0.03-0.11,0.19-0.17,0.26l-0.03-0.03c-0.13,0.23-0.04,0.29-0.2,0.53l0.04-0.01c-0.11,0.41-0.26-0.01-0.43,0.76
				c-0.01-0.13,0.07-0.33,0.1-0.47c-0.05,0.09-0.17,0.3-0.16,0.37c0.01-0.04,0.02-0.1,0.05-0.12c-0.22,0.37-0.21,0.74-1.68,2.46
				c0.06,0,0.03,0.1-0.05,0.2l0.15-0.11c-0.08,0.3-0.24,0.16-0.32,0.46l0.01-0.11c-0.13,0.18-0.12,0.25-0.78,1.65l-0.02-0.04
				c-0.05,0.2-0.08,0.06-0.19,0.44l0-0.09c-0.21,0.29-0.17,0.53-0.29,0.81c-0.1-0.05-0.26,0.18-0.34,0.3l-0.01-0.01
				c-0.55,0.91,0.17-0.28-2.19,4.72l-0.02-0.08c-0.06,0.59-0.02,0.25-0.44,1.3c-0.01-0.01-0.04-0.03-0.02-0.08
				c-0.06,0.13-0.27,0.5-0.12,0.5c-0.09-0.03-0.13,0.29-0.15,0.46l-0.06-0.05c0.02,0.02-0.01,0.21-0.11,0.63l-0.02-0.08
				c-0.1,0.23,0.11,0.2-0.22,0.52c0.02-0.07,0.01,0.26-0.04,0.16c-0.19,0.22,0.08,0.02-0.24,0.57c0.07-0.2,0.13-0.36,0.13-0.34
				c-0.06-0.02-0.13,0.14-0.17-0.02c-0.03,0.14,0.05,0.01-0.09,0.57l0-0.02c-0.06,0.23-0.08,0.11-0.21,0.48
				c0.07,0.02,0.02,0.12,0.11,0.04c-0.01,0.02,0.01-0.04,0.02-0.11l0,0.01c0.02-0.05,0.04-0.12,0.07-0.2
				c-0.03,0.6-0.24,0.34-0.5,1.54c0-0.21,0.03-0.19-0.16,0.48l-0.02-0.04c-0.28,0.44-0.01,0.86-0.35,1.34
				c0.02-0.03,0.03-0.05,0.05-0.05c-0.22,0.21-0.36,1.54-0.87,3.16l0.06-0.03c-0.29,0.81,0.12-0.14,0.14,1.13
				c-0.01-0.15,0.15-0.06,0.04-0.49c0.21,0.07-0.08-0.3,0.14-0.2c-0.04-0.07,0.17-0.16,0.13-0.88c0.09,0.19,0.08-0.32,0.21-0.25
				c-0.09-0.15,0.05-0.43,0.04-0.66c0.01,0.01,0.02,0.04,0.01,0.06c0.04-0.11,0.08-0.14,0.12-0.21c-0.04,0.13-0.18-0.63,0.1-0.24
				c-0.08-0.01,0-0.27-0.08-0.24c0.09-0.09,0.05-0.09,0.15-0.12c-0.15,0.05,0.07-0.62,0.16-0.31c-0.16-0.15,0.19-0.61,0.12-1.44
				c0.57-0.04,0.07,0.03,0.57-1.41l0.07,0.09c0.09-0.75,0.49-1.31,1.28-3.59l0,0.06c0.1-0.18-0.05-0.27,0.08-0.42l0,0.04
				C637.68,286.79,637.83,286.93,638.24,285.52z M636.01,289.94c-0.02-0.04-0.04-0.05,0.02-0.11
				C636.03,289.86,636.01,289.84,636.01,289.94z M636.55,289.78c0-0.02,0.01-0.04,0.02-0.08
				C636.56,289.73,636.57,289.7,636.55,289.78z M636.6,289.45c0.01,0.02,0.05-0.05,0.09-0.09
				C636.68,289.32,636.66,289.58,636.6,289.45z"
                />
                <path
                  className="st10"
                  d="M686.98,229.98C686.98,229.98,686.98,229.98,686.98,229.98c0.02,0.08,0.04-0.03,0.35,0.1l-0.06,0.05
				c0.07,0.01,0.06-0.04,0.07-0.08c0.06,0.01,0.08,0.09,0.07-0.12c0.16,0.2,0.02-0.16,0.14,0.01c0.02-0.06,0.09-0.09,0.07-0.15
				c0.08,0.02,0.11-0.11,0.18-0.02c0-0.03,0.01-0.12,0.04-0.15c0.02,0.15,0.13-0.09,0.2,0.03c-0.03-0.13,0.13-0.17,0.18-0.28
				c0,0.01,0,0.03-0.01,0.03c0.05-0.03,0.09-0.01,0.13-0.01c-0.05-0.08,0.01-0.15,0.01-0.23c0.11,0.03,0.03,0.12,0.11,0.18
				c-0.05-0.06,0.05-0.14-0.01-0.18c0.08,0.01,0.05-0.01,0.13,0.04c-0.07-0.1,0.03-0.05,0.02-0.13c0.05,0.04,0.14-0.09,0.15,0.08
				c-0.09-0.19,0.28-0.07,0.37-0.64c-0.02,0.01-0.01,0.03-0.01,0.06l0.02-0.05c-0.08,0.23,0.23,0.01,0.07,0.06
				c0.17,0.37,0.33-0.3,1.08-0.79c-0.24,0.15,0.41-0.13,0.98-0.64l-0.01,0.03c0.1-0.03,0-0.19,0.12-0.18l-0.01,0.02
				c0.12,0.06,0.5-0.36,1.47-0.95c0.01,0.03-0.01,0.06-0.02,0.09c0-0.16,0.1,0.07,0.07-0.11c0.07,0.01,0.16-0.05,0.21-0.11
				l0.02,0.05c0.13-0.07,0.24-0.19,0.38-0.28c-0.01,0.08-0.13-0.2-0.01-0.05c-0.01-0.05-0.06-0.17,0.03,0.01l0.05-0.06
				c0.02,0.02,0.01,0.04,0.02,0.07c0.28-0.22,0.05,0.04,0.77-0.7c0.04,0.05,0.14-0.02,0.11,0.05c0.11-0.09,0.14-0.11,0.26-0.25
				c-0.06-0.01-0.06-0.08,0.03-0.01c0.12-0.19,0.49-0.1,0.55-0.3l0,0.01c0.34-0.28,0-0.05,1.02-0.57c-0.02-0.03-0.07-0.08,0.05-0.04
				c-0.38-0.09,0.67-0.26,1.64-1.11c0,0.02,0.01,0.05-0.01,0.06c0.27-0.2,0-0.08,0.74-0.56c0.05,0.1,0.19-0.01,0.21-0.08
				c0,0.04,0.06,0,0.07,0.06c0.06-0.04,0.1-0.09,0.06-0.13c0.23-0.01,0.14,0.01,0.73-0.51c-0.02,0.29-0.29,0.29,0.61-0.32
				c0,0.01,0,0.03-0.01,0.04c0.04-0.09-0.01,0.02,0.03-0.27c0.03,0.15,0.15-0.04,0.17,0.16c0.01-0.02,0.02-0.05,0-0.08
				c0.03-0.01,0.05,0.01,0.04,0.06c0.08-0.08,0.02-0.11,0.1-0.15c0.01,0.02,0,0.04,0.01,0.07c0.02-0.07,0.04-0.13,0.12-0.16
				c0,0.02,0.02,0.03,0,0.04c0.12-0.08,0.15-0.23,0.3-0.27c0.01,0.12,0.06,0,0.12,0.08l0.06-0.07l-0.05-0.02
				c0.06-0.03,0.09-0.12,0.16-0.13l-0.03,0.06l0.11-0.08l-0.09,0.01c0.09-0.13-0.1-0.13,0.31-0.12c-0.01-0.09,0.14-0.1,0.14-0.2
				c0,0.02,0.02,0.07,0.03,0.07l0-0.07c0.04,0,0.05,0.01,0.06,0.04c0.09-0.04-0.09-0.09,0.04-0.1c-0.01,0.01,0,0.02-0.01,0.03
				c0.12-0.08,0.17-0.09,0.22-0.09l-0.03,0.03l0.06,0.01l-0.01-0.05c0.16,0.04-0.02-0.17,0.89-0.56l0,0.04
				c-0.01-0.01,0.21,0.01,0.21-0.17c0.28,0.07,0.3-0.28,0.54-0.23c-0.06,0.02,0.16,0.02,0.09-0.21c-0.04,0.07,0.04,0.08,0.09,0.1
				c-0.01-0.01-0.01-0.04-0.02-0.05c0.12,0.1,0-0.09,0.1-0.07c-0.01,0.02,0.15-0.19,0.09,0c0.05-0.03,0.05-0.17,0.13-0.14
				c-0.02,0.03-0.05,0.05-0.07,0.08c0.09,0.04,0.09,0.02,0.14,0.01l0,0l0.11-0.02c-0.1-0.18-0.01-0.01,0.14-0.26
				c-0.03,0.13-0.02,0.06,0.12,0.17c0.02-0.08,0.1,0,0.12-0.11c0.01,0.01,0.02,0.01,0.04,0.01c-0.03-0.03,0.13-0.15,0.65-0.48
				c0.03,0.15,0.13-0.17,0.11,0.07c0.04-0.05,0.07-0.11,0.11-0.16c0.03,0.03,0,0.07-0.01,0.11c-0.01-0.01,0.14-0.09,0.07-0.18
				c-0.01,0.2-0.01-0.19-0.26,0.16l-0.02-0.11l-0.04,0.12c-0.03-0.02-0.07-0.06-0.04-0.09c-0.07-0.01-0.04-0.04-0.15,0.07
				c0-0.02-0.04-0.06,0-0.06c0.01-0.02-0.16,0.26-0.62,0.32c0,0.06,0.08,0.01,0.03,0.08c-0.04-0.16-0.13,0.16-0.18-0.05
				c0,0.18-0.12-0.1-0.33,0.25c-0.01-0.05,0,0.03-0.28,0.04c0.03,0.09-0.06,0.12-0.11,0.16c-0.02-0.05-0.02-0.07,0-0.11
				c0,0.01-0.04-0.02-0.09,0.11l-0.02-0.07c-0.07,0.04-0.11,0.15-0.23,0.16c0.02,0.06,0.16,0.12,0.08,0.21
				c-0.02-0.03-0.05-0.08-0.03-0.11c-0.01,0.01-0.02,0.04-0.04,0.04l0.01-0.07c-0.01,0.05-0.13,0.07-0.05-0.01
				c-0.04,0.01-0.1,0.01-0.14-0.01c0.07,0.09-0.16-0.03-0.28,0.27l0,0c-0.04,0.14-0.37,0.08-0.8,0.39c0.04-0.07-0.04-0.02,0.04-0.06
				c-0.08,0.02-0.12,0-0.16,0.1c0-0.07-0.09-0.04-0.12,0l0.07,0.01c-0.24,0.06-0.06-0.06-0.51,0.19c0.3,0.14-0.38,0.27-0.33,0.21
				l0.01,0.03c-0.05-0.01-0.14,0.05-0.14-0.01c-0.04,0.05,0,0.05,0.05,0.05c-0.11,0.01-0.14,0.07-0.24,0.1
				c0.05-0.01-0.15,0.37-0.22,0.07c-0.05,0.1-0.17,0.05-0.22,0.07l0.03-0.05c-0.02,0.03-0.04,0.04-0.06,0.05l0.05,0.04
				c-0.14,0.01-0.06-0.04-0.16,0.17c0.16-0.02-0.1-0.3-0.14,0.05c-0.01-0.05-0.07-0.06-0.08-0.04c0.02-0.03,0.08,0.07-0.06,0.19
				l0.05-0.01c0.01,0.05-0.03,0.06-0.07,0.1c-0.02-0.03,0.03-0.05,0.01-0.05c-0.02,0.19-0.49-0.12-0.45,0.21l0.12-0.12l-0.05,0.13
				c0.04-0.02,0.07-0.08,0.1-0.09c-0.02,0.07-0.02,0.05,0.02,0.08c-0.24,0.19-0.13-0.23-0.55,0.19c0.06,0.01,0.1,0-0.05,0.1
				c-0.02-0.04-0.06-0.04-0.09-0.06c0.02,0.03-0.1,0.05-0.06,0.15c-0.15,0-0.12-0.07-0.25,0.21c0-0.06-0.06-0.07-0.01-0.14
				c-0.08,0.1,0.02-0.02-0.18,0.31c-0.14-0.05-0.18-0.02-0.65,0.29c0-0.02-0.01-0.02,0-0.02c-0.19,0.18-0.02-0.02-0.36,0.15
				l0.01-0.02c-0.08-0.01-0.1,0.13-0.19,0.17c0,0,0-0.01-0.01-0.02c-0.02,0.11-0.11,0.2-0.19,0.27c-0.01-0.02-0.02-0.04-0.02-0.05
				c-0.11-0.06-0.34-0.05-0.73,0.52c0-0.09,0.07-0.17,0.1-0.25c-0.04,0.03-0.14,0.09-0.13,0.15c0.06-0.04,0.07-0.05-0.1,0.17
				c-0.14-0.13-0.08-0.09-0.85,0.28c0.09-0.01-0.01,0.26-0.06,0.09c0,0.03-0.02,0.06,0,0.08c-0.08,0-0.17-0.02-0.28-0.09
				c0.04,0.04,0.01,0.09-0.05,0.1l0.11,0.01c-0.08,0.15-0.17-0.04-0.25,0.1l0.01-0.07c-0.05,0.06-0.23,0.15-0.22,0.29
				c-0.02-0.01-0.06-0.03-0.04-0.06c-0.14,0.02-0.16,0.12-0.6,0.46l0.01-0.06c-0.33,0.42-0.35,0.04-1.44,0.92
				c-0.07,0.2-0.09,0.05-0.82,0.68c-0.12-0.07-0.02-0.06-0.32,0.12l0-0.06c-0.1,0.27,0.01,0.13-0.48,0.46
				c-0.01-0.01-0.02-0.04,0-0.06c-0.06,0.04-0.26,0.1-0.16,0.2c-0.06-0.08-0.13,0.08-0.17,0.16l-0.03-0.06
				c0.01,0.02-0.03,0.1-0.17,0.28l0-0.06c-0.19,0.12-0.09,0.06-0.53,0.36c0.08-0.06,0.15-0.1,0.15-0.1c-0.03-0.15-0.33,0-0.43,0.32
				c-0.01,0,0.01-0.01,0.03-0.05l0,0.01c0.02-0.02,0.05-0.04,0.08-0.06c-0.01,0.05-0.02,0.09-0.07,0.09
				c0.01,0.05,0.02,0.1,0.02,0.13c-0.25-0.02-0.25-0.24-0.56,0.27c0.01-0.02,0.03-0.04,0.03-0.06c-0.04,0.07-0.16,0.14-0.12,0.22
				c-0.42-0.06-0.06-0.07-0.61,0.39c0.02,0,0.03,0,0.04,0.01c-0.32,0.15-0.12,0-1.69,1.41c0,0-0.01-0.01-0.02-0.03L686.98,229.98z
				 M690.41,227.67c0-0.01-0.08,0.09-0.08-0.01C690.34,227.68,690.38,227.67,690.41,227.67z M690.27,227.77
				c-0.01,0.01,0,0-0.03,0.03C690.25,227.8,690.26,227.79,690.27,227.77z M689.85,227.53c-0.01-0.01,0,0-0.01-0.03
				C689.85,227.48,689.91,227.47,689.85,227.53z"
                />
                <path
                  className="st10"
                  d="M606.94,293.14c0.11,0.34,0.51,0.29,0.6,0.59c-0.07-0.07,0.07,0.18,0.28,0.06
				c-0.1-0.04-0.06,0.07-0.05,0.12c0.01-0.02,0.03-0.02,0.04-0.04c-0.01,0.17,0.06-0.03,0.14,0.1c-0.03-0.01,0.3,0.13,0.06,0.1
				c0.07,0.06,0.22,0.02,0.24,0.13c-0.04-0.01-0.08-0.05-0.13-0.06c0.02,0.12,0.04,0.11,0.08,0.16l0-0.01l0.09,0.13l-0.02-0.04
				c0.2-0.05,0.2,0.13,0.39,0.15c-0.16-0.01-0.08,0.01-0.1,0.18c0.1,0,0.06,0.12,0.2,0.12c-0.09-0.02,0.56,0.34,1.06,0.85
				c-0.09,0.03-0.13,0.11,0.28,0.25l-0.03-0.06c-0.07,0.06,0.1-0.1-0.33-0.28c0.15-0.01,0.16-0.13-0.13-0.29
				c0.03,0,0.04-0.06,0.07-0.02c-0.07-0.15-0.23-0.11-0.22-0.21l0.01,0c-0.15-0.27-0.07,0.04-0.6-0.42
				c0.15-0.08-0.25-0.11-0.06-0.23c-0.2,0.02,0.09-0.07-0.48-0.34c0.04-0.03-0.02,0.02-0.22-0.32c-0.08,0.05-0.17-0.05-0.25-0.09
				c0.04-0.04,0.07-0.04,0.12-0.03c-0.02,0,0.01-0.04-0.19-0.09l0.06-0.04c-0.09-0.08-0.23-0.1-0.32-0.23
				c-0.05,0.04-0.02,0.22-0.17,0.14c0.02-0.03,0.05-0.08,0.09-0.06c-0.01-0.01-0.06-0.01-0.07-0.04l0.09,0
				c-0.04,0-0.16-0.14-0.02-0.06c-0.04-0.05-0.08-0.12-0.08-0.17c-0.22,0.02,0,0-0.47-0.26l0,0c-0.17-0.01-0.33-0.42-0.96-0.84
				c0.1,0.02,0-0.05,0.09,0.03c-0.08-0.09-0.08-0.14-0.22-0.17c0.07-0.02-0.02-0.12-0.08-0.14l0.04,0.09
				c-0.22-0.35,0.09-0.04-0.99-0.8c0.02,0.13-0.15-0.07-0.02-0.08l-0.03,0.01c-0.03-0.06-0.15-0.15-0.09-0.17
				c-0.08-0.03-0.06,0.02-0.02,0.07c-0.09-0.12-0.18-0.14-0.28-0.25c0.02,0.06-0.5-0.08-0.23-0.24c-0.14-0.04-0.18-0.18-0.24-0.24
				l0.07,0.02c-0.04-0.01-0.07-0.03-0.1-0.06l0,0.07c-0.03-0.03-0.06-0.05-0.04-0.07c-0.1,0-0.11,0.19-0.26-0.06
				c0.01,0.11,0.24-0.22-0.15-0.15c0.04-0.02,0.02-0.09-0.01-0.1c0.05,0.02-0.05,0.1-0.25-0.02l0.04,0.05
				c-0.19,0.03-0.19-0.23-0.55-0.56c0,0.07,0.01,0.06-0.1,0.06l0.22,0.1l-0.17-0.01c0.06,0.04,0.14,0.05,0.17,0.09
				c-0.09,0-0.07-0.01-0.07,0.05c-0.31-0.21,0.06-0.3-0.61-0.56l0.04,0.06c-0.06,0.03-0.12-0.07-0.19-0.09
				c0.03-0.04,0.01-0.08,0-0.12c-0.02,0.03-0.13-0.09-0.2-0.02c-0.11-0.18-0.01-0.15-0.42-0.21c0.07-0.02,0.03-0.09,0.14-0.06
				c-0.05-0.03-0.09-0.07-0.1-0.04c0.09,0.03-0.17-0.15-0.37-0.06c-0.05-0.16-0.13-0.23-0.82-0.62c0.01-0.01,0.01-0.02,0.03-0.01
				c-0.34-0.14,0.01-0.02-0.45-0.34l0.03,0.01c-0.05-0.09-0.22-0.06-0.34-0.15c0,0,0.01-0.01,0.01-0.02
				c-0.13,0.02-0.3-0.06-0.44-0.11c0.02-0.02,0.02-0.03,0.03-0.04c-0.04,0.03-0.13,0.05-0.17-0.16l0.11,0.05
				c-0.23-0.31-0.4-0.09-1.09-0.47c0.1-0.03,0.25,0.01,0.35,0.02c-0.06-0.03-0.21-0.11-0.27-0.08c0.03,0.01,0.07,0,0.09,0.03
				c-0.58-0.16-0.04,0.03-1.37-0.82c-0.03,0.13-0.12,0.13-0.2,0.05c0.03-0.02,0.14-0.04,0.06-0.12c0.05,0.08-0.09,0.04-0.09,0.08
				c-0.07-0.08-0.14-0.2-0.15-0.32c-0.01,0.06-0.08,0.05-0.15-0.01l0.08,0.11c-0.22-0.01-0.11-0.19-0.33-0.21l0.08-0.02
				c-0.11-0.02-0.36-0.17-0.5-0.1c-0.01-0.02-0.02-0.07,0.03-0.06c-0.22-0.13-0.58-0.11-0.73-0.25l0.03-0.03
				c-0.15-0.01-0.04-0.06-0.32-0.09l0.07-0.02c-0.21-0.14-0.39-0.05-0.6-0.11c0.04-0.1-0.13-0.21-0.22-0.26l0.01-0.01
				c-0.35-0.2-0.09,0.2-1.52-0.52c0,0.02-0.07,0.12-0.21,0.01c0.04,0.01-0.16-0.29-0.3-0.03c-0.06-0.16-0.39-0.09-1.43-0.54
				l0.06-0.04c-0.41,0.07-0.16,0.06-0.94-0.13c0.01-0.01,0.02-0.04,0.06-0.04c-0.1-0.03-0.36-0.16-0.36-0.01
				c0.02-0.09-0.21-0.06-0.33-0.05l0.03-0.06c-0.01,0.02-0.14,0.03-0.45,0.02l0.06-0.03c-0.17-0.05-0.14,0.15-0.38-0.1
				c0.05,0.01-0.19,0.07-0.12,0c-0.16-0.13-0.03,0.08-0.4-0.13c0.14,0.04,0.26,0.07,0.24,0.07c0.01-0.06-0.1-0.1,0.01-0.17
				c-0.07-0.05-0.24,0.12-0.73-0.12c-0.01,0.07-0.08,0.04-0.03,0.11c-0.02-0.01,0.03,0.01,0.08,0l-0.01,0.01
				c0.04,0.01,0.09,0.02,0.14,0.03c-0.04,0.06-0.09-0.11-0.27,0.08c-0.13-0.25-0.15-0.21-0.82-0.32c0.03-0.01,0.07,0,0.09-0.01
				c-0.11,0.01-0.3-0.06-0.34,0.02c-0.11-0.07,0.09-0.06-0.08-0.09l0.03-0.02c-0.3-0.23-0.61,0.08-0.93-0.21
				c0.02,0.02,0.03,0.03,0.03,0.04c-0.18-0.11-0.6-0.17-2.12-0.74l0.01,0.06c-0.54-0.32,0.07,0.12-0.84,0.08
				c0.06,0.06,0.04,0.04,0.35,0.07c-0.09,0.24,0.2-0.04,0.11,0.15c0.08-0.01,0.17,0.05,0.23,0.01c0.04,0.09,0.22,0.07,0.17,0.17
				c0.03-0.02,0.14-0.04,0.2-0.02c-0.15,0.09,0.22,0.09,0.14,0.21c0.12-0.08,0.31,0.06,0.47,0.04c-0.01,0.01-0.03,0.02-0.04,0.01
				c0.08,0.04,0.09,0.08,0.13,0.12c-0.09-0.04,0.49-0.2,0.17,0.08c0.02-0.08,0.2-0.02,0.18-0.09c0.06,0.08,0.06,0.05,0.07,0.14
				c-0.02-0.14,0.45,0.03,0.21,0.13c0.19-0.22,0.22,0.21,1.03-0.01c0.02,0.65,0,0,1,0.36l-0.07,0.08c0.27-0.05,0.97,0.29,2.59,0.57
				l-0.04,0.01c0.13,0.06,0.19-0.11,0.3-0.01l-0.03,0.01c0.25,0.15,0.18,0.07,1.91,0.59c0.07-0.13-0.17,0.11,0.5,0.13
				c-0.02,0.03-0.06,0.02-0.1,0.03c0.17-0.03,0,0.09,0.15,0.06c-0.01-0.02,0.02-0.04,0.03-0.05c0.05,0.07,0.2,0.11,0.32,0.13
				l-0.04,0.04c0.19,0.08,0.43,0.1,0.64,0.19c-0.1,0.03,0.08-0.22,0.04-0.03c0.05-0.04,0.12-0.14,0.02,0.03l0.11,0.01
				c0,0.03-0.03,0.03-0.05,0.06c0.5,0.17,0.01,0.07,1.43,0.37c-0.02,0.06,0.14,0.12,0.04,0.13c0.53,0.19,0.48,0,1.33,0.57l-0.01,0
				c0.6,0.24,0.04-0.07,1.47,0.78c0.03-0.05,0.02-0.09,0.09,0.04c0.29-0.25,0.21,0.39,1.62,0.78l-0.1,0.04
				c0.37,0.09,0.64,0.38,0.99,0.49c-0.03,0.01-0.04,0.03-0.07,0.01c0.03,0.09,0.24,0.15,0.26,0.1c0.12,0.1-0.08,0.02,0.91,0.51
				c-0.07,0.09,0.15,0.2,0.25,0.21c-0.04,0.01,0.04,0.07-0.01,0.09c0.08,0.05,0.17,0.08,0.19,0.02c0.19,0.26,0.13,0.17,1.09,0.66
				c-0.35,0.06-0.47-0.23,0.79,0.6c-0.01,0.01-0.03,0-0.06,0c0.13,0.03-0.02,0,0.31-0.04c-0.15,0.08,0.15,0.16-0.05,0.24
				c0.03,0.01,0.07,0.01,0.09-0.02c0.03,0.03,0.03,0.06-0.03,0.06c0.14,0.07,0.13-0.01,0.24,0.07c-0.02,0.02-0.04,0.01-0.07,0.03
				c0.09,0,0.17,0.01,0.26,0.09c-0.01,0.01-0.02,0.03-0.04,0.01c0.17,0.11,0.35,0.1,0.5,0.28c-0.12,0.05,0.04,0.07-0.01,0.16
				l0.11,0.05l-0.01-0.07c0.08,0.06,0.2,0.07,0.25,0.15l-0.09-0.02l0.17,0.11l-0.07-0.1c0.33,0.06,0.05-0.09,0.65,0.43
				c-0.1,0-0.01,0.2,0.15,0.14c-0.01,0-0.01,0.01-0.04,0c0.16,0.11,0.21,0.18,0.24,0.23l-0.05-0.03c0.2,0.3,0.03-0.14,1.28,0.96
				l-0.04,0.01C606.64,292.92,606.67,293.13,606.94,293.14z M587.09,282.63c0.01-0.01,0,0,0.02-0.03
				C587.15,282.61,587.2,282.65,587.09,282.63z M587.21,283.13c0.01,0,0.03,0,0.06,0.01C587.24,283.14,587.27,283.14,587.21,283.13z
				 M587.44,283.12c-0.02,0.01,0.04,0.04,0.07,0.07C587.53,283.18,587.35,283.19,587.44,283.12z"
                />
                <path
                  className="st10"
                  d="M652.7,233.41l-0.06-0.04c-0.18,0.29-0.26,0.46-0.28,0.87l-0.04-0.09c0.22,0.71-0.04,0.36,0.53,1.61
				c-0.02,0-0.05,0.01-0.09-0.04c0.09,0.17,0.38,0.59,0.44,0.44c-0.07,0.07,0.26,0.28,0.44,0.36l-0.07,0.04
				c0.03-0.01,0.22,0.1,0.66,0.31l-0.09,0c0.23,0.15,0.23-0.06,0.54,0.33c-0.08-0.03,0.27,0.03,0.17,0.07
				c0.22,0.22,0.03-0.08,0.58,0.35c-0.21-0.11-0.37-0.21-0.35-0.2c-0.03,0.06,0.13,0.16-0.04,0.16c0.15,0.05,0.01-0.05,0.6,0.2
				l-0.02,0c0.24,0.11,0.11,0.1,0.5,0.3c0.02-0.07,0.13,0,0.05-0.1c0.02,0.02-0.04-0.02-0.12-0.04l0.01,0
				c-0.06-0.03-0.13-0.06-0.21-0.1c0.66,0.13,0.34,0.33,1.64,0.73c-0.23-0.02-0.21-0.05,0.53,0.21l-0.05,0.01
				c0.48,0.34,0.93,0.09,1.48,0.44c-0.04-0.02-0.06-0.03-0.06-0.05c0.26,0.2,1.72,0.41,3.62,0.37l-0.05-0.04
				c0.94-0.07-0.17-0.02,1.06-0.62c-0.14,0.07-0.11-0.07-0.48,0.2c-0.02-0.22-0.26,0.21-0.26-0.04c-0.06,0.06-0.2-0.07-0.93,0.17
				c0.17-0.14-0.36,0-0.31-0.14c-0.13,0.11-0.46,0.04-0.69,0.08c0.01-0.01,0.04-0.02,0.06-0.02c-0.13-0.02-0.16-0.06-0.24-0.1
				c0.15,0.02-0.65,0.27-0.27-0.06c0,0.08-0.28,0.03-0.24,0.11c-0.11-0.08-0.1-0.05-0.15-0.14c0.07,0.15-0.67-0.03-0.35-0.13
				c-0.16,0.16-0.64-0.17-1.55-0.15c0.26-0.23,0.22-0.16-1.5-0.71l0.11-0.06c-0.73-0.25-1.48-0.55-3.58-2.02l0.05,0.02
				c-0.12-0.16-0.28-0.07-0.35-0.26c0.1-0.02-0.1-0.21-0.02-2.23l0.05,0.05c0.02-0.09,0.01-0.1,0.26-0.2
				c-0.09-0.08,0.1,0.05,0.68-0.29c0,0.03-0.07,0.06-0.11,0.09c0.2-0.13,0.06,0.07,0.24-0.05c-0.03-0.01,0-0.05,0.01-0.06
				c0.11,0.03,0.34-0.02,0.52-0.05l-0.03,0.06c0.31-0.03,0.66-0.08,1.02-0.05c0.49,0.17-0.06-0.03,4.5,1.7l-0.02-0.01
				c0.7,0.53,0.24,0.06,1.36,1.28c-0.01,0-0.01,0-0.02,0.01c0.43,0.35-0.03,0.02,0.59,0.65l-0.04-0.02c0.59,0.57,0.58,0.12,1.67,1.5
				c-0.02-0.03,0.01,0.01,0.32-0.4c0,0,0,0,0,0.01l0,0c0.1,0.12,0.02,0,0.37-0.19l0.02,0.08c0.04-0.06-0.01-0.08-0.04-0.11
				c0.04-0.05,0.12-0.03-0.07-0.13c0.25-0.05-0.13-0.11,0.07-0.13c-0.04-0.04-0.03-0.13-0.1-0.15c0.06-0.06-0.04-0.17,0.07-0.18
				c-0.03-0.01-0.1-0.08-0.11-0.12c0.14,0.07-0.02-0.17,0.13-0.17c-0.13-0.05-0.08-0.22-0.16-0.32c0.01,0.01,0.03,0.01,0.03,0.03
				c0-0.06,0.03-0.09,0.05-0.13c-0.1,0-0.13-0.09-0.2-0.14c0.08-0.09,0.12,0.03,0.21,0c-0.08,0.01-0.1-0.12-0.16-0.09
				c0.05-0.07,0.02-0.05,0.1-0.09c-0.12,0-0.03-0.05-0.11-0.09c0.06-0.03-0.01-0.19,0.15-0.1c-0.22-0.03,0.09-0.28-0.37-0.71
				c0,0.03,0.03,0.03,0.05,0.04l-0.03-0.04c0.16,0.2,0.12-0.21,0.09-0.03c0.06,0,0.09-0.02,0.11-0.04c0.07,0.16,0.28,0.6,0.49,1.3
				c-0.11-0.04-0.2-0.09,0.18,0.57l0-0.1c-0.04,0.02-0.16,0-0.08-0.19l0.04,0.09c0.11-0.14-0.14-0.36-0.19-0.55
				c0.08,0,0.16,0.02-0.03-0.48c0.03,0.02,0.06-0.05,0.07,0.04c-0.02-0.25-0.2-0.35-0.16-0.46l0.01,0.02
				c-0.02-0.48-0.2-0.26-0.5-1.14c0.05,0.04,0.03-0.05-0.06-0.51c0.01,0.28,0.11-0.45-0.01-1.28l0.02,0.03
				c0.03-0.11-0.15-0.12-0.09-0.22l0.01,0.02c0.12-0.08-0.03-0.65,0.05-1.91c0.03,0.01,0.04,0.04,0.06,0.07
				c-0.08-0.12,0.08-0.02,0.01-0.13c-0.02,0.01-0.05-0.01-0.06-0.01c0.05-0.05,0.05-0.17,0.03-0.26l0.05,0.02
				c0.02-0.16-0.01-0.33,0-0.51c0.06,0.06-0.24-0.02-0.04-0.03c-0.05-0.03-0.17-0.06,0.03-0.02l-0.02-0.08
				c0.02,0,0.04,0.02,0.07,0.02c0.01-0.38,0.05-0.02,0.01-1.08c0.06,0.01,0.09-0.11,0.12-0.03c0.03-0.14,0.04-0.18,0.06-0.36
				c-0.06,0.02-0.1-0.03,0.02-0.03c0-0.24,0.37-0.31,0.36-0.5l0,0.01c0.14-0.16,0.01-0.03,0.94-0.57c-0.32-0.23,0.73-0.12,1.62,0.59
				c-0.02,0-0.04,0.02-0.06,0c-0.01,0.07,0.11,0.15,0.16,0.12c0.05,0.1-0.06-0.04,0.38,0.61c-0.11,0.03-0.03,0.18,0.03,0.23
				c-0.04-0.01-0.02,0.06-0.07,0.05c0.02,0.06,0.05,0.12,0.11,0.1c-0.08,0.24-0.02,0.12,0.17,0.91c-0.26-0.16-0.08-0.42-0.05,0.74
				c-0.01,0-0.02-0.02-0.03-0.04c0.05,0.09-0.01-0.02,0.2,0.19c-0.14-0.07-0.05,0.16-0.23,0.06c0.01,0.02,0.03,0.05,0.06,0.05
				c-0.01,0.03-0.03,0.04-0.07,0c0.02,0.12,0.08,0.08,0.06,0.19c-0.02,0-0.03-0.03-0.06-0.04c0.04,0.06,0.09,0.12,0.05,0.2
				c-0.01-0.01-0.03,0-0.03-0.03c-0.01,0.15,0.1,0.28,0.03,0.44c-0.1-0.06-0.04,0.05-0.14,0.05l0.02,0.09l0.05-0.03
				c-0.01,0.08,0.05,0.16,0.01,0.22l-0.03-0.06l0,0.15l0.05-0.08c0.05,0.16,0.16,0-0.1,0.35c0.07,0.05-0.01,0.19,0.07,0.25
				c-0.02-0.02-0.06-0.03-0.08-0.02l0.06,0.04c-0.03,0.03-0.04,0.04-0.07,0.03c-0.03,0.11,0.12-0.02,0.05,0.11
				c0-0.01-0.01-0.01-0.02-0.03c-0.01,0.15-0.04,0.21-0.07,0.24l0-0.05l-0.05,0.04l0.04,0.02c-0.13,0.12,0.14,0.08-0.15,1.14
				l-0.03-0.03c0.02,0.01-0.15,0.14-0.02,0.3c-0.24,0.18,0.01,0.45-0.2,0.61c0.02-0.06-0.12,0.12,0.09,0.22
				c-0.02-0.08-0.09-0.02-0.13,0c0.02,0,0.03,0.01,0.05,0.02c-0.15,0.04,0.06,0.06-0.01,0.13c0-0.02,0.04,0.26-0.06,0.07
				c-0.01,0.07,0.09,0.17,0.01,0.21c-0.01-0.03,0-0.08-0.01-0.11c-0.09,0.05-0.08,0.06-0.1,0.11l0,0l-0.06,0.11
				c0.2,0.05,0.02,0,0.09,0.3c-0.36,0.09-0.14-0.04-0.37,1.05c-0.11-0.05-0.1-0.05-0.17,0.16c0.02-0.01-0.04,0.17,0.07,0.2
				c-0.13-0.16,0.14,0.13,0.08-0.32l0.09,0.07l-0.05-0.12c0.03-0.01,0.09-0.01,0.09,0.04c0.06-0.04,0.06,0,0.06-0.17
				c0.02,0.02,0.07,0.01,0.05,0.04c0.11,0.01-0.11-0.29,0.21-0.74c-0.05-0.04-0.06,0.06-0.08-0.03c0.14,0.08-0.03-0.22,0.17-0.11
				c-0.12-0.14,0.12,0.03,0.05-0.45c0.04,0.02-0.02-0.02,0.17-0.26c-0.08-0.04-0.04-0.13-0.05-0.21c0.05,0.02,0.07,0.03,0.09,0.08
				c-0.01-0.01,0.04-0.02-0.02-0.16l0.06,0.03c0.02-0.09-0.04-0.2,0.03-0.3c-0.06-0.02-0.19,0.05-0.21-0.08
				c0.03,0,0.09,0.01,0.1,0.05c0-0.01-0.02-0.04,0-0.06l0.05,0.06c-0.02-0.04,0.04-0.16,0.04-0.03c0.02-0.05,0.06-0.1,0.1-0.11
				c-0.11-0.02,0.14-0.12-0.01-0.42l0,0c-0.08-0.12,0.18-0.37,0.22-0.95c0.03,0.08,0.04-0.02,0.02,0.08
				c0.04-0.09,0.08-0.1,0.03-0.21c0.06,0.04,0.09-0.05,0.08-0.1l-0.06,0.06c0.11-0.25,0.09-0.02,0.18-0.57
				c-0.3,0.17,0.02-0.51,0.04-0.43l-0.03-0.02c0.04-0.04,0.05-0.16,0.09-0.12c-0.02-0.07-0.04-0.03-0.07,0.01
				c0.06-0.1,0.02-0.17,0.07-0.28c-0.03,0.05-0.2-0.37,0.08-0.24c-0.04-0.11,0.06-0.19,0.08-0.25l0.02,0.06
				c-0.01-0.03-0.01-0.06,0-0.09l-0.06,0.02c0.07-0.13,0.06-0.03-0.04-0.25c-0.08,0.16,0.3,0.1,0.04-0.16
				c0.04,0.02,0.09-0.02,0.08-0.05c0.01,0.04-0.1,0.02-0.12-0.17l-0.02,0.05c-0.04-0.02-0.03-0.06-0.04-0.12
				c0,0.01,0.17-0.22,0.2-0.47c-0.06,0.03-0.05,0.04-0.1-0.04l0.04,0.18l-0.08-0.12c0,0.05,0.03,0.12,0.02,0.15
				c-0.05-0.06-0.03-0.05-0.08-0.03c-0.01-0.31,0.3-0.07,0.11-0.64c-0.04,0.05-0.05,0.11-0.07-0.1c0.05,0,0.06-0.04,0.09-0.06
				c-0.03,0.01-0.01-0.13-0.11-0.13c0.06-0.16,0.11-0.09-0.12-0.35c0.06,0.03,0.08-0.04,0.14,0.04c-0.07-0.12,0.02,0.03-0.26-0.31
				c0.06-0.12,0.08-0.24-0.25-0.77c0.02,0,0.02-0.01,0.03,0c-0.21-0.2,0.03-0.04-0.23-0.4l0.02,0.01c-0.01-0.1-0.17-0.09-0.24-0.18
				c0,0,0.01-0.01,0.02-0.02c-0.12,0.01-0.25-0.07-0.36-0.11c0.01-0.02,0.02-0.02,0.03-0.03c-0.04,0.02-0.11,0.03-0.11-0.15
				c0.32-0.01,0.16,0-0.84-0.18c0.07-0.07,0.2-0.08,0.28-0.1c-0.06-0.02-0.2-0.04-0.24,0.01c0.02-0.01,0.06-0.02,0.08,0
				c-0.51,0.12-0.02-0.01-1.35,0.16c0.09,0.1,0.05,0.16-0.04,0.19c-0.01-0.04,0.02-0.13-0.07-0.11c0.08,0.01,0,0.09,0.03,0.12
				c-0.09,0.02-0.21,0.01-0.32-0.03c0.05,0.03,0.02,0.09-0.05,0.12l0.12-0.02c-0.07,0.18-0.2,0.01-0.27,0.2l0-0.07
				c-0.05,0.08-0.22,0.27-0.17,0.41c-0.02,0-0.07,0-0.06-0.04c-0.14,0.11-0.13,0.19-0.33,0.84l-0.03-0.06
				c0.03,0.58-0.31,0.36-0.3,1.94c0.13,0.19-0.02,0.11,0.07,1.17c-0.13,0.07-0.06-0.02-0.08,0.37l-0.05-0.03
				c0.17,0.27,0.11,0.08,0.12,0.72c-0.02,0-0.05-0.01-0.05-0.03c0,0.08-0.06,0.3,0.08,0.27c-0.09,0,0,0.17,0.04,0.26l-0.07-0.01
				c0.02,0,0.07,0.09,0.14,0.33l-0.05-0.04c0,0.11,0.11,0.09,0.05,0.22c0,0,0-0.01-0.01-0.01c0.04,0-0.14-0.39-0.2-0.59l0.02,0
				c-0.1,0.01-0.18-0.19-0.27-0.31c0.05-0.02,0.08-0.01,0.14,0.06c-0.02-0.01,0.03-0.04-0.2-0.25c0.28,0-0.09-0.06-0.44-0.59
				l0.1,0.06c-0.04-0.03-0.17-0.29-0.02-0.09c-0.19-0.42-0.08-0.18-0.6-0.94l0,0c-0.22-0.1-0.33-0.82-1.2-1.67
				c0.14,0.08-0.01-0.07,0.13,0.09c-0.12-0.16-0.13-0.22-0.34-0.31c0.1,0-0.07-0.16-0.16-0.2l0.09,0.12
				c-0.09-0.06-0.17-0.11-0.25-0.17l0.09-0.04c-0.34-0.16-1.07-0.58-1.92-0.04c0.27,0-0.55,0.21-0.35,0.25
				c-0.21,0.19-0.24,0.36-0.33,0.62c0.01,0,0.19,0.73-0.09,0.58c0.07,0.23,0.02,0.44,0.05,0.58l-0.05-0.12
				c0.03,0.07,0.04,0.13,0.06,0.19l0.04-0.08c0.01,0.07,0.02,0.13,0,0.14c0.13,0.11,0.19-0.06,0.22,0.35
				c-0.08-0.37-0.35-0.05,0.1,0.33c-0.05-0.02-0.06,0.09-0.03,0.13c-0.03-0.09,0.1-0.03,0.25,0.27l-0.03-0.1
				c0.2,0.12,0.11,0.47,0.4,1.19c0.02-0.09,0-0.09,0.13,0.03c-0.07-0.1-0.14-0.21-0.21-0.32l0.18,0.18c-0.05-0.1-0.14-0.2-0.16-0.26
				c0.09,0.08,0.07,0.08,0.1,0.01c0.24,0.5-0.15,0.37,0.52,1.21l-0.03-0.11c0.07,0.01,0.11,0.18,0.2,0.27
				c-0.05,0.02-0.02,0.09-0.02,0.14c0.02-0.01,0.06,0.06,0.11,0.12c-0.17-0.08-0.33-0.15-0.44-0.24l0.05-0.02
				c-0.22-0.06-0.08-0.09-0.51-0.2l0.1,0c-0.35-0.22-0.62-0.15-0.94-0.24c0.04-0.11-0.26-0.24-0.41-0.3l0.01-0.01
				C656.78,233.04,657.63,230.89,652.7,233.41z M656.01,237.95c-0.05,0.02-0.05,0.03-0.11-0.04
				C655.92,237.91,655.89,237.92,656.01,237.95z M655.89,237.37c-0.02-0.01-0.04-0.02-0.09-0.04
				C655.84,237.34,655.8,237.33,655.89,237.37z M655.54,237.25c0.03,0-0.05-0.06-0.09-0.11
				C655.41,237.15,655.68,237.22,655.54,237.25z M663.89,237.12c0,0-0.01-0.01-0.01-0.01c0.01,0.01,0.03-0.02,0.11,0.04
				C663.96,237.14,663.93,237.13,663.89,237.12z M663.37,237.25C663.31,237.29,663.32,237.26,663.37,237.25L663.37,237.25z
				 M663.66,233.16l0-0.02c0.01,0,0.01,0.01,0.02,0.01C663.67,233.16,663.67,233.16,663.66,233.16z M663.76,233.21
				c0,0.03,0,0.05-0.02,0.1c0-0.04,0-0.08,0-0.11C663.75,233.2,663.76,233.21,663.76,233.21z M664.14,233.18
				c-0.01-0.01,0.04,0.12-0.05,0.07C664.11,233.26,664.12,233.21,664.14,233.18z M659.6,232.32c0.08,0.05,0.14,0.09,0.17,0.12
				c-0.12-0.22-0.2-0.28-0.81-1.55c0.01,0,0.04,0.03,0.06,0.06c-0.12-0.15,0.02,0.02-0.34-0.26c0.18,0.06-0.09-0.33,0.15-0.23
				c-0.02-0.04-0.07-0.08-0.1-0.06c-0.01-0.07,0-0.09,0.06-0.04c-0.11-0.22-0.13-0.12-0.2-0.32c0.02-0.01,0.05,0.03,0.08,0.04
				c-0.08-0.09-0.16-0.18-0.17-0.36c0.02,0.01,0.03-0.02,0.04,0.04c-0.07-0.29-0.22-0.51-0.12-0.83c0.09,0.11,0.06-0.1,0.15-0.09
				c0-0.05,0.02-0.1,0.03-0.15l-0.07,0.04c0.05-0.13,0.07-0.28,0.18-0.35c-0.01,0.04-0.03,0.07-0.04,0.1
				c0.05-0.07,0.11-0.14,0.18-0.19c-0.05,0.02-0.09,0.04-0.14,0.07c0.08-0.13-0.1-0.08,0.09-0.19c0.29-0.05,0.09-0.04,0.96-0.17
				c-0.09,0.11,0.2,0.14,0.3,0.08c-0.02,0-0.01,0.01-0.06,0.01c0.2,0.1,0.3,0.16,0.37,0.2l-0.01-0.01c0.28,0.19-0.02-0.04,0.96,0.83
				l-0.03-0.06c0.32,0.26,0.01,0.28,0.74,1.02l-0.05-0.02c0.39,0.54,0.25,0.47,0.99,1.67c-0.08-0.08-0.12-0.13,0.13,0.25
				c0.04-0.05,0.01-0.06,0.01-0.15c0.05,0.02,0.12,0.1,0.16,0.18c-0.11-0.13-0.08,0.04-0.08,0.12c0.01-0.02,0.04-0.01,0.05-0.02
				c0,0.21,0.12-0.03,0.18,0.24c-0.05,0.01-0.12,0.02,0.25,0.53c-0.04-0.05-0.08-0.13-0.13-0.18c0.14,0.3-0.2,0.14,0.24,0.99
				c0,0.01,0-0.02-0.02-0.06l0.01,0c0-0.03-0.01-0.07-0.01-0.11c0.3,0.43,0.09,0.04,0.09,0.85c-0.02-0.02-0.02-0.05-0.04-0.06
				c0.04,0.08,0.03,0.23,0.13,0.24c-0.03,0.1-0.09-0.05-0.06,0.09l-0.03-0.02c-0.12,0.28,0.27,0.41,0.12,0.72
				c0.01-0.02,0.01-0.03,0.03-0.04c-0.05,0.28,0.14-0.01,0.1,1.21c0.07-0.02,0.38,1.05-0.11,0.57l0.01,0
				c-0.13-0.09-0.02-0.02-0.25-0.17c0.03,0.02,0.07,0.05,0.09,0.08c-0.74-0.6-0.03,0.02-1.73-1.83c0.03,0.22-0.11-0.09-0.18,0.05
				c-0.2-0.12-0.21-0.29-0.33-0.63c-0.01,0.06-0.11,0.01-0.21-0.09l0.11,0.18c-0.31-0.14-0.15-0.28-0.46-0.42
				C660.65,233.71,660.85,234.15,659.6,232.32z M663.71,233.62c-0.01,0,0,0-0.04-0.01C663.67,233.59,663.7,233.53,663.71,233.62z"
                />
                <path
                  className="st10"
                  d="M679.65,186.98l0-0.03c-0.01,0.23-0.06,0.23-0.06,0.4c0-0.01,0.01-0.02,0.02-0.03
				c0.01,0.04-0.02,0,0.03,0.15c-0.01-0.01-0.03-0.01-0.04-0.03c0,0.06,0.01,0.14,0.03,0.25c0.07-0.02,0.09,0.12,0.11-0.03
				c0,0.02-0.01-0.02-0.03-0.09l0.01,0.02c-0.01-0.02,0.04-0.01-0.02-0.07l0.01,0.04c0-0.02-0.01-0.03-0.01-0.04l0.01,0.01
				c-0.01-0.07-0.03-0.17-0.02-0.29c0.33,0.69-0.21,0.72,0.97,1.76c-0.23-0.11-0.21-0.12,0.49,0.38l-0.05,0
				c0.43,0.47,0.95,0.33,1.46,0.78c-0.04-0.03-0.06-0.05-0.06-0.06c0.24,0.27,1.74,0.67,3.77,0.92l-0.05-0.05
				c0.99,0.08-0.16-0.06,1.23-0.44c-0.26-0.08-0.83,0.25-0.82,0.03c-0.07,0.05-0.2-0.12-1.02,0.03c0.2-0.11-0.38-0.05-0.31-0.19
				c-0.15,0.1-0.5-0.02-0.75-0.02c0.02-0.01,0.05-0.02,0.07-0.01c-0.13-0.04-0.16-0.08-0.24-0.12c0.16,0.04-0.72,0.17-0.28-0.1
				c-0.01,0.08-0.3-0.01-0.27,0.07c-0.11-0.09-0.1-0.06-0.14-0.16c0.05,0.16-0.7-0.14-0.35-0.19c-0.2,0.13-0.62-0.26-1.59-0.45
				c0.07-0.53,0.1-0.07-1.27-1.16l0.12-0.01c-0.44-0.35-1.18-2.03,1.63-2.33l-0.06,0.02c0.21,0.04,0.27-0.11,0.47-0.02l-0.04,0.01
				c0.05,0.11,1.07,0.06,2.39,1.36l-0.09-0.05c0.22,0.37,0.49,0.46,0.73,0.71c-0.09,0.07,0.11,0.35,0.21,0.49
				c-0.23-0.01,0.47,0.28,1.22,1.2c0.05-0.08,0,0,0.09-0.08c-0.01,0.01-0.02,0.02-0.03,0.03c0.09-0.04,0.01-0.01,0.34-0.32
				c-0.83-1.3-0.05-0.28-2.46-2.83c0,0.03-0.01,0.03-0.01,0.04c-0.35-0.53-0.02-0.01-0.78-1.4l0.02,0.02
				c-0.51-0.97-0.16,0.08-1.1-2.57l-0.03,0.04c-0.31-0.71-0.21-0.13,1.37-0.55l-0.13,0.02c0.55,0.19,0.95,0.69,1.36,1.1
				c-0.18,0.16,0.04-0.39,1.31,2.29c-0.05-0.02,0.02,0.13-0.05,0.09c0.07,0.15,0.14,0.28,0.18,0.23c0.04,0.29-0.01,0.03,0.23,0.55
				c0.59-0.43,0.62-0.1-0.18-1.62c0.08,0.07,0.13,0.12,0.16,0.16c-0.08-0.25-0.18-0.32-0.58-1.77c0.01,0,0.03,0.04,0.05,0.07
				c-0.09-0.19,0.02,0.02-0.29-0.34c0.17,0.09-0.03-0.37,0.19-0.2c-0.02-0.04-0.05-0.1-0.08-0.08c0-0.07,0.02-0.1,0.06-0.03
				c-0.06-0.25-0.11-0.15-0.14-0.39c0.02,0,0.04,0.04,0.07,0.06c-0.06-0.11-0.13-0.22-0.12-0.42c0.02,0.01,0.03-0.01,0.04,0.04
				c-0.04-0.34-0.18-0.57-0.14-0.95c0.06,0.06,0.12,0.06,0.12-0.33l-0.05,0.07c0.02-0.16-0.02-0.32,0.05-0.45l0,0.13
				c0.02-0.1,0.05-0.2,0.08-0.29c-0.04,0.05-0.07,0.09-0.1,0.15c0.01-0.17-0.12-0.03-0.01-0.23c0.23-0.19,0.06-0.09,0.81-0.6
				c-0.07,0.08,0.18,0.13,0.3,0.03c-0.02,0-0.01,0.01-0.06,0.01c0.61,0.49,0.23-0.05,1.02,1.36l-0.02-0.07
				c0.24,0.36-0.05,0.29,0.41,1.28l-0.04-0.04c0.17,0.69,0.1,0.55,0.26,2.04c-0.04-0.12-0.04-0.18,0,0.3
				c0.06-0.03,0.04-0.05,0.08-0.13c0.03,0.05,0.06,0.16,0.06,0.25c-0.04-0.17-0.09,0-0.13,0.07c0.02-0.01,0.04,0.01,0.05,0.01
				c-0.11,0.2,0.12,0.04,0.03,0.31c-0.06-0.02-0.1-0.06-0.07,0.62c-0.01-0.07,0-0.16-0.01-0.23c-0.1,0.14-0.07,0.16-0.1,0.27l0-0.01
				l-0.07,0.25l0.03-0.06c0.14,0.22-0.03,0.39,0.03,0.67c-0.01-0.13-0.18-0.22-0.26,0.42c0-0.09-0.14,0.98-0.42,2.28
				c-0.06-0.1-0.16-0.18-0.09,0.64l0.04-0.1c-0.04,0-0.14-0.07,0.01-0.22v0.1c0.16-0.08,0.04-0.41,0.09-0.62
				c0.08,0.05,0.12,0.09,0.23-0.46c0.02,0.03,0.08-0.01,0.04,0.08c0.11-0.24,0.02-0.43,0.12-0.5l0,0.02
				c0.08-0.39,0.35-0.96,0.49-2.53c0.03,0.02,0.1-0.43,0.14-0.65l0.01,0.01c-0.09-0.05-0.06-0.27-0.08-0.44
				c0.05,0.01,0.08,0.04,0.1,0.13c-0.02-0.02,0.04-0.02-0.05-0.34c0.24,0.15-0.04-0.11-0.13-0.78l0.07,0.11
				c-0.03-0.05-0.04-0.36,0.02-0.1c-0.01-0.5,0.01-0.22-0.2-1.18l0,0c-0.16-0.18-0.08-0.94-0.66-2.09c0.11,0.13,0.01-0.07,0.1,0.14
				c-0.07-0.2-0.05-0.27-0.23-0.43c0.1,0.04-0.02-0.19-0.11-0.26c0.02,0.05,0.04,0.1,0.06,0.15c-0.08-0.09-0.14-0.17-0.21-0.25
				l0.1-0.02c-0.51-0.29-1.41-0.72-1.93,0.4c0.25-0.13-0.39,0.44-0.19,0.4c-0.11,0.26-0.08,0.41-0.12,0.68
				c0.03-0.03,0.26,0.73-0.04,0.57c0.07,0.23,0,0.44,0,0.58l-0.04-0.12c0.02,0.07,0.03,0.13,0.03,0.2l0.05-0.08
				c0,0.07,0.01,0.13-0.02,0.14c0.12,0.13,0.19-0.03,0.16,0.4c-0.02-0.29-0.07,0.02-0.12-0.19c0,0.3-0.07,0.17,0.15,0.55
				c-0.04-0.03-0.06,0.06-0.05,0.11c-0.04-0.06-0.1-0.14-0.17-0.24l0.04,0.03c-0.16-0.16,0.02-0.16-1.12-0.84
				c0.02-0.02,0.03-0.02,0.04-0.03c-0.06,0.01-0.17-0.02-0.3-0.27l0.19,0.1c-0.05-0.13-0.24-0.14-0.33-0.21l0.03-0.03
				c-0.31-0.12-0.34-0.06-0.67-0.16l0.03,0.04c-0.52,0-0.13-0.39-1.07,0c0.09-0.14,0.39-0.22,0.57-0.24
				c-0.13-0.03-0.49,0.03-0.54,0.12c0.05-0.03,0.12-0.07,0.18-0.07c-0.73,0.92-0.69,0.21-0.14,2.62c0.07-0.22,0.06,0.13,0.19,0.04
				c0.14,0.23,0.08,0.4,0.05,0.75c0.03-0.05,0.11,0.04,0.16,0.18l-0.03-0.21c0.23,0.28,0.02,0.34,0.26,0.61l-0.1-0.08
				c0,0.11,0.07,0.1,0.56,0.87c-0.15-0.07-0.33-0.16-1.07-0.41c0.01-0.01,0.03-0.04,0.09-0.03c-0.17-0.06-0.69-0.21-0.64-0.05
				c0.01-0.1-0.42-0.05-0.62,0.02l0.03-0.07c-0.01,0.03-0.27,0.07-0.76,0.28l0.07-0.07c-0.29,0.11-0.11,0.2-0.65,0.3
				c0.07-0.06-0.16,0.26-0.16,0.15c-0.33,0.19,0.06,0.02-0.51,0.63c0.11-0.27,0.28-0.44,0.26-0.42c-0.05-0.04-0.2,0.15-0.16-0.06
				C679.78,186.39,679.94,186.21,679.65,186.98z M686.53,183.54c-0.01-0.01-0.01-0.01-0.02-0.02
				C686.52,183.51,686.52,183.52,686.53,183.54z M686.37,183.3l-0.01-0.12c0.06,0.06,0.08,0.15,0.09,0.26
				C686.33,183.35,685.99,182.52,686.37,183.3z M680.25,186.66c0-0.04-0.06,0.22-0.1,0.08C680.15,186.77,680.21,186.7,680.25,186.66
				z M680.11,187c0,0.04,0,0-0.02,0.09C680.1,187.06,680.1,187.04,680.11,187z"
                />
                <path
                  className="st10"
                  d="M713.66,203.97c-0.02,0-0.05,0-0.05-0.02c0,0.05-0.06,0.19,0.08,0.17c-0.09,0.01-0.01,0.11,0.04,0.16
				c-0.17-0.02,0.03,0.01,0.05,0.62c0-0.07-0.01-0.13,0-0.12c-0.1-0.06-0.16,0.28,0.04,0.37c-0.01,0.01,0-0.01-0.02-0.03l0.01,0
				c0-0.02-0.01-0.04-0.01-0.07c0.04,0.02,0.07,0.04,0.03,0.07c0.05,0.01,0.09,0.02,0.12,0.03c0.04,0.07-0.14,0.37,0.05,1.04
				c0.01-0.01,0.01-0.02,0.03-0.03c0.01,0.14-0.12,0.17,0.16,0.73l-0.07,0.03c0.13-0.15-0.05,0.36,0.34,0.7c0,0,0,0-0.01,0
				c0.07-0.02,0.16-0.06,0.29-0.14l-0.08-0.04l0.05-0.03l0.02,0.05c0.04-0.04-0.01-0.05-0.04-0.06c0.03-0.01,0.05-0.03,0.05-0.04
				l-0.12-0.03c0.04-0.02,0.09,0.01,0.11-0.04c-0.06-0.02-0.09,0.05-0.14,0.01c0.04-0.04,0.09-0.04,0.04-0.07
				c0.03-0.01,0.05-0.01,0.06,0c-0.04-0.02-0.03-0.08-0.1-0.08c0.06-0.05-0.04-0.1,0.07-0.12c-0.03,0-0.1-0.04-0.11-0.06
				c0.14,0.02-0.02-0.11,0.13-0.12c-0.12-0.01-0.08-0.13-0.16-0.18c0.01,0,0.03,0.01,0.03,0.01c0-0.04,0.03-0.06,0.05-0.09
				c-0.1,0.01-0.13-0.04-0.2-0.06c0.08-0.07,0.12,0.01,0.2-0.03c-0.08,0.02-0.09-0.07-0.16-0.04c0.05-0.05,0.02-0.04,0.1-0.07
				c-0.12,0.02-0.03-0.03-0.11-0.05c0.15-0.11-0.05,0.01-0.08-0.45c-0.01-0.01-0.13,0.08-0.13-0.03c0,0.02,0.02,0.01,0.04,0.02
				l-0.03-0.02c0.14,0.1,0.13-0.13,0.09-0.03c0.35,0.11,0-0.41-0.11-0.9c0.07,0.35,0.15-0.05-0.06-1.78l0.09-0.03
				c-0.14-0.09,0.12,0.04-0.04-0.28c0.03,0,0.04,0.03,0.06,0.04c-0.12-0.06,0.11-0.04-0.05-0.09c0.05-0.03,0.05-0.1,0.03-0.16
				l0.05,0.01c0.02-0.1-0.01-0.21-0.01-0.32c-0.03,0.04-0.12,0.02-0.13-0.01l0.08-0.01c-0.05-0.02-0.17-0.04,0.03-0.01l-0.03-0.05
				c0.02,0,0.04,0.01,0.07,0.02c-0.05-0.03,0.09-0.08,0-0.11c0.04-0.07,0-0.38-0.05-0.56c0.24,0.17,0.12-0.8,0.8-0.85
				c0-0.01-0.04-0.11,0.02-0.01c-0.15-0.26,0.5,0.17,0.46,0.13c0.16,0.01,0.17,0.2,0.31,0.28c-0.02,0-0.04,0.01-0.05,0
				c-0.04,0.05,0.05,0.1,0.09,0.08c0-0.06,0.02,0.23,0.2,0.41c-0.11,0.03-0.06,0.12,0,0.15c-0.04,0-0.03,0.04-0.08,0.03
				c0.01,0.04,0.04,0.07,0.09,0.06c-0.12,0.1,0.13,0.14-0.03,0.22c0.09,0.2,0.12,0.07,0.19,0.82c-0.01,0-0.02-0.01-0.03-0.02
				c0.01,0.02-0.01,0.05,0.04,0.04l-0.02-0.02c0.07-0.04,0.16,0.07,0.2,0.07c-0.15-0.03-0.03,0.1-0.22,0.06
				c0.01,0.01,0.04,0.03,0.07,0.02c-0.08,0.16-0.01,0.04-0.05,0.51c0.01-0.03-0.02,0.1,0.07,0.03c0,0.05,0.06,0.09,0.03,0.14
				l-0.04-0.04l0.01,0.09l0.04-0.05c0.06,0.1,0.16-0.02-0.06,0.22c0.08,0.03,0.01,0.12,0.1,0.15c-0.02-0.01-0.07-0.02-0.08-0.01
				l0.06,0.02c-0.05,0.04,0.01,0.05-0.07,0.22l-0.01-0.03l-0.04,0.03l0.04,0.01c-0.1-0.02,0.1,0.21-0.01,0.47
				c-0.01-0.01-0.07-0.03-0.06-0.02c-0.03,0.07,0.01,0.15,0.04,0.27c0-0.07-0.17,0.1-0.02,0.17c-0.09,0.1-0.25,0.04-0.02,0.52
				c-0.03-0.06-0.09-0.02-0.13-0.01c0.02,0,0.04,0.01,0.05,0.01c-0.15,0,0.08,0.06,0,0.08c0.01,0.02,0.04,0.17-0.05,0.04
				c-0.01,0.13,0-0.01-0.13,0.18c0.2,0.05,0.02,0,0.11,0.2c-0.08-0.08-0.02-0.04-0.2-0.04c0.05,0.05-0.06,0.04,0.01,0.11
				c-0.02,0-0.02,0.01-0.04,0.01c0.03,0.02,0.03,0.13-0.05,0.55c-0.13-0.08,0.04,0.16-0.12,0c0.01,0.04,0.04,0.1,0.04,0.14
				c-0.04-0.01-0.05-0.05-0.07-0.07c0.02,0-0.03,0.11,0.08,0.14c-0.14-0.12,0.14,0.11,0.05-0.2l0.09,0.06
				c-0.12-0.11,0.02-0.05,0.08-0.15c0.02,0.01,0.07,0.02,0.05,0.04c0.07-0.08,0.12-0.16-0.08-0.27l0.19,0.01l-0.07-0.03
				c0.01-0.01,0.02-0.03,0.05-0.01c-0.04-0.05-0.03,0-0.07-0.04c0.08-0.03,0.01-0.14,0.13-0.12c-0.05-0.03-0.06,0.03-0.08-0.03
				c0.14,0.07-0.04-0.15,0.15-0.06c-0.08-0.14,0.15-0.1,0.01-0.29c0.04,0.02-0.02-0.02,0.14-0.15c-0.09-0.03-0.05-0.09-0.06-0.14
				c0.05,0.01,0.07,0.03,0.09,0.06c0-0.03,0.02-0.04-0.03-0.11l0.06,0.03c0.01-0.06-0.05-0.13,0.01-0.19
				c-0.06-0.02-0.19,0.02-0.21-0.06c0.03,0,0.09,0.01,0.1,0.04c-0.01,0,0.07-0.1,0.08-0.02c0.01-0.03,0.05-0.06,0.09-0.06
				c-0.03-0.01-0.14-0.07,0.03-0.07c-0.03-0.02,0.01-0.05-0.06-0.06c0-0.02,0.04-0.01,0.06-0.02c-0.03-0.03,0,0-0.08-0.12v0
				c-0.09-0.09,0.15-0.24,0.12-0.61c0.04,0.05,0.04-0.01,0.03,0.05c0.03-0.05,0.07-0.06,0.01-0.13c0.06,0.03,0.08-0.04,0.07-0.06
				l-0.05,0.04c0.15-0.15-0.12-0.52,0.1-0.64l-0.03-0.01c0.03-0.03,0.03-0.1,0.08-0.08c-0.03-0.04-0.05-0.02-0.06,0.01
				c0.05-0.07,0-0.11,0.03-0.18c-0.05,0.03-0.21-0.24,0.05-0.16c-0.06-0.07,0.04-0.12,0.05-0.16l0.03,0.03
				c-0.02-0.02-0.02-0.04-0.02-0.06l-0.06,0.02c0.01-0.02,0.01-0.04,0.03-0.04c-0.04-0.02-0.1,0.02-0.15-0.02
				c0.03-0.03-0.04-0.08,0.04-0.09c-0.02,0.11,0.3,0.01,0.02-0.1c0.04,0.01,0.06-0.01,0.07-0.03c-0.02,0.01-0.09,0-0.13-0.11
				c-0.03,0.03-0.02,0.06-0.07-0.04c0.03-0.01,0.03,0.03,0.04,0.02c-0.17-0.05,0.33-0.27,0.01-0.35l0.05,0.11l-0.09-0.06
				c0,0.03,0.05,0.07,0.04,0.09c-0.05-0.03-0.04-0.03-0.08-0.01c-0.07-0.24,0.27,0.01,0.07-0.4c-0.03,0.03-0.04,0.07-0.07-0.06
				c0.05,0,0.06-0.03,0.09-0.05c-0.04,0.01-0.01-0.08-0.11-0.07c0.06-0.09,0.12-0.07-0.09-0.21c0.06,0.02,0.08-0.03,0.13,0.02
				c-0.06-0.08,0.02,0.02-0.22-0.19c0.09-0.09,0.1-0.13-0.07-0.5c0.02,0,0.02-0.01,0.02,0c-0.19-0.08,0.16-0.17-0.21-0.42
				c0,0,0.01-0.01,0.02-0.01c-0.1,0-0.18-0.06-0.24-0.09c0.11-0.08,0.09-0.04,0-0.16c0.34-0.08-0.42,0-0.32-0.21
				c-0.05,0-0.16,0.04-0.23,0.04c0.05-0.06,0.14-0.08,0.21-0.1c-0.04-0.02-0.14-0.04-0.17,0.01c0.05,0.01,0.07,0.01-0.15,0.07
				c0.03-0.19-0.19-0.06-0.27-0.18c-0.14,0.09-0.31,0.06-0.46,0.17c0.07,0,0.03,0.24-0.04,0.09c0.01,0.03,0,0.06,0.01,0.08
				c-0.07,0.01-0.16,0-0.27-0.06c0.05,0.03,0.04,0.08-0.02,0.1l0.1,0c-0.02,0.14-0.17,0-0.18,0.14l-0.02-0.06
				c-0.02,0.07-0.13,0.19-0.05,0.3c-0.02,0-0.06-0.01-0.06-0.04c-0.1,0.05-0.07,0.16-0.17,0.55l-0.03-0.04
				c-0.08,0.54-0.03,0.16-0.21,1.23c-0.01-0.01,0.14-0.01,0.07,0.11l0-0.01c-0.1,0.02-0.08,0.08-0.08,0.13l0.13,0.01
				c-0.2,0.12,0.06,0.33-0.11,0.4c0.03,0.03,0.03,0.07,0.07,0.1c-0.09,0.03-0.08-0.02-0.08,0.23l-0.05-0.02
				C713.69,203.67,713.68,203.52,713.66,203.97z M713.71,205.09c-0.01-0.02,0.02-0.04,0.04,0
				C713.73,205.1,713.72,205.1,713.71,205.09z M714.19,204.94c0-0.01,0-0.01-0.01-0.03C714.19,204.92,714.18,204.91,714.19,204.94z
				 M714.17,204.8c-0.01,0,0.03,0.08-0.05,0.04C714.14,204.85,714.15,204.82,714.17,204.8z"
                />
                <path
                  className="st10"
                  d="M719.02,257.95c0.05,0.02,0.09,0.15-0.17,0.34l0.08-0.16c-0.22,0.08-0.35,0.62-0.55,0.92
				c-0.05-0.08-0.1-0.11-0.59,0.64c0.01-0.05-0.08-0.01,0.01-0.13c-0.3,0.33-0.34,0.64-0.51,0.73l0.02-0.03
				c-0.78,1.08-0.24,0.65-2.19,2.89c-0.07-0.08,0.11-0.05-0.46,0.63c-0.01-0.04-0.51,0.55-0.77,0.82l0-0.02
				c0.03,0.11-0.23,0.4-0.39,0.63c-0.04-0.04-0.02-0.1,0.06-0.23c-0.01,0.04-0.04-0.01-0.32,0.48c-0.01-0.26-0.13,0.06-0.79,1.08
				l0.08-0.18c-0.14,0.13-0.19,0.34-0.13,0.1c-0.61,0.63-0.26,0.27-1.31,1.55l0,0c-0.34,0.54-0.61,0.57-2.53,2.73
				c0.15-0.23-0.09,0.04,0.17-0.22c-0.25,0.24-0.34,0.27-0.54,0.59c0.04-0.13-0.24,0.13-0.34,0.26l0.2-0.14
				c-0.12,0.14-0.24,0.25-0.36,0.36l0-0.12c-0.55,0.46-1.07,1.14-2.35,2.22c0.37-0.03-0.79,0.36-0.55,0.39
				c-0.36,0.23-0.51,0.41-0.87,0.65c0.04,0.03-0.82,0.91-0.78,0.52c-0.28,0.28-0.61,0.41-0.8,0.54l0.15-0.14
				c-0.09,0.09-0.17,0.15-0.25,0.21l0.13-0.02c-0.1,0.06-0.19,0.13-0.2,0.11c-0.13,0.28,0.09,0.16-0.51,0.5
				c0.56-0.38,0.07-0.42-0.51,0.33c0.03-0.07-0.14,0-0.21,0.06c0.13-0.09,0.01,0.14-0.42,0.46l0.17-0.1
				c-0.18,0.29-0.73,0.45-1.92,1.18c0.13-0.02,0.14-0.04-0.07,0.17l0.54-0.42l-0.31,0.32c0.17-0.12,0.34-0.28,0.44-0.34
				c-0.14,0.16-0.13,0.13-0.03,0.12c-0.8,0.51-0.62,0.1-2.03,1.23l0.18-0.09c-0.43,0.41-0.52,0.19-2.19,1.53
				c0.1-0.12-0.11-0.03,0.15-0.24c-0.64,0.54,0.19-0.09-3.59,2.34c0.01-0.02-0.01-0.02,0.04-0.05c-0.9,0.61-0.03,0-1.43,0.79
				l0.07-0.05c-0.36,0.17-0.1,0.05-1.93,1.31c0-0.02,0-0.04-0.01-0.05c-0.03,0.08-0.16,0.21-0.59,0.27l0.3-0.18
				c-0.2,0.01-0.39,0.23-0.56,0.3l0-0.05c-0.46,0.27-0.41,0.37-0.92,0.64l0.07,0c-0.65,0.49-0.39-0.04-1.53,0.89
				c0.15-0.17,0.51-0.41,0.72-0.58c-0.18,0.1-0.6,0.35-0.67,0.44c0.07-0.05,0.15-0.12,0.23-0.15c-1.43,0.94-0.03,0.08-4.11,2.13
				c0.33-0.01-0.21,0.12-0.1,0.23c-0.48,0.24-0.11,0.12-1.16,0.32c0.08,0.02-0.1,0.14-0.32,0.23l0.34-0.1
				c-0.49,0.35-0.52,0.13-1.02,0.48l0.15-0.13c-0.53,0.25-0.5,0.31-3.2,1.79l0.03-0.06c-0.2,0.14-0.09,0.03-0.85,0.48l0.12-0.11
				c-0.67,0.29-0.94,0.6-1.47,0.9c-0.05-0.1-0.57,0.13-0.83,0.25l0-0.02c-1.23,0.72-3.45,1.81-8.03,4.25l-0.02-0.07
				c-0.46,0.22-0.7,0.33-1.25,0.63l0.09-0.1c-0.98,0.7-0.45,0.33-2.4,1.35c0-0.02,0-0.05,0.09-0.1c-0.26,0.13-1.04,0.46-0.88,0.54
				c-0.05-0.08-0.58,0.29-0.86,0.48l0.01-0.09c-0.02,0.04-0.35,0.26-1.07,0.7l0.11-0.1c-0.46,0.23-0.19,0.29-1.01,0.51
				c0.12-0.07-0.32,0.28-0.28,0.17c-0.53,0.17,0.06,0.06-1.11,0.54c0.38-0.19,0.69-0.35,0.66-0.33c-0.03-0.06-0.34,0.09-0.14-0.11
				c-0.24,0.15,0.03,0.04-0.96,0.61l0.03-0.02c-0.41,0.23-0.25,0.09-0.96,0.45c0.04,0.06-0.16,0.15,0.05,0.1
				c-0.05,0.02,0.07-0.04,0.19-0.12l-0.01,0.01c0.1-0.05,0.23-0.12,0.38-0.2c-0.97,0.69-0.82,0.29-2.89,1.52
				c0.07-0.05,0.16-0.1,0.2-0.13c-0.03-0.02-0.6,0.5-1.11,0.61l0.05-0.06c-0.98,0.34-1.37,0.97-2.46,1.32
				c0.08-0.03,0.11-0.04,0.13-0.03c-0.55,0.1-2.69,1.5-5.9,3l0.1,0.01c-0.22,0.1-0.67,0.38-0.66,0.31c-0.11,0.21-2.08,0.77-1.5,0.88
				c-0.3,0.1-0.2,0.47,0.71,0l-0.29,0.15c0.33-0.11,1.03-0.41,2.86-1.54c-0.23,0.27,0.61-0.3,0.59-0.14
				c0.16-0.22,0.76-0.45,1.12-0.71c-0.02,0.02-0.05,0.06-0.09,0.07c0.08,0.2,0.98-0.84,0.93-0.37c-0.05-0.06,0.43-0.31,0.3-0.32
				c0.23-0.05,0.19-0.06,0.33-0.05c-0.21-0.04,1.07-0.67,0.65-0.26c0.64-0.61,0.06,0.01,2.2-1.32c-0.1-0.12-0.12-0.09,0.23-0.26
				c0.4,0.22-0.07,0.27,2.78-1.32l-0.08,0.15c1.16-0.74,0.06-0.01,6.87-3.58l-0.08,0.07c0.38-0.16,0.35-0.35,0.72-0.46l-0.06,0.05
				c0.47-0.21-0.14,0.16,6.59-3.32c0,0.04-0.12,0.11-0.21,0.18c0.68-0.41,0.12-0.08,1.34-0.72l-0.03,0.08
				c0.55-0.27,1.14-0.61,1.75-0.93c-0.25,0.32,3.86-2.18,8.22-4.29l-0.03,0.02c1.69-0.95-0.02-0.02,4.36-2.25l-0.05-0.02
				c0.95-0.51,0.18-0.31,4.88-2.77l-0.12,0.17c0.89-0.62,1.91-1.09,2.77-1.7c-0.32,0.28,1.09-0.57,3.16-1.97
				c0.04,0.11,0.63-0.29,0.82-0.47c-0.05,0.07,0.2-0.09,0.15,0.01c0.24-0.15,0.45-0.31,0.38-0.33c0.87-0.42,0.67-0.32,3.09-2.11
				c-0.08,0.1-0.14,0.17-0.2,0.23c0.42-0.32,0.55-0.33,2.61-1.87c0,0.01-0.05,0.06-0.09,0.1c0.29-0.28-0.22,0.18,0.43-0.57
				c-0.09,0.24,0.54-0.34,0.37,0.01c0.06-0.06,0.13-0.14,0.1-0.15c0.11-0.06,0.15-0.07,0.07,0.03c0.35-0.29,0.19-0.23,0.52-0.48
				c0.01,0.02-0.05,0.08-0.06,0.13c0.14-0.16,0.28-0.33,0.57-0.52c-0.01,0.02,0.04,0.02-0.05,0.08c0.47-0.36,0.74-0.71,1.29-1.07
				c-0.05,0.1-0.03,0.2,0.54-0.29l-0.14,0.05c0.24-0.19,0.41-0.41,0.64-0.55l-0.16,0.17l0.44-0.37l-0.28,0.17
				c0.21-0.23-0.1-0.07,0.24-0.31c-0.06,0.06,0.24-0.19,0.4-0.25l0,0c0.45-0.46,0.28,0.02,1.09-0.95c-0.02,0.21,0.4-0.24,0.44-0.4
				c-0.03,0.03,0,0.03-0.06,0.09c0.37-0.39,0.55-0.53,0.69-0.64l-0.08,0.1c0.51-0.47-0.02,0.05,1.66-1.81l-0.09,0.08
				c0.09-0.42,0.28,0.09,1.55-1.74l-0.03,0.09c0.8-0.98,0.7-0.69,2.36-2.72c-0.1,0.18-0.17,0.26,0.33-0.4
				c-0.08,0-0.08,0.04-0.21,0.12c0.03-0.09,0.13-0.25,0.23-0.38c-0.16,0.26,0.07,0.06,0.18-0.02c-0.03,0.01-0.01-0.04-0.04-0.04
				c0.3-0.2-0.05-0.12,0.3-0.44c0.02,0.07,0.01,0.17,0.69-0.83c-0.06,0.1-0.16,0.23-0.22,0.33c0.23-0.16,0.23-0.19,0.36-0.34
				l-0.01,0.01l0.31-0.34l-0.09,0.08c0.07-0.37,0.4-0.56,0.59-0.99c-0.19,0.41,0.01,0.1,0.04,0.17c0.09-0.08,0.21-0.21,0.2-0.15
				c0.09-0.13,0.01-0.19,0.53-0.66c-0.7,0.76,1.15-1.71,2.06-3.19c-0.02,0.18,0.03,0.33,0.55-0.95L719.02,257.95z M655.52,302.14
				c0.06-0.09,0.03-0.07,0.18-0.11C655.66,302.06,655.69,302.01,655.52,302.14z M656.33,302.24c0.03-0.02,0.08-0.04,0.16-0.09
				C656.42,302.19,656.48,302.15,656.33,302.24z M656.89,301.88c-0.03,0.03,0.13-0.03,0.24-0.06
				C657.18,301.77,656.8,302.05,656.89,301.88z"
                />
                <path
                  className="st10"
                  d="M720.8,246.72c0.23,0.16-0.04-0.11-0.08-0.79l0.06,0.11c-0.02-0.05-0.01-0.37,0.03-0.1
				c0.03-0.5,0.02-0.22-0.09-1.2l0,0c-0.12-0.21,0.03-0.93-0.32-2.18c0.08,0.14,0.03-0.06,0.07,0.15c-0.03-0.21,0-0.26-0.12-0.46
				c0.08,0.06,0.04-0.17-0.01-0.26l-0.01,0.15c-0.04-0.1-0.06-0.2-0.08-0.3l0.09,0.03c-0.09-0.43-0.26-0.93-0.72-1.81
				c-0.1,0.26-0.03-0.54-0.14-0.38c-0.07-0.25-0.16-0.36-0.26-0.6c-0.02,0.04-0.58-0.52-0.25-0.52c-0.18-0.17-0.22-0.39-0.3-0.51
				l0.09,0.09c-0.06-0.05-0.09-0.11-0.13-0.16l0,0.09c-0.04-0.07-0.08-0.12-0.06-0.13c-0.18-0.05-0.14,0.12-0.36-0.26
				c0.24,0.33,0.35-0.08-0.24-0.29c0.06,0,0.01-0.11-0.04-0.14c0.07,0.07-0.09,0.07-0.37-0.15l0.08,0.09
				c-0.26,0-0.35-0.45-1.17-0.81c0.06,0.08,0.07,0.07-0.12,0.07c0.14,0.02,0.28,0.06,0.42,0.11l-0.29-0.02
				c0.12,0.04,0.27,0.06,0.33,0.1c-0.14-0.01-0.12-0.01-0.08,0.05c-0.59-0.21-0.21-0.49-1.43-0.34l0.13,0.01
				c-0.67,0.18-0.33-0.11-1.47,0.74c0.03-0.1-0.1-0.03,0.04-0.2c-0.46,0.73-0.04,0-0.81,2.18c-0.08-0.08-0.15-0.14-0.26-0.33l0,0.05
				c-0.41-0.32,0.13-0.39-0.85-0.67c0.15-0.04,0.43,0.07,0.59,0.17c-0.1-0.1-0.4-0.28-0.5-0.24c0.06,0.01,0.14,0.01,0.18,0.06
				c-1.31,0.05-0.54-0.34-2.34,1.42c0.18-0.17-0.03,0.17,0.14,0.15c0.01,0.36-0.09,0.38-0.21,0.79c0.05-0.05,0.09,0.08,0.12,0.24
				c0-0.08,0-0.16,0.01-0.24c0.18,0.34-0.03,0.37,0.19,0.7l-0.09-0.09c0.08,0.14,0.28,0.54,0.4,0.6c-0.01,0-0.02,0-0.03,0
				c0.03,0.05,0.03,0.02,0.28,0.29c-0.42-0.21-0.13-0.19-1.11-0.17c0.01-0.11-0.35-0.17-0.54-0.17l0.01-0.02
				c-0.29,0.14-1.6,0.16-2.33,1.81c0.17-0.01-0.31,1.91,1.14,3.43l-0.09-0.02c0.64,0.35,0.27,0.18,1.37,0.98
				c-0.01,0.01-0.04,0.03-0.09,0c0.14,0.1,0.53,0.43,0.55,0.28c-0.05,0.08,0.32,0.21,0.51,0.28l-0.06,0.04
				c0.02-0.02,0.24,0.07,0.7,0.29l-0.09-0.01c0.25,0.16,0.24-0.06,0.56,0.36c-0.08-0.04,0.29,0.05,0.18,0.08
				c0.23,0.25,0.03-0.08,0.6,0.41c-0.21-0.14-0.38-0.25-0.36-0.24c-0.03,0.05,0.13,0.17-0.05,0.16c0.15,0.07,0.02-0.05,0.62,0.27
				l-0.02,0c0.24,0.14,0.11,0.11,0.51,0.37c0.03-0.06,0.14,0.02,0.06-0.1c0.02,0.02-0.04-0.02-0.12-0.05l0.01,0
				c-0.06-0.03-0.13-0.08-0.22-0.13c0.67,0.22,0.35,0.36,1.64,1c-0.24-0.07-0.21-0.09,0.51,0.32l-0.05,0
				c1.74,1.25,0.64,0.14,4.13,3.26l0.03-0.06c0.31-0.15-0.39,1.97,0.56,1.04c-0.01,0.07-0.01,0.15-0.03,0.22
				c0.2-0.32-0.2-0.95-0.01-0.97c-0.11-0.03,0.1-0.35-0.55-1.01c0.24,0.09-0.26-0.34-0.12-0.39c-0.19-0.04-0.43-0.36-0.69-0.47
				c0.02,0,0.05,0,0.07,0.02c-0.11-0.1-0.12-0.16-0.19-0.23c0.14,0.09-0.76-0.1-0.25-0.21c-0.04,0.07-0.3-0.11-0.29-0.02
				c-0.08-0.12-0.08-0.09-0.1-0.19c0.02,0.16-0.68-0.31-0.32-0.27c-0.57-0.07,0.02-0.03-1.36-0.66c-0.04,0.1-0.03,0.17-0.22,0.01
				c0.07-0.48,0.14-0.09-1.47-1.05l0.12-0.04c-0.85-0.33-1.38-0.96-3.87-2.39l0.06,0.01c-0.19-0.16-0.31-0.02-0.46-0.2l0.04,0.01
				c-0.06-0.22-0.88-0.23-2.06-2.01l0.07-0.04c-0.03,0.01-0.11-0.08-0.16-0.35c-0.08,0.09,0.05-0.12-0.2-0.78
				c0.03,0.01,0.05,0.08,0.08,0.13c-0.1-0.22,0.07-0.05-0.01-0.25c-0.02,0.03-0.05-0.01-0.06-0.02c0.04-0.11,0.05-0.34,0.07-0.52
				l0.05,0.04c0.07-0.3,0.17-0.62,0.4-0.87c0.41-0.33-0.07-0.03,3.96-0.02c-0.14-0.09-0.26-0.14-0.26-0.17
				c0.09,0.02,0.34,0.17,0.42,0.23l-0.02,0c0.04,0.01,0.08,0.02,0.12,0.03c-0.12-0.08-0.56-0.96-1.17-1.81
				c0.42,0.5-1.14-3.55,2.02-1.44c-0.97-0.78,1.66,1.97,0.91,0.22c0.39-0.01-0.39,0.36-0.17-1.87c0.09,0.05,0.23-0.27,0.28-0.4
				c0,0.05,0.1-0.07,0.1,0c0.09-0.11,0.17-0.22,0.11-0.24c0.67-0.27,0.37-0.47,1.79-0.63c-0.06,0.05-0.13,0.07-0.19,0.08
				c0.25,0.12,0.43-0.16,1.59,0.64c-0.01,0.01-0.04-0.01-0.08-0.02c0.19,0.08-0.03-0.01,0.43,0.09c-0.19,0.02,0.23,0.28-0.04,0.28
				c0.04,0.03,0.1,0.05,0.12,0.02c0.04,0.06,0.04,0.09-0.04,0.06c0.19,0.17,0.17,0.07,0.32,0.25c-0.02,0.01-0.06-0.02-0.09-0.02
				c0.11,0.06,0.23,0.12,0.32,0.3c-0.02,0-0.02,0.03-0.06-0.02c0.21,0.27,0.44,0.41,0.58,0.76c-0.1-0.06-0.1,0.1,0.07,0.36
				l0.01-0.09c0.07,0.16,0.2,0.28,0.24,0.43l-0.09-0.1l0.16,0.3l-0.05-0.19c0.12,0.14,0.1-0.07,0.18,0.16
				c-0.03-0.04,0.08,0.16,0.06,0.27l0,0c0.23,0.36-0.13,0.13,0.32,0.78c-0.14-0.05-0.03,0.28,0.09,0.33
				c-0.01-0.02-0.02-0.01-0.04-0.05c0.09,0.29,0.12,0.42,0.13,0.52l-0.02-0.06c0.07,0.39-0.01-0.03,0.37,1.38l0-0.07
				c0.18,0.41-0.11,0.28,0.22,1.34l-0.04-0.05c0.09,0.72,0.02,0.57,0.07,2.09c-0.03-0.12-0.04-0.18-0.01,0.3
				c0.06-0.03,0.04-0.05,0.08-0.13c0.03,0.05,0.06,0.16,0.05,0.26c-0.03-0.18-0.09,0-0.13,0.07c0.02-0.01,0.04,0.02,0.05,0.01
				c-0.12,0.2,0.11,0.04,0.02,0.31c-0.05-0.02-0.11-0.05-0.07,0.63c-0.01-0.07,0-0.16-0.01-0.23c-0.09,0.15-0.08,0.16-0.1,0.28
				l0-0.01l-0.07,0.26l0.03-0.06c0.14,0.22-0.02,0.4,0.04,0.68c0.01-0.16-0.03,0.06-0.3,0.51c0.14-0.32-0.16,1.15-0.53,2.15
				c-0.04-0.05-0.04-0.35-0.08,0.07c-0.01-0.01-0.02-0.01-0.06,0.01c0-0.01,0.03-0.03,0.04-0.04c-0.04,0,0-0.01-0.12-0.02l0,0
				c-0.12,0.1,0.03-0.17-0.13-0.45c0.04,0.03,0.05-0.13,0.01-0.02c0.02-0.29-0.33-0.21-0.1-0.49c-0.04-0.01-0.05,0.01-0.05,0.03
				c0.02-0.06-0.03-0.07-0.02-0.12c-0.06,0.04-0.24-0.07,0-0.12c-0.07-0.01,0-0.09,0-0.12l0.03,0.01c-0.02-0.01-0.02-0.02-0.03-0.03
				l-0.05,0.04c0-0.02,0-0.03,0.02-0.04c-0.04,0-0.08,0.05-0.14,0.05c0.02-0.03-0.05-0.03,0.02-0.07c0,0.06,0.07-0.04,0.1,0
				c0-0.09,0.07-0.06-0.11-0.07c0.04-0.01,0.07-0.05,0.06-0.05c0.01,0.02-0.06,0.04-0.1,0.04c0.02-0.05-0.04-0.02-0.04-0.04
				c-0.02,0.03-0.01,0.04-0.07,0c0.03-0.02,0.03,0.01,0.04,0c-0.08,0.01,0.05-0.09-0.02-0.1c0.02-0.03,0.07-0.04,0.09-0.06
				c-0.09,0.01,0.07-0.12-0.13-0.06l0.07,0.05c-0.14,0-0.12,0.09-0.14-0.22c-0.02,0.03-0.03,0.06-0.07-0.01
				c0.04-0.02,0.05-0.04,0.08-0.06c-0.03,0.02-0.02-0.05-0.11-0.01c0.19-0.1-0.26-0.08,0.01-0.14c-0.06-0.03,0.02,0-0.22-0.05
				c0.11-0.12-0.08-0.13,0.01-0.23c-0.2-0.15-0.1,0.01-0.35-0.42c0.06-0.02,0.04,0,0.09-0.04c-0.26,0.11-0.09-0.09-0.13-0.27
				c-0.03-0.01-0.12-0.02-0.16-0.05c0.07-0.02,0.13,0.01,0.18,0.01c-0.17-0.1-0.05,0.07-0.25-0.56c-0.03,0.05-0.08,0.03-0.11,0.06
				c0-0.04,0.05-0.12,0.16-0.21c-0.04,0.03-0.08,0.03-0.07-0.01c0.01,0.07-0.12-0.06-0.01-0.07c-0.04-0.01-0.06-0.11-0.19-0.07
				c0.3-0.07-0.21-0.15-0.61-1.01l0,0.01c0.07-0.07,0.03-0.09,0.02-0.12l-0.11,0.07c0.11-0.18-0.18-0.14-0.06-0.28
				c-0.04,0-0.05-0.02-0.09-0.02c0.04-0.05,0.1-0.03-0.02-0.17c0.2-0.08-0.09-0.15-0.28-0.27c0.08-0.05-0.03-0.06-0.09-0.07
				c0.08-0.06,0.11,0-0.14-0.19c-0.04,0.02-0.08-0.04-0.1-0.14c0.03,0.04,0.05,0.07,0.04,0.07c0.06,0.01,0.03-0.3-0.15-0.19
				c0-0.01,0,0,0.03,0.01l-0.01,0l0.03,0.03c-0.04,0.01-0.07,0.01-0.05-0.03c-0.04,0.02-0.09,0.04-0.11,0.05
				c-0.01-0.06,0.13-0.2-0.18-0.31c0.13-0.25,0.09-0.06-0.16-0.29c0,0.01-0.01,0.02-0.02,0.03c-0.03-0.06,0.02-0.22-0.32-0.38
				l0.05-0.05c-0.05,0.19-0.07-0.26-0.44-0.29c0.01,0,0.01,0,0.01,0c-0.05,0.05-0.12,0.12-0.22,0.23l0.08-0.01l-0.04,0.05
				l-0.02-0.03c-0.03,0.05,0.02,0.03,0.05,0.02c-0.02,0.02-0.03,0.04-0.04,0.05l0.11-0.04c-0.03,0.03-0.08,0.04-0.08,0.08
				c0.05-0.02,0.07-0.08,0.11-0.07c-0.03,0.05-0.07,0.07-0.02,0.06c-0.02,0.02-0.04,0.03-0.06,0.03c0.04-0.01,0.05,0.03,0.1,0
				c-0.04,0.06,0.06,0.05-0.03,0.11c0.02-0.01,0.09-0.02,0.11-0.01c-0.13,0.05,0.04,0.06-0.08,0.14c0.11-0.05,0.1,0.04,0.18,0.04
				c-0.01,0-0.03,0.01-0.03,0c0.01,0.03-0.01,0.05-0.02,0.08c0.08-0.06,0.12-0.04,0.18-0.06c-0.06,0.08-0.1,0.06-0.17,0.12
				c0.06-0.05,0.1,0,0.14-0.05c-0.03,0.06-0.01,0.03-0.07,0.09c0.1-0.07,0.03,0.01,0.1-0.02c-0.03,0.07-0.09,0.08-0.03,0.09
				c-0.16,0.05,0.12,0.15,0.35,0.1c-0.01-0.01-0.03,0-0.04,0.01l0.03,0c-0.1,0.08-0.11-0.02-0.07,0.12l0-0.05
				c-0.09,0.17,0.29,0.15,0.81,1.25c0.06-0.03,0.2,0.07,0.14,0.24l0.09-0.02l-0.07,0.07c0.13-0.02-0.11,0.06,0.14,0.13
				c-0.03,0.02-0.04,0.01-0.07,0.01c0.12-0.03-0.07,0.09,0.08,0.02c-0.03,0.04,0,0.09,0.04,0.11c-0.06,0.06-0.07,0.01,0.09,0.19
				c0.01-0.04,0.09-0.08,0.11-0.07l-0.06,0.05c0.05-0.02,0.16-0.08-0.02,0.02l0.04,0.01c-0.02,0.01-0.03,0.02-0.06,0.03
				c0.05-0.02-0.04,0.09,0.05,0.06c-0.31-0.1,0.6,0.58,0.7,1.2c0.01-0.01,0.09-0.07,0.02,0.02c0.07-0.19,0.32,0.57,0.27,0.47
				c0.14,0.05,0.11,0.21,0.24,0.27c-0.02,0-0.04,0.01-0.05,0c-0.04,0.05,0.04,0.08,0.08,0.06c-0.03-0.08,0.03,0.21,0.2,0.27
				c-0.1,0.05-0.05,0.11,0.01,0.11c-0.03,0.01-0.02,0.04-0.07,0.05c0.02,0.03,0.05,0.04,0.1,0.01c-0.1,0.11,0.15,0.05,0.01,0.16
				c0.1,0.05,0.17,0.11,0.23,0.17c-0.09,0.08-0.07,0.02,0.11,0.29c-0.01,0-0.02,0-0.03,0c0.01,0.01,0,0.04,0.04,0.01l-0.02-0.01
				c0.06-0.05,0.16-0.02,0.2-0.03c-0.14,0.04,0,0.08-0.19,0.13c0.01,0,0.04,0,0.07-0.01c-0.03,0.12-0.01,0.02,0.1,0.34
				c0-0.02,0.01,0.07,0.08-0.01c-0.04,0.01,0.13,0.07,0.03,0.06l0.04,0.05c0.03-0.04,0.02-0.05,0.12-0.05
				c-0.04,0.06,0.01,0.07-0.08,0.16c0.08-0.02,0.05,0.06,0.14,0.05c-0.02,0-0.07,0.02-0.07,0.03l0.06-0.01
				c-0.08,0.05-0.01,0.07-0.01,0.05c-0.05,0.01,0.16,0.17-0.03,0.16c0.05,0.06,0.17,0.09,0.17,0.17c0.13-0.09,0.01-0.01,0.04,0.07
				c-0.01,0-0.07,0.02-0.06,0.02c0,0.05,0.07,0.08,0.14,0.13c-0.02-0.02-0.08,0.15,0.05,0.1c-0.15,0.18,0.02-0.01,0.05,0.3
				c0.01-0.03-0.01-0.03,0.08-0.02c-0.01,0.01,0.01,0.02-0.02,0.06l0.01-0.01c0,0.04,0,0.08,0.01,0.1l0.06-0.09c0,0,0,0,0,0
				c0.02,0,0.03,0,0,0.02c0.02,0.01,0.1,0.06-0.02,0.05c0.06,0.08,0-0.02-0.02,0.16c0.2-0.08,0.01-0.01,0.2,0.04
				c-0.11,0.01-0.02-0.02-0.18,0.09c0.07,0-0.03,0.05,0.07,0.05c-0.02,0.01,0.01,0.02-0.05,0.04c-0.02-0.22,0.31,0.23,0.19,0.34
				c0.03,0.02,0.08,0.02,0.11,0.04c-0.04,0.02-0.07,0.01-0.1,0.01c0.01-0.01,0.04,0.05,0.13,0.02c-0.03-0.03-0.07-0.12,0.05-0.14
				c-0.01,0.02-0.08-0.09,0.04-0.12c0-0.09-0.01-0.13-0.2-0.08l0.16-0.1l-0.07,0.02c0-0.01,0-0.02,0.03-0.03
				c-0.06-0.01-0.03,0.02-0.08,0.02c0.05-0.06-0.06-0.07,0.05-0.13c-0.06,0.01-0.03,0.05-0.08,0.03c0.15-0.04-0.1-0.06,0.1-0.11
				c-0.14-0.03,0.07-0.12-0.13-0.15c0.04,0-0.02-0.02,0.05-0.15c-0.09,0.03-0.09-0.02-0.12-0.04c0.05-0.02,0.07-0.02,0.1-0.02
				c-0.02-0.02-0.01-0.03-0.08-0.04l0.07-0.02c-0.08-0.17-0.03,0,0.06-0.51c0.06,0.06,0.12,0.12,0.28-0.43
				c0.01,0.03,0.08,0,0.03,0.08c0.14-0.23,0.06-0.43,0.17-0.5l0,0.02c0.1-0.4,0.37-0.96,0.5-2.56c0.04,0.02,0.09-0.44,0.14-0.65
				l0.01,0.01c-0.09-0.05-0.04-0.27-0.06-0.44c0.05,0.01,0.08,0.04,0.1,0.13C720.83,247.04,720.89,247.04,720.8,246.72z
				 M712.69,249.99c-0.06,0.01-0.06,0.02-0.12-0.05C712.6,249.94,712.57,249.95,712.69,249.99z M712.6,249.39
				c-0.02-0.01-0.05-0.03-0.09-0.05C712.54,249.35,712.51,249.33,712.6,249.39z M712.24,249.23c0.03,0-0.05-0.07-0.09-0.12
				C712.1,249.1,712.38,249.2,712.24,249.23z M713.5,239.83C713.55,239.82,713.53,239.83,713.5,239.83L713.5,239.83z M713.18,239.37
				c0.01,0.01,0.01,0.02,0.01,0.03C713.19,239.39,713.19,239.39,713.18,239.37z M715.74,245.73c-0.01-0.02-0.03-0.04,0.03-0.05
				C715.75,245.69,715.75,245.71,715.74,245.73z M716,245.33c0.01-0.01,0.02-0.01,0.03-0.02C716.05,245.31,716.05,245.34,716,245.33
				z M719.5,251.69c0,0.01,0,0.03-0.01,0.04C719.47,251.73,719.46,251.72,719.5,251.69z M719.48,251.78
				c-0.01,0.03-0.02,0.04-0.03,0.02C719.46,251.8,719.47,251.79,719.48,251.78z M719.33,251.88c0.02-0.01,0.04-0.01,0.06-0.02
				C719.37,251.88,719.39,251.87,719.33,251.88z M719.38,252.07L719.38,252.07C719.38,252.07,719.38,252.08,719.38,252.07z
				 M719.37,252.05c0-0.01-0.01-0.01-0.01-0.02c0.03,0,0.05,0,0.08,0C719.41,252.02,719.39,252.04,719.37,252.05z"
                />
                <path
                  className="st10"
                  d="M706.64,250.87c-0.01,0.03-0.12,0.04-0.36-0.03c0.04,0.15-0.09-0.18-0.77-0.01
				c0.01-0.03,0.08-0.04,0.14-0.06c-0.23,0.08-0.04-0.08-0.25-0.01c0.03,0.02-0.01,0.05-0.02,0.06c-0.1-0.05-0.34-0.04-0.51,0
				l0.03-0.06c-0.32,0.01-0.67,0.1-1.02,0.19c0.1,0.06-0.1,0.06-2.68,2.03c0.08-0.07,0.11,0.01-0.03,0.07
				c0.14,0.16-0.46,0.61,0.43,1.45l-0.02-0.01c0.95,0.33,0.02,0.35,2.04,0.62c-0.09,0.1-0.18,0.2-0.25,0.31
				c0.01-0.04-0.01-0.05,0.03-0.1c-0.44,0.65-0.09,0.07-0.85,1.87c-0.12-0.04-0.13,0.39-0.04,0.52c-0.04-0.04-0.02,0.13-0.07,0.08
				c0.03,0.17,0.09,0.31,0.14,0.26c0.23,0.69,0.11,0.72,1.38,1.43c-0.04,0-0.07,0-0.1,0c0.54,0.21,0.4,0.32,1.57,0.37
				c0,0.01-0.04,0.02-0.07,0.03c0.18-0.05-0.02,0.02,0.35-0.22c-0.1,0.15,0.32,0.02,0.16,0.21c0.04-0.01,0.09-0.04,0.08-0.08
				c0.06,0,0.09,0.02,0.03,0.07c0.23-0.06,0.14-0.1,0.35-0.13c0,0.02-0.04,0.04-0.05,0.07c0.1-0.07,0.19-0.14,0.37-0.15
				c-0.01,0.02,0.02,0.03-0.03,0.04c0.3-0.08,0.46-0.26,0.78-0.33c-0.03,0.07,0,0.15,0.32-0.02l-0.08-0.02
				c0.15-0.05,0.24-0.18,0.37-0.2l-0.09,0.08l0.26-0.14l-0.16,0.04c0.33-0.34-0.05-0.07,0.99-0.56c-0.01,0.14,0.25,0,0.27-0.12
				c-0.01,0.01,0,0.02-0.03,0.05c0.24-0.14,0.34-0.18,0.43-0.2l-0.06,0.04c0.3-0.14-0.02,0.02,1.04-0.6l-0.06,0.02
				c-0.02-0.14,0.24-0.05,0.36-0.17c0.11,0.11-0.02,0.06,0.67-0.31l-0.03,0.05c0.54-0.25,0.46-0.14,1.66-0.57
				c-0.08,0.06-0.14,0.08,0.24-0.07c-0.04-0.05-0.05-0.02-0.13-0.05c0.03-0.05,0.11-0.09,0.19-0.11c-0.13,0.07,0.03,0.09,0.09,0.1
				c-0.01-0.01,0-0.04-0.01-0.05c0.36-0.04,0-0.09,0.76-0.22c-0.05,0.03-0.13,0.05-0.18,0.08c0.17,0.04,0.04,0.01,0.47,0.01
				l-0.06-0.01c0.58-0.22,0.65-0.46,4.08-0.12c-0.14-0.16-0.03-0.15-0.39-0.32c0.18-0.13-0.3-0.09-0.1-0.23
				c-0.09,0.01-0.05-0.25-0.72-0.55c0.21,0-0.26-0.22-0.14-0.3c-0.17,0.02-0.38-0.24-0.61-0.3c0.02,0,0.05,0,0.06,0.01
				c-0.1-0.08-0.11-0.13-0.16-0.19c0.12,0.08-0.67-0.02-0.21-0.17c-0.04,0.07-0.27-0.07-0.26,0.01c-0.07-0.11-0.07-0.08-0.08-0.18
				c0.01,0.15-0.6-0.23-0.27-0.23c-0.5,0.01,0.02-0.06-1.22-0.44c-0.02,0.08-0.01,0.18-0.2,0.05c0.02-0.61,0.03-0.05-1.37-0.67
				l0.1-0.07c-0.75-0.09-1.31-0.57-3.66-1.14l0.06-0.01c-0.19-0.09-0.26,0.08-0.43-0.04l0.04,0
				C708.99,251.43,708.68,251.59,706.64,250.87z M711.85,252.38c-0.02,0-0.04-0.01-0.09-0.02
				C711.8,252.37,711.77,252.36,711.85,252.38z M711.52,252.33c0.02-0.01-0.05-0.05-0.1-0.08
				C711.39,252.26,711.65,252.27,711.52,252.33z M706.92,251.47c0.14,0.2-0.01,0.03,0.64,0.21l-0.07,0.04
				c0.58-0.04,0.24-0.05,1.32,0.23c-0.01,0.01-0.03,0.04-0.07,0.03c0.14,0.04,0.52,0.22,0.51,0.07c-0.02,0.09,0.3,0.1,0.47,0.1
				l-0.04,0.06c0.01-0.02,0.21-0.01,0.64,0.05l-0.08,0.02c0.23,0.08,0.19-0.13,0.53,0.17c-0.08-0.02,0.25-0.03,0.16,0.02
				c0.23,0.17,0.02-0.08,0.57,0.21c-0.2-0.07-0.36-0.12-0.34-0.12c-0.02,0.06,0.14,0.12-0.02,0.17c0.14,0.02,0.01-0.05,0.56,0.08
				l-0.02,0c0.22,0.06,0.11,0.07,0.48,0.2c0.02-0.07,0.12-0.02,0.04-0.11c0.02,0.01-0.04-0.01-0.11-0.02l0.01,0
				c-0.05-0.02-0.12-0.04-0.2-0.06c0.59,0.03,0.34,0.23,1.51,0.52c-0.21-0.01-0.19-0.04,0.47,0.19l-0.04,0.01
				c0.39,0.33,0.84,0.12,1.25,0.54c-0.03-0.03-0.04-0.04-0.04-0.06c0.12,0.27,1.48,0.63,2.63,1.84c-0.04-0.15-0.03-0.08,0.07,0.19
				l0.09-0.01c-0.09-0.15-0.35,0.02-0.52,0.02c0.01-0.07,0.07-0.15-0.43-0.11c0.02-0.02-0.02-0.07,0.05-0.06c-0.02,0-0.03,0-0.05,0
				c0.02-0.03,0.04,0.01-0.13,0l0-0.01l-0.01,0.01l0,0c-0.12,0.02-0.2,0.07-0.26,0.02l0.02-0.01c-0.32,0.03-0.86-0.02-2.16,0.33
				c0.01-0.03-0.37,0.03-0.56,0.04l0-0.02c-0.01,0.1-0.2,0.13-0.33,0.18c-0.01-0.05,0.01-0.08,0.08-0.12
				c-0.01,0.02-0.03-0.04-0.26,0.13l0.01-0.08c-0.06,0.04-0.29,0.01-0.62,0.36l0.07-0.08c-0.03,0.03-0.29,0.1-0.09,0
				c-0.4,0.08-0.17,0.03-0.94,0.37l0,0c-0.13,0.17-0.76,0.18-1.69,0.75c0.1-0.1-0.06-0.01,0.11-0.09c-0.16,0.06-0.22,0.05-0.35,0.19
				c0.03-0.09-0.15-0.01-0.21,0.05l0.13-0.02c-0.08,0.05-0.15,0.09-0.22,0.12l0-0.09c-0.35,0.14-0.68,0.38-1.38,0.81
				c0.23,0.11-0.45-0.01-0.31,0.11c-0.2,0.05-0.29,0.12-0.49,0.17c0.06,0.03-0.5,0.42-0.44,0.1c-0.16,0.11-0.34,0.06-0.45,0.07
				l0.09-0.04c-0.05,0.03-0.1,0.03-0.14,0.04l0.07,0.05c-0.06,0-0.1,0.01-0.11-0.01c-0.09,0.1,0.05,0.21-0.31,0.16
				c0.3,0,0.1-0.31-0.29,0c0.03-0.05-0.06-0.08-0.09-0.07c0.07-0.01,0.01,0.13-0.28,0.13l0.09,0.02c-0.17,0.11-0.4-0.03-0.98-0.3
				c0.03,0.07,0.04,0.06-0.1,0.07c0.1,0.02,0.2,0.04,0.31,0.06c-0.07,0.01-0.14,0.02-0.22,0.02c0.09,0.04,0.2,0.02,0.25,0.05
				c-0.1,0.02-0.09,0.01-0.06,0.07c-0.44-0.18-0.11-0.26-0.92-0.59l0.06,0.08c-0.07,0.02-0.15-0.1-0.23-0.15
				c0.04-0.03,0.01-0.08,0-0.13c-0.02,0.03-0.13-0.15-0.22-0.11c-0.08-0.29,0.02-0.13-0.34-0.51c0.07,0.05,0.07-0.06,0.16,0.06
				c-0.03-0.06-0.03-0.13-0.07-0.12c0.05,0.03-0.05-0.07-0.07-0.26c-0.01,0.03,0,0.01,0,0.07c-0.04-0.03,0.02-0.12-0.16-0.23
				c0.1-0.11,0.08-0.23,0.22-0.62c0,0.06,0.08,0.09,0.08,0l-0.08,0c0.06-0.17,0.14-0.38,0.28-0.67c0.01,0.02,0.02,0.01,0,0.04
				c0.25-0.46-0.01-0.01,0.51-0.58c-0.58-0.05-0.03,0.01,0.84-1.21l0.07-0.11c-0.59,0.26-0.14,0.25-1.54,0.11
				c0.12,0.16-0.12-0.01-0.11,0.14c-0.1-0.03-0.18,0.02-0.1-0.17c0.11,0.08-0.19,0.05-0.04,0.14c-0.14-0.05-0.18-0.15-0.33-0.34
				c0.01,0.06-0.09,0.06-0.18,0c0.04,0.04,0.08,0.08,0.12,0.12c-0.28-0.01-0.17-0.19-0.43-0.23c0.18-0.03-0.05-0.02-0.43-1.03
				l0.02,0.04c0.05-0.17,0.05-0.04,0.2-0.36l-0.01,0.08C702.98,252.22,701.87,251.51,706.92,251.47z M711.92,252.9
				c0.02,0,0,0.02,0.1,0.02C711.98,252.94,711.98,252.96,711.92,252.9z M704.57,254.96C704.58,255,704.57,254.99,704.57,254.96
				L704.57,254.96z"
                />
                <path
                  className="st10"
                  d="M708.49,254.97C708.5,254.97,708.5,254.97,708.49,254.97c0.01,0.06,0.03,0.16,0.07,0.31l0.03-0.07
				l0.02,0.06l-0.03,0.01c0.02,0.05,0.03,0,0.04-0.03c0.01,0.03,0.01,0.05,0.02,0.06l0.02-0.11c0.01,0.04-0.01,0.09,0.02,0.11
				c0.01-0.05-0.02-0.1,0-0.14c0.02,0.05,0.02,0.09,0.04,0.05c0.01,0.03,0,0.05,0,0.06c0.01-0.04,0.04-0.02,0.05-0.08
				c0.02,0.06,0.06-0.02,0.06,0.09c0-0.03,0.02-0.09,0.04-0.09c-0.02,0.13,0.06,0,0.06,0.14c0.01-0.12,0.07-0.06,0.11-0.13
				c0,0.01,0,0.03-0.01,0.02c0.02,0.01,0.03,0.04,0.05,0.06c0-0.1,0.03-0.12,0.04-0.18c0.03,0.09-0.01,0.12,0.01,0.21
				c-0.01-0.08,0.04-0.08,0.03-0.15c0.02,0.06,0.02,0.03,0.04,0.11c-0.01-0.12,0.02-0.02,0.03-0.1c0.03,0.06,0.02,0.11,0.04,0.07
				c0.03,0.17,0.02-0.03,0.2-0.12c0.02,0.04,0,0.03,0.03-0.1c-0.01,0-0.01,0.02-0.01,0.04l0.01-0.03c0.02,0.12-0.06,0.08,0.05,0.12
				l-0.04-0.03c0.06,0.06,0.15,0.15,0.5-0.03c0,0.03-0.01,0.05,0,0.08c-0.04-0.1,0.25-0.12,0.72-0.02c0-0.07,0.16-0.13,0.23,0.02
				l0.03-0.08l0.01,0.09c0.05-0.11-0.01,0.13,0.15-0.05c0,0.03-0.01,0.04-0.02,0.06c0.03-0.12,0.03,0.11,0.05-0.05
				c0.02,0.05,0.06,0.05,0.09,0.03c0.01,0.07-0.03,0.07,0.17,0.03c-0.02-0.03-0.01-0.12,0-0.13l0,0.08
				c0.01-0.05-0.02-0.03,0.01-0.09c0.02,0.01,0.01,0.09,0,0.12l0.03-0.03c0,0.02-0.01,0.04-0.01,0.07c0.01-0.05,0.04,0.08,0.06,0
				c-0.23,0.21,0.72-0.18,1.13,0.09c0-0.01,0-0.06,0.01-0.05c0.03-0.07,0.47,0.1,0.46,0.09c0.1-0.09,0.19,0.02,0.29-0.05
				c-0.01,0.02-0.01,0.04-0.02,0.04c0.01,0.06,0.07,0.01,0.08-0.04c-0.01,0.02,0.03,0.13,0.11,0.03c0.03,0.04,0.07-0.02,0.09,0.02
				c0.03-0.07,0.05,0.04,0.07-0.06c-0.02,0.11,0.05,0.1,0.08,0.05c-0.01,0.03,0.01,0.04,0,0.08c0.02,0,0.05-0.02,0.05-0.07
				c0.03,0.14,0.11-0.09,0.11,0.08c0.08-0.05,0.16-0.08,0.23-0.1c0.01,0.11-0.03,0.07,0.25,0.07c0,0.01-0.01,0.02-0.02,0.03
				c0.01,0,0.02,0.02,0.03-0.03l-0.01,0.01c-0.01-0.08,0.07-0.15,0.08-0.18c-0.04,0.14,0.05,0.05-0.01,0.22
				c0.01-0.01,0.02-0.03,0.03-0.06c0.06,0.09,0,0.02,0.27,0.1c-0.01-0.01,0.05,0.03,0.03-0.07c-0.02,0.04,0.11-0.07,0.05,0.01
				l0.05-0.01c-0.01-0.05-0.02-0.04,0.03-0.12c-0.01,0.01,0,0,0.03,0.06c0.03-0.02,0.08-0.07,0.03,0.09
				c0.03-0.07,0.07-0.01,0.1-0.09c-0.01,0.02-0.02,0.07-0.02,0.08l0.02-0.06c-0.01,0.1,0.04,0.05,0.03,0.03
				c-0.01,0.04,0.2-0.02,0.09,0.11c0.06-0.01,0.14-0.09,0.2-0.05c0.01-0.16,0-0.01,0.06,0.01c-0.01,0.01-0.02,0.07-0.02,0.06
				c0.03,0.03,0.09-0.01,0.15-0.04c-0.02,0,0.06,0.15,0.09,0.01c0.02,0.1,0.07,0.24,0.29-0.02c-0.03,0.03-0.02,0.09-0.02,0.12
				l0.01-0.05c-0.02,0.15,0.05-0.1,0.05-0.01c0.02-0.01,0.09-0.05,0.02,0.05c0.08-0.01-0.01-0.01,0.09,0.11
				c0.05-0.21,0-0.01,0.13-0.14c-0.05,0.1-0.02,0-0.04,0.2c0.03-0.05,0.02,0.05,0.07-0.03c0,0.02,0.02,0.01,0,0.07
				c-0.17-0.07,0.29-0.17,0.32,0.03c0.03-0.02,0.06-0.05,0.09-0.07c-0.01,0.04-0.03,0.06-0.04,0.08c0-0.02,0.05-0.01,0.08-0.1
				c-0.03,0.01-0.12-0.01-0.07-0.12c0.01,0.01-0.1,0.02-0.06-0.1c-0.07-0.05-0.09-0.06-0.16,0.12l0.02-0.19l-0.02,0.07
				c0,0-0.01-0.01,0-0.04c-0.03,0.05,0,0.03-0.03,0.08c-0.01-0.07-0.08,0.01-0.06-0.11c-0.02,0.05,0.01,0.05-0.02,0.08
				c0.05-0.14-0.09,0.05-0.02-0.14c-0.08,0.12,0.04-0.01-0.08,0.01c-0.01-0.08,0.1-0.06-0.08,0.02c0.02-0.03-0.03,0.01-0.07-0.12
				c-0.03,0.09-0.06,0.06-0.08,0.08c0.01-0.05,0.02-0.07,0.04-0.09c-0.03,0-0.02-0.01-0.06,0.04l0.02-0.07
				c-0.03,0-0.08,0.06-0.11,0.01c-0.02,0.06-0.01,0.18-0.06,0.21c-0.03-0.05,0.04,0.05,0-0.26c-0.01,0.03-0.06,0.14-0.04-0.02
				c-0.01,0.03-0.03-0.01-0.04,0.07c-0.01,0,0-0.04,0-0.06c-0.02,0.03-0.01-0.01-0.07,0.08c-0.08,0.09-0.25-0.21-0.74-0.34
				c-0.03,0.02-0.02,0.05,0,0.06c-0.03-0.05-0.06-0.01-0.09-0.05c0.08-0.07-0.17,0.15-0.07-0.07c-0.04,0.05-0.06-0.05-0.08-0.06
				l0.02-0.02c-0.01,0.01-0.02,0.01-0.03,0.01l0,0.06c-0.01-0.01-0.02-0.01-0.01-0.04c-0.02,0.04-0.01,0.1-0.04,0.15
				c-0.01-0.03-0.05,0.02-0.04-0.05c0.04,0.03,0.01-0.08,0.05-0.08c-0.06-0.05,0-0.09-0.1,0.05c0.01-0.04,0-0.08-0.01-0.08
				c0.02,0,0,0.07-0.03,0.11c-0.02-0.04-0.04,0.02-0.05,0.01c0.01,0.04,0.02,0.03-0.03,0.06c0-0.03,0.02-0.02,0.02-0.04
				c-0.03,0.07-0.04-0.09-0.08-0.04c-0.01-0.03,0.01-0.08,0-0.1c-0.06,0.06-0.07-0.09-0.1,0.08l0.07-0.03
				c-0.01,0.13-0.19,0.11-0.26-0.05c0,0.04-0.04-0.01-0.06,0.09c0.03-0.21-0.18,0.17-0.09-0.08c-0.05,0.04,0.01-0.01-0.14,0.16
				c-0.03-0.16-0.13,0-0.15-0.14c-0.19,0.07-0.04,0.07-0.45,0.06c0.02-0.06,0.02-0.03,0.02-0.1c-0.05,0.27-0.11,0.02-0.24-0.04
				c-0.02,0.02-0.07,0.09-0.11,0.1c0.03-0.07,0.07-0.1,0.1-0.15c-0.14,0.09,0.03,0.09-0.5-0.1c0.02,0.06-0.02,0.08-0.01,0.13
				c-0.03-0.03-0.05-0.1-0.06-0.25c0,0.05-0.02,0.08-0.04,0.05c0.05,0.02-0.1,0.06-0.05-0.03c-0.03,0.03-0.1-0.01-0.14,0.11
				c0.03-0.17-0.14-0.07-0.42,0.05c-0.05-0.12-0.03-0.31-0.27-0.05c-0.04-0.12-0.15-0.05-0.21-0.12c0.01,0.02,0,0.09-0.02,0.1
				c-0.02-0.02-0.05,0.08-0.04-0.03l0.01,0c-0.01-0.1-0.04-0.07-0.07-0.08l-0.01,0.13c-0.07-0.19-0.18,0.07-0.22-0.1
				c-0.02,0.03-0.04,0.03-0.05,0.07c-0.01-0.04,0.03-0.11-0.13-0.08c0.05-0.23-0.15,0-0.32,0.08c0-0.09-0.06-0.01-0.09,0.03
				c-0.01-0.09,0.06-0.1-0.2,0.01c0,0.05-0.07,0.05-0.14,0c0.04,0,0.07,0,0.07,0.01c0.03-0.01-0.2-0.2-0.2,0.02
				c0-0.01,0,0,0.02-0.02l0,0.01l0.04-0.01c-0.01,0.04-0.02,0.07-0.04,0.03c-0.01,0.05-0.01,0.09-0.02,0.11
				c-0.04-0.02-0.09-0.23-0.29-0.02c-0.04-0.04,0.01-0.09-0.03-0.07c-0.07-0.19,0-0.03-0.24,0.03c0.01,0.01,0.01,0.02,0.01,0.03
				c-0.04-0.01-0.17-0.14-0.4,0.05l-0.01-0.07C709.02,254.98,708.67,254.65,708.49,254.97z M710.02,255.03
				c0,0.02,0.01,0.03,0.02,0.05C710.02,255.07,710,255.08,710.02,255.03z M709.89,254.63c0-0.01,0-0.03,0-0.04
				C709.9,254.59,709.91,254.61,709.89,254.63z"
                />
                <path
                  className="st10"
                  d="M722.57,257.63c-0.07-0.1-0.03-0.11-0.27-0.18c0.01,0,0.01,0,0.01,0c-0.05,0.05-0.12,0.12-0.22,0.23
				l0.08-0.01l-0.03,0.05l-0.03-0.03c-0.03,0.05,0.02,0.03,0.05,0.02c-0.02,0.02-0.03,0.04-0.03,0.05l0.11-0.04
				c-0.03,0.03-0.08,0.04-0.08,0.08c0.05-0.02,0.06-0.08,0.11-0.08c-0.03,0.05-0.06,0.07-0.01,0.06c-0.02,0.02-0.04,0.03-0.06,0.03
				c0.04-0.01,0.05,0.04,0.11,0c-0.03,0.06,0.06,0.05-0.02,0.12c0.02-0.01,0.09-0.03,0.11-0.01c-0.12,0.06,0.05,0.06-0.07,0.15
				c0.11-0.06,0.11,0.04,0.19,0.04c-0.01,0-0.03,0.01-0.02,0.01c0.01,0.03-0.01,0.06-0.01,0.09c0.07-0.06,0.12-0.05,0.18-0.07
				c-0.05,0.09-0.1,0.06-0.16,0.13c0.06-0.05,0.1-0.01,0.14-0.06c-0.02,0.06-0.01,0.04-0.06,0.1c0.09-0.08,0.03,0,0.1-0.03
				c-0.02,0.07-0.08,0.09-0.02,0.09c-0.16,0.06,0.14,0.15,0.37,0.08c-0.01-0.01-0.02,0.01-0.04,0.02l0.03,0
				c-0.09,0.1-0.12-0.01-0.05,0.13l-0.01-0.06c-0.25-0.06,0.36,0.19,1.13,1.14c0.05-0.04,0.21,0,0.22,0.2l0.08-0.04l-0.04,0.08
				c0.16-0.06-0.12,0.05,0.19,0.09c-0.02,0.02-0.04,0.02-0.06,0.03c0.1-0.07-0.04,0.11,0.08,0c-0.01,0.05,0.03,0.08,0.08,0.09
				c-0.03,0.08-0.06,0.03,0.16,0.16c-0.01-0.04,0.05-0.11,0.08-0.1l-0.04,0.07c0.04-0.03,0.12-0.13-0.01,0.03l0.05,0
				c-0.01,0.02-0.03,0.03-0.05,0.05c0.04-0.03,0,0.1,0.07,0.05c-0.33,0.01,0.81,0.35,1.25,0.86c0.01-0.01,0.05-0.1,0.03,0.01
				c-0.01-0.21,0.54,0.37,0.52,0.29c0.16-0.02,0.22,0.13,0.37,0.11c-0.02,0.01-0.03,0.04-0.04,0.03c0,0.06,0.08,0.05,0.11,0
				c-0.08-0.04,0.16,0.16,0.34,0.12c-0.05,0.1,0.03,0.12,0.08,0.08c-0.02,0.03,0.01,0.04-0.02,0.08c0.03,0.01,0.07,0.01,0.09-0.05
				c0,0.15,0.16-0.05,0.11,0.12c0.16,0.02,0.01-0.1,0.6,0.16c0,0.01-0.02,0.02-0.03,0.02c0.02,0,0.03,0.03,0.04-0.02l-0.02,0.01
				c0.01-0.08,0.11-0.12,0.13-0.16c-0.08,0.13,0.05,0.06-0.05,0.22c0.01-0.01,0.03-0.02,0.04-0.06c0.07,0.11,0.01,0.02,0.32,0.19
				c-0.02-0.02,0.06,0.04,0.05-0.06c-0.03,0.03,0.15-0.04,0.07,0.03l0.07,0.01c-0.01-0.05-0.02-0.05,0.05-0.12
				c0.02,0.07,0.06,0.04,0.06,0.17c0.04-0.07,0.09,0.01,0.13-0.06c-0.01,0.02-0.03,0.06-0.03,0.07l0.04-0.05
				c0.01,0.05,0.03,0,0.14,0.11c-0.01,0.01-0.05-0.02-0.02,0.05l0.02-0.04c-0.05,0.1,0.2-0.07,0.33,0.07
				c-0.01,0.01-0.03,0.07-0.03,0.05c0.04,0.04,0.11,0,0.2-0.02c-0.03,0,0.07,0.16,0.12,0.03c0.03,0.1,0.1,0.26,0.37,0.02
				c-0.04,0.03-0.02,0.09-0.02,0.12l0.02-0.05c-0.02,0.15,0.06-0.09,0.06,0c0.02-0.01,0.12-0.04,0.03,0.05
				c0.1,0-0.02-0.02,0.12,0.12c0.05-0.21,0-0.01,0.16-0.13c-0.06,0.09-0.03,0.01-0.03,0.2c0.04-0.05,0.03,0.06,0.08-0.02
				c0,0.02,0.03,0.01,0.01,0.07c-0.2-0.1,0.37-0.13,0.42,0.04c0.03-0.02,0.07-0.05,0.1-0.07c0,0.04-0.03,0.06-0.05,0.08
				c0-0.02,0.06-0.01,0.09-0.1c-0.04,0.01-0.14-0.01-0.1-0.12c0.01,0.01-0.12,0.02-0.08-0.1c-0.08-0.05-0.12-0.07-0.19,0.12
				l0.01-0.19l-0.02,0.07c-0.01,0-0.02-0.01-0.01-0.04c-0.04,0.04,0,0.03-0.03,0.08c-0.02-0.07-0.1,0.01-0.08-0.11
				c-0.03,0.05,0.02,0.05-0.02,0.08c0.05-0.14-0.11,0.04-0.03-0.14c-0.1,0.1-0.06-0.13-0.2,0.01c0.02-0.03-0.03,0.01-0.1-0.13
				c-0.03,0.09-0.07,0.06-0.11,0.07c0.02-0.05,0.03-0.07,0.05-0.09c-0.03,0-0.03-0.01-0.08,0.04l0.03-0.06
				c-0.04-0.01-0.1,0.06-0.13,0c-0.02,0.06-0.01,0.18-0.07,0.21c0.01-0.03,0.02-0.09,0.04-0.1c-0.05-0.06,0.01,0.04-0.03-0.17
				c-0.02,0.03-0.07,0.13-0.05-0.03c-0.02,0.03-0.03-0.01-0.05,0.06c-0.01,0,0-0.04,0-0.06c-0.03,0.03,0,0-0.1,0.07l0,0
				c0,0.15-0.12-0.13-0.44-0.2c0.05-0.01-0.06-0.13-0.01-0.02c-0.2-0.22-0.39,0.09-0.43-0.27c-0.04,0.02-0.03,0.04-0.01,0.06
				c-0.03-0.06-0.07-0.03-0.1-0.07c-0.01,0.07-0.23,0.11-0.08-0.09c-0.06,0.04-0.06-0.07-0.08-0.09l0.03-0.01
				c-0.02,0.01-0.03,0-0.04,0l-0.01,0.06c-0.01-0.01-0.02-0.02-0.01-0.04c-0.03,0.03-0.03,0.1-0.07,0.13
				c-0.01-0.03-0.06,0.01-0.04-0.07c0.04,0.05,0.02-0.08,0.07-0.07c-0.06-0.06,0.01-0.09-0.13,0.02c0.02-0.04,0.02-0.08,0.01-0.08
				c0.02,0.01-0.02,0.07-0.05,0.1c-0.02-0.05-0.05,0.01-0.06,0c0.01,0.04,0.02,0.04-0.05,0.05c0.01-0.03,0.03-0.01,0.03-0.03
				c-0.06,0.06-0.03-0.1-0.08-0.07c-0.01-0.03,0.03-0.08,0.02-0.1c-0.06,0.07-0.03-0.14-0.14,0.04l0.09-0.01
				c-0.11,0.09-0.04,0.15-0.25-0.09c0,0.04,0.02,0.07-0.06,0.03c0.02-0.04,0.02-0.07,0.02-0.1c-0.01,0.03-0.05-0.02-0.09,0.06
				c-0.02-0.08,0.03-0.13-0.16-0.01c0.04-0.04,0.03-0.08,0.08-0.1c-0.02-0.01-0.03-0.02-0.04,0.01c0.02,0.01-0.09-0.05-0.17,0.09
				c0.02-0.16-0.15-0.06-0.13-0.2c-0.25-0.02-0.08,0.06-0.55-0.16c0.04-0.05,0.03-0.02,0.06-0.08c-0.02,0-0.04,0.09-0.09,0.09
				c0.02,0.15-0.01-0.34-0.34-0.22c0.06-0.05,0.12-0.06,0.17-0.08c-0.02-0.01-0.07-0.03-0.11,0c0.03,0.01,0.04,0.02-0.12,0.04
				c0.04-0.25,0.02-0.25-0.28-0.42c-0.01,0.06-0.06,0.06-0.07,0.11c-0.02-0.04-0.01-0.13,0.05-0.26c-0.02,0.05-0.06,0.06-0.07,0.03
				c0.04,0.06-0.14,0-0.04-0.06c-0.05,0.01-0.11-0.07-0.2,0.01c0.24-0.2-0.27-0.04-0.98-0.72l0,0.01c0.05-0.09,0-0.09-0.03-0.11
				l-0.09,0.1c0.05-0.21-0.22-0.09-0.15-0.25c-0.04,0.01-0.06,0-0.09,0.01c0.02-0.06,0.08-0.06-0.07-0.16
				c0.17-0.14-0.13-0.11-0.35-0.2c0.06-0.07-0.05-0.05-0.1-0.05c0.07-0.08,0.11-0.02-0.19-0.16c-0.04,0.03-0.09-0.02-0.13-0.12
				c0.03,0.03,0.06,0.06,0.05,0.07c0.06,0-0.03-0.3-0.19-0.17c0-0.01,0,0,0.03,0l-0.01,0l0.04,0.03c-0.04,0.02-0.07,0.03-0.06-0.02
				c-0.04,0.03-0.08,0.05-0.1,0.06c-0.02-0.06,0.1-0.22-0.24-0.28c0.09-0.27,0.08-0.07-0.2-0.28c0,0.01,0,0.02-0.01,0.03
				c-0.04-0.06,0-0.21-0.37-0.36l0.05-0.06C722.66,257.82,722.65,257.7,722.57,257.63z M723.73,258.57c0.01,0,0.02,0.03-0.03,0.02
				C723.71,258.58,723.72,258.57,723.73,258.57z M723.53,258.99c-0.01,0.01-0.01,0.03-0.01,0.06
				C723.51,259.02,723.47,259.01,723.53,258.99z"
                />
                <path
                  className="st10"
                  d="M747.81,250.49l0.02,0.06c-0.14-0.2,0.21-0.31-0.96-1.31c0.02-0.01,0.03-0.02,0.04-0.02
				c-0.07,0-0.19-0.04-0.36-0.3c0.08,0.03,0.15,0.08,0.23,0.12c-0.07-0.15-0.29-0.16-0.41-0.21l0.03-0.03
				c-0.38-0.1-0.41-0.01-0.81-0.04l0.04,0.03c-0.26,0.09-0.34,0.02-0.44,0c0.23-0.38,0.37-0.89,0.11-1.56
				c-0.01,0.28-0.1-0.18-0.22-0.04c-0.29-0.29-0.25-0.38-0.59-0.75c0.02,0.07-0.14,0.02-0.32-0.03c0.08,0.04,0.16,0.09,0.24,0.14
				c-0.45-0.04-0.37-0.24-0.85-0.16c0.37-0.14,0.23-0.09-1.19,0.47c0.02-0.05,0.03-0.11,0.04-0.15l0.01,0.01
				c-0.63,1.42,0.9-4.08-4.89-0.66l-0.04-0.06c-0.29,0.2-0.43,0.3-0.74,0.6l0.03-0.1c-0.47,0.65-0.21,0.29-1.27,1.35
				c-0.01-0.02-0.02-0.05,0.03-0.1c-0.14,0.14-0.58,0.48-0.43,0.54c-0.07-0.06-0.31,0.3-0.43,0.48l-0.02-0.07
				c0.01,0.03-0.15,0.25-0.51,0.68l0.04-0.1c-0.24,0.22-0.01,0.27-0.55,0.51c0.07-0.07-0.15,0.28-0.14,0.17
				c-0.33,0.17,0.07,0.05-0.63,0.51c0.22-0.18,0.39-0.33,0.38-0.31c-0.04-0.05-0.22,0.09-0.14-0.09c-0.12,0.14,0.04,0.03-0.5,0.55
				l0.01-0.02c-0.23,0.2-0.15,0.08-0.57,0.39c0.05,0.05-0.08,0.13,0.07,0.09c-0.03,0.01,0.04-0.03,0.11-0.1l0,0.01
				c0.06-0.05,0.13-0.11,0.22-0.17c-0.51,0.58-0.49,0.26-1.75,1.22c0.19-0.19,0.2-0.16-0.58,0.34l0.03-0.05
				c-0.65,0.19-0.86,0.71-1.62,0.84c0.05-0.01,0.08-0.02,0.09-0.01c-0.37-0.03-1.89,0.91-4.17,1.2l0.07,0.04
				c-0.54-0.09-0.7,0.24-1.38,0.58c0.3,0.1,0.96-0.32,0.97-0.1c0.07-0.07,0.28,0.11,1.17-0.29c-0.19,0.18,0.45-0.09,0.42,0.06
				c0.13-0.15,0.56-0.2,0.83-0.34c-0.01,0.01-0.04,0.04-0.06,0.04c0.05,0.21,0.77-0.56,0.67-0.11c-0.03-0.07,0.31-0.18,0.22-0.23
				c0.16,0,0.13-0.02,0.24,0.03c-0.15-0.08,0.76-0.39,0.47-0.1c0.46-0.48,0.03,0.07,1.51-0.84c-0.07-0.1-0.12-0.11,0.13-0.21
				c0.38,0.29,0,0.2,1.83-0.94l-0.02,0.13c0.74-0.74,1.72-0.97,3.92-3.38l-0.03,0.06c0.21-0.17,0.11-0.33,0.34-0.46l-0.03,0.04
				c0.16-0.17,1.42-1.29,2.52-2.22l0.05,0.06c-0.02-0.02,0.1-0.13,0.38-0.26c-0.13-0.07,0.17,0.03,0.83-0.49
				c0,0.03-0.07,0.08-0.13,0.13c0.22-0.19,0.08,0.05,0.28-0.13c-0.04,0,0-0.05,0-0.07c0.14,0,0.41-0.12,0.6-0.2l-0.02,0.06
				c0.36-0.11,0.7-0.19,0.99-0.01c0.46,0.48-0.02,0.01,0.29,2.43l0-0.02c-0.31,0.24-0.14,0.04,0.17,0.72
				c0.08-0.08,0.04-0.08,0.31-0.44c-0.03,0.01-0.05,0.01-0.06,0.01c0.54-0.54,0.01-0.05,1.46-0.9l-0.02,0.01
				c-0.41-0.01,1.72-0.01,1.18,2.62c0.12-0.27,0.56-0.26,1.88-0.33l-0.13,0.03c0.6,0.13,0.92,0.79,1.08,1.37
				c-0.38-0.12,0.49-0.31-0.46,2.16c-0.08-0.07-0.32,0.3-0.39,0.44c0.01-0.06-0.12,0.08-0.11,0c-0.12,0.13-0.22,0.26-0.16,0.28
				c-0.55,0.29-0.3,0.32-1.93,1.43c0.07-0.1,0.13-0.16,0.17-0.2c-0.25,0.12-0.33,0.27-1.93,0.91c0.01,0.01,0.05,0.02-0.28,0.29
				c0.09-0.18-0.41,0.08-0.25-0.16c-0.05,0.02-0.11,0.07-0.09,0.1c-0.08,0.01-0.11,0-0.04-0.06c-0.28,0.11-0.16,0.13-0.42,0.2
				c-0.01-0.02,0.04-0.05,0.06-0.08c-0.12,0.08-0.24,0.16-0.46,0.2c0.01-0.02-0.02-0.03,0.05-0.05c-0.38,0.1-0.62,0.28-1.05,0.32
				c0.06-0.07,0.05-0.15-0.41-0.02l0.1,0.03c-0.2,0.04-0.36,0.13-0.54,0.14l0.14-0.06l-0.37,0.08l0.22,0
				c-0.19,0.08,0.04,0.11-0.23,0.13c0.05-0.02-0.2,0.03-0.31,0l0,0c-0.43,0.15-0.13-0.15-0.94,0.17c0.09-0.14-0.31-0.07-0.39,0.04
				c0.03-0.01,0.01-0.02,0.06-0.03c-0.36,0.06-0.51,0.06-0.63,0.05l0.09-0.02c-0.45,0.03,0.04-0.01-1.6,0.23l0.08,0
				c-0.48,0.16-0.32-0.15-1.53,0.16l0.05-0.04c-0.82,0.11-0.65,0-2.37,0.14c0.13-0.04,0.2-0.05-0.34,0.02
				c0.04,0.06,0.06,0.03,0.15,0.07c-0.05,0.04-0.18,0.07-0.29,0.07c0.2-0.05-0.01-0.09-0.09-0.12c0.01,0.02-0.01,0.04-0.01,0.06
				c-0.23-0.09-0.03,0.12-0.35,0.06c0.02-0.05,0.05-0.11-0.72,0.01c0.09-0.02,0.17-0.03,0.26-0.04c-0.18-0.07-0.2-0.05-0.33-0.06
				l0.01,0l-0.3-0.03l0.08,0.02c-0.22,0.18-0.45,0.04-0.75,0.15c0.29-0.11,0.05-0.06,0.07-0.14c-0.08-0.02-0.19-0.04-0.16-0.06
				c-0.14,0.06-0.24-0.05-0.44,0.04c0.06-0.23-0.19,0.45-4.07-0.33c0.08-0.07,0.18-0.1-0.49-0.19l0.07,0.06
				c0-0.04,0.09-0.13,0.18,0.05l-0.08-0.02c0.03,0.17,0.33,0.1,0.49,0.17c-0.09,0.04,0,0.12,0.15,0.2c-0.36-0.04-0.35,0.16,0.2,0.06
				c-0.03,0.01,0,0.08-0.07,0.04c0.19,0.13,0.36,0.04,0.41,0.14l-0.02,0c0.3,0.09,0.77,0.32,2.09,0.52
				c-0.02,0.03,0.35,0.13,0.52,0.2l-0.01,0.01c0.05-0.08,0.23-0.03,0.37-0.02c-0.02,0.05-0.04,0.07-0.12,0.08
				c0.02-0.01,0.01,0.04,0.29,0l-0.04,0.06c0.1-0.08,0.21,0.23,0.7-0.03l-0.1,0.04c0.04-0.01,0.3,0.05,0.07,0.04
				c0.38,0.12,0.16,0.05,0.98,0.16l0,0c0.3-0.02,0,0,0.87,0.18c-0.04,0.09,0.27,0.05,0.91,0.21c-0.14,0.02,0.04,0.05-0.14,0
				c0.16,0.05,0.19,0.1,0.38,0.07c-0.07,0.05,0.11,0.11,0.19,0.11l-0.11-0.07c0.09,0.01,0.17,0.03,0.24,0.06l-0.06,0.07
				c0.35,0.13,0.75,0.17,1.55,0.31c-0.09-0.24,0.33,0.3,0.3,0.12c0.18,0.1,0.3,0.1,0.49,0.19c-0.03-0.06,0.65-0.02,0.41,0.2
				c0.2,0.01,0.31,0.16,0.41,0.21l-0.1-0.01c0.06,0.01,0.11,0.03,0.15,0.04l-0.03-0.08c0.05,0.03,0.1,0.05,0.09,0.07
				c0.14-0.04,0.07-0.2,0.35,0.03c-0.26-0.17-0.25,0.22,0.25,0.15c-0.05,0.03,0.01,0.1,0.05,0.11c-0.07-0.03,0.04-0.1,0.32,0.01
				l-0.08-0.05c0.22-0.05,0.33,0.24,0.87,0.63c-0.02-0.08-0.03-0.07,0.12-0.05l-0.3-0.13l0.23,0.03c-0.08-0.05-0.2-0.07-0.24-0.11
				c0.11,0.01,0.09,0.02,0.08-0.05c0.41,0.27,0.05,0.32,0.88,0.72l-0.06-0.08c0.07-0.02,0.16,0.1,0.25,0.14
				c-0.04,0.03,0,0.08,0.01,0.13c0.02-0.03,0.17,0.14,0.25,0.08c0.14,0.25,0.02,0.16,0.51,0.4c-0.09-0.01-0.04,0.08-0.17,0
				c0.54,0.47,0.05-0.03,0.92,1.67c-0.02-0.01-0.02,0-0.02-0.03c0.04,0.5,0.03,0.01-0.12,0.73l0-0.04
				c-0.12,0.16,0.07,0.06-0.29,1.04c-0.02-0.02-0.03-0.04-0.04-0.05c0.03,0.05,0.05,0.17-0.22,0.22l0.1-0.13
				c-0.12,0.01-0.12,0.16-0.19,0.22l-0.02-0.03c-0.15,0.18-0.09,0.26-0.3,0.43l0.04,0.01c-0.25,0.29-0.17,0-0.68,0.49
				c0.06-0.11,0.21-0.24,0.29-0.35c-0.08,0.05-0.27,0.17-0.29,0.24c0.03-0.03,0.06-0.07,0.1-0.08c-0.32,0.06-0.66,0.73-2.68,0.42
				c0.02,0.05-0.07,0.08-0.18,0.04c0.05,0.03,0.1,0.06,0.15,0.09c-0.28,0.05-0.21-0.15-0.49-0.12l0.09-0.03
				c-0.11-0.16-0.38,0.21-1.35-0.68c0.14-0.27,0.02-0.02-0.6,0c0.01,0.02,0.03,0.03,0.03,0.05l-0.07-0.02
				c0.36,0.99,0.68,0.14-1.44,2.14c0.03,0.01-0.24-0.23-0.39,0.06c-0.11-0.17-0.57,0.03-1.94-0.66l0.08-0.02
				c-0.55-0.06-0.25,0.02-1.21-0.49c0.01-0.01,0.03-0.03,0.08-0.01c-0.12-0.07-0.42-0.34-0.46-0.19c0.04-0.08-0.25-0.17-0.4-0.24
				l0.06-0.04c-0.02,0.01-0.2-0.05-0.57-0.25l0.08,0.01c-0.18-0.16-0.22,0.05-0.41-0.35c0.06,0.04-0.24-0.07-0.14-0.08
				c-0.13-0.24-0.05,0.06-0.41-0.43c0.15,0.14,0.27,0.26,0.25,0.25c0.04-0.05-0.07-0.17,0.09-0.14c-0.11-0.08-0.03,0.04-0.46-0.3
				l0.02,0.01c-0.16-0.16-0.06-0.12-0.31-0.4c-0.05,0.05-0.11-0.03-0.09,0.08c-0.01-0.02,0.03,0.03,0.09,0.06l-0.01,0
				c0.04,0.04,0.09,0.09,0.14,0.15c-0.49-0.29-0.2-0.34-1.02-1.18c0.17,0.12,0.14,0.13-0.29-0.4l0.04,0.01
				c-0.13-0.49-0.64-0.55-0.72-1.13c0.01,0.04,0.01,0.06,0,0.07c0.04-0.27-0.81-1.36-1.02-3l-0.04,0.05
				c-0.06-0.28-0.01-0.14,0.21-0.93c-0.17,0.14,0.04,0.09-0.25,0.57c0.08-0.73-0.11,0.22-0.5-0.63c0.06,0.14-0.12,0.11,0.13,0.48
				c-0.38,0.04,0.31,0.12-0.07,1.5l0.06-0.21c-0.2,0.16-0.28,0.83-0.47,1.23c-0.05-0.16-0.08-0.14-0.53,0.93
				c0-0.06-0.09,0.02,0-0.15c-0.27,0.48-0.27,0.85-0.43,1.01l0.01-0.05c-0.69,1.47-0.18,0.85-1.98,4.01
				c-0.08-0.07,0.1-0.07-0.43,0.86c0-0.04-0.49,0.79-0.76,1.16l-0.01-0.02c0.03,0.12-0.22,0.52-0.38,0.83
				c-0.04-0.04-0.03-0.1,0.04-0.28c-0.01,0.05-0.05-0.01-0.31,0.64c-0.02-0.3-0.13,0.12-0.79,1.45l0.08-0.23
				c-0.14,0.18-0.19,0.44-0.13,0.15c-0.64,0.9-0.28,0.4-1.35,2.11l0,0c-0.36,0.68-0.63,0.84-2.72,3.71c0.17-0.29-0.1,0.07,0.18-0.29
				c-0.27,0.33-0.36,0.38-0.59,0.79c0.05-0.16-0.26,0.21-0.37,0.38l0.22-0.2c-0.14,0.18-0.27,0.34-0.4,0.49l0-0.14
				c-0.6,0.65-1.21,1.53-2.53,3.13c0.41-0.14-0.84,0.64-0.57,0.6c-0.37,0.36-0.55,0.6-0.93,0.96c0.04,0.03-0.84,1.22-0.84,0.8
				c-0.29,0.39-0.65,0.63-0.86,0.81l0.17-0.19c-0.1,0.12-0.19,0.2-0.28,0.29l0.15-0.06c-0.11,0.1-0.21,0.19-0.22,0.17
				c-0.14,0.35,0.09,0.15-0.55,0.68c0.61-0.57,0.07-0.49-0.56,0.51c0.03-0.09-0.16,0.04-0.23,0.12c0.14-0.13,0.02,0.17-0.46,0.61
				l0.19-0.15c-0.4,0.47-1.35,1.09-3.82,3.21l0.21-0.14c-0.5,0.52-0.64,0.37-2.58,2.03c0.12-0.15-0.12-0.02,0.18-0.28
				c-0.73,0.64,0.25-0.12-4.44,2.85c0.02-0.03-0.01-0.02,0.06-0.05c-1.16,0.68-0.03,0-1.81,0.89l0.09-0.06
				c-0.46,0.19-0.13,0.07-2.51,1.4c0-0.03,0-0.04,0-0.06c-0.05,0.08-0.23,0.22-0.75,0.28l0.39-0.19c-0.24,0.01-0.5,0.24-0.71,0.32
				l0.01-0.05c-0.59,0.29-0.56,0.38-1.21,0.67l0.08,0c-0.85,0.5-0.46-0.04-1.98,0.92c0.21-0.17,0.68-0.43,0.97-0.6
				c-0.23,0.1-0.78,0.36-0.88,0.46c0.09-0.05,0.2-0.12,0.29-0.15c-1.88,0.98-0.06,0.07-5.25,2.27c0.4-0.02-0.27,0.13-0.15,0.24
				c-0.11,0.06,0.01,0-0.32,0.15c0,0,0,0,0.02,0c-0.32,0.12-0.62,0.15-1.12,0.24c0.09,0.02-0.13,0.15-0.42,0.26l0.42-0.12
				c-0.64,0.39-0.64,0.16-1.28,0.55l0.19-0.14c-0.68,0.29-0.64,0.35-4.02,2.11l0.04-0.07c-0.24,0.16-0.12,0.04-1.05,0.59l0.15-0.13
				c-0.82,0.37-1.17,0.73-1.82,1.1c-0.05-0.1-0.69,0.2-1,0.36l0-0.02c-1.36,0.87-4.18,2.28-9.54,5.94l-0.03-0.07
				c-0.53,0.36-0.79,0.53-1.42,1l0.1-0.13c-1.11,1.01-0.54,0.45-2.67,2.13c0-0.02,0-0.06,0.09-0.14c-0.29,0.22-1.15,0.82-0.95,0.85
				c-0.07-0.07-0.63,0.49-0.93,0.78l0.01-0.09c-0.02,0.04-0.36,0.4-1.1,1.14l0.1-0.15c-0.25,0.39-0.79,0.55-2.48,2.25
				c0.38-0.37,0.68-0.67,0.66-0.64c-0.04-0.05-0.36,0.25-0.17-0.06c-0.23,0.26,0.04,0.03-0.93,1.07l0.03-0.04
				c-0.4,0.42-0.25,0.2-0.96,0.9c0.06,0.04-0.14,0.23,0.08,0.08c-0.05,0.04,0.07-0.07,0.18-0.21c0,0,0,0.01-0.01,0.01
				c0.1-0.1,0.23-0.23,0.37-0.38c-0.91,1.17-0.89,0.63-2.73,2.98c0.06-0.09,0.15-0.18,0.17-0.24c-0.04,0-0.5,0.82-1.01,1.2
				l0.04-0.08c-0.89,0.91-1.06,1.78-2.06,2.78c0.07-0.07,0.1-0.11,0.13-0.11c-0.82,1.01-2.4,3.68-4.15,7.05l0.09-0.08
				c-0.13,0.27-0.34,0.89-0.38,0.82c0.03,0.27-1.31,2.42-0.75,2.02c-0.19,0.33,0.12,0.56,0.61-0.6l-0.15,0.38
				c0.24-0.33,0.54-1.24,1.58-3.66c-0.07,0.42,0.4-0.73,0.47-0.57c0.04-0.32,0.46-0.98,0.65-1.48c0,0.37-0.05,0.18,0.67-0.87
				c-0.08-0.03,0.28-0.58,0.15-0.51c0.2-0.2,0.15-0.19,0.3-0.27c-0.22,0.11,0.74-1.35,0.52-0.69c0.4-1,0-0.06,1.69-2.64
				c-0.17-0.07-0.14-0.02,0.14-0.39c0.39,0.08,0.03,0.28,2.51-2.83l-0.04,0.19c1.24-1.33-0.71,0.14,6.88-6.55l-0.09,0.1
				c0.42-0.29,0.36-0.47,0.77-0.71l-0.06,0.07c0.35-0.21,2.94-2.31,5.16-3.66c-0.14-0.03,0.67-0.41,2.28-1.53
				c0,0.04-0.14,0.14-0.24,0.23c0.79-0.58,0.14-0.13,1.58-1.01l-0.04,0.09c2.68-1.65,2.6-1.49,12.14-6.65l-0.04,0.03
				c2.14-1.06-0.03-0.09,5.5-2.5l-0.06-0.02c1.22-0.54,0.27-0.35,6.28-2.89l-0.18,0.17c1.16-0.65,2.46-1.16,3.58-1.83
				c-0.4,0.26,1.15-0.44,3.98-2.31c0.03,0.12,0.77-0.4,1-0.61c-0.07,0.09,0.24-0.12,0.17-0.01c0.3-0.2,0.55-0.39,0.47-0.41
				c1.03-0.6,0.81-0.41,3.65-2.85c-0.07,0.1-0.13,0.18-0.19,0.24c0.53-0.48,0.66-0.44,2.93-2.59c0.01,0.01-0.05,0.07-0.11,0.14
				c0.33-0.38-0.24,0.26,0.47-0.74c-0.09,0.29,0.59-0.52,0.41-0.09c0.07-0.08,0.14-0.19,0.1-0.19c0.12-0.1,0.17-0.12,0.08,0.02
				c0.38-0.41,0.2-0.31,0.57-0.66c0.02,0.02-0.05,0.1-0.07,0.15c0.15-0.21,0.3-0.43,0.62-0.71c-0.01,0.03,0.04,0.01-0.05,0.1
				c0.51-0.53,0.77-1,1.36-1.55c-0.04,0.12-0.04,0.23,0.59-0.48l-0.15,0.1c0.27-0.27,0.43-0.57,0.67-0.8l-0.16,0.24l0.46-0.55
				l-0.29,0.28c0.22-0.32-0.12-0.05,0.24-0.41c-0.06,0.08,0.25-0.28,0.42-0.4l0,0c0.44-0.62,0.31-0.08,1.14-1.36
				c-0.02,0.24,0.44-0.37,0.48-0.56c-0.03,0.04,0,0.03-0.06,0.12c0.41-0.53,0.6-0.73,0.76-0.89l-0.1,0.13
				c0.57-0.65-0.02,0.06,1.81-2.43l-0.1,0.11c0.08-0.49,0.38,0,1.66-2.35l-0.03,0.1c0.83-1.33,0.75-0.95,2.43-3.72
				c-0.1,0.24-0.16,0.35,0.32-0.55c-0.08,0.03-0.08,0.08-0.21,0.2c0.02-0.11,0.12-0.32,0.22-0.5c-0.15,0.34,0.08,0.04,0.19-0.08
				c-0.03,0.01-0.02-0.04-0.04-0.04c0.31-0.32-0.06-0.12,0.29-0.59c0.01,0.08,0.04,0.2,0.67-1.16c-0.06,0.14-0.13,0.31-0.2,0.45
				c0.22-0.25,0.22-0.29,0.35-0.5l-0.01,0.02l0.3-0.48l-0.09,0.12c0.04-0.45,0.37-0.76,0.53-1.31c-0.16,0.53,0.01,0.11,0.06,0.18
				c0.09-0.12,0.21-0.3,0.2-0.23c0.09-0.19-0.02-0.19,0.48-0.92c-0.64,1.04,0.98-2.31,1.77-4.34c0,0.27,0.06,0.27,0.46-1.11
				c0.02,0.03,0.04,0.07,0.05,0.09c-0.17-0.13,0.08,0.33-0.07,0.32c0.14,0.09,0.17,0.41,0.31,0.59c-0.01-0.01-0.04-0.02-0.04-0.04
				c0.03,0.12,0.01,0.16,0.01,0.24c-0.05-0.13,0.53,0.41,0.06,0.26c0.07-0.04,0.18,0.21,0.22,0.14c-0.01,0.13,0.01,0.1-0.04,0.19
				c0.08-0.13,0.34,0.54,0.08,0.35c0.41,0.28-0.06,0.04,0.74,1.05c0.07-0.05,0.11-0.14,0.19,0.07c-0.39,0.58-0.01,0,0.79,1.29
				l-0.12,0.01c0.62,0.42,0.8,1.26,2.86,2.56l-0.06-0.01c0.16,0.13,0.28-0.01,0.41,0.15l-0.04-0.01c0.11,0.13,0.44,0.05,2.29,1.15
				c0.01-0.03,0.13-0.01,0.36,0.09c-0.05-0.24,0.01,0.19,0.77,0.09c-0.01,0.03-0.09,0.04-0.14,0.05c0.24-0.06,0.04,0.09,0.26,0.03
				c-0.03-0.02,0.02-0.04,0.02-0.06c0.1,0.05,0.35,0.05,0.53,0.01l-0.03,0.06c0.34-0.02,0.69-0.16,1.04-0.36
				c0.39-0.36,0.02,0.01,0.96-1.59c0.24,0.22,0.5,0.43,0.75,0.41l-0.02,0c1.06,0.03-0.06,0.34,2.54,0.07
				c-0.01-0.07-0.04-0.07,0.14-0.05c0.04-0.4,0.73,0.15,2.27-1.5l0,0.13c0.3-0.49,0.66-1,0.69-1.58c0.01,0.04,0.04,0.04,0.02,0.1
				c0.04-0.78,0.06-0.12-0.39-2.01c0.11-0.05-0.12-0.36-0.24-0.43c0.05,0.01-0.04-0.11,0.03-0.1c-0.1-0.12-0.2-0.2-0.23-0.14
				c-0.26-0.39-0.14-0.38-1.49-1.14c0.44,0.06,0.67,0.23-1.24-0.74c0.01-0.01,0.04,0,0.07,0c-0.18-0.03,0.03,0-0.4,0.05
				c0.16-0.09-0.27-0.17-0.04-0.26c-0.04-0.01-0.1-0.01-0.1,0.03c-0.05-0.04-0.06-0.06,0.01-0.07c-0.22-0.07-0.16,0.01-0.35-0.06
				c0.01-0.02,0.05-0.02,0.08-0.04c-0.11,0.01-0.23,0.02-0.38-0.07c0.02-0.01,0-0.04,0.05-0.02c-0.28-0.09-0.51-0.07-0.8-0.2
				c0.07-0.04,0.1-0.11-0.25-0.19l0.05,0.06c-0.14-0.05-0.29-0.02-0.41-0.09l0.12,0l-0.28-0.07l0.14,0.08
				c-0.47,0.03-0.01,0.09-1.09-0.24c0.13-0.06-0.14-0.19-0.28-0.08c0.02,0,0.01-0.01,0.06-0.01c-0.27-0.05-0.37-0.09-0.45-0.14
				l0.07,0.01c-0.32-0.1,0.03-0.01-1.18-0.21l0.06,0.02c-0.07,0.12-0.22-0.11-0.39-0.08c-0.02-0.15,0.05-0.03-0.73-0.12l0.05-0.03
				c-0.59-0.07-0.46-0.14-1.71-0.34c0.1-0.02,0.16-0.01-0.25-0.05c0.01,0.06,0.03,0.04,0.09,0.1c-0.05,0.03-0.14,0.03-0.22,0.02
				c0.15-0.01,0.02-0.09-0.04-0.13c0.01,0.02-0.02,0.03-0.02,0.05c-0.34-0.12-0.04,0.08-0.77-0.13c0.06-0.01,0.14,0.01,0.19,0
				c-0.11-0.1-0.13-0.09-0.22-0.12l0.01,0l-0.21-0.08l0.05,0.03c-0.2,0.13-0.33-0.05-0.57,0.01c0.17-0.04,0.11-0.06,0.02-0.16
				c0.27,0.05,0.45-0.06,0.55,0.02l-0.03,0.01c0.83,0.12,0.81-0.14,2.41,0.02c-0.01,0.1-0.09-0.04,0.52-0.04
				c-0.02,0.03,0.5,0.04,0.75,0.06l-0.01,0.02c0.04-0.09,0.3-0.09,0.48-0.11c-0.01,0.06-0.04,0.08-0.14,0.11
				c0.02-0.02,0.02,0.04,0.38-0.07c-0.17,0.25,0.12-0.06,0.88-0.14l-0.12,0.06c0.05-0.03,0.41-0.02,0.11,0.02
				c0.56,0.01,0.23,0.01,1.34-0.12l0,0c0.84-0.19,0.16,0.1,2.47-0.21c-0.17,0.06,0.07,0.03-0.18,0.05c0.23,0,0.29,0.03,0.53-0.07
				c-0.08,0.07,0.18,0.06,0.29,0.02l-0.17-0.02c0.12-0.02,0.23-0.03,0.34-0.04l-0.05,0.09c0.49-0.03,1.05-0.13,2.14-0.39
				c-0.25-0.16,0.59,0.1,0.45-0.05c0.28-0.01,0.43-0.08,0.72-0.11c-0.04-0.04,0.76-0.4,0.63-0.08c0.24-0.11,0.49-0.08,0.64-0.1
				l-0.13,0.06c0.08-0.03,0.14-0.05,0.21-0.07l-0.09-0.04c0.08-0.01,0.15-0.03,0.15,0c0.13-0.15-0.05-0.19,0.43-0.23
				c-0.33,0.06,0.03,0.07-0.2,0.15c0.33-0.05,0.2,0.04,0.6-0.24c-0.03,0.06,0.1,0.06,0.15,0.03c-0.11,0.03-0.01-0.12,0.35-0.25
				l-0.13,0.03c0.16-0.21,0.58-0.11,1.46-0.45c-0.1-0.02-0.1,0,0.05-0.14l-0.4,0.23l0.23-0.2c-0.13,0.06-0.25,0.15-0.33,0.18
				c0.11-0.1,0.1-0.08,0.02-0.1c0.62-0.26,0.45,0.14,1.47-0.63l-0.13,0.05c0.28-0.31,0.39-0.05,1.39-1.15
				c-0.04,0.11,0.1,0.03-0.04,0.21c0.28-0.45-0.08,0.13,1.37-2.57c0,0.01,0.01,0.01,0.01,0.01
				C747.77,250.92,747.93,251.61,747.81,250.49z M732.23,250.89c0.02-0.06,0-0.06,0.1-0.1
				C732.31,250.82,732.32,250.78,732.23,250.89z M732.86,250.97c0.02-0.02,0.04-0.04,0.09-0.08
				C732.91,250.93,732.95,250.9,732.86,250.97z M733.17,250.65c-0.01,0.03,0.09-0.03,0.15-0.07
				C733.35,250.54,733.14,250.81,733.17,250.65z M649.47,320.91c0.05-0.13,0.02-0.08,0.18-0.2
				C649.61,320.77,649.64,320.7,649.47,320.91z M650.39,320.65c0.03-0.03,0.08-0.08,0.15-0.16
				C650.48,320.56,650.54,320.49,650.39,320.65z M650.93,320.03c-0.02,0.04,0.11-0.07,0.22-0.15
				C651.08,319.95,650.89,320.18,650.93,320.03z M701.76,288.92c0.02-0.09-0.12,0.32-0.6,0.57
				C701.37,289.3,701.56,289.11,701.76,288.92z M701.88,289c-0.2,0.16-0.34,0.25-0.46,0.33c0.19-0.16,0.37-0.38,0.49-0.47
				C701.75,289.07,701.76,289.04,701.88,289z M725.29,266.07c0.01,0.01,0.03,0.03,0.07,0.06
				C725.33,266.11,725.36,266.13,725.29,266.07z M725.4,265.52c0.05,0,0.06-0.02,0.08,0.06
				C725.46,265.57,725.49,265.57,725.4,265.52z M725.57,266.26c-0.03,0,0.03,0.07,0.05,0.12
				C725.66,266.39,725.44,266.26,725.57,266.26z M725.1,257.52c0.04-0.03,0.1-0.03,0.22,0.05
				C725.24,257.55,725.32,257.57,725.1,257.52z M725.57,257.63c0.12-0.02,0.24-0.04,0.33-0.03
				C725.81,257.66,725.85,257.71,725.57,257.63z M725.86,257.68c0.04-0.08,0.14,0.1,0.15-0.04
				C726.05,257.69,725.98,257.7,725.86,257.68z M726.43,257.81c-0.01-0.02-0.02-0.03-0.04-0.05l0.07-0.01
				C726.44,257.77,726.47,257.8,726.43,257.81z"
                />
                <path
                  className="st10"
                  d="M723.67,255.32l0.01,0.06l-0.06-0.01c0.02,0.06,0.06,0.02,0.09,0c0,0.03,0,0.05,0.01,0.07l0.1-0.09
				c-0.05,0.25,0.11-0.09,0.06,0.11c0.05-0.03,0.1,0.01,0.13-0.04c0.02,0.07,0.13,0.01,0.1,0.12c0.02-0.02,0.08-0.07,0.12-0.07
				c-0.09,0.11,0.12,0.04,0.08,0.17c0.07-0.11,0.18-0.01,0.27-0.06c-0.01,0.01-0.02,0.02-0.03,0.02c0.04,0.02,0.05,0.06,0.07,0.09
				c0.03-0.09,0.1-0.1,0.15-0.16c0.04,0.1-0.06,0.11-0.06,0.2c0.01-0.08,0.11-0.06,0.11-0.13c0.03,0.07,0.03,0.03,0.04,0.12
				c0.03-0.12,0.04-0.01,0.09-0.08c0.01,0.07,0.14,0.04,0.03,0.17c0.06-0.19,0.23,0.14,0.59-0.2c-0.02,0-0.02,0.02-0.04,0.04
				l0.04-0.02c-0.2,0.58,0,0,1.07,0.2c-0.29-0.04,0.34,0.17,0.93,0.08l-0.02,0.02c0.08,0.03,0.09-0.15,0.16-0.08l-0.01,0.01
				c0.13,0.03,0.06,0.16,1.04-0.02l0.04,0.09c0.09-0.15-0.02,0.12,0.33-0.07c0,0.03-0.03,0.04-0.05,0.06
				c0.06-0.13,0.05,0.11,0.1-0.06c0.04,0.04,0.13,0.03,0.19,0.01l-0.01,0.05c0.12,0,0.24-0.05,0.37-0.06
				c-0.05-0.02-0.05-0.11-0.01-0.13l0.02,0.08c0.01-0.05,0.02-0.18,0.02,0.02l0.06-0.04c0.01,0.02-0.01,0.04-0.01,0.07
				c0.02-0.05,0.11,0.07,0.14-0.02c0.09,0.03,0.43-0.1,0.66-0.25c0.02,0.06,0.11,0.04,0.07,0.09c0.11-0.03,0.14-0.03,0.27-0.11
				c-0.04-0.03-0.03-0.1,0.02,0c0.14-0.13,0.4,0.11,0.5-0.04l-0.01,0.01c0.18-0.13,0.13-0.03,0.93-0.15
				c-0.01-0.03-0.04-0.1,0.05-0.02c-0.32-0.2,0.58,0,1.49-0.54c-0.01,0.02,0,0.05-0.02,0.05c0.06,0.04,0.15-0.05,0.13-0.09
				c-0.07,0,0.27-0.01,0.53-0.24c0.03,0.11,0.16,0.04,0.18-0.03c0,0.04,0.05,0.02,0.05,0.07c0.05-0.02,0.09-0.06,0.06-0.11
				c0.14,0.09,0.15-0.17,0.26-0.03c0.13-0.11,0.25-0.21,0.37-0.29c-0.03,0.25-0.27,0.25,0.52-0.19c0,0.01,0,0.03-0.01,0.04
				c0.02-0.01,0.06-0.01,0.03-0.05l-0.02,0.02c-0.06-0.05,0.02-0.18,0.01-0.22c0.02,0.15,0.12-0.01,0.14,0.18
				c0.01-0.02,0.02-0.05,0-0.08c0.23-0.01,0,0.01,0.62-0.26l-0.04-0.03c0.05-0.02,0.07-0.11,0.13-0.1l-0.02,0.05l0.09-0.06l-0.07,0
				c0.07-0.11-0.09-0.13,0.25-0.07c-0.01-0.08,0.11-0.08,0.11-0.17c0,0.02,0.02,0.07,0.03,0.07l-0.01-0.07
				c0.08,0.02,0.05-0.04,0.26-0.07l-0.03,0.03l0.05,0.02l-0.01-0.05c0.04,0.01,0.25-0.15,0.71-0.44c-0.06,0.05,0.19,0.07,0.17-0.11
				c0.15,0,0.22,0.15,0.48-0.37c-0.03,0.06,0.05,0.08,0.08,0.1c-0.01-0.01-0.01-0.03-0.03-0.05c0.11,0.1-0.01-0.1,0.07-0.06
				c0,0.01,0.1-0.17,0.07,0c0.12-0.1-0.01,0.01,0.25-0.05c-0.11-0.18-0.01-0.01,0.08-0.24c0,0.12-0.02,0.05,0.13,0.16
				c0-0.07,0.08,0.01,0.09-0.1c0.02,0.14-0.07,0.09,0.5-0.43c0.05,0.14,0.08-0.16,0.1,0.07c0.03-0.04,0.04-0.11,0.07-0.15
				c0.03,0.03,0.01,0.07,0.01,0.1c-0.01-0.01,0.1-0.08,0.03-0.17c0.03,0.19-0.04-0.17-0.19,0.15l-0.03-0.1l-0.01,0.11
				c-0.03-0.02-0.07-0.06-0.05-0.09c-0.05-0.02-0.04-0.04-0.11,0.06c0-0.02-0.05-0.06-0.01-0.06c-0.08,0.02-0.22,0.03-0.14,0.27
				l-0.15-0.13l0.03,0.07c-0.01,0-0.03,0.01-0.05-0.02c-0.01,0.07,0.02,0.02,0.03,0.08c-0.09-0.03-0.12,0.11-0.19,0.01
				c0.01,0.06,0.07,0.01,0.04,0.08c-0.06-0.15-0.09,0.14-0.16-0.05c0.01,0.16-0.18-0.06-0.24,0.22c-0.01-0.04,0,0.03-0.23,0.02
				c0.03,0.09-0.04,0.11-0.07,0.15c-0.03-0.05-0.03-0.07-0.02-0.11c0,0.01-0.04-0.01-0.07,0.1l-0.02-0.07
				c-0.05,0.04-0.07,0.14-0.17,0.14c0.03,0.16,0.18,0.2-0.13,0.03c0.01,0.03,0.03,0.15-0.08,0.03c0,0.04-0.05,0.03-0.02,0.09
				c-0.02,0.01-0.04-0.02-0.05-0.03c-0.01,0.05,0,0-0.06,0.14l0,0c-0.02,0.13-0.3,0.05-0.63,0.3c0.02-0.06-0.03-0.03,0.03-0.05
				c-0.07,0.01-0.1-0.02-0.13,0.07c-0.01-0.07-0.08-0.05-0.1-0.02l0.06,0.02c-0.2-0.11-0.44,0.42-0.68,0.27l0.01,0.03
				c-0.04-0.01-0.11,0.03-0.12-0.03c-0.03,0.05,0,0.05,0.04,0.05c-0.09,0-0.11,0.05-0.2,0.06c0.05,0.02-0.14,0.32-0.18,0.03
				c-0.04,0.08-0.14,0.02-0.18,0.03l0.02-0.04c-0.01,0.02-0.03,0.03-0.05,0.04l0.04,0.04c-0.02,0-0.04,0.01-0.05-0.01
				c0,0.05,0.06,0.08,0.04,0.15c-0.04-0.01-0.07,0.07-0.11,0c0.13-0.03-0.1-0.28-0.12,0.02c-0.01-0.05-0.05-0.07-0.06-0.05
				c0.01-0.02,0.07,0.07-0.05,0.16c0.04,0.01,0.07,0-0.02,0.08c-0.02-0.03,0.03-0.04,0.01-0.05c-0.01,0.18-0.39-0.21-0.37,0.11
				l0.1-0.09l-0.04,0.11c0.04-0.01,0.06-0.07,0.08-0.07c-0.02,0.06-0.02,0.04,0.02,0.08c-0.23,0.15-0.07-0.25-0.45,0.06
				c0.04,0.03,0.09,0.02-0.05,0.08c-0.02-0.05-0.04-0.05-0.07-0.08c0.02,0.03-0.08,0.03-0.05,0.12c-0.11-0.03-0.09-0.1-0.21,0.15
				c0.01-0.06-0.05-0.08,0-0.14c-0.07,0.07,0.02-0.02-0.17,0.26c-0.11-0.08-0.14-0.07-0.55,0.11c0-0.02-0.01-0.02,0-0.02
				c-0.18,0.13,0-0.02-0.31,0.05l0.01-0.01c-0.06-0.04-0.09,0.1-0.17,0.11c0,0,0-0.01-0.01-0.02c-0.03,0.1-0.12,0.15-0.18,0.2
				c-0.02-0.14,0-0.1-0.14-0.07c0.15-0.35-0.27,0.39-0.35,0.1c-0.03,0.03-0.1,0.13-0.16,0.17c0.02-0.08,0.09-0.14,0.12-0.19
				c-0.04,0.01-0.12,0.04-0.13,0.09c0.05-0.02,0.06-0.02-0.1,0.13c-0.04-0.19-0.2,0.01-0.3-0.12c-0.11,0.1-0.26,0.04-0.41,0.09
				c0.07,0.02-0.06,0.24-0.06,0.06c0,0.03-0.03,0.05-0.02,0.08c-0.06-0.02-0.13-0.08-0.19-0.19c0.02,0.05-0.01,0.08-0.06,0.07
				l0.08,0.05c-0.09,0.1-0.12-0.1-0.21,0l0.02-0.05c-0.05,0.04-0.21,0.04-0.24,0.17c-0.01-0.02-0.03-0.05-0.01-0.07
				c-0.09-0.05-0.17,0.05-0.56,0.16l0.02-0.05c-0.56,0.13-0.15,0.06-1.32,0.14c-0.1,0.13-0.08,0.01-0.81,0.16
				c-0.04-0.1,0.02-0.08-0.26-0.07l0.02-0.05c-0.18,0.16-0.02,0.14-0.5,0.12c0-0.02,0.01-0.05,0.02-0.05
				c-0.05,0-0.21-0.07-0.19,0.07c0-0.09-0.12-0.01-0.18,0.03l0.01-0.07c0,0.02-0.05,0.06-0.24,0.12l0.03-0.04
				c-0.09-0.01-0.06,0.18-0.21-0.02c-0.01,0.01,0.02,0.01-0.29-0.02c0.08,0.01,0.14,0.02,0.14,0.02c0.07-0.1-0.25-0.21-0.41-0.02
				c-0.01-0.01,0.02,0,0.04-0.02l0,0.01c0.02,0,0.05,0,0.08,0c-0.03,0.03-0.06,0.06-0.09,0.02c-0.02,0.04-0.04,0.09-0.05,0.11
				c-0.09,0.01-0.35-0.18-1.15-0.23c0.01,0.01,0.02,0.02,0.02,0.04c-0.17-0.04-0.1-0.16-0.84-0.14l0-0.08
				c-0.16,0.21-0.34-0.27-0.86-0.07c0-0.01,0-0.01,0.01-0.02l-0.02,0.04c0,0,0,0,0,0C723.55,255.12,723.55,255.21,723.67,255.32z
				 M726.74,255.73c-0.01,0.02,0.02,0.03,0.04,0.05C726.79,255.78,726.69,255.81,726.74,255.73z M726.52,255.32
				c0-0.01,0.01-0.03,0.01-0.03C726.56,255.28,726.57,255.3,726.52,255.32z M726.65,255.78c-0.01,0,0,0-0.03,0
				C726.62,255.78,726.63,255.78,726.65,255.78z"
                />
                <path
                  className="st10"
                  d="M735.37,232.91c0.08-0.09-0.28-0.38-0.51-0.45l0.01-0.01c-0.53,1-1.13-1.81-4.86,3.77l-0.06-0.04
				c-0.17,0.31-0.26,0.47-0.42,0.88l-0.01-0.1c-0.14,0.81-0.07,0.36-0.57,1.82c-0.01-0.01-0.04-0.03-0.02-0.1
				c-0.07,0.19-0.31,0.72-0.15,0.69c-0.09-0.02-0.14,0.42-0.17,0.65l-0.06-0.05c0.02,0.02-0.02,0.29-0.13,0.87l-0.01-0.11
				c-0.1,0.34,0.11,0.25-0.23,0.73c0.02-0.1,0.01,0.32-0.04,0.22c-0.2,0.33,0.08,0.01-0.28,0.79c0.09-0.27,0.17-0.5,0.17-0.47
				c-0.06-0.02-0.14,0.2-0.17,0c-0.04,0.19,0.05,0.01-0.14,0.76l0-0.02c-0.09,0.3-0.09,0.16-0.28,0.66c0.07,0.02,0,0.15,0.11,0.04
				c-0.02,0.03,0.02-0.05,0.03-0.15l0,0.01c0.02-0.07,0.06-0.17,0.09-0.27c-0.11,0.79-0.28,0.5-0.79,2.06
				c0.01-0.06,0.04-0.12,0.04-0.16c-0.03,0-0.06,0.55-0.32,0.79l-0.01-0.05c-0.43,0.56-0.28,1.12-0.82,1.7
				c0.04-0.04,0.05-0.06,0.07-0.06c-0.32,0.22-0.94,1.92-2.48,3.73l0.08-0.02c-0.78,0.84,0.22-0.13-0.62,1.4
				c0.29-0.14,0.49-0.91,0.65-0.76c0.01-0.11,0.27-0.12,0.69-1.03c-0.03,0.26,0.28-0.37,0.36-0.24c0-0.21,0.31-0.53,0.41-0.81
				c0,0.02,0,0.06-0.02,0.08c0.18,0.13,0.24-0.94,0.45-0.53c-0.07-0.03,0.12-0.34,0.03-0.32c0.13-0.1,0.1-0.1,0.21-0.13
				c-0.17,0.04,0.35-0.8,0.3-0.38c0.08-0.67,0.06,0.03,0.7-1.62c-0.12-0.03-0.16-0.01-0.01-0.25c0.48,0,0.11,0.16,0.96-1.87
				l0.05,0.12c0.23-1.05,0.8-1.82,1.64-5.03l0,0.07c0.11-0.26-0.05-0.35,0.1-0.57l0,0.05c0.07-0.23,0.71-1.83,1.34-3.18
				c0.21,0.06-0.02,0.07,0.85-1.22c0.02,0.03-0.03,0.11-0.06,0.18c0.27-0.44,0.04-0.08,0.63-0.78l0.01,0.07
				c1.44-1.02,0.27-1.47,3.76,0.45c-0.03-0.1,0.12,0.12,0,0.6c0.02-0.03,0.04-0.05,0.06-0.07c-0.05,0.31-0.02,0.07-0.18,0.7
				c0.83,0.4,0.94-1.63,2.85,0.97l-0.01-0.04c-0.13,0.18-0.22,0.01,0.17,0.47c0.02-0.02,0.03-0.04,0.05-0.06
				c0.02,0.04,0.02,0.07,0.04,0.11c0.01-0.08,0.02-0.17,0.25-0.28l-0.04,0.14c0.47-0.52,1.16-0.83,1.73-0.95
				c-0.44,0.6,0.48-0.95,1.13,1.45c-0.1-0.02-0.12,0.41-0.12,0.58c-0.02-0.06-0.07,0.12-0.1,0.05c-0.04,0.17-0.08,0.33-0.01,0.32
				c-0.52,0.79-0.25,0.62-1.21,2.15c0.02-0.09,0.04-0.16,0.06-0.21c-0.21,0.28-0.24,0.36-1.29,1.73c0.01,0,0.06-0.02-0.1,0.4
				c-0.01-0.2-0.32,0.29-0.3,0c-0.03,0.05-0.06,0.12-0.03,0.13c-0.06,0.06-0.1,0.06-0.07-0.03c-0.18,0.25-0.07,0.2-0.26,0.41
				c-0.02-0.02,0.01-0.07,0.01-0.11c-0.06,0.14-0.12,0.28-0.29,0.43c0-0.02-0.03-0.01,0.01-0.06c-0.27,0.29-0.39,0.58-0.73,0.86
				c0.01-0.09-0.03-0.15-0.36,0.22l0.1-0.03c-0.15,0.14-0.24,0.31-0.39,0.41l0.09-0.13l-0.27,0.28l0.19-0.12
				c-0.12,0.18,0.1,0.07-0.12,0.24c0.04-0.05-0.15,0.14-0.27,0.17l0,0c-0.29,0.36-0.19-0.05-0.73,0.65c0-0.16-0.3,0.11-0.32,0.25
				c0.02-0.02,0-0.02,0.04-0.06c-0.29,0.24-0.42,0.32-0.53,0.38l0.07-0.07c-0.38,0.27,0.03-0.03-1.31,1.02l0.07-0.04
				c-0.35,0.37-0.35,0.04-1.3,0.89l0.03-0.06c-0.7,0.48-0.58,0.32-2.11,1.21c0.11-0.1,0.17-0.13-0.31,0.16c0.06,0.03,0.07,0,0.17,0
				c-0.03,0.06-0.14,0.14-0.24,0.19c0.16-0.13-0.04-0.08-0.14-0.07c0.02,0.01,0,0.04,0.02,0.05c-0.26,0.02,0.02,0.13-0.3,0.21
				c0-0.06,0.01-0.13-0.67,0.3c0.08-0.05,0.15-0.1,0.23-0.15c-0.2,0.01-0.21,0.03-0.33,0.08l0.01,0l-0.29,0.1l0.08-0.01
				c-0.13,0.26-0.41,0.22-0.65,0.44c0.23-0.21,0.02-0.08,0.01-0.15c-0.08,0.01-0.2,0.03-0.18,0c-0.11,0.11-0.24,0.05-0.4,0.21
				c-0.02-0.02-0.14,0.06-0.14-0.01c0.18,0.04-0.31,0.23-0.81,0.43c0.01-0.02,0.05-0.07-0.05,0.02c0-0.09,0.12-0.39,0.31-0.29
				l-0.01-0.03c0.04,0.01,0.1-0.05,0.12,0c0.02-0.05-0.02-0.05-0.05-0.04c0.09-0.01,0.09-0.07,0.17-0.1
				c-0.05-0.02,0.05-0.33,0.16-0.07c0.02-0.09,0.12-0.06,0.16-0.08l-0.01,0.04c0.01-0.03,0.02-0.04,0.04-0.05l-0.05-0.03
				c0.02-0.01,0.04-0.02,0.05,0c-0.01-0.04-0.08-0.06-0.08-0.13c0.04,0,0.04-0.08,0.1-0.03c-0.11,0.06,0.18,0.24,0.1-0.05
				c0.02,0.04,0.07,0.05,0.08,0.03c0,0.03-0.09-0.04,0-0.17c-0.04,0-0.06,0.02-0.01-0.08c0.03,0.02-0.01,0.04,0.01,0.04
				c-0.04-0.16,0.44,0.08,0.3-0.22l-0.06,0.11l0-0.12c-0.03,0.02-0.03,0.08-0.05,0.09c-0.01-0.06,0-0.05-0.05-0.07
				c0.15-0.21,0.16,0.22,0.38-0.21c-0.05-0.01-0.09,0.01,0.01-0.09c0.03,0.04,0.06,0.04,0.09,0.05c-0.03-0.03,0.06-0.05,0-0.13
				c0.11-0.01,0.12,0.06,0.13-0.21c0.02,0.06,0.07,0.05,0.06,0.12c0.03-0.09-0.01,0.02,0.04-0.29c0.13,0.03,0.15,0.01,0.42-0.32
				c0.01,0.01,0.02,0.02,0.01,0.02c0.09-0.19,0.01,0.02,0.24-0.17l0,0.02c0.07,0,0.03-0.12,0.09-0.17c0,0,0.01,0.01,0.02,0.02
				c-0.02-0.09,0.02-0.19,0.05-0.26c0.09,0.11,0.05,0.09,0.16-0.01c0.07,0.37,0.02-0.46,0.23-0.27c0.01-0.04,0.01-0.16,0.04-0.22
				c0.03,0.08,0.01,0.16,0.01,0.22c0.02-0.03,0.08-0.1,0.05-0.14c-0.03,0.04-0.04,0.05,0.01-0.16c0.14,0.13,0.16-0.11,0.31-0.06
				c0.03-0.15,0.18-0.18,0.27-0.31c-0.07,0.02-0.1-0.22,0.01-0.08c-0.01-0.02-0.01-0.06-0.03-0.07c0.06-0.02,0.14-0.02,0.26,0.03
				c-0.05-0.03-0.05-0.07,0-0.09l-0.09,0.01c0-0.13,0.16,0.01,0.16-0.13l0.02,0.06c0.01-0.06,0.13-0.16,0.06-0.28
				c0.02,0,0.06,0.02,0.06,0.04c0.39-0.43,0.13-0.38,1.05-1.49c-0.04-0.14,0.06-0.07,0.33-0.73c0.1,0.02,0.06,0.06,0.2-0.17
				l0.03,0.05c-0.05-0.23-0.09-0.07,0.15-0.48c0.01,0.01,0.04,0.03,0.03,0.04c0.03-0.04,0.16-0.15,0.02-0.2
				c0.08,0.04,0.06-0.1,0.05-0.17l0.06,0.04c-0.02-0.01-0.03-0.07-0.01-0.26l0.03,0.04c0.05-0.07-0.13-0.13,0.1-0.17
				c-0.01-0.02,0,0.01,0.13-0.25c-0.03,0.07-0.06,0.12-0.07,0.11c0.07,0.11,0.29-0.16,0.16-0.36c0.01-0.01,0,0.01,0,0.04l0,0
				c-0.01,0.02-0.02,0.04-0.03,0.07c-0.02-0.04-0.04-0.07,0.01-0.09c-0.03-0.03-0.07-0.07-0.08-0.09c0.01-0.09,0.32-0.25,0.54-0.98
				c-0.02,0.01-0.02,0.01-0.04,0.01c0.06-0.14,0.2-0.08,0.28-0.76l0.08,0.01c-0.18-0.16,0.28-0.31,0.15-0.81c0,0,0,0,0.01,0
				c-0.08-0.08-0.01,0.01-0.37,0.03l0.02-0.06c-0.06,0.01-0.02,0.05,0,0.08c-0.03,0-0.05,0-0.07,0.01l0.08,0.1
				c-0.03,0-0.21-0.04,0.01,0.04c-0.06,0.01-0.1-0.01-0.07,0.04c-0.03,0-0.05-0.02-0.05-0.04c0.02,0.04-0.02,0.09,0.04,0.13
				c-0.07,0.01-0.02,0.11-0.13,0.07c0.02,0.02,0.06,0.09,0.05,0.12c-0.1-0.1-0.05,0.11-0.17,0.04c0.1,0.08,0,0.16,0.03,0.26
				c-0.01-0.01-0.02-0.02-0.01-0.03c-0.02,0.04-0.06,0.04-0.09,0.05c0.09,0.04,0.08,0.11,0.13,0.17c-0.11,0.01-0.09-0.07-0.19-0.09
				c0.07,0.03,0.04,0.11,0.11,0.12c-0.07,0.02-0.04,0.02-0.12,0.01c0.11,0.05,0.01,0.04,0.06,0.1c-0.06-0.01-0.06,0.11-0.17-0.01
				c0.18,0.11-0.14,0.13,0.08,0.58c0.01-0.02-0.01-0.03-0.03-0.04l0.01,0.04c-0.06-0.18-0.18,0.05-0.09-0.02
				c-0.24-0.28-0.24,0.39-0.41,0.9c0.11-0.33-0.15-0.04-0.93,1.69l-0.09-0.02c0.08,0.15-0.08-0.09-0.11,0.29
				c-0.02-0.02-0.02-0.05-0.03-0.07c0.08,0.12-0.12-0.03,0,0.11c-0.06,0.01-0.1,0.07-0.12,0.13l-0.04-0.04
				c-0.07,0.08-0.1,0.2-0.17,0.3c0.04-0.02,0.12,0.04,0.1,0.07l-0.08-0.03c0.03,0.04,0.13,0.12-0.03,0l-0.01,0.06
				c-0.02-0.01-0.03-0.03-0.05-0.05c0.03,0.05-0.12,0.03-0.06,0.11c-0.08,0.05-0.18,0.36-0.23,0.6c-0.05-0.03-0.1,0.04-0.11-0.02
				c-0.05,0.09-0.07,0.11-0.1,0.25c0.05,0,0.09,0.05-0.02,0.02c-0.01,0.18-0.35,0.17-0.31,0.34l0-0.01
				c-0.05,0.2-0.07,0.11-0.58,0.63c0.02,0.02,0.09,0.06-0.02,0.04c0.36-0.01-0.42,0.31-0.89,1.14c-0.01-0.02-0.02-0.04-0.01-0.05
				c-0.07-0.01-0.1,0.1-0.07,0.14c0.06-0.03-0.23,0.12-0.33,0.42c-0.08-0.08-0.15,0.02-0.14,0.09c-0.01-0.03-0.05,0-0.07-0.04
				c-0.03,0.04-0.05,0.08-0.01,0.12c-0.16-0.03-0.06,0.21-0.21,0.12c-0.12,0.2,0.01,0.14-0.61,0.7c-0.01-0.01-0.01-0.02,0-0.04
				c-0.01,0.02-0.05,0.02-0.01,0.06l0.01-0.02c0.08,0.03,0.04,0.17,0.06,0.21c-0.07-0.13-0.11,0.05-0.19-0.13
				c0,0.02,0,0.05,0.02,0.07c-0.2,0.06,0-0.01-0.49,0.39l0.05,0.02c-0.04,0.03-0.04,0.11-0.09,0.12l0.01-0.05l-0.07,0.08l0.07-0.01
				c-0.04,0.12,0.12,0.1-0.22,0.11c0.03,0.08-0.08,0.1-0.06,0.18c0-0.02-0.03-0.06-0.04-0.07l0.02,0.06
				c-0.08-0.01-0.04,0.05-0.23,0.11l0.02-0.03l-0.05-0.01l0.02,0.04c-0.05-0.08-0.09,0.22-0.38,0.34c0.01-0.02-0.03-0.07-0.02-0.06
				c-0.08,0.03-0.11,0.12-0.18,0.22c0.05-0.05-0.2-0.05-0.14,0.12c-0.16,0-0.03-0.03-0.36,0.12c0.06-0.12,0.14-0.2-0.71,0.21
				l0.12-0.01c-0.02-0.04,0.01-0.16,0.24-0.09l-0.11,0.05c0.1,0.07,0.26-0.01,0.42-0.1c-0.01,0.02-0.01,0.1-0.02,0.21
				c0.02-0.06-0.05-0.08-0.09-0.09c0.01,0.01,0.02,0.03,0.03,0.05c-0.12-0.1,0.02,0.1-0.07,0.06c0-0.01-0.09,0.17-0.07,0
				c-0.1,0.1,0.01-0.01-0.24,0.06c0.12,0.18,0.01,0.01-0.06,0.24c0-0.12,0.02-0.05-0.13-0.16c0,0.07-0.08-0.01-0.07,0.1
				c-0.02-0.13,0.07-0.09-0.47,0.4c-0.05-0.14-0.08,0.15-0.1-0.07c-0.02,0.04-0.04,0.1-0.06,0.14c-0.03-0.03-0.01-0.07-0.01-0.1
				c0.01,0.01-0.09,0.07-0.03,0.17c-0.03-0.19,0.03,0.17,0.18-0.13l0.03,0.1l0.01-0.11c0.03,0.02,0.06,0.06,0.04,0.09
				c0.05,0.02,0.04,0.04,0.11-0.06c0,0.02,0.04,0.06,0.01,0.06c0.11-0.02,0.21-0.05,0.13-0.26l0.15,0.13l-0.03-0.07
				c0.01,0,0.03-0.01,0.05,0.02c0.01-0.07-0.02-0.02-0.03-0.08c0.08,0.03,0.11-0.11,0.18-0.01c-0.01-0.06-0.07-0.01-0.04-0.08
				c0.06,0.15,0.08-0.14,0.16,0.05c-0.02-0.16,0.17,0.06,0.22-0.22c0.01,0.04,0-0.03,0.22-0.03c-0.04-0.08,0.03-0.11,0.06-0.15
				c0.03,0.05,0.03,0.07,0.03,0.1c0-0.01,0.04,0,0.05-0.1l0.03,0.06c0.05-0.04,0.06-0.13,0.15-0.14c-0.03-0.06-0.01,0-0.11-0.19
				c0.06-0.02-0.01,0.12,0.23,0.15c-0.01-0.1,0.01,0.01,0.23-0.25c0.07-0.01,0,0.01,0.19-0.09c-0.03,0.03,0.05,0.06-0.05,0.08
				c0.31-0.03,0.44-0.23,0.58-0.19l-0.02,0.01c0.45-0.18,1.15-0.3,2.78-1.16c0,0.04,0.49-0.17,0.73-0.25l0,0.02
				c0-0.1,0.24-0.21,0.4-0.31c0.02,0.05,0,0.09-0.08,0.16c0.01-0.03,0.04,0.03,0.32-0.23c-0.06,0.3,0.1-0.1,0.76-0.52l-0.08,0.12
				c0.13-0.05,0.04-0.08,0.14-0.13c0.02,0.06,0.19-0.01-0.03,0.1c0.52-0.25,0.22-0.1,1.18-0.73l0,0c0.15-0.23,0.97-0.47,2.13-1.4
				c-0.12,0.15,0.08,0-0.13,0.14c0.21-0.12,0.27-0.13,0.43-0.34c-0.03,0.1,0.19-0.05,0.27-0.13l-0.16,0.07
				c0.09-0.09,0.19-0.15,0.28-0.21l0,0.1c0.42-0.28,0.87-0.67,1.67-1.5c-0.3,0,0.56-0.24,0.36-0.29c0.24-0.17,0.34-0.3,0.56-0.49
				c-0.05-0.02,0.43-0.76,0.49-0.42c0.15-0.23,0.39-0.33,0.49-0.44l-0.08,0.12c0.05-0.07,0.09-0.13,0.14-0.18l-0.1,0.02
				c0.06-0.06,0.11-0.11,0.13-0.09c0.03-0.2-0.14-0.13,0.25-0.43c-0.33,0.32,0.09,0.35,0.3-0.29c0,0.06,0.12,0,0.15-0.05
				c-0.08,0.08-0.06-0.1,0.17-0.41l-0.1,0.09c0.03-0.27,0.45-0.4,1.07-1.12c-0.1,0.03-0.09,0.05-0.02-0.15l-0.25,0.4l0.11-0.29
				c-0.08,0.11-0.15,0.26-0.21,0.32c0.05-0.14,0.05-0.12-0.03-0.1c0.43-0.53,0.44-0.11,1.06-1.24l-0.1,0.1
				c0.14-0.4,0.35-0.21,0.82-1.66c0.01,0.12,0.1-0.02,0.05,0.21c0.09-1.06,0.04,0-0.38-2.93c0,0,0,0,0.01,0
				c-0.67-0.25,0.06-0.27-1.14-0.35l0.06-0.02c-0.24,0.09-0.23-0.17-1.45,0.8c-0.01-0.02-0.01-0.03-0.01-0.05
				c0,0.02-0.02,0.05-0.05,0.08c0.01,0,0.04-0.12,0.05-0.1c0.12-0.21,0.29-0.12,0.06-0.98c0.1,0.13,0.17,0.45,0.17,0.66
				c0.04-0.16,0-0.54-0.06-0.6c0.02,0.06,0.05,0.13,0.04,0.2c-0.71-1.36,0.07-0.81-2.57-1.53c0.28,0.01-0.16,0.1-0.04,0.21
				c-0.17,0.15-0.28,0.2-0.4,0.26c-0.01-0.11-0.03-0.21-0.03-0.31l0.05,0.03c-0.08-0.16,0.03-0.09-0.23-0.68l0.08,0.1
				C736.05,233.36,735.73,233.16,735.37,232.91z M727.54,243.2c-0.02-0.06-0.03-0.06,0.03-0.14
				C727.57,243.09,727.55,243.06,727.54,243.2z M728.12,242.92c0.01-0.02,0.02-0.06,0.04-0.12
				C728.14,242.85,728.16,242.8,728.12,242.92z M728.21,242.47c0.01,0.03,0.06-0.08,0.1-0.14
				C728.3,242.28,728.27,242.61,728.21,242.47z M732.98,243.17c-0.01,0.02-0.03,0.03-0.03-0.02
				C732.96,243.15,732.97,243.16,732.98,243.17z M732.49,243.05c0,0.01,0,0.01-0.01,0.03C732.48,243.07,732.48,243.09,732.49,243.05
				z M732.49,243.19c-0.01-0.01-0.04,0.01-0.06,0.02C732.43,243.21,732.44,243.11,732.49,243.19z M726.16,251.42
				c0.02,0.01,0.02,0.02,0.04,0.03c-0.03,0,0,0.03-0.11,0.02C726.11,251.45,726.14,251.44,726.16,251.42z M726.08,251.5
				c-0.01,0.01-0.03,0.01-0.04,0.02c0-0.01,0.01-0.01,0.01-0.03C726.06,251.5,726.07,251.5,726.08,251.5z M725.97,251.53
				c0,0.02,0,0.02,0,0.02c-0.03,0.01-0.06,0.02-0.09,0.04C725.9,251.55,725.91,251.53,725.97,251.53z M725.85,251.6
				c-0.04,0.02-0.08,0.03-0.11,0.05C725.75,251.61,725.78,251.58,725.85,251.6z M724.82,252c0.04-0.09-0.01-0.01,0.19-0.12
				C724.97,251.96,724.94,252.04,724.82,252z M725.02,251.99C725.02,251.99,725.02,251.98,725.02,251.99
				C725.02,251.98,725.02,251.98,725.02,251.99z"
                />
                <path
                  className="st10"
                  d="M722.05,250.29c0.02-0.04,0.03-0.07,0.04-0.15c0.03,0.03-0.01-0.03,0.19-0.13
				c-0.07-0.06-0.02-0.11-0.01-0.17c0.05,0.03,0.06,0.05,0.07,0.08c0-0.01,0.04-0.01,0-0.12l0.05,0.04
				c0.03-0.06-0.01-0.16,0.07-0.21c-0.12-0.11-0.26-0.07,0.09-0.1c-0.03-0.02-0.11-0.11,0.05-0.07c-0.02-0.03,0.03-0.05-0.04-0.09
				c0.01-0.02,0.04-0.01,0.06,0c-0.02-0.05,0,0-0.04-0.15l0,0c-0.06-0.12,0.22-0.22,0.31-0.63c0.02,0.06,0.04,0,0.01,0.06
				c0.04-0.05,0.09-0.05,0.05-0.14c0.05,0.05,0.09-0.02,0.09-0.05l-0.06,0.03c0.23-0.05,0.03-0.61,0.31-0.67l-0.03-0.02
				c0.04-0.02,0.06-0.1,0.1-0.07c-0.01-0.05-0.04-0.03-0.07-0.01c0.07-0.06,0.04-0.12,0.09-0.19c-0.05,0.02-0.14-0.32,0.1-0.15
				c-0.03-0.09,0.08-0.12,0.1-0.16l0.01,0.04c-0.01-0.03,0-0.05,0-0.06l-0.06,0c0.02-0.02,0.02-0.04,0.05-0.03
				c-0.03-0.03-0.1-0.01-0.14-0.07c0.04-0.02-0.01-0.1,0.07-0.09c-0.06,0.12,0.28,0.11,0.06-0.1c0.04,0.02,0.09,0,0.08-0.02
				c0.01,0.03-0.1,0.01-0.09-0.15c-0.04,0.02-0.04,0.05-0.05-0.07c0.03,0.01,0.01,0.04,0.03,0.03c-0.13-0.12,0.41-0.18,0.14-0.36
				l0.01,0.13l-0.06-0.1c-0.01,0.04,0.02,0.09,0,0.11c-0.04-0.05-0.02-0.04-0.08-0.04c0.02-0.27,0.25,0.1,0.22-0.4
				c-0.05,0.02-0.07,0.06-0.04-0.09c0.05,0.02,0.07,0,0.1-0.01c-0.04-0.01,0.02-0.08-0.07-0.12c0.13,0.05-0.11-0.33,0.12-0.19
				c0.01-0.03,0.02-0.06-0.01-0.06c0.14-0.54-0.02-0.06,0.7-1.08c0.08,0.07-0.09,0.05,0.27-0.45c0.02,0.03,0.32-0.39,0.47-0.59
				l0.01,0.01c-0.05-0.09,0.1-0.3,0.18-0.47c0.04,0.03,0.04,0.07,0.01,0.17c0-0.03,0.05,0.01,0.15-0.36
				c0.12,0.28,0.02-0.14,0.35-0.83l-0.01,0.14c0.01-0.06,0.2-0.36,0.08-0.08c0.3-0.47,0.13-0.19,0.58-1.23l0,0
				c-0.01-0.27,0.55-0.91,0.97-2.3c-0.02,0.18,0.06-0.05-0.03,0.18c0.1-0.21,0.15-0.25,0.17-0.51c0.03,0.1,0.13-0.14,0.15-0.25
				l-0.09,0.14c0.03-0.12,0.06-0.22,0.1-0.33l0.06,0.08c0.17-0.46,0.34-1.03,0.5-2.14c-0.24,0.17,0.32-0.51,0.13-0.44
				c0.11-0.27,0.08-0.43,0.16-0.72c-0.05,0.02-0.1-0.85,0.15-0.62c-0.02-0.27,0.1-0.49,0.13-0.64l0.01,0.14c0-0.09,0-0.15,0.01-0.22
				l-0.07,0.07c0.02-0.08,0.03-0.15,0.05-0.15c-0.09-0.18-0.19-0.02-0.06-0.48c-0.07,0.45,0.29,0.22,0.05-0.41
				c0.04,0.05,0.09-0.08,0.08-0.13c-0.01,0.11-0.11-0.04-0.12-0.42l-0.02,0.13c-0.14-0.23,0.1-0.58,0.09-1.53
				c-0.05,0.09-0.04,0.1-0.11-0.09l0.08,0.46l-0.11-0.29c0.01,0.14,0.06,0.29,0.05,0.37c-0.06-0.14-0.04-0.12-0.09-0.05
				c-0.04-0.67,0.28-0.38-0.09-1.6l0,0.14c-0.19-0.36,0.08-0.38-0.6-1.71c0.09,0.07,0.06-0.09,0.19,0.12
				c-0.64-0.62,0.06-0.14-2.57,0.09c0-0.01,0-0.01,0-0.02c-0.4,0.56-0.08-0.06-0.72,0.84l0.02-0.06c-0.07,0.05-0.17,0.26-0.32,0.98
				c-0.04-0.1,0.23-0.97-1.99-1.5c0.23,0.14-0.19,0.01-0.13,0.16c-0.39,0.1-0.44,0-0.93,0.03c0.07,0.02-0.06,0.13-0.23,0.22
				c0.08-0.03,0.17-0.06,0.26-0.09c-0.32,0.31-0.41,0.12-0.67,0.51c0.02-0.05,0.04-0.09,0.06-0.14c-0.37,0.06,0,0.01-1.34,0.3
				c1.24,0.87-3.66-2.05-2.08,4.46l-0.07,0.02c0.11,0.33,0.17,0.5,0.36,0.88l-0.08-0.06c0.5,0.63,0.22,0.28,0.92,1.61
				c-0.02,0-0.05,0-0.08-0.05c0.09,0.18,0.28,0.7,0.38,0.57c-0.08,0.05,0.19,0.38,0.32,0.56h-0.08c0.03,0,0.19,0.22,0.48,0.7
				l-0.08-0.07c0.13,0.3,0.24,0.1,0.3,0.69c-0.05-0.09,0.22,0.23,0.11,0.19c0.06,0.37,0.07-0.05,0.29,0.77
				c-0.1-0.26-0.19-0.47-0.17-0.46c-0.06,0.03,0.02,0.24-0.13,0.11c0.09,0.16,0.04-0.03,0.37,0.66l-0.01-0.02
				c0.12,0.28,0.03,0.17,0.2,0.67c0.06-0.03,0.1,0.11,0.11-0.04c0.01,0.03-0.02-0.05-0.07-0.13l0.01,0.01
				c-0.03-0.07-0.06-0.16-0.1-0.26c0.4,0.66,0.11,0.55,0.66,2.05c-0.13-0.24-0.09-0.24,0.15,0.66l-0.04-0.04
				c-0.02,0.68,0.43,1.04,0.3,1.81c0.01-0.05,0.01-0.08,0.03-0.09c-0.19,0.27,0.08,0.01-0.28,3.62l0.02-0.01
				c-0.05,0.23-0.11,0.46-0.19,0.69l0.06-0.04c-0.45,1.03,0.18-0.19-0.15,1.5c0.24-0.21,0.19-1.01,0.39-0.92
				c-0.04-0.1,0.23-0.21,0.26-1.2c0.08,0.25,0.1-0.45,0.23-0.37c0.01-0.02-0.09-0.12,0.04-0.34c0.03,0.09,0.07,0.15,0.47-0.34
				c-0.01,0.04,0.07,0.03-0.01,0.09c0.24-0.19,0.26-0.41,0.39-0.45l-0.01,0.02c0.03-0.02,0.04-0.03,0.06-0.05
				c-0.02,0.1-0.06,0.19-0.04,0.29c-0.01,0-0.02-0.02-0.02-0.03c0,0.02-0.03,0.05,0.02,0.06l-0.01-0.03
				c0.08-0.02,0.12,0.13,0.16,0.15c-0.13-0.08-0.07,0.1-0.23-0.01c0.01,0.02,0.02,0.04,0.06,0.05c-0.02,0.02-0.04,0.02-0.07-0.01
				c0,0.09,0.06,0.08,0.04,0.15c-0.02-0.01-0.03-0.02-0.05-0.04c0.03,0.09-0.03,0.03-0.14,0.53l0.05-0.01
				c-0.02,0.05,0.03,0.12-0.01,0.16l-0.02-0.05l-0.02,0.11l0.05-0.05c0.03,0.12,0.16,0.02-0.13,0.22c0.06,0.05-0.02,0.13,0.04,0.19
				c-0.01-0.02-0.06-0.03-0.07-0.03l0.05,0.04c-0.07,0.04-0.01,0.06-0.14,0.22l0-0.04l-0.05,0.02l0.04,0.03
				c-0.04,0.02-0.07,0.27-0.26,0.78c0.02-0.07-0.19,0.06-0.07,0.18c-0.13,0.09-0.25-0.01-0.19,0.57c-0.01-0.07-0.08-0.04-0.12-0.04
				c0.02,0.01,0.03,0.02,0.05,0.03c-0.15-0.03,0.06,0.08-0.03,0.09c0-0.01,0,0.2-0.06,0.03c-0.05,0.14,0.01-0.01-0.19,0.16
				c0.18,0.11,0.01,0,0.04,0.25c-0.05-0.11-0.01-0.05-0.18-0.09c0.03,0.07-0.08,0.03-0.03,0.12c-0.01-0.01-0.02,0-0.04,0
				c0.03,0.02-0.02,0.15-0.26,0.57c-0.09-0.12-0.03,0.17-0.12-0.03c-0.01,0.05,0,0.11-0.02,0.16c-0.04-0.02-0.03-0.06-0.04-0.09
				c0.02,0.01-0.07,0.1,0.02,0.17c-0.08-0.17,0.09,0.16,0.14-0.19l0.06,0.09l-0.02-0.11c0.03,0.01,0.08,0.04,0.07,0.07
				c0.06,0,0.06,0.02,0.09-0.1c0.01,0.02,0.06,0.04,0.03,0.05c0.07-0.04,0.2-0.11,0.03-0.3l0.19,0.06l-0.06-0.05
				c0.01-0.01,0.03-0.02,0.05,0c-0.02-0.07-0.03-0.01-0.06-0.06c0.09-0.01,0.07-0.15,0.17-0.09c-0.04-0.05-0.07,0.02-0.07-0.05
				c0.11,0.11,0.02-0.17,0.17-0.02c-0.06-0.1,0.01-0.01,0.07-0.15C722.04,250.3,722.18,250.4,722.05,250.29z M723.81,245.6
				c-0.01-0.01-0.01-0.01-0.01-0.03c0.01,0.01,0.02,0.01,0.03,0.01L723.81,245.6z M724.73,230.05
				C724.67,230.01,724.7,230,724.73,230.05L724.73,230.05z M720.79,240c-0.06-0.03-0.06-0.02-0.06-0.13
				C720.75,239.9,720.72,239.88,720.79,240z M720.85,239.03c0.02,0.02,0-0.09-0.01-0.17C720.8,238.82,720.99,239.1,720.85,239.03z
				 M721.02,239.31c0.02,0.04,0,0,0.05,0.11C721.06,239.4,721.04,239.37,721.02,239.31z M722.65,246.68
				c-0.15,0.05,0.01,0.14-0.44,0.4c0.02-0.18,0.05-0.38,0.03-0.54c0.01,0.02,0.02,0.05,0.01,0.08c0.22,0.03-0.24-0.93,0.15-0.67
				c-0.08,0.01-0.07-0.36-0.13-0.29c0.06-0.15,0.03-0.13,0.12-0.21c-0.12,0.12-0.11-0.86,0.06-0.48c-0.31-0.61,0.1-0.01-0.31-1.72
				c-0.12,0.04-0.14,0.09-0.16-0.19c0.4-0.29,0.18,0.06-0.36-2.03l0.12,0.06c-0.5-0.93-0.44-1.92-2.02-4.79l0.05,0.05
				c-0.1-0.26-0.28-0.21-0.33-0.47l0.03,0.04c-0.11-0.21-0.81-1.74-1.4-3.08l0.07-0.03c-0.03,0.01-0.1-0.13-0.15-0.44
				c-0.1,0.11,0.08-0.15-0.24-0.94c0.03,0.01,0.05,0.09,0.09,0.15c-0.12-0.47-0.05-0.07-0.07-0.92l0.05,0.04
				c0.04-0.36,0.1-0.77,0.33-1.04c-0.02,0.14-0.19-0.16,0-0.06c0.59-1.68,2.03,2.98,3.3-0.13l-0.01,0.02
				c0.02,1.2,0.93-3.38,3.66,1.65c0.01-0.02-0.01,0,0.17,0.01c-0.01-0.31-0.13-0.24-0.09-0.88c0.03,0.09,0.05,0.18,0.07,0.26
				c-0.04-0.81-0.18-0.3,0.41-1.83c0.17,0.32-0.52-0.03,1.7-1.43c0.05,0.1,0.23,0.06,0.34,0.13c-0.05,0,0.04,0.09-0.03,0.09
				c0.08,0.09,0.16,0.22,0.2,0.17c0.07,0.6,0.2,0.4,0.68,2.3c-0.07-0.11-0.11-0.18-0.13-0.23c0.02,0.28,0.14,0.4,0.19,2.14
				c-0.01,0-0.02-0.05-0.03-0.09c0.05,0.23-0.01-0.03,0.2,0.46c-0.14-0.15-0.06,0.42-0.23,0.18c0.01,0.05,0.02,0.12,0.06,0.11
				c-0.02,0.08-0.04,0.1-0.07,0.02c0.01,0.3,0.07,0.2,0.04,0.47c-0.02,0-0.03-0.06-0.06-0.09c0.04,0.14,0.07,0.28,0.02,0.51
				c-0.01-0.02-0.04,0.01-0.03-0.06c-0.04,0.39,0.04,0.68-0.08,1.1c-0.04-0.08-0.12-0.1-0.17,0.38l0.06-0.08
				c-0.03,0.2,0,0.39-0.07,0.55l-0.01-0.16l-0.06,0.38l0.08-0.2c0,0.21,0.12,0,0.04,0.26c0-0.06-0.04,0.2-0.12,0.29l0,0
				c-0.03,0.46-0.18,0.06-0.21,0.93c-0.09-0.13-0.18,0.26-0.12,0.37c0-0.03-0.01-0.02,0-0.07c-0.1,0.35-0.16,0.49-0.21,0.59
				l0.02-0.09c-0.16,0.42,0.01-0.04-0.49,1.54l0.04-0.07c-0.08,0.49-0.27,0.22-0.59,1.43l-0.01-0.06c-0.32,0.76-0.3,0.57-1.09,2.11
				c0.02-0.1,0.03-0.13-0.17,0.3c0.07,0,0.06-0.03,0.15-0.09c0,0.07-0.04,0.19-0.1,0.28c0.07-0.19-0.08-0.05-0.15,0.01
				c0.02,0,0.02,0.03,0.04,0.04c-0.21,0.14,0.08,0.09-0.14,0.33c-0.04-0.05-0.05-0.11-0.4,0.6c0.04-0.08,0.07-0.15,0.11-0.24
				c-0.16,0.1-0.16,0.13-0.24,0.23l0.01-0.01l-0.2,0.23l0.06-0.05c0.02,0.29-0.23,0.39-0.32,0.7c0.09-0.3-0.02-0.08-0.07-0.14
				c-0.03,0.03-0.07,0.06-0.1,0.08c-0.01-0.13,0.03,0.02,0.11-0.26l0.01,0.02c0.06-0.04-0.04-0.13-0.01-0.21c0,0,0.01,0.01,0.02,0
				c-0.07-0.07-0.08-0.18-0.09-0.26c0.14,0.04,0.08,0.05,0.13-0.1c0.25,0.29-0.22-0.41,0.06-0.37c-0.02-0.04-0.07-0.15-0.08-0.22
				c0.07,0.05,0.09,0.14,0.13,0.19c0.01-0.04,0.01-0.13-0.03-0.16c-0.01,0.05-0.01,0.07-0.07-0.15c0.19,0.04,0.08-0.19,0.23-0.23
				c-0.05-0.14,0.06-0.26,0.07-0.42c-0.05,0.06-0.2-0.14-0.03-0.08c-0.02-0.01-0.04-0.04-0.06-0.05c0.04-0.05,0.12-0.1,0.24-0.12
				c-0.06,0.01-0.08-0.04-0.05-0.08l-0.08,0.06c-0.07-0.12,0.14-0.08,0.07-0.21l0.04,0.04c-0.02-0.06,0.02-0.22-0.1-0.28
				c0.02-0.01,0.06-0.02,0.07,0.01c0.07-0.08,0.01-0.18-0.01-0.6l0.04,0.03c-0.01-0.59-0.01-0.17,0.09-1.36
				c-0.12-0.11,0.01-0.09-0.09-0.84c0.1-0.04,0.08,0.02,0.08-0.26l0.05,0.02c-0.16-0.18-0.13-0.02-0.12-0.52
				c0.02,0,0.05,0,0.05,0.02c0-0.06,0.06-0.22-0.08-0.19c0.09-0.01,0-0.12-0.04-0.18l0.07,0.01c-0.02,0-0.06-0.05-0.14-0.23
				l0.05,0.02c0-0.09-0.18-0.04,0-0.21c-0.01-0.01,0.01,0.01-0.02-0.29c0.01,0.08,0.01,0.15,0,0.14c0.12,0.06,0.17-0.29-0.04-0.42
				c0.01-0.01,0,0.01,0.02,0.04l-0.01,0c0,0.02,0.01,0.05,0.01,0.08c-0.04-0.02-0.07-0.05-0.03-0.09c-0.05-0.01-0.09-0.03-0.12-0.03
				c-0.04-0.08,0.14-0.4-0.04-1.19c-0.01,0.02-0.01,0.02-0.03,0.03c-0.01-0.17,0.13-0.16-0.15-0.84l0.07-0.03
				c-0.25-0.06,0.1-0.44-0.3-0.82c0.01,0,0.01,0,0.02,0c-0.13-0.01-0.02-0.01-0.31,0.22l-0.01-0.06c-0.05,0.04,0,0.06,0.04,0.07
				c-0.03,0.01-0.05,0.03-0.06,0.04l0.12,0.05c-0.24,0.06,0.13,0.06-0.08,0.1c0.04,0.03,0.03,0.09,0.1,0.1
				c-0.06,0.05,0.04,0.12-0.07,0.13c0.03,0.01,0.1,0.05,0.11,0.08c-0.14-0.04,0.02,0.12-0.13,0.13c0.12,0.02,0.08,0.15,0.15,0.22
				c-0.01,0-0.03-0.01-0.03-0.02c0,0.05-0.04,0.06-0.05,0.1c0.1-0.01,0.13,0.06,0.2,0.08c-0.08,0.07-0.12-0.02-0.21,0.02
				c0.08-0.01,0.09,0.08,0.16,0.05c-0.05,0.05-0.02,0.04-0.1,0.07c0.12-0.01,0.03,0.04,0.11,0.06c-0.06,0.03,0,0.14-0.15,0.08
				c0.18,0-0.06,0.26,0.36,0.49c0-0.02-0.02-0.02-0.04-0.02l0.03,0.03c-0.61-0.04,0,0,0.02,1.07c0.01-0.29-0.12,0.35,0.01,0.91
				l-0.02-0.02c-0.02,0.08,0.16,0.08,0.09,0.15l-0.01-0.01c-0.03,0.12-0.15,0.06,0.01,1.01l-0.09,0.03
				c0.14,0.09-0.12-0.03,0.05,0.32c-0.03,0-0.04-0.03-0.06-0.05c0.13,0.07-0.11,0.03,0.05,0.1c-0.05,0.04-0.05,0.12-0.03,0.18
				l-0.05-0.01c-0.02,0.11,0.02,0.24,0.01,0.37c0.03-0.04,0.12-0.03,0.13,0.01l-0.08,0.01c0.05,0.02,0.18,0.04-0.03,0.01l0.03,0.06
				c-0.02,0-0.04-0.01-0.07-0.02c0.05,0.03-0.08,0.1,0,0.13c-0.04,0.09,0.03,0.42,0.11,0.67c-0.06,0-0.06,0.09-0.11,0.04
				c0,0.11,0,0.14,0.05,0.28c0.04-0.03,0.1,0-0.01,0.02c0.08,0.16-0.22,0.34-0.1,0.48l-0.01-0.01c0.06,0.2,0.01,0.13-0.17,0.89
				c0.03,0.01,0.11,0,0,0.05c0.31-0.2-0.2,0.51-0.17,1.53c-0.02-0.01-0.04-0.02-0.04-0.04c-0.07,0.03-0.03,0.15,0.02,0.16
				c0.03-0.06-0.13,0.23-0.07,0.56c-0.11-0.03-0.12,0.1-0.07,0.16c-0.03-0.02-0.04,0.03-0.09,0c-0.01,0.05,0,0.1,0.06,0.11
				C723.08,246.09,723.43,245.67,722.65,246.68z M724.53,237.16c0.01,0,0.03,0,0.04,0.01C724.57,237.19,724.55,237.2,724.53,237.16z
				 M724.15,237.44c-0.02-0.01-0.03,0.03-0.05,0.05C724.12,237.49,724.07,237.4,724.15,237.44z M724.09,237.36
				c-0.01-0.01,0,0-0.01-0.03C724.08,237.33,724.09,237.34,724.09,237.36z M723.23,246.26c-0.04-0.06-0.13-0.09,0.01-0.15
				C723.25,246.16,723.25,246.22,723.23,246.26z M723.27,246.09c0.01,0,0.03-0.01,0.04-0.01c0,0.03,0,0.06,0,0.09
				C723.27,246.14,723.27,246.12,723.27,246.09z"
                />
                <path
                  className="st10"
                  d="M667.97,266.24c-0.06-0.1-0.33,0.06-0.43,0.17l-0.01-0.01c-0.22,0.5-0.24-0.02-0.51,2.19
				c0.02-0.02,0.14,0.05,0.11,0.25c-0.01-0.05-0.16,0.32,0.14,0.37c-0.06,0.3-0.08,0.23,0.45,1.29l-0.07,0.03
				c0.1,0.21,0.14,0.33,0.31,0.56l-0.07-0.02c0.43,0.33,0.17,0.14,0.81,0.95c-0.01,0.01-0.04,0.02-0.08-0.01
				c0.08,0.1,0.28,0.44,0.35,0.3c-0.07,0.06,0.18,0.22,0.32,0.3l-0.07,0.03c0.02-0.01,0.17,0.09,0.49,0.31l-0.08-0.01
				c0.16,0.16,0.21-0.04,0.36,0.35c-0.06-0.04,0.23,0.06,0.13,0.08c0.12,0.23,0.05-0.06,0.39,0.38c-0.14-0.12-0.25-0.23-0.24-0.22
				c-0.04,0.05,0.07,0.16-0.08,0.14c0.11,0.07,0.02-0.04,0.45,0.25l-0.02,0c0.17,0.12,0.07,0.11,0.34,0.33
				c0.04-0.06,0.11,0.01,0.07-0.09c0.01,0.02-0.03-0.02-0.09-0.05l0.01,0c-0.04-0.03-0.09-0.07-0.15-0.11
				c0.07-0.04,0.05,0.15,0.33,0.09c0.06,0.33,0.09,0.29,0.82,0.77c-0.18-0.05-0.16-0.07,0.36,0.27l-0.04,0
				c0.27,0.39,0.72,0.25,0.99,0.7c-0.02-0.03-0.03-0.04-0.03-0.06c0.12,0.17,0.81,0.49,2.1,1.93l0.01-0.06
				c0.42,0.65-0.02-0.18,0.91,0.49c-0.04-0.1-0.02-0.08-0.33-0.3c0.19-0.12-0.27-0.09-0.06-0.22c-0.09-0.04-0.17-0.17-0.24-0.16
				c-0.01-0.11-0.21-0.2-0.13-0.27c-0.04,0-0.17-0.05-0.23-0.11c0.2,0.01-0.21-0.22-0.08-0.29c-0.16,0.01-0.32-0.23-0.51-0.31
				c0.01,0,0.04,0,0.05,0.02c-0.06-0.07-0.07-0.11-0.1-0.16c0.04,0,0.07,0.01,0.04-0.03c0.02-0.01,0.04-0.01,0.05-0.01
				c-0.02,0,0,0-0.1,0.01c0,0,0,0,0,0c-0.09,0.02,0.12,0.09-0.16-0.17c-0.05,0.06-0.23-0.09-0.24-0.01
				c-0.04-0.11-0.05-0.08-0.04-0.17c-0.02,0.15-0.5-0.27-0.2-0.24c-0.34,0.09-0.1-0.27-1.18-0.54c0.17-0.68,0,0.01-1.01-0.89
				l0.1-0.04c-0.6-0.29-0.98-0.72-2.51-2.17l0.05,0.02c-0.09-0.15-0.25-0.05-0.3-0.22l0.03,0.02c0.22,0.08-0.69-0.78-1.44-2.73
				c0.03,0,0.06,0.05,0.09,0.09c-0.13-0.15,0.07-0.05-0.04-0.19c-0.01,0.03-0.05,0-0.06,0c0.03-0.09-0.02-0.28-0.06-0.41l0.06,0.02
				c-0.02-0.24-0.07-0.51-0.05-0.78c0.09-0.51,0.01,0,0.83-1.46c0.03,0.06,0.2,0,0.12,0.07c0.91-0.11,0.91-0.06,3.11,1.3
				c-0.02-0.04-0.08-0.09,0.05-0.05c-0.1-0.51-0.16-1.39-0.14-2.63l0.06,0.09c0.01-0.43,0.31-0.77,0.54-1.08
				c-0.03,0.3,0-0.34,1.27-0.35c-0.04,0.1,0.21,0.16,0.32,0.16c-0.04,0.02,0.06,0.07,0,0.1c0.1,0.04,0.19,0.08,0.2,0.02
				c0.21,0.34,0.22,0.11,1.09,1.04c-0.41-0.1-0.32-0.51,0.52,1.11c-0.01,0-0.03-0.02-0.05-0.04c0.1,0.12-0.02-0.02,0.31,0.19
				c-0.17-0.03,0.07,0.27-0.16,0.19c0.02,0.03,0.06,0.06,0.09,0.04c0.01,0.05,0,0.07-0.06,0.04c0.09,0.17,0.12,0.09,0.17,0.26
				c-0.02,0.01-0.04-0.02-0.08-0.03c0.07,0.07,0.14,0.14,0.16,0.29c-0.02,0-0.03,0.02-0.04-0.03c0.08,0.24,0.24,0.39,0.25,0.68
				c-0.12-0.06-0.02,0.1-0.11,0.13l0.06,0.14l0.03-0.07c0.03,0.13,0.11,0.23,0.1,0.35l-0.05-0.09l0.05,0.24l0.02-0.14
				c0.2,0.36,0.09-0.02,0.16,0.98c-0.08-0.09-0.15,0.17,0,0.26c-0.01-0.02-0.02-0.01-0.03-0.04c0.02,0.24,0.01,0.34-0.01,0.41
				l-0.01-0.06c-0.02,0.29-0.01-0.03,0.05,1.06l0.01-0.05c0.13,0.04-0.07,0.21-0.01,0.36c-0.13,0.04-0.05-0.05,0,0.66l-0.03-0.04
				c0.02,0.53-0.07,0.43-0.09,1.56c-0.03-0.09-0.03-0.14-0.01,0.23c0.06-0.02,0.04-0.04,0.09-0.09c0.03,0.04,0.05,0.12,0.05,0.19
				c-0.03-0.13-0.09,0-0.13,0.05c0.02-0.01,0.04,0.01,0.05,0.01c-0.07,0.31,0.08,0.02,0,0.71c-0.02-0.05-0.01-0.12-0.03-0.17
				c-0.08,0.11-0.06,0.13-0.08,0.21l0-0.01l-0.04,0.2l0.02-0.05c0.21,0.98-0.01,0.06,0.08,2.58c-0.07-0.05-0.2-0.1,0.04,0.48
				l0.02-0.08c-0.03,0.01-0.16-0.02-0.04-0.16l0.02,0.08c0.13-0.09-0.04-0.31-0.04-0.47c0.12,0.13,0.2,0.03,0.11-0.38
				c0.02,0.02,0.07-0.02,0.06,0.05c0.05-0.2-0.08-0.31-0.01-0.39l0.01,0.02c0.09-0.42,0.04-0.18,0.01-1.92
				c0.03,0.01,0.06-0.32,0.1-0.48l0.01,0.01c-0.09-0.04-0.06-0.2-0.08-0.32c0.05,0.01,0.08,0.03,0.1,0.1
				c-0.01-0.02,0.04-0.02-0.04-0.25l0.07,0.03c-0.08-0.07,0.18-0.23-0.13-0.62l0.05,0.09c-0.02-0.04,0.01-0.27,0.03-0.07
				c0.07-0.36,0.03-0.15,0.02-0.89l0,0c-0.1-0.17,0.14-0.67,0.07-1.63c0.05,0.12,0.04-0.04,0.03,0.12c0.02-0.15,0.06-0.19-0.01-0.35
				c0.06,0.05,0.08-0.12,0.06-0.19l-0.04,0.11c-0.01-0.08-0.01-0.15,0-0.23l0.08,0.04c0.02-0.34-0.01-0.72-0.18-1.44
				c-0.17,0.17,0.15-0.39-0.01-0.3c0.02-0.19-0.05-0.29-0.06-0.48c-0.03,0.05-0.35-0.5-0.03-0.43c-0.1-0.16-0.05-0.33-0.07-0.43
				l0.05,0.08c-0.03-0.05-0.04-0.09-0.05-0.14l-0.04,0.07c-0.01-0.06-0.02-0.1,0.01-0.11c-0.11-0.08-0.19,0.08-0.19-0.27
				c0.04,0.3,0.34,0.01-0.08-0.27c0.05,0.01,0.06-0.08,0.04-0.11c0.03,0.07-0.1,0.05-0.22-0.21l0.02,0.09
				c-0.18-0.1-0.08-0.38-0.32-0.97c-0.03,0.08-0.01,0.08-0.12-0.01l0.18,0.25l-0.17-0.13c0.04,0.08,0.12,0.15,0.14,0.21
				c-0.09-0.06-0.07-0.06-0.09,0c-0.22-0.42,0.19-0.34-0.47-0.97l0.04,0.09c-0.07,0-0.12-0.13-0.2-0.19
				c0.04-0.03,0.02-0.08,0.01-0.13c-0.02,0.03-0.15-0.16-0.24-0.1c-0.14-0.24-0.01-0.18-0.52-0.34c0.09-0.01,0.02-0.1,0.17-0.04
				c-0.71-0.2-0.01-0.02-1.96-0.24c0-0.01,0-0.01,0-0.02c-0.39,0.27-0.05-0.09-0.67,0.37l0.02-0.04c-0.1,0.13-0.19,0-0.46,0.99
				c-0.02-0.01-0.03-0.02-0.04-0.02c0.03,0.05,0.04,0.14-0.16,0.26l0.04-0.16c-0.11,0.05-0.06,0.2-0.09,0.28l-0.04-0.02
				c-0.04,0.25,0.06,0.27,0.03,0.53l0.03-0.02c0.07,0.36-0.14,0.14,0.05,0.79c-0.08-0.08-0.14-0.28-0.18-0.4
				c0,0.09,0.02,0.32,0.08,0.36c-0.01-0.04-0.04-0.08-0.03-0.12c0.08,0.1,0.14,0.27,0.16,0.4c-0.19-0.14,0.07,0.22-0.17,0.15
				c0.05,0.1,0.08,0.27-0.06,0.04c0.18,0.37-0.02-0.02,0.11,0.31c-0.06-0.09-0.06-0.03-0.11-0.26c0.01,0.05-0.02,0.06,0.05,0.18
				c-0.07-0.1-0.14-0.24-0.15-0.48c0,0.07-0.02,0.09-0.04,0.04c-0.02,0.15,0.05,0.06-0.21-0.13l0.1,0.02
				c-0.18-0.08-0.17-0.33-1.64-0.63l0.03-0.03c-0.21,0.04-0.1-0.07-0.5,0.05l0.08-0.05C668.33,265.82,668.19,266.07,667.97,266.24z
				 M674.64,276.29c0-0.01-0.01-0.01-0.01-0.02C674.67,276.27,674.69,276.27,674.64,276.29z M670.95,273.58
				c-0.01-0.01-0.03-0.02-0.06-0.05C670.91,273.55,670.88,273.53,670.95,273.58z M670.89,274.12c-0.01,0.01,0,0-0.04,0.02
				C670.81,274.1,670.77,274.03,670.89,274.12z M670.69,273.44c0.02,0-0.02-0.06-0.05-0.11
				C670.61,273.32,670.82,273.42,670.69,273.44z"
                />
                <path
                  className="st10"
                  d="M674.81,276.05c-0.04-0.03-0.08,0.01-0.17,0l0.18-0.05l-0.08,0.01c0,0,0.01-0.01,0.04-0.02
				c-0.05,0-0.03,0.01-0.08,0.01c0.06-0.03-0.04-0.04,0.08-0.07c-0.06,0.01-0.04,0.02-0.08,0.01c0.15-0.02-0.07-0.03,0.12-0.06
				c-0.12,0,0.01,0.01-0.03-0.04c0.07-0.03,0.08,0.03-0.05-0.04c0.04,0-0.01-0.02,0.08-0.08c-0.09,0.01-0.08-0.01-0.1-0.02
				c0.05-0.01,0.08-0.01,0.1-0.01c-0.02-0.02,0-0.01-0.06-0.02l0.07-0.01c-0.01-0.02-0.09-0.02-0.05-0.05
				c-0.06,0.01-0.17,0.05-0.22,0.04c0.06-0.02,0.02,0.01,0.25-0.08c-0.03,0-0.15,0.01,0.01-0.03c-0.03,0,0-0.02-0.07,0
				c0-0.01,0.03-0.01,0.05-0.02c-0.03,0,0-0.01-0.1-0.01c-0.1-0.03,0.1-0.22,0.09-0.51c-0.03-0.01-0.05,0-0.06,0.02
				c0.04-0.03-0.01-0.04,0.02-0.06c-0.05,0.01-0.01,0-0.12-0.01c0.04-0.02,0.11-0.02,0.07-0.03c0.03-0.02,0.08-0.02,0.1-0.02
				c-0.06,0.01,0.01-0.06,0.06-0.05c-0.02,0-0.02-0.01-0.02-0.01l-0.06,0.02c0.01-0.01,0.01-0.01,0.03-0.02
				c-0.04,0-0.1,0.02-0.15,0.02c0.03-0.01-0.04-0.02,0.04-0.04c-0.02,0.03,0.08-0.02,0.09,0c0.04-0.06,0.07-0.02-0.07-0.04
				c0.04-0.01,0.08-0.02,0.07-0.03c0,0.01-0.07,0.02-0.11,0.02c0.03-0.02-0.03-0.01-0.03-0.02c-0.03,0.02-0.02,0.02-0.07,0
				c0.03-0.01,0.03,0.01,0.04,0c-0.08,0,0.07-0.05,0.02-0.05c0.03-0.01,0.08-0.02,0.1-0.03c-0.06-0.02,0.06-0.06-0.1-0.04l0.05,0.03
				c-0.13,0-0.17,0.08-0.02-0.16c-0.04,0.01,0-0.03-0.1-0.01c0.21-0.04-0.21-0.05,0.06-0.07c0-0.01,0.01-0.02-0.03-0.01
				c0.01,0.01-0.02-0.05-0.16-0.02c0.15-0.06-0.03-0.07,0.1-0.12c-0.02-0.02-0.04-0.04-0.05-0.05c-0.07,0.04,0.14-0.12-0.11-0.19
				c0.06-0.01,0.03,0,0.1-0.01c-0.01-0.01-0.1,0.02-0.12,0.01c0.03,0,0.16-0.06,0.18-0.07c-0.02-0.04-0.27-0.03-0.07-0.08
				c-0.03-0.01-0.1-0.02-0.12-0.04c0.07,0,0.12,0.01,0.16,0.02c-0.19,0,0.19-0.12-0.13-0.29c0.03,0,0.1,0,0.13-0.03
				c-0.05,0.02-0.08,0-0.13,0.02c0-0.01,0.05-0.03,0.23-0.1c-0.05,0.01-0.08,0.01-0.06-0.01c0,0.03-0.08-0.04,0.02-0.04
				c-0.03-0.01-0.01-0.06-0.13-0.05c0.08,0.01,0.08-0.14-0.12-0.24c0.08-0.04,0.31-0.08,0.01-0.17c0.11-0.05,0.03-0.1,0.09-0.15
				c-0.02,0.01-0.08,0.02-0.1,0.01c0.02-0.01-0.08-0.01,0.02-0.03l0,0c0.1-0.03,0.07-0.04,0.07-0.06l-0.13,0.02
				c0.14-0.07,0.12-0.27-0.05-0.43c0.09-0.02,0-0.04-0.04-0.05l0.07-0.01c-0.09,0-0.11,0.01-0.14-0.01
				c0.01-0.01,0.05-0.02,0.04-0.01c0.04-0.09-0.14,0.03,0.01-0.09c-0.05,0.01-0.06-0.04-0.01-0.08c0,0.02,0,0.04,0,0.04
				c-0.02,0.02,0.18-0.17-0.03-0.12c0,0,0,0,0.02,0.01c0,0,0,0-0.01,0c0,0.01,0.01,0.01,0.01,0.02c-0.04,0-0.07,0-0.03-0.02
				c-0.14-0.01-0.12,0.07-0.11-0.17c0.04-0.03,0.09-0.01,0.07-0.03c0.18-0.07,0.03-0.01-0.05-0.14c-0.01,0.01-0.02,0.01-0.03,0.01
				c0.01-0.02,0.12-0.16-0.07-0.24l0.07-0.02c-0.16,0.04-0.03-0.09-0.09-0.09c0-0.01,0.01-0.04,0.03-0.06l-0.07-0.02
				c0.13-0.05,0.31-0.16-0.38,0.03l0.07,0.01c-0.01,0.02-0.15,0-0.03,0.02c-0.03,0.01-0.05,0.01-0.06,0.02l0.11,0
				c-0.04,0.01-0.09,0.01-0.11,0.03c0.05,0,0.1-0.03,0.13-0.02c-0.05,0.02-0.09,0.03-0.05,0.03c-0.03,0.01-0.05,0.01-0.06,0.01
				c0.04,0,0.02,0.02,0.08,0.02c-0.06,0.02,0.02,0.03-0.08,0.05c0.03,0,0.09,0,0.09,0.01c-0.13,0.01,0,0.04-0.14,0.06
				c0.12-0.01,0.06,0.04,0.13,0.05c-0.01,0-0.03,0-0.02,0c-0.01,0.01-0.04,0.03-0.06,0.04c0.1-0.02,0.12,0,0.18,0
				c-0.09,0.03-0.11,0.01-0.2,0.04c0.08-0.02,0.08,0.01,0.15-0.01c-0.05,0.02-0.03,0.02-0.1,0.04c0.12-0.02,0.02,0.01,0.1,0
				c-0.06,0.03-0.11,0.03-0.07,0.04c-0.17,0.04,0.03,0.01,0.13,0.1c-0.01,0-0.02,0.01-0.02,0.01c0,0.01,0.11-0.05,0.13-0.01
				c0-0.01-0.02,0-0.04,0l0.03,0c-0.08,0.03-0.03-0.01-0.09,0.01c0.02,0.01,0.13,0.06-0.02,0.04l0.03-0.03
				c-0.05,0.06-0.14,0.13,0.06,0.3c-0.03,0-0.05,0-0.08,0.02c0.05,0.03,0.01,0.09-0.03,0.14l0.07,0c-0.15,0.04,0.11-0.01-0.03,0.02
				c0.04,0.12,0.17,0.03,0.08,0.27c0.08-0.02,0.14,0.1,0.01,0.14l0.08,0l-0.09,0.03c0.04,0.02,0.17-0.05-0.01,0.05l0.07,0.03
				c-0.03,0.01-0.04,0-0.06,0c0.11,0-0.1,0.04,0.06,0.02c-0.04,0.02-0.04,0.04-0.02,0.06c-0.04,0.02-0.09-0.01-0.01,0.11
				c0.02-0.02,0.11-0.03,0.12-0.02L673.8,273c0.05,0,0.03-0.02,0.09-0.02c-0.01,0.02-0.09,0.03-0.12,0.02l0.03,0.01
				c-0.02,0.01-0.04,0-0.07,0.01c0.05,0-0.07,0.04,0.01,0.04c-0.04,0.01-0.01,0.1,0.14,0.08c0,0.02-0.12,0.13,0.05,0.08
				c-0.15,0.06,0.01,0-0.05,0.26l0.08,0.02c-0.01,0.02-0.05,0-0.09,0.02c0.11,0.02,0,0.07,0.07,0.09c-0.18,0.06,0.03,0.09-0.08,0.15
				c0,0,0.11-0.03,0.01,0.01c0.17-0.16-0.07,0.19,0.17,0.43c-0.02,0-0.04,0.01-0.04,0c-0.06,0.02,0.01,0.04,0.05,0.03
				c-0.05,0-0.07,0.05-0.07,0.05c0.03,0,0,0.04,0.07,0.02c-0.03,0.03,0.04,0.03,0,0.06c0.08,0-0.03,0.04,0.08,0.03
				c-0.11,0.02-0.08,0.05-0.03,0.06c-0.03,0-0.03,0.02-0.08,0.02c0,0.01,0.03,0.02,0.08,0.01c-0.13,0.05,0.12,0.03-0.05,0.08
				c0.07,0.03,0.12,0.07,0.15,0.1c-0.09,0.04-0.08-0.01,0,0.16c-0.01,0-0.02,0-0.03,0c0,0.01-0.01,0.02,0.04,0.01l-0.01,0
				c0.07-0.03,0.16,0,0.2-0.01c-0.15,0.02-0.03,0.04-0.22,0.06c0.01,0,0.04,0,0.07,0c-0.03,0.07,0.04,0.06-0.02,0.18
				c0.01-0.01,0,0.03,0.07,0c-0.01,0.03-0.04,0.06,0.1,0.07c0,0,0,0,0,0c0.03,0.02,0.08,0.02-0.08,0.04
				c0.08-0.01,0.03,0.03,0.11,0.03c-0.02,0-0.07,0.01-0.08,0.01l0.06-0.01c-0.15,0,0.11,0.1-0.1,0.11c0.02,0.03,0.13,0.05,0.11,0.09
				c0.15-0.04,0,0,0.01,0.04c-0.01,0-0.07,0.01-0.06,0.01c-0.02,0.03,0.04,0.04,0.09,0.07c-0.03,0.01-0.05-0.01-0.06,0.06
				c0.03,0,0.03,0.01,0.08-0.01c-0.12,0.07-0.13,0.17,0.12,0.15c-0.04-0.01-0.09,0.02-0.12,0.03c0.02,0,0.04-0.01,0.05-0.01
				c-0.15,0.04,0.11,0,0.02,0.02c0.02,0.01,0.07,0.03-0.03,0.03c0.04,0.04,0,0-0.07,0.09c0.22-0.04,0.01,0,0.17,0.02
				c-0.11,0.01,0-0.02-0.2,0.05c0.06,0-0.04,0.03,0.05,0.03c-0.02,0.01,0,0.01-0.06,0.02c-0.09-0.18,0.21,0.2,0.09,0.18
				c0.02,0.01,0.07,0.01,0.1,0.02c-0.04,0.01-0.06,0-0.09,0.01c0.21,0.05,0.01-0.03,0.2-0.07c-0.02-0.01-0.19,0.03,0.04-0.05
				l-0.03-0.01c0.02,0,0.07-0.02,0.06-0.01C674.87,276.06,674.8,276.09,674.81,276.05z M674.19,271.98c0.01,0-0.01,0.01-0.04,0
				C674.17,271.98,674.18,271.98,674.19,271.98z M673.73,272.16c0-0.01,0-0.01,0-0.02C673.75,272.14,673.81,272.12,673.73,272.16z"
                />
                <path
                  className="st10"
                  d="M676.77,279.59c-0.05-0.06,0.07-0.06-0.01-0.14c0.02,0,0.02-0.01,0.04-0.02c-0.04-0.01-0.03-0.17,0.02-0.71
				c0.14,0.07-0.05-0.18,0.12-0.03c-0.01-0.05-0.05-0.11-0.06-0.17c0.04,0,0.06,0.04,0.08,0.07c-0.06-0.07-0.05-0.24-0.13,0.11
				l-0.1-0.05l0.07,0.09c-0.03,0.01-0.09,0.01-0.1-0.03c-0.05,0.03-0.06,0-0.04,0.14c-0.02-0.01-0.07-0.01-0.05-0.04
				c-0.04,0.07-0.13,0.19,0.09,0.31l-0.2,0.02l0.07,0.03c-0.01,0.01-0.02,0.04-0.05,0.02c0.04,0.06,0.03,0,0.08,0.04
				c-0.08,0.05-0.01,0.18-0.13,0.16c0.05,0.04,0.06-0.05,0.08,0.03c-0.15-0.07,0.04,0.18-0.16,0.09c0.13,0.11-0.17,0.07-0.02,0.35
				c-0.04-0.02,0.02,0.02-0.15,0.2c0.09,0.04,0.05,0.11,0.05,0.17c-0.05-0.02-0.07-0.03-0.09-0.07c0.01,0.01-0.03,0.02,0.02,0.13
				l-0.06-0.03c-0.01,0.07,0.04,0.16-0.03,0.24c0.06,0.02,0.19-0.02,0.21,0.08c-0.03-0.01-0.09-0.02-0.1-0.05
				c-0.01,0.01,0.03,0.1-0.04,0c0.02,0.04-0.04,0.12-0.04,0.02c-0.02,0.03-0.06,0.07-0.1,0.07c0.03,0.02,0.12,0.09-0.04,0.09
				c0.03,0.03-0.02,0.06,0.05,0.08c-0.01,0.02-0.04,0.01-0.06,0.02c0.03,0.04,0,0,0.06,0.15l0,0c0.07,0.11-0.19,0.27-0.25,0.73
				c-0.02-0.07-0.04,0.01-0.01-0.06c-0.04,0.06-0.09,0.06-0.05,0.16c-0.05-0.04-0.09,0.03-0.09,0.06l0.06-0.03
				c-0.13,0.17-0.08,0-0.23,0.41c0.31-0.07-0.09,0.4-0.09,0.32l0.03,0.02c-0.04,0.02-0.07,0.11-0.1,0.07
				c0.01,0.06,0.04,0.04,0.07,0.01c-0.07,0.07-0.04,0.13-0.1,0.2c0.05-0.03,0.12,0.35-0.12,0.16c0.03,0.1-0.09,0.13-0.11,0.17
				l-0.01-0.05c0,0.03,0,0.05-0.01,0.07l0.06,0c-0.1,0.08-0.07,0.01,0,0.2c0.07-0.12-0.27-0.13-0.07,0.11
				c-0.04-0.03-0.09-0.01-0.08,0.01c-0.01-0.03,0.1,0,0.08,0.16c0.04-0.02,0.05-0.06,0.05,0.08c-0.03-0.01-0.01-0.05-0.03-0.04
				c0.12,0.14-0.43,0.15-0.2,0.38l0.02-0.14l0.05,0.11c0.02-0.04,0-0.1,0.02-0.12c0.03,0.06,0.02,0.05,0.07,0.05
				c-0.07,0.28-0.24-0.12-0.3,0.4c0.05-0.02,0.08-0.05,0.02,0.1c-0.04-0.02-0.07-0.01-0.11,0c0.03,0.01-0.04,0.09,0.05,0.14
				c-0.11,0.07-0.14,0-0.05,0.28c-0.04-0.05-0.09-0.02-0.1-0.1c0,0.11,0-0.03,0.06,0.32c-0.14,0.03-0.15,0.06-0.34,0.52
				c-0.01-0.01-0.02-0.01-0.01-0.02c-0.04,0.24-0.01-0.01-0.19,0.28l0-0.02c-0.07,0.02,0,0.15-0.05,0.22c0,0-0.01-0.01-0.02-0.01
				c0.05,0.09,0.02,0.21,0,0.29c-0.09-0.03-0.26,0-0.35,0.68c-0.05-0.07-0.04-0.17-0.05-0.24c-0.02,0.04-0.06,0.13-0.03,0.17
				c0.03-0.05,0.03-0.07,0.02,0.18c-0.16-0.1-0.14,0.17-0.3,0.15c-0.01,0.16-0.17,0.24-0.24,0.4c0.06-0.04,0.16,0.24-0.04,0.08
				c0.06-0.01,0.05,0.06,0.08,0.08c-0.06,0.03-0.15,0.05-0.27,0.03c0.05,0.02,0.05,0.07,0.01,0.1l0.1-0.03
				c0.01,0.15-0.16,0.03-0.15,0.18l-0.02-0.06c-0.01,0.07-0.11,0.2-0.04,0.32c-0.02,0-0.06-0.01-0.06-0.04
				c-0.1,0.05-0.08,0.16-0.29,0.58l-0.02-0.05c-0.3,0.57-0.07,0.18-0.82,1.22c0.03,0.18-0.05,0.07-0.44,0.8
				c-0.12-0.03-0.05-0.06-0.23,0.19l-0.03-0.05c0.01,0.26,0.09,0.11-0.25,0.52c-0.01-0.01-0.03-0.03-0.02-0.05
				c-0.04,0.05-0.2,0.14-0.07,0.21c-0.08-0.06-0.09,0.1-0.1,0.18l-0.05-0.05c0.02,0.01,0.01,0.09-0.06,0.29l-0.02-0.05
				c-0.07,0.08,0.1,0.16-0.15,0.18c0,0.01,0,0-0.2,0.25c0.06-0.07,0.11-0.12,0.11-0.11c-0.05-0.13-0.32,0.09-0.29,0.36
				c-0.01,0,0.01-0.01,0.01-0.05l0,0.01c0.01-0.02,0.03-0.04,0.05-0.07c0.01,0.04,0.01,0.09-0.04,0.09
				c0.02,0.04,0.05,0.09,0.06,0.11c-0.06,0.08-0.37,0.22-0.91,0.93c0.02,0,0.03-0.01,0.04,0c-0.14,0.14-0.19,0-0.61,0.71l-0.07-0.04
				c0.08,0.27-0.43,0.18-0.55,0.79c0,0-0.01-0.01-0.02-0.02l0.02,0.04c0,0,0,0,0,0c0.03,0.08,0.03-0.02,0.34,0.08l-0.05,0.05
				c0.06,0.01,0.05-0.04,0.04-0.08c0.06,0.01,0.09,0.08,0.04-0.12c0.19,0.17-0.01-0.15,0.13,0c0-0.05,0.06-0.09,0.03-0.15
				c0.08,0.02,0.08-0.11,0.16-0.03c-0.01-0.03-0.01-0.11,0.01-0.14c0.05,0.14,0.1-0.1,0.19,0.01c-0.05-0.12,0.09-0.17,0.1-0.28
				c0,0.01,0.01,0.03,0,0.03c0.04-0.03,0.08-0.02,0.12-0.03c-0.07-0.07-0.03-0.15-0.05-0.22c0.11,0.02,0.06,0.11,0.14,0.15
				c-0.06-0.05,0.01-0.13-0.05-0.16c0.07,0,0.05-0.01,0.12,0.02c-0.09-0.09,0.01-0.05-0.02-0.13c0.06,0.03,0.12-0.1,0.16,0.06
				c-0.13-0.15,0.25-0.13,0.18-0.64c-0.02,0.01,0,0.03,0.01,0.05l0-0.04c-0.01,0.21,0.21-0.02,0.08,0.04
				c0.26,0.29,0.2-0.56,0.45-0.39c0.11-0.31,0.04,0.08,0.3-0.51c-0.2,0.21,0.35-0.2,0.67-0.78l0,0.03c0.08-0.05-0.06-0.17,0.04-0.19
				l0,0.02c0.11-0.13,0.16,0.05,0.68-0.91l0.1,0.02c-0.06-0.17,0.08,0.09,0.17-0.32c0.02,0.02,0.02,0.05,0.02,0.08
				c-0.06-0.14,0.11,0.04,0.02-0.12c0.06-0.01,0.11-0.09,0.14-0.16l0.04,0.04c0.08-0.1,0.13-0.24,0.21-0.36
				c-0.05,0.03-0.12-0.03-0.1-0.07l0.08,0.03c-0.03-0.04-0.13-0.13,0.03,0l0.01-0.07c0.02,0.01,0.02,0.03,0.05,0.05
				c-0.03-0.05,0.13-0.05,0.08-0.13c0.09-0.06,0.22-0.41,0.27-0.71c0.06,0.03,0.11-0.06,0.12,0.01c0.06-0.11,0.07-0.14,0.11-0.3
				c-0.06,0.01-0.09-0.04,0.02-0.02c0.01-0.2,0.37-0.25,0.33-0.44l0,0.01c0.04-0.23,0.05-0.12,0.56-0.86
				c-0.03-0.02-0.1-0.04,0.02-0.06c-0.37,0.11,0.42-0.47,0.71-1.59c0.01,0.02,0.03,0.03,0.02,0.05c0.07-0.02,0.07-0.15,0.03-0.18
				c-0.04,0.06,0.19-0.21,0.23-0.6c0.1,0.05,0.15-0.09,0.11-0.16c0.02,0.03,0.05-0.03,0.09,0.01c0.02-0.06,0.02-0.11-0.03-0.13
				c0.17-0.12,0.12-0.05,0.24-0.74c0.15,0.21-0.02,0.39,0.25-0.55c0.01,0,0.02,0.02,0.02,0.04c-0.03-0.09,0,0.02-0.15-0.21
				c0.12,0.09,0.08-0.11,0.23,0.02c0-0.02-0.02-0.05-0.05-0.06c0.15-0.17,0.06-0.03,0.15-0.6c0.09,0.07,0.05-0.03,0.14-0.01l0-0.08
				l-0.05,0.01c0.02-0.06-0.02-0.14,0.02-0.18l0.02,0.06l0.02-0.12l-0.06,0.05c-0.02-0.08-0.11-0.03-0.06-0.1
				c0.02,0.02,0.01-0.06,0.2-0.15c-0.06-0.05,0.03-0.15-0.04-0.21c0.01,0.02,0.06,0.03,0.07,0.03l-0.05-0.04
				c0.08-0.05,0.01-0.07,0.15-0.26l0,0.04l0.05-0.02l-0.04-0.03c0.14-0.06-0.12-0.12,0.22-0.89c-0.01,0.08,0.19-0.09,0.05-0.21
				c0.11-0.12,0.27-0.07,0.1-0.66c0.02,0.07,0.09,0.02,0.13,0.01c-0.02,0-0.03-0.01-0.05-0.02c0.15-0.01-0.07-0.06,0.01-0.11
				c0,0.01-0.05-0.21,0.06-0.06c0.02-0.17,0,0.02,0.15-0.24c-0.1-0.04-0.11-0.03-0.03-0.16l-0.06,0.02c0-0.03,0-0.06-0.02-0.1
				C676.64,279.66,676.59,279.62,676.77,279.59z M669.68,292.72c-0.01-0.01,0,0-0.02-0.03
				C669.67,292.67,669.72,292.66,669.68,292.72z M670.12,292.9c0-0.01,0.01-0.02,0.02-0.03
				C670.14,292.89,670.14,292.87,670.12,292.9z M670.17,292.76c0.01,0.02,0.04,0,0.07-0.01
				C670.23,292.74,670.19,292.85,670.17,292.76z"
                />
                <path
                  className="st10"
                  d="M690.64,273.28l-0.02,0.12c-0.16-0.59,0.12-0.3-0.71-1.26c0.1,0.01,0.02-0.1,0.19,0
				c-0.3-0.14,0.16-0.02-2.25,0.08c0-0.02-0.01-0.02,0.02-0.03c-0.47,0.2-0.02,0.05-0.74,0.14l0.03-0.01
				c-0.1-0.05-0.25,0.06-0.39-0.06c0,0,0.01-0.01,0.02-0.02c-0.15,0.01-0.32-0.19-0.35-0.37c0.3,0.04-0.24,0.02,0.13-0.27
				l-0.02,0.15c0.1-0.06,0.02-0.18,0.07-0.26l0.03,0.02c0.06-0.23-0.01-0.27,0.12-0.52l-0.04,0.01c0.1-0.42,0.26,0,0.4-0.79
				c0.02,0.13-0.06,0.34-0.09,0.48c0.05-0.09,0.17-0.31,0.15-0.39c-0.01,0.04-0.02,0.1-0.05,0.13c-0.01-0.15,0.04-0.32,0.13-0.51
				c0.04,0.23,0.99-1.48-0.72-1.66c0.2,0.14-0.16,0.01-0.1,0.17c-0.31,0.09-0.36,0.01-0.74,0.01c0.06,0.03-0.03,0.11-0.16,0.18
				l0.2-0.05c-0.23,0.25-0.32,0.06-0.52,0.35l0.05-0.11c-0.24,0.08-0.05,0.34-1.21,1.29l0-0.04c-0.08,0.05,0.05,0-0.34,0.14
				l0.05-0.04c-1.22,0.48,1.7-4.48-2.92-3.84l0.04-0.08c-0.4,0.52-0.24,0.2-1,1.14c-0.01-0.01-0.03-0.04,0-0.08
				c-0.1,0.12-0.39,0.45-0.24,0.49c-0.08-0.05-0.18,0.28-0.24,0.44l-0.05-0.06c0.01,0.02-0.05,0.22-0.21,0.63l0-0.08
				c-0.13,0.22,0.08,0.21-0.28,0.51c0.03-0.07-0.01,0.26-0.05,0.16c-0.21,0.21,0.08,0.02-0.3,0.56c0.09-0.2,0.17-0.36,0.17-0.34
				c-0.06-0.03-0.14,0.13-0.16-0.04c-0.04,0.14,0.05,0.01-0.15,0.57l0-0.02c-0.08,0.23-0.09,0.11-0.25,0.49
				c0.07,0.02,0.01,0.12,0.11,0.04c-0.01,0.02,0.01-0.04,0.03-0.11l0,0.01c0.02-0.05,0.05-0.12,0.08-0.2
				c-0.06,0.62-0.29,0.33-0.55,1.57c0-0.22,0.02-0.2-0.15,0.5l-0.02-0.04c-0.29,0.46,0,0.87-0.3,1.39c0.02-0.04,0.03-0.05,0.05-0.06
				c-0.22,0.24-0.22,1.59-0.48,3.32l0.05-0.04c-0.12,0.87,0.08-0.16,0.36,1.1c-0.04-0.15,0.12-0.09-0.07-0.5
				c0.22,0.03-0.14-0.28,0.1-0.23c-0.05-0.07,0.14-0.18-0.02-0.9c0.12,0.17,0.03-0.34,0.17-0.28c-0.1-0.13-0.01-0.44-0.04-0.66
				c0.01,0.01,0.02,0.04,0.01,0.06c0.03-0.12,0.07-0.15,0.1-0.22c-0.02,0.14-0.27-0.62,0.06-0.26c-0.08,0-0.04-0.27-0.11-0.23
				c0.08-0.1,0.04-0.09,0.13-0.14c-0.14,0.07,0.01-0.64,0.12-0.33c-0.18-0.14,0.14-0.62,0.03-1.46c0.25,0.27,0.17,0.2,0.54-1.44
				l0.07,0.09c0.13-0.75,0.46-1.33,1.62-3.47l-0.01,0.06c0.4-0.75-0.24-0.02,2.01-1.65l0,0.08c0.08-0.06,0.09-0.04,0.29,0.04
				c-0.02-0.1,0.06,0.06,0.5,0.28c-0.03,0.01-0.07-0.02-0.11-0.03c0.16,0.06-0.04,0.07,0.1,0.13c0.01-0.03,0.04-0.02,0.06-0.02
				c0,0.08,0.05,0.23,0.08,0.36l-0.06-0.02c0.01,0.22-0.01,0.52-0.11,0.85c-0.09,0.49-0.14,1.26,2.72,1.34l-0.01,0.02
				c0.49-0.06,2.62-1.19,0.6,2.01l-0.02-0.04c0,0.3-0.28,1.16,1.2,1.32c-0.04,0.02-0.04,0.05-0.1,0.05
				c0.16,0.06,0.49-0.09,0.47-0.14c0.25-0.02-0.03,0.02,1.41-0.49c0.01,0.1,0.26,0.08,0.36,0.08c-0.04,0.02,0.06,0.06,0.01,0.09
				c0.09,0.03,0.18,0.07,0.2,0.02c0.21,0.47,0.37,0.31,0.47,1.09c-0.11-0.06,0,0.11-0.65,1.49c-0.01-0.01,0-0.04,0.02-0.07
				c-0.07,0.17,0.01-0.03-0.05,0.39c-0.03-0.17-0.25,0.2-0.26-0.05c-0.02,0.04-0.04,0.09-0.01,0.11c-0.05,0.04-0.08,0.04-0.06-0.03
				c-0.14,0.18-0.04,0.16-0.19,0.3c-0.02-0.02,0.01-0.05,0-0.09c-0.04,0.11-0.08,0.22-0.21,0.32c0-0.02-0.03-0.02,0.01-0.05
				c-0.22,0.2-0.3,0.42-0.58,0.6c0.01-0.08-0.03-0.14-0.3,0.1l0.08,0c-0.12,0.09-0.2,0.22-0.32,0.27l0.07-0.1l-0.22,0.18l0.15-0.06
				c-0.1,0.13,0.08,0.09-0.1,0.19c-0.28,0.1-0.11-0.01-0.84,0.46c0.01-0.14-0.25,0-0.27,0.12c0.02-0.01,0-0.02,0.04-0.05
				c-0.23,0.12-0.34,0.15-0.42,0.17l0.04-0.03c-0.31,0.11,0.03-0.02-1.11,0.51l0.06-0.01c0,0.14-0.25,0.02-0.39,0.14
				c-0.09-0.11,0.03-0.05-0.72,0.25l0.03-0.04c-0.58,0.21-0.48,0.1-1.74,0.43c0.09-0.06,0.15-0.07-0.25,0.06
				c0.04,0.05,0.05,0.03,0.13,0.05c-0.03,0.04-0.12,0.09-0.2,0.11c0.14-0.07-0.02-0.09-0.09-0.11c0.01,0.02,0,0.04,0,0.06
				c-0.37,0.02-0.01,0.08-0.79,0.18c0.05-0.03,0.13-0.04,0.19-0.07c-0.14-0.05-0.16-0.03-0.26-0.03l0.01,0l-0.23,0l0.06,0.01
				c-1.07,0.39-0.06,0.03-2.93,0.44c0.06-0.09,0.12-0.18-0.55,0.06l0.09,0.02c-0.01-0.04,0.03-0.16,0.19-0.04l-0.09,0.02
				c0.1,0.13,0.35-0.06,0.53-0.07c-0.01,0.08-0.06,0.15,0.44,0.08c-0.02,0.02,0.03,0.07-0.05,0.06c0.23,0.03,0.35-0.12,0.45-0.05
				l-0.02,0.01c0.33-0.03,0.86-0.01,2.21-0.33c-0.01,0.03,0.38-0.02,0.57-0.02l0,0.02c0.02-0.1,0.21-0.11,0.35-0.16
				c0.01,0.05-0.01,0.08-0.09,0.12c0.01-0.02,0.03,0.04,0.27-0.12l-0.02,0.07c0.06-0.04,0.29,0.01,0.66-0.31l-0.08,0.08
				c0.04-0.03,0.3-0.08,0.09,0.01c0.42-0.06,0.17-0.02,0.99-0.29l0,0c0.15-0.17,0.8-0.1,1.8-0.6c-0.11,0.09,0.06,0.02-0.12,0.08
				c0.17-0.05,0.22-0.03,0.37-0.17c-0.03,0.08,0.16,0.02,0.23-0.03l-0.13,0.01c0.08-0.05,0.16-0.07,0.24-0.11l-0.01,0.1
				c0.36-0.15,0.76-0.35,1.43-0.87c-0.25-0.07,0.46-0.08,0.3-0.17c0.2-0.09,0.28-0.19,0.48-0.3c-0.06-0.01,0.35-0.6,0.41-0.27
				c0.12-0.18,0.32-0.21,0.41-0.29l-0.06,0.09c0.04-0.06,0.07-0.09,0.11-0.13l-0.08,0c0.05-0.04,0.09-0.08,0.11-0.06
				c0.02-0.15-0.15-0.14,0.18-0.33c-0.27,0.21,0.12,0.32,0.24-0.21c0.01,0.06,0.1,0.01,0.13-0.03c-0.06,0.06-0.08-0.08,0.11-0.33
				l-0.08,0.07c-0.01-0.24,0.39-0.28,0.75-0.96c-0.08,0.04-0.07,0.06-0.07-0.12c-0.03,0.12-0.07,0.24-0.11,0.36
				c0.01-0.08,0.02-0.17,0.02-0.25c-0.04,0.1-0.06,0.23-0.1,0.28c0.01-0.12,0.01-0.1-0.05-0.08
				C690.46,273.99,690.72,274.39,690.64,273.28z M678.26,270.45c-0.02-0.05-0.04-0.05,0.03-0.11
				C678.28,270.37,678.27,270.34,678.26,270.45z M678.81,270.31c0.01-0.02,0.02-0.04,0.03-0.08
				C678.83,270.26,678.84,270.23,678.81,270.31z M678.9,269.99c0.01,0.02,0.05-0.05,0.1-0.09
				C678.99,269.86,678.94,270.11,678.9,269.99z M690.13,273.59c0.01,0.08,0.02,0.15,0.03,0.25
				C690.1,273.82,690.13,273.69,690.13,273.59z"
                />
                <path
                  className="st10"
                  d="M667.7,287.23c0.28,0.06,0.46-0.06,0.74-0.06c-0.06,0.12,0.1,0.01,0.15,0.1c0.05-0.03,0.1-0.05,0.15-0.08
				l-0.08-0.02c0.13-0.05,0.2-0.16,0.32-0.19l-0.07,0.08c0.07-0.05,0.14-0.09,0.2-0.15l-0.14,0.05c0.21-0.35-0.05-0.05,0.67-0.66
				c0,0.11,0.22-0.01,0.17-0.17c-0.01,0.02,0.01,0.02-0.01,0.05c0.13-0.19,0.2-0.24,0.26-0.28l-0.03,0.05c0.18-0.2,0,0.03,0.55-0.81
				l-0.04,0.03c-0.09-0.11,0.17-0.12,0.19-0.27c0.13,0.05,0.02,0.07,0.33-0.51l0.01,0.05c0.23-0.41,0.27-0.3,0.82-1.17
				c-0.02,0.08-0.04,0.13,0.12-0.17c-0.06-0.02-0.05,0.01-0.12,0.03c-0.01-0.05,0.01-0.12,0.05-0.18c-0.04,0.12,0.08,0.05,0.13,0.03
				c-0.02,0-0.02-0.03-0.04-0.04c0.18-0.03-0.04-0.04,0.11-0.17c-0.02,0.04,0.13-0.35,0.11-0.08c0.06-0.09,0.03-0.24,0.14-0.28
				c-0.01,0.05-0.05,0.1-0.07,0.15c0.13-0.03,0.12-0.06,0.18-0.11l-0.01,0l0.14-0.12l-0.05,0.02c0.39-0.8-0.01-0.08,1.61-1.65
				c0.02,0.08,0,0.21,0.36-0.24l-0.07,0.02c0.03,0.02,0.06,0.15-0.11,0.1l0.05-0.05c-0.14-0.08-0.23,0.17-0.34,0.25
				c0.04-0.15-0.08-0.2-0.35,0.11c0-0.03-0.06-0.05,0-0.07c-0.18,0.07-0.17,0.25-0.27,0.23l0.01-0.01
				c-0.19,0.17-0.58,0.41-1.18,1.32c-0.02-0.03-0.23,0.2-0.34,0.31l-0.01-0.01c0.06,0.08-0.05,0.19-0.1,0.29
				c-0.04-0.04-0.05-0.06-0.03-0.13c0,0.02-0.05-0.01-0.09,0.22l-0.04-0.06c-0.08,0.12-0.1,0.28-0.24,0.4
				c0.05,0.04,0.23-0.01,0.15,0.16c-0.03-0.01-0.09-0.04-0.07-0.09c-0.01,0.02-0.01,0.06-0.04,0.08l0-0.1
				c0,0.05-0.14,0.21-0.06,0.04c-0.05,0.06-0.12,0.11-0.17,0.11c0.02,0.26,0,0-0.27,0.57l0,0c-0.07,0.24,0,0-0.42,0.59
				c-0.07-0.08-0.13,0.19-0.46,0.61c0.03-0.11-0.05,0.01,0.04-0.11c-0.1,0.1-0.15,0.1-0.18,0.26c-0.02-0.08-0.13,0.03-0.15,0.1
				l0.09-0.05c-0.04,0.06-0.08,0.11-0.12,0.16l-0.04-0.08c-0.24,0.21-0.33,0.49-0.79,0.96c0.19,0.11-0.34-0.04-0.22,0.11
				c-0.15,0.03-0.21,0.1-0.35,0.11c0.07,0.03-0.42,0.29-0.3-0.02c-0.13,0.05-0.22-0.07-0.27-0.1l0.06-0.01
				c-0.04,0.01-0.06,0-0.09-0.02l0.01,0.07c-0.03-0.02-0.06-0.03-0.05-0.06c-0.08,0.01-0.08,0.22-0.23-0.01
				c0.1,0.15,0.25-0.17-0.1-0.15c0.05-0.01,0.05-0.07,0.04-0.08c0.03,0.02-0.1,0.09-0.18-0.11l0,0.06c-0.15-0.11-0.04-0.28,0.18-0.7
				c-0.07,0.02-0.06,0.03-0.08-0.09c-0.02,0.08-0.03,0.17-0.04,0.24c-0.02-0.06-0.02-0.11-0.04-0.18c-0.03,0.07-0.01,0.16-0.03,0.2
				c-0.03-0.08-0.02-0.07-0.07-0.05c0.15-0.39,0.28-0.03,0.46-0.8l-0.06,0.06c-0.04-0.06,0.06-0.14,0.08-0.23
				c0.04,0.03,0.08-0.01,0.12-0.03c-0.03-0.01,0.08-0.17,0-0.24c0.17-0.2,0.16-0.07,0.11-0.56c0.04,0.07,0.09-0.02,0.1,0.13
				c-0.1-0.66,0.01-0.01-0.54-1.74c0.02,0,0.02-0.02,0.03,0.01c-0.36-0.3,0.05-0.05-0.48-0.52l0.04,0.01
				c-0.13-0.07-0.04-0.19-0.98-0.23c0.01-0.02,0.01-0.03,0.01-0.04c-0.04,0.04-0.12,0.07-0.28-0.08c0.05,0,0.1-0.01,0.15,0
				c-0.09-0.1-0.22-0.12-0.73,0.15l0.03,0.02c-0.28,0.19-0.16-0.08-0.65,0.3c0.04-0.1,0.19-0.21,0.28-0.29
				c-0.08,0.03-0.26,0.12-0.28,0.19c0.03-0.02,0.05-0.06,0.09-0.06c-0.15,0.23-0.61,0.17-1.81,0.55c0.03-0.03,0.11-0.08,0.02-0.13
				c0.06,0.06-0.06,0.06-0.07,0.1c-0.09-0.06-0.18-0.15-0.17-0.28c0,0.06-0.08,0.06-0.12,0c0.01,0.04,0.03,0.07,0.06,0.11
				c-0.21-0.02-0.07-0.17-0.23-0.22c0.02,0,0.05,0,0.07,0c-0.08-0.03-0.14-0.19-0.26-0.27c0.01-0.02,0.06-0.04,0.07-0.01
				c-0.02-0.17-0.05-0.52,0.13-0.67l0.02,0.04c0.04-0.15,0.06-0.03,0.17-0.31l0,0.07c0.71-0.89,0.01-0.32,2.08-1.69
				c-0.02-0.01-0.03-0.14,0.16-0.16c-0.04,0.01,0.31,0.11,0.28-0.18c0.17,0.1,0.39-0.24,1.66-0.39l-0.04,0.06
				c0.38-0.25,0.14-0.14,1.03-0.28c0,0.02-0.01,0.05-0.04,0.06c0.11-0.01,0.44,0.02,0.39-0.12c0.01,0.09,0.25-0.01,0.38-0.06
				l-0.02,0.07c0-0.03,0.15-0.08,0.49-0.15l-0.05,0.05c0.2,0,0.11-0.19,0.45,0c-0.06,0.01,0.19-0.11,0.13-0.03
				c0.21,0.09,0-0.08,0.49,0.04c-0.17-0.01-0.3-0.01-0.29-0.02c0,0.06,0.13,0.08,0.03,0.16c0.09,0.04,0.24-0.18,0.85-0.02
				c0-0.07,0.09-0.05,0.01-0.12c0.02,0.01-0.03,0-0.09,0.01l0-0.01c-0.04,0-0.1,0-0.17,0c0.03-0.07,0.12,0.09,0.29-0.12
				c0.2,0.23,0.22,0.17,0.99,0.21c-0.17,0.05-0.16,0.02,0.4,0.05l-0.03,0.02c0.36,0.21,0.69-0.12,1.08,0.18
				c-0.03-0.02-0.04-0.03-0.04-0.04c0.17,0.18,0.75,0.1,2.31,1.1l0-0.06c0.48,0.52-0.03-0.17,0.92,0.28
				c-0.05-0.09-0.03-0.08-0.35-0.23c0.17-0.2-0.23-0.04-0.08-0.2c-0.09-0.02-0.18-0.12-0.25-0.1c-0.02-0.11-0.23-0.15-0.16-0.23
				c-0.04,0.01-0.17-0.01-0.23-0.04c0.19-0.04-0.23-0.15-0.14-0.25c-0.15,0.05-0.35-0.13-0.54-0.15c0.01,0,0.04-0.01,0.05,0
				c-0.09-0.05-0.1-0.1-0.15-0.15c0.1,0.05-0.57,0.14-0.19-0.11c-0.02,0.08-0.23-0.01-0.21,0.07c-0.07-0.09-0.07-0.05-0.08-0.15
				c0.03,0.14-0.53-0.07-0.25-0.16c-0.25,0.23-0.2-0.22-1.21-0.01c-0.12-0.69,0,0.02-1.21-0.27l0.06-0.09
				c-0.61,0.15-1.14-0.23-3.13,0.02l0.04-0.02c-0.17-0.02-0.17,0.17-0.33,0.12l0.03-0.02c-0.13-0.05-0.34,0.22-2.07,0.31
				c0,0.03-0.07,0.08-0.28,0.1c0.02,0.17,0.09-0.22-0.57,0.24c0-0.03,0.04-0.06,0.08-0.1c-0.14,0.14-0.06-0.06-0.19,0.07
				c0.03,0.01,0.01,0.05,0.01,0.06c-0.1-0.02-0.27,0.07-0.39,0.15l0-0.06c-0.24,0.09-0.47,0.27-0.71,0.42
				c0.02,0.04,0.17-0.16-1.84,2c0.01,0.97-0.07,0.03,3.95,1.13l-0.06,0.09c0.35-0.12,0.69,0.11,1.01,0.25c-0.03,0-0.04,0.02-0.07,0
				c0.03,0.09,0.21,0.17,0.25,0.15c0.04,0.18,0.05-0.09,0.56,0.89c-0.1,0.02-0.05,0.24,0,0.32c-0.03-0.03-0.04,0.07-0.09,0.04
				c0,0.09,0.01,0.17,0.07,0.17c-0.27,0.47-0.1,0.31-0.47,1.33c0.24-0.59-0.63,0.78,0.09,1.43c-0.19,0,0.15,0.25-0.09,0.25
				c0.03,0.03,0.09,0.05,0.11,0.02c0.04,0.05,0.04,0.08-0.03,0.06c0.05,0.12,0.32,0.03,0.25,0.16c0.12,0.01,0.22,0,0.36,0.06
				C667.73,287.22,667.74,287.24,667.7,287.23z M670.43,278.1c-0.01,0.01,0,0-0.02,0.03C670.37,278.13,670.3,278.1,670.43,278.1z
				 M670.19,277.63c-0.01,0-0.04,0-0.07,0C670.15,277.63,670.12,277.63,670.19,277.63z M669.92,277.68c0.02-0.02-0.05-0.03-0.1-0.05
				C669.8,277.64,670.01,277.59,669.92,277.68z"
                />
              </g>
            </g>
          </g>
          <g id="nuve-iz">
            <g>
              <g>
                <g>
                  <path
                    className="st11"
                    d="M319.01,172.04c-14.78,7.17-32.06,9.06-48.04,5.26c-9.27-2.21-18-6.23-26.75-10.01
					c-24.19-10.43-50.34-19.31-76.42-15.66c-8.03,1.12-15.84,3.41-23.83,4.79c-13.66,2.37-27.79,2.04-41.32-0.96
					c-7.67-1.7-15.69-4.24-23.2-1.92c-1.18,0.37-2.47,1.01-2.77,2.21c-0.18,0.73,0.06,1.49,0.39,2.16c2.03,3.99,7.08,5.18,11.5,5.93
					c8.46,1.43,16.94,2.86,25.51,3.24c28.59,1.24,57.74-9.31,85.4-1.94c13.07,3.48,24.77,10.75,37.35,15.72
					c12.85,5.07,26.65,7.72,40.46,7.75c4.27,0.01,8.61-0.24,12.66-1.6c9.12-3.07,16.29-11.59,25.89-12.17"
                  />
                </g>
                <g>
                  <path
                    className="st11"
                    d="M369.44,115.38c-5.08,5.65-14.07,5.2-21.37,3.06c-9.81-2.88-18.89-7.93-28.7-10.79
					c-9.82-2.86-21.18-3.23-29.51,2.71c-4.4,3.14-7.54,7.73-11.62,11.28c-4.08,3.55-9.93,6.04-14.86,3.81
					c-4.96-2.24-6.88-8.12-9.95-12.62c-4.78-7.01-13.16-11.09-21.61-11.79c-8.45-0.7-16.95,1.7-24.49,5.59
					c-5.68,2.93-10.93,6.69-16.79,9.21s-12.71,3.71-18.61,1.25c4.53-6.6,9.07-13.19,13.6-19.79c3.2-4.66,6.48-9.4,10.92-12.9
					c5.15-4.06,11.54-6.2,17.89-7.84c6.13-1.58,12.42-2.78,18.75-2.53c13.32,0.52,25.7,7.48,35.49,16.52
					c1.72,1.59,3.68,3.37,6.02,3.24c1.22-0.07,2.34-0.66,3.43-1.2c23.06-11.48,51.94-6.99,73.46,7.17
					c6.78,4.46,13.29,10.28,15.48,18.09"
                  />
                </g>
              </g>
              <path
                className="st10"
                d="M13.54,152.38l-0.61,0.14c0.76-0.09,0.74-0.01,5.95-1.41c-0.57,0.31,1.24-0.26,1.13-0.03
			c0.41-0.26,1.61-0.43,2.38-0.69c-0.41,0.37-0.26,0.13,1.67-0.19c-0.05-0.1,0.93-0.31,0.73-0.36c0.44-0.01,0.37-0.04,0.61,0.03
			c-0.35-0.11,2.28-0.62,1.31-0.18c1.56-0.67,0.07,0.02,4.66-1.16c-0.11-0.24-0.13-0.15,0.55-0.28c-0.19,0.03-0.2,0.07-0.28,0.11
			c-1.58,0.57,2.37-0.36,0.51-0.01c0.95,0.37,1.63-0.41,5.48-0.99l-0.23,0.17c1.43-0.26,0.56-0.37,14.4-1.92l-0.19,0.05
			c0.78-0.02,0.86-0.28,1.58-0.24l-0.14,0.03c1.26,0.04,1.68-0.31,9.64-0.27c-0.03-0.05,0.48-0.06,1.35,0
			c-0.36-0.28,0.18,0.16,2.83,0.02c-0.04,0.04-0.3,0.05-0.5,0.07c1.5-0.02,0.28-0.01,2.85,0.08l-0.13,0.07
			c1.15,0.08,2.42,0.12,3.71,0.23c-0.4-0.03-0.25-0.08,0.77,0.1c1.69,0.13-0.06,0.29,11.49,0.56c1.92-0.09,0.06,0,5.22-0.13
			l-0.07,0.02c2.48-0.46,3.83-0.22,9.1-2.05l-0.09-0.03c2.01-0.93,0.72-0.17,9.29-5.14l-0.23,0.3c1.59-1.27,3.39-2.4,4.88-3.73
			c-0.08,0.09-0.06,0.12-0.21,0.24c0.21-0.21,0.31-0.05,3.04-2.46c0.56-0.41,0.48-0.31,2.6-2.2c0.06,0.16,1.09-0.71,1.42-1.06
			c-0.09,0.13,0.35-0.21,0.25-0.05c0.42-0.33,0.79-0.66,0.66-0.66c1.55-0.94,1.08-0.81,5.62-4.19c-0.22,0.24-0.4,0.41-0.52,0.52
			c0.69-0.43,0.92-0.74,5.23-3.25c0,0.01-0.1,0.09-0.21,0.16c0.64-0.42-0.47,0.27,0.99-0.86c-0.26,0.37,1.08-0.5,0.63,0.02
			c0.13-0.08,0.29-0.2,0.24-0.23c0.21-0.09,0.29-0.1,0.1,0.05c0.74-0.42,0.44-0.34,1.14-0.66c0.01,0.03-0.12,0.11-0.18,0.18
			c0.33-0.22,0.66-0.44,1.26-0.68c-0.03,0.03,0.05,0.03-0.12,0.11c0.62-0.21,0.29-0.34,2.85-1.29c-0.14,0.13-0.22,0.28,1.12-0.26
			l-0.27,0.03c0.54-0.19,0.98-0.48,1.47-0.58c-0.13,0.06-0.26,0.11-0.39,0.18c0.34-0.13,0.68-0.23,1.02-0.35l-0.6,0.13
			c0.54-0.23-0.11-0.14,0.64-0.33c-0.15,0.06,0.55-0.17,0.86-0.18l0,0c1.07-0.42,0.45,0.05,2.59-0.76
			c-0.24,0.25,0.88-0.06,1.09-0.24c-0.07,0.02-0.03,0.03-0.18,0.08c0.81-0.18,1.23-0.26,1.55-0.31c-0.03,0.01-0.05,0.01-0.08,0.02
			c1.28-0.21-0.09,0.03,4.54-0.84l-0.23,0.01c0.65-0.35,0.17,0.27,4.35-0.46l-0.15,0.06c2.37-0.18,1.83-0.1,6.73-0.11
			c-0.39,0.03-0.58,0.02,0.97,0.05c-0.09-0.09-0.16-0.06-0.42-0.14c0.16-0.04,0.51-0.06,0.82-0.04c-0.57,0.02,0,0.13,0.24,0.19
			c-0.04-0.03,0.05-0.05,0.03-0.08c0.64,0.18,0.12-0.16,1-0.01l-0.05,0.04c0.2,0,1.45,0.02,0.61,0.1c0.5,0.05,1.01-0.06,1.45,0.09
			c-0.22-0.01-0.52-0.02-0.74-0.03c0.48,0.17,0.54,0.15,0.91,0.21l-0.04-0.01l0.84,0.15l-0.21-0.05c0.66-0.17,1.28,0.09,2.15,0.1
			c-0.84,0-0.15,0.06-0.23,0.16c0.21,0.07,0.53,0.16,0.43,0.17c1.18,0.23,2.98,0.07,8.46,1.88c-0.1,0.01,0,0-0.42,0.02c0,0,0,0,0,0
			c-0.24-0.02,1.88,0.31,0.88,0.27c0.51,0.11,1.29,0.41,1.48,0.37l-0.3-0.15c-0.04,0.08-0.22,0.13-0.68-0.22l0.32,0.1
			c-0.25-0.33-1.28-0.38-1.91-0.59c0.41-0.08,0.72,0-0.26-0.25c-0.56-0.24,0.03-0.07-1.23-0.39c0.1,0-0.05-0.12,0.22-0.02
			c-0.78-0.34-1.35-0.23-1.62-0.43l0.07,0.01c-1.37-0.3-0.62-0.13-3.84-0.8c0.09-0.15,0.19-0.34,0.29-0.61l-0.15,0.42
			c0.26-0.41,0.57-1.35,1.5-4.09c-0.01,0.48,0.38-0.82,0.52-0.64c-0.02-0.37,0.42-1.11,0.57-1.68c0.09,0.44-0.03,0.21,0.69-0.99
			c-0.11-0.03,0.23-0.66,0.08-0.59c0.22-0.23,0.16-0.21,0.34-0.3c-0.27,0.12,0.67-1.56,0.52-0.78c0.31-1.28-0.02,0.01,1.55-3.06
			c-0.21-0.07-0.21-0.02,0.08-0.46c-0.07,0.11-0.05,0.14-0.06,0.21c-0.4,1.18,1-1.44,0.27-0.26c0.71-0.2,0.84-1.47,2.26-3.28l0,0.23
			c1.26-1.71-0.82,0.26,6.78-7.85l-0.08,0.12c0.43-0.35,0.31-0.58,0.76-0.86l-0.06,0.08c0.37-0.23,3.05-2.84,5.36-4.35
			c-0.08-0.07,0.08,0.08,0.75-0.62c0.14-0.03,0.57-0.23,1.66-1.17c0,0.05-0.14,0.17-0.24,0.28c0.82-0.68,0.13-0.15,1.69-1.15
			l-0.03,0.11c0.71-0.42,1.45-0.95,2.23-1.43c-0.24,0.16-0.2,0.02,0.5-0.24c1.26-0.45-0.31,0.05,7.29-3.7
			c1.26-0.58,0.03-0.03,3.49-1.2l-0.05,0.02c2.49-0.69-0.08-0.3,6.37-1.58l-0.06-0.05c1.06-0.21,0.4-0.39,7.42-1.09l-0.25,0.13
			c0.78-0.08,0.73-0.18,4.34-0.28c-0.08,0.03-0.09,0.06-0.22,0.06c-0.05,0.01,0.87,0.07,2.78,0c0.49,0.06,0.41,0.09,2.43,0.07
			c-0.05,0.16,0.91,0.17,1.25,0.16c-0.12,0.03,0.28,0.09,0.15,0.14c0.38,0.05,0.73,0.06,0.67-0.03c1.25,0.37,0.95,0.09,4.97,0.71
			c-0.17,0.02-0.31,0.03-0.42,0.03c0.69,0.15,0.87,0.08,4.21,0.9c-0.01,0.01-0.1,0.01-0.19-0.01c0.54,0.06-0.37-0.07,0.96-0.06
			c-0.33,0.12,0.81,0.27,0.3,0.4c0.11,0.02,0.26,0.02,0.25-0.03c0.16,0.06,0.2,0.1,0.02,0.11c0.6,0.12,0.41-0.01,0.93,0.16
			c-0.01,0.03-0.12,0.01-0.19,0.03c0.28,0.02,0.57,0.05,1,0.23c-0.03,0.01,0.01,0.05-0.12,0.01c0.76,0.24,1.35,0.26,2.16,0.6
			c-0.26,0.02,0.06,0.21,0.72,0.41l-0.15-0.13c0.38,0.16,0.75,0.21,1.07,0.38l-0.3-0.07l0.73,0.27l-0.39-0.21
			c0.41,0.1,0.02-0.17,0.52,0.07c-0.11-0.03,0.38,0.16,0.55,0.31l0,0c0.83,0.23,0.15,0.31,1.8,0.72c-0.27,0.07,0.49,0.41,0.71,0.37
			c-0.05-0.02-0.03,0.01-0.14-0.03c0.49,0.26,0.82,0.43,1.15,0.6l-0.19-0.07c1.01,0.48,0.01-0.01,2.95,1.46l-0.14-0.09
			c0.94,0.36,0.49,0.63,2.73,1.55l-0.12-0.02c1.45,0.89,1.06,0.78,3.99,2.72c-0.25-0.13-0.37-0.21,0.56,0.42
			c-0.01-0.11-0.06-0.11-0.18-0.27c0.12,0.03,0.34,0.15,0.52,0.29c-0.35-0.2-0.08,0.11,0.04,0.25c-0.01-0.04,0.06-0.02,0.06-0.05
			c0.29,0.4,0.16-0.08,0.61,0.38c-0.26-0.03-0.06-0.03,0.27,0.33c0.29,0.22,0.69,0.28,0.87,0.58c-0.13-0.08-0.3-0.22-0.44-0.29
			c0.22,0.3,0.26,0.31,0.45,0.5l-0.02-0.02l0.43,0.44l-0.1-0.13c0.5,0.11,0.72,0.57,1.29,0.85c-0.55-0.28-0.13,0-0.24,0.05
			c0.1,0.12,0.25,0.3,0.18,0.28c0.17,0.12,0.26,0.03,0.83,0.76c-0.84-1.06,2.12,1.76,3.73,3.46c-0.45-0.18-0.48-0.31,0.15,0.38
			c0.27,0.27,0.68,0.7,0.82,0.73l-0.13-0.2c-0.05,0.05-0.23,0.05-0.36-0.37l0.17,0.17c-0.01-0.32-0.65-0.7-0.96-1.07
			c0.23,0.03,0.42,0.18-0.03-0.29c-0.26-0.35,0.06-0.05-0.65-0.66c0.07,0.03,0.03-0.11,0.17,0.05c-0.37-0.49-0.77-0.61-0.85-0.85
			l0.04,0.03c-1.24-1.17-1.35-1.23-3.61-3.33c0.12-0.09,0.06,0.15-0.82-0.66c0.07-0.03-0.76-0.67-1.13-1.02l0.02-0.01
			c-0.16,0.04-0.55-0.29-0.87-0.5c0.06-0.05,0.13-0.04,0.31,0.06c-0.05-0.01,0.02-0.06-0.67-0.41c0.42-0.07,0.19,0.05-1.28-0.8
			c-0.05-0.02-0.19-0.07-0.27-0.15l0.26,0.07c-0.2-0.17-0.49-0.19-0.16-0.16c-0.95-0.71-0.41-0.31-2.32-1.48l0,0
			c-0.71-0.38,0,0-2.1-1.27c0.01-0.17-0.66-0.38-2.24-1.3c0.35,0.11-0.08-0.11,0.35,0.13c-0.4-0.25-0.48-0.33-0.98-0.47
			c0.2,0-0.27-0.25-0.48-0.33l0.27,0.2c-0.23-0.1-0.43-0.2-0.63-0.31l0.16-0.05c-0.86-0.46-1.92-1.01-4.08-1.72
			c0.3,0.45-0.97-0.71-0.83-0.39c-0.51-0.26-0.82-0.36-1.36-0.57c-0.01,0.07-1.65-0.21-1.19-0.48c-0.53-0.09-0.93-0.37-1.22-0.48
			l0.28,0.06c-0.17-0.03-0.3-0.08-0.43-0.12l0.13,0.13c-0.15-0.07-0.29-0.11-0.27-0.14c-0.41,0.07-0.1,0.22-0.96-0.15
			c0.88,0.3,0.53-0.3-0.79-0.25c0.1-0.04-0.12-0.15-0.23-0.17c0.21,0.07-0.13,0.14-0.85-0.04l0.25,0.09
			c-0.47,0.1-1.1-0.46-2.92-0.91c0.14,0.11,0.17,0.1-0.23,0.1l0.92,0.12l-0.61,0c0.27,0.06,0.59,0.06,0.75,0.11
			c-0.29,0.01-0.26,0-0.14,0.09c-1.26-0.3-0.67-0.53-3.12-0.78l0.26,0.09c-0.13,0.06-0.52-0.09-0.8-0.11
			c-0.81-0.29,0.02-0.03-2.8-0.3c0.21-0.05-0.09-0.14,0.36-0.13c-0.22-0.03-0.42-0.07-0.43-0.02c0.23,0.03-0.56-0.18-1.38,0.04
			c-0.76-0.2,0.02-0.15-4-0.37c0.01-0.01,0.01-0.01,0.02-0.02c-1.36,0.07,0.01-0.04-2.15-0.08l0.11-0.01
			c-0.54,0-0.16-0.13-3.15,0.31c0.01-0.03,0.02-0.05,0.03-0.07c-0.09,0.08-0.33,0.16-0.88-0.02l0.47-0.04
			c-0.25-0.11-0.61,0.05-0.86,0.05l0.03-0.06c-0.72,0.07-0.72,0.21-1.5,0.26l0.08,0.04c-1.07,0.27-0.47-0.26-2.36,0.37
			c0.27-0.14,0.84-0.28,1.2-0.39c-0.28,0.04-0.94,0.15-1.07,0.24c0.11-0.03,0.24-0.08,0.36-0.08c-2.27,0.54-0.09,0.03-6.2,1.04
			c0.45,0.11-0.32,0.08-0.2,0.27c-0.13,0.05,0.01,0-0.38,0.11c0,0,0,0,0.01,0c-0.37,0.07-0.7,0.02-1.27-0.01
			c0.1,0.05-0.16,0.16-0.49,0.23l0.48-0.04c-0.75,0.34-0.73,0.03-1.49,0.37l0.23-0.14c-0.75,0.21-0.77,0.24-4.65,1.81l0.05-0.08
			c-0.28,0.16-0.14,0.02-1.19,0.58l0.17-0.14c-0.92,0.34-1.32,0.75-2.05,1.13c-0.06-0.13-0.8,0.12-1.15,0.29l0-0.03
			c-1.38,0.72,0.02,0.13-5.46,3.14c-1.79,1.36-0.2,0.13-4.88,3.56l-0.05-0.09c-0.56,0.42-0.83,0.63-1.49,1.2l0.09-0.17
			c-1.09,1.23-0.54,0.52-2.72,2.57c0-0.03-0.02-0.07,0.08-0.17c-0.29,0.27-1.2,0.98-0.95,1.03c-0.09-0.08-0.66,0.58-0.93,0.95
			l-0.02-0.12c0,0.06-0.33,0.49-1.06,1.38l0.09-0.18c-0.44,0.5-0.12,0.06-1.35,1.46c-0.61,0.46,0.11,0.04-1.12,1.25
			c0.37-0.45,0.68-0.8,0.65-0.76c-0.07-0.06-0.38,0.29-0.23-0.08c-0.22,0.32,0.06,0.03-0.88,1.28l0.02-0.04
			c-0.39,0.5-0.27,0.24-0.96,1.08c0.09,0.05-0.12,0.27,0.13,0.1c-0.05,0.05,0.07-0.09,0.17-0.25l0,0.02
			c0.1-0.12,0.22-0.28,0.37-0.45c-0.83,1.39-0.91,0.77-2.65,3.55c0.05-0.11,0.14-0.22,0.15-0.28c-0.04,0-0.43,0.99-0.96,1.42
			l0.02-0.1c-0.91,1.06-0.92,2.08-1.96,3.24c0.07-0.08,0.11-0.12,0.14-0.13c-0.47,0.64-1.53,2.62-3.99,8.02l0.11-0.08
			c-0.13,0.3-0.32,1-0.38,0.91c-0.5-0.7,1.13,2.95-3.14,1.69c0.05-0.14,0.16,0.1-1.46-0.13c0.05-0.02-1.4-0.27-2.09-0.33l0.02-0.02
			c-0.15,0.12-0.86,0.06-1.39,0.05c0.02-0.07,0.12-0.1,0.38-0.12c-0.05,0-0.15-0.05-1.06,0.01c0.45-0.31,0.23-0.06-2.1,0.05
			c-0.08,0.01-0.27,0.04-0.43,0.02l0.36-0.08c-0.33-0.03-0.74,0.11-0.31-0.04c-1.69-0.06-0.75-0.05-3.83,0.14l0,0
			c-1.11,0.16,0,0-3.41,0.27c-0.43-0.07-0.25-0.06-3.58,0.47c0.49-0.15-0.18-0.02,0.51-0.14c-0.65,0.09-0.81,0.06-1.49,0.28
			c0.22-0.13-0.51-0.02-0.81,0.06l0.47-0.02c-0.34,0.08-0.65,0.12-0.96,0.17l0.14-0.14c-1.32,0.27-2.96,0.6-5.95,1.67
			c0.68,0.09-1.64,0.22-1.23,0.33c-1.13,0.31,0.45-0.08-3.64,1.24c-0.65,0.38-1.3,0.52-1.69,0.68l0.35-0.19
			c-0.21,0.11-0.38,0.19-0.56,0.27l0.24-0.02c-0.22,0.08-0.4,0.16-0.41,0.13c-0.4,0.42,0.04,0.24-1.15,0.68
			c1.2-0.51,0.34-0.66-1.05,0.5c0.08-0.11-0.25,0-0.38,0.08c0.27-0.12-0.06,0.22-0.95,0.7l0.34-0.15c-0.43,0.42-1.5,0.69-3.77,1.97
			c0.24-0.05,0.26-0.08-0.17,0.27l1.07-0.72l-0.64,0.53c0.33-0.2,0.67-0.47,0.88-0.58c-0.97,0.75-0.75,0.01-3.92,2.49l0.33-0.2
			c-0.08,0.16-0.59,0.44-0.89,0.69c-1.08,0.58-0.01-0.03-3.09,2.41c0.17-0.23-0.19-0.01,0.27-0.43c-0.25,0.19-0.47,0.35-0.45,0.39
			c0.23-0.17-0.65,0.36-1.36,1.34c-0.94,0.56-0.08-0.12-4.44,3.4c0.02-0.04-0.02-0.03,0.08-0.09c-1.56,1.33-0.04,0.01-2.47,1.84
			l0.12-0.11c-0.63,0.41-0.16,0.15-3.38,2.79c-0.01-0.04-0.01-0.05-0.01-0.08c-0.06,0.13-0.3,0.38-1.04,0.63l0.53-0.38
			c-0.35,0.09-0.69,0.5-0.99,0.66l0.01-0.07c-0.83,0.56-0.76,0.69-1.67,1.26l0.12-0.02c-1.21,0.89-0.68,0.08-2.85,1.69
			c0.3-0.27,0.99-0.7,1.39-1.02c-0.32,0.21-1.13,0.66-1.27,0.8c0.13-0.08,0.29-0.21,0.43-0.26c-0.36,0.32-0.89,0.66-1.57,1.01
			c0.02-0.42-0.95,0.63-6.43,2.17c0.59,0.04-0.44,0.14-0.29,0.31c-0.09-0.03-0.7,0.31-0.52-0.1c0.5-0.03-0.71,0.28-0.05,0.22
			c-0.55,0.09-0.85,0.04-1.66-0.02c0.12,0.06-0.25,0.16-0.7,0.21l0.65-0.02c-1.08,0.32-0.98-0.02-2.08,0.25l0.34-0.11
			c-1.18,0.03-1.11,0.38-6.82,0.5l0.11-0.05c-0.44,0.03-0.17-0.02-1.82,0l0.3-0.05c-1.35-0.12-2.09,0.05-3.23,0.01
			c0.01-0.15-1.05-0.28-1.58-0.34l0.02-0.02c-2.14-0.26-0.06,0.28-8.7-0.67c-0.3,0.03-0.91,0.11-3.36-0.11l0.26-0.02l-0.52,0.01
			l0.19,0.01c-0.85-0.02-2.21-0.01-4.97,0.05l0.02-0.1c-0.97,0-1.45-0.01-2.65,0.04l0.24-0.08c-2.26,0.33-1.07,0.17-5.18,0.43
			c0.02-0.02,0.05-0.07,0.24-0.09c-0.55,0.04-2.15,0.07-1.93,0.25c-0.03-0.13-1.21,0.08-1.84,0.21l0.09-0.11
			c-0.06,0.05-0.8,0.18-2.37,0.42l0.26-0.09c-0.89,0.23-0.27-0.03-5.06,0.61c0.8-0.12,1.45-0.19,1.39-0.17
			c-0.01-0.09-0.66-0.02-0.16-0.21c-0.52,0.09,0.03,0.07-2.1,0.44l0.06-0.02c-0.87,0.15-0.49,0.02-1.99,0.23
			c0.04,0.09-0.38,0.15,0.02,0.16c-0.1,0.01,0.16-0.03,0.41-0.08l-0.02,0.01c0.21-0.03,0.49-0.07,0.8-0.12
			c-2.18,0.56-1.64,0.06-6.07,1.02c0.16-0.05,0.35-0.08,0.43-0.12c-0.04-0.03-1.37,0.49-2.34,0.46l0.12-0.06
			c-1.93,0.15-3.01,0.89-5.15,1.05c0.15-0.01,0.22-0.02,0.26-0.01c-2.94,0.46,0.97-0.23-12.19,2.55l0.18,0.03
			c-0.45,0.09-1.41,0.36-1.35,0.26c-0.31,0.26-4.13,0.55-3.16,0.85C11.71,152.26,11.65,152.8,13.54,152.38z M182.22,96.37
			c-0.07,0.09-0.25,0.38-0.24,0.18C181.97,96.6,182.11,96.46,182.22,96.37z M181.62,97.1c-0.06,0.08,0,0-0.15,0.19
			C181.51,97.25,181.55,97.19,181.62,97.1z M180.4,97.58c0.03-0.14,0-0.1,0.17-0.24C180.53,97.41,180.55,97.33,180.4,97.58z
			 M43.51,146.37c-0.07,0.03,0.21,0.01,0.41,0.01C43.76,146.41,43.32,146.51,43.51,146.37z M42.61,146.57c-0.14,0.03,0,0-0.33,0.05
			C42.35,146.62,42.45,146.6,42.61,146.57z M40.93,146.17c0.19-0.11,0.1-0.07,0.4-0.08C41.23,146.12,41.32,146.07,40.93,146.17z"
              />
              <path
                className="st10"
                d="M298.58,185.55c-0.17-0.02-0.25,0.04-0.63,0.12c0.2-0.13,0.68-0.35,1.11-0.51
			c-0.76,0.35,0.05,0.12,0.41,0.03c-0.07,0,0.04-0.07,0-0.08c0.96-0.21,0.09-0.21,1.37-0.59c-0.43,0.31-0.09,0.05,0.84-0.21
			c0.71-0.25,1.3-0.74,1.96-0.93c-0.29,0.15-0.69,0.35-0.98,0.5c0.72-0.21,0.79-0.27,1.3-0.48l-0.05,0.02l1.18-0.48l-0.31,0.11
			c0.75-0.63,1.75-0.8,2.79-1.54c-1.02,0.72-0.14,0.17-0.18,0.31c0.3-0.13,0.75-0.33,0.64-0.24c0.51-0.36,1.38-0.44,10.88-7.02
			c-0.08,0.11,0.01,0-0.39,0.46c0,0,0,0,0,0c-0.25,0.25,2.09-1.85,1.11-0.82c0.64-0.46,1.55-1.31,1.68-1.53
			c-0.4,0.32-0.18,0.17-1.21,1c0.13-0.1,0.25-0.21,0.37-0.32c-0.51,0.17-1.58,1.21-2.38,1.8c0.33-0.49,0.69-0.77-0.48,0.15
			c-0.77,0.48-0.03-0.08-1.55,1.12c0.09-0.11-0.15-0.02,0.21-0.25c-1.07,0.64-1.63,1.18-2.09,1.35l0.08-0.06
			c-2.93,1.71-0.74,1.13-8.86,4.89c0,0,0.01-0.01,0.01-0.01c-1.22,0.55-0.61,0.49-4.91,2.03l0.02-0.03
			c-0.14,0.19-1.15,0.55-1.87,0.85c0-0.08,0.12-0.16,0.47-0.32c-0.08,0.03-0.22,0.03-1.44,0.62c0.53-0.45,0.28-0.14-2.93,1.04
			c-0.11,0.04-0.38,0.16-0.6,0.2l0.48-0.23c-0.45,0.1-1.05,0.43-0.45,0.09c-2.48,0.65-1.09,0.35-5.48,1.46l0,0
			c-1.58,0.53,0,0-4.98,0.92c-0.07-0.18-0.55,0.15-5.3,0.62c0.74-0.14-0.26-0.03,0.76-0.12c-0.97,0.07-1.19,0.04-2.22,0.24
			c0.35-0.11-0.74-0.08-1.19-0.04l0.68,0.04c-0.52,0.03-0.97,0.04-1.43,0.05l0.24-0.13c-1.98-0.03-4.4,0.18-9.09,0.05
			c1.16,0.39-2.48-0.52-1.87-0.22c-1.7-0.39,0.66,0.41-5.59-0.84c-1.09-0.11-2.01-0.37-2.62-0.52c0.19,0.04,0.38,0.06,0.57,0.08
			c-0.35-0.03-0.62-0.1-0.9-0.17l0.3,0.16c-0.33-0.09-0.61-0.16-0.59-0.19c-0.86,0.04-0.16,0.2-1.94-0.31
			c1.25,0.33,0.14-0.05,0.79,0.06c-1.69-0.54-0.38-0.17-2.44-0.49c0.19-0.02-0.29-0.19-0.51-0.23c0.42,0.1-0.3,0.12-1.68-0.33
			l0.51,0.18c-0.83-0.12-2.34-0.7-5.86-2.17c0.28,0.2,0.34,0.2-0.45-0.01l1.79,0.62l-1.18-0.32c0.53,0.2,1.15,0.38,1.46,0.51
			c-0.56-0.14-0.5-0.14-0.27,0.02c-0.69-0.4-1.48-0.48-2.29-0.88l0.1-0.18c-1.23-0.58-2.27-0.84-3.87-1.57l0.51,0.26
			c-0.25-0.01-1-0.43-1.53-0.65c-1.66-0.96-0.01-0.02-5.25-2.46c0.4,0.12-0.13-0.2,0.71,0.16c-0.41-0.2-0.77-0.4-0.8-0.36
			c0.34,0.19-0.86-0.58-2.59-1.12c-1.35-0.89,0.09-0.09-7.23-4.07c0.03,0,0.04,0,0.05,0c-2.48-1.28,0.02-0.04-3.86-2.21l0.21,0.1
			c-0.99-0.56-0.13-0.16-5.85-2.87c0.03-0.02,0.05-0.02,0.08-0.03c-0.21-0.03-0.68-0.19-1.58-0.9l0.87,0.43
			c-0.39-0.34-1.13-0.56-1.58-0.82l0.08-0.02c-1.34-0.66-1.43-0.52-2.88-1.22l0.13,0.11c-2.07-0.8-0.73-0.71-4.53-1.97
			c0.58,0.14,1.71,0.58,2.43,0.82c-0.53-0.23-1.8-0.77-2.1-0.83c0.22,0.08,0.5,0.17,0.7,0.28c-3.73-1.17-3.02-1.56-12.27-4.23
			c0.77,0.38-0.68-0.14-0.58,0.1c-0.3-0.06,0.02,0.01-0.79-0.18c0,0,0.01,0.01,0.05,0.01c-0.86-0.22-1.23-0.45-2.43-0.85
			c0.15,0.1-0.43,0.04-1.11-0.09l0.94,0.25c-1.68-0.14-1.41-0.43-3.11-0.53l0.54-0.01c-1.08-0.07-0.08-0.29-10.29-0.49l0.16-0.05
			c-0.65,0.06-0.28-0.07-2.73,0.07l0.44-0.07c-2.03,0.01-3.11,0.21-4.8,0.38c0-0.15-1.6-0.1-2.4-0.07l0.03-0.02
			c-3.2,0.29-0.06,0.1-12.88,1.56c-2.53,0.58-2.25,0.3-12.26,2.12l0.01-0.1c-1.42,0.21-2.12,0.32-3.87,0.63l0.34-0.13
			c-3.31,0.77-1.57,0.41-7.57,1.33c0.02-0.03,0.07-0.07,0.35-0.12c-0.81,0.11-3.15,0.32-2.83,0.48c-0.04-0.12-1.78,0.22-2.71,0.36
			l0.13-0.11c-0.18,0.04-0.46,0.15-3.51,0.53l0.39-0.09c-1.87,0.18,0.24,0.08-7.52,0.43c1.19-0.05,2.15-0.09,2.06-0.08
			c0-0.09-0.97-0.06-0.21-0.22c-0.78,0.06,0.03,0.07-3.15,0.24l0.1-0.01c-1.29,0.03-0.72-0.04-2.96-0.03c0.04,0.1-0.59,0.07,0,0.16
			c-0.14-0.01,0.24-0.01,0.62-0.03l-0.03,0.01c0.32,0,0.73-0.01,1.2-0.02c-3.38,0.23-0.9,0.03-9.09-0.3c0.24,0,0.53,0.02,0.66,0.01
			c-0.05-0.05-2.93-0.03-3.51-0.31l0.2-0.02c-4.85-1.66-2.83,2.28-24.22-8.16l0.16,0.2c-0.55-0.4-1.78-1.21-1.64-1.21
			c-0.87-0.23-4.39-4.05-3.86-2.92c-0.55-0.57-1.17-0.41,1.06,1.5l-0.71-0.6c1.13,0.87-0.34,0.53,7.52,5.18
			c0.29,0.09-0.79,0.14,4.79,2.39c-0.75,0-0.44-0.09,2.27,1.05c0-0.11,1.4,0.42,1.17,0.22c0.2,0.31,3.95,1.07,2.66,1.07
			c2.58,0.27,0.03,0.28,7.06,1.36c-0.05-0.29-0.1-0.18,0.91-0.02c-0.28-0.05-0.31-0.02-0.44-0.02c-1.99-0.1,3.04,0.6,0.72,0.22
			c1.38,0.72,1.53,0.28,8.27,0.83l-0.38,0.11c1.43,0.11,0.83-0.16,2.32-0.01c3.75-0.22,1.35,1,19.33-0.95l-0.28,0.07
			c1.15-0.11,1.27-0.38,2.35-0.43l-0.2,0.05c1.34-0.23,0.59,0.13,16.2-2.52c0.28,0.02,1.25-0.06,4.16-0.66
			c-0.04,0.05-0.42,0.13-0.72,0.2c2.2-0.44,0.44-0.1,4.19-0.69l-0.17,0.1c1.69-0.25,3.57-0.53,5.46-0.82
			c-0.6,0.09-0.36-0.02,1.15-0.11c1.4,0.01,2.78-0.7,16.93-1.25c2.9-0.08,0.07-0.03,7.64,0.51l-0.11,0
			c2.17,0.4,3.49-0.22,13.29,2.81l-0.08-0.09c3.62,1.17,0.47-0.13,14.38,5.96l-0.53-0.14c2.74,1.2,5.43,2.82,8.06,4.12
			c-0.17-0.06-0.19-0.03-0.44-0.17c0.43,0.2,0.25,0.3,5.1,2.75c1.26,0.69-0.48-0.13,4.48,2.35c-0.15,0.11,1.65,1.02,2.3,1.28
			c-0.23-0.07,0.51,0.33,0.24,0.26c0.71,0.37,1.37,0.68,1.28,0.54c1.89,0.96-1.02-0.01,9.53,4.33c-0.1-0.02-0.19-0.04-0.29-0.06
			c2.91,1.14,1.95,0.96,7.99,2.78c-0.01,0.01-0.1-0.01-0.21-0.04c0.76,0.18-0.81-0.21,1.75,0.21c-0.64,0.06,1.71,0.46,0.72,0.49
			c0.23,0.04,0.52,0.08,0.48,0.02c0.33,0.09,0.43,0.15,0.08,0.12c1.25,0.26,0.82,0.08,1.94,0.34c-0.01,0.03-0.24-0.01-0.37,0
			c0.59,0.05,1.16,0.17,2.11,0.36c-0.06,0.01,0.04,0.06-0.24,0.01c1.65,0.26,2.84,0.29,4.63,0.65c-0.25,0.01-0.53,0.16,1.68,0.32
			l-0.39-0.1c0.86,0.08,1.63,0.06,2.36,0.18l-0.64-0.01l1.61,0.13l-0.91-0.14c0.87,0.02-0.08-0.17,1.06-0.03
			c-0.23-0.01,0.86,0.1,1.3,0.16l0,0c1.65-0.05,0.66,0.25,4.02,0.09c-0.44,0.19,1.3,0.17,1.66,0c-0.11,0.01-0.06,0.02-0.29,0.04
			c1.08-0.04,1.84-0.04,2.59-0.05l-0.31,0.02c2.34-0.08,0.01,0.03,6.83-0.61l-0.34,0.02c1.01-0.38,0.78,0.31,6.44-1.07l-0.22,0.1
			c0.75-0.33,3.9-0.7,9.71-2.6C296.83,186.22,296.74,186.28,298.58,185.55z M112.1,166.57c0.33-0.09,0.15-0.05,0.6-0.03
			C112.54,166.56,112.7,166.52,112.1,166.57z M93.85,164.81c0.06,0.02,0.1,0.04,0.12,0.05C93.92,164.84,93.89,164.82,93.85,164.81z
			 M114.49,167.18c-0.21,0-0.39,0.01-0.5,0.01C114.09,167.19,114.24,167.19,114.49,167.18L114.49,167.18z M115.85,167.08
			c-0.1,0.03,0.3,0.03,0.6,0.04C116.21,167.14,115.58,167.2,115.85,167.08z"
              />
              <path
                className="st10"
                d="M420.92,138.05L420.92,138.05c-0.81,0.38,0,0-2.63,0.71c0.14-0.22-0.93,0.22-2.86,0.13
			c0.4-0.02-0.14-0.07,0.41,0c-0.52-0.08-0.62-0.15-1.19-0.11c0.2-0.07-0.37-0.14-0.62-0.15c0.11,0.05,0.23,0.07,0.35,0.1
			c-0.28,0-0.52-0.07-0.76-0.12l0.15-0.09c-1.04-0.3-2.29-0.58-4.69-1.42c0.36,0.46-1.12-0.75-0.94-0.41
			c-0.58-0.28-0.94-0.36-1.56-0.61c-0.01,0.04-1.86-0.2-1.38-0.48c-0.61-0.07-1.11-0.3-1.47-0.36c0.11,0,0.22,0,0.33,0.01
			c-0.2-0.02-0.36-0.03-0.52-0.05l0.18,0.11c-0.19-0.04-0.35-0.06-0.34-0.09c-0.45,0.16-0.07,0.23-1.12,0.08
			c1.08,0.1,0.47-0.46-0.96,0c0.1-0.06-0.2-0.12-0.32-0.08c0.25-0.01-0.1,0.18-0.93,0.31l0.3-0.02c-0.43,0.29-1.39,0.07-3.4,0.83
			c0.2,0,0.22-0.04-0.15,0.23c0.3-0.18,0.63-0.32,0.95-0.46l-0.58,0.36c0.29-0.12,0.6-0.29,0.79-0.35
			c-0.28,0.18-0.25,0.15-0.09,0.16c-1.34,0.55-1.05-0.1-3.31,1.54l0.28-0.14c-0.06,0.14-0.5,0.33-0.73,0.55
			c-0.87,0.46-0.01-0.04-2.37,2.12c0.11-0.21-0.17-0.01,0.15-0.4c-0.18,0.18-0.35,0.33-0.32,0.37c0.18-0.18-0.51,0.38-0.9,1.27
			c-0.62,0.61-0.26-0.18-2.74,3.61c0-0.02-0.01-0.02-0.01-0.03c-0.71,1.36-0.06-0.04-1.28,2.07l0.05-0.12
			c-0.32,0.52-0.15,0.13-1.42,3.26c-0.02-0.03-0.03-0.04-0.05-0.06c0.02,0.12-0.04,0.4-0.51,0.85l0.23-0.48
			c-0.23,0.19-0.3,0.62-0.44,0.86l-0.03-0.06c-0.34,0.74-0.22,0.81-0.61,1.59l0.08-0.06c-0.4,1.16-0.49,0.3-1.16,2.42
			c0.06-0.33,0.31-0.94,0.44-1.33c-0.14,0.28-0.47,0.95-0.48,1.12c0.05-0.12,0.09-0.27,0.16-0.37c-0.57,1.63-0.21,0.47-3.71,5.91
			c0.43-0.25-0.21,0.31,0.01,0.37c-0.08,0.14,0,0-0.26,0.36c0,0,0,0,0,0c-0.27,0.31-0.61,0.48-1.12,0.83
			c0.11-0.02-0.05,0.24-0.3,0.52l0.4-0.35c-0.47,0.78-0.61,0.51-1.13,1.26l0.14-0.26c-0.8,0.72,0.3-0.02-3.73,4.06l0.03-0.1
			c-0.24,0.26-0.1,0.1-1.07,1.01l0.14-0.2c-0.88,0.64-1.18,1.2-1.9,1.76c-0.09-0.12-0.81,0.35-1.16,0.59l0-0.03
			c-1.55,0.75,0.28,0.57-5.94,3.6c-1.86,1.06,0.03,0.09-6.16,2.62l-0.02-0.1c-0.75,0.19-1.12,0.28-2.03,0.56l0.17-0.12
			c-1.7,0.65-0.77,0.37-4.02,0.98c0.01-0.02,0.03-0.07,0.18-0.1c-0.43,0.09-1.7,0.14-1.52,0.32c-0.03-0.13-0.96,0.1-1.46,0.21
			l0.07-0.11c-0.05,0.05-0.64,0.14-1.9,0.3l0.21-0.07c-0.74,0.05-0.13-0.03-2.21,0.07c-0.83-0.11,0.04,0.12-1.86-0.1
			c0.65,0.03,1.17,0.06,1.12,0.07c0-0.09-0.52-0.13-0.1-0.23c-0.42,0.01,0.01,0.07-1.71,0.06l0.05-0.01
			c-0.7-0.02-0.39-0.07-1.59-0.2c0.01,0.1-0.33,0.05-0.02,0.16c-0.08-0.01,0.13,0.01,0.33,0.01l-0.02,0.01
			c0.17,0.02,0.39,0.04,0.64,0.06c-1.78,0.03-1.3-0.21-4.89-0.64c0.13,0.01,0.28,0.04,0.36,0.03c-0.02-0.05-1.19,0-1.88-0.34
			l0.11-0.02c-1.44-0.57-2.52-0.3-4.07-1.09c0.11,0.05,0.16,0.08,0.18,0.1c-2.04-1.16,0.39,0.85-9.03-4.24l0.07,0.13
			c-0.3-0.21-1.01-0.59-0.91-0.63c-0.51-0.04-2.43-2.17-2.24-1.39c-0.34-0.32-0.76,0.02,0.52,0.93l-0.42-0.29
			c0.45,0.54,1.98,1.04,1.82,1.28c0.23,0.01,0.36,0.5,2.41,1.33c-0.53-0.09,0.9,0.52,0.69,0.63c0.41,0.03,1.25,0.54,1.88,0.75
			c-0.04,0-0.12-0.01-0.16-0.05c-0.16,0.29,1.89,0.39,1.34,0.75c0.02-0.11,0.77,0.21,0.67,0.07c0.29,0.21,0.26,0.16,0.38,0.33
			c-0.18-0.25,1.86,0.54,0.97,0.46c1.36,0.09,0.03,0.15,3.79,1.01c0.01-0.24-0.04-0.2,0.52-0.05c-0.15-0.04-0.17-0.01-0.25,0
			c-1.43-0.06,1.94,0.58,0.37,0.19c0.48,0.6,1.9,0.47,4.44,0.92l-0.22,0.1c2.31-0.24-0.76,0.96,11.82,0.22l-0.15,0.05
			c0.64-0.03,0.69-0.28,1.28-0.27l-0.11,0.04c1.18-0.14,4.77-0.81,7.68-1.72c-0.05-0.09,0.04,0.1,1.02-0.43
			c0.16,0,0.68-0.11,2.15-0.84c-0.01,0.05-0.2,0.14-0.34,0.23c1.09-0.55,0.2-0.09,2.11-1l-0.06,0.11c0.86-0.38,1.75-0.94,2.68-1.44
			c-0.28,0.17-0.21,0.05,0.56-0.3c1.24-0.84,0.03,0.54,7.39-5.7c0.68-0.76-0.04,0.02,3.02-2.95l-0.03,0.05
			c1.77-2.37,0.26,0.19,4.58-5.97l-0.08,0.02c0.88-1.42,0.11-0.5,3.92-7.6l-0.02,0.31c0.59-1.53,1.47-2.98,2.13-4.42
			c-0.02,0.1,0.01,0.11-0.07,0.25c-0.03,0.04,0.56-0.8,1.55-2.71c0.34-0.44,0.26-0.38,1.51-2.25c0.1,0.13,0.75-0.72,0.96-1.03
			c-0.05,0.12,0.25-0.21,0.2-0.05c0.28-0.32,0.54-0.6,0.43-0.61c1.7-1.27,1.08-1.16,4.38-3.41c-0.37,0.87-0.64-0.28,4.09-1.33
			c0,0.01-0.1,0.04-0.19,0.07c0.58-0.16-0.42,0.08,0.97-0.42c-0.3,0.22,0.95,0.01,0.45,0.29c0.12-0.02,0.27-0.06,0.25-0.11
			c0.18,0.01,0.24,0.03,0.06,0.09c0.66-0.03,0.43-0.1,1.04-0.08c-0.01,0.03-0.13,0.04-0.19,0.08c0.31-0.06,0.63-0.08,1.13,0.02
			c-0.03,0.01,0.02,0.05-0.13,0.02c0.87,0.13,1.52,0.14,2.44,0.5c-0.16,0.04-0.27,0.09,0.82,0.43l-0.18-0.13
			c0.44,0.16,0.85,0.21,1.21,0.4l-0.34-0.07l0.82,0.29l-0.44-0.23c0.46,0.11,0.01-0.17,0.58,0.09c-0.12-0.03,0.43,0.17,0.63,0.33
			l0,0c0.93,0.24,0.2,0.35,2.07,0.73c-0.29,0.1,0.62,0.36,0.86,0.29c-0.06-0.01-0.04,0.01-0.16-0.01c0.67,0.13,1.11,0.29,1.57,0.42
			l-0.37-0.05c1.29,0.24,0.01,0.06,3.76,0.19l-0.19-0.01c1.13-0.3,0.7,0.27,3.5-0.72l-0.11,0.08c1.74-0.8,1.54-0.32,4.83-2.62
			c-0.16,0.17-0.2,0.22,0.62-0.5c-0.11-0.01-0.13,0.05-0.33,0.14c0.07-0.12,0.26-0.34,0.45-0.5c-0.31,0.34,0.09,0.1,0.27,0
			c-0.04,0-0.01-0.06-0.04-0.07c0.5-0.24-0.04-0.18,0.57-0.58c-0.09,0.29-0.04,0.06,0.4-0.26c0.28-0.3,0.44-0.7,0.76-0.91
			c-0.12,0.14-0.26,0.32-0.38,0.47c0.36-0.21,0.37-0.27,0.6-0.47l-0.02,0.02c0.17-0.16,0.33-0.33,0.49-0.5l-0.14,0.12
			c0.14-0.56,0.65-0.82,0.94-1.47c-0.28,0.63,0,0.14,0.06,0.25c0.13-0.13,0.31-0.32,0.29-0.24c0.13-0.21,0.01-0.27,0.79-1.03
			c-0.83,1,1.1-1.81,2.64-5.15c0,0.08,0,0-0.01,0.35c0,0,0,0,0,0c0,0.23,0.36-1.53,0.31-0.69c0.14-0.41,0.37-1.04,0.33-1.2
			l-0.14,0.24c0.07,0.03,0.14,0.2-0.19,0.55l0.08-0.26c-0.29,0.18-0.37,1.02-0.63,1.5c-0.05-0.34,0.05-0.59-0.26,0.16
			c-0.27,0.41-0.07-0.04-0.46,0.93c0.01-0.08-0.12,0.01,0-0.19c-0.39,0.56-0.43,1.01-0.65,1.18l0.02-0.05
			c-1.06,1.7-0.19,1.11-3.11,4.5c-0.08-0.11,0.14-0.06-0.74,0.93c-0.01-0.06-0.83,0.78-1.27,1.12l0-0.03
			c0.01,0.16-0.44,0.53-0.74,0.83c-0.04-0.07,0-0.14,0.15-0.32c-0.03,0.06-0.06-0.03-0.6,0.63c0.09-0.44,0.12-0.17-1.29,1.08
			c-0.04,0.04-0.17,0.15-0.28,0.2l0.2-0.21c-0.26,0.11-0.44,0.39-0.23,0.09C422.43,137.22,423.13,136.93,420.92,138.05z
			 M340.98,178.44c0.16-0.06,0.09-0.05,0.32,0.01C341.22,178.45,341.31,178.42,340.98,178.44z M341.95,179.16
			c0.06,0,0.14,0,0.27,0.01C342.11,179.17,342.22,179.17,341.95,179.16z M342.97,179.13c-0.06,0.02,0.16,0.05,0.33,0.08
			C343.16,179.22,342.79,179.24,342.97,179.13z"
              />
              <path
                className="st10"
                d="M366.79,115.21l-0.06,0.1c0.23,0.04,0.28-0.37,0.44-0.54c-0.02,0.27,0.16,0.22,0.51-0.29
			c0,0.04,0.09,0.05,0.01,0.11c0.26-0.15,0.24-0.41,0.4-0.42l-0.01,0.02c0.63-0.41,0.32-0.72,1.68-1.65
			c0.09,0.11-0.11,0.02,0.34-0.38c0.01,0.04,0.41-0.27,0.63-0.39l0.01,0.02c-0.05-0.13,0.16-0.27,0.28-0.41
			c0.04,0.06,0.04,0.11-0.01,0.2c0-0.03,0.06,0.03,0.23-0.3l0.03,0.1c0.17-0.15,0.27-0.38,0.53-0.51c0.05,0-0.17-0.12,0.02-0.24
			l-0.05,0.14c0.02-0.06,0.31-0.25,0.11-0.03c0.47-0.27,0.2-0.12,1.02-0.83l0,0c0.24-0.33,0,0,0.96-0.68
			c0.06,0.13,0.31-0.22,1.04-0.68c-0.11,0.14,0.08,0.01-0.13,0.13c0.2-0.1,0.28-0.08,0.43-0.29c-0.02,0.12,0.2,0,0.28-0.07
			l-0.17,0.03c0.1-0.07,0.19-0.12,0.28-0.17l0.01,0.13c0.46-0.21,0.86-0.55,1.76-1.16c-0.32-0.13,0.6-0.02,0.4-0.17
			c0.27-0.08,0.37-0.21,0.64-0.3c-0.08-0.02,0.59-0.66,0.58-0.2c0.19-0.18,0.45-0.16,0.58-0.22l-0.1,0.09
			c0.06-0.05,0.12-0.08,0.18-0.11l-0.1-0.04c0.08-0.02,0.14-0.05,0.15-0.02c0.09-0.17-0.15-0.26,0.35-0.33
			c-0.3,0.1,0.05,0.08-0.15,0.21c0.34-0.08,0.2,0.04,0.53-0.33c-0.01,0.07,0.12,0.08,0.16,0.04c-0.09,0.05-0.07-0.14,0.29-0.32
			l-0.12,0.03c0.14-0.25,0.53-0.16,1.41-0.39c-0.1-0.05-0.11-0.03,0.04-0.17l-0.39,0.21l0.22-0.21c-0.12,0.05-0.24,0.15-0.32,0.16
			c0.1-0.11,0.09-0.09,0.01-0.13c0.63-0.22,0.41,0.27,1.49-0.39l-0.14,0.01c0.02-0.1,0.23-0.1,0.34-0.18
			c0.01,0.07,0.1,0.06,0.17,0.09c-0.02-0.05,0.29-0.09,0.28-0.23c0.39,0,0.22,0.1,0.81-0.35c-0.07,0.1,0.08,0.1-0.1,0.22
			c0.1-0.03,0.2-0.05,0.18-0.1c-0.14,0.07,0.37-0.05,0.56-0.41c0.28,0.09,0.46,0.04,1.89-0.39c-0.01,0.02,0.01,0.03-0.03,0.04
			c0.68-0.24,0.01,0.03,1.05-0.15l-0.05,0.02c0.26,0.01,0.11-0.04,1.43-0.51c0,0.04,0,0.06,0,0.08c0.03-0.07,0.13-0.18,0.43,0.01
			l-0.22,0.04c0.13,0.1,0.29-0.05,0.41-0.05l-0.01,0.06c0.34-0.07,0.32-0.21,0.69-0.26l-0.04-0.03c0.49-0.18,0.21,0.15,1.13-0.18
			c-0.12,0.12-0.4,0.2-0.56,0.28c0.13-0.01,0.45-0.06,0.51-0.14c-0.05,0.02-0.11,0.06-0.17,0.05c1.07-0.3,0.03-0.09,2.99-0.1
			c0.05-0.3,0.11-0.38,0.89,0.14c-0.04-0.08,0.09-0.11,0.25-0.08l-0.22-0.1c0.39-0.12,0.34,0.18,0.72,0.06l-0.12,0.07
			c0.28,0.05,0.36-0.07,2.36-0.08l-0.04,0.05c0.26-0.05,0.12,0.06,0.63,0l-0.11,0.05c0.46,0.11,0.72-0.08,1.12-0.08
			c-0.02,0.16,0.34,0.23,0.52,0.28l-0.01,0.02c0.7,0.24,0.1-0.4,2.94,0.45c-0.02-0.04,0.11-0.17,0.38-0.03
			c-0.05-0.02,0.3,0.39,0.56,0.03c0.14,0.26,0.75,0.08,2.71,0.88l-0.1,0.04c0.77-0.02,0.33-0.07,1.73,0.4
			c-0.01,0.02-0.04,0.05-0.1,0.04c0.18,0.07,0.66,0.34,0.66,0.12c-0.04,0.13,0.39,0.14,0.61,0.17l-0.07,0.08
			c0.02-0.03,0.28,0.01,0.83,0.14l-0.11,0.02c0.31,0.13,0.27-0.15,0.67,0.29c-0.1-0.03,0.35-0.02,0.21,0.05
			c0.28,0.25,0.04-0.11,0.71,0.36c-0.25-0.12-0.45-0.22-0.43-0.21c-0.04,0.08,0.16,0.19-0.05,0.23c0.18,0.05,0.02-0.07,0.72,0.18
			l-0.02,0c0.28,0.11,0.13,0.12,0.6,0.34c0.03-0.09,0.16-0.02,0.07-0.15c0.03,0.02-0.05-0.02-0.14-0.04l0.01,0
			c-0.07-0.03-0.15-0.07-0.25-0.11c0.78,0.1,0.38,0.34,1.9,0.91c-0.28-0.03-0.25-0.07,0.59,0.31l-0.06,0.01
			c0.46,0.51,1.09,0.26,1.59,0.86c-0.04-0.04-0.05-0.06-0.05-0.08c0.32,0.43,0.34,0.13,3.49,2.48v-0.09
			c0.75,0.7,0.34,0.73,1.27,0.33l0.17,0.13c-0.1-0.13-0.07-0.1-0.57-0.33c0.22-0.21-0.41-0.06-0.15-0.29
			c-0.12,0.02-0.08-0.33-0.99-0.64c0.29-0.02-0.35-0.27-0.19-0.4c-0.23,0.04-0.51-0.28-0.79-0.38c0.02,0,0.06,0,0.08,0.02
			c-0.12-0.1-0.13-0.18-0.2-0.26c0.15,0.1-0.91-0.01-0.27-0.22c-0.05,0.1-0.35-0.1-0.34,0.02c-0.08-0.15-0.09-0.1-0.09-0.24
			c0,0.21-0.77-0.34-0.34-0.33c-0.57,0-0.01-0.08-1.55-0.68c-0.04,0.11-0.04,0.25-0.27,0.05c0.06,0.04,0.08,0.01,0.12,0
			c0.35,0.17-0.39-0.5-0.11-0.17c0.07-0.5-0.78-0.4-1.71-0.92l0.14-0.08c-1.01-0.22-1.61-0.98-4.68-1.99l0.07-0.01
			c-0.24-0.13-0.36,0.1-0.57-0.08l0.05,0c-0.21-0.07-0.89-0.2-3.21-1.01l0.02-0.11c-0.01,0.04-0.16,0.04-0.46-0.1
			c-0.12,0.2,0.32-0.15-0.97-0.2c0.02-0.04,0.11-0.03,0.19-0.04c-0.31,0.04-0.03-0.12-0.31-0.09c0.03,0.03-0.03,0.06-0.04,0.08
			c-0.12-0.09-0.41-0.15-0.64-0.16l0.06-0.06c-0.38-0.09-0.81-0.13-1.23-0.2c0.03,0.06-0.12,0.27-0.12,0.04
			c-0.14,0.05,0.16-0.07-0.19-0.12c-0.75-0.19,0.06,0.15-3.01-0.29c-0.39-0.02-0.5-0.04-1,0.01c-0.7,0.08-0.02,0.01-1.8-0.15
			l0.02-0.01c-2.57,0.36,2.74-0.6-6.85,0.56l0.1-0.13c-0.68,0.24-1.43,0.24-2.08,0.52c0.03-0.03,0.03-0.07,0.09-0.08
			c-0.16-0.05-0.53,0.11-0.52,0.18c-0.14-0.06-0.21-0.12-0.8,0.17c-0.23,0.03-0.24,0.01-1.12,0.4c-0.03-0.16-0.48-0.01-0.61,0.12
			c0.04-0.06-0.15,0-0.11-0.09c-0.18,0.06-0.33,0.14-0.28,0.21c-0.62,0.05-0.47-0.01-2.28,0.9c0.37-0.48,0.79-0.52-1.78,0.69
			c0-0.01,0.03-0.05,0.06-0.08c-0.18,0.16,0.02-0.03-0.29,0.45c0.04-0.24-0.4,0.12-0.3-0.21c-0.04,0.03-0.09,0.09-0.06,0.13
			c-0.08,0.02-0.12,0-0.06-0.08c-0.26,0.15-0.13,0.18-0.37,0.3c-0.01-0.03,0.03-0.07,0.04-0.12c-0.09,0.12-0.19,0.24-0.4,0.32
			c0-0.02-0.03-0.04,0.03-0.07c-0.35,0.18-0.51,0.46-0.93,0.59c0.04-0.19-0.15,0.02-0.23-0.09l-0.19,0.14l0.11,0.01
			c-0.18,0.1-0.29,0.27-0.46,0.31l0.1-0.12l-0.32,0.21l0.21-0.06c-0.15,0.16,0.1,0.13-0.15,0.23c0.04-0.04-0.18,0.1-0.3,0.08
			c0.01-0.07,0,0-0.36,0.06c-0.03,0.14-0.4,0.25-0.44,0.42c0.06-0.19-0.31-0.06-0.34,0.18c0.02-0.02,0-0.02,0.04-0.07
			c-0.3,0.21-0.44,0.26-0.55,0.29l0.07-0.06c-0.4,0.2,0.02-0.04-1.31,0.92l0.07-0.03c0.06,0.19-0.31,0.11-0.44,0.3
			c-0.17-0.12,0.01-0.08-0.8,0.59l0.02-0.07c-0.59,0.53-0.57,0.33-1.86,1.44c0.07-0.12,0.12-0.18-0.26,0.22
			c0.08,0.04,0.08,0,0.18-0.01c-0.01,0.07-0.08,0.18-0.16,0.25c0.11-0.17-0.09-0.1-0.18-0.08c0.03,0.01,0.02,0.05,0.04,0.06
			c-0.27,0.04,0.08,0.15-0.22,0.28c-0.01-0.14,0.02-0.04-0.22,0.06c-0.13,0.12-0.15,0.35-0.32,0.39c0.04-0.07,0.12-0.14,0.16-0.21
			c-0.21,0.03-0.2,0.07-0.31,0.14l0.01-0.01l-0.25,0.17l0.08-0.03c0.01,0.33-0.3,0.34-0.4,0.66c0.07-0.13-0.09-0.36-0.54,0.19
			c-0.02-0.07-0.69,0.9-1.7,1.96c-0.03-0.27,0.06-0.33-0.23,0.05c-0.1,0.16-0.27,0.39-0.23,0.5l0.1-0.07
			C366.6,115.4,366.52,115.24,366.79,115.21z M413.05,106.14c-0.06,0.03-0.07,0.05-0.14-0.04
			C412.95,106.1,412.91,106.12,413.05,106.14z M412.92,105.37c-0.02-0.01-0.05-0.02-0.11-0.04
			C412.86,105.34,412.82,105.33,412.92,105.37z M412.5,105.26c0.03-0.01-0.06-0.07-0.11-0.13
			C412.33,105.14,412.67,105.2,412.5,105.26z"
              />
              <polygon
                className="st10"
                points="384.84,103.15 384.84,103.15 384.87,103.14 		"
              />
              <path
                className="st10"
                d="M303.22,86.85l0.27-0.11c-1.49,0.1-3.05,0.01-4.5,0.19c0.09-0.03,0.09-0.07,0.23-0.08
			c0.06,0.03-0.92-0.11-2.87,0.25c-0.51,0.04-0.44-0.04-2.48,0.38c0.02-0.16-0.97,0.03-1.3,0.15c0.11-0.07-0.31,0-0.18-0.09
			c-0.39,0.07-0.74,0.15-0.66,0.22c-1.32,0.14-1.02-0.01-5.02,1.3c0.1-0.07,0.18-0.12,0.26-0.16c-0.78,0.3-0.9,0.14-4.08,1.51
			c0-0.01,0.08-0.07,0.16-0.12c-0.48,0.3,0.35-0.18-0.75,0.65c0.19-0.31-0.83,0.3-0.5-0.12c-0.1,0.06-0.22,0.15-0.17,0.18
			c-0.16,0.06-0.23,0.05-0.09-0.07c-0.56,0.29-0.31,0.28-0.82,0.54c-0.01-0.03,0.08-0.1,0.12-0.16c-0.23,0.2-0.45,0.39-0.89,0.59
			c0.02-0.03-0.05-0.03,0.08-0.1c-0.73,0.39-1.16,0.8-1.95,1.24c0.08-0.14,0.07-0.26-0.79,0.33l0.2-0.05
			c-0.36,0.23-0.63,0.5-0.96,0.67c0.07-0.08,0.16-0.15,0.24-0.22c-0.23,0.14-0.43,0.32-0.64,0.48l0.39-0.22
			c-0.31,0.31,0.13,0.11-0.35,0.41c0.09-0.08-0.34,0.25-0.57,0.32l0,0c-0.64,0.61-0.38-0.05-1.57,1.22
			c0.05-0.29-0.58,0.29-0.64,0.51c0.04-0.04,0.01-0.03,0.09-0.12c-0.62,0.59-0.88,0.74-1.11,0.89l0.21-0.21
			c-0.77,0.64,0.03-0.06-2.39,2.4l0.12-0.12c-0.11,0.58-0.44-0.08-2.07,2.47l0.03-0.12c-0.95,1.44-0.67,0.71-2.78,4.13
			c0.05-0.21,0.08-0.26-0.32,0.64c0.1-0.03,0.1-0.09,0.24-0.23c-0.01,0.13-0.09,0.38-0.19,0.58c0.14-0.39-0.12-0.06-0.24,0.08
			c0.04-0.02,0.03,0.05,0.06,0.05c-0.34,0.37,0.11,0.14-0.27,0.69c-0.02-0.27,0.01-0.06-0.28,0.34c-0.15,0.34-0.11,0.75-0.32,1.03
			c0.05-0.16,0.1-0.36,0.15-0.53c-0.23,0.3-0.22,0.35-0.34,0.6l0.01-0.02l-0.28,0.57l0.09-0.14c0.08,0.52-0.34,0.89-0.33,1.55
			c0.02-0.63-0.06-0.12-0.15-0.2c-0.07,0.15-0.17,0.37-0.18,0.3c-0.04,0.22,0.08,0.25-0.34,1.12c0.33-0.87-0.47,2.65-0.51,5.23
			c-0.2-0.46-0.13-0.58-0.16,0.39c-0.02,0.39,0.09,1.01,0.18,1.12l0.02-0.25c-0.08,0.01-0.21-0.11-0.06-0.53
			c0.01,0.08,0.02,0.16,0.03,0.24c0.16-0.25,0-0.98,0.03-1.47c0.17,0.26,0.19,0.52,0.18-0.23c0.09-0.43,0.08,0.01,0.06-0.94
			c0.03,0.07,0.11-0.05,0.07,0.16c0.11-0.6,0.03-0.99,0.16-1.21l0,0.05c0.32-1.79-0.24-1.01,1.2-4.81c0.12,0.08-0.12,0.11,0.35-1.02
			c0.03,0.05,0.42-0.94,0.72-1.36l0.01,0.02c-0.07-0.14,0.19-0.6,0.33-0.96c0.06,0.05,0.06,0.12,0,0.32c0-0.06,0.07,0.01,0.28-0.74
			c0.09,0.39-0.04,0.2,0.67-1.37c0.02-0.05,0.07-0.19,0.15-0.28l-0.07,0.26c0.16-0.2,0.18-0.5,0.15-0.17
			c0.7-0.98,0.26-0.45,1.54-2.32l0,0c0.25-0.36,0.19-0.4,1.58-1.92c0.19,0.08,0.47-0.63,1.82-1.88c-0.22,0.3,0.13-0.05-0.24,0.3
			c0.36-0.31,0.48-0.35,0.78-0.77c-0.06,0.19,0.33-0.17,0.48-0.35l-0.28,0.19c0.17-0.19,0.33-0.34,0.52-0.48l-0.02,0.17
			c0.39-0.28,0.77-0.62,1.26-1.05c0.1,0.1-0.03,0.04,0.48,0.5c0.31-0.22-0.83-0.13-0.1-0.81c0.03-0.02-0.08,0.06-0.3,0.29
			c0.46-0.57-0.01,0,2.09-1.79c-0.54,0.01,1.13-0.42,0.78-0.5c0.52-0.25,0.76-0.47,1.29-0.75c-0.06-0.06,1.25-1.13,1.13-0.6
			c0.44-0.32,0.91-0.42,1.19-0.55l-0.23,0.16c0.14-0.1,0.26-0.16,0.39-0.23l-0.18,0c0.15-0.06,0.28-0.13,0.29-0.1
			c0.24-0.34-0.09-0.24,0.79-0.58c-0.62,0.27,0.06,0.08-0.37,0.33c0.66-0.26,0.38-0.06,1.15-0.63c-0.05,0.09,0.2,0.04,0.29-0.01
			c-0.2,0.08,0.01-0.19,0.7-0.5l-0.25,0.09c0.32-0.36,1.13-0.38,2.93-0.9c-0.18-0.03-0.2,0,0.15-0.21l-0.86,0.36l0.52-0.31
			c-0.27,0.09-0.54,0.24-0.71,0.29c0.25-0.16,0.23-0.13,0.08-0.15c1.25-0.37,0.83,0.07,3.15-0.79l-0.28,0.04
			c0.09-0.11,0.51-0.15,0.78-0.26c0.89-0.05-0.01,0.02,2.76-0.69c-0.18,0.12,0.12,0.1-0.31,0.25c0.22-0.05,0.42-0.08,0.41-0.12
			c-0.23,0.04,0.59,0.01,1.33-0.43c0.81,0.03-0.01,0.1,4.06-0.37c-0.01,0.01-0.01,0.02-0.02,0.03c1.37-0.16,0,0.04,2.18-0.06
			l-0.11,0.02c0.55,0.01,0.16,0.01,3.19-0.3c-0.01,0.03-0.02,0.05-0.03,0.07c0.09-0.07,0.34-0.14,0.89,0.1l-0.48,0
			c0.24,0.13,0.62,0.01,0.87,0.03l-0.03,0.05c0.73,0,0.75-0.15,1.53-0.11l-0.08-0.05c1.11-0.1,0.42,0.34,2.43,0.08
			c-0.3,0.09-0.89,0.11-1.27,0.15c0.28,0.02,0.96,0.04,1.11-0.02c-0.11,0.01-0.26,0.03-0.37,0.01c1.56-0.16,0.46,0.06,6.31,0.75
			c-0.38-0.26,0.34,0.04,0.29-0.18c0.14,0.01,0-0.01,0.39,0.06c0,0,0,0-0.01-0.01c0.37,0.09,0.66,0.27,1.17,0.54
			c-0.07-0.09,0.22-0.08,0.55,0l-0.45-0.16c0.83,0,0.68,0.28,1.52,0.3l-0.27,0.03c0.78,0.18,0.81,0.04,4.99,0.7l-0.09,0.04
			c0.33,0.02,0.13,0.05,1.32,0.2l-0.22,0.02c0.96,0.25,1.53,0.15,2.35,0.28c-0.03,0.15,0.73,0.36,1.1,0.46l-0.02,0.02
			c1.51,0.44,0.12-0.35,6.23,1.32c2.25,0.36,0.25,0.01,5.95,1.37l-0.04,0.09c0.67,0.21,1,0.33,1.84,0.56l-0.19,0.02
			c1.63,0.27,0.77,0.08,3.61,1.07c-0.02,0.02-0.06,0.05-0.19,0c0.38,0.14,1.41,0.65,1.35,0.41c-0.04,0.13,0.81,0.34,1.26,0.47
			l-0.1,0.06c0.05-0.01,0.58,0.13,1.66,0.56l-0.2-0.03c0.61,0.29,0.1,0.08,1.8,0.88c0.61,0.45,0.02-0.12,1.45,0.86
			c-0.51-0.29-0.91-0.53-0.87-0.52c-0.04,0.08,0.36,0.33-0.03,0.25c0.34,0.17,0.02-0.07,1.4,0.66l-0.05-0.02
			c0.55,0.32,0.28,0.22,1.18,0.83c0.04-0.09,0.28,0.1,0.09-0.14c0.05,0.05-0.1-0.06-0.26-0.15l0.02,0c-0.13-0.08-0.3-0.19-0.5-0.31
			c1.44,0.7,0.95,0.72,3.6,2.62c-0.1-0.06-0.21-0.15-0.27-0.18c0,0.03,0.97,0.53,1.32,1.11l-0.1-0.04c0.78,1.55,1.35-0.6,6.87,10.44
			l0.03-0.14c0.08,0.32,0.35,1,0.25,0.97c0.29,0.21,0.2,3,0.58,2.31c0.01,0.43,0.53,0.47,0.31-0.94l0.07,0.46
			c0.11-0.63-0.69-1.93-0.38-2.01c-0.15-0.14,0.08-0.58-0.89-2.35c0.35,0.36-0.34-0.89-0.13-0.84c-0.3-0.24-0.58-1.1-0.97-1.58
			c0.03,0.02,0.08,0.07,0.1,0.12c0.3-0.14-1.24-1.3-0.58-1.28c-0.08,0.08-0.48-0.56-0.48-0.39c-0.1-0.31-0.11-0.26-0.11-0.45
			c-0.01,0.3-1.14-1.35-0.5-0.85c-1-0.71,0.08-0.17-2.46-2.6c-0.12,0.18-0.07,0.2-0.43-0.19c0.1,0.1,0.13,0.09,0.19,0.12
			c1.08,0.69-1.21-1.35-0.19-0.33c-0.04-0.73-1.28-1.24-3.08-2.73l0.23,0.01c-1.83-0.75,0.32-0.84-9.37-5.23l0.14,0.03
			c-0.52-0.26-0.68-0.06-1.14-0.33l0.1,0.02c-0.39-0.26-4.04-1.44-6.73-2.31c-0.01,0.11,0.01-0.11-0.98-0.19
			c-0.12-0.1-0.56-0.29-2.02-0.52c0.03-0.04,0.22-0.01,0.38,0c-1.08-0.18-0.2-0.03-2.06-0.45l0.1-0.05
			c-0.82-0.21-1.75-0.36-2.68-0.56c0.29,0.06,0.17,0.1-0.55-0.15c-1.32-0.35,0.32-0.08-8.28-1.52c-1.42-0.15-0.04,0-3.73-0.73
			l0.05,0c-2.66-0.31,0.01-0.05-6.71-1l0.05,0.07C309.86,87.12,310.47,87.42,303.22,86.85z M351.72,99.24
			c-0.14-0.01-0.1,0.01-0.26-0.14C351.53,99.13,351.45,99.12,351.72,99.24z M358.57,105.17c-0.04-0.07-0.07-0.12-0.06-0.15
			C358.53,105.08,358.55,105.13,358.57,105.17z M351.28,98.2c-0.04-0.03-0.11-0.06-0.21-0.13C351.16,98.13,351.07,98.08,351.28,98.2
			z M350.45,97.8c0.06,0.01-0.11-0.11-0.23-0.21C350.33,97.64,350.65,97.78,350.45,97.8z"
              />
              <polygon
                className="st10"
                points="292.39,87.55 292.39,87.55 292.45,87.54 		"
              />
              <path
                className="st10"
                d="M192.38,114.91c-0.46,0.41-0.77,1.48,0.21,0.76l-0.1,0.16c0.11-0.09,0.08-0.07,0.23-0.52
			c0.31,0.19,0.02-0.32,0.26-0.15c0.02-0.12,0.15-0.26,0.11-0.35c0.14-0.04,0.19-0.33,0.31-0.23c-0.01-0.05,0.01-0.23,0.06-0.32
			c0.05,0.26,0.22-0.31,0.36-0.16c-0.06-0.21,0.22-0.46,0.28-0.72c0,0.02,0.01,0.06-0.01,0.07c0.09-0.11,0.15-0.11,0.23-0.16
			c-0.11,0.11,0.03-0.8,0.22-0.21c-0.09-0.06,0.1-0.3-0.01-0.31c0.15-0.05,0.1-0.06,0.23-0.04c-0.21-0.03,0.34-0.65,0.32-0.25
			c0.03-0.49,0.05-0.02,0.74-1.27c-0.08-0.06-0.24-0.08-0.02-0.25c-0.04,0.04-0.02,0.07-0.01,0.11c-0.18,0.31,0.5-0.27,0.16-0.06
			c0.44,0.17,0.45-0.61,1.14-1.25l0.04,0.15c0.44-0.77,1.13-1.13,3.16-3.07l-0.02,0.06c0.2-0.12,0.07-0.33,0.29-0.4l-0.02,0.04
			c0.15-0.09,0.56-0.65,2.34-1.77l0.07,0.09c-0.02-0.04,0.05-0.14,0.32-0.25c-0.07-0.21-0.07,0.32,0.67-0.54
			c0.02,0.04-0.04,0.1-0.07,0.16c0.14-0.24,0.1,0.07,0.23-0.15c-0.04,0-0.02-0.06-0.03-0.08c0.13-0.01,0.36-0.14,0.51-0.26
			l0.01,0.08c0.3-0.16,0.59-0.39,0.9-0.59c-0.06-0.02-0.1-0.27,0.05-0.11c0.07-0.14-0.11,0.13,0.2-0.02
			c0.59-0.24-0.14-0.14,2.23-1.34c0.3-0.17,0.38-0.21,0.73-0.47c0.51-0.37,0.01-0.01,1.44-0.63l-0.02,0.02
			c1.54-0.5-0.25-0.18,5.57-2.1l-0.07,0.14c0.3-0.15,0.28-0.15,1.75-0.6c-0.03,0.03-0.02,0.07-0.08,0.08
			c0.14,0.05,0.45-0.1,0.44-0.17c0.12,0.06,0.19,0.11,0.7-0.14c0.2-0.02,0.22,0,0.98-0.32c0.02,0.16,0.41,0.03,0.53-0.06
			c-0.04,0.05,0.13,0.03,0.09,0.1c0.16-0.03,0.3-0.08,0.26-0.15c0.55,0.08,0.37,0.05,2.09-0.41c-0.39,0.46-0.8,0.16,1.65-0.14
			c0,0.01-0.04,0.03-0.07,0.05c0.19-0.09-0.03,0.02,0.36-0.33c-0.11,0.21,0.37,0.03,0.19,0.3c0.05-0.02,0.1-0.05,0.09-0.1
			c0.07,0.01,0.1,0.04,0.03,0.09c0.25-0.06,0.16-0.13,0.39-0.14c0,0.03-0.04,0.05-0.07,0.09c0.11-0.08,0.23-0.15,0.43-0.14
			c-0.01,0.02,0.01,0.05-0.04,0.05c0.34-0.03,0.57-0.22,0.94-0.18c-0.1,0.16,0.13,0.04,0.15,0.17c0.07-0.02,0.13-0.03,0.2-0.05
			l-0.08-0.06c0.18,0,0.33-0.09,0.48-0.04l-0.13,0.05l0.33-0.02l-0.19-0.05c0.18-0.07-0.02-0.17,0.21-0.13
			c-0.05,0.01,0.18,0,0.27,0.08c-0.03,0.05,0,0,0.3,0.12c0.09-0.11,0.41-0.03,0.52-0.14c-0.12,0.1,0.2,0.22,0.34,0.03
			c-0.02,0.01-0.01,0.02-0.06,0.03c0.33-0.01,0.45,0.02,0.55,0.05l-0.09,0.01c0.38,0.03-0.04,0.01,1.4,0.01l-0.07-0.02
			c0.06-0.18,0.27,0.12,0.47,0.05c0.04,0.2-0.06,0.06,0.86,0.06l-0.05,0.04c0.69,0.01,0.56,0.13,2.04,0.25
			c-0.12,0.03-0.19,0.02,0.29,0.05c-0.02-0.09-0.04-0.06-0.11-0.13c0.05-0.04,0.17-0.06,0.26-0.04c-0.18,0.02-0.02,0.13,0.05,0.19
			c-0.01-0.03,0.02-0.05,0.02-0.08c0.16,0.22,0.02-0.07,0.29,0.03c-0.06,0.01,0.53-0.05,0.18,0.09c0.15,0.02,0.33-0.12,0.45,0
			c-0.07,0.01-0.16,0-0.23,0.01c0.13,0.14,0.15,0.11,0.26,0.15l-0.01,0l0.25,0.1l-0.06-0.04c0.24-0.2,0.39,0.03,0.68-0.08
			c-0.14,0.02-0.22,0.29,0.4,0.31c0,0.02,0.05,0.03,0.07,0.05c-0.29-0.17,1.17,0.16,2.14,0.43c-0.2,0.15-0.3,0.1,0.12,0.18
			c0.16,0.02,0.41,0.06,0.49-0.01l-0.09-0.06c0.1,0.17-0.39,0.01-0.12-0.02c-0.04-0.22-0.4-0.08-0.59-0.15
			c0.21-0.09,0.15-0.25-0.43-0.3c0.04-0.02,0-0.11,0.08-0.06c-0.23-0.15-0.43-0.01-0.49-0.14l0.02,0c-0.36-0.21-0.49-0.16-1.21-0.18
			c-0.1-0.2-0.02,0.01-0.83-0.29c0.04-0.13,0.08,0.07-0.45-0.04c0.02-0.04-0.41-0.15-0.62-0.23l0.01-0.02
			c-0.06,0.12-0.28,0.05-0.44,0.05c0.02-0.07,0.05-0.1,0.14-0.12c-0.02,0.02-0.02-0.06-0.34,0.02l0.05-0.09
			c-0.2-0.03-0.41,0.03-0.66-0.07c-0.03,0.09,0.17,0.27-0.11,0.3c0-0.05,0-0.13,0.07-0.14c-0.02,0-0.09,0.03-0.13,0.01l0.12-0.07
			c-0.06,0.03-0.36-0.03-0.09-0.05c-0.46-0.12-0.19-0.05-1.17-0.08l0,0c-0.24,0.13-0.86-0.24-2.14-0.25
			c0.16-0.06-0.05-0.06,0.16-0.04c-0.2-0.04-0.24-0.09-0.46,0c0.08-0.09-0.15-0.11-0.24-0.09l0.14,0.06c-0.11,0.01-0.2,0-0.3-0.01
			l0.06-0.12c-0.45-0.06-0.93-0.02-1.89,0.08c0.17,0.27-0.45-0.31-0.39-0.05c-0.24-0.06-0.38-0.02-0.63-0.03
			c0.07,0.05-0.73,0.38-0.55-0.03c-0.22,0.1-0.43-0.02-0.56-0.03l0.12-0.04c-0.07,0.03-0.13,0.03-0.19,0.03l0.07,0.08
			c-0.07-0.01-0.13-0.01-0.13-0.04c-0.13,0.11,0.05,0.29-0.4,0.18c0.29,0.02-0.01-0.1,0.19-0.14c-0.05,0.01-0.15,0.02-0.13-0.03
			c-0.29-0.08-0.12,0.13-0.41,0.15c0.03-0.06-0.08-0.11-0.12-0.1c0.09-0.01,0.03,0.15-0.34,0.21l0.11,0.01
			c-0.2,0.22-0.5-0.1-1.32-0.06c0.08,0.07,0.09,0.05-0.07,0.16l0.38-0.12l-0.24,0.16c0.12-0.02,0.24-0.09,0.31-0.09
			c-0.11,0.09-0.1,0.06-0.04,0.12c-0.61,0.08-0.28-0.36-1.38,0.05l0.12,0.02c-0.04,0.09-0.22,0.05-0.33,0.1
			c0-0.07-0.08-0.08-0.13-0.12c0.01,0.05-0.27,0.04-0.28,0.18c-0.34-0.07-0.18-0.13-0.76,0.2c0.07-0.09-0.07-0.12,0.11-0.2
			c-0.09,0.01-0.18,0.01-0.17,0.06c0.14-0.05-0.36-0.02-0.54,0.3c-0.24-0.1-0.41-0.07-1.69,0.28c0.01-0.02-0.01-0.03,0.03-0.03
			c-0.59,0.22-0.01-0.03-0.94,0.14l0.04-0.02c-0.23,0.03-0.09-0.05-1.24,0.56c0-0.03,0-0.05-0.01-0.07
			c-0.02,0.08-0.1,0.18-0.38,0.03l0.19-0.07c-0.13-0.09-0.25,0.08-0.35,0.1l0-0.06c-0.29,0.11-0.25,0.24-0.57,0.34l0.05,0.03
			c-0.4,0.25-0.21-0.11-0.95,0.35c0.08-0.13,0.3-0.26,0.43-0.37c-0.11,0.04-0.38,0.13-0.42,0.22c0.04-0.03,0.09-0.08,0.14-0.07
			c-0.88,0.49-0.02,0.06-2.56,0.81c0.06,0.3,0.04,0.39-0.8,0.16c0.06,0.06-0.04,0.13-0.18,0.16l0.22,0.01
			c-0.28,0.24-0.35-0.05-0.63,0.19l0.08-0.1c-0.23,0.05-0.27,0.13-1.85,1.06l0.01-0.06c-0.19,0.17-0.12-0.01-0.48,0.3l0.05-0.09
			c-0.41,0.13-0.51,0.42-0.8,0.61c-0.07-0.13-0.39-0.04-0.55,0.03l-0.01-0.02c-0.63,0.25,0.17,0.29-2.29,1.42
			c0.03,0.01,0.05,0.2-0.23,0.26c0.06-0.02-0.49-0.07-0.37,0.33c-0.25-0.06-0.51,0.44-2.17,1.37l0.02-0.1
			c-0.37,0.59-0.15,0.22-1.16,1.11c-0.01-0.02-0.03-0.06,0.01-0.1c-0.13,0.11-0.56,0.35-0.39,0.47c-0.09-0.1-0.29,0.23-0.4,0.41
			l-0.04-0.09c0.02,0.03-0.11,0.22-0.44,0.62l0.02-0.1c-0.23,0.19,0.03,0.29-0.51,0.42c0.06-0.07-0.11,0.3-0.12,0.15
			c-0.32,0.11,0.08,0.08-0.54,0.47c0.18-0.18,0.32-0.31,0.31-0.3c-0.06-0.07-0.21,0.07-0.19-0.14c-0.1,0.15,0.06,0.04-0.36,0.57
			l0.01-0.02c-0.18,0.21-0.14,0.07-0.47,0.4c0.08,0.06-0.03,0.15,0.12,0.12c-0.03,0.02,0.03-0.03,0.07-0.11l0,0.01
			c0.04-0.05,0.1-0.11,0.17-0.18c-0.29,0.65-0.42,0.18-1.29,1.38c0.09-0.23,0.12-0.2-0.41,0.44l0-0.06
			c-0.53,0.32-0.36,0.96-0.97,1.29c0.04-0.02,0.06-0.04,0.08-0.03c-0.47,0.45,0.16,0.11-1.69,2.07c-0.09,0.48,0.08,0.22-0.62,0.97
			l0.09,0C192.67,114.58,192.65,114.59,192.38,114.91z M198.43,108.33c0.01-0.04-0.14,0.23-0.14,0.05
			C198.29,108.41,198.37,108.36,198.43,108.33z M198.15,108.63c-0.02,0.04,0,0-0.07,0.08C198.09,108.69,198.11,108.67,198.15,108.63
			z M197.36,108.53c-0.01-0.02,0,0-0.02-0.05C197.37,108.43,197.49,108.38,197.36,108.53z"
              />
              <path
                className="st10"
                d="M287.89,122.46c-0.42,0.35-0.55,1.19,0.28,0.67l-0.08,0.13c0.09-0.07,0.06-0.05,0.16-0.43
			c0.31,0.19-0.01-0.27,0.23-0.1c0.01-0.1,0.11-0.21,0.07-0.29c0.13-0.02,0.15-0.26,0.27-0.17c-0.02-0.04-0.01-0.2,0.03-0.27
			c0.07,0.23,0.18-0.25,0.32-0.1c-0.07-0.19,0.16-0.37,0.19-0.59c0,0.02,0.01,0.05,0,0.06c0.07-0.08,0.13-0.08,0.2-0.12
			c-0.09,0.08-0.05-0.7,0.18-0.16c-0.09-0.06,0.06-0.25-0.04-0.27c0.13-0.03,0.09-0.04,0.21-0.02c-0.19-0.05,0.25-0.53,0.27-0.19
			c-0.13-0.4,0.55-0.44,0.5-1.27c-0.03,0.03-0.01,0.06,0,0.09c-0.12,0.26,0.42-0.19,0.14-0.04c0.34,0.45,0.51-0.69,1.75-1.86
			c-0.11,0.19,0.43-0.25,1.07-0.81c0.11-0.21,0.37-0.57,0.62-0.64l-0.02,0.05c0.17-0.09,0.02-0.3,0.22-0.34l-0.01,0.04
			c0.33-0.23,0.61-0.6,0.97-0.65c0.08-0.17-0.06-0.24,0.88-0.75l0.07,0.09c0.05-0.06-0.05-0.3,0.26-0.2
			c-0.08-0.19-0.02,0.28,0.53-0.44c0.02,0.04-0.02,0.09-0.05,0.14c0.1-0.21,0.09,0.07,0.18-0.12c-0.03-0.01-0.03-0.06-0.03-0.08
			c0.11,0.01,0.29-0.1,0.41-0.2l0.02,0.08c0.24-0.12,0.46-0.32,0.71-0.48c-0.05-0.02-0.11-0.27,0.03-0.1c0-0.08-0.05-0.26,0.05,0.01
			l0.1-0.11c0.02,0.02,0.01,0.06,0.02,0.1c0.59-0.39,0.08,0.04,1.59-1.16c0.05,0.07,0.25-0.04,0.17,0.07
			c0.23-0.14,0.3-0.17,0.57-0.39c0.42-0.33,0-0.01,1.15-0.49l-0.01,0.01c0.79-0.42-0.07,0.06,2.2-0.82c0-0.54,0.59,0.17,2.22-0.87
			l-0.05,0.14c0.42-0.3,0.94-0.29,1.38-0.51c-0.02,0.03-0.01,0.07-0.06,0.08c0.12,0.06,0.36-0.08,0.35-0.15
			c0.09,0.05,0.16,0.12,0.55-0.11c0.16-0.01,0.19,0.01,0.78-0.28c0.02,0.16,0.33,0.05,0.43-0.04c-0.03,0.05,0.11,0.03,0.08,0.11
			c0.13-0.02,0.24-0.07,0.2-0.15c0.43,0.09,0.28,0.07,1.66-0.35c-0.29,0.43-0.66,0.16,1.31-0.11c0,0.01-0.03,0.03-0.06,0.05
			c0.15-0.09-0.03,0.02,0.28-0.32c-0.08,0.21,0.29,0.03,0.16,0.3c0.04-0.02,0.08-0.05,0.07-0.1c0.06,0.01,0.08,0.04,0.02,0.09
			c0.2-0.05,0.12-0.13,0.31-0.14c0,0.03-0.03,0.05-0.05,0.09c0.09-0.07,0.18-0.15,0.34-0.13c-0.01,0.02,0.01,0.05-0.03,0.05
			c0.27-0.03,0.45-0.22,0.75-0.17c-0.08,0.16,0.1,0.04,0.12,0.17c0.05-0.02,0.1-0.03,0.16-0.04l-0.07-0.06
			c0.14,0,0.26-0.09,0.38-0.05l-0.1,0.05l0.26-0.02l-0.15-0.05c0.14-0.07-0.02-0.17,0.17-0.13c-0.04,0.01,0.14,0,0.21,0.08
			c-0.02,0.04,0,0,0.24,0.12c0.07-0.11,0.33-0.03,0.41-0.14c-0.1,0.08,0.15,0.23,0.27,0.03c-0.02,0.01-0.01,0.02-0.05,0.03
			c0.26-0.01,0.36,0.02,0.44,0.05l-0.08,0.02l0.08,0.06c0.17-0.11-0.03-0.01,1.04-0.07c0.08-0.2-0.03,0.21,1,0.06l-0.04,0.04
			c0.53,0,0.45,0.11,1.62,0.19c-0.09,0.03-0.16,0.03,0.23,0.04c0-0.12-0.21-0.15,0.12-0.18c-0.14,0.03-0.02,0.13,0.03,0.18
			c-0.01-0.03,0.02-0.05,0.02-0.08c0.11,0.21,0.05-0.08,0.23,0.02c-0.04,0.01,0.43-0.07,0.14,0.08c0.12,0.02,0.27-0.13,0.36-0.02
			c-0.06,0.02-0.13,0-0.19,0.02c0.1,0.13,0.12,0.1,0.2,0.14l-0.01,0l0.19,0.09l-0.05-0.04c0.2-0.21,0.31,0.01,0.55-0.11
			c-0.19,0.1-0.09,0.07,0,0.29c0.11-0.07,0.14,0.09,0.31,0c0,0.03,0.1,0.02,0.06,0.1c-0.52-0.25,1.07,0.07,1.71,0.24
			c-0.16,0.15-0.25,0.11,0.09,0.17c0.13,0.01,0.33,0.04,0.4-0.04l-0.07-0.05c-0.04,0.12,0.06-0.19-0.57-0.13
			c0.21-0.13,0.07-0.26-0.33-0.27c0.03-0.02,0.01-0.1,0.07-0.07c-0.18-0.14-0.35,0.02-0.39-0.11l0.02,0
			c-0.29-0.03-0.05,0.1-0.29,0.16c-0.03,0.13,0.02-0.45-0.19-0.27c-0.08,0.21-0.3-0.26-0.49,0c0.12-0.21-0.37,0.06-0.23-0.23
			c-0.15,0.11-0.05-0.01-0.39-0.04c-0.02-0.09,0.02,0.04-0.4-0.01c0.03-0.06-0.02,0.04-0.48-0.23c-0.05,0.12-0.22,0.07-0.35,0.07
			c0.02-0.08,0.04-0.1,0.12-0.12c-0.02,0.02-0.02-0.06-0.27,0.03l0.04-0.09c-0.16-0.02-0.33,0.05-0.53-0.04
			c-0.02,0.09,0.13,0.26-0.09,0.3c0-0.05,0-0.13,0.06-0.15c-0.02,0-0.07,0.03-0.11,0.01l0.1-0.07c-0.05,0.03-0.28-0.02-0.07-0.05
			c-0.08-0.02-0.18-0.07-0.21-0.13c-0.22,0.2,0-0.01-0.73,0.08l0,0c-0.19,0.13-0.68-0.22-1.7-0.2c0.13-0.06-0.04-0.06,0.13-0.04
			c-0.16-0.04-0.19-0.09-0.37,0c0.06-0.09-0.12-0.11-0.19-0.09l0.11,0.06c-0.52-0.19,0.07-0.12-1.7-0.03
			c0.13,0.13-0.21,0.05-0.09-0.07l-0.02,0.04c-0.08-0.04-0.28-0.04-0.24-0.11c-0.11,0.04-0.05,0.07,0.04,0.09
			c-0.2-0.06-0.3-0.01-0.5-0.03c0.08,0.04-0.58,0.38-0.44-0.04c-0.18,0.09-0.34-0.03-0.45-0.03l0.09-0.04
			c-0.06,0.02-0.1,0.03-0.15,0.03l0.06,0.08c-0.06-0.01-0.1-0.01-0.11-0.04c-0.1,0.08,0.06,0.3-0.31,0.17
			c0.3,0.05,0.09-0.44-0.29-0.02c0.02-0.06-0.06-0.11-0.1-0.1c0.07-0.02,0.05,0.15-0.27,0.2l0.09,0.02
			c-0.17,0.21-0.39-0.09-1.05-0.08c0.07,0.07,0.07,0.05-0.05,0.15l0.3-0.11l-0.18,0.15c0.09-0.01,0.19-0.08,0.25-0.08
			c-0.09,0.08-0.08,0.06-0.03,0.12c-0.5,0.08-0.21-0.37-1.1,0.01l0.1,0.02c-0.03,0.09-0.18,0.04-0.26,0.09
			c0-0.07-0.06-0.09-0.11-0.12c0.01,0.05-0.21,0.03-0.22,0.17c-0.27-0.08-0.15-0.14-0.6,0.18c0.05-0.08-0.06-0.12,0.08-0.19
			c-0.07,0.01-0.14,0.01-0.14,0.06c0.12-0.06-0.31,0-0.42,0.28c-0.2-0.11-0.33-0.09-1.35,0.2c0-0.02-0.01-0.03,0.02-0.03
			c-0.44,0.18-0.02-0.02-0.75,0.1l0.03-0.02c-0.14-0.05-0.27,0.15-0.46,0.18c0,0,0-0.02-0.01-0.03c-0.1,0.14-0.33,0.25-0.5,0.34
			c-0.01-0.03-0.01-0.05-0.01-0.07c-0.01,0.07-0.07,0.17-0.31,0.01l0.15-0.05c-0.15-0.38-0.63,0.65-1.02,0.33
			c-0.08,0.06-0.27,0.23-0.43,0.31c0.06-0.13,0.23-0.24,0.33-0.33c-0.09,0.03-0.3,0.11-0.33,0.19c0.03-0.02,0.07-0.07,0.11-0.06
			c-0.68,0.43-0.01,0.06-2.05,0.62c0.11,0.16,0.02,0.24-0.12,0.26c0-0.05,0.08-0.18-0.07-0.18c0.13,0.03-0.05,0.13,0.02,0.18
			c-0.15,0-0.34-0.05-0.47-0.16c0.06,0.06-0.02,0.13-0.14,0.14l0.19,0.03c-0.21,0.21-0.29-0.07-0.51,0.14l0.05-0.1
			c-0.12,0.09-0.49,0.2-0.54,0.43c-0.03-0.01-0.09-0.05-0.04-0.09c-0.32,0.13-0.6,0.51-0.88,0.54l0-0.06
			c-0.15,0.15-0.1-0.02-0.38,0.25l0.04-0.09c-0.33,0.09-0.39,0.36-0.62,0.52c-0.07-0.13-0.34-0.06-0.46-0.02l-0.01-0.02
			c-0.51,0.18,0.17,0.29-1.82,1.15c0.02-0.02,0.12,0.15-0.19,0.24c0.11-0.06,0.03-0.02-0.26,0.27c-0.22-0.09-0.39,0.37-1.74,1.07
			l0.01-0.09c-0.25,0.5-0.08,0.19-0.91,0.91c-0.01-0.02-0.03-0.06,0-0.09c-0.1,0.09-0.46,0.27-0.29,0.39
			c-0.09-0.1-0.23,0.19-0.31,0.33l-0.05-0.09c0.02,0.02-0.07,0.19-0.32,0.52l0.01-0.09c-0.18,0.15,0.06,0.27-0.4,0.33
			c0.05-0.05-0.07,0.27-0.09,0.13c-0.27,0.07,0.07,0.09-0.43,0.37c0.14-0.14,0.25-0.25,0.25-0.23c-0.06-0.07-0.18,0.04-0.18-0.14
			c-0.12,0.04-0.01,0.36-0.65,0.77c0.07,0.06-0.01,0.13,0.12,0.11c-0.02,0.01,0.02-0.03,0.05-0.09l0,0.01
			c0.04-0.04,0.08-0.09,0.13-0.15c0.05,0.09-0.19,0.04-0.09,0.37c-0.38-0.03-0.35,0.01-0.93,0.74c0.01-0.04,0.05-0.07,0.04-0.11
			c-0.06,0.13-0.27,0.31-0.2,0.42c-0.16,0.09-0.02-0.15-0.17,0.04l-0.01-0.05c-0.45,0.23-0.23,0.81-0.76,1.04
			c0.04-0.02,0.05-0.03,0.07-0.02c-0.38,0.33,0.16,0.12-1.36,1.65c-0.04,0.39,0.1,0.2-0.48,0.78l0.08,0.01
			C288.13,122.2,288.1,122.24,287.89,122.46z M292.76,117.28c0-0.03-0.11,0.19-0.12,0.03C292.64,117.34,292.71,117.3,292.76,117.28z
			 M292.54,117.52c-0.02,0.03,0,0-0.05,0.06C292.49,117.57,292.51,117.55,292.54,117.52z M291.82,117.35c-0.01-0.02,0,0-0.02-0.05
			C291.83,117.26,291.92,117.23,291.82,117.35z"
              />
              <path
                className="st10"
                d="M331.44,158.44l0.02,0.08l-0.09-0.01c0.04,0.08,0.08,0.02,0.12-0.02c0,0.04,0.01,0.07,0.03,0.09l0.13-0.14
			c-0.05,0.35,0.15-0.14,0.1,0.15c0.06-0.04,0.14,0,0.18-0.08c0.04,0.1,0.18,0,0.15,0.16c0.02-0.03,0.11-0.11,0.16-0.1
			c-0.11,0.17,0.18,0.04,0.12,0.23c0.09-0.15,0.24-0.04,0.37-0.11c-0.01,0.01-0.02,0.04-0.03,0.03c0.06,0.02,0.07,0.08,0.11,0.11
			c0.03-0.14,0.13-0.16,0.19-0.23c0.06,0.14-0.07,0.15-0.06,0.28c0.01-0.11,0.15-0.1,0.13-0.19c0.05,0.09,0.05,0.04,0.06,0.16
			c0.03-0.17,0.06-0.02,0.12-0.12c0.01,0.09,0.19,0.04,0.05,0.23c0.19-0.21,0.05,0.03,0.69-0.2c0.01,0.01,0.01,0.02,0.01,0.03
			c0.02,0-0.07-0.22,0.1-0.17c-0.03-0.01-0.03,0.03-0.05,0.05l0.05-0.03c-0.22,0.16,0.17,0.22,0.01,0.13
			c-0.27,0.46,0.69,0.14,1.49,0.08c-0.3,0.02,0.54,0.18,0.78,0.16c0.14-0.09,0.38-0.19,0.52-0.08l-0.03,0.03
			c0.11,0.04,0.12-0.21,0.22-0.12l-0.02,0.02c0.24,0.04,0.5-0.05,0.68,0.09c0.19-0.19,0.18-0.2,0.67-0.12l0.01,0.11l0.08-0.12
			l0.05,0.13c0.12-0.21-0.04,0.17,0.45-0.09c0,0.04-0.04,0.06-0.06,0.09c0.08-0.19,0.06,0.16,0.13-0.08
			c0.06,0.06,0.18,0.05,0.27,0.01l-0.01,0.08c0.16,0,0.34-0.07,0.52-0.09c-0.07-0.03-0.07-0.16-0.02-0.18l0.03,0.11
			c0.02-0.07,0.02-0.25,0.03,0.03l0.08-0.05c0.01,0.03-0.01,0.05-0.01,0.1c0.03-0.07,0.16,0.1,0.19-0.03
			c0.12,0.05,0.62-0.13,0.92-0.33c0.02,0.09,0.15,0.06,0.09,0.13c0.15-0.04,0.2-0.05,0.38-0.15c-0.05-0.05-0.04-0.14,0.03,0
			c0.2-0.17,0.56,0.18,0.7-0.04l-0.01,0.01c0.25-0.17,0.17-0.06,1.29-0.17c-0.01-0.04-0.05-0.14,0.07-0.03
			c-0.45-0.28,0.82,0.02,2.1-0.69c-0.01,0.03,0,0.06-0.02,0.07c0.08,0.06,0.21-0.06,0.19-0.13c0.04,0.02,0.14,0.14,0.32-0.08
			c0.11,0.01,0.18-0.1,0.27-0.07c0.02-0.12,0.19-0.01,0.15-0.16c0.04,0.15,0.22,0.07,0.25-0.03c0,0.05,0.07,0.03,0.07,0.1
			c0.07-0.03,0.12-0.08,0.09-0.15c0.19,0.13,0.21-0.24,0.37-0.04c0.18-0.16,0.35-0.29,0.53-0.39c-0.05,0.34-0.38,0.35,0.73-0.25
			c0.01,0.01-0.01,0.04-0.02,0.05c0.03-0.02,0.08-0.01,0.05-0.07l-0.02,0.03c-0.09-0.07,0.04-0.25,0.02-0.31
			c0.03,0.21,0.17-0.02,0.19,0.26c0.01-0.02,0.03-0.06,0-0.11c0.08,0.07,0.03,0.15,0.18,0c0.07-0.12,0.08,0.02,0.7-0.35l-0.06-0.04
			c0.07-0.03,0.1-0.15,0.18-0.15l-0.03,0.08l0.13-0.09l-0.1-0.01c0.04-0.1-0.08-0.15,0.03-0.16c0.02,0.04,0.1-0.04,0.32,0.06
			c-0.01-0.12,0.16-0.11,0.15-0.24c0,0.03,0.03,0.09,0.04,0.1l-0.01-0.09c0.11,0.03,0.07-0.06,0.36-0.1l-0.04,0.04l0.07,0.03
			l-0.01-0.06c0.05,0.01,0.35-0.2,0.98-0.63c-0.08,0.06,0.26,0.11,0.23-0.15c0.21-0.01,0.33,0.21,0.65-0.53
			c-0.04,0.09,0.07,0.11,0.12,0.13c-0.02-0.02-0.02-0.05-0.04-0.07c0.16,0.14-0.02-0.14,0.1-0.09c-0.01,0.01,0.13-0.24,0.1,0
			c0.16-0.14-0.02,0.01,0.35-0.09c-0.16-0.25-0.02-0.01,0.1-0.34c0,0.17-0.02,0.07,0.19,0.22c0-0.1,0.12,0,0.11-0.14
			c0.01,0.02,0.03,0.01,0.06,0.02c-0.03-0.04,0.1-0.19,0.61-0.67c0.08,0.2,0.1-0.23,0.15,0.08c0.03-0.06,0.05-0.15,0.08-0.21
			c0.05,0.04,0.02,0.1,0.03,0.14c-0.02-0.01,0.13-0.12,0.03-0.24c0.06,0.26-0.07-0.24-0.25,0.22l-0.05-0.14l-0.01,0.15
			c-0.04-0.02-0.1-0.08-0.07-0.12c-0.08-0.02-0.07-0.05-0.15,0.1c-0.01-0.03-0.07-0.08-0.02-0.08c-0.11,0.03-0.3,0.07-0.16,0.39
			l-0.22-0.17l0.05,0.1c-0.02,0.01-0.05,0.02-0.07-0.02c-0.01,0.1,0.03,0.03,0.04,0.11c-0.12-0.03-0.16,0.16-0.27,0.03
			c0.02,0.08,0.1,0.01,0.06,0.1c-0.09-0.2-0.12,0.21-0.23-0.07c0.02,0.16-0.01,0-0.17,0.14c-0.13-0.1,0.12-0.12-0.15,0.18
			c-0.02-0.06,0,0.04-0.32,0.04c0.05,0.12-0.04,0.15-0.09,0.21c-0.04-0.07-0.04-0.1-0.03-0.15c0,0.02-0.05-0.01-0.08,0.15
			l-0.04-0.09c-0.07,0.05-0.09,0.2-0.23,0.2c0.04,0.08,0.21,0.17,0.13,0.28c-0.03-0.04-0.08-0.11-0.06-0.14
			c0.01,0.01-0.19,0.03-0.1-0.06c-0.05,0.01-0.12,0.01-0.16-0.03c0.02,0.04,0.04,0.21-0.11,0.04c0.01,0.05-0.07,0.04-0.02,0.13
			c-0.02,0.01-0.05-0.03-0.07-0.05c-0.01,0.07,0,0-0.07,0.2l0,0c-0.03,0.18-0.4,0.07-0.87,0.44c0.03-0.09-0.05-0.04,0.04-0.07
			c-0.09,0.02-0.14-0.02-0.18,0.1c-0.01-0.09-0.11-0.07-0.14-0.03l0.09,0.03c-0.28-0.16-0.61,0.58-0.94,0.37l0.01,0.04
			c-0.06-0.02-0.16,0.04-0.16-0.04c-0.04,0.06,0,0.07,0.06,0.07c-0.12-0.01-0.15,0.08-0.27,0.08c0.07,0.04-0.21,0.43-0.25,0.03
			c-0.06,0.11-0.2,0.03-0.25,0.04l0.04-0.05c-0.02,0.03-0.05,0.04-0.07,0.05l0.06,0.06c-0.03,0-0.06,0.01-0.07-0.02
			c-0.01,0.06,0.08,0.12,0.05,0.21c-0.06-0.02-0.1,0.09-0.16,0c0.17-0.04-0.14-0.39-0.16,0.03c-0.01-0.06-0.07-0.1-0.09-0.08
			c0.02-0.03,0.1,0.1-0.07,0.23c0.06,0.02,0.1,0-0.02,0.12c-0.02-0.04,0.04-0.05,0.01-0.06c-0.02,0.25-0.54-0.29-0.52,0.15
			l0.14-0.12l-0.06,0.15c0.05-0.02,0.09-0.09,0.12-0.09c-0.02,0.08-0.03,0.06,0.02,0.12c-0.32,0.19-0.1-0.36-0.64,0.06
			c0.06,0.04,0.12,0.04-0.07,0.11c-0.02-0.07-0.06-0.08-0.1-0.11c0.02,0.05-0.11,0.04-0.08,0.17c-0.16-0.05-0.13-0.14-0.3,0.2
			c0.01-0.08-0.06-0.11,0-0.19c-0.04,0.01-0.08,0.02-0.06,0.06c-0.02-0.01-0.07-0.02-0.18,0.17c0.01,0.03-0.06,0.07,0.01,0.13
			c-0.15-0.11-0.18-0.11-0.77,0.12c0-0.02-0.01-0.03,0.01-0.03c-0.26,0.17,0-0.03-0.43,0.06l0.02-0.02
			c-0.09-0.05-0.13,0.13-0.24,0.15c0,0,0-0.02-0.01-0.03c-0.04,0.14-0.17,0.21-0.26,0.27c-0.03-0.2,0.01-0.13-0.2-0.11
			c0.22-0.48-0.4,0.52-0.5,0.13c-0.04,0.05-0.15,0.18-0.24,0.23c0.03-0.11,0.12-0.19,0.18-0.27c-0.05,0.01-0.17,0.06-0.18,0.13
			c0.07-0.02,0.09-0.03-0.15,0.18c-0.05-0.26-0.28,0.01-0.41-0.18c-0.16,0.14-0.37,0.04-0.58,0.1c0.09,0.03-0.09,0.38-0.11,0.03
			c0.07,0.06-0.02,0.11-0.01,0.16c-0.08-0.03-0.18-0.11-0.26-0.27c0.03,0.07-0.02,0.12-0.08,0.1l0.11,0.08
			c-0.13,0.14-0.17-0.15-0.3-0.01l0.04-0.08c-0.07,0.06-0.29,0.06-0.34,0.24c-0.02-0.02-0.05-0.08-0.02-0.1
			c-0.13-0.07-0.24,0.06-0.79,0.2l0.03-0.06c-0.19-0.04-0.26,0.19-0.41,0.25c-0.47-0.39-0.09-0.25-1.43-0.07
			c0.01-0.01,0.04,0.19-0.15,0.12l0.02,0c-0.05-0.14-0.14-0.08-0.22-0.09l0,0.18c-0.22-0.26-0.51,0.13-0.64-0.11
			c-0.04,0.05-0.1,0.05-0.15,0.1c-0.06-0.14,0.02-0.11-0.36-0.09l0.03-0.07c-0.24,0.22-0.02,0.2-0.7,0.17
			c0-0.02,0.01-0.06,0.03-0.07c-0.08,0-0.29-0.1-0.26,0.1c0-0.13-0.17-0.01-0.25,0.05l0.01-0.1c0,0.03-0.07,0.08-0.33,0.18
			l0.04-0.06c-0.13-0.01-0.08,0.25-0.29-0.01c-0.01,0.01,0.03,0.01-0.41-0.02c0.11,0.01,0.2,0.02,0.19,0.02
			c0-0.09-0.09-0.11-0.01-0.23c-0.06-0.05-0.17,0.24-0.56,0.05c0,0.1-0.06,0.08-0.01,0.16c-0.01-0.01,0.02,0,0.06-0.02l0,0.01
			c0.03,0,0.07,0,0.11,0c-0.04,0.05-0.08,0.09-0.12,0.03c-0.03,0.06-0.06,0.13-0.07,0.16c-0.19-0.22,0.03-0.45-0.59-0.23
			c-0.08,0.04-0.23,0.01-0.26,0.13c-0.29-0.43,0.01-0.13-0.78-0.15c0.02,0.02,0.03,0.03,0.03,0.05c-0.24-0.05-0.15-0.21-1.18-0.11
			l-0.01-0.11c-0.21,0.31-0.51-0.34-1.21,0.01c0-0.01,0-0.01,0.01-0.03l-0.03,0.06c0,0,0,0,0,0
			C331.25,158.18,331.27,158.31,331.44,158.44z M335.75,158.76c-0.01,0.02,0.03,0.04,0.06,0.07
			C335.82,158.82,335.68,158.87,335.75,158.76z M335.62,158.83c-0.02,0.01,0,0-0.05,0.01C335.58,158.83,335.6,158.83,335.62,158.83z
			 M335.42,158.18c0-0.02,0,0,0.01-0.05C335.46,158.13,335.52,158.18,335.42,158.18z"
              />
              <path
                className="st10"
                d="M350.19,154.99c-0.01,0,0.02-0.01,0.06-0.06L350.19,154.99z"
              />
              <path
                className="st10"
                d="M424.45,109.04l-0.41,0.14c0.47-0.06,1.4-0.34,4.07-1.26c-0.38,0.28,0.86-0.2,0.8,0.03
			c0.27-0.24,1.11-0.32,1.65-0.51c-0.03,0.02-0.09,0.06-0.14,0.06c0.01,0.29,1.58-0.68,1.3-0.09c-0.04-0.11,0.65-0.2,0.51-0.27
			c0.31,0.04,0.26,0.01,0.42,0.1c-0.25-0.16,1.61-0.29,0.91,0c1.06-0.36,0.06-0.06,3.31-0.3c-0.04-0.21-0.08-0.2,0.42-0.15
			c-0.13-0.01-0.15,0.02-0.21,0.04c-1.21,0.21,1.72,0.2,0.34,0.12c0.49,0.54,1.63,0.03,3.83,0.55l-0.2,0.09
			c1.84,0.37,0.12-0.35,9.7,2.98l-0.14-0.02c0.5,0.23,0.65,0.01,1.1,0.28l-0.1-0.02c0.37,0.24,3.89,1.35,6.42,2.27
			c0-0.05,0.34,0.03,0.92,0.25c-0.17-0.36,0.05,0.25,1.94,0.5c-0.03,0.04-0.22,0-0.36-0.01c1.04,0.19,0.19,0.03,1.96,0.48l-0.1,0.05
			c0.78,0.23,1.67,0.37,2.56,0.58c-0.27-0.07-0.17-0.1,0.53,0.16c1.27,0.31-0.31,0.14,7.98,1.32c1.36,0.09,0.04,0,3.61,0.55l-0.05,0
			c2.56,0.18-0.01,0.04,6.47,0.66l-0.05-0.06c1.43,0.08,0.43-0.22,7.42,0.11l-0.25,0.12c1.9-0.11,1.81-0.23,1.43,0.24
			c0-0.03-0.04-0.05-0.01-0.07c-0.55,0.49-0.33,0.18-1.8,1.52c-0.42,0.23-0.33,0.23-1.9,1.39c-0.04-0.14-0.83,0.35-1.09,0.56
			c0.07-0.09-0.27,0.09-0.19-0.03c-0.32,0.18-0.61,0.37-0.51,0.41c-0.95,0.18-1.71,0.89-4.48,1.94c0.18-0.14,0.32-0.23,0.42-0.28
			c-0.56,0.16-0.76,0.37-4.11,1.23c0-0.01,0.08-0.05,0.17-0.08c-0.49,0.18,0.36-0.1-0.82,0.47c0.24-0.26-0.83,0.11-0.44-0.23
			c-0.1,0.03-0.23,0.09-0.2,0.14c-0.16,0.02-0.22,0-0.07-0.09c-0.58,0.14-0.36,0.18-0.89,0.26c0-0.03,0.1-0.06,0.15-0.11
			c-0.26,0.11-0.52,0.22-0.97,0.26c0.02-0.02-0.03-0.04,0.1-0.06c-0.77,0.11-1.3,0.35-2.16,0.36c0.12-0.09,0.17-0.2-0.81-0.08
			l0.19,0.05c-0.4,0.03-0.75,0.16-1.1,0.13l0.3-0.07l-0.75,0.06l0.43,0.03c-0.41,0.08,0.05,0.16-0.49,0.15
			c0.11-0.02-0.41,0.02-0.62-0.05l0,0c-0.84,0.13-0.26-0.22-1.89,0.05c0.21-0.18-0.6-0.16-0.78-0.04c0.05-0.01,0.03-0.02,0.14-0.03
			c-0.69-0.01-0.99-0.04-1.22-0.08l0.15-0.01c-0.91-0.08,0.07-0.01-3.22-0.12l0.16,0.03c-0.99,0.07-0.69-0.36-3.06-0.26l0.12-0.04
			c-1.66-0.13-1.27-0.21-4.7-0.6c0.27-0.01,0.41,0-0.68-0.09c0.06,0.09,0.11,0.06,0.28,0.15c-0.12,0.03-0.37,0.03-0.58,0
			c0.4,0,0.01-0.13-0.15-0.2c0.02,0.03-0.04,0.05-0.03,0.07c-0.43-0.21-0.1,0.15-0.71-0.04c0.24-0.1,0.07-0.01-0.39-0.16
			c-0.35-0.05-0.72,0.07-1.02-0.07c0.15,0,0.37,0.03,0.52,0.02c-0.33-0.16-0.37-0.14-0.63-0.19l0.02,0.01l-0.59-0.14l0.15,0.05
			c-0.48,0.18-0.9-0.09-1.52-0.02c0.6-0.06,0.11-0.07,0.17-0.17c-0.15-0.05-0.37-0.12-0.3-0.14c-0.21-0.01-0.23,0.11-1.09-0.2
			c1.32,0.34-2.7-0.23-4.98-0.49c0.45-0.17,0.56-0.09-0.36-0.19c-0.37-0.02-0.96-0.06-1.08,0.02l0.23,0.06
			c0-0.08,0.14-0.19,0.5,0.03l-0.23-0.01c0.21,0.23,0.94,0.08,1.4,0.16c-0.26,0.15-0.52,0.15,0.2,0.19c0.41,0.12-0.01,0.08,0.9,0.13
			c-0.07,0.02,0.04,0.11-0.16,0.06c0.57,0.16,0.96,0.02,1.16,0.16l-0.05,0c1.67,0.19,1.8,0.13,4.74,0.7
			c-0.06,0.14-0.13-0.1,1.03,0.11c-0.04,0.04,0.97,0.21,1.45,0.33l-0.02,0.02c0.11-0.11,0.61-0.02,0.98,0.01
			c-0.03,0.07-0.1,0.09-0.3,0.1c0.05-0.02,0.01,0.06,0.76,0.03c-0.33,0.26-0.19,0.05,1.47,0.09c0.05,0,0.2-0.01,0.3,0.02l-0.26,0.05
			c0.25,0.06,0.51-0.04,0.21,0.07c1.14,0.22,0.49,0.1,2.67,0.29l0,0c0.79-0.02,0,0,2.39,0.25c0.05,0.16,0.75,0.06,2.52,0.22
			c-0.36,0.04,0.12,0.06-0.37,0.02c0.46,0.06,0.56,0.12,1.06,0.05c-0.17,0.08,0.34,0.13,0.56,0.11l-0.32-0.08
			c0.25,0,0.46,0.02,0.68,0.03l-0.12,0.11c0.96,0.06,2.12,0.11,4.34-0.1c-0.45-0.27,1.15,0.24,0.9,0.01
			c0.56,0.02,0.87-0.06,1.45-0.07c-0.02-0.05,1.55-0.49,1.25-0.06c0.51-0.14,0.98-0.06,1.28-0.1l-0.27,0.08
			c0.16-0.05,0.3-0.07,0.43-0.09l-0.17-0.06c0.16-0.01,0.3-0.03,0.3,0c0.32-0.24-0.01-0.26,0.9-0.29c-0.9,0.1-0.32,0.5,0.81-0.12
			c-0.07,0.08,0.18,0.09,0.28,0.05c-0.21,0.03,0.05-0.18,0.76-0.34l-0.26,0.03c0.37-0.29,1.16-0.13,2.94-0.57
			c-0.18-0.04-0.2-0.01,0.15-0.2l-0.85,0.31l0.52-0.28c-0.26,0.08-0.54,0.21-0.7,0.24c0.25-0.14,0.22-0.12,0.08-0.15
			c1.22-0.32,0.84,0.21,3.05-0.82l-0.27,0.06c0.08-0.11,0.48-0.2,0.72-0.33c0.86-0.18,0.01,0.03,2.49-1.19
			c-0.14,0.15,0.15,0.07-0.23,0.3c0.2-0.09,0.38-0.17,0.36-0.21c-0.21,0.11,0.55-0.18,1.08-0.83c0.7-0.3,0.15,0.23,3.34-2.1
			c-0.01,0.03,0.02,0.03-0.05,0.07c0.7-0.66,0.66-0.48,1.91-1.57l-0.1,0.12c0.14-0.07,0.48-0.47,1.13-1.18
			c-0.79-0.02,0.06-0.04-2.53,0.2c0.01-0.03,0.02-0.05,0.03-0.07c-0.09,0.07-0.32,0.15-0.86-0.07l0.46-0.01
			c-0.24-0.12-0.59,0.01-0.84-0.01l0.03-0.05c-0.7,0.02-0.71,0.15-1.47,0.14l0.08,0.04c-1.06,0.15-0.41-0.31-2.33,0
			c0.28-0.1,0.85-0.13,1.21-0.19c-0.27-0.01-0.92-0.01-1.06,0.05c0.11-0.01,0.25-0.04,0.35-0.02c-2.26,0.11-0.1,0.09-6.08-0.45
			c0.38,0.24-0.32-0.02-0.27,0.2c-0.14,0,0,0.01-0.38-0.04c0,0,0,0,0.01,0c-0.36-0.07-0.65-0.25-1.15-0.48
			c0.07,0.08-0.21,0.09-0.52,0.03l0.44,0.14c-0.8,0.04-0.66-0.23-1.46-0.21l0.26-0.04c-0.75-0.15-0.78,0.01-4.8-0.47l0.08-0.04
			c-0.31,0-0.12-0.04-1.27-0.15l0.21-0.02c-0.93-0.23-1.47-0.08-2.26-0.2c0.03-0.15-0.7-0.34-1.06-0.43l0.02-0.02
			c-1.45-0.42-0.13,0.4-5.95-1.29c-2.15-0.4-0.23-0.02-5.67-1.45l0.04-0.09c-0.64-0.23-0.95-0.33-1.76-0.57l0.18-0.01
			c-1.56-0.27-0.73-0.1-3.47-1.03c0.02-0.02,0.06-0.05,0.18-0.01c-0.36-0.13-1.37-0.6-1.31-0.36c0.04-0.13-0.79-0.3-1.23-0.4
			l0.1-0.07c-0.05,0.02-0.56-0.1-1.64-0.4l0.2,0c-0.61-0.19-0.1-0.09-1.84-0.62c-0.66-0.35,0,0.12-1.54-0.55
			c0.54,0.17,0.97,0.32,0.93,0.31c0.03-0.09-0.4-0.24-0.02-0.25c-0.37-0.09-0.01,0.07-1.48-0.32l0.05,0
			c-0.6-0.16-0.32-0.15-1.34-0.44c-0.01,0.1-0.29,0.01-0.04,0.16c-0.06-0.03,0.11,0.03,0.29,0.05l-0.02,0
			c0.15,0.04,0.34,0.09,0.55,0.15c-1.59-0.16-1.03-0.55-4.24-0.94c0.12,0,0.25,0.02,0.32,0.01c-0.02-0.05-1.05,0.07-1.68-0.19
			l0.1-0.03c-1.34-0.36-2.22,0.12-3.72-0.1c0.1,0.01,0.16,0.02,0.18,0.04c-2.06,0.16,0.59-0.56-8.65,1.37l0.13,0.04
			c-0.31,0.07-0.97,0.32-0.94,0.23c-0.19,0.24-2.89,0.52-2.15,0.82C423.1,108.94,423.17,109.45,424.45,109.04z M445.31,108.49
			c-0.05,0.01,0.13,0.08,0.25,0.15C445.45,108.61,445.12,108.54,445.31,108.49z M443.77,107.44c0.14-0.03,0.09-0.04,0.28,0.05
			C443.98,107.49,444.06,107.48,443.77,107.44z M444.68,108.37c-0.1-0.02,0,0-0.23-0.06C444.5,108.32,444.56,108.34,444.68,108.37z"
              />
              <path
                className="st10"
                d="M243.1,75.84l-0.19,0.19c0.38-0.13,0.7-1.04,0.89-0.8c0.03-0.13,0.36-0.06,1.05-1.04
			c-0.09,0.31,0.44-0.34,0.51-0.14c0.06-0.25,0.53-0.5,0.75-0.79c-0.01,0.02-0.02,0.07-0.05,0.08c0.17-0.11,0.24-0.11,0.37-0.16
			c-0.2,0.14,0.48-0.94,0.37-0.22c-0.08-0.08,0.28-0.34,0.16-0.36c0.2-0.04,0.16-0.06,0.3-0.02c-0.23-0.07,0.77-0.7,0.52-0.25
			c0.43-0.68,0.01,0.02,1.65-1.31c-0.1-0.11-0.2-0.14,0.14-0.28c-0.07,0.04-0.06,0.07-0.08,0.12c-0.49,0.6,0.97-0.46,0.24-0.03
			c0.49,0.27,0.89-0.59,2.14-1.18l-0.04,0.17c0.92-0.62,1-0.62,5.85-2.55l-0.07,0.06c0.33-0.08,0.3-0.33,0.63-0.34l-0.05,0.04
			c0.6-0.14,0.07-0.23,4.08-1.19l0.03,0.1c-0.01-0.04,0.17-0.13,0.57-0.16c0.04-0.24-0.31,0.3,1.21-0.33
			c-0.01,0.05-0.11,0.09-0.19,0.13c0.33-0.19,0.09,0.09,0.39-0.08c-0.05-0.01,0.01-0.07,0.01-0.09c0.18,0.03,0.55-0.05,0.82-0.13
			l-0.04,0.08c0.48-0.09,0.99-0.24,1.53-0.33c-0.06-0.04,0.03-0.29,0.13-0.09c0.15-0.11-0.16,0.14,0.28,0.04
			c1.01-0.07-0.11-0.18,3.73-0.63c0.49-0.06,0.62-0.08,1.23-0.22c0.87-0.19,0.02-0.02,2.27-0.09l-0.03,0.01
			c1.58-0.09,0.01-0.15,4.04,0.05l-0.03-0.06c1.47-0.23-0.03-0.07,4.6,0.3l-0.17,0.09c0.9-0.03,1.78,0.27,2.65,0.32
			c-0.06,0.02-0.06,0.05-0.15,0.03c0.16,0.11,0.66,0.14,0.68,0.07c0.15,0.13,0.17,0.19,1,0.25c0.28,0.09,0.26,0.12,1.46,0.3
			c-0.07,0.15,0.51,0.27,0.73,0.27c-0.08,0.02,0.15,0.1,0.06,0.14c0.22,0.07,0.43,0.11,0.42,0.02c0.67,0.42,0.5,0.23,2.9,1.02
			c-0.06,0.02-0.13,0.03-0.19,0.03c0.81,0.37,0.7,0.28,2.32,1.06c-0.01,0.01-0.07,0-0.12-0.01c0.29,0.07-0.04,0,0.64,0
			c-0.25,0.09,0.42,0.31,0.05,0.39c0.07,0.02,0.16,0.04,0.17-0.01c0.08,0.06,0.09,0.1-0.02,0.1c0.34,0.16,0.26,0.02,0.55,0.2
			c-0.02,0.03-0.08,0.01-0.14,0.02c0.18,0.03,0.37,0.06,0.6,0.23c-0.03,0.01-0.01,0.05-0.08,0c0.41,0.26,0.79,0.32,1.21,0.67
			c-0.17-0.01-0.05,0.2,0.33,0.4l-0.06-0.12c0.2,0.15,0.44,0.21,0.58,0.38l-0.18-0.07l0.39,0.27l-0.18-0.2
			c0.25,0.1,0.08-0.15,0.33,0.09c-0.06-0.03,0.2,0.16,0.26,0.3l0,0c0.51,0.26-0.05,0.25,0.93,0.74c-0.21,0.05,0.18,0.36,0.36,0.34
			c-0.03-0.02-0.03,0-0.09-0.03c0.35,0.31,0.47,0.45,0.56,0.57l-0.1-0.08c0.39,0.41-0.04-0.03,1.46,1.39l-0.06-0.08
			c0.52,0.35,0.09,0.41,1.28,1.44l-0.08-0.02c0.68,0.78,0.46,0.66,1.72,2.41c-0.13-0.11-0.19-0.19,0.23,0.36
			c0.05-0.08,0-0.09,0-0.22c0.08,0.03,0.19,0.15,0.26,0.26c-0.18-0.18-0.11,0.07-0.09,0.18c0.01-0.03,0.05-0.01,0.07-0.03
			c0.03,0.32,0.17-0.04,0.29,0.34c-0.16-0.05-0.04-0.04,0.03,0.27c0.11,0.19,0.36,0.3,0.37,0.53c-0.07-0.07-0.13-0.19-0.2-0.26
			c0.01,0.25,0.04,0.25,0.1,0.41l-0.01-0.02l0.13,0.35l-0.02-0.1c0.36,0.13,0.29,0.48,0.59,0.76c-0.11-0.15-0.39-0.1,0.06,0.69
			c-0.02,0.02,0.02,0.09,0.02,0.14c-0.08-0.51,0.74,1.48,1.14,2.92c-0.27-0.18-0.26-0.3-0.07,0.26c0.09,0.21,0.17,0.57,0.28,0.62
			l0.01-0.15c-0.06,0.03-0.22-0.02-0.09-0.3l0.05,0.14c0.18-0.2-0.18-0.56-0.24-0.85c0.19,0.08,0.3,0.26,0.12-0.18
			c0-0.26,0.08-0.03-0.14-0.55c0.04,0.03,0.09-0.06,0.1,0.07c-0.01-0.39-0.29-0.54-0.23-0.71l0.02,0.03
			c-0.2-1.03-0.52-0.83-1.15-2.81c0.14-0.05,0,0.13-0.34-0.56c0.05,0-0.23-0.59-0.34-0.88l0.02,0c-0.14,0.01-0.29-0.27-0.44-0.46
			c0.07-0.03,0.12-0.01,0.21,0.08c-0.03-0.01,0.04-0.05-0.32-0.37c0.34-0.03,0.16,0.06-0.6-0.72c-0.03-0.02-0.1-0.08-0.13-0.14
			l0.16,0.08c-0.07-0.04-0.3-0.42-0.04-0.13c-0.35-0.6-0.13-0.25-1.04-1.34l0,0c-0.32-0.14-0.68-1.12-2.03-2.36
			c0.2,0.11-0.02-0.09,0.2,0.13c-0.19-0.22-0.2-0.31-0.49-0.46c0.14,0.01-0.08-0.23-0.2-0.31l0.1,0.19c-0.12-0.1-0.21-0.2-0.31-0.3
			l0.13-0.03c-0.42-0.47-0.98-0.94-2.15-1.71c0.05,0.39-0.39-0.65-0.42-0.38c-0.25-0.26-0.43-0.34-0.71-0.57
			c0,0.08-1.05-0.31-0.62-0.5c-0.32-0.12-0.48-0.4-0.64-0.5l0.17,0.06c-0.1-0.04-0.17-0.08-0.25-0.13l0.04,0.12
			c-0.08-0.06-0.16-0.11-0.14-0.14c-0.25,0.02-0.14,0.24-0.59-0.18c0.34,0.24,0.04-0.09,0.31,0.04c-0.34-0.28-0.13-0.22-0.74-0.34
			c0.08-0.03-0.03-0.15-0.09-0.18c0.11,0.07-0.1,0.13-0.54-0.08l0.13,0.1c-0.35,0.05-0.58-0.45-1.61-1.01
			c0.06,0.12,0.08,0.11-0.18,0.08l0.55,0.18l-0.39-0.04c0.16,0.07,0.35,0.1,0.44,0.16c-0.19-0.01-0.16-0.02-0.12,0.07
			c-0.76-0.38-0.21-0.54-1.75-0.95l0.15,0.1c-0.1,0.05-0.31-0.11-0.48-0.14c0.04-0.06-0.05-0.12-0.1-0.19
			c-0.55-0.05-0.41-0.14-1.55-0.33c0.14-0.03-0.02-0.14,0.26-0.1c-0.13-0.04-0.24-0.1-0.26-0.05c0.17,0.05-0.37-0.22-0.87-0.06
			c-0.25-0.21-0.51-0.35-2.36-0.75c0.01-0.01,0.01-0.02,0.02-0.02c-0.83-0.11,0.01-0.06-1.28-0.36l0.07,0
			c-0.32-0.09-0.1-0.08-1.96-0.11c0.01-0.03,0.02-0.05,0.03-0.07c-0.06,0.06-0.22,0.11-0.53-0.16l0.29,0.04
			c-0.13-0.14-0.37-0.05-0.52-0.09l0.03-0.05c-0.45-0.05-0.47,0.1-0.94,0.04l0.04,0.05c-0.7,0.09-0.2-0.35-1.47-0.09
			c0.18-0.09,0.55-0.12,0.78-0.15c-0.17-0.02-0.58-0.04-0.68,0.02c0.07-0.01,0.16-0.04,0.23-0.01c-1.44,0.1-0.06,0.05-3.87-0.28
			c0.25,0.21-0.21,0.01-0.16,0.22c-0.38,0.05-0.56-0.13-0.99-0.36c0.05,0.07-0.12,0.11-0.33,0.09l0.29,0.09
			c-0.5,0.13-0.44-0.16-0.94-0.03l0.16-0.07c-0.42-0.01-0.48,0.04-3.06,0.36l0.04-0.06c-0.31,0.09-0.18-0.04-0.81,0.13l0.12-0.07
			c-0.61-0.01-0.91,0.26-1.4,0.35c-0.02-0.15-0.5-0.14-0.74-0.13l0.01-0.02c-0.96,0.07,0.02,0.27-3.82,0.7
			c0.05,0.03-0.08,0.21-0.44,0.2c-1.04,0.3-0.33,0.05-3.15,0.79l-0.02-0.1c-0.42,0.09-0.63,0.12-1.14,0.3l0.08-0.1
			c-0.86,0.52-0.37,0.23-2.16,0.83c0-0.02,0-0.07,0.08-0.1c-0.23,0.07-0.94,0.2-0.78,0.37c-0.05-0.12-0.51,0.17-0.76,0.32l0.01-0.11
			c0,0.04-0.3,0.2-0.93,0.54l0.09-0.1c-0.4,0.16-0.14,0.32-0.89,0.34c0.11-0.06-0.3,0.28-0.24,0.14c-0.48,0.05,0.06,0.09-0.97,0.37
			c0.33-0.14,0.59-0.25,0.57-0.24c-0.03-0.08-0.31,0.02-0.14-0.19c-0.21,0.13,0.04,0.06-0.81,0.51l0.02-0.02
			c-0.35,0.18-0.22,0.03-0.83,0.32c0.05,0.08-0.12,0.15,0.07,0.15c-0.04,0.01,0.06-0.03,0.16-0.1l-0.01,0.01
			c0.08-0.05,0.2-0.1,0.32-0.15c-0.79,0.65-0.69,0.1-2.43,1.25c0.25-0.24,0.26-0.2-0.76,0.41l0.03-0.06
			c-0.84,0.26-1.04,0.96-1.97,1.26c0.06-0.02,0.1-0.03,0.12-0.02c-0.5,0.07-2.13,1.51-4.46,3.25l0.1,0.01
			C242.85,75.22,242.95,74.77,243.1,75.84z M254.41,68.42c0.04-0.05-0.3,0.2-0.21,0.01C254.18,68.47,254.31,68.43,254.41,68.42z
			 M253.86,68.67c-0.05,0.03,0,0-0.13,0.07C253.75,68.72,253.79,68.7,253.86,68.67z M252.95,68.38c0.03-0.08,0.02-0.09,0.15-0.09
			C253.07,68.31,253.09,68.27,252.95,68.38z"
              />
            </g>
          </g>
          <g id="montañas-d">
            <g>
              <g className="st2">
                <path
                  className="st3"
                  d="M1131.3,421.31c18.63-1.7,33.83-15.2,51.32-21.83c6.11-2.31,12.5-3.79,18.61-6.1
				c8.16-3.09,15.96-7.72,24.66-8.5c6.12-0.55,12.3,0.87,18.41,0.19c4.91-0.54,9.57-2.41,14.34-3.69
				c15.13-4.05,31.14-2.13,46.68-0.18c14.22,1.78,29.93,3.22,41.66-5.01c3.53-2.47,6.46-5.69,9.35-8.88
				c7.2-7.92,14.39-15.83,21.59-23.75c1.24-1.36,2.52-2.8,3.02-4.57c0.5-1.77-0.03-3.99-1.68-4.81c-1.07-0.53-2.33-0.38-3.5-0.13
				c-18.74,4.02-26.06,28.49-43.75,35.88c-15.19,6.35-32.17-1.89-48.58-3.17c-10.8-0.84-21.61,1.41-32.01,4.45
				c-6.69,1.95-13.46,4.27-20.43,4.05c-5.84-0.18-11.53-2.13-17.37-2.06c-13.99,0.15-24.95,11.32-35.64,20.35
				c-14.21,12-30.65,21.35-48.22,27.43"
                />
              </g>
              <g className="st2">
                <path
                  className="st3"
                  d="M1407.09,334.09c6.61,8.37,13.5,17.4,14.44,28.02c0.36,4.04-0.15,8.28,1.41,12.02
				c1.87,4.49,6.35,7.34,10.89,9.1c4.68,1.82,9.68,2.79,14.7,2.84c5.27,0.06,10.99-0.77,15.45,2.06c2.04,1.29,3.59,3.25,5.61,4.56
				c2.21,1.43,4.84,2.02,7.41,2.58c6.63,1.44,13.4,2.9,20.15,2.14c4.89-0.55,9.6-2.24,14.5-2.61c9.23-0.7,18.23,3.33,27.49,3.47
				c5.52,0.08,10.98-1.22,16.5-1.16c9.99,0.11,20.44,4.6,29.59,0.58c-8.86-1.06-17.72-2.13-26.58-3.19
				c-6.57-0.79-13.14-1.58-19.66-2.72c-18.27-3.21-35.93-9.18-53.5-15.13c-6.01-2.03-12.17-4.16-17-8.27
				c-3.07-2.62-5.46-5.94-8.19-8.91c-7.2-7.86-16.63-13.24-25.9-18.5c-7.66-4.34-16.58-8.82-24.88-5.89"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    className="st3"
                    d="M1836.94,411.65c-4.6,6.54-15.62,6.98-24.99,5.28c-9.38-1.7-19.03-4.9-27.99-3.42
					c-5.96,0.99-10.92,3.97-16.33,6.07c-12.45,4.85-27.13,5.02-41.4,5.12c-2.01,0.01-4.77-0.5-5.08-2.25
					c-0.23-1.28,1.16-2.12,2.39-2.68c13.07-5.85,27.79-9.38,39.82-16.56c3.93-2.34,7.6-5.09,12.19-6.47
					c6.91-2.08,14.99-0.81,22.55,1.07c11.87,2.95,23.46,7.35,34.28,13.01"
                  />
                </g>
                <g>
                  <path
                    className="st8"
                    d="M1678.42,333.38c-26.53,0.35-54-6.71-77.23-19.84c-12.29-6.95-23.55-15.6-36.91-20.63
					c-14.42-5.43-30.74-6.16-44.63-2c-0.4-4.35,4.76-6.94,9.31-8.53c12.13-4.23,25.44-7.8,38.97-5.26
					c11.02,2.07,20.84,7.94,30.6,13.24c28.46,15.46,59.45,27.19,91.35,34.56c-3.89,2.1-7.69,4.33-11.37,6.67"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M1815.51,299.55c-4.73,23.54-32.7,36.39-59.35,43.18s-56.25,11.13-74.84,28.06
					c-11.82,10.76-19.31,26.57-36.4,30.55c-7.98,1.86-16.82,0.71-25.19,1.38c-9.5,0.76-18.23,3.86-26.81,6.92
					c-13.99,5-27.99,9.99-41.98,14.99c10.19-8.8,20.39-17.59,30.58-26.39c5.44-4.69,10.95-9.43,17.77-12.66
					c11.46-5.41,25.94-6.21,36.63-12.58c10.41-6.19,15.73-16.72,23.52-25.22c15.04-16.41,38.87-24.93,61.82-32.89
					c29.86-10.36,61.32-20.92,94.85-17.35"
                  />
                </g>
                <g>
                  <path
                    className="st8"
                    d="M1468,366.8c9.3,5.49,21.29,4.24,31.21,1.43c9.92-2.82,19.57-7.01,30.38-6.65c6.2,0.2,12.35,1.9,18.52,3.05
					c17.52,3.25,35.65,1.95,51.25-3.68c14.57-5.26,28.74-14.4,45.09-11.21c-0.17,10.38-13.96,14.97-25.58,17.37
					c-16.6,3.43-33.21,6.86-49.81,10.3c-6.79,1.4-13.87,2.81-21,1.47c-6.66-1.25-12.73-4.8-19.38-6.18
					c-8.98-1.85-17.83,0.44-26.54,1.83s-18.85,1.58-26.23-3.51"
                  />
                </g>
                <g>
                  <path
                    className="st0"
                    d="M1361.84,442.71c-4.04-4.02-9.02-7.37-14.46-9.73c-4.15-1.8-8.73-3.13-11.98-6.06
					c-0.93-0.84-1.78-2.04-1.31-3.01c0.58-1.18,2.57-1.06,4.1-0.67c4.83,1.24,9.33,3.58,13.57,6.14c-4.02-6.2-6.54-13-7.34-19.77
					c-0.25-2.1-0.32-4.28,0.59-6.12c0.91-1.84,3.03-3.27,5.46-3.02c3.98,0.41,6.2,4.5,7.45,7.87c1.97,5.3,3.95,10.6,5.92,15.89
					c-1.38-8.21,3.36-16.3,11.52-19.64c5.48,8.53,3.22,18.33,0.8,27.07c-1.2,4.33-3.08,9.28-8.13,10.27"
                  />
                </g>
              </g>
            </g>
            <path
              className="st10"
              d="M1889.29,296.23l1.38,0.25c-1.68-0.44-0.08-0.24-13.53-2.4c1.43-0.09-2.84-0.37-2.43-0.61
		c-1.06,0.11-3.72-0.36-5.55-0.46c0.1-0.01,0.31-0.02,0.47,0.01c-3.59-1.01-1.09,0.54-4.17-0.71c0.04,0.15-1.9-0.08-1.83,0.04
		c-0.59-0.46-6.05-0.29-4.19-0.7c-3.4,0.26-0.44-0.07-10.81-0.4c0.13,0.4,0.19,0.24-1.35,0.16c3.22,0.13-2.95-0.49-0.45-0.23
		c-2.83-0.86,0.35,0.05-12.53-0.15l0.56-0.18c-11.95,0.85,1.02-1.17-32.65,2.15l0.43-0.1c-1.74,0.17-1.92,0.56-3.55,0.64
		c0.78-0.17-8.77,0.98-30.38,5.39c0.04-0.06,0.35-0.15,0.69-0.24c-2.82,0.68-0.54,0.12-5.86,1.47l0.24-0.17
		c-2.51,0.64-5.3,1.36-8.09,2.18c2.18-0.95-21.81,6.7-37.06,12.71l0.14-0.07c-3.12,1.46-7.86,3.06-19.16,7.91l0.18,0.02
		c-3.88,2.03-4.81,1.81-21.17,10.68l0.61-0.56c-3.84,2.46-7.93,4.93-11.35,7.82c0.18-0.2,0.16-0.24,0.5-0.51
		c-3.41,2.85-6.12,4.26-13.91,15.01c0.12-0.34-0.53,0.76-0.4,0.35c-0.62,1.05-1.15,2.03-0.94,1.91c-1.99,3.15-1.42,2.88-5.78,11.62
		c0.17-0.18-0.44,0.82-1.24,2.47c0.07-0.19,0.14-0.36,0.21-0.53c-2.89,3.48-1.44,3.5-9.16,8.23c0-0.02,0.25-0.16,0.49-0.29
		c-1.22,0.5,0.11,0.25-2.52,1.33c0.66-0.36-1.4,0.35-1.29-0.16c-0.33,0.09-0.75,0.22-0.66,0.27c-0.51,0.07-0.68,0.05-0.18-0.11
		c-1.85,0.34-1.17,0.36-2.88,0.54c0-0.05,0.34-0.1,0.51-0.18c-0.85,0.19-1.72,0.36-3.18,0.4c0.09-0.04-0.09-0.06,0.35-0.09
		c-2.5,0.17-4.26,0.44-7.03,0.59c0.36-0.1,0.74-0.42-2.59,0.13l0.61,0c-0.66,0.11-0.64,0.07-3.52,0.7l0.94-0.28
		c-0.8,0.19-1.6,0.35-2.39,0.57c0.45-0.11,0.91-0.16,1.37-0.24c-1.27,0.38,0.18,0.17-1.51,0.54c0.33-0.11-1.25,0.35-1.94,0.43l0,0
		c-2.31,0.91-1.12-0.08-5.71,2.1c0.51-0.51-1.9,0.58-2.31,0.98c0.15-0.08,0.07-0.06,0.38-0.23c-2.5,1.35-2.78,1.32-3.52,1.61
		l0.38-0.2c-1.62,0.77-1.59,0.74-8.97,5.2l0.45-0.25c-0.19,0.74-0.64-0.34-8.19,5.48l0.25-0.26c-4.6,3.39-3.29,2.13-12.3,8.89
		c0.66-0.58,1-0.83-1.76,1.3c0.25-0.04,0.34-0.16,0.87-0.44c-0.25,0.27-0.85,0.78-1.41,1.19c0.97-0.81-0.12-0.14-0.6,0.13
		c0.09-0.03-0.03,0.12,0.03,0.12c-1.31,0.7-0.06,0.33-1.76,1.4c0.42-0.66-1.27,0.82-3.71,2.65c0.38-0.3,0.92-0.73,1.3-1.03
		c-1,0.53-1.08,0.64-1.77,1.09l0.07-0.04l-1.61,1.05l0.42-0.25c-0.96,1.11-2.34,1.7-3.79,2.97c1.42-1.23,0.18-0.28,0.22-0.48
		c-0.43,0.24-1.07,0.61-0.92,0.46c-0.81-0.03-1.93,2.71-16.85,9.69c1.42-1-1.29,0.34-4.3,1.64c0.73-0.23,0.34-0.14,2.22-0.79
		l-0.69,0.28c0.83,0.04,2.78-1.12,4.15-1.7c-0.75,0.63,0.19,0.3,3.37-1.33c-0.16,0.13,0.22,0.05-0.37,0.32
		c1.74-0.76,2.64-1.53,3.33-1.75l-0.13,0.09c3.52-2.15-1.61,1.17,12.99-8.19c0,0.01-0.01,0.02-0.01,0.04
		c0.03-0.02,0.04-0.04,0.03-0.05c0.7-0.51,1.56-1.13,2.65-1.93c-0.05,0.11,2.64-1.76,3.94-2.64l-0.02,0.05
		c0.14-0.33,1.46-1.25,2.39-1.98c0.03,0.11-0.11,0.27-0.55,0.65c0.1-0.07,0.3-0.14,1.84-1.49l-0.14,0.28
		c0.48-0.41,0.68-0.62,4.59-3.78l-0.58,0.57c0.59-0.38,1.29-1.13,0.59-0.36c1.57-0.97,2.07-1.44,6.92-5.08c-0.01,0,0,0-0.01,0
		c1.96-1.57,0,0,6.33-4.32l0.02,0.04c0.94-0.54,0.86-0.69,6.86-4.25c-0.94,0.65,0.38-0.13-0.97,0.64c1.29-0.69,1.6-0.82,2.92-1.68
		c-0.41,0.37,1.04-0.39,1.64-0.75l-0.94,0.41c0.68-0.39,1.28-0.71,1.91-1.01l-0.26,0.32c5.82-1.45,7.41-6.08,27.94-6.73l-0.88,0.11
		c0.53-0.06,0.96-0.09,1.4-0.12l-0.51-0.08c0.52-0.01,0.96-0.05,0.95,0c1.25-0.46,0.14-0.31,2.97-0.55
		c-2.03,0.23-0.05,0.15-1.26,0.35c2.63-0.21,0.8,0.03,3.85-0.81c-0.25,0.16,0.54,0.04,0.88-0.09c-0.68,0.13,0.36-0.24,2.4-1.19
		l-0.78,0.31c1.23-0.89,4.97-1.89,7.81-5.65c-0.42,0.33-0.45,0.41,0.25-0.63c-0.58,0.78-1.18,1.56-1.85,2.27
		c0.39-0.5,0.79-0.98,1.12-1.51c-0.53,0.7-1.2,1.42-1.57,1.8c0.55-0.71,0.5-0.63,0.15-0.39c0.97-0.74,1.53-1.82,2.42-2.87l0.22,0.21
		c1.32-1.65,1.9-3.2,3.17-5.57l-0.44,0.75c1.56-3.57,0.64-0.67,4.76-10.23c-0.21,0.59,0.29-0.2-0.28,1.06
		c2.78-5.23,2.47-5.81,10.85-14.22c-0.03,0.05-0.01,0.06-0.05,0.09c3.14-2.86-0.07-0.11,5.2-4.24l-0.26,0.23
		c1.02-0.59,0.13-0.34,7.9-5.81c0,0.04-0.01,0.08-0.01,0.11c0.21-0.24,0.81-0.7,2.39-1.29l-1.25,0.76c0.74-0.23,1.61-1,2.31-1.34
		l-0.05,0.12c1.95-1.11,1.87-1.32,4.01-2.45l-0.25,0.06c2.87-1.71,1.46-0.33,6.62-3.42c-0.73,0.53-2.35,1.39-3.31,1.98
		c0.77-0.39,2.63-1.31,2.99-1.6c-0.3,0.17-0.68,0.41-1,0.53c0.88-0.68,0.68-0.59,17.87-7.85c-1.28,0.22,0.95-0.42,0.63-0.61
		c0.37-0.17-0.02-0.01,1.13-0.47c0,0-0.01,0-0.1,0.03c1.08-0.4,2.07-0.59,3.77-1c-0.27,0,0.52-0.39,1.49-0.75l-1.4,0.43
		c2.27-1.13,2.12-0.59,4.42-1.7l-0.71,0.39c5.04-2.03,1.51-0.76,14.36-5.76l-0.2,0.15c0.9-0.39,0.4-0.1,3.84-1.44l-0.59,0.3
		c1.75-0.58-0.05,0.01,6.77-2.58c0.06,0.21,2.37-0.46,3.52-0.8l-0.03,0.04c2.96-1.29,20.78-6.42,36.99-9.76l-0.01,0.15
		c2.13-0.34,3.2-0.51,5.82-1.01l-0.52,0.19c4.98-1.2,2.35-0.75,11.42-2c-0.04,0.04-0.11,0.11-0.53,0.17
		c1.23-0.16,4.76-0.49,4.28-0.72c0.05,0.19,2.69-0.36,4.09-0.59l-0.2,0.16c0.5-0.09-0.12-0.06,5.32-0.72l-0.59,0.14
		c1.4-0.17,3.23-0.46,11.36-0.82c-1.8,0.06-2.99,0.1-3.12,0.11c0.01,0.11,1.47,0.09,0.32,0.31c1.18-0.09-0.04-0.1,4.77-0.38
		l-0.15,0.02c1.95-0.11,1.08,0.03,4.48-0.09c-0.24-0.15,0.64-0.32-0.94-0.16c0.01,0,0.03-0.01,0.05-0.01
		c-0.48,0.02-1.1,0.05-1.81,0.08c3.24-0.23,3.37-0.34,18.11-0.2l-0.3,0.05c3.72,0.82,3.13-1.6,38.87,3.78l-0.35-0.17
		c1.01,0.21,3.21,0.54,3.02,0.62c0.84-0.11,9.04,2.24,7.25,1.16C1892.95,297.69,1893.64,297.05,1889.29,296.23z M1820.93,291.57
		c-0.04-0.02-0.55-0.03-0.94-0.04C1820.33,291.49,1821.23,291.41,1820.93,291.57z M1822.95,291.4c0.31-0.02,0,0,0.75-0.04
		C1823.55,291.37,1823.33,291.38,1822.95,291.4z M1826.59,292.17c-0.51,0.15-0.23,0.08-0.9,0.07
		C1825.92,292.21,1825.69,292.26,1826.59,292.17z M1854.21,292.35c0.14,0.03,0.27,0.06,0.39,0.08
		C1854.38,292.4,1854.26,292.38,1854.21,292.35z"
            />
            <path
              className="st10"
              d="M1877.02,424.22c-0.23-0.17-0.25-0.12-1.87-0.39c0.24-0.08-0.1-0.39-1.35-0.75c0.1-0.01-0.01-0.16,0.23-0.03
		c-0.68-0.4-1.23-0.35-1.44-0.62l0.07,0.02c-2.05-0.95-2.23-0.9-5.6-3.14c0.16-0.16,0.12,0.2-1.22-0.66
		c0.07-0.03-1.1-0.77-1.66-1.12l0.03-0.02c-0.2,0.1-0.79-0.24-1.25-0.43c0.06-0.09,0.16-0.09,0.4-0.02
		c-0.05-0.03-0.11-0.13-0.94-0.35c0.46-0.17-0.15-0.22-2.27-0.77l0.36,0.01c-0.29-0.17-0.69-0.09-0.25-0.16
		c-1.42-0.63-0.6-0.3-3.44-1.15l0,0c-1.16-0.07-0.17-0.37-6.42-1.68c0.49,0.01-0.15-0.11,0.49,0.04c-0.6-0.16-0.72-0.28-1.4-0.27
		c0.25-0.08-0.43-0.24-0.73-0.26l0.41,0.17c-0.33-0.04-0.61-0.11-0.9-0.17l0.19-0.13c-1.26-0.31-2.78-0.51-5.76-0.81
		c0.51,0.51-1.44-0.64-1.17-0.25c-0.72-0.2-1.15-0.18-1.9-0.35c0.03,0.1-2.19,0.12-1.63-0.37c-0.71,0-1.28-0.28-1.66-0.37l0.37,0.02
		c-0.22-0.01-0.4-0.04-0.58-0.07l0.19,0.15c-0.21-0.06-0.39-0.09-0.38-0.14c-0.51,0.18-0.09,0.36-1.26-0.01
		c1.17,0.25,0.64-0.48-1.05-0.26c0.13-0.07-0.17-0.2-0.32-0.21c0.28,0.06-0.15,0.22-1.11,0.05l0.33,0.1
		c-1.63-0.22-2.4-0.75-6.93-1.82l0.33,0.15c-1.08-0.13-0.93-0.47-4.57-1.23c0.28-0.02-0.07-0.21,0.51-0.1
		c-1.4-0.32-0.85,0.05-7.27-2.27c0.02-0.01,0.03-0.02,0.04-0.02c-1.73-0.48,0.03-0.06-2.64-1.02l0.15,0.03
		c-0.65-0.28-0.2-0.13-4.04-0.97c0.03-0.04,0.05-0.06,0.07-0.08c-0.14,0.06-0.48,0.06-1.07-0.45l0.6,0.17
		c-0.25-0.26-0.77-0.23-1.07-0.35l0.06-0.06c-0.92-0.24-0.99-0.05-1.97-0.34l0.09,0.08c-1.45-0.15-0.42-0.61-3.06-0.69
		c0.4-0.06,1.17,0.03,1.66,0.07c-0.35-0.1-1.22-0.27-1.42-0.22c0.15,0.01,0.34,0.01,0.48,0.07c-2.06-0.06-0.55-0.3-8.09-1.7
		c0.49,0.37-0.44-0.05-0.38,0.26c-0.94-0.14-0.29,0.08-2.04-0.75c0.09,0.12-0.28,0.13-0.71,0.04l0.6,0.2c-1.08,0.05-0.9-0.36-2-0.26
		l0.35-0.08c-1.02-0.07-1.09-0.12-6.57,0.03l0.1-0.08c-0.42,0.07-0.16-0.03-1.74,0.11l0.28-0.09c-1.3-0.07-1.97,0.31-3.05,0.42
		c-0.01-0.23-1.04-0.22-1.55-0.19l0.01-0.03c-2.43,0.52-7.36,0.69-15.8,3.67l-0.03-0.14c-0.89,0.25-1.31,0.44-2.36,0.88l0.18-0.18
		c-1.84,1.14-0.85,0.51-4.42,2.21c0-0.04,0-0.11,0.16-0.2c-0.47,0.23-1.88,0.8-1.57,0.97c-0.11-0.17-1.02,0.54-1.5,0.9l0.01-0.17
		c-0.02,0.08-0.61,0.48-1.84,1.35l0.17-0.21c-0.73,0.46-1.57,0.92-4.11,2.48c0.64-0.41,1.17-0.72,1.13-0.68
		c-0.07-0.11-0.6,0.2-0.28-0.21c-0.41,0.3,0.07,0.07-1.6,1.24l0.04-0.05c-0.68,0.47-0.43,0.18-1.64,0.94c0.1,0.11-0.25,0.3,0.13,0.2
		c-0.08,0.04,0.13-0.08,0.31-0.24l-0.01,0.02c0.17-0.11,0.39-0.25,0.64-0.42c-1.59,1.4-1.38,0.61-4.87,3.13
		c0.12-0.1,0.28-0.19,0.33-0.27c-0.06-0.03-1,1.01-1.88,1.22l0.08-0.11c-2.16,0.52,0.06,1.46-14.92,6.48
		c0.05,0.31-1.68,0.31-2.91,1.4l-0.59,0.08c0.86,0.15,2.53-0.75,2.6-0.35c0.19-0.2,0.74,0.19,3.11-0.89c-0.5,0.41,1.18-0.31,1.11,0
		c0.35-0.36,1.47-0.62,2.16-1c-0.03,0.03-0.1,0.1-0.17,0.11c0.14,0.45,1.9-1.41,1.74-0.49c-0.09-0.14,0.8-0.5,0.58-0.56
		c0.42-0.05,0.35-0.08,0.61-0.02c-0.39-0.1,1.95-1.16,1.19-0.43c1.12-1.12,0.15,0.09,3.91-2.4c-0.2-0.22-0.26-0.18,0.37-0.5
		c-1.14,1,0.8-0.02,0.27,0.09c0.95,0.26,1.92-1.22,4.51-2.74l-0.1,0.29c1.02-0.83,1.84-1.32,11.66-7.19l-0.14,0.13
		c0.67-0.3,0.59-0.65,1.25-0.84l-0.1,0.08c1.53-0.36,1.5-1.11,12.14-4.52c-0.02,0.06-0.25,0.15-0.42,0.24
		c1.32-0.47,0.24-0.09,2.59-0.69l-0.09,0.13c4.4-0.98,4.23-0.87,19.64-1.69l-0.07,0.01c3.37,0.23,0.06-0.37,8.55,1.11l-0.05-0.1
		c1.78,0.32-0.1-0.32,9.62,2.19l-0.37,0.06c1.87,0.34,3.7,1.16,5.51,1.59c-0.12,0-0.14,0.05-0.31-0.01
		c0.46,0.17,2.56,0.94,6.58,2.05c-0.12,0.2,1.11,0.57,1.56,0.61c-0.16,0.02,0.34,0.19,0.15,0.22c0.48,0.16,0.94,0.26,0.89,0.12
		c1.55,0.73,1.15,0.6,6.41,1.8c-0.19,0.01-0.34,0.02-0.48,0.02c0.99,0.19,1.07,0.43,5.52,1.23c-0.01,0.02-0.13,0.02-0.25,0.01
		c0.72,0.02-0.49-0.06,1.27-0.19c-0.43,0.22,1.09,0.29,0.43,0.54c0.15,0.01,0.34,0,0.32-0.08c0.21,0.06,0.27,0.11,0.04,0.15
		c0.8,0.09,0.54-0.07,1.26,0.06c-0.01,0.05-0.16,0.04-0.24,0.09c0.38-0.03,0.76-0.06,1.36,0.08c-0.03,0.02,0,0.05-0.06,0.05
		c1,0.13,1.76,0.04,2.85,0.3c-0.19,0.08-0.28,0.23,1.04,0.36l-0.23-0.13c0.54,0.09,1.04,0.03,1.49,0.16l-0.41,0.01l1.02,0.12
		l-0.56-0.16c0.56-0.01-0.02-0.24,0.69-0.08c-0.15,0,0.54,0.08,0.8,0.24l0,0c1.15,0.02,0.28,0.38,2.52,0.36
		c-0.32,0.21,0.76,0.35,1.03,0.21c-0.07,0-0.04,0.02-0.19,0.02c0.95,0.15,1.34,0.26,1.65,0.35l-0.24-0.03
		c1.2,0.28-0.09-0.01,4.26,0.78l-0.21-0.07c1.32,0.1,0.81,0.59,4,1.01l-0.16,0.03c2.14,0.6,1.63,0.54,5.93,2.21
		c-0.27-0.04-0.34-0.08,0.82,0.38c-0.03-0.15-0.11-0.13-0.29-0.33c0.16,0.01,0.48,0.11,0.74,0.24c-0.51-0.17-0.08,0.17,0.1,0.33
		c-0.02-0.05,0.07-0.05,0.07-0.09c0.45,0.46,0.2-0.16,0.88,0.34l-0.07,0.03c0.17,0.06,1.31,0.52,0.48,0.35
		c0.42,0.22,0.94,0.24,1.25,0.58c-0.19-0.07-0.44-0.22-0.64-0.29c0.33,0.37,0.39,0.37,0.68,0.56l-0.03-0.02l0.66,0.47l-0.15-0.14
		c0.69,0.01,1.08,0.57,1.88,0.79c-0.78-0.22-0.18,0.04-0.3,0.14c0.15,0.14,0.4,0.34,0.3,0.33c0.22,0.08,2.11,1.21,7.58,3.27
		c-0.09,0.03,0,0.01-0.42,0.09c0,0,0.01,0,0.01,0c-0.27,0.07,1.89,0.01,0.87,0.22c0.14-0.04,0.37,0.12,1.01,0.07
		c-0.11,0.02-0.28-0.02-0.52-0.2L1877.02,424.22z M1744.71,411.26c0.08-0.16,0.04-0.14,0.3-0.23
		C1744.95,411.1,1745,411.01,1744.71,411.26z M1734.7,417.46c0.09-0.03,0.15-0.05,0.18-0.04
		C1734.82,417.44,1734.76,417.45,1734.7,417.46z M1746.22,411.4c0.05-0.04,0.13-0.09,0.26-0.18
		C1746.37,411.3,1746.48,411.22,1746.22,411.4z M1747.15,410.67c-0.04,0.06,0.2-0.06,0.38-0.13
		C1747.4,410.64,1747.05,410.92,1747.15,410.67z M1829.56,409.3c-0.63-0.19-0.89-0.34-1.17-0.5l1.18,0.21l-0.79-0.02
		c0.35,0.09,0.76,0.11,0.97,0.18C1829.37,409.18,1829.42,409.16,1829.56,409.3z"
            />
            <path
              className="st10"
              d="M1888.57,304.16l1.26,0.08c-1.16-0.16-1.38-0.41-12.39-0.59c1.27-0.21-2.58-0.12-2.24-0.39
		c-0.94,0.19-3.37-0.03-5.03,0.1c0.09-0.02,0.28-0.05,0.42-0.04c-1.46-0.36-0.16,0.4-3.81-0.24c0.06,0.16-1.98,0.13-1.6,0.27
		c-0.38-0.38-5.56,0.19-3.87-0.36c-3.47,0.58-0.11-0.13-9.75,0.49c0.14,0.39,0.17,0.22-1.2,0.29c2.24-0.14-0.8-0.31-0.43-0.2
		c-2.58-0.66,0.3-0.11-11.3,0.57l0.5-0.22c-7.73,1.08-0.65-0.78-29.38,3.08l0.39-0.1c-1.57,0.16-1.72,0.55-3.19,0.62
		c0.77-0.18-8.08,1.01-27.4,5.01c0.04-0.06,0.34-0.15,0.66-0.25c-2.6,0.63-0.49,0.12-5.35,1.26l0.22-0.16
		c-7.08,1.54-26.38,7.27-41.07,12.51l0.13-0.06c-6.8,2.9-0.17-0.34-17.35,7.11l0.17,0.02c-3.55,2.04-4.47,1.83-18.57,10.71
		l0.51-0.54c-3.27,2.51-6.84,4.93-9.72,7.77c0.16-0.19,0.13-0.23,0.42-0.49c-3.08,2.64-4.93,4.26-12.43,13.68
		c0.13-0.3-0.54,0.64-0.4,0.27c-0.64,0.89-1.17,1.73-0.97,1.65c-2.26,2.9-1.67,2.23-7.78,11.94c0.3-0.63,0.53-1.09,0.69-1.38
		c-1.27,1.4,1.37-0.82-7.58,9.97c-0.01-0.02,0.16-0.22,0.31-0.41c-1,1.2,0.75-0.83-1.5,2.14c0.39-0.77-1.8,1.59-1.11,0.43
		c-0.21,0.23-0.47,0.53-0.38,0.53c-0.37,0.29-0.51,0.35-0.2-0.01c-1.27,1.13-0.71,0.87-1.94,1.8c-0.03-0.04,0.21-0.25,0.3-0.39
		c-0.56,0.55-1.12,1.13-2.23,1.83c0.05-0.07-0.1-0.01,0.22-0.24c-1.83,1.3-3.06,2.34-5.29,3.45c0.23-0.28,0.5-0.5-2.14,0.88
		l0.52-0.16c-1.04,0.53-1.92,1.08-2.85,1.45l0.75-0.44c-0.65,0.33-1.32,0.63-1.98,0.93l1.16-0.43c-1.04,0.56,0.21,0.16-1.24,0.74
		c0.29-0.14-1.08,0.46-1.69,0.59l0,0c-2.03,0.96-0.95,0.07-5.09,2c0.47-0.47-1.73,0.41-2.14,0.73c0.14-0.06,0.07-0.05,0.36-0.18
		c-1.91,0.68-2.74,0.92-3.4,1.11l0.46-0.17c-1.6,0.52-1.36,0.35-8.82,3.06l0.45-0.13c-0.3,0.63-0.52-0.37-8.42,2.86l0.28-0.17
		c-4.85,1.74-3.38,1.01-12.98,4.35c0.73-0.32,1.09-0.45-1.87,0.63c0.23,0.05,0.34-0.04,0.87-0.12c-0.28,0.17-0.94,0.45-1.54,0.65
		c1.06-0.44-0.07-0.17-0.56-0.08c0.09,0.01-0.06,0.1-0.01,0.12c-1.91,0.6-0.48,0.06-5.81,2.04c0.41-0.16,0.99-0.39,1.4-0.55
		c-1.01,0.17-1.11,0.25-1.83,0.47l0.07-0.02l-1.67,0.49l0.43-0.1c-1.11,0.74-2.46,0.9-4.02,1.66c1.52-0.74,0.22-0.21,0.3-0.39
		c-0.43,0.1-1.08,0.27-0.92,0.17c-0.55,0.04-3.33,1.52-16.62,5.88c1.33-0.81-1.11,0.08-3.95,1.34c0.67-0.2,0.31-0.11,2.03-0.68
		l-0.63,0.25c0.75,0.05,2.55-0.91,3.84-1.33c-0.92,0.64,0.2,0.26,3.18-0.81c-0.16,0.1,0.2,0.09-0.37,0.25
		c1.66-0.43,2.56-1.03,3.22-1.08l-0.13,0.06c3.49-1.12,1.11-0.75,13.12-4.42c0,0.01-0.01,0.01-0.01,0.02c0.79-0.33-0.01,0,2.82-1.03
		c-0.07,0.09,2.74-0.82,4.1-1.21l-0.03,0.04c0.2-0.26,1.59-0.69,2.59-1.07c0,0.11-0.17,0.21-0.65,0.43c0.1-0.03,0.3-0.03,1.99-0.78
		l-0.19,0.21c1.27-0.73-0.03-0.02,4.98-2.02l-0.65,0.34c0.62-0.16,1.41-0.63,0.61-0.14c3.34-1.02,1.49-0.46,7.33-2.53
		c-0.01,0,0,0-0.01,0c2.1-0.86,0,0,6.57-2.19l0.01,0.05c1.55-0.41-0.26,0.07,6.92-2.33c-0.95,0.41,0.36-0.04-0.98,0.4
		c1.27-0.38,1.58-0.4,2.89-0.99c-0.42,0.28,1-0.17,1.58-0.41l-0.91,0.21c0.67-0.25,1.26-0.43,1.86-0.63l-0.27,0.27
		c4.52-1.97,4.14-0.26,24.15-10.98l-0.63,0.5c0.38-0.3,0.7-0.52,1.02-0.76c-0.14,0.06-0.29,0.12-0.44,0.17
		c0.4-0.25,0.71-0.51,0.73-0.46c0.7-0.97-0.06-0.33,1.99-1.87c-1.9,1.44-0.89,1.67,1.79-1.54c-0.12,0.23,0.45-0.18,0.66-0.43
		c-0.46,0.4,0.1-0.41,1.45-1.89c-0.17,0.18-0.35,0.36-0.53,0.52c0.55-1.02,2.46-2.35,5.66-6.52c-0.37,0.3-0.38,0.38,0.17-0.59
		c-0.48,0.72-0.98,1.43-1.47,2.15l0.85-1.45c-0.47,0.63-0.92,1.38-1.23,1.74c0.41-0.69,0.38-0.61,0.08-0.37
		c0.78-0.75,1.19-1.76,2-2.71l0.22,0.2c1.19-1.45,1.76-2.82,3.15-4.79l-0.47,0.62c1.88-3.23,0.94-1.08,5.47-8.59
		c-0.23,0.53,0.31-0.14-0.34,0.94c3.08-4.54,0.74-2.66,10.18-12.69c-0.02,0.04-0.01,0.05-0.03,0.08c2.69-2.71-0.06-0.07,4.51-4.04
		l-0.22,0.22c0.71-0.5-0.06-0.06,6.73-5.75c0,0.04,0,0.07,0,0.1c0.16-0.24,0.67-0.71,2.07-1.31c-0.37,0.24-0.72,0.51-1.08,0.77
		c0.64-0.27,1.4-0.99,2-1.35l-0.03,0.11c1.68-1.13,1.56-1.36,3.44-2.48l-0.22,0.07c2.47-1.74,1.3-0.36,5.76-3.47
		c-0.62,0.53-2.03,1.39-2.87,1.98c0.67-0.4,2.3-1.32,2.6-1.61c-0.26,0.17-0.59,0.41-0.87,0.53c1.02-0.76-0.23-0.36,15.86-7.6
		c-1.16,0.17,0.86-0.37,0.56-0.58c0.58-0.15,1.27-0.17,1.08-0.44c1.05-0.39,1.65-0.44,3.27-0.77c-0.25-0.02,0.47-0.36,1.35-0.66
		l-1.27,0.35c2.06-0.99,1.92-0.5,4.02-1.46l-0.65,0.35c4.59-1.71,1.35-0.71,13.09-4.84l-0.18,0.14c0.82-0.34,0.35-0.08,3.49-1.25
		l-0.54,0.27c2.65-0.75,3.98-1.44,6.17-2.18c0.06,0.21,2.15-0.35,3.2-0.63l-0.03,0.04c4.86-1.69,16.84-4.83,33.52-8.38l-0.01,0.15
		c1.92-0.33,2.88-0.52,5.25-0.99l-0.47,0.18c4.49-1.1,2.13-0.55,10.3-1.93c-0.03,0.04-0.09,0.11-0.47,0.17
		c1.11-0.15,4.3-0.46,3.86-0.7c0.05,0.18,2.43-0.33,3.69-0.59l-0.18,0.17c0.26-0.07,0.62-0.23,4.77-0.86l-0.53,0.15
		c2.54-0.38-0.32-0.05,10.23-1.08c-1.63,0.14-2.94,0.25-2.81,0.23c0.01,0.13,1.33,0.03,0.3,0.3c1.06-0.14-0.04-0.1,4.28-0.59
		l-0.13,0.03c1.75-0.19,0.98-0.01,4.03-0.28c-0.23-0.14,0.57-0.34-0.85-0.12c0.01,0,0.03-0.01,0.04-0.02
		c-0.43,0.04-0.99,0.09-1.63,0.15c2.81-0.39,1.9-0.41,16.32-1.11l-0.26,0.07c3.89,0.16,6.3-0.75,10.62-0.39
		c-0.3-0.02-0.45-0.03-0.51-0.06c4.22,0.36-3.62-0.69,25.19,0.51l-0.33-0.12c0.93,0.04,2.94,0.13,2.78,0.22
		c0.74-0.21,8.35,1.1,6.63,0.25C1892.03,305.15,1892.54,304.43,1888.57,304.16z M1826.63,305.22c-0.04-0.02-0.5-0.01-0.85-0.01
		C1826.09,305.17,1826.9,305.05,1826.63,305.22z M1831.77,305.58c-0.44,0.16-0.2,0.09-0.81,0.11
		C1831.16,305.65,1830.96,305.72,1831.77,305.58z M1828.45,304.97c0.28-0.04,0,0,0.68-0.07
		C1828.98,304.91,1828.79,304.93,1828.45,304.97z"
            />
            <path
              className="st10"
              d="M1881.68,320.57l1.04,0.21c-1.51-0.36,0.03-0.43-10.23-1.72c1.08-0.12-2.14-0.31-1.82-0.55
		c-0.8,0.13-2.81-0.24-4.2-0.24c0.08-0.01,0.23-0.03,0.35-0.01c0.09-0.45-3.79,0.26-3.16-0.47c0.04,0.16-1.66,0.03-1.35,0.19
		c-0.3-0.38-4.65-0.06-3.21-0.53c-2.88,0.46-0.1-0.22-8.17,0.19c0.12,0.39,0.15,0.23-1.01,0.27c2.28-0.18-1.21-0.32-0.36-0.2
		c-1.83-0.6-0.81-0.23-9.44,0.91l0.39-0.26c-6.81,1.94-1.2-0.71-23.46,7.64l0.3-0.14c-1.26,0.36-1.32,0.73-2.52,0.95l0.22-0.09
		c-3.4,0.55,1.18,0.47-15.94,3.26c0.05,0.06-0.79,0.21-2.26,0.32c0.39,0.32,0.8-0.27-4.75,0.57c0.04-0.05,0.3-0.1,0.57-0.15
		c-2.2,0.24-0.4,0.08-4.54,0.39l0.2-0.12c-3.74,0.65-23.16-0.72-35.5,4.09l0.1-0.07c-5.71,3.46-0.01-0.7-13.22,8.46l0.15,0
		c-2.93,2.05,0.28,0.43-15.21,9.09l0.46-0.43c-2.94,1.75-6.21,3.28-8.93,5.3c0.15-0.14,0.12-0.19,0.4-0.37
		c-2.85,2.1-3.32,1.47-11.17,10.7c0.11-0.26-0.48,0.51-0.36,0.21c-0.56,0.73-0.97,1.46-0.8,1.39c-1.87,2.44-1.57,1.76-6.12,10.24
		c0.12-0.35,0.22-0.64,0.31-0.87c-0.82,1.47-0.97,1.78-5.01,8.84c-0.02-0.02,0.08-0.22,0.17-0.4c-0.58,1.17,0.47-0.8-0.78,2.06
		c0.12-0.73-1.15,1.65-0.88,0.54c-0.13,0.23-0.27,0.54-0.18,0.52c-0.24,0.31-0.34,0.39-0.18,0.02c-0.77,1.21-0.39,0.85-1.13,1.92
		c-0.04-0.03,0.1-0.26,0.12-0.4c-0.28,0.6-0.62,1.17-1.31,2.03c0.02-0.07-0.09,0.01,0.11-0.25c-1.12,1.5-1.78,2.69-3.32,4.07
		c0.31-0.5-0.39,0.04-1.56,1.1l0.4-0.2c-0.74,0.61-1.36,1.21-2.1,1.58c0.19-0.14,0.38-0.29,0.56-0.44c-0.49,0.34-1,0.65-1.53,0.92
		c0.32-0.12,0.62-0.27,0.93-0.42c-0.81,0.55,0.22,0.18-0.96,0.71c0.23-0.12-0.88,0.41-1.4,0.47l0,0c-1.76,0.69-0.74,0.03-4.38,1.2
		c0.43-0.37-1.48,0.07-1.86,0.31c0.12-0.03,0.06-0.04,0.32-0.11c-2.08,0.53-2.28,0.43-2.88,0.49l0.32-0.09
		c-2.06,0.4,0.18-0.09-7.54,2.12c0.12-0.04,0.25-0.07,0.38-0.1c-2.17,0.99-1.83,0.09-6.96,2.61l0.22-0.16
		c-3.67,1.64-2.95,1.06-10.48,4.59c0.57-0.34,0.86-0.48-1.49,0.69c0.2,0.04,0.28-0.05,0.72-0.15c-0.21,0.17-0.74,0.47-1.21,0.69
		c0.83-0.46-0.08-0.17-0.49-0.05c0.08,0-0.03,0.1,0.01,0.12c-1.09,0.27-0.07,0.29-1.5,0.79c0.09-0.17,0.34-0.37-3.15,1.37
		c0.33-0.17,0.79-0.4,1.11-0.57c-0.82,0.19-0.16,0.05-2.22,0.76l0.73-0.26c-0.43,0.16-0.86,0.33-1.3,0.48l0.36-0.1
		c-0.85,0.73-1.99,0.88-3.24,1.64c1.22-0.73,0.17-0.2,0.21-0.39c-0.35,0.11-0.89,0.25-0.76,0.16c-3.33,1.01,0.44,0.56-14.01,4.18
		c0.13-0.07,0.29-0.18,0.55-0.29c-0.5,0.13-2.79,0.62-1.47,0.2c-0.87,0.12-2.26,0.3-2.53,0.46c0.59-0.03,0.25-0.03,1.77-0.17
		l-0.55,0.09c0.6,0.23,2.24-0.24,3.35-0.47c-0.71,0.43-0.08,0.34,2.73-0.26c-0.14,0.08,0.16,0.11-0.32,0.2
		c1.41-0.23,2.2-0.72,2.76-0.7l-0.11,0.04c3.25-1.01,2.45-0.78,10.93-3.83c0,0.01-0.01,0.02-0.01,0.03
		c0.03-0.01,0.03-0.02,0.03-0.03c0.6-0.27,1.33-0.6,2.25-1.01c-0.05,0.08,2.24-0.85,3.33-1.29l-0.02,0.04
		c0.13-0.26,1.26-0.73,2.05-1.14c0.01,0.11-0.11,0.21-0.49,0.44c0.08-0.04,0.26-0.04,1.58-0.84c-0.52,0.68,0.67-0.31,3.8-1.94
		l-0.5,0.35c0.52-0.18,1.1-0.66,0.5-0.16c2.68-1.09,1.18-0.5,5.9-2.65l0,0c1.58-0.76,3.66-1.5,11.17-3.91
		c-0.79,0.33,0.3-0.01-0.82,0.31c1.07-0.28,1.32-0.28,2.44-0.67c-0.36,0.21,0.84-0.03,1.34-0.19l-0.77,0.09
		c0.57-0.15,1.07-0.26,1.58-0.37l-0.24,0.23c2.18-0.6,5.17-0.49,10.11-2.65c-1.41,0.37,2.77-1.07,1.97-0.98
		c1.11-0.56,3.71-2.82,7-6.66l-0.36,0.56c0.22-0.34,0.42-0.6,0.62-0.86l-0.32,0.23c0.25-0.3,0.45-0.57,0.49-0.53
		c0.29-0.94-0.2-0.28,1.13-1.97c-0.89,1.2,0.02,0.18-0.38,0.84c1.32-1.57,0.37-0.33,1.5-2.45c-0.04,0.22,0.35-0.23,0.47-0.46
		c-0.3,0.41-0.04-0.35,0.81-1.8l-0.35,0.51c0.27-0.95,1.59-2.32,3.85-6.06c-0.29,0.27-0.3,0.34,0.06-0.52l-1,1.92l0.54-1.29
		c-0.33,0.57-0.62,1.23-0.83,1.56c0.94-1.89,0.89-0.77,3.94-6.76c-0.72,1.01-0.37,0.32,3.97-6.75c-0.18,0.45,0.28-0.09-0.25,0.8
		c0.3-0.43,0.58-0.8,0.53-0.84c-0.05,0.2,0.29-0.52,1.15-1.63c-0.14,0.14-0.04,0.03-0.29,0.37c1.24-2.16,0.94-2.03,7.43-8.11
		c-0.02,0.05,0,0.05-0.04,0.09c2.52-2.03-0.03-0.07,4.17-2.87l-0.21,0.16c1.16-0.58,0.1-0.21,6.22-3.98c0,0.04,0,0.07,0,0.1
		c0.15-0.19,0.62-0.54,1.87-0.84l-0.98,0.52c0.58-0.11,1.26-0.67,1.8-0.91l-0.03,0.1c1.49-0.81,1.43-0.99,3.05-1.83l-0.19,0.04
		c2.13-1.38,1.18-0.09,4.9-2.78c-0.5,0.47-1.67,1.22-2.38,1.71c0.57-0.32,1.94-1.1,2.18-1.36c-0.22,0.15-0.48,0.36-0.72,0.46
		c0.72-0.84,0.18,0.3,12.33-8.12c-0.95,0.3,0.63-0.46,0.32-0.61c1.55-0.95,0.26-0.4,3.39-1.64c-0.21,0.01,0.31-0.38,0.99-0.75
		l-0.99,0.45c0.92-0.67,0.07-0.03,3.1-1.7l-0.48,0.37c1.81-0.35-0.56-1.12,10.7-3.9l-0.16,0.1c0.72-0.16,0.27-0.05,3.03-0.46
		l-0.48,0.13c2.27-0.13,3.47-0.48,5.4-0.59c0,0.22,1.8,0.25,2.71,0.28l-0.03,0.03c4.09-0.3,15.07,0.17,28.87-2.13l-0.01,0.15
		c1.62-0.24,2.44-0.35,4.42-0.79l-0.39,0.18c3.72-1.14,1.77-0.48,8.52-2.25c-0.02,0.04-0.06,0.11-0.37,0.21
		c0.91-0.23,3.53-0.9,3.12-1.07c0.08,0.17,1.96-0.62,2.95-1.05l-0.11,0.19c0.18-0.12,0.49-0.26,3.72-1.65l-0.39,0.24
		c1.95-0.85-0.25,0.03,8.05-2.99c-1.28,0.44-2.31,0.82-2.22,0.78c0.04,0.12,1.08-0.23,0.32,0.23c0.82-0.35-0.06-0.09,3.32-1.37
		l-0.1,0.05c1.38-0.47,0.79-0.2,3.23-0.89c-0.08-0.12,0.62-0.28-0.06-0.23c0.16-0.03-0.26,0.07-0.66,0.22l0.03-0.02
		c-0.34,0.1-0.79,0.24-1.3,0.39c3.53-1.26,1.01-0.51,9.57-2.03c-0.09,0.02-0.17,0.04-0.22,0.06c0.07,0.01,0.32-0.06,0.72-0.14
		c-0.08,0.01-0.16,0.03-0.24,0.04c1.11-0.21-0.03-0.02,3.5-0.34l-0.22,0.07c3.23,0.1,5.25-0.69,8.84-0.24
		c-0.25-0.03-0.37-0.04-0.42-0.08c5.16,0.76-2.07-1.01,20.89,2.03l-0.26-0.15c0.76,0.14,2.42,0.36,2.28,0.46
		c0.65-0.2,6.73,1.93,5.45,0.92C1884.41,321.94,1884.92,321.27,1881.68,320.57z M1830.39,321.35c-0.04-0.01-0.4,0.09-0.69,0.16
		C1829.94,321.41,1830.57,321.13,1830.39,321.35z M1831.81,320.77c0.22-0.08,0,0,0.54-0.19
		C1832.23,320.62,1832.08,320.67,1831.81,320.77z M1834.67,320.83c-0.31,0.21-0.15,0.12-0.64,0.22
		C1834.19,320.98,1834.04,321.08,1834.67,320.83z"
            />
            <path
              className="st10"
              d="M1693.17,410.24c0.08-0.06,0.28-0.23,0.47-0.3l-0.34,0.33c0.4-0.18,0.75-0.63,0.38-0.15
		c1.93-1.07,0.86-0.45,4.19-2.74l0,0c1.24-1.19,2.45-1.35,7.33-5.54c-0.46,0.49,0.23-0.09-0.48,0.48c0.68-0.52,0.91-0.56,1.5-1.28
		c-0.15,0.31,0.59-0.31,0.86-0.61l-0.51,0.33c0.33-0.32,0.63-0.58,0.94-0.84l-0.05,0.27c1.91-1.93,2.77-2.37,5.74-5.65
		c-0.88,0.27,1.79-1.22,1.21-1.14c0.78-0.68,1.14-1.15,1.92-1.88c-0.08-0.06,1.69-2.45,1.67-1.61c0.59-0.78,1.31-1.26,1.7-1.65
		l-0.31,0.41c0.19-0.24,0.36-0.42,0.53-0.61l-0.29,0.13c0.22-0.2,0.39-0.39,0.43-0.35c0.25-0.71-0.2-0.29,1.03-1.41
		c-1.17,1.2-0.09,0.97,1.07-1.06c-0.04,0.18,0.32-0.1,0.45-0.26c-0.28,0.27-0.05-0.3,0.84-1.27l-0.35,0.33
		c1.41-1.71,2.61-2.37,6.97-7.12l-0.39,0.32c-0.11-0.15,1.09-0.89,4.58-4.69c-0.19,0.33,0.25,0-0.28,0.61
		c0.29-0.27,0.57-0.51,0.53-0.56c-0.05,0.17,0.29-0.38,1.12-1.07c-0.13,0.08-0.03,0.02-0.29,0.24c1.93-2.25,0.27-0.27,6.53-5.54
		c-0.01,0.03,0,0.04-0.02,0.07c1.94-1.56,0.03,0.03,3.26-2.16l-0.16,0.13c0.73-0.41-0.03-0.01,4.69-3.25
		c0.01,0.04,0.01,0.08,0.01,0.11c0.1-0.17,0.45-0.46,1.47-0.59l-0.76,0.38c0.47-0.03,0.98-0.49,1.4-0.66l-0.02,0.1
		c1.16-0.58,1.08-0.81,2.37-1.35l-0.16,0c1.75-0.9,0.91,0.13,4.01-1.61c-0.44,0.32-1.4,0.77-1.98,1.09
		c0.47-0.18,1.58-0.62,1.79-0.81c-0.18,0.09-0.4,0.23-0.6,0.27c0.92-0.56-0.4-0.27,11-2.61c-0.78-0.15,0.59-0.12,0.41-0.4
		c0.24-0.06-0.01-0.01,0.7-0.13c0,0-0.01,0-0.05,0c0.67-0.08,1.27,0.02,2.3,0.09c-0.16-0.08,0.33-0.22,0.93-0.3l-0.86,0.03
		c1.42-0.45,1.31,0,2.73-0.47l-0.44,0.19c1.39-0.39-0.02,0.34,8.71-2.62l-0.11,0.12c0.52-0.26,0.23-0.03,2.23-0.93l-0.33,0.22
		c1.7-0.57,2.46-1.23,3.81-1.88c0.09,0.2,1.4-0.3,2.05-0.56l-0.01,0.04c3.09-1.64,9.34-4.44,20.71-8.03l0.02,0.14
		c1.22-0.29,1.83-0.44,3.32-0.88l-0.28,0.18c2.77-1.04,1.29-0.51,6.51-1.7c-0.02,0.03-0.05,0.1-0.29,0.16
		c0.71-0.13,2.76-0.37,2.45-0.61c0.05,0.19,1.55-0.29,2.35-0.49l-0.1,0.16c0.08-0.07,1.02-0.29,3.05-0.7l-0.33,0.14
		c1.33-0.33-0.26-0.01,6.58-0.75c-1.04,0.11-1.89,0.19-1.81,0.17c0.01,0.13,0.86,0.06,0.21,0.31c0.68-0.11-0.03-0.1,2.74-0.47
		l-0.08,0.02c1.13-0.1,0.64,0.03,2.6-0.09c-0.04-0.14,0.51-0.15-0.01-0.23c0.13,0-0.21,0.02-0.54,0.07l0.03-0.01
		c-0.28,0.02-0.64,0.05-1.05,0.08c2.95-0.56,0.73,0.06,7.99-0.4c-0.21,0.03-0.46,0.03-0.58,0.08c0.03,0.03,1.91-0.36,3.1-0.08
		l-0.17,0.05c1.7,0.31-0.35-0.35,6.84,0.14c-0.19-0.03-0.29-0.05-0.32-0.08c3.07,0.48,5.5-0.11,16.08,2.02l-0.19-0.14
		c0.58,0.12,1.88,0.27,1.75,0.38c0.51-0.16,5.14,1.68,4.21,0.73c0.7,0.25,1.1-0.42-1.38-0.97l0.8,0.17
		c-1.21-0.32,0.08-0.39-7.88-1.44c0.85-0.12-1.64-0.3-1.39-0.53c-0.63,0.15-2.18-0.19-3.25-0.2c0.06-0.01,0.18-0.03,0.27-0.01
		c0.1-0.45-3.05,0.24-2.43-0.48c0.02,0.16-1.29,0.04-1.06,0.19c-0.55-0.17-0.48-0.1-0.75-0.29c0.39,0.3-3.11-0.08-1.71-0.29
		c-2.19,0.33-0.09-0.11-6.32-0.03c0.08,0.35,0.13,0.25-0.79,0.24c2.25-0.3-1.48-0.35-0.26-0.21c-1.09-0.74-2.25-0.01-7.31,0.05
		l0.32-0.2c-5.12,0.78-2.14-0.75-18.98,2.06l0.24-0.09c-1.02,0.13-1.08,0.51-2.03,0.55l0.17-0.06c-2.15,0.34-0.6-0.09-12.37,2.81
		c0.05,0.05-0.57,0.28-1.69,0.55c0.06,0.05,0.12,0.07,0.15,0.08c-0.14-0.01-0.97,0.01-3.68,1.13c0.02-0.07,0.34-0.21,0.58-0.34
		c-1.81,0.78-0.34,0.15-3.52,1.32l0.11-0.15c-6.52,2.3-7.75,3.72-25.66,9.96l0.09-0.03c-4.63,1.12,0.05,0.06-11.83,2.31l0.11,0.07
		c-2.1,0.73-0.74-0.13-13.04,4.76l0.35-0.36c-0.75,0.56-2.6,1.13-6.86,4.22c0.11-0.12,0.08-0.16,0.29-0.31
		c-1.02,0.72-3.08,1.89-7.5,6c-0.09-0.22-1.41,0.98-1.8,1.47c0.11-0.19-0.45,0.3-0.33,0.07c-0.52,0.48-0.95,0.96-0.79,0.96
		c-1.86,1.47-1.47,1.04-6.48,6.54c0.14-0.24,0.26-0.44,0.37-0.6c-0.89,0.99-1.15,1.08-5.35,5.75c-0.01-0.02,0.09-0.16,0.19-0.29
		c-0.6,0.81,0.46-0.54-0.83,1.51c0.15-0.59-1.12,1.09-0.82,0.22c-0.13,0.16-0.27,0.38-0.18,0.4c-0.22,0.21-0.32,0.25-0.16-0.03
		c-0.72,0.85-0.37,0.64-1.06,1.37c-0.03-0.04,0.09-0.2,0.12-0.31c-0.28,0.44-0.56,0.88-1.17,1.47c0.01-0.06-0.08-0.01,0.09-0.2
		c-1,1.07-1.53,1.98-2.7,3.08c0.08-0.25,0.09-0.45-1.18,0.93l0.31-0.18c-0.53,0.54-0.9,1.11-1.4,1.53l0.34-0.45l-0.97,1.03
		l0.61-0.52c-0.47,0.61,0.23,0.11-0.52,0.79c0.13-0.16-0.53,0.54-0.89,0.74l0,0c-0.97,1.15-0.61,0.13-2.51,2.48
		c0.09-0.46-0.95,0.63-1.08,0.98c0.07-0.07,0.02-0.06,0.16-0.21c-1.02,0.92-1.44,1.26-1.8,1.54l0.27-0.27
		c-1.33,1.15,0.09-0.07-4.51,3.96l0.24-0.17c-0.46,0.85-0.58-0.05-4.54,3.47l0.13-0.17c-2.53,1.81-1.98,1.32-7.46,4.65
		c0.41-0.31,0.62-0.44-1.11,0.6c0.17,0.04,0.22-0.04,0.57-0.12c-0.15,0.16-0.53,0.42-0.89,0.62c0.61-0.42-0.09-0.16-0.4-0.07
		c0.06,0.01-0.01,0.09,0.03,0.11c-0.85,0.21-0.02,0.27-1.12,0.67c0.04-0.17,0.23-0.31-2.4,1.13c0.24-0.15,0.6-0.32,0.84-0.47
		c-0.66,0.13-0.71,0.2-1.16,0.38l0.04-0.02l-1.04,0.4l0.28-0.08c-0.61,0.66-1.52,0.72-2.45,1.37c0.91-0.62,0.12-0.19,0.14-0.36
		c-0.28,0.08-0.7,0.18-0.6,0.1c-0.5-0.06-1.85,1.48-10.68,3.75c0.1-0.08-0.01,0,0.45-0.32c0,0,0,0-0.01,0
		c0.29-0.16-2.3,0.95-1.17,0.3c-0.65,0.21-1.71,0.47-1.89,0.65l0.44-0.05c-0.02-0.11,0.14-0.32,0.9-0.25l-0.42,0.12
		c0.5,0.19,1.69-0.45,2.53-0.65c-0.5,0.41-0.02,0.36,2.1-0.32c-0.09,0.07,0.08,0.1-0.11,0.16c1.01-0.2,1.56-0.62,1.98-0.6
		l-0.08,0.04c2.88-1.06,0.95-0.41,8.38-3.14c0,0.01,0,0.02-0.01,0.03c0.02-0.01,0.02-0.02,0.02-0.03c0.46-0.22,1.01-0.49,1.72-0.83
		c-0.03,0.09,1.72-0.69,2.56-1.06l-0.01,0.04c0.08-0.24,0.94-0.63,1.52-1c0.03,0.11-0.06,0.2-0.34,0.4
		c0.06-0.03,0.21-0.04,1.17-0.75C1690.59,412.4,1690.65,411.97,1693.17,410.24z M1837.11,336.36c-0.25,0.13-0.14,0.09-0.52,0.07
		C1836.72,336.4,1836.59,336.46,1837.11,336.36z M1835.39,335.55c-0.09,0.01-0.22,0.02-0.44,0.04l0,0
		C1835.13,335.58,1835.29,335.56,1835.39,335.55z M1833.77,335.81c0.09-0.04-0.27-0.03-0.54-0.04
		C1833.44,335.74,1834.02,335.62,1833.77,335.81z M1725.84,381.04c0.64-0.62,0.99-0.86,1.35-1.12l-1.09,1.26l0.62-0.89
		c-0.35,0.36-0.68,0.82-0.91,1.02C1726.09,380.87,1726.08,380.93,1725.84,381.04z"
            />
            <path
              className="st10"
              d="M1879.17,368.07l0.71,0.09c-0.89-0.23-0.77-0.37-6.93-0.82c0.74-0.18-1.44-0.19-1.22-0.45
		c-0.55,0.17-1.9-0.12-2.83-0.1c0.05-0.01,0.16-0.04,0.24-0.02c0.1-0.43-2.72,0.31-2.11-0.42c0.01,0.16-1.12,0.04-0.92,0.19
		c-0.48-0.17-0.41-0.1-0.65-0.29c0.34,0.29-2.71-0.07-1.48-0.28c-1.88,0.33-0.08-0.11-5.5-0.17c0.05,0.33,0.12,0.28-0.7,0.19
		c1.99-0.18-1.29-0.44-0.22-0.23c-0.86-0.81-1.96-0.07-6.35-0.46l0.3-0.17c-1.66,0.21-0.68-0.33-16.62-0.64l0.22-0.04
		c-0.89-0.08-1.01,0.28-1.83,0.13l0.16-0.02c-3.09-0.01-0.87-0.34-15.84-0.04c0.04-0.06,0.33-0.09,0.57-0.14
		c-1.71,0.16-0.31,0.03-3.27,0.15l0.14-0.11c-1.32,0.01-2.77,0.14-4.25,0.21c0.71-0.24-11.24,0.67-19.73,3.17l0.07-0.04
		c-4.11,1.91-0.01-0.68-9.68,4.45l0.12,0.03c-2.1,1.48-0.46,0.29-9.71,7.19l0.22-0.38c-1.69,1.58-3.7,2.9-5.4,4.38
		c0.09-0.11,0.06-0.15,0.24-0.28c-0.74,0.42-2.62,2.01-6.76,4.74c-0.06-0.22-1.36,0.57-1.79,0.88c0.13-0.14-0.44,0.14-0.3-0.03
		c-0.53,0.27-1,0.56-0.86,0.62c-2,0.48-1.33,0.71-7.42,2.75c0.28-0.17,0.5-0.29,0.67-0.37c-0.99,0.26-1.29,0.43-6.74,1.71
		c0-0.02,0.14-0.07,0.27-0.12c-0.82,0.29,0.59-0.16-1.34,0.72c0.39-0.38-1.35,0.21-0.71-0.31c-0.17,0.06-0.38,0.15-0.33,0.21
		c-0.26,0.04-0.35,0.01-0.11-0.12c-0.94,0.26-0.58,0.29-1.44,0.48c-0.01-0.05,0.16-0.11,0.24-0.18c-0.42,0.19-0.83,0.38-1.57,0.51
		c0.04-0.04-0.06-0.06,0.16-0.11c-1.25,0.29-2.06,0.76-3.45,1.02c0.17-0.15,0.24-0.35-1.32,0.18l0.32,0
		c-0.64,0.2-1.16,0.47-1.72,0.62l0.44-0.24l-1.16,0.45l0.69-0.17c-0.6,0.32,0.15,0.2-0.7,0.45c0.16-0.07-0.63,0.21-0.99,0.24l0,0
		c-0.74,0.5-1.48,0.21-2.8,1.34c0.18-0.39-0.99,0.27-1.14,0.58c0.07-0.05,0.02-0.05,0.17-0.16c-1.04,0.81-1.49,0.99-1.88,1.17
		c0.12-0.1,0.24-0.19,0.36-0.28c-1.33,0.87,0.02-0.13-3.9,3.62l0.19-0.19c-0.81,1.4-1.15,0.61-2.94,4.07l0.05-0.19
		c-0.32,0.57-0.97,1.81-5.03,5.69c0.2-0.26,0.27-0.31-0.85,0.69c0.16,0.02,0.2-0.05,0.48-0.17c-0.09,0.18-0.39,0.45-0.67,0.66
		c0.47-0.44-0.12-0.15-0.37-0.04c0.06,0,0,0.09,0.04,0.1c-0.72,0.26,0.05,0.28-0.88,0.72c0-0.17,0.16-0.29-1.99,1.17
		c0.2-0.15,0.5-0.32,0.7-0.47c-0.58,0.15-0.06,0.02-1.46,0.59c0.04-0.02,0.07-0.03,0.1-0.04c-0.16,0.07-0.31,0.15-0.47,0.21
		l0.24-0.07c-0.49,0.62-1.27,0.72-2.07,1.3c0.78-0.57,0.09-0.18,0.1-0.34c-0.24,0.06-0.61,0.17-0.52,0.08
		c-0.37-0.03-2.41,1.67-9.29,3.31c0.08-0.07-0.01,0,0.38-0.3c0,0,0,0,0,0c0.24-0.14-2.01,0.88-1.02,0.26
		c-0.57,0.18-1.48,0.44-1.63,0.61l0.38-0.04c-0.03-0.11,0.11-0.32,0.78-0.24l-0.36,0.12c0.44,0.18,1.47-0.4,2.21-0.59
		c-0.54,0.48,0.26,0.24,1.84-0.24c-0.09,0.07,0.13,0.12-0.2,0.18c0.96-0.15,1.45-0.58,1.84-0.54l-0.07,0.04
		c2.53-0.95,0.83-0.3,7.29-2.84c0,0.01,0,0.02-0.01,0.03c0.02-0.01,0.02-0.02,0.01-0.03c0.89-0.47,0.49-0.33,3.62-1.93l0,0.04
		c0.04-0.22,0.72-0.69,1.19-1.07c0.04,0.1-0.01,0.19-0.22,0.4c0.06-0.03,0.22-0.06,0.9-0.81c-0.18,0.61,0.42-0.23,2.03-2.07
		l-0.21,0.37c0.32-0.24,0.49-0.69,0.3-0.2c1.37-1.33,0.63-0.56,2.75-3.43l0,0c0.77-1.25,1.21-2.04,4.96-6.19
		c-0.34,0.47,0.19-0.08-0.37,0.46c0.31-0.31,0.14-0.05,1.24-1.14c-0.13,0.26,0.53-0.22,0.78-0.47l-0.45,0.25
		c0.3-0.25,0.55-0.49,0.86-0.66l-0.06,0.24c1.94-1.39,2.55-1.66,6.04-3.28c-0.8-0.18,1.85-0.2,1.37-0.42
		c0.88-0.19,1.34-0.4,2.23-0.62c-0.05-0.09,2.36-1.1,1.97-0.4c0.79-0.31,1.55-0.28,2.02-0.38l-0.42,0.15
		c0.25-0.09,0.46-0.14,0.68-0.18l-0.27-0.06c0.26-0.04,0.47-0.09,0.48-0.04c0.51-0.4-0.02-0.36,1.43-0.52
		c-1.43,0.27-0.52,0.72,1.3-0.22c-0.12,0.12,0.28,0.11,0.45,0.06c-0.34,0.05,0.1-0.27,1.23-0.52l-0.41,0.05
		c0.6-0.41,1.88-0.24,4.78-0.8c-0.28-0.06-0.31-0.02,0.27-0.28l-1.41,0.42l0.88-0.38c-0.43,0.1-0.89,0.28-1.15,0.33
		c0.41-0.2,0.38-0.16,0.15-0.21c1.99-0.42,1.36,0.3,5-1.09l-0.44,0.08c1.21-0.56,1.3-0.13,5.34-2.19c-0.25,0.23,0.23,0.1-0.4,0.45
		c1.46-0.8,0.77-0.59,7.93-4.8c-0.01,0.02,0,0.03-0.01,0.05c1.7-1.34,0.05,0.08,2.85-1.91l-0.13,0.12c0.71-0.47,0.25-0.1,3.77-3.31
		c0.01,0.04,0.02,0.07,0.03,0.09c0.06-0.17,0.31-0.48,1.17-0.75l-0.58,0.47c0.41-0.1,0.76-0.6,1.08-0.82l0.01,0.09
		c0.88-0.73,0.78-0.89,1.76-1.63l-0.14,0.03c1.23-1.22,0.81-0.07,2.98-2.33c-0.28,0.38-0.98,0.99-1.38,1.41
		c0.35-0.27,1.18-0.91,1.31-1.12c-0.13,0.12-0.28,0.29-0.44,0.37c1.73-1.7,0.57-0.47,8.39-5.14c-0.7,0.02,0.46-0.24,0.23-0.46
		c0.99-0.4,0.26-0.32,2.48-0.58c-0.15-0.05,0.22-0.28,0.72-0.43c-0.25,0.04-0.48,0.1-0.73,0.15c1.11-0.64,1.11-0.17,2.25-0.78
		c-0.12,0.07-0.23,0.15-0.35,0.23c1.23-0.22-0.23-0.34,7.43-2.26l-0.1,0.1c0.48-0.15,0.19-0.03,2.02-0.44l-0.31,0.14
		c1.53-0.16,2.3-0.56,3.59-0.77c0.02,0.21,1.24,0.15,1.84,0.13l-0.02,0.03c3-0.52,8.88-0.68,19.39-1.02l-0.02,0.14
		c1.1,0.03,1.65,0.03,3,0l-0.28,0.1c2.57-0.34,1.19-0.2,5.89-0.17c-0.02,0.03-0.06,0.09-0.28,0.1c0.63,0.01,2.43,0.18,2.21-0.11
		c0.01,0.19,1.38,0.05,2.1-0.01l-0.12,0.14c0.08-0.05,0.92-0.12,2.73-0.18l-0.31,0.08c0.85,0.04,2.47,0.04,5.78,0.26
		c-0.92-0.05-1.66-0.09-1.59-0.1c-0.01,0.13,0.73,0.19,0.14,0.34c0.6-0.02-0.02-0.1,2.44-0.08l-0.08,0.01
		c0.99,0.02,0.55,0.1,2.27,0.2c-0.02-0.14,0.46-0.09,0.01-0.23c0.11,0.02-0.18-0.01-0.47,0.01l0.03-0.01
		c-0.24-0.01-0.56-0.02-0.92-0.04c2.64-0.26,0.59,0.24,6.99,0.38c-0.19,0.01-0.41-0.01-0.51,0.02c0.04,0.06,1.68-0.22,2.71,0.12
		l-0.15,0.05c2.15,0.48,3.59-0.19,5.97,0.42c-0.17-0.04-0.25-0.06-0.28-0.09c3.58,0.63-1.67-0.37,14.08,1.65l-0.17-0.13
		c0.52,0.09,1.65,0.15,1.55,0.26c0.44-0.21,4.58,1.19,3.72,0.34C1881.07,369.08,1881.36,368.38,1879.17,368.07z M1844.4,365.07
		c0.08-0.03-0.23-0.07-0.46-0.12C1844.13,364.95,1844.66,364.92,1844.4,365.07z M1845.45,365.02c0.16,0,0,0,0.38,0.01
		C1845.75,365.02,1845.64,365.02,1845.45,365.02z M1847.23,366.01c-0.22,0.09-0.13,0.08-0.46,0.01
		C1846.89,366.01,1846.77,366.05,1847.23,366.01z"
            />
            <path
              className="st10"
              d="M1485.98,376.5c0.19,0.06,0.33,0.1,0.46,0.13C1486.31,376.59,1486.15,376.55,1485.98,376.5z M1481.66,375.22
		c0.52,0.2,1.2,0.43,1.23,0.46C1483.36,375.66,1482.12,375.35,1481.66,375.22z M1517.65,384.66c-0.53-0.13-1-0.24-1.18-0.28
		c0.15,0.04,0.55,0.16,1.32,0.35C1517.74,384.71,1517.7,384.69,1517.65,384.66z M1469.2,370.9c-0.03-0.02-0.06-0.03-0.09-0.05
		c-0.12-0.05-0.25-0.09-0.41-0.13c0.25,0.12,0.51,0.25,0.77,0.35c-0.09-0.05-0.17-0.11-0.25-0.16
		C1469.21,370.91,1469.2,370.9,1469.2,370.9z M1396.77,332.42c0.31-0.2,0.65-0.34,0.97-0.52c-0.28,0.13-0.73,0.34-1.33,0.67
		c0.62,0.04,1.26,0.08,1.92,0.13c0.75-0.39,1.14-0.65,0.6-0.66C1399.87,331.7,1399.71,330.86,1396.77,332.42z M1367.24,333.99
		c1.62-0.62,3.33-1.13,5.11-1.54c-3.71,0.72-3.18,0.76-4.34,1.09C1367.75,333.69,1367.49,333.84,1367.24,333.99z M1355.02,344.3
		l0.09-0.21c-4.42,3.61,4.11,4.54-16.02,22.93l0.23-0.04c-3.83,4.4-27.42,7.55-48.25-2.53c0.05,0.01,0.09,0.01,0.13,0.01
		c-6.92-1.15-7.17-2.02-22.03,0.99c0.11-0.26-3.91,0.7-5.36,1.16c0.2-0.11-1.29,0.23-0.78,0.02c-1.63,0.39-3.11,0.75-2.8,0.8
		c-3.02-0.1-1.18,1.56-21.72,2.17c0.93-0.09,1.65-0.14,2.16-0.17c-3.07,0.14,2.4-0.32-19.34,0.06c0.01-0.02,0.32-0.05,0.65-0.09
		c-1.59,0.1,0.03,0.13-3.83,0.7c1.23-0.45-3.73,0.38-1.77-0.23c-0.49,0.07-1.1,0.21-0.97,0.26c-0.73,0.11-0.97,0.11-0.24-0.09
		c-2.65,0.51-1.69,0.43-4.07,0.97c0-0.05,0.48-0.18,0.71-0.29c-1.19,0.36-2.35,0.77-4.33,1.29c0.09-0.05-0.04-0.04,0.2-0.12
		c-3.23,0.95-5.51,1.89-9.03,3.2c0.82-0.42-0.07-0.36-3.38,1.29l0.79-0.29c-1.64,0.79-3.01,1.65-4.36,2.45
		c0.31-0.23,0.62-0.45,0.94-0.66c-0.38,0.22-0.63,0.34-2.68,1.78c0.53-0.38,1.09-0.7,1.65-1.03c-1.9,1.8-4.04,2.11-8.2,8.55
		c3.84-4.7,10.82-10.52,25.78-14.54l-0.64,0.2c0.47-0.14,0.9-0.25,1.34-0.37l-0.71,0.07c0.7-0.17,1.29-0.33,1.29-0.27
		c1.05-0.51,0.77-0.74,3.96-1.08c-1.7,0.29-0.42,0.1-2.04,0.58c2.69-0.45,1.76-0.19,5.58-1.03c-0.32,0.11,0.47,0.1,0.99,0.06
		c-0.43-0.02,1.02-0.37,3.76-0.49l-1.14,0.02c0.64-0.2,3.47-0.25,13.2,0.25c-0.7-0.14-0.83-0.11,0.89-0.19h-4l2.57-0.12
		c-1.21-0.03-2.55,0.02-3.27-0.02c1.22-0.07,1.1-0.04,0.52-0.15c1.65,0.21,3.28-0.03,5.19,0.13l-0.07,0.29
		c2.88,0.19,5.12-0.05,8.86-0.09l-1.2-0.01c5.81-0.64,0.93,0.88,15.62-2.12c-0.83,0.29,0.48,0.09-1.41,0.56
		c4.47-0.55,1.43-1.9,24.39-4.41c0.12-0.01,0.24-0.03,0.36-0.04c-0.13,0.03-0.01,0.05-0.36,0.04c4.4,0.63,2.32-1.66,21.78,5.85
		c-0.07,0.03-0.13,0.05-0.18,0.06c0.38,0.05,1.41,0.31,3.64,1.31l-1.99-0.59c0.97,0.47,2.56,0.81,3.62,1.07l-0.15,0.04
		c1.08,0.22,3.24,0.59,6.57,0.84l-0.35-0.08c4.39-0.09,2.38,0.79,8.99-0.2c-0.14,0.05-0.29,0.11-0.45,0.18
		c1.36-0.21,1.28-0.28,1.57-0.35c-0.4,0.06-0.77,0.12-1.11,0.17c6.15-2.31,5.62,2.97,22.7-11.65c0,0.04,0.11-0.05,0.41-0.36
		c-0.14,0.12-0.28,0.24-0.41,0.35c0.04-0.15,1.14-1.54,0.82-1.48c0.3-0.48-0.01-0.02,0.86-1.38c0.01-0.01,0.02-0.03,0.03-0.05
		c1.13-1.79,1.52-2.22,2.94-4.46c-0.25,0.27,0.23-0.87,0.87-2.17l-0.98,1.8c1.34-3.29,1.48-2.7,2.83-5.98
		c0.42,2.21-0.97-5.3,12.36-13.38C1361.86,336.05,1357.46,339.31,1355.02,344.3z M1324.51,373c-0.16,0.02-0.33,0.05-0.48,0.06
		c0.11-0.05,0.22-0.09,0.32-0.13c-0.64,0.1-1.62,0.22-3.12,0.39C1321.57,373.32,1322.11,373.38,1324.51,373z M1291.22,364.46
		c0.27,0.05,0.55,0.09,0.84,0.14C1291.56,364.46,1291.47,364.48,1291.22,364.46z M1182.59,388.49c0.28-0.46,0.55-0.89,0.81-1.3
		c-0.29,0.35-0.56,0.7-0.81,1.03C1182.61,388.24,1182.61,388.32,1182.59,388.49z M1182.16,388.8c0.14-0.19,0.28-0.38,0.43-0.58
		C1182.56,388.18,1182.43,388.39,1182.16,388.8z M1182.13,388.85c-0.2,0.28-0.4,0.56-0.58,0.83
		C1181.79,389.36,1181.98,389.08,1182.13,388.85z M1180.77,390.64c-1.9,1.58-4.43,4.01-15.59,11.02l0.63-0.35
		c-0.69,0.83,0.25-0.38-12,6.64l0.41-0.29c-1.79,0.94-3.48,1.98-19.04,9.16c0.37-0.19,0.67-0.35,0.74-0.39
		c-0.12,0.04-1.04,0.47-3.52,1.63c0.33-0.02,0.5-0.14,1.29-0.39c-0.4,0.24-1.3,0.69-2.14,1.07c1.27-0.66-0.26-0.08-0.96,0.16
		c0.08,0-0.11,0.12-0.04,0.13c-4.1,1.66-0.93,0.44-8.71,3.78c0.61-0.28,1.49-0.68,2.1-0.96c-1.45,0.43-1.64,0.56-2.62,0.94
		l-2.51,0.94l0.65-0.21c-1.71,1.07-3.72,1.56-6.13,2.75c2.34-1.15,0.36-0.27,0.49-0.49c-0.65,0.21-1.63,0.55-1.37,0.4
		c-2.69,0.87-5.39,2.71-25.55,9.36c2.38-1.17-4.23,1.18-6.14,1.95c1.01-0.29,0.53-0.19,3.15-0.98l-0.98,0.35
		c1.1-0.06,3.97-1.31,5.96-1.95c-0.98,0.64,0.07,0.33,4.87-1.36c-0.22,0.11,0.14,0.08-0.29,0.23c2.35-0.71,3.72-1.46,4.67-1.62
		c2.14-0.8,3.39-1.28,19.82-7.46c-0.01,0.01-0.02,0.03-0.03,0.04c0.05-0.02,0.07-0.04,0.06-0.05c1.11-0.46,2.48-1.04,4.2-1.77
		c-0.11,0.11,4.13-1.59,6.16-2.41c0.42-0.36,2.41-1.16,3.84-1.84c-0.02,0.11-0.23,0.26-0.87,0.6c0.23-0.09,0.82-0.33,2.86-1.39
		l-0.3,0.29c0.89-0.47,0.33-0.16,7.4-3.74l-0.96,0.58c0.58-0.24,1.3-0.65,1.4-0.69c-0.03,0.03-0.19,0.13-0.51,0.34
		c1.46-0.68,0.5,0.08,10.74-5.3c0-0.01,0.01-0.01,0.01-0.01c3.04-1.7,0,0,9.45-5l0.02,0.05c1.22-0.77,0.48-0.07,9.7-5.78
		c-0.36,0.26-0.48,0.36-0.5,0.39c0.01,0.01,0.01,0,0.02,0c-0.07,0.05-0.3,0.19-0.86,0.54c1.76-1.06,2.2-1.25,3.95-2.59
		c-0.54,0.53,1.37-0.75,2.14-1.35l-1.23,0.76c0.89-0.65,1.68-1.2,2.46-1.79l-0.32,0.45c1.71,0.04,2.58-2.14,4.71-5.28
		c-0.27,0.37-0.61,0.8-1.03,1.27C1180.52,390.94,1180.53,390.91,1180.77,390.64z"
            />
            <path
              className="st10"
              d="M1384.65,342.57c0.04-0.07,0.11-0.21,0.2-0.3c-0.32-0.32-1.93,3.22-2.22,2.45c-0.02,0.21-0.79,1.23-0.67,1.23
		c-0.57,0.17-2.59,3.93-2.21,2.46c-1.2,2.74-0.23-0.02-4.32,7.16c0.37,0.11,0.28,0.01-0.25,0.86c0.77-1.42-0.44,0.18-0.55,0.41
		c0.03-0.02,0.06-0.04,0.09-0.06c-0.07,0.08-0.1,0.1-0.1,0.08c-1.54,1.05-0.23,0.76-5.01,8.1l0.08-0.47
		c-4.02,4.98-0.92,4.83-16.56,18.03c-1.16,0.65-1.1,1.08-2.24,1.58l0.19-0.14c-3.29,0.73,1.45,1.65-15.73,4.62
		c0.05,0.05-0.81,0.2-2.31,0.16c0.07,0.06,0.16,0.1,0.19,0.12c-0.05,0-0.96-0.19-5.06-0.12c0.06-0.06,0.51-0.05,0.87-0.08
		c-2.59-0.03-0.51,0.03-4.91-0.34l0.22-0.08c-9.98-1.26-17.49-3.44-35.94-6.92c-6.13-0.2,0.03-1-15.97-0.62l0.13,0.08
		c-3.3,1.35-0.92-1.85-16.95,6.92l0.39-0.52c-2.24,1.09,1.49,0.59-8.79,5.3c0.18-0.1,0.17-0.15,0.48-0.25
		c-5.71,0.98-3.1,1.67-15.32,0.97c0.28-0.04-0.69-0.14-0.36-0.2c-0.92-0.09-1.77-0.17-1.62-0.03c-4.6-0.83-3.4-0.57-12.1-1.41
		c0.26-0.02,0.49-0.04,0.69-0.05c-2-0.05-2.07-0.6-10.23-0.34c0-0.01,0.03-0.01,0.08-0.03c-0.46,0.08,0.89-0.07-1.83,0.53
		c0.66-0.39-2.07,0.19-1.01-0.32c-0.27,0.05-0.6,0.14-0.53,0.2c-0.41,0.03-0.54,0.01-0.14-0.12c-1.48,0.24-0.93,0.31-2.25,0.54
		c0-0.05,0.25-0.12,0.38-0.2c-0.65,0.23-1.31,0.42-2.42,0.67c0.04-0.04-0.02-0.05,0.08-0.09c-0.97,0.27,0.1-0.05-5.03,1.65
		c0.52-0.3-0.43-0.2-1.94,0.49l0.46-0.08c-0.94,0.37-1.72,0.8-2.54,1.1c0.22-0.12,0.44-0.25,0.66-0.36l-1.71,0.78l1-0.36
		c-0.89,0.49,0.18,0.17-1.04,0.65l-0.01,0.01c-0.16,0.05-1.01,0.42-1.43,0.53c-0.84,0.5-1.06,0.5-1.48,0.64
		c-0.41,0.14-1.04,0.43-2.7,1.5c0.29-0.44-1.1,0.33-1.59,0.75c0.05-0.03,0.09-0.05,0.14-0.08c-0.09,0.06-0.13,0.09-0.16,0.11
		c0.01-0.01,0.01-0.02,0.02-0.03c-1.45,0.84-2.1,1.14-2.62,1.38l0.4-0.25c-1.27,0.69-1.11,0.49-6.89,4.03l0.35-0.18
		c-0.84,0.93-0.54-0.21-6.63,3.69l0.21-0.19c-3.63,1.98-2.83,1.43-10.46,5.2c0.58-0.35,0.87-0.49-1.53,0.7
		c0.2,0.03,0.29-0.05,0.74-0.16c-0.22,0.18-0.76,0.48-1.25,0.7c0.86-0.48-0.08-0.17-0.49-0.06c0.07,0-0.04,0.1,0.01,0.12
		c-2.3,0.89-0.42,0.23-4.82,2.1c0.34-0.16,0.82-0.39,1.16-0.55c-0.83,0.17-0.92,0.25-1.48,0.45l-1.41,0.47l0.37-0.1
		c-0.9,0.72-2.06,0.85-3.38,1.55c1.28-0.68,0.18-0.2,0.24-0.37c-0.37,0.08-0.93,0.23-0.79,0.13c-0.81-0.25-1.36,1.79-14.52,3.59
		c0.15-0.06,0-0.01,0.66-0.25c0.02,0,0.02-0.01,0.02-0.01c0-0.02-3.14,0.51-1.61,0.12c-0.3,0.05-0.8,0.01-2.61,0.23
		c0.6,0.04,0.26,0.01,1.82,0.01l-0.57,0.05c0.44,0.06,0.55,0.2,3.44-0.24c-0.76,0.37-0.07,0.37,2.8-0.14
		c-0.12,0.06,0.06,0.1-0.13,0.14c1.34-0.14,2.11-0.55,2.65-0.52l-0.12,0.04c3.01-0.82,1.31-0.3,11.35-3.47
		c-0.01,0.01-0.01,0.02-0.02,0.03c0.03-0.01,0.04-0.02,0.03-0.03c0.62-0.26,1.38-0.57,2.34-0.96c-0.05,0.09,2.31-0.83,3.44-1.25
		l-0.02,0.04c0.14-0.26,1.3-0.72,2.12-1.13c0.01,0.11-0.12,0.22-0.51,0.44c0.09-0.04,0.28-0.05,1.63-0.85
		c-0.54,0.68,0.69-0.3,3.87-2.03l-0.51,0.37c0.53-0.2,1.11-0.7,0.51-0.17c2.7-1.23,1.21-0.54,5.89-3.05v0.01
		c2.25-1.44,3.58-1.66,10.66-5.85c-0.27,0.19-0.29,0.23-0.27,0.23c-0.04,0.03-0.13,0.08-0.33,0.19c0.89-0.46,1.17-0.55,2.11-1.23
		c-0.3,0.31,0.82-0.28,1.27-0.58l-0.74,0.31c0.52-0.31,0.99-0.56,1.46-0.82l-0.17,0.29c5.08-1.77,1.49-2.84,17.61-7.86
		c1.19-0.49,2.32-0.51,3.02-0.68l-0.64,0.2c0.39-0.11,0.7-0.21,1.03-0.26c-0.14-0.02-0.27-0.03-0.4-0.04
		c0.39-0.07,0.72-0.12,0.72-0.07c0.86-0.49,0.05-0.36,2.19-0.61c-1.51,0.2-0.07,0.13-0.9,0.33c2-0.16,0.54,0.02,2.87-0.53
		c-0.19,0.11,0.35,0.12,0.62,0.09c-0.44,0,0.32-0.26,1.98-0.39l-0.62,0.01c0.96-0.28,2.83-0.06,7.24,0.41
		c-0.37-0.15-0.44-0.13,0.51-0.17c-0.74-0.02-1.47-0.08-2.21-0.07c0.48-0.05,0.95-0.06,1.43-0.08c-0.67-0.07-1.41-0.03-1.81-0.06
		c0.68-0.07,0.61-0.04,0.3-0.15c0.9,0.2,1.81,0.03,2.85,0.23l-0.06,0.29c1.56,0.28,2.81,0.13,4.85,0.47c-1.24-0.27-0.45-0.19,8,0.83
		c-0.39,0.04-0.02,0.14-0.38,0.16c2.15,0.07,1.64,0.22,13.57,0.3c-0.03,0.01-0.03,0.02-0.04,0.03c3.17-0.67,0.13,0.29,4.95-0.96
		c0.73-0.64,0.66,0.74,6.51-3.87c0.01,0.04,0.01,0.08,0.02,0.11c0.12-0.22,0.53-0.65,1.73-1.19l-0.88,0.7
		c0.57-0.22,1.14-0.91,1.63-1.25l-0.01,0.11c0.87-0.58,0-0.29,2.85-2.19l-0.2,0.04c1.42-0.97,1.69-0.77,5.14-2.46
		c-0.59,0.37-1.88,0.9-2.61,1.39c0.2-0.09,0.58-0.26,1.75-0.81c-0.07,0.02-0.13,0.04-0.2,0.06c0.11-0.04,0.22-0.08,0.31-0.11
		c0,0,0,0,0.01,0c0.01-0.01,0.02-0.01,0.02-0.01c3.3-1.22-0.22-0.81,14.32-2.01c-0.96-0.32,0.79,0,0.63-0.3
		c1.87,0.14,0.45-0.19,3.79,0.7c-0.18-0.13,0.49-0.13,1.27-0.01l-1.1-0.24c1.95,0.02,1.65,0.39,3.61,0.46l-0.62,0.02
		c1.93,0.46-1.84-0.67,11.75,1.78l-0.2,0.04c0.76,0.1,0.28,0.08,3.11,0.62l-0.52-0.03c2.27,0.62,3.57,0.62,5.51,1.02
		c-0.06,0.21,1.73,0.7,2.63,0.94l-0.04,0.03c0.69,0.02,10.13,2.37,20.65,3.17l-0.36,0.03l0.91-0.02c1.87,0,4.53-0.12,8.32-0.55
		l-0.02,0.15c1.05-0.18,0.46,0.1,4.56-0.89l-0.39,0.21c3.74-1.51,1.87-0.47,8.42-3.47c-0.01,0.03-0.03,0.08-0.13,0.16
		c0.8-0.55-0.35,0.49,5.29-3.58l-0.06,0.21c0.16-0.14,0.43-0.38,3.08-2.85l-0.29,0.38c1.46-1.79-0.12,0.67,6.16-6.37
		c-0.78,0.98-1.5,1.73-1.59,1.83c0.12,0.05,0.85-0.72,0.39,0.05c0.6-0.71-0.09-0.04,2.25-2.99l-0.06,0.1
		c0.63-0.85,0.68-0.82,1.17-1.43c-0.03,0.02-0.05,0.04-0.07,0.04c0.09-0.15,0.02,0.02,0.4-0.57c-0.02,0.03,0.02,0.08-0.09,0.23
		c0.2-0.24,0.44-0.56,0.76-0.99c-0.14-0.05,0.31-0.62-0.17-0.15l-0.01,0.01c-0.05,0.08-0.23,0.34-0.4,0.61l0.01-0.04
		c-0.21,0.3-0.51,0.68-0.84,1.12c2-3.49,0.99-0.54,6-8.83c0.31-0.65,0.83-1.67,1.76-2.86l-0.08,0.22c1.24-0.8,1.16-2.53,4.52-7.44
		c0,0,0.01,0,0.02-0.01c2.38-3.47,6.5-8.54,14.05-15.97l-0.28,0.09c0.6-0.49,1.95-1.47,1.95-1.35c0.38-0.6,2.84-1.65,4.17-2.33
		c-0.66-0.05-1.3-0.09-1.92-0.13C1394.22,333.78,1389.98,336.57,1384.65,342.57z M1363.61,373.29c-0.03,0-0.3,0.28-0.52,0.49
		C1363.26,373.57,1363.68,372.98,1363.61,373.29z"
            />
            <path
              className="st10"
              d="M1576.28,396.04l1.37,0.23c-5.46-0.99-4.73-1.05-18.29-3.16c1.95,0.09-3.92-0.73-3.36-0.92
		c-1.45-0.03-5.12-0.84-7.65-1.2c0.14,0.01,0.43,0.03,0.64,0.08c-2.5-0.75,1.3,0.57-5.73-1.29c0.05,0.16-2.61-0.36-2.52-0.23
		c-0.9-0.46-8.29-1.46-5.72-1.48c-3.94-0.45-4.04-0.64-14.77-2.82c0.13,0.4,0.26,0.32-1.88-0.16c3.18,0.62,0.91,0-0.72-0.43
		c0.05,0.03,0.09,0.05,0.14,0.07c-0.77-0.19-1.17-0.31-1.32-0.35c0.18,0.04,0.65,0.15,1.18,0.28c-3.96-1.77,1.89,0.66-16.81-4.04
		l0.81,0.01c-9.25-3.91-21.35-1.23-40.76-17.85l0.44,0.42c-7.9-5.9,1.63-6.48-29.44-25.2c0.03,0.04,0.06,0.07,0.1,0.11
		c-0.83-0.41-3.12-1.31-8.64-2.81c0.07-0.03,0.39,0.03,0.76,0.12c-2.65-0.78-0.85-0.09-8.01-1.81l0.4-0.02
		c-5.22-0.62-27.44-5.08-44.15-1.14c0.33-0.07,0.7-0.14,1.11-0.22c-0.3,0.07-0.6,0.13-0.89,0.21c2.53-0.17-0.11-0.32,9.99-0.74
		c0,0.21,3.32,0.37,4.99,0.49l-0.06,0.03c1.17,0.03,4.43,0.07,8.92,0.35c0.6-0.33,1.05-0.54,1.33-0.67
		c-0.32,0.18-0.66,0.32-0.97,0.52c2.94-1.56,3.1-0.72,2.16-0.38c0.54,0.01,0.15,0.27-0.6,0.66c8.22,0.6,19.7,2,30.21,5.34
		c-0.14,0.1-0.61,0.21-0.77,0.39c2.5-0.52,3.33,0.93,11.36,4.24l-0.12,0.1c2.55,1.55,3.8,2.36,6.68,4.65l-0.64-0.41
		c6.38,5.02,2.15,1.22,11.02,11.62c-0.02-0.01-0.05-0.03-0.09-0.05c1.09,1.05-1.75-1.12,7.59,8.28l-0.33-0.11
		c1.79,1.2-0.31,0.19,5.87,4.1c0.03,0.02,0.06,0.03,0.09,0.05c0,0,0.01,0.01,0.02,0.01c1.05,0.49,1.49,1.58,14.32,5.71
		c-2.18-0.65-3.95-1.22-4.17-1.3c0.03,0.14,1.86,0.75,0.29,0.43c1.6,0.47-0.01-0.11,6.47,1.75l-0.2-0.04
		c2.62,0.74,1.42,0.51,5.99,1.81c-0.26-0.25,0.95-0.04-1.2-0.55c0.02,0.01,0.05,0.01,0.07,0.01c-0.64-0.18-1.48-0.41-2.43-0.68
		c5.86,1.27,1.61,0.79,24.51,6.35l-0.42-0.04c5.63,1.61,9.42,1.74,15.55,3.37c-0.12-0.04-0.2-0.07-0.24-0.09
		c6.7,1.42,2.97,0.92,38.24,7.46l-0.49-0.19c1.41,0.27,4.48,0.75,4.23,0.82c1.16-0.06,12.71,2.79,10.11,1.6
		C1581.44,397.72,1582.37,397.12,1576.28,396.04z M1490.2,378.68c-0.75-0.08-0.32-0.02-1.22-0.31
		C1489.29,378.44,1488.96,378.39,1490.2,378.68z"
            />
            <path
              className="st10"
              d="M1491.12,305.68c-0.79,0.49-1.41,1.04-2.12,1.42l0.55-0.43l-1.46,0.96l0.87-0.46
		c-0.75,0.58,0.21,0.15-0.87,0.76c0.2-0.15-0.8,0.48-1.27,0.64l0,0c-1.48,1.07-0.76,0.08-3.7,2.29c0.27-0.48-1.31,0.53-1.56,0.88
		c0.1-0.07,0.04-0.06,0.25-0.2c-1.34,0.8-1.96,1.12-2.44,1.36l0.29-0.19c-1.16,0.64-1,0.46-6.4,3.74l0.33-0.16
		c-0.79,0.9-0.37-0.31-6.17,3.41l0.19-0.18c-3.35,1.87-2.64,1.31-9.64,5c0.53-0.35,0.8-0.49-1.39,0.71
		c0.19,0.03,0.27-0.06,0.68-0.17c-0.2,0.18-0.68,0.48-1.13,0.71c0.77-0.48-0.09-0.16-0.47-0.05c0.07,0-0.03,0.1,0.02,0.11
		c-1.04,0.28-0.06,0.29-1.41,0.79c0.07-0.17,0.29-0.39-2.95,1.42c0.31-0.17,0.73-0.42,1.04-0.59c-0.79,0.21-0.85,0.29-1.4,0.52
		l0.05-0.02l-1.28,0.53l0.34-0.11c-0.77,0.75-1.85,0.95-2.99,1.74c1.11-0.77,0.15-0.21,0.17-0.39c-0.33,0.11-0.84,0.3-0.72,0.19
		c-0.71,0.09-1.19,1.13-12.67,5.94c0.11-0.1-0.01,0,0.5-0.43c0,0,0,0,0,0c0.32-0.22-2.64,1.53-1.37,0.59
		c-0.76,0.37-1.98,0.93-2.17,1.15c0.53-0.19,0.22-0.09,1.57-0.65l-0.48,0.24c0.62,0.05,1.96-0.89,2.95-1.3
		c-0.56,0.57,0.01,0.33,2.48-0.8c-0.12,0.1,0.17,0.08-0.26,0.24c1.29-0.44,1.94-1.03,2.47-1.09l-0.1,0.06
		c2.96-1.33,2.1-1.25,9.99-4.6c0,0.01,0,0.01-0.01,0.02c0.59-0.35-0.01,0,2.12-1.09c-0.04,0.09,2.08-0.91,3.12-1.35l-0.02,0.04
		c0.11-0.26,1.17-0.74,1.91-1.15c0.02,0.11-0.09,0.22-0.44,0.44c0.08-0.04,0.26-0.05,1.47-0.86c-0.47,0.67,0.62-0.3,3.54-2
		l-0.46,0.37c0.49-0.19,1-0.69,0.47-0.17c2.48-1.18,1.1-0.53,5.43-2.91l0,0c1.93-1.29,3.26-1.5,9.93-5.4
		c-0.67,0.48,0.28-0.07-0.69,0.47c0.94-0.49,1.18-0.54,2.1-1.22c-0.27,0.3,0.77-0.26,1.18-0.54l-0.69,0.29
		c0.48-0.3,0.91-0.53,1.35-0.77l-0.15,0.28c1.9-1.03,4.17-2.41,8.42-5.21c-1.29,0.36,2.53-1.11,1.77-1.02
		c1.05-0.47,3.66-2.36,7.79-4.66l-0.5,0.38c0.3-0.23,0.56-0.4,0.82-0.57l-0.36,0.1c0.32-0.19,0.58-0.36,0.6-0.32
		c0.55-0.75-0.1-0.34,1.66-1.36c-1.2,0.74-0.04,0.17-0.62,0.62c1.71-0.91,0.43-0.16,2.19-1.6c-0.11,0.18,0.39-0.06,0.59-0.21
		c-0.41,0.25,0.08-0.34,1.39-1.26l-0.51,0.3c0.6-0.73,2.28-1.38,5.79-3.59c-0.37,0.1-0.4,0.16,0.27-0.42l-1.66,1.21l1-0.88
		c-0.52,0.34-1.04,0.79-1.36,0.98c1.58-1.21,1.07-0.26,6.24-4.05c-1.05,0.55-0.45,0.11,6.22-4.12c-0.33,0.32,0.29,0.04-0.53,0.61
		c1.84-1.22,1.12-1.03,11.37-6.18c-0.03,0.03-0.02,0.04-0.05,0.06c2.77-1.29,0-0.01,4.49-1.73l-0.23,0.11
		c1.21-0.31,0.05-0.19,6.59-2.43c-0.01,0.04-0.02,0.06-0.02,0.09c0.18-0.16,0.69-0.38,1.91-0.34c-0.34,0.07-0.68,0.14-1.02,0.24
		c0.55,0.01,1.32-0.29,1.87-0.38l-0.05,0.09c1.57-0.32,1.54-0.6,3.26-0.81l-0.19-0.04c1.56-0.37,1.74,0.01,5.29-0.61
		c-0.62,0.2-1.93,0.28-2.71,0.51c0.61-0.12,2.08-0.17,2.4-0.3c-0.25,0.03-0.56,0.1-0.8,0.08c0.78-0.18,0.65-0.69,13.82,0.93
		c-0.83-0.47,0.72,0.14,0.63-0.19c1.66,0.45,0.5-0.09,3.34,1.29c-0.14-0.15,0.47-0.04,1.17,0.18l-0.97-0.39
		c1.79,0.28,1.44,0.62,3.23,0.99l-0.58-0.07c1.68,0.77-1.55-0.97,10.43,3.56l-0.19,0c0.67,0.22,0.24,0.12,2.72,1.11l-0.47-0.12
		c1.96,0.97,3.13,1.22,4.81,1.96c-0.1,0.2,1.41,1.02,2.17,1.43l-0.04,0.01c5.16,2.2,9.25,5.05,24.63,12.24l-0.09,0.12
		c1.39,0.7,2.09,1.03,3.85,1.8l-0.4-0.07c3.48,1.21,1.65,0.57,7.72,3.19c-0.04,0.02-0.12,0.05-0.4-0.06
		c0.82,0.35,3.12,1.46,2.94,1.07c-0.05,0.18,1.79,0.77,2.77,1.08l-0.2,0.07c0.2,0.03,0.52,0.1,3.68,1.19l-0.43-0.08
		c1.94,0.6-0.24-0.03,7.64,2.96c-1.22-0.46-2.19-0.84-2.1-0.81c-0.05,0.12,0.93,0.51,0.09,0.38c0.81,0.26,0.01-0.1,3.3,1l-0.1-0.02
		c1.34,0.45,0.71,0.33,3,1.16c0.01-0.15,0.64,0.11,0.08-0.22c0.14,0.06-0.24-0.08-0.64-0.19l0.04,0c-0.32-0.11-0.75-0.26-1.23-0.43
		c2.06,0.51,0.01-0.01,12.36,4.11l-0.22-0.01c2.86,1.22,4.94,1.04,8.12,2.37c-0.22-0.09-0.33-0.13-0.37-0.17
		c4.78,1.44-2.09-0.28,19.39,5.08l-0.24-0.16c0.71,0.18,2.29,0.46,2.14,0.55c0.61-0.13,6.42,1.9,5.19,0.91
		c0.85,0.28,1.31-0.38-1.75-1.03l0.98,0.2c-1.43-0.45,0.03-0.15-9.61-2.02c1.03-0.02-1.98-0.51-1.66-0.72
		c-0.77,0.05-2.61-0.54-3.91-0.73c0.84-0.19,0.48,0.01-2.54-0.91c0,0.17-1.56-0.25-1.29-0.04c-0.21-0.39-4.29-0.98-2.86-1.14
		c-2.73-0.27-0.09-0.04-7.5-1.84c-0.01,0.39,0.07,0.27-0.99-0.04c2.06,0.53-0.93-0.66-0.26-0.3c-1.23-1.22-1.63-0.36-8.53-2.74
		l0.44-0.05c-6.3-2.02-0.85,0.05-21.92-8.14l0.31,0.08c-1.13-0.54-1.42-0.27-2.44-0.84l0.22,0.06c-3.71-1.83-0.6,0.02-20.17-9.48
		c0.08-0.03,0.46,0.14,0.77,0.25c-2.21-0.98-0.43-0.17-4.13-2.04l0.22,0c-2.52-0.71,0.59-1.29-31-14.24l0.11,0.02
		c-5.66-1.29-2.72-1.49-14.63-3.16l0.11,0.1c-4.13,0.11-0.54-0.65-17.03,1.36l0.53-0.28c-2.31,0.81-0.35-0.33-9.44,3.04
		c0.16-0.11,0.15-0.15,0.44-0.27c-1.46,0.61-4.31,1.46-10.66,5.12c-0.03-0.24-1.93,0.86-2.54,1.32c0.19-0.18-0.61,0.26-0.4,0.03
		c-0.74,0.44-1.41,0.88-1.22,0.91c-2.61,1.32-2.01,0.93-9.53,6.15c0.23-0.23,0.43-0.42,0.6-0.58c-1.36,0.96-1.65,1.02-7.95,5.45
		c-0.01-0.02,0.08-0.09,0.19-0.19c-0.69,0.57,0.72-0.53-1.35,1.41c0.38-0.6-1.64,1-0.99,0.12c-0.2,0.16-0.43,0.37-0.35,0.4
		c-0.32,0.19-0.44,0.22-0.17-0.05c-1.11,0.8-0.64,0.63-1.68,1.31c-0.02-0.04,0.17-0.2,0.25-0.32c-0.47,0.42-0.94,0.87-1.84,1.4
		c0.04-0.06-0.09-0.03,0.18-0.19c-1.5,0.99-2.44,1.88-4.15,2.87c0.18-0.24,0.29-0.49-1.67,0.81L1491.12,305.68z M1646.01,312.54
		c-0.36,0-0.19,0.02-0.62-0.19C1645.55,312.39,1645.38,312.38,1646.01,312.54z M1644.4,310.98c-0.11-0.04-0.25-0.09-0.51-0.17
		C1644.1,310.88,1643.89,310.81,1644.4,310.98z M1642.47,310.41c0.11,0.01-0.29-0.17-0.58-0.32
		C1642.14,310.17,1642.82,310.36,1642.47,310.41z"
            />
            <path
              className="st10"
              d="M1296.8,443.5l0.17,0.04c-0.05-0.33-0.67-0.2-0.97-0.39c0.09-0.12,0.24-0.2-0.64-0.6
		c0.06-0.02,0.03-0.15,0.15-0.06c-0.33-0.3-0.7-0.17-0.77-0.39l0.04,0c-0.59-0.33-1.65-0.98-3.69-2.14
		c0.06-0.04-0.55-0.48-0.81-0.74l0.03-0.02c-0.16,0.11-0.44-0.14-0.68-0.26c0.07-0.08,0.14-0.09,0.28-0.04
		c-0.05,0.01,0.02-0.09-0.52-0.23l0.13-0.07c-0.12-0.05-0.38-0.42-1.29-0.54l0.22,0.02c-0.1-0.01-0.49-0.36-0.09-0.14
		c-0.56-0.56-0.23-0.23-1.6-1.16l0,0c-0.55-0.23,0,0-1.37-1.11c0.15-0.14-0.43-0.34-1.42-1.2c0.26,0.1-0.02-0.12,0.25,0.12
		c-0.24-0.25-0.25-0.36-0.61-0.48c0.18-0.02-0.1-0.28-0.25-0.36l0.13,0.22c-0.15-0.1-0.27-0.21-0.39-0.32l0.17-0.08
		c-0.53-0.52-1.2-0.99-2.58-1.87c0.01,0.5-0.4-0.81-0.48-0.45c-0.27-0.32-0.51-0.39-0.81-0.7c0.03,0.11-1.28-0.35-0.68-0.63
		c-0.38-0.13-0.54-0.48-0.71-0.63l0.19,0.08c-0.12-0.05-0.2-0.11-0.28-0.17l0.03,0.16c-0.09-0.09-0.17-0.15-0.14-0.19
		c-0.29,0.01-0.21,0.35-0.69-0.22c0.37,0.31,0.08-0.12,0.37,0.04c-0.07-0.04-0.2-0.12-0.15-0.17c-0.32-0.38-0.29,0.03-0.7-0.25
		c0.1-0.04,0-0.2-0.08-0.23c0.14,0.08-0.12,0.19-0.65-0.11l0.14,0.13c-0.44,0.04-0.61-0.59-1.75-1.38c0.04,0.16,0.07,0.15-0.23,0.1
		l0.62,0.26l-0.46-0.07c0.17,0.1,0.41,0.15,0.5,0.23c-0.23-0.02-0.19-0.03-0.16,0.1c-0.86-0.53-0.09-0.73-1.92-1.33l0.16,0.13
		c-0.13,0.07-0.35-0.16-0.56-0.2c0.06-0.08-0.04-0.17-0.09-0.26c-0.02,0.07-0.41-0.21-0.54-0.05c-0.44-0.41-0.14-0.34-1.26-0.43
		c0.18-0.05,0-0.19,0.33-0.14c-0.71-0.06,0.22,0.11-4.06-1.2c0.01-0.01,0.02-0.02,0.02-0.03c-0.99-0.14,0.03-0.07-1.5-0.46l0.08,0
		c-0.36-0.12-0.16-0.11-2.31-0.04c0.01-0.05,0.02-0.07,0.03-0.09c-0.08,0.08-0.26,0.16-0.63-0.18l0.34,0.02
		c-0.16-0.19-0.44-0.02-0.62-0.07l0.03-0.08c-0.53-0.01-0.55,0.18-1.11,0.13l0.06,0.06c-0.78,0.15-0.29-0.26-1.73,0.06
		c0.2-0.15,0.63-0.22,0.89-0.31c-0.2,0-0.69,0.01-0.79,0.11c0.08-0.02,0.18-0.07,0.26-0.04c-1.68,0.33-0.06,0.06-4.57,0.2
		c0.16-0.13,0.21,0.85-1.38,0.02c0.08,0.09-0.11,0.19-0.36,0.2l0.36,0.06c-0.54,0.29-0.55-0.14-1.1,0.15l0.16-0.14
		c-0.44,0.06-0.55,0.13-3.43,1.18l0.03-0.09c-0.35,0.21-0.22-0.02-0.89,0.37l0.12-0.13c-0.7,0.16-0.96,0.57-1.49,0.83
		c-0.08-0.21-0.62-0.06-0.9,0.01l0-0.03c-1.26,0.43-0.07-0.15-9.08,4.82l0.05-0.15c-0.75,0.88-0.29,0.4-2.16,1.62
		c-0.01-0.03-0.03-0.09,0.05-0.15c-0.25,0.15-1.01,0.48-0.76,0.67c-0.11-0.15-0.54,0.33-0.76,0.57l-0.03-0.14
		c0.01,0.05-0.26,0.33-0.89,0.89l0.07-0.15c-0.3,0.24-1.03,0.65-2.21,1.41c0.35-0.22,0.64-0.4,0.62-0.37
		c-0.07-0.11-0.36,0.06-0.24-0.24c-0.21,0.19,0.07,0.07-0.81,0.77l0.02-0.03c-0.37,0.27-0.25,0.07-0.91,0.49
		c0.09,0.11-0.11,0.21,0.13,0.2c-0.05,0.02,0.07-0.05,0.16-0.15l0,0.02c0.09-0.06,0.21-0.14,0.35-0.23c-0.76,0.9-0.75,0.2-2.66,1.72
		c0.26-0.32,0.28-0.26-0.85,0.52l0.03-0.08c-1,0.24-1.15,1.22-2.3,1.41c0.08-0.02,0.12-0.03,0.14-0.01
		c-0.59-0.06-2.64,1.7-5.81,2.66l0.12,0.06c-0.74,0.1-1.02,0.28-1.57,1.41c0.15-0.01,0.26,0.09,0.73-0.4
		c0.1,0.43,0.41-0.47,0.49,0.02c0.07-0.16,0.46,0.12,1.58-0.79c-0.2,0.37,0.62-0.25,0.65,0.04c0.12-0.32,0.76-0.44,1.08-0.75
		c-0.01,0.03-0.04,0.08-0.08,0.09c0.22-0.09,0.32-0.05,0.48-0.08c-0.24,0.11,0.62-0.99,0.5-0.27c0.07-0.13,0.35-0.29,0.23-0.35
		c0.25,0,0.2-0.04,0.37,0.05c-0.27-0.15,1.01-0.75,0.67-0.21c0.44-0.73,0.1,0.03,2.04-1.49c-0.11-0.14-0.28-0.22,0.14-0.37
		c0.69,0.55,0.02,0.38,2.67-1.37l-0.01,0.23c1.03-0.94-0.2,0.18,6.29-4.16l-0.06,0.09c0.37-0.17,0.22-0.5,0.6-0.59l-0.05,0.06
		c0.22,0.08,0.52-0.86,4.32-2.48c-0.02-0.06,0.13-0.21,0.59-0.36c-0.23-0.18,0.3,0.16,1.24-0.77c0.01,0.06-0.09,0.14-0.17,0.23
		c0.3-0.34,0.15,0.11,0.42-0.2c-0.06-0.01-0.02-0.09-0.03-0.13c0.22-0.01,0.6-0.22,0.87-0.41l-0.01,0.12
		c0.51-0.24,1.04-0.57,1.61-0.86c1.07-0.35-0.24,0.12,8.37-3.15l-0.03,0.02c1.59-0.22,4.01-1,9.9-0.92l-0.19,0.15
		c0.56-0.05,0.52-0.02,3.07,0.27c-0.07,0.03-0.08,0.07-0.17,0.05c0.18,0.16,0.76,0.16,0.78,0.07c-0.02,0.07,0.91,0.34,2.82,0.72
		c-0.1,0.2,0.55,0.41,0.81,0.4c-0.1,0.03,0.17,0.14,0.04,0.2c0.25,0.1,0.5,0.15,0.5,0.02c0.73,0.56,0.52,0.35,3.27,1.44
		c-0.92,0.1-1.23-0.59,2.36,1.5c-0.01,0.02-0.08,0-0.14-0.02c0.34,0.1-0.05,0,0.79,0c-0.34,0.13,0.47,0.4-0.01,0.52
		c0.08,0.03,0.19,0.04,0.21-0.02c0.09,0.08,0.1,0.14-0.05,0.13c0.4,0.19,0.33,0.02,0.65,0.25c-0.03,0.04-0.11,0.01-0.17,0.03
		c0.22,0.04,0.44,0.08,0.69,0.31c-0.03,0.01-0.02,0.07-0.11,0.01c0.47,0.32,0.95,0.36,1.4,0.81c-0.15,0.02-0.25,0.15,0.35,0.52
		l-0.05-0.16c0.23,0.18,0.53,0.24,0.69,0.44l-0.22-0.07l0.46,0.31l-0.21-0.25c0.89,0.27,0.1-0.16,1.81,1.28
		c-0.25,0,0.09,0.46,0.43,0.39c-0.04-0.02-0.03,0.01-0.11-0.03c0.44,0.32,0.58,0.49,0.69,0.63l-0.12-0.07
		c0.48,0.46-0.06-0.02,1.9,1.43l-0.08-0.09c0.24-0.15,0.29,0.4,0.62,0.5c-0.1,0.28-0.12,0.02,1.17,0.89l-0.11,0
		c0.99,0.66,0.68,0.69,2.78,2.13c-0.2-0.06-0.3-0.12,0.41,0.29c0.03-0.12-0.03-0.11-0.09-0.26c0.11-0.01,0.29,0.06,0.42,0.15
		c-0.28-0.11-0.11,0.15-0.04,0.28c0-0.04,0.06-0.05,0.07-0.08c0.52,0.49,0.12-0.09,1.34,0.82c-0.11-0.03-0.25-0.12-0.36-0.15
		c0.13,0.27,0.18,0.26,0.32,0.38l-0.01-0.01l0.33,0.3l-0.07-0.1c0.47-0.11,0.59,0.32,1.09,0.36c-0.21-0.06-0.49,0.25,0.5,0.67
		c-0.15,0.01,1.59,0.65,3.51,1.66c-0.18,0.09-0.38,0.23,1,0.39l-0.14-0.11C1297.16,443.68,1296.97,443.86,1296.8,443.5z
		 M1229.49,435.78c0.01-0.09-0.01-0.12,0.16-0.14C1229.61,435.68,1229.62,435.62,1229.49,435.78z M1230.53,436.2
		c0.03-0.02,0.07-0.05,0.14-0.1C1230.62,436.14,1230.67,436.1,1230.53,436.2z M1231.01,435.75c-0.01,0.05,0.12-0.01,0.23-0.04
		C1231.18,435.77,1230.97,435.97,1231.01,435.75z"
            />
            <path
              className="st10"
              d="M1396.77,332.42c0.31-0.2,0.65-0.34,0.97-0.52c-0.28,0.13-0.73,0.34-1.33,0.67c0.62,0.04,1.26,0.08,1.92,0.13
		c0.75-0.39,1.14-0.65,0.6-0.66C1399.87,331.7,1399.71,330.86,1396.77,332.42z M1374.62,356.86c0,0.02,0.03,0,0.1-0.08
		c-0.03,0.02-0.06,0.04-0.09,0.06C1374.62,356.85,1374.62,356.86,1374.62,356.86z M1372.22,362.35c-0.13,0.25-0.31,0.53-0.36,0.68
		C1371.94,362.98,1372.04,362.73,1372.22,362.35z M1248.06,393.29l0.25-0.1c-0.06,0.02-0.13,0.03-0.19,0.05
		C1248.1,393.25,1248.08,393.27,1248.06,393.29z M1183.75,399.68c0.03-0.02,0.07-0.05,0.16-0.11c-0.05,0.03-0.09,0.05-0.14,0.08
		C1183.76,399.66,1183.76,399.67,1183.75,399.68z"
            />
            <path
              className="st10"
              d="M1653.61,348.61l0.66,0.16c-0.75-0.45-2.96-0.33-2.88-0.72c-0.29,0.11-0.68-0.49-3.64-0.43
		c0.7-0.19-1.37-0.16-1.16-0.42c-0.37,0.1-0.42-0.03-2.7,0.04c0.05-0.02,0.15-0.05,0.23-0.04c0.06-0.44-2.55,0.51-2.03-0.27
		c0.03,0.14-1.06,0.17-0.85,0.3c-0.47-0.11-0.4-0.05-0.64-0.21c0.35,0.25-2.56,0.26-1.43-0.1c-1.71,0.6-0.12-0.16-5.16,0.76
		c0.11,0.3,0.17,0.25-0.61,0.33c1.65-0.49-0.84-0.29-0.25-0.18c-0.94-0.6-1.86,0.29-5.92,1.06l0.22-0.25
		c-1.47,0.78-0.96-0.47-15.02,4.54l0.18-0.11c-0.81,0.24-0.78,0.61-1.55,0.76l0.13-0.08c-2.3,0.85-1.28,0.26-13.85,5.47
		c0.01-0.07,0.26-0.19,0.45-0.32c-1.44,0.71-0.26,0.13-2.83,1.19l0.08-0.15c-1.36,0.05-2.17,2.09-21.52,6.7l0.07-0.03
		c-2.24,0.31-0.98,0.71-9.74,0.9l0.08,0.09c-1.8,0.05-0.3,0.42-11.13-0.76l0.41-0.1c-2.14-0.2-4.27-0.92-6.33-1.37
		c0.13,0,0.15-0.05,0.35,0.01c-0.77-0.28-2.96-0.94-7.48-2.24c0.13-0.19-1.26-0.63-1.77-0.7c0.18-0.01-0.39-0.21-0.17-0.24
		c-0.55-0.18-1.06-0.32-1-0.17c-1.77-0.83-1.31-0.68-7.26-2.16c0.2-0.01,0.37,0,0.51,0c-2.12-0.51-1.41-0.52-6.28-1.34
		c0.01-0.02,0.15-0.02,0.29-0.02c-0.83-0.01,0.57,0.05-1.43,0.21c0.47-0.25-1.27-0.22-0.54-0.51c-0.17,0-0.39,0.03-0.36,0.1
		c-0.25-0.04-0.32-0.09-0.06-0.14c-0.93-0.03-0.61,0.1-1.44,0.02c0.01-0.05,0.18-0.05,0.27-0.1c-0.43,0.05-0.86,0.15-1.57,0.08
		c0.04-0.03-0.04-0.07,0.17-0.06c-1.22-0.07-2.07,0.28-3.4,0.24c0.36-0.14-0.22-0.3-1.25-0.12c0.1,0.02,0.2,0.03,0.29,0.05
		c-0.62,0.08-1.15,0.3-1.69,0.31l0.45-0.14l-1.16,0.2l0.67-0.02c-0.61,0.19,0.1,0.22-0.73,0.29c0.16-0.04-0.62,0.08-0.96,0.04l0,0
		c-1.21,0.43-0.46-0.24-2.83,0.68c0.24-0.32-0.96,0.03-1.16,0.29c0.07-0.03,0.03-0.04,0.19-0.11c-1.1,0.37-1.54,0.44-1.92,0.5
		l0.32-0.12c-1.4,0.37,0.08-0.07-4.67,1.69l0.24-0.07c-0.54,0.67-0.48-0.4-4.38,1.74l0.13-0.13c-2.33,1.03-1.87,0.64-6.74,2.64
		c0.26-0.17,0.34-0.2-0.98,0.36c0.14,0.07,0.19,0.01,0.49,0c-0.14,0.12-0.48,0.29-0.79,0.4c0.54-0.27-0.07-0.17-0.33-0.15
		c0.05,0.02-0.02,0.09,0.01,0.11c-0.73,0.04-0.03,0.26-0.98,0.44c0.03-0.16,0.2-0.24-2.08,0.64c0.21-0.1,0.53-0.2,0.74-0.3
		c-0.56,0-0.6,0.06-0.99,0.14l0.04-0.01l-0.9,0.19l0.23-0.02c-0.38,0.26-0.21,0.21-2.14,0.85c0.82-0.41,0.11-0.15,0.15-0.32
		c-0.24,0.01-0.59,0.04-0.5-0.02c-0.33-0.19-2.52,1.42-9.15,1.04c0.1-0.04,0,0,0.43-0.17c0,0,0,0,0,0c0.27-0.07-2.06,0.22-0.97-0.07
		c-0.19,0.01-0.51-0.03-1.63,0.03l0.35,0.12c0.01-0.12,0.21-0.25,0.77,0.03l-0.36,0c0.33,0.31,1.43,0.06,2.14,0.13
		c-0.39,0.13,0.22,0.42,1.72,0.27c-0.1,0.05,0.09,0.14-0.22,0.13c0.91,0.08,1.46-0.21,1.81-0.07l-0.08,0.02
		c2.52-0.32,0.82-0.17,7.25-1.27c0.01,0.23-0.24-0.04,1.5-0.5c-0.03,0.08,1.49-0.33,2.21-0.54l-0.01,0.04
		c0.08-0.22,0.82-0.44,1.34-0.67c0.02,0.11-0.06,0.18-0.31,0.32c0.06-0.02,0.18,0.01,1.03-0.48c-0.34,0.5,0.43-0.1,2.46-1.15
		l-0.32,0.25c0.37-0.09,0.67-0.43,0.33-0.06c1.69-0.56,0.73-0.24,3.79-1.57l0,0c1.24-0.68,2.1-0.72,7.05-2.62
		c-0.48,0.26,0.2,0.01-0.5,0.24c0.68-0.19,0.85-0.17,1.52-0.54c-0.2,0.21,0.55-0.03,0.85-0.16c-0.17,0.02-0.33,0.05-0.49,0.07
		c0.35-0.15,0.66-0.22,0.99-0.31l-0.12,0.21c1.43-0.27,3.01-0.95,6.31-1.71c-0.71-0.33,1.75,0.17,1.34-0.13
		c0.84-0.03,1.3-0.21,2.16-0.17c-0.05-0.09,2.39-0.59,1.87,0.01c0.78-0.18,1.46,0.1,1.9,0.12l-0.41,0.03c0.25-0.02,0.45-0.01,0.65,0
		l-0.23-0.13c0.24,0.03,0.44,0.04,0.44,0.09c0.55-0.25,0.07-0.38,1.41-0.09c-1.34-0.17-0.67,0.53,1.21,0.18
		c-0.14,0.07,0.21,0.2,0.37,0.21c-0.31-0.06,0.17-0.22,1.25-0.07l-0.38-0.09c1.74,0.18,2.94,0.77,7.88,2l-0.38-0.17
		c1.22,0.2,1.07,0.53,5.17,1.5c-0.32,0,0.09,0.22-0.57,0.07c1.51,0.38,0.92,0.01,8.26,2.63c-0.02,0.01-0.03,0.02-0.04,0.02
		c1.98,0.51-0.03,0.08,3.05,1.05l-0.17-0.03c0.76,0.24,0.21,0.2,4.65,0.86c-0.03,0.04-0.04,0.06-0.06,0.09
		c0.16-0.07,0.53-0.08,1.27,0.39c-0.23-0.03-0.46-0.09-0.7-0.13c0.32,0.26,0.9,0.17,1.27,0.25l-0.06,0.07
		c1.08,0.14,1.12-0.05,2.27,0.15l-0.11-0.08c1.64-0.01,0.6,0.53,3.57,0.29c-0.43,0.11-1.32,0.1-1.87,0.14
		c0.42,0.04,1.42,0.11,1.64,0.03c-0.17,0-0.38,0.03-0.55-0.01c2.31-0.25,0.71,0.26,9.41,0.14c-0.63-0.24,0.5-0.05,0.36-0.35
		c1.04-0.02,0.28-0.18,2.44,0.26c-0.13-0.09,0.28-0.2,0.78-0.23l-0.71-0.04c1.18-0.33,1.08,0.11,2.28-0.23l-0.37,0.15
		c1.17-0.2-0.06,0.16,7.26-1.76l-0.09,0.1c0.44-0.18,0.19-0.02,1.89-0.59l-0.28,0.16c1.45-0.28,2.11-0.83,3.27-1.23
		c0.06,0.21,1.19-0.05,1.75-0.21l-0.01,0.04c2.62-1.12,7.99-2.78,17.07-6.81l0.04,0.14c0.97-0.37,1.45-0.54,2.62-1.06l-0.2,0.19
		c2.1-1.22,0.96-0.6,5.07-2.24c-0.01,0.04-0.02,0.11-0.21,0.19c0.55-0.2,2.19-0.67,1.89-0.87c0.08,0.16,1.22-0.43,1.83-0.73
		l-0.05,0.17c0.05-0.07,0.76-0.43,2.33-1.08l-0.24,0.17c0.77-0.24,2.18-0.79,5.2-1.59c-0.83,0.24-1.5,0.43-1.44,0.4
		c0.04,0.12,0.71-0.05,0.23,0.27c0.53-0.2-0.05-0.09,2.13-0.84l-0.06,0.03c0.89-0.26,0.52-0.06,2.08-0.46
		c-0.06-0.13,0.38-0.22-0.06-0.23c0.1-0.02-0.16,0.05-0.42,0.15l0.02-0.02c-0.22,0.06-0.51,0.14-0.83,0.23
		c2.28-0.97,0.57-0.03,6.38-1.52c-0.17,0.06-0.37,0.09-0.46,0.14c0.05,0.05,1.47-0.61,2.49-0.51l-0.13,0.08
		c1.41,0.04-0.32-0.29,5.55-0.74c-0.16-0.01-0.24-0.02-0.27-0.04c1.94,0.32,1.91-1.08,13.23,0.6l-0.16-0.13
		c0.48,0.1,1.54,0.21,1.43,0.31c0.65-0.23,4.03,1.56,3.44,0.63C1655.27,349.79,1655.63,349.12,1653.61,348.61z M1527.03,360.19
		l0.89,0.04c-0.4-0.1-0.86-0.14-1.1-0.22c0.43,0.01,0.38,0.02,0.21-0.12c0.71,0.21,1.02,0.37,1.33,0.54L1527.03,360.19z
		 M1621.03,350.62c0.06-0.05-0.23,0.01-0.45,0.03C1620.74,350.59,1621.21,350.39,1621.03,350.62z M1621.94,350.24L1621.94,350.24
		c0.14-0.05,0.27-0.08,0.34-0.11C1622.21,350.15,1622.11,350.18,1621.94,350.24z M1623.84,350.67c-0.16,0.15-0.09,0.12-0.41,0.14
		C1623.53,350.77,1623.44,350.84,1623.84,350.67z"
            />
            <polygon
              className="st10"
              points="1537.78,362.21 1537.78,362.21 1537.86,362.24 	"
            />
            <path
              className="st10"
              d="M1390.83,424.71c0.35-0.16,0.59-0.37,1.56-2.22c0.01,0.01,0.02,0.02,0.02,0.03c0.32-0.87,0.08,0.05,0.75-1.27
		l-0.01,0.08c0.18-0.3,0.15-0.09,0.45-2.11c0.04,0.02,0.06,0.03,0.09,0.05c-0.07-0.09-0.11-0.27,0.29-0.54l-0.08,0.31
		c0.22-0.11,0.1-0.41,0.17-0.56l0.07,0.04c0.1-0.49-0.08-0.53,0.02-1.05l-0.07,0.04c-0.03-0.75,0.32-0.22,0.17-1.6
		c0.12,0.2,0.15,0.61,0.21,0.87c0.02-0.19,0.06-0.65-0.03-0.75c0.01,0.08,0.05,0.18,0.01,0.25c-0.14-1.94,0.53-0.73-1.82-2.84
		c0.12-0.14,0.36,0.78-1.26,0.28c0.09,0.08-0.06,0.2-0.28,0.27l0.34-0.01c-0.42,0.39-0.54-0.02-0.97,0.38l0.12-0.17
		c-0.36,0.15-0.48,0.19-2.85,1.83l0-0.09c-0.27,0.27-0.2,0.02-0.72,0.53l0.08-0.15c-0.61,0.28-0.72,0.75-1.14,1.11
		c-0.13-0.18-0.59,0.06-0.83,0.19l-0.01-0.03c-0.94,0.51,0.24,0.31-3.26,2.71c0.06,0.01,0.1,0.28-0.29,0.46
		c0.07-0.05-0.71,0.02-0.5,0.61c-0.63,0.17-0.03,0.14-3,2.69l0.01-0.16c-0.43,0.99-0.14,0.44-1.52,2.01c-0.02-0.03-0.05-0.08,0-0.16
		c-0.18,0.2-0.77,0.7-0.49,0.81c-0.15-0.12-0.39,0.44-0.52,0.72l-0.08-0.12c0.04,0.04-0.15,0.36-0.53,1.06l0.01-0.16
		c-0.19,0.31-0.71,0.89-1.52,1.92c0.24-0.31,0.44-0.55,0.43-0.52c-0.1-0.08-0.31,0.16-0.3-0.15c-0.12,0.24,0.09,0.04-0.46,0.95
		l0.01-0.03c-0.19,0.3-0.2,0.21-0.45,0.51c0.01-0.07-0.06,0.01-0.11-0.04l0.09,0.06c-0.05,0.06-0.1,0.12-0.16,0.21
		c0.12,0.07-0.02,0.22,0.19,0.14c-0.04,0.03,0.04-0.06,0.09-0.18l0,0.02c0.06-0.09,0.13-0.2,0.22-0.32
		c-0.31,1.06,0.01-0.05-1.61,2.35l0.03-0.03c-0.03,0.05-0.05,0.09-0.08,0.14c0.03-0.11,0.13-0.45-0.04,0.12c0-0.04-0.01,0,0.01-0.16
		l0,0c-0.04-0.38,0.76-1.08,1.41-2.81c-0.02,0.24,0.1-0.04-0.04,0.24c0.16-0.25,0.26-0.27,0.27-0.62c0.07,0.15,0.24-0.13,0.26-0.28
		l-0.17,0.15c0.05-0.15,0.11-0.27,0.18-0.39l0.12,0.14c0.34-0.57,0.58-1.23,0.98-2.58c-0.46,0.11,0.64-0.49,0.28-0.5
		c0.21-0.3,0.21-0.51,0.4-0.83c-0.1,0.06-0.03-1.18,0.39-0.7c0.01-0.35,0.29-0.56,0.38-0.73l-0.02,0.18
		c0.01-0.11,0.04-0.19,0.07-0.27l-0.15,0.05c0.06-0.09,0.09-0.17,0.13-0.15c-0.07-0.23-0.4-0.11,0.01-0.63
		c-0.19,0.37,0.14,0.04,0.07,0.33c0.02-0.07,0.06-0.21,0.11-0.16c0.27-0.34-0.1-0.24,0.04-0.65c0.07,0.08,0.19-0.04,0.2-0.11
		c-0.03,0.13-0.22-0.05-0.07-0.58l-0.09,0.14c-0.15-0.38,0.37-0.63,0.85-1.73c-0.14,0.06-0.12,0.09-0.16-0.18l-0.1,0.57l-0.05-0.4
		c-0.05,0.16-0.04,0.37-0.09,0.46c-0.04-0.2-0.02-0.17-0.14-0.12c0.28-0.85,0.67-0.16,0.8-1.85l-0.1,0.15
		c-0.09-0.1,0.07-0.32,0.08-0.5c0.09,0.04,0.16-0.05,0.23-0.11c-0.07-0.01,0.11-0.38-0.07-0.48c0.29-0.43,0.28-0.17,0.16-1.14
		c0.08,0.15,0.19-0.02,0.2,0.27c-0.04-0.57-0.06,0.22,0.46-3.64c0.01,0.01,0.02,0.01,0.03,0.02c-0.04-0.86,0.08,0.01,0.19-1.36
		l0.02,0.07c0.04-0.32,0.12-0.15-0.41-1.98c0.04,0,0.06,0,0.09,0.01c-0.1-0.05-0.2-0.2,0.04-0.57l0.06,0.3
		c0.15-0.18-0.07-0.39-0.07-0.55l0.08,0c-0.12-0.46-0.33-0.41-0.43-0.9l-0.04,0.07c-0.37-0.6,0.17-0.33-0.6-1.39
		c0.2,0.1,0.44,0.41,0.59,0.61c-0.05-0.18-0.25-0.56-0.37-0.61c0.05,0.06,0.13,0.12,0.13,0.2c-1.13-1.42,0.12-0.64-3.34-1.76
		c0.42,0.04-0.19,0.16,0.07,0.36c-0.18,0.13-0.18,0.31-0.39-0.04c0.26-0.11-0.14,0.4,0.19,0.23c-0.24,0.18-0.44,0.16-0.91,0.36
		c0.12,0.01,0.06,0.2-0.09,0.38l0.28-0.21c-0.13,0.55-0.47,0.3-0.55,0.87l-0.02-0.2c-0.21,0.32-0.13,0.44-0.99,2.97l-0.05-0.07
		c-0.04,0.35-0.14,0.12-0.23,0.8l-0.03-0.15c-0.31,0.54-0.15,0.95-0.27,1.45c-0.21-0.08-0.44,0.35-0.55,0.57l-0.03-0.02
		c-0.47,0.87,0.41,0.15-1.22,3.73c0.05-0.02,0.24,0.17,0,0.5c0.04-0.08-0.59,0.37-0.11,0.72c-0.45,0.43,0.08,0.12-1.24,3.53
		l-0.07-0.13c0.1,1,0.08,0.4-0.36,2.31c-0.03-0.02-0.08-0.04-0.07-0.13c-0.06,0.24-0.36,0.9-0.06,0.88
		c-0.19-0.04-0.15,0.53-0.12,0.82l-0.13-0.07c0.05,0.02,0.06,0.36,0.02,1.11l-0.06-0.14c-0.04,0.4-0.04,0.12-0.48,2.23
		c0.07-0.35,0.14-0.64,0.14-0.61c-0.13-0.03-0.2,0.26-0.33-0.01c0,0.25,0.1,0,0.02,0.98l-0.01-0.03c-0.04,0.4-0.12,0.2-0.24,0.87
		c0.14,0.01,0.09,0.2,0.23,0.04c-0.02,0.04,0.01-0.06-0.01-0.19c0,0,0.01,0.01,0.01,0.01c0.01-0.1,0.03-0.22,0.06-0.36
		c0.18,1.03-0.36,0.56-0.41,2.73c-0.09-0.36-0.03-0.34-0.11,0.86l-0.05-0.07c-0.42,0.81,0.26,1.45-0.22,2.35
		c0.03-0.06,0.05-0.09,0.08-0.1c-0.11,0.17-0.21,2.56-0.56,5.56l0.11-0.07c-0.02,0.1-0.03,0.32-0.15,0.89
		c-0.09-0.2-0.25-0.42-0.3-0.61c0.15-0.01,0.31,0.06,0.02-0.9c0.06,0.04,0.13-0.08,0.14,0.08c0-0.47-0.34-0.66-0.23-0.86l0.02,0.03
		c-0.18-0.69-0.44-1.96-1.23-4.26c0.07,0.01-0.11-0.75-0.15-1.13l0.03,0c-0.2-0.02-0.27-0.4-0.38-0.66
		c0.11-0.02,0.16,0.02,0.25,0.15c-0.04-0.02,0.07-0.06-0.27-0.53c0.38,0.04,0.1-0.14-0.56-1.24l0.17,0.15
		c-0.07-0.07-0.19-0.6,0.01-0.17c-0.14-0.82-0.06-0.34-0.63-1.94l0,0c-0.31-0.53,0,0-0.52-1.75c0.2-0.03-0.16-0.55-0.54-1.85
		c0.17,0.23,0.04-0.11,0.15,0.24c-0.07-0.35-0.02-0.45-0.26-0.76c0.16,0.08,0.07-0.3-0.02-0.45l-0.01,0.26
		c-0.08-0.17-0.12-0.33-0.16-0.49l0.19,0.03c-0.16-0.75-0.49-1.54-1.19-3.07c-0.27,0.44,0.1-0.93-0.16-0.66
		c-0.06-0.43-0.22-0.62-0.32-1.05c-0.05,0.11-0.88-1.03-0.23-0.93c-0.24-0.33-0.19-0.72-0.24-0.94l0.12,0.18
		c-0.07-0.11-0.1-0.2-0.14-0.3l-0.07,0.15c-0.02-0.12-0.06-0.22-0.02-0.23c-0.25-0.16-0.37,0.17-0.46-0.59
		c0.13,0.48,0.13-0.06,0.29,0.26c-0.04-0.07-0.11-0.22-0.04-0.23c-0.05-0.51-0.25-0.15-0.43-0.62c0.11,0.03,0.11-0.17,0.07-0.24
		c0.06,0.15-0.21,0.08-0.47-0.48l0.04,0.19c-0.38-0.23-0.18-0.85-0.61-2.2c-0.06,0.16-0.03,0.16-0.25-0.06l0.34,0.6l-0.33-0.34
		c0.08,0.19,0.24,0.37,0.27,0.5c-0.17-0.16-0.14-0.15-0.19-0.02c-0.37-0.96,0.35-0.64-0.71-2.29l0.04,0.21
		c-0.14-0.03-0.18-0.35-0.3-0.52c0.1-0.02,0.08-0.15,0.1-0.26c-0.06,0.04-0.19-0.43-0.39-0.4c-0.08-0.61,0.09-0.34-0.67-1.2
		c0.16,0.09,0.13-0.14,0.34,0.13c-0.44-0.48,0.09,0.26-2.06-3.81c0.04,0.01,0.04-0.02,0.06,0.04c-0.61-0.93,0.04-0.03-0.66-1.52
		l0.06,0.07c-0.13-0.37-0.03-0.17-1.41-1.91c0.05-0.02,0.07-0.02,0.1-0.03c-0.12-0.01-0.3-0.12-0.22-0.63l0.18,0.3
		c0.07-0.25-0.23-0.39-0.29-0.57l0.08-0.02c-0.28-0.46-0.46-0.36-0.75-0.87l-0.02,0.08c0.08-0.18-1.89-2.71-0.39-1.12
		c-1.49-1.85-0.64-2.06-2.21,2.55c-0.08-0.19,0.88,0.01-0.37,1.37c0.12-0.05,0.13,0.16,0.09,0.41c0.05-0.11,0.1-0.23,0.16-0.34
		c0.12,0.62-0.28,0.51-0.15,1.14l-0.09-0.2c-0.05,0.46-0.06,0.57,0.17,3.74l-0.08-0.05c0.1,0.4-0.09,0.21,0.11,0.99l-0.1-0.15
		c-0.04,0.74,0.29,1.11,0.4,1.72c-0.22,0.02-0.25,0.6-0.25,0.9l-0.03-0.01c0.36,1.62-0.51,0.07,2.17,8.97l-0.14,0.03
		c0.12,0.51,0.19,0.76,0.42,1.37l-0.14-0.09c0.71,0.96,0.35,0.39,1.19,2.54c-0.03,0-0.1,0.01-0.14-0.08
		c0.09,0.29,0.33,1.12,0.54,0.9c-0.17,0.08,0.25,0.6,0.46,0.88l-0.15,0.01c0.05-0.01,0.29,0.33,0.76,1.07l-0.15-0.09
		c0.21,0.34,0.56,1.14,1.22,2.45c-0.19-0.39-0.35-0.7-0.32-0.68c-0.11,0.06,0.03,0.38-0.26,0.22c0.18,0.24,0.07-0.06,0.72,0.92
		l-0.03-0.02c0.26,0.4,0.07,0.27,0.47,0.97c0.11-0.08,0.21,0.13,0.2-0.12c0.02,0.05-0.05-0.07-0.14-0.17l0.02,0
		c-0.06-0.1-0.14-0.23-0.23-0.37c0.92,0.84,0.12,0.82,1.76,2.8c-0.34-0.27-0.28-0.29,0.58,0.87l-0.09-0.03
		c0.31,1.04,1.35,1.1,1.71,2.25c-0.03-0.08-0.04-0.12-0.03-0.15c0.02,0.35,0.64,1.03,1.53,2c-0.12-0.02-0.27-0.09-0.28,0.01
		c-0.07-0.2-0.09-0.13-0.06-0.32c-0.04,0.29-0.9-0.41-0.36-0.42c-0.26,0.25-0.87-0.68-2.11-0.82c0.3-0.79,0.31-0.18-1.77-1.54
		l0.2-0.09c-1.12-0.39-1.65-1.37-4.81-3.42l0.09,0.01c-0.22-0.23-0.46,0.03-0.62-0.25c0.36,0.03-0.2-0.24-3.44-2.77
		c-0.24,0.2,0.43-0.07-0.96-0.63c0.05-0.04,0.15,0,0.24,0.02c-0.36-0.07,0.05-0.17-0.29-0.23c0.01,0.05-0.07,0.06-0.1,0.08
		c-0.07-0.17-0.35-0.37-0.58-0.49l0.1-0.06c-0.35-0.29-0.79-0.52-1.19-0.82c-0.84-0.59,0.2,0.09-6.21-3.83l0.03,0
		c-0.93,0.08-1.64-0.85-4.22,0.34c0.08,0.12,0.12,0.1-0.22,0.2c0.41,0.16-2.12,2.55,0.69,4.43l-0.23,0
		c0.74,0.51,1.34,1.21,2.08,1.55c-0.06,0-0.09,0.04-0.15-0.01c0.06,0.2,0.5,0.38,0.56,0.3c0.27,0.24-0.18,0.08,1.95,1.3
		c-0.15,0.17,0.33,0.46,0.55,0.47c-0.09,0.02,0.1,0.15-0.02,0.19c0.18,0.12,0.37,0.2,0.41,0.08c0.45,0.58,0.31,0.42,2.45,1.48
		c-0.76,0.08-1.05-0.42,1.86,1.23c-0.01,0.02-0.07,0.01-0.12,0c0.29,0.04-0.05,0,0.67-0.1c-0.29,0.17,0.38,0.31-0.04,0.5
		c0.06,0.02,0.16,0.02,0.18-0.05c0.07,0.07,0.08,0.12-0.05,0.13c0.33,0.13,0.28-0.03,0.55,0.14c-0.03,0.04-0.09,0.03-0.15,0.06
		c0.19,0,0.37,0,0.58,0.17c-0.03,0.02-0.02,0.07-0.09,0.03c0.4,0.22,0.8,0.16,1.19,0.49c-0.23,0.12,0.11,0.15,0.05,0.32l0.26,0.08
		l-0.05-0.13c0.2,0.12,0.45,0.1,0.6,0.25l-0.19-0.02l0.41,0.18l-0.19-0.18c0.74,0.04,0.07-0.18,1.59,0.72
		c-0.21,0.04,0.09,0.41,0.39,0.24c-0.03,0-0.03,0.02-0.09,0.01c0.4,0.17,0.53,0.29,0.63,0.39l-0.12-0.04
		c0.22,0.26,0.06-0.01,1.73,0.76l-0.08-0.06c0.18-0.21,0.28,0.28,0.56,0.27c-0.05,0.27-0.11,0.06,1.06,0.45l-0.09,0.04
		c0.86,0.29,0.65,0.43,2.52,1.1c-0.16,0.01-0.27-0.02,0.37,0.15c0.01-0.12-0.04-0.09-0.1-0.21c0.09-0.04,0.24-0.03,0.35,0.01
		c-0.24-0.02-0.07,0.17,0,0.27c0-0.04,0.04-0.06,0.05-0.1c0.14,0.34,0.07-0.1,0.37,0.11c-0.08,0,0.72,0.04,0.21,0.16
		c0.2,0.07,0.47-0.09,0.6,0.1c-0.09,0-0.21-0.04-0.31-0.04c0.13,0.22,0.17,0.19,0.3,0.27l-0.01-0.01l0.3,0.19l-0.07-0.07
		c0.36-0.24,0.51,0.13,0.92,0.02c-0.18,0.01-0.36,0.37,0.47,0.5c-0.09,0.06,1.37,0.22,2.95,0.75c-0.11,0.12-0.29,0.29,0.81,0.21
		l-0.12-0.08c0.14,0.23-0.53,0.03-0.15-0.03c-0.06-0.31-0.54-0.09-0.79-0.19c0.26-0.12,0.23-0.36-0.55-0.43
		c0.05-0.03,0.01-0.15,0.12-0.09c-0.29-0.21-0.57-0.01-0.65-0.2l0.03,0c-0.47-0.16-1.37-0.53-3.19-1.04
		c0.04-0.05-0.5-0.29-0.74-0.45l0.02-0.03c-0.12,0.15-0.38,0.02-0.59-0.03c0.05-0.1,0.1-0.12,0.22-0.13
		c-0.03,0.02-0.01-0.09-0.45-0.06l0.1-0.11c-0.25-0.09-0.55-0.05-0.83-0.27c-0.07,0.11,0.11,0.43-0.25,0.37
		c0.01-0.07,0.05-0.18,0.15-0.17c-0.03,0-0.12,0.01-0.17-0.04l0.18-0.05c-0.09,0.02-0.44-0.17-0.09-0.1
		c-0.12-0.07-0.26-0.19-0.28-0.28c-0.48,0.16,0.01-0.01-1.16-0.29l0,0c-0.47-0.05,0,0-1.25-0.59c0.12-0.16-0.37-0.18-1.29-0.67
		c0.22,0.01-0.03-0.1,0.22,0.03c-0.22-0.15-0.24-0.25-0.55-0.26c0.14-0.07-0.11-0.23-0.24-0.25l0.12,0.16
		c-0.13-0.05-0.24-0.11-0.35-0.18l0.14-0.12c-0.5-0.35-1.08-0.55-2.26-1.02c0.01,0.44-0.3-0.66-0.42-0.3
		c-0.24-0.23-0.43-0.25-0.69-0.46c0.05,0.11-1.09-0.07-0.57-0.44c-0.32-0.05-0.45-0.34-0.58-0.45l0.16,0.04
		c-0.09-0.02-0.16-0.06-0.23-0.1l0.02,0.15c-0.07-0.07-0.14-0.11-0.11-0.15c-0.21,0.03-0.18,0.39-0.57-0.09
		c0.29,0.24,0.07-0.12,0.31-0.02c-0.06-0.02-0.18-0.08-0.12-0.13c-0.25-0.31-0.24,0.07-0.57-0.13c0.09-0.05,0.01-0.19-0.05-0.21
		c0.11,0.05-0.08,0.21-0.53-0.02l0.11,0.1c-0.39,0.08-0.47-0.45-1.31-1.12c0.01,0.15,0.04,0.13-0.21,0.11l0.48,0.2l-0.37-0.03
		c0.13,0.08,0.32,0.11,0.39,0.18c-0.19,0-0.16-0.02-0.15,0.11c-0.68-0.44,0.05-0.62-1.39-1.17l0.1,0.13
		c-0.12,0.06-0.26-0.15-0.41-0.19c0.07-0.07,0-0.16-0.02-0.25c-0.03,0.07-0.29-0.2-0.44-0.06c-0.26-0.38-0.04-0.3-0.92-0.48
		c0.15-0.03,0.05-0.18,0.3-0.1c-0.1-0.07-0.18-0.15-0.22-0.09c0.19,0.08-0.35-0.34-0.81-0.18c-0.11-0.34-0.28-0.47-1.74-1.45
		c0.03-0.01,0.03-0.04,0.06,0c-0.7-0.39,0.01-0.04-0.93-0.83l0.06,0.02c-0.09-0.21-0.47-0.19-0.7-0.41c0,0,0.03-0.01,0.03-0.04
		c-0.27,0-0.63-0.23-0.89-0.41c0.05-0.04,0.07-0.05,0.1-0.07c-0.1,0.04-0.29,0.06-0.27-0.41l0.18,0.17
		c-0.48-0.83-0.04-0.1-1.42-1.95c0.15,0.14,0.3,0.38,0.45,0.51c-0.05-0.12-0.15-0.4-0.24-0.46c0.02,0.05,0.08,0.1,0.07,0.16
		c-0.37-0.58,0.52-1.05,1.97-2.05c-0.03,0.07-0.18,0.21,0.02,0.27c-0.16-0.09,0.13-0.16,0.08-0.24c0.21,0.06,0.44,0.22,0.52,0.45
		c-0.03-0.11,0.11-0.16,0.26-0.09c-0.07-0.05-0.14-0.11-0.21-0.16c0.43-0.12,0.3,0.28,0.72,0.21c-0.18,0.42-0.28-0.39,2.28,0.9
		l-0.07,0.04c0.31,0.09,0.07,0.14,0.64,0.33l-0.14,0c0.39,0.38,0.8,0.31,1.2,0.54c-0.13,0.18,0.18,0.49,0.33,0.65l-0.02,0.02
		c0.61,0.64,0.29-0.37,2.87,2.06c0-0.04,0.22-0.18,0.44,0.14c-0.07-0.05,0.14,0.66,0.59,0.29c0.02,0.36,0.75,0.44,2.58,2.18
		l-0.14,0.02c0.88,0.21,0.37,0.02,1.82,1.07c-0.02,0.02-0.07,0.06-0.14,0.02c0.18,0.14,0.61,0.63,0.72,0.35
		c-0.11,0.16,0.38,0.3,0.64,0.39l-0.11,0.09c0.03-0.04,0.32,0.07,0.93,0.37l-0.14,0.01c0.25,0.12,0.46,0.37,1.64,1.24
		c-0.26-0.2-0.47-0.36-0.44-0.35c-0.08,0.1,0.12,0.28-0.15,0.3c0.11,0.17,0.56-0.02,1.39,0.89c0.07-0.12,0.2,0,0.14-0.19
		c0.02,0.03-0.05-0.03-0.16-0.07c0,0,0.01,0,0.01,0c-0.07-0.05-0.16-0.11-0.27-0.19c0.13-0.08,0.09,0.28,0.6,0.09
		c0.09,0.59,0.12,0.52,1.44,1.33c-0.33-0.07-0.29-0.12,0.64,0.46l-0.08,0.01c0.45,0.7,1.3,0.37,1.77,1.18
		c-0.03-0.05-0.05-0.08-0.04-0.11c0.08,0.17,1.94,1.31,4.03,3.03l0.01-0.12c0.6,0.6,1.14,1,2.7,1.48c-0.07-0.13-0.02-0.28-0.67-0.52
		c0.37-0.11-0.03-0.16-0.45-0.77l0.21,0.14c-0.59-0.28-0.15-0.33-1.25-1.07c0.42,0.07-0.44-0.51-0.17-0.63
		c-0.34-0.03-0.68-0.58-1.05-0.82c0.03,0.01,0.09,0.02,0.1,0.06c-0.14-0.2-0.13-0.3-0.19-0.45c0.18,0.22-1.26-0.44-0.29-0.44
		c-0.11,0.11-0.45-0.3-0.49-0.14c-0.05-0.25-0.08-0.19-0.03-0.38c-0.09,0.3-0.95-0.85-0.34-0.62c-0.39,0.15-0.82-1.18-2.16-1.95
		c0.47-0.74,0.36-0.04-1.53-2.67l0.24,0.01c-0.82-1.16-0.16,0.44-3.63-6.77l0.08,0.08c-0.11-0.4-0.47-0.31-0.49-0.71l0.05,0.06
		c0.13-0.3-0.1-0.12-2.2-6.99c0.06,0,0.12,0.13,0.19,0.23c-0.26-0.38,0.14-0.12-0.1-0.47c-0.02,0.06-0.1-0.01-0.13-0.01
		c0.04-0.22-0.08-0.65-0.18-0.98l0.12,0.04c-0.09-0.58-0.28-1.19-0.41-1.83c-0.32-1.04-0.13-1.81-0.73-9.2
		c0.15-0.52,0.12-1.79,1.68-2.95l-0.24-0.08c0.69,0.84,1.08,1.9,1.7,2.76c-0.06-0.04-0.11-0.02-0.15-0.11
		c0,0.25,0.37,0.73,0.46,0.69c-0.08,0.03,0.39,0.93,1.45,2.69c-0.22,0.07,0.14,0.7,0.34,0.88c-0.1-0.05,0.03,0.23-0.1,0.18
		c0.13,0.25,0.27,0.47,0.36,0.38c0.16,0.95,0.15,0.66,1.63,3.37c-0.08-0.03-0.15-0.08-0.21-0.12c0.43,1,0.35,0.85,1.26,2.84
		c-0.02,0.01-0.07-0.05-0.11-0.1c0.23,0.29-0.04-0.04,0.65,0.48c-0.35-0.09,0.16,0.62-0.31,0.44c0.05,0.07,0.13,0.15,0.19,0.11
		c0.03,0.12,0,0.17-0.11,0.08c0.22,0.4,0.26,0.21,0.41,0.6c-0.05,0.02-0.1-0.05-0.17-0.07c0.17,0.16,0.34,0.32,0.41,0.66
		c-0.04-0.01-0.06,0.04-0.09-0.05c0.22,0.55,0.59,0.86,0.73,1.51c-0.14-0.06-0.3-0.01,0.02,0.64l0.04-0.16
		c0.1,0.29,0.33,0.5,0.35,0.77l-0.15-0.19l0.22,0.53l-0.04-0.33c0.61,0.74,0.16-0.08,0.85,2.12c-0.28-0.07-0.07,0.49,0.16,0.57
		c-0.02-0.03-0.03-0.01-0.08-0.08c0.19,0.52,0.23,0.75,0.24,0.93l-0.06-0.13c0.16,0.66-0.04-0.05,0.83,2.31l-0.02-0.12
		c0.28,0.01,0.02,0.51,0.24,0.79c-0.24,0.18-0.1-0.05,0.48,1.44l-0.09-0.06c0.44,1.15,0.17,0.98,1,3.46
		c-0.12-0.17-0.17-0.29,0.15,0.5c0.1-0.08,0.04-0.1,0.09-0.26c0.09,0.06,0.19,0.23,0.24,0.39c-0.16-0.27-0.18,0.05-0.2,0.2
		c0.03-0.03,0.08,0,0.11-0.02c0.1,0.73,0.17,0,0.52,1.54c-0.07-0.1-0.11-0.26-0.18-0.36c-0.08,0.3-0.03,0.32,0,0.51l0-0.02
		l0.05,0.46l0.01-0.12c0.43,0.23,0.25,0.65,0.59,1.02c-0.12-0.2-0.52-0.16-0.1,0.86c-0.1-0.12,0.67,1.63,1.22,3.79
		c-0.25,0-0.27-0.02,0.24,1.26c-0.17-0.08,0.72,1.53,0.26,0.38c0.16,0.04,0.35-0.13,0.58-0.7c-0.07,0.23-0.18,0.48-0.25,1.53
		c0.23-0.24-0.02,0,0.86-0.61l-0.13,0.27c0.14-0.17,0.11-0.13,0.26-0.86c0.47,0.23-0.01-0.51,0.35-0.29
		c-0.04-0.15,0.39-0.19,0.62-1.49c0.09,0.39,0.3-0.53,0.51-0.32c-0.1-0.31,0.28-0.75,0.36-1.14c0.01,0.03,0.02,0.08-0.01,0.11
		c0.13-0.18,0.22-0.2,0.33-0.3c-0.13,0.21-0.09-1.24,0.28-0.38c-0.14-0.07,0.1-0.48-0.06-0.47c0.2-0.12,0.14-0.12,0.33-0.13
		c-0.31,0,0.4-1.08,0.42-0.48c-0.28-0.28,0.76-1.12,0.87-2.5c0.85,0.2,0.19,0.31,1.7-2.19l0.09,0.21c0.5-1.13,0.61-1.01,4.14-5.56
		l-0.03,0.1c0.28-0.25,0.04-0.52,0.36-0.69c-0.05,0.38,0.25-0.22,3.67-3.66c-0.25-0.12,0.31,0.07,0.93-0.98
		c0.03,0.06-0.05,0.15-0.09,0.25c0.18-0.38,0.16,0.07,0.33-0.28c-0.06,0.01-0.04-0.08-0.06-0.11c0.2-0.05,0.5-0.33,0.7-0.56
		l0.03,0.12c0.41-0.33,0.78-0.78,1.24-1.15c1.01-0.65-0.16,0.14,6.82-4.7l-0.02,0.03c0.78,0.32,4.87-3.78,2.43,2.87l-0.12-0.2
		c-0.04,0.96-0.57,1.81-0.8,2.71c-0.01-0.07-0.06-0.09-0.02-0.17c-0.19,0.13-0.31,0.65-0.22,0.69c-0.06-0.05-0.47,0.75-1.27,2.38
		c-0.18-0.15-0.48,0.4-0.53,0.64c-0.01-0.1-0.17,0.11-0.2-0.01c-0.15,0.2-0.25,0.41-0.13,0.44c-0.7,0.49-0.41,0.4-2.08,2.57
		c-0.01-0.1,0.01-0.18,0.03-0.25c-0.39,0.39-0.4,0.39-1.98,1.99c-0.01-0.02,0.02-0.08,0.05-0.13c-0.16,0.29,0.01-0.05-0.15,0.74
		c-0.06-0.35-0.47,0.35-0.5-0.14c-0.04,0.07-0.08,0.17-0.02,0.2c-0.09,0.06-0.15,0.06-0.12-0.08c-0.26,0.33-0.07,0.3-0.34,0.56
		c-0.03-0.04,0.01-0.1-0.01-0.17c-0.07,0.2-0.13,0.4-0.38,0.6c-0.01-0.03-0.06-0.04,0.01-0.1c-0.37,0.39-0.46,0.84-0.94,1.2
		c-0.04-0.28-0.19,0.1-0.35-0.01l-0.18,0.27l0.15-0.03c-0.19,0.2-0.27,0.47-0.47,0.6l0.09-0.2l-0.33,0.4l0.25-0.17
		c-0.31,0.81,0.15,0.11-1.33,1.58c0.02-0.24-0.45,0.03-0.39,0.37c0.02-0.03-0.01-0.03,0.03-0.1c-0.33,0.38-0.5,0.5-0.64,0.58
		l0.08-0.11c-0.46,0.4,0.03-0.06-1.48,1.63l0.09-0.06c0.13,0.25-0.41,0.21-0.53,0.51c-0.26-0.14,0-0.12-0.97,0.95l0.01-0.1
		c-0.76,0.76-0.71,0.53-2.51,1.98c0.1-0.15,0.19-0.23-0.38,0.25c0.1,0.07,0.1,0.02,0.26,0.02c-0.03,0.1-0.14,0.24-0.26,0.32
		c0.18-0.21-0.1-0.15-0.23-0.15c0.03,0.02,0.02,0.07,0.05,0.1c-0.61,0.22,0.06,0.18-1.26,0.71c0.08-0.08,0.21-0.14,0.29-0.22
		c-0.28-0.04-0.29,0.01-0.46,0.06l0.02,0l-0.4,0.11l0.11-0.01c-0.12,0.45-0.53,0.32-0.8,0.71c0.2-0.14-0.11,0-0.95,0.05
		c0.27,0.31-0.88,0.12-1.27,1.23c-0.03-0.29-0.82,0.66-0.57,0.14c-0.24-0.01-0.16,0.03-1.07,0.11c0.08-0.17,0.23-0.36-0.98,0.08
		l0.16,0.04c-0.03-0.07,0.05-0.32,0.34-0.07l-0.16,0.04c0.18,0.28,0.64-0.09,0.95-0.11c-0.27,0.24-0.01,0.43,0.8,0.13
		c-0.04,0.05,0.07,0.14-0.08,0.14c0.73-0.26,1.94-1.03,4.21-2.24c0.01,0.06,0.65-0.2,0.99-0.29l0,0.03
		c-0.02-0.19,0.32-0.33,0.5-0.51c0.05,0.1,0.04,0.16-0.05,0.28c0.01-0.04,0.08,0.05,0.38-0.38l0.03,0.15
		c0.02-0.2,0.6-0.02,0.86-1.01l-0.07,0.19c0.04-0.08,0.45-0.35,0.16-0.04c0.65-0.38,0.28-0.16,1.39-1.22l0,0
		c0.12-0.38,1.2-0.79,2.49-2.29c-0.11,0.23,0.11-0.01-0.13,0.22c0.25-0.19,0.36-0.19,0.5-0.53c0.01,0.17,0.28-0.06,0.36-0.19
		l-0.22,0.1c0.1-0.13,0.21-0.23,0.33-0.33l0.06,0.17c0.54-0.45,1.01-1.04,1.92-2.24c-0.49-0.05,0.8-0.27,0.46-0.4
		c0.32-0.22,0.41-0.43,0.72-0.67c-0.12,0.01,0.46-1.15,0.66-0.53c0.16-0.34,0.52-0.42,0.67-0.55l-0.1,0.16
		c0.06-0.1,0.12-0.16,0.19-0.23l-0.16-0.01c0.09-0.06,0.16-0.13,0.19-0.09c0.03-0.26-0.32-0.27,0.3-0.59
		c-0.35,0.27,0.1,0.09-0.09,0.34c0.4-0.26,0.29-0.04,0.53-0.68c0.02,0.1,0.19,0.05,0.23-0.02c-0.09,0.11-0.17-0.16,0.22-0.56
		l-0.15,0.09c0.07-0.43,0.66-0.41,1.68-1.19c-0.16-0.01-0.16,0.02-0.04-0.24l-0.4,0.49l0.17-0.4c-0.14,0.13-0.24,0.32-0.34,0.39
		c0.07-0.2,0.08-0.17-0.05-0.18c0.7-0.64,0.7,0.15,1.7-1.36l-0.16,0.1c-0.03-0.14,0.23-0.28,0.32-0.45c0.06,0.08,0.17,0.02,0.27,0
		c-0.06-0.04,0.29-0.31,0.17-0.49c0.47-0.29,0.33-0.05,0.69-1.04c0,0.17,0.18,0.05,0.06,0.34c0.09-0.12,0.19-0.22,0.13-0.26
		C1390.42,425.83,1390.94,425.27,1390.83,424.71z M1372.71,430.78c-0.02-0.08-0.06-0.11,0.09-0.18
		C1372.78,430.64,1372.76,430.59,1372.71,430.78z M1366.29,429.29c0-0.1,0.03-0.13,0.06-0.13c0.01,0.04-0.03,0.01,0,0.18
		C1366.32,429.32,1366.3,429.31,1366.29,429.29z M1352.94,434.28c0.04-0.01-0.03-0.1-0.08-0.17
		C1352.92,434.14,1353.14,434.24,1352.94,434.28z M1353.29,435.53c-0.02,0.02,0,0-0.07,0.04
		C1353.16,435.53,1353.06,435.38,1353.29,435.53z M1353.3,434.41c0.05,0.02,0,0,0.12,0.07
		C1353.39,434.47,1353.36,434.45,1353.3,434.41z M1359.58,438.71c0.08,0.09,0.16,0.18,0.25,0.27
		C1359.59,438.98,1359.23,438.88,1359.58,438.71z M1354.64,430.25c-0.02-0.03-0.05-0.07-0.1-0.15
		C1354.59,430.16,1354.54,430.1,1354.64,430.25z M1354.22,429.72c0.05,0.02-0.01-0.15-0.02-0.26
		C1354.13,429.42,1354.47,429.81,1354.22,429.72z M1354.04,431.11c0.04,0.03-0.02,0.02,0.14,0.17
		C1354.08,431.26,1354.06,431.28,1354.04,431.11z M1349.52,399.58c-0.05-0.08-0.1-0.15-0.15-0.21
		C1349.43,399.42,1349.48,399.47,1349.52,399.58z M1365.13,441.48c0.04,0.14,0.02,0.03,0.02,0.35
		C1365.08,441.8,1365.02,441.71,1365.13,441.48z M1367.48,428.21c-0.01,0.07-0.01,0.33-0.13,0.16
		C1367.37,428.41,1367.42,428.3,1367.48,428.21z M1367.37,428.8c0,0.06,0,0-0.02,0.15C1367.36,428.91,1367.37,428.87,1367.37,428.8z
		 M1366.02,441.82c0.12-0.15,0.24-0.02-0.08-0.46c0.52,0.02-0.22-0.43,0.2-0.38c-0.06-0.17-0.02-0.44-0.13-0.53
		c0.14-0.16,0-0.56,0.22-0.53c-0.05-0.05-0.15-0.3-0.15-0.45c0.24,0.29,0.05-0.56,0.33-0.47c-0.22-0.22-0.06-0.74-0.15-1.11
		c0.02,0.02,0.05,0.06,0.04,0.1c0.04-0.2,0.12-0.25,0.17-0.38c-0.03,0.23-0.6-1.03,0.09-0.43c-0.16,0-0.12-0.45-0.26-0.37
		c0.13-0.18,0.07-0.16,0.24-0.24c-0.28,0.12-0.1-1.07,0.18-0.57c-0.35-0.13,0.16-1.14-0.28-2.45c0.86-0.15,0.3,0.2,0.58-2.52
		l0.17,0.14c-0.12-1.31,0.49-2.3,1.17-6.37l0.02,0.09c0.14-0.32-0.2-0.45,0.01-0.73c0.12,0.16,0.16-0.02,1.73-5.86
		c0.05,0.03,0.04,0.15,0.05,0.25c-0.04-0.4,0.18-0.02,0.14-0.38c-0.05,0.03-0.08-0.05-0.11-0.07c0.14-0.13,0.25-0.5,0.3-0.78
		l0.09,0.08c0.17-0.46,0.27-1,0.44-1.52c0.46-0.95-0.04,0.22,2.67-7.34l0,0.03c0.52-0.67,2.44-4.24,3.5,1.39l-0.19-0.12
		c0.36,0.8,0.21,1.72,0.34,2.57c-0.04-0.05-0.09-0.05-0.08-0.14c-0.12,0.17-0.03,0.66,0.06,0.67c-0.08-0.02-0.15,0.81-0.23,2.5
		c-0.22-0.06-0.29,0.52-0.23,0.75c-0.05-0.08-0.11,0.16-0.18,0.06c-0.04,0.23-0.05,0.45,0.07,0.44c-0.42,0.69-0.24,0.48-0.68,3.04
		c-0.32-0.77,0.21-1.2-0.81,2.29c-0.02-0.01-0.02-0.07-0.02-0.13c0,0.31-0.01-0.05,0.22,0.68c-0.22-0.27-0.25,0.48-0.5,0.08
		c0,0.07,0.01,0.17,0.08,0.18c-0.05,0.09-0.1,0.11-0.14-0.02c-0.07,0.38,0.07,0.29-0.05,0.61c-0.05-0.02-0.04-0.09-0.08-0.14
		c0.03,0.2,0.06,0.39-0.09,0.65c-0.02-0.03-0.07-0.01-0.04-0.09c-0.16,0.47-0.06,0.89-0.35,1.36c-0.14-0.23-0.14,0.15-0.32,0.1
		l-0.06,0.29l0.13-0.07c-0.1,0.24-0.06,0.5-0.2,0.68l0-0.21l-0.15,0.46l0.17-0.22c0.01,0.81,0.18,0.05-0.65,1.8
		c-0.06-0.22-0.41,0.15-0.24,0.44c0-0.03-0.02-0.03-0.01-0.1c-0.18,0.44-0.29,0.6-0.39,0.71l0.03-0.12c-0.28,0.49,0-0.06-0.79,1.9
		l0.06-0.08c0.21,0.18-0.29,0.32-0.3,0.62c-0.28-0.05-0.05-0.11-0.52,1.16l-0.03-0.09c-0.37,0.94-0.47,0.7-1.35,2.7
		c0.01-0.18,0.05-0.28-0.19,0.39c0.12,0.01,0.1-0.03,0.23-0.1c0.01,0.08,0.03,0-0.06,0.35c0.01-0.19-0.17-0.05-0.27,0.02
		c0.04,0,0.06,0.05,0.09,0.06c-0.2,0.25,0.07,0.17-0.35,0.64c0-0.01,0-0.01,0-0.03c-0.04,0.14-0.08,0.2-0.41,0.62l0.01-0.01
		l-0.23,0.31l0.08-0.07c-0.42,1.05-0.85,1.42-1.71,3.36c0,0,0,0,0-0.01c-0.07,0.1-0.05,0.07-0.26,0.36l0.05-0.01
		c0,0.01-0.01,0.02-0.01,0.03c0.02-0.04-0.01-0.03,0.09-0.04c-0.08,0.12-0.17,0.36-0.25,0.48c-0.03-0.06,0.04-0.04-0.05,0.06
		c0-0.02,0.01-0.03,0-0.05c-0.09,0.14-0.04,0.02-0.33,0.47C1366.07,441.92,1366.04,441.87,1366.02,441.82z M1373.8,430.82
		c0.02-0.03,0.04-0.07,0.08-0.14C1373.86,430.74,1373.89,430.68,1373.8,430.82z M1374.06,430.27c0,0.05,0.11-0.05,0.2-0.1
		C1374.22,430.23,1374.09,430.48,1374.06,430.27z"
            />
            <path
              className="st10"
              d="M1525.03,428.8l0.45-0.16c-0.49,0.11-1.5,0.46-4.45,1.39c0.43-0.25-0.94,0.23-0.85,0.06
		c-0.32,0.19-1.23,0.31-1.83,0.49c0.33-0.26,0.21-0.09-1.28,0.09c0.03,0.07-0.72,0.2-0.57,0.24c-0.33-0.01-0.28,0.02-0.46-0.04
		c0.26,0.1-1.78,0.32-1,0.06c-1.24,0.38-0.04,0.01-3.64,0.49c0.06,0.18,0.08,0.12-0.45,0.15c-0.23-0.35,0.22-0.21-4.43-0.02
		l0.19-0.09c-2.11-0.09,0.85,0.45-11.11-0.8l0.15,0c-0.59-0.1-0.69,0.06-1.23-0.08l0.11,0c-0.47-0.12-4.28-0.62-7.3-1.3
		c0.1,0.12-0.94-0.2-3.17-0.56c0.03-0.03,0.23,0,0.39,0.01c-1.14-0.16-0.22-0.03-2.16-0.4l0.1-0.03c-3.64-0.68-3.45-0.84-16-2.88
		l0.06,0c-2.78-0.3-0.01-0.08-7.05-0.91l0.05,0.05c-1.2-0.09-0.49,0.13-8.11-0.35l0.28-0.07c-1.56,0.06-3.18,0.01-4.7,0.09
		c0.55-0.08-1.42-0.05-5.37,0.25c0.04-0.12-1-0.03-1.36,0.04c0.12-0.04-0.32-0.02-0.18-0.07c-0.41,0.03-0.79,0.07-0.71,0.12
		c-1.4-0.02-1.05-0.03-5.38,0.46c0.15-0.05,0.27-0.08,0.38-0.1c-0.79,0.09-0.92,0.04-4.57,0.45c0-0.01,0.1-0.03,0.19-0.05
		c-0.58,0.11,0.41-0.06-0.96,0.3c0.29-0.18-0.91,0.05-0.44-0.18c-0.12,0.02-0.27,0.06-0.24,0.09c-0.18,0.01-0.24-0.01-0.06-0.06
		c-0.65,0.09-0.41,0.12-1,0.18c0-0.02,0.12-0.05,0.18-0.08c-0.29,0.08-0.59,0.16-1.09,0.18c0.03-0.02-0.03-0.03,0.12-0.05
		c-0.86,0.09-1.45,0.27-2.39,0.32c0.13-0.07,0.22-0.15-0.88-0.01l0.21,0.02c-0.44,0.04-0.83,0.15-1.21,0.16l0.32-0.07l-0.83,0.1
		l0.47-0.01c-0.44,0.09,0.06,0.11-0.53,0.15c0.12-0.02-0.44,0.04-0.68,0.01l0,0c-0.86,0.16-0.34-0.13-2.06,0.23
		c0.21-0.16-0.67-0.04-0.85,0.07c0.06-0.01,0.03-0.02,0.15-0.04c-0.78,0.09-1.1,0.1-1.37,0.1l0.2-0.03
		c-1.01,0.07,0.07-0.01-3.52,0.37l0.18-0.01c-0.5,0.25-0.24-0.23-3.35,0.3l0.12-0.04c-1.82,0.18-1.41,0.06-5.17,0.35
		c0.3-0.05,0.44-0.07-0.75,0.04c0.08,0.05,0.13,0.03,0.33,0.06c-0.12,0.04-0.39,0.08-0.63,0.1c0.43-0.06-0.01-0.09-0.2-0.11
		c0.03,0.02-0.03,0.04-0.01,0.06c-0.51-0.07-0.08,0.12-0.77,0.09c0.06-0.07,0.2-0.1-1.56,0.06c0.17-0.02,0.39-0.04,0.57-0.06
		c-0.39-0.07-0.43-0.04-0.71-0.05l0.03,0l-0.66-0.01l0.17,0.02c-0.48,0.19-0.99,0.06-1.64,0.2c0.63-0.13,0.1-0.07,0.15-0.14
		c-0.17-0.02-0.42-0.03-0.35-0.05c-0.24,0.02-0.17,0.11-1.21,0.02c1.13,0.1-1.96,0.16-5.46,0.14c0.25-0.09,0.39-0.16-1.58-0.05
		c0.27,0.02,0.12,0,0.81,0.03l-0.26,0.01c0.26,0.15,1.03,0.01,1.54,0.04c-0.16,0.09-0.11,0.15,1.24,0.14
		c-0.07,0.02,0.06,0.07-0.16,0.06c0.65,0.06,1.04-0.08,1.29,0l-0.05,0.01c1.9-0.04,0.99-0.11,5.25-0.09
		c-0.02,0.1-0.12-0.05,1.13-0.06c-0.03,0.04,1.09,0.01,1.62,0l-0.02,0.02c0.1-0.1,0.65-0.11,1.05-0.15
		c-0.01,0.06-0.08,0.08-0.3,0.12c0.05-0.02,0.02,0.04,0.83-0.1c-0.28,0.19,0.21,0.05,1.93-0.21l-0.27,0.08
		c0.26,0.01,0.56-0.12,0.24,0.01c1.3-0.05,0.57-0.02,2.93-0.25l0,0c1.19-0.22,1.27,0.02,5.37-0.43c-0.38,0.08,0.14,0.03-0.39,0.07
		c0.5-0.03,0.62,0.01,1.15-0.11c-0.18,0.08,0.38,0.04,0.62,0l-0.35-0.01c0.27-0.04,0.5-0.05,0.74-0.07l-0.12,0.1
		c1.03-0.06,2.28-0.21,4.69-0.54c-0.62-0.13,1.32,0.06,0.97-0.07c0.49,0.02,0.83-0.12,4.32-0.3l-0.3,0.05
		c0.18-0.03,0.32-0.05,0.47-0.06l-0.18-0.04c0.18-0.01,0.32-0.02,0.32,0c0.4-0.2,0.03-0.17,1-0.21c-0.99,0.08-0.42,0.34,0.88-0.06
		c-0.09,0.05,0.18,0.07,0.3,0.05c-0.23,0.01,0.11-0.13,0.87-0.21l-0.28,0.01c0.71-0.16,2.04-0.07,5.84-0.46l-0.29,0
		c0.83-0.16,0.87,0.04,3.84-0.36c-0.21,0.07,0.11,0.08-0.36,0.15c0.24-0.01,0.45-0.02,0.45-0.05c-0.4,0,0.88-0.22,5.73-0.34
		c-0.03,0.02,0,0.02-0.09,0.02c1.57-0.11,0.03,0,2.36-0.01l-0.12,0.01c0.58,0.03,0.17,0.02,3.36-0.18
		c-0.01,0.02-0.02,0.03-0.03,0.04c0.1-0.05,0.36-0.1,0.93,0.08l-0.5-0.01c0.26,0.1,0.65,0.02,0.91,0.04l-0.03,0.04
		c0.77,0.01,0.78-0.09,1.61-0.06l-0.08-0.03c1.15-0.07,0.48,0.23,2.55,0.06c-0.31,0.04-0.93,0.06-1.32,0.1
		c0.3,0.01,1,0.02,1.16-0.01c-0.12,0-0.27,0.01-0.39-0.01c1.63-0.08,0.51,0.07,6.64,0.63c-0.41-0.2,0.35,0.04,0.3-0.12
		c0.74,0.11,0.22-0.03,1.65,0.47c-0.08-0.07,0.22-0.05,0.57,0.02l-0.48-0.14c0.87,0.04,0.72,0.23,1.6,0.27l-0.28,0.01
		c0.88,0.16,0.85,0.05,5.24,0.73l-0.09,0.02c0.34,0.03,0.14,0.04,1.39,0.21l-0.23,0c1.02,0.23,1.59,0.19,2.46,0.32
		c-0.02,0.11,0.78,0.3,1.18,0.39l-0.02,0.01c1.99,0.29,5.59,1.1,12.9,2.38l-0.03,0.07c0.73,0.15,1.09,0.22,2,0.36l-0.19,0.02
		c1.75,0.12,0.83,0.05,3.95,0.52c-0.02,0.01-0.05,0.04-0.19,0.02c0.42,0.06,1.62,0.31,1.49,0.14c0,0.09,0.92,0.13,1.41,0.15
		l-0.08,0.06c0.05-0.02,0.63,0.01,1.84,0.08l-0.21,0.02c0.7-0.03,0.21,0.08,3.89,0.35c-0.62-0.04-1.12-0.09-1.07-0.09
		c-0.01,0.06,0.49,0.12,0.09,0.17c0.41,0.02-0.01-0.05,1.65,0.01l-0.05,0c0.67,0.02,0.37,0.07,1.54,0.12
		c-0.02-0.07,0.31-0.05,0-0.12c0.07,0.01-0.12,0-0.32,0.01l0.02-0.01c-0.16-0.01-0.38-0.01-0.62-0.02c1.73-0.11,1.24,0.22,4.73,0.08
		c-0.13,0.02-0.27,0.02-0.34,0.03c0.03,0.03,1.1-0.19,1.83-0.07l-0.1,0.03c1.49,0.09,2.39-0.33,4.03-0.29c-0.11,0-0.17,0-0.2-0.02
		c2.23-0.31-0.63,0.53,9.42-1.67l-0.14-0.01c0.34-0.09,1.06-0.35,1.02-0.28c0.37-0.23,3.04-0.74,2.36-0.89
		C1526.42,428.7,1526.43,428.3,1525.03,428.8z M1432.37,425.63l0.62-0.14c-0.3,0.03-0.62,0.09-0.8,0.1
		c0.29-0.08,0.27-0.06,0.12-0.09c0.5-0.03,0.75-0.01,1,0.01L1432.37,425.63z M1501.94,431.43c0.05-0.01-0.15-0.04-0.31-0.07
		C1501.76,431.37,1502.1,431.36,1501.94,431.43z M1503.86,431.94c-0.16,0.05-0.08,0.04-0.31,0
		C1503.62,431.94,1503.54,431.96,1503.86,431.94z M1502.65,431.42L1502.65,431.42c0.11,0,0.2,0.01,0.26,0.01
		C1502.85,431.43,1502.78,431.43,1502.65,431.42z"
            />
          </g>
          <g id="logo">
            <g>
              <g>
                <g>
                  <g>
                    <path
                      className="st13"
                      d="M923.71,130.19l3.3,9.09h-1.25l-1.17-3.21h-2.41l-1.16,3.21h-1.25l3.3-9.09H923.71z M924.17,134.88
						l-0.78-2.13l-0.77,2.13H924.17z"
                    />
                    <path
                      className="st13"
                      d="M920.39,143.52h1.07l3.75,6.48v-6.48h1.18v9.09h-1.03l-3.8-6.56v6.56h-1.18V143.52z M923.53,141.55
						c0.23,0.22,0.4,0.33,0.5,0.33v0.01c0.11,0,0.17-0.07,0.17-0.22c0-0.1-0.02-0.23-0.07-0.39h1.06c-0.14,0.83-0.42,1.31-0.83,1.43
						c-0.11,0.03-0.21,0.05-0.32,0.05c-0.28,0-0.53-0.11-0.76-0.32c-0.23-0.22-0.39-0.32-0.5-0.32h-0.02
						c-0.11,0.01-0.17,0.08-0.17,0.22c0,0.09,0.03,0.22,0.08,0.37h-1.06c0.15-0.83,0.43-1.31,0.85-1.43
						c0.11-0.03,0.21-0.05,0.32-0.05C923.04,141.22,923.3,141.33,923.53,141.55z"
                    />
                    <path
                      className="st13"
                      d="M926.68,155.9c0.91,0.91,1.36,2,1.36,3.29c0,1.28-0.45,2.38-1.36,3.29c-0.91,0.91-2,1.36-3.29,1.36
						c-1.28,0-2.38-0.45-3.29-1.36c-0.91-0.91-1.36-2-1.36-3.29c0-1.28,0.45-2.38,1.36-3.29c0.91-0.91,2-1.36,3.29-1.36
						C924.67,154.54,925.77,155,926.68,155.9z M923.39,155.73c-0.96,0-1.77,0.34-2.45,1.01c-0.68,0.68-1.01,1.49-1.01,2.45
						c0,0.96,0.34,1.78,1.02,2.45c0.68,0.68,1.49,1.02,2.45,1.02c0.96,0,1.77-0.34,2.45-1.02c0.68-0.68,1.01-1.5,1.01-2.45
						c0-0.96-0.34-1.77-1.01-2.45C925.16,156.07,924.35,155.73,923.39,155.73z"
                    />
                    <path
                      className="st13"
                      d="M923.43,170.23c-0.64-0.22-1.16-0.52-1.56-0.92c-0.4-0.4-0.6-0.89-0.6-1.48c0-0.58,0.21-1.08,0.62-1.49
						c0.41-0.42,0.92-0.62,1.51-0.62c0.58,0,1.08,0.21,1.5,0.62c0.23,0.23,0.43,0.59,0.59,1.08l-1.07,0.42
						c-0.13-0.35-0.25-0.58-0.35-0.68c-0.19-0.18-0.41-0.27-0.67-0.27c-0.27,0-0.49,0.09-0.68,0.28c-0.19,0.18-0.28,0.41-0.28,0.67
						c0,0.26,0.09,0.49,0.28,0.68c0.21,0.2,0.5,0.39,0.86,0.56c0.71,0.23,1.32,0.59,1.81,1.09c0.56,0.55,0.83,1.22,0.83,2
						c0,0.78-0.28,1.45-0.83,2.01c-0.55,0.55-1.22,0.83-2,0.83c-0.78,0-1.45-0.28-2-0.83c-0.44-0.44-0.72-1.05-0.84-1.83l1.14-0.41
						c0.07,0.67,0.25,1.15,0.53,1.43c0.33,0.33,0.72,0.49,1.18,0.49c0.46,0,0.85-0.16,1.18-0.49c0.33-0.33,0.49-0.72,0.49-1.18
						c0-0.46-0.16-0.85-0.49-1.18C924.26,170.66,923.88,170.41,923.43,170.23z"
                    />
                  </g>
                  <g>
                    <path
                      className="st13"
                      d="M985.82,193.19c-4.33-4.55-9.54-6.83-15.63-6.83h-4.13l3.01-7.94c0,0,10.81-0.06,12.65-0.07
						c2.74-0.02,5.63-0.37,6.41,0.88l-1.93-10.3c-0.78-0.02-0.11-0.01-1.34,0H963l-9.61,25.19l-0.65,1.74h17.46
						c3.62,0,6.7,1.34,9.26,4.03c2.56,2.69,3.83,5.94,3.83,9.74c0,3.76-1.28,6.99-3.83,9.68c-2.56,2.73-5.64,4.1-9.26,4.1
						c-3.58,0-6.65-1.36-9.2-4.1c-2.41-2.54-3.85-7.3-4-10.99l0.23-4.32l-9.09-1.37c-0.25,3.36-0.15,6.25,0.04,8.45
						c0.08,1.52,0.29,2.98,0.62,4.39c0.01,0.05,0.02,0.08,0.02,0.08l0,0c0.95,3.92,2.88,7.41,5.81,10.46
						c4.33,4.55,9.52,6.83,15.57,6.83c6.09,0,11.3-2.27,15.63-6.83c4.33-4.51,6.49-9.97,6.49-16.38S990.15,197.74,985.82,193.19z"
                    />
                    <path
                      className="st13"
                      d="M967.02,185.45H940.2l17.27-21.58l1.25-1.66l0.26-0.33c3.08-3.96,4.46-7.63,4.46-12.61
						c0-6.03-2.14-11.17-6.41-15.44c-4.28-4.27-9.41-6.4-15.41-6.4c-1.89,0-4.25,0.37-6.56,0.95v9.13c1.41-0.43,2.93-0.67,4.51-0.67
						c7.56,0,13.69,5.31,13.69,11.85c0,0.08-0.01,0.16-0.01,0.24c0,0.11,0.02,0.22,0.02,0.34c0,2.6-0.79,4.93-2.38,6.99
						l-32.12,39.59h54.6L967.02,185.45z"
                    />
                    <g>
                      <g>
                        <path
                          className="st14"
                          d="M955.96,290.94c7.95,0,14.72-1.67,19.58-5.64c4.78-3.97,6.79-10.33,6.79-16.81
								c0-7.41-2.24-13.36-6.87-17.85c-4.55-4.49-13.25-6.79-18.19-6.79h-1.31V290.94z M943.96,279.46v-24.85c0-0.73,0-7.93,0-8.56
								c-0.08-4.07-1.16-6.37-2.47-8.66h11.59h3.78c11.57,0,20.14,2.72,26.92,8.14c6.79,5.43,10.03,12.74,10.03,22.03
								c0,8.87-3.08,16.7-9.41,21.92c-6.25,5.22-14.12,7.1-24.92,7.1h-6.4H941.8c0.69-1.46,2.16-3.45,2.16-7.83
								C943.96,288.44,943.96,279.88,943.96,279.46z"
                        />
                        <path
                          className="st14"
                          d="M937.37,320.54c8.19,0,15.17-1.67,20.18-5.64c4.93-3.97,6.99-10.33,6.99-16.81
								c0-7.41-2.31-13.36-7.07-17.85c-4.69-4.49-13.66-6.79-18.74-6.79h-1.35V320.54z M925,309.06v-24.85c0-0.73,0-7.93,0-8.56
								c-0.08-4.07-1.19-6.37-2.54-8.66h11.95h3.89c11.92,0,20.75,2.72,27.74,8.14c6.99,5.43,10.33,12.74,10.33,22.03
								c0,8.87-3.18,16.7-9.7,21.92c-6.44,5.22-14.55,7.1-25.67,7.1h-6.6h-11.63c0.72-1.46,2.23-3.45,2.23-7.83
								C925,318.03,925,309.47,925,309.06z"
                        />
                        <path
                          className="st14"
                          d="M960.18,201.59c-2.48-0.64-5.17-0.92-8.15-0.92c-9.35,0-17.78,3.02-24.02,9.06
								c-6.23,6.04-9.28,13.64-9.28,22.79c0,8.42,2.9,15.37,8.79,20.86c5.88,5.58,13.82,8.33,22.67,8.33c3.61,0,6.81-0.24,9.49-0.92
								c3.26-0.82,6.02-2.28,8.44-3.35v-8.39c0,0-20.31,14.9-33.71-1.93c-3.16-4.26-4.42-9.77-4.42-15.99
								c0-6.68,1.98-12.17,5.67-17.2c3.83-5.31,9.21-7.6,15.3-7.6c3.12,0,6.16,0.73,9,1.92c2.9,1.28,5.31,2.84,7.93,5.77v-10.16
								C966.2,202.97,962.59,202.14,960.18,201.59z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <polygon
                    className="st3"
                    points="1009.3,133.01 998.19,131.02 1004.54,121.9 995.41,128.44 993.82,115.35 992.23,128.44 
					983.11,121.9 989.46,131.02 978.34,133.01 990.74,134.88 983.11,143.79 992.23,137.24 993.82,150.34 995.41,137.24 
					1004.54,143.79 997,134.99 				"
                  />
                </g>
              </g>
              <g>
                <path
                  className="st13"
                  d="M840.52,408.59v-33.76h9.27v33.76H840.52z"
                />
                <path
                  className="st13"
                  d="M869.62,392v16.6h-9.27v-33.76h7.23L881.13,392v-17.17h9.27v33.76h-7.37L869.62,392z"
                />
                <path
                  className="st13"
                  d="M900.96,408.59v-33.76h23.16v8.08h-13.89v5.61h11.32v7.51h-11.32v12.55H900.96z"
                />
                <path
                  className="st13"
                  d="M947.28,408.88c-2.57,0-4.9-0.49-6.99-1.47c-2.09-0.98-3.88-2.27-5.37-3.88c-1.49-1.6-2.65-3.43-3.47-5.49
				c-0.82-2.06-1.24-4.18-1.24-6.37c0-2.22,0.43-4.36,1.28-6.42c0.86-2.06,2.05-3.88,3.59-5.44c1.54-1.57,3.36-2.82,5.47-3.76
				c2.11-0.94,4.41-1.4,6.92-1.4c2.57,0,4.9,0.49,6.99,1.47c2.09,0.98,3.88,2.28,5.37,3.9c1.49,1.62,2.64,3.46,3.45,5.52
				c0.81,2.06,1.21,4.17,1.21,6.33c0,2.22-0.43,4.35-1.28,6.4c-0.86,2.04-2.04,3.85-3.57,5.42c-1.52,1.57-3.34,2.83-5.45,3.78
				S949.78,408.88,947.28,408.88z M939.62,391.76c0,1.14,0.16,2.24,0.48,3.31c0.32,1.06,0.79,2,1.43,2.83c0.63,0.82,1.43,1.49,2.4,2
				c0.97,0.51,2.12,0.76,3.45,0.76s2.49-0.26,3.47-0.78c0.98-0.52,1.78-1.2,2.4-2.04c0.62-0.84,1.08-1.8,1.38-2.88
				c0.3-1.08,0.45-2.17,0.45-3.28c0-1.14-0.16-2.24-0.48-3.31c-0.32-1.06-0.8-2-1.45-2.81c-0.65-0.81-1.46-1.46-2.43-1.95
				c-0.97-0.49-2.1-0.74-3.4-0.74c-1.33,0-2.48,0.25-3.45,0.76c-0.97,0.51-1.77,1.18-2.4,2.02c-0.63,0.84-1.1,1.79-1.4,2.85
				C939.77,389.56,939.62,390.65,939.62,391.76z"
                />
                <path
                  className="st13"
                  d="M973.2,408.59v-33.76h15.5c1.65,0,3.17,0.34,4.57,1.02c1.39,0.68,2.59,1.57,3.59,2.66
				c1,1.09,1.78,2.35,2.35,3.76c0.57,1.41,0.86,2.83,0.86,4.26c0,1.97-0.43,3.8-1.28,5.49c-0.86,1.7-2.04,3.08-3.57,4.16l7.13,12.41
				h-10.46l-5.94-10.37h-3.47v10.37H973.2z M982.47,390.14h5.85c0.57,0,1.1-0.32,1.59-0.95c0.49-0.63,0.74-1.52,0.74-2.66
				c0-1.17-0.29-2.07-0.86-2.69c-0.57-0.62-1.14-0.93-1.71-0.93h-5.61V390.14z"
                />
                <path
                  className="st13"
                  d="M1035.59,408.59v-17.79l-5.66,12.51h-4.95l-5.66-12.51v17.79h-9.27v-33.76h10.13l7.28,15.88l7.32-15.88
				h10.08v33.76H1035.59z"
                />
                <path
                  className="st13"
                  d="M1079.48,400.51v8.08h-24.06v-33.76h23.63v8.08h-14.36v4.76h12.27v7.51h-12.27v5.33H1079.48z"
                />
                <path
                  className="st13"
                  d="M934.36,444.82l-3.35-0.1c-2.27-0.07-3.99-0.1-5.16-0.1c-2.92,0-5.35,0.03-7.27,0.1l-1.44,0.05
				c-0.24,0-0.36-0.14-0.36-0.41c0-0.17,0.12-0.38,0.36-0.62c1.37-1.37,2.42-2.35,3.15-2.94c4.67-3.82,7.01-7.13,7.01-9.95
				c0-1.2-0.31-2.17-0.93-2.91c-0.62-0.74-1.43-1.11-2.42-1.11c-2.51,0-4.5,0.83-5.98,2.48c-0.24,0.24-0.47,0.36-0.7,0.36
				c-0.22,0-0.34-0.15-0.34-0.46s0.09-0.65,0.28-1.03c0.19-0.38,0.52-0.84,0.98-1.39c0.46-0.55,1.01-1.08,1.62-1.6
				c1.48-1.24,3.35-1.86,5.62-1.86c1.58,0,2.87,0.63,3.87,1.88c1,1.25,1.5,2.73,1.5,4.43c0,1.7-0.71,3.41-2.11,5.13
				c-0.69,0.83-1.81,1.99-3.38,3.48c-1.56,1.5-2.49,2.35-2.78,2.55c-0.29,0.21-0.23,0.34,0.18,0.41l0.21,0.05h7.99
				c0.96,0,1.88-0.29,2.76-0.88c0.88-0.58,1.55-1.37,2.04-2.37c0.21-0.34,0.53-0.52,0.98-0.52c0.14,0,0.21,0.09,0.21,0.26
				c0,0.17-0.29,1.08-0.88,2.71c-0.58,1.63-0.88,2.68-0.88,3.15c0,0.46-0.1,0.78-0.31,0.95
				C934.62,444.74,934.47,444.82,934.36,444.82z"
                />
                <path
                  className="st13"
                  d="M940.76,434.17c0-3.04,1.07-5.61,3.22-7.71c2.15-2.1,4.74-3.15,7.76-3.15c3.02,0,5.62,1.06,7.79,3.17
				c2.17,2.11,3.25,4.69,3.25,7.73c0,3.04-1.07,5.66-3.22,7.86c-2.15,2.2-4.74,3.3-7.79,3.3c-3.04,0-5.64-1.11-7.79-3.33
				S940.76,437.22,940.76,434.17z M958.75,434.74c0-2.7-0.6-4.92-1.8-6.68c-1.2-1.75-2.84-2.63-4.92-2.63
				c-2.08,0-3.81,0.82-5.18,2.45c-1.38,1.63-2.06,3.67-2.06,6.11c0,2.44,0.61,4.6,1.83,6.47c1.22,1.87,2.89,2.81,5,2.81
				s3.83-0.75,5.16-2.24C958.09,439.54,958.75,437.44,958.75,434.74z"
                />
                <path
                  className="st13"
                  d="M973.65,444.51l-5.67,0.26c-0.38,0-0.57-0.21-0.57-0.64c0-0.43,0.19-0.64,0.57-0.64
				c1.82-0.14,2.96-0.52,3.4-1.13c0.24-0.34,0.36-0.76,0.36-1.24v-13.66c0-0.89-0.22-1.51-0.67-1.86c-0.34-0.27-0.65-0.43-0.93-0.46
				l-0.52-0.1c-0.62-0.14-1-0.21-1.13-0.21c-0.24,0-0.36-0.19-0.36-0.57c0-0.38,0.12-0.57,0.36-0.57l5.16,0.15l5-0.15
				c0.27,0,0.41,0.17,0.41,0.52c0,0.34-0.14,0.55-0.41,0.62c-0.1,0.03-0.34,0.08-0.7,0.13c-0.36,0.05-0.62,0.1-0.77,0.15
				c-0.15,0.05-0.36,0.14-0.62,0.26c-0.26,0.12-0.44,0.25-0.54,0.39c-0.34,0.45-0.52,1.17-0.54,2.17c-0.02,1-0.03,3.21-0.03,6.65
				c0,3.44,0.01,5.55,0.03,6.34c0.02,0.79,0.21,1.35,0.59,1.68c0.38,0.33,0.73,0.52,1.06,0.59c0.33,0.07,1.01,0.17,2.04,0.31
				c0.41,0.07,0.62,0.3,0.62,0.7c0,0.4-0.21,0.59-0.62,0.59L973.65,444.51z"
                />
                <path
                  className="st13"
                  d="M987.94,426.49c1.92-2.15,4.25-3.22,6.99-3.22c2.73,0,4.98,1,6.73,2.99c1.75,1.99,2.63,4.5,2.63,7.53
				c0,4.57-2.17,8.99-6.5,13.25c-1.51,1.48-3.58,2.98-6.19,4.51c-2.61,1.53-4.76,2.41-6.45,2.66h-0.26c-0.31,0-0.46-0.09-0.46-0.26
				c0-0.31,0.24-0.53,0.72-0.67c1.44-0.45,3.37-1.63,5.78-3.56c2.41-1.93,4.18-3.63,5.31-5.1c2.61-3.47,3.92-7.37,3.92-11.71
				c0-2.17-0.64-4-1.91-5.52c-1.27-1.51-2.76-2.27-4.46-2.27c-1.7,0-2.99,0.59-3.87,1.78c-0.88,1.19-1.31,2.54-1.31,4.07
				c0,1.53,0.51,2.97,1.52,4.31c1.01,1.34,2.19,2.01,3.53,2.01c0.96,0,1.65-0.09,2.06-0.28c0.41-0.19,0.82-0.4,1.21-0.62
				c0.39-0.22,0.67-0.36,0.83-0.41c0.15-0.05,0.27,0,0.36,0.15c0.09,0.15,0.09,0.28,0.03,0.39c-0.34,0.62-0.91,1.22-1.7,1.8
				c-1.55,1.13-3.18,1.7-4.9,1.7c-1.72,0-3.23-0.63-4.54-1.88c-1.31-1.25-1.96-2.79-1.96-4.61
				C985.05,430.99,986.01,428.64,987.94,426.49z"
                />
              </g>
            </g>
          </g>
          <g id="sol">
            <g>
              <path
                className="st3"
                d="M1237.19,140c-9.15,2.04-16.9,9.45-19.35,18.5s0.5,19.36,7.37,25.74c1.41,1.31,2.95,2.45,4.56,3.5
			c5.12,3.36,11.09,5.84,17.21,5.5c3.38-0.19,6.66-1.23,9.79-2.54c2.82-1.18,5.57-2.6,7.89-4.58c6.3-5.36,8.69-14.24,8.17-22.49
			c-0.17-2.78-0.64-5.56-1.65-8.15c-2.7-6.98-9.17-12.07-16.32-14.28c-7.14-2.22-14.88-1.82-22.18-0.2"
              />
            </g>
            <path
              className="st10"
              d="M1211.62,172.89c0.2-0.63,0.08,0.47,0.37,0.31c0.05,0.19,0.01-0.01,0.11,0.55c0,0,0-0.01,0-0.02
		c0.07,0.53,0,1-0.08,1.83c0.08-0.14,0.22,0.24,0.3,0.72l-0.03-0.69c0.43,1.09,0.06,1.04,0.59,2.11l-0.22-0.32
		c0.52,1.13-0.19,0.4,3.35,6.33l-0.12-0.05c0.32,0.34,0.04,0.21,1.16,1.51l-0.25-0.2c0.47,0.56,0.18,0.55,2.31,2.42
		c-0.16,0.13,0.51,1.04,0.91,1.43l-0.04,0.01c4.28,3.21,5.08,5.04,15.64,7.82l-0.04,0.14c0.99,0.17,1.48,0.32,2.72,0.39l-0.26,0.09
		c2.35-0.26,1.09,0.02,5.38-0.2c-0.02,0.03-0.05,0.09-0.24,0.11c0.58,0.03,2.23-0.12,1.98-0.37c0.04,0.18,1.25-0.16,1.87-0.37
		l-0.07,0.16c0.05-0.07,0.78-0.34,2.33-0.87l-0.24,0.15c0.73-0.27,2.18-0.58,4.9-1.86c-0.75,0.36-1.36,0.64-1.31,0.61
		c0.05,0.12,0.67-0.15,0.26,0.24c0.49-0.28-0.06-0.08,1.91-1.15l-0.05,0.04c0.79-0.44,0.48-0.17,1.83-0.97
		c-0.1-0.11,0.29-0.31-0.12-0.2c0.09-0.04-0.14,0.09-0.35,0.25l0.01-0.02c-0.19,0.12-0.43,0.27-0.71,0.44
		c1.76-1.56,1.63-0.47,5.11-3.73c-0.11,0.13-0.26,0.26-0.3,0.35c0.06,0.01,0.89-1.25,1.75-1.72l-0.05,0.14
		c0.98-0.92-0.11,0.25,3.37-4.23c-0.11,0.11-0.16,0.16-0.21,0.17c0.46-0.11,3.91-6.55,4.88-11.71l-0.13,0.15
		c0.09-0.46,0.17-1.48,0.27-1.39c-0.13-0.55,0.83-4.11,0.07-3.35c0.11-0.6-0.61-0.75-0.71,1.18l0.02-0.62
		c-0.28,0.96,0.11,0.19-0.76,6.05c-0.1-0.67-0.33,1.23-0.56,0.99c0.14,0.52-0.45,1.59-0.59,2.4c-0.15-0.6,0.03-0.29-0.87,1.43
		c0.16,0.04-0.28,0.94-0.08,0.83c-0.29,0.33-0.21,0.31-0.45,0.43c0.41-0.15-0.98,2.14-0.75,1.07c-0.54,1.76,0.09,0.02-2.46,4.11
		c0.27,0.14,0.31,0.07-0.21,0.61c0.78-1.28-0.71,0.23-0.3,0.01c-1.1,0.05-1.39,1.91-4.07,3.82l0.08-0.3
		c-2.79,1.19,2.37,1.23-13.16,5.78l0.19-0.07c-0.77,0.06-0.83,0.43-1.56,0.4l0.13-0.05c-1.16-0.28-1.99,0.8-9.6-0.76
		c0,0.07-0.48-0.03-1.31-0.32c0.19,0.46,0.56,0.06-2.71-0.85c0.06-0.05,0.31,0.02,0.51,0.06c-1.43-0.42-0.29,0-2.64-1.08l0.16-0.04
		c-1.04-0.47-2.15-1.1-3.25-1.77c1.29,0.56-7.65-4.72-10.88-13.19l0.04,0.06c-0.75-1.85-1.86-4.64-1.87-8.94l-0.09,0.08
		c-0.1-0.31,0.03-0.38,0.04-0.61c-0.74,0.17,0.33-5.09,1.06-9.78l0.07,0.39c0.47-1.38-0.53,0.47,2.19-5.66
		c-0.03,0.12,0.01,0.14-0.07,0.32c0.45-0.62,1.38-2.56,4.09-6.02c0.14,0.2,0.98-0.85,1.29-1.21c-0.07,0.15,0.35-0.22,0.28-0.03
		c0.39-0.36,0.72-0.72,0.58-0.74c0.99-0.43,1.81-1.72,5.71-4.08c-0.21,0.21-0.37,0.35-0.47,0.44c1-0.47-0.01-0.31,5.58-2.44
		c0,0.02-0.12,0.08-0.23,0.12c0.69-0.3-0.51,0.17,1.13-0.74c-0.32,0.39,1.18-0.23,0.64,0.29c0.15-0.06,0.33-0.15,0.28-0.21
		c0.23-0.04,0.31-0.02,0.1,0.12c0.81-0.27,0.5-0.27,1.27-0.4c0.01,0.05-0.15,0.09-0.21,0.16c0.37-0.15,0.74-0.31,1.4-0.37
		c-0.04,0.04,0.05,0.06-0.15,0.09c1.1-0.23,1.89-0.43,3.13-0.42c-0.34,0.16,0.19,0.23,1.15,0.18l-0.26-0.1
		c0.58,0.03,1.1-0.08,1.6,0.01l-0.44,0.05l1.09,0.02l-0.61-0.1c0.6-0.07-0.05-0.24,0.73-0.15c-0.16,0.02,0.59,0.01,0.88,0.2l0,0
		c1.22,0.03,0.32,0.38,2.71,0.39c-0.34,0.18,0.79,0.43,1.08,0.31c-0.08-0.01-0.05,0.02-0.2,0c2.6,0.88,0.9,0.19,5.8,2.12l-0.2-0.12
		c0.79,0.01-0.02,0.31,3.85,2.18l-0.17-0.04c0.5,0.28,1.56,0.92,5.1,4.49c-0.32-0.24-0.46-0.38,0.66,0.72
		c0.02-0.15-0.06-0.17-0.17-0.42c0.16,0.07,0.44,0.3,0.65,0.54c-0.45-0.37-0.13,0.11-0.04,0.35c0-0.06,0.08-0.01,0.1-0.05
		c0.24,0.66,0.25-0.06,0.68,0.76c-0.15,0.02-0.29-0.09,1.18,1.69c-0.14-0.17-0.3-0.44-0.47-0.58c0.18,0.49,0.21,0.54,0.39,0.86
		l-0.02-0.03l0.39,0.77l-0.08-0.21c0.61,0.38,0.78,1.05,1.33,1.74c-0.56-0.66-0.17-0.07-0.33-0.06c0.06,0.22,0.16,0.54,0.08,0.47
		c-0.1,0.34,1.87,2,2.93,8.22c-0.06-0.08,0,0-0.25-0.36c0,0,0,0,0,0c-0.12-0.23,0.66,1.85,0.14,0.92c0.12,0.52,0.3,1.34,0.41,1.5
		l0.03-0.35c-0.12,0.02-0.3-0.13-0.15-0.71l0.08,0.33c0.23-0.38-0.25-1.34-0.34-2c0.21,0.33,0.35-0.29-0.11-1.65
		c0.07,0.08,0.12-0.11,0.17,0.18c-0.1-0.87-0.5-1.31-0.43-1.66l0.03,0.07c-0.83-2.08-0.82-2.43-2.81-6.4
		c0.21-0.07,0.02,0.24-0.83-1.26c0.11-0.02-0.75-1.24-1.13-1.85l0.04,0c-0.24,0.01-0.62-0.63-1.02-1c0.1-0.05,0.2-0.02,0.43,0.18
		c-0.18-0.17,0.04-0.07-0.83-0.76c0.56,0.13-0.19-0.39-1.92-1.73l0.35,0.16c-0.23-0.27-0.66-0.38-0.19-0.25
		c-1.19-1.2-0.48-0.57-3.05-2.46l0,0c-1.17-0.43,0.06-0.68-6.25-3.54c0.52,0.1-0.14-0.13,0.53,0.13c-0.61-0.27-0.73-0.4-1.46-0.52
		c0.28-0.03-0.42-0.32-0.74-0.4l0.42,0.25c-0.34-0.11-0.64-0.23-0.93-0.34l0.21-0.1c-2.09-0.38-2.77-0.95-6.24-1.11
		c0.61,0.46-1.64-0.5-1.3-0.13c-0.81-0.11-1.27-0.11-2.12-0.1c0.02,0.1-2.3,0.58-1.84-0.01c-0.76,0.12-1.43,0.11-1.88,0.13l0.4-0.11
		c-0.24,0.06-0.43,0.09-0.64,0.12l0.25,0.08c-0.24,0.02-0.44,0.05-0.44,0c-0.47,0.35,0.02,0.36-1.32,0.43
		c1.32-0.17,0.44-0.76-1.17,0.24c0.1-0.12-0.26-0.11-0.42-0.05c0.31-0.06-0.06,0.26-1.09,0.55l0.37-0.06
		c-0.48,0.47-1.7,0.29-4.18,1.32c0.26,0.01,0.28-0.04-0.18,0.31l1.16-0.65l-0.69,0.53c0.36-0.16,0.72-0.45,0.96-0.5
		c-0.34,0.23-0.3,0.21-0.1,0.21c-1.21,0.52-2.2,0.43-4.17,1.93l0.36-0.16c-0.9,0.78-1.13,0.33-4.07,3.18
		c0.14-0.27-0.23-0.03,0.2-0.52c-1.47,1.8,0.15-0.12-5.41,6.3c0-0.03-0.01-0.03-0.01-0.05c-0.92,1.72-0.06-0.05-1.67,2.59l0.05-0.15
		c-0.3,0.7-0.34,0.1-1.5,4.26c-0.04-0.03-0.05-0.05-0.07-0.07c0.04,0.16,0.01,0.52-0.54,1.14c0.05-0.22,0.14-0.43,0.21-0.64
		c-0.31,0.27-0.27,0.83-0.38,1.17l-0.06-0.07c-0.24,1.01-0.05,1.07-0.34,2.15l0.09-0.1c-0.07,1.57-0.56,0.53-0.45,3.37
		c-0.13-0.42-0.04-1.26-0.06-1.79c-0.06,0.39-0.19,1.34-0.12,1.56c0-0.16-0.01-0.37,0.04-0.52c0.05,0.5,0.13,1.14,0.08,1.93
		C1211.46,165.62,1210.86,166.98,1211.62,172.89z M1256.01,193.05c-0.1,0.18-0.05,0.14-0.34,0.25
		C1255.74,193.22,1255.68,193.32,1256.01,193.05z M1254.44,192.9c-0.06,0.03,0,0-0.3,0.15
		C1254.26,192.99,1254.37,192.94,1254.44,192.9z M1253.36,193.55c0.05-0.06-0.21,0.04-0.41,0.09
		C1253.09,193.56,1253.49,193.31,1253.36,193.55z"
            />
          </g>
          <g id="paloma">
            <g>
              <g>
                <g>
                  <g>
                    <path
                      className="st8"
                      d="M1363.16,249.9c9.78-4.32,22.1,3.86,31.48-1.26c1.45-0.79,2.75-1.88,4.28-2.51
						c2.18-0.91,4.63-0.82,6.94-1.28s4.77-1.78,5.3-4.07c-11.22,0.47-23.16-2.02-31.32-9.74c-0.79-0.75-1.7-1.6-2.78-1.5
						c-1.02,0.09-1.75,0.99-2.31,1.84c-2.16,3.31-3.53,7.09-5.68,10.41c-2.35,3.63-5.62,6.65-9.43,8.71"
                    />
                  </g>
                  <g>
                    <path
                      className="st8"
                      d="M1378.75,209.64c5.86,0.71,8.02,9.22,13.79,10.44c2.8,0.59,5.59-0.74,8.22-1.87c2.33-1,4.72-1.86,7.2-2.38
						c4.1-0.86,8.34-0.76,12.48-1.39s8.41-2.16,10.97-5.48c0.37-0.47,0.71-1.08,0.5-1.64c-0.37-0.99-1.85-0.71-2.79-0.22
						c-3.34,1.77-6.5,4.06-10.19,4.89c-3.55,0.8-7.25,0.17-10.87,0.54c-2.69,0.27-5.29,1.08-7.97,1.42
						c-6.85,0.87-13.67-1.38-20.2-3.6"
                    />
                  </g>
                  <g>
                    <path
                      className="st8"
                      d="M1447.72,206.62c-3.05,0-5.46,2.5-7.33,4.92s-3.86,5.12-6.84,5.79c-1.03,0.23-2.15,0.22-3.03,0.79
						c-0.96,0.63-1.39,1.8-2.15,2.65c-1.8,2.04-4.93,1.93-7.65,2c-0.97,0.03-2.19,0.29-2.4,1.24c1.85,1.71,4.65,1.93,7.14,1.55
						c1.77-0.27,3.5-0.79,5.12-1.54c1.68-0.78,3.24-1.8,4.81-2.8c2.98-1.9,6-3.72,9.07-5.47c1.19-0.68,2.42-1.38,3.21-2.49
						c1.31-1.85,1.1-4.37,0.48-6.55"
                    />
                  </g>
                  <g>
                    <path
                      className="st8"
                      d="M1424.24,228.62c2.81,1.75,0.9,6.77,3.34,9.01c0.56,0.51,1.29,0.8,1.99,1.08c2.72,1.07,5.45,2.14,8.17,3.2
						c3.48,1.36,7.06,2.75,10.79,2.72c2.57-0.02,5.1-0.7,7.67-0.67c2.17,0.03,4.3,0.58,6.44,0.93c3.12,0.51,6.3,0.61,9.46,0.72
						c4.68,0.15,9.35,0.3,14.03,0.45c-6.68-3.25-14.38-3.27-21.76-4.09c-14.15-1.57-27.93-6.32-40.05-13.79"
                    />
                  </g>
                  <g>
                    <path
                      className="st3"
                      d="M1371.17,210.37c-0.5,1.82-0.69,3.73-0.56,5.61c0.12,1.62,0.47,3.26,0.16,4.86
						c-1.93-1.65-4.03-3.11-6.25-4.34c-0.53-0.29-1.07-0.57-1.6-0.85c-0.5-0.26-1.01-0.53-1.51-0.79c-0.12-0.06-0.26-0.14-0.3-0.28
						c-0.07-0.23,0.18-0.43,0.4-0.54c2.77-1.33,6.12-1.24,8.76-2.81"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st10"
                d="M1360.8,214.35c0.05,0,0.07,0.02,0.12,0.02c-0.44,0.04,0.18,0.06-0.14,0.13c0.13,0.05,0,0,0.3,0.11
			c-0.12,0.03-0.14-0.04-0.28-0.03c0.3,0.19-0.04-0.03,0.42,0.39l-0.02-0.05c0.39-0.05,0.36,0.15,0.63,0.12
			c-0.21,0.04-0.1,0.05-0.08,0.28c0.11-0.04,0.1,0.13,0.26,0.07c-0.01,0.02,0.02,0.04,0.03,0.06c0.09-0.05,0.66,0.13,0.92,0.21
			c0,0,0,0,0,0.01c0.17,0.04,0.06,0.04,0.37,0.12c-0.07,0.16-0.07,0.15,0.24,0.25c-0.01-0.02,0.16,0.02,0.24-0.03
			c-0.1-0.12,0.13-0.19-0.43-0.19l0.1-0.12l-0.17,0.07c-0.02-0.05-0.01-0.13,0.06-0.13c-0.02-0.05-0.02,0,0.02-0.11
			c-0.13,0.22,0.08,0.1,0.16-0.11c-0.05,0.21,0.07,0.01,0.03,0.19c0.13-0.09,0.02-0.06,0.27-0.07c-0.03,0.08-0.02,0.04-0.05,0.13
			c0.01-0.01,0.04-0.14,0.07-0.16c-0.02,0.03-0.05,0.23-0.06,0.26c0.03-0.01,0.08-0.14,0.1-0.12c0.04-0.09,0.07-0.1,0,0.08
			c0.02-0.03,0.08-0.11,0.1-0.13c-0.04,0.09-0.08,0.14-0.11,0.19c0.13-0.25-0.04,0.27,0.28,0c-0.02,0.05-0.05,0.12-0.06,0.16
			c0.01-0.05,0.12-0.35,0.02,0.03c-0.01-0.02-0.03-0.02-0.04-0.05c-0.17,0.02,0.23,0.28,0.19,0.01c-0.11,0.13,0.09,0.05,0.09,0.12
			c0.08-0.1-0.06,0.17,0.15-0.14c-0.01,0.1-0.14,0.44,0.12,0.11c-0.03,0.17,0.05,0.09,0.05,0.19c0-0.03,0.04-0.11,0.05-0.13
			c0,0.03,0.06-0.09,0.01,0.04l0,0c-0.04,0.13-0.01,0.1,0,0.11l0.06-0.17c-0.06,0.27,0.12-0.06,0.06,0.17
			c0.05-0.09-0.01,0.05,0.07,0.5l-0.08-0.03c0.01,0.09,0.07,0.04,0.11,0.01c-0.01,0.04-0.01,0.07,0,0.1l0.15-0.11
			c-0.01,0.06-0.08,0.28,0.06-0.01c0.01,0.09-0.02,0.14,0.05,0.1c-0.01,0.04-0.03,0.06-0.06,0.07c0.07-0.03,0.13,0.03,0.19-0.04
			c0.01,0.1,0.16,0.04,0.09,0.18c0.03-0.03,0.13-0.07,0.17-0.06c-0.15,0.13,0.14,0.09,0.04,0.25c0.13-0.12,0.23,0.03,0.36,0
			c-0.01,0.01-0.03,0.03-0.04,0.02c0.05,0.04,0.04,0.09,0.06,0.14c0.07-0.12,0.17-0.1,0.26-0.15c0,0.15-0.12,0.12-0.16,0.24
			c0.05-0.1,0.16-0.03,0.19-0.13c0.01,0.1,0.02,0.06-0.01,0.17c0.09-0.14,0.06,0,0.15-0.07c-0.02,0.09,0.14,0.11-0.05,0.23
			c0.16-0.2,0.22,0.28,0.82,0.03c-0.02-0.02-0.04,0.01-0.06,0.03l0.06-0.01c-0.26,0.04,0.03,0.26-0.05,0.12
			c-0.45,0.26,0.47,0.41,1.12,0.84c-0.27-0.29,0.22,0.45,0.85,0.88l-0.04,0c0.04,0.1,0.23-0.07,0.23,0.07l-0.03,0
			c0.08,0.15-0.1,0.21,0.97,1l-0.06,0.12c0.24-0.05-0.15,0.09,0.38,0.26c-0.01,0.01-0.02,0.01-0.04,0.01
			c0.01,0.03-0.08,0.13,0.08,0.04c0,0.08,0.09,0.16,0.18,0.2l-0.06,0.05c0.11,0.12,0.29,0.2,0.44,0.31
			c-0.03-0.07,0.06-0.16,0.11-0.14l-0.05,0.1c0.06-0.04,0.19-0.17,0,0.04l0.09,0.02c-0.02,0.03-0.04,0.03-0.07,0.06
			c0.07-0.04,0.05,0.18,0.16,0.11c0.05,0.11,0.58,0.3,0.94,0.32c-0.03,0.08,0.09,0.14,0,0.16c0.15,0.06,0.19,0.07,0.39,0.09
			c-0.02-0.07,0.05-0.14,0.03,0.02c0.26-0.03,0.33,0.44,0.55,0.35l-0.01,0c0.19,0.01,0.06,0.08,0.82,0.76
			c0.17-0.51,0.17,0.72,1.2,1.71c-0.03,0-0.06,0.02-0.07,0c-0.04,0.1,0.13,0.18,0.18,0.14c-0.04-0.1,0.13,0.37,0.56,0.58
			c-0.12,0.1,0.03,0.22,0.13,0.22c-0.05,0.02,0,0.08-0.06,0.1c0.05,0.05,0.12,0.09,0.17,0.02c-0.04,0.23,0.3,0.09,0.19,0.31
			c0.22,0.1,0.4,0.2,0.57,0.3c-0.33,0.12-0.47-0.19,0.54,0.53c-0.01,0.01-0.03,0.01-0.05,0.01c0.03,0.02,0.04,0.07,0.09,0.01
			l-0.04-0.01c0.03-0.11,0.24-0.08,0.28-0.12c-0.17,0.12,0.09,0.14-0.14,0.29c0.03,0,0.07-0.01,0.09-0.05
			c0.02,0.03,0.01,0.06-0.05,0.08c0.11,0.04,0.13-0.04,0.21,0.03c-0.02,0.03-0.05,0.02-0.08,0.05c0.13,0.01,0.02,0.07,0.61,0.45
			l0.01-0.07c0.06,0.05,0.17,0.02,0.2,0.09l-0.08,0.01l0.13,0.07l-0.03-0.09c0.17,0.02,0.11-0.19,0.23,0.27
			c0.1-0.06,0.16,0.09,0.27,0.03c-0.03,0.01-0.07,0.07-0.08,0.08l0.08-0.05c0.02,0.11,0.08,0.04,0.23,0.28l-0.05-0.01l0,0.07
			l0.05-0.04c0.01,0.05,0.32,0.21,0.92,0.64c-0.08-0.05-0.01,0.27,0.21,0.16c0.07,0.2-0.11,0.35,0.68,0.45
			c-0.09-0.01-0.09,0.1-0.1,0.15c0.01-0.02,0.04-0.03,0.05-0.05c-0.1,0.19,0.13-0.05,0.11,0.07c-0.01,0,0.26,0.07,0.02,0.1
			c0.16,0.11-0.01-0.01,0.15,0.31c0.21-0.21,0.01-0.02,0.35,0.02c-0.16,0.03-0.07-0.01-0.19,0.22c0.1-0.02,0.01,0.11,0.15,0.08
			c-0.01,0.02-0.01,0.03-0.01,0.05c0.04-0.03,0.19,0.07,0.68,0.52c-0.19,0.09,0.22,0.09-0.08,0.15c0.06,0.03,0.14,0.04,0.2,0.07
			c-0.04,0.05-0.09,0.02-0.14,0.03c0.01-0.02,0.11,0.13,0.24,0.02c-0.26,0.06,0.24-0.07-0.21-0.24l0.14-0.06l-0.15,0
			c0.02-0.04,0.07-0.1,0.12-0.08c0.02-0.07,0.05-0.07-0.1-0.14c0.03-0.01,0.07-0.07,0.08-0.03c-0.03-0.1-0.08-0.3-0.39-0.13
			l0.15-0.23l-0.09,0.06c-0.01-0.01-0.02-0.04,0.01-0.07c-0.1,0-0.03,0.04-0.1,0.06c0.02-0.12-0.18-0.13-0.07-0.26
			c-0.08,0.03,0,0.1-0.09,0.07c0.19-0.12-0.22-0.08,0.03-0.23c-0.23,0.08,0.06-0.26-0.37-0.25c0.06-0.03-0.04,0.01-0.11-0.3
			c-0.1,0.08-0.16-0.01-0.23-0.04c0.05-0.05,0.08-0.07,0.14-0.07c-0.02,0-0.01-0.06-0.16-0.04l0.08-0.06
			c-0.07-0.06-0.21-0.04-0.25-0.16c-0.06,0.06-0.1,0.25-0.23,0.21c0.03-0.04,0.08-0.1,0.12-0.1c0,0.02-0.09-0.17,0.03-0.11
			c-0.03-0.04-0.04-0.11-0.02-0.16c-0.09,0.09,0-0.2-0.4-0.15l0,0c-0.18,0.04-0.19-0.35-0.71-0.64c0.09,0,0.01-0.06,0.08,0.01
			c-0.05-0.08-0.03-0.13-0.17-0.12c0.08-0.05,0.02-0.13-0.03-0.13l0.01,0.09c0.03-0.32-0.78-0.3-0.72-0.68l-0.03,0.03
			c-0.01-0.06-0.1-0.13-0.04-0.16c-0.07-0.01-0.06,0.04-0.04,0.08c-0.05-0.11-0.13-0.1-0.19-0.2c0,0.08-0.47,0.01-0.14-0.21
			c-0.13,0-0.11-0.16-0.15-0.2l0.06,0.01c-0.04,0-0.06-0.02-0.08-0.04l-0.03,0.08c-0.01-0.03-0.03-0.05-0.01-0.07
			c-0.06,0.02-0.07,0.12-0.16,0.14c-0.01-0.06-0.12-0.04-0.07-0.14c0.11,0.14,0.29-0.3-0.09-0.13c0.05-0.04,0.05-0.11,0.03-0.11
			c0.04,0-0.04,0.13-0.23,0.04c0.01,0.06,0.04,0.08-0.11,0.03c0.03-0.04,0.06,0.01,0.06-0.02c-0.23,0.12,0.06-0.6-0.35-0.39
			l0.17,0.07l-0.16,0.02c0.04,0.04,0.12,0.03,0.13,0.06c-0.08,0.02-0.07,0-0.09,0.07c-0.32-0.19,0.28-0.24-0.33-0.52
			c-0.01,0.07,0.02,0.12-0.13-0.01c0.05-0.05,0.05-0.09,0.06-0.13c-0.03,0.04-0.08-0.08-0.19,0c-0.02-0.16,0.08-0.17-0.3-0.18
			c0.08-0.03,0.08-0.1,0.17-0.08c-0.13-0.05,0.03,0.01-0.4-0.07c0.05-0.19,0.02-0.19-0.39-0.64c0.02-0.01,0.03-0.02,0.03,0
			c-0.29-0.08,0.16-0.22-0.36-0.54c0,0,0.02-0.01,0.03-0.02c-0.14,0-0.25-0.09-0.33-0.16c0.18-0.08,0.13-0.03,0.05-0.21
			c0.52,0.08-0.6-0.24-0.24-0.44c-0.06-0.03-0.21-0.1-0.28-0.16c0.12-0.01,0.22,0.06,0.31,0.09c-0.03-0.05-0.1-0.15-0.17-0.14
			c0.04,0.06,0.06,0.08-0.21-0.09c0.24-0.13-0.1-0.27,0.02-0.46c-0.2-0.1-0.23-0.35-0.43-0.5c0.03,0.09-0.36,0.16-0.1-0.08
			c0.01,0.09-0.1,0.05-0.13,0.1c-0.03-0.09-0.04-0.22,0-0.39c-0.03,0.07-0.1,0.07-0.14,0l0.03,0.14c-0.2,0.01-0.03-0.23-0.23-0.22
			l0.08-0.03c-0.1-0.01-0.28-0.16-0.43-0.04c0-0.03,0.01-0.09,0.05-0.09c-0.26-0.26-0.94-0.19-2.1-1.29c0.02,0-0.11,0.16-0.18-0.02
			l0.01,0.01c0.07-0.13-0.03-0.16-0.08-0.21l-0.13,0.13c0.05-0.33-0.43-0.27-0.34-0.52c-0.07,0-0.11-0.04-0.17-0.04
			c0.06-0.13,0.1-0.06-0.17-0.33l0.07-0.03c-0.33-0.03-0.14,0.11-0.6-0.39c0.02-0.01,0.05-0.04,0.07-0.03
			c-0.05-0.06-0.13-0.28-0.26-0.12c0.09-0.09-0.11-0.13-0.21-0.14l0.08-0.06c-0.02,0.02-0.11,0.01-0.36-0.1l0.07-0.02
			c-0.08-0.1-0.22,0.13-0.21-0.21c-0.02,0,0.01,0-0.3-0.28c0.08,0.07,0.14,0.14,0.13,0.14c0.18-0.05-0.12-0.45-0.45-0.37
			c-0.01-0.01,0.02,0.01,0.06,0.02l-0.01,0c0.02,0.02,0.06,0.04,0.09,0.07c-0.06,0.02-0.11,0.02-0.12-0.05
			c-0.06,0.03-0.12,0.07-0.15,0.08c-0.12-0.02-0.26-0.55-1.25-1.04c0.01,0.02,0.01,0.04,0.01,0.06c-0.2-0.12-0.07-0.29-1.04-0.54
			c0.07-0.16-0.01,0.01,0.37-0.62l-0.07,0.07c-0.01-0.01,0.13-0.16,0.01-0.05c0.02-0.04,0.03-0.06,0.04-0.08l-0.1,0.12
			c0.03-0.06,0.07-0.1,0.08-0.14c-0.05,0.06-0.07,0.13-0.11,0.16c0.03-0.07,0.06-0.12,0.02-0.07c0.02-0.04,0.04-0.06,0.05-0.07
			c-0.03,0.04-0.03,0-0.08,0.08c0.04-0.08-0.04,0,0.04-0.13c-0.02,0.03-0.08,0.1-0.09,0.1c0.11-0.15-0.03-0.03,0.08-0.2
			c-0.09,0.14-0.08,0.04-0.14,0.11c0.01-0.01,0.02-0.03,0.02-0.02c0-0.02,0.02-0.06,0.02-0.1c-0.07,0.12-0.1,0.14-0.15,0.21
			c0.05-0.12,0.08-0.14,0.14-0.25c-0.05,0.1-0.08,0.09-0.12,0.17c0.03-0.08,0.01-0.04,0.06-0.14c-0.08,0.15-0.02,0.02-0.08,0.11
			c0.02-0.08,0.07-0.14,0.03-0.11c0.1-0.22-0.03,0.02-0.18,0.08l0.01-0.03c-0.01,0-0.05,0.16-0.09,0.15c0,0,0.02-0.03,0.03-0.05
			l-0.02,0.03c0.05-0.11,0.03-0.03,0.07-0.11c-0.03,0.03-0.13,0.1-0.02-0.05l0,0.05c0-0.1-0.01-0.25-0.26-0.11
			c0.02-0.03,0.03-0.06,0.04-0.1c-0.06,0.04-0.07-0.05-0.08-0.12l-0.05,0.09c0.07-0.2-0.07,0.14,0-0.05
			c-0.12-0.03-0.12,0.2-0.26-0.06c-0.05,0.12-0.18,0.09-0.11-0.07l-0.05,0.1l0.04-0.12c-0.04,0.04-0.07,0.24-0.03-0.04l-0.07,0.07
			c0.02-0.04,0.03-0.05,0.04-0.08c-0.07,0.14,0.03-0.14-0.05,0.06c-0.04-0.09-0.01-0.06-0.17,0.05l0.03-0.11
			c-0.03,0.06,0,0.04-0.04,0.12c-0.01-0.02,0.03-0.13,0.05-0.16l-0.03,0.03c0.01-0.03,0.02-0.05,0.03-0.09
			c-0.03,0.06,0.01-0.12-0.04,0c0.02-0.06-0.08-0.05-0.14,0.13c-0.02-0.02-0.02-0.22-0.09,0.01c0.04-0.2-0.01,0.01-0.16-0.21
			l-0.06,0.08c-0.01-0.02,0.03-0.06,0.04-0.12c-0.08,0.13-0.05-0.04-0.11,0.05c0.06-0.26-0.08-0.01-0.06-0.18
			c0,0-0.05,0.16-0.02,0.01c-0.02,0.13-0.33-0.11-0.23-0.08c-0.09,0.11-0.1-0.04-0.18,0.06c0.03-0.07,0.06-0.13-0.03-0.01
			c0.03-0.06,0.01-0.11,0-0.11c-0.02,0.04-0.03-0.02-0.05,0.07c0-0.05-0.05,0.03-0.04-0.03c-0.04,0.1-0.01-0.06-0.07,0.08
			c0.05-0.15,0.01-0.13-0.03-0.07c0.02-0.04,0.01-0.05,0.03-0.11c-0.01,0-0.04,0.02-0.06,0.1c0.04-0.19-0.1,0.13-0.03-0.11
			c-0.07,0.07-0.12,0.11-0.17,0.13c0.02-0.13,0.07-0.1-0.12-0.09c0-0.01,0.01-0.03,0.02-0.04c-0.01,0-0.01-0.03-0.03,0.04l0.01-0.02
			c-0.02,0.1-0.09,0.2-0.11,0.25c0.08-0.19-0.01-0.06,0.09-0.3c-0.01,0.01-0.02,0.04-0.04,0.09c-0.04-0.07-0.07,0.02-0.12-0.12
			c-0.01,0.02-0.03,0.01-0.04,0.09c0.02-0.04-0.1,0.1-0.03-0.01c-0.05-0.01-0.09,0.3-0.09,0.1c-0.04,0.04-0.06,0.08,0.02-0.12
			c-0.04,0.1-0.04,0.02-0.09,0.12c0.01-0.03,0.03-0.09,0.04-0.1l-0.03,0.08c0.09-0.18-0.14,0.08-0.02-0.18
			c-0.04,0.01-0.11,0.13-0.13,0.09c-0.06,0.21,0,0.01-0.03,0c0.01-0.01,0.04-0.1,0.03-0.08c-0.01-0.04-0.06,0.03-0.11,0.07
			c0.01-0.03,0.04-0.06-0.01-0.1c-0.02,0.04-0.02,0.03-0.04,0.1c0.08-0.3-0.07,0.06-0.06-0.15c-0.07,0.04-0.03,0.1-0.12,0.21
			c0.03-0.05,0.04-0.12,0.05-0.17l-0.03,0.07c0.06-0.2-0.07,0.14-0.03,0.01c-0.02,0.03-0.07,0.07,0-0.06c-0.04,0.02,0,0-0.02-0.13
			c-0.1,0.3,0,0.01-0.12,0.21c0.07-0.15,0.01,0.02,0.09-0.27c-0.04,0.08,0.01-0.07-0.05,0.04c0.05-0.13,0.13-0.11-0.18-0.01
			c-0.01,0-0.08,0.14,0.01-0.06c-0.02,0.03-0.06,0.08-0.07,0.11c0.02-0.06,0.04-0.08,0.05-0.12c-0.16,0.21,0.02,0.04-0.07,0.29
			l0.06-0.11c-0.01,0-0.05,0.18-0.05,0.19l0.03-0.04c-0.01,0.03-0.03,0.1-0.03,0.08c-0.02,0.1,0.02-0.02,0.04,0.02
			c0.04-0.04,0.04-0.1,0.1-0.21l-0.07,0.25l0.04-0.1c0,0,0,0.01-0.01,0.05c0.03-0.07,0.01-0.04,0.04-0.11
			c-0.02,0.1,0.05-0.03,0,0.13c0.03-0.08,0.01-0.06,0.04-0.11c-0.07,0.19,0.07-0.07-0.03,0.18c0.07-0.14,0,0.01,0.05-0.02
			c-0.02,0.09-0.08,0.11,0.06-0.04c-0.02,0.03,0,0.02,0,0.06c-0.01,0-0.01,0-0.02-0.01c0.02,0.02,0.04,0,0.03,0.1
			c0.01-0.04,0.02-0.04,0.03-0.06c0.02,0.02,0.03,0.01-0.01,0.08c0.03-0.02,0.02-0.01,0.04-0.05c0.01,0,0,0-0.03,0.08
			c0.02,0,0.07-0.1,0.07-0.03c0,0,0-0.01,0.01-0.02l0.01,0c0.01-0.01-0.02-0.04,0.03-0.08c-0.01,0.02-0.02,0.06-0.03,0.15
			c0-0.01,0.05-0.07-0.02,0.06c0.02-0.04-0.08,0-0.06-0.11c-0.01,0.01-0.01,0.01-0.02,0.03c-0.04-0.04-0.11-0.01-0.18-0.22
			c0,0.01-0.01,0.02-0.01,0.04c0,0,0,0,0,0c-0.04-0.01,0.06,0.08-0.06,0.1c0.15-0.17-0.32-0.03-0.12-0.26
			c-0.15,0.06-0.04,0-0.22-0.09c0.11-0.11,0.05,0.06-0.29-0.09c0.12,0.05-0.15-0.16,0-0.18c-0.23-0.02-0.06-0.14,0.16-0.09
			c0-0.01-0.01-0.02-0.01-0.03c0.04,0,0.12-0.04,0.25-0.14c0.02,0.04-0.03-0.06-0.08-0.13c0.05-0.01,0.19,0,0.1,0.07
			c0.06-0.01,0.12,0.01,0.17,0.05c-0.07-0.24,0.18,0.13,0.51-0.31l0,0c0.2-0.16,0,0,0.68-0.13c-0.02,0.14,0.2-0.03,0.73-0.07
			c-0.1,0.07,0.04,0.05-0.1,0.06c0.14,0.01,0.17,0.06,0.3-0.05c-0.04,0.09,0.11,0.1,0.17,0.07l-0.1-0.05
			c0.39,0.06,0.06,0.12,0.88,0.02c-0.36-0.34,0.73-0.18,0.64-0.11l0.01-0.04c0.07,0.03,0.25,0,0.22,0.07
			c0.09-0.06,0.03-0.07-0.05-0.08c0.18,0.03,0.26-0.05,0.44-0.04c-0.08-0.02,0.44-0.45,0.39-0.02c0.14-0.11,0.31-0.03,0.4-0.05
			l-0.07,0.06c0.04-0.04,0.09-0.05,0.13-0.06l-0.07-0.06c0.05,0,0.09-0.01,0.1,0.02c0.07-0.09-0.13-0.27,0.22-0.24
			c-0.12-0.07,0.09,0.44,0.25-0.07c0,0.07,0.1,0.09,0.12,0.06c-0.05,0.04-0.09-0.11,0.15-0.28l-0.08,0.02
			c-0.01-0.07,0.06-0.09,0.13-0.15c-0.1,0.03,0.31,0.03,0.76-0.21c-0.09-0.03-0.08-0.01-0.04-0.15l-0.18,0.22l0.07-0.2
			c-0.07,0.05-0.11,0.15-0.16,0.17c0.03-0.11,0.03-0.08-0.04-0.12c0.34-0.29,0.36,0.24,0.83-0.48c-0.09,0-0.16,0.04,0.06-0.16
			c0.04,0.06,0.09,0.04,0.15,0.05c-0.04-0.04,0.14-0.11,0.06-0.23c0.23-0.05,0.18,0.06,0.35-0.39c0,0.09,0.1,0.08,0.03,0.2
			c0.11-0.16-0.03,0.04,0.26-0.49c0.21,0.05,0.25-0.01,1.04-0.43c0,0.02,0.01,0.03-0.01,0.04c0.32-0.21,0.03,0.02,0.6-0.15
			l-0.02,0.02c0.11,0.04,0.2-0.15,0.36-0.18c0,0,0,0.02,0.01,0.03c0.07-0.15,0.27-0.24,0.42-0.3c0,0.04,0.01,0.06,0.01,0.08
			c0.14,0.11,0.46,0.19,1.4-0.29c-0.07,0.11-0.23,0.18-0.33,0.25c0.06-0.01,0.18-0.03,0.24-0.08c-0.02,0.01-0.03,0.01-0.05,0
			c0.62-0.22,0.02-0.09,1.75,0.12c-0.04-0.19,0.06-0.23,0.19-0.18c-0.03,0.05-0.12,0.13-0.02,0.19c-0.08-0.07,0.07-0.09,0.07-0.15
			c0.1,0.06,0.21,0.17,0.27,0.33c-0.02-0.08,0.07-0.11,0.16-0.07l-0.12-0.11c0.24-0.1,0.18,0.2,0.42,0.11l-0.08,0.06
			c0.12-0.03,0.43,0.05,0.57-0.11c0.02,0.03,0.04,0.09-0.02,0.1c0.2,0.11,0.31,0.02,1.2,0.11l-0.07,0.05
			c0.85-0.08,0.47,0.31,2.7,0.62c0.62-0.09-0.04-0.15,2.18,0.55l-0.07,0.05c0.45-0.11,0.16-0.12,1.06,0.16
			c-0.01,0.02-0.03,0.06-0.07,0.05c0.11,0.04,0.39,0.23,0.41,0.02c-0.04,0.13,0.23,0.09,0.37,0.07l-0.05,0.09
			c0.01-0.03,0.16-0.04,0.52-0.02l-0.07,0.04c0.27,0.1,0.07,0.02,0.98,0.36c-0.16-0.06-0.28-0.11-0.27-0.11
			c-0.03,0.08,0.09,0.14-0.05,0.23c0.05,0.08,0.3-0.16,0.81,0.21c0.03-0.09,0.11-0.05,0.06-0.15c0.02,0.01-0.03-0.01-0.09-0.01
			l0.01-0.01c-0.04-0.01-0.1-0.03-0.16-0.05c0.07-0.03,0.13-0.05,0.18,0.03c0.06-0.05,0.11-0.09,0.14-0.12
			c0.08,0.34,0.11,0.3,0.87,0.5c-0.04,0.01-0.07-0.01-0.1,0.01c0.12,0,0.33,0.11,0.4,0c0.11,0.11-0.12,0.08,0.07,0.13l-0.04,0.03
			c0.29,0.34,0.72-0.06,1.02,0.35c-0.02-0.03-0.03-0.04-0.03-0.06c0.16,0.06,0.57,0.28,1.94,0.82c-0.04,0.03-0.07,0.05-0.08,0.06
			c0.21,0.12,0.4-0.1,1.91-0.06c-0.02,0.03,0.04,0.1-0.06,0.09c0.27,0.05,0.4-0.16,0.51-0.06l-0.02,0.01
			c0.35-0.03,1.01,0.01,2.54-0.33c-0.01,0.04,0.44,0.02,0.66,0.04l0,0.02c0.03-0.13,0.25-0.13,0.41-0.18c0,0.08-0.02,0.11-0.11,0.15
			c0.02-0.02,0.04,0.05,0.32-0.12l-0.03,0.1c0.2-0.03,0.39-0.16,0.66-0.13c0-0.09-0.23-0.22,0.03-0.32
			c0.02,0.05,0.04,0.13-0.03,0.16c0.02-0.01,0.08-0.05,0.13-0.03l-0.1,0.09c0.05-0.04,0.36-0.04,0.1,0.03
			c0.48,0.02,0.19,0.01,1.17-0.17l0,0c0.33-0.14,0,0,1.05-0.11c-0.01,0.14,0.33-0.03,1.11-0.09c-0.15,0.08,0.06,0.05-0.15,0.06
			c0.2,0.01,0.26,0.06,0.46-0.06c-0.06,0.1,0.16,0.09,0.26,0.06l-0.15-0.05c0.11-0.03,0.2-0.03,0.3-0.03l-0.04,0.12
			c0.46,0,0.92-0.1,1.87-0.32c-0.2-0.25,0.48,0.25,0.39,0c0.25,0.03,0.38-0.04,0.63-0.03c-0.07-0.05,0.7-0.41,0.55,0.01
			c0.22-0.11,0.43,0,0.56,0l-0.12,0.05c0.07-0.03,0.13-0.03,0.19-0.04l-0.08-0.07c0.07,0.01,0.13,0,0.13,0.03
			c0.12-0.12-0.06-0.29,0.39-0.19c-0.29,0,0.02,0.1-0.18,0.15c0.04-0.01,0.14-0.03,0.13,0.02c0.29,0.07,0.12-0.14,0.41-0.17
			c-0.03,0.07,0.08,0.11,0.13,0.09c-0.09,0.02-0.03-0.15,0.33-0.22l-0.11-0.01c0.19-0.21,0.5,0.04,1.32,0.03
			c-0.08-0.08-0.09-0.05,0.07-0.15l-0.39,0.1l0.24-0.15c-0.12,0.01-0.24,0.08-0.32,0.07c0.11-0.08,0.1-0.06,0.04-0.12
			c0.61-0.06,0.28,0.37,1.39,0.01l-0.12-0.02c0.04-0.09,0.22-0.04,0.34-0.08c0,0.07,0.07,0.09,0.13,0.12
			c-0.01-0.05,0.27-0.03,0.29-0.17c0.34,0.08,0.17,0.14,0.77-0.17c-0.08,0.08,0.06,0.12-0.13,0.19c0.36-0.16-0.16,0,2.46-0.41
			c-0.01,0.02,0,0.03-0.03,0.03c0.62-0.15,0.01,0.03,0.95-0.03l-0.05,0.02c0.23,0.02,0.1,0.01,1.32-0.39c0,0.04,0,0.05-0.01,0.07
			c0.03-0.07,0.12-0.17,0.39,0.03l-0.2,0.03c0.12,0.11,0.26-0.04,0.37-0.03l-0.01,0.06c0.31-0.06,0.29-0.2,0.62-0.24l-0.04-0.04
			c0.44-0.18,0.19,0.14,1.01-0.2c-0.1,0.12-0.35,0.21-0.49,0.3c0.12-0.02,0.4-0.07,0.45-0.15c-0.05,0.02-0.1,0.07-0.15,0.05
			c0.54-0.15,1.09-0.4,3.51-0.44c-0.05-0.07,0.06-0.13,0.2-0.14l-0.22-0.04c0.31-0.2,0.34,0.09,0.66-0.12l-0.09,0.1
			c0.23-0.04,0.3-0.09,2.01-0.85l-0.01,0.06c0.2-0.16,0.12,0.01,0.51-0.27l-0.06,0.09c0.42-0.12,0.55-0.39,0.84-0.59
			c0.07,0.14,0.39,0.02,0.55-0.04l0.01,0.02c0.64-0.27-0.11-0.18,2.31-1.52c-0.04-0.01-0.06-0.19,0.2-0.29
			c-0.06,0.03,0.49,0.02,0.34-0.38c0.22,0,0.51-0.47,1.99-1.71l0,0.11c0.23-0.67,0.07-0.29,0.91-1.37c0.01,0.02,0.04,0.05,0.01,0.1
			c0.1-0.14,0.47-0.49,0.27-0.56c0.11,0.07,0.23-0.3,0.3-0.5l0.06,0.08c-0.02-0.02,0.04-0.25,0.24-0.74l0.01,0.1
			c0.16-0.26-0.12-0.28,0.36-0.56c-0.03,0.09,0-0.33,0.06-0.19c0.26-0.23-0.1-0.05,0.37-0.63c-0.12,0.22-0.21,0.4-0.21,0.38
			c0.08,0.04,0.19-0.13,0.22,0.07c0.05-0.17-0.07-0.02,0.18-0.66l0,0.02c0.1-0.26,0.13-0.11,0.31-0.55
			c-0.09-0.03-0.03-0.15-0.15-0.07c0.02-0.02-0.02,0.04-0.03,0.13l-0.01-0.01c-0.02,0.06-0.06,0.14-0.09,0.24
			c0.03-0.75,0.36-0.3,0.68-1.8c0.01,0.26-0.02,0.24,0.19-0.58l0.02,0.05c0.38-0.52-0.06-1.03,0.36-1.6
			c-0.02,0.04-0.04,0.06-0.06,0.07c0.03-0.38,0.36-1.22,0.45-3.85l-0.07,0.05c0.18-0.54-0.09-0.61-0.6-1.22
			c-0.03,0.14-0.02,0.1,0.14,0.56c-0.36,0.02,0.18,0.28-0.11,0.27c0.06,0.11,0.03,0.29,0.11,0.35c-0.09,0.11,0.02,0.38-0.14,0.36
			c0.04,0.04,0.12,0.2,0.12,0.3c-0.18-0.19-0.02,0.38-0.22,0.32c0.17,0.15,0.03,0.5,0.1,0.75c-0.01-0.02-0.04-0.04-0.03-0.07
			c-0.03,0.14-0.08,0.17-0.12,0.25c0.03-0.16,0.42,0.72-0.06,0.29c0.11,0.01,0.08,0.31,0.17,0.26c-0.1,0.12-0.06,0.1-0.18,0.15
			c0.2-0.07,0.01,0.73-0.16,0.37c0.24,0.11-0.18,0.77,0.04,1.68c-0.7-0.01-0.13-0.06-0.68,1.63l-0.1-0.12
			c-0.15,0.87-0.6,1.49-2.01,3.9l0.01-0.07c-0.17,0.17,0.02,0.34-0.19,0.46l0.01-0.04c-0.12-0.01-0.14,0.2-2.29,2.43
			c0.1,0.2,0.03-0.32-0.61,0.6c-0.02-0.04,0.03-0.1,0.06-0.17c-0.11,0.25-0.11-0.06-0.21,0.17c0.04,0,0.03,0.06,0.04,0.08
			c-0.13,0.03-0.34,0.18-0.47,0.32l-0.02-0.08c-0.28,0.18-0.53,0.46-0.84,0.66c-0.57,0.42,0.02-0.02-2.28,1.51
			c-0.05-0.08-0.3,0.05-0.2-0.07c-0.42,0.14-0.63,0.62-2.27,0.86l0.02-0.01c-1.61,0.29,0.29,0.24-5.87,1.24l0.09-0.13
			c-0.6,0.22-1.26,0.16-1.85,0.35c0.03-0.03,0.03-0.07,0.09-0.07c-0.14-0.07-0.47,0.05-0.47,0.12c-0.27-0.01,0.04-0.11-1.76,0.27
			c0-0.16-0.41-0.08-0.55,0.01c0.04-0.05-0.13-0.03-0.08-0.11c-0.16,0.02-0.31,0.07-0.27,0.15c-0.55-0.1-0.38-0.07-2.15,0.34
			c0.41-0.4,0.82-0.23-1.68,0.15c0-0.01,0.04-0.04,0.07-0.05c-0.19,0.1,0.03-0.02-0.36,0.34c0.1-0.22-0.37-0.01-0.2-0.29
			c-0.05,0.02-0.1,0.06-0.09,0.11c-0.07-0.01-0.1-0.03-0.03-0.09c-0.26,0.07-0.16,0.13-0.39,0.16c0-0.03,0.04-0.05,0.06-0.1
			c-0.11,0.08-0.22,0.16-0.43,0.16c0.01-0.02-0.02-0.05,0.04-0.05c-0.34,0.05-0.56,0.25-0.95,0.23c0.09-0.16-0.13-0.03-0.17-0.16
			l-0.2,0.06l0.09,0.05c-0.18,0.02-0.32,0.13-0.48,0.11l0.13-0.07l-0.33,0.06l0.19,0.03c-0.49,0.28,0.03,0.13-1.31,0.23
			c0.11-0.11-0.23-0.2-0.34,0.02c0.02-0.01,0.01-0.02,0.06-0.04c-0.32,0.05-0.45,0.04-0.56,0.02l0.08-0.02
			c-0.39,0.01,0.04-0.02-1.41,0.22l0.07,0.01c-0.03,0.19-0.29-0.06-0.47,0.05c-0.08-0.18,0.05-0.07-0.87,0.12l0.04-0.05
			c-0.69,0.14-0.58-0.01-2.07,0.22c0.11-0.06,0.18-0.08-0.3,0.04c0.04,0.08,0.06,0.04,0.14,0.1c-0.04,0.05-0.15,0.1-0.24,0.11
			c0.17-0.07-0.02-0.13-0.1-0.17c0.02,0.02-0.01,0.05,0,0.08c-0.22-0.16,0,0.07-0.29,0.06c0.06-0.03-0.5,0.21-0.2-0.03
			c-0.15,0.02-0.29,0.21-0.44,0.13c0.06-0.03,0.16-0.04,0.22-0.08c-0.17-0.09-0.18-0.06-0.3-0.07l0.01,0l-0.27-0.02l0.07,0.02
			c-1.24,0.47-0.08,0.02-3.39,0.49c0.17-0.32-0.36,0.2-0.67-0.34c-0.04,0.02-0.17,0.05-0.24,0.03c0.2-0.11-0.23-0.12-0.12-0.29
			c-0.16,0.11-0.35-0.08-0.54-0.06c0.01-0.01,0.04-0.02,0.05-0.01c-0.08-0.05-0.09-0.11-0.13-0.16c-0.07,0.12-0.22,0.09-0.32,0.13
			c-0.05-0.17,0.13-0.12,0.15-0.25c-0.04,0.1-0.23,0.02-0.23,0.12c-0.05-0.11-0.05-0.07-0.05-0.19c-0.01,0.2-0.51-0.07-0.22-0.19
			c-0.21,0.25-0.29-0.31-1.19-0.06c0.04,0.02,0.05-0.01,0.08-0.03l-0.08,0.01c0.38-0.06-0.21-0.3,0.01-0.13
			c0.33-0.48-0.7-0.23-2.06-0.79c0.26,0.12-0.16-0.27-1.8-0.68l0.05-0.02c-0.14-0.09-0.24,0.15-0.35,0.01l0.03-0.01
			c-0.25-0.18-0.25-0.13-2.17-0.75c-0.08,0.18,0.19-0.17-0.58-0.11c0.02-0.04,0.07-0.04,0.12-0.06c-0.19,0.06,0-0.12-0.18-0.06
			c0.01,0.03-0.03,0.06-0.04,0.08c-0.06-0.08-0.24-0.13-0.37-0.13l0.04-0.07c-0.22-0.07-0.48-0.08-0.73-0.14
			c0.02,0.05-0.11,0.27-0.08,0.05c-0.05,0.06-0.13,0.22-0.02-0.04l-0.13,0.01c0-0.03,0.03-0.05,0.05-0.09
			c-0.59-0.1-0.01-0.1-1.7-0.13c0.01-0.09-0.19-0.12-0.08-0.16c-0.65-0.06-0.55,0.13-1.67-0.24l0.02-0.01
			c-0.75,0.01-0.01,0-1.95-0.22c0,0.06,0.02,0.13-0.12,0.01c-0.08,0.47-0.55-0.36-2.11,0.31l0.05-0.13c-0.4,0.22-0.88,0.2-1.25,0.45
			c0.02-0.03,0-0.07,0.04-0.08c-0.53,0.25-0.05,0.07-1.38,0.8c-0.08-0.15-0.31,0.05-0.35,0.17c0-0.06-0.1,0.01-0.11-0.07
			c-0.1,0.07-0.17,0.16-0.1,0.21c-0.37,0.09-0.27,0.04-1.06,1.03c-0.03-0.49,0.4-0.38-0.9,0.57c-0.01-0.01,0.01-0.04,0.02-0.06
			c-0.07,0.13,0.02-0.02-0.09,0.38c-0.01-0.22-0.24,0.05-0.23-0.24c-0.02,0.02-0.04,0.07-0.02,0.11c-0.05,0-0.07-0.02-0.05-0.08
			c-0.07-0.02-0.17,0.28-0.18,0.1c-0.05,0.09-0.1,0.17-0.23,0.19c0-0.02-0.02-0.04,0.02-0.06c-0.2,0.08-0.32,0.27-0.57,0.27
			c0.04-0.16-0.09-0.03-0.13-0.16l-0.12,0.06l0.06,0.05c-0.11,0.02-0.2,0.13-0.31,0.11l0.08-0.07l-0.21,0.06l0.13,0.03
			c-0.11,0.08,0.04,0.16-0.12,0.15c0.03-0.02,0,0-0.19-0.05c0.01-0.03-0.25,0.24-0.22-0.09c-0.04,0.12-0.27,0.06-0.33,0.19
			c0.06-0.14-0.17-0.19-0.23,0c0.01-0.01,0.01-0.02,0.04-0.04c-0.22,0.04-0.31,0.02-0.38,0l0.06-0.02l-0.08-0.05l-0.02,0.06
			c-0.24-0.1,0.01,0.13-1.71,0.35l0.02-0.05c-0.49,0.2-0.27,0.03-1.5,0.57C1361.12,213.94,1361.45,214.39,1360.8,214.35z
			 M1368.61,218.01c0.01-0.01,0,0,0.04-0.03C1368.67,217.99,1368.69,218.06,1368.61,218.01z M1368.51,218.65
			c-0.02,0.01,0,0.05,0,0.09C1368.52,218.74,1368.39,218.69,1368.51,218.65z M1368.37,218.63c-0.02-0.01,0,0-0.04-0.02
			C1368.34,218.61,1368.35,218.62,1368.37,218.63z M1365.77,216.31c0,0.03,0.02,0.24,0.2,0.07l-0.05,0.09
			c0.1-0.2,0.07,0.02,0.13-0.05c0,0.01,0.01,0.05,0.01,0.07l0.07-0.07C1366.24,216.61,1365.5,216.49,1365.77,216.31z M1365.6,215.75
			c0,0.01,0.01,0.01,0.01,0.01c-0.01,0.03-0.03,0.06-0.03,0.06C1365.59,215.8,1365.59,215.78,1365.6,215.75z M1365.56,216.34
			c0.03-0.04,0.04,0,0.1-0.12c-0.01,0.06-0.06,0.1-0.03,0.09c-0.02,0.05-0.02,0.06-0.03,0.08c-0.03-0.02-0.08-0.03-0.11-0.04
			C1365.56,216.29,1365.66,216.17,1365.56,216.34z M1365.47,216.26c0.03-0.06,0.06-0.11,0.07-0.14c-0.03,0.1-0.05,0.17-0.07,0.23
			c-0.01-0.01-0.02-0.02,0-0.07c0,0.01,0,0-0.02,0.02l0-0.01l-0.02,0C1365.46,216.24,1365.48,216.21,1365.47,216.26z
			 M1365.38,216.23c-0.01-0.01-0.01-0.01-0.02-0.02C1365.38,216.21,1365.38,216.21,1365.38,216.23z M1365.33,216.19
			c-0.01,0-0.01,0-0.02-0.01C1365.33,216.15,1365.34,216.12,1365.33,216.19z M1365.3,216.13c0-0.01,0.01,0.04-0.03,0.03
			C1365.28,216.15,1365.29,216.14,1365.3,216.13z M1365.09,216.08c0-0.01,0.01,0.02-0.03,0.07
			C1365.07,216.13,1365.07,216.12,1365.09,216.08z M1364.21,216.01l-0.01,0.01C1364.16,215.94,1364.18,215.96,1364.21,216.01z
			 M1363.36,215.48c-0.01,0.03-0.02,0.06-0.02,0.08c-0.02-0.01-0.03,0.01-0.06-0.01C1363.32,215.55,1363.31,215.56,1363.36,215.48z
			 M1363.26,215.36c0.08-0.03-0.01,0.1-0.01,0.16c0-0.01-0.01-0.01,0-0.02l-0.01,0.01c-0.01-0.02-0.03,0.01,0.02-0.07
			C1363.23,215.45,1363.22,215.49,1363.26,215.36z M1363.19,215.5l-0.07,0.01C1363.11,215.5,1363.21,215.4,1363.19,215.5z
			 M1363.19,215.45c-0.02-0.03-0.05-0.01,0.01-0.08c-0.01,0.05-0.02,0.03-0.02,0.05C1363.2,215.36,1363.19,215.41,1363.19,215.45z
			 M1363.02,215.3c-0.01,0,0.01,0.04-0.03,0.06C1363,215.33,1363.01,215.31,1363.02,215.3z M1362.96,215.16
			c0.01-0.01-0.01,0.06-0.05,0.16C1362.88,215.28,1362.9,215.33,1362.96,215.16z M1362.83,215.32c0,0-0.01,0-0.01,0
			C1362.82,215.31,1362.85,215.19,1362.83,215.32z M1362.56,215.1c0.03-0.09,0.06,0.01,0.07,0.07c0.03-0.03,0.04-0.01,0.08-0.08
			C1362.63,215.17,1362.68,215.84,1362.56,215.1L1362.56,215.1z M1362.36,215.05c0.01-0.02,0.02-0.02,0.04-0.03
			C1362.39,215.02,1362.41,215.06,1362.36,215.05z M1362.47,215.03c0.02-0.07,0.04-0.14,0.07-0.17c0,0.03-0.03,0.05-0.06,0.18
			C1362.47,215.03,1362.47,215.03,1362.47,215.03z M1362.51,215.14c0-0.01,0.01,0.02-0.02,0.04
			C1362.49,215.18,1362.49,215.17,1362.51,215.14z M1386.54,212.62c-0.01,0.02,0,0-0.03,0.04
			C1386.47,212.65,1386.41,212.58,1386.54,212.62z M1432.94,200.67c0.01,0.01,0,0,0.03,0.05
			C1432.96,200.77,1432.87,200.86,1432.94,200.67z M1432.19,200.75c-0.01,0.02-0.02,0.05-0.04,0.09
			C1432.17,200.8,1432.15,200.85,1432.19,200.75z M1432.09,201.13c-0.01-0.03-0.07,0.05-0.13,0.09
			C1431.97,201.26,1432.03,200.96,1432.09,201.13z M1392.17,214.22L1392.17,214.22L1392.17,214.22L1392.17,214.22z M1392.23,214.08
			c0.02-0.04-0.09,0.28-0.17,0.05C1392.12,214.11,1392.18,214.09,1392.23,214.08z M1386.52,211.93c-0.01,0-0.03-0.01-0.07-0.02
			C1386.48,211.91,1386.45,211.91,1386.52,211.93z M1386.25,211.92c0.02-0.02-0.03-0.06-0.06-0.1
			C1386.16,211.83,1386.37,211.84,1386.25,211.92z"
              />
              <path
                className="st10"
                d="M1376.55,214l-0.11,0.06c-0.08-0.15,0.2-0.09,0.11-0.25l0.06,0.05c-0.03-0.08,0.01-0.29-0.18-0.34
			c0.2-0.02,0.1,0.08-0.52-0.85c0.22-0.03-0.17-0.47-0.74-0.53c0.01,0,0.01-0.01,0.02-0.02c-0.16,0.18,0.01,0.1-0.58,0.07
			c0.01-0.01,0.11,0.15-0.1,0.15l0.02-0.01c-0.12-0.09-0.2-0.01-0.26,0.03l0.14,0.13c-0.4-0.05-0.25,0.35-0.53,0.35
			c0.02,0.05,0,0.1,0.03,0.14c-0.15,0.02-0.12-0.05-0.22,0.3l-0.07-0.03c0.23,0.06-0.17,0.38,0.28,0.76
			c-0.2,0.05,0.24,0.04,0.08,0.2c0.11,0.02,0.03-0.03,0.16,0.26c-0.02,0.01-0.03,0.02-0.03,0c0.27,0.03-0.18,0.36,0.4,0.44
			c0,0-0.01,0.02-0.02,0.03c0.11-0.07,0.23-0.07,0.32-0.09c-0.06,0.18-0.07,0.12,0.11,0.17c-0.31,0,0.37,0.19,0.58-0.27
			c0.01,0.11-0.06,0.19-0.1,0.27c0.04-0.01,0.14-0.06,0.13-0.13c-0.05,0.03-0.06,0.04,0.07-0.18c0.13,0.24,0.21-0.05,0.39,0.06
			c0.05-0.18,0.26-0.21,0.35-0.4c-0.1-0.01-0.23-0.27,0.04-0.1c-0.08,0.02-0.08-0.07-0.13-0.09c0.07-0.04,0.19-0.08,0.36-0.08
			C1376.53,214.11,1376.51,214.06,1376.55,214z M1375.46,213.09c0.02,0.01,0.01,0,0.01,0.04c-0.03,0-0.07,0.01-0.09,0.01
			C1375.4,213.13,1375.43,213.1,1375.46,213.09z M1375.12,213.13c0.01-0.01,0.01-0.01,0.02-0.02
			C1375.13,213.13,1375.13,213.13,1375.12,213.13C1375.13,213.13,1375.13,213.13,1375.12,213.13z M1375.12,213.6
			c0-0.03-0.01-0.02,0.05-0.05C1375.15,213.58,1375.17,213.56,1375.12,213.6z M1375.11,213.61c0,0-0.01,0.01-0.01,0.01
			c0-0.01,0-0.02,0.01-0.03C1375.11,213.6,1375.11,213.61,1375.11,213.61z M1375.19,213.22c0.02-0.04,0.04-0.02,0.05-0.03
			c0.01,0.01,0.03,0.03,0.05,0.04C1375.27,213.24,1375.25,213.24,1375.19,213.22z M1375.46,213.43
			C1375.46,213.44,1375.46,213.44,1375.46,213.43L1375.46,213.43C1375.46,213.43,1375.46,213.43,1375.46,213.43z M1375.27,213.19
			c-0.01,0-0.03-0.01-0.03-0.01C1375.22,213.17,1375.24,213.18,1375.27,213.19z M1375.23,213.19c-0.03,0.01-0.06-0.01-0.08-0.04
			c0.02-0.01,0.04-0.03,0.04-0.03C1375.19,213.13,1375.2,213.16,1375.23,213.19z M1375.1,213.08c0,0-0.01,0-0.01-0.01
			C1375.09,213.07,1375.1,213.07,1375.1,213.08z M1375.1,213.08c0,0,0,0.01,0,0.01C1375.1,213.09,1375.09,213.09,1375.1,213.08
			C1375.09,213.08,1375.1,213.08,1375.1,213.08z M1375.09,213.08c0-0.01-0.01-0.01-0.01-0.02
			C1375.07,213.06,1375.09,213.06,1375.09,213.08z M1374.92,213.09c-0.02-0.01-0.05-0.02-0.07-0.03L1374.92,213.09
			C1374.92,213.08,1374.92,213.08,1374.92,213.09z M1374.94,213.06C1374.89,213.05,1374.87,213.04,1374.94,213.06
			C1374.94,213.06,1374.94,213.06,1374.94,213.06z M1374.92,213.1C1374.92,213.1,1374.92,213.1,1374.92,213.1
			c-0.02,0-0.04-0.01-0.08-0.02L1374.92,213.1z M1375,213.76C1375,213.76,1375,213.77,1375,213.76
			C1374.99,213.76,1374.99,213.76,1375,213.76C1375,213.76,1375,213.76,1375,213.76z M1374.99,213.79c0.01,0,0.02,0,0.03,0
			C1374.99,213.84,1374.96,213.95,1374.99,213.79z M1375.1,213.07c0,0-0.01,0,0-0.01L1375.1,213.07c-0.02-0.02-0.02-0.02-0.05-0.04
			C1375.07,213.05,1375.08,213.01,1375.1,213.07z M1375.09,213.07L1375.09,213.07c-0.01,0-0.03-0.02-0.02-0.02c0,0,0,0,0,0
			C1375.07,213.05,1375.08,213.06,1375.09,213.07z M1375.07,212.56L1375.07,212.56C1375.03,212.56,1375.03,212.58,1375.07,212.56z"
              />
              <path
                className="st10"
                d="M1434.5,202.93c0.02,0,0.05,0.02,0.07,0.03c-0.21-0.03,0.1,0.1-0.02,0.11c0.01,0.02,0.03,0.23-0.08,0.05
			c-0.03,0.17,0-0.01-0.21,0.23c0.28,0.1,0.02,0,0.14,0.29c-0.11-0.12-0.02-0.06-0.28-0.07c0.06,0.07-0.09,0.05,0,0.15
			c-0.02-0.01-0.03,0.01-0.05,0.01c0.04,0.03,0.04,0.18-0.08,0.75c-0.18-0.11,0.05,0.21-0.17,0c0.01,0.06,0.05,0.13,0.06,0.19
			c-0.06-0.01-0.07-0.06-0.1-0.09c0.02,0-0.04,0.14,0.12,0.19c-0.2-0.17,0.19,0.15,0.07-0.27l0.12,0.08
			c-0.16-0.15,0.02-0.06,0.11-0.19c0.02,0.02,0.1,0.03,0.07,0.05c0.1-0.11,0.16-0.21-0.1-0.36l0.27,0.03l-0.1-0.05
			c0.01-0.01,0.03-0.03,0.06-0.01c-0.05-0.07-0.05-0.01-0.1-0.06c0.11-0.03,0.02-0.19,0.19-0.14c-0.07-0.05-0.08,0.04-0.11-0.05
			c0.19,0.11-0.03-0.2,0.22-0.06c-0.16-0.16,0.23-0.06,0.04-0.38c0.05,0.04-0.02-0.04,0.21-0.18c-0.11-0.06-0.06-0.13-0.07-0.2
			c0.07,0.03,0.1,0.05,0.12,0.09c0.01-0.04,0.03-0.05-0.03-0.14l0.09,0.05c0.02-0.07-0.05-0.18,0.04-0.25
			c-0.08-0.04-0.26-0.01-0.28-0.13c0.05,0.01,0.13,0.04,0.14,0.07c-0.02-0.01,0.11-0.12,0.11-0.01c0.03-0.03,0.08-0.06,0.14-0.06
			c-0.04-0.02-0.17-0.13,0.05-0.09c-0.04-0.03,0.02-0.06-0.07-0.1c0.01-0.02,0.05-0.01,0.08-0.01c-0.04-0.05,0,0-0.08-0.17l0,0
			c-0.15-0.13,0.11-0.11,0.32-0.75c0.04,0.08,0.06,0,0.02,0.07c0.05-0.06,0.11-0.06,0.05-0.17c0.07,0.06,0.12-0.02,0.11-0.06
			l-0.08,0.03c0.24-0.15,0.03-0.72,0.36-0.77l-0.04-0.02c0.05-0.02,0.08-0.11,0.14-0.07c-0.02-0.06-0.06-0.04-0.09-0.01
			c0.09-0.07,0.05-0.13,0.12-0.21c-0.09,0.01-0.17-0.4,0.14-0.17c-0.05-0.11,0.1-0.14,0.13-0.18l0.02,0.05
			c-0.01-0.03,0-0.05,0.01-0.07l-0.09,0c0.02-0.02,0.03-0.04,0.06-0.03c-0.04-0.04-0.14-0.02-0.19-0.1c0.05-0.02-0.01-0.12,0.1-0.09
			c-0.08,0.13,0.39,0.16,0.08-0.11c0.04,0.03,0.08,0.02,0.1,0c-0.03,0-0.12-0.05-0.12-0.2c-0.05,0.02-0.06,0.06-0.07-0.08
			c0.05,0.01,0.02,0.05,0.04,0.04c-0.09-0.09,0.15-0.08,0.1-0.18c0.02,0.01,0.36-0.02,0.1-0.23l0.01,0.16l-0.09-0.13
			c-0.02,0.04,0.02,0.11,0,0.13c-0.05-0.06-0.03-0.05-0.1-0.05c0.04-0.34,0.33,0.17,0.32-0.44c-0.06,0.02-0.1,0.06-0.05-0.11
			c0.06,0.03,0.1,0.01,0.14,0c-0.05-0.01,0.04-0.09-0.09-0.15c0.13-0.07,0.19,0,0.01-0.31c0.06,0.05,0.12,0.02,0.15,0.11
			c-0.02-0.12,0.01,0.03-0.15-0.35c0.17-0.04,0.18-0.06,0.31-0.58c0.02,0.01,0.03,0.01,0.02,0.02c-0.05-0.35,0.21,0.16,0.19-0.54
			c0,0,0.02,0.01,0.03,0.01c-0.09-0.1-0.09-0.22-0.1-0.32c0.18,0.08,0.11,0.08,0.19-0.09c0.32,0.4-0.26-0.56,0.13-0.41
			c-0.02-0.05-0.08-0.19-0.08-0.27c0.08,0.07,0.11,0.18,0.15,0.25c0.01-0.04,0.03-0.15-0.02-0.19c-0.02,0.06-0.02,0.08-0.08-0.19
			c0.25,0.09,0.13-0.2,0.35-0.21c-0.05-0.18,0.13-0.29,0.17-0.47c-0.07,0.05-0.29-0.23,0.02-0.1c-0.08,0.03-0.09-0.06-0.14-0.07
			c0.06-0.05,0.17-0.08,0.35-0.07c-0.08-0.01-0.1-0.06-0.05-0.1c-0.09,0.11-0.03-0.24,0.07-0.11c-0.02-0.08,0.07-0.24-0.08-0.35
			c0.03,0,0.09,0,0.09,0.03c0.18-0.28,0.08-0.81,0.62-2.14c-0.14-0.14,0.06-0.13,0.05-1.03c0.14-0.03,0.11,0.04,0.13-0.33l0.07,0.03
			c-0.22-0.24-0.15-0.02-0.19-0.67c0.02,0,0.07,0,0.07,0.02c-0.01-0.07,0.04-0.31-0.15-0.24c0.13-0.03-0.03-0.16-0.11-0.22
			c0.2-0.02,0.04-0.04-0.46-0.81c0.07,0.08,0.11,0.16,0.1,0.15c0.21,0-0.09-0.48-0.38-0.4c-0.01-0.01,0.02,0.01,0.06,0.02l-0.01,0
			c0.02,0.02,0.05,0.04,0.08,0.07c-0.06,0.01-0.11,0.02-0.11-0.05c-0.06,0.03-0.12,0.07-0.15,0.08c-0.03-0.3,0.26-0.46-0.55-0.46
			c0.03-0.01,0.06,0,0.07-0.02c-0.09,0.02-0.28-0.06-0.26,0.08c-0.28-0.18-0.01-0.24-0.57,0.52c0.02,0,0.04-0.01,0.05,0
			c-0.12,0.13-0.31,0.07-0.51,0.88l-0.1-0.03c0.28-0.08-0.37,0.37-0.15,1.05c0-0.01,0-0.01,0-0.01c0.1,0.02,0.23,0.04,0.45,0.05
			l-0.07-0.11l0.08,0l-0.01,0.07c0.08-0.01,0.03-0.06-0.01-0.1c0.04,0.01,0.07,0.01,0.09,0l-0.12-0.12
			c0.06,0.01,0.11,0.07,0.16,0.04c-0.06-0.06-0.15-0.01-0.17-0.09c0.08-0.01,0.14,0.02,0.09-0.04c0.04,0.01,0.06,0.03,0.07,0.05
			c-0.03-0.05,0.02-0.1-0.06-0.16c0.1,0,0.03-0.13,0.17-0.07c-0.03-0.03-0.08-0.11-0.07-0.15c0.14,0.14,0.07-0.12,0.24-0.02
			c-0.13-0.12,0.01-0.19-0.03-0.31c0.01,0.01,0.03,0.03,0.02,0.03c0.04-0.04,0.09-0.02,0.13-0.04c-0.12-0.07-0.1-0.15-0.16-0.24
			c0.14,0.02,0.12,0.11,0.24,0.16c-0.1-0.05-0.04-0.15-0.13-0.18c0.09,0,0.06-0.01,0.16,0.03c-0.14-0.1,0-0.05-0.07-0.14
			c0.11-0.01,0.14,0.07,0.14-0.01c0.21,0.12,0.01-0.08,0.14-0.44l0.02,0.02c0.01-0.02-0.17-0.09-0.09-0.17
			c-0.01,0.01,0.01,0.04,0.02,0.07l-0.01-0.05c0.11,0.15,0.01,0.16,0.13,0.11c-0.02-0.01-0.03-0.01-0.05-0.01
			c0.44,0.05-0.11,0.06,1.06,2.28l-0.13,0.02c0.18,0.17-0.13-0.14,0,0.36c-0.04-0.01-0.05-0.04-0.07-0.07
			c0.15,0.11-0.16,0.02,0.05,0.12c-0.07,0.03-0.09,0.12-0.08,0.19l-0.07-0.03c-0.05,0.12-0.03,0.27-0.07,0.41
			c0.05-0.04,0.17,0,0.17,0.05l-0.11-0.01c0.06,0.04,0.23,0.1-0.04,0.01l0.02,0.08c-0.03-0.01-0.05-0.02-0.09-0.04
			c0.06,0.04-0.14,0.08-0.03,0.15c-0.08,0.06-0.08,0.55-0.01,0.81c-0.09-0.02-0.11,0.09-0.16,0.02c-0.02,0.13-0.03,0.16,0,0.34
			c0.06-0.02,0.15,0.03-0.01,0.03c0.08,0.21-0.37,0.34-0.23,0.53l-0.01-0.01c0.05,0.28-0.04,0.17-0.39,0.99
			c0.03,0.01,0.15,0.02-0.01,0.06c0.45-0.2-0.36,0.55-0.42,1.76c-0.02-0.02-0.05-0.03-0.05-0.05c-0.09,0.02-0.06,0.17,0.01,0.19
			c0.06-0.07-0.21,0.28-0.14,0.65c-0.15-0.05-0.17,0.1-0.11,0.18c-0.04-0.03-0.06,0.03-0.12-0.01c-0.02,0.06-0.01,0.12,0.07,0.14
			c-0.22,0.05,0.07,0.26-0.18,0.25c-0.01,0.3,0.1,0.16-0.23,1.13c-0.01-0.01-0.02-0.02-0.03-0.04c0,0.03-0.04,0.05,0.03,0.07
			l-0.01-0.03c0.11-0.01,0.17,0.17,0.22,0.19c-0.18-0.11-0.09,0.11-0.32-0.04c0.01,0.02,0.03,0.05,0.08,0.07
			c-0.19,0.17-0.03,0.04-0.3,0.63c0.03-0.03-0.06,0.12,0.08,0.08c-0.02,0.06,0.05,0.15-0.01,0.19l-0.03-0.06l-0.02,0.13l0.07-0.05
			c0.04,0.15,0.22,0.04-0.16,0.26c0.09,0.07-0.02,0.16,0.07,0.23c-0.02-0.02-0.09-0.05-0.1-0.05l0.07,0.06
			c-0.09,0.04-0.01,0.07-0.17,0.26l0-0.05l-0.07,0.02l0.06,0.04c-0.13-0.06,0.06,0.31-0.16,0.61c-0.01-0.02-0.09-0.06-0.07-0.05
			c-0.06,0.08-0.02,0.2-0.02,0.36c0.01-0.09-0.26,0.09-0.06,0.22c-0.15,0.1-0.36,0.02-0.13,0.69
			C1434.65,202.89,1434.55,202.92,1434.5,202.93z M1437.63,188.95c0.01,0,0.01,0.01,0.03,0.02
			C1437.64,188.96,1437.65,188.96,1437.63,188.95z M1437.74,189.07c-0.01-0.03-0.07-0.06,0.03-0.08
			C1437.75,189,1437.75,189.03,1437.74,189.07z M1437.96,188.38c0.01-0.01,0,0,0.04-0.03
			C1438.03,188.36,1438.05,188.43,1437.96,188.38z"
              />
              <path
                className="st10"
                d="M1439.07,198.98c-0.09,0.02-0.02,0.06-0.21,0.22l0-0.05l-0.07,0l0.05,0.05c-0.12-0.08,0.01,0.31-0.25,0.54
			c0-0.02-0.08-0.07-0.06-0.06c-0.07,0.07-0.05,0.19-0.07,0.34c0.01-0.06-0.25,0.08-0.09,0.19c-0.16,0.07-0.36-0.04-0.22,0.63
			c-0.02-0.08-0.11-0.06-0.17-0.06c0.02,0.01,0.04,0.03,0.07,0.04c-0.2-0.06,0.09,0.11-0.03,0.1c0,0.03,0,0.22-0.08,0.03
			c-0.05,0.15,0-0.01-0.23,0.18c0.26,0.15,0.02,0.01,0.1,0.3c-0.09-0.13,0-0.06-0.26-0.12c0.05,0.08-0.09,0.03-0.02,0.15
			c-0.03-0.01-0.03,0.04-0.1-0.01c0.17,0.12-0.04,0.54-0.13,0.7c-0.16-0.13,0.02,0.21-0.16-0.03c0,0.06,0.03,0.13,0.04,0.19
			c-0.06-0.02-0.06-0.07-0.09-0.11c0.02,0.01-0.05,0.13,0.09,0.2c-0.18-0.18,0.16,0.17,0.1-0.24l0.11,0.1
			c-0.13-0.17,0.02-0.06,0.13-0.16c0.02,0.02,0.09,0.04,0.06,0.06c0.11-0.09,0.18-0.17-0.05-0.36l0.26,0.07l-0.09-0.06
			c0.01-0.01,0.03-0.03,0.06,0c-0.04-0.08-0.04-0.02-0.09-0.07c0.12-0.01,0.05-0.17,0.2-0.1c-0.06-0.06-0.08,0.02-0.1-0.06
			c0.17,0.13-0.01-0.2,0.22-0.02c-0.14-0.18,0.23-0.01,0.09-0.35c0.04,0.05-0.01-0.04,0.23-0.13c-0.1-0.08-0.04-0.13-0.04-0.2
			c0.07,0.04,0.09,0.06,0.1,0.11c0.01-0.04,0.03-0.04-0.01-0.14l0.08,0.06c0.03-0.06-0.03-0.18,0.07-0.23
			c-0.08-0.05-0.25-0.06-0.26-0.17c0.04,0.02,0.12,0.06,0.12,0.09c-0.02-0.01,0.12-0.09,0.11,0.02c0.03-0.03,0.09-0.05,0.14-0.03
			c-0.03-0.02-0.15-0.15,0.06-0.07c-0.03-0.04,0.03-0.05-0.06-0.11c0.01-0.02,0.05,0,0.08,0.01c-0.03-0.05,0,0-0.05-0.18l0,0
			c-0.13-0.15,0.13-0.08,0.42-0.64c0.03,0.08,0.06,0.01,0.01,0.07c0.06-0.05,0.12-0.03,0.07-0.15c0.06,0.07,0.12,0.01,0.12-0.03
			l-0.08,0.01c0.26-0.1,0.14-0.67,0.47-0.65l-0.03-0.03c0.05-0.01,0.09-0.09,0.14-0.04c-0.01-0.06-0.05-0.05-0.09-0.03
			c0.09-0.05,0.07-0.12,0.15-0.17c-0.08-0.01-0.1-0.4,0.16-0.13c-0.03-0.11,0.12-0.11,0.16-0.14l0.01,0.05
			c0-0.03,0.01-0.05,0.02-0.07l-0.08-0.02c0.02-0.01,0.04-0.03,0.07-0.02c-0.03-0.05-0.13-0.05-0.17-0.13
			c0.05-0.01,0.01-0.11,0.11-0.07c-0.1,0.1,0.35,0.23,0.09-0.09c0.04,0.03,0.08,0.03,0.1,0.02c-0.03-0.01-0.1-0.07-0.08-0.21
			c-0.05,0.01-0.07,0.04-0.06-0.09c0.04,0.02,0.01,0.05,0.03,0.05c-0.07-0.1,0.16-0.05,0.13-0.14c0.01,0.01,0.35,0.06,0.13-0.2
			l-0.02,0.15l-0.06-0.14c-0.02,0.04,0,0.1-0.02,0.12c-0.04-0.07-0.02-0.06-0.09-0.07c0.09-0.31,0.29,0.23,0.38-0.34
			c-0.06,0-0.11,0.03-0.03-0.11c0.06,0.04,0.09,0.03,0.14,0.03c-0.05-0.02,0.05-0.08-0.06-0.16c0.13-0.04,0.19,0.04,0.07-0.28
			c0.05,0.06,0.12,0.05,0.13,0.13c0-0.12,0,0.03-0.08-0.36c0.18,0,0.19-0.01,0.4-0.47c0.02,0.01,0.03,0.02,0.02,0.02
			c-0.01-0.32,0.21,0.15,0.28-0.46c0,0,0.02,0.01,0.03,0.02c-0.07-0.11-0.05-0.23-0.03-0.32c0.16,0.11,0.1,0.1,0.2-0.04
			c0.22,0.41-0.14-0.59,0.2-0.35c-0.01-0.05-0.04-0.19-0.03-0.27c0.07,0.09,0.07,0.19,0.1,0.27c0.02-0.04,0.06-0.13,0.01-0.18
			c-0.03,0.05-0.03,0.07-0.04-0.19c0.23,0.15,0.17-0.16,0.38-0.11c-0.01-0.18,0.18-0.23,0.25-0.39c-0.1-0.01-0.21-0.29,0.04-0.08
			c-0.08,0-0.07-0.08-0.12-0.1c0.07-0.03,0.18-0.04,0.35,0.02c-0.07-0.03-0.08-0.08-0.03-0.11c-0.11,0.08,0.02-0.23,0.09-0.09
			c0-0.07,0.12-0.2,0-0.34c0.03,0,0.09,0.02,0.08,0.05c0.47-0.79,0.17-0.53,1.05-1.8c0.01,0.01-0.17-0.08-0.02-0.16l-0.01,0.01
			c0.13,0.04,0.14-0.04,0.17-0.09l-0.16-0.1c0.31,0,0.12-0.44,0.37-0.43c-0.03-0.06-0.01-0.11-0.04-0.16c0.15,0,0.1,0.07,0.2-0.3
			l0.06,0.05c-0.25-0.2,0.27-0.38-0.29-1.02c0.12-0.08-0.12-0.2-0.24-0.2l0.07-0.07c-0.03,0.02-0.13-0.02-0.44-0.01l0.06-0.05
			c-0.14-0.04-0.1,0.22-0.33-0.04c-0.01,0.03-0.05-0.03-0.4,0.11c0.1-0.04,0.19-0.06,0.18-0.05c-0.12-0.18,0.01-0.4-0.46,0.06
			c0.02,0.02,0.03,0.01-0.01,0.06c0.01-0.01-0.04-0.03,0-0.06c-0.03,0.01-0.05,0.02-0.1,0.04c0.06,0.08,0,0.1,0.09,0.14
			c-0.01,0,0.01-0.01,0.03-0.05l0,0.01c0.02-0.02,0.05-0.03,0.08-0.06c0,0.06-0.01,0.11-0.07,0.09c0.02,0.06,0.03,0.13,0.04,0.16
			c-0.03,0.1-0.49,0.07-1.11,0.69c0.02,0,0.03,0,0.05,0.02c-0.14,0.09-0.29-0.04-0.72,0.6l-0.08-0.07c0.28,0.07-0.45,0.13-0.61,0.76
			c0-0.01,0-0.01,0-0.01c0.07,0.07,0.17,0.16,0.34,0.3l0-0.13l0.07,0.05l-0.05,0.04c0.08,0.03,0.05-0.03,0.05-0.08
			c0.03,0.03,0.06,0.04,0.08,0.05l-0.04-0.17c0.05,0.04,0.05,0.12,0.12,0.11c-0.02-0.08-0.12-0.09-0.1-0.16
			c0.08,0.03,0.11,0.09,0.1,0.01c0.03,0.03,0.04,0.06,0.04,0.08c0-0.06,0.07-0.08,0.03-0.16c0.09,0.05,0.09-0.1,0.19,0.02
			c-0.01-0.04-0.02-0.14,0-0.16c0.06,0.18,0.12-0.08,0.22,0.09c-0.06-0.16,0.09-0.17,0.11-0.29c0,0.01,0.01,0.04,0,0.04
			c0.05-0.02,0.09,0.01,0.13,0.02c-0.08-0.11-0.04-0.18-0.06-0.28c0.13,0.07,0.07,0.15,0.16,0.24c-0.07-0.09,0.01-0.15-0.06-0.21
			c0.09,0.04,0.06,0.01,0.14,0.08c-0.1-0.14,0.02-0.05-0.02-0.16c0.1,0.03,0.11,0.12,0.14,0.03c0.19,0.19-0.05-0.05,0.31-0.39
			c0.01-0.01-0.18-0.11-0.05-0.18c-0.02,0.01,0,0.04,0.01,0.07l0.01-0.05c-0.02,0.23,0.23,0.08,0.09,0.09
			c0.12,0.44,0.14-0.36,1.46-0.57c0,0.09,0.09,0.26-0.08,0.35c0.1,0.03,0.11,0.3-0.14,0.4l0.08,0.1l-0.13-0.02
			c0.12,0.17-0.1-0.11-0.09,0.31c-0.04-0.02-0.03-0.06-0.05-0.09c0.11,0.15-0.16-0.03,0.01,0.13c-0.08,0-0.12,0.09-0.13,0.16
			l-0.06-0.05c-0.08,0.1-0.1,0.25-0.17,0.37c0.06-0.03,0.16,0.05,0.15,0.09l-0.11-0.04c0.05,0.05,0.2,0.16-0.04,0l0,0.08
			c-0.03-0.01-0.04-0.04-0.08-0.06c0.05,0.06-0.15,0.04-0.07,0.13c-0.09,0.04-0.2,0.49-0.18,0.74c-0.26-0.13-0.43,0.77-1.16,1.55
			c0.02,0.02,0.14,0.06-0.02,0.06c0.48-0.08-0.46,0.43-0.74,1.54c-0.02-0.02-0.05-0.04-0.04-0.06c-0.01,0.04-0.09,0.17-0.28,0.76
			c-0.13-0.08-0.18,0.06-0.14,0.14c-0.03-0.04-0.07,0.01-0.11-0.04c-0.03,0.05-0.03,0.11,0.05,0.14c-0.22,0,0.03,0.26-0.21,0.2
			c-0.06,0.28,0.07,0.17-0.42,1.01c-0.01-0.01-0.02-0.03-0.02-0.05c-0.01,0.03-0.05,0.04,0.01,0.07l0-0.03
			c0.11,0.02,0.14,0.2,0.18,0.23c-0.16-0.14-0.11,0.09-0.3-0.1c0,0.02,0.02,0.06,0.07,0.08c-0.2,0.12-0.04,0.03-0.39,0.53
			c0.03-0.03-0.08,0.1,0.07,0.09c-0.03,0.05,0.02,0.15-0.04,0.17l-0.02-0.07l-0.04,0.11l0.08-0.03c0.02,0.15,0.21,0.08-0.2,0.21
			c0.08,0.08-0.05,0.14,0.04,0.23c-0.02-0.02-0.08-0.06-0.09-0.06L1439.07,198.98z M1443.28,189.41c0,0.02,0.04,0.03,0.06,0.05
			C1443.32,189.46,1443.28,189.5,1443.28,189.41z"
              />
              <path
                className="st10"
                d="M1443.99,193.48c-0.49,0.02-0.43,0.2-0.67,0.9c0.11-0.01,0.08-0.01,0.35-0.28c0.16,0.32,0.13-0.23,0.24,0.04
			c0.06-0.08,0.2-0.1,0.21-0.2c0.11,0.06,0.26-0.11,0.3,0.04c0.01-0.04,0.1-0.16,0.18-0.18c-0.09,0.21,0.27-0.05,0.27,0.16
			c0.07-0.18,0.33-0.11,0.49-0.18c-0.01,0.01-0.03,0.04-0.04,0.03c0.09,0.02,0.11,0.08,0.16,0.11c0.03-0.13,0.18-0.14,0.28-0.19
			c0.07,0.16-0.11,0.13-0.11,0.27c0.03-0.11,0.21-0.05,0.21-0.14c0.05,0.11,0.05,0.07,0.05,0.19c0.09-0.15,0.08,0.01,0.19-0.05
			c-0.02,0.09,0.2,0.15-0.03,0.24c0.18-0.09,0.18,0.29,0.82,0.62c0-0.04-0.03-0.04-0.06-0.06l0.04,0.06c-0.37-0.09,0-0.22-0.77,1.28
			c0-0.27-0.83,0.88-1.68,2c-0.05-0.04-0.1-0.08-0.16-0.13l0.03,0.11l-0.07-0.05l0.03-0.03c-0.07-0.04-0.04,0.02-0.02,0.07
			c-0.03-0.03-0.06-0.04-0.08-0.05l0.07,0.15c-0.05-0.04-0.07-0.11-0.12-0.11c0.03,0.07,0.12,0.09,0.12,0.15
			c-0.07-0.04-0.11-0.09-0.08-0.02c-0.03-0.03-0.05-0.05-0.05-0.07c0.01,0.06-0.04,0.06,0.01,0.14c-0.08-0.05-0.05,0.08-0.15-0.03
			c0.02,0.03,0.05,0.12,0.03,0.14c-0.09-0.17-0.08,0.06-0.2-0.1c0.09,0.14-0.04,0.14-0.02,0.25c-0.01-0.01-0.02-0.04-0.01-0.03
			c-0.03,0.01-0.08-0.02-0.11-0.03c0.09,0.1,0.07,0.16,0.11,0.24c-0.12-0.07-0.09-0.14-0.19-0.22c0.08,0.08,0.02,0.13,0.09,0.19
			c-0.08-0.04-0.05-0.01-0.14-0.08c0.11,0.13,0,0.04,0.05,0.14c-0.09-0.03-0.12-0.11-0.12-0.04c-0.18-0.16,0,0.05-0.14,0.33
			c-0.05-0.03-0.03-0.03,0.06,0.14c0.01-0.01-0.01-0.03-0.02-0.06l0.01,0.04c-0.13-0.13,0.01-0.15-0.17-0.08l0.07,0
			c-0.17-0.01-0.29-0.03-0.58,0.52c-0.02-0.03-0.03-0.07-0.07-0.08c0.13,0.07-0.19,0.38-0.85,0.74c0.05,0.08-0.06,0.29-0.29,0.21
			l0.04,0.12l-0.1-0.08c0.05,0.17-0.1-0.14-0.14,0.21c-0.03-0.04-0.02-0.06-0.03-0.09c0.07,0.16-0.13-0.09-0.01,0.1
			c-0.06-0.03-0.11,0.01-0.13,0.06c-0.09-0.07-0.03-0.09-0.23,0.14c0.05,0.01,0.12,0.11,0.11,0.14l-0.08-0.08
			c0.03,0.06,0.14,0.21-0.03-0.02l-0.01,0.06c-0.02-0.02-0.03-0.05-0.05-0.08c0.03,0.07-0.13-0.04-0.07,0.07
			c0.08-0.43-0.65,0.87-1.43,1.09c0.01,0.01,0.06,0.07,0.03,0.07c0,0.11-0.63,0.46-0.62,0.39c-0.04,0.2-0.25,0.18-0.29,0.36
			c-0.01-0.03-0.03-0.05-0.02-0.06c-0.07-0.05-0.09,0.06-0.05,0.12c-0.01-0.04-0.16-0.1-0.15,0.09c-0.07-0.01-0.06,0.1-0.13,0.08
			c0.04,0.1-0.11,0.01-0.02,0.14c-0.09-0.13-0.15-0.05-0.14,0.03c-0.02-0.05-0.05-0.02-0.08-0.08c-0.03,0.02-0.04,0.07,0.01,0.13
			c-0.17-0.11-0.03,0.21-0.2,0.04c-0.05,0.14-0.1,0.25-0.17,0.34c-0.13-0.1-0.03-0.1-0.36,0.2c-0.01-0.01-0.01-0.03-0.01-0.05
			c-0.01,0.01-0.05,0,0,0.06l0-0.03c0.08,0.07,0.07,0.22,0.1,0.27c-0.09-0.19-0.1,0.01-0.21-0.23c0,0.02,0.01,0.05,0.04,0.09
			c-0.16-0.02-0.03-0.01-0.41,0.19c0.03,0-0.08,0.03,0.03,0.1c-0.02-0.05-0.06,0.19-0.07,0.04l-0.05,0.07
			c0.06,0.03,0.07,0.02,0.09,0.15c-0.08-0.04-0.09,0.03-0.23-0.09c0.04,0.1-0.07,0.08-0.02,0.19c-0.01-0.03-0.05-0.08-0.06-0.09
			l0.04,0.08c-0.08-0.1-0.1-0.01-0.07,0c-0.03-0.05-0.2,0.24-0.21-0.01c-0.06,0.07-0.07,0.24-0.17,0.26
			c0.15,0.16,0.01,0.01-0.08,0.06c0-0.02-0.04-0.09-0.04-0.08c-0.07,0.01-0.08,0.11-0.13,0.21c0.03-0.03-0.22-0.08-0.11,0.09
			c-0.13-0.07-0.34-0.14-0.31,0.34c0.01-0.07-0.07-0.11-0.11-0.14c0.02,0.02,0.03,0.05,0.04,0.07c-0.14-0.16,0.04,0.15-0.05,0.06
			c-0.01,0.03-0.05,0.15-0.07-0.02c-0.08,0.08,0.03,0-0.22,0c0.16,0.26,0.01,0.01,0,0.27c-0.04-0.15,0.03-0.03-0.17-0.23
			c0.02,0.09-0.08-0.03-0.05,0.1c-0.02-0.03-0.03,0.01-0.07-0.06c0.24-0.11-0.1,0.45-0.38,0.33c-0.01,0.04-0.01,0.12-0.02,0.16
			c-0.04-0.05-0.03-0.09-0.04-0.13c0.02,0.02-0.06,0.06,0.01,0.18c0.03-0.04,0.14-0.12,0.2,0.04c-0.02-0.01,0.08-0.12,0.17,0.03
			c0.12-0.02,0.17-0.04,0.05-0.29l0.18,0.2l-0.05-0.1c0.01,0,0.03,0,0.05,0.03c-0.01-0.08-0.03-0.04-0.05-0.11
			c0.09,0.06,0.08-0.1,0.18,0.04c-0.03-0.08-0.07-0.03-0.06-0.1c0.1,0.19,0.05-0.14,0.17,0.11c-0.01-0.19,0.18,0.07,0.15-0.2
			c0.01,0.06,0.02-0.03,0.2,0.03c-0.06-0.11,0-0.12,0.02-0.17c0.04,0.07,0.05,0.09,0.05,0.14c0.03-0.03,0.03-0.02,0.03-0.11
			l0.04,0.09c0.04-0.03,0.02-0.15,0.11-0.13c-0.05-0.08-0.18-0.18-0.15-0.27c0.03,0.04,0.08,0.11,0.07,0.13
			c0.11,0.01-0.06-0.03,0.19,0.12c-0.02-0.03-0.08-0.2,0.06-0.02c-0.02-0.05,0.04-0.02-0.02-0.11c0.01-0.01,0.04,0.03,0.06,0.05
			c-0.01-0.05,0.02,0,0-0.16l0,0c-0.16-0.14,0.24,0,0.54-0.26c-0.03,0.06,0.18,0.05,0.03,0c0.33,0.01,0.26-0.49,0.5-0.23l-0.02-0.04
			c0.04,0.02,0.09-0.02,0.12,0.05c0.01-0.05-0.03-0.06-0.06-0.07c0.08,0.02,0.08-0.05,0.15-0.05c-0.02,0.16,0.05-0.33,0.15-0.01
			c0-0.1,0.11-0.02,0.15-0.02l-0.01,0.04c0-0.03,0.02-0.03,0.03-0.04l-0.06-0.06c0.02,0,0.03-0.01,0.05,0.02
			c-0.02-0.05-0.09-0.11-0.1-0.19c0.04,0.02,0.03-0.08,0.1,0.01c-0.08,0.01,0.07,0.09,0.03,0.14c0.12-0.01,0.09,0.08,0.06-0.15
			c0.03,0.06,0.08,0.09,0.08,0.07c-0.02,0.02-0.07-0.08-0.08-0.13c0.07,0.01,0.02-0.06,0.04-0.07c-0.05-0.02-0.06-0.01-0.03-0.1
			c0.03,0.04,0,0.04,0.02,0.05c-0.03-0.11,0.13,0.05,0.13-0.04c0.04,0.02,0.07,0.09,0.1,0.11c-0.04-0.13,0.18,0.07,0.04-0.18
			l-0.04,0.1c-0.11-0.14,0.08-0.3,0.35-0.22c-0.03-0.04,0.06-0.03-0.02-0.15c0.17,0.25,0.04-0.36,0.18-0.01
			c0.02-0.09,0,0.02,0.01-0.31c0.18,0.13,0.15-0.13,0.31-0.02c0.15-0.29-0.03-0.13,0.47-0.53c0.04,0.08,0.01,0.05,0.07,0.12
			c-0.2-0.33,0.1-0.13,0.32-0.21c0-0.04,0-0.17,0.03-0.22c0.04,0.1,0.02,0.18,0.03,0.25c0.02-0.02,0.07-0.07,0.04-0.13
			c-0.01,0.04-0.15-0.2,0.32-0.06c0.02-0.14,0.19-0.09,0.27-0.17c-0.07-0.01-0.11-0.3,0.01-0.08c-0.02-0.03-0.01-0.07-0.04-0.1
			c0.06,0.01,0.15,0.06,0.28,0.18c-0.05-0.06-0.05-0.1-0.01-0.1c-0.08,0.02,0.05-0.17,0.09-0.02c0.01-0.06,0.13-0.1,0.06-0.26
			c0.06,0.4,0.25-0.44,1.2-0.93l-0.01,0c0.1,0.09,0.12,0.03,0.15,0.01l-0.11-0.15c0.25,0.14,0.15-0.26,0.35-0.11
			c-0.01-0.05,0.02-0.07,0.01-0.12c0.06,0.04,0.06,0.13,0.22-0.04c0.15,0.28,0.17-0.14,0.3-0.41c0.09,0.1,0.08-0.05,0.07-0.12
			c0.09,0.1,0.02,0.16,0.23-0.21c-0.03-0.06,0.04-0.12,0.17-0.14c-0.05,0.04-0.09,0.07-0.09,0.06c-0.02,0.07,0.4,0.02,0.23-0.21
			c0.01,0-0.01,0-0.01,0.04l0-0.01c-0.01,0.01-0.03,0.03-0.04,0.04c-0.02-0.05-0.03-0.09,0.02-0.07c-0.03-0.06-0.06-0.11-0.08-0.14
			c0.07-0.02,0.3,0.17,0.38-0.27c0.35,0.15,0.1,0.12,0.36-0.23c-0.02,0-0.03-0.01-0.04-0.02c0.07-0.04,0.3,0,0.45-0.45l0.08,0.07
			c-0.31-0.09,0.36-0.08,0.32-0.61c0,0.01,0,0.01,0,0.01c-0.03-0.03-0.05-0.05-0.09-0.09c0.69-0.14,0.41-0.11,2.16-0.2l-0.05,0.02
			c0.15,0.08,0.24-0.16,0.37-0.02c-0.08,0.04,0.15,0.02,0.98,0.89l-0.08-0.08c-0.02,0.09,0.09,0.25-0.22,0.2
			c0.1,0.19-0.01-0.28-0.5,0.47c-0.02-0.04,0.02-0.09,0.04-0.14c-0.08,0.21-0.1-0.06-0.18,0.13c0.03,0,0.03,0.06,0.04,0.08
			c-0.06-0.03-0.43,0.27-0.59,0.43l-0.04-0.06c-0.25,0.19-0.48,0.4-0.71,0.6c0.23,0.11,0.46,0.22,0.67,0.33
			c-0.14-0.11,0.08-0.14,0.22-0.09c-0.03,0.03-0.05,0.06-0.08,0.09c0.14,0,0.38-0.08,0.02,0.05l0.26,0.09
			c-0.01,0.03-0.08,0.02-0.14,0.04c0.67,0.32,0.18,0.2,1.89,0.97c-0.05,0.08,0.11,0.21-0.01,0.18c0.48,0.45,0.68,0.22,1.01,1.37
			l-0.01-0.01c-0.03,0.04,0.3,0.19,0.02,0.14c0.13,0.09,0.02-0.01,0.02,0.18c-0.4-0.08-0.33-0.1-1.05,0.32
			c0.06,0.07,0.1,0.07-0.11,0.13c0.35,0.21-1.4,1.18-0.36,2.72l-0.14-0.05c0.31,0.47,0.49,1,0.87,1.34
			c-0.04-0.01-0.07,0.01-0.09-0.03c-0.01,0.14,0.22,0.32,0.27,0.28c0.06,0.18-0.06-0.02,0.74,1.04c-0.15,0.06-0.08,0.17-0.04,0.25
			c-0.04-0.03-0.06,0.04-0.12,0c-0.01,0.04,0,0.09,0.07,0.13c-0.53,0.14-0.17,0.15-1.29,0.61c0.76-0.29-1.25,0.01-1.19,1.15
			c-0.17-0.18-0.14,0.3-0.34,0.06c0,0.05,0.02,0.11,0.07,0.11c-0.03,0.06-0.06,0.08-0.1,0c-0.07,0.1,0.18,0.35-0.01,0.31
			c0.06,0.11,0.13,0.21,0.11,0.38c-0.02-0.01-0.05,0.01-0.05-0.04c0.02,0.28,0.21,0.46,0.16,0.75c-0.15-0.08-0.05,0.1-0.18,0.11
			l0.04,0.14l0.07-0.06c-0.01,0.12,0.08,0.25,0.02,0.35c-0.01-0.03-0.02-0.07-0.04-0.1c0,0.08-0.01,0.15-0.02,0.22
			c0.03-0.03,0.05-0.07,0.08-0.11c0.04,0.14,0.17,0.02,0.09,0.18c0-0.04,0,0-0.14,0.15c-0.01-0.04,0.02,0.37-0.2,0.12
			c0.06,0.11-0.15,0.23-0.14,0.36c-0.03-0.15-0.24-0.07-0.22,0.13c0.01-0.01,0-0.02,0.02-0.05c-0.21,0.11-0.3,0.13-0.37,0.14
			l0.05-0.04c-0.45-0.03,0.15,0.28-1.98,0.54l0.03-0.05c-0.51,0.12-0.46-0.01-1.61,0.17c0.08-0.05,0.15-0.07-0.23,0.02
			c0.03,0.11,0.23,0.09-0.08,0.2c0.13-0.06-0.01-0.13-0.07-0.17c0.01,0.02-0.01,0.05,0,0.08c-0.15-0.18-0.03,0.09-0.23,0.04
			c0.04-0.02-0.4,0.17-0.15-0.04c-0.12,0.01-0.23,0.19-0.34,0.1c0.05-0.03,0.12-0.03,0.17-0.06c-0.13-0.1-0.14-0.07-0.23-0.09
			l0.01,0l-0.21-0.04l0.05,0.03c-0.14,0.25-0.31,0.06-0.51,0.22c0.16-0.14,0.07-0.09-0.07-0.28c-0.09,0.09-0.16-0.06-0.3,0.06
			c0.1-0.07-0.86,0.18-1.79,0.11c0.21-0.19-0.02-0.06,0.65-0.12c-0.03,0.02-0.03-0.02-0.1-0.03c0.05-0.02,0.08-0.03,0.14-0.06
			l0.01,0.11c-0.18-0.24,0.34,0.16,0.67-0.25c-0.01,0.01-0.01,0.01-0.01,0.01c-0.01-0.1-0.03-0.23-0.06-0.45l-0.05,0.1l-0.02-0.08
			l0.05-0.01c-0.03-0.07-0.04-0.01-0.06,0.04c-0.01-0.04-0.01-0.07-0.03-0.09l-0.05,0.16c-0.01-0.06,0.02-0.13-0.02-0.16
			c-0.02,0.07,0.03,0.14-0.01,0.19c-0.03-0.07-0.02-0.13-0.05-0.07c-0.01-0.04,0-0.07,0.01-0.09c-0.03,0.05-0.07,0.02-0.09,0.11
			c-0.03-0.09-0.1,0.03-0.1-0.13c-0.01,0.04-0.05,0.12-0.07,0.13c0.04-0.19-0.1-0.01-0.09-0.21c-0.03,0.17-0.13,0.08-0.19,0.17
			c0-0.01,0.01-0.04,0.02-0.03c-0.04-0.01-0.05-0.06-0.07-0.09c0,0.14-0.06,0.17-0.08,0.26c-0.05-0.13,0.02-0.16,0.01-0.29
			c0,0.11-0.07,0.11-0.06,0.2c-0.03-0.08-0.03-0.04-0.05-0.15c0,0.17-0.03,0.03-0.05,0.14c-0.05-0.08-0.02-0.16-0.07-0.11
			c-0.03-0.24-0.03,0.03-0.34,0.16c-0.02-0.06,0-0.05-0.05,0.14c0.01,0,0.02-0.03,0.02-0.06l-0.02,0.04
			c-0.01-0.18,0.11-0.11-0.08-0.17l0.06,0.04c-0.11-0.23-0.39,0.2-2-0.07c-0.01,0.09-0.23,0.18-0.37-0.02l-0.05,0.11l-0.03-0.13
			c-0.08,0.16,0.02-0.18-0.25,0.07c0-0.04,0.02-0.06,0.04-0.09c-0.05,0.16-0.05-0.15-0.08,0.07c-0.03-0.07-0.1-0.07-0.14-0.04
			c-0.03-0.11,0.03-0.09-0.28-0.04c0.04,0.03,0.03,0.16,0,0.18l-0.01-0.11c-0.01,0.07-0.03,0.25-0.01-0.04l-0.05,0.04
			c0-0.03,0.01-0.05,0.01-0.1c-0.02,0.07-0.08-0.11-0.1,0.01c0.34-0.28-1.09,0.27-1.88-0.04c0,0.02,0.01,0.16-0.04,0.01
			c0.21,0.21-0.87,0-0.74-0.01c-0.16,0.14-0.32-0.01-0.47,0.11c0.01-0.03,0.01-0.06,0.03-0.06c-0.03-0.08-0.12-0.01-0.12,0.06
			c0.02-0.03-0.07-0.17-0.18-0.02c-0.06-0.05-0.11,0.04-0.15-0.02c-0.03,0.11-0.1-0.05-0.11,0.1c0.01-0.16-0.1-0.13-0.14-0.06
			c0.01-0.05-0.03-0.05-0.01-0.12c-0.04,0-0.08,0.03-0.07,0.11c-0.07-0.19-0.16,0.15-0.19-0.09c-0.12,0.08-0.24,0.14-0.35,0.17
			c-0.04-0.16,0.03-0.1-0.42-0.05c0-0.01,0.01-0.03,0.02-0.04c-0.02,0-0.04-0.02-0.04,0.05l0.02-0.02c0.03,0.1-0.08,0.22-0.08,0.27
			c0.04-0.2-0.09-0.05-0.03-0.31c-0.01,0.01-0.03,0.05-0.03,0.09c-0.12-0.11-0.02-0.02-0.46-0.09c0.03,0.02-0.09-0.02-0.03,0.1
			c0.02-0.05-0.16,0.12-0.09-0.01l-0.08,0.02c0.03,0.06,0.05,0.06-0.01,0.18c-0.05-0.08-0.09-0.03-0.14-0.2
			c-0.03,0.11-0.11,0.03-0.14,0.14c0.01-0.03,0.01-0.1,0.01-0.11l-0.02,0.09c-0.01-0.13-0.08-0.06-0.06-0.04
			c0.01-0.06-0.31,0.08-0.17-0.14c-0.1,0.02-0.21,0.15-0.3,0.12c0.03,0.22,0,0.01-0.1,0.01c0.01-0.01,0.02-0.1,0.02-0.08
			c-0.06-0.03-0.13,0.04-0.24,0.09c0.04-0.01-0.14-0.19-0.15,0.01c-0.07-0.13-0.21-0.31-0.47,0.1c0.05-0.05,0.01-0.13-0.01-0.18
			c0,0.02-0.01,0.05,0,0.08c-0.02-0.21-0.05,0.14-0.07,0.02c-0.03,0.02-0.14,0.09-0.05-0.06c-0.12,0.02,0.03,0.02-0.18-0.12
			c-0.01,0.3,0,0.02-0.16,0.23c0.05-0.15,0.04-0.01-0.01-0.29c-0.03,0.08-0.05-0.07-0.1,0.05c0-0.03-0.03,0-0.02-0.09
			c0.26,0.05-0.34,0.31-0.52,0.05c-0.04,0.03-0.07,0.09-0.11,0.12c-0.01-0.06,0.03-0.09,0.04-0.13c0,0.02-0.08,0.02-0.1,0.16
			c0.05-0.02,0.19-0.02,0.15,0.14c-0.01-0.02,0.14-0.05,0.12,0.12c0.11,0.06,0.17,0.06,0.21-0.21l0.03,0.27l0.01-0.11
			c0.01,0,0.03,0.01,0.02,0.06c0.04-0.07,0-0.05,0.02-0.11c0.04,0.1,0.13-0.04,0.13,0.13c0.02-0.08-0.04-0.06,0.01-0.12
			c-0.03,0.21,0.13-0.09,0.08,0.18c0.11-0.16,0.12,0.15,0.25-0.08c-0.02,0.05,0.03-0.02,0.16,0.14c0.02-0.13,0.07-0.1,0.11-0.13
			c0,0.08-0.01,0.11-0.04,0.14c0.04-0.01,0.04,0,0.09-0.07l-0.01,0.1c0.05,0,0.11-0.11,0.17-0.04c0.01-0.09-0.04-0.25,0.03-0.31
			c0,0.05,0,0.13-0.02,0.15c0.08,0.08-0.03-0.07,0.09,0.21c0-0.03,0.05-0.21,0.07,0.02c0.01-0.05,0.04,0,0.05-0.1
			c0.01,0,0.02,0.05,0.02,0.08c0.02-0.05,0.01,0.01,0.1-0.13h0c-0.05-0.21,0.19,0.14,0.61,0.11c-0.06,0.03,0.12,0.15,0.03,0.02
			c0.27,0.2,0.51-0.25,0.56,0.11l0.01-0.04c0.02,0.04,0.09,0.04,0.07,0.11c0.04-0.04,0.02-0.07-0.01-0.09
			c0.06,0.06,0.1,0.01,0.16,0.05c-0.02-0.1,0.23-0.25,0.13,0.08c0.06-0.08,0.11,0.06,0.14,0.07l-0.03,0.03
			c0.02-0.02,0.03-0.02,0.05-0.02l-0.01-0.08c0.02,0.02,0.03,0.02,0.03,0.05c0.02-0.05-0.01-0.14,0.03-0.21
			c0.02,0.04,0.07-0.04,0.08,0.07c-0.07-0.04,0.01,0.11-0.06,0.13c0.1,0.06,0.03,0.12,0.15-0.09c-0.01,0.06,0.01,0.12,0.03,0.11
			c-0.03,0-0.01-0.1,0.02-0.15c0.05,0.05,0.05-0.03,0.08-0.03c-0.03-0.05-0.04-0.04,0.04-0.1c0,0.05-0.03,0.03-0.02,0.05
			c0.04-0.11,0.08,0.12,0.13,0.04c0.02,0.04,0,0.12,0.02,0.15c0.04-0.13,0.1,0.16,0.15-0.12l-0.1,0.06
			c-0.01-0.18,0.25-0.19,0.42,0.03c0-0.05,0.07,0.01,0.08-0.13c-0.01,0.3,0.26-0.27,0.16,0.1c0.08-0.06-0.01,0.02,0.2-0.24
			c0.07,0.22,0.21-0.01,0.27,0.17c0.3-0.14,0.06-0.12,0.72-0.14c-0.02,0.09-0.03,0.05-0.02,0.14c0.04-0.39,0.17-0.04,0.4,0.03
			c0.03-0.03,0.11-0.13,0.16-0.16c-0.03,0.1-0.1,0.15-0.14,0.21c0.03,0,0.1-0.01,0.12-0.08c-0.04,0.03-0.01-0.26,0.3,0.15
			c0.11-0.1,0.21,0.04,0.33,0.03c-0.05-0.05,0.1-0.3,0.06-0.06c0.01-0.03,0.03-0.06,0.03-0.1c0.04,0.04,0.09,0.14,0.11,0.32
			c-0.01-0.08,0.02-0.11,0.06-0.08c-0.08-0.04,0.15-0.1,0.09,0.04c0.05-0.04,0.17,0,0.21-0.17c-0.13,0.41,0.34-0.15,1.57,0.03
			l-0.01,0c0.02,0.14,0.08,0.1,0.12,0.11l0.01-0.18c0.11,0.27,0.29-0.1,0.35,0.14c0.03-0.05,0.06-0.05,0.09-0.09
			c0.02,0.08-0.04,0.14,0.2,0.11c-0.06,0.31,0.23,0,0.51-0.12c0,0.13,0.09,0.01,0.14-0.05c0.01,0.14-0.09,0.13,0.32-0.01
			c0.01-0.07,0.11-0.06,0.23,0c-0.06,0-0.11-0.01-0.11-0.01c-0.04,0.06,0.23,0.24,0.33,0.02c0.11,0.2,0.25-0.12,0.34-0.02
			c0.04-0.05,0.13-0.02,0.14-0.15c0.02,0.07,0.03,0.08-0.1,0.17c0.25,0.01,0.02-0.01,0.66,0.03c-0.17,0.18-0.01,0.27,0.38,0.19
			c-0.02,0.03,0.02,0.1-0.05,0.08c0.2,0.09,0.33-0.1,0.41,0.01l-0.02,0.01c0.5-0.41-0.02-0.02,0.96-0.14
			c-0.07,0.23,0.35-0.13,0.27,0.17c0.17-0.22-0.02,0.16,0.77-0.12c-0.01,0.07,0.01-0.04,0.51,0.11c0.02-0.13,0.2-0.12,0.32-0.16
			c0,0.08-0.02,0.11-0.09,0.15c0.01-0.02,0.04,0.05,0.26-0.1l-0.02,0.1c0.16-0.02,0.31-0.14,0.52-0.1c0-0.09-0.19-0.23,0.02-0.31
			c0.01,0.05,0.03,0.13-0.02,0.15c0.02-0.01,0.06-0.05,0.1-0.03l-0.08,0.09c0.04-0.04,0.28-0.04,0.08,0.03
			c0.08,0,0.19,0.03,0.23,0.08c0.17-0.25,0,0.01,0.69-0.23l0,0c0.16-0.18,0.73,0.08,1.7-0.25c-0.11,0.09,0.06,0.04-0.11,0.08
			c0.17-0.01,0.22,0.03,0.36-0.11c-0.03,0.1,0.16,0.07,0.22,0.01l-0.13-0.02c0.08-0.04,0.16-0.06,0.23-0.09l0.01,0.13
			c0.31-0.15,0.97-0.39,1.15-1.23c-0.28,0.09,0.39-0.33,0.13-0.34c0.09-0.21,0.03-0.34,0.07-0.55c-0.04,0.07-0.37-0.58,0.05-0.45
			c-0.09-0.17,0.03-0.33,0.05-0.42l0.04,0.08c-0.02-0.05-0.02-0.09-0.02-0.13l-0.08,0.05c0.01-0.05,0.01-0.09,0.05-0.09
			c-0.05-0.09-0.32,0.03-0.13-0.28c-0.11,0.21,0.4,0.16,0.09-0.2c0.05,0.04,0.12,0,0.12-0.02c0.02,0.04-0.17-0.01-0.06-0.25
			l-0.06,0.04c-0.03-0.05,0-0.1,0.05-0.17c-0.03,0.04,0.2-0.07,0.71-0.13c-0.07-0.06-0.08-0.04,0.01-0.16
			c-0.09,0.05-0.16,0.1-0.23,0.15l0.12-0.18c-0.08,0.03-0.15,0.11-0.19,0.13c0.04-0.1,0.05-0.07-0.02-0.12
			c0.47-0.17,0.23,0.31,1.04-0.16l-0.1-0.01c0.02-0.09,0.17-0.06,0.26-0.12c0.01,0.07,0.08,0.08,0.13,0.1
			c-0.02-0.05,0.22-0.07,0.2-0.21c0.33-0.02,0.22,0.1,0.54-0.48c0,0.11,0.13,0.04,0.04,0.23c0.16-0.71,0.07,0.01-0.44-2.09
			c0.02,0,0.03-0.02,0.04,0.01c-0.32-0.33,0.01-0.02-0.36-0.65l0.03,0.02c0-0.14-0.23-0.19-0.32-0.35c0,0,0.02,0,0.03-0.02
			c-0.16-0.05-0.32-0.24-0.43-0.4c0.04-0.01,0.07-0.02,0.09-0.02c-0.07,0.01-0.22-0.01-0.05-0.29c0.27,0.19-0.06-0.31-0.24-1.25
			c0.07,0.11,0.09,0.3,0.15,0.4c0-0.08,0.02-0.26-0.03-0.32c0,0.04,0.03,0.08,0,0.11c-0.09-0.12-0.1-0.27-0.03-0.44
			c0.16,0.31,0.59-0.82,1.27-0.62c-0.32-0.26,0.21,0.03,0.05-0.22c0.17,0.09,0.24-0.02,0.38,0.31c-0.31-0.17,0.24-0.06-0.15-0.25
			c0.17,0.02,0.36,0.05,0.48,0.29c0.04-0.22,0.1-0.02,0.15-0.2c-0.08-0.01-0.12-0.2-0.1-0.38c-0.03,0.08-0.06,0.15-0.09,0.21
			c-0.14-0.48,0.15-0.32-0.02-0.83l0.09,0.15c-0.07-0.34-0.21-0.74-0.43-0.8c0.01-0.04,0.05-0.1,0.09-0.04
			c-0.15-0.37-0.59-0.61-0.7-0.88l0.06-0.01c-0.19-0.11-0.01-0.11-0.37-0.31l0.1,0.01c-1.23-1.31-1.16-0.12-0.56-3.63
			c-0.63-0.25-0.33-0.15-1.41-0.66c0.01-0.02,0.03-0.06,0.08-0.04c-0.13-0.06-0.53-0.26-0.52-0.05c0.04-0.13-0.34-0.08-0.46-0.04
			l0.03-0.1c0.01,0.03,0.01,0.05-0.38,0.16c0.2-0.18-0.52,0.21,0-0.28c-0.06,0.07-0.12,0.14-0.12,0.13c0.17-0.07,0.22-0.1,0.43-0.15
			c0,0.06-0.26-0.08,0.02-0.14c-0.08-0.06,0-0.13-0.12-0.11c0.03-0.01-0.02,0.01-0.02,0.06l-0.01-0.01
			c-0.02,0.02-0.03,0.04-0.06,0.07c-0.03-0.04-0.04-0.09,0.04-0.1c-0.04-0.05-0.05-0.14-0.06-0.17c0.39-0.01,0.36,0.03,0.87-0.85
			c0,0.05-0.04,0.09-0.03,0.12c0.05-0.15,0.19-0.42,0.08-0.5c0.12-0.16,0.09,0.15,0.14-0.12l0.03,0.05c0.1-0.26,0.17-0.26-0.35-1.41
			c-0.05,0.43,0.07,0.04-2.97-0.52L1443.99,193.48z M1442.58,199.69c0.03-0.01,0.04-0.05,0.07,0.03
			C1442.65,199.7,1442.62,199.71,1442.58,199.69z M1443.2,200.06c0,0.02-0.04,0.01-0.03-0.04
			C1443.18,200.04,1443.19,200.05,1443.2,200.06z M1437.31,215.2c0.01-0.02-0.02-0.04-0.04-0.07
			C1437.31,215.14,1437.34,215.13,1437.31,215.2z M1437.51,215.81c-0.01,0.01-0.04-0.01,0.01-0.05
			C1437.51,215.78,1437.51,215.8,1437.51,215.81z M1437.58,215.64c-0.02,0.02-0.03,0.02-0.04,0.02c0,0,0.01,0,0-0.01
			c0,0.01,0,0.03-0.07,0.04c0.02-0.05,0.04-0.09,0.07-0.04c0.01-0.06,0.03-0.13,0.03-0.16c0.02,0.08,0.05,0.14,0.08,0.19
			C1437.62,215.67,1437.6,215.67,1437.58,215.64z M1438.53,215.58c0-0.01-0.04,0.05-0.06-0.03
			C1438.48,215.56,1438.5,215.57,1438.53,215.58z M1438.4,215.63c-0.04,0.02-0.08,0.03-0.12,0.04
			C1438.31,215.65,1438.36,215.64,1438.4,215.63z M1445.11,197.36c0.03-0.04,0,0,0.11-0.15
			C1445.3,197.22,1445.35,197.18,1445.11,197.36z M1445.37,197L1445.37,197c0.02-0.04,0.05-0.07,0.06-0.09
			C1445.42,196.93,1445.4,196.96,1445.37,197z M1446.32,196.96c0.02,0-0.06,0.03-0.04-0.03
			C1446.29,196.94,1446.31,196.95,1446.32,196.96z"
              />
              <path
                className="st10"
                d="M1419.6,222.72c0.01,0.02-0.23-0.03-0.25,0.13c0.2-0.21-0.16,0.21,0.42,0.07l-0.08,0.13l0.15-0.09
			c0.02,0.04,0.02,0.13-0.05,0.13c0.06,0.08,0.01,0.08,0.22,0.06c-0.02,0.03-0.01,0.1-0.05,0.07c-0.03,0.02,0.41-0.14,0.97,0.21
			c0.05-0.07-0.08-0.08,0.04-0.11c-0.1,0.21,0.28-0.06,0.16,0.22c0.17-0.19-0.02,0.17,0.58,0.03c-0.03,0.06,0.02-0.03,0.34,0.21
			c0.05-0.12,0.17-0.07,0.27-0.08c-0.02,0.07-0.04,0.1-0.1,0.13c0.01-0.01,0.03,0.05,0.2-0.04l-0.04,0.09
			c0.12,0.02,0.25-0.07,0.39,0.02c0.02-0.09-0.07-0.27,0.09-0.3c0,0.05-0.01,0.13-0.06,0.14c0.01,0,0.06-0.03,0.08-0.01l-0.08,0.07
			c0.04-0.03,0.21,0.03,0.05,0.05c0.06,0.02,0.13,0.08,0.14,0.13c0.01-0.16,0.15,0.18,0.54-0.05l0,0c0.16-0.12,0.48,0.23,1.23,0.25
			c-0.1,0.05,0.02,0.06-0.1,0.03c0.11,0.05,0.13,0.11,0.27,0.03c-0.06,0.08,0.07,0.12,0.13,0.11l-0.07-0.07
			c0.32,0.15,0.03,0.12,0.74,0.22c-0.22-0.41,0.66,0.01,0.55,0.05l0.02-0.04c0.05,0.05,0.2,0.06,0.16,0.13
			c0.09-0.03,0.04-0.06-0.02-0.09c0.13,0.08,0.22,0.03,0.36,0.09c-0.07-0.04,0.48-0.29,0.31,0.11c0.14-0.07,0.24,0.08,0.32,0.1
			l-0.07,0.03c0.04-0.02,0.08-0.01,0.11-0.01l-0.03-0.09c0.16,0.1,0.04,0.09,0.32-0.06c-0.2-0.11-0.12,0.42,0.2,0.06
			c-0.03,0.06,0.03,0.12,0.06,0.11c-0.05,0.02-0.03-0.14,0.22-0.16l-0.06-0.03c0.03-0.06,0.08-0.05,0.16-0.06
			c0,0.05-0.07,0.03-0.05,0.05c0.13-0.15,0.21,0.2,0.63,0.26c-0.03-0.09-0.04-0.07,0.06-0.14l-0.23,0.04l0.16-0.11
			c-0.07-0.01-0.15,0.04-0.19,0.02c0.08-0.06,0.07-0.04,0.04-0.11c0.41,0.02,0.03,0.42,0.82,0.21c-0.07-0.06-0.15-0.07,0.15-0.09
			c0,0.07,0.06,0.09,0.1,0.12c-0.02-0.04,0.21-0.05,0.18-0.19c0.31-0.01,0.21,0.09,0.4-0.43c0.03,0.09,0.13,0.03,0.08,0.2
			c0.05-0.07,0.1-0.12,0.05-0.13c0.05,0.01-0.04-0.13,0.01-0.12c-0.02-0.04-0.07-0.11-0.07-0.15c0.01,0.01,0.02,0.03,0.02,0.03
			c-0.02-0.02,0-0.03-0.09-0.16c0.21-0.01,0.03,0.12,0.53,0.21c-0.01,0.02-0.01,0.03-0.03,0.02c0.38,0.04,0,0.06,0.54,0.24
			l-0.03,0.01c0.08,0.1,0.25-0.04,0.4,0c0,0-0.01,0.02,0,0.03c0.12-0.1,0.32-0.13,0.46-0.17c0,0.03,0,0.04,0,0.06
			c0.02-0.06,0.09-0.12,0.25,0.07c-0.35,0.28-0.16,0.15,0.91-0.7c0.04,0.12-0.04,0.29-0.09,0.4c0.07-0.05,0.2-0.21,0.17-0.29
			c-0.01,0.03-0.01,0.09-0.05,0.1c0.28-0.62,0.34,0.01,0.39-1.52c0.06,0.03,0.12,0.06,0.14,0.07c-0.16-0.06-0.19-0.21,0.16,0.06
			c-0.01-0.02,0-0.02,0.01-0.09c-0.06,0.08,0.38,0,0.99-0.06l-0.05,0.06c0.8-0.28,0.48,0.34,2.71-0.37
			c-0.11-0.08-0.05-0.13,0.09-0.34c0.01,0.15,0.36-0.42-0.03-0.47c0.08,0.16-0.17-0.67-1.1-1.39l0.07-0.05
			c-0.08-0.06-0.26,0.23-0.48-0.1c0.03,0,0.09-0.02,0.1-0.01c-0.02-0.02,0.12-0.04-0.23-0.06c0.23,0.02-0.01-0.04-0.14-0.15
			l0.19,0.07c-0.25-0.11-0.33-0.15-0.38-0.27c0.03-0.01,0.16,0.07,0.1,0.06c0.19,0.08-0.19-0.3,0.29,0.02
			c-0.1-0.09,0.09-0.04,0.25,0.03c-0.07-0.01-0.13-0.02-0.13-0.02c0.07,0.09,0.1,0.11,0.16,0.24c-0.01-0.14,0.03-0.14,0.25-0.1
			c-0.03-0.02-0.07-0.02,0.04-0.04c0,0.02-0.01,0.03-0.01,0.05c0.04,0.01,0.07,0.01,0.13,0.02c-0.01-0.1,0.07-0.08-0.01-0.16
			c0.02,0.01-0.03,0-0.08,0.03l0-0.01c-0.04,0-0.09,0.01-0.15,0.01c0.05-0.05,0.1-0.09,0.16-0.04c0.03-0.06,0.06-0.13,0.08-0.16
			c0.29,0.22,0.15,0.48,0.89,0.06c-0.03,0.02-0.06,0.03-0.07,0.06c0.1-0.06,0.3-0.07,0.3-0.21c0.14,0.03-0.05,0.13,0.12,0.07
			l-0.02,0.04c0.28,0.06,0.11-0.16,1.09-0.42c-0.05,0-0.07,0.01-0.1-0.01c0.25-0.05,0.47-0.16,0.65-0.26
			c-0.31-0.16-0.68-0.92-2.55-1.37c0-0.01,0.01-0.01,0.01-0.04l-0.03,0.05c0.01,0,0.01,0,0.01,0c-0.09,0.09,0.05,0.02,0.16,0.49
			l-0.1-0.03c0.03,0.09,0.09,0.04,0.14,0.01c0.03,0.08-0.05,0.16,0.19-0.01c-0.12,0.34,0.2-0.09,0.09,0.17
			c0.08-0.02,0.16,0.04,0.22-0.02c0.02,0.11,0.2,0.07,0.12,0.21c0.03-0.03,0.15-0.06,0.2-0.03c-0.18,0.11,0.18,0.12,0.05,0.27
			c0.16-0.1,0.1-0.02,0.24-0.04c-0.02,0-0.05,0.01-0.04,0.01c0.01,0.02-0.05,0.05-0.08,0.06c0.17-0.04,0.25-0.02,0.42,0
			c-0.18,0.04-0.27,0-0.45,0.02c0.16-0.02,0.2,0.02,0.36,0.02c-0.12,0.01-0.05,0.02-0.25,0c0.32,0.04-0.04-0.01,0.27,0.06
			c-0.2-0.01-0.31-0.06-0.24-0.02c-0.05-0.01-0.1-0.03-0.14-0.04c0.18,0.12,0.1-0.02,0.04,0.24c-0.01-0.01-0.01-0.02-0.02-0.03
			c-0.03,0.02,0.18,0.17-0.02,0.19c0.03-0.01,0.03-0.04,0.03-0.07l-0.04,0.05c0.19-0.25-0.28-0.13-0.06-0.11
			c-0.03-0.58-0.51,0.28-1.82,0.22c0.74,0.23-1.67-1.04-1.37,0.69l-0.05-0.04c0.01,0.17,0.31,0.19,0.29,0.32l-0.04-0.02
			c0.08,0.21-0.09,0.17,1.64,1.21l-0.06,0.12c0.18-0.03-0.08,0.04,0.24,0.28c-0.04,0.01-0.06-0.01-0.09-0.01
			c0.18,0.02-0.14,0.05,0.09,0.05c-0.07,0.03-0.07,0.02-0.06,0.06c-0.1-0.04-0.01-0.06-0.26,0.07c0.06,0.03,0.07,0.15,0.02,0.17
			l-0.04-0.11c-0.02,0.07-0.03,0.25-0.02-0.03l-0.08,0.05c-0.01-0.03,0.01-0.05,0.02-0.1c-0.47,0.03,0-0.08-1.42,0.23
			c-0.01-0.09-0.18-0.08-0.09-0.15c-0.2,0.02-0.26,0.02-0.51,0.09c0.06,0.06,0.02,0.14-0.04-0.01c-0.23,0.1-0.11-0.14-1.15-0.07
			l0.02-0.01c-2.29-0.19,1.29,1.07-2.05,2.1c0.02-0.02,0.03-0.06,0.06-0.05c-0.04-0.09-0.23-0.07-0.26,0
			c-0.4-0.27-0.08-0.17-1.17,0.11c0-0.06-0.07,0.05-0.08-0.02c-0.26,0.33-0.09,0.19-0.91,0.5c0.24-0.32,0.55-0.1-0.96-0.13
			c0-0.01,0.03-0.03,0.05-0.04c-0.12,0.07,0.03-0.01-0.26,0.28c0.09-0.2-0.21-0.07-0.07-0.32c-0.03,0.01-0.07,0.04-0.07,0.09
			c-0.04-0.02-0.05-0.05,0-0.1c-0.15,0.02-0.11,0.1-0.24,0.09c0-0.03,0.03-0.04,0.05-0.08c-0.08,0.06-0.15,0.12-0.26,0.08
			c0.01-0.02,0-0.05,0.03-0.04c-0.2-0.01-0.36,0.14-0.57,0.05c0.08-0.14-0.07-0.06-0.06-0.19l-0.12,0.02l0.04,0.07
			c-0.1-0.01-0.2,0.07-0.29,0.02l0.08-0.04l-0.19,0l0.1,0.06c-0.21,0.07,0.01,0.23-0.45-0.13c-0.06,0.1-0.24,0-0.32,0.1
			c0.02-0.02,0.04-0.09,0.03-0.11l-0.05,0.08c-0.04-0.03-0.05-0.05-0.03-0.1c-0.14-0.04,0.02,0.17-0.14,0.08
			c0.01-0.01,0.01-0.02,0.04-0.03c-0.2-0.01-0.27-0.05-0.32-0.09l0.06-0.01l-0.05-0.06l-0.03,0.06c-0.15-0.18-0.11,0.21-1.48-0.15
			l0.04-0.04c-0.01,0.02-0.2-0.2-0.38,0c-0.24-0.32-0.58,0.05-0.8-0.23c0.09,0.02-0.15-0.16-0.28,0.15
			c0.11-0.03,0.02-0.13-0.01-0.18c0,0.03-0.02,0.05-0.02,0.08c-0.05-0.21-0.08,0.1-0.17-0.01c0.03-0.01-0.33,0.08-0.1-0.08
			c-0.09-0.01-0.21,0.14-0.27,0.03c0.04-0.02,0.1-0.01,0.14-0.03c-0.07-0.13-0.08-0.1-0.14-0.13l0.01,0l-0.14-0.08
			c-0.05,0.29,0,0.02-0.38,0.16c0.15-0.11,0.07-0.05,0.01-0.29c-0.09,0.07-0.1-0.09-0.23,0.01c0-0.02-0.02-0.03-0.03-0.05
			c0.02,0.07-0.28,0.04-1.13-0.06c0.11-0.19-0.29,0.06-0.06-0.17c-0.09,0.01-0.18,0.06-0.27,0.07
			C1419.49,222.77,1419.57,222.75,1419.6,222.72z M1436.65,218.82c-0.02,0,0,0-0.08,0C1436.6,218.82,1436.63,218.82,1436.65,218.82z
			 M1436.35,218.88c0.01-0.02-0.08-0.05-0.15-0.08C1436.18,218.82,1436.41,218.76,1436.35,218.88z"
              />
              <path
                className="st10"
                d="M1418.37,223.67l0,0.09l-0.1-0.05c0.02,0.1,0.08,0.06,0.14,0.04c0.02,0.09-0.08,0.15,0.19,0.03
			c-0.17,0.31,0.22-0.06,0.07,0.19c0.08-0.01,0.16,0.07,0.23,0.01c0.01,0.11,0.2,0.09,0.12,0.22c0.04-0.02,0.16-0.05,0.21-0.02
			c-0.19,0.1,0.19,0.12,0.06,0.28c0.15-0.1,0.29,0.08,0.46,0.08c-0.01,0.01-0.04,0.02-0.05,0.01c0.06,0.05,0.06,0.11,0.09,0.16
			c0.08-0.11,0.2-0.08,0.3-0.12c0.02,0.16-0.13,0.11-0.17,0.23c0.05-0.1,0.2-0.01,0.22-0.11c0.02,0.11,0.04,0.07,0.01,0.18
			c0.1-0.14,0.08,0.01,0.18-0.05c-0.02,0.09,0.2,0.14-0.03,0.24c0.2-0.22,0.24,0.33,1.02,0.13c-0.03-0.02-0.05,0.01-0.07,0.02
			l0.07,0c-0.33,0.03,0.11,0.29-0.04,0.12c-0.39,0.42,0.56,0.26,1.61,0.94c-0.26-0.19,0.1,0.28,1.37,0.88l-0.04,0.01
			c0.1,0.11,0.24-0.1,0.3,0.04l-0.03,0c-0.01,0.19,0.74,0.42,2.05,1.3c-0.01,0.01-0.02,0.01-0.04,0.02c0.01,0.03-0.08,0.13,0.1,0.04
			c0.02,0.09,0.15,0.17,0.26,0.2l-0.06,0.05c0.16,0.12,0.39,0.19,0.58,0.3c-0.11,0.03,0.18-0.29,0.06-0.04
			c0.06-0.04,0.18-0.18,0,0.05l0.11,0.02c-0.01,0.03-0.04,0.04-0.07,0.07c0.44,0.22-0.03,0.09,1.34,0.53
			c-0.04,0.08,0.11,0.16,0,0.17c0.18,0.09,0.23,0.11,0.48,0.17c-0.02-0.08,0.06-0.13,0.03,0.03c0.31,0.02,0.43,0.55,0.72,0.49
			l-0.02,0c0.57,0.24,0.05-0.03,1.39,0.86c0.03-0.04,0.04-0.13,0.09,0.03c-0.14-0.54,0.75,0.64,2.55,1.1
			c-0.03,0.02-0.04,0.05-0.08,0.03c0.44,0.15,0.08-0.05,1.25,0.38c-0.07,0.14,0.17,0.2,0.27,0.16c-0.04,0.04,0.05,0.07,0,0.12
			c0.09,0.03,0.18,0.03,0.2-0.05c0.22,0.25,0.12,0.18,1.2,0.33c-0.32,0.24-0.57-0.04,0.89,0.35c-0.01,0.01-0.03,0.02-0.06,0.03
			c0.13-0.04-0.03,0,0.31-0.21c-0.14,0.17,0.18,0.12-0.01,0.33c0.03,0,0.07-0.02,0.08-0.07c0.06,0.09-0.04,0.12,0.44,0.12
			c-0.01,0.02-0.01,0.05-0.04,0.04c0.19,0.06,0.38-0.06,0.56,0.08c-0.11,0.12,0.05,0.07,0.02,0.2l0.12,0l-0.02-0.08
			c0.09,0.04,0.21-0.02,0.28,0.05l-0.09,0.03l0.19,0.04l-0.08-0.08c0.22-0.03,0.04-0.22,0.4,0.22c0.08-0.09,0.23,0.05,0.33-0.04
			c-0.03,0.02-0.05,0.08-0.05,0.1l0.07-0.07c0.04,0.04,0.04,0.06,0.01,0.11c0.13,0.06,0.01-0.17,0.15-0.05
			c-0.01,0-0.01,0.02-0.04,0.02c0.19,0.05,0.25,0.1,0.29,0.14l-0.06,0l0.04,0.07l0.04-0.05c0.12,0.2,0.13-0.17,1.42,0.38l-0.04,0.04
			c0.01-0.02,0.17,0.22,0.38,0.05c0.2,0.34,0.57,0.02,0.76,0.31c-0.08-0.03,0.13,0.17,0.29-0.13c-0.11,0.02-0.03,0.13-0.01,0.18
			c0-0.03,0.02-0.05,0.02-0.07c0.03,0.22,0.08-0.09,0.17,0.02c-0.03,0.01,0.33-0.05,0.09,0.08c0.09,0.02,0.22-0.13,0.27-0.02
			c-0.04,0.02-0.1,0-0.14,0.02c0.06,0.13,0.07,0.1,0.13,0.14l-0.01,0l0.13,0.09c0.07-0.28,0-0.02,0.39-0.14
			c-0.15,0.1-0.08,0.05-0.03,0.29c0.09-0.07,0.1,0.09,0.23,0c0,0.02,0.02,0.03,0.03,0.05c-0.01-0.07,0.28-0.03,1.12,0.07
			c-0.1,0.19,0.29-0.06,0.06,0.17c0.08-0.01,0.17-0.06,0.26-0.07c0.01,0.06-0.07,0.08-0.1,0.11c-0.01-0.02,0.22,0.03,0.24-0.13
			c-0.19,0.21,0.15-0.21-0.42-0.06l0.08-0.13l-0.14,0.09c-0.02-0.04-0.02-0.13,0.05-0.13c-0.06-0.07-0.01-0.08-0.22-0.06
			c0.02-0.03,0.01-0.1,0.05-0.07c-0.09,0.04-0.64,0.12-0.68-0.03c-0.08-0.12-0.28-0.02-0.26-0.19c-0.06,0.07,0.08,0.08-0.04,0.11
			c0.1-0.2-0.28,0.05-0.14-0.22c-0.18,0.18,0.01-0.18-0.56-0.05c0.03-0.06-0.03,0.03-0.32-0.23c-0.06,0.12-0.17,0.06-0.27,0.06
			c0.02-0.07,0.05-0.1,0.11-0.12c-0.01,0.01-0.03-0.05-0.2,0.02l0.05-0.09c-0.11-0.03-0.25,0.05-0.38-0.06
			c-0.03,0.08,0.04,0.27-0.12,0.29c0.01-0.05,0.03-0.13,0.07-0.14c-0.01-0.01-0.16,0.03,0.01-0.06c-0.05,0.03-0.2-0.05-0.04-0.06
			c-0.05-0.03-0.11-0.09-0.12-0.15c-0.03,0.16-0.13-0.19-0.53-0.01l0,0c-0.17,0.09-0.44-0.29-1.15-0.42c0.1-0.03-0.01-0.06,0.1-0.01
			c-0.1-0.06-0.11-0.12-0.25-0.07c0.07-0.07-0.05-0.13-0.11-0.13l0.06,0.09c-0.28-0.2,0-0.12-0.66-0.35
			c0.13,0.45-0.63-0.14-0.52-0.16l-0.03,0.03c-0.04-0.06-0.18-0.1-0.12-0.16c-0.09,0.01-0.06,0.05-0.01,0.09
			c-0.11-0.1-0.2-0.07-0.32-0.16c0.04,0.07-0.54,0.15-0.27-0.17c-0.15,0.03-0.21-0.13-0.28-0.17l0.08-0.01
			c-0.05,0-0.08-0.01-0.11-0.02l0.01,0.09c-0.13-0.13-0.01-0.1-0.32-0.01c0.2,0.1,0.17-0.38-0.18-0.1c0.04-0.05,0-0.12-0.03-0.12
			c0.05,0,0,0.15-0.25,0.1c0.04,0.05,0.1,0.08-0.11,0.06c0.01-0.05,0.08-0.01,0.06-0.04c-0.2,0.17-0.3-0.63-0.63-0.28l0.23,0.02
			l-0.18,0.07c0.06,0.02,0.15,0,0.19,0.02c-0.09,0.04-0.07,0.02-0.07,0.1c-0.42-0.08,0.13-0.35-0.69-0.41
			c0.03,0.07,0.09,0.11-0.15,0.03c0.03-0.06,0-0.1-0.02-0.15c-0.01,0.05-0.14-0.05-0.2,0.07c-0.13-0.16-0.03-0.19-0.45-0.07
			c0.07-0.05,0.02-0.12,0.14-0.14c-0.17,0,0.04,0-0.49,0.08c-0.06-0.19-0.12-0.21-0.86-0.48c0.01-0.02,0.01-0.03,0.03-0.02
			c-0.35-0.07,0-0.04-0.46-0.28l0.03,0c-0.05-0.11-0.23-0.01-0.35-0.08c0,0,0.01-0.02,0.01-0.03c-0.14,0.07-0.32,0.03-0.45,0
			c0.02-0.03,0.03-0.05,0.04-0.07c-0.01-0.17-0.19-0.43-1.14-0.46c0.11-0.06,0.26-0.05,0.36-0.06c-0.06-0.03-0.21-0.09-0.27-0.05
			c0.09,0.04,0.11,0.05-0.27,0.01c0.05-0.26,0.06-0.14-0.92-0.82c-0.05,0.18-0.15,0.18-0.22,0.1c0.04-0.03,0.15-0.07,0.09-0.16
			c0.03,0.09-0.09,0.06-0.12,0.11c-0.05-0.09-0.09-0.22-0.07-0.4c-0.02,0.08-0.1,0.07-0.15,0.01l0.05,0.14
			c-0.23,0.01-0.06-0.24-0.29-0.24l0.09-0.03c-0.11-0.01-0.33-0.18-0.5-0.08c0-0.03,0-0.09,0.05-0.08
			c-0.11-0.16-0.25-0.12-0.94-0.44l0.07-0.03c-0.72-0.15-0.26-0.4-2.02-1.23c-0.29,0.04-0.11-0.08-1.33-0.63
			c0.02-0.19,0.07-0.07-0.34-0.33l0.07-0.04c-0.41,0.03-0.15,0.1-0.86-0.33c0.01-0.02,0.04-0.05,0.07-0.04
			c-0.08-0.05-0.27-0.28-0.34-0.08c0.06-0.12-0.18-0.12-0.3-0.12l0.06-0.08c-0.01,0.03-0.14,0.02-0.45-0.06l0.07-0.03
			c-0.26-0.17-0.13-0.08-0.76-0.47c0.12,0.07,0.22,0.14,0.2,0.14c0.18-0.12-0.2-0.43-0.64-0.36c-0.01-0.01,0.02,0.01,0.08,0.01
			l-0.01,0.01c0.03,0.02,0.08,0.04,0.13,0.07c-0.06,0.02-0.13,0.03-0.15-0.05c-0.06,0.04-0.12,0.08-0.15,0.1
			c-0.15-0.07-0.44-0.53-1.73-1.16c0.01,0.03,0.02,0.04,0.01,0.06C1420.91,224.62,1419.58,223.38,1418.37,223.67z M1422.99,225.6
			c0.01-0.01,0,0,0.04-0.04C1423.06,225.57,1423.1,225.65,1422.99,225.6z M1423.08,226.3c-0.02,0.01,0.02,0.06,0.03,0.1
			C1423.14,226.4,1422.96,226.36,1423.08,226.3z M1422.91,226.29c-0.02-0.01,0,0-0.05-0.02
			C1422.87,226.27,1422.88,226.27,1422.91,226.29z"
              />
              <path
                className="st10"
                d="M1426.88,231.18c0.02-0.01,0.06-0.02,0.07,0c-0.01-0.05,0.03-0.21-0.15-0.12c0.12-0.05-0.02-0.1-0.1-0.12
			c0.12-0.06,0.16,0.03-0.16-0.28c-0.06,0.02-0.11-0.05-0.12-0.19c0.03,0.05,0.06,0.1,0.05,0.1c0.09,0.03,0.04-0.41-0.2-0.25
			c0-0.01,0,0.01,0.04,0.01l-0.01,0c0.01,0.01,0.03,0.03,0.04,0.04c-0.05,0.02-0.1,0.03-0.07-0.03c-0.06,0.03-0.12,0.06-0.14,0.07
			c-0.02-0.05,0.16-0.31-0.27-0.36c0.13-0.36,0.11-0.11-0.26-0.33c0,0.02,0,0.03-0.02,0.05c-0.04-0.06-0.05-0.3-0.51-0.35l0.05-0.09
			c-0.04,0.34-0.14-0.34-0.64-0.15c0.01,0,0.01,0,0.01,0c-0.05,0.09-0.11,0.21-0.21,0.4l0.1-0.06l-0.03,0.08l-0.04-0.02
			c-0.02,0.08,0.03,0.03,0.07,0c-0.02,0.04-0.03,0.07-0.03,0.08l0.13-0.1c-0.03,0.06-0.09,0.09-0.08,0.14
			c0.06-0.05,0.06-0.13,0.12-0.15c-0.02,0.08-0.06,0.12,0,0.08c-0.02,0.04-0.04,0.06-0.06,0.07c0.05-0.03,0.07,0.02,0.13-0.04
			c-0.03,0.09,0.08,0.03,0,0.15c0.03-0.03,0.1-0.07,0.13-0.07c-0.14,0.13,0.06,0.06-0.06,0.21c0.12-0.12,0.13,0,0.23-0.04
			c-0.01,0.01-0.03,0.03-0.03,0.02c0.02,0.03-0.01,0.07-0.01,0.11c0.08-0.11,0.14-0.1,0.21-0.16c-0.05,0.13-0.11,0.11-0.18,0.22
			c0.06-0.09,0.12-0.05,0.16-0.13c-0.03,0.08,0,0.05-0.06,0.15c0.11-0.13,0.04-0.01,0.12-0.08c-0.02,0.09-0.09,0.13-0.02,0.12
			c-0.13,0.21,0.04-0.02,0.32,0.07c-0.02,0.06-0.03,0.04,0.13-0.08c-0.01-0.01-0.03,0.01-0.05,0.03l0.04-0.01
			c-0.07,0.1-0.05,0.01-0.11,0.08c0.04,0,0.25,0.01,0.04,0.09l-0.01-0.07c0,0.16-0.03,0.25,0.47,0.5c-0.03,0.02-0.07,0.03-0.09,0.07
			c-0.04-0.09,0.59,0.83,0.35,1.04l0.11,0.04l-0.13,0c0.14,0.07-0.16-0.04,0.04,0.18c-0.04-0.01-0.05-0.03-0.08-0.05
			c0.15,0.07-0.15,0,0.06,0.07c-0.07,0-0.08,0.04-0.07,0.09c-0.08-0.01-0.08-0.07-0.11,0.16c0.04-0.01,0.15,0.06,0.16,0.08
			l-0.1-0.05c0.06,0.05,0.05,0,0.11,0.07c-0.03,0.02-0.12-0.05-0.15-0.08l0.02,0.05c-0.03-0.02-0.04-0.03-0.08-0.05
			c0.05,0.05-0.13-0.01-0.03,0.06c0.09,0.29-1.82-0.04-0.61,1.92c-0.03,0.01-0.05,0.04-0.06,0.02c-0.04,0.08,0.06,0.08,0.12,0.04
			c-0.07,0.04-0.03,0.12-0.03,0.14c0.04-0.01,0.04,0.07,0.11,0c-0.01,0.07,0.09,0.05,0.07,0.12c0.1-0.04,0.01,0.1,0.14,0.02
			c-0.13,0.09-0.05,0.14,0.03,0.13c-0.05,0.02-0.03,0.05-0.09,0.08c0.02,0.03,0.06,0.04,0.13-0.01c-0.12,0.16,0.2,0.03,0.02,0.19
			c0.13,0.04,0.23,0.1,0.31,0.16c-0.11,0.11-0.1,0.03,0.13,0.33c-0.01,0.01-0.03,0.01-0.05,0c0.01,0.01-0.01,0.04,0.06,0.01l-0.02,0
			c0.08-0.07,0.22-0.04,0.27-0.06c-0.2,0.06-0.02,0.09-0.27,0.15c0.02,0,0.05,0,0.1-0.02c-0.07,0.1-0.01,0.01-0.01,0.3
			c0.01-0.01-0.01,0.05,0.1,0.02c-0.04-0.02,0.11,0.12-0.01,0.05c0.05,0.08,0.01,0.03,0.18,0.1c-0.01-0.01,0,0-0.09,0.02
			c0.02,0.05,0.07,0.12-0.13,0c0.09,0.06,0,0.07,0.08,0.15c-0.02-0.02-0.07-0.06-0.09-0.07l0.03,0.03
			c-0.06-0.02-0.06,0.25-0.19,0.03c-0.03,0.05-0.03,0.21-0.12,0.21c0.11,0.19,0,0.01-0.07,0.04c0-0.02-0.02-0.1-0.02-0.08
			c-0.05-0.01-0.08,0.08-0.15,0.15c0.02,0-0.15-0.16-0.12,0.04c-0.08-0.13-0.19-0.27-0.4,0.12c0.04-0.05,0.01-0.13,0-0.18
			l-0.01,0.08c-0.01-0.21-0.06,0.14-0.07,0.02c-0.03,0.02-0.13,0.08-0.04-0.06c-0.11,0.02,0.02,0.01-0.16-0.13
			c-0.04,0.3,0,0.02-0.16,0.22c0.06-0.15,0.04,0,0.02-0.29c-0.04,0.08-0.04-0.07-0.1,0.05c0-0.03-0.03-0.01-0.02-0.09
			c0.23,0.05-0.32,0.31-0.52,0.06c-0.03,0.03-0.06,0.1-0.1,0.13c-0.02-0.06,0.01-0.09,0.02-0.13c0,0.02-0.08,0.03-0.07,0.17
			c0.05-0.03,0.19-0.04,0.16,0.13c-0.02-0.02,0.13-0.06,0.12,0.11c0.11,0.05,0.14,0.05,0.19-0.22l0.02,0.27l0.02-0.11
			c0.01,0,0.02,0.01,0.02,0.06c0.04-0.07,0-0.05,0.02-0.11c0.03,0.1,0.12-0.03,0.11,0.14c0.02-0.08-0.03-0.07,0.02-0.12
			c-0.04,0.21,0.12-0.08,0.06,0.19c0.11-0.15,0.1,0.15,0.23-0.06c-0.03,0.05,0.03-0.02,0.13,0.15c0.02-0.13,0.07-0.1,0.11-0.12
			c-0.01,0.08-0.02,0.11-0.04,0.14c0.04-0.01,0.03,0,0.09-0.07l-0.02,0.1c0.05,0,0.1-0.11,0.17-0.04c0-0.09-0.05-0.25,0.01-0.31
			c0.01,0.08-0.03,0.12,0.1,0.36c0-0.04,0.02-0.21,0.07,0.01c0.01-0.05,0.05,0,0.04-0.11c0.01,0,0.02,0.05,0.03,0.07
			c0.01-0.05,0.01,0.01,0.07-0.15l0,0c-0.12-0.2,0.26,0.09,0.62-0.15c-0.04,0.07,0.2,0.03,0.04,0c0.33-0.02,0.14-0.47,0.47-0.52
			l-0.04-0.02c0.05-0.02,0.06-0.11,0.12-0.08c-0.03-0.06-0.06-0.03-0.09,0c0.07-0.07,0.02-0.12,0.06-0.2
			c-0.09,0.02-0.28-0.23,0.06-0.18c-0.09-0.06,0.02-0.14,0.03-0.19l0.04,0.03c-0.03-0.02-0.03-0.04-0.03-0.05l-0.07,0.04
			c0.01-0.02,0-0.04,0.04-0.05c-0.06,0-0.13,0.05-0.21,0.04c0.03-0.04-0.07-0.06,0.03-0.1c-0.01,0.09,0.1-0.05,0.14,0.01
			c0.01-0.13,0.1-0.08-0.14-0.12c0.06-0.02,0.1-0.06,0.08-0.07c0.01,0.03-0.09,0.06-0.14,0.05c0.02-0.07-0.06-0.03-0.06-0.06
			c-0.03,0.04-0.01,0.06-0.1,0.01c0.04-0.02,0.04,0.01,0.06-0.01c-0.11,0.02,0.06-0.12-0.03-0.13c0.03-0.04,0.1-0.06,0.12-0.09
			c-0.13,0.03,0.09-0.17-0.18-0.06l0.1,0.05c-0.15,0.1-0.29-0.1-0.19-0.36c-0.04,0.03-0.03-0.06-0.15,0.01
			c0.25-0.17-0.36-0.04,0-0.18c-0.09-0.02,0.02,0-0.31-0.01c0.14-0.18-0.12-0.14-0.01-0.3c-0.24-0.14-0.08,0-0.5-0.37
			c0.11-0.01,0.06,0,0.16-0.02c-0.02-0.01-0.16,0.04-0.21,0.01c0.1-0.07,0.33-0.08-0.22-0.28c0.19,0.06,0.29,0.09,0.41,0.13
			c-0.01-0.01-0.04-0.03-0.17-0.06c0.02,0.02,0.04,0.03-0.28-0.11c0.5,0.15,0.05-0.03,0.46,0.1c-0.19-0.08,0.01,0,0.12,0.03
			c-0.19-0.07-0.27-0.11-0.27-0.11c0.07,0.03,0.2,0.08,0.28,0.11c-0.2-0.08-0.56-0.24,0.24,0.1c-0.09-0.05-0.12-0.05-0.05-0.03
			c-0.04-0.03,0,0-0.12-0.13c0.09,0.17,0.05-0.16,0.22-0.04c0.08-0.21,0.19,0.13,0.2-0.4c0.18,0.05,0.4,0.21,0.33-0.33
			c0.05,0.04,0.35-0.11,0.25-0.41l-0.01,0.01c0.08,0.01,0.28-0.11-0.04-0.15c0.3-0.06-0.04-0.32,0.2-0.36
			c-0.04-0.03-0.04-0.07-0.09-0.1c0.1-0.03,0.13,0.04,0.1-0.24l0.07,0.02C1426.92,231.47,1426.93,231.64,1426.88,231.18z
			 M1425.99,230.83c-0.01-0.03-0.03-0.04,0.04-0.06C1426.01,230.78,1426.01,230.81,1425.99,230.83z M1426.41,230.26
			c0.02,0,0.01,0.04-0.04,0.03C1426.39,230.28,1426.4,230.27,1426.41,230.26z"
              />
              <path
                className="st10"
                d="M1420.77,239.24c0.22,0.18,0.02,0.03,0.23-0.2c-0.02-0.01-0.03-0.01-0.04-0.03c0.04,0,0.33-0.04,0.32-0.39
			l0.09,0.05c-0.21-0.12,0.09-0.17,0.03-0.24c0.01-0.01,0.06-0.05,0.1-0.07c-0.07-0.14,0.06-0.02-0.1-0.25
			c0,0.01,0.01,0.01,0.02,0.01c-0.1-0.03-0.23-0.06-0.45-0.11l0.08,0.06c-0.02,0.01-0.18-0.1-0.05,0c-0.04-0.01-0.07-0.02-0.08-0.02
			l0.13,0.09c-0.06-0.02-0.11-0.06-0.15-0.06c0.06,0.04,0.14,0.04,0.17,0.09c-0.07-0.02-0.13-0.05-0.08,0
			c-0.04-0.02-0.06-0.03-0.08-0.04c0.04,0.03,0,0.04,0.08,0.09c-0.09-0.03,0,0.06-0.14-0.02c0.03,0.02,0.09,0.08,0.09,0.1
			c-0.14-0.12-0.04,0.04-0.2-0.08c0.13,0.11,0.02,0.09,0.08,0.18c-0.01-0.01-0.03-0.03-0.02-0.03c-0.02,0.01-0.06-0.02-0.1-0.03
			c0.1,0.09,0.11,0.13,0.16,0.2c-0.11-0.07-0.11-0.12-0.21-0.2c0.08,0.07,0.06,0.1,0.13,0.16c-0.07-0.04-0.04-0.02-0.13-0.09
			c0.12,0.12,0.01,0.03,0.07,0.12c-0.08-0.04-0.12-0.11-0.1-0.05c-0.19-0.17,0.03,0.04-0.05,0.24c-0.09-0.12,0.1,0.21,0.04,0.08
			l0.01,0.04c-0.08-0.09-0.01-0.05-0.06-0.12c0,0.04-0.03,0.21-0.07,0l0.05,0.02c-0.14-0.03-0.2-0.12-0.44,0.23
			c-0.01-0.03-0.02-0.07-0.04-0.1c0.08,0.07-0.37,0.17-0.93,0.15l-0.03,0.11l-0.02-0.13c-0.04,0.05,0.04,0.24-0.09-0.02l-0.08,0.09
			c0-0.04,0.01-0.06,0.02-0.09c-0.03,0.16-0.03-0.15-0.05,0.07c-0.02-0.07-0.06-0.07-0.1-0.04c-0.02-0.08,0.04-0.11-0.18-0.04
			c0.02,0.04,0.01,0.16-0.01,0.18l-0.01-0.11c-0.01,0.07,0.02,0.04-0.01,0.13c-0.03-0.01-0.01-0.13,0-0.17l-0.03,0.04
			c0-0.03,0.01-0.05,0.01-0.1c-0.01,0.07-0.05-0.12-0.07,0.01c0.27-0.29-0.84,0.25-1.32-0.07c0,0.01,0.01,0.1-0.01,0.07
			c0.03,0.15-0.3-0.18-0.87,0.06c0.01-0.03,0-0.06,0.02-0.06c-0.03-0.08-0.08,0-0.08,0.07c0.01-0.08-0.06-0.1-0.08-0.1
			c-0.01,0.04-0.07-0.01-0.05,0.09c-0.04-0.04-0.08,0.05-0.11-0.01c-0.02,0.11-0.07-0.05-0.07,0.1c0-0.16-0.08-0.12-0.1-0.05
			c0.01-0.05-0.02-0.05-0.02-0.12c-0.03,0-0.05,0.03-0.05,0.12c-0.06-0.19-0.1,0.16-0.14-0.08c-0.08,0.09-0.16,0.15-0.23,0.19
			c-0.05-0.14,0.03-0.11-0.3-0.02c0-0.01,0-0.03,0.01-0.04c-0.01,0.01-0.03-0.02-0.02,0.05l0.01-0.02c0.03,0.1-0.03,0.22-0.03,0.28
			c0.01-0.21-0.07-0.05-0.05-0.31c-0.01,0.01-0.02,0.05-0.01,0.1c-0.09-0.09,0-0.03-0.33-0.05c0.02,0.02-0.06-0.02-0.01,0.1
			c0.01-0.05-0.1,0.14-0.06,0l-0.06,0.03c0.03,0.02,0.06,0.28-0.04,0.1c-0.04,0.05-0.06,0.12-0.07-0.11c0,0.11-0.07,0.04-0.08,0.16
			c0-0.03,0-0.1-0.01-0.11l0,0.09c0.03-0.22-0.2,0.15-0.18-0.15c-0.07,0.03-0.12,0.17-0.19,0.15c0.05,0.21,0,0.01-0.07,0.02
			c0.01-0.01,0-0.1,0-0.08c-0.05-0.02-0.09,0.06-0.15,0.12c-0.01-0.05,0.02-0.07-0.1-0.08c0,0.05-0.02,0.04,0,0.11
			c-0.07-0.14-0.26-0.23-0.3,0.16c0.02-0.06-0.02-0.13-0.04-0.17l0.01,0.08c-0.05-0.21-0.01,0.15-0.05,0.03
			c-0.02,0.03-0.07,0.1-0.04-0.05c-0.07,0.04,0.02,0-0.15-0.09c0.05,0.3,0,0.01-0.06,0.25c0.01-0.16,0.03,0-0.06-0.28
			c-0.01,0.09-0.05-0.06-0.06,0.07c-0.01-0.03-0.02,0-0.03-0.08c0.21-0.02-0.22,0.39-0.35,0.14c-0.02,0.04-0.03,0.1-0.05,0.14
			c-0.02-0.06,0-0.09,0-0.13c0.01,0.02-0.05,0.03-0.03,0.17c0.03-0.03,0.13-0.06,0.13,0.11c0.02-0.04-0.03-0.27,0.09,0.06l0.02-0.05
			c0,0.03,0.02,0.1,0,0.08c0.1,0.03,0.12,0.02,0.1-0.24l0.08,0.25l-0.01-0.11c0.01,0,0.02,0.01,0.03,0.05
			c0.01-0.08-0.01-0.05-0.01-0.12c0.05,0.09,0.08-0.06,0.11,0.11c0-0.08-0.04-0.06-0.02-0.12c0.02,0.21,0.07-0.1,0.09,0.17
			c0-0.03,0-0.05,0-0.06c0,0.03,0.02,0.06,0.07,0.02c0.04,0.1-0.06,0.11,0.07-0.08c-0.01,0.05,0.03-0.02,0.13,0.12
			c-0.01-0.13,0.03-0.11,0.05-0.14c0.01,0.08,0.01,0.11,0,0.14c0.03-0.02,0.02,0,0.05-0.09l0.01,0.1c0.03-0.01,0.05-0.12,0.11-0.06
			c-0.01-0.09-0.07-0.24-0.03-0.31c0.02,0.08-0.02,0.05,0.11,0.35c0-0.04,0-0.21,0.05,0.01c0-0.05,0.03,0,0.02-0.11
			c0.01,0,0.02,0.05,0.03,0.07c0.01-0.05,0.01,0,0.04-0.14l0,0c0.05-0.14,0.36,0.15,0.91,0.15c0.02-0.04,0-0.07-0.02-0.09
			c0.05,0.06,0.07,0,0.11,0.03c-0.01-0.08,0.01-0.01,0.05-0.17c0.03,0.06,0.01,0.15,0.04,0.09c0.03,0.04,0.02,0.11,0.01,0.14
			c-0.01-0.09,0.1,0.02,0.08,0.09c0.01-0.02,0.02-0.02,0.03-0.02l-0.02-0.08c0.01,0.01,0.02,0.01,0.03,0.05
			c0.01-0.06-0.02-0.14,0-0.21c0.02,0.04,0.05-0.05,0.06,0.06c-0.05-0.03,0.01,0.11-0.03,0.13c0.09,0.06,0.03,0.11,0.09-0.1
			c0,0.06,0.02,0.12,0.03,0.1c-0.02,0-0.02-0.1,0-0.15c0.04,0.05,0.03-0.04,0.05-0.03c-0.02-0.05-0.03-0.04,0.02-0.1
			c0.01,0.05-0.02,0.03-0.01,0.06c0.02-0.11,0.07,0.11,0.09,0.03c0.02,0.04,0.01,0.12,0.03,0.14c0.05-0.09,0.09,0.09,0.09-0.13
			l-0.06,0.06c-0.03-0.18,0.21-0.2,0.29,0c0-0.05,0.05,0,0.04-0.14c0.02,0.3,0.16-0.28,0.11,0.09c0.05-0.06-0.01,0.02,0.12-0.25
			c0.06,0.21,0.14-0.02,0.19,0.16c0.03-0.02,0.08-0.05,0.11-0.06c-0.06-0.09,0.15,0.22,0.38-0.11c-0.01,0.09-0.02,0.05-0.01,0.14
			c0.02-0.01-0.01-0.15,0.03-0.17c-0.01,0.04,0.07,0.23,0.07,0.26c0.07,0,0.15-0.37,0.17-0.08c0.02-0.03,0.07-0.13,0.11-0.16
			c-0.02,0.1-0.06,0.16-0.09,0.22c0.15-0.19-0.04-0.11,0.56-0.11c0,0.04-0.04,0.15,0,0.2c-0.02-0.08,0.03-0.11,0.01-0.2
			c0.03,0,0.06,0.08,0.08,0.36c0-0.08,0.02-0.11,0.04-0.08c-0.05-0.02,0.11-0.1,0.06,0.04c0.03-0.04,0.11,0,0.15-0.17
			c-0.05,0.11,0.2,0.16,0.47-0.08c0.05,0.13,0.02,0.45,0.31,0.07c0.05,0.17,0.17,0.07,0.25,0.17c-0.01-0.03,0-0.12,0.02-0.14
			c0.02,0.03,0.05-0.11,0.05,0.03l-0.01,0c0.01,0.14,0.05,0.1,0.09,0.11l0-0.18c0.09,0.27,0.21-0.1,0.26,0.13
			c0.02-0.05,0.04-0.05,0.06-0.1c0.02,0.04-0.03,0.16,0.16,0.1c-0.04,0.33,0.2-0.03,0.37-0.16c0.02,0.13,0.07,0,0.1-0.07l0.01,0.1
			c0.01-0.13,0-0.16,0.03-0.21c0.01,0.01,0.03,0.07,0.01,0.05c0.05,0.1-0.03-0.01,0.18-0.01c-0.02-0.07,0.06-0.09,0.17-0.05
			c-0.05,0.01-0.08,0.02-0.08,0.01c-0.04,0.04,0.35,0.17,0.23-0.1c0.01,0.01-0.01,0-0.01,0.03l0-0.01
			c-0.01,0.01-0.03,0.02-0.04,0.02c0-0.05-0.01-0.1,0.03-0.05c-0.02-0.06-0.03-0.13-0.04-0.16c0.04,0,0.26,0.25,0.33-0.12
			C1420.76,239.2,1420.74,239.29,1420.77,239.24z M1420.05,239.02c0.01,0,0,0,0.03-0.01
			C1420.08,239.04,1420.12,239.13,1420.05,239.02z M1420.43,239.56c0.01,0.02,0.01,0.03,0.01,0.05
			C1420.44,239.62,1420.41,239.6,1420.43,239.56z"
              />
              <path
                className="st10"
                d="M1393.1,235.73c-0.05-0.09-0.22,0.03-1.06-0.25c0.01-0.01,0.03-0.02,0.05-0.03c-0.12,0.04,0.02,0-0.29,0.21
			c0.13-0.17-0.15-0.12,0.04-0.32c-0.03,0-0.07,0.02-0.08,0.07c-0.03-0.03-0.03-0.06,0.03-0.09c-0.14-0.01-0.12,0.08-0.23,0.03
			c0.01-0.03,0.04-0.04,0.07-0.07c-0.21-0.01-0.18-0.04-0.7-0.09c0.11-0.12-0.04-0.07,0-0.19l-0.11,0l0.01,0.07
			c-0.08-0.04-0.19,0.02-0.24-0.05l0.08-0.02l-0.16-0.04l0.07,0.08c-0.19,0.02-0.05,0.22-0.34-0.22c-0.08,0.08-0.2-0.06-0.3,0.03
			c0.03-0.02,0.06-0.08,0.06-0.1l-0.07,0.07c-0.06-0.11-0.1-0.03-0.34-0.23l0.06,0l-0.03-0.07l-0.04,0.05
			c-0.12-0.22-0.3-0.09-2.36-0.9c0.1-0.01,0.06-0.12,0.05-0.17c-0.01,0.02-0.03,0.04-0.04,0.07c0.03-0.21-0.11,0.08-0.14-0.05
			c0.02,0-0.3,0-0.06-0.09c-0.22-0.08,0.03,0.01-0.29-0.28c-0.13,0.26,0,0.02-0.37,0.07c0.16-0.08,0.08-0.02,0.1-0.27
			c-0.1,0.05-0.06-0.11-0.2-0.04c0.01-0.02-0.01-0.03-0.01-0.05c-0.01,0.06-0.24-0.03-0.94-0.31c0.15-0.15-0.27-0.01,0.01-0.18
			c-0.08-0.01-0.17,0.02-0.25,0.01c0.01-0.06,0.08-0.06,0.12-0.08c0,0.02-0.18-0.08-0.25,0.07c0.22-0.16-0.2,0.16,0.34,0.15
			l-0.11,0.11l0.15-0.05c0,0.04-0.02,0.12-0.08,0.12c0.02,0.08-0.02,0.08,0.17,0.11c-0.03,0.02-0.04,0.1-0.07,0.06
			c0.08,0.08,0.21,0.26,0.46,0l-0.05,0.28l0.07-0.09c0.01,0.01,0.04,0.04,0.02,0.07c0.1-0.03,0.01-0.05,0.08-0.09
			c0.03,0.13,0.24,0.08,0.18,0.24c0.07-0.06-0.05-0.09,0.07-0.1c-0.14,0.17,0.26,0.01,0.07,0.24c0.2-0.15-0.02,0.22,0.48,0.15
			c-0.04,0.05,0.03-0.02,0.23,0.27c0.08-0.1,0.17-0.03,0.25-0.02c-0.04,0.07-0.06,0.09-0.12,0.1c0.02-0.01,0.02,0.06,0.18,0.01
			l-0.06,0.08c0.09,0.04,0.23-0.01,0.32,0.11c0.05-0.08,0.02-0.27,0.17-0.26c-0.02,0.05-0.05,0.12-0.09,0.12
			c0.01,0.01,0.15-0.01-0.02,0.06c0.05-0.02,0.17,0.07,0.02,0.06c0.04,0.03,0.08,0.1,0.08,0.16c0.07-0.11,0.06,0.19,0.47,0.07l0,0
			c0.17-0.07,0.34,0.32,0.96,0.51c-0.1,0.02,0,0.06-0.09,0.01c0.08,0.07,0.08,0.13,0.22,0.09c-0.07,0.07,0.02,0.13,0.08,0.13
			l-0.04-0.09c0.23,0.21-0.01,0.11,0.56,0.37c-0.07-0.45,0.56,0.17,0.45,0.17l0.03-0.03c0.03,0.06,0.15,0.11,0.09,0.16
			c0.08-0.01,0.06-0.05,0.01-0.09c0.09,0.1,0.17,0.08,0.28,0.16c-0.03-0.07,0.5-0.15,0.23,0.17c0.14-0.03,0.18,0.13,0.24,0.17
			l-0.07,0.01c0.04,0,0.07,0.01,0.1,0.02l0-0.09c0.11,0.13,0,0.1,0.29,0.01c-0.17-0.1-0.19,0.37,0.15,0.1
			c-0.04,0.05-0.01,0.12,0.02,0.12c-0.04,0.01,0-0.14,0.23-0.1c-0.03-0.05-0.08-0.07,0.11-0.06c-0.01,0.05-0.07,0.01-0.05,0.04
			c0.2-0.16,0.22,0.62,0.54,0.27l-0.21-0.01l0.16-0.07c-0.06-0.02-0.14,0.01-0.17-0.02c0.08-0.04,0.07-0.03,0.07-0.1
			c0.4,0.08-0.16,0.34,0.59,0.39c-0.03-0.07-0.08-0.11,0.14-0.04c-0.03,0.06,0,0.1,0.01,0.15c0.01-0.05,0.13,0.05,0.19-0.07
			c0.11,0.15,0.02,0.19,0.4,0.06c-0.07,0.06-0.02,0.12-0.13,0.14c0.15-0.01-0.04,0,0.45-0.1c0.06,0.18,0.11,0.2,0.79,0.38
			c-0.01,0.02-0.01,0.03-0.03,0.02c0.33,0.02,0,0.05,0.43,0.22l-0.03,0.01c0.05,0.1,0.2-0.02,0.32,0.03c0,0-0.01,0.02-0.01,0.03
			c0.12-0.08,0.28-0.07,0.41-0.07c-0.01,0.03-0.02,0.05-0.03,0.07c0.18-0.14,0.12,0.35,1.07,0.24c-0.09,0.08-0.22,0.1-0.32,0.13
			c0.06,0.02,0.2,0.05,0.25-0.01c-0.08-0.02-0.11-0.02,0.24-0.07c-0.09,0.25,0.28,0.15,0.31,0.37c0.23-0.04,0.4,0.15,0.64,0.19
			c-0.07-0.07,0.27-0.29,0.13,0.03c-0.04-0.08,0.07-0.08,0.08-0.14c0.07,0.07,0.13,0.18,0.14,0.36c0-0.08,0.07-0.1,0.13-0.05
			l-0.07-0.12c0.2-0.07,0.1,0.21,0.3,0.15l-0.07,0.05c0.1-0.01,0.33,0.09,0.45-0.06c0.01,0.03,0.02,0.09-0.03,0.1
			c0.12,0.12,0.26,0.04,0.92,0.13l-0.06,0.05c0.67-0.09,0.31,0.3,2.06,0.47c0.21-0.13,0.13,0.04,1.31,0.09
			c0.04,0.17-0.04,0.1,0.39,0.18l-0.05,0.06c0.33-0.18,0.08-0.15,0.82-0.03c-0.01,0.02-0.02,0.06-0.05,0.06
			c0.08,0.01,0.32,0.14,0.31-0.06c-0.02,0.13,0.19,0.04,0.29-0.01l-0.03,0.09c0-0.03,0.1-0.07,0.39-0.13l-0.05,0.06
			c0.14,0.03,0.11-0.24,0.33,0.06c0.02-0.02,0.01,0.01,0.46,0.05c-0.12-0.01-0.22-0.03-0.21-0.04c-0.12,0.16,0.35,0.3,0.65,0.05
			c0.01,0.01-0.02,0-0.07,0.02l0-0.01c-0.03,0-0.08,0-0.13-0.01c0.04-0.05,0.09-0.08,0.14-0.02c0.03-0.06,0.07-0.12,0.09-0.15
			c0.14-0.02,0.56,0.31,1.83,0.27c-0.02-0.02-0.03-0.03-0.04-0.05c0.83,0.1,0.14,0.15,2.7-0.02c0,0.01,0,0.01,0,0.04l0.02-0.06
			c0,0,0,0,0,0c0.06-0.11-0.05-0.01-0.26-0.43l0.1,0c-0.05-0.08-0.09-0.01-0.13,0.03c-0.05-0.07-0.01-0.17-0.16,0.06
			c-0.01-0.36-0.14,0.16-0.13-0.13c-0.06,0.05-0.15,0.02-0.18,0.1c-0.05-0.09-0.19,0.02-0.18-0.14c-0.02,0.04-0.1,0.12-0.16,0.12
			c0.1-0.18-0.2-0.02-0.16-0.21c-0.08,0.16-0.26,0.07-0.39,0.15c0.01-0.01,0.02-0.04,0.03-0.03c-0.07-0.02-0.09-0.07-0.13-0.1
			c-0.02,0.14-0.13,0.16-0.19,0.24c-0.08-0.14,0.06-0.16,0.04-0.29c0,0.11-0.16,0.1-0.13,0.2c-0.06-0.09-0.05-0.04-0.08-0.16
			c-0.02,0.17-0.06,0.03-0.12,0.13c-0.02-0.09-0.21-0.03-0.07-0.23c-0.06,0.27-0.35-0.17-0.86,0.35c0.03,0,0.03-0.03,0.05-0.05
			l-0.05,0.03c0.25-0.17-0.19-0.21-0.02-0.13c0.11-0.52-0.8,0.23-0.81-0.19c-0.44,0.11,0.06-0.11-0.82,0.05
			c0.39,0.07-0.49-0.25-1.41-0.23l0.03-0.03c-0.11-0.06-0.16,0.19-0.26,0.08l0.02-0.02c-0.19-0.07-0.04-0.23-1.56-0.25l-0.03-0.14
			c-0.18,0.18,0.07-0.15-0.51-0.01c0.01-0.04,0.05-0.05,0.09-0.07c-0.14,0.17-0.01-0.17-0.16,0.05c-0.05-0.07-0.18-0.1-0.28-0.09
			l0.03-0.07c-0.17-0.05-0.37-0.04-0.57-0.08c0.06,0.05,0.02,0.17-0.04,0.17l0-0.12c-0.04,0.06-0.1,0.23-0.02-0.04l-0.1,0.02
			c0-0.03,0.03-0.05,0.04-0.09c-0.42-0.05,0.01-0.09-1.27-0.04c0.01-0.09-0.13-0.11-0.04-0.16c-0.17-0.02-0.22-0.03-0.45-0.01
			c0.04,0.07-0.02,0.14-0.04-0.01c-0.27,0.08-0.49-0.38-0.72-0.24l0.01-0.01c-0.33,0.03-0.18-0.02-1.35-0.45
			c-0.01,0.04-0.02,0.14-0.08-0.01c0.24,0.48-0.76-0.4-2.34-0.58c0.03-0.02,0.04-0.05,0.06-0.04c-0.04-0.1-0.22-0.08-0.25-0.01
			c0.1,0.04-0.33-0.22-0.86-0.21c0.06-0.15-0.15-0.18-0.24-0.14c0.04-0.04-0.04-0.07,0.01-0.12c-0.08-0.03-0.16-0.02-0.17,0.06
			c-0.18-0.23-0.08-0.16-1.05-0.29C1392.88,235.68,1393.05,235.89,1393.1,235.73z M1406.48,239.7c-0.01,0.02,0,0-0.02,0.05
			C1406.43,239.75,1406.37,239.7,1406.48,239.7z M1406.35,239.05c-0.01,0-0.03,0-0.05,0
			C1406.32,239.04,1406.3,239.05,1406.35,239.05z M1406.15,239.11c0.01-0.02-0.03-0.05-0.06-0.08
			C1406.08,239.05,1406.24,239,1406.15,239.11z"
              />
              <path
                className="st10"
                d="M1337.5,276.64c-0.04-0.03-0.07-0.21,0.19-0.16l-0.09,0.08c0.21,0.1,0.37-0.28,0.57-0.4
			c0.04,0.09,0.03,0.22,0.57-0.16c-0.01,0.04,0.08,0.07-0.01,0.11c0.29-0.1,0.34-0.34,0.49-0.33l-0.01,0.02
			c0.34-0.25,0.94-0.61,2.25-1.86c0.02,0.05,0.42-0.27,0.63-0.4l0.01,0.02c-0.05-0.13,0.15-0.28,0.27-0.43
			c0.05,0.06,0.04,0.11-0.01,0.2c0-0.03,0.06,0.02,0.23-0.32l0.03,0.1c-0.01-0.16,0.41-0.06,0.53-0.8l-0.04,0.14
			c0.02-0.06,0.3-0.29,0.11-0.04c0.45-0.32,0.19-0.14,0.96-0.94l0,0c0.21-0.35,0,0,0.85-0.84c0.09,0.12,0.26-0.27,0.91-0.88
			c-0.08,0.17,0.08-0.01-0.1,0.16c0.18-0.14,0.26-0.14,0.36-0.38c0.01,0.12,0.2-0.05,0.26-0.14l-0.16,0.07
			c0.08-0.1,0.16-0.17,0.24-0.24l0.04,0.12c0.39-0.32,0.74-0.76,1.39-1.65c-0.35-0.02,0.58-0.22,0.33-0.3
			c0.23-0.17,0.29-0.32,0.51-0.5c-0.08,0.01,0.32-0.84,0.47-0.41c0.12-0.25,0.37-0.33,0.48-0.43l-0.07,0.12
			c0.04-0.07,0.09-0.12,0.13-0.17l-0.11,0c0.07-0.05,0.11-0.1,0.14-0.08c0.01-0.19-0.23-0.18,0.19-0.45
			c-0.17,0.04,0.26,0.37,0.29-0.28c0.02,0.07,0.14,0.02,0.17-0.03c-0.06,0.08-0.12-0.1,0.13-0.43l-0.1,0.08
			c0.01-0.3,0.44-0.36,1.11-1.01c-0.11,0.01-0.11,0.03-0.05-0.17l-0.25,0.38l0.09-0.3c-0.09,0.1-0.15,0.25-0.21,0.3
			c0.04-0.15,0.04-0.12-0.05-0.12c0.46-0.51,0.49,0.04,1.14-1.08l-0.11,0.08c-0.03-0.1,0.16-0.2,0.22-0.33
			c0.05,0.05,0.12,0.01,0.19-0.01c-0.04-0.03,0.2-0.23,0.12-0.35c0.34-0.21,0.23-0.04,0.51-0.74c0,0.12,0.13,0.04,0.03,0.24
			c0.18-0.43-0.13,0.12,1.7-2.27c0,0.01,0.01,0.02,0.01,0.02c0.36-0.56,0.03,0.03,0.72-0.76l-0.02,0.05
			c0.2-0.17,0.08-0.07,0.79-1.34c0.02,0.03,0.03,0.04,0.05,0.06c-0.03-0.08-0.02-0.22,0.33-0.29l-0.14,0.18
			c0.17-0.01,0.18-0.23,0.27-0.32l0.03,0.05c0.21-0.29,0.1-0.38,0.35-0.66l-0.06,0c0.24-0.48,0.26-0.04,0.69-0.94
			c0,0.17-0.14,0.43-0.21,0.6c0.09-0.1,0.28-0.36,0.27-0.46c-0.02,0.05-0.04,0.13-0.09,0.15c0.35-0.48,0.59-1.16,2.68-2.93
			c-0.08-0.02-0.03-0.14,0.1-0.25l-0.22,0.12c0.15-0.38,0.35-0.16,0.51-0.54l-0.02,0.14c0.21-0.2,0.19-0.32,1.37-2l0.02,0.06
			c0.11-0.24,0.13-0.06,0.35-0.54l-0.02,0.12c0.35-0.33,0.34-0.67,0.55-1c0.12,0.1,0.39-0.17,0.53-0.3l0.01,0.02
			c0.55-0.52-0.24-0.23,1.86-2.42c-0.04,0-0.1-0.18,0.15-0.36c-0.04,0.04,0.49-0.1,0.28-0.49c0.27-0.04,0.43-0.63,1.93-2.18l0,0.11
			c0.27-0.74,0.08-0.32,1.01-1.51c0.01,0.02,0.04,0.05,0,0.11c0.12-0.15,0.55-0.52,0.34-0.59c0.11,0.08,0.28-0.32,0.37-0.53
			l0.05,0.09c-0.02-0.03,0.09-0.27,0.39-0.76l-0.01,0.11c0.21-0.26-0.07-0.31,0.48-0.57c-0.06,0.09,0.08-0.34,0.11-0.19
			c0.32-0.2-0.09-0.06,0.54-0.61c-0.18,0.22-0.33,0.39-0.32,0.37c0.07,0.06,0.22-0.11,0.21,0.11c0.09-0.17-0.06-0.03,0.36-0.67
			l-0.01,0.02c0.18-0.25,0.15-0.1,0.49-0.5c-0.08-0.06,0.03-0.16-0.13-0.1c0.03-0.02-0.03,0.04-0.07,0.13l0-0.01
			c-0.05,0.06-0.11,0.13-0.18,0.22c0.3-0.75,0.45-0.27,1.35-1.67c-0.1,0.27-0.12,0.23,0.43-0.52l0,0.06
			c0.58-0.38,0.45-1.05,1.15-1.43c-0.05,0.03-0.07,0.04-0.09,0.04c0.41-0.1,1.26-1.72,2.96-3.18l-0.09-0.01
			c0.55-0.27,0.4-0.59,0.59-1.42c-0.13,0.09-0.1,0.06-0.38,0.55c-0.19-0.24-0.1,0.41-0.3,0.13c0,0.11-0.31,0.06-0.74,0.93
			c0.02-0.28-0.32,0.32-0.42,0.14c0.01,0.23-0.35,0.47-0.47,0.75c0-0.02,0-0.06,0.02-0.08c-0.12,0.11-0.18,0.11-0.28,0.17
			c0.13-0.14-0.14,0.9-0.26,0.24c0.09,0.07-0.14,0.33-0.03,0.34c-0.16,0.06-0.12,0.07-0.25,0.05c0.21,0.03-0.45,0.72-0.37,0.29
			c0.13,0.27-0.6,0.64-0.94,1.69c-0.84-0.28-0.04-0.04-1.41,1.46l-0.05-0.15c-0.45,0.9-1.29,1.38-3.19,3.99l0.02-0.07
			c-0.2,0.19-0.03,0.37-0.26,0.51l0.02-0.05c-0.12,0.09-0.19-0.01-3.07,3.76c-0.03-0.04,0.02-0.12,0.04-0.19
			c-0.09,0.3-0.12-0.03-0.21,0.24c0.04-0.01,0.04,0.05,0.05,0.07c-0.13,0.06-0.32,0.3-0.44,0.5l-0.03-0.08
			c-0.26,0.31-0.5,0.69-0.78,1.05c-0.53,0.46,0.04-0.07-3.71,4.84l0.01-0.03c-1.55,2.05,1.67-2.19-4.24,5.43l-0.01-0.17
			c-0.33,0.64-0.91,1.12-1.25,1.74c0.01-0.05-0.02-0.07,0.02-0.12c-0.16,0.06-0.34,0.42-0.29,0.46c-0.23,0.22-0.04-0.1-1.23,1.6
			c-0.11-0.11-0.39,0.27-0.43,0.44c0-0.07-0.13,0.08-0.14-0.01c-0.12,0.15-0.2,0.3-0.12,0.32c-0.52,0.36-0.34,0.28-1.51,1.94
			c-0.01-0.06,0-0.12,0.01-0.17c-0.26,0.31-0.3,0.31-1.29,1.59c-0.01-0.01,0.01-0.06,0.03-0.1c-0.09,0.22,0.01-0.04-0.06,0.54
			c-0.06-0.24-0.31,0.28-0.36-0.06c-0.03,0.05-0.04,0.12,0,0.15c-0.06,0.05-0.1,0.05-0.09-0.04c-0.16,0.25-0.04,0.22-0.21,0.43
			c-0.03-0.02,0-0.07-0.01-0.12c-0.04,0.15-0.08,0.3-0.25,0.45c-0.01-0.02-0.04-0.02,0-0.07c-0.25,0.3-0.31,0.61-0.65,0.89
			c-0.01-0.1-0.09-0.18-0.37,0.2l0.11-0.03c-0.14,0.15-0.19,0.34-0.34,0.44l0.06-0.15l-0.24,0.3l0.18-0.13
			c-0.23,0.59,0.11,0.07-0.95,1.16c0.01-0.2-0.31,0.03-0.28,0.27c0.01-0.02-0.01-0.02,0.02-0.07c-0.25,0.27-0.37,0.36-0.47,0.42
			l0.05-0.08c-0.34,0.29,0.02-0.04-1.08,1.19l0.07-0.05c0.09,0.17-0.29,0.16-0.38,0.38c-0.19-0.09,0-0.08-0.68,0.73l0.01-0.07
			c-0.53,0.6-0.53,0.39-1.73,1.6c0.06-0.12,0.12-0.19-0.25,0.23c0.08,0.04,0.07,0,0.18-0.02c-0.01,0.07-0.07,0.18-0.15,0.26
			c0.11-0.17-0.09-0.1-0.18-0.07c0.03,0.01,0.02,0.05,0.05,0.06c-0.4,0.27,0.06,0.11-0.76,0.75c0.04-0.07,0.13-0.13,0.17-0.2
			c-0.21,0.03-0.2,0.06-0.32,0.13l0.01-0.01l-0.26,0.15l0.08-0.02c-0.02,0.33-0.32,0.32-0.45,0.63c0.09-0.13-0.06-0.37-0.55,0.15
			c0.03-0.09-0.78,0.86-2.02,1.63c0-0.13,0.02-0.31-0.6,0.4L1337.5,276.64z M1371.87,238.35c0.01,0.06,0.03,0.08-0.07,0.12
			C1371.81,238.44,1371.82,238.48,1371.87,238.35z M1371.09,238.28c-0.01,0.02-0.03,0.05-0.07,0.1
			C1371.05,238.33,1371.02,238.37,1371.09,238.28z M1370.88,238.67c0-0.03-0.09,0.04-0.15,0.08
			C1370.71,238.8,1370.86,238.48,1370.88,238.67z"
              />
              <path
                className="st10"
                d="M1449.7,236.73c0,0.09,0.03,0.06,0.08,0.14c-0.06,0.03-0.16,0.04-0.24,0.01c0.16,0,0.04-0.12-0.01-0.19
			c0,0.03-0.03,0.05-0.03,0.07c-0.1-0.23-0.05,0.07-0.25-0.06c0.05,0-0.48,0-0.14-0.1c-0.13-0.04-0.31,0.08-0.4-0.04
			c0.06-0.01,0.14,0.02,0.21,0.01c-0.09-0.15-0.12-0.12-0.21-0.17l0.01,0.01l-0.21-0.12l0.05,0.05c-0.24,0.18-0.35-0.07-0.62,0.01
			c0.22-0.07,0.1-0.07,0.02-0.29c-0.13,0.05-0.15-0.12-0.34-0.05c0.08-0.03-0.93-0.14-1.95-0.52c0.08-0.08,0.21-0.19-0.53-0.17
			l0.08,0.06c0.05-0.12-0.07,0.19,0.62,0.18c-0.21,0.1-0.12,0.25,0.36,0.31c-0.03,0.02-0.01,0.1-0.08,0.06
			c0.19,0.15,0.38,0.01,0.43,0.15l-0.02,0c0.3,0.21,0.43,0.15,1.07,0.22c-0.15,0.19,0.41,0,0.23,0.27c0.25-0.14-0.08,0.14,0.85,0.18
			c-0.03,0.04,0.34,0.18,0.51,0.29l-0.01,0.02c0.07-0.11,0.25-0.03,0.4-0.01c-0.03,0.07-0.06,0.09-0.15,0.1
			c0.02-0.01,0.01,0.06,0.3,0.02l-0.06,0.08c0.17,0.05,0.37,0,0.57,0.13c0.04-0.08-0.1-0.29,0.15-0.28
			c-0.01,0.05-0.02,0.13-0.09,0.13c0.02,0,0.08-0.02,0.12,0.02l-0.12,0.05c0.06-0.02,0.3,0.09,0.07,0.07
			c0.09,0.04,0.18,0.11,0.2,0.17c0.29-0.14,0,0.01,0.8,0.1l0,0c0.23-0.08,0.7,0.38,1.8,0.62c-0.15,0.02,0.03,0.07-0.15,0
			c0.16,0.08,0.18,0.15,0.39,0.11c-0.09,0.07,0.09,0.15,0.18,0.14l-0.1-0.1c0.09,0.02,0.17,0.05,0.25,0.08l-0.08,0.1
			c0.37,0.17,0.78,0.25,1.62,0.39c-0.07-0.3,0.28,0.4,0.32,0.14c0.19,0.12,0.32,0.1,0.52,0.2c-0.05-0.07,0.73-0.13,0.44,0.21
			c0.22-0.02,0.35,0.16,0.45,0.2l-0.11,0c0.07,0,0.12,0.01,0.17,0.03l-0.03-0.1c0.06,0.03,0.11,0.05,0.1,0.08
			c0.14-0.05,0.05-0.3,0.39-0.04c-0.07-0.14-0.21,0.4,0.29,0.12c-0.05,0.05,0.02,0.13,0.07,0.13c-0.08-0.01,0.02-0.15,0.35-0.09
			l-0.09-0.05c0.24-0.12,0.38,0.21,1.05,0.5c-0.04-0.1-0.05-0.08,0.11-0.12l-0.35-0.04l0.25-0.06c-0.1-0.03-0.23-0.01-0.28-0.04
			c0.12-0.04,0.1-0.02,0.08-0.1c0.53,0.15,0.07,0.43,1.12,0.49l-0.09-0.06c0.06-0.07,0.2,0.04,0.3,0.04
			c-0.03,0.06,0.03,0.11,0.06,0.16c0.01-0.05,0.23,0.07,0.29-0.06c0.24,0.19,0.09,0.19,0.69,0.11c-0.09,0.05,0,0.13-0.17,0.14
			c0.08,0.02,0.15,0.06,0.16,0.01c-0.14-0.01,0.3,0.14,0.55-0.07c0.14,0.2,0.28,0.24,1.43,0.51c-0.02,0.02-0.01,0.03-0.04,0.02
			c0.53,0.07,0,0.03,0.79,0.3l-0.04,0c0.11,0.11,0.34,0,0.54,0.08c0,0-0.02,0.02-0.01,0.03c0.17-0.07,0.46-0.04,0.67-0.01
			c-0.01,0.03-0.02,0.05-0.03,0.07c0.05-0.06,0.16-0.12,0.3,0.16l-0.17-0.04c0.46,0.34,0.61,0,1.73,0.29
			c-0.13,0.07-0.36,0.07-0.5,0.09c0.1,0.03,0.35,0.08,0.42,0.03c-0.04,0-0.1,0.02-0.14-0.01c0.88,0.04,0.05-0.06,2.25,0.73
			c0.01-0.2,0.14-0.21,0.28-0.13c-0.03,0.04-0.19,0.1-0.05,0.18c-0.1-0.1,0.13-0.08,0.1-0.15c0.13,0.09,0.27,0.25,0.32,0.42
			c-0.01-0.08,0.1-0.09,0.21-0.03l-0.15-0.14c0.32-0.04,0.21,0.24,0.54,0.21l-0.11,0.04c0.16,0.09,0.25,0.02,1.85,0.3l-0.04,0.04
			c0.23-0.01,0.07,0.08,0.5,0.07l-0.09,0.04c0.34,0.15,0.58-0.01,0.89,0.03c-0.04,0.15,0.23,0.27,0.37,0.32l-0.01,0.02
			c0.55,0.24,0.08-0.32,2.31,0.51c-0.01-0.03,0.09-0.18,0.31-0.04c-0.06-0.02,0.27,0.38,0.44,0.02c0.11,0.23,0.58,0.06,2.17,0.65
			l-0.08,0.05c0.59-0.12,0.23-0.09,1.4,0.15c-0.01,0.02-0.03,0.06-0.08,0.06c0.15,0.03,0.55,0.22,0.54,0.01
			c-0.02,0.13,0.32,0.08,0.5,0.06l-0.05,0.09c0.01-0.03,0.21-0.05,0.67-0.04l-0.08,0.05c0.26,0.06,0.2-0.21,0.56,0.13
			c-0.08-0.01,0.28-0.1,0.17,0c0.24,0.18,0.03-0.11,0.61,0.17c-0.21-0.05-0.39-0.09-0.37-0.09c-0.02,0.09,0.15,0.14-0.01,0.23
			c0.11,0.08,0.34-0.18,1.1,0.14c0.01-0.1,0.12-0.06,0.03-0.16c0.02,0.01-0.04-0.01-0.12,0l0.01-0.01
			c-0.06-0.01-0.13-0.02-0.22-0.04c0.05-0.09,0.14,0.15,0.39-0.12c0.21,0.34,0.22,0.29,1.25,0.41c-0.05,0.01-0.1,0-0.13,0.02
			c0.16-0.02,0.45,0.07,0.51-0.05c0.18,0.1-0.13,0.1,0.13,0.13l-0.04,0.03c0.47,0.3,0.89-0.16,1.41,0.21
			c-0.04-0.02-0.05-0.03-0.06-0.06c0.32,0.09,1.07,0.22,3.31,0.66l-0.03-0.08c0.45,0.27,0.54,0.03,1.18-0.34
			c-0.11-0.06-0.08-0.04-0.52,0.01c0.06-0.36-0.28,0.11-0.21-0.17c-0.11,0.03-0.26-0.03-0.33,0.05c-0.08-0.11-0.34-0.04-0.3-0.19
			c-0.04,0.03-0.2,0.09-0.29,0.08c0.2-0.15-0.34-0.07-0.26-0.26c-0.15,0.14-0.46-0.01-0.69,0.04c0.01-0.01,0.04-0.03,0.06-0.02
			c-0.12-0.04-0.15-0.09-0.22-0.14c0.13,0.04-0.67,0.36-0.26-0.09c-0.01,0.11-0.28,0.06-0.25,0.16c-0.1-0.1-0.09-0.06-0.13-0.18
			c0.05,0.2-0.66,0.02-0.33-0.15c-0.08,0.24-0.74-0.17-1.51,0.12c0-0.69,0.06-0.14-1.51-0.44l0.09-0.12
			c-0.79,0.09-1.39-0.37-3.87-0.74l0.06-0.02c-0.2-0.08-0.28,0.16-0.45,0.02l0.04-0.01c-0.04-0.17-0.35-0.12-2.89-0.76
			c-0.09,0.19,0.24-0.17-0.76-0.13c0.02-0.04,0.09-0.04,0.15-0.05c-0.24,0.06-0.01-0.12-0.24-0.07c0.02,0.03-0.03,0.06-0.04,0.08
			c-0.09-0.09-0.31-0.14-0.49-0.15l0.05-0.06c-0.29-0.09-0.62-0.11-0.95-0.19c-0.55-0.2,0.03,0.04-4.64-1.06l0.02,0
			c-0.89-0.22-1.81-0.48-5.17-1.3l0.12-0.09c-0.56,0-1.08-0.29-1.62-0.32c0.04-0.02,0.05-0.05,0.1-0.04
			c-0.09-0.11-0.4-0.11-0.42-0.05c-0.22-0.09,0.09-0.08-1.52-0.35c0.06-0.15-0.31-0.22-0.45-0.18c0.05-0.03-0.09-0.08-0.03-0.13
			c-0.14-0.04-0.28-0.05-0.27,0.04c-0.4-0.28-0.27-0.19-1.86-0.43c0.47-0.23,0.76,0.08-1.41-0.45c0.01-0.01,0.04-0.02,0.08-0.02
			c-0.19,0.02,0.03-0.01-0.42,0.19c0.16-0.17-0.3-0.14-0.06-0.34c-0.04,0-0.1,0.02-0.11,0.07c-0.06-0.03-0.07-0.06,0.01-0.1
			c-0.23-0.03-0.18,0.07-0.38,0.01c0.01-0.03,0.05-0.03,0.09-0.07c-0.12,0.04-0.24,0.07-0.4-0.01c0.02-0.02,0-0.05,0.05-0.03
			c-0.29-0.08-0.55,0.03-0.85-0.13c0.14-0.12-0.09-0.08-0.07-0.21l-0.18-0.01l0.05,0.08c-0.15-0.05-0.31,0-0.43-0.08l0.13-0.02
			l-0.29-0.06l0.14,0.1c-0.49,0.08-0.02,0.13-1.13-0.26c0.13-0.06-0.1-0.27-0.28-0.11c0.02,0,0.02-0.02,0.06-0.02
			c-0.28-0.07-0.38-0.13-0.46-0.18l0.08,0.01c-0.17-0.15-0.04,0.03-1.22-0.32l0.06,0.03c-0.1,0.16-0.21-0.16-0.4-0.13
			c0.01-0.19,0.07-0.05-0.74-0.21l0.06-0.03c-0.59-0.13-0.46-0.23-1.74-0.57C1450.06,236.79,1450.13,236.8,1449.7,236.73z
			 M1483.68,246.24c-0.01,0.02,0,0-0.03,0.04C1483.6,246.28,1483.5,246.21,1483.68,246.24z M1483.47,245.54
			c-0.02,0-0.04-0.01-0.09-0.01C1483.42,245.53,1483.38,245.53,1483.47,245.54z M1483.12,245.55c0.02-0.02-0.06-0.05-0.11-0.09
			C1482.98,245.47,1483.26,245.45,1483.12,245.55z"
              />
              <path
                className="st10"
                d="M1449.78,245.5c-0.14,0-0.35-0.03-0.42,0.05l0.08,0.06c0.04-0.12-0.05,0.21,0.61,0.1
			c-0.19,0.13-0.07,0.28,0.4,0.23c-0.03,0.03,0.01,0.1-0.06,0.08c0.22,0.11,0.37-0.09,0.45,0.03l-0.02,0.01
			c0.34,0.1,0.48,0.01,1.06-0.2c-0.04,0.24,0.35-0.19,0.33,0.12c-0.08-0.29,0.15,0.1,0.5-0.32c-0.05,0.3,0.08,0.26-0.04-0.44
			c0.01,0.02,0.02-0.17-0.06-0.42l0.03,0.01c-0.17-0.05-0.24-0.15-0.38-0.22c0.14,0.05,0.21,0.08,0.3,0.13
			c-0.09-0.06-0.06-0.03-0.29-0.14c0.04-0.07,0,0-0.08-0.23c0.24,0.02,0,0.01,0.51,0.2c-0.06-0.07-0.12-0.23,0.13,0.02
			c-0.02-0.06,0.07,0.01,0.02-0.11c0.03,0,0.06,0.06,0.08,0.09c0.02-0.06,0,0,0.17-0.1l0,0c0.27-0.03,0,0,0.8,0.32
			c-0.09,0.1,0.25,0.14,0.87,0.42c-0.15,0.01,0.03,0.07-0.15-0.01c0.16,0.09,0.18,0.16,0.39,0.13c-0.09,0.06,0.1,0.15,0.19,0.14
			l-0.11-0.1c0.1,0.01,0.18,0.04,0.26,0.07l-0.07,0.1c0.38,0.05,1.13,0.3,1.81-0.42c-0.33,0.03,0.54-0.29,0.29-0.32
			c0.16-0.22,0.15-0.39,0.2-0.65c-0.01,0.02-0.25-0.38-0.2-0.55c0.16,0.08,0.38,0.23,0.8,0.36c-0.04-0.09-0.06-0.07,0.1-0.13
			l-0.34,0l0.24-0.08c-0.1-0.02-0.22,0.01-0.28-0.01c0.11-0.05,0.1-0.03,0.06-0.11c0.53,0.1,0.11,0.43,1.14,0.37l-0.09-0.05
			c0.06-0.07,0.19,0.02,0.3,0.01c-0.02,0.07,0.04,0.1,0.07,0.15c0-0.05,0.23,0.04,0.28-0.09c0.26,0.17,0.11,0.18,0.68,0.03
			c-0.09,0.06,0.02,0.13-0.15,0.15c0.08,0.01,0.15,0.04,0.16-0.01c-0.14,0.01,0.31,0.11,0.53-0.13c0.16,0.18,0.3,0.21,1.44,0.36
			c-0.01,0.02-0.01,0.03-0.04,0.02c0.53,0.01,0,0.03,0.79,0.21l-0.04,0.01c0.12,0.1,0.33-0.03,0.54,0.02c0,0-0.01,0.02-0.01,0.03
			c0.16-0.09,0.44-0.08,0.65-0.08c-0.01,0.03-0.02,0.05-0.03,0.07c0.04-0.06,0.14-0.13,0.31,0.13l-0.17-0.02
			c0.48,0.3,0.59-0.06,1.72,0.11c-0.12,0.08-0.34,0.1-0.48,0.14c0.1,0.02,0.35,0.04,0.41-0.01c-0.04,0.01-0.1,0.03-0.14,0
			c0.87-0.05,0.04-0.06,2.26,0.5c-0.01-0.2,0.11-0.22,0.26-0.16c-0.03,0.04-0.18,0.12-0.04,0.19c-0.1-0.09,0.12-0.09,0.08-0.16
			c0.14,0.08,0.29,0.22,0.35,0.39c-0.02-0.08,0.09-0.1,0.21-0.05l-0.16-0.12c0.31-0.07,0.23,0.22,0.55,0.15l-0.1,0.05
			c0.16-0.02,0.57,0.09,0.74-0.06c0.02,0.03,0.05,0.09-0.02,0.1c0.36,0.08,0.84-0.06,1.11,0.09l-0.04,0.05
			c0.22-0.04,0.08,0.07,0.49,0.02l-0.09,0.05c0.35,0.12,0.57-0.06,0.88-0.06c-0.02,0.15,0.25,0.24,0.39,0.29l-0.01,0.02
			c0.55,0.19,0.05-0.32,2.3,0.3c-0.01-0.03,0.07-0.19,0.3-0.06c-0.06-0.01,0.3,0.36,0.43-0.02c0.12,0.22,0.58,0.01,2.17,0.45
			l-0.07,0.06c0.57-0.17,0.21-0.12,1.38,0.03c-0.01,0.02-0.02,0.06-0.07,0.06c0.15,0.02,0.55,0.17,0.52-0.04
			c-0.01,0.13,0.32,0.05,0.49,0.02l-0.04,0.09c0.01-0.03,0.21-0.07,0.65-0.1l-0.08,0.05c0.25,0.04,0.18-0.22,0.56,0.08
			c-0.08,0,0.27-0.12,0.17-0.02c0.25,0.16,0.02-0.11,0.61,0.12c-0.21-0.03-0.38-0.06-0.37-0.06c-0.01,0.09,0.16,0.13,0.01,0.23
			c0.11,0.07,0.32-0.21,1.08,0.06c0-0.1,0.11-0.07,0.02-0.16c0.02,0.01-0.04,0-0.11,0.01l0.01-0.01c-0.06-0.01-0.13-0.01-0.21-0.02
			c0.05-0.09,0.15,0.13,0.38-0.15c0.23,0.32,0.23,0.27,1.25,0.31c-0.05,0.01-0.1,0-0.12,0.03c0.15-0.03,0.44,0.03,0.49-0.09
			c0.18,0.08-0.12,0.11,0.14,0.11l-0.04,0.04c0.48,0.26,0.86-0.23,1.39,0.1c-0.04-0.02-0.06-0.03-0.06-0.05
			c0.32,0.07,1.06,0.13,3.28,0.4l-0.04-0.08c0.46,0.24,0.52-0.01,1.13-0.43c-0.11-0.05-0.08-0.04-0.5,0.05
			c0.03-0.36-0.26,0.14-0.22-0.15c-0.1,0.04-0.26-0.01-0.32,0.07c-0.09-0.1-0.33-0.01-0.31-0.16c-0.03,0.03-0.18,0.1-0.28,0.1
			c0.18-0.17-0.33-0.05-0.27-0.24c-0.14,0.15-0.45,0.02-0.67,0.09c0.01-0.01,0.04-0.04,0.06-0.03c-0.12-0.03-0.15-0.08-0.22-0.12
			c0.13,0.03-0.63,0.42-0.26-0.06c0,0.11-0.27,0.08-0.23,0.18c-0.1-0.09-0.09-0.05-0.14-0.17c0.07,0.19-0.64,0.07-0.34-0.13
			c-0.06,0.24-0.74-0.11-1.47,0.24c-0.16-0.88,0-0.01-1.51-0.31l0.08-0.13c-0.76,0.15-1.38-0.26-3.84-0.41l0.06-0.02
			c-0.2-0.07-0.26,0.18-0.44,0.06l0.04-0.01c-0.04-0.17-0.35-0.09-2.88-0.5c-0.08,0.2,0.22-0.19-0.75-0.06
			c0.01-0.04,0.09-0.05,0.14-0.07c-0.23,0.08-0.02-0.12-0.24-0.04c0.02,0.03-0.02,0.06-0.03,0.08c-0.09-0.08-0.32-0.11-0.49-0.11
			l0.04-0.07c-0.29-0.06-0.62-0.06-0.94-0.1c-0.26-0.1,0.29,0.27-2.52-0.32c-0.38-0.09-0.75,0.18-2.11-0.3l0.02-0.01
			c-0.89-0.12-1.81-0.31-5.17-0.77l0.11-0.1c-0.55,0.05-1.08-0.18-1.62-0.15c0.04-0.02,0.04-0.06,0.09-0.05
			c-0.1-0.1-0.4-0.07-0.42,0c-0.22-0.07,0.08-0.09-1.52-0.19c0.04-0.15-0.32-0.18-0.45-0.13c0.05-0.04-0.1-0.07-0.04-0.12
			c-0.14-0.02-0.27-0.01-0.26,0.07c-0.42-0.24-0.28-0.16-1.85-0.23c0.5-0.28,0.61,0.01-1.39-0.31c0.01-0.01,0.02-0.01,0.04-0.02
			c-0.03-0.01-0.04,0.03-0.13-0.08c0.06,0.02,0.1,0.05,0.15,0.08c-0.02,0-0.04,0,0.02,0.01c-0.13-0.08-0.01,0-0.26-0.16
			c0.02,0.04,0.04,0.08,0.05,0.12c-0.08-0.07-0.17-0.1-0.15-0.13c-0.11,0.03-0.12,0.1-0.21,0.14c0.01-0.02,0.02-0.04,0.05-0.07
			c-0.04,0.01-0.1,0.03-0.1,0.08c-0.06-0.02-0.07-0.06,0-0.1c-0.06,0.01-0.1,0.01-0.12,0.01c0.03-0.04,0.06-0.08,0.09-0.1
			c-0.06,0.02,0-0.02-0.22,0.02c0,0,0-0.01,0-0.01c-0.02,0.17,0,0-0.03,0.62c0.02-0.25,0.01-0.06,0.03-0.61c0,0.03,0,0.12-0.01,0.42
			c0-0.02,0.01-0.04,0.02-0.06c0,0.09,0,0.21,0.04,0.39c-0.02,0-0.04-0.01-0.05-0.02c0.05,0.14,0.15,0.22,0.35,0.11
			c0.08,0.18,0.08-0.04,0.26,0.47c-0.15-0.08-0.05,0.09-0.19,0.09c0.01,0.04,0.01,0.09,0.02,0.13l0.07-0.04
			c-0.02,0.12,0.02,0.25-0.06,0.33l-0.01-0.1c-0.02,0.07-0.05,0.13-0.09,0.2l0.11-0.07c-0.02,0.14,0.15,0.08,0.01,0.21
			c0.02-0.04,0,0-0.21,0.07c0.03-0.04-0.2,0.33-0.25-0.01c-0.02,0.13-0.28,0.11-0.36,0.23c0.07-0.14-0.18-0.19-0.28-0.02
			c0.02-0.01,0.01-0.02,0.05-0.03c-0.25-0.04-0.35-0.07-0.42-0.1l0.06,0c-0.34-0.22-0.19,0.13-2.18-0.74
			c0.96,0.02-2.4-0.47-1.4,0.24c-0.11-0.04-0.15-0.03-0.23-0.03c0.06,0.02,0.13,0.04,0.19,0.07c-0.62-0.11,0.58,0.27,0.09,0.22
			c0.08,0.05,0.82,0.46,0.19,0.2c0.1,0.09,0.28,0.05,0.33,0.37c-0.04-0.05-0.05-0.12-0.1-0.16c-0.08,0.15,0.02,0.03-0.09,0.39
			c0.09-0.03,0.06-0.04,0.12,0.11c-0.08,0.02-0.16,0.04-0.23,0.13c0.15-0.17,0.05-0.1-0.12-0.27c-0.08,0.11-0.18-0.04-0.3,0.11
			c-0.03-0.13-0.03,0.09-1.91,0.08C1450.04,245.5,1450.14,245.53,1449.78,245.5z M1482.62,247.47c-0.01,0.02,0,0-0.03,0.05
			C1482.54,247.52,1482.44,247.46,1482.62,247.47z M1482.36,246.79c-0.02,0-0.04,0-0.09,0
			C1482.31,246.79,1482.27,246.79,1482.36,246.79z M1482.02,246.83c0.02-0.02-0.06-0.05-0.11-0.09
			C1481.88,246.77,1482.14,246.72,1482.02,246.83z M1456.55,242.89c0,0.01,0,0.02,0,0.03c-0.01,0-0.01,0-0.02,0
			C1456.53,242.91,1456.54,242.9,1456.55,242.89z"
              />
              <path
                className="st10"
                d="M1450.71,247.81l0.04-0.1c-0.39-0.08-0.2,0.07-0.74-0.56l-0.01,0.08c-0.6-0.61,0.05,0.17-1.13-0.14
			c0.07,0.09,0.05,0.06,0.43,0.17c-0.2,0.3,0.28,0,0.09,0.23c0.1,0.01,0.2,0.12,0.29,0.08c0.01,0.13,0.25,0.17,0.15,0.28
			c0.04-0.01,0.2,0,0.26,0.05c-0.23,0.05,0.23,0.2,0.07,0.33c0.19-0.05,0.35,0.2,0.55,0.27c-0.02,0-0.05,0.01-0.06-0.01
			c0.07,0.08,0.06,0.14,0.09,0.21c0.09-0.1,0.17-0.08,0.29-0.07c-0.1,0.09-0.19,0.04-0.3,0.11c0.1-0.05,0.16,0,0.26-0.03
			c-0.08,0.04-0.02,0.03-0.17,0.05c0.23,0-0.02,0,0.24,0.05c-0.11,0.02-0.26-0.04-0.19,0.01c-0.04-0.01-0.08-0.02-0.11-0.03
			c0.11,0.19,0.18,0.02-0.37,0.38c0.04,0.01,0.05-0.03,0.07-0.05c-0.59-0.59,0.15,0.01-4.54-1.91l0.06-0.01
			c-0.16-0.11-0.28,0.11-0.41-0.05l0.04,0c-0.31-0.24-0.23-0.15-2.44-1.18c0.03,0.26-0.11-0.18-0.66-0.24
			c0.02-0.04,0.09-0.02,0.15-0.03c-0.08-0.47-1.41,0.3,0.17,1.27c-0.14,0.01,0.19-0.27,0.07-0.03c0.08-0.03,0.21-0.15,0.01,0.05
			l0.14,0.05c-0.01,0.03-0.05,0.03-0.08,0.06c0.59,0.39-0.01,0.08,1.68,1.03c-0.05,0.08,0.12,0.22-0.01,0.19
			c0.24,0.27,0.71,0.21,1.39,1.34c-0.6,0.22-0.01-0.01,0.55,2.33l0.1-0.1c-0.49,0.05-0.93-0.22-1.41-0.26
			c0.04-0.02,0.05-0.05,0.09-0.03c-0.06-0.11-0.34-0.13-0.36-0.07c-0.18-0.12,0.1-0.01-1.25-0.53c0.09-0.13-0.2-0.26-0.33-0.26
			c0.06-0.02-0.05-0.09,0.02-0.13c-0.48-0.07-0.13,0.11-1.18-0.68c0.29,0.04-0.72,0.24-0.25,0.71c0-0.03,0.06,0.01,0.25,0.11
			c-0.49,0.03-0.58-0.39,0.93,0.95c-0.01,0.01-0.04,0-0.07-0.01c0.17,0.06-0.03-0.01,0.43,0.01c-0.22,0.07,0.17,0.24-0.13,0.31
			c0.04,0.02,0.09,0.03,0.12-0.01c0.03,0.05,0.02,0.08-0.06,0.08c0.17,0.12,0.18,0.02,0.3,0.15c-0.03,0.02-0.06,0-0.1,0.02
			c0.11,0.02,0.23,0.05,0.32,0.18c-0.02,0.01-0.03,0.04-0.06,0c0.19,0.19,0.45,0.22,0.59,0.49c-0.17,0.03,0.03,0.11-0.06,0.21
			l0.13,0.1l0.01-0.09c0.09,0.11,0.24,0.14,0.29,0.26l-0.11-0.05l0.19,0.19l-0.06-0.15c0.42,0.17,0.1-0.1,0.71,0.77
			c-0.13-0.03-0.09,0.26,0.14,0.23c-0.02-0.01-0.02,0-0.06-0.02c0.14,0.21,0.18,0.3,0.2,0.37l-0.05-0.05
			c-0.17,0.4,0.72-0.07,0.33,1.84l-0.03-0.05c0.02,0.05-0.24,0.07-0.23,0.39c-0.25-0.09-0.15,0.28-0.96,0.12
			c0.08,0.01-0.15-0.15-0.36,0.12c0.15-0.02,0.03-0.12-0.01-0.18c0,0.03-0.02,0.05-0.03,0.07c-0.07-0.22-0.07,0.07-0.22-0.05
			c0.05,0-0.44-0.02-0.12-0.11c-0.11-0.04-0.29,0.07-0.36-0.07c0.06,0,0.13,0.03,0.18,0.03c-0.06-0.15-0.09-0.13-0.16-0.18
			l0.01,0.01l-0.17-0.14l0.04,0.05c-0.25,0.15-0.3-0.1-0.56-0.05c0.21-0.04,0.1-0.05,0.07-0.28c-0.13,0.03-0.12-0.13-0.3-0.08
			c0.06-0.03-0.84-0.23-1.7-0.7c0.1-0.08,0.14-0.2-0.46-0.22l0.06,0.07c0.06-0.11-0.09,0.18,0.54,0.23
			c-0.22,0.09-0.14,0.24,0.28,0.33c-0.03,0.02-0.03,0.1-0.08,0.05c0.15,0.17,0.35,0.05,0.37,0.18l-0.02,0
			c0.63-0.14-0.01-0.03,0.94,0.31c-0.17,0.17,0.37,0.05,0.17,0.28c0.26-0.11-0.09,0.13,0.75,0.26c-0.03,0.03,0.29,0.21,0.45,0.31
			l-0.01,0.02c0.08-0.11,0.24-0.02,0.38-0.02c-0.02,0.07-0.06,0.1-0.14,0.11c0.02-0.02,0.02,0.07,0.3-0.01l-0.05,0.09
			c0.18,0.03,0.36-0.05,0.6,0c0-0.09-0.17-0.24,0.03-0.31c0.01,0.05,0.03,0.13-0.03,0.15c0.02-0.01,0.07-0.05,0.11-0.03l-0.08,0.09
			c0.04-0.04,0.31-0.05,0.09,0.03c0.1,0,0.22,0.01,0.28,0.04c0.13-0.31,0.01,0.01,0.6-0.59l0,0c-0.06-0.21,0.64-0.72,0.18-1.79
			c0.1,0.1,0.04-0.06,0.09,0.11c-0.03-0.17,0-0.24-0.16-0.36c0.11,0.02,0.04-0.17-0.03-0.23l0,0.14c-0.12-0.58,0.16-0.03-1.01-1.43
			c-0.02,0.18-0.18-0.13-0.01-0.12l-0.04,0.01c-0.02-0.09-0.15-0.24-0.07-0.25c-0.11-0.06-0.08,0.01-0.05,0.09
			c-0.08-0.19-0.2-0.24-0.3-0.41c0.01,0.08-0.65-0.24-0.24-0.38c-0.11-0.05-0.15-0.14-0.19-0.22c0.32,0.08-0.03,0.04,0.61,0.33
			l-0.04,0c0.1,0.11,0.32,0.02,0.51,0.09c0,0-0.02,0.02-0.01,0.03c0.17-0.07,0.43-0.04,0.62-0.03c-0.01,0.03-0.02,0.05-0.03,0.06
			c0.05-0.06,0.14-0.11,0.28,0.15l-0.16-0.03c0.41,0.12,0.77,0.36,1.63-0.36c0.04,0.16-0.17,0.4-0.33,0.49
			c0.14-0.01,0.43-0.25,0.4-0.36c-0.02,0.05-0.05,0.13-0.11,0.15c0.22-1.05,0.36-0.35-0.36-2.33c-0.12,0.15-0.23,0.09-0.29-0.05
			c0.05-0.01,0.2,0.03,0.15-0.11c0.01,0.13-0.14-0.01-0.17,0.07c-0.05-0.14-0.05-0.34,0.01-0.51c-0.04,0.07-0.13,0.02-0.18-0.09
			l0.03,0.19c-0.27-0.14-0.02-0.3-0.3-0.44l0.11,0.02c-0.12-0.09-0.37-0.4-0.59-0.39c0-0.04,0.02-0.1,0.07-0.07
			c-0.23-0.27-0.68-0.42-0.8-0.68l0.06-0.02c-0.16-0.07-0.08-0.1-0.25-0.2c0.08,0.02,0.14,0.02,0.16-0.06
			c-0.05,0.12,0.27,0.13,0.43,0.14l-0.06,0.08c0.01-0.03,0.2-0.02,0.61,0.07l-0.08,0.03c0.22,0.1,0.22-0.17,0.48,0.22
			c-0.07-0.02,0.28-0.05,0.16,0.03c0.17,0.21,0.06-0.1,0.51,0.26c-0.18-0.08-0.33-0.15-0.31-0.15c-0.04,0.08,0.11,0.16-0.06,0.22
			c0.08,0.09,0.34-0.12,0.95,0.33c0.03-0.09,0.12-0.04,0.06-0.15c0.02,0.02-0.03-0.01-0.1-0.02l0.01-0.01
			c-0.05-0.02-0.11-0.04-0.18-0.07c0.07-0.08,0.1,0.16,0.38-0.05c0.12,0.36,0.11,0.32,1.05,0.6c-0.05,0-0.09-0.02-0.12,0
			c0.15,0.01,0.42,0.12,0.48,0c0.16,0.1-0.13,0.09,0.12,0.13l-0.04,0.03c0.31,0.12,0.09,0.11,1.52-0.1c-0.06,0.01-0.08,0.01-0.11,0
			C1451.91,249.09,1452.34,248.83,1450.71,247.81z M1444.15,251.8c0.01,0.01,0.03,0.01,0.05,0.02
			C1444.19,251.82,1444.17,251.81,1444.15,251.8z M1447.75,248.17c0.03-0.01-0.04-0.06-0.07-0.11
			C1447.64,248.07,1447.89,248.1,1447.75,248.17z M1448.09,248.93c-0.01,0.02,0,0-0.04,0.04
			C1448,248.94,1447.95,248.87,1448.09,248.93z M1447.98,248.19c0.03,0.01,0,0,0.08,0.03
			C1448.04,248.21,1448.02,248.2,1447.98,248.19z"
              />
              <path
                className="st10"
                d="M1448.22,248.88c-0.01-0.01-0.01-0.02-0.02-0.03l-0.03,0.02C1448.17,248.87,1448.18,248.88,1448.22,248.88z"
              />
              <path
                className="st10"
                d="M1444.49,262.76c-0.02,0.02,0.04-0.1-0.17-0.19c0.22,0.02-0.01-0.14,0.18-0.15
			c-0.02-0.04-0.05-0.09-0.06-0.13c-0.08,0.09,0.19-0.21-0.17-0.32c0.09-0.01,0.05,0,0.14-0.02c-0.02-0.01-0.14,0.04-0.17,0.01
			c0.09-0.05,0.38-0.14,0.14-0.24c-0.04-0.01-0.14-0.02-0.18-0.05c0.1-0.01,0.16,0.01,0.23,0.02c-0.27,0.02,0.25-0.19-0.22-0.42
			c0.05-0.01,0.13-0.02,0.17-0.05c-0.07,0.04-0.11,0.02-0.18,0.05c-0.01-0.02,0.06-0.06,0.3-0.17c-0.07,0.03-0.11,0.02-0.09-0.01
			c0,0.05-0.13-0.06,0.02-0.06c-0.05-0.01-0.03-0.09-0.2-0.06c0.11,0.01,0.09-0.23-0.22-0.34c0.1-0.08,0.42-0.18-0.03-0.26
			c0.14-0.1,0.01-0.15,0.08-0.25c-0.02,0.02-0.11,0.04-0.13,0.04c0.02-0.03-0.12,0,0.02-0.05l0,0.01c0.12-0.06,0.08-0.08,0.07-0.1
			l-0.17,0.07c0.22-0.17-0.16-0.11,0.05-0.24c-0.17-0.04,0.19-0.29-0.27-0.12c0.03-0.04,0.22-0.06,0.05-0.21
			c0.02-0.01,0.06-0.03,0.07-0.02c-0.01-0.02,0.05-0.13-0.13-0.03c0.12-0.06-0.01-0.05-0.08-0.05l0.09-0.04
			c-0.12,0.04-0.15,0.05-0.2,0.04c0.01-0.01,0.07-0.04,0.05-0.02c0-0.16-0.17,0.1-0.03-0.14c-0.06,0.04-0.09-0.04-0.06-0.12
			c0.02,0.03,0.02,0.06,0.02,0.06c-0.01,0.03,0.16-0.32-0.1-0.16c0.01-0.01,0,0,0.03,0l-0.01,0l0.02,0.03
			c-0.05,0.01-0.09,0.03-0.05-0.02c-0.06,0.02-0.12,0.05-0.15,0.06c-0.01-0.03,0.24-0.25-0.09-0.26c0.03-0.06,0.11-0.05,0.07-0.07
			c0.2-0.19,0.04-0.02-0.14-0.18c-0.01,0.01-0.01,0.02-0.03,0.03c0.01-0.03,0.06-0.28-0.23-0.29l0.08-0.06
			c-0.19,0.14-0.09-0.1-0.17-0.07c0-0.01-0.01-0.06,0-0.09l-0.11,0.02c0.14-0.15,0.3-0.39-0.47,0.26c0.18-0.07-0.05,0.02,0.07,0.01
			c-0.03,0.03-0.05,0.05-0.06,0.06l0.14-0.07c-0.05,0.04-0.11,0.06-0.13,0.1c0.07-0.03,0.11-0.09,0.16-0.1
			c-0.05,0.06-0.1,0.09-0.04,0.06c-0.03,0.03-0.06,0.04-0.08,0.04c0.05-0.02,0.04,0.02,0.12-0.02c-0.07,0.07,0.05,0.03-0.08,0.12
			c0.03-0.02,0.11-0.05,0.13-0.04c-0.17,0.08,0.02,0.05-0.15,0.16c0.15-0.08,0.1,0.01,0.19-0.01c-0.01,0.01-0.04,0.02-0.03,0.01
			c0,0.02-0.04,0.06-0.06,0.08c0.12-0.07,0.16-0.07,0.24-0.1c-0.1,0.09-0.14,0.08-0.24,0.15c0.09-0.06,0.11-0.03,0.19-0.08
			c-0.06,0.06-0.02,0.03-0.12,0.1c0.15-0.09,0.03,0,0.13-0.05c-0.06,0.07-0.13,0.09-0.07,0.09c-0.2,0.14,0.04-0.01,0.23,0.07
			l-0.03,0.02c0.01,0.01,0.12-0.12,0.16-0.07c0-0.01-0.03,0.01-0.06,0.02l0.04-0.01c-0.1,0.07-0.05,0.01-0.12,0.05
			c0.04,0,0.2,0.02,0,0.07l0.02-0.05c-0.03,0.11-0.12,0.22,0.23,0.38c-0.03,0.01-0.07,0.03-0.1,0.05c0.08,0.02,0.05,0.12,0.01,0.2
			l0.1-0.03c-0.18,0.11,0.15-0.06-0.03,0.05c0.1,0.14,0.25-0.02,0.22,0.35c0.1-0.05,0.23,0.09,0.06,0.19l0.11-0.02l-0.11,0.07
			c0.06,0.01,0.21-0.12,0.01,0.07l0.11,0.02c-0.04,0.02-0.06,0.01-0.09,0.02c0.16-0.04-0.12,0.08,0.08,0.01
			c-0.05,0.04-0.04,0.07,0,0.09c-0.05,0.04-0.13,0.01,0.02,0.15c0.02-0.03,0.15-0.08,0.16-0.07l-0.1,0.05
			c0.07-0.02,0.03-0.03,0.12-0.04c0,0.02-0.12,0.06-0.15,0.06l0.05,0.01c-0.03,0.01-0.05,0.02-0.09,0.03
			c0.07-0.02-0.09,0.08,0.03,0.05c-0.22,0.05,0.01-0.01,0.27,0.57l0.11,0.02c-0.01,0.03-0.07,0.01-0.12,0.04
			c0.16,0.01,0.01,0.09,0.12,0.11c-0.24,0.11,0.05,0.12-0.09,0.22c0.01,0,0.16-0.05,0.02,0.02c0.22-0.24-0.08,0.28,0.27,0.6
			c-0.03,0-0.06,0.01-0.06,0c-0.08,0.04,0,0.03,0.07,0.02c-0.07,0.01-0.1,0.02-0.1,0.02c0.04,0-0.01,0.01,0.09,0.01
			c-0.05,0,0.05,0.02-0.01,0.02c0.11,0.03-0.05,0,0.1,0.05c-0.16-0.05-0.13-0.03-0.06,0.01c-0.05-0.02-0.05-0.02-0.12-0.05
			c0,0,0.03,0.02,0.11,0.06c-0.19-0.1,0.14,0.12-0.1-0.03c0.07,0.07,0.1,0.14,0.1,0.19c-0.11-0.11-0.04-0.12-0.12-0.02
			c0-0.02-0.01-0.03-0.01-0.04c0,0.01-0.01-0.02,0.01,0.05l0-0.02c0.04,0.11,0.04,0.23,0.04,0.28c-0.04-0.21-0.05-0.06-0.08-0.33
			c0,0.01,0.01,0.05,0.01,0.1c-0.05-0.06-0.05,0.04-0.11-0.1c-0.01,0.02-0.04,0.01-0.04,0.09c0.02-0.11-0.26,0.27-0.17-0.05
			c-0.04,0.1-0.06,0.01-0.11,0.12c0.01-0.02,0.03-0.09,0.04-0.1l-0.04,0.08c0.1-0.19-0.2,0.06-0.07-0.2
			c-0.06,0.01-0.16,0.12-0.2,0.07c-0.05,0.22,0,0.01-0.06-0.01c0.01-0.01,0.04-0.1,0.03-0.08c-0.03-0.04-0.08,0.02-0.15,0.06
			c0.01-0.04,0.05-0.06-0.04-0.11c-0.02,0.04-0.03,0.03-0.04,0.1c0-0.19-0.13-0.28-0.28,0.04c0.04-0.04,0.04-0.12,0.04-0.17
			l-0.02,0.07c0.05-0.21-0.07,0.14-0.05,0.01c-0.03,0.02-0.09,0.06-0.01-0.06c-0.07,0.01,0.01,0.01-0.07-0.14
			c-0.09,0.3,0,0.01-0.15,0.2c0.07-0.14,0.02,0.01,0.08-0.28c-0.04,0.08-0.01-0.07-0.07,0.04c0.01-0.03-0.02-0.01,0.01-0.09
			c0.28,0.02-0.4,0.11-0.3-0.01c-0.03,0.02-0.07,0.08-0.09,0.1c0.01-0.06,0.04-0.08,0.06-0.12c-0.21,0.21,0.04,0.03-0.05,0.3
			c0-0.02,0.1-0.01,0.04,0.13c0.07,0.07,0.08,0.06,0.17-0.18l-0.05,0.26l0.04-0.1c0,0.01,0.01,0.02,0,0.06
			c0.04-0.06,0.01-0.05,0.04-0.11c-0.01,0.1,0.08-0.02,0.03,0.14c0.03-0.07,0-0.07,0.04-0.11c-0.07,0.19,0.09-0.06-0.01,0.19
			c0.08-0.15-0.02,0.01,0.08-0.01c-0.01,0.11-0.1,0.08,0.08-0.03c-0.03,0.04,0.03,0,0.04,0.16c0.04-0.12,0.07-0.09,0.1-0.11
			c-0.02,0.07-0.04,0.1-0.06,0.13c0.04-0.01,0.02,0,0.07-0.06l-0.03,0.09c0.03,0,0.09-0.09,0.1-0.02c0.03-0.08,0.05-0.25,0.1-0.29
			c-0.01,0.05-0.04,0.13-0.05,0.14c0.04,0.04-0.02-0.01-0.01,0.22c0.01-0.04,0.08-0.19,0.03,0.03c0.02-0.04,0.02,0.01,0.05-0.09
			c0.01,0-0.01,0.05-0.01,0.08c0.02-0.04,0.01,0.01,0.09-0.11h0c0.1-0.1,0.22,0.28,0.61,0.46c0.03-0.03,0.03-0.06,0.02-0.09
			c-0.07,0.16,0.25-0.11,0.19,0.17c0.06-0.07,0.1,0.07,0.13,0.09l-0.03,0.03c0.02-0.02,0.03-0.02,0.05-0.01l-0.02-0.09
			c0.02,0.02,0.03,0.02,0.03,0.05c0.01-0.05-0.02-0.14,0.01-0.21c0.02,0.05,0.05-0.04,0.07,0.07c-0.07-0.04,0.02,0.11-0.04,0.13
			c0.15,0.2,0.03-0.2,0.17,0.01c-0.03,0-0.03-0.1-0.02-0.15c0.05,0.04,0.03-0.04,0.05-0.04c-0.03-0.04-0.05-0.03,0-0.1
			c0.02,0.05-0.01,0.04,0.01,0.05c-0.01-0.11,0.1,0.1,0.12,0.01c0.04,0.03,0.05,0.11,0.08,0.13c-0.03-0.12,0.17,0.09,0.05-0.16
			c-0.01,0.03-0.03,0.06-0.04,0.08c-0.09-0.16,0.05-0.25,0.3-0.2c-0.04-0.03,0.04-0.04-0.07-0.13c0.28,0.17-0.17-0.31,0.14-0.06
			C1444.52,262.79,1444.53,262.78,1444.49,262.76z M1443.43,258.99c0.02,0-0.01,0.03-0.04,0.02
			C1443.4,259,1443.42,259,1443.43,258.99z M1442.9,259.43c0-0.01,0-0.02-0.01-0.02C1442.91,259.4,1442.99,259.34,1442.9,259.43z"
              />
              <polygon
                className="st10"
                points="1443.95,260 1443.99,259.99 1443.95,259.99 		"
              />
              <path
                className="st10"
                d="M1431.98,255.29c0.24,0.17-0.13,0.43,0.71,0.86c-0.14,0.25-0.09-0.04,1,1.18c-0.04,0.02-0.12-0.04-0.19-0.08
			c0.29,0.15-0.05,0.12,0.22,0.26c-0.01-0.04,0.06-0.03,0.08-0.04c0.05,0.15,0.27,0.38,0.45,0.53l-0.09,0.02
			c0.28,0.32,0.64,0.62,0.97,0.97c0.41,0.64-0.07-0.05,4.53,4.53l-0.03-0.01c2.01,1.81-2.2-1.87,5.3,4.93l-0.18,0
			c0.65,0.39,1.13,1.01,1.75,1.41c-0.05-0.01-0.07,0.02-0.13-0.03c0.06,0.17,0.42,0.38,0.47,0.33c0.22,0.25-0.1,0.04,1.65,1.33
			c-0.12,0.11,0.28,0.41,0.46,0.46c-0.07,0,0.08,0.14-0.01,0.14c0.15,0.13,0.31,0.22,0.34,0.13c0.39,0.54,0.29,0.38,2.05,1.58
			c-0.06,0.01-0.12,0-0.17-0.01c0.59,0.47,0.48,0.41,1.71,1.3c-0.01,0.01-0.06-0.01-0.1-0.03c0.24,0.09-0.04-0.01,0.56,0.06
			c-0.12,0.03-0.05,0.11,0.01,0.18c-0.04-0.02-0.15,0.25,0.1,0.19c0.06,0.06,0.06,0.1-0.04,0.09c0.07,0.08,0.14-0.03,0.27,0.29
			l-0.12-0.09c0.07,0.05,0.11,0.11,0.16,0.16l0.01-0.12c0.03,0.05,0.06,0.09,0.06,0.11c-0.29,0.1,0.87-0.57,0.34-0.68
			c0.09-0.29,0.12-0.14-0.55-0.63c-0.03,0.05-0.03,0.08-0.2-0.16c0.11,0.01,0.2-0.06-0.16-0.41l0.01,0.12
			c-0.13-0.16-0.33-0.25-0.41-0.41l0.14,0.09l-0.28-0.29l0.11,0.2c-0.58-0.33-0.09,0.09-1.08-1.15c0.2-0.03-0.08-0.34-0.25-0.33
			c0.02,0.02,0.02,0,0.07,0.03c-0.26-0.28-0.34-0.42-0.4-0.53l0.07,0.06c-0.28-0.38,0.04,0.02-1.16-1.24l0.05,0.07
			c-0.18,0.08-0.15-0.31-0.37-0.43c0.1-0.19,0.08,0-0.72-0.76l0.07,0.01c-0.62-0.58-0.4-0.56-1.69-1.82
			c0.13,0.06,0.2,0.12-0.25-0.25c-0.03,0.08,0.01,0.08,0.03,0.19c-0.08-0.01-0.19-0.07-0.27-0.15c0.18,0.11,0.09-0.09,0.06-0.19
			c-0.01,0.03-0.05,0.03-0.06,0.05c-0.31-0.41-0.1,0.05-0.83-0.74c0.07,0.04,0.16,0.11,0.23,0.15c-0.06-0.21-0.09-0.2-0.18-0.31
			l0.01,0.01l-0.19-0.26l0.04,0.08c-0.33,0.01-0.38-0.29-0.71-0.39c0.14,0.07,0.37-0.1-0.25-0.54c0.1,0.03-0.99-0.69-2.18-1.68
			c0.13-0.03,0.3-0.08-0.6-0.46l0.08,0.1c0.02-0.05,0.18-0.14,0.23,0.14l-0.11-0.06c-0.02,0.24,0.4,0.28,0.59,0.44
			c-0.08,0.07-0.2,0.09,0.35,0.51c-0.05,0-0.04,0.1-0.11,0.01c0.18,0.26,0.45,0.24,0.47,0.39l-0.03-0.01
			c0.32,0.31,0.8,0.83,2.27,2.01c-0.04,0.02,0.32,0.41,0.47,0.62l-0.02,0.01c0.13-0.06,0.29,0.15,0.45,0.27
			c-0.06,0.05-0.11,0.05-0.2,0c0.03,0-0.02,0.06,0.33,0.23l-0.1,0.04c0.08,0.04,0.21,0.34,0.84,0.54l-0.15-0.04
			c0.06,0.02,0.3,0.3,0.05,0.11c0.34,0.46,0.14,0.19,0.99,0.99l0,0c0.36,0.23,0,0,0.83,0.94c-0.12,0.08,0.26,0.29,0.85,1.01
			c-0.17-0.1,0,0.08-0.16-0.11c0.14,0.2,0.13,0.28,0.37,0.41c-0.12-0.01,0.03,0.21,0.13,0.28l-0.06-0.17
			c0.09,0.09,0.16,0.18,0.23,0.27l-0.13,0.03c0.19,0.27,0.43,0.54,0.78,0.89c-0.1-0.07-0.22-0.14-0.37-0.23l0.08,0.12
			c-0.1,0.03-0.21-0.16-0.34-0.23c0.05-0.04,0-0.12-0.02-0.19c-0.03,0.04-0.24-0.21-0.36-0.13c-0.23-0.35-0.04-0.24-0.77-0.53
			c0.13,0.01,0.04-0.13,0.25-0.02c-0.45-0.2,0.12,0.14-2.33-1.82c0.02-0.01,0.02-0.03,0.05,0.01c-0.62-0.43,0.01-0.02-0.8-0.8
			l0.05,0.03c-0.17-0.21-0.06-0.09-1.35-0.9c0.03-0.02,0.05-0.03,0.06-0.04c-0.08,0.02-0.23,0-0.28-0.36l0.18,0.16
			c-0.01-0.17-0.23-0.2-0.31-0.3l0.05-0.03c-0.29-0.23-0.38-0.13-0.67-0.41l0,0.06c-0.53-0.26,0.09-0.36-0.93-0.79
			c0.18,0.02,0.43,0.19,0.61,0.27c-0.1-0.1-0.36-0.32-0.46-0.32c0.05,0.03,0.13,0.05,0.15,0.11c-0.46-0.4-1.15-0.71-2.8-3.05
			c-0.02,0.08-0.15,0.01-0.25-0.13l0.1,0.23c-0.38-0.2-0.14-0.37-0.51-0.58l0.14,0.04c-0.19-0.25-0.31-0.24-1.9-1.67l0.06-0.01
			c-0.23-0.14-0.06-0.13-0.51-0.44l0.12,0.04c-0.3-0.4-0.64-0.45-0.95-0.72c0.11-0.11-0.13-0.42-0.25-0.58l0.02-0.01
			c-0.8-1.16-0.05,0.33-4.96-5.23l0.11,0.02c-0.7-0.42-0.32-0.15-1.37-1.32c0.02-0.01,0.06-0.03,0.11,0.02
			c-0.13-0.15-0.44-0.65-0.55-0.46c0.1-0.09-0.29-0.34-0.48-0.48l0.1-0.04c-0.03,0.01-0.26-0.15-0.71-0.55l0.11,0.03
			c-0.23-0.27-0.32,0-0.5-0.6c0.08,0.07-0.33-0.15-0.18-0.15c-0.15-0.37-0.08,0.08-0.52-0.67c0.19,0.23,0.34,0.41,0.32,0.4
			c0.07-0.06-0.08-0.25,0.14-0.19c-0.15-0.13-0.04,0.06-0.61-0.5l0.02,0.01c-0.22-0.23-0.08-0.17-0.43-0.6
			c-0.07,0.07-0.16-0.06-0.13,0.11c-0.02-0.03,0.04,0.04,0.12,0.1l-0.01,0c0.05,0.06,0.12,0.14,0.19,0.22
			c-0.71-0.46-0.21-0.51-1.47-1.7c0.25,0.15,0.21,0.17-0.45-0.55l0.06,0.01c-0.28-0.68-0.98-0.68-1.26-1.45
			c0.02,0.05,0.03,0.08,0.02,0.1c-0.05-0.42-1.49-1.62-2.78-3.61l-0.02,0.09c-0.23-0.48-0.39-0.64-1.28-0.77
			c0.04,0.09,0,0.19,0.43,0.4c-0.27,0.15,0.4,0.18,0.09,0.33c0.11,0.02,0.02,0.33,0.84,0.92c-0.28-0.07,0.28,0.38,0.08,0.45
			c0.24,0.03,0.44,0.43,0.69,0.61c-0.02-0.01-0.06-0.01-0.07-0.04c0.09,0.14,0.08,0.21,0.13,0.31c-0.12-0.16,0.88,0.32,0.2,0.31
			c0.08-0.08,0.31,0.21,0.34,0.1c0.03,0.17,0.05,0.13,0.01,0.27c0.07-0.21,0.65,0.6,0.23,0.43c0.3-0.07,0.55,0.71,1.57,1.29
			c-0.42,0.82-0.03,0.02,1.24,1.73l-0.16,0.02c0.85,0.64,1.18,1.59,3.52,4.03l-0.07-0.03c0.16,0.24,0.37,0.1,0.48,0.36l-0.05-0.03
			C1430.7,253.84,1430.43,253.95,1431.98,255.29z M1451.92,273.48c0.02,0.02,0.05,0.05,0.07,0.07c-0.01,0-0.02-0.01-0.03-0.01
			c0.09,0.06,0.05,0.03,0.22,0.19l-0.13-0.04c0.11,0.09,0.27,0.15,0.32,0.21c-0.15-0.04-0.13-0.04-0.12,0.05
			C1452,273.74,1451.98,273.62,1451.92,273.48z M1452.36,273.79l-0.11-0.03C1452.3,273.76,1452.28,273.73,1452.36,273.79z
			 M1452.45,273.84C1452.45,273.84,1452.45,273.84,1452.45,273.84C1452.45,273.84,1452.45,273.84,1452.45,273.84z M1427.74,250.46
			c0.02,0.02,0.04,0.04,0.08,0.09C1427.79,250.52,1427.83,250.55,1427.74,250.46z M1427.93,249.69c0.07,0,0.08-0.02,0.11,0.1
			C1428.01,249.77,1428.06,249.77,1427.93,249.69z M1428.1,250.76c-0.04-0.01,0.03,0.1,0.05,0.17
			C1428.2,250.95,1427.91,250.74,1428.1,250.76z"
              />
              <path
                className="st10"
                d="M1439.55,254.34l0.04-0.07l0.05,0.04c0.03-0.08-0.03-0.04-0.08-0.03c0.03-0.03,0.04-0.06,0.04-0.08
			l-0.16,0.07c0.04-0.05,0.11-0.07,0.1-0.13c-0.07,0.03-0.08,0.12-0.15,0.12c0.03-0.08,0.08-0.11,0-0.09
			c0.03-0.03,0.05-0.05,0.07-0.05c-0.06,0.01-0.08-0.05-0.15,0c0.04-0.09-0.1-0.06,0.01-0.17c-0.03,0.02-0.13,0.05-0.16,0.03
			c0.17-0.09-0.08-0.09,0.07-0.22c-0.15,0.09-0.16-0.05-0.28-0.04c0.01-0.01,0.04-0.02,0.04-0.01c-0.02-0.04,0-0.08,0.01-0.13
			c-0.1,0.1-0.17,0.07-0.25,0.11c0.05-0.13,0.13-0.09,0.21-0.2c-0.07,0.08-0.14,0.02-0.19,0.1c0.03-0.09,0-0.05,0.07-0.15
			c-0.12,0.12-0.05,0-0.14,0.05c0.02-0.1,0.1-0.13,0.02-0.13c0.17-0.21-0.04,0.05-0.39-0.21c-0.01-0.01-0.08,0.19-0.16,0.09
			c0.01,0.01,0.04-0.01,0.06-0.02l-0.05,0.01c0.12-0.14,0.17,0.01,0.06-0.18l0.02,0.08c0.19-0.17,0.14-0.16-2.12-2.05l0.07-0.11
			c-0.23,0.06,0.18-0.05-0.27-0.16c0.03-0.03,0.06-0.02,0.09-0.03c-0.16,0.08,0.07-0.15-0.12-0.01c0.02-0.07-0.04-0.13-0.1-0.15
			c0.06-0.12,0.08-0.03-0.22-0.26c0.01,0.06-0.09,0.14-0.12,0.13l0.07-0.09c-0.06,0.04-0.19,0.15,0.02-0.04l-0.07-0.01
			c0.02-0.03,0.04-0.03,0.08-0.06c-0.07,0.04,0.01-0.15-0.1-0.08c0.47,0.07-1.12-0.7-1.63-1.61c-0.02,0.02-0.1,0.12-0.05-0.03
			c-0.05,0.31-0.65-0.73-0.65-0.62c-0.23-0.03-0.28-0.27-0.49-0.31c0.03-0.01,0.05-0.04,0.06-0.02c-0.03-0.01-0.14-0.12-0.59-0.38
			c0.11-0.11,0-0.18-0.08-0.15c0.04-0.02,0.01-0.06,0.06-0.1c-0.04-0.03-0.09-0.04-0.14,0.02c0.06-0.2-0.24-0.02-0.11-0.23
			c-0.23-0.1-0.18,0.03-0.77-0.55c0.01-0.01,0.03-0.01,0.05-0.01c-0.02-0.01-0.02-0.05-0.07,0l0.03,0c-0.05,0.1-0.21,0.1-0.26,0.13
			c0.17-0.12-0.05-0.11,0.18-0.26c-0.02,0-0.06,0.01-0.09,0.05c-0.04-0.2-0.01-0.04-0.36-0.45c0.01,0.03-0.07-0.09-0.1,0.05
			c0.05-0.03-0.22-0.04-0.08-0.08l-0.09-0.05c-0.01,0.07,0.01,0.08-0.13,0.12c0.02,0-0.04-0.04-0.01-0.11c0,0,0,0,0,0
			c-0.05-0.01-0.19,0,0.02-0.15c-0.09,0.06-0.11-0.07-0.22-0.01c0.03-0.01,0.07-0.06,0.08-0.07l-0.08,0.05
			c0.01-0.08-0.04-0.02-0.13-0.23l0.04,0.01l0.02-0.06l-0.06,0.04c0.11-0.1-0.3-0.05-0.4-0.32c0.02,0,0.08-0.06,0.07-0.05
			c-0.04-0.08-0.15-0.08-0.28-0.12c0.05,0.02,0-0.25-0.14-0.12c-0.01-0.16,0.14-0.33-0.49-0.3c0.08,0,0.09-0.09,0.1-0.14
			c-0.01,0.02-0.04,0.03-0.06,0.05c0.12-0.18-0.13,0.06-0.08-0.05c-0.03,0-0.19-0.04,0-0.08c-0.12-0.09,0.02,0.05-0.09-0.24
			c-0.21,0.22-0.01,0.02-0.29,0.04c0.14-0.07,0.05,0.01,0.18-0.22c-0.09,0.04,0-0.09-0.12-0.04c0.02-0.03-0.03-0.03,0.04-0.09
			c-0.24,0.28,0-0.22-0.59-0.07c0-0.07,0.21-0.07,0.01-0.16c0.16-0.12-0.19-0.01,0.07-0.15c-0.05-0.01-0.13,0.01-0.18,0
			c0.03-0.05,0.08-0.05,0.12-0.06c-0.01,0.02-0.09-0.05-0.19,0.04c0.06,0.02,0.18,0.13,0.05,0.23c0-0.02,0.17,0.07,0.04,0.19
			c0.06,0.12,0.1,0.19,0.33,0.01l-0.14,0.23l0.08-0.07c0.01,0.01,0.02,0.03-0.02,0.06c0.08-0.02,0.03-0.04,0.09-0.07
			c-0.03,0.11,0.14,0.07,0.03,0.2c0.07-0.05,0.01-0.08,0.09-0.08c-0.17,0.14,0.18,0.03-0.05,0.2c0.2-0.04-0.01,0.22,0.28,0.14
			c-0.05,0.03,0.04,0,0.05,0.23c0.1-0.08,0.13-0.02,0.18-0.01c-0.05,0.05-0.08,0.07-0.12,0.08c0.04,0.02,0.03,0.03,0.13,0.01
			l-0.07,0.06c0.05,0.04,0.17,0,0.18,0.1c0.07-0.06,0.13-0.22,0.23-0.21c-0.03,0.04-0.09,0.1-0.12,0.1c0.02,0,0.04,0.12-0.05,0.1
			c0.01,0.03,0.02,0.09-0.01,0.13c0.03-0.02,0.18-0.12,0.05,0.07c0.04-0.02,0.04,0.04,0.11-0.04c0.01,0.01-0.02,0.05-0.03,0.07
			c0.05-0.02,0,0.02,0.17-0.02c0,0,0,0,0,0c0.09-0.19,0.07,0.24,0.49,0.58c-0.08-0.03,0.01,0.21,0.01,0.04
			c0.03,0.27,0.55,0.24,0.43,0.54l0.03-0.02c-0.01,0.05,0.05,0.1-0.01,0.14c0.06,0,0.06-0.04,0.05-0.07
			c0.01,0.09,0.08,0.08,0.11,0.16c0.05-0.08,0.38-0.01,0.07,0.17c0.11-0.01,0.06,0.13,0.08,0.17l-0.05,0c0.03,0,0.04,0.01,0.06,0.03
			l0.04-0.07c0.01,0.03,0.02,0.04-0.01,0.06c0.05-0.02,0.08-0.11,0.17-0.13c-0.01,0.05,0.09,0.03,0.03,0.11
			c-0.04-0.09-0.07,0.09-0.14,0.05c0.05,0.12-0.06,0.12,0.19,0.05c-0.05,0.04-0.07,0.1-0.05,0.1c-0.03-0.03,0.06-0.09,0.12-0.1
			c0.01,0.07,0.07,0.02,0.09,0.04c0.01-0.05-0.01-0.07,0.1-0.04c-0.03,0.04-0.05,0-0.05,0.02c0.11-0.05-0.01,0.15,0.09,0.14
			c-0.01,0.05-0.08,0.09-0.08,0.12c0.13-0.07-0.03,0.2,0.21,0.03l-0.13-0.04c0.2-0.06,0.12-0.17,0.32,0.26
			c0.02-0.06,0-0.1,0.11-0.01c-0.05,0.05-0.05,0.08-0.07,0.12c0.03-0.04,0.06,0.06,0.16-0.03c0,0.12-0.09,0.17,0.23,0.11
			c-0.07,0.04-0.08,0.1-0.16,0.1c0.1,0.02-0.03,0,0.34-0.02c0.03,0.23-0.08,0.04,0.85,0.84c-0.08,0.05-0.06,0.01-0.11,0.09
			c0.06-0.27,0.01,0.38,0.58,0.37c-0.1,0.05-0.19,0.03-0.27,0.04c0.03,0.03,0.1,0.08,0.16,0.04c-0.04-0.03-0.05-0.04,0.18-0.01
			c-0.19,0.18,0.09,0.18-0.02,0.37c0.16,0.02,0.15,0.21,0.27,0.3c-0.01-0.08,0.29-0.14,0.09,0c0.03-0.02,0.07-0.02,0.09-0.05
			c0,0.07-0.02,0.17-0.12,0.32c0.05-0.06,0.09-0.06,0.11-0.01c-0.04-0.1,0.2,0.06,0.05,0.1c0.07,0.01,0.15,0.14,0.3,0.05
			c-0.01,0.02-0.04,0.08-0.07,0.07c0.1,0.19,0.28-0.03,1.28,1.26c-0.02,0.01,0.12-0.15,0.15,0l-0.01-0.01
			c-0.08,0.12,0,0.14,0.03,0.17l0.13-0.13c-0.09,0.29,0.32,0.17,0.21,0.4c0.06-0.01,0.08,0.02,0.14,0.01
			c-0.05,0.09-0.12,0.07,0.1,0.25l-0.07,0.04c0.15-0.08,0.12,0.24,0.59,0.31c-0.09,0.1,0.07,0.09,0.16,0.09
			c-0.21,0.15,0.07-0.02,0.48,0.46c-0.05-0.05-0.09-0.1-0.08-0.1c-0.11,0.01,0.04,0.42,0.29,0.27c0,0.01-0.01-0.01-0.05-0.01
			c0,0,0,0,0.01,0c-0.02-0.01-0.04-0.03-0.06-0.05c0.05-0.02,0.1-0.03,0.09,0.03c0.06-0.03,0.11-0.07,0.14-0.09
			c0.04,0.09-0.13,0.32,0.38,0.44c-0.11,0.41-0.11,0.1,0.34,0.42c0-0.02,0-0.03,0.01-0.05c0.08,0.1,0.01,0.3,0.6,0.53l-0.06,0.09
			c0.02-0.27,0.18,0.37,0.75,0.4c-0.01,0-0.01,0-0.01,0c0.06-0.08,0.15-0.19,0.27-0.37L1439.55,254.34z M1437.12,253.03
			c-0.02,0-0.02-0.05,0.04-0.03C1437.14,253.01,1437.13,253.02,1437.12,253.03z M1437.37,252.43c0.02-0.01,0.01-0.05,0.02-0.08
			C1437.4,252.38,1437.46,252.4,1437.37,252.43z M1437.49,252.44c0.01,0,0,0,0.03,0.02C1437.51,252.45,1437.5,252.45,1437.49,252.44
			z"
              />
              <path
                className="st10"
                d="M1440.2,252.67c-0.01-0.02,0.17-0.12,0.02-0.05c0.03-0.03,0.05-0.06,0.05-0.07l-0.13,0.09
			c0.04-0.05,0.1-0.08,0.11-0.12c-0.06,0.04-0.09,0.11-0.14,0.13c0.04-0.06,0.09-0.1,0.03-0.07c0.03-0.03,0.05-0.05,0.07-0.06
			c-0.05,0.03-0.04-0.01-0.11,0.05c0.05-0.08-0.05-0.02,0.06-0.13c-0.03,0.02-0.1,0.07-0.12,0.06c0.15-0.11-0.03-0.04,0.12-0.18
			c-0.13,0.11-0.1,0.01-0.18,0.05c0.01-0.01,0.03-0.02,0.03-0.02c0-0.02,0.03-0.06,0.04-0.09c-0.1,0.1-0.14,0.1-0.21,0.15
			c0.08-0.11,0.12-0.1,0.21-0.2c-0.08,0.08-0.1,0.05-0.17,0.12c0.05-0.07,0.02-0.04,0.09-0.12c-0.12,0.12-0.03,0.01-0.12,0.07
			c0.04-0.08,0.11-0.12,0.05-0.1c0.16-0.18-0.04,0.02-0.23-0.01l0.02-0.02c-0.01-0.01-0.1,0.14-0.14,0.11
			c0,0.01,0.03-0.02,0.05-0.03l-0.04,0.02c0.08-0.09,0.04-0.02,0.11-0.08c-0.04,0.01-0.19,0.03-0.01-0.07l-0.01,0.05
			c0.01-0.11,0.07-0.25-0.28-0.29c0.03-0.02,0.06-0.04,0.08-0.08c-0.08,0-0.07-0.09-0.05-0.18l-0.09,0.06
			c0.15-0.15-0.13,0.1,0.02-0.05c-0.13-0.1-0.22,0.1-0.28-0.25c-0.09,0.08-0.23-0.02-0.1-0.15l-0.1,0.06l0.09-0.09
			c-0.06,0.01-0.17,0.18-0.03-0.06l-0.11,0.02c0.03-0.03,0.05-0.03,0.08-0.04c-0.14,0.08,0.09-0.12-0.08,0.02
			c0.04-0.05,0.02-0.08-0.02-0.08c0.03-0.04,0.12-0.05-0.06-0.13c-0.01,0.04-0.12,0.12-0.13,0.11l0.08-0.08
			c-0.06,0.04-0.02,0.04-0.1,0.08c0-0.02,0.09-0.09,0.13-0.11l-0.04,0.01c0.03-0.02,0.04-0.03,0.08-0.06
			c-0.06,0.04,0.06-0.1-0.04-0.03c0.04-0.05-0.05-0.12-0.22,0c-0.01-0.02,0.07-0.24-0.11-0.06c0.14-0.17-0.02,0.01-0.09-0.34
			l-0.11,0.02c0-0.03,0.06-0.03,0.09-0.08c-0.15,0.05-0.04-0.08-0.14-0.05c0.18-0.19-0.09-0.08,0.01-0.23c0,0-0.12,0.11-0.02-0.01
			c-0.12,0.3-0.02-0.28-0.45-0.4c0.02-0.01,0.05-0.03,0.06-0.03c0.06-0.06-0.03-0.05-0.09-0.01c0.06-0.03,0.05-0.1,0.06-0.1
			c-0.04,0.02-0.02-0.04-0.09,0.02c0.02-0.05-0.07-0.02-0.03-0.07c-0.1,0.05,0.01-0.07-0.11,0.02c0.13-0.09,0.07-0.12,0-0.09
			c0.04-0.02,0.03-0.04,0.09-0.08c-0.01-0.02-0.05-0.01-0.11,0.04c0.13-0.14-0.17,0.03,0.02-0.13c-0.1,0-0.18-0.01-0.25-0.03
			c0.09-0.11,0.11-0.03-0.09-0.2c0.01-0.01,0.03-0.01,0.04-0.02c-0.01,0,0.01-0.03-0.05,0.01l0.02,0c-0.08,0.07-0.2,0.1-0.24,0.13
			c0.18-0.11,0.02-0.07,0.24-0.2c-0.01,0-0.05,0.02-0.09,0.04c0.01-0.02,0.03-0.03,0.08-0.06c-0.05,0-0.11,0.05-0.12,0.02
			c0.03-0.02,0.04-0.02,0.08-0.04c-0.01,0.01-0.15,0.03-0.08-0.01c-0.02-0.04-0.24,0-0.03-0.15c-0.01,0.02-0.02-0.04-0.09,0.05
			c0.04-0.02-0.15,0-0.02-0.04c-0.04-0.06-0.26,0.19-0.15,0c-0.06,0-0.11,0.02,0.08-0.1c-0.1,0.05-0.05-0.03-0.16,0.03
			c0.03-0.01,0.08-0.05,0.09-0.06l-0.08,0.04c0.19-0.08-0.19-0.06,0.07-0.19c-0.05-0.03-0.19,0-0.19-0.05
			c-0.17,0.14-0.01,0-0.04-0.04c0.01,0,0.09-0.05,0.07-0.04c0.01-0.04-0.07-0.03-0.15-0.04c0.03-0.02,0.07-0.02,0.04-0.11
			c-0.04,0.02-0.04,0.01-0.1,0.05c0.12-0.14,0.08-0.28-0.22-0.13c0.06-0.01,0.11-0.07,0.14-0.1l-0.07,0.04
			c0.17-0.12-0.14,0.06-0.04-0.02c-0.04,0-0.11-0.01,0.03-0.05c-0.06-0.03,0,0.01,0.05-0.14c-0.26,0.16-0.01,0.01-0.24,0.06
			c0.15-0.06,0,0.02,0.24-0.16c-0.08,0.03,0.04-0.06-0.07-0.01c0.03-0.02-0.01-0.02,0.07-0.06c0.19,0.18-0.37-0.16-0.2-0.18
			c-0.03,0-0.1,0.01-0.14,0.02c0.05-0.03,0.08-0.04,0.12-0.05c-0.29,0.03,0,0.05-0.24,0.18c0.04,0,0.24-0.13-0.03,0.09l0.05-0.01
			c-0.03,0.01-0.09,0.06-0.07,0.04c0,0.09,0.01,0.09,0.24-0.03l-0.21,0.16l0.09-0.05c0,0.01,0,0.02-0.04,0.04
			c0.07-0.02,0.04-0.03,0.1-0.05c-0.07,0.07,0.07,0.03-0.07,0.12c0.07-0.04,0.04-0.05,0.1-0.06c-0.18,0.1,0.11,0.01-0.13,0.13
			c0.16-0.06-0.02,0,0.06,0.04c-0.08,0.07-0.12,0,0.08,0.02c-0.05,0.01,0.02,0.02-0.07,0.14c0.11-0.06,0.11-0.03,0.14-0.02
			c-0.07,0.04-0.09,0.05-0.13,0.06c0.03,0.02,0.01,0.01,0.09-0.01l-0.08,0.05c0.02,0.02,0.12-0.02,0.09,0.05
			c0.08-0.05,0.2-0.16,0.27-0.16c-0.07,0.05-0.02-0.01-0.28,0.23c0.03-0.02,0.19-0.1,0,0.04c0.04-0.02,0.01,0.02,0.1-0.04
			c0,0.01-0.04,0.03-0.06,0.05c0.05-0.02,0,0.01,0.14-0.03l0,0c0.14-0.02-0.02,0.34,0.15,0.69c0.04-0.01,0.06-0.03,0.07-0.06
			c-0.03,0.06,0.03,0.04,0.01,0.09c0.06-0.04,0.01,0,0.17-0.06c-0.04,0.05-0.13,0.08-0.07,0.08c-0.02,0.04-0.09,0.07-0.12,0.08
			c0.07-0.05,0.02,0.08-0.05,0.1c0.02,0,0.03,0,0.03,0.01l0.06-0.05c-0.01,0.02,0,0.02-0.03,0.04c0.05-0.02,0.11-0.08,0.18-0.11
			c-0.03,0.03,0.06,0-0.03,0.07c0.01-0.05-0.09,0.07-0.12,0.05c-0.02,0.1-0.08,0.07,0.12,0.01c-0.05,0.03-0.09,0.07-0.08,0.07
			c-0.01-0.01,0.08-0.07,0.13-0.08c-0.03,0.05,0.04,0,0.05,0.01c0.03-0.04,0.02-0.04,0.09-0.04c-0.04,0.03-0.04,0.01-0.05,0.02
			c0.1-0.05-0.07,0.1,0.01,0.08c-0.03,0.03-0.09,0.07-0.11,0.09c0.09-0.01-0.04,0.1,0.15-0.01l-0.08-0.01
			c0.16-0.07,0.17-0.19,0.11,0.19c0.04-0.03,0.02,0.03,0.13-0.05c-0.24,0.18,0.3-0.06-0.03,0.12c0.01,0.01,0,0.03,0.04,0
			c-0.03-0.01,0.05,0.05,0.22-0.07c-0.15,0.16,0.07,0.07-0.05,0.21c0.03,0.01,0.07,0.02,0.09,0.04c0.07-0.09-0.11,0.25,0.25,0.16
			c-0.08,0.05-0.05,0.02-0.11,0.08c0.02,0,0.11-0.09,0.15-0.09c-0.04,0.02-0.16,0.18-0.18,0.2c0.04,0.04,0.35-0.13,0.14,0.06
			c0.04-0.01,0.14-0.04,0.18-0.03c-0.09,0.05-0.15,0.06-0.21,0.08c0.24-0.13-0.17,0.28,0.34,0.26c-0.04,0.03-0.12,0.07-0.15,0.12
			c0.05-0.06,0.1-0.06,0.15-0.12c0.01,0.01-0.04,0.07-0.23,0.27c0.06-0.05,0.09-0.06,0.08-0.03c-0.02-0.04,0.13,0,0,0.06
			c0.05-0.01,0.05,0.07,0.2-0.02c-0.1,0.03-0.01,0.23,0.3,0.2c-0.06,0.1-0.34,0.31,0.1,0.21c-0.1,0.14,0.03,0.14-0.01,0.24
			c0.02-0.03,0.09-0.08,0.11-0.08c-0.01,0.03,0.1-0.04,0,0.05l0-0.01c-0.1,0.1-0.05,0.1-0.04,0.11l0.14-0.12
			c-0.12,0.17,0.04,0.41,0.35,0.47c-0.1,0.09,0.02,0.04,0.08,0.02l-0.07,0.06c0.11-0.07,0.13-0.09,0.18-0.09
			c0,0.01-0.05,0.06-0.04,0.03c0.02,0.14,0.15-0.14,0.05,0.11c0.05-0.05,0.1,0.01,0.08,0.09c-0.02-0.02-0.03-0.05-0.03-0.05
			c0.02-0.04-0.1,0.33,0.12,0.11c0,0.01,0,0-0.03,0.01l0.01-0.01l-0.03-0.02c0.04-0.03,0.08-0.05,0.05,0
			c0.05-0.04,0.1-0.08,0.13-0.1c0.01,0.02-0.18,0.29,0.13,0.21c-0.02,0.06-0.1,0.07-0.05,0.08c-0.18,0.18,0.06,0.09,0.18,0.09
			c0,0.03-0.01,0.28,0.26,0.21l-0.07,0.08c0.15-0.17,0.1,0.07,0.17,0.02c0,0.01,0.01,0.06,0.01,0.09l0.1-0.04
			c-0.11,0.17-0.24,0.43,0.41-0.34L1440.2,252.67z M1438.91,252.4c-0.02,0.01,0-0.02,0.04-0.03
			C1438.93,252.38,1438.92,252.39,1438.91,252.4z M1439.32,251.85c0,0.01,0.01,0.01,0.01,0.02
			C1439.32,251.88,1439.26,251.95,1439.32,251.85z"
              />
              <path
                className="st10"
                d="M1439.01,252.32c0.01,0,0,0,0.02-0.02L1439.01,252.32L1439.01,252.32z"
              />
              <path
                className="st10"
                d="M1446.01,241.05c0.02-0.06-0.03-0.21-1.54-0.25l0-0.13c-0.18,0.17,0.14-0.13-0.34-0.01
			c0.01-0.04,0.04-0.05,0.07-0.07c-0.11,0.15-0.02-0.16-0.11,0.05c-0.02-0.07-0.11-0.09-0.18-0.08l0.03-0.07
			c-0.11-0.05-0.25-0.03-0.38-0.06c0.04,0.05,0,0.17-0.04,0.17l0.01-0.11c-0.04,0.07-0.1,0.23-0.01-0.04l-0.07,0.02
			c0-0.03,0.02-0.05,0.04-0.09c-0.04,0.06-0.07-0.14-0.14-0.03c0.46-0.17-1.4-0.08-2.4-0.65c-0.01,0.02-0.02,0.15-0.06,0
			c0.17,0.45-0.49-0.33-1.57-0.3c0.02-0.02,0.03-0.06,0.05-0.05c-0.04,0-0.18-0.04-0.75-0.06c0.05-0.15-0.09-0.16-0.16-0.1
			c0.03-0.04-0.03-0.06,0.01-0.12c-0.05-0.01-0.11,0-0.12,0.08c-0.05-0.21-0.23,0.09-0.23-0.15c-0.27,0.02-0.14,0.11-1.01-0.13
			c0-0.01,0.02-0.03,0.04-0.03c-0.02,0-0.05-0.04-0.06,0.03l0.03-0.01c0.01,0.11-0.15,0.18-0.17,0.24c0.09-0.19-0.1-0.08,0.03-0.32
			c-0.02,0.01-0.05,0.04-0.06,0.08c-0.15-0.16-0.03-0.03-0.57-0.24c0.03,0.03-0.11-0.05-0.07,0.09c0.04-0.05-0.23,0.07-0.11-0.04
			l-0.11-0.01l0.05,0.06c-0.12,0.06-0.07,0.19-0.23-0.14c-0.06,0.1-0.14-0.01-0.21,0.09c0.02-0.02,0.04-0.09,0.04-0.1l-0.05,0.08
			c-0.03-0.08-0.06,0-0.24-0.14l0.05-0.01l-0.02-0.06l-0.03,0.06c0.05-0.14-0.3,0.1-0.55-0.1c0.02-0.01,0.05-0.09,0.04-0.07
			c-0.07-0.05-0.18,0-0.32,0.02c0.06,0-0.13-0.23-0.2-0.04c-0.09-0.14-0.04-0.36-0.62-0.05c0.07-0.04,0.04-0.12,0.02-0.18
			c0,0.02-0.02,0.05-0.02,0.07c0.02-0.21-0.09,0.12-0.1,0c-0.03,0.01-0.2,0.05-0.05-0.07c-0.16-0.02,0.04,0.04-0.21-0.18
			c-0.08,0.29,0,0.02-0.25,0.17c0.1-0.12,0.05-0.01,0.05-0.28c-0.06,0.07-0.05-0.08-0.14,0.02c0.01-0.03-0.04-0.02-0.01-0.1
			c-0.09,0.36-0.11-0.21-0.6,0.2c-0.03-0.06,0.16-0.16-0.07-0.15c0.09-0.19-0.18,0.07-0.01-0.17c-0.05,0.02-0.11,0.07-0.17,0.08
			c0-0.06,0.05-0.08,0.08-0.11c0,0.02-0.13-0.02-0.17,0.14c0.13-0.22-0.13,0.19,0.24,0.04l-0.07,0.13c0.06-0.18,0.19,0.06,0.13,0.16
			c0.11,0.08,0.2,0.13,0.32-0.14l-0.02,0.27l0.04-0.1c0.01,0.01,0.03,0.02,0.02,0.06c0.06-0.06,0.01-0.05,0.05-0.11
			c0.03,0.11,0.17,0,0.13,0.17c0.04-0.07-0.04-0.08,0.04-0.11c-0.09,0.2,0.18-0.05,0.06,0.21c0.17-0.12,0.11,0.2,0.34,0
			c-0.03,0.05,0.04-0.02,0.17,0.19c0.05-0.12,0.11-0.08,0.17-0.09c-0.02,0.07-0.04,0.1-0.08,0.13c0.04,0,0.05,0.02,0.13-0.04
			l-0.04,0.09c0.07,0.01,0.16-0.07,0.23,0.01c0.03-0.08,0-0.26,0.11-0.29c-0.01,0.05-0.03,0.13-0.06,0.14
			c0.01-0.01,0.1,0.09,0.01,0.11c0.03,0.02,0.06,0.07,0.06,0.13c0.01-0.04,0.11-0.19,0.08,0.04c0.03-0.04,0.06,0.02,0.09-0.08
			c0.02,0.01,0.01,0.05,0.01,0.08c0.04-0.04,0,0,0.15-0.1l0,0c0.11-0.16,0.1,0.1,0.67,0.24c-0.07,0.04,0,0.06-0.06,0.03
			c0.06,0.05,0.06,0.1,0.15,0.03c-0.09,0.01,0.12,0.18,0.03,0.03c0.16,0.22,0.65-0.03,0.69,0.29l0.02-0.04
			c0.02,0.05,0.1,0.07,0.07,0.13c0.06-0.03,0.04-0.06,0.01-0.09c0.06,0.08,0.12,0.04,0.19,0.1c-0.01-0.09,0.35-0.19,0.15,0.12
			c0.09-0.06,0.12,0.09,0.16,0.11l-0.05,0.02c0.03-0.01,0.05-0.01,0.07,0v-0.09c0.02,0.02,0.04,0.03,0.03,0.06
			c0.04-0.05,0.02-0.14,0.08-0.2c0.02,0.05,0.1-0.02,0.08,0.09c-0.08-0.06-0.02,0.11-0.1,0.1c0.11,0.08,0.01,0.13,0.21-0.04
			c-0.03,0.06-0.01,0.12,0.01,0.11c-0.04-0.01,0.01-0.1,0.06-0.14c0.05,0.06,0.07-0.02,0.1,0c-0.02-0.05-0.05-0.05,0.07-0.08
			c-0.01,0.05-0.05,0.02-0.04,0.05c0.08-0.09,0.08,0.14,0.16,0.08c0.02,0.05-0.03,0.11-0.01,0.15c0.08-0.12,0.08,0.2,0.22-0.07
			l-0.14,0.02c0.16-0.15,0.02-0.21,0.44,0.08c-0.01-0.06-0.05-0.09,0.09-0.06c-0.02,0.07,0,0.1,0,0.14c0.01-0.05,0.08,0.03,0.13-0.1
			c0.06,0.11,0,0.19,0.27-0.01c-0.05,0.07-0.02,0.12-0.09,0.16c0.11-0.03-0.03,0.01,0.31-0.17c0.04,0.17,0.05,0.18,0.52,0.26
			c-0.01,0.02-0.01,0.03-0.02,0.02c0.29-0.09-0.09,0.24,0.48,0.15c0,0-0.01,0.02-0.01,0.03c0.08-0.1,0.2-0.11,0.28-0.12
			c-0.05,0.08-0.05,0.04-0.06,0.13c-0.09-0.27,0.21,0.33,0.74,0.05c-0.06,0.09-0.16,0.12-0.22,0.16c0.04,0.01,0.13,0.02,0.17-0.04
			c-0.05-0.01-0.07-0.01,0.17-0.09c-0.08,0.26,0.18,0.12,0.18,0.33c0.16-0.06,0.26,0.11,0.42,0.13c-0.02-0.1,0.21-0.28,0.08,0.02
			c-0.02-0.08,0.06-0.09,0.07-0.14c0.04,0.06,0.07,0.16,0.07,0.34c0.01-0.08,0.06-0.1,0.09-0.06c-0.1-0.08,0.21-0.04,0.1,0.06
			c0.07-0.02,0.21,0.06,0.31-0.1c0,0.03,0,0.09-0.03,0.1c0.84,0.23,0.54,0.02,1.88,0.51c-0.01,0.01,0.03-0.19,0.14-0.07l-0.01-0.01
			c-0.01,0.14,0.07,0.12,0.12,0.14l0.05-0.18c0.08,0.3,0.4,0,0.42,0.25c0.05-0.04,0.09-0.03,0.13-0.06
			c0.01,0.11-0.06,0.11,0.23,0.17l-0.04,0.06c0.11-0.15,0.23,0.16,0.72-0.01c-0.03,0.13,0.12,0.04,0.19,0
			c-0.09,0.22,0-0.03,0.7,0.17c-0.08-0.02-0.14-0.04-0.13-0.05c-0.1,0.08,0.27,0.34,0.42,0.09c0.01,0.01-0.02,0-0.05,0.02l0-0.01
			c-0.02,0-0.05-0.01-0.08-0.01c0.04-0.04,0.07-0.08,0.1-0.02c0.03-0.06,0.07-0.12,0.08-0.14c0.1-0.04,0.35,0.33,1.15,0.4
			c-0.01-0.02-0.02-0.03-0.01-0.05c0.14,0.05,0.16,0.25,0.86,0.17l-0.01,0.11c-0.12-0.24,0.37,0.23,0.92-0.02
			c-0.01,0-0.01,0-0.01,0.01c0.01-0.1,0.03-0.23,0.03-0.45l-0.1,0.08l-0.01-0.08l0.06,0.01c-0.02-0.08-0.06-0.02-0.09,0.02
			c0-0.04,0-0.07-0.01-0.09l-0.11,0.13c0.01-0.06,0.06-0.12,0.02-0.16c-0.05,0.06,0,0.15-0.07,0.18c-0.02-0.08,0.01-0.14-0.05-0.08
			c0-0.04,0.02-0.07,0.04-0.08c-0.05,0.04-0.1,0-0.14,0.08c-0.02-0.1-0.13-0.01-0.09-0.15c-0.02,0.03-0.09,0.1-0.13,0.1
			c0.1-0.17-0.12-0.04-0.06-0.23c-0.08,0.15-0.18,0.03-0.28,0.1c0.01-0.01,0.02-0.04,0.03-0.03c-0.04-0.02-0.04-0.08-0.07-0.11
			c-0.04,0.13-0.11,0.15-0.17,0.22c-0.03-0.14,0.07-0.15,0.08-0.28c-0.02,0.11-0.12,0.09-0.12,0.18c-0.02-0.09-0.03-0.05-0.02-0.16
			c-0.05,0.17-0.05,0.02-0.11,0.11c-0.04-0.1,0.02-0.16-0.06-0.13c0.03-0.27-0.01,0.07-0.47,0.01c-0.02,0,0.03,0.21-0.1,0.16
			c0.02,0.01,0.03-0.03,0.04-0.05l-0.04,0.03c0.17-0.13-0.07-0.23,0.01-0.13c-0.04-0.19,0.15-0.2-0.18-0.21
			c0.02,0.32-0.25-0.29-0.87-0.04C1446.66,241.08,1445.89,241.12,1446.01,241.05z M1446.53,242.01c-0.01,0.02-0.01,0.03-0.02,0.05
			C1446.48,242.05,1446.48,242.02,1446.53,242.01z M1446.56,241.35c-0.01,0-0.02,0-0.03,0
			C1446.54,241.35,1446.52,241.35,1446.56,241.35z M1446.41,241.4c0.01-0.02-0.01-0.05-0.03-0.08
			C1446.41,241.34,1446.48,241.33,1446.41,241.4z"
              />
              <path
                className="st10"
                d="M1440.91,242.92c0.02-0.07,0.1-0.04,0.13,0.04c-0.04-0.01-0.06-0.03-0.06-0.03
			c-0.01-0.03,0.11,0.34,0.19,0.05c0,0.01-0.01,0-0.03,0.02c0,0,0-0.01,0-0.01c-0.01,0-0.02,0-0.04,0c0.03-0.04,0.05-0.08,0.05-0.02
			c0.03-0.06,0.05-0.12,0.07-0.15c0.03,0.02-0.01,0.34,0.26,0.13c0.01,0.07-0.05,0.11,0,0.1c-0.02,0.27-0.02,0.04,0.23,0.04
			c0-0.02,0-0.03,0-0.05c0.02,0.03,0.14,0.25,0.39,0.08l-0.02,0.1c0.06-0.23,0.14,0.03,0.19-0.05c0.01,0.01,0.05,0.05,0.06,0.08
			l0.08-0.08c-0.02,0.2,0.01,0.49,0.22-0.49c-0.1,0.16,0.03-0.04-0.07,0.03c0.01-0.04,0.01-0.07,0.01-0.09l-0.08,0.14
			c0.01-0.06,0.05-0.12,0.04-0.16c-0.04,0.06-0.03,0.14-0.07,0.18c0.01-0.08,0.03-0.13-0.01-0.08c0.01-0.04,0.02-0.07,0.03-0.08
			c-0.03,0.04-0.05,0.01-0.08,0.09c0.01-0.09-0.06,0.01-0.02-0.14c-0.02,0.03-0.06,0.11-0.08,0.11c0.08-0.17-0.05-0.03,0.01-0.21
			c-0.07,0.15-0.09,0.05-0.16,0.13c0.01-0.01,0.02-0.04,0.02-0.03c-0.02-0.02-0.01-0.07-0.01-0.1c-0.04,0.13-0.08,0.15-0.12,0.23
			c0.01-0.13,0.06-0.15,0.09-0.27c-0.03,0.11-0.08,0.09-0.09,0.18c0-0.08,0-0.04,0.02-0.15c-0.05,0.16-0.03,0.02-0.07,0.12
			c0-0.09,0.04-0.16-0.01-0.12c0.06-0.24-0.03,0.03-0.24,0.09c0-0.01,0-0.02,0.01-0.03c-0.01,0-0.01,0.17-0.08,0.16
			c0.01,0,0.02-0.03,0.03-0.05l-0.03,0.03c0.02-0.12,0.03-0.03,0.06-0.12c-0.03,0.03-0.18,0.11-0.05-0.06l0.02,0.05
			c-0.05-0.11-0.07-0.26-0.47-0.14c0.01-0.03,0.03-0.07,0.03-0.1c0.01,0.13-0.37-0.07-0.89-0.29l-0.06,0.1l0.02-0.13
			c-0.05,0.04-0.04,0.24-0.07-0.05l-0.1,0.07c0.01-0.04,0.03-0.05,0.05-0.08c-0.08,0.14,0.01-0.15-0.06,0.06
			c0-0.07-0.04-0.08-0.07-0.06c0.01-0.07,0.07-0.1-0.15-0.09c0.01,0.04-0.03,0.16-0.05,0.17l0.03-0.11
			c-0.03,0.06,0.01,0.05-0.04,0.12c-0.02-0.02,0.02-0.13,0.05-0.16l-0.04,0.03c0.01-0.03,0.02-0.05,0.03-0.09
			c-0.03,0.06-0.01-0.12-0.06-0.01c0.14-0.23-0.08,0.07-0.75-0.19c-0.11,0.12-0.09-0.05-0.17,0.03c0.04-0.27-0.15-0.03-0.15-0.2
			c0,0.01-0.04,0.16-0.03,0c0.11,0.31-0.23-0.23-0.73-0.1c0.01-0.02,0.02-0.06,0.03-0.05c0.01-0.09-0.07-0.02-0.09,0.04
			c0.03-0.07-0.02-0.11-0.03-0.12c-0.02,0.04-0.05-0.03-0.07,0.07c-0.02-0.05-0.08,0.02-0.08-0.04c-0.05,0.1-0.04-0.07-0.09,0.08
			c0.05-0.15-0.02-0.14-0.06-0.07c0.02-0.04,0-0.05,0.02-0.11c-0.02-0.01-0.05,0.02-0.07,0.1c0.01-0.19-0.13,0.12-0.09-0.12
			c-0.1,0.06-0.18,0.1-0.25,0.11c0.01-0.15,0.07-0.09-0.23-0.11c0-0.01,0.01-0.03,0.02-0.04c-0.01,0-0.02-0.03-0.04,0.04l0.02-0.02
			c-0.01,0.1-0.1,0.2-0.12,0.25c0.08-0.19-0.04-0.07,0.07-0.31c-0.01,0.01-0.03,0.04-0.04,0.09c-0.03-0.11,0.02-0.02-0.25-0.16
			c0.01,0.02-0.04-0.04-0.05,0.09c0.02-0.05-0.13,0.09-0.05-0.02l-0.06,0.01c0.02,0.03-0.05,0.28-0.07,0.08c0,0,0,0,0,0
			c-0.05,0.03-0.09,0.08-0.01-0.13c-0.04,0.1-0.07,0.01-0.12,0.12c0.01-0.02,0.03-0.09,0.04-0.1l-0.04,0.08
			c0.1-0.19-0.22,0.07-0.09-0.2c-0.06,0.01-0.16,0.12-0.21,0.07c-0.04,0.22,0,0.01-0.06-0.01c0.01-0.01,0.04-0.1,0.03-0.08
			c-0.03-0.04-0.09,0.02-0.16,0.06c0.01-0.05,0.05-0.06-0.05-0.11c-0.02,0.04-0.03,0.03-0.04,0.1c-0.01-0.18-0.15-0.29-0.3,0.03
			c0.04-0.04,0.04-0.12,0.04-0.17c-0.01,0.02-0.02,0.05-0.02,0.07c0.05-0.21-0.07,0.14-0.05,0.01c-0.03,0.02-0.1,0.06-0.01-0.06
			c-0.07,0.01,0.01,0.01-0.08-0.14c-0.09,0.3,0,0.01-0.15,0.2c0.07-0.14,0.02,0.01,0.07-0.28c-0.04,0.08-0.01-0.07-0.07,0.04
			c0.1-0.15,0.13-0.03-0.31-0.11c-0.03,0.02-0.07,0.08-0.1,0.1c0.01-0.06,0.04-0.08,0.06-0.12c-0.22,0.21,0.04,0.03-0.05,0.3
			c0-0.02,0.1-0.02,0.04,0.13c0.07,0.07,0.08,0.06,0.18-0.18l-0.05,0.26l0.04-0.1c0,0.01,0.01,0.02,0,0.06
			c0.04-0.06,0.01-0.05,0.04-0.11c0,0.1,0.08-0.02,0.04,0.14c0.03-0.07-0.01-0.07,0.04-0.11c-0.07,0.2,0.1-0.06,0,0.19
			c0.09-0.16-0.02,0.01,0.08-0.01c-0.01,0.11-0.1,0.08,0.08-0.03c-0.03,0.04,0.03,0,0.05,0.16c0.04-0.12,0.07-0.09,0.1-0.11
			c-0.02,0.07-0.04,0.1-0.06,0.13c0.04-0.01,0.02,0,0.07-0.06l-0.03,0.09c0.03,0,0.09-0.09,0.11-0.02c0.03-0.09,0.04-0.25,0.1-0.29
			c-0.02,0.08-0.04,0.04-0.06,0.36c0.01-0.04,0.08-0.19,0.03,0.03c0.02-0.04,0.03,0.01,0.06-0.09c0.01,0,0,0.05-0.01,0.08
			c0.02-0.04,0.01,0.01,0.09-0.11c0.1-0.11,0.23,0.28,0.67,0.47c0.03-0.03,0.03-0.06,0.02-0.09c0.02,0.07,0.05,0.02,0.08,0.07
			c0.02-0.07,0.01-0.01,0.1-0.14c0,0.07-0.05,0.14,0,0.1c0.01,0.05-0.03,0.11-0.05,0.14c0.02-0.08,0.07,0.05,0.03,0.12
			c0.02-0.02,0.02-0.01,0.03-0.01l0.01-0.08c0.01,0.02,0.01,0.02,0,0.05c0.03-0.05,0.03-0.13,0.07-0.2c0,0.04,0.05-0.03,0.03,0.08
			c-0.03-0.05-0.03,0.11-0.07,0.11c0.05,0.09-0.02,0.11,0.11-0.06c-0.02,0.06-0.02,0.12-0.01,0.11c-0.02-0.01,0.02-0.1,0.05-0.14
			c0.01,0.05,0.04-0.03,0.05-0.02c0-0.05-0.01-0.04,0.05-0.09c-0.01,0.05-0.03,0.03-0.03,0.05c0.05-0.1,0.01,0.12,0.07,0.06
			c0,0.04-0.03,0.11-0.03,0.14c0.07-0.06,0.05,0.11,0.12-0.1l-0.07,0.04c0.04-0.19,0.24-0.11,0.24,0.09
			c0.01-0.05,0.04,0.02,0.08-0.11c-0.09,0.29,0.22-0.22,0.06,0.12c0.06-0.05-0.01,0.01,0.18-0.2c-0.02,0.22,0.12,0.02,0.11,0.21
			c0.04-0.01,0.08-0.02,0.11-0.02c-0.01-0.11,0.07,0.26,0.35,0c-0.04,0.08-0.03,0.04-0.05,0.13c0.02-0.01,0.04-0.14,0.08-0.16
			c0,0.11-0.08,0.4,0.15,0.24c0.03-0.03,0.1-0.11,0.14-0.12c-0.05,0.09-0.1,0.13-0.14,0.18c0.17-0.13-0.01-0.12,0.49,0.05
			c-0.02,0.05-0.06,0.12-0.05,0.17c0-0.08,0.05-0.1,0.06-0.17c0.03,0.01,0.02,0.09-0.03,0.35c0.02-0.07,0.04-0.1,0.06-0.07
			c-0.04-0.03,0.12-0.07,0.04,0.05c0.04-0.03,0.1,0.03,0.17-0.13c-0.07,0.09,0.14,0.2,0.42,0.03c0.01,0.13-0.11,0.44,0.24,0.14
			c-0.01,0.17,0.12,0.11,0.15,0.22c0-0.03,0.04-0.11,0.05-0.13c0.01,0.03,0.07-0.09,0.03,0.04l0,0c-0.03,0.14,0.01,0.11,0.04,0.12
			l0.06-0.18c-0.01,0.28,0.2-0.04,0.16,0.2c0.03-0.04,0.05-0.03,0.08-0.07c0.01,0.03-0.09,0.14,0.08,0.14
			c-0.03,0.1-0.05,0.12,0.12-0.19c0.01,0.05-0.1,0.2,0.12,0.19c-0.01,0.02-0.02,0.06-0.03,0.06c0.02,0.01,0.07,0.12,0.12-0.07
			c-0.04,0.13,0.05,0.03,0.09-0.02l-0.03,0.09c0.06-0.11,0.07-0.14,0.11-0.17c0,0.01-0.02,0.08-0.02,0.05
			C1440.92,242.94,1440.82,242.65,1440.91,242.92z M1441.2,242.42c-0.01,0.02-0.03,0.11-0.03-0.01
			C1441.18,242.42,1441.17,242.41,1441.2,242.42z M1441.12,243.06c-0.01,0.02-0.01,0.03-0.02,0.05
			C1441.09,243.12,1441.09,243.08,1441.12,243.06z"
              />
              <path
                className="st10"
                d="M1412.77,222.76c-0.01,0.06,0.17,0.29,0.47,0.16l-0.01,0c-0.01,0.14,0.06,0.12,0.11,0.13l0.04-0.18
			c0.07,0.29,0.34-0.03,0.36,0.21c0.04-0.04,0.07-0.03,0.11-0.07c0.01,0.09-0.06,0.13,0.2,0.15l-0.03,0.07
			c0.06-0.14,0.23,0.13,0.62-0.08c-0.02,0.13,0.1,0.03,0.16-0.02c-0.08,0.24,0.03-0.06,0.61,0.09c-0.07-0.01-0.12-0.03-0.12-0.03
			c-0.08,0.06,0.27,0.32,0.36,0.04c0.01,0.01-0.01,0-0.04,0.02l0-0.01c-0.02,0-0.04,0-0.07,0c0.03-0.05,0.06-0.08,0.08-0.03
			c0.02-0.06,0.05-0.12,0.06-0.15c0.07,0.04,0.08,0.33,0.52,0.11c0.15,0.38-0.02,0.15,0.48,0.12c-0.01-0.02-0.02-0.02-0.02-0.05
			c0.11,0.02,0.2,0.22,0.74,0.02l0.01,0.11c-0.17-0.2,0.38,0.14,0.76-0.23c-0.01,0-0.01,0-0.01,0.01c-0.01-0.1-0.03-0.23-0.07-0.45
			l-0.06,0.1l-0.02-0.08l0.05,0c-0.03-0.07-0.05-0.01-0.07,0.04c-0.01-0.04-0.01-0.07-0.03-0.09l-0.06,0.16
			c-0.01-0.06,0.03-0.13-0.02-0.16c-0.03,0.07,0.03,0.14-0.02,0.19c-0.03-0.07-0.02-0.13-0.06-0.07c0-0.04,0-0.07,0.02-0.09
			c-0.03,0.05-0.08,0.02-0.1,0.11c-0.03-0.09-0.1,0.02-0.1-0.13c-0.01,0.04-0.06,0.12-0.08,0.13c0.06-0.18-0.1-0.01-0.09-0.21
			c-0.04,0.17-0.14,0.07-0.21,0.16c0-0.01,0.01-0.04,0.02-0.03c-0.04-0.02-0.05-0.07-0.07-0.1c-0.01,0.14-0.07,0.16-0.11,0.25
			c-0.04-0.14,0.04-0.16,0.03-0.29c0,0.11-0.09,0.11-0.08,0.2c-0.03-0.08-0.03-0.04-0.04-0.16c-0.02,0.17-0.03,0.03-0.07,0.13
			c-0.05-0.09,0-0.16-0.06-0.11c0.09-0.21-0.27-0.02-0.45,0.26c0.02,0,0.02-0.03,0.03-0.05l-0.03,0.03c0.01-0.18,0.13-0.1-0.06-0.18
			l0.06,0.05c-0.1-0.26-0.45,0.16-2.16-0.41c-0.04,0.09-0.27,0.14-0.4-0.1l-0.07,0.1l0-0.13c-0.11,0.14,0.06-0.17-0.28,0.01
			c0.01-0.04,0.04-0.05,0.06-0.08c-0.09,0.15-0.02-0.16-0.1,0.06c-0.02-0.07-0.09-0.09-0.15-0.07c-0.01-0.12,0.05-0.08-0.29-0.1
			c0.03,0.04,0,0.17-0.04,0.17l0.01-0.11c-0.03,0.07-0.08,0.24-0.01-0.04l-0.06,0.03c0-0.03,0.02-0.05,0.03-0.09
			c-0.03,0.07-0.06-0.13-0.11-0.01c0.41-0.19-1.21,0.01-2.01-0.49c-0.01,0.02-0.02,0.16-0.05,0c0.1,0.28-0.87-0.29-0.79-0.19
			c-0.2,0.09-0.34-0.09-0.53-0.02c0.02-0.02,0.03-0.06,0.04-0.05c-0.01-0.09-0.13-0.04-0.14,0.03c0.12,0.04-0.26-0.17-0.49-0.06
			c0.04-0.15-0.07-0.15-0.13-0.09c0.03-0.04-0.02-0.06,0.01-0.12c-0.04-0.01-0.09,0.01-0.11,0.09c-0.03-0.2-0.2,0.1-0.19-0.14
			c-0.23,0.01,0.01,0.15-0.86-0.09c0-0.01,0.02-0.03,0.03-0.04c-0.02,0-0.04-0.03-0.06,0.04l0.02-0.01c0,0.11-0.13,0.19-0.15,0.24
			c0.09-0.19-0.08-0.08,0.04-0.31c-0.02,0.01-0.04,0.04-0.05,0.08c-0.11-0.15-0.02-0.03-0.48-0.21c0.02,0.02-0.09-0.05-0.06,0.09
			c0.03-0.05-0.2,0.07-0.09-0.03l-0.1,0c0.02,0.07,0.04,0.07-0.06,0.17c-0.03-0.09-0.09-0.05-0.1-0.23c-0.05,0.1-0.12,0-0.18,0.1
			c0.02-0.02,0.04-0.09,0.04-0.1l-0.04,0.08c0.06-0.07-0.23-0.01-0.17-0.21l-0.03,0.07c0.05-0.14-0.28,0.11-0.46-0.08
			c0.02-0.01,0.04-0.09,0.04-0.08c-0.06-0.05-0.15,0-0.28,0.03c0.05,0-0.1-0.22-0.17-0.03c-0.04-0.14-0.15-0.36-0.53-0.03
			c0.06-0.04,0.04-0.12,0.03-0.17c0,0.02-0.02,0.05-0.02,0.07c0.03-0.21-0.09,0.12-0.08,0c-0.03,0.01-0.17,0.06-0.04-0.07
			c-0.14-0.01,0.03,0.03-0.17-0.17c-0.08,0.29,0,0.02-0.22,0.18c0.09-0.13,0.04,0,0.06-0.28c-0.06,0.07-0.04-0.08-0.12,0.03
			c0.01-0.03-0.03-0.01,0-0.09c0.27,0.17-0.53,0.14-0.57-0.08c-0.05,0.02-0.1,0.07-0.15,0.09c0.01-0.06,0.05-0.08,0.07-0.11
			c0,0.02-0.09-0.01-0.14,0.13c0.06-0.01,0.21,0.03,0.13,0.18c-0.01-0.02,0.17-0.02,0.11,0.15c0.1,0.08,0.16,0.11,0.27-0.15
			l-0.02,0.27l0.04-0.1c0.01,0.01,0.03,0.02,0.01,0.06c0.06-0.06,0.01-0.05,0.05-0.11c0.02,0.11,0.14-0.01,0.11,0.16
			c0.04-0.07-0.03-0.07,0.04-0.11c-0.08,0.2,0.16-0.06,0.04,0.2c0.15-0.13,0.09,0.18,0.29-0.01c-0.03,0.05,0.04-0.01,0.13,0.18
			c0.05-0.12,0.1-0.08,0.15-0.09c-0.02,0.07-0.04,0.1-0.07,0.13c0.04,0,0.04,0.01,0.11-0.05l-0.04,0.09c0.06,0.01,0.14-0.08,0.19,0
			c0.03-0.09,0.01-0.26,0.1-0.29c-0.01,0.05-0.03,0.13-0.06,0.14c0.02,0.02,0.02,0.03,0.03,0.03c0,0.01-0.01,0.04,0.02,0.2
			c0.01-0.03,0.1-0.19,0.07,0.04c0.03-0.04,0.05,0.02,0.08-0.08c0.02,0.01,0.01,0.05,0,0.08c0.04-0.04,0,0,0.13-0.1l0,0
			c-0.01-0.21,0.17,0.18,0.64,0.27c-0.08,0.01,0.1,0.18,0.02,0.03c0.3,0.29,0.54-0.17,0.65,0.34c0.05-0.03,0.03-0.06,0.01-0.09
			c0.05,0.08,0.1,0.03,0.16,0.09c0-0.1,0.31-0.18,0.13,0.11c0.08-0.06,0.1,0.08,0.13,0.11l-0.04,0.02c0.02-0.01,0.04-0.01,0.06,0
			l0-0.09c0.02,0.02,0.03,0.02,0.02,0.06c0.03-0.05,0.02-0.14,0.08-0.2c0.01,0.05,0.09-0.02,0.07,0.09
			c-0.07-0.06-0.02,0.11-0.09,0.11c0.09,0.08,0,0.13,0.18-0.05c-0.03,0.06-0.01,0.12,0,0.11c-0.03-0.01,0.01-0.1,0.05-0.14
			c0.04,0.06,0.06-0.02,0.09-0.01c-0.02-0.05-0.04-0.05,0.07-0.08c-0.01,0.05-0.04,0.02-0.03,0.05c0.07-0.09,0.06,0.13,0.13,0.08
			c0.01,0.05-0.03,0.11-0.01,0.15c0.07-0.12,0.07,0.19,0.19-0.08l-0.12,0.03c0.14-0.14,0.02-0.21,0.37,0.06
			c-0.01-0.05-0.04-0.09,0.08-0.06c-0.02,0.07-0.01,0.1,0,0.14c0.01-0.05,0.07,0.02,0.12-0.11c0.05,0.1-0.01,0.19,0.23-0.02
			c-0.04,0.07-0.02,0.12-0.09,0.16c0.09-0.04-0.02,0.01,0.27-0.19c0.02,0.23,0.22,0.04,0.25,0.24c0.36-0.05,0.09-0.1,0.81,0.05
			c-0.04,0.08-0.04,0.04-0.05,0.13c0.03-0.01,0.03-0.14,0.09-0.15c-0.09-0.19,0.15,0.45,0.54,0.17c-0.06,0.09-0.14,0.13-0.19,0.17
			c0.03,0.01,0.11,0.02,0.14-0.04c-0.05-0.01-0.06-0.01,0.14-0.1c-0.07,0.26,0.15,0.11,0.15,0.32c0.14-0.07,0.22,0.09,0.35,0.11
			c-0.04-0.06,0.17-0.27,0.08-0.05c0.02-0.03,0.05-0.05,0.05-0.09c0.03,0.05,0.06,0.15,0.05,0.34c0.01-0.08,0.05-0.1,0.08-0.06
			c-0.08-0.06,0.19-0.06,0.08,0.06c0.06-0.03,0.18,0.04,0.27-0.11C1411.51,222.85,1411.43,222.58,1412.77,222.76z M1415.59,223.31
			c-0.01,0.02-0.01,0.04-0.01,0.05C1415.57,223.37,1415.54,223.34,1415.59,223.31z M1415.57,222.66c-0.01,0-0.01,0-0.03,0
			C1415.55,222.65,1415.54,222.66,1415.57,222.66z M1415.46,222.72c0.01-0.02-0.02-0.04-0.03-0.08
			C1415.45,222.66,1415.5,222.65,1415.46,222.72z"
              />
              <path
                className="st10"
                d="M1387.31,238.77c-0.05-0.06-0.06-0.09-0.03-0.08c-0.02,0.01-0.02-0.12,0.04,0.01
			c-0.01-0.04,0.04-0.03-0.05-0.18c0.05,0.17,0.09-0.04,0.14,0c-0.03-0.12,0.1,0.15-0.02-0.2c0.07,0.07,0.26,0.38,0.15-0.02
			c0.11,0.13,0.1,0.02,0.18,0.08c-0.02-0.02-0.06-0.1-0.07-0.12c0.02,0.02-0.04-0.1,0.04,0.02l0,0c0.08,0.11,0.07,0.07,0.09,0.07
			l-0.09-0.16c0.17,0.21,0.03-0.13,0.17,0.07c0-0.12,0.22,0.18,0-0.23l0.08,0.13c0.02-0.02,0.11,0.08,0.07-0.05
			c0.01,0.02,0.03,0.06,0.03,0.06c0.01,0,0.09,0.06-0.01-0.11c0.06,0.12,0.03,0,0.02-0.06l0.04,0.09c-0.05-0.12-0.06-0.15-0.06-0.18
			c0.01,0.01,0.04,0.07,0.02,0.05c0.11,0.05-0.1-0.18,0.08,0c-0.05-0.06,0.02-0.09,0.06-0.03c-0.16-0.11,0.23,0.24,0.05-0.05
			c0.01,0.01,0,0,0,0.03l0-0.01l-0.01,0.01c-0.02-0.05-0.03-0.09,0-0.04c-0.05-0.16-0.13-0.16,0.1-0.17
			c0.04,0.04,0.04,0.11,0.05,0.08c0.1,0.24,0.07-0.04,0.08-0.11c0.02,0.02,0.21,0.13,0.18-0.11l0.05,0.1
			c-0.1-0.2,0.06-0.05,0.04-0.13c0.01,0.01,0.04,0.02,0.06,0.04l-0.01-0.1c0.09,0.17,0.25,0.42-0.16-0.51l0.02,0.1
			c-0.01,0-0.05-0.2-0.03-0.04c-0.02-0.04-0.03-0.06-0.04-0.08l0.04,0.15c-0.03-0.06-0.04-0.12-0.07-0.15
			c0.02,0.07,0.06,0.13,0.07,0.18c-0.04-0.06-0.06-0.12-0.04-0.06c-0.02-0.04-0.03-0.06-0.03-0.08c0.01,0.05-0.02,0.03,0.01,0.11
			c-0.04-0.08-0.02,0.03-0.08-0.11c0.01,0.04,0.03,0.12,0.03,0.13c-0.06-0.18-0.03,0-0.11-0.18c0.05,0.16-0.01,0.08,0.01,0.18
			c0-0.01-0.01-0.04-0.01-0.03c-0.02-0.01-0.04-0.05-0.06-0.08c0.05,0.13,0.05,0.16,0.07,0.24c-0.07-0.11-0.06-0.15-0.11-0.27
			c0.05,0.1,0.02,0.11,0.06,0.19c-0.04-0.07-0.02-0.03-0.08-0.13c0.07,0.16,0,0.03,0.04,0.13c-0.05-0.07-0.07-0.14-0.06-0.09
			c-0.11-0.22,0,0.04-0.04,0.19l-0.02-0.03c-0.01,0.01,0.09,0.14,0.06,0.16c0.01,0-0.01-0.03-0.02-0.06l0.01,0.04
			c-0.06-0.11-0.01-0.04-0.05-0.12c0,0.05,0,0.17-0.05-0.02l0.04,0.03c-0.07-0.05-0.2-0.15-0.24,0.12c-0.01-0.03-0.03-0.07-0.05-0.1
			c-0.01,0.07-0.08,0.02-0.14-0.02l0.04,0.1c-0.1-0.18,0.07,0.14-0.04-0.04c-0.09,0.07,0.07,0.22-0.21,0.16
			c0.06,0.12-0.04,0.2-0.12,0.04l0.04,0.11l-0.07-0.11c0,0.05,0.14,0.2-0.05,0l0.01,0.1c-0.02-0.04-0.02-0.05-0.03-0.08
			c0.07,0.14-0.09-0.12,0.02,0.08c-0.09-0.02-0.06-0.03-0.07,0.16l-0.06-0.09c0.03,0.06,0.03,0.03,0.07,0.11
			c-0.02,0-0.08-0.11-0.09-0.14l0.01,0.04c-0.02-0.03-0.03-0.04-0.05-0.08c0.03,0.06-0.08-0.08-0.03,0.03
			c-0.04-0.05-0.09,0.03,0.01,0.19c-0.03,0-0.19-0.11-0.04,0.08c-0.14-0.15,0,0.02-0.26,0l0.03,0.1c-0.02,0-0.03-0.06-0.07-0.1
			c0.06,0.14-0.06,0.02-0.03,0.12c-0.17-0.2-0.06,0.06-0.18-0.05c0,0,0.1,0.13,0,0.02c0.3,0.26-0.22-0.08-0.25,0.34
			c-0.01-0.02-0.04-0.05-0.03-0.05c-0.06-0.06-0.03,0.02,0.01,0.08c-0.04-0.06-0.09-0.06-0.1-0.06c0.02,0.04-0.03,0.01,0.04,0.08
			c-0.04-0.02,0,0.06-0.05,0.02c0.06,0.09-0.06-0.02,0.04,0.1c-0.1-0.12-0.11-0.08-0.07-0.01c-0.03-0.04-0.04-0.03-0.08-0.08
			c-0.01,0.01,0,0.04,0.06,0.1c-0.14-0.13,0.06,0.15-0.11-0.02c0.03,0.09,0.04,0.16,0.03,0.21c-0.11-0.08-0.05-0.11-0.13,0.06
			c-0.01-0.01-0.02-0.02-0.02-0.04c0,0.01-0.03-0.01,0.02,0.04l-0.01-0.02c0.08,0.07,0.13,0.18,0.17,0.22
			c-0.13-0.16-0.06-0.02-0.22-0.22c0.01,0.01,0.03,0.04,0.06,0.07c-0.08,0-0.01,0.07-0.17,0.05c0.01,0.02-0.01,0.04,0.06,0.08
			c-0.03-0.03,0.05,0.13-0.02,0.02c-0.03,0.05,0.23,0.22,0.05,0.13c0.02,0.05,0.05,0.09-0.1-0.07c0.07,0.08,0,0.05,0.07,0.13
			c-0.02-0.02-0.07-0.07-0.08-0.08l0.06,0.07c-0.13-0.16,0.01,0.16-0.17-0.06c-0.01,0.04,0.07,0.16,0.02,0.16
			c0.17,0.14,0.01,0-0.02,0.03c-0.01-0.01-0.07-0.07-0.06-0.06c-0.04,0,0,0.06,0.02,0.13c-0.02-0.02-0.04-0.07-0.1-0.03
			c0.03,0.03,0.02,0.04,0.08,0.08c-0.24-0.19,0.02,0.09-0.17,0c0.01,0.08,0.08,0.07,0.15,0.19c-0.03-0.05-0.1-0.09-0.14-0.11
			l0.06,0.05c-0.17-0.13,0.11,0.12,0,0.03c0.02,0.03,0.04,0.09-0.05-0.02c0,0.05,0,0-0.13-0.03c0.24,0.2,0.01,0.01,0.15,0.19
			c-0.11-0.11,0.02,0-0.22-0.18c0.06,0.06-0.06-0.03,0.02,0.06c-0.11-0.1-0.06-0.16-0.07,0.16c0,0.01,0.11,0.12-0.06-0.03
			c0.02,0.03,0.06,0.08,0.08,0.11c-0.05-0.03-0.07-0.06-0.09-0.09c0.15,0.22,0.04-0.01,0.25,0.17l-0.08-0.09
			c0,0,0.15,0.11,0.16,0.11l-0.03-0.04c0.02,0.02,0.08,0.06,0.06,0.06c0.08,0.05-0.01-0.02,0.03-0.02
			c-0.02-0.05-0.08-0.07-0.16-0.17l0.21,0.16l-0.08-0.07c0,0,0.01,0,0.05,0.03c-0.05-0.06-0.04-0.03-0.09-0.08
			c0.08,0.05-0.01-0.06,0.12,0.05c-0.06-0.06-0.06-0.03-0.09-0.08c0.15,0.14-0.04-0.08,0.16,0.1c-0.11-0.11,0,0,0-0.05
			c0.08,0.05,0.07,0.11-0.02-0.07c0.03,0.04,0.01-0.02,0.14,0.05c-0.09-0.09-0.07-0.08-0.08-0.11c0.06,0.05,0.08,0.07,0.1,0.1
			c0-0.04,0-0.01-0.04-0.07l0.07,0.07c0.01-0.02-0.06-0.1,0-0.07c-0.07-0.06-0.2-0.15-0.23-0.21c0.06,0.05,0.02,0.04,0.29,0.22
			c-0.02-0.02-0.15-0.15,0.03,0c-0.03-0.03,0.01-0.01-0.07-0.08c0,0,0.04,0.03,0.06,0.05c-0.03-0.04,0.01,0-0.08-0.11v0
			c-0.07-0.07,0.04-0.05,0.09-0.03c-0.02-0.04,0.01-0.04-0.04-0.1c0.44,0.2,0.11,0.06,0.41,0.02c-0.02-0.04-0.04-0.05-0.07-0.06
			c0.06,0.03,0.02-0.02,0.06,0c-0.02-0.01-0.05-0.05-0.05-0.05c0.06,0.02-0.05-0.07-0.04-0.09c0.05,0.04,0.1,0.11,0.08,0.06
			c0.04,0.02,0.08,0.08,0.1,0.1c-0.06-0.06,0.05-0.01,0.09,0.05c-0.01-0.02,0-0.02,0-0.02l-0.06-0.06c0.01,0.01,0.02,0,0.04,0.03
			c-0.03-0.04-0.1-0.1-0.14-0.16c0.1,0.06,0.02,0.02,0.19,0.11c-0.01,0.01-0.07-0.07-0.1-0.11c0.04,0.03-0.01-0.04,0-0.04
			c-0.05-0.03-0.03-0.02-0.06-0.08c0.03,0.03,0.02,0.03,0.03,0.04c-0.07-0.09,0.1,0.06,0.06,0c0.03,0.03,0.08,0.08,0.1,0.1
			c-0.03-0.08,0.08,0.04-0.05-0.12l0.02,0.06c-0.08-0.12-0.2-0.16,0.11-0.07c-0.03-0.04,0.02-0.01-0.07-0.12
			c0.13,0.22,0.12-0.02-0.02-0.19c0.16,0.15,0.04-0.05,0.18,0.07c-0.01-0.15-0.04-0.04,0.08-0.26c0.05,0.07,0.02,0.04,0.08,0.11
			c0-0.02-0.09-0.11-0.09-0.14c0.02,0.03,0.17,0.16,0.19,0.19c0.01-0.03-0.08-0.14-0.05-0.15c-0.05-0.08-0.05-0.11,0.07,0.04
			c-0.01-0.03-0.06-0.13-0.06-0.16c0.05,0.09,0.07,0.14,0.1,0.2c-0.14-0.25,0.2,0.19,0.15-0.22c0.03,0.04,0.07,0.11,0.1,0.14
			C1387.1,238.56,1386.97,238.37,1387.31,238.77z M1388.28,238.01c0,0.02-0.02-0.01-0.02-0.04
			C1388.27,237.99,1388.28,238,1388.28,238.01z M1387.92,237.43c0.02,0.03,0.03,0.06,0.03,0.06c-0.01-0.02-0.03-0.03-0.04-0.06
			C1387.91,237.44,1387.92,237.44,1387.92,237.43z"
              />
              <path
                className="st10"
                d="M1359.87,254.66c0.12-0.24-0.1,0.23,0.33-0.01l-0.05,0.14l0.1-0.11c0.02,0.04,0.03,0.12-0.02,0.14
			c0.05,0.06,0.02,0.08,0.18,0.02c-0.01,0.03,0.01,0.1-0.03,0.08c0.1,0.04,0.28,0.13,0.37-0.21l0.08,0.27l0.02-0.11
			c0.02,0,0.05,0.01,0.04,0.05c0.06-0.07-0.01-0.05,0.03-0.12c0.08,0.1,0.22-0.04,0.24,0.13c0.03-0.08-0.08-0.06,0.01-0.12
			c-0.04,0.22,0.21-0.11,0.16,0.18c0.1-0.21,0.17,0.2,0.45-0.1c-0.02,0.06,0.02-0.04,0.3,0.12c0.01-0.13,0.11-0.11,0.19-0.14
			c0,0.08-0.01,0.11-0.05,0.15c0.01-0.01,0.05,0.03,0.15-0.09l-0.01,0.1c0.09-0.01,0.18-0.13,0.3-0.07
			c0.05-0.22-0.11-0.35,0.19,0.03c0-0.05,0.05-0.21,0.12,0c0.02-0.05,0.08-0.01,0.07-0.11c0.03,0,0.04,0.04,0.05,0.07
			c0.04-0.06,0,0,0.15-0.15l0,0c0.1-0.16,0.4,0.1,0.98-0.11c-0.06,0.07,0.03,0.05-0.06,0.06c0.1,0.01,0.13,0.06,0.21-0.05
			c-0.02,0.09,0.09,0.1,0.13,0.06l-0.08-0.05c0.28,0.04,0.05,0.09,0.61-0.02c-0.3-0.32,0.49-0.2,0.42-0.14l0-0.04
			c0.05,0.03,0.17-0.01,0.16,0.07c0.05-0.06,0.01-0.07-0.04-0.08c0.12,0.03,0.17-0.05,0.29-0.04c-0.06-0.05,0.27-0.4,0.26,0
			c0.08-0.11,0.2-0.01,0.27-0.02l-0.04,0.05c0.03-0.03,0.05-0.04,0.08-0.05l-0.06-0.07c0.04,0,0.06,0,0.07,0.03
			c0.01-0.06-0.07-0.12-0.03-0.21c0.05,0.03,0.11-0.09,0.16,0.01c-0.18,0.03,0.11,0.4,0.17-0.02c0.01,0.06,0.07,0.1,0.09,0.08
			c-0.02,0.03-0.09-0.1,0.09-0.23c-0.05-0.02-0.1,0,0.03-0.11c0.02,0.04-0.04,0.05-0.01,0.06c0.03-0.24,0.55,0.3,0.53-0.16
			l-0.15,0.13l0.06-0.16c-0.05,0.02-0.09,0.09-0.12,0.09c0.03-0.08,0.03-0.06-0.02-0.12c0.32-0.21,0.12,0.37,0.65-0.12
			c-0.06-0.03-0.13-0.02,0.06-0.12c0.03,0.06,0.07,0.07,0.11,0.1c-0.03-0.04,0.12-0.06,0.06-0.18c0.17,0.02,0.15,0.12,0.27-0.26
			c0.01,0.09,0.08,0.09,0.03,0.19c0.08-0.12-0.02,0.03,0.17-0.4c0.17,0.06,0.25,0.05,0.69-0.42c0.01,0.02,0.02,0.02,0.01,0.03
			c0.15-0.29,0.03,0.03,0.37-0.27l0,0.03c0.11,0,0.05-0.19,0.13-0.26c0,0,0.01,0.02,0.03,0.02c-0.04-0.13,0.03-0.27,0.07-0.37
			c0.13,0.15,0.07,0.12,0.22-0.01c0.04,0.52,0.1-0.67,0.35-0.31c0.02-0.05,0.07-0.2,0.14-0.27c0,0.11-0.06,0.21-0.07,0.29
			c0.04-0.03,0.12-0.09,0.11-0.16c-0.06,0.03-0.07,0.04,0.1-0.19c0.08,0.25,0.27-0.03,0.4,0.16c0.14-0.14,0.35-0.04,0.57-0.11
			c-0.09-0.04,0.09-0.38,0.12-0.03c-0.07-0.05,0.02-0.11,0-0.16c0.09,0.03,0.19,0.1,0.3,0.25c-0.04-0.07,0.01-0.12,0.09-0.11
			l-0.12-0.07c0.13-0.16,0.2,0.13,0.33-0.04l-0.03,0.08c0.07-0.06,0.31-0.12,0.32-0.31c0.02,0.02,0.07,0.06,0.04,0.09
			c0.17,0.02,0.22-0.12,0.74-0.47l-0.01,0.07c0.36-0.5,0.42,0,1.6-1.09c0.02-0.22,0.1-0.07,0.83-0.83c0.13,0.08,0.05,0.1,0.36-0.14
			l0.02,0.08c0.07-0.33-0.09-0.16,0.47-0.57c0.01,0.02,0.03,0.05,0.02,0.08c0.06-0.04,0.29-0.11,0.15-0.25
			c0.08,0.1,0.14-0.1,0.17-0.2l0.05,0.08c-0.02-0.02,0.01-0.11,0.15-0.35l0.01,0.07c0.11-0.07-0.1-0.24,0.24-0.17
			c0-0.02-0.01,0.01,0.32-0.25c-0.09,0.07-0.16,0.12-0.16,0.11c0.18,0.12,0.09,0.37,0.44-0.18c-0.03-0.02-0.04,0,0-0.07
			c-0.01,0.01,0.04,0.03,0,0.06c0.03-0.01,0.06-0.03,0.1-0.06c-0.06-0.07-0.01-0.1-0.1-0.13c0.01,0-0.02,0.02-0.03,0.06l0-0.01
			c-0.02,0.02-0.05,0.05-0.08,0.08c0-0.06,0-0.12,0.07-0.11c-0.02-0.07-0.04-0.13-0.05-0.16c0.07-0.11,0.56-0.14,1.37-0.93
			c-0.03,0-0.04,0-0.06-0.01c0.2-0.14,0.28,0.04,0.91-0.77l0.08,0.07c-0.09-0.35,0.61-0.16,0.81-0.91c0,0.01,0.01,0.01,0.02,0.03
			c-0.07-0.17,0.01-0.03-0.47-0.23l0.06-0.06c-0.08-0.03-0.07,0.04-0.07,0.1c-0.03-0.03-0.06-0.04-0.09-0.04l0.02,0.18
			c-0.23-0.27,0.01,0.2-0.18-0.03c-0.01,0.07-0.09,0.1-0.06,0.19c-0.1-0.04-0.12,0.13-0.21,0c0.01,0.04,0,0.15-0.03,0.19
			c-0.05-0.2-0.15,0.1-0.25-0.07c0.05,0.17-0.13,0.2-0.17,0.34c0-0.01-0.01-0.04,0-0.04c-0.06,0.03-0.1,0-0.16,0
			c0.08,0.11,0.02,0.2,0.03,0.3c-0.14-0.05-0.06-0.16-0.16-0.24c0.07,0.08-0.04,0.17,0.04,0.23c-0.1-0.03-0.06,0-0.16-0.07
			c0.1,0.14-0.03,0.06,0,0.17c-0.07-0.06-0.16,0.11-0.2-0.12c0.14,0.23-0.36,0.11-0.33,0.79c0.02-0.01,0-0.04,0-0.07l-0.01,0.06
			c-0.4-0.76,0,0-1.25,0.84c0.33-0.22-0.5,0.14-1.05,0.75l0-0.04c-0.11,0.03,0.04,0.24-0.1,0.23l0-0.03
			c-0.17,0.07-0.2-0.13-1.13,0.88l-0.12-0.07c0.02,0.24-0.07-0.16-0.31,0.34c-0.01-0.01-0.01-0.03-0.01-0.04
			c-0.03,0.01-0.11-0.1-0.05,0.07c-0.08-0.02-0.18,0.06-0.23,0.13l-0.03-0.07c-0.14,0.08-0.24,0.24-0.38,0.34
			c0.07-0.01,0.14,0.1,0.11,0.14l-0.09-0.08c0.02,0.07,0.11,0.23-0.04-0.02l-0.04,0.08c-0.02-0.02-0.02-0.05-0.04-0.09
			c0.02,0.08-0.18-0.01-0.14,0.12c-0.13,0.02-0.43,0.4-0.63,0.69c-0.05-0.07-0.15,0-0.13-0.09c-0.12,0.09-0.15,0.11-0.28,0.27
			c0.06,0.03,0.08,0.12-0.03,0.01c-0.13,0.22-0.54-0.02-0.61,0.21l0.01-0.01c-0.23,0.18-0.1,0.1-1.2,0.23
			c0.01,0.04,0.06,0.13-0.08,0.03c0.7,0.14-1.12,0.09-1.86,1.52c-0.01-0.03-0.04-0.05-0.02-0.07c-0.1-0.01-0.14,0.14-0.09,0.19
			c0.08-0.04-0.31,0.16-0.51,0.54c-0.09-0.13-0.21-0.01-0.22,0.09c-0.01-0.05-0.07-0.01-0.09-0.08c-0.05,0.04-0.09,0.1-0.04,0.16
			c-0.2-0.09-0.14,0.27-0.33,0.09c-0.14,0.17-0.29,0.31-0.45,0.43c0-0.36,0.38-0.32-0.7,0.24c0-0.01,0.01-0.04,0.02-0.05
			c-0.03,0.02-0.08,0-0.05,0.07l0.02-0.03c0.09,0.08-0.05,0.25-0.03,0.3c-0.02-0.21-0.17,0.01-0.18-0.27
			c-0.02,0.02-0.03,0.06-0.01,0.1c-0.03,0-0.05-0.03-0.04-0.09c-0.1,0.08-0.03,0.13-0.13,0.17c-0.01-0.03,0-0.05,0-0.1
			c-0.07,0.1-0.06-0.02-0.71,0.26l0.05,0.05c-0.08,0.02-0.12,0.13-0.19,0.11l0.04-0.07l-0.14,0.06l0.09,0.03
			c-0.12,0.13,0.09,0.2-0.36,0.02c-0.01,0.12-0.17,0.08-0.19,0.2c0.01-0.03-0.01-0.1-0.01-0.11l-0.01,0.09
			c-0.1-0.05-0.08,0.03-0.37,0l0.04-0.03l-0.06-0.05l0,0.07c-0.05-0.03-0.37,0.12-1.11,0.28c0.1-0.02-0.19-0.2-0.27,0.05
			c-0.2-0.08-0.18-0.31-0.81,0.2c0.07-0.06-0.01-0.13-0.05-0.17c0.01,0.02,0,0.05,0,0.08c-0.07-0.2-0.05,0.13-0.13,0.03
			c0.01-0.01-0.24,0.15-0.09-0.05c-0.2,0.04,0.02,0-0.34-0.1c0.01,0.3,0.01,0.02-0.26,0.24c0.09-0.14,0.05-0.05-0.04-0.28
			c-0.05,0.09-0.09-0.07-0.17,0.06c0-0.02-0.02-0.03-0.03-0.04c0,0.05-0.19,0.1-0.87,0.16c0.06-0.2-0.22,0.11-0.06-0.16
			c-0.06,0.03-0.13,0.09-0.2,0.11c-0.01-0.06,0.05-0.09,0.07-0.13C1360.05,254.52,1359.87,254.5,1359.87,254.66z M1377.58,246.36
			c-0.01,0.01-0.02,0.02-0.03,0.03C1377.55,246.37,1377.54,246.39,1377.58,246.36z M1377.49,246.53c-0.01-0.02-0.05-0.01-0.09-0.02
			C1377.4,246.53,1377.48,246.4,1377.49,246.53z"
              />
              <path
                className="st10"
                d="M1412.2,242.04l0.09-0.06c-0.39-0.07,0.01-0.81-0.83-1.24c0.01,0,0.02-0.01,0.04-0.02l-0.06,0.01
			c0.01,0.01,0.01,0.01,0.01,0.01c-0.13,0,0.02,0.05-0.24,0.44l-0.05-0.09c-0.04,0.09,0.04,0.09,0.1,0.1
			c-0.03,0.08-0.15,0.09,0.14,0.11c-0.31,0.18,0.21,0.05-0.05,0.18c0.07,0.03,0.09,0.13,0.18,0.12c-0.06,0.09,0.1,0.17-0.04,0.22
			c0.04,0,0.15,0.04,0.17,0.09c-0.2-0.02,0.06,0.18-0.14,0.22c0.18,0.01,0.14,0.2,0.25,0.3c-0.01,0-0.04-0.01-0.04-0.02
			c0,0.07-0.05,0.09-0.07,0.14c0.14-0.01,0.18,0.09,0.27,0.14c-0.13,0.08-0.16-0.04-0.29-0.01c0.11-0.01,0.11,0.12,0.21,0.1
			c-0.08,0.06-0.04,0.05-0.16,0.07c0.17,0.02,0.03,0.05,0.12,0.11c-0.3,0.1,0.06,0.02-0.25,0.67c-0.01,0.02,0.23,0.05,0.08,0.17
			c0.02-0.02-0.01-0.04-0.02-0.07l0,0.06c-0.12-0.05,0.01-0.08-0.06-0.13c0.14-0.09-0.27,0.15-0.05,0.07
			c-0.29-0.37-0.26,0.45-1.33,0.19c0.24,0.2-0.03-0.38-1.62-0.48l0.05-0.03c-0.23-0.04-0.17,0.22-0.37,0.14
			c0.01-0.01,0.01-0.02,0.02-0.02c-0.03-0.04-0.28,0.05-0.31,1.69l-0.12,0.07c0.21,0.12-0.15-0.04,0.1,0.49
			c-0.04-0.01-0.06-0.04-0.09-0.07c0.19,0.1-0.16,0.04,0.07,0.15c-0.07,0.06-0.07,0.18-0.05,0.27l-0.07-0.02
			c-0.03,0.16-0.01,0.35-0.06,0.51c0.05-0.05,0.17,0,0.17,0.06l-0.12-0.01c0.06,0.04,0.22,0.13-0.04,0.01l0.01,0.09
			c-0.03-0.01-0.04-0.03-0.08-0.05c-0.13,0.35-0.03,0-0.51,0.99c-0.06-0.07-0.16,0.01-0.14-0.08c-0.12,0.08-0.15,0.11-0.3,0.23
			c0.06,0.04,0.05,0.13-0.03,0c-0.07,0.1-0.16-0.04-0.59-0.09l0.01-0.01c-0.33,0.14-0.79-0.73-1.93,0.13
			c0.09,0.06-0.32,0.71-0.53,2.75c-0.02-0.03-0.05-0.04-0.04-0.06c-0.09,0.01-0.09,0.16-0.04,0.2c0.05-0.06-0.27,0.2-0.53,0.59
			c-0.08-0.14-0.22-0.02-0.25,0.08c0-0.05-0.07-0.02-0.08-0.09c-0.06,0.03-0.12,0.08-0.08,0.16c-0.19-0.13-0.23,0.24-0.37,0.02
			c-0.2,0.14-0.41,0.22-0.62,0.25c0.18-0.37,0.51-0.05-0.83-0.14c0-0.01,0.03-0.03,0.05-0.03c-0.12,0.06,0.03,0-0.27,0.25
			c0.11-0.18-0.17-0.1-0.01-0.32c-0.03,0.01-0.07,0.03-0.07,0.08c-0.06-0.08,0.04-0.11-0.4-0.09c0.01-0.02,0.01-0.05,0.04-0.04
			c-0.19-0.05-0.32,0.08-0.55-0.08c0.06-0.06,0.08-0.17-0.25-0.26l0.07,0.1c-0.14-0.06-0.22,0.01-0.34-0.09l0.07-0.03l-0.23-0.07
			l0.16,0.12c-0.08,0.04,0.1,0.22-0.06,0.12c0.02-0.01,0,0-0.23-0.18c-0.01,0.03-0.02,0.05-0.06,0.05c0,0.02,0,0.03-0.01,0.04
			c-0.05-0.04-0.17-0.24-0.2-0.26c0.13,0.22,0.07,0.37,0.2,0.57c-0.03-0.06-0.24-0.04-0.01,0.21c-0.01-0.02-0.02-0.02-0.04-0.06
			c0.04,0.22,0.01,0.3-0.02,0.38l-0.02-0.07c-0.35,0.32,0.42,0.43-0.27,2.75c0-0.11-0.11-0.06-0.17-0.05
			c0.02,0.01,0.04,0.03,0.06,0.04c-0.21-0.04,0.06,0.11-0.06,0.14c0-0.02-0.06,0.31-0.1,0.05c-0.04,0.06,0.04,0.24-0.08,0.23
			c0-0.07,0.05-0.2-0.13-0.08l0.01,0l-0.13,0.06c0.21,0.21,0.02,0.01-0.07,0.38c-0.4-0.24-0.08-0.15-1.2,0.44
			c0.03-0.21-0.22,0.14-0.09-0.15c-0.06,0.03-0.13,0.1-0.2,0.13c-0.02-0.06,0.05-0.09,0.07-0.13c0.02,0.03-0.19,0-0.19,0.17
			c0.14-0.24-0.1,0.25,0.37-0.03l-0.03,0.15l0.1-0.13c0.03,0.03,0.06,0.11,0,0.14c0.07,0.05,0.04,0.08,0.22-0.03
			c-0.01,0.03,0.04,0.1-0.02,0.09c-0.04,0.03,0.28-0.28,0.88-0.39c-0.02-0.09-0.11,0-0.06-0.1c0.09,0.21,0.13-0.24,0.26,0.03
			c-0.07-0.25,0.22,0.1,0.35-0.45c0.04,0.05-0.02-0.04,0.33-0.21c-0.09-0.09,0-0.18,0.02-0.28c0.07,0.04,0.08,0.07,0.09,0.13
			c-0.01-0.02,0.07-0.01,0.02-0.21l0.08,0.06c0.05-0.11,0-0.26,0.11-0.37c-0.08-0.04-0.27,0.01-0.28-0.15
			c0.05,0.01,0.13,0.04,0.13,0.08c0.01-0.01-0.03-0.16,0.06,0.01c-0.02-0.05,0.06-0.19,0.06-0.03c0.03-0.05,0.09-0.11,0.15-0.12
			c-0.17-0.03,0.21-0.14-0.01-0.53v0c-0.12-0.15,0.24-0.47,0.15-1.18c0.06,0.09,0.05-0.03,0.04,0.09c0.03-0.11,0.09-0.14-0.01-0.26
			c0.04,0.13,0.13-0.23,0.02-0.06c-0.01-0.07-0.01-0.07,0-0.11c0.2,0.23-0.11-0.44,0.17-0.26l-0.02-0.05
			c0.05,0.07,0.1,0.08,0.11,0.16c0.01-0.04-0.01-0.08-0.04-0.11c0.07,0.09,0.09,0.04,0.14,0.1c-0.08-0.07,0.44-0.19,0.18,0.15
			c0.15-0.03,0.21,0.13,0.28,0.17l-0.08,0.01c0.04-0.01,0.08,0.01,0.11,0.02l-0.01-0.09c0.13,0.13,0.02,0.1,0.32,0
			c-0.21-0.09-0.15,0.39,0.18,0.09c-0.04,0.05,0.01,0.12,0.04,0.12c-0.05,0.01-0.01-0.14,0.24-0.13c-0.04-0.05-0.1-0.06,0.1-0.08
			c0,0.05-0.07,0.02-0.05,0.05c0.15-0.14,0.19,0.24,0.61,0.28c-0.04-0.09-0.04-0.07,0.06-0.14c-0.08,0.02-0.15,0.03-0.23,0.04
			l0.16-0.11c-0.07,0-0.15,0.04-0.19,0.02c0.08-0.06,0.07-0.04,0.04-0.11c0.39,0,0.06,0.44,0.79,0.17c-0.06-0.05-0.14-0.06,0.12-0.1
			c0,0.07,0.05,0.09,0.09,0.12c-0.01-0.05,0.16-0.03,0.15-0.17c0.2,0.06,0.14,0.14,0.41-0.21c-0.02,0.09,0.07,0.11-0.02,0.2
			c0.14-0.11-0.03,0.03,0.31-0.41c0.16,0.06,0.34,0.05,0.83-0.53c0.01,0.02,0.03,0.02,0.01,0.03c0.15-0.35,0.08,0.03,0.37-0.46
			l0,0.03c0.11-0.06,0.01-0.25,0.06-0.39c0,0,0.02,0.01,0.03,0.01c-0.08-0.13-0.1-0.32-0.12-0.45c0.03,0.01,0.05,0.01,0.06,0.01
			c0.2-0.08,0.3-0.3,0-1.15c0.09,0.08,0.13,0.2,0.18,0.29c0.01-0.06,0.01-0.19-0.05-0.23c-0.01,0.08-0.01,0.1-0.08-0.23
			c0.24,0.1,0.17-0.26,0.4-0.2c0.02-0.2,0.23-0.19,0.32-0.21c-0.09-0.04,0.12-0.32,0.06-0.07c0.01-0.03,0.04-0.06,0.04-0.1
			c0.06,0.05,0.11,0.15,0.1,0.34c0.01-0.08,0.06-0.1,0.11-0.05l-0.05-0.12c0.19-0.06,0.06,0.21,0.28,0.18l-0.08,0.04
			c0.09,0,0.41,0.15,0.57-0.01c0.02,0.03,0.04,0.09-0.02,0.1c0.22,0.1,0.37,0.07,1.19-0.3l-0.02,0.08c0.58-0.72,0.86-0.16,1.45-2.13
			c-0.17-0.21,0.08-0.16-0.2-1.5c0.14-0.09,0.11,0.01,0.04-0.41c0.38,0.1-0.15-0.22,0.01-0.12c0.04,0,0.09,0.09,0.04-0.11
			c0.04,0.14,0.04,0.01,0.17-0.03l-0.03,0.09c0-0.03,0.09-0.08,0.39-0.09l-0.06,0.05c0.27,0.1,0.12,0.04,0.79,0.28
			c-0.13-0.05-0.23-0.09-0.22-0.09c-0.03,0.09,0.07,0.14-0.05,0.22c0.05,0.07,0.25-0.16,0.73,0.13c0.01-0.1,0.09-0.07,0.03-0.16
			c0.02,0.01-0.03,0-0.08,0.01l0.01-0.01c-0.04-0.01-0.09-0.02-0.14-0.03c0.06-0.04,0.11-0.07,0.16,0c0.04-0.06,0.09-0.11,0.11-0.14
			c0.17,0.29,0.11,0.35,0.89,0.1c-0.02,0.03-0.06,0.03-0.07,0.07c0.1-0.06,0.32-0.15,0.27-0.28c0.15-0.03,0,0.15,0.15,0.02l0,0.05
			c0.23-0.05,0.1-0.12,0.56-0.85c-0.02,0.02-0.04,0.03-0.06,0.03C1412.34,243.27,1412.5,243.28,1412.2,242.04z M1410.29,244.64
			c-0.01,0.02,0,0-0.03,0.04C1410.22,244.68,1410.16,244.63,1410.29,244.64z M1410.02,243.99c0.02-0.02-0.03-0.05-0.05-0.09
			C1409.96,243.91,1410.13,243.91,1410.02,243.99z M1410.18,243.96c0.02,0,0,0,0.05,0.01
			C1410.23,243.97,1410.21,243.97,1410.18,243.96z"
              />
              <polygon
                className="st10"
                points="1402.74,251.36 1402.74,251.36 1402.75,251.35 		"
              />
              <path
                className="st10"
                d="M1392.28,258.98c0.31-0.15,0.02,0-0.01-0.58c-0.02,0.02-0.02,0.03-0.04,0.03c0.01-0.17,0.19-0.21-0.09-0.92
			l0.1-0.02c-0.19,0.17,0.1-0.39-0.21-0.92c0,0.01,0,0.01,0.01,0.01c-0.1,0-0.24,0-0.45,0.01l0.09,0.1l-0.08,0.02l0-0.08
			c-0.08,0.03-0.02,0.07,0.03,0.1c-0.04,0-0.07,0.01-0.09,0.03l0.15,0.1c-0.06,0.01-0.12-0.05-0.16,0.01
			c0.07,0.04,0.14-0.03,0.19,0.04c-0.08,0.04-0.14,0.02-0.07,0.07c-0.04,0.01-0.07-0.01-0.09-0.03c0.05,0.04,0.02,0.12,0.11,0.14
			c-0.09,0.04,0.02,0.14-0.13,0.14c0.04,0.01,0.12,0.07,0.13,0.11c-0.19-0.06,0,0.14-0.2,0.14c0.17,0.04,0.09,0.18,0.18,0.26
			c-0.01,0-0.04-0.01-0.03-0.02c-0.01,0.05-0.06,0.07-0.09,0.1c0.14,0,0.17,0.07,0.26,0.1c-0.13,0.07-0.16-0.02-0.29,0
			c0.11-0.01,0.12,0.1,0.21,0.07c-0.08,0.05-0.04,0.04-0.15,0.07c0.17,0,0.03,0.04,0.14,0.07c-0.08,0.07-0.16,0.03-0.1,0.09
			c-0.27,0.04,0.07-0.01,0.13,0.45c0,0.02,0.2-0.07,0.17,0.07c0-0.02-0.03-0.02-0.06-0.03l0.04,0.03c-0.16-0.14-0.21,0.1-0.13,0.01
			c-0.17,0.08-0.22-0.11-0.18,0.19c0.15-0.75,0,1.16-1.13,1.31c-0.01,0.1-0.28,0.14-0.41-0.09l-0.09,0.09l0.01-0.13
			c-0.18,0.15,0.16-0.11-0.31-0.04c0.02-0.04,0.05-0.04,0.08-0.06c-0.12,0.13,0.01-0.16-0.12,0.03c-0.01-0.08-0.08-0.11-0.15-0.11
			l0.04-0.06c-0.1-0.06-0.39-0.18-0.59-0.3c0.03,0.07-0.01,0.35-0.05,0.04c-0.06,0.05,0.09,0.03-0.12-0.19
			c-0.02,0.07-0.23-0.29-0.24-0.15c-0.24-0.26-0.02-0.03-0.51-0.33c0.22,0.23-0.12,0.44,0.15,0.91c-0.09-0.1-0.08,0.09-0.15-0.07
			c0.01,0.22,0.02,0.3,0.09,0.59c0.07-0.03,0.13,0.06-0.01,0.04c0.12,0.34-0.28,0.48-0.12,0.63c-0.12-0.01,0.11,0.18-0.24,0.92
			c0.03,0.01,0.15,0.01,0,0.06c0.42-0.21-0.27,0.52-0.33,1.59c-0.02-0.02-0.05-0.03-0.05-0.05c0,0.04-0.05,0.17-0.17,0.75
			c-0.14-0.07-0.17,0.07-0.13,0.14c-0.04-0.04-0.06,0.02-0.11-0.03c-0.02,0.05-0.02,0.11,0.06,0.14c-0.21,0.01,0.04,0.25-0.2,0.19
			c-0.05,0.26,0.09,0.18-0.4,0.96c-0.01-0.01-0.02-0.03-0.02-0.05c-0.01,0.02-0.05,0.03,0.01,0.07l0-0.03
			c0.11,0.02,0.13,0.2,0.17,0.24c-0.15-0.15-0.11,0.08-0.29-0.13c0,0.02,0.02,0.06,0.06,0.08c-0.03,0.01-0.05,0-0.08-0.05
			c-0.02,0.1,0.06,0.11,0.02,0.18c-0.03-0.02-0.03-0.04-0.06-0.07c-0.05,0.15,0,0.07-0.31,0.41c0.04-0.02-0.09,0.08,0.06,0.1
			c-0.04,0.05,0,0.15-0.06,0.16l-0.01-0.07l-0.05,0.1l0.08-0.02c-0.01,0.14,0.19,0.11-0.23,0.16c0.06,0.09-0.07,0.13-0.01,0.23
			c-0.01-0.03-0.06-0.07-0.08-0.08l0.05,0.08c-0.09,0-0.03,0.05-0.25,0.16l0.01-0.05l-0.07-0.01l0.04,0.06
			c-0.1-0.11-0.06,0.32-0.37,0.45c0-0.02-0.06-0.09-0.04-0.07c-0.08,0.04-0.1,0.16-0.16,0.29c0.03-0.05-0.26-0.02-0.15,0.14
			c-0.19,0-0.25-0.22-0.47,0.44c0.03-0.07-0.06-0.11-0.1-0.14c0.02,0.02,0.02,0.05,0.03,0.07c-0.13-0.17,0.01,0.15-0.08,0.07
			c-0.01,0.03-0.12,0.17-0.08-0.02c-0.12,0.08,0,0-0.27-0.02c0.1,0.29,0.01,0.02-0.12,0.28c0.03-0.16,0.03-0.03-0.09-0.27
			c-0.02,0.09-0.07-0.05-0.1,0.08c-0.01-0.03-0.04,0-0.04-0.08c0.03,0.36-0.08-0.12-0.5,0.34c-0.04-0.06,0.15-0.16-0.07-0.15
			c0.1-0.18-0.18,0.06,0.01-0.16c-0.05,0.02-0.1,0.05-0.16,0.06c0.02-0.06,0.07-0.07,0.1-0.09c0,0.03-0.11-0.05-0.18,0.1
			c0.19-0.17-0.17,0.17,0.22,0.08l-0.08,0.13c0.14-0.17,0.06,0.02,0.19,0.09c-0.02,0.03-0.01,0.1-0.04,0.08
			c0.12,0.07,0.26,0.11,0.31-0.19c0.02,0.09,0.04,0.18,0.07,0.26l0.01-0.11c0.01,0,0.04,0.01,0.04,0.05
			c0.05-0.08-0.01-0.05,0.01-0.12c0.07,0.1,0.16-0.07,0.21,0.1c0.01-0.08-0.07-0.05-0.01-0.12c0.01,0.22,0.15-0.14,0.16,0.15
			c0.02-0.23,0.19,0.16,0.32-0.2c0,0.06,0.01-0.04,0.27,0.03c-0.04-0.12,0.04-0.14,0.08-0.19c0.03,0.07,0.04,0.1,0.03,0.15
			c0.03-0.03,0.05-0.02,0.07-0.13l0.03,0.09c0.06-0.04,0.08-0.17,0.19-0.17c-0.05-0.08-0.2-0.17-0.15-0.27
			c0.03,0.04,0.08,0.11,0.07,0.14c-0.01-0.02,0.15-0.02,0.09,0.06c0.04-0.01,0.1,0,0.15,0.03c-0.02-0.04-0.07-0.2,0.09-0.05
			c-0.01-0.05,0.06-0.04-0.01-0.12c0.02-0.01,0.05,0.02,0.07,0.04c-0.01-0.06,0,0,0.02-0.19l0,0c-0.07-0.18,0.15-0.03,0.59-0.49
			c0,0.08,0.05,0.02-0.01,0.07c0.07-0.03,0.12,0,0.11-0.13c0.05,0.08,0.12,0.04,0.12-0.01l-0.08-0.01c0.29-0.04,0.28-0.62,0.6-0.55
			l-0.03-0.03c0.06,0,0.11-0.07,0.15-0.02c0-0.07-0.04-0.06-0.08-0.04c0.1-0.03,0.08-0.11,0.17-0.16c-0.08-0.02-0.04-0.41,0.18-0.11
			c-0.02-0.11,0.14-0.09,0.18-0.12l0,0.06c0-0.03,0.01-0.05,0.02-0.07l-0.08-0.03c0.03-0.01,0.04-0.03,0.07-0.01
			c-0.03-0.05-0.12-0.06-0.16-0.15c0.05,0,0.02-0.11,0.12-0.06c-0.11,0.1,0.33,0.26,0.1-0.09c0.03,0.04,0.08,0.04,0.09,0.03
			c-0.03-0.01-0.1-0.08-0.06-0.21c-0.05,0.01-0.07,0.04-0.05-0.1c0.04,0.02,0,0.05,0.03,0.05c-0.06-0.1,0.16-0.04,0.13-0.14
			c0.01,0.01,0.35,0.07,0.14-0.19l-0.02,0.15l-0.06-0.14c-0.02,0.04,0,0.1-0.03,0.12c-0.04-0.07-0.02-0.06-0.09-0.08
			c0.1-0.31,0.29,0.24,0.39-0.34c-0.06,0-0.1,0.03-0.03-0.11c0.06,0.04,0.09,0.02,0.14,0.03c-0.05-0.02,0.05-0.08-0.07-0.16
			c0.13-0.04,0.19,0.03,0.05-0.29c0.05,0.06,0.12,0.04,0.14,0.13c-0.01-0.12,0.01,0.03-0.11-0.36c0.17-0.01,0.2-0.03,0.35-0.51
			c0.02,0.01,0.03,0.01,0.02,0.02c-0.05-0.32,0.25,0.12,0.21-0.5c0,0,0.02,0.01,0.03,0.01c-0.09-0.1-0.09-0.22-0.09-0.3
			c0.08,0.05,0.03,0.05,0.12,0.06c-0.29,0.09,0.36-0.22,0.08-0.79c0.09,0.07,0.12,0.17,0.16,0.24c0.01-0.04,0.03-0.14-0.04-0.18
			c-0.01,0.06-0.01,0.08-0.1-0.18c0.26,0.07,0.11-0.2,0.33-0.22c-0.07-0.17,0.09-0.29,0.11-0.47c-0.1,0.03-0.3-0.2,0.01-0.09
			c-0.08,0.03-0.09-0.05-0.15-0.05c0.06-0.05,0.16-0.1,0.33-0.12c-0.08,0-0.11-0.05-0.07-0.09c-0.07,0.12-0.07-0.22,0.05-0.12
			c-0.03-0.07,0.03-0.24-0.14-0.32c0.03-0.01,0.08-0.02,0.1,0.01c0.09-0.13-0.11-0.13-0.01-0.25c0.02,0.01-0.1-0.09,0.01-0.01
			c-0.09-0.14,0.16,0.19-0.17-0.36c0.07-0.01,0.3,0.65,0.23,0.12c0.11,0.19,0.01,0.3,0.28,0.22l-0.01-0.01
			c-0.03,0.14,0.06,0.14,0.12,0.17l0.08-0.17c0.06,0.32,0.44,0.06,0.47,0.31c0.05-0.03,0.1-0.02,0.16-0.06
			c0.02,0.14-0.05,0.11,0.31,0.16l-0.04,0.07c0.15-0.23,0.34,0.17,0.9-0.3c0.05,0.13,0.16-0.05,0.2-0.15
			c0.03,0.19,0.09,0.05,0.64-0.65c-0.05,0.09-0.11,0.15-0.11,0.14c0.06,0.19,0.42-0.23,0.23-0.46c0.01-0.01,0,0.02,0.01,0.06
			l-0.01-0.01c-0.01,0.03-0.02,0.06-0.03,0.09c-0.03-0.05-0.06-0.09,0.01-0.11c-0.05-0.04-0.1-0.09-0.13-0.11
			C1392.07,259.75,1391.91,259.45,1392.28,258.98z M1391.54,259.74c0,0.01,0,0.02-0.01,0.03
			C1391.54,259.76,1391.54,259.77,1391.54,259.74z M1391.48,259.88c0.02-0.02,0.03-0.08,0.08,0
			C1391.54,259.86,1391.51,259.88,1391.48,259.88z M1392.23,259.91c0,0.02-0.06,0.06-0.04-0.03
			C1392.2,259.89,1392.22,259.9,1392.23,259.91z"
              />
              <path
                className="st10"
                d="M1381.6,266.94c0.14-0.12,0.13-0.35,0.02-0.46c0,0,0,0,0.01,0c-0.1-0.11-0.01,0.01-0.52,0.04l0.02-0.08
			c-0.09,0.02-0.03,0.07,0,0.11c-0.04-0.01-0.07,0-0.1,0.01l0.11,0.14c-0.06-0.01-0.28-0.06,0.01,0.06
			c-0.08,0.02-0.14-0.02-0.1,0.05c-0.04-0.01-0.06-0.03-0.07-0.05c0.03,0.06-0.03,0.12,0.05,0.18c-0.1,0.01-0.04,0.16-0.18,0.09
			c0.03,0.03,0.08,0.12,0.07,0.16c-0.14-0.15-0.07,0.14-0.25,0.04c0.13,0.12-0.02,0.22,0.02,0.35c-0.01-0.01-0.03-0.03-0.02-0.04
			c-0.04,0.05-0.09,0.04-0.13,0.06c0.12,0.07,0.11,0.16,0.16,0.24c-0.15,0-0.12-0.11-0.25-0.15c0.1,0.05,0.04,0.16,0.14,0.18
			c-0.1,0.01-0.06,0.02-0.17,0c0.15,0.09,0,0.06,0.07,0.15c-0.09-0.02-0.1,0.14-0.23-0.04c0.12,0.23-0.03,0.03-0.06,0.65
			c-0.01,0-0.02,0-0.03-0.01c-0.01,0.02,0.22,0.03,0.11,0.16c0.02-0.02-0.01-0.04-0.03-0.06l0.01,0.06
			c-0.05-0.25-0.26,0.03-0.12-0.05c-0.28-0.44-0.38,0.46-0.78,1.1c0.25-0.35-0.18-0.11-1.88,1.85l-0.11-0.08
			c0.03,0.24-0.06-0.16-0.29,0.3c-0.01-0.01-0.01-0.03-0.01-0.04c-0.03,0.01-0.11-0.1-0.05,0.06c-0.08-0.02-0.16,0.04-0.21,0.11
			l-0.03-0.07c-0.13,0.06-0.23,0.2-0.37,0.29c0.07,0,0.13,0.11,0.09,0.15l-0.08-0.09c0.02,0.07,0.09,0.23-0.04-0.02l-0.04,0.07
			c-0.02-0.03-0.02-0.05-0.04-0.09c0.01,0.08-0.17-0.03-0.14,0.09c-0.13,0.01-0.4,0.31-0.62,0.52c-0.13-0.31-0.1,0.34-0.64-0.19
			l0.01,0c-0.02,0-0.25-0.01-0.02-0.07c-0.25-0.4,0.06-0.29,0.11-1.4c-0.13,0.1-0.5-1.59-1.55,0.28c0.01-0.05-0.01-0.09,0.02-0.12
			c-0.1-0.01-0.25,0.28-0.2,0.33c0.03-0.06-0.31,0.18-0.5,0.68c-0.11-0.11-0.2,0.04-0.19,0.14c-0.02-0.05-0.07,0.01-0.1-0.06
			c-0.04,0.05-0.06,0.12,0,0.17c-0.22-0.03-0.06,0.3-0.28,0.18c-0.07,0.21-0.16,0.39-0.25,0.56c-0.14-0.34,0.21-0.44-0.53,0.47
			c-0.01-0.01-0.01-0.03-0.01-0.05c-0.02,0.02-0.07,0.03-0.01,0.08l0.01-0.03c0.11,0.04,0.06,0.24,0.1,0.29
			c-0.1-0.19-0.15,0.07-0.26-0.18c-0.01,0.02,0,0.07,0.04,0.1c-0.03,0.01-0.06,0-0.07-0.06c-0.05,0.1,0.03,0.13-0.05,0.2
			c-0.02-0.03-0.02-0.05-0.04-0.09c-0.02,0.12-0.05,0.01-0.51,0.48l0.07,0.03c-0.06,0.04-0.05,0.16-0.13,0.16l0.01-0.08l-0.09,0.1
			l0.09-0.01c-0.05,0.15,0.1,0.18-0.3,0.14c0.03,0.11-0.12,0.12-0.1,0.24c0-0.03-0.04-0.09-0.05-0.1l0.02,0.09
			c-0.1-0.02-0.06,0.06-0.32,0.11l0.03-0.04l-0.07-0.03l0.02,0.06c-0.05-0.13-0.18,0.29-0.59,0.37c0.01-0.02-0.02-0.1-0.02-0.08
			c-0.11,0.02-0.18,0.13-0.31,0.25c0.08-0.05-0.24-0.13-0.23,0.1c-0.21-0.04-0.21-0.27-0.71,0.33c0.06-0.07-0.03-0.13-0.07-0.16
			c0.01,0.02,0.01,0.05,0.01,0.08c-0.1-0.19-0.03,0.14-0.11,0.05c0.01-0.01-0.2,0.17-0.09-0.04c-0.18,0.06,0.01,0-0.33-0.06
			c0.05,0.29,0.01,0.02-0.21,0.27c0.07-0.15,0.04-0.05-0.07-0.28c-0.04,0.09-0.09-0.06-0.15,0.07c0-0.02-0.02-0.02-0.03-0.04
			c0,0.05-0.17,0.12-0.79,0.21c0.05-0.2-0.2,0.12-0.06-0.16c-0.06,0.03-0.12,0.09-0.18,0.12c-0.01-0.06,0.04-0.09,0.06-0.13
			c0.01,0.02-0.16,0.01-0.16,0.17c0.11-0.24-0.09,0.23,0.31-0.01l-0.04,0.14l0.09-0.12c0.02,0.04,0.03,0.12-0.01,0.14
			c0.05,0.06,0.02,0.08,0.17,0.01c-0.01,0.03,0.01,0.1-0.03,0.08c0.14,0.05,0.29,0.08,0.34-0.23l0.09,0.26l0.01-0.11
			c0.02,0,0.05,0.01,0.04,0.05c0.05-0.08-0.01-0.05,0.02-0.12c0.08,0.09,0.2-0.06,0.24,0.11c0.02-0.08-0.08-0.05,0-0.12
			c-0.01,0.22,0.19-0.13,0.17,0.17c0.07-0.22,0.19,0.17,0.41-0.15c-0.01,0.06,0.02-0.04,0.3,0.08c-0.01-0.13,0.09-0.13,0.15-0.17
			c0.02,0.08,0.01,0.11-0.02,0.15c0.01-0.01,0.05,0.02,0.12-0.11l0.01,0.1c0.09-0.03,0.14-0.16,0.27-0.12c0-0.22-0.18-0.33,0.19,0
			c-0.03-0.13,0.17,0.1,0.32-0.26l0,0c0.05-0.17,0.4,0.01,0.87-0.35c-0.03,0.08,0.05,0.04-0.04,0.07c0.09-0.02,0.14,0.03,0.17-0.1
			c0.02,0.09,0.11,0.06,0.14,0.02l-0.09-0.02c0.27-0.05,0.07,0.07,0.55-0.21c-0.39-0.19,0.37-0.34,0.33-0.27l-0.02-0.04
			c0.06,0.01,0.15-0.06,0.17,0.01c0.03-0.07-0.02-0.07-0.07-0.06c0.12-0.02,0.13-0.1,0.24-0.14c-0.07-0.02,0.08-0.46,0.23-0.1
			c0.02-0.13,0.18-0.08,0.23-0.11l-0.02,0.06c0.01-0.04,0.03-0.05,0.05-0.07l-0.08-0.04c0.03-0.01,0.05-0.03,0.08,0
			c-0.01-0.06-0.11-0.09-0.11-0.18c0.06,0,0.06-0.12,0.15-0.05c-0.15,0.09,0.26,0.33,0.14-0.08c0.03,0.06,0.1,0.07,0.11,0.04
			c-0.01,0.04-0.12-0.06-0.01-0.24c-0.06,0-0.09,0.03-0.02-0.11c0.04,0.03-0.01,0.06,0.02,0.06c-0.07-0.23,0.61,0.09,0.41-0.32
			l-0.08,0.16l-0.01-0.16c-0.04,0.04-0.04,0.11-0.07,0.12c-0.01-0.08,0-0.07-0.07-0.1c0.2-0.31,0.24,0.3,0.51-0.32
			c-0.07-0.01-0.12,0.02,0.01-0.13c0.05,0.05,0.09,0.04,0.13,0.06c-0.04-0.03,0.08-0.08-0.01-0.19c0.16-0.02,0.17,0.07,0.16-0.31
			c0.03,0.08,0.1,0.07,0.09,0.17c0.04-0.13-0.01,0.03,0.03-0.41c0.18,0.03,0.21,0,0.57-0.47c0.01,0.02,0.03,0.02,0.01,0.03
			c0.07-0.36,0.14,0.26,0.26-0.26c0,0,0,0.03,0,0.05c0.02-0.19,0.09-0.34,0.16-0.46c-0.04,0.18-0.04,0.11-0.05,0.27
			c0.02-0.05,0.03-0.26,0.09-0.36c-0.31,1.16,0.36-0.5,0.14,0.14c0.54-0.42-0.19-0.17,0.01,0.74c-0.03,0-0.07,0.01-0.11,0.02
			c0.04,0,0.07,0.02,0.12,0c-0.01,0.05-0.09,0.13-0.31,0.26c0.07-0.03,0.11,0.03,0.12,0.13c0.02-0.05,0.04-0.1,0.06-0.15
			c0.16,0.17-0.13,0.25,0.09,0.42c-0.03-0.01-0.07-0.02-0.1-0.03c0.08,0.1,0.3,0.4,0.5,0.31c0,0.03,0,0.1-0.06,0.08
			c0.17,0.19,0.27,0.17,1.07,0.09l-0.04,0.07c0.46-0.36,0.51,0.12,1.76-0.82c0.02-0.21,0.12-0.06,0.82-0.79
			c0.13,0.08,0.05,0.1,0.34-0.14l0.02,0.08c0.04-0.33-0.09-0.14,0.41-0.59c0.01,0.02,0.04,0.05,0.03,0.07
			c0.06-0.05,0.28-0.13,0.11-0.25c0.1,0.09,0.12-0.11,0.14-0.21l0.06,0.08c-0.02-0.02-0.01-0.11,0.09-0.36l0.02,0.07
			c0.09-0.08-0.14-0.22,0.2-0.21c0-0.02-0.01,0.02,0.26-0.3c-0.07,0.08-0.13,0.15-0.13,0.13c0.05,0.17,0.45-0.13,0.34-0.44
			c0.01-0.01-0.01,0.02-0.01,0.06l-0.01-0.01c-0.02,0.02-0.04,0.05-0.06,0.09c-0.02-0.06-0.03-0.11,0.04-0.12
			c-0.04-0.06-0.07-0.12-0.09-0.14c0.01-0.12,0.54-0.27,0.96-1.24c-0.02,0.01-0.04,0.01-0.06,0.01c0.12-0.19,0.28-0.08,0.49-1.02
			l0.1,0.03C1381.21,267.59,1381.48,267.15,1381.6,266.94z M1379.3,269.99c0.01,0.01,0.05-0.12,0.09,0
			C1379.37,269.97,1379.33,269.99,1379.3,269.99z M1379.4,269.85c0-0.02,0,0,0.02-0.04C1379.42,269.82,1379.41,269.83,1379.4,269.85
			z M1380.03,270.08c0.01,0.01,0,0,0.03,0.04C1380.05,270.14,1379.98,270.16,1380.03,270.08z"
              />
              <path
                className="st10"
                d="M1329.9,281.9l-0.06,0.03c0.1,0.09,0.39,0.01,1.27,0.1c-0.2,0.05,0.05,0.06-0.2,0.03
			c0.23,0.05,0.32,0.1,0.5,0.01c-0.07,0.09,0.13,0.12,0.22,0.09l-0.12-0.07c0.59,0.21-0.08,0.14,1.91-0.01
			c-0.17-0.25,0.42,0.28,0.35,0.03c0.22,0.04,0.34-0.03,0.57-0.01c-0.07-0.04,0.63-0.4,0.5,0.02c0.19-0.11,0.39,0,0.51,0l-0.1,0.05
			c0.06-0.03,0.11-0.03,0.17-0.04l-0.07-0.07c0.07,0.01,0.12,0,0.12,0.03c0.1-0.11-0.07-0.29,0.34-0.2
			c-0.13-0.09-0.02,0.45,0.32-0.02c-0.02,0.07,0.08,0.11,0.12,0.09c-0.08,0.02-0.05-0.14,0.28-0.24l-0.1,0
			c0.16-0.23,0.45,0.02,1.18-0.07c-0.08-0.07-0.09-0.04,0.04-0.16l-0.33,0.14l0.19-0.17c-0.1,0.02-0.2,0.11-0.27,0.11
			c0.09-0.09,0.08-0.07,0.02-0.12c0.54-0.13,0.29,0.35,1.23-0.16l-0.11,0c0.02-0.09,0.19-0.07,0.28-0.13
			c0.01,0.07,0.08,0.07,0.14,0.1c-0.02-0.05,0.23-0.06,0.22-0.2c0.32,0.02,0.18,0.11,0.64-0.3c-0.05,0.1,0.08,0.1-0.07,0.21
			c0.08-0.03,0.16-0.04,0.14-0.09c-0.12,0.08,0.33-0.06,0.41-0.38c0.23,0.07,0.39,0.03,1.46-0.48c0,0.01,0,0.02,0,0.02
			c0.35-0.32,0.24,0.14,0.77-0.35c0,0-0.02,0.03-0.04,0.06c0.1-0.11,0.23-0.23,0.32-0.31c-0.46,0.45,0.11-0.13-0.35,0.47
			c0.01-0.02,0,0,0.02-0.08c0.03,0.41-0.12,0.52,0.73,1.3c-0.16,0.02-0.39-0.13-0.53-0.24c0.06,0.11,0.3,0.33,0.39,0.31
			c-0.05-0.02-0.12-0.04-0.14-0.09c0.95,0.33,0,0.14,2.49,0.64c-0.07-0.18,0.05-0.25,0.22-0.23c-0.02,0.05-0.14,0.16,0.02,0.19
			c-0.13-0.06,0.09-0.12,0.04-0.18c0.16,0.03,0.35,0.13,0.46,0.27c-0.04-0.07,0.06-0.12,0.19-0.11l-0.19-0.07
			c0.29-0.16,0.29,0.14,0.58-0.01l-0.09,0.08c0.18,0.02,0.24-0.07,1.85-0.44l-0.02,0.06c0.2-0.1,0.1,0.04,0.49-0.14l-0.07,0.07
			c0.38,0,0.54-0.25,0.84-0.35c0.03,0.15,0.33,0.14,0.48,0.13l0,0.02c0.61-0.06-0.07-0.25,2.33-0.68c-0.07-0.03,0.05,0.05,0.36-0.33
			c-0.02,0.11,0.09,0.06,0.16,0.01c-0.09,0.09-0.12,0.04-0.22,0.3c0-0.01,0.01,0.02-0.01-0.02c-0.01,0.03,0.68,0.91,2.32,0.6
			c0.05,0.13,0.33-0.09,0.47-0.2l0,0.1c-0.01-0.03,0.16-0.15,0.57-0.37l-0.05,0.08c0.25-0.08,0.07-0.28,0.56-0.17
			c-0.07,0.04,0.19-0.23,0.15-0.09c0.3,0.03-0.03-0.11,0.61-0.17c-0.21,0.08-0.38,0.13-0.37,0.11c0.03,0.08,0.2,0.04,0.11,0.2
			c0.14,0.01,0.2-0.33,1.03-0.46c-0.04-0.09,0.07-0.12-0.06-0.15c0.03,0-0.04,0.02-0.1,0.07l0-0.01c-0.05,0.02-0.12,0.05-0.2,0.09
			c0-0.1,0.2,0.05,0.27-0.32c0.37,0.16,0.34,0.14,1.28-0.37c-0.03,0.04-0.08,0.06-0.09,0.09c0.12-0.11,0.41-0.21,0.39-0.34
			c0.2-0.03-0.05,0.15,0.19,0.02l-0.02,0.05c0.57-0.06,0.61-0.68,1.25-0.75c-0.04,0.01-0.06,0.01-0.08,0
			c0.25-0.23,1.02-0.48,2.68-2.16l-0.09-0.01c0.67-0.67-0.17,0.06,0.2-1.21c-0.1,0.08-0.05,0.03-0.18,0.46
			c-0.29-0.21-0.02,0.29-0.24,0.1c-0.02,0.11-0.14,0.21-0.1,0.31c-0.13,0.01-0.2,0.26-0.31,0.15c0.01,0.05-0.02,0.21-0.08,0.28
			c-0.02-0.24-0.24,0.23-0.35,0.05c0.03,0.2-0.26,0.35-0.37,0.55c0-0.02,0-0.05,0.02-0.06c-0.1,0.07-0.16,0.05-0.24,0.07
			c0.11-0.07-0.16,0.74-0.22,0.13c0.07,0.08-0.14,0.24-0.04,0.28c-0.14,0-0.1,0.03-0.22-0.03c0.18,0.1-0.43,0.47-0.33,0.12
			c0.1,0.23-0.63,0.38-1.03,1.08c-0.63-0.64-0.01-0.02-1.47,0.54l0.01-0.15c-0.61,0.51-1.33,0.47-3.67,1.38l0.04-0.04
			c-0.2,0.02-0.16,0.27-0.37,0.22l0.03-0.03c-0.46,0.3-1.84-0.59-0.77-1.16c-0.01,0-0.02,0.01-0.02,0.01
			c0.08-0.09-0.14-0.45-1.58,0.25l0.01-0.08c-0.28,0.08-0.57,0.23-0.89,0.33c-0.6,0.23,0.02-0.01-2.32,0.75
			c-0.03-0.09-0.27-0.03-0.16-0.12c-0.4,0.04-0.66,0.42-2.14,0.36l0.02-0.01c-0.91,0.15,0.08,0-2.53,0.17
			c0.09,0.28-1.29-0.05-1.02-1.25c-0.01,0.03-0.37-0.23-0.17-0.32c-0.2-0.13-0.05-0.77-0.78-0.26c0.06-0.06,0.07-0.11,0.15-0.15
			c-0.17,0-0.71,0.37-0.67,0.41c-0.31,0.13,0-0.1-1.43,0.7c-0.05-0.15-0.37,0.01-0.46,0.12c0.02-0.06-0.12-0.01-0.1-0.09
			c-0.13,0.06-0.25,0.12-0.2,0.19c-0.49-0.01-0.31,0.02-1.77,0.64c0.28-0.45,0.7-0.27-1.44,0.29c0-0.01,0.03-0.04,0.06-0.06
			c-0.16,0.11,0.03-0.02-0.28,0.36c0.07-0.22-0.33,0-0.2-0.28c-0.04,0.02-0.09,0.06-0.07,0.11c-0.06,0-0.09-0.03-0.03-0.09
			c-0.22,0.08-0.13,0.14-0.33,0.17c0-0.03,0.04-0.05,0.05-0.1c-0.09,0.08-0.19,0.16-0.37,0.16c0.01-0.02-0.02-0.05,0.04-0.05
			c-0.3,0.05-0.49,0.25-0.83,0.21c0.08-0.16-0.12-0.04-0.15-0.17l-0.17,0.06l0.08,0.06c-0.15,0.01-0.28,0.12-0.42,0.09l0.11-0.06
			l-0.29,0.04l0.17,0.04c-0.43,0.24,0.03,0.13-1.16,0.12c0.11-0.09-0.18-0.22-0.3-0.02c0.02-0.01,0.01-0.02,0.05-0.03
			c-0.27,0.02-0.38-0.01-0.48-0.05l0.08-0.01c-0.33-0.06,0-0.01-1.04,0.01l0.03,0.02c-0.27,0.17-0.06-0.1-0.29-0.02
			c0.22-0.11,0.33-0.09-0.61-0.04c0.02,0.01,0.2-0.05,0.46-0.19c-0.11,0-0.04-0.03-0.25,0.01c0.6-0.15-0.21,0.05,0.9-0.4
			c-0.01,0.05-0.03,0.07,0.19-0.1c-0.06-0.06-0.06-0.02-0.15-0.05c0.02-0.06,0.09-0.14,0.16-0.18c-0.11,0.12,0.06,0.12,0.14,0.13
			c-0.02-0.02-0.01-0.05-0.03-0.07c0.23,0.09-0.01-0.08,0.22-0.14c-0.04,0.04,0.35-0.33,0.18-0.02c0.12-0.06,0.17-0.28,0.32-0.24
			c-0.04,0.05-0.12,0.08-0.16,0.13c0.17,0.04,0.17,0.01,0.27-0.02l-0.01,0l0.23-0.05l-0.07,0c0.06-0.29,0.3-0.18,0.45-0.42
			c-0.12,0.2-0.03,0.12,0.18,0.24c0.07-0.12,0.19-0.01,0.29-0.18c-0.05,0.07,0.8-0.5,1.85-0.81c-0.01,0.11-0.06,0.28,0.53-0.17
			l-0.1-0.01c0.03,0.13-0.06-0.2-0.61,0.22c0.09-0.17-0.01-0.3-0.47-0.03c0.01-0.04-0.05-0.09,0.03-0.1c-0.25,0-0.31,0.23-0.43,0.15
			l0.02-0.01c-0.36,0.03-0.44,0.16-0.97,0.52c-0.01-0.24-0.31,0.26-0.35-0.05c-0.1,0.28-0.04-0.16-0.76,0.44
			c-0.01-0.05-0.38,0.1-0.57,0.14l0-0.02c0.02,0.13-0.16,0.2-0.28,0.29c-0.03-0.07-0.02-0.11,0.03-0.18
			c-0.01,0.03-0.05-0.03-0.24,0.21l-0.01-0.11c-0.24,0.14-0.35,0.31-0.68,0.42c0.03,0.01,0.11,0.05,0.18,0.08
			c-0.01,0.01-0.09,0.14-0.1-0.02c-0.18,0.07,0.06,0.1-0.27,0.1c0.01-0.02,0.12-0.04,0.12-0.07c-0.12,0.05-0.32,0.12-0.46,0.14
			c0.1,0.08,0.13,0.1-0.32,0.17c0.05,0.03-0.2,0.12,0.04,0.1c-0.18,0.05,0-0.01-0.28,0.24C1329.26,281.88,1329.1,281.7,1329.9,281.9
			z M1357.89,279.94c0,0.02,0,0,0,0.05C1357.84,280.02,1357.72,280.02,1357.89,279.94z M1357.34,279.47
			c-0.02,0.01-0.04,0.02-0.08,0.04C1357.29,279.48,1357.26,279.5,1357.34,279.47z M1357.05,279.66c0.01-0.03-0.08-0.02-0.14-0.03
			C1356.89,279.67,1357.11,279.5,1357.05,279.66z"
              />
              <path
                className="st10"
                d="M1402.64,245.12l0-0.08l0.06,0.01c-0.01-0.08-0.05-0.02-0.08,0.02c0.01-0.04,0-0.07-0.01-0.09l-0.1,0.13
			c0.01-0.06,0.06-0.11,0.03-0.16c-0.05,0.06-0.01,0.15-0.07,0.18c-0.01-0.08,0.01-0.14-0.04-0.08c0.01-0.04,0.02-0.07,0.04-0.08
			c-0.05,0.04-0.09,0-0.13,0.08c-0.01-0.1-0.12-0.01-0.07-0.15c-0.02,0.03-0.09,0.1-0.12,0.1c0.1-0.16-0.11-0.04-0.04-0.23
			c-0.08,0.15-0.17,0.03-0.26,0.1c0.01-0.01,0.02-0.04,0.03-0.03c-0.04-0.02-0.04-0.08-0.05-0.11c-0.04,0.13-0.11,0.14-0.17,0.22
			c-0.01-0.14,0.07-0.15,0.09-0.27c-0.03,0.11-0.11,0.08-0.12,0.18c-0.02-0.09-0.02-0.05-0.01-0.16c-0.05,0.16-0.04,0.02-0.1,0.11
			c-0.03-0.1,0.03-0.16-0.05-0.13c0.14-0.18-0.28-0.09-0.53,0.16c0.02,0.01,0.03-0.03,0.04-0.05l-0.04,0.03
			c0.04-0.18,0.16-0.07-0.04-0.19c0.63,0.14,0.15-0.19-2.69-0.78l0.01-0.13c-0.18,0.16,0.14-0.13-0.3-0.03
			c0.02-0.04,0.04-0.05,0.07-0.07c-0.11,0.14,0-0.16-0.11,0.04c-0.01-0.07-0.09-0.1-0.15-0.09c0.01-0.13,0.06-0.07-0.3-0.14
			c0.03,0.05-0.02,0.17-0.06,0.17l0.02-0.11c-0.04,0.06-0.11,0.22,0-0.04l-0.06,0.02c0.01-0.03,0.03-0.05,0.04-0.09
			c-0.04,0.06-0.05-0.14-0.12-0.03c0.45-0.14-1.28-0.15-2.09-0.77c-0.01,0.02-0.04,0.15-0.05-0.01c0.07,0.3-0.87-0.41-0.82-0.31
			c-0.22,0.06-0.35-0.14-0.56-0.1c0.02-0.02,0.03-0.05,0.05-0.05c-0.03,0-0.17-0.05-0.67-0.12c0.06-0.15-0.06-0.16-0.13-0.11
			c0.03-0.04-0.02-0.06,0.02-0.12c-0.05-0.02-0.1-0.01-0.12,0.07c-0.01-0.21-0.22,0.07-0.18-0.17c-0.24,0-0.15,0.09-0.89-0.22
			c0.01-0.01,0.02-0.02,0.04-0.03c-0.02,0-0.04-0.04-0.06,0.03l0.03-0.01c-0.01,0.11-0.16,0.17-0.19,0.22
			c0.11-0.17-0.08-0.09,0.08-0.31c-0.02,0.01-0.05,0.03-0.06,0.08c-0.1-0.17-0.02-0.03-0.48-0.29c0.02,0.03-0.09-0.06-0.07,0.08
			c0.04-0.04-0.22,0.04-0.1-0.05l-0.1-0.02c0.01,0.07,0.03,0.08-0.08,0.16c-0.02-0.09-0.09-0.07-0.08-0.25
			c-0.07,0.09-0.13-0.02-0.2,0.07c0.02-0.02,0.05-0.08,0.05-0.1l-0.06,0.07c0.08-0.07-0.23-0.04-0.16-0.23l-0.04,0.06
			c0.07-0.13-0.3,0.06-0.48-0.16c0.02-0.01,0.06-0.09,0.05-0.07c-0.06-0.06-0.16-0.02-0.29-0.02c0.05,0.01-0.08-0.24-0.17-0.06
			c-0.06-0.14,0.03-0.35-0.55-0.12c0.07-0.03,0.05-0.12,0.05-0.17c-0.01,0.02-0.03,0.05-0.03,0.07c0.06-0.2-0.11,0.1-0.09-0.02
			c-0.03,0.01-0.19,0.03-0.03-0.07c-0.14-0.04,0.03,0.04-0.15-0.2c-0.13,0.27,0,0.02-0.26,0.14c0.12-0.11,0.05,0,0.1-0.27
			c-0.07,0.06-0.03-0.09-0.13,0c0.01-0.03-0.03-0.02,0.01-0.09c-0.15,0.34-0.06-0.21-0.58,0.12c-0.02-0.06,0.18-0.13-0.04-0.16
			c0.12-0.17-0.18,0.05,0.03-0.16c-0.05,0.01-0.12,0.05-0.17,0.06c0.02-0.06,0.06-0.07,0.09-0.1c0,0.02-0.1-0.02-0.17,0.1
			c0.06,0,0.21,0.07,0.11,0.21c-0.01-0.02,0.17,0.01,0.09,0.17c0.09,0.1,0.15,0.15,0.31-0.1l-0.07,0.26l0.06-0.09
			c0.01,0.01,0.02,0.03,0,0.06c0.07-0.05,0.01-0.04,0.07-0.1c0.01,0.11,0.15,0.02,0.09,0.18c0.05-0.07-0.02-0.08,0.06-0.1
			c-0.11,0.18,0.17-0.03,0.01,0.21c0.18-0.1,0.06,0.21,0.3,0.04c-0.04,0.04,0.04-0.01,0.11,0.21c0.07-0.11,0.12-0.06,0.17-0.06
			c-0.03,0.07-0.06,0.09-0.09,0.11c0.04,0.01,0.04,0.02,0.12-0.03l-0.05,0.08c0.06,0.02,0.16-0.05,0.2,0.04
			c0.04-0.08,0.05-0.25,0.15-0.27c-0.02,0.05-0.05,0.12-0.08,0.13c0.02-0.01,0.07,0.1-0.01,0.11c0.02,0.02,0.04,0.08,0.03,0.13
			c0.02-0.03,0.13-0.17,0.07,0.05c0.03-0.04,0.05,0.02,0.09-0.07c0.02,0.01,0,0.05-0.01,0.08c0.04-0.04,0,0,0.15-0.08l0,0
			c0.03-0.21,0.15,0.21,0.64,0.38c-0.08,0,0.08,0.19,0.02,0.03c0.12,0.24,0.59,0.04,0.58,0.35l0.02-0.04
			c0.01,0.05,0.08,0.08,0.04,0.13c0.06-0.02,0.04-0.06,0.02-0.09c0.04,0.08,0.1,0.05,0.16,0.11c0.02-0.1,0.34-0.14,0.12,0.13
			c0.09-0.05,0.1,0.1,0.13,0.13l-0.05,0.02c0.03-0.01,0.04,0,0.06,0l0.01-0.08c0.01,0.02,0.03,0.03,0.02,0.06
			c0.04-0.04,0.04-0.13,0.11-0.19c0.01,0.05,0.1-0.01,0.06,0.1c-0.07-0.07-0.03,0.11-0.11,0.09c0.09,0.09-0.01,0.13,0.19-0.02
			c-0.03,0.05-0.03,0.12-0.01,0.11c-0.03-0.02,0.02-0.1,0.07-0.13c0.03,0.07,0.07-0.01,0.1,0.01c-0.01-0.05-0.04-0.06,0.08-0.07
			c-0.02,0.05-0.05,0.02-0.04,0.04c0.08-0.08,0.05,0.14,0.13,0.1c0.01,0.05-0.04,0.11-0.03,0.14c0.09-0.11,0.04,0.2,0.21-0.05
			l-0.13,0.01c0.16-0.12,0.05-0.21,0.39,0.12c0-0.06-0.03-0.1,0.09-0.05c-0.03,0.06-0.02,0.09-0.02,0.14
			c0.02-0.05,0.07,0.03,0.13-0.09c0.04,0.11-0.03,0.19,0.25,0.01c-0.05,0.06-0.03,0.12-0.11,0.15c0.1-0.02-0.02,0.01,0.3-0.14
			c0.1,0.2-0.06,0.06,1.09,0.44c-0.05,0.08-0.05,0.03-0.07,0.12c-0.04-0.27,0.15,0.34,0.67,0.11c-0.07,0.08-0.16,0.11-0.22,0.15
			c0.03,0.01,0.12,0.03,0.16-0.02c-0.05-0.01-0.06-0.02,0.16-0.08c-0.11,0.25,0.15,0.13,0.12,0.34c0.15-0.05,0.22,0.12,0.36,0.16
			c-0.04-0.06,0.21-0.25,0.09-0.03c0.02-0.03,0.05-0.05,0.07-0.08c0.03,0.06,0.05,0.16,0.02,0.34c0.02-0.08,0.06-0.09,0.09-0.05
			c-0.08-0.08,0.2-0.03,0.08,0.07c0.06-0.02,0.19,0.07,0.29-0.08c0,0.03-0.01,0.09-0.03,0.09c0.17,0.13,0.24-0.13,1.66,0.6
			c-0.01,0.01,0.04-0.18,0.14-0.06l-0.01-0.01c-0.02,0.14,0.05,0.13,0.1,0.14l0.07-0.17c0.04,0.3,0.36,0.01,0.36,0.26
			c0.04-0.03,0.08-0.02,0.13-0.06c0,0.1-0.07,0.12,0.2,0.18l-0.04,0.06c0.09-0.13,0.22,0.16,0.66,0.01
			c-0.04,0.13,0.1,0.04,0.18,0.01c-0.12,0.22,0.05-0.05,0.63,0.19c-0.07-0.02-0.13-0.04-0.12-0.05c-0.09,0.05,0.23,0.35,0.37,0.1
			c0.01,0.01-0.01,0-0.04,0.01l0-0.01c-0.02,0-0.05-0.01-0.07-0.01c0.04-0.04,0.07-0.07,0.09-0.01c0.03-0.06,0.07-0.11,0.08-0.14
			c0.07,0.06,0.03,0.34,0.53,0.21c0.09,0.41-0.05,0.14,0.48,0.21c-0.01-0.02-0.01-0.03-0.01-0.05c0.11,0.04,0.15,0.26,0.77,0.18
			l-0.01,0.11c-0.11-0.25,0.33,0.24,0.83-0.02c-0.01,0-0.01,0-0.01,0.01c0.02-0.1,0.04-0.23,0.05-0.45L1402.64,245.12z
			 M1399.89,244.48c0.01-0.02-0.01-0.05-0.02-0.08C1399.89,244.42,1399.95,244.41,1399.89,244.48z M1399.95,245.08
			c-0.01,0.02-0.02,0.03-0.02,0.05C1399.92,245.14,1399.9,245.1,1399.95,245.08z M1399.99,244.44c0.01,0,0,0,0.03,0
			C1400.02,244.44,1400.01,244.44,1399.99,244.44z"
              />
              <path
                className="st10"
                d="M1396.22,250.03c0.05-0.08,0.07-0.06,0.09-0.14c0,0.01-0.01,0-0.04,0.01l0.01-0.01
			c-0.02-0.01-0.03-0.01-0.05-0.02c0.04-0.03,0.08-0.06,0.07,0c0.04-0.05,0.09-0.1,0.11-0.12c0.04,0.06-0.07,0.33,0.35,0.27
			c-0.05,0.37-0.09,0.13,0.31,0.27c0-0.02,0-0.03,0.01-0.05c0.05,0.05,0.08,0.29,0.54,0.28l-0.05,0.09
			c0.02-0.34,0.16,0.32,0.66,0.12c-0.01,0-0.01,0-0.01,0c0.05-0.09,0.11-0.21,0.2-0.4l-0.1,0.06l0.03-0.08l0.04,0.02
			c0.02-0.08-0.03-0.03-0.07,0c0.02-0.04,0.03-0.07,0.03-0.09l-0.13,0.11c0.03-0.06,0.09-0.1,0.08-0.14
			c-0.06,0.05-0.06,0.13-0.12,0.15c0.02-0.08,0.06-0.12,0-0.08c0.02-0.04,0.04-0.06,0.06-0.07c-0.05,0.03-0.07-0.02-0.13,0.05
			c0.03-0.09-0.09-0.03-0.01-0.15c-0.03,0.03-0.1,0.08-0.13,0.07c0.14-0.13-0.07-0.06,0.04-0.22c-0.12,0.12-0.14,0-0.24,0.05
			c0.01-0.01,0.03-0.03,0.03-0.02c-0.02-0.03,0-0.08,0-0.11c-0.08,0.11-0.14,0.11-0.21,0.17c0.04-0.13,0.11-0.12,0.16-0.24
			c-0.06,0.1-0.12,0.06-0.16,0.14c0.02-0.09,0-0.05,0.04-0.15c-0.1,0.14-0.04,0.01-0.12,0.09c0.01-0.09,0.08-0.14,0.01-0.12
			c0.11-0.21-0.04,0.01-0.35-0.03c0.01-0.06,0.02-0.04-0.12,0.1c0.01,0.01,0.03-0.02,0.05-0.04l-0.04,0.02
			c0.08-0.16,0.15-0.04,0.03-0.18l0.02,0.07c-0.04-0.16-0.06-0.28-0.66-0.38c0.02-0.03,0.05-0.05,0.06-0.09
			c-0.03,0.15-0.41-0.05-0.95-0.55c-0.06,0.08-0.29,0.04-0.28-0.2l-0.1,0.07l0.05-0.12c-0.14,0.1,0.1-0.14-0.24-0.06
			c0.02-0.04,0.05-0.04,0.08-0.06c-0.12,0.12,0.04-0.15-0.1,0.03c0.01-0.07-0.04-0.1-0.1-0.1c0.03-0.1,0.08-0.06-0.2-0.17
			c0.01,0.05-0.06,0.15-0.09,0.15l0.05-0.1c-0.05,0.05-0.15,0.2,0.01-0.04l-0.06,0.01c0.02-0.03,0.03-0.04,0.06-0.08
			c-0.05,0.05,0-0.13-0.09-0.04c0.43-0.08-1.02-0.29-1.49-0.92c-0.01,0.01-0.04,0.09-0.05,0.06c-0.09,0.05-0.62-0.41-0.58-0.41
			c-0.19,0.05-0.25-0.15-0.43-0.12c0.02-0.02,0.04-0.05,0.05-0.04c0.02-0.09-0.09-0.06-0.13,0c0.03-0.02,0.03-0.18-0.13-0.1
			c-0.02-0.07-0.11-0.01-0.12-0.09c-0.08,0.08-0.05-0.09-0.14,0.04c0.08-0.14-0.02-0.16-0.08-0.11c0.03-0.04,0-0.06,0.04-0.11
			c-0.03-0.02-0.08-0.01-0.11,0.06c0.03-0.2-0.2,0.06-0.11-0.17c-0.14,0.02-0.26,0.01-0.37-0.01c0.04-0.16,0.07-0.07-0.32-0.24
			c0.01-0.01,0.02-0.02,0.04-0.03c-0.02,0-0.02-0.04-0.06,0.02l0.02-0.01c-0.03,0.1-0.17,0.15-0.2,0.2
			c0.13-0.16-0.05-0.09,0.12-0.29c-0.02,0.01-0.05,0.03-0.07,0.07c-0.04-0.15,0-0.03-0.33-0.29c0.01,0.03-0.06-0.06-0.08,0.07
			c0.04-0.04-0.19,0.03-0.07-0.05l-0.08-0.02c0,0.07,0.01,0.07-0.09,0.15c0-0.09-0.07-0.07-0.02-0.24c-0.07,0.08-0.1-0.02-0.18,0.07
			c0.02-0.02,0.06-0.08,0.06-0.09l-0.06,0.07c0.06-0.12-0.04-0.09-0.03-0.06c0.03-0.05-0.29-0.07-0.08-0.2
			c-0.09-0.03-0.24,0.04-0.3-0.03c-0.08,0.21,0,0.01-0.09-0.04c0.02-0.01,0.06-0.08,0.05-0.07c-0.04-0.06-0.13-0.02-0.24-0.02
			c0.03,0.01-0.03-0.23-0.13-0.06c0.01-0.14-0.03-0.37-0.43-0.11c0.06-0.03,0.06-0.11,0.07-0.16l-0.04,0.07
			c0.08-0.2-0.11,0.11-0.07-0.01c-0.03,0.01-0.15,0.02-0.01-0.07c-0.11-0.03,0.02,0.02-0.1-0.19c-0.15,0.27-0.01,0.02-0.23,0.13
			c0.11-0.11,0.03,0.01,0.12-0.26c-0.07,0.06-0.01-0.08-0.1,0.01c0.02-0.03-0.02-0.02,0.02-0.09c0.2,0.16-0.44,0.13-0.45-0.18
			c-0.04,0.01-0.1,0.05-0.14,0.06c0.02-0.06,0.06-0.07,0.09-0.1c-0.01,0.02-0.08-0.02-0.15,0.1c0.05,0,0.17,0.06,0.06,0.2
			c0-0.02,0.14,0.02,0.05,0.16c0.07,0.1,0.11,0.13,0.27-0.1l-0.09,0.25l0.06-0.09c0.01,0.01,0.02,0.02-0.01,0.06
			c0.06-0.05,0.02-0.04,0.07-0.09c-0.01,0.11,0.12,0.02,0.05,0.17c0.05-0.06,0-0.07,0.06-0.1c-0.12,0.18,0.15-0.02-0.02,0.2
			c0.02-0.01,0.02-0.02,0.03-0.03c-0.01,0.02,0,0.05,0.08,0.06c-0.02,0.11-0.14,0.03,0.13,0c-0.04,0.04,0.04,0,0.06,0.2
			c0.07-0.11,0.1-0.06,0.15-0.06c-0.04,0.07-0.06,0.09-0.09,0.11c0.04,0.01,0.03,0.02,0.1-0.03l-0.05,0.08
			c0.04,0.02,0.14-0.05,0.16,0.04c0.05-0.08,0.08-0.24,0.16-0.26c-0.04,0.07-0.07,0.08-0.11,0.36c0.02-0.03,0.13-0.16,0.04,0.05
			c0.03-0.04,0.03,0.02,0.09-0.07c0.01,0.01-0.01,0.05-0.02,0.08c0.04-0.03,0.01,0.01,0.14-0.07l0,0c0.05-0.21,0.1,0.21,0.45,0.36
			c-0.07,0,0.03,0.18,0.01,0.03c0.13,0.3,0.53,0.01,0.41,0.34l0.03-0.03c0,0.05,0.05,0.07,0.01,0.13c0.05-0.02,0.05-0.05,0.04-0.08
			c0.02,0.08,0.08,0.05,0.11,0.11c-0.15,0.05,0.31-0.1,0.07,0.13c0.09-0.04,0.06,0.1,0.08,0.12l-0.04,0.01
			c0.02-0.01,0.04,0,0.05,0.01l0.03-0.08c0.01,0.02,0.02,0.03,0,0.06c0.04-0.04,0.06-0.13,0.12-0.17c0,0.05,0.08-0.01,0.03,0.09
			c-0.04-0.07-0.05,0.1-0.11,0.09c0.06,0.1-0.04,0.12,0.16-0.01c-0.04,0.05-0.05,0.11-0.03,0.1c-0.02-0.02,0.04-0.1,0.09-0.13
			c0.01,0.06,0.06-0.01,0.08,0.01c0-0.05-0.02-0.05,0.08-0.07c-0.02,0.04-0.04,0.01-0.04,0.04c0.08-0.08,0.01,0.14,0.09,0.1
			c0,0.05-0.06,0.1-0.05,0.14c0.09-0.09,0,0.19,0.18-0.04l-0.11,0c0.08-0.16,0.31-0.05,0.33,0.22c0.02-0.05,0.05,0.04,0.13-0.08
			c-0.15,0.26,0.34-0.12,0.08,0.16c0.09-0.02-0.02,0.01,0.28-0.12c-0.05,0.22,0.17,0.08,0.13,0.27c0.32,0.02,0.11-0.08,0.65,0.2
			c-0.06,0.07-0.05,0.03-0.08,0.11c0.23-0.32,0.16,0.04,0.3,0.2c0.04-0.01,0.15-0.07,0.21-0.06c-0.08,0.07-0.15,0.09-0.21,0.12
			c0.25-0.02,0.02-0.13,0.61,0.46c0.01-0.08,0.07-0.09,0.1-0.15c0.02,0.05,0.01,0.17-0.07,0.35c0.03-0.07,0.07-0.08,0.09-0.04
			c-0.05-0.07,0.17-0.01,0.05,0.07c0.06-0.01,0.13,0.08,0.25-0.05c-0.4,0.23,0.54-0.01,1.11,0.8c0-0.03,0.06-0.1,0.09-0.11
			c0.01,0.04,0.11-0.06,0.04,0.06l-0.01-0.01c-0.06,0.13,0.01,0.12,0.04,0.15l0.1-0.15c-0.05,0.28,0.28,0.05,0.21,0.29
			c0.05-0.03,0.07-0.01,0.12-0.04c-0.02,0.07-0.11,0.1,0.1,0.19c-0.22,0.23,0.19,0.11,0.47,0.14c-0.07,0.11,0.07,0.05,0.14,0.03
			c-0.06,0.11-0.15,0.07,0.26,0.15c0.04-0.05,0.12,0,0.18,0.11c-0.05-0.03-0.09-0.06-0.08-0.06
			C1396.01,249.9,1395.72,249.98,1396.22,250.03z M1396.37,249.3c0.02,0.03,0.06,0.03-0.01,0.08
			C1396.38,249.36,1396.37,249.33,1396.37,249.3z M1396.22,249.95c-0.01,0.01-0.02,0.03-0.03,0.04
			C1396.18,250,1396.17,249.96,1396.22,249.95z"
              />
              <path
                className="st10"
                d="M1393.31,252.36l0.04-0.07l0.04,0.03c0.03-0.07-0.02-0.03-0.07-0.01c0.03-0.03,0.04-0.06,0.04-0.08
			l-0.14,0.08c0.04-0.05,0.1-0.08,0.1-0.13c-0.07,0.04-0.08,0.12-0.14,0.13c0.03-0.07,0.08-0.11,0.01-0.08
			c0.03-0.03,0.05-0.05,0.07-0.06c-0.05,0.02-0.06-0.03-0.13,0.03c0.04-0.08-0.08-0.04,0.03-0.15c-0.03,0.02-0.11,0.06-0.14,0.05
			c0.16-0.11-0.06-0.07,0.08-0.21c-0.13,0.1-0.13-0.02-0.24,0.01c0.01-0.01,0.03-0.02,0.03-0.01c-0.01-0.03,0.01-0.07,0.02-0.11
			c-0.09,0.1-0.15,0.09-0.23,0.14c0.06-0.12,0.13-0.1,0.2-0.21c-0.07,0.08-0.12,0.04-0.18,0.12c0.03-0.08,0.01-0.05,0.07-0.14
			c-0.12,0.12-0.04,0-0.13,0.07c0.02-0.09,0.1-0.13,0.03-0.12c0.14-0.19-0.05,0.01-0.33-0.09c0.02-0.06,0.03-0.04-0.13,0.08
			c0.01,0.01,0.03-0.01,0.05-0.03l-0.04,0.01c0.07-0.1,0.05-0.01,0.11-0.08c-0.04,0-0.26-0.01-0.04-0.09l0.01,0.07
			c-0.01-0.16,0-0.29-0.55-0.48c0.03-0.02,0.06-0.04,0.07-0.08c-0.05,0.14-0.38-0.12-0.81-0.7c-0.07,0.07-0.29-0.01-0.24-0.24
			l-0.11,0.06l0.07-0.11c-0.15,0.07,0.12-0.13-0.22-0.1c0.03-0.03,0.05-0.03,0.08-0.04c-0.14,0.09,0.06-0.14-0.1,0.01
			c0.02-0.07-0.02-0.11-0.07-0.11c0.05-0.09,0.09-0.05-0.16-0.19c0,0.05-0.09,0.14-0.12,0.13l0.07-0.09
			c-0.05,0.04-0.18,0.17,0.02-0.04l-0.06,0c0.02-0.03,0.04-0.03,0.07-0.07c-0.06,0.04,0.02-0.13-0.08-0.05
			c0.43-0.01-0.94-0.44-1.27-1.12c-0.01,0.01-0.06,0.08-0.06,0.05c-0.09,0.04-0.52-0.5-0.49-0.49c-0.2,0.02-0.22-0.19-0.4-0.18
			c0.02-0.02,0.05-0.04,0.06-0.03c0.03-0.08-0.08-0.07-0.12-0.02c0.03-0.01,0.06-0.17-0.11-0.12c-0.01-0.07-0.1-0.03-0.1-0.1
			c-0.09,0.07-0.04-0.1-0.14,0.02c0.1-0.12,0.01-0.16-0.06-0.12c0.04-0.03,0.01-0.06,0.06-0.1c-0.03-0.02-0.08-0.02-0.12,0.05
			c0.07-0.19-0.2,0.03-0.08-0.18c-0.14,0-0.25-0.02-0.35-0.06c0.06-0.15,0.09-0.06-0.27-0.27c0.01-0.01,0.03-0.02,0.04-0.02
			c-0.02-0.01-0.02-0.04-0.06,0.02l0.02-0.01c-0.04,0.1-0.19,0.13-0.22,0.17c0.15-0.14-0.03-0.09,0.16-0.27
			c-0.02,0-0.05,0.02-0.08,0.06c-0.02-0.15,0.01-0.03-0.28-0.32c0.01,0.03-0.05-0.07-0.09,0.06c0.04-0.03-0.19,0.01-0.06-0.05
			l-0.07-0.03c-0.02,0.07,0,0.07-0.12,0.14c0.02,0,0,0,0-0.1c-0.06,0-0.15,0.03,0.02-0.14c-0.08,0.07-0.09-0.03-0.18,0.04
			c0.02-0.02,0.07-0.07,0.07-0.08l-0.07,0.06c0.07-0.11-0.02-0.09-0.02-0.06c0.04-0.04-0.28-0.11-0.04-0.2
			c-0.08-0.04-0.24,0.01-0.29-0.07c-0.11,0.19,0,0.01-0.08-0.05c0.02,0,0.07-0.07,0.06-0.06c-0.03-0.06-0.12-0.04-0.23-0.05
			c0.03,0.02,0.01-0.23-0.12-0.07c0.03-0.14,0.03-0.36-0.4-0.16c0.06-0.02,0.08-0.1,0.09-0.15l-0.05,0.06
			c0.11-0.18-0.12,0.09-0.07-0.02c-0.03,0-0.15,0,0-0.07c-0.1-0.04,0.01,0.02-0.06-0.2c-0.18,0.24-0.01,0.01-0.25,0.1
			c0.13-0.1,0.03,0.02,0.16-0.24c-0.07,0.05,0-0.08-0.1-0.01c0.02-0.03-0.02-0.02,0.04-0.09c0.18,0.17-0.46,0.07-0.42-0.22
			c-0.04,0.01-0.11,0.04-0.15,0.04c0.03-0.05,0.07-0.06,0.1-0.08c-0.01,0.02-0.07-0.03-0.16,0.08c0.05,0.01,0.16,0.08,0.03,0.2
			c0-0.02,0.14,0.03,0.02,0.16c0.06,0.11,0.09,0.13,0.28-0.07l-0.13,0.23l0.07-0.08c0,0.01,0.01,0.02-0.02,0.06
			c0.07-0.04,0.02-0.04,0.08-0.08c-0.03,0.1,0.12,0.03,0.02,0.17c0.06-0.06,0.01-0.07,0.08-0.09c-0.14,0.16,0.15-0.01-0.05,0.19
			c0.02-0.01,0.02-0.02,0.03-0.02c-0.01,0.02-0.01,0.05,0.07,0.07c-0.03,0.11-0.15,0.02,0.13,0.02c-0.05,0.03,0.04,0.01,0.03,0.2
			c0.09-0.1,0.11-0.05,0.16-0.05c-0.05,0.06-0.07,0.08-0.11,0.1c0.04,0.01,0.02,0.02,0.11-0.02l-0.07,0.07
			c0.04,0.02,0.14-0.03,0.15,0.05c0.06-0.07,0.12-0.23,0.2-0.24c-0.04,0.07-0.08,0.06-0.16,0.33c0.02-0.03,0.16-0.14,0.04,0.05
			c0.04-0.03,0.03,0.03,0.09-0.06c0.01,0.01-0.02,0.05-0.03,0.07c0.04-0.03,0,0.01,0.15-0.06l0,0c0.15-0.1,0.13,0.38,0.73,0.82
			l0.03-0.03c-0.01,0.05,0.04,0.08-0.01,0.12c0.05-0.01,0.05-0.04,0.05-0.08c0.01,0.08,0.07,0.05,0.08,0.12
			c-0.15,0.03,0.31-0.06,0.05,0.13c0.09-0.03,0.04,0.1,0.06,0.13l-0.04,0.01c0.03-0.01,0.03,0,0.05,0.01l0.04-0.07
			c0,0.02,0.01,0.03-0.01,0.06c0.05-0.03,0.08-0.12,0.15-0.15c-0.01,0.05,0.08,0,0.02,0.1c-0.03-0.07-0.06,0.09-0.12,0.07
			c0.04,0.11-0.05,0.11,0.16,0.01c-0.05,0.04-0.06,0.1-0.04,0.1c-0.02-0.02,0.05-0.09,0.1-0.11c0,0.06,0.06,0,0.07,0.02
			c0.01-0.05-0.01-0.05,0.09-0.05c-0.03,0.04-0.04,0.01-0.05,0.03c0.09-0.06-0.01,0.13,0.07,0.11c-0.01,0.05-0.07,0.09-0.07,0.13
			c0.11-0.07-0.02,0.18,0.18-0.02l-0.11-0.01c0.1-0.15,0.31,0,0.28,0.25c0.03-0.04,0.04,0.04,0.14-0.06
			c-0.19,0.23,0.35-0.07,0.05,0.16c0.09,0-0.02,0.01,0.29-0.08c-0.08,0.21,0.15,0.1,0.08,0.28c0.31,0.06,0.12-0.07,0.59,0.29
			c-0.07,0.06-0.05,0.02-0.1,0.1c0.27-0.28,0.15,0.06,0.26,0.24c0.04-0.01,0.16-0.04,0.21-0.03c-0.09,0.06-0.16,0.07-0.23,0.09
			c0.24,0.02,0.04-0.12,0.51,0.53c0.02-0.08,0.08-0.07,0.12-0.13c0.01,0.05-0.02,0.16-0.13,0.33c0.04-0.06,0.09-0.07,0.09-0.03
			c-0.04-0.07,0.17,0.01,0.04,0.08c0.06,0,0.11,0.1,0.25-0.01c-0.44,0.16,0.53,0.07,0.92,0.95c0.01-0.03,0.08-0.09,0.1-0.09
			c0,0.04,0.12-0.04,0.02,0.07l0-0.01c-0.08,0.12-0.02,0.12,0.01,0.15l0.12-0.14c-0.1,0.27,0.26,0.1,0.15,0.31
			c0.05-0.02,0.07,0,0.12-0.02c-0.03,0.06-0.13,0.08,0.06,0.2c-0.25,0.19,0.16,0.14,0.42,0.21c-0.09,0.1,0.05,0.06,0.13,0.05
			c-0.08,0.1-0.16,0.05,0.22,0.18c0.05-0.05,0.12,0.02,0.15,0.14c-0.04-0.04-0.07-0.07-0.06-0.07c-0.06-0.01,0.02,0.39,0.22,0.18
			c0,0.01,0-0.01-0.04,0l0.01-0.01c-0.01-0.01-0.03-0.02-0.05-0.03c0.05-0.03,0.09-0.05,0.07,0.01c0.05-0.04,0.1-0.08,0.13-0.1
			c0.03,0.06-0.13,0.31,0.29,0.32c-0.12,0.35-0.11,0.11,0.25,0.32c0-0.02,0-0.03,0.02-0.05c0.04,0.06,0.03,0.3,0.46,0.37l-0.06,0.09
			c0.09-0.33,0.09,0.34,0.6,0.23c-0.01,0-0.01,0-0.01,0c0.06-0.08,0.14-0.19,0.27-0.36L1393.31,252.36z M1391.36,251.6
			c-0.02,0.01-0.01-0.03,0.04-0.03C1391.38,251.58,1391.37,251.59,1391.36,251.6z M1391.64,251.03c0.02-0.01,0.02-0.04,0.02-0.07
			C1391.67,250.98,1391.71,250.99,1391.64,251.03z"
              />
              <path
                className="st10"
                d="M1380.91,245.82l-0.03,0.02c0.01,0,0.01,0,0.01,0c-0.07,0.07-0.16,0.17-0.31,0.33l0.11-0.02l-0.05,0.07
			l-0.04-0.03c-0.04,0.07,0.02,0.04,0.07,0.03c-0.03,0.03-0.05,0.06-0.05,0.08l0.15-0.06c-0.04,0.05-0.11,0.06-0.11,0.12
			c0.07-0.03,0.09-0.11,0.16-0.11c-0.04,0.07-0.09,0.1-0.02,0.09c-0.03,0.03-0.05,0.04-0.08,0.05c0.06-0.01,0.07,0.04,0.14,0
			c-0.05,0.08,0.08,0.06-0.04,0.16c0.03-0.02,0.13-0.04,0.15-0.02c-0.17,0.08,0.06,0.08-0.1,0.2c0.15-0.08,0.14,0.05,0.25,0.04
			c-0.01,0.01-0.04,0.02-0.03,0.01c0.01,0.04-0.02,0.08-0.03,0.11c0.11-0.09,0.17-0.06,0.25-0.09c-0.07,0.12-0.14,0.08-0.23,0.18
			c0.08-0.07,0.13-0.01,0.19-0.08c-0.04,0.08-0.01,0.05-0.09,0.13c0.13-0.11,0.04,0.01,0.14-0.04c-0.03,0.09-0.11,0.11-0.04,0.12
			c-0.21,0.08,0.17,0.2,0.48,0.12c-0.01-0.01-0.03,0.01-0.06,0.02l0.04,0c-0.13,0.12-0.15-0.02-0.08,0.17l0-0.07
			c-0.11,0.24,0.4,0.18,1.22,1.64c0.08-0.05,0.29,0.07,0.22,0.31l0.12-0.03l-0.08,0.1c0.17-0.04-0.15,0.1,0.21,0.16
			c-0.04,0.02-0.06,0.02-0.09,0.02c0.16-0.06-0.09,0.13,0.11,0.01c-0.03,0.06,0.01,0.12,0.06,0.14c-0.07,0.09-0.09,0.03,0.14,0.25
			c0-0.05,0.11-0.12,0.14-0.11l-0.08,0.08c0.06-0.03,0.21-0.13-0.02,0.03l0.06,0.01c-0.03,0.02-0.05,0.03-0.08,0.05
			c0.07-0.03-0.04,0.13,0.07,0.08c-0.44-0.1,0.89,0.71,1.14,1.54c0.02-0.01,0.12-0.11,0.04,0.03c0.05-0.28,0.54,0.72,0.45,0.6
			c0.21,0.04,0.19,0.26,0.37,0.32c-0.03,0.01-0.06,0.03-0.06,0.02c-0.05,0.08,0.07,0.1,0.12,0.06c-0.04,0.01-0.1,0.16,0.09,0.16
			c0,0.07,0.1,0.06,0.08,0.14c0.11-0.04,0.02,0.11,0.15,0.03c-0.13,0.09-0.04,0.16,0.04,0.14c-0.05,0.02-0.02,0.05-0.08,0.08
			c0.03,0.03,0.07,0.05,0.13-0.01c-0.11,0.17,0.21,0.04,0.04,0.21c0.14,0.05,0.26,0.11,0.35,0.18c-0.1,0.13-0.09,0.04,0.21,0.38
			c-0.01,0.01-0.03,0.01-0.05,0.01c0.01,0.01,0.01,0.05,0.06,0.01l-0.03,0c0.07-0.08,0.22-0.06,0.27-0.09
			c-0.19,0.09,0.01,0.11-0.23,0.21c0.02,0,0.05,0,0.09-0.03c-0.02,0.17-0.01,0.03,0.21,0.43c0-0.03,0.04,0.09,0.1-0.03
			c-0.05,0.02,0.19,0.06,0.05,0.08l0.07,0.06c0.03-0.06,0.02-0.07,0.15-0.1c-0.04,0.09,0.03,0.09-0.08,0.23
			c0.1-0.04,0.09,0.07,0.2,0.03c-0.03,0.01-0.08,0.05-0.09,0.06l0.08-0.03c-0.1,0.09-0.01,0.1,0,0.07
			c-0.05,0.03,0.26,0.21-0.01,0.22c0.08,0.07,0.25,0.08,0.28,0.18c0.16-0.15,0.01-0.01,0.07,0.08c-0.02,0-0.09,0.04-0.08,0.04
			c0.01,0.07,0.11,0.09,0.22,0.14c-0.03-0.03-0.07,0.23,0.1,0.12c-0.06,0.13-0.13,0.35,0.36,0.33c-0.07-0.01-0.11,0.07-0.13,0.11
			c0.02-0.02,0.05-0.03,0.07-0.04c-0.16,0.14,0.15-0.04,0.06,0.05c0.03,0.01,0.16,0.06-0.02,0.07c0.09,0.09,0-0.03,0.01,0.22
			c0.25-0.17,0.01-0.01,0.28,0c-0.15,0.04-0.03-0.02-0.23,0.17c0.09-0.02-0.03,0.08,0.1,0.05c-0.03,0.02,0.02,0.03-0.06,0.07
			c-0.11-0.25,0.46,0.1,0.35,0.4c0.05,0.01,0.12,0.01,0.16,0.02c-0.04,0.04-0.09,0.03-0.13,0.04c0.02-0.02,0.06,0.06,0.18-0.01
			c-0.05-0.03-0.13-0.14,0.03-0.21c0,0.02-0.13-0.09,0.02-0.17c-0.02-0.13-0.05-0.18-0.3-0.05l0.2-0.18l-0.09,0.05
			c0-0.01-0.01-0.03,0.03-0.05c-0.08,0.01-0.04,0.03-0.1,0.05c0.06-0.09-0.11-0.08,0.03-0.18c-0.08,0.03-0.03,0.07-0.1,0.06
			c0.19-0.1-0.15-0.05,0.1-0.17c-0.19,0.01,0.06-0.19-0.22-0.16c0.06-0.01-0.04-0.02,0.02-0.21c-0.11,0.06-0.12,0-0.17-0.02
			c0.06-0.04,0.09-0.05,0.14-0.05c-0.03-0.03-0.02-0.03-0.11-0.03l0.09-0.05c-0.03-0.04-0.15-0.02-0.14-0.11
			c-0.08,0.05-0.18,0.18-0.27,0.16c0.04-0.03,0.11-0.08,0.13-0.07c0.01-0.12-0.05,0.07,0.12-0.2c-0.03,0.02-0.2,0.08-0.02-0.07
			c-0.05,0.02-0.02-0.04-0.11,0.02c-0.01-0.01,0.03-0.04,0.05-0.06c-0.05,0.01,0-0.02-0.16,0l0,0c-0.13,0.16-0.01-0.24-0.29-0.57
			c0.06,0.03,0.04-0.19,0-0.03c0-0.34-0.51-0.28-0.25-0.53l-0.04,0.02c0.02-0.04-0.02-0.1,0.04-0.12c-0.05-0.01-0.06,0.03-0.07,0.06
			c0.01-0.09-0.05-0.08-0.06-0.16c-0.07,0.07-0.34-0.04-0.02-0.16c-0.1,0-0.02-0.12-0.03-0.16l0.05,0.01
			c-0.03,0-0.04-0.02-0.04-0.03l-0.06,0.06c0-0.02-0.01-0.04,0.02-0.06c-0.06,0.02-0.1,0.09-0.19,0.1c0.02-0.04-0.08-0.03,0.01-0.1
			c0.01,0.08,0.09-0.07,0.14-0.03c-0.02-0.12,0.08-0.1-0.16-0.07c0.06-0.03,0.09-0.08,0.07-0.08c0.02,0.03-0.08,0.07-0.13,0.08
			c0.01-0.07-0.06-0.02-0.07-0.05c-0.02,0.05,0,0.06-0.1,0.02c0.04-0.03,0.04,0.01,0.06-0.02c-0.11,0.03,0.04-0.13-0.05-0.13
			c0.02-0.04,0.09-0.07,0.11-0.1c-0.13,0.04,0.07-0.18-0.19-0.05l0.11,0.05c-0.14,0.11-0.31-0.08-0.24-0.37
			c-0.04,0.03-0.04-0.06-0.15,0.01c0.25-0.17-0.37-0.05-0.02-0.19c-0.09-0.03,0.02,0-0.32-0.01c0.13-0.19-0.14-0.16-0.02-0.32
			c-0.3-0.16-0.13,0.03-0.56-0.5c0.08-0.04,0.05-0.01,0.12-0.07c-0.34,0.2-0.14-0.11-0.22-0.34c-0.05,0-0.17-0.01-0.22-0.04
			c0.1-0.04,0.18-0.02,0.25-0.02c-0.02-0.03-0.07-0.08-0.13-0.05c0.04,0.01-0.2,0.16-0.07-0.33c-0.14-0.03-0.09-0.2-0.18-0.29
			c-0.01,0.07-0.3,0.1-0.09-0.01c-0.03,0.01-0.07,0.01-0.1,0.04c0.01-0.06,0.05-0.15,0.18-0.28c-0.06,0.05-0.1,0.05-0.1,0
			c0.02,0.09-0.17-0.06-0.02-0.09c-0.06-0.01-0.1-0.14-0.27-0.07c0.41-0.15-0.32-0.18-0.96-1.28l0,0.01c0.1-0.1,0.03-0.12,0.01-0.16
			l-0.15,0.11c0.14-0.26-0.26-0.17-0.11-0.37c-0.05,0.01-0.07-0.02-0.13-0.01c0.05-0.07,0.13-0.06-0.04-0.23
			c0.28-0.14-0.14-0.19-0.42-0.34c0.1-0.08-0.05-0.08-0.13-0.08c0.11-0.09,0.16-0.01-0.21-0.25c-0.06,0.03-0.12-0.04-0.14-0.18
			c0.04,0.05,0.07,0.09,0.06,0.09c0.08,0.02,0.03-0.4-0.22-0.25c0-0.01,0,0.01,0.04,0.01l-0.01,0c0.01,0.01,0.03,0.03,0.05,0.04
			c-0.05,0.02-0.1,0.03-0.07-0.03c-0.06,0.03-0.12,0.06-0.15,0.07c-0.02-0.07,0.18-0.29-0.27-0.41c0.16-0.35,0.12-0.09-0.23-0.39
			c0,0.02-0.01,0.03-0.02,0.04c-0.05-0.08,0.02-0.3-0.46-0.49l0.07-0.08C1381.47,246.47,1381.3,245.82,1380.91,245.82z
			 M1382.7,247.38c0.01,0,0.02,0.04-0.04,0.03C1382.67,247.4,1382.69,247.39,1382.7,247.38z M1382.35,247.92
			c-0.02,0.01-0.02,0.04-0.03,0.07C1382.31,247.96,1382.27,247.94,1382.35,247.92z"
              />
              <path
                className="st10"
                d="M1382.52,259.51c-0.01,0-0.03,0-0.05-0.01c0.01,0.02-0.01,0.06,0.06,0.03l-0.02-0.01
			c0.09-0.06,0.23,0.02,0.29,0.01c-0.21,0.02-0.02,0.12-0.28,0.14c0.02,0.01,0.05,0.02,0.1,0c-0.07,0.19-0.01,0.03,0.08,0.56
			c0.01-0.04,0.01,0.11,0.11,0.01c-0.06-0.01,0.17,0.15,0.02,0.1l0.05,0.09c0.05-0.05,0.04-0.08,0.17-0.04
			c-0.06,0.07,0,0.11-0.15,0.22c0.11,0,0.06,0.11,0.18,0.12c-0.03,0-0.1,0.01-0.11,0.02l0.09,0c-0.1-0.02,0.11,0.21-0.09,0.27
			l0.07,0c-0.15,0.02,0.23,0.2,0.16,0.48c-0.02-0.01-0.1,0-0.08,0c-0.01,0.08,0.08,0.15,0.16,0.25c-0.03-0.04-0.14,0.21,0.05,0.18
			c-0.08,0.14-0.29,0.18,0.22,0.53c-0.06-0.04-0.13,0.02-0.17,0.05c0.02-0.01,0.05-0.01,0.08-0.01c-0.2,0.07,0.15,0.03,0.04,0.08
			c0.02,0.02,0.13,0.14-0.04,0.07c0.05,0.14,0.01-0.05-0.08,0.25c0.3-0.05,0.02-0.01,0.26,0.14c-0.16-0.03-0.03-0.04-0.28,0.07
			c0.09,0.02-0.06,0.07,0.08,0.11c-0.03,0.01,0,0.04-0.08,0.04c0.37-0.06-0.14,0.17,0.41,0.42c-0.04,0.05-0.21-0.08-0.11,0.12
			c-0.2-0.01,0.14,0.13-0.15,0.07c0.04,0.04,0.1,0.07,0.14,0.11c-0.06,0.02-0.09-0.02-0.13-0.03c0.02-0.01,0.03,0.1,0.17,0.09
			c-0.03-0.05-0.06-0.22,0.11-0.21c-0.01,0.02-0.08-0.16,0.09-0.17c0.03-0.13,0.04-0.22-0.25-0.21l0.25-0.09l-0.11,0
			c0-0.01,0.01-0.04,0.05-0.04c-0.08-0.03-0.05,0.01-0.12,0c0.09-0.07-0.07-0.14,0.1-0.18c-0.08-0.01-0.05,0.06-0.12,0.01
			c0.22-0.01-0.12-0.13,0.16-0.13c-0.18-0.09,0.13-0.17-0.14-0.28c0.06,0.01-0.03-0.03,0.1-0.22c-0.13,0.01-0.12-0.06-0.15-0.11
			c0.08-0.01,0.11-0.01,0.15,0.01c-0.02-0.04,0-0.05-0.09-0.09l0.1-0.01c-0.02-0.06-0.13-0.1-0.08-0.19
			c-0.09,0.01-0.23,0.11-0.31,0.04c0.05-0.01,0.13-0.03,0.15-0.01c-0.01-0.01,0.03-0.12,0.09-0.05c0.01-0.03,0.04-0.08,0.09-0.1
			c-0.04,0-0.21-0.01,0-0.08c-0.05-0.01-0.01-0.05-0.11-0.03c0-0.02,0.04-0.03,0.07-0.04c-0.06-0.01,0,0-0.16-0.08l0,0
			c-0.19,0.11,0.08-0.24-0.08-0.76c0.05,0.07,0.1-0.18,0.01-0.04c0.11-0.25-0.34-0.5-0.09-0.7l-0.04,0
			c0.03-0.04,0.01-0.12,0.08-0.12c-0.05-0.03-0.07,0-0.08,0.04c0.04-0.09-0.03-0.11-0.01-0.2c-0.08,0.05-0.33-0.18,0.02-0.18
			c-0.1-0.05,0.01-0.14,0.02-0.18l0.04,0.03c-0.03-0.02-0.03-0.03-0.04-0.05l-0.07,0.04c0.01-0.03,0-0.04,0.04-0.05
			c-0.06-0.01-0.13,0.06-0.21,0.03c0.03-0.04-0.07-0.07,0.03-0.11c-0.01,0.1,0.1-0.04,0.14,0.03c0.01-0.13,0.11-0.08-0.14-0.14
			c0.06-0.01,0.11-0.05,0.09-0.06c0.01,0.04-0.09,0.05-0.15,0.03c0.03-0.07-0.05-0.05-0.06-0.08c-0.03,0.04-0.02,0.07-0.11-0.02
			c0.05-0.02,0.04,0.03,0.06,0.01c-0.12-0.01,0.08-0.13-0.01-0.17c0.03-0.04,0.11-0.04,0.13-0.07c-0.14,0,0.12-0.17-0.18-0.13
			l0.09,0.1c-0.2-0.05-0.19,0.1-0.17-0.38c-0.04,0.04-0.05,0.09-0.1-0.04c0.07-0.02,0.08-0.05,0.12-0.08
			c-0.05,0.02-0.02-0.08-0.16-0.05c0.06-0.11,0.16-0.11-0.16-0.2c0.08,0,0.11-0.05,0.18-0.02c-0.08-0.07,0.02,0.01-0.31-0.14
			c0.07-0.21,0.09,0-0.41-1.13c0.09-0.01,0.06,0.01,0.14-0.03c-0.16,0.22,0.14-0.35-0.38-0.58c0.11,0,0.19,0.05,0.26,0.07
			c-0.01-0.03-0.06-0.11-0.13-0.1c0.02,0.05,0.03,0.06-0.17-0.07c0.25-0.09-0.01-0.2,0.16-0.33c-0.14-0.08-0.06-0.25-0.14-0.38
			c-0.02,0.07-0.32,0.01-0.09-0.04c-0.03,0.01-0.07-0.01-0.1,0.01c0.02-0.06,0.08-0.15,0.23-0.25c-0.07,0.04-0.11,0.02-0.1-0.03
			c0,0.11-0.16-0.13-0.01-0.11c-0.06-0.04-0.08-0.19-0.26-0.17c0.02-0.02,0.07-0.05,0.09-0.04c-0.03-0.22-0.26-0.09-0.75-1.65
			c0.02,0-0.16,0.1-0.14-0.06l0,0.01c0.11-0.08,0.05-0.13,0.03-0.18l-0.17,0.07c0.18-0.24-0.25-0.27-0.07-0.45
			c-0.06-0.01-0.07-0.05-0.13-0.05c0.08-0.07,0.13-0.03-0.01-0.27l0.07-0.01c-0.17,0.02-0.03-0.27-0.46-0.49
			c0.12-0.06-0.04-0.11-0.12-0.13c0.24-0.07-0.07,0-0.31-0.59c0.03,0.07,0.06,0.12,0.05,0.12c0.11,0.03,0.09-0.42-0.19-0.34
			c0-0.01,0.01,0.01,0.04,0.02l-0.01,0c0.01,0.02,0.03,0.04,0.04,0.06c-0.06,0-0.1,0-0.07-0.06c-0.06,0.01-0.13,0.03-0.16,0.04
			c-0.01-0.1,0.22-0.26-0.23-0.54c0.23-0.36,0.13-0.07-0.19-0.5c-0.01,0.02-0.01,0.03-0.03,0.04c-0.05-0.12,0.08-0.29-0.41-0.69
			l0.09-0.06c-0.1,0.25-0.06-0.41-0.6-0.61c0.01,0,0.01,0,0.01,0c-0.08,0.06-0.19,0.13-0.36,0.27l0.12,0.01l-0.06,0.06l-0.03-0.05
			c-0.05,0.06,0.02,0.05,0.07,0.05c-0.03,0.03-0.06,0.05-0.06,0.07l0.17-0.01c-0.05,0.04-0.13,0.03-0.13,0.09
			c0.08-0.01,0.11-0.1,0.18-0.07c-0.05,0.06-0.11,0.09-0.03,0.09c-0.03,0.02-0.06,0.03-0.09,0.03c0.06,0.01,0.06,0.07,0.15,0.04
			c-0.06,0.07,0.08,0.09-0.06,0.16c0.04-0.01,0.14,0,0.16,0.02c-0.19,0.04,0.05,0.11-0.13,0.19c0.17-0.04,0.14,0.1,0.26,0.12
			c-0.01,0-0.04,0.01-0.04,0c0.01,0.04-0.03,0.08-0.04,0.12c0.12-0.06,0.18-0.02,0.27-0.03c-0.09,0.11-0.16,0.05-0.26,0.12
			c0.1-0.06,0.14,0.03,0.21-0.03c-0.05,0.07-0.02,0.05-0.11,0.12c0.15-0.08,0.04,0.02,0.15,0c-0.05,0.09-0.13,0.09-0.06,0.12
			c-0.23,0.15,0.06-0.04,0.31,0.32c0.01,0.01,0.14-0.15,0.18-0.03c-0.01-0.02-0.04,0-0.06,0l0.05,0.01
			c-0.16,0.09-0.16-0.06-0.11,0.16l0.01-0.08c-0.24,0.1-0.19,0.11,1.35,2.61l-0.1,0.08c0.24,0.02-0.19-0.02,0.2,0.24
			c-0.04,0.02-0.06,0-0.1,0c0.18-0.02-0.11,0.12,0.11,0.05c-0.04,0.06-0.01,0.13,0.04,0.17c-0.1,0.09-0.09,0,0.11,0.32
			c0.01-0.05,0.13-0.1,0.16-0.08l-0.1,0.06c0.07-0.02,0.24-0.08-0.03,0.03l0.06,0.03c-0.03,0.02-0.05,0.01-0.09,0.03
			c0.07-0.01-0.06,0.13,0.06,0.11c-0.42-0.23,0.8,1.04,0.91,2.08c0.02-0.01,0.14-0.08,0.03,0.04c0.18-0.26,0.3,0.93,0.34,0.82
			c0.2,0.12,0.14,0.36,0.31,0.49c-0.03,0-0.06,0.01-0.07-0.01c0.02,0.02,0.08,0.16,0.36,0.59c-0.15,0.05-0.08,0.16,0.01,0.17
			c-0.05,0-0.03,0.05-0.1,0.06c0.02,0.05,0.06,0.08,0.13,0.04c-0.15,0.15,0.2,0.12-0.01,0.25
			C1382.25,258.87,1382.27,258.73,1382.52,259.51z M1379.07,251.44c0-0.03-0.05-0.07,0.04-0.07
			C1379.09,251.38,1379.09,251.41,1379.07,251.44z M1379.55,250.89c0.02,0.01,0,0.05-0.05,0.02
			C1379.52,250.9,1379.54,250.89,1379.55,250.89z"
              />
              <path
                className="st10"
                d="M1376.7,262.62l0.03,0.03c-0.02-0.02-0.02-0.03-0.02-0.05l-0.08,0.02c0.01-0.02,0.01-0.03,0.05-0.04
			c-0.06-0.02-0.14,0.02-0.21-0.01c0.04-0.03-0.05-0.07,0.06-0.09c-0.03,0.08,0.11-0.01,0.13,0.05c0.05-0.11,0.12-0.04-0.1-0.15
			c0.06,0.01,0.12-0.02,0.1-0.04c0,0.03-0.1,0.02-0.15-0.01c0.05-0.05-0.04-0.05-0.03-0.08c-0.04,0.03-0.04,0.05-0.1-0.03
			c0.05-0.01,0.03,0.03,0.06,0.02c-0.11-0.03,0.11-0.09,0.03-0.14c0.04-0.03,0.12-0.01,0.14-0.03c-0.13-0.03,0.16-0.12-0.13-0.14
			l0.06,0.1c-0.17-0.08-0.21,0.05-0.06-0.35c-0.05,0.02-0.07,0.07-0.08-0.05c0.07,0,0.09-0.02,0.14-0.04c-0.05,0,0-0.07-0.14-0.07
			c0.3-0.02-0.29-0.24,0.09-0.17c-0.06-0.07,0.02,0.01-0.26-0.18c0.21-0.09-0.03-0.21,0.15-0.29c-0.16-0.3-0.13-0.05-0.2-0.73
			c0.09,0.01,0.05,0.02,0.14,0.01c-0.39-0.01-0.06-0.17-0.01-0.41c-0.04-0.03-0.14-0.1-0.17-0.15c0.1,0.02,0.16,0.08,0.23,0.12
			c0-0.03-0.02-0.1-0.09-0.11c0.03,0.03-0.26,0.03,0.12-0.32c-0.11-0.1,0.02-0.22,0-0.34c-0.04,0.06-0.31-0.07-0.07-0.05
			c-0.03,0-0.06-0.03-0.1-0.02c0.04-0.05,0.13-0.1,0.31-0.14c-0.08,0.02-0.11-0.01-0.09-0.05c-0.03,0.09-0.11-0.15,0.03-0.09
			c-0.04-0.04-0.01-0.17-0.19-0.2c0.42,0.09-0.18-0.33-0.14-1.63l0,0.01c0.14-0.04,0.09-0.09,0.09-0.13l-0.18,0.01
			c0.25-0.15-0.13-0.29,0.1-0.38c-0.05-0.02-0.05-0.06-0.1-0.08c0.08-0.03,0.14,0.02,0.09-0.22c0.31,0.03-0.02-0.24-0.18-0.51
			c0.13-0.02,0-0.1-0.06-0.14c0.14-0.02,0.14,0.07-0.05-0.33c-0.07-0.01-0.08-0.1-0.03-0.23c0.01,0.06,0.01,0.12,0,0.11
			c0.06,0.06,0.24-0.33-0.05-0.33c0.01-0.01,0,0.01,0.03,0.03l-0.01,0c0,0.02,0.01,0.04,0.02,0.06c-0.05-0.01-0.1-0.03-0.05-0.07
			c-0.06-0.01-0.13-0.01-0.16-0.01c0.02-0.07,0.3-0.16-0.02-0.5c0.33-0.22,0.15-0.01,0.01-0.46c-0.01,0.01-0.02,0.02-0.04,0.03
			c0-0.09,0.17-0.25-0.13-0.67l0.1-0.03c-0.18,0.2,0.09-0.37-0.34-0.65c0.01,0.01,0.01,0.01,0.01,0.01
			c-0.1,0.02-0.23,0.06-0.43,0.12l0.11,0.04l-0.08,0.03l-0.01-0.05c-0.07,0.04,0,0.05,0.05,0.06c-0.04,0.01-0.07,0.02-0.08,0.04
			l0.16,0.03c-0.06,0.02-0.13-0.01-0.16,0.04c0.08,0.02,0.14-0.05,0.19-0.01c-0.07,0.04-0.13,0.04-0.06,0.06
			c-0.04,0.01-0.07,0.01-0.09,0c0.05,0.02,0.03,0.07,0.12,0.08c-0.09,0.04,0.04,0.09-0.11,0.12c0.04,0,0.13,0.03,0.14,0.06
			c-0.19-0.02,0.01,0.1-0.19,0.12c0.17,0.01,0.09,0.12,0.19,0.17c-0.01,0-0.04,0-0.03-0.01c-0.01,0.04-0.06,0.06-0.08,0.08
			c0.14-0.02,0.18,0.04,0.26,0.05c-0.12,0.06-0.16,0-0.29,0.03c0.11-0.02,0.12,0.06,0.21,0.03c-0.08,0.05-0.03,0.04-0.14,0.07
			c0.17-0.02,0.03,0.03,0.14,0.04c-0.08,0.06-0.16,0.04-0.1,0.08c-0.22-0.05,0.04,0.27,0.35,0.36c0-0.02-0.03-0.01-0.06-0.02
			l0.04,0.02c-0.18,0.03-0.12-0.1-0.16,0.1l0.04-0.07c-0.22,0.15,0.25,0.37,0.17,2.06c0.09,0,0.21,0.21,0.02,0.38l0.12,0.04
			l-0.13,0.04c0.17,0.06-0.18,0,0.1,0.25c-0.04,0-0.06-0.02-0.09-0.03c0.17,0.03-0.14,0.07,0.08,0.07
			c-0.06,0.04-0.06,0.11-0.02,0.15c-0.11,0.04-0.09-0.02-0.01,0.29c0.03-0.04,0.16-0.05,0.18-0.02l-0.11,0.02
			c0.07,0.01,0.25,0-0.04,0.02l0.04,0.04c-0.03,0.01-0.05,0-0.1,0c0.07,0.01-0.11,0.09,0.02,0.11c-0.32-0.32,0.38,1.09,0.15,1.94
			c0.02,0,0.16-0.03,0.02,0.04c0.19-0.21,0.08,0.92,0.06,0.76c0.15,0.15,0.02,0.33,0.15,0.48c-0.03-0.01-0.06-0.01-0.06-0.02
			c-0.08,0.04,0,0.12,0.07,0.12c-0.04-0.02-0.17,0.08-0.01,0.19c-0.04,0.06,0.05,0.11,0,0.16c0.11,0.02-0.05,0.1,0.11,0.1
			c-0.16,0.01-0.12,0.11-0.04,0.14c-0.05-0.01-0.05,0.04-0.11,0.02c0,0.04,0.04,0.08,0.12,0.07c-0.18,0.09,0.16,0.15-0.08,0.21
			c0.09,0.12,0.16,0.24,0.2,0.35c-0.15,0.06-0.1-0.02-0.02,0.44c-0.01,0-0.03-0.01-0.04-0.02c0.01,0.02-0.02,0.05,0.05,0.04
			l-0.02-0.02c0.1-0.04,0.22,0.06,0.28,0.07c-0.21-0.02-0.05,0.1-0.31,0.06c0.01,0.01,0.05,0.03,0.1,0.02
			c-0.11,0.14-0.02,0.02-0.06,0.48c0.02-0.03-0.02,0.09,0.1,0.03c-0.05-0.01,0.13,0.16,0,0.09l0.03,0.09c0.06-0.04,0.05-0.05,0.18,0
			c-0.08,0.05-0.02,0.1-0.19,0.16c0.11,0.02,0.03,0.11,0.15,0.13c-0.03-0.01-0.1-0.01-0.11,0l0.09,0.02
			c-0.13,0.02-0.06,0.08-0.03,0.06c-0.06-0.01,0.11,0.32-0.12,0.18c0.03,0.1,0.16,0.21,0.14,0.3c0.21-0.04,0.01,0,0.01,0.1
			c-0.01-0.01-0.1-0.01-0.08-0.01c-0.03,0.07,0.05,0.14,0.11,0.24c-0.01-0.04-0.18,0.16,0.02,0.16c-0.12,0.08-0.3,0.23,0.13,0.48
			c-0.05-0.04-0.13,0-0.17,0.02c0.02,0,0.05,0,0.08,0c-0.21,0.03,0.14,0.04,0.02,0.08c0.02,0.02,0.1,0.14-0.05,0.05
			c0.03,0.12,0.01-0.03-0.11,0.2c0.3-0.01,0.02,0,0.24,0.15c-0.15-0.05-0.01-0.04-0.29,0.02c0.09,0.03-0.07,0.05,0.06,0.1
			c-0.03,0,0,0.03-0.09,0.03c0.08-0.3,0.29,0.45,0.08,0.54c0.03,0.04,0.1,0.07,0.13,0.11c-0.06,0.01-0.09-0.02-0.13-0.04
			c0.02,0,0.02,0.09,0.16,0.09c-0.02-0.05-0.03-0.19,0.14-0.17c-0.01,0.02-0.06-0.15,0.11-0.14c0.05-0.12,0.06-0.18-0.22-0.21
			l0.26-0.05l-0.11-0.01c0-0.01,0.01-0.03,0.05-0.03c-0.07-0.04-0.05,0.01-0.12-0.01c0.1-0.05-0.05-0.13,0.12-0.14
			c-0.08-0.02-0.06,0.05-0.12,0c0.21,0.02-0.1-0.13,0.18-0.09c-0.17-0.1,0.14-0.13-0.09-0.25c0.05,0.02-0.02-0.03,0.13-0.17
			c-0.13-0.01-0.11-0.07-0.13-0.11c0.08,0,0.11,0.01,0.14,0.03c-0.01-0.04,0-0.04-0.08-0.09l0.1,0.01c-0.01-0.05-0.11-0.1-0.05-0.17
			c-0.09,0-0.25,0.06-0.31-0.01c0.05,0,0.13-0.01,0.15,0.01c0.02-0.02,0.02-0.03,0.02-0.03c0.01,0,0.04,0,0.18-0.07
			c-0.04,0-0.21-0.04,0.01-0.07c-0.05-0.01,0-0.05-0.1-0.04c0-0.02,0.05-0.02,0.08-0.03c-0.05-0.02,0.01-0.01-0.14-0.09v0
			c-0.2,0.07,0.12-0.21,0.07-0.64c0.04,0.06,0.14-0.14,0.02-0.03c0.18-0.29-0.28-0.51,0.07-0.59l-0.04,0
			c0.04-0.03,0.03-0.09,0.1-0.08c-0.04-0.04-0.07-0.01-0.09,0.02c0.06-0.07,0-0.1,0.04-0.17c-0.1,0.02-0.27-0.22,0.07-0.15
			C1376.56,262.72,1376.69,262.66,1376.7,262.62z M1375.91,254.55c0.01,0.01-0.01,0.04-0.05,0
			C1375.88,254.55,1375.9,254.55,1375.91,254.55z M1375.27,254.86c0.01-0.03-0.02-0.07,0.07-0.04
			C1375.31,254.82,1375.3,254.84,1375.27,254.86z"
              />
              <path
                className="st10"
                d="M1371.61,259.34c0.02,0,0.06,0,0.07,0.02c0-0.06,0.1-0.22-0.11-0.2c0.13,0,0.01-0.13-0.05-0.19
			c0.24,0.03-0.03,0.01-0.02-0.73c0,0.08,0,0.15-0.01,0.14c0.1,0.08,0.26-0.35-0.03-0.43c0.01-0.01,0,0.02,0.03,0.04l-0.01,0
			c0,0.02,0.01,0.05,0.01,0.08c-0.05-0.02-0.09-0.05-0.04-0.09c-0.06-0.02-0.13-0.03-0.16-0.04c-0.06-0.08,0.22-0.43,0.07-1.23
			c-0.02,0.02-0.02,0.02-0.04,0.03c0.01-0.15,0.2-0.22-0.08-0.88l0.1-0.02c-0.2,0.18,0.12-0.42-0.28-0.88
			c0,0.01,0.01,0.01,0.01,0.01c-0.1,0.01-0.23,0.04-0.44,0.09l0.1,0.07l-0.08,0.03l-0.01-0.06c-0.07,0.04,0,0.06,0.04,0.08
			c-0.04,0.01-0.07,0.02-0.08,0.04l0.16,0.07c-0.06,0.01-0.13-0.03-0.16,0.03c0.07,0.03,0.14-0.04,0.19,0.01
			c-0.07,0.04-0.13,0.03-0.07,0.07c-0.04,0.01-0.07,0-0.09-0.01c0.05,0.03,0.03,0.1,0.11,0.11c-0.09,0.04,0.03,0.13-0.12,0.13
			c0.04,0.01,0.12,0.06,0.13,0.09c-0.19-0.05,0,0.13-0.2,0.13c0.17,0.04,0.08,0.17,0.18,0.24c-0.01,0-0.04-0.01-0.03-0.02
			c-0.01,0.05-0.06,0.06-0.09,0.1c0.14,0,0.17,0.07,0.26,0.1c-0.13,0.07-0.16-0.03-0.29,0c0.11-0.01,0.12,0.09,0.21,0.07
			c-0.08,0.05-0.04,0.04-0.15,0.07c0.17,0,0.03,0.04,0.14,0.07c-0.08,0.07-0.16,0.03-0.1,0.09c-0.27,0.04,0.07-0.01,0.14,0.46
			c0,0.02,0.19-0.08,0.18,0.05c0-0.02-0.03-0.02-0.06-0.03l0.04,0.03c-0.17-0.13-0.2,0.13-0.13,0.02c-0.42-0.1-0.09,0.45,0.05,1.09
			c-0.13,0.22-0.39,0.17,0.02,2.13l-0.13,0.03c0.2,0.13-0.16-0.11,0.07,0.33c-0.04,0-0.06-0.03-0.09-0.05
			c0.17,0.07-0.15,0.05,0.07,0.1c-0.07,0.04-0.07,0.13-0.04,0.19l-0.07-0.01c-0.02,0.12,0.03,0.25,0.03,0.38
			c0.04-0.05,0.16-0.03,0.18,0.01l-0.11,0.01c0.07,0.02,0.24,0.04-0.04,0.01l0.04,0.06c-0.03,0-0.05-0.01-0.09-0.02
			c0.07,0.03-0.12,0.1,0,0.14c-0.27-0.42,0.22,1.39-0.23,2.46c0.03,0.01,0.15,0,0.01,0.06c0.41-0.23-0.24,0.52-0.15,1.57
			c-0.02-0.02-0.06-0.02-0.05-0.04c0.01,0.04-0.03,0.18-0.03,0.75c-0.15-0.04-0.15,0.1-0.09,0.16c-0.04-0.03-0.06,0.03-0.12-0.01
			c-0.01,0.05,0,0.11,0.09,0.12c-0.21,0.05,0.1,0.23-0.15,0.22c0.02,0.26,0.11,0.15-0.16,1c-0.01,0-0.03-0.02-0.03-0.04
			c0,0.02-0.04,0.05,0.03,0.07l-0.01-0.03c0.11,0,0.18,0.16,0.23,0.18c-0.18-0.11-0.09,0.1-0.31-0.05c0.01,0.02,0.03,0.05,0.08,0.06
			c-0.17,0.13-0.03,0.03-0.28,0.54c0.03-0.03-0.06,0.1,0.08,0.07c-0.04-0.04,0.04,0.23-0.05,0.11l-0.02,0.11l0.07-0.04
			c0.04,0.13,0.18,0.09-0.16,0.21c0.09,0.07-0.02,0.14,0.07,0.22c-0.02-0.02-0.08-0.05-0.1-0.05l0.07,0.06
			c-0.08,0.02-0.01,0.05-0.18,0.22l0-0.05l-0.06,0.01l0.06,0.04c-0.12-0.07,0.05,0.31-0.2,0.52c-0.01-0.02-0.08-0.06-0.07-0.05
			c-0.07,0.06-0.04,0.18-0.05,0.32c0.01-0.06-0.25,0.07-0.08,0.18c-0.16,0.06-0.34-0.06-0.21,0.59c-0.02-0.08-0.11-0.07-0.16-0.07
			c0.02,0.01,0.04,0.03,0.06,0.04c-0.2-0.07,0.09,0.12-0.03,0.1c0,0.03,0,0.21-0.08,0.02c-0.05,0.14,0-0.01-0.23,0.15
			c0.25,0.16,0.02,0.01,0.08,0.29c-0.09-0.14,0-0.05-0.25-0.14c0.05,0.08-0.09,0.02-0.02,0.14c-0.03-0.02-0.03,0.03-0.09-0.02
			c0.31,0.21-0.22,0.02-0.02,0.63c-0.07,0.01-0.09-0.21-0.17,0.01c-0.14-0.15,0,0.2-0.16-0.06c0,0.05,0.02,0.13,0.01,0.19
			c-0.05-0.03-0.05-0.08-0.07-0.12c0.02,0.01-0.07,0.11,0.06,0.21c-0.15-0.21,0.13,0.19,0.13-0.21l0.1,0.11
			c-0.14-0.12,0.12-0.16,0.19-0.07c0.12-0.08,0.19-0.14-0.02-0.35l0.25,0.11l-0.08-0.07c0.01-0.01,0.03-0.02,0.06,0.01
			c-0.03-0.08-0.04-0.02-0.08-0.08c0.12,0.01,0.06-0.16,0.2-0.07c-0.05-0.06-0.08,0.01-0.09-0.07c0.16,0.15,0.01-0.19,0.21,0.01
			c-0.06-0.2,0.23-0.04,0.11-0.33c0.04,0.05-0.01-0.04,0.23-0.11c-0.1-0.08-0.04-0.13-0.04-0.19c0.06,0.04,0.09,0.07,0.1,0.11
			c0.01-0.04,0.03-0.04-0.01-0.14l0.08,0.06c0.03-0.06-0.02-0.17,0.08-0.22c-0.08-0.05-0.25-0.07-0.26-0.18
			c0.04,0.02,0.12,0.06,0.12,0.09c-0.02-0.02,0.12-0.08,0.11,0.02c0.03-0.03,0.09-0.04,0.14-0.03c-0.03-0.02-0.15-0.15,0.06-0.07
			c-0.03-0.04,0.03-0.05-0.06-0.1c0.01-0.02,0.05,0,0.08,0.01c-0.03-0.05,0,0-0.06-0.17l0,0c-0.13-0.14,0.12-0.08,0.37-0.63
			c0.03,0.07,0.06,0.01,0.02,0.07c0.06-0.05,0.11-0.04,0.06-0.15c0,0.09,0.2-0.09,0.03-0.02c0.25-0.12,0.06-0.65,0.39-0.67
			l-0.04-0.02c0.05-0.01,0.08-0.1,0.14-0.05c-0.02-0.06-0.06-0.04-0.09-0.02c0.09-0.05,0.05-0.12,0.12-0.18
			c-0.09,0-0.16-0.37,0.13-0.15c-0.05-0.1,0.1-0.12,0.13-0.16l0.02,0.05c-0.01-0.03,0-0.05,0-0.07l-0.09-0.01
			c0.02-0.02,0.03-0.04,0.06-0.02c-0.04-0.04-0.14-0.03-0.19-0.1c0.05-0.02-0.01-0.11,0.1-0.08c-0.08,0.11,0.39,0.16,0.07-0.1
			c0.04,0.03,0.08,0.02,0.1,0.01c-0.03,0-0.11-0.05-0.12-0.18c-0.05,0.02-0.06,0.05-0.08-0.08c0.05,0.01,0.02,0.05,0.04,0.04
			c-0.09-0.08,0.14-0.07,0.09-0.16c0.05-0.02,0.11,0.03,0.15,0.01c-0.12-0.09,0.2-0.07-0.06-0.22l0.02,0.15l-0.09-0.12
			c-0.01,0.04,0.03,0.1,0.01,0.12c-0.05-0.06-0.03-0.05-0.11-0.05c0.02-0.32,0.33,0.16,0.29-0.4c-0.06,0.01-0.1,0.05-0.06-0.1
			c0.07,0.02,0.1,0.01,0.14,0c-0.05-0.01,0.03-0.09-0.1-0.13c0.11-0.06,0.19-0.01-0.01-0.28c0.07,0.05,0.12,0.02,0.16,0.09
			c-0.03-0.11,0.01,0.03-0.17-0.31c0.17-0.04,0.18-0.06,0.25-0.54c0.02,0.01,0.03,0.01,0.02,0.02c-0.2-0.19,0.33-0.14,0.02-0.77
			c0.08,0.04,0.04,0.04,0.13,0.05c-0.27,0.11,0.32-0.24-0.02-0.76c0.1,0.06,0.14,0.15,0.19,0.21c0.01-0.04,0.01-0.14-0.05-0.17
			c-0.01,0.06,0,0.07-0.11-0.16c0.26,0.05,0.1-0.2,0.31-0.23c-0.08-0.15,0.08-0.27,0.08-0.44c-0.09,0.03-0.31-0.17,0-0.09
			c-0.07,0.03-0.1-0.04-0.15-0.05c0.05-0.05,0.15-0.1,0.33-0.12c-0.08,0-0.11-0.04-0.07-0.09c-0.06,0.11-0.08-0.21,0.05-0.11
			c-0.03-0.06,0.02-0.22-0.14-0.3c0.03-0.01,0.08-0.02,0.1,0.01c0.08-0.88-0.07-0.52,0.13-1.99c0.01,0.01-0.19,0.01-0.1-0.13l0,0.01
			c0.14-0.02,0.1-0.1,0.11-0.16l-0.18-0.01c0.27-0.15-0.1-0.39,0.15-0.47c-0.05-0.04-0.05-0.08-0.09-0.12
			c0.11-0.03,0.13,0.03,0.11-0.27l0.07,0.02C1371.57,259.69,1371.56,259.89,1371.61,259.34z M1371.61,258.02
			c0.01,0.03-0.03,0.04-0.05-0.01C1371.58,258.01,1371.6,258.01,1371.61,258.02z M1370.92,258.16c0,0.01,0,0.02,0.01,0.03
			C1370.92,258.19,1370.93,258.2,1370.92,258.16z M1370.94,258.34c0.01-0.02-0.02-0.09,0.07-0.05
			C1370.98,258.28,1370.97,258.32,1370.94,258.34z"
              />
              <path
                className="st10"
                d="M1366.11,262.63c0.01,0.01-0.19-0.03-0.06-0.16l-0.01,0.01c0.14,0.01,0.13-0.08,0.15-0.14l-0.18-0.06
			c0.3-0.09,0.01-0.44,0.26-0.46c-0.04-0.05-0.02-0.1-0.06-0.15c0.13-0.01,0.11,0.06,0.18-0.26l0.06,0.04
			c-0.15-0.25-0.18-0.04-0.02-0.6c0.02,0.01,0.06,0.02,0.06,0.04c0.02-0.06,0.14-0.22-0.06-0.24c0.13,0.03,0.04-0.13,0-0.21
			c0.23,0.08-0.03-0.01,0.14-0.79c-0.02,0.09-0.03,0.16-0.04,0.15c0.1,0.11,0.32-0.31,0.06-0.47c0.01-0.01,0,0.02,0.02,0.05l-0.01,0
			c0,0.02,0,0.06-0.01,0.09c-0.05-0.04-0.08-0.07-0.02-0.1c-0.06-0.03-0.12-0.06-0.15-0.07c-0.05-0.1,0.33-0.42,0.28-1.3
			c-0.02,0.02-0.03,0.02-0.05,0.02c0.02-0.17,0.23-0.2,0.03-0.96l0.11-0.01c-0.22,0.17,0.18-0.44-0.21-0.98c0,0.01,0,0.01,0.01,0.01
			c-0.1,0.01-0.23,0.03-0.45,0.08l0.1,0.08l-0.08,0.03l0-0.07c-0.08,0.04-0.01,0.06,0.03,0.09c-0.04,0.01-0.07,0.02-0.09,0.03
			l0.15,0.08c-0.06,0.01-0.13-0.04-0.16,0.02c0.07,0.04,0.14-0.03,0.19,0.03c-0.07,0.04-0.14,0.03-0.07,0.07
			c-0.04,0.01-0.07,0-0.09-0.02c0.05,0.04,0.02,0.11,0.1,0.13c-0.09,0.04,0.02,0.14-0.13,0.13c0.04,0.01,0.12,0.07,0.13,0.11
			c-0.18-0.07-0.01,0.14-0.21,0.11c0.16,0.06,0.07,0.18,0.16,0.28c-0.01-0.01-0.04-0.02-0.03-0.02c-0.02,0.05-0.06,0.06-0.1,0.09
			c0.14,0.01,0.16,0.09,0.24,0.14c-0.14,0.05-0.16-0.05-0.29-0.04c0.11,0,0.1,0.11,0.2,0.1c-0.09,0.04-0.04,0.04-0.16,0.05
			c0.17,0.02,0.03,0.05,0.13,0.09c-0.09,0.06-0.16,0.01-0.12,0.08c-0.27,0.01,0.07,0,0.08,0.5c0,0.02,0.2-0.06,0.17,0.08
			c0-0.02-0.03-0.03-0.06-0.04l0.03,0.04c-0.16-0.17-0.21,0.11-0.13,0.01c-0.42-0.22-0.16,0.47-0.15,1.15
			c0.03-0.52-0.22,0.02-0.49,2.23l-0.13,0c0.17,0.16-0.15-0.09-0.02,0.36c-0.04-0.02-0.05-0.05-0.07-0.08
			c0.15,0.12-0.16,0.01,0.04,0.12c-0.07,0.02-0.1,0.11-0.1,0.19l-0.07-0.03c-0.05,0.12-0.04,0.26-0.08,0.4
			c0.05-0.04,0.17,0.01,0.17,0.05l-0.11-0.02c0.06,0.04,0.23,0.11-0.04,0.01l0.02,0.07c-0.03-0.01-0.05-0.03-0.09-0.04
			c0.06,0.05-0.14,0.07-0.03,0.14c-0.07,0.06-0.09,0.52-0.03,0.77c-0.28-0.08-0.27,0.84-0.89,1.73c0.03,0.01,0.15,0.04-0.01,0.06
			c0.47-0.14-0.39,0.49-0.57,1.62c-0.02-0.02-0.05-0.04-0.04-0.06c-0.01,0.05-0.07,0.18-0.21,0.79c-0.14-0.07-0.18,0.08-0.13,0.15
			c-0.04-0.04-0.06,0.02-0.12-0.03c-0.02,0.05-0.02,0.12,0.06,0.14c-0.22,0.02,0.04,0.26-0.2,0.21c-0.04,0.28,0.08,0.17-0.36,1.05
			c-0.01-0.01-0.02-0.03-0.03-0.04c-0.01,0.03-0.05,0.04,0.02,0.07l-0.01-0.03c0.11,0.01,0.15,0.19,0.19,0.22
			c-0.16-0.13-0.11,0.09-0.31-0.09c0,0.02,0.02,0.06,0.07,0.07c-0.2,0.13-0.04,0.03-0.37,0.56c0.03-0.03-0.08,0.1,0.07,0.09
			c-0.03,0.06,0.03,0.15-0.04,0.18l-0.03-0.07l-0.03,0.12l0.08-0.04c0.02,0.15,0.21,0.07-0.19,0.22c0.08,0.08-0.04,0.15,0.04,0.24
			c-0.02-0.02-0.08-0.06-0.09-0.06l0.07,0.06c-0.09,0.02-0.02,0.06-0.2,0.23l0-0.05l-0.07,0.01l0.05,0.05
			c-0.12-0.08,0.02,0.32-0.24,0.56c0-0.02-0.08-0.07-0.06-0.05c-0.07,0.07-0.05,0.19-0.07,0.34c0.02-0.09-0.27,0.05-0.09,0.2
			c-0.16,0.07-0.36-0.04-0.22,0.64c-0.02-0.08-0.11-0.06-0.17-0.06c0.02,0.01,0.04,0.03,0.07,0.04c-0.2-0.06,0.09,0.11-0.03,0.1
			c0,0.03,0,0.22-0.08,0.03c-0.05,0.16,0-0.01-0.23,0.18c0.26,0.14,0.02,0.01,0.1,0.3c-0.09-0.13,0-0.06-0.26-0.12
			c0.05,0.08-0.09,0.03-0.02,0.15c-0.02-0.01-0.03,0-0.06,0c0.04,0.04,0.02,0.17-0.18,0.7c-0.16-0.13,0.02,0.21-0.16-0.03
			c0,0.06,0.03,0.13,0.04,0.19c-0.06-0.02-0.06-0.07-0.09-0.11c0.02,0-0.05,0.13,0.09,0.2c-0.18-0.18,0.16,0.17,0.1-0.25l0.11,0.1
			c-0.13-0.17,0.03-0.06,0.13-0.17c0.02,0.02,0.09,0.04,0.06,0.06c0.11-0.09,0.18-0.18-0.05-0.36l0.26,0.07l-0.09-0.06
			c0.01-0.01,0.03-0.03,0.06,0c-0.04-0.08-0.04-0.02-0.09-0.07c0.12-0.01,0.05-0.18,0.2-0.11c-0.06-0.06-0.08,0.02-0.1-0.06
			c0.17,0.13-0.01-0.2,0.22-0.02c-0.14-0.18,0.23-0.01,0.09-0.35c0.04,0.05-0.01-0.04,0.23-0.14c-0.1-0.08-0.04-0.13-0.04-0.2
			c0.07,0.04,0.09,0.06,0.1,0.11c0.01-0.04,0.03-0.04-0.01-0.14l0.08,0.06c0.03-0.07-0.03-0.18,0.07-0.23
			c-0.08-0.05-0.25-0.06-0.26-0.17c0.04,0.02,0.12,0.06,0.12,0.09c-0.02-0.01,0.12-0.09,0.11,0.01c0.03-0.03,0.09-0.05,0.14-0.04
			c-0.03-0.02-0.15-0.15,0.06-0.08c-0.03-0.04,0.03-0.06-0.06-0.11c0.01-0.02,0.05,0,0.08,0.01c-0.03-0.05,0,0-0.06-0.18l0,0
			c-0.13-0.14,0.13-0.09,0.42-0.66c0.03,0.08,0.06,0.01,0.01,0.07c0.06-0.05,0.12-0.04,0.07-0.15c0.07,0.07,0.12,0,0.12-0.04
			l-0.08,0.02c0.26-0.11,0.13-0.68,0.46-0.68l-0.03-0.03c0.05-0.01,0.09-0.1,0.14-0.04c-0.01-0.06-0.05-0.05-0.09-0.03
			c0.09-0.05,0.07-0.12,0.14-0.18c-0.08-0.01-0.11-0.41,0.16-0.14c-0.03-0.11,0.12-0.11,0.15-0.15l0.01,0.05
			c-0.01-0.03,0-0.05,0.01-0.07l-0.09-0.02c0.02-0.02,0.03-0.04,0.07-0.02c-0.04-0.05-0.13-0.04-0.17-0.13
			c0.05-0.01,0.01-0.11,0.11-0.07c-0.1,0.11,0.36,0.21,0.09-0.1c0.04,0.03,0.08,0.03,0.1,0.02c-0.03-0.01-0.11-0.06-0.09-0.2
			c-0.05,0.01-0.07,0.04-0.06-0.09c0.05,0.02,0.01,0.05,0.04,0.05c-0.07-0.1,0.16-0.06,0.12-0.15c0.01,0.01,0.36,0.04,0.12-0.21
			l-0.01,0.15l-0.07-0.14c-0.02,0.04,0.01,0.1-0.02,0.12c-0.04-0.07-0.02-0.06-0.1-0.07c0.08-0.32,0.3,0.21,0.36-0.37
			c-0.06,0.01-0.1,0.04-0.04-0.11c0.06,0.03,0.09,0.02,0.14,0.02c-0.05-0.02,0.05-0.09-0.07-0.16c0.13-0.05,0.19,0.03,0.05-0.29
			c0.05,0.06,0.12,0.04,0.14,0.12c-0.01-0.12,0.01,0.03-0.1-0.36c0.18-0.01,0.19-0.03,0.37-0.51c0.02,0.01,0.03,0.01,0.02,0.02
			c-0.01-0.35,0.19,0.19,0.25-0.49c0,0,0.02,0.01,0.03,0.01c-0.08-0.1-0.07-0.22-0.06-0.32c0.15,0.21,0.32-0.11,0.3-0.72
			c0.08,0.08,0.09,0.18,0.12,0.26c0.02-0.04,0.05-0.14-0.01-0.19c-0.02,0.06-0.02,0.07-0.06-0.19c0.24,0.12,0.15-0.18,0.37-0.16
			c-0.04-0.18,0.15-0.27,0.2-0.44c-0.1,0.01-0.25-0.26,0.03-0.09c-0.08,0.02-0.08-0.07-0.13-0.09c0.07-0.04,0.18-0.06,0.35-0.04
			c-0.08-0.02-0.09-0.07-0.05-0.11c-0.09,0.1-0.01-0.23,0.08-0.1c-0.01-0.08,0.09-0.23-0.05-0.35c0.03,0,0.09,0,0.09,0.04
			C1365.61,264.43,1365.55,263.9,1366.11,262.63z M1366.75,259.46c0,0.02-0.04,0.06-0.05-0.02
			C1366.72,259.45,1366.74,259.45,1366.75,259.46z M1366.05,259.49c0,0.01,0,0.02,0,0.04
			C1366.04,259.51,1366.05,259.52,1366.05,259.49z M1366.11,259.63c-0.02-0.01-0.05,0.02-0.08,0.04
			C1366.05,259.65,1366.03,259.57,1366.11,259.63z"
              />
              <path
                className="st10"
                d="M1359.91,261.15c-0.09-0.03-0.06-0.07-0.16-0.05c0.01-0.01,0,0.02,0.01,0.06l-0.01-0.01
			c-0.01,0.03-0.01,0.06-0.02,0.1c-0.04-0.05-0.07-0.09,0-0.12c-0.05-0.04-0.11-0.08-0.13-0.1c-0.01-0.12,0.38-0.4,0.55-1.39
			c-0.02,0.01-0.03,0.02-0.05,0.02c0.07-0.2,0.26-0.13,0.26-1.04l0.11,0.01c-0.27-0.19,0.34-0.44,0.11-1.09c0,0,0,0,0.01,0
			c-0.11-0.1-0.01,0.01-0.51,0.07l0.02-0.08c-0.08,0.02-0.03,0.07,0.01,0.11c-0.04,0-0.07,0-0.09,0.02l0.13,0.13
			c-0.06,0-0.28-0.04,0.01,0.06c-0.08,0.02-0.14,0-0.09,0.06c-0.04,0-0.07-0.02-0.08-0.05c0.04,0.06-0.01,0.12,0.07,0.17
			c-0.1,0.02-0.02,0.16-0.17,0.11c0.03,0.02,0.1,0.11,0.09,0.15c-0.16-0.12-0.05,0.15-0.24,0.08c0.14,0.1,0.02,0.22,0.08,0.34
			c-0.01-0.01-0.03-0.03-0.02-0.03c-0.03,0.05-0.08,0.05-0.12,0.08c0.13,0.04,0.14,0.14,0.2,0.21c-0.15,0.03-0.14-0.08-0.27-0.1
			c0.11,0.03,0.07,0.15,0.17,0.15c-0.09,0.03-0.05,0.03-0.17,0.03c0.16,0.06,0.01,0.06,0.1,0.13c-0.09,0-0.07,0.16-0.23,0.01
			c0.17,0.2-0.03,0.04,0.09,0.64c-0.01,0-0.02,0-0.03,0c0,0.02,0.22-0.03,0.15,0.12c0.01-0.02-0.02-0.03-0.05-0.05l0.02,0.05
			c-0.12-0.22-0.24,0.1-0.13-0.01c-0.39-0.35-0.26,0.55-0.38,1.27c0.09-0.49-0.22-0.02-0.83,2.46l-0.14,0
			c0.15,0.18-0.14-0.1-0.07,0.41c-0.04-0.02-0.04-0.05-0.06-0.09c0.14,0.14-0.17,0,0.03,0.14c-0.08,0.02-0.11,0.12-0.11,0.21
			l-0.06-0.04c-0.07,0.13-0.07,0.3-0.13,0.45c0.05-0.04,0.17,0.01,0.16,0.06l-0.11-0.02c0.06,0.04,0.22,0.13-0.04,0.01l0.01,0.08
			c-0.03-0.01-0.04-0.03-0.08-0.05c0.06,0.05-0.15,0.08-0.05,0.16c-0.09,0.07-0.14,0.56-0.1,0.86c-0.09-0.03-0.12,0.09-0.16,0.01
			c-0.04,0.14-0.05,0.17-0.04,0.36c0.07-0.02,0.14,0.04-0.02,0.03c0.05,0.23-0.41,0.33-0.29,0.55l-0.01-0.01
			c0.02,0.28-0.05,0.17-0.52,1.03c0.03,0.02,0.15,0.04-0.01,0.07c0.47-0.16-0.42,0.56-0.69,1.81c-0.02-0.02-0.05-0.04-0.04-0.06
			c-0.1,0.01-0.09,0.17-0.03,0.2c0.07-0.07-0.26,0.25-0.27,0.66c-0.14-0.08-0.19,0.08-0.15,0.16c-0.03-0.04-0.07,0.02-0.11-0.03
			c-0.03,0.06-0.04,0.12,0.04,0.15c-0.22,0.01,0.01,0.29-0.23,0.22c-0.09,0.3,0.07,0.2-0.56,1.08c-0.01-0.01-0.02-0.03-0.02-0.05
			c-0.01,0.03-0.06,0.04,0,0.08l0-0.03c0.11,0.02,0.11,0.22,0.15,0.26c-0.13-0.16-0.13,0.09-0.29-0.13c0,0.02,0.01,0.06,0.05,0.09
			c-0.03,0.01-0.06,0-0.08-0.05c-0.03,0.11,0.05,0.12-0.01,0.2c-0.03-0.02-0.03-0.05-0.05-0.08c0,0.12-0.05,0.02-0.41,0.54
			l0.07,0.02c-0.05,0.05-0.02,0.16-0.09,0.18l-0.01-0.08l-0.07,0.12l0.09-0.02c-0.02,0.16,0.19,0.12-0.27,0.19
			c0.06,0.1-0.09,0.14-0.04,0.25c-0.01-0.03-0.06-0.08-0.07-0.08l0.04,0.08c-0.1,0-0.04,0.06-0.28,0.18l0.02-0.04l-0.07-0.01
			l0.03,0.06c-0.09-0.11-0.09,0.32-0.45,0.51c0-0.02-0.05-0.09-0.04-0.07c-0.1,0.05-0.13,0.18-0.2,0.33
			c0.06-0.08-0.27-0.04-0.17,0.17c-0.19,0.03-0.33-0.16-0.49,0.58c0.02-0.09-0.08-0.1-0.14-0.11c0.02,0.02,0.03,0.04,0.05,0.06
			c-0.25-0.2,0.05,0.23-0.48,0.21c0.19,0.23,0.02,0.01-0.04,0.33c-0.03-0.16,0.01-0.06-0.2-0.2c0.01,0.1-0.11,0-0.08,0.14
			c-0.02-0.02-0.03-0.01-0.05-0.02c0.03,0.05-0.07,0.18-0.5,0.63c-0.09-0.19-0.08,0.22-0.15-0.08c-0.03,0.06-0.03,0.14-0.06,0.2
			c-0.05-0.04-0.03-0.09-0.04-0.14c0.02,0.01-0.11,0.11-0.01,0.23c-0.08-0.25,0.08,0.23,0.22-0.21l0.06,0.14l-0.01-0.15
			c0.02,0.02,0.17,0.27,0.21,0.03c0.01,0.03,0.07,0.07,0.03,0.08c0.14-0.05,0.27-0.11,0.12-0.38l0.22,0.16l-0.06-0.09
			c0.01-0.01,0.04-0.02,0.06,0.02c0-0.09-0.04-0.03-0.05-0.1c0.12,0.03,0.13-0.16,0.25-0.04c-0.03-0.08-0.09-0.01-0.07-0.1
			c0.11,0.19,0.08-0.2,0.22,0.05c0.02-0.23,0.26,0.01,0.26-0.32c0.02,0.06,0-0.04,0.29-0.06c-0.07-0.11,0.02-0.15,0.05-0.21
			c0.05,0.06,0.06,0.09,0.05,0.14c0-0.02,0.05,0,0.05-0.15l0.05,0.08c0.06-0.06,0.05-0.19,0.18-0.21c-0.05-0.07-0.23-0.14-0.18-0.25
			c0.03,0.03,0.09,0.09,0.08,0.13c-0.01-0.01,0.16-0.06,0.1,0.05c0.04-0.02,0.11-0.02,0.16,0c-0.02-0.04-0.09-0.2,0.09-0.06
			c-0.02-0.05,0.06-0.05-0.01-0.13c0.02-0.02,0.05,0.02,0.08,0.03c-0.01-0.07,0,0,0.02-0.2l0,0c-0.02-0.18,0.36-0.12,0.68-0.57
			c0,0.09,0.05,0.02-0.02,0.08c0.08-0.04,0.13-0.01,0.13-0.14c0.04,0.08,0.12,0.04,0.13-0.01l-0.09,0c0.3-0.03,0.36-0.68,0.71-0.61
			l-0.02-0.04c0.06,0,0.13-0.09,0.16-0.02c0.01-0.07-0.03-0.06-0.08-0.05c0.11-0.04,0.11-0.12,0.2-0.17c-0.08-0.01,0-0.46,0.2-0.13
			c0-0.12,0.15-0.1,0.2-0.14l-0.01,0.06c0-0.04,0.02-0.05,0.04-0.07l-0.08-0.03c0.03-0.01,0.05-0.03,0.07-0.01
			c-0.02-0.06-0.12-0.07-0.14-0.16c0.06,0,0.04-0.12,0.13-0.07c-0.13,0.11,0.31,0.28,0.12-0.09c0.04,0.05,0.11,0.05,0.11,0.02
			c0,0.04-0.13-0.03-0.06-0.23c-0.06,0.01-0.08,0.04-0.04-0.11c0.04,0.02,0,0.06,0.03,0.06c-0.11-0.21,0.61-0.01,0.33-0.37
			l-0.04,0.17l-0.04-0.16c-0.03,0.04-0.02,0.12-0.04,0.13c-0.03-0.08-0.01-0.06-0.08-0.08c0.14-0.33,0.28,0.25,0.45-0.38
			c-0.07,0-0.11,0.04-0.02-0.12c0.06,0.04,0.09,0.03,0.14,0.03c-0.05-0.02,0.06-0.09-0.05-0.18c0.15-0.05,0.19,0.03,0.09-0.32
			c0.05,0.07,0.12,0.05,0.12,0.14c0.01-0.13,0,0.03-0.06-0.4c0.18-0.01,0.21-0.04,0.44-0.56c0.02,0.01,0.03,0.02,0.02,0.03
			c-0.07-0.29,0.29,0.04,0.3-0.55c0,0,0.02,0.01,0.03,0.02c-0.07-0.11-0.05-0.25-0.04-0.35c0.17,0.1,0.1,0.09,0.2-0.07
			c0.25,0.46-0.17-0.61,0.2-0.43c-0.01-0.06-0.05-0.21-0.04-0.3c0.07,0.09,0.08,0.2,0.11,0.28c0.02-0.04,0.06-0.16,0-0.21
			c-0.02,0.06-0.03,0.08-0.06-0.21c0.24,0.12,0.16-0.21,0.38-0.19c-0.03-0.2,0.17-0.3,0.23-0.49c-0.07,0.05-0.27-0.27,0.04-0.1
			c-0.08,0.02-0.08-0.07-0.13-0.09c0.07-0.04,0.18-0.07,0.36-0.05c-0.08-0.02-0.09-0.07-0.04-0.12l-0.12,0.04
			c-0.05-0.17,0.21-0.05,0.16-0.22l0.05,0.07c-0.01-0.08,0.1-0.25-0.03-0.38c0.03,0,0.09,0,0.09,0.04c0.16-0.47,0.36-0.9,0.88-2.24
			c-0.11-0.17,0.05-0.11,0.22-1.04c0.14,0,0.1,0.07,0.21-0.27l0.06,0.05c-0.12-0.29-0.17-0.07,0.07-0.67
			c0.02,0.01,0.06,0.03,0.06,0.05c0.03-0.06,0.18-0.22-0.02-0.26c0.13,0.04,0.06-0.14,0.03-0.23c0.22,0.12-0.03-0.03,0.27-0.85
			c-0.03,0.1-0.06,0.17-0.07,0.16C1359.87,261.63,1359.87,261.85,1359.91,261.15z M1359.87,261.23c0,0.02-0.06,0.06-0.04-0.02
			C1359.84,261.21,1359.86,261.22,1359.87,261.23z M1359.17,261.17c0,0.01,0,0.02-0.01,0.04
			C1359.16,261.19,1359.16,261.21,1359.17,261.17z M1359.11,261.37c0.01,0.01,0-0.12,0.08-0.03
			C1359.18,261.32,1359.15,261.35,1359.11,261.37z"
              />
              <path
                className="st10"
                d="M1389.22,230.16c-0.01-0.03-0.03,0.01-0.06-0.07c0.35-0.04-0.34,0.39-0.48,0.13
			c-0.04,0.03-0.08,0.08-0.13,0.1c0.01-0.06,0.05-0.08,0.07-0.12c0.01,0.03-0.1-0.02-0.14,0.15c0.13-0.21-0.12,0.2,0.21,0.02
			l-0.05,0.14c0.02-0.17,0.19,0.01,0.16,0.13c0.13,0.05,0.25,0.05,0.23-0.24c0.03,0.08,0.07,0.16,0.11,0.24l-0.01-0.11
			c0.01,0,0.04,0,0.05,0.05c0.03-0.08-0.02-0.04-0.01-0.12c0.09,0.08,0.13-0.09,0.21,0.06c-0.01-0.08-0.08-0.04-0.04-0.11
			c0.06,0.21,0.1-0.16,0.18,0.12c0.03-0.21,0.23,0.08,0.24-0.25c0.02,0.06,0-0.04,0.26-0.04c-0.08-0.1-0.01-0.14,0.01-0.2
			c0.06,0.05,0.07,0.08,0.07,0.13c0.02-0.04,0.04-0.03,0.02-0.14l0.06,0.07c0.04-0.06,0-0.18,0.11-0.22
			c-0.07-0.05-0.24-0.08-0.25-0.19c0.04,0.02,0.11,0.06,0.12,0.1c0-0.01,0.12-0.08,0.11,0.02c0.03-0.02,0.09-0.04,0.14-0.03
			c-0.12-0.05,0.19-0.05,0-0.35l0,0c-0.17-0.11,0.12-0.1,0.19-0.69c0.06,0.06,0.05-0.01,0.04,0.06c0.03-0.06,0.09-0.08,0-0.16
			c0.03,0.09,0.15-0.17,0.02-0.03c0.2-0.23-0.28-0.62,0.12-0.7c-0.04-0.03-0.07-0.03-0.09-0.02c0.06-0.01-0.01-0.05,0.03-0.06
			c-0.09-0.06-0.01,0-0.2-0.18c0.07,0.03,0.17,0.14,0.1,0.05c0.05,0.02,0.13,0.1,0.16,0.14c-0.11-0.1,0.05,0.01,0.11,0.08
			c-0.05-0.06,0.01,0.01-0.12-0.13c0.02,0.01,0.02,0.01,0.06,0.05c-0.07-0.08-0.16-0.17-0.26-0.29c0.05,0.05-0.07-0.12,0.07,0.05
			c-0.12-0.12,0.27,0.34,0.19,0.23c0,0,0,0,0,0c-0.01-0.02-0.11-0.13-0.16-0.2c0.07,0.08-0.01-0.03,0.01-0.01
			c-0.01-0.02-0.08-0.08-0.06-0.1c0.04,0.06,0.01,0.03,0.03,0.06c-0.09-0.09,0.09,0.01,0.2,0.24c0-0.06,0.05,0.04,0.03-0.06
			c0.03,0.03,0.1,0.03,0.29-0.07c-0.05-0.04-0.1-0.05,0.06-0.1c0.01,0.07,0.05,0.08,0.08,0.12c-0.02-0.05,0.1-0.03,0.06-0.17
			c0.13,0.05,0.14,0.15,0.21-0.2c0.01,0.08,0.08,0.1,0.04,0.18c0.06-0.1,0,0.03,0.09-0.36c0.14,0.07,0.23,0.05,0.48-0.35
			c0.02,0.01,0.03,0.02,0.02,0.03c-0.09-0.26,0.35,0.02,0.24-0.51c0,0,0.02,0.01,0.03,0.01c-0.1-0.09-0.12-0.2-0.15-0.28
			c0.19,0.04,0.13,0.05,0.16-0.11c0.4,0.24-0.41-0.41-0.01-0.4c-0.04-0.04-0.15-0.12-0.19-0.19c0.11,0.02,0.17,0.1,0.24,0.14
			c-0.01-0.04-0.03-0.14-0.1-0.15c0.01,0.02,0.09-0.12-0.06-0.86c-0.02,0.08-0.33-0.01-0.08-0.05c-0.03,0-0.07-0.02-0.11,0
			c0.03-0.07,0.09-0.15,0.25-0.25c-0.07,0.04-0.11,0.01-0.1-0.04c-0.01,0.12-0.16-0.14-0.01-0.11c-0.06-0.04-0.07-0.2-0.25-0.18
			c0.02-0.02,0.07-0.05,0.09-0.03c0.04-0.24-0.37-0.06-0.42-1.69c0.02,0-0.18,0.04-0.11-0.09l0,0.01c0.13-0.04,0.09-0.1,0.09-0.15
			l-0.18,0.01c0.25-0.16-0.12-0.34,0.11-0.43c-0.05-0.03-0.05-0.07-0.1-0.1c0.1-0.03,0.13,0.03,0.11-0.24l0.07,0.02
			c-0.01-0.03,0.04-0.26-0.16-0.63c0.13,0.01,0.02-0.11-0.03-0.17c0.25,0.07-0.07-0.04,0.09-0.65c-0.02,0.07-0.03,0.13-0.04,0.13
			c0.06,0.08,0.33-0.25,0.07-0.39c0.01-0.01,0,0.02,0.02,0.04l-0.01,0c0,0.02-0.01,0.05-0.01,0.07c-0.04-0.03-0.08-0.07-0.02-0.09
			c-0.06-0.03-0.12-0.06-0.15-0.08c0.07-0.08,0.33-0.04,0.2-0.55c0.42-0.08,0.14,0.05,0.25-0.48c-0.02,0.01-0.03,0.01-0.05,0.01
			c0.06-0.12,0.26-0.1,0.31-0.75l0.1,0.04c-0.26,0.04,0.31-0.25,0.23-0.83c0,0.01,0,0.01,0,0.01c-0.09-0.05-0.21-0.11-0.4-0.2
			l0.04,0.12l-0.08-0.02l0.03-0.06c-0.08-0.01-0.04,0.05-0.02,0.09c-0.04-0.02-0.07-0.02-0.09-0.02l0.09,0.15
			c-0.06-0.03-0.09-0.1-0.15-0.07c0.04,0.07,0.14,0.05,0.15,0.12c-0.08-0.01-0.13-0.05-0.1,0.02c-0.04-0.02-0.06-0.04-0.06-0.06
			c0.02,0.06-0.04,0.1,0.03,0.16c-0.1-0.01-0.04,0.12-0.17,0.04c0.03,0.03,0.07,0.12,0.06,0.15c-0.13-0.14-0.07,0.11-0.24,0
			c0.12,0.12-0.01,0.19,0.03,0.3c-0.01-0.01-0.03-0.03-0.02-0.03c-0.03,0.04-0.08,0.03-0.12,0.04c0.12,0.07,0.12,0.14,0.18,0.21
			c-0.14,0-0.13-0.1-0.25-0.14c0.1,0.04,0.06,0.14,0.15,0.16c-0.09,0.01-0.05,0.02-0.17-0.01c0.15,0.08,0.01,0.05,0.09,0.13
			c-0.1,0.02-0.15-0.05-0.13,0.04c-0.14-0.18-0.14,0.29,0.09,0.59c0.01-0.02-0.02-0.03-0.05-0.05l0.02,0.04
			c-0.44-0.54-0.24-0.08-0.57,3.08l-0.13,0.04c0.21,0.12-0.17-0.09,0.09,0.33c-0.04,0-0.06-0.02-0.09-0.04
			c0.18,0.05-0.15,0.07,0.09,0.09c-0.06,0.05-0.05,0.13-0.01,0.19c-0.13,0.06-0.08-0.02,0.02,0.37c0.03-0.05,0.15-0.06,0.17-0.03
			l-0.11,0.04c0.07,0.01,0.25-0.01-0.03,0.02l0.05,0.05c-0.03,0.01-0.05,0-0.1,0c0.07,0.01-0.09,0.13,0.03,0.14
			c-0.38-0.35,0.64,1.23,0.71,2.29c0.02,0,0.15-0.06,0.02,0.04c0.24-0.23,0.07,0.9,0.12,0.76c0.13,0.17-0.03,0.3,0.03,0.44
			c-0.02-0.02-0.05-0.03-0.05-0.04c-0.09-0.01-0.04,0.08,0.02,0.12c-0.02-0.03-0.17-0.04-0.11,0.13c-0.06,0.01-0.03,0.11-0.1,0.1
			c0.06,0.1-0.1,0.03,0,0.14c-0.1-0.12-0.15-0.04-0.14,0.04c-0.02-0.05-0.05-0.02-0.08-0.09c-0.03,0.02-0.04,0.07,0.01,0.13
			c-0.15-0.13-0.06,0.21-0.2,0c-0.07,0.11-0.13,0.22-0.27,0.22c0.16-0.34,0.24,0.09-1.12-1.06c0.03,0.08-0.03,0.1,0.1,0.49
			c-0.02-0.03-0.05-0.08-0.07-0.12c0.01,0.04-0.04,0.02,0.08,0.16l-0.03-0.06c0.06,0.05,0.11,0.19,0.39,0.56
			c-0.29-0.37-0.13-0.13-0.47-0.57c0.02,0.02,0.06,0.08,0.12,0.16c-0.03-0.03-0.06-0.07-0.13-0.16c0.05,0.11,0.16,0.24,0.17,0.33
			c-0.04-0.05-0.07-0.09-0.12-0.16c0.1,0.16,0.19,0.3,0.17,0.36c-0.03-0.03-0.06-0.05-0.06-0.08c0.05,0.2,0.27,0.45,0.22,0.55
			c-0.18-0.15-0.05,0.02-0.19-0.04c0.06,0.15,0.13,0.06,0.21,0.35l-0.07-0.07l0.05,0.16l0.04-0.07c0.09,0.14,0.22,0.04-0.03,0.32
			c0.11,0.02,0.04,0.12,0.16,0.15c-0.03-0.01-0.1-0.01-0.11,0l0.09,0.02c-0.06,0.05,0.01,0.05-0.07,0.25
			c-0.02-0.01,0-0.07-0.08-0.01l0.07,0.01c-0.15-0.03,0.18,0.28,0,0.5c-0.01-0.02-0.1-0.04-0.08-0.03
			c-0.04,0.07,0.02,0.15,0.04,0.28c0-0.04-0.23,0.09-0.05,0.17c-0.16,0.07-0.29-0.09-0.2,0.52c0-0.07-0.1-0.09-0.14-0.1
			c0.02,0.01,0.03,0.04,0.05,0.06c-0.17-0.12,0.06,0.14-0.05,0.08c-0.01,0.03-0.05,0.18-0.08,0c-0.09,0.1,0.04,0-0.24,0.04
			c0.16,0.25,0.01,0.01-0.03,0.29c-0.01-0.16,0.02-0.03-0.15-0.25C1389.3,230.16,1389.22,230.03,1389.22,230.16z M1391.85,219.52
			c0.01,0.02-0.03,0.04-0.05-0.02C1391.82,219.51,1391.84,219.51,1391.85,219.52z M1391.15,219.49c0,0.01,0,0.02,0,0.03
			C1391.15,219.51,1391.15,219.52,1391.15,219.49z M1391.13,219.65c0.02-0.02,0-0.09,0.08-0.03
			C1391.18,219.61,1391.16,219.64,1391.13,219.65z M1390.9,227.7c-0.01,0.01-0.02,0.02-0.02,0.04c0-0.12-0.03-0.05-0.09-0.21
			C1390.94,227.5,1390.89,227.62,1390.9,227.7z"
              />
              <polygon
                className="st10"
                points="1391.35,226.73 1391.35,226.73 1391.36,226.73 		"
              />
              <path
                className="st10"
                d="M1386.21,231.36l-0.02-0.12c-0.01,0,0.07,0.18,0.08,0.18l0-0.04c0.01,0.03,0.03,0.1,0.02,0.08
			c0.05,0.09,0-0.03,0.04,0c0.01-0.05-0.03-0.1-0.06-0.23l0.11,0.24l-0.04-0.1c0,0,0.01,0.01,0.03,0.05
			c-0.02-0.07-0.02-0.04-0.04-0.11c0.05,0.09,0.02-0.06,0.08,0.1c-0.03-0.08-0.04-0.05-0.04-0.11c0.08,0.19,0-0.09,0.09,0.16
			c-0.05-0.15,0,0,0.02-0.05c0.05,0.08,0.02,0.13,0.01-0.07c0.01,0.04,0.01-0.01,0.11,0.1c-0.06-0.12-0.03-0.1-0.03-0.13
			c0.04,0.07,0.05,0.1,0.06,0.13c0.01-0.04,0-0.01-0.01-0.08l0.04,0.09c0.01-0.01-0.03-0.11,0.02-0.07
			c-0.04-0.08-0.14-0.21-0.15-0.27c0.04,0.06,0,0.04,0.2,0.3c-0.02-0.03-0.1-0.19,0.03,0c-0.02-0.04,0.01-0.01-0.04-0.09
			c0,0,0.03,0.04,0.05,0.06c-0.02-0.04,0.01,0-0.05-0.13h0c-0.03-0.1,0.08,0.02,0.07-0.03l0.02,0.03c-0.01-0.04,0.02-0.04-0.02-0.1
			c0,0,0.05,0.04,0.07,0.08c0.04-0.04,0.35,0.06,0.1-0.24c0.02,0,0.13,0.12,0.15,0.15c0,0-0.01,0-0.02-0.01
			c0.04,0.05,0.02,0.03,0.11,0.09c-0.02-0.04-0.04-0.05-0.06-0.06c0.06,0.03,0.03-0.02,0.07-0.01c-0.02-0.01-0.05-0.05-0.05-0.05
			c0.06,0.02-0.05-0.07-0.05-0.09c0.05,0.04,0.1,0.11,0.08,0.06c0.04,0.02,0.08,0.08,0.1,0.1c-0.07-0.06,0.06-0.02,0.1,0.04
			c-0.01-0.02-0.01-0.02,0-0.03l-0.06-0.05c0.01,0.01,0.02,0,0.04,0.03c-0.04-0.04-0.1-0.09-0.15-0.15c0.11,0.06,0.02,0.02,0.2,0.09
			c-0.01,0.01-0.08-0.07-0.11-0.1c0.05,0.02-0.02-0.04-0.01-0.04c-0.05-0.03-0.03-0.01-0.06-0.07c0.04,0.03,0.02,0.03,0.04,0.04
			c-0.08-0.08,0.1,0.05,0.06-0.01c0.04,0.02,0.09,0.07,0.12,0.09c-0.04-0.07,0.09,0.02-0.07-0.12l0.03,0.06
			c-0.1-0.11-0.22-0.13,0.1-0.1c-0.04-0.03,0.02-0.02-0.09-0.1c0.18,0.18,0.12-0.05-0.06-0.17c0.19,0.1,0.02-0.06,0.18,0.02
			c-0.07-0.14-0.05-0.03-0.03-0.26c0.08,0.04,0.04,0.03,0.12,0.07c-0.01-0.01-0.13-0.06-0.15-0.09c0.03,0.02,0.22,0.08,0.25,0.09
			c-0.01-0.03-0.13-0.09-0.11-0.11c-0.09-0.05-0.1-0.08,0.08,0c-0.03-0.02-0.11-0.08-0.13-0.11c0.09,0.05,0.13,0.09,0.18,0.12
			c-0.24-0.14,0.28,0.04,0.01-0.27c0.05,0.02,0.12,0.05,0.17,0.05c-0.13-0.04-0.35-0.11,0.17,0c-0.07-0.02-0.1-0.04-0.07-0.04
			c-0.01,0.02-0.08-0.08,0.04-0.02c-0.03-0.03,0.02-0.05-0.15-0.11c0.13,0.09,0.04-0.1,0.11-0.1c-0.11-0.07,0.18,0.04-0.16-0.13
			c0.1,0,0.46,0.06,0.08-0.13c0.17,0,0.08-0.07,0.18-0.1c-0.03,0-0.12-0.01-0.13-0.02c0.03-0.01-0.1-0.03,0.04-0.02l0,0
			c0.14,0,0.1-0.02,0.11-0.03l-0.18-0.01c0.22-0.01,0.27-0.25,0.04-0.38c0.13-0.01,0.01-0.03-0.05-0.04l0.1-0.01
			c-0.13,0-0.16,0.01-0.2-0.01c0.01-0.01,0.08-0.01,0.05-0.01c0.08-0.09-0.21,0.04,0.03-0.08c-0.07,0.03-0.08-0.05-0.01-0.07
			c-0.14,0.13,0.29-0.17-0.04-0.06c0.01,0,0,0,0.03,0l-0.01,0l0.01,0.02c-0.05,0-0.09,0.01-0.04-0.01
			c-0.06,0.01-0.13,0.02-0.16,0.03c0-0.01,0.31-0.16,0.01-0.15c0.05-0.03,0.12-0.03,0.09-0.04c0.25-0.08-0.04-0.08-0.11-0.09
			c0.02-0.01,0.12-0.23-0.11-0.17l0.09-0.05c-0.19,0.12-0.06-0.06-0.13-0.03c0.01-0.01,0.01-0.05,0.03-0.07l-0.09,0.03
			c0.15-0.12,0.38-0.34-0.46,0.26l0.09-0.04c0,0.01-0.18,0.1-0.04,0.03c-0.03,0.02-0.06,0.04-0.07,0.05l0.14-0.07
			c-0.05,0.03-0.11,0.06-0.14,0.09c0.06-0.03,0.12-0.08,0.16-0.1c-0.06,0.04-0.11,0.07-0.05,0.05c-0.04,0.02-0.06,0.04-0.08,0.04
			c0.05-0.02,0.03,0,0.1-0.04c-0.08,0.05,0.03,0.01-0.1,0.08c0.03-0.02,0.11-0.05,0.12-0.05c-0.17,0.08,0,0.02-0.17,0.12
			c0.15-0.07,0.08-0.01,0.17-0.04c-0.01,0.01-0.04,0.02-0.03,0.01c-0.01,0.01-0.05,0.04-0.08,0.05c0.12-0.06,0.16-0.06,0.24-0.09
			c-0.12,0.07-0.15,0.06-0.26,0.12c0.1-0.05,0.11-0.03,0.19-0.07c-0.07,0.04-0.03,0.02-0.14,0.07c0.16-0.07,0.03-0.01,0.13-0.04
			c-0.07,0.04-0.15,0.07-0.09,0.06c-0.22,0.1,0.05,0,0.29-0.01c-0.11,0.05-0.04,0.01-0.13,0.04c0.05,0,0.16,0-0.03,0.04l0.04-0.03
			c-0.08,0.08-0.17,0.16,0.06,0.19c-0.03,0.01-0.07,0.01-0.11,0.02c0.07,0.01,0,0.05-0.07,0.09l0.1-0.01
			c-0.21,0.04,0.16-0.02-0.05,0.02c0.02,0.07,0.23,0,0.06,0.17c0.14,0,0.14,0.1-0.02,0.09l0.11,0.01l-0.13,0
			c0.05,0.02,0.25-0.01-0.02,0.03l0.09,0.03c-0.04,0-0.06-0.01-0.09-0.01c0.16,0.02-0.14,0.01,0.07,0.02
			c-0.03,0.03-0.22,0.1,0.09,0.11l-0.11-0.01c0.07,0.01,0.04,0,0.13,0.02c-0.01,0.01-0.13-0.01-0.17-0.02l0.04,0.01
			c-0.03,0-0.05-0.01-0.09-0.01c0.07,0.01-0.12,0,0.01,0.02c-0.06,0-0.02,0.06,0.16,0.09c-0.03,0.02-0.21,0.03,0.03,0.06
			c-0.19-0.01,0.01,0.01-0.18,0.12l0.09,0.05c-0.02,0.01-0.06-0.03-0.12-0.03c0.14,0.07-0.03,0.03,0.06,0.08
			c-0.26-0.07,0,0.06-0.17,0.03c0.01,0,0.16,0.05,0.01,0.01c0.11,0.02-0.08,0.24-0.06,0.15c0.11,0.09-0.03,0.06,0.05,0.15
			c-0.06-0.04-0.12-0.08-0.01,0.02c-0.06-0.04-0.1-0.03-0.1-0.03c0.03,0.02-0.02,0.01,0.07,0.06c-0.05-0.01,0.02,0.04-0.03,0.02
			c0.09,0.06-0.06-0.01,0.07,0.08c-0.13-0.09-0.12-0.05-0.06,0c-0.04-0.03-0.05-0.02-0.1-0.06c0,0.01,0.02,0.03,0.08,0.08
			c-0.17-0.09,0.1,0.12-0.1-0.02c0.05,0.07,0.08,0.13,0.1,0.17c-0.12-0.07-0.07-0.09-0.1,0.05l-0.03-0.03
			c0,0.01-0.02-0.01,0.03,0.04l-0.01-0.02c0.08,0.06,0.15,0.16,0.19,0.19c-0.15-0.14-0.05-0.02-0.24-0.2
			c0.01,0.01,0.03,0.04,0.07,0.07c-0.07-0.01,0,0.06-0.13,0.03c0.02,0.02,0,0.03,0.07,0.08c-0.03-0.03,0.05,0.12-0.02,0.01
			c-0.02,0.04,0.22,0.22,0.05,0.12c0.02,0.05,0.04,0.09-0.09-0.08c0.07,0.09,0,0.04,0.07,0.13c-0.02-0.02-0.06-0.07-0.07-0.08
			l0.05,0.07c-0.11-0.17,0.01,0.14-0.15-0.09c0,0.03,0.06,0.16,0.02,0.15c0.15,0.21-0.06-0.12-0.05,0.07
			c-0.02-0.02-0.03-0.07-0.08-0.06c0.02,0.04,0.01,0.04,0.05,0.1c-0.17-0.26,0,0.09-0.13-0.06c-0.01,0.08,0.05,0.09,0.08,0.23
			c-0.01-0.05-0.06-0.11-0.09-0.15l0.04,0.07c-0.1-0.19,0.06,0.15-0.01,0.03c0,0.04,0.01,0.09-0.04-0.04c-0.01,0.04,0,0-0.09-0.08
			c0.13,0.29,0,0.01,0.06,0.23c-0.05-0.15,0.02,0.01-0.12-0.26c0.03,0.08-0.04-0.06,0,0.06c-0.04-0.13,0.02-0.16-0.1,0.09
			c-0.01,0.01,0.03,0.16-0.03-0.06c0,0.03,0.01,0.1,0.01,0.13c-0.02-0.06-0.02-0.09-0.03-0.13
			C1386.12,231.35,1386.13,231.09,1386.21,231.36z M1388,228.69c0.02,0-0.02,0.01-0.05,0.01
			C1387.97,228.69,1387.99,228.69,1388,228.69z M1387.35,228.9c0.02-0.03,0-0.01,0.07-0.02
			C1387.39,228.88,1387.37,228.89,1387.35,228.9z"
              />
              <path
                className="st10"
                d="M1435.87,206.87c0.01,0.02-0.05,0.05-0.01,0.17c0.03-0.03,0.13-0.08,0.16,0.09
			c-0.02-0.02,0.09-0.09,0.14,0.07c0.11,0.01,0.14,0,0.07-0.26l0.13,0.24l-0.03-0.1c0.01,0,0.02,0,0.04,0.04
			c0-0.08-0.02-0.04-0.03-0.11c0.07,0.08,0.08-0.08,0.14,0.08c-0.02-0.08-0.05-0.05-0.04-0.11c0.06,0.2,0.06-0.12,0.13,0.14
			c0-0.02,0-0.04,0-0.05c0.01,0.03,0.03,0.05,0.09-0.01c0.07,0.08-0.05,0.15,0.07-0.1c0,0.05,0.02-0.03,0.17,0.08
			c-0.04-0.12,0.01-0.12,0.03-0.15c0.03,0.07,0.03,0.1,0.03,0.14c0.03-0.03,0.03-0.01,0.03-0.1l0.03,0.09
			c0.04-0.02,0.03-0.13,0.11-0.09c-0.03-0.08-0.13-0.22-0.1-0.29c0.03,0.07,0.02,0.11,0.2,0.31c-0.01-0.03-0.05-0.21,0.06-0.01
			c-0.01-0.05,0.03-0.01-0.01-0.11c0.01,0,0.03,0.04,0.05,0.06c0-0.05,0.01,0,0.01-0.15l0,0c-0.02-0.18,0.39,0.09,0.96-0.21
			l-0.01-0.04c0.04,0.03,0.08,0,0.1,0.07c0.01-0.05-0.02-0.07-0.05-0.08c0.18,0.06,0-0.06,0.39,0.01l-0.01,0.04
			c0-0.02,0.02-0.03,0.03-0.03l-0.05-0.07c0.02,0.01,0.03,0,0.04,0.03c-0.01-0.06-0.07-0.12-0.08-0.2c0.04,0.03,0.03-0.07,0.09,0.03
			c-0.07-0.01,0.05,0.1,0.02,0.13c0.11,0.02,0.07,0.09,0.06-0.13c0.02,0.06,0.06,0.1,0.07,0.08c-0.02,0.01-0.06-0.09-0.06-0.14
			c0.06,0.03,0.02-0.05,0.04-0.05c-0.04-0.03-0.05-0.02-0.02-0.1c0.02,0.04-0.01,0.04,0.01,0.06c-0.02-0.11,0.11,0.07,0.11-0.01
			c0.04,0.03,0.05,0.1,0.08,0.12c0.02-0.11,0.14,0.04,0.05-0.17l-0.04,0.09c-0.09-0.15,0.12-0.28,0.31-0.14
			c-0.02-0.04,0.05-0.02-0.01-0.15c0.14,0.27,0.05-0.33,0.16,0.02c0.02-0.01,0.04-0.01,0.02-0.05c-0.02,0.03,0.1-0.06,0-0.24
			c0.15,0.16,0.14-0.09,0.27,0.04c0.03-0.04,0.06-0.08,0.09-0.11c-0.12-0.03,0.23,0.12,0.33-0.31c0.03,0.08,0,0.05,0.06,0.13
			c-0.17-0.35,0.1-0.11,0.28-0.14c0-0.04,0-0.16,0.03-0.2c0.03,0.1,0.02,0.17,0.02,0.24c0.08-0.21-0.11-0.08,0.6-0.27
			c-0.06-0.05-0.05-0.1-0.09-0.15c0.05,0.01,0.14,0.07,0.27,0.23c-0.05-0.06-0.04-0.1,0-0.09c-0.07,0.01,0.05-0.15,0.08-0.01
			c0.01-0.05,0.11-0.07,0.05-0.24c0.11,0.21,0.19-0.02,0.4-0.39c0.13,0.08,0.29,0.34,0.33-0.15c0.14,0.1,0.2-0.05,0.33-0.03
			c-0.03-0.01-0.07-0.1-0.06-0.12c0.04,0.01-0.01-0.12,0.07,0l-0.01,0c0.09,0.1,0.11,0.05,0.14,0.03l-0.1-0.15
			c0.23,0.16,0.13-0.22,0.31-0.06c-0.01-0.05,0.01-0.07,0-0.12c0.05,0.03,0.06,0.14,0.2-0.01c0.14,0.3,0.16-0.13,0.26-0.34
			c0.08,0.1,0.07-0.04,0.06-0.11l0.06,0.08c-0.02-0.07-0.04-0.17,0.14-0.25c-0.04-0.06,0.03-0.11,0.14-0.11
			c-0.04,0.03-0.07,0.05-0.08,0.04c-0.02,0.04,0.38,0.06,0.19-0.17c0.01,0-0.01,0,0,0.04l0-0.01l-0.03,0.04
			c-0.02-0.05-0.03-0.09,0.02-0.06c-0.03-0.06-0.06-0.11-0.08-0.14c0.05-0.01,0.29,0.18,0.32-0.2c0.07,0.02,0.06,0.11,0.09,0.05
			c0.24,0.15,0.03,0.03,0.22-0.23c-0.02-0.01-0.03-0.01-0.04-0.02c0.05-0.02,0.3,0.01,0.37-0.38l0.08,0.07
			c-0.17-0.17,0.12-0.13,0.09-0.23c0.01,0,0.07-0.03,0.12-0.03l-0.01-0.12c0.18,0.11,0.46,0.19-0.3-0.45l0.04,0.1l-0.07-0.05
			l0.02-0.03c-0.07-0.04-0.03,0.02-0.01,0.06c-0.03-0.03-0.06-0.04-0.07-0.05l0.08,0.14c-0.05-0.04-0.07-0.11-0.12-0.11
			c0.03,0.07,0.11,0.09,0.12,0.15c-0.07-0.04-0.1-0.09-0.08-0.02c-0.03-0.03-0.05-0.05-0.05-0.07c0.02,0.05-0.03,0.06,0.02,0.13
			c-0.08-0.05-0.04,0.07-0.14-0.05c0.02,0.03,0.05,0.12,0.04,0.13c-0.1-0.16-0.06,0.04-0.19-0.11c0.09,0.14-0.02,0.12,0,0.22
			c-0.01-0.01-0.02-0.04-0.01-0.03c-0.03,0.01-0.07-0.02-0.1-0.03c0.09,0.1,0.08,0.16,0.12,0.23c-0.11-0.08-0.09-0.13-0.19-0.22
			c0.08,0.08,0.03,0.12,0.1,0.18c-0.07-0.04-0.04-0.01-0.13-0.09c0.11,0.13,0,0.04,0.06,0.13c-0.08-0.04-0.11-0.11-0.11-0.05
			c-0.18-0.17,0.01,0.04-0.1,0.29c-0.1-0.12,0.11,0.21,0.04,0.08l0.01,0.04c-0.09-0.08-0.01-0.05-0.07-0.12
			c0,0.04-0.03,0.24-0.09,0.02l0.06,0c-0.14-0.02-0.27-0.05-0.49,0.42c-0.02-0.03-0.03-0.07-0.07-0.08
			c0.12,0.07-0.15,0.34-0.72,0.58c0.06,0.08-0.07,0.27-0.25,0.15l0.04,0.11l-0.09-0.09c0.04,0.16-0.1-0.14-0.12,0.17
			c-0.02-0.04-0.02-0.06-0.03-0.09c0.06,0.15-0.12-0.1,0,0.09c-0.06-0.04-0.1-0.01-0.11,0.03c-0.07-0.06-0.03-0.1-0.2,0.09
			c0.04,0.01,0.11,0.12,0.1,0.14l-0.07-0.09c0.03,0.07,0.04,0.02,0.07,0.11c-0.03,0.01-0.09-0.09-0.1-0.13l-0.01,0.05
			c-0.02-0.03-0.02-0.05-0.05-0.08c0.03,0.07-0.12-0.06-0.06,0.05c0.08-0.41-0.59,0.76-1.24,0.78c0,0.01,0.05,0.07,0.03,0.07
			c0.11,0.1-0.36,0.03-0.81,0.54c-0.01-0.03-0.02-0.06-0.01-0.06c-0.06-0.06-0.08,0.04-0.05,0.1c-0.03-0.08-0.11-0.06-0.12-0.05
			c0.01,0.04-0.07,0.03-0.01,0.11c-0.06-0.02-0.05,0.08-0.11,0.05c0.03,0.11-0.09-0.01-0.03,0.13c-0.07-0.14-0.13-0.07-0.12,0
			c-0.01-0.05-0.05-0.03-0.07-0.1c-0.03,0.02-0.04,0.06,0,0.13c-0.14-0.14-0.03,0.19-0.18-0.01c-0.05,0.12-0.1,0.21-0.16,0.28
			c-0.11-0.11-0.01-0.1-0.32,0.11c-0.01-0.01-0.01-0.03-0.01-0.05c-0.01,0.01-0.04-0.01-0.01,0.06l0-0.02
			c0.07,0.08,0.04,0.22,0.07,0.27c-0.07-0.2-0.09-0.01-0.17-0.27c0,0.02,0,0.05,0.02,0.09c-0.13-0.05-0.01-0.02-0.36,0.09
			c0.03,0.01-0.07,0.01,0.02,0.1c-0.01-0.05-0.06,0.17-0.07,0.03l-0.05,0.05c0.04,0.01,0.16,0.24-0.01,0.11
			c-0.02,0.05-0.03,0.14-0.1-0.08c0.03,0.11-0.06,0.06-0.03,0.18c0-0.03-0.03-0.09-0.04-0.1l0.02,0.09
			c-0.06-0.12-0.08-0.02-0.06-0.01c-0.03-0.05-0.2,0.18-0.18-0.06c-0.06,0.05-0.08,0.21-0.16,0.21c0.11,0.19,0,0.01-0.07,0.04
			c0-0.02-0.03-0.1-0.02-0.08c-0.06-0.01-0.08,0.08-0.13,0.17c-0.03-0.05,0-0.07-0.13-0.05c0.01,0.05-0.01,0.05,0.03,0.11
			c-0.1-0.11-0.31-0.17-0.29,0.25c0.01-0.06-0.05-0.12-0.08-0.16l0.03,0.07c-0.1-0.19,0.02,0.15-0.05,0.04
			c-0.01,0.03-0.06,0.12-0.06-0.04c-0.07,0.06,0.02,0-0.18-0.05c0.11,0.28,0.01,0.01-0.03,0.26c-0.02-0.16,0.03-0.01-0.12-0.26
			c0.01,0.09-0.06-0.05-0.05,0.08c-0.01-0.03-0.03,0.01-0.05-0.08c0.23-0.06-0.17,0.42-0.36,0.21c-0.02,0.04-0.02,0.11-0.04,0.15
			C1435.86,206.95,1435.88,206.91,1435.87,206.87z M1443.98,203.05c0,0.02-0.03,0-0.03-0.04
			C1443.96,203.02,1443.97,203.04,1443.98,203.05z M1443.44,202.62c0.01,0.02,0.08,0.09-0.03,0.02
			C1443.42,202.63,1443.41,202.64,1443.44,202.62z"
              />
              <path
                className="st10"
                d="M1445.6,206.47c0.04,0.24-0.21,0.23-0.24,0.12c-0.04,0.03-0.07,0.13-0.12,0.17
			c-0.01-0.03-0.01-0.07-0.03-0.1c0.08,0.1-0.36,0.16-1,0.28l0,0.12l-0.05-0.12c-0.01,0.16-0.05-0.17-0.14,0.11
			c-0.01-0.04,0-0.06,0-0.09c0.01,0.16-0.07-0.13-0.03,0.08c-0.04-0.06-0.08-0.05-0.1-0.01c-0.04-0.07,0.01-0.12-0.19,0.01
			c0.03,0.03,0.06,0.15,0.04,0.17l-0.04-0.11c0.01,0.07,0.03,0.04,0.03,0.13c-0.03-0.01-0.05-0.12-0.05-0.16l-0.02,0.05
			c-0.01-0.03-0.01-0.05-0.02-0.09c0.01,0.07-0.08-0.1-0.07,0.03c0.17-0.36-0.7,0.48-1.22,0.26c0,0.01,0.05,0.16-0.02,0.02
			c0.27,0.21-0.33-0.08-0.76,0.27c0-0.03-0.01-0.06,0-0.06c-0.04-0.08-0.08,0.01-0.07,0.08c-0.01-0.08-0.08-0.09-0.09-0.09
			c-0.01,0.04-0.07,0-0.03,0.09c-0.05-0.04-0.06,0.06-0.1,0.01c0,0.11-0.07-0.04-0.05,0.11c-0.03-0.16-0.09-0.11-0.1-0.04
			c0-0.05-0.03-0.05-0.03-0.11c-0.03,0.01-0.05,0.04-0.03,0.12c-0.08-0.18-0.07,0.17-0.14-0.07c-0.07,0.1-0.13,0.17-0.2,0.22
			c-0.06-0.14,0.02-0.11-0.28,0c0-0.01,0-0.03,0.01-0.04c-0.01,0.01-0.03-0.02-0.02,0.05l0.01-0.02c0.04,0.1-0.01,0.22-0.01,0.28
			c-0.01-0.21-0.07-0.04-0.08-0.3c-0.01,0.01-0.01,0.05,0,0.1c-0.09-0.09,0-0.03-0.32-0.03c0.02,0.01-0.06-0.02-0.01,0.1
			c0-0.05-0.09,0.14-0.06,0l-0.05,0.03c0.03,0.02,0.07,0.28-0.04,0.11c-0.04,0.05-0.05,0.12-0.07-0.11c0,0.11-0.07,0.04-0.07,0.16
			c0-0.03-0.01-0.1-0.01-0.11l0,0.09c0.02-0.22-0.19,0.16-0.18-0.14c-0.06,0.03-0.12,0.17-0.18,0.15c0.05,0.21,0,0.01-0.07,0.02
			c0.01-0.01,0-0.1,0-0.08c-0.05-0.02-0.08,0.06-0.14,0.12c-0.01-0.05,0.02-0.07-0.1-0.08c0,0.05-0.02,0.04,0,0.11
			c-0.06-0.15-0.24-0.24-0.3,0.15c0.02-0.06-0.01-0.13-0.03-0.17l0.01,0.08c-0.04-0.21-0.02,0.15-0.05,0.03
			c-0.02,0.03-0.08,0.1-0.04-0.05c-0.07,0.04,0.01,0.01-0.14-0.1c0.03,0.31,0,0.01-0.08,0.24c0.02-0.16,0.03,0-0.04-0.28
			c-0.01,0.09-0.04-0.06-0.06,0.06c0-0.03-0.02,0-0.03-0.09c0.21,0-0.25,0.36-0.34,0.1c-0.02,0.03-0.04,0.1-0.06,0.13
			c-0.01-0.06,0.01-0.09,0.01-0.13c0,0.02-0.05,0.02-0.05,0.16c0.03-0.03,0.13-0.05,0.12,0.13c0.02-0.03,0-0.28,0.08,0.07l0.02-0.05
			c0,0.03,0.01,0.1,0,0.08c0.1,0.04,0.12,0.03,0.12-0.23l0.05,0.26l0-0.11c0.01,0,0.02,0.01,0.02,0.05c0.02-0.07-0.01-0.05,0-0.12
			c0.04,0.1,0.08-0.05,0.1,0.12c0-0.08-0.03-0.06-0.01-0.12c0.01,0.21,0.08-0.1,0.07,0.18c0.01-0.03,0.01-0.05,0.01-0.06
			c0,0.02,0.01,0.07,0.07,0.03c0.04,0.1-0.07,0.11,0.08-0.07c-0.01,0.05,0.03-0.02,0.12,0.13c-0.01-0.13,0.03-0.11,0.06-0.14
			c0.01,0.08,0,0.11-0.01,0.14c0.03-0.02,0.02,0,0.05-0.08l0,0.1c0.03-0.01,0.06-0.12,0.11-0.06c-0.01-0.09-0.06-0.24-0.02-0.31
			c0.02,0.08-0.02,0.05,0.1,0.35c0-0.04,0-0.21,0.05,0.01c0-0.05,0.03,0,0.02-0.1c0.01,0,0.02,0.05,0.02,0.07
			c0.01-0.05,0.01,0.01,0.05-0.14l0,0c0.06-0.14,0.36,0.17,0.9,0.15c0.02-0.04,0-0.07-0.02-0.09c0.05,0.05,0.06-0.01,0.11,0.03
			c-0.01-0.08,0.01-0.01,0.04-0.18c0.03,0.06,0.01,0.15,0.05,0.09c0.03,0.04,0.02,0.11,0.02,0.14c-0.02-0.09,0.1,0.01,0.09,0.09
			c0.01-0.02,0.02-0.02,0.03-0.02l-0.02-0.08c0.01,0.01,0.02,0.01,0.03,0.05c0-0.06-0.03-0.14-0.02-0.21
			c0.02,0.04,0.04-0.05,0.06,0.06c-0.06-0.03,0.02,0.11-0.02,0.13c0.09,0.05,0.03,0.11,0.08-0.1c0,0.06,0.03,0.11,0.04,0.1
			c-0.02,0-0.03-0.1-0.01-0.15c0.04,0.04,0.03-0.04,0.05-0.04c-0.03-0.04-0.04-0.03,0.01-0.1c0.01,0.05-0.01,0.04,0,0.06
			c0.01-0.11,0.07,0.1,0.1,0.03c0.02,0.04,0.02,0.11,0.04,0.14c0.04-0.09,0.1,0.08,0.08-0.14l-0.06,0.07
			c-0.05-0.18,0.18-0.22,0.29-0.03c-0.01-0.05,0.05,0,0.03-0.14c0.05,0.3,0.12-0.3,0.12,0.08c0.02,0,0.03,0.01,0.02-0.04
			c-0.02,0,0.09-0.02,0.06-0.23c0.09,0.2,0.13-0.04,0.21,0.13c0.03-0.03,0.07-0.06,0.1-0.07c-0.08-0.08,0.19,0.2,0.35-0.17
			c0.01,0.09-0.01,0.05,0.02,0.14c0.02-0.02-0.03-0.14,0-0.17c0,0.04,0.11,0.21,0.12,0.25c0.06-0.02,0.09-0.41,0.15-0.11
			c0.01-0.04,0.04-0.15,0.07-0.18c0,0.1-0.03,0.16-0.04,0.23c0.01-0.29,0.22,0.31,0.52-0.23c0.01,0.04,0,0.15,0.04,0.19
			c-0.04-0.07,0-0.12-0.04-0.19c0.03-0.01,0.07,0.07,0.17,0.33c-0.02-0.07-0.01-0.11,0.02-0.09c-0.06-0.01,0.08-0.12,0.06,0.02
			c0.02-0.05,0.11-0.02,0.1-0.2c-0.02,0.12,0.24,0.1,0.43-0.21c0.08,0.11,0.15,0.42,0.31-0.02c0.09,0.15,0.17,0.03,0.27,0.1
			c-0.02-0.02-0.03-0.12-0.02-0.13c0.03,0.03,0.02-0.12,0.05,0.02l-0.01,0c0.05,0.13,0.08,0.09,0.11,0.08l-0.05-0.18
			c0.15,0.24,0.16-0.15,0.27,0.07c0-0.05,0.03-0.06,0.03-0.1c0.03,0.03,0,0.16,0.16,0.07c0.03,0.1,0.03,0.13,0-0.23
			c0.04,0.05,0.02,0.22,0.23,0.08c0.01,0.02,0.02,0.06,0.01,0.07c0.03,0,0.14,0.06,0.07-0.13c0.04,0.13,0.06-0.01,0.08-0.07
			l0.02,0.09c-0.01-0.13-0.02-0.16,0.01-0.2c0.01,0.01,0.03,0.07,0.01,0.05c0.17,0.02-0.07-0.19,0.16-0.01
			c-0.02-0.07,0.06-0.09,0.15-0.04c-0.04,0.01-0.07,0.02-0.07,0.01c-0.03,0,0.3,0.21,0.21-0.08c0.01,0.01-0.01,0-0.01,0.03l0-0.01
			l-0.04,0.02c0-0.05,0-0.1,0.03-0.05c-0.01-0.06-0.02-0.13-0.03-0.16c0.04,0,0.19,0.28,0.32-0.05c0.05,0.04,0.02,0.12,0.06,0.08
			c0.15,0.23,0.01,0.04,0.24-0.11c-0.01-0.01-0.02-0.02-0.03-0.04c0.04,0.01,0.27,0.12,0.41-0.18l0.04,0.1
			c-0.09-0.22,0.14-0.07,0.14-0.16c0.01,0,0.07,0.01,0.1,0.02l0.02-0.11c0.11,0.17,0.32,0.37-0.11-0.52v0.11l-0.04-0.07l0.03-0.02
			c-0.04-0.06-0.03,0-0.03,0.05c-0.02-0.04-0.03-0.06-0.05-0.08l0.02,0.16c-0.03-0.06-0.03-0.12-0.07-0.15
			c0.01,0.07,0.07,0.13,0.06,0.18c-0.04-0.06-0.06-0.12-0.06-0.05c-0.02-0.04-0.02-0.06-0.02-0.09c0,0.05-0.04,0.04-0.02,0.12
			c-0.05-0.08-0.05,0.04-0.1-0.1c0.01,0.04,0.01,0.13,0,0.14c-0.03-0.18-0.06,0.01-0.13-0.18c0.04,0.16-0.05,0.1-0.06,0.2
			c0-0.01-0.01-0.04,0-0.03c-0.03,0-0.05-0.05-0.08-0.07c0.04,0.13,0.02,0.17,0.03,0.26c-0.07-0.11-0.04-0.16-0.09-0.27
			c0.04,0.1-0.01,0.12,0.03,0.2c-0.05-0.07-0.03-0.03-0.08-0.13c0.05,0.16-0.01,0.03,0.01,0.14c-0.06-0.07-0.06-0.15-0.08-0.09
			c-0.1-0.22-0.01,0.04-0.16,0.22c-0.05-0.15,0.03,0.24,0.01,0.09l0,0.04c-0.05-0.11,0.01-0.05-0.02-0.13
			c-0.01,0.04-0.09,0.2-0.08-0.01l0.05,0.03C1445.66,206.5,1445.78,206.35,1445.6,206.47z M1445.18,206.82c0.01,0,0,0,0.03-0.01
			C1445.22,206.84,1445.26,206.92,1445.18,206.82z M1445.53,207.37c0,0.02,0.01,0.04,0.01,0.05
			C1445.53,207.44,1445.51,207.41,1445.53,207.37z"
              />
              <path
                className="st10"
                d="M1447,209.47l0.01-0.11c0.14,0.16,0.38,0.33-0.17-0.51l0.01,0.11l-0.05-0.06l0.03-0.02
			c-0.05-0.06-0.03,0.01-0.02,0.05c-0.02-0.04-0.04-0.06-0.06-0.07l0.04,0.16c-0.03-0.05-0.04-0.12-0.08-0.14
			c0.02,0.07,0.08,0.12,0.08,0.17c-0.05-0.06-0.07-0.11-0.07-0.05c-0.02-0.04-0.03-0.06-0.03-0.08c0.01,0.05-0.04,0.04-0.01,0.12
			c-0.06-0.07-0.05,0.05-0.12-0.09c0.01,0.04,0.02,0.12,0.01,0.14c-0.05-0.18-0.07,0.02-0.15-0.16c0.05,0.16-0.05,0.11-0.05,0.21
			c0-0.01-0.01-0.04,0-0.03c-0.03,0-0.06-0.04-0.08-0.06c0.06,0.13,0.03,0.17,0.05,0.25c-0.08-0.11-0.05-0.15-0.11-0.26
			c0.05,0.1,0,0.12,0.05,0.2c-0.06-0.06-0.04-0.03-0.09-0.13c0.07,0.16-0.01,0.04,0.02,0.14c-0.07-0.06-0.07-0.14-0.09-0.08
			c-0.12-0.21,0,0.04-0.16,0.24c-0.06-0.14,0.05,0.23,0.01,0.09l0,0.04c-0.06-0.11,0.01-0.05-0.03-0.13
			c-0.01,0.04-0.09,0.21-0.08-0.01l0.06,0.02c-0.12-0.04-0.23-0.15-0.54,0.22c-0.11-0.26,0.08-0.09-1.09,0.22l0,0.12l-0.05-0.12
			c-0.01,0.16-0.05-0.17-0.15,0.12c-0.01-0.04,0-0.06,0-0.09c0.01,0.16-0.07-0.13-0.03,0.09c-0.04-0.06-0.08-0.05-0.11-0.01
			c-0.04-0.08,0.01-0.11-0.2,0.01c0.03,0.03,0.06,0.15,0.04,0.17l-0.04-0.11c0,0.07,0.03,0.04,0.02,0.13
			c-0.03-0.01-0.05-0.12-0.04-0.16l-0.02,0.05c-0.01-0.03-0.01-0.05-0.01-0.09c0,0.07-0.08-0.1-0.07,0.03
			c0.2-0.36-0.76,0.47-1.3,0.21c0,0.01,0.03,0.16-0.03,0.02c0.26,0.24-0.34-0.1-0.83,0.2c0-0.03,0-0.06,0.01-0.06
			c-0.03-0.08-0.08,0-0.08,0.07c0-0.08-0.07-0.1-0.08-0.1c-0.01,0.04-0.07-0.01-0.05,0.09c-0.04-0.04-0.07,0.05-0.11-0.01
			c-0.02,0.11-0.07-0.05-0.07,0.11c0-0.16-0.08-0.12-0.1-0.05c0.01-0.05-0.02-0.05-0.02-0.12c-0.03,0-0.05,0.03-0.05,0.11
			c-0.06-0.19-0.1,0.15-0.14-0.09c-0.08,0.09-0.16,0.15-0.24,0.18c-0.04-0.15,0.04-0.1-0.3-0.05c0-0.01,0.01-0.03,0.01-0.04
			c-0.01,0-0.03-0.02-0.03,0.05l0.01-0.02c0.02,0.1-0.06,0.22-0.06,0.27c0.03-0.21-0.06-0.05-0.02-0.31
			c-0.01,0.01-0.02,0.05-0.02,0.09c-0.08-0.1,0-0.03-0.33-0.09c0.02,0.02-0.06-0.03-0.03,0.1c0.01-0.05-0.13,0.12-0.06-0.01
			l-0.06,0.02c0.03,0.03,0.02,0.29-0.06,0.1c-0.05,0.04-0.08,0.1-0.05-0.12c-0.02,0.11-0.08,0.02-0.1,0.14
			c0.01-0.03,0.01-0.1,0.01-0.11l-0.02,0.09c0.07-0.21-0.23,0.11-0.15-0.18c-0.07,0.02-0.16,0.15-0.22,0.11c0,0.22,0,0.01-0.07,0
			c0.01-0.01,0.02-0.1,0.02-0.08c-0.04-0.04-0.1,0.03-0.18,0.08c0-0.05,0.04-0.06-0.08-0.1c-0.01,0.05-0.03,0.04-0.03,0.11
			c-0.03-0.15-0.16-0.3-0.35,0.07c0.04-0.05,0.02-0.13,0.01-0.18l-0.01,0.08c0.01-0.21-0.06,0.14-0.06,0.01
			c-0.03,0.02-0.11,0.08-0.03-0.06c-0.09,0.02,0.01,0.01-0.12-0.14c-0.05,0.3,0,0.01-0.15,0.21c0.06-0.15,0.03,0.01,0.04-0.28
			c-0.04,0.08-0.02-0.07-0.08,0.05c0-0.03-0.02-0.01,0-0.09c0.21,0.06-0.36,0.27-0.38,0c-0.03,0.03-0.07,0.08-0.1,0.11
			c0.01-0.06,0.03-0.08,0.05-0.12c0,0.02-0.06,0.01-0.1,0.14c0.04-0.01,0.15,0,0.08,0.16l0.06-0.11c-0.01,0-0.03,0.17,0,0.2
			l0.03-0.04c-0.01,0.03-0.02,0.1-0.03,0.08c0.09,0.07,0.11,0.07,0.19-0.19l-0.02,0.27l0.03-0.1c0.01,0.01,0.02,0.02,0.01,0.06
			c0.04-0.07,0-0.05,0.03-0.11c0.01,0.1,0.1-0.03,0.07,0.14c0.03-0.08-0.02-0.07,0.03-0.12c-0.05,0.2,0.11-0.07,0.03,0.19
			c0.08-0.17-0.03,0.02,0.1-0.02c0.01,0.11-0.11,0.1,0.09-0.04c-0.03,0.05,0.03-0.01,0.09,0.15c0.03-0.13,0.06-0.1,0.1-0.12
			c-0.01,0.08-0.02,0.1-0.04,0.14c0.04-0.01,0.02,0,0.07-0.07l-0.02,0.09c0.04,0,0.09-0.1,0.13-0.03c0.02-0.09,0-0.25,0.06-0.3
			c0,0.08-0.04,0.05,0.01,0.37c0.01-0.04,0.06-0.2,0.05,0.02c0.02-0.05,0.03,0.01,0.05-0.1c0.01,0,0.01,0.05,0.01,0.08
			c0.02-0.05,0.01,0.01,0.08-0.13h0c0.09-0.13,0.33,0.25,0.93,0.34c0.03-0.04,0.02-0.07,0-0.09c0.13,0.14,0.02-0.06,0.32,0.2
			l-0.02,0.03c0.01-0.02,0.03-0.02,0.04-0.02l-0.01-0.08c0.01,0.01,0.02,0.02,0.02,0.05c0.02-0.05-0.01-0.14,0.02-0.21
			c0.02,0.04,0.05-0.04,0.06,0.07c-0.05-0.04,0,0.11-0.04,0.13c0.08,0.07,0.02,0.11,0.11-0.09c-0.01,0.06,0.01,0.12,0.02,0.11
			c-0.02,0-0.01-0.1,0.01-0.15c0.03,0.05,0.04-0.03,0.06-0.03c-0.02-0.05-0.03-0.04,0.03-0.1c0,0.05-0.02,0.03-0.01,0.05
			c0.03-0.11,0.06,0.11,0.1,0.04c0.02,0.04,0,0.12,0.02,0.15c0.06-0.08,0.09,0.1,0.11-0.13l-0.07,0.06
			c-0.01-0.18,0.22-0.19,0.32,0.02c0-0.05,0.05,0,0.05-0.13c0.01,0.3,0.18-0.28,0.12,0.09c0.02,0,0.03,0.01,0.03-0.04
			c-0.04,0.03,0.12-0.02,0.1-0.22c0.06,0.21,0.15-0.02,0.2,0.16c0.04-0.03,0.08-0.05,0.12-0.06c-0.08-0.09,0.17,0.22,0.4-0.13
			c-0.01,0.09-0.02,0.05,0,0.14c-0.01-0.39,0.13-0.06,0.29-0.01c0.02-0.04,0.06-0.14,0.1-0.17c-0.01,0.1-0.05,0.16-0.07,0.22
			c0.14-0.21-0.05-0.1,0.58-0.19c0,0.05-0.01,0.15,0.03,0.19c-0.04-0.08,0.01-0.12-0.02-0.19c0.04,0,0.08,0.07,0.15,0.33
			c-0.02-0.07,0-0.11,0.03-0.09c-0.06-0.01,0.1-0.12,0.07,0.03c0.03-0.04,0.12-0.02,0.13-0.19c0.02,0.23,0.17,0.07,0.48-0.19
			c0.08,0.12,0.14,0.43,0.33-0.01c0.09,0.15,0.19,0.03,0.29,0.1c-0.02-0.02-0.03-0.12-0.02-0.14c0.03,0.03,0.03-0.12,0.06,0.02
			l-0.01,0c0.05,0.13,0.08,0.09,0.11,0.08l-0.04-0.18c0.15,0.24,0.18-0.16,0.29,0.06c0.01-0.05,0.03-0.06,0.04-0.11
			c0.03,0.04,0,0.16,0.18,0.07c0.03,0.33,0.18-0.06,0.33-0.22c0.04,0.13,0.07-0.01,0.09-0.08l0.03,0.09
			c-0.01-0.13-0.02-0.16,0.01-0.21c0.01,0.01,0.03,0.07,0.01,0.05c0.09,0.09-0.11,0.01,0.18-0.02c-0.02-0.07,0.06-0.09,0.16-0.05
			c-0.04,0.01-0.08,0.02-0.08,0.02c-0.03,0.02,0.33,0.18,0.22-0.1c0.01,0.01-0.01,0-0.01,0.03l0-0.01l-0.04,0.02
			c0-0.05-0.01-0.1,0.03-0.05c-0.01-0.06-0.03-0.13-0.03-0.16c0.05,0,0.21,0.27,0.34-0.08c0.06,0.04,0.02,0.12,0.07,0.08
			c0.17,0.22,0.02,0.04,0.26-0.14c-0.01-0.01-0.02-0.02-0.03-0.04c0.04,0,0.29,0.1,0.43-0.23l0.05,0.09
			c-0.12-0.21,0.15-0.09,0.14-0.18C1446.9,209.46,1446.96,209.46,1447,209.47z M1445.32,209.7c0.01,0,0,0,0.03-0.01
			C1445.35,209.72,1445.4,209.8,1445.32,209.7z M1445.69,210.23c0.01,0.02,0.01,0.04,0.01,0.05
			C1445.7,210.3,1445.68,210.27,1445.69,210.23z"
              />
              <path
                className="st10"
                d="M1445.37,213.08c0.01,0.01,0.05,0.02,0.07,0.04l0.02-0.1c0.07,0.18,0.22,0.44-0.07-0.53l0,0.1
			c-0.02,0-0.02-0.2-0.02-0.04c-0.01-0.04-0.02-0.07-0.03-0.08l0.01,0.16c-0.02-0.06-0.02-0.13-0.04-0.16
			c0.01,0.07,0.04,0.14,0.03,0.19c-0.03-0.07-0.04-0.13-0.04-0.06c-0.01-0.04-0.01-0.07-0.01-0.09c0,0.05-0.03,0.03-0.01,0.11
			c-0.03-0.09-0.03,0.03-0.07-0.12c0,0.04,0,0.12,0,0.13c-0.02-0.18-0.04,0-0.08-0.2c0.02,0.17-0.04,0.08-0.04,0.18
			c0-0.01,0-0.04,0-0.03c-0.02-0.01-0.03-0.06-0.05-0.09c0.02,0.13,0.01,0.17,0.01,0.25c-0.04-0.13-0.02-0.16-0.05-0.28
			c0.02,0.11-0.01,0.11,0.01,0.2c-0.03-0.08-0.02-0.04-0.05-0.15c0.03,0.17-0.01,0.03,0,0.14c-0.03-0.08-0.03-0.16-0.05-0.1
			c-0.05-0.24,0,0.04-0.12,0.17l-0.01-0.03c-0.01,0,0.06,0.16,0.01,0.18c0.01,0,0-0.03,0-0.06l0,0.04
			c-0.03-0.12,0.01-0.04-0.01-0.13c-0.01,0.04-0.07,0.17-0.05-0.03l0.03,0.04c-0.07-0.07-0.15-0.2-0.34,0.05
			c0-0.04,0-0.07-0.01-0.11c-0.04,0.07-0.1,0-0.15-0.07l-0.01,0.1c-0.03-0.21,0,0.16-0.02-0.05c-0.14,0.04-0.04,0.23-0.31,0.07
			c0.01,0.13-0.15,0.16-0.16-0.02l-0.01,0.11l-0.02-0.13c-0.03,0.05,0.03,0.24-0.06-0.02l-0.05,0.09c0-0.04,0.01-0.06,0.01-0.09
			c-0.02,0.16-0.03-0.14-0.03,0.08c-0.09-0.07-0.03-0.06-0.18,0.1l-0.01-0.11c-0.01,0.07,0.01,0.04,0,0.13
			c-0.02-0.01-0.01-0.13,0-0.17l-0.02,0.04c0-0.03,0-0.05,0-0.1c-0.01,0.07-0.03-0.11-0.04,0.01c-0.01-0.06-0.11-0.03-0.12,0.17
			c-0.03-0.01-0.12-0.21-0.1,0.04c-0.03-0.21-0.01,0.02-0.27-0.15l-0.05,0.09c-0.02-0.02,0.01-0.07,0-0.12
			c-0.05,0.15-0.07-0.03-0.11,0.07c-0.01-0.27-0.1,0.01-0.13-0.16c0,0,0,0.17-0.02,0.01c0.1,0.32-0.17-0.17-0.51,0.06
			c0.01-0.03,0.01-0.06,0.02-0.06c0-0.09-0.05-0.01-0.06,0.06c0.02-0.07-0.02-0.11-0.03-0.11c-0.01,0.04-0.04-0.02-0.04,0.08
			c-0.02-0.05-0.05,0.04-0.06-0.02c-0.03,0.11-0.03-0.06-0.06,0.09c0.03-0.16-0.02-0.13-0.05-0.06c0.01-0.05-0.01-0.05,0.01-0.12
			c-0.02,0-0.04,0.03-0.05,0.11c0-0.19-0.09,0.14-0.07-0.1c-0.06,0.08-0.12,0.12-0.17,0.15c-0.01-0.14,0.06-0.1-0.17-0.08
			c0-0.01,0.01-0.03,0.02-0.04c-0.01,0-0.01-0.03-0.03,0.04l0.01-0.02c-0.01,0.1-0.07,0.21-0.09,0.26c0.06-0.2-0.03-0.06,0.05-0.31
			c-0.01,0.01-0.02,0.04-0.03,0.09c0-0.02,0-0.04,0.02-0.09c-0.03,0.03-0.04,0.11-0.07,0.1c0.01-0.03,0.01-0.05,0.03-0.09
			c0,0.01-0.08,0.13-0.05,0.05c-0.04-0.01-0.16,0.16-0.1-0.09c-0.01,0.02-0.04,0.01-0.04,0.1c0.02-0.04-0.1,0.11-0.04-0.01
			c-0.06-0.01-0.06,0.31-0.09,0.1c-0.04,0.04-0.06,0.09,0-0.12c-0.04,0.1-0.05,0.02-0.09,0.13c0.01-0.03,0.03-0.09,0.03-0.11
			l-0.03,0.08c0.08-0.19-0.16,0.08-0.05-0.19c-0.05,0.01-0.13,0.13-0.16,0.09c-0.04,0.22,0,0.01-0.04,0
			c0.01-0.01,0.03-0.1,0.03-0.08c-0.02-0.04-0.07,0.03-0.12,0.07c0.01-0.03,0.04-0.06-0.03-0.11c-0.02,0.04-0.03,0.03-0.04,0.1
			c0.03-0.28-0.14,0.11-0.12-0.12c0.01,0,0.02-0.04,0.02-0.04c-0.08,0.03-0.03,0.1-0.13,0.21c0.03-0.04,0.04-0.12,0.05-0.17
			l-0.02,0.07c0.06-0.2-0.07,0.14-0.04,0.01c-0.03,0.03-0.08,0.06,0-0.06c-0.05,0.01,0,0-0.04-0.13c-0.1,0.29,0,0.01-0.14,0.2
			c0.07-0.15,0.01,0.02,0.09-0.27c-0.04,0.08,0-0.07-0.06,0.04c0.01-0.03-0.01-0.01,0.02-0.09c0.24,0-0.36,0.13-0.23-0.01
			c-0.02,0.03-0.07,0.08-0.09,0.1c0.02-0.06,0.04-0.08,0.06-0.11c-0.2,0.21,0.03,0.03-0.08,0.29l0.06-0.1
			c-0.01,0-0.06,0.18-0.05,0.19l0.03-0.03c-0.01,0.03-0.03,0.1-0.04,0.07c0.05,0.07,0.06,0.05,0.16-0.18l-0.07,0.25l0.04-0.1
			c0,0.01,0.01,0.02-0.01,0.06c0.04-0.06,0.01-0.04,0.05-0.11c-0.02,0.1,0.07-0.02,0.01,0.14c0.03-0.07,0-0.06,0.04-0.11
			c-0.08,0.19,0.08-0.06-0.03,0.18c0.08-0.15-0.01,0.01,0.07-0.01c-0.02,0.1-0.09,0.09,0.07-0.04c-0.03,0.04,0.02,0,0.02,0.15
			c0.05-0.12,0.06-0.09,0.09-0.11c-0.02,0.07-0.04,0.1-0.06,0.13c0.03-0.01,0.01,0,0.06-0.06l-0.03,0.09c0.02,0,0.08-0.09,0.09-0.02
			c0.03-0.09,0.05-0.24,0.1-0.29c-0.01,0.05-0.04,0.13-0.05,0.14c0.04,0.03-0.02-0.02-0.02,0.21c0.01-0.03,0.08-0.2,0.02,0.02
			c0.02-0.04,0.02,0.01,0.05-0.09c0.01,0-0.01,0.05-0.01,0.08c0.02-0.04,0.01,0.01,0.08-0.12h0c0.27,0,0.01,0.2,0.5,0.31
			c0.01,0.07,0.04,0.01,0.06,0.06c0-0.02,0.01-0.07,0.01-0.07c0.03,0.06,0.04-0.07,0.07-0.08c0,0.07-0.04,0.15,0,0.1
			c0.01,0.05-0.02,0.11-0.03,0.14c0.02-0.09,0.06,0.04,0.03,0.11c0.01-0.02,0.02-0.02,0.03-0.01l0.01-0.08c0,0.02,0.01,0.02,0,0.05
			c0.02-0.05,0.02-0.14,0.06-0.21c0.02,0.12-0.01,0.03,0.04,0.22c-0.01,0,0.01-0.1,0.04-0.15c0.01,0.05,0.03-0.03,0.04-0.02
			c0-0.06-0.01-0.04,0.04-0.09c-0.01,0.05-0.02,0.03-0.02,0.05c0.04-0.1,0.01,0.12,0.05,0.05c0,0.04-0.02,0.11-0.02,0.14
			c0.06-0.07,0.04,0.1,0.09-0.11l-0.05,0.05c0.06-0.16,0-0.25,0.18,0.05c0.01-0.05,0.03,0.01,0.06-0.13
			c-0.05,0.3,0.16-0.25,0.05,0.11c0.05-0.06,0,0,0.12-0.23c0,0.22,0.09,0,0.09,0.18c0.14-0.11,0-0.07,0.33-0.11
			c-0.02,0.09-0.02,0.05-0.02,0.14c0.01-0.01,0.01-0.14,0.04-0.17c-0.01,0.04,0.01,0.23,0.02,0.27c0.04-0.01,0.05-0.15,0.09-0.14
			c0.04-0.12,0.05-0.09,0.03,0.08c0.02-0.03,0.06-0.13,0.08-0.15c-0.02,0.1-0.05,0.15-0.08,0.21c0.06-0.27,0.1,0.33,0.37-0.08
			c0,0.04-0.03,0.14-0.01,0.2c0-0.13,0.01-0.42,0.05,0.16c0-0.08,0.02-0.11,0.03-0.08c-0.03-0.01,0.08-0.1,0.04,0.04
			c0.02-0.04,0.08,0.01,0.1-0.17c-0.04,0.1,0.16,0.15,0.31-0.09c0.03,0.1,0.01,0.46,0.2,0.06c0.03,0.17,0.11,0.07,0.16,0.16
			c-0.01-0.03,0-0.12,0.01-0.14c0.01,0.03,0.03-0.11,0.03,0.03l0,0c0.01,0.14,0.04,0.1,0.06,0.1l0-0.18
			c0.04,0.2,0.33,0.22,0.52,0.02c0.01,0.13,0.04,0.01,0.06-0.05l0.01,0.1c0.01-0.13,0-0.16,0.03-0.2c0.01,0.01,0.01,0.08,0,0.05
			c0.13,0.07-0.04-0.19,0.11,0.03c-0.01-0.08,0.06-0.07,0.1-0.01c-0.03,0-0.05,0-0.05-0.01c-0.02-0.04,0.19,0.28,0.14-0.03
			c0,0.01,0,0-0.01,0.03l0-0.01l-0.03,0.01c0-0.05,0.01-0.09,0.03-0.04c0.02-0.18-0.08-0.19,0.21-0.14c0.03,0.05,0,0.12,0.03,0.09
			c0.04,0.25,0.1-0.01,0.15-0.1c0.02,0.02,0.22,0.17,0.28-0.08l0.03,0.1C1445.21,212.98,1445.37,213.16,1445.37,213.08z
			 M1444.39,212.82c0.01,0,0.01,0,0.02,0c0.01,0.03,0,0.07,0,0.07C1444.41,212.86,1444.4,212.85,1444.39,212.82z M1444.57,213.43
			c0,0.02,0,0.04,0,0.05C1444.57,213.5,1444.55,213.47,1444.57,213.43z"
              />
              <path
                className="st10"
                d="M1383.35,218.27c0.02-0.03,0.07-0.26,0.03,0.09l0.03-0.04c-0.01,0.03-0.02,0.1-0.03,0.08
			c0.06,0.07,0.06,0.05,0.14-0.19l-0.04,0.26l0.03-0.1c0,0,0.01,0.01,0,0.06c0.03-0.07,0.01-0.05,0.03-0.11
			c0,0.1,0.06-0.03,0.03,0.14c0.03-0.08,0-0.07,0.03-0.12c-0.05,0.2,0.07-0.07-0.01,0.19c0.06-0.15-0.01,0.01,0.06-0.02
			c-0.01,0.1-0.08,0.1,0.06-0.04c-0.02,0.04,0.02,0,0.04,0.15c0.03-0.12,0.05-0.09,0.08-0.11c-0.02,0.07-0.03,0.1-0.05,0.13
			c0.03-0.01,0.01,0,0.05-0.07l-0.03,0.09c0.02,0,0.07-0.1,0.08-0.03c0.02-0.09,0.04-0.25,0.08-0.3c-0.02,0.08-0.02,0.01-0.05,0.36
			c0.01-0.03,0.07-0.2,0.02,0.02c0.02-0.05,0.02,0.01,0.04-0.09c0.01,0,0,0.05-0.01,0.08c0.02-0.04,0.01,0.01,0.07-0.12h0
			c0.26-0.01,0.01,0.2,0.5,0.32c0.01,0.07,0.04,0.01,0.06,0.06c0-0.02,0.01-0.07,0.01-0.07c0.03,0.06,0.04-0.07,0.07-0.08
			c0,0.07-0.04,0.14,0,0.1c0.01,0.05-0.02,0.11-0.04,0.14c0.02-0.09,0.05,0.04,0.02,0.11c0.01-0.02,0.02-0.01,0.02-0.01l0.01-0.08
			c0,0.02,0.01,0.02,0,0.05c0.02-0.05,0.03-0.14,0.06-0.2c0.01,0.12-0.01,0.03,0.03,0.22c-0.01,0,0.02-0.1,0.04-0.14
			c0.01,0.05,0.03-0.03,0.04-0.02c0-0.06-0.01-0.04,0.04-0.09c-0.01,0.05-0.02,0.03-0.02,0.05c0.04-0.1,0.01,0.12,0.05,0.05
			c0,0.04-0.03,0.11-0.03,0.14c0.06-0.06,0.03,0.1,0.09-0.1l-0.06,0.05c0.07-0.15,0.01-0.25,0.17,0.07
			c0.01-0.05,0.03,0.01,0.07-0.12c-0.08,0.29,0.18-0.24,0.04,0.11c0.05-0.05,0,0.01,0.15-0.22c-0.02,0.22,0.09,0.01,0.07,0.2
			c0.15-0.09,0.01-0.06,0.34-0.06c-0.03,0.08-0.02,0.04-0.04,0.13c0.01-0.01,0.03-0.14,0.06-0.16c-0.01,0.04-0.02,0.23-0.03,0.27
			c0.04-0.01,0.07-0.14,0.11-0.12c0.06-0.11,0.07-0.08,0.02,0.08c0.02-0.03,0.08-0.12,0.11-0.13c-0.04,0.1-0.08,0.14-0.11,0.19
			c0.11-0.26,0.03,0.34,0.38-0.01c-0.01,0.04-0.06,0.13-0.06,0.19c0.03-0.12,0.11-0.41,0.01,0.17c0.02-0.07,0.04-0.1,0.05-0.07
			c-0.03-0.02,0.1-0.08,0.03,0.04c0.03-0.03,0.07,0.02,0.14-0.14c-0.06,0.08,0.11,0.18,0.32-0.01c0,0.11-0.12,0.44,0.17,0.11
			c-0.02,0.17,0.08,0.1,0.1,0.2c0-0.03,0.04-0.11,0.05-0.13c0,0.03,0.06-0.09,0.02,0.04l0,0c-0.03,0.14,0,0.11,0.02,0.11l0.06-0.18
			c-0.03,0.27,0.15-0.06,0.11,0.18c0.12-0.09,0.05,0.3,0.22-0.15l-0.04,0.14c0.04,0.01,0.04,0.14,0.12,0.03
			c-0.01,0.02-0.02,0.06-0.03,0.07c0.02,0.01,0.04,0.12,0.09-0.08c-0.04,0.13,0.04,0.02,0.07-0.03l-0.03,0.09
			c0.05-0.11,0.06-0.15,0.1-0.17c0,0.01-0.02,0.08-0.02,0.05c0.09,0.1,0.04-0.19,0.09,0.06c0.02-0.08,0.08-0.04,0.1,0.03
			c-0.03-0.01-0.05-0.02-0.04-0.03c0-0.05,0.06,0.33,0.14,0.03c0,0.01,0,0-0.02,0.02l0-0.01l-0.03,0c0.02-0.05,0.04-0.08,0.04-0.03
			c0.02-0.06,0.05-0.12,0.06-0.15c0.02,0.01-0.02,0.34,0.19,0.11c0.01,0.06-0.05,0.11-0.01,0.1c-0.08,0.24,0.09,0.03,0.18-0.02
			c0.01,0.03,0.11,0.24,0.29,0.05l-0.03,0.1c0.06-0.22,0.11,0.01,0.15-0.07c0,0.01,0.03,0.04,0.04,0.07l0.07-0.08
			c-0.03,0.2-0.04,0.47,0.22-0.49l-0.06,0.09c-0.01-0.01,0.1-0.18,0-0.05c0.01-0.04,0.02-0.07,0.02-0.09l-0.07,0.14
			c0.02-0.06,0.05-0.12,0.05-0.16c-0.03,0.07-0.03,0.14-0.07,0.18c0.01-0.07,0.03-0.13,0-0.07c0.01-0.04,0.02-0.07,0.03-0.08
			c-0.03,0.04-0.04,0.01-0.07,0.09c0.02-0.09-0.05,0.01,0-0.14c-0.02,0.03-0.06,0.11-0.07,0.11c0.08-0.17-0.04-0.02,0.03-0.21
			c-0.07,0.15-0.08,0.05-0.13,0.13c0.01-0.01,0.02-0.04,0.02-0.03c-0.01-0.02,0-0.07,0-0.1c-0.04,0.13-0.08,0.15-0.12,0.23
			c0.02-0.13,0.06-0.15,0.09-0.27c-0.03,0.11-0.07,0.1-0.09,0.19c0.01-0.08,0-0.04,0.03-0.15c-0.06,0.16-0.02,0.02-0.07,0.12
			c0.01-0.09,0.04-0.15,0.01-0.11c0.06-0.24-0.02,0.03-0.19,0.1l0.01-0.03c-0.01,0-0.02,0.16-0.07,0.16c0.01,0,0.02-0.03,0.03-0.05
			l-0.02,0.03c0.03-0.12,0.03-0.04,0.05-0.12c-0.03,0.03-0.14,0.12-0.03-0.05l0.01,0.05c-0.03-0.18-0.04-0.07-0.24-0.07l0.03-0.11
			c-0.04,0.01-0.1,0.08-0.15,0.09c0.01-0.03,0.03-0.07,0.03-0.1c-0.06,0.04-0.1-0.04-0.12-0.12l-0.05,0.09
			c0.05-0.2-0.06,0.15,0-0.05c-0.15-0.02-0.13,0.2-0.34-0.05c-0.04,0.12-0.2,0.11-0.15-0.07l-0.05,0.1l0.03-0.13
			c-0.04,0.04-0.05,0.24-0.05-0.04l-0.08,0.07c0.01-0.04,0.03-0.05,0.04-0.08c-0.07,0.14,0.02-0.15-0.05,0.06
			c-0.06-0.09-0.01-0.07-0.21,0.04l0.03-0.11c-0.03,0.07,0,0.04-0.04,0.12c-0.01-0.02,0.03-0.13,0.05-0.16l-0.03,0.03
			c0.01-0.03,0.02-0.05,0.03-0.09c-0.03,0.07,0-0.12-0.05,0c0.01-0.06-0.1-0.06-0.17,0.13c-0.02-0.02-0.06-0.23-0.11,0.01
			c0.02-0.21-0.01,0.01-0.24-0.22l-0.07,0.08c-0.02-0.02,0.03-0.06,0.03-0.12c-0.09,0.13-0.07-0.04-0.13,0.04
			c0.04-0.26-0.11-0.01-0.11-0.19c0,0-0.04,0.16-0.02,0.01c0.05,0.32-0.16-0.21-0.55-0.04c0.01-0.02,0.02-0.06,0.03-0.06
			c0.01-0.08-0.05-0.02-0.07,0.05c0.02-0.07-0.01-0.11-0.02-0.12c-0.02,0.04-0.04-0.02-0.06,0.07c-0.01-0.05-0.06,0.03-0.06-0.03
			c-0.04,0.1-0.03-0.06-0.07,0.08c0.04-0.15-0.01-0.14-0.04-0.07c0.02-0.04,0-0.05,0.02-0.11c-0.02,0-0.04,0.02-0.06,0.1
			c0.02-0.19-0.1,0.13-0.06-0.11c-0.07,0.07-0.14,0.11-0.19,0.13c0-0.14,0.07-0.1-0.17-0.09c0-0.01,0.01-0.03,0.02-0.04
			c-0.01,0-0.01-0.03-0.03,0.04l0.01-0.02c-0.01,0.1-0.09,0.2-0.1,0.25c0.06-0.2-0.03-0.06,0.06-0.31
			c-0.01,0.01-0.02,0.04-0.03,0.09c0-0.02,0-0.04,0.02-0.09c-0.04,0.03-0.05,0.11-0.07,0.1c0.01-0.03,0.02-0.05,0.03-0.08
			c0,0.01-0.09,0.12-0.06,0.04c-0.04-0.01-0.16,0.15-0.1-0.1c-0.01,0.02-0.04,0.01-0.04,0.1c0.02-0.04-0.11,0.1-0.04-0.01
			c-0.07-0.02-0.07,0.31-0.1,0.1c-0.04,0.04-0.06,0.08,0-0.12c-0.04,0.1-0.05,0.02-0.09,0.13c0.01-0.03,0.03-0.09,0.03-0.11
			l-0.03,0.08c0.08-0.19-0.16,0.08-0.06-0.19c-0.05,0.01-0.12,0.13-0.16,0.09c-0.04,0.22,0,0.01-0.05,0
			c0.01-0.01,0.03-0.1,0.02-0.08c-0.02-0.04-0.07,0.03-0.12,0.07c0.01-0.04,0.04-0.06-0.03-0.11c-0.02,0.04-0.02,0.03-0.04,0.1
			c0.01-0.28-0.14,0.11-0.13-0.12c0.01,0,0.02-0.04,0.02-0.04c-0.08,0.03-0.03,0.11-0.11,0.22c0.03-0.05,0.03-0.13,0.03-0.17
			l-0.02,0.08c0.04-0.21-0.06,0.14-0.04,0.01c-0.03,0.03-0.07,0.07-0.01-0.06c-0.05,0.02,0.01,0-0.05-0.13
			c-0.07,0.3,0,0.01-0.12,0.21c0.06-0.15,0.02,0.01,0.06-0.28c-0.03,0.08-0.01-0.07-0.05,0.04c0.01-0.03-0.01-0.01,0.01-0.09
			c0.24-0.02-0.35,0.16-0.24,0.01c-0.02,0.03-0.06,0.08-0.07,0.11c0.01-0.06,0.03-0.09,0.04-0.12
			C1383.22,218.2,1383.42,218,1383.35,218.27z M1388.12,219.3c0,0.01,0.01,0.01,0.02,0.01c-0.01,0.03-0.02,0.06-0.02,0.06
			C1388.12,219.35,1388.12,219.33,1388.12,219.3z M1388.03,219.93c-0.01,0.02-0.01,0.03-0.02,0.05
			C1388,219.99,1388,219.95,1388.03,219.93z"
              />
              <path
                className="st10"
                d="M1381.59,220.31c-0.26,0.16,0.03,0.04-0.11,0.28c0-0.02,0.1,0.01,0.01,0.14c0.06,0.09,0.07,0.08,0.21-0.14
			l-0.1,0.24l0.06-0.09c0,0.01,0.01,0.02-0.01,0.05c0.05-0.05,0.02-0.04,0.06-0.1c-0.02,0.1,0.08-0.01,0.01,0.15
			c0.05-0.07,0.01-0.07,0.06-0.1c-0.11,0.18,0.11-0.04-0.04,0.18c0.11-0.14-0.02,0.01,0.08,0c-0.03,0.1-0.12,0.06,0.09-0.02
			c-0.04,0.04,0.03,0,0.02,0.17c0.07-0.11,0.08-0.07,0.12-0.09c-0.04,0.07-0.05,0.09-0.08,0.12c0.04,0,0.02,0.01,0.08-0.05
			l-0.05,0.08c0.03,0.01,0.11-0.07,0.11,0c0.04-0.08,0.09-0.24,0.15-0.27c-0.03,0.07-0.05,0.03-0.12,0.34
			c0.02-0.03,0.11-0.17,0.03,0.03c0.03-0.04,0.02,0.01,0.07-0.08c0.01,0-0.01,0.05-0.02,0.07c0.03-0.04,0.01,0.01,0.11-0.1l0,0
			c0.12-0.09,0.18,0.32,0.57,0.56c0.04-0.03,0.04-0.06,0.03-0.08c0.01,0.07,0.05,0.02,0.07,0.08c0.03-0.07,0.01,0,0.12-0.13
			c-0.01,0.07-0.07,0.13-0.01,0.1c0,0.05-0.04,0.1-0.06,0.13c0.03-0.08,0.07,0.06,0.02,0.12c0.02-0.01,0.03-0.01,0.03-0.01
			l0.02-0.08c0,0.02,0.01,0.02,0,0.05c0.03-0.05,0.05-0.13,0.1-0.19c0,0.04,0.06-0.02,0.02,0.08c-0.02-0.05-0.04,0.1-0.08,0.1
			c0.04,0.09-0.03,0.1,0.11-0.05c-0.03,0.05-0.04,0.11-0.03,0.1c-0.02-0.01,0.03-0.1,0.07-0.14c0.01,0.06,0.04-0.02,0.05-0.01
			c0-0.05-0.01-0.04,0.06-0.08c-0.02,0.05-0.03,0.02-0.03,0.05c0.06-0.09,0,0.12,0.06,0.07c0,0.04-0.04,0.11-0.04,0.14
			c0.08-0.05,0.03,0.12,0.13-0.08l-0.08,0.03c0.11-0.15,0.04-0.25,0.22,0.12c0.02-0.05,0.03,0.02,0.09-0.11
			c-0.12,0.28,0.24-0.2,0.05,0.12c0.08-0.05,0.01,0,0.2-0.19c-0.04,0.22,0.12,0.03,0.08,0.22c0.04-0.01,0.08-0.02,0.11-0.01
			c0-0.11,0.05,0.27,0.34,0.02c-0.04,0.08-0.03,0.04-0.06,0.12c0.02-0.01,0.05-0.14,0.09-0.15c-0.01,0.1-0.11,0.4,0.12,0.25
			c0.03-0.02,0.1-0.1,0.14-0.12c-0.05,0.09-0.11,0.13-0.15,0.17c0.17-0.12,0-0.12,0.48,0.08c-0.02,0.05-0.07,0.12-0.06,0.17
			c0.01-0.08,0.05-0.1,0.07-0.17c0.02,0.01,0.01,0.09-0.05,0.35c0.02-0.07,0.05-0.1,0.06-0.06c-0.04-0.03,0.12-0.06,0.03,0.05
			c0.04-0.03,0.09,0.03,0.17-0.12c-0.08,0.08,0.12,0.21,0.41,0.04c0,0.12-0.13,0.43,0.22,0.15c-0.02,0.17,0.11,0.11,0.14,0.22
			c0-0.03,0.04-0.11,0.06-0.12c0,0.03,0.08-0.09,0.03,0.05l0,0c-0.03,0.13,0.01,0.11,0.03,0.12l0.06-0.17
			c-0.03,0.28,0.19-0.04,0.15,0.2c0.15-0.1,0.08,0.33,0.28-0.12c0.01,0.05-0.11,0.19,0.11,0.19c-0.01,0.02-0.02,0.06-0.03,0.06
			c0.02,0.01,0.06,0.13,0.12-0.07c-0.04,0.13,0.05,0.03,0.09-0.02l-0.04,0.09c0.06-0.11,0.08-0.14,0.12-0.16
			c0,0.01-0.02,0.08-0.02,0.05c0.11,0.11,0.05-0.19,0.12,0.08c0.02-0.07,0.1-0.03,0.12,0.05c-0.03-0.01-0.06-0.03-0.05-0.03
			c-0.01-0.03,0.09,0.34,0.18,0.05c0,0.01,0,0-0.03,0.02l0-0.01l-0.04-0.01c0.03-0.04,0.05-0.08,0.05-0.02
			c0.03-0.06,0.06-0.12,0.07-0.14c0.03,0.02-0.03,0.34,0.24,0.14c0.01,0.07-0.06,0.11-0.01,0.1c-0.03,0.27-0.02,0.04,0.22,0.05
			c0-0.02,0-0.03,0-0.05c0.02,0.03,0.12,0.26,0.37,0.09l-0.03,0.1c0.07-0.23,0.13,0.03,0.18-0.04c0.01,0.01,0.04,0.05,0.05,0.08
			l0.08-0.07c-0.03,0.2-0.02,0.49,0.24-0.48c-0.1,0.16,0.03-0.04-0.07,0.03c0.01-0.04,0.02-0.07,0.02-0.09l-0.08,0.14
			c0.02-0.06,0.06-0.11,0.05-0.16c-0.04,0.06-0.03,0.14-0.07,0.18c0.01-0.08,0.03-0.13-0.01-0.08c0.01-0.04,0.02-0.06,0.04-0.08
			c-0.03,0.04-0.05,0.01-0.08,0.08c0.01-0.09-0.06,0-0.01-0.14c-0.02,0.03-0.07,0.1-0.08,0.11c0.09-0.17-0.05-0.03,0.02-0.21
			c-0.07,0.15-0.09,0.05-0.16,0.12c0.01-0.01,0.02-0.04,0.02-0.03c-0.01-0.02,0-0.07-0.01-0.1c-0.05,0.13-0.09,0.15-0.13,0.22
			c0.02-0.13,0.07-0.15,0.1-0.27c-0.04,0.11-0.08,0.09-0.1,0.18c0.01-0.08,0-0.04,0.03-0.15c-0.06,0.16-0.03,0.02-0.08,0.12
			c0-0.09,0.05-0.15,0-0.11c0.07-0.24-0.03,0.03-0.24,0.08c0.05-0.14-0.11,0.21-0.04,0.07l-0.03,0.03c0.03-0.12,0.03-0.03,0.06-0.12
			c-0.03,0.03-0.18,0.1-0.05-0.06l0.02,0.05c-0.05-0.11-0.06-0.26-0.45-0.15c0.01-0.03,0.03-0.06,0.04-0.1
			c0.01,0.13-0.36-0.08-0.85-0.31l-0.06,0.1l0.03-0.13c-0.05,0.04-0.05,0.24-0.06-0.05l-0.1,0.06c0.02-0.04,0.03-0.05,0.05-0.07
			c-0.08,0.14,0.02-0.15-0.07,0.05c0.01-0.07-0.03-0.08-0.07-0.06c0.01-0.07,0.07-0.1-0.14-0.09c0.01,0.04-0.04,0.16-0.06,0.17
			l0.03-0.11c-0.03,0.06,0,0.05-0.05,0.12c-0.02-0.02,0.03-0.13,0.05-0.16l-0.04,0.03c0.01-0.03,0.02-0.05,0.04-0.09
			c-0.03,0.06,0-0.12-0.06-0.01c0.15-0.23-0.08,0.07-0.71-0.21c-0.11,0.12-0.09-0.05-0.17,0.02c0.05-0.26-0.14-0.03-0.13-0.21
			c0,0.01-0.05,0.16-0.03,0c0.08,0.32-0.2-0.24-0.69-0.14c0.01-0.02,0.03-0.06,0.04-0.05c0.01-0.08-0.06-0.03-0.09,0.03
			c0.04-0.07-0.01-0.11-0.02-0.12c-0.03,0.03-0.05-0.03-0.07,0.06c-0.01-0.05-0.07,0.02-0.08-0.05c-0.06,0.09-0.03-0.07-0.09,0.07
			c0.06-0.15-0.01-0.14-0.05-0.08c0.02-0.04,0-0.05,0.03-0.11c-0.02-0.01-0.05,0.01-0.08,0.09c0.03-0.19-0.14,0.11-0.07-0.13
			c-0.1,0.05-0.18,0.08-0.25,0.09c0.02-0.14,0.08-0.09-0.21-0.13c0.01-0.01,0.02-0.03,0.03-0.03c-0.01,0-0.01-0.03-0.04,0.04
			l0.02-0.01c-0.03,0.1-0.13,0.18-0.15,0.23c0.1-0.18-0.03-0.07,0.1-0.3c-0.01,0.01-0.03,0.04-0.05,0.08
			c-0.02-0.11,0.02-0.02-0.21-0.18c0.01,0.02-0.03-0.05-0.06,0.09c0.03-0.04-0.14,0.07-0.05-0.02l-0.05,0
			c0.01,0.03-0.09,0.27-0.08,0.07c-0.05,0.03-0.09,0.07,0.01-0.13c-0.06,0.09-0.07,0-0.13,0.1c0.02-0.02,0.05-0.08,0.05-0.1
			l-0.05,0.08c0.13-0.17-0.22,0.04-0.05-0.21c-0.06,0-0.17,0.09-0.21,0.04c-0.08,0.21,0,0.01-0.06-0.02
			c0.01-0.01,0.05-0.09,0.04-0.07c-0.02-0.04-0.09,0.01-0.17,0.03c0.02-0.04,0.06-0.05-0.03-0.12c-0.03,0.04-0.04,0.03-0.06,0.09
			c0.02-0.18-0.09-0.3-0.29-0.02c0.05-0.04,0.06-0.12,0.07-0.16l-0.04,0.07c0.08-0.19-0.1,0.12-0.05,0c-0.03,0.02-0.11,0.05,0-0.06
			c-0.07,0,0.01,0.01-0.04-0.15c-0.15,0.27-0.01,0.01-0.19,0.17c0.1-0.13,0.02,0.02,0.13-0.26c-0.06,0.07,0.01-0.07-0.08,0.02
			c0.02-0.03-0.02-0.01,0.03-0.09c0.28,0.08-0.42,0.03-0.3-0.08c-0.03,0.02-0.08,0.06-0.11,0.08
			C1381.54,220.36,1381.57,220.34,1381.59,220.31z M1387.47,222.75c0.01,0.01,0.01,0.01,0.02,0.01
			C1387.48,222.79,1387.46,222.88,1387.47,222.75z M1387.39,223.39c-0.01,0.02-0.01,0.03-0.02,0.05
			C1387.35,223.45,1387.35,223.42,1387.39,223.39z"
              />
              <path
                className="st10"
                d="M1382.77,225.9c-0.01,0.01,0.09-0.05,0.02,0.03c0.13-0.03-0.11,0.13,0.26-0.02
			c-0.04,0.12-0.27,0.37,0.16,0.22c-0.07,0.16,0.07,0.14,0.06,0.26c0.01-0.03,0.08-0.09,0.1-0.1c-0.01,0.03,0.1-0.06,0.01,0.05
			l0-0.01c-0.08,0.12-0.03,0.11-0.01,0.13l0.12-0.14c-0.12,0.25,0.2,0.03,0.07,0.24c0.17-0.04-0.04,0.35,0.3-0.01
			c-0.01,0.05-0.17,0.14,0.04,0.22c-0.01,0.02-0.04,0.05-0.05,0.05c0.02,0.02,0.01,0.14,0.13-0.02c-0.08,0.1,0.03,0.05,0.09,0.02
			l-0.06,0.07c0.1-0.08,0.12-0.11,0.17-0.11c0,0.02-0.04,0.07-0.04,0.04c0.07,0.15,0.11-0.16,0.09,0.12c0.04-0.06,0.1,0,0.1,0.09
			c-0.03-0.03-0.05-0.05-0.04-0.05c0-0.03-0.03,0.36,0.15,0.12c0,0.01,0,0-0.03,0.01l0.01-0.01l-0.03-0.02
			c0.04-0.03,0.08-0.06,0.05,0c0.05-0.04,0.09-0.09,0.11-0.11c0.02,0.02-0.14,0.32,0.19,0.22c-0.01,0.07-0.09,0.08-0.04,0.09
			c-0.12,0.25-0.03,0.03,0.2,0.13c0-0.02,0.01-0.03,0.02-0.04c0.01,0.03,0.03,0.29,0.32,0.22l-0.06,0.09
			c0.14-0.2,0.12,0.08,0.19,0.02c0,0.01,0.03,0.06,0.03,0.1l0.1-0.04c-0.09,0.18-0.18,0.47,0.38-0.37
			c-0.15,0.12,0.04-0.03-0.07,0.01c0.02-0.04,0.04-0.06,0.04-0.08l-0.12,0.1c0.04-0.05,0.09-0.09,0.09-0.13
			c-0.06,0.05-0.08,0.12-0.12,0.14c0.03-0.07,0.07-0.11,0.02-0.08c0.02-0.03,0.04-0.05,0.06-0.06c-0.04,0.03-0.05-0.01-0.11,0.05
			c0.04-0.08-0.06-0.02,0.04-0.14c-0.03,0.03-0.1,0.08-0.11,0.07c0.13-0.13-0.04-0.05,0.09-0.2c-0.12,0.12-0.1,0.01-0.19,0.06
			c0.01-0.01,0.03-0.03,0.03-0.02c-0.01-0.02,0.02-0.07,0.03-0.1c-0.09,0.11-0.13,0.11-0.2,0.17c0.06-0.12,0.11-0.12,0.18-0.22
			c-0.07,0.09-0.1,0.06-0.15,0.14c0.04-0.08,0.01-0.04,0.07-0.14c-0.11,0.13-0.03,0.01-0.11,0.08c0.03-0.08,0.09-0.13,0.04-0.11
			c0.14-0.2-0.04,0.02-0.25-0.01c0.09-0.12-0.17,0.16-0.07,0.06l-0.03,0.02c0.06-0.1,0.04-0.02,0.1-0.09
			c-0.04,0.01-0.2,0.04-0.02-0.07l0,0.06c-0.01-0.12,0.03-0.27-0.38-0.31c0.02-0.03,0.05-0.05,0.07-0.09
			c-0.04,0.13-0.31-0.2-0.7-0.6l-0.09,0.07l0.07-0.11c-0.06,0.02-0.13,0.21-0.05-0.07l-0.11,0.03c0.03-0.03,0.05-0.04,0.07-0.05
			c-0.12,0.1,0.07-0.13-0.08,0.03c0.03-0.06,0-0.09-0.04-0.08c0.03-0.06,0.1-0.07-0.1-0.14c-0.01,0.04-0.09,0.14-0.11,0.14
			l0.07-0.09c-0.05,0.05-0.01,0.04-0.09,0.1c-0.01-0.02,0.07-0.11,0.1-0.13l-0.05,0.01c0.02-0.03,0.04-0.04,0.06-0.07
			c-0.05,0.05,0.04-0.12-0.05-0.03c0.21-0.16-0.1,0.04-0.61-0.45c-0.14,0.07-0.06-0.08-0.17-0.04c0.14-0.23-0.12-0.08-0.06-0.24
			c-0.01,0-0.1,0.13-0.03-0.01c-0.02,0.33-0.12-0.3-0.62-0.37c0.02-0.02,0.04-0.04,0.05-0.04c0.04-0.08-0.05-0.05-0.1,0
			c0.05-0.05,0.03-0.11,0.02-0.12c-0.04,0.02-0.04-0.04-0.09,0.04c0-0.06-0.08-0.01-0.06-0.07c-0.08,0.07-0.01-0.08-0.11,0.04
			c0.1-0.12,0.04-0.14-0.03-0.09c0.04-0.03,0.02-0.05,0.07-0.1c-0.02-0.01-0.06-0.01-0.11,0.06c0.09-0.17-0.17,0.06-0.03-0.14
			c-0.11,0.02-0.2,0.02-0.27,0.01c0.06-0.13,0.1-0.06-0.17-0.19c0.01-0.01,0.02-0.02,0.04-0.03c-0.01,0,0-0.03-0.05,0.02l0.02-0.01
			c-0.05,0.09-0.17,0.14-0.21,0.18c0.15-0.15-0.01-0.07,0.18-0.25c-0.01,0.01-0.04,0.03-0.07,0.06c0.01-0.11,0.03-0.01-0.16-0.24
			c0,0.03-0.02-0.05-0.08,0.07c0.04-0.03-0.16,0.03-0.04-0.04l-0.05-0.01c0,0.04-0.16,0.24-0.1,0.05c-0.06,0.01-0.11,0.04,0.04-0.12
			c-0.08,0.07-0.07-0.02-0.16,0.06c0.02-0.02,0.07-0.07,0.07-0.08l-0.07,0.06c0.17-0.13-0.22-0.02,0-0.21
			c-0.06-0.02-0.2,0.04-0.22-0.02c-0.13,0.18,0,0.01-0.05-0.03c0.01-0.01,0.07-0.07,0.06-0.06c-0.01-0.05-0.09-0.02-0.17-0.01
			c0.03-0.04,0.07-0.04,0-0.12c-0.04,0.03-0.04,0.02-0.08,0.07c0.07-0.17-0.02-0.32-0.29-0.09c0.06-0.02,0.09-0.1,0.11-0.14
			l-0.05,0.06c0.13-0.17-0.12,0.1-0.05-0.01c-0.04,0.01-0.12,0.02,0.01-0.06c-0.07-0.02,0,0.01-0.01-0.16
			c-0.2,0.23-0.01,0.01-0.22,0.12c0.13-0.1,0.01,0.02,0.18-0.22c-0.07,0.05,0.02-0.07-0.08,0.01c0.02-0.03-0.01-0.02,0.05-0.08
			c0.26,0.14-0.43-0.06-0.29-0.14c-0.04,0.01-0.09,0.05-0.13,0.05c0.03-0.05,0.07-0.06,0.1-0.08c-0.29,0.11,0.02,0.05-0.16,0.25
			c0.01-0.02,0.1,0.03-0.01,0.13c0.04,0.1,0.05,0.09,0.24-0.09l-0.15,0.22l0.07-0.08c0,0.01,0,0.02-0.02,0.05
			c0.06-0.04,0.03-0.04,0.08-0.08c-0.04,0.09,0.09,0.01-0.02,0.14c0.06-0.05,0.02-0.06,0.08-0.09c-0.14,0.15,0.12-0.02-0.08,0.17
			c0.15-0.11-0.04,0.01,0.07,0.03c-0.04,0.09-0.13,0.04,0.1,0c-0.04,0.03,0.03,0.01-0.02,0.16c0.09-0.09,0.1-0.05,0.13-0.06
			c-0.05,0.06-0.07,0.08-0.11,0.1c0.04,0.01,0.02,0.01,0.09-0.03l-0.07,0.07c0.03,0.02,0.12-0.05,0.11,0.03
			c0.06-0.07,0.14-0.21,0.21-0.23c-0.04,0.06-0.05,0.02-0.2,0.3c0.03-0.03,0.15-0.14,0.02,0.04c0.04-0.03,0.02,0.02,0.09-0.06
			c0.01,0.01-0.02,0.04-0.04,0.07c0.04-0.03,0,0.01,0.13-0.07v0c0.11-0.05,0.04,0.11,0.11,0.11c0.06,0.31,0.08,0.18,0.31,0.57
			c0.04-0.02,0.05-0.05,0.05-0.07c-0.01,0.07,0.04,0.04,0.05,0.09c0.05-0.06,0.01,0,0.15-0.09c-0.02,0.06-0.1,0.11-0.04,0.09
			c-0.01,0.05-0.07,0.09-0.1,0.11c0.05-0.07,0.05,0.07-0.02,0.12c0.02-0.01,0.03,0,0.03,0l0.05-0.07c0,0.02,0.01,0.02-0.02,0.05
			c0.04-0.03,0.08-0.11,0.15-0.15c-0.02,0.04,0.06-0.01-0.01,0.08c-0.01-0.06-0.07,0.09-0.11,0.08c0.01,0.1-0.06,0.09,0.12-0.02
			c-0.04,0.04-0.07,0.1-0.05,0.09c-0.01-0.01,0.06-0.08,0.1-0.11c-0.01,0.05,0.05-0.01,0.05,0.01c0.02-0.05,0-0.04,0.08-0.06
			c-0.03,0.04-0.04,0.01-0.04,0.03c0.09-0.07-0.04,0.12,0.04,0.08c-0.02,0.04-0.07,0.09-0.08,0.12c0.09-0.03,0,0.12,0.15-0.04
			l-0.08,0.01c0.12-0.16,0.27-0.01,0.18,0.18c0.03-0.04,0.03,0.03,0.12-0.07c-0.19,0.23,0.29-0.11,0.01,0.13
			c0.08-0.02-0.01,0.01,0.25-0.12c-0.1,0.19,0.1,0.07,0.01,0.23c0.04,0,0.08,0.01,0.11,0.02c0.03-0.1-0.04,0.27,0.32,0.13
			c-0.07,0.06-0.04,0.03-0.1,0.1c0.02,0,0.09-0.11,0.13-0.11c-0.04,0.1-0.23,0.34,0.04,0.28c0.04-0.01,0.13-0.06,0.17-0.06
			c-0.08,0.07-0.14,0.08-0.2,0.12c0.21-0.06,0.04-0.11,0.43,0.24c-0.04,0.04-0.1,0.09-0.11,0.14c0.03-0.07,0.08-0.08,0.12-0.14
			c0.02,0.02-0.01,0.09-0.16,0.31c0.04-0.06,0.08-0.08,0.08-0.04c-0.03-0.04,0.14-0.02,0.02,0.06c0.05-0.01,0.08,0.06,0.2-0.05
			C1382.62,225.83,1382.51,225.83,1382.77,225.9z M1384.64,226.56c0,0.01,0,0,0.02,0.02
			C1384.64,226.6,1384.59,226.68,1384.64,226.56z M1384.36,227.14c-0.01,0.01-0.02,0.03-0.03,0.04
			C1384.31,227.19,1384.32,227.15,1384.36,227.14z"
              />
            </g>
          </g>
          <g id="nuve">
            <g>
              <path
                className="st10"
                d="M1831.74,186.59c-0.57-0.23-0.2,0.25-0.66,0.98c0.14,0.03,0.1,0.02,0.52-0.25c0.1,0.51,0.24-0.27,0.29,0.14
			c0.1-0.09,0.29-0.07,0.32-0.2c0.13,0.11,0.37-0.09,0.39,0.13c0.03-0.06,0.17-0.2,0.27-0.22c-0.15,0.28,0.38-0.03,0.36,0.26
			c0.11-0.25,0.48-0.16,0.71-0.31c-0.01,0.02-0.04,0.06-0.06,0.05c0.14,0.01,0.18,0.08,0.28,0.12c0-0.2,0.21-0.26,0.32-0.39
			c0.18,0.18-0.09,0.24-0.02,0.41c-0.02-0.16,0.28-0.18,0.22-0.31c0.14,0.11,0.11,0.05,0.19,0.21c-0.11-0.26,0.71-0.23,0.4,0.11
			c-0.14-0.18,0.7,0.15,1.62-0.61c-0.06,0-0.06,0.05-0.09,0.08l0.1-0.05c-0.49,0.27,0.44,0.29,0.05,0.18
			c-0.19,0.81,1.05-0.11,3.19,0.22c-0.36-0.04,0.29,0.24,2.73,0.57l-0.07,0.03c0.21,0.11,0.33-0.24,0.51-0.06l-0.05,0.02
			c-0.07,0.24,0.51,0.18,3.12,1.12c0.15-0.25-0.3,0.22,0.82,0.25c-0.03,0.05-0.11,0.05-0.19,0.06c0.29-0.06-0.02,0.16,0.25,0.12
			c-0.02-0.04,0.05-0.08,0.07-0.11c0.07,0.13,0.31,0.23,0.5,0.27l-0.08,0.08c0.29,0.16,0.66,0.24,1,0.39
			c-0.01-0.08,0.23-0.35,0.13-0.04c0.09-0.07,0.25-0.27,0.02,0.07l0.19,0.02c-0.01,0.05-0.06,0.05-0.1,0.11
			c0.81,0.34-0.01,0.13,2.36,0.8c-0.04,0.12,0.22,0.24,0.04,0.25c0.4,0.25,0.93,0,2.23,1.06l-0.03,0c1.06,0.34,0.02-0.01,2.61,1.23
			c0.04-0.09,0.03-0.19,0.17,0.04c0.37-0.59,0.55,0.74,3.08,0.64l-0.14,0.14c0.66-0.08,1.29,0.2,1.93,0.09
			c-0.04,0.03-0.05,0.08-0.11,0.08c0.12,0.13,0.48,0.05,0.49-0.05c0.26,0.03-0.13,0.18,1.79-0.02c-0.02,0.23,0.4,0.18,0.55,0.08
			c-0.05,0.07,0.13,0.07,0.07,0.17c0.17,0,0.32-0.05,0.29-0.16c0.52,0.22,0.34,0.17,2.16-0.18c-0.42,0.5-0.89,0.24,1.68,0
			c0,0.02-0.04,0.05-0.08,0.06c0.2-0.12-0.04,0.02,0.38-0.44c-0.11,0.3,0.37,0.06,0.18,0.44c0.05-0.02,0.11-0.07,0.09-0.14
			c0.07,0.02,0.09,0.06,0.03,0.14c0.26-0.06,0.16-0.17,0.4-0.17c0,0.05-0.05,0.07-0.07,0.13c0.12-0.1,0.23-0.2,0.43-0.16
			c-0.01,0.03,0.01,0.07-0.05,0.07c0.34-0.02,0.58-0.27,0.95-0.18c-0.11,0.22,0.13,0.07,0.15,0.26l0.2-0.06l-0.08-0.09
			c0.18,0,0.33-0.14,0.48-0.08l-0.13,0.08l0.33-0.04l-0.19-0.07c0.4-0.46,0.02,0.3,1.31-0.12c-0.12,0.19,0.22,0.29,0.34,0.03
			c-0.02,0.01-0.01,0.03-0.06,0.05c0.33-0.02,0.46,0.02,0.55,0.06l-0.1,0.02l0.1,0.08l0.04-0.08c0.14,0.2,0.24-0.24,2.54-0.08
			l-0.05,0.07c0.65-0.06,0.58,0.12,2.06,0.09c-0.15-0.01,0.27,0.19,0.43-0.27c-0.18,0.07-0.01,0.19,0.06,0.25
			c-0.01-0.04,0.02-0.07,0.01-0.11c0.15,0.28,0.07-0.14,0.29-0.02c-0.05,0.02,0.53-0.18,0.19,0.09c0.15,0,0.32-0.24,0.45-0.1
			c-0.07,0.03-0.16,0.03-0.23,0.06c0.14,0.16,0.16,0.12,0.28,0.16l-0.01,0l0.26,0.09c0.01-0.41-0.02-0.04,0.61-0.3
			c0.19,0.74-0.29,0.27,2.72,0.33c-0.15,0.24-0.29,0.24,0.15,0.21c-0.02-0.02,0.22-0.01,0.33-0.11c-0.19-0.14,0.12-0.32-0.68-0.13
			c0.23-0.26,0.03-0.37-0.47-0.29c0.03-0.04-0.01-0.15,0.07-0.11c-0.25-0.14-0.43,0.13-0.51-0.04l0.02-0.01
			c-0.72,0.55,0.17,0.07-1.23,0.11c0.11-0.31-0.46,0.14-0.32-0.28c-0.24,0.3,0.02-0.24-0.99,0.08c0.03-0.09-0.02,0.06-0.63-0.23
			c-0.05,0.18-0.27,0.14-0.43,0.17c0.01-0.11,0.04-0.15,0.13-0.2c-0.02,0.02-0.05-0.07-0.34,0.1l0.04-0.14
			c-0.2,0-0.41,0.15-0.67,0.04c-0.02,0.13,0.19,0.36-0.08,0.44c-0.01-0.07-0.01-0.19,0.06-0.21c-0.02,0.01-0.09,0.05-0.13,0.02
			l0.11-0.11c-0.06,0.05-0.36-0.01-0.09-0.06c-0.11-0.02-0.23-0.08-0.27-0.16c0,0.24-0.3-0.23-0.9,0.18l0,0
			c-0.24,0.2-0.85-0.23-2.15-0.11c0.16-0.08-0.05-0.08,0.16-0.06c-0.2-0.04-0.25-0.12-0.46,0.01c0.07-0.13-0.15-0.16-0.25-0.12
			l0.14,0.09c-0.57-0.15-0.08-0.18-1.3-0.17c0.47,0.54-1.09,0.13-0.95,0.04l-0.02,0.06c-0.09-0.06-0.35-0.05-0.29-0.15
			c-0.14,0.06-0.06,0.1,0.05,0.13c-0.24-0.08-0.38,0-0.63-0.05c0.1,0.06-0.75,0.5-0.55-0.1c-0.23,0.12-0.43-0.07-0.56-0.08
			l0.12-0.05c-0.07,0.03-0.13,0.03-0.19,0.03l0.07,0.12c-0.07-0.02-0.13-0.02-0.13-0.07c-0.13,0.09,0.07,0.44-0.4,0.22
			c0.29,0.04-0.01-0.14,0.19-0.18c-0.31-0.09-0.15-0.17-0.55,0.14c0.03-0.09-0.07-0.17-0.12-0.15c0.08-0.03,0.06,0.2-0.35,0.27
			l0.11,0.03c-0.41,0.18,0.03,0.14-1.3-0.22c0.07,0.12,0.08,0.09-0.08,0.21l0.39-0.1l-0.24,0.18c0.12,0,0.25-0.08,0.32-0.07
			c-0.12,0.1-0.1,0.07-0.04,0.17c-0.65,0.04-0.19-0.56-1.37-0.19c0.1,0.08,0.22,0.1-0.22,0.12c0.01-0.1-0.07-0.13-0.12-0.19
			c0.01,0.07-0.27,0-0.29,0.2c-0.31-0.16-0.16-0.23-0.78,0.13c0.08-0.11-0.04-0.18,0.14-0.26c-0.28,0.09,0.06-0.02-0.76,0.36
			c-0.22-0.23-0.35-0.22-1.69-0.22c0.01-0.03,0-0.05,0.04-0.04c-0.57,0.05-0.03-0.03-0.92-0.18l0.05-0.02
			c-0.14-0.12-0.37,0.08-0.61,0.04c0,0,0.02-0.03,0.01-0.05c-0.18,0.15-0.51,0.16-0.74,0.17c0.02-0.05,0.03-0.08,0.03-0.11
			c-0.05,0.09-0.17,0.21-0.35-0.17c0.37-0.08-0.37-0.41-1.75-0.17c0.15-0.1,0.41-0.11,0.58-0.17c-0.11-0.03-0.38-0.08-0.47-0.01
			c0.05-0.01,0.12-0.04,0.16,0.01c-0.98-0.02-0.07,0.11-2.4-1.03c-0.04,0.27-0.2,0.29-0.35,0.16c0.05-0.05,0.24-0.12,0.11-0.25
			c0.08,0.15-0.16,0.1-0.16,0.2c-0.12-0.13-0.22-0.35-0.24-0.61c-0.01,0.11-0.14,0.11-0.25,0.02l0.12,0.21
			c-0.38,0.01-0.17-0.35-0.54-0.34l0.14-0.04c-0.18-0.02-0.59-0.27-0.85-0.12c-0.01-0.05-0.02-0.14,0.06-0.13
			c-0.23-0.24-0.44-0.18-1.67-0.68l0.12-0.04c-1.21-0.21-0.69-0.75-3.74-1.85c-0.95-0.03,0.05,0.19-3.24-1.15l0.1-0.08
			c-0.69,0.16-0.24,0.13-1.62-0.22c0.01-0.03,0.04-0.09,0.1-0.08c-0.16-0.05-0.62-0.29-0.62,0c0.04-0.19-0.37-0.1-0.57-0.06
			l0.06-0.13c-0.01,0.04-0.24,0.08-0.78,0.11l0.1-0.07c-0.29-0.06-0.23,0.31-0.65-0.14c0.09,0-0.33,0.17-0.2,0.02
			c-0.27-0.22-0.06,0.15-0.7-0.16c0.25,0.04,0.45,0.08,0.43,0.08c0.02-0.13-0.18-0.18,0.01-0.33c-0.12-0.09-0.38,0.29-1.28-0.05
			c-0.01,0.14-0.14,0.1-0.02,0.23c-0.03-0.01,0.05,0,0.13-0.02l-0.01,0.01c0.07,0,0.15,0.01,0.25,0.02
			c-0.09,0.06-0.18,0.11-0.28,0.02c-0.06,0.08-0.13,0.17-0.16,0.21c-0.26-0.43-0.26-0.39-1.46-0.34c0.05-0.03,0.11-0.02,0.14-0.05
			c-0.18,0.06-0.52,0.01-0.57,0.19c-0.22-0.09,0.13-0.17-0.17-0.15l0.04-0.05c-0.59-0.32-0.97,0.46-1.63,0.08
			c0.05,0.02,0.07,0.03,0.08,0.06C1835.63,186.22,1834.28,186.22,1831.74,186.59z M1840.43,187.04c-0.03,0.03,0.07,0.07,0.12,0.12
			C1840.58,187.13,1840.27,187.19,1840.43,187.04z M1839.76,186.15c0.01-0.03,0,0,0.03-0.07
			C1839.84,186.08,1839.97,186.15,1839.76,186.15z M1840.14,187.1c-0.04,0.01,0,0-0.1,0C1840.06,187.1,1840.09,187.1,1840.14,187.1z
			"
              />
              <path
                className="st10"
                d="M1872.64,193.06l-0.18,0.05C1872.44,193.11,1872.51,193.11,1872.64,193.06z"
              />
              <path
                className="st10"
                d="M1847.56,187.93l-0.17-0.02C1847.41,187.89,1847.39,187.98,1847.56,187.93z"
              />
              <g>
                <g>
                  <g>
                    <path
                      className="st11"
                      d="M1724.99,220.3c-13.42,3.52-27.53-0.98-40.67-5.37c-9.53-3.19-19.65-6.4-29.52-4.38
						c-9.25,1.9-17.71,8.3-27.09,7.14c-6.45-0.79-11.77-5.04-16.94-8.84c-13.54-9.96-31-18.27-47.12-12.97
						c6.78-0.05,13.58,1.52,19.6,4.54c11.45,5.74,19.62,16.27,30.8,22.48c14.54,8.06,33.71,7.6,47.82-1.14
						c1.72-1.06,3.43-2.27,5.45-2.58c2.25-0.35,4.5,0.48,6.63,1.26c18.47,6.78,40.11,11.25,57.28,1.8"
                    />
                  </g>
                  <g>
                    <path
                      className="st11"
                      d="M1854.29,194.51c0.54-3.16-2.62-5.89-5.84-6.67s-6.62-0.32-9.9-0.83c-7.53-1.19-13.39-7.35-20.87-8.81
						c-9.34-1.82-18.17,3.98-26.75,7.97c-1.4,0.65-2.88,1.27-4.44,1.25c-2.75-0.04-4.98-2.02-7.23-3.53
						c-7.46-5-17.96-5.39-25.79-0.96c-3,1.7-5.95,4.12-9.42,3.93c-2.19-0.13-4.14-1.29-6-2.41c-15.58-9.37-34.21-17.8-51.71-12.45
						c-12.56,3.84-22.59,14.37-35.68,15.87c15.41-5.5,32.57-1.62,48.31,2.95c15.73,4.58,32.08,9.88,48.25,7.04
						c8.23-1.44,16.23-4.99,24.57-4.32c5.58,0.45,10.83,2.76,16.39,3.43c16.75,2.01,33.06-11.1,49.47-7.3"
                    />
                  </g>
                </g>
                <path
                  className="st10"
                  d="M1557.58,195.42l-0.69,0.22c0.92-0.16,0.84-0.06,6.76-2.15c-0.62,0.45,1.42-0.39,1.32-0.07
				c0.45-0.37,1.82-0.62,2.7-0.99c-0.05,0.03-0.14,0.09-0.22,0.11c0.06,0.43,2.47-1.27,2.15-0.37c-0.07-0.15,1.06-0.43,0.81-0.5
				c0.51,0,0.43-0.05,0.72,0.05c-0.43-0.17,2.61-0.83,1.51-0.23c1.72-0.86,0.09-0.01,5.38-1.44c-0.14-0.31-0.18-0.24,0.64-0.36
				c-0.22,0.03-0.23,0.09-0.32,0.14c-1.85,0.76,2.76-0.38,0.6,0.03c1.07,0.61,1.89-0.5,6.4-0.82l-0.27,0.21
				c1.11-0.07,0.61-0.32,1.78-0.29c-0.58,0.54,0.43-0.97,14.95,0.99l-0.23,0.01c0.88,0.21,1.05-0.13,1.86,0.15l-0.16,0
				c1.85,0.49,0.56-0.02,10.98,2.42c0-0.15-0.02,0.15,1.57,0.22c0.19,0.13,0.91,0.4,3.25,0.65c-0.05,0.05-0.35,0.02-0.6,0.01
				c1.74,0.2,0.32,0.06,3.31,0.52l-0.16,0.08c1.33,0.26,2.83,0.35,4.34,0.52c-0.46-0.06-0.31-0.09,0.9,0.13
				c0.99-0.44,2.27,1.06,13.43-1.14c1.24-0.48-0.11,0.05,5.78-2.03l-0.07,0.05c3.91-2.44-0.06,0.39,9.15-5.77l-0.12-0.03
				c1.46-1.06,0.79-0.83,10.39-6.31l-0.3,0.32c0.92-0.49,0.7-0.48,6.36-3.03c-0.11,0.09-0.1,0.13-0.3,0.21
				c0.3-0.12,0.35,0,4.18-1.67c0.96-0.12-0.04-0.32,5.7-1.79c-0.16,0.11,0.48-0.05,0.29,0.1c0.6-0.15,1.14-0.33,1-0.42
				c2.09-0.22,1.5-0.37,7.93-1.75c-0.06,0.05-0.13,0.09-0.19,0.12c2.4-0.25,1.42-0.09,6.5-0.71c0,0.02-0.15,0.05-0.29,0.07
				c0.87-0.15-0.62,0.06,1.46-0.49c-0.45,0.31,1.38,0.02,0.66,0.42c0.18-0.03,0.4-0.08,0.36-0.15c0.27,0.01,0.36,0.05,0.09,0.13
				c0.98-0.09,0.63-0.19,1.52-0.22c0,0.05-0.18,0.08-0.27,0.14c0.45-0.11,0.9-0.25,1.66-0.16c-0.05,0.03,0.04,0.07-0.18,0.06
				c1.29,0.03,2.21-0.18,3.63-0.04c-0.4,0.12,0.2,0.29,1.31,0.26c-0.1-0.04-0.2-0.09-0.3-0.12c0.67,0.06,1.26,0,1.83,0.13l-0.5,0.02
				l1.25,0.11l-0.7-0.15c0.68-0.02-0.05-0.24,0.83-0.09c-0.18,0,0.67,0.08,1,0.23l0,0c1.36,0.03,0.41,0.28,3.1,0.43
				c-0.38,0.2,0.94,0.4,1.26,0.28c-0.09-0.01-0.05,0.02-0.23,0c1.03,0.22,1.52,0.34,1.88,0.45l-0.16-0.02
				c1.43,0.38-0.1-0.03,5.16,1.33l-0.25-0.09c0.89-0.09,0.02,0.38,4.77,1.74l-0.2,0c2.5,1.04,1.13,0.45,6.95,3.55
				c-0.42-0.18-0.61-0.29,0.95,0.6c-0.03-0.16-0.12-0.16-0.33-0.4c0.19,0.04,0.57,0.22,0.87,0.41c-0.59-0.29-0.09,0.16,0.11,0.36
				c-0.02-0.06,0.08-0.04,0.08-0.08c0.52,0.58,0.24-0.13,1.03,0.55c-0.42-0.05-0.1-0.04,0.48,0.47c0.5,0.31,1.09,0.47,1.45,0.88
				c-0.22-0.12-0.51-0.33-0.74-0.45c0.38,0.47,0.45,0.47,0.78,0.75l-0.03-0.03l0.76,0.65l-0.18-0.19c0.8,0.16,1.24,0.85,2.17,1.26
				c-0.9-0.4-0.2,0.01-0.35,0.08c0.18,0.19,0.46,0.45,0.35,0.42c0.29,0.1,2.4,1.86,8.96,4.8c-0.11,0.02,0,0.01-0.5,0.04
				c0,0,0.01,0,0.01,0c-0.33,0.05,2.27,0.23,1.05,0.33c0.17-0.02,0.44,0.16,1.21,0.17c-0.13,0-0.33-0.06-0.62-0.25l0.39,0.06
				c-0.27-0.2-0.32-0.13-2.27-0.63c0.33-0.11,0.76-0.03-0.27-0.36c-0.61-0.38,0.05-0.05-1.36-0.62c0.11,0.01-0.02-0.17,0.27,0
				c-0.82-0.52-1.47-0.58-1.71-0.89l0.08,0.03c-2.3-1.46-1.04-0.05-6.52-4.48c0.01,0,0.01,0,0.02-0.01c-0.43-0.25,0,0-1.42-0.97
				c0.08-0.02-1.26-1.06-1.93-1.53l0.04-0.01c-0.24,0.07-0.92-0.42-1.46-0.71c0.08-0.08,0.19-0.06,0.47,0.07
				c-0.05-0.04-0.12-0.15-1.1-0.58c0.48-0.23,0.24,0.03-2.17-1.12c-0.08-0.03-0.31-0.09-0.46-0.2l0.43,0.09
				c-0.33-0.22-0.84-0.25-0.29-0.21c-1.57-0.9-0.55-0.32-4.02-1.94l0,0c-1.27-0.34,0,0-3.71-1.47c-0.34-0.39-0.26-0.13-4-1.29
				c0.59,0.08-0.18-0.13,0.6,0.11c-0.72-0.25-0.87-0.38-1.69-0.48c0.3-0.04-0.51-0.33-0.88-0.37c0.17,0.06,0.33,0.15,0.5,0.23
				c-0.39-0.11-0.75-0.17-1.1-0.26l0.23-0.12c-1.55-0.33-3.43-0.86-7.12-1.06c0.68,0.51-1.83-0.63-1.47-0.23
				c-0.91-0.19-1.44-0.18-2.39-0.25c0.01,0.1-2.65,0.37-2.07-0.18c-0.87,0.08-1.62-0.13-2.12-0.17l0.46-0.03
				c-0.27,0.02-0.5,0-0.73,0.01c0.09,0.03,0.17,0.08,0.26,0.12c-0.27-0.03-0.5-0.01-0.49-0.06c-0.6,0.31-0.05,0.36-1.55,0.27
				c1.5-0.02,0.67-0.62-1.35,0c0.14-0.09-0.27-0.16-0.45-0.13c0.35,0-0.15,0.25-1.34,0.31l0.43,0.02c-0.66,0.28-1.94,0.01-4.95,0.23
				c-0.75,0.43,0.12,0.03-3.91,0.79l0.45-0.03c-0.16,0.15-0.84,0.17-1.28,0.3c-1.46,0.04-0.01-0.05-4.42,1.09
				c0.29-0.18-0.2-0.13,0.48-0.38c-0.35,0.09-0.67,0.15-0.66,0.22c0.34-0.08-0.91,0.07-2.07,0.83c-1.27,0.15-0.07-0.26-6.25,1.84
				c0.02-0.02,0.01-0.03,0.02-0.04c-2.02,0.9-0.01-0.06-3.3,1.17l0.16-0.08c-0.83,0.33-0.16-0.11-4.54,2.37c0-0.05,0-0.07,0-0.1
				c-0.09,0.15-0.41,0.41-1.35,0.49l0.69-0.33c-0.43,0.01-0.9,0.42-1.27,0.58l0.01-0.09c-1.02,0.59-0.94,0.77-2.05,1.37l0.14-0.01
				c-1.43,1.04-0.86-0.07-3.33,1.98c0.21-0.19,0.22-0.33,1.57-1.29c-0.4,0.21-1.33,0.76-1.47,0.99c0.14-0.11,0.31-0.28,0.48-0.35
				c-1.87,1.66-0.72,0.31-8.54,5.5c0.7-0.13-0.43,0.31-0.17,0.5c-0.18,0.14,0.01,0-0.52,0.35c0,0,0.01,0,0.02-0.01
				c-0.52,0.29-1.05,0.4-1.92,0.63c0.17,0.03-0.19,0.3-0.66,0.55l0.71-0.27c-1.02,0.82-1.08,0.37-2.13,1.15l0.31-0.28
				c-0.59,0.12-0.77,0.88-7.21,3.06l0.1-0.1c-0.48,0.15-0.16,0.11-2.03,0.5l0.32-0.14c-0.82,0.06-0.53,0.19-3.64,0.72
				c-0.02-0.23-1.24-0.18-1.85-0.21l0.02-0.03c-1.64-0.21-1.99,0.81-9.97-0.69c-2.03-0.06-1.7-0.26-9.57-1.61l0.05-0.13
				c-1.09-0.27-1.63-0.4-3-0.65l0.3-0.04c-2.65-0.19-1.23-0.09-5.96-0.98c0.03-0.03,0.08-0.08,0.3-0.04
				c-0.63-0.14-2.44-0.57-2.25-0.25c0.01-0.18-1.39-0.27-2.14-0.28l0.13-0.12c-0.08,0.04-0.96,0.01-2.81-0.12l0.32-0.05
				c-1.05,0.12-0.32-0.21-5.93-0.46c0.94,0.04,1.7,0.08,1.63,0.09c0.01-0.13-0.76-0.18-0.14-0.33c-0.63,0.03,0.02,0.09-2.52,0.14
				l0.08-0.01c-0.75,0.03-0.71-0.04-1.34-0.05c0.08,0.01,0.13,0.02,0.15,0.04c-0.12,0.02,0-0.03-0.47,0.03
				c0.03-0.01,0.03-0.08,0.22-0.08c-0.22,0-0.5,0-0.9,0.01c0.04,0.14-0.46,0.14,0.01,0.23c-0.11-0.01,0.19-0.01,0.49-0.06
				l-0.03,0.01c0.25-0.02,0.58-0.01,0.94-0.02c-2.69,0.53-0.64-0.41-7.17,0.52c0.19-0.05,0.41-0.07,0.51-0.12
				c-0.06-0.06-1.64,0.54-2.75,0.42l0.14-0.08c-2.28,0.02-3.52,1.04-6.02,1.16c0.17-0.01,0.26-0.02,0.3,0.01
				c-3.55,0.68,1.4-0.6-14.04,3.6l0.21,0.04c-0.52,0.13-1.6,0.54-1.55,0.41c-0.32,0.37-4.76,0.9-3.58,1.3
				C1555.39,195.32,1555.44,196.08,1557.58,195.42z M1692.68,170.18l-0.93,0.28c0.45-0.05,0.94-0.18,1.21-0.19
				c-0.44,0.15-0.4,0.11-0.17,0.19c-0.79,0.07-1.17,0.01-1.56-0.03L1692.68,170.18z M1592.22,188.68c-0.08,0.03,0.24,0.07,0.47,0.11
				C1592.5,188.8,1591.96,188.83,1592.22,188.68z M1591.16,188.76c-0.16,0.01,0,0-0.39,0.01
				C1590.85,188.77,1590.96,188.77,1591.16,188.76z"
                />
                <path
                  className="st10"
                  d="M1756.25,182.81l-0.27,0.05c0.2,0.02,0.15,0.02,0.8-0.27c0.08,0.51,0.38-0.29,0.41,0.13
				c0.15-0.1,0.43-0.09,0.49-0.22c0.18,0.11,0.54-0.11,0.56,0.11c0.04-0.06,0.26-0.21,0.41-0.24c-0.24,0.3,0.56-0.06,0.52,0.23
				c0.17-0.26,0.71-0.19,1.06-0.36c-0.02,0.02-0.05,0.06-0.09,0.06c0.2,0,0.27,0.06,0.4,0.09c-0.23,0.02,0.88-0.8,0.44,0
				c-0.03-0.16,0.41-0.21,0.31-0.33c0.2,0.1,0.17,0.04,0.28,0.19c-0.17-0.24,1.03-0.3,0.59,0.07c0.56-0.45,0.06,0.01,2.08-0.5
				c-0.03-0.14-0.15-0.33,0.25-0.27c-0.08,0-0.09,0.05-0.13,0.09c-0.49,0.1,0.78,0.26,0.23,0.11c0.27,0.63,1.11-0.12,2.46-0.12
				l-0.11,0.19c1.25-0.3,2.33-0.01,6.37,0.28l-0.09,0.03c0.33,0.11,0.43-0.24,0.72-0.06l-0.06,0.02c0.55,0.19,0.26-0.27,4.08,1.05
				l-0.05,0.15c0.04-0.05,0.2-0.05,0.55,0.2c0.01-0.27,0.04,0.31,1.16,0.41c-0.04,0.05-0.15,0.02-0.24,0.02
				c0.38,0.01-0.01,0.18,0.34,0.19c-0.02-0.05,0.06-0.07,0.08-0.1c0.11,0.16,0.44,0.32,0.7,0.41l-0.1,0.07
				c0.42,0.21,0.89,0.44,1.35,0.72c-0.01-0.09,0.29-0.3,0.17,0c0.22,0-0.24-0.04,0.15,0.25c0.67,0.61,0.11-0.25,3.11,2.25
				c0.5,0.26,0.66,0.38,1.43,0.42c1.04-0.03,0.04,0.02,2.5-0.42l-0.02,0.02c2.66-0.83-2.34,0.53,8.24-2.72l-0.11,0.2
				c0.82-0.4,1.76-0.44,2.58-0.8c-0.04,0.05-0.03,0.1-0.12,0.11c0.21,0.08,0.67-0.13,0.65-0.23c0.18,0.08,0.27,0.17,1.02-0.18
				c0.3-0.01,0.31-0.01,1.45-0.41c0.02,0.23,0.6,0.08,0.78-0.06c-0.06,0.08,0.19,0.04,0.13,0.15c0.23-0.04,0.44-0.12,0.38-0.23
				c0.79,0.11,0.54,0.04,3.05-0.57c-0.57,0.6-1.17,0.28,2.41-0.2c0,0.02-0.05,0.05-0.11,0.07c0.28-0.14-0.04,0.03,0.53-0.48
				c-0.15,0.31,0.53,0.02,0.28,0.42c0.07-0.03,0.15-0.08,0.13-0.15c0.1,0.01,0.14,0.05,0.04,0.13c0.37-0.09,0.23-0.18,0.57-0.2
				c0,0.05-0.06,0.07-0.1,0.13c0.16-0.11,0.33-0.21,0.62-0.19c-0.02,0.03,0.02,0.07-0.07,0.07c0.49-0.04,0.83-0.31,1.38-0.25
				c-0.15,0.22,0.19,0.06,0.23,0.24l0.29-0.07l-0.12-0.08c0.26-0.02,0.48-0.15,0.7-0.1l-0.19,0.08l0.48-0.05l-0.27-0.06
				c0.26-0.1-0.04-0.24,0.31-0.19c-0.07,0.02,0.26,0,0.39,0.1c-0.05,0.08,0,0,0.44,0.16c0.12-0.16,0.6-0.06,0.76-0.23
				c-0.17,0.15,0.3,0.31,0.49,0.03c-0.03,0.01-0.02,0.03-0.09,0.05c0.47-0.01,0.66,0.03,0.8,0.07l-0.12,0.02
				c0.55,0.06-0.06,0.02,2.04-0.05l-0.1-0.02c0.08-0.27,0.4,0.14,0.68,0.03c0.07,0.28-0.08,0.09,1.26,0.03l-0.07,0.06
				c1-0.02,0.82,0.14,2.98,0.25c-0.17,0.05-0.28,0.06,0.43,0.04c-0.03-0.12-0.07-0.08-0.17-0.18c0.08-0.06,0.24-0.1,0.38-0.09
				c-0.26,0.05-0.02,0.18,0.08,0.26c-0.01-0.04,0.03-0.07,0.02-0.11c0.26,0.29,0.02-0.1,0.42,0.01c-0.09,0.02,0.77-0.12,0.27,0.11
				c0.22,0.02,0.47-0.2,0.66-0.04c-0.1,0.03-0.24,0.01-0.33,0.03c0.2,0.18,0.23,0.15,0.39,0.19l-0.02-0.01l0.37,0.12l-0.09-0.05
				c0.32-0.31,0.57,0.01,0.98-0.17c-0.19,0.04-0.29,0.42,0.61,0.42c-0.1,0.08,1.5-0.01,3.28,0.39c-0.27,0.23-0.41,0.17,0.19,0.24
				c0.23,0.01,0.6,0.04,0.7-0.07l-0.14-0.08c0.01,0.07-0.12,0.3-0.32-0.02l0.15,0c-0.09-0.31-0.59-0.07-0.88-0.15
				c0.29-0.15,0.17-0.39-0.66-0.38c0.05-0.04-0.01-0.15,0.11-0.1c-0.35-0.18-0.62,0.06-0.74-0.12l0.03-0.01
				c-0.88-0.37-0.97,0.14-3.01-0.45c0.04-0.19,0.12,0.1-0.66-0.02c0.03-0.06-0.61-0.18-0.91-0.28l0.01-0.03
				c-0.08,0.17-0.4,0.11-0.63,0.13c0.02-0.11,0.07-0.15,0.2-0.19c-0.03,0.03-0.03-0.08-0.49,0.07l0.07-0.13
				c-0.29-0.02-0.6,0.11-0.96-0.01c-0.05-0.05,0.06,0.28-0.24,0.23l0.17-0.1c-0.08,0.04-0.52-0.04-0.13-0.07
				c-0.67-0.16-0.28-0.07-1.7-0.07l0,0c-0.33,0.2-1.29-0.3-3.12-0.19c0.23-0.08-0.08-0.08,0.23-0.06c-0.29-0.05-0.36-0.13-0.67,0
				c0.11-0.12-0.22-0.16-0.36-0.13l0.2,0.09c-0.16,0.02-0.29,0-0.43-0.01l0.08-0.17c-0.65-0.09-1.36-0.02-2.75,0.17
				c0.29,0.38-0.71-0.38-0.57-0.05c-0.36-0.08-0.55,0.02-0.92-0.02c0.09,0.07-1.05,0.53-0.8-0.06c-0.32,0.13-0.62-0.04-0.82-0.05
				l0.17-0.06c-0.1,0.03-0.19,0.04-0.28,0.04l0.11,0.11c-0.1-0.01-0.19-0.01-0.19-0.06c-0.18,0.16,0.07,0.42-0.58,0.25
				c0.42,0.02-0.02-0.14,0.27-0.2c-0.07,0.01-0.21,0.03-0.19-0.04c-0.42-0.1-0.17,0.2-0.6,0.23c0.05-0.09-0.11-0.16-0.18-0.13
				c0.13-0.02,0.04,0.22-0.49,0.31l0.17,0.02c-0.29,0.3-0.72-0.07-1.91-0.07c0.11,0.11,0.13,0.07-0.1,0.22
				c0.19-0.05,0.37-0.11,0.56-0.15l-0.35,0.21c0.17-0.02,0.35-0.11,0.46-0.1c-0.16,0.11-0.15,0.08-0.06,0.17
				c-0.88,0.08-0.41-0.53-2,0.02l0.18,0.03c-0.06,0.13-0.32,0.06-0.49,0.13c0-0.1-0.11-0.12-0.19-0.17
				c0.02,0.07-0.39,0.04-0.41,0.25c-0.5-0.1-0.26-0.19-1.11,0.3c0.11-0.13-0.09-0.16,0.17-0.29c-0.14,0.02-0.26,0.03-0.25,0.09
				c0.2-0.07-0.51-0.01-0.78,0.44c-0.34-0.16-0.6-0.14-2.48,0.31c0.01-0.03-0.01-0.05,0.04-0.05c-0.87,0.3-0.02-0.04-1.37,0.17
				l0.06-0.03c-0.33,0.01-0.15,0-1.82,0.78c0-0.05,0-0.08,0-0.11c-0.03,0.11-0.15,0.26-0.56,0.02l0.28-0.08
				c-0.19-0.14-0.37,0.1-0.52,0.1l0-0.08c-0.43,0.13-0.38,0.34-0.85,0.45l0.06,0.04c-0.59,0.34-0.3-0.17-1.4,0.47
				c0.13-0.19,0.46-0.36,0.65-0.51c-0.17,0.05-0.56,0.18-0.62,0.29c0.06-0.04,0.13-0.11,0.21-0.1c-1.3,0.66-0.03,0.08-3.77,0.99
				c0.3,0.11,0.04,0.27-0.28,0.43c0,0,0,0,0.01,0c-0.24,0.03-0.48-0.1-0.89-0.24c0.08,0.09-0.06,0.19-0.27,0.22l0.32,0.03
				c-0.42,0.33-0.5-0.09-0.92,0.24l0.12-0.15c-0.27-0.13-0.4,0.58-2.56,0.84l0.05-0.07c-0.3,0-0.07-0.07-0.63-0.16l0.14-0.03
				c-0.38-0.31-0.8-0.26-1.21-0.54c0.14-0.16-0.16-0.53-0.33-0.71l0.03-0.02c-0.76-0.66-0.14,0.2-3.35-2.21
				c0.01,0.05-0.22,0.2-0.51-0.09c0.08,0.05-0.28-0.65-0.71-0.22c-0.38-0.45-0.11-0.02-3.48-1.48l0.13-0.07
				c-1.01,0.1-0.41,0.09-2.35-0.31c0.01-0.03,0.04-0.09,0.13-0.08c-0.25-0.04-0.94-0.29-0.89,0.01c0.02-0.19-0.54-0.1-0.83-0.06
				l0.06-0.13c-0.01,0.03-0.36,0.1-1.1,0.15l0.13-0.08c-0.37-0.01-0.18-0.09-1.24-0.08c-0.45-0.2,0,0.16-1.04-0.1
				c0.36,0.02,0.65,0.04,0.62,0.05c0.01-0.13-0.29-0.16-0.04-0.33c-0.25,0.04,0.02,0.1-0.97,0.16l0.03-0.01
				c-0.4,0.02-0.22-0.09-0.9-0.1c0.01,0.14-0.18,0.12,0,0.23c-0.04-0.01,0.07,0,0.19-0.04c0,0-0.01,0.01-0.01,0.01
				c0.1,0,0.22-0.01,0.36-0.01c-0.99,0.37-0.61-0.28-2.75,0.09c0.33-0.15,0.33-0.09-0.87,0.04l0.06-0.06
				c-0.88-0.26-1.36,0.56-2.34,0.24c0.07,0.02,0.1,0.03,0.12,0.06c-0.96-0.08,0.04,0.29-3.88,0.3c-0.61,0.36-0.16,0.29-1.64,0.26
				l0.09,0.09C1756.3,181.9,1756.64,181.47,1756.25,182.81z M1769.69,181.52c-0.03,0.03,0.09,0.06,0.18,0.1
				C1769.8,181.63,1769.55,181.67,1769.69,181.52z M1769.29,181.61c-0.06,0.01,0,0-0.15,0
				C1769.17,181.61,1769.21,181.61,1769.29,181.61z M1768.59,180.68c0.01-0.03,0,0,0.04-0.07
				C1768.71,180.6,1768.89,180.67,1768.59,180.68z"
                />
                <path
                  className="st10"
                  d="M1754.72,228.36c-0.44,0.09-1.69,0.24-1.49,0.48c-0.04-0.18-0.96,0.12-1.46,0.27l0.06-0.15
				c-0.04,0.06-0.62,0.23-1.9,0.43l0.21-0.1c-0.73,0.05-0.17-0.03-2.23,0.08c-0.83-0.17,0.03,0.17-1.87-0.16
				c0.64,0.06,1.07,0.11,1.12,0.11c0-0.12-0.51-0.19-0.09-0.33c-0.42,0.01,0.01,0.1-1.72,0.06l0.05-0.01
				c-0.7-0.04-0.38-0.11-1.58-0.3c0,0.14-0.34,0.07-0.04,0.23c-0.08-0.02,0.13,0.02,0.34,0.02l-0.02,0.01
				c0.17,0.02,0.39,0.06,0.65,0.09c-1.79,0.03-1.24-0.31-4.86-1c0.63,0.04,0.58,0.1-1.49-0.48l0.12-0.02
				c-1.34-0.81-2.55-0.49-3.92-1.62c0.09,0.08,0.14,0.12,0.15,0.16c-1.66-1.59-0.1,1.01-7.4-5.63c0.48-0.13,1.02-0.28,1.13-0.41
				l-0.34,0.02c0.03,0.1-0.1,0.32-0.7,0.18l0.32-0.1c-0.04-0.23-0.37,0.33-0.96-0.2l0.02,0.17c-0.23-0.29-0.81-0.86-0.68-0.89
				c-0.56-0.12-1.51-2.91-1.76-2c-0.23-0.45-0.88-0.13,0.05,1.18l-0.31-0.42c0.2,0.74,1.72,1.58,1.37,1.84
				c0.15,0.16,0.26,0.16,0.53,0.46c-0.15,0.08-0.93,0.52-0.62,0.1c-0.61,0.02-0.01-0.11-1.29,0.22c0.08-0.06-0.1-0.13,0.19-0.15
				c-0.86,0.04-1.32,0.4-1.67,0.3l0.07-0.02c-2.38,0.46-2.53,0.65-6.82,1.07c0.02-0.21,0.21,0.07-1.47,0.26
				c0.04-0.07-1.43,0.07-2.14,0.09l0.02-0.03c-0.11,0.2-0.84,0.25-1.36,0.35c0.01-0.11,0.1-0.17,0.38-0.25
				c-0.07,0.03-0.04-0.07-1.08,0.22c0.38-0.47,0.25-0.13-2.1,0.34c-0.08,0.02-0.27,0.08-0.43,0.07l0.35-0.15
				c-0.36-0.02-0.71,0.22-0.31-0.03c-1.67,0.03-0.72,0.03-3.86,0.28l0,0c-1.12,0.22,0,0-3.45,0.05c-0.33-0.26-0.25,0.06-3.63-0.13
				c0.51-0.06-0.17-0.08,0.53-0.04c-0.66-0.07-0.8-0.16-1.52-0.05c0.25-0.12-0.49-0.18-0.8-0.16l0.46,0.11
				c-0.35,0-0.66-0.01-0.97-0.06l0.18-0.15c-1.36-0.26-2.98-0.22-6.14-0.64c0.26,0.46-0.76-0.47-1.42-0.33
				c-0.65-0.2-1.09-0.26-1.81-0.48c0.02,0.11-2.34-0.11-1.7-0.52c-0.74-0.1-1.3-0.5-1.69-0.65l0.38,0.08
				c-0.23-0.05-0.41-0.11-0.59-0.17l0.17,0.18c-0.21-0.09-0.39-0.15-0.37-0.2c-0.57,0.1-0.15,0.33-1.33-0.22
				c1.2,0.42,0.75-0.31-1.05-0.48c0.14-0.03-0.14-0.24-0.29-0.29c0.27,0.13-0.2,0.17-1.16-0.22l0.32,0.18
				c-0.64-0.01-1.48-0.73-3.66-2.13c0.14,0.21,0.19,0.21-0.35,0.04l1.16,0.55l-0.8-0.25c0.34,0.19,0.75,0.33,0.94,0.46
				c-0.39-0.1-0.34-0.11-0.22,0.07c-1.51-0.96-0.72-0.87-3.68-2.55l0.29,0.26c-0.2,0.01-0.61-0.41-0.96-0.59
				c-0.88-0.88,0.02-0.01-3.29-2.23c0.3,0.06-0.03-0.24,0.56,0.05c-0.27-0.18-0.52-0.35-0.56-0.29c0.33,0.19-0.74-0.55-1.97-0.46
				c-0.98-0.02-2.45-0.84-5.51,1.32c0.01-0.02,0-0.03,0.01-0.04c-1.55,1.13-0.04-0.05-2.6,1.57l0.12-0.1
				c-0.71,0.29-0.11,0.22-3.64,2.49c-0.01-0.05-0.01-0.07-0.01-0.1c-0.06,0.15-0.32,0.41-1.15,0.43c0.2-0.1,0.4-0.18,0.59-0.28
				c-0.38-0.01-0.77,0.36-1.1,0.47l0.01-0.09c-0.91,0.42-0.83,0.64-1.85,1.03l0.13,0.02c-1.36,0.73-0.7-0.19-3.14,1.17
				c0.2-0.15,0.29-0.14,1.54-0.84c-0.36,0.14-1.23,0.46-1.4,0.59c0.14-0.06,0.32-0.16,0.47-0.18c-3.4,1.2-0.76,0.39-8.55,1.68
				c0.6,0.24-0.46,0.05-0.33,0.35c-0.19,0.03,0.01,0.01-0.55,0.05c0,0,0,0,0.02,0c-0.52,0-0.97-0.16-1.73-0.38
				c0.12,0.11-0.27,0.17-0.73,0.14l0.65,0.12c-1.12,0.2-0.98-0.22-2.11-0.02l0.36-0.11c-1.23-0.1-0.27,0.28-6.88-0.19l0.11-0.06
				c-0.45-0.01-0.16-0.06-1.82-0.21l0.31-0.04c-1.32-0.31-2.08-0.23-3.22-0.41c0.05-0.19-0.95-0.57-1.46-0.75l0.03-0.02
				c-1.91-0.93-0.47,0.86-8.01-3.28c-1.64-0.65-1.3-0.69-7.18-4.36l0.1-0.1c-0.77-0.59-1.16-0.89-2.15-1.57l0.26,0.06
				c-2.05-1.06-0.95-0.47-4.38-2.91c0.03-0.01,0.1-0.04,0.26,0.06c-0.45-0.33-1.69-1.37-1.69-1.02c0.09-0.16-1-0.72-1.59-1
				l0.16-0.06c-0.09,0.01-0.76-0.34-2.19-1.1l0.28,0.07c-0.81-0.44-0.12-0.19-2.44-1.4c-0.85-0.68-0.02,0.16-2.08-1.16
				c0.74,0.36,1.33,0.68,1.26,0.66c0.06-0.11-0.51-0.45,0.03-0.36c-0.51-0.22-0.02,0.1-2.06-0.8l0.07,0.02
				c-0.83-0.34-0.43-0.27-1.86-0.89c-0.03,0.14-0.42-0.05-0.09,0.22c-0.09-0.05,0.15,0.06,0.41,0.13l-0.03,0
				c0.2,0.08,0.47,0.19,0.76,0.32c-1.52-0.32-2.76-1.48-5.98-1.95c0.17,0.01,0.36,0.06,0.46,0.05c-0.03-0.08-1.52,0.02-2.4-0.43
				l0.14-0.03c-1.91-0.62-3.21-0.01-5.34-0.5c0.15,0.03,0.22,0.05,0.25,0.08c-3-0.13,0.97-0.45-12.63,0.48l0.18,0.08
				c-0.46,0.03-1.45,0.23-1.39,0.1c-0.32,0.3-4.22-0.02-3.27,0.6c-0.6-0.01-0.65,0.73,1.28,0.49l-0.62,0.09
				c0.84,0.02,0.7,0.09,6.12-0.81c-0.6,0.32,1.28-0.1,1.14,0.2c0.44-0.27,1.66-0.24,2.46-0.41c-0.04,0.02-0.13,0.06-0.2,0.06
				c-0.03,0.42,2.38-0.71,1.89,0.1c-0.03-0.16,0.97-0.19,0.78-0.32c0.43,0.1,0.37,0.05,0.6,0.21c-0.33-0.27,2.37-0.2,1.32,0.12
				c1.59-0.39,0.09-0.05,4.81-0.05c-0.04-0.31-0.11-0.3,0.63-0.17c-0.19-0.03-0.21,0.02-0.31,0.04c-1.77,0.2,2.47,0.44,0.48,0.2
				c0.67,0.84,2.35,0.14,5.46,1.14l-0.3,0.11c0.91,0.28,0.61-0.12,1.52,0.34c-0.86,0.1,1.75-0.16,11.42,6.21l-0.19-0.08
				c0.61,0.49,0.9,0.25,1.42,0.79l-0.13-0.06c0.94,0.8,1.53,1.07,7.85,5.87c0.06-0.14-0.07,0.14,1.2,0.7
				c0.1,0.18,0.59,0.65,2.42,1.59c-0.06,0.03-0.3-0.09-0.5-0.17c1.36,0.71,0.23,0.14,2.54,1.46l-0.16,0.03
				c1.03,0.59,2.22,1.15,3.45,1.67c-0.37-0.17-0.21-0.19,0.71,0.37c1.78,0.56-0.53,0.6,11.4,2.85c0.58,0.04,0.16-0.03,5.29,0.47
				l-0.07,0.01c4.01-0.38-0.22,0.45,9.44-0.73l-0.09-0.07c2.18-0.65,0.54,0.03,10.22-3.48l-0.27,0.3c1.27-0.83-0.42,0.56,5.39-3.22
				c-0.08,0.1-0.06,0.14-0.23,0.24c0.25-0.12,0.29-0.06,3.25-2.15c0.63-0.14,0.47-0.47,3-1.04c-0.03,0.2,1.13,0.23,1.57,0.33
				c-0.15,0.01,0.32,0.2,0.13,0.23c0.44,0.17,0.86,0.38,0.84,0.23c2.11,1.72,1.49,1.26,5.88,3.97c-0.14-0.03-0.24-0.07-0.35-0.1
				c1.84,1.03,1.05,0.76,5.31,2.71c-0.01,0.02-0.14-0.01-0.27-0.05c0.75,0.21-0.51-0.18,1.37,0.13c-0.49,0.11,1.09,0.55,0.35,0.64
				c0.15,0.05,0.36,0.08,0.35,0c0.22,0.09,0.28,0.16,0.02,0.15c0.85,0.22,0.59,0.02,1.34,0.28c-0.02,0.05-0.18,0.01-0.27,0.04
				c0.41,0.04,0.82,0.07,1.44,0.32c-0.05,0.02,0.01,0.08-0.17,0.01c0.64,0.2,0.41,0.08,3.12,0.71c-0.21,0.06-0.33,0.19,1.09,0.47
				l-0.24-0.15c0.57,0.14,1.1,0.15,1.59,0.28l-0.45,0l1.1,0.15l-0.6-0.17c0.61,0-0.02-0.24,0.74-0.06c-0.16-0.01,0.58,0.1,0.86,0.26
				l0,0c1.22,0.03,0.31,0.44,2.72,0.36c-0.33,0.23,0.85,0.3,1.12,0.15c-0.08,0-0.04,0.03-0.2,0.03c1.16,0.07,1.56,0.16,1.92,0.25
				l-0.38-0.01c1.36,0.15-0.09,0.03,4.68,0.15l-0.23-0.02c1.42-0.23,1.06,0.45,4.45-0.06l-0.16,0.08c2.4-0.22,1.88,0.09,6.85-0.42
				c-0.38,0.11-0.58,0.13,0.98-0.09c-0.11-0.11-0.17-0.06-0.45-0.12c0.15-0.08,0.51-0.16,0.82-0.2c-0.56,0.14,0.02,0.18,0.28,0.22
				c-0.04-0.03,0.03-0.08,0.01-0.11c0.68,0.13,0.09-0.24,1-0.21c-0.3,0.23-0.09,0.03,0.6,0.07c0.51-0.07,0.97-0.37,1.45-0.29
				c-0.21,0.06-0.52,0.1-0.73,0.16c0.52,0.1,0.58,0.05,0.95,0.03l-0.04,0l0.87-0.05l-0.22-0.01c0.59-0.44,1.29-0.25,2.11-0.6
				c-0.8,0.33-0.12,0.14-0.16,0.3c0.22,0.01,0.56,0.01,0.48,0.06c-0.55,0.36,5.28-1.71,8.3-1.47c0,0,0,0,0,0
				c0.6-0.07-0.17,0.05,1.43,1.07c0.11,0.13-0.64,0.1,2.22,2c-0.04-0.01-0.13-0.02-0.17-0.07c-0.28,0.33,2,0.73,1.15,1.09
				c0.06-0.13,0.77,0.33,0.71,0.13c0.24,0.29,0.23,0.21,0.29,0.45c-0.09-0.35,1.79,0.87,0.86,0.7c1.55,0.19-0.1,0.21,3.73,1.53
				c0.05-0.26-0.01-0.33,0.55-0.03c-0.15-0.06-0.18-0.02-0.26-0.02c-1.35-0.16,1.74,0.86,0.34,0.27c0.28,0.82,1.91,0.72,4.41,1.41
				l-0.25,0.14c2.51-0.32,0.24,1.55,12.07,0.44l-0.15,0.07c0.66-0.03,0.67-0.42,1.28-0.41l-0.11,0.05c0.57,0-0.09-0.27,7.62-2.58
				c-0.1-0.11,0.11,0.11,0.94-0.65c0.18,0.01,0.71-0.15,2.01-1.26c0.01,0.07-0.16,0.21-0.28,0.34c0.96-0.8,0.19-0.1,1.9-1.46
				l-0.01,0.15c0.8-0.55,1.52-1.36,2.32-2.09c-0.24,0.23-0.21,0.1,0.48-0.44c1.43-1.43-0.09,0.15,4.31-5.79
				c0.47-0.81,0.54-1.07,1-2.16c0.29-1.02-0.03,0.03,1.64-4.01l-0.01,0.06c1.09-2.2-0.59-1.63,4.92-0.11l-0.03-0.1
				c1.59,0.43,0.54-0.19,8.52,1.75l-0.32,0.11c1.68,0.08,3.39,0.48,5.05,0.43c-0.1,0.03-0.11,0.08-0.27,0.06
				c-0.08-0.02,1.05,0.28,3.24,0.05c0.57-0.01,0.51,0.16,2.82-0.26c-0.03,0.22,1.1,0.09,1.47-0.07c-0.12,0.09,0.35,0.01,0.21,0.13
				c0.44-0.08,0.84-0.19,0.75-0.29c2.53-0.45,1.49,0.07,5.6-1.74c-0.09,0.09-0.17,0.16-0.24,0.22c1.54-0.75,1.08-0.25,4.27-2.39
				c0.01,0.02-0.07,0.1-0.14,0.17c0.44-0.45-0.32,0.28,0.61-0.98c-0.08,0.44,0.82-0.55,0.63,0.07c0.09-0.1,0.19-0.24,0.12-0.27
				c0.16-0.1,0.24-0.11,0.13,0.07c0.51-0.5,0.25-0.41,0.74-0.83c0.03,0.04-0.05,0.14-0.07,0.23c0.18-0.28,0.37-0.57,0.79-0.89
				c0,0.03,0.04,0.03,0.01,0.08c0.64-0.61,0.96-1.2,1.74-1.79c-0.04,0.19,0.03,0.33,0.83-0.47l-0.22,0.08
				c0.35-0.31,0.58-0.69,0.93-0.9l-0.21,0.29l0.65-0.59l-0.43,0.26c0.3-0.38-0.19-0.15,0.33-0.51c-0.09,0.09,0.37-0.3,0.63-0.36l0,0
				c0.71-0.72,0.39,0.09,1.78-1.29c-0.06,0.35,0.69-0.2,0.79-0.45c-0.05,0.04-0.01,0.04-0.11,0.12c0.67-0.36,0.99-0.51,1.24-0.61
				l-0.11,0.08c0.94-0.43-0.07,0.03,3.38-1.58l-0.18,0.05c1.01-0.57,0.81,0.07,3.4-1.07l-0.11,0.09c1.83-0.48,1.03-0.3,5.42-0.91
				c-0.22,0.09-0.29,0.1,0.79-0.08c-0.09-0.11-0.14-0.06-0.36-0.13c0.12-0.08,0.41-0.15,0.66-0.17c-0.45,0.11,0.02,0.18,0.22,0.23
				c-0.04-0.03,0.03-0.08,0.01-0.11c0.55,0.16,0.07-0.24,0.81-0.16l-0.03,0.06c0.15-0.03,1.22-0.25,0.51,0.05
				c0.41-0.03,0.8-0.29,1.18-0.17c-0.17,0.04-0.43,0.06-0.6,0.1c0.41,0.14,0.46,0.1,0.76,0.11l-0.03,0l0.7,0.04l-0.18-0.04
				c0.51-0.36,1.05-0.08,1.75-0.31c-0.68,0.22-0.11,0.13-0.16,0.28c0.18,0.04,0.45,0.08,0.37,0.13c0.23-0.03,0.28-0.21,1.29,0.06
				c-1.32-0.22,2.17-0.2,5.82-0.06c-0.5,0.28-0.66,0.19,0.42,0.23c0.43-0.01,1.12,0,1.26-0.13l-0.27-0.07c0.01,0.1-0.15,0.29-0.59,0
				l0.27-0.01c-0.26-0.3-1.1-0.03-1.64-0.08c0.28-0.24,0.6-0.28-0.25-0.25c-0.48-0.12,0-0.11-1.06-0.07
				c0.08-0.04-0.06-0.15,0.18-0.11c-0.68-0.16-1.12,0.1-1.37-0.07l0.06-0.01c-1.99-0.26-1.88,0.22-5.61-0.07
				c0.03-0.22,0.2,0.1-1.2,0.09c0.04-0.06-1.16-0.07-1.74-0.08l0.01-0.03c-0.1,0.19-0.69,0.2-1.12,0.28
				c0.01-0.11,0.08-0.16,0.29-0.22c-0.05,0-0.14-0.05-0.86,0.17c0.28-0.47,0.21-0.15-1.69,0.37c-0.06,0.02-0.21,0.08-0.34,0.07
				l0.27-0.15c-0.32-0.01-0.15,0.11-0.4,0.14c-0.01-0.13-0.39-0.12,0.14-0.17c-1.33,0.12-0.56,0.04-3.09,0.63l0,0
				c-0.62,0.43-1.4-0.07-5.53,1.71c0.35-0.25-0.16-0.01,0.37-0.24c-0.51,0.19-0.66,0.17-1.13,0.53c0.13-0.2-0.44,0.03-0.66,0.17
				l0.39-0.08c-0.26,0.13-0.49,0.24-0.72,0.36l0.05-0.22c-1.01,0.52-2.27,1.16-4.21,2.91c0.66-0.01-1.28,0.54-0.85,0.62
				c-0.55,0.36-0.77,0.67-1.31,1.08c0.1,0.03-1.08,1.61-1.15,0.9c-0.37,0.49-0.89,0.7-1.16,0.91l0.21-0.25
				c-0.12,0.15-0.24,0.25-0.36,0.35l0.21-0.03c-0.15,0.11-0.27,0.21-0.3,0.17c-0.14,0.44,0.24,0.3-0.68,0.85
				c0.6-0.43-0.08-0.11,0.27-0.46c-0.72,0.45-0.37,0.11-1.03,0.99c0.02-0.13-0.24-0.03-0.33,0.06c0.19-0.14,0.1,0.24-0.6,0.75
				c0.09-0.04,0.17-0.09,0.26-0.14c-0.28,0.45-1.13,0.69-3.06,1.46c0.21,0.03,0.23-0.01-0.12,0.28l0.89-0.51l-0.52,0.44
				c0.28-0.13,0.56-0.35,0.74-0.41c-0.24,0.22-0.22,0.18-0.04,0.2c-1.37,0.56-0.92-0.06-3.4,1.19l0.31-0.06
				c-0.08,0.15-0.55,0.22-0.83,0.37c-0.98,0,0.01-0.04-3.06,0.82c0.2-0.14-0.14-0.16,0.34-0.31c-0.25,0.04-0.47,0.06-0.46,0.13
				c0.28-0.07-0.7-0.01-1.48,0.53c-0.91-0.19,0.03-0.08-4.55,0.03c0.03-0.03,0-0.05,0.1-0.04c-1.68,0.05-0.02-0.03-2.49-0.23
				l0.13-0.01c-0.6-0.15-0.2,0.03-3.58-0.07c0.02-0.05,0.03-0.07,0.05-0.09c-0.11,0.09-0.4,0.16-0.97-0.26
				c0.18,0.03,0.35,0.06,0.53,0.08c-0.24-0.21-0.68-0.11-0.96-0.18l0.05-0.07c-0.81-0.12-0.86,0.08-1.72-0.09l0.08,0.08
				c-1.26-0.03-0.36-0.53-2.67-0.5c0.35-0.06,1.02-0.01,1.44-0.01c-0.31-0.07-1.05-0.2-1.23-0.15c0.13,0.01,0.29,0,0.41,0.05
				c-1.78-0.02-0.47-0.16-6.84-1.96c0.36,0.41-0.37-0.09-0.37,0.22c-0.8,0.15,0.02-0.54-2.38-1.05c0.08,0.04,0.17,0.09,0.31,0.16
				c-0.66-0.17-0.81,0.42-1.1,1.73l-0.03-0.3c-0.4,0.63,0.49-0.27-1.67,5.25l-0.03-0.11c-0.11,0.34-0.09,0.1-0.6,1.34l0.03-0.25
				c-0.62,0.9-0.67,1.55-1.14,2.35c-0.18-0.12-0.74,0.53-1.02,0.86l-0.02-0.03c-1.73,2.06-0.32,1.53-4.78,5.05
				c0.1-0.01-0.03,0.36-0.58,0.65c-1.69,1.31-0.56,0.51-4.96,3.12l-0.05-0.13c-0.71,0.31-1.06,0.47-1.94,0.83l0.14-0.17
				c-1.53,0.94-0.65,0.53-3.88,1.46C1754.56,228.47,1754.57,228.4,1754.72,228.36z M1744.27,228.91c0.14-0.07,0.1-0.08,0.33,0.02
				C1744.52,228.93,1744.6,228.89,1744.27,228.91z M1589.32,200.47c0.21-0.02,0.14-0.04,0.39,0.13
				C1589.61,200.58,1589.72,200.57,1589.32,200.47z M1590.13,201.82c0.06,0.03,0.15,0.07,0.31,0.14
				C1590.31,201.91,1590.44,201.96,1590.13,201.82z M1591.31,202.27c-0.08,0,0.16,0.14,0.33,0.27
				C1591.48,202.47,1591.03,202.31,1591.31,202.27z M1725.76,220.59c-0.03,0.17,0.18-0.51,0.4-0.1
				C1726.02,220.53,1725.88,220.57,1725.76,220.59z M1745.19,229.96c0.06,0,0.14,0.01,0.28,0.01
				C1745.35,229.97,1745.46,229.97,1745.19,229.96z M1746.23,229.93c-0.06,0.03,0.17,0.07,0.33,0.12
				C1746.42,230.05,1746.01,230.07,1746.23,229.93z"
                />
                <path
                  className="st10"
                  d="M1665.22,192.11c-0.49,0.2-0.14,0.14,0.39,0.91l-0.1,0.14c0.14-0.01,0.11-0.12,0.1-0.21
				c0.13-0.01,0.2,0.14,0.1-0.28c0.41,0.3-0.03-0.34,0.29-0.07c0.01-0.12,0.14-0.23,0.08-0.35c0.16,0,0.19-0.28,0.35-0.13
				c-0.02-0.06-0.01-0.24,0.04-0.32c0.09,0.3,0.24-0.25,0.42-0.03c-0.09-0.25,0.22-0.4,0.27-0.66c0.01,0.02,0.02,0.07,0,0.07
				c0.1-0.08,0.17-0.06,0.26-0.08c-0.13-0.15-0.02-0.31-0.04-0.47c0.24,0.03,0.1,0.23,0.26,0.32c-0.12-0.11,0.07-0.3-0.06-0.35
				c0.17,0,0.11-0.03,0.27,0.04c-0.16-0.19,0.05-0.11,0.01-0.28c0.12,0.06,0.28-0.24,0.33,0.12c0-0.47,0.07-0.03,0.69-1.14
				c0.02,0,0.04,0.01,0.05,0.02c0.01-0.03-0.33-0.13-0.04-0.3c-0.04,0.03-0.01,0.07-0.01,0.12l0.03-0.1
				c-0.14,0.49,0.48-0.04,0.15,0.11c0.38,0.74,0.64-0.72,2.3-1.75c-0.28,0.25,0.74-0.24,1.38-0.64c0.15-0.25,0.49-0.61,0.81-0.6
				l-0.03,0.06c0.21-0.05,0.06-0.38,0.3-0.35l-0.02,0.04c0.43-0.17,0.82-0.52,1.24-0.47c0.12-0.2-0.04-0.35,1.14-0.66l0.06,0.14
				c0.19-0.59-0.11,0.28,1.02-0.51c0.01,0.06-0.04,0.11-0.08,0.17c0.15-0.23,0.1,0.13,0.24-0.07c-0.04-0.02-0.02-0.09-0.03-0.12
				c0.13,0.05,0.35-0.03,0.5-0.13l0.01,0.11c0.29-0.08,0.58-0.26,0.9-0.38c-0.06-0.05-0.08-0.41,0.05-0.11
				c0.01-0.11-0.02-0.36,0.06,0.04l0.13-0.1c0.02,0.04,0,0.08,0.01,0.14c0.73-0.28,0.07,0.1,2.03-0.93
				c0.05,0.12,0.29,0.04,0.18,0.17c0.29-0.1,0.37-0.13,0.72-0.32c0.58-0.3,0,0,1.4-0.19l-0.02,0.02c0.92-0.24,0.02-0.15,2.5-0.27
				c-0.01-0.08-0.06-0.19,0.14-0.04c-0.37-0.53,0.57,0.14,4.25-0.47c-0.03,0.04-0.03,0.09-0.08,0.09c0.12,0.12,0.42-0.01,0.42-0.1
				c0.07,0.08,0.16,0.22,0.64,0.02c0.18,0.05,0.25,0.07,0.93-0.12c-0.02,0.22,0.35,0.18,0.48,0.09c-0.04,0.07,0.11,0.07,0.06,0.17
				c0.15,0,0.28-0.04,0.26-0.15c0.45,0.25,0.27,0.15,1.93-0.03c-0.4,0.46-0.86,0.12,1.49,0.19c0,0.02-0.04,0.04-0.08,0.05
				c0.19-0.09-0.04,0.02,0.39-0.4c-0.14,0.28,0.32,0.1,0.11,0.46c0.04-0.02,0.1-0.06,0.1-0.13c0.06,0.03,0.08,0.07,0,0.14
				c0.24-0.03,0.17-0.15,0.38-0.12c-0.01,0.05-0.05,0.06-0.08,0.12c0.12-0.08,0.24-0.17,0.41-0.1c-0.01,0.03,0,0.07-0.05,0.06
				c0.31,0.02,0.55-0.19,0.88-0.06c-0.13,0.2,0.1,0.08,0.1,0.27l0.19-0.03l-0.06-0.1c0.16,0.03,0.32-0.09,0.44-0.01l-0.13,0.06
				l0.3,0.01l-0.16-0.09c0.17-0.07,0.02-0.24,0.22-0.15c-0.01,0.04,0.08,0.02,0.47,0.35c0.1-0.14,0.38,0.01,0.5-0.13
				c-0.04,0.03-0.06,0.13-0.05,0.15l0.08-0.11c0.07,0.05,0.07,0.07,0.05,0.15c0.22,0.06-0.03-0.25,0.21-0.1
				c-0.02,0.01-0.02,0.03-0.06,0.04c0.3,0.03,0.41,0.09,0.49,0.14l-0.09,0.01l0.08,0.09l0.05-0.08c0.12,0.1,0.2,0.19,1.14,0.14
				c0.15-0.34-0.32,0.23,1.13,0.18l-0.06,0.06c0.02-0.04,0.28,0.3,0.59,0.04c0.36,0.48,0.9-0.02,1.22,0.39
				c-0.13-0.04,0.22,0.24,0.43-0.19c-0.17,0.04-0.04,0.18,0.01,0.26c0-0.04,0.03-0.07,0.03-0.11c0.07,0.31,0.11-0.13,0.26,0.03
				c-0.04,0.01,0.51-0.09,0.15,0.12c0.14,0.02,0.33-0.18,0.42-0.02c-0.07,0.02-0.15,0-0.22,0.02c0.1,0.18,0.12,0.15,0.21,0.2
				l-0.01-0.01l0.21,0.13c0.09-0.4-0.01-0.04,0.6-0.19c-0.23,0.14-0.12,0.08-0.03,0.41c0.14-0.09,0.16,0.14,0.35,0
				c0.28,0.12,0.3-0.04,1.8,0.27c-0.08,0.22-0.11,0.2,0.32,0.33c-0.01-0.03,0.35,0.07,0.39-0.16c-0.32,0.27,0.25-0.29-0.65-0.14
				l0.13-0.18l-0.23,0.11c-0.02-0.06-0.02-0.18,0.08-0.19c-0.09-0.11,0-0.12-0.34-0.11c0.04-0.04,0.02-0.15,0.09-0.1
				c-0.16-0.09-0.47-0.33-0.78,0.12c-0.04,0.19,0.07-0.63-0.19-0.37c-0.15,0.07,0,0.07-0.09,0.15c-0.12-0.18-0.44-0.05-0.42-0.29
				c-0.08,0.1,0.13,0.12-0.06,0.16c0.16-0.29-0.44,0.06-0.23-0.33c-0.19,0.16-0.04-0.01-0.44-0.07c-0.01-0.14,0.02,0.06-0.46-0.02
				c0.04-0.08-0.03,0.05-0.52-0.32c-0.08,0.17-0.27,0.09-0.42,0.09c0.03-0.11,0.07-0.14,0.15-0.17c-0.02,0.02-0.04-0.08-0.32,0.04
				l0.06-0.13c-0.18-0.03-0.39,0.07-0.6-0.07c-0.04,0.12,0.1,0.39-0.16,0.42c0.01-0.07,0.02-0.19,0.09-0.2c-0.02,0-0.09,0.04-0.12,0
				l0.12-0.09c-0.07,0.04-0.32-0.06-0.07-0.08c-0.09-0.04-0.19-0.12-0.21-0.2c-0.03,0.23-0.23-0.27-0.84,0.03l0,0
				c-0.25,0.15-0.71-0.35-1.9-0.44c0.15-0.06-0.03-0.08,0.15-0.04c-0.17-0.08-0.2-0.16-0.41-0.06c0.09-0.11-0.11-0.18-0.2-0.16
				l0.11,0.11c-0.49-0.22-0.04-0.18-1.13-0.36c0.34,0.6-1.01-0.03-0.86-0.09l-0.03,0.06c-0.08-0.07-0.31-0.1-0.24-0.19
				c-0.14,0.04-0.07,0.09,0.02,0.13c-0.21-0.12-0.34-0.05-0.56-0.14c0.09,0.07-0.75,0.4-0.48-0.16c-0.22,0.09-0.38-0.12-0.49-0.16
				l0.11-0.03c-0.07,0.02-0.12,0.01-0.18,0.01l0.05,0.13c-0.25-0.14-0.06-0.13-0.5,0.08c0.31,0.16,0.19-0.61-0.31-0.08
				c0.04-0.09-0.04-0.17-0.09-0.16c0.08-0.02,0.04,0.21-0.35,0.23l0.1,0.04c-0.42,0.11-0.02,0.11-1.15-0.37
				c0.05,0.12,0.07,0.09-0.09,0.2l0.36-0.06l-0.24,0.16c0.11,0.01,0.23-0.06,0.29-0.03c-0.12,0.09-0.1,0.06-0.06,0.16
				c-0.6-0.02-0.1-0.58-1.22-0.32c0.08,0.09,0.19,0.12-0.21,0.1c0.02-0.1-0.05-0.14-0.09-0.2c0,0.07-0.24-0.02-0.28,0.18
				c-0.28-0.17-0.13-0.24-0.72,0.09c0.08-0.1-0.03-0.18,0.14-0.25c-0.09,0-0.16-0.02-0.17,0.04c-0.02-0.02-0.11-0.08-0.47,0.11
				c0,0.05-0.15,0.06-0.07,0.17c-0.19-0.23-0.33-0.23-1.55-0.18c0.01-0.03,0-0.05,0.03-0.04c-0.54,0.11-0.03-0.05-0.86-0.09
				l0.04-0.02c-0.14-0.11-0.33,0.13-0.56,0.12c0,0,0.01-0.03,0-0.05c-0.15,0.17-0.44,0.23-0.65,0.3c0-0.05,0.01-0.08,0.01-0.11
				c-0.03,0.1-0.12,0.22-0.35-0.09l0.18-0.02c-0.03-0.58-0.94,0.67-1.22,0.08c-0.1,0.06-0.36,0.23-0.55,0.28
				c0.09-0.16,0.31-0.25,0.45-0.35c-0.11,0.01-0.36,0.05-0.41,0.15c0.04-0.02,0.09-0.08,0.14-0.05c-0.85,0.36-0.02,0.1-2.45,0.1
				c0.1,0.25-0.02,0.34-0.2,0.31c0.01-0.07,0.13-0.22-0.04-0.27c0.14,0.08-0.08,0.17-0.02,0.25c-0.16-0.05-0.35-0.18-0.5-0.39
				c0.05,0.1-0.05,0.17-0.19,0.15l0.2,0.11c-0.28,0.22-0.32-0.2-0.6,0.01l0.08-0.11c-0.15,0.09-0.6,0.1-0.71,0.39
				c-0.04-0.03-0.09-0.1-0.02-0.14c-0.3-0.05-0.44,0.11-1.59,0.49l0.06-0.1c-0.39,0.01-0.51,0.35-0.81,0.49
				c-1.09-0.4-0.24-0.31-2.85,0.59c0.02-0.02,0.12,0.25-0.26,0.25c0.14-0.05,0.04-0.02-0.35,0.25c-0.52-0.2-0.92,0.54-1.26,0.31
				c-0.06,0.1-0.18,0.15-0.24,0.24c-0.23-0.18-0.02-0.13-0.73,0.17l0.02-0.12c-0.35,0.55-0.05,0.25-1.21,0.85
				c-0.01-0.03-0.03-0.09,0.01-0.12c-0.14,0.07-0.6,0.16-0.41,0.39c-0.09-0.16-0.31,0.15-0.42,0.3l-0.05-0.13
				c0.02,0.03-0.1,0.2-0.44,0.57l0.02-0.12c-0.23,0.14,0.05,0.38-0.52,0.3c-0.12,0.1-0.16,0.06-0.7,0.44
				c0.19-0.13,0.34-0.22,0.34-0.2c-0.07-0.11-0.24-0.03-0.21-0.26c-0.14-0.01-0.05,0.47-0.89,0.74c0.09,0.11-0.02,0.16,0.14,0.19
				c-0.03,0.01,0.03-0.03,0.07-0.1l0,0.01c0.05-0.04,0.11-0.08,0.18-0.14c-0.02,0.1-0.05,0.19-0.17,0.18
				c0.01,0.1,0.03,0.21,0.03,0.26c-0.54-0.03-0.54-0.46-1.21,0.63c0.02-0.05,0.07-0.08,0.06-0.12c-0.08,0.15-0.35,0.31-0.27,0.48
				c-0.21,0.06-0.02-0.2-0.22-0.01l-0.01-0.07c-0.61,0.12-0.35,0.93-1.05,1.04c0.05-0.01,0.07-0.02,0.1,0
				c-0.5,0.25,0.19,0.21-1.79,1.66C1666.25,191.13,1665.7,191.6,1665.22,192.11z M1671.65,187.19c0-0.04-0.17,0.2-0.16-0.01
				C1671.49,187.21,1671.58,187.19,1671.65,187.19z M1671.34,187.4c-0.02,0.03,0,0-0.07,0.06
				C1671.29,187.45,1671.3,187.43,1671.34,187.4z M1670.46,186.91c-0.01-0.03,0,0-0.02-0.07
				C1670.47,186.8,1670.61,186.78,1670.46,186.91z"
                />
              </g>
            </g>
          </g>
        </animated.svg>
      )}
    </Spring>
  )
}

export default HeroAnimation

import { esel1, esel2 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'raices',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '2',
              },
            ],
          },
          {
            type: 'hero',
            cols: 11,
            title: [
              {
                text: '¡ES LEY!',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'Oaxaca se convirtió en el segundo estado mexicano en despenalizar el aborto hasta la décimo segunda semana de gestación.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        md: 7,
        sm: 12,
        data: [
          { type: 'placeholder', cols: 3 },
          {
            type: 'img',
            hover: true,
            img: esel1,
            cols: 10,
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        sm: 12,
        className: 'mt-2',
        md: 4,
        data: [
          {
            type: 'img',
            className: 'max-width-img',
            img: esel2,
            hover: true,
            delay: 1,
            cols: 9,
          },
        ],
      },
      {
        type: 'col',
        cols: 8,

        data: [
          {
            type: 'text',
            cols: 12,
            className: 'mt-2',
            text: [
              {
                type: 'p',
                text:
                  'Se trata de una decisión histórica de justicia social que protege la vida y la salud de las mujeres que viven en una de las entidades con mayor desigualdad y pobreza, y reafirma la laicidad del Estado mexicano para proteger su libertad de conciencia.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Esta victoria ha sido resultado del trabajo continuo de muchas organizaciones, activistas y aliadas, cada una aportando desde su trinchera. Desde CDD contribuimos con argumentos éticos y manteniendo un diálogo cercano con nuestras aliadas locales en apoyo a su trabajo.',
              },
              {
                type: 'mark',
                color: 'green',
                text:
                  ' Más aún, CDD ha estado presente en Oaxaca desde hace más de 20 años para promover la despenalización social del aborto. ',
              },
            ],
          },
        ],
      },
    ],
  },
]

import React from 'react'
import { Template } from '../../../../Components'
import { DATA } from './data'

import './index.css'

const Elevamos = ({ animate, reset }) => {
  return <Template data={DATA} animate={animate} reset={reset} />
}

export default Elevamos

import { intro1, intro2 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'block-1 ',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 6,
        data: [
          {
            type: 'hero',
            className: 'text-right',
            star: 'top right',
            cols: 11,

            subtitle: [
              {
                text: 'MILES DE MUJERES, MUCHAS DE ELLAS JÓVENES, SALIERON',
              },
              {
                type: 'br',
              },
              {
                text: ' A LAS CALLES Y',
              },
              {
                color: 'pink',
                text: ' SE',
              },
              {
                type: 'br',
              },
              {
                color: 'pink',
                text:
                  'APROPIARON DE OTROS ESPACIOS PARA DENUNCIAR EL ACOSO Y LAS VIOLENCIAS QUE ENFRENTAMOS.',
              },
              {
                type: 'img',
                img: intro1,
                hover: true,
                cols: 12,
                md: 8,
                sm: 8,
              },
            ],
          },

          {
            type: 'img',
            className: 'text-center',
            img: intro1,
            hover: true,
            figcaption: 'Fotografía: Miguel Bruna/ unsplash.com',
            cols: 9,
            md: 8,
            sm: 8,
          },
          {
            type: 'img',
            className: 'timeClock',
            img: intro2,
            hover: true,
            delay: 1,
            cols: 10,
          },
        ],
      },
      {
        type: 'col',
        cols: 6,
        data: [
          { type: 'placeholder', cols: 1 },
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'La Primavera feminista, diamantina púrpura y los pañuelos verdes se extendieron como símbolos de esa lucha que, si bien ha ganado muchas batallas, nos recuerda que aún hay mucho por hacer.',
              },
            ],
          },
          { type: 'placeholder', cols: 1 },
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'Esta movilización se dio en un momento particularmente importante para CDD: en 2019',
              },
              {
                type: 'mark',
                color: 'pink',
                text: ' cumplimos 25 años',
              },
              {
                type: 'p',
                text:
                  ' y nuestra fundadora y directora María Consuelo Mejía decidió retirarse. Nuestra directora adjunta, Gillian Fawcett, asumió la dirección interina e iniciamos un ',
              },
              {
                type: 'mark',
                color: 'pink',
                text:
                  'un proceso de transición hacia un nuevo modelo de co-dirección.',
              },
            ],
          },
          { type: 'placeholder', cols: 1 },
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'Con tristeza por la partida de nuestra querida María Consuelo pero con emoción por lo que viene, festejamos un cuarto de siglo de trabajo a favor de los derechos de las mujeres y los jóvenes; ',
              },
              {
                type: 'p',
                text:
                  'Con tristeza por la partida de nuestra querida María Consuelo pero con emoción por lo que viene, festejamos un cuarto de siglo de trabajo a favor de los derechos de las mujeres y los jóvenes; ',
              },
              {
                type: 'p',
                color: 'green',
                text:
                  'un cuarto de siglo de ser una voz católica alternativa y disidente que busca una sociedad e Iglesia más solidarias, justas y compasivas',
              },
              {
                type: 'p',
                text: ', a pesar de múltiples resistencias. ',
              },
            ],
          },
          { type: 'placeholder', cols: 1 },
          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'Celebrando lo que hemos alcanzado y preparándonos para el camino que continúa, presentamos en este informe',
              },
              {
                type: 'mark',
                color: 'pink',
                text: ' los avances a los que contribuimos a lo largo de 2019.',
              },
            ],
          },
        ],
      },
    ],
  },
]

import React from 'react'
import { Template } from '../../../../Components'
import { DATA, DATA2 } from './data'
import { ramas1 } from '../../../../Components/ImagesBySection'

import './index.css'

const Ramas = ({ animate, reset }) => {
  const animation = animate
    ? `animation-list animate__animated animate__pulse`
    : ''
  return (
    <>
      <Template data={DATA} animate={animate} reset={reset} />
      <div className={`rama-animada ${animation}`}>
        <img className="rama" alt="rama" src={ramas1} />
      </div>
      <Template data={DATA2} animate={animate} reset={reset} />
    </>
  )
}

export default Ramas

import { raices1, raices2 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'raices',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 6,
        data: [
          {
            type: 'hero',
            className: 'star-bottom',
            cols: 11,
            star: 'bottom',
            title: [
              {
                text: '1',
              },
            ],
          },
          {
            type: 'hero',
            cols: 8,
            title: [
              {
                text: 'RAÍCES DE UN CICLO',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text: 'María Consuelo se retiró con un merecido',
              },
              {
                type: 'p',
                color: 'yellow',
                text: ' reconocimiento ',
              },
              {
                type: 'p',
                text:
                  'a su trayectoria a la cabeza de cdd, recibiendo la presea “Elvia Carrillo Puerto” de parte del Senado de la República por su destacable defensa de los derechos de las mujeres. ',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 5,

        data: [
          {
            type: 'img',
            img: raices1,
            hover: true,
            figcaption: 'Fotografía: Miguel Bruna/ unsplash.com',
            cols: 12,
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    className: 'raices-quote',
    data: [
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 7,
        md: 7,
        sm: 11,
        data: [
          {
            type: 'text',
            className: 'quote',
            cols: 12,

            text: [
              {
                type: 'p',
                className: 'quote-text',
                text:
                  'En 2019, con el corazón en la mano, decidí dejar Católicas por el Derecho a Decidir. Fue una decisión que me costó mucho, pues dediqué 25 años de mi vida, con pasión y compromiso, a consolidar la presencia en México de esta propuesta católica feminista.',
              },
              {
                type: 'br',
              },

              {
                type: 'p',
                text: 'María Consuelo Mejía',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        md: 4,
        sm: 6,
        data: [
          {
            type: 'img',
            img: raices2,
            hover: true,
            delay: 1,
            cols: 8,
            md: 11,
            sm: 12,
          },
        ],
      },
    ],
  },
]
